import React, { useEffect, useState } from 'react';
import { Redirect, Route, RouteComponentProps, Switch, useLocation } from 'react-router';
import startCase from 'lodash/startCase';
import { useDispatch, connect } from 'react-redux';
import analyticsService, { AmplitudeEventData, AnalyticsEvent } from 'services/AnalyticsService';

import { TimerContext, BOOKING_TIMEOUT } from 'lib/booking/utils';

import Banner from 'components/UI/Banner/Banner';
import { Breadcrumbs } from 'components/Breadcrumbs';
import RouteLeavingGuard from 'components/UI/Modals/RouteLeavingGuardModal';

import { useRoutes } from 'hooks/useRoutes';
import { useRoutesToCrumbs } from 'hooks/useRoutesToCrumbs';

import { exitBooking } from 'modules/constants/booking';

import GetCareHome from 'screens/GetCareHome/GetCareHome';
import { BOOKING_PAGE_TITLE } from 'screens/Booking/constants';
import { BookingStepper } from 'screens/Booking/components/BookingStepper';
import { ComponentProps, RouteData, WithRoutes } from 'screens/Booking/router/types';

import { RootState } from 'store/types';
import {
  clearAppointmentDetails,
  closeTimeSlotListener,
  clearNpv2State,
  clearLocationTimeSlots
} from 'store/booking/actions';
import * as bookingTypes from 'store/booking/types';
import * as bookingSelectors from 'store/booking/selectors';
import {
  currentLocationPathNameSelector,
  previousLocationPathNameSelector
} from 'store/router/selectors';

import BookingPatientSelect from '../BookingPatientSelect';
import BookingSpecialtyOrMyDoctorSelect from '../BookingSpecialtyOrMyDoctorSelect';
import BookingSpecialtyProviderSelect from '../BookingSpecialtyProviderSelect';
import BookingVisitTypeSelect from '../BookingVisitTypeSelect';
import BookingLocationPreferenceSelect from '../BookingLocationPreferenceSelect';
import BookingOfficeLocationSelect from '../BookingOfficeLocationSelect';
import BookingDateAndTimeSelect from '../BookingDateAndTimeSelect';
import BookingReasonForVisit from '../BookingReasonForVisit';
import BookingReviewAppointmentDetails from '../BookingReviewAppointmentDetails';
import BookingSuccess from '../BookingSuccess';
import ManageAppointments from '../ManageAppointments';
import ProviderDetailsScreen from '../../ProviderDetails/ProviderDetails';
import { SHARE_FEEDBACK_SCENARIOS } from 'lib/constants/help';
import { ROUTE } from 'components/ConnectCare/constants';
import KyruusProviderDetails from 'screens/ProviderSearch/KyruusProviderDetails';
import KyruusProviderSearch from 'screens/ProviderSearch/KyruusProviderSearch';
import { kyruusEnabled } from 'lib/findProvider/utils';

export const bookingFormRoutes: RouteData[] = [
  {
    path: '/patient-select',
    component: BookingPatientSelect,
    componentName: 'BookingPatientSelect',
    title: BOOKING_PAGE_TITLE,
    crumbLabel: 'Patient',
    deps: []
  },
  {
    path: '/specialty-or-my-doctor-select',
    component: BookingSpecialtyOrMyDoctorSelect,
    componentName: 'BookingSpecialtyOrMyDoctorSelect',
    title: BOOKING_PAGE_TITLE,
    crumbLabel: 'Specialty Or My Doctor',
    deps: []
  },
  {
    path: '/specialty-provider-select',
    component: BookingSpecialtyProviderSelect,
    componentName: 'BookingSpecialtyProviderSelect',
    title: BOOKING_PAGE_TITLE,
    crumbLabel: 'Specialty Provider',
    deps: []
  },
  {
    path: '/provider-details/:id',
    component: ProviderDetailsScreen,
    componentName: 'ProviderDetailsScreen',
    title: BOOKING_PAGE_TITLE,
    crumbLabel: 'Provider Details',
    includeBanner: false,
    includeStepper: false,
    deps: []
  },
  {
    path: '/visit-type-select',
    component: BookingVisitTypeSelect,
    componentName: 'BookingVisitTypeSelect',
    title: BOOKING_PAGE_TITLE,
    crumbLabel: 'Visit Type',
    deps: []
  },
  {
    path: '/kyruus-provider-details',
    component: KyruusProviderDetails,
    componentName: 'KyruusProviderDetails',
    title: BOOKING_PAGE_TITLE,
    includeBanner: false
  },
  {
    path: '/location-preference-select',
    component: BookingLocationPreferenceSelect,
    componentName: 'BookingLocationPreferenceSelect',
    title: BOOKING_PAGE_TITLE,
    crumbLabel: 'Location Preference',
    deps: []
  },
  {
    path: '/office-location-select',
    component: BookingOfficeLocationSelect,
    componentName: 'BookingOfficeLocationSelect',
    title: BOOKING_PAGE_TITLE,
    crumbLabel: 'Office Location',
    deps: []
  },
  {
    path: '/date-and-time-select',
    component: BookingDateAndTimeSelect,
    componentName: 'BookingDateAndTimeSelect',
    title: BOOKING_PAGE_TITLE,
    crumbLabel: 'Date & Time Select',
    deps: []
  },
  {
    path: '/reason-for-visit',
    component: BookingReasonForVisit,
    componentName: 'BookingReasonForVisit',
    title: BOOKING_PAGE_TITLE,
    crumbLabel: 'Reason for Visit',
    deps: []
  },
  {
    path: '/review-appointment-details',
    component: BookingReviewAppointmentDetails,
    componentName: 'BookingReviewAppointmentDetails',
    title: BOOKING_PAGE_TITLE,
    crumbLabel: 'Review Details',
    deps: []
  },
  {
    path: '/book-appointment-success',
    component: BookingSuccess,
    componentName: 'BookingSuccess',
    title: BOOKING_PAGE_TITLE,
    crumbLabel: 'Success',
    deps: []
  },
  {
    path: '/find-a-doctor',
    component: KyruusProviderSearch,
    componentName: 'BookingFindProvider',
    title: BOOKING_PAGE_TITLE,
    crumbLabel: 'Find Provider',
    deps: []
  }
];

interface Props extends RouteComponentProps {
  currentUrl?: string;
  referringUrl?: string;
  appointment: bookingTypes.AppointmentDetails;
}

const isRouter = (r: Partial<RouteData>) => r.componentName?.includes('Router');
const filterRouter = (routes: RouteData[]) => routes.filter(r => !isRouter(r));

const BookingComponent = ({
  component: Component,
  routes = [],
  stepRoutes = [],
  ...rest
}: ComponentProps) => {
  const crumbs = useRoutesToCrumbs(filterRouter(routes));
  const activeStep = stepRoutes.findIndex((s: RouteData) => s.path === rest.location.pathname);

  return (
    <>
      {rest.includeCrumbs ? <Breadcrumbs crumbs={crumbs} /> : null}
      {rest.includeBanner ? (
        <Banner
          message={rest.match.params.title ? startCase(rest.match.params.title) : rest.title}
          subMessage={rest.subTitle}
          icon={rest.icon || null}
          {...rest}
        />
      ) : null}
      {rest.includeStepper ? <BookingStepper activeStep={activeStep} steps={stepRoutes} /> : null}
      <Component routes={routes} steps={stepRoutes} activeStep={activeStep} {...rest} />
    </>
  );
};

const BookingFormRoute = ({ exact, path, ...rest }: WithRoutes<RouteData>) => {
  return (
    <Route exact={exact} path={path} render={props => <BookingComponent {...rest} {...props} />} />
  );
};

const BookingFormRouter = (props: WithRoutes) => {
  const routes = useRoutes(bookingFormRoutes);
  const [timerContext, setTimerContext] = useState(BOOKING_TIMEOUT);
  const stepRoutes = routes.filter(r => r.includeStepper !== false);

  return (
    <TimerContext.Provider value={[timerContext, setTimerContext]}>
      <Switch>
        {routes.map(r => (
          <BookingFormRoute
            key={r.path}
            stepRoutes={stepRoutes}
            routes={routes.concat(props.routes)}
            includeStepper={r.includeStepper || true}
            includeBanner={r.includeBanner || true}
            includeCrumbs={r.includeCrumbs || true}
            {...r}
          />
        ))}
        <Redirect to={`${props.match.path}/patient-select`} />
      </Switch>
    </TimerContext.Provider>
  );
};

const bookingRoutes: RouteData[] = [
  {
    path: '/manage-appointments',
    component: ManageAppointments,
    componentName: 'ManageAppointments',
    crumbLabel: 'Manage Appointments'
  },
  {
    path: '/',
    component: BookingFormRouter,
    componentName: 'BookingFormRouter',
    includeCrumbs: false,
    includeBanner: false
  }
];

const getCareRoute: RouteData = {
  exact: true,
  path: '/u/get-care-now',
  component: GetCareHome,
  crumbLabel: 'Get Care'
};

const BookingRouter = ({ match, history, currentUrl, referringUrl, appointment }: Props) => {
  let routes = useRoutes(bookingRoutes);
  const location = useLocation();

  routes = routes.concat([getCareRoute]);
  const crumbs = useRoutesToCrumbs(filterRouter(routes));
  const [isRouteGuardOpen, setIsRouteGuardOpen] = useState(false);
  const currentRoute = history?.location?.pathname;
  const dispatch = useDispatch();

  useEffect(() => {
    setIsRouteGuardOpen(true);
  }, [location]);

  const handleNavigateFromBooking = async (path: string, options: object) => {
    dispatch(clearNpv2State());
    const eventData: AmplitudeEventData = {
      referringUrl,
      currentUrl
    };
    analyticsService.logEvent(AnalyticsEvent.BookingFlowExited, eventData);

    // Manually set history if user entered booking from guest flow
    if (path === '/auth/ping/callback' && appointment.doctor?.corpProvId) {
      const providerUrl = `/u/provider/${appointment.doctor?.corpProvId}`;
      await dispatch(clearAppointmentDetails());
      dispatch(clearLocationTimeSlots());
      closeTimeSlotListener();
      history.push(providerUrl);
    } else if (path !== '/auth/ping/callback') {
      await dispatch(clearAppointmentDetails());
      dispatch(clearLocationTimeSlots());
      closeTimeSlotListener();
      history.push(path, options);
    }
  };

  const exitAndShareFeedback = (path?: string) => {
    setTimeout(() => {
      history.push('/u/help-support/share-feedback', {
        scenario: SHARE_FEEDBACK_SCENARIOS.appointmentScheduling,
        screenLocation: '/u/get-care-now/booking',
        navigateTo: path || ROUTE.getCare
      });
    }, 1); // Note stick this on the end of the stack.
  };

  return (
    <Switch>
      {routes.map(({ component: Component, ...r }) => (
        <Route
          key={r.path}
          path={r.path}
          exact={r.exact || false}
          render={props => (
            <>
              {r.includeCrumbs === false ? null : <Breadcrumbs crumbs={crumbs} />}
              <Component routes={routes} {...r} {...props} />
              <RouteLeavingGuard
                when={isRouteGuardOpen}
                shouldBlockNavigation={nextLocation => {
                  const bookingRoutes = bookingFormRoutes.map(
                    route => `/u/get-care-now/booking${route.path.replace('/:id', '')}`
                  );
                  const rescheduleAppointmentRoutes = [
                    '/u/get-care-now/booking/book-appointment-success',
                    '/u/get-care-now/booking/date-and-time-select',
                    '/u/get-care-now/booking/reason-for-visit',
                    '/u/get-care-now/booking/review-appointment-details'
                  ];

                  // Handle Success Exit
                  if (currentRoute === '/u/get-care-now/booking/book-appointment-success') {
                    dispatch(clearAppointmentDetails());
                    dispatch(clearNpv2State());
                    return false;
                  }

                  if (
                    currentRoute === '/u/get-care-now/booking/manage-appointments' ||
                    rescheduleAppointmentRoutes.includes(nextLocation.pathname) ||
                    bookingRoutes.includes(nextLocation.pathname)
                  ) {
                    return false;
                  }

                  if (
                    kyruusEnabled() &&
                    bookingRoutes.includes(currentRoute) &&
                    !rescheduleAppointmentRoutes.includes(currentRoute)
                  ) {
                    return false;
                  }

                  return true;
                }}
                exitTitle={exitBooking.BOOKING_CONFIRM_DIALOG_TEXT.title}
                exitSubtitle={exitBooking.BOOKING_CONFIRM_DIALOG_TEXT.subtitle}
                exitButtonText={exitBooking.BOOKING_CONFIRM_DIALOG_TEXT.primaryButton}
                keepGoingButtonText={exitBooking.BOOKING_CONFIRM_DIALOG_TEXT.secondaryButton}
                exitAndShareButtonText={exitBooking.BOOKING_CONFIRM_DIALOG_TEXT.textButton}
                navigate={(path: string, options: object) =>
                  handleNavigateFromBooking(path, options)
                }
                exitAndShareHandler={exitAndShareFeedback}
              />
            </>
          )}
        />
      ))}
      <Redirect to={`${match.path}`} />
    </Switch>
  );
};

const mapStateToProps = (state: RootState) => ({
  currentUrl: currentLocationPathNameSelector(state),
  referringUrl: previousLocationPathNameSelector(state),
  appointment: bookingSelectors.appointmentDetailsSelector(state)
});

export default connect(mapStateToProps)(BookingRouter);
