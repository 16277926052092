import React, { useState } from 'react';
import CollapsibleListItem from 'components/common/CollapsibleList/CollapsibleListItem';
import { Color } from 'modules/styles/colors';
import { Spacing } from 'modules/styles/variables';
import { FilterFormControlLabel, FilterRadio } from './styled';
import { MuiButton, MuiRadioGroup } from 'theme/material-ui';
import { FilterItem } from 'store/visitSummary/types';

interface Props {
  items: FilterItem[];
  subtitle: string;
  sectionKey: string;
  initialItemsNumber: number;
  handleRadioClick: (value: string) => void;
}

const VisitSummaryFilterGroup = ({
  items,
  subtitle,
  sectionKey,
  initialItemsNumber,
  handleRadioClick
}: Props) => {
  const [showAll, setShowAll] = useState(initialItemsNumber >= items.length);
  const itemsList = showAll ? items : items.slice(0, initialItemsNumber);

  const itemsToRender = items.length
    ? itemsList.map(item => (
        <FilterFormControlLabel
          key={item.value}
          control={
            <FilterRadio id={`${item.label}-radio`} color="primary" checked={item.checked} />
          }
          for={`${item.label}-radio`}
          label={item.label}
          value={item.value}
          onClick={() => handleRadioClick(item.value)}
        />
      ))
    : [];

  return (
    <CollapsibleListItem
      headerColorOnExpand={Color.grayLight}
      subtitle={subtitle}
      key={sectionKey}
      padding={`${Spacing.medium}px 0px`}
      isExpandable={!!items.length}
    >
      <MuiRadioGroup>{itemsToRender}</MuiRadioGroup>
      {initialItemsNumber < items?.length ? (
        <>
          {!showAll ? (
            <MuiButton fullWidth onClick={() => setShowAll(true)} variant="text" color="primary">
              Show more
            </MuiButton>
          ) : (
            <MuiButton fullWidth onClick={() => setShowAll(false)} variant="text" color="primary">
              Show less
            </MuiButton>
          )}
        </>
      ) : null}
    </CollapsibleListItem>
  );
};

export default VisitSummaryFilterGroup;
