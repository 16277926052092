import CollapsibleListItem from 'components/common/CollapsibleList/CollapsibleListItem';
import DataLoader from 'components/UI/DataLoader/DataLoader';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { RootState } from 'store/types';
import { HEALTH_RECORDS_DRAWERS } from 'lib/constants/healthRecords';
import { VisitSummary } from 'store/visitSummary/types';
import { MuiBox, MuiButton, MuiGrid } from 'theme/material-ui';
import Spinner from 'components/UI/Spinner/Spinner';
import { HealthRecordsListItemPlaceholder } from '../shared';
import HealthRecordsVisitSummaryListItem from './ListItem';
import analyticsService, { AnalyticsEvent } from 'services/AnalyticsService';
import {
  currentLocationPathNameSelector,
  previousLocationPathNameSelector
} from 'store/router/selectors';
import { Spacing } from 'modules/styles/variables';

interface HealthRecordsVisitSummaryListProps {
  visitSummaries: VisitSummary[];
  error: boolean;
  loading?: boolean;
  currentUrl?: string;
  referringUrl?: string;
  totalVisits: number;
}

const getLoadingState = () => (
  <>
    <HealthRecordsListItemPlaceholder message={HEALTH_RECORDS_DRAWERS.VisitSummary.loading} />
    <MuiBox display="flex" flexDirection="row" justifyContent="center">
      <Spinner />
    </MuiBox>
  </>
);

const getErrorState = () => (
  <HealthRecordsListItemPlaceholder
    message={HEALTH_RECORDS_DRAWERS.getErrorState('visit details')}
  />
);

const getEmptyState = () => (
  <HealthRecordsListItemPlaceholder
    message={HEALTH_RECORDS_DRAWERS.getEmptyState('visit details')}
  />
);

export const HealthRecordsVisitSummaryList = ({
  visitSummaries,
  error,
  loading,
  currentUrl,
  referringUrl,
  totalVisits
}: HealthRecordsVisitSummaryListProps) => {
  const slicedVisitSummaries = visitSummaries.slice(0, 3);
  const history = useHistory();

  const handleViewAllClicked = () => {
    analyticsService.logEvent(AnalyticsEvent.ViewAllSummariesClicked, {
      currentUrl,
      referringUrl
    });
    history.push('/u/health-record/visit-summaries');
  };

  return (
    <CollapsibleListItem
      leftIcon="VisitSummaryIcon"
      title={HEALTH_RECORDS_DRAWERS.VisitSummary.title}
      subtitle={HEALTH_RECORDS_DRAWERS.VisitSummary.subtitle(totalVisits)}
      error={error ? 'An issue occurred' : undefined}
      analyticsEvent={AnalyticsEvent.VisitSummariesClicked}
      currentUrlForAnalytics={currentUrl}
      referringUrlForAnalytics={referringUrl}
      detailsPadding={`${Spacing.large}px 0`}
    >
      <MuiBox width="100%">
        <DataLoader
          data={visitSummaries}
          error={error}
          loading={loading}
          renderData={() => (
            <>
              <MuiGrid container spacing={3}>
                {slicedVisitSummaries.map(item => (
                  <HealthRecordsVisitSummaryListItem visit={item} key={item.id} />
                ))}
              </MuiGrid>

              {visitSummaries.length > slicedVisitSummaries.length ? (
                <MuiBox mt={3}>
                  <MuiButton
                    color="primary"
                    variant="outlined"
                    data-testid="show-all-link"
                    onClick={handleViewAllClicked}
                  >
                    Show all
                  </MuiButton>
                </MuiBox>
              ) : null}
            </>
          )}
          renderNoData={getEmptyState}
          renderError={getErrorState}
          renderLoading={getLoadingState}
        />
      </MuiBox>
    </CollapsibleListItem>
  );
};

const mapState = (state: RootState) => ({
  currentUrl: currentLocationPathNameSelector(state),
  referringUrl: previousLocationPathNameSelector(state)
});

export default connect(mapState)(HealthRecordsVisitSummaryList);
