import React from 'react';
import { MuiInputAdornment } from 'theme/material-ui';
import { TextInput, TextInputProps } from 'components/TextInput';

export type ConnectCareTextFieldProps = Omit<TextInputProps, 'variant' | 'fullWidth'>;

function ConnectCareTextField({ InputProps, ...rest }: ConnectCareTextFieldProps) {
  return (
    <TextInput
      {...rest}
      fullWidth
      variant="outlined"
      id={rest.name}
      InputProps={{
        ...InputProps,
        startAdornment: InputProps?.startAdornment ? (
          <MuiInputAdornment position="start">{InputProps?.startAdornment}</MuiInputAdornment>
        ) : null,
        endAdornment: InputProps?.endAdornment ? (
          <MuiInputAdornment position="end">{InputProps?.endAdornment}</MuiInputAdornment>
        ) : null
      }}
      InputLabelProps={{ shrink: true }}
    />
  );
}

export default ConnectCareTextField;
