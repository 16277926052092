import React, { useState } from 'react';

import { IconSize } from 'modules/styles/variables';
import { NavRoute } from 'screens/navigation';
import { MuiBox } from 'theme/material-ui';
import CollapsedItemButton from '../CollapsedItemButton/CollapsedItemButton';
import { FOOTER_LINK } from '../constants';
import { FullWidthList, StyledMuiDivider } from './styled';
import { LANGUAGE_SWITCHER_ENABLED } from '../ExpandedView';
import LanguageSwitcherModal from '../LanguageSwitcherModal';

export interface CollapsedViewProps {
  handleTabClick: (path: string) => void;
  isGuest: boolean | undefined;
  navBarRoutes: NavRoute[];
  unreadCount: number | null;
}

export const CollapsedView = ({
  handleTabClick,
  isGuest,
  navBarRoutes,
  unreadCount
}: CollapsedViewProps) => {
  const [laguageModalOpen, setLaguageModalOpen] = useState(false);
  const handleOpenLangModal = (e: any) => {
    e.preventDefault();
    setLaguageModalOpen(true);
  };
  return (
    <>
      <LanguageSwitcherModal
        open={laguageModalOpen}
        handleClose={() => setLaguageModalOpen(false)}
      />
      <FullWidthList id="navigation">
        <MuiBox display="flex" flexShrink="0" flexDirection="column">
          {navBarRoutes.map((item: NavRoute) => (
            <CollapsedItemButton
              key={item.componentName}
              iconName={item.sidenavIcon}
              iconSize={item.sidenavIconSize}
              routeName={item.path}
              routeText={item.title}
              disabled={isGuest}
              onClick={() => handleTabClick(item.path)}
              unreadCount={item.sidenavIcon === 'SNavMessages' ? unreadCount : null}
            />
          ))}
        </MuiBox>
        <StyledMuiDivider />
        <MuiBox flexDirection="column" display="flex" style={{ marginTop: 'auto' }}>
          <StyledMuiDivider />
          {LANGUAGE_SWITCHER_ENABLED ? (
            <MuiBox flex-shrink="0" data-testid="language">
              <CollapsedItemButton
                set="material"
                iconName="language"
                iconSize={IconSize.large}
                routeName="/"
                routeText=""
                onClick={handleOpenLangModal}
                unreadCount={null}
              />
            </MuiBox>
          ) : null}
          <MuiBox flexShrink="0">
            <CollapsedItemButton
              key={FOOTER_LINK.routeName}
              set="material"
              iconName="help_outline"
              iconSize={IconSize.large}
              routeName={FOOTER_LINK.routeName}
              routeText={FOOTER_LINK.name}
              unreadCount={unreadCount}
            />
          </MuiBox>
        </MuiBox>
      </FullWidthList>
    </>
  );
};

export default CollapsedView;
