import FlexBox from 'components/UI/Layout/FlexBox';
import { Svg } from 'components/UI/Svg';
import React from 'react';
import { MuiBox } from 'theme/material-ui';

interface Props {
  item: {
    statusText: string;
    statusColor: string;
  };
}

const VitalRowStatus = ({ item }: Props) => {
  return (
    <FlexBox flexDirection="row" alignItems="center">
      <MuiBox mr={1} ml={1}>
        |
      </MuiBox>
      {item.statusColor ? (
        <Svg name="Dot" fill="none" color={item.statusColor} height={10} width={10} />
      ) : null}
      <MuiBox ml={1}>{item.statusText}</MuiBox>
    </FlexBox>
  );
};

export default VitalRowStatus;
