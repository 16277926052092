import DataLoader from 'components/UI/DataLoader/DataLoader';
import React from 'react';
import { History } from 'lib/history';
import { MuiBox, MuiList, MuiPaper } from 'theme/material-ui';
import UpdatesListError from './UpdatesListError';
import UpdatesListItem from './UpdatesListItem';
import UpdatesListLoading from './UpdatesListLoading';
import UpdatesListNoData from './UpdatesListNoData';
import { FormattedEvent } from 'serverTypes/event';

interface UpdatesListProps {
  loading: boolean;
  error?: Error | null;
  updates: FormattedEvent[];
  history: History;
}

export function UpdatesList(props: UpdatesListProps) {
  const { loading, error, history, updates } = props;

  return (
    <DataLoader
      loading={loading}
      error={error}
      data={updates}
      renderLoading={UpdatesListLoading}
      renderError={UpdatesListError}
      renderNoData={UpdatesListNoData}
      renderData={() => (
        <MuiBox>
          <MuiPaper elevation={2}>
            <MuiList disablePadding>
              {updates.map(update => (
                <UpdatesListItem key={update.id} history={history} update={update} />
              ))}
            </MuiList>
          </MuiPaper>
        </MuiBox>
      )}
    />
  );
}

export default UpdatesList;
