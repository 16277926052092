import styled, { css } from 'styled-components';
import { BorderRadius } from 'modules/styles/variables';
import { spacing } from 'modules/utils/StylesUtils';
import { Color } from 'modules/styles/colors';
import { MuiIconButton } from 'theme/material-ui';

interface IconButtonProps {
  appearance?: 'transparent' | 'box';
  spacing?: number;
}

const getAppearance = (props: IconButtonProps) => {
  switch (props.appearance) {
    case 'transparent':
      return css`
        background-color: transparent;
      `;
    case 'box':
      return css`
        border-radius: ${BorderRadius.base}px;
        background-color: ${Color.grayHue4};
        border-color: ${Color.grayHue4};
        border-width: 1px;
      `;
    default:
      return null;
  }
};

const getSpacing = (props: IconButtonProps) =>
  props.spacing &&
  css`
    padding: ${spacing(props.spacing)};
  `;

export const IconButton = styled(MuiIconButton)<IconButtonProps>`
  &.MuiIconButton-root {
    padding: 0;
    ${getAppearance};
    ${getSpacing};
  }
`;
