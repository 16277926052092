interface LanguageOption {
  label: string;
  value: 'es' | 'en';
}

export const languagesOptions: LanguageOption[] = [
  {
    label: 'Spanish',
    value: 'es'
  },
  {
    label: 'English',
    value: 'en'
  }
];

export const translations = {
  en: {
    imageTitle: 'Learn what you can do to prevent type 2 diabetes',
    paragraph:
      "Watch the videos below to learn more about healthy lifestyle choices and decisions that decrease your risk for type 2 diabetes. These videos cover the same information shared in Intermountain's prediabetes 101 class."
  },
  es: {
    imageTitle: 'Aprende que puedes hace para prevenir la diabetes',
    paragraph:
      'Vea los videos a continuación para aprender más sobre alternativas para un estilo de vida saludable y decisiones que puede tomar para disminuir su riesgo de contraer la diabetes tipo 2. Estos videos cubren la misma información que se comparte en la clase de Intermountain para la prediabetes 101.'
  }
};
