import { useEffect, useRef } from 'react';

export function usePortal(
  element?: HTMLElement | null,
  onElement?: (el: HTMLElement | null) => void
) {
  const root = useRef<HTMLElement | null>(null);

  function createElement() {
    const el = document.createElement('div');
    return el;
  }

  function addElement(rootEl: HTMLElement) {
    if (document.body != null) {
      document.body.appendChild(rootEl);
    }
  }

  const setupElement = () => {
    root.current = element || createElement();
    if (!element) {
      if (onElement) {
        onElement(root.current);
      }
      addElement(root.current);
    }
  };

  const removeElement = () => {
    if (root.current != null && document.body != null) {
      document.body.removeChild(root.current);
    }
  };

  useEffect(() => {
    setupElement();
    return () => {
      removeElement();
    };
  }, []);

  return root.current;
}
