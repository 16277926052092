import { useMediaQuery } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { SearchButton, StyledIconButton } from 'components/AutoComplete/styled';
import SearchResultsList from 'components/cost/SearchResults/SearchResultsList';
import Spinner from 'components/UI/Spinner/Spinner';
import { Coordinate } from 'modules/types/common';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getSearchResults, selectSearchResult } from 'store/cost/actions';
import * as costSelectors from 'store/cost/selectors';
import { SearchTopic } from 'store/cost/types';
import { setGeoLocation } from 'store/findProvider/actions';
import { FILTER_CURRENT_LOCATION } from 'store/findProvider/constants';
import { getCurrentPosition } from 'store/geolocation/actions';
import { slcGeoCoords } from 'store/geolocation/constants';
import * as geoSelectors from 'store/geolocation/selectors';
import { RootState } from 'store/types';
import { dfdDefaultTheme, MuiBox } from 'theme/material-ui';
import { StyledInputBase, StyledMuiBox } from './styled';

interface Props {
  searchResults: ReturnType<typeof costSelectors.searchResultsSelector>;
  costEstimatorSearchText: ReturnType<typeof costSelectors.costEstimatorSearchTextSelector>;
  isFetching: ReturnType<typeof costSelectors.isFetchingSelector>;
  geolocationCoords?: Coordinates;
  dispatch: Function;
}

const CostSearch = ({
  isFetching,
  searchResults,
  dispatch,
  geolocationCoords,
  costEstimatorSearchText
}: Props) => {
  const [searchText, setSearchText] = useState(costEstimatorSearchText);
  const [locationText, setLocationText] = useState('');
  const isSmall = false;
  const isSmallScreen = useMediaQuery(dfdDefaultTheme.breakpoints.down('sm'));
  const history = useHistory();

  const setCurrentLocation = (coords?: Coordinate) => {
    if (coords) {
      setLocationText(FILTER_CURRENT_LOCATION);
      dispatch(setGeoLocation(coords));
    }
  };

  useEffect(() => {
    dispatch(getCurrentPosition());
  }, []);

  useEffect(() => {
    if (geolocationCoords) {
      setCurrentLocation(geolocationCoords);
    }
  }, [geolocationCoords]);

  useEffect(() => {
    if (!locationText && !geolocationCoords) {
      setCurrentLocation();
    }
  }, [locationText]);

  const handleSubmit = (e?: React.FormEvent) => {
    e?.preventDefault();

    const coords = geolocationCoords || slcGeoCoords;
    dispatch(getSearchResults(searchText, coords));
    history.push('/u/estimate-costs/find-services');
  };

  const handleButtonPress = (item: SearchTopic) => {
    dispatch(selectSearchResult(item));
    history.push('/u/estimate-costs/providers');
  };

  const showResults = (fetching: boolean, results: SearchTopic[]) => {
    if (fetching) {
      return (
        <MuiBox display="flex" justifyContent="center" mt={3}>
          <Spinner />
        </MuiBox>
      );
    }

    return (
      <MuiBox display="flex" flexDirection="column" mt={3}>
        <SearchResultsList
          results={results}
          onPress={(item: SearchTopic) => handleButtonPress(item)}
        />
      </MuiBox>
    );
  };

  // TODO: If search by location is added back in in the future uncomment this code
  // const renderStyledGeoLocation = () => (
  //   <StyledGeoLocation
  //     flex="1 1 auto"
  //     noOptionsText="no options"
  //     handleOnPress={(val: GeoCodeLocation) => {
  //       const { lat, lng } = val.geometry.location;
  //       const loc: Coordinate = {
  //         latitude: lat,
  //         longitude: lng
  //       };
  //       setCurrentLocation(loc);
  //       setLocationText(val.formatted_address);
  //     }}
  //     hideIcon
  //     placeholder={getAddressText(locationText)}
  //     width="auto"
  //     data-testid="geo-location-field"
  //   />
  // );

  const onEnterKeyPress = (e: React.KeyboardEvent<any>) => {
    if (e.charCode === 13) {
      e.preventDefault();
      handleSubmit();
    }
  };

  return (
    <MuiBox
      data-testid="search-for-services-parent"
      display="flex"
      flexDirection="column"
      pl={3}
      pr={3}
    >
      <StyledMuiBox mt={2}>
        <MuiBox display="flex" flexDirection="row">
          <StyledIconButton hidden={false}>
            <SearchIcon />
          </StyledIconButton>
          <StyledInputBase
            id="estimate-costs-search"
            data-testid="estimate-costs-search-field"
            placeholder="Enter your search term to find costs for services"
            value={searchText}
            onChange={e => setSearchText(e.target.value)}
            onKeyPress={onEnterKeyPress}
          />
          {/* TODO: If search by location is added back in in the future uncomment this code */}
          {/* {!isSmall && !isSmallScreen ? renderStyledGeoLocation() : null} */}
          {!isSmall && !isSmallScreen ? (
            <SearchButton data-testid="search-button" type="submit" onClick={handleSubmit}>
              Search
            </SearchButton>
          ) : null}
        </MuiBox>
      </StyledMuiBox>
      {/* TODO: If search by location is added back in in the future uncomment this code */}
      {/* {isSmall || isSmallScreen ? (
        <StyledMuiBox mt={2} paddingLeft="30px">
          {renderStyledGeoLocation()}
        </StyledMuiBox>
      ) : null} */}
      {isSmall || isSmallScreen ? (
        <StyledMuiBox mt={2} height="auto">
          <SearchButton
            data-testid="search-button"
            borderRadius="28px"
            type="submit"
            color="primary"
            onClick={handleSubmit}
          >
            Search
          </SearchButton>
        </StyledMuiBox>
      ) : null}

      {window.location.pathname === '/u/estimate-costs/find-services'
        ? showResults(isFetching, searchResults)
        : null}
    </MuiBox>
  );
};

const mapStateToProps = (state: RootState) => ({
  searchResults: costSelectors.searchResultsSelector(state),
  isFetching: costSelectors.isFetchingSelector(state),
  geolocationCoords: geoSelectors.geolocationCoordsSelector(state),
  geolocationAccepted: geoSelectors.geolocationAcceptedSelector(state),
  costEstimatorSearchText: costSelectors.costEstimatorSearchTextSelector(state)
});

export default connect(mapStateToProps)(CostSearch);
