import React, { useEffect } from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { MuiBox, MuiGrid, dfdDefaultTheme } from 'theme/material-ui';
import { Stepper, StepperProps } from 'components/UI/Stepper';
import { Color } from 'modules/styles/colors';
import { FontSize } from 'modules/styles/variables';
import useStyles from '../useStyles';
import { useConnectCareStepAction } from './ConnectCareStepAction';

function ConnectCareStepper<T>({ activeStep, steps, stepToValue, ...rest }: StepperProps<T>) {
  const classes = useStyles();
  const { setStepper } = useConnectCareStepAction();
  const isSmallScreen = useMediaQuery(dfdDefaultTheme.breakpoints.down('sm'));

  useEffect(() => {
    setStepper({ steps, activeStep });
  }, [steps, activeStep]);

  return (
    <MuiBox className={classes.steppersHeader}>
      {isSmallScreen ? (
        <MuiGrid container direction="row" spacing={2} alignItems="center">
          {steps.map((step, idx) => (
            <MuiGrid item key={stepToValue ? stepToValue(step) : idx}>
              <MuiBox
                bgcolor={idx <= activeStep ? Color.primary : 'transparent'}
                height={FontSize.small}
                width={FontSize.small}
                borderRadius={8}
                border={
                  idx <= activeStep
                    ? `2px solid ${Color.primary}`
                    : `2px solid ${Color.buttonDisabled}`
                }
              />
            </MuiGrid>
          ))}
        </MuiGrid>
      ) : (
        <Stepper activeStep={activeStep} steps={steps} {...rest} />
      )}
    </MuiBox>
  );
}

ConnectCareStepper.defaultProps = {
  stepToValue: (step: any) => (step ? step.path : '')
};

export default ConnectCareStepper;
