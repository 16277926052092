import { ListItem } from 'components/UI/ListItem/ListItem';
import { MuiButton, MuiTypography } from 'theme/material-ui';
import React from 'react';
import { toSentenceCase } from 'modules/utils/StringUtils';
import { PreventativeCareItem } from 'store/preventativeCare/types';
import { convertPreventiveCareTitle } from 'store/preventativeCare/utils';
import { isPreventiveCareItemDue } from 'lib/healthRecords/preventativeCare/utils';

interface PreventicareCareListItemProps {
  careItem: PreventativeCareItem;
  onPress: () => void;
}

export const PreventiveCareUpToDateListItem = ({ careItem }: PreventicareCareListItemProps) => (
  <ListItem
    title={careItem.displayText}
    action={<MuiTypography>{careItem.status}</MuiTypography>}
  />
);

export const PreventiveCareDueItem = ({ careItem, onPress }: PreventicareCareListItemProps) => {
  return (
    <ListItem
      body={convertPreventiveCareTitle(careItem.displayText)}
      action={
        <MuiButton color="primary" onClick={onPress}>
          {toSentenceCase(careItem.status)}
        </MuiButton>
      }
    />
  );
};

export const PreventativeCareListItem = ({ careItem, ...rest }: PreventicareCareListItemProps) => {
  if (isPreventiveCareItemDue(careItem)) {
    return <PreventiveCareDueItem {...rest} careItem={careItem} />;
  }

  return <PreventiveCareUpToDateListItem careItem={careItem} {...rest} />;
};
