import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { CustomTooltip, CustomDOBTooltip } from 'components/InfoPopper';
import ConfirmModal from 'components/UI/Modals/ConfirmModal';
import { Form, Formik } from 'formik';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AnyAction } from 'redux';
import { handleUrlOnExisting } from 'store/pageActions/RegisterAccount';
import {
  formatAlert,
  registrationStatuses,
  Step
} from 'store/pageActions/RegisterAccount/constants';
import {
  updateRegistrationAlert,
  updateRegistrationData,
  updateRegistrationMeta,
  updateRegistrationStep,
  validateConsumerHealthIds,
  validateConsumerId
} from 'store/register/actions';
import {
  registerMetaSelector,
  registerPersonalAbridgedSelector,
  registerSubmittingSelector,
  validateConsumerIdSelector
} from 'store/register/selectors';
import {
  AlertType,
  getInsuranceType,
  RegisterAlert,
  RegisterData,
  RegisterMeta
} from 'store/register/types';
import { getRegisterBrand } from 'store/register';
import { getBrandColor, getBrandLinkColor } from 'store/register/utils';
import { RootState } from 'store/types';
import * as Yup from 'yup';
import { CancelButton } from '../FormElements/CancelButton';
import CustomButton from '../FormElements/CustomButton';
import CustomDatepicker from '../FormElements/CustomDatepicker';
import CustomTextField from '../FormElements/CustomTextField';
import RequiredFieldsLegend from 'components/RequiredFieldsLegend';
import { MuiTypography } from 'theme/material-ui';
import { forgotUsername } from 'store/authentication/actions.web';
import TypographyInlineWrapper from 'components/UI/Typography/TypographyInLineWrapper';
import { USPhoneMaskRegex } from 'modules/utils/PhoneUtils';
import useStyles from '../useStyles';

export interface Props {
  initialValues: {
    firstName: string;
    lastName: string;
    dateOfBirth: string;
    email: string;
    registrationStatus: string;
    insuranceId: string;
  };
  updateRegister: (data: Partial<RegisterData>) => void;
  updateAlert: (data: RegisterAlert) => void;
  validateIds: () => Promise<AnyAction>;
  updateStep: (step: string) => void;
  updateRegisterMeta: (meta: RegisterMeta) => void;
  isSubmitting: boolean;
  meta: RegisterMeta;
  validateConsumerId: (payload: string) => Promise<AnyAction>;
  isAccountAlreadyExist: boolean;
}

const PersonalInfoSchema = Yup.object().shape({
  firstName: Yup.string()
    .required('Required')
    .max(60, 'Must be less than 60 characters'),
  lastName: Yup.string()
    .required('Required')
    .max(60, 'Must be less than 60 characters'),
  dateOfBirth: Yup.date()
    .typeError('Invalid Date')
    .required('Required')
    .test('DOB', 'Must be at least 18 years old to register.', (date: string) => {
      return moment().diff(moment(date), 'years') >= 18;
    }),
  email: Yup.string()
    .trim()
    .email('Must be a valid email address')
    .required('Required'),
  phone: Yup.string().matches(USPhoneMaskRegex, 'Phone must match format (XXX) XXX-XXXX')
});
const getInsuranceTypeText = (insuranceType: string) => {
  const InsuranceType = getInsuranceType();

  switch (insuranceType) {
    case InsuranceType.SH_SUBSCRIBER: {
      return InsuranceType.SH_SUBSCRIBER === 'Scripius'
        ? 'Enter Scripius Subscriber Number'
        : 'Enter Select Health Subscriber Number';
    }
    case InsuranceType.MEDICAID: {
      return 'Enter Medicaid Id';
    }
    default: {
      return '';
    }
  }
};

export const RegisterPersonalAbridgedComponent = ({
  initialValues,
  updateRegister,
  validateIds,
  updateStep,
  updateAlert,
  updateRegisterMeta,
  isSubmitting,
  meta,
  validateConsumerId,
  isAccountAlreadyExist
}: Props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const InsuranceType = getInsuranceType();

  const [showDialog, setShowDialog] = useState(false);
  useEffect(() => {
    if (isAccountAlreadyExist) {
      setShowDialog(true);
    }
  }, [isAccountAlreadyExist]);

  const handleSubmit = (values: Partial<RegisterData>) => {
    values.registrationStatus = registrationStatuses.SELECT_HEALTH;

    const metaData = {
      insuranceId: values.insuranceId
    };

    updateRegisterMeta(metaData);
    switch (meta.insuranceType) {
      case InsuranceType.SH_SUBSCRIBER: {
        values.shSubscriberNumber = values.insuranceId;
        break;
      }
      case InsuranceType.MEDICAID: {
        values.pharmacyPlanId = values.insuranceId;
        break;
      }
      default: {
        break;
      }
    }
    updateRegister(values);

    validateIds().then(res => {
      if (res.error) {
        updateAlert(
          formatAlert(
            AlertType.ERROR,
            'Please check your information and try again.',
            `We were unable to validate the combination of ${meta.insuranceType} id + name + date of birth.`
          )
        );
        updateStep(Step.INSURANCE);
      } else {
        const { consumerId } = res.payload.data;
        if (consumerId) {
          validateConsumerId(consumerId).then(resp => {
            if (resp.error || !resp.payload.data) {
              updateAlert(formatAlert());
              if (values.phone) {
                updateStep(Step.VERIFY_PHONE);
              } else {
                updateStep(Step.ACCOUNT);
              }
            }
          });
        }
      }
    });
  };

  const redirectBrand = getRegisterBrand();
  const classes = useStyles({ redirectBrand });
  const brandColor = getBrandColor({ redirectBrand });
  const brandLinkColor = getBrandLinkColor({ redirectBrand });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={PersonalInfoSchema}
      onSubmit={handleSubmit}
    >
      {({ isValid, dirty }) => {
        return (
          <Form>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <RequiredFieldsLegend />
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  data-testid="insurance-id-input"
                  name="insuranceId"
                  label="Insurance Identification Number"
                  required
                  placeholder={getInsuranceTypeText(meta.insuranceType)}
                  tooltip={<CustomTooltip />}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  data-testid="first-name-input"
                  name="firstName"
                  label="Legal First Name"
                  required
                  placeholder="Enter First Name"
                />
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  data-testid="last-name-input"
                  name="lastName"
                  label="Legal Last Name"
                  required
                  placeholder="Enter Last Name"
                />
              </Grid>
              <Grid item xs={12}>
                <CustomDatepicker
                  data-testid="date-of-birth-input"
                  name="dateOfBirth"
                  label="Date of Birth (Must be over 18 years)"
                  required
                  tooltip={<CustomDOBTooltip />}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  data-testid="email-input"
                  name="email"
                  label="Email Address"
                  required
                  placeholder="example@email.com"
                />
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  data-testid="phone-input"
                  name="phone"
                  mask="phone"
                  label="Mobile phone number"
                  placeholder="(000) 000-0000"
                />
              </Grid>
            </Grid>
            <Box my={3} maxWidth="xs" textAlign="center">
              <CustomButton
                type="submit"
                label="Next"
                disabled={!(isValid && dirty)}
                isSubmitting={isSubmitting}
                aria-label="submit-button"
              />
              <CancelButton />
            </Box>
            <ConfirmModal
              isOpen={showDialog}
              icon="ExclamationCircle"
              iconColor={brandColor}
              description={
                <MuiTypography align="center">
                  It appears that you already have an account associated with us, please click{' '}
                  <TypographyInlineWrapper
                    color={brandLinkColor}
                    onClick={() => dispatch(forgotUsername())}
                  >
                    Forgot Username
                  </TypographyInlineWrapper>{' '}
                  to retrieve account information.
                </MuiTypography>
              }
              confirmLabel="Sign In"
              onConfirm={() => dispatch(handleUrlOnExisting(history))}
              showCloseIcon
              cancelLabel="Cancel"
              onCancel={() => setShowDialog(false)}
              fullWidth
              maxWidth="400px"
              position="bottom"
              buttonConfirmClassName={classes.customContainedButton}
              buttonOutlinedClassName={classes.customOutlinedButton}
              buttonTextClassName={classes.customTextButton}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = (state: RootState) => ({
  initialValues: registerPersonalAbridgedSelector(state),
  isSubmitting: registerSubmittingSelector(state),
  meta: registerMetaSelector(state),
  isAccountAlreadyExist: validateConsumerIdSelector(state)
});

const mapDispatchToProps = {
  updateRegister: updateRegistrationData,
  updateStep: updateRegistrationStep,
  validateIds: validateConsumerHealthIds,
  updateAlert: updateRegistrationAlert,
  updateRegisterMeta: updateRegistrationMeta,
  validateConsumerId
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterPersonalAbridgedComponent);
