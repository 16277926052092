import FlexBox from 'components/UI/Layout/FlexBox';
import Svg from 'components/UI/Svg/Svg';
import { Color } from 'modules/styles/colors';
import { baseFontSize, Spacing } from 'modules/styles/variables';
import styled, { css } from 'styled-components';

interface Props {
  color?: string;
  fontSize?: number;
}

const fontStyles = (props: Props) => {
  return css`
    justify-content: center;
    font-size: ${props.fontSize || baseFontSize};
    color: ${props.color || Color.textLight};
  `;
};

export const CardContainer = styled(FlexBox)`
  padding: ${Spacing.large}px;
  margin-bottom: ${Spacing.large}px;
  background-color: ${Color.foreColor};
  border-radius: 1px;
  box-shadow: 1px 2px 4px ${Color.gray};
  cursor: pointer;
`;

export const CardInfoContainer = styled(FlexBox)`
  flex-direction: row;
`;

export const CardInfo = styled(FlexBox)`
  flex: 1 0 0px;
  justify-content: space-between;
`;

export const CardAvatar = styled(FlexBox)`
  flex: 1;
  flex-basis: 20%;
  margin-left: ${Spacing.small}px;
  align-items: flex-end;
`;

export const CardBodyContainer = styled(FlexBox)`
  flex: 1 0;
  margin-top: ${Spacing.large}px;
  padding-top: ${Spacing.large}px;
  border-top-color: ${Color.gray};
  border-top-width: 1px;
`;

export const CardHeadingContainer = styled(FlexBox)`
  flex-direction: row;
`;

export const CardHeading = styled(FlexBox)`
  margin-bottom: ${Spacing.small}px;
`;

export const CardSubTitle = styled(FlexBox)`
  flex-direction: row;
  align-items: center;
  margin-top: ${Spacing.medium}px;
`;

export const CardText = styled.div`
  ${fontStyles}
`;

export const HeadingText = styled(CardText)`
  margin-right: ${Spacing.medium}px;
`;

export const CardIcon = styled(Svg)`
  margin-right: ${Spacing.medium}px;
`;
