import React from 'react';

export default {
  svg: (
    <g>
      <g>
        <circle fill="#4A00E2" strokeWidth={0} cx={40} cy={40} r={38.5} />
        <path
          fill="#ffffff"
          strokeWidth={0}
          d="M40,2.5A37.5,37.5,0,1,1,2.5,40,37.5,37.5,0,0,1,40,2.5m0-2A39.5,39.5,0,1,0,79.5,40,39.5,39.5,0,0,0,40,.5Z"
        />
        <g>
          <path
            fill="#4A00E2"
            strokeWidth={0}
            d="M21.4,72.5l3.1-1-.6-.7,7.2-1.5h2.1V65.3h0c-8.7-3.7-9.5-13.3-9.7-17.4a3.3,3.3,0,0,1-2.4-3.2v-3c-.8-3.8-1.7-10.8.1-17.3A13.4,13.4,0,0,1,20,16.5a1.4,1.4,0,0,1,2.2-.8c1.4,1.1,2.5.9,4.2-.2a14,14,0,0,1,3.3-2.4l2.8-2c8.7-6,16.3-2.9,20.1,2,11.6,6.2,10.1,22.2,8.7,28.6v3a3.3,3.3,0,0,1-2.4,3.2c-.2,4.1-1,13.7-9.7,17.4v3.9h2.1l7.2,1.5-.6.7,1.6.5a37.5,37.5,0,1,0-57-32A37.3,37.3,0,0,0,21.4,72.5Z"
          />
          <polygon
            fill="#d1d3d4"
            strokeWidth={0}
            points="49.2 70.6 49.5 70.7 51 69.3 51 69.2 49.2 69.2 49.2 70 49.2 70.6"
          />
          <polygon
            fill="#d1d3d4"
            strokeWidth={0}
            points="32.9 70.7 33.2 70.6 33.2 69.2 31.4 69.2 31.4 69.3 31.4 69.3 32.9 70.7"
          />
          <path fill="#f7cdb9" strokeWidth={0} d="M41.2,66.8h0Z" />
          <path fill="#f7cdb9" strokeWidth={0} d="M45.8,66.4h0Z" />
          <path fill="#f7cdb9" strokeWidth={0} d="M44.3,66.6h0Z" />
          <path fill="#f7cdb9" strokeWidth={0} d="M41.2,66.8h0Z" />
          <path fill="#f7cdb9" strokeWidth={0} d="M48.4,65.6Z" />
          <polygon
            fill="#f7cdb9"
            strokeWidth={0}
            points="33.2 65.3 33.2 65.3 33.2 65.3 33.2 65.3"
          />
          <path fill="#f7cdb9" strokeWidth={0} d="M46.9,66.1h0Z" />
          <path fill="#f7cdb9" strokeWidth={0} d="M39.2,66.7h0Z" />
          <path
            fill="#f7cdb9"
            strokeWidth={0}
            d="M31.6,52.7c1.6-.7,5.2-1.3,9.6-1.3v-.7h-4a70.5,70.5,0,0,1,4-12.7V30.3c-5.6,1.5-10.7,1.1-14.6-.8a141.9,141.9,0,0,0-1.1,16c-.1,4.7,1.3,5.4,3.3,6.9A2.8,2.8,0,0,0,31.6,52.7Zm-.7-13.3a2.6,2.6,0,0,1,5.2-.2c.1.2,0,.3,0,.5v.2a2.6,2.6,0,1,1-5.2.2v-.7Z"
          />
          <path fill="#f7cdb9" strokeWidth={0} d="M34.1,65.6h0Z" />
          <path
            fill="#f7cdb9"
            strokeWidth={0}
            d="M21.6,43.4a12.2,12.2,0,0,1-.5-1.7v3a3.3,3.3,0,0,0,2.4,3.2,9.5,9.5,0,0,0-.2-1.8A9.6,9.6,0,0,1,21.6,43.4Z"
          />
          <path fill="#f7cdb9" strokeWidth={0} d="M36,66.2h0Z" />
          <path fill="#f7cdb9" strokeWidth={0} d="M37.5,66.5h0Z" />
          <path
            fill="#f7cdb9"
            strokeWidth={0}
            d="M49.2,70.6V70l-16-2.9v3.5h-.3l7.5,6.8h1.7l7.4-6.7Z"
          />
          <path fill="#7fa1cc" strokeWidth={0} d="M56,73.9A39,39,0,0,0,59.5,72l-1.6-.5Z" />
          <path fill="#7fa1cc" strokeWidth={0} d="M56,73.9A39,39,0,0,0,59.5,72l-1.6-.5Z" />
          <path fill="#7fa1cc" strokeWidth={0} d="M51.1,69.3Z" />
          <path fill="#7fa1cc" strokeWidth={0} d="M56,73.9A39,39,0,0,0,59.5,72l-1.6-.5Z" />
          <path fill="#7fa1cc" strokeWidth={0} d="M31.3,69.3h0Z" />
          <path fill="#7fa1cc" strokeWidth={0} d="M56,73.9A39,39,0,0,0,59.5,72l-1.6-.5Z" />
          <path fill="#7fa1cc" strokeWidth={0} d="M21.4,72.5a32,32,0,0,0,6.2,2.9l-3.1-3.9Z" />
          <path
            fill="#f7cdb9"
            strokeWidth={0}
            d="M52.7,24.8a34.5,34.5,0,0,1-11.5,5.5V38a70.5,70.5,0,0,0-4,12.7h4v.7h0c4.4,0,8,.6,9.6,1.3a3,3,0,0,0,2.9-.3c1.9-1.5,3.3-2.2,3.2-6.9-.2-9.6-1.1-17.7-2.5-22.6A5.1,5.1,0,0,1,52.7,24.8ZM51.5,39.9a2.6,2.6,0,1,1-5.2.2v-.7a2.6,2.6,0,0,1,5.2-.2c.1.2,0,.3,0,.5Z"
          />
          <path
            fill="#eea484"
            strokeWidth={0}
            d="M52.7,24.8a34.5,34.5,0,0,1-11.5,5.5V38a70.5,70.5,0,0,0-4,12.7h4v.7h0c4.4,0,8,.6,9.6,1.3a3,3,0,0,0,2.9-.3c1.9-1.5,3.3-2.2,3.2-6.9-.2-9.6-1.1-17.7-2.5-22.6A5.1,5.1,0,0,1,52.7,24.8ZM51.5,39.9a2.6,2.6,0,1,1-5.2.2v-.7a2.6,2.6,0,0,1,5.2-.2c.1.2,0,.3,0,.5Z"
          />
          <path
            fill="#eea484"
            strokeWidth={0}
            d="M59.1,46.1a9.5,9.5,0,0,0-.2,1.8,3.3,3.3,0,0,0,2.4-3.2v-3c-.2.7-.3,1.3-.4,1.7A11.8,11.8,0,0,1,59.1,46.1Z"
          />
          <path
            fill="#eea484"
            strokeWidth={0}
            d="M59.1,46.1a9.5,9.5,0,0,0-.2,1.8,3.3,3.3,0,0,0,2.4-3.2v-3c-.2.7-.3,1.3-.4,1.7A11.8,11.8,0,0,1,59.1,46.1Z"
          />
          <path
            fill="#f7cdb9"
            strokeWidth={0}
            d="M49.2,70V65.3l-.8.3h0l-1.5.5h-.4l-.7.2H36.6l-.6-.2h-.5l-1.4-.5H34l-.8-.3h0v1.8Z"
          />
          <g>
            <path
              fill="#eea484"
              strokeWidth={0}
              d="M49.2,70V65.3l-.8.3h0l-1.5.5h-.4l-.7.2H36.6l-.6-.2h-.5l-1.4-.5H34l-.8-.3h0v1.8Z"
            />
          </g>
          <g>
            <path
              fill="#ffffff"
              strokeWidth={0}
              d="M31.4,69.3h-.3l-7.2,1.5.6.7,3.1,3.9A38.9,38.9,0,0,0,40,77.5h.4l-7.5-6.8Z"
            />
            <path
              fill="#ffffff"
              strokeWidth={0}
              d="M58.5,70.8l-7.2-1.5H51l-1.5,1.4-7.4,6.7A35.6,35.6,0,0,0,56,73.9l1.9-2.4Z"
            />
          </g>
          <path
            fill="#f7cdb9"
            strokeWidth={0}
            d="M33.6,42.6a2.5,2.5,0,0,0,2.5-2.7v-.2a2.6,2.6,0,0,1-2.5,2.2,2.5,2.5,0,0,1-2.7-2.3v.5A2.5,2.5,0,0,0,33.6,42.6Z"
          />
          <path
            fill="#efa585"
            strokeWidth={0}
            d="M33.6,42.6a2.5,2.5,0,0,0,2.5-2.7v-.2a2.6,2.6,0,0,1-2.5,2.2,2.5,2.5,0,0,1-2.7-2.3v.5A2.5,2.5,0,0,0,33.6,42.6Z"
          />
          <path
            fill="#3c2417"
            strokeWidth={0}
            d="M33.6,41.9a2.6,2.6,0,0,0,2.5-2.2c0-.2.1-.3,0-.5a2.6,2.6,0,0,0-5.2.2v.2A2.5,2.5,0,0,0,33.6,41.9Zm.8-2.8c-.3.1-.6-.2-.7-.6a.7.7,0,0,1,.7-.7.8.8,0,0,1,.7.7A.7.7,0,0,1,34.4,39.1Z"
          />
          <path
            fill="#ffffff"
            strokeWidth={0}
            d="M34.4,37.8a.7.7,0,0,0-.7.7c.1.4.4.7.7.6a.7.7,0,0,0,.7-.6A.8.8,0,0,0,34.4,37.8Z"
          />
          <path
            fill="#f7cdb9"
            strokeWidth={0}
            d="M49,41.9a2.5,2.5,0,0,1-2.7-2.3v.5a2.6,2.6,0,1,0,5.2-.2v-.2A2.6,2.6,0,0,1,49,41.9Z"
          />
          <path
            fill="#f6ccb8"
            strokeWidth={0}
            d="M49,41.9a2.5,2.5,0,0,1-2.7-2.3v.5a2.6,2.6,0,1,0,5.2-.2v-.2A2.6,2.6,0,0,1,49,41.9Z"
          />
          <path
            // stroke="#e68460"
            fill="#e68460"
            strokeWidth={0}
            d="M49,41.9a2.5,2.5,0,0,1-2.7-2.3v.5a2.6,2.6,0,1,0,5.2-.2v-.2A2.6,2.6,0,0,1,49,41.9Z"
          />
          <path
            fill="#3c2417"
            strokeWidth={0}
            d="M48.8,36.7a2.5,2.5,0,0,0-2.5,2.7v.2A2.5,2.5,0,0,0,49,41.9a2.6,2.6,0,0,0,2.5-2.2c0-.2.1-.3,0-.5A2.5,2.5,0,0,0,48.8,36.7Zm1,2.4c-.3.1-.6-.2-.7-.6a.7.7,0,0,1,.7-.7.8.8,0,0,1,.7.7A.7.7,0,0,1,49.8,39.1Z"
          />
          <path
            fill="#ffffff"
            strokeWidth={0}
            d="M49.8,37.8a.7.7,0,0,0-.7.7c.1.4.4.7.7.6a.7.7,0,0,0,.7-.6A.8.8,0,0,0,49.8,37.8Z"
          />
          <path
            fill="#3c2417"
            strokeWidth={0}
            d="M52.6,13.1c-3.8-4.9-11.4-8-20.1-2l-2.8,2a14,14,0,0,0-3.3,2.4c-1.7,1.1-2.8,1.3-4.2.2a1.4,1.4,0,0,0-2.2.8,13.4,13.4,0,0,0,1.2,7.9c-1.8,6.5-.9,13.5-.1,17.3a12.2,12.2,0,0,0,.5,1.7,9.6,9.6,0,0,0,1.7,2.7,9.5,9.5,0,0,1,.2,1.8c.2,4.1,1,13.7,9.7,17.4h0l.8.3h.1l1.4.5H36l.6.2h9.2l.7-.2h.4l1.5-.5h0l.8-.3c8.7-3.7,9.5-13.3,9.7-17.4a9.5,9.5,0,0,1,.2-1.8,11.8,11.8,0,0,0,1.8-2.7c.1-.4.2-1,.4-1.7C62.7,35.3,64.2,19.3,52.6,13.1ZM45.8,56.6A6.9,6.9,0,0,1,41.2,58a6.9,6.9,0,0,1-4.6-1.4.3.3,0,0,1,.2-.5h8.8A.3.3,0,0,1,45.8,56.6Zm7.9-4.2a3,3,0,0,1-2.9.3c-1.6-.7-5.2-1.3-9.6-1.3h0c-4.4,0-8,.6-9.6,1.3a2.8,2.8,0,0,1-2.8-.3c-2-1.5-3.4-2.2-3.3-6.9a141.9,141.9,0,0,1,1.1-16c3.9,1.9,9,2.3,14.6.8a34.5,34.5,0,0,0,11.5-5.5,5.1,5.1,0,0,0,1.7-1.9c1.4,4.9,2.3,13,2.5,22.6C57,50.2,55.6,50.9,53.7,52.4Z"
          />
          <path
            fill="#f7cdb9"
            strokeWidth={0}
            d="M41.2,58a6.9,6.9,0,0,0,4.6-1.4.3.3,0,0,0-.2-.5H36.8a.3.3,0,0,0-.2.5A6.9,6.9,0,0,0,41.2,58Z"
          />
        </g>
      </g>
    </g>
  ),
  viewBox: '0 0 80 80'
};
