import { Spacing } from 'modules/styles/variables';
import styled, { css } from 'styled-components';
import { Color } from 'modules/styles/colors';

interface Props {
  middle?: boolean;
  spaced?: boolean;
  light?: boolean;
}

const middleStyles = (props: Props) =>
  props.middle &&
  css`
    margin-left: ${Spacing.xLarge}px;
    margin-right: ${Spacing.xLarge}px;
  `;

const spacingStyles = (props: Props) =>
  props.spaced &&
  css`
    margin-top: ${Spacing.large}px;
    margin-bottom: ${Spacing.large}px;
  `;

export const Divider = styled.div<Props>`
  height: 1px;
  background-color: ${props => (props.color ? props.color : Color.grayLight)};
  ${middleStyles};
  ${spacingStyles};
`;

export const VerticalDivider = styled.div`
  width: 1px;
  height: 100%;
  background-color: ${Color.grayLight};
`;
