import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import get from 'lodash/get';

import { Formik } from 'formik';
import { Grid } from '@material-ui/core';
import { MuiMenuItem, MuiTypography } from 'theme/material-ui';
import FormHelperText from '@material-ui/core/FormHelperText';

import Spacer from 'components/UI/Layout/Spacer';
import FlexBox from 'components/UI/Layout/FlexBox';
import RequiredLabel from 'components/UI/Labels/RequiredLabel';
import CustomTextField from 'screens/Register/FormElements/CustomTextField';

import { CovidTitles } from 'lib/constants/covidScreening';
import { convertToLowerKabobCase } from 'modules/utils/StringUtils';
import {
  InsuranceValidateSchema,
  RELATIONSHIP_TYPE,
  InsuranceInformationData,
  RelationshipOptionsType
} from 'modules/constants/covidScreening/insuranceFound';
import { ButtonsNavigation, CovidTestGuestFooter } from 'screens/CovidTestGuest/components';

import { SelectRelationSuscriber } from './styled';
import { useLanguageSwitcher } from 'lib/hooks/useLanguageSwitcher';
import { translations } from 'lib/constants/translations/screens/covidGuest/covidInsurance';

interface CovidTestInsuranceFormProps {
  initialValues: InsuranceInformationData;
  onPreviousClicked: (dataForm: InsuranceInformationData) => void;
  saveDataForm: (dataForm: InsuranceInformationData) => void;
}

export default function CovidTestInsuranceForm({
  initialValues,
  onPreviousClicked,
  saveDataForm
}: CovidTestInsuranceFormProps) {
  const history = useHistory();
  const screenText = useLanguageSwitcher(translations);
  const [dropdownSelected, setDropdownSelected] = useState(false);

  const handleNext = values => {
    const navigateTo =
      values?.Q_Patient_relationship_to_subscriber === 'Self'
        ? '/guest-home/covid-screen/guest-covid-insurance-upload'
        : '/guest-home/covid-screen/guest-subscriber-information';
    saveDataForm(values);
    history.push(navigateTo);
  };

  return (
    <Formik
      enableReinitialize
      validateOnMount
      initialValues={initialValues}
      validationSchema={InsuranceValidateSchema(screenText)}
      onSubmit={values => handleNext(values)}
    >
      {({ values, isValid, errors, touched, handleChange, handleBlur, handleSubmit }) => {
        const getFormError = (errorKey: string, touchKey?: string) => {
          const touchedValue = get(touched, touchKey || errorKey);
          const errorValue = get(errors, errorKey);

          return touchedValue && errorValue ? errorValue : null;
        };

        return (
          <>
            <Spacer size="small" />

            <MuiTypography fontWeight="bold">{screenText.instructions}</MuiTypography>

            <Spacer size="small" />

            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <RequiredLabel labelText={screenText.field_1} />
                <CustomTextField
                  name="memberId"
                  variant="outlined"
                  placeholder={screenText.field_1}
                  data-testid="new-insurance-memberid"
                  value={values?.MEMBER_NUMBER}
                  error={!!getFormError('MEMBER_NUMBER' as keyof typeof values)}
                  onChange={handleChange('MEMBER_NUMBER')}
                  onBlur={handleBlur('MEMBER_NUMBER')}
                  helperText={getFormError('MEMBER_NUMBER' as keyof typeof values)}
                  required
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <RequiredLabel hideIndicator labelText={screenText.field_2} />
                <CustomTextField
                  name="groupid"
                  variant="outlined"
                  placeholder={screenText.field_2}
                  data-testid="new-insurance-groupid"
                  value={values?.GROUP_NUMBER}
                  error={!!getFormError('GROUP_NUMBER' as keyof typeof values)}
                  onChange={handleChange('GROUP_NUMBER')}
                  onBlur={handleBlur('GROUP_NUMBER')}
                  helperText={getFormError('GROUP_NUMBER' as keyof typeof values)}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <FlexBox width="100%">
                  <FlexBox flexDirection="row" justifyContent="flex-start" alignItems="center">
                    <RequiredLabel labelText={screenText.field_3} />
                  </FlexBox>
                  <SelectRelationSuscriber
                    name="relationship"
                    variant="outlined"
                    data-testid="new-insurance-relationship"
                    value={values?.Q_Patient_relationship_to_subscriber}
                    onChange={handleChange('Q_Patient_relationship_to_subscriber')}
                    onClose={() => setDropdownSelected(true)}
                    error={dropdownSelected && !!errors?.Q_Patient_relationship_to_subscriber}
                  >
                    {RELATIONSHIP_TYPE.map((option: RelationshipOptionsType, index: Number) => (
                      <MuiMenuItem
                        style={{ whiteSpace: 'normal' }}
                        data-testid={convertToLowerKabobCase(
                          option.value,
                          '-relationship-to-subscriber'
                        )}
                        key={option.label}
                        value={option.value}
                      >
                        {screenText[`dropdown_item_${index}`]}
                      </MuiMenuItem>
                    ))}
                  </SelectRelationSuscriber>
                  {dropdownSelected && !!errors?.Q_Patient_relationship_to_subscriber ? (
                    <FormHelperText style={{ marginLeft: 4 }} error>
                      {errors?.Q_Patient_relationship_to_subscriber}
                    </FormHelperText>
                  ) : null}
                </FlexBox>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <RequiredLabel labelText={screenText.field_4} />
                <CustomTextField
                  name="firstname"
                  variant="outlined"
                  placeholder={screenText.field_4}
                  data-testid="new-insurance-first-name"
                  value={values?.SUBSCRIBER_FIRST_NAME_ON_CARD}
                  error={!!getFormError('SUBSCRIBER_FIRST_NAME_ON_CARD')}
                  onChange={handleChange('SUBSCRIBER_FIRST_NAME_ON_CARD')}
                  onBlur={handleBlur('SUBSCRIBER_FIRST_NAME_ON_CARD')}
                  helperText={getFormError('SUBSCRIBER_FIRST_NAME_ON_CARD' as keyof typeof values)}
                  required
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <RequiredLabel hideIndicator labelText={screenText.field_5} />
                <CustomTextField
                  name="middlename"
                  variant="outlined"
                  placeholder={screenText.field_5}
                  data-testid="new-insurance-middle-name"
                  value={values?.SUBSCRIBER_MIDDLE_NAME_ON_CARD}
                  error={!!getFormError('SUBSCRIBER_MIDDLE_NAME_ON_CARD' as keyof typeof values)}
                  onChange={handleChange('SUBSCRIBER_MIDDLE_NAME_ON_CARD')}
                  onBlur={handleBlur('SUBSCRIBER_MIDDLE_NAME_ON_CARD')}
                  helperText={getFormError('SUBSCRIBER_MIDDLE_NAME_ON_CARD' as keyof typeof values)}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <RequiredLabel labelText={screenText.field_6} />
                <CustomTextField
                  name="lastname"
                  variant="outlined"
                  placeholder={screenText.field_6}
                  data-testid="new-insurance-last-name"
                  value={values?.SUBSCRIBER_LAST_NAME_ON_CARD}
                  error={!!getFormError('SUBSCRIBER_LAST_NAME_ON_CARD')}
                  onChange={handleChange('SUBSCRIBER_LAST_NAME_ON_CARD')}
                  onBlur={handleBlur('SUBSCRIBER_LAST_NAME_ON_CARD')}
                  helperText={getFormError('SUBSCRIBER_LAST_NAME_ON_CARD' as keyof typeof values)}
                  required
                />
              </Grid>
            </Grid>

            <Spacer size="medium" />

            <ButtonsNavigation
              cancelTestId="insurance-previous-button"
              nextTestId="insurance-next-button"
              disabledNextButton={!isValid}
              onCancelClick={() => onPreviousClicked(values)}
              onNextClick={handleSubmit}
            />

            <CovidTestGuestFooter title={CovidTitles.EXISTING_INSURANCE_FOUND} />
          </>
        );
      }}
    </Formik>
  );
}
