import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CovidScreeningInsuranceCardSelector } from 'store/CovidScreening/selectors';
import { InsuranceCard } from 'store/CovidScreening/types';

export type CardFace = InsuranceCard | undefined;

interface Card {
  back: CardFace;
  front: CardFace;
}

export default function useCovidInsuranceUpload() {
  const insuranceCard = useSelector(CovidScreeningInsuranceCardSelector);
  const [card, setCard] = useState<Card>({
    back: undefined,
    front: undefined
  });

  useEffect(() => {
    if (insuranceCard?.length) {
      updateCard({
        back: insuranceCard?.[1],
        front: insuranceCard?.[0]
      });
    }
  }, [insuranceCard]);

  const updateCard = (newCard: Card) => {
    setCard(newCard);
  };

  return {
    card,
    updateCard
  };
}
