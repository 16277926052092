import React from 'react';
import { connect } from 'react-redux';
import { MuiBox, MuiTypography } from 'theme/material-ui';
import analyticsService, { AmplitudeEventData, AnalyticsEvent } from 'services/AnalyticsService';

import LinkingServices from 'services/LinkingServices';
import { technicalHelpNumber } from 'modules/constants/phoneNumbers';
import { Color } from 'modules/styles/colors';
import { Spacing } from 'modules/styles/variables';
import { RootState } from 'store/types';
import * as routerSelectors from 'store/router/selectors';

export interface Props {
  currentUrl: string;
  referringUrl: string;
}

export const ProxyFormFooter = ({ currentUrl, referringUrl }: Props) => {
  const handleCall = () => {
    LinkingServices.callPhoneNumber(technicalHelpNumber);
    const eventData: AmplitudeEventData = {
      currentUrl,
      referringUrl,
      phone_number: technicalHelpNumber,
      support_call_type: 'Minor Proxy Add'
    };
    analyticsService.logEvent(AnalyticsEvent.SupportCalled, eventData);
  };

  return (
    <MuiBox display="flex" justifyContent="center" paddingBottom={Spacing.small}>
      <MuiTypography>
        If you have any questions, please call &nbsp;
        <MuiTypography
          display="inline"
          aria-label="Call Technical Support button"
          color={Color.link}
          onClick={handleCall}
        >
          {technicalHelpNumber}
        </MuiTypography>
      </MuiTypography>
    </MuiBox>
  );
};

const mapStateToProps = (state: RootState) => ({
  currentUrl: routerSelectors.currentLocationPathNameSelector(state),
  referringUrl: routerSelectors.previousLocationPathNameSelector(state)
});

export default connect(mapStateToProps)(ProxyFormFooter);
