import React from 'react';
import { MuiBox, MuiGrid, MuiTypography } from 'theme/material-ui';
import { useWindowSize } from 'hooks/useWindowSize';
import { FontSize, FontWeight } from 'modules/styles/variables';
import { Color } from 'modules/styles/colors';

export interface HealthToolsPictureBannerProps {
  pictureBannerSrc: string;
  titleBanner: string;
  titleBannerStrong: string;
  dataTestId: string;
}

function HealthToolsPictureBanner({
  pictureBannerSrc,
  titleBanner,
  titleBannerStrong,
  dataTestId
}: HealthToolsPictureBannerProps) {
  const smallDeviceWidth = 750;
  const deviceWidth = useWindowSize().width || window.innerWidth;
  const isSmallDevice = deviceWidth < smallDeviceWidth;

  return (
    <MuiGrid container>
      <MuiGrid item xs={isSmallDevice ? 12 : 6}>
        <img src={pictureBannerSrc} alt="" height="100%" width="100%"></img>
      </MuiGrid>
      <MuiGrid
        item
        style={{ display: 'flex', backgroundColor: Color.white, width: '100%' }}
        alignItems="center"
        xs={isSmallDevice ? 12 : 6}
      >
        <MuiBox pl={2} pr={2}>
          <MuiTypography
            fontSize={FontSize.mediumHeading}
            color={Color.primary}
            data-testid={dataTestId}
            style={{ display: 'inline' }}
          >
            {titleBanner}
            <MuiTypography
              fontSize={FontSize.largeHeading}
              fontWeight={FontWeight.bold}
              color={Color.primary}
              data-testid={dataTestId}
              style={{ display: 'inline' }}
            >
              {titleBannerStrong}
            </MuiTypography>
          </MuiTypography>
        </MuiBox>
      </MuiGrid>
    </MuiGrid>
  );
}
export default HealthToolsPictureBanner;
