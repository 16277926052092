import { ItemBody } from 'components/billing/AmountSummaryTable';
import AbsoluteSpinner from 'components/common/AbsoluteSpinner/AbsoluteSpinner';
import Divider from 'components/UI/Divider';
import { FlexBoxColumn, FlexBoxRow } from 'components/UI/Layout/FlexBox';
import { Color } from 'modules/styles/colors';
import Spacer from 'components/UI/Layout/Spacer';
import Typography from 'components/UI/Typography';
import DefaultText from 'components/UI/Typography/DefaultText';
import CollapsibleList from 'components/common/CollapsibleList/CollapsibleList';
import CollapsibleListHeader from 'components/common/CollapsibleList/CollapsibleListHeader';
import { RTABasicInfo, RTABreakdown } from 'lib/dashboard/types';
import { getRTADetails } from 'lib/dashboard/utils';
import { RTA_BREAKDOWN } from 'lib/constants/dashboard';
import { FontSize, FontWeight } from 'modules/styles/variables';
import React, { useEffect, useState } from 'react';
import { FlatList } from 'react-native';
import { useParams } from 'react-router-dom';
import { MuiBox, MuiCard, MuiGrid } from 'theme/material-ui';
import {
  BillingDetailMarginView,
  BottomDivider,
  BottomDividerContainer,
  ViewDrillDownFlexBox
} from '../styled';
import { useLowPriorityActionItems } from 'hooks/useDashboardPriorityItems';

export const RTADetail = () => {
  const { id } = useParams<{ id: string }>();
  const [basicInfo, setBasicInfo] = useState<RTABasicInfo | null>(null);
  const [rtaSummary, setRtaSummary] = useState<ItemBody[] | null>([]);
  const [rtaBreakdownDetails, setRtaBreakdownDetails] = useState<RTABreakdown | null>(null);
  const { lowPriorityData, lowPriorityErrors, lowPriorityLoading } = useLowPriorityActionItems();

  useEffect(() => {
    if (lowPriorityData?.rtas) {
      const { basicInfoDetails, rtaInfoDetails, rtaBreakdown } = getRTADetails(
        lowPriorityData?.rtas,
        id
      );
      setBasicInfo(basicInfoDetails);
      setRtaSummary(rtaInfoDetails);
      setRtaBreakdownDetails(rtaBreakdown);
    }
  }, [lowPriorityData]);

  if (lowPriorityLoading) return <AbsoluteSpinner />;
  // NOTE: pending bills expire after 10 days, so if a user were to bookmark this page,
  // it wouldn't be able to fetch details in the future, this code safeguards it from crashing
  if (!!lowPriorityErrors?.length || !lowPriorityData?.rtas || !basicInfo || !rtaSummary)
    return null;

  return (
    <MuiBox m={6}>
      <MuiCard>
        <BillingDetailMarginView>
          <MuiGrid container spacing={3}>
            <MuiGrid item xs={12} lg={6} sm={12} data-testid="bill-detail-info">
              <Typography fontSize={FontSize.heading} fontWeight={FontWeight.bold}>
                {basicInfo.provider}
              </Typography>
              <Spacer size="xxsmall" />
              <Typography>{basicInfo.date}</Typography>
              <Spacer size="xxsmall" />
              <Typography>{basicInfo.patientName}</Typography>
              <Spacer size="xxsmall" />
              <Typography>{basicInfo.patientId}</Typography>
              <Spacer size="xxsmall" />
              <Typography>{basicInfo.encounterId}</Typography>
              <Spacer size="xxsmall" />
            </MuiGrid>
          </MuiGrid>
          <Spacer size="xsmall" />
          <Divider />
          <Spacer size="xsmall" />
          <Typography fontWeight={FontWeight.bold} fontSize={FontSize.large}>
            Summary
          </Typography>
          <Spacer size="xxsmall" />
          <FlatList
            data-testid="bill-detail-summary"
            accessibilityLabel="Bill Detail"
            data={rtaSummary}
            keyExtractor={(itemBody: ItemBody) => `${itemBody.left}`}
            renderItem={({ item: itemBody }) => (
              <>
                <FlexBoxRow justifyContent="space-between">
                  <FlexBoxColumn>
                    <BottomDivider>{itemBody.left}</BottomDivider>
                  </FlexBoxColumn>

                  <FlexBoxColumn flex="1 1 auto">
                    <BottomDividerContainer></BottomDividerContainer>
                  </FlexBoxColumn>

                  <FlexBoxColumn>
                    <DefaultText fontWeight={FontWeight.bold} textAlign="right">
                      {itemBody.right}
                    </DefaultText>
                  </FlexBoxColumn>
                </FlexBoxRow>
                <Spacer size="xxsmall" />
              </>
            )}
          />
          <Spacer size="medium" />

          <MuiBox>
            <CollapsibleList>
              <CollapsibleListHeader
                title={RTA_BREAKDOWN.rtaItemOne.titleText}
                subtitle={`${RTA_BREAKDOWN.rtaItemOne.subtitleText} $${rtaBreakdownDetails?.totalChargeAmount}`}
                headerColor={Color.offBlack}
                headerWeight={FontWeight.bold}
              >
                {rtaBreakdownDetails?.claimLineInformation?.map((lineItem: any) => (
                  <>
                    <ViewDrillDownFlexBox>
                      <FlexBoxRow justifyContent="space-between">
                        <FlexBoxColumn>
                          <Typography>{lineItem?.procedureCode}</Typography>
                        </FlexBoxColumn>

                        <FlexBoxColumn>
                          <Typography>{lineItem?.procedureCodeDescription}</Typography>
                        </FlexBoxColumn>

                        <FlexBoxColumn>
                          <Typography>{`$${lineItem?.claimLineChargeAmount}`}</Typography>
                        </FlexBoxColumn>
                      </FlexBoxRow>
                    </ViewDrillDownFlexBox>
                    <Divider />
                  </>
                ))}
                <ViewDrillDownFlexBox>
                  <FlexBoxRow justifyContent="space-between">
                    <FlexBoxColumn>
                      <Typography>{RTA_BREAKDOWN.rtaItemOne.subtitleText}</Typography>
                    </FlexBoxColumn>

                    <FlexBoxColumn>
                      <Typography>{`$${rtaBreakdownDetails?.totalChargeAmount}`}</Typography>
                    </FlexBoxColumn>
                  </FlexBoxRow>
                </ViewDrillDownFlexBox>
              </CollapsibleListHeader>
            </CollapsibleList>

            <Divider />

            <CollapsibleList>
              <CollapsibleListHeader
                title={RTA_BREAKDOWN.rtaItemTwo.titleText}
                headerColor={Color.offBlack}
                headerWeight={FontWeight.bold}
              >
                <ViewDrillDownFlexBox>
                  <FlexBoxRow justifyContent="space-between">
                    <FlexBoxColumn>
                      <Typography>{RTA_BREAKDOWN.rtaItemTwo.subtitleText}</Typography>
                    </FlexBoxColumn>

                    <FlexBoxColumn>
                      <Typography>{`$${rtaBreakdownDetails?.totalPayableAmount}`}</Typography>
                    </FlexBoxColumn>
                  </FlexBoxRow>
                </ViewDrillDownFlexBox>
                <Divider />
                <ViewDrillDownFlexBox>
                  <FlexBoxRow justifyContent="space-between">
                    <FlexBoxColumn>
                      <Typography>{RTA_BREAKDOWN.rtaItemTwo.subtitleTwoText}</Typography>
                    </FlexBoxColumn>

                    <FlexBoxColumn>
                      <Typography>{`$${rtaBreakdownDetails?.totalAdjustmentAmount}`}</Typography>
                    </FlexBoxColumn>
                  </FlexBoxRow>
                </ViewDrillDownFlexBox>
              </CollapsibleListHeader>
            </CollapsibleList>

            <Divider />

            <CollapsibleList>
              <CollapsibleListHeader
                title={RTA_BREAKDOWN.rtaItemThree.titleText}
                subtitle={`${RTA_BREAKDOWN.rtaItemThree.subtitleText} $${rtaBreakdownDetails?.totalEligibleAmount}`}
                headerColor={Color.offBlack}
                headerWeight={FontWeight.bold}
              >
                <ViewDrillDownFlexBox>
                  {rtaBreakdownDetails?.externalReferenceNo ? (
                    <FlexBoxRow justifyContent="space-between">
                      <FlexBoxColumn>
                        <Typography>{`Claim ${rtaBreakdownDetails?.externalReferenceNo}`}</Typography>
                        <Typography>{rtaBreakdownDetails?.CLAIM_PLAN_NAME}</Typography>
                      </FlexBoxColumn>

                      <FlexBoxColumn>
                        <Typography>{`$${rtaBreakdownDetails?.totalEligibleAmount}`}</Typography>
                      </FlexBoxColumn>
                    </FlexBoxRow>
                  ) : (
                    <FlexBoxRow justifyContent="center">
                      <Typography>{RTA_BREAKDOWN.noClaims}</Typography>
                    </FlexBoxRow>
                  )}
                </ViewDrillDownFlexBox>
              </CollapsibleListHeader>
            </CollapsibleList>
          </MuiBox>
        </BillingDetailMarginView>
      </MuiCard>
    </MuiBox>
  );
};

export default RTADetail;
