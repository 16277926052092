import { Alert } from 'components/Alert';
import CallToActionRow from 'components/common/CallToActionRow/CallToActionRow';
import DownloadErrorSnackbar from 'components/DownloadErrorSnackbar/DownloadErrorSnackbar';
import Banner from 'components/UI/Banner/Banner';
import Divider from 'components/UI/Divider';
import Spacer from 'components/UI/Layout/Spacer';
import FlexBox from 'components/UI/Layout/FlexBox';
import SpacedSpinner from 'components/UI/Spinner/SpacedSpinner';
import Svg from 'components/UI/Svg/Svg';
import dayjs from 'dayjs';
import { useTestResultFocused } from 'hooks/useTestResultFocused';
import React, { ComponentType, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { NavigationScreenProps } from 'screens/navigation';
import ResultDocumentHandler from 'screens/TestResults/ResultDocumentHandler';
import { currentAccountConsumerSelector } from 'store/account/selectors';
import { Consumer } from 'store/profile/types';
import { AnalyticsEvent } from 'services/AnalyticsService';
import {
  askTestResultsQuestion,
  downloadImagingDetails,
  downloadLabDetails,
  downloadMicrobiologyDetails,
  downloadPathologyDetails
} from 'store/testResults/actions';
import * as testResults from 'store/testResults/selectors.web';
import {
  LabDetails,
  TestResult,
  TestResultCategory,
  TestResultDetails,
  TestResultFocused
} from 'store/testResults/types';
import { RootState, RootDispatch } from 'store/types';
import { MuiBox, MuiPaper, MuiContainer, MuiAlert, MuiTypography } from 'theme/material-ui';
import { FontSize, FontWeight } from 'modules/styles/variables';
import { Color } from 'modules/styles/colors';
import { MhpDetails, Nullable } from 'modules/types/common';
import Lab from './Lab/Lab';
import Microbiology from './Microbiology/Microbiology';
import { patchEventBySourceId } from 'store/events/actions';
import useNavigationAnalytics from 'hooks/useNavigationAnalytics';
import { StackContainer, TestResultContent } from './styled';
import { objectFromQsParam } from 'modules/utils/UrlUtils';
import useBreadCrumbs from 'hooks/useBreadCrumbs';

export interface Props extends NavigationScreenProps {
  askQuestion: (testResult: TestResultFocused, isShare?: boolean, returnBackTo?: string) => void;
  currentAccountConsumer: Consumer;
  downloadImaging: (eventId: string, source: TestResult | TestResultFocused) => void;
  downloadLabs: (testResult: TestResult | TestResultFocused) => void;
  downloadMicrobiology: (eventId: string, source: TestResult | TestResultFocused) => void;
  downloadPathology: (eventId: string, source: TestResult | TestResultFocused) => void;
  isDownloading: boolean;
  downloadError: string | null;
  patchEvent: (sourceId: string) => void;
  testResultDetails: TestResultDetails;
  testResultDetailsError: Nullable<Error>;
  testResultDetailsLoading: boolean;
  encryptionSettings: {
    password: string;
    algorithm: string;
  };
}

const { PATHOLOGY, IMAGING, MICROBIOLOGY, LAB } = TestResultCategory;

export const TestResultsComponent = (props: Props) => {
  const {
    currentAccountConsumer,
    isDownloading,
    downloadLabs,
    downloadMicrobiology,
    downloadPathology,
    downloadImaging,
    downloadError,
    askQuestion,
    testResultDetails,
    testResultDetailsError,
    testResultDetailsLoading,
    location,
    match,
    patchEvent
  } = props;
  const { updateLastBreadCrumb } = useBreadCrumbs();
  const { id: eventId = '', mhpDetails: mhpDetailsEncoded = '' } = match?.params || {};
  const customInfo = (testResultDetails?.content as LabDetails)?.customInformation;
  const shareTestResultsClicked = useNavigationAnalytics(AnalyticsEvent.ShareTestResultsClicked, {
    eventId
  });
  const [downloadRetryFn, setDownloadRetryFn] = useState(() => () => {});

  const downloadTestResultsClicked = useNavigationAnalytics(
    AnalyticsEvent.DownloadTestResultsClicked,
    { eventId }
  );

  const externalLinkClicked = useNavigationAnalytics(AnalyticsEvent.ExternalLinkClicked, {
    source: customInfo?.url
  });

  const {
    data: testResultFocused,
    loading: testResultFocusedLoading,
    error: testResultFocusedError
  } = useTestResultFocused({
    eventId,
    mhpDetailsEncoded,
    mhpDetails: mhpDetailsEncoded
      ? (objectFromQsParam(mhpDetailsEncoded) as MhpDetails)
      : undefined,
    testResultForceFocus: location?.state?.state?.result
  });
  const errorText = 'An error occurred retrieving test results';
  const errorTextDetails = 'An error occurred retrieving test result details';

  useEffect(() => {
    if ((location && !location?.state) || location?.state?.state?.result?.isRead === false) {
      // if Deep link or refresh or not read.
      patchEvent(eventId);
    }
  }, [location?.state?.result?.isRead]);

  useEffect(() => {
    updateLastBreadCrumb(testResultFocused?.name || '');
  }, [testResultFocused?.name]);

  if (testResultFocusedLoading) {
    return (
      <FlexBox
        width="100%"
        alignItems="center"
        justifyContent="center"
        data-testid="test-result-loading"
      >
        <SpacedSpinner />
      </FlexBox>
    );
  }

  if (testResultFocusedError) {
    return (
      <MuiBox p={3} flex={1} data-testid="test-result-error">
        <MuiContainer maxWidth="md">
          <MuiBox>{errorText}</MuiBox>
        </MuiContainer>
      </MuiBox>
    );
  }

  if (!testResultFocused) {
    return (
      <MuiBox p={3} flex={1} data-testid="test-result-has-no-result">
        <MuiContainer maxWidth="md">
          <MuiBox>{errorText}</MuiBox>
        </MuiContainer>
      </MuiBox>
    );
  }

  const patientDisplayName = currentAccountConsumer?.displayName || '';
  const providersMap = testResultFocused?.providerNames || [];
  const providers = providersMap.length ? providersMap.join(', ') : undefined;

  const renderCTARow = () => (
    <MuiBox>
      <CallToActionRow
        isPatientToolbar
        isDownloading={isDownloading}
        justifyContent="flex-end"
        onReplyPress={handlReplyToTestResult}
        onDownloadPress={handleDownloadTestResults}
        onTransmitPress={handleShareTestResult}
      />
    </MuiBox>
  );

  const renderContent = () => {
    if (testResultDetailsLoading) {
      return (
        <FlexBox
          width="100%"
          alignItems="center"
          justifyContent="center"
          data-testid="test-result-loading"
        >
          <SpacedSpinner />
        </FlexBox>
      );
    }

    if (testResultDetailsError) {
      return (
        <FlexBox
          width="100%"
          alignItems="center"
          justifyContent="center"
          data-testid="test-result-loading"
        >
          <MuiBox p={2}>
            <MuiBox>{errorTextDetails}</MuiBox>
          </MuiBox>
        </FlexBox>
      );
    }

    if (testResultFocused.category === IMAGING || testResultFocused.category === PATHOLOGY) {
      return <ResultDocumentHandler testResultFocused={testResultFocused} eventId={eventId} />;
    }

    if (testResultFocused.category === MICROBIOLOGY) {
      return <Microbiology testResultFocused={testResultFocused} eventId={eventId} />;
    }

    if (testResultFocused.category === LAB) {
      return <Lab testResultFocused={testResultFocused} eventId={eventId} />;
    }

    return <div>TBD</div>;
  };

  const handleShareTestResult = () => {
    shareTestResultsClicked();

    if (!testResultFocused) {
      Alert.alert('Unable to create reply');
      return;
    }

    askQuestion(testResultFocused, true, location.pathname);
  };

  const handleDownloadTestResults = () => {
    downloadTestResultsClicked();

    switch (testResultFocused.category) {
      case LAB:
        setDownloadRetryFn(() => () => downloadLabs(testResultFocused));
        downloadLabs(testResultFocused);
        break;
      case MICROBIOLOGY:
        setDownloadRetryFn(() => () => downloadMicrobiology(eventId, testResultFocused));
        downloadMicrobiology(eventId, testResultFocused);
        break;
      case IMAGING:
        setDownloadRetryFn(() => () => downloadImaging(eventId, testResultFocused));
        downloadImaging(eventId, testResultFocused);
        break;
      case PATHOLOGY:
        setDownloadRetryFn(() => () => downloadPathology(eventId, testResultFocused));
        downloadPathology(eventId, testResultFocused);
        break;
      default:
    }
  };

  const handlReplyToTestResult = () => {
    if (!testResultFocused) {
      Alert.alert('Unable to create reply');
      return;
    }
    askQuestion(testResultFocused, false, location.pathname);
  };

  const getImageByCategory = (category: TestResultCategory | string | undefined) => {
    if (category === IMAGING) return 'TestResultsImaging';
    if (category === LAB) return 'TestResultsLab';
    if (category === MICROBIOLOGY) return 'TestResultsMicrobiology';
    if (category === PATHOLOGY) return 'TestResultsPathology';
    return '';
  };

  return (
    <>
      <DownloadErrorSnackbar hasError={!!downloadError} onRetry={downloadRetryFn} />
      <MuiBox flexGrow={1} bgcolor={Color.baseColor}>
        <div data-testid="test-result">
          <Banner message="Test Results" />
          {renderCTARow()}
          <Divider />
          <TestResultContent>
            <MuiBox component={MuiPaper} p={3} elevation={0} bgcolor={Color.baseColor}>
              <StackContainer>
                <MuiBox flexGrow={4} display="flex" flexDirection="column">
                  <MuiBox data-testid="test-name" paddingLeft="20px">
                    <MuiTypography fontSize={FontSize.heading} fontWeight={FontWeight.bold}>
                      {testResultFocused.name}
                    </MuiTypography>
                  </MuiBox>
                  <Divider spaced />
                  <MuiBox paddingLeft="20px">
                    <MuiTypography data-testid="health-banner-patient-name">
                      Patient: {patientDisplayName}
                    </MuiTypography>
                    <MuiTypography>Test Type: {testResultFocused.category}</MuiTypography>
                    <MuiTypography>
                      Collection Date/Time:{' '}
                      {dayjs(testResultFocused.testDate).format('DD MMMM, YYYY')}
                    </MuiTypography>
                  </MuiBox>
                </MuiBox>

                <MuiBox paddingLeft="5%" margin="auto">
                  <Svg
                    set="assets"
                    name={getImageByCategory(testResultFocused.category)}
                    size={120}
                  />
                </MuiBox>
              </StackContainer>
            </MuiBox>
            <Spacer size="small" />
            <MuiBox>
              {customInfo?.text && customInfo?.url ? (
                <a
                  target="_blank"
                  onClick={() => externalLinkClicked()}
                  href={customInfo?.url}
                  rel="noopener noreferrer"
                >
                  <MuiBox display="flex" justifyContent="center" width="100%">
                    <MuiAlert
                      style={{
                        width: '100%',
                        border: `2px solid ${Color.warning}`
                      }}
                      severity="warning"
                    >
                      <MuiTypography>
                        {customInfo?.text} <span style={{ color: Color.link }}>Learn More</span>
                      </MuiTypography>
                    </MuiAlert>
                  </MuiBox>
                </a>
              ) : null}
            </MuiBox>
            {/* @TODO: to be continued when provider image and location is available from the service call */}
            <MuiBox component={MuiPaper} p={2} elevation={0} bgcolor={Color.baseColor}>
              {!!providers ? (
                <>
                  <MuiBox display="flex" flexDirection="row" alignItems="center">
                    {/* @Note: provider Image url not available */}
                    {/* <MuiBox padding="6px" borderRadius={50}>
                  <Avatar size="large" src="" />
                </MuiBox> */}
                    <MuiBox paddingLeft="10px">
                      <MuiTypography>
                        Provider: <b>{providers}</b>
                      </MuiTypography>
                      {/* @Note: Location not available */}
                      {/* <MuiTypography>Family Medicine Clinic | Ogden Clinic</MuiTypography> */}
                    </MuiBox>
                  </MuiBox>
                  <Divider spaced />
                </>
              ) : null}
              <MuiBox>
                <MuiTypography style={{ fontStyle: 'italic' }}>
                  You may be seeing your results before your provider has reviewed them. These test
                  results could include sensitive information. If you have any questions about your
                  result contact the ordering provider for interpretation. Do not change or adjust
                  treatment plan, always follow your providers instruction until further advised.
                </MuiTypography>
              </MuiBox>
            </MuiBox>
            <Spacer size="small" />
            <MuiBox>{renderContent()}</MuiBox>
          </TestResultContent>
        </div>
      </MuiBox>
    </>
  );
};

const mapDispatchToProps = (dispatch: RootDispatch) => ({
  downloadLabs: (testResult: TestResultFocused) => dispatch(downloadLabDetails(testResult)),
  downloadMicrobiology: (eventId: string, testResult: TestResultFocused) =>
    dispatch(downloadMicrobiologyDetails(eventId, testResult)),
  downloadImaging: (eventId: string, testResult: TestResultFocused) =>
    dispatch(downloadImagingDetails(eventId, testResult)),
  downloadPathology: (eventId: string, testResult: TestResultFocused) =>
    dispatch(downloadPathologyDetails(eventId, testResult)),
  askQuestion: (testResult: TestResultFocused, isShare?: boolean, returnBackTo?: string) =>
    dispatch(askTestResultsQuestion({ testResult }, isShare, returnBackTo)),
  patchEvent: (sourceId: string) => dispatch(patchEventBySourceId(sourceId, { read: true }))
});

const mapStateToProps = (state: RootState) => ({
  currentAccountConsumer: currentAccountConsumerSelector(state),
  isDownloading: testResults.downloadingTestResultsSelector(state),
  downloadError: testResults.downloadTestResultsErrorSelector(state),
  testResultDetails: testResults.testResultDetailsDataSelector(state),
  testResultDetailsError: testResults.testResultDetailsErrorSelector(state),
  testResultDetailsLoading: testResults.testResultDetailsLoadingSelector(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(TestResultsComponent as ComponentType);
