import { headerHeight } from 'components/AuthLayout/styled';
import { animated } from 'react-spring';
import styled from 'styled-components';
import { dfdDefaultTheme, MuiAlert, MuiBox } from 'theme/material-ui';

export const SnackContainer = animated(MuiBox);

export const SnacksContainer = styled(MuiBox)`
  display: flex;
  flex-direction: column;
  height: auto;
  width: auto;
  max-height: 100%;
  max-width: 100%;
  position: absolute;
  z-index: ${dfdDefaultTheme.zIndex.snackbar};
  top: ${dfdDefaultTheme.spacing(2) + headerHeight}px;
  right: ${dfdDefaultTheme.spacing(2)}px;
`;

export const AlertStyled = styled(MuiAlert)<{ backgroundColor: string }>`
  &.MuiAlert-root {
    background-color: ${props => props.backgroundColor};
  }
  & > .MuiAlert-icon {
    opacity: 1;
  }
`;
