import { makeStyles, createStyles } from '@material-ui/core/styles';
import { headerHeight } from 'components/AuthLayout/styled';
import { spacings } from 'modules/utils/StylesUtils';
import { Color } from 'modules/styles/colors';

interface UseStylesProps {
  row?: boolean;
  spacing?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
}

const BACKGROUND = Color.white;
const BORDER = '1px solid rgba(0,0,0,0.1)';

export default makeStyles(theme => {
  const stepperBase: React.CSSProperties = {
    width: '100%',
    height: 'auto',
    backgroundColor: BACKGROUND
  };

  return createStyles({
    steppersHeader: {
      ...stepperBase,
      padding: spacings(3, 2),
      borderBottom: BORDER
    },
    steppersFooter: {
      ...stepperBase,
      position: 'absolute',
      bottom: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderTop: BORDER,
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      '& > button': {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1)
      }
    },
    formControl: {
      display: 'flex'
    },
    formLabel: {
      transform: 'translate(14px, -6px) scale(0.75)',
      transformOrigin: 'top left',
      padding: 0,
      position: 'absolute',
      top: 0,
      left: 0
    },
    radioGroup: (props?: UseStylesProps) => ({
      flexDirection: props?.row ? 'row' : 'column',
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2)
    }),
    formHelperText: {
      color: theme.palette.error.main,
      marginRight: theme.spacing(2),
      maringLeft: theme.spacing(2)
    },
    svgText: {
      fill: theme.palette.primary.contrastText,
      fontSize: theme.typography.caption.fontSize,
      fontFamily: theme.typography.fontFamily
    },
    bullet: (props?: UseStylesProps) => ({
      display: 'inline-block',
      transform: 'scale(1.2)',
      marginRight: props?.spacing ? theme.spacing(props.spacing) : 1,
      marginLeft: props?.spacing ? theme.spacing(props.spacing) : 1
    }),
    snackbarContainer: {
      display: 'flex',
      flexDirection: 'column',
      position: 'absolute',
      zIndex: theme.zIndex.snackbar,
      height: 'auto',
      width: 'auto',
      maxHeight: '100%',
      maxWidth: '100%',
      top: theme.spacing(1) + headerHeight,
      right: theme.spacing(2)
    },
    ratingLabel: {
      marginBottom: theme.spacing(4)
    },
    rating: {
      '& .MuiSlider-valueLabel': {
        top: -26,
        '& > span': {
          width: 24,
          height: 24
        }
      }
    }
  });
});
