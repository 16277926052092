import React from 'react';
import Spinner from 'components/UI/Spinner/Spinner';
import SupergraphicBackground from '../SupergraphicBackground/SupergraphicBackground';
import { MuiBox } from 'theme/material-ui';

function LoadingSplash() {
  return (
    <SupergraphicBackground>
      <MuiBox display="flex" alignItems="center" justifyContent="center" height="100%">
        <Spinner />
      </MuiBox>
    </SupergraphicBackground>
  );
}

export default LoadingSplash;
