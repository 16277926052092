import React from 'react';

export default {
  svg: (
    <g>
      <path
        d="M50.9525 3.3794C50.9525 2.2744 51.1205 0.5254 49.8995 0.0683999V0.000399947H54.4535V0.0683999C53.2545 0.5254 53.3995 2.3014 53.3995 3.3794V12.4164C53.3995 13.4974 53.2545 15.2484 54.4535 15.7044V15.7784H49.8995V15.7044C51.0975 15.2744 50.9525 13.4974 50.9525 12.4164V3.3794Z"
        fill="#fff"
      />
      <path
        d="M57.3314 4.7471C57.8584 4.7471 58.4324 4.7211 58.9394 4.4341H59.0114V6.2791C59.8494 5.1281 60.8314 4.4341 62.3924 4.4341C63.7334 4.4341 65.2684 4.9641 66.0364 6.0891C66.7064 7.0481 66.6354 8.5341 66.6354 9.6581V13.1401C66.6354 14.2401 66.4914 15.1031 67.5464 15.7041V15.7781H63.5404V15.7041C64.5984 15.1031 64.4544 14.2161 64.4544 13.1401V9.9031C64.4544 7.3351 63.7334 6.0161 61.5504 6.0161C59.3704 6.0161 59.0114 7.4791 59.0114 8.8941V13.1401C59.0114 14.2401 58.8654 15.1031 59.9194 15.7041V15.7781H55.9144V15.7041C56.9734 15.1031 56.8274 14.2161 56.8274 13.1161V7.4091C56.8274 6.3071 56.9734 5.4211 55.9144 4.8191V4.7471H57.3314Z"
        fill="#fff"
      />
      <path
        d="M72.4153 11.1245C72.4153 12.0345 72.1253 14.5075 74.6883 14.5075C75.0253 14.5075 75.3383 14.3845 75.6483 14.2665L74.3793 15.9665C74.1413 16.0395 73.8773 16.0855 73.6113 16.0855C69.8973 16.0855 70.2293 12.2535 70.2293 11.3165V6.3275H68.6023V5.4685C70.8563 5.0825 71.4553 3.4075 71.4553 1.1705H72.4153V4.7475H74.6673C74.9763 4.7475 75.3383 4.7475 75.5793 4.6035H75.6483V6.7855H75.5793C75.1943 6.2795 74.4733 6.3275 73.8533 6.3275H72.4153V11.1245Z"
        fill="#fff"
      />
      <path
        d="M85.3362 8.4859C85.0722 6.8089 83.9722 6.0159 82.2432 6.0159C80.6372 6.0159 79.4392 6.9749 79.1502 8.4859H85.3362ZM79.0562 9.8759C79.1042 12.8029 81.1412 14.5069 83.8492 14.5069C85.2902 14.5069 86.6782 14.0969 87.7822 13.2359H87.8562L86.6572 15.4879C85.6252 15.9209 84.4752 16.0859 83.3472 16.0859C79.2702 16.0859 76.4642 13.5249 76.4642 10.1889C76.4642 6.8599 78.8862 4.4339 82.4612 4.4339C85.7232 4.4339 87.9502 6.7849 87.8562 9.8759H79.0562Z"
        fill="#fff"
      />
      <path
        d="M90.7791 4.7471C91.3311 4.7471 91.9081 4.7211 92.2431 4.4341H92.3151V6.2601C92.8421 5.1761 93.9431 4.4341 95.1451 4.4341L96.0041 4.5061V6.7141C95.7411 6.4241 95.3101 6.3271 94.9041 6.3271C93.0831 6.3271 92.3151 7.2881 92.3151 9.7561V13.1161C92.3151 14.2161 92.1711 15.1031 93.2251 15.7041V15.7781H89.2191V15.7041C90.2791 15.1031 90.1331 14.2161 90.1331 13.1161V7.3831C90.1331 6.2791 90.2791 5.4211 89.2451 4.8191V4.7471H90.7791Z"
        fill="#fff"
      />
      <path
        d="M99.3118 4.7471C99.8368 4.7471 100.414 4.7211 100.797 4.4341H100.869V6.3271C101.709 5.0331 102.644 4.4341 104.248 4.4341C105.952 4.4341 107.102 5.1031 107.99 6.5191C108.926 5.2251 110.053 4.4341 111.828 4.4341C115.232 4.4341 116.118 6.3821 116.118 9.4241V13.1161C116.118 14.1461 115.977 15.3231 117.174 15.7051V15.7781H113.026V15.7051C114.082 15.1031 113.938 14.2161 113.938 13.1161V10.0231C113.938 7.5751 113.168 6.0161 111.252 6.0161C109.09 6.0161 108.494 7.3831 108.494 9.6371V13.1161C108.494 14.2161 108.35 15.1031 109.408 15.7051V15.7781H105.399V15.7051C106.456 15.1031 106.312 14.2161 106.312 13.1161V9.8051C106.312 7.2881 105.066 6.0161 103.581 6.0161C101.755 6.0161 100.869 7.1441 100.869 9.4471V13.1161C100.869 14.2161 100.725 15.0801 101.755 15.7041V15.7781H97.7768V15.7041C98.8318 15.1031 98.6848 14.2161 98.6848 13.1161V7.3831C98.6848 6.2791 98.8318 5.4211 97.7768 4.8191V4.7471H99.3118Z"
        fill="#fff"
      />
      <path
        d="M128.274 10.645C128.274 8.317 127.028 6.016 124.461 6.016C122.231 6.016 120.795 7.772 120.795 9.924C120.795 12.129 122.114 14.507 124.558 14.507C126.762 14.507 128.274 12.803 128.274 10.645ZM118.373 10.358C118.373 6.764 121.273 4.434 124.728 4.434C126.257 4.434 127.58 4.843 128.729 5.852C129.955 6.926 130.697 8.51 130.697 10.143C130.697 13.593 127.817 16.085 124.439 16.085C121.154 16.085 118.373 13.763 118.373 10.358Z"
        fill="#fff"
      />
      <path
        d="M140.818 4.7471C141.439 4.7471 142.181 4.8431 142.615 4.4341H142.685V13.1631C142.685 14.2671 142.542 15.1541 143.597 15.7051V15.7781H140.506V14.2161C139.638 15.5361 138.73 16.0851 137.052 16.0851C134.222 16.0851 132.881 14.4801 132.881 11.1011V7.4091C132.881 6.3071 133.018 5.4211 131.968 4.8191V4.7471H133.525C134.054 4.7471 134.652 4.7211 134.99 4.4341H135.061V10.6921C135.061 12.9001 135.684 14.2671 137.769 14.2671C139.353 14.2671 140.506 12.9731 140.506 11.1241V7.4311C140.506 6.3071 140.648 5.4401 139.59 4.8191V4.7471H140.818Z"
        fill="#fff"
      />
      <path
        d="M146.283 4.7471C146.81 4.7471 147.387 4.7211 147.891 4.4341H147.961V6.2791C148.801 5.1281 149.785 4.4341 151.343 4.4341C152.683 4.4341 154.222 4.9641 154.986 6.0881C155.656 7.0481 155.586 8.5341 155.586 9.6581V13.1401C155.586 14.2401 155.442 15.1031 156.498 15.7051V15.7781H152.497V15.7051C153.551 15.1031 153.404 14.2161 153.404 13.1401V9.9031C153.404 7.3351 152.683 6.0141 150.502 6.0141C148.32 6.0141 147.961 7.4791 147.961 8.8941V13.1401C147.961 14.2401 147.818 15.1031 148.873 15.7051V15.7781H144.867V15.7051C145.922 15.1031 145.783 14.2161 145.783 13.1161V7.4091C145.783 6.3071 145.922 5.4211 144.867 4.8161V4.7471H146.283Z"
        fill="#fff"
      />
      <path
        d="M160.886 11.1245C160.886 12.0345 160.6 14.5075 163.163 14.5075C163.498 14.5075 163.81 14.3845 164.121 14.2665L162.849 15.9665C162.612 16.0395 162.348 16.0855 162.086 16.0855C158.368 16.0855 158.702 12.2535 158.702 11.3165V6.3275H157.074V5.4685C159.328 5.0825 159.928 3.4075 159.928 1.1705H160.886V4.7475H163.14C163.45 4.7475 163.81 4.7475 164.052 4.6035H164.121V6.7855H164.052C163.67 6.2795 162.949 6.3275 162.324 6.3275H160.886V11.1245Z"
        fill="#fff"
      />
      <path
        d="M175.033 16.0855C173.449 15.9925 171.863 15.5125 171.863 13.5935V9.8055C171.17 10.1635 170.355 10.3585 169.567 10.5495C168.412 10.8145 167.119 11.0775 167.119 12.5385C167.119 14.0285 168.461 14.5075 169.732 14.5075C170.308 14.5075 170.885 14.3355 171.459 14.1685L170.047 15.9665C169.637 16.0395 169.204 16.0855 168.801 16.0855C166.375 16.0855 164.7 14.5545 164.7 12.5635C164.7 8.1065 171.863 10.1635 171.863 7.5525C171.863 6.4025 170.454 6.0135 169.567 6.0135C168.344 6.0135 167.385 6.4695 166.305 6.9755L167.907 4.5765L169.468 4.4345C172.057 4.4345 174.024 5.1755 174.048 8.0785V11.6745C174.048 13.5705 174.097 14.2875 176.085 14.5075L175.033 16.0855Z"
        fill="#fff"
      />
      <path
        d="M178.556 3.3062C177.667 3.3062 176.999 2.6092 176.999 1.7232C176.999 0.8842 177.765 0.3082 178.556 0.3082C179.368 0.3082 179.995 1.0072 179.995 1.7992C179.995 2.5902 179.368 3.3062 178.556 3.3062ZM178.099 4.7472C178.654 4.7472 179.251 4.7212 179.661 4.3612H179.732V13.1162C179.732 14.2162 179.585 15.1032 180.643 15.7052V15.7782H176.639V15.7052C177.691 15.1032 177.548 14.2162 177.548 13.1162V7.3832C177.548 6.2792 177.691 5.4212 176.639 4.8162V4.7472H178.099Z"
        fill="#fff"
      />
      <path
        d="M183.686 4.7471C184.212 4.7471 184.789 4.7211 185.293 4.4341H185.365V6.2791C186.205 5.1281 187.187 4.4341 188.744 4.4341C190.087 4.4341 191.623 4.9641 192.392 6.0881C193.063 7.0481 192.989 8.5341 192.989 9.6581V13.1401C192.989 14.2401 192.846 15.1031 193.9 15.7051V15.7781H189.899V15.7051C190.952 15.1031 190.806 14.2161 190.806 13.1401V9.9011C190.806 7.3351 190.087 6.0141 187.908 6.0141C185.723 6.0141 185.365 7.4791 185.365 8.8941V13.1401C185.365 14.2401 185.221 15.1031 186.277 15.7051V15.7781H182.275V15.7051C183.327 15.1031 183.183 14.2161 183.183 13.1161V7.4091C183.183 6.3071 183.327 5.4211 182.275 4.8161V4.7471H183.686Z"
        fill="#fff"
      />
      <path
        d="M20.2239 4.2974C20.2239 3.1384 21.1629 2.1954 22.3269 2.1954C23.4849 2.1954 24.4219 3.1384 24.4219 4.2974C24.4219 5.4624 23.4849 6.4014 22.3269 6.4014C21.1629 6.4014 20.2239 5.4624 20.2239 4.2974Z"
        fill="#fff"
      />
      <path
        d="M22.3138 30.7725C22.5028 30.7725 22.6638 30.6395 22.7218 30.4545L28.2238 4.6335L28.2248 4.6135L28.2308 4.5605C28.2308 4.2865 28.0278 4.0665 27.7748 4.0665C27.6118 4.0665 27.4678 4.1625 27.3878 4.3025L22.7618 11.1005L22.7458 11.1155C22.6398 11.2445 22.4828 11.3295 22.3078 11.3295H22.3368C22.1638 11.3295 22.0068 11.2445 21.8998 11.1155L21.8818 11.1005L17.2588 4.3025C17.1788 4.1625 17.0368 4.0665 16.8688 4.0665C16.6178 4.0665 16.4128 4.2865 16.4128 4.5605L16.4218 4.6135V4.6335L21.9248 30.4545C21.9828 30.6395 22.1428 30.7725 22.3328 30.7725H22.3138Z"
        fill="#fff"
      />
      <path
        d="M25.1443 27.6577C25.2573 27.7297 25.3963 27.7017 25.4883 27.6017L37.1993 11.4217L37.2073 11.4107L37.2293 11.3807C37.3113 11.1997 37.2553 10.9737 37.1053 10.8807C37.0053 10.8187 36.8883 10.8277 36.7973 10.8917L31.9463 13.6337L31.9293 13.6387C31.8263 13.6837 31.7093 13.6787 31.6063 13.6157L31.6223 13.6237C31.5163 13.5587 31.4453 13.4457 31.4213 13.3207L31.4193 13.3037L32.2693 0.3437C32.2643 0.2197 32.2063 0.1027 32.1093 0.0427C31.9563 -0.0523 31.7683 0.0167 31.6843 0.1977L31.6713 0.2347L31.6673 0.2487L25.0083 27.3037C24.9873 27.4467 25.0443 27.5937 25.1543 27.6637L25.1443 27.6577Z"
        fill="#fff"
      />
      <path
        d="M19.2303 27.6577C19.1193 27.7297 18.9803 27.7017 18.8893 27.6017L7.1773 11.4217L7.1693 11.4107L7.1493 11.3807C7.0653 11.1997 7.1203 10.9737 7.2723 10.8807C7.3713 10.8187 7.4873 10.8277 7.5793 10.8917L12.4313 13.6337L12.4453 13.6387C12.5493 13.6837 12.6673 13.6787 12.7713 13.6157L12.7533 13.6237C12.8603 13.5587 12.9303 13.4457 12.9543 13.3207L12.9573 13.3037L12.1073 0.3437C12.1113 0.2197 12.1683 0.1027 12.2683 0.0427C12.4203 -0.0523 12.6103 0.0167 12.6923 0.1977L12.7023 0.2347L12.7093 0.2487L19.3693 27.3037C19.3913 27.4467 19.3323 27.5937 19.2223 27.6637L19.2303 27.6577Z"
        fill="#fff"
      />
      <path
        d="M32.3343 21.48C32.3903 21.568 32.4873 21.594 32.5773 21.556L44.2423 15.271L44.2513 15.264L44.2743 15.249C44.3813 15.155 44.4093 14.984 44.3343 14.869C44.2883 14.791 44.2093 14.758 44.1303 14.767L40.1383 14.949L40.1283 14.948C40.0453 14.942 39.9723 14.9 39.9193 14.82L39.9253 14.829C39.8753 14.748 39.8643 14.65 39.8813 14.555L39.8833 14.54L41.8293 8.844C41.8613 8.757 41.8573 8.659 41.8093 8.584C41.7383 8.467 41.5903 8.444 41.4823 8.542L41.4663 8.562L41.4573 8.57L32.3473 21.189C32.2923 21.279 32.2853 21.401 32.3393 21.488L32.3343 21.48Z"
        fill="#fff"
      />
      <path
        d="M12.0413 21.48C11.9883 21.568 11.8883 21.594 11.7993 21.556L0.1343 15.271L0.1253 15.264L0.1023 15.249C-0.00469999 15.155 -0.0327 14.984 0.0423 14.869C0.0883 14.791 0.1673 14.758 0.2463 14.767L4.2383 14.949L4.2483 14.948C4.3313 14.942 4.4063 14.9 4.4573 14.82L4.4503 14.829C4.5013 14.748 4.5123 14.65 4.4953 14.555L4.4933 14.54L2.5473 8.844C2.5153 8.757 2.5193 8.659 2.5683 8.584C2.6393 8.467 2.7863 8.444 2.8943 8.542L2.9113 8.562L2.9193 8.57L12.0303 21.189C12.0833 21.279 12.0923 21.401 12.0373 21.488L12.0413 21.48Z"
        fill="#fff"
      />
      <path
        d="M50.9525 19.7237H53.4835V24.8247H58.5485V19.7237H61.0815V32.3477H58.5485V26.8147H53.4835V32.3477H50.9525V19.7237Z"
        fill="#fff"
      />
      <path
        d="M69.9232 26.7793C69.8512 25.6013 69.3062 24.5873 67.9522 24.5873C66.5972 24.5873 65.9072 25.5283 65.8002 26.7793H69.9232ZM71.5482 31.8233C70.6632 32.3093 69.6522 32.5633 68.3492 32.5633C65.2772 32.5633 63.4842 30.7903 63.4842 27.7333C63.4842 25.0413 64.9132 22.8183 67.8102 22.8183C71.2612 22.8183 72.2372 25.1853 72.2372 28.4413H65.8002C65.9072 29.9403 66.9582 30.7903 68.4582 30.7903C69.6342 30.7903 70.6482 30.3563 71.5482 29.8513V31.8233Z"
        fill="#fff"
      />
      <path
        d="M77.7728 30.7901C78.5128 30.7901 79.0928 30.4671 79.4738 29.9591C79.8708 29.4361 79.9778 28.7671 79.9778 28.0441H78.9288C77.8428 28.0441 76.2338 28.2251 76.2338 29.6511C76.2338 30.4491 76.9038 30.7901 77.7728 30.7901ZM74.9328 23.5211C75.8728 23.0871 77.1378 22.8181 78.1688 22.8181C81.0088 22.8181 82.1868 23.9921 82.1868 26.7411V27.9361C82.1868 28.8741 82.2028 29.5801 82.2198 30.2671C82.2398 30.9721 82.2748 31.6241 82.3318 32.3481H80.1968C80.1048 31.8591 80.1048 31.2431 80.0868 30.9541H80.0498C79.4918 31.9861 78.2788 32.5631 77.1748 32.5631C75.5308 32.5631 73.9208 31.5691 73.9208 29.7961C73.9208 28.4061 74.5888 27.5911 75.5128 27.1421C76.4348 26.6861 77.6268 26.5971 78.6398 26.5971H79.9778C79.9778 25.0951 79.3078 24.5871 77.8798 24.5871C76.8508 24.5871 75.8198 24.9861 75.0028 25.6021L74.9328 23.5211Z"
        fill="#fff"
      />
      <path d="M84.9 32.3475H87.324V18.7835H84.9V32.3475Z" fill="#fff" />
      <path
        d="M91.6614 24.8057H89.8704V23.0337H91.6614V21.1697L94.0854 20.3937V23.0337H96.2374V24.8057H94.0854V29.1447C94.0854 29.9407 94.3024 30.6837 95.2244 30.6837C95.6574 30.6837 96.0754 30.5917 96.3274 30.4317L96.3974 32.3477C95.8954 32.4927 95.3334 32.5637 94.6084 32.5637C92.7104 32.5637 91.6614 31.3897 91.6614 29.5437V24.8057Z"
        fill="#fff"
      />
      <path
        d="M99.2552 18.7832H101.681V24.2992H101.718C102.331 23.3962 103.38 22.8182 104.648 22.8182C106.853 22.8182 107.81 24.3722 107.81 26.4702V32.3472H105.39V27.3712C105.39 26.2362 105.37 24.6972 103.814 24.6972C102.06 24.6972 101.681 26.5962 101.681 27.7892V32.3472H99.2552V18.7832Z"
        fill="#fff"
      />
      <path
        d="M116.775 25.041C116.341 24.843 115.873 24.697 115.132 24.697C113.63 24.697 112.67 25.889 112.67 27.646C112.67 29.362 113.486 30.684 115.183 30.684C115.873 30.684 116.632 30.432 117.048 30.249L117.156 32.257C116.451 32.473 115.728 32.563 114.732 32.563C111.677 32.563 110.14 30.484 110.14 27.646C110.14 24.95 111.732 22.818 114.642 22.818C115.621 22.818 116.324 22.922 116.994 23.126L116.775 25.041Z"
        fill="#fff"
      />
      <path
        d="M122.969 30.7901C123.709 30.7901 124.291 30.4671 124.67 29.9591C125.067 29.4361 125.176 28.7671 125.176 28.0441H124.123C123.041 28.0441 121.432 28.2251 121.432 29.6511C121.432 30.4491 122.102 30.7901 122.969 30.7901ZM120.129 23.5211C121.069 23.0871 122.336 22.8181 123.367 22.8181C126.203 22.8181 127.381 23.9921 127.381 26.7411V27.9361C127.381 28.8741 127.399 29.5801 127.418 30.2671C127.436 30.9721 127.471 31.6241 127.524 32.3481H125.391C125.301 31.8591 125.301 31.2431 125.283 30.9541H125.246C124.686 31.9861 123.477 32.5631 122.373 32.5631C120.727 32.5631 119.117 31.5691 119.117 29.7961C119.117 28.4061 119.785 27.5911 120.707 27.1421C121.629 26.6861 122.824 26.5971 123.836 26.5971H125.176C125.176 25.0951 124.506 24.5871 123.076 24.5871C122.047 24.5871 121.014 24.9861 120.201 25.6021L120.129 23.5211Z"
        fill="#fff"
      />
      <path
        d="M130.457 23.0332H132.605V25.1482H132.643C132.754 24.2792 133.748 22.8182 135.191 22.8182C135.43 22.8182 135.682 22.8182 135.934 22.8872V25.3292C135.719 25.2052 135.283 25.1322 134.85 25.1322C132.881 25.1322 132.881 27.5912 132.881 28.9292V32.3472H130.457V23.0332Z"
        fill="#fff"
      />
      <path
        d="M143.584 26.7793C143.512 25.6013 142.969 24.5873 141.613 24.5873C140.256 24.5873 139.57 25.5283 139.461 26.7793H143.584ZM145.209 31.8233C144.326 32.3093 143.313 32.5633 142.01 32.5633C138.938 32.5633 137.147 30.7903 137.147 27.7333C137.147 25.0413 138.574 22.8183 141.469 22.8183C144.924 22.8183 145.898 25.1853 145.898 28.4413H139.461C139.57 29.9403 140.617 30.7903 142.121 30.7903C143.295 30.7903 144.307 30.3563 145.209 29.8513V31.8233Z"
        fill="#fff"
      />
      <path
        d="M196.82 6.1768H197.172C197.406 6.1768 197.612 6.1448 197.612 5.8578C197.612 5.6068 197.38 5.5648 197.176 5.5648H196.82V6.1768ZM196.423 5.2608H197.218C197.731 5.2608 198.008 5.4388 198.008 5.8888C198.008 6.2548 197.799 6.4338 197.448 6.4648L198.024 7.4008H197.617L197.061 6.4788H196.82V7.4008H196.423V5.2608ZM198.578 6.3388C198.578 5.4548 197.94 4.8168 197.151 4.8168C196.363 4.8168 195.724 5.4548 195.724 6.3388C195.724 7.2228 196.363 7.8618 197.151 7.8618C197.94 7.8618 198.578 7.2228 198.578 6.3388ZM195.263 6.3388C195.263 5.2988 196.111 4.4508 197.151 4.4508C198.191 4.4508 199.039 5.2988 199.039 6.3388C199.039 7.3778 198.191 8.2268 197.151 8.2268C196.111 8.2268 195.263 7.3778 195.263 6.3388Z"
        fill="#fff"
      />
    </g>
  ),
  viewBox: '0 0 200 33'
};
