import React from 'react';
import * as constants from 'store/providerSearch/constants';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { ListItemLink } from '../styled';
import { MuiTypography } from 'theme/material-ui';
import { Spacing } from 'modules/styles/variables';
import Box from '../../../components/UI/Layout/Box';

interface InsuranceLink {
  text: string;
  link: string;
}

const { selectHealthInsuranceLinks } = constants;

const InsuranceListItem = ({ item }: { item: InsuranceLink }) => (
  <ListItem key={item.text} divider>
    <ListItemLink href={item.link} target="_blank" rel="noopener noreferrer">
      <ListItemText disableTypography>{item.text}</ListItemText>
      <ListItemIcon>
        <ArrowForwardIosIcon color="primary" />
      </ListItemIcon>
    </ListItemLink>
  </ListItem>
);

const SelectHealthInsuranceLinks = () => (
  <>
    <Box vOffset={Spacing.large}>
      <MuiTypography variant="subtitle1">{constants.noOptionsTextSelectHealth}</MuiTypography>
    </Box>
    {selectHealthInsuranceLinks.map(i => (
      <InsuranceListItem item={i} />
    ))}
  </>
);

export default SelectHealthInsuranceLinks;
