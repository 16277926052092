import React from 'react';
import { useHistory } from 'react-router';
import { MuiBox, MuiButton, MuiTypography } from 'theme/material-ui';
import { Color } from 'modules/styles/colors';
import { FontSize } from 'modules/styles/variables';

import FlexBox from 'components/UI/Layout/FlexBox';
import { BillboardContainer } from 'components/UI/Banner/styled';
import { Icon } from 'components/Icon';
import { ROUTE } from 'components/ConnectCare/constants';
import EVisitsLeavingGuard from './EVisitLeavingGuard';

interface EVisitBannerProps {
  title: string;
}

const EVisitBanner = ({ title }: EVisitBannerProps) => {
  const history = useHistory();

  const handleGoBack = () => {
    history.goBack();
  };

  const handleExit = () => {
    history.push(ROUTE.base);
  };

  return (
    <>
      <EVisitsLeavingGuard />
      <MuiBox display="flex" data-testid="banner" flexBasis="auto" flexGrow="0" flexShrink="0">
        <BillboardContainer>
          <MuiBox px={2} width="100%">
            <FlexBox flexDirection="row" alignItems="center" justifyContent="space-between">
              <MuiBox display="flex" flexDirection="row" alignItems="center">
                <MuiButton data-testid="back-button" onClick={handleGoBack}>
                  <Icon name="chevron-left" size={40} color={Color.foreColor} />
                </MuiButton>
                <MuiTypography
                  fontSize={FontSize.largeHeading}
                  color={Color.foreColor}
                  style={{ marginLeft: '5px' }}
                >
                  {title}
                </MuiTypography>
              </MuiBox>

              <MuiButton data-testid="close-button" onClick={handleExit}>
                <Icon name="close" size={30} color={Color.foreColor} />
              </MuiButton>
            </FlexBox>
          </MuiBox>
        </BillboardContainer>
      </MuiBox>
    </>
  );
};

export default EVisitBanner;
