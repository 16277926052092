import React from 'react';
import FlexBox from 'components/UI/Layout/FlexBox';
import Spacer from 'components/UI/Layout/Spacer';
import { Color } from 'modules/styles/colors';
import { MuiTypography } from 'theme/material-ui';
import { FontWeight } from 'modules/styles/variables';
import LinkingServices from 'services/LinkingServices';
import { cleanPhone } from 'modules/utils/PhoneUtils';
import { AnalyticsEvent } from 'services/AnalyticsService';
import useNavigationAnalytics from 'hooks/useNavigationAnalytics';

interface Props {
  headerText: string;
  link: string;
  linkName: string;
  helplineHours: string;
  title: string;
  authenticated: boolean;
}

const Footer = ({ headerText, link, linkName, helplineHours, title, authenticated }: Props) => {
  const phoneNumberClicked = useNavigationAnalytics(AnalyticsEvent.PhoneNumberClicked);

  const handlePhoneNumber = () => {
    LinkingServices.callPhoneNumber(cleanPhone(link));
    const phoneEventData = {
      phone_number: link,
      support_call_type: 'Covid19 Screening',
      title
    };
    if (!authenticated) {
      phoneEventData.authentication_type = 'unauthenticated';
    }
    phoneNumberClicked(phoneEventData);
  };

  return (
    <FlexBox justifyContent="center" alignItems="center" padding="10px">
      <Spacer size="small" />
      <MuiTypography display="inline">
        <MuiTypography fontWeight={FontWeight.bold} display="inline">
          {headerText}{' '}
        </MuiTypography>
        <MuiTypography display="inline" color={Color.link} onClick={handlePhoneNumber}>
          {linkName}
        </MuiTypography>{' '}
        {helplineHours}
      </MuiTypography>
    </FlexBox>
  );
};

export default Footer;
