import styled from 'styled-components';
import { BasicAutoComplete } from 'components/AutoComplete/BasicAutoComplete';
import { Color } from 'modules/styles/colors';
import { FontSize, BorderRadius, ZIndex, Spacing, FontWeight } from 'modules/styles/variables';
import {
  dfdDefaultTheme,
  MuiBox,
  MuiButton,
  MuiTextField,
  MuiChip,
  CursorMuiBox
} from 'theme/material-ui';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

export const Container = styled(MuiBox)`
  height: 100%;
  overflow: auto;
  background: ${Color.grayLight3};
  padding-bottom: ${Spacing.xLarge}px;
`;

export const ListFetching = styled(MuiBox)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.6;
  background: ${Color.grayLight3};
  z-index: ${ZIndex.high};
`;

export const ListItemLink = styled.a`
  display: flex;
  width: 100%;
  padding: ${Spacing.medium}px 0;
  align-items: center;
  justify-content: space-between;
  color: ${Color.primary};
  font-size: ${FontSize.large}px;
  font-weight: ${FontWeight.bold};
`;

export const StyledMuiBox = styled(MuiBox)`
  && {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    padding: 8px 3px;
    cursor: pointer;
  }
`;

export const StyledListBox = styled.ul`
  && {
    &.MuiAutocomplete-listbox {
      padding: 0;
    }
  }
`;

// Refactor Components
export const SEARCH_BUTTON_WIDTH = 54;
export const INPUT_HEIGHT = 42;
export const INPUT_WIDTH = 555;

export const Autocomplete = styled(BasicAutoComplete)``;

export const AutocompleteInput = styled(MuiTextField)`
  &&& .MuiInput-root {
    padding: 0 ${dfdDefaultTheme.spacing(4)}px 0 ${dfdDefaultTheme.spacing(2.5)}px;
    border: 1px solid ${Color.grayLight};
    border-radius: ${BorderRadius.full}px;
    background-color: ${Color.white};
    max-width: ${INPUT_WIDTH}px;
    overflow: hidden;

    & > input {
      height: ${INPUT_HEIGHT}px;
      min-height: ${INPUT_HEIGHT}px;

      &::-ms-clear {
        display: none;
      }
    }
  }
  &&& .MuiInput-underline:before,
  &&& .MuiInput-underline:after {
    display: none;
  }
  &&& .MuiAutocomplete-endAdornment {
    right: ${SEARCH_BUTTON_WIDTH + dfdDefaultTheme.spacing(2)}px;
  }
`;

export const SearchButton = styled(MuiButton)`
  &.MuiButton-root {
    top: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    background-color: ${Color.grayLight};
    border-radius: 0;
    min-width: ${SEARCH_BUTTON_WIDTH}px;
  }
  &.MuiButton-root:hover {
    background-color: ${Color.grayLight};
  }
  & .MuiIcon-root {
    font-size: 1.75rem;
  }
`;

interface FiltersButtonProps {
  active: number;
}

export const FiltersButton = styled(MuiButton)<FiltersButtonProps>`
  &.MuiButton-root {
    background-color: ${Color.white};
    color: ${Color.black};
    margin-left: ${Spacing.large}px !important;
  }
`;

export const ShowMoreButton = styled(MuiButton)<FiltersButtonProps>`
  &.MuiButton-root {
    background-color: ${Color.translucent};
    color: ${Color.black};
    border: none;
  }
`;

export const Chip = styled(MuiChip)`
  &.MuiChip-colorPrimary {
    color: ${Color.black};
    height: 36px;
    border-radius: ${BorderRadius.base / 2}px;
    background-color: transparent;
    justify-content: space-between;
    padding-left: ${dfdDefaultTheme.spacing(0.5)}px;
  }
  &.MuiChip-deletableColorPrimary:focus {
    background-color: transparent;
  }

  .MuiChip-label {
    font-size: ${FontSize.small}px;
    letter-spacing: 0.25px;
    line-height: 20px;
    padding: 0px;
  }
`;

export const ChipDeleteIcon = styled(HighlightOffIcon)`
  &.MuiSvgIcon-root.MuiChip-deleteIcon.MuiChip-deleteIconColorPrimary {
    color: #979797;
    width: 16px;
    height: 16px;
    margin: 0px;
  }
`;

export const CountCircle = styled.span`
  height: ${FontSize.large}px;
  width: ${FontSize.large}px;
  font-size: ${FontSize.xsmall}px;
  border-radius: 50%;
  padding: ${dfdDefaultTheme.spacing(0.25)}px;
  background-color: ${Color.lightPurple};
  color: ${Color.white};
  text-align: center;
  line-height: 1;
  box-sizing: content-box;
  white-space: nowrap;
  margin-left: 8px;
  &::before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    padding-top: 100%;
    height: 0;
  }
`;
// Dialog styles
export const CloseButton = styled(CursorMuiBox)`
  position: absolute;
  top: ${Spacing.large}px;
  right: ${Spacing.large}px;
  z-index: ${ZIndex.high};
`;
