import React from 'react';
import { Color } from 'modules/styles/colors';
import { SvgProps } from 'components/UI/Svg/Svg';

export default (props: SvgProps | undefined) => {
  return {
    svg: (
      <g>
        <path
          fill="none"
          stroke={
            props?.active
              ? 'url(#a)'
              : props?.fill
              ? props.fill
              : props?.disabled
              ? Color.grayHue5
              : Color.primary
          }
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M7.286 5.714V2.571A1.571 1.571 0 0 1 8.857 1h6.286a1.571 1.571 0 0 1 1.571 1.571v3.143M12 10.41v6.285m-3.143-3.142h6.286m-11-7.838h15.714A3.143 3.143 0 0 1 23 8.857v9.429a3.143 3.143 0 0 1-3.143 3.143H4.143A3.143 3.143 0 0 1 1 18.286V8.857a3.143 3.143 0 0 1 3.143-3.143Z"
        />
        <defs>
          <linearGradient
            id="a"
            x1={1}
            x2={23}
            y1={11.214}
            y2={11.214}
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0.084} stopColor="#FF0048" />
            <stop offset={0.386} stopColor="#DF36FF" />
            <stop offset={0.98} stopColor="#4A00E2" />
          </linearGradient>
        </defs>
      </g>
    ),
    viewBox: '0 0 24 23'
  };
};
