import React, { useEffect } from 'react';
import * as accountSelectors from 'store/account/selectors';
import * as profileSelectors from 'store/profile/selectors';
import { RootState } from 'store/types';
import * as accountActions from 'store/account/actions';
import { Consumer } from 'store/profile/types';
import ProxyWarning from 'modules/constants/amwell/proxyWarning';
import Logger from 'services/Logger';
import analyticsService, { AnalyticsEvent } from 'services/AnalyticsService';
import { Confirm } from 'components/ConfirmDialog/ConfirmDialog';
import { connect } from 'react-redux';
import { ROUTE } from './constants';

export interface Props {
  onError: (path: string) => void;
  onDecline: (path: string) => void;
  onLoading: (bool: boolean) => void;

  profileConsumer: Consumer;
  currentConsumer: Consumer;
  switchAccount: typeof accountActions.switchAccount;
}

export function ConnectCareProxy({
  onError,
  onDecline,
  onLoading,
  profileConsumer,
  currentConsumer,
  switchAccount
}: Props) {
  const consumerId = profileConsumer?.consumerId;
  const firstName = currentConsumer?.firstName;

  const onSwitchError = (errorMessage: string) => {
    Logger.error(ProxyWarning.ERROR.LOGGER.title, errorMessage);
    Confirm.show(
      ProxyWarning.ERROR.title,
      ProxyWarning.ERROR.description,
      null,
      ProxyWarning.ERROR.severity,
      {
        text: ProxyWarning.ERROR.text,
        onClick: () => onError('/u/manage-account')
      }
    );
  };

  const onSwitch = async () => {
    if (!consumerId) {
      onSwitchError(ProxyWarning.ERROR.LOGGER.consumerNotFound);
    } else {
      onLoading(true);
      const res: any = await switchAccount(consumerId);
      onLoading(false);

      if (res?.error) {
        onSwitchError(res.error);
      }

      analyticsService.logEvent(AnalyticsEvent.AccountSwitched, {
        source: ProxyWarning.SUCCESS.LOGGER.source
      });
    }
  };

  useEffect(() => {
    Confirm.show(
      ProxyWarning.MODAL_TEXT.title,
      ProxyWarning.MODAL_TEXT.slug(firstName),
      ProxyWarning.MODAL_TEXT.copy,
      ProxyWarning.MODAL_TEXT.severity,
      {
        text: ProxyWarning.MODAL_TEXT.confirm,
        onClick: onSwitch,
        skipOnClose: true
      },
      {
        text: ProxyWarning.MODAL_TEXT.decline,
        onClick: () => onDecline(ROUTE.getCare)
      },
      {
        cancelable: false,
        closeIcon: false
      }
    );
  }, []);

  return <></>;
}

const mapDispatchToProps = {
  switchAccount: accountActions.switchAccount
};

const mapStateToProps = (state: RootState) => ({
  currentConsumer: accountSelectors.currentAccountConsumerSelector(state),
  profileConsumer: profileSelectors.profileConsumerSelector(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(ConnectCareProxy);
