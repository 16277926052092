import React from 'react';
import { FlatList } from 'react-native';

import Spacer from 'components/UI/Layout/Spacer';

import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { convertToLowerKabobCase } from 'modules/utils/StringUtils';
import { COVID_REASONS_CHECKBOX, CovidReasonsBooleanEnums } from 'lib/covidScreening/types';
import { useCovidReasonsGuest } from '../../hooks';
import { useLanguageSwitcher } from 'lib/hooks/useLanguageSwitcher';
import { translations } from 'lib/constants/translations/screens/covidGuest/reasons';

const style = {
  alignSelf: 'start',
  paddingBottom: '0px',
  paddingTop: '0px'
};

interface FlatListItemProps {
  checked: boolean;
  fieldName: CovidReasonsBooleanEnums;
  label: string;
  onChange: (reason: CovidReasonsBooleanEnums) => void;
}

const FlatListItem = (props: FlatListItemProps) => (
  <Box pt={1.5} px={4} py={1.07}>
    <Box>
      <FormControlLabel
        control={
          <Checkbox
            checked={props.checked}
            color="primary"
            data-testid={convertToLowerKabobCase(props.fieldName, '-form-checkbox')}
            onChange={() => props.onChange(props.fieldName)}
            style={style}
            value={props.fieldName}
          />
        }
        label={props.label}
      />
    </Box>
  </Box>
);

export default function CheckboxStatements() {
  const { requiredFields, updateRequiredFields } = useCovidReasonsGuest();
  const screenText = useLanguageSwitcher(translations);

  return (
    <FlatList
      ItemSeparatorComponent={() => <Spacer size="small" />}
      data={COVID_REASONS_CHECKBOX}
      keyExtractor={item => `${item.key}`}
      renderItem={({ item }) => {
        const checkedValues = requiredFields.covidReasonsChecked;
        return (
          <FlatListItem
            checked={checkedValues.includes(item.fieldName)}
            fieldName={item.fieldName}
            label={screenText?.[`checkbox${item.key}`]}
            onChange={updateRequiredFields}
          />
        );
      }}
    />
  );
}
