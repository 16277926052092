import React, { useEffect } from 'react';
import { useMediaQuery, useTheme } from '@material-ui/core';
import useNavigationAnalytics from 'hooks/useNavigationAnalytics';
import Typography from 'components/UI/Typography';
import {
  MuiBox,
  MuiButton,
  MuiTypography,
  MuiGrid,
  MuiCardContent,
  MuiCard,
  MuiContainer
} from 'theme/material-ui';
import { EMOTIONAL_SUPPORT } from 'lib/constants/help';
import emotionalSupport from 'assets/emotional-support.png';
import { AnalyticsEvent } from 'services/AnalyticsService';
import FlexBox from 'components/UI/Layout/FlexBox';
import Box from 'components/UI/Layout/Box';
import { Color } from 'modules/styles/colors';
import { Alert } from 'components/Alert';
import { FontSize, Spacing, FontWeight, IconSize } from 'modules/styles/variables';
import { Svg } from 'components/UI/Svg';
import styled from 'styled-components';
import LinkingServices from 'services/LinkingServices';

export const EmotionalImageBackground = styled(Box)`
  height: 100%;
  width: 100%;
  color: #17a2b8;
  background-color: ${Color.grayLight8};
`;
function EmotionalSupportScreen() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down('md'));
  const analyticsEvent = useNavigationAnalytics(AnalyticsEvent.EmotionalSupportViewed);

  useEffect(() => {
    analyticsEvent();
  }, []);

  const handleExternalLink = (link: string) => {
    try {
      window.open(link, '_blank');
    } catch (err) {
      Alert.alert('Link not available');
    }
  };

  const onCallPhone = (phone: string) => {
    LinkingServices.callPhoneNumber(phone);
  };

  return (
    <MuiBox component="main">
      <EmotionalImageBackground>
        <FlexBox
          hSpacingLeft={isSmallScreen ? Spacing.large : 100}
          alignItems="center"
          flexDirection="row"
        >
          <MuiBox>
            <img
              style={{
                width: 520,
                maxWidth: '100%',
                position: 'relative',
                bottom: -50,
                backgroundSize: 'contain'
              }}
              src={emotionalSupport}
              alt=""
            />
          </MuiBox>
          {!isSmallScreen ? (
            <MuiBox m={1} maxWidth={600} marginLeft={20}>
              <MuiTypography fontSize={FontSize.largeTitle} color={Color.black} component="h2">
                {EMOTIONAL_SUPPORT.title}
              </MuiTypography>
            </MuiBox>
          ) : null}
        </FlexBox>
      </EmotionalImageBackground>
      <MuiContainer style={{ maxWidth: 1100 }}>
        <MuiGrid
          container
          xs={12}
          justify="center"
          spacing={1}
          style={{ marginBottom: 50, marginTop: 80 }}
        >
          <MuiGrid
            item
            xs={12}
            lg={8}
            style={{ marginBottom: isSmallerScreen ? Spacing.xLarge : 0 }}
          >
            {isSmallScreen ? (
              <Box hSpacing={Spacing.largeXLarge} vSpacingBottom={Spacing.xLarge} width="100%">
                <MuiTypography
                  fontSize={FontSize.title}
                  fontWeight={FontWeight.bold}
                  color={Color.primary}
                  component="h2"
                >
                  {EMOTIONAL_SUPPORT.title}
                </MuiTypography>
              </Box>
            ) : null}
            <Box
              vSpacingTop={isSmallScreen ? Spacing.none : Spacing.largeXLarge}
              hSpacing={isSmallScreen ? Spacing.largeXLarge : 100}
            >
              <Box vSpacingBottom={Spacing.xLarge}>
                <Typography fontSize={FontSize.large} color={Color.textLight}>
                  {EMOTIONAL_SUPPORT.description[0]}
                </Typography>
              </Box>
              <Typography fontSize={FontSize.large} color={Color.textLight}>
                {EMOTIONAL_SUPPORT.description[1]}
              </Typography>
            </Box>
          </MuiGrid>
          <MuiGrid item xs={12} lg={4}>
            <MuiCard>
              <MuiCardContent>
                <FlexBox
                  alignItems="center"
                  hSpacing={isSmallScreen ? Spacing.none : Spacing.largeXLarge}
                >
                  <Box hSpacingLeft={Spacing.smallMedium} spacing={Spacing.large}>
                    <MuiTypography
                      fontSize={FontSize.mediumHeading}
                      color={Color.primary}
                      fontWeight={FontWeight.bold}
                      component="h3"
                    >
                      {EMOTIONAL_SUPPORT.card.title}
                    </MuiTypography>
                  </Box>
                  <FlexBox flexDirection="row" spacing={Spacing.mediumLarge}>
                    <Svg name="Schedule" size={IconSize.base} color={Color.primary} />
                    <Box hSpacingLeft={Spacing.medium}>
                      <MuiTypography
                        fontSize={FontSize.large}
                        fontWeight={FontWeight.bold}
                        color={Color.textLight}
                      >
                        {EMOTIONAL_SUPPORT.card.schedule}
                      </MuiTypography>
                    </Box>
                  </FlexBox>
                  <FlexBox flexDirection="row" spacing={Spacing.mediumLarge}>
                    <Svg name="CalendarFlat" size={IconSize.base} color={Color.primary} />
                    <Box hSpacingLeft={Spacing.medium}>
                      <MuiTypography
                        fontSize={FontSize.large}
                        fontWeight={FontWeight.bold}
                        color={Color.textLight}
                      >
                        {EMOTIONAL_SUPPORT.card.week}
                      </MuiTypography>
                    </Box>
                  </FlexBox>
                  <FlexBox
                    fullWidth
                    flexDirection="row"
                    justifyContent="center"
                    alignSelf="center"
                    vSpacingTop={Spacing.largeXLarge}
                  >
                    <MuiButton
                      fullWidth
                      variant="contained"
                      color="primary"
                      onClick={() => onCallPhone(EMOTIONAL_SUPPORT.phone)}
                    >
                      Call {EMOTIONAL_SUPPORT.phone}
                    </MuiButton>
                  </FlexBox>
                  <FlexBox
                    flexDirection="row"
                    fullWidth
                    justifyContent="center"
                    vSpacingTop={Spacing.largeXLarge}
                  >
                    <MuiButton
                      fullWidth
                      color="primary"
                      onClick={() => {
                        handleExternalLink(EMOTIONAL_SUPPORT.url);
                      }}
                    >
                      {EMOTIONAL_SUPPORT.getMoreInfo}
                    </MuiButton>
                  </FlexBox>
                </FlexBox>
              </MuiCardContent>
            </MuiCard>
          </MuiGrid>
        </MuiGrid>
      </MuiContainer>
    </MuiBox>
  );
}

export default EmotionalSupportScreen;
