import React from 'react';
import { MuiMenuItem, MenuProps } from 'theme/material-ui';
import { ConnectCareTextField } from '../ConnectCareTextField';

export interface ConnectCareSelectProps<T> {
  items: T[];
  itemToLabel: (item: T) => string;
  itemToValue: (item: T) => string;
  value: T;
  onChange?: (e: React.ChangeEvent<any>) => void;
  onClose?: (e: React.ChangeEvent<any>) => void;
  label?: React.ReactNode;
  disabled?: boolean;
  error?: boolean;
  placeholder?: string;
  helperText?: React.ReactNode;
  name?: string;
  required?: boolean;
}

const SELECT_ITEM_HEIGHT = 48;
const SELECT_ITEM_PADDING_TOP = 8;

const menuProps: Partial<MenuProps> = {
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left'
  },
  PaperProps: {
    style: {
      maxHeight: SELECT_ITEM_HEIGHT * 4.5 + SELECT_ITEM_PADDING_TOP
    }
  }
};

function ConnectCareSelect<T>(props: ConnectCareSelectProps<T>) {
  const {
    items,
    itemToLabel,
    itemToValue,
    label,
    onChange,
    error,
    helperText,
    value,
    placeholder,
    disabled,
    name,
    onClose,
    required,
    ...rest
  } = props;

  return (
    <ConnectCareTextField
      {...rest}
      select
      required={required}
      value={itemToValue(value)}
      onChange={onChange}
      label={label}
      disabled={disabled}
      helperText={helperText}
      error={error}
      name={name}
      placeholder={placeholder}
      SelectProps={{
        displayEmpty: true,
        onClose,
        MenuProps: menuProps
      }}
    >
      <MuiMenuItem value="" disabled>
        {placeholder}
      </MuiMenuItem>
      {items.map(item => {
        const itemLabel = itemToLabel(item);
        const itemValue = itemToValue(item);

        return (
          <MuiMenuItem key={itemValue} value={itemValue}>
            {itemLabel}
          </MuiMenuItem>
        );
      })}
    </ConnectCareTextField>
  );
}

ConnectCareSelect.defaultProps = {
  itemToLabel: (item: any) => (item ? item.label : ''),
  itemToValue: (item: any) => (item ? item.value : ''),
  placeholder: 'Select One'
};

export default ConnectCareSelect;
