import React from 'react';
import styled from 'styled-components';
import { MuiCardActions, CardActionsProps, dfdDefaultTheme } from 'theme/material-ui';

interface CardActions extends CardActionsProps {
  padding?: string;
}

const Actions = styled(MuiCardActions)<{ padding?: string }>`
  &.MuiCardActions-root {
    padding: ${props => props.padding};
    display: flex;
    flex-direction: column;
    width: 50%;
  }
  ${dfdDefaultTheme.breakpoints.down('lg')} {
    &.MuiCardActions-root {
      width: 100%;
    }
  }
`;

export const ConnectCareCardActions = (props: CardActions) => {
  return <Actions {...props} />;
};

ConnectCareCardActions.defaultProps = {
  padding: '8px 16px'
};
