import { connect } from 'react-redux';
import Screen from 'components/UI/Layout/Screen';
import { useHistory } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import DownloadErrorSnackbar from 'components/DownloadErrorSnackbar/DownloadErrorSnackbar';
import { Drawer } from 'components/common/Drawer';
import Spinner from 'components/UI/Spinner/Spinner';
import FilterModal from 'components/common/FilterModal/FilterModal';
import ListActionsRow from 'components/common/ListActionsRow/ListActionsRow';
import { CenteredSpinnerContainer } from 'components/UI/Spinner/SpinnerContainer';
import {
  MuiBox,
  MuiRadio,
  MuiButton,
  MuiRadioGroup,
  dfdDefaultTheme,
  MuiFormControlLabel
} from 'theme/material-ui';

import { initialFilterState, sortOptions } from 'lib/visitSummary/constants';
import analyticsService, { AnalyticsEvent } from 'services/AnalyticsService';
import { getEVisitFeatureRestriction } from 'lib/visitSummary/utils';

import {
  currentLocationPathNameSelector,
  previousLocationPathNameSelector
} from 'store/router/selectors';
import { RootState, RootDispatch } from 'store/types';
import * as types from 'store/visitSummary/types';
import * as actions from 'store/visitSummary/actions.web';
import * as selectors from 'store/visitSummary/selectors';
import {
  SORT_BY_YEAR_NEWEST_TO_OLDEST,
  SORT_BY_YEAR_OLDEST_TO_NEWEST,
  INIT_NUM_OF_VISITS_TO_FETCH
} from 'store/visitSummary/constants';

import FilterTag from './FilterTag';
import { Circle, ListHeader, Line, StyledMuiContainer, CircleBox } from './styled';
import VisitSummaryMessage from './VisitSummaryMessage';
import VisitsListCard from './VisitsListCard';
import { getVisitSummaryLinkPath } from '../navigation';
import ListActionsSelectionRow from 'components/common/ListActionsSelectionRow/ListActionsSelectionRow';
import ConfirmModal from 'components/UI/Modals/ConfirmModal';
import { mergeArrayObjectsBy } from 'store/myDoctors/utils';

export interface VisitSummaryListProps {
  loadingFullList: boolean;
  multipleDownloadsStatus: string;
  data: types.VisitSummary[];
  filter: types.VisitSummaryFilter | null;
  dispatch: RootDispatch;
  hasVisits: boolean;
  hasActiveFilters: boolean;
  totalVisits: number;
  sortBy: types.OptionType | null;
  errorFullList: Error | null;
  sortedData: types.VisitSummary[];
  currentUrl: string;
  referringUrl?: string;
}

export function VisitSummaryListComponent(props: VisitSummaryListProps) {
  const {
    data,
    filter,
    dispatch,
    hasVisits,
    hasActiveFilters,
    totalVisits,
    loadingFullList,
    multipleDownloadsStatus,
    errorFullList,
    sortBy,
    currentUrl,
    referringUrl
  } = props;

  const [isFilterOpen, setFilterOpen] = useState(false);
  const [sortOpen, setSortOpen] = useState(false);
  const [sortDisabled, setSortDisabled] = useState(true);
  const [sortByValue, setSortByValue] = useState<string | undefined>(sortBy?.value);
  const [sortActive, setSortActive] = useState(false);
  const [filterState, setFilterState] = useState(initialFilterState);
  const [showFullList, setShowFullList] = useState(false);
  const [items, setItems] = useState(data);
  const history = useHistory();
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [itemsSelected, setItemsSelected] = useState<types.VisitSummary[]>([]);
  const [isDownloadActionSelected, setIsDownloadActionSelected] = useState(false);
  const [isShareActionSelected, setIsShareActionSelected] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const isSmallScreen = !!dfdDefaultTheme.breakpoints.down('sm') === true;
  const isDownloadingMultipleVisitSummary = multipleDownloadsStatus === 'pending';
  const downloadError = multipleDownloadsStatus === 'fail';

  // Init state is to only show 25 visits unless they choose to filter data or select show all
  useEffect(() => {
    if (!showFullList) {
      setItems(data.slice(0, INIT_NUM_OF_VISITS_TO_FETCH));
    } else {
      const allItems = [...data, ...itemsSelected];
      const newData = mergeArrayObjectsBy(allItems, 'id');
      setItems(newData);
      setIsSelectAll(false);
    }

    if (filter) {
      setFilterState(filter);
    }
  }, [filter, data, showFullList]);

  const handleFilterClose = () => {
    setFilterOpen(!isFilterOpen);
  };

  const visitCountMessage =
    items.length === 1 ? `1 visit of 1` : `${items.length} visits of ${totalVisits}`;

  const sortByDate = (value: string) => {
    setSortActive(true);
    if (value === 'asc') {
      dispatch(actions.setVisitSummarySortBy(SORT_BY_YEAR_OLDEST_TO_NEWEST));
    } else if (value === 'desc') {
      dispatch(actions.setVisitSummarySortBy(SORT_BY_YEAR_NEWEST_TO_OLDEST));
    }
  };

  /**
   * Retrieve full list on component load
   */
  useEffect(() => {
    dispatch(actions.getVisitSummaryFullList());
  }, []);

  useEffect(() => {
    if (currentUrl === '/u/health-record/visit-summaries' && referringUrl === '/u/health-record') {
      dispatch(actions.setVisitSummaryFilter(initialFilterState));
      dispatch(actions.setVisitSummarySortBy(SORT_BY_YEAR_NEWEST_TO_OLDEST));
    }
  }, [currentUrl, referringUrl]);

  const showAllVisits = () => {
    setShowFullList(true);
  };

  const onItemPress = (item: types.VisitSummary) => {
    analyticsService.logEvent(AnalyticsEvent.VisitSummaryDetailClicked, {
      referringUrl,
      currentUrl
    });

    const params = {
      source: item.source,
      id: item.id,
      returnBackTo: currentUrl,
      dateOfEncounter: item.date,
      mhpDetails: item.mhpDetails
    };
    history.push(getVisitSummaryLinkPath(item), {
      ...params
    });
  };

  const handleOnItemChecked = (selectedItem: types.VisitSummary) => {
    if (isSelectAll) {
      handleSelectAllPress();
    } else {
      const itemsUpdated = items.map(item => {
        if (item.id === selectedItem.id) return { ...item, isSelected: !selectedItem.isSelected };
        return item;
      });
      const itemsFiltered = itemsUpdated.filter(item => item.isSelected);
      if (itemsFiltered.length > 10) {
        setIsAlertOpen(true);
      } else {
        setItemsSelected(itemsFiltered);
        setItems(itemsUpdated);
        setIsSelectAll(itemsFiltered.length === itemsUpdated.length);
      }
    }
  };

  const handleSortClose = () => {
    if (sortBy?.value !== sortByValue) {
      setSortByValue(sortBy?.value);
    }
    setSortOpen(false);
  };

  const clearAllFilters = () => {
    dispatch(actions.setVisitSummaryFilter(initialFilterState));
  };

  const applyFilters = (state: types.VisitSummaryFilter) => {
    dispatch(actions.setVisitSummaryFilter(state));
  };

  const sortByText = (): string => {
    if (sortBy?.value === 'asc') return ': Old';
    return ': New';
  };

  const handleOnSharePress = () => {
    const encounterIdList = itemsSelected.map(item => item.id);
    history.push({
      pathname: '/u/messages/transmit',
      state: {
        source: 'MultipleVisitSummary',
        payload: { encounterIdList, all: isSelectAll },
        returnBackTo: '/u/health-record/visit-summaries'
      }
    });
  };

  const handleOnDownloadPress = () => {
    setIsConfirmModalOpen(true);
  };

  const onConfirmDownload = () => {
    setIsConfirmModalOpen(false);
    setIsDownloadActionSelected(false);
    const encounterIdList = itemsSelected.map(item => item.id);
    const downloadAll = isSelectAll || encounterIdList.length === totalVisits;
    dispatch(actions.getMultipleVisitSummaryCcd(encounterIdList, downloadAll));
  };

  const handleSelectAllPress = () => {
    const itemsUpdated = items.map(item => ({ ...item, isSelected: !isSelectAll }));
    const itemsFiltered = itemsUpdated.filter(item => item.isSelected);
    setItemsSelected(itemsFiltered);
    setItems(itemsUpdated);
    setIsSelectAll(!isSelectAll);
  };

  const resetItems = () => {
    const itemsUpdated = items.map(item => ({ ...item, isSelected: false }));
    setItemsSelected([]);
    setItems(itemsUpdated);
    setIsSelectAll(false);
  };

  const handleOnDownloadActionPress = () => {
    resetItems();
    setIsDownloadActionSelected(true);
  };

  const handleOnShareActionPress = () => {
    resetItems();
    setIsShareActionSelected(true);
  };

  const isAnyActionSelected = isDownloadActionSelected || isShareActionSelected;
  const areSomeItemsSelected = itemsSelected.length > 0;

  return loadingFullList ? (
    <CenteredSpinnerContainer>
      <Spinner />
    </CenteredSpinnerContainer>
  ) : (
    <>
      {isDownloadActionSelected ? (
        <ListActionsSelectionRow
          actionButtonText="Download"
          actionBtnTestID="action-download-button"
          actionBtnAriaLabel="download"
          buttonIconName="GetApp"
          message="Please check the visits you would like to download (limited to 10)"
          shortMessage="Select visits to download"
          onActionPress={handleOnDownloadPress}
          onSelectAllPress={handleSelectAllPress}
          onRowClose={() => setIsDownloadActionSelected(false)}
          isSelectAll={isSelectAll}
          areSomeSelected={areSomeItemsSelected}
        />
      ) : null}
      {isShareActionSelected ? (
        <ListActionsSelectionRow
          actionButtonText="Share"
          actionBtnTestID="action-share-button"
          actionBtnAriaLabel="share"
          buttonIconName="Share"
          message="Please check the visits you would like to share"
          shortMessage="Select visits to share"
          onActionPress={handleOnSharePress}
          onSelectAllPress={handleSelectAllPress}
          onRowClose={() => setIsShareActionSelected(false)}
          isSelectAll={isSelectAll}
          areSomeSelected={areSomeItemsSelected}
        />
      ) : null}
      {!isAnyActionSelected ? (
        <ListActionsRow
          justifyContent="flex-end"
          sortTitleEndText={sortByText()}
          sortActive={sortBy?.value === 'asc' || sortActive}
          filterActive={hasActiveFilters}
          onFilterPress={handleFilterClose}
          onSortPress={() => setSortOpen(true)}
          onDownloadActionPress={handleOnDownloadActionPress}
          onShareActionPress={handleOnShareActionPress}
          isDownloadingFiles={isDownloadingMultipleVisitSummary}
        />
      ) : null}
      <Screen background>
        <StyledMuiContainer maxWidth="lg">
          <DownloadErrorSnackbar hasError={!!downloadError} onRetry={onConfirmDownload} />
          <MuiBox flex={1}>
            <MuiBox flex={1} px={2}>
              {!!hasVisits ? <FilterTag clearAllFilters={clearAllFilters} /> : null}
              {!!items.length ? (
                <MuiBox display="flex" paddingBottom={1} paddingTop={2}>
                  <CircleBox>
                    <Circle />
                  </CircleBox>
                  <MuiBox
                    width="100%"
                    display={isSmallScreen ? 'none' : 'flex'}
                    flexDirection="row"
                    justifyContent="space-between"
                  >
                    <ListHeader>Past Visits</ListHeader>
                    {!loadingFullList && items.length ? (
                      <VisitSummaryMessage
                        isSmallScreen={isSmallScreen}
                        message={visitCountMessage}
                      />
                    ) : null}
                  </MuiBox>
                </MuiBox>
              ) : null}
              {!loadingFullList ? (
                <div position="relative">
                  {items.length ? <Line /> : null}
                  <VisitsListCard
                    items={items}
                    onItemPress={onItemPress}
                    onItemChecked={handleOnItemChecked}
                    eVisitHandler={getEVisitFeatureRestriction}
                    loading={loadingFullList}
                    error={errorFullList}
                    hasVisits={hasVisits}
                    enableCheckbox={isAnyActionSelected}
                  />
                </div>
              ) : null}
              {!showFullList &&
              items.length === INIT_NUM_OF_VISITS_TO_FETCH &&
              totalVisits > INIT_NUM_OF_VISITS_TO_FETCH ? (
                <MuiBox
                  display="flex"
                  justifyContent="center"
                  pt={4}
                  style={{ marginBottom: '24px' }}
                >
                  <MuiButton
                    disabled={loadingFullList}
                    onClick={showAllVisits}
                    color="primary"
                    fullWidth
                    variant="outlined"
                  >
                    Show all
                  </MuiButton>
                </MuiBox>
              ) : null}
            </MuiBox>
          </MuiBox>
        </StyledMuiContainer>
      </Screen>
      <FilterModal
        close={handleFilterClose}
        open={isFilterOpen}
        applyFilters={applyFilters}
        clearAllFilters={clearAllFilters}
        filterState={filterState}
      />
      <Drawer
        title="Sort"
        open={sortOpen}
        disabled={sortDisabled}
        onClose={handleSortClose}
        onApply={() => {
          sortByDate(sortByValue || '');
          setSortOpen(false);
        }}
      >
        <MuiBox p={3}>
          <MuiRadioGroup
            aria-label="sort"
            name="sort"
            value={sortByValue}
            defaultValue="desc"
            onChange={(_, value: string) => {
              setSortDisabled(false);
              setSortByValue(value);
            }}
          >
            {sortOptions?.map((item: types.SortOptions) => (
              <MuiFormControlLabel
                value={item?.value}
                control={<MuiRadio color="primary" />}
                label={item?.label}
              />
            ))}
          </MuiRadioGroup>
        </MuiBox>
      </Drawer>
      <ConfirmModal
        isOpen={isConfirmModalOpen}
        fullWidth
        maxWidth="400px"
        showCloseIcon
        position="bottom"
        icon="InfoIcon"
        confirmLabel="Got it"
        accessibilityLabel="remove card modal"
        title="Downloading files could take a few minutes."
        onConfirm={() => onConfirmDownload()}
        onCancel={() => setIsConfirmModalOpen(false)}
      />
      <ConfirmModal
        isOpen={isAlertOpen}
        fullWidth
        maxWidth="400px"
        position="bottom"
        icon="InfoIcon"
        confirmLabel="Got it"
        accessibilityLabel="remove card modal"
        title="You've reached the maximum number of downloads allowed"
        description=""
        onConfirm={() => setIsAlertOpen(false)}
      />
    </>
  );
}

const mapStateToProps = (state: RootState) => ({
  loadingFullList: selectors.visitSummaryFullListLoadingSelector(state),
  multipleDownloadsStatus: selectors.multipleDownloadsByCernerPersonId(state),
  totalVisits: selectors.visitSummaryListTotalCountSelector(state),
  data: selectors.visitSummaryFilteredAndSortedDataSelector(state),
  filter: selectors.visitSummaryFilterSelector(state),
  hasVisits: selectors.visitSummaryHasVisitsSelector(state),
  hasActiveFilters: selectors.visitSummaryFiltersActiveSelector(state),
  sortBy: selectors.visitSummarySortBySelector(state),
  errorFullList: selectors.visitSummaryFullListErrorSelector(state),
  currentUrl: currentLocationPathNameSelector(state),
  referringUrl: previousLocationPathNameSelector(state)
});

export default connect(mapStateToProps)(VisitSummaryListComponent);
