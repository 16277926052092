import React, { ReactNode } from 'react';
import { useWindowSize } from 'hooks/useWindowSize';
import useVisibleSlidesCount from 'hooks/useVisibleSlidesCount';
import { CardContainerBlank, FlippableCardContainer, FlippableInner } from './styled';

interface FlippableCardProps {
  flipped: boolean;
  front: ReactNode;
  back: ReactNode;
  totalCards: number;
}

export const FlippableCard = ({ flipped, front, back, totalCards }: FlippableCardProps) => {
  const visibleSlides = useVisibleSlidesCount(totalCards);
  const windowDim = useWindowSize();
  const calcCardProps = {
    totalCards,
    visibleSlides,
    windowWidth: windowDim.width
  };

  return (
    <FlippableCardContainer>
      <FlippableInner {...calcCardProps}>
        <CardContainerBlank {...calcCardProps}>{flipped ? back : front}</CardContainerBlank>
      </FlippableInner>
    </FlippableCardContainer>
  );
};
