import React from 'react';

import { MuiBox, MuiGrid, MuiTypography } from 'theme/material-ui';

import { LazyMuiAvatar } from 'components/LazyAvatar';
import { Svg } from 'components/UI/Svg';
import { onEnter } from 'utils/keyboard';
import { convertToLowerKabobCase } from 'modules/utils/StringUtils';
import { showDistance } from 'modules/utils/LocationUtils';
import { Color } from 'modules/styles/colors';
import { FontSize, IconSize } from 'modules/styles/variables';
import { ProviderSummary } from 'store/findProvider/types';
import { Nullable } from 'modules/types/common';
import { Specialty } from 'store/booking/types';

import { BookingPanelListItem, SelectHealthIconWrapper } from '../styled';

interface Props {
  selectedSpecialty: Nullable<Specialty>;
  doctor: ProviderSummary;
  geolocationStatus: boolean;
  getProviderIsSelected: Function;
  handleProviderSelect: Function;
}

const Provider = ({
  selectedSpecialty,
  doctor,
  geolocationStatus,
  getProviderIsSelected,
  handleProviderSelect
}: Props) => (
  <BookingPanelListItem
    tabindex="0"
    role="radio"
    elevation={0}
    backgroundColor={Color.foreColor}
    square
    key={doctor.cernerResourceId}
    aria-checked={getProviderIsSelected(doctor)}
    selected={getProviderIsSelected(doctor)}
    onClick={() => handleProviderSelect(doctor)}
    onKeyUp={onEnter(() => handleProviderSelect(doctor))}
    data-testid={convertToLowerKabobCase(doctor.displayName)}
  >
    <MuiGrid container spacing={2} alignItems="center">
      {/* Avatar */}
      <MuiGrid item>
        <MuiBox>
          <LazyMuiAvatar
            src={doctor?.providerProfileImageUrl}
            style={{ width: IconSize.xxLarge || 65, height: IconSize.xxLarge || 65 }}
          />
          {doctor?.isSelectHealth ? (
            <SelectHealthIconWrapper align="right">
              <Svg set="assets" name="SelectHealth" size={IconSize.small} />
            </SelectHealthIconWrapper>
          ) : null}
        </MuiBox>
      </MuiGrid>
      {/* Data */}
      <MuiGrid container item xs alignItems="center">
        <MuiGrid item xs row-directioin="colum">
          {/* Provider name */}
          <MuiGrid>
            <MuiTypography data-testid="provider-name">{doctor?.displayName}</MuiTypography>
            <MuiTypography variant="subtitle1" data-testid="provider-specialty">
              {doctor?.primarySpecialty?.specialtyName || ''}
            </MuiTypography>
          </MuiGrid>
          {/* provider location */}
          <MuiGrid container spacing={1}>
            {doctor?.isVirtualClinic === false || doctor?.primaryLocation?.name ? (
              <MuiGrid container direction="row" alignItems="center" item sm={6} spacing={1}>
                <MuiGrid item>
                  <Svg name="DirectionIcon" size={IconSize.xmedium} color={Color.primary} />
                </MuiGrid>
                <MuiGrid item xs>
                  <MuiTypography fontSize={FontSize.xsmall} data-testid="provider-primary-location">
                    {showDistance(doctor, selectedSpecialty, geolocationStatus) ? (
                      <>
                        {' '}
                        {doctor?.distance} <br />{' '}
                      </>
                    ) : null}
                    {doctor?.primaryLocation?.name}
                  </MuiTypography>
                </MuiGrid>
              </MuiGrid>
            ) : null}
            {/* Video visit */}
            {doctor?.offersVideoVisits ? (
              <MuiGrid container direction="row" alignItems="center" item sm={6} spacing={1}>
                <MuiGrid item>
                  <Svg name="VideoVisit" size={IconSize.xmedium} color={Color.primary} />
                </MuiGrid>
                <MuiGrid item xs>
                  <MuiTypography
                    data-testid="provider-primary-has-video-visits-available"
                    fontSize={FontSize.xsmall}
                  >
                    Video visits available
                  </MuiTypography>
                </MuiGrid>
              </MuiGrid>
            ) : null}
          </MuiGrid>
        </MuiGrid>
        <MuiGrid item>
          <Svg name="ChevronRight" width={10} />
        </MuiGrid>
      </MuiGrid>
    </MuiGrid>
  </BookingPanelListItem>
);

export default Provider;
