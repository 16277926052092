import React from 'react';
import { DOC_PHONE_COLORS } from 'store/booking/constants';

export default {
  svg: (
    <svg xmlns="http://www.w3.org/2000/svg">
      <g style={{ clipPath: 'circle(50% at 50% 50%)', WebkitClipPath: 'circle(50% at 50% 50%)' }}>
        <circle fill={DOC_PHONE_COLORS.st0} cx="152" cy="140" r="140" />
        <defs>
          <clipPath id="circClipPath">
            <circle cx="170" cy="131" r="150" />
          </clipPath>
        </defs>
        <g>
          <g fill={DOC_PHONE_COLORS.st1}>
            <path
              fill={DOC_PHONE_COLORS.st2}
              d="M195.33,254c-1.48,0.44-3,0.2-4.5,0.2c-0.98,0-1.58,0.31-2.09,1.19c-14.37,24.95-28.77,49.88-43.16,74.82
      c-0.34,0.58-0.73,1.14-0.84,1.83c-0.6,0.74-1.1,0.04-1.53-0.19c-2.75-1.51-5.45-3.12-8.17-4.69
      c-12.29-7.1-24.59-14.18-36.87-21.28c-7.5-4.34-15.01-8.66-22.5-13.02c-0.61-0.36-1.28-0.67-1.61-1.36
      c4.1-7.1,8.25-14.16,12.28-21.3c3.68-6.52,4.4-13.41,2.32-20.64c-5-17.4-9.98-34.81-14.84-52.24c-1.01-3.63-2.28-7.22-2.72-10.99
      c-0.45-3.9-0.06-7.72,1.57-11.33c5.94-13.16,11.9-26.32,17.85-39.47c1.38-3.04,2.77-6.08,4.13-9.14c1.04-2.33,2.27-2.81,4.53-1.62
      c0.87,0.46,1.13,0.26,1.12-0.67c-0.03-2.15-0.01-4.3-0.01-6.45c0.12-0.11,0.25-0.15,0.41-0.1c0.54,0.34,0.42,0.91,0.44,1.4
      c0.04,1.43,0.03,2.86,0.02,4.3c-0.01,1.23,0.41,2.22,1.36,3.04c0.75,0.65,1.53,1.27,2.23,1.98c0.51,0.52,0.84,0.34,0.92-0.35
      c0.06-0.47,0.03-0.95,0.03-1.43c0-23.67,0-47.33,0-71c0-0.64-0.03-1.27,0.05-1.91c0.06-0.51,0.19-0.96,0.86-0.87
      c0.48,0.24,0.45,0.71,0.49,1.14c0.06,0.67,0.03,1.35,0.03,2.03c0,24.12,0.01,48.24-0.02,72.37c0,1.8,0.37,3.4,1.2,5.02
      c2.96,5.76,3.23,11.72,1.07,17.81c-0.37,1.05-0.72,2.11-1.14,3.14c-0.86,2.1-1.17,4.26-1.14,6.53c0.07,5.49,0.02,10.99,0.03,16.48
      c0,0.79,0.1,1.61-0.12,2.34c-0.28,0.94,0.63,1.68,0.26,2.57c-0.15,1.03-0.13,2.06-0.13,3.1c0,16.64,0,33.27-0.01,49.91
      c0,0.81,0.19,1.68-0.36,2.42c-0.63,0.29-0.88-0.06-0.99-0.61c-0.1-0.51-0.07-1.03-0.07-1.55c0-24.44,0-48.88,0-73.31
      c0-0.62-0.02-1.25,0.16-1.86c-1.17,3.85-2.72,7.57-4.05,11.37c-0.44,1.26-0.65,2.52-0.65,3.86c0.01,20.3,0.01,40.6,0.01,60.9
      c0,9.4,7.6,17.02,17,17.02c24.75,0.01,49.49,0,74.24,0.01c0.64,0,1.27-0.02,1.91,0.03C194.71,253.45,195.19,253.43,195.33,254z"
            />
            <path fill={DOC_PHONE_COLORS.st3} d="M38.51,351.22" />
            <path
              fill={DOC_PHONE_COLORS.st3}
              d="M56.28,321.29c5.92-9.94,11.84-19.87,17.76-29.8c0.6-0.01,1.05,0.34,1.53,0.61
      c22.98,13.28,45.96,26.56,68.95,39.84c0.07,0.04,0.15,0.06,0.22,0.09c1.89,1.1,3.75,2.24,5.67,3.27c0.88,0.47,1.02,0.98,0.74,1.9
      c-1.58,5.24-3.1,10.49-4.64,15.74c-0.08,0.26-0.11,0.54-0.16,0.81c-1.01,2.45-1.01,2.45-3.74,2.45c-35.16,0-70.32,0-105.48-0.03
      c-0.48,0-1.23,0.47-1.49-0.44"
            />
            <path
              fill={DOC_PHONE_COLORS.st3}
              d="M106.47,52.83c-0.26,0.65-0.17,1.34-0.17,2.01c0,24.7,0,49.4-0.01,74.09c0,0.55,0.09,1.11-0.12,1.68
      c-0.11-0.02-0.27,0.01-0.32-0.05c-1.43-1.77-3.03-3.37-4.88-4.7c-0.5-0.36-0.44-0.9-0.44-1.41c-0.01-2.27-0.02-4.53-0.03-6.8
      c-0.47-0.45-0.55-1.02-0.55-1.63c-0.02-5.71-0.21-11.42,0.1-17.12c0.11-2.08,0.29-4.19,0.36-6.29c0.02-0.43,0.05-0.87-0.13-1.29
      c-0.27-0.88-0.35-1.79-0.35-2.71c0.01-4.73-0.33-9.49,0.08-14.19c0.64-7.25,0.2-14.49,0.4-21.73c0-0.13,0.06-0.23,0.09-0.34
      c0.27-7.61,6.65-14.75,14.41-16.13c1.34-0.24,2.68-0.32,4.04-0.32c24.62,0.01,49.23,0,73.85,0.01c9.84,0,16.92,6.02,18.53,15.76
      c0.19,0.42,0.1,0.86,0.1,1.29c0.01,7.65,0,15.3,0.01,22.95c0,0.43-0.05,0.87,0.05,1.3c0.38,1.31,0.42,2.63,0.29,4
      c-0.18,1.93-0.28,3.88,0.16,5.82c0.3,1.33,0.1,2.69,0.07,4.04c-0.21,8.19-0.05,16.39-0.09,24.58c-0.01,1.62,0.04,3.24-0.22,4.85
      c-1.64,1.45-3.16,3.02-4.74,4.53c-0.33,0.32-0.66,0.76-1.22,0.53c-0.62-0.26-0.49-0.85-0.5-1.35c-0.01-2.07-0.01-4.14-0.01-6.2
      c0-16.98-0.03-33.96,0.02-50.94c0.01-4.69-0.12-9.36-0.5-14.03c-0.38-5.79-4.87-10.21-10.71-10.54c-0.48-0.03-0.95-0.02-1.43-0.02
      c-24.45,0-48.91,0-73.36,0c-1.28,0-2.55,0.06-3.78,0.42c-4.2,1.21-6.91,3.91-8.11,8.13C107.18,51.66,107.35,52.53,106.47,52.83z"
            />
            <path
              fill={DOC_PHONE_COLORS.st2}
              d="M211.62,77.18c4.05-1.92,8-1.93,11.76,0.69c5.5,3.84,6.22,11.72,1.51,16.5c-2.82,2.86-5.68,5.68-8.51,8.53
      c-0.5,0.51-0.96,1.06-1.44,1.59c-0.04-0.03-0.08-0.06-0.12-0.08c-0.61-0.4-0.5-1.04-0.51-1.62c-0.03-2.42-0.03-4.85-0.01-7.27
      c0.01-1.74-0.44-3.21-2.02-4.18c-0.64-0.39-0.89-0.96-0.88-1.76c0.05-4.01,0.02-8.03,0.02-12.04
      C211.42,77.38,211.48,77.26,211.62,77.18z"
            />
            <path
              fill={DOC_PHONE_COLORS.st4}
              d="M100.51,52.36c0.02,6.08,0.06,12.15,0.06,18.23c0,6.91-0.03,13.82-0.05,20.73c-1.17-0.04-2.01-0.73-2.86-1.42
      C97.1,89.26,97,88.47,97,87.67c-0.01-4.09-0.02-8.18,0-12.27c0.01-1.91,0.76-3.02,2.53-3.59c0.87-0.28,0.82-0.83,0.82-1.47
      c0-5.36,0-10.73,0.01-16.09C100.36,53.62,100.3,52.98,100.51,52.36z"
            />
            <path
              fill={DOC_PHONE_COLORS.st4}
              d="M97.66,89.9c0.95,0.47,1.9,0.95,2.86,1.42c0,5,0,10.01,0,15.01c0,3.77-0.01,7.55-0.02,11.32
      c-0.08-0.01-0.15-0.01-0.23-0.01c-2.49-0.71-3.26-1.69-3.27-4.25c-0.01-3.85-0.01-7.7,0-11.56c0.01-2.24,0.62-3.16,2.68-3.97
      c0.43-0.17,0.66-0.38,0.66-0.85c0-1.59-0.01-3.18,0.01-4.77c0-0.53-0.26-0.77-0.74-0.91C98.79,91.11,98.07,90.71,97.66,89.9z"
            />
            <path
              fill={DOC_PHONE_COLORS.st5}
              d="M211.34,77.21c0-8.51,0-17.02-0.01-25.53c0.33,0.72,0.23,1.49,0.23,2.24c0.01,7.12,0.01,14.24,0.01,21.36
      c0,0.64,0.03,1.27,0.04,1.91c-0.04,0.04-0.09,0.07-0.13,0.11C211.4,77.34,211.35,77.31,211.34,77.21z"
            />
            <path
              fill={DOC_PHONE_COLORS.st3}
              d="M195.33,254c-2.35,0.01-4.69,0.04-7.04,0.04c-23.28,0-46.55,0.02-69.83-0.01
      c-9.02-0.01-16.22-6.09-17.81-14.92c-0.21-1.14-0.2-2.3-0.2-3.45c-0.01-20.38-0.01-40.76-0.02-61.14c0-0.6,0.05-1.19,0.26-1.76
      c1.7-4.63,3.39-9.27,5.09-13.9c0.05-0.14,0.14-0.28,0.28-0.57c0.36,0.66,0.23,1.24,0.23,1.78c-0.01,25.12-0.02,50.23-0.03,75.35
      c0,0.71-0.11,1.44,0.21,2.12c0.77,0.18,0.69,0.91,0.86,1.43c0.9,2.77,2.36,5.11,4.88,6.7c1.87,1.18,3.88,1.77,6.1,1.77
      c7.68-0.01,15.35-0.01,23.03,0c9.92,0,19.84-0.01,29.76,0c7.36-0.01,14.72-0.03,22.07,0.02c2.16,0.01,4.12-0.6,6.08-1.35
      c0.4-0.15,0.56-0.51,0.8-0.8c0.13-0.09,0.27-0.16,0.42-0.22c2.38,0.03,4.76-0.12,7.14,0.01c0.5,0.03,0.95,0.18,0.88,0.84
      c-2.4,3.77-5.79,6.21-10.05,7.45C197.43,253.66,196.42,254.05,195.33,254z"
            />
            <path
              fill={DOC_PHONE_COLORS.st6}
              d="M205.38,52.83c-0.02,0.72-0.06,1.43-0.06,2.15c0,22.92-0.01,45.84,0.02,68.77c0,1.34-0.4,2.3-1.34,3.25
      c-2.07,2.13-4.52,3.93-5.99,6.6c-2.92,5.31-1.11,12.85,5.54,15.48c1.35,0.54,1.75,1.2,1.81,2.6c0.08,1.87-0.45,3.23-1.85,4.49
      c-1.65,1.48-3.26,3.03-4.63,4.81c-1.33,1.73-2.04,3.67-2.15,5.84c-0.68,0.4-0.94-0.24-1.24-0.57c-2.44-2.67-5.14-5.06-8.12-7.12
      c-3.12-2.15-6.46-3.86-10.01-5.18c-0.33-0.12-0.7-0.19-0.99-0.39c-0.68-0.46-1.4-0.61-2.19-0.42c-0.69-0.47-1.3-1.05-1.93-1.59
      c-0.95-0.81-1.56-1.75-1.44-3.14c0.14-1.66,0.03-3.33,0.03-5c-0.05-1.82,0.53-3.33,1.74-4.76c4.33-5.1,7.06-11.02,8.32-17.59
      c0.3-1.56,0.45-3.14,0.45-4.74c0.02-4.89-0.13-9.78,0.09-14.67c0.06-1.41-0.01-2.82,0.31-4.24c0.24-1.05,0.18-2.21-0.13-3.31
      c-0.32-1.12-0.22-2.29-0.3-3.44c-0.38-3.81-1.44-7.41-3.37-10.72c-0.65-1.12-0.65-1.99,0.2-3.12c4.23-5.66,4.34-12.68,0.3-18.34
      c-2.47-3.45-5.89-5.5-10.11-6.16c-8.64-1.35-16.79,4.95-17.53,13.96c-0.08,1.02-0.44,1.58-1.46,1.86
      c-10.32,2.88-17.99,12.07-18.85,23.22c-0.05,1.35-0.01,2.71-0.42,4.02c-0.06,0.19-0.08,0.39-0.06,0.59
      c0.84,7.12,0.24,14.28,0.51,21.41c0.16,4.05,1.38,7.91,3,11.61c1.67,3.81,3.77,7.37,6.66,10.4c1.04,1.09,1.35,2.35,1.28,3.78
      c-0.05,1.85,0.01,3.7,0.04,5.55c0.01,0.94-0.36,1.6-0.99,2.21c-0.68,0.65-1.42,1.25-2.07,1.93c-5.73,1.19-10.74,3.9-15.4,7.32
      c-6.35,4.66-11.04,10.66-14.25,17.84c-0.52,1.16-0.93,2.36-1.43,3.53c-0.15,0.36-0.25,0.81-0.82,0.57
      c-0.01-8.08-0.02-16.16-0.01-24.24c0-1.59,0.81-2.98,1.32-4.44c0.81-2.33,1.77-4.61,2.08-7.08c0.6-4.85-0.19-9.45-2.67-13.69
      c-0.53-0.91-0.75-1.79-0.75-2.81c0.01-24.96,0.01-49.92,0.01-74.87c0-0.72-0.04-1.43-0.06-2.15c0.28-5.75,5.28-10.66,11.15-10.92
      c0.79-0.04,1.59-0.02,2.39-0.02c24.42,0,48.84,0,73.27,0C199.78,41.89,205.07,46.69,205.38,52.83z"
            />
            <path
              fill={DOC_PHONE_COLORS.st7}
              d="M106.55,182.12c2.25-6.85,5.87-12.88,10.94-17.98c5.48-5.51,11.97-9.42,19.44-11.65
      c0.35-0.1,0.69-0.2,1.04-0.03c0.23,0.91-0.5,1.39-0.95,1.93c-1.84,2.17-3.4,4.5-4.6,7.09c-2.74,5.91-3.8,12.1-3.18,18.59
      c0.09,0.91,0.21,1.76-0.97,2.25c-9.57,4-14.2,13.21-11.58,23.24c2.03,7.78,4.11,15.54,6.25,23.29c0.41,1.5,0.71,3.02,1.26,4.48
      c0.98,2.54,3.5,4.26,6.18,4.21c0.2,0,0.37-0.12,0.55-0.19c0.37-0.08,0.67,0.19,1.02,0.23c0.51,0.05,0.95-0.07,1.2-0.55
      c0.25-0.47,0.18-0.92-0.19-1.3c-0.38-0.38-0.83-0.37-1.31-0.19c-0.37,0.14-0.57,0.55-1.01,0.57c-3.16-0.03-4.81-1.32-5.64-4.41
      c-2.36-8.84-4.8-17.65-7.08-26.51c-2.96-11.51,4.55-20.97,14.19-22.55c2.74-0.45,5.56-0.45,8.25,0.55
      c0.61,0.23,1.36,0.35,1.37,1.26c0.18,0.74,0.23,1.49,0.23,2.25c0,19.65,0,39.31-0.01,58.96c0,0.87,0.27,1.88-0.82,2.4
      c-7.95,0-15.9,0.15-23.84-0.04c-5.56-0.14-10.15-4.82-10.81-10.46c0.02-0.48,0.05-0.95,0.05-1.43c0-17.52,0-35.04,0-52.56
      C106.52,183.07,106.54,182.59,106.55,182.12z"
            />
            <path
              fill={DOC_PHONE_COLORS.st4}
              d="M211.34,77.21c0.05,0.03,0.1,0.05,0.15,0.08c0.01,3.9,0.05,7.79,0.03,11.69c-0.01,0.98,0.19,1.63,1.23,2
      c1.5,0.53,2.09,1.82,2.1,3.3c0.04,3.38-0.01,6.76-0.03,10.14c-0.29,1.69-0.11,3.4-0.13,5.1c-0.03,2.69-0.03,5.39,0,8.08
      c0.01,0.83-0.27,1.31-1.1,1.57c-0.86,0.28-1.66,0.73-2.49,1.11c0.26-0.69,0.23-1.41,0.23-2.12c0-8.16,0-16.32,0-24.47
      C211.33,88.19,211.33,82.7,211.34,77.21z"
            />
            <path
              fill={DOC_PHONE_COLORS.st7}
              d="M190.2,247.97c-0.61,0.36-1.28,0.39-1.96,0.39c-5.15,0.01-10.31,0.01-15.46,0c-0.68,0-1.35-0.02-1.95-0.39
      c-0.56-0.81-0.46-27.53-0.46-28.43c-0.01-17.31-0.01-34.62,0-51.93c0-2.15-0.14-4.31,0.17-6.45c0.13-0.9-0.65-1.69-0.34-2.62
      c0.32-1.67-0.01-3.39,0.3-5.06c0.65-0.74,1.26-0.55,1.94,0c3.04,2.48,5.36,5.54,7.24,8.93c3.25,5.86,4.86,12.15,4.53,18.88
      c-0.07,1.55-0.35,2.05-1.43,2.36c-2.46,0.56-4.09,2.21-4.47,4.51c-0.34,2.09,0.7,4.38,2.47,5.42c2.07,1.22,4.46,1.09,6.36-0.36
      c2.72-2.07,2.7-6.26-0.04-8.5c-0.4-0.33-0.88-0.53-1.22-0.94c-0.42-3.44-0.21-6.93-0.88-10.36c-1.49-7.58-4.76-14.2-10.25-19.68
      c-0.31-0.31-0.54-0.59-0.24-1.02c0.48-0.27,0.93-0.06,1.37,0.08c6.39,2.08,12.06,5.38,17,9.97c1.37,1.27,2.62,2.64,3.84,4.04
      c-0.2,5.38,2.4,8.99,7.11,11.25c1.03,0.56,1.1,1.58,1.28,2.57c0.08,0.42,0.03,0.86,0.17,1.27c0.01,4.5-0.23,69.25-0.96,69.36
      c-0.47,0.07-0.95,0.05-1.43,0.05c-3.25,0.01-6.51-0.02-9.76,0.01c-1.98,0.02-2.47,0.51-2.48,2.48L190.2,247.97"
            />
            <path fill={DOC_PHONE_COLORS.st8} d="M205.6,205.28" />
            <path
              fill={DOC_PHONE_COLORS.st9}
              d="M209.87,149.98c-1.18,1.17-2.35,2.35-3.54,3.52c-0.16,0.16-0.34,0.42-0.59,0.25
      c-0.12-0.08-0.13-0.34-0.18-0.53c-0.02-0.07,0-0.16-0.01-0.24c-0.16-3.02-0.16-3.02,2.79-3.15c0.47-0.02,0.94-0.07,1.4-0.1
      C209.79,149.81,209.83,149.9,209.87,149.98z"
            />
            <path fill={DOC_PHONE_COLORS.st10} d="M170.52,222.46" />
            <path
              fill={DOC_PHONE_COLORS.st10}
              d="M159.18,221.86c0.46-0.64,0.02-1.24-0.14-1.82c-2.13-7.87-4.24-15.75-6.32-23.63
      c-1.11-4.23-3.47-7.55-6.72-10.31c-1.06-0.9-2.25-1.64-3.58-2.11c-0.41-0.14-0.85-0.37-1.08,0.27c0,20.71,0.01,41.42,0.01,62.13
      c0,0.56-0.02,1.11-0.24,1.64c6.66,0.02,13.32,0.04,19.98,0.05c3.37,0,6.74-0.03,10.1-0.05"
            />
            <path
              fill={DOC_PHONE_COLORS.st7}
              d="M171.22,246.86c9.13,0,18.26,0,27.38,0c0.59,0,1.2-0.05,1.75,0.25c-1.67,1.32-3.61,2.1-5.7,2.12
      c-7.81,0.07-15.63,0.03-23.44,0.02C170.92,248.46,170.88,247.66,171.22,246.86z"
            />
            <path
              fill={DOC_PHONE_COLORS.st11}
              d="M181.95,90.38c0.21,0.4,0.23,0.84,0.23,1.28c-0.07,8.79,0.06,17.59-0.3,26.36
      c-0.28,6.84-3.03,12.96-6.88,18.56c-0.61,0.88-1.24,1.77-1.98,2.53c-1.18,1.21-1.75,2.62-1.82,4.27
      c-0.09,0.08-0.19,0.14-0.31,0.15c-0.65-0.45-1.14-0.29-1.76,0.21c-3.96,3.15-8.38,5.16-13.57,5.01c-3.84-0.11-7.3-1.4-10.47-3.54
      c-0.76-0.51-1.49-1.06-2.24-1.59c-0.42-0.3-0.85-0.61-1.42-0.34c-0.12-0.01-0.21-0.07-0.29-0.15c0.02-1.45-0.7-2.54-1.62-3.59
      c-5.37-6.07-8.48-13.18-9.57-21.19c-0.05-0.35-0.06-0.71-0.06-1.07c-0.08-8.15-0.16-16.3-0.23-24.45
      c-0.01-0.59-0.05-1.2,0.22-1.76c0.76,0.13,0.58,0.77,0.59,1.23c0.05,1.71,0,3.42,0.03,5.13c0.02,1.44,0.42,1.8,1.83,1.59
      c5.62-0.84,11.03-2.41,16.22-4.71c6.12-2.71,11.63-6.39,16.64-10.81c0.9-0.8,1.33-0.77,2.18,0.21c2.75,3.2,5.53,6.36,8.29,9.55
      c1.78,2.06,3.55,4.15,5.47,6.16c0.36-0.57,0.21-1.1,0.22-1.6c0.02-2.07-0.02-4.14,0.03-6.21
      C181.38,91.16,181.19,90.53,181.95,90.38z"
            />
            <path
              fill={DOC_PHONE_COLORS.st3}
              d="M181.95,90.38c0,3.45,0,6.91,0,10.42c-0.71,0-0.94-0.48-1.23-0.81c-4.55-5.21-9.11-10.42-13.63-15.67
      c-0.64-0.74-1.05-0.81-1.81-0.13c-9.69,8.63-20.97,13.84-33.83,15.61c-1.39,0.19-1.52,0.09-1.53-1.32
      c-0.02-2.47-0.02-4.93-0.03-7.4c1.16-10.06,6.17-17.46,15.23-22.05c1.24-0.63,2.55-1.12,3.91-1.46c0.84-0.2,1.12-0.63,1.19-1.48
      c0.7-8.21,7.51-14.61,15.69-14.57c6.54,0.03,11.52,3.02,14.42,8.89c2.86,5.78,2.22,11.45-1.59,16.65c-0.73,1-0.82,1.65-0.18,2.7
      C180.53,83.02,181.52,86.62,181.95,90.38z"
            />
            <path
              fill={DOC_PHONE_COLORS.st12}
              d="M170.98,143.41c0.07,0,0.14-0.01,0.22-0.02c0,1.69,0.05,3.39,0,5.08c-0.03,0.95,0.24,1.67,1,2.26
      c0.81,0.63,1.56,1.33,2.33,1.99c7.81,7.37,11.38,16.56,11.74,27.15c0.04,1.22-0.26,2.47,0.21,3.66c-0.05,0.11-0.11,0.27-0.22,0.26
      c-1.21-0.03-2.42-0.12-3.61-0.37c-0.15-0.03-0.2-0.23-0.23-0.39c0.96-0.23,1.24-0.84,1.25-1.82c0.15-9.66-2.91-18.12-9.45-25.26
      c-0.91-1-1.76-2.18-3.23-2.49c-0.65-0.21-0.61-0.79-0.6-1.27c0.04-2.13-0.25-4.27,0.19-6.39c0.13-0.59-0.18-1.21-0.01-1.82
      C170.61,143.72,170.74,143.52,170.98,143.41z"
            />
            <path
              fill={DOC_PHONE_COLORS.st13}
              d="M131.07,238c-0.71-0.78-0.86-1.64-0.41-2.6c0.72-0.79,1.59-1.04,2.54-0.49c0.76,0.44,1.02,1.18,0.82,2.04
      C133.72,238.19,132.57,238.59,131.07,238z"
            />
            <path
              fill={DOC_PHONE_COLORS.st10}
              d="M161.66,221.97c-1.17-3.38-1.92-6.88-2.87-10.33c-1.52-5.51-2.88-11.07-4.48-16.56
      c-1.96-6.71-6.35-11.22-12.91-13.63c-0.26-0.1-0.52-0.19-0.78-0.29c0.31-7.3,0.1-14.61,0.12-21.92c0-0.56-0.15-1.1-0.18-1.66
      c-0.07-1.47-0.14-2.94,0.34-4.37c0.14-0.08,0.29-0.17,0.43-0.25c0.76,0.13,0.58,0.76,0.6,1.23c0.05,1.31,0.02,2.61,0.23,3.92
      c0.96,5.98,4.37,9.98,10.09,11.62c5.6,1.61,10.63,0.15,14.64-4.2c1.61-1.74,2.61-3.88,3.17-6.2c0.1-0.43,0.06-1.15,0.9-0.9
      c0,12.26,0,24.51,0.01,36.77c0,8.91,0.01,17.83,0.02,26.74C167.88,221.96,164.77,221.97,161.66,221.97z"
            />
            <path
              fill={DOC_PHONE_COLORS.st11}
              d="M170.96,158.44c-0.58,2.11-1.21,4.19-2.47,6.04c-3.61,5.28-10.25,7.72-16.39,5.93
      c-6.2-1.8-10.49-7.31-10.7-13.74c-0.04-1.23-0.04-2.47-0.07-3.7c0.01-3.19,0.03-6.38,0.04-9.58c0.75-0.25,0.72,0.39,0.88,0.78
      c1.81,4.74,5.13,7.88,10.02,9.22c6.99,1.92,14.02-1.32,17.27-7.82c0.21-0.43,0.43-0.85,0.61-1.29c0.15-0.36,0.33-0.66,0.79-0.42
      c0.02,3.2,0.04,6.4,0.06,9.6C170.99,155.13,170.98,156.79,170.96,158.44z"
            />
            <path
              fill={DOC_PHONE_COLORS.st14}
              d="M170.94,143.87c-1.82,4.97-5.15,8.38-10.25,10c-8.07,2.56-16.76-2.03-19.18-10.15
      c-0.03-0.11-0.09-0.22-0.14-0.33c-0.01-0.08-0.02-0.16-0.03-0.23c0.07-0.38-0.15-0.91,0.25-1.1c0.5-0.24,0.73,0.34,1.05,0.6
      c3.89,3.22,8.2,5.49,13.39,5.49c5,0,9.24-2.02,13.03-5.14c0.12-0.1,0.23-0.23,0.35-0.32c0.4-0.31,0.81-0.95,1.25-0.79
      c0.61,0.23,0.17,0.99,0.31,1.5C170.97,143.56,170.96,143.72,170.94,143.87z"
            />
            <path
              fill={DOC_PHONE_COLORS.st15}
              d="M155.86,111.38c-2.4,0-2.4,0-2.46,2.34c-0.11,4.53-3.16,8.35-7.55,9.45c-4.36,1.09-8.86-0.85-11.11-4.79
      c-2.21-3.87-1.51-8.78,1.7-11.99c3.12-3.11,8.05-3.79,11.9-1.59c1.7,0.97,3.05,2.32,3.91,4.08c0.45,0.93,1.08,1.3,2.06,1.24
      c0.44-0.02,0.88-0.04,1.31,0.01c2.1,0.26,3.48-0.33,4.71-2.34c2.41-3.93,7.44-5.26,11.69-3.59c4.22,1.66,6.84,6.01,6.29,10.47
      c-0.56,4.58-4.1,8.12-8.68,8.7c-5.98,0.76-11.3-3.88-11.17-9.9c0.05-2.31-0.07-2.08-2.14-2.1
      C156.18,111.38,156.02,111.38,155.86,111.38z"
            />
            <path
              fill={DOC_PHONE_COLORS.st16}
              d="M155.9,138.64c-1.07,0-2.14,0.02-3.22,0c-1.26-0.02-2.32-0.5-3.14-1.46c-0.18-0.21-0.41-0.42-0.28-0.73
      c0.11-0.27,0.39-0.25,0.62-0.25c4.05-0.01,8.1-0.01,12.15,0c0.81,0,0.72,0.45,0.33,0.92c-0.81,0.97-1.86,1.48-3.12,1.51
      C158.13,138.66,157.01,138.63,155.9,138.64z"
            />
            <path
              fill={DOC_PHONE_COLORS.st7}
              d="M140.91,153.22c0.41,2.11,0.37,4.26,0.41,6.39c0.12,6.63,0.05,13.25,0.04,19.88c0,0.65,0.17,1.41-0.74,1.67
      c-2.83-0.75-5.69-1.02-8.6-0.56c-0.62,0.1-0.85-0.31-0.88-0.86c-0.58-8.77,1.34-16.81,7.03-23.72
      C138.99,155.02,139.88,154.05,140.91,153.22z"
            />
            <path
              fill={DOC_PHONE_COLORS.st11}
              d="M168.41,122.17c-4.86,0-8.69-3.86-8.67-8.73c0.02-4.78,3.94-8.69,8.67-8.65c4.82,0.04,8.75,4,8.71,8.76
      C177.08,118.31,173.17,122.18,168.41,122.17z"
            />
            <path
              fill={DOC_PHONE_COLORS.st11}
              d="M152.11,113.57c-0.02,4.84-3.91,8.64-8.8,8.6c-4.73-0.04-8.63-4.02-8.58-8.75c0.06-4.79,4.08-8.71,8.84-8.63
      C148.36,104.88,152.14,108.76,152.11,113.57z"
            />
            <path
              fill={DOC_PHONE_COLORS.st17}
              d="M215.62,166.85c-0.01,3.93-3.21,7.1-7.16,7.1c-3.77,0-7.06-3.31-7.09-7.12c-0.02-3.95,3.25-7.21,7.22-7.19
      C212.54,159.66,215.64,162.84,215.62,166.85z"
            />
            <circle fill={DOC_PHONE_COLORS.st5} cx="206.13" cy="168.43" r="11.05" />
            <rect x="151.34" y="210.01" fill={DOC_PHONE_COLORS.st10} width="19.65" height="38.07" />
            <path fill={DOC_PHONE_COLORS.st12} d="M161.68,222.21" />
            <path
              fill={DOC_PHONE_COLORS.st12}
              d="M158.8,221.5c-2.3-8.57-4.5-17.17-6.94-25.7c-1.57-5.5-5.05-9.48-10.51-11.55
      c-4.25-1.89-8.56-1.75-12.76-0.02c-8.22,3.39-12.4,12.1-10.1,20.7c2.38,8.88,4.75,17.76,7.14,26.64c0.75,2.81,2.58,4.19,5.03,3.82
      c0.53,0.81,0.78,1.66,0.41,2.6c-4.24,0.14-6.72-1.57-7.83-5.63c-2.47-9.05-4.96-18.1-7.28-27.19c-2.48-9.73,2.39-19.4,11.6-23.27
      c0.99-0.41,1.13-0.94,1.06-1.91c-0.76-10.5,1.94-19.84,9.36-27.56c0.31-0.31,0.56-0.7,0.93-0.9c1.97-1.12,2.51-2.82,2.25-4.98
      c-0.14-1.13-0.01-2.28-0.01-3.43c0.07,0.01,0.14,0.01,0.21,0.02c0.14,0.08,0.25,0.19,0.3,0.34c0.26,3.12,0.44,6.23,0.21,9.36
      c-0.05,0.31-0.21,0.54-0.5,0.66c-2.24,1.99-4.07,4.32-5.55,6.92c-3.15,5.53-4.44,11.5-4.12,17.84c0.06,1.23,0.35,1.55,1.61,1.52
      c2.51-0.04,5,0,7.41,0.79c7.39,2.4,11.73,7.35,13.83,14.85c2.29,8.15,5.16,20.08,7.37,28.24"
            />
            <circle fill={DOC_PHONE_COLORS.st13} cx="132.45" cy="235.97" r="2.46" />
            <path
              fill={DOC_PHONE_COLORS.st3}
              d="M124.37,254.21c22.96,0.05,45.93,0.11,68.89,0.14c8.9,0.01,16.01-6.09,17.6-14.96
      c0.21-1.15,0.2-2.31,0.2-3.47c0.05-20.49,0.1-40.98,0.15-61.46c0-0.6-0.04-1.19-0.25-1.77c-1.67-4.66-3.32-9.33-4.99-13.99
      c-0.05-0.15-0.13-0.28-0.28-0.58c-0.35,0.66-0.23,1.25-0.23,1.79c-0.05,25.25-0.08,50.5-0.13,75.75c0,0.71,0.11,1.45-0.21,2.13
      c-0.76,0.18-0.68,0.91-0.85,1.43c-0.89,2.79-2.34,5.13-4.83,6.72c-1.85,1.18-3.83,1.77-6.02,1.76
      c-7.57-0.03-15.14-0.04-22.72-0.05c-9.79-0.02-19.57-0.05-29.36-0.06C134.09,247.57,122.06,254.2,124.37,254.21z"
            />
            <circle fill={DOC_PHONE_COLORS.st2} cx="214.73" cy="195.45" r="11.05" />
            <path
              fill={DOC_PHONE_COLORS.st2}
              d="M205.38,52.83c0.26,0.65,0.17,1.33,0.17,2.01c0,22.82,0,45.64,0.02,68.45c0,0.56-0.32,1.4,0.27,1.63
      c0.57,0.22,0.92-0.61,1.34-1c1.3-1.23,2.48-2.58,3.92-3.65c0.53-0.69,1.1-1.29,1.92-1.68c0.96-0.46,1.03-1.42,1.03-2.36
      c0.01-3.41-0.01-6.81,0.02-10.22c0.01-0.56-0.16-1.22,0.48-1.61c0.15-0.04,0.28-0.01,0.39,0.09c0,4.22,0.02,8.44-0.02,12.65
      c-0.01,1.08,0.2,1.54,1.45,1.47c4.77-0.26,8.81,2.28,10.69,6.54c1.85,4.2,0.95,8.82-2.4,12.2c-2.94,2.97-5.91,5.91-8.85,8.87
      c-0.3,0.3-0.71,0.52-0.78,0.99c0.22,0.3,0.52,0.18,0.78,0.15c4.77-0.44,9.03,1.9,11.07,6.1c2.05,4.22,1.31,9.05-2.02,12.49
      c-3.07,3.17-6.23,6.26-9.36,9.37c-0.79,0.78-1.65,1.53-2.69,1.96c-1.01,0.42-7.96,1.46-9.04,1.41c-1.71-0.78-3.21-1.84-4.52-3.2
      c-1.96-2.03-2.97-4.45-3.09-7.26c-0.02-0.56-0.09-1.1-0.15-1.66c0.15-0.53,0.31-1.05,0.39-1.61c0.32-2.32,1.67-4.1,3.18-5.76
      c1.28-1.41,2.69-2.72,4.03-4.07c0.77-0.78,1.12-1.69,1.15-2.79c0.04-1.34-0.44-2.23-1.73-2.81c-2.98-1.36-5.23-3.54-6.26-6.69
      c-1.41-4.32-0.79-8.34,2.31-11.8c1.27-1.42,2.64-2.76,4.04-4.06c1.2-1.11,1.65-2.41,1.65-4.03c-0.03-14.57-0.02-29.14-0.02-43.7
      c0-8.2-0.01-16.4,0.02-24.6C204.77,54.04,204.44,53.2,205.38,52.83z"
            />
            <path
              fill={DOC_PHONE_COLORS.st12}
              d="M152.93,197.24c2.12,8.98,4.24,17.96,6.35,26.94c0.67,2.84-0.34,4.98-2.78,5.91
      c-0.1,0.98,0.09,1.85,0.89,2.48c3.97-2.04,5.42-4.79,4.47-8.91c-2.11-9.18-5.04-17.85-7.31-26.98"
            />
            <circle fill={DOC_PHONE_COLORS.st13} cx="157.01" cy="231.06" r="2.46" />
            <circle fill={DOC_PHONE_COLORS.st13} cx="184.03" cy="188.08" r="6.14" />
          </g>
        </g>
      </g>
    </svg>
  ),
  viewBox: '0 0 375 375'
};
