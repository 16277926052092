import styled from 'styled-components';
import { Color } from 'modules/styles/colors';

interface FixedLayoutProps {
  leftAlign?: boolean;
  centered?: boolean;
  backgroundColor?: string;
}

const FixedLayout = styled.div<FixedLayoutProps>`
  background-color: ${props => (props.backgroundColor ? props.backgroundColor : Color.baseColor)};
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: ${props => (props.leftAlign ? 'flex-start' : 'center')};
  justify-content: ${props => (props.centered ? 'center' : 'flex-start')};
`;

export default FixedLayout;
