import { SvgProps } from 'components/UI/Svg/Svg';
import AccountManagement from './AccountManagement';
import AccountShare from './AccountShare';
import Add from './Add';
import AddPerson from './AddPerson';
import Ambulance from './Ambulance';
import Appointment from './Appointment';
import Avatar from './Avatar';
import AvatarDefault from './avatars/AvatarDefault';
import Avatar01 from './avatars/Avatar01';
import Avatar02 from './avatars/Avatar02';
import Avatar03 from './avatars/Avatar03';
import Avatar04 from './avatars/Avatar04';
import Avatar05 from './avatars/Avatar05';
import Avatar06 from './avatars/Avatar06';
import Avatar07 from './avatars/Avatar07';
import Avatar08 from './avatars/Avatar08';
import Avatar09 from './avatars/Avatar09';
import Avatar10 from './avatars/Avatar10';
import Avatar11 from './avatars/Avatar11';
import Avatar12 from './avatars/Avatar12';
import Avatar13 from './avatars/Avatar13';
import Avatar14 from './avatars/Avatar14';
import Avatar15 from './avatars/Avatar15';
import Avatar16 from './avatars/Avatar16';
import Avatar17 from './avatars/Avatar17';
import Avatar18 from './avatars/Avatar18';
import Avatar19 from './avatars/Avatar19';
import Avatar20 from './avatars/Avatar20';
import Avatar21 from './avatars/Avatar21';
import Avatar22 from './avatars/Avatar22';
import Avatar23 from './avatars/Avatar23';
import Avatar24 from './avatars/Avatar24';
import Avatar25 from './avatars/Avatar25';
import Avatar26 from './avatars/Avatar26';
import Avatar27 from './avatars/Avatar27';
import Avatar28 from './avatars/Avatar28';
import Avatar29 from './avatars/Avatar29';
import Avatar30 from './avatars/Avatar30';
import Bill from './Bill';
import Bookmark from './Bookmark';
import Calculate from './Calculate';
import Calendar from './Calendar';
import CalendarFlat from './CalendarFlat';
import CalendarPin from './CalendarPin';
import CalendarThreeDots from './CalendarThreeDots';
import CAlphaHealthRecord from './CAlphaHealthRecord';
import CardiacTests from './CardiacTests';
import CheckCircle from './CheckCircle';
import ChevronDown from './ChevronDown';
import ChevronLeft from './ChevronLeft';
import ChevronRight from './ChevronRight';
import CheckCircleFilled from './CheckCircleFilled';
import ClinicNotes from './ClinicNotes';
import CloseOutline from './CloseOutline';
import CloseIcon from './CloseIcon';
import CloseCircleOutline from './CloseCircleOutline';
import Collections from './Collections';
import ClockCircle from './ClockCircle';
import Comment from './Comment';
import CompletedEVisit from './CompletedEVisit';
import ConnectCare from './ConnectCare';
import ConnectCareCancel from './ConnectCareCancel';
import ConnectCareIcon from './ConnectCareIcon';
import ConnectCareLogo from './ConnectCareLogo';
import ConnectCareTextMe from './ConnectCareTextMe';
import CostEstimatorIcon from './CostEstimatorIcon';
import COVID19 from './COVID19';
import COVID19ModalHospital from './COVID19ModalHospital';
import COVID19ModalMask from './COVID19ModalMask';
import CovidIcon from './CovidIcon';
import CreditCard from './CreditCard';
import DefenseShield from './DefenseShield';
import Detail from './Detail';
import Directions from './Directions';
import DirectionFilled from './DirectionFilled';
import DocPhone from './DocPhone';
import Dot from './Dot';
import DownloadIcon from './DownloadIcon';
import Education from './Education';
import Email from './Email';
import Endoscopy from './Endoscopy';
import ExclamationCircle from './ExclamationCircle';
import FileAttachment from './FileAttachment';
import Filter from './Filter';
import FindProviderIcon from './FindProvider';
import HeaderMyHealthPlus from './HeaderMyHealthPlus';
import HealthRecordBannerLg from './HealthRecordBannerLg';
import HealthRecordBannerSm from './HealthRecordBannerSm';
import HealthTool from './HealthTool';
import HelpIcon from './HelpIcon';
import Home from './Home';
import Hospital from './Hospital';
import HospitalShield from './HospitalShield';
import HrDownload from './HrDownload';
import HSA from './HSA';
import IconBubble from './Icon-Bubble';
import IconGoals from './Icon-Goals';
import IllustrationsHealthRecord from './IllustrationsHealthRecord';
import InfoIcon from './InfoIcon';
import InfoIconFilled from './InfoIconFilled';
import DirectionIcon from './DirectionIcon';
import Intermountain from './Intermountain';
import IntermountainGreyed from './IntermountainGreyed';
import IntermountainSolid from './IntermountainSolid';
import Language from './Language';
import LgbtqIcon from './LgbtqIcon';
import Lock from './Lock';
import MagnifyingGlass from './MagnifyingGlass';
import MailOutline from './MailOutline';
import MedicalCross from './MedicalCross';
import MedicalHistoryIcon from './MedicalHistoryIcon';
import MedicationListIcon from './MedicationListIcon';
import Messages from './Messages';
import Money from './Money';
import MyCareTeam from './MyCareTeam';
import MyDoctors from './MyDoctors';
import MyHealthPlusLogo from './MyHealthPlusLogo';
import NavBilling from './NavBilling';
import NavGetCare from './NavGetCare';
import NavHealthRecord from './NavHealthRecord';
import NavHome from './NavHome';
import NavMessages from './NavMessages';
import NerveAndMuscle from './NerveAndMuscle';
import NoAppointments from './NoAppointments';
import OfficeVisit from './OfficeVisit';
import PaymentHistory from './PaymentHistory';
import PaymentPlan from './PaymentPlan';
import Pencil from './Pencil';
import PendingEVisit from './PendingEVisit';
import PersonalInfo from './PersonalInfo';
import Phone from './Phone';
import PhoneCall from './PhoneCall';
import PhoneCallThinEdges from './PhoneCallThinEdges';
import PhoneNumber from './PhoneNumber';
import PhoneCobaltOutline from './PhoneCobaltOutline';
import PillBottle from './PillBottle';
import Pregnancy from './Pregnancy';
import PreventativeCareIcon from './PreventativeCareIcon';
import ProxySwitch from './ProxySwitch';
import QLCheckSymptoms from './QLCheckSymptoms';
import QLConnectCare from './QLConnectCare';
import QLImmunizations from './QLImmunizations';
import QLMedicationList from './QLMedicationList';
import QLProviderSearch from './QLProviderSearch';
import QLScheduleAppointment from './QLScheduleAppointment';
import QLTestResults from './QLTestResults';
import QLVisitSummary from './QLVisitSummary';
import RadiologyImaging from './RadiologyImaging';
import Respiratory from './Respiratory';
import Reschedule from './Reschedule';
import Schedule from './Schedule';
import Scout from './Scout';
import Security from './Security';
import SelectHealth from './SelectHealth';
import SelectHealthGreyed from './SelectHealthGreyed';
import SelectHealthNew from './SelectHealthNew';
import SelectHealthReversed from './SelectHealthReversed';
import Settings from './Settings';
import Share from './Share';
import SignOut from './SignOut';
import SleepWake from './SleepWake';
import SNavBilling from './SNavBilling';
import SNavDashboard from './SNavDashboard';
import SNavGetCare from './SNavGetCare';
import SNavHealthRecord from './SNavHealthRecord';
import SNavHealthTools from './SNavHealthTools';
import SNavMessages from './SNavMessages';
import SNavTimeline from './SNavTimeline';
import Spending from './Spending';
import Stethoscope from './Stethoscope';
import StethoscopeGS from './StethoscopeGS';
import StethoscopeOnboarding from './StethoscopeOnboarding';
import Surgery from './Surgery';
import Subtract from './Subtract';
import TestResultsIcon from './TestResultsIcon';
import TestResultsIcon2 from './TestResultsIcon2';
import TestResultsImaging from './TestResultsImaging';
import TestResultsLab from './TestResultsLab';
import TestResultsMicrobiology from './TestResultsMicrobiology';
import TestResultsPathology from './TestResultsPathology';
import Timer from './Timer';
import TrashIcon from './TrashIcon';
import Trendline from './Trendline';
import TriageIcon from './TriageIcon';
import UpcomingAppointments from './UpcomingAppointments';
import UploadFileIcon from './UploadFileIcon';
import OTPValidation from './OTPValidation';
import VideoVisit from './VideoVisit';
import VisitSummaryIcon from './VisitSummaryIcon';
import VitalsIcon from './VitalsIcon';
import Vitruvian from './Vitruvian';
import WarningIcon from './WarningIcon';
import WebCostEstimator from './WebCostEstimator';
import WebGetCareNow from './WebGetCareNow';
import WebTitleGetCareNow from './WebTitleGetCareNow';
import ExclamationCircleAlt from './ExclamationCircleAlt';
import ArrowLeft from './ArrowLeft';
import Location from './Location';

export const SNavStyles = {
  fill: 'none',
  strokeWidth: '2px',
  strokeLinecap: 'round',
  strokeLinejoin: 'round',
  strokeMiterlimit: 10
} as const;

export default (props?: SvgProps) => ({
  AccountManagement: AccountManagement(props),
  AccountShare: AccountShare(props),
  Add: Add(props),
  AddPerson,
  Ambulance,
  Appointment: Appointment(props),
  Avatar: Avatar(props),
  AvatarDefault,
  Avatar01,
  Avatar02,
  Avatar03,
  Avatar04,
  Avatar05,
  Avatar06,
  Avatar07,
  Avatar08,
  Avatar09,
  Avatar10,
  Avatar11,
  Avatar12,
  Avatar13,
  Avatar14,
  Avatar15,
  Avatar16,
  Avatar17,
  Avatar18,
  Avatar19,
  Avatar20,
  Avatar21,
  Avatar22,
  Avatar23,
  Avatar24,
  Avatar25,
  Avatar26,
  Avatar27,
  Avatar28,
  Avatar29,
  Avatar30,
  Bill,
  Bookmark,
  Calculate,
  Calendar,
  CalendarFlat: CalendarFlat(props),
  CalendarPin,
  CalendarThreeDots,
  CAlphaHealthRecord,
  CardiacTests,
  ChevronDown: ChevronDown(props),
  CheckCircle: CheckCircle(props),
  ChevronLeft: ChevronLeft(props),
  ChevronRight: ChevronRight(props),
  CheckCircleFilled: CheckCircleFilled(props),
  ClinicNotes,
  CloseOutline,
  CloseIcon: CloseIcon(props),
  CloseCircleOutline,
  Collections,
  ClockCircle,
  Comment,
  CompletedEVisit,
  ConnectCare,
  ConnectCareCancel,
  ConnectCareIcon,
  ConnectCareLogo,
  ConnectCareTextMe,
  CostEstimatorIcon,
  COVID19: COVID19(props),
  COVID19ModalHospital,
  COVID19ModalMask,
  CovidIcon: CovidIcon(props),
  CreditCard: CreditCard(props),
  DefenseShield: DefenseShield(props),
  Detail,
  Directions,
  DirectionFilled: DirectionFilled(props),
  DocPhone,
  Dot: Dot(props),
  DownloadIcon: DownloadIcon(props),
  Education,
  Email: Email(props),
  Endoscopy,
  ExclamationCircle: ExclamationCircle(props),
  ExclamationCircleAlt: ExclamationCircleAlt(props),
  FileAttachment,
  Filter,
  FindProviderIcon,
  HeaderMyHealthPlus: HeaderMyHealthPlus(props),
  HealthRecordBannerLg,
  HealthRecordBannerSm,
  HealthTool: HealthTool(props),
  HelpIcon: HelpIcon(props),
  Home: Home(props),
  Hospital,
  HospitalShield: HospitalShield(props),
  HrDownload: HrDownload(props),
  HSA,
  IconBubble,
  IconGoals,
  IllustrationsHealthRecord,
  InfoIcon: InfoIcon(props),
  InfoIconFilled: InfoIconFilled(props),
  DirectionIcon: DirectionIcon(props),
  Intermountain,
  IntermountainGreyed,
  IntermountainSolid,
  Language: Language(props),
  LgbtqIcon,
  Lock: Lock(props),
  Location: Location(props),
  MagnifyingGlass,
  MailOutline: MailOutline(props),
  MedicalCross,
  MedicalHistoryIcon,
  MedicationListIcon: MedicationListIcon(props),
  Messages,
  Money,
  MyCareTeam: MyCareTeam(props),
  MyDoctors: MyDoctors(props),
  MyHealthPlusLogo,
  NavBilling,
  NavGetCare,
  NavHealthRecord,
  NavHome,
  NavMessages,
  NerveAndMuscle,
  NoAppointments,
  OfficeVisit,
  PaymentHistory,
  PaymentPlan,
  Pencil,
  PendingEVisit,
  PersonalInfo: PersonalInfo(props),
  Phone: Phone(props),
  PhoneCall: PhoneCall(props),
  PhoneCallThinEdges: PhoneCallThinEdges(props),
  PhoneNumber,
  PhoneCobaltOutline,
  PillBottle: PillBottle(props),
  Pregnancy,
  PreventativeCareIcon,
  ProxySwitch,
  QLCheckSymptoms: QLCheckSymptoms(props),
  QLConnectCare: QLConnectCare(props),
  QLImmunizations: QLImmunizations(props),
  QLMedicationList: QLMedicationList(props),
  QLProviderSearch: QLProviderSearch(props),
  QLScheduleAppointment: QLScheduleAppointment(props),
  QLTestResults: QLTestResults(props),
  QLVisitSummary: QLVisitSummary(props),
  RadiologyImaging,
  Respiratory,
  Reschedule,
  Schedule: Schedule(props),
  Scout,
  Security: Security(props),
  SelectHealth,
  SelectHealthGreyed,
  SelectHealthNew,
  SelectHealthReversed,
  Settings: Settings(props),
  Share: Share(props),
  SignOut: SignOut(props),
  SleepWake,
  SNavBilling: SNavBilling(props),
  SNavDashboard: SNavDashboard(props),
  SNavGetCare: SNavGetCare(props),
  SNavHealthRecord: SNavHealthRecord(props),
  SNavHealthTools: SNavHealthTools(props),
  SNavMessages: SNavMessages(props),
  SNavTimeline: SNavTimeline(props),
  Spending,
  Stethoscope,
  StethoscopeGS,
  StethoscopeOnboarding: StethoscopeOnboarding(props),
  Subtract: Subtract(props),
  Surgery,
  TestResultsIcon,
  TestResultsIcon2,
  TestResultsImaging,
  TestResultsLab,
  TestResultsMicrobiology,
  TestResultsPathology,
  Timer,
  TrashIcon,
  Trendline,
  TriageIcon,
  UpcomingAppointments: UpcomingAppointments(props),
  UploadFileIcon: UploadFileIcon(props),
  OTPValidation,
  VideoVisit: VideoVisit(props),
  VisitSummaryIcon,
  VitalsIcon,
  Vitruvian,
  WarningIcon: WarningIcon(props),
  WebCostEstimator,
  WebGetCareNow,
  WebTitleGetCareNow,
  ArrowLeft: ArrowLeft(props)
});
