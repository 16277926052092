import { Divider } from '@material-ui/core';
import Avatar from 'components/UI/Avatar/Avatar';
import Card from 'components/UI/Card/Card';
import Box from 'components/UI/Layout/Box';
import FlexBox from 'components/UI/Layout/FlexBox';
import Svg from 'components/UI/Svg/Svg';
import Typography from 'components/UI/Typography';
import { Color } from 'modules/styles/colors';
import { FontWeight, IconSize, Spacing } from 'modules/styles/variables';
import React from 'react';
import styled from 'styled-components';

interface Props {
  patient: string;
  providers?: string;
  providerImageUrl?: string;
  isSelectHealth?: boolean;
  location?: string;
  date?: string;
  title?: string;
  dischargeDisposition?: string | null;
  accessibilityLabel?: string;
  source?: string | null;
}

const orNA = (str?: string) => str || 'N/A';
const TitleHeader = styled.h2`
  margin: 0;
`;
function HealthSummaryCard(props: Props) {
  const {
    date,
    patient,
    providers,
    providerImageUrl,
    isSelectHealth,
    location,
    title,
    dischargeDisposition,
    accessibilityLabel,
    source
  } = props;

  return (
    <Card
      accessibilityLabel={accessibilityLabel}
      maxWidth="670px"
      data-testid="health-summary-banner"
    >
      <FlexBox flexDirection="row" justifyContent="space-between">
        <Box>
          <Typography>{date}</Typography>
          <TitleHeader>{title}</TitleHeader>
        </Box>
        <Box>
          <Box border={{ width: 3, color: Color.secondary, style: 'solid', radius: 50 }}>
            <Avatar size="large" imageUrl={providerImageUrl} />
          </Box>
        </Box>
      </FlexBox>

      <Divider background-color={Color.gray} style={{ margin: '10px 0px' }} />

      {source ? (
        <>
          <FlexBox flexDirection="row">
            <Typography fontWeight={FontWeight.semibold}>Source:</Typography>
            <Box width={Spacing.medium} />
            <Typography>{source}</Typography>
          </FlexBox>
        </>
      ) : null}

      {providers ? (
        <>
          <FlexBox flexDirection="row" alignItems="center" flexWrap="nowrap">
            {isSelectHealth ? (
              <Svg name="SelectHealth" size={IconSize.small} />
            ) : (
              <Typography fontWeight={FontWeight.semibold}>Caregiver:&nbsp;</Typography>
            )}
            <Box hOffsetLeft={Spacing.small} />
            <Typography>{orNA(providers)}</Typography>
          </FlexBox>
          <Divider background-color={Color.gray} style={{ margin: '10px 0px' }} />
        </>
      ) : null}

      {location ? (
        <>
          <FlexBox alignItems="center" flexDirection="row" flexWrap="nowrap">
            <Svg name="Location" size={IconSize.small} />
            <Box hOffsetLeft={Spacing.small}>
              <Typography>{orNA(location)}</Typography>
            </Box>
          </FlexBox>
          <Divider background-color={Color.gray} style={{ margin: '10px 0px' }} />
        </>
      ) : null}

      <FlexBox flexDirection="row">
        <Typography fontWeight={FontWeight.semibold}>Patient:</Typography>
        <Box width={Spacing.medium} />
        <Typography data-testid="health-banner-patient-name">{orNA(patient)}</Typography>
      </FlexBox>
      {dischargeDisposition && (
        <FlexBox flexDirection="row">
          <Typography fontWeight={FontWeight.semibold}>Discharge Disposition:</Typography>
          <Box width={Spacing.medium} />
          <Typography>{orNA(dischargeDisposition)}</Typography>
        </FlexBox>
      )}
    </Card>
  );
}

export default HealthSummaryCard;
