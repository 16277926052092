import React, { ComponentType } from 'react';
import ListItem from '@material-ui/core/ListItem';
import { history } from 'lib/history';
import { vyncaFaqCategories } from 'lib/constants/VyncaFaqConstants';
import Typography from 'components/UI/Typography';
import { MuiBox, MuiContainer, MuiListItemSecondaryAction } from 'theme/material-ui';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { Color } from 'modules/styles/colors';
import { Spacing } from 'modules/styles/variables';
import { StyledList } from '../styled';
import useNavigationAnalytics from 'hooks/useNavigationAnalytics';
import { AnalyticsEvent } from 'services/AnalyticsService';
import { convertToLowerKabobCase } from 'modules/utils/StringUtils';

interface FaqCategory {
  id: string;
  title: string;
}

const FAQS = () => {
  const faqCategoryClicked = useNavigationAnalytics(AnalyticsEvent.VyncaFAQCategoryClicked);

  const handleOnClick = (id: string, selectedFaq: string) => {
    faqCategoryClicked({
      title: 'Vynca FAQ Home',
      vendor: 'Vynca',

      vynca_faq_category: selectedFaq
    });
    history.push(`/u/health-tools/vynca/faqslist/${id}`);
  };
  return (
    <MuiContainer>
      <MuiBox display="flex" justifyContent="space-around" paddingTop={Spacing.small}>
        <MuiBox width="100%">
          <StyledList>
            {vyncaFaqCategories.map((item: FaqCategory) => (
              <ListItem
                data-testid={convertToLowerKabobCase(item.title)}
                button
                key={item.title}
                onClick={() => handleOnClick(item.id, item.title)}
                style={{ height: '100%', backgroundColor: Color.foreColor }}
              >
                <Typography testID={item.title}>{item.title}</Typography>
                <MuiListItemSecondaryAction>
                  <KeyboardArrowRightIcon />
                </MuiListItemSecondaryAction>
              </ListItem>
            ))}
          </StyledList>
        </MuiBox>
      </MuiBox>
    </MuiContainer>
  );
};

export default FAQS as ComponentType;
