import * as React from 'react';

export default () => {
  return {
    svg: (
      <g id="prefix__Find_A_Provider" data-name="Find A Provider">
        <path
          fill="none"
          d="M20.3218 22.7412H19.5156C18.6256 22.7412 17.9031 22.0199 17.9031 21.1287L17.9028 16.2898C17.9028 9.8386 22.8728 4.99985 30.0003 4.99985C32.0228 4.99985 34.0315 5.8061 35.6453 5.8061H42.9028V8.22485C42.9028 9.85735 42.1865 11.3648 41.2903 12.2573C42.2065 14.0311 42.0965 15.4848 42.0965 17.0961L42.0959 21.129C42.0959 22.019 41.3747 22.7415 40.4835 22.7415H39.6772M20.4563 22.7415C21.2238 27.3177 25.2051 30.8065 30.0001 30.8065C34.8026 30.8065 38.7901 27.3065 39.5476 22.7177M21.1288 19.5156V15.4831C21.1288 14.1481 22.2125 13.0644 23.5475 13.0644H24.355L27.58 9.8381C27.58 9.8381 28.3863 13.0644 32.4188 13.0644M38.8708 16.2901V15.4838C38.8708 14.1476 37.787 13.0638 36.452 13.0638H35.6445M23.5481 34.8384C23.5481 34.8384 23.2406 39.4847 29.9994 43.7097C29.9994 43.7097 36.4519 39.6772 36.4519 34.8384M23.5481 34.8384C18.7094 35.6447 18.7094 39.6772 18.7094 39.6772V42.0959M23.5481 34.8384L17.9027 35.6452C13.8702 36.4514 11.4514 39.6764 11.4514 44.5152V52.5802C11.4514 52.5802 14.6777 55.0002 30.0002 55.0002C32.1039 55.0002 33.9789 54.9539 35.6489 54.8752M23.5481 34.8384C24.3381 34.7209 25.1614 34.1164 25.1614 33.2252V30.0002M36.4519 34.8384C35.6631 34.7209 34.8386 34.1158 34.8386 33.2258V29.9995M36.4519 34.8384L42.0961 35.6445C46.1286 36.4508 48.5486 39.677 48.5486 44.5158V52.5808C48.5486 52.5808 48.1548 52.8745 46.9936 53.257M36.4519 34.8384C41.2906 35.6447 41.2902 39.6772 41.2902 39.6772L41.2908 50.1616M18.7094 42.0959C21.0132 42.0959 22.7413 45.3208 22.7413 47.857V50.1608H21.1288M18.7094 42.0959C16.4044 42.0959 14.6775 45.3208 14.6775 47.857V50.1608H16.405M41.2908 50.1616C39.9545 50.1616 38.8708 51.2441 38.8708 52.5803C38.8708 53.9166 39.9545 54.9991 41.2908 54.9991C42.6258 54.9991 43.7095 53.9166 43.7095 52.5803C43.7095 51.2441 42.6258 50.1616 41.2908 50.1616Z"
          stroke="url(#paint0_linear_2404_1750)"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <defs>
          <linearGradient
            id="paint0_linear_2404_1750"
            x1={11.4514}
            y1={30}
            x2={48.5486}
            y2={30}
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0.0843751} stopColor="#FF0048" />
            <stop offset={0.386458} stopColor="#DF36FF" />
            <stop offset={0.980208} stopColor="#4A00E2" />
          </linearGradient>
        </defs>
      </g>
    ),
    viewBox: '0 0 60 60'
  };
};
