import styled from 'styled-components';
import { MuiBox, dfdDefaultTheme, MuiButton } from 'theme/material-ui';

export const StyledBox = styled(MuiBox)`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 22px 0px;
  padding: 22px 0px 37px;

  ${dfdDefaultTheme.breakpoints.up('sm')} {
    gap: 20px 25px;
    padding: 22px 22px 37px;
    grid-template-columns: repeat(2, 1fr);
  }

  ${dfdDefaultTheme.breakpoints.up('md')} {
    gap: 10px 10px;
    padding: 20px 10px 37px;
    grid-template-columns: repeat(2, 1fr);
  }

  ${dfdDefaultTheme.breakpoints.up('lg')} {
    gap: 20px 10px;
    padding: 22px 22px 37px;
    grid-auto-flow: column;
    grid-template-columns: none;
  }

  ${dfdDefaultTheme.breakpoints.up('xl')} {
    grid-auto-flow: column;
    grid-template-columns: repeat(8, 1fr);
  }
`;

export const QuickLinkBtn = styled(MuiButton)`
  &.MuiButton-root {
    max-height: none;
  }
`;
