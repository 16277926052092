import React from 'react';

export default {
  svg: (
    <g>
      <path
        d="M216.4,121.3c-0.4,3.9-3.7,6.7-7.5,6.7c-0.3,0-0.5,0-0.8,0l-50.2-5.1l-7.7-8.4l-7.7,8.4L92.4,128c-4.1,0.4-7.8-2.6-8.2-6.7
        c-0.4-4.1,2.6-7.8,6.7-8.2l44.5-4.6l0.3-0.4c-4.9-4.1-8-10.3-8-17.2v-3.8c0-4.1,3.4-7.5,7.5-7.5s7.5,3.4,7.5,7.5v0v3.8
        c0,4.2,3.4,7.6,7.6,7.6s7.6-3.4,7.6-7.6v-3.8c0,0,0,0,0,0c0-4.1,3.4-7.5,7.5-7.5s7.5,3.4,7.5,7.5v3.8c0,6.9-3.1,13.1-8,17.2
        l0.3,0.4l44.5,4.6C213.9,113.5,216.9,117.2,216.4,121.3z M244.7,152.1c0,52.1-42.4,94.4-94.4,94.4c-52.1,0-94.4-42.4-94.4-94.4
        c0-52.1,42.4-94.4,94.4-94.4C202.4,57.6,244.7,100,244.7,152.1z M161.4,229.9l-11.1-20.5l-11.1,20.5c3.6,0.5,7.3,0.8,11.1,0.8
        C154.1,230.7,157.8,230.4,161.4,229.9z M228.9,152.1c0-43.4-35.3-78.6-78.6-78.6s-78.6,35.3-78.6,78.6c0,23.8,10.6,45.1,27.4,59.6
        l20.4-47l-3.2-10.5H83.6c-4.1,0-7.5-3.4-7.5-7.5s3.4-7.5,7.5-7.5h43.7l8.1,26.4l-23.9,55c4,2.3,8.2,4.2,12.6,5.8l19-35.2
        c1.4-2.7,4.3-4.1,7.1-3.9c2.8-0.2,5.7,1.2,7.1,3.9l19,35.2c4.4-1.6,8.6-3.5,12.6-5.8l-23.9-55l8.1-26.4H217c4.1,0,7.5,3.4,7.5,7.5
        s-3.4,7.5-7.5,7.5h-32.6l-3.2,10.5l20.4,47C218.3,197.2,228.9,175.9,228.9,152.1z M153.2,133l-2.9,2.9l-2.9-2.9
        c-2.9-2.9-7.7-2.9-10.6,0c-2.9,2.9-2.9,7.7,0,10.6l4.1,4.1l9.4,9.4l5.3-5.3c0,0,0,0,0,0l8.2-8.2c2.9-2.9,2.9-7.7,0-10.6
        C160.8,130.1,156.1,130.1,153.2,133z"
      />
    </g>
  ),
  viewBox: '0 0 300 300'
};
