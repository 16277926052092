import { Color } from 'modules/styles/colors';
import { MuiBox } from 'theme/material-ui';
import styled from 'styled-components';

export const LineChartContainerStyles = styled(MuiBox)`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  shape-rendering: geometricprecision;
  & .line-chart__tabs {
    background-color: #888;
  }
  & .line-chart__tabs .mat-tab-label {
    flex: 1 1 auto;
  }
  & .line-chart__tabs .mat-tab-header-pagination {
    display: none !important;
  }
  & .line-chart text {
    background: transparent;
    text-rendering: optimizeLegibility;
    font-family: 'ABC Social Variable', sans-serif;
    font-size: 13px;
    fill: #222;
    stroke: none;
  }
  & .line-chart .x-axis,
  & .line-chart .y-axis {
    font-family: 'ABC Social Variable', sans-serif;
    text-rendering: optimizeLegibility;
    font-weight: lighter;
    font-size: 12px;
    text-anchor: end;
    color: #555;
  }
  & .line-chart .x-axis .tick line {
    transform: translate(0, -10px);
  }
  & .line-chart .no-ticks .tick line {
    stroke: none;
  }
  & .line-chart line.dotted-line {
    stroke: #555;
    stroke-dasharray: 2;
  }
  & .line-chart .domain {
    stroke: transparent;
    stroke-width: 3px;
  }
  & .line-chart .x-axis-label {
    font-weight: lighter;
  }
  & .line-chart .graph-label-y {
    transform: rotate(-90deg);
    font-size: 1.1rem;
  }
  & .line-chart .graph-label-note {
    font-size: 1.3rem;
    font-weight: bold;
  }
  & .line-chart .graph-label-note + .graph-label-note {
    display: none;
  }
  & .line-chart .graph-label-range {
    font-size: 0.8rem;
    text-transform: uppercase;
    font-weight: bold;
    opacity: 0.5;
    fill: #001733;
  }
  & .line-chart .graph-point__data-point {
    stroke-width: 2px;
    transition: stroke-width 0.3s ease-in-out;
    fill: white;
  }
  & .line-chart .graph-point__line {
    opacity: 0;
    transition: all 0.25s ease-in-out;
  }
  & .line-chart .graph-point-hover-block,
  & .line-chart .graph-point-hover-block-1 {
    opacity: 0;
  }
  & .line-chart .graph-point.active .graph-point__line,
  & .line-chart .graph-point.active .graph-point__data-group {
    opacity: 1;
    transition: all 0.25s ease;
  }
  & .line-chart .graph-point__data-group {
    opacity: 0;
    pointer-events: none;
    transition: all 0.25s ease-in-out;
  }
  & .line-chart .graph-point__label-line {
    pointer-events: none;
    stroke: none;
  }
  & .line-chart .graph-point:hover .graph-point__line,
  & .line-chart .graph-point:hover .graph-point__data-group,
  & .line-chart .graph-point-hover-block-1:hover .graph-point__line,
  & .line-chart .graph-point-hover-block-1:hover .graph-point__data-group,
  & .line-chart .graph-point-hover-block:hover .graph-point__line,
  & .line-chart .graph-point-hover-block:hover .graph-point__data-group {
    opacity: 1;
  }
  & .line-chart .graph-point__label {
    fill: #000;
    stroke: none;
    font-size: 2rem;
    pointer-events: none;
    text-anchor: middle;
  }
  & .line-chart .graph-point__label--highlight {
    fill: #049;
    font-weight: bold;
  }
  & .line-chart .graph-point__label.small {
    font-size: 0.7rem;
  }
  & .line-chart .graph-point {
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
  }
  & .line-chart .graph-point__inner.normal .graph-point__data-point {
    fill: ${Color.green};
  }
  & .line-chart .graph-point__inner.normal .graph-point__label,
  & .line-chart .graph-point__inner.normal .graph-point__label-line {
    fill: #000;
  }
  & .line-chart .graph-point__inner.normal .graph-point__label--highlight {
    fill: #049;
  }
  & .line-chart .graph-point__inner.obese .graph-point__data-point {
    fill: #ff6666;
  }
  & .line-chart .graph-point__inner.obese .graph-point__label,
  & .line-chart .graph-point__inner.obese .graph-point__label-line {
    fill: #000;
  }
  & .line-chart .graph-point__inner.obese .graph-point__label--highlight {
    fill: #049;
  }
  & .line-chart .graph-point__inner.warn .graph-point__data-point {
    fill: #fb2;
  }
  & .line-chart .graph-point__inner.warn .graph-point__label,
  & .line-chart .graph-point__inner.warn .graph-point__label-line {
    fill: #333;
  }
  & .line-chart .graph-point__inner.warn .graph-point__label--highlight {
    fill: #049;
  }
  & .line-chart .graph-point2__data-point {
    fill: #000;
  }
  & .line-chart .graph-point2__label,
  & .line-chart .graph-point2__label-line {
    fill: yellow;
  }
  & .line-chart .graph-point2__label--highlight {
    fill: #049;
    font-weight: bold;
  }
  & .line-chart .graph-point2 .graph-point__inner.normal .graph-point2__data-point {
    fill: ${Color.green};
  }
  & .line-chart .graph-point2 .graph-point__inner.normal .graph-point2__label,
  & .line-chart .graph-point2 .graph-point__inner.normal .graph-point2__label-line {
    fill: #000;
  }
  & .line-chart .graph-point2 .graph-point__inner.normal .graph-point2__label--highlight {
    fill: #049;
  }
  & .line-chart .graph-point2 .graph-point__inner.obese .graph-point2__data-point {
    fill: #ff6666;
  }
  & .line-chart .graph-point2 .graph-point__inner.obese .graph-point2__label,
  & .line-chart .graph-point2 .graph-point__inner.obese .graph-point2__label-line {
    fill: orange;
  }
  & .line-chart .graph-point2 .graph-point__inner.obese .graph-point2__label--highlight {
    fill: #049;
  }
  & .line-chart .graph-point2 .graph-point__inner.warn .graph-point2__data-point {
    fill: #fb2;
  }
  & .line-chart .graph-point2 .graph-point__inner.warn .graph-point2__label,
  & .line-chart .graph-point2 .graph-point__inner.warn .graph-point2__label-line {
    fill: #000;
  }
  & .line-chart .graph-point2 .graph-point__inner.warn .graph-point2__label--highlight {
    fill: #049;
  }
  & .line-chart .range__low {
    fill: #29abe2;
    opacity: 0.8;
    stroke-width: 1px;
    stroke: #29abe2;
    stroke-dasharray: 4;
  }
  & .line-chart .range__normal {
    fill: #29abe2;
    opacity: 0.8;
    stroke-width: 1px;
    stroke: #29abe2;
    stroke-dasharray: 4;
  }
  & .line-chart .range__high {
    fill: #29abe2;
    opacity: 0.8;
    stroke-width: 1px;
    stroke: #29abe2;
    stroke-dasharray: 4;
  }
  & .line-chart .path-area-inner {
    fill: #29abe2;
    opacity: 0.3;
  }
  & .line-chart .chart-line {
    stroke-width: 3px;
    stroke-linejoin: round;
    stroke-linecap: round;
  }
  & .line-chart .chart-line2 {
    stroke: #000;
  }
`;

export const YAxisRight = styled.div`
  border-right: 4px solid ${Color.graySidebarDivider};
  height: ${({ height }) => height};
  opacity: ${({ opacity }) => opacity};
  float: right;
  position: absolute;
  top: 20px;
  z-index: 10;
  right: 0;
`;

export const YAxisLeft = styled.svg`
  width: ${({ width }) => width};
`;

export const StyledDiv = styled.div`
  margin: 0 1rem 0 0rem;

  & .y-axis {
    float: left;
    margin: 0;
    font-family: 'ABC Social Variable', sans-serif;
    font-weight: lighter;
    font-size: 14px;
    text-anchor: end;
    color: #555;
    stroke: none;
    background: transparent;
    text-rendering: optimizeLegibility;
  }
  & .domain {
    stroke: transparent;
    stroke-width: 3px;
  }
`;

export const LineChartContainer = styled(MuiBox)<{ background: string }>`
  display: flex;
  flex-direction: column;
  justify-content: start;
  overflow-x: auto;
  margin: 0;
  background: ${props => props.background};

  // width
  ::-webkit-scrollbar {
    height: 16px;
  }
  //track
  ::-webkit-scrollbar-track {
    background-color: ${Color.grayLight3};
    opacitiy: 0;
  }
  //handle
  ::-webkit-scrollbar-thumb {
    background: ${Color.grayLight};
    border-radius: 100px;
    position: absolute;
    top: 0;
  }

  & .KeyValueNormal {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  & .KeyValueWarning {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 1em;
  }
  & .lineGraphContainer {
    max-width: 600px;
    width: 100%;
  }
  & .right-trend-title {
    font-weight: 200;
    color: #fff;
    text-align: center;
    margin-top: 1.5em;
  }
  & .header__left {
    float: left;
    margin-left: 1em;
  }
  & .header__right {
    width: calc(80vw - 33vw);
    align-self: flex-end;
  }
`;

export const LineChart = styled(MuiBox)`
  justify-content: start;
`;
