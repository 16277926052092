import { Row } from 'components/UI/Layout/Container';
import ScrollableLayout from 'components/UI/Layout/ScrollableLayout';
import DefaultText from 'components/UI/Typography/DefaultText';
import { Color } from 'modules/styles/colors';
import { BorderRadius, Spacing } from 'modules/styles/variables';
import styled from 'styled-components';

export const BackgroundWrapper = styled.div`
  background: linear-gradient(323deg, ${Color.blueHue3} 0%, ${Color.primary} 100%);
  width: 100%;
`;

export const StatusContainer = styled.div`
  flex-direction: row;
  align-items: flex-start;
  align-self: flex-start;
`;

export const StyledView = styled.div`
  margin-bottom: ${Spacing.medium}px;
`;

export const StyledRow = styled(Row)`
  padding: ${Spacing.large}px ${Spacing.medium}px;
  border-top-width: 0.5px;
  border-top-color: ${Color.gray};
  width: 100%;
  justify-content: space-between;
`;

export const Dot = styled.div<{ color?: string }>`
  height: 10px;
  width: 10px;
  background-color: ${props => props.color || Color.gray};
  border-radius: ${BorderRadius.full};
  margin-right: ${Spacing.small}px;
`;

export const BoldDefaultText = styled(DefaultText)`
  font-weight: bold;
`;

export const CenterAlign = styled.div`
  align-items: center;
`;

export const LeftPaddingContainer = styled.div`
  padding: ${Spacing.medium}px;
`;

export const StyledScrollableLayout = styled(ScrollableLayout)`
  background-color: ${Color.foreColor};
  margin-bottom: ${Spacing.medium}px;
`;

export const LeftAlignContainer = styled.div`
  justify-content: flex-start;
  flex-direction: row;
  flex: 1;
`;

export const CenterAlignContainer = styled.div`
  justify-content: center;
  flex-direction: row;
  flex: 2;
`;

export const RightAlignContainer = styled.div`
  justify-content: flex-end;
  flex-direction: row;
  flex: 1;
`;

export const SimpleCenterAlignContainer = styled.div`
  justify-content: center;
  flex-direction: row;
  align-items: center;
`;

export const IconContainerAlignLeft = styled.div`
  background-color: ${Color.grayLight};
  padding: 10px;
`;
