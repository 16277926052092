import React, { useRef, useState, useEffect, useMemo } from 'react';
import { MuiContainer, MuiBox, MuiTypography, MuiButton, MuiGrid } from 'theme/material-ui';
import { useHistory } from 'react-router-dom';
import { FontSize, FontWeight, Spacing } from 'modules/styles/variables';
import { Color } from 'modules/styles/colors';
import { eVisitFrontDoorNewInsuranceScreen } from 'lib/triage/constants';
import { EVISITS_ROUTES } from '../constants';
import Spacer from 'components/UI/Layout/Spacer';
import EVisitBanner from './components/EVisitBanner';
import Screen from 'components/UI/Layout/Screen';
import UploadInsurance from './components/UploadInsurance';
import Svg from 'components/UI/Svg/Svg';
import { useDispatch, useSelector } from 'react-redux';
import { setInsuranceFile, uploadInsuranceFile } from 'store/eVisit/actions.common';
import { v4 } from 'uuid';
import { eVisitInsuranceFilesSelector } from 'store/eVisit/selectors';
import { ImageInfo, InsuranceFile, InsuranceFileState } from 'store/eVisit/types';
import analyticsService, { AnalyticsEvent } from 'services/AnalyticsService';
import { LocalEVisitState, UploadSideState, UploadState } from './types';

const MAX_FILE_SIZE = 10 * 1024 * 1024;

function parseUploadState(insFile: InsuranceFile): UploadSideState {
  return {
    isSuccess: !!(insFile.azureFileName && !insFile.errorMessage),
    isError: !!insFile.errorMessage,
    isLoading: !!insFile.isFetching,
    errorMessage: insFile.errorMessage
  };
}

const EVisitUpdateInsurance = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const uuid = useRef(v4());
  const { localStateSelected, conditionCode } =
    (history?.location?.state as LocalEVisitState) ?? ({} as LocalEVisitState);
  const [selectedImages, setSelectedImages] = useState<ImageInfo>();
  const [addSecondary, setAddSecondary] = useState(false);
  const {
    primaryFront,
    primaryBack,
    secondaryFront,
    secondaryBack
  }: InsuranceFileState = useSelector(eVisitInsuranceFilesSelector);

  // Note: establish Upload State
  const afterUpload: UploadState = useMemo(
    () => ({
      frontSide: parseUploadState(primaryFront),
      backSide: parseUploadState(primaryBack)
    }),
    [primaryFront, primaryBack]
  );
  const afterUploadSecondary: UploadState = useMemo(
    () => ({
      frontSide: parseUploadState(secondaryFront),
      backSide: parseUploadState(secondaryBack)
    }),
    [secondaryFront, secondaryBack]
  );

  // Note: calculate disabled
  let disabled = true;
  if (!addSecondary && afterUpload.frontSide.isSuccess && afterUpload.backSide.isSuccess) {
    disabled = false;
  } else if (
    addSecondary &&
    afterUpload.frontSide.isSuccess &&
    afterUpload.backSide.isSuccess &&
    afterUploadSecondary.frontSide.isSuccess &&
    afterUploadSecondary.backSide.isSuccess
  ) {
    disabled = false;
  }

  const {
    screenTitle,
    sectionTitle,
    sectionSubtitle,
    buttons,
    errors
  } = eVisitFrontDoorNewInsuranceScreen;

  useEffect(() => {
    const { frontSide, backSide } = afterUpload;
    if (frontSide.isSuccess && backSide.isSuccess) {
      analyticsService.logEvent(AnalyticsEvent.EVisitsFrontDoorInsuranceImageUploaded, {
        action: 'Primary Insurance Images'
      });
    }
  }, [afterUpload]);

  useEffect(() => {
    const { frontSide, backSide } = afterUploadSecondary;
    if (frontSide.isSuccess && backSide.isSuccess) {
      analyticsService.logEvent(AnalyticsEvent.EVisitsFrontDoorInsuranceImageUploaded, {
        action: 'Secondary Insurance Images'
      });
    }
  }, [afterUploadSecondary]);

  const onContinue = () => {
    history.push({
      pathname: EVISITS_ROUTES.FRONTDOOR_INSURANCE_SUBMIT,
      state: { conditionCode, localStateSelected }
    });
  };

  const uploadImage = (isBackSide: boolean, isPrimary: boolean, file: File) => {
    const insuranceType = isPrimary
      ? isBackSide
        ? 'primaryBack'
        : 'primaryFront'
      : isBackSide
      ? 'secondaryBack'
      : 'secondaryFront';
    if (file) {
      if (file.size > MAX_FILE_SIZE) {
        setImageBox(isBackSide, isPrimary);
        dispatch(
          setInsuranceFile({ insuranceType, status: 'error', errorMessage: errors.fileSize })
        );
      } else {
        const reader = new window.FileReader();
        reader.onload = async ({ target }: ProgressEvent<FileReader>) => {
          const imageSrc = target?.result;
          setImageBox(isBackSide, isPrimary, typeof imageSrc === 'string' ? imageSrc : undefined);

          dispatch(uploadInsuranceFile(file, insuranceType, uuid.current));
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const setImageBox = (isBackSide: boolean, isPrimary: boolean, imageSrc?: string) => {
    const selectedImagesKeyPrefix = isPrimary ? 'primary' : 'secondary';
    const selectedImagesKeySuffix = isBackSide ? 'Back' : 'Front';
    const selectedImagesKey: keyof ImageInfo = `${selectedImagesKeyPrefix}${selectedImagesKeySuffix}`;
    const isValidImgSrc = !!imageSrc;

    setSelectedImages(prevState => ({
      ...prevState,
      [selectedImagesKey]: isValidImgSrc ? imageSrc : undefined
    }));
  };

  const toggleNewInsurance = () => {
    if (addSecondary) {
      setSecondaryToDefault();
    }
    setAddSecondary(prev => !prev);
  };

  const setSecondaryToDefault = () => {
    setSelectedImages(prev => ({
      ...prev,
      secondaryFront: undefined,
      secondaryBack: undefined
    }));
  };

  return (
    <Screen>
      <EVisitBanner title={screenTitle} />
      <MuiContainer data-testid="e-visit-upload-insurance">
        <MuiBox paddingTop={6} padding={4}>
          <MuiTypography
            component="h2"
            fontSize={FontSize.mediumHeading}
            fontWeight={FontWeight.bold}
          >
            {sectionTitle.title}
          </MuiTypography>
          <MuiTypography component="p" fontSize={FontSize.base}>
            {sectionTitle.description}
          </MuiTypography>
        </MuiBox>
        <MuiGrid container justify="center" style={{ padding: '40px 0' }}>
          <MuiGrid
            item
            xs="auto"
            style={{ maxWidth: '100%' }}
            data-testid="primary-insurance-section"
          >
            <UploadInsurance
              status={afterUpload}
              uploadImage={uploadImage}
              selectedImages={{
                front: selectedImages?.primaryFront ? selectedImages.primaryFront : undefined,
                back: selectedImages?.primaryBack ? selectedImages.primaryBack : undefined
              }}
              isPrimary
            />
          </MuiGrid>
        </MuiGrid>
        <MuiBox paddingTop={6} padding={4}>
          <MuiTypography component="h3" fontSize={FontSize.mediumHeading}>
            {sectionSubtitle.title}
          </MuiTypography>
          <MuiTypography component="p" fontSize={FontSize.base}>
            {sectionSubtitle.description}
          </MuiTypography>
        </MuiBox>
        <MuiGrid>
          <Spacer spacing="large" />
        </MuiGrid>
        <MuiGrid container spacing={Spacing.small} justify="center">
          <MuiGrid item container xs={12} md={12} lg={5} justify="center">
            <MuiButton
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              onClick={toggleNewInsurance}
              data-testid="add-insurance-button"
              startIcon={
                !addSecondary ? (
                  <Svg set="assets" name="Add" size={FontSize.small} color={Color.foreColor} />
                ) : null
              }
            >
              {addSecondary ? buttons.removeSecondaryInsurance : buttons.addNewInsurance}
            </MuiButton>
          </MuiGrid>
        </MuiGrid>
        {addSecondary ? (
          <MuiGrid container justify="center" style={{ paddingTop: '40px 0' }}>
            <MuiGrid
              item
              xs="auto"
              style={{ maxWidth: '100%' }}
              data-testid="secondary-insurance-section"
            >
              <UploadInsurance
                status={afterUploadSecondary}
                uploadImage={uploadImage}
                selectedImages={{
                  front: selectedImages?.secondaryFront ? selectedImages.secondaryFront : undefined,
                  back: selectedImages?.secondaryBack ? selectedImages.secondaryBack : undefined
                }}
                isPrimary={false}
              />
            </MuiGrid>
          </MuiGrid>
        ) : null}
        <MuiGrid>
          <Spacer spacing="xxLarge" />
        </MuiGrid>
        <MuiGrid container spacing={Spacing.small} justify="center">
          <MuiGrid item container xs={12} md={5} justify="center">
            <MuiButton
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              disabled={disabled}
              onClick={onContinue}
              data-testid="confirm-button"
            >
              {buttons.confirm}
            </MuiButton>
          </MuiGrid>
        </MuiGrid>
      </MuiContainer>
    </Screen>
  );
};

export default EVisitUpdateInsurance;
