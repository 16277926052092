/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Dialog, DialogTitle, DialogContent, DialogActions, Box } from '@material-ui/core';
import CloseButton from 'components/UI/Button/CloseButton';
import { ConnectCareCheckboxItem, ConnectCareButton } from 'components/ConnectCare';
import analyticsService, { AnalyticsEvent } from 'services/AnalyticsService';
import { updateVisitContext } from 'store/amwell/actions';
import { RootDispatch, RootState } from 'store/types';
import { UpdateVisitContextForm, Disclaimer, VisitContext } from 'store/amwell/types';
import * as selectors from 'store/amwell/selectors';
import {
  currentLocationPathNameSelector,
  previousLocationPathNameSelector
} from 'store/router/selectors';
import { consentToTreat as consentToTreatConfirmations } from 'modules/constants/amwell';

const BorderedBox = styled(Box)`
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
`;

export interface ConsentToTreatDialogProps {
  // Props
  isOpen: boolean;
  onDecline: () => void;
  onError: () => void;
  onAgree: () => void;
  // Redux Props
  dispatch: RootDispatch;
  visitContext: VisitContext | null;
  isLoading: boolean;
  disclaimersAcknowledged: boolean;
  currentUrl?: string;
  referringUrl?: string;
}

const createDisclaimers = (visitContext: VisitContext | null) => {
  if (Array.isArray(visitContext?.disclaimers) && visitContext?.disclaimers.length) {
    return visitContext.disclaimers
      .filter((d: Disclaimer) => d.required)
      .map((disclaimer: Disclaimer) => (
        <div key={disclaimer.id.persistentId}>
          <h3>{disclaimer.title}</h3>
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: disclaimer.legalText }}
            style={{
              background: 'rgb(242,242,242)',
              padding: 24,
              borderRadius: 4,
              border: '1px solid rgba(0,0,0,0.1)'
            }}
          />
        </div>
      ));
  }
  return [];
};

export function ConsentToTreatDialog(props: ConsentToTreatDialogProps) {
  const {
    dispatch,
    visitContext,
    isLoading,
    isOpen,
    onDecline,
    onError,
    onAgree,
    disclaimersAcknowledged,
    currentUrl,
    referringUrl
  } = props;

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const disclaimers = useMemo(() => {
    return createDisclaimers(visitContext);
  }, [visitContext]);

  const hasNoDocument = visitContext && !disclaimers.length;

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    const updateVisitContextForm: Partial<UpdateVisitContextForm> = {
      disclaimersAcknowledged: e.target.checked
    };

    const res = await dispatch(updateVisitContext({ updateVisitContextForm }));

    if (res.error) {
      onError();
    }

    if (e.target.checked) {
      analyticsService.logEvent(AnalyticsEvent.ConsentToTreatAccepted, {
        currentUrl,
        referringUrl
      });
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      scroll="paper"
      open={isOpen}
      onClose={onDecline}
      fullScreen={isSmallScreen}
      aria-labelledby="connect-care-disclaimer-dialog-title"
    >
      <DialogTitle
        id="connect-care-disclaimer-dialog-title"
        data-testid="connect-care-disclaimer-dialog-title"
      >
        Release Statement
        <CloseButton
          absolute
          onPress={onDecline}
          data-testid="close-disclaimer-dialog"
          margin="0px"
          padding={3}
        />
      </DialogTitle>
      <DialogContent dividers>
        {hasNoDocument ? (
          <Alert severity={consentToTreatConfirmations.NO_DATA.severity}>
            <AlertTitle>Info</AlertTitle>
            {consentToTreatConfirmations.NO_DATA.title}
          </Alert>
        ) : (
          disclaimers
        )}
      </DialogContent>
      <BorderedBox py={2} px={3}>
        <ConnectCareCheckboxItem
          required
          disabled={isLoading}
          checked={disclaimersAcknowledged}
          onChange={handleChange}
          data-testid="acknowledge"
          label="I acknowledge receipt of the notice of privacy practices and consent to treat."
        />
      </BorderedBox>
      <DialogActions>
        <ConnectCareButton
          size="large"
          variant="outlined"
          onClick={onDecline}
          data-testid="decline-care-button"
        >
          I Decline
        </ConnectCareButton>
        <ConnectCareButton
          size="large"
          color="primary"
          disabled={!disclaimersAcknowledged}
          onClick={onAgree}
          data-testid="agree-care-button"
        >
          I Agree
        </ConnectCareButton>
      </DialogActions>
    </Dialog>
  );
}

const mapStateToProps = (state: RootState) => ({
  visitContext: selectors.visitContextDataSelector(state),
  isLoading: selectors.visitContextLoadingSelector(state),
  disclaimersAcknowledged: selectors.disclaimersAcknowledgedSelector(state),
  currentUrl: currentLocationPathNameSelector(state),
  referringUrl: previousLocationPathNameSelector(state)
});

export default connect(mapStateToProps)(ConsentToTreatDialog);
