import React from 'react';
import { Formik, Form } from 'formik';
import styled from 'styled-components';
import { CheckInForm } from './types';
import * as Yup from 'yup';
import { MuiButton, MuiBox, MuiTypography } from 'theme/material-ui';
import RequiredFieldsLegend, { StyledAsterisk } from 'components/RequiredFieldsLegend';
import { FontSize, FontWeight } from 'modules/styles/variables';
import { RedMuiBox } from '../Error/styled';
import FlexBox, { FlexBoxRow } from 'components/UI/Layout/FlexBox';
import FormConstant from './FormConstant';
import { InputField } from 'components/UI/Input/TextInput';
import { Color } from 'modules/styles/colors';
import { CenteredSpinner } from 'components/UI/Spinner/SpinnerContainer';
import Spacer from 'components/UI/Layout/Spacer';
import Footer, { SmallFooter } from 'components/UI/Footer/Footer';
import { IntermountainHealthAuthLiteLogo } from 'components/common/AppHeader/AppHeader';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { Warning } from 'components/Error/common';
import { getErrorCode } from 'services/api/utils';
import { AuthLiteDatePicker } from './AuthLiteDatePicker';

export const NumberLink = styled.a`
  color: ${Color.primary};
  font-weight: ${FontWeight.semibold};
`;

export function Attempts({ attempts }: { attempts: number }) {
  return (
    <RedMuiBox m={3} p={2} data-testid="please-check-your-information">
      <FlexBoxRow alignItems="flex-start">
        <Warning />
        <MuiBox>
          <MuiTypography fontSize={FontSize.base} fontWeight="bold">
            Please check your information
          </MuiTypography>
          <Spacer size="xsmall" />
          <MuiTypography fontSize={FontSize.base}>
            The information you entered does not match what we have on file. Please try again. You
            have {3 - attempts} attempts remaining.
          </MuiTypography>
          <>
            <Spacer size="small" />
            <MuiTypography fontSize={FontSize.base}>
              You can also get your test results by calling &nbsp;
              <NumberLink href="tel:$3852976430">(385) 297-6430</NumberLink>, Monday through Friday
              from 7AM-8PM or Saturday from 9AM-2PM (Mountain Time)
            </MuiTypography>
          </>
        </MuiBox>
      </FlexBoxRow>
    </RedMuiBox>
  );
}

export function ErrorHandeler() {
  return (
    <RedMuiBox m={3} p={2} data-testid="auth-lite-error">
      <FlexBoxRow alignItems="flex-start">
        <Warning />
        <MuiBox>
          <MuiTypography fontSize={FontSize.base} fontWeight="bold">
            Please check your information
          </MuiTypography>
          <Spacer size="xsmall" />
          <MuiTypography fontSize={FontSize.base}>
            There was an error retrieving your data. Please verify that this is the correct link.
          </MuiTypography>
        </MuiBox>
      </FlexBoxRow>
    </RedMuiBox>
  );
}

interface Props {
  attempts: number;
  error?: Error | null;
  loading?: boolean;
  message: string;
  submitCheckIn: (values: CheckInForm) => void;
  submitText: string;
  useZip?: boolean;
}

export function AuthLiteLogin(props: Props) {
  const { attempts, error, loading, message, submitCheckIn, submitText, useZip } = props;

  const statusCode = error ? getErrorCode(error) : null;
  const shape = {
    lastName: Yup.string()
      .required('Please enter a valid Last Name')
      .max(20, 'Must be less than 20 characters'),
    dob: Yup.date()
      .nullable(true)
      .required('Please enter a valid date of birth MM/DD/YYYY'),
    zipCode: Yup.number()
  };

  if (useZip) {
    shape.zipCode = Yup.number()
      .required('Please enter a valid ZIP Code')
      .test('len', 'Please enter a valid ZIP code', val => val && val.toString().length === 5)
      .typeError('Please enter a valid ZIP code');
  }
  const submitCheckInValidationSchema = Yup.object().shape(shape);

  const {
    formField: { lastName, dob, zipCode }
  } = FormConstant;

  const initialValues: CheckInForm = {
    lastName: '',
    dob: null,
    zipCode: ''
  };

  return (
    <Formik
      validateOnMount
      initialValues={initialValues}
      validationSchema={submitCheckInValidationSchema}
      onSubmit={submitCheckIn}
    >
      {({ isValid, setFieldTouched, setFieldValue, values }) => {
        return (
          <Form data-testid="prereg-checkin-form">
            <MuiBox m={1} p={1}>
              <MuiTypography fontSize={FontSize.large} fontWeight={FontWeight.bold}>
                {message}
              </MuiTypography>
              <RequiredFieldsLegend />
            </MuiBox>
            {/* Note: The old way had no errors. Need to rework Pre-Reg to use errors */}
            {!error && attempts && !loading ? <Attempts attempts={attempts} /> : null}
            {error && statusCode === '401' && !loading ? <Attempts attempts={attempts} /> : null}
            {error && statusCode !== '401' && !loading ? <ErrorHandeler /> : null}
            <MuiBox m={1} p={1}>
              <MuiTypography>
                {`${lastName.label} `}
                <StyledAsterisk />
              </MuiTypography>
              <InputField name={lastName.name} label={lastName.label} fullWidth />
            </MuiBox>
            <MuiBox m={1} p={1}>
              <AuthLiteDatePicker
                fullWidth
                label={dob.label}
                name={dob.name}
                onBlur={() => setFieldTouched(dob.name, true, true)}
                onChange={(date: MaterialUiPickersDate) => setFieldValue(dob.name, date)}
                required
                value={values.dob}
                disableToolbar={false}
              />
            </MuiBox>

            {useZip ? (
              <MuiBox m={1} p={1} data-testid="auth-lite-zip">
                <MuiTypography>
                  {`${zipCode.label} `}
                  <StyledAsterisk />
                </MuiTypography>
                <InputField name={zipCode.name} label={zipCode.label} fullWidth />
              </MuiBox>
            ) : null}

            <MuiBox m={1} p={1} flexDirection="row">
              {loading ? (
                <CenteredSpinner
                  data-testid="auth-lite-loading"
                  opacity={0.5}
                  backgroundColor="#fff"
                />
              ) : (
                <MuiButton
                  data-testid="auth-lite-sublmit"
                  color="primary"
                  variant="contained"
                  type="submit"
                  disabled={!isValid || loading || attempts >= 3}
                  fullWidth
                >
                  {submitText}
                </MuiButton>
              )}
            </MuiBox>
            <Spacer size="large" />
            <FlexBox>
              <SmallFooter>
                <Footer />
              </SmallFooter>
            </FlexBox>
          </Form>
        );
      }}
    </Formik>
  );
}

export const AuthLiteHeader = () => {
  return (
    <MuiBox borderBottom={`1px solid ${Color.grayHue4}`}>
      <FlexBoxRow justifyContent="center" backgroundColor={Color.grayLight3}>
        <MuiBox m={1}>
          <IntermountainHealthAuthLiteLogo />
        </MuiBox>
      </FlexBoxRow>
    </MuiBox>
  );
};
