import { Color } from 'modules/styles/colors';
import styled from 'styled-components';
import { MuiBox } from 'theme/material-ui';

export const LineGraphKey = styled.div`
  display: flex;
  flex-direction: row;
  text-transform: uppercase;
  justify-content: center;
  margin-top: -2em;
`;

export const KeyText = styled.div`
  padding-right: 0.5em;
  color: white;
  font-family: 'ABC Social Variable', sans-serif;
`;

export const ColoredDot = styled.span`
  height: 25px;
  width: 25px;
  border-radius: 50%;
  border: 1px solid #000;
  background-color: ${props => (props.color === 'KeyDotNormal' ? Color.white : Color.red)};
  margin-top: -0.2em;
`;

export const LineChartContainer = styled(MuiBox)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  & .KeyValueNormal {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  & .KeyValueWarning {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 1em;
  }
  & .lineGraphContainer {
    max-width: 600px;
    width: 100%;
  }
  & .right-trend-title {
    font-weight: 200;
    color: #fff;
    text-align: center;
    margin-top: 1.5em;
  }
  & .header__left {
    float: left;
    margin-left: 1em;
  }
  & .header__right {
    width: calc(80vw - 33vw);
    align-self: flex-end;
  }
`;

export const LineChartContainerStyles = styled(MuiBox)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  shape-rendering: geometricprecision;
  font-family: 'ABC Social Variable', sans-serif;

  & .line-chart.bg-light text {
    fill: #333;
  }
  & .line-chart.bg-light .tick line {
    stroke: #333;
  }
  & .line-chart.bg-light .domain {
    stroke: #333;
  }
  & .line-chart.bg-light .graph-point__data-point {
    fill: #38b;
  }
  & .line-chart.bg-light .graph-point .graph-point__label {
    fill: #fff;
  }
  & .line-chart.bg-light .graph-point2__data-point {
    fill: #000;
  }
  & .line-chart.bg-light .graph-point2 .graph-point__label {
    fill: #fff;
  }
  & .line-chart.bg-light .chart-line,
  & .line-chart.bg-light .chart-line1 {
    stroke: #38b;
  }
  & .line-chart.bg-light .chart-line2 {
    stroke: #000;
  }
  & .line-chart__tabs {
    background-color: #fff;
  }
  & .line-chart__tabs .mat-tab-label {
    flex: 1 1 auto;
  }
  & .line-chart__tabs .mat-tab-header-pagination {
    display: none !important;
  }
  & .line-chart text {
    background: transparent;
    text-rendering: optimizeLegibility;
    font-family: 'ABC Social Variable', sans-serif;
    font-size: 12px;
    fill: #fff;
    stroke: none;
  }
  & .line-chart .x-axis,
  & .line-chart .y-axis {
    font-family: 'ABC Social Variable', sans-serif;
    text-rendering: optimizeLegibility;
    font-weight: lighter;
    font-size: 12px;
    text-anchor: end;
    color: #fff;
  }
  & .line-chart .tick line {
    stroke: #29abe2;
  }
  & .line-chart .domain {
    stroke: #29abe2;
  }
  & .line-chart .x-axis-label {
    transform: rotate(-65deg);
    font-weight: lighter;
  }
  & .line-chart .graph-label-y {
    transform: rotate(-90deg);
    font-size: 1.1rem;
  }
  & .line-chart .graph-label-note {
    font-size: 1.3rem;
    font-weight: bold;
  }
  & .line-chart .graph-label-note + .graph-label-note {
    display: none;
  }
  & .line-chart .graph-label-range {
    font-size: 0.8rem;
    text-transform: uppercase;
    font-weight: bold;
    opacity: 0.5;
    fill: #001733;
  }
  & .line-chart .graph-point__data-point {
    stroke-width: 4px;
    transition: stroke-width 0.3s ease-in-out;
    fill: #fff;
  }
  & .line-chart .graph-point__label-line {
    pointer-events: none;
    stroke: none;
    fill: #fff;
  }
  & .line-chart .graph-point__label {
    fill: #000;
    stroke: none;
    font-size: 1rem;
    pointer-events: none;
    text-anchor: middle;
  }
  & .line-chart .graph-point__label--highlight {
    fill: #049;
    font-weight: bold;
  }
  & .line-chart .graph-point__label.small {
    font-size: 0.7rem;
  }
  & .line-chart .graph-point__inner {
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
  }
  & .line-chart .graph-point__inner.normal .graph-point__data-point {
    fill: ${Color.white};
  }
  & .line-chart .graph-point__inner.normal .graph-point__label,
  & .line-chart .graph-point__inner.normal .graph-point__label-line {
    fill: #000;
  }
  & .line-chart .graph-point__inner.normal .graph-point__label--highlight {
    fill: #049;
  }
  & .line-chart .graph-point__inner.obese .graph-point__data-point {
    fill: #ff6666;
  }
  & .line-chart .graph-point__inner.obese .graph-point__label,
  & .line-chart .graph-point__inner.obese .graph-point__label-line {
    fill: #000;
  }
  & .line-chart .graph-point__inner.obese .graph-point__label--highlight {
    fill: #049;
  }
  & .line-chart .graph-point__inner.warn .graph-point__data-point {
    fill: ${Color.red};
  }
  & .line-chart .graph-point__inner.warn .graph-point__label,
  & .line-chart .graph-point__inner.warn .graph-point__label-line {
    fill: #333;
  }
  & .line-chart .graph-point__inner.warn .graph-point__label--highlight {
    fill: #049;
  }
  & .line-chart .graph-point__inner .graph-point__data-group {
    opacity: 0;
    transition: all 0.25s ease-in-out;
  }
  & .line-chart .graph-point:hover .graph-point__data-group,
  & .line-chart .graph-point.hover .graph-point__inner .graph-point__data-group {
    opacity: 1;
  }
  & .line-chart .graph-point2__data-point {
    fill: #000;
  }
  & .line-chart .graph-point2__label,
  & .line-chart .graph-point2__label-line {
    fill: #fff;
  }
  & .line-chart .graph-point2__label--highlight {
    fill: #049;
    font-weight: bold;
  }
  & .line-chart .graph-point2 .graph-point__inner.normal .graph-point2__data-point {
    fill: ${Color.white};
  }
  & .line-chart .graph-point2 .graph-point__inner.normal .graph-point2__label,
  & .line-chart .graph-point2 .graph-point__inner.normal .graph-point2__label-line {
    fill: #000;
  }
  & .line-chart .graph-point2 .graph-point__inner.normal .graph-point2__label--highlight {
    fill: #049;
  }
  & .line-chart .graph-point2 .graph-point__inner.obese .graph-point2__data-point {
    fill: #ff6666;
  }
  & .line-chart .graph-point2 .graph-point__inner.obese .graph-point2__label,
  & .line-chart .graph-point2 .graph-point__inner.obese .graph-point2__label-line {
    fill: #fff;
  }
  & .line-chart .graph-point2 .graph-point__inner.obese .graph-point2__label--highlight {
    fill: #049;
  }
  & .line-chart .graph-point2 .graph-point__inner.warn .graph-point2__data-point {
    fill: ${Color.red};
  }
  & .line-chart .graph-point2 .graph-point__inner.warn .graph-point2__label,
  & .line-chart .graph-point2 .graph-point__inner.warn .graph-point2__label-line {
    fill: #000;
  }
  & .line-chart .graph-point2 .graph-point__inner.warn .graph-point2__label--highlight {
    fill: #049;
  }
  & .line-chart .range__low {
    fill: #29abe2;
    opacity: 0.8;
    stroke-width: 1px;
    stroke: #29abe2;
    stroke-dasharray: 4;
  }
  & .line-chart .range__normal {
    fill: #29abe2;
    opacity: 0.8;
    stroke-width: 1px;
    stroke: #29abe2;
    stroke-dasharray: 4;
  }
  & .line-chart .range__high {
    fill: #29abe2;
    opacity: 0.8;
    stroke-width: 1px;
    stroke: #29abe2;
    stroke-dasharray: 4;
  }
  & .line-chart .path-area-inner {
    fill: #29abe2;
    opacity: 0.3;
  }
  & .line-chart .chart-line {
    stroke-width: 4px;
    stroke: #fff;
    stroke-linejoin: round;
    stroke-linecap: round;
    stroke-width: 4;
  }
  & .line-chart .chart-line2 {
    stroke: #000;
  }
`;
