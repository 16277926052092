import dayjs from 'dayjs';

import { maskPhone } from 'modules/utils/MaskUtils';
import { CovidScreeningState } from 'store/CovidScreening/types';

// function to populate SubscriberDOB field based on Parient relationship to the Subscriber.
const getSubscriberDOB = (covidScreeningData: CovidScreeningState) => {
  if (covidScreeningData.questionnaire.Q_Patient_relationship_to_subscriber === 'Self') {
    return dayjs(covidScreeningData.patient.dateOfBirth).format('MM/DD/YYYY');
  }
  return dayjs(covidScreeningData?.subscriber.subscriberDateOfBirth).format('MM/DD/YYYY');
};

export const createQuestionnaireObject = (covidScreeningData: CovidScreeningState) => {
  try {
    const { questionnaire } = covidScreeningData;

    let questionnaireObject = {
      Q_COVID_Healthcare_Worker_HD_residence: questionnaire.Q_COVID_Healthcare_Worker_HD_residence,
      Q_Covid19_Prescreening: questionnaire.Q_Covid19_Prescreening,
      Q_Covid_Symptoms_Primary: questionnaire.Q_Covid_Symptoms_Primary,
      has_insurance: questionnaire.has_insurance,

      Q_attest_to_accurate_and_complete: questionnaire.Q_attest_to_accurate_and_complete,
      Q_COVID_Screen_High_Density_Residence: questionnaire.Q_COVID_Screen_High_Density_Residence,
      Q_COVID_Screen_Close_Contact: questionnaire.Q_COVID_Screen_Close_Contact,
      Q_UDOH_Contact_Tracing: questionnaire.Q_UDOH_Contact_Tracing,
      Q_None_of_the_above: questionnaire.Q_None_of_the_above,
      patient_first_name: covidScreeningData?.patient?.names[0].givenName,
      patient_last_name: covidScreeningData?.patient?.names[0].familyName,
      patient_middle_name: covidScreeningData?.patient?.names[0].middleName,
      patient_cell_number: maskPhone(
        covidScreeningData?.patient?.phones[0].areaCode.concat(
          covidScreeningData?.patient?.phones[0].phoneNumber
        )
      ),
      patient_alt_number: covidScreeningData?.patient.alternateNumber
        ?.replace(/[^a-zA-Z0-9 ]/g, '')
        .replace(/ /g, ''),
      patient_ssn: covidScreeningData?.patient?.ssn || null,
      patient_dob: covidScreeningData?.patient?.dateOfBirth,
      patient_sex: covidScreeningData?.patient?.genderType,
      patient_address_1: covidScreeningData?.patient?.addresses[0].line1,
      patient_address_2: covidScreeningData?.patient?.addresses[0].line2,
      patient_zip_code: covidScreeningData?.patient?.addresses[0].zip,
      patient_city: covidScreeningData?.patient?.addresses[0].city,
      patient_state: covidScreeningData?.patient?.addresses[0].state,
      patient_email: covidScreeningData?.patient?.emails[0].address,
      SUBSCRIBER_FIRST_NAME_ON_CARD: questionnaire.SUBSCRIBER_FIRST_NAME_ON_CARD,
      SUBSCRIBER_LAST_NAME_ON_CARD: questionnaire.SUBSCRIBER_LAST_NAME_ON_CARD,
      SUBSCRIBER_MIDDLE_NAME_ON_CARD: questionnaire.SUBSCRIBER_MIDDLE_NAME_ON_CARD
    };

    if (questionnaire.has_insurance === 'Yes') {
      questionnaireObject = {
        ...questionnaireObject,
        Health_Plan_Name: questionnaire.Health_Plan_Name,
        HealthPlan_state: questionnaire.HealthPlan_state,
        HealthPlan_zip_code: questionnaire.HealthPlan_zip_code,
        HealthPlan_City: questionnaire.HealthPlan_City,
        Health_Plan_ID: questionnaire.Health_Plan_ID.toString(),
        Health_Plan_Address_line_1: questionnaire.Health_Plan_Address_line_1,
        HEALTH_PLAN_COUNTRY: questionnaire.HEALTH_PLAN_COUNTRY,
        MEMBER_NUMBER: questionnaire.MEMBER_NUMBER,
        GROUP_NUMBER: questionnaire.GROUP_NUMBER
      };
    }

    if (questionnaire.Q_Outbreak_Date_of_Symptoms_Onset) {
      questionnaireObject = {
        ...questionnaireObject,
        Q_Fever: questionnaire.Q_Fever,
        Q_New_or_Worsening_Cough: questionnaire.Q_New_or_Worsening_Cough,
        Q_Shortness_of_Breath_New_or_Worsening:
          questionnaire.Q_Shortness_of_Breath_New_or_Worsening,
        Q_Body_Aches: questionnaire.Q_Body_Aches,
        Q_COVID_Screen_Sore_Throat: questionnaire.Q_COVID_Screen_Sore_Throat,
        Q_COVID_Screen_Runny_Nose: questionnaire.Q_COVID_Screen_Runny_Nose,
        Q_COVID_Screen_Decreased_Smell_or_Taste:
          questionnaire.Q_COVID_Screen_Decreased_Smell_or_Taste,
        Q_Diarrhea: questionnaire.Q_Diarrhea,
        Q_Outbreak_Date_of_Symptoms_Onset: questionnaire.Q_Outbreak_Date_of_Symptoms_Onset
      };
    }
    if (
      questionnaire.Q_Patient_relationship_to_subscriber === 'Self' ||
      covidScreeningData?.subscriber.subscriberDateOfBirth
    ) {
      questionnaireObject = {
        ...questionnaireObject,
        Q_Patient_relationship_to_subscriber: questionnaire.Q_Patient_relationship_to_subscriber,
        subscriber_address_1:
          covidScreeningData?.subscriber.subscriberAddressLine1 ||
          covidScreeningData.patient.addresses[0].line1,
        subscriber_address_2:
          covidScreeningData?.subscriber.subscriberAddressLine2 ||
          covidScreeningData.patient.addresses[0].line2,
        subscriber_city:
          covidScreeningData?.subscriber.subscriberCity ||
          covidScreeningData.patient.addresses[0].city,
        subscriber_state:
          covidScreeningData?.subscriber.subscriberState ||
          covidScreeningData.patient.addresses[0].state,
        subscriber_zip_code:
          covidScreeningData?.subscriber.subscriberZip ||
          covidScreeningData.patient.addresses[0].zip,
        subscriber_dob: getSubscriberDOB(covidScreeningData),
        subscriber_email:
          covidScreeningData?.subscriber.subscriberEmail ||
          covidScreeningData.patient.emails[0].address,
        subscriber_first_name:
          covidScreeningData?.subscriber.subscriberFirstName ||
          covidScreeningData?.patient?.names[0].givenName,
        subscriber_middle_name:
          covidScreeningData?.subscriber.subscriberMiddleName ||
          covidScreeningData?.patient?.names[0].middleName,
        subscriber_last_name:
          covidScreeningData?.subscriber.subscriberLastName ||
          covidScreeningData?.patient?.names[0].familyName,
        subscriber_phone:
          covidScreeningData?.subscriber.subscriberCellNumber ||
          maskPhone(
            covidScreeningData.patient.phones[0].areaCode.concat(
              covidScreeningData.patient.phones[0].phoneNumber
            )
          ),
        subscriber_sex:
          covidScreeningData?.subscriber.subscriberSex || covidScreeningData.patient.genderType
      };
    }
    return questionnaireObject;
  } catch (error) {
    throw error;
  }
};

export const createSubscriberObject = (covidScreeningData: CovidScreeningState) => {
  let subscriberObject = null;
  if (covidScreeningData.questionnaire.Q_Patient_relationship_to_subscriber === 'Self') {
    subscriberObject = {
      dateOfBirth: dayjs(covidScreeningData.patient.dateOfBirth).format('MM/DD/YYYY'),
      genderType: covidScreeningData.patient.genderType,
      names: [
        {
          givenName: covidScreeningData.patient.names[0].givenName,
          familyName: covidScreeningData.patient.names[0].familyName,
          middleName: covidScreeningData.patient.names[0].middleName
        }
      ],
      addresses: [
        {
          line1: covidScreeningData.patient.addresses[0].line1,
          line2: covidScreeningData.patient.addresses[0].line2,
          city: covidScreeningData.patient.addresses[0].city,
          state: covidScreeningData.patient.addresses[0].state,
          zip: covidScreeningData.patient.addresses[0].zip,
          type: 'HOMEADDR'
        }
      ],
      phones: [
        {
          areaCode: covidScreeningData.patient.phones[0].areaCode,
          phoneNumber: covidScreeningData.patient.phones[0].phoneNumber,
          countryCode: null,
          phoneType: 'CELLPHONE'
        }
      ],
      emails: [
        {
          address: covidScreeningData.patient.emails[0].address,
          type: 'HOMEEMAIL'
        }
      ]
    };
  }
  if (covidScreeningData?.subscriber.subscriberDateOfBirth) {
    subscriberObject = {
      dateOfBirth: dayjs(covidScreeningData?.subscriber.subscriberDateOfBirth).format('MM/DD/YYYY'),
      genderType: covidScreeningData?.subscriber.subscriberSex,
      names: [
        {
          givenName: covidScreeningData?.subscriber.subscriberFirstName,
          familyName: covidScreeningData?.subscriber.subscriberLastName,
          middleName: covidScreeningData?.subscriber.subscriberMiddleName
        }
      ],
      addresses: [
        {
          line1: covidScreeningData?.subscriber.subscriberAddressLine1,
          line2: covidScreeningData?.subscriber.subscriberAddressLine2,
          city: covidScreeningData?.subscriber.subscriberCity,
          state: covidScreeningData?.subscriber.subscriberState,
          zip: covidScreeningData?.subscriber.subscriberZip,
          type: 'HOMEADDR'
        }
      ],
      phones: [
        {
          areaCode: covidScreeningData?.subscriber.subscriberCellNumber
            ?.replace(/[^a-zA-Z0-9]/g, '')
            .substring(0, 3),
          phoneNumber: covidScreeningData?.subscriber.subscriberCellNumber
            ?.replace(/[^a-zA-Z0-9]/g, '')
            .substring(3, 10),
          countryCode: null,
          phoneType: 'CELLPHONE'
        }
      ],
      emails: [
        {
          address: covidScreeningData?.subscriber.subscriberEmail,
          type: 'HOMEEMAIL'
        }
      ]
    };
  }

  return subscriberObject;
};
