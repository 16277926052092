import { InsuranceCardData } from 'store/CovidScreening/types';

export function getInsuranceObjectFormatted(insuranceSelected: InsuranceCardData | null) {
  if (!insuranceSelected) {
    return {
      Health_Plan_ID: '',
      Health_Plan_Name: '',
      Health_Plan_Address_line_1: '',
      HealthPlan_City: '',
      HealthPlan_state: '',
      HealthPlan_zip_code: '',
      HEALTH_PLAN_COUNTRY: '',
      Insurance_Object: '',
      Insurance_Not_Found: true
    };
  }

  return {
    Health_Plan_ID: insuranceSelected?.plan_name ?? '',
    Health_Plan_Name: insuranceSelected?.plan_name ?? '',
    Health_Plan_Address_line_1: insuranceSelected?.street_address ?? '',
    HealthPlan_City: insuranceSelected?.city ?? '',
    HealthPlan_state: insuranceSelected?.state ?? '',
    HealthPlan_zip_code: insuranceSelected?.zipcode ?? '',
    HEALTH_PLAN_COUNTRY: insuranceSelected?.country ?? ''
  };
}
