import Spacer from 'components/UI/Layout/Spacer';
import DefaultText from 'components/UI/Typography/DefaultText';
import dayjs from 'dayjs';
import * as React from 'react';
import styled from 'styled-components';
import {
  MuiContainer,
  MuiTable,
  MuiTableBody,
  MuiTableCell,
  MuiTableRow,
  MuiTypography,
  MuiTablePagination
} from 'theme/material-ui';
import { Text } from 'react-native';
import { degreeSymbol } from 'screens/VisitSummary/constants';
import {
  BLOOD_PRESSURE_VITAL_SERVICE_NAME,
  TEMPERATURE_VITAL_SERVICE_NAME,
  HEAD_CIRCUMFERENCE_VITAL_SERVICE_NAME,
  showStatusInTable
} from 'store/vitals/constants';
import { HistoricalVitalsDataObject, ValueUnits } from 'store/vitals/types';
import { oc } from 'ts-optchain';
import { NavigationScreenProps } from 'screens/navigation';
import { CenterAlign, Dot, StatusContainer, StyledMuiBox, TableWrapper } from './styled';
import { Spacing } from 'modules/styles/variables';

interface Props extends NavigationScreenProps {
  data: HistoricalVitalsDataObject[];
  type: string;
  isFetching: boolean;
}

const TempTympanic = 'Temperature Tympanic';
const TempAxillary = 'Temperature Axillary';
const TempTemporal = 'Temperature Temporal';
const TempRectal = 'Temperature Rectal';
const TempOral = 'Temperature Oral';

const StyledMuiTypography = styled(MuiTypography)`
  &.MuiTypography-root {
    display: inline-block;
    line-height: 36px;
    font-size: 16px;
    margin-right: ${Spacing.small}px;
  }
`;

const StyledMuiTypographySmall = styled(StyledMuiTypography)`
  &.MuiTypography-root {
    font-size: 12px;
  }
`;

const isDegreeMeasurement = (unit: string) => unit.toLowerCase().includes('deg');

const BlockItemValueUnits = ({ values }: { values: ValueUnits[] }) => (
  <>
    {values.map(item => (
      <React.Fragment key={item.units}>
        <StyledMuiTypography>{item.value}</StyledMuiTypography>

        {isDegreeMeasurement(item.units) ? (
          <StyledMuiTypographySmall variant="caption">{degreeSymbol}F</StyledMuiTypographySmall>
        ) : (
          <StyledMuiTypographySmall variant="caption">{item.units}</StyledMuiTypographySmall>
        )}
      </React.Fragment>
    ))}
  </>
);

export const Table = ({ data = [], type, isFetching }: Props) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event: React.FormEvent<HTMLInputElement>, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.FormEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const statusRender = (item: HistoricalVitalsDataObject) => {
    if (showStatusInTable.includes(type)) {
      return (
        <StatusContainer>
          <Dot color={item.statusColor}></Dot>
          <DefaultText>{item.statusText}</DefaultText>
        </StatusContainer>
      );
    }
    return <StatusContainer />;
  };

  const BloodPressureTable = () => {
    return (
      <MuiTableBody>
        {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(item => (
          <React.Fragment>
            <MuiTableRow>
              <MuiTableCell rowSpan={2}>{dayjs(item.date).format('MM/DD/YY')}</MuiTableCell>
              <MuiTableCell>Systolic</MuiTableCell>
              <MuiTableCell>{` ${item['Systolic Blood Pressure'].value} ${item.units}`}</MuiTableCell>
              <MuiTableCell>{statusRender(item['Systolic Blood Pressure'])}</MuiTableCell>
            </MuiTableRow>
            <MuiTableRow>
              <MuiTableCell>Diastolic</MuiTableCell>
              <MuiTableCell>{` ${item['Diastolic Blood Pressure'].value} ${item.units}`}</MuiTableCell>
              <MuiTableCell>{statusRender(item['Diastolic Blood Pressure'])}</MuiTableCell>
            </MuiTableRow>
          </React.Fragment>
        ))}
      </MuiTableBody>
    );
  };

  const TemperatureTable = () => {
    return (
      <MuiTableBody>
        {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(item => (
          <React.Fragment>
            <MuiTableRow>
              <MuiTableCell>{dayjs(item.date).format('MM/DD/YY')}</MuiTableCell>
              <MuiTableCell>
                <BlockItemValueUnits values={item.valueUnits || []} />
              </MuiTableCell>
              <MuiTableCell>{statusRender(item)}</MuiTableCell>
              {item.measureType === TempTympanic ? <MuiTableCell>Source: Ear</MuiTableCell> : null}
              {item.measureType === TempAxillary ? (
                <MuiTableCell>Source: Armpit</MuiTableCell>
              ) : null}
              {item.measureType === TempAxillary ? (
                <MuiTableCell>Source: Armpit</MuiTableCell>
              ) : null}
              {item.measureType === TempTemporal ? (
                <MuiTableCell>Source: Forehead</MuiTableCell>
              ) : null}
              {item.measureType === TempRectal ? <MuiTableCell>Source: Rectal</MuiTableCell> : null}
              {item.measureType === TempOral ? <MuiTableCell>Source: Oral</MuiTableCell> : null}
            </MuiTableRow>
          </React.Fragment>
        ))}
      </MuiTableBody>
    );
  };

  const HeadCircumferenceTable = () => {
    return (
      <MuiTableBody>
        {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(item => (
          <React.Fragment>
            <MuiTableRow>
              <MuiTableCell>{dayjs(item.date).format('MM/DD/YY')}</MuiTableCell>
              <MuiTableCell>
                <BlockItemValueUnits values={item.valueUnits || []} />
              </MuiTableCell>
            </MuiTableRow>
          </React.Fragment>
        ))}
      </MuiTableBody>
    );
  };

  const DefaultTable = () => {
    return (
      <MuiTableBody>
        {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(item => (
          <MuiTableRow style={{ columnWidth: '2em' }}>
            <MuiTableCell>
              <StyledMuiTypography>{dayjs(item.date).format('MM/DD/YY')}</StyledMuiTypography>
            </MuiTableCell>
            <MuiTableCell>
              <BlockItemValueUnits values={item.valueUnits || []} />
            </MuiTableCell>
            <MuiTableCell>{statusRender(item)}</MuiTableCell>
          </MuiTableRow>
        ))}
      </MuiTableBody>
    );
  };

  const DefaultMessage = () => (
    <CenterAlign aria-label="No table data available">
      <Spacer size="medium" />
      <Text>No data found</Text>
    </CenterAlign>
  );

  let content;
  if (isFetching) {
    content = null;
  } else if (oc(data)[0].units() && type === BLOOD_PRESSURE_VITAL_SERVICE_NAME) {
    content = (
      <TableWrapper>
        <MuiContainer maxWidth="lg">
          <StyledMuiBox my={5}>
            <MuiTable aria-label="Vitals Details">
              <BloodPressureTable />
            </MuiTable>
            <MuiTablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </StyledMuiBox>
        </MuiContainer>
      </TableWrapper>
    );
  } else if (type === TEMPERATURE_VITAL_SERVICE_NAME) {
    content = (
      <TableWrapper>
        <MuiContainer maxWidth="lg">
          <StyledMuiBox my={5}>
            <MuiTable aria-label="Vitals Details">
              <TemperatureTable />
            </MuiTable>
            <MuiTablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </StyledMuiBox>
        </MuiContainer>
      </TableWrapper>
    );
  } else if (type === HEAD_CIRCUMFERENCE_VITAL_SERVICE_NAME) {
    content = (
      <TableWrapper>
        <MuiContainer maxWidth="lg">
          <StyledMuiBox my={5}>
            <MuiTable aria-label="Vitals Details">
              <HeadCircumferenceTable />
            </MuiTable>
            <MuiTablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </StyledMuiBox>
        </MuiContainer>
      </TableWrapper>
    );
  } else if (
    (data && data.length > 0 && type !== BLOOD_PRESSURE_VITAL_SERVICE_NAME) ||
    TEMPERATURE_VITAL_SERVICE_NAME ||
    HEAD_CIRCUMFERENCE_VITAL_SERVICE_NAME
  ) {
    content = (
      <TableWrapper>
        <MuiContainer maxWidth="lg">
          <StyledMuiBox>
            <MuiTable aria-label="Vitals Details">
              <DefaultTable />
            </MuiTable>
            <MuiTablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </StyledMuiBox>
        </MuiContainer>
      </TableWrapper>
    );
  } else if (data.length < 1) {
    content = <DefaultMessage />;
  } else {
    return null;
  }
  return content;
};

export default Table;
