import React from 'react';
import { MuiBox } from 'theme/material-ui';
import { CovidTestButton } from 'screens/CovidScreening/Components/CovidTestButton';
import useStyles from 'screens/CovidScreening/Components/CovidTestButton/useStyles';
import { useLanguageSwitcher } from 'lib/hooks/useLanguageSwitcher';
import { ButtonsNavigation_translations } from 'lib/constants/translations/components';

interface ButtonNavigationProps {
  cancelTestId: string;
  disabledNextButton: boolean;
  nextTestId: string;
  skipTestId: string;
  onCancelClick: () => void;
  onNextClick: () => void;
  onSkipClick: () => void;
  isCancel?: boolean;
  isSubmit?: boolean;
  isSkip?: boolean;
}

export default function ButtonsNavigation(props: ButtonNavigationProps) {
  const classes = useStyles();
  const screenText = useLanguageSwitcher(ButtonsNavigation_translations);

  return (
    <MuiBox className={classes.steppersFooter}>
      <CovidTestButton
        data-testid={props.nextTestId}
        fullWidth
        variant="contained"
        disabled={props.disabledNextButton}
        onClick={props.onNextClick}
      >
        {props.isSubmit ? screenText?.submit : screenText?.next}
      </CovidTestButton>

      {props.isSkip ? (
        <CovidTestButton
          data-testid={props.skipTestId}
          fullWidth
          onClick={props.onSkipClick}
          variant="contained"
          color="default"
        >
          {screenText?.skip}
        </CovidTestButton>
      ) : null}

      <CovidTestButton
        data-testid={props.cancelTestId}
        fullWidth
        onClick={props.onCancelClick}
        variant="text"
      >
        {props.isCancel ? screenText?.cancel : screenText?.previous}
      </CovidTestButton>
    </MuiBox>
  );
}
