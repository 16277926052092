import React from 'react';
import Config from 'react-native-config';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { MuiBox, MuiTypography } from 'theme/material-ui';
import { Stepper } from 'components/UI/Stepper';
import { Color } from 'modules/styles/colors';
import { history } from 'lib/history';

const step3Label = Config.FA_SHOW_ASSETS_AND_ASSISTANCE === 'enabled' ? 'Assets' : 'Other Funding';
const steps = ['Basic Info', 'Household', step3Label, 'Bills', 'Submit'];

const activeStepKey = {
  'basic-info': 0,
  household: 1,
  assets: 2,
  'other-funding': 2,
  bills: 3,
  complete: 4
} as const;

const FAStepper = () => {
  const theme = useTheme();
  const isBiggerScreen = useMediaQuery('(min-width:1200px)');
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const slug = history.location.pathname.split('/').pop() || '';
  const activeStep = activeStepKey[slug as keyof typeof activeStepKey];

  return (
    <MuiBox
      display="flex"
      flexDirection={isBiggerScreen ? 'row' : 'column'}
      alignItems={isBiggerScreen ? 'flex-start' : 'center'}
      justifyContent="space-between"
    >
      <MuiTypography fontSize={28} color={Color.white}>
        Financial Assistance Application
      </MuiTypography>
      {!isSmallScreen && (
        <MuiBox
          pl={isBiggerScreen ? 4 : 0}
          pt={isBiggerScreen ? 1 : 4}
          flex={1}
          width="100%"
          maxWidth="650px"
        >
          <Stepper
            steps={steps}
            activeStep={activeStep}
            stepToLabel={step => step}
            options={{
              activeColor: Color.white,
              completedColor: Color.white,
              inactiveColor: 'rgba(255,255,255,0.5)'
            }}
          />
        </MuiBox>
      )}
    </MuiBox>
  );
};

export default FAStepper;
