import React from 'react';

import { Color } from 'modules/styles/colors';
import { SvgProps } from 'components/UI/Svg/Svg';

const stylesDark = {
  fill: Color.MHlogoDarkBlue,
  stroke: 'none'
} as const;

const stylesLight = {
  fill: Color.MHlogoLightBlue,
  stroke: 'none'
} as const;

export default (props?: SvgProps) => ({
  svg: (
    <g>
      <path
        {...stylesDark}
        d="M110,12.42c-0.93,0-1.85,0-2.81,0c0,0.97,0,1.88,0,2.81c-0.89,0-1.75,0-2.64,0c0-0.92,0-1.83,0-2.78
	c-0.95,0-1.86,0-2.8,0c0-0.89,0-1.74,0-2.63c0.92,0,1.83,0,2.79,0c0-0.94,0-1.85,0-2.78c0.89,0,1.75,0,2.65,0c0,0.91,0,1.82,0,2.74
	c0.97,0,1.89,0,2.82,0C110,10.67,110,11.55,110,12.42z"
        fill={props?.color || stylesDark.fill}
      />
      <path
        {...stylesDark}
        d="M16.86,32.89c-0.47,0-0.94,0-1.4,0c-1.88-0.08-3.69-0.47-5.43-1.2c-3.5-1.46-6.15-3.85-8.01-7.13
	c-0.95-1.67-1.57-3.46-1.83-5.36C-0.4,15,0.45,11.11,2.88,7.62c2.22-3.2,5.24-5.32,9-6.38c2.07-0.58,4.19-0.71,6.33-0.44
	c4.17,0.54,7.59,2.46,10.33,5.62c1.1,1.26,2.24,3.21,2.77,4.76c-0.39,0-0.76,0-1.14,0c-0.39,0-0.79-0.01-1.18,0
	c-0.18,0.01-0.27-0.06-0.34-0.22c-1.33-2.8-3.38-4.93-6.13-6.38c-1.57-0.83-3.24-1.32-5.01-1.48c-3.59-0.32-6.84,0.58-9.7,2.76
	c-2.67,2.03-4.4,4.71-5.1,7.98c-0.86,4.04-0.07,7.78,2.39,11.11c2.03,2.76,4.75,4.56,8.11,5.25c4.75,0.98,8.94-0.22,12.51-3.52
	c0.81-0.75,1.49-1.62,2.1-2.55c0.11-0.17,0.12-0.31,0.04-0.5c-1.34-3.28-2.67-6.56-4.01-9.83c-0.08-0.21-0.16-0.42-0.25-0.66
	c1.04,0,2.03,0,3.02,0c0.15,0,0.19,0.08,0.24,0.21c0.84,2.37,1.68,4.74,2.48,7.13c0.06,0.17,0.12,0.34,0.22,0.6
	c0.3-0.78,0.56-1.5,0.83-2.21c0.7-1.86,1.52-3.68,2.27-5.52c0.05-0.13,0.11-0.22,0.28-0.22c0.85,0.01,1.71,0,2.59,0
	c-0.11,0.4-0.3,0.74-0.46,1.09c-1.4,3.11-2.78,6.23-4.23,9.32c-1.62,3.46-4.17,6.04-7.61,7.74c-1.59,0.78-3.27,1.26-5.01,1.5
	C17.75,32.85,17.3,32.86,16.86,32.89z"
        fill={props?.color || stylesDark.fill}
      />
      <path
        {...stylesDark}
        d="M20.21,12.85c-1.19,1.58-2.38,3.16-3.56,4.73c-0.44,0.58-0.87,1.17-1.32,1.76c-0.33,0.44-0.69,0.43-1.01-0.01
	c-1.54-2.1-3.09-4.21-4.63-6.31c-0.04-0.06-0.1-0.12-0.19-0.23c0,3.83,0,7.61,0,11.41c-1,0-1.97,0-2.96,0c0-5.24,0-10.49,0-15.78
	c0.44,0,0.89,0,1.33,0c0.59,0,1.18,0.01,1.77-0.01c0.21-0.01,0.34,0.07,0.46,0.23c1.45,2.01,2.92,4.01,4.38,6.01
	c0.13,0.17,0.26,0.35,0.41,0.55c0.5-0.69,0.99-1.34,1.47-2c1.1-1.52,2.21-3.03,3.31-4.55c0.12-0.16,0.24-0.24,0.46-0.24
	c1.04,0.01,2.08,0.01,3.13,0.01c0,5.27,0,10.52,0,15.78c-0.99,0-1.97,0-2.98,0c0-3.79,0-7.56,0-11.34
	C20.26,12.86,20.23,12.86,20.21,12.85z"
        fill={props?.color || stylesDark.fill}
      />
      <path
        {...stylesLight}
        d="M40.09,15.6c2.61,0,5.17,0,7.77,0c0-2.22,0-4.42,0-6.64c0.99,0,1.95,0,2.93,0c0,5.25,0,10.5,0,15.76
	c-0.97,0-1.93,0-2.92,0c0-2.12,0-4.22,0-6.36c-2.6,0-5.16,0-7.76,0c0,2.11,0,4.22,0,6.36c-1.01,0-1.99,0-2.99,0
	c0-5.25,0-10.49,0-15.75c0.98,0,1.96,0,2.98,0C40.09,11.17,40.09,13.38,40.09,15.6z"
        fill={props?.color || stylesLight.fill}
      />
      <path
        {...stylesLight}
        d="M73.53,15.08c0.04-0.54,0.07-0.99,0.1-1.47c0.86,0,1.71,0,2.59,0c0,3.68,0,7.36,0,11.08c-0.84,0-1.67,0-2.52,0
	c-0.13-0.48-0.06-0.99-0.19-1.51c-0.67,1.02-1.62,1.58-2.77,1.76c-2.87,0.45-5.67-0.84-6.38-4.05c-0.34-1.53-0.29-3.06,0.37-4.51
	c0.82-1.78,2.27-2.7,4.17-2.98c1.08-0.16,2.13-0.1,3.13,0.39C72.62,14.07,73.09,14.5,73.53,15.08z M70.21,22.54
	c1.98,0.02,3.16-1.42,3.26-3.06c0.14-2.2-1.25-3.66-3.13-3.69c-2.03-0.04-3.35,1.23-3.43,3.2C66.83,21.08,68.18,22.49,70.21,22.54z"
        fill={props?.color || stylesLight.fill}
      />
      <path
        {...stylesLight}
        d="M63.67,20.16c-2.88,0-5.75,0-8.66,0c0.13,0.88,0.58,1.49,1.32,1.9c1.39,0.76,3.6,0.55,4.83-0.44
	c0.04-0.03,0.09-0.06,0.12-0.09c0.57,0.58,1.13,1.16,1.71,1.75c-0.24,0.26-0.57,0.51-0.92,0.71c-1.6,0.92-3.32,1.2-5.14,0.9
	c-1.23-0.2-2.32-0.67-3.21-1.56c-0.98-0.99-1.43-2.22-1.54-3.58c-0.1-1.22,0.05-2.39,0.6-3.49c0.86-1.7,2.3-2.62,4.14-2.91
	c1.3-0.2,2.6-0.12,3.82,0.41c1.66,0.71,2.52,2.04,2.87,3.76c0.14,0.71,0.14,1.43,0.13,2.15C63.75,19.81,63.75,19.97,63.67,20.16z
	 M55.06,17.84c2.03,0,4,0,5.98,0c-0.04-0.71-0.44-1.39-1.07-1.72c-1.15-0.61-2.34-0.6-3.49-0.05
	C55.75,16.41,55.27,17.01,55.06,17.84z"
        fill={props?.color || stylesLight.fill}
      />
      <path
        {...stylesLight}
        d="M101.88,24.69c-0.89,0-1.78,0-2.67,0c-0.08-0.13-0.04-0.26-0.04-0.39c0-1.91,0.01-3.82-0.01-5.74
	c-0.01-0.69-0.19-1.34-0.68-1.86c-0.97-1.03-2.81-0.96-3.79,0.12c-0.59,0.65-0.83,1.42-0.84,2.27c-0.01,1.74,0,3.47,0,5.21
	c0,0.12,0,0.24,0,0.38c-0.91,0-1.79,0-2.69,0c0-5.23,0-10.46,0-15.72c0.87,0,1.75,0,2.69,0c0,2,0,4.01,0,6.03
	c0.03,0.01,0.06,0.02,0.08,0.03c0.29-0.34,0.6-0.65,0.97-0.9c1.02-0.68,2.16-0.77,3.33-0.62c1,0.13,1.86,0.55,2.52,1.32
	c0.59,0.69,0.88,1.52,1.04,2.4c0.1,0.55,0.14,1.1,0.14,1.66c-0.01,1.81,0,3.63,0,5.44C101.92,24.44,101.95,24.56,101.88,24.69z"
        fill={props?.color || stylesLight.fill}
      />
      <path
        {...stylesLight}
        d="M82.03,13.6c0.69,0,1.37,0,2.07,0c0-0.96,0-1.89,0-2.85c0.93-0.1,1.82-0.2,2.76-0.3c0,1.04,0,2.07,0,3.14
	c1.02,0,2.01,0,3.02,0c0,0.79,0,1.55,0,2.36c-1,0-2.01,0-2.99,0c-0.12,0.15-0.08,0.28-0.08,0.39c0,1.44,0,2.88,0,4.33
	c0,0.25,0.03,0.5,0.1,0.74c0.22,0.73,0.91,1.1,1.72,0.94c0.33-0.06,0.65-0.16,0.98-0.31c0.25,0.77,0.5,1.52,0.75,2.29
	c-0.31,0.2-0.65,0.27-0.98,0.35c-0.9,0.21-1.81,0.26-2.72,0.02c-1.37-0.36-2.3-1.46-2.49-2.92c-0.1-0.75-0.05-1.5-0.06-2.26
	c-0.01-1.15-0.01-2.29-0.01-3.44c0-0.02-0.01-0.04-0.03-0.11c-0.67,0-1.35,0-2.05,0C82.03,15.18,82.03,14.41,82.03,13.6z"
        fill={props?.color || stylesLight.fill}
      />
      <path
        {...stylesLight}
        d="M80.89,24.68c-0.9,0-1.78,0-2.67,0c0-5.24,0-10.47,0-15.72c0.89,0,1.77,0,2.67,0
	C80.89,14.19,80.89,19.42,80.89,24.68z"
        fill={props?.color || stylesLight.fill}
      />
    </g>
  ),
  viewBox: '0 0 110 30'
});
