import React from 'react';
import { useHistory } from 'react-router-dom';
import CollapsibleListItem from 'components/common/CollapsibleList/CollapsibleListItem';
import { MuiBox, MuiPaper } from 'theme/material-ui';
import { connect } from 'react-redux';
import Config from 'react-native-config';
import { AnalyticsEvent } from 'services/AnalyticsService';
import useNavigationAnalytics from 'hooks/useNavigationAnalytics';
import { RootState } from 'store/types';
import { PMMCTokenObject } from 'store/cost/types';
import { pmmcTokenSelector, pmmcTokenFetchingSelector } from 'store/cost/selectors';
import { IconSize } from 'modules/styles/variables';
import Svg from 'components/UI/Svg/Svg';
import { StyledTitle, StyledMuiBoxContainer } from './styled';

interface GetCareCostEstimatorListProps {
  pmmcToken: PMMCTokenObject;
  pmmcTokenFetching: boolean;
}

const title = 'Search for services and procedures';

const GetCareCostEstimatorList = ({
  pmmcToken,
  pmmcTokenFetching
}: GetCareCostEstimatorListProps) => {
  const history = useHistory();
  const costEstimatorClicked = useNavigationAnalytics(AnalyticsEvent.CostEstimatorStarted);

  const onSelectOption = async () => {
    if (!pmmcTokenFetching) {
      const eproUrl = pmmcToken
        ? `${Config.PMMC_SITE_BASE_URL}/${pmmcToken}`
        : Config.PMMC_SITE_BASE_URL;
      costEstimatorClicked({
        title: 'Get Care Landing Page',
        vendor: 'PMMC'
      });
      history.push('/u/estimate-costs/cost-estimator-pmmc', { eproUrl });
    }
  };

  return (
    <CollapsibleListItem
      leftIcon="WebCostEstimator"
      title="Cost Estimator"
      subtitle="Estimate the cost of procedures"
      error={false ? 'An issue occurred' : undefined}
      analyticsEvent={AnalyticsEvent.EstimateCostsClicked}
      sourceUrlForAnalytics="Get Care"
    >
      <MuiBox width="100%">
        <MuiPaper data-testid="search-for-services-and-procedures-link" elevation={1}>
          <StyledMuiBoxContainer key={title} onClick={onSelectOption} disabled={pmmcTokenFetching}>
            <StyledTitle color="textPrimary">{title}</StyledTitle>
            <MuiBox flex={1} flexDirection="row" justifyContent="flex-end" display="flex">
              <Svg name="ChevronRight" size={IconSize.small} />
            </MuiBox>
          </StyledMuiBoxContainer>
        </MuiPaper>
      </MuiBox>
    </CollapsibleListItem>
  );
};

const mapStateToProps = (state: RootState) => ({
  pmmcToken: pmmcTokenSelector(state),
  pmmcTokenFetching: pmmcTokenFetchingSelector(state)
});

export default connect(mapStateToProps)(GetCareCostEstimatorList);
