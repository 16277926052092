import { CompanyName } from 'modules/utils/ConfigUtils';

export const FINANCIAL_ASSISTANCE_DOCUMENT_NOTE =
  'Please note: supplementary financial documents must be included with your application';

export const RESOURCE_INFO_BOX = `No single organization can meet the needs of all patients who are unable to pay. Patients in need of financial assistance should utilize all resources for which they are eligible. This ensures Intermountain resources will be available to patients who don't qualify for other financial help.`;

export const FAQ_BODY = [
  {
    key: '1',
    title: 'How do you apply?',
    body: [
      {
        type: 'item',
        title: `All medically necessary and generally available care is provided in a timely manner regardless of ability to pay. If you need help paying for medical care at ${CompanyName}, you should apply for financial assistance.`
      },
      {
        type: 'linkItem',
        title: 'Online application',
        linkType: 'in-app',
        routeName: 'FAInitiation'
      },
      {
        type: 'sectionHeading',
        title: 'Mail'
      },
      {
        type: 'item',
        title:
          'You may also print the application form, fill it out, and mail it to the Intermountain Financial Assistance office.'
      },
      {
        type: 'subItem',
        textTransform: 'numericSubItem',
        title:
          'PDF application form can be found on Intermountain’s Financial Assistance FAQ page online'
      },
      {
        type: 'MAIL_ADDRESS_INFO',
        title: '{MAIL_ADDRESS_INFO}'
      },
      {
        type: 'item',
        title:
          'You can also request an application to be mailed to you by calling the Patient Financial Services Department'
      },
      {
        type: 'sectionHeading',
        title: 'Email'
      },
      {
        type: 'item',
        title:
          'You may print the application form, fill it out, scan it, and email it to Intermountain Financial Assistance at'
      },
      {
        type: 'linkItem',
        title: 'financial.assistance@r1rcm.com',
        linkType: 'web',
        routeName:
          'mailto:financial.assistance@r1rcm.com?subject=Financial Assistance Application&body=Please attach scanned PDF form and supplementary financial documents'
      },
      {
        type: 'sectionHeading',
        title: 'In Person'
      },
      {
        type: 'item',
        title: 'Visit your local Intermountain hospital or clinic where you receive services.'
      },
      {
        type: 'sectionHeading',
        title: 'Call'
      },
      {
        type: 'item',
        title: 'Contact our Patient Financial Services Department at:',
        textTransform: 'center'
      },
      {
        type: 'PATIENT_FINANCIAL_SERVICE_NUMBER',
        title: '{PATIENT_FINANCIAL_SERVICE_NUMBER}'
      },
      {
        type: 'item',
        title: 'They can assist you with any questions.',
        textTransform: 'center'
      }
    ]
  },
  {
    key: '2',
    title: 'What to include with your application?',
    body: [
      {
        type: 'item',
        title: 'After completing the application form, please submit the following documentation:'
      },
      {
        type: 'item',
        title: 'Copies of your current federal tax return with all schedules, including W-2s'
      },
      {
        type: 'subItem',
        title:
          "If you don't have tax return documents, please submit the non-filing letter from the IRS"
      },
      {
        type: 'item',
        title:
          'Household income verification (e.g., paycheck stubs for the past two pay periods OR your 3 most recent monthly bank statements).'
      },
      {
        type: 'subItem',
        title:
          "If you can't provide above information, please provide the following, as applicable:"
      },
      {
        type: 'subInnerItem',
        title: 'Title 1 school assistance letter'
      },
      {
        type: 'subInnerItem',
        title:
          'Support letter written by whomever is supporting you financially (Please note: This person will not be liable for your balance)'
      },
      {
        type: 'item',
        title: 'Please also provide following documents, as applicable:'
      },
      {
        type: 'subItem',
        title: 'Pension/retirement statement'
      },
      {
        type: 'subItem',
        title: 'Social security award letter'
      },
      {
        type: 'subItem',
        title: 'Unemployment award letter'
      },
      {
        type: 'subItem',
        title: 'Disability award letter'
      },
      {
        type: 'subItem',
        title: 'Denial letter from Medicaid'
      },
      {
        type: 'subItem',
        title: 'Child support award letter'
      },
      {
        type: 'subItem',
        title: 'Alimony award letter'
      },
      {
        type: 'item',
        title: "If you don't have insurance, we will also require the following:"
      },
      {
        type: 'subItem',
        title: 'Medicaid denial letter'
      },
      {
        type: 'subItem',
        title:
          'Screening completed by an Eligibility Counselor at the Intermountain facility (while in-patient)'
      },
      {
        type: 'item',
        title:
          'Please note: above list is not exhaustive and Intermountain will contact you via mail regarding specific verification information you are missing'
      },
      {
        type: 'item',
        title: 'If you cannot provide these, please explain why on the application form.',
        textTransform: 'italic'
      }
    ]
  },
  {
    key: '3',
    title: "When will I know about the financial assistance I'll receive?",
    body: `Within about 10 days of submitting your application and all required information, you'll receive confirmation via mail that Intermountain is processing your application.

You may be asked to apply for Medicaid or other programs before you receive financial assistance. The representative can help you with this application.`
  },
  {
    key: '4',
    title: 'If I qualify, how much help will I receive?',
    body: [
      {
        type: 'item',
        title: 'The amount of financial assistance provided will be based on need.'
      },
      {
        type: 'item',
        title:
          'If approved for full financial assistance, you will be asked to pay only a nominal amount. If you are unable to pay this, the amount can be waived based off extenuating circumstances.'
      },
      {
        type: 'item',
        title:
          'If you qualify for partial assistance, the remaining balance of the bill is your responsibility to pay. Intermountain has various payment plans available, including interest-free payment plans.'
      },
      {
        type: 'entripay',
        title: 'Set up a payment plan'
      }
    ]
  },
  {
    key: '5',
    title: 'How do I know if I qualify for help paying my medical bills?',
    body: [
      {
        type: 'item',
        title: 'Please see the Eligibility page',
        textTransform: 'center'
      },
      {
        type: 'linkItem',
        title: 'Financial Assistance Eligibility',
        linkType: 'in-app',
        routeName: 'FAEligibility'
      }
    ]
  },
  {
    key: '6',
    title: 'Do I have any other payment options?',
    body: [
      {
        type: 'item',
        title: `${CompanyName} can help you manage your bills even if you do not apply for or qualify for financial assistance. Intermountain offers both short-term and long-term payment plans. Call the Financial Assistance Department for more information at`
      },
      {
        type: 'PATIENT_FINANCIAL_SERVICE_NUMBER',
        title: '{PATIENT_FINANCIAL_SERVICE_NUMBER}'
      }
    ]
  },
  {
    key: '7',
    title:
      'Why do I need to look for other sources of funding before I receive financial assistance?',
    body: [
      {
        type: 'item',
        title:
          'You may be asked to apply for Medicaid or other programs before you receive financial assistance from Intermountain.'
      },
      {
        type: 'linkItem',
        title: 'List of Assistance Resources',
        routeName:
          'https://intermountainhealthcare.org/patient-tools/financial-assistance/other-resources/',
        linkType: 'web'
      },
      {
        type: 'RESOURCE_INFO_BOX',
        title: '{RESOURCE_INFO_BOX}'
      }
    ]
  },
  {
    key: '8',
    title: 'What bills will Intermountain Financial Assistance Cover?',
    body: `Intermountain’s Financial Assistance Program applies to bills for care received at ${CompanyName} hospitals, clinics and healthcare providers employed by ${CompanyName}.

Some medical care you receive may not be covered by Intermountain’s Financial Assistance Program. For example, care provided by certain physicians who are not Intermountain caregivers are not covered. Please contact your non-${CompanyName} providers directly to ask if any financial assistance is available.`
  },
  {
    key: '9',
    title: "I've already received my medical bill – can I still apply for financial assistance?",
    body:
      'Yes. Patients are strongly encouraged to apply for financial assistance from the time they first start to receive care. However, patients can apply for assistance even if their bill has been sent to collections.'
  }
];

export const ELIGIBILITY_BODY = `Eligibility is based on many factors. This includes your family size, income, assets, and outstanding medical debt. You may also qualify if you have a catastrophic healthcare event. This table will help you determine if you may qualify. The figures listed are household incomes before taxes, up to these amounts. Patients 250% at or below federal poverty line are encouraged to apply for assistance. Patients above 250-500% of the poverty line will be considered in extenuating circumstances only, such as if they have incurred significant medical debt.`;

export const ELIGIBILITY_DATA = [
  {
    id: 1,
    value1: '$36,450.00',
    value2: '$43,740.00',
    value3: '$51,030.00',
    value4: '$58,320.00',
    value5: '$72,900.00'
  },
  {
    id: 2,
    value1: '$49,300.00',
    value2: '$59,160.00',
    value3: '$69,020.00',
    value4: '$78,880.00',
    value5: '$98,600.00'
  },
  {
    id: 3,
    value1: '$62,150.00',
    value2: '$74,580.00',
    value3: '$87,010.00',
    value4: '$99,440.00',
    value5: '$124,300.00'
  },
  {
    id: 4,
    value1: '$75,000.00',
    value2: '$90,000.00',
    value3: '$105,000.00',
    value4: '$120,000.00',
    value5: '$150,000.00'
  },
  {
    id: 5,
    value1: '$87,850.00',
    value2: '$105,420.00',
    value3: '$122,990.00',
    value4: '$140,560.00',
    value5: '$175,700.00'
  },
  {
    id: 6,
    value1: '$100,700.00',
    value2: '$120,840.00',
    value3: '$140,980.00',
    value4: '$161,120.00',
    value5: '$201,400.00'
  },
  {
    id: 7,
    value1: '$113,550.00',
    value2: '$136,260.00',
    value3: '$158,970.00',
    value4: '$181,680.00',
    value5: '$227,100.00'
  },
  {
    id: 8,
    value1: '$126,400.00',
    value2: '$151,680.00',
    value3: '$176,960.00',
    value4: '$202,240.00',
    value5: '$252,800.00'
  }
];
