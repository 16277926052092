import React from 'react';
import { ConnectCareTextField } from 'components/ConnectCare';
import { Color } from 'modules/styles/colors';
import { AutocompleteProps, UseAutocompleteProps, Autocomplete } from '@material-ui/lab';

export type ConnectCareAutocompleteProps<T> = Partial<AutocompleteProps<T>> &
  UseAutocompleteProps<T> & { label?: React.ReactNode; placeholder?: string };

function ConnectCareAutocomplete<T = any>({
  label,
  placeholder,
  ...rest
}: ConnectCareAutocompleteProps<T>) {
  return (
    <Autocomplete
      {...rest}
      style={{ width: '100%', ...rest.style }}
      renderInput={({ InputProps, ...r }) => (
        <ConnectCareTextField
          {...r}
          InputProps={{
            style: { background: Color.white },
            ref: InputProps.ref,
            endAdornment: InputProps.endAdornment
          }}
          label={label}
          placeholder={placeholder}
        />
      )}
    />
  );
}

ConnectCareAutocomplete.defaultProps = {
  getOptionLabel: (option: any) => (option ? option.label : '')
};

export default ConnectCareAutocomplete;
