import React from 'react';

import { FontSize, FontWeight } from 'modules/styles/variables';
import { Color } from 'modules/styles/colors';

import Spacer from 'components/UI/Layout/Spacer';
import FlexBox, { FlexBoxColumn } from 'components/UI/Layout/FlexBox';
import {
  BillingDetailMarginViewSummary,
  CenteredTypography,
  BillDetailBorderFlex,
  DueNowHeading,
  StyledHeading,
  GreyBoxTypography
} from 'screens/Billing/styled';
import formatMoney from 'services/formatMoney';
import LinkingServices from 'services/LinkingServices';
import { patientFinancialServicesNumber } from 'modules/constants/phoneNumbers';

export const renderBadDebt = (amountDue: number, status: string | null) => {
  return (
    <>
      <BillingDetailMarginViewSummary justifyContent="center" alignItems="center">
        <FlexBoxColumn justifyContent="center" alignItems="center">
          <CenteredTypography fontSize={FontSize.largeHeading}>
            Immediate attention required
          </CenteredTypography>
          <Spacer size="small" />
          <CenteredTypography>
            All bills are overdue and were likely sent to a collection agency
          </CenteredTypography>
        </FlexBoxColumn>
        <BillDetailBorderFlex flexDirection="column" justifyContent="center" alignItems="center">
          <DueNowHeading
            fontSize={FontSize.base}
            fontWeight={FontWeight.bold}
            data-testid="bad-debt-due-status"
            accessibilityLabel="Due Status"
          >
            {status}
          </DueNowHeading>
          <StyledHeading
            adjustsFontSizeToFit
            accessibilityLabel="Total Owed Amt"
            data-testid="bad-debt-total-amount-due"
          >
            {`$${formatMoney(amountDue)}`}
          </StyledHeading>
          <Spacer size="small" />
        </BillDetailBorderFlex>
        <FlexBox justifyContent="center" alignItems="center">
          <CenteredTypography>
            In order to pay this balance, please call {'\n'} Patient Financial Services at
            <CenteredTypography
              onPress={() => {
                LinkingServices.callPhoneNumber(patientFinancialServicesNumber);
              }}
              color={Color.primaryUnderlay}
            >
              {` 1-${patientFinancialServicesNumber}`}
            </CenteredTypography>
          </CenteredTypography>
          <Spacer size="small" />
          <GreyBoxTypography fontSize={FontSize.small}>
            Please ignore this message if the balance has already been paid
          </GreyBoxTypography>
        </FlexBox>
      </BillingDetailMarginViewSummary>
    </>
  );
};
