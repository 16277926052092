import React from 'react';

import { MuiTypography } from 'theme/material-ui';
import shareIdeas from 'assets/HealthTools/share-ideas.png';
import { HealthToolCardData } from 'components/HealthToolsCards/HealthToolCard';
import { FontWeight } from 'modules/styles/variables';

export const shareIdeasCard: HealthToolCardData = {
  healthToolCardKey: 'shareIdeas',
  description: (
    <MuiTypography>
      <MuiTypography display="inline">Share your </MuiTypography>
      <MuiTypography fontWeight={FontWeight.bold} display="inline">
        ideas
      </MuiTypography>
      <MuiTypography display="inline"> for new health tools</MuiTypography>
    </MuiTypography>
  ),
  path: '/u/health-tools/survey',
  thumb: shareIdeas
};

export default shareIdeasCard;
