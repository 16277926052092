import { useState } from 'react';

export const useLocalStorage = (key: string, defaultValue = '') => {
  const [value, setValue] = useState<string>(() => {
    try {
      const saved = localStorage.getItem(key);
      if (saved !== null) {
        return saved;
      }
      return defaultValue;
    } catch {
      return defaultValue;
    }
  });

  const setStorageValue = (value: string) => {
    localStorage.setItem(key, value);
    setValue(value);
  };

  return [value, setStorageValue] as const;
};
