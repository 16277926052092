import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getAuthLitePatientLetter } from 'store/testResults/lab/actions';
import { BinaryDocument } from 'store/visitSummary/types';
import { Nullable } from 'modules/types/common';
import { MuiBox, MuiTypography } from 'theme/material-ui';
import { FlexBoxRow } from 'components/UI/Layout/FlexBox';
import Spinner from 'components/UI/Spinner/Spinner';
import DataLoader from 'components/UI/DataLoader/DataLoader';
import { oc } from 'ts-optchain';
import { Divider } from '@material-ui/core';
import { Color } from 'modules/styles/colors';
import { FontWeight, FontSize } from 'modules/styles/variables';

export interface Props {
  match: { params: { docId: string } };
}

export function AuthLitePatientLetter({ match }: Props) {
  const dispatch = useDispatch();

  const [data, setData] = useState<null | BinaryDocument>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error>();
  const documentId = match.params.docId;

  // Render Document Content
  const RenderDocument = (data: Nullable<{ content: string; contentType: string }>) => {
    const documentObj = data.data;
    const content = oc(documentObj).content();
    const contentType = oc(documentObj).contentType();

    if (content && contentType && contentType.includes('application/pdf')) {
      return (
        <>
          <MuiBox p={2} bgcolor={Color.grayLight3}>
            <MuiTypography fontSize={FontSize.large} fontWeight={FontWeight.bold}>
              Patient Letters
            </MuiTypography>
          </MuiBox>
          <Divider />
          <iframe
            title="Patient letter"
            style={{ width: '100%', height: '100vh' }}
            src={`data:application/pdf;base64, ${encodeURI(content)}`}
          ></iframe>
        </>
      );
    }
    return <></>;
  };

  const handleDocumentReceived = (document: BinaryDocument) => {
    setData(document);
    setLoading(false);
  };

  useEffect(() => {
    if (documentId) {
      dispatch(getAuthLitePatientLetter('application/json+fhir', documentId))
        .then(res => {
          const { data } = res.payload;
          handleDocumentReceived(data);
        })
        .catch(error => {
          setError(error);
        });
    }
  }, [documentId]);

  return (
    <DataLoader
      loading={loading}
      data={data}
      error={error}
      renderLoading={() => (
        <MuiBox p={5} display="flex" alignItems="center" justifyContent="center">
          <Spinner />
        </MuiBox>
      )}
      renderError={() => (
        <FlexBoxRow alignItems="center" justifyContent="center">
          <MuiTypography>There was an issue loading document</MuiTypography>
        </FlexBoxRow>
      )}
      renderNoData={() => (
        <FlexBoxRow alignItems="center" justifyContent="center">
          <MuiTypography>No documents on file.</MuiTypography>
        </FlexBoxRow>
      )}
      renderData={() => <RenderDocument data={data} />}
    />
  );
}

export default AuthLitePatientLetter;
