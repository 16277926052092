import styled from 'styled-components';

import DefaultText from './DefaultText';
import { Spacing } from 'modules/styles/variables';

interface Props {
  textAlign?: string;
  margin?: string;
}

export default styled(DefaultText)`
  align-self: center;
  letter-spacing: 0.25px;
  line-height: 20px;
  max-width: 208px;
  text-align: center;
`;

export const ParagraphMargined = styled(DefaultText)`
  letter-spacing: 0.25px;
  line-height: 20px;
  margin: ${(props: Props) =>
    props.margin ? props.margin : `${Spacing.medium}px ${Spacing.large}px`};
  text-align: ${(props: Props) => (props.textAlign ? props.textAlign : 'left')};
`;
