// deprecated
import styled from 'styled-components';

import { smallFontSize } from 'modules/styles/variables';

import DefaultText from './DefaultText';

export default styled(DefaultText)`
  font-size: ${smallFontSize};
  line-height: 17px;
  padding-left: 5px;
  flex-direction: row;
  flex-wrap: wrap;
  &.displayHours {
    padding-left: 0;
  }
  &.distanceTextSpacer {
    padding-left: 20px;
  }
`;
