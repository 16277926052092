import React from 'react';
import Divider from 'components/UI/Divider';
import analyticsService, { AmplitudeEventData, AnalyticsEvent } from 'services/AnalyticsService';
import { LabResult, PatientLetters } from 'store/testResults/types';
import Typography from 'components/UI/Typography';
import { Color } from 'modules/styles/colors';
import { FontSize, FontWeight } from 'modules/styles/variables';
import Spinner from 'components/UI/Spinner/Spinner';
import { MuiBox, MuiButton } from 'theme/material-ui';

// RELATED DOCUMENTS
const RelatedDocuments = ({
  content,
  downloadPatientLetter,
  patientLetterLoading,
  referringUrl
}: {
  content: LabResult[];
  downloadPatientLetter: (documentId: string) => void;
  patientLetterLoading: boolean;
  referringUrl: string;
}) => {
  // const history = useHistory();
  const handleViewPatientLetters = (item: PatientLetters) => {
    const evnentData: AmplitudeEventData = {
      currentUrl: '/al/test-results',
      referringUrl,
      authentication_type: 'Light'
    };
    analyticsService.logEvent(AnalyticsEvent.DownloadPatientLetterClicked, evnentData);

    // TEMP: DFD-20508 - removing view and adding download feature to patient letters
    // history.push(`/al/test-results/patient-letters/XR-${item.docId}`);
    downloadPatientLetter(`XR-${item.docId}`);
  };
  if (!content) return null;
  const { patientLetters } = content[0];

  if (patientLetters?.some(a => a.docId === 0)) return null;
  if (patientLetters?.length) {
    return (
      <>
        <Divider />
        <MuiBox p={2} bgcolor={Color.grayLight3}>
          <Typography fontSize={FontSize.large} fontWeight={FontWeight.bold}>
            Related Documents
          </Typography>
        </MuiBox>
        <Divider />
        <MuiBox p={3}>
          {patientLetters?.length
            ? patientLetters.map((eachDoc: PatientLetters) => (
                <MuiButton
                  variant="text"
                  color="primary"
                  onClick={() => handleViewPatientLetters(eachDoc)}
                >
                  {patientLetterLoading ? <Spinner /> : `Download ${eachDoc.title}`}
                </MuiButton>
              ))
            : null}
        </MuiBox>
      </>
    );
  }
  return null;
};

export default RelatedDocuments;
