import CollapsibleListItem from 'components/common/CollapsibleList/CollapsibleListItem';
import { MuiBox, MuiPaper, MuiTypography } from 'theme/material-ui';
import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'store/types';
import { AnalyticsEvent } from 'services/AnalyticsService';
import {
  currentLocationPathNameSelector,
  previousLocationPathNameSelector
} from 'store/router/selectors';
import { IconSize, Spacing } from 'modules/styles/variables';
import Svg from 'components/UI/Svg/Svg';
import { Link } from 'react-router-dom';
import { StyledMuiBoxContainer, StyledMuiTypography, StyledSymbolMuiTypography } from './styled';
import { convertToLowerKabobCase } from 'modules/utils/StringUtils';

const getCareNowStaticData = [
  {
    title: 'Connect Care',
    costSymbol: '&#36;',
    desc: '24/7 video visit for low-level urgent conditions',
    to: '/u/get-care-now/connect-care'
  },
  {
    title: 'Nearest InstaCare',
    costSymbol: '&#36;&#36;',
    desc: 'Urgent care for non life-threatening conditions',
    to: '/u/get-care-now/live-care/instacares'
  },
  {
    title: 'Nearest Kids Care',
    costSymbol: '&#36;&#36;',
    desc: 'Pediatric urgent-care for non life-threatening needs',
    to: '/u/get-care-now/live-care/kidscares'
  },
  {
    title: 'Nearest Emergency Room',
    costSymbol: '&#36;&#36;&#36;&#36;',
    desc: 'Fast, immediate care for severe symptoms',
    to: '/u/get-care-now/live-care/emergencyRooms'
  },
  {
    title: 'Need help choosing?',
    desc: 'Get care guidance based on your symptoms',
    to: '/u/assess-my-symptoms/terms'
  },
  {
    title: 'Behavioral Health Services',
    desc: 'Guidance, tools, and referrals for mental well-being',
    to: '/u/get-care-now/care/emotionalSupport'
  }
];

interface NavLinkProps {
  to: string;
  isExternal?: boolean;
  children: React.ReactNode;
}

interface GetCareNowListProps {
  currentUrl?: string;
  referringUrl?: string;
}

const NavLink = ({ isExternal, children, to }: NavLinkProps) => {
  return isExternal ? <a href={to}>{children}</a> : <Link to={to}>{children}</Link>;
};

export const GetCareNowList = ({ currentUrl, referringUrl }: GetCareNowListProps) => {
  return (
    <CollapsibleListItem
      leftIcon="WebGetCareNow"
      title="Get Care Now"
      subtitle="Options for quick, quality care today"
      analyticsEvent={AnalyticsEvent.GetCareNowClicked}
      currentUrlForAnalytics={currentUrl}
      referringUrlForAnalytics={referringUrl}
    >
      <MuiBox width="100%">
        <MuiPaper elevation={1}>
          {getCareNowStaticData.map(item => (
            <NavLink to={item.to} key={item.title}>
              <StyledMuiBoxContainer>
                <MuiBox
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  paddingRight={Spacing.xSmall}
                  style={{ flex: '0 0 35%' }}
                >
                  <MuiTypography
                    data-testid={convertToLowerKabobCase(item.title, '-drawer-item')}
                    color="textPrimary"
                    style={{ fontWeight: 'bold' }}
                  >
                    {item.title}
                  </MuiTypography>
                  {item.costSymbol && (
                    <StyledSymbolMuiTypography
                      color="textSecondary"
                      dangerouslySetInnerHTML={{ __html: item.costSymbol }}
                    ></StyledSymbolMuiTypography>
                  )}
                </MuiBox>
                <MuiTypography
                  color="textSecondary"
                  variant="subtitle1"
                  style={{ flex: '0 0 50%' }}
                >
                  {item.desc}
                </MuiTypography>
                <MuiBox display="flex" flex={1} flexDirection="row-reverse">
                  <Svg name="ChevronRight" size={IconSize.xmedium} />
                </MuiBox>
              </StyledMuiBoxContainer>
            </NavLink>
          ))}
          <MuiBox
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            style={{ height: '4em' }}
          >
            <StyledMuiTypography>
              If this is an emergency, please CALL 911 immediately.
            </StyledMuiTypography>
          </MuiBox>
        </MuiPaper>
      </MuiBox>
    </CollapsibleListItem>
  );
};

const mapState = (state: RootState) => ({
  currentUrl: currentLocationPathNameSelector(state),
  referringUrl: previousLocationPathNameSelector(state)
});

export default connect(mapState)(GetCareNowList);
