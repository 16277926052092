import React from 'react';

export default {
  svg: (
    <g>
      <g>
        <g>
          <circle fill="#FF5D55" strokeWidth={0} cx={40} cy={40} r={38.5} />
          <path
            fill="#FFFFFF"
            strokeWidth={0}
            d="M40,2.5c20.711,0,37.5,16.789,37.5,37.5S60.711,77.5,40,77.5S2.5,60.711,2.5,40S19.289,2.5,40,2.5 M40,0.5 C18.22,0.5,0.5,18.22,0.5,40c0,21.78,17.72,39.5,39.5,39.5S79.5,61.78,79.5,40C79.5,18.22,61.78,0.5,40,0.5L40,0.5z"
          />
        </g>
        <g>
          <defs>
            <circle id="SVGID_7_" cx={40} cy={40} r={37.5} />
          </defs>
          <clipPath id="SVGID_2_24">
            <use xlinkHref="#SVGID_7_" />
          </clipPath>
          <g id="Female_-_Adult_7_" clipPath="url(#SVGID_2_24)">
            <path
              fill="#F6CCB8"
              strokeWidth={0}
              d="M54.707,35.727v-6.676c0-7.09-8.208-16.048-14.706-16.048c-6.497,0-14.706,8.958-14.706,16.048v6.676 c-1.805,0-3.268,1.437-3.268,3.21v3.338c0,1.773,1.463,3.21,3.268,3.21c0,4.828,3.807,10.638,8.287,13.856l0,1.119 c0,2.399,0,5.597,0,5.597l-4.971,0.389l-3.374,20.557c0.001,0,29.528,0,29.528,0c0.001,0-3.374-20.557-3.374-20.557l-4.97-0.389 c0,0,0-3.198,0-5.597v-1.12c4.48-3.218,8.286-9.028,8.286-13.856c1.805,0,3.268-1.437,3.268-3.21v-3.338 C57.975,37.164,56.512,35.727,54.707,35.727z"
            />
            <path
              fill="#84D1C9"
              strokeWidth={0}
              d="M69.785,103.5h-59.57c0.18-6.426,0.385-11.747,0.61-13.743l0.032-0.27 c0.687-6.06,1.541-13.602,6.875-18.725c3.043-2.914,6.875-3.935,10.476-4.301c0.315-0.032,0.623-0.058,0.931-0.083 c-0.128,2.696-0.032,14.064,10.784,14.064c10.836,0,10.913-11.413,10.778-14.077c0.302,0.026,0.61,0.051,0.918,0.077 c3.653,0.353,7.562,1.354,10.65,4.32c5.341,5.123,6.195,12.665,6.881,18.725l0.026,0.27 C69.406,91.753,69.612,97.074,69.785,103.5z"
            />
            <path
              fill="#EEAB91"
              strokeWidth={0}
              d="M54.707,35.727v-6.676c0-7.09-8.208-16.048-14.706-16.048v25.998c-2.384,4.98-3.268,10.271-3.268,10.271 l3.268,0.642v11.855c-1.97-0.102-4.076-1.009-6.052-2.429v2.384l12.838,4.516V59.34c-2.099,1.508-4.346,2.449-6.419,2.449 c-0.053,0-0.108-0.008-0.161-0.009c6.464-0.19,14.5-9.28,14.5-16.296c1.805,0,3.268-1.437,3.268-3.21v-3.338 C57.975,37.164,56.512,35.727,54.707,35.727z"
            />
            <g>
              <g>
                <path
                  fill="#EBA388"
                  strokeWidth={0}
                  d="M34.562,39.98c0.049,1.399-1.046,2.573-2.445,2.622c-1.399,0.049-2.573-1.046-2.622-2.445 c-0.049-1.399,1.046-2.573,2.445-2.622C33.339,37.486,34.513,38.581,34.562,39.98z"
                />
                <path
                  fill="#3C2415"
                  strokeWidth={0}
                  d="M34.562,39.338c0.049,1.399-1.046,2.573-2.445,2.622c-1.399,0.049-2.573-1.046-2.622-2.445 c-0.049-1.399,1.046-2.573,2.445-2.622C33.339,36.844,34.513,37.939,34.562,39.338z"
                />
                <path
                  fill="#FFFFFF"
                  strokeWidth={0}
                  d="M33.522,39.897c0.012,0.35-0.261,0.643-0.611,0.656c-0.35,0.012-0.643-0.261-0.656-0.611 c-0.012-0.35,0.261-0.643,0.611-0.656C33.217,39.274,33.51,39.547,33.522,39.897z"
                />
                <path
                  fill="#3C2415"
                  strokeWidth={0}
                  d="M35.112,37.402c-0.468-1.086-1.666-1.91-2.982-2.05c-0.807-0.088-2.32,0.032-3.621,1.678 c-0.062,0.005-0.12,0.007-0.192-0.001c-0.145-0.013-0.346-0.06-0.542-0.143c-0.399-0.161-0.731-0.497-0.731-0.497 c-0.023-0.022-0.061-0.031-0.091-0.016c-0.039,0.019-0.056,0.067-0.036,0.106c0,0,0.05,0.11,0.146,0.269 c0.094,0.16,0.243,0.366,0.431,0.546c0.187,0.181,0.41,0.335,0.598,0.429c0.093,0.048,0.178,0.081,0.241,0.103 c0.06,0.021,0.124,0.033,0.113,0.032c0.175,0.018,0.334-0.076,0.411-0.223c0.852-1.19,1.991-1.775,3.207-1.644 c1.075,0.115,2.087,0.8,2.46,1.666c0.071,0.163,0.26,0.238,0.422,0.168C35.106,37.753,35.182,37.565,35.112,37.402z"
                />
              </g>
              <g>
                <path
                  fill="#E4896B"
                  strokeWidth={0}
                  d="M50.61,39.98c0.049,1.399-1.046,2.573-2.445,2.622c-1.399,0.049-2.573-1.046-2.622-2.445 c-0.049-1.399,1.046-2.573,2.445-2.622C49.387,37.486,50.561,38.581,50.61,39.98z"
                />
                <path
                  fill="#3C2415"
                  strokeWidth={0}
                  d="M50.61,39.338c0.049,1.399-1.046,2.573-2.445,2.622c-1.399,0.049-2.573-1.046-2.622-2.445 c-0.049-1.399,1.046-2.573,2.445-2.622C49.387,36.844,50.561,37.939,50.61,39.338z"
                />
                <path
                  fill="#FFFFFF"
                  strokeWidth={0}
                  d="M49.57,39.897c0.012,0.35-0.261,0.643-0.611,0.656c-0.35,0.012-0.643-0.261-0.656-0.611 c-0.012-0.35,0.261-0.643,0.611-0.656C49.265,39.274,49.558,39.547,49.57,39.897z"
                />
                <path
                  fill="#3C2415"
                  strokeWidth={0}
                  d="M44.993,37.402c0.468-1.086,1.666-1.91,2.982-2.05c0.807-0.088,2.32,0.032,3.621,1.678 c0.062,0.005,0.12,0.007,0.192-0.001c0.145-0.013,0.346-0.06,0.542-0.143c0.399-0.161,0.731-0.497,0.731-0.497 c0.023-0.022,0.061-0.031,0.091-0.016c0.039,0.019,0.056,0.067,0.036,0.106c0,0-0.05,0.11-0.146,0.269 c-0.094,0.16-0.243,0.366-0.431,0.546c-0.187,0.181-0.41,0.335-0.598,0.429c-0.093,0.048-0.178,0.081-0.241,0.103 c-0.06,0.021-0.124,0.033-0.113,0.032c-0.175,0.018-0.334-0.076-0.411-0.223c-0.852-1.19-1.991-1.775-3.207-1.644 c-1.075,0.115-2.087,0.8-2.46,1.666c-0.071,0.163-0.26,0.238-0.422,0.168C44.998,37.753,44.923,37.565,44.993,37.402z"
                />
              </g>
            </g>
            <g>
              <path
                fill="#F05D44"
                strokeWidth={0}
                d="M45.136,52.285c0,2.521-2.299,4.565-5.135,4.565c-2.836,0-5.135-2.044-5.135-4.565H45.136z"
              />
              <path
                fill="#FFFFFF"
                strokeWidth={0}
                d="M43.582,53.997h-7.275c-0.355,0-0.642-0.287-0.642-0.642v-1.07h8.559v1.07 C44.224,53.709,43.937,53.997,43.582,53.997z"
              />
            </g>
            <g>
              <circle fill="#84D1C9" strokeWidth={0} cx={56.37} cy={45.308} r={1.605} />
              <circle fill="#84D1C9" strokeWidth={0} cx={23.632} cy={45.308} r={1.605} />
            </g>
            <path
              fill="#EE9D25"
              strokeWidth={0}
              d="M48.549,30.344c0.437,1.566,1.29,3.633,2.773,4.866c-4.365-1.117-8.082-3.697-10.771-6.753 c0.263,1.579,1.265,3.299,2.812,4.821c-1.175-0.52-2.317-1.194-3.409-1.964c-2.433-1.708-4.596-3.922-6.201-6.169 C31.21,30.3,26.484,36.727,23.293,36.823C18.328,27.69,21.96,8.361,39.934,8.361c17.974,0,21.726,18.686,17.224,28.999 C53.538,36.588,49.556,31.84,48.549,30.344z"
            />
          </g>
        </g>
      </g>
    </g>
  ),
  viewBox: '0 0 80 80'
};
