/* eslint-disable react/jsx-no-duplicate-props */
import React from 'react';
import { removeUnderline, StyledIconButton, StyledInputV2, Adornment } from './styled';
import { Color } from 'modules/styles/colors';
import { Svg } from 'components/UI/Svg';

export interface SearchBarProps {
  value?: string;
  autoFocus?: boolean;
  fullWidth?: boolean;
  placeholder?: string;
  inputProps?: string[];
  label?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClear?: () => void;
  onBlur?: () => void;
  onFocus?: () => void;
  onOpen?: () => void;
  inputRef?: any;
}

export const SearchBar = ({
  value,
  autoFocus,
  placeholder,
  fullWidth,
  inputProps,
  label,
  onBlur,
  onChange,
  onClear,
  onFocus,
  inputRef
}: SearchBarProps) => {
  const handleOnClear = () => {
    onClear?.();
  };

  const handleOnBlur = () => {
    onBlur?.();
  };

  return (
    <StyledInputV2
      id={label || ''}
      label={label || ''}
      value={value}
      inputRef={inputRef}
      fullWidth={fullWidth}
      autoFocus={autoFocus}
      placeholder={placeholder}
      onChange={onChange}
      onBlur={handleOnBlur}
      onFocus={onFocus}
      inputProps={{
        'data-testid': 'auto-complete-input'
      }}
      InputProps={{
        classes: removeUnderline(),
        startAdornment: (
          <Adornment position="start">
            <Svg set="material" name="search" color={Color.black} />
          </Adornment>
        ),
        endAdornment: (
          <Adornment position="end">
            <StyledIconButton
              customColor="black"
              onClick={handleOnClear}
              hidden={value === ''}
              data-testid="record-search-close-button"
            >
              <Svg set="material" name="clear" color="inherit" size={20} />
            </StyledIconButton>
          </Adornment>
        ),
        ...inputProps
      }}
    />
  );
};
