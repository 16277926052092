import React from 'react';
import Box from 'components/UI/Layout/Box';
import FlexBox from 'components/UI/Layout/FlexBox';
import Typography from 'components/UI/Typography';
import { Spacing, BorderRadius, FontSize, IconSize, FontWeight } from 'modules/styles/variables';
import { StyleProp, ViewStyle, ScrollView, FlatList } from 'react-native';
import { Color } from 'modules/styles/colors';
import WebMuiModal from 'components/UI/Modals/WebMuiModal';
import Svg from 'components/UI/Svg/Svg';
import Divider from 'components/UI/Divider';

export interface Props {
  isOpen: boolean;
  onClose?: () => void;
  handleClose: () => void;
  onClick?: () => void;
  style?: StyleProp<ViewStyle>;
}

const clinics = [
  {
    id: '1',
    label: 'North/South',
    location: 'Logan/Ogden/Layton/Utah County (including Park City/Heber)',
    phone: '801-525-5700'
  },
  {
    id: '2',
    label: 'Central/Southwest',
    location: 'Salt Lake/St. George/Cedar City',
    phone: '801-507-7840'
  },
  {
    id: '3',
    label: 'Rural South',
    location: 'Richfield/Sanpete Valley/Fillmore/Delta',
    phone: '435-893-0514'
  },
  { id: '4', label: 'Bear River', location: '', phone: '435-207-4545' },
  { id: '5', label: 'Cassia', location: '', phone: '208-677-6551' }
];

export const ClinicsModal = (props: Props) => {
  const { onClose, isOpen, style } = props;

  return (
    <WebMuiModal open={isOpen} handleClose={onClose}>
      <FlexBox
        height={350}
        width="100%"
        borderRadius={BorderRadius.base}
        spacing="20px"
        flexDirection="column"
        backgroundColor={Color.white}
        style={style}
      >
        <FlexBox width="100%" flexDirection="row" justifyContent="flex-end">
          <Svg.Button
            name="close"
            set="material"
            size={IconSize.base}
            onClick={() => onClose()}
            accessibilityLabel="clinics modal close"
            appearance="transparent"
            style={{
              paddingTop: 0,
              paddingBottom: 0,
              paddingRight: 0,
              paddingLeft: 0
            }}
          />
        </FlexBox>
        <ScrollView style={{ flex: 1 }}>
          <FlatList
            ItemSeparatorComponent={() => <Divider />}
            data={clinics}
            keyExtractor={({ id }) => id}
            renderItem={({ item }) => (
              <Box key={item.id} vSpacing={Spacing.large}>
                <Typography fontWeight={FontWeight.bold} fontSize={FontSize.large}>
                  {item.label}
                </Typography>
                {!!item.location && (
                  <Box hOffset={Spacing.medium} vSpacingTop={Spacing.medium}>
                    <Typography fontWeight={FontWeight.bold} fontSize={FontSize.small}>
                      Locations
                    </Typography>
                    <Typography fontSize={FontSize.small}>{item.location}</Typography>
                  </Box>
                )}

                <Box hOffset={Spacing.medium} vSpacingTop={Spacing.medium}>
                  <Typography fontWeight={FontWeight.bold} fontSize={FontSize.small}>
                    Phone
                  </Typography>
                  <Typography color={Color.link} fontSize={FontSize.small}>
                    {item.phone}
                  </Typography>
                </Box>
              </Box>
            )}
          />
        </ScrollView>
      </FlexBox>
    </WebMuiModal>
  );
};

export default ClinicsModal;
