import React, { useState, ChangeEvent } from 'react';
import { connect } from 'react-redux';

import SearchIcon from '@material-ui/icons/Search';
import { MuiAutoComplete, RenderInputParams, MuiInputBase, MuiBox } from 'theme/material-ui';

import { Alert } from 'components/Alert';
import { AutoCompleteBox, GroupBox, GroupHeader } from 'components/AutoComplete/styled';

import { RootState } from 'store/types';
import { State } from 'store/amwell/types';
import * as selectors from 'store/amwell/selectors';

import { geoCodeLocation } from 'services/LocationService/GoogleApis';
import { GeoCodeLocation } from 'services/LocationService/types';

export interface FilterOption {
  description: string;
  city?: string;
  state?: string;
  zip?: string;
  country?: string;
  lat?: number;
  lng?: number;
}
interface FilterValue {
  inputValue: null | string;
}
interface Params {
  onChange: (option: FilterOption) => void;
  usStates: State[];
}

export function filterOptions(options: FilterOption[], val: FilterValue): FilterOption[] {
  const { inputValue } = val;
  if (!inputValue) return options;

  return options.filter(option => {
    const str = JSON.stringify(option)
      .toLowerCase()
      .replace(/\W/g, '');

    return str.includes(inputValue.toLowerCase().replace(/\W/g, ''));
  });
}

export function mapNewOptions(obj: GeoCodeLocation): FilterOption {
  const city = obj.address_components.find(comp => comp.types.find(type => type === 'locality'));
  const state = obj.address_components.find(comp =>
    comp.types.find(type => type === 'administrative_area_level_1')
  );

  const zip = obj.address_components.find(comp => comp.types.find(type => type === 'postal_code'));
  const country = obj.address_components.find(comp => comp.types.find(type => type === 'country'));

  const { lat, lng } = obj.geometry.location;

  const description = `${city?.long_name ? `${city.long_name},` : ''}${
    state?.short_name ? ` ${state.short_name}` : ''
  } ${zip?.short_name || ''}`.trim();

  return {
    city: city?.long_name,
    lat,
    lng,
    state: state?.short_name,
    zip: zip?.short_name,
    country: country?.short_name,
    description
  };
}

/*
  Method: CityStateZipPickerComponent
  Description: The purpose of this is to return the City, State, and or Zip for the Connect Care Pharmacy Search. 
*/
export function CityStateZipPickerComponent(params: Params) {
  const { usStates, onChange } = params;

  const states = usStates /* eslint-disable-next-line */
    .map(obj => ({ ...obj.__data, description: obj.__data?.name, state: obj.__data?.code }))
    .filter(state => !!state.state);

  const [options, setOptions] = useState<FilterOption[]>(states);
  const [selected, setSelected] = useState<FilterOption | undefined>();

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { value } = event?.target || {};

    if (!value || value.trim().length <= 3) {
      setOptions(states);
      return;
    }

    geoCodeLocation(value, {
      components: 'country:US'
    }).then(res => {
      if (!res.length && !options.length) return setOptions(states);

      const newOptions = res.map(mapNewOptions).filter(obj => obj.country === 'US');

      if (newOptions.length) {
        return setOptions([...states, ...newOptions]);
      }

      return options;
    });
  };

  const searchOption = (val: FilterOption) => {
    const { lat, lng } = val;

    if (lat && lng) {
      onChange(val);
    }
  };

  const submit = (val: FilterOption) => {
    const { lat, lng } = val;

    if (lat && lng) {
      searchOption(val);
      return;
    }

    geoCodeLocation(val.description).then(res => {
      // This should not happen, if it is in the results it should be a geocoded location, or a US state. But its here because sometimes the unexpected happens
      if (!res.length) return Alert.alert('Invalid Location', 'Location could not be found');

      const option = mapNewOptions(res[0]);
      return searchOption(option);
    });
  };

  return (
    <MuiAutoComplete
      autoHighlight
      data-testid="city-state-zip-autocomplete"
      onChange={(event: ChangeEvent<{}>, val: FilterOption) => {
        if (val) {
          submit(val);
          setSelected(val);
        }
      }}
      filterOptions={filterOptions}
      options={options}
      getOptionLabel={option => option.description}
      renderGroup={params => {
        return (
          <GroupBox key={params.key}>
            <MuiBox
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              borderBottom="1px solid rgba(151,151,151,0.15)"
            >
              <GroupHeader>{params.key}</GroupHeader>
            </MuiBox>
            {params.children}
          </GroupBox>
        );
      }}
      renderInput={(params: RenderInputParams) => (
        <AutoCompleteBox display="flex" width="auto">
          <MuiInputBase
            style={{ flex: '1 1 auto' }}
            ref={params.InputProps.ref}
            inputProps={{ ...params.inputProps }}
            placeholder=" Please enter a City, State, or Zip Code"
            onChange={handleInputChange}
            autoComplete="off"
          />
          <SearchIcon
            onClick={() => {
              if (selected) {
                submit(selected);
              }
            }}
          />
        </AutoCompleteBox>
      )}
    />
  );
}

const mapStateToProps = (state: RootState) => ({
  usStates: selectors.usStatesSelector(state)
});

export default connect(mapStateToProps)(CityStateZipPickerComponent);
