import * as Yup from 'yup';

export const submitFeedbackValidationSchema = Yup.object().shape({
  feedback: Yup.string().required('Required'),
  name: Yup.string()
    .notRequired()
    .when('showContact', {
      is: (val: any) => val === true,
      then: Yup.string().required('Required')
    }),
  email: Yup.string()
    .notRequired()
    .when('showContact', {
      is: (val: any) => val === true,
      then: Yup.string()
        .required('Required')
        .email('Please enter a valid email')
    }),
  preferredPhone: Yup.string()
    .notRequired()
    .when('showContact', {
      is: (val: any) => val === true,
      then: Yup.string()
        .required('Required')
        .matches(/^\(\d{3}\) \d{3}-\d{4}$/, 'Phone must match format (###) ###-####')
    })
});
