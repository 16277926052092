import { useEffect, ReactNode } from 'react';
import { FormikConfig, useFormikContext, FormikContextType } from 'formik';
import { EventEmitter } from 'services/EventEmitter';

export type ConnectCareFormProps<T> = FormikConfig<T>;

const formBus = new EventEmitter();

export function ConnectCareFormEffect() {
  const { values, ...rest } = useFormikContext();

  useEffect(() => {
    const timer = setTimeout(() => formBus.emit('change', { values, ...rest }), 0);
    return () => {
      clearTimeout(timer);
    };
  }, [values]);

  return null;
}

export const useConnectCareFormEffect = (
  cb: (props: FormikContextType<any>) => void,
  dependency: ReactNode[] = []
) => {
  useEffect(() => {
    const unsubscribe = formBus.on('change', cb);
    return () => unsubscribe();
  }, dependency);
};
