import React from 'react';
import { FiltersStructure } from 'lib/hooks/ProviderSearch/types';
import { FilterDrawer } from 'components/FilterDrawer';
import { FormCheckboxList } from '../shared';

export type HasPhotoVideoFilterProps = FiltersStructure['hasPhotoVideo'] & {
  defaultExpanded: boolean;
};

function HasPhotoVideoFilter({ title, values, defaultExpanded }: HasPhotoVideoFilterProps) {
  return (
    <FilterDrawer.Panel title={title} defaultExpanded={defaultExpanded}>
      <FormCheckboxList list={Object.values(values)} />
    </FilterDrawer.Panel>
  );
}

export default HasPhotoVideoFilter;
