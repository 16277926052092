import React from 'react';
import FlexBox from 'components/UI/Layout/FlexBox';
import Typography from 'components/UI/Typography';
import { FontSize, IconSize, Spacing } from 'modules/styles/variables';
import { Svg } from 'components/UI/Svg';
import { Color } from 'modules/styles/colors';
import { MuiButton } from 'theme/material-ui';
import Box from 'components/UI/Layout/Box';

interface DashboardEmptyStateCardProps {
  iconName: string;
  iconBgColor?: string;
  title: string;
  subtitle: string | React.ReactNode;
  buttonText?: string;
  buttonOnPress?: () => void;
  iconSize?: number;
}

const DashboardEmptyStateCard = ({
  iconName,
  iconBgColor,
  iconSize = IconSize.xxLarge,
  title,
  subtitle,
  buttonText,
  buttonOnPress
}: DashboardEmptyStateCardProps) => {
  return (
    <FlexBox
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      spacing={Spacing.mediumMedium}
    >
      <FlexBox borderRadius="50%" backgroundColor={iconBgColor} spacing={Spacing.large}>
        <Svg
          name={iconName}
          set="assets"
          size={iconSize}
          color={Color.secondary}
          strokeWidth="1.7"
          stroke={Color.secondary}
          fill={Color.secondary}
        />
      </FlexBox>
      <Typography
        fontSize={FontSize.heading}
        color={Color.navy}
        textAlign="center"
        style={{ marginTop: 5, letterSpacing: 0.38 }}
      >
        {title}
      </Typography>
      <Typography
        color={Color.textDark}
        textAlign="center"
        lineHeight={16}
        style={{
          marginTop: Spacing.small,
          marginBottom: buttonOnPress ? Spacing.xLarge : Spacing.large,
          letterSpacing: 0.33,
          maxWidth: 300
        }}
      >
        {subtitle}
      </Typography>
      {buttonOnPress ? (
        <MuiButton
          data-testid="dashboard-empty-state-button"
          onClick={buttonOnPress}
          endIcon={<Svg color={Color.white} name="ChevronRight" size={IconSize.base} />}
          variant="contained"
          color="primary"
        >
          {buttonText}
        </MuiButton>
      ) : null}
      <Box vOffset={Spacing.xSmall} />
    </FlexBox>
  );
};

export default DashboardEmptyStateCard;
