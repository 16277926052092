import styled from 'styled-components';
import { MuiGrid } from 'theme/material-ui';
import { Color } from 'modules/styles/colors';

export const AlertWrapper = styled(MuiGrid)<{ isError?: boolean }>`
  border: ${({ isError }) => (isError ? 3 : 1)}px solid
    ${({ isError }) => (isError ? Color.red : Color.infoDark)};
  background-color: ${({ isError }) => (isError ? Color.redLight : Color.infoLight)};
  border-radius: 5px;
  margin: 0 !important;
  width: 100% !important;
`;

export const AlertIconWrapper = styled(MuiGrid)<{ isError?: boolean }>`
  background-color: ${({ isError }) => (isError ? 'transparent' : Color.infoDark)};
  align-self: stretch;
  color: white;
  line-height: 11px;
  svg {
    margin: 5px 2px;
  }
`;
