import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Svg from 'components/UI/Svg/Svg';
import Box from 'components/UI/Layout/Box';
import { dfdDefaultTheme, MuiBox, MuiDialog, MuiTypography, MuiDivider } from 'theme/material-ui';

import { Color } from 'modules/styles/colors';
import { IconSize, FontSize, FontWeight, Spacing } from 'modules/styles/variables';

import { CloseButton } from './styled';
import { BookingButton } from 'screens/Booking/components/BookingButton';

interface DownloadFileInfo {
  name: string;
  description: string;
}
interface Props {
  fullScreen?: boolean;
  handleClose: () => void;
  handleDownload: () => void;
  open: boolean;
  secondScreen?: boolean;
  downloadFileInfo: DownloadFileInfo[];
}

function AuditLogDownloadDailog({ handleClose, open, downloadFileInfo, handleDownload }: Props) {
  const fullScreen = useMediaQuery(dfdDefaultTheme.breakpoints.down('sm'));

  return (
    <MuiDialog fullScreen={fullScreen} maxWidth="sm" open={open} onClose={handleClose}>
      <MuiBox p={3} py={2} pl={8} pr={8}>
        <CloseButton onClick={handleClose}>
          <Svg color={Color.black} name="close" size={IconSize.large} set="material" />
        </CloseButton>
        <MuiBox display="flex" justifyContent="center" py={1} pt={2}>
          <Svg set="assets" size={IconSize.xLarge} color={Color.primary} name="ExclamationCircle" />
        </MuiBox>
        <MuiBox display="flex" justifyContent="center" py={2}>
          <Box vSpacing={Spacing.large}>
            <MuiTypography align="center" fontSize={FontSize.heading} fontWeight={600}>
              Are you sure you would like to download this file?
            </MuiTypography>
          </Box>
        </MuiBox>
        <MuiDivider />
        <MuiBox display="flex" justifyContent="center">
          <Box vSpacing={Spacing.small}>
            <MuiTypography
              fontSize={FontSize.large}
              fontWeight={FontWeight.semibold}
              color={Color.black}
            >
              This may take a minute to download...
            </MuiTypography>
          </Box>
        </MuiBox>
        {downloadFileInfo.map((item: DownloadFileInfo) => {
          return (
            <>
              <MuiBox display="flex" justifyContent="center">
                <Box vSpacing={Spacing.small}>
                  <MuiTypography
                    fontSize={FontSize.heading}
                    fontWeight={FontWeight.semibold}
                    color={Color.black}
                  >
                    {item.name}: {item.description}
                  </MuiTypography>
                </Box>
              </MuiBox>
            </>
          );
        })}

        <MuiBox display="flex" justifyContent="center" py={1}>
          <Box
            vSpacing={43}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width="80%"
          >
            <BookingButton
              fullWidth
              variant="contained"
              loading={false}
              disabled={false}
              onClick={handleDownload}
            >
              Download
            </BookingButton>

            <Box height={Spacing.medium} />
            <BookingButton
              fullWidth
              variant="text"
              loading={false}
              disabled={false}
              onClick={handleClose}
            >
              Cancel
            </BookingButton>
          </Box>
        </MuiBox>
      </MuiBox>
    </MuiDialog>
  );
}

export default AuditLogDownloadDailog;
