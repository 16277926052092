import CallToActionRow from 'components/common/CallToActionRow/CallToActionRow';
import HealthRecordBanner from 'components/common/HealthRecordBanner/HealthRecordBanner';
import DataLoader from 'components/UI/DataLoader/DataLoader';
import DocumentView from 'components/UI/DocumentView';
import { FlexBoxRow } from 'components/UI/Layout/FlexBox';
import Spinner from 'components/UI/Spinner/Spinner';
import dayjs from 'dayjs';
import { Nullable } from 'modules/types/common';
import React, { ComponentType, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { profileConsumerSelector } from 'store/profile/selectors';
import { Consumer } from 'store/profile/types';
import { currentAccountConsumerFullNameSelector } from 'store/account/selectors';
import { sendAuditLog } from 'store/audit/actions';
import {
  getClinicNotesDetail,
  parseClinicNotesDownloadAuditData,
  replyToClinicNotesDocument
} from 'store/clinicNotes/actions';
import {
  clinicNotesDetailDataSelector,
  clinicNotesDetailErrorSelector,
  clinicNotesDetailFetchingSelector
} from 'store/clinicNotes/selectors';
import { ClinicNotesDetailData, ClinicNotesListItem } from 'store/clinicNotes/types';
import { RootDispatch, RootState } from 'store/types';
import { BinaryDocument } from 'store/visitSummary/types';
import { MuiBox, MuiContainer, MuiTypography } from 'theme/material-ui';

export interface ClinicalNotesScreenProps {
  clinicNotesDetail: Nullable<ClinicNotesDetailData>;
  clinicNotesDetailIsFetching: boolean;
  clinicNotesDetailError: Nullable<Error>;
  consumer: Consumer;
  currentAccountName: string;
  dispatch: RootDispatch;
}

export function ClinicalNotesScreen({
  clinicNotesDetail,
  clinicNotesDetailIsFetching,
  clinicNotesDetailError,
  consumer,
  currentAccountName,
  dispatch
}: ClinicalNotesScreenProps) {
  const history = useHistory();
  const clinicNote: ClinicNotesListItem = history?.location?.state?.clinicNote;

  useEffect(() => {
    const { documentId, mimeType } = clinicNote?.links?.[0] || {};
    if (!documentId) {
      history.push('/u/health-record/');
    }
    if (documentId !== clinicNotesDetail?.id && clinicNote.mhpDetails) {
      dispatch(getClinicNotesDetail(clinicNote.mhpDetails, mimeType));
    }
  }, [clinicNote?.links?.[0]?.documentId]);

  return ClinicNotesDetailRender({
    clinicNote,
    clinicNotesDetail,
    currentAccountName,
    consumer,
    dispatch,
    error: clinicNotesDetailError,
    isLoading: clinicNotesDetailIsFetching,
    returnBackTo: history?.location?.pathname
  });
}

export function ClinicNotesDetailRender({
  clinicNote,
  clinicNotesDetail,
  consumer,
  currentAccountName,
  dispatch,
  error,
  isLoading,
  returnBackTo
}: {
  clinicNote: ClinicNotesListItem;
  clinicNotesDetail: Nullable<ClinicNotesDetailData>;
  consumer: Consumer;
  currentAccountName: string;
  dispatch: RootDispatch;
  error?: Error | null;
  isLoading: boolean;
  returnBackTo?: string;
}) {
  const { resourceType, id, contentType, content } = clinicNotesDetail || ({} as BinaryDocument);
  const clinicNotesDocument = {
    resourceType,
    id,
    contentType,
    content
  } as BinaryDocument;
  const description = clinicNote?.description || 'Document Detail';
  const altReturnBackTo = '/u/health-record/clinic-notes-and-documents';

  const handleMessage = () => {
    if (clinicNotesDetail) {
      dispatch(
        replyToClinicNotesDocument(
          clinicNotesDetail,
          false,
          returnBackTo || altReturnBackTo,
          undefined,
          { clinicNote: clinicNotesDetail }
        )
      );
    }
  };

  const handleShare = () => {
    if (clinicNotesDetail) {
      dispatch(
        replyToClinicNotesDocument(
          clinicNotesDetail,
          true,
          returnBackTo || altReturnBackTo,
          undefined,
          { clinicNote: clinicNotesDetail }
        )
      );
    }
  };

  const sendAuditDownloadInfo = () => {
    const logDescription = `Downloaded ${clinicNotesDetail?.noteType} (${dayjs(
      clinicNotesDetail?.createDate
    ).format('YYYY/MM/DD')})`;
    const auditData = parseClinicNotesDownloadAuditData(consumer, logDescription);
    dispatch(sendAuditLog(auditData));
  };

  return (
    <div aria-label="Clinical Notes">
      <DataLoader
        data={clinicNotesDocument}
        loading={isLoading}
        error={error}
        renderLoading={() => (
          <MuiBox p={5} display="flex" alignItems="center" justifyContent="center">
            <Spinner />
          </MuiBox>
        )}
        renderError={() => (
          <FlexBoxRow alignItems="center" justifyContent="center">
            <MuiTypography>There was an issue loading your document.</MuiTypography>
          </FlexBoxRow>
        )}
        renderNoData={() => (
          <FlexBoxRow alignItems="center" justifyContent="center">
            <MuiTypography>No clinic notes document on file.</MuiTypography>
          </FlexBoxRow>
        )}
        renderData={() => (
          <>
            <HealthRecordBanner
              title={clinicNotesDetail?.noteType || ''}
              subtitle={clinicNotesDetail?.description || 'Document Detail'}
              patient={currentAccountName}
              providers={clinicNotesDetail?.primaryProvider?.providerName}
              providerImageUrl={clinicNotesDetail?.primaryProvider?.providerImageUrl}
              location={clinicNotesDetail?.location?.locationName}
              isSelectHealth={clinicNotesDetail?.primaryProvider?.isSelectHealth}
              date={dayjs(clinicNotesDetail?.indexDate).format('MM/DD/YYYY')}
            />
            <MuiContainer maxWidth="lg">
              <MuiBox display="flex" justifyContent="flex-end">
                <CallToActionRow
                  onTransmitPress={handleShare}
                  onReplyPress={handleMessage}
                  hrefDownload={`data:application/pdf;base64,${encodeURI(
                    clinicNotesDocument?.content
                  )}`}
                  downloadTitle={`${clinicNote?.description}.pdf`}
                  sendAuditInfo={sendAuditDownloadInfo}
                />
              </MuiBox>
            </MuiContainer>
            <MuiBox height="calc(100vh - 300px)">
              <DocumentView
                content={clinicNotesDocument?.content}
                contentType={clinicNotesDocument?.contentType}
                documentName={description}
              />
            </MuiBox>
          </>
        )}
      />
    </div>
  );
}

const mapStateToProps = (state: RootState) => ({
  clinicNotesDetail: clinicNotesDetailDataSelector(state),
  clinicNotesDetailIsFetching: clinicNotesDetailFetchingSelector(state),
  clinicNotesDetailError: clinicNotesDetailErrorSelector(state),
  consumer: profileConsumerSelector(state),
  currentAccountName: currentAccountConsumerFullNameSelector(state)
});

export default connect(mapStateToProps)(ClinicalNotesScreen as ComponentType);
