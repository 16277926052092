export enum Display {
  CHART = 'chart',
  TABLE = 'table'
}

export enum HistoricalFilters {
  CURRENT = 'Current',
  LAST = 'Last',
  ALL = 'All'
}
