/* eslint-disable react/jsx-no-duplicate-props */
import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { useFormikContext, FormikValues } from 'formik';

import { ConnectCareDataLoader } from 'components/ConnectCare';
import RequiredFieldsLegend from 'components/RequiredFieldsLegend';
import { MuiBox, MuiGrid, MuiInputLabel, MuiTypography } from 'theme/material-ui';

import { Color } from 'modules/styles/colors';
import { FontSize, FontWeight } from 'modules/styles/variables';

import { Vitals } from 'store/amwell/types';
import { onEKeyPress } from 'utils/index';

import { VitalsTextField, Divider } from './styled';
import {
  initialNonRequiredValues,
  initialRequiredValues,
  temperatureNonRequiredValue
} from './constants';

export interface Props {
  vitals: Vitals | null;
  loading: boolean;
  error: Error | null;
  errorComponentProps: any;
}

export function ConnectCareVitalsForm({ vitals, loading, error, errorComponentProps }: Props) {
  const { handleChange, handleBlur, errors, values, touched } = useFormikContext<FormikValues>();

  const renderErrors = (touched, errors, values) =>
    !isEmpty(touched)
      ? Object.keys(errors).map(key =>
          touched[key] && errors[key] && key in values ? (
            <MuiTypography key={key} color={Color.red}>
              {errors[key]}
            </MuiTypography>
          ) : null
        )
      : null;

  return (
    <MuiGrid container direction="column">
      <MuiBox pb={2}>
        <MuiTypography variant="h5">Do you know your current vitals?</MuiTypography>
      </MuiBox>
      <MuiBox pb={4}>
        <MuiTypography color={Color.textLight} gutterBottom>
          Provide information to the best of your knowledge. Exact values are not required.
        </MuiTypography>
        <MuiBox>
          <RequiredFieldsLegend />
        </MuiBox>
      </MuiBox>
      <ConnectCareDataLoader
        data={vitals}
        loading={loading}
        error={error}
        errorComponentProps={errorComponentProps}
        noDataComponentProps={errorComponentProps}
        renderData={data => (
          <>
            <MuiBox pb={4}>
              <MuiGrid container direction="row" item spacing={2} alignItems="flex-end">
                <MuiGrid item xs={6} sm={6} lg={2}>
                  <MuiBox pb={1}>
                    <MuiInputLabel required>Weight</MuiInputLabel>
                  </MuiBox>
                  <VitalsTextField
                    type="number"
                    name={data?.weightMajor?.toString()}
                    error={touched.weightMajor && !!errors.weightMajor}
                    value={values?.weightMajor}
                    onBlur={handleBlur('weightMajor')}
                    onChange={handleChange('weightMajor')}
                    InputProps={{
                      endAdornment: 'lb',
                      onKeyDown: onEKeyPress
                    }}
                    inputProps={{
                      'data-testid': 'weight-lb'
                    }}
                  />
                </MuiGrid>
                <MuiGrid item xs={6} sm={6} lg={2}>
                  <VitalsTextField
                    type="number"
                    name={data?.weightMinor?.toString()}
                    error={touched.weightMinor && !!errors.weightMinor}
                    value={values?.weightMinor}
                    defaultValue={values?.weightMinor}
                    onBlur={handleBlur('weightMinor')}
                    onChange={handleChange('weightMinor')}
                    InputProps={{
                      endAdornment: 'oz',
                      onKeyDown: onEKeyPress
                    }}
                    inputProps={{
                      'data-testid': 'weight-oz'
                    }}
                  />
                </MuiGrid>
                <MuiGrid item xs={6} sm={6} lg={2}>
                  <MuiBox pb={1}>
                    <MuiInputLabel required>Height</MuiInputLabel>
                  </MuiBox>
                  <VitalsTextField
                    type="number"
                    data-testid="Height"
                    name={data?.heightMajor?.toString()}
                    error={touched.heightMajor && !!errors.heightMajor}
                    value={values?.heightMajor}
                    onBlur={handleBlur('heightMajor')}
                    onChange={handleChange('heightMajor')}
                    InputProps={{
                      endAdornment: 'ft',
                      onKeyDown: onEKeyPress
                    }}
                    inputProps={{
                      'data-testid': 'height-ft'
                    }}
                  />
                </MuiGrid>
                <MuiGrid item xs={6} sm={6} lg={2}>
                  <VitalsTextField
                    type="number"
                    name={data?.heightMinor?.toString()}
                    error={touched.heightMinor && !!errors.heightMinor}
                    value={values?.heightMinor}
                    onBlur={handleBlur('heightMinor')}
                    onChange={handleChange('heightMinor')}
                    InputProps={{
                      endAdornment: 'in',
                      onKeyDown: onEKeyPress
                    }}
                    inputProps={{
                      'data-testid': 'height-in'
                    }}
                  />
                </MuiGrid>
                <MuiGrid item xs={6} sm={6} lg={3}>
                  <MuiBox
                    pb={1}
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="flex-end"
                  >
                    <MuiBox>
                      <MuiInputLabel>Temperature</MuiInputLabel>
                    </MuiBox>
                    <MuiBox>
                      <MuiTypography color={Color.textLight} fontSize={FontSize.small}>
                        Optional
                      </MuiTypography>
                    </MuiBox>
                  </MuiBox>
                  <VitalsTextField
                    type="number"
                    name={data?.temperature?.toString()}
                    error={touched.temperature && !!errors.temperature}
                    value={values?.temperature}
                    onBlur={handleBlur('temperature')}
                    onChange={handleChange('temperature')}
                    InputProps={{
                      endAdornment: String.fromCharCode(176).concat('F'),
                      onKeyDown: onEKeyPress
                    }}
                  />
                </MuiGrid>
              </MuiGrid>
              <MuiBox pt={4}>
                {renderErrors(touched, errors, {
                  ...initialRequiredValues,
                  ...temperatureNonRequiredValue
                })}
              </MuiBox>
            </MuiBox>
            <MuiBox>
              <MuiBox pb={2} display="flex" flexDirection="row" alignItems="center">
                <MuiBox mr={1}>
                  <MuiTypography fontSize={FontSize.large} fontWeight={FontWeight.semibold}>
                    Blood Pressure
                  </MuiTypography>
                </MuiBox>
                <MuiBox ml={1}>
                  <MuiTypography color={Color.textLight} fontSize={FontSize.small}>
                    Optional
                  </MuiTypography>
                </MuiBox>
              </MuiBox>
              <MuiGrid container direction="row" item spacing={1} alignItems="flex-end">
                <MuiGrid item xs={6} sm={6} lg={2}>
                  <MuiBox pb={1}>
                    <MuiInputLabel>Systolic</MuiInputLabel>
                  </MuiBox>
                  <VitalsTextField
                    type="number"
                    name={data?.systolic?.toString()}
                    error={touched.systolic && !!errors.systolic}
                    value={values?.systolic}
                    onBlur={handleBlur('systolic')}
                    onChange={handleChange('systolic')}
                    InputProps={{
                      endAdornment: 'mmHg',
                      onKeyDown: onEKeyPress
                    }}
                  />
                </MuiGrid>
                <MuiGrid item xs={1} sm={1} lg={1}>
                  <Divider orientation="vertical" variant="middle" />
                </MuiGrid>
                <MuiGrid item xs={6} sm={6} lg={2}>
                  <MuiBox pb={1}>
                    <MuiInputLabel>Diastolic</MuiInputLabel>
                  </MuiBox>
                  <VitalsTextField
                    type="number"
                    name={data?.diastolic?.toString()}
                    error={touched.diastolic && !!errors.diastolic}
                    value={values?.diastolic}
                    onBlur={handleBlur('diastolic')}
                    onChange={handleChange('diastolic')}
                    InputProps={{
                      endAdornment: 'mmHg',
                      onKeyDown: onEKeyPress
                    }}
                  />
                </MuiGrid>
              </MuiGrid>
            </MuiBox>
            <MuiBox pt={4}>{renderErrors(touched, errors, initialNonRequiredValues)}</MuiBox>
          </>
        )}
      />
    </MuiGrid>
  );
}

export default ConnectCareVitalsForm;
