import React from 'react';
import { MuiBox, MuiTypography } from 'theme/material-ui';
import { Color } from 'modules/styles/colors';

interface RequiredLabelProps {
  labelText: string;
  hideIndicator: boolean;
}

const RequiredLabel = ({ labelText, hideIndicator }: RequiredLabelProps) => {
  return (
    <MuiBox display="flex" flexDirection="row" pb={1} pt={1}>
      <MuiTypography>{labelText}</MuiTypography>
      {!hideIndicator ? (
        <MuiBox px={1}>
          <MuiTypography color={Color.red}>*</MuiTypography>
        </MuiBox>
      ) : null}
    </MuiBox>
  );
};

RequiredLabel.defaultProps = {
  hideIndicator: false
};

export default RequiredLabel;
