import React, { useEffect, useState } from 'react';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import {
  MuiBox,
  MuiContainer,
  MuiGrid,
  MuiTypography,
  MuiCard,
  MuiCardContent,
  MuiCardActionArea,
  useTheme,
  MuiIcon,
  MuiFab,
  MuiTooltip,
  withStyles
} from 'theme/material-ui';
import { Dependent, Consumer } from 'store/amwell/types';
import * as selectors from 'store/amwell/selectors';
import { updateConsumer, getDependents } from 'store/amwell/actions';
import { RootState } from 'store/types';
import {
  ConnectCareDataLoader,
  ConnectCareBullet,
  ConnectCareStepActions,
  ConnectCareStepAction
} from 'components/ConnectCare';
import { useSnack } from 'components/Snack';
import { RouteData } from '../types';
import { findSelectionById, getSelectionId } from '../utils';
import { stepActionsHeight, FormScreen } from '../styled';
import ConnectCarePatientSelectAddDependentDialog from './ConnectCarePatientSelectAddDependentDialog';
import { patientSelectConfirmations } from 'modules/constants/amwell';
import { FontSize } from 'modules/styles/variables';

export interface Props extends RouteComponentProps {
  consumer: Consumer | null;
  dependents: Dependent[];
  dependentsLoading: boolean;
  dependentsError: Error | null;
  activeConsumer: Consumer;
  currentUrl?: string;
  referringUrl?: string;
  fetchDependents: typeof getDependents;
  updateActiveConsumer: typeof updateConsumer;
}

export function ConnectCarePatientSelect(props: Props) {
  const {
    consumer,
    dependents,
    dependentsLoading,
    dependentsError,
    activeConsumer,
    history,
    fetchDependents,
    updateActiveConsumer
  } = props;
  const theme = useTheme();
  const { create } = useSnack();

  const patients: Consumer[] = consumer ? [consumer, ...dependents] : [];

  const patientInit = findSelectionById(patients, activeConsumer);
  const [patientSelection, setPatientSelection] = useState<Consumer | null>(patientInit);
  const [showAddDependent, setShowAddDependent] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchDependents();
  }, []);

  useEffect(() => {
    if (!activeConsumer && consumer) {
      setPatientSelection(consumer);
    }
  }, [activeConsumer]);

  const onPrevClick = (prevStep?: RouteData) => {
    if (prevStep) {
      history.push(prevStep.path);
    }
  };

  const onNextClick = (nextStep?: RouteData) => {
    setLoading(true);
    updateActiveConsumer({ consumer: patientSelection }).then((res: AnyAction) => {
      setLoading(false);
      if (res.error) {
        create(
          patientSelectConfirmations.UPDATE.PATIENT_SELECT_ERROR.subtitle,
          patientSelectConfirmations.UPDATE.PATIENT_SELECT_ERROR.severity
        );
      } else if (nextStep) {
        history.push(nextStep.path);
      }
    });
  };
  const MuiTooltipMedium = withStyles({
    tooltip: {
      fontSize: FontSize.base
    }
  })(MuiTooltip);

  const patientId = getSelectionId(patientSelection);

  const errorComponentProps = {
    message: patientSelectConfirmations.GET.DEPENDENTS_ERROR.subtitle,
    action: { onClick: fetchDependents, label: 'Try Again?' }
  };

  return (
    <>
      <FormScreen>
        <MuiContainer maxWidth="lg">
          <MuiBox my={3}>
            <MuiBox py={2}>
              <MuiTypography variant="h5">Patients List</MuiTypography>
            </MuiBox>
            <ConnectCareDataLoader
              data={patients}
              loading={dependentsLoading}
              error={dependentsError}
              errorComponentProps={errorComponentProps}
              noDataComponentProps={errorComponentProps}
              renderData={data => (
                <MuiGrid container spacing={2}>
                  {data.map((p, idx) => {
                    const id = getSelectionId(p);
                    const selected = id === patientId;

                    return (
                      <MuiGrid item xs={12} md={6} lg={4} key={id}>
                        <MuiCard raised={selected}>
                          <MuiCardActionArea
                            data-testid={`${id}-patient-card`}
                            disabled={selected}
                            onClick={() => setPatientSelection(p)}
                          >
                            <MuiCardContent>
                              <MuiTypography gutterBottom color="textSecondary">
                                {idx === 0 ? 'Primary' : 'Dependent'}
                              </MuiTypography>
                              <MuiTypography variant="h6">{p.fullName}</MuiTypography>
                              <MuiTypography color="textSecondary">
                                {p.age} {p.age.includes('Month') ? `Old` : `Years Old`}
                                {p.localizedGenderDisplayName && (
                                  <>
                                    <ConnectCareBullet />
                                    {p.localizedGenderDisplayName}
                                  </>
                                )}
                              </MuiTypography>
                            </MuiCardContent>
                          </MuiCardActionArea>
                        </MuiCard>
                      </MuiGrid>
                    );
                  })}
                </MuiGrid>
              )}
            />
          </MuiBox>
        </MuiContainer>
      </FormScreen>
      <ConnectCareStepActions>
        <ConnectCareStepAction onClick={onPrevClick} />
        <ConnectCareStepAction
          disabled={!patientSelection}
          loading={loading}
          onClick={onNextClick}
        />
      </ConnectCareStepActions>
      <MuiBox
        position="absolute"
        bottom={theme.spacing(3) + stepActionsHeight}
        right={theme.spacing(3)}
        maxWidth={theme.breakpoints.values.lg}
      >
        <MuiTooltipMedium
          data-testid="add-dependent"
          aria-label="add-dependent"
          title="Add Dependent"
        >
          <MuiFab color="primary" onClick={() => setShowAddDependent(true)}>
            <MuiIcon>add</MuiIcon>
          </MuiFab>
        </MuiTooltipMedium>
      </MuiBox>
      <ConnectCarePatientSelectAddDependentDialog
        open={showAddDependent}
        onClose={() => setShowAddDependent(false)}
      />
    </>
  );
}

const mapStateToProps = (state: RootState) => ({
  dependents: selectors.dependentsDataSelector(state),
  dependentsLoading: selectors.dependentsDataLoadingSelector(state),
  dependentsError: selectors.dependentsDataErrorSelector(state),
  consumer: selectors.consumerDataSelector(state),
  activeConsumer: selectors.activeConsumerDataSelector(state)
});

const mapDispatch = {
  fetchDependents: getDependents,
  updateActiveConsumer: updateConsumer
};

export default connect(mapStateToProps, mapDispatch)(ConnectCarePatientSelect);
