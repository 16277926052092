import React from 'react';
import { SearchBar } from 'components/AutoComplete';

interface Props {
  value: string;
  onSearch: (value: string) => void;
}

const MessagesSearchBar = (props: Props) => {
  return (
    <SearchBar
      data-testid="search-input"
      autoFocus
      fullWidth
      value={props.value}
      border
      placeholder="Enter text to search your messages"
      onClear={() => props.onSearch('')}
      onChange={e => props.onSearch(e.target.value)}
      label="Search"
    />
  );
};

export default MessagesSearchBar;
