import Box from '@material-ui/core/Box';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { handleUrlOnCancel } from 'store/pageActions/RegisterAccount';
import { useDispatch } from 'react-redux';
import { MuiButton } from 'theme/material-ui';
import useStyles from '../useStyles';
import { getRegisterBrand } from 'store/register';

export const CancelButton = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const redirectBrand = getRegisterBrand();
  const classes = useStyles({ redirectBrand });

  return (
    <Box mt={1} textAlign="center" maxWidth="xs">
      <MuiButton
        className={classes.customTextButton}
        data-testid="register-cancel-button"
        color="primary"
        size="large"
        fullWidth
        aria-label="cancel-button"
        onClick={() => dispatch(handleUrlOnCancel(history))}
      >
        Cancel
      </MuiButton>
    </Box>
  );
};
