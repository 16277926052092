import { Alert } from 'components/Alert';
import { GooglePlacesInput } from 'components/profile/AddressValidation/AddressValidation';
import Box from 'components/UI/Layout/Box';
import { FlexBoxColumn } from 'components/UI/Layout/FlexBox';
import SpinnerModal from 'components/UI/Spinner/SpinnerModal';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import analyticsService, { AmplitudeEventData, AnalyticsEvent } from 'services/AnalyticsService';
import Logger, { LoggingModule } from 'services/Logger';
import { Color } from 'modules/styles/colors';
import { FontSize, Spacing } from 'modules/styles/variables';
import { AddressComponent, GeoCodeLocation } from 'services/LocationService/types';
import { updateFAFormProfile } from 'store/billing/financialAssistance/createApplication/actions';
import { isEditingFAProfileSelector } from 'store/billing/financialAssistance/createApplication/selectors';
import { getProfile, updateConsumerProfile, validateConsumerAddress } from 'store/profile/actions';
import { ProfileUpdateMessages } from 'store/profile/constants';
import * as profileSelectors from 'store/profile/selectors';
import { Consumer } from 'store/profile/types';
import { RootDispatch, RootState } from 'store/types';
import {
  MuiBox,
  MuiButton,
  MuiContainer,
  MuiPaper,
  MuiTextField,
  MuiTypography
} from 'theme/material-ui';
import { oc } from 'ts-optchain';
import { getUpdatedAddressConsumer } from './utils';
import { UPDATE_DISCLAIMER } from './variables';

export interface Props extends RouteComponentProps {
  consumer: Consumer;
  fetchProfile: () => void;
  updateConsumerAddress: (update: Consumer) => Promise<any>;
  validateAddressUpdate: (update: Consumer) => Promise<any>;
  isEditingFAProfile: boolean;
  updateFAProfile: typeof updateFAFormProfile;
}

export interface AddressValues {
  streetAddress: string;
  streetAddress2: string;
  city: string;
  state: string;
  country: string;
  postalCode: string;
}

interface FieldValue {
  name: string;
  value: string;
}

function addressFromComponents(addressComponents: AddressComponent[]) {
  const streetAddress = {
    number: '',
    street: ''
  };

  const newAC: FieldValue[] = [];

  for (const ac of addressComponents) {
    switch (ac.types[0]) {
      case 'street_number':
        streetAddress.number = ac.long_name;
        break;
      case 'route':
        streetAddress.street = ac.long_name;
        break;
      case 'locality':
        newAC.push({ name: 'city', value: ac.long_name });
        break;
      case 'administrative_area_level_1':
        newAC.push({ name: 'state', value: ac.short_name });
        break;
      case 'country':
        newAC.push({ name: 'country', value: ac.long_name });
        break;
      case 'postal_code':
        newAC.push({ name: 'postalCode', value: ac.long_name });
        break;
      default:
    }
  }

  newAC.push({ name: 'streetAddress', value: `${streetAddress.number} ${streetAddress.street}` });

  return newAC;
}

export function EditAddressScreen({
  consumer,
  fetchProfile,
  updateConsumerAddress,
  validateAddressUpdate,
  isEditingFAProfile,
  updateFAProfile,
  history
}: Props) {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const initialValues: AddressValues = {
    streetAddress: oc(consumer).addresses[0].streetAddress(''),
    streetAddress2: oc(consumer).addresses[0].streetAddress2(''),
    state: oc(consumer).addresses[0].state(''),
    city: oc(consumer).addresses[0].city(''),
    country: oc(consumer).addresses[0].country(''),
    postalCode: oc(consumer).addresses[0].postalCode('')
  };

  const data: AmplitudeEventData = {
    currentUrl: 'Edit Address',
    referringUrl: 'Profile',
    type: 'address'
  };

  const handleCancel = () => {
    history.goBack();
  };

  const handleValidate = (values: AddressValues) => {
    return values.streetAddress.length === 0
      ? { streetAddress: 'Please enter a valid address' }
      : {};
  };

  const updateAddress = (details: GeoCodeLocation) => {
    return addressFromComponents(details.address_components);
  };

  const handleSubmitValues = async (values: AddressValues) => {
    const updatedConsumer = getUpdatedAddressConsumer(
      { ...consumer },
      {
        primary: true,
        streetAddress: values.streetAddress,
        streetAddress2: values.streetAddress2,
        city: values.city,
        state: values.state,
        country: values.country,
        postalCode: values.postalCode,
        type: 'HOME'
      }
    );

    analyticsService.logEvent(AnalyticsEvent.MyAccountEditOptionsEdited, data);

    const validateRes = await validateAddressUpdate(updatedConsumer);

    if (validateRes.error) {
      Logger.error(
        `${LoggingModule.profile}Address verification failed`,
        validateRes.error.message
      );

      return Alert.alert(
        ProfileUpdateMessages.VALIDATE_ADDRESS.title,
        ProfileUpdateMessages.VALIDATE_ADDRESS.message
      );
    }

    const res = await updateConsumerAddress(updatedConsumer);
    if (res.error) {
      Logger.error(`${LoggingModule.profile}Failed to update address`, res.error.message);

      return Alert.alert(
        ProfileUpdateMessages.UPDATE_ADDRESS.title,
        ProfileUpdateMessages.UPDATE_ADDRESS.message
      );
    }

    if (isEditingFAProfile) {
      updateFAProfile(values);
    }

    analyticsService.logEvent(AnalyticsEvent.MyAccountEditOptionsCompleted, data);

    return Alert.alert('Address Updated', UPDATE_DISCLAIMER, [
      {
        text: 'OK',
        onPress: () => {
          fetchProfile();
          history.push('/u/manage-account');
        }
      }
    ]);
  };

  const onSubmit = async (values: AddressValues) => {
    setIsSubmitting(true);
    await handleSubmitValues(values);
    setIsSubmitting(false);
  };

  return (
    <MuiBox mt={6}>
      <SpinnerModal isLoading={isSubmitting} />
      <MuiContainer maxWidth="sm">
        <Formik initialValues={initialValues} validate={handleValidate} onSubmit={onSubmit}>
          {({ values, handleChange, setFieldValue, isValid, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <MuiBox p={3} component={MuiPaper}>
                <MuiBox mb={2}>
                  <MuiTypography
                    fontWeight={600}
                    fontSize={FontSize.large}
                    color={Color.textLight}
                    gutterBottom
                  >
                    Edit Address
                  </MuiTypography>
                </MuiBox>

                <MuiBox mb={2}>
                  <GooglePlacesInput
                    handleOnPress={(geoCode: GeoCodeLocation) => {
                      const addressFieldValues = updateAddress(geoCode);

                      addressFieldValues.forEach(ac => {
                        setFieldValue(ac.name, ac.value);
                      });
                      setFieldValue('streetAddress2', '');
                    }}
                  />

                  <MuiTextField
                    fullWidth
                    name="streetAddress"
                    label="Address Line 1"
                    value={values.streetAddress}
                    onChange={handleChange('streetAddress')}
                    inputProps={{ 'data-testid': 'street-address' }}
                  />

                  <Box vSpacingBottom={10} />

                  <MuiTextField
                    fullWidth
                    name="streetAddress2"
                    label="Address Line 2"
                    value={values.streetAddress2}
                    onChange={handleChange('streetAddress2')}
                  />

                  <Box vSpacingBottom={10} />

                  <MuiTextField
                    fullWidth
                    name="city"
                    label="City"
                    value={values.city}
                    onChange={handleChange('city')}
                  />

                  <Box vSpacingBottom={10} />

                  <MuiTextField
                    fullWidth
                    name="state"
                    label="State"
                    value={values.state}
                    onChange={handleChange('state')}
                  />

                  <Box vSpacingBottom={10} />

                  <MuiTextField
                    fullWidth
                    label="Postal Code"
                    value={values.postalCode}
                    onChange={handleChange('postalCode')}
                  />
                </MuiBox>

                <FlexBoxColumn
                  center
                  style={{
                    gap: Spacing.medium
                  }}
                >
                  <MuiButton
                    fullWidth
                    variant="outlined"
                    disabled={!isValid}
                    onClick={handleCancel}
                  >
                    Cancel
                  </MuiButton>
                  <MuiButton
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={!isValid || isSubmitting}
                    type="submit"
                    data-testid="submit-button"
                  >
                    Submit
                  </MuiButton>
                </FlexBoxColumn>
              </MuiBox>
            </form>
          )}
        </Formik>
      </MuiContainer>
    </MuiBox>
  );
}

const mapDispatch = (dispatch: RootDispatch) => ({
  validateAddressUpdate: update => dispatch(validateConsumerAddress(update)),
  updateConsumerAddress: update => dispatch(updateConsumerProfile(update, ['Address'])),
  fetchProfile: () => dispatch(getProfile('', true)),
  updateFAProfile: update => dispatch(updateFAFormProfile(update))
});

const mapState = (state: RootState) => ({
  addresses: profileSelectors.profileAddressesSelector(state),
  consumer: profileSelectors.profileConsumerSelector(state),
  isEditingFAProfile: isEditingFAProfileSelector(state)
});

export default connect(mapState, mapDispatch)(EditAddressScreen);
