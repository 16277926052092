import { TRIAGE_ROUTES } from 'screens/TriageGyant/constants';
import TriageTerms from './TriageTermsScreen';
import TriageProxy from './TriageProxySelectScreen';
import GyantWebView from './TriageGyantWebView';
import ResultsScreen from './ResultsScreens/Results/TriageResultsScreen';
import GyantLikelyCausesScreen from './ResultsScreens/LikelyCausesList/LikelyCausesListScreen';
import GyantLikelyCauseDetailScreen from './ResultsScreens/LikelyCausesDetail/LikelyCauseDetailScreen';
import { RouteData } from '../navigation';

const gyantTriageNavigation: RouteData[] = [
  {
    exact: true,
    path: TRIAGE_ROUTES.TERMS,
    component: TriageTerms,
    componentName: 'TriageTerms',
    title: 'Terms & Conditions'
  },
  {
    path: TRIAGE_ROUTES.PROXY_SELECT,
    component: TriageProxy,
    componentName: 'TriageProxy',
    title: 'Select A User'
  },
  {
    path: TRIAGE_ROUTES.SYMPTOM_CHECKER,
    component: GyantWebView,
    componentName: 'GyantWebView',
    title: 'Scout - Symptom Checker'
  },
  {
    path: TRIAGE_ROUTES.RESULTS.PARAM_URL,
    component: ResultsScreen,
    componentName: 'ResultsScreen',
    title: 'Results'
  },
  {
    path: TRIAGE_ROUTES.POSSIBLE_CAUSES.PARAM_URL,
    component: GyantLikelyCausesScreen,
    componentName: 'GyantLikelyCausesScreen',
    title: 'Likely Causes'
  },
  {
    path: TRIAGE_ROUTES.CAUSE_DETAIL.PARAM_URL,
    component: GyantLikelyCauseDetailScreen,
    componentName: 'GyantLikelyCauseDetailScreen',
    title: 'Likely Cause Detail'
  }
];

export default gyantTriageNavigation;
