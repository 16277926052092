import styled from 'styled-components';
import { sideBarWidthOpen } from 'components/AuthLayout/styled';
import { MuiList } from 'theme/material-ui';

export const FullWidthList = styled(MuiList)`
  &&& {
    padding: 0;
    position: relative;
    width: ${sideBarWidthOpen}px;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1 0 0%;
    transition: width 250ms linear, height 250ms linear;
  }
`;
