import React from 'react';
import { useField, FieldHookConfig } from 'formik';
import { ConnectCareRadioGroup, ConnectCareRadioGroupProps } from '../ConnectCareRadioGroup';

export type ConnectCareFormRadioGroupProps<T> = FieldHookConfig<any> & {
  label?: ConnectCareRadioGroupProps<T>['label'];
  items: ConnectCareRadioGroupProps<T>['items'];
  itemToValue: ConnectCareRadioGroupProps<T>['itemToValue'];
  itemToLabel: ConnectCareRadioGroupProps<T>['itemToLabel'];
  itemToRender: ConnectCareRadioGroupProps<T>['itemToRender'];
  row?: ConnectCareRadioGroupProps<T>['row'];
};

export function ConnectCareFormRadioGroup<T>(props: ConnectCareFormRadioGroupProps<T>) {
  const { label, items, itemToValue, itemToLabel, itemToRender, row, ...rest } = props;
  const [field, meta] = useField(rest);

  const error = meta.touched && !!meta.error;
  const helperText = error && meta.error;
  const value = items.find(item => itemToValue(item) === field.value) || field.value;

  return (
    <ConnectCareRadioGroup
      {...field}
      row={row}
      label={label}
      items={items}
      value={value}
      error={error}
      helperText={helperText || ' '}
      itemToValue={itemToValue}
      itemToLabel={itemToLabel}
      itemToRender={itemToRender}
    />
  );
}

ConnectCareFormRadioGroup.defaultProps = {
  itemToValue: (item: any) => (item ? item.value : ''),
  itemToLabel: (item: any) => (item ? item.label : ''),
  itemToRender: (item: any) => (item ? item.render : null)
};
