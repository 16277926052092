import React from 'react';
import dayjs from 'dayjs';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { FlatList } from 'react-native';
import { useHistory } from 'react-router-dom';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { KeyboardDatePicker } from '@material-ui/pickers';

import { MuiBox, MuiTypography } from 'theme/material-ui';
import { CovidTitles } from 'lib/constants/covidScreening';
import { CovidSymptomsEnums, COVID_SYMPTOMS } from 'modules/constants/covidScreening/symptomInfo';
import { FontWeight, Spacing } from 'modules/styles/variables';
import { convertToLowerKabobCase } from 'modules/utils/StringUtils';

import { AnalyticsEvent } from 'services/AnalyticsService';
import { AuthType } from 'store/authentication/types';

import { StyledScreen } from 'components/common/PatientLoader/styled';
import { StyledAsterisk } from 'components/RequiredFieldsLegend';
import Box from 'components/UI/Layout/Box';
import Spacer from 'components/UI/Layout/Spacer';
import useNavigationAnalytics from 'hooks/useNavigationAnalytics';

import { ButtonsNavigation, CovidTestGuestFooter } from '../components';
import { useCovidQuestionnaire } from '../hooks';
import CovidGuestGuard from '../navigation/CovidGuestLeavingGuard';
import { useLanguageSwitcher } from 'lib/hooks/useLanguageSwitcher';
import { translations } from 'lib/constants/translations/screens/covidGuest/symptoms';
import FieldError from 'screens/Register/FormElements/FieldError';

const Asterisk = () => (
  <MuiTypography display="inline">
    <StyledAsterisk />
  </MuiTypography>
);

export default function CovidCurrentSymptomsScreen() {
  const history = useHistory();
  const screenText = useLanguageSwitcher(translations);
  const { questionnaire, updateQuestionnaire } = useCovidQuestionnaire();
  const covidTestCurrentSymptomsPrev = useNavigationAnalytics(AnalyticsEvent.CovidPrev);
  const covidTestCurrentSymptomsNxt = useNavigationAnalytics(AnalyticsEvent.CovidNext);

  const covidSymptomsValidationSchema = Yup.object().shape({
    covidSymptomsChecklist: Yup.array().required(),
    symptomsBeganDate: Yup.string()
      .nullable(true)
      .test('invalid-dob', screenText.error_1, function(value: string) {
        return dayjs(value).isValid();
      })
      .test('dob-after-max-date', screenText.error_2, function(value: string) {
        return dayjs().isAfter(value);
      })
      .required(screenText.error_2)
  });

  const getFieldValue = () => {
    const reduxFieldValues = {
      Q_Fever: questionnaire?.Q_Fever,
      Q_New_or_Worsening_Cough: questionnaire?.Q_New_or_Worsening_Cough,
      Q_Shortness_of_Breath_New_or_Worsening: questionnaire?.Q_Shortness_of_Breath_New_or_Worsening,
      Q_Body_Aches: questionnaire?.Q_Body_Aches,
      Q_COVID_Screen_Sore_Throat: questionnaire?.Q_COVID_Screen_Sore_Throat,
      Q_COVID_Screen_Runny_Nose: questionnaire?.Q_COVID_Screen_Runny_Nose,
      Q_COVID_Screen_Decreased_Smell_or_Taste:
        questionnaire?.Q_COVID_Screen_Decreased_Smell_or_Taste,
      Q_Diarrhea: questionnaire?.Q_Diarrhea,
      Q_None_of_the_above: questionnaire?.Q_None_of_the_above,
      Q_Outbreak_Date_of_Symptoms_Onset: dayjs(
        questionnaire?.Q_Outbreak_Date_of_Symptoms_Onset
      ).format('MM/DD/YYYY')
    };

    const filtered = Object.entries(reduxFieldValues)
      .filter(([key, value]) => value === 'Yes')
      .map(([key]) => key);

    const initialValues = {
      covidSymptomsChecklist: filtered || [],
      symptomsBeganDate: questionnaire?.Q_Outbreak_Date_of_Symptoms_Onset ?? ''
    };

    return initialValues;
  };

  const updateFieldValues = values => {
    const {
      fever,
      cough,
      shortnessOfBreath,
      fatigueOrBodyAches,
      soreThroat,
      runnyNose,
      lossOfSmell,
      diarrhea,
      noneOfTheAbove
    } = CovidSymptomsEnums;
    const { covidSymptomsChecklist } = values;

    const symptomsInfo = {
      Q_Fever: covidSymptomsChecklist.includes(fever) ? 'Yes' : 'No',
      Q_New_or_Worsening_Cough: covidSymptomsChecklist.includes(cough) ? 'Yes' : 'No',
      Q_Shortness_of_Breath_New_or_Worsening: covidSymptomsChecklist.includes(shortnessOfBreath)
        ? 'Yes'
        : 'No',
      Q_Body_Aches: covidSymptomsChecklist.includes(fatigueOrBodyAches) ? 'Yes' : 'No',
      Q_COVID_Screen_Sore_Throat: covidSymptomsChecklist.includes(soreThroat) ? 'Yes' : 'No',
      Q_COVID_Screen_Runny_Nose: covidSymptomsChecklist.includes(runnyNose) ? 'Yes' : 'No',
      Q_COVID_Screen_Decreased_Smell_or_Taste: covidSymptomsChecklist.includes(lossOfSmell)
        ? 'Yes'
        : 'No',
      Q_Diarrhea: covidSymptomsChecklist.includes(diarrhea) ? 'Yes' : 'No',
      Q_None_of_the_above: covidSymptomsChecklist.includes(noneOfTheAbove) ? 'Yes' : 'No',
      Q_Outbreak_Date_of_Symptoms_Onset: dayjs(values.symptomsBeganDate).format('MM/DD/YYYY')
    };

    const questionnaireObject = {
      ...questionnaire,
      ...symptomsInfo
    };

    updateQuestionnaire(questionnaireObject);

    return symptomsInfo;
  };
  const saveToStore = values => {
    const selectedValue = updateFieldValues(values);
    covidTestCurrentSymptomsNxt({
      selectedValue,
      title: CovidTitles.SYMPTOM_INFORMATION,
      authentication_type: AuthType.UNAUTHENTICATED
    });
    history.push('/guest-home/covid-screen/personal-information');
  };

  const onCancelClick = values => {
    updateFieldValues(values);
    history.goBack();
    covidTestCurrentSymptomsPrev({
      title: CovidTitles.SYMPTOM_INFORMATION,
      authentication_type: AuthType.UNAUTHENTICATED
    });
  };

  const checkDate = values =>
    !dayjs().isValid(values.symptomsBeganDate) || values.symptomsBeganDate > new Date();

  return (
    <>
      <CovidGuestGuard />
      <MuiBox
        mt={1}
        style={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <StyledScreen>
          <MuiBox pl={4} my={2}>
            <MuiTypography fontWeight={FontWeight.bold}>
              {screenText.question_text_1}&nbsp;
              <Asterisk />
            </MuiTypography>
            <Spacer size="small" />
            <MuiTypography>{screenText.instructions}</MuiTypography>
          </MuiBox>
          <Formik
            enableReinitialize="true"
            initialValues={getFieldValue()}
            validationSchema={covidSymptomsValidationSchema}
            onSubmit={values => saveToStore(values)}
          >
            {({
              values,
              isValid,
              touched,
              errors,
              handleBlur,
              setFieldTouched,
              setFieldValue,
              handleSubmit
            }) => {
              return (
                <>
                  <Box hOffsetLeft={Spacing.large}>
                    <FlatList
                      ItemSeparatorComponent={() => <Spacer size="small" />}
                      data={COVID_SYMPTOMS}
                      keyExtractor={item => `${item.key}`}
                      renderItem={({ item }) => {
                        const checkedValues = values?.covidSymptomsChecklist || [];
                        return (
                          <>
                            <MuiBox px={4} py={0.3}>
                              <FormControlLabel
                                label={screenText[`checkbox_${item.key}`]}
                                control={
                                  <Checkbox
                                    data-testid={convertToLowerKabobCase(
                                      item.fieldName,
                                      '-checkbox'
                                    )}
                                    color="primary"
                                    value={item.fieldName}
                                    checked={checkedValues.includes(item.fieldName)}
                                    onChange={() => {
                                      if (
                                        (item.fieldName === 'Q_None_of_the_above' &&
                                          !checkedValues.includes(item.fieldName)) ||
                                        (item.fieldName !== 'Q_None_of_the_above' &&
                                          checkedValues.includes('Q_None_of_the_above'))
                                      ) {
                                        checkedValues.length = 0;
                                      }
                                      setFieldTouched('covidSymptomsChecklist');
                                      setFieldValue(
                                        'covidSymptomsChecklist',
                                        checkedValues.includes(item.fieldName)
                                          ? checkedValues.filter(i => i !== item.fieldName)
                                          : checkedValues.concat(item.fieldName)
                                      );
                                    }}
                                    style={{
                                      alignSelf: 'start',
                                      paddingTop: '0px',
                                      paddingBottom: '0px'
                                    }}
                                  />
                                }
                              />
                            </MuiBox>
                          </>
                        );
                      }}
                    />
                  </Box>
                  <MuiBox px={4}>
                    <Spacer size="medium" />
                    <MuiTypography fontWeight={FontWeight.bold}>
                      {screenText.question_text_2}&nbsp;
                      <Asterisk />
                    </MuiTypography>

                    <Spacer size="small" />
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      inputVariant="outlined"
                      format="MM/DD/YYYY"
                      value={values.symptomsBeganDate || null}
                      onChange={value => {
                        setFieldValue('symptomsBeganDate', value, true);
                      }}
                      onBlur={handleBlur('symptomsBeganDate')}
                      maxDate={new Date()}
                      name="symptomsBeganDate"
                      data-testid="symptoms-began-date"
                      placeholder={screenText.date_format}
                      required
                      autoOk
                      helperText={null}
                      error={touched.symptomsBeganDate && errors.symptomsBeganDate}
                    />
                    {touched.symptomsBeganDate && errors.symptomsBeganDate && (
                      <FieldError error={errors.symptomsBeganDate} />
                    )}

                    <ButtonsNavigation
                      disabledNextButton={
                        !values?.symptomsBeganDate ||
                        checkDate(values) ||
                        !isValid ||
                        values.covidSymptomsChecklist.length === 0
                      }
                      onNextClick={() => handleSubmit()}
                      nextTestId="symptoms-next-button"
                      onCancelClick={() => onCancelClick(values)}
                      cancelTestId="symptoms-previous-button"
                    />
                  </MuiBox>
                </>
              );
            }}
          </Formik>
        </StyledScreen>
        <CovidTestGuestFooter title={CovidTitles.SYMPTOM_INFORMATION} />
      </MuiBox>
    </>
  );
}
