import React, { useEffect } from 'react';
import { TouchableOpacity, ViewProps } from 'react-native';
import styled from 'styled-components';

import Svg from 'components/UI/Svg/Svg';
import { Color } from 'modules/styles/colors';
import { Icon } from '../../Icon';
import Panel from '../Panel/Panel';
import Box from '../Layout/Box';
import FlexBox from '../Layout/FlexBox';
import { ExpandableList } from '../../Sidebar';
import { IconSize } from 'modules/styles/variables';

interface ExpandableListItemProps {
  item: ExpandableList;
  renderItem: Function;
  renderBody: Function;
  firstChild?: boolean;
  lastChild?: boolean;
  style?: ViewProps['style'];
  backgroundColor?: string;
  closeListBoolean?: boolean;
  web?: boolean;
  isExpanded?: boolean;
}

const StyledExpandablePanel = styled(Panel)`
  display: flex;
  border-radius: 0;
  border-top-width: ${(props: ExpandableListItemProps) => (props.firstChild ? 0 : 1)};
  border-bottom-width: 0;
  background-color: ${(props: ExpandableListItemProps) =>
    props.backgroundColor ? props.backgroundColor : Color.foreColor};
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  ${(props: ExpandableListItemProps) => props.style};
`;

const ExpandableListItem = ({
  item,
  renderItem,
  renderBody,
  firstChild,
  lastChild,
  closeListBoolean,
  web,
  isExpanded,

  ...rest
}: ExpandableListItemProps) => {
  const [itemExpanded, setItemExpanded] = React.useState(isExpanded);
  const toggleItem = () => setItemExpanded(!itemExpanded);

  useEffect(() => {
    if (itemExpanded) setItemExpanded(false);
  }, [closeListBoolean]);

  useEffect(() => {
    if (isExpanded) {
      setItemExpanded(true);
    }
  }, [isExpanded]);

  return (
    <Box>
      <FlexBox flex={1}>
        <TouchableOpacity onPress={toggleItem}>
          <StyledExpandablePanel
            backgroundColor={web ? Color.grayHue4 : itemExpanded ? Color.grayHue4 : Color.foreColor}
            firstChild={firstChild}
            lastChild={lastChild}
            {...rest}
          >
            {web ? (
              <Svg
                name={item.iconName}
                set="assets"
                size={item.iconSize}
                style={{ marginRight: '20px', marginTop: '3px' }}
              />
            ) : null}
            <Box flex="1 1 auto">{renderItem(item)}</Box>
            {web ? (
              <Icon
                accessibilityLabel={itemExpanded ? 'Collapse' : 'Expand'}
                name={itemExpanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
                size={24}
                color={Color.black}
              />
            ) : (
              <Svg
                accessibilityLabel={itemExpanded ? 'Collapse' : 'Expand'}
                name={itemExpanded ? 'Subtract' : 'Add'}
                set="assets"
                size={IconSize.base}
                color={Color.black}
              />
            )}
          </StyledExpandablePanel>
        </TouchableOpacity>
      </FlexBox>
      <FlexBox flex={1}>{itemExpanded && <>{renderBody(item)}</>}</FlexBox>
    </Box>
  );
};

ExpandableListItem.defaultProps = {
  firstChild: false,
  lastChild: false,
  style: {}
};

export default ExpandableListItem;
