import SpinnerModal from 'components/UI/Spinner/SpinnerModal';
import React, { useState } from 'react';
import { makeStyles, MuiBox, MuiButton, MuiDialog, MuiTypography } from 'theme/material-ui';
import { CovidTitles } from 'lib/constants/covidScreening';
import { Icon } from 'components/Icon';
import { Color } from 'modules/styles/colors';
import { IconSize } from 'modules/styles/variables';
import { Svg } from 'components/UI/Svg';
import { AuthType } from 'store/authentication/types';
import { AnalyticsEvent } from 'services/AnalyticsService';
import useNavigationAnalytics from 'hooks/useNavigationAnalytics';
import OTPInput from 'components/OTPInput';
import { OTP_MAX_PIN_AUTH_ERROR } from 'modules/constants/covidScreening/personalInfo';
import { useLanguageSwitcher } from 'lib/hooks/useLanguageSwitcher';
import { ButtonsNavigation_translations } from 'lib/constants/translations/components/index';
import { translations } from 'lib/constants/translations/screens/covidGuest/personalInfo';
import { ValidationType } from 'screens/CovidTestGuest/PersonalInformation/types';

const translationsToRender = {
  en: {
    ...translations.en,
    ...ButtonsNavigation_translations.en
  },
  es: {
    ...translations.es,
    ...ButtonsNavigation_translations.es
  }
};

interface ValidationProps {
  authenticated: boolean;
  children: React.ReactNode;
  closeAction: () => void;
  continueAction?: () => void;
  sendAction: () => void;
  sendError: string;
  sendErrorAction: () => void;
  userCellNumber?: string;
  userEmail?: string;
  userHasNoCellNumber: boolean;
  validateAction: (pin: string) => Promise<any>;
  validated?: string;
  validationType?: ValidationType;
}

const useStyles = makeStyles({
  dialog: {
    position: 'absolute',
    bottom: 24,
    margin: 'auto',
    maxWidth: 300
  }
});

function ValidationDialog({
  authenticated,
  children,
  closeAction,
  continueAction,
  sendAction,
  sendError,
  sendErrorAction,
  userCellNumber,
  userEmail,
  userHasNoCellNumber,
  validateAction,
  validated,
  validationType = ValidationType.SMS
}: ValidationProps) {
  const styles = useStyles();

  const [otp, setOtp] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const reSendOTPBtnClicked = useNavigationAnalytics(AnalyticsEvent.CovidOTPResend);
  const covidOTPDialogExited = useNavigationAnalytics(AnalyticsEvent.CovidOTPDialogClose);
  const screenText = useLanguageSwitcher(translationsToRender);

  const disableBtn = otp.trim().length < 6;

  const resetAndClose = () => {
    setOtp('');
    closeAction();
    sendErrorAction();

    const authType = authenticated ? {} : { authentication_type: AuthType.UNAUTHENTICATED };

    return covidOTPDialogExited({
      ...authType,
      title: CovidTitles.PERSONAL_INFORMATION,
      OTP_validation_type: validationType
    });
  };

  const submitAction = async () => {
    setIsSubmitting(true);
    await validateAction(otp);
    setIsSubmitting(false);
  };

  const resendAction = () => {
    setOtp('');
    sendAction();

    const authType = authenticated ? {} : { authentication_type: AuthType.UNAUTHENTICATED };

    return reSendOTPBtnClicked({
      ...authType,
      title: CovidTitles.PERSONAL_INFORMATION,
      OTP_validation_type: validationType
    });
  };

  const onChange = (value: string) => setOtp(value);

  const errorMessageOTP: Record<string, string> = {
    emailError: `${screenText.OTP_EMAIL_ERROR_1}${userEmail}${screenText.OTP_EMAIL_ERROR_2}`,
    phoneError: `${screenText.OTP_PHONE_ERROR_1}${userCellNumber}${screenText.OTP_PHONE_ERROR_2}`,
    maxPinAuthError: `${OTP_MAX_PIN_AUTH_ERROR}`,
    maxPinUnauthError: `${screenText.OTP_MAX_PIN_UNAUTH_ERROR}`,
    otherError: `${screenText.OTP_OTHER_ERROR}`
  };

  return (
    <MuiDialog
      classes={{
        paper: styles.dialog
      }}
      onClose={resetAndClose}
      open
      maxWidth="xs"
      disableBackdropClick
    >
      <>
        {sendError !== '' ? (
          <MuiBox p={3} alignItems="center">
            <MuiBox mt={2} display="flex" flexDirection="row" justifyContent="center">
              <Svg set="material" color={Color.orange} name="warning" />
            </MuiBox>
            <MuiBox mt={2} mb={3}>
              <MuiTypography>{errorMessageOTP[sendError]}</MuiTypography>
            </MuiBox>
            <MuiBox mb={1}>
              <MuiButton
                variant="contained"
                color="primary"
                fullWidth
                onClick={resetAndClose}
                style={{ height: 45 }}
                data-testid="close-button"
              >
                {screenText.close}
              </MuiButton>
            </MuiBox>
          </MuiBox>
        ) : validated === 'SUCCESS' ? (
          <MuiBox p={3} alignItems="center">
            <MuiBox mt={2} display="flex" flexDirection="row" justifyContent="center">
              <Svg name="OTPValidation" />
            </MuiBox>
            <MuiBox textAlign="center" mt={2} mb={3}>
              <MuiTypography fontSize={24} color=" #75a201" fontWeight={700}>
                {userHasNoCellNumber ? screenText.OTP_EMAIL_VERIFY : screenText.OTP_PHONE_VERIFY}
              </MuiTypography>
            </MuiBox>
            <MuiBox mb={1}>
              <MuiButton
                variant="contained"
                color="primary"
                fullWidth
                disabled={disableBtn}
                onClick={continueAction}
                style={{ height: 45 }}
                data-testid="continue"
              >
                {screenText.continue}
              </MuiButton>
            </MuiBox>
          </MuiBox>
        ) : (
          <MuiBox p={3}>
            <MuiBox textAlign="right">
              <Icon name="close" size={IconSize.base} onClick={resetAndClose} />
            </MuiBox>
            {children}
            <MuiBox>
              <OTPInput value={otp} valueLength={6} onChange={onChange} />
              <MuiBox
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="center"
                mb={2}
              >
                <MuiTypography fontSize={16}>{screenText.OTP_NOT_RECEIVE}</MuiTypography>
                <MuiButton
                  color="primary"
                  data-testid="resend-button"
                  style={{
                    fontSize: 16
                  }}
                  onClick={resendAction}
                >
                  {screenText.resend}
                </MuiButton>
              </MuiBox>
              <MuiBox mb={1}>
                <MuiButton
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={disableBtn}
                  onClick={submitAction}
                  style={{ height: 45 }}
                  data-testid="verify-otp"
                >
                  {screenText.verify}
                </MuiButton>
              </MuiBox>
            </MuiBox>
          </MuiBox>
        )}
      </>
      <SpinnerModal isLoading={isSubmitting} />
    </MuiDialog>
  );
}

export default ValidationDialog;
