import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import Svg from 'components/UI/Svg/Svg';
import Spacer from 'components/UI/Layout/Spacer';
import Box from 'components/UI/Layout/Box';
import FlexBox from 'components/UI/Layout/FlexBox';
import StyledButton from 'components/UI/Button/StyledButton';

import { Color } from 'modules/styles/colors';
import { Spacing } from 'modules/styles/variables';

import { RootState } from 'store/types';
import { Medication } from 'store/medications/types';
import {
  prescriptionRenewalDataSelector,
  prescriptionRenewalErrorSelector
} from 'store/medications/selectors';

import { StyledHeadline, StyledText, StyledMessage } from './styled';
import { Icon } from 'components/Icon';
import { NavigationScreenProps } from 'screens/navigation';

interface PrescriptionRenewalContentProps {
  data: Medication;
  onClose: () => void;
}

const PrescriptionRenewalContent = (props: PrescriptionRenewalContentProps) => {
  const { name = '' } = props.data;

  return (
    <Fragment>
      <FlexBox
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        vSpacingTop={Spacing.xLarge}
      >
        <Svg name="MedicationListIcon" stroke={Color.gray} height="40" width="40" />
        <StyledHeadline>{name}</StyledHeadline>
      </FlexBox>

      <Spacer spacing="large" />

      <FlexBox justifyContent="center" alignItems="center">
        <Svg name="CheckCircle" height="150" width="150" />
      </FlexBox>

      <Spacer spacing="large" />

      <Box>
        <StyledText>
          Your medication renewal{'\n'}
          has been sent to your provider
        </StyledText>
      </Box>

      <Spacer spacing="xLarge" />

      <Box>
        <StyledMessage>
          You should receive a status update{'\n'}
          from your provider within 24 hours
        </StyledMessage>
      </Box>

      <Spacer spacing="xLarge" />

      <Box spacing={Spacing.xLarge}>
        <StyledButton flat onPress={props.onClose} textStyle={{ textTransform: 'uppercase' }}>
          Close
        </StyledButton>
      </Box>
    </Fragment>
  );
};

interface Props extends NavigationScreenProps {
  data: Medication | {};
  error: Error | null;
}

const ScreenContent = ({ children, ...rest }: { children: React.ReactNode }) => (
  <FlexBox height="100%" alignItems="center" justifyContent="center" {...rest}>
    {children}
  </FlexBox>
);

const PrescriptionRenewalNoData = () => (
  <ScreenContent>
    <StyledText>Your medication was not found</StyledText>
  </ScreenContent>
);

const PrescriptionRenewalError = () => (
  <ScreenContent>
    <StyledText>There was an issue renewing your prescription</StyledText>
  </ScreenContent>
);

export const RenewPrescriptionConfirmation = ({ history, error, data }: Props) => {
  const type = data?.type.toLowerCase() || 'current';
  const handleClose = () => {
    history.push(`/u/health-record/${type}-medications/medications/${data ? data.id : ''}`);
  };

  let content;

  if (error) {
    content = <PrescriptionRenewalError />;
  } else if (!data || !Object.keys(data).length) {
    content = <PrescriptionRenewalNoData />;
  } else {
    content = <PrescriptionRenewalContent data={data as Medication} onClose={handleClose} />;
  }

  return (
    <Box spacing={Spacing.large}>
      <FlexBox alignItems="flex-end">
        <Icon name="close" size={24} onClick={handleClose} />
      </FlexBox>
      <Spacer spacing="large" />

      {content}
    </Box>
  );
};

const mapStateToProps = (state: RootState) => ({
  data: prescriptionRenewalDataSelector(state),
  error: prescriptionRenewalErrorSelector(state)
});

export default connect(mapStateToProps)(RenewPrescriptionConfirmation);
