import React from 'react';
import { MuiGrid, MuiCardContent } from 'theme/material-ui';
import { Icon } from 'components/Icon';
import { Color } from 'modules/styles/colors';

import SmallCardText from 'components/UI/Typography/SmallCardText';
import WaitTimeBadge from '../WaitTime/WaitTimeBadge';
import { JsonObj } from '@mhp/general-interface';
import {
  Clickable,
  TextContainer,
  PaddedContainer,
  LargeCardText,
  AddressContainer,
  BadgeColContainer,
  CardColMedia,
  MuiRowCard
} from './styled';

interface Props {
  onPress: Function;
  facility: JsonObj;
}

export const FacilityCardItem = ({ onPress, facility }: Props) => (
  <MuiGrid item sm={12} md={4} data-testid="facility-card">
    <Clickable>
      <MuiRowCard onClick={() => onPress(facility)}>
        <BadgeColContainer>
          <WaitTimeBadge
            isOpen={facility.isCurrentlyOpen}
            time={facility.currentWaitTime}
            text="minute"
          />
        </BadgeColContainer>
        <CardColMedia imageUrl={facility.locationImageUrl} />
        <MuiCardContent>
          <PaddedContainer>
            <TextContainer>
              <LargeCardText>{facility.locationName}</LargeCardText>
              <AddressContainer>
                <Icon name="location-on" color={Color.primary} size={15} />
                <MuiGrid item>
                  <SmallCardText>{facility.address1}</SmallCardText>
                </MuiGrid>
              </AddressContainer>
              <AddressContainer>
                <SmallCardText>
                  {`Distance: ${parseFloat(facility.distanceToUser).toFixed(2)} miles`}
                </SmallCardText>
              </AddressContainer>
            </TextContainer>
          </PaddedContainer>
        </MuiCardContent>
      </MuiRowCard>
    </Clickable>
  </MuiGrid>
);
