import React, { useState, useEffect, RefObject } from 'react';
import { Typography } from '@material-ui/core';
import { Color } from 'modules/styles/colors';

export interface ExpandableTextProps {
  maxLines?: number;
  text: string;
  typographyRef: RefObject<HTMLSpanElement>;
}

const ExpandableText = ({ maxLines = 1, text, typographyRef }: ExpandableTextProps) => {
  const [isTruncated, setIsTruncated] = useState(false);
  const [truncatedText, setTruncatedText] = useState(text);
  const [isShowButton, setShowButton] = useState(false);

  useEffect(() => {
    if (typographyRef) {
      const typography = typographyRef.current;
      const typographyWidth = typography.offsetWidth;
      const typographyLineHeight = parseInt(window.getComputedStyle(typography).fontSize, 0);

      const maxTextLength = maxLines * Math.floor(typographyWidth / typographyLineHeight);

      if (text.length > maxTextLength) {
        setShowButton(true);
        setIsTruncated(true);
        setTruncatedText(`${text.substring(0, maxTextLength)}...`);
      }
    }
  }, [text, maxLines]);

  const handleToggleTruncation = () => {
    setIsTruncated(!isTruncated);
  };

  return (
    <>
      <Typography ref={typographyRef}>
        {isTruncated ? truncatedText : text}
        {isShowButton && (
          <button
            onClick={handleToggleTruncation}
            style={{ background: 'transparent', border: 0, width: null, color: Color.link }}
            type="submit"
          >
            {isTruncated ? ' more' : 'see less'}
          </button>
        )}
      </Typography>
    </>
  );
};

export default ExpandableText;
