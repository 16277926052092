import React from 'react';
import { MuiBox, MuiTypography } from 'theme/material-ui';
import Avatar from 'components/UI/Avatar/Avatar';
import FlexBox from 'components/UI/Layout/FlexBox';

interface LabRowProviderProps {
  referringProvider: any;
}

const LabRowProvider = ({ referringProvider }: LabRowProviderProps) => {
  const name = referringProvider?.providerName || 'No Provider';
  const imageUrl = referringProvider?.providerImageUrl || '';

  if (!referringProvider) return null;

  return (
    <FlexBox flexDirection="row">
      {imageUrl ? (
        <MuiBox mr={1}>
          <Avatar size="xSmall" imageUrl={imageUrl} />
        </MuiBox>
      ) : null}
      <MuiTypography>Provider: {name}</MuiTypography>
    </FlexBox>
  );
};

export default LabRowProvider;
