import styled from 'styled-components';

interface Centerable {
  center?: boolean;
  width?: number;
}

const Container = styled.div<{ flexEnd?: boolean }>`
  flex: auto;
  justify-content: ${props => (props.flexEnd ? 'flex-end' : 'flex-start')};
  width: 100%;
`;

export const Column = styled.div<Centerable>`
  flex-direction: column;
  justify-content: ${props => (props.center ? 'center' : 'flex-start')};
  width: ${props => (props.width ? props.width : '100%')};
`;

export const Row = styled.div<Centerable>`
  display: flex;
  flex-direction: row;
  align-items: ${props => (props.center ? 'center' : 'flex-start')};
  width: ${props => (props.width ? props.width : '100%')};
`;

export default Container;
