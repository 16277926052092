import GradientView from 'components/UI/Banner/GradientView';
import Divider from 'components/UI/Divider';
import FlexBox from 'components/UI/Layout/FlexBox';
import Screen from 'components/UI/Layout/Screen';
import Typography from 'components/UI/Typography';
import { Color } from 'modules/styles/colors';
import { FontSize, Spacing, FontWeight } from 'modules/styles/variables';
import React, { useState, useRef } from 'react';
import { Dispatch } from 'redux';
import { View, TouchableOpacity } from 'react-native';
import { NavigationScreenProps } from 'screens/navigation';
import { connect } from 'react-redux';
import { RootState } from 'store/types';
import { Icon } from 'components/Icon';
import styled from 'styled-components';
import TextInput from 'components/UI/Input/TextInput';
import { SubmitButtonBox } from 'screens/Billing/FinancialAssistance/FAApplication/styled';
import StyledButton from 'components/UI/Button/StyledButton';
import Spacer from 'components/UI/Layout/Spacer';
import { FormGroup } from 'components/UI/Layout/Form';
import LinkingServices from 'services/LinkingServices';
import { authenticatePin } from 'store/billing/Payment/actions';
import BottomModal from 'components/UI/Modals/BottomModal';
import { Formik } from 'formik';
import { AuthenticatePinForm } from 'store/billing/Payment/types';
import * as Yup from 'yup';
import {
  authenticatePinFetchingSelector,
  authenticatePinErrorSelector
} from 'store/billing/Payment/selectors';
import SpinnerModal from 'components/UI/Spinner/SpinnerModal';

type PinAuthenticationScreenProps = NavigationScreenProps & {
  dispatch: Dispatch;
  isFetching: boolean;
  error: null | Error;
};

const PinAuthenticationScreen = (props: PinAuthenticationScreenProps) => {
  const CloseButton = styled.div`
    position: absolute;
    right: ${Spacing.xLarge}px;
    top: ${Spacing.large}px;
  `;

  const StyledFormGroup = styled(FormGroup)`
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    border-bottom-width: 0;
    padding: 0;
  `;

  const handleClosePress = () => {
    props.navigation.pop();
  };

  const [showErrorModal, setShowHideErrorModal] = useState(false);

  const toggleModal = () => {
    setShowHideErrorModal(!showErrorModal);
  };

  const authenticationPin = async (values: any) => {
    let authenticatePinResult = null;
    const response = await props.dispatch(
      (authenticatePinResult = await authenticatePin({
        fac: values.facility,
        account: values.account,
        pin: values.pin
      }))
    );

    if (response.error) {
      toggleModal();
    } else {
      props.navigation.replace('Payment');
    }
  };

  const [showModal, setShowHideModal] = useState(false);

  const hideModal = () => {
    setShowHideModal(!showModal);
  };

  const initialValues: AuthenticatePinForm = {
    facility: '',
    account: '',
    pin: ''
  };

  const fieldFacility = useRef(null);
  const fieldAccount = useRef(null);
  const fieldPin = useRef(null);

  const focusNextField = nextRef => {
    nextRef.current.focus();
  };

  const submitPinValidationSchema = Yup.object().shape({
    facility: Yup.number()
      .required('Please enter account number')
      .typeError('Account should only be numbers'),
    account: Yup.number()
      .required('Please enter account number')
      .typeError('Account should only be numbers'),
    pin: Yup.number()
      .required('Please enter pin')
      .typeError('Pin should only be numbers')
  });

  return (
    <Screen safe>
      <SpinnerModal isLoading={props.isFetching} />
      <GradientView>
        <FlexBox spacing={Spacing.xLarge} alignItems="center" justifyContent="center">
          <Typography color={Color.white} fontSize={FontSize.largeHeading}>
            Pay as Guest
          </Typography>
          <CloseButton accessibilityLabel="close button" onPress={handleClosePress}>
            <Icon name="close" size={24} color={Color.white} />
          </CloseButton>
        </FlexBox>
      </GradientView>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={submitPinValidationSchema}
        onSubmit={values => authenticationPin(values)}
      >
        {({ touched, errors, handleChange, handleBlur, isValid, handleSubmit }) => (
          <FlexBox hSpacing={Spacing.large + 5}>
            <Divider />
            <Spacer size="medium" />
            <FlexBox
              alignItems="flex-start"
              justifyContent="center"
              flexDirection="column"
              vSpacing={Spacing.medium}
            >
              <Typography
                color={Color.black}
                fontSize={FontSize.base}
                fontWeight={FontWeight.semibold}
              >
                Account Number
              </Typography>
            </FlexBox>
            <StyledFormGroup>
              <View style={{ flex: 0.34 }}>
                <TextInput
                  keyboardType="numeric"
                  error={touched.facility && errors.facility ? errors.facility : null}
                  clearButtonMode="always"
                  placeholder="xxx"
                  accessibilityLabel="Account Number"
                  onChangeText={handleChange('facility')}
                  onBlur={handleBlur('facility')}
                  ref={fieldFacility}
                  onSubmitEditing={() => focusNextField(fieldAccount)}
                  returnKeyType="done"
                />
              </View>
              <Typography
                color={Color.black}
                fontSize={FontSize.base}
                fontWeight={FontWeight.semibold}
                style={{ padding: 5 }}
              >
                -
              </Typography>
              <View style={{ flex: 0.66 }}>
                <TextInput
                  keyboardType="numeric"
                  error={touched.account && errors.account ? errors.account : null}
                  clearButtonMode="always"
                  placeholder="xxxxxxxx"
                  onChangeText={handleChange('account')}
                  onBlur={handleBlur('account')}
                  ref={fieldAccount}
                  onSubmitEditing={() => focusNextField(fieldPin)}
                  returnKeyType="done"
                />
              </View>
            </StyledFormGroup>
            <Spacer size="small" />
            <FlexBox
              alignItems="flex-start"
              justifyContent="center"
              flexDirection="column"
              vSpacing={Spacing.medium}
            >
              <Typography
                color={Color.black}
                fontSize={FontSize.base}
                fontWeight={FontWeight.semibold}
              >
                PIN
              </Typography>
            </FlexBox>
            <FlexBox alignItems="center" justifyContent="center" flexDirection="column">
              <TextInput
                keyboardType="numeric"
                error={touched.pin && errors.pin ? errors.pin : null}
                clearButtonMode="always"
                placeholder="******"
                onChangeText={handleChange('pin')}
                onBlur={handleBlur('pin')}
                ref={fieldPin}
                returnKeyType="done"
              />
            </FlexBox>
            {showErrorModal && (
              <FlexBox
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
                vSpacing={Spacing.medium}
              >
                <BottomModal
                  onCloseModal={toggleModal}
                  modalText="Authentication Failed"
                  iconName="error"
                  iconColor={Color.red}
                />
              </FlexBox>
            )}

            <SubmitButtonBox>
              <StyledButton disabled={!isValid} accessibilityLabel="Submit" onPress={handleSubmit}>
                Submit
              </StyledButton>
            </SubmitButtonBox>
            <Spacer size="medium" />
            <FlexBox
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              vSpacing={Spacing.medium}
            >
              <TouchableOpacity onPress={() => props.history.push('VisitPayWebView')}>
                <Typography fontSize={FontSize.large} color={Color.primary} textAlign="center">
                  Use Intermountain Billpay to pay your bill {'\n'}or setup a payment plan
                </Typography>
              </TouchableOpacity>
              <Spacer size="large" />
              <Typography
                color={Color.gray}
                fontSize={FontSize.base}
                textAlign="center"
                fontWeight={FontWeight.semibold}
              >
                If you have questions please call Patient Financial Services at
                <Typography
                  color={Color.primary}
                  onPress={() => {
                    LinkingServices.callPhoneNumber('18664156556');
                  }}
                >
                  {' '}
                  1-866-415-6556
                </Typography>
              </Typography>
            </FlexBox>
            <Spacer size="medium" />
            <Divider />
            <Spacer size="medium" />
            <FlexBox
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              vSpacing={Spacing.medium}
            >
              <Typography
                color={Color.primary}
                fontSize={FontSize.base}
                fontWeight={FontWeight.semibold}
                onPress={hideModal}
              >
                How to find your account number and PIN?
              </Typography>
              {showModal && (
                <BottomModal
                  onCloseModal={hideModal}
                  imageURL="https://webpay.intermountainhealthcare.org/webpay/assets/accountpin.gif"
                />
              )}
            </FlexBox>
          </FlexBox>
        )}
      </Formik>
    </Screen>
  );
};

const mapStateToProps = (state: RootState) => ({
  isFetching: authenticatePinFetchingSelector(state),
  error: authenticatePinErrorSelector(state)
});

export default connect(mapStateToProps)(PinAuthenticationScreen);
