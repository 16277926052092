import React from 'react';
import { MuiBox, MuiButton } from 'theme/material-ui';
import { FontWeight } from 'modules/styles/variables';
import { Color } from 'modules/styles/colors';
import Svg from 'components/UI/Svg/Svg';
import Spacer from 'components/UI/Layout/Spacer';
import { useWindowSize } from 'hooks/useWindowSize';
import {
  CardContainer,
  ContentLeft,
  ContentRight,
  TextContentContainer,
  SmallTitle,
  LargeTitle,
  SubTitle,
  LeftBtnContainer,
  ActionButtonTwoSpace,
  StyledImage
} from './styled';

export interface CardAction {
  text: string;
  onClick: () => void;
}

export interface DashboardBannerCardProps {
  title: string;
  imageName: string;
  background: string;
  textColor?: string;
  totalCards: number;
  smallTitle?: string;
  secondSubtitle?: string;
  subTitle?: string;
  actionOne?: CardAction;
  actionTwo?: CardAction;
  onClosePress?: () => void;
  dataTestId?: string;
}

export const DashboardBannerCard = ({
  smallTitle,
  title,
  subTitle,
  secondSubtitle,
  actionOne,
  actionTwo,
  imageName,
  background,
  textColor,
  onClosePress,
  dataTestId
}: DashboardBannerCardProps) => {
  const windowDim = useWindowSize();
  return (
    <CardContainer data-testid={dataTestId} backgroundColor={background}>
      {onClosePress ? (
        <MuiBox position="absolute" right="10px">
          <Svg.Button
            accessibilityLabel="close carousel card"
            name="close"
            color={textColor}
            size={24}
            set="material"
            onClick={onClosePress}
            appearance="transparent"
            data-testid="banner-card-close"
            style={{
              paddingTop: 10,
              paddingBottom: 0,
              paddingRight: 0,
              paddingLeft: 0,
              zIndex: 3
            }}
          />
        </MuiBox>
      ) : null}
      <ContentLeft>
        <TextContentContainer>
          {smallTitle ? (
            <SmallTitle color={textColor}>{smallTitle}</SmallTitle>
          ) : (
            <>{windowDim.width > 480 ? <Spacer size="small" /> : null}</>
          )}
          <LargeTitle color={textColor} fontWeight={FontWeight.semibold}>
            {title}
          </LargeTitle>
          {subTitle ? <SubTitle color={textColor}>{subTitle}</SubTitle> : null}
          {secondSubtitle ? <SubTitle color={textColor}>{secondSubtitle}</SubTitle> : null}
        </TextContentContainer>
        <LeftBtnContainer display="flex" flexDirection="column">
          <Spacer size="small" />
          {actionOne ? (
            <MuiButton
              data-testid="banner-card-btn-one"
              color="primary"
              variant="outlined"
              onClick={actionOne.onClick}
            >
              {actionOne.text}
            </MuiButton>
          ) : null}
          <Spacer size="xsmall" />
          {actionTwo ? (
            <MuiButton
              data-testid="banner-card-btn-two"
              variant="text"
              color={textColor === Color.foreColor ? 'foreColor' : 'primary'}
              onClick={actionTwo.onClick}
            >
              {actionTwo.text}
            </MuiButton>
          ) : (
            <ActionButtonTwoSpace />
          )}
        </LeftBtnContainer>
      </ContentLeft>

      <ContentRight>
        <StyledImage src={imageName} />
      </ContentRight>
    </CardContainer>
  );
};
