import React, { CSSProperties, FormEvent } from 'react';
import { GestureResponderEvent, StyleProp, TextStyle } from 'react-native';
import styled from 'styled-components';
// import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { baseFontSize, Spacing } from 'modules/styles/variables';
import { convertToLowerKabobCase, toSentenceCase } from 'modules/utils/StringUtils';
import { JsonObj } from '@mhp/general-interface';
import { Color } from 'modules/styles/colors';

import DefaultText from 'components/UI/Typography/DefaultText';

interface Props {
  onPress?: (event: GestureResponderEvent) => void | undefined | Promise<void>;
  onClick?: (event?: FormEvent<HTMLFormElement> | undefined) => void | undefined | Promise<void>;
  className?: string;
  children: string;
  flat?: boolean;
  border?: boolean;
  style?: JsonObj;
  disabled?: boolean;
  customColor?: string;
  fullWidth?: boolean;
  height?: number;
  icon?: JsonObj;
  textStyle?: StyleProp<TextStyle>;
  accessibilityLabel?: string;
  title?: string;
  testID?: string;
  ['data-testid']?: string;
}

interface TouchableProps {
  flat?: boolean;
  height?: number;
  disabled?: boolean;
  fullWidth?: boolean;
  border?: boolean;
  style?: CSSProperties | undefined;
  onClick?: (event: GestureResponderEvent) => void | undefined | Promise<void>;
}

const backgroundColor = (
  disabled: boolean | undefined,
  flat: boolean | undefined,
  customColor: string
) => {
  const activeColor = flat ? 'transparent' : customColor;
  return disabled ? Color.buttonDisabled : activeColor;
};

const foreColor = (flat: boolean | undefined, border: boolean | undefined) => {
  const color = flat ? Color.primary : Color.inverted;
  return border ? Color.textLight : color;
};

const StyledTouchable = styled.div<TouchableProps>`
  display: flex;
  align-items: center;
  flex-direction: row;
  background-color: ${props => backgroundColor(props.disabled, props.flat, props.customColor)};
  border-color: ${Color.gray};
  border-radius: 5px;
  border-width: ${props => (props.border ? `1px` : `0px`)};
  border-style: solid;
  elevation: ${props => (props.flat ? 0 : 1)};
  min-height: ${props => (props.height ? props.height : '36px')};
  justify-content: space-around;
  align-items: center;
  margin-bottom: 3px;
  margin-top: 3px;
  max-height: 38px;
  shadow-color: ${props => (props.flat ? 'transparent' : '#000')};
  shadow-offset: 0 1px;
  shadow-opacity: 0.3;
  shadow-radius: 2px;
  shadow-radius: ${props => (props.flat ? '0' : '2px')};
  padding: 8px 16px 6px 16px;
  cursor: pointer;
  ${({ fullWidth }) =>
    fullWidth &&
    `
    width: 100%;
  `}
`;

const StyledText = styled(DefaultText)<TouchableProps>`
  color: ${props => foreColor(props.flat, props.border)};
  letter-spacing: 1.25px;
  line-height: ${baseFontSize * 1.15};
  text-align: center;
`;

const StyledIcon = styled.div`
  margin-right: ${Spacing.small}px;
`;

const StyledButton = (props: Props) => (
  <StyledTouchable
    border={props.border}
    className={props.className}
    flat={props.flat}
    style={props.style}
    underlayColor={props.flat ? Color.flatUnderlay : Color.primaryUnderlay}
    onClick={props.disabled ? () => {} : props.onClick || props.onPress}
    disabled={props.disabled}
    customColor={props.customColor}
    height={props.height}
    title={props.title}
    aria-label={props.accessibilityLabel}
    accessibilityLabel={props.accessibilityLabel}
    testID={props.testID}
    fullWidth={props.fullWidth}
    data-testid={
      props['data-testid']
        ? props['data-testid']
        : typeof props.title === 'undefined'
        ? 'undefined-button'
        : convertToLowerKabobCase(props.title, '-button')
    }
  >
    <>
      {props.icon && (
        <StyledIcon name={props.icon.name} color={props.icon.color} size={props.icon.size} />
      )}
      <StyledText border={props.border} flat={props.flat} style={props.textStyle} numberOfLines={1}>
        {toSentenceCase(props.children)}
      </StyledText>
    </>
  </StyledTouchable>
);

StyledButton.defaultProps = {
  flat: false,
  border: false,
  style: {},
  disabled: false,
  customColor: Color.primary
};

export default StyledButton;
