import React from 'react';

export default {
  svg: (
    <g>
      <defs>
        <path
          d="M0.142663265,21.6288265 C0.142663265,33.4863265 9.7555102,43.0988571 21.6130102,43.0988571 L21.6130102,43.0988571 C33.4708265,43.0988571 43.0833571,33.4863265 43.0833571,21.6288265 L43.0833571,21.6288265 C43.0833571,9.7710102 33.4708265,0.158163265 21.6130102,0.158163265 L21.6130102,0.158163265 C9.7555102,0.158163265 0.142663265,9.7710102 0.142663265,21.6288265"
          id="path-1"
        />
        <path
          d="M0.142663265,21.6288265 C0.142663265,33.4863265 9.7555102,43.0988571 21.6130102,43.0988571 L21.6130102,43.0988571 C33.4708265,43.0988571 43.0833571,33.4863265 43.0833571,21.6288265 L43.0833571,21.6288265 C43.0833571,9.7710102 33.4708265,0.158163265 21.6130102,0.158163265 L21.6130102,0.158163265 C9.7555102,0.158163265 0.142663265,9.7710102 0.142663265,21.6288265"
          id="path-3"
        />
        <path
          d="M0.142663265,21.6288265 C0.142663265,33.4863265 9.7555102,43.0988571 21.6130102,43.0988571 L21.6130102,43.0988571 C33.4708265,43.0988571 43.0833571,33.4863265 43.0833571,21.6288265 L43.0833571,21.6288265 C43.0833571,9.7710102 33.4708265,0.158163265 21.6130102,0.158163265 L21.6130102,0.158163265 C9.7555102,0.158163265 0.142663265,9.7710102 0.142663265,21.6288265"
          id="path-5"
        />
        <path
          d="M0.142663265,21.6288265 C0.142663265,33.4863265 9.7555102,43.0988571 21.6130102,43.0988571 L21.6130102,43.0988571 C33.4708265,43.0988571 43.0833571,33.4863265 43.0833571,21.6288265 L43.0833571,21.6288265 C43.0833571,9.7710102 33.4708265,0.158163265 21.6130102,0.158163265 L21.6130102,0.158163265 C9.7555102,0.158163265 0.142663265,9.7710102 0.142663265,21.6288265"
          id="path-7"
        />
        <path
          d="M0.142663265,21.6288265 C0.142663265,33.4863265 9.7555102,43.0988571 21.6130102,43.0988571 L21.6130102,43.0988571 C33.4708265,43.0988571 43.0833571,33.4863265 43.0833571,21.6288265 L43.0833571,21.6288265 C43.0833571,9.7710102 33.4708265,0.158163265 21.6130102,0.158163265 L21.6130102,0.158163265 C9.7555102,0.158163265 0.142663265,9.7710102 0.142663265,21.6288265"
          id="path-9"
        />
        <path
          d="M0.142663265,21.6288265 C0.142663265,33.4863265 9.7555102,43.0988571 21.6130102,43.0988571 L21.6130102,43.0988571 C33.4708265,43.0988571 43.0833571,33.4863265 43.0833571,21.6288265 L43.0833571,21.6288265 C43.0833571,9.7710102 33.4708265,0.158163265 21.6130102,0.158163265 L21.6130102,0.158163265 C9.7555102,0.158163265 0.142663265,9.7710102 0.142663265,21.6288265"
          id="path-11"
        />
        <path
          d="M0.142663265,21.6288265 C0.142663265,33.4863265 9.7555102,43.0988571 21.6130102,43.0988571 L21.6130102,43.0988571 C33.4708265,43.0988571 43.0833571,33.4863265 43.0833571,21.6288265 L43.0833571,21.6288265 C43.0833571,9.7710102 33.4708265,0.158163265 21.6130102,0.158163265 L21.6130102,0.158163265 C9.7555102,0.158163265 0.142663265,9.7710102 0.142663265,21.6288265"
          id="path-13"
        />
      </defs>
      <g id="Finding-Care" fill="none" fillRule="evenodd">
        <g id="Triage-Landing" transform="translate(-155 -111)">
          <g id="Group-44" transform="translate(152 108)">
            <g id="Group-22" transform="translate(18.347 18.598)">
              <mask id="mask-2" fill="#fff">
                <use xlinkHref="#path-1" />
              </mask>
              <path
                d="M25.3595184,5.25820102 L25.3595184,6.20401735 C25.3595184,8.26456837 23.673498,9.95058878 21.6129469,9.95058878 C19.5527122,9.95058878 17.8666918,8.26456837 17.8666918,6.20401735 L17.8666918,5.25788469"
                id="Stroke-1"
                stroke="#FFF"
                strokeWidth="2.688"
                strokeLinecap="round"
                strokeLinejoin="round"
                mask="url(#mask-2)"
              />
              <mask id="mask-4" fill="#fff">
                <use xlinkHref="#path-3" />
              </mask>
              <polyline
                id="Stroke-4"
                stroke="#FFF"
                strokeWidth="2.688"
                strokeLinecap="round"
                strokeLinejoin="round"
                mask="url(#mask-4)"
                points="23.6729918 9.32872245 24.4245837 12.5941612 36.1732673 13.7993653"
              />
              <mask id="mask-6" fill="#fff">
                <use xlinkHref="#path-5" />
              </mask>
              <polyline
                id="Stroke-7"
                stroke="#FFF"
                strokeWidth="2.688"
                strokeLinecap="round"
                strokeLinejoin="round"
                mask="url(#mask-6)"
                points="7.05319592 13.799302 18.8015633 12.594098 19.5771959 9.34479184"
              />
              <mask id="mask-8" fill="#fff">
                <use xlinkHref="#path-7" />
              </mask>
              <polyline
                id="Stroke-10"
                stroke="#FFF"
                strokeWidth="2.688"
                strokeLinecap="round"
                strokeLinejoin="round"
                mask="url(#mask-8)"
                points="0.186980612 20.2660918 14.5447255 20.2660918 15.947001 24.847449 7.03450102 45.3552143"
              />
              <mask id="mask-10" fill="#fff">
                <use xlinkHref="#path-9" />
              </mask>
              <path
                d="M21.4823357,32.1898306 L13.4353051,47.0695143"
                id="Stroke-13"
                stroke="#FFF"
                strokeWidth="2.688"
                strokeLinecap="round"
                strokeLinejoin="round"
                mask="url(#mask-10)"
              />
              <mask id="mask-12" fill="#fff">
                <use xlinkHref="#path-11" />
              </mask>
              <polyline
                id="Stroke-16"
                stroke="#FFF"
                strokeWidth="2.688"
                strokeLinecap="round"
                strokeLinejoin="round"
                mask="url(#mask-12)"
                points="43.0399571 20.2660918 28.6812633 20.2660918 27.2789878 24.847449 36.1914878 45.3552143"
              />
              <mask id="mask-14" fill="#fff">
                <use xlinkHref="#path-13" />
              </mask>
              <path
                d="M21.7438429,32.1898306 L29.7908735,47.0695143"
                id="Stroke-19"
                stroke="#FFF"
                strokeWidth="2.688"
                strokeLinecap="round"
                strokeLinejoin="round"
                mask="url(#mask-14)"
              />
            </g>
            <g id="Group-43" transform="translate(0 .251)" stroke="#FFF">
              <path
                d="M61.4302959,39.9756388 C61.4302959,51.8331388 51.8177653,61.4459857 39.959949,61.4459857 C28.102449,61.4459857 18.489602,51.8331388 18.489602,39.9756388 C18.489602,28.1181388 28.102449,18.5052918 39.959949,18.5052918 C51.8177653,18.5052918 61.4302959,28.1181388 61.4302959,39.9756388 Z"
                id="Stroke-23"
                strokeWidth="2.688"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M37.9336561,36.3125459 L39.9600439,38.3389337"
                id="Stroke-25"
                strokeWidth="3.84"
                strokeLinecap="round"
              />
              <path
                d="M41.9864316,36.3125459 L39.9600439,38.3389337"
                id="Stroke-27"
                strokeWidth="3.84"
                strokeLinecap="round"
              />
              <polyline
                id="Stroke-29"
                strokeWidth="3.84"
                points="41.9864316 36.3125459 39.9600439 38.3389337 37.9336561 36.3125459"
              />
              <polyline
                id="Stroke-31"
                strokeWidth="2.688"
                strokeLinecap="round"
                strokeLinejoin="round"
                points="16.135848 18.5052918 10.677001 18.5052918 10.677001 61.4459857 16.135848 61.4459857"
              />
              <path
                d="M10.6770327,39.9756388 L4.96986939,39.9756388"
                id="Stroke-33"
                strokeWidth="2.688"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <polyline
                id="Stroke-35"
                strokeWidth="2.688"
                strokeLinecap="round"
                strokeLinejoin="round"
                points="61.9230378 16.0444929 61.9230378 10.5856459 18.9823439 10.5856459 18.9823439 16.0444929"
              />
              <path
                d="M40.4528173,10.5857092 L40.4528173,4.87854592"
                id="Stroke-37"
                strokeWidth="2.688"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  ),
  viewBox: '0 0 61 61'
};
