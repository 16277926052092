import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import Config from 'react-native-config';
import Hidden from '@material-ui/core/Hidden';
import logo from 'assets/branding/ih-logo-640x196-on-light.png';

import { Color } from 'modules/styles/colors';
import { IconSize, Spacing, ZIndex } from 'modules/styles/variables';
import analyticsService, { AnalyticsEvent } from 'services/AnalyticsService';
import { setSwitchAccountOpen } from 'store/account/actions';
import { isAccountSwitcherModalOpenSelector } from 'store/account/selectors';
import {
  isPrimaryUserSelectHealthMemberSelector,
  consumerIdSelector
} from 'store/profile/selectors';
import { RootDispatch, RootState } from 'store/types';
import { languageSelector } from 'store/consumerPreferences/selectors';
import { languagesValues } from 'lib/constants/translations/components/LanguageSwitcher';
import { ExpandedView as translations } from 'lib/constants/translations/components/index';
import { useLanguageSwitcher } from 'lib/hooks/useLanguageSwitcher';
import useNavigationAnalytics from 'hooks/useNavigationAnalytics';
import { CursorMuiBox, MuiBox, MuiDivider } from 'theme/material-ui';

import { NavRoute } from 'screens/navigation';
import Svg from 'components/UI/Svg/Svg';

import { SidebarLink } from '../constants';
import ListItemButton from '../ListItemButton/ListItemButton';
import { FullWidthList } from './styled';
import LanguageSwitcherModal from '../LanguageSwitcherModal';

export const LANGUAGE_SWITCHER_ENABLED = Config.PRIMARY_LANGUAGE_SWITCHER === 'enabled';

export interface ExpandedViewProps {
  handleTabClick: Function;
  isAccountSwitcherModalOpen: boolean;
  isGuest: boolean | undefined;
  navBarRoutes: NavRoute[];
  open: boolean;
  setCollapse: () => void;
  unreadCount: number;
  isSelectHealthMember: boolean;
  consumerId: string;
  language: string;
}

export const ExpandedView = ({
  handleTabClick,
  isGuest,
  navBarRoutes,
  setCollapse,
  unreadCount,
  isSelectHealthMember,
  consumerId,
  language
}: ExpandedViewProps) => {
  const history = useHistory();
  const helpTabClicked = useNavigationAnalytics(AnalyticsEvent.HelpTabClicked);
  const screenText = useLanguageSwitcher(translations);
  const [laguageModalOpen, setLaguageModalOpen] = useState(false);

  const resourceLinks = [
    {
      id: 14,
      name: 'SelectHealth',
      routeName: isSelectHealthMember
        ? 'https://fssoconsumer.intermountainhealthcare.org/shmyhealthweb/dashboard'
        : 'https://selecthealth.org',
      newWindow: true,
      analyticsName: isSelectHealthMember
        ? 'https://fssoconsumer.intermountainhealthcare.org/shmyhealthweb/dashboard'
        : 'https://selecthealth.org',
      key: 'SH'
    }
  ];
  if (!isGuest) {
    resourceLinks.push({
      id: 15,
      name: 'Family Health History',
      routeName: Config.OUR_FAMILY_URL,
      newWindow: true,
      analyticsName: 'Our Family Health',
      key: 'FHH'
    });
  }
  const handleResourceAmplitudeEvent = (externalLink = 'Unknown') =>
    analyticsService.logEvent(AnalyticsEvent.ExternalLinkClicked, { source: externalLink });

  return (
    <>
      <LanguageSwitcherModal
        open={laguageModalOpen}
        handleClose={() => setLaguageModalOpen(false)}
      />
      <FullWidthList id="navigation">
        <Hidden only={['lg', 'xl']}>
          <CursorMuiBox
            onClick={setCollapse}
            position="absolute"
            top="12px"
            right="12px"
            zIndex={ZIndex.high}
          >
            <Svg
              set="material"
              name="arrow_back"
              accessibilityLabel="Collapse side navigation"
              size={IconSize.base}
            />
          </CursorMuiBox>
        </Hidden>
        <CursorMuiBox pl={Spacing.small} onClick={() => history.push('/u/dashboard')}>
          <MuiBox
            testID="intermountainHealth-logo"
            style={{
              display: 'flex',
              flexDirection: 'column',
              height: '100px',
              justifyContent: 'flex-end'
            }}
          >
            <img src={logo} width="195px" height="60px" alt="" />
          </MuiBox>
        </CursorMuiBox>
        <MuiBox pt={Spacing.small} display="flex" flexShrink="0" flexDirection="column">
          {navBarRoutes.map((item: NavRoute) => (
            <ListItemButton
              key={item.componentName}
              disabled={isGuest}
              iconName={item.sidenavIcon}
              iconSize={item.sidenavIconSize}
              routeName={item.path}
              routeText={screenText[item?.componentName]}
              onClick={() => handleTabClick(item.path)}
              unreadCount={unreadCount}
            />
          ))}
        </MuiBox>
        <MuiBox display="flex" flexShrink="0" flexDirection="column" />
        <MuiBox
          width="100%"
          bgcolor={Color.grayLight3}
          flexDirection="column"
          display="flex"
          mt="auto"
        >
          <MuiDivider />
          <MuiBox paddingY={1}>
            {resourceLinks.map((item: SidebarLink) => {
              if (item.id === 15) {
                item.routeName = item.routeName.replace('$CONSUMERID', consumerId);
              }

              return (
                <MuiBox key={item.id}>
                  <ListItemButton
                    newWindow
                    routeName={item.routeName}
                    routeText={screenText[item?.key]}
                    onClick={() => handleResourceAmplitudeEvent(item.analyticsName)}
                  />
                </MuiBox>
              );
            })}
          </MuiBox>
          <MuiDivider variant="middle" />
          {LANGUAGE_SWITCHER_ENABLED ? (
            <MuiBox paddingTop={1} data-testid="language">
              <ListItemButton
                set="assets"
                iconName="Language"
                iconSize={IconSize.base}
                titleText={`${screenText?.language}:`}
                routeText={`${languagesValues[language]}`}
                onClick={() => setLaguageModalOpen(true)}
              />
            </MuiBox>
          ) : null}
          <MuiBox paddingTop={1} paddingBottom={Spacing.xSmall}>
            <ListItemButton
              set="assets"
              iconName="HelpIcon"
              iconSize={IconSize.base}
              routeName="/u/help-support"
              routeText={screenText?.help}
              onClick={() => {
                helpTabClicked();
                history.push('/u/help-support');
              }}
            />
          </MuiBox>
        </MuiBox>
      </FullWidthList>
    </>
  );
};

const mapState = (state: RootState) => ({
  isAccountSwitcherModalOpen: isAccountSwitcherModalOpenSelector(state),
  isSelectHealthMember: isPrimaryUserSelectHealthMemberSelector(state),
  consumerId: consumerIdSelector(state),
  language: languageSelector(state)
});

const mapDispatch = (dispatch: RootDispatch) => ({
  setSwitchAccountOpen: (isOpen: boolean) => dispatch(setSwitchAccountOpen(isOpen))
});

export default connect(mapState, mapDispatch)(ExpandedView);
