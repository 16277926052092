import React from 'react';
import { Icon } from 'components/Icon';
import { MuiBox } from 'theme/material-ui';
import FlexBox from 'components/UI/Layout/FlexBox';
import Box from 'components/UI/Layout/Box';
import Spacer from 'components/UI/Layout/Spacer';
import styled from 'styled-components';
import StyledButton from 'components/UI/Button/StyledButton';
import { Color } from 'modules/styles/colors';
import {
  Spacing,
  BorderRadius,
  FontSize,
  IconSize,
  FontWeight,
  ZIndex
} from 'modules/styles/variables';

interface Props {
  modalText: string;
  buttonText: string;
  children?: React.ReactNode;
  onCloseModal: Function;
  onButtonPress: Function;
}

const ModalContainer = styled(Box)`
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: ${Color.backDrop};
  width: 100%;
  height: 100%;
  z-index: ${ZIndex.base};
`;
const ModalChild = styled(FlexBox)`
  position: absolute;
  bottom: ${Spacing.none};
  left: ${Spacing.none};
  height: 300px;
  background-color: ${Color.white};
  width: 100%;
  z-index: ${ZIndex.low};
`;
const CloseButton = styled.div`
  position: absolute;
  right: ${Spacing.large}px;
  top: ${Spacing.large}px;
  background-color: ${Color.grayLight};
  border-radius: ${BorderRadius.xLarge};
  padding: ${Spacing.small}px;
`;

const StatusText = styled.div`
  font-size: ${FontSize.heading};
  text-align: center;
  font-weight: ${FontWeight.semibold};
`;

const MainActionButton = styled(StyledButton)`
  width: 80%;
  margin-top: ${Spacing.large};
`;

export const HeadingMuiBox = styled(MuiBox)`
  display: flex;
  width: 100%;
  display: -webkit-box;
  flex-direction: row;
  align-items: center;
  align-content: center;
  text-align: center;
`;

const BottomConfirmationModal = (props: Props) => {
  return (
    <ModalContainer>
      <ModalChild flexDirection="column" justifyContent="center" alignItems="center">
        <HeadingMuiBox p={1}>
          <CloseButton onPress={props.onCloseModal} accessibilityLabel="Close pop-up">
            <Icon name="close" size={IconSize.small} color={Color.foreColor} />
          </CloseButton>
        </HeadingMuiBox>

        <FlexBox
          flexDirection="column"
          width="80%"
          height="90%"
          justifyContent="space-between"
          alignItems="center"
        >
          <StatusText>{props.modalText}</StatusText>
          {props.children}
          <FlexBox
            flexDirection="column"
            width="80%"
            justifyContent="flex-start"
            alignItems="center"
          >
            {props.buttonText && (
              <MainActionButton onClick={props.onButtonPress}>{props.buttonText}</MainActionButton>
            )}
            {props.onCloseModal && (
              <MainActionButton customColor={Color.gray} onClick={props.onCloseModal}>
                Cancel
              </MainActionButton>
            )}
            <Spacer size="small" />
          </FlexBox>
        </FlexBox>
      </ModalChild>
    </ModalContainer>
  );
};

export default BottomConfirmationModal;
