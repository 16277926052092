import CollapsibleListItem from 'components/common/CollapsibleList/CollapsibleListItem';
import Divider from 'components/UI/Divider';
import React from 'react';
import {
  Document,
  InpatientDocumentInfoItem,
  ResourceDocument,
  VisitSummaryDetails
} from 'store/visitSummary/types';
import { oc } from 'ts-optchain';
import { MuiBox, MuiGrid, MuiPaper } from 'theme/material-ui';
import BinaryDocumentHandler from 'components/VisitSummaries/BinaryDocumentHandler';

interface Props {
  visit: VisitSummaryDetails;
}

const getInpatientParsedDocumentTextArray = (
  infoItems: InpatientDocumentInfoItem[],
  key: keyof InpatientDocumentInfoItem['sections']
) => {
  const parsedTextArray = infoItems.reduce((acc, item) => {
    if (oc(item).sections[key]()) {
      return acc.concat(item.sections[key]);
    }

    return acc;
  }, [] as string[]);

  return parsedTextArray;
};

export const renderCategoryList = (item: ResourceDocument) => {
  return (
    <>
      <MuiGrid item xs={3}>
        <MuiBox textAlign="left" fontWeight="fontWeightBold" p={3}>
          {item.categoryDisplay.toString()}
        </MuiBox>
      </MuiGrid>
      <MuiGrid item xs={9} direction="column">
        <MuiGrid container>
          {item.documents.map((document: Document) => (
            <BinaryDocumentHandler document={document} />
          ))}
        </MuiGrid>
      </MuiGrid>
    </>
  );
};

export const PatientInstructions = ({ visit }: Props) => {
  let dischargeInstructions: string[] = [];
  let followUpInstructions: string[] = [];
  const hasDocuments = visit.inpatientDocumentInfo && visit.inpatientDocumentInfo.length;

  if (hasDocuments) {
    dischargeInstructions = getInpatientParsedDocumentTextArray(
      visit.inpatientDocumentInfo,
      'dischargeDiagnosisAndPlan'
    );
    followUpInstructions = getInpatientParsedDocumentTextArray(
      visit.inpatientDocumentInfo,
      'followUp'
    );
  }

  if (dischargeInstructions.length || followUpInstructions.length || hasDocuments) {
    return (
      <>
        <CollapsibleListItem title="Patient instructions">
          <MuiBox width="100%">
            <MuiPaper elevation={2}>
              {dischargeInstructions.length > 0 &&
                dischargeInstructions.map((instruction: string) => (
                  <MuiBox
                    key={instruction}
                    flex={1}
                    p={3}
                    dangerouslySetInnerHTML={{ __html: instruction }}
                  />
                ))}
              {followUpInstructions.length > 0 &&
                followUpInstructions.map((instruction: string) => (
                  <MuiBox
                    key={instruction}
                    flex={1}
                    p={3}
                    dangerouslySetInnerHTML={{ __html: instruction }}
                  />
                ))}
              {oc(visit).documents.inpatientDocumentInfoNotes() && (
                <MuiGrid container aria-label="resources list">
                  {renderCategoryList(visit.documents.inpatientDocumentInfoNotes)}
                </MuiGrid>
              )}
            </MuiPaper>
          </MuiBox>
        </CollapsibleListItem>
        <Divider />
      </>
    );
  }

  return null;
};

export default PatientInstructions;
