import React from 'react';
import {
  MuiDialog,
  MuiDialogTitle,
  MuiDialogContent,
  MuiDialogActions,
  MuiBox
} from 'theme/material-ui';
import * as Yup from 'yup';
import { FormikHelpers } from 'formik';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import { ConnectCareButton, ConnectCareForm } from 'components/ConnectCare';
import { noPhone } from 'modules/constants/amwell';
import { USPhoneMaskRegex } from 'modules/utils/PhoneUtils';
import { CenteredSpinner } from 'components/UI/Spinner/SpinnerContainer';
import { Alert } from 'components/Alert';
import * as profileActions from 'store/profile/actions';
import { connect } from 'react-redux';

interface Props {
  isVisible: boolean;
  onClose: () => void;
  onSubmit: () => void;
  savePrimaryPhone: typeof profileActions.savePrimaryPhone;
}

type PhoneType = 'HOME' | 'MOBILE' | 'WORK';

type InitialValues = {
  phone: string;
  type: PhoneType;
};

const initialValues: InitialValues = {
  phone: '',
  type: 'MOBILE'
};

const validationSchema = Yup.object().shape({
  phone: Yup.string()
    .required(noPhone.PHONE_VALIDATION.required)
    .matches(USPhoneMaskRegex, noPhone.PHONE_VALIDATION.invalid)
});

export function NoPhoneDialog({ isVisible, onClose, savePrimaryPhone, onSubmit }: Props) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleSubmit = async (values: InitialValues, actions: FormikHelpers<InitialValues>) => {
    actions.setSubmitting(true);
    try {
      const res: any = await savePrimaryPhone(values.phone, values.type);

      if (res.error) {
        throw new Error(res.error.message);
      }

      Alert.alert(noPhone.SUCCESS.title, noPhone.SUCCESS.subtitle, [
        { text: 'Ok', onPress: onSubmit }
      ]);
    } catch (err) {
      Alert.alert(noPhone.ERROR.title, noPhone.ERROR.subtitle, [{ text: 'Ok', onPress: onClose }]);
    }
    actions.setSubmitting(false);
  };

  return (
    <ConnectCareForm
      isInitialValid={false}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isValid, isSubmitting, submitForm }) => (
        <MuiDialog
          maxWidth="md"
          fullWidth
          fullScreen={isSmallScreen}
          disableBackdropClick
          disableEscapeKeyDown
          onClose={onClose}
          open={isVisible}
          aria-labelledby="connect-care-disclaimer-dialog-title"
        >
          <MuiDialogTitle data-testid-="connect-care-no-phone-dialog-title">
            {noPhone.SCREEN.title}
          </MuiDialogTitle>
          <Divider />
          <MuiDialogContent>
            <MuiBox p={2}>{noPhone.FORM.title} </MuiBox>
            <MuiBox mb={1}>
              <ConnectCareForm.TextField
                label="Phone"
                placeholder="(xxx) xxx-xxxx"
                required
                mask="phone"
                type="string"
                name="phone"
              />
            </MuiBox>
            <MuiBox mb={1}>
              <ConnectCareForm.Select
                label="Type"
                required
                name="type"
                placeholder="Type"
                items={noPhone.ITEMS.phoneTypes}
              />
            </MuiBox>
          </MuiDialogContent>
          <Divider />
          <MuiDialogActions>
            <ConnectCareButton variant="text" onClick={onClose}>
              Not now
            </ConnectCareButton>
            <ConnectCareButton type="submit" disabled={!isValid} onClick={submitForm}>
              Submit
            </ConnectCareButton>
          </MuiDialogActions>
          {isSubmitting ? <CenteredSpinner opacity={0.8} /> : null}
        </MuiDialog>
      )}
    </ConnectCareForm>
  );
}

const mapDispatchToProps = {
  savePrimaryPhone: profileActions.savePrimaryPhone
};

export default connect(null, mapDispatchToProps)(NoPhoneDialog);
