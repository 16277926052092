import { useDispatch, useSelector } from 'react-redux';

import { Questionnaire } from 'store/CovidScreening/types';
import { setQuestionnaire } from 'store/CovidScreening/actions';
import { CovidScreeningQuestionnaireSelector } from 'store/CovidScreening/selectors';

export default function useCovidQuestionnaire() {
  const dispatch = useDispatch();
  const questionnaire = useSelector(CovidScreeningQuestionnaireSelector);

  const updateQuestionnaire = (questionnaireData: Questionnaire) => {
    dispatch(setQuestionnaire(questionnaireData));
  };

  return {
    questionnaire,
    updateQuestionnaire
  };
}
