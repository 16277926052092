import React, { createRef, useState, useMemo, useRef } from 'react';
import { connect } from 'react-redux';
import { oc } from 'ts-optchain';
import isEmpty from 'lodash/isEmpty';
import { Formik } from 'formik';
import { maskPhone } from 'modules/utils/MaskUtils';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { MuiButton } from 'theme/material-ui';
import {
  faBasicInfoSelector,
  updateFAProfileAddressSelector
} from 'store/billing/financialAssistance/createApplication/selectors';
import { RootState, RootDispatch } from 'store/types';
import { BasicInfoForm } from 'store/billing/financialAssistance/createApplication/types';
import { setFAForm1BasicInfo } from 'store/billing/financialAssistance/createApplication/actions';
import {
  profileConsumerSelector,
  profileAddressesSelector,
  profilePhonesSelector
} from 'store/profile/selectors';
import {
  currentLocationPathNameSelector,
  previousLocationPathNameSelector
} from 'store/router/selectors';
import analyticsService, { AmplitudeEventData, AnalyticsEvent } from 'services/AnalyticsService';
import { Consumer, Address, Phone } from 'store/profile/types';
import { AddressValues } from 'screens/Profile/EditAddress';
import { history } from 'lib/history';

import RequiredFieldsLegend from 'components/RequiredFieldsLegend';

import { basicInformationValidationSchema } from '../validation';
import { INIT_FORM, BASIC_INFO_SECTIONS, OPTIONAL_ADDRESS } from './constants';
import { InitPhone, InitAddress, RefStore } from './types';
import FABasicInfoSection from './FABasicInfoSection';
import FABasicInfoHeader from './FABasicInfoHeader';
import DisableWhenWaiting from '../../components/DisableWhenWaiting';
import { FAExitGuard } from '../FAExitGuard';

export interface Props {
  basicInfoData: BasicInfoForm | {};
  profileConsumer: Consumer;
  guarantorAddress: Address[];
  guarantorPhones: Phone[];
  guarantorEditedAddress: AddressValues | {};
  dispatch: RootDispatch;
  currentUrl?: string;
  referringUrl?: string;
}

const getInitAddress = (address: Address[] = [], update: AddressValues | {}) => {
  const result = isEmpty(update) ? address[0] : (update as AddressValues);

  return {
    addressLineOne: oc(result).streetAddress(''),
    addressLineTwo: oc(result).streetAddress2(''),
    city: oc(result).city(''),
    zip: oc(result).postalCode(''),
    state: oc(result).state('')
  };
};

const getInitPhones = (phones: Phone[] = []) => {
  return phones.reduce(
    (acc, cur) => {
      if (cur.type === 'MOBILE' || cur.type === 'HOME') {
        acc.homePhone = maskPhone(cur.value);
      }
      if (cur.type === 'WORK') {
        acc.workPhone = maskPhone(cur.value);
      }
      return acc;
    },
    { homePhone: '', workPhone: '' }
  );
};

const getInitUser = (consumer: Consumer) => ({
  firstName: consumer.firstName,
  lastName: consumer.lastName,
  dateOfBirth: consumer.dateOfBirth
});

const getInitialValues = (phones: InitPhone, address: InitAddress, data: BasicInfoForm) => {
  if (oc(data).patientFirstName('')) return data;

  return {
    ...INIT_FORM,
    ...phones,
    ...address
  };
};

const createRefs = () => [...Array(35)].map(createRef);

export function FABasicInfoScreen(props: Props) {
  const {
    basicInfoData,
    profileConsumer,
    guarantorAddress,
    guarantorPhones,
    guarantorEditedAddress,
    dispatch,
    currentUrl,
    referringUrl
  } = props;

  const eventData: AmplitudeEventData = {
    currentUrl,
    referringUrl
  };

  const data = basicInfoData as BasicInfoForm;

  const initUser = useMemo(() => {
    return getInitUser(profileConsumer);
  }, [profileConsumer]);

  const initAddress = useMemo(() => {
    return getInitAddress(guarantorAddress, guarantorEditedAddress);
  }, [guarantorEditedAddress]);

  const initPhones = useMemo(() => {
    return getInitPhones(guarantorPhones);
  }, [guarantorPhones]);

  const initialValues = useMemo(() => {
    return getInitialValues(initPhones, initAddress, data);
  }, [initPhones, initAddress, data]);

  const prefill = { ...initUser, ...initAddress, ...initPhones };

  const formRef = useRef<RefStore>(createRefs());

  // Extra Address 2 Expand
  const initialExpanded = !!oc(data).extra2AddressLineOne('');
  const [expanded, setExpanded] = useState(initialExpanded);

  const isInitialValid = () => {
    if (!Object.keys(basicInfoData).length) return false;
    return basicInformationValidationSchema.isValid(basicInfoData);
  };

  const onNextPress = (values: BasicInfoForm) => {
    const isEdit = oc(history.location.state).isEdit(false);
    dispatch(setFAForm1BasicInfo(values));

    if (isEdit) {
      analyticsService.logEvent(AnalyticsEvent.BasicInformationNextClicked, {
        type: 'edit',
        ...eventData
      });
      history.push('/u/fa-app/complete');
    } else {
      analyticsService.logEvent(AnalyticsEvent.BasicInformationNextClicked, {
        type: 'apply',
        ...eventData
      });
      history.push('/u/fa-app/household');
    }
  };

  // TODO: Link to edit account
  // const onEditProfilePress = () => {
  //   dispatch(setEditingFAFormProfile(true));
  //   navigation.navigate('MyAccount', { returnBackTo: 'FABasicInfo' });
  // };

  // const basicInfoFns: { [x: string]: () => void } = {
  //   onEditProfilePress
  // };

  return (
    <Container maxWidth="md">
      <FAExitGuard />
      <Box py={4}>
        <Box mb={2}>
          <Typography variant="h5">Basic Information</Typography>
        </Box>
        <Divider />
        <Box mt={2}>
          <RequiredFieldsLegend />
        </Box>
      </Box>
      <Formik
        initialValues={initialValues}
        validationSchema={basicInformationValidationSchema}
        onSubmit={onNextPress}
        isInitialValid={isInitialValid() as boolean | ((props: {}) => boolean) | undefined}
        enableReinitialize
      >
        {formik => (
          <Box>
            {BASIC_INFO_SECTIONS.map(section => {
              const isOptAddress = section.title.includes(OPTIONAL_ADDRESS);
              const hide = oc(section).hide(() => false)(formik.values, expanded);
              const sectionProps = {
                formRef,
                formik,
                onFocusNext: () => {},
                prefill,
                profileConsumer,
                expanded
              };

              if (isOptAddress && hide) return null;

              return (
                <Box mb={4}>
                  <FABasicInfoHeader section={section} />
                  <Grid container spacing={2}>
                    {section.data.map(item => (
                      <FABasicInfoSection item={item} {...sectionProps} />
                    ))}
                  </Grid>
                </Box>
              );
            })}
            {formik.values.addressLength === '1' && (
              <Box display="flex" justifyContent="flex-end" pb={4}>
                <MuiButton
                  size="large"
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    setExpanded(!expanded);
                    if (expanded) {
                      const resetFields = [
                        'extra2AddressLineOne',
                        'extra2AddressLineTwo',
                        'extra2AddressCity',
                        'extra2AddressZip',
                        'extra2AddressState',
                        'extra2AddressFrom',
                        'extra2AddressTo'
                      ];
                      resetFields.forEach(f => formik.setFieldValue(f, ''));
                    }
                  }}
                >
                  {expanded ? 'Remove additional address' : 'Add additional address'}
                </MuiButton>
              </Box>
            )}
            <Box pb={4}>
              <Container maxWidth="xs">
                <Box mb={1}>
                  <MuiButton
                    data-testid="next-button"
                    size="large"
                    variant="contained"
                    color="primary"
                    disabled={!formik.isValid}
                    fullWidth
                    onClick={() => {
                      formik.handleSubmit();
                    }}
                  >
                    Next
                  </MuiButton>
                </Box>
                <MuiButton
                  data-testid="previous-button"
                  size="large"
                  color="primary"
                  fullWidth
                  onClick={() => history.push('/u/fa-app')}
                >
                  Cancel
                </MuiButton>
              </Container>
            </Box>
          </Box>
        )}
      </Formik>
      <DisableWhenWaiting />
    </Container>
  );
}

const mapStateToProps = (state: RootState) => ({
  basicInfoData: faBasicInfoSelector(state),
  profileConsumer: profileConsumerSelector(state),
  guarantorAddress: profileAddressesSelector(state),
  guarantorPhones: profilePhonesSelector(state),
  guarantorEditedAddress: updateFAProfileAddressSelector(state),
  currentUrl: currentLocationPathNameSelector(state),
  referringUrl: previousLocationPathNameSelector(state)
});

export default connect(mapStateToProps)(FABasicInfoScreen);
