import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { MuiBox, MuiGrid, MuiTypography, MuiLink } from 'theme/material-ui';

import { Svg } from 'components/UI/Svg';
import { LazyMuiAvatar } from 'components/LazyAvatar';

import { Color } from 'modules/styles/colors';
import { FontSize, FontWeight, IconSize, Spacing } from 'modules/styles/variables';
import { Nullable } from 'modules/types/common';

import { RootState } from 'store/types';
import * as selectors from 'store/findProvider/selectors';
import {
  AppointmentLocation,
  DoctorSpecialty,
  GroupedVisitType,
  Slots,
  Specialty,
  VisitTypeNpv
} from 'store/booking/types';
import {
  currentLocationPathNameSelector,
  previousLocationPathNameSelector
} from 'store/router/selectors';

import { SelectHealthIconWrapper } from 'screens/Booking/components/styled';
import FlexBox from 'components/UI/Layout/FlexBox';
import { RatingsRow } from 'components/FindProvider/ProviderDetailsBanner/styled';
import Stars from 'components/UI/Stars/Stars';
import SpecialtyProviderLocation from '../SpecialtyProviderLocation';
import { ProviderLocation, ProviderSearchFilters, ProviderSummary } from 'store/findProvider/types';

export interface Props {
  provider: ProviderSummary;
  providerId: string | number;
  displayName: string;
  imageUrl: string;
  specialties: DoctorSpecialty[];
  selectedSpecialty: Nullable<Specialty>;
  hasLocation: boolean;
  isSelectHealth: 'Y' | 'N' | null | undefined;
  onClick: (
    provider: ProviderSummary,
    appointmentLocation: AppointmentLocation,
    groupedVisitType: GroupedVisitType,
    slot: Nullable<Slots>,
    index: number
  ) => void;
  activeFilters: ProviderSearchFilters;
}

export const SpecialtyProviderPanel = ({
  provider,
  providerId,
  displayName,
  imageUrl,
  specialties,
  selectedSpecialty,
  hasLocation,
  isSelectHealth,
  onClick,
  activeFilters
}: Props) => {
  const history = useHistory();
  const { rating } = provider;

  const locations: ProviderLocation[] = [];
  provider.locations.map(location => {
    if (location.bookingData.length && location.bookingData.some(vt => !!vt.slots.length)) {
      locations.push(location);
    }
    return true;
  });

  const handleOnSelectSlot = (
    location: ProviderLocation,
    visitType: VisitTypeNpv,
    slot: Nullable<Slots>,
    index: number
  ) => {
    const groupedVisitType: GroupedVisitType = {
      synonymId: visitType.synonymId,
      visitTypeId: visitType.synonymId,
      name: visitType.synonymDisplay,
      description: visitType.synonymDisplay,
      visitTypes: [visitType],
      appointmentTypes: [visitType.appointmentType],
      stateLicenseList: visitType?.stateLicenseList
    };

    const appointmentLocation: AppointmentLocation = {
      locationId: visitType.bookingLocationId,
      locationName: location.name,
      phoneDisplay: location.phoneNumber,
      addressLine1: location.address.addressLine1,
      city: location.address.city,
      state: location.address.state,
      postalCode: location.address.postalCode,
      longitude: location.address.longitude?.toString(),
      latitude: location.address.latitude?.toString(),
      slots: visitType.slots
    };

    onClick(provider, appointmentLocation, groupedVisitType, slot, index);
  };

  return (
    <MuiGrid container spacing={1} alignItems="center">
      <MuiGrid item>
        <MuiBox>
          <LazyMuiAvatar
            src={imageUrl}
            style={{ width: IconSize.xxLarge, height: IconSize.xxLarge }}
          />
          {isSelectHealth ? (
            <SelectHealthIconWrapper align="right">
              <Svg set="assets" name="SelectHealth" size={IconSize.xmedium} />
            </SelectHealthIconWrapper>
          ) : null}
        </MuiBox>
      </MuiGrid>
      <MuiGrid container item xs alignItems="center">
        <MuiGrid item xs={12} md container direction="column">
          <MuiGrid item xs>
            <MuiTypography
              data-testid="provider-name"
              fontWeight={FontWeight.semibold}
              fontSize={FontSize.small}
            >
              {displayName}
            </MuiTypography>
            <MuiTypography
              data-testid="provider-specialty"
              fontSize={FontSize.small}
              style={{ marginTop: Spacing.xSmall }}
            >
              {specialties.map(e => e.specialtyName).join(', ')}
            </MuiTypography>
            <FlexBox flexDirection="row" alignItems="center" justifyContent="space-between">
              <RatingsRow
                display="flex"
                alignItems="center"
                alignContent="center"
                style={{ marginTop: Spacing.small }}
              >
                <Stars ratings={rating?.overallRating} />
              </RatingsRow>
              <MuiLink
                data-testid="view-details"
                onClick={(e: React.MouseEvent) => {
                  e.stopPropagation();
                  history.push(`/u/get-care-now/booking/provider-details/${providerId}`);
                }}
                style={{
                  lineHeight: '16px',
                  display: 'inline-flex',
                  marginTop: Spacing.small,
                  fontSize: FontSize.small
                }}
              >
                Full Profile
                <Svg
                  color={Color.secondary}
                  size={FontSize.xsmall}
                  name="ChevronRight"
                  style={{
                    marginTop: Spacing.xSmall
                  }}
                />
              </MuiLink>
            </FlexBox>
          </MuiGrid>
        </MuiGrid>
      </MuiGrid>

      {locations?.map((location, index) => (
        <SpecialtyProviderLocation
          key={location.id}
          location={location}
          onSelectSlot={handleOnSelectSlot}
          selectedSpecialty={selectedSpecialty}
          hasLocation={hasLocation}
          index={index}
          activeFilters={activeFilters}
        />
      ))}
    </MuiGrid>
  );
};

const mapStateToProps = (state: RootState) => ({
  provider: selectors.providerSelector(state),
  currentUrl: currentLocationPathNameSelector(state),
  referringUrl: previousLocationPathNameSelector(state)
});

export default connect(mapStateToProps)(SpecialtyProviderPanel);
