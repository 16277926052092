import { orFallback } from 'modules/utils/StringUtils';
import { fallback } from './constants';

function inactiveColor(color?: string) {
  return orFallback(color, fallback.inactiveColor);
}

function activeColor(color?: string) {
  return orFallback(color, fallback.activeColor);
}

function completedColor(color?: string) {
  return orFallback(color, fallback.completedColor);
}

export const generate = {
  inactiveColor,
  activeColor,
  completedColor
};
