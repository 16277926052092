import React from 'react';
import { Color } from 'modules/styles/colors';

const a = {
  fill: Color.purple
} as const;

export default {
  svg: (
    <g>
      <path {...a} d="M6,0h79c3.3,0,6,2.7,6,6v84L73,77H6c-3.3,0-6-2.7-6-6V6C0,2.7,2.7,0,6,0z" />
    </g>
  ),
  viewBox: '0 0 91 90'
};
