import { SearchBar } from 'components/AutoComplete';
import { Icon } from 'components/Icon';
import DataLoader from 'components/UI/DataLoader/DataLoader';
import Divider from 'components/UI/Divider';
import Box from 'components/UI/Layout/Box';
import FlexBox from 'components/UI/Layout/FlexBox';
import Typography from 'components/UI/Typography';
import debounce from 'lodash/debounce';
import { Color } from 'modules/styles/colors';
import { FontSize, FontWeight, IconSize, Spacing } from 'modules/styles/variables';
import React, { useState } from 'react';
import { ActivityIndicator, FlatList, TouchableOpacity } from 'react-native';
import { connect } from 'react-redux';
import { MY_CARE_TEAM, NO_CARE_TEAM_FOUND } from 'modules/constants/Provider';
import { MessagePool, ProviderSummary } from 'store/findProvider/types';
import {
  messageComposePoolSelected,
  messageComposeProviderSelected,
  searchMessagableProviders
} from 'store/messaging/actions.web';
import {
  messageFindProviderDataSelector,
  messageFindProviderErrorSelector,
  messageFindProviderIsFetchingSelector,
  suggestedMessagingProvidersSelector
} from 'store/messaging/selectors';
import { RootState } from 'store/types';
import { MuiBox, MuiList, MuiTypography } from 'theme/material-ui';
import { isLast } from 'modules/utils/ArrayUtils';
import { MessageComposeProps } from 'screens/Messaging/MessageHomeScreen';
import { SearchProviderBar, ProviderOptions, BgCurtain } from 'screens/Messaging/styled';
import { convertToLowerKabobCase } from 'modules/utils/StringUtils';

interface MessageComposeFindProviderProps {
  suggestedProviders: ProviderSummary[];
  data: MessagePool[];
  error: Error | null;
  isFetching: boolean;
  handleSelectProvider: typeof messageComposeProviderSelected;
  handleSelectPool: typeof messageComposePoolSelected;
  isOpen: boolean;
  onClose: (event?: Event) => void;
  paramsMessage?: MessageComposeProps;
}

interface MessageFindProviderListItemProps {
  title: string;
  providerType?: string;
  onPress: () => void;
}

const MessageFindProviderListItem = ({
  title,
  onPress,
  providerType = ''
}: MessageFindProviderListItemProps) => {
  if (providerType === 'POOL') {
    providerType = 'Provider Pool';
  }

  return (
    <TouchableOpacity onPress={onPress}>
      <FlexBox
        data-testid={convertToLowerKabobCase(title, '-list-item')}
        flexDirection="row"
        justifyContent="space-between"
        spacing={12}
      >
        <FlexBox hOffset={Spacing.small} hOffsetRight="auto" flex={1}>
          <Typography fontWeight={FontWeight.semibold} numberOfLines={1}>
            {title}
          </Typography>
          <Typography fontSize={FontSize.small} numberOfLines={1}>
            {providerType}
          </Typography>
        </FlexBox>
        <FlexBox justifyContent="center" alignItems="center">
          <Icon name="keyboard-arrow-right" size={IconSize.base} color={Color.gray} />
        </FlexBox>
      </FlexBox>
    </TouchableOpacity>
  );
};

const debouncer = debounce(cb => cb(), 200);

const MessageComposeFindProvider = (props: MessageComposeFindProviderProps) => {
  const [search, setSearch] = useState('');

  const handleClose = (event: Event) => {
    props.onClose(event);
  };

  const handlePoolSelected = (pool: MessagePool) => {
    props.handleSelectPool(pool);
    props.onClose();
  };

  const handleProviderSelected = (provider: ProviderSummary) => {
    props.handleSelectProvider(provider, props.paramsMessage);
    props.onClose();
  };

  const handleTextChange = (v: string) => {
    setSearch(v);
    debouncer(() => props.searchMessagableProviders(v));
  };

  return (
    <MuiBox flex={1}>
      <SearchProviderBar data-testid="search-input">
        <SearchBar
          fullWidth
          value={search}
          placeholder="Search Provider Name or Location"
          onClear={() => setSearch('')}
          onChange={e => handleTextChange(e.target.value)}
        />
      </SearchProviderBar>
      {props.isOpen ? (
        <>
          <BgCurtain onClick={handleClose} />
          <ProviderOptions elevation={5}>
            {search.length ? (
              <DataLoader
                data={props.data}
                error={props.error}
                loading={props.isFetching}
                renderData={() => (
                  <MuiList>
                    {props.data.map((item, idx, arr) => (
                      <>
                        <MessageFindProviderListItem
                          key={item.npi}
                          providerType={item.poolTypeName}
                          title={item.groupDisplayName}
                          onPress={() => handlePoolSelected(item)}
                        />
                        {!isLast(arr, idx) ? <Divider /> : null}
                      </>
                    ))}
                  </MuiList>
                )}
                renderLoading={() => (
                  <MuiBox p={2}>
                    <ActivityIndicator></ActivityIndicator>
                  </MuiBox>
                )}
                renderError={() => (
                  <MuiBox p={2}>
                    <MuiTypography>There was an issue searching providers</MuiTypography>
                  </MuiBox>
                )}
                renderNoData={() => (
                  <MuiBox p={2}>
                    <MuiTypography align="center">No Results Found</MuiTypography>
                  </MuiBox>
                )}
              />
            ) : (
              <FlatList
                data={props.suggestedProviders}
                keyExtractor={item => `${item.npi}`}
                ItemSeparatorComponent={() => <Divider />}
                ListHeaderComponent={() => (
                  <Box spacing={Spacing.large}>
                    <Typography fontSize={FontSize.large}>{MY_CARE_TEAM}</Typography>
                  </Box>
                )}
                ListEmptyComponent={() => (
                  <Box spacing={Spacing.large}>
                    <Typography textAlign="center">{NO_CARE_TEAM_FOUND}</Typography>
                  </Box>
                )}
                renderItem={({ item }) => (
                  <MessageFindProviderListItem
                    title={item.displayName}
                    onPress={() => handleProviderSelected(item)}
                  />
                )}
              />
            )}
          </ProviderOptions>
        </>
      ) : null}
    </MuiBox>
  );
};

const mapStateToProps = (state: RootState) => ({
  suggestedProviders: suggestedMessagingProvidersSelector(state),
  isFetching: messageFindProviderIsFetchingSelector(state),
  data: messageFindProviderDataSelector(state),
  error: messageFindProviderErrorSelector(state)
});

const mapDispatchToProps = {
  handleSelectProvider: messageComposeProviderSelected,
  handleSelectPool: messageComposePoolSelected,
  searchMessagableProviders
};

export default connect(mapStateToProps, mapDispatchToProps)(MessageComposeFindProvider);
