import { Divider } from '@material-ui/core';
import FlexBox from 'components/UI/Layout/FlexBox';
import Typography from 'components/UI/Typography';
import CollapsibleList from 'components/common/CollapsibleList/CollapsibleList';
import CollapsibleListItem from 'components/common/CollapsibleList/CollapsibleListItem';
import {
  COLLECTIONS_NUMBER_CBE,
  COLLECTIONS_NUMBER_IC,
  GUARANTOR_VERIFICATION_PHONE_NUMBER,
  LEGACY_BILLPAY_URL
} from 'modules/constants/Billing';
import { Color } from 'modules/styles/colors';
import { Spacing } from 'modules/styles/variables';
import { AppName, CompanyName, getAppName } from 'modules/utils/ConfigUtils';
import React, { ComponentType } from 'react';
import { useHistory } from 'react-router-dom';
import LinkingServices from 'services/LinkingServices';
import { MuiButton, MuiContainer } from 'theme/material-ui';
import VisitPayWebView from './VisitPayWebView';
import { VISIT_PAY_HOME, VISIT_PAY_SUPPORT } from './constants';
import { BorderMuiBox } from './styled';

export const BillingHelpCenter = () => {
  const history = useHistory();
  const [goToVisitPaySupport, setGoToVisitPaySupport] = React.useState(false);
  const [goToEntriPay, setGoToEntriPay] = React.useState(false);

  const goToBillingSupport = () => {
    setGoToVisitPaySupport(true);
  };

  const goToProfile = () => {
    history.push('/u/manage-account');
  };

  const goToLegacyPayment = () => {
    window.open(LEGACY_BILLPAY_URL, '_blank');
  };

  const goToManageBills = () => {
    setGoToEntriPay(true);
  };

  interface FAQListItemInterface {
    useDivider?: boolean;
    title: string;
    children: ReactElement;
    testID?: string;
  }

  const FAQListItem = ({ useDivider = true, title, children, testID }: FAQListItemInterface) => {
    return (
      <>
        {useDivider ? <Divider /> : null}
        <CollapsibleListItem title={title} testID={testID}>
          <FlexBox
            vSpacing={Spacing.mediumLarge}
            hSpacing={Spacing.large}
            backgroundColor={Color.white}
          >
            {children}
          </FlexBox>
        </CollapsibleListItem>
      </>
    );
  };

  return (
    <>
      <BorderMuiBox display="flex" flexDirection="column" paddingTop={Spacing.small}>
        <MuiContainer maxWidth="lg">
          <FlexBox alignItems="center">
            <MuiButton
              variant="contained"
              color="primary"
              style={{ whiteSpace: 'nowrap' }}
              onClick={goToBillingSupport}
              data-testid="ask-a-question"
            >
              {goToVisitPaySupport && (
                <VisitPayWebView closePopup={setGoToVisitPaySupport} uri={VISIT_PAY_SUPPORT} />
              )}
              Ask a question or view your billing inquiries
            </MuiButton>
          </FlexBox>
          <Divider style={{ padding: '2px', marginTop: Spacing.xLargeLarge }}></Divider>
          <Typography
            tag="h3"
            color={Color.primary}
            style={{ paddingTop: Spacing.xLargeLarge, paddingBottom: Spacing.xLarge }}
          >
            FAQS
          </Typography>
          <CollapsibleList>
            <FAQListItem
              useDivider={false}
              title="Why can't I see my minor child's (under 18 years of age) profile?"
              testID="proxy-acct-management"
            >
              <>
                <Typography>
                  To add your child as a proxy, please go to{' '}
                  <Typography
                    tag="link"
                    onClick={goToProfile}
                    data-testid="go-to-profile-1"
                    display="inline"
                  >
                    Account Management.
                  </Typography>{' '}
                  Under "Account Sharing," go to "People I have access to." Click "Request access to
                  another account."
                </Typography>
              </>
            </FAQListItem>
            <FAQListItem title="I have added my child (under 18 years of age) to my account, but still cannot see their bill.">
              <>
                <Typography>
                  You must be the guarantor to see your child's bill after you have gained access to
                  their profile. To ensure you are the guarantor please call:{' '}
                  <Typography
                    display="inline"
                    tag="link"
                    onClick={() =>
                      LinkingServices.callPhoneNumber(GUARANTOR_VERIFICATION_PHONE_NUMBER)
                    }
                  >
                    {GUARANTOR_VERIFICATION_PHONE_NUMBER}
                  </Typography>
                  . If you were not the guarantor at time of service, you will not see your child's
                  bill. Only the person who was marked guarantor at the time of service will be able
                  to see the bill. If you call to change yourself to the guarantor, you will still
                  not be able to see bills for the prior service. You will only see future bills.
                </Typography>
              </>
            </FAQListItem>
            <FAQListItem title="What is a guarantor?">
              <>
                <Typography>
                  The guarantor is the person who is legally responsible to make sure the bill is
                  paid. In most cases an adult who receives care is their own guarantor. In the case
                  of a minor receiving care the guarantor is typically a parent or guardian. There
                  is only one guarantor per bill and different bills for the minor may have
                  different guarantors.
                </Typography>
              </>
            </FAQListItem>
            <FAQListItem
              testID="legacy-drawer"
              title="How do I pay bill(s) for a person over the age of 18 years old?"
            >
              <>
                <Typography>
                  Click{' '}
                  <Typography
                    tag="link"
                    onClick={goToLegacyPayment}
                    display="inline"
                    data-testid="go-to-legacy"
                  >
                    here{' '}
                  </Typography>
                  to pay the bill. You will need to have the patient's account number, last name,
                  and date of birth.
                </Typography>
              </>
            </FAQListItem>
            <FAQListItem
              title="How do I request to manage bill(s) for someone who is over the age of 18 years old?"
              testID="manage-adult-bills-drawer"
            >
              <>
                <Typography>
                  Go to{' '}
                  <Typography
                    display="inline"
                    tag="link"
                    onClick={goToProfile}
                    data-testid="go-to-profile"
                  >
                    Account Management.
                  </Typography>{' '}
                  Under "Personal information" select "Manage Family Finances." Click on "Manage
                  Family." Here you can select "Request to be managed" or "Request to manage another
                  account." If you select "Request to manage another account" the person you are
                  requesting to manage will need to sign into the {getAppName(AppName.secondary)} to
                  approve the request to be managed.
                </Typography>
              </>
            </FAQListItem>
            <FAQListItem
              title="How do I pay bill(s) I am signed up to manage?"
              testID="go-to-entripay"
            >
              <>
                <Typography>
                  Click{' '}
                  <Typography
                    tag="link"
                    onClick={goToManageBills}
                    display="inline"
                    data-testid="entripay"
                  >
                    {goToEntriPay && (
                      <VisitPayWebView closePopup={setGoToEntriPay} uri={VISIT_PAY_HOME} />
                    )}
                    here{' '}
                  </Typography>
                  to pay bill for those who you manage.
                </Typography>
              </>
            </FAQListItem>
            <FAQListItem
              title={`Why am I not seeing all my bills in the ${getAppName(AppName.secondary)}?`}
            >
              <>
                <Typography>
                  Bills for Homecare, Central Labs, and Intermountain Payment Plans will not appear
                  in the {getAppName(AppName.secondary)}. You may receive additional bills not
                  shown.
                </Typography>
              </>
            </FAQListItem>
            <FAQListItem title="What if my bill is in collections?">
              <>
                <p>
                  <Typography>
                    {CompanyName} uses two collection companies. Please call the below phone to see
                    which collection company holds your bill(s).
                  </Typography>
                </p>
                <p>
                  <Typography>CBE Group - Waterloo, IA </Typography>{' '}
                  <Typography
                    display="inline"
                    tag="link"
                    onClick={() =>
                      LinkingServices.callPhoneNumber(GUARANTOR_VERIFICATION_PHONE_NUMBER)
                    }
                  >
                    {' '}
                    {COLLECTIONS_NUMBER_CBE}{' '}
                  </Typography>
                </p>
                <Typography> IC System - St Paul, MN </Typography>{' '}
                <Typography
                  display="inline"
                  tag="link"
                  onClick={() =>
                    LinkingServices.callPhoneNumber(GUARANTOR_VERIFICATION_PHONE_NUMBER)
                  }
                >
                  {' '}
                  {COLLECTIONS_NUMBER_IC}
                </Typography>
              </>
            </FAQListItem>
            <FAQListItem title="How do I see my explanation of benefits (EOB)?">
              <>
                <Typography>
                  Currently only Select Health members can see their EOBs in the{' '}
                  {getAppName(AppName.secondary)}. To find this, click on your health record. Click
                  on the visit summary for which you would like to see your EOB. Then scroll down to
                  "Explanation of Benefits."
                </Typography>
              </>
            </FAQListItem>
            <FAQListItem title="How do I see the details of my visit from my bill?">
              <>
                <Typography>
                  On each bill detail page there is a Visit Summary hyperlink you can click on to
                  see your visit information. This will only be seen on the logged in user's
                  bill(s). Switch to the minor or managed person's account and go to visit summary.
                </Typography>
              </>
            </FAQListItem>
            <FAQListItem title="How do I see an itemized bill?">
              <>
                <Typography>
                  Go to the bills section and click "View All". Click on the bill you would like to
                  see itemized information for. On the bill detail page, you will see a "View
                  Itemized Charges" section. Click on the plus sign to display all your itemized
                  charges for your bill. Here you will also see any billing codes assigned to these
                  itemized charges.
                </Typography>
              </>
            </FAQListItem>
          </CollapsibleList>
        </MuiContainer>
      </BorderMuiBox>
    </>
  );
};

export default BillingHelpCenter as ComponentType;
