import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Appointment } from 'store/amwell/types';
import * as selectors from 'store/amwell/selectors';
import { RootState } from 'store/types';

import {
  MuiBox,
  MuiGrid,
  MuiTypography,
  MuiDivider,
  MuiContainer,
  MuiRating,
  MuiAvatar
} from 'theme/material-ui';

import { Color } from 'modules/styles/colors';
import { FontSize, FontWeight } from 'modules/styles/variables';

const Avatar = styled(MuiAvatar)`
  && {
    height: 160px;
    width: 160px;
  }
`;

const Rating = styled(MuiRating)`
  && {
    & .MuiRating-iconFilled {
      color: ${Color.white};
    }
  }
`;

const Divider = styled(MuiDivider)`
  && {
    background-color: ${Color.white};
  }
`;

const FlexGrid = styled(MuiGrid)`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 1 1 auto;
`;

export interface Props {
  appointment: Appointment | null;
}

export function AppointmentDetailsBanner(props: Props) {
  const { appointment } = props;

  if (!appointment) return null;

  const { assignedProvider, cost } = appointment;

  return (
    <MuiBox width="100%">
      <MuiBox mb={2}>
        <MuiTypography color={Color.white} variant="h4" gutterBottom>
          {assignedProvider.fullName}
        </MuiTypography>
      </MuiBox>
      <MuiContainer maxWidth="md">
        <FlexGrid container spacing={2}>
          <MuiGrid item>
            <Avatar alt={assignedProvider.fullName} src={assignedProvider.logoUrl} />
          </MuiGrid>
          <MuiGrid item xs>
            <MuiTypography fontSize={18} color={Color.white} gutterBottom variant="body1">
              {assignedProvider.specialty.value}
            </MuiTypography>
            <MuiBox py={1}>
              <Divider />
            </MuiBox>
            <Rating name="read-only" value={assignedProvider.rating} readOnly />
          </MuiGrid>
          <MuiGrid item>
            <MuiBox
              p={3}
              height="100%"
              bgcolor={Color.white}
              borderRadius={3}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <MuiTypography color={Color.black} fontWeight={FontWeight.normal} variant="h6">
                Visit Cost
              </MuiTypography>
              <MuiBox my={1}>
                <MuiTypography color={Color.black} variant="h3">
                  {cost ? `$${Number(cost.expectedConsumerCopayCost.toFixed(2))}` : 'N/A'}
                </MuiTypography>
              </MuiBox>
              <MuiTypography color={Color.black} fontSize={FontSize.base} variant="body1">
                (This may be covered by insurance)
              </MuiTypography>
            </MuiBox>
          </MuiGrid>
        </FlexGrid>
      </MuiContainer>
    </MuiBox>
  );
}

const mapStateToProps = (state: RootState) => ({
  appointment: selectors.appointmentDataSelector(state)
});

export default connect(mapStateToProps)(AppointmentDetailsBanner);
