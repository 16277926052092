import React from 'react';
import { MuiBox, MuiButton, MuiTypography } from 'theme/material-ui';

interface ErrorMessageProps {
  message: string;
  action: {
    text: string;
    onClick: () => void;
  };
}

const ErrorMessage = ({ message, action }: ErrorMessageProps) => {
  return (
    <MuiBox
      m={10}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <MuiTypography>{message}</MuiTypography>
      <MuiBox mt={2}>
        <MuiButton onClick={action.onClick} variant="contained" color="primary">
          {action.text}
        </MuiButton>
      </MuiBox>
    </MuiBox>
  );
};

export default ErrorMessage;
