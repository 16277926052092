import React, { ComponentType, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Alert } from 'components/Alert';
import Spinner from 'components/UI/Spinner/Spinner';
import { Confirm } from 'components/ConfirmDialog/ConfirmDialog';
import { MuiButton, MuiCardContent, MuiBox } from 'theme/material-ui';
import NavigateToProtectedRouteModal from 'components/UI/Modals/NavigateToProtectedRouteModal';

import { getCanSetProviderAsPcp } from 'lib/findProvider/utils';

import { IconSize } from 'modules/styles/variables';
import { providerSelectConstant } from 'modules/constants/booking';

import analyticsService, { AnalyticsEvent } from 'services/AnalyticsService';
import Svg from 'components/UI/Svg/Svg';

import { navigateToProtectedRoute } from 'store/authentication/actions';
import { RootState } from 'store/types';
import { ProviderDetail, ProviderSummary } from 'store/findProvider/types';
import { Consumer, Insurance, Member } from 'store/profile/types';
import * as doctorActions from 'store/myDoctors/actions';
import * as accountSelectors from 'store/account/selectors';
import * as bookingTypes from 'store/booking/types';
import * as bookingActions from 'store/booking/actions';
import * as bookingSelectors from 'store/booking/selectors';
import * as selectors from 'store/findProvider/selectors';
import * as profileSelectors from 'store/profile/selectors';
import {
  currentLocationPathNameSelector,
  previousLocationPathNameSelector
} from 'store/router/selectors';

import { ProviderActions } from './styled';
import { Color } from 'modules/styles/colors';
import { Icon } from 'components/Icon';

export interface Props {
  appointment: bookingTypes.AppointmentDetails;
  isAuthenticated: boolean;
  provider: ProviderDetail;
  isAcceptingOnlineBooking: boolean;
  consumerPastProviders: ProviderSummary[];
  setDoctor: typeof bookingActions.setDoctor;
  updatePcpProviderAndRefreshMyDoctors: typeof doctorActions.updatePcpProviderAndRefreshMyDoctors;
  hasOnlineNpvSlots: boolean;
  callClinicForNPV: boolean;
  isProviderPcpProvider: boolean;
  consumer: Consumer;
  currentUrl?: string;
  referringUrl?: string;
  insurances: Insurance[];
  getPatients: typeof bookingActions.getPatients;
  setPatient: typeof bookingActions.setPatient;
  patients: bookingTypes.Patient[];
  patientsLoading: boolean;
  validateSelectedPatientInsurance: typeof bookingActions.validateSelectedPatientInsurance;
  navigateToProtectedRoute: typeof navigateToProtectedRoute;
}

export const ProviderBannerActions = ({
  appointment,
  isAuthenticated,
  provider,
  isAcceptingOnlineBooking,
  consumerPastProviders,
  isProviderPcpProvider,
  setDoctor,
  updatePcpProviderAndRefreshMyDoctors,
  consumer,
  insurances,
  getPatients,
  setPatient,
  patients,
  patientsLoading,
  validateSelectedPatientInsurance,
  currentUrl,
  referringUrl,
  navigateToProtectedRoute
}: Props) => {
  useEffect(() => {
    if (isAuthenticated) {
      getPatients();
    }
  }, [isAuthenticated]);

  const history = useHistory();

  const canSetProviderAsPcp = getCanSetProviderAsPcp(provider, consumer, insurances);

  const createMinimalProvider = () => {
    const minimalProvider: ProviderSummary = {
      corpProvId: provider.corpProvId,
      npi: provider.npi,
      cernerResourceId: provider.cernerResourceId,
      displayName: provider.displayName,
      providerProfileImageUrl: provider.providerProfileImageUrl,
      primarySpecialty: {
        specialtyName: provider?.primarySpecialty?.specialtyName,
        shortSpecialtyName: provider?.primarySpecialty?.shortSpecialtyName
      },
      hasOnlineNpvSlots: provider.hasOnlineNpvSlots,
      cernerPersonnelId: provider.cernerPersonnelId,
      callClinicForNPV: provider.callClinicForNPV
    };
    return minimalProvider;
  };

  const onBookingClick = () => {
    analyticsService.logEvent(AnalyticsEvent.BookAppointmentStarted, {
      currentUrl,
      referringUrl,
      isAuthenticated
    });
    if (!isAuthenticated) {
      const minimalProvider = JSON.stringify(createMinimalProvider());
      window.sessionStorage.setItem('provider', minimalProvider);
      return navigateToProtectedRoute('/u/get-care-now/booking/patient-select');
    }

    return onNavigateToBooking();
  };

  /** BOOK APPOINTMENT */
  const onNavigateToBooking = () => {
    // Create minimal provider object for booking
    const minimalProvider = createMinimalProvider();

    const bookingNoProxies = patients.length === 1 && isAuthenticated;
    const bookingHasPatient = appointment.patient;
    const isPastProvider = consumerPastProviders.some(
      pastProvider => pastProvider.corpProvId.toString() === provider.corpProvId
    );

    if (provider?.callClinicForNPV && !isPastProvider) {
      Confirm.show(
        `${provider?.displayName}, ${providerSelectConstant.NO_ONLINE_NPV_SLOTS.title}`,
        null,
        null,
        providerSelectConstant.NO_ONLINE_NPV_SLOTS.severity,
        { text: providerSelectConstant.CALL_TO_SCHEDULE },
        { text: 'Close' },
        { cancelable: false }
      );
    } else if (bookingNoProxies) {
      setPatient(patients[0]);
      validateSelectedPatientInsurance();
      setDoctor(minimalProvider);

      history.push('/u/get-care-now/booking/visit-type-select');
    } else if (bookingHasPatient) {
      setDoctor(minimalProvider);
      history.push('/u/get-care-now/booking/visit-type-select');
    } else {
      setDoctor(minimalProvider);
      history.push({
        pathname: '/u/get-care-now/booking/patient-select',
        state: {
          skipProviderSelect: true
        }
      });
    }
  };

  /** FAVORITE DOCTOR */

  const [updating, setUpdating] = useState(false);
  const [showPCPModal, setShowPCPModal] = useState(false);
  const [modalDescription, setModalDescription] = useState('');

  const showSaveAsPcp = isAuthenticated && canSetProviderAsPcp && !isProviderPcpProvider;
  const facetsInsurance = insurances.find(insurance => insurance.source === 'Facets');
  const { corpProvId, providerProfileVideoId } = provider;

  let insuranceMember: Member | undefined;

  if (facetsInsurance) {
    insuranceMember = facetsInsurance.members.find(
      member => member.consumerId === consumer.consumerId
    );
  }

  const update = async () => {
    setUpdating(true);
    setShowPCPModal(false);

    const { subscriberId, suffix } = insuranceMember as Member;

    const res = await updatePcpProviderAndRefreshMyDoctors(subscriberId, suffix, corpProvId);

    setUpdating(false);
    if (res.error) {
      setModalDescription(
        'Unable to update PCP Provider There was an issue updating your pcp provider'
      );
      return true;
    }

    setModalDescription('Successfully updated PCP Provider');
    return true;
  };

  const commonModalProps = {
    confirmLabel: 'Ok',
    onConfirm: () => {
      setModalDescription('');
    }
  };

  const onSetPcp = () => {
    if (!facetsInsurance || !insuranceMember) {
      setModalDescription('Unable to set as PCP \n Unable to find a valid insurance');
      return true;
    }

    const { subscriberId, suffix } = insuranceMember;

    if (!subscriberId || !suffix || !corpProvId) {
      setModalDescription('Unable to set as PCP \n  Information could not be verified');
      return true;
    }
    setShowPCPModal(true);
    return true;
  };

  const LoadingIndicator = () => {
    return (
      <MuiBox display="flex" alignItems="center" justifyContent="center">
        <Spinner />
      </MuiBox>
    );
  };

  const onViewVideo = (id: string) => {
    const providerVideoUrl = `https://www.youtube.com/embed/${id}`;
    window.open(providerVideoUrl, '_blank');

    analyticsService.logEvent(AnalyticsEvent.ProviderVideosViewed, {
      currentUrl,
      referringUrl
    });
  };

  if (showPCPModal) {
    Alert.alert(
      'Change PCP',
      'Assigning this provider as your PCP will remove your existing PCP provider, would you like to continue?',
      [{ text: 'Ok', onPress: () => update() }, { text: 'Cancel' }]
    );
  }

  if (modalDescription) {
    Alert.alert('', modalDescription, [
      { text: 'Ok', onPress: () => commonModalProps.onConfirm() }
    ]);
  }

  const scrollToLocations = () => {
    const locationSection = document.getElementById('provider-details-locations');
    locationSection?.scrollIntoView({ behavior: 'smooth' });

    analyticsService.logEvent(AnalyticsEvent.ProviderLocationsViewed, {
      referringUrl,
      currentUrl
    });
  };

  if (history.location.pathname.includes('book-appointment')) {
    if (!isAuthenticated) {
      const minimalProvider = JSON.stringify(createMinimalProvider());
      window.sessionStorage.setItem('provider', minimalProvider);
      window.sessionStorage.setItem('isExternalRequest', 'yes');
      navigateToProtectedRoute('/u/get-care-now/booking/patient-select');
    }
    onNavigateToBooking();
  }

  return (
    <>
      {updating && <LoadingIndicator />}
      <NavigateToProtectedRouteModal />
      <ProviderActions>
        <MuiCardContent>
          {(provider.primaryLocation || provider.locations.length > 0) && (
            <MuiBox mx={1} py={1}>
              <MuiButton
                variant="outlined"
                color="secondary"
                fullWidth
                size="large"
                onClick={scrollToLocations}
                disableElevation
                startIcon={<Svg name="DirectionIcon" color={Color.secondary} />}
                data-testid="provider-locations-button"
              >
                View Locations
              </MuiButton>
            </MuiBox>
          )}
          {provider.primaryLocation && provider.primaryLocation.phoneNumber && (
            <MuiBox mx={1} py={1}>
              <MuiButton
                variant="outlined"
                color="secondary"
                fullWidth
                size="large"
                disableElevation
                startIcon={<Svg name="PhoneCallThinEdges" />}
                data-testid="provider-phone-button"
              >
                Call {provider.primaryLocation.phoneNumber}
              </MuiButton>
            </MuiBox>
          )}
          {showSaveAsPcp && (
            <MuiBox mx={1} py={1}>
              <MuiButton
                variant="outlined"
                color="secondary"
                fullWidth
                size="large"
                onClick={onSetPcp}
                disableElevation
                startIcon={<Icon name="people" size={24} color={Color.secondary} />}
                data-testid="provider-pcp-button"
              >
                Save as PCP
              </MuiButton>
            </MuiBox>
          )}
          {providerProfileVideoId && (
            <MuiBox mx={1} py={1}>
              <MuiButton
                variant="outlined"
                color="secondary"
                fullWidth
                size="large"
                onClick={() => onViewVideo(providerProfileVideoId)}
                disableElevation
                startIcon={<Icon name="video-library" size={24} color={Color.secondary} />}
                data-testid="provider-video-button"
              >
                View provider video
              </MuiButton>
            </MuiBox>
          )}
          {isAcceptingOnlineBooking && (
            <MuiBox mx={1} py={1}>
              <MuiButton
                variant="contained"
                color="primary"
                fullWidth
                onClick={onBookingClick}
                disableElevation
                disabled={patientsLoading}
                endIcon={patientsLoading ? <Spinner size={IconSize.small} /> : null}
                data-testid="provider-book-appointment-button"
              >
                {referringUrl?.includes('/u/get-care-now/booking')
                  ? 'Continue booking'
                  : 'Book an appointment'}
              </MuiButton>
            </MuiBox>
          )}
        </MuiCardContent>
      </ProviderActions>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  appointment: bookingSelectors.appointmentDetailsSelector(state),
  isProviderPcpProvider: selectors.isProviderPcpProviderSelector(state),
  isAcceptingOnlineBooking: selectors.providerIsAcceptingOnlineBookingSelector(state),
  consumerPastProviders: selectors.selectedConsumerPastDoctorsSelector(state),
  hasOnlineNpvSlots: selectors.providerHasOnlineNpvSlotsSelector(state),
  callClinicForNPV: selectors.callClinicForNPVSelector(state),
  consumer: accountSelectors.currentAccountConsumerSelector(state),
  currentUrl: currentLocationPathNameSelector(state),
  referringUrl: previousLocationPathNameSelector(state),
  insurances: profileSelectors.currentConsumerInsurancesSelector(state),
  patients: bookingSelectors.patientsDataSelector(state),
  patientsLoading: bookingSelectors.patientsFetchingSelector(state)
});

const mapDispatchToProps = (dispatch: Function) => ({
  setDoctor: (doctor: ProviderSummary) => dispatch(bookingActions.setDoctor(doctor)),
  getPatients: () => dispatch(bookingActions.getPatients()),
  setPatient: (patient: bookingTypes.Patient) => dispatch(bookingActions.setPatient(patient)),
  validateSelectedPatientInsurance: () =>
    dispatch(bookingActions.validateSelectedPatientInsurance()),
  updatePcpProviderAndRefreshMyDoctors: (
    subscriberId: string,
    suffix: string,
    corpProvId: string
  ) =>
    dispatch(doctorActions.updatePcpProviderAndRefreshMyDoctors(subscriberId, suffix, corpProvId)),
  navigateToProtectedRoute: (route: string) => dispatch(navigateToProtectedRoute(route))
});

export default connect(mapStateToProps, mapDispatchToProps)(ProviderBannerActions as ComponentType);
