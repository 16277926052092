import React from 'react';
import dayjs from 'dayjs';
import get from 'lodash/get';
import Config from 'react-native-config';
import SummaryCard from 'components/UI/SummaryCard/SummaryCard';
import { formatDate } from 'modules/utils/DateUtils';
import { VisitSummary } from 'store/visitSummary/types';

export interface Props {
  visit: VisitSummary;
  onClick: () => void;
  onChecked: () => void;
  enableCheckbox?: boolean;
}

const displayDate = (date: string) => {
  const currentDate = dayjs().startOf('day');
  const prevDate = dayjs(date).startOf('day');
  const dayDifference = currentDate.diff(prevDate, 'day');

  if (dayDifference <= 7) {
    if (dayDifference === 0) return 'Today';
    if (dayDifference === 1) return `${dayDifference} day ago`;
    return `${dayDifference} days ago`;
  }

  return formatDate(prevDate.toISOString());
};

const getBodyText = (complaint: string) => (complaint ? `Reason for visit: ${complaint}` : '');

const mapVisitsToCardProps = (visit: VisitSummary) => {
  const { primaryProvider, locationObject } = visit;
  const { providerName, isSelectHealth, providerImageUrl } = primaryProvider || {
    isSelectHealth: null,
    providerImageUrl: null,
    providerName: null
  };
  const eVisitText =
    Config.EVISITS === 'enabled' && locationObject?.locationName.includes('CCCL_EVisit');
  const locationText = eVisitText ? 'E-Visit appointment' : locationObject?.locationName;
  const finishedEVisitDescriptionText =
    visit.status === 'finished' && eVisitText
      ? `${visit.visitDescription} E-Visit`
      : visit.visitDescription;
  const visitDescription =
    Config.EVISITS === 'enabled' && visit.status === 'in-progress'
      ? 'E-Visit is under review'
      : finishedEVisitDescriptionText;

  return {
    heading: visit.date ? displayDate(visit.date) : 'Date Unknown',
    headingTag: visit.visitType || '',
    title: visitDescription || '',
    subTitle: providerName || '',
    subTitleIcon: isSelectHealth ? 'SelectHealth' : false,
    subHeading: locationText || '',
    avatarUrl: providerImageUrl ?? '',
    bodyText: getBodyText(get(visit, 'chiefComplaint.valueString', '')),
    status: visit.status,
    isSelected: visit.isSelected
  };
};

export const VisitSummaryCard = React.memo(
  ({ visit, onClick, onChecked, enableCheckbox }: Props) => {
    return (
      <div onClick={onClick}>
        <SummaryCard
          card={mapVisitsToCardProps(visit)}
          onChecked={onChecked}
          enableCheckbox={enableCheckbox}
        />
      </div>
    );
  }
);
