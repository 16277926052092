export const COST_ESTIMATOR_PATHS = {
  ROOT: '/u/estimate-costs',
  PMMC: '/u/estimate-costs/cost-estimator-pmmc',
  FIND_SERVICES: '/u/estimate-costs/find-services',
  PROVIDERS: '/u/estimate-costs/providers',
  PROVIDERS_DETAIL: '/u/estimate-costs/providers/detail'
};

export const COST_ESTIMATOR_CONFIRM_DIALOG_TEXT = {
  title: 'Are you sure you want to cancel?',
  subtitle: 'Your information will be lost.',
  primaryButton: 'Yes, cancel estimation',
  secondaryButton: 'No, take me back'
};

export const estimateCostsText =
  'Quickly estimate the cost of common services and procedures based on your location and plan coverage by browsing your available services.';

export const insuranceNotFoundText =
  'Sorry, we’re having trouble locating your insurance information. Please try again later.';
