import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { FlatList } from 'react-native';
import { Color } from 'modules/styles/colors';
import { DUE_STATUS_TYPE, DueStatusKey } from 'modules/constants/Billing';
import { FontSize, FontWeight } from 'modules/styles/variables';
import { formatDate, formatMMDDYYYYDate } from 'modules/utils/DateUtils';
import analyticsService, { AmplitudeEventData, AnalyticsEvent } from 'services/AnalyticsService';
import {
  currentLocationPathNameSelector,
  previousLocationPathNameSelector
} from 'store/router/selectors';
import CollapsibleList from 'components/common/CollapsibleList/CollapsibleList';
import CollapsibleListHeader from 'components/common/CollapsibleList/CollapsibleListHeader';
import DefaultText from 'components/UI/Typography/DefaultText';
import Divider from 'components/UI/Divider';
import FlexBox, { FlexBoxRow, FlexBoxColumn } from 'components/UI/Layout/FlexBox';
import isNil from 'lodash/isNil';
import ScrollableLayout from 'components/UI/Layout/ScrollableLayout';
import Spacer from 'components/UI/Layout/Spacer';
import Typography from 'components/UI/Typography';
import Screen from 'components/UI/Layout/Screen';
import formatMoney from 'services/formatMoney';
import { consumerIdSelector } from 'store/profile/selectors';
import * as snacksActions from 'store/snacks/actions';
import {
  DETAIL_DOWNLOAD_SUCCESS,
  downloadBillingDetail,
  getEncounterDetail,
  downloadBillingDetailToStore
} from 'store/billing/actions';
import { Snack, SnackType } from 'store/snacks/types';
import { RootState } from 'store/types';
import {
  BillDetailBorderFlex,
  BillDetailPanel,
  BillingDetailMarginView,
  BillingDetailMarginViewSummary,
  CenteredTypography,
  DueNowHeading,
  EmptyFlexBox,
  GreyTypography,
  StyledHeading,
  ViewDrillDownFlexBox,
  BillContainer,
  BillDetailRightPanel,
  BottomDivider,
  BottomDividerContainer,
  RedText,
  BigMoney,
  BillDetailRightPanelTop,
  BillingButton
} from '../styled';
import { NavigationScreenProps, Match } from 'screens/navigation';
import { getGlossary } from 'store/glossary/actions';
import { GlossaryItem } from 'store/glossary/reducers';
import ChargeGroup from '../BillingDetailScreen/ChargeGroup';
import ClaimsGroup from '../BillingDetailScreen/ClaimsGroup';
import { InfoButton } from 'components/InfoButton';
import { getGlossaries, getItemByTerm } from 'store/glossary/selectors';
import SpinnerModal from 'components/UI/Spinner/SpinnerModal';
import VisitPayWebView from 'screens/Billing/VisitPayWebView';
import Config from 'react-native-config';
import { renderBadDebt } from 'components/billing/BadDebt';
import { ACCOUNT_NUMBER_PREFIX, VISIT_PAY_HOME, VISIT_PAY_SUPPORT } from '../constants';
import { MuiButton, MuiGrid, MuiTypography, MuiLink } from 'theme/material-ui';
import CallToActionRow from 'components/common/CallToActionRow/CallToActionRow';
import MessageComposeWidget from 'components/Messaging/MessageComposeWidget';
import {
  detailDownloadFetchingSelector,
  getEncounterDetailDataSelector,
  getEncounterDetailErrorSelector,
  getEncounterDetailFetchingSelector,
  detailDownloadDataSelector
} from 'store/billing/selectors';
import {
  IseriesPayments as EncounterPayments,
  EncounterDetail,
  IseriesPaymentsDetails
} from 'store/billing/types';
import { Alert } from 'components/Alert';
import { Action } from 'modules/types/common';
import { v4 as uuidv4 } from 'uuid';
import { Base64Attachment } from 'store/messaging/types';

interface Props extends NavigationScreenProps {
  createSnack: typeof snacksActions.createSnack;
  consumerId: string;
  glossary: GlossaryItem[];
  match: Match<{ eaid: string }>;
  currentUrl?: string;
  referringUrl?: string;
  isDownloading: boolean;
  handleDownloadBilling: (encounterID: string) => Promise<Action>;
  encounter: EncounterDetail;
  loading: boolean;
  encounterError: Error;
  handleShareDetail: (encounterID: string) => Promise<Action>;
  pdfData: string | { encounterId: string; base64: string };
}

interface ItemBody {
  left: string;
  right: number | undefined | null;
}

export const BillingDetail = (props: Props) => {
  const {
    createSnack,
    glossary,
    consumerId,
    currentUrl,
    referringUrl,
    isDownloading,
    handleDownloadBilling,
    encounter,
    loading,
    encounterError,
    handleShareDetail,
    pdfData
  } = props;
  const eventData: AmplitudeEventData = {
    currentUrl,
    referringUrl
  };
  const dispatch = useDispatch();
  const [goToVisitPaySupport, setGoToVisitPaySupport] = React.useState(false);
  const [goToVisitPay, setGoToVisitPay] = React.useState(false);
  const [definitions, setDefinitions] = useState([] as GlossaryItem[]);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [messageUUID, setMessageUUID] = useState('');
  const { eaid } = props.match.params;

  const showVisitpay = () => {
    analyticsService.logEvent(AnalyticsEvent.ProceedWithPaymentClicked, eventData);
    setGoToVisitPay(true);
  };

  const handleDownload = async () => {
    const action = await handleDownloadBilling(eaid);
    if (action.type === DETAIL_DOWNLOAD_SUCCESS) {
      Alert.alert('Success', 'Download complete');
    } else {
      Alert.alert('Error', 'Download Failed. Please try again later.');
    }
  };

  const handleShare = () => {
    setShowMessageModal(true);
    if (!pdfData || typeof pdfData === 'string' || pdfData?.encounterId !== eaid) {
      handleShareDetail(eaid);
    }
  };

  const handleShowSuccessAlert = (providerName: string) => {
    Alert.alert(`Success`, `Message has been sent to ${providerName}`);
  };
  useEffect(() => {
    setMessageUUID(uuidv4());
    dispatch(getEncounterDetail(eaid));
  }, []);
  useEffect(() => {
    if (encounterError && currentUrl === 'BillingDetail') {
      createSnack(SnackType.BASE, {
        label: 'Unable to load data, please try again'
      });
    }
  }, [currentUrl, encounterError]);

  useEffect(() => {
    if (!glossary || !glossary.length) {
      dispatch(getGlossary());
    } else {
      const def: GlossaryItem[] = [
        getItemByTerm(glossary, 'Adjustments'),
        getItemByTerm(glossary, 'Billing Statement'),
        getItemByTerm(glossary, 'Financial Assistance'),
        getItemByTerm(glossary, 'Total Charges'),
        getItemByTerm(glossary, 'What you already paid'),
        getItemByTerm(glossary, 'What you owe'),
        getItemByTerm(glossary, 'What your insurance covered')
      ];
      setDefinitions(def);
    }
  }, [glossary]);

  const charges = encounter?.charges || [];
  const totalCharges = encounter?.totalCharges;
  const dueStatus = (encounter?.dueStatus as unknown) as DueStatusKey;
  const inCollections = encounter?.inCollections;
  const paymentAdjustment = encounter?.iseriesPayments || [];
  const claims = encounter?.claims || [];
  const interestBalance = encounter?.interestBalance;
  const cernerEncounterId = encounter?.cernerEncounterId;
  const balance = encounter?.balance || 0;
  const totalPlanPaid = encounter?.totalPlanPaid || 0;
  const paymentPlan = !!encounter?.paymentPlan;
  const principleBalance = encounter?.principleBalance || 0;
  const pendingAmount = paymentPlan ? true : Math.round(principleBalance) > 0;
  const fullAccountNumber = `${encounter?.facilityId}-${encounter?.accountNumber}`;
  const accountNumber = `${ACCOUNT_NUMBER_PREFIX}-${encounter?.guarantor.id || ''}`;
  const admitDate = encounter?.admitDate || '';
  const dischargeDate = encounter?.dischargeDate || '';
  const firstName = encounter?.patient.firstName || '';
  const lastName = encounter?.patient.lastName || '';
  const billingSummaryData = [
    { left: 'Total charges:', right: totalCharges },
    {
      left: 'What your insurance covered:',
      right: totalPlanPaid
    },
    { left: 'Adjustments:', right: encounter?.totalAdjustments },
    {
      left: 'Financial assistance:',
      right: encounter?.totalFinancialAssistance
    },
    { left: 'What you already paid:', right: encounter?.totalPayments },
    { left: 'What you owe:', right: balance }
  ];
  if (interestBalance) {
    billingSummaryData.splice(1, 0, {
      left: 'Interest:',
      right: interestBalance
    });
  }

  const openHelpWebView = () => {
    analyticsService.logEvent(AnalyticsEvent.AskBillingQuestionClicked, eventData);
    setGoToVisitPaySupport(true);
  };

  const navigateToVisitSummary = (encounter: EncounterDetail) => {
    // Note that we use FHIR in the url being built as this is expected to always be the source
    props.history.push(
      `/u/health-record/visit-summaries/live/fhir/${encounter.cernerEncounterId}/${encounter.dischargeDate}`
    );
  };

  const navigateToFinancialAssistance = () => {
    props.history.push('/u/fa-app');
  };

  const triggerSurvey = () => createSnack(SnackType.SURVEY);

  const renderProceedPayment = () => {
    return (
      <>
        <BillingDetailMarginViewSummary>
          <FlexBoxColumn alignItems="center">
            <BillDetailBorderFlex>
              <DefaultText center accessibilityLabel="Total Owed Header">
                You owe
              </DefaultText>
              <StyledHeading adjustsFontSizeToFit accessibilityLabel="Total Owed Amt">
                {`$${formatMoney(balance)}`}
              </StyledHeading>
              {!!dueStatus && (
                <DueNowHeading center accessibilityLabel="Due Status">
                  {inCollections ? 'In Collections' : DUE_STATUS_TYPE[dueStatus]}
                </DueNowHeading>
              )}
            </BillDetailBorderFlex>
            <FlexBoxColumn width="280px">
              {inCollections ? null : (
                <MuiButton
                  data-testid="proceed-with-payment-button-bottom"
                  onClick={showVisitpay}
                  variant="contained"
                  color="primary"
                >
                  <DefaultText color={Color.white}>Proceed with Payment</DefaultText>
                </MuiButton>
              )}
              {Config.FA_APP === 'enabled' ? (
                <>
                  <Spacer size="small" />
                  <MuiButton
                    variant="outlined"
                    onClick={navigateToFinancialAssistance}
                    color="primary"
                  >
                    <DefaultText color={Color.secondary}>
                      Apply for Financial Assistance
                    </DefaultText>
                  </MuiButton>
                </>
              ) : null}
            </FlexBoxColumn>
          </FlexBoxColumn>
        </BillingDetailMarginViewSummary>
      </>
    );
  };

  const renderPaymentsAdjustment = (eachPayment: EncounterPayments) => {
    const propertyMappings = [
      { key: 'amount', sources: ['amount', 'PAYMENTAMOUNT'] },
      { key: 'date', sources: ['date', 'PAYMENTDATE'] },
      { key: 'description', sources: ['description'] },
      { key: 'payerFirstName', sources: ['payerFirstName', 'PAYORFIRSTNAME'] },
      { key: 'payerLastName', sources: ['payerLastName', 'PAYORLASTNAME'] }
    ];
    const extractedData: IseriesPaymentsDetails = {};
    propertyMappings.forEach(mapping => {
      const value = mapping.sources
        .map(sourceKey => eachPayment[sourceKey])
        .find(value => value !== undefined);
      extractedData[mapping.key] = value;
    });

    return (
      <>
        <ViewDrillDownFlexBox>
          <FlexBox
            accessibilityLabel="Tap to view payments and adjustments"
            flexDirection="row"
            alignItems="flex-start"
            justifyContent="space-between"
          >
            <Typography numberOfLines={1} fontWeight={FontWeight.bold}>
              {extractedData?.date ? `${formatDate(extractedData?.date)}` : `Not Provided`}
            </Typography>
          </FlexBox>
          <FlexBox flexDirection="row" alignItems="flex-start" justifyContent="space-between">
            <Typography numberOfLines={1}>
              {extractedData?.description ??
                `${extractedData?.payerFirstName} ${extractedData?.payerLastName}`}
            </Typography>
            <Typography numberOfLines={1}>{`$${formatMoney(extractedData?.amount)}`}</Typography>
          </FlexBox>
        </ViewDrillDownFlexBox>
        <Divider />
      </>
    );
  };

  const paramsMessage = {
    attachments: [] as Base64Attachment[],
    transmitParams: {
      source: 'BillingDetail',
      payload: { id: eaid },
      returnBackTo: currentUrl,
      message: ''
    },
    isSending: false,
    open: showMessageModal
  };
  if (pdfData && typeof pdfData !== 'string' && pdfData?.encounterId === eaid) {
    paramsMessage.attachments = [
      {
        base64: `data:application/pdf;base64, ${pdfData.base64}`,
        filename: `billing-detail-${eaid.replace(/\W/g, '')}.pdf`
      }
    ];
  }

  return (
    <>
      {showMessageModal && !isDownloading && paramsMessage.attachments.length ? (
        <MessageComposeWidget
          messageUUID={messageUUID}
          paramsMessage={paramsMessage}
          showSuccessAlert={handleShowSuccessAlert}
          handleMessageModal={setShowMessageModal}
        />
      ) : null}

      <ScrollableLayout>
        <BillDetailPanel data-testid="billing-detail">
          {!encounterError ? <SpinnerModal isLoading={loading} /> : null}
          <FlexBox>
            <CallToActionRow
              isDownloading={isDownloading && !showMessageModal}
              isPatientToolbar
              justifyContent="flex-end"
              onDownloadPress={handleDownload}
              onTransmitPress={handleShare}
            />
          </FlexBox>
          <Screen>
            <BillContainer>
              <BillingDetailMarginView>
                <MuiGrid container spacing={3}>
                  <MuiGrid item xs={12} lg={6} sm={12} data-testid="bill-detail-info">
                    <Typography
                      color={Color.grayHue3}
                      fontSize={FontSize.heading}
                      fontWeight={FontWeight.bold}
                    >
                      {encounter?.billingDescription || 'Unknown Provider'}
                    </Typography>
                    {paymentPlan && (
                      <Typography fontWeight={FontWeight.bold} color={Color.gray}>
                        Payment Plan
                      </Typography>
                    )}
                    <Spacer size="small" />
                    <GreyTypography>
                      Patient: {`${firstName} ${lastName || 'patient'}`}
                    </GreyTypography>
                    <Spacer size="xsmall" />
                    <GreyTypography>Account Number: {accountNumber}</GreyTypography>
                    <Spacer size="xsmall" />
                    <GreyTypography>Encounter Number: {fullAccountNumber}</GreyTypography>
                    <Spacer size="xsmall" />
                    <GreyTypography>
                      Admit Date: {formatMMDDYYYYDate(admitDate) || 'Not Provided'}
                    </GreyTypography>
                    <Spacer size="xsmall" />
                    <GreyTypography>
                      Discharge Date: {formatMMDDYYYYDate(dischargeDate) || 'Not Provided'}
                    </GreyTypography>
                    <Spacer size="small" />
                    <GreyTypography>
                      {cernerEncounterId && consumerId === encounter?.patient.id ? (
                        <MuiLink
                          data-testid="view-visit-summary-link"
                          onClick={() => navigateToVisitSummary(encounter)}
                        >
                          <MuiTypography fontSize={FontSize.base}>View Visit Summary</MuiTypography>
                        </MuiLink>
                      ) : null}
                    </GreyTypography>
                  </MuiGrid>
                  {dueStatus === 'DELINQUENT' ? null : (
                    <MuiGrid item xs={12} lg={6} sm={12}>
                      <BillDetailRightPanel>
                        <BillDetailRightPanelTop>
                          <RedText>{!!dueStatus && DUE_STATUS_TYPE[dueStatus]}</RedText>
                          <Spacer size="small" />
                          <BigMoney textAlign="center"> {`$${formatMoney(balance)}`}</BigMoney>
                          <Spacer size="small" />
                        </BillDetailRightPanelTop>
                        <Spacer size="xsmall" />
                        {balance > 0 ? (
                          <BillingButton
                            data-testid="proceed-with-payment-button"
                            variant="contained"
                            color="primary"
                            onClick={showVisitpay}
                          >
                            Proceed with Payment
                          </BillingButton>
                        ) : null}
                        <Spacer size="small" />
                      </BillDetailRightPanel>
                    </MuiGrid>
                  )}
                </MuiGrid>
                <Spacer size="small" />
                <Spacer size="xsmall" />
                <Divider />
                <Spacer size="xsmall" />
                <FlexBox flexDirection="row" justifyContent="flex-start" alignItems="center">
                  <Typography
                    numberOfLines={1}
                    fontSize={FontSize.large}
                    fontWeight={FontWeight.bold}
                  >
                    Summary{' '}
                    <InfoButton definitions={definitions} hideBorder color={Color.secondary} />
                  </Typography>
                </FlexBox>
                <Spacer size="xsmall" />
                <FlatList
                  accessibilityLabel="Bill Detail"
                  data-testid="bill-detail-summary"
                  data={billingSummaryData}
                  keyExtractor={(itemBody: ItemBody) => `${itemBody.left}`}
                  renderItem={({ item: itemBody }) => (
                    <>
                      <FlexBoxRow justifyContent="space-between">
                        <FlexBoxColumn>
                          <BottomDivider>{itemBody.left}</BottomDivider>
                        </FlexBoxColumn>

                        <FlexBoxColumn flex="1 1 auto">
                          <BottomDividerContainer></BottomDividerContainer>
                        </FlexBoxColumn>

                        <FlexBoxColumn>
                          <DefaultText fontWeight={FontWeight.bold} textAlign="right">
                            {isNil(itemBody.right)
                              ? 'N/A'
                              : `$${formatMoney(Math.abs(itemBody.right))}`}
                          </DefaultText>
                        </FlexBoxColumn>
                      </FlexBoxRow>
                      <Spacer size="xsmall" />
                    </>
                  )}
                />
              </BillingDetailMarginView>
              <Divider />
              <ChargeGroup
                totalCharges={totalCharges || 0}
                triggerSurvey={triggerSurvey}
                charges={charges}
              />
              <Divider />
              <CollapsibleList
                accessibilityLabel="View payments and adjustments"
                data-testid="view-payments-and-adjustments"
                key="View payments and adjustments"
              >
                <CollapsibleListHeader
                  title="View payments and adjustments"
                  onOpened={() => {
                    analyticsService.logEvent(AnalyticsEvent.ViewPaymentsClicked, eventData);
                  }}
                  headerWeight="700"
                >
                  <Divider />
                  {paymentAdjustment.length === 0 ? (
                    <EmptyFlexBox>
                      <DefaultText>No payments and adjustments available</DefaultText>
                    </EmptyFlexBox>
                  ) : (
                    paymentAdjustment.map((eachPayment: EncounterPayments) =>
                      renderPaymentsAdjustment(eachPayment)
                    )
                  )}
                </CollapsibleListHeader>
              </CollapsibleList>
              <Divider />
              <ClaimsGroup claims={claims} data-testid="view-claims" />
              <Divider />
              <Spacer size="small" />
              {pendingAmount ? (
                <>
                  {inCollections
                    ? renderBadDebt(
                        principleBalance,
                        DUE_STATUS_TYPE[dueStatus] ? 'In Collections' : null
                      )
                    : renderProceedPayment()}
                  <BillingDetailMarginViewSummary>
                    <FlexBox flexDirection="column" justifyContent="center" alignItems="center">
                      <CenteredTypography fontSize={FontSize.small}>
                        <CenteredTypography fontSize={FontSize.small} fontWeight={FontWeight.bold}>
                          Disclaimer:
                        </CenteredTypography>
                        Bills for homecare, central labs, and Intermountain payment plans will not
                        appear here. You may receive additional bills not shown. Adjustments to your
                        bill will be reflected within 48 hours
                      </CenteredTypography>
                    </FlexBox>
                    <Spacer size="small" />
                    <Divider />
                  </BillingDetailMarginViewSummary>
                  <Spacer size="small" />
                </>
              ) : null}
              <FlexBox justifyContent="center" alignItems="center" padding="10px">
                <Spacer size="small" />
                <Typography tag="link" data-testid="billing-questions" onClick={openHelpWebView}>
                  Ask a question about your bill
                </Typography>
                {goToVisitPaySupport && (
                  <VisitPayWebView closePopup={setGoToVisitPaySupport} uri={VISIT_PAY_SUPPORT} />
                )}
              </FlexBox>
              <Spacer size="medium" />
            </BillContainer>
          </Screen>
        </BillDetailPanel>
        {goToVisitPay && <VisitPayWebView closePopup={setGoToVisitPay} uri={VISIT_PAY_HOME} />}
      </ScrollableLayout>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  consumerId: consumerIdSelector(state),
  glossary: getGlossaries(state),
  currentUrl: currentLocationPathNameSelector(state),
  referringUrl: previousLocationPathNameSelector(state),
  isDownloading: detailDownloadFetchingSelector(state),
  encounter: getEncounterDetailDataSelector(state),
  loading: getEncounterDetailFetchingSelector(state),
  encounterError: getEncounterDetailErrorSelector(state),
  pdfData: detailDownloadDataSelector(state)
});

const mapDispatch = (dispatch: Function) => ({
  createSnack: (type: SnackType, snack?: Snack) => dispatch(snacksActions.createSnack(type, snack)),
  handleDownloadBilling: (id: string): Promise<Action> => dispatch(downloadBillingDetail(id)),
  handleShareDetail: (id: string) => dispatch(downloadBillingDetailToStore(id))
});

export default connect(mapStateToProps, mapDispatch)(BillingDetail);
