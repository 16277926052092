import styled from 'styled-components';

import { dfdDefaultTheme, MuiBox } from 'theme/material-ui';
import FlexBox from 'components/UI/Layout/FlexBox';
import { Spacing } from 'modules/styles/variables';

export const DetailsCommentsContainer = styled(FlexBox)`
  flex-direction: row;
  column-gap: ${Spacing.xLarge}px;
  ${dfdDefaultTheme.breakpoints.down('md')} {
    flex-direction: column;
    & > div:first-child {
      margin-bottom: ${Spacing.xLarge}px;
    }
  }
`;

export const PageContainer = styled(MuiBox)`
  padding: 0 ${Spacing.xLargeLarge}px;
  & > div:first-child {
    & > div:first-child {
      position: absolute;
      right: 0;
      top: -${Spacing.xxLarge}px;
      ${dfdDefaultTheme.breakpoints.down('sm')} {
        top: -88px;
      }
    }
  }
  ${dfdDefaultTheme.breakpoints.down('md')} {
    padding: 0 ${Spacing.medium}px;
  }
`;
