import React from 'react';
import FlexBox from 'components/UI/Layout/FlexBox';
import Spinner from 'components/UI/Spinner/Spinner';

// LOADING
const Loading = () => (
  <FlexBox flex={1} alignItems="center" justifyContent="center" accessibilityLabel="Lab loading">
    <Spinner />
  </FlexBox>
);

export default Loading;
