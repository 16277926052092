import { Formik } from 'formik';
import { connect, useDispatch } from 'react-redux';

import React, { useEffect, useState } from 'react';
import { History } from 'lib/history';
import { NavigationStackScreenProps } from '@react-navigation/stack';
import {
  consumerIdSelector,
  consumerPrimaryEmailSelector,
  consumerPrimaryPhoneSelector,
  profileConsumerDisplayNameSelector
} from 'store/profile/selectors';
import { RootState } from 'store/types';
import { Color } from 'modules/styles/colors';
import { maskPhone } from 'modules/utils/MaskUtils';
import { FontSize, Spacing } from 'modules/styles/variables';
import {
  FeedbackFormValues,
  QualtricsSession,
  SHARE_FEEDBACK_SCENARIOS,
  ShareFeedbackLocationState,
  ShareFeedbackScenarios,
  SESSION_URL
} from 'lib/constants/help';
import {
  getQualtricsSession,
  updateQualtricsSession,
  getScenarioQuestionText
} from 'services/QualtricsService';
import useNavigationAnalytics from 'hooks/useNavigationAnalytics';
import { AnalyticsEvent } from 'services/AnalyticsService';

import {
  MuiBox,
  MuiButton,
  MuiCheckbox,
  MuiContainer,
  MuiTypography,
  MuiFormControlLabel
} from 'theme/material-ui';
import FlexBox from 'components/UI/Layout/FlexBox';
import { Icon } from 'components/Icon';
import CloseButton from 'components/UI/Button/CloseButton';
import TypographyInlineWrapper from 'components/UI/Typography/TypographyInLineWrapper';
import { submitFeedbackValidationSchema } from './Utils';
import { StyledWebMuiModal } from './styled';
import { shareServiceNow } from 'store/serviceNow/actions';
import GlobalTextInput from 'components/TextInput/GlobalTextInput';
import { isDfdAuthenticatedSelector } from 'store/authentication/selectors';

interface ShareFeedbackScreenProps extends NavigationStackScreenProps {
  consumerPrimaryPhone: string;
  consumerPrimaryEmail: string;
  consumerDisplayName: string;
  isDfdAuthenticated: string;
  consumerId: string;
  history: History;
}

export const ShareFeedbackScreen = ({
  consumerPrimaryPhone,
  consumerPrimaryEmail,
  consumerDisplayName,
  isDfdAuthenticated,
  consumerId,
  history
}: ShareFeedbackScreenProps) => {
  const dispatch = useDispatch();
  const [showFeedbackGivenModal, setShowFeedbackGivenModal] = useState(false);
  const locationState = history.location.state as ShareFeedbackLocationState;
  const scenario: ShareFeedbackScenarios =
    locationState?.scenario || SHARE_FEEDBACK_SCENARIOS.shareFeedback;
  const supportUrl = '/u/help-support';
  const screenLocation: string = locationState?.screenLocation || supportUrl;
  const navigateTo = locationState?.navigateTo || supportUrl;
  const navigateParams = locationState?.navigateToParams || {};
  const scenarioQuestion = getScenarioQuestionText(scenario);
  const [session, setSession] = useState<QualtricsSession>();
  const [showSubmitting, setShowSubmitting] = useState<boolean>(false);
  const shareFeedbackForm = useNavigationAnalytics(AnalyticsEvent.ShareFeedbackFormSubmitted);
  const shareFeedbackFormSubmitted = (contact: boolean) => {
    shareFeedbackForm({
      user_outreach_approved: contact ? 'Yes' : 'No'
    });
  };
  const shareFeedbackFormCanceled = useNavigationAnalytics(
    AnalyticsEvent.ShareFeedbackFormCanceled
  );
  const technicalSupportLinkClicked = useNavigationAnalytics(
    AnalyticsEvent.ShareFeedbackTechnicalSupportLinkClicked
  );

  const initialValues = {
    feedback: '',
    showContact: true,
    name: isDfdAuthenticated ? consumerDisplayName : '',
    email: consumerPrimaryEmail || '',
    preferredPhone: maskPhone(consumerPrimaryPhone || ''),
    scenario,
    screenLocation
  };

  const navigateToBla = () => {
    setShowFeedbackGivenModal(false);
    if (navigateTo) return history.push(navigateTo, { ...navigateParams, feedbackGiven: true });
    return history.push(supportUrl, { feedbackGiven: true });
  };

  const handleCancel = () => {
    shareFeedbackFormCanceled();
    if (navigateTo) return history.push(navigateTo, navigateParams);
    return history.goBack();
  };

  async function updateSession(values: FeedbackFormValues) {
    const FeedbackDTO = {
      incidentType: 'shareFeedback',
      records: {
        suggestions: values.feedback,
        name: values.name,
        email: values.email,
        preferredPhone: values.preferredPhone,
        mrn: consumerId
      }
    };
    shareFeedbackFormSubmitted(values?.showContact);
    try {
      setShowSubmitting(true);
      if (values?.showContact) {
        dispatch(shareServiceNow(FeedbackDTO, !consumerId));
      }
      const qualtricsResponse = await updateQualtricsSession(values, session as QualtricsSession);
      if (qualtricsResponse?.meta?.httpStatus === '200 - OK') {
        setShowFeedbackGivenModal(true);
      }
    } catch (err) {
      setShowSubmitting(false);
    }
    setShowSubmitting(false);
    return null;
  }

  async function initSession() {
    const newSession = await getQualtricsSession(SESSION_URL);
    setSession(newSession);
  }

  const handleTechnicalSupport = () => {
    technicalSupportLinkClicked();
    history.push('/u/help-support/technical-support');
  };

  useEffect(() => {
    initSession();
  }, []);

  return (
    <>
      <StyledWebMuiModal open={showSubmitting}>
        <FlexBox flexDirection="row">
          <FlexBox backgroundColor={Color.secondary} width="4px"></FlexBox>
          <FlexBox vSpacing={Spacing.xLarge} hSpacing={Spacing.xxLarge}>
            <MuiTypography variant="h6">Sending your feedback...</MuiTypography>
          </FlexBox>
        </FlexBox>
      </StyledWebMuiModal>
      <StyledWebMuiModal open={showFeedbackGivenModal} handleClose={navigateToBla}>
        <FlexBox flexDirection="row">
          <FlexBox backgroundColor={Color.green} width="4px"></FlexBox>
          <FlexBox hSpacing={Spacing.medium} justifyContent="center" alignItems="center">
            <Icon color={Color.green} name="check-circle-outline" size={60} />
          </FlexBox>
          <FlexBox vSpacing={Spacing.xLarge} hSpacing={Spacing.large}>
            <MuiTypography variant="h6">Thank you for your feedback!</MuiTypography>
          </FlexBox>
          <FlexBox hSpacing={Spacing.medium}>
            <CloseButton color={Color.black} onPress={navigateToBla} />
          </FlexBox>
        </FlexBox>
      </StyledWebMuiModal>
      <MuiBox
        style={{
          minHeight: 'calc(100vh - 273px)'
        }}
        bgcolor={Color.baseColor}
        flex={1}
        width="100%"
      >
        <Formik
          data-testid="Formik-test-wrapper"
          initialValues={initialValues}
          validationSchema={submitFeedbackValidationSchema}
          onSubmit={updateSession}
        >
          {({ values, isValid, errors, dirty, handleChange, handleSubmit }) => {
            return (
              <MuiContainer maxWidth="sm">
                <MuiBox
                  pt={Spacing.small}
                  display="flex"
                  flex={1}
                  flexDirection="column"
                  justifyContent="space-between"
                >
                  <MuiTypography fontSize={FontSize.heading}>{scenarioQuestion}</MuiTypography>

                  <MuiBox pt={Spacing.small}>
                    <MuiTypography>
                      If you need help with a specific app issue, please contact{' '}
                      <TypographyInlineWrapper
                        color={Color.link}
                        onClick={() => handleTechnicalSupport()}
                      >
                        Technical Support
                      </TypographyInlineWrapper>
                      .
                    </MuiTypography>
                    <GlobalTextInput
                      data-testid="feedback-input"
                      aria-label="Input feedback"
                      label="Suggestions"
                      variant="outlined"
                      placeholder="Enter comment here..."
                      defaultValue=""
                      onChange={handleChange('feedback')}
                      showError={!!errors?.feedback}
                      helperTextInfo={errors?.feedback}
                      rows={15}
                      multiline
                      required
                      fullWidth
                    />
                  </MuiBox>
                </MuiBox>
                <MuiBox
                  pt={Spacing.small}
                  display="flex"
                  flex={1}
                  flexDirection="column"
                  justifyContent="space-between"
                >
                  <MuiBox display="flex" justifyContent="center" marginBottom="15px">
                    <MuiFormControlLabel
                      control={
                        <MuiCheckbox
                          aria-label="Show contact"
                          checked={values?.showContact}
                          onChange={handleChange('showContact')}
                          name="contact-ok"
                          color="primary"
                        />
                      }
                      label="It's OK to contact me for more details if needed"
                    />
                  </MuiBox>
                  {values?.showContact ? (
                    <>
                      <MuiTypography variant="subtitle2">
                        Please verify your contact information
                      </MuiTypography>
                      <GlobalTextInput
                        data-testid="name-input"
                        aria-label="Input name"
                        label="First and Last Name"
                        placeholder="Enter First and Last Name"
                        value={values?.name}
                        onChange={handleChange('name')}
                        showError={!!errors?.name}
                        helperTextInfo={errors?.name}
                        required
                      />
                      <GlobalTextInput
                        data-testid="email-input"
                        aria-label="Input email"
                        label="Email"
                        placeholder="example@email.com"
                        value={values?.email}
                        onChange={handleChange('email')}
                        showError={!!errors?.email}
                        helperTextInfo={errors?.email}
                        required
                      />
                      <GlobalTextInput
                        data-testid="phone-input"
                        aria-label="Input Preferred Phone"
                        label="Preferred Phone"
                        placeholder="(000) 000-0000"
                        value={values?.preferredPhone}
                        onChange={handleChange('preferredPhone')}
                        mask="phone"
                        showError={!!errors?.preferredPhone}
                        helperTextInfo={errors?.preferredPhone}
                        required
                      />
                    </>
                  ) : null}
                </MuiBox>
                <MuiBox width="100%" p={Spacing.medium}>
                  <MuiButton
                    data-testid="submit-btn"
                    fullWidth
                    color="primary"
                    variant="contained"
                    disabled={!isValid || !dirty}
                    onClick={handleSubmit}
                  >
                    Submit
                  </MuiButton>
                  <MuiButton
                    data-testid="cancel-btn"
                    fullWidth
                    variant="text"
                    onClick={handleCancel}
                  >
                    Cancel
                  </MuiButton>
                </MuiBox>
              </MuiContainer>
            );
          }}
        </Formik>
      </MuiBox>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  consumerId: consumerIdSelector(state),
  consumerPrimaryPhone: consumerPrimaryPhoneSelector(state),
  consumerPrimaryEmail: consumerPrimaryEmailSelector(state),
  consumerDisplayName: profileConsumerDisplayNameSelector(state),
  isDfdAuthenticated: isDfdAuthenticatedSelector(state)
});

export default connect(mapStateToProps)(ShareFeedbackScreen);
