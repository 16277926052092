/* eslint-disable react/no-this-in-sfc */
import * as d3 from 'd3';
import React, { useRef, useLayoutEffect } from 'react';
import { isIE } from 'lib/browser';
import { MuiBox } from 'theme/material-ui';
import { DIASTOLIC, LEGEND_HIGHLOW, LEGEND_NORMAL, SYSTOLIC } from 'store/vitals/constants';
import { ColoredDot, KeyText, LineChartContainer, LineChartContainerStyles } from './styled';

const LineGraphKeyItems = [
  { title: LEGEND_NORMAL, class1: 'KeyValueNormal', color: 'KeyDotNormal' },
  { title: LEGEND_HIGHLOW, class1: 'KeyValueWarning', color: 'KeyDotWarning' }
];

const appendElement = element => {
  // it will not fire onMouseleave for the element on ie
  if (!isIE()) {
    element.parentNode.appendChild(element);
  }
};

const d3ReorderingmouseEvents = {
  onMouseover() {
    appendElement(this);

    setTimeout(() => {
      d3.select(this)
        .classed('hover', true)
        .select('.graph-point__inner')
        .attr('transform', 'scale(1.75)');
    }, 10);
  },

  onMouseleave() {
    d3.select(this)
      .classed('hover', false)
      .select('.graph-point__inner')
      .attr('transform', 'scale(0.4)');
  }
};

const FullLineGraphKey = () => (
  <MuiBox display="flex" justifyContent="center">
    {LineGraphKeyItems.map(({ title, color }) => (
      <MuiBox display="flex" alignItems="center" justifyContent="center" key={title} mr={2}>
        <KeyText>{title}</KeyText>
        <ColoredDot color={color} />
      </MuiBox>
    ))}
  </MuiBox>
);

type ChartDataInterpretation = 'high' | 'low' | 'overweight' | 'underweight' | 'obese' | 'normal';

interface ChartDataPoint {
  date: Date;
  value: number;
  value2: number;
  interpretation: ChartDataInterpretation;
  interpretation2: ChartDataInterpretation;
}

const cleanData = (data: any) => {
  const chartData: ChartDataPoint[] = [];
  for (const vital of data) {
    if (!vital) {
      continue;
    }

    const systolic = vital[SYSTOLIC];
    const diastolic = vital[DIASTOLIC];

    chartData.push({
      date: new Date(vital.date),
      value: parseFloat(systolic.value),
      value2: parseFloat(diastolic.value),
      interpretation: systolic.statusText,
      interpretation2: diastolic.statusText
    });
  }

  return chartData;
};

const setPointClass = (
  point: d3.Selection<SVGGElement, unknown, SVGGElement, unknown>,
  interpretation: ChartDataInterpretation
) => {
  switch (interpretation) {
    case 'high':
    case 'low':
    case 'overweight':
    case 'underweight':
      point.classed('warn', true);
      break;
    case 'obese':
      point.classed('obese', true);
      break;
    case 'normal':
      point.classed('normal', true);
      break;
    default:
      point.classed('normal', true);
  }
};

const createState = () => ({
  width: 600,
  height: 0,
  chartMargin: {
    top: 40,
    right: 60,
    bottom: 80,
    left: 80
  },
  timeFormat: '%m%/%y',
  labelX: 'Blood Pressure Trend',
  labelY: '',
  minY: 40,
  maxY: 190,
  range: null,
  originalDataSet: []
});

const LineChart = (props: any) => {
  const svgRef = useRef<SVGSVGElement | null>(null);

  const draw = (data: ChartDataPoint[]) => {
    const state = createState();
    const svg = d3.select(svgRef.current);
    svg.selectAll('g').remove();

    const svgWidth = window.innerWidth > 768 ? 600 : 320;
    const svgHeight = 230;
    const margin = state.chartMargin;

    const lastPointV1 = d3.max(data, d => d.value);
    const lastPointV2 = d3.max(data, d => d.value2);

    if (data.length > 6) {
      state.timeFormat = "%b% % %d % %'%y";
    }

    // if default value
    if (margin && margin.right === 20) {
      margin.right = 80;
    }

    const width = svgWidth - margin.left - margin.right;
    const height = svgHeight - margin.top - margin.bottom;

    // Select SVG and set width and height
    svg
      .attr('preserveAspectRatio', 'xMidYMid meet')
      .attr('viewbox', `0 0 ${svgWidth} ${svgHeight}`)
      .attr('width', svgWidth)
      .attr('height', svgHeight)
      .attr('shape-rendering', 'auto');

    // Append group tag inside SVG element (move to specific position)
    const svgGroup = svg.append('g').attr('transform', `translate(${margin.left}, ${margin.top})`);
    const trendLines = svgGroup.append('g').classed('chart-path', true);
    const axesGroup = svgGroup.append('g').classed('chart-axes', true);
    const lineGroup = svgGroup.append('g').classed('chart-lines', true);

    // Keep scale of y-axis linear
    const x = d3.scaleTime().rangeRound([0, width]);
    const y = d3.scaleLinear().rangeRound([height, 0]);

    // Get min and max values either passed in, or calculated from data
    const minY = Math.min(d3.min(data, d => d.value)!, state.minY);
    const maxY = Math.max(d3.max(data, d => d.value)!, state.maxY);

    const xDomain = d3.extent(data, d => d.date);
    const yDomain = [minY, maxY];

    const linePath = d3
      .line<ChartDataPoint>()
      .x(d => x(d.date)) // returns minimum and maximum value of the dates
      .y(d => y(d.value)) // returns minimum and maximum
      .curve(d3.curveMonotoneX); // apply smoothing to the line

    const linePath2 = d3
      .line<ChartDataPoint>()
      .x(d => x(d.date)) // returns minimum and maximum value of the dates
      .y(d => y(d.value2)) // returns minimum and maximum
      .curve(d3.curveMonotoneX); // apply smoothing to the line

    x.domain(xDomain);
    y.domain(yDomain);

    let ticksX = Math.round(width / 100) + 8;
    const ticksY = Math.round(height / 100) + 8;

    if (ticksX * 2 > data.length) {
      ticksX /= 2; // attempting to minimize too many ticks
    }

    if (data.length === 0) {
      axesGroup
        .append('text')
        .classed('graph-label-note', true)
        .attr('y', height / 2)
        .attr('x', width / 2)
        .attr('text-anchor', 'middle')
        .text('No Data Available');
    }

    // HORIZONTAL AXIS
    axesGroup
      .append('g')
      .attr('transform', `translate(0,${height})`)
      .classed('x-axis', true)
      .call(
        d3.axisBottom(x).tickFormat(date => {
          if (d3.timeYear(date) < date) {
            return d3.timeFormat(state.timeFormat)(date);
          }
          return d3.timeFormat('%Y')(date);
        })
      )
      .selectAll('text')
      .classed('x-axis-label', true)
      .attr('dx', '-.8em')
      .attr('dy', '.15em')
      .attr('transform', 'rotate(-65)')
      .select('.domain')
      .remove();

    const axisY = d3.axisLeft(y).ticks(ticksY);
    const yAxisLabelRotation = 0;
    const yAxisLabelPositionX = -10;
    const yAxisLabelPositionY = 0;
    const yAxisLabelTextAlign = 'right';

    axesGroup
      .append('g')
      .call(axisY)
      .classed('y-axis', true)
      .selectAll('text')
      .classed('y-axis-label', true)
      .attr('transform', `rotate(${yAxisLabelRotation})`)
      .attr('text-anchor', yAxisLabelTextAlign)
      .attr('x', yAxisLabelPositionX)
      .attr('y', yAxisLabelPositionY);

    axesGroup
      .append('g')
      .append('text')
      .classed('graph-label-x', true)
      .attr('y', 0)
      .attr('x', width / 2)
      .attr('dy', '-2.5em')
      .attr('text-anchor', 'middle')
      .text(state.labelX);

    const trendLine1 = trendLines
      .append('path')
      .classed('chart-line', true)
      .datum(data)
      .attr('fill', 'none')
      .attr('d', linePath);

    const trendLine2 = trendLines
      .append('path')
      .classed('chart-line chart-line2', true)
      .datum(data)
      .attr('fill', 'none')
      .attr('d', linePath2);

    trendLine1
      .attr('stroke-dashoffset', trendLine1.node()?.getTotalLength() ?? 0)
      .attr('stroke-dasharray', trendLine1.node()?.getTotalLength() ?? 0)
      .transition()
      .delay(200)
      .attr('stroke-dashoffset', 0)
      .duration(400);

    // CHART LINE PATH2
    trendLine2
      .attr('stroke-dashoffset', trendLine2.node()?.getTotalLength() ?? 0)
      .attr('stroke-dasharray', trendLine2.node()?.getTotalLength() ?? 0)
      .transition()
      .delay(200)
      .attr('stroke-dashoffset', 0)
      .duration(400);

    const trend1 = lineGroup.append('g').classed('chart-line', true);
    const trend2 = lineGroup.append('g').classed('chart-line chart-line2', true);

    trend1.on('mouseover', function mouseover() {
      appendElement(this);
    });
    trend2.on('mouseover', function mouseover() {
      appendElement(this);
    });

    const dataPoint = trend1
      .selectAll('graph-point')
      .data(data)
      .enter()
      .append('g')
      .on('mouseover', d3ReorderingmouseEvents.onMouseover)
      .on('mouseleave', d3ReorderingmouseEvents.onMouseleave)
      .classed('graph-point', true)
      .attr('transform', d => `translate(${x(d.date)},${y(d.value)})`)
      .append('g')
      .classed('graph-point__inner', true)
      .attr('transform', 'scale(0.4)');

    const dataPointTransitionDelay = (d: ChartDataPoint, i: number): number => {
      if (data.length === 1) {
        return 0;
      }

      let s = i + 1;
      s = s * 50 + 600;
      return s;
    };

    dataPoint
      .append('circle')
      .classed('graph-point__data-point', true)
      .attr('r', 0)
      .transition()
      .delay(dataPointTransitionDelay)
      .duration(300)
      .attr('r', 26)
      .attr('opacity', '1')
      .transition()
      .delay(200)
      .duration(200)
      .attr('r', 20);

    const dataPointLabel = dataPoint.append('g').classed('graph-point__data-group', true);

    dataPointLabel
      .append('text')
      .classed('graph-point__label', true)
      .attr('transform', 'translate(0, 4)')
      .text((d, i) => {
        const label = d.value || [];

        const dp = dataPoint.filter((d, idx) => {
          return idx === i;
        }); // filter the matching dataPoint with current index
        if (label.length > 3) {
          dp.select('text').classed('small', true);
        }
        // Add interpretation class to dataPoint
        if (d.interpretation) {
          const interp = d.interpretation.toLowerCase();

          setPointClass(dp, interp);
        }

        return label;
      });

    dataPointLabel
      .selectAll('.graph-point__label')
      .classed('graph-point__label--highlight', true)
      .attr('transform', 'translate(0, -2)');

    dataPoint.selectAll('circle').classed('has-secondary-value', true);

    const dataPoint2 = trend2
      .selectAll('graph-point2')
      .data(data)
      .enter()
      .append('g')
      .classed('graph-point graph-point2', true)
      .on('mouseover', d3ReorderingmouseEvents.onMouseover)
      .on('mouseleave', d3ReorderingmouseEvents.onMouseleave)
      .attr('transform', d => {
        return `translate(${x(d.date)},${y(d.value2)})`;
      })
      .append('g')
      .classed('graph-point__inner', true)
      .attr('transform', 'scale(0.4)');

    dataPoint2
      .append('circle')
      .classed('graph-point__data-point graph-point2__data-point', true)
      .attr('r', 0)
      .transition()
      .delay(dataPointTransitionDelay)
      .duration(300)
      .attr('r', 26)
      .attr('opacity', '1')
      .transition()
      .delay(200)
      .duration(200)
      .attr('r', 20);

    dataPointLabel
      .append('text')
      .classed('graph-point__label', true)
      .text(d => d.value2)
      .attr('transform', 'translate(0, 14)');

    dataPointLabel
      .append('rect')
      .classed('graph-point__label-line', true)
      .attr('width', 22)
      .attr('height', 1.25) // matching visual stroke width of font
      .attr('transform', 'translate(-11, 0)');

    const dataPointLabel2 = dataPoint2.append('g').classed('graph-point__data-group', true);

    dataPointLabel2
      .append('text')
      .classed('graph-point__label graph-point2__label', true)
      .text((d, i) => {
        const label = d.value;

        // filter the matching dataPoint with current index
        const dp2 = dataPoint2.filter((d, idx) => {
          return idx === i;
        });

        if (label.length > 3) {
          dp2.select('text').classed('small', true);
        }

        // Add interpretation class to dataPoint
        if (d.interpretation2) {
          const interp = d.interpretation2.toLowerCase();
          setPointClass(dp2, interp);
        }

        return label;
      })
      .attr('transform', 'translate(0, -2)');

    dataPointLabel2
      .append('text')
      .classed('graph-point__label graph-point2__label', true)
      .text(d => {
        return d.value2;
      })
      .classed('graph-point2__label--highlight', true)
      .attr('transform', 'translate(0, 14)');

    dataPointLabel2
      .append('rect')
      .classed('graph-point__label-line graph-point2__label-line', true)
      .attr('width', 22)
      .attr('height', 1.25)
      .attr('transform', 'translate(-11, 0)');

    trend1
      .append('g')
      .lower()
      .append('text')
      .classed('trendline__label', true)
      .text('Systolic')
      .attr('text-anchor', 'end')
      .attr('x', svgWidth - 80)
      .attr('y', y(lastPointV1! - 3) - 20)
      .attr('opacity', 0)
      .transition()
      .delay(2000)
      .duration(300)
      .attr('opacity', 1);

    trend2
      .append('g')
      .lower()
      .append('text')
      .classed('trendline__label', true)
      .text('Diastolic')
      .attr('text-anchor', 'end')
      .attr('x', svgWidth - 80)
      .attr('y', y(lastPointV2! - 3) + 12)
      .attr('opacity', 0)
      .transition()
      .delay(2000)
      .duration(300)
      .attr('opacity', 1);

    if (width < 240) {
      svg
        .classed('svg__small', true)
        .selectAll('.trendline__label')
        .attr('transform', 'translate(20,0)');
    }
  };

  useLayoutEffect(() => {
    if (props.data.length > 0) {
      draw(cleanData(props.data));
    }
  }, [props.data]);

  return (
    <LineChartContainer>
      <LineChartContainerStyles>
        <svg className="line-chart" ref={svgRef} />
      </LineChartContainerStyles>
      <FullLineGraphKey />
    </LineChartContainer>
  );
};

export default LineChart;
