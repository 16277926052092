import React, { ComponentType, useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { MuiContainer, MuiTypography, MuiBox } from 'theme/material-ui';
import Spacer from 'components/UI/Layout/Spacer';
import Footer from 'components/common/Footer';
import * as covidScreeningSelectors from 'store/CovidScreening/selectors';
import { RootState } from 'store/types';
import { Patient } from 'store/CovidScreening/types';
import { patientSelect } from 'modules/constants/covidScreening';
import { covidTestingFacilities } from 'modules/constants/urls';
import { Color } from 'modules/styles/colors';
import { CovidTestButton } from '../Components/CovidTestButton';
import { AnalyticsEvent } from 'services/AnalyticsService';
import { CovidTitles } from 'lib/constants/covidScreening';
import useNavigationAnalytics from 'hooks/useNavigationAnalytics';
import * as covidActions from 'store/CovidScreening/actions';
import { CompanyName } from 'modules/utils/ConfigUtils';

export interface Props extends RouteComponentProps {
  patientsData: Patient;
  setPrevPatient: typeof covidActions.setPrevPatient;
}

export function CovidConfirmation({ history, patientsData, setPrevPatient }: Props) {
  const facilitiesLink = useNavigationAnalytics(AnalyticsEvent.CovidFacilitiesLinkClicked);
  const covidOrderConfirmation = useNavigationAnalytics(AnalyticsEvent.CovidOrderCompleted);
  const covidScreeningClosed = useNavigationAnalytics(AnalyticsEvent.CovidScreeningClosed);
  const handleClose = () => {
    covidScreeningClosed({ title: CovidTitles.CONFIRMATION_PAGE });
    history.push('/u/get-care-now');
  };
  const onFacilitiesLinkClick = () => {
    facilitiesLink({
      title: CovidTitles.CONFIRMATION_PAGE
    });
    window.open(covidTestingFacilities, '_blank');
  };

  useEffect(() => {
    covidOrderConfirmation({
      covid19_order: 'Success',
      title: CovidTitles.CONFIRMATION_PAGE
    });
    setPrevPatient(patientsData);
    window.history.pushState(null, document.title, window.location.href);
  }, []);
  return (
    <>
      <MuiContainer maxWidth="lg">
        <Spacer size="medium" />
        <MuiBox pl={4} my={2}>
          <MuiTypography variant="h4" align="center">
            Your COVID-19 test has been ordered.
          </MuiTypography>
        </MuiBox>
        <Spacer size="medium" />
        <MuiBox pl={4} my={2}>
          <MuiTypography align="justify">
            Please click on the link below to review a list of {CompanyName} facilities where you
            can pick up your saliva (drool) kit and drop off your saliva (drool) sample.
          </MuiTypography>
          <Spacer size="small" />
          <MuiTypography
            display="inline"
            onClick={onFacilitiesLinkClick}
            color={Color.primaryUnderlay}
          >
            {CompanyName} COVID-19 testing facilities
          </MuiTypography>
          <Spacer size="small" />
          <MuiTypography align="justify">Proceed to any site to take the test.</MuiTypography>
          <Spacer size="medium" />
          <MuiTypography variant="h6" align="center">
            A summary of this information will
          </MuiTypography>
          <MuiTypography variant="h6" align="center">
            be sent to {patientsData?.emails[0]?.address} shortly.
          </MuiTypography>
        </MuiBox>

        <Footer
          headerText={patientSelect.GET_PATIENTS.HELPLINE.CovidInfo}
          link={patientSelect.GET_PATIENTS.HELPLINE.CovidPatientSelectHelpline}
          linkName={patientSelect.GET_PATIENTS.HELPLINE.CovidPatientSelectHelpline}
          helplineHours={patientSelect.GET_PATIENTS.HELPLINE.CovidHelplineHours}
          title={CovidTitles.CONFIRMATION_PAGE}
        />
        <Spacer size="medium" />
        <MuiBox width="50%" margin="0 auto" alignItems="center">
          <CovidTestButton
            fullWidth
            style={{ height: 45, fontSize: 14 }}
            data-testid="Close"
            color="primary"
            variant="contained"
            onClick={handleClose}
          >
            Close
          </CovidTestButton>
        </MuiBox>
        <Spacer size="medium" />
      </MuiContainer>
    </>
  );
}

const mapStateToProps = (state: RootState) => ({
  patientsData: covidScreeningSelectors.CovidScreeningPatientInfoSelector(state)
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setPrevPatient: (patient: Patient) => dispatch(covidActions.setPrevPatient(patient))
});

export default connect(mapStateToProps, mapDispatchToProps)(CovidConfirmation as ComponentType);
