import React, { ComponentType, useEffect } from 'react';
import { connect } from 'react-redux';
import capitalize from 'lodash/capitalize';
import {
  downloadProblemsList,
  fetchProblems,
  replyToProblems
} from 'store/medicalHistory/problems/actions';
import {
  activeProblemsSelector,
  loadingSelector,
  problemsDownloadErrorSelector,
  problemsDownloadingSelector,
  problemsErrorSelector,
  remissionProblemsSelector,
  resolvedProblemsSelector
} from 'store/medicalHistory/problems/selectors';
import { Problem } from 'store/medicalHistory/types';
import { RootDispatch, RootState } from 'store/types';
import Banner from 'screens/MedicalHistory/Banner';
import { MuiContainer, MuiBox, MuiPaper, MuiGrid } from 'theme/material-ui';
import { Color } from 'modules/styles/colors';
import { formatDate } from 'modules/utils/DateUtils';
import { convertToLowerKabobCase } from 'modules/utils/StringUtils';
import { MedicalHistoryListItem } from 'components/medicalHistory/MedicalHistoryListItem';
import { AnalyticsEvent } from 'services/AnalyticsService';
import useNavigationAnalytics, { NavigationAnalyticsCallback } from 'hooks/useNavigationAnalytics';
import { currentLocationPathNameSelector } from 'store/router/selectors';
import { MedicalHistoryMessage } from '../common';
import DownloadErrorSnackbar from 'components/DownloadErrorSnackbar/DownloadErrorSnackbar';

export interface Props {
  loading: boolean;
  error: Error | null;
  activeProblems: Problem[];
  remissionProblems: Problem[];
  resolvedProblems: Problem[];
  dispatch: RootDispatch;
  isDownloadingProblemsList: boolean;
  downloadError: string | null;
  currentUrl?: string;
}

interface GetAnalytics {
  drawer: NavigationAnalyticsCallback;
  item: NavigationAnalyticsCallback;
  info: NavigationAnalyticsCallback;
}

const orEmpty = (str: string | undefined, empty = 'N/A') => str || empty;

const renderData = (
  title: string,
  problems: Problem[],
  getAnalytics: (title: string) => GetAnalytics | null
) => {
  return (
    <>
      {problems.map((item: Problem) => (
        <MuiBox mb={1} key={item.id}>
          <MuiPaper elevation={2}>
            <MedicalHistoryListItem
              title={capitalize(item.name)}
              body={orEmpty(item.provider)}
              secondaryBody={formatDate(item.onsetDate)}
              tertiaryBody={orEmpty(item.confirmation)}
              itemBackgroundColor={Color.white}
              onInfoClick={() => getAnalytics(title)?.info()}
              onExpand={() => getAnalytics(title)?.item()}
            >
              <MuiBox p={3}>
                <MuiGrid container spacing={3} aria-label={`${title} problems list`}>
                  <MuiGrid item xs={3}>
                    <MuiBox>
                      <MuiBox
                        fontWeight="fontWeightBold"
                        textAlign="left"
                        data-testid={convertToLowerKabobCase(item.name, '-patient')}
                      >
                        Patient:
                      </MuiBox>
                      <MuiBox
                        fontWeight="fontWeightBold"
                        textAlign="left"
                        data-testid={convertToLowerKabobCase(item.name, '-status')}
                      >
                        Status:
                      </MuiBox>
                      <MuiBox
                        fontWeight="fontWeightBold"
                        textAlign="left"
                        data-testid={convertToLowerKabobCase(item.name, '-last-reviewed')}
                      >
                        Last reviewed:
                      </MuiBox>
                      <MuiBox
                        fontWeight="fontWeightBold"
                        textAlign="left"
                        data-testid={convertToLowerKabobCase(item.name, '-comments')}
                      >
                        Comments:
                      </MuiBox>
                    </MuiBox>
                  </MuiGrid>
                  <MuiGrid item xs={6}>
                    <MuiBox>
                      <MuiBox style={{ minHeight: '19px' }}>{orEmpty(item.patient)}</MuiBox>
                    </MuiBox>
                    <MuiBox>
                      <MuiBox style={{ minHeight: '19px' }}>{orEmpty(item.confirmation)}</MuiBox>
                    </MuiBox>
                    <MuiBox>
                      <MuiBox style={{ minHeight: '19px' }}>{formatDate(item.lastUpdated)}</MuiBox>
                    </MuiBox>
                    <MuiBox>
                      <MuiBox style={{ minHeight: '19px' }}>{orEmpty(item.comments)}</MuiBox>
                    </MuiBox>
                  </MuiGrid>
                </MuiGrid>
              </MuiBox>
            </MedicalHistoryListItem>
          </MuiPaper>
        </MuiBox>
      ))}
    </>
  );
};

export const ProblemList = ({
  loading,
  error,
  activeProblems,
  remissionProblems,
  resolvedProblems,
  dispatch,
  isDownloadingProblemsList,
  downloadError,
  currentUrl
}: Props) => {
  const shareProblems = useNavigationAnalytics(AnalyticsEvent.ShareProblemListClicked);
  const downloadProblems = useNavigationAnalytics(AnalyticsEvent.DownloadProblemListClicked);
  const activeClicked = useNavigationAnalytics(AnalyticsEvent.ProblemListActiveClicked);
  const activeItemClicked = useNavigationAnalytics(AnalyticsEvent.ProblemListActiveItemClicked);
  const activeInfoClicked = useNavigationAnalytics(AnalyticsEvent.ProblemListActiveItemInfoClicked);
  const resolvedClicked = useNavigationAnalytics(AnalyticsEvent.ProblemListResolvedClicked);
  const resolvedItemClicked = useNavigationAnalytics(AnalyticsEvent.ProblemListResolvedItemClicked);
  const resolvedInfoClicked = useNavigationAnalytics(
    AnalyticsEvent.ProblemListResolvedItemInfoClicked
  );
  const remissionClicked = useNavigationAnalytics(AnalyticsEvent.ProblemListRemissionClicked);
  const remissionItemClicked = useNavigationAnalytics(
    AnalyticsEvent.ProblemListRemissionItemClicked
  );
  const remissionInfoClicked = useNavigationAnalytics(
    AnalyticsEvent.ProblemListRemissionItemInfoClicked
  );
  useEffect(() => {
    dispatch(fetchProblems());
  }, [fetchProblems]);

  const handleShareProblems = () => {
    shareProblems();
    dispatch(replyToProblems(activeProblems, remissionProblems, resolvedProblems, currentUrl));
  };

  const handleDownloadProblemsList = () => {
    downloadProblems();
    dispatch(downloadProblemsList());
  };

  const data = [
    { title: 'Active', problems: activeProblems },
    { title: 'Remission', problems: remissionProblems },
    { title: 'Resolved', problems: resolvedProblems }
  ];

  const getAnalytics = (title: string): GetAnalytics | null => {
    if (title === 'Active') {
      return {
        drawer: activeClicked,
        item: activeItemClicked,
        info: activeInfoClicked
      };
    }
    if (title === 'Resolved') {
      return {
        drawer: resolvedClicked,
        item: resolvedItemClicked,
        info: resolvedInfoClicked
      };
    }
    if (title === 'Remission') {
      return {
        drawer: remissionClicked,
        item: remissionItemClicked,
        info: remissionInfoClicked
      };
    }
    return null;
  };

  return (
    <>
      <DownloadErrorSnackbar hasError={!!downloadError} onRetry={handleDownloadProblemsList} />
      <Banner
        onDownloadPress={handleDownloadProblemsList}
        onTransmitPress={handleShareProblems}
        isDownloading={isDownloadingProblemsList}
      />
      <MuiContainer maxWidth="lg">
        <MedicalHistoryMessage
          type="problems"
          loading={loading}
          data={[...activeProblems, ...remissionProblems, ...resolvedProblems]}
          error={error}
        />

        <MuiBox width="100%" pt={3}>
          {data.map(item => {
            return item.problems.length > 0 ? (
              <MuiBox mb={1} key={item.title}>
                <MuiPaper elevation={2}>
                  <MedicalHistoryListItem
                    title={capitalize(item.title)}
                    itemBackgroundColor={Color.grayHue4}
                    onExpand={() => getAnalytics(item.title)?.drawer()}
                    hideInfoIcon
                  >
                    {renderData(item.title, item.problems, getAnalytics)}
                  </MedicalHistoryListItem>
                </MuiPaper>
              </MuiBox>
            ) : (
              <></>
            );
          })}
        </MuiBox>
      </MuiContainer>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  loading: loadingSelector(state),
  error: problemsErrorSelector(state),
  activeProblems: activeProblemsSelector(state),
  remissionProblems: remissionProblemsSelector(state),
  resolvedProblems: resolvedProblemsSelector(state),
  isDownloadingProblemsList: problemsDownloadingSelector(state),
  downloadError: problemsDownloadErrorSelector(state),
  currentUrl: currentLocationPathNameSelector(state)
});

export default connect(mapStateToProps)(ProblemList as ComponentType);
