import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { CustomTooltip } from 'components/InfoPopper';
import FlexBox from 'components/UI/Layout/FlexBox';
import Spacer from 'components/UI/Layout/Spacer';
import ConfirmModal from 'components/UI/Modals/ConfirmModal';
import Typography from 'components/UI/Typography';
import { Form, Formik } from 'formik';
import * as H from 'history';
import { Color } from 'modules/styles/colors';
import { FontSize, Spacing } from 'modules/styles/variables';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import analyticsService, { AmplitudeEventData, AnalyticsEvent } from 'services/AnalyticsService';
import { formatAlert, Step } from 'store/pageActions/RegisterAccount/constants';
import { handleUrlOnForgetUserName, handleUrlOnExisting } from 'store/pageActions/RegisterAccount';
import {
  updateAccountCreationInfo,
  updateRegistrationAlert,
  updateRegistrationMeta,
  updateRegistrationStep,
  accountCreationInfoSelector,
  getRegisterBrand,
  CreacteAccountOptions,
  getInsuranceType,
  RegisterAlert,
  RegisterMeta,
  getRedirectIdx
} from 'store/register';
import { getBrandColor } from 'store/register/utils';
import * as Yup from 'yup';
import { CancelButton } from '../FormElements/CancelButton';
import CustomButton from '../FormElements/CustomButton';
import CustomRadioGroup from '../FormElements/CustomRadioGroup';
import { StyledLIDisc, StyledUL } from './styled';
import { RootState } from 'store/types';
import { AppName, getAppName } from 'modules/utils/ConfigUtils';
import useStyles from '../useStyles';

export interface RegisterInsuranceProps {
  initialValues: {
    shSubscriberNumber: string;
    pharmacyPlanId: string;
    medicaidId: string;
    insuranceType: string;
    insuranceId: string;
  };
  updateRegisterMeta: (data: Partial<RegisterMeta>) => void;
  updateStep: (step: string) => void;
  updateAlert: (data: RegisterAlert) => void;
  updateAccountInfo: (data: boolean) => void;
  CreacteAccountOptions: CreacteAccountOptions;
  accountInfo: boolean;
  handleLoginPress: (history: H.History, currentUrl?: string) => void;
  handleUrlOnExisting: (history: H.History) => void;
  handleUrlOnForgetUserName: (history: H.History) => void;
  handleUrlOnCancel: (history: H.History) => void;
}

const getInsuranceOptions = () => {
  const insuranceType: Record<string, string> = getInsuranceType();
  return Object.keys(insuranceType).map(key => ({
    value: insuranceType[key],
    label: insuranceType[key]
  }));
};

const loginOptions = Object.keys(CreacteAccountOptions).map(key => ({
  value: CreacteAccountOptions[key],
  label: CreacteAccountOptions[key]
}));
const InsuranceSchema = Yup.object().shape({
  insuranceType: Yup.string().required()
});

const loginInfoSchema = Yup.object().shape({
  loginInfo: Yup.string().required()
});

const initialValues = {
  insuranceType: ''
};

const loginInfoInitialValues = {
  loginInfo: ''
};
export const RegisterInsurance = ({
  updateRegisterMeta,
  updateStep,
  updateAlert,
  updateAccountInfo,
  accountInfo,
  handleUrlOnForgetUserName,
  handleUrlOnExisting
}: RegisterInsuranceProps) => {
  const [showModal, setShowModal] = useState(false);
  const history = useHistory();

  const handleSubmit = values => {
    const meta = {
      insuranceType: values.insuranceType
    };

    updateRegisterMeta(meta);
    const redirect_index = getRedirectIdx();
    const redirect_brand = getRegisterBrand();
    const insuranceType = getInsuranceType();

    const eventData: AmplitudeEventData = {
      redirect_index,
      redirect_brand
    };
    analyticsService.logEvent(AnalyticsEvent.AccountCreationStarted, eventData);
    if (values.insuranceType === insuranceType.NONE) {
      updateAlert(formatAlert());
      updateStep(Step.PERSONAL);
    } else {
      updateStep(Step.PERSONAL_ABRIDGED);
    }
  };

  const handleLoginInfoSubmit = values => {
    if (values.loginInfo === CreacteAccountOptions.YES) {
      setShowModal(true);
    } else {
      updateAccountInfo(true);
    }
  };

  const additionalButtons = [
    { title: 'Recover username', action: () => handleUrlOnForgetUserName(history) }
  ];

  const redirectBrand = getRegisterBrand();
  const classes = useStyles({ redirectBrand });
  const brandColor = getBrandColor({ redirectBrand });

  return (
    <>
      <ConfirmModal
        isOpen={showModal}
        showCloseIcon
        icon="InfoIcon"
        iconColor={brandColor}
        description="Did you know you can use the same username and password to sign into Intermountain and Select Health?"
        confirmLabel="Sign in"
        onConfirm={() => handleUrlOnExisting(history)}
        cancelLabel="Cancel"
        onCancel={() => setShowModal(false)}
        additionalButtons={additionalButtons}
        fullWidth
        maxWidth="400px"
        position="bottom"
        buttonConfirmClassName={classes.customContainedButton}
        buttonOutlinedClassName={classes.customOutlinedButton}
        buttonTextClassName={classes.customTextButton}
      />
      {accountInfo ? (
        <Formik
          initialValues={initialValues}
          validationSchema={InsuranceSchema}
          onSubmit={handleSubmit}
        >
          {({ values }) => (
            <Form>
              <Grid container spacing={Spacing.small}>
                <Grid item xs={12}>
                  <Spacer spacing="large" />
                  <CustomRadioGroup
                    name="insuranceType"
                    value={values.insuranceType}
                    label="Do you have any of the following identification numbers?"
                    options={getInsuranceOptions()}
                    tooltip={<CustomTooltip />}
                  />
                </Grid>
              </Grid>
              <Spacer spacing="xxLarge" />
              <Box my={3} maxWidth="xs" textAlign="center">
                <CustomButton
                  data-testid="insurance-type-next-button"
                  disabled={!values.insuranceType}
                  type="submit"
                  label="Next"
                  isSubmitting={false}
                  aria-label="submit-button"
                />
                <CancelButton />
              </Box>
            </Form>
          )}
        </Formik>
      ) : (
        <>
          <FlexBox flex={1} justifyContent="center" maxWidth="500px">
            <Spacer spacing="xLarge" />
            <FlexBox flex={1} justifyContent="center">
              <Typography fontSize={FontSize.large} color={Color.black}>
                Before we begin, do you have a username and password for any of the following
                websites or mobile apps?
              </Typography>
            </FlexBox>
            <Spacer spacing="xLarge" />
            <StyledUL>
              <StyledLIDisc>{getAppName(AppName.secondary)}</StyledLIDisc>
              <StyledLIDisc> My Health (Intermountain's legacy portal) </StyledLIDisc>
              <StyledLIDisc> Select Health (online account) </StyledLIDisc>
              <StyledLIDisc> {getAppName(AppName.base)} </StyledLIDisc>
            </StyledUL>
            <Spacer spacing="xLarge" />
            <Formik
              initialValues={loginInfoInitialValues}
              validationSchema={loginInfoSchema}
              onSubmit={handleLoginInfoSubmit}
            >
              {({ values }) => (
                <Form>
                  <Grid container spacing={Spacing.small}>
                    <Grid item xs={12}>
                      <CustomRadioGroup
                        name="loginInfo"
                        value={values.loginInfo}
                        options={loginOptions}
                      />
                    </Grid>
                  </Grid>
                  <Spacer spacing="xLarge" />
                  <Box my={3} maxWidth="xs" textAlign="center">
                    <CustomButton
                      data-testid="login-info-next-button"
                      disabled={!values.loginInfo}
                      type="submit"
                      label="Next"
                      isSubmitting={false}
                      aria-label="submit-button"
                    />
                    <CancelButton />
                  </Box>
                </Form>
              )}
            </Formik>
          </FlexBox>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  accountInfo: accountCreationInfoSelector(state)
});

const mapDispatchToProps = {
  updateRegisterMeta: updateRegistrationMeta,
  updateStep: updateRegistrationStep,
  updateAlert: updateRegistrationAlert,
  updateAccountInfo: updateAccountCreationInfo,
  handleUrlOnExisting,
  handleUrlOnForgetUserName
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterInsurance);
