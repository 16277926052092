import DefaultLabel from 'components/UI/Labels/DefaultLabel';
import { Column } from 'components/UI/Layout/Container';
import { TouchableOpacity } from 'react-native';
import styled from 'styled-components';
import { MuiBox, MuiExpansionPanel, dfdDefaultTheme } from 'theme/material-ui';
import { CollapsibleListItemProps } from './CollapsibleListItem';
import { FontSize } from 'modules/styles/variables';

export const PaddedColumn = styled(Column)`
  padding-left: 15px;
`;
export const TitleText = styled(DefaultLabel)`
  font-weight: bold;
`;
export const SubText = styled(DefaultLabel)`
  font-size: ${FontSize.base};
`;
export const StyledIcon = styled.div`
  margin-left: auto;
`;

export const ExpandableMuiBox = styled(MuiBox)`
  width: 100%;
  margin-top: 0px;
`;

interface TouchableOpacityWrapperProps {
  margin?: string;
}
export const TouchableOpacityWrapper = styled(TouchableOpacity)`
  ${(props: TouchableOpacityWrapperProps) => (props.margin ? `margin: ${props.margin}` : '')}
`;

export const StyledMuiExpansionPanel = styled(MuiExpansionPanel)`
  ${(props: CollapsibleListItemProps) => (props.margin ? `margin: ${props.margin};` : '')};
  ${(props: CollapsibleListItemProps) => (props.padding ? `padding: ${props.padding}` : '')};

  &.MuiExpansionPanel-root.Mui-expanded {
    margin: 0;
  }

  &.MuiExpansionPanel-root.Mui-expanded::before {
    opacity: 1;
  }
  &.MuiExpansionPanel-root.Mui-expanded + .MuiExpansionPanel-root:before {
    display: initial;
  }
  & > .MuiExpansionPanel-root.Mui-expanded {
    &&& {
      padding: 0;
    }
    ${(props: CollapsibleListItemProps) =>
      props.firstListItem ? `padding-top: 0px !important; margin-top: 0px !important;` : ''}
  }

  ${dfdDefaultTheme.breakpoints.up('lg')} {
    & .MuiExpansionPanelSummary-content.Mui-expanded {
      margin: 0;
    }
    & .MuiExpansionPanelSummary-root {
      height: 72px;
      ${(props: CollapsibleListItemProps) => (props.height ? `height: ${props.height};` : '')};
    }
  }
`;
