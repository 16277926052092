import React, { useState, useEffect } from 'react';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { MuiBox, MuiContainer, MuiGrid } from 'theme/material-ui';
import { getPaymentMethod, createVisit, startVisit } from 'store/amwell/actions';
import * as types from 'store/amwell/types.web';
import * as selectors from 'store/amwell/selectors';
import { RootState, RootDispatch } from 'store/types';
import {
  ConnectCareDataLoader,
  ConnectCareStepActions,
  ConnectCareStepAction
} from 'components/ConnectCare';
import { useSnack } from 'components/Snack';
import analyticsService, { AnalyticsEvent } from 'services/AnalyticsService';
import {
  currentLocationPathNameSelector,
  previousLocationPathNameSelector
} from 'store/router/selectors';
import { Alert } from 'components/Alert';
import { paymentMethod as paymentMethodConfirmations } from 'modules/constants/amwell';
import ConnectCarePaymentMethodSelectCost from './ConnectCarePaymentMethodSelectCost';
import ConnectCarePaymentMethodSelectSaved from './ConnectCarePaymentMethodSelectSaved';
import { FormScreen } from '../styled';

export interface Props extends RouteComponentProps {
  activeConsumer: types.Consumer | null;
  insuranceStatus: string;
  selectedPractice: types.Practice;
  selectedProvider: types.ProviderDetails;
  providerDetailsWereViewed: boolean;
  preferredPharmacyWasUpdated: boolean;
  dispatch: RootDispatch;
  visit: types.Visit | null;
  visitLoading: boolean;
  visitError: Error | null;
  paymentMethod: types.PaymentMethod | null;
  paymentMethodLoading: boolean;
  paymentMethodWasUpdated: boolean;
  paymentMethodError: Error | null;
  currentUrl?: string;
  referringUrl?: string;
}

export function ConnectCarePaymentMethodSelect(props: Props) {
  const {
    activeConsumer,
    insuranceStatus,
    selectedPractice,
    selectedProvider,
    providerDetailsWereViewed,
    preferredPharmacyWasUpdated,
    visit,
    visitLoading,
    visitError,
    paymentMethod,
    paymentMethodLoading,
    paymentMethodWasUpdated,
    paymentMethodError,
    history,
    dispatch,
    currentUrl,
    referringUrl
  } = props;
  const { create } = useSnack();
  const [stepLoading, setStepLoading] = useState(false);
  const loading = visitLoading || paymentMethodLoading;
  const error = visitError || paymentMethodError;
  const hasCopayCost = visit?.cost?.expectedConsumerCopayCost !== 0;
  const calculationFinished = visit?.cost?.costCalculationStatus === 'FINISHED';
  const isCouponApplied = visit?.cost?.couponCode !== null && visit?.cost?.canApplyCouponCode;
  const noInsuranceStatus =
    insuranceStatus === 'skip' ? 'Plan not listed/Skip' : 'Does not have insurance';

  const fetchVisit = () => {
    if (!visit) {
      dispatch(createVisit());
    }

    dispatch(getPaymentMethod());
  };

  useEffect(fetchVisit, []);

  const historyBack = () => {
    history.goBack();
  };

  const showAlert = () => {
    Alert.alert('Are you sure?', 'Your progress will be lost', [
      { text: 'Ok', onPress: historyBack },
      { text: 'Cancel' }
    ]);
  };
  // Show alert message when user goes back after entering coupon code
  // if user click ok in message modal it takes to pharmacies page
  const onPrevClick = () => {
    if (isCouponApplied) {
      showAlert();
    } else {
      historyBack();
    }
  };

  const onNextClick = () => {
    setStepLoading(true);

    analyticsService.logEvent(AnalyticsEvent.ConnectCarePreVisitFormsComplete, {
      state: activeConsumer?.legalResidence?.code,
      practice_selected: selectedPractice?.name,
      patient_account: activeConsumer?.isDependent ? 'Dependent' : 'Primary',
      provider: selectedProvider?.fullName,
      provider_title: selectedProvider?.specialty?.value,
      view_provider_details: providerDetailsWereViewed ? 'Yes' : 'No',
      terms_and_conditions: 'Agree',
      insurance: insuranceStatus === 'insured' ? 'Has insurance' : noInsuranceStatus,
      pharmacy_location: preferredPharmacyWasUpdated ? 'Changed' : 'No change',
      triage: 'Complete',
      medical_history: 'Complete',
      payment_information: paymentMethodWasUpdated
        ? 'Changed payment method'
        : 'No change to payment method',
      currentUrl,
      referringUrl
    });

    dispatch(startVisit({ visit })).then((res: AnyAction) => {
      setStepLoading(false);
      if (res.error) {
        create('There was an issue starting your visit', 'error');
      } else {
        history.push('/u/get-care-now/connect-care/form/waiting-room');
      }
    });
  };

  const errorComponentProps = {
    message: paymentMethodConfirmations.GET_ERROR.title,
    action: { onClick: fetchVisit, label: 'Try Again?' }
  };

  const renderNoData = !visit && !paymentMethod && !loading;
  return (
    <>
      <FormScreen>
        <MuiContainer maxWidth="lg">
          <MuiBox my={3} display="flex" justifyContent="center">
            <ConnectCareDataLoader
              data={{ visit, paymentMethod }}
              loading={loading}
              error={error || (!loading && !calculationFinished)}
              errorComponentProps={errorComponentProps}
              noDataComponentProps={errorComponentProps}
              renderNoData={renderNoData}
              renderData={data => (
                <MuiGrid
                  container
                  spacing={4}
                  style={{ width: 'auto' }}
                  direction="column"
                  wrap="nowrap"
                >
                  <MuiGrid item xs={12}>
                    <ConnectCarePaymentMethodSelectCost cost={data.visit.cost} />
                  </MuiGrid>
                  <MuiGrid item xs={12}>
                    <ConnectCarePaymentMethodSelectSaved paymentMethod={data.paymentMethod} />
                  </MuiGrid>
                </MuiGrid>
              )}
            />
          </MuiBox>
        </MuiContainer>
      </FormScreen>
      <ConnectCareStepActions>
        <ConnectCareStepAction onClick={onPrevClick} />
        <ConnectCareStepAction
          disabled={!visit || !calculationFinished || (hasCopayCost && !paymentMethod)}
          loading={stepLoading}
          onClick={onNextClick}
          label="Start"
        />
      </ConnectCareStepActions>
    </>
  );
}

const mapStateToProps = (state: RootState) => ({
  activeConsumer: selectors.activeConsumerDataSelector(state),
  insuranceStatus: selectors.activeConsumerInsuranceStatusSelector(state),
  selectedPractice: selectors.practiceDetailsDataSelector(state),
  selectedProvider: selectors.providerDetailsDataSelector(state),
  providerDetailsWereViewed: selectors.providerDetailsWereViewedSelector(state),
  preferredPharmacyWasUpdated: selectors.preferredPharmacyWasUpdatedSelector(state),
  visit: selectors.visitDataSelector(state),
  visitLoading: selectors.visitCreateLoadingSelector(state),
  visitError: selectors.visitCreateErrorSelector(state),
  paymentMethod: selectors.paymentMethodDataSelector(state),
  paymentMethodLoading: selectors.paymentMethodDataLoadingSelector(state),
  paymentMethodWasUpdated: selectors.paymentMethodWasUpdatedSelector(state),
  paymentMethodError: selectors.paymentMethodDataErrorSelector(state),
  currentUrl: currentLocationPathNameSelector(state),
  referringUrl: previousLocationPathNameSelector(state)
});

export default connect(mapStateToProps)(ConnectCarePaymentMethodSelect);
