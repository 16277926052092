import Config from 'react-native-config';

const SCRIPT_ID = 'google-maps-js-api';

const mapsPromise = new Promise(resolve => {
  window.initMap = function(mapsApi) {
    resolve(mapsApi);
  };
});

function initGoogleScript() {
  const script = document.createElement('script');
  script.src = `https://maps.googleapis.com/maps/api/js?key=${Config.GOOGLE_GEOCODE_KEY}&callback=initMap`;
  script.id = SCRIPT_ID;
  script.defer = true;
  script.async = true;
  document.head.appendChild(script);
}

export async function getGoogleMap() {
  const script = document.getElementById(SCRIPT_ID);
  if (!script) {
    initGoogleScript();
  }
  return mapsPromise;
}
