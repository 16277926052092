import React from 'react';
import styled from 'styled-components';
import { dfdDefaultTheme } from 'theme/material-ui';

const StyledChartLegend = styled.div`
  display: flex;
  align-items: center;
  margin-left: 4px;
`;

const StyledLabel = styled.span`
  font-size: 12px;

  ${dfdDefaultTheme.breakpoints.up('lg')} {
    font-size: 16px;
  }
`;

const Circle = styled.div<{ $bgColor?: string }>`
  width: 10px;
  height: 10px;
  border-radius: 100px;
  background-color: ${({ $bgColor }) => $bgColor || 'gray'};
  margin-right: 4px;
`;

interface ChartLegendProps {
  children: React.ReactNode;
  color?: string;
}

const ChartLegend = ({ children, color }: ChartLegendProps) => (
  <StyledChartLegend>
    <Circle $bgColor={color} />
    <StyledLabel>{children}</StyledLabel>
  </StyledChartLegend>
);

export default ChartLegend;
