import React from 'react';

export default {
  svg: (
    <g>
      <path
        d="M42,25 C39.1649267,30.9277399 33.2886867,35 26.499839,35 C19.7109913,35 13.8353953,30.9280754 11,25.0006709"
        fill="none"
        strokeWidth="2.298"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.04753931,18.7534703 C9.01595497,18.3253727 9,17.8930525 9,17.4565098 C9,7.81554294 16.8348684,0 26.5,0 C36.164806,0 44,7.81554294 44,17.4565098 C44,17.9765283 43.9768816,18.4913498 43.9325984,19"
        fill="none"
        strokeWidth="2.298"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <polyline
        points="0 18.9386027 16.103274 18.9386027 21.6215631 12 30.9231573 24 35.0433808 19.1819964 51 19.1819964"
        fill="none"
        strokeWidth="2.298"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M56,19 L56,19"
        fill="none"
        strokeWidth="2.298"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M58.7071302,49.7071392 L58.7071302,49.7071392 C56.9564198,51.4578375 54.2337508,51.4038584 52.7621534,49.7071392 L39.2814528,34.1667713 C38.6903124,33.4847922 39.0638894,
        32.1119472 40.0881817,31.0883203 C41.1121449,30.0640352 42.4846703,29.6901316 43.1666541,30.281597 L58.7071302,43.7622038 C60.4038612,45.2337909 60.4578407,47.9561119 58.7071302,49.7071392 Z"
        fill="none"
        strokeWidth="2.298"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37,10 C37.3876629,10.6255995 37.7232119,11.2946643 38,12"
        fill="none"
        strokeWidth="2.298"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14,18 C14,10.8202069 19.743252,5 26.8277154,5 C29.4846313,5 31.9526669,5.81804224 34,7.22016663"
        fill="none"
        strokeWidth="2.298"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15,24 C14.6123371,23.3744942 14.2767881,22.70523 14,22"
        fill="none"
        strokeWidth="2.298"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39,19 C38.1050015,25.2191749 32.7239609,30 26.2191349,30 C23.5448637,30 21.0610315,29.1918367 19,27.8083982"
        fill="none"
        strokeWidth="2.298"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M43,38 L47,34"
        fill="none"
        strokeWidth="2.298"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  ),
  viewBox: '0 0 64 60'
};
