import { Box, Grid, Paper } from '@material-ui/core';
import { headerHeight } from 'components/AuthLayout/styled';
import FlexBox from 'components/UI/Layout/FlexBox';
import Typography from 'components/UI/Typography';
import { Color } from 'modules/styles/colors';
import { FontSize, Spacing, smallFontSize, largeFontSize } from 'modules/styles/variables';
import { RedirectBrand } from 'store/register';
import styled from 'styled-components';
import { dfdDefaultTheme, MuiBox } from 'theme/material-ui';

export const ProgressContainer = styled(Box)`
  display: flex;
  flex-direction: row;
`;

export const StyledMuiBox = styled(MuiBox)`
  margin-top: ${headerHeight}px;
  justify-content: center;
`;

export const CloseMuiBox = styled(MuiBox)`
  text-align: right;
`;

export const StyledUserGrid = styled(Grid)`
  padding: ${Spacing.large}px;
`;

export const StyledFieldTip = styled(Paper)`
  &&& {
    background-color: ${Color.grayLight3};
    box-shadow: none;
    margin-top: ${Spacing.medium}px;
    ${dfdDefaultTheme.breakpoints.up('md')} {
      position: absolute;
      width: 185px;
      right: -185px;
      top: ${Spacing.xLarge}px;
    }
  }
`;
export const StyledPwdGrid = styled(Grid)`
  padding: ${Spacing.large}px;
`;

export const RequiredTextField = styled.span`
  color: ${Color.red};
`;

export const StyleTerms = styled(Paper)`
  height: 500px;
  padding: ${Spacing.medium}px;
  overflow-y: auto;
`;

export const RegistrationContainer = styled(Box)`
  background-color: ${Color.foreColor};
  flex-direction: column;
  display: flex;
  flex: 1;
  overflow: auto;
`;

export const RegistrationStepperContainer = styled(Box)`
  background-color: ${Color.white};
`;

export const RegistrationStepper = styled(Box)`
  padding-bottom: 35px;
  ${dfdDefaultTheme.breakpoints.between('xs', 'sm')} {
    position: absolute;
    z-index: 100;
    top: 70px;
    right: 0px;
    width: 100%;
    padding-bottom: 0px;
  }
`;

export const RegistrationHeaderContainer = styled(Box)`
  display: flex;
  flex-direction: column;
`;

export const RegistrationHeaderLogo = styled(FlexBox)`
  ${dfdDefaultTheme.breakpoints.between('xs', 'sm')} {
    display: none;
  }
`;

export const RegistrationFooterLogo = styled(FlexBox)`
  display: none;
  ${dfdDefaultTheme.breakpoints.between('xs', 'sm')} {
    display: block;
  }
`;

export const RegistrationMainContainer = styled(Box)`
  padding: 2px;
  ${dfdDefaultTheme.breakpoints.between('xs', 'sm')} {
    margin-top: ${Spacing.largeXLarge}px;
  }
`;

interface Props {
  registerBrand: RedirectBrand;
}
export const RegistrationTitle = styled(Typography)<Props>`
  color: ${props => (props.registerBrand === RedirectBrand.MHP ? Color.primary : Color.textDark)};
  // Minimum width at which the title text still plays nice with the X icon while remaining "title-y"
  min-width: 220px;
  ${dfdDefaultTheme.breakpoints.down('xs')} {
    // Title text needs a downward bump at this breakpoint
    font-size: ${FontSize.mediumHeading}px;
    padding-bottom: 16px;
  }
  @media (max-width: 279.95px) {
    // Title text and X icon begin to cross each other at this "breakpoint".
    // To compensate, the text alignment switches to left
    padding-left: ${Spacing.mediumLarge}px;
    text-align: left;
  }
`;

export const FooterText = styled.div`
  color: ${Color.textLight};
  font-size: ${smallFontSize};
  line-height: 24px;
`;

export const CrossIconContainer = styled(MuiBox)`
  display: none;
  ${dfdDefaultTheme.breakpoints.between('xs', 'sm')} {
    display: inline-block;
    right: ${Spacing.mediumLarge}px;
    top: -${Spacing.smallMedium}px;
    position: absolute;
  }
`;

export const StyledTypography = styled(MuiBox)`
  width: 100%;
  height: 50px;
  text-align: center;
  font-size: ${largeFontSize};
  padding: ${Spacing.mediumLarge}px;
`;
