import React, { ComponentType, SyntheticEvent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Icon } from 'components/Icon';
import styled from 'styled-components';

import { SnackBar } from 'screens/Messaging/styled';
import {
  MuiBox,
  MuiContainer,
  MuiGrid,
  MuiPaper,
  MuiTypography,
  MuiAlert
} from 'theme/material-ui';
import Spacer from 'components/UI/Layout/Spacer';
import FlexBox from 'components/UI/Layout/FlexBox';
import Footer from 'components/common/Footer';
import { Color } from 'modules/styles/colors';
import { SpinnerOverlay } from 'components/UI/Spinner/SpinnerModal';
import { FontSize, FontWeight, IconSize } from 'modules/styles/variables';
import { patientSelect } from 'modules/constants/covidScreening';
import { MAX_FILE_SIZE } from 'modules/constants/covidScreening/insuranceFound';
import { RootState } from 'store/types';
import { InsuranceCard } from 'store/CovidScreening/types';
import { handleInsuranceUploadErrors } from 'store/CovidScreening/utils';
import * as covidActions from 'store/CovidScreening/actions';
import { CovidScreeningInsuranceCardSelector } from 'store/CovidScreening/selectors';
import { CovidTestButton } from '../Components/CovidTestButton';
import useStyles from '../Components/CovidTestButton/useStyles';
import { CovidTitles } from 'lib/constants/covidScreening';
import { AnalyticsEvent } from 'services/AnalyticsService';
import useNavigationAnalytics from 'hooks/useNavigationAnalytics';

const PreviewImg = styled.img`
  width: 250px;
  height: 200px;
`;

const PreviewIFrame = styled.iframe`
  height: 200px;
`;

const PreviewFlexBox = styled(FlexBox)`
  height: 220px;
  align-items: center;
  justify-content: center;
`;

const RoundedMuiPaper = styled(MuiPaper)`
  border-radius: 10px;
`;

interface Props extends RouteComponentProps {
  insuranceCard: InsuranceCard[];
  setInsuranceCard: typeof covidActions.setInsuranceCard;
  addInsuranceCardFile: typeof covidActions.addInsuranceCard;
}

export function CovidScreeningInsuranceUpload({
  history,
  insuranceCard,
  setInsuranceCard,
  addInsuranceCardFile
}: Props) {
  const classes = useStyles();

  const [cardFront, setCardFront] = useState<InsuranceCard | undefined>();
  const [cardBack, setCardBack] = useState<InsuranceCard | undefined>();
  const [errorMessage, setErrorMessage] = useState('');

  const KEY_FRONT = 'Front';
  const KEY_BACK = 'Back';

  const covidScreeningNextBtn = useNavigationAnalytics(AnalyticsEvent.CovidNext, {
    title: CovidTitles.INSURANCE_CARD_UPLOAD
  });

  const covidScreeningPreviousBtn = useNavigationAnalytics(AnalyticsEvent.CovidPrev, {
    title: CovidTitles.INSURANCE_CARD_UPLOAD
  });

  const covidInsuranceCardUploadSkip = useNavigationAnalytics(
    AnalyticsEvent.CovidInsuranceCardUploadSkipped,
    { title: CovidTitles.INSURANCE_CARD_UPLOAD }
  );

  useEffect(() => {
    if (insuranceCard?.length) {
      setCardFront(insuranceCard?.[0]);
      setCardBack(insuranceCard?.[1]);
    }
  }, []);

  const covidInsuranceUploadErrorEvent = useNavigationAnalytics(
    AnalyticsEvent.CovidInsuranceUploadError
  );
  const [isLoading, setIsLoading] = useState(false);

  const onUpload = (evt: SyntheticEvent, key: string) => {
    const file = evt.target.files[0];
    if (file?.size > MAX_FILE_SIZE) {
      setErrorMessage('File size limit is 10MB');
      covidInsuranceUploadErrorEvent({
        errorMessage: 'File size limit is 10MB',
        title: CovidTitles.INSURANCE_CARD_UPLOAD
      });
      return;
    }

    setIsLoading(true);
    const reader = new window.FileReader();
    reader.onloadend = async () => {
      if (file) {
        const { error, azureFileName, actionStatus, errorCode } = await addInsuranceCardFile(file);
        const fileString = reader.result ? (reader.result as string) : '';

        setIsLoading(false);
        if (!error && actionStatus === 'scannedUploaded') {
          const uploadedData = {
            fileName: file.name,
            img: azureFileName,
            mimeType: file.type,
            fileString
          };

          if (key === KEY_FRONT) setCardFront(uploadedData);
          else setCardBack(uploadedData);
        } else {
          covidInsuranceUploadErrorEvent({
            errorMessage: handleInsuranceUploadErrors(error, errorCode),
            title: CovidTitles.INSURANCE_CARD_UPLOAD
          });
          setErrorMessage(handleInsuranceUploadErrors(error, errorCode));
        }
      }
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  // This ties the input to the button, so we can use the style of the button. <input /> does not accept children.
  const handleFileClick = (id: string) => {
    const ele = document.getElementById(id) as HTMLElement;
    ele.click();
  };

  const removeUploaded = (key: string) => {
    if (key === KEY_FRONT) setCardFront(undefined);
    else setCardBack(undefined);
  };

  const handlePrevious = () => {
    covidScreeningPreviousBtn();
    history.goBack();
  };

  const handleSkip = () => {
    setInsuranceCard([]);
    covidInsuranceCardUploadSkip();
    return history.push('/u/get-care-now/covid-screen/summary-information');
  };

  const handleSubmit = () => {
    const insuranceData = [cardFront, cardBack];
    setInsuranceCard(insuranceData);
    covidScreeningNextBtn();
    return history.push('/u/get-care-now/covid-screen/summary-information');
  };

  const renderUploadButton = (key: string) => {
    const ButtonId = `UploadInsurance-${key}-${Date.now()}`;
    const currentState = key === KEY_FRONT ? cardFront : cardBack;
    return (
      <MuiGrid xs={8}>
        <MuiTypography fontSize={FontSize.large}>{key} of Insurance Card</MuiTypography>
        <MuiBox mt={1} p={1}>
          <RoundedMuiPaper elevation={2} square>
            {currentState ? (
              <FlexBox flex={1}>
                <FlexBox alignItems="flex-end">
                  <Icon name="close" onClick={() => removeUploaded(key)} color={Color.black} />
                </FlexBox>
                <FlexBox alignItems="center">
                  {currentState?.mimeType?.includes('pdf') ? (
                    <PreviewIFrame title="pdf" src={currentState?.fileString}></PreviewIFrame>
                  ) : (
                    <PreviewImg src={currentState?.fileString} />
                  )}
                </FlexBox>
              </FlexBox>
            ) : (
              <PreviewFlexBox flex={1} onClick={() => handleFileClick(ButtonId)}>
                <Spacer spacing="xLarge" />
                <FlexBox alignItems="center">
                  <Icon name="publish" size={IconSize.xLarge} />
                </FlexBox>
                <MuiTypography textAlign="center">Click here to upload file</MuiTypography>
                <input
                  id={ButtonId}
                  data-testid="camera-button-input"
                  type="file"
                  onChange={e => onUpload(e, key)}
                  accept="image/*, application/pdf"
                  style={{ display: 'none' }}
                />
                <Spacer spacing="xLarge" />
              </PreviewFlexBox>
            )}
          </RoundedMuiPaper>
        </MuiBox>
      </MuiGrid>
    );
  };

  return (
    <MuiContainer maxWidth="md">
      <SpinnerOverlay
        isLoading={isLoading}
        position="absolute"
        backgroundColor={Color.translucent}
        testID="Covid-Insurance-Card-Upload"
      />
      <Spacer spacing="xLarge" />
      <MuiTypography fontSize={FontSize.large} fontWeight={FontWeight.bold}>
        Please upload your insurance card (Optional).
      </MuiTypography>
      <Spacer spacing="xLarge" />
      <MuiTypography fontWeight={FontWeight.bold}>Primary Insurance</MuiTypography>
      <Spacer spacing="xLarge" />
      <FlexBox flexDirection="row" justifyContent="space-between">
        {renderUploadButton(KEY_FRONT)}
        {renderUploadButton(KEY_BACK)}
      </FlexBox>
      <Spacer spacing="large" />
      {errorMessage ? (
        <SnackBar
          key={errorMessage}
          open
          autoHideDuration={8000}
          onClose={() => setErrorMessage('')}
        >
          <MuiAlert severity="error">{errorMessage}</MuiAlert>
        </SnackBar>
      ) : null}
      <Spacer spacing="large" />
      <MuiBox className={classes.steppersFooter}>
        <CovidTestButton
          fullWidth
          data-testid="insurance-upload-next-btn"
          color="primary"
          variant="contained"
          onClick={handleSubmit}
          disabled={!cardFront && !cardBack}
        >
          Next
        </CovidTestButton>
        <CovidTestButton
          color="default"
          data-testid="insurance-skip-button"
          ariant="contained"
          onClick={handleSkip}
          fullWidth
        >
          Skip
        </CovidTestButton>
        <CovidTestButton
          variant="text"
          data-testid="insurance-upload-previous-btn"
          onClick={handlePrevious}
          fullWidth
        >
          Previous
        </CovidTestButton>
      </MuiBox>
      <Footer
        headerText={patientSelect.GET_PATIENTS.HELPLINE.CovidInfo}
        link={patientSelect.GET_PATIENTS.HELPLINE.CovidPatientSelectHelpline}
        linkName={patientSelect.GET_PATIENTS.HELPLINE.CovidPatientSelectHelpline}
        helplineHours={patientSelect.GET_PATIENTS.HELPLINE.CovidHelplineHours}
        title={CovidTitles.INSURANCE_CARD_UPLOAD}
      />
    </MuiContainer>
  );
}

const mapStateToProps = (state: RootState) => ({
  insuranceCard: CovidScreeningInsuranceCardSelector(state)
});

const mapDispatchToProps = {
  setInsuranceCard: covidActions.setInsuranceCard,
  addInsuranceCardFile: covidActions.addInsuranceCard
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CovidScreeningInsuranceUpload as ComponentType);
