import React from 'react';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import * as actions from 'store/amwell/actions';
import { visitDataSelector } from 'store/amwell/selectors';
import { Visit, Disposition } from 'store/amwell/types';
import { RootState, RootDispatch } from 'store/types';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core';
import { MuiButton } from 'theme/material-ui';
import { ACTIVE_VISIT } from '../constants';

export interface ActiveVisitDialogProps {
  visit: Visit | null;
  onReject: () => void;
  onRejoin: (path: string) => void;
  onError: () => void;
  cancelVisit: (visit: Visit) => AnyAction;
  endVisit: (visit: Visit) => AnyAction;
}

export function ActiveVisitDialog({
  visit,
  onError,
  onRejoin,
  onReject,
  cancelVisit,
  endVisit
}: ActiveVisitDialogProps) {
  const handleReject = async () => {
    let response: AnyAction;

    if (visit?.disposition === Disposition.InProgress) {
      response = await endVisit({ visit });
    } else {
      response = await cancelVisit({ visit });
    }

    if (response.error) {
      onError();
    } else {
      onReject();
    }
  };

  const handleRejoin = () => {
    onRejoin('/u/get-care-now/connect-care/form/waiting-room');
  };

  return (
    <Dialog
      open
      disableBackdropClick
      disableEscapeKeyDown
      aria-labelledby="active-visit-dialog-title"
      aria-describedby="active-visit-dialog-description"
    >
      <DialogTitle id="active-visit-dialog-title">{ACTIVE_VISIT.title}</DialogTitle>

      <DialogContent>
        <DialogContentText
          color="textPrimary"
          id="active-visit-dialog-description"
          component="span"
        >
          {ACTIVE_VISIT.subtitle}
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <MuiButton onClick={handleReject} color="primary" variant="outlined">
          No
        </MuiButton>
        <MuiButton onClick={handleRejoin} color="primary" variant="contained">
          Yes
        </MuiButton>
      </DialogActions>
    </Dialog>
  );
}

const mapStateToProps = (state: RootState) => ({
  visit: visitDataSelector(state)
});

const mapDispatchToProps = (dispatch: RootDispatch) => ({
  cancelVisit: (visit: Visit) => dispatch(actions.cancelVisit({ visit })),
  endVisit: (visit: Visit) => dispatch(actions.endVisit({ visit }))
});

export default connect(mapStateToProps, mapDispatchToProps)(ActiveVisitDialog);
