import React, { ComponentType, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import { Color } from 'modules/styles/colors';
import { Spacing, IconSize, FontWeight } from 'modules/styles/variables';
import { notificationsButtonsText, eVisitSuccessText } from 'lib/evisit/constants';
import LinkingServices from 'services/LinkingServices';

import { SpinnerOverlay } from 'components/UI/Spinner/SpinnerModal';
import Spacer from 'components/UI/Layout/Spacer';
import { MuiBox, MuiTypography, MuiContainer, MuiPaper, MuiButton } from 'theme/material-ui';
import { Icon } from 'components/Icon';

import analyticsService, {
  AmplitudeEventData,
  AnalyticsEvent,
  AnalyticsUserAttributes
} from 'services/AnalyticsService';
import { getNotificationsPreferences } from 'store/notifications/actions';
import {
  notificationsChannelsSelector,
  notificationsPreferencesFetchingSelector
} from 'store/notifications/selectors';
import { profileConsumerSelector } from 'store/profile/selectors';
import { eVisitSessionStateSelector } from 'store/eVisit/selectors';

export interface QueryParams {
  encounterId: string;
}

export const EVisitSuccessScreen = () => {
  const { encounterId = '' } = useParams() as QueryParams;
  const history = useHistory();
  const dispatch = useDispatch();
  const notificationPreferencesLoading = useSelector(notificationsPreferencesFetchingSelector);
  const notificationsChannels = useSelector(notificationsChannelsSelector);
  const userProfile = useSelector(profileConsumerSelector);
  const state = useSelector(eVisitSessionStateSelector);
  const hasChannelsEnabled = !!Object.values(notificationsChannels).find(c => c.enabled);

  useEffect(() => {
    if (isEmpty(notificationsChannels)) {
      dispatch(getNotificationsPreferences());
    }
    const data: AmplitudeEventData & AnalyticsUserAttributes = {
      state,
      medical_history: 'Complete',
      allergies: 'Complete',
      consent_to_treat_accepted: 'Yes',
      notice_of_privacy_practice_accepted: 'Yes',
      age: userProfile.age,
      gender: userProfile.gender
    };

    analyticsService.logEvent(AnalyticsEvent.EVisitComplete, data);
  }, []);

  if (notificationPreferencesLoading) {
    return (
      <SpinnerOverlay
        isLoading={!notificationPreferencesLoading}
        testID="notifications-preferences-loading"
      />
    );
  }

  const buttonText = notificationsButtonsText(hasChannelsEnabled);

  return (
    <MuiContainer maxWidth="lg">
      <MuiPaper>
        <MuiBox py={Spacing.medium} mt={Spacing.medium}>
          <MuiBox mb={Spacing.small} display="flex" justifyContent="center">
            <Icon name="check-circle-outline" color={Color.green} size={IconSize.xLarge} />
          </MuiBox>
          <MuiTypography variant="h5" align="center">
            {eVisitSuccessText.title}
          </MuiTypography>
          <MuiContainer maxWidth="sm">
            {encounterId ? (
              <MuiBox
                my={Spacing.small}
                p={1}
                bgcolor={Color.grayLight3}
                borderRadius={Spacing.medium}
                display="flex"
                justifyContent="center"
                data-testid="encounter-id"
              >
                <MuiTypography variant="h6">Encounter Number: {encounterId}</MuiTypography>
              </MuiBox>
            ) : null}
            <MuiTypography align="center" variant="subtitle2" fontWeight={FontWeight.normal}>
              {buttonText.title}
            </MuiTypography>
            <MuiBox my={Spacing.small} textAlign="center">
              <MuiButton
                variant="contained"
                color="primary"
                fullWidth
                data-testid="setup-button"
                onClick={() => history.push('/u/manage-account/notifications')}
              >
                {buttonText.topButton}
              </MuiButton>
              <Spacer size="small" />
              <MuiButton
                variant="outlined"
                fullWidth
                data-testid="next-button"
                onClick={() => history.push('/u/dashboard')}
              >
                {buttonText.bottomButton}
              </MuiButton>
            </MuiBox>
            <MuiBox
              border={1}
              borderRadius={Spacing.medium}
              width="100%"
              display="flex"
              flexDirection="row"
              p={1}
              alignItems="center"
              justifyContent="center"
            >
              <Icon name="access-time" size={IconSize.base} color={Color.textDark} />
              <MuiTypography style={{ lineHeight: 1 }}>
                {' '}
                {eVisitSuccessText.estimatedTime}
              </MuiTypography>
            </MuiBox>
          </MuiContainer>
        </MuiBox>
        <MuiBox bgcolor={Color.grayLight3} p={1} justifyContent="center" display="flex">
          <MuiTypography align="center">
            {eVisitSuccessText.footer[0]}
            <MuiTypography align="center">{eVisitSuccessText.footer[1]}</MuiTypography>
            <MuiTypography
              align="center"
              color={Color.link}
              onClick={() => LinkingServices.callPhoneNumber(eVisitSuccessText.phone)}
            >
              {eVisitSuccessText.phone}
            </MuiTypography>
          </MuiTypography>
        </MuiBox>
      </MuiPaper>
    </MuiContainer>
  );
};

export default EVisitSuccessScreen as ComponentType;
