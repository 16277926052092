import React from 'react';
import Divider from 'components/UI/Divider';
import { MuiBox, MuiTypography, MuiButton, MuiLink } from 'theme/material-ui';
import FlexBox from 'components/UI/Layout/FlexBox';
import Spacer from 'components/UI/Layout/Spacer';
import Typography from 'components/UI/Typography';
import { FinancialAssistanceApplication as Application } from 'store/billing/types';
import analyticsService, { AmplitudeEventData, AnalyticsEvent } from 'services/AnalyticsService';
import { useSelector } from 'react-redux';
import {
  currentLocationPathNameSelector,
  previousLocationPathNameSelector
} from 'store/router/selectors';
import { useHistory } from 'react-router-dom';
import { ApplicationSection } from './shared';
import { PATIENT_FINANCIAL_SERVICE_NUMBER } from 'screens/Billing/FinancialAssistance/FAApplication/constants';

interface DeclinedProps {
  application: Application;
}

export const DeclinedStatus = ({ application }: DeclinedProps) => {
  const history = useHistory();

  const currentUrl = useSelector(currentLocationPathNameSelector);
  const referringUrl = useSelector(previousLocationPathNameSelector);

  const eventData: AmplitudeEventData = {
    currentUrl,
    referringUrl
  };

  return (
    <>
      <ApplicationSection application={application} />
      <Divider />
      <Spacer spacing="large" />
      <MuiBox flexDirection="column">
        <Typography accessibilityLabel="Declined status message">
          Your application was not approved. You may need to submit additional income information,
          or you may not meet eligibility requirements.
        </Typography>

        <Spacer spacing="large" />
      </MuiBox>
      <Spacer spacing="medium" />
      <Divider />
      <Spacer size="medium" />
      <MuiBox display="flex" justifyContent="center" alignItems="center">
        <MuiButton
          onClick={() => {
            analyticsService.logEvent(AnalyticsEvent.ReApplyClicked, eventData);
            history.push('/u/fa-app');
          }}
          variant="contained"
          color="primary"
          data-testid="re-apply for assistance button"
        >
          Re-apply for assistance
        </MuiButton>
      </MuiBox>
      <Spacer size="medium" />
      <MuiBox display="flex" justifyContent="center" alignItems="center">
        <MuiButton
          variant="outlined"
          color="primary"
          onClick={() => history.push('/u/billing/bills')}
          data-testid="pay outstanding bills button"
        >
          Pay outstanding bills
        </MuiButton>
      </MuiBox>
      <Spacer size="small" />
      <FlexBox justifyContent="center" alignItems="center" padding="10px">
        <Spacer size="small" />
        <MuiTypography display="inline">
          <MuiTypography display="inline">Billing questions?</MuiTypography>{' '}
          <MuiLink data-testid="service-number" href={`tel: ${PATIENT_FINANCIAL_SERVICE_NUMBER}`}>
            {PATIENT_FINANCIAL_SERVICE_NUMBER}
          </MuiLink>
        </MuiTypography>
      </FlexBox>
    </>
  );
};

export default DeclinedStatus;
