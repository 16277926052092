import React from 'react';
import { Svg } from 'components/UI/Svg';
import { Color } from 'modules/styles/colors';
import { IconSize } from 'modules/styles/variables';
import { MY_DOCS } from 'store/booking/constants';
import { ProviderSummary } from 'store/findProvider/types';
import ProviderItem from './ProviderItem';
import { MuiButton, MuiBox } from 'theme/material-ui';
import { history } from 'lib/history';
import { ProviderItemsContainer, StyledMuiBox } from './styled';
import { useLanguageSwitcher } from 'lib/hooks/useLanguageSwitcher';
import { ProviderList as translations } from 'lib/constants/translations/components/dashboard';
import { setDoctor } from 'store/booking/actions';
import { useDispatch } from 'react-redux';

const MAX_PROVIDERS_SHOWN = 3;

export interface ProviderListProps {
  providerList: ProviderSummary[];
}

const ProviderList = ({ providerList }: ProviderListProps) => {
  const screenText = useLanguageSwitcher(translations);
  const showButton = providerList.length > MAX_PROVIDERS_SHOWN;
  const filteredProviderList = providerList.slice(0, 3);
  const dispatch = useDispatch();
  const handleProviderSelect = (provider: ProviderSummary) => {
    dispatch(setDoctor(provider));
    history.push('/u/provider/kyruus/details');
  };
  return (
    <MuiBox>
      <ProviderItemsContainer>
        {filteredProviderList.map(provider => (
          <ProviderItem
            key={provider.corpProvId}
            name={provider.displayName}
            specialty={provider.primarySpecialty?.specialtyName}
            pictureUrl={provider.providerProfileImageUrl}
            isSelectHealth={provider.isSelectHealth}
            onClick={() => handleProviderSelect(provider)}
          />
        ))}
      </ProviderItemsContainer>
      {showButton ? (
        <StyledMuiBox width="100%">
          <MuiButton
            data-testid="all-my-care-team-button"
            onClick={() => {
              history.push('/u/get-care-now', {
                isExpanded: MY_DOCS
              });
            }}
            endIcon={
              <Svg color={Color.white} set="material" name="chevron-right" size={IconSize.base} />
            }
            variant="contained"
            color="primary"
          >
            {screenText?.btnText}
          </MuiButton>
        </StyledMuiBox>
      ) : null}
    </MuiBox>
  );
};

export default ProviderList;
