import React, { useCallback } from 'react';
import DataLoader, { DataLoaderProps } from 'components/UI/DataLoader/DataLoader';
import { ConnectCareLoading } from '../ConnectCareLoading';
import { ConnectCareError, ConnectCareErrorProps } from '../ConnectCareError';

type OmittedProps = 'renderLoading';
type BaseDataLoaderProps<T> = Omit<DataLoaderProps<T>, OmittedProps>;

export interface ConnectCareDataLoader<T> extends BaseDataLoaderProps<T> {
  errorComponentProps?: ConnectCareErrorProps;
  noDataComponentProps?: ConnectCareErrorProps;
}

function ConnectCareDataLoader<T>(props: ConnectCareDataLoader<T>) {
  const { errorComponentProps, noDataComponentProps, renderError, renderNoData, ...rest } = props;

  const createError = useCallback(() => {
    if (renderError) {
      return () => renderError();
    }
    if (errorComponentProps) {
      return () => <ConnectCareError {...errorComponentProps} />;
    }
    return undefined;
  }, [renderError, errorComponentProps]);

  const createNoData = useCallback(() => {
    if (renderNoData) {
      return () => renderNoData();
    }
    if (noDataComponentProps) {
      return () => <ConnectCareError {...noDataComponentProps} />;
    }
    return undefined;
  }, [renderNoData, noDataComponentProps]);

  return (
    <DataLoader
      {...rest}
      renderLoading={() => <ConnectCareLoading />}
      renderError={createError()}
      renderNoData={createNoData()}
    />
  );
}

export default ConnectCareDataLoader;
