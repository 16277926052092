import styled from 'styled-components';
import { MuiContainer, MuiGrid } from 'theme/material-ui';

export const ProfileContainer = styled(MuiContainer)`
  padding: 24px 0px 24px 0px;
`;

export const MuiGridPanel = styled(MuiGrid)`
  padding-left: 42px;
`;
