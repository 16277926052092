import Dialog from '@material-ui/core/Dialog';
import CloseButton from 'components/UI/Button/CloseButton';
import Divider from 'components/UI/Divider';
import Box from 'components/UI/Layout/Box';
import FlexBox from 'components/UI/Layout/FlexBox';
import Spinner from 'components/UI/Spinner/Spinner';
import { SpinnerContainer } from 'components/UI/Spinner/SpinnerContainer';
import { Color } from 'modules/styles/colors';
import React, { useEffect, useState } from 'react';
import { MuiBox, MuiContainer } from 'theme/material-ui';
import {
  CONFIRM_AND_SIGN,
  DISCLAIMER,
  E_SIGNATURE_TERMS,
  TCPA_TEXT,
  TCPA_TITLE,
  TCPA_URI
} from './constants';
import {
  StyledBodyText,
  StyledDisclaimer,
  StyledDisclaimerText,
  StyledHeader,
  StyledLegal
} from './styled';
import StyledButton from 'components/UI/Button/StyledButton';

interface TCPAAuthorizationProps {
  open: boolean;
  handleAccept: () => void;
  handleClose: () => void;
}

function TCPAAuthorization({ open, handleAccept, handleClose }: TCPAAuthorizationProps) {
  const [isFetching, setIsFetching] = useState(false);
  const [htmlString, setHtmlString] = useState('');
  const fetchData = () => {
    return window.fetch(TCPA_URI).then(res => res.text());
  };
  useEffect(() => {
    let isSubscribed = true;
    setIsFetching(true);
    fetchData()
      .then(res => {
        if (isSubscribed) {
          setIsFetching(false);
          setHtmlString(res);
        }
      })
      .catch(() => {
        setIsFetching(false);
        setHtmlString(TCPA_TEXT);
      });
    return () => {
      setIsFetching(false);
      isSubscribed = false;
    };
  }, []);
  return (
    <Dialog fullScreen open={open} onClose={handleClose}>
      <MuiContainer maxWidth="md">
        <FlexBox spacing={18} flex={1} flexDirection="column" backgroundColor={Color.white}>
          <FlexBox alignItems="flex-end">
            <CloseButton onPress={() => handleClose()} />
          </FlexBox>
          <>
            <StyledHeader>{TCPA_TITLE}</StyledHeader>
            <Box vOffsetTop={10} vOffsetBottom={15}>
              <StyledDisclaimer>{E_SIGNATURE_TERMS}</StyledDisclaimer>
            </Box>
          </>
          <StyledLegal>
            {isFetching ? (
              <SpinnerContainer backgroundColor="transparent" display="flex">
                <Box vSpacingBottom={50}>
                  <Spinner />
                </Box>
              </SpinnerContainer>
            ) : null}
            <StyledBodyText dangerouslySetInnerHTML={{ __html: htmlString }} />
          </StyledLegal>
          <Box vSpacingTop={15} vSpacingBottom={20}>
            <StyledDisclaimerText>{DISCLAIMER}</StyledDisclaimerText>
            <MuiBox mt={2}>
              <Divider />
            </MuiBox>
          </Box>
          <StyledButton disabled={isFetching} title="TCPAConfirmAndSign" onClick={handleAccept}>
            {CONFIRM_AND_SIGN}
          </StyledButton>
        </FlexBox>
      </MuiContainer>
    </Dialog>
  );
}

export default TCPAAuthorization;
