import styled from 'styled-components';
import Screen from 'components/UI/Layout/Screen';
import { Color } from 'modules/styles/colors';

// Display logic for block or flex due to an issue with safari on mobile
export const AccountSelectScreen = styled(Screen)<{ isSmallScreen: boolean }>`
  background: ${Color.primary};
  display: ${props => (props.isSmallScreen ? 'block' : 'flex')};
  flex: 1;
`;
