import React from 'react';
import { FlatList } from 'react-native';
import DefaultLabel from 'components/UI/Labels/DefaultLabel';
import Container from 'components/UI/Layout/Container';
import TouchablePanelItem from './TouchablePanelItem';

interface Props {
  data?: any[];
  emptyCaption?: string;
  keyExtractor: Function;
  onItemPressed: Function;
  renderItem: Function;
}

const TouchablePanelGroup = (props: Props) =>
  !props.data || props.data.length === 0 ? (
    <Container>
      <DefaultLabel>{props.emptyCaption}</DefaultLabel>
    </Container>
  ) : (
    <Container>
      <FlatList
        accessibilityLabel="Records List"
        data={props.data}
        keyExtractor={props.keyExtractor}
        renderItem={({ item, index }) => (
          <TouchablePanelItem
            onPress={() => props.onItemPressed(item)}
            firstChild={index === 0}
            lastChild={index === props.data.length - 1}
          >
            {props.renderItem(item, index)}
          </TouchablePanelItem>
        )}
      />
    </Container>
  );

TouchablePanelGroup.defaultProps = {
  data: [],
  emptyCaption: undefined
};

export default TouchablePanelGroup;
