import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { TouchableOpacity } from 'react-native';

import { MuiBox } from 'theme/material-ui';
import { KeyboardArrowRight } from '@material-ui/icons';

import Typography from 'components/UI/Typography';
import CollapsibleListItem from 'components/common/CollapsibleList/CollapsibleListItem';
import AmountSummaryTable from 'components/billing/AmountSummaryTable';
import DefaultText from 'components/UI/Typography/DefaultText';

import { Color } from 'modules/styles/colors';

import useCoreEventData from 'hooks/useCoreEventData';
import { useBillsCollapsibleList } from 'lib/hooks/useBilling';

import formatMoney from 'services/formatMoney';
import analyticsService, { AmplitudeEventData, AnalyticsEvent } from 'services/AnalyticsService';

import { BILLS_TAB } from 'store/billing/constants';
import { currentAccountConsumerIdSelector } from 'store/account/selectors';

import { iff, mapEncountersToData } from './util';
import { EmptyMuiBox } from '../styled';
import { TableData } from './types';

interface BillsComponentProps {
  largeView: boolean;
}

export default function Bills({ largeView }: BillsComponentProps) {
  const history = useHistory();
  const location = useLocation<{ isExpanded: string }>();
  const { currentUrl, referringUrl } = useCoreEventData();
  const { guarantorData, paymentsSummary, isImpersonating } = useBillsCollapsibleList();
  const consumerId = useSelector(currentAccountConsumerIdSelector);
  const amountDue = guarantorData?.amountDue || 0;
  const encounters = guarantorData?.encounters || [];
  const isExpanded = location.state?.isExpanded === BILLS_TAB;
  const totalEncounters = guarantorData?.totalEncounters || 0;
  const paidEncounters = paymentsSummary?.paidEncounters || [];

  const data: AmplitudeEventData = {
    currentUrl,
    referringUrl
  };

  const impersonatingPaidEncounters = paidEncounters.filter(
    encounter => encounter.patient.id === consumerId
  );
  const updatedPaidEncounters = isImpersonating ? impersonatingPaidEncounters : paidEncounters;
  const billingData = mapEncountersToData(encounters, updatedPaidEncounters);

  const goToBillSummary = () => {
    analyticsService.logEvent(AnalyticsEvent.ViewAllBillsClicked, data);
    history.push('/u/billing/bills');
  };

  const goToBillingDetail = (item: TableData) => {
    analyticsService.logEvent(AnalyticsEvent.IndividualBillingClicked, data);
    history.push(`/u/billing/bill-detail/${item.id}`, {
      eaid: item.id
    });
  };

  return (
    <CollapsibleListItem
      largeView={largeView}
      leftIcon="Money"
      title="Bills"
      key={`Bills ${isImpersonating}`}
      expandOnFirstRender={isExpanded || false}
      isExpandable
      subtitle={iff(
        totalEncounters === 0,
        '0 Unpaid',
        `(${totalEncounters} Unpaid) $${formatMoney(amountDue)}`
      )}
      subtitle1={iff(totalEncounters === 0, '0 Unpaid', `${totalEncounters} Unpaid Bills Due`)}
      subtitle2={`Total Amount Due: $${formatMoney(amountDue)}`}
      analyticsEvent={AnalyticsEvent.BillsClicked}
      currentUrlForAnalytics={currentUrl}
      referringUrlForAnalytics={referringUrl}
    >
      <AmountSummaryTable
        largeView={largeView}
        arrows
        data={billingData}
        onItemPressed={goToBillingDetail}
        hideHeaders={!largeView}
        headers={['Visit Date', 'Patient', 'Amount']}
      />

      {billingData.length > 0 ? (
        <TouchableOpacity
          onPress={goToBillSummary}
          accessibilityLabel="Tap to view all unpaid bills"
        >
          <MuiBox justifyContent="space-between" display="flex" padding="20px 20px 0px 20px">
            <MuiBox data-testid="view-all-unpaid-bills-link">
              <Typography color={Color.secondary}>View All</Typography>
            </MuiBox>
            <MuiBox justifyContent="space-between" flexDirection="row">
              <KeyboardArrowRight />
            </MuiBox>
          </MuiBox>
        </TouchableOpacity>
      ) : (
        <EmptyMuiBox>
          <DefaultText>No Bills Are Currently Due</DefaultText>
        </EmptyMuiBox>
      )}
    </CollapsibleListItem>
  );
}
