import React from 'react';
import { StyledMuiDialog } from 'screens/Booking/components/styled';
import CloseButton from 'components/UI/Button/CloseButton';
import { DialogTitle } from '@material-ui/core';
import { MuiButton } from 'theme/material-ui';
import Spacer from 'components/UI/Layout/Spacer';
import { AlertIcon } from './styled';

export function EmailAlert(params: {
  errorCode?: string;
  open: boolean;
  handleClose: (action: 'closeDialog' | 'closeX' | 'closeBtn' | 'update') => void;
}) {
  const { open, handleClose } = params;
  return (
    <StyledMuiDialog
      open={open}
      onClose={() => handleClose('closeDialog')}
      aria-labelledby="email-alert-dialog"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="email-alert-dialog-title">
        <CloseButton absolute onPress={() => handleClose('closeX')} margin="0px" padding={3} />
        <AlertIcon />

        <div>
          <p style={{ fontWeight: 300 }}>
            We have an invalid email for your profile.
            <span style={{ fontWeight: 'bold' }}> Please update your email address.</span> Some
            features will not be available without a valid email address.
          </p>
        </div>

        <div style={{ textAlign: 'center' }}>
          <MuiButton
            fullWidth
            color="primary"
            variant="contained"
            onClick={() => handleClose('update')}
          >
            Update my email address
          </MuiButton>
          <Spacer size="small" />
        </div>
      </DialogTitle>
    </StyledMuiDialog>
  );
}
