import FlexBox, { DisplayFlex } from 'components/UI/Layout/FlexBox';
import React, { ComponentType, useState, useEffect } from 'react';
import analyticsService, { AmplitudeEventData, AnalyticsEvent } from 'services/AnalyticsService';
import { connect, useDispatch } from 'react-redux';
import { useProxySwitcherEffect } from 'hooks/useProxySwitcherEffect';
import Banner from 'screens/MedicalHistory/Banner';
import { HEALTH_RECORDS_PAGES } from 'lib/constants/healthRecords';
import { fetchUSIR } from 'store/medicalHistory/immunizations/actions';
import {
  usirDataSelector,
  usirErrorSelector,
  usirLoadingSelector
} from 'store/medicalHistory/immunizations/selectors';
import {
  currentLocationPathNameSelector,
  previousLocationPathNameSelector
} from 'store/router/selectors';
import DocumentView from 'components/UI/DocumentView';
import {
  isImpersonatingSelector,
  currentAccountConsumerFullNameSelector
} from 'store/account/selectors';
import { RootState } from 'store/types';
import { MedicalHistoryMessage } from '../common';
import MessageComposeWidget from 'components/Messaging/MessageComposeWidget';
import { TransmitSources } from 'store/transmit';
import { v4 as uuidv4 } from 'uuid';
import { Alert } from 'components/Alert';
import useBreadCrumbs from 'hooks/useBreadCrumbs';

interface Props {
  currentUserFullName: string;
  usir: string;
  error?: Error | null;
  loading?: boolean;
  isProxyUser: boolean;
  referringUrl: string;
  currentUrl: string;
}

export function UtahSchoolImmunizationRecordComponent({
  error,
  loading,
  usir,
  isProxyUser,
  referringUrl,
  currentUrl,
  currentUserFullName
}: Props) {
  const dispatch = useDispatch();
  const profileName = currentUserFullName;
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [messageUUID, setMessageUUID] = useState('');
  const { mergeCurrentWithPreviousBreadCrumbs } = useBreadCrumbs();

  useEffect(() => {
    mergeCurrentWithPreviousBreadCrumbs();
  }, []);

  useProxySwitcherEffect(() => {
    dispatch(fetchUSIR());
  }, []);

  useEffect(() => {
    setMessageUUID(uuidv4());
  }, []);
  const amplitudeEventData: AmplitudeEventData = {
    referringUrl,
    currentUrl,
    isProxy: isProxyUser ? 'Yes' : 'No'
  };

  const handleDownloadImmunizations = () => {
    if (loading) {
      return;
    }
    analyticsService.logEvent(
      AnalyticsEvent.DownloadUtahSchoolImmunizationRecord,
      amplitudeEventData
    );
    const downloadLink = document.createElement('a');
    const fileName = `${profileName}_Utah_School_Immunizations_Record.pdf`;
    downloadLink.id = 'Utah_School_Immunizations_Record_Download_Anchor';
    downloadLink.href = `data:application/pdf;base64,${usir}`;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  const handleShareImmunizations = () => {
    analyticsService.logEvent(AnalyticsEvent.ShareUtahSchoolImmunizationRecord, amplitudeEventData);
    setShowMessageModal(true);
  };
  const handleShowSuccessAlert = (providerName: string) => {
    Alert.alert(`Success`, `Message has been sent to ${providerName}`);
  };

  const paramsMessage = {
    subject: HEALTH_RECORDS_PAGES.MedicalHistory.usirButton,
    message: `Utah school immunization record for ${profileName}`,
    attachments: [
      {
        base64: `data:application/pdf;base64,${usir}`,
        filename: 'utah_school_immunization_record.pdf'
      }
    ],
    transmitParams: {
      source: TransmitSources.UtahSchoolImmunizationRecord,
      returnBackTo: currentUrl
    },
    isSending: false,
    open: showMessageModal
  };

  return (
    <>
      {showMessageModal && !loading && paramsMessage.attachments.length ? (
        <MessageComposeWidget
          messageUUID={messageUUID}
          paramsMessage={paramsMessage}
          showSuccessAlert={handleShowSuccessAlert}
          handleMessageModal={setShowMessageModal}
        />
      ) : null}
      <FlexBox data-testid="utah-school-immunization-record-page" height="calc(100vh - 240px)">
        <Banner
          onDownloadPress={handleDownloadImmunizations}
          onTransmitPress={handleShareImmunizations}
          isDownloading={loading}
        />
        <DisplayFlex>
          <MedicalHistoryMessage type="USIR" loading={loading} data={usir} error={error} />
          {usir ? (
            <FlexBox width="100%" data-testid="usir-holder">
              <DocumentView
                content={usir}
                contentType="application/pdf"
                documentName="Utah_School_Immunization_Record"
                aria-label={HEALTH_RECORDS_PAGES.MedicalHistory.usirButton}
              />
            </FlexBox>
          ) : null}
        </DisplayFlex>
      </FlexBox>
    </>
  );
}

const mapStateToProps = (state: RootState) => ({
  currentUserFullName: currentAccountConsumerFullNameSelector(state),
  error: usirErrorSelector(state),
  loading: usirLoadingSelector(state),
  usir: usirDataSelector(state),
  isProxyUser: isImpersonatingSelector(state),
  currentUrl: currentLocationPathNameSelector(state),
  referringUrl: previousLocationPathNameSelector(state)
});

export default connect(mapStateToProps)(UtahSchoolImmunizationRecordComponent as ComponentType);
