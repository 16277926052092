import Config from 'react-native-config';

import { AdvisoryFormattedInterface } from 'store/priorityItems/types';
import { ProviderSummary } from 'store/findProvider/types';
import { sendComposeMessage } from 'store/messaging/actions';
import { Thunk } from 'store/types';
import { ClearHealthAdvisoryFormValues } from './CloseHealthAdvisoryScreen';
import { snoozeHealthAdvisoryItem } from 'store/healthAdvisory/actions';
import { patchEvent } from 'store/events/actions.common';

const useSurrogateId = Config.HIGH_PRIORITY_ITEMS_V2 === 'enabled';

// Action is very specific to embedded messaging so located here
export const clearHealthAdvisory = (
  advisory: AdvisoryFormattedInterface,
  pcp: ProviderSummary,
  values: ClearHealthAdvisoryFormValues
): Thunk<Promise<boolean>> => async dispatch => {
  if (!advisory.eventId) {
    return false;
  }

  const subject = 'Preventive Care Resolution';
  const message = `
  Advisory Type: ${advisory.advisoryName}
  Advisory ID: ${advisory.eventId}

  Date of Service: ${values.date}
  Location: ${values.location}
  Address: ${values.address}
  City: ${values.city}
  State: ${values.state}
  Zip Code: ${values.zip};

  Servicing Provider ${values.providerName}
  Servicing Provider Phone: ${values.phone}

  `;

  const { error } = await dispatch(sendComposeMessage(pcp.messagingPools[0], subject, message));

  if (error) {
    return false;
  }

  if (useSurrogateId) {
    await dispatch(snoozeHealthAdvisoryItem(advisory.eventId, { hideFor: 90 }));
  } else {
    await dispatch(patchEvent(advisory.eventId, { hideFor: 90 }));
  }

  return true;
};
