import styled from 'styled-components';
import { Spacing } from 'modules/styles/variables';
import { Color } from 'modules/styles/colors';
import { MuiBox, MuiButton, MuiTypography, MuiContainer } from 'theme/material-ui';
import { INPUT_WIDTH } from 'screens/ProviderSearch/styled';
import { Link } from 'react-router-dom';

export const GetCareButton = styled(MuiButton)<{ component?: Link; to?: string; onClick?(): any }>`
  width: 40%;
  &:hover {
    color: ${Color.grayLight};
  }
`;

export const GetCareButtonTextBox = styled(MuiBox)`
  padding-top: ${Spacing.large}px;
  padding-bottom: ${Spacing.xLarge}px;
`;

export const GetCareText = styled(MuiTypography)`
  white-space: pre-line;
  text-align: center;
  padding-bottom: ${Spacing.large}px;
`;

export const GetCareSearchContainer = styled(MuiContainer)`
  & .MuiAutocomplete-root {
    max-width: ${INPUT_WIDTH}px;
    margin: auto;
  }
`;
