import { RouteData } from 'screens/Booking/router/types';
import { TEST_GUEST_PAGE_NAME } from './constants';

export const getCovidTestGuestPageName = (route: string): string => {
  if (route in TEST_GUEST_PAGE_NAME) return TEST_GUEST_PAGE_NAME[route];

  return 'COVID-19 Test Screening';
};

const isRouter = (r: Partial<RouteData>) => r.componentName?.includes('Router');

export const filterRouter = (routes: RouteData[]) => routes.filter(r => !isRouter(r));
