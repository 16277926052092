import React, { ComponentType, useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import analyticsService, { AmplitudeEventData, AnalyticsEvent } from 'services/AnalyticsService';
import { RootState } from 'store/types';
import * as bookingTypes from 'store/booking/types';
import * as bookingActions from 'store/booking/actions';
import * as bookingSelectors from 'store/booking/selectors';
import * as routerSelectors from 'store/router/selectors';
import { avatarDataMapSelector } from 'store/consumerPreferences/selectors';
import { BookingStepActions, BookingStepAction } from '../components/BookingStepper';
import PatientLoader from 'components/common/PatientLoader';
import { MapAvatar } from 'store/consumerPreferences/types';
import { closeTimeSlotListener } from 'store/booking/actions';
import { StyledScreen } from '../components/styled';
import { kyruusEnabled } from 'lib/findProvider/utils';

export interface Props extends RouteComponentProps {
  appointmentDetails: bookingTypes.AppointmentDetails;
  patients: bookingTypes.Patient[];
  patientsLoading: boolean;
  setPatient: typeof bookingActions.setPatient;
  currentRouteName?: string;
  previousRouteName?: string;
  avatarsMap: MapAvatar;
  getPatients: typeof bookingActions.getPatients;
  validateSelectedPatientInsurance: typeof bookingActions.validateSelectedPatientInsurance;
  clearAppointmentDetails: typeof bookingActions.clearAppointmentDetails;
  setCorrelationId: typeof bookingActions.setCorrelationIDAction;
}

interface LocationStateProps {
  skipProviderSelect: boolean;
  skipSpecialtySelect: boolean;
  skipNpv2FlowSelect: boolean;
}

export const hasDoctorSelected = (appointmentDetails: bookingTypes.AppointmentDetails) =>
  appointmentDetails.doctor?.primarySpecialty.shortSpecialtyName;

export function BookingPatientSelect({
  appointmentDetails,
  patients,
  patientsLoading,
  setPatient,
  history,
  currentRouteName,
  previousRouteName,
  getPatients,
  avatarsMap,
  validateSelectedPatientInsurance,
  clearAppointmentDetails,
  setCorrelationId
}: Props) {
  const locationState = history.location?.state as LocationStateProps;

  const shouldSkipProviderSelect = locationState?.skipProviderSelect;
  const shouldSkipSpecialtySelect = locationState?.skipSpecialtySelect;
  const shouldSkipNpv2FlowSelect = locationState?.skipNpv2FlowSelect;
  const shouldResetAppointmentDetails =
    appointmentDetails.patient && appointmentDetails.doctor && !shouldSkipProviderSelect;

  const eventData: AmplitudeEventData = {
    currentUrl: currentRouteName,
    referringUrl: previousRouteName
  };

  useEffect(() => {
    getPatients();
    setCorrelationId();

    if (shouldResetAppointmentDetails) {
      clearAppointmentDetails();
      closeTimeSlotListener();
    }
  }, []);

  const handlePatientSelect = (patient: bookingTypes.Patient) => {
    setPatient(patient);
    validateSelectedPatientInsurance();
    analyticsService.logEvent(AnalyticsEvent.BookingPatientSelected, eventData);
  };

  const getPatientIsSelected = (patient: bookingTypes.Patient) => {
    if (!patient || !appointmentDetails.patient) {
      return false;
    }

    return patient.consumerId === appointmentDetails.patient.consumerId;
  };

  const onNextClick = () => {
    const hasDoctor = appointmentDetails?.doctor;

    if (shouldSkipSpecialtySelect) {
      history.push({
        pathname: '/u/get-care-now/booking/specialty-provider-select',
        state: {
          skipNpv2FlowSelect: shouldSkipNpv2FlowSelect
        }
      });
    } else if (hasDoctor && !kyruusEnabled()) {
      history.push('/u/get-care-now/booking/visit-type-select');
    } else {
      history.push('/u/get-care-now/booking/specialty-or-my-doctor-select');
    }
  };

  const onCancelClick = () => {
    history.goBack();
  };

  return (
    <>
      <StyledScreen>
        <PatientLoader
          title="Who needs care?"
          patients={patients}
          patientsLoading={patientsLoading}
          handlePatientSelect={handlePatientSelect}
          getPatientIsSelected={getPatientIsSelected}
          id="Booking"
          avatarsMap={avatarsMap}
          isCovidPatientSelect={false}
        />
        <BookingStepActions>
          <BookingStepAction
            disabled={!appointmentDetails.patient}
            onClick={onNextClick}
            data-testid="next-button"
          />
          <BookingStepAction
            label="Cancel"
            data-testid="booking-patient-select-cancel-button"
            onClick={onCancelClick}
          />
        </BookingStepActions>
      </StyledScreen>
    </>
  );
}

const mapStateToProps = (state: RootState) => ({
  appointmentDetails: bookingSelectors.appointmentDetailsSelector(state),
  avatarsMap: avatarDataMapSelector(state),
  patients: bookingSelectors.patientsDataSelector(state),
  patientsLoading: bookingSelectors.patientsFetchingSelector(state),
  currentRouteName: routerSelectors.currentLocationPathNameSelector(state),
  previousRouteName: routerSelectors.previousLocationPathNameSelector(state)
});

const mapDispatchToProps = {
  setPatient: bookingActions.setPatient,
  getPatients: bookingActions.getPatients,
  validateSelectedPatientInsurance: bookingActions.validateSelectedPatientInsurance,
  clearAppointmentDetails: bookingActions.clearAppointmentDetails,
  setCorrelationId: bookingActions.setCorrelationIDAction
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingPatientSelect as ComponentType);
