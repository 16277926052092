import styled from 'styled-components';
import { MuiTypography, MuiTextField, dfdDefaultTheme } from 'theme/material-ui';
import { Color } from 'modules/styles/colors';
import NativeSelect from '@material-ui/core/NativeSelect/NativeSelect';
import { FormControl, Select } from '@material-ui/core';

export const StyledHeader = styled(MuiTypography)`
  &.MuiTypography-body1 {
    color: ${Color.black};
    font-size: 1.5rem;
    font-weight: 400;
    letter-spacing: 0.57px;
    line-height: 29px;
  }
`;
export const StyledFormTitle = styled(MuiTypography)`
  &.MuiTypography-body1 {
    height: 24px;
    color: ${Color.black};
    font-family: 'ABC Social Variable';
    font-size: 1.25rem;
    letter-spacing: 0.48px;
    line-height: 24px;
  }
`;

export const StyledMuiTextField = styled(MuiTextField)`
  box-sizing: border-box;
  height: 48px;
  width: 900px;
  border: 1px solid #c7c7cc;
  border-radius: 3px;
`;

export const StyledHelperText = styled(MuiTypography)`
  &.MuiTypography-body1 {
    height: 20px;
    margin-bottom: 20px;
    color: rgba(0, 0, 0, 0.87);
    font-size: 13px;
    letter-spacing: 0.25px;
    line-height: 20px;
    text-align: center;
  }
`;
export const StyledNote = styled(MuiTypography)`
  &.MuiTypography-body1 {
    height: 16px;
    margin-top: 20px;
    width: 751.62px;
    color: rgba(0, 0, 0, 0.87);
    font-size: 11.85px;
    letter-spacing: 0.4px;
    line-height: 16px;
  }
`;

export const StyledNativeSelect = styled(NativeSelect)`
  height: 48px;
  width: 439px;
  border-radius: 4px;
  background-color: ${Color.white};
  border: 1px solid ${Color.graySidebarDivider};
  & select.MuiNativeSelect-select {
    padding-left: 10px;
    padding-right: 24px;
    color: ${Color.gray};
    font-size: 16px;
    letter-spacing: 0.38px;
    line-height: 22px;
  }
  & svg.MuiNativeSelect-icon {
    right: 15px;
  }
`;

export const StyledText = styled.div`
  height: 20px;
  /* margin: 20px 0px 8px 0px; */
  margin: 15px 0px 8px 0px;
  color: ${Color.black};
  font-size: 14px;
  letter-spacing: 0.33px;
  line-height: 20px;
`;

export const StyledInstructions = styled.div`
  margin-top: 16px;
  margin-bottom: 20px;
  height: 20px;
  width: 899px;
  color: ${Color.black};
  font-size: 14px;
  letter-spacing: 0.33px;
  line-height: 20px;
`;

export const StyledDateFormControl = styled(FormControl)`
  & button.MuiIconButton-root {
    padding: 0;
  }
`;

export const FullWidthTextField = styled(MuiTextField)`
  box-sizing: border-box;
  height: 48px;
  width: 900px;
  background-color: ${Color.white};
  border: 1px solid ${Color.graySidebarDivider};
  border-radius: 4px;
  &.MuiInputBase-input {
    padding-left: 10px;
  }
`;

export const StateSelect = styled(Select)`
  box-sizing: border-box;
  height: 48px;
  width: 140px;
  background-color: ${Color.white};
  border: 1px solid ${Color.graySidebarDivider};
  border-radius: 4px;
  & select.MuiSelect-select {
    padding-left: 10px;
    padding-right: 24px;
    color: ${Color.gray};
    font-size: 16px;
    letter-spacing: 0.38px;
    line-height: 22px;
  }
  & svg.MuiSelect-icon {
    right: 15px;
  }
`;
export const StyledTextField = styled(MuiTextField)`
  box-sizing: border-box;
  height: 48px;
  width: 450px;
  border-radius: 4px;
  background-color: ${Color.white};
  border: 1px solid ${Color.graySidebarDivider};
`;
export const StyledZip = styled(MuiTextField)`
  box-sizing: border-box;
  height: 48px;
  width: 270px;
  border-radius: 4px;
  background-color: ${Color.white};
  border: 1px solid ${Color.graySidebarDivider};
`;
export const StyledFormControl = styled(FormControl)`
  ${dfdDefaultTheme.breakpoints.up('md')} {
    &.MuiFormControl-root {
      margin: 0px 20px;
    }
  }
`;

export const StyledCheckBoxNote = styled.div`
  height: 16px;
  width: 751.62px;
  color: rgba(0, 0, 0, 0.87);
  font-size: 11.85px;
  letter-spacing: 0.4px;
  line-height: 16px;
`;
