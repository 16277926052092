import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'store/types';
import { SelectDropdown } from 'components/UI/SelectDropdown';
import { MuiBox, MuiContainer, MuiTypography, MuiGrid, MuiButton } from 'theme/material-ui';
import { ProviderSearchInterface } from 'lib/hooks/ProviderSearch/useProviderSearch';
import * as selectors from 'store/providerSearch/selectors';
import * as constants from 'store/providerSearch/constants';
import ProviderSearchListFiltersGroup from './ProviderSearchListFiltersGroup';
import ProviderSearchListItem from './ProviderSearchListItem';
import { ListFetching } from '../styled';
import * as profileSelectors from 'store/profile/selectors';
import { ProviderFacetGroups } from 'store/findProvider/types';
import analyticsService, { AnalyticsEvent } from 'services/AnalyticsService';
import SelectHealthInsuranceLinks from './SelectHealthInsuranceLinks';

interface Props {
  providers: ProviderSearchInterface['result'];
  fetching: ProviderSearchInterface['fetching'];
  search: ProviderSearchInterface['search'];
  options: ProviderSearchInterface['options'];
  error: ProviderSearchInterface['error'];
  isSelectHealth: boolean;
  termsAccepted: boolean;
  totalCount: number;
  currentCount: number;
  hasInsurance: boolean;
}

const ProviderSearchListStates = ({
  error,
  isSelectHealth,
  fetching,
  providers
}: Partial<Props>) => {
  if (error)
    return (
      <MuiTypography color="error" variant="subtitle1">
        {constants.searchErrorText}
      </MuiTypography>
    );

  if (fetching) {
    if (!providers?.length) {
      return (
        <MuiTypography color="primary" variant="subtitle1">
          {constants.fetchingText}
        </MuiTypography>
      );
    }

    return <ListFetching />;
  }

  if (!providers?.length) {
    return (
      <>
        {isSelectHealth ? (
          <SelectHealthInsuranceLinks />
        ) : (
          <MuiTypography variant="subtitle1">{constants.noOptionsText}</MuiTypography>
        )}
      </>
    );
  }

  return null;
};

const ProviderSearchListViewMore = ({
  fetching,
  onViewMore
}: {
  fetching: boolean;
  onViewMore: () => void;
}) => (
  <MuiBox marginTop={2} marginBottom={3} display="flex" justifyContent="center">
    <MuiButton
      loading={fetching}
      size="large"
      variant="outlined"
      fullWidth
      onClick={onViewMore}
      color="primary"
    >
      View more
    </MuiButton>
  </MuiBox>
);

export const ProviderSearchList = ({
  providers,
  fetching,
  search,
  options,
  error,
  isSelectHealth,
  termsAccepted,
  totalCount,
  currentCount,
  hasInsurance
}: Props) => {
  const onViewMore = () => {
    analyticsService.logEvent(AnalyticsEvent.ViewMoreProviderSearchResultsClicked, {
      number_results_visible: currentCount
    });

    search({ page: (options.page ?? 0) + 1 });
  };

  const insuranceFilterApplied = options?.facets?.some(
    f => f.group === ProviderFacetGroups.insurancePlanNcid
  );

  return (
    <MuiContainer>
      <MuiBox>
        <MuiGrid container spacing={2}>
          <MuiGrid item xs={12}>
            <MuiBox display="flex" flexDirection="row" alignItems="center">
              <ProviderSearchListFiltersGroup options={options} search={search} />
              <MuiBox>
                <SelectDropdown
                  placeholder="Sort by"
                  variant="standard"
                  items={
                    termsAccepted
                      ? constants.sortOptions.ACCEPTED_TC
                      : constants.sortOptions.DECLINED_TC
                  }
                  value={options.orderBy || ''}
                  onChange={e => {
                    analyticsService.logEvent(AnalyticsEvent.ProviderSearchResultsSorted, {
                      sort_used: e.target.value
                    });
                    search({ page: 0, orderBy: e.target.value });
                  }}
                />
              </MuiBox>
            </MuiBox>
          </MuiGrid>
        </MuiGrid>

        <MuiBox>
          <ProviderSearchListStates
            error={error}
            fetching={fetching}
            providers={providers}
            isSelectHealth={isSelectHealth}
          />
          {providers.map(p => (
            <ProviderSearchListItem
              key={p.providerId}
              provider={p}
              showInsurance={hasInsurance || insuranceFilterApplied}
            />
          ))}
        </MuiBox>

        {currentCount < totalCount && (
          <ProviderSearchListViewMore fetching={fetching} onViewMore={onViewMore} />
        )}
      </MuiBox>
    </MuiContainer>
  );
};

const mapStateToProps = (state: RootState) => ({
  isSelectHealth: profileSelectors.isSelectHealthMemberSelector(state),
  termsAccepted: selectors.searchTermsAndConditionsAccepted(state),
  totalCount: selectors.searchRawTotalSelector(state),
  currentCount: selectors.searchRawCountSelector(state),
  hasInsurance: profileSelectors.profileInsurancesSelector(state).length > 0
});

export default connect(mapStateToProps)(ProviderSearchList);
