import FlexBox from 'components/UI/Layout/FlexBox';
import styled from 'styled-components';

export const StyledBox = styled.div`
  padding: 16px;
`;

export const AmountRow = styled(FlexBox)`
  flex-direction: row;
  align-items: center;
  margin: 10px 0 20px;
`;

export const Label = styled.div`
  font-size: 12px;
  border-width: 1px;
  border-style: solid;
  border-radius: 100px;
  padding: 0px 6px;
  margin: 0 16px;
`;
