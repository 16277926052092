import useNavigationAnalytics from 'hooks/useNavigationAnalytics';
import { AnalyticsEvent } from 'services/AnalyticsService.common';
import { useState } from 'react';

interface PauseVideoArgs {
  id: number;
  language: 'en' | 'es';
  pauseTime: number;
  startTime: number;
  totalDuration: number;
  videoTitle: string;
}

interface ExitedVideoArgs {
  currentTime: number;
  id: number;
  language: string;
  totalDuration: number;
  videoTitle: string;
}

interface VideoCompleted {
  currentTime: number;
  id: number;
  language: string;
  totalDuration: number;
  videoTitle: string;
}

export default function usePreventDiabetes() {
  const [completed, setCompleted] = useState(false);
  const playVideo = useNavigationAnalytics(AnalyticsEvent.PreventPrediabetesVideoStarted);
  const pauseVideo = useNavigationAnalytics(AnalyticsEvent.PreventPrediabetesVideoPaused);
  const onCloseVideo = useNavigationAnalytics(AnalyticsEvent.PreventPrediabetesVideoExited);
  const videoCompleted = useNavigationAnalytics(AnalyticsEvent.PreventPrediabetesVideoCompleted);

  const changeLanguage = useNavigationAnalytics(AnalyticsEvent.PreventDiabetesLanguageSelected);

  const onSelectLanguage = (currentLanguage: string) => {
    changeLanguage({
      language: currentLanguage === 'es' ? 'Spanish' : 'English',
      title: 'Prediabetes Landing Page'
    });
  };

  const onSelectVideo = (videoTitle: string, language: 'en' | 'es', id: number) => {
    setCompleted(false);
    playVideo({
      language: language === 'es' ? 'Spanish' : 'English',
      title: `Prediabetes Video ${id}`,
      video: videoTitle
    });
  };

  const onPauseVideo = (args: PauseVideoArgs) => {
    const duration = args.pauseTime - args.startTime;
    const percentageViewed = (args.pauseTime / args.totalDuration) * 100;

    pauseVideo({
      duration,
      language: args.language === 'es' ? 'Spanish' : 'English',
      percentage_viewed: percentageViewed,
      title: `Prediabetes Video ${args.id}`,
      video: args.videoTitle,
      video_completed: percentageViewed >= 95 ? 'Yes' : 'No'
    });
  };

  const onExitedVideo = (args: ExitedVideoArgs) => {
    const percentageViewed = (args.currentTime * 100) / args.totalDuration;

    onCloseVideo({
      duration: args.currentTime,
      language: args.language === 'es' ? 'Spanish' : 'English',
      percentage_viewed: percentageViewed,
      title: `Prediabetes Video ${args.id}`,
      video: args.videoTitle,
      video_completed: percentageViewed >= 95 ? 'Yes' : 'No'
    });
  };

  const onVideoCompleted = (args: VideoCompleted) => {
    const percentageViewed = (args.currentTime * 100) / args.totalDuration;
    if (percentageViewed >= 95 && !completed) {
      setCompleted(true);
      videoCompleted({
        duration: args.totalDuration,
        language: args.language === 'es' ? 'Spanish' : 'English',
        title: `Prediabetes Video ${args.id}`,
        video: args.videoTitle
      });
    }
  };

  return {
    onExitedVideo,
    onPauseVideo,
    onSelectLanguage,
    onSelectVideo,
    onVideoCompleted
  };
}
