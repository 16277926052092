import styled from 'styled-components';

import { smallFontSize } from 'modules/styles/variables';

import DefaultText from 'components/UI/Typography/DefaultText';

export default styled(DefaultText)`
  font-size: ${smallFontSize};
  letter-spacing: 0.4px;
  line-height: ${smallFontSize * 1.34};
  ${props => props.style}
`;
