import React from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';
import { useRoutes } from 'hooks/useRoutes';
import { Breadcrumbs } from 'components/Breadcrumbs';
import { useRoutesToCrumbs } from 'hooks/useRoutesToCrumbs';
import { RouteData } from 'screens/navigation';
import HSADetailsScreen from 'screens/Billing/HSA/HSADetailsScreen';
import Insurance from 'screens/Insurance/Insurance';
import InsuranceDetails from 'screens/Insurance/InsuranceDetails';
import AuditLog from './AuditLog/AuditLog';
import EditAddress from './EditAddress';
import EditEmail from './EditEmail';
import EditPhone from './EditPhone';
import Profile from './Profile';
import HipaaViewDocument from './HipaaViewDocument';
import ProfileUserNameBreadCrumb from './components/ProfileUserNameBreadCrumb';
import ManageAvatars from './Avatars/ManageAvatars';

const profileRoutes: RouteData[] = [
  {
    path: '/audit-logs',
    component: AuditLog,
    title: 'Audit Logs'
  },
  {
    path: '/hipaa-documents/:id',
    component: HipaaViewDocument,
    title: 'Hipaa Documents'
  },
  {
    path: '/insurance',
    component: Insurance,
    title: 'Insurance'
  },
  {
    path: '/insurance-details',
    component: InsuranceDetails,
    title: 'Insurance Details'
  },
  {
    path: '/savings',
    component: HSADetailsScreen,
    title: 'Health Savings'
  },
  {
    path: '/edit-address',
    component: EditAddress,
    title: 'Edit Address'
  },
  {
    path: '/edit-email',
    component: EditEmail,
    title: 'Edit Email'
  },
  {
    path: '/edit-phone',
    component: EditPhone,
    title: 'Edit Phone'
  },
  {
    path: '/manage-avatars',
    component: ManageAvatars
  },
  {
    path: '/:expandKey?',
    exact: true,
    component: Profile,
    title: 'Profile',
    crumbComponent: ProfileUserNameBreadCrumb
  }
];

export const ProfileRouter = (props: RouteComponentProps) => {
  const routes = useRoutes(profileRoutes);
  const crumbs = useRoutesToCrumbs(routes);

  return (
    <>
      <Breadcrumbs crumbs={crumbs} />
      <Switch>
        {routes.map(({ component: Component, ...r }) => (
          <Route
            key={r.path}
            path={r.path}
            exact={r.exact || false}
            render={props => <Component {...r} {...props} />}
          />
        ))}
        <Redirect to={props.match.path} />
      </Switch>
    </>
  );
};
