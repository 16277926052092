import styled, { css } from 'styled-components';
import { Color } from 'modules/styles/colors';
import { spacing, spacings } from 'modules/utils/StylesUtils';
import { ZIndex, BorderRadius } from 'modules/styles/variables';
import { MuiBackdrop, MuiSlider } from 'theme/material-ui';
import SmallLabel from 'components/UI/Labels/SmallLabel';
import FlexBox, { FlexBoxRow } from 'components/UI/Layout/FlexBox';
import { IconButton } from 'components/UI/Svg/styled';

// ///// //
// Video //
// ///// //

export const Video = styled.video`
  display: block;
  width: 100%;
  height: auto;
`;

// ////////////////// //
// Subtitle Container //
// ////////////////// //

export const SubtitlesContainer = styled(FlexBox)`
  margin-left: ${spacing(2)};
  z-index: ${ZIndex.medium};
  & .MuiPaper-root {
    position: absolute;
    bottom: 2rem;
    left: 2.5rem;
    z-index: ${ZIndex.low};
  }
`;

// ////////////// //
// Video Backdrop //
// ////////////// //

interface VideoBackdropProps {
  fade?: boolean;
}

export const VideoBackdrop = styled(MuiBackdrop)<VideoBackdropProps>`
  &.MuiBackdrop-root {
    z-index: ${ZIndex.low};
    position: absolute;
    background-color: transparent;
  }
  & svg {
    opacity: ${props => (props.fade ? 1 : 0)};
    transition: opacity 150ms ease-in-out;
  }
`;

// ///////////////////// //
// Video Controls Layout //
// ///////////////////// //

export const VideoControls = styled.div`
  width: 100%;
  height: auto;
  position: absolute;
  bottom: 0;
  z-index: ${ZIndex.medium};
  padding: ${spacings(1, 2, 1.5)};
  background-color: rgba(0, 0, 0, 0.8);
`;

export const VideoControlRow = styled(FlexBoxRow)`
  align-items: center;

  & > ${IconButton} {
    margin: ${spacings(0, 2, 0, 0)};
  }
`;

// ////////// //
// Video Text //
// ////////// //

export const ProgressText = styled(SmallLabel)`
  color: ${Color.white};
`;

// ////////////////////////////// //
// Video Progress + Volume Slider //
// ////////////////////////////// //

interface SliderProps {
  color?: 'primary' | 'secondary';
}

const color = (props: SliderProps) => {
  switch (props.color) {
    case 'primary':
      return css`
        &.MuiSlider-root {
          color: ${Color.MHlogoLightBlue};
          &.Mui-disabled {
            color: ${Color.MHlogoLightBlue};
          }
        }

        & .MuiSlider-thumb {
          background-color: ${Color.MHlogoLightBlue};
        }
      `;
    case 'secondary':
      return css`
        &.MuiSlider-root {
          color: ${Color.MHlogoDarkBlue};
          &.Mui-disabled {
            color: ${Color.MHlogoDarkBlue};
          }
        }

        & .MuiSlider-thumb {
          background-color: ${Color.MHlogoDarkBlue};
        }
      `;
    default:
      return css`
        &.MuiSlider-root {
          color: ${Color.white};
          &.Mui-disabled {
            color: ${Color.white};
          }
        }

        & .MuiSlider-thumb {
          background-color: ${Color.white};
        }
      `;
  }
};

export const Slider = styled(MuiSlider)<SliderProps>`
  &.MuiSlider-root {
    flex: 1;
    height: 4px;
    padding: ${spacings(1, 0)};

    &:focus,
    &:hover {
      & .MuiSlider-thumb {
        transform: scale(1);
        transform-origin: center;
        box-shadow: none;
      }
    }
  }

  & .MuiSlider-thumb {
    height: 12px;
    width: 12px;
    margin-top: -4.5px;
    margin-left: -6px;
    transform: scale(0);
    transition: transform 100ms linear;
  }

  & .MuiSlider-rail,
  & .MuiSlider-track {
    height: 4px;
    border-radius: ${BorderRadius.small}px;
  }

  ${color};
`;

// //////////// //
// Video Volume //
// //////////// //

export const VolumeContainer = styled.div`
  display: inline-flex;
  align-items: center;
  max-width: 100px;
  margin-left: -${spacing(0.5)};
  margin-right: -${spacing(0.5)};

  & > ${Slider} {
    width: 0px;
    transition: width 150ms cubic-bezier(0.4, 0, 1, 1);
  }

  &:focus,
  &:hover {
    & > ${Slider} {
      width: 60px;
      margin-right: ${spacing(1.5)};
    }
  }

  & > * {
    margin-left: ${spacing(0.5)};
    margin-right: ${spacing(0.5)};
  }
`;

interface VideoContainerProps {
  collapsible?: boolean;
}

const collapsible = (props: VideoContainerProps) =>
  props.collapsible &&
  css`
    & > ${VideoControls} {
      transform: translateY(150%);
      transition: transform 150ms cubic-bezier(0.4, 0, 1, 1);
    }

    &:focus,
    &:hover {
      & > ${VideoControls} {
        transform: translateY(0%);
      }
    }
  `;

export const VideoContainer = styled.figure<VideoContainerProps>`
  position: relative;
  overflow: hidden;
  margin: 0;
  ${collapsible};
`;
