import React, { useRef } from 'react';
import { CloudUpload } from '@material-ui/icons';
import { MuiButton } from 'theme/material-ui';
import { toSentenceCase } from 'modules/utils/StringUtils';

export interface FileUploadButtonProps {
  hasIcon?: boolean;
  iconPlacement?: 'start' | 'end';
  fullWidth?: boolean;
  variant?: 'contained' | 'outlined' | 'text';
  color?: 'primary' | 'secondary' | 'inherit' | 'default';
  disabled?: boolean;
  loading?: boolean;
  onClick?: (file: File) => void;
  children: React.ReactNode;
  image?: boolean;
}

function FileUploadButton({
  onClick,
  disabled,
  loading,
  iconPlacement,
  hasIcon,
  children,
  image,
  ...rest
}: FileUploadButtonProps) {
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <MuiButton
      type="button"
      onClick={() => inputRef.current?.click()}
      loading={loading}
      disabled={disabled}
      startIcon={hasIcon && iconPlacement === 'start' ? <CloudUpload /> : null}
      endIcon={hasIcon && iconPlacement === 'end' ? <CloudUpload /> : null}
      {...rest}
    >
      {toSentenceCase(children)}
      <input
        data-testid="file-upload"
        type="file"
        hidden
        ref={inputRef}
        disabled={disabled || loading}
        accept={image ? 'image/png, image/jpg, image/jpeg' : undefined}
        onChange={({
          target: {
            validity,
            files: [file]
          }
        }: React.ChangeEvent<HTMLInputElement>) => validity.valid && onClick?.(file)}
      />
    </MuiButton>
  );
}

FileUploadButton.defaultProps = {
  hasIcon: true,
  iconPlacement: 'start'
};

export default FileUploadButton;
