import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import startCase from 'lodash/startCase';
import { Appointment } from 'store/amwell/types';
import * as selectors from 'store/amwell/selectors';
import { RootState } from 'store/types';
import { Breadcrumb, BreadcrumbProps } from 'components/Breadcrumbs';

export interface Props extends BreadcrumbProps {
  appointment: Appointment | null;
}

export const AppointmentDetailsCrumb = (props: Props) => {
  const { appointment, ...rest } = props;

  const label = useMemo(() => {
    return appointment ? startCase(appointment.assignedProvider.fullName) : 'Appointment Details';
  }, [appointment]);

  return <Breadcrumb {...rest} label={label} />;
};

const mapStateToProps = (state: RootState) => ({
  appointment: selectors.appointmentDataSelector(state)
});

export default connect(mapStateToProps)(AppointmentDetailsCrumb);
