import React from 'react';
import { Color } from 'modules/styles/colors';
import { Spacing, FontWeight } from 'modules/styles/variables';
import {
  MuiBox,
  MuiTypography,
  MuiButton,
  MuiCard,
  MuiCardContent,
  ButtonProps
} from 'theme/material-ui';
import { FlexBoxRow, FlexBoxColumn } from 'components/UI/Layout/FlexBox';
import Svg from 'components/UI/Svg/Svg';
import Spacer from 'components/UI/Layout/Spacer';

interface Props {
  buttonProps: ButtonProps;
}

const DownloadLargeDocumentCard = ({ buttonProps }: Props) => (
  <FlexBoxColumn padding={25} alignItems="center" justifyContent="center">
    <MuiBox maxWidth="500px">
      <MuiCard>
        <MuiCardContent>
          <FlexBoxColumn spacing={Spacing.xLarge}>
            <FlexBoxRow>
              <Svg set="assets" name="InfoIcon" size={18} color={Color.primary} />
              <MuiBox paddingLeft={1}>
                <MuiTypography fontWeight={FontWeight.bold}>Download to View</MuiTypography>
              </MuiBox>
            </FlexBoxRow>
            <Spacer spacing="small" />
            <MuiBox paddingLeft={3.4}>
              <MuiTypography>
                The file you are trying to access is too large for online viewing. Please download
                to view.
              </MuiTypography>
              <Spacer spacing="xLarge" />
              <MuiButton color="primary" variant="contained" {...buttonProps}>
                <Svg set="material" name="get-app" color={Color.white} />
                <MuiTypography color={Color.white}>Download</MuiTypography>
              </MuiButton>
            </MuiBox>
          </FlexBoxColumn>
        </MuiCardContent>
      </MuiCard>
    </MuiBox>
  </FlexBoxColumn>
);

export default DownloadLargeDocumentCard;
