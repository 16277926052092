import { RefObject } from 'react';
import { BasicInfoForm } from 'store/billing/financialAssistance/createApplication/types';
import { KeyboardTypeOptions } from 'react-native';
import { FocusableInputProps } from 'components/UI/Input/FocusableInput';
import { TextInputMaskTypeProp, TextInputMaskOptionProp } from 'react-native-masked-text';

export type RefStore = RefObject<any>[];
export type InitPhone = Pick<BasicInfoForm, 'workPhone' | 'homePhone'>;
export interface InitAddress {
  addressLineOne: string;
  addressLineTwo: string;
  city: string;
  zip: string;
  state: string;
}

export enum BasicInfoInputType {
  DEFAULT = 'input',
  SELECT = 'select',
  CALENDAR = 'calendar'
}

export interface BasicInfo {
  idx: number;
  key: keyof BasicInfoForm & string;
  type: BasicInfoInputType;
  span: number;
  opts?: {
    hide?: (values: BasicInfoForm) => boolean;
    edit?: (values: BasicInfoForm, prefill: any) => boolean;
    last?: (values: BasicInfoForm, expanded: boolean) => boolean;
    fn?: string;
  };
  override?: {
    value?: (values: BasicInfoForm) => string | undefined;
    mask?: (values: BasicInfoForm) => string | undefined;
    minDate?: (values: BasicInfoForm) => string | Date | undefined;
    maxDate?: (values: BasicInfoForm) => string | Date | undefined;
  };
  props: {
    label: string;
    placeholder: string;
    accessibilityLabel: string;
    // Generic Options
    required?: boolean;
    autoFocus?: boolean;
    // FocusableInput Options
    keyboardType?: KeyboardTypeOptions;
    textContentType?: FocusableInputProps['textContentType'];
    maxLength?: number;
    // FocusableInput (Masked) Options
    type?: TextInputMaskTypeProp;
    options?: {
      mask: string;
      getRawValue?: (value: string, settings: TextInputMaskOptionProp) => any;
    };
    // CalendarDatePicker Options
    mode?: 'date' | 'calendar';
    minDate?: string | Date;
    maxDate?: string | Date;
    openTo?: 'year' | 'month';
    views?: ['year', 'month', 'date'];
    // InputSelectPicker
    items?: any[];
  };
}

export interface BasicInfoHeader {
  title?: string;
  hide?: (values: BasicInfoForm, expanded: boolean) => boolean;
  action?: {
    label: string;
    fn: string;
  };
}

export interface BasicInfoSection extends BasicInfoHeader {
  data: (BasicInfo | BasicInfo[])[];
}
