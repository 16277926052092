import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import {
  MuiBox,
  MuiContainer,
  MuiDivider,
  MuiGrid,
  MuiTypography,
  MuiFormControlLabel,
  MuiRadioGroup,
  MuiRadio
} from 'theme/material-ui';
import DataLoader from 'components/UI/DataLoader/DataLoader';
import { RootState } from 'store/types';
import * as bookingTypes from 'store/booking/types';
import * as bookingActions from 'store/booking/actions';
import * as bookingSelectors from 'store/booking/selectors';
import { StyledScreen } from '../components/styled';
import { BookingNoData } from '../components/sharedComponents';
import { BookingStepActions, BookingStepAction } from '../components/BookingStepper';
import { useCheckBookingInfoEffect } from '../useCheckBookingInfoEffect';
import FlexBox from 'components/UI/Layout/FlexBox';

interface Props extends RouteComponentProps {
  selectedAppointmentLocation: bookingTypes.AppointmentLocation;
  appointmentDetails: bookingTypes.AppointmentDetails;
  locations: bookingTypes.AppointmentLocation[];
  setAppointmentLocation: typeof bookingActions.setAppointmentLocation;
  setInitialTimeSlots: typeof bookingActions.setInitialTimeSlots;
  getLocationTimeSlots: typeof bookingActions.getLocationTimeSlots;
  fetchSlots: boolean;
  setFetchSlots: typeof bookingActions.setFetchSlots;
  setUpdatedVisitGroup: typeof bookingActions.setUpdatedVisitGroup;
  clearLocationTimeSlots: typeof bookingActions.clearLocationTimeSlots;
  clearAppointmentLocation: typeof bookingActions.clearAppointmentLocation;
  updatedVisitGroup: boolean;
}

export function BookingOfficeLocationSelect({
  selectedAppointmentLocation,
  appointmentDetails,
  locations,
  setAppointmentLocation,
  setInitialTimeSlots,
  getLocationTimeSlots,
  fetchSlots,
  setFetchSlots,
  setUpdatedVisitGroup,
  clearLocationTimeSlots,
  clearAppointmentLocation,
  updatedVisitGroup,
  history
}: Props) {
  useCheckBookingInfoEffect(appointmentDetails?.patient);

  useEffect(() => {
    clearLocationTimeSlots();
    if (locations?.length === 1 && !selectedAppointmentLocation && !fetchSlots) {
      setLocationAndSlots(locations[0]);
    }
  }, [locations]);

  const setLocationAndSlots = (loc: bookingTypes.AppointmentLocation) => {
    if (!selectedAppointmentLocation || selectedAppointmentLocation.locationId !== loc.locationId) {
      setUpdatedVisitGroup(true);
    }
    setAppointmentLocation(loc);
    setInitialTimeSlots(loc.slots);
  };

  const locationLabel = (location: bookingTypes.AppointmentLocation) => {
    return (
      <>
        <MuiTypography>{location.locationName}</MuiTypography>
        <MuiTypography>
          {`${location.addressLine1}, ${location.addressLine2 ? location.addressLine2 : ''}
          ${location.city}, ${location.state}`}
        </MuiTypography>
      </>
    );
  };

  const handleNext = async () => {
    if (updatedVisitGroup || fetchSlots) {
      getLocationTimeSlots();
    }
    setFetchSlots(false);
    setUpdatedVisitGroup(false);
    history.push('/u/get-care-now/booking/date-and-time-select');
  };

  const handlePrevious = (): void => {
    clearAppointmentLocation();
    history.goBack();
  };

  return (
    <StyledScreen>
      <FlexBox flex="1">
        <MuiContainer maxWidth="lg">
          <MuiBox my={3}>
            <MuiGrid container>
              <MuiGrid item xs>
                <MuiTypography gutterBottom variant="h4">
                  Select Provider Office Location
                </MuiTypography>
              </MuiGrid>
            </MuiGrid>
            <MuiBox mb={4}>
              <MuiDivider />
            </MuiBox>
            <MuiGrid container justify="center">
              <MuiBox>
                <DataLoader
                  data={locations}
                  renderNoData={() => <BookingNoData />}
                  renderData={() => (
                    <>
                      {locations.map(location => (
                        <MuiRadioGroup
                          onChange={() => setLocationAndSlots(location)}
                          key={location?.locationId}
                        >
                          <MuiGrid container>
                            <MuiBox
                              data-testid="location-office-radio-button"
                              pb={3}
                              alignItems="center"
                            >
                              <MuiFormControlLabel
                                value={location.locationId}
                                control={
                                  <MuiRadio
                                    color="secondary"
                                    size="medium"
                                    checked={
                                      appointmentDetails?.appointmentLocation?.locationId ===
                                      location.locationId
                                    }
                                  />
                                }
                                label={locationLabel(location)}
                              />
                            </MuiBox>
                          </MuiGrid>
                        </MuiRadioGroup>
                      ))}
                    </>
                  )}
                />
              </MuiBox>
            </MuiGrid>
          </MuiBox>
        </MuiContainer>
      </FlexBox>

      <BookingStepActions>
        <BookingStepAction
          disabled={!appointmentDetails?.appointmentLocation}
          onClick={handleNext}
          data-testid="next-button"
        />
        <BookingStepAction data-testid="previous-button" onClick={handlePrevious} />
      </BookingStepActions>
    </StyledScreen>
  );
}

const mapStateToProps = (state: RootState) => ({
  appointmentDetails: bookingSelectors.appointmentDetailsSelector(state),
  fetchSlots: bookingSelectors.fetchSlotsSelector(state),
  locations: bookingSelectors.groupedVisitTypeLocationsSelector(state),
  selectedAppointmentLocation: bookingSelectors.appointmentLocationSelector(state),
  updatedVisitGroup: bookingSelectors.updatedVisitGroupHasBeenSelected(state)
});

const mapDispatchToProps = {
  getLocationTimeSlots: bookingActions.getLocationTimeSlots,
  setAppointmentLocation: bookingActions.setAppointmentLocation,
  setFetchSlots: bookingActions.setFetchSlots,
  setInitialTimeSlots: bookingActions.setInitialTimeSlots,
  setUpdatedVisitGroup: bookingActions.setUpdatedVisitGroup,
  clearLocationTimeSlots: bookingActions.clearLocationTimeSlots,
  clearAppointmentLocation: bookingActions.clearAppointmentLocation
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingOfficeLocationSelect);
