import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';

import { MuiBox, MuiContainer } from 'theme/material-ui';
import { PaymentMethod, VisitSummary, Disposition } from 'store/amwell/types';
import * as selectors from 'store/amwell/selectors';
import { visitIsScheduledSelector } from 'store/amwell/visit/selectors';
import {
  currentLocationPathNameSelector,
  previousLocationPathNameSelector
} from 'store/router/selectors';
import analyticsService, { AnalyticsUserStat, AnalyticsEvent } from 'services/AnalyticsService';
import { getPaymentMethod, getVisitSummary } from 'store/amwell/actions';
import { RootState, RootDispatch } from 'store/types';
import { ConnectCareDataLoader } from 'components/ConnectCare';
import ConnectCareVisitSummaryCancel from './ConnectCareVisitSummaryCancel';
import ConnectCareVisitSummaryEnd from './ConnectCareVisitSummaryEnd';
import { FormScreen } from '../styled';

export interface Props {
  dispatch: RootDispatch;
  visitSummary: VisitSummary | null;
  visitSummaryLoading: boolean;
  visitSummaryError: Error | null;
  paymentMethod: PaymentMethod | null;
  paymentMethodLoading: boolean;
  paymentMethodError: Error | null;
  currentUrl?: string;
  referringUrl?: string;
  wasScheduledVisit?: boolean;
}

export function ConnectCareVisitSummary(props: Props) {
  const {
    dispatch,
    visitSummary,
    visitSummaryLoading,
    visitSummaryError,
    paymentMethod,
    paymentMethodLoading,
    paymentMethodError,
    currentUrl,
    referringUrl,
    wasScheduledVisit
  } = props;
  const initial = useRef(true);
  const prevVisitSummary = useRef<VisitSummary | null>(null);

  const loading = visitSummaryLoading || paymentMethodLoading || initial.current;
  const error = visitSummaryError || paymentMethodError;

  const fetchVisitSummary = () => {
    if (initial.current) {
      dispatch(getPaymentMethod());
      dispatch(getVisitSummary());
      initial.current = false;
    }
  };

  useEffect(fetchVisitSummary, []);

  useEffect(() => {
    const isConnectCareCompleted =
      !!visitSummary && visitSummary.disposition !== Disposition.ConsumerCanceled;
    if (prevVisitSummary.current === null && isConnectCareCompleted) {
      analyticsService.logEvent(AnalyticsEvent.ConnectCareComplete, {
        currentUrl,
        referringUrl
      });

      if (wasScheduledVisit) {
        analyticsService.incrementUserStat(AnalyticsUserStat.ScheduledVideoVisitsCompleted);
      }
    }

    prevVisitSummary.current = visitSummary;
  }, [visitSummary]);

  const errorComponentProps = {
    message: 'There was an issue fetching your visit summary',
    action: { onClick: fetchVisitSummary, label: 'Try Again?' }
  };

  return (
    <FormScreen>
      <MuiContainer maxWidth="md">
        <MuiBox my={3}>
          <ConnectCareDataLoader
            data={{ visitSummary, paymentMethod }}
            loading={loading}
            error={error}
            errorComponentProps={errorComponentProps}
            renderData={data => {
              const visitSummary = data.visitSummary as VisitSummary;
              const paymentMethod = data.paymentMethod as PaymentMethod;

              return visitSummary.disposition === Disposition.ConsumerCanceled ? (
                <ConnectCareVisitSummaryCancel />
              ) : (
                <ConnectCareVisitSummaryEnd
                  visitSummary={visitSummary}
                  paymentMethod={paymentMethod}
                />
              );
            }}
          />
        </MuiBox>
      </MuiContainer>
    </FormScreen>
  );
}

const mapStateToProps = (state: RootState) => ({
  visitSummary: selectors.visitSummaryDataSelector(state),
  visitSummaryLoading: selectors.visitSummaryLoadingSelector(state),
  visitSummaryError: selectors.visitSummaryErrorSelector(state),
  paymentMethod: selectors.paymentMethodDataSelector(state),
  paymentMethodLoading: selectors.paymentMethodDataLoadingSelector(state),
  paymentMethodError: selectors.paymentMethodDataErrorSelector(state),
  currentUrl: currentLocationPathNameSelector(state),
  referringUrl: previousLocationPathNameSelector(state),
  wasScheduledVisit: visitIsScheduledSelector(state)
});

export default connect(mapStateToProps)(ConnectCareVisitSummary);
