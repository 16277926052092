import React from 'react';

export default {
  svg: (
    <g id="Unauthenticated" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Web-|-Telephone-Verification-|-Verified"
        transform="translate(-833.000000, -542.000000)"
        fill="#75A201"
      >
        <g id="Group" transform="translate(683.000000, 500.000000)">
          <g id="Icons-/-Close-X" transform="translate(150.000000, 42.000000)">
            <path
              d="M22,0 C24.3432501,0 26.6407251,0.367520211 28.827942,1.08102628 C30.4781103,1.61933826 31.379448,3.39345207 30.841136,5.04362038 C30.302824,6.69378869 28.5287102,7.59512634 26.8785419,7.05681436 C25.3188289,6.5480104 23.6791402,6.28571429 22,6.28571429 C13.3212396,6.28571429 6.28571429,13.3212396 6.28571429,22 C6.28571429,30.6787604 13.3212396,37.7142857 22,37.7142857 C30.6787604,37.7142857 37.7142857,30.6787604 37.7142857,22 C37.7142857,21.2472122 37.6615807,20.5020213 37.5573546,19.7677637 C37.3134141,18.0492387 38.508801,16.458346 40.2273261,16.2144055 C41.9458511,15.9704651 43.5367437,17.165852 43.7806842,18.8843771 C43.9264169,19.9110427 44,20.9514254 44,22 C44,34.1502645 34.1502645,44 22,44 C9.8497355,44 0,34.1502645 0,22 C0,9.8497355 9.8497355,0 22,0 Z M39.5749589,3.4715172 C40.8930398,4.38245752 41.2990947,6.12051022 40.5775795,7.5056113 L40.3735849,7.8438505 L22.7502852,33.3438033 L12.4998187,21.9573688 C11.3384943,20.6673452 11.4428257,18.680135 12.7328493,17.5188106 C13.9236403,16.4468189 15.7084838,16.4532557 16.8894421,17.4752277 L17.1714075,17.7518412 L22.0974286,23.2257143 L35.2026256,4.27014324 C36.1894776,2.84222225 38.1470379,2.48466519 39.5749589,3.4715172 Z"
              id="Combined-Shape"
            ></path>
          </g>
        </g>
      </g>
    </g>
  ),
  viewBox: '0 0 44 44',
  height: '44px',
  width: '44px'
};
