import * as React from 'react';

export default {
  svg: (
    <g>
      <g fill="none" fillRule="evenodd">
        <g fillOpacity={0.475}>
          <path
            d="M0 138.284c87.36-70.646 218.4-69.77 393.12 2.627C567.84 213.31 826.8 263.204 1170 290.598v29.35H0V138.284z"
            fill="#213245"
            opacity={0.474}
          />
          <path
            d="M1170 95.5C1027.721 10.696 844.505-4.47 620.35 50 396.193 104.47 189.41 181.507 0 281.11v38.701h1170V95.5z"
            fill="#004394"
            opacity={0.831}
          />
        </g>
      </g>
    </g>
  ),
  viewBox: '0 0 1170 321'
};
