/* eslint-disable no-useless-escape */
import * as Yup from 'yup';

export const InsuranceValidateSchema = (screenText: object = {}) => {
  return Yup.object().shape({
    Health_Plan_Name: Yup.string()
      .max(100, screenText.error_100_characters)
      .required(screenText.error_name),
    Health_Plan_ID: Yup.string()
      .max(20, screenText.error_20_characters)
      .required(screenText.error_id),
    MEMBER_NUMBER: Yup.string()
      .max(20, screenText.error_20_characters)
      .required(screenText.error_number),
    GROUP_NUMBER: Yup.string()
      .max(20, screenText.error_20_characters)
      .optional(),
    Health_Plan_Address_line_1: Yup.string()
      .max(30, screenText.error_30_characters)
      .required(screenText.error_street),
    HealthPlan_City: Yup.string()
      .max(34, screenText.error_34_characters)
      .required(screenText.error_city),
    HealthPlan_state: Yup.string()
      .max(2, '2 characters maximum')
      .required(screenText.error_state),
    HealthPlan_zip_code: Yup.string()
      .required(screenText.error_zip)
      .matches(/^[0-9]+$/, screenText.error_valid_zip)
      .min(5, 'Must be exactly 5 digits')
      .max(5, 'Must be exactly 5 digits'),
    Q_Patient_relationship_to_subscriber: Yup.string().required(screenText.label_relationship),
    SUBSCRIBER_FIRST_NAME_ON_CARD: Yup.string()
      .max(16, screenText.error_16_characters)
      .matches(/^[a-zA-Z-]*$/, screenText.error_invalid_char)
      .required(screenText.error_first_name),
    SUBSCRIBER_MIDDLE_NAME_ON_CARD: Yup.string()
      .max(16, screenText.error_16_characters)
      .matches(/^[a-zA-Z-]*$/, screenText.error_invalid_char)
      .optional(),
    SUBSCRIBER_LAST_NAME_ON_CARD: Yup.string()
      .max(26, screenText.error_26_characters)
      .matches(/^[a-zA-Z-]*$/, screenText.error_invalid_char)
      .required(screenText.error_last_name)
  });
};
