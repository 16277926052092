import React from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { useSelector } from 'react-redux';
import { FiltersStructure } from 'lib/hooks/ProviderSearch/types';
import { isSelectHealthMemberSelector } from 'store/profile/selectors';
import { FilterDrawer } from 'components/FilterDrawer';
import { FormCheckboxList } from '../shared';

export type OrganizationAffiliationsFilterProps = FiltersStructure['organizationAffiliations'] & {
  defaultExpanded: boolean;
};

function OrganizationAffiliationsFilter({
  title,
  values: valuesProp,
  defaultExpanded
}: OrganizationAffiliationsFilterProps) {
  const isSelectHealth = useSelector(isSelectHealthMemberSelector);

  const values: Mutable<typeof valuesProp> = cloneDeep(valuesProp);

  if (!isSelectHealth) {
    delete values.isStLukes;
  }

  return (
    <FilterDrawer.Panel title={title} defaultExpanded={defaultExpanded}>
      <FormCheckboxList list={Object.values(values)} />
    </FilterDrawer.Panel>
  );
}

export default OrganizationAffiliationsFilter;
