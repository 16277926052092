import React from 'react';
import { KeyboardDatePicker, KeyboardDatePickerProps } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

type OmittedProps =
  | 'clearable'
  | 'disableFuture'
  | 'disableToolbar'
  | 'autoOk'
  | 'variant'
  | 'inputVariant'
  | 'format'
  | 'openTo'
  | 'views'
  | 'onChange'
  | 'fullWidth'
  | 'invalidDateMessage'
  | 'maxDateMessage'
  | 'minDateMessage'
  | 'KeyboardButtonProps'
  | 'requried';
type DatePickerProps = Omit<KeyboardDatePickerProps, OmittedProps>;
export interface ConnectCareDatePickerProps extends DatePickerProps {
  ariaLabel?: string;
  helperText?: React.ReactNode;
  onChange?: (date: MaterialUiPickersDate) => void;
}

function ConnectCareDatePicker(props: ConnectCareDatePickerProps) {
  const { ariaLabel, onChange, helperText, ...rest } = props;

  return (
    <KeyboardDatePicker
      {...rest}
      id="connect-care-date-picker"
      autoOk
      fullWidth
      disableFuture
      disableToolbar
      maxDateMessage={null}
      minDateMessage={null}
      invalidDateMessage={null}
      openTo="year"
      variant="inline"
      format="MM/DD/YYYY"
      inputVariant="outlined"
      helperText={helperText}
      views={['year', 'month', 'date']}
      onChange={date => onChange && onChange(date)}
      KeyboardButtonProps={{ 'aria-label': ariaLabel }}
      InputLabelProps={{ shrink: true, htmlFor: 'connect-care-date-picker' }}
    />
  );
}

ConnectCareDatePicker.defaultProps = {
  placeholder: 'MM/DD/YYYY'
};

export default ConnectCareDatePicker;
