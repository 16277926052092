import { MuiTableContainer, MuiPaper } from 'theme/material-ui';
import React from 'react';
import { connect } from 'react-redux';
import { ListItem } from 'components/UI/ListItem/ListItem';

export const AccessLogList = () => {
  return (
    <MuiTableContainer component={MuiPaper}>
      <ListItem
        title="Audit logs are a historical log of activity within the application. This information can include what pages people are viewing, the success status of requests, and the time and date of activity. "
        to="/u/manage-account/audit-logs"
        icon="ChevronRight"
      />
    </MuiTableContainer>
  );
};

export default connect(null)(AccessLogList);
