import React from 'react';
import dayjs from 'dayjs';
import capitalize from 'lodash/capitalize';
import { ListItem } from 'components/UI/ListItem/ListItem';
import { MuiTypography } from 'theme/material-ui';
import { EducationItem } from 'store/education/types';
import { IconSize } from 'modules/styles/variables';

export interface EducationListItemProps {
  item: EducationItem;
  onPress: () => void;
}

export const EducationListItem = ({ item, onPress }: EducationListItemProps) => {
  const name = capitalize(item?.programName || 'Program');
  const dueDate = dayjs(item?.viewBy).format('MM/DD/YYYY');

  return (
    <ListItem
      key={`${name}-${dueDate}`}
      testID="education-item"
      accessibilityLabel={`Tap to view ${name}`}
      accessible
      title=""
      body={
        <MuiTypography>
          {name} |{' '}
          <MuiTypography data-testid="education-status" component="span">
            Status: {item?.status || ''} |{' '}
          </MuiTypography>
          <MuiTypography data-testid="due-date" component="span" fontWeight="bold">
            Due Date: {dueDate}
          </MuiTypography>
        </MuiTypography>
      }
      icon="ChevronRight"
      iconSize={IconSize.xmedium}
      onPress={onPress}
      style={{ paddingTop: '15px', paddingBottom: '15px' }}
    />
  );
};

export default EducationListItem;
