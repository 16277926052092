import React, { ComponentType, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import dayjs from 'dayjs';

import Logger from 'services/Logger';
import { RootState } from 'store/types';
import * as covidActions from 'store/CovidScreening/actions';
import {
  Patient,
  Questionnaire,
  CovidScreeningState,
  InsuranceCard
} from 'store/CovidScreening/types';
import * as covidScreeningSelectors from 'store/CovidScreening/selectors';
import { Insurance, Grantor } from 'store/profile/types';
import { profileConsumerMilleniumInsuranceSelector } from 'store/profile/selectors';
import { AuthType } from 'store/authentication/types';
import { CovidTitles } from 'lib/constants/covidScreening';
import { translations } from 'lib/constants/translations/screens/covidGuest/summary';
import { getRelationTranslated } from 'modules/constants/covidScreening/personalInfo.ts';
import {
  relationTranslations,
  genderTranslations,
  yesNotranslations
} from 'lib/constants/translations/components/index';
import { maskPhone } from 'modules/utils/MaskUtils';
import { Color } from 'modules/styles/colors';
import { FontWeight } from 'modules/styles/variables';
import { MuiContainer, MuiTypography, MuiBox } from 'theme/material-ui';
import { AnalyticsEvent } from 'services/AnalyticsService';
import { useLanguageSwitcher } from 'lib/hooks/useLanguageSwitcher';

import Spacer from 'components/UI/Layout/Spacer';
import { SpinnerOverlay } from 'components/UI/Spinner/SpinnerModal';
import useNavigationAnalytics from 'hooks/useNavigationAnalytics';

import { createQuestionnaireObject, createSubscriberObject } from './utils';
import { ButtonsNavigation, CovidTestGuestFooter } from '../components';
import CovidGuestGuard from '../navigation/CovidGuestLeavingGuard';

export interface Props extends RouteComponentProps {
  patientsData: Patient;
  questionnaire: Questionnaire;
  covidScreeningData: CovidScreeningState;
  sendCovidScreeningFinalData: typeof covidActions.sendGuestCovidScreeningData;
  setCovidScreeningOrderStatus: typeof covidActions.covidOrderStatus;
  activeInsurance: Insurance[];
  grantors: Grantor[];
}

export function CovidScreenGuestSummary({
  history,
  questionnaire,
  patientsData,
  covidScreeningData,
  sendCovidScreeningFinalData,
  setCovidScreeningOrderStatus
}: Props) {
  const covidTestSummaryPrev = useNavigationAnalytics(AnalyticsEvent.CovidPrev);
  const covidTestSummarySubmit = useNavigationAnalytics(AnalyticsEvent.CovidSubmit);
  const screenText = useLanguageSwitcher({
    en: {
      ...translations.en,
      ...relationTranslations.en,
      ...genderTranslations.en,
      ...yesNotranslations.en
    },
    es: {
      ...translations.es,
      ...relationTranslations.es,
      ...genderTranslations.es,
      ...yesNotranslations.es
    }
  });

  const [isLoading, setIsLoading] = useState(false);
  const updatePatientPhones = (covidScreeningData: CovidScreeningState) => {
    const updatedPhonesArray = covidScreeningData.patient.phones?.map(({ verified, ...rest }) => {
      return rest;
    });
    return updatedPhonesArray;
  };

  const getInsuranceCardData = () => {
    if (covidScreeningData?.insuranceCard?.length) {
      let insuranceBack = {};
      let insuranceFront = {};
      let insuranceCardData: InsuranceCard[] = [];

      if (covidScreeningData.insuranceCard?.[0]) {
        insuranceFront = {
          fileName: covidScreeningData.insuranceCard?.[0].fileName,
          img: covidScreeningData.insuranceCard?.[0].img,
          mimeType: covidScreeningData.insuranceCard?.[0].mimeType
        };
        insuranceCardData = [insuranceFront];
      }

      if (covidScreeningData.insuranceCard?.[1]) {
        insuranceBack = {
          fileName: covidScreeningData.insuranceCard?.[1].fileName,
          img: covidScreeningData.insuranceCard?.[1].img,
          mimeType: covidScreeningData.insuranceCard?.[1].mimeType
        };
        insuranceCardData = [...insuranceCardData, insuranceBack];
      }

      return insuranceCardData;
    }

    return [];
  };

  const submitCovidScreening = async () => {
    try {
      setIsLoading(true);

      const patientObject = {
        dateOfBirth: covidScreeningData.patient.dateOfBirth,
        genderType: covidScreeningData.patient.genderType,
        names: covidScreeningData.patient.names,
        addresses: covidScreeningData.patient.addresses,
        phones: updatePatientPhones(covidScreeningData),
        emails: covidScreeningData.patient.emails
      };

      const insuranceCardData = getInsuranceCardData();

      const sendData = {
        patient: patientObject,
        questionnaire: createQuestionnaireObject(covidScreeningData),
        subscriber: createSubscriberObject(covidScreeningData),
        insuranceCard: insuranceCardData.length ? insuranceCardData : null
      };

      const result = await sendCovidScreeningFinalData(sendData);

      setIsLoading(false);
      covidTestSummarySubmit({
        title: CovidTitles.PATIENT_SUMMARY,
        authentication_type: AuthType.UNAUTHENTICATED
      });

      if (result?.error) {
        setCovidScreeningOrderStatus(result?.error?.response?.status);
        history.push('/guest-home/covid-screen/guest-covid-order-status');
      } else {
        history.push('/guest-home/covid-screen/guest-covid-order-confirmation');
      }
    } catch (e) {
      Logger.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const getGenderTranslation = (gender: string) => {
    if (screenText[gender]) return screenText[gender];
    switch (gender) {
      case 'MALE':
        return screenText.male;
      case 'FEMALE':
        return screenText.female;
      case 'UNKNOWN':
        return screenText.unknown;
      case 'BOTH':
        return screenText.both;
      default:
        break;
    }
    return '';
  };

  const handleCancel = () => {
    covidTestSummaryPrev({
      title: CovidTitles.PATIENT_SUMMARY,
      authentication_type: AuthType.UNAUTHENTICATED
    });
    history.goBack();
  };

  return (
    <>
      <CovidGuestGuard />
      <MuiContainer maxWidth="lg">
        <SpinnerOverlay
          isLoading={isLoading}
          position="absolute"
          backgroundColor={Color.translucent}
          testID="Covid-Order-Submit"
        />
        <Spacer size="medium" />
        <MuiTypography fontWeight={FontWeight.bold}>{screenText.title}</MuiTypography>
        <Spacer size="xsmall" />
        <MuiTypography fontWeight={FontWeight.semibold} display="inline">
          {screenText.label_name}
        </MuiTypography>
        <MuiTypography display="inline">
          {patientsData?.names[0]?.givenName} {patientsData?.names[0]?.middleName}
          {patientsData?.names[0]?.familyName}
        </MuiTypography>
        <MuiTypography>
          <MuiTypography fontWeight={FontWeight.semibold} display="inline">
            {screenText.label_phone}
          </MuiTypography>
          <MuiTypography display="inline">
            {maskPhone(
              patientsData?.phones[0]?.areaCode.concat(patientsData?.phones[0]?.phoneNumber)
            )}
          </MuiTypography>
        </MuiTypography>
        <MuiTypography>
          <MuiTypography fontWeight={FontWeight.semibold} display="inline">
            {screenText.label_alt_phone}
          </MuiTypography>
          <MuiTypography display="inline">{patientsData?.alternateNumber}</MuiTypography>
        </MuiTypography>
        <MuiTypography>
          <MuiTypography fontWeight={FontWeight.semibold} display="inline">
            {screenText.label_email}
          </MuiTypography>
          <MuiTypography display="inline"> {patientsData?.emails[0]?.address} </MuiTypography>
        </MuiTypography>
        <MuiTypography>
          <MuiTypography fontWeight={FontWeight.semibold} display="inline">
            {screenText.label_birthdate}
          </MuiTypography>
          <MuiTypography display="inline"> {patientsData?.dateOfBirth} </MuiTypography>
        </MuiTypography>
        <MuiTypography>
          <MuiTypography fontWeight={FontWeight.semibold} display="inline">
            {screenText.label_sex}
          </MuiTypography>
          <MuiTypography display="inline">
            {getGenderTranslation(patientsData?.genderType)}
          </MuiTypography>
        </MuiTypography>
        <MuiTypography>
          <MuiTypography fontWeight={FontWeight.semibold} display="inline">
            {screenText.label_address}
          </MuiTypography>
          <MuiTypography>
            {`${patientsData?.addresses[0]?.line1 ?? ''} ${patientsData.addresses[0]?.line2 ?? ''}`}
          </MuiTypography>
          <MuiTypography>
            {`${patientsData?.addresses[0]?.city ?? ''} ${patientsData?.addresses[0]?.state ??
              ''} ${patientsData?.addresses[0]?.zip ?? ''}`}
          </MuiTypography>
        </MuiTypography>
        <Spacer size="small" />
        <MuiTypography>
          <MuiTypography fontWeight={FontWeight.semibold} display="inline">
            {screenText.label_have_insurance}
          </MuiTypography>
          <MuiTypography display="inline">
            {questionnaire?.has_insurance === 'Yes' ? screenText.yes : screenText.no}
          </MuiTypography>
        </MuiTypography>
        {questionnaire?.has_insurance === 'Yes' ? (
          <MuiTypography>
            <MuiTypography fontWeight={FontWeight.semibold} display="inline">
              {screenText.label_seen_intermountain}
            </MuiTypography>
            <MuiTypography display="inline">
              {questionnaire?.have_been_patient === 'Yes' ? screenText.yes : screenText.no}
            </MuiTypography>
          </MuiTypography>
        ) : null}
        {questionnaire?.have_been_patient === 'Yes' ? (
          <MuiTypography>
            <MuiTypography fontWeight={FontWeight.semibold} display="inline">
              {screenText.label_change_insurance}
            </MuiTypography>
            <MuiTypography display="inline">
              {questionnaire?.have_changed_insurance === 'Yes' ? screenText.yes : screenText.no}
            </MuiTypography>
          </MuiTypography>
        ) : null}
        {questionnaire?.MEMBER_NUMBER ? (
          <>
            <Spacer size="small" />
            {questionnaire?.has_insurance === 'Yes' ? (
              <MuiTypography fontWeight={FontWeight.bold}>
                {screenText.header_insurance}
              </MuiTypography>
            ) : null}
            {questionnaire?.Health_Plan_Name && questionnaire?.has_insurance === 'Yes' ? (
              <MuiTypography>
                <MuiTypography fontWeight={FontWeight.semibold} display="inline">
                  {screenText.label_plan}
                </MuiTypography>
                <MuiTypography display="inline"> {questionnaire?.Health_Plan_Name} </MuiTypography>
              </MuiTypography>
            ) : null}
            {questionnaire?.MEMBER_NUMBER && questionnaire?.has_insurance === 'Yes' ? (
              <MuiTypography>
                <MuiTypography fontWeight={FontWeight.semibold} display="inline">
                  {screenText.label_member_id}
                </MuiTypography>
                <MuiTypography display="inline"> {questionnaire?.MEMBER_NUMBER} </MuiTypography>
              </MuiTypography>
            ) : null}
            {questionnaire?.GROUP_NUMBER && questionnaire?.has_insurance === 'Yes' ? (
              <MuiTypography>
                <MuiTypography fontWeight={FontWeight.semibold} display="inline">
                  {screenText.label_group_id}
                </MuiTypography>
                <MuiTypography display="inline"> {questionnaire?.GROUP_NUMBER} </MuiTypography>
              </MuiTypography>
            ) : null}
            {questionnaire?.has_insurance === 'No' ? <Spacer size="small" /> : null}
            {questionnaire?.Q_Patient_relationship_to_subscriber &&
            questionnaire?.Q_Patient_relationship_to_subscriber !== 'Self' ? (
              <>
                <Spacer size="small" />
                <MuiTypography fontWeight={FontWeight.bold}>
                  {screenText.header_subscriber}
                </MuiTypography>
                <Spacer size="xsmall" />
                <MuiTypography>
                  <MuiTypography fontWeight={FontWeight.semibold} display="inline">
                    {screenText.label_relationship}
                  </MuiTypography>
                  <MuiTypography display="inline">
                    {getRelationTranslated(
                      questionnaire?.Q_Patient_relationship_to_subscriber,
                      screenText
                    )}
                  </MuiTypography>
                </MuiTypography>
                <MuiTypography>
                  <MuiTypography fontWeight={FontWeight.semibold} display="inline">
                    {screenText.label_subscriber_name}
                  </MuiTypography>
                  <MuiTypography display="inline">
                    {covidScreeningData?.subscriber?.subscriberFirstName}{' '}
                    {covidScreeningData?.subscriber?.subscriberMiddleName}{' '}
                    {covidScreeningData?.subscriber?.subscriberLastName}
                  </MuiTypography>
                </MuiTypography>
                <MuiTypography>
                  <MuiTypography fontWeight={FontWeight.semibold} display="inline">
                    {screenText.label_subscriber_phone}
                  </MuiTypography>
                  <MuiTypography display="inline">
                    {covidScreeningData?.subscriber?.subscriberCellNumber}
                  </MuiTypography>
                </MuiTypography>
                <MuiTypography>
                  <MuiTypography fontWeight={FontWeight.semibold} display="inline">
                    {screenText.label_subscriber_birthdate}
                  </MuiTypography>
                  <MuiTypography display="inline">
                    {dayjs(covidScreeningData?.subscriber?.subscriberDateOfBirth).format(
                      'MM/DD/YYYY'
                    )}
                  </MuiTypography>
                </MuiTypography>
                <MuiTypography>
                  <MuiTypography fontWeight={FontWeight.semibold} display="inline">
                    {screenText.label_subscriber_email}
                  </MuiTypography>
                  <MuiTypography display="inline">
                    {covidScreeningData?.subscriber?.subscriberEmail}
                  </MuiTypography>
                </MuiTypography>
                <MuiTypography>
                  <MuiTypography fontWeight={FontWeight.semibold} display="inline">
                    {screenText.label_subscriber_sex}
                  </MuiTypography>
                  <MuiTypography display="inline">
                    {getGenderTranslation(covidScreeningData?.subscriber?.subscriberSex)}
                  </MuiTypography>
                </MuiTypography>
                <MuiTypography fontWeight={FontWeight.semibold} display="inline">
                  {screenText.label_subscriber_address}
                </MuiTypography>
                <MuiTypography>
                  {`${covidScreeningData?.subscriber?.subscriberAddressLine1} ${covidScreeningData?.subscriber?.subscriberAddressLine2}`}
                </MuiTypography>
                <MuiTypography>
                  {`${covidScreeningData?.subscriber?.subscriberCity} ${covidScreeningData?.subscriber?.subscriberState} ${covidScreeningData?.subscriber?.subscriberZip}`}
                </MuiTypography>
              </>
            ) : null}
          </>
        ) : null}
        <Spacer size="small" />
        <MuiTypography>{screenText.paragraph}</MuiTypography>
        <Spacer size="small" />
        {!covidScreeningData?.patient?.dateOfBirth ? (
          <MuiBox pl={4} my={2}>
            <MuiTypography variant="h5" align="center" color="red">
              Something went wrong, please try again.
            </MuiTypography>
          </MuiBox>
        ) : null}
        <ButtonsNavigation
          cancelTestId="summary-previous-button"
          nextTestId="summary-submit-button"
          disabledNextButton={!covidScreeningData?.patient?.dateOfBirth}
          isSubmit
          onCancelClick={() => handleCancel()}
          onNextClick={() => {
            submitCovidScreening();
          }}
        />
        <Spacer size="small" />
        <CovidTestGuestFooter title={CovidTitles.PATIENT_SUMMARY} />
        <Spacer size="small" />
      </MuiContainer>
    </>
  );
}

const mapStateToProps = (state: RootState) => ({
  questionnaire: covidScreeningSelectors.CovidScreeningQuestionnaireSelector(state),
  patientsData: covidScreeningSelectors.CovidScreeningPatientInfoSelector(state),
  covidScreeningData: covidScreeningSelectors.CovidScreeningSelector(state),
  subscriberInfo: covidScreeningSelectors.CovidScreeningSubscriberSelector(state),
  activeInsurance: profileConsumerMilleniumInsuranceSelector(state)
});

const mapDispatchToProps = {
  sendCovidScreeningFinalData: covidActions.sendGuestCovidScreeningData,
  setCovidScreeningOrderStatus: covidActions.covidOrderStatus
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CovidScreenGuestSummary as ComponentType);
