import React, { useState } from 'react';
import { connect } from 'react-redux';
import { RootState } from 'store/types';
import {
  MuiBox,
  MuiTypography,
  MuiCard,
  MuiCardContent,
  MuiCardActionArea,
  MuiGrid,
  MuiButton,
  MuiIconButton
} from 'theme/material-ui';
import * as selectors from 'store/amwell/selectors';
import * as actions from 'store/amwell/actions';
import { Practice } from 'store/amwell/types';
import { ConnectCareError, ConnectCareLoading } from 'components/ConnectCare';
import DataLoader from 'components/UI/DataLoader/DataLoader';
import { IconSize } from 'modules/styles/variables';
import { Svg } from 'components/UI/Svg';
import { Alert } from 'components/Alert';
import { providerSelect } from 'modules/constants/amwell';
import { getSelectionId } from '../utils';
import { PracticesWrapper } from './styled';
import ConnectCareAddServiceKeyDialog from './ConnectCareAddServiceKeyDialog';

export interface Props {
  practiceSelection: Practice | null;
  practices: Practice[];
  loading: boolean;
  error: Error | null;
  getPractices: typeof actions.getPractices;
  setPracticeSelection: (practice: Practice | null) => void;
}

export function ConnectCarePractices({
  practices,
  error,
  loading,
  getPractices,
  practiceSelection,
  setPracticeSelection
}: Props) {
  const practiceId = getSelectionId(practiceSelection);
  const [showAddServiceKey, setShowAddServiceKey] = useState(false);

  return (
    <>
      <MuiGrid container justify="space-between" alignItems="center">
        <MuiGrid item>
          <MuiTypography variant="h5">{providerSelect.PRACTICES_TITLE.title}</MuiTypography>
        </MuiGrid>
        <MuiGrid item>
          <MuiButton
            color="primary"
            data-testid="service-key"
            onClick={() => setShowAddServiceKey(true)}
          >
            {providerSelect.SERVICE_KEY_TITLE.title}
          </MuiButton>

          <MuiIconButton
            title="Term Definitions"
            aria-label="More info on Service Keys"
            onClick={() =>
              Alert.alert(
                providerSelect.SERVICE_KEY_INFO.title,
                providerSelect.SERVICE_KEY_INFO.subtitle
              )
            }
            color="inherit"
          >
            <Svg set="assets" name="InfoIcon" size={IconSize.base} />
          </MuiIconButton>
        </MuiGrid>
      </MuiGrid>
      <DataLoader
        data={practices}
        loading={loading}
        error={error}
        renderLoading={() => <ConnectCareLoading />}
        renderError={() => (
          <ConnectCareError
            message={providerSelect.PRACTICES_ERROR.title}
            action={{ onClick: getPractices }}
          />
        )}
        renderNoData={() => <ConnectCareError message={providerSelect.PRACTICES_NO_DATA.title} />}
        renderData={data => (
          <PracticesWrapper>
            <MuiBox>
              {data.map(p => {
                const id = getSelectionId(p);
                const selected = id === practiceId;

                return (
                  <MuiBox key={id}>
                    <MuiCard raised={selected}>
                      <MuiCardActionArea
                        disabled={selected}
                        onClick={() => setPracticeSelection(p)}
                      >
                        <MuiCardContent>
                          <MuiTypography data-testid={p.name} variant="h6">
                            {p.name}
                          </MuiTypography>
                          <MuiTypography color="textSecondary">{p.subtitle}</MuiTypography>
                        </MuiCardContent>
                      </MuiCardActionArea>
                    </MuiCard>
                  </MuiBox>
                );
              })}
            </MuiBox>
          </PracticesWrapper>
        )}
      />
      <ConnectCareAddServiceKeyDialog
        isOpen={showAddServiceKey}
        onClose={() => setShowAddServiceKey(false)}
        onSubmit={getPractices}
      />
    </>
  );
}

const mapStateToProps = (state: RootState) => ({
  practices: selectors.practicesListDataSelector(state),
  loading: selectors.practicesListDataLoadingSelector(state),
  error: selectors.practicesListDataErrorSelector(state)
});

const mapDispatch = {
  getPractices: actions.getPractices
};

export default connect(mapStateToProps, mapDispatch)(ConnectCarePractices);
