import styled from 'styled-components';

import { Color } from 'modules/styles/colors';
import { FontSize } from 'modules/styles/variables';

import DefaultText from './DefaultText';
import { dfdDefaultTheme } from 'theme/material-ui';

interface Props {
  color?: string;
  adjustsFontSizeToFit?: boolean;
  FontSize?: string | number;
  LineHeight?: string | number;
  numberOfLines?: number;
}

export default styled(DefaultText)`
  color: ${(props: Props) => (props.color ? props.color : Color.textDark)};
  font-size: ${(props: Props) => (props.FontSize ? `${props.FontSize}px` : `${FontSize.xlarge}px`)};
  line-height: ${(props: Props) =>
    props.LineHeight ? `${props.LineHeight}px` : `${FontSize.xlarge}px`};

  ${(props: Props) =>
    props.adjustsFontSizeToFit &&
    `${dfdDefaultTheme.breakpoints.down('lg')} {
        font-size: ${FontSize.xlarge}px;
        line-height: ${FontSize.xlarge}px;
      }

    ${dfdDefaultTheme.breakpoints.down('md')} {
      font-size: ${FontSize.largeHeading}px;
      line-height: ${FontSize.largeHeading}px;
    }

    ${dfdDefaultTheme.breakpoints.down('sm')} {
      font-size: ${FontSize.heading}px;
      line-height: ${FontSize.heading}px;
    }`}
`;

export const HeadingMedium = styled(DefaultText)`
  color: ${(props: Props) => (props.color ? props.color : Color.textDark)};
  font-size: ${FontSize.largeHeading}px;
  line-height: ${FontSize.largeHeading}px;

  ${(props: Props) =>
    props.adjustsFontSizeToFit &&
    `${dfdDefaultTheme.breakpoints.down('lg')} {
        font-size: ${FontSize.largeHeading}px;
        line-height: ${FontSize.largeHeading}px;
      }
    
    ${dfdDefaultTheme.breakpoints.down('md')} {
      font-size: ${FontSize.heading}px;
      line-height: ${FontSize.heading}px;
    }
    
    ${dfdDefaultTheme.breakpoints.down('sm')} {
      font-size: ${FontSize.large}px;
      line-height: ${FontSize.large}px;
    }`}
`;
