import React from 'react';
import { MuiBox } from 'theme/material-ui';
import useStyles from '../useStyles';

export interface BookingStepActionsProps {
  children: React.ReactNode;
}

function BookingStepActions({ children }: BookingStepActionsProps) {
  const classes = useStyles();

  return (
    <MuiBox className={classes.steppersFooter}>
      {React.Children.map(children, (child, index) => {
        if (!React.isValidElement(child)) return null;

        return React.cloneElement(child, {
          ...child.props,
          variant: index === 0 ? 'next' : 'previous'
        });
      })}
    </MuiBox>
  );
}

export default BookingStepActions;
