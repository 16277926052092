import React from 'react';
import styled from 'styled-components';
import { MuiCardContent, CardContentProps } from 'theme/material-ui';

export interface CardContent extends CardContentProps {
  padding?: string;
}

const Content = styled(MuiCardContent)`
  &.MuiCardContent-root {
    padding: ${props => props.padding};
  }
`;

export const ConnectCareCardContent = (props: CardContent) => <Content {...props} />;

ConnectCareCardContent.defaultProps = {
  padding: '8px 16px'
};
