import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import Container from '@material-ui/core/Container';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import dayjs from 'dayjs';
import { Formik } from 'formik';
import { history } from 'lib/history';
import isEmpty from 'lodash/isEmpty';
import wait from 'modules/utils/wait';
import React, { useState } from 'react';
import Config from 'react-native-config';
import { connect } from 'react-redux';
import analyticsService, { AnalyticsEvent } from 'services/AnalyticsService';
import {
  setFAForm5Complete,
  setFAFormClear,
  submitFAForm
} from 'store/billing/financialAssistance/createApplication/actions';
import {
  faAssetsSelector,
  faBasicInfoSelector,
  faBillsSelector,
  faCompleteSelector,
  faHouseholdSelector,
  faSubmitSendingSelector
} from 'store/billing/financialAssistance/createApplication/selectors';
import {
  currentLocationPathNameSelector,
  previousLocationPathNameSelector
} from 'store/router/selectors';
import { consumerIdSelector, profileConsumerDisplayNameSelector } from 'store/profile/selectors';
import { RootState } from 'store/types';
import ConfirmDialog from '../../components/ConfirmDialog';
import { submitInformationValidationSchema } from '../validation';
import Assets from './components/Assets';
import BasicInfo from './components/BasicInfo';
import BillsInfo from './components/BillsInfo';
import Household from './components/Household';
import { ApplicationDto, CreateApplicationDto, Data } from './components/types';
import { FA_FORM_SECTIONS } from './constants';
import { transformAssets, transformBasicInfo, transformHousehold } from './dataUtils';
import { convertToLowerKabobCase } from 'modules/utils/StringUtils';
import { StyledAsterisk } from 'components/RequiredFieldsLegend';
import { MuiButton } from 'theme/material-ui';
import DisableWhenWaiting from '../../components/DisableWhenWaiting';

const ExpansionPanel = withStyles({
  root: {
    backgroundColor: 'transparent',
    border: 'none',
    boxShadow: 'none',
    '&:before': {
      display: 'none'
    },
    '&$expanded': {
      margin: 'auto'
    }
  },
  expanded: {}
})(MuiExpansionPanel);

const ExpansionPanelDetails = withStyles(theme => ({
  root: {
    display: 'block',
    backgroundColor: theme.palette.common.white,
    borderRadius: '2px',
    boxShadow: theme.shadows[1],
    padding: theme.spacing(4)
  }
}))(MuiExpansionPanelDetails);

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: 'transparent',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 64,
    paddingLeft: 0,
    paddingRight: 0,
    '&$expanded': {
      minHeight: 64,
      borderColor: 'transparent'
    }
  },
  content: {
    '&$expanded': {
      margin: '12px 0'
    }
  },
  expanded: {}
})(MuiExpansionPanelSummary);

interface Props {
  basicInfoData: Data | {};
  householdData: Data | {};
  assetsData: Data | {};
  billsInfo: Data | {};
  consumerId: string;
  createUsername: string;
  dfdAccessToken: string;
  setFAFormClear: typeof setFAFormClear;
  submitFAForm: (
    arg0: CreateApplicationDto
  ) => {
    response: { payload: { data: { applicationId: string } } };
    error: {};
  };
  isSending: boolean;
  setFAForm5Complete: typeof setFAForm5Complete;
  faCompleteFieldData: Data | {};
  currentUrl?: string;
  referringUrl?: string;
}

interface FormikValues {
  email: string;
  retypeEmail: string;
  guarantorName: string;
  signDate: string;
  readMessage: boolean | string;
  esign: boolean | string;
}

const initialValues: FormikValues = {
  email: '',
  retypeEmail: '',
  guarantorName: '',
  signDate: dayjs().format('MM/DD/YYYY'),
  readMessage: false,
  esign: false
};

export const FAComplete = (props: Props) => {
  const { currentUrl, referringUrl } = props;
  const [showErrorDialog, setShowErrorDialog] = useState(false);

  const navigateToPrev = (values: FormikValues) => {
    props.setFAForm5Complete({ email: values.email, retypeEmail: values.retypeEmail });
    history.push('/u/fa-app/bills');
  };

  const sendToEndpoint = async (additionalValues: FormikValues) => {
    const basicInfo = transformBasicInfo(props.basicInfoData);
    const household = transformHousehold(props.householdData);
    const assets = transformAssets(props.assetsData);

    const additionalValuesObj = Object.assign({}, additionalValues);
    additionalValuesObj.signDate = dayjs(additionalValuesObj.signDate).format('YYYY-MM-DD');
    additionalValuesObj.esign = 'Y';
    additionalValuesObj.readMessage = 'Y';

    const applicationDto: ApplicationDto = {
      ...basicInfo,
      ...household,
      ...assets,
      ...props.billsInfo,
      ...additionalValuesObj
    };

    const createApplicationDto: CreateApplicationDto = {
      guarantorMmi: props.consumerId,
      createUser: props.createUsername,
      createDate: dayjs().toISOString(),
      source: 'dfdWeb',
      application: applicationDto
    };

    const response = await props.submitFAForm(createApplicationDto);
    if (response.error) {
      await wait(90);
      setShowErrorDialog(true);
    } else {
      await wait(90);
      history.push('/u/fa-app/submit', {
        applicationId: response.payload.data.applicationId
      });
      props.setFAFormClear();
    }
  };

  const navigateToEdit = (redirectTo: string) => {
    history.push(`/u/fa-app/${redirectTo}`, { isEdit: true });
  };

  const items = [
    <BasicInfo navigateToEdit={() => navigateToEdit('basic-info')} />,
    <Household navigateToEdit={() => navigateToEdit('household')} />,
    <Assets
      navigateToEdit={() => {
        const navigateToScreen =
          Config.FA_SHOW_ASSETS_AND_ASSISTANCE === 'enabled' ? 'assets' : 'other-funding';
        navigateToEdit(navigateToScreen);
      }}
    />,
    <BillsInfo navigateToEdit={() => navigateToEdit('bills')} />
  ];

  return (
    <>
      <Container maxWidth="md">
        <Formik
          initialValues={
            !isEmpty(props.faCompleteFieldData)
              ? (props.faCompleteFieldData as FormikValues)
              : initialValues
          }
          validationSchema={submitInformationValidationSchema}
          onSubmit={values => sendToEndpoint(values)}
          validateOnMount
        >
          {({ values, handleChange, handleSubmit, handleBlur, isValid, touched, errors }) => {
            return (
              <>
                <ConfirmDialog
                  isOpen={showErrorDialog}
                  title="Failure to Submit"
                  description="Sorry for the inconvenience."
                  onCancel={() => setShowErrorDialog(false)}
                  onConfirm={() => {
                    setShowErrorDialog(false);
                    sendToEndpoint(values);
                  }}
                  confirmLabel="Try Again"
                  cancelLabel="Cancel"
                />
                <Box my={4}>
                  <Box mb={1}>
                    <Typography variant="h5">Review Application and Submit</Typography>
                  </Box>
                  <Typography variant="body1">
                    Please review your answers, agree to terms of service and sign below to complete
                    the application
                  </Typography>
                </Box>
                {FA_FORM_SECTIONS.map(item => (
                  <ExpansionPanel>
                    <ExpansionPanelSummary
                      data-testid={convertToLowerKabobCase(item.label, '-drawer')}
                      expandIcon={<ExpandMoreIcon />}
                    >
                      <Box
                        flex={1}
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Typography variant="h5">
                          {item.label === 'Bills' ? 'Non-Intermountain Bills' : item.label}
                        </Typography>
                        <Typography variant="caption">View Answers</Typography>
                      </Box>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>{items[item.key - 1]}</ExpansionPanelDetails>
                  </ExpansionPanel>
                ))}
                <ExpansionPanel expanded>
                  <ExpansionPanelSummary>
                    <Box flex={1} display="flex" justifyContent="space-between" alignItems="center">
                      <Typography variant="h5">E-mail</Typography>
                    </Box>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Box pb={2}>
                      <Typography>
                        Confirmation for submitting the financial assistance application will be
                        sent to the above address (please note: you will still have to submit income
                        verification documents)
                      </Typography>
                    </Box>
                    <Container maxWidth="sm">
                      <Box pb={2}>
                        <TextField
                          variant="outlined"
                          label="E-mail Address"
                          data-testid="email-address"
                          value={values.email}
                          onChange={handleChange('email')}
                          onBlur={handleBlur('email')}
                          error={touched.email && errors.email}
                          helperText={touched.email && errors.email ? errors.email : null}
                          fullWidth
                        />
                      </Box>
                      <Box>
                        <TextField
                          variant="outlined"
                          label="Verify E-mail Address"
                          data-testid="verify-email-address"
                          value={values.retypeEmail}
                          onChange={handleChange('retypeEmail')}
                          onBlur={handleBlur('retypeEmail')}
                          error={touched.retypeEmail && errors.retypeEmail}
                          helperText={
                            touched.retypeEmail && errors.retypeEmail ? errors.retypeEmail : null
                          }
                          fullWidth
                        />
                      </Box>
                    </Container>
                  </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel expanded>
                  <ExpansionPanelSummary>
                    <Box flex={1} display="flex" justifyContent="space-between" alignItems="center">
                      <Typography variant="h5">
                        Terms of Service
                        <StyledAsterisk />
                      </Typography>
                      <Typography variant="caption">Required</Typography>
                    </Box>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Typography>
                      I hereby state that the information given herein is true and correct. I
                      authorize any required verification, including a credit bureau report. I
                      understand that if this information is determined false or deceptive, I will
                      be liable for payment of charges for all services rendered. I understand this
                      request for financial assistance may not pertain to other healthcare providers
                    </Typography>
                    <Box pt={2}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            data-testid="terms-of-service-check-box"
                            checked={values.readMessage}
                            value={values.readMessage}
                            onChange={handleChange('readMessage')}
                          />
                        }
                        label="I read the message above."
                      />
                    </Box>
                  </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel expanded>
                  <ExpansionPanelSummary>
                    <Box flex={1} display="flex" justifyContent="space-between" alignItems="center">
                      <Typography variant="h5">
                        Signature
                        <StyledAsterisk />
                      </Typography>
                      <Typography variant="caption">Required</Typography>
                    </Box>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Container maxWidth="sm">
                      <Box pb={3}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              data-testid="signature-check-box"
                              checked={values.esign}
                              value={values.esign}
                              onChange={handleChange('esign')}
                            />
                          }
                          label="I intend the electronic signature above to be my actual signature on the financial assistance application."
                        />
                      </Box>
                      <Box pb={2}>
                        <TextField
                          variant="outlined"
                          label="Guarantor Signature"
                          data-testid="guarantor-signature"
                          required
                          value={values.guarantorName}
                          onChange={handleChange('guarantorName')}
                          onBlur={handleBlur('guarantorName')}
                          error={touched.guarantorName && errors.guarantorName}
                          helperText={
                            touched.guarantorName && errors.guarantorName
                              ? errors.guarantorName
                              : null
                          }
                          fullWidth
                        />
                      </Box>
                      <Box>
                        <TextField
                          variant="outlined"
                          label="Sign Date"
                          disabled
                          value={initialValues.signDate}
                          fullWidth
                        />
                      </Box>
                    </Container>
                  </ExpansionPanelDetails>
                </ExpansionPanel>

                <Box py={4}>
                  <Container maxWidth="xs">
                    <Box mb={1} position="relative">
                      <MuiButton
                        size="large"
                        variant="contained"
                        color="primary"
                        disabled={!isValid || props.isSending}
                        data-testid="submitButton"
                        fullWidth
                        onClick={() => {
                          analyticsService.logEvent(AnalyticsEvent.FASubmitBtnClicked, {
                            currentUrl,
                            referringUrl
                          });
                          handleSubmit();
                        }}
                      >
                        <span style={{ opacity: props.isSending ? 0 : 1 }}>Submit</span>
                      </MuiButton>
                    </Box>
                    <MuiButton
                      size="large"
                      color="primary"
                      fullWidth
                      disabled={props.isSending}
                      onClick={() => navigateToPrev(values)}
                    >
                      Previous
                    </MuiButton>
                  </Container>
                </Box>
              </>
            );
          }}
        </Formik>
      </Container>
      <DisableWhenWaiting />
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  basicInfoData: faBasicInfoSelector(state),
  householdData: faHouseholdSelector(state),
  assetsData: faAssetsSelector(state),
  billsInfo: faBillsSelector(state),
  consumerId: consumerIdSelector(state),
  createUsername: profileConsumerDisplayNameSelector(state),
  isSending: faSubmitSendingSelector(state),
  faCompleteFieldData: faCompleteSelector(state),
  currentUrl: currentLocationPathNameSelector(state),
  referringUrl: previousLocationPathNameSelector(state)
});

const mapDispatch = {
  setFAFormClear,
  submitFAForm,
  setFAForm5Complete
};

export default connect(mapStateToProps, mapDispatch)(FAComplete as ComponentType);
