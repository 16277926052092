import React, { useState, useEffect, useMemo } from 'react';
import { oc } from 'ts-optchain';
import { connect } from 'react-redux';
import {
  applyInboxSearch,
  applyOutboxSearch,
  clearInboxSearch,
  clearOutboxSearch,
  loadAllMessages
} from 'store/messaging/actions';
import {
  inboxErrorSelector,
  inboxFetchingSelector,
  outboxErrorSelector,
  outboxFetchingSelector,
  sortedInboxDataSelector,
  sortedOutboxDataSelector,
  messageComposeToSelector
} from 'store/messaging/selectors';
import { RootState } from 'store/types';
import { MessagePool } from 'store/findProvider/types';
import { RequestState } from 'modules/types/common';
import { getMyDoctors } from 'store/myDoctors/actions';
import {
  MessageComposeScreenNavigationParams,
  MessageFolderType,
  MessageSummary
} from 'store/messaging/types';
import {
  currentLocationPathNameSelector,
  previousLocationPathNameSelector
} from 'store/router/selectors';
import { myDoctorsRequestStateSelector } from 'store/myDoctors/selectors';
import analyticsService, { AnalyticsEvent } from 'services/AnalyticsService';
import { Color } from 'modules/styles/colors';
import { Spacing } from 'modules/styles/variables';
import { useProxySwitcherEffect } from 'hooks/useProxySwitcherEffect';
import {
  MuiBox,
  MuiButton,
  MuiContainer,
  MuiTab,
  MuiTabs,
  MuiTypography,
  MuiAlertTitle,
  makeStyles
} from 'theme/material-ui';
import MessageList from 'components/Messaging/MessageList';
import Banner from 'components/UI/Banner/Banner';
import Svg from 'components/UI/Svg/Svg';
import { NavigationScreenProps } from 'screens/navigation';
import MessageInboxSearch from './MessageInboxSearch';
import MessageOutboxSearch from './MessageOutboxSearch';
import MessageComposeWidget from 'components/Messaging/MessageComposeWidget';
import { NewMessageButton, AlertSuccessMessage } from './styled';
import { v4 as uuidv4 } from 'uuid';

interface LocationState {
  showSent?: boolean;
}
interface LocationWithState extends Location {
  state: LocationState;
}

interface MessageHomeScreenProps extends NavigationScreenProps {
  to: MessagePool | null;
  inboxData: MessageSummary[];
  inboxIsFetching: boolean;
  inboxError: Error | null;
  outboxData: MessageSummary[];
  outboxIsFetching: boolean;
  outboxError: Error | null;
  currentRouteName?: string;
  previousRouteName?: string;
  applyInboxSearch: (e: string) => void;
  applyOutboxSearch: (e: string) => void;
  loadMessages: () => void;
  clearInboxSearch: () => void;
  clearOutboxSearch: () => void;
  location: LocationWithState;
  myDoctorsRequestState: RequestState;
  dispatch: Function;
}

export interface MessageComposeProps extends MessageComposeScreenNavigationParams {
  isSending: boolean;
  open?: boolean;
  handleClose?: () => void;
  onSend?: string;
}

enum MessagingTab {
  INBOX = 'Inbox',
  SENT = 'Sent',
  COMPOSE = 'Compose'
}

const useStyle = makeStyles({
  indicator: {
    top: '0'
  },
  tabsRoot: {
    minHeight: Spacing.xLarge,
    marginTop: Spacing.medium
  },
  tabRoot: {
    border: `1px solid ${Color.grayLight2}`,
    minHeight: Spacing.xLarge
  },
  selected: {
    backgroundColor: Color.white
  }
});

const MessageHomeScreen = (props: MessageHomeScreenProps) => {
  const { location, myDoctorsRequestState, dispatch } = props;
  const { showSent = false } = oc(location).state({} as LocationState);
  const [selectedTab, setSelectedTab] = useState(showSent ? MessagingTab.SENT : MessagingTab.INBOX);
  const [displayCount, setDisplayCount] = useState(10);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [messageSentProvider, setMessageSentProvider] = useState('');
  const [messageUUID, setMessageUUID] = useState('');
  const params = oc(location).state({}) as MessageComposeProps;
  const classes = useStyle();

  useEffect(() => {
    if (params.subject || props.to) {
      setMessageUUID(uuidv4());
      setShowMessageModal(true);
    }
  }, []);

  const inboxData = useMemo(() => {
    return props.inboxData.slice(0, displayCount);
  }, [props.inboxData, displayCount]);

  const outboxData = useMemo(() => {
    return props.outboxData.slice(0, displayCount);
  }, [props.outboxData, displayCount]);

  const seeMoreMessages = () => {
    setDisplayCount(prevState => prevState + 10);
    props.loadMessages();
  };

  const goToMessageDetails = (message: MessageSummary, messageFolder: MessageFolderType) => {
    if (messageFolder === MessageFolderType.Inbox) {
      const data = {
        currentUrl: props.currentRouteName,
        referringUrl: props.previousRouteName,
        message_pool_type: message.messagePool?.poolTypeName || '',
        message_pool: message.senderDisplayName,
        message_provider_id: message.senderCernerPersonnelId,
        messageUUID: message.id
      };
    }
  };

  const clearSearchValues = () => {
    props.clearInboxSearch();
    props.clearOutboxSearch();
    setDisplayCount(10);
  };

  useProxySwitcherEffect(() => {
    props.loadMessages();
  }, []);

  useEffect(() => {
    if (
      myDoctorsRequestState === RequestState.ERROR ||
      myDoctorsRequestState === RequestState.INITIALIZED
    ) {
      dispatch(getMyDoctors());
    }

    return () => {
      clearSearchValues();
    };
  }, []);

  const handleTabChanged = (event: React.ChangeEvent<{}>, value: MessagingTab) => {
    clearSearchValues();
    setSelectedTab(value);
  };

  const handleToggleMessageModal = (openModal: boolean) => {
    analyticsService.logEvent(AnalyticsEvent.NewMessageClicked);
    setMessageUUID(uuidv4());
    setShowMessageModal(openModal);
  };

  const handleShowSuccessAlert = (providerName: string) => {
    setMessageSentProvider(providerName);
  };

  const SearchBar = selectedTab === MessagingTab.INBOX ? MessageInboxSearch : MessageOutboxSearch;
  const tabClasses = {
    root: classes.tabRoot,
    selected: classes.selected
  };

  return (
    <>
      {!!messageSentProvider ? (
        <AlertSuccessMessage
          onClose={() => setMessageSentProvider('')}
          data-testid="success-message"
        >
          <MuiAlertTitle>Success!</MuiAlertTitle>
          Your message has been sent to {messageSentProvider}.
        </AlertSuccessMessage>
      ) : null}
      <Banner message="Messages" />
      <MuiBox p={3} flex={1}>
        <MuiContainer maxWidth="md">
          <SearchBar />
          {showMessageModal ? (
            <MessageComposeWidget
              messageUUID={messageUUID}
              paramsMessage={params}
              handleMessageModal={handleToggleMessageModal}
              showSuccessAlert={handleShowSuccessAlert}
            />
          ) : (
            <NewMessageButton>
              <MuiButton
                data-testid="new-message-button"
                variant="contained"
                color="primary"
                aria-label="Compose message"
                onClick={() => handleToggleMessageModal(true)}
              >
                <MuiTypography>New Message</MuiTypography>
              </MuiButton>
            </NewMessageButton>
          )}
          <MuiTabs
            value={selectedTab}
            onChange={handleTabChanged}
            indicatorColor="primary"
            textColor="primary"
            classes={{
              root: classes.tabsRoot,
              indicator: classes.indicator
            }}
          >
            <MuiTab
              label={MessagingTab.INBOX}
              data-testid="inbox-tab"
              value={MessagingTab.INBOX}
              classes={tabClasses}
            />
            <MuiTab
              label={MessagingTab.SENT}
              data-testid="sent-tab"
              value={MessagingTab.SENT}
              classes={tabClasses}
            />
          </MuiTabs>
          <MuiBox>
            {selectedTab === MessagingTab.INBOX ? (
              <MessageList
                onPress={message => goToMessageDetails(message, MessageFolderType.Inbox)}
                onRefresh={props.loadMessages}
                messages={inboxData}
                loading={props.inboxIsFetching}
                error={props.inboxError}
                folder={MessageFolderType.Inbox}
                showStatus
              />
            ) : null}

            {selectedTab === MessagingTab.SENT ? (
              <MessageList
                onPress={message => goToMessageDetails(message, MessageFolderType.Outbox)}
                onRefresh={props.loadMessages}
                messages={outboxData}
                loading={props.outboxIsFetching}
                error={props.outboxError}
                folder={MessageFolderType.Outbox}
                showStatus={false}
              />
            ) : null}
          </MuiBox>
        </MuiContainer>
        {(!props.inboxIsFetching || !props.outboxIsFetching) &&
        ((selectedTab === MessagingTab.INBOX && displayCount < props.inboxData.length) ||
          (selectedTab === MessagingTab.SENT && displayCount < props.outboxData.length)) ? (
          <MuiBox display="flex" justifyContent="center" pt={4}>
            <MuiButton
              disabled={props.inboxIsFetching || props.outboxIsFetching}
              onClick={seeMoreMessages}
            >
              Show more
              <Svg set="material" name="keyboard-arrow-down" size="24" />
            </MuiButton>
          </MuiBox>
        ) : null}
      </MuiBox>
    </>
  );
};
const mapStateToProps = (state: RootState) => ({
  inboxData: sortedInboxDataSelector(state),
  inboxIsFetching: inboxFetchingSelector(state),
  inboxError: inboxErrorSelector(state),
  outboxData: sortedOutboxDataSelector(state),
  outboxIsFetching: outboxFetchingSelector(state),
  outboxError: outboxErrorSelector(state),
  myDoctorsRequestState: myDoctorsRequestStateSelector(state),
  to: messageComposeToSelector(state),
  currentRouteName: currentLocationPathNameSelector(state),
  previousRouteName: previousLocationPathNameSelector(state)
});

const mapDispatchToProps = {
  applyInboxSearch,
  applyOutboxSearch,
  loadMessages: loadAllMessages,
  clearInboxSearch,
  clearOutboxSearch
};

export default connect(mapStateToProps, mapDispatchToProps)(MessageHomeScreen);
