import React from 'react';

import { MuiBox, MuiContainer, MuiGrid } from 'theme/material-ui';

import { Svg } from 'components/UI/Svg';

import { Color } from 'modules/styles/colors';
import { BorderRadius, FontSize, IconSize } from 'modules/styles/variables';

import { OptionType } from 'store/providerSearch/types';

import { ActionHeaderButton } from 'screens/Booking/components/styled';
import { CountCircle } from 'screens/Booking/components/sharedComponents';
import { Typography } from 'components/UI/Typography/styled';

export interface Props {
  currentSortBy: OptionType | undefined;
  showSortOptions: () => void;
  filtersCount: number;
  showFilters: () => void;
  displayFilters: boolean;
}

export const SpecialtyProviderActionsHeader = ({
  currentSortBy,
  showSortOptions,
  filtersCount,
  showFilters,
  displayFilters
}: Props) => {
  return (
    <MuiBox width="100%" bgcolor={Color.cobaltTint} px={2} py={1}>
      <MuiContainer maxWidth="lg">
        <MuiGrid container spacing={1} justify="flex-end" alignItems="center">
          <MuiGrid item>
            <ActionHeaderButton
              data-testid="sort-drawer-button"
              borderRadius={BorderRadius.base}
              hasCount={!!currentSortBy}
              onClick={showSortOptions}
              startIcon={
                currentSortBy ? <CountCircle testID="sort-count-icon">1</CountCircle> : null
              }
              endIcon={<Svg color={Color.secondary} name="ChevronDown" size={IconSize.xmedium} />}
            >
              <Typography fontSize={FontSize.large} color={Color.secondary}>
                Sort
              </Typography>
            </ActionHeaderButton>
          </MuiGrid>
          {displayFilters ? (
            <MuiGrid item>
              <ActionHeaderButton
                data-testid="filter-drawer-button"
                borderRadius={BorderRadius.base}
                hasCount={!!filtersCount}
                onClick={showFilters}
                startIcon={
                  filtersCount ? (
                    <CountCircle testID="filters-count-icon">{filtersCount}</CountCircle>
                  ) : null
                }
                endIcon={<Svg color={Color.secondary} name="ChevronDown" size={IconSize.xmedium} />}
              >
                <Typography fontSize={FontSize.large} color={Color.secondary}>
                  Filter
                </Typography>
              </ActionHeaderButton>
            </MuiGrid>
          ) : null}
        </MuiGrid>
      </MuiContainer>
    </MuiBox>
  );
};

export default SpecialtyProviderActionsHeader;
