import Typography from 'components/UI/Typography';
import { Color } from 'modules/styles/colors';
import { FontSize } from 'modules/styles/variables';
import styled from 'styled-components';
import { MuiBox, MuiTypography } from 'theme/material-ui';
import Box from 'components/UI/Layout/Box';

export const ProxyName = styled(Typography)`
  font-size: ${FontSize.large}px;
  font-weight: 600;
`;

export const Legal = styled(Typography)`
  font-size: 12px;
  margin-bottom: 10px;
  line-height: 15px;
`;

export const Error = styled(Typography)`
  text-align: center;
`;

export const Disclaimer = styled(Typography)`
  font-size: 12px;
  line-height: 16px;
  font-style: italic;
  text-align: center;
`;

export const LegalScrollWrapper = styled.div`
  display: flex;
  position: relative;
  flex: 1;
  border-width: 0.5;
  border-color: ${Color.gray};
  border-radius: 2px;
`;

export const LegalScrollView = styled.div`
  padding: 18px;
  flex: 1;
`;

export const StyledHeader = styled.span`
  height: 29px;
  color: ${Color.black};
  font-size: 24px;
  padding-bottom: 50px;
  letter-spacing: 0.57px;
  line-height: 29px;
`;
export const StyledDisclaimer = styled(MuiTypography)`
  &.MuiTypography-body1 {
    height: 24px;
    width: 285px;
    color: ${Color.black};
    font-size: 16.35px;
    font-weight: 600;
    letter-spacing: 0.15px;
    line-height: 24px;
  }
`;
export const StyledLegal = styled.div`
  box-sizing: border-box;
  height: 275px;
  padding: 20px;
  border: 1px solid #dcdcdc;
`;
export const StyledDisclaimerText = styled.div`
  height: 17px;
  color: rgba(0, 0, 0, 0.87);
  font-size: 12px;
  font-style: italic;
  letter-spacing: 0.25px;
  line-height: 15px;
  text-align: center;
`;
export const StyledBodyText = styled.div`
  & p.MsoNormal {
    color: ${Color.black};
    font-size: 14px;
    letter-spacing: 0.33px;
    line-height: 20px;
  }
`;

export const BoxWrapper = styled(Box)`
  border-radius: 0 0 3px 3px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12),
    0 1px 3px 0 rgba(0, 0, 0, 0.2);
  && .MuiListItemText-primary {
    color: ${Color.black};
    font-size: 14.32px;
    letter-spacing: 0.24px;
    line-height: ${FontSize.heading}px;
  }
  && .MuiListItemText-secondary {
    font-size: 13px;
    color: ${Color.black};
    margin-top: 2px;
    line-height: ${FontSize.large}px;
    letter-spacing: 0.21px;
  }
  && .MuiListItemSecondaryAction-root {
    right: 0px;
  }
`;
export const NotifyBoxWrapper = styled(MuiBox)`
  && .MuiListItemSecondaryAction-root {
    right: 0px;
  }
  && .MuiListItemText-primary {
    color: ${Color.black};
    font-size: 16.35px;
    letter-spacing: 0.24px;
    line-height: ${FontSize.mediumHeading}px;
    font-weight: 600;
  }
  && .MuiListItemText-secondary {
    font-size: 13px;
    color: ${Color.black};
    margin-top: 2px;
    line-height: ${FontSize.large}px;
    letter-spacing: 0.21px;
  }
`;
