import styled from 'styled-components';
import get from 'lodash/get';
import { OverflowYProperty } from 'csstype';
// Units used for margins/spacings/paddings
type Unit = string | number;

export interface BoxProps {
  accessibilityLabel?: string;
  vSpacing?: Unit;
  vOffset?: Unit;
  hSpacing?: Unit;
  hOffset?: Unit;
  vSpacingTop?: Unit;
  vSpacingBottom?: Unit;
  vOffsetTop?: Unit;
  vOffsetBottom?: Unit;
  hSpacingLeft?: Unit;
  hSpacingRight?: Unit;
  hOffsetLeft?: Unit;
  hOffsetRight?: Unit;
  spacing?: Unit;
  padding?: Unit;
  offset?: Unit;
  backgroundColor?: string;
  bottom?: number;
  top?: number;
  left?: number;
  right?: number;
  zIndex?: number;
  borderStyle?: string;
  borderColor?: string;
  borderWidth?: number;
  alignSelf?: string;
  borderRadius?: number | string;
  border?: {
    width?:
      | number
      | {
          top?: number;
          bottom?: number;
          left?: number;
          right?: number;
        };
    style?: string;
    color?: string;
    radius?:
      | number
      | {
          topLeft?: number;
          topRight?: number;
          bottomLeft?: number;
          bottomRight?: number;
        };
  };
  minHeight?: Unit;
  maxHeight?: Unit;
  height?: Unit;
  overflowY?: OverflowYProperty;
  minWidth?: Unit;
  maxWidth?: Unit;
  width?: Unit;
  flex?: Unit;
  boxShadow?: string;
  testID?: string;
  flexShrink?: Unit;
}

const Box = styled.div<BoxProps>(props => ({
  padding: props.spacing || props.padding,
  margin: props.offset,
  paddingTop: props.vSpacingTop || props.vSpacing,
  paddingBottom: props.vSpacingBottom || props.vSpacing,
  marginTop: props.vOffsetTop || props.vOffset,
  marginBottom: props.vOffsetBottom || props.vOffset,

  paddingLeft: props.hSpacingLeft || props.hSpacing,
  paddingRight: props.hSpacingRight || props.hSpacing,
  marginLeft: props.hOffsetLeft || props.hOffset,
  marginRight: props.hOffsetRight || props.hOffset,

  borderColor: props.borderColor || get(props, 'border.color'),
  borderStyle: props.borderStyle || get(props, 'border.style'),
  borderWidth: props.borderWidth || get(props, 'border.borderWidth') || get(props, 'border.width'),
  borderTopWidth: get(props, 'border.width.top'),
  borderBottomWidth: get(props, 'border.width.bottom'),
  borderRightWidth: get(props, 'border.width.right'),
  borderLeftWidth: get(props, 'border.width.left'),

  borderRadius: props.borderRadius || get(props, 'border.radius'),
  borderBottomRightRadius: get(props, 'border.radius.bottomRight'),
  borderBottomLeftRadius: get(props, 'border.radius.bottomLeft'),
  borderTopRightRadius: get(props, 'border.radius.topRight'),
  borderTopLeftRadius: get(props, 'border.radius.topLeft'),

  backgroundColor: get(props, 'backgroundColor'),

  flex: props.flex,
  alignSelf: props.alignSelf || 'auto',
  height: props.height,
  overflowY: props.overflowY || 'visible',
  width: props.width,
  maxWidth: props.maxWidth,
  flexShrink: props.flexShrink,

  bottom: props.bottom,
  top: props.top,
  left: props.left,
  right: props.right,

  zIndex: props.zIndex,
  boxShadow: props.boxShadow
}));

export default Box;
