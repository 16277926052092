import React from 'react';
import { Color } from 'modules/styles/colors';

export default (props: any = { color: Color.black }) => ({
  svg: (
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2.55714C11.9021 2.55714 11.8051 2.57658 11.7147 2.61433C11.6244 2.65209 11.5424 2.70741 11.4736 2.77708L11.4703 2.78034L1.28033 12.9703C0.987437 13.2632 0.512563 13.2632 0.21967 12.9703C-0.0732233 12.6774 -0.0732233 12.2026 0.21967 11.9097L10.4064 1.72291C10.407 1.72233 10.4076 1.72174 10.4082 1.72115C10.6162 1.51107 10.8637 1.34424 11.1365 1.23027C11.41 1.11599 11.7035 1.05714 12 1.05714C12.2965 1.05714 12.59 1.11599 12.8635 1.23027C13.1363 1.34424 13.3838 1.51107 13.5918 1.72115C13.5924 1.72174 13.593 1.72233 13.5936 1.72291L15.75 3.87934V2.77C15.75 2.35578 16.0858 2.02 16.5 2.02H20.25C20.6642 2.02 21 2.35578 21 2.77V9.12934L23.7803 11.9097C24.0732 12.2026 24.0732 12.6774 23.7803 12.9703C23.4874 13.2632 23.0126 13.2632 22.7197 12.9703L15.9703 6.22094C15.9699 6.22053 15.9695 6.22012 15.9691 6.21972L12.5264 2.77709C12.4576 2.70742 12.3756 2.65209 12.2853 2.61433C12.1949 2.57658 12.0979 2.55714 12 2.55714ZM17.25 5.37934L19.5 7.62934V3.52H17.25V5.37934ZM3.75 13.19C4.16421 13.19 4.5 13.5258 4.5 13.94V21.44H9V16.19C9 15.5933 9.23705 15.021 9.65901 14.599C10.081 14.1771 10.6533 13.94 11.25 13.94H12.75C13.3467 13.94 13.919 14.1771 14.341 14.599C14.7629 15.021 15 15.5933 15 16.19V21.44H19.5V13.94C19.5 13.5258 19.8358 13.19 20.25 13.19C20.6642 13.19 21 13.5258 21 13.94V22.19C21 22.6042 20.6642 22.94 20.25 22.94H14.25C13.8358 22.94 13.5 22.6042 13.5 22.19V16.19C13.5 15.9911 13.421 15.8003 13.2803 15.6597C13.1397 15.519 12.9489 15.44 12.75 15.44H11.25C11.0511 15.44 10.8603 15.519 10.7197 15.6597C10.579 15.8003 10.5 15.9911 10.5 16.19V22.19C10.5 22.6042 10.1642 22.94 9.75 22.94H3.75C3.33579 22.94 3 22.6042 3 22.19V13.94C3 13.5258 3.33579 13.19 3.75 13.19Z"
        fill={props.color}
      />
    </g>
  ),
  viewBox: '0 0 24 24'
});
