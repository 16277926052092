import React from 'react';

import { Color } from 'modules/styles/colors';
import {
  BadgeCell,
  TopContainer,
  PaddedIcon,
  MinuteText,
  BottomContainer,
  UnitText,
  ClosedText,
  ClosedTextContainer
} from './styled';

interface Props {
  time: string;
  text: string;
  isOpen?: boolean;
}

const ClosedBadge = () => (
  <BadgeCell>
    <ClosedTextContainer>
      <ClosedText>Closed</ClosedText>
    </ClosedTextContainer>
  </BadgeCell>
);

const ClockBadge = ({ time, text }: Props) => (
  <BadgeCell>
    <TopContainer>
      <PaddedIcon name="access-time" color={Color.inverted} size={20} />
      <MinuteText>{time}</MinuteText>
    </TopContainer>
    <BottomContainer>
      <UnitText>{`${text} wait`}</UnitText>
    </BottomContainer>
  </BadgeCell>
);

export default ({ isOpen = true, time, text }: Props) => {
  if (!isOpen) return <ClosedBadge />;
  if (time) return <ClockBadge time={time} text={text} />;
  return null;
};
