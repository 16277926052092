export interface MedicalHistoryError {
  title: string;
  subtitle?: string;
}

export type MedicalHistoryErrors = Readonly<Record<string, MedicalHistoryError>>;

export const vitalsError: MedicalHistoryErrors = {
  GET: {
    title: 'There was an issue fetching your vitals.'
  }
};

export const initialRequiredValues = {
  weightMajor: '',
  weightMinor: '0',
  heightMajor: '',
  heightMinor: ''
};

export const temperatureNonRequiredValue = {
  temperature: ''
};

export const initialNonRequiredValues = {
  systolic: '',
  diastolic: ''
};
