import React from 'react';

export default {
  svg: (
    <g>
      <g>
        <g>
          <circle fill="#FF5D55" strokeWidth={0} cx={40} cy={40} r={38.5} />
          <path
            fill="#FFFFFF"
            strokeWidth={0}
            d="M40,2.5c20.711,0,37.5,16.789,37.5,37.5S60.711,77.5,40,77.5S2.5,60.711,2.5,40S19.289,2.5,40,2.5 M40,0.5 C18.22,0.5,0.5,18.22,0.5,40S18.22,79.5,40,79.5S79.5,61.78,79.5,40S61.78,0.5,40,0.5L40,0.5z"
          />
        </g>
        <g>
          <defs>
            <circle id="SVGID_39_" cx={40} cy={40} r={37.5} />
          </defs>
          <clipPath id="SVGID_2_11">
            <use xlinkHref="#SVGID_39_" />
          </clipPath>
          <g clipPath="url(#SVGID_2_11)">
            <path
              fill="#D8D8D8"
              strokeWidth={0}
              d="M78.448,80.661c0.493,3.745-2.813,6.523-5.779,8.124c-4.712,2.543-10.386,2.233-15.727,1.856 c-15.187-1.072-30.477,1.023-45.632-0.425c-8.566-0.818-8.884-5.676-8.603-7.612c0.384-2.643,3.452-4.586,4.743-6.924 c1.728-3.128-0.125-7.676,0.523-11.191c0.656-3.558,3.776-4.983,4.683-8.486c0.778-3.003-0.793-6.187-0.476-9.273 c0.429-4.172,3.584-7.614,5.386-11.279c0.086-0.175,0.17-0.35,0.252-0.525c1.953-4.175,1.376-8.252,2.612-12.574 c2.69-9.405,7.532-15.128,12.741-12.54c7.481-7.959,18.243-0.526,20.171,0.864c2.045,1.474,3.316,2.459,5.102,5.708 c2.259,4.108,3.067,8.744,3.929,13.352c0.936,5.005,5.316,8.954,5.791,14.025c0.498,5.33-2.7,7.663,0.474,13.052 c1.231,2.09,3.315,4.102,3.936,6.447c0.689,2.602-0.343,5.391,0.271,8.011C73.744,75.098,77.952,76.892,78.448,80.661"
            />
            <polygon
              fill="#F080B1"
              strokeWidth={0}
              points="33.179,62.18 47.965,62.18 47.795,67.712 33.343,67.712  "
            />
            <path
              fill="#F2AE75"
              strokeWidth={0}
              d="M54.769,34.326v-6.445c0-6.845-7.924-15.493-14.197-15.493c-6.273,0-14.197,8.648-14.197,15.493v6.445 c-1.742,0-3.155,1.387-3.155,3.099v3.223c0,1.711,1.412,3.099,3.155,3.099c0,4.66,3.673,10.268,7.997,13.375V63.2l-3.099,1.103 l3.099,24.077h12.394l3.099-23.941l-3.099-1.271v-6.043c4.326-3.106,8.003-8.717,8.003-13.379c1.742,0,3.155-1.387,3.155-3.099 v-3.223C57.924,35.713,56.511,34.326,54.769,34.326z"
            />
            <path
              fill="#F080B1"
              strokeWidth={0}
              d="M69.329,99.5c-0.171-6.282-0.372-11.493-0.593-13.442l-0.03-0.262c-0.663-5.85-1.487-13.132-6.641-18.078 c-4.434-4.255-9.551-4.32-14.074-4.368c0-0.001-1.29,4.788-1.29,4.788l-6.13,13.886l-6.136-13.899l-0.833-4.777 c-4.522,0.049-10.096,0.114-14.531,4.369c-5.154,4.946-5.978,12.229-6.641,18.081l-0.029,0.259 c-0.221,1.948-0.421,7.159-0.593,13.442"
            />
            <polygon
              fill="#C85889"
              strokeWidth={0}
              points="48.273,69.706 55.223,67.138 47.965,62.18 40.888,81.177 40.572,81.437 40.255,81.176  33.179,62.18 25.921,67.138 32.871,69.706 30.143,73.425 39.916,81.484 39.916,99.5 41.227,99.5 41.227,81.485 51.001,73.425  "
            />
            <path
              fill="#E99260"
              strokeWidth={0}
              d="M54.769,34.326v-6.445c0-6.845-7.924-15.493-14.197-15.493v25.099c-2.302,4.807-3.155,9.916-3.155,9.916 l3.155,0.62v11.465c0,0,0,0,0,0c-2.002,0-4.172-0.909-6.2-2.366v2.339l12.394,3.74v-6.075c0,0,0,0,0,0 c4.326-3.106,8.003-8.717,8.003-13.379c1.742,0,3.155-1.387,3.155-3.099v-3.223C57.924,35.713,56.511,34.326,54.769,34.326z  M45.162,58.139c0.033-0.018,0.067-0.036,0.1-0.055C45.229,58.103,45.196,58.121,45.162,58.139z M41.263,59.448 c0.069-0.007,0.139-0.017,0.209-0.026C41.402,59.431,41.332,59.441,41.263,59.448z M41.982,59.341 c0.073-0.014,0.145-0.029,0.218-0.045C42.127,59.312,42.055,59.326,41.982,59.341z M42.774,59.149 c0.045-0.013,0.09-0.024,0.135-0.038C42.864,59.125,42.819,59.136,42.774,59.149z M43.653,58.853 c0.011-0.004,0.023-0.01,0.034-0.014C43.676,58.843,43.664,58.849,43.653,58.853z M44.389,58.535 c0.045-0.021,0.089-0.043,0.134-0.065C44.478,58.492,44.433,58.514,44.389,58.535z"
            />
            <g>
              <g>
                <path
                  fill="#EC9B68"
                  strokeWidth={0}
                  d="M35.321,38.432c0.047,1.351-1.01,2.484-2.361,2.531c-1.351,0.047-2.484-1.01-2.531-2.361 c-0.047-1.351,1.01-2.484,2.361-2.531C34.14,36.024,35.273,37.081,35.321,38.432z"
                />
                <path
                  fill="#3C2415"
                  strokeWidth={0}
                  d="M35.321,37.812c0.047,1.351-1.01,2.484-2.361,2.531c-1.351,0.047-2.484-1.01-2.531-2.361 c-0.047-1.351,1.01-2.484,2.361-2.531C34.14,35.404,35.273,36.461,35.321,37.812z"
                />
                <path
                  fill="#FFFFFF"
                  strokeWidth={0}
                  d="M34.317,38.352c0.012,0.338-0.252,0.621-0.59,0.633c-0.338,0.012-0.621-0.252-0.633-0.59 c-0.012-0.338,0.252-0.621,0.59-0.633C34.022,37.75,34.305,38.014,34.317,38.352z"
                />
                <path
                  fill="#3C2415"
                  strokeWidth={0}
                  d="M35.851,35.942c-0.451-1.049-1.608-1.844-2.879-1.979c-0.779-0.085-2.24,0.03-3.496,1.62 c-0.06,0.005-0.115,0.006-0.186-0.001c-0.14-0.012-0.334-0.058-0.523-0.139c-0.386-0.155-0.706-0.48-0.706-0.48 c-0.022-0.021-0.058-0.029-0.088-0.015c-0.038,0.019-0.054,0.064-0.035,0.102c0,0,0.048,0.106,0.141,0.259 c0.091,0.154,0.234,0.353,0.416,0.527c0.18,0.175,0.396,0.324,0.577,0.414c0.09,0.046,0.172,0.078,0.232,0.099 c0.058,0.021,0.119,0.032,0.109,0.031c0.169,0.017,0.323-0.073,0.397-0.215c0.822-1.149,1.922-1.714,3.097-1.587 c1.038,0.111,2.014,0.772,2.375,1.608c0.069,0.158,0.251,0.23,0.407,0.162C35.846,36.282,35.919,36.1,35.851,35.942z"
                />
              </g>
              <g>
                <path
                  fill="#DB8258"
                  strokeWidth={0}
                  d="M50.813,38.432c0.047,1.351-1.01,2.484-2.361,2.531c-1.351,0.047-2.484-1.01-2.531-2.361 c-0.047-1.351,1.01-2.484,2.361-2.531C49.633,36.024,50.766,37.081,50.813,38.432z"
                />
                <path
                  fill="#3C2415"
                  strokeWidth={0}
                  d="M50.813,37.812c0.047,1.351-1.01,2.484-2.361,2.531c-1.351,0.047-2.484-1.01-2.531-2.361 c-0.047-1.351,1.01-2.484,2.361-2.531C49.633,35.404,50.766,36.461,50.813,37.812z"
                />
                <path
                  fill="#FFFFFF"
                  strokeWidth={0}
                  d="M49.81,38.352c0.012,0.338-0.252,0.621-0.59,0.633c-0.338,0.012-0.621-0.252-0.633-0.59 c-0.012-0.338,0.252-0.621,0.59-0.633C49.515,37.75,49.798,38.014,49.81,38.352z"
                />
                <path
                  fill="#3C2415"
                  strokeWidth={0}
                  d="M45.391,35.942c0.451-1.049,1.608-1.844,2.879-1.979c0.779-0.085,2.24,0.03,3.496,1.62 c0.06,0.005,0.115,0.006,0.186-0.001c0.14-0.012,0.334-0.058,0.523-0.139c0.386-0.155,0.706-0.48,0.706-0.48 c0.023-0.021,0.058-0.029,0.088-0.015c0.038,0.019,0.054,0.064,0.035,0.102c0,0-0.048,0.106-0.141,0.259 c-0.091,0.154-0.234,0.353-0.416,0.527c-0.18,0.175-0.396,0.324-0.577,0.414c-0.09,0.046-0.172,0.078-0.232,0.099 c-0.058,0.021-0.119,0.032-0.109,0.031c-0.169,0.017-0.323-0.073-0.397-0.215c-0.822-1.149-1.922-1.714-3.097-1.587 c-1.038,0.111-2.014,0.772-2.375,1.608c-0.069,0.158-0.251,0.23-0.407,0.162C45.396,36.282,45.323,36.1,45.391,35.942z"
                />
              </g>
            </g>
            <g>
              <path
                fill="#F05D44"
                strokeWidth={0}
                d="M45.529,50.311c0,2.434-2.22,4.407-4.958,4.407c-2.738,0-4.958-1.973-4.958-4.407H45.529z"
              />
              <path
                fill="#FFFFFF"
                strokeWidth={0}
                d="M44.029,51.963h-7.023c-0.342,0-0.62-0.277-0.62-0.62v-1.033h8.263v1.033 C44.649,51.686,44.371,51.963,44.029,51.963z"
              />
            </g>
            <g>
              <circle fill="#F080B1" strokeWidth={0} cx={56.374} cy={43.575} r={1.549} />
              <circle fill="#F080B1" strokeWidth={0} cx={24.769} cy={43.575} r={1.549} />
            </g>
            <path
              fill="#E5E5E5"
              strokeWidth={0}
              d="M60.44,37.135c-3.056-3.544-2.217-6.948-2.561-8.082c-1.007-3.321-0.778-6.478-2.129-9.674 c-4.175-9.877-24.614-13.691-30.616,4.749c-0.943,2.899,0.335,8.027,1.577,10.81c0.838-0.297,5.558-7.501,7.691-12.509 C39.649,31.475,54.399,36.077,60.44,37.135z"
            />
            <path
              fill="#3C2415"
              strokeWidth={0}
              d="M56.85,33.328h-2.181c-1.247-0.845-3.523-0.848-5.584-0.84c-3.471,0-5.794,0.681-6.916,2.019 c-0.387-0.221-0.955-0.285-1.597-0.285c-0.642,0-1.21,0.065-1.597,0.285c-1.122-1.338-3.446-2.019-6.916-2.019 c-2.061-0.009-4.337-0.005-5.584,0.84h-2.181v2.355l0.943,0.524c0.035,2.299,2.098,6.693,6.821,6.693 c3.358,0,6.6-0.655,7.584-5.403c0.066-0.32,0.092-0.629,0.087-0.928c0.063-0.245,0.382-0.489,0.842-0.489 c0.46,0,0.779,0.243,0.842,0.489c-0.005,0.299,0.021,0.608,0.087,0.928c0.984,4.748,4.227,5.403,7.584,5.403 c4.723,0,6.786-4.394,6.821-6.693l0.943-0.524V33.328z M38.55,37.272c-1.012,3.249-2.389,4.513-6.492,4.513 c-4.304,0-5.709-4.194-5.709-5.639c0-1.754,3.928-1.921,5.709-1.921C35.983,34.225,38.989,35.864,38.55,37.272z M49.085,41.785 c-4.103,0-5.48-1.264-6.492-4.513c-0.438-1.408,2.567-3.047,6.492-3.047c1.781,0,5.709,0.167,5.709,1.921 C54.794,37.591,53.389,41.785,49.085,41.785z"
            />
          </g>
        </g>
      </g>
    </g>
  ),
  viewBox: '0 0 80 80'
};
