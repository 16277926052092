import analyticsService, { AmplitudeEventData, AnalyticsEvent } from 'services/AnalyticsService';
import useCoreEventData from './useCoreEventData';

/**
 * Will require a mock of useSelector in tests
 *
 * jest.mock('react-redux', () => ({
 *   useSelector: jest.fn()
 * }));
 *
 * Be sure to add any new event names to the AnalyticsEvent enum
 * as well as any new data params in the AmplitudeEventData interface
 * before use
 */

export interface NavigationAnalyticsCallback {
  (ampData?: AmplitudeEventData): void;
  currentUrl?: string;
  referringUrl?: string;
}

const useNavigationAnalytics = (event: AnalyticsEvent, data?: AmplitudeEventData) => {
  const { currentUrl, referringUrl } = useCoreEventData();
  // Pass data to 'ampData' if anyaplitude event data is dynamic and not predefined.
  // Otherwise, pass all amplitude event data to the 'data' prop above.
  const callback: NavigationAnalyticsCallback = (ampData?: AmplitudeEventData) => {
    const eventData = data || ampData || {};
    analyticsService.logEvent(event, {
      ...eventData,
      currentUrl: ampData?.currentUrl || currentUrl, // Pass currentUrl and referringUrl as ampData if the screen does not
      referringUrl: ampData?.referringUrl || referringUrl // Have the access of currentUrl and referringUrl from useCoreEventData()
    } as AmplitudeEventData);
  };

  callback.currentUrl = currentUrl;
  callback.referringUrl = referringUrl;

  return callback;
};

export default useNavigationAnalytics;
