import React, { useState } from 'react';
import { toSentenceCase } from 'modules/utils/StringUtils';
import { titleForHealthAdvisoryType } from 'lib/dashboard/utils';
import { AdvisoryType } from 'lib/dashboard/types';
import { mainButtonAction, HealthAdvisoryLinkType } from './enums';
import {
  DashboardBannerCard,
  DashboardCards
} from 'components/DashboardCarousel/DashboardBannerCard';
import { MammogramCallModal } from './MammogramCallModal';

interface Props {
  advisoryName: string;
  advisoryType: AdvisoryType;
  totalCards: number;
  onViewAllOptions: () => void;
  healthAdvisoryAction: {
    label: string;
    onPress: () => void;
    onAnalyticsClick: () => void;
  };
}

const DashboardCarouselHealthAdvisoryFront = (props: Props) => {
  const { advisoryType, onViewAllOptions, healthAdvisoryAction, totalCards } = props;
  const { label, onPress, onAnalyticsClick } = healthAdvisoryAction;

  const handleCall = () => {
    onAnalyticsClick();
    setShowModal(true);
  };

  const buttonForAdvisory = (): { text: string; onClick: () => void } | null => {
    const actionType = mainButtonAction(advisoryType);
    switch (actionType) {
      case HealthAdvisoryLinkType.EXTERNAL_FLU:
        return {
          text: toSentenceCase(label) as string,
          onClick: () => {
            onPress();
            onAnalyticsClick();
          }
        };
      case HealthAdvisoryLinkType.BOOKING:
        return {
          text: toSentenceCase(label) as string,
          onClick: () => {
            onAnalyticsClick();
            onPress();
          }
        };
      case HealthAdvisoryLinkType.CALL:
        return {
          text: toSentenceCase(label) as string,
          onClick: handleCall
        };
      default:
        return null;
    }
  };

  const getCardSpecs = (advisoryType: AdvisoryType) => {
    switch (advisoryType) {
      case AdvisoryType.CERVICAL_CANCER:
        return DashboardCards.cervicalCancer;
      case AdvisoryType.DIABETES_A1C:
        return DashboardCards.diabetes;
      case AdvisoryType.FLU:
        return DashboardCards.fluShot;
      case AdvisoryType.HPV_VACCINE:
        return DashboardCards.hpv;
      case AdvisoryType.MAMMOGRAM:
        return DashboardCards.mammogram;
      default:
        return null;
    }
  };

  const cardSpecProps = getCardSpecs(advisoryType);
  const [showModal, setShowModal] = useState(false);
  const hideModal = () => {
    setShowModal(false);
  };
  return (
    <>
      <DashboardBannerCard
        smallTitle="Health Reminder"
        title={titleForHealthAdvisoryType(advisoryType)}
        subTitle=""
        actionOne={buttonForAdvisory()}
        actionTwo={{
          text: 'View all options',
          onClick: onViewAllOptions
        }}
        imageName={cardSpecProps?.imageName as string}
        background={cardSpecProps?.background as string}
        textColor={cardSpecProps?.color as string}
        totalCards={totalCards}
      />

      <MammogramCallModal onClose={hideModal} isOpen={showModal} />
    </>
  );
};

export default DashboardCarouselHealthAdvisoryFront;
