import React from 'react';
import { useSelector } from 'react-redux';
import { FiltersStructure } from 'lib/hooks/AuditLogFilters/types/filter';
import { FilterDrawer } from 'components/FilterDrawer';
import { viewableCount } from '../constants';
import { FormCheckboxList } from '../shared';
import { useAuditLogFilters } from 'lib/hooks/AuditLogFilters/useAuditLogFilters';
import { accountSwitcherUsersSelector } from 'store/account/selectors';

export type PerformedBysFilterProps = FiltersStructure['performedBy'] & {
  defaultExpanded: boolean;
};

function PerformedBysFilter({ title, values, defaultExpanded }: PerformedBysFilterProps) {
  const performedBy = useSelector(accountSwitcherUsersSelector);
  const filterContext = useAuditLogFilters();
  return (
    <FilterDrawer.Panel
      expandable={performedBy.length}
      title={title}
      defaultExpanded={defaultExpanded}
      filtersCount={filterContext?.values?.facets?.performedBy?.length}
    >
      <FormCheckboxList initialCount={viewableCount} list={Object.values(values(performedBy))} />
    </FilterDrawer.Panel>
  );
}

export default PerformedBysFilter;
