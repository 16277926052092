import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import analyticsService, { AnalyticsEvent } from 'services/AnalyticsService';
import { SpendSummaryBody } from './types';
import CollapsibleListItem from 'components/common/CollapsibleList/CollapsibleListItem';
import { MuiBox } from 'theme/material-ui';
import Typography from 'components/UI/Typography';
import { Svg } from 'components/UI/Svg';
import { IconSize, FontWeight, FontSize } from 'modules/styles/variables';
import TouchablePanelGroup from 'components/UI/Panel/TouchablePanelGroup';
import { generateDeductible, generateSpentYearDate } from './util';
import Divider from 'components/UI/Divider';
import { useHSACollapsibleList } from 'lib/hooks/useBilling';
import useCoreEventData from 'hooks/useCoreEventData';

interface HSAComponentProps {
  largeView: boolean;
}

function HSAComponent(props: HSAComponentProps) {
  const history = useHistory();
  const { currentUrl, referringUrl } = useCoreEventData();
  const {
    familyBenefits,
    hsaData,
    hsaLoading,
    isImpersonating,
    myBenefits,
    selectHealthFacetsInsurance
  } = useHSACollapsibleList();

  const [spendingSummaryData, setSpendingSummaryData] = useState([] as SpendSummaryBody[]);
  const { largeView } = props;

  const goToSpendingDetail = (currentPlan: string) => {
    if (currentPlan === 'Health Saving Account') {
      analyticsService.logEvent(AnalyticsEvent.HSAViewDetailsClicked, {
        currentUrl,
        referringUrl
      });
      history.push('/u/hsaDetails', {
        hsaData
      });
    } else {
      history.push('/u/manage-account/insurance');
    }
  };

  useEffect(() => {
    if (myBenefits) {
      if (
        myBenefits.max_oop &&
        !spendingSummaryData.map(a => a.title).includes('SelectHealth Med Out of Pocket Max')
      ) {
        setSpendingSummaryData(oldArray => [
          {
            id: 'SelectHealth',
            title: 'SelectHealth Med Out of Pocket Max',
            left: 'Individual Out of Pocket Max',
            right: 'Family Out of Pocket Max',
            testId: 'select-health-list-item'
          },
          ...oldArray
        ]);
      }
      if (
        myBenefits.deductible &&
        !spendingSummaryData.map(a => a.title).includes('SelectHealth Med')
      ) {
        setSpendingSummaryData(oldArray => [
          {
            id: 'SelectHealth',
            title: 'SelectHealth Med',
            left: 'Individual Deductible',
            right: 'Family Deductible',
            testId: 'select-health-out-of-pocket-list-item'
          },
          ...oldArray
        ]);
      }
    }
  }, [myBenefits]);

  useEffect(() => {
    if (
      hsaData?.hasAccount &&
      !spendingSummaryData.map(a => a.title).includes('Health Saving Account')
    ) {
      setSpendingSummaryData(oldArray => [
        ...oldArray,
        {
          id: 'HSA',
          title: 'Health Saving Account',
          left: 'Available Balance',
          testId: 'health-saving-list-item'
        }
      ]);
    }
  }, [hsaData]);

  return (
    <>
      {!hsaLoading && (hsaData?.hasAccount || myBenefits) ? (
        <>
          <Divider />
          <CollapsibleListItem
            largeView={largeView}
            leftIcon="Spending"
            title="Spending Summary"
            subtitle="HSA & Deductible progress"
            isExpandable={!isImpersonating}
            analyticsEvent={AnalyticsEvent.SpendingSummaryClicked}
            currentUrlForAnalytics={currentUrl}
            referringUrlForAnalytics={referringUrl}
          >
            <TouchablePanelGroup
              data={spendingSummaryData}
              emptyCaption=""
              keyExtractor={(itemBody: SpendSummaryBody) => `${itemBody.right}`}
              onItemPressed={(itemBody: SpendSummaryBody) => goToSpendingDetail(itemBody.title)}
              renderItem={(itemBody: SpendSummaryBody) => {
                return (
                  <MuiBox
                    data-testid={itemBody.testId}
                    aria-label="Tap for more info on spending summary"
                    justifyContent="space-between"
                    alignItems="center"
                    title="SelectHealth Facets Insurance"
                    display="flex"
                  >
                    <MuiBox flex="1">
                      <Svg set="assets" name={`${itemBody.id}`} size={IconSize.large} />
                    </MuiBox>
                    <MuiBox flex="8">
                      <Typography fontWeight={FontWeight.semibold} fontSize={FontSize.large}>
                        {itemBody.title === 'Health Saving Account'
                          ? itemBody.title
                          : selectHealthFacetsInsurance?.insuranceName}
                      </Typography>
                      {itemBody.id === 'SelectHealth' ? (
                        <Typography
                          fontSize={FontSize.small}
                        >{`Spent year to date Individual: $${generateSpentYearDate(
                          myBenefits,
                          itemBody.title
                        )}`}</Typography>
                      ) : null}
                      <Typography fontSize={FontSize.small}>{`${
                        itemBody.left
                      }: $${generateDeductible(myBenefits, itemBody.title, hsaData)}`}</Typography>
                    </MuiBox>
                    <MuiBox flex="4">
                      {itemBody.right && familyBenefits ? (
                        <>
                          <Typography fontWeight={FontWeight.semibold} fontSize={FontSize.large}>
                            &nbsp;
                          </Typography>
                          <Typography fontSize={FontSize.small}>
                            {`Spent year to date Family: $${generateSpentYearDate(
                              familyBenefits,
                              itemBody.title
                            )}`}
                          </Typography>
                          <Typography fontSize={FontSize.small}>
                            {`${itemBody.right}: $${generateDeductible(
                              familyBenefits,
                              itemBody.title,
                              hsaData
                            )}`}
                          </Typography>
                        </>
                      ) : null}
                    </MuiBox>
                    <MuiBox flex="inherit">
                      <Svg set="material" name="keyboard_arrow_right" />
                    </MuiBox>
                  </MuiBox>
                );
              }}
            />
          </CollapsibleListItem>
        </>
      ) : null}
    </>
  );
}

export default HSAComponent;
