import React from 'react';

export default {
  svg: (
    <g fill="none">
      <rect x="1" y="1" width="42" height="42" rx="3" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.9697 13.9697C14.2626 13.6768 14.7374 13.6768 15.0303 13.9697L22 20.9393L28.9697 13.9697C29.2626 13.6768 29.7374 13.6768 30.0303 13.9697C30.3232 14.2626 30.3232 14.7374 30.0303 15.0303L23.0607 22L30.0303 28.9697C30.3232 29.2626 30.3232 29.7374 30.0303 30.0303C29.7374 30.3232 29.2626 30.3232 28.9697 30.0303L22 23.0607L15.0303 30.0303C14.7374 30.3232 14.2626 30.3232 13.9697 30.0303C13.6768 29.7374 13.6768 29.2626 13.9697 28.9697L20.9393 22L13.9697 15.0303C13.6768 14.7374 13.6768 14.2626 13.9697 13.9697Z"
        fill="#4A00E2"
      />
      <rect x="1" y="1" width="42" height="42" rx="3" stroke="#4A00E2" strokeWidth="2" />
    </g>
  ),
  viewBox: '0 0 44 44'
};
