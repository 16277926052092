import React, { useEffect, useState } from 'react';
import { MuiAvatar } from 'theme/material-ui';

const loadImage = (src: string): Promise<string> => {
  return new Promise((resolve, reject) => {
    const img = new window.Image();

    img.onload = () => resolve(src);
    img.onerror = err => reject(err);

    img.src = src;
  });
};

interface LazyAvatarProps extends React.ComponentProps<typeof MuiAvatar> {
  src: string;
}

// Loads image in JS so we show backup while it is loading
export const LazyMuiAvatar = ({ src, ...rest }: LazyAvatarProps) => {
  const [srcState, setSrcState] = useState<string | undefined>();

  useEffect(() => {
    loadImage(src)
      .then(setSrcState)
      .catch(() => {});
  }, [src]);

  return <MuiAvatar src={srcState} {...rest} />;
};
