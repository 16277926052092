import React, { useEffect, useRef, MutableRefObject } from 'react';
import Config from 'react-native-config';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import { MuiButton } from 'theme/material-ui';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Alert from '@material-ui/lab/Alert';
import formatMoney from 'services/formatMoney';
import { RootState } from 'store/types';
import { setFAForm4Bills } from 'store/billing/financialAssistance/createApplication/actions';
import { BillsForm, Expense } from 'store/billing/financialAssistance/createApplication/types';
import { faBillsSelector } from 'store/billing/financialAssistance/createApplication/selectors';
import {
  currentLocationPathNameSelector,
  previousLocationPathNameSelector
} from 'store/router/selectors';
import analyticsService, { AmplitudeEventData, AnalyticsEvent } from 'services/AnalyticsService';
import { billsValidateSchema } from './validation';
import ConfirmDialog from '../components/ConfirmDialog';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import RequiredFieldsLegend from 'components/RequiredFieldsLegend';
import { useHistory } from 'react-router-dom';
import { CompanyName } from 'modules/utils/ConfigUtils';
import DisableWhenWaiting from '../components/DisableWhenWaiting';
import { FAExitGuard } from './FAExitGuard';
import { Nullable } from 'shared/src/modules/types/common';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    margin: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1)
    }
  })
);
interface Props {
  setFAForm4Bills: typeof setFAForm4Bills;
  billsInfo: BillsForm;
  currentUrl?: string;
  referringUrl?: string;
}
const initialValues: Expense = {
  accountNbr: '',
  provider: '',
  balance: ''
};
function BillsScreen({ setFAForm4Bills, billsInfo, currentUrl, referringUrl }: Props) {
  const history = useHistory();
  const eventData: AmplitudeEventData = {
    currentUrl,
    referringUrl
  };
  const [showFields, setShowFields] = React.useState(false);
  const [showRowDeleteConfirm, setShowRowDeleteConfirm] = React.useState(false);
  const [activeIndex, setActiveIndex] = React.useState(-1);
  const [billsArray, setBillsArray] = React.useState([] as Expense[]);
  const editingIndex: MutableRefObject<Nullable<number>> = useRef(null);
  const [showAddButton, setShowAddButton] = React.useState(false);
  useEffect(() => {
    const bills = billsInfo.expenses;
    if (bills) {
      setBillsArray([...bills]);
    }
  }, [billsInfo]);
  if (billsArray.length && !showAddButton) {
    setShowAddButton(true);
  }
  const saveToStore = (values: BillsForm) => {
    setFAForm4Bills(values);
    analyticsService.logEvent(AnalyticsEvent.BillsNextClicked, {
      type: 'apply',
      ...eventData
    });
    history.push('/u/fa-app/complete');
  };
  const handleSave = (item: Expense, resetForm: Function) => {
    if (item.provider) {
      if (editingIndex.current !== null) {
        billsArray.splice(editingIndex.current, 1, item);
        setBillsArray([...billsArray]);
        editingIndex.current = null;
      } else {
        setBillsArray([...billsArray, item]);
        analyticsService.logEvent(AnalyticsEvent.MedicalExpenseAdded, eventData);
      }
      resetForm({});
    }
    setShowFields(false);
  };
  const cancel = (resetForm: Function) => {
    resetForm({});
    editingIndex.current = null;
    setShowFields(false);
  };
  const editListItem = (itemIndex: number, setValues: Function) => {
    const item: Expense = billsArray[itemIndex];
    setValues({ accountNbr: item.accountNbr, provider: item.provider, balance: item.balance });
    editingIndex.current = itemIndex;
    setShowFields(true);
  };
  const navigateToPrevious = (dataArray: BillsForm) => {
    setFAForm4Bills(dataArray);
    const navigateToURL =
      Config.FA_SHOW_ASSETS_AND_ASSISTANCE === 'enabled'
        ? '/u/fa-app/assets'
        : '/u/fa-app/other-funding';
    history.push(navigateToURL);
  };
  const classes = useStyles();
  const maxLengthBalance = 14;
  return (
    <>
      <Container maxWidth="md">
        <FAExitGuard />
        <Formik
          initialValues={initialValues}
          validationSchema={billsValidateSchema}
          onSubmit={() => saveToStore(billsArray as BillsForm)}
        >
          {({ values, errors, handleSubmit, handleChange, handleBlur, resetForm, setValues }) => {
            const isValid =
              values.provider &&
              values.balance &&
              !errors.provider &&
              !errors.balance &&
              !errors.accountNbr;
            return (
              <>
                <Dialog open={showFields} onClose={() => cancel(resetForm)} maxWidth="sm" fullWidth>
                  <DialogTitle>Add Medical Expense</DialogTitle>
                  <DialogContent>
                    <Box mb={2}>
                      <TextField
                        variant="outlined"
                        label="Account Number"
                        inputProps={{
                          maxLength: 20
                        }}
                        value={values.accountNbr}
                        onChange={handleChange('accountNbr')}
                        onBlur={handleBlur('accountNbr')}
                        fullWidth
                      />
                    </Box>
                    <Box mb={2}>
                      <TextField
                        variant="outlined"
                        required
                        label="Name of Care Facility"
                        placeholder="Hospital, Clinic, etc."
                        value={values.provider}
                        onChange={handleChange('provider')}
                        onBlur={handleBlur('provider')}
                        fullWidth
                      />
                    </Box>
                    <Box mb={2}>
                      <TextField
                        variant="outlined"
                        required
                        type="number"
                        name="balance"
                        label="Account Balance ($)"
                        inputProps={{
                          autoComplete: 'off'
                        }}
                        onChange={e => {
                          if (e.target.value !== 'undefined' || e.target.value !== null) {
                            if (e.target.value.toString().length > maxLengthBalance) {
                              e.target.value = e.target.value.substring(0, maxLengthBalance - 1);
                            } else {
                              handleChange(e);
                            }
                          }
                        }}
                        placeholder="Account Balance($)"
                        value={values.balance}
                        onBlur={handleBlur('balance')}
                        fullWidth
                      />
                    </Box>
                  </DialogContent>
                  <DialogActions>
                    <MuiButton size="large" onClick={() => cancel(resetForm)} color="primary">
                      Cancel
                    </MuiButton>
                    <MuiButton
                      data-testid="save-button"
                      size="large"
                      variant="contained"
                      disabled={!isValid}
                      onClick={() => handleSave(values, resetForm)}
                      color="primary"
                    >
                      Save
                    </MuiButton>
                  </DialogActions>
                </Dialog>
                <Box py={4}>
                  <Box mb={2}>
                    <Typography variant="h5">Non-Intermountain Medical Bills</Typography>
                  </Box>
                  <Divider />
                  <Box mt={2}>
                    <RequiredFieldsLegend />
                  </Box>
                  <Box mt={2}>
                    <Typography>
                      Please itemize your <b>Non-{CompanyName}</b> outstanding medical expenses and
                      if known, indicate the amount still owed after the insurance company pays.
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  {showAddButton ? (
                    <React.Fragment>
                      {billsArray && billsArray.length === 0 ? (
                        <Grid container spacing={2}>
                          <Grid item xs={4}>
                            <TextField
                              data-testid="account-number-text-field"
                              variant="outlined"
                              label="Account Number"
                              inputProps={{
                                maxLength: 20
                              }}
                              value={values.accountNbr}
                              onChange={handleChange('accountNbr')}
                              onBlur={handleBlur('accountNbr')}
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <TextField
                              data-testid="care-facility-text-field"
                              variant="outlined"
                              required
                              label="Name of Care Facility"
                              placeholder="Hospital, Clinic, etc."
                              value={values.provider}
                              onChange={handleChange('provider')}
                              onBlur={handleBlur('provider')}
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <TextField
                              data-testid="account-balance-text-field"
                              variant="outlined"
                              required
                              type="number"
                              name="balance"
                              label="Account Balance ($)"
                              placeholder="Account Balance($)"
                              value={values.balance}
                              inputProps={{
                                autoComplete: 'off'
                              }}
                              onChange={e => {
                                if (e.target.value !== 'undefined' || e.target.value !== null) {
                                  if (e.target.value.toString().length > maxLengthBalance) {
                                    e.target.value = e.target.value.substring(
                                      0,
                                      maxLengthBalance - 1
                                    );
                                  } else {
                                    handleChange(e);
                                  }
                                }
                              }}
                              onBlur={handleBlur('balance')}
                              fullWidth
                            />
                          </Grid>
                        </Grid>
                      ) : (
                        <TableContainer>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>
                                  <Typography>
                                    <b>Account Number</b>
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography>
                                    <b>Care Facility</b>
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography>
                                    <b>Balance</b>
                                  </Typography>
                                </TableCell>
                                <TableCell />
                                <TableCell />
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <ConfirmDialog
                                isOpen={showRowDeleteConfirm}
                                title="You will lose your data"
                                description="Are you sure you want to delete?"
                                onCancel={() => setShowRowDeleteConfirm(false)}
                                onConfirm={() => {
                                  billsArray.splice(activeIndex, 1);
                                  setBillsArray([...billsArray]);
                                  setShowRowDeleteConfirm(false);
                                  if (billsArray.length === 0) {
                                    setShowAddButton(true);
                                  }
                                  analyticsService.logEvent(
                                    AnalyticsEvent.RemoveBillClicked,
                                    eventData
                                  );
                                }}
                                confirmLabel="Yes, Delete"
                                cancelLabel="Cancel"
                              />
                              {billsArray.map((bill, idx) => (
                                <>
                                  <TableRow>
                                    <TableCell>
                                      <Typography>{bill.accountNbr}</Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography>{bill.provider}</Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography>{formatMoney(Number(bill.balance))}</Typography>
                                    </TableCell>
                                    <TableCell padding="checkbox" align="right">
                                      <IconButton onClick={() => editListItem(idx, setValues)}>
                                        <EditIcon fontSize="small" />
                                      </IconButton>
                                    </TableCell>
                                    <TableCell padding="checkbox" align="right">
                                      <IconButton
                                        onClick={() => {
                                          setActiveIndex(idx);
                                          setShowRowDeleteConfirm(true);
                                        }}
                                      >
                                        <DeleteIcon color="error" fontSize="small" />
                                      </IconButton>
                                    </TableCell>
                                  </TableRow>
                                </>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      )}
                      <Box mb={4}>
                        {billsArray && billsArray.length === 0 ? (
                          <Box display="flex" pt={3} justifyContent="flex-end">
                            <MuiButton
                              data-testid="save-button"
                              color="primary"
                              variant="contained"
                              disabled={!isValid}
                              onClick={() => handleSave(values, resetForm)}
                              className={classes.margin}
                            >
                              Save
                            </MuiButton>
                            <MuiButton
                              variant="outlined"
                              color="primary"
                              onClick={() => setShowAddButton(false)}
                              className={classes.margin}
                            >
                              Cancel
                            </MuiButton>
                          </Box>
                        ) : billsArray && billsArray.length < 5 ? (
                          <Box display="flex" justifyContent="flex-end" pt={3}>
                            <MuiButton
                              color="primary"
                              variant="outlined"
                              onClick={() => {
                                analyticsService.logEvent(
                                  AnalyticsEvent.AddAdditionalMedExpenseClicked,
                                  eventData
                                );
                                setShowFields(true);
                              }}
                            >
                              Add additional medical expenses
                            </MuiButton>
                          </Box>
                        ) : (
                          <Box pt={3}>
                            <Alert severity="info">
                              List additional Non-Intermountain bills in &quot;Additional
                              Comment&quot; input on Assets page.
                            </Alert>
                          </Box>
                        )}
                      </Box>
                    </React.Fragment>
                  ) : (
                    <Box py={4}>
                      <Container maxWidth="xs">
                        <Box mb={1}>
                          <MuiButton
                            data-testid="add-medical-expense-button"
                            variant="outlined"
                            color="primary"
                            fullWidth
                            size="large"
                            onClick={() => setShowAddButton(true)}
                          >
                            Add medical expense
                          </MuiButton>
                        </Box>
                      </Container>
                    </Box>
                  )}
                  <Box py={4}>
                    <Container maxWidth="xs">
                      <Box mb={1}>
                        <MuiButton
                          disabled={
                            (showAddButton && !billsArray.length) ||
                            (showFields && billsArray.length)
                          }
                          data-testid="bills-next-button"
                          size="large"
                          variant="contained"
                          color="primary"
                          fullWidth
                          onClick={() => {
                            handleSubmit();
                          }}
                        >
                          Next
                        </MuiButton>
                      </Box>
                      <MuiButton
                        data-testid="bills-previous-button"
                        size="large"
                        color="primary"
                        fullWidth
                        onClick={() => navigateToPrevious(billsArray as BillsForm)}
                      >
                        Previous
                      </MuiButton>
                    </Container>
                  </Box>
                </Box>
              </>
            );
          }}
        </Formik>
      </Container>
      <DisableWhenWaiting />
    </>
  );
}
const mapStateToProps = (state: RootState) => ({
  billsInfo: faBillsSelector(state),
  currentUrl: currentLocationPathNameSelector(state),
  referringUrl: previousLocationPathNameSelector(state)
});
const mapDispatch = {
  setFAForm4Bills
};
export default connect(mapStateToProps, mapDispatch)(BillsScreen);
