import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import { MuiBox, MuiContainer, MuiRadio, MuiTypography } from 'theme/material-ui';
import { Spacing, FontWeight } from 'modules/styles/variables';
import Spacer from 'components/UI/Layout/Spacer';
import FlexBox from 'components/UI/Layout/FlexBox';
import Footer from 'components/common/Footer';

import { RootState, RootDispatch } from 'store/types';
import { Insurance } from 'store/profile/types';
import { profileConsumerMilleniumInsuranceSelector } from 'store/profile/selectors';
import { CovidScreeningQuestionnaireSelector } from 'store/CovidScreening/selectors';
import { Questionnaire } from 'store/CovidScreening/types';
import { setQuestionnaire } from 'store/CovidScreening/actions';
import { getProfile } from 'store/profile/actions';
import { patientSelect } from 'modules/constants/covidScreening';
import { StyledScreen } from '../../../components/common/PatientLoader/styled';
import { CovidTestButton } from '../Components/CovidTestButton';
import useStyles from '../Components/CovidTestButton/useStyles';
import { CovidTitles } from 'lib/constants/covidScreening';
import { AnalyticsEvent } from 'services/AnalyticsService';
import useNavigationAnalytics from 'hooks/useNavigationAnalytics';

interface CovidInsuranceProps extends RouteComponentProps {
  dispatch: RootDispatch;
  activeInsurance: Insurance[];
  questionnaire: Questionnaire;
  setHasInsurance: typeof setQuestionnaire;
}

const CovidExistingInsurance = ({
  activeInsurance,
  questionnaire,
  setHasInsurance,
  history
}: CovidInsuranceProps) => {
  const classes = useStyles();

  const [correctInfo, setCorrectInfo] = useState('');

  const covidScreeningNextBtn = useNavigationAnalytics(AnalyticsEvent.CovidNext, {
    title: CovidTitles.CONFIRM_EXISTING_INSURANCE,

    confirmed_insurance: correctInfo === 'Yes' ? 'yes' : 'no'
  });

  const covidScreeningPreviousBtn = useNavigationAnalytics(AnalyticsEvent.CovidPrev, {
    title: CovidTitles.CONFIRM_EXISTING_INSURANCE
  });

  const onCancelClick = () => {
    covidScreeningPreviousBtn();
    history.goBack();
  };

  useEffect(() => {
    setCorrectInfo(questionnaire?.correctInsurance);
  }, [questionnaire]);

  const handleSubmit = () => {
    covidScreeningNextBtn();
    if (correctInfo === 'Yes') {
      const questionnaireObject = {
        ...questionnaire,

        has_insurance: 'Yes',
        MEMBER_NUMBER: activeInsurance[0]?.subscriberId,
        Health_Plan_Name: activeInsurance[0]?.planName,
        Health_Plan_ID: activeInsurance[0]?.planId,
        GROUP_NUMBER: activeInsurance[0]?.groupId,
        correctInsurance: correctInfo
      };
      setHasInsurance(questionnaireObject);
      setCorrectInfo('');
      history.push('/u/get-care-now/covid-screen/summary-information');
    }
    if (correctInfo === 'No') {
      const questionnaireObject = {
        ...questionnaire,
        has_insurance: 'Yes',
        MEMBER_NUMBER: '',
        Health_Plan_Name: '',
        Health_Plan_ID: '',
        GROUP_NUMBER: '',
        correctInsurance: correctInfo
      };
      setHasInsurance(questionnaireObject);
      setCorrectInfo('');
      history.push('/u/get-care-now/covid-screen/covid-insurance');
    }
  };
  const renderInsuranceConfirmation = () => (
    <>
      <FlexBox
        testID="covid-insurance-confirmation"
        width="100%"
        hSpacing={Spacing.medium}
        vSpacing={Spacing.medium}
      >
        <MuiTypography fontWeight={FontWeight.bold}>
          Please confirm the insurance information we have on file is up to date.
        </MuiTypography>
        <Spacer size="small" />
        <MuiTypography>
          Insurance information is needed to bill for COVID-19 tests. Copayments and deductibles may
          apply based on insurance plan.
        </MuiTypography>
        <Spacer size="small" />
        <MuiTypography>
          If additional insurance verification is needed, you may receive a follow-up call.
        </MuiTypography>
        <Spacer />
        <MuiTypography fontWeight={FontWeight.bold}>{activeInsurance[0]?.planName}</MuiTypography>
        <Spacer size="small" />

        <MuiTypography>Subscriber ID: {activeInsurance[0]?.subscriberId} </MuiTypography>
        <Spacer size="small" />
        <MuiTypography>Plan Network: {activeInsurance[0]?.planName}</MuiTypography>
        <Spacer size="small" />
        <MuiTypography>Plan Number: {activeInsurance[0]?.planId}</MuiTypography>
        <Spacer />
        <MuiTypography fontWeight={FontWeight.bold}>
          Is the above information correct?
        </MuiTypography>
        <Spacer size="small" />
        <FlexBox flexDirection="row" alignItems="center">
          <MuiRadio
            onChange={() => setCorrectInfo('Yes')}
            checked={correctInfo === 'Yes'}
            data-testid="existing-insurance-yes"
            value={correctInfo}
            color="primary"
          />
          <MuiTypography>Yes</MuiTypography>
        </FlexBox>
        <FlexBox flexDirection="row" alignItems="center">
          <MuiRadio
            onChange={() => setCorrectInfo('No')}
            checked={correctInfo === 'No'}
            data-testid="existing-insurance-no"
            value={correctInfo}
            color="primary"
          />
          <MuiTypography>No</MuiTypography>
        </FlexBox>
      </FlexBox>
    </>
  );

  return (
    <>
      <StyledScreen>
        <MuiContainer maxWidth="lg">
          <Spacer />
          {renderInsuranceConfirmation()}
          <Spacer size="small" />
        </MuiContainer>
      </StyledScreen>
      <MuiContainer maxWidth="lg">
        <MuiBox className={classes.steppersFooter}>
          <CovidTestButton
            fullWidth
            variant="contained"
            data-testid="existing-insurance-next-button"
            onClick={handleSubmit}
            disabled={!correctInfo}
          >
            Next
          </CovidTestButton>
          <CovidTestButton
            fullWidth
            variant="text"
            data-testid="existing-insurance-previous-button"
            onClick={onCancelClick}
          >
            Previous
          </CovidTestButton>
        </MuiBox>
      </MuiContainer>
      <Footer
        headerText={patientSelect.GET_PATIENTS.HELPLINE.CovidInfo}
        link={patientSelect.GET_PATIENTS.HELPLINE.CovidPatientSelectHelpline}
        linkName={patientSelect.GET_PATIENTS.HELPLINE.CovidPatientSelectHelpline}
        helplineHours={patientSelect.GET_PATIENTS.HELPLINE.CovidHelplineHours}
        title={CovidTitles.CONFIRM_EXISTING_INSURANCE}
      />
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  activeInsurance: profileConsumerMilleniumInsuranceSelector(state),
  questionnaire: CovidScreeningQuestionnaireSelector(state)
});
const mapDispatchToProps = {
  setHasInsurance: setQuestionnaire,
  fetchInsurance: getProfile
};

export default connect(mapStateToProps, mapDispatchToProps)(CovidExistingInsurance);
