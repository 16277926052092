import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RootState } from 'store/types';
import analyticsService, { AmplitudeEventData, AnalyticsEvent } from 'services/AnalyticsService';
import {
  currentLocationPathNameSelector,
  previousLocationPathNameSelector
} from 'store/router/selectors';
import styled from 'styled-components';
import LinkingServices from 'services/LinkingServices.web.ts'; // eslint-disable-line import/extensions
import Svg from 'components/UI/Svg/Svg';
import { Color } from 'modules/styles/colors';
import { MuiTypography } from 'theme/material-ui';
import { FontSize } from 'modules/styles/variables';

interface AppBannerProps {
  currentUrl?: string;
  referringUrl?: string;
}

const BannerContainer = styled.div<{ position: string }>`
  display: flex;
  align-items: center;
  color: #000;
  background: #f2f2f2;
  box-shadow: ${({ position }) =>
    position === 'top'
      ? '0 1px 2px rgba(0, 0, 0, 0.8) inset, 0 1px 1px rgba(255, 255, 255, 0.3)'
      : '5px 5px 15px 0px rgba(138, 138, 138, 0.6)'};
  border-radius: ${({ position }) => (position === 'top' ? '0' : '10px')};
  z-index: 10;
`;

const CloseButtonContainer = styled.div`
  justify-self: end;
  align-self: center;
`;

const CloseButton = styled.button`
  background: transparent;
  border: none;
  font-size: ${FontSize.xsmall};
  text-align: center;
  text-decoration: none;
  border-radius: 14px;
  -webkit-font-smoothing: subpixel-antialiased;
  border: 0;
  height: 17px;
  line-height: 17px;
  color: #b1b1b3;
  background: ${Color.grayHue4};
  cursor: pointer;
`;

const AppImageContainer = styled.div`
  justify-self: start;
  padding: 10px 10px 10px 0px;
`;

const AppImage = styled.img`
  width: 65px;
  border-radius: 15px;
`;

const AppInfoContainer = styled.div`
  justify-self: start;
  display: flex;
  flex-direction: column;
  padding: 2px;
  justify-content: center;
  color: #0d0d0d;
  font-size: 12px;
`;

const AppLinkButton = styled.a`
  font-size: ${FontSize.large};
  height: 26px;
  align-self: center;
  color: blue;
  padding: 2px 10px;
  background: transparent;
  border-radius: 5px;
  border: 1px solid;
  cursor: pointer;
  position: absolute;
  right: 9px;
  text-decoration: none;
`;

const APP_TITLE = 'Intermountain Health UT/ID';
const APP_SUB_TITLE = 'Find, Manage, and Pay for Care';

const AppBanner = (props: AppBannerProps) => {
  const [show, setShow] = useState(false);
  const [infoText, setInfoText] = useState('');
  const { currentUrl, referringUrl } = props;

  const ua: string = window.navigator.userAgent;
  const isMobile: boolean = /Android|webOS|iPhone|iPad|iPod/i.test(ua);
  const isApple: boolean = /webOS|iPhone|iPad|iPod/i.test(ua);
  const isSafari: boolean = /^((?!chrome|android).)*safari/i.test(ua);

  const eventData: AmplitudeEventData = {
    currentUrl,
    referringUrl
  };

  const handleLink = () => {
    analyticsService.logEvent(AnalyticsEvent.MobileAppBannerDownloadClicked, eventData);
    LinkingServices.openAppPage(isApple);
  };

  const handleClose = () => {
    analyticsService.logEvent(AnalyticsEvent.MobileAppBannerCloseClicked, eventData);
    window.sessionStorage.setItem('closeBanner', 'y');
    setShow(false);
  };

  // Our banner should be hidden in favor of Apple's Smart App Banner if the user is visiting the
  // site from Safari Mobile. See the <meta> tag  @ web/public/index.html line: 9
  // More information about about Apple's Smart App Banner can be found at:
  // https://developer.apple.com/library/archive/documentation/AppleApplications/Reference/SafariWebContent/PromotingAppswithAppBanners/PromotingAppswithAppBanners.html
  useEffect(() => {
    if (isMobile && !isSafari && window.sessionStorage.getItem('closeBanner') !== 'y') {
      setShow(true);
      setInfoText(isApple ? 'GET - On the App Store' : 'GET - On the Play Store');
    }
  }, []);

  return (
    show && (
      <BannerContainer position="top">
        <CloseButtonContainer>
          <CloseButton onClick={handleClose}>
            <Svg name="close" set="material" size={14} color={Color.gray} />
          </CloseButton>
        </CloseButtonContainer>
        <AppImageContainer>
          <AppImage src="/app.png" alt="App Icon" />
        </AppImageContainer>
        <AppInfoContainer>
          <MuiTypography fontSize={FontSize.base}>{APP_TITLE}</MuiTypography>
          <MuiTypography fontSize={FontSize.small} color={Color.gray}>
            {APP_SUB_TITLE}
          </MuiTypography>
          <MuiTypography color={Color.gray}>{infoText}</MuiTypography>
        </AppInfoContainer>
        <AppLinkButton onClick={handleLink} target="_blank">
          View
        </AppLinkButton>
      </BannerContainer>
    )
  );
};

const mapState = (state: RootState) => ({
  currentUrl: currentLocationPathNameSelector(state),
  referringUrl: previousLocationPathNameSelector(state)
});

export default connect(mapState)(AppBanner);
