import React from 'react';
import DefaultLabel from 'components/UI/Labels/DefaultLabel';
import Container from 'components/UI/Layout/Container';
import PanelGroupItem from 'components/UI/Panel/PanelGroupItem';

interface Props<T> {
  data: T[];
  keyExtractor: (item: T) => string;
  renderItem: (item: T, index: number) => React.ReactNode;
  emptyCaption?: string;
  style?: any;
}

function PanelGroup<T>(props: Props<T>) {
  return !props.data || props.data.length === 0 ? (
    <Container>
      <DefaultLabel>{props.emptyCaption}</DefaultLabel>
    </Container>
  ) : (
    <Container>
      {props.data.map((item, index) => (
        <PanelGroupItem
          key={props.keyExtractor(item)}
          firstChild={index === 0}
          lastChild={index === props.data.length - 1}
          style={props.style}
        >
          {props.renderItem(item, index)}
        </PanelGroupItem>
      ))}
    </Container>
  );
}

PanelGroup.defaultProps = {
  data: [],
  emptyCaption: undefined
};

export default PanelGroup;
