// Web Alert Implementation

import React, { useState, useEffect } from 'react';
import {
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  DialogProps
} from '@material-ui/core';
import { MuiButton, MuiGrid } from 'theme/material-ui';
import { EventEmitter } from 'services/EventEmitter';
import CloseButton from 'components/UI/Button/CloseButton';
import { StyledMuiDialog } from 'screens/Booking/components/styled';

export interface AlertAction {
  text: string;
  onPress?: () => void;
}

export interface AlertOptions {
  cancelable?: boolean;
  closeIcon?: boolean;
  fullScreen?: boolean;
  onClose?: () => void;
}

export interface AlertProps {
  title: string;
  message: string | undefined | null;
  renderContent?: () => Element | JSX.Element;
  actions?: AlertAction[];
  options?: AlertOptions;
}

/**
 * Event bus to pass alert events through to AlertDialog component
 */
const alertBus = new EventEmitter();

/**
 * Alert public api - matches react native api
 */
export class Alert {
  static alert(
    title: AlertProps['title'],
    message?: AlertProps['message'],
    actions?: AlertProps['actions'],
    options?: AlertProps['options'],
    renderContent?: AlertProps['renderContent']
  ) {
    alertBus.emit('alert', {
      title,
      message,
      renderContent,
      actions,
      options
    });
  }
}

/**
 * Alert component to use in app
 */
export const AlertDialog = () => {
  const [alertProps, setAlertProps] = useState<AlertProps | null>(null);

  useEffect(() => {
    const unsubscribe = alertBus.on(
      'alert',
      ({ title, message, renderContent, actions, options }: AlertProps) => {
        // Don't override existing alerts - Determine later if we should queue these
        if (alertProps !== null) {
          return;
        }

        setAlertProps(
          prevState =>
            prevState || {
              title,
              message,
              renderContent,
              actions,
              options
            }
        );
      }
    );

    return () => {
      unsubscribe();
    };
  }, [setAlertProps, alertBus]);

  const handleClose = () => {
    if (alertProps && alertProps.options && alertProps.options.onClose) {
      alertProps.options.onClose();
    }
    setAlertProps(null);
  };

  const wrapOnPress = (onPressHandler: AlertAction['onPress']) => () => {
    onPressHandler?.();
    handleClose();
  };

  if (!alertProps) {
    return null;
  }

  const {
    title,
    message,
    renderContent,
    actions = [
      {
        onPress: handleClose,
        text: 'Ok'
      }
    ],
    options = { cancelable: false, closeIcon: false }
  } = alertProps;

  const dialogProps: DialogProps = {
    open: true,
    onClose: handleClose,
    disableBackdropClick: !options.cancelable,
    disableEscapeKeyDown: !options.cancelable,
    fullScreen: options.fullScreen,
    'aria-labelledby': 'alert-dialog-title',
    'aria-describedby': 'alert-dialog-description'
  };

  return (
    <StyledMuiDialog {...dialogProps}>
      <DialogTitle id="alert-dialog-title">
        {title}
        {options.closeIcon && (
          <CloseButton absolute onPress={handleClose} margin="0px" padding={3} />
        )}
      </DialogTitle>

      {message || renderContent ? (
        <DialogContent dividers>
          <DialogContentText
            color="textPrimary"
            id="alert-dialog-description"
            data-testid="alert-dialog-description"
            component="span"
          >
            {message && message}
            {renderContent && renderContent()}
          </DialogContentText>
        </DialogContent>
      ) : null}

      {actions ? (
        <DialogActions style={{ justifyContent: 'center' }}>
          {actions.map(action => (
            <MuiGrid item xs={actions?.length === 1 ? 6 : 12}>
              <MuiButton
                onClick={wrapOnPress(action.onPress)}
                fullWidth={actions?.length > 0}
                variant="outlined"
                data-testid="action-button"
                color="primary"
                key={action.text}
              >
                {action.text}
              </MuiButton>
            </MuiGrid>
          ))}
        </DialogActions>
      ) : null}
    </StyledMuiDialog>
  );
};
