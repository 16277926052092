import React from 'react';
import styled from 'styled-components';
import { MuiTypography } from 'theme/material-ui';
import { Color } from 'modules/styles/colors';
import bg from 'assets/evisits/card_bg.png';
import Spacer from 'components/UI/Layout/Spacer';
import { FontWeight, FontSize } from 'modules/styles/variables';
import { InsurancePolicy } from 'store/eVisit/types';
import Spinner from 'components/UI/Spinner/Spinner';
import { UploadSideState } from '../EVisitUploadInsurance';
import {
  UploadCardContentDefault,
  UploadCardContentSuccess,
  UploadCardContentError
} from './InsuranceCardItems';
import { FileUploadBox } from 'components/FileUploadBox';

interface InsuranceCardProps {
  insuranceInfo?: InsurancePolicy;
  isLoading?: boolean;
}

const CardWrapperStyled = styled.div<{ onClick?: Function; isEmpty: boolean }>`
  color: ${({ isEmpty }) => (isEmpty ? Color.primary : Color.foreColor)};
  border-radius: 11px;
  padding: 25px 30px 35px;
  margin: 2rem 0;
  width: 335px;
  max-width: 100%;
  background-color: ${({ isEmpty }) => (isEmpty ? Color.foreColor : Color.primary)};
  position: relative;
  box-shadow: 5px 5px 15px rgb(0 0 0 / 25%);
  cursor: ${({ onClick }) => (!!onClick ? 'pointer' : 'default')};
  min-height: 185px;
  ${({ isEmpty }) =>
    !isEmpty
      ? `&:before {
        content: '';
        left: 0;
        top: 0;
        background: transparent url(${bg}) no-repeat left center;
        background-size: auto 100%;
        opacity: 0.25;
        position: absolute;
        width: 100%;
        height: 100%;
      }`
      : ''}
`;

const LoadingCardWrapper = styled(CardWrapperStyled)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const defaultPlanNameText = 'No Insurance on file';

const FloatingLabelStyled = styled.div`
  position: absolute;
  left: 25px;
  top: 20px;
`;

const FloatingLabel = ({ isBackSide }: { isBackSide?: boolean }) => (
  <FloatingLabelStyled>
    <MuiTypography fontSize={FontSize.xsmall}>{isBackSide ? 'Back' : 'Front'} Side</MuiTypography>
  </FloatingLabelStyled>
);

const LoadingCard = ({
  isUploading,
  isBackSide
}: {
  isUploading?: boolean;
  isBackSide?: boolean;
}) => (
  <LoadingCardWrapper isEmpty data-testId="insurance-empty-card">
    <Spinner />
    {isUploading ? (
      <>
        <Spacer size="xsmall" />
        <FloatingLabel isBackSide={isBackSide} />
        <MuiTypography fontSize={FontSize.base} fontWeight={FontWeight.bold}>
          Processing
        </MuiTypography>
        <MuiTypography fontSize={FontSize.xsmall}>
          Please wait as we upload your image
        </MuiTypography>
      </>
    ) : null}
  </LoadingCardWrapper>
);

export const UploadInsuranceCard = ({
  isBackSide,
  uploadImage,
  status,
  selectedImage,
  isPrimary
}: {
  isBackSide?: boolean;
  uploadImage: Function;
  status: UploadSideState;
  selectedImage?: string;
  isPrimary?: Boolean;
}) => {
  if (status?.isLoading) return <LoadingCard isUploading isBackSide={isBackSide} />;
  const upload = (file: File) => {
    uploadImage(isBackSide, isPrimary, file);
  };

  return (
    <FileUploadBox hasIcon={false} image isMini color={Color.baseColor} onClick={upload}>
      <CardWrapperStyled isEmpty style={{ textAlign: 'center', cursor: 'pointer' }}>
        <FloatingLabel isBackSide={isBackSide} />
        {!status?.isError && !status?.isSuccess && (
          <UploadCardContentDefault isBackSide={isBackSide} />
        )}
        {status.isSuccess && <UploadCardContentSuccess selectedImage={selectedImage} />}
        {status.isError && <UploadCardContentError isBackSide={isBackSide} />}
      </CardWrapperStyled>
    </FileUploadBox>
  );
};

export default ({ insuranceInfo, isLoading }: InsuranceCardProps) => {
  const { planNetwork = '', subscriberFullName, memberId } = insuranceInfo || {};
  if (isLoading) return <LoadingCard />;
  return (
    <CardWrapperStyled isEmpty={!insuranceInfo}>
      <MuiTypography fontWeight={FontWeight.bold} component="h5" variant="h5">
        {planNetwork || defaultPlanNameText}
      </MuiTypography>
      <MuiTypography fontSize={FontSize.xsmall}>Primary Insurance</MuiTypography>
      <Spacer spacing="large" />
      {subscriberFullName && (
        <>
          <MuiTypography component="h6" fontWeight={FontWeight.bold}>
            {subscriberFullName}
          </MuiTypography>
          <MuiTypography fontSize={FontSize.xsmall}>Subscriber</MuiTypography>
          <Spacer spacing="large" />
        </>
      )}
      {memberId && <MuiTypography fontSize={FontSize.small}>Member ID: {memberId}</MuiTypography>}
      {!insuranceInfo ? (
        <MuiTypography>
          Please select “<b>Add New Insurance</b>” to update your primary or secondary insurance.
        </MuiTypography>
      ) : null}
    </CardWrapperStyled>
  );
};
