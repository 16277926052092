import { MuiBox, MuiPaper, MuiTypography } from 'theme/material-ui';
import { Color } from 'modules/styles/colors';
import { formatDate } from 'modules/utils/DateUtils';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { termsAndConditionsSelector } from 'store/global/selectors';
import { profileConsumerSelector } from 'store/profile/selectors';
import { RootState } from 'store/types';
import { isIE } from 'lib/browser';
import { oc } from 'ts-optchain';

interface TermsOfUseProps {
  terms: ReturnType<typeof termsAndConditionsSelector>;
  consumer: ReturnType<typeof profileConsumerSelector>;
}

/**
 * Using iFrame so styles don't bleed out
 */
export const TermsOfUse = ({ terms, consumer }: TermsOfUseProps) => {
  const generalTermAcceptance = oc(terms).general.content('');
  const acceptanceDate = oc(consumer).termAcceptance.date('');

  useEffect(() => {
    if (isIE()) {
      const doc = document.getElementById('termsFrame')?.contentWindow.document;
      doc.open();
      doc.write(generalTermAcceptance);
      doc.close();
    }
  }, []);

  return (
    <MuiPaper>
      <MuiBox data-testid="privacy-and-consent" p={3}>
        <MuiBox mb={2}>
          <MuiTypography gutterBottom>
            Accepted on:{'  '}
            <MuiTypography display="inline" style={{ fontWeight: 600 }}>
              {formatDate(acceptanceDate)}
            </MuiTypography>
          </MuiTypography>
        </MuiBox>

        <MuiBox p={2} bgcolor={Color.foreColor}>
          <iframe
            id="termsFrame"
            title="Terms of use"
            frameBorder="0"
            width="100%"
            height={375}
            srcDoc={generalTermAcceptance}
          />
        </MuiBox>
      </MuiBox>
    </MuiPaper>
  );
};

const mapState = (state: RootState) => ({
  terms: termsAndConditionsSelector(state),
  consumer: profileConsumerSelector(state)
});

export default connect(mapState)(TermsOfUse);
