import styled from 'styled-components';
import Headline from 'components/UI/Typography/Headline';
import { borderRadius, ZIndex, headingFontSize } from 'modules/styles/variables';
import { Color } from 'modules/styles/colors';
import { MuiCard } from 'theme/material-ui';

export const Clickable = styled.div`
  cursor: pointer;
  height: 100%;
`;

export const MuiRowCard = styled(MuiCard)`
  height: 100%;
  margin-right: 20px;
`;

export const TextContainer = styled.div`
  flex: auto;
  align-items: flex-start;
  align-self: flex-start;
`;

export const PaddedContainer = styled.div`
  padding: 10px;
  justify-content: center;
  align-items: center;
  align-content: center;
`;

export const LargeCardText = styled(Headline)`
  margin-bottom: 10px;
`;

export const DetailTitleCardText = styled(LargeCardText)`
  font-size: ${headingFontSize};
`;

export const AddressContainer = styled.div`
  && {
    display: flex;
    flex-direction: row;
    align-content: center;
  }
`;

export interface CardMediaProps {
  imageUrl: string;
}

export const CardRowMedia = styled.div`
  height: 100%;
  background-repeat: no-repeat;
  background-position: left;
  background-image: url("${(props: CardMediaProps) => props.imageUrl}");
`;

export const CardColMedia = styled.div`
  height: 150px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left;
  background-image: url("${(props: CardMediaProps) => props.imageUrl}");
`;

export const BadgeRowContainer = styled.div`
  && {
    display: flex;
    margin-right: 10px;
    justify-content: flex-end;
  }
`;

export const BadgeColContainer = styled.div`
  && {
    z-index: ${ZIndex.high};
    position: absolute;
    right: 10px;
  }
`;

export const Container = styled.div`
  background: white;
  border-width: 0.5px;
  border-color: ${Color.grayLight};
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  border-radius: ${borderRadius};
  margin-top: 10px;
  padding: 10px;
`;
