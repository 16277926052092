import React, { useEffect, useState } from 'react';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Image } from 'react-native';

import errorPlug from 'assets/errorPlug.png';

import { useSnack } from 'components/Snack';
import { Alert } from 'components/Alert';
import { Modal } from 'components/CustomModal';
import RouteLeavingGuard from 'components/UI/Modals/RouteLeavingGuardModal';

import { MuiBox, MuiContainer, MuiGrid } from 'theme/material-ui';
import { waitingRoom as waitingRoomConfirmations } from 'modules/constants/amwell';

import {
  handleTransfer,
  processVisit,
  cancelVisit,
  startVisit,
  resetAmwellActivity
} from 'store/amwell/actions';
import { Disposition, Visit, VisitType, VisitStep, EndReason } from 'store/amwell/types';
import * as selectors from 'store/amwell/selectors';
import { RootState, RootDispatch } from 'store/types';
import {
  currentLocationPathNameSelector,
  previousLocationPathNameSelector
} from 'store/router/selectors';

import ConnectCareWaitingRoomProviderDetails from './ConnectCareWaitingRoomProviderDetails';
import ConnectCareWaitingRoomChatWindow from './ConnectCareWaitingRoomChatWindow';
import ConnectCareWaitingRoomTransferDialog from './ConnectCareWaitingRoomTransferDialog';
import ConnectCareWebRTC from './ConnectCareWebRTC';
import ConnectCareTelehealthSetup from './ConnectCareTelehealthSetup';
import { FormScreen } from '../styled';

export interface Props extends RouteComponentProps {
  showSuggestedTransfer: boolean;
  dispatch: RootDispatch;
  visit: Visit | null;
  visitType: VisitType | null;
  visitStep: VisitStep | null;
  error: Error | null;
  referringUrl: string;
  currentUrl: string;
}

const visitSummaryDispositions = [
  Disposition.Completed,
  Disposition.ProviderWrapup,
  Disposition.ConsumerCanceled
];

const isVisitSummaryDisposition = (visit: Visit) => {
  return visitSummaryDispositions.some(d => d === visit.disposition);
};

const isWebRTC = (visitType: VisitType | null) => {
  return visitType === VisitType.WEB_RTC;
};

const isTelehealthVideo = (visitType: VisitType | null) => {
  return visitType === VisitType.TELEHEALTH_VIDEO;
};

let visitTimer: NodeJS.Timeout;

export function ConnectCareWaitingRoom(props: Props) {
  const { dispatch, visit, history, error, visitType, visitStep } = props;
  const chatItemsLength = visit?.chatReport?.chatItems?.length;
  const { create } = useSnack();

  const [showWebRTC, setShowWebRTC] = useState(false);
  const [showTelehealthSetup, setShowTelehealthSetup] = useState(false);

  const [transfering, setTransfering] = useState(false);
  const [showTransfer, setShowTransfer] = useState(false);
  const [isChatWindowVisible, setIsChatWindowVisible] = useState(false);

  const handleWebRTCSteps = () => {
    switch (visitStep) {
      case VisitStep.STARTED:
        setShowWebRTC(true);
        break;
      case VisitStep.FINISHED:
        setShowWebRTC(false);
        break;
      default:
    }
  };

  const handleTelehealthSteps = () => {
    switch (visitStep) {
      case VisitStep.SETUP:
        setShowTelehealthSetup(true);
        break;
      case VisitStep.PROCESSING:
        setShowTelehealthSetup(false);
        break;
      default:
    }
  };

  const handleEndReasons = () => {
    let shouldEndVisit = false;

    switch (visit?.endReason) {
      case EndReason.CONSUMER_TRANSFER:
        dispatch(handleTransfer({ visit })).then((res: AnyAction) => {
          if (res.error) {
            create(
              waitingRoomConfirmations.TRANSFER_ERROR.subtitle,
              waitingRoomConfirmations.TRANSFER_ERROR.severity
            );
            shouldEndVisit = true;
          } else {
            onVisitTransfer(res.payload.visit, res.payload.isQuickTransfer);
          }
        });
        break;
      case EndReason.PROVIDER_DECLINE_AND_TRANSFER:
        setShowTransfer(true);
        break;
      case EndReason.PROVIDER_DECLINE:
        Alert.alert(
          waitingRoomConfirmations.DECLINED_WARNING.title,
          waitingRoomConfirmations.DECLINED_WARNING.subtitle,
          [
            {
              text: 'Ok',
              onPress: () => dispatch(resetAmwellActivity())
            }
          ]
        );
        shouldEndVisit = true;
        break;
      default:
        shouldEndVisit = true;
    }

    return shouldEndVisit;
  };

  useEffect(() => {
    dispatch(processVisit({ visit }));
    return () => {
      clearTimeout(visitTimer);
    };
  }, []);

  useEffect(() => {
    if (isTelehealthVideo(visitType)) {
      handleTelehealthSteps();
    } else if (isWebRTC(visitType)) {
      handleWebRTCSteps();
    }
  }, [visitType, visitStep]);

  const providerOfflineError = (time: number) => {
    clearTimeout(visitTimer);
    visitTimer = setTimeout(() => {
      const buttons = {
        styles: { direction: 'row', justifyContent: 'center', alignItems: 'center' },
        items: [
          {
            label: 'Ok',
            onClick: () => {
              onVisitFinished(visit);
            },
            styles: { paddingX: 30, height: 55, width: 175 }
          }
        ]
      };
      Modal.show({
        customIcon: (
          <Image
            accessibilityLabel="error-plug"
            style={{ width: 83, height: 51 }}
            source={{ uri: errorPlug }}
          />
        ),
        backdrop: { skipBackdropClose: true },
        title: 'Unable to find an available provider at this time',
        description: 'Please select another provider or tray again later.',
        buttons,
        options: {
          onClose: (): void => {
            onVisitFinished(visit);
          }
        }
      });
    }, time * 60 * 1000);
  };

  useEffect(() => {
    if (visit?.canTransfer || visit?.suggestedProviderForTransfer) {
      setTransfering(false);
      setShowTransfer(true);
      setIsChatWindowVisible(false);
    } else if (visit?.finished) {
      const shouldEndVisit = handleEndReasons();

      if (shouldEndVisit) {
        setShowTransfer(false);
        setTransfering(false);
        if (visit?.endReason === EndReason.WAITING_ROOM_EXPIRED) {
          providerOfflineError(1);
        } else {
          onVisitFinished(visit);
        }
      }
    }
    if (visit?.assignedProvider.visibility === 'OFFLINE' && !visitTimer) {
      providerOfflineError(20);
    } else if (visit?.assignedProvider.visibility !== 'OFFLINE' && visitTimer) {
      clearTimeout(visitTimer);
      visitTimer = 0;
    }
  }, [visit]);

  useEffect(() => {
    setIsChatWindowVisible(chatItemsLength > 0);
  }, [chatItemsLength, visit?.id.persistentId]);

  useEffect(() => {
    if (error) {
      create(
        waitingRoomConfirmations.VISIT_ERROR.subtitle,
        waitingRoomConfirmations.VISIT_ERROR.severity
      );
      setTransfering(false);
    }
  }, [error]);

  const onVisitCancel = (cancelingVisit?: Visit | null) => {
    cancelingVisit = cancelingVisit || visit;

    if (!cancelingVisit?.finished) {
      dispatch(cancelVisit({ visit: cancelingVisit }));
    }
  };

  const onVisitTransfer = (transferVisit: Visit, isQuickTransfer: boolean) => {
    if (isQuickTransfer) {
      dispatch(startVisit({ visit: transferVisit })).then((res: AnyAction) => {
        if (res.payload) {
          dispatch(processVisit({ visit: res.payload }));
        } else {
          create(
            waitingRoomConfirmations.START_ERROR.subtitle,
            waitingRoomConfirmations.START_ERROR.severity
          );
          onVisitFinished(transferVisit);
        }
      });
    } else {
      history.push('/u/get-care-now/connect-care/form/your-visit');
    }
  };

  const onVisitFinished = (endVisit: Visit) => {
    history.replace('/u/dashboard'); // Replace before navigating elsewhere to prevent returning here with browser back button
    if (isVisitSummaryDisposition(endVisit)) {
      history.push('/u/get-care-now/connect-care/form/visit-summary');
    } else {
      history.push('/u/get-care-now/connect-care');
    }
  };

  return (
    <FormScreen>
      <MuiContainer maxWidth="lg">
        <MuiBox my={3}>
          <MuiGrid container spacing={3}>
            <MuiGrid item xs={12}>
              {visit?.assignedProvider ? (
                <ConnectCareWaitingRoomProviderDetails
                  baseCost={visit?.cost?.baseCost}
                  provider={visit?.assignedProvider}
                />
              ) : null}
            </MuiGrid>
          </MuiGrid>
        </MuiBox>
      </MuiContainer>
      {isChatWindowVisible ? (
        <ConnectCareWaitingRoomChatWindow
          handleClose={() => setIsChatWindowVisible(false)}
          chatReport={visit?.chatReport}
        />
      ) : null}
      <ConnectCareWaitingRoomTransferDialog
        visit={visit}
        open={showTransfer}
        transfering={transfering}
        setTransfering={setTransfering}
        onVisitCancel={onVisitCancel}
        onVisitTransfer={onVisitTransfer}
        onClose={() => {
          setTransfering(false);
          setShowTransfer(false);
        }}
      />
      <RouteLeavingGuard
        shouldBlockNavigation={() => showTransfer && !transfering}
        exitTitle="Unable to navigate back at this stage"
        exitSubtitle="Please finalize your session"
        navigate={() => null}
        hideExitButton
      />
      <ConnectCareTelehealthSetup
        open={showTelehealthSetup}
        onVisitCancel={onVisitCancel}
        onClose={() => setShowTelehealthSetup(false)}
      />
      <ConnectCareWebRTC open={showWebRTC} />
    </FormScreen>
  );
}

const mapStateToProps = (state: RootState) => ({
  // visit selectors
  visit: selectors.visitDataSelector(state),
  error: selectors.visitStartErrorSelector(state),
  visitType: selectors.visitTypeSelector(state),
  visitStep: selectors.visitStepSelector(state),
  currentUrl: currentLocationPathNameSelector(state),
  referringUrl: previousLocationPathNameSelector(state)
});

export default connect(mapStateToProps)(ConnectCareWaitingRoom);
