import Box from 'components/UI/Layout/Box';
import FlexBox from 'components/UI/Layout/FlexBox';
import Svg from 'components/UI/Svg/Svg';
import Typography from 'components/UI/Typography';
import BlueDot from 'components/common/BlueDot';
import { Color } from 'modules/styles/colors';
import { FontSize, IconSize, Spacing } from 'modules/styles/variables';
import { LinkTo } from 'modules/types/common';
import { convertToLowerKabobCase } from 'modules/utils/StringUtils';
import React from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import {
  ListItemProps,
  MuiListItem,
  MuiListItemIcon,
  MuiListItemSecondaryAction,
  MuiListItemText
} from 'theme/material-ui';
import { TypographyProps } from '../Typography/styled';

interface Props {
  title: string | React.ReactNode;
  titleProps?: TypographyProps;
  icon?: string;
  iconColor?: string;
  iconSize?: string | number;
  iconSet?: 'assets' | 'material' | 'ionic' | 'downloading' | 'web';
  body?: string | React.ReactNode;
  secondaryBody?: React.ReactNode;
  secondaryStatus?: React.ReactNode;
  action?: React.ReactNode;
  statusText?: string;
  statusColor?: string;
  onPress?: () => void;
  to?: string | LinkTo;
  testID?: string;
  rightItem?: React.ReactNode;
  rightIcon?: string;
  leftIcon?: string;
  leftIconSet?: 'assets' | 'material' | 'ionic' | 'downloading' | 'web';
  hasBottomBorder?: boolean;
  disabled?: boolean;
  showUnread?: boolean;
  accessibilityLabel?: string;
  accessible?: boolean;
  compact?: boolean;
  style?: object;
}

const StyledMuiListItem = styled(MuiListItem)<
  { icon: string; rightIcon: string; transparent: boolean } & ListItemProps
>`
  ${props =>
    props.transparent &&
    css`
      background-color: black;
      box-shadow: none;
    `}
  ${props =>
    props.disabled &&
    css`
      opacity: 0.3;
    `}


  &:not(:last-child) {
    border-bottom: 1px solid ${Color.grayLight};
  }
`;

const StyledFlexBox = styled(FlexBox)`
  flex: 1 1 90%;
`;

// TODO tech debt: investigate consolidating icon and rightIcon into one prop
export const ListItem = ({
  title,
  titleProps,
  body,
  icon,
  rightIcon,
  leftIcon,
  leftIconSet,
  iconColor,
  iconSize,
  iconSet,
  onPress,
  showUnread,
  accessibilityLabel,
  to,
  action,
  secondaryBody,
  testID,
  disabled,
  style
}: Props) => {
  const listItemProps = {
    button: !!to || !!onPress,
    onClick: onPress,
    component: to ? Link : undefined,
    to
  };

  const renderComponentOrTypography = (content: React.ReactNode | string, props?: any) => {
    if (!content) {
      return null;
    }

    if (typeof content === 'string') {
      return <Typography {...props}>{content}</Typography>;
    }

    return content;
  };

  return (
    <StyledMuiListItem
      data-testid={testID || convertToLowerKabobCase(title?.toString())}
      {...listItemProps}
      accessibility-label={accessibilityLabel}
      disabled={disabled}
      style={style}
    >
      {showUnread ? <BlueDot hOffsetRight={Spacing.large} /> : null}
      <StyledFlexBox flexDirection="row" alignItems="center" width="100%">
        {leftIcon ? (
          <FlexBox width={40} alignItems="center">
            <Svg
              set={leftIconSet || 'assets'}
              name={leftIcon}
              size={iconSize || IconSize.base}
              fill={iconColor || Color.black}
            />
          </FlexBox>
        ) : null}
        <FlexBox flexDirection="column">
          <MuiListItemText
            primary={title}
            primaryTypographyProps={{
              style: {
                color: Color.textDark,
                ...titleProps
              }
            }}
            secondary={body}
            secondaryTypographyProps={{ style: { fontSize: FontSize.base, color: Color.textDark } }}
          />
          {secondaryBody && (
            <Box vOffsetTop={Spacing.small}>{renderComponentOrTypography(secondaryBody)}</Box>
          )}
        </FlexBox>
      </StyledFlexBox>
      <FlexBox alignItems="flex-end" flex={1}>
        {action ? (
          <MuiListItemSecondaryAction style={{ position: 'relative', transform: 'none' }}>
            {action}
          </MuiListItemSecondaryAction>
        ) : null}

        {icon ? (
          <MuiListItemIcon>
            <Svg
              set={iconSet || 'assets'}
              name={icon}
              size={iconSize || IconSize.base}
              fill={iconColor || Color.black}
            />
          </MuiListItemIcon>
        ) : null}
        {rightIcon ? (
          <Svg
            set="material"
            name={rightIcon}
            size={iconSize || IconSize.base}
            fill={iconColor || Color.black}
          />
        ) : null}
      </FlexBox>
    </StyledMuiListItem>
  );
};
