import React from 'react';
import { connect } from 'react-redux';
import Config from 'react-native-config';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';

import { RootState } from 'store/types';
import { faAssetsSelector } from 'store/billing/financialAssistance/createApplication/selectors';

import { ASSETS_INFO } from '../constants';
import { Section } from './types';
import { convertToLowerKabobCase } from 'modules/utils/StringUtils';
import { MuiButton } from 'theme/material-ui';

const Assets = ({ navigateToEdit, formData }: Section) => {
  return (
    <Box>
      <Box position="absolute" top="-10px" right="-7px" zIndex={1}>
        <MuiButton color="primary" onClick={navigateToEdit} startIcon={<EditIcon />}>
          Edit
        </MuiButton>
      </Box>
      <Box>
        <Typography variant="h6">
          {Config.FA_SHOW_ASSETS_AND_ASSISTANCE === 'enabled' ? 'Assets' : 'Other Funding'}
        </Typography>
      </Box>
      {ASSETS_INFO.map(item => {
        const isChecked = () => {
          if (formData.checked && formData.checked.includes(item.field)) {
            return 'Yes';
          }
          return null;
        };
        let fieldValue = formData[item.field];
        if (formData.checked && !fieldValue) {
          fieldValue = isChecked();
        }
        if (fieldValue) {
          return (
            <Box py={1} key={item.field}>
              <Typography>{item.label}</Typography>
              <Typography data-testid={convertToLowerKabobCase(item.label, '-text')}>
                <b>{fieldValue === 'Y' ? 'Yes' : fieldValue}</b>
              </Typography>
            </Box>
          );
        }
        return null;
      })}
    </Box>
  );
};

const mapStateToProps = (state: RootState) => ({
  formData: faAssetsSelector(state)
});
export default connect(mapStateToProps)(Assets);
