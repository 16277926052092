//
//  Component: ProviderSearch
//
//  Description:
//  <ProviderSearch /> is the root component for provider search.
//  <ProviderSearch /> leverages useProviderSearch as a single source of truth for anything related to the search
//
//  Props:
//  termsAccepted: boolean;
//  terms?: TermsAndConditionsResponse;
//  acceptTermsAndConditions: typeof searchActions.acceptSearchTermConditions;
//

import React, { useState, useMemo, useEffect } from 'react';
import { oc } from 'ts-optchain';
import { RouteComponentProps, StaticContext } from 'react-router';
import { connect } from 'react-redux';
import { RootState } from 'store/types';
import { hasLength } from 'modules/utils/ArrayUtils';
import { Color } from 'modules/styles/colors';
import { useProviderSearch } from 'lib/hooks/ProviderSearch/useProviderSearch';
import { FiltersKey } from 'lib/hooks/ProviderSearch/types';

import * as globalSelectors from 'store/global/selectors';
import * as searchSelectors from 'store/providerSearch/selectors';
import * as searchActions from 'store/providerSearch/actions';
import { TermsAndConditionsResponse } from 'store/global/reducers';
import { ProviderSearchOptions } from 'store/providerSearch/types';
import { sortBy } from 'store/providerSearch/constants';
import { MuiBox, MuiTypography, MuiContainer } from 'theme/material-ui';
import { Container, CountCircle } from './styled';
import ProviderSearchBar from './ProviderSearchBar';
import ProviderSearchFilters from './ProviderSearchFilters';
import ProviderSearchTerms from './ProviderSearchTerms';
import ProviderSearchList from './ProviderSearchList';
import { providerSearch } from './utils';
import { ActionHeaderButton } from 'screens/Booking/components/styled';
import { BorderRadius, FontWeight, IconSize, Spacing } from 'modules/styles/variables';
import { Svg } from 'components/UI/Svg';

interface RouteState {
  initialOptions?: Partial<ProviderSearchOptions>;
  initialQuery?: string;
  expandedFilters?: FiltersKey[];
}

export interface Props extends RouteComponentProps<{}, StaticContext, RouteState> {
  terms?: TermsAndConditionsResponse;
  acceptTermsAndConditions: typeof searchActions.acceptSearchTermConditions;
  setSearchTermsShown: typeof searchActions.setSearchTermsShown;
  termsAccepted: boolean;
  termsShown: boolean;
}

function countFilters(options: ProviderSearchOptions) {
  let count = 0;
  if (options.locationText) {
    count += 1;
  }
  if (options.filters) {
    options.filters.forEach(({ value }) => {
      if (value) {
        count += 1;
      }
    });
  }
  if (options.facets) {
    options.facets.forEach(() => {
      count += 1;
    });
  }
  return count;
}

export const ProviderSearch = ({
  history,
  terms,
  termsAccepted,
  termsShown,
  acceptTermsAndConditions,
  setSearchTermsShown
}: Props) => {
  const routeState = oc(history).location.state({});
  const expandedFilters = oc(routeState).expandedFilters([]);
  const initialQuery = oc(routeState).initialQuery('');
  const initialOptions = oc(routeState).initialOptions({});

  const {
    change,
    search,
    query,
    options,
    fetching,
    searched,
    result,
    reset,
    error,
    suggestions,
    quickLinks
  } = useProviderSearch(false, initialQuery, initialOptions);

  const [showFilters, setShowFilters] = useState(false);
  const termsOpen = !termsAccepted && !termsShown;

  const filtersCount = useMemo(() => {
    return countFilters(options);
  }, [options]);

  useEffect(() => {
    if (hasLength(expandedFilters)) {
      setShowFilters(true);
    }
  }, [expandedFilters]);

  return (
    <Container>
      <MuiBox
        display="flex"
        flexDirection="row"
        bgcolor={Color.grayLight2}
        alignItems="center"
        mb={3}
      >
        <MuiBox flex={1} py={1.75}>
          <MuiContainer>
            <ProviderSearchBar
              change={change}
              search={params => providerSearch({ from: 'Find Provider', search, params })}
              value={query}
              suggestions={suggestions}
              quickLinks={quickLinks}
            />
          </MuiContainer>
        </MuiBox>

        <ActionHeaderButton
          data-testid="filters-button"
          borderRadius={BorderRadius.base}
          hasCount={!!filtersCount}
          onClick={() => setShowFilters(true)}
          style={{ marginRight: Spacing.large }}
          endIcon={<Svg color={Color.secondary} name="ChevronDown" size={IconSize.base} />}
          startIcon={
            filtersCount ? <CountCircle data-testid="count-icon">{filtersCount}</CountCircle> : null
          }
        >
          <MuiTypography fontWeight={FontWeight.semibold} color={Color.secondary}>
            Filter
          </MuiTypography>
        </ActionHeaderButton>
      </MuiBox>
      <ProviderSearchList
        providers={result}
        fetching={fetching}
        search={search}
        options={options}
        error={error}
        searched={searched}
      />
      <ProviderSearchFilters
        open={showFilters}
        onClose={() => setShowFilters(false)}
        defaultExpandedFilters={expandedFilters}
        search={search}
        filtersCount={filtersCount}
        defaultOptions={options}
      />
      <ProviderSearchTerms
        open={termsOpen}
        handleDecline={() => {
          setSearchTermsShown();
        }}
        handleAccept={() => {
          setSearchTermsShown();
          acceptTermsAndConditions({ terms });
          if (searched) {
            search({ orderBy: sortBy.BEST_MATCH.value });
          } else {
            reset({ orderBy: sortBy.BEST_MATCH.value });
          }
        }}
        content={terms?.content}
      />
    </Container>
  );
};

const mapStateToProps = (state: RootState) => ({
  terms: globalSelectors.providerSearchTermsSelector(state),
  termsAccepted: searchSelectors.searchTermsAndConditionsAccepted(state),
  termsShown: searchSelectors.searchTermsShownSelector(state)
});

const mapDispatchToProps = {
  acceptTermsAndConditions: searchActions.acceptSearchTermConditions,
  setSearchTermsShown: searchActions.setSearchTermsShown
};

export default connect(mapStateToProps, mapDispatchToProps)(ProviderSearch);
