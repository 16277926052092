import React from 'react';

import { SvgProps } from 'components/UI/Svg/Svg';
import { Color } from 'modules/styles/colors';
import { SNavStyles } from './index';
import { getFillColor, getStrokeColor } from './utils';

export default (props: SvgProps | undefined) => {
  const getFill = getFillColor(
    props?.active,
    props?.activeFillColor,
    props?.disabled,
    props?.disabledColor,
    props?.color
  );
  const getStroke = getStrokeColor(
    props?.active,
    props?.activeStrokeColor,
    props?.disabled,
    props?.disabledColor,
    props?.color
  );

  return {
    svg: (
      <g>
        <g {...SNavStyles} strokeWidth="2px" fill={getFill} stroke={getStroke}>
          <path d="M29.8,31H6.2V7.1h23.6v23.3C29.8,31.2,29.8,31,29.8,31z" />
          <line x1="6.2" y1="12.4" x2="29.8" y2="12.4" />
        </g>
        <g
          {...SNavStyles}
          fill={getFillColor(
            props?.active,
            Color.white,
            props?.disabled,
            Color.grayHue5,
            Color.graySidebarIcon
          )}
          strokeWidth={props?.active ? '0px' : '.5px'}
          stroke={getStroke}
        >
          <circle cx="12.3" cy="16.4" r="1.5" />
          <circle cx="12.3" cy="21.7" r="1.5" />
          <circle cx="12.3" cy="27.1" r="1.5" />
          <circle cx="18" cy="16.4" r="1.5" />
          <circle cx="18" cy="21.7" r="1.5" />
          <circle cx="18" cy="27.1" r="1.5" />
          <circle cx="23.6" cy="16.4" r="1.5" />
          <circle cx="23.6" cy="21.7" r="1.5" />
          <circle cx="23.6" cy="27.1" r="1.5" />
        </g>
        <path
          {...SNavStyles}
          fill={getFillColor(
            props?.active,
            Color.white,
            props?.disabled,
            props?.disabledColor,
            props?.color
          )}
          d="M29.1,13H6.9c-0.2,0-0.3-0.1-0.3-0.3V8c0-0.2,0.1-0.3,0.3-0.3h22.2c0.2,0,0.3,0.1,0.3,0.3v4.7
            C29.4,12.9,29.3,13,29.1,13z"
        />
        <g {...SNavStyles} strokeWidth="2px" stroke={getStroke}>
          <line x1="25.7" y1="4.6" x2="25.7" y2="9.5" />
          <line x1="10.3" y1="4.6" x2="10.3" y2="9.5" />
        </g>
      </g>
    ),
    viewBox: '0 0 36 36'
  };
};
