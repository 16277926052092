import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Bg9 from 'assets/DashboardBanner/desktop/bg_9_2.jpg';
import Svg from 'components/UI/Svg/Svg';
import { Color } from 'modules/styles/colors';
import React from 'react';
import { MuiBox } from 'theme/material-ui';
import useBillingBannerComponents from 'screens/Billing/BillingHome/PaperStatementBillpayComponents/useBillingBannerComponents';
import { BillboardContainer, BillboardTitle, SubMessage } from './styled';

export interface BannerProps {
  message: string | React.ReactNode;
  subMessage?: string | React.ReactNode;
  align?: 'center' | 'flex-start' | 'space-between';
  bannerComponent?: React.ComponentType<any>;
  componentName?: string;
  display?: 'block' | 'flex';
  icon?: string | null;
  leftItem?: React.ReactNode;
  rightItem?: React.ReactNode;
  extraBannerPadding?: boolean;
  title?: string;
  bannerRef?: any;
  bgUrl?: string;
  height?: number;
}

const Banner = (props: BannerProps) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const setDisplay = () => {
    return props.display || 'flex';
  };

  const setAlignment = () => {
    return props.align || 'flex-start';
  };

  const renderBannerComponent = () => {
    if (!props.bannerComponent) return null;
    const { bannerComponent: BannerComponent, ...rest } = props;

    return (
      <MuiBox flexDirection="row" justifyContent="center" pb={4} width="100%">
        <BannerComponent {...rest} />
      </MuiBox>
    );
  };

  const renderMessage = () => (
    <MuiBox display="flex" flexDirection="row" justifyContent={setAlignment()} alignItems="center">
      {props.leftItem ? props.leftItem : null}

      {props.icon ? (
        <MuiBox alignSelf="center" pr={2}>
          <Svg name={props.icon} height="40" width="40" fill={Color.foreColor} />
        </MuiBox>
      ) : null}
      <BillboardTitle style={props.leftItem ? { marginLeft: 10, marginTop: 0 } : {}}>
        {props.message}
      </BillboardTitle>

      {props.rightItem ? props.rightItem : null}
    </MuiBox>
  );

  const renderSubMessage = () => {
    if (!props.subMessage) return null;

    if (typeof props.subMessage === 'string') {
      return (
        <SubMessage padding={props.title === 'Prediabetes 101' ? 0 : 25}>
          {props.subMessage}
        </SubMessage>
      );
    }

    return props.subMessage;
  };

  const { VariantA, VariantB } = useBillingBannerComponents(props.componentName);

  return (
    <>
      <VariantA />
      <MuiBox
        display={setDisplay()}
        data-testid="banner"
        flexBasis="auto"
        flexGrow="0"
        flexShrink="0"
        ref={props.bannerRef}
      >
        <BillboardContainer
          bgUrl={props.componentName === 'GetCareHomeScreen' ? Bg9 : undefined}
          height={props.componentName === 'GetCareHomeScreen' ? 220 : undefined}
        >
          <MuiBox px={isSmallScreen ? 2 : 4} py={props.extraBannerPadding ? 3 : 0} width="100%">
            {renderMessage()}
            {renderSubMessage()}
            {renderBannerComponent()}
          </MuiBox>
        </BillboardContainer>
      </MuiBox>
      <VariantB />
    </>
  );
};

export default Banner;
