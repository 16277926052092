import Spinner from 'components/UI/Spinner/Spinner';
import Svg from 'components/UI/Svg/Svg';
import capitalize from 'lodash/capitalize';
import isEmpty from 'lodash/isEmpty';
import { Color } from 'modules/styles/colors';
import { IconSize } from 'modules/styles/variables';
import { MhpDetails } from 'modules/types/common';
import { objectToQsParam } from 'modules/utils/UrlUtils';
import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AnalyticsEvent } from 'services/AnalyticsService';
import useNavigationAnalytics from 'hooks/useNavigationAnalytics';
import { currentLocationPathNameSelector } from 'store/router/selectors';
import {
  askMedicationsQuestion,
  downloadMedications,
  getMedications
} from 'store/medications/actions';
import {
  medicationsDataSelector,
  medicationsDownloadErrorSelector,
  medicationsDownloadingSelector,
  medicationsErrorSelector,
  medicationsLoadingSelector
} from 'store/medications/selectors';
import { MuiBox, MuiContainer, MuiPaper, MuiTypography, MuiListItem } from 'theme/material-ui';
import { HEALTH_RECORDS_PAGES } from 'lib/constants/healthRecords';
import { Medication, MedicationType } from 'store/medications/types';
import { useHistory } from 'react-router';
import dayjs from 'dayjs';
import Banner from 'screens/MedicalHistory/Banner';
import { FlexBoxRow } from 'components/UI/Layout/FlexBox';
import DownloadErrorSnackbar from 'components/DownloadErrorSnackbar/DownloadErrorSnackbar';

export interface Props {
  medicationType?: MedicationType;
}

export const formatMedication = (m: Medication) => ({
  id: m.id,
  name: m.name,
  date: dayjs(m.dateWritten).format('MM/DD/YYYY'),
  mhpDetails: m.mhpDetails
});

export const Medications = ({ medicationType }: Props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const medications = useSelector(medicationsDataSelector);
  const error = useSelector(medicationsErrorSelector);
  const loading = useSelector(medicationsLoadingSelector);
  const medicationsDownloads = useSelector(medicationsDownloadingSelector);
  const downloadError = useSelector(medicationsDownloadErrorSelector);
  const currentUrl = useSelector(currentLocationPathNameSelector);
  const previousItemClicked = useNavigationAnalytics(AnalyticsEvent.PreviousMedicationItemClicked);
  const currentItemClicked = useNavigationAnalytics(AnalyticsEvent.CurrentMedicationItemClicked);

  useEffect(() => {
    dispatch(getMedications(true));
  }, [dispatch]);

  const currentMedicationsListDownloadClicked = useNavigationAnalytics(
    AnalyticsEvent.CurrentMedicationsListDownloadClicked
  );
  const pastMedicationsListDownloadClicked = useNavigationAnalytics(
    AnalyticsEvent.PastMedicationsListDownloadClicked
  );
  const currentMedicationsListShareClicked = useNavigationAnalytics(
    AnalyticsEvent.CurrentMedicationsListShareClicked
  );

  const pastMedicationsListShareClicked = useNavigationAnalytics(
    AnalyticsEvent.PastMedicationsListShareClicked
  );

  const filteredMedications = useMemo(() => {
    if (medicationType) {
      return medications
        .filter((m: Medication) => m.type.toLowerCase() === medicationType)
        .map(formatMedication);
    }

    return medications.map(formatMedication);
  }, [medicationType, medications]);

  const handleMedicationSelect = (item: { id: string; mhpDetails?: MhpDetails }) => {
    let url = `/u/health-record/${medicationType}-medications/medications/${item.id}`;
    if (!isEmpty(item.mhpDetails)) {
      url += `/${objectToQsParam(item.mhpDetails)}`;
    }
    history.push(url);

    if (medicationType === 'past') {
      previousItemClicked();
    } else {
      currentItemClicked();
    }
  };

  const handleShareMedications = () => {
    if (medicationType === 'current') {
      currentMedicationsListShareClicked();
    } else {
      pastMedicationsListShareClicked();
    }
    dispatch(askMedicationsQuestion({ medications }, false, currentUrl));
  };

  const handleDownloadMedications = () => {
    if (medicationType === 'current') {
      currentMedicationsListDownloadClicked();
    } else {
      pastMedicationsListDownloadClicked();
    }
    dispatch(downloadMedications());
  };

  const getPlaceHolder = () => {
    let message: string | undefined;
    if (loading) {
      message = HEALTH_RECORDS_PAGES.Medications.loading;
    } else if (error) {
      message = HEALTH_RECORDS_PAGES.Medications.error;
    } else if (filteredMedications.length === 0) {
      message = HEALTH_RECORDS_PAGES.Medications.empty;
    }

    return (
      <MuiBox pt={3} width="100%">
        <MuiTypography align="center">{message}</MuiTypography>
        {loading ? (
          <MuiBox p={3} display="flex" flexDirection="row" justifyContent="center">
            <Spinner />
          </MuiBox>
        ) : null}
      </MuiBox>
    );
  };

  return (
    <>
      {loading || error || filteredMedications.length === 0 ? (
        getPlaceHolder()
      ) : (
        <>
          <DownloadErrorSnackbar hasError={!!downloadError} onRetry={handleDownloadMedications} />
          <Banner
            justifyContent="flex-end"
            isDownloading={medicationsDownloads}
            onDownloadPress={handleDownloadMedications}
            onTransmitPress={handleShareMedications}
          />
          <MuiBox my={5}>
            <MuiContainer maxWidth="lg">
              <MuiBox width="100%">
                <MuiPaper elevation={2}>
                  <MuiListItem divider>
                    <MuiBox
                      display="flex"
                      flex="1"
                      justifyContent="space-between"
                      alignItems="center"
                      width="100%"
                    >
                      <MuiBox width="80%">
                        <MuiTypography
                          color="textPrimary"
                          fontWeight="bold"
                          data-testid="medication-name"
                        >
                          Name
                        </MuiTypography>
                      </MuiBox>

                      <MuiBox width="20%">
                        <MuiTypography
                          color="textPrimary"
                          fontWeight="bold"
                          data-testid="medication-date"
                        >
                          Date
                        </MuiTypography>
                      </MuiBox>
                    </MuiBox>
                  </MuiListItem>
                  {filteredMedications.map(m => (
                    <MuiListItem
                      key={m.id}
                      divider
                      button
                      onClick={() => handleMedicationSelect(m)}
                      data-testid="medications-list"
                    >
                      <MuiBox
                        display="flex"
                        flex="1"
                        justifyContent="space-between"
                        alignItems="center"
                        width="100%"
                        py="6px"
                      >
                        <MuiBox>
                          <MuiTypography color="textPrimary">{capitalize(m.name)}</MuiTypography>
                        </MuiBox>

                        <MuiBox>
                          <FlexBoxRow>
                            <MuiTypography color="textPrimary">{m.date}</MuiTypography>
                            <Svg
                              set="material"
                              name="keyboard-arrow-right"
                              size={IconSize.base}
                              fill={Color.black}
                            />
                          </FlexBoxRow>
                        </MuiBox>
                      </MuiBox>
                    </MuiListItem>
                  ))}
                </MuiPaper>
              </MuiBox>
            </MuiContainer>
          </MuiBox>
        </>
      )}
    </>
  );
};

export default Medications;
