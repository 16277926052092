import React, { ComponentType, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import startCase from 'lodash/startCase';
import Banner from 'components/UI/Banner/Banner';
import { Breadcrumbs } from 'components/Breadcrumbs';
import RouteLeavingGuard from 'components/UI/Modals/RouteLeavingGuardModal';
import { useRoutes } from 'hooks/useRoutes';
import { useRoutesToCrumbs } from 'hooks/useRoutesToCrumbs';
import exitScreening from 'modules/constants/covidScreening/exitScreening';
import GetCareHome from 'screens/GetCareHome/GetCareHome';
import { ComponentProps, RouteData, WithRoutes } from 'screens/Booking/router/types';
import CovidTestScreen from '../CovidPatientSelect';
import CovidPersonalInfoScreen from '../PersonalInformation/PersonalInformation';
import CovidCurrentSymptomsScreen from '../CurrentSymptoms/CurrentSymptoms';
import CovidAgreement from '../ConsentForms/CovidAgreement';
import CovidReasons from '../ConsentForms/CovidReasons';
import CovidInsurance from '../CovidInsurance';
import CovidExistingInsurance from '../CovidExistingInsurance';
import CovidInsuranceSelect from '../CovidInsuranceSelect';
import CovidScreenSummary from '../CovidScreenSummary';
import CovidConfirmation from '../OrderConfirmation';
import CovidErrorComponent from '../OrderStatus';
import { RootState } from 'store/types';
import * as covidActions from 'store/CovidScreening/actions';
import * as bookingActions from 'store/booking/actions';
import * as bookingSelectors from 'store/booking/selectors';
import * as bookingTypes from 'store/booking/types';

import { connect } from 'react-redux';
import { CovidTitles } from 'lib/constants/covidScreening';
import { AnalyticsEvent } from 'services/AnalyticsService';
import useNavigationAnalytics from 'hooks/useNavigationAnalytics';
import { privateRoutes } from 'screens/navigation';

const TITLE = 'Covid-19 Test Screening';
import CovidSubscriberInfoScreen from '../SubscriberInformation';
import CovidScreeningInsuranceUpload from '../CovidScreeningInsuranceUpload';
import CovidNewInsuranceFound from '../CovidNewInsuranceFound';
import InsuranceInformation from '../InsuranceInformation';
import { History } from 'lib/history';

interface Match {
  path: string;
  params?: {
    title?: string;
  };
}

interface Props {
  match: Match;
  history: History;
  appointmentDetails: bookingTypes.AppointmentDetails;
  resetCovidScreening: typeof covidActions.resetCovidScreening;
  clearAppointmentDetails: typeof bookingActions.clearAppointmentDetails;
}

export const covidTestScreenRoutes: RouteData[] = [
  {
    path: '/patient-select',
    component: CovidTestScreen,
    componentName: 'CovidTestScreen',
    title: TITLE,
    crumbLabel: TITLE,
    deps: []
  },
  {
    path: '/consent-and-agreement',
    component: CovidAgreement,
    componentName: 'CovidAgreement',
    title: TITLE,
    crumbLabel: TITLE,
    deps: []
  },
  {
    path: '/covid-reasons',
    component: CovidReasons,
    componentName: 'CovidReasons',
    title: TITLE,
    crumbLabel: TITLE,
    deps: []
  },
  {
    path: '/personal-information',
    component: CovidPersonalInfoScreen,
    componentName: 'CovidPersonalInfoScreen',
    title: TITLE,
    crumbLabel: TITLE,
    deps: []
  },
  {
    path: '/covid-insurance',
    component: CovidInsurance,
    componentName: 'CovidInsurance',
    title: TITLE,
    crumbLabel: TITLE,
    deps: []
  },
  {
    path: '/covid-existing-insurance',
    component: CovidExistingInsurance,
    componentName: 'CovidExistingInsurance',
    title: TITLE,
    crumbLabel: TITLE,
    deps: []
  },
  {
    path: '/insurance-select',
    component: CovidInsuranceSelect,
    componentName: 'CovidInsuranceSelect',
    title: TITLE,
    crumbLabel: TITLE,
    deps: []
  },
  {
    path: '/summary-information',
    component: CovidScreenSummary,
    componentName: 'CovidScreenSummary',
    title: TITLE,
    crumbLabel: TITLE,
    deps: []
  },
  {
    path: '/covid-insurance-upload',
    component: CovidScreeningInsuranceUpload,
    componentName: 'CovidScreeningInsuranceUpload',
    title: 'Covid-19 Test Screening',
    crumbLabel: 'Covid-19 Test Screening',
    deps: []
  },
  {
    path: '/subscriber-information',
    component: CovidSubscriberInfoScreen,
    componentName: 'CovidSubscriberInfoScreen',
    title: 'Covid-19 Test Screening',
    crumbLabel: 'Covid-19 Test Screening',
    deps: []
  },
  {
    path: '/new-insurance-found',
    component: CovidNewInsuranceFound,
    componentName: 'CovidNewInsuranceFound',
    title: 'Covid-19 Test Screening',
    crumbLabel: 'Covid-19 Test Screening',
    deps: []
  },
  {
    path: '/current-symptoms',
    component: CovidCurrentSymptomsScreen,
    componentName: 'CovidCurrentSymptomsScreen',
    title: 'Covid-19 Test Screening',
    crumbLabel: 'Covid-19 Test Screening',
    deps: []
  },
  {
    path: '/insurance-information',
    component: InsuranceInformation,
    componentName: 'InsuranceInformation',
    title: 'Covid-19 Test Screening',
    crumbLabel: 'Covid-19 Test Screening',
    deps: []
  },
  {
    path: '/order-confirmation',
    component: CovidConfirmation,
    componentName: 'OrderConfirmation',
    title: TITLE,
    crumbLabel: TITLE,
    deps: []
  },
  {
    path: '/order-status',
    component: CovidErrorComponent,
    componentName: 'OrderStatus',
    title: TITLE,
    crumbLabel: TITLE,
    deps: []
  }
];

const isRouter = (r: Partial<RouteData>) => r.componentName?.includes('Router');
const filterRouter = (routes: RouteData[]) => routes.filter(r => !isRouter(r));

const CovidTestScreenComponent = ({
  component: Component,
  routes = [],
  stepRoutes = [],
  ...rest
}: ComponentProps) => {
  const crumbs = useRoutesToCrumbs(filterRouter(routes));
  const activeStep = stepRoutes.findIndex((s: RouteData) => s.path === rest.location.pathname);

  return (
    <>
      {rest.includeCrumbs ? <Breadcrumbs crumbs={crumbs} /> : null}
      {rest.includeBanner ? (
        <Banner
          message={rest.match.params.title ? startCase(rest.match.params.title) : rest.title}
          subMessage={rest.subTitle}
          icon={rest.icon || null}
          {...rest}
        />
      ) : null}
      <Component routes={routes} steps={stepRoutes} activeStep={activeStep} {...rest} />
    </>
  );
};

const CovidTestScreenRoute = ({ exact, path, ...rest }: WithRoutes<RouteData>) => {
  return (
    <Route
      exact={exact}
      path={path}
      render={props => <CovidTestScreenComponent {...rest} {...props} />}
    />
  );
};

const CovidTestScreenRouter = (props: WithRoutes) => {
  const routes = useRoutes(covidTestScreenRoutes);
  const stepRoutes = routes.filter(r => r.includeStepper !== false);

  return (
    <Switch>
      {routes.map(r => (
        <CovidTestScreenRoute
          key={r.path}
          stepRoutes={stepRoutes}
          routes={routes.concat(props.routes)}
          includeBanner={r.includeBanner || true}
          includeCrumbs={r.includeCrumbs || true}
          {...r}
        />
      ))}
      <Redirect to={`${props.match.path}/patient-select`} />
    </Switch>
  );
};

const covidScreenRoutes: RouteData[] = [
  {
    path: '/',
    component: CovidTestScreenRouter,
    componentName: 'CovidTestScreenRouter',
    includeCrumbs: false,
    includeBanner: false
  }
];

const getCareRoute: RouteData = {
  exact: true,
  path: '/u/get-care-now',
  component: GetCareHome,
  crumbLabel: 'Get Care'
};

// Todo: Handle this login with object key(route) -> value(pageName).
const getPageName = (route: string): string => {
  const path = '/u/get-care-now';

  switch (route) {
    case `${path}/covid-screen/patient-select`:
      return CovidTitles.PATIENT_SELECT;
    case `${path}/covid-screen/consent-and-agreement`:
      return CovidTitles.CONSENT_AND_AGREEMENTS;
    case `${path}/covid-screen/covid-reasons`:
      return CovidTitles.REASON_FOR_REQUEST;
    case `${path}/covid-screen/current-symptoms`:
      return CovidTitles.SYMPTOM_INFORMATION;
    case `${path}/covid-screen/personal-information`:
      return CovidTitles.PERSONAL_INFORMATION;
    case `${path}/covid-screen/covid-insurance`:
      return CovidTitles.SCREENING_INSURANCE;
    case `${path}/covid-screen/covid-existing-insurance`:
      return CovidTitles.EXISTING_INSURANCE_FOUND;
    case `${path}/covid-screen/insurance-select`:
      return CovidTitles.INSURANCE_SELECT;
    case `${path}/covid-screen/summary-information`:
      return CovidTitles.SUMMARY_INFORMATION;
    case `${path}/covid-screen/covid-insurance-upload`:
      return CovidTitles.INSURANCE_CARD_UPLOAD;
    case `${path}/covid-screen/subscriber-information`:
      return CovidTitles.SUBSCRIBER_INFO;
    case `${path}/covid-screen/new-insurance-found`:
      return CovidTitles.NEW_INSURANCE_FOUND;
    case `${path}/covid-screen/insurance-information`:
      return CovidTitles.INSURANCE_INFORMATION;
    case `${path}/covid-screen/order-confirmation`:
      return CovidTitles.ORDER_CONFIRMATION;
    case `${path}/covid-screen/order-status`:
      return CovidTitles.ORDER_STATUS;
    default:
      return 'COVID-19 Test Screening';
  }
};

const hasSpecialtySelected = (appointmentDetails: bookingTypes.AppointmentDetails) =>
  appointmentDetails.specialty?.nameLong;

const CovidScreenRouter = ({
  match,
  history,
  appointmentDetails,
  resetCovidScreening,
  clearAppointmentDetails
}: Props) => {
  let routes = useRoutes(covidScreenRoutes);
  routes = routes.concat([getCareRoute]);
  const crumbs = useRoutesToCrumbs(filterRouter(routes));
  const [isRouteGuardOpen] = useState<boolean>(false);
  const [isRouteExternal, setIsRouteExternal] = useState<string>('');
  const handleAnalyticsCovid = useNavigationAnalytics(AnalyticsEvent.CovidFlowExited);

  const handleNavigateFromCovidTestBooking = () => {
    const title = getPageName(history.location.pathname);

    const shouldNavigate = privateRoutes.some(route => route?.path.includes(isRouteExternal));

    handleAnalyticsCovid({ title });
    resetCovidScreening();

    if (hasSpecialtySelected(appointmentDetails)) {
      clearAppointmentDetails();
    }

    if (isRouteExternal) {
      setIsRouteExternal('');
      if (shouldNavigate) {
        return history.push(isRouteExternal);
      }
    }

    return history.push('/u/get-care-now');
  };

  return (
    <Switch>
      {routes.map(({ component: Component, ...r }) => (
        <Route
          key={r.path}
          path={r.path}
          exact={r.exact || false}
          render={props => (
            <>
              {r.includeCrumbs === false ? null : <Breadcrumbs crumbs={crumbs} />}
              <Component routes={routes} {...r} {...props} />
              <RouteLeavingGuard
                when={!isRouteGuardOpen}
                shouldBlockNavigation={nextLocation => {
                  if (
                    nextLocation.pathname.includes('/u/assess-my-symptoms/scout-symptom-checker')
                  ) {
                    return false;
                  }
                  if (!nextLocation.pathname.includes('get-care-now')) {
                    setIsRouteExternal(nextLocation.pathname);
                  }
                  if (nextLocation.pathname.includes('/u/get-care-now/covid-screen')) {
                    return false;
                  }

                  if (
                    nextLocation.pathname.includes('get-care-now') &&
                    history?.location?.pathname.includes('order-confirmation')
                  ) {
                    resetCovidScreening();
                    return false;
                  }
                  return true;
                }}
                exitTitle={exitScreening.COVID_SCREENING_CONFIRM_DIALOG_TEXT.title}
                exitSubtitle={exitScreening.COVID_SCREENING_CONFIRM_DIALOG_TEXT.subtitle}
                exitButtonText="Exit COVID Screening "
                keepGoingButtonText="Continue Screening"
                navigate={handleNavigateFromCovidTestBooking}
              />
            </>
          )}
        />
      ))}
      <Redirect to={`${match.path}`} />
    </Switch>
  );
};

const mapDispatchToProps = {
  resetCovidScreening: covidActions.resetCovidScreening,
  clearAppointmentDetails: bookingActions.clearAppointmentDetails
};

const mapStateToProps = (state: RootState) => ({
  appointmentDetails: bookingSelectors.appointmentDetailsSelector(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(CovidScreenRouter as ComponentType);
