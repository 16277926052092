import React from 'react';
import { resolveCSSUnit } from 'modules/utils/StylesUtils';
import Spinner from 'components/UI/Spinner/Spinner';
import svgs from 'assets/svgs';
import { Icon } from 'components/Icon';
import { IconSize } from 'modules/styles/variables';
import SvgButton from './SvgButton';

export type SvgIconName = keyof ReturnType<typeof svgs>;

export interface SvgProps {
  accessibilityLabel?: string;
  active?: boolean;
  activeFillColor?: string;
  activeStrokeColor?: string;
  noWidth?: boolean;
  noHeight?: boolean;
  noSize?: boolean;
  height?: string | number;
  width?: string | number;
  size?: string | number;
  name?: SvgIconName | string;
  stroke?: string;
  strokeWidth?: string | number;
  fill?: string;
  color?: string;
  set?: 'assets' | 'material' | 'ionic' | 'downloading' | 'web';
  children?: React.ReactChild;
  onPress?: () => void | Function;
  disabled?: boolean;
  disabledColor?: string;
  style?: React.CSSProperties;
}

const Svg = (props: SvgProps) => {
  const {
    height,
    width,
    size,
    noWidth,
    noHeight,
    noSize,
    accessibilityLabel,
    set,
    children,
    ...rest
  } = props;

  const optionalDimensions = {
    width: noWidth ? null : resolveCSSUnit(width || size || IconSize.base),
    height: noHeight ? null : resolveCSSUnit(height || size || IconSize.base),
    size: noSize ? null : resolveCSSUnit(size || IconSize.base)
  };

  const iconProps = {
    ...optionalDimensions,
    ...rest
  };

  if (set === 'downloading') {
    return <Spinner {...iconProps} />;
  }

  if (set === 'material') {
    return <Icon accessibility-label={accessibilityLabel} {...iconProps} />;
  }

  if (set === 'assets') {
    const iconSet = svgs({ ...iconProps, children });
    const iconName = props.name as keyof typeof iconSet;

    if (!props.name || !iconSet[iconName]) {
      return null;
    }

    const svgObject = iconSet[iconName];
    const { svg: svgChild, ...rest } = svgObject;

    return (
      <svg {...iconProps} {...rest} accessibility-label={accessibilityLabel}>
        {svgChild}
      </svg>
    );
  }

  if (set === 'web') {
    return (
      <div aria-label={accessibilityLabel}>
        <svg {...iconProps}>{children}</svg>
      </div>
    );
  }

  return null;
};

Svg.Button = SvgButton;

Svg.defaultProps = {
  set: 'assets'
};

export default Svg;
