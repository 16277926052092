import React from 'react';
import { Color } from 'modules/styles/colors';
import { SvgProps } from 'components/UI/Svg';

export default (props?: SvgProps) => {
  return {
    svg: (
      <svg
        width={props?.size}
        height={props?.size}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        fillRule="evenodd"
        clipRule="evenodd"
      >
        <g clipPath="url(#clip0_1689_1591)">
          <path
            d="M0 12C0 5.37259 5.37259 0 12 0C18.6274 0 24 5.37259 24 12C24 18.6274 18.6274 24 12 24C5.37259 24 0 18.6274 0 12ZM12 1.5C6.20101 1.5 1.5 6.20101 1.5 12C1.5 17.799 6.20101 22.5 12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5ZM12 4.5C12.4142 4.5 12.75 4.83579 12.75 5.25V13.5C12.75 13.9142 12.4142 14.25 12 14.25C11.5858 14.25 11.25 13.9142 11.25 13.5V5.25C11.25 4.83579 11.5858 4.5 12 4.5ZM10.875 16.875C10.875 16.2537 11.3787 15.75 12 15.75C12.6213 15.75 13.125 16.2537 13.125 16.875C13.125 17.4963 12.6213 18 12 18C11.3787 18 10.875 17.4963 10.875 16.875Z"
            fill={props?.color || Color.secondary}
          />
        </g>
        <defs>
          <clipPath id="clip0_1689_1591">
            <rect width="24" height="24" fill={Color.white} />
          </clipPath>
        </defs>
      </svg>
    )
  };
};
