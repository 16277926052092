import React, { useState, useEffect } from 'react';
import {
  MuiBox,
  MuiButton,
  MuiDialogTitle,
  MuiDivider,
  MuiGrid,
  MuiTypography,
  MuiBackdrop
} from 'theme/material-ui';
import Spinner from 'components/UI/Spinner/Spinner';
import TextInputCounter from 'components/UI/Input/TextInputCounter';
import { StyledMuiDialog } from 'screens/Booking/components/styled';

export interface CancelAppointmentDialogProps {
  open: boolean;
  loading?: boolean;
  onSubmit: (comment: string) => void;
  onClose: () => void;
}

export function CancelAppointmentDialog({
  open,
  onSubmit,
  onClose,
  loading = false
}: CancelAppointmentDialogProps) {
  const [comment, setComment] = useState('');

  useEffect(() => {
    setComment('');
  }, [open]);

  const getButtonDisabled = () => {
    return loading || !comment.length;
  };

  return (
    <StyledMuiDialog open={open} onClose={onClose} maxWidth="lg">
      <MuiDialogTitle id="wrt-title"> Cancel Appointment</MuiDialogTitle>
      <MuiBox p={2}>
        <MuiBox mb={2}>
          <MuiTypography>
            By selecting "Yes", this appointment will be cancelled. Are you sure you want to cancel?
            <span style={{ color: 'red' }}>*</span>
          </MuiTypography>
        </MuiBox>

        <MuiBox mb={3}>
          <TextInputCounter
            data-testid="cancel-appointment-reason-text-area"
            multiline
            rows={3}
            fullWidth
            value={comment}
            placeholder="Reason for cancelling appointment..."
            variant="outlined"
            maxLength={40}
            onTextChange={setComment}
            color="secondary"
          />
        </MuiBox>

        <MuiBox mb={2}>
          <MuiDivider />
        </MuiBox>

        <MuiBox>
          <MuiGrid container justify="space-between" spacing={3}>
            <MuiGrid item xs={6}>
              <MuiButton
                variant="outlined"
                fullWidth
                color="primary"
                disabled={loading}
                onClick={onClose}
              >
                No, go back
              </MuiButton>
            </MuiGrid>
            <MuiGrid item xs={6}>
              <MuiButton
                variant="contained"
                fullWidth
                disabled={getButtonDisabled()}
                color="primary"
                onClick={() => onSubmit(comment)}
                data-testid="cancel-yes-button"
              >
                Yes, cancel my appointment
              </MuiButton>
            </MuiGrid>
          </MuiGrid>
        </MuiBox>
      </MuiBox>

      <MuiBackdrop open={loading} data-testid="Cancel appointment loading">
        <Spinner />
      </MuiBackdrop>
    </StyledMuiDialog>
  );
}
