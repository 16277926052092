import Svg from 'components/UI/Svg/Svg';
import { IconSize } from 'modules/styles/variables';
import React, { useState } from 'react';
import { Color } from 'modules/styles/colors';
import {
  MuiBox,
  MuiClickAwayListener,
  MuiIconButton,
  MuiPaper,
  MuiPopover
} from 'theme/material-ui';

interface HeaderAvatarMenuProps {
  children: React.ReactNode;
}

export const InfoTooltip = (props: HeaderAvatarMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleRequestClose = () => {
    setAnchorEl(null);
  };

  const handleRequestOpen = (e: React.MouseEvent<HTMLElement> | React.FocusEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget);
  };

  return (
    <>
      <MuiClickAwayListener onClickAway={handleRequestClose}>
        <MuiIconButton aria-label="information" aria-haspopup="true" onClick={handleRequestOpen}>
          <Svg name="InfoIcon" set="assets" size={IconSize.base} color={Color.primary} />
        </MuiIconButton>
      </MuiClickAwayListener>

      <MuiPopover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
      >
        <MuiBox component={MuiPaper} p={2} maxWidth={225}>
          {props.children}
        </MuiBox>
      </MuiPopover>
    </>
  );
};
