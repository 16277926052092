import { Facility } from 'store/getCare/liveCare/types';

export const FILTER_NONE = 'FILTER_NONE';
export const FILTER_IS_OPEN = 'FILTER_IS_OPEN';

export const filterOptions = [
  { id: FILTER_NONE, label: 'None', filter: () => true },
  {
    id: FILTER_IS_OPEN,
    label: 'Currently Open',
    filter: (facility: Facility) => facility.isCurrentlyOpen
  }
];
