import { Icon } from 'components/Icon';
import Divider from 'components/UI/Divider';
import Box from 'components/UI/Layout/Box';
import FlexBox from 'components/UI/Layout/FlexBox';
import Spacer from 'components/UI/Layout/Spacer';
import Typography from 'components/UI/Typography';
import { getProviders, getLocations } from 'lib/visitSummary/utils';
import zipObject from 'lodash/zipObject';
import { Color } from 'modules/styles/colors';
import { Spacing } from 'modules/styles/variables';
import { capitalizeEachWord } from 'modules/utils/StringUtils';
import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootDispatch, RootState } from 'store/types';
import { setVisitSummaryFilter } from 'store/visitSummary/actions.web';
import { visitSummaryDataSelector, visitSummaryFilterSelector } from 'store/visitSummary/selectors';
import * as types from 'store/visitSummary/types';
import { MuiButton, MuiContainer, MuiDialog } from 'theme/material-ui';
// import CalendarRangePicker from 'components/UI/CalendarRangePicker/CalendarRangePicker';
import VisitSummaryFilterGroup from './VisitSummaryFilterGroup';

export interface Props extends NavigationStackScreenProps<{ source: string; onReset: () => void }> {
  data: types.VisitSummary[];
  filter: { [key: string]: types.VisitSummaryFilter };
  dispatch: RootDispatch;
}

enum FilterKey {
  START_DATE = 'startDate',
  END_DATE = 'endDate',
  PROVIDER = 'provider',
  LOCATION = 'location',
  VISIT_TYPE = 'visitType'
}

const initialState: types.VisitSummaryFilter = {
  provider: '',
  location: '',
  visitType: '',
  startDate: '',
  endDate: ''
};

export function VisitSummaryFilter(props: Props) {
  const { data, filter, dispatch } = props;
  const history = useHistory();

  const params = history?.location?.state || {};
  const { source } = params;

  const [state, setState] = useState(initialState);

  const providers = useMemo(() => getProviders(data), [data]);
  const locations = useMemo(() => getLocations(data), [data]);
  const visitTypes = Object.values(types.VisitType).map(type => ({
    label: capitalizeEachWord(type),
    value: type
  }));

  useEffect(() => {
    if (filter[source]) setState(filter[source]);
  }, []);

  const handlePress = (key: FilterKey | FilterKey[]) => (
    value: string | types.VisitType | null | string[]
  ) => {
    if (Array.isArray(key) && Array.isArray(value)) {
      const obj = zipObject(key, value);
      setState({ ...state, ...obj });
    }
    if (typeof key === 'string') {
      setState({ ...state, [key]: value });
    }
  };

  const onApply = () => {
    dispatch(setVisitSummaryFilter(source, state));
    history.push(source);
  };

  return (
    <MuiDialog fullScreen open onClose={() => history.goBack()}>
      <FlexBox alignItems="flex-end">
        <Icon
          accessibilityLabel="go back"
          name="close"
          size={24}
          onClick={() => history.goBack()}
        />
      </FlexBox>
      <MuiContainer maxWidth="lg">
        <Box width="100%">
          <Box vOffsetTop={Spacing.xLarge} hSpacing={Spacing.large}>
            <Typography color={Color.gray} fontSize={20}>
              Date Range
            </Typography>
          </Box>
          <FlexBox
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            flexWrap="nowrap"
            vSpacing={10}
            hSpacing={Spacing.large}
          ></FlexBox>
          <Divider />
          <Box vOffsetTop={Spacing.xLarge} hSpacing={Spacing.large}>
            <Typography color={Color.gray} fontSize={20}>
              Providers
            </Typography>
          </Box>
          <VisitSummaryFilterGroup
            items={providers}
            filter={state[FilterKey.PROVIDER]}
            onPress={handlePress(FilterKey.PROVIDER)}
            initialCount={3}
          />
          <Box vOffsetTop={Spacing.xLarge} hSpacing={Spacing.large}>
            <Typography color={Color.gray} fontSize={20}>
              Locations
            </Typography>
          </Box>
          <VisitSummaryFilterGroup
            items={locations}
            filter={state[FilterKey.LOCATION]}
            onPress={handlePress(FilterKey.LOCATION)}
            initialCount={3}
          />
          <Box vOffsetTop={Spacing.xLarge} hSpacing={Spacing.large}>
            <Typography color={Color.gray} fontSize={20}>
              Visit Types
            </Typography>
          </Box>
          <VisitSummaryFilterGroup
            items={visitTypes}
            filter={state[FilterKey.VISIT_TYPE]}
            onPress={handlePress(FilterKey.VISIT_TYPE)}
          />
        </Box>
        <Spacer spacing="large" />
        <FlexBox justifyContent="right">
          <MuiButton onPress={onApply} color="primary" variant="contained">
            Filter
          </MuiButton>
        </FlexBox>
      </MuiContainer>
    </MuiDialog>
  );
}

const mapStateToProps = (state: RootState) => ({
  data: visitSummaryDataSelector(state),
  filter: visitSummaryFilterSelector(state)
});

export default connect(mapStateToProps)(VisitSummaryFilter);
