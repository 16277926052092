import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import { MuiBox, MuiContainer, MuiRadio, MuiTypography } from 'theme/material-ui';
import { Spacing, FontWeight } from 'modules/styles/variables';
import Spacer from 'components/UI/Layout/Spacer';
import FlexBox from 'components/UI/Layout/FlexBox';
import Footer from 'components/common/Footer';

import { RootState, RootDispatch } from 'store/types';
import { CovidScreeningQuestionnaireSelector } from 'store/CovidScreening/selectors';
import { Questionnaire } from 'store/CovidScreening/types';
import { setQuestionnaire } from 'store/CovidScreening/actions';
import { getProfile } from 'store/profile/actions';
import { patientSelect } from 'modules/constants/covidScreening';
import { StyledScreen } from '../../../components/common/PatientLoader/styled';
import { CovidTestButton } from '../Components/CovidTestButton';
import useStyles from '../Components/CovidTestButton/useStyles';
import { CovidTitles } from 'lib/constants/covidScreening';
import { AnalyticsEvent } from 'services/AnalyticsService';
import useNavigationAnalytics from 'hooks/useNavigationAnalytics';

interface CovidInsuranceProps extends RouteComponentProps {
  dispatch: RootDispatch;
  questionnaire: Questionnaire;
  setHasInsurance: typeof setQuestionnaire;
}

const CovidInsurance = ({ questionnaire, setHasInsurance, history }: CovidInsuranceProps) => {
  const classes = useStyles();

  const [insuranceExits, setInsuranceExits] = useState('');

  const covidScreeningNextBtn = useNavigationAnalytics(AnalyticsEvent.CovidNext, {
    title: CovidTitles.HAVE_HEALTH_INSURANCE,
    has_insurance: insuranceExits === 'Yes' ? 'yes' : 'no'
  });

  const covidScreeningPreviousBtn = useNavigationAnalytics(AnalyticsEvent.CovidPrev, {
    title: CovidTitles.HAVE_HEALTH_INSURANCE
  });

  const onCancelClick = () => {
    covidScreeningPreviousBtn();
    history.goBack();
  };

  useEffect(() => {
    setInsuranceExits(questionnaire?.patientInsuranceExist);
  }, []);

  const handleSubmit = () => {
    covidScreeningNextBtn();

    const questionnaireObject = {
      ...questionnaire,
      // patientInsuranceExist: insuranceExits,
      has_insurance: insuranceExits
    };
    setHasInsurance(questionnaireObject);
    if (insuranceExits === 'Yes') {
      history.push('/u/get-care-now/covid-screen/insurance-select');
    }
    if (insuranceExits === 'No') {
      history.push('/u/get-care-now/covid-screen/summary-information');
    }
  };

  const renderInsuranceExit = () => (
    <>
      <FlexBox
        testID="covid-insurance-exit"
        width="100%"
        hSpacing={Spacing.medium}
        vSpacing={Spacing.medium}
      >
        <MuiTypography fontWeight={FontWeight.bold}>Do you have health insurance?</MuiTypography>
        <Spacer size="small" />
        <MuiTypography>
          Insurance information is needed to bill for COVID-19 tests. Copayments and deductibles may
          apply based on insurance plan.
        </MuiTypography>
        <Spacer size="small" />
        <MuiTypography>
          If additional insurance verification is needed, you may receive a follow-up call.
        </MuiTypography>
        <Spacer size="small" />
        <FlexBox flexDirection="row" alignItems="center">
          <MuiRadio
            onChange={() => setInsuranceExits('Yes')}
            checked={insuranceExits === 'Yes'}
            data-testid="insurance-yes"
            value={insuranceExits}
            color="primary"
          />
          <MuiTypography>Yes</MuiTypography>
        </FlexBox>
        <FlexBox flexDirection="row" alignItems="center">
          <MuiRadio
            onChange={() => setInsuranceExits('No')}
            checked={insuranceExits === 'No'}
            data-testid="insurance-no"
            value={insuranceExits}
            color="primary"
          />
          <MuiTypography>No</MuiTypography>
        </FlexBox>
      </FlexBox>
    </>
  );
  return (
    <>
      <StyledScreen>
        <MuiContainer maxWidth="lg">
          <Spacer />
          {renderInsuranceExit()}
          <Spacer size="small" />
        </MuiContainer>
      </StyledScreen>
      <MuiContainer maxWidth="lg">
        <MuiBox className={classes.steppersFooter}>
          <CovidTestButton
            fullWidth
            variant="contained"
            data-testid="insurance-next-button"
            onClick={handleSubmit}
            disabled={!insuranceExits}
          >
            Next
          </CovidTestButton>
          <CovidTestButton
            fullWidth
            variant="text"
            data-testid="insurance-previous-button"
            onClick={onCancelClick}
          >
            Previous
          </CovidTestButton>
        </MuiBox>
      </MuiContainer>
      <Footer
        headerText={patientSelect.GET_PATIENTS.HELPLINE.CovidInfo}
        link={patientSelect.GET_PATIENTS.HELPLINE.CovidPatientSelectHelpline}
        linkName={patientSelect.GET_PATIENTS.HELPLINE.CovidPatientSelectHelpline}
        helplineHours={patientSelect.GET_PATIENTS.HELPLINE.CovidHelplineHours}
        title={CovidTitles.HAVE_HEALTH_INSURANCE}
      />
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  questionnaire: CovidScreeningQuestionnaireSelector(state)
});
const mapDispatchToProps = {
  setHasInsurance: setQuestionnaire,
  fetchInsurance: getProfile
};

export default connect(mapStateToProps, mapDispatchToProps)(CovidInsurance);
