import CollapsibleListItem from 'components/common/CollapsibleList/CollapsibleListItem';
import { MuiBox, MuiPaper } from 'theme/material-ui';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import React from 'react';
import { RootState } from 'store/types';
import { TRIAGE_ROUTES, TRIAGE_TITLE } from 'screens/TriageGyant/constants';
import { GetCareButton, GetCareButtonTextBox, GetCareText } from '../styled';
import {
  previousLocationPathNameSelector,
  currentLocationPathNameSelector
} from 'store/router/selectors';

export const GetCareCheckSymptomsList = () => {
  const history = useHistory();

  const handleCheckSymptomsGetStarted = () => {
    history.push(TRIAGE_ROUTES.TERMS);
  };

  return (
    <CollapsibleListItem
      leftIcon="Scout"
      title="Check Symptoms"
      subtitle="Care Guidance based on symptoms"
    >
      <MuiBox width="100%">
        <MuiPaper elevation={1}>
          <GetCareButtonTextBox
            flexDirection="column"
            justifyContent="center"
            display="flex"
            alignItems="center"
          >
            <GetCareText color="textPrimary">{TRIAGE_TITLE}</GetCareText>
            <GetCareButton
              data-testid="check-symptoms-get-started-button"
              variant="contained"
              color="primary"
              onClick={handleCheckSymptomsGetStarted}
            >
              Get started
            </GetCareButton>
          </GetCareButtonTextBox>
        </MuiPaper>
      </MuiBox>
    </CollapsibleListItem>
  );
};

const mapState = (state: RootState) => ({
  currentRouteName: currentLocationPathNameSelector(state),
  previousRouteName: previousLocationPathNameSelector(state)
});

export default connect(mapState)(GetCareCheckSymptomsList);
