import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import dayjs from 'dayjs';
import sortBy from 'lodash/sortBy';
import relativeTime from 'dayjs/plugin/relativeTime';
import { MuiBox, MuiButton, MuiIcon } from 'theme/material-ui';
import { Appointment } from 'store/amwell/types';
import * as selectors from 'store/amwell/selectors';
import * as actions from 'store/amwell/actions';
import { RootState, RootDispatch } from 'store/types';
import { APPOINTMENT_CAROUSEL } from 'lib/constants/connectCare';
import { AnimatedEllipsis } from 'components/AnimatedEllipsis';
import { Carousel } from 'components/Carousel';
import Svg from 'components/UI/Svg/Svg';
import { CarouselSlide } from './CarouselSlide';

import detectiveImage from 'assets/detective.png';
import dogImg from 'assets/dog.png';

export interface Props {
  appointments: Appointment[];
  isLoading: boolean;
  isError: Error | null;
  getAppointments: () => void;
}

dayjs.extend(relativeTime);

const CarouselLoading = () => (
  <CarouselSlide
    testId="connect-care-home-carousel-loading"
    src={dogImg}
    alt="Dog chasing ball"
    title={APPOINTMENT_CAROUSEL.loading.title}
    subtitle={APPOINTMENT_CAROUSEL.loading.subtitle}
  >
    <MuiBox position="absolute">
      <AnimatedEllipsis />
    </MuiBox>
  </CarouselSlide>
);

const CarouselError = () => (
  <CarouselSlide
    testId="connect-care-home-carousel-error"
    src={detectiveImage}
    alt="Detective looking through magnifying glass"
    title={APPOINTMENT_CAROUSEL.error.title}
    subtitle={APPOINTMENT_CAROUSEL.error.subtitle}
  />
);

const CarouselNoData = () => (
  <CarouselSlide
    testId="connect-care-home-carousel-no-data"
    img={
      <MuiBox m={1}>
        <Svg name="Calendar" height="75" width="100%" />
      </MuiBox>
    }
    title={APPOINTMENT_CAROUSEL.empty.title}
  />
);

const CarouselAppointment = ({ appointment }: { appointment: Appointment }) => {
  const history = useHistory();
  const currentDate = dayjs();
  const appointmentDate = dayjs(appointment.schedule.scheduledStartTime);
  const relativeAppointmentDate = appointmentDate.fromNow();
  const difference = appointmentDate.diff(currentDate, 'minute');

  const isPast = appointmentDate < currentDate;
  const disabled = difference < -30 || difference > 30;

  return (
    <CarouselSlide
      testId="connect-care-home-carousel-appointment"
      title={appointmentDate.format('MMMM D, YYYY, h:mma')}
      subtitle={appointment.assignedProvider.fullName}
    >
      <MuiBox display="flex" justifyContent="center" mt={2.5}>
        <MuiButton
          color="primary"
          startIcon={<MuiIcon>schedule</MuiIcon>}
          variant="contained"
          disabled={disabled}
          onClick={() =>
            history.push(`/u/get-care-now/connect-care/appointments/${appointment.sourceId}`)
          }
        >
          {isPast ? `Ended ${relativeAppointmentDate}` : `Starting ${relativeAppointmentDate}`}
        </MuiButton>
      </MuiBox>
    </CarouselSlide>
  );
};
/**
 * To DO: Check if this component is still necessary.
 */
export const HomeBanner = (props: Props) => {
  const { appointments, isLoading, isError, getAppointments } = props;
  const { state } = useLocation<{ sourceId?: string }>();

  useEffect(() => {
    getAppointments();
  }, []);

  if (isLoading) {
    return (
      <MuiBox px={7}>
        <CarouselLoading />
      </MuiBox>
    );
  }

  if (isError) {
    return (
      <MuiBox px={7}>
        <CarouselError />
      </MuiBox>
    );
  }

  if (!appointments.length) {
    return (
      <MuiBox px={7}>
        <CarouselNoData />
      </MuiBox>
    );
  }

  return (
    <Carousel
      sliderHeight={185}
      naturalSlideHeight={165}
      slides={sortBy(appointments, a => (a.sourceId === state?.sourceId ? 0 : 1))}
      slideToKey={slide => (slide ? slide.id.persistentId : '')}
      renderSlide={(appointment: Appointment) => <CarouselAppointment appointment={appointment} />}
    />
  );
};

const mapStateToProps = (state: RootState) => ({
  appointments: selectors.appointmentsDataSelector(state),
  isError: selectors.appointmentsErrorSelector(state),
  isLoading: selectors.appointmentsLoadingSelector(state)
});

const mapDispatchToProps = (dispatch: RootDispatch) => ({
  getAppointments: () => dispatch(actions.getAppointments())
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeBanner);
