import React from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { History } from 'history';

import Spacer from 'components/UI/Layout/Spacer';
import UserAvatar from 'components/UI/Avatar';
import Divider from 'components/UI/Divider';

import TriageHeader from 'screens/TriageGyant/TriageGyantHeader';

import { TRIAGE_ROUTES } from 'screens/TriageGyant/constants';
import {
  currentAccountConsumerSelector,
  otherActiveAccountsSelector,
  isImpersonatingSelector
} from 'store/account/selectors';
import { RootState } from 'store/types';
import { MapAvatar } from 'store/consumerPreferences/types';
import { Consumer, LinkedConsumer } from 'store/profile/types';
import { avatarDataMapSelector } from 'store/consumerPreferences/selectors';
import { getUserName } from 'lib/user';

import { CursorMuiBox, MuiContainer, MuiBox, MuiTypography, MuiPaper } from 'theme/material-ui';
import { toSentenceCase } from 'modules/utils/StringUtils';
import { Spacing } from 'modules/styles/variables.web';

export interface TriageProxyScreenProps {
  avatarsMap: MapAvatar;
  currentAccount: Consumer;
  proxyAccounts: LinkedConsumer[];
  isImpersonating: boolean;
}

export const RenderAccountListItem = (
  avatarsMap: MapAvatar,
  item: Consumer | LinkedConsumer,
  index: number,
  currentAccount: Consumer,
  history: History,
  isImpersonating: boolean
) => {
  const handleNext = () => {
    // selected user is proxy if:
    // isImpersonating and selected index is not 1
    // or is not impersonating and selected index is not 0
    // (list shows currentAccount first and it could be a proxy)
    const isProxy = isImpersonating ? index !== 1 : index !== 0;
    const guarantorId = isProxy ? currentAccount.consumerId : undefined;
    history.push(TRIAGE_ROUTES.SYMPTOM_CHECKER, {
      selectedProxyAccount: { ...item, isProxy, guarantorId }
    });
  };

  return (
    <MuiPaper>
      <CursorMuiBox p={Spacing.xSmall} onClick={handleNext} data-testid="touchable-user-avatar">
        <MuiBox display="flex" flexDirection="row" alignItems="center" justifyContent="flex-start">
          <UserAvatar
            size={50}
            fallback={getUserName(item)}
            avatarUrl={avatarsMap[item.consumerId]}
            margin="none"
          />
          <MuiBox pl={2}>
            <MuiTypography>{toSentenceCase(item.firstName)}</MuiTypography>
          </MuiBox>
        </MuiBox>
      </CursorMuiBox>
    </MuiPaper>
  );
};

export const TriageProxySelectScreen = ({
  avatarsMap,
  currentAccount,
  proxyAccounts,
  isImpersonating
}: TriageProxyScreenProps) => {
  const history = useHistory();
  return (
    <MuiBox display="flex" flexDirection="column" height="100%">
      <TriageHeader />
      <MuiContainer maxWidth="sm">
        <MuiBox mt={Spacing.small} px={2}>
          <MuiTypography variant="h6">Who is this regarding?</MuiTypography>
          <Spacer size="small" />
          <MuiPaper elevation={2}>
            {[currentAccount, ...proxyAccounts].map((item, index) => {
              return (
                <>
                  {RenderAccountListItem(
                    avatarsMap,
                    item,
                    index,
                    currentAccount,
                    history,
                    isImpersonating
                  )}
                  <Divider />
                </>
              );
            })}
          </MuiPaper>
          <Spacer size="medium" />
        </MuiBox>
      </MuiContainer>
    </MuiBox>
  );
};

const mapState = (state: RootState) => ({
  avatarsMap: avatarDataMapSelector(state),
  currentAccount: currentAccountConsumerSelector(state),
  proxyAccounts: otherActiveAccountsSelector(state),
  isImpersonating: isImpersonatingSelector(state)
});

export default connect(mapState)(TriageProxySelectScreen);
