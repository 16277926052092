import { Color } from 'modules/styles/colors';
import styled from 'styled-components';

export const TypographyInlineWrapper = styled.span`
  display: inline-block;
  color: ${props => props?.color || Color.link};
  cursor: pointer;
`;

export default TypographyInlineWrapper;
