import React from 'react';
import { SvgProps } from 'components/UI/Svg';

export default (props: SvgProps) => {
  return {
    svg: (
      <g>
        <path
          fill={props?.color || 'inherit'}
          fillRule="evenodd"
          d="M12.001 3a.75.75 0 0 1 .75.75v10.19l3.22-3.22a.75.75 0 0 1 1.06 1.06l-4.5 4.5a.75.75 0 0 1-1.06 0l-4.5-4.5a.75.75 0 1 1 1.06-1.06l3.22 3.22V3.75a.75.75 0 0 1 .75-.75ZM.751 15a.75.75 0 0 1 .75.75v1.5a2.25 2.25 0 0 0 2.25 2.25h16.5a2.25 2.25 0 0 0 2.25-2.25v-1.5a.75.75 0 0 1 1.5 0v1.5a3.75 3.75 0 0 1-3.75 3.75h-16.5a3.75 3.75 0 0 1-3.75-3.75v-1.5a.75.75 0 0 1 .75-.75Z"
          clipRule="evenodd"
        />
      </g>
    ),
    viewBox: '0 0 24 24'
  };
};
