import DataLoader from 'components/UI/DataLoader/DataLoader';
import { BLOOD_PRESSURE, HISTORICAL_VITALS } from 'lib/constants/healthRecords';
import { MINOR_VITAL_AGE_MAX, INFANT_VITAL_AGE_MAX } from 'lib/vitals/utils';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { RootDispatch, RootState } from 'store/types';
import { setHistoricalVitalType } from 'store/vitals/actions';
import { currentAccountConsumerAgeSelector } from 'store/account/selectors';
import * as vitalsSelectors from 'store/vitals/selectors';
import { BloodPressureData, HistoricalVitalsDataObject } from 'store/vitals/types';
import { HEIGHT_VITAL_SERVICE_NAME, WEIGHT_VITAL_SERVICE_NAME } from 'store/vitals/constants';
import VitalsChart from '../VitalsChart/VitalsChart';
import BloodPressureVital from './bloodPressure/BloodPressureVital/BloodPressureVital';
import { Message, MessageText } from './bloodPressure/BloodPressureVital/styled';

interface Props {
  bloodPressureData: BloodPressureData;
  isHistoricalVitalsLoading: boolean;
  isRecentVitalsLoading: boolean;
  historicalVitalsError?: Error | null;
  recentVitalsError?: Error | null;
  age: number;
  historicalVitalsData: HistoricalVitalsDataObject[];
  bloodType: string;
  dispatch: RootDispatch;
}

export const getKeyVital = (age: number) => {
  if (age <= INFANT_VITAL_AGE_MAX) {
    return { serviceName: WEIGHT_VITAL_SERVICE_NAME, title: 'weight' };
  }

  return { serviceName: HEIGHT_VITAL_SERVICE_NAME, title: 'height' };
};

export function VitalsBanner({
  isRecentVitalsLoading,
  age,
  bloodPressureData,
  historicalVitalsData,
  recentVitalsError,
  bloodType,
  dispatch
}: Props) {
  const { serviceName, title } = getKeyVital(age);
  const isMinor = age <= MINOR_VITAL_AGE_MAX;

  useEffect(() => {
    if (isMinor) {
      dispatch(setHistoricalVitalType(serviceName));
    }
  }, [isMinor, serviceName]);

  const renderHistoricalVitalsData = () => <VitalsChart type={serviceName} />;

  const renderHistoricalVitalsLoadingState = () => (
    <Message>
      <MessageText>{HISTORICAL_VITALS.loading.title}</MessageText>
    </Message>
  );

  const renderHistoricalVitalsErrorState = () => (
    <Message>
      <MessageText>{HISTORICAL_VITALS.getErrorState(title)}</MessageText>
    </Message>
  );

  const renderHistoricalVitalsEmptyState = () => (
    <Message data-testid="vitals-empty-state">
      <MessageText>{HISTORICAL_VITALS.empty.title}</MessageText>
      <MessageText>{HISTORICAL_VITALS.empty.subtitle}</MessageText>
    </Message>
  );

  const renderBPData = () => (
    <BloodPressureVital bloodPressureData={bloodPressureData} bloodType={bloodType} />
  );

  const renderBPLoadingState = () => (
    <Message>
      <MessageText>{BLOOD_PRESSURE.loading.title}</MessageText>
    </Message>
  );

  const renderBPErrorState = () => (
    <Message>
      <MessageText>{BLOOD_PRESSURE.error.title}</MessageText>
      <MessageText>{BLOOD_PRESSURE.error.subtitle}</MessageText>
    </Message>
  );

  const renderBPEmptyState = () => (
    <Message data-testid="vitals-empty-state">
      <MessageText>{BLOOD_PRESSURE.empty.title}</MessageText>
      <MessageText>{BLOOD_PRESSURE.empty.subtitle}</MessageText>
    </Message>
  );

  if (isMinor) {
    return (
      <DataLoader
        data={historicalVitalsData}
        error={recentVitalsError}
        loading={isRecentVitalsLoading}
        renderData={renderHistoricalVitalsData}
        renderNoData={renderHistoricalVitalsEmptyState}
        renderError={renderHistoricalVitalsErrorState}
        renderLoading={renderHistoricalVitalsLoadingState}
      />
    );
  }

  return (
    <DataLoader
      data={bloodPressureData}
      error={recentVitalsError}
      loading={isRecentVitalsLoading}
      renderData={renderBPData}
      renderNoData={renderBPEmptyState}
      renderError={renderBPErrorState}
      renderLoading={renderBPLoadingState}
    />
  );
}

const mapStateToProps = (state: RootState) => ({
  historicalVitalsData: vitalsSelectors.historicalVitalsChartDataSelector(state),
  bloodPressureData: vitalsSelectors.bloodPressureSelector(state),
  isRecentVitalsLoading: vitalsSelectors.recentVitalsLoadingSelector(state),
  recentVitalsError: vitalsSelectors.recentVitalsErrorSelector(state),
  bloodType: vitalsSelectors.bloodTypeSelector(state),
  age: currentAccountConsumerAgeSelector(state)
});

export default connect(mapStateToProps)(VitalsBanner);
