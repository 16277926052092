import React from 'react';
import { Icon } from 'components/Icon';

import FlexBox from 'components/UI/Layout/FlexBox';
import Box from 'components/UI/Layout/Box';
import styled from 'styled-components';
import StyledButton from 'components/UI/Button/StyledButton';
import { Color } from 'modules/styles/colors';
import { Spacing, BorderRadius, FontSize, IconSize, ZIndex } from 'modules/styles/variables';
import { Image } from 'react-native';

interface Props {
  iconName: string;
  iconColor: string;
  modalText: string;
  buttonText: string;
  imageURL?: string;
  onCloseModal: () => void;
  onButtonPress: () => void;
}

const ModalContainer = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  background-color: ${Color.backDrop};
  width: 100%;
  height: 100%;
  z-index: ${ZIndex.base};
`;
const ModalChild = styled(FlexBox)`
  position: absolute;
  bottom: ${Spacing.none};
  left: ${Spacing.none};
  height: 300px;
  background-color: ${Color.white};
  width: 100%;
  z-index: ${ZIndex.low};
`;
const CloseButton = styled.div`
  position: absolute;
  right: ${Spacing.large};
  top: ${Spacing.large};
  background-color: ${Color.grayLight};
  border-radius: ${BorderRadius.xLarge};
  padding: ${Spacing.small}px;
`;

const StatusText = styled.div`
  font-size: ${FontSize.title};
  margin-top: ${Spacing.xLarge};
  text-align: center;
`;

const MainActionButton = styled(StyledButton)`
  width: 80%;
  margin-top: ${Spacing.xLarge};
`;

const BottomModal = (props: Props) => {
  return (
    <ModalContainer>
      <ModalChild flexDirection="column" justifyContent="center" alignItems="center">
        <CloseButton onPress={props.onCloseModal} accessibilityLabel="Close pop-up">
          <Icon name="close" size={IconSize.small} color={Color.foreColor} />
        </CloseButton>
        <FlexBox width="80%" justifyContent="center" alignItems="center">
          <Icon
            name={props.iconName}
            size={IconSize.xLarge + 20}
            color={props.iconColor || Color.primaryHue5}
          />
          <StatusText>{props.modalText}</StatusText>
          {props.imageURL && (
            <Image style={{ width: 300, height: 150 }} source={{ uri: props.imageURL }} />
          )}
          {props.buttonText && (
            <MainActionButton onPress={props.onButtonPress}>{props.buttonText}</MainActionButton>
          )}
        </FlexBox>
      </ModalChild>
    </ModalContainer>
  );
};

export default BottomModal;
