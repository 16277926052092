import React from 'react';
import { MuiBox } from 'theme/material-ui';
import Spinner from 'components/UI/Spinner/Spinner';

function ConnectCareLoading() {
  return (
    <MuiBox
      data-testid="connect-care-loading"
      py={5}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Spinner />
    </MuiBox>
  );
}

export default ConnectCareLoading;
