import React from 'react';
import styled from 'styled-components';
import { Color } from 'modules/styles/colors';
import { SafeAreaView, ViewProps } from 'react-native';

import FlexBox from './FlexBox';

interface ScreenProps extends ViewProps {
  foreground?: boolean;
  background?: boolean;
  safe?: boolean;
  children: React.ReactNode;
}

const colorStyle = (props: ScreenProps) => {
  if (props.foreground) {
    return Color.foreColor;
  }

  if (props.background) {
    return Color.baseColor;
  }

  return Color.baseColor;
};

const StyledScreen = styled(FlexBox)<ScreenProps>`
  flex: 1;
  background-color: ${colorStyle};
`;

const Screen = (props: ScreenProps) => {
  if (props.safe) {
    return <StyledScreen {...props} as={SafeAreaView} />;
  }

  return <StyledScreen {...props} />;
};

export default Screen;
