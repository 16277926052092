import React from 'react';
import styled from 'styled-components';
import {
  MuiFormControlLabel,
  MuiCheckbox,
  CheckboxProps,
  FormControlLabelProps
} from 'theme/material-ui';

export interface CustomFormControlLabelProps {
  justifyContent?: 'center' | 'space-around' | 'space-between' | 'flex-end';
  borderBottom?: string;
  paddingX?: number;
}

type OmittedProps = 'color' | 'onChange';
type MuiCheckboxProps = Omit<CheckboxProps, OmittedProps> & CustomFormControlLabelProps;
type MuiFormControlLabelProps = FormControlLabelProps & CustomFormControlLabelProps;

export interface ConnectCareCheckboxItemProps extends MuiCheckboxProps {
  label?: React.ReactNode;
  labelPlacement?: 'start' | 'top' | 'end' | 'bottom';
  onChange?: (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
}

export const CustomFormControlLabel = styled(MuiFormControlLabel)<MuiFormControlLabelProps>`
  ${props => props.justifyContent && `justify-content: ${props.justifyContent};`}
  ${props =>
    props.paddingX &&
    `padding-top: ${props.paddingX}px; padding-bottom: ${props.paddingX}px;`}
  &:not(:last-child) {
    ${props => props.borderBottom && `border-bottom: ${props.borderBottom};`}
  }
`;

function ConnectCareCheckboxItem(props: ConnectCareCheckboxItemProps) {
  const {
    label,
    labelPlacement,
    onChange,
    justifyContent,
    borderBottom,
    paddingX,
    ...rest
  } = props;

  return (
    <CustomFormControlLabel
      label={label}
      labelPlacement={labelPlacement}
      control={
        <MuiCheckbox {...rest} onChange={(e, checked) => onChange && onChange(e, checked)} />
      }
      justifyContent={justifyContent}
      borderBottom={borderBottom}
      paddingX={paddingX}
    />
  );
}

export default ConnectCareCheckboxItem;
