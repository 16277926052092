import React from 'react';
import { useSelector } from 'react-redux';
import { FiltersStructure } from 'lib/hooks/AuditLogFilters/types/filter';
import { FilterDrawer } from 'components/FilterDrawer';
import { auditCategoriesDataSelector } from 'store/audit/selectors';
import { viewableCount } from '../constants';
import { FormCheckboxList } from '../shared';
import { useAuditLogFilters } from 'lib/hooks/AuditLogFilters/useAuditLogFilters';

export type CategoriesFilterProps = FiltersStructure['categories'] & {
  defaultExpanded: boolean;
};

function CategoriesFilter({ title, values, defaultExpanded }: CategoriesFilterProps) {
  const categories = useSelector(auditCategoriesDataSelector);
  const filterContext = useAuditLogFilters();
  return (
    <FilterDrawer.Panel
      expandable={categories.length}
      title={title}
      defaultExpanded={defaultExpanded}
      filtersCount={filterContext?.values?.facets?.categories?.length}
    >
      <FormCheckboxList initialCount={viewableCount} list={Object.values(values(categories))} />
    </FilterDrawer.Panel>
  );
}

export default CategoriesFilter;
