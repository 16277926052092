import React from 'react';
import Divider from 'components/UI/Divider';
import { LabResult } from 'store/testResults/types';
import Typography from 'components/UI/Typography';
import { Color } from 'modules/styles/colors';
import { FontSize, FontWeight } from 'modules/styles/variables';
import Box from 'components/UI/Layout/Box';
import { MuiBox } from 'theme/material-ui';

// LAB COMMENTS
const LabComments = ({ content }: { content: LabResult[] }) => {
  const comments = content.reduce((acc, { notes }) => {
    return notes ? acc.concat(`${notes}<br><br>`) : acc;
  }, '');

  const html = comments.length ? comments : 'No comments';

  return (
    <>
      <Divider />
      <MuiBox p={2} bgcolor={Color.grayLight3}>
        <Typography fontSize={FontSize.large} fontWeight={FontWeight.bold}>
          Additional comments
        </Typography>
      </MuiBox>
      <Divider />

      <Box flex={1} spacing={30} dangerouslySetInnerHTML={{ __html: html }} />
    </>
  );
};

export default LabComments;
