import React from 'react';
import styled, { css } from 'styled-components';
import { MuiButton, ButtonProps } from 'theme/material-ui';
import { Color } from 'modules/styles/colors';

export interface CovidTestButtonProps extends ButtonProps {
  loading?: boolean;
  download?: boolean;
}

const Button = styled(MuiButton)`
  ${props =>
    props.href &&
    css`
      &.MuiButton-containedPrimary:hover,
      &.MuiButton-containedPrimary:focus,
      &.MuiButton-containedPrimary:active {
        color: ${Color.white};
      }
    `}
`;

function CovidTestButton(props: CovidTestButtonProps) {
  return <Button {...props} />;
}

CovidTestButton.defaultProps = {
  variant: 'contained',
  color: 'primary'
};

export default CovidTestButton;
