import styled from 'styled-components';

import { Color } from 'modules/styles/colors';
import { largeFontSize } from 'modules/styles/variables';

import DefaultText from './DefaultText';

const BodyText = styled(DefaultText)`
  color: ${Color.textDark};
  font-size: ${largeFontSize};
  letter-spacing: 0.5px;
  line-height: ${largeFontSize * 1.625};
`;

export default BodyText;
