import styled from 'styled-components';

import { Color } from 'modules/styles/colors';
import { FontSize } from 'modules/styles/variables';
import { dfdDefaultTheme } from 'theme/material-ui';

export interface DefaultTextProps {
  color?: string;
  center?: boolean;
  textAlign?: string;
  adjustsFontSizeToFit?: boolean;
  fontSize?: string | number;
  fontWeight?: string;
  'data-testid'?: string;
  accessibilityLabel?: string;
}

const DefaultText = styled.span<DefaultTextProps>`
  color: ${(props: DefaultTextProps) => (props.color ? props.color : Color.textLight)};
  text-align: ${(props: DefaultTextProps): string => {
    if (props.center) {
      return 'center';
    }
    return props.textAlign ? props.textAlign : 'left';
  }};
  margin: 0;
  font-size: ${(props: DefaultTextProps) => {
    let rtn = `${FontSize.large}px`;
    if (props.FontSize) {
      // TODO: CapitalCase? Deprecating this pattern.
      rtn = typeof props.FontSize === 'string' ? props.FontSize : `${props.FontSize}px`;
    } else if (props.fontSize) {
      rtn = typeof props.fontSize === 'string' ? props.fontSize : `${props.fontSize}px`;
    }
    return rtn;
  }};
  ${(props: DefaultTextProps) => (props.fontWeight ? `font-weight: ${props.fontWeight};` : '')}
  line-height: 1.4;
  ${(props: DefaultTextProps) =>
    props.adjustsFontSizeToFit &&
    `${dfdDefaultTheme.breakpoints.down('lg')} {
      font-size: ${FontSize.large}px;
    }

    ${dfdDefaultTheme.breakpoints.down('md')} {
      font-size: ${FontSize.base}px;
    }

    ${dfdDefaultTheme.breakpoints.down('sm')} {
      font-size: ${FontSize.small}px;
    }`}
`;

export default DefaultText;
