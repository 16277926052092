import React from 'react';
import Svg from 'components/UI/Svg/Svg';
import FlexBox from 'components/UI/Layout/FlexBox';
import { IconSize } from 'modules/styles/variables';

const getDirectionIcon = (direction?: string) => {
  switch (direction) {
    case '1':
      return 'arrow-upward';
    case '-1':
      return 'arrow-downward';
    default:
      return 'remove';
  }
};

export interface LabRowChangeProps {
  value: string;
  direction?: string;
}

const LabRowChange = ({ value, direction }: LabRowChangeProps) => {
  if (value === 'N/A') return null;
  return (
    <FlexBox
      flexDirection="row"
      alignItems="center"
      style={{ marginLeft: '10px' }}
      testID="trendline-change"
    >
      <span data-testid="change-value-text"> | Change: {value === '0.00' ? '' : value}</span>
      <Svg
        set="material"
        name={getDirectionIcon(direction)}
        size={IconSize.small}
        data-testid={`direction-icon-${getDirectionIcon(direction)}`}
      />
    </FlexBox>
  );
};

export default LabRowChange;
