/* eslint-disable */
// linting is disabled to get around a typing issue in csstype
import styled from 'styled-components';
import {
  FlexWrapProperty,
  FlexDirectionProperty,
  AlignItemsProperty,
  JustifyContentProperty
} from 'csstype';
import Box, { BoxProps } from './Box';

export interface FlexProps extends BoxProps {
  children: React.ReactNode;
  flexDirection?: FlexDirectionProperty;
  alignItems?: AlignItemsProperty;
  justifyContent?: JustifyContentProperty;
  center?: boolean;
  fullWidth?: boolean;
  flexWrap?: FlexWrapProperty;
  testID?: string;
  ['data-testid']?: string;
}

const FlexBox = styled(Box)<FlexProps>`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection || 'column'};
  align-items: ${({ alignItems }) => alignItems};
  justify-content: ${({ justifyContent }) => justifyContent};
  flex-wrap: ${({ flexWrap }) => flexWrap};
  ${({ center }) =>
    center
      ? `
    align-items: center;
    justify-content: center;
  `
      : ''}
  ${({ fullWidth }) => (fullWidth ? 'width: 100%;' : '')}
`;

export const FlexBoxColumn = styled(FlexBox)<FlexProps>(props => ({
  display: 'flex',
  flexDirection: 'column'
}));

export const FlexBoxRow = styled(FlexBox)<FlexProps>(props => ({
  display: 'flex',
  flexDirection: 'row'
}));

export const DisplayFlex = styled.div`
  display: flex;
  flex: 1 1 auto;
`;

interface FlexBoxDynamicProps {
  width?: string;
  justifyContent?: string;
}
export const FlexBoxDynamic = styled(DisplayFlex)<FlexBoxDynamicProps>`
  flex-direction: row;
  flex-wrap: wrap;
  ${props => (props.justifyContent ? `justify-content: ${props.justifyContent};` : '')}
  ${props => (props.width ? `width: ${props.width};` : '')}
`;

export default FlexBox;
