import React from 'react';

export default {
  svg: (
    <g>
      <path
        fill="#000"
        fillRule="evenodd"
        d="M4.533 1.773A2.461 2.461 0 0 1 6.876.063h5.13c1.098 0 2.027.719 2.344 1.71h1.932a2.46 2.46 0 0 1 2.46 2.46v6.12a.75.75 0 0 1-1.5 0v-6.12a.96.96 0 0 0-.96-.96h-1.815v.106a2.46 2.46 0 0 1-2.46 2.46h-5.13a2.46 2.46 0 0 1-2.46-2.46v-.106H2.6a.96.96 0 0 0-.96.96v17.101a.96.96 0 0 0 .96.96h9.935a.75.75 0 0 1 0 1.5H2.601a2.46 2.46 0 0 1-2.46-2.46v-17.1a2.46 2.46 0 0 1 2.46-2.46h1.932Zm2.343-.21a.96.96 0 0 0-.96.96v.856c0 .53.43.96.96.96h5.13c.53 0 .96-.43.96-.96v-.855a.96.96 0 0 0-.96-.96h-5.13ZM3.887 9.07a.75.75 0 0 1 .75-.75h9.61a.75.75 0 0 1 0 1.5h-9.61a.75.75 0 0 1-.75-.75Zm11.459 3.884a.75.75 0 0 1 .75-.75h3.792a.75.75 0 0 1 .75.75v2.47h2.47a.75.75 0 0 1 .75.75v3.792a.75.75 0 0 1-.75.75h-2.47v2.47a.75.75 0 0 1-.75.75h-3.792a.75.75 0 0 1-.75-.75v-2.47h-2.47a.75.75 0 0 1-.75-.75v-3.792a.75.75 0 0 1 .75-.75h2.47v-2.47Zm1.5.75v2.47a.75.75 0 0 1-.75.75h-2.47v2.292h2.47a.75.75 0 0 1 .75.75v2.47h2.292v-2.47a.75.75 0 0 1 .75-.75h2.47v-2.292h-2.47a.75.75 0 0 1-.75-.75v-2.47h-2.292ZM3.887 13.57a.75.75 0 0 1 .75-.75h6.864a.75.75 0 0 1 0 1.5H4.637a.75.75 0 0 1-.75-.75Zm0 4.5a.75.75 0 0 1 .75-.75H8.07a.75.75 0 0 1 0 1.5H4.637a.75.75 0 0 1-.75-.75Z"
        clipRule="evenodd"
      />
    </g>
  ),
  viewBox: '0 0 24 24'
};
