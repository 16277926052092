import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { MuiBox, MuiGrid, MuiTypography, MuiLink } from 'theme/material-ui';

import { Svg } from 'components/UI/Svg';
import { LazyMuiAvatar } from 'components/LazyAvatar';

import { showDistance } from 'modules/utils/LocationUtils';
import { Color } from 'modules/styles/colors';
import { FontSize, IconSize } from 'modules/styles/variables';
import { Nullable } from 'modules/types/common';

import { RootState } from 'store/types';
import { DoctorSpecialty, DoctorLocation, Specialty } from 'store/booking/types';
import {
  currentLocationPathNameSelector,
  previousLocationPathNameSelector
} from 'store/router/selectors';

import { SelectHealthIconWrapper } from 'screens/Booking/components/styled';

export interface Props {
  providerId: string | number;
  displayName: string;
  imageUrl: string;
  specialties: DoctorSpecialty[];
  selectedSpecialty: Nullable<Specialty>;
  primaryLocation: DoctorLocation;
  isSelectHealth: 'Y' | 'N' | null | undefined;
  currentUrl?: string;
  referringUrl?: string;
  offersVideoVisits: boolean;
  hasLocation: boolean;
}

export const SpecialtyProviderPanel = ({
  providerId,
  displayName,
  imageUrl,
  specialties,
  selectedSpecialty,
  primaryLocation,
  isSelectHealth,
  offersVideoVisits,
  hasLocation
}: Props) => {
  const history = useHistory();
  const locationDistance = (location: string): string => {
    if (!!location) {
      const [distanceNumber, units] = location.split(' ');
      const distanceString = parseFloat(distanceNumber)
        ? `${parseFloat(distanceNumber).toFixed(2)} ${units}`
        : '';
      return distanceString;
    }
    return 'Unknown Miles';
  };

  return (
    <MuiGrid container spacing={2} alignItems="center">
      <MuiGrid item>
        <MuiBox mr={2}>
          <LazyMuiAvatar src={imageUrl} style={{ width: IconSize.large, height: IconSize.large }} />
          {isSelectHealth ? (
            <SelectHealthIconWrapper>
              <Svg set="assets" name="SelectHealth" size={IconSize.small} />
            </SelectHealthIconWrapper>
          ) : null}
        </MuiBox>
      </MuiGrid>
      <MuiGrid container item xs alignItems="center">
        <MuiGrid item xs={12} md container direction="column">
          <MuiGrid item xs>
            <MuiTypography data-testid="provider-name">{displayName}</MuiTypography>
            <MuiTypography variant="subtitle1" data-testid="provider-specialty">
              {specialties.map(e => e.specialtyName).join(', ')}
            </MuiTypography>
            <MuiLink
              data-testid="view-details"
              onClick={(e: React.MouseEvent) => {
                e.stopPropagation();
                history.push(`/u/get-care-now/booking/provider-details/${providerId}`);
              }}
            >
              View details
            </MuiLink>
          </MuiGrid>
        </MuiGrid>
        {offersVideoVisits ? (
          <MuiGrid container direction="row" alignItems="center" item xs={12} md>
            <Svg set="assets" name="VideoVisit" color={Color.black} />
            <MuiBox ml={0.5}>
              <MuiTypography
                data-testid="provider-has-video-visits-available"
                fontSize={FontSize.small}
              >
                Video visits available
              </MuiTypography>
            </MuiBox>
          </MuiGrid>
        ) : (
          <MuiGrid item xs={12} md />
        )}
        {primaryLocation?.isVirtualClinic === false || primaryLocation?.name ? (
          <MuiGrid item style={{ marginLeft: -5 }}>
            <Svg name="DirectionIcon" size={IconSize.base} />
          </MuiGrid>
        ) : null}
        <MuiGrid container item direction="column" xs>
          <MuiTypography fontSize={FontSize.small} data-testid="provider-primary-distance">
            {showDistance(primaryLocation, selectedSpecialty, hasLocation)
              ? locationDistance(primaryLocation?.distance)
              : null}
          </MuiTypography>
          <MuiTypography fontSize={FontSize.small} data-testid="provider-primary-location">
            {primaryLocation?.name}
          </MuiTypography>
        </MuiGrid>
        <MuiBox>
          <Svg color={Color.black} name="ChevronRight" size={IconSize.xmedium} />
        </MuiBox>
      </MuiGrid>
    </MuiGrid>
  );
};

const mapStateToProps = (state: RootState) => ({
  currentUrl: currentLocationPathNameSelector(state),
  referringUrl: previousLocationPathNameSelector(state)
});

export default connect(mapStateToProps)(SpecialtyProviderPanel);
