export default {
  formId: 'CheckInForm',
  formField: {
    lastName: {
      name: 'lastName',
      label: 'Patient Last Name',
      requiredErrorMsg: 'Patient Last Name is required'
    },
    dob: {
      name: 'dob',
      label: 'Patient Date of Birth (MM/DD/YYYY)',
      requiredErrorMsg: 'Date of Birth is required'
    },
    zipCode: {
      name: 'zipCode',
      label: 'Billing ZIP Code',
      requiredErrorMsg: 'Please enter a valid ZIP code'
    }
  }
};
