import dayjs from 'dayjs';
import { MuiBox, MuiCard, MuiCardContent, MuiGrid, MuiTypography } from 'theme/material-ui';
import upperFirst from 'lodash/upperFirst';
import { FontSize, FontWeight, Spacing } from 'modules/styles/variables';
import * as React from 'react';
import { degreeSymbol } from 'screens/VisitSummary/constants';
import { ValueUnits, VitalsBlock } from 'store/vitals/types';
import styled from 'styled-components';
import { Dot } from './styled';
import { convertToLowerKabobCase } from 'modules/utils/StringUtils';

export interface BlockItemProps {
  block: VitalsBlock;
  onPress: () => void;
}

const getFormattedBlockDate = (date: Date | string) => {
  if (!date) {
    return 'N/A';
  }

  return dayjs(date).format('MM/DD/YYYY');
};

const getFormattedVitalCategory = (category: string) => {
  if (!category) {
    return 'N/A';
  }

  return upperFirst(category.toLowerCase());
};

const isDegreeMeasurement = (unit: string) => unit.toLowerCase().includes('deg');

const StyledMuiTypography = styled(MuiTypography)`
  &.MuiTypography-root {
    display: inline-block;
    margin-right: ${Spacing.small}px;
  }
`;

const BlockItemValueUnits = ({ values }: { values: ValueUnits[] }) => (
  <>
    {values.map(item => (
      <React.Fragment key={item.units}>
        <StyledMuiTypography>{item.value}</StyledMuiTypography>

        {isDegreeMeasurement(item.units) ? (
          <StyledMuiTypography>{degreeSymbol}F</StyledMuiTypography>
        ) : (
          <StyledMuiTypography>{item.units}</StyledMuiTypography>
        )}
      </React.Fragment>
    ))}
  </>
);

export const BlockItem = ({
  block: { title, statusColor, statusText, valueUnits, date },
  onPress
}: BlockItemProps) => (
  <MuiGrid item xs={12} sm={6} md={4}>
    <MuiCard data-testid="block-item" onClick={onPress} style={{ height: '100%' }}>
      <MuiCardContent>
        <MuiTypography>{getFormattedBlockDate(date)}</MuiTypography>

        <MuiTypography
          data-testid={convertToLowerKabobCase(title, '-block-item')}
          fontSize={FontSize.heading}
          fontWeight={FontWeight.semibold}
        >
          {getFormattedVitalCategory(title)}
        </MuiTypography>

        <MuiBox data-testid={convertToLowerKabobCase(title, '-block-item-units')} display="block">
          <BlockItemValueUnits values={valueUnits} />
        </MuiBox>

        {statusColor ? (
          <MuiBox display="flex" alignItems="center">
            <Dot color={statusColor} />
            <MuiTypography>{statusText}</MuiTypography>
          </MuiBox>
        ) : null}
      </MuiCardContent>
    </MuiCard>
  </MuiGrid>
);

export default BlockItem;
