import React from 'react';
import { Color } from 'modules/styles/colors';
import { SvgProps } from 'components/UI/Svg/Svg';

export default (props: SvgProps | undefined) => {
  return {
    svg: (
      <g>
        <path
          fill={
            props?.active
              ? 'url(#a)'
              : props?.fill
              ? props.fill
              : props?.disabled
              ? Color.grayHue5
              : Color.primary
          }
          fillRule="evenodd"
          d="M8.13.805a3.726 3.726 0 0 1 4.858.996h.762a1.5 1.5 0 0 1 1.5 1.5h2.1a2.323 2.323 0 0 1 2.4 2.25v16.535a2.323 2.323 0 0 1-2.4 2.215H2.65a2.323 2.323 0 0 1-2.4-2.25v-16.5a2.323 2.323 0 0 1 2.4-2.25h2.1a1.5 1.5 0 0 1 1.5-1.5h.762c.3-.403.68-.742 1.118-.996ZM4.75 4.801H2.606a.823.823 0 0 0-.856.77v16.46a.823.823 0 0 0 .886.77h14.728l.03.001a.823.823 0 0 0 .856-.77V5.572a.823.823 0 0 0-.886-.77H15.25v1.5a1.5 1.5 0 0 1-1.5 1.5h-7.5a1.5 1.5 0 0 1-1.5-1.5v-1.5Zm9 1.5h-7.5v-3h1.166a.75.75 0 0 0 .651-.378 2.226 2.226 0 0 1 3.866 0 .75.75 0 0 0 .651.378h1.166v3Zm-5.56 3.44a1.5 1.5 0 0 1 1.06-.44h1.5a1.5 1.5 0 0 1 1.5 1.5v1.5h1.5a1.5 1.5 0 0 1 1.5 1.5v1.5a1.5 1.5 0 0 1-1.5 1.5h-1.5v1.5a1.5 1.5 0 0 1-1.5 1.5h-1.5a1.5 1.5 0 0 1-1.5-1.5v-1.5h-1.5a1.5 1.5 0 0 1-1.5-1.5v-1.5a1.5 1.5 0 0 1 1.5-1.5h1.5v-1.5c0-.397.158-.779.44-1.06Zm1.06 1.06v2.25a.75.75 0 0 1-.75.75H6.25v1.5H8.5a.75.75 0 0 1 .75.75v2.25h1.5v-2.25a.75.75 0 0 1 .75-.75h2.25v-1.5H11.5a.75.75 0 0 1-.75-.75v-2.25h-1.5Z"
          clipRule="evenodd"
        />
        <defs>
          <linearGradient
            id="a"
            x1={0.25}
            x2={19.75}
            y1={12.001}
            y2={12.001}
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0.084} stopColor="#FF0048" />
            <stop offset={0.386} stopColor="#DF36FF" />
            <stop offset={0.98} stopColor="#4A00E2" />
          </linearGradient>
        </defs>
      </g>
    ),
    viewBox: '0 0 20 25'
  };
};
