import ProviderAffiliations from 'components/FindProvider/ProviderAffiliations/ProviderAffiliations';
import ProviderComments from 'components/FindProvider/ProviderComments/ProviderComments';
import { ProviderDetailsBanner } from 'components/FindProvider/ProviderDetailsBanner/ProviderDetailsBanner';
import { ProviderProfessions } from 'components/FindProvider/ProviderProfessions/ProviderProfessions';
import ProviderLocations from 'components/FindProvider/ProviderLocations/ProviderLocations';
import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { NavigationScreenProps } from 'screens/navigation';
import { ID_TYPE_CORPPROV } from 'store/findProvider/constants';
import { RootState } from 'store/types';
import * as authSelectors from 'store/authentication/selectors';
import * as actions from 'store/findProvider/actions';
import * as selectors from 'store/findProvider/selectors';
import * as types from 'store/findProvider/types';
import { Color } from 'modules/styles/colors';

import { MuiBox, MuiContainer } from 'theme/material-ui';
import DataLoader from 'components/UI/DataLoader/DataLoader';
import { ProviderLoading, ProviderError, ProviderNoData } from './shared';
import analyticsService, {
  AnalyticsEvent,
  AmplitudeEventData,
  AnalyticsUserStat
} from 'services/AnalyticsService';
import { StyledMuiContainer, StyledMuiGrid } from './styled';
import {
  currentLocationPathNameSelector,
  previousLocationPathNameSelector
} from 'store/router/selectors';

export interface Props extends NavigationScreenProps {
  isFetching: boolean;
  isDfdAuthenticated: boolean;
  getProviderDetails: typeof actions.getProviderDetails;
  resetProviderDetail: typeof actions.resetProviderDetail;
  provider: types.ProviderDetail;
  allAffiliatedHospitals: types.AffiliatedHospital[];
  locations: types.ProviderLocation[];
  currentUrl?: string;
  referringUrl?: string;
}

const ProviderDetailsScreen = (props: Props) => {
  const {
    isFetching,
    isDfdAuthenticated,
    getProviderDetails,
    resetProviderDetail,
    provider,
    allAffiliatedHospitals,
    locations,
    currentUrl,
    referringUrl,
    history
  } = props;
  const { id: providerId } = props.match.params;

  const criteria: types.ProviderDetailCriteria = {
    id: providerId,
    idType: ID_TYPE_CORPPROV
  };

  useEffect(() => {
    getProviderDetails(criteria);

    return () => {
      resetProviderDetail();
    };
  }, [providerId]);

  useEffect(() => {
    if (provider && Object.keys(provider).length) {
      const data: AmplitudeEventData = {
        referringUrl,
        currentUrl,
        provider: provider?.displayName,
        provider_specialty: provider?.primarySpecialty?.specialtyName,
        rating: provider?.ratings?.overallRating
      };

      analyticsService.incrementUserStat(AnalyticsUserStat.ProviderPagesSeen);
      analyticsService.logEvent(AnalyticsEvent.ProviderDetailViewed, data);
    }
  }, [provider]);

  useEffect(() => {
    const handleGoBackBrowserBtn = () => history.replace('/u/find-a-doctor');
    window.addEventListener('popstate', handleGoBackBrowserBtn);
    return () => window.removeEventListener('popstate', handleGoBackBrowserBtn);
  }, []);

  const providerCommentsRef = useRef(null);
  const scrollToProviderComments = () => {
    providerCommentsRef?.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <MuiBox component="main" width="100%">
      <DataLoader
        loading={isFetching}
        data={provider?.noProvider ? {} : provider}
        renderLoading={() => <ProviderLoading />}
        renderError={() => <ProviderError />}
        renderNoData={() => <ProviderNoData />}
        renderData={() => (
          <StyledMuiContainer maxWidth={false} style={{ backgroundColor: Color.baseColor }}>
            <ProviderDetailsBanner
              provider={provider}
              isAuthenticated={isDfdAuthenticated}
              scrollToProviderComments={scrollToProviderComments}
            />
            <MuiContainer maxWidth="lg">
              <StyledMuiGrid container justify="center" alignItems="center" alignContent="center">
                <ProviderProfessions provider={provider} />
                {locations?.length > 0 ? <ProviderLocations locations={locations} /> : null}
                {props.allAffiliatedHospitals.length > 0 ? (
                  <ProviderAffiliations affiliations={allAffiliatedHospitals} />
                ) : null}
                <ProviderComments criteria={criteria} providerCommentsRef={providerCommentsRef} />
              </StyledMuiGrid>
            </MuiContainer>
          </StyledMuiContainer>
        )}
      />
    </MuiBox>
  );
};

const mapStateToProps = (state: RootState) => ({
  isFetching: selectors.providerDetailFetchingSelector(state),
  isDfdAuthenticated: authSelectors.isDfdAuthenticatedSelector(state),
  provider: selectors.providerSelector(state),
  allAffiliatedHospitals: selectors.providerAllAffiliatedHospitalsSelector(state),
  locations: selectors.getProviderLocations(state),
  currentUrl: currentLocationPathNameSelector(state),
  referringUrl: previousLocationPathNameSelector(state)
});

const mapDispatchToProps = {
  getProviderDetails: actions.getProviderDetails,
  resetProviderDetail: actions.resetProviderDetail
};

export default connect(mapStateToProps, mapDispatchToProps)(ProviderDetailsScreen);
