import styled from 'styled-components';

import { MuiDialog, MuiDialogActions } from 'theme/material-ui';

export const StyledMuiDialog = styled(MuiDialog)`
  @media (max-width: 600px) {
    width: 100vw;
    height: 100vh;
  }
`;

export const StyledMuiDialogActions = styled(MuiDialogActions)`
  display: flex;
  flex-direction: row;
  justify-content: space-between !important;
`;
