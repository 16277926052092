import React from 'react';
import { InfoCard } from 'components/InfoCard';
import { useSelector } from 'react-redux';
import * as bookingSelectors from 'store/booking/selectors';
import { Quicklink, QuicklinkData } from './Quicklink/Quicklink';
import { StyledBox } from './styled';
import { DASHBOARD_QUICKLINKS, QuickLink } from 'lib/constants/dashboard';
import analyticsService, { AnalyticsEvent } from 'services/AnalyticsService';
import useBookingNavigationSetup from 'hooks/useBookingNavigationSetup';
import { useHistory } from 'react-router';
import { useLanguageSwitcher } from 'lib/hooks/useLanguageSwitcher';
import { DashboardQuicklinks as translations } from 'lib/constants/translations/components/dashboard';

export const DashboardQuicklinks = () => {
  const history = useHistory();
  const patients = useSelector(bookingSelectors.patientsDataSelector);
  const hasProxies = patients.length > 1;
  const quicklinksList = Object.values(DASHBOARD_QUICKLINKS);
  const navigateToBooking = useBookingNavigationSetup();
  const screenText = useLanguageSwitcher(translations);

  const onSchedulePressed = (link?: string) => {
    navigateToBooking();
    const eventData = {
      currentUrl: hasProxies ? link : 'BookingSpecialtyOrMyDoctorSelect',
      referringUrl: 'DASHBOARD'
    };
    analyticsService.logEvent(AnalyticsEvent.BookAppointmentStarted, eventData);
  };

  const handleOnQuicklinkClicked = ({ link = '', params }: QuicklinkData) => {
    switch (link) {
      case DASHBOARD_QUICKLINKS.SCHEDULE_APPOINTMENT.webPath:
        onSchedulePressed(link);
        break;
      default:
        history.push(link, params);
    }
  };

  return (
    <InfoCard title={screenText?.title}>
      <StyledBox>
        {quicklinksList.map((quicklink: QuickLink, index: Number) => (
          <Quicklink
            key={quicklink.label}
            iconName={quicklink.iconName}
            label={screenText[`link_${index}`]}
            link={quicklink.webPath}
            labelWidth={quicklink.labelWidth}
            onQuicklinkClicked={handleOnQuicklinkClicked}
            analyticsTitle={quicklink.label}
          />
        ))}
      </StyledBox>
    </InfoCard>
  );
};

export default DashboardQuicklinks;
