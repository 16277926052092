import * as Yup from 'yup';

const nameRegExp = /^[A-Za-z0-9-,’' .]*$/;
const specialCharErrorMessage = 'No special characters';

export const notSelf = (val: string) => ['spouse', 'parent', 'other'].includes(val);
export const isMarried = (val: string) => ['M'].includes(val);

export const billsValidateSchema = Yup.object().shape({
  accountNbr: Yup.string(),
  provider: Yup.string(),
  balance: Yup.number().typeError('Account balance should only be numbers')
});

export const submitInformationValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Please enter a valid email')
    .notRequired(),
  retypeEmail: Yup.string()
    .notRequired()
    .when('email', {
      is: val => !!val,
      then: Yup.string()
        .email()
        .required('Please verify e-mail')
        .oneOf([Yup.ref('email'), ''], 'E-mail and verify e-mail should be same')
    }),
  guarantorName: Yup.string()
    .matches(nameRegExp, specialCharErrorMessage)
    .required('Please sign after reviewing'),
  readMessage: Yup.boolean().oneOf([true], 'Field must be checked'),
  esign: Yup.boolean().oneOf([true], 'Field must be checked')
});

export const basicInformationValidationSchema = Yup.object().shape({
  patientFirstName: Yup.string()
    .matches(nameRegExp, specialCharErrorMessage)
    .notRequired()
    .when('relationship', {
      is: val => notSelf(val),
      then: Yup.string().required("Please enter the patient's first name")
    }),
  patientMiddle: Yup.string()
    .matches(/^[a-zA-Z]*$/, 'No numbers or special characters')
    .notRequired(),
  patientLastName: Yup.string()
    .matches(nameRegExp, specialCharErrorMessage)
    .notRequired()
    .when('relationship', {
      is: val => notSelf(val),
      then: Yup.string().required("Please enter the patient's last name")
    }),
  patientSSN: Yup.string()
    .matches(/^\d{3}-\d{2}-\d{4}$/, 'SSN must match format: XXX-XX-XXXX')
    .notRequired(),
  patientBirthDate: Yup.date()
    .typeError('Please enter a valid date')
    .notRequired()
    .when('relationship', {
      is: val => notSelf(val),
      then: Yup.date().required('Please enter a valid date of birth')
    })
    .max(new Date(), 'Please enter a valid date of birth'),

  relationship: Yup.string().required('Please select relationship to patient'),

  relationshipExplain: Yup.string()
    .notRequired()
    .when('relationship', {
      is: val => val === 'other',
      then: Yup.string().required('Please explain your relationship')
    }),
  guarantorFirstName: Yup.string()
    .matches(nameRegExp, specialCharErrorMessage)
    .notRequired()
    .when('relationship', {
      is: val => notSelf(val),
      then: Yup.string().required("Please enter the responsible party's first name")
    }),
  guarantorMiddle: Yup.string()
    .matches(/^[a-zA-Z]*$/, 'No numbers or special characters')
    .notRequired(),
  guarantorLastName: Yup.string()
    .matches(nameRegExp, specialCharErrorMessage)
    .notRequired()
    .when('relationship', {
      is: val => notSelf(val),
      then: Yup.string().required("Please enter the responsible party's last name")
    }),
  guarantorSSN: Yup.string()
    .matches(/^\d{3}-\d{2}-\d{4}$/, 'SSN must match format: XXX-XX-XXXX')
    .notRequired(),
  guarantorBirthDate: Yup.date()
    .typeError('Please enter a valid date')
    .notRequired()
    .when('relationship', {
      is: val => notSelf(val),
      then: Yup.date()
        .typeError('Please enter a valid date')
        .required('Please enter a valid date of birth')
    })
    .max(new Date(), 'Please enter a valid date of birth'),

  homePhone: Yup.string()
    .matches(/^\(\d{3}\) \d{3}-\d{4}$/, 'Phone must match format (XXX) XXX-XXXX')
    .required('Please enter a valid phone number'),
  workPhone: Yup.string()
    .matches(/^\(\d{3}\) \d{3}-\d{4}$/, 'Phone must match format (XXX) XXX-XXXX')
    .notRequired(),
  cellPhone: Yup.string()
    .matches(/^\(\d{3}\) \d{3}-\d{4}$/, 'Phone must match format (XXX) XXX-XXXX')
    .notRequired(),

  addressLineOne: Yup.string()
    .matches(/^[a-zA-Z0-9\- .,'@#()]*$/, specialCharErrorMessage)
    .required('Please enter a valid address'),
  addressLineTwo: Yup.string()
    .matches(/^[a-zA-Z0-9\- .,'@#()]*$/, specialCharErrorMessage)
    .notRequired(),
  city: Yup.string()
    .matches(/^[a-zA-Z\-.' ]*$/, 'No numbers or special characters')
    .required('Please enter a valid city'),
  zip: Yup.string()
    .matches(/^\d{5}|\d{5}-\d{4}$/, 'Please enter a valid zip code (XXXXX or XXXXX-XXXX)')
    .required('Please enter a valid zip code'),
  state: Yup.string().required('Please select a state'),

  employer: Yup.string()
    .matches(/^[a-zA-Z0-9/ -.,'#()]*$/, specialCharErrorMessage)
    .notRequired(),

  addressLength: Yup.string().required('Please select your time at current address'),

  extra1AddressLineOne: Yup.string()
    .notRequired()
    .when('addressLength', {
      is: val => val === '1',
      then: Yup.string()
        .matches(/^[a-zA-Z0-9\- .,'@#()]*$/, specialCharErrorMessage)
        .required('Please enter a valid address')
    }),
  extra1AddressLineTwo: Yup.string()
    .matches(/^[a-zA-Z0-9\- .,'@#()]*$/, specialCharErrorMessage)
    .notRequired(),
  extra1AddressCity: Yup.string()
    .notRequired()
    .when('addressLength', {
      is: val => val === '1',
      then: Yup.string()
        .matches(/^[a-zA-Z\-.' ]*$/, 'No numbers or special characters')
        .required('Please enter a valid city')
    }),
  extra1AddressZip: Yup.string()
    .notRequired()
    .when('addressLength', {
      is: val => val === '1',
      then: Yup.string()
        .matches(/^[0-9]{5}(-[0-9]{4})?$/, 'Please enter a valid zip code (XXXXX or XXXXX-XXXX)')
        .required('Please enter a valid zip code')
    }),
  extra1AddressState: Yup.string()
    .notRequired()
    .when('addressLength', {
      is: val => val === '1',
      then: Yup.string().required('Please select a state')
    }),
  extra1AddressFrom: Yup.date()
    .typeError('Please enter a valid date')
    .notRequired()
    .when('addressLength', {
      is: val => val === '1',
      then: Yup.date()
        .typeError('Please enter a valid date')
        .required('Please enter a valid date')
    })
    .max(new Date(), 'Please enter a valid date'),
  extra1AddressTo: Yup.date()
    .typeError('Please enter a valid date')
    .notRequired()
    .when('addressLength', {
      is: val => val === '1',
      then: Yup.date()
        .typeError('Please enter a valid date')
        .required('Please enter a valid date')
    })
    .max(new Date(), 'Please enter a valid date'),

  extra2AddressLineOne: Yup.string()
    .matches(/^[a-zA-Z0-9\- .,'@#()]*$/, specialCharErrorMessage)
    .notRequired(),
  extra2AddressLineTwo: Yup.string()
    .matches(/^[a-zA-Z0-9\- .,'@#()]*$/, specialCharErrorMessage)
    .notRequired(),
  extra2AddressCity: Yup.string()
    .matches(/^[a-zA-Z\-.' ]*$/, 'No numbers or special characters')
    .notRequired(),
  extra2AddressZip: Yup.string()
    .matches(/^[0-9]{5}(-[0-9]{4})?$/, 'Please enter a valid zip code (XXXXX or XXXXX-XXXX)')
    .notRequired(),
  extra2AddressState: Yup.string().notRequired(),
  extra2AddressFrom: Yup.date()
    .typeError('Please enter a valid date')
    .notRequired()
    .when('extra2AddressLineOne', {
      is: val => !!val,
      then: Yup.date()
        .typeError('Please enter a valid date')
        .required('Please enter a valid date')
    })
    .max(new Date(), 'Please enter a valid date'),
  extra2AddressTo: Yup.date()
    .typeError('Please enter a valid date')
    .notRequired()
    .when('extra2AddressLineOne', {
      is: val => !!val,
      then: Yup.date()
        .typeError('Please enter a valid date')
        .required('Please enter a valid date')
    })
    .max(new Date(), 'Please enter a valid date')
});

export const householdValidationSchema = Yup.object().shape({
  maritalStatus: Yup.string().required('Please select marital status'),

  spouseFirstName: Yup.string()
    .notRequired()
    .when('maritalStatus', {
      is: val => isMarried(val),
      then: Yup.string()
        .matches(nameRegExp, specialCharErrorMessage)
        .required("Please enter spouse's first name")
    }),
  spouseMiddle: Yup.string()
    .matches(/^[a-zA-Z]*$/, 'No numbers or special characters')
    .notRequired(),
  spouseLastName: Yup.string()
    .notRequired()
    .when('maritalStatus', {
      is: val => isMarried(val),
      then: Yup.string()
        .matches(nameRegExp, specialCharErrorMessage)
        .required("Please enter spouse's last name")
    }),
  spouseSSN: Yup.string()
    .matches(/^\d{3}-\d{2}-\d{4}$/, 'SSN must match format: XXX-XX-XXXX')
    .notRequired(),
  spouseBirthDate: Yup.date()
    .typeError('Please enter a valid birthday')
    .notRequired()
    .when('maritalStatus', {
      is: val => isMarried(val),
      then: Yup.date()
        .typeError('Please enter a valid date')
        .required('Please enter a valid birthday')
    }),

  spouseHomePhone: Yup.string()
    .notRequired()
    .when('maritalStatus', {
      is: val => isMarried(val),
      then: Yup.string()
        .matches(/^\(\d{3}\) \d{3}-\d{4}$/, 'Phone must match format (XXX) XXX-XXXX')
        .required('Please enter a valid phone number')
    }),
  spouseWorkPhone: Yup.string()
    .matches(/^\(\d{3}\) \d{3}-\d{4}$/, 'Phone must match format (XXX) XXX-XXXX')
    .notRequired(),
  spouseCellPhone: Yup.string()
    .matches(/^\(\d{3}\) \d{3}-\d{4}$/, 'Phone must match format (XXX) XXX-XXXX')
    .notRequired(),
  spouseEmployer: Yup.string().notRequired(),

  members: Yup.array()
    .of(
      Yup.object()
        .shape({
          name: Yup.string()
            .matches(nameRegExp, 'No numbers or special characters')
            .nullable(),

          birthDate: Yup.date()
            .typeError('Please enter a valid date')
            .nullable(),
          relationship: Yup.string()
            .matches(/^[a-zA-Z -]*$/, 'No numbers or special characters')
            .nullable()
        })
        .nullable()
    )
    .notRequired(),
  hasIncome: Yup.boolean(),
  grossIncome: Yup.string()
    .matches(/^[0-9.]*$/, 'Only numbers')
    .required('Required'),
  spouseIncome: Yup.string()
    .notRequired()
    .when('maritalStatus', {
      is: val => isMarried(val),
      then: Yup.string()
        .matches(/^[0-9.]*$/, 'Only numbers')
        .required('Required')
    }),
  hasPension: Yup.boolean().notRequired(),
  pension: Yup.string()
    .notRequired()
    .when('hasPension', {
      is: true,
      then: Yup.string()
        .matches(/^[0-9.]*$/, 'Only numbers')
        .required('Required')
    }),
  hasChildSupport: Yup.boolean().notRequired(),
  childSupport: Yup.string()
    .notRequired()
    .when('hasChildSupport', {
      is: true,
      then: Yup.string()
        .matches(/^[0-9.]*$/, 'Only numbers')
        .required('Required')
    }),
  hasAlimony: Yup.boolean().notRequired(),
  alimony: Yup.string()
    .notRequired()
    .when('hasAlimony', {
      is: true,
      then: Yup.string()
        .matches(/^[0-9.]*$/, 'Only numbers')
        .required('Required')
    }),
  hasGrants: Yup.boolean().notRequired(),
  grants: Yup.string()
    .notRequired()
    .when('hasGrants', {
      is: true,
      then: Yup.string()
        .matches(/^[0-9.]*$/, 'Only numbers')
        .required('Required')
    }),
  grantPeriod: Yup.string()
    .notRequired()
    .when('grants', {
      is: val => val > 0,
      then: Yup.string().required('Required')
    }),
  hasOther: Yup.boolean().notRequired(),
  otherIncome: Yup.string()
    .notRequired()
    .when('hasOther', {
      is: true,
      then: Yup.string()
        .matches(/^[0-9.]*$/, 'Only numbers')
        .required('Required')
    }),
  otherIncomeSource: Yup.string()
    .notRequired()
    .when('otherIncome', {
      is: val => val > 0,
      then: Yup.string().required('Required')
    }),
  otherPeriod: Yup.string()
    .notRequired()
    .when('otherIncome', {
      is: val => val > 0,
      then: Yup.string().required('Please select yearly or monthly')
    })
});

export const assetsValidationSchema = Yup.object().shape({
  cash: Yup.string()
    .matches(/^[0-9.]*$/, 'Only numbers')
    .required('Required'),
  cashInstitution: Yup.string()
    .notRequired()
    .when('cash', {
      is: val => val > 0,
      then: Yup.string().required('Required')
    }),
  saving: Yup.string()
    .matches(/^[0-9.]*$/, 'Only numbers')
    .required('Required'),
  savingInstitution: Yup.string()
    .notRequired()
    .when('saving', {
      is: val => val > 0,
      then: Yup.string().required('Required')
    }),
  checking: Yup.string()
    .matches(/^[0-9.]*$/, 'Only numbers')
    .required('Required'),
  checkingInstitution: Yup.string()
    .notRequired()
    .when('checking', {
      is: val => val > 0,
      then: Yup.string().required('Required')
    }),
  stocks: Yup.string()
    .matches(/^[0-9.]*$/, 'Only numbers')
    .required('Required'),
  stocksInstitution: Yup.string()
    .notRequired()
    .when('stocks', {
      is: val => val > 0,
      then: Yup.string().required('Required')
    }),

  hasMedicare: Yup.string().required('Required'),
  k401: Yup.string()
    .notRequired()
    .when('hasMedicare', {
      is: val => val === 'Y',
      then: Yup.string()
        .required('Required')
        .matches(/^[0-9.]*$/, 'Only numbers')
    }),
  k401Institution: Yup.string()
    .notRequired()
    .when('hasMedicare', {
      is: val => val === 'Y',
      then: Yup.string().required('Required')
    }),
  ira: Yup.string()
    .notRequired()
    .when('hasMedicare', {
      is: val => val === 'Y',
      then: Yup.string()
        .required('Required')
        .matches(/^[0-9.]*$/, 'Only numbers')
    }),
  iraInstitution: Yup.string()
    .notRequired()
    .when('hasMedicare', {
      is: val => val === 'Y',
      then: Yup.string().required('Required')
    }),
  // eslint-disable-next-line react/forbid-prop-types
  checked: Yup.array(),
  insuranceCompany: Yup.string()
    .notRequired()
    .when('checked', {
      is: val => val && val.includes('hasInsured'),
      then: Yup.string().required('Required')
    }),
  otherCompany: Yup.string()
    .notRequired()
    .when('checked', {
      is: val => val && val.includes('hasOtherInsured'),
      then: Yup.string().required('Required')
    }),
  flexBalance: Yup.string()
    .notRequired()
    .when('checked', {
      is: val => val && val.includes('hasFlex'),
      then: Yup.string()
        .required('Required')
        .matches(/^[0-9.]*$/, 'Only numbers')
    })
});
export const otherFundingValidationSchema = Yup.object().shape({
  // eslint-disable-next-line react/forbid-prop-types
  checked: Yup.array(),
  insuranceCompany: Yup.string()
    .notRequired()
    .when('checked', {
      is: val => val && val.includes('hasInsured'),
      then: Yup.string().required('Required')
    }),
  otherCompany: Yup.string()
    .notRequired()
    .when('checked', {
      is: val => val && val.includes('hasOtherInsured'),
      then: Yup.string().required('Required')
    }),
  flexBalance: Yup.string()
    .notRequired()
    .when('checked', {
      is: val => val && val.includes('hasFlex'),
      then: Yup.string()
        .required('Required')
        .matches(/^[0-9.]*$/, 'Only numbers')
    })
});
