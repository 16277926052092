import CloseButton from 'components/UI/Button/CloseButton';
import Box from 'components/UI/Layout/Box';
import FlexBox from 'components/UI/Layout/FlexBox';
import SpinnerModal from 'components/UI/Spinner/SpinnerModal';
import Svg from 'components/UI/Svg/Svg';
import Typography from 'components/UI/Typography';
import { Color } from 'modules/styles/colors';
import { BorderRadius, FontSize, FontWeight, Spacing } from 'modules/styles/variables';
import React, { ReactNode } from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import styled from 'styled-components';
import { dfdDefaultTheme, MuiButton } from 'theme/material-ui';
import WebMuiModal from './WebMuiModal';

const ConfirmButton = styled(MuiButton)`
  display: flex;
  width: 50%;
  ${dfdDefaultTheme.breakpoints.down('sm')} {
    width: 100%;
  }
  && {
    margin-bottom: ${Spacing.large}px;
    font-size: ${FontSize.base}px;
  }
`;

const StyledFlexBox = styled(FlexBox)`
  height: 300px;
  ${dfdDefaultTheme.breakpoints.down('sm')} {
    height: auto;
  }
`;

interface AdditionalButtonProps {
  title: string;
  action: () => void;
}

export interface ConfirmModalProps {
  title?: string | ReactNode;
  description?: string | ReactNode;
  icon?: string;
  iconColor?: string;
  isOpen: boolean;
  isLoading?: boolean;
  onConfirm?: () => void;
  onCancel?: () => void | Function;
  confirmLabel?: string | JSX.Element;
  cancelLabel?: string;
  style?: StyleProp<ViewStyle>;
  position?: 'middle' | 'bottom';
  showCloseIcon?: boolean;
  subTitleElement?: ReactNode;
  backdropColor?: string;
  fullWidth?: boolean;
  maxWidth?: string;
  additionalButtons?: AdditionalButtonProps[];
  buttonConfirmClassName?: string;
  buttonOutlinedClassName?: string;
  buttonTextClassName?: string;
}
export const ConfirmModal = (props: ConfirmModalProps) => {
  const {
    title,
    icon,
    iconColor,
    description,
    isOpen,
    isLoading,
    confirmLabel,
    onConfirm,
    cancelLabel,
    onCancel,
    style,
    showCloseIcon,
    position,
    subTitleElement,
    backdropColor,
    maxWidth,
    additionalButtons = [],
    buttonConfirmClassName,
    buttonOutlinedClassName,
    buttonTextClassName
  } = props;
  const renderTitle = () => {
    if (!title) return null;
    if (typeof title === 'string') {
      return (
        <Box vSpacingBottom={Spacing.large}>
          <Typography
            textAlign="center"
            fontSize={FontSize.heading}
            data-testid="modal-title"
            fontWeight={FontWeight.semibold}
          >
            {title}
          </Typography>
        </Box>
      );
    }
    return title;
  };

  const renderDescription = () => {
    if (!description) return null;
    if (typeof description === 'string') {
      return (
        <Box vSpacingBottom={Spacing.medium}>
          <Typography textAlign="center" fontSize={FontSize.large} data-testid="modal-description">
            {description}
          </Typography>
        </Box>
      );
    }
    return description;
  };

  const renderIcon = () => {
    if (!icon) return null;
    return (
      <FlexBox style={{ marginBottom: 20 }}>
        <Svg
          name={icon}
          set="assets"
          color={iconColor || Color.secondary}
          size={36}
          data-testid="modal-icon"
        />
      </FlexBox>
    );
  };
  const backdropStyles: { backgroundColor?: string } = {};
  if (backdropColor) {
    backdropStyles.backgroundColor = backdropColor;
  }

  const modalStyles: { display?: string; justifyContent?: string } = {};

  if (maxWidth) {
    modalStyles.display = 'flex';
    modalStyles.justifyContent = 'center';
  }

  return (
    <WebMuiModal
      open={isOpen}
      handleClose={onCancel}
      position={position}
      maxWidth={maxWidth}
      BackdropProps={{ style: backdropStyles }}
      style={modalStyles}
    >
      <StyledFlexBox
        height={300}
        borderRadius={BorderRadius.base}
        spacing={props.fullWidth ? '30px 0px' : '30px 60px'}
        alignItems="center"
        justifyContent="center"
        backgroundColor={Color.white}
        style={style}
      >
        {showCloseIcon ? (
          <CloseButton
            data-testid="modal-close-button"
            absolute
            onPress={onCancel || onConfirm}
            margin="0px"
            padding={3}
          />
        ) : null}
        <FlexBox
          flex={1}
          width={props.fullWidth ? `100%` : ''}
          alignItems="center"
          style={{ marginBottom: Spacing.large }}
        >
          {renderIcon()}
          {renderTitle()}
          {renderDescription()}
          {subTitleElement ? subTitleElement() : null}
        </FlexBox>

        <FlexBox width="100%" alignItems="center">
          {isLoading ? <SpinnerModal isLoading /> : null}
          {onConfirm && confirmLabel && (
            <ConfirmButton
              className={buttonConfirmClassName || undefined}
              color="primary"
              variant="contained"
              onClick={onConfirm}
              title={typeof confirmLabel === 'string' ? confirmLabel : ''}
              data-testid={typeof confirmLabel === 'string' ? confirmLabel : ''}
              fullWidth
            >
              {confirmLabel}
            </ConfirmButton>
          )}
          {additionalButtons.length
            ? additionalButtons.map(button => (
                <ConfirmButton
                  className={buttonOutlinedClassName || undefined}
                  color="primary"
                  variant="outlined"
                  onClick={button.action}
                  title={button.title}
                  data-testid={button.title}
                  fullWidth
                >
                  {button.title}
                </ConfirmButton>
              ))
            : null}

          {onCancel && cancelLabel && (
            <ConfirmButton
              className={buttonTextClassName || undefined}
              color="primary"
              variant="text"
              onClick={onCancel}
              title={cancelLabel}
              fullWidth
            >
              {cancelLabel}
            </ConfirmButton>
          )}
        </FlexBox>
      </StyledFlexBox>
    </WebMuiModal>
  );
};

ConfirmModal.defaultProps = {
  accessibilityLabel: 'confirm modal',
  showCloseIcon: false,
  isLoading: false,
  position: 'middle',
  confirmLabel: 'Confirm',
  cancelLabel: 'Cancel',
  fullWidth: false
};

export default ConfirmModal;
