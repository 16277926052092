import React from 'react';
import { Color } from 'modules/styles/colors';

const a = {
  fill: Color.black
} as const;

export default {
  svg: (
    <path
      fill="#000"
      fillRule="evenodd"
      d="M3.515 3.514a12 12 0 1 1 16.97 16.97 12 12 0 0 1-16.97-16.97ZM12 1.499a10.5 10.5 0 1 0 0 21 10.5 10.5 0 0 0 0-21Zm6.465 4.74a.75.75 0 0 1 .123 1.054l-7.839 9.918a1.8 1.8 0 0 1-2.917-.085l-2.445-3.471a.75.75 0 0 1 1.226-.864l2.45 3.477.01.015a.3.3 0 0 0 .487.014l.009-.011 7.843-9.923a.75.75 0 0 1 1.053-.123Z"
      clipRule="evenodd"
    />
  ),
  viewBox: '0 0 24 24'
};
