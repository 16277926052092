import React, { useMemo } from 'react';
import { connect } from 'react-redux';

import Svg from 'components/UI/Svg/Svg';
import { Breadcrumb, BreadcrumbProps } from 'components/Breadcrumbs';
import { MuiBox, MuiTypography } from 'theme/material-ui';

import { FontWeight, IconSize } from 'modules/styles/variables.web';

import { RootState } from 'store/types';
import { profileConsumerUserNameSelector } from 'store/profile/selectors';

export interface Props extends BreadcrumbProps {
  consumerName: string;
}

export const ProfileUserNameBreadCrumb = (props: Props) => {
  const { consumerName, ...rest } = props;

  const label = useMemo(() => {
    return consumerName ? `${consumerName}` : 'Account Management';
  }, [consumerName]);

  return (
    <MuiBox display="flex" flexDirection="row" alignItems="center">
      <Breadcrumb {...rest} fontWeight={FontWeight.normal} label={label} />
      <Svg set="material" name="chevron-right" size={IconSize.base} />
      <MuiTypography fontWeight={FontWeight.semibold}>Account Management</MuiTypography>
    </MuiBox>
  );
};

const mapStateToProps = (state: RootState) => ({
  consumerName: profileConsumerUserNameSelector(state)
});

export default connect(mapStateToProps)(ProfileUserNameBreadCrumb);
