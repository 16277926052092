import React from 'react';
import { useHistory } from 'react-router';
import { connect } from 'react-redux';

import RouteLeavingGuard from 'components/UI/Modals/RouteLeavingGuardModal';
import { triageGyantCurrentCaseIdSelector } from 'store/triageGyant/selectors';
import { eVisitSessionTokenSelector } from 'store/eVisit/selectors';
import { RootState } from 'store/types';

import { EVISIT_CONFIRM_DIALOG_TEXT } from 'lib/triage/constants';
import { EVISITS_ROUTES } from 'screens/EVisits/constants';
import { TRIAGE_ROUTES } from 'screens/TriageGyant/constants';

export interface Props {
  currentCaseId: string;
  eVisitSessionToken: string;
}

export const EVisitsLeavingGuard = ({ currentCaseId, eVisitSessionToken }: Props) => {
  const history = useHistory();

  const handleNavigate = () => {
    history.push(`${TRIAGE_ROUTES.RESULTS.BASE_URL}/${currentCaseId}`);
  };

  const mainEVisitsRoutes = [
    EVISITS_ROUTES.OVERVIEW,
    EVISITS_ROUTES.STATE_SELECTOR,
    EVISITS_ROUTES.PHARMACY_SELECTOR,
    EVISITS_ROUTES.REQUEST_SUCCESS
  ];

  return (
    <RouteLeavingGuard
      shouldBlockNavigation={nextLocation =>
        !mainEVisitsRoutes.includes(nextLocation.pathname) && !eVisitSessionToken
      }
      exitTitle={EVISIT_CONFIRM_DIALOG_TEXT.title}
      exitSubtitle={EVISIT_CONFIRM_DIALOG_TEXT.subtitle}
      primaryButtonText={EVISIT_CONFIRM_DIALOG_TEXT.primaryButton}
      secondaryButtonText={EVISIT_CONFIRM_DIALOG_TEXT.secondaryButton}
      navigate={handleNavigate}
      iconType="info"
      closeIcon
    />
  );
};

const mapState = (state: RootState) => ({
  currentCaseId: triageGyantCurrentCaseIdSelector(state),
  eVisitSessionToken: eVisitSessionTokenSelector(state)
});

export default connect(mapState)(EVisitsLeavingGuard);
