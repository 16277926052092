import startCase from 'lodash/startCase';
import toLower from 'lodash/toLower';
import cheerio from 'cheerio';
import { Color } from 'modules/styles/colors';

export function formatDisplayName(name: string) {
  if (name.split(',').length > 1) {
    const arrangedName = name
      .split(',')
      .reverse()
      .map(n => n.trim())
      .join(' ');
    return startCase(toLower(arrangedName));
  }
  return name;
}

export function getFormattedAgreement(html: string) {
  const $ = cheerio.load(html);
  $('body').css({
    'font-family': '-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif',
    margin: '0',
    padding: '0'
  });
  $('p').css({
    'font-family': '-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif',
    'font-size': '13px'
  });
  $('style').append(`
      html, body, #height-calculator {
        margin: 0;
        padding: 0;
        color: ${Color.black};
        box-sizing: border-box;
      }
      *, *:before, *:after {
        box-sizing: inherit;
      }
      #height-calculator {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
      }
    `);

  const scripts = `
    <script>
      window.location.hash = 1;
      var calculator = document.createElement("div");
      calculator.id = "height-calculator";
      while (document.body.firstChild) {
          calculator.appendChild(document.body.firstChild);
      }
      document.body.appendChild(calculator);
      document.title = calculator.clientHeight;
    </script>
  `;

  return $.html() + scripts;
}
