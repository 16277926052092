import React, { useState } from 'react';
import { MuiCard, MuiCardContent, MuiTypography, MuiButton } from 'theme/material-ui';
import { Svg } from 'components/UI/Svg';
import { Alert } from 'components/Alert';
import { ResetAccountAccessModal } from 'components/ResetAccountAccessModal';
import Spacer from 'components/UI/Layout/Spacer';
import styled from 'styled-components';

const StyledMuiCardContent = styled(MuiCardContent)`
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const StyledMuiButton = styled(MuiButton)`
  width: 30%;
`;

export const RefreshUserList = () => {
  const [isResetAccountModalOpen, setIsResetAccountModalOpen] = useState(false);

  const handleResetUserAccount = () => {
    setIsResetAccountModalOpen(true);
  };

  const handleResetUserAccountClosed = () => {
    setIsResetAccountModalOpen(false);
  };

  const handleResetUserAccountError = () => {
    Alert.alert('Failed to reset account. Please try again later.');
  };
  return (
    <>
      <MuiCard data-testid="refresh-user-list">
        <StyledMuiCardContent>
          <Svg name="AddPerson" width="60" height="60" />
          <MuiTypography>Changes to people you have access to are now available</MuiTypography>
          <StyledMuiButton
            onClick={handleResetUserAccount}
            color="primary"
            variant="contained"
            size="small"
          >
            Refresh user list
          </StyledMuiButton>
          <ResetAccountAccessModal
            open={isResetAccountModalOpen}
            onClose={handleResetUserAccountClosed}
            onSuccess={handleResetUserAccountClosed}
            onError={handleResetUserAccountError}
          />
        </StyledMuiCardContent>
      </MuiCard>
      <Spacer size="medium" />
    </>
  );
};
