import React from 'react';

import { Icon } from 'components/Icon';
import styled from 'styled-components';
import { largeFontSize } from 'modules/styles/variables';
import { Color } from 'modules/styles/colors';
import DefaultText from 'components/UI/Typography/DefaultText';

interface Props {
  children: React.ReactNode;
  color: string;
  iconColor: string;
  iconName: string;
  size: number;
  style?: any;
}

const StyledLabel = styled(DefaultText)<{ size: number }>`
  color: ${props => (props.color ? props.color : Color.textLight)};
  flex-direction: row;
  flex-wrap: wrap;
  font-size: ${largeFontSize};
  padding-left: 10px;
  display: inline-block;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  align-items: center;
`;

const IconSubhead = (props: Props) => (
  <Row style={props.style}>
    <Icon
      color={props.iconColor ? props.iconColor : props.color}
      size={props.size}
      name={props.iconName}
    />
    <StyledLabel {...props}>{props.children}</StyledLabel>
  </Row>
);

IconSubhead.defaultProps = {
  color: Color.textLight,
  iconColor: undefined,
  size: 24
};

export default IconSubhead;
