import React from 'react';
import { MuiTypography, MuiContainer, MuiBox } from 'theme/material-ui';

import { Color } from 'modules/styles/colors';
import { FontSize } from 'modules/styles/variables';

interface Props {
  title?: string;
  subtitle?: string;
}

const Header = ({ title, subtitle }: Props) => (
  <MuiContainer maxWidth="lg">
    <MuiBox
      display="flex"
      mt={12}
      p={2}
      borderRadius={4}
      flexDirection="column"
      bgcolor={Color.white}
    >
      <MuiBox>
        <MuiBox>
          <MuiTypography fontSize={FontSize.largeHeading} data-testid="vitals-header-title">
            {`${title} Trendline`}
          </MuiTypography>
        </MuiBox>
        <MuiBox>
          <MuiTypography
            variant="subtitle2"
            fontSize={FontSize.large}
            data-testid="vitals-header-subtitle"
          >
            {subtitle}
          </MuiTypography>
        </MuiBox>
      </MuiBox>
    </MuiBox>
  </MuiContainer>
);

export default Header;
