import React from 'react';
import { Color } from 'modules/styles/colors';
import { SvgProps } from 'components/UI/Svg/Svg';

export default (props: SvgProps | undefined = { color: Color.black }) => ({
  svg: (
    <g fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.52637 11.25H6.51016C6.54306 9.95596 6.65523 8.69365 6.83768 7.5H2.51045C1.96399 8.65031 1.62054 9.91574 1.52637 11.25ZM3.38203 6H7.11159C7.4215 4.55158 7.84098 3.24264 8.351 2.15141C6.32694 2.90163 4.59714 4.25797 3.38203 6ZM10.3554 1.62805C9.66261 2.65457 9.0669 4.17188 8.64789 6H15.3521C14.9331 4.17188 14.3374 2.65457 13.6446 1.62805C13.1088 1.54376 12.5595 1.5 12 1.5C11.4405 1.5 10.8912 1.54376 10.3554 1.62805ZM15.649 2.15141C16.159 3.24263 16.5785 4.55158 16.8884 6H20.618C19.4029 4.25797 17.6731 2.90162 15.649 2.15141ZM21.4896 7.5H17.1623C17.3448 8.69365 17.4569 9.95596 17.4898 11.25H22.4736C22.3795 9.91574 22.036 8.65031 21.4896 7.5ZM22.4736 12.75H17.4922C17.4632 14.0493 17.354 15.3099 17.174 16.5H21.4896C22.036 15.3497 22.3795 14.0843 22.4736 12.75ZM20.618 18H16.9017C16.5944 19.4412 16.177 20.7438 15.6693 21.841C17.6846 21.0893 19.4069 19.7362 20.618 18ZM13.678 22.3666C14.3653 21.3086 14.9533 19.8034 15.3656 18H8.63437C9.0467 19.8034 9.63469 21.3086 10.322 22.3666C10.8684 22.4544 11.4289 22.5 12 22.5C12.5711 22.5 13.1316 22.4544 13.678 22.3666ZM8.33067 21.841C7.82302 20.7438 7.40564 19.4412 7.09828 18H3.38203C4.59307 19.7362 6.31536 21.0893 8.33067 21.841ZM2.51045 16.5H6.82604C6.64596 15.3099 6.53676 14.0493 6.50779 12.75H1.52637C1.62054 14.0843 1.96399 15.3497 2.51045 16.5ZM8.00815 12.75C8.03899 14.0714 8.15639 15.332 8.34399 16.5H15.656C15.8436 15.332 15.961 14.0714 15.9919 12.75H8.00815ZM15.9894 11.25C15.9543 9.93264 15.8337 8.67023 15.644 7.5H8.35598C8.16627 8.67023 8.04566 9.93264 8.01062 11.25H15.9894ZM0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
        fill={props.color || Color.black}
      />
    </g>
  ),
  viewBox: '0 0 24 24'
});
