import * as React from 'react';

export default () => {
  return {
    svg: (
      <g id="prefix__Medication">
        <path
          fill="none"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.2268 9.70673V6.96954H35.0687V9.70673H36.4367V12.4439H13.8588V9.70673H15.2268Z"
          stroke="url(#paint0_linear_2404_1769)"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fill="none"
          d="M15.2267 9.70663H18.6479"
          stroke="url(#paint1_linear_2404_1769)"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fill="none"
          d="M35.0686 26.1276V12.444"
          stroke="url(#paint2_linear_2404_1769)"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fill="none"
          d="M15.2267 12.4435V49.3901H30.2794"
          stroke="url(#paint3_linear_2404_1769)"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fill="none"
          d="M15.2267 20.6539H28.2262V41.1799H15.2267"
          stroke="url(#paint4_linear_2404_1769)"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fill="none"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M32.1936 34.9789C32.1936 31.9555 34.6434 29.5045 37.6668 29.5045C40.6902 29.5045 43.1412 31.9555 43.1412 34.9789C43.1412 36.8836 43.1412 42.6525 43.1412 44.5573C43.1412 47.5806 40.6902 50.0305 37.6668 50.0305C34.6434 50.0305 32.1936 47.5806 32.1936 44.5573C32.1936 42.6525 32.1936 36.8836 32.1936 34.9789Z"
          stroke="url(#paint5_linear_2404_1769)"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fill="none"
          d="M43.1408 39.7676H32.1932"
          stroke="url(#paint6_linear_2404_1769)"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fill="none"
          d="M40.4041 34.9779C40.4041 33.4662 39.1774 32.2407 37.6669 32.2407"
          stroke="url(#paint7_linear_2404_1769)"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <defs>
          <linearGradient
            id="paint0_linear_2404_1769"
            x1={13.8588}
            y1={9.70673}
            x2={36.4367}
            y2={9.70673}
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0.0843751} stopColor="#FF0048" />
            <stop offset={0.386458} stopColor="#DF36FF" />
            <stop offset={0.980208} stopColor="#4A00E2" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_2404_1769"
            x1={15.2267}
            y1={9.70663}
            x2={18.6479}
            y2={9.70663}
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0.0843751} stopColor="#FF0048" />
            <stop offset={0.386458} stopColor="#DF36FF" />
            <stop offset={0.980208} stopColor="#4A00E2" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_2404_1769"
            x1={34.4749}
            y1={19.2858}
            x2={35.6624}
            y2={19.2858}
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0.0843751} stopColor="#FF0048" />
            <stop offset={0.386458} stopColor="#DF36FF" />
            <stop offset={0.980208} stopColor="#4A00E2" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_2404_1769"
            x1={15.2267}
            y1={30.9168}
            x2={30.2794}
            y2={30.9168}
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0.0843751} stopColor="#FF0048" />
            <stop offset={0.386458} stopColor="#DF36FF" />
            <stop offset={0.980208} stopColor="#4A00E2" />
          </linearGradient>
          <linearGradient
            id="paint4_linear_2404_1769"
            x1={15.2267}
            y1={30.9169}
            x2={28.2262}
            y2={30.9169}
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0.0843751} stopColor="#FF0048" />
            <stop offset={0.386458} stopColor="#DF36FF" />
            <stop offset={0.980208} stopColor="#4A00E2" />
          </linearGradient>
          <linearGradient
            id="paint5_linear_2404_1769"
            x1={32.1936}
            y1={39.7675}
            x2={43.1412}
            y2={39.7675}
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0.0843751} stopColor="#FF0048" />
            <stop offset={0.386458} stopColor="#DF36FF" />
            <stop offset={0.980208} stopColor="#4A00E2" />
          </linearGradient>
          <linearGradient
            id="paint6_linear_2404_1769"
            x1={32.1932}
            y1={39.7676}
            x2={43.1408}
            y2={39.7676}
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0.0843751} stopColor="#FF0048" />
            <stop offset={0.386458} stopColor="#DF36FF" />
            <stop offset={0.980208} stopColor="#4A00E2" />
          </linearGradient>
          <linearGradient
            id="paint7_linear_2404_1769"
            x1={37.6669}
            y1={33.6093}
            x2={40.4041}
            y2={33.6093}
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0.0843751} stopColor="#FF0048" />
            <stop offset={0.386458} stopColor="#DF36FF" />
            <stop offset={0.980208} stopColor="#4A00E2" />
          </linearGradient>
        </defs>
      </g>
    ),
    viewBox: '0 0 57 57'
  };
};
