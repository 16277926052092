import React from 'react';
import { MuiBox, MuiTypography } from 'theme/material-ui';
import { Color } from 'modules/styles/colors';
import { FontSize, FontWeight, Spacing } from 'modules/styles/variables';

interface Props {
  title: string;
}

const AvatarScreenTitle = ({ title }: Props) => {
  return (
    <MuiBox
      maxWidth={900}
      marginY={Spacing.small}
      width="100%"
      borderBottom={`1px solid ${Color.white}`}
    >
      <MuiTypography
        fontSize={FontSize.heading}
        fontWeight={FontWeight.regularbold}
        color={Color.white}
      >
        {title}
      </MuiTypography>
    </MuiBox>
  );
};

export default AvatarScreenTitle;
