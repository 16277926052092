import styled from 'styled-components';

import { Color } from 'modules/styles/colors';
import { Spacing } from 'modules/styles/variables';
import { spacings } from 'modules/utils/StylesUtils';

import Svg from 'components/UI/Svg/Svg';

import {
  MuiAppBar,
  MuiButton,
  MuiDialogTitle,
  MuiDivider,
  MuiRating,
  MuiBox
} from 'theme/material-ui';

// ConnectCareProviderProfile
export const AppBar = styled(MuiAppBar)`
  && {
    background-color: ${Color.primary};
  }
`;

export const DialogTitle = styled(MuiDialogTitle)`
  && {
    flex: 1;
  }
`;

export const ProviderRating = styled(MuiRating)`
  && {
    & .MuiRating-iconFilled {
      color: ${Color.white};
    }
  }
`;

export const Divider = styled(MuiDivider)`
  && {
    background-color: ${Color.white};
  }
`;

export const LanguageIcon = styled(Svg)`
  margin-top: 1px;
`;

export const PracticesWrapper = styled(MuiBox)`
  overflow-x: auto;
  margin-right: -${Spacing.large}px;
  margin-left: -${Spacing.large}px;

  & > div {
    display: inline-flex;
    flex-direction: row;
    flex-wrap: nowrap;
    white-space: nowrap;
    overflow-y: hidden;
    padding: ${spacings(1, 2, 3)};
    margin-left: -${Spacing.medium}px;
    margin-right: -${Spacing.medium}px;

    & > div {
      position: relative;
      display: inline-block;
      margin-right: ${Spacing.medium}px;
      margin-left: ${Spacing.medium}px;
    }
  }
`;

export const LoadMoreButton = styled(MuiButton)`
  &.MuiButton-root {
    background-color: ${Color.white};
    width: 180px;
    height: 45px;
  }
`;
