import styled from 'styled-components';
import { dfdDefaultTheme, MuiBox } from 'theme/material-ui';
import StyledButton from 'components/UI/Button/StyledButton';
import Svg from 'components/UI/Svg/Svg';
import { Color } from 'modules/styles/colors';

export const StyledEditContactDiv = styled(MuiBox)`
  width: 18rem;
  height: 25rem;
`;

export const ContactHeader = styled(MuiBox)`
  justify-content: center;
  display: flex;
`;

export const ContactSubmitButton = styled(StyledButton)`
  && {
    text-transform: uppercase;
  }
`;

export const ContactCancelButton = styled(StyledButton)`
  && {
    background-color: transparent;
    border: 1px;
    border-color: gray;
    border-style: solid;
    && > span {
      color: black;
    }
  }
`;

export const LogoSVG = styled(Svg)<{ useSizeBP?: boolean }>`
  width: 253px;
  ${({ useSizeBP }) => {
    return useSizeBP
      ? `${dfdDefaultTheme.breakpoints.between('xs', 'sm')} {
      width: 144px;
    }`
      : '';
  }}
`;

export const RedText = styled.span`
  color: ${Color.red};
`;
