import React, { useEffect, RefObject } from 'react';
import { connect } from 'react-redux';
import Rating from '@material-ui/lab/Rating';
import Panel from 'components/UI/Panel/Panel';
import { formatShortDate } from 'modules/utils/DateUtils';
import { RootState } from 'store/types';
import * as actions from 'store/findProvider/actions';
import * as selectors from 'store/findProvider/selectors';
import { Comment, ProviderDetailCriteria } from 'store/findProvider/types';
import { MuiBox, MuiButton, MuiGrid } from 'theme/material-ui';
import { SectionHeader } from '../common';
import { CommentText, StarCol, StyledMuiDivider, TimeCol } from './styled';
import isEmpty from 'lodash/isEmpty';
import analyticsService, { AnalyticsEvent } from 'services/AnalyticsService';
import { FontSize, FontWeight } from 'modules/styles/variables';
import { Color } from 'modules/styles/colors';

import {
  currentLocationPathNameSelector,
  previousLocationPathNameSelector
} from 'store/router/selectors';

interface Props {
  comments: Comment[];
  totalCount: number;
  getComments: typeof actions.getComments;
  resetComments: typeof actions.resetComments;
  criteria: ProviderDetailCriteria;
  currentUrl?: string;
  referringUrl?: string;
  providerCommentsRef?: RefObject<HTMLDivElement>;
}

const CommentItem = ({ comment, last }: { comment: Comment; last: boolean }) => (
  <MuiBox
    display="flex"
    flexDirection="column"
    width="100%"
    justifyContent="center"
    fontWeight={FontWeight.regularbold}
  >
    <MuiBox display="flex" width="100%" alignItems="center">
      <TimeCol>{formatShortDate(comment.date)}</TimeCol>
      <StarCol>
        <Rating
          name="half-rating-read"
          value={comment.overallRating || 0}
          precision={0.5}
          readOnly
        />
      </StarCol>
    </MuiBox>
    <CommentText>{comment.comment}</CommentText>
    {!last && <StyledMuiDivider />}
  </MuiBox>
);

const ProviderComments = ({
  comments,
  totalCount,
  getComments,
  resetComments,
  criteria,
  currentUrl,
  referringUrl,
  providerCommentsRef
}: Props) => {
  useEffect(() => {
    resetComments();
    getComments(criteria);
  }, [criteria?.id]);

  const hasMoreComments = !isEmpty(comments) && comments.length < totalCount;

  const onViewMoreComment = () => {
    analyticsService.logEvent(AnalyticsEvent.ViewMoreProviderReviewsClicked, {
      currentUrl,
      referringUrl
    });

    getComments(criteria);
  };

  return (
    <MuiGrid item xs={12} ref={providerCommentsRef}>
      {comments.length ? (
        <>
          <SectionHeader
            variant="h5"
            fontWeight={FontWeight.bold}
            fontSize={FontSize.heading}
          >{`Comments (total ${totalCount || 0})`}</SectionHeader>
          <MuiBox display="flex" flexDirection="column" mb={2}>
            <Panel style={{ backgroundColor: Color.baseColor }}>
              {comments.map((comment, index) => (
                <CommentItem
                  key={comment.id}
                  comment={comment}
                  last={index === comments.length - 1}
                />
              ))}
            </Panel>

            {hasMoreComments && (
              <MuiBox my={2} display="flex" justifyContent="center">
                <MuiButton onClick={onViewMoreComment} color="primary" fullWidth>
                  View more comments
                </MuiButton>
              </MuiBox>
            )}
          </MuiBox>
        </>
      ) : null}
    </MuiGrid>
  );
};

const mapStateToProps = (state: RootState) => ({
  comments: selectors.providerCommentsSelector(state),
  totalCount: selectors.totalCommentsSelector(state),
  currentUrl: currentLocationPathNameSelector(state),
  referringUrl: previousLocationPathNameSelector(state)
});

const mapDispatchToProps = {
  getComments: actions.getComments,
  resetComments: actions.resetComments
};

export default connect(mapStateToProps, mapDispatchToProps)(ProviderComments);
