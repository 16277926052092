import React, { useState, useEffect } from 'react';
import { Amwell } from 'lib/amwell';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';

import { Color } from 'modules/styles/colors';

import { Confirm } from 'components/ConfirmDialog/ConfirmDialog';
import RouteLeavingGuardModal from 'components/UI/Modals/RouteLeavingGuardModal';
import { SpinnerOverlay } from 'components/UI/Spinner/SpinnerModal';

import analyticsService, { AnalyticsEvent } from 'services/AnalyticsService';

import { RootState, RootDispatch } from 'store/types';
import {
  findActiveVisit,
  setActivityStatus,
  visitEmitter,
  clearStorage,
  resetAmwellActivity
} from 'store/amwell/actions';
import { ActivityStatus, Visit, VisitType, VisitStep } from 'store/amwell/types';
import { hasStartedVisit } from 'store/amwell/visit/utils';
import * as amwellSelectors from 'store/amwell/selectors';
import * as routerSelectors from 'store/router/selectors';
import * as accountSelectors from 'store/account/selectors';

import { ConnectCareProxy, ConnectCareActiveVisitDialog } from './index';
import { ROUTE, EXPIRED_SESSION, EXIT_MODAL_TEXT } from './constants';
import { SHARE_FEEDBACK_SCENARIOS } from 'lib/constants/help';

export interface ConnectCareActivityProps {
  currentUrl?: string;
  referringUrl?: string;
  activityStatus: ActivityStatus;
  isProxyUser: boolean;
  findActiveVisit: () => AnyAction;
  setActivityStatus: (activityStatus: ActivityStatus) => void;
  clearSession: () => void;
  resetActivity: () => void;
  visitEmitter: (visit: Visit, type: VisitType, step: VisitStep) => void;
  children?: React.ReactNode;
}

const isActive = (url?: string) => url?.includes(ROUTE.base);
const isLanding = (url?: string) => url?.includes(ROUTE.landing);
const isLogin = (url?: string) => url?.includes(ROUTE.login);
const isForm = (url?: string) => url?.includes(ROUTE.form);
const isInVisit = (url?: string) => url?.includes(ROUTE.visit);
const isInProgress = (url?: string) => isForm(url) && !isInVisit(url);

export function ConnectCareActivity({
  currentUrl,
  referringUrl,
  activityStatus,
  isProxyUser,
  findActiveVisit,
  clearSession,
  resetActivity,
  setActivityStatus,
  visitEmitter,
  children
}: ConnectCareActivityProps) {
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const resolveActivityStatus = () => {
    if (isInVisit(currentUrl)) {
      setActivityStatus(ActivityStatus.IN_VISIT);
    } else if (isInProgress(currentUrl)) {
      setActivityStatus(ActivityStatus.IN_PROGRESS);
    } else if (isActive(currentUrl)) {
      setActivityStatus(ActivityStatus.ACTIVE);
    } else {
      setActivityStatus(ActivityStatus.INACTIVE);
    }
  };

  const onFindActiveVisit = () => {
    findActiveVisit().then((res: AnyAction) => {
      if (hasStartedVisit(res.payload)) {
        setActivityStatus(ActivityStatus.HAS_VISIT);
      } else {
        resolveActivityStatus();
      }
    });
  };

  useEffect(() => {
    if (referringUrl === currentUrl || (isInProgress(referringUrl) && isInProgress(currentUrl))) {
      return;
    }

    if (isInProgress(currentUrl) || isInVisit(referringUrl)) {
      onFindActiveVisit();
      return;
    }
    resolveActivityStatus();
  }, [referringUrl, currentUrl]);
  useEffect(() => {
    if (isProxyUser && isLogin(currentUrl)) {
      setActivityStatus(ActivityStatus.PROXY_SWITCH);
    } else {
      resolveActivityStatus();
    }
  }, [isProxyUser]);

  useEffect(() => {
    if (activityStatus === ActivityStatus.EXPIRED) {
      Confirm.show(
        EXPIRED_SESSION.title,
        EXPIRED_SESSION.subtitle,
        null,
        EXPIRED_SESSION.severity,
        {
          text: 'Ok',
          onClick: () => history.push(ROUTE.login)
        },
        undefined,
        {
          cancelable: false,
          closeIcon: false
        }
      );
    }

    if (activityStatus === ActivityStatus.END_VISIT) {
      clearSession().then(() => {
        resetActivity();
        history.push(ROUTE.base);
      });
    }

    if (activityStatus === ActivityStatus.IN_VISIT) {
      Amwell.visitChange(visitEmitter);
    } else {
      Amwell.visitLeave();
    }
  }, [activityStatus]);

  const exitAndShareHandler = (path?: string) => {
    history.push('/u/help-support/share-feedback', {
      scenario: SHARE_FEEDBACK_SCENARIOS.connectCare,
      screenLocation: ROUTE.base,
      navigateTo: path || ROUTE.getCare
    });
  };

  return (
    <>
      {activityStatus === ActivityStatus.IN_PROGRESS && !isLogin(currentUrl) ? (
        <RouteLeavingGuardModal
          shouldBlockNavigation={({ pathname }) => {
            return !isForm(pathname) && !isLogin(pathname) && !isLanding(pathname);
          }}
          exitTitle={EXIT_MODAL_TEXT.title}
          exitSubtitle={EXIT_MODAL_TEXT.subtitle}
          exitAndShareButtonText={EXIT_MODAL_TEXT.textButton}
          exitAndShareHandler={exitAndShareHandler}
          exitButtonText={EXIT_MODAL_TEXT.primaryButton}
          keepGoingButtonText={EXIT_MODAL_TEXT.secondaryButton}
          navigate={(path, options) =>
            clearSession().then(() => {
              analyticsService.logEvent(AnalyticsEvent.ConnectCareFlowExited, {
                referringUrl,
                currentUrl
              });
              history.push(path, options);
            })
          }
        />
      ) : null}
      {activityStatus === ActivityStatus.HAS_VISIT && !isLogin(currentUrl) ? (
        <ConnectCareActiveVisitDialog
          onError={resolveActivityStatus}
          onReject={resolveActivityStatus}
          onRejoin={path => history.push(path)}
        />
      ) : null}
      {activityStatus === ActivityStatus.PROXY_SWITCH ? (
        <ConnectCareProxy
          onError={path => {
            resolveActivityStatus();
            history.push(path);
          }}
          onDecline={path => {
            resolveActivityStatus();
            history.push(path);
          }}
          onLoading={bool => setLoading(bool)}
        />
      ) : null}
      {loading ? <SpinnerOverlay isLoading color={Color.primary} /> : null}
      {children}
    </>
  );
}

const mapStateToProps = (state: RootState) => ({
  currentUrl: routerSelectors.currentLocationPathNameSelector(state),
  referringUrl: routerSelectors.previousLocationPathNameSelector(state),
  activityStatus: amwellSelectors.activityStatusSelector(state),
  isProxyUser: accountSelectors.isImpersonatingSelector(state)
});

const mapDispatchToProps = (dispatch: RootDispatch) => ({
  findActiveVisit: () => dispatch(findActiveVisit()),
  setActivityStatus: (status: ActivityStatus) => dispatch(setActivityStatus(status)),
  clearSession: () => dispatch(clearStorage({ opts: 'session' })),
  resetActivity: () => dispatch(resetAmwellActivity()),
  visitEmitter: (visit: Visit, type: VisitType, step: VisitStep) =>
    dispatch(visitEmitter({ visit, type, step }))
});

export default connect(mapStateToProps, mapDispatchToProps)(ConnectCareActivity);
