import React from 'react';

import { Color } from 'modules/styles/colors';

export const styles = {
  fill: 'none',
  stroke: Color.gray,
  strokeWidth: 2,
  strokeLinecap: 'round',
  strokeLinejoin: 'round',
  strokeMiterlimit: 10
} as const;

export default {
  svg: (
    <g>
      <path
        {...styles}
        d="M19.62,17.39c0,0-11.05-0.04-12.26-0.06c-0.23,0.04-4.39,3.28-4.39,3.28s0-8.81,0-9.08V4.49
	c0-0.6,0.49-1.09,1.09-1.09h15.5c0.6,0,1.09,0.49,1.09,1.09v12.15C20.65,17.23,20.22,17.39,19.62,17.39z"
      />
    </g>
  ),
  viewBox: '0 0 24 24'
};
