import { KeyboardArrowRight } from '@material-ui/icons';
import AmountSummaryTable from 'components/billing/AmountSummaryTable';
import FinancialAssistanceBody from 'components/billing/FinancialAssistanceBody';
import CallToActionRow from 'components/common/CallToActionRow/CallToActionRow';
import CollapsibleList from 'components/common/CollapsibleList/CollapsibleList';
import CollapsibleListItem from 'components/common/CollapsibleList/CollapsibleListItem';
import Divider from 'components/UI/Divider';
import { CenteredSpinner } from 'components/UI/Spinner/SpinnerContainer';
import Typography from 'components/UI/Typography';
import { Color } from 'modules/styles/colors';
import React, { ComponentType, useEffect, useState } from 'react';
import { Dimensions, TouchableOpacity } from 'react-native';
import Config from 'react-native-config';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import analyticsService, { AmplitudeEventData, AnalyticsEvent } from 'services/AnalyticsService';
import formatMoney from 'services/formatMoney';
import { getGlossary } from 'store/glossary/actions';
import { GlossaryItem } from 'store/glossary/reducers';
import { getItemByTerm } from 'store/glossary/selectors';
import * as snacksActions from 'store/snacks/actions';
import { SnackType } from 'store/snacks/types';
import { dfdDefaultTheme, MuiBox, MuiButton, MuiContainer } from 'theme/material-ui';
import { GreyView } from '../styled';
import { getProfileForInsurance } from 'store/profile/actions';
import {
  getBillingSummary,
  getHsaSummary,
  getPaymentPlanSummary,
  getPaymentsSummary
} from 'store/billing/actions';
import VisitPayWebView from 'screens/Billing/VisitPayWebView';
import { VISIT_PAY_HOME, VISIT_PAY_PAYMENT_PLAN } from '../constants';
import {
  generatePaymentPlanText,
  generateTotalPaymentPlans,
  iff,
  mapPaymentPlanData
} from './util';
import BillsComponent from './Bills';
import PaymentsComponent from './Payments';
import HSAComponent from './HSA';
import CollectionsComponent from './Collections';
import { useBillingHome } from 'lib/hooks/useBilling';
import { BillingHomeInterface, EncounterListItem } from 'store/billing/types';
import useCoreEventData from 'hooks/useCoreEventData';

const renderSpinner = () => <CenteredSpinner opacity={0.5} backgroundColor="#fff" />;

export const BillingHome = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { width } = Dimensions.get('window');

  const { currentUrl, referringUrl } = useCoreEventData();
  const {
    collectionError,
    collectionLoading,
    glossary,
    guarantorData,
    guarantorLoading,
    hsaError,
    isImpersonating,
    paymentPlansData,
    paymentPlansError,
    paymentPlansLoading,
    shInsurance
  } = useBillingHome() as BillingHomeInterface;

  const skip = isImpersonating;
  const subscriberId = shInsurance?.subscriberId;
  const hasError = hsaError || collectionError;
  const definitions: GlossaryItem[] = [];
  if (glossary?.length) {
    definitions.push(
      ...[
        getItemByTerm(glossary, 'Balance Billing'),
        getItemByTerm(glossary, 'Billing Statement'),
        getItemByTerm(glossary, 'Financial Assistance'),
        getItemByTerm(glossary, 'Total Charges')
      ]
    );
  }

  const onError = () => {
    if (hasError && currentUrl === '/u/billing') {
      dispatch(
        snacksActions.createSnack(SnackType.BASE, {
          label: 'Unable to load data, please try again'
        })
      );
    }
  };

  const refreshBilling = () => {
    // update new data
    if (currentUrl === '/u/billing' && !skip) {
      dispatch(getBillingSummary(3));
      dispatch(getPaymentsSummary());
      dispatch(getPaymentPlanSummary());
      dispatch(getProfileForInsurance());
      if (subscriberId) {
        dispatch(getHsaSummary(subscriberId));
      }
    }
  };

  useEffect(refreshBilling, [skip, currentUrl]);

  useEffect(() => {
    if (!skip) {
      dispatch(getHsaSummary(subscriberId));
    }
  }, [shInsurance, subscriberId]);

  useEffect(() => {
    if (!glossary) {
      dispatch(getGlossary());
    }
  }, [glossary]);

  useEffect(onError, [currentUrl, hasError]);

  const encounters = guarantorData?.encounters || [];

  const paymentPlans = paymentPlansData?.paymentPlans || [];
  const checkPaymentPlansEligiblity = paymentPlansData?.checkPaymentPlansEligiblity;

  const [visitPayURL, setVisitPayURL] = useState('');
  const [goToVisitPay, setGoToVisitPay] = useState(false);

  const closePopup = (val: boolean) => {
    refreshBilling();
    setGoToVisitPay(val);
  };

  const largeView = width > dfdDefaultTheme.breakpoints.values.md;
  const data: AmplitudeEventData = {
    currentUrl,
    referringUrl
  };
  const isVPeligible = encounters.every(
    (eachEncounter: EncounterListItem) => eachEncounter.visitPayEligible
  );

  const totalpaymentPlans = generateTotalPaymentPlans(paymentPlans);
  const paymentPlanData = mapPaymentPlanData(paymentPlans);

  const goToVisitPayHome = () => {
    analyticsService.logEvent(AnalyticsEvent.ProceedWithPaymentClicked, data);
    setVisitPayURL(VISIT_PAY_HOME);
    setGoToVisitPay(true);
  };

  const goToVisitPayPaymentPlan = () => {
    analyticsService.logEvent(AnalyticsEvent.ProceedWithPaymentClicked, data);
    setVisitPayURL(VISIT_PAY_PAYMENT_PLAN);
    setGoToVisitPay(true);
  };

  const goToBillingHelpPage = () => {
    history.push('/u/billing/billing-help');
  };

  const renderApplyPaymentPlan = () => {
    if (checkPaymentPlansEligiblity && checkPaymentPlansEligiblity.eligibleForPaymentPlans) {
      return (
        <TouchableOpacity onPress={goToVisitPayPaymentPlan}>
          <MuiBox justifyContent="space-between" display="flex" padding="20px 20px 0px 20px">
            <Typography color={Color.primary}>Apply for Payment Plan</Typography>
            <KeyboardArrowRight />
          </MuiBox>
        </TouchableOpacity>
      );
    }
    return null;
  };

  return (
    <>
      {(guarantorLoading || collectionLoading) && !skip && renderSpinner()}
      <GreyView accessibilityLabel="Billing Summary View">
        <CallToActionRow
          isPatientToolbar
          definitions={definitions}
          isBillingHome
          justifyContent="flex-end"
        />
        <MuiContainer maxWidth="lg">
          <CollapsibleList>
            <BillsComponent largeView={largeView} />
            <Divider />
            {!isImpersonating ? (
              <>
                {' '}
                <PaymentsComponent largeView={largeView} />
                <HSAComponent largeView={largeView} />
                <CollectionsComponent largeView={largeView} />
              </>
            ) : null}

            {!paymentPlansError && !isImpersonating ? (
              <>
                <Divider />
                <CollapsibleListItem
                  leftIcon="PaymentPlan"
                  title="Payment Plan"
                  isExpandable={!isImpersonating}
                  subtitle={
                    isImpersonating
                      ? 'Information unavailable'
                      : iff(
                          paymentPlans.length === 0,
                          'Check for eligibility',
                          `Total balance $${formatMoney(totalpaymentPlans)}`
                        )
                  }
                  analyticsEvent={AnalyticsEvent.PaymentPlanClicked}
                  currentUrlForAnalytics={currentUrl}
                  referringUrlForAnalytics={referringUrl}
                >
                  {paymentPlansLoading ? (
                    <CenteredSpinner />
                  ) : (
                    <AmountSummaryTable
                      arrows
                      largeView={largeView}
                      hideHeaders={!largeView}
                      onItemPressed={goToVisitPayHome}
                      emptyCaption={generatePaymentPlanText(
                        isVPeligible,
                        checkPaymentPlansEligiblity
                      )}
                      data={paymentPlanData}
                      headers={['Due Date', 'Plan', 'Due Amount']}
                    />
                  )}
                  {renderApplyPaymentPlan()}
                </CollapsibleListItem>
              </>
            ) : null}
            <Divider />
            {Config.FA_APP === 'enabled' && !isImpersonating ? (
              <>
                <Divider />
                <CollapsibleListItem
                  largeView={largeView}
                  leftIcon="Bill"
                  title="Financial Assistance"
                  subtitle="Get help paying your bills"
                  isExpandable={!isImpersonating}
                  analyticsEvent={AnalyticsEvent.ApplyForAssistanceClicked}
                  currentUrlForAnalytics={currentUrl}
                  referringUrlForAnalytics={referringUrl}
                >
                  <FinancialAssistanceBody history={history} />
                </CollapsibleListItem>
              </>
            ) : null}
            <Divider />
            <CollapsibleListItem
              largeView={largeView}
              leftIcon="HelpIcon"
              title="Billing Help Center"
              isExpandable={!isImpersonating}
              subtitle="Inquiries and FAQs"
            >
              <MuiBox display="flex" justifyContent="center">
                <MuiButton
                  variant="contained"
                  color="primary"
                  onClick={goToBillingHelpPage}
                  data-testid="navigate-to-billing-help-center"
                >
                  Take me to the Billing Help Center
                </MuiButton>
              </MuiBox>
            </CollapsibleListItem>
          </CollapsibleList>
          {goToVisitPay && visitPayURL && (
            <VisitPayWebView
              closePopup={closePopup}
              uri={visitPayURL}
              referringUrl={referringUrl}
            />
          )}
        </MuiContainer>
      </GreyView>
    </>
  );
};

export default BillingHome as ComponentType;
