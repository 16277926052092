import React from 'react';

export default {
  svg: (
    <g>
      <path d="M116.4 21.3C74.8 21.3 39.7 49 28.3 87h-1.2c-.4 1.9-1 3-3.4 2.8-2.6-.3-8.1-.1-11.3-.1 8.7 10.9 17 21.4 25.7 32.3 9-10.5 17.8-20.7 27-31.4-3.6 0-10.8-.1-13.7-.1-1.9 0-3.9-1.1-2.9-3.3h-.9c10.6-27.7 37.3-47.5 68.6-47.5 40.6 0 73.7 33 73.7 73.7s-33 73.7-73.7 73.7c-23.7 0-44.7-11.3-58.2-28.6l-21.1 1.4c16 27.2 45.5 45.5 79.3 45.5 50.7 0 92-41.3 92-92s-41.1-92.1-91.8-92.1z" />
      <path d="M123.7 152.2v10.6h-12.4v-10.6c-15.3-1.6-25.5-9.3-27.2-23.2l14.6-.5c2.1 8.4 9.1 12.7 19 12.7 8.9 0 16.2-4.8 16.2-11.7 0-6.7-4-9.9-12.7-12l-14-3.3c-12.5-2.8-20.5-9.8-20.6-22-.1-13.3 10.4-22.6 25.2-24.5V56.2h12.4v11.6c14.3 2.2 22.9 12.1 23 23.8l-14.1.4c-1.3-8.5-5.5-13.3-15-13.3-9.3 0-16.4 4.5-16.4 12 0 5.4 3 8 13 10.8l13.6 3.6c14.7 3.8 20.6 12.7 20.6 24.4-.1 12.1-9.9 20.9-25.2 22.7z" />
    </g>
  ),
  viewBox: '0 0 300 240'
};
