import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { MuiBox, MuiTypography, MuiGrid, MuiAlert, MuiContainer } from 'theme/material-ui';
import { StyledMuiButton } from './styled';
import { StyledScreen } from 'screens/HealthTools/Cards/';
import { history } from 'lib/history';
import Config from 'react-native-config';
import { FontSize, FontWeight } from 'modules/styles/variables';
import { Color } from 'modules/styles/colors';
import VyncaBanner from 'assets/vynca/Vynca-web.png';
import VyncaBannerB from 'assets/HealthTools/vyncaB-full.png';
import HealthToolsPictureBanner from 'components/HealthToolsCards/HealthToolsPictureBanner';
import Spacer from 'components/UI/Layout/Spacer';
import FlexBox from 'components/UI/Layout/FlexBox';
import useNavigationAnalytics from 'hooks/useNavigationAnalytics';
import analyticsService, { AMPLITUDE_EXPERIMENTS, AnalyticsEvent } from 'services/AnalyticsService';
import { dfdAccessTokenSelector } from 'store/authentication/selectors';
import { profileConsumerSelector } from 'store/profile/selectors';
import { Consumer } from 'store/profile/types';
import { RootState } from 'store/types';
import { AppName, getAppName } from 'modules/utils/ConfigUtils';

export interface HealthToolsVyncaProps {
  bearerToken: string;
  userProfile: Consumer;
}

export const HealthToolsVyncaHome = (props: HealthToolsVyncaProps) => {
  const { userProfile } = props;
  const [showStandardDesign, setShowStandardDesign] = useState(true);
  useEffect(() => {
    const result = analyticsService.fetchExperimentVariant(
      AMPLITUDE_EXPERIMENTS.VYNCA_SCREEN.flagKey
    );
    setShowStandardDesign(result?.value === AMPLITUDE_EXPERIMENTS.VYNCA_SCREEN.variantA);
  }, []);
  const letsBeginClicked = useNavigationAnalytics(AnalyticsEvent.VyncaBeginClicked, {
    title: 'Vynca Landing Page',
    vendor: 'Vynca',
    age: userProfile?.age,
    gender: userProfile?.gender
  });

  const vyncaFaqClicked = useNavigationAnalytics(AnalyticsEvent.VyncaFAQClicked, {
    title: 'Vynca Landing Page',
    vendor: 'Vynca',
    age: userProfile?.age,
    gender: userProfile?.gender
  });

  const letsBegin = () => {
    letsBeginClicked();
    window.open(`${Config.AUTH_SERVER_BASE_URL}${Config.VYNCA_AUTH_PATH}`, '_blank');
  };

  const navigate = () => {
    vyncaFaqClicked();
    history.push('/u/health-tools/vynca/faqs');
  };

  return (
    <StyledScreen>
      <MuiBox mb={3} display="center" data-testid="Vynca">
        <HealthToolsPictureBanner
          pictureBannerSrc={showStandardDesign ? VyncaBanner : VyncaBannerB}
          titleBanner={
            showStandardDesign ? 'Be prepared for the unexpected with ' : 'Take Control with '
          }
          titleBannerStrong="Advance Care Planning"
          dataTestId="section-description"
        />
      </MuiBox>
      <MuiContainer maxWidth="lg">
        <FlexBox alignItems="center">
          <MuiTypography>
            <Spacer size="xsmall" />
            <MuiTypography fontSize={FontSize.large} fontWeight={FontWeight.regularbold}>
              Participating in advance care planning gives you the opportunity to talk to your
              family or those closest to you, as well as your health care provider(s) about future
              medical care.{' '}
            </MuiTypography>
            <Spacer size="xsmall" />
            <MuiTypography fontSize={FontSize.large} fontWeight={FontWeight.regularbold}>
              A written directive provides clarity, peace of mind, and avoids confusion when the
              time comes where you cannot make decisions for yourself.{' '}
            </MuiTypography>
            <Spacer size="xsmall" />
            <MuiTypography fontSize={FontSize.large} fontWeight={FontWeight.regularbold}>
              {getAppName(AppName.base)} has partnered with Vynca Health to simplify the process of
              creating and sharing a written directive.
            </MuiTypography>
          </MuiTypography>
        </FlexBox>
      </MuiContainer>
      <Spacer size="small" />
      <MuiContainer maxWidth="md">
        <MuiBox justifyItems="center" alignItems="center" pl={9} pr={9}>
          <MuiGrid color={Color.cyanBlueLight} container spacing={2}>
            <MuiBox paddingRight={1} bgcolor={Color.blueHue10} display="flex">
              <MuiAlert color={Color.cyanBlueLight} severity="info">
                <MuiTypography fontSize={FontSize.small} color={Color.primary}>
                  Advance directives through Vynca currently are only applicable to the state of
                  Utah. For advance directives in other states please refer to your primary care
                  provider in that state.
                </MuiTypography>
              </MuiAlert>
            </MuiBox>
          </MuiGrid>
        </MuiBox>
      </MuiContainer>
      <Spacer size="medium" />
      <MuiContainer maxWidth="md">
        <MuiGrid container spacing={2} justify="center" direction="row-reverse">
          <MuiGrid item>
            <StyledMuiButton
              variant="contained"
              color={Color.secondary}
              onClick={letsBegin}
              data-testid="launch-vynca-button"
            >
              Launch Vynca
            </StyledMuiButton>
          </MuiGrid>
          <MuiGrid item>
            <StyledMuiButton variant="outlined" onClick={navigate} data-testid="vynca-faq-button">
              FAQ
            </StyledMuiButton>{' '}
          </MuiGrid>
        </MuiGrid>
      </MuiContainer>
      <Spacer size="medium" />
    </StyledScreen>
  );
};

const mapState = (state: RootState) => ({
  bearerToken: dfdAccessTokenSelector(state),
  userProfile: profileConsumerSelector(state)
});

export default connect(mapState)(HealthToolsVyncaHome);
