import styled from 'styled-components';

import { Color } from 'modules/styles/colors';
import { largeFontSize } from 'modules/styles/variables';

import DefaultText from './DefaultText';

const Subhead = styled(DefaultText)`
  color: ${Color.textDark};
  font-weight: 600;
  line-height: ${largeFontSize};
  margin-top: 8px;
  margin-bottom: 8px;
`;

export default Subhead;
