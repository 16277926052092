import ExpandableList from 'components/UI/ExpandableList/ExpandableList';
import Box from 'components/UI/Layout/Box';
import FlexBox from 'components/UI/Layout/FlexBox';
import Spacer from 'components/UI/Layout/Spacer';
import Typography from 'components/UI/Typography';
import map from 'lodash/map';
import { Color } from 'modules/styles/colors';
import { FontSize, FontWeight, Spacing } from 'modules/styles/variables';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getGlossary } from 'store/glossary/actions';
import { getGlossariesLoading, groupedGlossary } from 'store/glossary/selectors';
import { RootState } from 'store/types';
import Spinner from 'components/UI/Spinner/Spinner';

interface GlossaryItem {
  Term: string;
  Definition: string;
}
interface Props {
  glossaries: Record<string, GlossaryItem[]>;
  glossariesLoading: boolean;
  isLoading: boolean;
  getGlossaryData: () => {};
}

const Glossary = ({ glossaries, getGlossaryData, isLoading }: Props) => {
  useEffect(() => {
    getGlossaryData();
  }, []);

  return (
    <Box maxWidth="100%" spacing={Spacing.large}>
      {!isLoading ? (
        map(glossaries, (glossaryList, title) => {
          return title ? (
            <FlexBox key={title} flex={0}>
              <Typography
                fontSize={FontSize.heading}
                fontWeight={FontWeight.bold}
                accessibilityLabel={title}
              >
                {title}
              </Typography>
              <Spacer size="small" />
              <FlexBox backgroundColor={Color.white}>
                <ExpandableList
                  backgroundColor={Color.white}
                  data={glossaryList}
                  keyExtractor={(item: GlossaryItem) => item.Term}
                  renderItem={(item: GlossaryItem) => (
                    <Typography accessibilityLabel={item.Term}>{item.Term}</Typography>
                  )}
                  renderBody={(item: GlossaryItem) => (
                    <FlexBox
                      backgroundColor={Color.foreColor}
                      hSpacing={Spacing.large}
                      accessibilityLabel={item.Definition}
                    >
                      <Spacer size="small" />
                      <Typography>{item.Definition}</Typography>
                      <Spacer size="small" />
                    </FlexBox>
                  )}
                />
              </FlexBox>
              <Spacer size="small" />
            </FlexBox>
          ) : null;
        })
      ) : (
        <Spinner />
      )}
    </Box>
  );
};
const mapDispatchToProps = {
  getGlossaryData: getGlossary
};
const mapStateToProps = (state: RootState) => ({
  glossaries: groupedGlossary(state),
  isLoading: getGlossariesLoading(state)
});
export default connect(mapStateToProps, mapDispatchToProps)(Glossary);
