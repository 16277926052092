import styled from 'styled-components';
import { MuiBox, dfdDefaultTheme, MuiButton } from 'theme/material-ui';
import Typography from 'components/UI/Typography';

export const StyledSvg = styled.div`
  position: absolute;
  right: -5px;
  bottom: 0px;
`;

export const AvatarContainer = styled.div`
  padding-right: 5px;

  ${dfdDefaultTheme.breakpoints.down('sm')} {
    & > div:first-child {
      height: 55px;
      width: 55px;
    }
  }

  ${dfdDefaultTheme.breakpoints.between('md', 'md')} {
    & > div:first-child {
      height: 45px;
      width: 45px;
    }
  }
`;

export const StyledMuiBox = styled(MuiBox)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
`;

export const StyledMuiButton = styled(MuiButton)`
  ${dfdDefaultTheme.breakpoints.between('md', 'md')} {
    & span p {
      display: none;
    }
  }
`;

export const StyledTypography = styled(Typography)`
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
`;

export const ProviderItemsContainer = styled(MuiBox)`
  ${dfdDefaultTheme.breakpoints.up('sm')} {
    height: 246px;
  }
`;
