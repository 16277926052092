import React from 'react';
import { MuiTypography, useTheme } from 'theme/material-ui';
import { Link, useLocation } from 'react-router-dom';
import Svg from 'components/UI/Svg/Svg';
import { BreadcrumbWrapper } from './styled';
import { useLanguageSwitcher } from 'lib/hooks/useLanguageSwitcher';
import { crumbLabels as translations } from 'lib/constants/translations/components/index';

export interface BreadcrumbProps {
  fontWeight?: number;
  icon?: string;
  label: string;
  to: string;
}

function Breadcrumb({ fontWeight, icon, label, to }: BreadcrumbProps) {
  const theme = useTheme();
  const location = useLocation();
  const screenText = useLanguageSwitcher(translations);

  return (
    <BreadcrumbWrapper spacing={theme.spacing(0.5)}>
      {icon ? <Svg set="material" name={icon} size={24} /> : null}
      {to === location.pathname ? (
        <MuiTypography fontWeight={fontWeight}>{screenText[label] ?? label}</MuiTypography>
      ) : (
        <Link to={to}>
          <MuiTypography>{screenText[label] ?? label}</MuiTypography>
        </Link>
      )}
    </BreadcrumbWrapper>
  );
}

Breadcrumb.defaultProps = {
  fontWeight: 800
};

export default Breadcrumb;
