import { AuthLiteHeader } from 'components/AuthLite';
import DocumentView from 'components/UI/DocumentView';
import React, { ComponentType, useEffect } from 'react';
import dayjs from 'dayjs';
import { objectFromQsParam } from 'modules/utils/UrlUtils';
import { MhpDetails } from 'modules/types/common';
import { ClinicNotesDetailData } from 'store/clinicNotes/types';
import { connect } from 'react-redux';
import { RootState, RootDispatch } from 'store/types';
import { useParams, useHistory } from 'react-router-dom';
import { MuiBox, MuiTypography, MuiButton } from 'theme/material-ui';
import { Spacing, FontSize } from 'modules/styles/variables';
import { CenteredSpinner } from 'components/UI/Spinner/SpinnerContainer';
import Spacer from 'components/UI/Layout/Spacer';
import { Warning } from 'components/Error/common';
import {
  clinicNotesAuthLiteDataSelector,
  clinicNotesAuthLiteErrorSelector,
  clinicNotesAuthLiteFetchingSelector
} from 'store/clinicNotes/selectors';
import { getAuthLiteDocumentById } from 'store/clinicNotes/actions.web';
import { CenteredContainer, InnerContainer } from './styled';

interface GoodFaithEstimateDocumentViewProps {
  getDocument: (mhpDetails: MhpDetails) => void;
  loading: boolean;
  error: Error | null;
  data: ClinicNotesDetailData;
}

export const GoodFaithEstimateDocumentView = ({
  getDocument,
  loading,
  error,
  data
}: GoodFaithEstimateDocumentViewProps) => {
  const history = useHistory();
  const { mhpDetails } = useParams<{ mhpDetails: string }>();
  const details = objectFromQsParam(mhpDetails) as MhpDetails;

  useEffect(() => {
    getDocument(details);
  }, []);

  if (error) {
    return (
      <CenteredContainer data-testid="error-message">
        <Warning large />
        <Spacer />
        <MuiTypography>There was an issue loading your good faith estimate</MuiTypography>
        <Spacer />
        <MuiButton onClick={() => history.push('/landing')} color="primary" variant="contained">
          Go to MyHealth+
        </MuiButton>
        <MuiButton onClick={() => history.goBack()} color="primary" variant="text">
          Try again
        </MuiButton>
      </CenteredContainer>
    );
  }

  if (loading) return <CenteredSpinner data-testid="loading-spinner" />;

  if (!loading && !error && data) {
    return (
      <>
        <AuthLiteHeader />
        <InnerContainer data-testid="gfe-document">
          <MuiBox width="100%" maxWidth={800}>
            <MuiBox p={Spacing.xSmall} paddingBottom={Spacing.xSmall}>
              <MuiTypography fontSize={FontSize.largeHeading}>Patient Letter</MuiTypography>
              <MuiTypography>
                Visit Date: {dayjs(data?.indexDate || '').format('MM/DD/YYYY')}
              </MuiTypography>
              <MuiTypography>Caregiver: {data?.primaryProvider?.providerName}</MuiTypography>
              <MuiTypography>Location: {data?.location?.locationName}</MuiTypography>
            </MuiBox>
            <DocumentView
              content={data?.content}
              contentType={data?.contentType}
              documentName="Good Faith Estimate"
            />
            <MuiBox p={Spacing.xSmall} paddingTop={Spacing.xSmall}>
              <MuiButton
                href={`data:application/octet-stream;base64,${encodeURI(data?.content)}`}
                download="good-faith-estimate.pdf"
                fullWidth
                color="primary"
                variant="contained"
              >
                Download
              </MuiButton>
            </MuiBox>
          </MuiBox>
        </InnerContainer>
      </>
    );
  }

  return null;
};

export const mapStateToProps = (state: RootState) => ({
  loading: clinicNotesAuthLiteFetchingSelector(state),
  error: clinicNotesAuthLiteErrorSelector(state),
  data: clinicNotesAuthLiteDataSelector(state)
});

export const mapDispatchToProps = (dispatch: RootDispatch) => ({
  getDocument: (mhpDetails: MhpDetails) => dispatch(getAuthLiteDocumentById(mhpDetails))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GoodFaithEstimateDocumentView as ComponentType);
