import FlexBox from 'components/UI/Layout/FlexBox';
import styled from 'styled-components';
import { dfdDefaultTheme, MuiBox } from 'theme/material-ui';
import { Spacing, BorderRadius } from 'modules/styles/variables';

export const Row = styled(FlexBox)`
  background-color: white;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: ${Spacing.medium}px;
  cursor: pointer;
  border-radius: ${BorderRadius.small}px;

  ${dfdDefaultTheme.breakpoints.up('lg')} {
    align-items: stretch;
  }
`;

export const ColumnName = styled(FlexBox)`
  & {
    justify-content: center;
    padding: ${Spacing.small}px ${Spacing.large}px;
    order: 1;
    width: 60%;
    font-size: 14px;

    ${dfdDefaultTheme.breakpoints.up('lg')} {
      width: 25%;
      font-size: 16px;
    }
  }
`;

export const ColumnRange = styled(FlexBox)`
  & {
    justify-content: center;
    padding: ${Spacing.small}px ${Spacing.large}px;
    order: 3;
    width: 100%;

    ${dfdDefaultTheme.breakpoints.up('lg')} {
      width: 30%;
    }
  }
`;

export const ColumnComments = styled(FlexBox)`
  & * + * {
    margin-left: 10px;
  }

  & {
    flex-direction: row;
    justify-content: flex-end;
    flex-wrap: wrap;
    padding: ${Spacing.small}px ${Spacing.large}px ${Spacing.small}px ${Spacing.none}px;
    order: 2;
    width: 40%;

    & > svg {
      flex-shrink: 0;
    }

    ${dfdDefaultTheme.breakpoints.up('lg')} {
      width: calc(45% - 50px);
      align-items: center;
      order: 3;
    }
  }
`;

export const ColumnArrow = styled(FlexBox)`
  & {
    justify-content: center;
    padding: ${Spacing.medium}px;
    border-left: 1px solid #cccccc;
    display: none;
    align-items: center;
    width: 50px;

    & > svg {
      flex-shrink: 0;
    }

    ${dfdDefaultTheme.breakpoints.up('lg')} {
      display: flex;
      order: 4;
    }
  }
`;

export const CommentContainer = styled.div`
  display: none;
  ${dfdDefaultTheme.breakpoints.up('lg')} {
    display: flex;
  }
`;

export const CardsContainer = styled(MuiBox)`
  display: grid;
  grid-gap: 10px;
  margin-top: ${Spacing.large}px;
  ${dfdDefaultTheme.breakpoints.up('lg')} {
    grid-template-columns: 1fr 1fr;
  }
`;
