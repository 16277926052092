import React from 'react';

export default {
  svg: (
    <g>
      <g>
        <g>
          <circle fill="#50E3C2" strokeWidth={0} cx={40} cy={40} r={38.5} />
          <path
            fill="#FFFFFF"
            strokeWidth={0}
            d="M40,2.5c20.711,0,37.5,16.789,37.5,37.5S60.711,77.5,40,77.5S2.5,60.711,2.5,40S19.289,2.5,40,2.5 M40,0.5 C18.22,0.5,0.5,18.22,0.5,40S18.22,79.5,40,79.5S79.5,61.78,79.5,40S61.78,0.5,40,0.5L40,0.5z"
          />
        </g>
        <g>
          <defs>
            <circle id="SVGID_19_" cx={40} cy={40} r={37.5} />
          </defs>
          <clipPath id="SVGID_4_">
            <use xlinkHref="#SVGID_19_" />
          </clipPath>
          <g id="Female_-_Adult_2_" clipPath="url(#SVGID_4_)">
            <polygon
              fill="#F7CDB9"
              strokeWidth={0}
              points="46.451,65.632 46.451,57.403 33.548,57.403 33.548,65.632 30.322,66.527 33.548,86.684  46.451,86.684 49.676,66.527  "
            />
            <polygon
              fill="#DEB4A0"
              strokeWidth={0}
              points="33.548,57.457 33.548,60.447 46.451,65.632 46.451,57.457  "
            />
            <rect
              x={26.997}
              y={78.768}
              fill="#FFFFFF"
              strokeWidth={0}
              width={26.004}
              height={30.731}
            />
            <path
              fill="#828282"
              strokeWidth={0}
              d="M60.943,109.499l9.075,0.001c-0.087-10.359-0.249-19.728-0.546-22.345 c-1.022-14.999-10.538-20.991-18.861-20.991c0,0,0.161,0.628,0.432,1.629c-1.114-0.428-2.301-0.776-3.579-1.012L43.2,86.368 v22.936l14.6,0.195c-0.673-5.951-0.775-9.934-0.775-9.934c1.452-1.546,2.612-3.302,3.492-5.175 C60.675,98.104,60.818,103.555,60.943,109.499z"
            />
            <path
              fill="#828282"
              strokeWidth={0}
              d="M32.535,66.78c-1.278,0.236-2.465,0.585-3.58,1.012c0.271-1.001,0.432-1.629,0.432-1.629 c-8.323,0-17.839,5.992-18.861,20.991c-0.295,2.601-0.456,11.868-0.544,22.15l9.078-0.053c0.124-5.848,0.265-11.199,0.421-14.861 c0.879,1.873,2.04,3.629,3.492,5.175c0,0-0.382,3.913-1.208,9.739h15.034V86.368L32.535,66.78z"
            />
            <g>
              <polygon
                fill="#9B9B9B"
                strokeWidth={0}
                points="24.747,70.87 31.982,73.544 29.142,77.415 36.774,86.368 32.303,65.708  "
              />
              <polygon
                fill="#9B9B9B"
                strokeWidth={0}
                points="55.252,70.87 47.696,65.708 43.2,86.368 50.857,77.415 48.017,73.544  "
              />
            </g>
            <g>
              <path
                fill="#F6CCB8"
                strokeWidth={0}
                d="M54.779,34.713v-6.71c0-7.126-8.25-16.129-14.78-16.129c-6.53,0-14.78,9.003-14.78,16.129v6.71 c-1.814,0-3.284,1.444-3.284,3.226v3.355c0,1.782,1.47,3.226,3.284,3.226c0,7.126,8.25,16.387,14.78,16.387 c6.53,0,14.78-9.261,14.78-16.387c1.814,0,3.284-1.444,3.284-3.226v-3.355C58.063,36.157,56.593,34.713,54.779,34.713z"
              />
              <path
                fill="#EEAB91"
                strokeWidth={0}
                d="M39.999,48.97v11.935c6.53,0,14.78-9.261,14.78-16.387c1.814,0,3.284-1.444,3.284-3.226v-3.355 c0-1.782-1.47-3.226-3.284-3.226v-6.71c0-7.126-8.25-16.129-14.78-16.129v26.129c-2.396,5.005-3.284,10.322-3.284,10.322 L39.999,48.97z"
              />
              <g>
                <g>
                  <circle fill="#BC8169" strokeWidth={0} cx={31.935} cy={38.956} r={2.548} />
                  <circle fill="#3C2417" strokeWidth={0} cx={31.935} cy={38.311} r={2.548} />
                  <path
                    fill="#FFFFFF"
                    strokeWidth={0}
                    d="M33.436,37.494c0.012,0.352-0.263,0.647-0.614,0.659c-0.352,0.012-0.647-0.263-0.659-0.614 c-0.012-0.352,0.263-0.647,0.614-0.659C33.129,36.867,33.424,37.142,33.436,37.494z"
                  />
                  <path
                    fill="#3C2417"
                    strokeWidth={0}
                    d="M35.034,36.276c-0.47-1.092-1.674-1.919-2.997-2.06c-0.811-0.088-2.332,0.032-3.639,1.687 c-0.063,0.005-0.12,0.007-0.193-0.001c-0.145-0.013-0.348-0.06-0.545-0.144c-0.401-0.161-0.735-0.5-0.735-0.5 c-0.023-0.022-0.061-0.031-0.092-0.016c-0.04,0.019-0.056,0.067-0.037,0.107c0,0,0.05,0.111,0.147,0.27 c0.094,0.16,0.244,0.367,0.433,0.548c0.188,0.182,0.412,0.337,0.601,0.431c0.094,0.048,0.179,0.081,0.242,0.103 c0.061,0.021,0.124,0.033,0.113,0.032c0.176,0.018,0.336-0.076,0.413-0.224c0.856-1.196,2.001-1.784,3.224-1.652 c1.08,0.115,2.097,0.804,2.472,1.674c0.072,0.164,0.261,0.239,0.424,0.169C35.028,36.629,35.104,36.44,35.034,36.276z"
                  />
                </g>
                <g>
                  <path
                    fill="#B97056"
                    strokeWidth={0}
                    d="M50.61,38.868c0.049,1.406-1.051,2.586-2.457,2.635c-1.406,0.049-2.586-1.051-2.635-2.457 c-0.049-1.406,1.051-2.586,2.457-2.635C49.381,36.361,50.561,37.461,50.61,38.868z"
                  />
                  <path
                    fill="#3C2417"
                    strokeWidth={0}
                    d="M50.61,38.222c0.049,1.406-1.051,2.586-2.457,2.635c-1.406,0.049-2.586-1.051-2.635-2.457 c-0.049-1.406,1.051-2.586,2.457-2.635C49.381,35.716,50.561,36.816,50.61,38.222z"
                  />
                  <path
                    fill="#FFFFFF"
                    strokeWidth={0}
                    d="M49.565,37.494c0.012,0.352-0.263,0.647-0.614,0.659c-0.352,0.012-0.647-0.263-0.659-0.614 c-0.012-0.352,0.263-0.647,0.614-0.659C49.258,36.867,49.553,37.142,49.565,37.494z"
                  />
                  <path
                    fill="#3C2417"
                    strokeWidth={0}
                    d="M44.965,36.276c0.47-1.092,1.674-1.919,2.997-2.06c0.811-0.088,2.332,0.032,3.639,1.687 c0.063,0.005,0.12,0.007,0.193-0.001c0.145-0.013,0.348-0.06,0.545-0.144c0.401-0.161,0.735-0.5,0.735-0.5 c0.023-0.022,0.061-0.031,0.092-0.016c0.039,0.019,0.056,0.067,0.037,0.107c0,0-0.05,0.111-0.147,0.27 c-0.095,0.16-0.244,0.367-0.433,0.548c-0.188,0.182-0.412,0.337-0.601,0.431c-0.094,0.048-0.179,0.081-0.242,0.103 c-0.061,0.021-0.124,0.033-0.113,0.032c-0.176,0.018-0.336-0.076-0.413-0.224c-0.856-1.196-2.001-1.784-3.224-1.652 c-1.08,0.115-2.097,0.804-2.472,1.674c-0.072,0.164-0.261,0.239-0.424,0.169C44.97,36.629,44.894,36.44,44.965,36.276z"
                  />
                </g>
              </g>
            </g>
            <g>
              <path
                fill="#F05D44"
                strokeWidth={0}
                d="M45.16,51.234c0,2.534-2.311,4.588-5.161,4.588c-2.85,0-5.161-2.054-5.161-4.588H45.16z"
              />
              <path
                fill="#FFFFFF"
                strokeWidth={0}
                d="M43.437,52.955h-6.989c-0.445,0-0.806-0.361-0.806-0.806v-0.914h8.602v0.914 C44.244,52.594,43.883,52.955,43.437,52.955z"
              />
            </g>
            <g>
              <path
                fill="#FFFFFF"
                strokeWidth={0}
                d="M56.128,33.358c0,7.08,0.238-4.237-16.01-4.237S23.87,40.437,23.87,33.358 c0-7.079,7.221-12.819,16.129-12.819C48.907,20.539,56.128,26.278,56.128,33.358z"
              />
              <path
                fill="#213245"
                strokeWidth={0}
                d="M61.625,66.007c-1.807-1.628-3.461-5.031-3.461-5.031c1.834-8.687,1.834-18.695,1.834-26.34 c0-16.954-6.69-28.117-20-28.117c-13.309,0-20,11.163-20,28.117c0,7.645,0.001,17.653,1.834,26.34 c-0.583,1.758-1.654,3.403-3.461,5.031c-6.55,5.903,10.429,14.165,21.626,14.165C51.196,80.172,68.176,71.91,61.625,66.007z  M25.24,39.09c0-8.997,7.32-17.58,14.759-17.58c7.438,0,14.759,8.584,14.759,17.58s-5.569,21.774-14.759,21.774 C30.81,60.864,25.24,48.087,25.24,39.09z"
              />
              <path
                fill="#213245"
                strokeWidth={0}
                d="M39.929,69.056c-8.499,0-14.289-5.764-14.376-5.852c-0.249-0.254-0.244-0.662,0.009-0.912 c0.253-0.249,0.662-0.246,0.912,0.009c0.495,0.504,12.312,12.213,27.097-0.045c0.275-0.229,0.682-0.189,0.909,0.084 c0.227,0.275,0.19,0.681-0.084,0.909C49.144,67.603,44.195,69.056,39.929,69.056z"
              />
              <path
                fill="#213245"
                strokeWidth={0}
                d="M39.929,73.572c-8.499,0-14.289-5.764-14.376-5.852c-0.249-0.254-0.244-0.662,0.009-0.912 c0.253-0.249,0.662-0.246,0.912,0.009c0.495,0.504,12.312,12.213,27.097-0.045c0.275-0.229,0.682-0.19,0.909,0.084 c0.227,0.275,0.19,0.681-0.084,0.909C49.144,72.119,44.195,73.572,39.929,73.572z"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  ),
  viewBox: '0 0 80 80'
};
