import React from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router';
import startCase from 'lodash/startCase';
import Banner from 'components/UI/Banner/Banner';
import { Breadcrumbs } from 'components/Breadcrumbs';
import RouteLeavingGuard from 'components/UI/Modals/RouteLeavingGuardModal';
import { useRoutes } from 'hooks/useRoutes';
import { useRoutesToCrumbs } from 'hooks/useRoutesToCrumbs';
import { ComponentProps, RouteData, WithRoutes } from 'screens/Booking/router/types';
import FAAssets from '../FAApplication/FAAssets';
import FABasicInfo from '../FAApplication/FABasicInfo/FABasicInfo';
import FABills from '../FAApplication/FABills';
import FAComplete from '../FAApplication/FAComplete/FAComplete';
import FAPreviouslyCompleted from '../FAApplication/FAComplete/FAPreviouslyCompleted';
import FASubmit from '../FAApplication/FAComplete/FASubmit';
import FAHousehold from '../FAApplication/FAHousehold';
import { FAInitiation } from '../FAApplication/FAInitiation';
import FAStepper from '../FAApplication/FAStepper';
import FAStatus from '../FAStatus/FAStatus';
import FAStatusDetailsScreen from '../FAStatusDetails/FAStatusDetailsScreen';
import FAFAQ from '../FAStatic/FAFAQ';
import FAEligibility from '../FAStatic/FAEligibility';
import { EXIT_MODAL_TEXT } from 'screens/Billing/constants';
import { SHARE_FEEDBACK_SCENARIOS } from 'lib/constants/help';

export const FAFormRoutes: RouteData[] = [
  {
    path: '/start',
    component: FAInitiation,
    componentName: 'FAInitiation',
    exact: true,
    title: 'Financial Assistance Application',
    extraBannerPadding: true
  },
  {
    path: '/basic-info',
    component: FABasicInfo,
    componentName: 'FABasicInfo',
    extraBannerPadding: true,
    bannerComponent: FAStepper
  },
  {
    path: '/household',
    component: FAHousehold,
    componentName: 'FAHousehold',
    extraBannerPadding: true,
    bannerComponent: FAStepper
  },
  {
    path: '/assets',
    component: FAAssets,
    componentName: 'FAAssets',
    extraBannerPadding: true,
    bannerComponent: FAStepper
  },
  {
    path: '/other-funding',
    component: FAAssets,
    componentName: 'FAAssets',
    extraBannerPadding: true,
    bannerComponent: FAStepper
  },
  {
    path: '/bills',
    component: FABills,
    componentName: 'FABills',
    extraBannerPadding: true,
    bannerComponent: FAStepper
  },
  {
    path: '/submit',
    component: FASubmit,
    componentName: 'FASubmit',
    title: 'Financial Assistance Application',
    extraBannerPadding: true
  },
  {
    path: '/complete',
    component: FAComplete,
    componentName: 'FAComplete',
    extraBannerPadding: true,
    bannerComponent: FAStepper
  },
  {
    path: '/previously-completed',
    component: FAPreviouslyCompleted,
    componentName: 'FAPreviouslyCompleted',
    title: 'Financial Assistance Application',
    extraBannerPadding: true
  },
  {
    path: '/status',
    component: FAStatus,
    componentName: 'FAStatus',
    extraBannerPadding: true,
    title: 'Applications Submitted for Financial Assistance'
  },
  {
    path: '/statusDetails/:id',
    component: FAStatusDetailsScreen,
    componentName: 'FAStatusDetailsScreen',
    extraBannerPadding: true,
    title: 'Financial Assistance Application Summary'
  },
  {
    path: '/faq',
    component: FAFAQ,
    componentName: 'FAFAQ',
    title: 'FAQs',
    crumbLabel: 'FAQs'
  },
  {
    path: '/eligibility',
    component: FAEligibility,
    componentName: 'FAEligibility',
    title: 'Eligibility Information'
  }
];

interface Props extends RouteComponentProps {
  currentUrl?: string;
}

const isRouter = (r: Partial<RouteData>) => r.componentName?.includes('Router');
const filterRouter = (routes: RouteData[]) => routes.filter(r => !isRouter(r));

const FAComponent = ({
  component: Component,
  routes = [],
  stepRoutes = [],
  ...rest
}: ComponentProps) => {
  const crumbs = useRoutesToCrumbs(filterRouter(routes));
  const activeStep = stepRoutes.findIndex((s: RouteData) => s.path === rest.location.pathname);

  return (
    <>
      {rest.includeCrumbs ? <Breadcrumbs crumbs={crumbs} /> : null}
      {rest.includeBanner ? (
        <Banner
          message={rest.match.params.title ? startCase(rest.match.params.title) : rest.title}
          subMessage={rest.subTitle}
          icon={rest.icon || null}
          {...rest}
        />
      ) : null}
      <Component routes={routes} steps={stepRoutes} activeStep={activeStep} {...rest} />
    </>
  );
};

const FAFormRoute = ({ exact, path, ...rest }: WithRoutes<RouteData>) => {
  return <Route exact={exact} path={path} render={props => <FAComponent {...rest} {...props} />} />;
};

const FAFormRouter = (props: WithRoutes) => {
  const routes = useRoutes(FAFormRoutes);

  return (
    <Switch>
      {routes.map(r => (
        <FAFormRoute
          key={r.path}
          routes={routes.concat(props.routes)}
          includeStepper={r.includeStepper || true}
          includeBanner={r.includeBanner || true}
          includeCrumbs={r.includeCrumbs || true}
          {...r}
        />
      ))}
      <Redirect to={`${props.match.path}/start`} />
    </Switch>
  );
};

const FARoutes: RouteData[] = [
  {
    path: '/',
    component: FAFormRouter,
    componentName: 'FAFormRouter',
    includeCrumbs: false,
    includeBanner: false
  }
];

const FARouter = ({ match, history }: Props) => {
  const routes = useRoutes(FARoutes);
  const crumbs = useRoutesToCrumbs(filterRouter(routes));
  const isRouteGuardOpen = false;
  const currentRoute = history?.location?.pathname;
  const handleNavigateFromFA = async (path: string, options: object) => {
    history.push(path, options);
  };

  const exitAndShareHandler = () => {
    history.push('/u/help-support/share-feedback', {
      scenario: SHARE_FEEDBACK_SCENARIOS.financialAssistance,
      screenLocation: '/u/fa-app',
      navigateTo: '/u/billing'
    });
  };

  return (
    <Switch>
      {routes.map(({ component: Component, ...r }) => (
        <Route
          key={r.path}
          path={r.path}
          exact={r.exact || false}
          render={props => (
            <>
              {r.includeCrumbs === false ? null : <Breadcrumbs crumbs={crumbs} />}
              <Component routes={routes} {...r} {...props} />
              <RouteLeavingGuard
                when={!isRouteGuardOpen}
                shouldBlockNavigation={nextLocation => {
                  if (
                    // These are the exceptions for when to NOT show the FAA leaving warning
                    nextLocation.pathname.includes('/u/fa-app') ||
                    currentRoute === '/u/fa-app/previously-completed' ||
                    currentRoute === '/u/fa-app/statusDetails/:id' ||
                    currentRoute === '/u/fa-app/status' ||
                    currentRoute === '/u/fa-app/faq' ||
                    currentRoute === '/u/fa-app/eligibility' ||
                    currentRoute === '/u/fa-app/start' ||
                    currentRoute === '/u/fa-app/submit' ||
                    // These are the exceptions specific to the details page to allow navigation to another page freely
                    (currentRoute.includes('/u/fa-app/statusDetails/') &&
                      (nextLocation.pathname === '/u/dashboard' ||
                        nextLocation.pathname === '/u/timeline' ||
                        nextLocation.pathname === '/u/health-record' ||
                        nextLocation.pathname === '/u/get-care-now' ||
                        nextLocation.pathname === '/u/billing' ||
                        nextLocation.pathname === '/u/messages' ||
                        nextLocation.pathname === '/u/manage-account' ||
                        nextLocation.pathname === '/u/billing/bills' ||
                        nextLocation.pathname === '/u/help-support'))
                  ) {
                    return false;
                  }
                  return true;
                }}
                exitTitle={EXIT_MODAL_TEXT.title}
                exitSubtitle={EXIT_MODAL_TEXT.subtitle}
                exitButtonText={EXIT_MODAL_TEXT.primaryButton}
                keepGoingButtonText={EXIT_MODAL_TEXT.secondaryButton}
                exitAndShareButtonText={EXIT_MODAL_TEXT.textButton}
                exitAndShareHandler={exitAndShareHandler}
                navigate={(path: string, options: object) => handleNavigateFromFA(path, options)}
              />
            </>
          )}
        />
      ))}
      <Redirect to={`${match.path}`} />
    </Switch>
  );
};

export default FARouter;
