import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { FlatList } from 'react-native';
import ScrollableLayout from 'components/UI/Layout/ScrollableLayout';
import { RootState } from 'store/types';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import FlexBox from 'components/UI/Layout/FlexBox';
import Box from 'components/UI/Layout/Box';
import Typography from 'components/UI/Typography';
import Heading from 'components/UI/Typography/Heading';
import Divider from 'components/UI/Divider';
import Spacer from 'components/UI/Layout/Spacer';
import { ListItem } from 'components/UI/ListItem/ListItem';
import Progress from 'components/cost/DeductibleBar/Progress';
import { JsonObj } from '@mhp/general-interface';
import { Alert } from 'components/Alert';

import { FontSize, Spacing, FontWeight, IconSize } from 'modules/styles/variables';
import { Color } from 'modules/styles/colors';

import { Insurance, Consumer } from 'store/profile/types';
import { myPlanBenefitsSelector, familyPlanBenefitsSelector } from 'store/cost/selectors';
import { getPlanBenefits } from 'store/cost/actions';
import { verifyInsurance } from 'store/booking/actions';

import { profileConsumerSelector, profileActiveInsuranceSelector } from 'store/profile/selectors';
import * as bookingSelectors from 'store/booking/selectors';

import { createInsuranceVerificationRequestBody } from 'lib/insurance';
import analyticsService, { AnalyticsEvent } from 'services/AnalyticsService';

import { BlueText } from 'screens/Billing/FinancialAssistance/FAApplication/styled';
import LinkingServices from 'services/LinkingServices';

import { PLAN_INFO, ADDITIONAL_RESOURCES } from 'lib/constants/insurance';
import { Icon } from 'components/Icon';
import Svg from 'components/UI/Svg/Svg';

interface Deduction {
  title: string;
  totalSpendAmt: number;
  remainAmt: number;
  barColor: string;
}

interface Details extends NavigationScreenProps {
  myBenefits: JsonObj;
  familyBenefits: JsonObj;
  status: JsonObj;
  customer: Consumer;
  activeInsurance: Insurance;
  isFetching: boolean;
}

const handleCallServiceNumber = (contactNumber: string) => {
  LinkingServices.callPhoneNumber(contactNumber);
};

const DeductionBar = ({ title, totalSpendAmt, remainAmt, barColor }: Deduction) => {
  const spendAmt = totalSpendAmt - remainAmt;
  const deductible = {
    alreadyPaidPercentage: (spendAmt * 100) / totalSpendAmt
  };
  return (
    <Box offset={Spacing.large} flex={1}>
      <FlexBox flexDirection="row" justifyContent="space-between">
        <Typography accessibilityLabel="deduction title" fontWeight={FontWeight.bold}>
          {title}:
        </Typography>
        <Typography accessibilityLabel="deduction total amt" fontWeight={FontWeight.bold}>
          ${totalSpendAmt}
        </Typography>
      </FlexBox>
      <Spacer size="small" />
      <Progress barColor={barColor} deductible={deductible} capMath={{}} />
      <Spacer size="small" />
      <Typography accessibilityLabel="deduction ytd">
        <Typography fontWeight={FontWeight.bold}>${spendAmt}</Typography> spent year to date
      </Typography>
    </Box>
  );
};

const InsuranceDetails = ({
  myBenefits,
  familyBenefits,
  customer,
  status,
  activeInsurance,
  isFetching
}: Details) => {
  const insurance = activeInsurance || {};
  const dispatch = useDispatch();
  const history = useHistory();

  let { startDate = '', endDate = '' } = insurance;
  startDate = dayjs(startDate).format('MMMM D, YYYY');
  endDate = dayjs(endDate).format('MMMM D, YYYY');

  useEffect(() => {
    if (insurance.members && insurance.source !== 'Facets') {
      dispatch(verifyInsurance(createInsuranceVerificationRequestBody(insurance, customer)));
    }

    if (insurance.source === 'Facets') {
      dispatch(getPlanBenefits());
    }
  }, [insurance]);

  const handleExternalLink = (link: string) => {
    try {
      window.open(link, '_blank');
    } catch (err) {
      Alert.alert('Link not available');
    }
  };

  const navigateToAdditionalResources = (redirectTo: string, type: string) => {
    if (type === 'web') {
      return handleExternalLink(redirectTo);
    }
    if (redirectTo === 'EstimateCost') {
      analyticsService.logEvent(AnalyticsEvent.EstimateCostsClicked);
      return history.push('/u/estimate-costs', {
        returnBackTo: 'InsuranceDetails',
        hideTabBar: true
      });
    }
    return history.push(redirectTo, {
      returnBackTo: 'InsuranceDetails'
    });
  };
  return (
    <ScrollableLayout backgroundColor={Color.foreColor}>
      <Box flex={1} hSpacing={Spacing.small} backgroundColor={Color.foreColor} width="100%">
        <FlexBox flex={1} spacing={Spacing.large} justifyContent="flex-start">
          <Heading accessibilityLabel="insurance name">{insurance.insuranceName}</Heading>
          <Spacer size="small" />
          <FlexBox vSpacing={Spacing.small} flexDirection="row">
            <Typography accessibilityLabel="subscriberId" fontWeight={FontWeight.bold}>
              Subscriber Id:
            </Typography>
            <Typography accessibilityLabel={insurance.subscriberId} color={Color.black}>
              &nbsp; {insurance.subscriberId}
            </Typography>
          </FlexBox>

          {PLAN_INFO.map(
            item =>
              !!insurance[item.value] && (
                <FlexBox vSpacing={Spacing.small} flexDirection="row" key={item.key}>
                  <Typography accessibilityLabel={item.label} fontWeight={FontWeight.bold}>
                    {item.label}:
                  </Typography>
                  <Box flex={1} hSpacingRight={Spacing.large}>
                    <Typography accessibilityLabel={item.value}>
                      &nbsp;
                      {item.value.endsWith('Date')
                        ? dayjs(String(insurance[item.value])).format('MMMM D, YYYY')
                        : insurance[item.value]}
                    </Typography>
                  </Box>
                </FlexBox>
              )
          )}
          <FlexBox flexDirection="row" alignItems="center" vOffsetBottom={Spacing.small}>
            <Typography accessibilityLabel="status" fontWeight={FontWeight.bold}>
              Status:{' '}
            </Typography>
            &nbsp;
            {insurance.source === 'Facets' ? (
              dayjs(endDate).isBefore(dayjs()) ? (
                <>
                  <Icon name="close-circle-outline" color={Color.red} size={IconSize.base} />
                  <Typography color={Color.red}>Expired</Typography>
                </>
              ) : dayjs(startDate).isAfter(dayjs()) ? (
                <>
                  <Icon
                    name="check-circle-outline"
                    color={Color.darkGreenShade}
                    size={IconSize.base}
                  />
                  <Typography color={Color.darkGreenShade}>Future</Typography>
                </>
              ) : (
                <>
                  <Icon
                    name="check-circle-outline"
                    color={Color.darkGreenShade}
                    size={IconSize.base}
                  />
                  <Typography color={Color.darkGreenShade}>Active</Typography>
                </>
              )
            ) : isFetching ? (
              <>
                <Icon name="progress-clock" size={IconSize.base} />
                <Typography> loading </Typography>
              </>
            ) : status && typeof status.isValid === 'boolean' && status.isValid ? (
              <>
                <Icon
                  name="check-circle-outline"
                  color={Color.darkGreenShade}
                  size={IconSize.base}
                />
                <Typography color={Color.darkGreenShade}>Active</Typography>
              </>
            ) : (
              <>
                <Typography>Not able to verify</Typography>
              </>
            )}
          </FlexBox>
        </FlexBox>
        <Divider />
        {insurance.source === 'Facets' &&
          (dayjs(startDate).isBefore(dayjs()) || dayjs(startDate).isSame(dayjs())) &&
          (dayjs(endDate).isAfter(dayjs()) || dayjs(endDate).isSame(dayjs())) && (
            <>
              {myBenefits && myBenefits.deductible ? (
                <DeductionBar
                  title="Individual Deductible"
                  totalSpendAmt={myBenefits.deductible}
                  remainAmt={myBenefits.remainder.deductible}
                  barColor={Color.tealLight}
                />
              ) : null}
              {familyBenefits && familyBenefits.deductible ? (
                <DeductionBar
                  title="Family Deductible"
                  totalSpendAmt={familyBenefits.deductible}
                  remainAmt={familyBenefits.remainder.deductible}
                  barColor={Color.tealLight}
                />
              ) : null}
              {myBenefits && myBenefits.max_oop ? (
                <DeductionBar
                  title="Individual Out of Pocket Max"
                  totalSpendAmt={myBenefits.max_oop}
                  remainAmt={myBenefits.remainder.max_oop}
                  barColor={Color.tealLight}
                />
              ) : null}
              {familyBenefits && familyBenefits.max_oop ? (
                <DeductionBar
                  title="Family Out of Pocket Max"
                  totalSpendAmt={familyBenefits.max_oop}
                  remainAmt={familyBenefits.remainder.max_oop}
                  barColor={Color.tealLight}
                />
              ) : null}
            </>
          )}
        <Divider />
        <FlexBox flex={1} spacing={Spacing.large} justifyContent="flex-start">
          <Typography
            accessibilityLabel="cover member title"
            fontWeight={FontWeight.bold}
            fontSize={FontSize.base}
          >
            Covered Members:
          </Typography>
          <Spacer size="small" />
          <Typography accessibilityLabel="subscriber name">
            {insurance.subscriberName} (subscriber)
          </Typography>
          {insurance.members &&
            insurance.members.map(member => (
              <Typography accessibilityLabel={member.displayName}>{member.displayName}</Typography>
            ))}
        </FlexBox>
        <Divider />
        {insurance.source === 'Facets' && (
          <FlatList
            ItemSeparatorComponent={() => <Divider />}
            data={ADDITIONAL_RESOURCES}
            keyExtractor={item => item.key.toString()}
            renderItem={({ item }) => (
              <ListItem
                testID={`resource-${item.key}`}
                title={item.title}
                body={item.body}
                secondaryBody={
                  item.contact ? (
                    <BlueText
                      accessibilityLabel={item.contact}
                      onPress={() => handleCallServiceNumber(item.contact)}
                    >
                      {item.contact}
                    </BlueText>
                  ) : null
                }
                onPress={() => navigateToAdditionalResources(item.redirectTo, item.type || '')}
                action={<Svg name="keyboard-arrow-right" set="material" fill={Color.black} />}
              />
            )}
          />
        )}
      </Box>
    </ScrollableLayout>
  );
};

const mapStateToProps = (state: RootState) => ({
  myBenefits: myPlanBenefitsSelector(state),
  familyBenefits: familyPlanBenefitsSelector(state),
  customer: profileConsumerSelector(state),
  status: bookingSelectors.patientInsuranceValidSelector(state),
  activeInsurance: profileActiveInsuranceSelector(state),
  isFetching: bookingSelectors.patientInsuranceFetchingSelector(state)
});

export default connect(mapStateToProps)(InsuranceDetails);
