import { Icon } from 'components/Icon';
import { JsonObj } from '@mhp/general-interface';
import FlexBox, { FlexBoxColumn } from 'components/UI/Layout/FlexBox';
import Screen from 'components/UI/Layout/Screen';
import Typography from 'components/UI/Typography';
import DefaultText from 'components/UI/Typography/DefaultText';
import useCoreEventData from 'hooks/useCoreEventData';
import { useBillingHome } from 'lib/hooks/useBilling';
import { DUE_STATUS_TYPE, DueStatusKey } from 'modules/constants/Billing';
import { Color } from 'modules/styles/colors';
import { FontSize, FontWeight, IconSize, Spacing } from 'modules/styles/variables';
import { formatDate } from 'modules/utils/DateUtils';
import React, { useEffect } from 'react';
import { SectionList, TouchableOpacity } from 'react-native';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import VisitPayWebView from 'screens/Billing/VisitPayWebView';
import analyticsService, { AmplitudeEventData, AnalyticsEvent } from 'services/AnalyticsService';
import formatMoney from 'services/formatMoney';
import { oc } from 'ts-optchain';

import {
  CustomLink,
  DueAmount,
  EmptyFlexBox,
  GreyFlexBox,
  Panel,
  SpacedRowFlexBox,
  TextBlueBlock
} from '../styled';

import { Divider, useMediaQuery, useTheme } from '@material-ui/core';
import Spinner from 'components/UI/Spinner/Spinner';
import Config from 'react-native-config';
import { MuiBox, MuiButton, MuiCardContent } from 'theme/material-ui';

import { getBills } from 'store/billing/actions';
import { EncounterListItem } from 'store/billing/types';
import { sortEncountersByDate } from '../BillingHome/util';
import { VISIT_PAY_HOME } from '../constants';

export function BillSummaryMHP() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { billsData, billsLoading, billsError } = useBillingHome();
  const isSmallScreen = useMediaQuery(useTheme().breakpoints.down('md'));
  const [goToVisitPay, setGoToVisitPay] = React.useState(false);

  const { currentUrl, referringUrl } = useCoreEventData();
  useEffect(() => {
    if (currentUrl === '/u/billing/bills') {
      dispatch(getBills());
    }
  }, [currentUrl, goToVisitPay]);

  const eventData: AmplitudeEventData = {
    currentUrl,
    referringUrl
  };

  const getDueStatus = (eachEncounter: EncounterListItem) => {
    if (
      eachEncounter.visitPayEligible &&
      eachEncounter.visitPayDueStatus &&
      !eachEncounter.paymentPlan
    ) {
      return eachEncounter.visitPayDueStatus;
    }
    if (eachEncounter.dueStatus && !eachEncounter.paymentPlan) {
      return eachEncounter.dueStatus;
    }
    return null;
  };

  const amountDue = oc(billsData).amountDue(0);
  const dueStatus = oc(billsData).dueStatus();

  const encounters = [...oc(billsData).encounters([])].sort(sortEncountersByDate);
  const paidEncounters = [...oc(billsData).paidEncounters([])].sort(sortEncountersByDate);
  const patientUnpaidBills: JsonObj = [];

  for (let mainIndex = 0; mainIndex < encounters.length; mainIndex += 1) {
    const eachEncounter: EncounterListItem = encounters[mainIndex];

    for (let index = 0; index < patientUnpaidBills.length; index += 1) {
      const element = patientUnpaidBills[index];
      if (element.id === eachEncounter.patient.id) {
        // same user
        patientUnpaidBills[index].unpaidBill.push(eachEncounter);
        break;
      }
      if (index === patientUnpaidBills.length - 1) {
        // after checking all old users add new user
        const temp: JsonObj = {};
        temp.id = eachEncounter.patient.id;
        temp.name = `${eachEncounter.patient.firstName} ${eachEncounter.patient.lastName}`;
        temp.unpaidBill = [];
        temp.unpaidBill.push(eachEncounter);
        patientUnpaidBills.push(temp);
        break;
      }
    }
    if (patientUnpaidBills.length === 0) {
      const temp: JsonObj = {};
      temp.id = eachEncounter.patient.id;
      temp.name = `${eachEncounter.patient.firstName} ${eachEncounter.patient.lastName}`;
      temp.unpaidBill = [];
      temp.unpaidBill.push(eachEncounter);
      patientUnpaidBills.push(temp);
    }
  }

  const showVisitpay = () => {
    analyticsService.logEvent(AnalyticsEvent.ProceedWithPaymentClicked, eventData);
    setGoToVisitPay(true);
  };

  const goToBillingDetail = (item: any) => {
    analyticsService.logEvent(AnalyticsEvent.IndividualBillingClicked, eventData);

    return history.push({
      pathname: `/u/billing/bill-detail/${item.id}`
    });
  };

  const navigateToFinancialAssistance = () => {
    history.push('/u/fa-app');
    analyticsService.logEvent(AnalyticsEvent.ApplyForAssistanceClicked, eventData);
  };

  const renderProceedPayment = () => {
    return (
      <>
        <FlexBox
          flexDirection={isSmallScreen ? 'column' : 'row'}
          justifyContent="center"
          alignItems="center"
          offset={Spacing.large}
        >
          <FlexBoxColumn spacing={`${Spacing.xLarge}px`}>
            <MuiCardContent>
              <DueAmount data-testid="due-status">
                {DUE_STATUS_TYPE[dueStatus as DueStatusKey]}
              </DueAmount>
              <Typography
                tag="h1"
                data-testid="total-amount-due"
                accessibilityLabel={`Unpaid bills total amount due is $${formatMoney(amountDue)}`}
              >
                {`$${formatMoney(amountDue)}`}
              </Typography>
            </MuiCardContent>
          </FlexBoxColumn>

          {amountDue ? (
            <FlexBoxColumn spacing={isSmallScreen ? Spacing.none : Spacing.xLarge}>
              <MuiBox marginBottom={1}>
                <MuiButton
                  style={{ width: 278 }}
                  data-testid="proceed-with-payment-button"
                  variant="contained"
                  onClick={showVisitpay}
                  color="primary"
                >
                  <DefaultText color={Color.white}>Proceed with Payment</DefaultText>
                </MuiButton>
              </MuiBox>

              {Config.FA_APP === 'enabled' ? (
                <MuiBox>
                  <MuiButton
                    style={{ width: 278 }}
                    data-testid="apply-to-financial-assistance-button"
                    variant="outlined"
                    onClick={navigateToFinancialAssistance}
                    color="primary"
                  >
                    <DefaultText color={Color.link}>Apply for Financial Assistance</DefaultText>
                  </MuiButton>
                </MuiBox>
              ) : null}
            </FlexBoxColumn>
          ) : null}
        </FlexBox>
      </>
    );
  };

  return (
    <Screen>
      <FlexBox
        offset={isSmallScreen ? Spacing.none : Spacing.xLarge}
        data-testid="BillSummaryComponent"
      >
        <Panel accessibilityLabel="Panel">{renderProceedPayment()}</Panel>

        <Panel accessibilityLabel="Panel">
          <SectionList
            sections={[
              {
                title: 'Unpaid Bills',
                data: encounters
              },
              {
                title: 'Paid Bills',
                data: paidEncounters
              }
            ]}
            keyExtractor={(item: EncounterListItem, index: number) => item.id + index}
            renderItem={({
              item: eachEncounter,
              section
            }: {
              item: EncounterListItem;
              section: any;
            }) => (
              <TouchableOpacity
                data-testid="unpaid-bill"
                accessible
                accessibilityLabel={`Tap to view bill detail ${formatDate(
                  eachEncounter.dischargeDate
                )} ${eachEncounter.billingDescription} $${formatMoney(eachEncounter.balance)}`}
                onPress={() => {
                  goToBillingDetail(eachEncounter);
                }}
                key={eachEncounter.id}
              >
                <SpacedRowFlexBox justifyContent="space-between">
                  <FlexBox flex={1} flexDirection="column">
                    {getDueStatus(eachEncounter) ? (
                      <Typography
                        color={Color.red}
                        fontSize={FontSize.base}
                        accessibilityLabel={`Due status of ${section.title} ${
                          DUE_STATUS_TYPE[getDueStatus(eachEncounter) as DueStatusKey]
                        }`}
                      >
                        {DUE_STATUS_TYPE[getDueStatus(eachEncounter) as DueStatusKey]}
                      </Typography>
                    ) : null}

                    <Typography
                      accessibilityLabel={`Date of ${section.title} is ${formatDate(
                        eachEncounter.dischargeDate
                      )}`}
                      numberOfLines={1}
                      fontSize={FontSize.small}
                    >{`${formatDate(eachEncounter.dischargeDate)}`}</Typography>

                    <Typography
                      accessibilityLabel={`${section.title} patient name ${eachEncounter.id}`}
                      numberOfLines={1}
                      fontSize={FontSize.small}
                    >{`${eachEncounter.patient.firstName} ${eachEncounter.patient.lastName}`}</Typography>

                    <Typography
                      accessibilityLabel={`${section.title} description is ${eachEncounter.billingDescription}`}
                      numberOfLines={1}
                      fontWeight={FontWeight.bold}
                    >{`${eachEncounter.billingDescription}`}</Typography>
                  </FlexBox>

                  <FlexBox justifyContent="space-between" alignItems="center" flexDirection="row">
                    <Typography
                      fontSize={FontSize.base}
                      accessibilityLabel={`Balance of ${section.title} is $${formatMoney(
                        eachEncounter.balance
                      )}`}
                      fontWeight={FontWeight.bold}
                    >
                      {eachEncounter.paymentPlan
                        ? 'Payment Plan'
                        : `$${formatMoney(eachEncounter.balance)}`}
                    </Typography>
                    <Icon name="keyboard-arrow-right" size={IconSize.base} color={Color.gray} />
                  </FlexBox>
                </SpacedRowFlexBox>
                <Divider />
              </TouchableOpacity>
            )}
            renderSectionHeader={({ section: { title } }) => (
              <GreyFlexBox>
                <Typography color={Color.gray}>{title.toUpperCase()}</Typography>
              </GreyFlexBox>
            )}
            renderSectionFooter={({ section }) => {
              if (section.data.length === 0) {
                if (billsLoading) {
                  return (
                    <EmptyFlexBox>
                      <Spinner />
                    </EmptyFlexBox>
                  );
                }
                if (billsError) {
                  return (
                    <EmptyFlexBox>
                      <DefaultText>Error in loading {section.title}</DefaultText>
                    </EmptyFlexBox>
                  );
                }
                return (
                  <EmptyFlexBox>
                    <DefaultText>No {section.title}</DefaultText>
                  </EmptyFlexBox>
                );
              }
              return null;
            }}
          />
        </Panel>

        <FlexBoxColumn justifyContent="center" alignItems="center" offset={Spacing.xLarge}>
          {Config.FA_APP === 'enabled' ? (
            <TextBlueBlock>
              <CustomLink to="/u/fa-app/eligibility">
                See the eligibility page for more information
              </CustomLink>
            </TextBlueBlock>
          ) : null}
          <Typography data-testid="unpaid-bills-summary-disclaimer">
            Bills for homecare, central labs, and Intermountain payment plans will not appear here.
            You may receive additional bills not shown. Adjustments to your bill will be reflected
            within 48 hours
          </Typography>
        </FlexBoxColumn>

        {goToVisitPay && <VisitPayWebView closePopup={setGoToVisitPay} uri={VISIT_PAY_HOME} />}
      </FlexBox>
    </Screen>
  );
}

export default BillSummaryMHP;
