import React, { useState } from 'react';
import { useField, FieldHookConfig } from 'formik';
import FormLabel from '@material-ui/core/FormLabel';
import Box from '@material-ui/core/Box';
import { getRegisterBrand } from 'store/register';
import useStyles from '../useStyles';
import FieldError from './FieldError';
import { InputAdornment } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { TextInput, TextInputProps } from 'components/TextInput';
import { RequiredTextField } from '../styled';

type CustomTextFieldProps = FieldHookConfig<string> &
  TextInputProps & {
    button?: string;
    tooltip?: string;
    pwdAdornment?: boolean;
    unMaskType?: string;
    variant?: string;
    required?: boolean;
    showError?: boolean;
  };

const CustomTextField = ({
  label,
  button,
  mask,
  onBlur,
  tooltip,
  pwdAdornment,
  type,
  unMaskType,
  required,
  showError = true,
  ...rest
}: CustomTextFieldProps) => {
  const redirectBrand = getRegisterBrand();
  const classes = useStyles({ redirectBrand });
  const [field, meta] = useField(rest);
  const [adorn, setAdorn] = useState(false);
  const hasError = meta.touched && !!meta.error;

  const inputAddOn = (pwdAdornment?: boolean) => {
    let inputProps = {};

    if (pwdAdornment) {
      let visibleIcon;

      if (adorn) {
        visibleIcon = <VisibilityOffIcon onClick={() => setAdorn(false)} />;
      } else {
        visibleIcon = <VisibilityIcon onClick={() => setAdorn(true)} />;
      }
      inputProps = {
        endAdornment: <InputAdornment position="end">{visibleIcon}</InputAdornment>
      };
    }
    return inputProps;
  };

  return (
    <>
      {label && (
        <FormLabel error={hasError} className={classes.label}>
          {label} {required ? <RequiredTextField>*</RequiredTextField> : null} {tooltip}
        </FormLabel>
      )}
      <Box>
        <TextInput
          error={hasError}
          variant="outlined"
          fullWidth
          {...field}
          onBlur={e => {
            field.onBlur(e);
            if (onBlur) {
              onBlur(e);
            }
          }}
          className={classes.customTextField}
          mask={mask}
          type={pwdAdornment ? (adorn ? unMaskType : type) : type}
          {...rest}
          InputProps={inputAddOn(pwdAdornment)}
        />
        {button && (
          <Box
            display="flex"
            alignItems="center"
            position="absolute"
            top={0}
            right={0}
            bottom={0}
            mr={1}
          >
            {button}
          </Box>
        )}
      </Box>
      {showError && <FieldError error={hasError && meta.error} />}
    </>
  );
};

export default CustomTextField;
