import React from 'react';

export default {
  svg: (
    <g id="Final-Mobile" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Group-10" transform="translate(0.667262, 0.316639)">
        <path
          d="M214.878373,26.5819087 C215.101346,26.5819087 215.297795,26.5603574 215.297795,26.297597 C215.297795,26.0804259 215.083939,26.0539011 214.904897,26.0539011 L214.547643,26.0539011 L214.547643,26.5819087 L214.878373,26.5819087 Z M214.547643,27.6064259 L214.250068,27.6064259 L214.250068,25.8010875 L214.934738,25.8010875 C215.371567,25.8010875 215.593711,25.9536046 215.593711,26.3199772 C215.593711,26.6473916 215.393118,26.781673 215.122897,26.8123422 L215.628525,27.6064259 L215.292821,27.6064259 L214.826152,26.8347224 L214.547643,26.8347224 L214.547643,27.6064259 Z M214.870084,25.3253004 C214.163034,25.3253004 213.614304,25.8790038 213.614304,26.6722586 C213.614304,27.4149506 214.093407,28.0208745 214.870084,28.0208745 C215.568015,28.0208745 216.117574,27.4713156 216.117574,26.6722586 C216.117574,25.8790038 215.568015,25.3253004 214.870084,25.3253004 Z M214.870084,28.3035285 C213.931772,28.3035285 213.265338,27.6031103 213.265338,26.6722586 C213.265338,25.691673 214.006373,25.0418175 214.870084,25.0418175 C215.725506,25.0418175 216.465711,25.691673 216.465711,26.6722586 C216.465711,27.6545019 215.725506,28.3035285 214.870084,28.3035285 L214.870084,28.3035285 Z"
          id="Fill-1"
          fill="#676666"
        ></path>
        <path
          d="M2.97988593,30.4404259 L2.97988593,6.37422053 C2.97988593,4.50339924 4.50257034,2.98071483 6.37339163,2.98071483 L30.059962,2.98071483 C31.9299544,2.98071483 33.4518099,4.50339924 33.4518099,6.37422053 L33.4518099,30.4404259 C33.4518099,32.3104183 31.9299544,33.8314449 30.059962,33.8314449 L6.37339163,33.8314449 C4.50257034,33.8314449 2.97988593,32.3104183 2.97988593,30.4404259 Z M6.37339163,2.94483491e-14 C2.85886692,2.94483491e-14 -9.42347172e-14,2.85886692 -9.42347172e-14,6.37422053 L-9.42347172e-14,30.4404259 C-9.42347172e-14,33.9541217 2.85886692,36.8121597 6.37339163,36.8121597 L30.059962,36.8121597 C33.5736578,36.8121597 36.4316958,33.9541217 36.4316958,30.4404259 L36.4316958,6.37422053 C36.4316958,2.85886692 33.5736578,2.94483491e-14 30.059962,2.94483491e-14 L6.37339163,2.94483491e-14 L6.37339163,2.94483491e-14 Z"
          id="Fill-2"
          fill="#676666"
        ></path>
        <path
          d="M23.1113156,10.2949049 C23.1113156,12.3605171 21.4361141,14.0332319 19.3688441,14.0332319 C17.3073764,14.0332319 15.6321749,12.3605171 15.6321749,10.2949049 C15.6321749,8.22929278 17.3073764,6.55492015 19.3688441,6.55492015 C21.4361141,6.55492015 23.1113156,8.22929278 23.1113156,10.2949049"
          id="Fill-3"
          fill="#527B2A"
        ></path>
        <path
          d="M15.2881825,28.8191027 C11.1577871,23.9990646 8.098327,20.7464715 6.7273308,19.095308 C4.65508745,16.6053004 6.47534601,13.8243498 9.69229658,15.9753384 C11.2166388,16.995711 12.6158175,18.0832243 15.3851635,20.0593156 C17.4789582,21.557962 18.9892091,21.5654221 21.1725247,20.1803346 C26.4526008,16.8241293 38.7037034,8.51277567 40.9052548,7.10282129 C43.4880989,5.44751331 45.4509278,8.13562738 43.285019,10.2020684 C41.5319011,11.8706388 26.9399924,24.4781673 21.6939011,29.0752319 C19.0215361,31.4152091 17.4375133,31.3281749 15.2881825,28.8191027"
          id="Fill-4"
          fill="#527B2A"
        ></path>
        <path
          d="M49.8374525,14.8107376 C49.8374525,17.9157871 52.5993384,18.820943 54.249673,19.3605551 C54.2587909,19.3630418 56.0003042,19.8744715 56.0003042,19.8744715 C57.6887681,20.3701521 58.6967072,20.7298935 58.6967072,21.9873308 C58.6967072,23.1892319 57.5486844,24.0289049 55.9041521,24.0289049 C55.1258175,24.0289049 53.7067452,23.8556654 52.9425019,22.6935513 C52.9449886,22.6968669 52.4633992,21.5728821 52.4633992,21.5728821 L52.3125399,21.1625779 C52.1451027,20.8235589 51.8873156,20.3054981 51.0095133,20.3054981 C50.2966616,20.3054981 49.2373308,20.7216046 49.2373308,21.8679696 C49.2373308,22.3827148 49.5597719,24.1242281 50.9954221,25.3402205 C52.1218935,26.2909658 53.757308,26.7932776 55.7242814,26.7932776 C56.7222738,26.7932776 58.6046996,26.6498783 60.0088517,25.6900152 C60.7623194,25.1860456 62.0321901,24.0032091 62.0321901,21.7469506 C62.0321901,18.2722129 58.9627833,17.3620837 57.3141065,16.8730342 L55.5427529,16.3243042 C54.023384,15.8617795 53.1729354,15.5492852 53.1729354,14.4493384 C53.1729354,13.9171863 53.4531027,13.4040989 53.9056806,13.1106692 C54.2637643,12.8661445 54.8075209,12.7384943 55.4839011,12.7384943 C56.9800608,12.7384943 58.052654,13.3311559 58.6718403,14.50073 C59.1252471,15.3768745 59.3755741,15.8609506 60.2293384,15.8609506 C60.8568137,15.8609506 61.7918099,15.469711 61.7918099,14.3896578 C61.7918099,13.4704106 60.7407681,10.0346312 55.6040913,10.0346312 C49.9990875,10.0346312 49.8374525,14.3208593 49.8374525,14.8107376"
          id="Fill-5"
          fill="#676666"
        ></path>
        <path
          d="M68.2953384,13.9561445 C69.2220456,13.1330494 70.2888365,13.039384 71.0099772,13.039384 C73.9707985,13.039384 74.5982738,15.4746844 74.7317262,16.6111027 C74.1448669,16.6111027 67.5186616,16.6111027 66.8538859,16.6111027 C67.0204943,15.8841597 67.337962,14.7925019 68.2953384,13.9561445 Z M76.1151559,20.6959087 C75.3094677,20.6959087 75.064943,21.1103574 74.620654,21.8596806 L74.5651179,21.953346 C74.0752395,22.7142738 73.3275741,23.8780456 71.0696578,23.8780456 C69.969711,23.8780456 68.8183726,23.713924 67.8502205,22.518654 C67.2177719,21.7461217 66.9011331,20.7439848 66.7983498,19.4649962 C67.4556654,19.4649962 76.5354068,19.4649962 76.5354068,19.4649962 C77.5856198,19.4649962 78.1882281,18.8632167 78.1882281,17.8130038 C78.1882281,15.9504715 77.5914221,13.9619468 76.6299011,12.6224487 C75.7305475,11.4047985 74.5452243,10.6306084 73.0034753,10.2534601 C72.3934068,10.1075741 71.7128821,10.0346312 70.9801369,10.0346312 C65.6892852,10.0346312 63.3211255,14.3042814 63.3211255,18.5341445 C63.3211255,20.9354601 64.0522129,23.0864487 65.3784487,24.5892395 C67.1464867,26.5661597 69.4740304,26.7932776 71.0696578,26.7932776 C73.5115894,26.7932776 75.4769049,25.9718403 76.9108973,24.3496882 C77.1106616,24.1308593 77.7870418,23.3508669 77.9412167,22.6206084 C77.9320989,22.6545932 77.9776882,22.2575513 77.9776882,22.2575513 C77.9776882,21.2802814 77.0310875,20.6959087 76.1151559,20.6959087 L76.1151559,20.6959087 Z"
          id="Fill-6"
          fill="#676666"
        ></path>
        <path
          d="M80.9194449,6.13218251 L80.9194449,25.0210951 C80.9194449,26.0240608 81.580076,26.6722586 82.6021065,26.6722586 C83.8429658,26.6722586 84.2847681,25.8193232 84.2847681,25.0210951 L84.2847681,6.13218251 C84.2847681,5.34638783 83.8429658,4.50920152 82.6021065,4.50920152 C81.3604183,4.50920152 80.9194449,5.38368821 80.9194449,6.13218251"
          id="Fill-7"
          fill="#676666"
        ></path>
        <path
          d="M103.909741,14.7991331 C103.53508,15.8675817 103.351894,17.0396426 103.351894,18.3832852 C103.351894,22.4490266 105.332958,26.7932776 110.891544,26.7932776 C115.370905,26.7932776 116.807384,24.2833764 117.278198,23.4586236 C117.710053,22.7383118 117.950433,22.0428669 117.950433,21.5065703 C117.950433,20.3684943 116.879498,19.9440989 116.206433,19.9440989 C115.136327,19.9440989 114.808913,20.8343346 114.633186,21.3126084 L114.618266,21.3416198 C114.179779,22.2791027 113.443719,23.8482053 110.951224,23.8482053 C107.372046,23.8482053 106.837407,20.3494297 106.837407,18.263924 C106.837407,17.0089734 107.134152,12.9191939 110.951224,12.9191939 C113.180958,12.9191939 113.895468,14.347384 114.166517,14.8853384 C114.501392,15.5865856 114.847871,16.3126996 115.876532,16.3126996 C116.886129,16.3126996 117.648715,15.615597 117.648715,14.6905475 C117.648715,14.372251 117.595665,13.9495133 116.970677,12.9664411 C116.135148,11.6037338 114.702814,10.0346312 111.042403,10.0346312 C106.546464,10.0346312 104.682274,12.6249354 103.909741,14.7991331"
          id="Fill-8"
          fill="#676666"
        ></path>
        <path
          d="M121.191422,7.12271483 C121.191422,7.12271483 121.191422,9.83155133 121.191422,10.334692 C120.791065,10.334692 119.87016,10.334692 119.87016,10.334692 C118.966662,10.334692 118.427878,10.8850798 118.427878,11.8076426 C118.427878,12.8918403 119.173886,13.2789354 119.87016,13.2789354 C119.87016,13.2789354 120.765369,13.2789354 121.160753,13.2789354 C121.160753,13.8409278 121.160753,21.7767909 121.160753,21.7767909 C121.160753,24.0728365 121.160753,26.6722586 125.636798,26.6722586 C126.148228,26.6722586 127.709042,26.6722586 127.709042,25.2009658 C127.709042,23.8332852 126.608266,23.786038 125.949293,23.7603422 C125.157696,23.7006616 124.796297,23.5688669 124.636319,22.9074068 C124.55343,22.5725323 124.526076,22.3677947 124.526076,20.6063878 C124.526076,20.6063878 124.526076,13.8342966 124.526076,13.2789354 C124.948814,13.2789354 126.08689,13.2789354 126.08689,13.2789354 C126.783163,13.2789354 127.529171,12.8918403 127.529171,11.8076426 C127.529171,10.8718175 127.002821,10.334692 126.08689,10.334692 C126.08689,10.334692 124.948814,10.334692 124.526076,10.334692 C124.526076,9.83155133 124.526076,7.12271483 124.526076,7.12271483 C124.526076,6.09571103 123.928441,5.52957414 122.844243,5.52957414 C121.809779,5.52957414 121.191422,6.12555133 121.191422,7.12271483"
          id="Fill-9"
          fill="#676666"
        ></path>
        <path
          d="M130.921019,6.19186312 L130.921019,25.0210951 C130.921019,25.4081901 131.042038,26.6722586 132.603681,26.6722586 C133.426776,26.6722586 133.922456,26.2238251 134.108958,25.8052319 C134.103985,25.8151787 134.286342,25.0210951 134.286342,25.0210951 L134.286342,17.9331939 C134.286342,16.5058327 134.36343,15.3321141 135.343186,14.2968213 C136.045262,13.5657338 137.119513,13.1289049 138.21946,13.1289049 C139.340129,13.1289049 140.087795,13.5093688 140.506388,14.291019 C140.832973,14.9433612 140.889338,15.704289 140.889338,17.512943 L140.889338,25.0210951 C140.889338,25.4081901 141.012844,26.6722586 142.575316,26.6722586 C143.397582,26.6722586 143.892433,26.2238251 144.079764,25.8052319 C144.073962,25.8151787 144.257148,25.0210951 144.257148,25.0210951 L144.257148,16.6724411 C144.257148,15.2102662 144.257148,13.3924943 143.238433,11.9626464 C142.713741,11.2406768 141.438897,10.0346312 138.788913,10.0346312 C136.988548,10.0346312 135.393749,10.657962 134.316183,11.6849658 C134.316183,10.5543498 134.316183,6.16119392 134.316183,6.16119392 C134.316183,5.39943726 133.867749,4.50920152 132.603681,4.50920152 C131.362821,4.50920152 130.921019,5.37871483 130.921019,6.19186312"
          id="Fill-10"
          fill="#979797"
        ></path>
        <path
          d="M172.453749,22.647962 C172.002829,23.2489125 170.861437,24.2394449 169.150593,24.2394449 C167.64946,24.2394449 166.358038,23.398943 166.358038,21.7469506 C166.358038,20.0659468 167.70997,19.6150266 168.160061,19.4649962 C168.581141,19.3141369 169.182091,19.1641065 169.690205,19.0745856 L171.253506,18.7140152 C172.515087,18.4437947 172.726456,18.3832852 173.295909,18.1437338 C173.26524,20.1562966 173.236228,21.566251 172.453749,22.647962 Z M175.057316,11.7620532 C174.056008,10.5999392 172.458722,10.0346312 170.172624,10.0346312 C167.528441,10.0346312 165.636068,10.8784487 164.544411,12.5428745 C164.337186,12.8794068 163.863886,13.7240532 163.863886,14.4493384 C163.863886,15.1621901 164.315635,15.922289 165.577217,15.922289 C166.876099,15.922289 167.04851,15.1116274 167.133057,14.7228745 C167.375924,13.9992471 167.756388,12.8586844 170.172624,12.8586844 C171.459072,12.8586844 172.99419,13.249924 172.99419,15.1099696 C172.99419,15.7316426 172.868198,15.8501749 172.372517,15.9927452 C172.359255,15.9960608 170.562205,16.4071939 170.562205,16.4071939 C170.560548,16.4080228 168.007544,16.9492928 168.007544,16.9492928 C166.30416,17.294943 163.204084,17.9257338 163.204084,21.7171103 C163.204084,24.8014373 165.302023,26.7932776 168.550471,26.7932776 C169.873392,26.7932776 171.653034,26.4070114 173.092829,24.9688745 C173.107749,25.4372015 173.142563,25.5996654 173.223795,25.8168365 C173.37051,26.1823802 173.729422,26.6208669 174.628776,26.6722586 C175.555483,26.6722586 176.058624,26.1815513 176.145658,25.2142281 L176.148973,25.1702966 L176.148973,16.1610114 C176.148973,14.6308669 176.148973,13.047673 175.057316,11.7620532 L175.057316,11.7620532 Z"
          id="Fill-11"
          fill="#979797"
        ></path>
        <path
          d="M179.69251,6.13218251 L179.69251,25.0210951 C179.69251,26.0240608 180.352312,26.6722586 181.374342,26.6722586 C182.614373,26.6722586 183.056175,25.8193232 183.056175,25.0210951 L183.056175,6.13218251 C183.056175,5.34638783 182.614373,4.50920152 181.374342,4.50920152 C180.130996,4.50920152 179.69251,5.38368821 179.69251,6.13218251"
          id="Fill-12"
          fill="#979797"
        ></path>
        <path
          d="M188.038677,7.12271483 C188.038677,7.12271483 188.038677,9.83155133 188.038677,10.334692 C187.641635,10.334692 186.717414,10.334692 186.717414,10.334692 C185.816403,10.334692 185.27762,10.8850798 185.27762,11.8076426 C185.27762,12.8918403 186.02197,13.2789354 186.717414,13.2789354 C186.717414,13.2789354 187.613452,13.2789354 188.009665,13.2789354 C188.009665,13.8409278 188.009665,21.7767909 188.009665,21.7767909 C188.009665,24.0728365 188.009665,26.6722586 192.484882,26.6722586 C192.997141,26.6722586 194.557125,26.6722586 194.557125,25.2009658 C194.557125,23.8332852 193.455521,23.786038 192.797376,23.7603422 C192.004951,23.7006616 191.646038,23.5688669 191.485232,22.9074068 C191.403171,22.575019 191.37416,22.3711103 191.37416,20.6063878 C191.37416,20.6063878 191.37416,13.8342966 191.37416,13.2789354 C191.796897,13.2789354 192.934144,13.2789354 192.934144,13.2789354 C193.632905,13.2789354 194.378084,12.8918403 194.378084,11.8076426 C194.378084,10.8718175 193.851734,10.334692 192.934144,10.334692 C192.934144,10.334692 191.796897,10.334692 191.37416,10.334692 C191.37416,9.83155133 191.37416,7.12271483 191.37416,7.12271483 C191.37416,6.09571103 190.775696,5.52957414 189.691498,5.52957414 C188.657034,5.52957414 188.038677,6.12555133 188.038677,7.12271483"
          id="Fill-13"
          fill="#979797"
        ></path>
        <path
          d="M197.168981,6.19186312 L197.168981,25.0210951 C197.168981,25.4081901 197.289171,26.6722586 198.850814,26.6722586 C199.675567,26.6722586 200.170418,26.1608289 200.357749,25.8052319 C200.564973,25.4081901 200.534304,25.0210951 200.534304,25.0210951 L200.534304,17.9331939 C200.534304,16.5058327 200.612221,15.3321141 201.592806,14.2968213 C202.292395,13.5657338 203.367475,13.1289049 204.466593,13.1289049 C205.587262,13.1289049 206.336586,13.5093688 206.75435,14.291019 C207.080935,14.9433612 207.138958,15.704289 207.138958,17.512943 L207.138958,25.0210951 C207.138958,25.4081901 207.260806,26.6722586 208.82162,26.6722586 C209.646373,26.6722586 210.117186,26.2271407 210.327726,25.8052319 C210.538266,25.3824943 210.503452,25.0210951 210.503452,25.0210951 L210.503452,16.6724411 C210.503452,15.2102662 210.503452,13.3924943 209.487224,11.9626464 C208.962532,11.2406768 207.688517,10.0346312 205.036875,10.0346312 C203.237338,10.0346312 201.643369,10.657962 200.564144,11.6849658 C200.564144,10.5543498 200.564144,6.16119392 200.564144,6.16119392 C200.564144,5.39943726 200.114882,4.50920152 198.850814,4.50920152 C197.609954,4.50920152 197.168981,5.37871483 197.168981,6.19186312"
          id="Fill-14"
          fill="#979797"
        ></path>
        <path
          d="M92.0672852,13.9544867 C92.9948213,13.1330494 94.0616122,13.0377262 94.7827529,13.0377262 C97.7435741,13.0377262 98.3710494,15.4730266 98.5045019,16.6094449 C97.9176426,16.6094449 91.2906084,16.6094449 90.6274905,16.6094449 C90.79327,15.8841597 91.1107376,14.7908441 92.0672852,13.9544867 Z M99.8879316,20.6959087 C99.0814144,20.6959087 98.8368897,21.1078707 98.3934297,21.8588517 L98.3378935,21.953346 C97.8488441,22.7142738 97.1003498,23.8772167 94.8424335,23.8772167 C93.7416578,23.8772167 92.5903194,23.7130951 91.6229962,22.5178251 C90.9905475,21.743635 90.6730798,20.7431559 90.5711255,19.4649962 C91.2276122,19.4649962 100.308183,19.4649962 100.308183,19.4649962 C101.358395,19.4649962 101.960175,18.8632167 101.960175,17.8121749 C101.960175,15.9496426 101.364198,13.9611179 100.402677,12.6216198 C99.5033232,11.4031407 98.318,10.6289506 96.7770798,10.2518023 C96.1661825,10.1067452 95.4856578,10.0329734 94.7529125,10.0329734 C89.4620608,10.0329734 87.0939011,14.3026236 87.0939011,18.5324867 C87.0939011,20.9354601 87.8249886,23.083962 89.1512243,24.5892395 C90.9192624,26.563673 93.2468061,26.7924487 94.8424335,26.7924487 C97.2835361,26.7924487 99.2488517,25.9693536 100.683673,24.3472015 C100.883437,24.1300304 101.560646,23.3508669 101.714821,22.6197795 C101.704875,22.6529354 101.751293,22.2575513 101.751293,22.2575513 C101.751293,21.2802814 100.803863,20.6959087 99.8879316,20.6959087 L99.8879316,20.6959087 Z"
          id="Fill-15"
          fill="#676666"
        ></path>
        <path
          d="M151.40473,14.0141673 C152.331437,13.1919011 153.395741,13.0974068 154.117711,13.0974068 C157.08019,13.0974068 157.706008,15.5327072 157.841118,16.6691255 C157.25343,16.6691255 150.625567,16.6691255 149.963278,16.6691255 C150.129057,15.9421825 150.446525,14.8513536 151.40473,14.0141673 Z M159.223719,20.7531027 C158.41803,20.7531027 158.172677,21.1667224 157.729217,21.9177034 L157.673681,22.0113688 C157.183802,22.7722966 156.436137,23.9368973 154.179049,23.9368973 C153.077445,23.9368973 151.926935,23.7711179 150.957954,22.5766768 C150.327163,21.8024867 150.009696,20.8020076 149.906913,19.523019 C150.563399,19.523019 159.643141,19.523019 159.643141,19.523019 C160.694183,19.523019 161.29762,18.9212395 161.29762,17.8701977 C161.29762,16.0084943 160.699985,14.0199696 159.737635,12.6804715 C158.83911,11.4619924 157.652958,10.6878023 156.111209,10.3114829 C155.501141,10.165597 154.821445,10.092654 154.087871,10.092654 C148.797019,10.092654 146.429688,14.3606464 146.429688,18.5913384 C146.429688,20.9934829 147.160776,23.1436426 148.486183,24.6472624 C150.255878,26.6225247 152.582593,26.8504715 154.179049,26.8504715 C156.620981,26.8504715 158.585468,26.0282053 160.01946,24.4060532 C160.220053,24.1888821 160.896433,23.4088897 161.049779,22.6786312 C161.04149,22.7109582 161.08708,22.316403 161.08708,22.316403 C161.08708,21.3383042 160.13965,20.7531027 159.223719,20.7531027 L159.223719,20.7531027 Z"
          id="Fill-16"
          fill="#979797"
        ></path>
      </g>
    </g>
  ),
  viewBox: '0 0 250 40'
};
