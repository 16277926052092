import React from 'react';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import { Color } from 'modules/styles/colors';

export const AlertIcon = () => (
  <div style={{ textAlign: 'center' }}>
    <div
      style={{
        position: 'relative',
        display: 'inline-block',
        background: Color.alertError,
        padding: '5px 8px 2px 8px',
        borderRadius: '25px'
      }}
    >
      <ReportProblemOutlinedIcon fontSize="large" style={{ color: Color.white }} />
    </div>
  </div>
);
