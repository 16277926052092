import { makeStyles } from '@material-ui/core/styles';
import { Color } from 'modules/styles/colors';
import { Spacing, FontSize } from 'modules/styles/variables';
import {
  RedirectProps,
  getBrandColor,
  getBrandHoverColor,
  getBrandLightHoverColor
} from 'store/register/utils';

export default makeStyles(theme => ({
  customContainedButton: {
    color: Color.baseColor,
    'background-color': (props: RedirectProps) => getBrandColor(props),
    '&:hover': {
      'background-color': (props: RedirectProps) => getBrandHoverColor(props)
    }
  },
  customOutlinedButton: {
    color: (props: RedirectProps) => getBrandColor(props),
    'border-color': (props: RedirectProps) => getBrandColor(props),
    '&:hover': {
      color: (props: RedirectProps) => getBrandColor(props),
      'background-color': (props: RedirectProps) => getBrandLightHoverColor(props),
      'border-color': 'none'
    }
  },
  customTextButton: {
    color: (props: RedirectProps) => getBrandColor(props),
    '&:hover': {
      color: (props: RedirectProps) => getBrandColor(props),
      'background-color': (props: RedirectProps) => getBrandLightHoverColor(props)
    }
  },
  customSelectField: {
    '&.MuiOutlinedInput-root.Mui-focused': {
      '& fieldset': {
        borderColor: (props: RedirectProps) => getBrandColor(props)
      }
    }
  },
  customTextField: {
    '& .MuiOutlinedInput-root.Mui-focused': {
      '& fieldset': {
        borderColor: (props: RedirectProps) => getBrandColor(props)
      }
    },
    '& .MuiSvgIcon-root': {
      color: (props: RedirectProps) => getBrandColor(props)
    },
    '& .MuiTouchRipple-root': {
      color: (props: RedirectProps) => getBrandHoverColor(props)
    }
  },
  heading: {
    borderBottom: `1px solid ${theme.palette.grey[300]}`
  },
  label: {
    display: 'block',
    marginBottom: theme.spacing(1),
    color: theme.palette.grey[600],
    fontWeight: 600,
    fontSize: '13px',
    [theme.breakpoints.down('sm')]: {
      whiteSpace: 'break-spaces'
    },
    whiteSpace: 'nowrap',
    '&.Mui-focused': {
      color: theme.palette.grey[600]
    }
  },
  formControl: {
    width: '100%'
  },
  checkbox: {
    margin: 0
  },
  radio: {
    '&.MuiRadio-colorSecondary.Mui-checked': {
      color: (props: RedirectProps) => getBrandColor(props)
    }
  },
  terms: {
    height: '150px',
    padding: theme.spacing(2),
    overflowY: 'scroll'
  },
  fieldTipItem: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },
  fieldTipIcon: {
    minWidth: 0,
    marginRight: theme.spacing(1)
  },
  fieldTipText: {
    fontSize: '12px',
    color: theme.palette.grey[600],
    fontWeight: 600,
    lineHeight: 1.2
  },
  fieldTipCheck: {
    color: Color.darkGreenShade
  },
  hidden: {
    opacity: 0
  },
  regHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: `0 ${Spacing.large}px`
  },
  stepStyle: {
    paddingTop: theme.spacing(3),
    paddingBottom: '35px'
  },
  banner: {
    height: '100px',
    backgroundImage: `linear-gradient(323deg, ${Color.blueHue3} 0%, ${Color.primary} 100%)`,
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    paddingLeft: theme.spacing(7)
  },
  progresslabel: {
    fontStyle: 'italic',
    paddingLeft: theme.spacing(7),
    [theme.breakpoints.down('md')]: {
      paddingLeft: 0
    }
  },
  signInLink: {
    marginLeft: Spacing.small,
    color: Color.primary,
    fontStyle: 'italic',
    display: 'inline-block',
    fontSize: `${FontSize.small}px`,
    cursor: 'pointer'
  },
  progressStepper: {
    width: '100%'
  },
  createAccountDisplay: {
    color: theme.palette.common.white,
    fontSize: '28px',
    letterSpacing: 0,
    lineHeight: '34px'
  },
  myHealthLogo: {
    height: '2.8em',
    alignItems: 'center'
  },
  accessInfo: {
    marginBottom: theme.spacing(5)
  },
  footer: {
    marginTop: 'auto',
    flexDirection: 'column',
    display: 'flex'
  }
}));
