import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { FontSize, FontWeight, Spacing } from 'modules/styles/variables';
import {
  MuiContainer,
  MuiTypography,
  MuiGrid,
  MuiMenuItem,
  MuiFormControlLabel,
  MuiRadio,
  MuiRadioGroup,
  MuiBox
} from 'theme/material-ui';
import { eVisitFrontDoorOverviewScreen } from 'lib/triage/constants';
import analyticsService, { AnalyticsEvent } from 'services/AnalyticsService';
import EVisitBanner from '../components/EVisitBanner';
import { StyledAsterisk } from 'components/RequiredFieldsLegend';
import {
  StyledSelectField,
  StyledTextField,
  StyledDatePickerField,
  MaskedPreRegInput,
  InsuraceText
} from '../components/styled';
import { genders } from 'screens/ProxyForm/constants';
import { cleanPhone, phoneNumberMask } from 'modules/utils/PhoneUtils';
import { RelationShipToSubscriber } from 'modules/constants/covidScreening/personalInfo';
import { Formik } from 'formik';
import { registerValidationSchema } from '../validation';
import { insurance } from 'modules/constants/amwell';
import {
  eVisitInsuranceFilesSelector,
  eVisitSessionFetchingSelector,
  insuranceSummaryStateDataSelector,
  insuranceSummaryStateIsFetchingSelector,
  sendInsuranceFailSelector,
  sendInsuranceLoadingSelector
} from 'store/eVisit/selectors';
import {
  checkEVisitSummary,
  sendInsuranceEVisit,
  startEVisitFrontDoorSession
} from 'store/eVisit/actions';
import { useDispatch, useSelector } from 'react-redux';
import { InsuranceData, SubscriberDetailsDTO } from 'store/eVisit/types';
import { ConnectCareButton } from 'components/ConnectCare';
import { formatUserAddressString } from 'modules/utils/UserUtils';
import Spacer from 'components/UI/Layout/Spacer';
import { EVisitPatientAcordian } from './components';
import { DefaultState } from 'store/setLocalStore';
import Alert from '@material-ui/lab/Alert';
import EVisitLoading from '../EVisitLoading';
import { buildPatientDTO } from 'lib/triage/utils';
import { profileConsumerSelector } from 'store/profile/selectors';
import { useHistory, useLocation } from 'react-router-dom';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { getStateAndCondition } from '../utils';
import { EVISITS_ROUTES } from 'screens/EVisits/constants';
import { SelectErrorHelper } from 'components/Select';

export function EvisitPatientForm() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location: any = useLocation();
  const [eVisitInfo, setEVisitInfo] = useLocalStorage('EvisitCache', '');
  const { conditionCode, localStateSelected } = getStateAndCondition(eVisitInfo, location?.state);
  const [openBottom, setOpenBottom] = useState(false);
  const { patient, policy } = useSelector(insuranceSummaryStateDataSelector);
  const loadingSummary = useSelector(insuranceSummaryStateIsFetchingSelector);
  const sending = useSelector(sendInsuranceLoadingSelector);
  const starting = useSelector(eVisitSessionFetchingSelector);
  const error = useSelector(sendInsuranceFailSelector);
  const loading = loadingSummary || sending || starting;
  const patientInfo = useSelector(profileConsumerSelector);
  const files = useSelector(eVisitInsuranceFilesSelector);

  useEffect(() => {
    if (!localStateSelected) {
      history.push(EVISITS_ROUTES.STATE_SELECTOR);
    } else if (!conditionCode) {
      history.push(EVISITS_ROUTES.FRONTDOOR_CONDITIONS_WE_TREAT);
    } else if (!files?.primaryFront?.azureFileName) {
      history.push(EVISITS_ROUTES.FRONTDOOR_INSURANCE_UPLOAD);
    }
  }, [conditionCode, localStateSelected, files]);
  const initialValues = {
    firstName: patient?.firstName || '',
    lastName: patient?.lastName || '',
    phoneNumber: patient?.phone || '',
    email: patient?.email || '',
    birthDate: patient?.birthDate || '',
    subscriberId: policy?.subscriberId || '',
    planNetwork: policy?.planNetwork || '',
    planNumber: policy?.memberId.toString() || '',
    address: formatUserAddressString(patient),
    subscriberFullName: policy?.subscriberFullName || '',
    subscriberDateOfBirth: policy?.subscriberDateOfBirth || '',
    subscriberRelationship: policy?.subscriberRelationship || '',
    subscriberGender: policy?.subscriberGender || ''
  };
  const { screenTitle } = eVisitFrontDoorOverviewScreen;
  const formatDataToInsurancesUpload = (value: InsuranceData): SubscriberDetailsDTO => {
    return {
      id: value.subscriberId,
      planNetwork: value.planNetwork,
      planNumber: value.planNumber,
      fullName: value.subscriberFullName,
      dateOfBirth: value.subscriberDateOfBirth,
      address: formatUserAddressString(value),
      phone: value.phoneNumber,
      gender: value.subscriberGender,
      relationship: value.subscriberRelationship
    };
  };

  const submitForm = async (values: InsuranceData) => {
    const insuranceUpload = formatDataToInsurancesUpload(values);
    const res = await dispatch(sendInsuranceEVisit(insuranceUpload));
    if ((res.payload as DefaultState<{ success?: boolean }>)?.data?.success) {
      const options = { insuranceUpdated: true, selfPay: false };
      const patientDTO = buildPatientDTO(patientInfo, conditionCode, options) ?? {};
      setEVisitInfo(JSON.stringify({ ...patientDTO, localStateSelected }));
      analyticsService.logEvent(AnalyticsEvent.EVisitsFrontDoorInsuranceSubmitted, {
        action: 'Insurance Upload Successful'
      });
      dispatch(startEVisitFrontDoorSession(patientDTO, localStateSelected));
    } else {
      analyticsService.logEvent(AnalyticsEvent.EVisitsFrontDoorInsuranceSubmitted, {
        action: 'Insurance Upload Error'
      });
    }
  };

  const renderError = () =>
    error ? (
      <div>
        <Spacer size="medium" />
        <Alert severity="error">Unable to update insurance data!</Alert>
      </div>
    ) : null;

  useEffect(() => {
    dispatch(checkEVisitSummary());
  }, []);

  return (
    <>
      {loading ? (
        <EVisitLoading />
      ) : (
        <>
          <EVisitBanner title={screenTitle} />
          {renderError()}
          <Formik
            validateOnMount
            enableReinitialize
            initialValues={initialValues}
            validationSchema={registerValidationSchema}
            onSubmit={(values: InsuranceData) => submitForm(values)}
          >
            {formProps => {
              const {
                values,
                touched,
                errors,
                handleChange,
                handleBlur,
                isValid,
                isValidating,
                setFieldValue,
                setFieldTouched,
                handleSubmit
              } = formProps;
              const getFormError = (formKey: keyof typeof values) => {
                if (
                  (formKey === 'birthDate' &&
                    touched.birthDate &&
                    (!values.birthDate || !values.birthDate)) ||
                  (formKey === 'subscriberDateOfBirth' &&
                    touched.subscriberDateOfBirth &&
                    (!values.subscriberDateOfBirth || !values.subscriberDateOfBirth))
                ) {
                  return 'You must provide a valid date.';
                }
                return touched[formKey] ? errors[formKey] || null : null;
              };
              return (
                <MuiContainer data-testid="e-visit-overview">
                  <MuiBox paddingLeft={0} marginTop={Spacing.medium} marginBottom={6}>
                    <MuiTypography
                      component="h1"
                      color="textPrimary"
                      fontSize={FontSize.heading}
                      fontWeight={FontWeight.bold}
                      data-testid="how-it-works"
                    >
                      Your Profile
                    </MuiTypography>
                  </MuiBox>

                  <EVisitPatientAcordian
                    defaultExpanded={!openBottom}
                    isValid={isValid}
                    num={1}
                    required
                    title="Profile Details"
                  >
                    <MuiGrid style={{ margin: '0 auto' }} container direction="row">
                      <MuiGrid container xs={12}>
                        <MuiGrid style={{ marginBottom: '10px' }} item xs={12}>
                          <MuiBox
                            style={{ marginBottom: '10px', marginTop: '10px' }}
                            display="flex"
                            flexDirection="row"
                          >
                            <InsuraceText>First Name</InsuraceText>
                            <MuiBox px={0.5}>
                              <StyledAsterisk />
                            </MuiBox>
                          </MuiBox>
                          <StyledTextField
                            placeholder="First Name"
                            FormHelperTextProps={{
                              style: { position: 'absolute', top: '100%' }
                            }}
                            variant="outlined"
                            onChange={handleChange('firstName')}
                            onBlur={handleBlur('firstName')}
                            error={!!getFormError('firstName')}
                            helperText={getFormError('firstName')}
                            data-testid="firstName"
                            fullWidth
                            value={values.firstName}
                          />
                        </MuiGrid>
                        <MuiGrid style={{ marginBottom: '10px' }} item xs={12}>
                          <MuiBox
                            style={{ marginBottom: '10px', marginTop: '10px' }}
                            display="flex"
                            flexDirection="row"
                          >
                            <InsuraceText>Last Name</InsuraceText>
                            <MuiBox px={0.5}>
                              <StyledAsterisk />
                            </MuiBox>
                          </MuiBox>
                          <StyledTextField
                            placeholder="Last Name"
                            FormHelperTextProps={{
                              style: { position: 'absolute', top: '100%' }
                            }}
                            variant="outlined"
                            onChange={handleChange('lastName')}
                            onBlur={handleBlur('lastName')}
                            error={!!getFormError('lastName')}
                            helperText={getFormError('lastName')}
                            data-testid="lastName"
                            fullWidth
                            value={values.lastName}
                          />
                        </MuiGrid>
                        <MuiGrid style={{ marginBottom: '10px' }} item xs={12}>
                          <MuiBox
                            style={{ marginBottom: '10px', marginTop: '10px' }}
                            display="flex"
                            flexDirection="row"
                          >
                            <InsuraceText>Birthdate</InsuraceText>
                            <MuiBox px={0.5}>
                              <StyledAsterisk />
                            </MuiBox>
                          </MuiBox>
                          <StyledDatePickerField
                            autoOk
                            fullWidth
                            value={values.birthDate}
                            openTo="date"
                            variant="inline"
                            inputVariant="outlined"
                            format="MM/DD/YYYY"
                            placeholder="MM/DD/YYYY"
                            maxDate={new Date()}
                            helperText={getFormError('birthDate')}
                            FormHelperTextProps={{
                              style: { position: 'absolute', top: '100%' }
                            }}
                            onChange={date => {
                              const value = date || '';
                              setFieldValue('birthDate', value);
                            }}
                            error={touched.birthDate && errors.birthDate}
                            onBlur={handleBlur('birthDate')}
                            data-testid="birthDate"
                          />
                        </MuiGrid>
                        <MuiGrid style={{ marginBottom: '10px' }} item xs={12}>
                          <MuiBox
                            style={{ marginBottom: '10px', marginTop: '10px' }}
                            display="flex"
                            flexDirection="row"
                          >
                            <InsuraceText>Telephone Number</InsuraceText>
                            <MuiBox px={0.5}>
                              <StyledAsterisk />
                            </MuiBox>
                          </MuiBox>
                          <MaskedPreRegInput
                            value={values.phoneNumber}
                            data-testid="Phone"
                            name="Phone"
                            label="Phone"
                            mask={phoneNumberMask}
                            error={!!getFormError('phoneNumber')}
                            onBlur={handleBlur('phoneNumber')}
                            helperText={getFormError('phoneNumber')}
                            onChange={(e: Event) => {
                              const value = cleanPhone(e.target?.value);
                              setFieldTouched('phoneNumber', true, true);
                              setFieldValue('phoneNumber', value, true);
                            }}
                          />
                          <SelectErrorHelper error={getFormError('phoneNumber')} />
                        </MuiGrid>
                        <MuiGrid style={{ marginBottom: '10px' }} item xs={12}>
                          <MuiBox
                            style={{ marginBottom: '10px', marginTop: '10px' }}
                            display="flex"
                            flexDirection="row"
                          >
                            <InsuraceText>Email Address</InsuraceText>
                            <MuiBox px={0.5}>
                              <StyledAsterisk />
                            </MuiBox>
                          </MuiBox>
                          <StyledTextField
                            placeholder="JhonDoe@mail.com"
                            FormHelperTextProps={{
                              style: { position: 'absolute', top: '100%' }
                            }}
                            variant="outlined"
                            onChange={handleChange('email')}
                            error={!!getFormError('email')}
                            helperText={getFormError('email')}
                            onBlur={handleBlur('email')}
                            data-testid="email"
                            fullWidth
                            value={values.email}
                          />
                        </MuiGrid>
                        <MuiGrid style={{ marginBottom: '16px' }} item xs={12}>
                          <MuiBox
                            style={{ marginBottom: '10px', marginTop: '10px' }}
                            display="flex"
                            flexDirection="row"
                          >
                            <InsuraceText>Address</InsuraceText>
                            <MuiBox px={0.5}>
                              <StyledAsterisk />
                            </MuiBox>
                          </MuiBox>
                          <StyledTextField
                            placeholder="123 Street, Zip Code - City, State"
                            variant="outlined"
                            FormHelperTextProps={{
                              style: { position: 'absolute', top: '100%' }
                            }}
                            onChange={handleChange('address')}
                            onBlur={handleBlur('address')}
                            error={!!getFormError('address')}
                            helperText={getFormError('address')}
                            data-testid="address"
                            fullWidth
                            value={values.address}
                          />
                        </MuiGrid>
                        <MuiGrid style={{ marginBottom: '10px' }} item xs={12}>
                          <MuiBox
                            style={{ marginBottom: '10px', marginTop: '10px' }}
                            display="flex"
                            flexDirection="row"
                          >
                            <InsuraceText>
                              Are you the primary subscriber to the insurance uploaded?
                            </InsuraceText>
                            <MuiBox px={0.5}>
                              <StyledAsterisk />
                            </MuiBox>
                          </MuiBox>
                          <MuiRadioGroup
                            name="isPrimarySubscriber"
                            onChange={(e: React.ChangeEvent<any>) => {
                              if (e.target.value === 'Yes') {
                                // clear value of second radio button set
                                setFieldValue('subscriberRelationship', 'Self');
                                setFieldValue('subscriberFullName', policy?.subscriberFullName);
                                setFieldValue(
                                  'subscriberDateOfBirth',
                                  dayjs(policy?.subscriberDateOfBirth)
                                );
                                setFieldValue('subscriberGender', policy?.subscriberGender);
                                setFieldValue('isPrimarySubscriber', 'Yes', true);
                                setFieldValue('subscriberId', policy?.subscriberId, true);
                              } else {
                                setFieldValue('subscriberRelationship', '');
                                setFieldValue('subscriberFullName', '');
                                setFieldValue('subscriberDateOfBirth', null);
                                setFieldValue('subscriberGender', '');
                                setFieldValue('isPrimarySubscriber', 'No', true);
                              }

                              setTimeout(() => {
                                setFieldTouched('subscriberRelationship', true, true);
                                setFieldTouched('subscriberFullName', true, true);
                                setFieldTouched('subscriberDateOfBirth', true, true);
                                setFieldTouched('subscriberGender', true, true);
                                setOpenBottom(true);
                              }, 400);
                            }}
                          >
                            {insurance.IS_PRIMARY_SUBSCRIBER_OPTIONS.map(option => {
                              return (
                                <MuiBox key={option.value} mr={4}>
                                  <MuiFormControlLabel
                                    value={option.value}
                                    control={<MuiRadio required color="secondary" />}
                                    label={option.label}
                                  />
                                </MuiBox>
                              );
                            })}
                          </MuiRadioGroup>
                        </MuiGrid>
                      </MuiGrid>
                    </MuiGrid>
                  </EVisitPatientAcordian>
                  <Spacer size="medium" />
                  <EVisitPatientAcordian
                    defaultExpanded={openBottom}
                    isValid={isValid}
                    num={2}
                    title="Subscriber Info"
                    required
                  >
                    <MuiGrid container xs={12}>
                      <MuiGrid style={{ marginBottom: '10px' }} item xs={12}>
                        <MuiBox
                          style={{ marginBottom: '10px', marginTop: '10px' }}
                          display="flex"
                          flexDirection="row"
                        >
                          <InsuraceText>Subscriber ID</InsuraceText>
                          <MuiBox px={0.5}>
                            <StyledAsterisk />
                          </MuiBox>
                        </MuiBox>
                        <StyledTextField
                          placeholder="00000000"
                          FormHelperTextProps={{ style: { position: 'absolute', top: '100%' } }}
                          variant="outlined"
                          onChange={handleChange('subscriberId')}
                          error={!!getFormError('subscriberId')}
                          helperText={getFormError('subscriberId')}
                          onBlur={handleBlur('subscriberId')}
                          data-testid="subscriberId"
                          fullWidth
                          value={values.subscriberId}
                        />
                      </MuiGrid>
                      <MuiGrid style={{ marginBottom: '10px' }} item xs={12}>
                        <MuiBox
                          style={{ marginBottom: '10px', marginTop: '10px' }}
                          display="flex"
                          flexDirection="row"
                        >
                          <InsuraceText>Plan Network</InsuraceText>
                          <MuiBox px={0.5}>
                            <StyledAsterisk />
                          </MuiBox>
                        </MuiBox>
                        <StyledTextField
                          placeholder="E.g. Select Health MedPlus"
                          FormHelperTextProps={{ style: { position: 'absolute', top: '100%' } }}
                          variant="outlined"
                          onChange={handleChange('planNetwork')}
                          error={!!getFormError('planNetwork')}
                          helperText={getFormError('planNetwork')}
                          onBlur={handleBlur('planNetwork')}
                          data-testid="planNetwork"
                          fullWidth
                          value={values.planNetwork}
                        />
                      </MuiGrid>
                      <MuiGrid style={{ marginBottom: '10px' }} item xs={12}>
                        <MuiBox
                          style={{ marginBottom: '10px', marginTop: '10px' }}
                          display="flex"
                          flexDirection="row"
                        >
                          <InsuraceText>Plan Number</InsuraceText>
                          <MuiBox px={0.5}>
                            <StyledAsterisk />
                          </MuiBox>
                        </MuiBox>
                        <StyledTextField
                          placeholder="00000000"
                          FormHelperTextProps={{ style: { position: 'absolute', top: '100%' } }}
                          variant="outlined"
                          onChange={handleChange('planNumber')}
                          error={!!getFormError('planNumber')}
                          helperText={getFormError('planNumber')}
                          onBlur={handleBlur('planNumber')}
                          data-testid="planNumber"
                          fullWidth
                          value={values.planNumber}
                        />
                      </MuiGrid>
                      <MuiGrid style={{ marginBottom: '10px', marginTop: '10px' }} item xs={12}>
                        <MuiBox display="flex" flexDirection="row">
                          <InsuraceText>Subscriber Full Name</InsuraceText>
                          <MuiBox px={0.5}>
                            <StyledAsterisk />
                          </MuiBox>
                        </MuiBox>
                        <StyledTextField
                          placeholder="First, Last Name"
                          FormHelperTextProps={{ style: { position: 'absolute', top: '100%' } }}
                          variant="outlined"
                          onChange={handleChange('subscriberFullName')}
                          error={!!getFormError('subscriberFullName')}
                          helperText={getFormError('subscriberFullName')}
                          onBlur={handleBlur('subscriberFullName')}
                          data-testid="subscriberFullName"
                          fullWidth
                          value={values.subscriberFullName}
                        />
                      </MuiGrid>
                      <MuiGrid style={{ marginBottom: '10px' }} item xs={12}>
                        <MuiBox
                          style={{ marginBottom: '10px', marginTop: '10px' }}
                          display="flex"
                          flexDirection="row"
                        >
                          <InsuraceText>Subscriber Date of Birth</InsuraceText>
                          <MuiBox px={0.5}>
                            <StyledAsterisk />
                          </MuiBox>
                        </MuiBox>

                        <StyledDatePickerField
                          autoOk
                          fullWidth
                          value={values.subscriberDateOfBirth}
                          openTo="date"
                          variant="inline"
                          inputVariant="outlined"
                          format="MM/DD/YYYY"
                          placeholder="MM/DD/YYYY"
                          maxDate={new Date()}
                          helperText={getFormError('subscriberDateOfBirth')}
                          FormHelperTextProps={{ style: { position: 'absolute', top: '100%' } }}
                          onChange={date => {
                            const value = date || '';
                            setFieldTouched('subscriberDateOfBirth', true);
                            setFieldValue('subscriberDateOfBirth', value, true);
                          }}
                          error={touched.subscriberDateOfBirth && !values.subscriberDateOfBirth}
                          onBlur={handleBlur('subscriberDateOfBirth')}
                          data-testid="subscriberDateOfBirth"
                        />
                      </MuiGrid>
                      <MuiGrid style={{ marginBottom: '10px' }} item xs={12}>
                        <MuiBox
                          style={{ marginBottom: '10px', marginTop: '10px' }}
                          display="flex"
                          flexDirection="row"
                        >
                          <InsuraceText>Subscriber Address</InsuraceText>
                          <MuiBox px={0.5}>
                            <StyledAsterisk />
                          </MuiBox>
                        </MuiBox>
                        <StyledTextField
                          placeholder="123 Street, Zip Code - City, State"
                          FormHelperTextProps={{ style: { position: 'absolute', top: '100%' } }}
                          variant="outlined"
                          onChange={handleChange('address')}
                          error={!!getFormError('address')}
                          helperText={getFormError('address')}
                          onBlur={handleBlur('address')}
                          data-testid="subAddress"
                          fullWidth
                          value={values.address}
                        />
                      </MuiGrid>
                      <MuiGrid style={{ marginBottom: '10px' }} item xs={12}>
                        <MuiBox
                          style={{ marginBottom: '10px', marginTop: '10px' }}
                          display="flex"
                          flexDirection="row"
                        >
                          <InsuraceText>Subscriber Gender</InsuraceText>
                          <MuiBox px={0.5}>
                            <StyledAsterisk />
                          </MuiBox>
                        </MuiBox>
                        <StyledSelectField
                          variant="outlined"
                          defaultValue={values.subscriberGender}
                          onChange={handleChange('subscriberGender')}
                          onBlur={handleBlur('subscriberGender')}
                          error={!!getFormError('subscriberGender')}
                          label="Make selection"
                          data-testid="subscriberGender"
                          fullWidth
                          displayEmpty
                          value={values.subscriberGender}
                        >
                          <MuiMenuItem value="" disabled>
                            Select One
                          </MuiMenuItem>
                          {genders.map(({ label, value }) => {
                            return (
                              <MuiMenuItem
                                selected={value === policy?.subscriberGender}
                                key={value}
                                value={value}
                              >
                                {label}
                              </MuiMenuItem>
                            );
                          })}
                        </StyledSelectField>
                        <SelectErrorHelper error={getFormError('subscriberGender')} />
                      </MuiGrid>
                      <MuiGrid style={{ marginBottom: '10px', marginTop: '10px' }} item xs={12}>
                        <MuiBox display="flex" flexDirection="row">
                          <InsuraceText>Relationship to Patient</InsuraceText>
                          <MuiBox px={0.5}>
                            <StyledAsterisk />
                          </MuiBox>
                        </MuiBox>
                        <StyledSelectField
                          variant="outlined"
                          defaultValue={values.subscriberRelationship}
                          onChange={handleChange('subscriberRelationship')}
                          onBlur={handleBlur('subscriberRelationship')}
                          error={!!getFormError('subscriberRelationship')}
                          label="Make selection"
                          data-testid="subscriberRelationship"
                          fullWidth
                          displayEmpty
                          value={values.subscriberRelationship}
                        >
                          <MuiMenuItem value="" disabled>
                            Select One
                          </MuiMenuItem>
                          {RelationShipToSubscriber.map(({ label, value }) => {
                            return (
                              <MuiMenuItem
                                selected={value === policy?.subscriberRelationship}
                                key={value}
                                value={value}
                              >
                                {label}
                              </MuiMenuItem>
                            );
                          })}
                        </StyledSelectField>
                        <SelectErrorHelper error={getFormError('subscriberRelationship')} />
                      </MuiGrid>
                    </MuiGrid>
                  </EVisitPatientAcordian>
                  <Spacer />
                  {renderError()}
                  <MuiBox
                    my={Spacing.xSmall}
                    data-testid="disclosure"
                    marginTop={Spacing.medium}
                  ></MuiBox>
                  <MuiBox my={Spacing.xSmall}>
                    <MuiBox mx="auto" padding={Spacing.small} maxWidth="390px">
                      <ConnectCareButton
                        data-testid="Confirm"
                        fullWidth
                        size="large"
                        disabled={isValidating || !isValid || loading}
                        onClick={handleSubmit}
                      >
                        Confirm
                      </ConnectCareButton>
                    </MuiBox>
                  </MuiBox>
                </MuiContainer>
              );
            }}
          </Formik>
        </>
      )}
    </>
  );
}

export default EvisitPatientForm;
