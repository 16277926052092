import React from 'react';
import { Color } from 'modules/styles/colors';
import { SvgProps } from 'components/UI/Svg/Svg';

export default (props: SvgProps | undefined) => {
  return {
    svg: (
      <g>
        <path
          fill={
            props?.active
              ? 'url(#a)'
              : props?.fill
              ? props.fill
              : props?.disabled
              ? Color.grayHue5
              : Color.primary
          }
          fillRule="evenodd"
          d="M2.75 1.5a.75.75 0 0 0-.75.75v19.5a.75.75 0 0 0 .75.75h16.5a.75.75 0 0 0 .75-.75V9h-5.25a2.25 2.25 0 0 1-2.25-2.25V1.5H2.75Zm0-1.5A2.25 2.25 0 0 0 .5 2.25v19.5A2.25 2.25 0 0 0 2.75 24h16.5a2.25 2.25 0 0 0 2.25-2.25V8.121a2.25 2.25 0 0 0-.659-1.59L14.97.659A2.25 2.25 0 0 0 13.38 0H2.75ZM14 1.81v4.94a.75.75 0 0 0 .75.75h4.94L14 1.81ZM7.062 4.5a.75.75 0 0 1 .75.75v.353a2.97 2.97 0 0 1 1.705 1.1.75.75 0 1 1-1.194.907 1.47 1.47 0 0 0-1.261-.579c-.422 0-.776.13-1.008.303-.231.173-.304.356-.304.494 0 .139.073.321.304.495.232.174.586.303 1.008.303.716 0 1.392.217 1.907.603.514.387.902.978.902 1.693 0 .716-.39 1.307-.904 1.694-.33.247-.725.425-1.155.52v.364a.75.75 0 0 1-1.5 0v-.353a2.973 2.973 0 0 1-1.709-1.099.75.75 0 1 1 1.194-.908 1.475 1.475 0 0 0 1.261.579c.423 0 .777-.13 1.009-.303.231-.174.304-.356.304-.494 0-.139-.073-.32-.303-.494-.23-.173-.584-.302-1.006-.302-.716 0-1.393-.217-1.908-.604-.515-.386-.904-.978-.904-1.694 0-.717.389-1.308.904-1.694.33-.248.727-.426 1.158-.521V5.25a.75.75 0 0 1 .75-.75ZM10.25 15a.75.75 0 0 1 .75-.75h6a.75.75 0 0 1 0 1.5h-6a.75.75 0 0 1-.75-.75ZM5 19.5a.75.75 0 0 1 .75-.75H17a.75.75 0 0 1 0 1.5H5.75A.75.75 0 0 1 5 19.5Z"
          clipRule="evenodd"
        />
        <defs>
          <linearGradient id="a" x1={0.5} x2={21.5} y1={12} y2={12} gradientUnits="userSpaceOnUse">
            <stop offset={0.084} stopColor="#FF0048" />
            <stop offset={0.386} stopColor="#DF36FF" />
            <stop offset={0.98} stopColor="#4A00E2" />
          </linearGradient>
        </defs>
      </g>
    ),
    viewBox: '0 0 22 24'
  };
};
