import Box from 'components/UI/Layout/Box';
import Typography from 'components/UI/Typography';
import { FontSize, FontWeight, Spacing } from 'modules/styles/variables';
import React, { useState } from 'react';
import Config from 'react-native-config';
import { MuiBox, MuiButton } from 'theme/material-ui';
import TermsAndConditions from 'screens/TermsAndConditions/TermsAndConditions';
import { TermsAndConditionsType } from 'store/global/reducers';
import { PRIVACY_NOTICES_URL } from 'lib/constants/help';
import { convertToLowerKabobCase } from 'modules/utils/StringUtils';
import { Color } from 'modules/styles/colors';

interface AboutInfoItem {
  title: string;
  text: string;
  link?: () => void;
}

const AboutScreen = () => {
  const [termsType, setTermsType] = useState<TermsAndConditionsType | null>();

  const ABOUT_INFO: AboutInfoItem[] = [
    {
      title: 'Version Number',
      text: `${Config.APP_VERSION}`
    },
    {
      title: 'Terms of Use',
      text: '',
      link: () => setTermsType(TermsAndConditionsType.GENERAL)
    },
    {
      title: 'Privacy Notices',
      text: '',
      link: () => window.open(PRIVACY_NOTICES_URL, '_blank')
    }
  ];

  const AboutItem = ({ text, title, link }: AboutInfoItem) => (
    <>
      <Box
        style={{ display: 'grid', gridTemplateColumns: '1fr 4fr', gridGap: '10px' }}
        spacing={Spacing.large}
      >
        <Box style={{ whiteSpace: 'nowrap' }}>
          {!link ? (
            <Typography>{title}</Typography>
          ) : (
            <MuiBox width="fit-content">
              <MuiButton
                fullWidth
                size="large"
                variant="text"
                style={{
                  fontSize: FontSize.base,
                  fontWeight: FontWeight.normal,
                  color: Color.secondary
                }}
                onClick={() => link()}
                data-testid={convertToLowerKabobCase(title)}
              >
                {title}
              </MuiButton>
            </MuiBox>
          )}
        </Box>
        <Box>
          <Typography fontWeight={FontWeight.regularbold}>{text}</Typography>
        </Box>
      </Box>
    </>
  );

  return (
    <Box style={{ backgroundColor: Color.baseColor, flexGrow: 1 }}>
      <TermsAndConditions
        declineAction={() => setTermsType(null)}
        open={!!termsType}
        type={termsType}
        close
      />
      {ABOUT_INFO.map(item => (
        <AboutItem {...item} key={item.title} />
      ))}
    </Box>
  );
};

export default AboutScreen;
