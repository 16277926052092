import React from 'react';

import { MuiBox, MuiTypography } from 'theme/material-ui';

import { Color } from 'modules/styles/colors';
import { FontWeight } from 'modules/styles/variables';

import { ChatItem, UserType } from 'store/amwell/types';

import { MessageListItem, MessageReceivedBubble, MessageSentBubble } from './styled';

function ConnectCareWaitingRoomChatListItem(props: { chatItem: ChatItem }) {
  const { chatItem } = props;

  const hasLabel =
    chatItem.userType === UserType.PROVIDER ||
    chatItem.userType === UserType.ASSISTANT ||
    chatItem.userType === UserType.CONSUMER;

  return (
    <MessageListItem key={chatItem.ordinal}>
      <MuiBox
        width="100%"
        display="flex"
        alignItems="flex-end"
        justifyContent={chatItem.isSelf ? 'flex-end' : 'flex-start'}
      >
        {hasLabel && (
          <MuiBox mr={1}>
            <MuiTypography color={Color.primary} fontWeight={FontWeight.semibold}>
              {!chatItem.isSelf ? chatItem.fullName : null}
            </MuiTypography>
          </MuiBox>
        )}
        {!chatItem.isSelf ? (
          <MessageReceivedBubble bgcolor={Color.grayLight2}>
            <MuiTypography color={Color.black}>{chatItem.message}</MuiTypography>
          </MessageReceivedBubble>
        ) : (
          <MessageSentBubble bgcolor={Color.primary}>
            <MuiTypography color={Color.white}>{chatItem.message}</MuiTypography>
          </MessageSentBubble>
        )}
        <MuiBox ml={1}>
          <MuiTypography color={Color.black} fontWeight={FontWeight.semibold}>
            {chatItem.isSelf ? chatItem.fullName : null}
          </MuiTypography>
        </MuiBox>
      </MuiBox>
    </MessageListItem>
  );
}

export default ConnectCareWaitingRoomChatListItem;
