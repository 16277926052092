import React from 'react';
import { Color } from 'modules/styles/colors';

export default (props: any = { color: Color.black }) => ({
  svg: (
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 1.5C10.8065 1.5 9.66193 1.97411 8.81802 2.81802C7.97411 3.66193 7.5 4.80653 7.5 6V9H16.5V6C16.5 4.80653 16.0259 3.66193 15.182 2.81802C14.3381 1.97411 13.1935 1.5 12 1.5ZM18 9V6C18 4.4087 17.3679 2.88258 16.2426 1.75736C15.1174 0.632141 13.5913 0 12 0C10.4087 0 8.88258 0.632141 7.75736 1.75736C6.63214 2.88258 6 4.4087 6 6V9H5.25C4.00736 9 3 10.0074 3 11.25V21.75C3 22.9926 4.00736 24 5.25 24H18.75C19.9926 24 21 22.9926 21 21.75V11.25C21 10.0074 19.9926 9 18.75 9H18ZM5.25 10.5C4.83579 10.5 4.5 10.8358 4.5 11.25V21.75C4.5 22.1642 4.83579 22.5 5.25 22.5H18.75C19.1642 22.5 19.5 22.1642 19.5 21.75V11.25C19.5 10.8358 19.1642 10.5 18.75 10.5H5.25ZM12 14.25C12.4142 14.25 12.75 14.5858 12.75 15V18C12.75 18.4142 12.4142 18.75 12 18.75C11.5858 18.75 11.25 18.4142 11.25 18V15C11.25 14.5858 11.5858 14.25 12 14.25Z"
        fill={props.color}
      />
    </g>
  ),
  viewBox: '0 0 24 24'
});
