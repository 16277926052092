import React from 'react';
import dayjs from 'dayjs';
import Divider from 'components/UI/Divider';
import { LabResult } from 'store/testResults/types';
import Typography from 'components/UI/Typography';
import { Color } from 'modules/styles/colors';
import { FontSize, FontWeight } from 'modules/styles/variables';
import { MuiBox, MuiPaper, MuiTypography } from 'theme/material-ui';
import { formatPhone } from 'services/formatPhone';

// LAB DETAILS
const LabDetailSection = ({ content }: { content: LabResult[] }) => {
  const result = content[0];
  return (
    <>
      <Divider />
      <MuiBox p={2} bgcolor={Color.grayLight3}>
        <Typography fontSize={FontSize.large} fontWeight={FontWeight.bold}>
          Lab Details
        </Typography>
      </MuiBox>
      <Divider />

      <MuiBox component={MuiPaper} p={3}>
        <MuiTypography fontWeight={FontWeight.bold}>
          Test Manufacturer: <MuiTypography display="inline">{result?.name || 'N/A'}</MuiTypography>
        </MuiTypography>
        <MuiTypography fontWeight={FontWeight.bold}>
          Specimen Source:{' '}
          <MuiTypography display="inline">{result?.specimenSource || 'N/A'}</MuiTypography>
        </MuiTypography>
        <MuiTypography fontWeight={FontWeight.bold}>
          Collection Date/Time:{' '}
          <MuiTypography display="inline">
            {result?.collectionDate
              ? dayjs(result?.collectionDate).format('MMM DD, YYYY [at] h:mm A')
              : 'N/A'}
          </MuiTypography>
        </MuiTypography>
        <MuiTypography fontWeight={FontWeight.bold}>
          Performing Lab:{' '}
          <MuiTypography display="inline">{result?.performingLab || 'N/A'}</MuiTypography>
        </MuiTypography>
        <MuiTypography fontWeight={FontWeight.bold}>
          Performing Lab Address:{' '}
          <MuiTypography display="inline">{result?.performingLabAddress || 'N/A'}</MuiTypography>
        </MuiTypography>
        <MuiTypography fontWeight={FontWeight.bold}>
          Performing Lab Contact:{' '}
          <MuiTypography display="inline">
            {result?.performingLabPhone ? formatPhone(result?.performingLabPhone) : 'N/A'}
          </MuiTypography>
        </MuiTypography>
      </MuiBox>
    </>
  );
};

export default LabDetailSection;
