import { RouteData, publicRoutes, privateRoutes } from '../navigation';
import { PathType } from 'store/pageActions/types';

export function getRoute(pathname: string, routes: RouteData[]): RouteData | undefined {
  pathname = pathname || '';
  const pathArr = pathname.split('?')[0].split('/');

  return routes.find(route => {
    // Always return true on an exact match
    if (route.path === pathname) {
      return true;
    }

    // Check for Variables
    const len = pathArr.length;
    const routePathArr = route.path.split('/');

    // always return false if lengths are differant
    // or there are no varables
    if (len !== routePathArr.length || !route.path.includes(':')) {
      return false;
    }

    // Detect if path has variables
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < len; i++) {
      const routePathEle = routePathArr[i];

      if (routePathEle !== pathArr[i] && !routePathEle.includes(':')) {
        return false; // Unmatched with no variable
      }
    }

    return true;
  });
}

export function isPublicRoute(pathname: string): boolean {
  return !!getRoute(pathname, publicRoutes);
}

export function isPrivateRoute(pathname: string): boolean {
  return !!getRoute(pathname, privateRoutes);
}

export function isLoadingRoute(pathname: string): boolean {
  return pathname.includes('/loading') || pathname.includes('/callback');
}

export function getPathtype(pathname: string): PathType {
  let pathType: PathType;
  const isPrivate = isPrivateRoute(pathname);
  const isPublic = isPublicRoute(pathname);

  if (!pathname) {
    pathType = 'ANY';
  } else if (pathname.includes('/al/events')) {
    pathType = 'AUTH_LITE';
  } else if (isLoadingRoute(pathname)) {
    pathType = 'LOADING';
  } else if (isPrivate && isPublic) {
    pathType = 'EITHER_VALID';
  } else if (isPrivate) {
    pathType = 'AUTHENTICATED';
  } else if (isPublic) {
    pathType = 'UNAUTHENTICATED';
  } else {
    pathType = 'ANY';
  }

  return pathType;
}
