export function createConsoleWrapperAttachPoints() {
  const config = {
    timerDOMNode: document.getElementById('awsdk-timer'),
    providerNotesDOMNode: document.getElementById('awsdk-menuNotes'),
    chatDOMNode: document.getElementById('awsdk-menuChat'),
    inviteGuestDOMNode: document.getElementById('awsdk-menuInviteGuest'),
    providerNotesContentDOMNode: document.getElementById('awsdk-notesContent'),
    providerNotesDetailTitleDOMNode: document.getElementById('awsdk-notesDetailsTitle'),
    menuDetailsWrapperDOMNode: document.getElementById('awsdk-menuDetailsWrapper'),
    chatDetailsDOMNode: document.getElementById('awsdk-chatDetails'),
    inviteGuestDetailsDOMNode: document.getElementById('awsdk-inviteGuestDetails'),
    noteDetailsDOMNode: document.getElementById('awsdk-notesDetails'),
    chatDetailsTitleDOMNode: document.getElementById('awsdk-chatDetailsTitle'),
    chatDetailsIntroTextDOMNode: document.getElementById('awsdk-chatIntroText'),
    chatDetailsMessageAreaDOMNode: document.getElementById('awsdk-chatMsgArea'),
    chatDetailsInputDOMNode: document.getElementById('awsdk-chatInput'),
    chatDetailsInputButtonDOMNode: document.getElementById('awsdk-chatInputIcon'),
    inviteGuestDetailsTitleDOMNode: document.getElementById('awsdk-inviteGuestDetailsTitle'),
    inviteGuestDetailsInvitedDOMNode: document.getElementById('awsdk-inviteGuestDisplayInvited'),
    inviteGuestLabelDOMNode: document.getElementById('awsdk-guestLabel'),
    inviteGuestIntroTextDOMNode: document.getElementById('awsdk-inviteGuestIntroText'),
    inviteGuestInputDOMNode: document.getElementById('awsdk-inviteGuestInput'),
    inviteGuestButtonDOMNode: document.getElementById('awsdk-inviteGuestButton'),
    closeMenuDOMNode: document.getElementById('awsdk-menuClose'),
    maxInviteeDOMNode: document.getElementById('awsdk-maxInvitesMessage'),
    inviteGuestInputWrapperDOMNode: document.getElementById('awsdk-inviteGuestInputWrapper'),
    notesTabTitleDOMNode: document.getElementById('awsdk-menu-tab-title-notes'),
    chatTabTitleDOMNode: document.getElementById('awsdk-menu-tab-title-chat'),
    inviteTabTitleDOMNode: document.getElementById('awsdk-menu-tab-title-invite'),
    inviteGuestErrorTextDOMNode: document.getElementById('awsdk-inviteGuestErrorText')
  };
  return config;
}

export const messages = {
  /**
   * Strings used within the web_common_sdk's WebRTC video console
   */
  chat_window_title: 'CHAT',
  chat_window_preparing: 'Preparing for your visit',
  chat_input_placeholder_text: 'Type Message...',
  invite_guests_already_invited: 'This guest has already been invited',
  invite_guests_email_placeholder: 'Email',
  invite_guests_max_guests_prompt: 'You have reached the maximum number of guests you can invite.',
  invite_guests_prompt: "Enter an email address and we'll send your guest a link to join the visit",
  invite_guests_send_invite: 'SEND INVITE',
  invite_guests_small_title: 'Guests:',
  invite_guests_title: 'INVITE GUESTS',
  tab_chat: 'Chat',
  tab_invite: 'Invite',
  tab_notes: 'Notes',
  time_remaining: 'Remaining',
  visit_notes_title: 'VISIT NOTES',
  accept_permission:
    'Please allow access to your camera and microphone. You may need to update your browser settings to allow access.',
  call_me: 'Call Me',
  camera_failed_help: 'An internal or external webcam is required for your video visit.',
  camera_failed: 'Camera Test Failed',
  camera_troubleshoot_0: 'Ensure your webcam is plugged in and enabled',
  camera_troubleshoot_1: 'Confirm your browser settings are allowing use of your webcam',
  camera_troubleshoot_2: 'Verify your camera selection is set to the correct webcam',
  camera_troubleshoot_3: 'Ensure your webcam is not in use by another application',
  camera_troubleshoot_4:
    'If using an external camera, try connecting your webcam to a different USB port',
  camera_troubleshoot_5: 'Restart your computer',
  camera: 'Camera',
  can_cancel_rejoin:
    "{participantName} will rejoin shortly. If you'd prefer not to wait, you can cancel the visit.",
  can_cancel:
    "You've connected and {participantName} will be joining you shortly. If you'd prefer not to wait, you can cancel the visit.",
  connecting_guest: 'Please wait.',
  connecting_guest_subtext: 'Your visit is about to begin.',
  connecting_remote_extended: "You've connected but we're still loading {participantName}'s video",
  connecting_remote_extended_subtext: "If you'd prefer not to wait you can cancel the visit.",
  connecting_remote_extended_subtext_ivr:
    "If you'd prefer, you can still talk to {participantName} on the phone, or continue to wait for the video",
  connecting_with_remote: "We're connecting you with {remoteName}",
  connecting_your_video: "We're connecting your video.",
  connecting_your_video_extended: 'It looks like you are having trouble connecting.',
  connecting_your_video_subtext_extended:
    "We're still trying to connect you. If you'd prefer not to wait, you can cancel the visit",
  connecting_your_video_subtext_extended_ivr:
    "We're still trying to connect you. If you’d prefer, you can talk to the {participantName} on the phone, or continue to wait for video.",
  consumer_reconnecting:
    "We're reconnecting {participantName}'s video. Please wait for your patient to rejoin",
  consumer_subtitle_age: 'Age {age}',
  continue: 'Continue',
  done: 'Done',
  end_visit_confirmation_title: 'Are you sure?',
  full_screen: 'Full Screen',
  get_started_detail:
    'On the following screens we’ll check your camera, microphone, speakers and internet so you are ready for your visit.',
  get_started: "Let's Get Started",
  header_browser_permission: 'Browser Permission',
  internet_troubleshoot_0: 'Try using a wired connection to the internet if possible.',
  internet_troubleshoot_1:
    'If a wired connection is not available, be sure that your wireless network signal is strong.',
  internet_troubleshoot_2:
    'If your wireless network signal is weak, try moving closer to the router.',
  media_permission_denied:
    'We are having trouble accessing camera and microphone. Please check your camera and microphone permissions.',
  mic: 'Mic',
  microphone_troubleshoot_0: 'Ensure your microphone is plugged in and enabled',
  microphone_troubleshoot_1: 'Confirm your browser settings are allowing use of your microphone',
  microphone_troubleshoot_2: 'Verify your camera selection is set to the correct microphone',
  microphone_troubleshoot_3: 'Ensure your microphone is not in use by another application',
  microphone_troubleshoot_4:
    'If using an external microphone, try connecting your microphone to a different USB port',
  microphone_troubleshoot_5: 'Restart your computer',
  no: 'No',
  participant_connection_issue:
    'We are having trouble connecting with the server. Please check your connection. We will try again after you resolve the issue.',
  participant_type_label_consumer: 'patient',
  participant_type_label_guest: 'guest',
  participant_type_label_provider: 'provider',
  provider_reconnecting:
    "We're reconnecting {participantName}'s video. Please wait for your provider to rejoin",
  reconnecting_remote: "We're reconnecting {participantName}'s video",
  reconnecting_consumer_subtext: 'Please wait for your patient to rejoin the visit.',
  reconnecting_provider_subtext: 'Please wait for your provider to rejoin the visit.',
  reconnecting_your_video_main: 'It looks like your video disconnected.',
  reconnecting_your_video_extended: "It looks like you're having trouble reconnecting",
  reconnecting_your_video_extended_subtext:
    "We're still trying to connect you. If you'd prefer not to wait, you can end the visit",
  reconnecting_your_video_extended_subtext_ivr:
    "We're still trying to connect you. If you’d prefer, you can talk to the provider on the phone, or continue to wait for video.",
  reconnecting_your_video_subtext: "We're trying to reconnect you now",
  reconnecting_your_video_subtext_extended:
    "We're still trying to connect you. If you'd prefer not to wait, you can end the visit",
  refresh: 'Reload',
  select_camera: 'Select a Camera',
  select_microphone: 'Select a Microphone',
  select_speaker: 'Select a Speaker',
  settings_camera_list_header: 'CAMERA',
  settings_microphone_list_header: 'MICROPHONE',
  settings_speaker_list_header: 'SPEAKER',
  settings: 'Settings',
  settings_details: 'Select the source for your inputs.',
  settings_default: 'Default',
  speaker_troubleshoot_0: "Check that your volume isn't muted",
  speaker_troubleshoot_1: 'Verify your volume is not turned down too low',
  speaker_troubleshoot_2:
    'If using external speakers/headset, try unplugging and plugging them back in',
  speaker_troubleshoot_3: 'Restart your computer',
  start_test: 'Start Test',
  step_camera_title: 'Camera',
  step_get_started_progress_title: 'Start',
  step_get_started_title: 'Get Started',
  step_internet_fail: 'Your internet connection didn’t pass the test',
  step_internet_fail_help:
    'You can retry the speed test by clicking the <b>AGAIN</b> button on the right.',
  step_internet_heading:
    'Press the GO button to test if your internet connection is strong enough for a visit.',
  step_internet_pass: 'Your internet connection passed the test',
  step_internet_skip: 'Skip Speed Test',
  step_internet_title: 'Internet',
  step_microphone_details: 'An internal or external microphone is required for your video visit.',
  step_microphone_fail: 'Microphone Test Failed',
  step_microphone_heading: 'Speak or make a noise. Did the green bars move?',
  step_microphone_title: 'Microphone',
  step_retry: 'Retry',
  step_skip: 'Skip',
  step_speaker_failed_help:
    'Your computer must have internal speakers or an audio port with external speakers/headset connected so you can hear during your visit.',
  step_speaker_failed: 'Speaker Test Failed',
  step_speaker_intro: 'Click the play button. Did the sound play?',
  step_speaker_title: 'Speaker',
  step_summary_fail_details:
    'Please try the test again or call Support at {formattedCustomerSupportPhone} for assistance.',
  step_summary_fail_details_no_phone: 'Please try the test again',
  step_summary_fail: 'Your computer is not ready for online visits',
  step_summary_pass: 'Your computer is ready for online visits',
  step_summary_title: 'Summary',
  talk_via_phone: 'Talk Via Phone',
  test_camera_tooltip: 'Test Camera',
  test_internet_tooltip: 'Test Internet',
  test_microphone_tooltip: 'Test Microphone',
  test_speaker_tooltip: 'Test Speaker',
  troubleshoot_tips: 'Troubleshooting Tips:',
  try_again: 'Try Again',
  video_no_access: 'We are unable to access your camera.',
  video_no_access_details:
    'Please check your browser permission settings to continue with this visit.',
  video_prompt: 'Is your video area on the right?',
  webrtc_additional_comments: 'Additional Comments',
  webrtc_back: 'Back',
  webrtc_cancel_visit: 'Cancel Visit',
  webrtc_cancel_visit_confirmation: 'Are you sure you want to cancel this visit?',
  webrtc_cancel_visit_sub: '(You will not be charged)',
  webrtc_confirm_end: 'YES',
  webrtc_disregard_end: 'NO',
  webrtc_end_visit_confirmation: 'Are you sure you want to end this visit?',
  webrtc_end_visit: 'End Visit',
  webrtc_guest_name_default: 'Guest',
  webrtc_refresh_visit: 'Refresh Video',
  webrtc_visit_report_note:
    'Visit report will NOT be generated and any entries you’ve made will not be applied.',
  yes: 'Yes',
  self_video: 'Self View'
};
