import React from 'react';
import { Icon } from 'components/Icon';
import { Color } from 'modules/styles/colors';
import { StyledFlexBox, Title, SubTitle, BannerBackButton, BannerCloseButton } from './styled';
import { FontSize } from 'modules/styles/variables';

interface Props {
  backgroundColor?: string;
  padding?: string;
  title?: string;
  subTitle?: string;
  showBackButton?: boolean;
  showCloseButton?: boolean;
  onCloseButtonPress?: (event: MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onBackButtonPress?: (event: MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const Banner = (props: Props) => {
  const {
    onBackButtonPress,
    onCloseButtonPress,
    showCloseButton,
    title,
    subTitle,
    showBackButton,
    ...styledProps
  } = props;
  return (
    <StyledFlexBox {...styledProps}>
      <Title FontSize={FontSize.largeHeading} data-testid="FABanner">
        {title}
      </Title>
      {subTitle ? <SubTitle>{subTitle}</SubTitle> : null}
      {showBackButton ? (
        <BannerBackButton onClick={onBackButtonPress} accessibilityLabel="back button">
          <Icon name="arrow-back" size={24} color={Color.textDark} />
        </BannerBackButton>
      ) : null}
      {showCloseButton ? (
        <BannerCloseButton
          data-testid="FABannerClose"
          onClick={onCloseButtonPress}
          accessibilityLabel="close button"
        >
          <Icon name="close" size={24} color={Color.textDark} />
        </BannerCloseButton>
      ) : null}
    </StyledFlexBox>
  );
};

Banner.defaultProps = {
  showBackButton: false,
  showCloseButton: false,
  onCloseButtonPress: () => null,
  onBackButtonPress: () => null
};

export default Banner;
