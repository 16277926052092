import React from 'react';
import Spacer from 'components/UI/Layout/Spacer';
import Spinner from 'components/UI/Spinner/Spinner';
import { history } from 'lib/history';
import { MuiBox, MuiTypography } from 'theme/material-ui';
import { StyledMuiButton } from './styled';
import { Spacing } from 'modules/styles/variables';

export const ProviderLoading = () => (
  <MuiBox p={5} display="flex" alignItems="center" justifyContent="center">
    <Spinner />
  </MuiBox>
);

export const ProviderNoData = () => (
  <MuiBox
    p={5}
    marginTop={Spacing.smallMedium}
    display="flex"
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
  >
    <MuiTypography align="center">No provider found</MuiTypography>
    <MuiTypography align="center">return to search for providers</MuiTypography>
    <Spacer size="medium" />
    <MuiBox>
      <StyledMuiButton variant="contained" onClick={() => history.goBack()}>
        Return
      </StyledMuiButton>
    </MuiBox>
  </MuiBox>
);

export const ProviderError = () => (
  <MuiBox p={5} display="flex" alignItems="center" justifyContent="center">
    <MuiTypography>There was an issue loading this provider</MuiTypography>
  </MuiBox>
);
