import React from 'react';
import styled from 'styled-components';
import { MuiBox, MuiTypography } from 'theme/material-ui';
import { Color } from 'modules/styles/colors';
import { FontWeight, FontSize } from 'modules/styles/variables';
import Spacer from 'components/UI/Layout/Spacer';
import Svg from 'components/UI/Svg/Svg';

import frontPlaceholder from 'assets/evisits/insurance_front.png';
import backPlaceholder from 'assets/evisits/insurance_back.png';

const StatusIcon = styled.div<{ isError?: boolean }>`
  position: relative;
  margin-top: -25px;
  background-color: ${({ isError }) => (isError ? Color.red : Color.baseColor)};
  border-radius: 50%;
  display: inline-block;
  width: ${FontSize.mediumHeading}px;
  height: ${FontSize.mediumHeading}px;
  padding: ${({ isError }) => (isError ? '4px' : '0')};
  align-self: center;
`;

const StatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 5px;
`;

export const UploadCardContentError = ({ isBackSide }: { isBackSide?: boolean }) => (
  <>
    <StatusWrapper>
      <img alt="" src={isBackSide ? backPlaceholder : frontPlaceholder} />
      <StatusIcon isError>
        <Svg
          set="assets"
          name="CloseIcon"
          size={FontSize.mediumHeading - 8}
          color={Color.foreColor}
        />
      </StatusIcon>
    </StatusWrapper>
    <MuiTypography color={Color.red} fontWeight={FontWeight.bold}>
      Error
    </MuiTypography>
    <Spacer spacing="small" />
    <MuiTypography color={Color.red} fontSize={FontSize.xsmall}>
      There was an error uploading <br /> your image
    </MuiTypography>
  </>
);

export const UploadCardContentSuccess = ({ selectedImage }: { selectedImage?: string }) => (
  <>
    <StatusWrapper>
      {selectedImage ? (
        <MuiBox style={{ maxWidth: '120px', overflowX: 'hidden' }}>
          <img src={selectedImage} alt="" style={{ width: 'auto', height: '60px' }} />
        </MuiBox>
      ) : null}
      <StatusIcon>
        <Svg
          set="assets"
          name="CheckCircleFilled"
          size={FontSize.mediumHeading}
          color={Color.darkGreenShade}
        />
      </StatusIcon>
    </StatusWrapper>
    <MuiTypography color={Color.darkGreenShade} fontWeight={FontWeight.bold}>
      Successful
    </MuiTypography>
    <Spacer spacing="small" />
    <MuiTypography color={Color.link} fontSize={FontSize.xsmall}>
      Press to preview or retake <br /> image
    </MuiTypography>
  </>
);

export const UploadCardContentDefault = ({ isBackSide }: { isBackSide?: boolean }) => (
  <>
    <div>
      <img alt="" src={isBackSide ? backPlaceholder : frontPlaceholder} />
    </div>
    <MuiTypography color={Color.link} fontWeight={FontWeight.bold}>
      Add photo
    </MuiTypography>
    <Spacer spacing="small" />
    <MuiTypography color={Color.link} fontSize={FontSize.xsmall}>
      Browse your photo library to <br /> add a photo
    </MuiTypography>
  </>
);
