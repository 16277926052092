import styled from 'styled-components';
import { Color } from 'modules/styles/colors';
import { BorderRadius, FontFamily, FontSize, Spacing } from 'modules/styles/variables';
import SmallText from '../Typography/SmallText';

type InputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

type TextAreaProps = React.DetailedHTMLProps<
  React.TextareaHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
>;

export interface StyledInputProps extends InputProps {
  error?: string | null;
  iconName?: string;
  rounded?: boolean;
  multiline?: boolean;
  ref?: string;
  autoComplete?: string;
  label?: string;
  fullWidth?: boolean;
}

export interface StyledTextAreaProps extends TextAreaProps {
  error?: string | null;
  iconName?: string;
  rounded?: boolean;
  multiline?: boolean;
  ref?: string;
}
export const StyledInput = styled.input<StyledInputProps>`
  background-color: ${Color.foreColor};
  border: 1px solid ${props => (props.error ? Color.red : Color.grayHue4)};
  color: ${Color.textDark};
  font-family: ${FontFamily.primary};
  font-size: ${FontSize.large};
  line-height: 20px;
  letter-spacing: 0.5;
  min-height: ${props => (props.multiline ? 100 : 42)};
  padding: 6px 10px;
  padding-left: ${props => (props.iconName ? 40 : 10)};
  width: 100%;
  border-radius: ${props => (props.rounded ? BorderRadius.xLarge : 0)};
`;

export const StyledTextArea = styled.textarea<StyledTextAreaProps>`
  background-color: ${Color.foreColor};
  border: 1px solid ${props => (props.error ? Color.red : Color.grayHue4)};
  color: ${Color.textDark};
  font-family: ${FontFamily.primary};
  font-size: ${FontSize.large};
  line-height: 20px;
  letter-spacing: 0.5;
  min-height: ${props => (props.multiline ? 100 : 42)};
  padding: 6px 10px;
  padding-left: ${props => (props.iconName ? 40 : 10)};
  width: 100%;
  border-radius: ${props => (props.rounded ? BorderRadius.xLarge : 0)};
`;

export const ErrorText = styled(SmallText)<{ formGroup: boolean }>`
  margin-top: ${Spacing.medium};
  color: ${Color.red};
  position: ${props => (props.formGroup ? 'absolute' : 'relative')};
  top: ${props => (props.formGroup ? '39px' : '0px')};
`;

export const RequiredLabel = styled(SmallText)`
  right: 10px;
  position: absolute;
  top: 9px;
  color: ${Color.red};
`;
