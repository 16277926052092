import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import * as d3 from 'd3';
import { Color } from 'modules/styles/colors';
import React, { useEffect, useState } from 'react';
import { sideBarWidthCollapsed, sideBarWidthOpen } from 'components/AuthLayout/styled';
import { useInterval } from 'lib/hooks/useInterval';
import { useWindowSize } from 'hooks/useWindowSize';
import { LabHistoricalComponent } from 'store/testResults/types';
import { oc } from 'ts-optchain';
import {
  LegendContainer,
  LineChartContainer,
  LineChart,
  LineChartContainerStyles,
  LineChartHeader,
  StyledDiv,
  YAxisRight,
  HeaderLabel,
  YAxisLeft
} from './styled';
import ChartLegend from './ChartLegend';

export interface ChartData {
  [x: string]: any;
  date: Date;
  value: number;
  interpretation?: string;
}
export interface ChartMarginProps {
  bottom: number;
  left: number;
  right: number;
  top: number;
}
export interface LabTrendLineChartProps {
  data: LabHistoricalComponent[];
  showBackground?: boolean;
  labName: string;
}

export const LabTrendLineChart = (props: LabTrendLineChartProps) => {
  const [isMenuCollapsed, setIsMenuCollapsed] = useState(
    window.sessionStorage.getItem('collapsed') === 'true'
  );
  const [menuWidth, setMenuWidth] = useState(sideBarWidthOpen);
  const { data, labName } = props;
  const units = data[0]?.valueQuantity?.unit || '';
  const LOW_VALUE = data[0]?.referenceRange?.low?.value || 0;
  const HIGH_VALUE = data[0]?.referenceRange?.high?.value || 0;
  const hasRefRange = LOW_VALUE >= 0 && HIGH_VALUE > 0;
  const theme = useTheme();
  const hasEmbeddedMenu = useMediaQuery(theme.breakpoints.between('sm', 'lg'));
  const { width: innerWidth } = useWindowSize();
  const isMobileDevice = innerWidth <= 500;
  const isMargin = innerWidth <= 500 ? 30 : 60;
  useInterval(() => setIsMenuCollapsed(window.sessionStorage.getItem('collapsed') === 'true'), 500);

  const ref = React.createRef();
  const state = {
    yAxisAttribute: 'skill',
    xAxisAttribute: 'value',
    width: 600,
    height: 0,
    chartMargin: {
      top: 40,
      bottom: isMobileDevice ? 50 : 40,
      right: isMobileDevice ? -15 : -40,
      left: isMobileDevice ? 30 : 60
    },
    showTabs: false,
    rotateLabelY: true,
    graphPointLabelModifier: '',
    componentClass: '',
    timeFormatMonthDay: '%b% % %d',
    timeFormatYear: '%Y',
    timeFormatMonth: '%b',
    timeFormatMonthYear: "%b '%y",
    timeFormatFull: '%b% % %d, % %Y',
    labelX: units,
    labelY: labName,
    currentYear: 'View All',
    chartAspect: 6 / 4,
    chartYears: [],
    startDate: '',
    endDate: '',
    range: null,
    originalDataSet: []
  };

  // CleanData used for anything other than blood pressure
  const cleanData = (data: LabHistoricalComponent[]) => {
    const chartData: ChartData[] = [];
    data.forEach((labHistoricalComponent: LabHistoricalComponent) => {
      if (!labHistoricalComponent) {
        return;
      }
      const getValue = () => oc(labHistoricalComponent).valueQuantity.value();
      chartData.push({
        date: new Date(labHistoricalComponent.issued),
        value: getValue(),
        change: {
          value: labHistoricalComponent.valueChange?.value,
          direction: labHistoricalComponent.valueChange?.direction
        },
        interpretation: labHistoricalComponent.interpretation,
        valueUnits: labHistoricalComponent.valueQuantity
      });
    });
    draw(chartData);
  };
  const setMaxY = d => {
    return parseFloat(d.value);
  };
  const setMinY = d => {
    return parseFloat(d.value);
  };

  const getSvgWidth = () => {
    const padding = 117;
    const { length } = data;
    let multiplier = 100;
    let lowPointsWidth = 842;

    if (innerWidth < 1245) {
      multiplier = innerWidth <= 500 ? 60 : 85;
      lowPointsWidth = innerWidth - padding;
    }

    if (length <= 8) return lowPointsWidth;
    return length * multiplier;
  };

  const getHeight = () => {
    if (data.length > 8 || menuWidth) {
      if (isMobileDevice) return 280;
      return 295;
    }
    return 245;
  };

  const getOpacity = () => {
    if (data.length > 8 || menuWidth) return 1;
    return 0;
  };

  const getHighStop = (minY: number, maxY: number) => {
    if (hasRefRange) {
      const calcValue = 100 - Math.round(((HIGH_VALUE - minY) * 100) / (maxY - minY));
      return Math.max(calcValue, 0);
    }
    return 30;
  };

  const getLowStop = (minY: number, maxY: number) => {
    if (hasRefRange) {
      const calcValue = 100 - Math.round(((LOW_VALUE - minY) * 100) / (maxY - minY));
      return Math.min(calcValue, 100);
    }
    return 70;
  };

  const getHighValueColor = (highValue: number, maxY: number) => {
    if (hasRefRange) {
      if (maxY <= highValue) return Color.darkGreenShade;
      return Color.darkOrangeShade;
    }
    return Color.darkGreenShade;
  };

  const getLowValueColor = (lowValue: number, minY: number) => {
    if (hasRefRange) {
      if (minY >= lowValue) return Color.darkGreenShade;
      return Color.darkOrangeShade;
    }
    return Color.darkGreenShade;
  };

  const getThreshold = (value: number) => {
    if (value <= 0) return 0;
    const threshold = +Math.abs(Math.log10(value)).toFixed(1);
    return threshold / 2;
  };

  const draw = data => {
    d3.select('svg.line-chart > *').remove();
    d3.select('svg.y-axis > *').remove();
    const svg = d3.select('svg.line-chart');
    const axis = d3.select('svg.y-axis');
    const svgWidth = getSvgWidth();
    const svgHeight = 360;
    const margin = state.chartMargin;
    const THRESHOLD = getThreshold(d3.min(data, setMinY));
    const maxY = d3.max(data, setMaxY) + THRESHOLD;
    const minY = d3.min(data, setMinY) - THRESHOLD;
    const normalValueColor = Color.darkGreenShade;
    const areaHighValueColor = getHighValueColor(HIGH_VALUE, maxY - THRESHOLD);
    const areaLowValueColor = getLowValueColor(LOW_VALUE, minY + THRESHOLD);
    const lineHighValueColor = getHighValueColor(HIGH_VALUE, maxY - THRESHOLD);
    const lineLowValueColor = getLowValueColor(LOW_VALUE, minY + THRESHOLD);
    const highPercentageStop = getHighStop(minY, maxY);
    const lowPercentageStop = getLowStop(minY, maxY);
    const GRADIENT_BLUR_VALUE = 6;
    const width = svgWidth - margin.left - margin.right;
    const height = svgHeight - margin.top - margin.bottom;
    const tooltipWidth = 115;
    const tooltipHeight = 60;
    const tooltipMargin = 20;
    const reverse: number[] = [];
    let prevx = -1;
    let diff = 0;

    // Select SVG and set width and height
    svg
      .attr('preserveAspectRatio', 'xMidYMid meet')
      .attr('viewbox', `0 0 ${svgWidth} ${svgHeight}`)
      .attr('width', svgWidth)
      .attr('height', svgHeight)
      .attr('shape-rendering', 'auto');

    // Select SVG and set width and height
    axis
      .attr('preserveAspectRatio', 'xMidYMid meet')
      .attr('viewbox', `0 0 ${svgWidth} ${svgHeight}`)
      .attr('width', svgWidth)
      .attr('height', svgHeight)
      .attr('shape-rendering', 'auto');

    // Append group tag inside SVG element (move to specific position)
    const svgGroup = svg.append('g').attr('transform', `translate(${0}, ${margin.top})`);
    const yAxis = axis.append('g').attr('transform', `translate(${margin.left}, ${margin.top})`);
    const xScale = d3.scaleTime().rangeRound([0, width]);

    // Keep scale of y-axis linear
    const yScale = d3.scaleLinear().rangeRound([height, 0]);

    const x = xScale;
    const y = yScale;

    const xDomain = d3.extent(data, d => {
      return d.date;
    });

    const yDomain = [minY, maxY];
    const linePath = d3
      .line()
      .x(d => {
        return x(d.date);
      }) // returns minimum and maximum value of the dates
      .y(d => {
        return y(d.value);
      }); // returns minimum and maximum
    x.domain(xDomain);
    y.domain(yDomain);

    const ticksY = Math.round(height / 100) + 6;

    if (data.length === 0) {
      svgGroup
        .append('text')
        .classed('graph-label-note', true)
        .attr('y', height / 2)
        .attr('x', width / 2)
        .attr('text-anchor', 'middle');
    }
    // HORIZONTAL AXIS (DATES)
    svgGroup
      .append('g')
      .attr('transform', () => {
        if (data.length > 8 || menuWidth)
          return `translate(${isMobileDevice ? '0' : '0'},${height + 13})`;
        return `translate(${isMobileDevice ? '6' : '0'},${height + 10})`;
      })
      .classed('x-axis', true)
      .call(
        d3.axisBottom(x).tickFormat(date => {
          if (isMobileDevice) {
            if (d3.timeYear(date) < date) {
              if (d3.timeMonth(date) < date) {
                return d3.timeFormat(state.timeFormatMonthDay)(date);
              }
              return d3.timeFormat(state.timeFormatMonthYear)(date);
            }
            return d3.timeFormat(state.timeFormatYear)(date);
          }
          if (d3.timeYear(date) < date) {
            if (d3.timeMonth(date) < date) {
              return d3.timeFormat(state.timeFormatMonthDay)(date);
            }
            return d3.timeFormat(state.timeFormatMonth)(date);
          }
          return d3.timeFormat('%Y')(date);
        })
      )
      .selectAll('text')
      .classed('x-axis-label', true)
      .attr('dx', '1.0em')
      .attr('dy', '.15em')
      .attr(
        'transform',
        `rotate(${isMobileDevice ? '-65' : '0'}) translate(${isMobileDevice ? '-10' : '0'},0)`
      )
      .select('.domain')
      .remove();

    if (!isMobileDevice) {
      svgGroup
        .append('g')
        .attr('transform', () => {
          if (data.length > 8 || menuWidth) return `translate(0,${height + 44})`;
          return `translate(0,${height + 27})`;
        })
        .classed('x-axis', true)
        .classed('no-ticks', true)
        .call(
          d3.axisBottom(x).tickFormat(date => {
            if (d3.timeYear(date) < date) {
              return d3.timeFormat(state.timeFormatYear)(date);
            }
            return '';
          })
        )
        .selectAll('text')
        .classed('x-axis-label', true)
        .attr('dx', '1.43em')
        .attr('dy', '.18em')
        .select('.domain')
        .remove();
    }

    // VERTICAL AXIS
    const axisY = d3.axisLeft(y).ticks(ticksY);
    const yAxisLabelRotation = 0;
    const yAxisLabelPositionX = -10;
    const yAxisLabelPositionY = 0;
    const yAxisLabelTextAlign = 'right';

    yAxis
      .append('g')
      .call(axisY)
      .classed('y-axis', true)
      .attr('transform', 'translate(0,-1)')
      .selectAll('.tick line')
      .selectAll('text')
      .classed('y-axis-label', true)
      .attr('transform', `rotate(${yAxisLabelRotation})`)
      .attr('text-anchor', yAxisLabelTextAlign)
      .attr('x', yAxisLabelPositionX)
      .attr('y', yAxisLabelPositionY);

    const highLineCalc = (highPercentageStop / 100) * height;
    const lowLineCalc = (lowPercentageStop / 100) * height;

    // HIGH & LOW LINES
    if (hasRefRange) {
      svgGroup
        .append('line')
        .classed('dotted-line', true)
        .attr('x2', width + 16)
        .attr('transform', `translate(0,${highLineCalc})`)
        .attr('stroke', 'red');
      svgGroup
        .append('line')
        .classed('dotted-line', true)
        .attr('x2', width + 16)
        .attr('transform', `translate(0,${lowLineCalc})`)
        .attr('stroke', 'red');
    }

    // CHART LINE PATH
    const trend1 = svgGroup
      .append('g')
      .classed('chart-line', true)
      .attr('transform', 'translate(8,-1)');

    // AREA GRADIENT
    const lg = trend1
      .append('defs')
      .append('linearGradient')
      .attr('id', 'areaGrad')
      .attr('x1', '0%')
      .attr('x2', '0%')
      .attr('y1', '0%')
      .attr('y2', '100%');

    lg.append('stop')
      .attr('offset', '0%')
      .style('stop-color', areaHighValueColor)
      .style('stop-opacity', 1);

    lg.append('stop')
      .attr('offset', `${highPercentageStop - GRADIENT_BLUR_VALUE}%`)
      .style('stop-color', areaHighValueColor)
      .style('stop-opacity', 1);

    lg.append('stop')
      .attr('offset', `${highPercentageStop}%`)
      .style('stop-color', normalValueColor)
      .style('stop-opacity', 1);

    lg.append('stop')
      .attr('offset', `${lowPercentageStop}%`)
      .style('stop-color', normalValueColor)
      .style('stop-opacity', 1);

    lg.append('stop')
      .attr('offset', `${lowPercentageStop + GRADIENT_BLUR_VALUE}%`)
      .style('stop-color', areaLowValueColor)
      .style('stop-opacity', 1);

    lg.append('stop')
      .attr('offset', '100%')
      .style('stop-color', areaLowValueColor)
      .style('stop-opacity', 1);

    // LINE GRADIENT
    const lg2 = trend1
      .append('defs')
      .append('linearGradient')
      .attr('id', 'lineGrad')
      .attr('x1', '0%')
      .attr('x2', '0%')
      .attr('y1', '0%')
      .attr('y2', '100%');

    lg2
      .append('stop')
      .attr('offset', '0%')
      .style('stop-color', lineHighValueColor)
      .style('stop-opacity', 1);

    lg2
      .append('stop')
      .attr('offset', `${highPercentageStop - GRADIENT_BLUR_VALUE}%`)
      .style('stop-color', lineHighValueColor)
      .style('stop-opacity', 1);

    lg2
      .append('stop')
      .attr('offset', `${highPercentageStop}%`)
      .style('stop-color', normalValueColor)
      .style('stop-opacity', 1);

    lg2
      .append('stop')
      .attr('offset', `${lowPercentageStop}%`)
      .style('stop-color', normalValueColor)
      .style('stop-opacity', 1);

    lg2
      .append('stop')
      .attr('offset', `${lowPercentageStop + GRADIENT_BLUR_VALUE}%`)
      .style('stop-color', hasRefRange ? lineLowValueColor : normalValueColor)
      .style('stop-opacity', 1);

    lg2
      .append('stop')
      .attr('offset', '100%')
      .style('stop-color', hasRefRange ? lineLowValueColor : normalValueColor)
      .style('stop-opacity', 1);

    const addFlatPaddingToPath = (
      path: string,
      xPadding: number,
      width: number,
      height: number
    ) => {
      const lastYValue = path.split(',')[1].split('L')[0];
      const firstYValue = path.split(',').reverse()[0];
      return `${path.substring(0, 1)}${width + xPadding},${height} L${width +
        xPadding},${lastYValue} L${path.substring(
        1
      )} L0,${firstYValue} L-${xPadding},${firstYValue} V0 V${height} Z`;
    };

    // CHART AREA
    trend1
      .append('path')
      .datum(data)
      .attr('fill', 'url(#areaGrad)')
      .attr('fill-opacity', 0.3)
      .attr('stroke', 'none')
      .attr('d', d => {
        const xPadding = 10;
        const path = linePath(d) || '';
        return addFlatPaddingToPath(path, xPadding, width, height);
      });
    // TRENDLINE PATH
    trend1
      .append('path')
      .datum(data)
      .attr('fill', 'none')
      .attr('d', d => {
        const xPadding = 10;
        const path = linePath(d) || '';
        return addFlatPaddingToPath(path, xPadding, width, height);
      }) // create the line for the d attribute
      .attr('stroke', 'url(#lineGrad)')
      .attr('stroke-dashoffset', d => {
        return d.totalLength;
      })
      .attr('stroke-dasharray', d => {
        return `${d.totalLength} ${d.totalLength}`;
      })
      .transition()
      .attr('stroke-dashoffset', 0)
      .duration(2000);

    const chartData = trend1.append('g').classed('chart-data', true);
    chartData
      .append('clipPath')
      .attr('id', 'rectArea')
      .append('rect')
      .attr('width', width + tooltipMargin)
      .attr('height', height)
      .attr('transform', 'translate(-8,0)');

    const chartDataLines = chartData
      .append('g')
      .classed('chart-data-lines', true)
      .attr('clip-path', 'url(#rectArea)');

    const dataPoint = chartDataLines
      .selectAll('graph-point')
      .data(data)
      .enter()
      .append('g')
      .classed('graph-point', true)
      .attr('stroke', d => {
        if (!hasRefRange) return Color.darkGreenShade;
        if (d.value >= HIGH_VALUE) return Color.darkOrangeShade;
        if (d.value < LOW_VALUE) return Color.darkOrangeShade;
        return normalValueColor;
      })
      .attr('transform', d => {
        return `translate(${x(d.date)},${y(d.value)})`;
      });
    let onActive = null;
    dataPoint.on('mouseover', function() {
      if (onActive != null) d3.select(onActive).classed('active', false);
      d3.select(this).raise();
    });

    dataPoint
      .append('rect')
      .classed('graph-point-hover-block', true)
      .attr('x', 0)
      .attr('y', -250)
      .attr('width', d => {
        if (prevx === -1) {
          prevx = x(d.date);
          return 0;
        }
        diff = (prevx - x(d.date)) / 2;
        reverse.push(diff);
        prevx = x(d.date);
        return diff;
      })
      .attr('height', 1000);
    dataPoint
      .append('rect')
      .classed('graph-point-hover-block-1', true)
      .attr('x', (d, i) => {
        return -reverse[i];
      })
      .attr('y', -250)
      .attr('width', (d, i) => {
        return reverse[i];
      })
      .attr('height', 1000);
    dataPoint
      .append('line')
      .classed('graph-point__line', true)
      .attr('y1', 1000)
      .attr('y2', -1000)
      .attr('stroke', d => {
        if (!hasRefRange) return Color.darkGreenShade;
        if (d.value >= HIGH_VALUE) return Color.darkOrangeShade;
        if (d.value < LOW_VALUE) return Color.darkOrangeShade;
        return normalValueColor;
      })
      .style('stroke-width', 3);

    dataPoint
      .append('circle')
      .classed('graph-point__data-point', true)
      .attr('r', 0)
      .transition()
      .delay((d, i) => {
        let s = i + 1;
        s *= 50;
        if (data.length === 1) {
          s = 0;
        }
        return s;
      })
      .duration(300)
      .attr('r', 8)
      .transition()
      .delay(200)
      .duration(200)
      .attr('r', 4);

    dataPoint.on('mouseout', function() {
      d3.select(this).classed('active', true);
      onActive = this;
    });
    const dataPointLabel = dataPoint.append('g').classed('graph-point__data-group', true);

    const getXValue = (dateXValue?: number, tooltipWidth?: number, margin = 0, offset = 0) => {
      if (dateXValue === undefined || tooltipWidth === undefined) return offset;
      if (dateXValue + tooltipWidth / 2 >= width) return -tooltipWidth + margin + offset;
      if (dateXValue + tooltipWidth / 2 <= tooltipWidth) return -margin + offset;
      return -(tooltipWidth / 2) + offset;
    };

    const getYValue = (dateYValue?: number, tooltipHeight?: number, margin = 0, offset = 0) => {
      if (!dateYValue || !tooltipHeight) return 0;
      if (dateYValue <= tooltipHeight) return margin + offset;
      return -tooltipHeight + offset;
    };

    const getDirectionIcon = (direction: string) => {
      switch (direction) {
        case '1':
          return '↑';
        case '-1':
          return '↓';
        default:
          return '-';
      }
    };

    dataPointLabel
      .append('rect')
      .classed(`graph-point__tooltip ${state.graphPointLabelModifier}`, true)
      .attr('width', tooltipWidth)
      .attr('height', d => (d.change.value !== 'N/A' ? tooltipHeight : tooltipHeight - 15))
      .attr('rx', 3)
      .style('fill', 'white')
      .attr('transform', d => {
        const xValue = getXValue(x(d.date), tooltipWidth, -10);
        const yValue = getYValue(y(d.value), tooltipHeight + tooltipMargin, tooltipMargin);

        return `translate(${xValue}, ${yValue})`;
      });

    dataPointLabel
      .append('text')
      .classed(`graph-point__label_date ${state.graphPointLabelModifier}`, true)
      .attr('transform', d => {
        const xValue = getXValue(x(d.date), tooltipWidth, -10, 5);
        const yValue = getYValue(y(d.value), tooltipHeight + tooltipMargin, tooltipMargin, 20);
        return `translate(${xValue}, ${yValue})`;
      })
      .text(d => d3.timeFormat(state.timeFormatFull)(d.date));

    dataPointLabel
      .append('text')
      .classed(`graph-point__label_value ${state.graphPointLabelModifier}`, true)
      .attr('transform', d => {
        const xValue = getXValue(x(d.date), tooltipWidth, -10, 5);
        const yValue = getYValue(y(d.value), tooltipHeight + tooltipMargin, tooltipMargin, 35);
        return `translate(${xValue}, ${yValue})`;
      })
      .text(d => `${d.value} ${units}`);

    dataPointLabel
      .append('text')
      .classed(`graph-point__label_change ${state.graphPointLabelModifier}`, true)
      .attr('transform', d => {
        const xValue = getXValue(x(d.date), tooltipWidth, -10, 5);
        const yValue = getYValue(y(d.value), tooltipHeight + tooltipMargin, tooltipMargin, 50);
        return `translate(${xValue}, ${yValue})`;
      })
      .text(d =>
        d.change.value && d.change.value !== 'N/A'
          ? `Change: ${d.change.value === '0.00' ? '' : d.change.value} ${getDirectionIcon(
              d.change.direction
            )}`
          : ''
      );

    // GRAPH FRAME
    svgGroup
      .append('line')
      .classed('x-axis-top', true)
      .attr('x1', -4)
      .attr('x2', width + 16)
      .attr('transform', `translate(0,-2)`)
      .style('stroke-width', 4)
      .style('stroke', Color.graySidebarDivider);
    svgGroup
      .append('line')
      .classed('x-axis-bottom', true)
      .attr('x1', -4)
      .attr('x2', width + 16)
      .attr('transform', () => {
        if (data.length > 8 || menuWidth) return `translate(0,${height - 1})`;
        return `translate(0,${height - 1})`;
      })
      .style('stroke-width', 4)
      .style('stroke', Color.graySidebarDivider);
    svgGroup
      .append('line')
      .classed('y-axis-right', true)
      .attr('y1', -4)
      .attr('y2', () => {
        if (data.length > 8 || menuWidth) return height;
        return height;
      })
      .attr('transform', () => {
        if (innerWidth <= 480) return `translate(${width + 14},0)`;
        return `translate(${width + 18},0)`;
      })
      .style('stroke-width', 4)
      .style('position', 'sticky')
      .style('right', 10)
      .style('stroke', Color.graySidebarDivider);

    yAxis
      .append('line')
      .classed('y-axis-left', true)
      .attr('y1', -4)
      .attr('y2', () => {
        if (data.length > 8 || (innerWidth > 480 && innerWidth < 1440)) return height + 1;
        return height;
      })
      .attr('transform', `translate(-2,0)`)
      .style('stroke-width', 4)
      .style('stroke', Color.graySidebarDivider);
  };

  useEffect(() => {
    setMenuWidth(!hasEmbeddedMenu ? 0 : isMenuCollapsed ? sideBarWidthCollapsed : sideBarWidthOpen);
  }, [innerWidth, isMenuCollapsed, hasEmbeddedMenu]);

  useEffect(() => {
    setTimeout(() => {
      cleanData(data);
    }, 1);
  }, [menuWidth, innerWidth]);

  return (
    <div>
      {hasRefRange ? (
        <LineChartHeader width={`${getSvgWidth()}px`}>
          <HeaderLabel>
            Normal Reference Range: {LOW_VALUE} - {HIGH_VALUE}
          </HeaderLabel>
          <LegendContainer>
            <ChartLegend color={Color.darkOrangeShade}>Outside Normal</ChartLegend>
            <ChartLegend color={Color.darkGreenShade}>Normal</ChartLegend>
          </LegendContainer>
        </LineChartHeader>
      ) : null}
      <StyledDiv>
        <YAxisLeft className="y-axis" width={`${isMargin}px`} />
        <YAxisRight height={`${getHeight()}px`} opacity={`${getOpacity()}`} />
        <LineChartContainer>
          <LineChartContainerStyles>
            <LineChart width={`${getSvgWidth()}px`}>
              <svg className="line-chart" ref={ref} width={`${innerWidth}px`} />
            </LineChart>
          </LineChartContainerStyles>
        </LineChartContainer>
      </StyledDiv>
    </div>
  );
};
