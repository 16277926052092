import React, { useEffect, useState } from 'react';

import { MuiBox, MuiFormControlLabel, MuiTypography } from 'theme/material-ui';
import { BookingDrawer } from 'components/booking/BookingDrawer';

import { Thunk } from 'store/types';
import { AppointmentDetails } from 'store/booking/types';
import * as findProviderTypes from 'store/findProvider/types';
import { mergeFiltersAndFacets } from 'store/findProvider/utils';
import { StyledMuiCheckbox } from 'screens/Booking/components/styled';
import { Color } from 'modules/styles/colors';
import Divider from 'components/UI/Divider';

export interface Props {
  appointment: AppointmentDetails;
  open: boolean;
  search: () => void;
  onClose: () => void;
  activeFilters: findProviderTypes.ProviderSearchFilters;
  searchFilter: findProviderTypes.ProviderSearchFilterState;
  setActiveProviderSearchFilter: (
    filter: Partial<findProviderTypes.ProviderSearchFilters>,
    opts?: { search: boolean }
  ) => Thunk;
}

export function SpecialtyProviderFilters({
  appointment,
  open,
  search,
  activeFilters,
  searchFilter,
  setActiveProviderSearchFilter,
  onClose
}: Props) {
  const [genderMaleFilter, setGenderMaleFilter] = useState(true);
  const [genderFemaleFilter, setGenderFemaleFilter] = useState(true);
  const [locationInPersonFilter, setLocationInPersonFilter] = useState(true);
  const [locationVideoFilter, setLocationVideoFilter] = useState(true);

  const toggleGenderMaleFilter = () => setGenderMaleFilter(!genderMaleFilter);
  const toggleGenderFemaleFilter = () => setGenderFemaleFilter(!genderFemaleFilter);
  const toggleLocationInPersonFilter = () => setLocationInPersonFilter(!locationInPersonFilter);
  const toggleLocationVideoFilter = () => setLocationVideoFilter(!locationVideoFilter);
  const toggleAllOptions = (value: boolean) => {
    setGenderMaleFilter(value);
    setGenderFemaleFilter(value);
    setLocationInPersonFilter(value);
    setLocationVideoFilter(value);
  };

  const setDefaultOptions = () => {
    toggleAllOptions(true);

    if (searchFilter.hasActiveFilters) {
      const { filters } = activeFilters;

      filters.forEach(filter => {
        if (filter.group === 'gender') {
          if (filter.value === 'F') {
            setGenderMaleFilter(false);
          } else {
            setGenderFemaleFilter(false);
          }
        }
        if (filter.group === 'offersVideoVisits') {
          if (filter.value) {
            setLocationInPersonFilter(false);
          } else {
            setLocationVideoFilter(false);
          }
        }
      });
    }
  };

  useEffect(() => {
    setDefaultOptions();
  }, [activeFilters, searchFilter]);

  const handleApplyOrClearAllFilters = (clearFilters?: boolean) => {
    const filters: findProviderTypes.LocalFiltersObject = {
      isAcceptingOnlineBooking: true,
      hasOnlineNpvSlots: true
    };

    if (!clearFilters) {
      if ((genderFemaleFilter || genderMaleFilter) && !(genderFemaleFilter && genderMaleFilter)) {
        filters.gender = genderFemaleFilter ? 'F' : 'M';
      }

      if (locationVideoFilter && !locationInPersonFilter) {
        filters.offersVideoVisits = true;
      } else if (!locationVideoFilter && locationInPersonFilter) {
        filters.offersVideoVisits = false;
      }
    } else {
      toggleAllOptions(true);
    }

    const facets = {
      specialty: [
        {
          name: appointment?.specialty?.nameLong || '',
          group: 'specialty',
          value: appointment?.specialty?.nameLong || ''
        }
      ]
    };

    const dispatchSearchFilters: findProviderTypes.ProviderSearchFilters = mergeFiltersAndFacets(
      filters,
      facets
    );

    setActiveProviderSearchFilter(dispatchSearchFilters);
    search();
    onClose();
  };

  const handleClose = () => () => {
    setDefaultOptions();
    onClose();
  };

  return (
    <BookingDrawer
      open={open}
      headerText="Filter By..."
      buttonOneText="Apply filters"
      buttonTwoText="Clear all filters"
      onClose={handleClose()}
      onApply={() => handleApplyOrClearAllFilters()}
      onClear={() => handleApplyOrClearAllFilters(true)}
    >
      <MuiBox width="100%" px={2} pt={3}>
        <MuiTypography color={Color.black}>Gender</MuiTypography>
      </MuiBox>

      <MuiBox width="100%" px={2} pt={2}>
        <MuiFormControlLabel
          control={
            <StyledMuiCheckbox checked={genderMaleFilter} onChange={toggleGenderMaleFilter} />
          }
          label="Male"
        />
      </MuiBox>

      <MuiBox width="100%" px={2} pt={1}>
        <MuiFormControlLabel
          control={
            <StyledMuiCheckbox checked={genderFemaleFilter} onChange={toggleGenderFemaleFilter} />
          }
          label="Female"
        />
      </MuiBox>

      <MuiBox width="100%" px={2} pt={3}>
        <Divider />
      </MuiBox>

      <MuiBox width="100%" px={2} pt={3}>
        <MuiTypography color={Color.black}>Location Preference</MuiTypography>
      </MuiBox>

      <MuiBox width="100%" px={2} pt={2}>
        <MuiFormControlLabel
          control={
            <StyledMuiCheckbox
              checked={locationInPersonFilter}
              onChange={toggleLocationInPersonFilter}
            />
          }
          label="In-Person"
        />
      </MuiBox>

      <MuiBox width="100%" px={2} pt={1}>
        <MuiFormControlLabel
          control={
            <StyledMuiCheckbox checked={locationVideoFilter} onChange={toggleLocationVideoFilter} />
          }
          label="Video"
        />
      </MuiBox>
    </BookingDrawer>
  );
}

export default SpecialtyProviderFilters;
