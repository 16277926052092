import React from 'react';
import { Color } from 'modules/styles/colors';

export default (props: any = { color: Color.black }) => ({
  svg: (
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.26035 7.41811C0.607482 7.07098 1.1703 7.07098 1.51743 7.41811L12 17.9007L22.4826 7.41811C22.8297 7.07098 23.3925 7.07098 23.7397 7.41811C24.0868 7.76524 24.0868 8.32806 23.7397 8.67519L13.1524 19.2624C13.1523 19.2625 13.1522 19.2626 13.1521 19.2627C13.1521 19.2627 13.152 19.2628 13.152 19.2628C13.0008 19.4141 12.8213 19.5341 12.6238 19.616C12.426 19.698 12.2141 19.7402 12 19.7402C11.7859 19.7402 11.574 19.698 11.3762 19.616C11.1787 19.5341 10.9992 19.4141 10.848 19.2628C10.848 19.2628 10.8479 19.2627 10.8479 19.2627C10.8478 19.2626 10.8477 19.2625 10.8476 19.2624L0.26035 8.67519C-0.0867832 8.32806 -0.0867832 7.76524 0.26035 7.41811ZM12.1054 18.0061L12.1051 18.0058C12.1052 18.0059 12.1053 18.006 12.1054 18.0061Z"
        fill={props.color}
      />
    </g>
  ),
  viewBox: '0 0 24 24'
});
