import * as React from 'react';
import { Color } from 'modules/styles/colors';

const styles = {
  fill: 'none',
  strokeWidth: 2,
  strokeLinecap: 'round',
  strokeLinejoin: 'round'
} as const;

export default (props: any) => {
  const { color = Color.secondary } = props;

  return {
    svg: (
      <g id="prefix__Booking">
        <path
          className="prefix__cls-1"
          d="M7.1 20.9v-9.7h33.4V28l-7.1 7h-6.3"
          stroke={color}
          {...styles}
        />
        <path
          className="prefix__cls-1"
          d="M33.4 35v-7h7.1M17.4 7.4a1.3 1.3 0 01-1.3 1.3 1.3 1.3 0 01-1.3-1.3V4.8a1.3 1.3 0 011.3-1.3 1.3 1.3 0 011.3 1.3zM32.8 7.4a1.3 1.3 0 01-1.3 1.3 1.3 1.3 0 01-1.3-1.3V4.8a1.3 1.3 0 011.3-1.3 1.3 1.3 0 011.3 1.3zM35.3 6.1h2.6c1.4 0 2.6.5 2.6 1.9v3.9M20 6.1h7.6M7.1 11.9V8.7a2.5 2.5 0 012.6-2.6h2.5"
          stroke={color}
          {...styles}
        />
        <circle className="prefix__cls-1" cx={14.8} cy={31.2} r={10.3} stroke={color} {...styles} />
        <path
          className="prefix__cls-1"
          d="M18.7 31.2h-3.9v-6.5M24.1 18.4l.9.9 2.6-3.5M32.8 18.4l.9.9 2.6-3.5"
          stroke={color}
          {...styles}
        />
      </g>
    ),
    viewBox: '0 0 45 45'
  };
};
