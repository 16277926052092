import React, { useMemo } from 'react';
import { MuiGrid, MuiTypography, MuiButton } from 'theme/material-ui';
import { FontWeight } from 'modules/styles/variables';
import { AuditLogFilterInterface } from 'lib/hooks/AuditLogFilters/useAuditLogFilter';
import { AuditFacet, AuditFilter, AuditLogFacetGroups } from 'store/audit/types';
import { Chip, ChipDeleteIcon } from './styled';

export interface Props {
  filter: AuditLogFilterInterface['filter'];
  options: AuditLogFilterInterface['options'];
}

function AuditLogListFiltersGroup({ options, filter }: Props) {
  const chips = useMemo(() => {
    const result = [];

    if (options.filters) {
      result.push(...options.filters.filter(({ value }) => value));
    }

    if (options.facets) {
      result.push(...options.facets);
    }

    return result;
  }, [options]);

  const onDelete = (
    chip:
      | AuditFilter
      | AuditFacet
      | {
          title: string;
          group: string;
          value: string;
        }
  ) => {
    if (chip.group in AuditLogFacetGroups) {
      filter({
        ...options,
        facets: options.facets?.filter(
          facet => facet.group !== chip.group || facet.value !== chip.value
        )
      });
    }
  };

  return (
    <MuiGrid container direction="row" alignItems="center">
      {chips.length > 0 ? (
        <MuiGrid item>
          <MuiTypography variant="body2" fontWeight={FontWeight.semibold}>
            Filtering by:
          </MuiTypography>
        </MuiGrid>
      ) : null}
      {chips.map(chip => (
        <MuiGrid item key={chip.title}>
          <Chip
            label={chip.title}
            onDelete={() => onDelete(chip)}
            color="primary"
            deleteIcon={<ChipDeleteIcon />}
          />
        </MuiGrid>
      ))}
      {chips.length ? (
        <MuiGrid item>
          <MuiButton
            color="primary"
            onClick={() =>
              filter({
                ...options,
                filters: [],
                facets: []
              })
            }
          >
            Clear all
          </MuiButton>
        </MuiGrid>
      ) : null}
    </MuiGrid>
  );
}

export default AuditLogListFiltersGroup;
