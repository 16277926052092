import React, { useEffect } from 'react';
import { FormikProps } from 'formik';
import { BasicInfoForm } from 'store/billing/financialAssistance/createApplication/types';
import { oc } from 'ts-optchain';
import MaskedInput from 'react-text-mask';
import { Consumer } from 'store/profile/types';
import { BasicInfo, BasicInfoInputType, RefStore } from './types';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { KeyboardDatePicker } from '@material-ui/pickers';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import { convertToLowerKabobCase } from 'modules/utils/StringUtils';
import { dfdDefaultTheme } from 'theme/material-ui';
import useMediaQuery from '@material-ui/core/useMediaQuery';

interface FABasicInfoSectionProps {
  item: BasicInfo;
  formik: FormikProps<BasicInfoForm>;
  prefill: Partial<BasicInfoForm>;
  formRef: React.MutableRefObject<RefStore>;
  profileConsumer: Consumer;
  onFocusNext: (key: number, stall?: number) => Promise<void>;
  expanded: boolean;
}

function TextMaskCustom(props: TextMaskCustomProps) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      guide={false}
    />
  );
}

function CustomSelect({
  key,
  label,
  value,
  onChange,
  items,
  error,
  extraProps
}: {
  key: string;
  label: string;
  value: any;
  onChange: () => void;
  items: any[];
  error?: string;
  extraProps: any;
}) {
  const inputLabel = React.useRef<HTMLLabelElement>(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  useEffect(() => {
    setLabelWidth(inputLabel.current!.offsetWidth);
  }, []);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP
      }
    }
  };

  return (
    <FormControl fullWidth variant="outlined" error={error}>
      <InputLabel id={key} ref={inputLabel} required>
        {label}
      </InputLabel>
      <Select
        {...extraProps}
        labelId={key}
        value={value}
        onChange={onChange}
        MenuProps={MenuProps}
        labelWidth={labelWidth}
        data-testid={convertToLowerKabobCase(label, '-menu')}
      >
        {items &&
          items.length > 0 &&
          items.map(item => (
            <MenuItem
              data-testid={
                item.altLabel
                  ? convertToLowerKabobCase(item.altLabel, '-menu-item')
                  : convertToLowerKabobCase(item.label, '-menu-item')
              }
              value={item.value}
            >
              {item.label}
            </MenuItem>
          ))}
      </Select>
      <FormHelperText>{error}</FormHelperText>
    </FormControl>
  );
}

function FABasicInfoSection(props: FABasicInfoSectionProps) {
  const isSmallScreen = useMediaQuery(dfdDefaultTheme.breakpoints.down('md'));

  const { item, formik, prefill, profileConsumer } = props;
  const { setFieldValue, touched, errors, handleChange, handleBlur, values } = formik;
  const { key, type, props: itemProps } = item;

  // Options: Should Hide | Should Edit Input | Additional Fn onChange/Press
  const edit = oc(item).opts.edit(() => true)(values, prefill);
  const hide = oc(item).opts.hide(() => false)(values);
  const fn = oc(item).opts.fn('');

  // Overrides: itemProps.value | itemProps.options.mask
  const overrideValue = oc(item).override.value(() => undefined)(values);
  const overrideMask = oc(item).override.mask(() => undefined)(values);
  const overrideMaxDate = oc(item).override.maxDate(() => undefined)(values);
  const overrideMinDate = oc(item).override.minDate(() => undefined)(values);

  // Unique Actions
  const onRelationshipChange = (value: null | string) => {
    if (value) {
      setFieldValue('guarantorSSN', '');
      setFieldValue('guarantorFirstName', profileConsumer.firstName);
      if (profileConsumer.middleName) {
        setFieldValue('guarantorMiddle', profileConsumer.middleName[0]);
      }
      setFieldValue('guarantorLastName', profileConsumer.lastName);
      setFieldValue('guarantorBirthDate', profileConsumer.dateOfBirth);

      if (value === 'self') {
        setFieldValue('patientSSN', '');
        setFieldValue('patientFirstName', profileConsumer.firstName);
        if (profileConsumer.middleName) {
          setFieldValue('patientMiddle', profileConsumer.middleName[0]);
        }
        setFieldValue('patientLastName', profileConsumer.lastName);
        setFieldValue('patientBirthDate', profileConsumer.dateOfBirth);
      }
    } else {
      setFieldValue('guarantorSSN', '');
      setFieldValue('guarantorFirstName', '');
      setFieldValue('guarantorMiddle', '');
      setFieldValue('guarantorLastName', '');
      setFieldValue('guarantorBirthDate', '');
    }
  };

  const onAddressLengthChange = (value: null | string) => {
    if (value === '4') {
      const resetFields = [
        'extra1AddressLineOne',
        'extra1AddressLineTwo',
        'extra1AddressCity',
        'extra1AddressZip',
        'extra1AddressState',
        'extra1AddressFrom',
        'extra1AddressTo',
        'extra2AddressLineOne',
        'extra2AddressLineTwo',
        'extra2AddressCity',
        'extra2AddressZip',
        'extra2AddressState',
        'extra2AddressFrom',
        'extra2AddressTo'
      ];
      resetFields.forEach(f => setFieldValue(f, ''));
    }
  };

  // Object key matches with item.opts.fn string to fire unique fn's
  const uniqueFns: { [x: string]: (value: null | string) => void } = {
    onRelationshipChange,
    onAddressLengthChange
  };

  // Gets Formik Error
  const getFormError = (formKey: keyof typeof values) => {
    return touched[formKey] ? errors[formKey] || null : null;
  };

  if (hide) return null;

  if (type === BasicInfoInputType.DEFAULT) {
    const mask = oc(itemProps).options.mask('');
    const customProps = {
      inputProps: {}
    };
    if (overrideMask || mask) {
      customProps.inputComponent = TextMaskCustom as any;
      customProps.inputProps.mask = overrideMask || mask;
    }
    if (item.props.maxLength) {
      customProps.inputProps.maxLength = item.props.maxLength;
    }

    return (
      <Grid
        key={key}
        item
        md={item.span ? item.span : 12}
        sm={12}
        style={{ minWidth: isSmallScreen ? '90%' : undefined }}
      >
        <TextField
          {...itemProps}
          variant="outlined"
          label={itemProps.label}
          value={overrideValue || values[key]}
          onChange={handleChange(key)}
          onBlur={handleBlur(key)}
          error={!!getFormError(key)}
          helperText={getFormError(key)}
          disabled={!edit}
          fullWidth
          InputProps={customProps}
          data-testid={convertToLowerKabobCase(itemProps.label, '-text-field')}
        />
      </Grid>
    );
  }

  if (type === BasicInfoInputType.CALENDAR) {
    return (
      <Grid
        key={key}
        item
        md={item.span ? item.span : 12}
        sm={12}
        style={{ minWidth: isSmallScreen ? '90%' : undefined }}
      >
        <KeyboardDatePicker
          {...itemProps}
          disableToolbar
          variant="inline"
          inputVariant="outlined"
          label={itemProps.label}
          format="MM/DD/YYYY"
          value={values[key] ? values[key] : null}
          onChange={date => setFieldValue(key, date, true)}
          onBlur={handleBlur(key)}
          minDate={overrideMinDate || itemProps.minDate}
          maxDate={overrideMaxDate || itemProps.maxDate}
          disabled={!edit}
          helperText={getFormError(key)}
          error={!!getFormError(key)}
          fullWidth
          data-testid={convertToLowerKabobCase(itemProps.label)}
        />
      </Grid>
    );
  }

  if (type === BasicInfoInputType.SELECT) {
    return (
      <Grid
        key={key}
        item
        md={item.span ? item.span : 12}
        sm={12}
        style={{ minWidth: isSmallScreen ? '90%' : undefined }}
      >
        <CustomSelect
          extraProps={itemProps}
          label={itemProps.label}
          items={itemProps.items}
          onChange={async e => {
            await setFieldValue(key, e.target.value, true);
            if (fn && uniqueFns[fn]) {
              uniqueFns[fn](e.target.value);
            }
          }}
          value={values[key] ? values[key] : ''}
          error={getFormError(key)}
          disabled={!edit}
          data-testid={convertToLowerKabobCase(itemProps.label)}
        />
      </Grid>
    );
  }

  return null;
}

export default FABasicInfoSection;
