import * as React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import { Field } from 'formik';
import { FormControlLabel, FormControl, Box } from '@material-ui/core';

import FieldError from './FieldError';
import useStyles from '../useStyles';

const CustomCheckbox = ({ name, label, disabled }) => {
  const classes = useStyles();

  return (
    <Box>
      <Field name={name}>
        {({ field, meta }) => (
          <>
            <FormControl margin="normal" className={classes.checkbox} disabled={disabled}>
              <FormControlLabel
                control={
                  <Checkbox
                    {...field}
                    color="primary"
                    checked={field.value ? field.value : false}
                  />
                }
                label={label}
              />
            </FormControl>
            <FieldError error={meta.touched && !!meta.error && meta.error} />
          </>
        )}
      </Field>
    </Box>
  );
};

export default CustomCheckbox;
