/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import styled, { css } from 'styled-components';
import { MuiStepper, MuiStepLabel, MuiStepConnector } from 'theme/material-ui';
import { generate } from './utils';

export const Steps = styled(MuiStepper)`
  &.MuiStepper-root {
    padding: 0;
    background-color: transparent;
  }
`;

export interface StepperOptions {
  activeColor?: string;
  completedColor?: string;
  inactiveColor?: string;
}

const line = (props: StepperOptions) => css`
  & .MuiStepConnector-line {
    border-top-width: 2px;
    border-radius: 1px;
    border-color: ${generate.inactiveColor(props.inactiveColor)};
  }

  &.MuiStepConnector-active .MuiStepConnector-line {
    border-color: ${generate.activeColor(props.activeColor)};
  }

  &.MuiStepConnector-completed .MuiStepConnector-line {
    border-color: ${generate.completedColor(props.completedColor)};
  }
`;

const alternativeLabel = css`
  &.MuiStepConnector-alternativeLabel {
    left: calc(-50% + 16px);
    right: calc(50% + 16px);
    top: 9px;
  }
`;

export const Connector = styled(({ inactiveColor, completedColor, activeColor, ...rest }) => (
  <MuiStepConnector {...rest} />
))<StepperOptions>`
  ${alternativeLabel};
  ${line};
`;

interface StepLabelProps extends StepperOptions {
  label?: React.ReactNode;
}

const label = (props: StepLabelProps) => css`
  & .MuiStepLabel-label {
    color: ${generate.inactiveColor(props.inactiveColor)};

    &.MuiStepLabel-alternativeLabel {
      margin-top: ${props.label ? 8 : 0}px;
    }

    &.MuiStepLabel-active {
      color: ${generate.activeColor(props.activeColor)};
    }

    &.MuiStepLabel-completed {
      color: ${generate.completedColor(props.completedColor)};
    }
  }
`;

export const StepLabel = styled(({ inactiveColor, completedColor, activeColor, ...rest }) => (
  <MuiStepLabel {...rest} />
))<StepLabelProps>`
  ${label};
`;
