import { createYears } from '../utils';

interface Item {
  value: number | string;
  label: number | string;
}

export const months: Item[] = [
  {
    label: '01 - January',
    value: '01'
  },
  {
    label: '02 - February',
    value: '02'
  },
  {
    label: '03 - March',
    value: '03'
  },
  {
    label: '04 - April',
    value: '04'
  },
  {
    label: '05 - May',
    value: '5'
  },
  {
    label: '06 - June',
    value: '06'
  },
  {
    label: '07 - July',
    value: '07'
  },
  {
    label: '08 - August',
    value: '08'
  },
  {
    label: '09 - September',
    value: '09'
  },
  {
    label: '10 - October',
    value: '10'
  },
  {
    label: '11 - November',
    value: '11'
  },
  {
    label: '12 - December',
    value: '12'
  }
];

export const years: Item[] = createYears(11).reduce((acc, cur) => {
  acc.push({ label: cur, value: cur });
  return acc;
}, [] as Item[]);
