import React from 'react';

const a = {
  fill: '#231f20'
} as const;

export default {
  svg: (
    <g>
      <rect {...a} x="128.2" y="126.74" width="196.54" height="23.1" rx="0.59" ry="0.59" />
      <rect {...a} x="128.2" y="70.92" width="196.54" height="23.1" rx="0.59" ry="0.59" />
      <rect {...a} x="93.39" y="182.55" width="231.35" height="23.1" rx="0.59" ry="0.59" />
      <path
        {...a}
        d="M118.54,200.07v6.29h-7.31v-6.29c-9.05-1-15.08-5.48-16.13-13.75l8.64-.3c1.22,5,5.41,7.52,11.26,7.52,5.24,0,9.56-2.83,9.56-6.94,0-3.95-2.38-5.88-7.52-7.11l-8.27-2c-7.38-1.67-12.11-5.82-12.18-13-.07-7.86,6.12-13.34,14.9-14.5v-6.8h7.35V150c8.47,1.33,13.58,7.18,13.64,14.09l-8.4.28c-.78-5-3.27-7.9-8.88-7.9s-9.73,2.65-9.73,7.08c0,3.2,1.8,4.77,7.69,6.4l8,2.11c8.68,2.25,12.18,7.52,12.18,14.43C133.37,193.81,127.59,199,118.54,200.07Z"
        transform="translate(-58.2 -52.81)"
      />
      <path
        {...a}
        d="M102.08,52.81v71a55.93,55.93,0,0,0,0,109.23V460.2H429.79V52.81Zm-31,125.63a43.16,43.16,0,1,1,43.16,43.16A43.16,43.16,0,0,1,71.08,178.44ZM405.48,435.89H126.39V233a55.93,55.93,0,0,0,0-109.23V77.13h279.1Z"
        transform="translate(-58.2 -52.81)"
      />
    </g>
  ),
  viewBox: '-60 -105 571.59 607.38'
};
