import React from 'react';
import { Color } from 'modules/styles/colors';

export default ({ color = Color.black }) => ({
  svg: (
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 1C13.4142 1 13.75 1.33579 13.75 1.75V12.25H24.25C24.6642 12.25 25 12.5858 25 13C25 13.4142 24.6642 13.75 24.25 13.75H13.75V24.25C13.75 24.6642 13.4142 25 13 25C12.5858 25 12.25 24.6642 12.25 24.25V13.75H1.75C1.33579 13.75 1 13.4142 1 13C1 12.5858 1.33579 12.25 1.75 12.25H12.25V1.75C12.25 1.33579 12.5858 1 13 1Z"
        fill={color}
        stroke={color}
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  ),
  viewBox: '0 0 26 26'
});
