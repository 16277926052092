import React, { useState } from 'react';
import ResetAccountAccessModal from 'components/ResetAccountAccessModal';
import { Alert } from 'components/Alert';
import {
  DashboardBannerCard,
  DashboardCards
} from 'components/DashboardCarousel/DashboardBannerCard';

export interface Props {
  close: () => void;
  totalCards: number;
}

const DashboardCarouselRefreshUserList = ({ close, totalCards }: Props) => {
  const [isResetAccountModalOpen, setIsResetAccountModalOpen] = useState(false);
  const removeCard = () => {
    close();
  };

  const onClick = () => {
    setIsResetAccountModalOpen(true);
  };

  const handleResetUserAccountClosed = () => {
    setIsResetAccountModalOpen(false);
  };

  const handleResetUserAccountError = () => {
    Alert.alert('Failed to reset account. Please try again later.');
  };

  return (
    <>
      <DashboardBannerCard
        title="Changes to people you have access to are now available"
        actionOne={{
          text: 'View details',
          onClick
        }}
        imageName={DashboardCards.changesToPeople.imageName}
        textColor={DashboardCards.changesToPeople.color}
        background={DashboardCards.changesToPeople.background}
        onClosePress={removeCard}
        totalCards={totalCards}
      />
      <ResetAccountAccessModal
        open={isResetAccountModalOpen}
        onClose={handleResetUserAccountClosed}
        onSuccess={handleResetUserAccountClosed}
        onError={handleResetUserAccountError}
        closeAccountSwitcherModal={handleResetUserAccountClosed}
      />
    </>
  );
};

export default DashboardCarouselRefreshUserList;
