import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTestResultListByEncounter } from 'store/testResults/actions';
import {
  testResultListByEncounterDataSelector,
  testResultListByEncounterIsFetchingSelector,
  testResultListByEncounterErrorSelector
} from 'store/testResults/selectors';

interface GetMedicalTestResultListByEncounterProps {
  encounterId: string;
}

export const useGetMedicalTestResultListByEncounter = (
  props: GetMedicalTestResultListByEncounterProps
) => {
  const { encounterId } = props || {};
  const restData = useSelector(testResultListByEncounterDataSelector);
  const restIsFetching = useSelector(testResultListByEncounterIsFetchingSelector);
  const restError = useSelector(testResultListByEncounterErrorSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (encounterId) {
      dispatch(getTestResultListByEncounter({ encounterId }));
    }
  }, [encounterId]);

  return {
    data: restData,
    dataErrors: [],
    loading: restIsFetching,
    error: restError,
    refetch: () => dispatch(getTestResultListByEncounter({ encounterId }))
  };
};
