import React from 'react';

import { Color } from 'modules/styles/colors';
import { FontSize, FontWeight } from 'modules/styles/variables';
import { providerDetails as amwellProviderDetails } from 'modules/constants/amwell';

import { MuiBox, MuiDivider, MuiGrid, MuiTypography } from 'theme/material-ui';
import { ConnectCareError, ConnectCareDataLoader } from 'components/ConnectCare';

import { ProviderDetails } from 'store/amwell/types';

export interface Props {
  providerDetailsLoading: boolean;
  providerDetailsError: Error | null;
  providerDetails: ProviderDetails | null;
  isWaitingRoom: boolean;
}

export function ProviderProfessionalSummary(props: Props) {
  const { providerDetailsLoading, providerDetailsError, providerDetails } = props;

  const errorComponentProps = {
    message: amwellProviderDetails.PROVIDER_DETAILS_ERROR.title
  };

  return (
    <MuiBox>
      <MuiBox py={3}>
        <MuiBox pb={1}>
          <MuiTypography fontWeight={FontWeight.bold} color={Color.primary} variant="h5">
            Professional Summary
          </MuiTypography>
        </MuiBox>
        <MuiBox>
          <MuiDivider variant="middle" />
        </MuiBox>
      </MuiBox>
      <ConnectCareDataLoader
        data={providerDetails}
        loading={providerDetailsLoading}
        error={providerDetailsError}
        errorComponentProps={errorComponentProps}
        renderNoData={() => (
          <ConnectCareError message={amwellProviderDetails.PROVIDER_DETAILS_NO_DATA.title} />
        )}
        renderData={data => (
          <MuiGrid container spacing={2}>
            <MuiGrid item xs={12} container>
              <MuiGrid item xs container direction="column" spacing={3}>
                {data?.schoolName && data?.graduatingYear ? (
                  <MuiGrid item xs>
                    <MuiTypography
                      fontWeight={FontWeight.semibold}
                      fontSize={FontSize.mediumHeading}
                      color={Color.primary}
                      variant="body1"
                      gutterBottom
                    >
                      Professional Education
                    </MuiTypography>
                    <MuiTypography fontSize={14} color="textPrimary" variant="body1">
                      {data?.schoolName}, {data?.graduatingYear}
                    </MuiTypography>
                  </MuiGrid>
                ) : null}
                {data?.fellowships?.length ? (
                  <MuiGrid item xs>
                    <MuiTypography
                      fontWeight={FontWeight.semibold}
                      fontSize={FontSize.mediumHeading}
                      variant="body1"
                      gutterBottom
                      color={Color.primary}
                    >
                      Fellowship(s)
                    </MuiTypography>
                    <MuiTypography fontSize={14} color="textPrimary" variant="body1">
                      {typeof data.fellowships === 'string'
                        ? data.fellowships
                        : data.fellowships.map(fellowship => fellowship).join(', ')}
                    </MuiTypography>
                  </MuiGrid>
                ) : null}
                {data?.boardCertificates?.length ? (
                  <MuiGrid item xs>
                    <MuiTypography
                      fontWeight={FontWeight.semibold}
                      fontSize={FontSize.mediumHeading}
                      variant="body1"
                      gutterBottom
                      color={Color.primary}
                    >
                      Board Certifications
                    </MuiTypography>
                    <MuiTypography fontSize={14} color="textPrimary" variant="body1">
                      {data.boardCertificates.map(certificate => certificate).join(', ')}
                    </MuiTypography>
                  </MuiGrid>
                ) : null}
                {data?.textGreeting ? (
                  <MuiGrid item xs>
                    <MuiTypography
                      fontWeight={FontWeight.semibold}
                      fontSize={FontSize.mediumHeading}
                      variant="body1"
                      gutterBottom
                      color={Color.primary}
                    >
                      Welcome
                    </MuiTypography>
                    <MuiTypography fontSize={14} color="textPrimary" variant="body1">
                      {data.textGreeting}
                    </MuiTypography>
                  </MuiGrid>
                ) : (
                  <MuiTypography>N/A</MuiTypography>
                )}
              </MuiGrid>
            </MuiGrid>
            <MuiGrid item xs={12} container>
              <MuiGrid item xs container direction="column" spacing={3}>
                {data?.yearsExperience ? (
                  <MuiGrid item>
                    <MuiTypography
                      fontWeight={FontWeight.semibold}
                      fontSize={FontSize.mediumHeading}
                      variant="body1"
                      gutterBottom
                      color={Color.primary}
                    >
                      Years Experience
                    </MuiTypography>
                    <MuiTypography fontSize={14} color="textPrimary" variant="body1">
                      {data.yearsExperience}
                    </MuiTypography>
                  </MuiGrid>
                ) : null}
                {data?.residency ? (
                  <MuiGrid item>
                    <MuiTypography
                      fontWeight={FontWeight.semibold}
                      fontSize={FontSize.mediumHeading}
                      variant="body1"
                      gutterBottom
                      color={Color.primary}
                    >
                      Residency
                    </MuiTypography>
                    <MuiTypography fontSize={14} color="textPrimary" variant="body1">
                      {data.residency}
                    </MuiTypography>
                  </MuiGrid>
                ) : null}
              </MuiGrid>
            </MuiGrid>
          </MuiGrid>
        )}
      />
    </MuiBox>
  );
}

ProviderProfessionalSummary.defaultProps = {
  isWaitingRoom: true
};

export default ProviderProfessionalSummary;
