import styled from 'styled-components';

import { headerHeight, sideBarWidthCollapsed } from 'components/AuthLayout/styled';
import { Spacing } from 'modules/styles/variables';
import { MuiDivider, MuiList, MuiTypography } from 'theme/material-ui';

export const FullWidthList = styled(MuiList)`
  &&& {
    position: relative;
    padding: ${Spacing.largeXLarge}px 0px ${Spacing.largeXLarge}px;
    width: ${sideBarWidthCollapsed}px;
    height: calc(100% - ${headerHeight}px);
    margin-top: ${headerHeight}px;
    display: flex;
    flex-direction: column;
    flex: 1 0 0%;
    transition: all 250ms linear;
  }
`;

export const StyledMuiDivider = styled(MuiDivider)`
  &&& {
    margin: 24px ${Spacing.medium}px;
  }
`;

export const StyledMuiTypography = styled(MuiTypography)`
  position: relative;
  flex: 1 1 auto;
  overflow: auto;
  padding: 0 0 ${Spacing.medium}px ${Spacing.medium}px;
`;
