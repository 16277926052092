import React from 'react';

import Svg from 'components/UI/Svg/Svg';
import Box from 'components/UI/Layout/Box';
import FlexBox from 'components/UI/Layout/FlexBox';

import { Color } from 'modules/styles/colors';
import { Spacing, FontSize, IconSize } from 'modules/styles/variables';
import { convertToLowerKabobCase } from 'modules/utils/StringUtils';
import Typography from 'components/UI/Typography';
import { StyledHeadline } from './styled';

interface Props {
  title: string;
  subtitle?: string;
  handleInfoPress?: Function;
}

const Header = ({ title, subtitle, handleInfoPress }: Props) => (
  <FlexBox
    flexDirection="row"
    justifyContent="flex-start"
    alignItems="flex-start"
    vSpacingTop={Spacing.large}
    data-testid={convertToLowerKabobCase(title)}
  >
    <Box>
      <Svg
        name="MedicationListIcon"
        stroke={Color.grayHue7}
        height="106"
        width="119"
        color={Color.grayHue7}
      />
    </Box>
    <Box>
      <StyledHeadline numberOfLines={1}>{title}</StyledHeadline>
      <Typography fontSize={FontSize.large}>{subtitle}</Typography>
      <FlexBox flexDirection="row" hSpacingLeft={Spacing.small}>
        {handleInfoPress ? (
          <FlexBox
            vSpacing={Spacing.medium}
            style={{ cursor: 'pointer' }}
            flexDirection="row"
            onClick={handleInfoPress}
          >
            <Svg set="assets" name="InfoIcon" size={IconSize.small} color={Color.secondary} />
            <Typography style={{ paddingLeft: '5px', lineHeight: '1.1' }} color={Color.link}>
              More Info
            </Typography>
          </FlexBox>
        ) : null}
      </FlexBox>
    </Box>
  </FlexBox>
);

export default Header;
