import styled from 'styled-components';
import { Color } from 'modules/styles/colors';
import DefaultText from 'components/UI/Typography/DefaultText';
import { MuiBox } from 'theme/material-ui';

export const Message = styled.div`
  padding: 24px;
  margin: 24px;
`;

export const MessageText = styled(DefaultText)`
  display: block;
  text-align: center;
  color: ${Color.foreColor};
`;

export const CirclesContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 12px 8px;
`;

export const BloodPressureHeader = styled.div`
  display: flex;
  flex-direction: row;
`;

export const BloodPressureContainer = styled(MuiBox)`
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  @media only screen and (max-width: 1250px) {
    flex-direction: column;
  }
`;
