import React, { ComponentType, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import { MuiBox, MuiContainer, MuiTypography } from 'theme/material-ui';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Spacer from 'components/UI/Layout/Spacer';
import Footer from 'components/common/Footer';
import Spinner from 'components/UI/Spinner/Spinner';
import { FontWeight } from 'modules/styles/variables';
import { patientSelect } from 'modules/constants/covidScreening';
import { RootState } from 'store/types';
import { Questionnaire, InsuranceCardData, InsuranceList } from 'store/CovidScreening/types';
import * as covidActions from 'store/CovidScreening/actions';
import {
  CovidScreeningQuestionnaireSelector,
  CovidScreeningInsuranceListSelector
} from 'store/CovidScreening/selectors';
import VirtualizedAutoComplete from '../Components/VirtualizedAutoComplete';
import { CovidTestButton } from '../Components/CovidTestButton';
import useStyles from '../Components/CovidTestButton/useStyles';
import { CovidTitles } from 'lib/constants/covidScreening';
import { AnalyticsEvent } from 'services/AnalyticsService';
import useNavigationAnalytics from 'hooks/useNavigationAnalytics';

interface Props extends RouteComponentProps {
  questionnaire: Questionnaire;
  insuranceList: InsuranceList;
  setQuestionnaire: typeof covidActions.setQuestionnaire;
  getInsuranceList: typeof covidActions.getInsuranceList;
}

export function CovidInsuranceSelect({
  history,
  questionnaire,
  setQuestionnaire,
  insuranceList,
  getInsuranceList
}: Props) {
  useEffect(() => {
    getInsuranceList();
  }, []);
  const { isFetching = false, insuranceData } = insuranceList;
  const classes = useStyles();
  const [insuranceCannotFind, setInsuranceCannotFind] = useState(false);
  const [insuranceSelected, setInsuranceSelected] = useState<InsuranceCardData | null>(null);
  const [insuranceSearch, setInsuranceSearch] = useState<string>(
    questionnaire?.Health_Plan_Name || ''
  );

  const handleInsurancePress = (value: InsuranceCardData) => {
    setInsuranceSelected(value);
    setInsuranceSearch(value?.plan_name);
    setInsuranceCannotFind(false);
  };

  const cleanStatesData = (insuranceCannotFind: boolean) => {
    setInsuranceCannotFind(insuranceCannotFind);
    setInsuranceSelected(null);
    setInsuranceSearch('');
  };

  const saveToStore = () => {
    if (insuranceCannotFind) {
      const questionnaireObject = {
        ...questionnaire,
        Health_Plan_ID: '',
        Health_Plan_Name: '',
        Health_Plan_Address_line_1: '',
        HealthPlan_City: '',
        HealthPlan_state: '',
        HealthPlan_zip_code: '',
        HEALTH_PLAN_COUNTRY: '',
        Insurance_Object: '',
        Insurance_Not_Found: true
      };
      setQuestionnaire(questionnaireObject);
    } else {
      const questionnaireObject = {
        ...questionnaire,
        Health_Plan_ID: insuranceSelected?.health_plan_id ?? '',
        Health_Plan_Name: insuranceSelected?.plan_name ?? '',
        Health_Plan_Address_line_1: insuranceSelected?.street_address ?? '',
        HealthPlan_City: insuranceSelected?.city ?? '',
        HealthPlan_state: insuranceSelected?.state ?? '',
        HealthPlan_zip_code: insuranceSelected?.zipcode ?? '',
        HEALTH_PLAN_COUNTRY: insuranceSelected?.country ?? ''
      };
      setQuestionnaire(questionnaireObject);
    }
  };

  const covidScreeningNextBtn = useNavigationAnalytics(AnalyticsEvent.CovidNext, {
    title: CovidTitles.EXISTING_INSURANCE_SEARCH,
    can_not_find_insurance: 'yes'
  });

  const covidInsuranceNotFoundNextBtn = useNavigationAnalytics(AnalyticsEvent.CovidNext, {
    title: CovidTitles.EXISTING_INSURANCE_SEARCH
  });

  const covidScreeningPreviousBtn = useNavigationAnalytics(AnalyticsEvent.CovidPrev, {
    title: CovidTitles.EXISTING_INSURANCE_SEARCH
  });

  const handleSubmit = () => {
    saveToStore();
    if (insuranceCannotFind) {
      covidScreeningNextBtn();
      history.push('/u/get-care-now/covid-screen/insurance-information');
    } else {
      covidInsuranceNotFoundNextBtn();
      history.push('/u/get-care-now/covid-screen/new-insurance-found');
    }
  };

  const onCancelClick = () => {
    saveToStore();
    covidScreeningPreviousBtn();
    history.goBack();
  };

  return (
    <MuiContainer maxWidth="lg">
      <MuiBox p={5}>
        <Spacer size="xLarge" />
        <MuiTypography fontWeight={FontWeight.bold}>
          Please search for your health insurance by name or address:
        </MuiTypography>
        <Spacer size="small" />
        <MuiTypography>
          Insurance information is needed to bill for COVID-19 tests. Copayments and deductibles may
          apply based on insurance plan.
        </MuiTypography>
        <Spacer size="small" />
        <MuiTypography>
          If additional insurance verification is needed, you may receive a follow-up call.
        </MuiTypography>
        <Spacer size="small" />
        {isFetching ? (
          <Spinner />
        ) : (
          <VirtualizedAutoComplete
            InsuranceList={insuranceData}
            handleInsurancePress={handleInsurancePress}
            valueSelected={insuranceSearch}
            cleanStatesData={cleanStatesData}
          />
        )}

        <Spacer />
        <MuiBox flexDirection="row" alignItems="center">
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                data-testid="insurance-select-check-box"
                checked={insuranceCannotFind}
                onChange={() => {
                  setInsuranceCannotFind(!insuranceCannotFind);
                  setInsuranceSelected(null);
                  setInsuranceSearch('');
                }}
              />
            }
            label="I can’t find my insurance in above search."
          />
        </MuiBox>
      </MuiBox>
      <MuiBox className={classes.steppersFooter}>
        <CovidTestButton
          fullWidth
          variant="contained"
          data-testid="insurance-select-next-button"
          onClick={handleSubmit}
          disabled={!insuranceSelected && !insuranceCannotFind}
        >
          Next
        </CovidTestButton>
        <CovidTestButton
          fullWidth
          variant="text"
          data-testid="insurance-select-previous-button"
          onClick={onCancelClick}
        >
          Previous
        </CovidTestButton>
      </MuiBox>
      <Spacer spacing="small" />
      <Footer
        headerText={patientSelect.GET_PATIENTS.HELPLINE.CovidInfo}
        link={patientSelect.GET_PATIENTS.HELPLINE.CovidPatientSelectHelpline}
        linkName={patientSelect.GET_PATIENTS.HELPLINE.CovidPatientSelectHelpline}
        helplineHours={patientSelect.GET_PATIENTS.HELPLINE.CovidHelplineHours}
        title={CovidTitles.EXISTING_INSURANCE_SEARCH}
      />
    </MuiContainer>
  );
}

const mapStateToProps = (state: RootState) => ({
  questionnaire: CovidScreeningQuestionnaireSelector(state),
  insuranceList: CovidScreeningInsuranceListSelector(state)
});

const mapDispatchToProps = {
  setQuestionnaire: covidActions.setQuestionnaire,
  getInsuranceList: covidActions.getInsuranceList
};

export default connect(mapStateToProps, mapDispatchToProps)(CovidInsuranceSelect as ComponentType);
