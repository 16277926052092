import React from 'react';

import { ConnectCareButton } from 'components/ConnectCare';

import {
  MuiDialog,
  MuiDialogTitle,
  MuiDialogContent,
  MuiDialogActions,
  MuiTypography,
  MuiBox
} from 'theme/material-ui';

export interface Props {
  isCancelVisitDialogVisible: boolean;
  handleClose: () => void;
  onVisitCancel: () => void;
}

function ConnectCareWaitingRoomCancelVisitDialog(props: Props) {
  const { onVisitCancel, isCancelVisitDialogVisible, handleClose } = props;

  return (
    <MuiDialog
      open={isCancelVisitDialogVisible}
      onClose={handleClose}
      maxWidth="md"
      aria-labelledby="connect-care-waiting-room-text-me-dialog-title"
    >
      <MuiDialogTitle id="connect-care-waiting-room-text-me-dialog-title">
        Cancel Visit
      </MuiDialogTitle>
      <MuiDialogContent dividers>
        <MuiBox p={1}>
          <MuiTypography>Are you sure you want to cancel your visit?</MuiTypography>
        </MuiBox>
      </MuiDialogContent>
      <MuiDialogActions>
        <ConnectCareButton variant="text" data-testid="cancel-visit-No" onClick={handleClose}>
          No
        </ConnectCareButton>
        <ConnectCareButton data-testid="cancel-visit-Yes" onClick={onVisitCancel}>
          Yes
        </ConnectCareButton>
      </MuiDialogActions>
    </MuiDialog>
  );
}

export default ConnectCareWaitingRoomCancelVisitDialog;
