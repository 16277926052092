import React from 'react';
import dayjs from 'dayjs';
import { Grid } from '@material-ui/core';
import { Formik } from 'formik';
import { KeyboardDatePicker } from '@material-ui/pickers';

import Spacer from 'components/UI/Layout/Spacer';
import RequiredLabel from 'components/UI/Labels/RequiredLabel';
import FlexBox from 'components/UI/Layout/FlexBox';
import SmallText from 'components/UI/Typography/SmallText';
import { MuiBox, MuiMenuItem } from 'theme/material-ui';
import { useLanguageSwitcher } from 'lib/hooks/useLanguageSwitcher';
import {
  CovidTestGuestFooter_translations,
  ButtonsNavigation_translations,
  genderTranslations
} from 'lib/constants/translations/components/index';
import { translations } from 'lib/constants/translations/screens/covidGuest/subscriberInfo';

import {
  SEX_OPTIONS_TRANSLATED,
  SexOptionsType,
  StateOptionsType,
  submitSubscriberInfoValidationGuest
} from 'modules/constants/covidScreening/personalInfo';
import { LOCATION_STATES } from 'modules/constants/LocationStates';

import { InfoTooltip } from 'components/InfoTooltip';
import CustomTextField from 'screens/Register/FormElements/CustomTextField';
import FieldError from 'screens/Register/FormElements/FieldError';
import CovidTestButton from 'components/CovidTest/insurance/form/CovidTestButton';
import Footer from 'components/common/Footer';
import { convertToLowerKabobCase } from 'modules/utils/StringUtils';
import { StyledSelect } from './styled';
import { InsuranceFormButtons } from 'components/CovidTest/insurance/form/styled';
import { CovidTitles } from 'lib/constants/covidScreening';

interface SubscriberInforamtion {
  subscriberFirstName: string;
  subscriberMiddleName: string;
  subscriberLastName: string;
  subscriberDateOfBirth: string;
  subscriberSex: string;
  subscriberAddressLine1: string;
  subscriberAddressLine2: string;
  subscriberCity: string;
  subscriberState: string;
  subscriberZip: string;
  subscriberEmail: string;
  subscriberPhone: string;
}

interface SusbscriberInformationFormProps {
  initialValues?: SubscriberInforamtion;
  onClickNext: (values) => void;
  onClickPrevious: (values) => void;
}

const checkDate = values => {
  const dateValidator = dayjs();

  return !dateValidator.isValid(values.symptomsBeganDate) || values.symptomsBeganDate > new Date();
};

export default function SusbscriberInformationForm({
  initialValues,
  onClickNext,
  onClickPrevious
}: SusbscriberInformationFormProps) {
  const screenText = useLanguageSwitcher({
    en: {
      ...translations.en,
      ...CovidTestGuestFooter_translations.en,
      ...genderTranslations.en,
      ...ButtonsNavigation_translations.en
    },
    es: {
      ...translations.es,
      ...CovidTestGuestFooter_translations.es,
      ...genderTranslations.es,
      ...ButtonsNavigation_translations.es
    }
  });

  return (
    <>
      <Spacer size="xsmall" />
      <Formik
        enableReinitialize
        validateOnMount
        validationSchema={submitSubscriberInfoValidationGuest(screenText)}
        onSubmit={values => onClickNext(values)}
        {...(initialValues ? { initialValues } : {})}
      >
        {({
          values,
          isValid,
          touched,
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldTouched,
          setFieldValue
        }) => {
          return (
            <>
              <Grid container spacing={2} data-testid="covid-subscriber-information-form">
                <Grid item xs={12} md={6}>
                  <RequiredLabel labelText={screenText.label_first_name} />
                  <CustomTextField
                    name="subscriberFirstName"
                    variant="outlined"
                    value={values?.subscriberFirstName}
                    data-testid="subscriber-info-first-name"
                    onChange={handleChange('subscriberFirstName')}
                    required
                    placeholder={screenText.placeholder_first_name}
                    maxLength={26}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <RequiredLabel hideIndicator labelText={screenText.label_middle_name} />
                  <CustomTextField
                    name="subscriberMiddleName"
                    variant="outlined"
                    value={values?.subscriberMiddleName}
                    data-testid="subscriber-info-middle-name"
                    onChange={handleChange('subscriberMiddleName')}
                    placeholder={screenText.placeholder_middle_name}
                    maxLength={16}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <RequiredLabel labelText={screenText.label_last_name} />
                  <CustomTextField
                    name="subscriberLastName"
                    variant="outlined"
                    value={values?.subscriberLastName}
                    data-testid="subscriber-info-last-name"
                    onChange={handleChange('subscriberLastName')}
                    required
                    placeholder={screenText.placeholder_last_name}
                    maxLength={26}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <RequiredLabel labelText={screenText.label_birthdate} />
                  <KeyboardDatePicker
                    disableToolbar
                    openTo="year"
                    views={['year', 'month', 'date']}
                    variant="inline"
                    inputVariant="outlined"
                    format="MM/DD/YYYY"
                    value={values?.subscriberDateOfBirth || null}
                    onChange={value => {
                      setFieldValue('subscriberDateOfBirth', value, true);
                    }}
                    onBlur={handleBlur('subscriberDateOfBirth')}
                    maxDate={new Date()}
                    name="subscriberDateOfBirth"
                    data-testid="subscriber-date-of-birth"
                    placeholder={screenText.placeholder_birthdate}
                    required
                    autoOk
                    minDateMessage="Please enter a valid date"
                    maxDateMessage="Please enter a valid date"
                    helperText={null}
                    error={touched.subscriberDateOfBirth && errors.subscriberDateOfBirth}
                  />
                  {touched.subscriberDateOfBirth && errors.subscriberDateOfBirth && (
                    <FieldError error={errors.subscriberDateOfBirth} />
                  )}
                </Grid>

                <Grid item xs={12} md={6}>
                  <FlexBox width="100%">
                    <FlexBox flexDirection="row" justifyContent="flex-start" alignItems="center">
                      <RequiredLabel labelText={screenText.label_sex} />
                      <MuiBox display="flex" justifyContent="flex-end">
                        <InfoTooltip>{screenText.tooltip}</InfoTooltip>
                      </MuiBox>
                    </FlexBox>

                    <StyledSelect
                      value={values.subscriberSex}
                      onChange={handleChange('subscriberSex')}
                      data-testid="subscriber-sex"
                      variant="outlined"
                    >
                      {SEX_OPTIONS_TRANSLATED(screenText).map((option: SexOptionsType) => {
                        return (
                          <MuiMenuItem
                            style={{ whiteSpace: 'normal' }}
                            data-testid={convertToLowerKabobCase(option.value, '-subscriber-sex')}
                            key={option.label}
                            value={option.value}
                          >
                            {option.label}
                          </MuiMenuItem>
                        );
                      })}
                    </StyledSelect>
                  </FlexBox>
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <RequiredLabel labelText={screenText.label_address_1} />
                  <CustomTextField
                    name="subscriberAddressLine1"
                    variant="outlined"
                    value={values?.subscriberAddressLine1}
                    onChange={handleChange('subscriberAddressLine1')}
                    data-testid="subscriber-address-line1"
                    onBlur={() => {
                      setFieldTouched('subscriberAddressLine1');
                    }}
                    placeholder={screenText.placeholder_address_1}
                    required
                    maxLength={30}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <RequiredLabel hideIndicator labelText={screenText.label_address_2} />
                  <CustomTextField
                    name="subscriberAddressLine2"
                    variant="outlined"
                    value={values?.subscriberAddressLine2}
                    onChange={handleChange('subscriberAddressLine2')}
                    data-testid="subscriber-address-line2"
                    maxLength={30}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <RequiredLabel labelText={screenText.label_city} />
                  <CustomTextField
                    name="subscriberCity"
                    variant="outlined"
                    value={values?.subscriberCity}
                    onChange={handleChange('subscriberCity')}
                    data-testid="subscriber-city"
                    onBlur={() => {
                      setFieldTouched('subscriberCity');
                    }}
                    placeholder={screenText.placeholder_city}
                    required
                    maxLength={34}
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <FlexBox width="100%">
                    <RequiredLabel labelText={screenText.label_state} />
                    <StyledSelect
                      variant="outlined"
                      value={values?.subscriberState}
                      onChange={handleChange('subscriberState')}
                      data-testid="subscriber-state"
                    >
                      {LOCATION_STATES.map((option: StateOptionsType) => (
                        <MuiMenuItem
                          style={{ whiteSpace: 'normal' }}
                          data-testid={convertToLowerKabobCase(option.value, '-subscriber-state')}
                          key={option.label}
                          value={option.value}
                        >
                          {option.label}
                        </MuiMenuItem>
                      ))}
                    </StyledSelect>
                    {errors?.subscriberState && touched.subscriberState ? (
                      <SmallText color="red">{errors?.subscriberState}</SmallText>
                    ) : null}
                  </FlexBox>
                </Grid>

                <Grid item xs={12} md={3}>
                  <RequiredLabel labelText={screenText.label_zip} />
                  <CustomTextField
                    name="subscriberZip"
                    data-testid="subscriber-zip"
                    variant="outlined"
                    value={values?.subscriberZip}
                    onChange={handleChange('subscriberZip')}
                    mask="zip"
                    placeholder={screenText.placeholder_zip}
                    required
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <RequiredLabel labelText={screenText.label_email} />
                  <CustomTextField
                    name="subscriberEmail"
                    variant="outlined"
                    value={values?.subscriberEmail}
                    onChange={handleChange('subscriberEmail')}
                    data-testid="subscriber-email"
                    onBlur={() => {
                      setFieldTouched('subscriberEmail');
                    }}
                    placeholder={screenText.placeholder_email}
                    required
                    maxLength={30}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <RequiredLabel labelText={screenText.label_phone} />
                  <CustomTextField
                    name="subscriberCellNumber"
                    variant="outlined"
                    value={values?.subscriberCellNumber}
                    onChange={handleChange('subscriberCellNumber')}
                    data-testid="subscriber-number"
                    onBlur={() => {
                      setFieldTouched('subscriberCellNumber');
                    }}
                    placeholder={screenText.placeholder_phone}
                    required
                    mask="phone"
                  />
                </Grid>
              </Grid>

              <Spacer size="medium" />

              <InsuranceFormButtons>
                <CovidTestButton
                  fullWidth
                  disabled={checkDate(values) || !isValid}
                  data-testid="subscriber-info-next-btn"
                  color="primary"
                  variant="contained"
                  onClick={() => handleSubmit()}
                >
                  {screenText.next}
                </CovidTestButton>
                <Spacer size="xsmall" />
                <CovidTestButton
                  variant="text"
                  data-testid="subscriber-info-previous-btn"
                  fullWidth
                  onClick={() => onClickPrevious(values)}
                >
                  {screenText.previous}
                </CovidTestButton>
              </InsuranceFormButtons>
              <Footer
                headerText={screenText.covidInfo}
                link={screenText.CovidPatientSelectHelpline}
                linkName={screenText.CovidPatientSelectHelpline}
                helplineHours={screenText.CovidHelplineHours}
                title={CovidTitles.SUBSCRIBER_INFO}
              />
            </>
          );
        }}
      </Formik>
    </>
  );
}
