import { FloatableSection } from 'components/common/FloatableSection';
import Avatar from 'components/UI/Avatar/Avatar';
import Divider from 'components/UI/Divider';
import Box from 'components/UI/Layout/Box';
import FlexBox from 'components/UI/Layout/FlexBox';
import ScrollableLayout from 'components/UI/Layout/ScrollableLayout';
import Spacer from 'components/UI/Layout/Spacer';
import { ListItem } from 'components/UI/ListItem/ListItem';
import Svg from 'components/UI/Svg/Svg';
import Typography from 'components/UI/Typography';
import dayjs from 'dayjs';
import { Color } from 'modules/styles/colors';
import { FontSize, Spacing } from 'modules/styles/variables';
import React from 'react';
import { FlatList } from 'react-native';
import { connect } from 'react-redux';
import { NavigationScreenProps } from 'screens/navigation';
import analyticsService, { AnalyticsEvent } from 'services/AnalyticsService';
import { setProfileActiveInsurance } from 'store/profile/actions';
import {
  profileInsurancesSelector,
  profileConsumerDisplayNameSelector
} from 'store/profile/selectors';
import { Insurance as InsuranceType } from 'store/profile/types';
import { RootState } from 'store/types';
import Banner from 'components/UI/Banner/Banner';

interface Props extends NavigationScreenProps {
  insurances: InsuranceType[];
  setActiveInsurance: (insurance: InsuranceType) => void;
  userName: string;
}

const Insurance = (props: Props) => {
  const shInsurances: InsuranceType[] = props.insurances.filter(
    insurance => insurance.source === 'Facets'
  );
  const nonShInsurances: InsuranceType[] = props.insurances.filter(
    insurance => insurance.source !== 'Facets'
  );

  const navigateToInsuranceDetail = (item: InsuranceType) => {
    analyticsService.logEvent(AnalyticsEvent.InsuranceCardClicked);
    props.setActiveInsurance(item);
    props.history.push('/u/manage-account/insurance-details');
  };

  return (
    <>
      <Banner message="Insurance" />
      <Box flex={1}>
        <ScrollableLayout>
          <FloatableSection>
            <FlexBox
              backgroundColor={Color.foreColor}
              spacing={Spacing.medium}
              alignItems="center"
              justifyContent="center"
            >
              <Spacer size="small" />
              <Avatar accessibilityLabel="user icon" size="small" imageUrl="" />
              <Typography
                accessibilityLabel="user name"
                lineHeight={FontSize.title}
                fontSize={FontSize.large + 3}
              >
                {props.userName ? props.userName : 'No User Name'}
              </Typography>
              <Typography accessibilityLabel="family tag">and family</Typography>
            </FlexBox>
            <Divider />

            <Spacer size="small" />
            {Array.isArray(props.insurances) && props.insurances.length > 0 ? (
              <>
                {shInsurances.length > 0 ? (
                  <Box backgroundColor={Color.foreColor}>
                    <FlexBox
                      justifyContent="center"
                      alignItems="center"
                      flex={0}
                      flexDirection="row"
                      spacing={Spacing.small}
                      backgroundColor={Color.primary}
                    >
                      <Svg fill={Color.white} name="SelectHealth" height="20" width="20" />

                      <Typography lineHeight={20} color={Color.white}>
                        {' '}
                        SelectHealth
                      </Typography>
                    </FlexBox>
                    <FlatList
                      ItemSeparatorComponent={() => <Divider accessibilityLabel="divider" />}
                      data={shInsurances}
                      keyExtractor={item => item.sequence.toString()}
                      renderItem={({ item, index }) => (
                        <ListItem
                          testID={`shInsurance-${index}`}
                          compact
                          title={item.insuranceName}
                          body={`${item.members ? item.members.length : 0} Covered Members`}
                          secondaryBody={
                            <>
                              <Typography>
                                Status:{' '}
                                {dayjs(item.endDate).isBefore(dayjs())
                                  ? 'Expired'
                                  : dayjs(item.startDate).isAfter(dayjs())
                                  ? 'Future'
                                  : 'Active'}
                              </Typography>

                              <Typography>
                                Start Date: {dayjs(item.startDate).format('MM/DD/YYYY')}
                              </Typography>
                              <Typography>
                                End Date: {dayjs(item.endDate).format('MM/DD/YYYY')}
                              </Typography>
                            </>
                          }
                          action={
                            <Svg name="keyboard-arrow-right" set="material" fill={Color.black} />
                          }
                          onPress={() => navigateToInsuranceDetail(item)}
                        />
                      )}
                    />
                  </Box>
                ) : null}
                <Spacer size="small" />
                {nonShInsurances.length > 0 ? (
                  <Box backgroundColor={Color.foreColor}>
                    <FlexBox
                      justifyContent="center"
                      alignItems="center"
                      flex={0}
                      spacing={Spacing.small}
                      backgroundColor={Color.primary}
                    >
                      <Typography color={Color.white}>
                        Insurances on File with Intermountain
                      </Typography>
                    </FlexBox>
                    <FlatList
                      ItemSeparatorComponent={() => <Divider accessibilityLabel="divider" />}
                      data={nonShInsurances}
                      keyExtractor={item => item.sequence.toString()}
                      renderItem={({ item, index }) => (
                        <ListItem
                          testID={`nonInsurance-${index}`}
                          title={item.insuranceName}
                          body={`${item.members ? item.members.length : 0} Covered Members`}
                          icon="ChevronRight"
                          onPress={() => navigateToInsuranceDetail(item)}
                        />
                      )}
                    />
                  </Box>
                ) : null}
              </>
            ) : (
              <FlexBox
                flex={1}
                justifyContent="center"
                alignItems="center"
                vSpacing={Spacing.xLarge}
              >
                <Typography>There is no insurance information available</Typography>
              </FlexBox>
            )}
          </FloatableSection>
        </ScrollableLayout>
      </Box>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  insurances: profileInsurancesSelector(state),
  userName: profileConsumerDisplayNameSelector(state)
});

const mapDispatchToProps = (dispatch: Function) => ({
  setActiveInsurance: (insurance: InsuranceType) => dispatch(setProfileActiveInsurance(insurance))
});

export default connect(mapStateToProps, mapDispatchToProps)(Insurance);
