import React from 'react';
import { MuiTypography, MuiBox } from 'theme/material-ui';
import { Rating } from '@material-ui/lab';
import { StarBorder as StarBorderIcon } from '@material-ui/icons/';

import { makeStyles } from '@material-ui/core/styles';

import { FontSize, Spacing } from 'modules/styles/variables';
import { Color } from 'modules/styles/colors';

const useStyles = makeStyles({
  starSize: {
    fontSize: (props: { starSize: number }) => props?.starSize ?? FontSize.xlarge
  }
});

export interface ConnectCareFormRatingProps {
  emptyIcon?: React.ReactNode;
  label?: React.ReactNode;
  labelColor?: string;
  labelFontSize?: FontSize;
  marginBottom?: typeof Spacing;
  max: number;
  name: string;
  starSize?: FontSize;
  step?: number;
  value: number;
  setValue: (e: React.ChangeEvent<{}>, newValue: number | null) => void;
}

export function ConnectCareFormRatingStars({
  emptyIcon,
  label,
  labelColor,
  labelFontSize,
  marginBottom,
  max,
  name,
  starSize,
  step,
  value,
  setValue
}: ConnectCareFormRatingProps) {
  const classes = useStyles({ starSize });

  return (
    <>
      {label && (
        <MuiBox marginBottom={marginBottom}>
          <MuiTypography color={labelColor} component="legend" fontSize={labelFontSize}>
            {label}
          </MuiTypography>
        </MuiBox>
      )}
      <Rating
        classes={{ root: classes.starSize }}
        name={name}
        value={value}
        onChange={setValue}
        max={max}
        precision={step}
        emptyIcon={emptyIcon}
      />
    </>
  );
}

ConnectCareFormRatingStars.defaultProps = {
  emptyIcon: <StarBorderIcon fontSize="inherit" />,
  labelColor: Color.textLight,
  labelFontSize: FontSize.large,
  marginBottom: Spacing.xSmall,
  max: 5,
  starSize: FontSize.largeTitle,
  step: 1
};
