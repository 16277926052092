import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useProxySwitcherEffect } from 'hooks/useProxySwitcherEffect';
import {
  downloadImmunizations,
  fetchImmunizations,
  replyToImmunizations,
  fetchHasUSIR
} from 'store/medicalHistory/immunizations/actions';
import {
  immunizationsDataSelector,
  immunizationsDownloadingSelector,
  immunizationsErrorSelector,
  immunizationsLoadingSelector,
  hasUsirSelector,
  hasUsirLoadingSelector,
  hasUsirErrorSelector,
  immunizationsDownloadErrorSelector
} from 'store/medicalHistory/immunizations/selectors';
import { Immunization } from 'store/medicalHistory/types';
import { RootDispatch, RootState } from 'store/types';
import { MuiContainer, MuiBox, MuiPaper, MuiGrid, MuiButton } from 'theme/material-ui';
import warning from 'assets/warning.png';
import styled from 'styled-components';
import Banner from 'screens/MedicalHistory/Banner';
import { MedicalHistoryListItem } from 'components/medicalHistory/MedicalHistoryListItem';
import Svg from 'components/UI/Svg/Svg';
import { Color } from 'modules/styles/colors';
import { IconSize, FontSize } from 'modules/styles/variables';
import { formatDate } from 'modules/utils/DateUtils';
import Typography from 'components/UI/Typography';
import capitalize from 'lodash/capitalize';
import {
  HEALTH_RECORDS_PAGES,
  NO_USIR_MESSAGE,
  USIR_ERROR_MESSAGE
} from 'lib/constants/healthRecords';
import analyticsService, { AmplitudeEventData, AnalyticsEvent } from 'services/AnalyticsService';
import {
  currentLocationPathNameSelector,
  previousLocationPathNameSelector
} from 'store/router/selectors';
import { isImpersonatingSelector } from 'store/account/selectors';
import Spacer from 'components/UI/Layout/Spacer';
import FlexBox, { FlexBoxRow } from 'components/UI/Layout/FlexBox';
import { MedicalHistoryMessage } from '../common';
import { useHistory } from 'react-router';
import DownloadErrorSnackbar from 'components/DownloadErrorSnackbar/DownloadErrorSnackbar';

export interface Props {
  error: null | Error;
  loading: boolean;
  immunizations: Immunization[];
  dispatch: RootDispatch;
  isDownloadingImmunizations: boolean;
  downloadError: string | null;
  currentUrl: string;
  referringUrl: string;
  hasUsir: boolean;
  hasUsirLoading: boolean;
  hasUsirError: null | boolean;
  isProxyUser: boolean;
}

interface UsirButtonProps {
  ['data-testid']: string;
  currentUrl: string;
  referringUrl: string;
  isProxyUser: boolean;
  loading: boolean;
}

const orEmpty = (str: string | undefined, empty = 'N/A') => str || empty;

const WarningIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 10px;
  margin-top: 3px;
`;

const UsirButton = (props: UsirButtonProps) => {
  const history = useHistory();
  const { isProxyUser, currentUrl, referringUrl, loading } = props;

  const onClick = () => {
    const amplitudeEventData: AmplitudeEventData = {
      referringUrl,
      currentUrl,
      isProxy: isProxyUser ? 'Yes' : 'No'
    };
    analyticsService.logEvent(
      AnalyticsEvent.UtahSchoolImmunizationRecordClicked,
      amplitudeEventData
    );

    history.push('/u/health-record/usir');
  };

  return (
    <MuiBox data-testid={props['data-testid']}>
      <Spacer spacing="medium" />
      <FlexBoxRow justifyContent="flex-end">
        <MuiButton
          disabled={loading}
          color="primary"
          size="large"
          variant="contained"
          onClick={onClick}
        >
          {loading ? (
            <MuiBox paddingRight="15px">
              <Svg set="downloading" color={Color.black} size={IconSize.base} />
            </MuiBox>
          ) : null}
          {loading ? 'Retrieving' : ''} {HEALTH_RECORDS_PAGES.MedicalHistory.usirButton}
        </MuiButton>
      </FlexBoxRow>
      <Spacer spacing="medium" />
    </MuiBox>
  );
};

const renderData = (immunizations: Immunization[]) => {
  return (
    <MuiBox width="100%" pt={3}>
      {immunizations.map((item: Immunization) => (
        <MuiBox mb={1} key={item.immunizationId}>
          <MuiPaper elevation={2}>
            <MedicalHistoryListItem
              title={capitalize(item.immunizationName)}
              body={formatDate(item.immunizationDate)}
              itemBackgroundColor={Color.white}
              hideInfoIcon
            >
              <MuiBox p={3}>
                <MuiGrid container spacing={3} aria-label="immunizations list">
                  <MuiGrid item xs={3}>
                    <MuiBox>
                      <MuiBox fontWeight="fontWeightBold" textAlign="left">
                        Reaction:
                      </MuiBox>
                      <MuiBox fontWeight="fontWeightBold" textAlign="left">
                        Date given:
                      </MuiBox>
                    </MuiBox>
                  </MuiGrid>
                  <MuiGrid item xs={6}>
                    <MuiBox>
                      <MuiBox style={{ minHeight: '19px' }}>{orEmpty(item.reaction)}</MuiBox>
                      <MuiBox>{formatDate(item.immunizationDate)}</MuiBox>
                    </MuiBox>
                  </MuiGrid>
                </MuiGrid>
              </MuiBox>
            </MedicalHistoryListItem>
          </MuiPaper>
        </MuiBox>
      ))}
    </MuiBox>
  );
};

export const ImmunizationsComponent = ({
  error,
  loading,
  immunizations,
  dispatch,
  isDownloadingImmunizations,
  downloadError,
  currentUrl,
  referringUrl,
  isProxyUser,
  hasUsir,
  hasUsirLoading,
  hasUsirError
}: Props) => {
  useEffect(() => {
    dispatch(fetchImmunizations());
  }, []);

  useProxySwitcherEffect(() => {
    dispatch(fetchHasUSIR());
  }, []);

  const data: AmplitudeEventData = {
    currentUrl,
    referringUrl
  };

  const handleShareImmunizations = () => {
    analyticsService.logEvent(AnalyticsEvent.ShareImmunizationsClicked, data);
    dispatch(replyToImmunizations(immunizations, currentUrl));
  };

  const handleDownloadImmunizations = () => {
    analyticsService.logEvent(AnalyticsEvent.DownloadImmunizationsClicked, data);
    dispatch(downloadImmunizations());
  };

  return (
    <>
      <DownloadErrorSnackbar hasError={!!downloadError} onRetry={handleDownloadImmunizations} />
      <MuiBox minHeight="calc(100vh - 300px)">
        <Banner
          onDownloadPress={handleDownloadImmunizations}
          onTransmitPress={handleShareImmunizations}
          isDownloading={isDownloadingImmunizations}
        />
        <MuiBox flexGrow={2}>
          <MuiContainer maxWidth="lg">
            <MedicalHistoryMessage
              type="immunizations"
              loading={loading}
              data={immunizations}
              error={error}
            />
            {renderData(immunizations)}
          </MuiContainer>
        </MuiBox>
      </MuiBox>

      <MuiBox
        position="sticky"
        bottom={0}
        right={0}
        zIndex={2}
        width="100%"
        bgcolor={Color.grayLight2}
      >
        {hasUsirError && !hasUsirLoading && !hasUsir ? (
          <FlexBox
            testID="usir-error-message"
            justifyContent="center"
            alignItems="center"
            vSpacing={2}
            hSpacing={20}
          >
            <FlexBoxRow padding="20px" backgroundColor={Color.redLight}>
              <WarningIcon alt="warning" src={warning} />
              <MuiBox padding="4px">
                <Typography fontSize={FontSize.small} textAlign="center">
                  {USIR_ERROR_MESSAGE}
                </Typography>
              </MuiBox>
            </FlexBoxRow>
          </FlexBox>
        ) : null}
        {!hasUsir && !hasUsirLoading && !hasUsirError ? (
          <FlexBox
            testID="usir_no_message"
            justifyContent="center"
            alignItems="center"
            vSpacing={15}
            hSpacing={20}
          >
            <Typography fontSize={FontSize.small} textAlign="center">
              {NO_USIR_MESSAGE}
            </Typography>
          </FlexBox>
        ) : null}
        {hasUsirLoading || (hasUsir && !hasUsirError) ? (
          <MuiBox paddingX="20px" paddingY="5px">
            <UsirButton
              loading={hasUsirLoading}
              currentUrl={currentUrl}
              referringUrl={referringUrl}
              isProxyUser={isProxyUser}
              data-testid="usir-button"
            />
          </MuiBox>
        ) : null}
      </MuiBox>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  error: immunizationsErrorSelector(state),
  loading: immunizationsLoadingSelector(state),
  immunizations: immunizationsDataSelector(state),
  isDownloadingImmunizations: immunizationsDownloadingSelector(state),
  downloadError: immunizationsDownloadErrorSelector(state),
  isProxyUser: isImpersonatingSelector(state),
  currentUrl: currentLocationPathNameSelector(state),
  referringUrl: previousLocationPathNameSelector(state),
  hasUsir: hasUsirSelector(state),
  hasUsirLoading: hasUsirLoadingSelector(state),
  hasUsirError: hasUsirErrorSelector(state)
});

export default connect(mapStateToProps)(ImmunizationsComponent);
