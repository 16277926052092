import React from 'react';
import { useHistory } from 'react-router-dom';
import isEmpty from 'lodash/isNil';
import { EducationItem } from 'store/education/types';
import usePatientEducation from 'lib/hooks/usePatientEducation';
import Spinner from 'components/UI/Spinner/Spinner';
import { MuiBox, MuiPaper, MuiTypography, MuiSnackbar } from 'theme/material-ui';
import EducationView from './EducationView';
import ErrorMessage from './ErrorMessage';

interface ActionEducationViewProps {
  accessCode?: string;
}

export const ActionEducationView = ({ accessCode }: ActionEducationViewProps) => {
  const history = useHistory();
  const { incomplete, counts, refetch, loading, error } = usePatientEducation();

  const indexOfAccessCode = incomplete.findIndex((e: EducationItem) => e.accessCode === accessCode);
  const educationItem = incomplete[indexOfAccessCode];

  // Minus the one currently being viewed
  const nextCount = counts.incompleteCount - 1;

  const handleNextAssignmentClicked = async () => {
    await refetch();
    if (indexOfAccessCode + 1 < counts.incompleteCount) {
      history.push(
        `/u/health-record/patient-education/detail/${incomplete[indexOfAccessCode + 1].accessCode}`
      );
    } else {
      history.push(`/u/health-record/patient-education/detail/${incomplete[0].accessCode}`);
    }
  };

  return (
    <>
      {loading ? (
        <MuiSnackbar
          open
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          style={{ bottom: 10, right: 10 }}
        >
          <MuiPaper>
            <MuiBox
              p={2}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <MuiTypography>Getting your next assignment...</MuiTypography>
              <Spinner />
            </MuiBox>
          </MuiPaper>
        </MuiSnackbar>
      ) : null}

      {isEmpty(error) ? (
        <EducationView
          educationItem={educationItem}
          nextCount={nextCount}
          onNext={nextCount >= 1 ? handleNextAssignmentClicked : undefined}
          loading={loading}
        />
      ) : (
        <ErrorMessage
          message="There was an issue loading your assignment."
          action={{
            text: 'Go to education page',
            onClick: () => history.push('/u/health-record/patient-education')
          }}
        />
      )}
    </>
  );
};

export default ActionEducationView;
