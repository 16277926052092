import { Carousel } from 'components/DashboardCarousel';
import Terms from 'components/Terms/Terms';
import Box from 'components/UI/Layout/Box';
import FlexBox from 'components/UI/Layout/FlexBox';
import Svg from 'components/UI/Svg/Svg';
import Typography from 'components/UI/Typography';
import {
  useHighPriorityActionItems,
  useLowPriorityActionItems
} from 'hooks/useDashboardPriorityItems';
import useNavigationAnalytics from 'hooks/useNavigationAnalytics';
import { DASHBOARD_CARD_CATEGORIES, dashboardCardCategory } from 'lib/constants/dashboard';
import { ItemTypes, ProcessedActionItem } from 'lib/dashboard/types';
import { getItems } from 'lib/dashboard/utils';
import { Color } from 'modules/styles/colors';
import { IconSize, Spacing } from 'modules/styles/variables';
import React, { ComponentType, useEffect, useMemo, useState } from 'react';
import Config from 'react-native-config';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import analyticsService, { AnalyticsEvent } from 'services/AnalyticsService';
import { getErrorMessage } from 'services/api/utils';
import { getGoToIdIndex } from 'store/deepLink/utils';
import {
  currentAccountConsumerSelector,
  isImpersonatingSelector,
  otherAccountConsumersNeedActivationSelector
} from 'store/account/selectors';
import { PATCH_EVENT_RESET, patchEventMessage } from 'store/events/actions';
import {
  patchEventCompletedSelector,
  patchEventErrorSelector,
  patchEventFetchingSelector,
  patchEventMessageSelector
} from 'store/events/selectors';
import { SNOOZE_HEALTH_ADVISORY_CARD_RESET } from 'store/healthAdvisory/actions';
import { healthAdvisorySnoozeCompletedSelector } from 'store/healthAdvisory/selectors';
import { getNotificationsPreferences } from 'store/notifications/actions';
import {
  notificationsChannelsSelector,
  notificationsPreferencesErrorSelector,
  notificationsPreferencesFetchingSelector
} from 'store/notifications/selectors';
import { NotificationItem } from 'store/notifications/types';
import { SET_HAS_SENT_DASHBOARD_CARD_ANALYTICS } from 'store/priorityItems/actions';
import { hasSentDashboardCardAnalyticsSelector } from 'store/priorityItems/selectors';
import { AdvisoryFormattedInterface } from 'store/priorityItems/types';
import { Consumer } from 'store/profile/types';
import {
  currentLocationPathNameSelector,
  previousLocationPathNameSelector
} from 'store/router/selectors';
import { RootState } from 'store/types';
import * as actions from 'store/visitSummary/actions.web';
import {
  completedEVisitListSelector,
  pendingEVisitListSelector
} from 'store/visitSummary/selectors';
import { VisitSummary } from 'store/visitSummary/types';
import { MuiSnackbar, MuiSnackbarContent } from 'theme/material-ui';
import { getVisitSummaryLinkPath } from '../../VisitSummary/navigation';
import DashboardBgBanner from './DashboardBgBanner';
import { DashboardCarouselAntiCoagulation } from './DashboardCarouselCards/DashboardCarouselAntiCoagulation';
import DashboardCarouselEVisitsCard from './DashboardCarouselCards/DashboardCarouselEVisits/DashboardCarouselEVisitsCard';
import DashboardCarouselGoals from './DashboardCarouselCards/DashboardCarouselGoals';
import DashboardCarouselHealthAdvisory from './DashboardCarouselCards/DashboardCarouselHealthAdvisory/DashboardCarouselHealthAdvisory';
import DashboardCarouselOnboarding from './DashboardCarouselCards/DashboardCarouselOnboarding';
import DashboardCarouselRTA from './DashboardCarouselCards/DashboardCarouselRTA';
import DashboardCarouselRefreshUserList from './DashboardCarouselCards/DashboardCarouselRefreshUserList';
import DashboardWelcomeCard from './DashboardCarouselCards/DashboardWelcomeCard';

export interface Props {
  consumer: Consumer;
  isImpersonating: boolean;
  notificationsChannels: { [key: string]: NotificationItem };
  hideEventComplete: boolean;
  hideEventLoading: boolean;
  patchEventMessage: string;
  patchEventError?: typeof Error;
  setPatchEventMessage: typeof patchEventMessage;
  goToId?: string;
  clearGoToId?: () => void;
  getNotificationsPreferences: typeof getNotificationsPreferences;
  notificationsPreferencesLoading: boolean;
  notificationsPreferencesError?: typeof Error;
  needActivation: boolean;
  currentUrl: string;
  referringUrl: string;
  pendingEVisits: VisitSummary[];
  completedEVisits: VisitSummary[];
  snoozed: boolean;
  hasSentDashboardCardAnalytics: boolean;
}

export const DashboardCarousel = (props: Props) => {
  const {
    consumer,
    isImpersonating,
    notificationsChannels,
    hideEventLoading,
    hideEventComplete,
    patchEventMessage,
    patchEventError,
    setPatchEventMessage,
    goToId,
    getNotificationsPreferences,
    notificationsPreferencesLoading,
    notificationsPreferencesError,
    needActivation,
    currentUrl,
    referringUrl,
    pendingEVisits = [],
    completedEVisits = [],
    snoozed,
    hasSentDashboardCardAnalytics
  } = props;
  const usingSnoozeApi = Config.HIGH_PRIORITY_ITEMS_V2 === 'enabled';
  const dispatch = useDispatch();
  const dashboardCardsDisplayed = useNavigationAnalytics(AnalyticsEvent.DashboardCardDisplayed);

  useEffect(() => {
    dispatch(actions.getVisitSummaryFullList());
  }, []);

  const onItemClick = (item: VisitSummary) => {
    analyticsService.logEvent(AnalyticsEvent.VisitSummaryDetailClicked, {
      referringUrl,
      currentUrl
    });

    const params = {
      source: item.source,
      id: item.id,
      dateOfEncounter: item.date,
      mhpDetails: item.mhpDetails
    };
    history.push(getVisitSummaryLinkPath(item), {
      ...params
    });
  };

  const [isTermsOpen, setTermsOpen] = useState(false);
  const history = useHistory();
  const {
    lowPriorityData,
    lowPriorityErrors,
    lowPriorityLoading,
    lowPriorityRefetch
  } = useLowPriorityActionItems();

  const {
    highPriorityData,
    highPriorityErrors,
    highPriorityLoading,
    highPriorityRefetch
  } = useHighPriorityActionItems();

  const isLoading = highPriorityLoading || hideEventLoading || notificationsPreferencesLoading;

  const { doOnboarding } = consumer;
  const [showOnboardingCard, setShowOnboardingCard] = useState(false);
  const [showNeedActivationCard, setShowNeedActivationCard] = useState(needActivation);
  const [autoSlideIndex, setAutoSlideIndex] = useState<number | undefined>();

  const allowedItems = useMemo(
    () =>
      getItems(
        lowPriorityData,
        highPriorityData,
        null,
        isImpersonating,
        showOnboardingCard,
        showNeedActivationCard,
        pendingEVisits,
        completedEVisits
      ),
    [
      lowPriorityData,
      highPriorityData,
      showOnboardingCard,
      showNeedActivationCard,
      pendingEVisits,
      completedEVisits
    ]
  );

  useEffect(() => {
    const hasChannels = !!Object.keys(notificationsChannels).length;
    const hasChannelsEnabled = !!Object.values(notificationsChannels).find(c => c.enabled);
    let doShowOnboardingCard = false;

    if (
      !isImpersonating &&
      !notificationsPreferencesLoading &&
      doOnboarding &&
      !notificationsPreferencesError
    ) {
      if (!hasChannels) {
        getNotificationsPreferences(false);
      } else if (!hasChannelsEnabled) {
        doShowOnboardingCard = true;
      }
    }

    setShowOnboardingCard(doShowOnboardingCard);
  }, [
    isImpersonating,
    notificationsChannels,
    notificationsPreferencesLoading,
    notificationsPreferencesError
  ]);

  useEffect(() => {
    if (!!goToId && !!allowedItems.length && !isLoading && !lowPriorityLoading) {
      setAutoSlideIndex(getGoToIdIndex(allowedItems, goToId));
    }
  }, [goToId, allowedItems.length, isLoading, lowPriorityLoading]);

  useEffect(() => {
    if (
      !lowPriorityLoading &&
      !highPriorityLoading &&
      !notificationsPreferencesLoading &&
      !hasSentDashboardCardAnalytics &&
      allowedItems.length
    ) {
      const cards = allowedItems.map(({ type, payload }) => {
        if (type === ItemTypes.HEALTH_ADVISORY && payload) {
          return {
            card_title: (payload as AdvisoryFormattedInterface).advisoryName ?? '',
            isProxy: isImpersonating,
            card_category: DASHBOARD_CARD_CATEGORIES.PREVENTIVE_CARE_ADVISORIES
          };
        }
        return {
          card_title: type,
          isProxy: isImpersonating,
          card_category: dashboardCardCategory[type]
        };
      });
      dashboardCardsDisplayed({ dashboard_card_displayed: cards });
      dispatch({ type: SET_HAS_SENT_DASHBOARD_CARD_ANALYTICS });
    }
  }, [
    allowedItems.length,
    highPriorityLoading,
    lowPriorityLoading,
    notificationsPreferencesLoading
  ]);

  useEffect(() => {
    // Refetch data after item removal complete

    if (hideEventComplete || snoozed) {
      highPriorityRefetch();
      lowPriorityRefetch();
      if (usingSnoozeApi) {
        dispatch({ type: SNOOZE_HEALTH_ADVISORY_CARD_RESET });
      } else dispatch({ type: PATCH_EVENT_RESET });
    }
  }, [hideEventComplete, snoozed]);

  useEffect(() => {
    if (highPriorityErrors?.length || lowPriorityErrors?.length) {
      const errors = (highPriorityErrors as string[]).concat(lowPriorityErrors as string[]);

      errors.forEach(error => {
        const errorMessage = getErrorMessage(error);
        analyticsService.logEvent(AnalyticsEvent.ErrorTracking, {
          currentUrl,
          referringUrl,
          action: `getDashboardCard`,
          errorMessage
        });
      });
    }
  }, [highPriorityErrors, lowPriorityErrors]);

  const onSnackbarClose = () => {
    setPatchEventMessage(null);
  };

  const handleAcceptTerms = () => {
    history.push('/u/find-a-doctor');
  };

  const renderCarouselItem = (item: ProcessedActionItem) => {
    if (item.type === ItemTypes.ANTICOAGULATION) {
      return (
        <DashboardCarouselAntiCoagulation data={item.payload} totalCards={allowedItems?.length} />
      );
    }

    if (item.type === ItemTypes.HEALTH_ADVISORY) {
      return (
        <DashboardCarouselHealthAdvisory data={item.payload} totalCards={allowedItems?.length} />
      );
    }

    if (item.type === ItemTypes.GOALS) {
      return <DashboardCarouselGoals data={item.payload} totalCards={allowedItems?.length} />;
    }

    if (item.type === ItemTypes.RTA_SUMMARY) {
      return <DashboardCarouselRTA data={item.payload} totalCards={allowedItems?.length} />;
    }

    if (item.type === ItemTypes.ONBOARDING) {
      return (
        <DashboardCarouselOnboarding
          history={history}
          close={() => setShowOnboardingCard(false)}
          totalCards={allowedItems?.length}
        />
      );
    }

    if (item.type === ItemTypes.NEED_ACTIVATION) {
      return (
        <DashboardCarouselRefreshUserList
          close={() => setShowNeedActivationCard(false)}
          totalCards={allowedItems?.length}
        />
      );
    }

    if (item.type === ItemTypes.PENDING_EVISITS && Config.EVISITS === 'enabled') {
      return (
        <DashboardCarouselEVisitsCard
          key={item.payload.id}
          isInProgress
          visitDetailsClick={() => {}}
          data={item.payload}
          totalCards={allowedItems?.length}
        />
      );
    }
    if (item.type === ItemTypes.COMPLETED_EVISITS && Config.EVISITS === 'enabled') {
      return (
        <DashboardCarouselEVisitsCard
          key={item.payload.id}
          visitDetailsClick={() => onItemClick(item.payload)}
          data={item.payload}
          totalCards={allowedItems?.length}
        />
      );
    }
    return null;
  };

  const renderCarousel = () => {
    if (isLoading) {
      return <DashboardWelcomeCard isLoading={isLoading} />;
    }

    if (!allowedItems.length) {
      return <DashboardWelcomeCard />;
    }

    return (
      <Carousel
        className="action-carousel"
        slides={allowedItems}
        renderSlide={renderCarouselItem}
        slideToKey={slide => (slide ? slide.reactUid : '')}
        autoSlideIndex={autoSlideIndex}
      />
    );
  };

  const renderSnackbar = () => {
    let messageText = patchEventMessage;
    if (patchEventError) {
      messageText = 'Something went wrong please try again.';
    }
    const message = (
      <FlexBox flexDirection="row" justifyContent="center" alignItems="center">
        <Svg set="assets" name="InfoIcon" size={IconSize.base} color={Color.white} />{' '}
        <Box hOffsetLeft={Spacing.medium}>
          <Typography color={Color.white}>{messageText}</Typography>
        </Box>
      </FlexBox>
    );

    return (
      <MuiSnackbar open={!!patchEventMessage} autoHideDuration={6000} onClose={onSnackbarClose}>
        <MuiSnackbarContent message={message} action={null} />
      </MuiSnackbar>
    );
  };

  const opacifyBanner = isLoading ? false : !!allowedItems.length;

  return (
    <>
      {isTermsOpen ? (
        <Terms
          open={isTermsOpen}
          handleClose={() => {
            setTermsOpen(false);
          }}
          handleAccept={handleAcceptTerms}
        />
      ) : null}
      <DashboardBgBanner opacify={opacifyBanner} />
      {renderCarousel()}
      {renderSnackbar()}
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  completedEVisits: completedEVisitListSelector(state),
  pendingEVisits: pendingEVisitListSelector(state),
  consumer: currentAccountConsumerSelector(state),
  hideEventComplete: patchEventCompletedSelector(state),
  hideEventLoading: patchEventFetchingSelector(state),
  patchEventMessage: patchEventMessageSelector(state),
  patchEventError: patchEventErrorSelector(state),
  isImpersonating: isImpersonatingSelector(state),
  notificationsChannels: notificationsChannelsSelector(state),
  notificationsPreferencesLoading: notificationsPreferencesFetchingSelector(state),
  notificationsPreferencesError: notificationsPreferencesErrorSelector(state),
  needActivation: otherAccountConsumersNeedActivationSelector(state),
  currentUrl: currentLocationPathNameSelector(state),
  referringUrl: previousLocationPathNameSelector(state),
  snoozed: healthAdvisorySnoozeCompletedSelector(state),
  hasSentDashboardCardAnalytics: hasSentDashboardCardAnalyticsSelector(state)
});

const mapDispatchToProps = {
  setPatchEventMessage: patchEventMessage,
  getNotificationsPreferences
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardCarousel as ComponentType);
