import React from 'react';

export default {
  svg: (
    <g>
      <path d="M20,17.14v1.62H4.06V17.14ZM4.06,21.72H20V20.1H4.06Zm5-9.54a.51.51,0,0,0,0,1h6.35a.51.51,0,1,0,0-1ZM3.77,6.67A4.66,4.66,0,1,1,12.87,8h4.86L20,15.75H4.11L5.65,10.4A4.65,4.65,0,0,1,3.77,6.67Zm8.21,3a4.69,4.69,0,0,1-.83.77H15a.41.41,0,1,1,0,.82H9.36l-.11,0a4.62,4.62,0,0,1-2.13-.11l-.86,3H17.8L16.51,9.67Zm-6.67-3A3.11,3.11,0,1,0,9,3.62V6.05h.62a.62.62,0,0,1,0,1.23H7.81V3.62A3.12,3.12,0,0,0,5.31,6.67Z"></path>
    </g>
  ),
  viewBox: '0 0 24 24',
  preserveAspectRatio: 'xMidYMid meet'
};
