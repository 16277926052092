import styled from 'styled-components';

import DefaultLabel from 'components/UI/Labels/DefaultLabel';
import { Column } from 'components/UI/Layout/Container';
import Headline from 'components/UI/Typography/Headline';
import TouchablePanel from 'components/UI/Panel/TouchablePanel';
import FlexBox from 'components/UI/Layout/FlexBox';

import { Color } from 'modules/styles/colors';
import { FontSize, FontWeight, Spacing } from 'modules/styles/variables';
import { Text } from 'react-native';
import { MuiBox } from 'theme/material-ui';

export const Container = styled.div`
  flex: auto;
  background-color: ${Color.foreColor};
  flex-direction: column;
  width: 100%;
`;

export const StyledIcon = styled.div`
  margin-left: auto;
`;

export const SubText = styled(DefaultLabel)`
  font-size: 14px;
`;

export const StyledToggleSubText = styled(DefaultLabel)`
  font-size: 14px;
  color: ${props => (props.isConsumerView ? Color.white : Color.textLight)};
`;

export const PaddedColumn = styled(Column)`
  padding-left: 15px;
`;

export const TitleText = styled(DefaultLabel)`
  font-weight: bold;
`;

export const DetailedBoldText = styled(TitleText)`
  width: 50%;
`;

export const DetailedSubText = styled(SubText)`
  width: 50%;
`;

export const DateText = styled(Headline)`
  color: ${Color.gray};
`;

export const StyledTouchablePanel = styled(TouchablePanel)`
  align-items: center;
  border-color: ${Color.gray};
  flex-direction: row;
  padding: 6px 10px;
  justify-content: center;
  background-color: ${props => (props.isConsumerView ? Color.grayHue2 : Color.foreColor)};
`;

export const StyledMedicalHistoryViewButtonContainer = styled.div`
  flex: 1;
  padding: 2px;
`;

export const StyledMedicalHistoryViewFlexBox = styled(FlexBox)`
  margin-left: 10;
  margin-right: 10;
`;

interface SortOptionProps {
  selected?: boolean;
}

export const SectionTitle = styled(Text)`
  font-size: ${FontSize.large};
  font-weight: ${FontWeight.bold};
  padding: 20px;
  background-color: ${Color.grayHue4};
`;

export const SectionBody = styled(Text)`
  font-size: ${FontSize.large};
  font-weight: ${FontWeight.normal};
  padding: 20px;
`;

export const SortOptions = styled.div`
  background-color: ${Color.grayLight2};
  flex-direction: row;
  justify-content: space-between;
`;

export const SortOption = styled.div`
  border-bottom-color: ${Color.purple};
  border-bottom-width: ${(props: SortOptionProps) => (props.selected ? 2 : 0)};
  font-weight: ${(props: SortOptionProps) =>
    props.selected ? FontWeight.bold : FontWeight.normal};
  padding: ${Spacing.medium}px ${Spacing.large}px;
`;

export const SortOptionText = styled(Text)``;

export const SortableFlatListContainer = styled.div`
  background-color: ${Color.white};
  border-color: ${Color.grayLight};
  border-width: 0.5px;
  margin-bottom: ${Spacing.large}px;
`;

export const ActionsHeaderBox = styled(FlexBox)`
  width: 100%;
  background-color: ${Color.white};
`;

export const LeftContainer = styled.div`
  flex-direction: row;
  align-items: center;
  flex: 1;
  justify-content: flex-start;
`;

export const RightContainer = styled.div`
  flex-direction: row;
  align-items: center;
  flex: 1;
  justify-content: flex-end;
`;

export const StyledMuiBox = styled(MuiBox)<{ itemBackgroundColor: string }>`
  background-color: ${props =>
    props.itemBackgroundColor ? props.itemBackgroundColor : Color.white};
  cursor: pointer;
`;
