import styled from 'styled-components';

import { baseFontSize, Spacing, FontSize } from 'modules/styles/variables';
import { Color } from 'modules/styles/colors';
import LinkButton from 'components/UI/Button/LinkButton';
import { MuiTypography } from 'theme/material-ui';
import DefaultText from 'components/UI/Typography/DefaultText';

export const DataPickerStyle = {
  marginTop: 0,
  marginBottom: 0,
  color: Color.primary,
  fontSize: baseFontSize
};

export const StyledLinkButton = styled(LinkButton)`
  /* color: ${Color.grayLight}   TODO change color */
`;

export const RadioButtonStyle = {
  color: Color.textLight,
  fontSize: baseFontSize,
  paddingRight: 5
};

export const SectionHeader = styled(MuiTypography)`
  padding-bottom: ${Spacing.medium}px;
  padding-top: ${Spacing.xLarge}px;
`;

export const StyledDefaultText = styled(DefaultText)`
  font-size: ${FontSize.base}px;
`;
