import React from 'react';

export default {
  svg: (
    <path
      d="M10.455 24.864a.737.737 0 0 1-.406-.114c-.33-.194-.546-.63-.546-1.099v-4.17H2.417c-1.155 0-2.108-1.195-2.108-2.682V2.819c0-1.47.94-2.683 2.108-2.683h20.178c1.155 0 2.108 1.196 2.108 2.683v13.996c0 1.47-.94 2.683-2.108 2.683H15.94l-4.876 5.107a.925.925 0 0 1-.61.259ZM2.418 2.56c-.114 0-.204.114-.204.26v13.995c0 .145.09.259.204.259h8.038c.254 0 .496.13.673.356.178.226.28.532.28.856V21.1l3.581-3.75a.83.83 0 0 1 .61-.275h6.984c.114 0 .204-.114.204-.26V2.82c0-.145-.09-.259-.204-.259H2.418Z"
      fill="#222"
      fillRule="evenodd"
    />
  ),
  viewBox: '0 0 25 25'
};
