import React from 'react';

import Spacer from 'components/UI/Layout/Spacer';
import { StyledHeader } from './styled';
import { Prescriber } from 'store/medications/types';
import { convertToLowerKabobCase } from 'modules/utils/StringUtils';
import { FontSize } from 'modules/styles/variables';
import { MuiBox, MuiGrid } from 'theme/material-ui';

interface Props {
  sectionSubheader: string;
  prescriber: Prescriber;
}

const AdditionalInformation = ({ sectionSubheader, prescriber }: Props) => (
  <MuiBox p={1}>
    <StyledHeader
      fontSize={FontSize.heading}
      data-testid={convertToLowerKabobCase(sectionSubheader)}
    >
      {sectionSubheader}
    </StyledHeader>
    <Spacer spacing="medium" />
    <MuiGrid container spacing={1} data-testid="prescriber">
      <MuiGrid item xs={4}>
        <MuiBox fontWeight="fontWeightBold" textAlign="left">
          Prescriber
        </MuiBox>
      </MuiGrid>
      <MuiGrid item xs={6}>
        <MuiBox style={{ minHeight: '19px' }}>{prescriber.name ? prescriber.name : 'None'}</MuiBox>
      </MuiGrid>
    </MuiGrid>
  </MuiBox>
);

export default AdditionalInformation;
