import { LabHistoricalResult, LabHistoricalComponent } from 'store/testResults/types';
import dayjs from 'dayjs';
import { HistoricalFilters } from './enums';

// Flattens out the data received, to fit component needs - Used in LabTrendline & LabTrendlineTable Component
export const formatData = (data: LabHistoricalResult[] | null) => {
  if (data) {
    return data.reduce((acc: LabHistoricalComponent[], { components }) => {
      return [...acc, ...components];
    }, []);
  }
  return [];
};

// Filters the data - Used in LabTrendLine Component
export const getFilteredData = (filter: HistoricalFilters, data: LabHistoricalComponent[]) => {
  if (data.length) {
    const currentDate = new Date();
    const currentYear = new Date(currentDate.getFullYear(), 0, 1);
    const lastYear = new Date(currentDate.getFullYear() - 1, 0, 1);

    if (filter === HistoricalFilters.CURRENT) {
      return data.filter(d => dayjs(currentYear).isSame(dayjs(d.issued), 'year'));
    }

    if (filter === HistoricalFilters.LAST) {
      return data.filter(d => dayjs(lastYear).isSame(dayjs(d.issued), 'year'));
    }

    if (filter === HistoricalFilters.ALL) {
      return data;
    }
  }
  return data;
};
