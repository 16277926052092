import React, { ComponentType } from 'react';
import dayjs from 'dayjs';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { FlatList } from 'react-native';
import { connect } from 'react-redux';

import { MuiBox, MuiTypography } from 'theme/material-ui';

import Footer from 'components/common/Footer';
import { StyledScreen } from 'components/common/PatientLoader/styled';
import { StyledAsterisk } from 'components/RequiredFieldsLegend';
import Box from 'components/UI/Layout/Box';
import Spacer from 'components/UI/Layout/Spacer';

import { CovidTitles } from 'lib/constants/covidScreening';
import { history } from 'lib/history';

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { KeyboardDatePicker } from '@material-ui/pickers';

import { patientSelect } from 'modules/constants/covidScreening';
import {
  CovidSymptomsEnums,
  COVID_SYMPTOMS,
  CURRENT_SYMPTOMS_TEXT_1,
  CURRENT_SYMPTOMS_TEXT_2,
  CURRENT_SYMPTOMS_TEXT_3
} from 'modules/constants/covidScreening/symptomInfo';
import { FontWeight, Spacing } from 'modules/styles/variables';
import { convertToLowerKabobCase } from 'modules/utils/StringUtils';

import { AnalyticsEvent } from 'services/AnalyticsService';
import * as covidActions from 'store/CovidScreening/actions';
import { CovidScreeningQuestionnaireSelector } from 'store/CovidScreening/selectors';
import { Questionnaire } from 'store/CovidScreening/types';
import { RootDispatch, RootState } from 'store/types';

import { CovidTestButton } from '../Components/CovidTestButton';
import useStyles from '../Components/CovidTestButton/useStyles';
import useNavigationAnalytics from 'hooks/useNavigationAnalytics';

interface CovidScreeningSymptomInfoProps {
  questionnaire: Questionnaire;
  setQuestionnaire: typeof covidActions.setQuestionnaire;
  dispatch: RootDispatch;
}

export const CovidCurrentSymptomsScreen = ({
  questionnaire,
  setQuestionnaire
}: CovidScreeningSymptomInfoProps) => {
  const covidTestCurrentSymptomsPrev = useNavigationAnalytics(AnalyticsEvent.CovidPrev);
  const covidTestCurrentSymptomsNxt = useNavigationAnalytics(AnalyticsEvent.CovidNext);

  const covidSymptomsValidationSchema = Yup.object().shape({
    covidSymptomsChecklist: Yup.array().required(),
    symptomsBeganDate: Yup.date().nullable(true)
  });

  const classes = useStyles();
  const asterisk = (
    <MuiTypography display="inline">
      <StyledAsterisk />
    </MuiTypography>
  );
  const getFieldValue = () => {
    const reduxFieldValues = {
      Q_Fever: questionnaire?.Q_Fever,
      Q_New_or_Worsening_Cough: questionnaire?.Q_New_or_Worsening_Cough,
      Q_Shortness_of_Breath_New_or_Worsening: questionnaire?.Q_Shortness_of_Breath_New_or_Worsening,
      Q_Body_Aches: questionnaire?.Q_Body_Aches,
      Q_COVID_Screen_Sore_Throat: questionnaire?.Q_COVID_Screen_Sore_Throat,
      Q_COVID_Screen_Runny_Nose: questionnaire?.Q_COVID_Screen_Runny_Nose,
      Q_COVID_Screen_Decreased_Smell_or_Taste:
        questionnaire?.Q_COVID_Screen_Decreased_Smell_or_Taste,
      Q_Diarrhea: questionnaire?.Q_Diarrhea,
      Q_None_of_the_above: questionnaire?.Q_None_of_the_above,
      Q_Outbreak_Date_of_Symptoms_Onset: dayjs(
        questionnaire?.Q_Outbreak_Date_of_Symptoms_Onset
      ).format('MM/DD/YYYY')
    };
    const filtered = Object.entries(reduxFieldValues)
      .filter(([key, value]) => value === 'Yes')
      .map(([key, value]) => key);
    const initialValues = {
      covidSymptomsChecklist: filtered || [],
      symptomsBeganDate: questionnaire?.Q_Outbreak_Date_of_Symptoms_Onset ?? ''
    };
    return initialValues;
  };

  const updateFieldValues = values => {
    const symptomsInfo = {
      Q_Fever: 'No',
      Q_New_or_Worsening_Cough: 'No',
      Q_Shortness_of_Breath_New_or_Worsening: 'No',
      Q_Body_Aches: 'No',
      Q_COVID_Screen_Sore_Throat: 'No',
      Q_COVID_Screen_Runny_Nose: 'No',
      Q_COVID_Screen_Decreased_Smell_or_Taste: 'No',
      Q_Diarrhea: 'No',
      Q_None_of_the_above: 'No',
      Q_Outbreak_Date_of_Symptoms_Onset: ''
    };

    if (values.covidSymptomsChecklist.includes(CovidSymptomsEnums.fever)) {
      symptomsInfo.Q_Fever = 'Yes';
    }
    if (values.covidSymptomsChecklist.includes(CovidSymptomsEnums.cough)) {
      symptomsInfo.Q_New_or_Worsening_Cough = 'Yes';
    }
    if (values.covidSymptomsChecklist.includes(CovidSymptomsEnums.shortnessOfBreath)) {
      symptomsInfo.Q_Shortness_of_Breath_New_or_Worsening = 'Yes';
    }
    if (values.covidSymptomsChecklist.includes(CovidSymptomsEnums.fatigueOrBodyAches)) {
      symptomsInfo.Q_Body_Aches = 'Yes';
    }
    if (values.covidSymptomsChecklist.includes(CovidSymptomsEnums.soreThroat)) {
      symptomsInfo.Q_COVID_Screen_Sore_Throat = 'Yes';
    }
    if (values.covidSymptomsChecklist.includes(CovidSymptomsEnums.runnyNose)) {
      symptomsInfo.Q_COVID_Screen_Runny_Nose = 'Yes';
    }
    if (values.covidSymptomsChecklist.includes(CovidSymptomsEnums.lossOfSmell)) {
      symptomsInfo.Q_COVID_Screen_Decreased_Smell_or_Taste = 'Yes';
    }
    if (values.covidSymptomsChecklist.includes(CovidSymptomsEnums.diarrhea)) {
      symptomsInfo.Q_Diarrhea = 'Yes';
    }
    if (values.covidSymptomsChecklist.includes(CovidSymptomsEnums.noneOfTheAbove)) {
      symptomsInfo.Q_None_of_the_above = 'Yes';
    }
    symptomsInfo.Q_Outbreak_Date_of_Symptoms_Onset = dayjs(values.symptomsBeganDate).format(
      'MM/DD/YYYY'
    );

    const questionnaireObject = {
      ...questionnaire,
      ...symptomsInfo
    };
    setQuestionnaire(questionnaireObject);
    return symptomsInfo;
  };
  const saveToStore = values => {
    const selectedValue = updateFieldValues(values);
    covidTestCurrentSymptomsNxt({
      selectedValue,
      title: CovidTitles.SYMPTOM_INFORMATION
    });
    history.push('/u/get-care-now/covid-screen/personal-information');
  };

  const onCancelClick = values => {
    updateFieldValues(values);
    history.goBack();
    covidTestCurrentSymptomsPrev({ title: CovidTitles.SYMPTOM_INFORMATION });
  };

  const checkDate = values =>
    !dayjs().isValid(values.symptomsBeganDate) || values.symptomsBeganDate > new Date();

  return (
    <MuiBox
      mt={1}
      style={{
        marginLeft: 'auto',
        marginRight: 'auto'
      }}
    >
      <StyledScreen>
        <MuiBox pl={4} my={2}>
          <MuiTypography fontWeight={FontWeight.bold}>
            {CURRENT_SYMPTOMS_TEXT_1}&nbsp;{asterisk}
          </MuiTypography>
          <Spacer size="small" />
          <MuiTypography>{CURRENT_SYMPTOMS_TEXT_2}</MuiTypography>
        </MuiBox>
        <Formik
          enableReinitialize="true"
          initialValues={getFieldValue()}
          validationSchema={covidSymptomsValidationSchema}
          onSubmit={values => saveToStore(values)}
        >
          {({ values, isValid, handleBlur, setFieldTouched, setFieldValue, handleSubmit }) => {
            return (
              <>
                <Box hOffsetLeft={Spacing.large}>
                  <FlatList
                    ItemSeparatorComponent={() => <Spacer size="small" />}
                    data={COVID_SYMPTOMS}
                    keyExtractor={item => `${item.key}`}
                    renderItem={({ item }) => {
                      const checkedValues = values?.covidSymptomsChecklist || [];
                      return (
                        <>
                          <MuiBox px={4} py={0.3}>
                            <FormControlLabel
                              label={item.label}
                              control={
                                <Checkbox
                                  data-testid={convertToLowerKabobCase(item.fieldName, '-checkbox')}
                                  color="primary"
                                  value={item.fieldName}
                                  checked={checkedValues.includes(item.fieldName)}
                                  onChange={() => {
                                    if (
                                      (item.fieldName === 'Q_None_of_the_above' &&
                                        !checkedValues.includes(item.fieldName)) ||
                                      (item.fieldName !== 'Q_None_of_the_above' &&
                                        checkedValues.includes('Q_None_of_the_above'))
                                    ) {
                                      checkedValues.length = 0;
                                    }
                                    setFieldTouched('covidSymptomsChecklist');
                                    setFieldValue(
                                      'covidSymptomsChecklist',
                                      checkedValues.includes(item.fieldName)
                                        ? checkedValues.filter(i => i !== item.fieldName)
                                        : checkedValues.concat(item.fieldName)
                                    );
                                  }}
                                  style={{
                                    alignSelf: 'start',
                                    paddingTop: '0px',
                                    paddingBottom: '0px'
                                  }}
                                />
                              }
                            />
                          </MuiBox>
                        </>
                      );
                    }}
                  />
                </Box>
                <MuiBox px={4}>
                  <Spacer size="medium" />
                  <MuiTypography fontWeight={FontWeight.bold}>
                    {CURRENT_SYMPTOMS_TEXT_3}&nbsp;{asterisk}
                  </MuiTypography>
                  <Spacer size="small" />
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    inputVariant="outlined"
                    format="MM/DD/YYYY"
                    value={values.symptomsBeganDate || null}
                    onChange={value => {
                      setFieldValue('symptomsBeganDate', value, true);
                    }}
                    onBlur={handleBlur('symptomsBeganDate')}
                    maxDateMessage="Please enter a valid date"
                    minDateMessage="Please enter a valid date"
                    maxDate={new Date()}
                    name="symptomsBeganDate"
                    data-testid="symptoms-began-date"
                    placeholder="MM/DD/YYYY"
                    required
                    autoOk
                  />
                  <MuiBox className={classes.steppersFooter}>
                    <CovidTestButton
                      fullWidth
                      data-testid="symptoms-next-button"
                      variant="contained"
                      onClick={() => handleSubmit()}
                      disabled={
                        !values?.symptomsBeganDate ||
                        checkDate(values) ||
                        !isValid ||
                        values.covidSymptomsChecklist.length === 0
                      }
                    >
                      Next
                    </CovidTestButton>
                    <CovidTestButton
                      fullWidth
                      variant="text"
                      data-testid="symptoms-previous-button"
                      onClick={() => onCancelClick(values)}
                    >
                      Previous
                    </CovidTestButton>
                  </MuiBox>
                </MuiBox>
              </>
            );
          }}
        </Formik>
        <Footer
          headerText={patientSelect.GET_PATIENTS.HELPLINE.CovidInfo}
          link={patientSelect.GET_PATIENTS.HELPLINE.CovidPatientSelectHelpline}
          linkName={patientSelect.GET_PATIENTS.HELPLINE.CovidPatientSelectHelpline}
          helplineHours={patientSelect.GET_PATIENTS.HELPLINE.CovidHelplineHours}
          title={CovidTitles.SYMPTOM_INFORMATION}
          authenticated
        />
      </StyledScreen>
    </MuiBox>
  );
};

const mapStateToProps = (state: RootState) => ({
  questionnaire: CovidScreeningQuestionnaireSelector(state)
});

const mapDispatchToProps = {
  setQuestionnaire: covidActions.setQuestionnaire
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CovidCurrentSymptomsScreen as ComponentType);
