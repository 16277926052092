import React, { useEffect, useState } from 'react';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { MuiBox, MuiContainer } from 'theme/material-ui';
import { ConnectCareStepActions, ConnectCareStepAction } from 'components/ConnectCare';
import { useSnack } from 'components/Snack';
import {
  Provider,
  ProviderDetails,
  Practice,
  ProviderVisibility,
  SpeedPass,
  StorageKey
} from 'store/amwell/types';
import { ROUTE } from 'components/ConnectCare/constants';
import analyticsService, { AmplitudeEventData, AnalyticsEvent } from 'services/AnalyticsService';
import { speedPassDataSelector } from 'store/amwell/activeConsumer/speedPass/selectors';
import {
  currentLocationPathNameSelector,
  previousLocationPathNameSelector
} from 'store/router/selectors';
import * as selectors from 'store/amwell/selectors';
import * as actions from 'store/amwell/actions';
import { RootState } from 'store/types';
import { providerSelect } from 'modules/constants/amwell';
import ConnectCarePractices from './ConnectCarePractices';
import ConnectCareProviders from './ConnectCareProviders';
import { getSelectionId, findSelectionById } from '../utils';
import { RouteData } from '../types';
import { FormScreen } from '../styled';
import { Alert } from 'components/Alert';
import { connectCareFormRoutes } from '../router';

export interface Props extends RouteComponentProps {
  createVisitFromSpeedPass: typeof actions.createVisitFromSpeedPass;
  dismissSpeedPass: typeof actions.dismissSpeedPass;
  getProviderDetail: typeof actions.getProviderDetail;
  getPractices: typeof actions.getPractices;
  getProvidersForPractice: typeof actions.getProvidersForPractice;
  getSpeedpass: typeof actions.getSpeedpass;
  updatePractice: typeof actions.updatePractice;
  hasFirstAvailable: typeof actions.hasFirstAvailable;
  remove: typeof actions.removeStorage;
  practices: Practice[];
  practice: Practice | null;
  providers: Provider[];
  provider: ProviderDetails | null;
  speedPass: SpeedPass;
  currentUrl?: string;
  referringUrl?: string;
}

export function ConnectCareProviderSelect({
  createVisitFromSpeedPass,
  dismissSpeedPass,
  getProviderDetail,
  getPractices,
  getProvidersForPractice,
  getSpeedpass,
  updatePractice,
  hasFirstAvailable,
  remove,
  practices,
  practice,
  providers,
  provider,
  history,
  speedPass,
  currentUrl,
  referringUrl
}: Props) {
  const { create } = useSnack();
  const data: AmplitudeEventData = {
    currentUrl,
    referringUrl
  };
  const [practiceSelection, setPracticeSelection] = useState<Practice | null>(null);
  const [providerSelection, setProviderSelection] = useState<Provider | null>(null);
  const [viewProviderDetails, setViewProviderDetails] = useState<Provider | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getPractices();
  }, []);

  useEffect(() => {
    // When there is only 1 practice to select, we select it by default.
    if (practices && practices.length === 1) {
      setPracticeSelection(findSelectionById(practices, practice, practices[0]));
      getProvidersForPractice({ practice: practices[0] });
    } else {
      setPracticeSelection(findSelectionById(practices, practice));
    }
  }, [practices]);

  useEffect(() => {
    const selection = findSelectionById(providers, provider);

    if (selection) {
      const selectionId = getSelectionId(selection.practice);
      const practiceId = getSelectionId(practiceSelection);

      if (selection.visibility === ProviderVisibility.OFFLINE) {
        remove({ keys: [StorageKey.PROVIDER] });
        setProviderSelection(null);
      } else if (selectionId === practiceId) {
        setProviderSelection(selection);
        getSpeedpass(selection);
      } else {
        setProviderSelection(null);
      }
    }
  }, [practiceSelection, providers]);

  const handleUseSpeedPass = async () => {
    setLoading(true);
    const paymentRoute = connectCareFormRoutes.find(
      route => route.componentName === 'ConnectCarePaymentMethodSelect'
    );
    if (paymentRoute) {
      onNextClick({ path: `${ROUTE.form}${paymentRoute.path}` } as RouteData, true);
    } else {
      throw new Error('handleUseSpeedPass could not find ConnectCarePaymentMethodSelect');
    }
  };

  useEffect(() => {
    if (speedPass && providerSelection) {
      Alert.alert(
        'Looks like you were just here!',
        `Would you like to use the information you provided on your last visit?`,
        [
          { text: 'Use speed pass', onPress: handleUseSpeedPass },
          { text: 'Cancel', onPress: () => dismissSpeedPass() }
        ]
      );
    }
  }, [speedPass, providerSelection]);

  const handlePracticeSelection = (p: Practice | null) => {
    setPracticeSelection(p);
    hasFirstAvailable({ practice: p });
    getProvidersForPractice({ practice: p });
  };

  const handleProviderSelection = (p: Provider | null) => {
    setProviderSelection(p);
    getSpeedpass(p);
    analyticsService.logEvent(AnalyticsEvent.ConnectCareProviderSelected, data);
  };

  const onPrevClick = (prevStep?: RouteData) => {
    if (prevStep) {
      history.push(prevStep.path);
    }
  };

  const onNextClick = async (nextStep?: RouteData, useSpeedPass?: boolean) => {
    if (!providerSelection) return;
    setLoading(true);
    await updatePractice({ practice: practiceSelection });
    const res: AnyAction = await getProviderDetail({ provider: providerSelection });

    if (res.error) {
      create(providerSelect.PROVIDER_SELECTION_ERROR.subtitle, 'error');
    } else if (useSpeedPass && nextStep) {
      await createVisitFromSpeedPass();
      history.push(nextStep.path);
      // Keep spinning till page transition
    } else if (nextStep) {
      history.push(nextStep.path);
      // Keep spinning till page transition
    } else {
      setLoading(false);
    }
  };

  return (
    <>
      <FormScreen>
        <MuiContainer maxWidth="lg">
          <MuiBox my={3}>
            <ConnectCarePractices
              setPracticeSelection={handlePracticeSelection}
              practiceSelection={practiceSelection}
            />
            <ConnectCareProviders
              practiceSelection={practiceSelection}
              providerSelection={providerSelection}
              setProviderSelection={handleProviderSelection}
              viewProviderDetails={viewProviderDetails}
              setViewProviderDetails={setViewProviderDetails}
            />
          </MuiBox>
        </MuiContainer>
      </FormScreen>
      <ConnectCareStepActions>
        <ConnectCareStepAction onClick={onPrevClick} />
        <ConnectCareStepAction
          disabled={!providerSelection}
          loading={loading}
          onClick={onNextClick}
        />
      </ConnectCareStepActions>
    </>
  );
}

const mapStateToProps = (state: RootState) => ({
  currentUrl: currentLocationPathNameSelector(state),
  referringUrl: previousLocationPathNameSelector(state),
  // list of practices
  practices: selectors.practicesListDataSelector(state),
  // selected practice
  practice: selectors.practiceDetailsDataSelector(state),
  // list of providers
  providers: selectors.providersListDataSelector(state),
  // selected provider details
  provider: selectors.providerDetailsDataSelector(state),
  // speed pass
  speedPass: speedPassDataSelector(state)
});

const mapDispatch = {
  createVisitFromSpeedPass: actions.createVisitFromSpeedPass,
  dismissSpeedPass: actions.dismissSpeedPass,
  getProviderDetail: actions.getProviderDetail,
  getPractices: actions.getPractices,
  getProvidersForPractice: actions.getProvidersForPractice,
  getSpeedpass: actions.getSpeedpass,
  updatePractice: actions.updatePractice,
  hasFirstAvailable: actions.hasFirstAvailable,
  remove: actions.removeStorage
};

export default connect(mapStateToProps, mapDispatch)(ConnectCareProviderSelect);
