import React from 'react';
import { Color } from 'modules/styles/colors';
import { MuiBox, MuiContainer, dfdDefaultTheme, MuiTypography } from 'theme/material-ui';
import { DISCLAIMER } from './constants';
import styled from 'styled-components';

export interface FooterLinks {
  id: number;
  name: string;
  link: string;
}

export default function Footer() {
  return (
    <MuiBox px={0} py={3} bgcolor={Color.baseColor}>
      <MuiContainer maxWidth="lg">
        <MuiBox
          alignItems="center"
          display="flex"
          justifyContent="center"
          textAlign="center"
          px={3}
        >
          <MuiTypography>{DISCLAIMER}</MuiTypography>
        </MuiBox>
      </MuiContainer>
    </MuiBox>
  );
}

export const SmallFooter = styled(MuiBox)`
  ${dfdDefaultTheme.breakpoints.between('xs', 'md')} {
    display: none;
  }
`;
