import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Config from 'react-native-config';

import { Color } from 'modules/styles/colors';
import { Spacing } from 'modules/styles/variables';
import { convertToLowerKabobCase } from 'modules/utils/StringUtils';
import analyticsService, { AnalyticsEvent } from 'services/AnalyticsService';
import { RedirectBrand } from 'store/register';
import { handleLoginPress } from 'store/pageActions/handleLoginPress';
import { hideGuestBenefitsModal } from 'store/authentication/actions';
import { hideGuestBenefitsModalSelector } from 'store/authentication/selectors';
import { RootState } from 'store/types';
import { CursorMuiBox, MuiBox, MuiButton, MuiContainer, MuiTypography } from 'theme/material-ui';

import VisitPayWebView from 'screens/Billing/VisitPayWebView';
import { guestHomeMenu } from 'screens/Guest/constants';
import GuestBenefitsModal from 'screens/Guest/GuestBenefitsModal';
import { NavigationScreenProps } from 'screens/navigation';
import { Icon } from 'components/Icon';
import Divider from 'components/UI/Divider';
import Spacer from 'components/UI/Layout/Spacer';
import Svg from 'components/UI/Svg/Svg';
import Typography from 'components/UI/Typography';
import useNavigationAnalytics from 'hooks/useNavigationAnalytics';

interface MenuItem {
  title: string;
  subTitle: string;
  route: string;
  icon: string;
}

export const renderMenuItem = (item: MenuItem, handleMenuPress: (route: string) => void) => (
  <MuiBox key={item.route}>
    <CursorMuiBox
      p={`${Spacing.large}px`}
      onClick={() => handleMenuPress(item.route)}
      flexDirection="row"
      display="flex"
      data-testid={convertToLowerKabobCase(item.title, '-menu-item')}
    >
      <Svg name={item.icon} stroke={Color.gray} height="40" width="40" />
      <MuiBox pl={Spacing.small}>
        <Typography tag="h4">{item.title}</Typography>
        <MuiTypography>{item.subTitle}</MuiTypography>
      </MuiBox>
      <MuiBox ml="auto" alignSelf="center">
        <Icon name="keyboard-arrow-right" size={24} color={Color.gray} />
      </MuiBox>
    </CursorMuiBox>
    <Divider />
  </MuiBox>
);

export interface Props extends NavigationScreenProps {
  hideGuestBenefits: boolean;
  hideGuestBenefitsModal: typeof hideGuestBenefitsModal;
  loginPress: typeof handleLoginPress;
}

export const GuestHomeScreen = ({
  history,
  hideGuestBenefits,
  hideGuestBenefitsModal,
  loginPress
}: Props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [goToVisitPay, setGoToVisitPay] = useState(false);

  const covidTestGetStartedClicked = useNavigationAnalytics(
    AnalyticsEvent.CovidTestGetStartedClicked
  );

  useEffect(() => {
    window.sessionStorage.setItem('registerBrand', RedirectBrand.MHP);
    window.sessionStorage.setItem('redirectIdx', '000');
    if (!hideGuestBenefits) {
      setModalOpen(true);
      hideGuestBenefitsModal();
    }
  }, []);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const navigateToCreateAccount = () => {
    history.push(`/register`);
  };

  const navigateToSignIn = () => {
    analyticsService.logEvent(AnalyticsEvent.SignInStarted, {
      currentUrl: '/guest-home',
      referringUrl: '/landing'
    });
    loginPress();
  };

  const handleMenuPress = (route: string) => {
    const covidScreeningStarted = route === '/guest-home/covid-screen/consent-and-agreement';
    if (covidScreeningStarted) {
      covidTestGetStartedClicked({ title: 'Guest Home', authentication_type: 'unauthenticated' });
    }
    if (route === '/find-a-doctor') {
      return window.open(Config.SCL_HEALTH_LINK_UNAUTH, '_blank');
    }
    if (route !== 'VisitPayModal') {
      return history.push(route);
    }

    analyticsService.logEvent(AnalyticsEvent.ProceedWithPaymentClicked, {
      currentUrl: '/guest-home',
      referringUrl: '/landing'
    });

    return setGoToVisitPay(true);
  };

  const renderMenuItem = (item: MenuItem) => (
    <MuiBox key={item.route}>
      <CursorMuiBox
        p={`${Spacing.large}px`}
        onClick={() => handleMenuPress(item.route)}
        flexDirection="row"
        display="flex"
        data-testid={convertToLowerKabobCase(item.title, '-menu-item')}
      >
        {item.icon === 'SNavBilling' ? (
          <Svg name={item.icon} fill={Color.black} height="40" width="40" />
        ) : (
          <Svg name={item.icon} height="40" width="40" />
        )}
        <MuiBox pl={Spacing.small}>
          <Typography tag="h4">{item.title}</Typography>
          <MuiTypography>{item.subTitle}</MuiTypography>
        </MuiBox>
        <MuiBox ml="auto" alignSelf="center">
          <Icon name="keyboard-arrow-right" size={24} color={Color.gray} />
        </MuiBox>
      </CursorMuiBox>
      <Divider />
    </MuiBox>
  );

  return (
    <>
      <GuestBenefitsModal open={modalOpen} handleToggle={toggleModal} />

      {guestHomeMenu.map(item => renderMenuItem(item))}

      <MuiContainer maxWidth="xs">
        <MuiBox
          mx={Spacing.small}
          mt={Spacing.medium}
          justifyContent="center"
          display="flex"
          flexDirection="column"
        >
          <MuiButton
            color="primary"
            variant="contained"
            data-testid="create-account"
            onClick={navigateToCreateAccount}
          >
            <MuiTypography>Create account</MuiTypography>
          </MuiButton>
          <Spacer size="small" />
          <MuiButton
            variant="text"
            color="primary"
            data-testid="sign-in"
            onClick={navigateToSignIn}
          >
            Sign in
          </MuiButton>
        </MuiBox>
      </MuiContainer>
      {goToVisitPay ? (
        <VisitPayWebView closePopup={setGoToVisitPay} referringUrl="/guest-home" />
      ) : null}
    </>
  );
};

const mapState = (state: RootState) => ({
  hideGuestBenefits: hideGuestBenefitsModalSelector(state)
});

const mapDispatch = (dispatch: Function) => ({
  hideGuestBenefitsModal: () => dispatch(hideGuestBenefitsModal()),
  loginPress: () => dispatch(handleLoginPress())
});

export default connect(mapState, mapDispatch)(GuestHomeScreen);
