import { MuiBox } from 'theme/material-ui';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import styled from 'styled-components';

import FlexBox from 'components/UI/Layout/FlexBox';
import Screen from 'components/UI/Layout/Screen';
import { Color } from 'modules/styles/colors';
import { ZIndex, Spacing, IconSize } from 'modules/styles/variables';
import IconEdit from 'assets/svgs/IconEdit';

export const AccountSelectScreen = styled(Screen)<{ isSmallScreen: boolean }>`
  background: ${Color.primary};
  z-index: ${ZIndex.navigation};
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
`;

export const AvatarContainer = styled(MuiBox)`
  opacity: 0.75;
`;

export const ScrollFlexbox = styled(FlexBox)`
  flex-direction: column;
  align-items: center;
  padding: ${Spacing.large}px 0;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none; /* Hide scrollbar for Chrome, Safari and Opera */
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

export const OpacityFlexbox = styled(FlexBox)`
  opacity: 0.8;
`;

export const EditComponent = styled(IconEdit)`
  position: absolute;
  right: 34px;
  top: 95px;
  color: ${Color.white};
  height: ${IconSize.base}px;
  width: ${IconSize.base}px;
`;

export const ArrowNew = styled(ArrowForwardIosIcon)`
  color: ${Color.white};
`;
