import React from 'react';

import VyncaBanner from 'assets/vynca/Vynca-web-thumb.png';

import { MuiTypography } from 'theme/material-ui';

import VyncaScreen from './screen';
import VyncaFaqs from './screen/FAQS';
import VyncaFaqsList from './screen/FAQSList';
import { RouteData } from 'screens/navigation';
import { HealthToolCardData } from 'components/HealthToolsCards/HealthToolCard';

import { FontSize, FontWeight } from 'modules/styles/variables';

export const vyncaCard: HealthToolCardData = {
  healthToolCardKey: 'vynca',
  description: (
    <MuiTypography>
      <MuiTypography display="inline">Be prepared for the unexpected with </MuiTypography>
      <MuiTypography fontWeight={FontWeight.bold} display="inline">
        Advance Care Planning
      </MuiTypography>
    </MuiTypography>
  ),
  path: '/u/health-tools/vynca',
  thumb: VyncaBanner
};

const vyncaScreenRouteData = {
  component: VyncaScreen,
  componentName: 'VyncaScreen',
  crumbLabel: 'Advance Care Planning',
  exact: true,
  includeBanner: true,
  includeCrumbs: true,
  includeDefaultLayout: true,
  path: vyncaCard.path,
  title: (
    <MuiTypography fontSize={FontSize.mediumHeading}>
      <MuiTypography fontSize={FontSize.mediumHeading}>Advance Care Planning </MuiTypography>
      <MuiTypography display="inline">provided by</MuiTypography>{' '}
      <MuiTypography
        fontSize={FontSize.mediumHeading}
        fontWeight={FontWeight.bold}
        display="inline"
      >
        Vynca
      </MuiTypography>
    </MuiTypography>
  )
};

const vyncaFaqRouteData: RouteData = {
  component: VyncaFaqs,
  componentName: 'VyncaFaqs',
  crumbLabel: 'FAQ',
  exact: true,
  includeBanner: true,
  includeCrumbs: true,
  includeDefaultLayout: true,
  path: `${vyncaCard.path}/faqs`,
  title: (
    <MuiTypography fontSize={FontSize.mediumHeading}>
      <MuiTypography fontSize={FontSize.mediumHeading}>Advance Care Planning </MuiTypography>
      <MuiTypography display="inline">provided by</MuiTypography>{' '}
      <MuiTypography
        fontSize={FontSize.mediumHeading}
        fontWeight={FontWeight.bold}
        display="inline"
      >
        Vynca
      </MuiTypography>
    </MuiTypography>
  )
};

const vyncaFaqListScreenRouteData: RouteData = {
  component: VyncaFaqsList,
  componentName: 'VyncaFaqsList',
  crumbLabel: 'FAQ',
  exact: true,
  includeBanner: true,
  includeCrumbs: true,
  includeDefaultLayout: true,
  path: `${vyncaCard.path}/faqslist/:id`,
  title: (
    <MuiTypography fontSize={FontSize.mediumHeading}>
      <MuiTypography fontSize={FontSize.mediumHeading}>Advance Care Planning </MuiTypography>
      <MuiTypography display="inline">provided by</MuiTypography>{' '}
      <MuiTypography
        fontSize={FontSize.mediumHeading}
        fontWeight={FontWeight.bold}
        display="inline"
      >
        Vynca
      </MuiTypography>
    </MuiTypography>
  )
};

export const vyncaRouteData: RouteData[] = [
  vyncaScreenRouteData,
  vyncaFaqRouteData,
  vyncaFaqListScreenRouteData
];
