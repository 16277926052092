import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { MuiContainer, MuiBox, MuiTypography } from 'theme/material-ui';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { RootState } from 'store/types';
import { Medication } from 'store/amwell/types';
import * as selectors from 'store/amwell/selectors';
import * as actions from 'store/amwell/actions';
import { Alert } from 'components/Alert';
import {
  ConnectCareDataLoader,
  ConnectCareStepAction,
  ConnectCareStepActions
} from 'components/ConnectCare';
import Divider from '@material-ui/core/Divider';
import Cancel from '@material-ui/icons/Cancel';
import IconButton from '@material-ui/core/IconButton';
import { medications as medicationConfirmations } from 'modules/constants/amwell';
import ConnectCareMedicationsSearch from './ConnectCareMedicationsSearch';
import { FormScreen } from '../styled';
import { RouteData } from '../types';
import { RouteComponentProps } from 'react-router';

export interface Props extends RouteComponentProps {
  getMedications: typeof actions.getMedications;
  updateMedications: typeof actions.updateMedications;
  medications: Medication[];
  loading: boolean;
  error: Error | null;
  actionError: Error | null;
}

export function ConnectCareMedications(props: Props) {
  const {
    history,
    getMedications,
    updateMedications,
    medications,
    loading,
    error,
    actionError
  } = props;

  useEffect(() => {
    if (actionError) {
      Alert.alert(
        medicationConfirmations.UPDATE_ERROR.title,
        medicationConfirmations.UPDATE_ERROR.subtitle
      );
      getMedications();
    }
  }, [actionError]);

  const handleRemoveMedication = (m: Medication) => {
    const meds = medications.filter(sm => sm.code !== m.code);
    updateMedications({ medications: meds });
  };

  const errorComponentProps = {
    message: medicationConfirmations.MEDICATIONS.ERROR,
    action: {
      label: 'Try Again?',
      onClick: getMedications
    }
  };

  const onNextClick = (nextStep?: RouteData) => {
    if (nextStep) {
      history.push(nextStep.path);
    }
  };

  const onPrevClick = (prevStep?: RouteData) => {
    if (prevStep) {
      history.push(prevStep.path);
    }
  };

  return (
    <>
      <FormScreen>
        <MuiContainer maxWidth="lg">
          <MuiBox my={2}>
            <MuiBox>
              <MuiTypography variant="h5">
                Please review the list of current medications
              </MuiTypography>
            </MuiBox>
            <ConnectCareDataLoader
              data={medications}
              loading={loading}
              error={error}
              errorComponentProps={errorComponentProps}
              renderNoData={() => (
                <MuiBox pb={2} pt={3}>
                  <MuiTypography>{medicationConfirmations.MEDICATIONS.NO_DATA}</MuiTypography>
                </MuiBox>
              )}
              renderData={data => (
                <List>
                  {data.map(m => (
                    <>
                      <ListItem key={m.code}>
                        <ListItemText data-testid="current-medication" primary={m.displayName} />
                        <ListItemIcon>
                          <IconButton
                            data-testid="remove-medication"
                            onClick={() => {
                              handleRemoveMedication(m);
                            }}
                          >
                            <Cancel />
                          </IconButton>
                        </ListItemIcon>
                      </ListItem>
                      <Divider />
                    </>
                  ))}
                </List>
              )}
            />
            <MuiBox pt={1}>
              <MuiBox pb={4}>
                <MuiTypography variant="h5">Add any new medications you are taking</MuiTypography>
              </MuiBox>
            </MuiBox>
            <ConnectCareMedicationsSearch />
          </MuiBox>
        </MuiContainer>
      </FormScreen>
      <ConnectCareStepActions>
        <ConnectCareStepAction onClick={onPrevClick} />
        <ConnectCareStepAction onClick={onNextClick} />
      </ConnectCareStepActions>
    </>
  );
}

const mapStateToProps = (state: RootState) => ({
  medications: selectors.medicationsDataSelector(state),
  loading: selectors.medicationsDataLoadingSelector(state),
  error: selectors.medicationsDataErrorSelector(state),
  actionError: selectors.medicationsActionErrorSelector(state)
});

const mapDispatch = {
  getMedications: actions.getMedications,
  updateMedications: actions.updateMedications
};

export default connect(mapStateToProps, mapDispatch)(ConnectCareMedications);
