import React from 'react';
import Divider from 'components/UI/Divider';
import { MuiBox, MuiButton, MuiLink } from 'theme/material-ui';
import Spacer from 'components/UI/Layout/Spacer';
import { Typography } from 'components/UI/Typography/styled';
import { Color } from 'modules/styles/colors';
import { Icon } from 'components/Icon';
import Paper from '@material-ui/core/Paper';
import TableHead from '@material-ui/core/TableHead';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import { FontSize, FontWeight } from 'modules/styles/variables';
import { formatDate } from 'modules/utils/DateUtils';
import { GreyViewBox, GreyViewBoldBox } from '../../styled';
import {
  APPLICATION_DETAIL_APPROVED,
  APPLICATION_DETAIL_APPROVED_DISCLAIMER_1,
  APPLICATION_DETAIL_APPROVED_DISCLAIMER_2,
  APPLICATION_DETAIL_APPROVED_TEXT
} from 'screens/Billing/constants';
import {
  FinancialAssistanceApplication as Application,
  EncounterListItem as Encounter
} from 'store/billing/types';
import { PATIENT_FINANCIAL_SERVICE_NUMBER } from 'screens/Billing/FinancialAssistance/FAApplication/constants';

import formatMoney from 'services/formatMoney';
import { Section } from 'screens/Billing/FinancialAssistance/FAStatusDetails/components/shared';
import { history } from 'lib/history';

const ApplicationSection = ({ application }: { application: Application }) => {
  return (
    <Section>
      <MuiBox display="flex" alignItems="flex-start" flexDirection="row">
        <GreyViewBox>Last Updated: </GreyViewBox>
        <Typography fontWeight={FontWeight.semibold}>
          {' '}
          {formatDate(new Date(application?.updateDate))}
        </Typography>
      </MuiBox>
      <Spacer size="small" />

      <MuiBox display="flex" alignItems="flex-start" flexDirection="row">
        <GreyViewBox>Total Considered Amount: </GreyViewBox>
        <Typography>{`$${formatMoney(application?.totalConsideredAmount ?? 0)}`}</Typography>
      </MuiBox>

      <MuiBox display="flex" alignItems="flex-start" flexDirection="row">
        <GreyViewBoldBox>Amount Approved by FA: </GreyViewBoldBox>
        <Typography fontWeight={FontWeight.semibold}>{`$${formatMoney(
          application?.adjustAmount ?? 0
        )}`}</Typography>
      </MuiBox>
      <Spacer size="small" />
    </Section>
  );
};

const renderEncounter = ({ encounter }: { encounter: Encounter }) => {
  return (
    <TableRow key={`${encounter?.location?.id}`}>
      <TableCell scope="encounter">
        {`${encounter?.location?.id}-${encounter?.accountNumber}`}{' '}
      </TableCell>
      <TableCell>{encounter?.billingDescription}</TableCell>
      <TableCell>
        <MuiBox
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
          flexDirection="row"
        >
          <Typography>{`${formatDate(new Date(encounter?.admitDate))}`}</Typography>
          <Icon name="ArrowForwardIos" color={Color.gray} size={15} />
        </MuiBox>
      </TableCell>
    </TableRow>
  );
};
const EncountersSection = ({ encounters }: { encounters: Encounter[] }) => {
  return (
    <>
      <Section>
        <Typography fontWeight={FontWeight.semibold} fontSize={FontSize.large}>
          Bills Considered for Financial Assistance
        </Typography>
        <Spacer size="small" />
        <Typography fontWeight={FontWeight.semibold}>{APPLICATION_DETAIL_APPROVED}</Typography>
      </Section>

      <Divider />
      {!!encounters.length && (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Application ID</TableCell>
                <TableCell>Provider</TableCell>
                <TableCell>Date</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {encounters.map((encounter: Encounter) => renderEncounter({ encounter }))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export const insertTelLink = (text, searchValue, phone) => {
  const telLink = `<a href='tel:${phone}'>${phone}</a>`;
  return text.replace(searchValue, telLink);
};

interface ApprovedProps {
  application: Application;
}
/**
 * Application Approved Status Detail  Screen
 */
const FAStatusDetailsApprovedScreen = ({ application }: ApprovedProps) => {
  const navigateToBillingRoot = () => {
    history.push(`/u/billing/bills`);
  };

  const encounters: Encounter[] = application?.encounters || [];

  return (
    <>
      <ApplicationSection application={application} />
      <Divider />
      <EncountersSection encounters={encounters} />
      <Spacer size="medium" />
      <Typography>{APPLICATION_DETAIL_APPROVED_TEXT}</Typography>
      <Spacer size="medium" />
      <MuiBox display="flex" alignItems="center" justifyContent="center" flexDirection="row">
        <MuiButton
          variant="contained"
          color="primary"
          onClick={() => {
            navigateToBillingRoot();
          }}
        >
          Pay outstanding bills
        </MuiButton>
      </MuiBox>
      <Spacer size="small" />
      <Section>
        <Typography>
          {' '}
          {APPLICATION_DETAIL_APPROVED_DISCLAIMER_1}{' '}
          <MuiLink data-testid="service-number" href={`tel: ${PATIENT_FINANCIAL_SERVICE_NUMBER}`}>
            {PATIENT_FINANCIAL_SERVICE_NUMBER}
          </MuiLink>
        </Typography>
        <Spacer size="small" />
        <Typography>
          {' '}
          {APPLICATION_DETAIL_APPROVED_DISCLAIMER_2}{' '}
          <MuiLink data-testid="service-number" href={`tel: ${PATIENT_FINANCIAL_SERVICE_NUMBER}`}>
            {PATIENT_FINANCIAL_SERVICE_NUMBER}
          </MuiLink>
        </Typography>
      </Section>
    </>
  );
};

export default FAStatusDetailsApprovedScreen;
