import React from 'react';
import { useHistory } from 'react-router-dom';

import { MuiContainer } from 'theme/material-ui';
import { CovidTitles } from 'lib/constants/covidScreening';
import { AnalyticsEvent } from 'services/AnalyticsService';
import { CovidReasonsBooleanEnums } from 'lib/covidScreening/types';
import { AuthType } from 'store/authentication/types';

import useNavigationAnalytics from 'hooks/useNavigationAnalytics';
import { ButtonsNavigation, CovidTestGuestFooter } from 'screens/CovidTestGuest/components';
import { StyledScreen } from 'components/common/PatientLoader/styled';

import { useCovidReasonsGuest } from './hooks';
import { CheckboxStatements, IntroductionText } from './components';
import CovidGuestGuard from '../../navigation/CovidGuestLeavingGuard';

export default function CovidReasonsGuest() {
  const history = useHistory();
  const covidReasonsPrevBtn = useNavigationAnalytics(AnalyticsEvent.CovidPrev);
  const navigateToPersonalInfo = '/guest-home/covid-screen/personal-information';
  const navigateToCurrentSymptoms = '/guest-home/covid-screen/guest-covid-symptoms';
  const { confirmReasonsGuest, requiredFields } = useCovidReasonsGuest();

  const disabledNextButton = requiredFields.covidReasonsChecked.length === 0;

  const handleSubmit = () => {
    confirmReasonsGuest();

    if (requiredFields.covidReasonsChecked.includes(CovidReasonsBooleanEnums.covidSymptoms))
      return history.push(navigateToCurrentSymptoms);

    return history.push(navigateToPersonalInfo);
  };

  const onCancelClick = () => {
    confirmReasonsGuest();

    covidReasonsPrevBtn({
      title: CovidTitles.REASON_FOR_REQUEST,
      authentication_type: AuthType.UNAUTHENTICATED
    });

    history.goBack();
  };

  return (
    <>
      <CovidGuestGuard />
      <StyledScreen>
        <MuiContainer maxWidth="lg">
          <IntroductionText data-testid="covid-reason-guest-introduction-text" />

          <CheckboxStatements />

          <ButtonsNavigation
            cancelTestId="covid-reasons-previous-button"
            nextTestId="covid-reasons-next-button"
            disabledNextButton={disabledNextButton}
            onCancelClick={onCancelClick}
            onNextClick={handleSubmit}
          />
        </MuiContainer>
      </StyledScreen>

      <CovidTestGuestFooter title={CovidTitles.REASON_FOR_REQUEST} />
    </>
  );
}
