import React, { useEffect } from 'react';
import { FlatList } from 'react-native';
import { ProviderSummary, MessagePool } from 'store/findProvider/types';
import { Spacing, FontSize, FontWeight } from 'modules/styles/variables';
import Spacer from 'components/UI/Layout/Spacer';
import Divider from 'components/UI/Divider';
import { messageComposePoolSelected } from 'store/messaging/actions';
import { useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { MessageComposeScreenNavigationParams } from 'store/messaging/types';
import { MuiBox, MuiContainer, MuiTypography, MuiPaper } from 'theme/material-ui';
import { ListItem } from 'components/UI/ListItem/ListItem';

interface PoolProviderParams {
  provider: ProviderSummary;
  messageParams?: MessageComposeScreenNavigationParams;
}

const MessagingPoolSelectScreen = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const params = location?.state || {};
  const { provider, messageParams } = params as PoolProviderParams;

  useEffect(() => {
    if (!provider) {
      history.push('/u/messages');
    }
  }, [provider]);

  const handleSelect = (messagingPool: MessagePool) => {
    dispatch(messageComposePoolSelected(messagingPool));
    history.push('/u/messages', { ...messageParams });
  };

  return (
    <MuiContainer data-testid="message-pool-screen">
      <MuiBox p={Spacing.small}>
        <MuiTypography fontWeight={FontWeight.bold} fontSize={FontSize.large}>
          Select A Location For {provider?.displayName}
        </MuiTypography>
      </MuiBox>

      <MuiPaper elevation={2}>
        <FlatList
          data={provider?.messagingPools}
          ItemSeparatorComponent={() => <Divider />}
          renderItem={({ item }) => {
            return (
              <ListItem
                key={item.groupDisplayName}
                icon="ChevronRight"
                title={item.groupDisplayName}
                onPress={() => handleSelect(item)}
              />
            );
          }}
        />
      </MuiPaper>
      <Spacer size="large" />
    </MuiContainer>
  );
};

export default MessagingPoolSelectScreen;
