import React from 'react';
import dayjs from 'dayjs';
import FlexBox from 'components/UI/Layout/FlexBox';
import { MuiTypography } from 'theme/material-ui';
import { FontWeight, IconSize } from 'modules/styles/variables';
import { LinkTo } from 'modules/types/common';
import { useHistory } from 'react-router-dom';
import { ListItem } from 'components/UI/ListItem/ListItem';

interface TestResultsRowItemProps {
  key?: string;
  date?: Date | string;
  resultName?: string;
  providerName?: string;
  to?: string | LinkTo;
  testID?: string;
  onClick?: () => void;
}

const TestResultsRowItem = ({
  key,
  date = '',
  resultName,
  providerName,
  to,
  testID,
  onClick
}: TestResultsRowItemProps) => {
  const history = useHistory();
  const formatedDate = dayjs(date).format('DD MMM YYYY');
  const handleOnClick = () => {
    history.push(to);
    onClick?.();
  };

  return (
    <ListItem
      key={key}
      testID={testID}
      accessibilityLabel="Tap to view test result"
      accessible
      title=""
      body={
        <FlexBox flexDirection="row" alignItems="center" fullWidth>
          <FlexBox alignItems="flex-start" style={{ marginRight: 20 }}>
            <MuiTypography fontWeight={FontWeight.regularbold}>{formatedDate}</MuiTypography>
          </FlexBox>
          <FlexBox alignItems="flex-start">
            <MuiTypography fontWeight={FontWeight.regularbold}>{resultName}</MuiTypography>
            <MuiTypography fontWeight={FontWeight.regularbold}>{providerName}</MuiTypography>
          </FlexBox>
        </FlexBox>
      }
      icon="ChevronRight"
      iconSize={IconSize.xmedium}
      onPress={handleOnClick}
      style={{ paddingTop: '15px', paddingBottom: '15px' }}
    />
  );
};

export default TestResultsRowItem;
