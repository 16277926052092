import React, { ComponentType, useEffect, useMemo, useState } from 'react';
import { Image } from 'react-native';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { RouteComponentProps } from 'react-router';

import awkwardDog from 'assets/awkwardDog.png';

import { MuiBox, MuiButton, MuiContainer, MuiGrid, useTheme } from 'theme/material-ui';

import Spinner from 'components/UI/Spinner/Spinner';
import DataLoader from 'components/UI/DataLoader/DataLoader';
import { Confirm } from 'components/ConfirmDialog/ConfirmDialog';

import { IconSize, Spacing } from 'modules/styles/variables';
import { providerSelectConstant } from 'modules/constants/booking';

import analyticsService, { AnalyticsEvent, AmplitudeEventData } from 'services/AnalyticsService';

import { RootState } from 'store/types';
import * as bookingTypes from 'store/booking/types';
import * as bookingActions from 'store/booking/actions';
import * as bookingSelectors from 'store/booking/selectors';
import * as findProviderTypes from 'store/findProvider/types';
import * as findProviderActions from 'store/findProvider/actions';
import * as findProviderConstants from 'store/findProvider/constants';
import * as findProviderSelectors from 'store/findProvider/selectors';
import * as timeSlotsSelectors from 'store/booking/appointment/timeSlots/selectors';
import * as npv2Selectors from 'store/booking/appointment/npv2/selectors';
import { geolocationAcceptedSelector } from 'store/geolocation/selectors';
import {
  currentLocationPathNameSelector,
  previousLocationPathNameSelector
} from 'store/router/selectors';

import { SpecialtyProviderPanel } from './SpecialtyProviderPanel';
import SpecialtyProviderFilters from './SpecialtyProviderFilters';
import { BookingPanel, StyledScreen } from 'screens/Booking/components/styled';
import { BookingLoading, BookingNoData } from 'screens/Booking/components/sharedComponents';
import { useCheckBookingInfoEffect } from 'screens/Booking/useCheckBookingInfoEffect';
import SpecialtyProviderActionsHeader from './SpecialtyProviderActionsHeader';
import SpecialtyProviderSortOptions from './SpecialtyProviderSortOptions';
import { convertToLowerKabobCase } from 'modules/utils/StringUtils';
import { AppointmentLocation, GroupedVisitType, Slots } from 'store/booking/types';
import { formatDateToIso8601 } from 'modules/utils/DateUtils';
import uniqBy from 'lodash/uniqBy';
import { Modal } from 'components/CustomModal';
import { SpinnerOverlay } from 'components/UI/Spinner/SpinnerModal';
import { Typography } from 'components/UI/Typography/styled';
import { useMediaQuery } from '@material-ui/core';
import { Nullable } from 'modules/types/common';

export interface Props extends RouteComponentProps {
  appointmentDetails: bookingTypes.AppointmentDetails;
  providers: findProviderTypes.ProviderSummary[];
  consumerPastProviders: findProviderTypes.ProviderSummary[];
  providersLoading: boolean;
  setDoctor: typeof bookingActions.setDoctor;
  searchFilter: findProviderTypes.ProviderSearchFilterState;
  activeFilters: findProviderTypes.ProviderSearchFilters;
  providerSearchMore: typeof findProviderActions.schedulingProviderSearchMore;
  isHydrating: boolean;
  rawProviderCount: number;
  setSort: typeof findProviderActions.setSortBy;
  providerSearch: typeof findProviderActions.schedulingProviderSearch;
  getSortBy: findProviderTypes.OptionType;
  setActiveProviderSearchFilter: typeof findProviderActions.setActiveProviderSearchFilter;
  currentProviderId: string;
  setGroupedVisitType: typeof bookingActions.setGroupedVisitType;
  setAppointmentLocation: typeof bookingActions.setAppointmentLocation;
  setAppointmentDate: typeof bookingActions.setAppointmentDate;
  setAppointmentTime: typeof bookingActions.setAppointmentTime;
  setAppointmentType: typeof bookingActions.setAppointmentType;
  setInitialTimeSlots: typeof bookingActions.setInitialTimeSlots;
  getGroupedVisitTypes: typeof bookingActions.getGroupedVisitTypes;
  lockTimeSlot: typeof bookingActions.lockTimeSlot;
  getLocationTimeSlots: typeof bookingActions.getLocationTimeSlots;
  initializeSSE: typeof bookingActions.initializeSSE;
  clearLocationTimeSlots: typeof bookingActions.clearLocationTimeSlots;
  getLocationTimeSlotsForNPV2: typeof bookingActions.getLocationTimeSlotsForNPV2;
  isSelectedSlotValid: boolean;
  isSelectedLocationSlotsLoaded: boolean;
  npv2TimeSlots: bookingTypes.TimeSlot[];
  geolocationStatus: boolean;
  currentUrl?: string;
  referringUrl?: string;
}

function countFilters(options: findProviderTypes.ProviderSearchFilters) {
  let countFilters = 4;
  if (options.filters.some(filter => filter.group === 'gender')) {
    countFilters -= 1;
  }

  if (options.filters.some(filter => filter.group === 'offersVideoVisits')) {
    countFilters -= 1;
  }

  return countFilters;
}

export function BookingSpecialtyProviderSelect({
  appointmentDetails,
  providers,
  consumerPastProviders,
  providersLoading,
  setDoctor,
  searchFilter,
  activeFilters,
  providerSearchMore,
  isHydrating,
  rawProviderCount,
  history,
  setSort,
  providerSearch,
  getSortBy,
  setActiveProviderSearchFilter,
  currentProviderId,
  setGroupedVisitType,
  setAppointmentLocation,
  setAppointmentDate,
  setAppointmentTime,
  setAppointmentType,
  clearLocationTimeSlots,
  setInitialTimeSlots,
  lockTimeSlot,
  getLocationTimeSlots,
  initializeSSE,
  getLocationTimeSlotsForNPV2,
  isSelectedSlotValid,
  isSelectedLocationSlotsLoaded,
  npv2TimeSlots,
  geolocationStatus
}: Props) {
  const eventData: AmplitudeEventData = {
    number_results_visible: providers.length,
    referringUrl: 'BookingSpecialtyOrProviderSelect',
    currentUrl: 'SpecialtyProviderSelect'
  };

  const hasMoreProviders = !!rawProviderCount;
  const currentSortBy = findProviderConstants.SORT_OPTIONS.find(
    item => item.value === getSortBy.value
  );

  const [showFiltersDrawer, setShowFiltersDrawer] = useState(false);
  const [showSortOptionsDrawer, setShowSortOptionsDrawer] = useState(false);
  const [displayFilters, setDisplayFilters] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isTimeout, setIsTimeout] = useState(false);
  const [isTimerFinished, setIsTimerFinished] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState<Slots>({} as Slots);
  const [provider, setProvider] = useState({} as findProviderTypes.ProviderSummary);
  const [apptLocation, setApptLocation] = useState({} as AppointmentLocation);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const panelBreakpointStyles = (index: number) => {
    const styles: React.CSSProperties = {};
    if (isSmallScreen) {
      styles.margin = 'auto';
    } else {
      styles.float = index % 2 ? 'left' : 'right';
    }

    return styles;
  };

  const callAnalytics = (
    provider: findProviderTypes.ProviderSummary,
    visitType: string | null,
    index: number
  ) => {
    const filterText = activeFilters.filters
      .filter(f => f.value)
      .map(f => f.group)
      .join(', ');
    const providerSpecialty = appointmentDetails.specialty
      ? provider.specialties.find(
          s => s.specialtyName === appointmentDetails.specialty?.nameLong
        ) || { isPrimary: false }
      : { isPrimary: false };
    const slot = index > 2 ? 'More' : `Slot ${index + 1}`;
    const helpUsedData: AmplitudeEventData = {
      referringUrl: 'BookingSpecialtyOrProviderSelect',
      currentUrl: 'SpecialtyProviderSelect',
      provider_specialty_is_primary: providerSpecialty?.isPrimary,
      provider: provider?.displayName,
      rating: provider?.rating?.overallRating,
      filters_used: filterText,
      sort_used: currentSortBy?.label || '',
      office_visit_time_selectionInOffice: visitType !== 'Video' ? slot : '',
      office_visit_time_selectionVideo: visitType === 'Video' ? slot : ''
    };
    analyticsService.logEvent(AnalyticsEvent.BookingProviderSelected, helpUsedData);
  };

  const callErrorAnalytics = () => {
    const helpUsedData: AmplitudeEventData = {
      referringUrl: 'BookingSpecialtyOrProviderSelect',
      currentUrl: 'SpecialtyProviderSelect',
      clinical_specialty_name: appointmentDetails.specialty?.nameLong || '',
      clinical_visit_name:
        appointmentDetails?.groupedVisitType?.visitTypes?.[0]?.synonymDisplay || '',
      facility_name: appointmentDetails?.appointmentLocation?.locationName || '',
      provider_name: provider.displayName,
      type_of_appointment: appointmentDetails?.groupedVisitType?.name || '',
      type_of_location: appointmentDetails?.appointmentType || '',
      provider_specialty: provider?.specialties?.[0].specialtyName || ''
    };
    analyticsService.logEvent(AnalyticsEvent.BookAppointmentErrorGoToCalendar, helpUsedData);
  };

  const filtersCount = useMemo(() => {
    return countFilters(activeFilters);
  }, [activeFilters]);

  useCheckBookingInfoEffect(appointmentDetails?.patient);

  useEffect(() => {
    setDisplayFilters(appointmentDetails?.specialty?.nameShort !== 'OB|OBG|GYN');
  }, []);

  useEffect(() => {
    clearLocationTimeSlots();
    initializeSSE();
    return (): void => setLoading(false);
  }, []);

  useEffect(() => {
    let clearTimer: number;
    if (loading) {
      clearTimer = setTimeout(() => {
        setIsTimerFinished(true);
      }, 10000); // it should be 10 seconds
    }
    return (): void => {
      clearTimeout(clearTimer);
    };
  }, [loading]);

  // Selects the earliest date that has slots.
  useEffect(() => {
    if (isTimerFinished && npv2TimeSlots?.length && appointmentDetails.appointmentDate) {
      setIsTimerFinished(false);
      if (isSelectedLocationSlotsLoaded && !isSelectedSlotValid) {
        setLoading(false);
        setIsTimeout(true);
      } else {
        checkLockAppt(provider, selectedSlot);
      }
    } else if (isTimerFinished && npv2TimeSlots?.length === 0) {
      checkLockAppt(provider, selectedSlot);
    }
  }, [npv2TimeSlots, isTimerFinished]);

  const onSlotError = () => {
    callErrorAnalytics();
    Modal.show({
      customIcon: (
        <Image
          accessibilityLabel="awkward-dog"
          testID="awkward-dog"
          style={{ width: 50, height: 50 }}
          source={{ uri: awkwardDog }}
        />
      ),
      backdrop: { skipBackdropClose: true },
      title: 'Uh-oh...',
      description: 'Someone else just picked this time slot.',
      buttons: {
        styles: { direction: 'row', justifyContent: 'center', alignItems: 'center' },
        items: [
          {
            label: 'View calendar',
            onClick: () => {
              setIsTimeout(false);
              checkSlotsCalendar(apptLocation);
            },
            styles: { paddingX: 20, height: 55, width: 120 }
          }
        ]
      },
      options: {
        onClose: () => {
          setIsTimeout(false);
        }
      }
    });
  };

  const checkLockAppt = async (
    provider: findProviderTypes.ProviderSummary,
    slot?: Slots
  ): Promise<void> => {
    const lockAppointmentSlotArgs = {
      slotStartTime: slot?.datetime,
      slotEndTime: slot?.end,
      providerId: provider?.cernerResourceId.toString()
    } as bookingTypes.LockSlotRequestPayload;

    const lockAppointmentResponse: AnyAction = await lockTimeSlot(lockAppointmentSlotArgs);
    if (lockAppointmentResponse.error || !lockAppointmentResponse.payload.data.success) {
      callErrorAnalytics();
      onSlotError();
    } else {
      history.push('/u/get-care-now/booking/reason-for-visit', {
        lockedSlot: lockAppointmentSlotArgs
      });
    }
    setLoading(false);
  };

  const checkSlotsCalendar = (appointmentLocation: AppointmentLocation): void => {
    let slots = [...appointmentLocation.slots];
    slots = uniqBy(slots, 'datetime');
    setInitialTimeSlots(slots);
    getLocationTimeSlots();

    history.push('/u/get-care-now/booking/date-and-time-select');
  };

  const handleSpecialtyProviderSelect = async (
    provider: findProviderTypes.ProviderSummary,
    appointmentLocation: AppointmentLocation,
    groupedVisitType: GroupedVisitType,
    slot: Nullable<Slots>,
    index: number
  ) => {
    const providerId = provider?.providerId || provider?.corpProvId;
    const isDifferentProvider = providerId !== currentProviderId;
    const isPastProvider = consumerPastProviders.some(
      pastProvider => pastProvider.corpProvId === provider.corpProvId
    );
    if (provider?.callClinicForNPV && !isPastProvider && isDifferentProvider) {
      Confirm.show(
        `${provider?.displayName}, ${providerSelectConstant.NO_ONLINE_NPV_SLOTS.title}`,
        null,
        null,
        providerSelectConstant.NO_ONLINE_NPV_SLOTS.severity,
        {
          text: providerSelectConstant.CALL_TO_SCHEDULE,
          onClick: () => history.push(`/u/provider/${providerId}`)
        },
        { text: 'Close' },
        { cancelable: false }
      );
    } else {
      setDoctor(provider);
      setGroupedVisitType(groupedVisitType);
      setAppointmentType(groupedVisitType.appointmentTypes[0]);
      setAppointmentLocation(appointmentLocation);

      if (slot) {
        setAppointmentDate(formatDateToIso8601(slot.datetime));
        setAppointmentTime(slot);

        setLoading(true);
        setSelectedSlot(slot);
        setProvider(provider);
        setApptLocation(appointmentLocation);

        getLocationTimeSlotsForNPV2();
      } else {
        checkSlotsCalendar(appointmentLocation);
      }
    }

    callAnalytics(provider, groupedVisitType?.appointmentTypes[0], index);
  };

  const getSpecialtyProviderIsSelected = (provider: findProviderTypes.ProviderSummary) => {
    if (!provider || !appointmentDetails.doctor) {
      return false;
    }

    return (
      appointmentDetails.doctor.cernerResourceId.toString() === provider.cernerResourceId.toString()
    );
  };

  const handleProviderSearch = () => {
    providerSearch({ isScheduling: true });
  };

  const handleViewMore = () => {
    providerSearchMore({ isScheduling: true });

    analyticsService.logEvent(AnalyticsEvent.ViewMoreProviderSearchResultsClicked, eventData);
  };

  if (isTimeout) {
    onSlotError();
  }

  if (loading) {
    return (
      <SpinnerOverlay
        direction="column"
        backgroundColor="#ffffff4c"
        position="fixed"
        isLoading={loading}
      >
        <MuiBox marginTop={3}>
          <Typography fontSize={20} fontWeight="600" textAlign="center">
            Loading Appointment
          </Typography>
        </MuiBox>
      </SpinnerOverlay>
    );
  }

  return (
    <>
      <StyledScreen>
        <SpecialtyProviderActionsHeader
          currentSortBy={currentSortBy}
          showSortOptions={() => setShowSortOptionsDrawer(true)}
          filtersCount={filtersCount}
          showFilters={() => setShowFiltersDrawer(true)}
          displayFilters={displayFilters}
        />
        <MuiContainer maxWidth="lg">
          <MuiBox my={3}>
            <MuiBox py={2}>
              <DataLoader
                data={providers}
                loading={providersLoading}
                renderLoading={() => <BookingLoading />}
                renderNoData={() => (
                  <BookingNoData
                    message={providerSelectConstant.GET_SPECIALTY_PROVIDER.NO_DATA.message}
                  />
                )}
                renderData={() => (
                  <MuiBox role="radiogroup">
                    <MuiGrid container direction="row" spacing={Spacing.xSmall}>
                      {providers.map((provider, index) => {
                        const hasSlots = provider.locations.some(location => {
                          if (location.bookingData?.length) {
                            return location.bookingData.some(vt => !!vt?.slots?.length);
                          }
                          return false;
                        });

                        if (!hasSlots) return null;

                        return (
                          <MuiGrid key={provider.providerId} item xs={12} md={12} lg={6} xl={4}>
                            <BookingPanel
                              tabindex="0"
                              role="radio"
                              elevation={2}
                              key={provider.cernerResourceId}
                              aria-checked={getSpecialtyProviderIsSelected(provider)}
                              data-testid={convertToLowerKabobCase(provider.displayName)}
                              hoverDisabled
                              style={{
                                padding: Spacing.mediumLarge,
                                maxWidth: '300px',
                                ...panelBreakpointStyles(index)
                              }}
                            >
                              <SpecialtyProviderPanel
                                provider={provider}
                                providerId={provider.providerId}
                                displayName={provider.displayName}
                                imageUrl={provider.profileImage}
                                specialties={provider.specialties}
                                selectedSpecialty={appointmentDetails?.specialty}
                                hasLocation={geolocationStatus}
                                isSelectHealth={provider.isSelectHealth}
                                onClick={handleSpecialtyProviderSelect}
                                activeFilters={activeFilters}
                              />
                            </BookingPanel>
                          </MuiGrid>
                        );
                      })}
                    </MuiGrid>
                    {hasMoreProviders ? (
                      <MuiBox mx="auto" mt={2} maxWidth="250px">
                        <MuiButton
                          data-testid="booking-specialty-provider-select-view-more"
                          fullWidth
                          color="primary"
                          variant="contained"
                          onClick={() => handleViewMore()}
                          disabled={isHydrating}
                          endIcon={isHydrating ? <Spinner size={IconSize.small} /> : null}
                        >
                          View more
                        </MuiButton>
                      </MuiBox>
                    ) : null}
                  </MuiBox>
                )}
              />
              <SpecialtyProviderSortOptions
                open={showSortOptionsDrawer}
                onClose={() => setShowSortOptionsDrawer(false)}
                sortOptions={findProviderConstants.SORT_OPTIONS}
                defaultSortOption={getSortBy}
                setSort={setSort}
                search={() => handleProviderSearch()}
              />
              <SpecialtyProviderFilters
                appointment={appointmentDetails}
                open={showFiltersDrawer}
                onClose={() => setShowFiltersDrawer(false)}
                searchFilter={searchFilter}
                activeFilters={activeFilters}
                setActiveProviderSearchFilter={setActiveProviderSearchFilter}
                search={() => handleProviderSearch()}
              />
            </MuiBox>
          </MuiBox>
        </MuiContainer>
      </StyledScreen>
    </>
  );
}

const mapStateToProps = (state: RootState) => ({
  appointmentDetails: bookingSelectors.appointmentDetailsSelector(state),
  providers: findProviderSelectors.providerSearchResultSelector(state),
  consumerPastProviders: findProviderSelectors.selectedConsumerPastDoctorsSelector(state),
  providersLoading: findProviderSelectors.providerSearchFetchingSelector(state),
  searchFilter: findProviderSelectors.searchFilterSelector(state),
  activeFilters: findProviderSelectors.activeFiltersSelector(state),
  isHydrating: findProviderSelectors.providerSearchHydratingSelector(state),
  rawProviderCount: findProviderSelectors.providerSearchCurrentTotal(state),
  getSortBy: findProviderSelectors.getSortBy(state),
  currentProviderId: bookingSelectors.currentProviderIdSelector(state),
  isSelectedSlotValid: timeSlotsSelectors.validateAppointmentTimeSelector(state),
  isSelectedLocationSlotsLoaded: timeSlotsSelectors.isLocationSlotsLoadedSelector(state),
  npv2TimeSlots: npv2Selectors.npv2SelectedProviderLocationSlotsSelector(state),
  geolocationStatus: geolocationAcceptedSelector(state),
  currentUrl: currentLocationPathNameSelector(state),
  referringUrl: previousLocationPathNameSelector(state)
});

const mapDispatchToProps = {
  setDoctor: bookingActions.setDoctor,
  providerSearchMore: findProviderActions.schedulingProviderSearchMore,
  setSort: findProviderActions.setSortBy,
  providerSearch: findProviderActions.schedulingProviderSearch,
  setActiveProviderSearchFilter: findProviderActions.setActiveProviderSearchFilter,
  setGroupedVisitType: bookingActions.setGroupedVisitType,
  setAppointmentLocation: bookingActions.setAppointmentLocation,
  setAppointmentDate: bookingActions.setAppointmentDate,
  setAppointmentTime: bookingActions.setAppointmentTime,
  setAppointmentType: bookingActions.setAppointmentType,
  setInitialTimeSlots: bookingActions.setInitialTimeSlots,
  getGroupedVisitTypes: bookingActions.getGroupedVisitTypes,
  lockTimeSlot: bookingActions.lockTimeSlot,
  getLocationTimeSlots: bookingActions.getLocationTimeSlots,
  initializeSSE: bookingActions.initializeSSE,
  clearLocationTimeSlots: bookingActions.clearLocationTimeSlots,
  getLocationTimeSlotsForNPV2: bookingActions.getLocationTimeSlotsForNPV2
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BookingSpecialtyProviderSelect as ComponentType);
