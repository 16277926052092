import React, { useMemo } from 'react';
import { MuiGrid, MuiTypography, MuiButton, MuiBox } from 'theme/material-ui';
import { FontWeight, IconSize } from 'modules/styles/variables';
import { ProviderSearchInterface } from 'lib/hooks/ProviderSearch/useProviderSearch';
import {
  AzureFacet,
  AzureFilter,
  ProviderFacetGroups,
  ProviderFilterGroups
} from 'store/providerSearch/types';
import { Svg } from 'components/UI/Svg';
import { Color } from 'modules/styles/colors';

export interface Props {
  search: ProviderSearchInterface['search'];
  options: ProviderSearchInterface['options'];
}

function ProviderSearchListFiltersGroup({ options, search }: Props) {
  const chips = useMemo(() => {
    const result = [];

    if (options.filters) {
      result.push(...options.filters.filter(({ value }) => value));
    }

    if (options.facets) {
      result.push(...options.facets);
    }

    if (options.locationText) {
      result.push({
        title: options.locationText,
        group: 'locationText',
        value: ''
      });
    }

    return result;
  }, [options]);

  const onDelete = (
    chip:
      | AzureFilter
      | AzureFacet
      | {
          title: string;
          group: string;
          value: string;
        }
  ) => {
    if (chip.group in ProviderFacetGroups) {
      search({
        ...options,
        facets: options.facets?.filter(
          facet => facet.group !== chip.group || facet.value !== chip.value
        )
      });
    }

    if (chip.group in ProviderFilterGroups) {
      search({
        ...options,
        filters: options.filters?.map(filter =>
          filter.group === chip.group ? { ...filter, value: false } : filter
        )
      });
    }

    if (chip.group === 'locationText') {
      search({
        ...options,
        locationText: ''
      });
    }
  };

  return (
    <MuiGrid container direction="row" alignItems="center" spacing={1}>
      <MuiGrid item xs={12}>
        <MuiBox display="flex" flexDirection="row" alignItems="center" justifyContent="flex-start">
          <MuiTypography variant="body2" fontWeight={FontWeight.semibold}>
            Filtering by:
          </MuiTypography>
        </MuiBox>
      </MuiGrid>
      {chips.map(chip => (
        <MuiGrid item key={chip.title}>
          <MuiButton
            size="large"
            variant="outlined"
            fullWidth
            onClick={() => onDelete(chip)}
            color="primary"
            endIcon={<Svg color={Color.black} name="CloseCircleOutline" size={IconSize.base} />}
          >
            {chip.title}
          </MuiButton>
        </MuiGrid>
      ))}
      {chips.length ? (
        <MuiGrid item>
          <MuiButton
            color="primary"
            onClick={() =>
              search({
                ...options,
                page: 0,
                locationText: '',
                filters: [],
                facets: []
              })
            }
          >
            Clear all
          </MuiButton>
        </MuiGrid>
      ) : null}
    </MuiGrid>
  );
}

export default ProviderSearchListFiltersGroup;
