import React from 'react';

export default {
  svg: (
    <g>
      <path d="M183.5 152.2v8.7h18.3v36.4h-18.3v8.8h26.6v-53.9zM146.1 197.3h-19v-36.4h19v-8.7h-27.3v53.9h27.3zM167.9 198.7v5.1h-6v-5.1c-7.4-.8-12.3-4.5-13.2-11.2l7.1-.2c1 4.1 4.4 6.1 9.2 6.1 4.3 0 7.8-2.3 7.8-5.7 0-3.2-1.9-4.8-6.1-5.8l-6.8-1.6c-6-1.4-9.9-4.8-10-10.6-.1-6.4 5-10.9 12.2-11.9v-5.6h6v5.6c6.9 1.1 11.1 5.9 11.2 11.5l-6.9.2c-.6-4.1-2.7-6.5-7.3-6.5-4.5 0-8 2.2-8 5.8 0 2.6 1.5 3.9 6.3 5.2l6.6 1.7c7.1 1.8 10 6.1 10 11.8.1 6-4.7 10.3-12.1 11.2z" />
      <path d="M53.9 31.9v6.2c0 8 5.7 14.2 13.5 14.5 7.7.3 14-5.6 14.4-13.6.1-2.2 0-4.5 0-6.9h16.6v6c0 8.3 5.8 14.2 13.8 14.5 7.7.2 13.9-6 14.1-14.2.1-2.1 0-4.1 0-6.3H143c0 2.3-.1 4.6 0 6.9.2 7.4 5.2 12.7 12.7 13.6 6.4.7 12.9-3.8 14.6-10.3.4-1.6.4-3.2.4-4.9.1-1.7 0-3.4 0-5.1 15.4-2.8 32.1 7.4 37.1 23 1.3 4 1.9 8.3 1.9 12.5.2 24.1.1 48.3.1 72.4v3.5h-15.4V79.3h-164v70.8c0 11.8 8 19.8 19.9 19.8 16.7.1 33.4 0 50.1 0h3v15.4c-.8 0-1.6.1-2.5.1H50.8c-17.5 0-31.1-10.4-35.1-26.9-.5-2.2-.8-4.4-.8-6.7-.1-28.8-.1-57.6 0-86.4C15 47.3 30.6 32 48.8 31.9h5.1z" />
      <path d="M71.7 144v18.4H53.3V144h18.4zM86.6 105.9V87.6H105v18.3H86.6zM119.9 105.9V87.6h18.3v18.3h-18.3zM153.1 105.9V87.7h18.4v18.2h-18.4zM171.6 115.8v18.3h-18.4v-18.3h18.4zM138.3 134.1h-18.4v-18.3h18.4v18.3zM86.7 134.1v-18.3H105v18.3H86.7zM71.6 134.1H53.3v-18.3h18.3v18.3zM71.6 106H53.3V87.7h18.3V106zM86.6 144h18.9c-.7 6.2-1.3 12.2-2 18.4H86.6V144zM163.4 32.1c0 2.2.1 4.3 0 6.5-.1 3.8-2.7 6.4-6.4 6.4s-6.3-2.5-6.4-6.4c-.1-4.4-.1-8.8 0-13.2.1-3.8 2.7-6.4 6.4-6.3 3.7 0 6.3 2.5 6.4 6.4v6.6zM118.8 32.1v6.5c-.1 3.4-2.4 6-5.6 6.3-3.7.3-6.7-1.8-6.9-5.4-.3-5-.3-10 0-15 .2-3.6 3.2-5.7 6.8-5.5 3.3.2 5.7 2.9 5.8 6.4-.1 2.2-.1 4.5-.1 6.7zM74.1 32c0 2.3.1 4.7 0 7-.1 3.5-2.7 5.9-6.2 5.9s-6.2-2.3-6.3-5.8c-.1-4.8-.1-9.7 0-14.5.1-3.5 2.9-5.7 6.4-5.6 3.4.1 5.9 2.4 6.1 5.8.1 1.2 0 2.4 0 3.6.1 1.2.1 2.4 0 3.6z" />
      <path d="M40.8 88.8c-2.1 0-3.7 1.7-3.7 3.7s1.7 3.7 3.7 3.7c2.1 0 3.7-1.7 3.7-3.7s-1.6-3.7-3.7-3.7zM40.8 57.5c-1.9 0-3.4 1.5-3.4 3.4v20.8c0 1.9 1.5 3.4 3.4 3.4s3.4-1.5 3.4-3.4V60.9c0-1.9-1.5-3.4-3.4-3.4z" />
    </g>
  ),
  viewBox: '0 0 300 240'
};
