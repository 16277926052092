import React from 'react';

export default {
  svg: (
    <g fill="none">
      <path
        d="M14.2022 23.3786L14.2129 23.3856C15.157 24.0619 16.2779 24.3556 17.3905 24.2183C18.503 24.081 19.5407 23.521 20.3321 22.6306L21.0201 21.8566C21.3274 21.5105 21.5 21.0413 21.5 20.5521C21.5 20.063 21.3274 19.5938 21.0201 19.2476L18.1214 15.9886C17.8137 15.643 17.3967 15.4488 16.9618 15.4488C16.527 15.4488 16.1099 15.643 15.8022 15.9886C15.6501 16.16 15.4694 16.296 15.2705 16.3887C15.0716 16.4815 14.8584 16.5292 14.6431 16.5292C14.4278 16.5292 14.2146 16.4815 14.0157 16.3887C13.8168 16.296 13.6361 16.16 13.484 15.9886L8.84566 10.7696C8.53838 10.4235 8.36578 9.95433 8.36578 9.46514C8.36578 8.97595 8.53838 8.50677 8.84566 8.16064C8.998 7.98947 9.11886 7.7862 9.20131 7.56245C9.28377 7.3387 9.32621 7.09886 9.32621 6.85664C9.32621 6.61442 9.28377 6.37458 9.20131 6.15083C9.11886 5.92708 8.998 5.72381 8.84566 5.55264L5.94783 2.28864C5.64016 1.94295 5.2231 1.74878 4.78825 1.74878C4.35341 1.74878 3.93635 1.94295 3.62868 2.28864L2.94066 3.06264C2.14938 3.95302 1.65158 5.12028 1.52941 6.3718C1.40724 7.62332 1.668 8.88429 2.26865 9.94664L2.27576 9.95864C5.45219 15.2485 9.50076 19.804 14.2022 23.3786Z"
        stroke="#4A00E2"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  ),
  viewBox: '0 0 23 26'
};
