import React from 'react';
import { Formik } from 'formik';
import { useHistory } from 'react-router-dom';

import { MuiBox, MuiMenuItem, MuiTypography } from 'theme/material-ui';

import Footer from 'components/common/Footer';
import RequiredLabel from 'components/UI/Labels/RequiredLabel';
import FlexBox from 'components/UI/Layout/FlexBox';
import Spacer from 'components/UI/Layout/Spacer';
import SmallText from 'components/UI/Typography/SmallText';

import { CovidTitles } from 'lib/constants/covidScreening';
import { useLanguageSwitcher } from 'lib/hooks/useLanguageSwitcher';
import { translations } from 'lib/constants/translations/screens/covidGuest/insuranceNotFound';
import {
  ButtonsNavigation_translations,
  CovidTestGuestFooter_translations,
  relationTranslations
} from 'lib/constants/translations/components/index';

import { Grid } from '@material-ui/core';
import Container from '@material-ui/core/Container';

import { getRelationTranslated } from 'modules/constants/covidScreening/personalInfo.ts';
import {
  StateOptionsType,
  RelationShipToSubscriber
} from 'modules/constants/covidScreening/personalInfo';
import { LOCATION_STATES } from 'modules/constants/LocationStates';
import { convertToLowerKabobCase } from 'modules/utils/StringUtils';

import CustomTextField from 'screens/Register/FormElements/CustomTextField';

import { AnalyticsEvent } from 'services/AnalyticsService';
import { AuthType } from 'store/authentication/types';

import { StyledSelect } from './styled';
import { InsuranceInformationData } from './types';
import { InsuranceValidateSchema } from './validation';
import useNavigationAnalytics from 'hooks/useNavigationAnalytics';
import { ButtonsNavigation } from '../components';
import { useCovidQuestionnaire } from '../hooks';
import CovidGuestGuard from '../navigation/CovidGuestLeavingGuard';

const translationsToRender = {
  en: {
    ...translations.en,
    ...ButtonsNavigation_translations.en,
    ...CovidTestGuestFooter_translations.en,
    ...relationTranslations.en
  },
  es: {
    ...translations.es,
    ...ButtonsNavigation_translations.es,
    ...CovidTestGuestFooter_translations.es,
    ...relationTranslations.es
  }
};

export default function InsuranceInformation() {
  const history = useHistory();
  const { questionnaire, updateQuestionnaire } = useCovidQuestionnaire();
  const screenText = useLanguageSwitcher(translationsToRender);

  const initialValues = {
    Health_Plan_Name: questionnaire.Health_Plan_Name ?? '',
    Health_Plan_ID: questionnaire.Health_Plan_ID ?? '',
    MEMBER_NUMBER: questionnaire.MEMBER_NUMBER ?? '',
    GROUP_NUMBER: questionnaire.GROUP_NUMBER ?? '',
    Health_Plan_Address_line_1: questionnaire.Health_Plan_Address_line_1 ?? '',
    HealthPlan_City: questionnaire.HealthPlan_City ?? '',
    HealthPlan_state: questionnaire.HealthPlan_state ?? '',
    HealthPlan_zip_code: questionnaire.HealthPlan_zip_code ?? '',
    Q_Patient_relationship_to_subscriber: questionnaire.Q_Patient_relationship_to_subscriber ?? '',
    SUBSCRIBER_FIRST_NAME_ON_CARD: questionnaire.SUBSCRIBER_FIRST_NAME_ON_CARD ?? '',
    SUBSCRIBER_MIDDLE_NAME_ON_CARD: questionnaire.SUBSCRIBER_MIDDLE_NAME_ON_CARD ?? '',
    SUBSCRIBER_LAST_NAME_ON_CARD: questionnaire.SUBSCRIBER_LAST_NAME_ON_CARD ?? ''
  };

  const covidScreeningNextBtn = useNavigationAnalytics(AnalyticsEvent.CovidNext, {
    title: CovidTitles.EXISTING_INSURANCE_NOT_FOUND,
    authentication_type: AuthType.UNAUTHENTICATED
  });

  const covidScreeningPreviousBtn = useNavigationAnalytics(AnalyticsEvent.CovidPrev, {
    title: CovidTitles.EXISTING_INSURANCE_NOT_FOUND,
    authentication_type: AuthType.UNAUTHENTICATED
  });

  const handleSubmit = (values: InsuranceInformationData) => {
    const newQuestionnaire = { ...questionnaire, ...values };
    newQuestionnaire.Health_Plan_ID = newQuestionnaire.Health_Plan_Name;

    updateQuestionnaire(newQuestionnaire);
    covidScreeningNextBtn();

    if (values?.Q_Patient_relationship_to_subscriber === 'Self')
      return history.push('/guest-home/covid-screen/guest-covid-insurance-upload');

    return history.push('/guest-home/covid-screen/guest-subscriber-information');
  };

  const onCancelClick = values => {
    const newQuestionnaire = { ...questionnaire, ...values };
    updateQuestionnaire(newQuestionnaire);
    covidScreeningPreviousBtn();
    history.goBack();
  };

  return (
    <>
      <CovidGuestGuard />
      <Container maxWidth="md">
        <Spacer size="medium" />
        <MuiBox my={2}>
          <MuiTypography align="left" variant="h6" fontWeight="bold">
            {screenText.instructions}
          </MuiTypography>
        </MuiBox>
        <Formik
          enableReinitialize="true"
          validateOnMount
          initialValues={initialValues}
          validationSchema={InsuranceValidateSchema(screenText)}
        >
          {({ values, errors, isValid, touched, handleChange, setFieldTouched }) => {
            return (
              <>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <RequiredLabel labelText={screenText.label_name} />
                    <CustomTextField
                      name="Health_Plan_Name"
                      variant="outlined"
                      value={values?.Health_Plan_Name}
                      onChange={handleChange('Health_Plan_Name')}
                      data-testid="health-plan-name"
                      required
                      maxLength={100}
                      placeholder={screenText.placeholder_plan_name}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <RequiredLabel labelText={screenText.label_id} />
                    <CustomTextField
                      name="Health_Plan_ID"
                      variant="outlined"
                      value={values?.Health_Plan_ID}
                      onChange={handleChange('Health_Plan_ID')}
                      data-testid="health-plan-id"
                      placeholder={screenText.placeholder_plan_id}
                      maxLength={20}
                      required
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <RequiredLabel labelText={screenText.label_number} />
                    <CustomTextField
                      name="MEMBER_NUMBER"
                      variant="outlined"
                      value={values?.MEMBER_NUMBER}
                      onChange={handleChange('MEMBER_NUMBER')}
                      data-testid="member-number"
                      required
                      placeholder={screenText.placeholder_plan_number}
                      maxLength={20}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <RequiredLabel hideIndicator labelText={screenText.label_group} />
                    <CustomTextField
                      name="GROUP_NUMBER"
                      variant="outlined"
                      placeholder={screenText.placeholder_plan_group}
                      data-testid="group-number"
                      value={values?.GROUP_NUMBER}
                      onChange={handleChange('GROUP_NUMBER')}
                      maxLength={20}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <RequiredLabel labelText={screenText.label_street} />
                    <CustomTextField
                      name="Health_Plan_Address_line_1"
                      variant="outlined"
                      value={values?.Health_Plan_Address_line_1}
                      onChange={handleChange('Health_Plan_Address_line_1')}
                      data-testid="insurance-street"
                      onBlur={() => {
                        setFieldTouched('Health_Plan_Address_line_1');
                      }}
                      placeholder={screenText.placeholder_address}
                      required
                      maxLength={30}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <RequiredLabel labelText={screenText.label_city} />
                    <CustomTextField
                      name="HealthPlan_City"
                      variant="outlined"
                      value={values?.HealthPlan_City}
                      onChange={handleChange('HealthPlan_City')}
                      data-testid="insurance-city"
                      placeholder={screenText.placeholder_city}
                      maxLength={34}
                    />
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <FlexBox width="100%">
                      <RequiredLabel labelText={screenText.label_state} />
                      <StyledSelect
                        variant="outlined"
                        value={values?.HealthPlan_state}
                        data-testid="insurance-state"
                        onChange={handleChange('HealthPlan_state')}
                      >
                        {LOCATION_STATES.map((option: StateOptionsType) => (
                          <MuiMenuItem
                            style={{ whiteSpace: 'normal' }}
                            data-testid={convertToLowerKabobCase(option.value, '-insurance-state')}
                            key={option.label}
                            value={option.value}
                          >
                            {option.label}
                          </MuiMenuItem>
                        ))}
                      </StyledSelect>
                      {errors?.HealthPlan_state && touched.HealthPlan_state ? (
                        <SmallText color="red">{errors?.HealthPlan_state}</SmallText>
                      ) : null}
                    </FlexBox>
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <RequiredLabel labelText={screenText.label_zip} />
                    <CustomTextField
                      name="HealthPlan_zip_code"
                      data-testid="insurance-zip_code"
                      maxLength={5}
                      variant="outlined"
                      value={values?.HealthPlan_zip_code}
                      onChange={handleChange('HealthPlan_zip_code')}
                      mask="zip"
                      placeholder={screenText.placeholder_zip}
                      required
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <FlexBox width="100%">
                      <RequiredLabel labelText={screenText.label_relationship} />
                      <StyledSelect
                        variant="outlined"
                        value={values?.Q_Patient_relationship_to_subscriber}
                        onChange={handleChange('Q_Patient_relationship_to_subscriber')}
                        data-testid="insurance-subscriber-relationship"
                      >
                        {RelationShipToSubscriber.map((option: StateOptionsType) => (
                          <MuiMenuItem
                            style={{ whiteSpace: 'normal' }}
                            data-testid={convertToLowerKabobCase(
                              option.value,
                              '-insurance-relationship-to-subscriber'
                            )}
                            key={option.label}
                            value={option.value}
                          >
                            {getRelationTranslated(option.label, screenText)}
                          </MuiMenuItem>
                        ))}
                      </StyledSelect>
                      {errors?.Q_Patient_relationship_to_subscriber &&
                      touched.Q_Patient_relationship_to_subscriber ? (
                        <SmallText color="red">
                          {errors?.Q_Patient_relationship_to_subscriber}
                        </SmallText>
                      ) : null}
                    </FlexBox>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <RequiredLabel labelText={screenText.label_first_name} />
                    <CustomTextField
                      name="SUBSCRIBER_FIRST_NAME_ON_CARD"
                      variant="outlined"
                      placeholder={screenText.placeholder_first_name}
                      value={values?.SUBSCRIBER_FIRST_NAME_ON_CARD}
                      data-testid="insurance-first-name"
                      onChange={handleChange('SUBSCRIBER_FIRST_NAME_ON_CARD')}
                      maxLength={16}
                      required
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <RequiredLabel hideIndicator labelText={screenText.label_middle_name} />
                    <CustomTextField
                      name="SUBSCRIBER_MIDDLE_NAME_ON_CARD"
                      variant="outlined"
                      placeholder={screenText.placeholder_middle_name}
                      value={values?.SUBSCRIBER_MIDDLE_NAME_ON_CARD}
                      data-testid="insurance-middle-name"
                      onChange={handleChange('SUBSCRIBER_MIDDLE_NAME_ON_CARD')}
                      maxLength={16}
                      required
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <RequiredLabel labelText={screenText.label_last_name} />
                    <CustomTextField
                      name="SUBSCRIBER_LAST_NAME_ON_CARD"
                      variant="outlined"
                      placeholder={screenText.placeholder_last_name}
                      value={values?.SUBSCRIBER_LAST_NAME_ON_CARD}
                      data-testid="insurance-last-name"
                      onChange={handleChange('SUBSCRIBER_LAST_NAME_ON_CARD')}
                      maxLength={26}
                      required
                    />
                  </Grid>
                </Grid>

                <Spacer size="medium" />

                <ButtonsNavigation
                  cancelTestId="insurance-info-previous-btn"
                  nextTestId="insurance-info-next-btn"
                  disabledNextButton={!isValid}
                  onCancelClick={() => onCancelClick(values)}
                  onNextClick={() => handleSubmit(values)}
                />
              </>
            );
          }}
        </Formik>

        <Footer
          headerText={screenText.covidInfo}
          link={screenText.CovidPatientSelectHelpline}
          linkName={screenText.CovidPatientSelectHelpline}
          helplineHours={screenText.CovidHelplineHours}
          title={CovidTitles.EXISTING_INSURANCE_NOT_FOUND}
          authenticated={false}
        />
      </Container>
    </>
  );
}
