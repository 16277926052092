import React from 'react';
import Box from 'components/UI/Layout/Box';
import Typography from 'components/UI/Typography';
import { Color } from 'modules/styles/colors';
import { Spacing } from 'modules/styles/variables';

interface HealthRecordsListItemPlaceholderProps {
  onRetry?: () => void;
  message: string;
}

export const HealthRecordsListItemPlaceholder = ({
  onRetry,
  message
}: HealthRecordsListItemPlaceholderProps) => (
  <Box spacing={Spacing.large}>
    <Typography data-testid="no-health-record-data" textAlign="center">
      {message}
    </Typography>

    {onRetry && (
      <Typography textAlign="center" color={Color.link} onPress={onRetry}>
        Try again?
      </Typography>
    )}
  </Box>
);

HealthRecordsListItemPlaceholder.defaultProps = {
  message: 'We failed to retrieve your records at this time'
};
