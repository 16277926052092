import React, { useState } from 'react';
import { oc } from 'ts-optchain';
import { history } from 'lib/history';
import { MuiIcon, MuiTypography } from 'theme/material-ui';
import { ProviderSearchInterface } from 'lib/hooks/ProviderSearch/useProviderSearch';
import { FiltersKey, Suggestion } from 'lib/hooks/ProviderSearch/types';
import { updateFacetsOnSpecialtySelect } from 'lib/hooks/ProviderSearch/QuickLinks/utils';
import { SuggestionTypes, ProviderSearchSuggestion } from 'store/providerSearch/types';
import * as constants from 'store/providerSearch/constants';
import { SearchOption } from 'components/AutoComplete/BasicAutoComplete';
import {
  AutocompleteInputChangeReason,
  RenderGroupParams,
  RenderInputParams
} from '@material-ui/lab';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { ListSubheader } from '@material-ui/core';
import {
  Autocomplete,
  AutocompleteInput,
  SearchButton,
  StyledListBox,
  StyledMuiBox
} from './styled';

export interface Props {
  change: ProviderSearchInterface['change'];
  search: (params?: {
    recentSearch: boolean;
    options: ProviderSearchInterface['options'];
    expandedFilters: FiltersKey[];
  }) => void;
  value: ProviderSearchInterface['query'];
  suggestions: ProviderSearchInterface['suggestions'];
  quickLinks: ProviderSearchInterface['quickLinks'];
}

const pruneSuggestions = (suggestions: Props['suggestions']) => {
  // limit each suggestion type to certain number in dropdown
  const limit = 5;
  const categories = new Map();

  return suggestions?.reduce((acc, suggestion) => {
    const { category } = suggestion;
    const entry = categories.get(category) || 0;

    if (!entry || entry < limit) {
      categories.set(category, entry + 1);
      return [...acc, suggestion];
    }

    return acc;
  }, [] as Suggestion[]);
};

const registerAutoCompleteInput = ({
  onUserInput,
  onUserSearchKey,
  onUserSearch
}: {
  onUserInput: React.ChangeEventHandler<HTMLInputElement>;
  onUserSearchKey: React.KeyboardEventHandler;
  onUserSearch: React.MouseEventHandler<HTMLButtonElement>;
}) => (params: RenderInputParams): React.ReactNode => {
  params.InputProps.endAdornment = (
    <>
      {params.InputProps.endAdornment}
      <SearchButton onClick={onUserSearch}>
        <MuiIcon>search</MuiIcon>
      </SearchButton>
    </>
  );

  return (
    <AutocompleteInput
      {...params}
      onChange={onUserInput}
      onKeyPress={onUserSearchKey}
      placeholder={constants.searchPlaceholder}
      data-testid="input-field"
    />
  );
};

const registerAutoCompleteOptions = ({ change, search }: Partial<Props>) => {
  const onClickSuggestion = (option: ProviderSearchSuggestion) => {
    const params = oc(option).params({});
    const isQuickLink = Object.values(SuggestionTypes).includes(option.category);

    const recentSearch = oc(params).recentSearch(false);
    const options = oc(params).options({});
    const expandedFilters = oc(params).defaultExpandedFilters([]);

    if (option.onClick) {
      option.onClick();
    } else if (option.providerId) {
      search?.();
      history.push(`/u/provider/${option.providerId}`);
    } else if (option.locationId) {
      search?.({
        recentSearch: true,
        options: {
          facets: [{ title: option.value, group: 'intermountainClinics', value: option.locationId }]
        },
        expandedFilters
      });
    } else {
      if (!isQuickLink) change?.(option.value || '');
      search?.({
        recentSearch,
        options: updateFacetsOnSpecialtySelect(options, option),
        expandedFilters
      });
    }
  };

  return (option: SearchOption) => (
    <StyledMuiBox onClick={() => onClickSuggestion(option)}>
      <MuiTypography>{option.value}</MuiTypography>
      <KeyboardArrowRightIcon />
    </StyledMuiBox>
  );
};

const registerAutoCompleteOptionsGroup = () => (params: RenderGroupParams) => {
  return (
    <React.Fragment key={params.key}>
      <ListSubheader component="div">{params.group}</ListSubheader>
      {params.children}
    </React.Fragment>
  );
};

function ProviderSearchBar({ change, search, value, suggestions, quickLinks }: Props) {
  const [isOpen, setIsOpen] = useState(false);

  const resetAutoComplete = () => {
    change('');
  };

  const onUserInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    change(e.target.value);
  };

  const onUserSearchKey = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') search();
  };

  const onUserSearch = () => {
    search();
  };

  const onAutoCompleteChange = (
    _0: React.ChangeEvent<{}>,
    _1: string,
    reason: AutocompleteInputChangeReason
  ) => {
    if (reason === 'clear') {
      resetAutoComplete();
    }
  };

  const onAutoCompleteFocus = () => {
    if (!isOpen) {
      setIsOpen(true);
      if (!value) {
        resetAutoComplete();
      }
    }
  };

  const onAutoCompleteBlur = () => {
    if (isOpen) {
      setIsOpen(false);
    }
  };

  const AutoCompleteOptionsGroup = registerAutoCompleteOptionsGroup();
  const AutoCompleteOptions = registerAutoCompleteOptions({ change, search });
  const AutoCompleteInput = registerAutoCompleteInput({
    onUserInput,
    onUserSearchKey,
    onUserSearch
  });

  const options = value ? pruneSuggestions(suggestions) : quickLinks;

  return (
    <Autocomplete
      freeSolo
      isControlled
      open={isOpen}
      value={value}
      options={options || []}
      onFocus={onAutoCompleteFocus}
      onBlur={onAutoCompleteBlur}
      renderInput={AutoCompleteInput}
      renderOption={AutoCompleteOptions}
      renderGroup={AutoCompleteOptionsGroup}
      getOptionDisabled={option => !!option?.params?.disabled}
      groupBy={option => option.category}
      noOptionsText={constants.noOptionsText}
      onChange={onAutoCompleteChange}
      ListboxComponent={StyledListBox}
    />
  );
}

export default ProviderSearchBar;
