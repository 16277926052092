import styled from 'styled-components';
import DefaultText from 'components/UI/Typography/DefaultText';
import { Color } from 'modules/styles/colors';

export const Container = styled.div<{ maxWidth: number }>`
  max-width: ${props => props.maxWidth};
`;

export const InvertedDefaultText = styled(DefaultText)`
  color: ${Color.foreColor};
`;

export const DateText = styled(DefaultText)`
  color: ${Color.foreColor};
  font-size: 12px;
`;

export const Label = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 8px 8px;
`;

export const DateLabel = styled.div<{ maxWidth: number }>`
  max-width: ${props => props.maxWidth};
  display: flex;
  align-items: center;
  padding: 0px 4px 4px;
`;

export const Circle = styled.div<{
  maxWidth: number;
  backgroundColor?: string;
  marginTop?: number;
  marginLeft?: number;
  paddingLeft?: number;
}>`
  flex-direction: column;
  height: ${props => props.maxWidth}px;
  width: ${props => props.maxWidth}px;
  border-radius: ${props => props.maxWidth / 2}px;
  margin-top: ${props => props.marginTop}px;
  margin-left: ${props => props.marginLeft}px;
  padding-top: ${props => props.marginTop - 10}px;
  padding-left: ${props => props.paddingLeft}px;

  border: 6px solid ${Color.foreColor};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.backgroundColor || 'transparent'};
`;
export const BloodPressureTitle = styled.div<{ fontColor?: string; fontSize?: string }>`
  font-size: ${props => props.fontSize};
  line-height: 1;
  text-align: left;
  color: ${props => (props.fontColor ? props.fontColor : 'white')};
`;

export const SBPValueContainer = styled.div<{ maxWidth: number }>`
  width: ${props => props.maxWidth}px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
`;

export const Divider = styled.div`
  width: 84px;
  border: solid ${Color.foreColor};
  border-width: 1px;
  margin-top: 4px;
`;

export const DBPValueContainer = styled.div<{ maxWidth: number }>`
  width: ${props => props.maxWidth};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;

export const BPValue = styled(DefaultText)<{ fontSize: number; fontColor?: string }>`
  font-size: ${props => props.fontSize};
  line-height: ${props => props.fontSize + 0.5}px;
  color: ${props => (props.fontColor ? props.fontColor : Color.foreColor)};
`;

export const ChartAside = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
`;

export const BloodPressure = styled.div`
  text-align: left;
  color: #fff;
  margin-right: 1rem;
  font-size: 0.85rem;

  @media (min-width: 48em) {
    .BloodPressure {
      font-size: 1rem;
    }
  }
`;

export const BloodPressureInner = styled.div<{ maxWidth: number }>`
  border: 6px solid #fff;
  height: ${props => props.maxWidth};
  width: ${props => props.maxWidth};
  border-radius: 1000px;
  padding: 1.5em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
`;

export const BloodPressureInnerRight = styled.div`
  border: 6px solid #fff;
  border-radius: 1000px;
  width: 8.125em;
  height: 8.125em;
  padding: 1.5em;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (min-width: 48em) {
    .BloodPressureInner {
      padding: 1rem;
    }
  }
`;

export const BloodPressureLatestInner = styled.div`
  padding-left: ${props => props.paddingLeft}px;
`;

export const BloodPressureLatest = styled.div<{ fontSize?: string }>`
  text-align: left;
  color: #fff;
  margin-right: 1rem;
  font-size: ${props => props.fontSize};
  height: 9rem;
  width: 9rem;
`;

export const BloodPressureAverage = styled.div`
  font-size: 0.75rem;
  text-align: left;
  color: #fff;
  margin-right: 1rem;
  margin-top: 1.75em;
  background: #29abe2;

  @media (min-width: 48em) {
    .BloodPressureAverage {
      font-size: 0.85em;
    }
  }
`;

export const BloodPressureAverageInner = styled.div`
  background: #29abe2;
`;

export const BloodPressureSys = styled.div`
  display: flex;
  flex-direction: row;
`;

export const BloodPressureSysAverage = styled.div`
  display: inline-block;
  border-bottom: 1px solid #fff;
  font-size: 2em;
`;

export const BloodPressureDi = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 1.1em;
  display: inline;
`;

export const BloodPressureDiAverage = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 1.1em;
  display: inline;
  font-size: 2em;
`;

export const BloodPressureTitleAverage = styled.span`
  font-size: 1.25em;
  line-height: 1;
  flex: 1 1 auto;
  text-align: right;
  color: ${Color.foreColor};
`;

export const BloodPressureLabel = styled.span`
  font-size: 1em;
  color: ${Color.foreColor};
  align-self: flex-end;
  padding-left: 6.1px;
`;

export const BloodPressureLabelAverage = styled.span`
  font-size: 0.5em;
  color: ${Color.foreColor};
`;
export const BloodPressureNote = styled.span`
  font-size: 0.75em;
  color: ${Color.foreColor};
  text-align: center;
  letter-spacing: 0.3px;
  width: ${props => props.width};
`;
export const BloodPressureBelowCircleNote = styled.span`
  color: ${Color.foreColor};
  align-self: center;
  text-align: center;
  height: 26px;
  font-size: 9.48px;
  letter-spacing: 0.32px;
  line-height: 12.8px;
  margin-top: 10px;
`;
