import { AppName, getAppName } from 'modules/utils/ConfigUtils';

export const BOOKING_PAGE_TITLE = 'Book Appointment';

export const pastAppointmentsSortOptions = [
  { label: 'Oldest', value: 'asc' },
  { label: 'Newest', value: 'desc' }
];

export const redirectExternalSchedulingUrl = 'https://intermountainhealthcare.org/find-a-doctor/';

export const InsuranceDisclaimerText = `Please confirm with your insurance provider that this visit is covered in-network. If not covered, you may cancel your appointment through ${getAppName(
  AppName.base,
  'the',
  AppName.suffix2
)} or ${getAppName(AppName.tertiary)}.`;
