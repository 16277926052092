import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { MuiBox, MuiTypography, MuiContainer } from 'theme/material-ui';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';

import DiabetesBanner from 'assets/HealthTools/diabetes-web.png';

import { StyledScreen } from 'screens/HealthTools/Cards';
import HealthToolsPictureBanner from 'components/HealthToolsCards/HealthToolsPictureBanner';
import Spacer from 'components/UI/Layout/Spacer';
import FlexBox from 'components/UI/Layout/FlexBox';
import Spinner from 'components/UI/Spinner/Spinner';
import { VideoPlayer } from 'components/VideoPlayer';
import { Alert } from 'components/Alert';
import {
  CardVideo,
  LanguageDropDown,
  PlayIcon,
  PrediabetesCardsVideos,
  VideoBanner
} from './styled';
import { getPrediabetesVideo } from 'store/healthTools/actions';
import { RootState, RootDispatch } from 'store/types';

import { FontSize, FontWeight, IconSize } from 'modules/styles/variables';
import { usePreventDiabetes } from '../hooks';
import { languagesOptions, translations } from '../constants';
import { prediabetesVideosSelector } from 'store/healthTools/selectors';

import { Color } from 'modules/styles/colors';
import { VideoData } from 'store/healthTools/types';

interface DataVideo {
  id: number;
  source: string;
  title: string;
  lastPauseTime: number;
}

interface PreDiabetesProps {
  dispatch: RootDispatch;
  getVideoData: typeof getPrediabetesVideo;
  videoData?: VideoData;
}

function PreventDiabetesScreen({ getVideoData, videoData }: PreDiabetesProps) {
  const [showModal, setShowModal] = useState(false);
  const [fullScreen, setFullScreen] = useState(false);
  const {
    onExitedVideo,
    onPauseVideo,
    onSelectLanguage,
    onSelectVideo,
    onVideoCompleted
  } = usePreventDiabetes();
  const [currentLanguage, setCurrentLanguage] = useState<'es' | 'en'>(languagesOptions[1].value);
  const [dataVideo, setDataVideo] = useState<DataVideo>({
    id: 0,
    source: '',
    title: '',
    lastPauseTime: 0
  });

  useEffect(() => {
    const language = currentLanguage === 'en' ? 'english' : 'spanish';
    const inputParams = {
      language,
      platform: 'web'
    };
    getVideoData(inputParams);
  }, [currentLanguage]);

  const onChangeLanguage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const currentLanguage = e.target.value as 'es' | 'en';

    onSelectLanguage(currentLanguage);
    setCurrentLanguage(currentLanguage);
  };

  const openVideo = (title: string, link?: string, id: number) => {
    if (!link) {
      return Alert.alert(
        'Unable to play video. Please try again and, if the problem continues, try refreshing the page'
      );
    }

    onSelectVideo(title, currentLanguage, id);
    setDataVideo(prevState => ({
      ...prevState,
      id,
      source: link,
      title
    }));

    return setShowModal(true);
  };

  const onClickPauseVideo = (event: React.SyntheticEvent<HTMLVideoElement, Event>) => {
    const currentTimeVideo = event.currentTarget.currentTime;
    const videoDuration = event.currentTarget.duration;

    onPauseVideo({
      id: dataVideo.id,
      language: currentLanguage,
      pauseTime: currentTimeVideo,
      startTime: dataVideo.lastPauseTime,
      totalDuration: videoDuration,
      videoTitle: dataVideo.title
    });

    setDataVideo(prevState => ({
      ...prevState,
      lastPauseTime: currentTimeVideo
    }));
  };

  const onCloseVideo = () => {
    const prediabetesVideoPlayer = document.getElementById(
      'prediabetes-video-player'
    ) as HTMLVideoElement;

    const { currentTime } = prediabetesVideoPlayer;

    onExitedVideo({
      id: dataVideo.id,
      language: currentLanguage,
      currentTime,
      totalDuration: prediabetesVideoPlayer.duration,
      videoTitle: dataVideo.title
    });

    setDataVideo(prevState => ({
      ...prevState,
      lastPauseTime: 0
    }));

    setShowModal(false);
  };

  const onTimeChange = () => {
    const prediabetesVideoPlayer = document.getElementById(
      'prediabetes-video-player'
    ) as HTMLVideoElement;

    const { currentTime } = prediabetesVideoPlayer;
    onVideoCompleted({
      id: dataVideo.id,
      language: currentLanguage,
      currentTime,
      totalDuration: prediabetesVideoPlayer.duration,
      videoTitle: dataVideo.title,
      source: dataVideo.source
    });
  };

  return (
    <StyledScreen>
      <MuiBox mb={3} display="center" data-testid="prevent-diabetes">
        <HealthToolsPictureBanner
          pictureBannerSrc={DiabetesBanner}
          titleBanner={translations[currentLanguage].imageTitle}
          dataTestId="prevent-diabetes-card-description"
        />

        <Spacer size="medium" />

        <MuiContainer maxWidth="lg">
          <FlexBox alignItems="center">
            <MuiTypography>
              <MuiTypography fontSize={FontSize.large} fontWeight={FontWeight.regularbold}>
                {translations[currentLanguage].paragraph}
              </MuiTypography>
            </MuiTypography>
          </FlexBox>
        </MuiContainer>

        <MuiContainer maxWidth="xl">
          <FlexBox alignItems="normal">
            <MuiTypography>
              <Spacer size="medium" />
              <MuiTypography fontSize={FontSize.large} fontWeight={FontWeight.regularbold}>
                Language / Idioma
              </MuiTypography>
            </MuiTypography>

            <MuiTypography>
              <LanguageDropDown
                items={languagesOptions}
                onChange={onChangeLanguage}
                value={currentLanguage}
                data-testid="language-idioma-drop-down"
              />
            </MuiTypography>
          </FlexBox>

          <Spacer size="medium" />

          {videoData?.error ? (
            <MuiBox flexGrow={1}>
              <MuiTypography
                fontSize={FontSize.large}
                fontWeight={FontWeight.semibold}
                color={Color.accent}
              >
                Video failed to load. Please refresh and try again.
              </MuiTypography>
            </MuiBox>
          ) : videoData?.isFetching ? (
            <MuiBox textAlign="center" paddingTop={3}>
              <Spinner size={IconSize.xLarge} data-testid="prevent-diabetes-videos-loading" />
            </MuiBox>
          ) : (
            <PrediabetesCardsVideos data-testid="prediabetes-video-cards">
              {videoData?.prediabetesVideosData?.map((card, index) => {
                return (
                  <CardVideo onClick={() => openVideo(card.title, card.videoURL, index + 1)}>
                    <img alt={`Prediabetes video ${card.title}`} src={card.imageURL} />

                    <VideoBanner>
                      <p>{card.title}</p>
                      <PlayIcon />
                    </VideoBanner>
                  </CardVideo>
                );
              })}
            </PrediabetesCardsVideos>
          )}
        </MuiContainer>
      </MuiBox>

      <Dialog
        aria-labelledby="Video-player-container"
        aria-describedby="Play-selected-video"
        disableBackdropClick
        fullScreen={fullScreen}
        maxWidth="lg"
        onClose={onCloseVideo}
        open={showModal}
      >
        <>
          <MuiBox p={1} display="flex" alignItems="center">
            <MuiBox flexGrow={1}>
              <MuiTypography
                fontSize={FontSize.large}
                fontWeight={FontWeight.semibold}
                color={Color.black}
              >
                {dataVideo.title}
              </MuiTypography>
            </MuiBox>

            <MuiBox>
              <IconButton>
                {!fullScreen ? (
                  <FullscreenIcon onClick={() => setFullScreen(true)}></FullscreenIcon>
                ) : (
                  <FullscreenExitIcon onClick={() => setFullScreen(false)}></FullscreenExitIcon>
                )}
              </IconButton>
              <IconButton onClick={onCloseVideo}>
                <CloseIcon />
              </IconButton>
            </MuiBox>
          </MuiBox>

          <VideoPlayer
            autoPlay
            id="prediabetes-video-player"
            onPause={onClickPauseVideo}
            seekable
            showSubtitle={false}
            src={dataVideo.source}
            onTimeChange={onTimeChange}
            onEnd={onCloseVideo}
          />
        </>
      </Dialog>
    </StyledScreen>
  );
}

const mapStateToProps = (state: RootState) => ({
  videoData: prediabetesVideosSelector(state)
});

const mapDispatchToProps = {
  getVideoData: getPrediabetesVideo
};

export default connect(mapStateToProps, mapDispatchToProps)(PreventDiabetesScreen);
