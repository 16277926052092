import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { FinancialAssistanceApplication as Application } from 'store/billing/types';
import { ApplicationStatus } from '../FAStatus/constant';
import DeclinedStatus from './components/declinedStatus';
import IncompleteStatus from './components/incompleteStatus';
import PendingStatus from './components/pendingStatus';
import { GreyViewHeader } from './components/shared';
import Container from '@material-ui/core/Container';
import { NavigationScreenProps } from 'screens/navigation';
import { getFinancialAssistanceApplicationById } from 'store/billing/actions';
import Spinner from 'components/UI/Spinner/Spinner';
import Typography from 'components/UI/Typography';
import { MuiBox } from 'theme/material-ui';
import FAStatusDetailsApprovedScreen from './components/approvedStatus';
import { Box } from '@material-ui/core';
import { Spacing } from 'modules/styles/variables';
import { Color } from 'modules/styles/colors';
import { Section } from 'screens/Billing/FinancialAssistance/FAStatusDetails/components/shared';
import { getFinancialAssistanceApplicationSelector } from 'store/billing/selectors';

const NoApp = ({ id }: { id: string }) => {
  return <Typography>{`No application found for id: ${id}`}</Typography>;
};

const LoadingIndicator = () => {
  return (
    <MuiBox display="flex" alignItems="center" justifyContent="center">
      <Spinner />
    </MuiBox>
  );
};

const ErrorMessageSection = () => {
  return (
    <Section>
      <MuiBox
        display="flex"
        alignItems="center"
        justifyContent="center"
        data-testid="message-error"
      >
        <Typography color={Color.red}>There was an issue fetching application details</Typography>
      </MuiBox>
    </Section>
  );
};

const FAStatusDetailsScreen = (props: NavigationScreenProps) => {
  const { id } = props.match.params;
  const dispatch = useDispatch();

  const loc = useLocation();
  const locState: { application?: Application } = loc.state || {};

  let app: Application = locState.application || ({} as Application);

  useEffect(() => {
    dispatch(getFinancialAssistanceApplicationById(id));
  }, [id]);

  const { data, error, isFetching: loading = false } = useSelector(
    getFinancialAssistanceApplicationSelector
  );

  if (data) {
    app = data;
  }

  const status = app.status || ApplicationStatus.PENDING;

  return (
    <>
      {!loading && !error && app && <GreyViewHeader application={app} />}
      <Container>
        <Box hSpacing={Spacing.large}>
          {error && <ErrorMessageSection />}

          {!loading && !app && <NoApp id={id} />}

          {loading && <LoadingIndicator />}

          {loading ||
            (app.id && (
              <Box
                hSpacing={Spacing.large}
                vSpacing={Spacing.medium}
                flex={1}
                backgroundColor={Color.foreColor}
                width="100%"
              >
                {!loading && app.id && (
                  <>
                    {[ApplicationStatus.DECLINED, ApplicationStatus.CANCELLED].includes(
                      status as ApplicationStatus
                    ) && <DeclinedStatus application={app} />}
                    {status === ApplicationStatus.PENDING && <PendingStatus application={app} />}
                    {status === ApplicationStatus.INCOMPLETE && (
                      <IncompleteStatus application={app} />
                    )}
                    {status === ApplicationStatus.APPROVED && (
                      <FAStatusDetailsApprovedScreen application={app} />
                    )}
                  </>
                )}
              </Box>
            ))}
        </Box>
      </Container>
    </>
  );
};

export default FAStatusDetailsScreen;
