import styled from 'styled-components';
import { MuiBox } from 'theme/material-ui';

export const CenteredContainer = styled(MuiBox)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
`;

export const InnerContainer = styled(MuiBox)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
