import React from 'react';
import { Color } from 'modules/styles/colors';
import { SvgProps } from 'components/UI/Svg/Svg';

export default (props: SvgProps | undefined) => {
  return {
    svg: (
      <g>
        <path
          fill={
            props?.active
              ? 'url(#a)'
              : props?.fill
              ? props.fill
              : props?.disabled
              ? Color.grayHue5
              : Color.primary
          }
          fillRule="evenodd"
          d="M12 2.859a.74.74 0 0 0-.526.22l-.004.003-10.19 10.19a.75.75 0 0 1-1.06-1.06L10.406 2.023l.002-.001a2.24 2.24 0 0 1 3.186.001l2.156 2.157V3.07a.75.75 0 0 1 .75-.75h3.75a.75.75 0 0 1 .75.75v6.36l2.78 2.78a.75.75 0 1 1-1.06 1.06l-6.75-6.749-3.444-3.444a.74.74 0 0 0-.526-.22Zm5.25 2.822 2.25 2.25V3.82h-2.25v1.86Zm-13.5 7.81a.75.75 0 0 1 .75.75v7.5H9v-5.25a2.25 2.25 0 0 1 2.25-2.25h1.5a2.25 2.25 0 0 1 2.25 2.25v5.25h4.5v-7.5a.75.75 0 0 1 1.5 0v8.25a.75.75 0 0 1-.75.75h-6a.75.75 0 0 1-.75-.75v-6a.75.75 0 0 0-.75-.75h-1.5a.75.75 0 0 0-.75.75v6a.75.75 0 0 1-.75.75h-6a.75.75 0 0 1-.75-.75v-8.25a.75.75 0 0 1 .75-.75Z"
          clipRule="evenodd"
        />
        <defs>
          <linearGradient
            id="a"
            x1={0}
            x2={24}
            y1={10.999}
            y2={10.999}
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0.084} stopColor="#FF0048" />
            <stop offset={0.386} stopColor="#DF36FF" />
            <stop offset={0.98} stopColor="#4A00E2" />
          </linearGradient>
        </defs>
      </g>
    ),
    viewBox: '0 0 24 25'
  };
};
