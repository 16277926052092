import React from 'react';
import { Formik, Form, FormikConfig, FormikValues } from 'formik';

import { ConnectCareFormTextField } from './ConnectCareFormTextField';
import { ConnectCareFormDatePicker } from './ConnectCareFormDatePicker';
import { ConnectCareFormRadioGroup } from './ConnectCareFormRadioGroup';
import { ConnectCareFormSelect } from './ConnectCareFormSelect';
import { ConnectCareFormRatingStars } from './ConnectCareFormRatingStars';
import { ConnectCareFormEffect } from './ConnectCareFormEffect';

export type ConnectCareFormProps<T> = FormikConfig<T>;

function ConnectCareForm<T = FormikValues>({ children, ...rest }: ConnectCareFormProps<T>) {
  return (
    <Formik {...rest}>
      {props =>
        children ? (
          <Form>
            <ConnectCareFormEffect />
            {typeof children === 'function' ? children(props) : children}
          </Form>
        ) : null
      }
    </Formik>
  );
}

ConnectCareForm.TextField = ConnectCareFormTextField;
ConnectCareForm.DatePicker = ConnectCareFormDatePicker;
ConnectCareForm.RadioGroup = ConnectCareFormRadioGroup;
ConnectCareForm.Select = ConnectCareFormSelect;
ConnectCareForm.RatingStars = ConnectCareFormRatingStars;

export default ConnectCareForm;
