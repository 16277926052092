import { Alert } from 'components/Alert';
import { Icon } from 'components/Icon';
import Box from 'components/UI/Layout/Box';
import FlexBox from 'components/UI/Layout/FlexBox';
import Spacer from 'components/UI/Layout/Spacer';
import {
  currentLocationPathNameSelector,
  previousLocationPathNameSelector
} from 'store/router/selectors';
import analyticsService, { AmplitudeEventData, AnalyticsEvent } from 'services/AnalyticsService';
import { SpinnerOverlay } from 'components/UI/Spinner/SpinnerModal';
import Typography from 'components/UI/Typography';
import { Formik } from 'formik';
import { Color } from 'modules/styles/colors';
import {
  FontSize,
  FontWeight,
  IconSize,
  Spacing,
  Elevation,
  ZIndex
} from 'modules/styles/variables';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Dispatch } from 'redux';
import { currentAccountConsumerFullNameSelector } from 'store/account/selectors';
import { shareAllergiesDataSelector } from 'store/medicalHistory/allergies/selectors';
import { shareImmunizationsDataSelector } from 'store/medicalHistory/immunizations/selectors';
import { shareProblemsDataSelector } from 'store/medicalHistory/problems/selectors';
import { shareProceduresDataSelector } from 'store/medicalHistory/procedures/selectors';
import { shareMedicalHistoryDataSelector } from 'store/medicalHistory/selectors';
import { shareSocialHistoryDataSelector } from 'store/medicalHistory/socialHistory/selectors';
import { shareHealthRecordSummaryDataSelector } from 'store/healthRecords/selectors';
import { shareMedicationsDataSelector } from 'store/medications/selectors';
import {
  shareImagingResultsDataSelector,
  shareLabResultsDataSelector,
  shareMicrobiologyResultsDataSelector,
  sharePathologyResultsDataSelector
} from 'store/testResults/selectors';
import { getTransmitAction } from 'store/transmit';
import { transmitSendingSelector } from 'store/transmit/selectors';
import { RootState } from 'store/types';
import { visitSummaryShareCcdDataSelector } from 'store/visitSummary/selectors';
import { shareVitalsDataSelector } from 'store/vitals/selectors';
import {
  MuiBox,
  MuiButton,
  MuiCheckbox,
  MuiFormControlLabel,
  MuiContainer,
  MuiDialog,
  MuiInput,
  MuiSwitch
} from 'theme/material-ui';
import { USPhoneMaskRegex, cleanPhone } from 'modules/utils/PhoneUtils';
import CustomTextField from 'screens/Register/FormElements/CustomTextField';
import * as Yup from 'yup';

interface TransmitFormData {
  email: string;
  confirmEmail: string;
  phone: string;
  encrypt: boolean;
  message: string;
  risksAccepted: boolean;
}

interface TransmitLandingScreenProps extends RouteComponentProps {
  onSubmit: (data: TransmitFormData) => void;
  dispatch: Dispatch;
  currentUserFullName: string;
  isSending: boolean;
  currentUrl?: string | undefined;
  referringUrl?: string | undefined;
}

const TransmitSchema = Yup.object().shape<TransmitFormData>({
  email: Yup.string()
    .email('Value must be a valid email')
    .required('Email is required'),
  confirmEmail: Yup.string()
    .oneOf([Yup.ref('email')], 'Email address entered must match')
    .required('Confirm Email is required'),
  phone: Yup.string()
    .matches(USPhoneMaskRegex, 'Phone must match format (XXX) XXX-XXXX')
    .required('Phone Number is required'),
  encrypt: Yup.bool(),
  message: Yup.string().required('Message is required'),
  risksAccepted: Yup.bool()
});

const initialTransmitValues: TransmitFormData = {
  email: '',
  confirmEmail: '',
  phone: '',
  encrypt: true,
  message: '',
  risksAccepted: false
};

export const TransmitLandingScreen = (props: TransmitLandingScreenProps) => {
  const source = props.location?.state?.source;
  const payload = props.location?.state?.payload;
  const returnBackTo = props.location?.state?.returnBackTo;
  const returnBackToParams = props.location?.state?.returnBackToParams;
  const initialMessage = props.location?.state?.message || '';
  const { history, currentUrl, referringUrl } = props;
  /*
   NOTE: The createSubject function is temporary - For Pilot/MVP release - the clients will send a subject to the transmit endpoints for: Medications, Vitals & Medical History. The deprecation will occur after release and can be tracked via https://jira.co.ihc.com/browse/DFD-9912. The story to remove support for the deprecated version of the endpoint is https://jira.co.ihc.com/browse/DFD-9922.
  */

  const createSubject = (currentUserFullName: string, isSecure: boolean) => {
    const subject = `Patient Health Information for ${currentUserFullName}`;

    return isSecure ? `PHI: ${subject}` : subject;
  };

  const handleClose = () => {
    if (returnBackTo) {
      return history.push(returnBackTo, { ...returnBackToParams });
    }
    return history.push('/u/messages');
  };
  const handleAnalytics = (values, status) => {
    analyticsService.logEvent(AnalyticsEvent.ShareRecordsSent, {
      encrypted_message: values.encrypt ? 'Yes' : 'No',
      email_status: status,
      currentUrl,
      referringUrl
    } as AmplitudeEventData);
  };
  const handleSend = async (values: TransmitFormData) => {
    const phone = cleanPhone(values.phone);
    const subject = createSubject(props.currentUserFullName, values.encrypt);
    if (props.onSubmit) {
      props.onSubmit(values);
    }

    const transmitAction = getTransmitAction(source);
    if (!transmitAction) {
      return;
    }

    const { error } = await props.dispatch(
      transmitAction(payload, values.email, values.message, values.encrypt, phone, subject)
    );

    if (error) {
      Alert.alert('Email did not send.', 'Sorry for the inconvenience.', [
        {
          text: 'Try Again?',
          onPress: () => {
            handleSend(values);
            handleAnalytics(values, 'Try Again');
          }
        },
        {
          text: 'Cancel',
          onPress: () => {
            handleAnalytics(values, 'Cancel');
          }
        }
      ]);
    } else {
      handleAnalytics(values, 'Success');
      Alert.alert('Email Sent', 'Your email was successfully sent.', [
        {
          text: 'OK',
          onPress: () => {
            if (returnBackTo) {
              return history.push(returnBackTo, { ...returnBackToParams });
            }
            return history.push('/u/messages');
          }
        }
      ]);
    }
  };

  // If the user loses a source, we cannot show anything. They must go back
  if (!source) {
    Alert.alert(
      'Share source not found',
      'For security purposes, we do not store data to be shared across browser refreshes.',
      [
        {
          text: 'OK',
          onPress: () => history.push('/u/health-record')
        }
      ],
      {
        cancelable: false
      }
    );
  }

  return (
    <Formik
      validationSchema={TransmitSchema}
      initialValues={{ ...initialTransmitValues, message: initialMessage }}
      onSubmit={handleSend}
      isInitialValid={false}
    >
      {({ values, touched, errors, handleSubmit, handleChange, handleBlur, isValid }) => {
        return (
          <MuiDialog fullScreen open>
            {props.isSending ? (
              <MuiBox
                position="absolute"
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100%"
                width="100%"
              >
                <MuiBox
                  boxShadow={Elevation.el5}
                  zIndex={ZIndex.spinner}
                  height={Spacing.xxLarge}
                  width={320}
                >
                  <SpinnerOverlay isLoading={props.isSending}>
                    <Typography fontWeight={FontWeight.bold}>
                      Sending Email. Please wait...
                    </Typography>
                  </SpinnerOverlay>
                </MuiBox>
              </MuiBox>
            ) : null}
            <MuiBox
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              height="40px"
              padding="10px 20px"
              color={Color.white}
              style={{
                background: '#003366'
              }}
            >
              <Typography
                variant="subtitle"
                color={Color.white}
                fontWeight={FontWeight.bold}
                fontSize={FontSize.large}
              >
                Transmit outside Intermountain
              </Typography>
              <Icon name="close" color={Color.white} size={IconSize.base} onClick={handleClose} />
            </MuiBox>

            <MuiBox p={3} flex={1}>
              <MuiContainer maxWidth="md">
                <FlexBox justifyContent="center" flexDirection="row" alignItems="center">
                  <Box flex={1}>
                    <Typography
                      fontSize={FontSize.heading}
                      fontWeight={FontWeight.semibold}
                      textAlign="center"
                    >
                      Share Records
                    </Typography>
                  </Box>

                  <Box hOffsetLeft="auto" />
                </FlexBox>

                <Spacer spacing="large" />

                <Typography textAlign="center" fontWeight={FontWeight.semibold}>
                  Outside Intermountain
                </Typography>

                <Spacer spacing="xLarge" />

                <CustomTextField
                  name="email"
                  data-testid="enter-email-field"
                  placeholder="Enter Email"
                  value={values.email}
                  onChange={handleChange('email')}
                  onBlur={handleBlur('email')}
                  error={touched.email && !!errors.email}
                  fullWidth
                  variant="standard"
                />

                <Spacer spacing="large" />

                <CustomTextField
                  name="confirmEmail"
                  data-testid="confirm-email-field"
                  placeholder="Confirm Email"
                  value={values.confirmEmail}
                  onChange={handleChange('confirmEmail')}
                  onBlur={handleBlur('confirmEmail')}
                  error={touched.confirmEmail && !!errors.confirmEmail}
                  fullWidth
                  variant="standard"
                />

                <Spacer spacing="large" />

                <CustomTextField
                  name="phone"
                  data-testid="phone-number-field"
                  placeholder="Follow-up contact phone number"
                  value={values.phone}
                  onChange={handleChange('phone')}
                  onBlur={handleBlur('phone')}
                  error={touched.phone && !!errors.phone}
                  fullWidth
                  mask="phone"
                />
                <Spacer spacing="small" />
                <Typography fontSize={FontSize.xsmall} italics>
                  *Phone number must be 10 digits
                </Typography>

                <Spacer spacing="large" />

                <FlexBox flexDirection="row" alignItems="center" justifyContent="space-between">
                  <Typography>Send Encrypted Message</Typography>
                  <MuiSwitch
                    data-testid="toggle-switch"
                    checked={values.encrypt}
                    onChange={handleChange('encrypt')}
                    value={values.encrypt}
                  />
                </FlexBox>

                <Spacer spacing="medium" />

                <Typography fontSize={FontSize.small}>
                  Health records contain sensitive health information. You should treat health
                  record information like your banking information and share it wisely with only
                  trusted sources. It is important to know that communications through email are not
                  secure. It is unlikely, but there is a chance that the information you include in
                  the email could be intercepted or read by someone other than the person to whom
                  you addressed it.
                </Typography>

                <Spacer spacing="medium" />

                <MuiInput
                  multiline
                  rowsMax={5}
                  fullWidth
                  placeholder="Include a message"
                  data-testid="message-body"
                  value={values.message}
                  onChange={handleChange('message')}
                  onBlur={handleBlur('message')}
                  style={{ minHeight: 100 }}
                  error={touched.message && !!errors.message}
                />

                <Spacer spacing="large" />

                <FlexBox flexDirection="row" alignItems="center">
                  <Box hOffsetRight={Spacing.medium}>
                    <MuiFormControlLabel
                      control={
                        <MuiCheckbox
                          padding={Spacing.large}
                          checked={values.risksAccepted}
                          data-testid="check-box"
                          onChange={handleChange('risksAccepted')}
                          value={values.risksAccepted}
                          inputProps={{
                            'aria-label': 'primary checkbox',
                            'data-testid': 'risks-checkbox'
                          }}
                        />
                      }
                    />
                  </Box>
                  <Box flex={1}>
                    <Typography fontSize={FontSize.small}>
                      By selecting the checkbox you confirm that you understand the risks of sending
                      health information via email.
                    </Typography>
                  </Box>
                </FlexBox>

                <Spacer spacing="large" />
                <FlexBox flexDirection="row" alignItems="right">
                  <MuiButton
                    data-testid="send-button"
                    disabled={!isValid || !values.risksAccepted || props.isSending}
                    onClick={handleSubmit}
                    color="primary"
                    variant="contained"
                  >
                    Send
                  </MuiButton>
                </FlexBox>
              </MuiContainer>
            </MuiBox>
          </MuiDialog>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = (state: RootState) => ({
  shareAllergies: shareAllergiesDataSelector(state),
  shareImmunizations: shareImmunizationsDataSelector(state),
  shareProblems: shareProblemsDataSelector(state),
  shareProcedures: shareProceduresDataSelector(state),
  shareSocialHistory: shareSocialHistoryDataSelector(state),
  shareMedicalHistory: shareMedicalHistoryDataSelector(state),
  shareHealthRecordSummary: shareHealthRecordSummaryDataSelector(state),
  shareMedications: shareMedicationsDataSelector(state),
  shareVitals: shareVitalsDataSelector(state),
  shareLabResults: shareLabResultsDataSelector(state),
  shareMicrobiologyResults: shareMicrobiologyResultsDataSelector(state),
  shareImagingResults: shareImagingResultsDataSelector(state),
  sharePathologyResults: sharePathologyResultsDataSelector(state),
  shareVisitCcd: visitSummaryShareCcdDataSelector(state),
  isSending: transmitSendingSelector(state),
  currentUserFullName: currentAccountConsumerFullNameSelector(state),
  currentUrl: currentLocationPathNameSelector(state),
  referringUrl: previousLocationPathNameSelector(state)
});

export default connect(mapStateToProps)(TransmitLandingScreen);
