import styled, { css } from 'styled-components';
import DefaultLabel from 'components/UI/Labels/DefaultLabel';
import DefaultText from 'components/UI/Typography/DefaultText';
import { Color } from 'modules/styles/colors';
import { smallFontSize, Spacing, BorderRadius } from 'modules/styles/variables';
import { HealthRecordsSwiper } from 'components/healthrecords/HealthRecordsSwiper';
import { Icon } from 'components/Icon';
import { Column, Row } from 'components/UI/Layout/Container';
import FlexBox from 'components/UI/Layout/FlexBox';
import { ScrollView } from 'react-native';
import Section from 'components/UI/Layout/Section';
import { MuiTableCell, MuiContainer, dfdDefaultTheme, MuiTypography } from 'theme/material-ui';
import Snackbar from '@material-ui/core/Snackbar';

export const TitleText = styled(DefaultLabel)`
  font-weight: bold;
`;

export const SubText = styled(DefaultLabel)`
  font-size: 14px;
`;

export const CardContainer = styled(FlexBox)`
  justify-content: center;
  align-items: center;
`;

export const PrimaryText = styled(DefaultText)`
  text-align: center;
`;

const withTextStyles = (props: { highlight?: boolean }) => {
  return (
    props.highlight &&
    css`
      color: ${Color.primary};
    `
  );
};

export const DetailText = styled(DefaultText)`
  text-align: center;
  font-size: ${smallFontSize};
  ${withTextStyles}
`;

export const StyledHealthRecordsSwiper = styled(HealthRecordsSwiper)`
  margin-bottom: 10px;
  padding-bottom: 10px;
`;

export const StyledIcon = styled(Icon)`
  margin-left: auto;
`;

export const PaddedColumn = styled(Column)`
  padding-left: 15px;
`;

export const VitalsMain = styled.div`
  background-color: ${Color.baseColor};
  height: 314px;
  width: 100%;
`;

export const StyledScrollView = styled(ScrollView)`
  background-color: ${Color.baseColor};
`;

export const StyledSection = styled(Section)`
  justify-content: center;
`;

export const BoldDefaultLabel = styled(DefaultLabel)`
  font-weight: bold;
  margin-right: ${Spacing.small}px;
`;

export const StyledRow = styled(Row)`
  align-items: center;
  align-content: center;
`;

export const ActionContainer = styled(FlexBox)`
  width: 100%;
  padding: ${Spacing.medium}px ${Spacing.small}px;
  flex-direction: row;
  background-color: ${Color.foreColor};
  justify-content: center;
`;

export const ActionContainerItem = styled.div`
  background-color: ${Color.grayHue4};
  border-radius: ${BorderRadius.base};
  margin: ${Spacing.small}px ${Spacing.small}px 0;
  min-width: 54px;
  min-height: 48px;
  justify-content: center;
  align-items: center;
`;

export const TableCell = styled(MuiTableCell)`
  && {
    border-bottom: none;
    padding-bottom: 0px;
    @media (max-width: 375px) {
      padding-left: 3px;
      padding-right: 3px;
    }
    color: ${({ active }) => (active ? Color.primary : Color.black)};
  }
`;

export const Container = styled(MuiContainer)`
  padding-top: 16px;
  padding-bottom: 16px;
  @media (max-width: 375px) {
    padding-left: 0px;
    padding-right: 0px;
  }
`;

export const FooterBanner = styled.div`
  && {
    text-align: center;
    position: sticky;
    bottom: 0px;
    bottom: 0;
    width: 100%;
    max-height: 175px;
    background: ${Color.grayLight3};
  }
`;

export const DownloadToast = styled(Snackbar)`
  height: 60px;
  margin: 10px;
  border-radius: 4px;
  background-color: ${Color.grayHue3};
`;

export const StyledText = styled(MuiTypography)`
  &.MuiTypography-body1 {
    color: ${Color.white};
    padding-left: 10px;
  }
`;

export const IconText = styled.span`
  padding: 6px;
  ${dfdDefaultTheme.breakpoints.down('md')} {
    display: none;
  }
`;
