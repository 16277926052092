import { stringLength } from 'modules/utils/StringUtils';

const masks = {
  amex: [/\d/, /\d/, /\d/, /\d/],
  common: [/\d/, /\d/, /\d/]
};

function isAmexCVV(rawValue: string) {
  return stringLength(rawValue, /\d/g) > 3;
}

export const cvv = {
  default: (rawValue: string) => (isAmexCVV(rawValue) ? masks.amex : masks.common),
  ...masks
};
