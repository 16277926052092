import React, { useEffect } from 'react';
import { oc } from 'ts-optchain';
import dayjs from 'dayjs';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Encryption from 'services/Encryption';
import analyticsService, { AmplitudeEventData, AnalyticsEvent } from 'services/AnalyticsService';
import * as testResults from 'store/testResults/selectors';
import * as appSettingsSelectors from 'store/appSettings/selectors';
import * as routeSelectors from 'store/router/selectors';
import { RootState } from 'store/types';
import {
  TestResultDetails,
  TestResultFocused,
  LabDetails,
  LabResult,
  LabResultFull,
  LabResultsListItems,
  PatientLetters
} from 'store/testResults/types';
import Typography from 'components/UI/Typography';
import { Color } from 'modules/styles/colors';
import { FontSize, FontWeight } from 'modules/styles/variables';
import Box from 'components/UI/Layout/Box';
import FlexBox from 'components/UI/Layout/FlexBox';
import Spinner from 'components/UI/Spinner/Spinner';
import {
  MuiBox,
  MuiPaper,
  MuiTable,
  MuiTableRow,
  MuiTableHead,
  MuiTypography
} from 'theme/material-ui';
import { TableCellAlt } from '../styled';
import { CardsContainer } from './styled';
import Divider from 'components/UI/Divider';
import DocumentView from 'components/UI/DocumentView';
import { formatPhone } from 'services/formatPhone';
import LabRow from './LabRow';

export interface Props {
  loading: boolean;
  error: Error | null;
  data: TestResultDetails | null;
  testResultFocused: TestResultFocused;
  currentUrl?: string;
  referringUrl?: string;
  eventId?: string;
  encryptionSettings: {
    password: string;
    algorithm: string;
  };
}

// LAB RESULTS
interface LabResultsProps {
  content: LabResultsListItems[];
}

const LabResults = (props: LabResultsProps) => {
  const { content } = props;
  const hasContent = content.length > 0;
  const history = useHistory();

  return (
    <>
      {hasContent ? (
        <>
          <MuiBox
            paddingX={2}
            paddingY={1}
            marginBottom={1}
            bgcolor={Color.cobaltTint}
            borderRadius={3}
          >
            <MuiTable aria-label="Labs Details">
              <MuiTableHead>
                <MuiTableRow>
                  <TableCellAlt
                    style={{ color: Color.black, fontSize: FontSize.base }}
                    id="table-name"
                  >
                    Subname
                  </TableCellAlt>
                  <TableCellAlt
                    style={{ color: Color.black, fontSize: FontSize.base }}
                    id="table-value"
                    align="center"
                  >
                    Test Result | Range
                  </TableCellAlt>
                  <TableCellAlt
                    style={{ color: Color.black, fontSize: FontSize.base }}
                    id="table-interpretation"
                    align="right"
                  >
                    Trendline | Comments
                  </TableCellAlt>
                </MuiTableRow>
              </MuiTableHead>
            </MuiTable>
          </MuiBox>
          <MuiBox>
            {content.map(item => (
              <LabRow
                item={item}
                onLabRowClick={() =>
                  history.push('/u/health-record/lab-history', {
                    resultDetail: item
                  })
                }
              />
            ))}
          </MuiBox>
        </>
      ) : null}
    </>
  );
};

// LAB COMMENTS
export const LabComments = ({ content, notes }: { content?: LabResult[]; notes?: string }) => {
  const comments = notes
    ? `${notes}<br><br>`
    : content.reduce((acc, { notes }) => {
        return notes ? acc.concat(`${notes}<br><br>`) : acc;
      }, '');

  if (!comments.length) return null;

  return (
    <MuiBox bgcolor={Color.baseColor} component={MuiPaper} elevation={0}>
      <MuiBox p={2}>
        <Typography
          fontSize={FontSize.large}
          fontWeight={FontWeight.bold}
          data-testid="lab-additional-comments"
        >
          Additional comments
        </Typography>
      </MuiBox>
      <Divider />
      <Box flex={1} spacing={20} dangerouslySetInnerHTML={{ __html: comments }} />
    </MuiBox>
  );
};

// LAB DETAILS
export const LabDetailSection = ({ content }: { content: LabResult[] }) => {
  const result = content[0];
  return (
    <MuiBox component={MuiPaper} elevation={0} bgcolor={Color.baseColor}>
      <MuiBox p={2}>
        <Typography fontSize={FontSize.large} fontWeight={FontWeight.bold}>
          Lab Details
        </Typography>
      </MuiBox>
      <Divider />

      <MuiBox p={2}>
        <MuiTypography fontWeight={FontWeight.bold}>
          Test Manufacturer:{' '}
          <MuiTypography display="inline">{oc(result).name('N/A')}</MuiTypography>
        </MuiTypography>
        <MuiTypography fontWeight={FontWeight.bold}>
          Specimen Source:{' '}
          <MuiTypography display="inline">{oc(result).specimenSource('N/A')}</MuiTypography>
        </MuiTypography>
        <MuiTypography fontWeight={FontWeight.bold}>
          Collection Date/Time:{' '}
          <MuiTypography display="inline">
            {result?.collectionDate
              ? dayjs(result?.collectionDate).format('MMM DD, YYYY [at] HH:mm A')
              : 'N/A'}
          </MuiTypography>
        </MuiTypography>
        <MuiTypography fontWeight={FontWeight.bold}>
          Performing Lab:{' '}
          <MuiTypography display="inline">{oc(result).performingLab('N/A')}</MuiTypography>
        </MuiTypography>
        <MuiTypography fontWeight={FontWeight.bold}>
          Performing Lab Address:{' '}
          <MuiTypography display="inline">{oc(result).performingLabAddress('N/A')}</MuiTypography>
        </MuiTypography>
        <MuiTypography fontWeight={FontWeight.bold}>
          Performing Lab Contact:{' '}
          <MuiTypography display="inline">
            {result?.performingLabPhone ? formatPhone(result?.performingLabPhone) : 'N/A'}
          </MuiTypography>
        </MuiTypography>
      </MuiBox>
    </MuiBox>
  );
};

// RELATED DOCUMENTS
export const RelatedDocuments = ({ content }: { content: LabResult[] }) => {
  const history = useHistory();
  const handleViewPatientLetters = (item: PatientLetters) => {
    history.push(`/u/health-record/patient-letters/XR-${item.docId}`);
  };
  const { patientLetters } = content[0];

  if (patientLetters?.some(a => a.docId === 0)) return null;
  if (patientLetters?.length) {
    return (
      <MuiBox component={MuiPaper} elevation={0}>
        <MuiBox p={2}>
          <Typography fontSize={FontSize.large} fontWeight={FontWeight.bold}>
            Related Documents
          </Typography>
        </MuiBox>
        <Divider />
        <MuiBox p={2}>
          {patientLetters.length
            ? patientLetters.map((eachDoc: PatientLetters) => (
                <Typography
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleViewPatientLetters(eachDoc)}
                  color={Color.primary}
                >{`View ${eachDoc.title}`}</Typography>
              ))
            : null}
        </MuiBox>
      </MuiBox>
    );
  }
  return null;
};

// LOADING
export const Loading = () => (
  <FlexBox flex={1} alignItems="center" justifyContent="center" accessibilityLabel="Lab loading">
    <Spinner />
  </FlexBox>
);

// ERROR
export const Error = ({ message }: { message: string }) => (
  <FlexBox flex={1} alignItems="center" justifyContent="center">
    <Typography>{message}</Typography>
  </FlexBox>
);

// MAIN COMPONENT
export const Lab = (props: Props) => {
  const {
    loading,
    error,
    data,
    testResultFocused,
    encryptionSettings,
    currentUrl,
    referringUrl,
    eventId
  } = props;
  const content = oc(data).content(undefined) as LabDetails;
  const results = oc(content).results([]);
  const result = results[0];
  const mediaData = result?.content
    ? { content: result.content, contentType: result.contentType }
    : null;
  const labResults = results.reduce(
    (acc: LabResultsListItems[], result: LabResult | LabResultFull) => {
      const { adapterSource, name, source, components } = result;
      const labResultsListItems = components.map(comp => ({
        ...comp,
        adapterSource,
        source,
        panelName: name
      }));
      return acc.concat(...labResultsListItems);
    },
    []
  );

  const eventData: AmplitudeEventData = {
    // we can not pass name to Amplitude as it is PHI
    test_name: Encryption.encrypt(
      encryptionSettings.algorithm,
      encryptionSettings.password,
      testResultFocused.name
    ),
    test_type: testResultFocused.category,
    referringUrl,
    currentUrl,
    eventId
  };

  useEffect(() => {
    if (data) {
      const detailsViewedEventData: AmplitudeEventData = {
        ...eventData,
        test_result_displayed: 'Success'
      };
      analyticsService.logEvent(AnalyticsEvent.TestResultsDetailsViewed, detailsViewedEventData);
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      const detailsViewedEventData: AmplitudeEventData = {
        ...eventData,
        test_result_displayed: 'Error',
        test_result_error: error
      };
      analyticsService.logEvent(AnalyticsEvent.TestResultsDetailsViewed, detailsViewedEventData);
    }
  }, [error]);

  // *******************************
  // ** LOADING | ERROR | NO DATA **
  // *******************************

  if (loading) return <Loading />;
  if (error) return <Error message="An error occurred retrieving Lab details" />;
  if (!data && !mediaData) return <Error message="No data available" />;

  if (result?.blobIds?.length) {
    if (mediaData)
      return (
        <>
          <Divider />
          <MuiBox height="80vh">
            <DocumentView
              content={mediaData?.content}
              contentType={mediaData?.contentType}
              documentName="TestResults"
            />
          </MuiBox>
        </>
      );
  }

  return (
    <>
      <MuiBox>
        <LabResults content={labResults} />
      </MuiBox>
      <CardsContainer>
        <LabDetailSection content={results} />
        <LabComments content={results} />
        <RelatedDocuments content={results} />
      </CardsContainer>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  loading: testResults.testResultDetailsLoadingSelector(state),
  error: testResults.testResultDetailsErrorSelector(state),
  data: testResults.testResultDetailsDataSelector(state),
  currentUrl: routeSelectors.currentLocationPathNameSelector(state),
  referringUrl: routeSelectors.previousLocationPathNameSelector(state),
  encryptionSettings: appSettingsSelectors.encryptionSettingsSelector(state)
});

export default connect(mapStateToProps)(Lab);
