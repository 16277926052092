import React, { ComponentType } from 'react';
import { connect, useDispatch } from 'react-redux';
import { NavigationScreenProps } from 'screens/navigation';
import { RootState } from 'store/types';
import { MuiBox, MuiContainer } from 'theme/material-ui';
import { getUpdates } from 'lib/updates/utils';
import UpdatesList from './UpdatesList';
import { getUpdateEvents } from 'store/events/actions';
import { FormattedEvent } from 'serverTypes/event';
import {
  eventListDataSelector,
  eventListErrorSelector,
  eventListFetchingSelector
} from 'store/events/selectors';
import { useProxySwitcherEffect } from 'hooks/useProxySwitcherEffect';

export interface Props extends NavigationScreenProps {
  loading: boolean;
  error?: Error | null;
  data?: FormattedEvent[];
}

export function Updates(props: Props) {
  const { history, loading, error, data = [] } = props;
  const dispatch = useDispatch();

  useProxySwitcherEffect(() => {
    dispatch(getUpdateEvents());
  }, []);

  const updates = getUpdates(data);

  return (
    <MuiContainer maxWidth="lg">
      <MuiBox my={3}>
        <UpdatesList loading={loading} error={error} history={history} updates={updates} />
      </MuiBox>
    </MuiContainer>
  );
}

const mapStateToProps = (state: RootState) => ({
  loading: eventListFetchingSelector(state),
  error: eventListErrorSelector(state),
  data: eventListDataSelector(state)
});

export default connect(mapStateToProps)(Updates as ComponentType);
