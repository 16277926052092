import { MuiGrid } from 'theme/material-ui';
import React from 'react';
import { VitalsBlock } from 'store/vitals/types';
import BlockItem from './BlockItem';

interface Props {
  blocks: VitalsBlock[];
  onBlockPressed: (block: VitalsBlock) => void;
}

export const emptyBlockItem: VitalsBlock = {
  title: '',
  valueUnits: [{ value: null, units: '' }],
  statusColor: '',
  statusText: '',
  date: ''
};

export const BlockSet = ({ blocks, onBlockPressed }: Props) => {
  return (
    <MuiGrid container spacing={3} alignItems="stretch">
      {blocks.map(block => (
        <BlockItem
          block={block}
          onPress={() => onBlockPressed(block)}
          key={block.title}
        ></BlockItem>
      ))}
    </MuiGrid>
  );
};

export default BlockSet;
