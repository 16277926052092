import React from 'react';
import { StyledMuiDialog } from 'screens/Booking/components/styled';
import CloseButton from 'components/UI/Button/CloseButton';
import { DialogTitle } from '@material-ui/core';
import { STATUS_PAGE_URL } from 'lib/constants/help';
import { MuiButton } from 'theme/material-ui';
import Spacer from 'components/UI/Layout/Spacer';
import { Color } from 'modules/styles/colors';
import { AlertIcon } from './styled';
import analyticsService, { AnalyticsEvent } from 'services/AnalyticsService';

export function LoadingAlert(params: {
  errorCode?: string;
  open: boolean;
  handleClose: (action: 'close' | 'tryAgain' | 'contact') => void;
}) {
  const { open, handleClose, errorCode } = params;
  return (
    <StyledMuiDialog
      open={open}
      onClose={() => handleClose('close')}
      aria-labelledby="loading-alert-dialog"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="loading-alert-dialog-title">
        <CloseButton absolute onPress={() => handleClose('close')} margin="0px" padding={3} />
        <AlertIcon />

        <div>
          <p style={{ fontWeight: 300 }}>
            <b>We're sorry!</b> There was an issue loading your information. Please try again.
          </p>
          <p style={{ fontWeight: 300 }}>
            If your information does not load, check the status page for current known issues or{' '}
            <span
              onClick={() => handleClose('contact')}
              style={{ color: Color.secondary, fontWeight: 'bold', cursor: 'pointer' }}
            >
              contact support.
            </span>
          </p>
        </div>

        <div style={{ textAlign: 'center' }}>
          <MuiButton
            fullWidth
            color="primary"
            variant="contained"
            onClick={() => handleClose('tryAgain')}
          >
            Try Again
          </MuiButton>
          <Spacer size="small" />
          <MuiButton
            fullWidth
            color="primary"
            variant="outlined"
            onClick={() => {
              analyticsService.logEvent(AnalyticsEvent.SignInFailedStatusClicked, { errorCode });
              window.open(STATUS_PAGE_URL, '_blank');
            }}
          >
            Check status page
          </MuiButton>
        </div>

        <div>
          <sub style={{ fontSize: 'xx-small' }}>Error Code: {errorCode}</sub>
        </div>
      </DialogTitle>
    </StyledMuiDialog>
  );
}
