import React, { useState } from 'react';
import { connect } from 'react-redux';
import DataLoader from 'components/UI/DataLoader/DataLoader';
import { ConnectCareError, ConnectCareLoading } from 'components/ConnectCare';
import {
  MuiBox,
  MuiGrid,
  MuiTypography,
  MuiCard,
  MuiCardContent,
  MuiAvatar,
  MuiCardActions,
  MuiButton
} from 'theme/material-ui';
import { Color } from 'modules/styles/colors';
import { providerSelect } from 'modules/constants/amwell';
import { BorderRadius, FontSize } from 'modules/styles/variables.web';
import { IconSize } from 'modules/styles/variables';
import { providerColorMap as colorMap } from 'modules/constants/ConnectCare';
import { RootState } from 'store/types';
import * as selectors from 'store/amwell/selectors';
import * as actions from 'store/amwell/actions';
import { getVisibilityAttr } from 'store/amwell/utils';
import { Practice, Provider, ProviderDetails, StorageKey } from 'store/amwell/types';
import { getSelectionId } from '../utils';
import { LoadMoreButton } from './styled';
import ConnectCareProviderProfile from './ConnectCareProviderProfile';
import ProviderAvatar from 'components/UI/Avatar/ProviderAvatar';

export interface Props {
  loading: boolean;
  error: Error | null;
  providers: Provider[];
  providerSelection: ProviderDetails | null;
  practiceSelection: Practice | null;
  getProviders: typeof actions.getProvidersForPractice;
  viewProviderDetails: ProviderDetails | null;
  setViewProviderDetails: (provider: Provider | null) => void;
  setProviderDetailsWereViewed: typeof actions.setProviderDetailsWereViewed;
  remove: typeof actions.removeStorage;
  setProviderSelection: (provider: Provider | null) => void;
}

export function ConnectCareProviders({
  getProviders,
  remove,
  providers,
  practiceSelection,
  providerSelection,
  setProviderSelection,
  viewProviderDetails,
  setViewProviderDetails,
  setProviderDetailsWereViewed,
  loading,
  error
}: Props) {
  const [providerList, setProviderList] = useState(10);
  const [providersPerLoad, setProvidersPerLoad] = useState(10);
  const [showProviderProfile, setShowProviderProfile] = useState(false);

  const providerId = getSelectionId(providerSelection);
  const providersToShow = practiceSelection ? providers : [];

  const handleLoadMoreClick = () => {
    setProvidersPerLoad((prev: number) => prev + 10);
    setProviderList(providerList + 10);
  };

  const handleViewProviderDetails = (provider: Provider) => {
    setViewProviderDetails(provider);
    setShowProviderProfile(true);
    setProviderDetailsWereViewed(true);
  };

  return (
    <>
      <MuiBox py={2}>
        <MuiGrid container justify="space-between" alignItems="center">
          <MuiGrid item>
            <MuiTypography variant="h5">{providerSelect.PROVIDERS_TITLE.title}</MuiTypography>
          </MuiGrid>
        </MuiGrid>
      </MuiBox>
      <DataLoader
        data={providersToShow.slice(0, providersPerLoad)}
        loading={loading}
        error={error}
        renderLoading={() => <ConnectCareLoading />}
        renderNoData={() =>
          practiceSelection ? (
            <ConnectCareError message={providerSelect.PROVIDERS_NO_DATA.title} />
          ) : (
            <ConnectCareError message={providerSelect.PROVIDERS_INIT.title} />
          )
        }
        renderError={() => (
          <ConnectCareError
            message={providerSelect.PROVIDERS_ERROR.title}
            action={{
              onClick: () => {
                if (practiceSelection) {
                  getProviders({ practice: practiceSelection });
                }
              }
            }}
          />
        )}
        renderData={data => (
          <MuiGrid container spacing={2}>
            {data
              .slice()
              .sort((a, b) => {
                const av = getVisibilityAttr(a);
                const bv = getVisibilityAttr(b);

                if (av.value < bv.value) return 1;
                if (av.value > bv.value) return -1;
                return 0;
              })
              .map(p => {
                const id = getSelectionId(p);
                const visibilityAttr = getVisibilityAttr(p);
                const selected = id === providerId;
                const offline = !visibilityAttr.value;

                return (
                  <MuiGrid container item md={12} lg={6} key={id}>
                    <MuiGrid item xs={12}>
                      <MuiCard raised={selected} elevation={0} variant="outlined">
                        <MuiCardContent>
                          <MuiGrid container alignItems="center" xs={6} spacing={2}>
                            <MuiGrid item xs={2}>
                              <MuiBox
                                width={24}
                                height={24}
                                bgcolor={colorMap[visibilityAttr.color]}
                                borderRadius={BorderRadius.full}
                              />
                            </MuiGrid>
                            <MuiGrid item xs={4}>
                              <MuiBox>
                                <MuiTypography fontSize={FontSize.small}>
                                  {visibilityAttr.label}
                                </MuiTypography>
                              </MuiBox>
                            </MuiGrid>
                            <MuiGrid item>
                              <MuiTypography fontSize={FontSize.small}>
                                {p.waitingRoomCount} Waiting
                              </MuiTypography>
                            </MuiGrid>
                          </MuiGrid>
                          <MuiGrid
                            container
                            direction="row"
                            alignItems="center"
                            spacing={2}
                            style={{ marginTop: 15 }}
                          >
                            <MuiGrid item>
                              {p.logoUrl ? (
                                <MuiAvatar
                                  alt={p.fullName}
                                  src={p.logoUrl}
                                  size={IconSize.navMenu * 2}
                                />
                              ) : (
                                <ProviderAvatar
                                  avatarUrl="Avatar"
                                  color={Color.grayDark}
                                  size={IconSize.navMenu * 2}
                                  backgroundColor={Color.grayLight7}
                                />
                              )}
                            </MuiGrid>
                            <MuiGrid item>
                              <MuiTypography variant="h6">{p.fullName}</MuiTypography>
                              <MuiTypography color="textSecondary">
                                {p.specialty.value}
                              </MuiTypography>
                            </MuiGrid>
                          </MuiGrid>
                        </MuiCardContent>
                        <MuiCardActions>
                          <MuiButton color="primary" onClick={() => handleViewProviderDetails(p)}>
                            <MuiTypography color="primary"> View details </MuiTypography>
                          </MuiButton>
                          <MuiButton
                            data-testid={`select-provider-${p.fullName}`}
                            color="secondary"
                            disabled={selected || offline}
                            onClick={() => setProviderSelection(p)}
                          >
                            Select provider
                          </MuiButton>
                        </MuiCardActions>
                      </MuiCard>
                    </MuiGrid>
                  </MuiGrid>
                );
              })}
            {providerList < providers.length ? (
              <MuiBox
                display="flex"
                flexDirection="row"
                justifyContent="center"
                width="100%"
                paddingY={2}
              >
                <LoadMoreButton
                  data-testid="load-more-providers"
                  variant="outlined"
                  color="primary"
                  size="large"
                  onClick={handleLoadMoreClick}
                >
                  Load more
                </LoadMoreButton>
              </MuiBox>
            ) : null}
          </MuiGrid>
        )}
      />
      <ConnectCareProviderProfile
        isOpen={showProviderProfile}
        onClose={() => {
          setShowProviderProfile(false);
          remove({ keys: [StorageKey.PROVIDER] });
          setProviderSelection(null);
        }}
        provider={viewProviderDetails}
      />
    </>
  );
}

const mapStateToProps = (state: RootState) => ({
  providers: selectors.providersListDataSelector(state),
  loading:
    selectors.practiceDetailsDataLoadingSelector(state) ||
    selectors.providersListDataLoadingSelector(state),
  error:
    selectors.practiceDetailsDataErrorSelector(state) ||
    selectors.providersListDataErrorSelector(state)
});

const mapDispatch = {
  getProviders: actions.getProvidersForPractice,
  setProviderDetailsWereViewed: actions.setProviderDetailsWereViewed,
  remove: actions.removeStorage
};

export default connect(mapStateToProps, mapDispatch)(ConnectCareProviders);
