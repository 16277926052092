/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import { createMuiTheme, SimplePaletteColorOptions, ThemeProvider } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { rgba } from 'polished';

import * as colors from '@material-ui/core/colors';
import * as Typography from '@material-ui/core/Typography';
import * as Backdrop from '@material-ui/core/Backdrop';
import * as Button from '@material-ui/core/Button';
import * as Link from '@material-ui/core/Link';
import * as Avatar from '@material-ui/core/Avatar';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';

import { Color } from 'modules/styles/colors';
import styled from 'styled-components';
import { BorderRadius, FontSize, IconSize, ZIndex } from 'modules/styles/variables';
import { isMobileBrowser } from 'lib/browser';
import Spinner from 'components/UI/Spinner/Spinner';

export { colors };
export * from '@material-ui/core/styles';

export { default as MuiAccordion } from '@material-ui/core/Accordion';
export * from '@material-ui/core/Accordion';

export { default as MuiAccordionActions } from '@material-ui/core/AccordionActions';
export * from '@material-ui/core/AccordionActions';

export { default as MuiAccordionDetails } from '@material-ui/core/AccordionDetails';
export * from '@material-ui/core/AccordionDetails';

export { default as MuiAccordionSummary } from '@material-ui/core/AccordionSummary';
export * from '@material-ui/core/AccordionSummary';

export { default as MuiAppBar } from '@material-ui/core/AppBar';
export * from '@material-ui/core/AppBar';

interface MuiAvatarProps extends React.ComponentProps<typeof Avatar.default> {
  size?: number;
}

export const MuiAvatar = ({ size, ...rest }: MuiAvatarProps) => {
  return (
    <Avatar.default
      {...rest}
      style={{
        width: size,
        height: size,
        ...rest.style
      }}
    />
  );
};

export * from '@material-ui/core/Avatar';

// Fixes backdrop not showing when opened
export const MuiBackdrop = styled(Backdrop.default)`
  && {
    z-index: ${ZIndex.max};
  }
`;
export * from '@material-ui/core/Backdrop';

export { default as MuiBadge } from '@material-ui/core/Badge';
export * from '@material-ui/core/Badge';

export { default as MuiBottomNavigation } from '@material-ui/core/BottomNavigation';
export * from '@material-ui/core/BottomNavigation';

export { default as MuiBottomNavigationAction } from '@material-ui/core/BottomNavigationAction';
export * from '@material-ui/core/BottomNavigationAction';

export { default as MuiBox } from '@material-ui/core/Box';
export * from '@material-ui/core/Box';

export { default as MuiBreadcrumbs } from '@material-ui/core/Breadcrumbs';
export * from '@material-ui/core/Breadcrumbs';

export * from '@material-ui/core/Button';

export interface ButtonProps extends React.ComponentProps<typeof Button.default> {
  loading?: boolean;
  dark?: boolean;
  borderRadius?: number;
  hideLabelBelow?: number | Breakpoint;
  text?: string;
  icon?: string;
}

// For handling asynchronous actions
export const MuiButton = ({
  disabled,
  loading,
  children,
  borderRadius,
  startIcon,
  endIcon,
  hideLabelBelow = 0,
  style,
  ...rest
}: ButtonProps) => {
  const isSmallScreen = useMediaQuery(dfdDefaultTheme.breakpoints.down(hideLabelBelow));
  const buttonStyles = { borderRadius: borderRadius ?? BorderRadius.full };
  if (rest.dark && rest.variant === 'contained') {
    rest.color = 'secondary';
  }

  if (hideLabelBelow && isSmallScreen) {
    return (
      <Button.default
        disabled={disabled || loading}
        style={{ ...buttonStyles, ...style }}
        {...rest}
      >
        {loading ? (
          <Spinner size={IconSize.base} data-testid="button loading" />
        ) : (
          startIcon || endIcon
        )}
      </Button.default>
    );
  }

  return (
    <Button.default
      disabled={disabled || loading}
      startIcon={
        loading && startIcon ? (
          <Spinner size={IconSize.base} data-testid="button loading" />
        ) : (
          startIcon
        )
      }
      endIcon={
        loading && endIcon ? <Spinner size={IconSize.base} data-testid="button loading" /> : endIcon
      }
      style={{ ...buttonStyles, ...style }}
      {...rest}
    >
      {loading && !startIcon && !endIcon ? (
        <Spinner size={IconSize.base} data-testid="button loading" />
      ) : (
        children
      )}
    </Button.default>
  );
};

export { default as MuiButtonBase } from '@material-ui/core/ButtonBase';
export * from '@material-ui/core/ButtonBase';

export { default as MuiButtonGroup } from '@material-ui/core/ButtonGroup';
export * from '@material-ui/core/ButtonGroup';

export { default as MuiCard } from '@material-ui/core/Card';
export * from '@material-ui/core/Card';

export { default as MuiCardActionArea } from '@material-ui/core/CardActionArea';
export * from '@material-ui/core/CardActionArea';

export { default as MuiCardActions } from '@material-ui/core/CardActions';
export * from '@material-ui/core/CardActions';

export { default as MuiCardContent } from '@material-ui/core/CardContent';
export * from '@material-ui/core/CardContent';

export { default as MuiCardHeader } from '@material-ui/core/CardHeader';
export * from '@material-ui/core/CardHeader';

export { default as MuiCardMedia } from '@material-ui/core/CardMedia';
export * from '@material-ui/core/CardMedia';

export { default as MuiCheckbox } from '@material-ui/core/Checkbox';
export * from '@material-ui/core/Checkbox';

export { default as MuiChip } from '@material-ui/core/Chip';
export * from '@material-ui/core/Chip';

export { default as MuiCircularProgress } from '@material-ui/core/CircularProgress';
export * from '@material-ui/core/CircularProgress';

export { default as MuiClickAwayListener } from '@material-ui/core/ClickAwayListener';
export * from '@material-ui/core/ClickAwayListener';

export { default as MuiCollapse } from '@material-ui/core/Collapse';
export * from '@material-ui/core/Collapse';

export { default as MuiContainer } from '@material-ui/core/Container';
export * from '@material-ui/core/Container';

export { default as MuiCssBaseline } from '@material-ui/core/CssBaseline';
export * from '@material-ui/core/CssBaseline';

export {
  TimePicker as MuiTimePicker,
  KeyboardDatePicker as MuiDatePicker
} from '@material-ui/pickers';
export * from '@material-ui/pickers/DatePicker';

export { default as MuiDateTimePicker } from '@material-ui/pickers/DateTimePicker';
export * from '@material-ui/pickers/DateTimePicker';

export { default as MuiDialog } from '@material-ui/core/Dialog';
export * from '@material-ui/core/Dialog';

export { default as MuiDialogActions } from '@material-ui/core/DialogActions';
export * from '@material-ui/core/DialogActions';

export { default as MuiDialogContent } from '@material-ui/core/DialogContent';
export * from '@material-ui/core/DialogContent';

export { default as MuiDialogContentText } from '@material-ui/core/DialogContentText';
export * from '@material-ui/core/DialogContentText';

export { default as MuiDialogTitle } from '@material-ui/core/DialogTitle';
export * from '@material-ui/core/DialogTitle';

export { default as MuiDivider } from '@material-ui/core/Divider';
export * from '@material-ui/core/Divider';

export { default as MuiDrawer } from '@material-ui/core/Drawer';
export * from '@material-ui/core/Drawer';

export { default as MuiExpansionPanel } from '@material-ui/core/ExpansionPanel';
export * from '@material-ui/core/ExpansionPanel';

export { default as MuiExpansionPanelActions } from '@material-ui/core/ExpansionPanelActions';
export * from '@material-ui/core/ExpansionPanelActions';

export { default as MuiExpansionPanelDetails } from '@material-ui/core/ExpansionPanelDetails';
export * from '@material-ui/core/ExpansionPanelDetails';

export { default as MuiExpansionPanelSummary } from '@material-ui/core/ExpansionPanelSummary';
export * from '@material-ui/core/ExpansionPanelSummary';

export { default as MuiFab } from '@material-ui/core/Fab';
export * from '@material-ui/core/Fab';

export { default as MuiFade } from '@material-ui/core/Fade';
export * from '@material-ui/core/Fade';

export { default as MuiFilledInput } from '@material-ui/core/FilledInput';
export * from '@material-ui/core/FilledInput';

export { default as MuiFormControl } from '@material-ui/core/FormControl';
export * from '@material-ui/core/FormControl';

export { default as MuiFormControlLabel } from '@material-ui/core/FormControlLabel';
export * from '@material-ui/core/FormControlLabel';

export { default as MuiFormGroup } from '@material-ui/core/FormGroup';
export * from '@material-ui/core/FormGroup';

export { default as MuiFormHelperText } from '@material-ui/core/FormHelperText';
export * from '@material-ui/core/FormHelperText';

export { default as MuiFormLabel } from '@material-ui/core/FormLabel';
export * from '@material-ui/core/FormLabel';

export { default as MuiGrid } from '@material-ui/core/Grid';
export * from '@material-ui/core/Grid';

export { default as MuiGridList } from '@material-ui/core/GridList';
export * from '@material-ui/core/GridList';

export { default as MuiGridListTile } from '@material-ui/core/GridListTile';
export * from '@material-ui/core/GridListTile';

export { default as MuiGridListTileBar } from '@material-ui/core/GridListTileBar';
export * from '@material-ui/core/GridListTileBar';

export { default as MuiGrow } from '@material-ui/core/Grow';
export * from '@material-ui/core/Grow';

export { default as MuiHidden } from '@material-ui/core/Hidden';
export * from '@material-ui/core/Hidden';

export { default as MuiIcon } from '@material-ui/core/Icon';
export * from '@material-ui/core/Icon';

export * from '@material-ui/icons';

export { default as MuiIconButton } from '@material-ui/core/IconButton';
export * from '@material-ui/core/IconButton';

export { default as MuiInput } from '@material-ui/core/Input';
export * from '@material-ui/core/Input';

export { default as MuiInputAdornment } from '@material-ui/core/InputAdornment';
export * from '@material-ui/core/InputAdornment';

export { default as MuiInputBase } from '@material-ui/core/InputBase';
export * from '@material-ui/core/InputBase';

export { default as MuiInputLabel } from '@material-ui/core/InputLabel';
export * from '@material-ui/core/InputLabel';

export { default as MuiLinearProgress } from '@material-ui/core/LinearProgress';
export * from '@material-ui/core/LinearProgress';

export const MuiLink = styled(({ ...rest }) => (
  <Link.default {...rest} color="secondary" underline="none" display="inline" />
))`
  &:focus,
  &:active,
  &:visited,
  &:hover {
    color: ${Color.link};
  }
`;
export * from '@material-ui/core/Link';

export { default as MuiList } from '@material-ui/core/List';
export * from '@material-ui/core/List';

export { default as MuiListItem } from '@material-ui/core/ListItem';
export * from '@material-ui/core/ListItem';

export { default as MuiListItemAvatar } from '@material-ui/core/ListItemAvatar';
export * from '@material-ui/core/ListItemAvatar';

export { default as MuiListItemIcon } from '@material-ui/core/ListItemIcon';
export * from '@material-ui/core/ListItemIcon';

export { default as MuiListItemSecondaryAction } from '@material-ui/core/ListItemSecondaryAction';
export * from '@material-ui/core/ListItemSecondaryAction';

export { default as MuiListItemText } from '@material-ui/core/ListItemText';
export * from '@material-ui/core/ListItemText';

export { default as MuiListSubheader } from '@material-ui/core/ListSubheader';
export * from '@material-ui/core/ListSubheader';

export { default as MuiMenu } from '@material-ui/core/Menu';
export * from '@material-ui/core/Menu';

export { default as MuiMenuItem } from '@material-ui/core/MenuItem';
export * from '@material-ui/core/MenuItem';

export { default as MuiMenuList } from '@material-ui/core/MenuList';
export * from '@material-ui/core/MenuList';

export { default as MuiMobileStepper } from '@material-ui/core/MobileStepper';
export * from '@material-ui/core/MobileStepper';

export { default as MuiModal } from '@material-ui/core/Modal';
export * from '@material-ui/core/Modal';

export { default as MuiNativeSelect } from '@material-ui/core/NativeSelect';
export * from '@material-ui/core/NativeSelect';

export { default as MuiNoSsr } from '@material-ui/core/NoSsr';
export * from '@material-ui/core/NoSsr';

export { default as MuiOutlinedInput } from '@material-ui/core/OutlinedInput';
export * from '@material-ui/core/OutlinedInput';

export { default as MuiPaper } from '@material-ui/core/Paper';
export * from '@material-ui/core/Paper';

export { default as MuiPopover } from '@material-ui/core/Popover';
export * from '@material-ui/core/Popover';

export { default as MuiPopper } from '@material-ui/core/Popper';
export * from '@material-ui/core/Popper';

export { default as MuiPortal } from '@material-ui/core/Portal';
export * from '@material-ui/core/Portal';

export { default as MuiRadio } from '@material-ui/core/Radio';
export * from '@material-ui/core/Radio';

export { default as MuiRadioGroup } from '@material-ui/core/RadioGroup';
export * from '@material-ui/core/RadioGroup';

export { default as MuiRootRef } from '@material-ui/core/RootRef';
export * from '@material-ui/core/RootRef';

export { default as MuiSelect } from '@material-ui/core/Select';
export * from '@material-ui/core/Select';

export { default as MuiSlide } from '@material-ui/core/Slide';
export * from '@material-ui/core/Slide';

export { default as MuiSlider } from '@material-ui/core/Slider';
export * from '@material-ui/core/Slider';

export { default as MuiSnackbar } from '@material-ui/core/Snackbar';
export * from '@material-ui/core/Snackbar';

export { default as MuiSnackbarContent } from '@material-ui/core/SnackbarContent';
export * from '@material-ui/core/SnackbarContent';

export { default as MuiStep } from '@material-ui/core/Step';
export * from '@material-ui/core/Step';

export { default as MuiStepButton } from '@material-ui/core/StepButton';
export * from '@material-ui/core/StepButton';

export { default as MuiStepConnector } from '@material-ui/core/StepConnector';
export * from '@material-ui/core/StepConnector';

export { default as MuiStepContent } from '@material-ui/core/StepContent';
export * from '@material-ui/core/StepContent';

export { default as MuiStepIcon } from '@material-ui/core/StepIcon';
export * from '@material-ui/core/StepIcon';

export { default as MuiStepLabel } from '@material-ui/core/StepLabel';
export * from '@material-ui/core/StepLabel';

export { default as MuiStepper } from '@material-ui/core/Stepper';
export * from '@material-ui/core/Stepper';

export { default as MuiSvgIcon } from '@material-ui/core/SvgIcon';
export * from '@material-ui/core/SvgIcon';

export { default as MuiSwipeableDrawer } from '@material-ui/core/SwipeableDrawer';
export * from '@material-ui/core/SwipeableDrawer';

export { default as MuiSwitch } from '@material-ui/core/Switch';
export * from '@material-ui/core/Switch';

export { default as MuiTab } from '@material-ui/core/Tab';
export * from '@material-ui/core/Tab';

export { default as MuiTable } from '@material-ui/core/Table';
export * from '@material-ui/core/Table';

export { default as MuiTableBody } from '@material-ui/core/TableBody';
export * from '@material-ui/core/TableBody';

export { default as MuiTableCell } from '@material-ui/core/TableCell';
export * from '@material-ui/core/TableCell';

export { default as MuiTableContainer } from '@material-ui/core/TableContainer';
export * from '@material-ui/core/TableContainer';

export { default as MuiTableFooter } from '@material-ui/core/TableFooter';
export * from '@material-ui/core/TableFooter';

export { default as MuiTableHead } from '@material-ui/core/TableHead';
export * from '@material-ui/core/TableHead';

export { default as MuiTablePagination } from '@material-ui/core/TablePagination';
export * from '@material-ui/core/TablePagination';

export { default as MuiTableRow } from '@material-ui/core/TableRow';
export * from '@material-ui/core/TableRow';

export { default as MuiTableSortLabel } from '@material-ui/core/TableSortLabel';
export * from '@material-ui/core/TableSortLabel';

export { default as MuiTabs } from '@material-ui/core/Tabs';
export * from '@material-ui/core/Tabs';

export { default as MuiTextField } from '@material-ui/core/TextField';
export * from '@material-ui/core/TextField';

export { default as MuiTextareaAutosize } from '@material-ui/core/TextareaAutosize';
export * from '@material-ui/core/TextareaAutosize';

export * from '@material-ui/pickers/TimePicker';

export { default as MuiToolbar } from '@material-ui/core/Toolbar';
export * from '@material-ui/core/Toolbar';

export { default as MuiTooltip } from '@material-ui/core/Tooltip';
export * from '@material-ui/core/Tooltip';

export { default as MuiAlert } from '@material-ui/lab/Alert';
export * from '@material-ui/lab/Alert';

export { default as MuiAlertTitle } from '@material-ui/lab/AlertTitle';
export * from '@material-ui/lab/AlertTitle';

export { default as MuiRating } from '@material-ui/lab/Rating';
export * from '@material-ui/lab/Rating';

interface MuiTypographyProps
  extends Omit<React.ComponentProps<typeof Typography.default>, 'color'> {
  fontWeight?: number | string;
  uppercase?: boolean;
  color?:
    | 'inherit'
    | 'initial'
    | 'primary'
    | 'secondary'
    | 'textPrimary'
    | 'textSecondary'
    | 'error'
    | string;
  fontSize?: FontSize;
  component?: React.ComponentType | string;
}

const colorPallette = ['primary', 'secondary', 'textPrimary', 'textSecondary', 'error'] as const;

const isColorPalletteColor = (color: any): color is typeof colorPallette[number] => {
  return colorPallette.includes(color);
};

// For convenience, since the typography doesn't accept the fontweight prop for some reason
export const MuiTypography = (props: MuiTypographyProps) => {
  return (
    <Typography.default
      {...props}
      color={isColorPalletteColor(props.color) ? props.color : undefined}
      style={{
        textTransform: props.uppercase ? 'uppercase' : undefined,
        fontWeight: props.fontWeight as number,
        fontSize: props.fontSize,
        color: !isColorPalletteColor(props.color) ? props.color : undefined,
        ...props.style
      }}
    />
  );
};
export * from '@material-ui/core/Typography';

export { default as MuiuseScrollTrigger } from '@material-ui/core/useScrollTrigger';
export * from '@material-ui/core/useScrollTrigger';

export { default as MuiwithMobileDialog } from '@material-ui/core/withMobileDialog';
export * from '@material-ui/core/withMobileDialog';

export { default as MuiwithWidth } from '@material-ui/core/withWidth';
export * from '@material-ui/core/withWidth';

export { default as MuiZoom } from '@material-ui/core/Zoom';
export * from '@material-ui/core/Zoom';

export { default as MuiAutoComplete } from '@material-ui/lab/Autocomplete';
export * from '@material-ui/lab/Autocomplete';

const createColorOptions = (main: string, contrastText?: string): SimplePaletteColorOptions => ({
  main,
  contrastText
});

const getInputFontSize = () => (isMobileBrowser() ? FontSize.large : FontSize.base);

export const dfdDefaultTheme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 480,
      md: 768,
      lg: 960,
      xl: 1440
    }
  },
  palette: {
    primary: createColorOptions(Color.primary, Color.white),
    secondary: createColorOptions(Color.secondary, Color.white),
    error: createColorOptions(Color.red),
    success: createColorOptions(Color.green),
    warning: createColorOptions(Color.warning),
    info: createColorOptions(Color.tertiary),
    text: {
      primary: Color.textDark,
      secondary: Color.textLight,
      disabled: Color.gray
    }
  },
  overrides: {
    // There is an issue currently of the input width bleeding out of variant='outlined' border - this fixes the issue
    MuiInputBase: {
      input: {
        minHeight: 53.63,
        boxSizing: 'border-box',
        fontSize: getInputFontSize()
      }
    },
    MuiFormLabel: {
      asterisk: {
        color: Color.red
      }
    },
    MuiIconButton: {
      root: {
        color: Color.secondary,
        fill: Color.secondary,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: Color.transparent,
        '&.Mui-disabled': {
          fill: Color.disabledColor,
          background: Color.disabledBGColor
        }
      }
    },
    MuiBackdrop: {
      root: {
        backgroundColor: rgba(Color.primary, 0.5)
      }
    },
    MuiButton: {
      root: {
        padding: '8px 16px',
        maxWidth: '327px',
        maxHeight: '44px',
        fontSize: 16,
        lineHeight: '24px',
        fontWeight: 600
      },
      outlined: {
        padding: '8px 16px'
      },
      text: {
        padding: '8px 16px'
      },
      textPrimary: {
        color: Color.secondary,
        fill: Color.secondary,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: Color.transparent,
        '&:hover': {
          backgroundColor: Color.outlinedPressed
        },
        '&:active': {
          borderColor: Color.secondary
        }
      },
      outlinedPrimary: {
        color: Color.secondary,
        fill: Color.secondary,
        borderColor: Color.secondary,
        backgroundColor: Color.white,
        '&:hover': {
          color: Color.secondary,
          backgroundColor: Color.outlinedPressed,
          borderColor: Color.transparent
        },
        '&:active': {
          backgroundColor: Color.outlinedPressed,
          borderColor: Color.secondary
        }
      },
      containedPrimary: {
        fill: Color.white,
        backgroundColor: Color.secondary,
        '&:hover': {
          backgroundColor: Color.primaryHover
        },
        '&:active': {
          backgroundColor: Color.containedPressed
        },
        '&.Mui-disabled': {
          color: Color.disabledColor,
          fill: Color.disabledColor,
          background: Color.disabledBGColor
        }
      },
      containedSecondary: {
        fill: Color.white,
        backgroundColor: Color.primary,
        '&:hover': {
          backgroundColor: Color.secondary
        },
        '&:active': {
          backgroundColor: Color.containedPressed
        },
        '&.Mui-disabled': {
          color: Color.disabledColor,
          background: Color.disabledBGColor
        }
      }
    }
  },
  typography: {
    fontWeightBold: 700,
    fontWeightMedium: 600,
    fontWeightRegular: 400,
    fontWeightLight: 300,
    fontFamily: [
      'ABC Social Variable',
      'Public Sans',
      'Rector Web Regular',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(','),
    subtitle1: {
      color: Color.textLight,
      fontSize: 13
    },
    subtitle2: {
      color: Color.textDark,
      fontSize: 16
    },
    caption: {
      color: Color.textLight
    },
    h6: {
      fontWeight: 500,
      fontSize: 18
    },
    button: {
      textTransform: 'none'
    }
  }
});

export type MuiTheme = typeof dfdDefaultTheme;

export const DfdMuiThemeProvider: React.FC = ({ children }) => (
  <ThemeProvider theme={dfdDefaultTheme}>{children}</ThemeProvider>
);

export * from '@material-ui/core/useMediaQuery';

export const useMuiMediaQuery = (arg: (theme: MuiTheme) => string) =>
  useMediaQuery<typeof dfdDefaultTheme>(arg);

export const CursorMuiBox = styled(Box)`
  cursor: pointer;
`;
