import React, { ComponentType, useEffect } from 'react';
import { ScrollView, TouchableOpacity } from 'react-native';
import { connect } from 'react-redux';
import { RootState } from 'store/types';
import LinkingServices from 'services/LinkingServices';
import formatMoney from 'services/formatMoney';
import { Insurance } from 'store/profile/types';
import FlexBox from 'components/UI/Layout/FlexBox';
import Typography from 'components/UI/Typography';
import { Color } from 'modules/styles/colors';
import { Spacing, FontSize, FontWeight } from 'modules/styles/variables';
import Screen from 'components/UI/Layout/Screen';
import Box from 'components/UI/Layout/Box';
import Spacer from 'components/UI/Layout/Spacer';
import { CenteredSpinner } from 'components/UI/Spinner/SpinnerContainer';
import { InfoButton } from 'components/InfoButton';
import Banner from 'components/UI/Banner/Banner';
import { getHsaSummary } from 'store/billing/actions';
import { getHsaSummarySelector } from 'store/billing/selectors';
import { selectHealthFacetsInsuranceSelector } from 'store/profile/selectors';
import { tooltipDataSelector } from 'store/tooltips/selectors';
import { getTooltips } from 'store/tooltips/actions';
import { HsaSummaryState } from 'store/billing/reducers';
import { Tooltip } from '@material-ui/core';

interface Props {
  shInsurance: Insurance;
  getTooltips: typeof getTooltips;
  getHsaSummary: typeof getHsaSummary;
  tooltipData: typeof Tooltip[];
  hsaSummaryData: HsaSummaryState;
}

const HSADetails = (props: Props) => {
  const { data: hsaSummaryData, isFetching: hsaLoading } = props.hsaSummaryData;

  const availableBalance = hsaSummaryData?.availableBalance || 0;
  const investmentAmount = hsaSummaryData?.investmentAmount || 0;
  const contributionAmount = hsaSummaryData?.contributionAmount || 0;
  const distributionAmount = hsaSummaryData?.distributionAmount || 0;

  const renderSpinner = () => <CenteredSpinner opacity={0.5} backgroundColor="#fff" />;

  useEffect(() => {
    const { subscriberId } = props.shInsurance;
    if (subscriberId) {
      getHsaSummary(subscriberId);
    }
    props.getTooltips('hsaDetails');
  }, []);

  return (
    <>
      <Banner message="Health Savings Account" />

      <Screen safe>
        <ScrollView>
          {hsaLoading ? renderSpinner() : null}
          <Box hSpacing={Spacing.large}>
            <Spacer size="small" />
            <Box hSpacing={Spacing.medium}>
              <Spacer size="small" />
              <Box vSpacing={Spacing.large}>
                <FlexBox flexDirection="row" justifyContent="space-between">
                  <Typography fontWeight={FontWeight.semibold}>Available to spend:</Typography>
                  <Typography fontWeight={FontWeight.semibold}>
                    ${formatMoney(availableBalance)}
                  </Typography>
                </FlexBox>
                <Typography fontSize={FontSize.small}>
                  Amount that can be drawn and used towards your healthcare
                </Typography>
              </Box>
              <Box vSpacing={Spacing.large}>
                <FlexBox flexDirection="row" justifyContent="space-between">
                  <Typography fontWeight={FontWeight.semibold}>Investment amount:</Typography>
                  <Typography fontWeight={FontWeight.semibold}>
                    ${formatMoney(investmentAmount)}
                  </Typography>
                </FlexBox>
                <Typography fontSize={FontSize.small}>
                  Amount invested in financial assets and not considered as amount available to be
                  spent towards healthcare
                </Typography>
              </Box>
              <Box vSpacing={Spacing.large}>
                <FlexBox flexDirection="row" justifyContent="space-between">
                  <Typography fontWeight={FontWeight.semibold}>Contributions YTD:</Typography>
                  <Typography fontWeight={FontWeight.semibold}>
                    ${formatMoney(contributionAmount)}
                  </Typography>
                </FlexBox>
                <Typography fontSize={FontSize.small}>Amount contributed since Jan 1st</Typography>
              </Box>
              <Box vSpacing={Spacing.large}>
                <FlexBox flexDirection="row" justifyContent="space-between">
                  <Typography fontWeight={FontWeight.semibold}>Distributions YTD:</Typography>
                  <Typography fontWeight={FontWeight.semibold}>
                    ${formatMoney(distributionAmount)}
                  </Typography>
                </FlexBox>
                <Typography fontSize={FontSize.small}>
                  Amount withdrawn from HSA since Jan 1st
                </Typography>
              </Box>
              <Box vSpacing={Spacing.xLarge}>
                <Typography textAlign="center">
                  If you have any questions, please call SelectHealth Member Services at{' '}
                  <TouchableOpacity onPress={() => LinkingServices.callPhoneNumber('18005385038')}>
                    <Typography
                      textAlign="center"
                      accessibilityLabel="Call SelectHealth Member Services button"
                      color={Color.link}
                    >
                      1-800-538-5038
                    </Typography>
                  </TouchableOpacity>
                </Typography>
              </Box>
              <FlexBox flexDirection="row" justifyContent="center" alignItems="center">
                <Box hSpacing={Spacing.small}>
                  <Typography>Need help understanding this page?</Typography>
                </Box>
                <InfoButton definitions={props.tooltipData} hideBorder color={Color.link} />
              </FlexBox>
            </Box>
          </Box>
        </ScrollView>
      </Screen>
    </>
  );
};

const mapDispatch = {
  getTooltips,
  getHsaSummary
};

const mapStateToProps = (state: RootState) => ({
  shInsurance: selectHealthFacetsInsuranceSelector(state),
  tooltipData: tooltipDataSelector(state),
  hsaSummaryData: getHsaSummarySelector(state)
});

export default connect(mapStateToProps, mapDispatch)(HSADetails as ComponentType);
