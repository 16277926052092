import { connect } from 'react-redux';
import { RootState } from 'store/types';
import get from 'lodash/get';
import * as vitalsSelectors from 'store/vitals/selectors';
import VitalsSummary from '../VitalsSummary';

const mapStateToProps = (state: RootState) => ({
  // isFetching: vitalsSelectors.encounterVitalsFetchingSelector(state),
  vitals: vitalsSelectors.encounterVitalsSelector(state),
  error: get(state, 'vitals.encounterVitalsError', false)
});

export default connect(mapStateToProps)(VitalsSummary);
