import { Color } from 'modules/styles/colors';
import { Spacing, ZIndex, Elevation } from 'modules/styles/variables';
import styled from 'styled-components';
import FlexBox, { FlexBoxRow } from 'components/UI/Layout/FlexBox';
import {
  MuiDialog,
  MuiTextField,
  MuiBox,
  dfdDefaultTheme,
  MuiPaper,
  MuiAlert
} from 'theme/material-ui';
import { Chip } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';

export const MessageTextInput = styled(MuiTextField)`
  & .MuiInputBase-root {
    background-color: ${Color.cobaltTint};
  }
`;

export const SearchInput = styled(MuiTextField)`
  &.MuiTextField-root {
    background: ${Color.white};
  }
`;
export const MessageRecepientTag = styled(Chip)`
  &.MuiChip-root {
    margin-left: ${Spacing.large}px;
    border-radius: ${Spacing.small}px;
    & > .MuiChip-label {
      white-space: break-spaces;
    }
  }
`;

export const StyledDialog = styled(MuiDialog)`
  & .MuiDialog-paperWidthSm {
    width: 100%;
    height: 100%;
    margin: 0;
  }
  & .MuiDialog-paperScrollPaper {
    max-height: 100%;
  }
  & .MuiDialog-paperWidthSm {
    max-width: 100%;
  }
`;

export const DisclaimerFlexBox = styled(FlexBox)`
  padding: 20px 40px;
  align-items: center;
  flex-direction: row;
  background-color: ${Color.grayLight3};
  @media (max-width: 375px) {
    padding-left: ${Spacing.large}px;
    padding-right: ${Spacing.large}px;
  }
`;

export const MessageDialog = styled(MuiBox)`
  position: fixed;
  margin: 0;
  right: 0;
  bottom: 0;
  max-width: 800px;
  max-height: 855px;
  width: 100%;
  z-index: ${ZIndex.high};
  background: ${Color.white};
  border-top-left-radius: ${Spacing.small}px;
  border-top-right-radius: ${Spacing.small}px;
  box-shadow: 5px 15px 25px ${Color.textLight};
  & > .MuiBox-root {
    overflow-y: scroll;
    max-height: 800px;
  }
  ${dfdDefaultTheme.breakpoints.down('sm')} {
    max-height: 100%;
    & > .MuiBox-root {
      max-height: 100%;
    }
  }
  @media (max-height: 1000px) {
    max-height: 100vh;
    & > .MuiBox-root {
      max-height: 90vh;
    }
  }
`;

export const MessageDialogHeader = styled(FlexBoxRow)`
  justify-content: space-between;
  padding: ${Spacing.large}px;
  color: ${Color.white};
  background-color: ${Color.primary};
  border-top-left-radius: ${Spacing.small}px;
  border-top-right-radius: ${Spacing.small}px;
`;
export const NewMessageButton = styled(MuiBox)`
  text-align: right;
  margin-top: ${Spacing.mediumLarge}px;
  margin-bottom: -${Spacing.smallMedium}px;
  & > .MuiButton-root {
    min-height: 40px;
    min-width: 250px;
    border-radius: 3px;
  }
  ${dfdDefaultTheme.breakpoints.down('sm')} {
    text-align: center;
  }
  ${dfdDefaultTheme.breakpoints.down('md')} {
    margin-bottom: ${Spacing.medium}px;
  }
`;

export const SnackBar = styled(Snackbar)`
  &.MuiSnackbar-root {
    position: relative;
    bottom: 0;
    justify-content: flex-start;
    margin-top: ${Spacing.medium}px;
  }
  & > .MuiAlert-root {
    width: 47%;
    left: 1%;
    align-items: center;
  }
  ${dfdDefaultTheme.breakpoints.down('xs')} {
    & > .MuiAlert-root {
      left: 0;
      width: 95%;
    }
  }
`;

export const SearchProviderBar = styled(MuiBox)`
  width: 52%;
  border: 1px solid ${Color.grayHue5};
  border-radius: 30px;
  z-index: ${ZIndex.high};
  ${dfdDefaultTheme.breakpoints.down('sm')} {
    width: 100%;
  }
  & > .MuiFormControl-root {
    & > .MuiInput-root {
      max-height: 40px;
    }
  }
`;

export const ProviderOptions = styled(MuiPaper)`
  position: absolute;
  z-index: ${ZIndex.high};
  width: 52%;
  max-height: 500px;
  overflow-y: scroll;
  ${dfdDefaultTheme.breakpoints.down('sm')} {
    width: 100%;
  }
`;

export const BgCurtain = styled(MuiBox)`
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: ${ZIndex.medium};
`;

export const AlertSuccessMessage = styled(MuiAlert)`
  position: absolute;
  top: 25%;
  left: 50%;
  z-index: ${ZIndex.high};
  transform: translateX(-50%) scale(1.4);
  min-width: 300px;
  border-left: 1px solid ${Color.green};
  &.MuiAlert-root {
    background-color: ${Color.white};
    box-shadow: ${Elevation.el8};
  }
  & > .MuiAlert-icon {
    align-items: center;
  }
`;
