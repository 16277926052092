import isEmpty from 'lodash/isEmpty';
import { objectToQsParam } from 'modules/utils/UrlUtils';
import { VisitSummary } from 'store/visitSummary/types';

export const getVisitSummaryLinkPath = (visit: VisitSummary) => {
  const urlBase = `/u/health-record/visit-summaries/live`;
  const urlSegment1 = `${visit.source}/${visit.id}`;
  let url = `${urlBase}/${urlSegment1}/${visit.date}`;
  if (!isEmpty(visit.mhpDetails)) {
    url = `${urlBase}-detailed/${urlSegment1}/${objectToQsParam(visit.mhpDetails)}`;
  }
  return url;
};
