import Splash from 'components/common/LoadingSplash/LoadingSplash';
import React from 'react';

function AuthCallback() {
  return (
    <>
      <Splash />
    </>
  );
}

export default AuthCallback;
