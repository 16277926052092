import React from 'react';
import { oc } from 'ts-optchain';

import Svg from 'components/UI/Svg/Svg';
import { MuiBox, MuiContainer, MuiGrid, MuiTypography } from 'theme/material-ui';

import { Color } from 'modules/styles/colors';
import { FontSize } from 'modules/styles/variables';

import { history } from 'lib/history';

import { ContentWrapper, NumberLink } from './styled';
import { PATIENT_FINANCIAL_SERVICE_NUMBER } from '../constants';
import FADisclaimer from '../FADisclaimer';

export const PreCompleteScreen = () => {
  const applicationID = oc(history).location.state.applicationId('#####');

  return (
    <MuiContainer>
      <MuiBox mb={5}>
        <ContentWrapper>
          <MuiBox p={6}>
            <MuiGrid container spacing={6}>
              <MuiGrid item md={7} sm={12}>
                <MuiBox mb={1}>
                  <MuiTypography fontSize={FontSize.large}>
                    <MuiTypography
                      component="span"
                      color={Color.grayHue3}
                      fontSize={FontSize.heading}
                    >
                      Success!{' '}
                    </MuiTypography>
                    Your Financial Assistance Application has been submitted successfully.
                  </MuiTypography>
                </MuiBox>
                <MuiBox mb={2}>
                  <MuiTypography fontSize={FontSize.large}>
                    Your Application Number is:{' '}
                    <MuiTypography component="span" fontSize={18} color={Color.primaryHue6}>
                      {applicationID}
                    </MuiTypography>
                  </MuiTypography>
                </MuiBox>
                <MuiBox mb={2}>
                  <MuiTypography fontSize={FontSize.large}>
                    Please save your Application Number and include it on all documents and
                    correspondence with Intermountain related to financial assistance.
                  </MuiTypography>
                </MuiBox>
                <MuiBox mb={2}>
                  <MuiTypography fontSize={FontSize.large}>
                    Our records show that we have all the information required at this time and
                    Intermountain will contact you via mail if it requires any updated verification
                    information
                  </MuiTypography>
                </MuiBox>
                <MuiBox>
                  <MuiTypography fontSize={FontSize.large}>
                    If your circumstances have changed (e.g., change in job or source of income)
                    from when you last submitted your Financial Assistance application, please call
                    Patient Financial Services at <a href="tel:18664156556">1-866-415-6556</a> to
                    update your profile.
                  </MuiTypography>
                </MuiBox>
              </MuiGrid>
              <MuiGrid item md={5} sm={12}>
                <MuiBox
                  display="flex"
                  alignItems="center"
                  bgcolor="grey.100"
                  p={2}
                  borderRadius={2}
                  mb={2}
                >
                  <Svg
                    name="Timer"
                    strokeWidth="0.25px"
                    fill={Color.primary}
                    height="40"
                    width="40"
                  />
                  <MuiBox pl={1}>
                    <MuiTypography color="primary">
                      {`Estimated processing time: `}
                      <b>2 weeks</b>.
                    </MuiTypography>
                  </MuiBox>
                </MuiBox>
                <MuiBox bgcolor="grey.100" p={2} borderRadius={2} mb={2}>
                  <MuiTypography color="primary">
                    If you need help, please call our Financial Assistance Dept.
                  </MuiTypography>
                  <MuiTypography variant="h6">
                    <NumberLink href={`tel:${PATIENT_FINANCIAL_SERVICE_NUMBER}`}>
                      {PATIENT_FINANCIAL_SERVICE_NUMBER}
                    </NumberLink>
                  </MuiTypography>
                </MuiBox>
              </MuiGrid>
            </MuiGrid>
          </MuiBox>
        </ContentWrapper>
      </MuiBox>
      <MuiBox mb={5}>
        <FADisclaimer />
      </MuiBox>
    </MuiContainer>
  );
};

export default PreCompleteScreen;
