import React from 'react';

export default {
  svg: (
    <>
      <defs>
        <polygon
          id="path-1"
          points="2.85714286e-05 0.228542857 56.4028571 0.228542857 56.4028571 79.9999714 2.85714286e-05 79.9999714"
        />
        <polygon id="path-3" points="0 89.9628571 56.4028571 89.9628571 56.4028571 0 0 0" />
      </defs>
      <g id="Page-1" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Illustrations-/-E-Visit-Summary">
          <rect id="Rectangle-Copy" x={0} y={0} width={100} height={100} />
          <g id="Group-19" transform="translate(22.000000, 5.000000)">
            <g id="Group-3" transform="translate(0.000000, 9.962857)">
              <mask id="mask-2" fill="white">
                <use xlinkHref="#path-1" />
              </mask>
              <g id="Clip-2" />
              <path
                d="M3.52574286,0.228542857 L52.8768857,0.228542857 C54.8243143,0.228542857 56.4028857,1.80711429 56.4028857,3.75425714 L56.4028857,76.4742571 C56.4028857,78.4214 54.8243143,79.9999714 52.8768857,79.9999714 L3.52574286,79.9999714 C1.5786,79.9999714 2.85714286e-05,78.4214 2.85714286e-05,76.4742571 L2.85714286e-05,3.75425714 C2.85714286e-05,1.80711429 1.5786,0.228542857 3.52574286,0.228542857"
                id="Fill-1"
                fill="#969696"
                mask="url(#mask-2)"
              />
            </g>
            <mask id="mask-4" fill="white">
              <use xlinkHref="#path-3" />
            </mask>
            <g id="Clip-5" />
            <polygon
              id="Fill-4"
              fill="#FFFFFF"
              mask="url(#mask-4)"
              points="3.52571429 86.4342857 52.88 86.4342857 52.88 13.7142857 3.52571429 13.7142857"
            />
            <polygon
              id="Fill-6"
              fill="#FFFFFF"
              mask="url(#mask-4)"
              points="52.8800857 13.7142857 3.5258 13.7142857 3.5258 78.2 11.7629429 86.4371429 52.8800857 86.4371429"
            />
            <path
              d="M28.2171429,3.1028 C29.3217143,3.1028 30.2171429,3.99822857 30.2171429,5.1028 C30.2171429,6.20737143 29.3217143,7.1028 28.2171429,7.1028 C27.1125714,7.1028 26.2171429,6.20737143 26.2171429,5.1028 C26.2171429,4.00394286 27.104,3.1108 28.2028571,3.1028 L28.2171429,3.1028 Z M43.2171429,17.6285143 L43.2171429,9.69994286 C43.2171429,8.12194286 41.9377143,6.8428 40.36,6.8428 L34.9914286,6.8428 C34.0445714,6.8428 33.2771429,6.07537143 33.2771429,5.12851429 L33.2771429,5.07137143 C33.2677143,2.27451429 31.0025714,0.00937142857 28.2057143,-5.71428571e-05 L28.1885714,-5.71428571e-05 C25.3917143,0.00937142857 23.1265714,2.27451429 23.1171429,5.07137143 L23.1171429,5.12851429 C23.1171429,6.07537143 22.3497143,6.8428 21.4028571,6.8428 L16.0514286,6.8428 C14.4734286,6.8428 13.1942857,8.12194286 13.1942857,9.69994286 L13.1942857,17.6285143 L43.2171429,17.6285143 Z"
              id="Fill-7"
              fill="#D7D7D7"
              mask="url(#mask-4)"
            />
            <path
              d="M28.2029143,7.09137143 C27.0983429,7.09137143 26.2029143,6.19594286 26.2029143,5.09137143 C26.2029143,3.9868 27.0983429,3.09137143 28.2029143,3.09137143 C29.3074857,3.09137143 30.2029143,3.9868 30.2029143,5.09137143 C30.2029143,6.19594286 29.3074857,7.09137143 28.2029143,7.09137143 M28.2029143,1.9628 C26.4672,1.9628 25.0600571,3.36994286 25.0600571,5.10565714 C25.0600571,6.84137143 26.4672,8.24851429 28.2029143,8.24851429 C29.9386286,8.24851429 31.3457714,6.84137143 31.3457714,5.10565714 C31.3457714,3.36994286 29.9386286,1.9628 28.2029143,1.9628"
              id="Fill-8"
              fill="#D1D3D4"
              mask="url(#mask-4)"
            />
            <polygon
              id="Fill-9"
              fill="#E6E7E8"
              mask="url(#mask-4)"
              points="13.2028571 17.6257143 43.2028571 17.6257143 43.2028571 9.7 13.2028571 9.7"
            />
            <polygon
              id="Fill-10"
              fill="#D1D3D4"
              mask="url(#mask-4)"
              points="13.2028571 17.6257143 43.2028571 17.6257143 43.2028571 15.42 13.2028571 15.42"
            />
            <path
              d="M28.2029143,22.1485714 C23.2700571,22.1485714 19.2714857,26.1474286 19.2714857,31.08 C19.2714857,36.0125714 23.2700571,40.0114286 28.2029143,40.0114286 C33.1357714,40.0114286 37.1343429,36.0125714 37.1343429,31.08 C37.1294857,26.1491429 33.1337714,22.1534286 28.2029143,22.1485714"
              id="Fill-11"
              fill="#2765B8"
              mask="url(#mask-4)"
            />
            <polygon
              id="Fill-12"
              fill="#FFFFFF"
              mask="url(#mask-4)"
              points="29.5742857 25.3285429 26.8314286 25.3285429 26.8314286 29.7199714 22.44 29.7199714 22.44 32.4628286 26.8314286 32.4628286 26.8314286 36.8514 29.5742857 36.8514 29.5742857 32.4628286 33.9657143 32.4628286 33.9657143 29.7199714 29.5742857 29.7199714"
            />
            <polygon
              id="Fill-13"
              fill="#E6E7E8"
              mask="url(#mask-4)"
              points="10.5771429 47.3714286 45.8285714 47.3714286 45.8285714 44.5542857 10.5771429 44.5542857"
            />
            <polygon
              id="Fill-14"
              fill="#E6E7E8"
              mask="url(#mask-4)"
              points="10.5771429 52.354 45.8285714 52.354 45.8285714 49.5371429 10.5771429 49.5371429"
            />
            <polygon
              id="Fill-15"
              fill="#E6E7E8"
              mask="url(#mask-4)"
              points="10.5771429 57.3371429 45.8285714 57.3371429 45.8285714 54.52 10.5771429 54.52"
            />
            <polygon
              id="Fill-16"
              fill="#E6E7E8"
              mask="url(#mask-4)"
              points="10.5771429 62.3197143 45.8285714 62.3197143 45.8285714 59.5028571 10.5771429 59.5028571"
            />
            <polygon
              id="Fill-17"
              fill="#E6E7E8"
              mask="url(#mask-4)"
              points="10.5771429 67.3028571 45.8285714 67.3028571 45.8285714 64.4857143 10.5771429 64.4857143"
            />
            <polygon
              id="Fill-18"
              fill="#E6E7E8"
              mask="url(#mask-4)"
              points="10.5771429 72.2857143 33.4914286 72.2857143 33.4914286 69.4685714 10.5771429 69.4685714"
            />
          </g>
        </g>
      </g>
    </>
  ),
  viewBox: '0 0 100 100'
};
