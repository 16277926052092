import React from 'react';
import { MuiBox, MuiLink, MuiPaper, MuiTypography } from 'theme/material-ui';
import { PRIVACY_NOTICES_URL } from 'lib/constants/help';

export const PrivacyNotices = () => {
  return (
    <MuiPaper>
      <MuiBox data-testid="privacy-notices" p={3}>
        <MuiTypography>
          For the Privacy Notices, please visit{' '}
          <MuiLink target="_blank" rel="noopener noreferrer" href={PRIVACY_NOTICES_URL}>
            IntermountainHealthcare.org
          </MuiLink>
        </MuiTypography>
      </MuiBox>
    </MuiPaper>
  );
};

export default PrivacyNotices;
