import React from 'react';

export default {
  svg: (
    <g>
      <g>
        <circle fill="#BF00E6" strokeWidth={0} cx={40.16} cy={39.592} r={38.5} />
        <path
          fill="#FFFFFF"
          strokeWidth={0}
          d="M40.16,2.092c20.711,0,37.5,16.789,37.5,37.5s-16.789,37.5-37.5,37.5c-20.711,0-37.5-16.789-37.5-37.5 S19.449,2.092,40.16,2.092 M40.16,0.092c-21.78,0-39.5,17.72-39.5,39.5s17.72,39.5,39.5,39.5s39.5-17.72,39.5-39.5 S61.94,0.092,40.16,0.092L40.16,0.092z"
        />
      </g>
      <g>
        <defs>
          <circle id="SVGID_31_" cx={40.16} cy={39.592} r={37.5} />
        </defs>
        <clipPath id="SVGID_2_8">
          <use xlinkHref="#SVGID_31_" />
        </clipPath>
        <g id="Femaie_-_Child_1_" clipPath="url(#SVGID_2_8)">
          <path
            fill="#F6CCB8"
            strokeWidth={0}
            d="M58.587,35.739l0.503-6.534c0-8.545-8.167-15.468-16.706-15.468c-8.545,0-16.706,6.924-16.706,15.468 l0.502,6.528c-0.478,0.115-0.909,0.334-1.269,0.643c-0.668,0.569-1.089,1.411-1.089,2.357v3.217c0,1.701,1.37,3.081,3.074,3.092 c0,5.193,4.017,10.209,8.424,13.127v6.9l-2.324,1.003l0.619,20.859h1.705v3.198H50.17v-3.198h1.695l0.619-20.795l-2.314-1.067 v-7.355h-0.062c4.14-2.967,7.763-7.739,7.763-12.671c1.698-0.012,3.072-1.39,3.072-3.091v-3.217 C60.943,37.281,59.937,36.071,58.587,35.739z"
          />
          <path
            fill="#EEAB91"
            strokeWidth={0}
            d="M58.587,35.739l0.503-6.534c0-8.545-8.167-15.468-16.706-15.468c-0.003,0-0.003,21.965-0.003,21.965 c-2.257,4.8-3.712,11.756-3.712,11.756h3.712V60.76c-2.064-0.001-4.622-0.976-7.061-2.591v2.773l14.926,2.967l-0.07-6.245 c-0.007,0.005-0.014,0.009-0.021,0.014c4.12-2.969,7.715-7.723,7.715-12.636c1.698-0.012,3.072-1.39,3.072-3.091v-3.217 C60.943,37.281,59.937,36.071,58.587,35.739z M42.382,60.76c0.004,0,0.009-0.001,0.013-0.001c-0.003,0-0.007,0.001-0.011,0.001 C42.383,60.76,42.382,60.76,42.382,60.76z M48.125,58.975c0.026-0.014,0.052-0.029,0.077-0.044 C48.176,58.945,48.151,58.96,48.125,58.975z M43.159,60.712c0.065-0.007,0.13-0.014,0.196-0.022 C43.289,60.698,43.224,60.705,43.159,60.712z M44.152,60.552c0.044-0.01,0.088-0.022,0.132-0.032 C44.24,60.529,44.196,60.542,44.152,60.552z M45.043,60.314c0.056-0.017,0.111-0.033,0.168-0.051 C45.155,60.281,45.099,60.297,45.043,60.314z M46.092,59.947c0.042-0.017,0.084-0.035,0.126-0.052 C46.177,59.912,46.134,59.93,46.092,59.947z M47.122,59.496c0.01-0.005,0.021-0.009,0.031-0.014 C47.143,59.486,47.133,59.491,47.122,59.496z"
          />
          <g>
            <g>
              <path
                fill="#F7CDB9"
                strokeWidth={0}
                d="M37.708,37.485c0.047,1.349-1.008,2.48-2.357,2.527c-1.349,0.047-2.48-1.008-2.527-2.357 c-0.047-1.349,1.008-2.48,2.357-2.527C36.529,35.081,37.661,36.136,37.708,37.485z"
              />
              <path
                fill="#3C2417"
                strokeWidth={0}
                d="M37.708,36.866c0.047,1.349-1.008,2.48-2.357,2.527c-1.349,0.047-2.48-1.008-2.527-2.357 c-0.047-1.349,1.008-2.48,2.357-2.527C36.529,34.462,37.661,35.517,37.708,36.866z"
              />
              <path
                fill="#FFFFFF"
                strokeWidth={0}
                d="M36.706,36.167c0.012,0.337-0.252,0.62-0.589,0.632c-0.337,0.012-0.62-0.252-0.632-0.589 c-0.012-0.337,0.252-0.62,0.589-0.632C36.411,35.566,36.694,35.83,36.706,36.167z"
              />
            </g>
            <g>
              <path
                fill="#F7CDB9"
                strokeWidth={0}
                d="M51.939,37.485c0.047,1.349-1.008,2.48-2.357,2.527c-1.349,0.047-2.48-1.008-2.527-2.357 c-0.047-1.349,1.008-2.48,2.357-2.527C50.76,35.081,51.892,36.136,51.939,37.485z"
              />
              <path
                fill="#3C2417"
                strokeWidth={0}
                d="M51.939,36.866c0.047,1.349-1.008,2.48-2.357,2.527c-1.349,0.047-2.48-1.008-2.527-2.357 c-0.047-1.349,1.008-2.48,2.357-2.527C50.76,34.462,51.892,35.517,51.939,36.866z"
              />
              <path
                fill="#FFFFFF"
                strokeWidth={0}
                d="M50.937,36.167c0.012,0.337-0.252,0.62-0.589,0.632c-0.337,0.012-0.62-0.252-0.632-0.589 c-0.012-0.337,0.252-0.62,0.589-0.632C50.642,35.566,50.925,35.83,50.937,36.167z"
              />
            </g>
          </g>
          <g>
            <path
              fill="#FFFFFF"
              strokeWidth={0}
              d="M47.951,50.25c0,2.734-2.493,4.95-5.569,4.95c-3.075,0-5.569-2.216-5.569-4.95H47.951z"
            />
          </g>
          <path
            fill="#F4BB6E"
            strokeWidth={0}
            d="M43.522,12.925c-4.491-0.055-7.928,0.863-10.529,2.344c-0.468-0.69-1.105-1.478-1.97-2.344 c-2.5-2.5-5.625-3.75-8.75-1.875c-5.625,3.75-7.5,13.75-5,18.125c1.875,4.375,1.25,6.25-0.625,8.75 c-1.875,1.875-5,1.25-6.875,1.25c0,0,0.625,1.25,4.375,2.5c6.875,2.5,8.474-4.39,8.75-6.875c0.625-5.625,0.625-12.5,2.5-15.625 c1.522-1.827,3.044-1.724,4.205-1.14c-3.76,4.33-4.305,10.857-3.58,17.389c0.625-2.5,6.25-5,6.25-5l1.875-5c0,0,0.625,3.75,0,5 c1.25-1.25,3.125-6.25,3.125-6.25s1.25,3.125,1.25,5.625c18.125-1.25,20.459,5.707,20.459,5.707 C60.342,19.179,54.219,13.056,43.522,12.925z"
          />
          <g>
            <path
              fill="#FF7BAC"
              strokeWidth={0}
              d="M70.547,100.092c-0.164-6.025-0.356-11.022-0.568-12.89L69.95,86.95 c-0.636-5.61-1.426-12.593-6.368-17.336c-4.252-4.08-9.159-4.143-13.496-4.188c0-0.001-1.237,4.592-1.237,4.592L42.97,83.334 l-5.884-13.329l-0.799-4.581c-4.337,0.047-9.682,0.11-13.934,4.19c-4.943,4.743-5.733,11.727-6.368,17.339l-0.028,0.248 c-0.212,1.868-0.404,6.865-0.568,12.89H70.547z"
            />
            <polygon
              fill="#FC5498"
              strokeWidth={0}
              points="50.355,71.521 57.019,69.058 50.059,64.304 42.97,83.334 42.97,83.334 35.88,64.304  28.92,69.058 35.585,71.521 32.969,75.087 42.361,82.833 42.361,100.092 43.577,100.092 43.577,82.833 42.97,83.334  42.97,83.334 52.971,75.087  "
            />
          </g>
        </g>
      </g>
    </g>
  ),
  viewBox: '0 0 80 80'
};
