import { useMediaQuery, useTheme } from '@material-ui/core';
import CollapsibleListItem from 'components/common/CollapsibleList/CollapsibleListItem';
import Box from 'components/UI/Layout/Box';
import FlexBox from 'components/UI/Layout/FlexBox';
import ConfirmModal from 'components/UI/Modals/ConfirmModal';
import Svg from 'components/UI/Svg/Svg';
import Typography from 'components/UI/Typography';
import { HEALTH_RECORDS_DRAWERS } from 'lib/constants/healthRecords';
import { Color } from 'modules/styles/colors';
import { IconSize, Spacing } from 'modules/styles/variables';
import { Nullable } from 'modules/types/common';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { downloadHealthRecordsSummary } from 'store/healthRecords/actions';
import {
  healthRecordsSummaryDownloadErrorSelector,
  healthRecordsSummaryIsDownloadingSelector
} from 'store/healthRecords/selectors';
import { AnalyticsEvent } from 'services/AnalyticsService';
import useNavigationAnalytics from 'hooks/useNavigationAnalytics';
import { RootState } from 'store/types';
import { MuiButton, MuiCard, MuiCardContent } from 'theme/material-ui';
import { useHistory } from 'react-router';

export interface HealthRecordsSummaryProps {
  downloadHealthRecordsSummary: (format: 'pdf' | 'xml') => Promise<void>;
  error: Nullable<Error>;
  isDownloadingSummary: boolean;
  currentUrl?: string;
  referringUrl?: string;
}

export const HealthRecordsSummary = ({
  downloadHealthRecordsSummary,
  error,
  isDownloadingSummary
}: HealthRecordsSummaryProps) => {
  const theme = useTheme();
  const history = useHistory();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleDownloadSummaryClicked = useNavigationAnalytics(
    AnalyticsEvent.DownloadHealthRecordSummaryClicked
  );
  const handleShareSummaryClicked = useNavigationAnalytics(
    AnalyticsEvent.ShareHealthRecordSummaryClicked
  );
  const handleMoreInfoClicked = useNavigationAnalytics(
    AnalyticsEvent.CompleteCopyOfMedicalRecordMoreInformationClicked
  );

  const handleDownload = (format: 'pdf' | 'xml') => {
    handleDownloadSummaryClicked();
    downloadHealthRecordsSummary(format);
    setIsModalOpen(false);
  };

  return (
    <CollapsibleListItem
      leftIcon="HrDownload"
      leftIconProps={{ set: 'assets', color: Color.black, stroke: Color.black }}
      title={HEALTH_RECORDS_DRAWERS.HealthRecordsSummary.title}
      subtitle={HEALTH_RECORDS_DRAWERS.HealthRecordsSummary.subtitle}
      analyticsEvent={AnalyticsEvent.HealthRecordSummaryClicked}
      currentUrlForAnalytics={handleDownloadSummaryClicked?.currentUrl}
      referringUrlForAnalytics={handleDownloadSummaryClicked?.referringUrl}
      detailsPadding="0"
    >
      <MuiCard>
        <MuiCardContent>
          <FlexBox
            flexDirection={isSmallScreen ? 'column' : 'row'}
            spacing={Spacing.large}
            justifyContent="space-between"
          >
            <Box
              vOffsetBottom={Spacing.large}
              hOffsetRight={isSmallScreen ? Spacing.none : Spacing.large}
              flex={1}
            >
              <Typography>{HEALTH_RECORDS_DRAWERS.HealthRecordsSummary.description}</Typography>
            </Box>
            <Box alignSelf="center">
              <MuiButton
                data-testid="health-summary-download-button"
                size="large"
                variant="contained"
                color="primary"
                onClick={() => setIsModalOpen(true)}
                loading={isDownloadingSummary}
                startIcon={<Svg set="assets" name="DownloadIcon" size={IconSize.xmedium} />}
              >
                {HEALTH_RECORDS_DRAWERS.HealthRecordsSummary.btnTxt}
              </MuiButton>
            </Box>
            <Box alignSelf="center" hSpacingLeft={isSmallScreen ? 0 : 20}>
              <MuiButton
                data-testid="health-summary-share-button"
                size="large"
                variant="text"
                color="primary"
                onClick={() => {
                  handleShareSummaryClicked();
                  history.push('/u/messages/transmit', {
                    source: 'HealthRecordSummary',
                    returnBackTo: '/u/health-record'
                  });
                }}
              >
                {HEALTH_RECORDS_DRAWERS.HealthRecordsSummary.shareBtnTxt}
              </MuiButton>
            </Box>
          </FlexBox>
          <Box hSpacing={Spacing.large}>
            <Typography>
              If you require a complete copy of your medical record or have additional inquiries,{' '}
              <span>
                <a
                  // Anonymous function intentional here. onClick doesn't seem to call
                  // correctly otherwise.
                  onClick={() => handleMoreInfoClicked()}
                  href={HEALTH_RECORDS_DRAWERS.HealthRecordsSummary.externalLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  please click here
                </a>
              </span>
              .
            </Typography>
          </Box>
          {error ? (
            <FlexBox alignItems="center" justifyContent="center" spacing={Spacing.large}>
              <Typography>{HEALTH_RECORDS_DRAWERS.HealthRecordsSummary.downloadError}</Typography>
            </FlexBox>
          ) : null}
        </MuiCardContent>
      </MuiCard>
      <ConfirmModal
        data-testid="health-summary-download-modal"
        isOpen={isModalOpen}
        showCloseIcon
        icon="InfoIcon"
        description={HEALTH_RECORDS_DRAWERS.HealthRecordsSummary.modalDescription}
        confirmLabel={HEALTH_RECORDS_DRAWERS.HealthRecordsSummary.pdfBtnTxt}
        onConfirm={() => handleDownload('pdf')}
        maxWidth="600px"
        position="bottom"
        additionalButtons={[
          {
            title: HEALTH_RECORDS_DRAWERS.HealthRecordsSummary.xmlBtnTxt,
            action: () => handleDownload('xml')
          }
        ]}
        cancelLabel="Cancel"
        onCancel={() => setIsModalOpen(false)}
        style={{ height: isSmallScreen ? '500px' : '400px', padding: '12px' }}
      />
    </CollapsibleListItem>
  );
};

const mapDispatch = {
  downloadHealthRecordsSummary
};

const mapState = (state: RootState) => ({
  isDownloadingSummary: healthRecordsSummaryIsDownloadingSelector(state),
  error: healthRecordsSummaryDownloadErrorSelector(state)
});

export default connect(mapState, mapDispatch)(HealthRecordsSummary);
