import React from 'react';

export default {
  svg: (
    <g clipPath="url(#clip0_1457_1234)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.51277 3.51472C5.7632 1.26428 8.81545 0 11.998 0C15.1806 0 18.2329 1.26428 20.4833 3.51472C22.7338 5.76516 23.998 8.8174 23.998 12C23.998 15.1826 22.7338 18.2348 20.4833 20.4853C18.2329 22.7357 15.1806 24 11.998 24C8.81545 24 5.7632 22.7357 3.51277 20.4853C1.26233 18.2348 -0.00195312 15.1826 -0.00195312 12C-0.00195312 8.8174 1.26233 5.76516 3.51277 3.51472ZM11.998 1.5C9.21327 1.5 6.54256 2.60625 4.57343 4.57538C2.60429 6.54451 1.49805 9.21523 1.49805 12C1.49805 14.7848 2.60429 17.4555 4.57343 19.4246C6.54256 21.3938 9.21327 22.5 11.998 22.5C14.7828 22.5 17.4535 21.3938 19.4227 19.4246C21.3918 17.4555 22.498 14.7848 22.498 12C22.498 9.21523 21.3918 6.54451 19.4227 4.57538C17.4535 2.60625 14.7828 1.5 11.998 1.5ZM6.96669 6.9697C7.25956 6.67679 7.73444 6.67676 8.02735 6.96964L11.9975 10.9393L15.9667 6.9697C16.2596 6.67679 16.7344 6.67676 17.0273 6.96964C17.3203 7.26252 17.3203 7.73739 17.0274 8.0303L13.0582 11.9999L17.0283 15.9696C17.3213 16.2625 17.3213 16.7374 17.0284 17.0303C16.7355 17.3232 16.2607 17.3232 15.9677 17.0304L11.9976 13.0607L8.02841 17.0303C7.73553 17.3232 7.26066 17.3232 6.96775 17.0304C6.67484 16.7375 6.67481 16.2626 6.96769 15.9697L10.9369 12.0001L6.96675 8.03036C6.67384 7.73748 6.67381 7.26261 6.96669 6.9697Z"
        fill="#4A00E2"
      />
    </g>
  ),
  viewBox: '0 0 24 24'
};
