import React from 'react';

export default {
  svg: (
    <g>
      <g>
        <circle fill="#FFAA45" strokeWidth={0} cx={40} cy={40} r={38.5} />
        <g>
          <path
            fill="#FFFFFF"
            strokeWidth={0}
            d="M40,2.5c20.711,0,37.5,16.789,37.5,37.5S60.711,77.5,40,77.5S2.5,60.711,2.5,40S19.289,2.5,40,2.5  M40,0.5C18.22,0.5,0.5,18.22,0.5,40S18.22,79.5,40,79.5S79.5,61.78,79.5,40S61.78,0.5,40,0.5L40,0.5z"
          />
        </g>
      </g>
      <g>
        <defs>
          <circle id="SVGID_3_" cx={39.873} cy={39.836} r={37.5} />
        </defs>
        <clipPath id="SVGID_2_10">
          <use xlinkHref="#SVGID_3_" />
        </clipPath>
        <g id="Female_-_Adult_6_" clipPath="url(#SVGID_2_10)">
          <path
            fill="#3C2417"
            strokeWidth={0}
            d="M61.119,71.624c-0.026,0.151-0.059,0.282-0.092,0.42c-0.335,1.431-0.709,2.764-1.116,3.979 c-0.703,2.095-1.451,3.828-2.403,5.128c-0.44,0.611-0.932,1.123-1.484,1.536c-1.057,0.794-2.351,1.215-3.992,1.215H27.528 c-1.714,0-3.047-0.46-4.13-1.32c-0.499-0.394-0.939-0.873-1.346-1.431c-0.952-1.3-1.701-3.033-2.403-5.128 c-0.407-1.215-0.781-2.548-1.116-3.979c-0.033-0.138-0.066-0.269-0.092-0.407c-3.841-16.907-2.699-46.874,6.008-55.902 c3.572-3.703,7.991-6.96,15.331-6.96c5.817,0,9.803,2.049,12.981,4.747c0.834,0.696,1.609,1.444,2.351,2.213 c0.295,0.309,0.584,0.643,0.86,0.998C63.889,26.792,64.828,55.307,61.119,71.624z"
          />
          <path
            fill="#F2AE75"
            strokeWidth={0}
            d="M54.902,39.305v-6.828c0-7.252-8.396-16.415-15.042-16.415c-6.646,0-15.042,9.162-15.042,16.415v6.828 c-1.846,0-3.343,1.47-3.343,3.283v3.414c0,1.813,1.497,3.283,3.343,3.283c0,4.909,3.848,10.81,8.395,14.113l0,6.804l-3.283,1.355 l3.283,20.069l13.132,0l3.283-20.069l-3.283-1.355v-6.684c4.618-3.279,8.557-9.265,8.557-14.232c1.846,0,3.343-1.47,3.343-3.283 v-3.414C58.245,40.775,56.748,39.305,54.902,39.305z"
          />
          <g>
            <path
              fill="#3FA9F5"
              strokeWidth={0}
              d="M33.214,70.201c-0.275,0.051-11.773,0.473-16.291,4.81c-5.461,5.24-6.334,12.955-7.036,19.153 l-0.031,0.278c-0.218,1.924-0.417,6.849-0.59,12.894h23.949V70.201z"
            />
            <path
              fill="#3FA9F5"
              strokeWidth={0}
              d="M69.541,94.442l-0.031-0.278c-0.702-6.198-1.575-13.913-7.036-19.153 c-4.464-4.285-15.851-4.759-16.129-4.81v37.135h23.786C69.958,101.291,69.759,96.366,69.541,94.442z"
            />
          </g>
          <path
            fill="#E99260"
            strokeWidth={0}
            d="M54.902,39.305v-6.828c0-7.252-8.396-16.415-15.042-16.415v26.592 c-2.438,5.093-3.343,10.505-3.343,10.505l3.343,0.657v12.147h0c-2.147,0-4.477-0.988-6.647-2.565v2.819l13.132,3.963v-6.662 c4.618-3.279,8.557-9.265,8.557-14.233c1.846,0,3.343-1.47,3.343-3.283v-3.414C58.245,40.775,56.748,39.305,54.902,39.305z  M45.461,64.104c0.036-0.022,0.072-0.043,0.108-0.066C45.533,64.06,45.497,64.081,45.461,64.104z M40.585,65.923 c0.075-0.007,0.15-0.019,0.225-0.028C40.735,65.904,40.66,65.915,40.585,65.923z M41.333,65.812 c0.082-0.016,0.163-0.032,0.245-0.05C41.497,65.78,41.415,65.796,41.333,65.812z M42.131,65.622 c0.066-0.019,0.131-0.035,0.197-0.055C42.262,65.587,42.197,65.603,42.131,65.622z M43.09,65.306 c0.004-0.002,0.008-0.004,0.012-0.005C43.098,65.302,43.094,65.304,43.09,65.306z M43.848,64.981 c0.062-0.029,0.124-0.06,0.187-0.09C43.973,64.921,43.911,64.952,43.848,64.981z M44.623,64.59 c0.065-0.035,0.13-0.07,0.195-0.107C44.753,64.52,44.688,64.555,44.623,64.59z"
          />
          <g>
            <g>
              <path
                fill="#EC9E6B"
                strokeWidth={0}
                d="M34.297,43.655c0.05,1.431-1.07,2.632-2.501,2.682c-1.431,0.05-2.632-1.07-2.682-2.501 c-0.05-1.431,1.07-2.632,2.501-2.682C33.046,41.104,34.247,42.224,34.297,43.655z"
              />
              <path
                fill="#3C2415"
                strokeWidth={0}
                d="M34.297,42.998c0.05,1.431-1.07,2.632-2.501,2.682c-1.431,0.05-2.632-1.07-2.682-2.501 c-0.05-1.431,1.07-2.632,2.501-2.682C33.046,40.447,34.247,41.567,34.297,42.998z"
              />
              <path
                fill="#FFFFFF"
                strokeWidth={0}
                d="M33.234,43.57c0.013,0.358-0.267,0.658-0.625,0.67c-0.358,0.013-0.658-0.267-0.67-0.625 c-0.012-0.358,0.267-0.658,0.625-0.67C32.921,42.932,33.221,43.212,33.234,43.57z"
              />
              <path
                fill="#3C2415"
                strokeWidth={0}
                d="M34.859,41.017c-0.478-1.111-1.704-1.953-3.05-2.097c-0.825-0.09-2.374,0.032-3.704,1.717 c-0.064,0.006-0.122,0.007-0.197-0.001c-0.148-0.013-0.354-0.061-0.554-0.147c-0.408-0.164-0.748-0.508-0.748-0.508 c-0.024-0.023-0.062-0.031-0.093-0.016c-0.04,0.02-0.057,0.068-0.037,0.108c0,0,0.051,0.113,0.149,0.275 c0.096,0.163,0.248,0.374,0.441,0.558c0.191,0.185,0.419,0.343,0.612,0.439c0.095,0.049,0.182,0.083,0.246,0.105 c0.062,0.022,0.127,0.034,0.115,0.033c0.179,0.018,0.342-0.078,0.42-0.228c0.871-1.217,2.037-1.816,3.281-1.682 c1.099,0.117,2.134,0.818,2.516,1.704c0.073,0.167,0.266,0.243,0.432,0.172C34.854,41.377,34.931,41.184,34.859,41.017z"
              />
            </g>
            <g>
              <path
                fill="#EC9E6B"
                strokeWidth={0}
                d="M50.712,43.655c0.05,1.431-1.07,2.632-2.501,2.682c-1.431,0.05-2.632-1.07-2.682-2.501 c-0.05-1.431,1.07-2.632,2.501-2.682C49.461,41.104,50.662,42.224,50.712,43.655z"
              />
              <path
                fill="#3C2415"
                strokeWidth={0}
                d="M50.712,42.998c0.05,1.431-1.07,2.632-2.501,2.682c-1.431,0.05-2.632-1.07-2.682-2.501 c-0.05-1.431,1.07-2.632,2.501-2.682C49.461,40.447,50.662,41.567,50.712,42.998z"
              />
              <path
                fill="#FFFFFF"
                strokeWidth={0}
                d="M49.648,43.57c0.012,0.358-0.267,0.658-0.625,0.67c-0.358,0.013-0.658-0.267-0.67-0.625 c-0.012-0.358,0.267-0.658,0.625-0.67C49.336,42.932,49.636,43.212,49.648,43.57z"
              />
              <path
                fill="#3C2415"
                strokeWidth={0}
                d="M44.966,41.017c0.478-1.111,1.704-1.953,3.05-2.097c0.825-0.09,2.374,0.032,3.704,1.717 c0.064,0.006,0.122,0.007,0.197-0.001c0.148-0.013,0.354-0.061,0.554-0.147c0.409-0.164,0.748-0.508,0.748-0.508 c0.024-0.023,0.062-0.031,0.093-0.016c0.04,0.02,0.057,0.068,0.037,0.108c0,0-0.051,0.113-0.149,0.275 c-0.096,0.163-0.248,0.374-0.441,0.558c-0.191,0.185-0.419,0.343-0.612,0.439c-0.095,0.049-0.182,0.083-0.246,0.105 c-0.062,0.022-0.127,0.034-0.115,0.033c-0.179,0.018-0.342-0.078-0.42-0.228c-0.871-1.217-2.037-1.816-3.281-1.682 c-1.099,0.117-2.134,0.818-2.516,1.704c-0.073,0.167-0.266,0.243-0.432,0.172C44.972,41.377,44.894,41.184,44.966,41.017z"
              />
            </g>
          </g>
          <g>
            <path
              fill="#F05D44"
              strokeWidth={0}
              d="M45.113,56.241c0,2.579-2.352,4.669-5.253,4.669s-5.253-2.09-5.253-4.669H45.113z"
            />
            <path
              fill="#FFFFFF"
              strokeWidth={0}
              d="M43.524,57.992h-7.441c-0.363,0-0.657-0.294-0.657-0.657v-1.094h8.754v1.094 C44.18,57.698,43.886,57.992,43.524,57.992z"
            />
          </g>
          <g>
            <circle fill="#FFFFFF" strokeWidth={0} cx={56.603} cy={49.104} r={1.641} />
            <circle fill="#FFFFFF" strokeWidth={0} cx={23.118} cy={49.104} r={1.641} />
          </g>
          <path
            fill="#3C2417"
            strokeWidth={0}
            d="M48.591,33.319c0.446,1.602,1.32,3.716,2.836,4.977c-4.465-1.142-8.266-3.782-11.018-6.907 c0.269,1.615,1.293,3.375,2.876,4.931c-1.202-0.532-2.37-1.221-3.486-2.009c-2.488-1.747-4.701-4.012-6.343-6.31 c-2.6,5.272-6.809,12.639-10.072,12.738c-4.461-8.986-4.911-28.592,16.395-28.592s18.109,22.269,16.435,28.592 C52.472,37.863,49.622,34.849,48.591,33.319z"
          />
        </g>
      </g>
    </g>
  ),
  viewBox: '0 0 80 80'
};
