import styled, { css } from 'styled-components';
import { Spacing, FontSize } from 'modules/styles/variables';
import { Color } from 'modules/styles/colors';
import { Column } from 'components/UI/Layout/Container';
import IconSubhead from 'components/UI/Labels/IconSubhead';
import SmallLabel from 'components/UI/Labels/SmallLabel';

export const StyledIconSubhead = styled(IconSubhead)`
  font-size: ${FontSize.base}px;
  padding-left: ${Spacing.small}px;
  line-height: 17;
  display: flex;
  align-items: center;
`;

export const ExceptionLabel = styled(SmallLabel)`
  color: ${Color.primary};
  margin-left: ${Spacing.medium}px;
  line-height: 12;
  margin-bottom: ${Spacing.medium}px;
`;

const withBreakStyles = (props: { break: boolean }) => {
  return (
    props.break &&
    css`
      padding-top: ${Spacing.medium * 2}px;
      border-top-width: 1px;
      border-top-color: ${Color.gray};
    `
  );
};

export const StyledColumn = styled(Column)`
  margin-top: ${Spacing.medium};
  display: flex;
  ${withBreakStyles}
`;

export const BoldText = styled.div`
  font-weight: bold;
`;
