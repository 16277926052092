import React from 'react';

export default {
  svg: (
    <g>
      <g>
        <circle fill="#8CC63F" strokeWidth={0} cx={39.641} cy={39.923} r={38.5} />
        <path
          fill="#FFFFFF"
          strokeWidth={0}
          d="M39.641,2.423c20.711,0,37.5,16.789,37.5,37.5c0,20.711-16.789,37.5-37.5,37.5s-37.5-16.789-37.5-37.5 C2.141,19.213,18.93,2.423,39.641,2.423 M39.641,0.423c-21.78,0-39.5,17.72-39.5,39.5s17.72,39.5,39.5,39.5s39.5-17.72,39.5-39.5 S61.421,0.423,39.641,0.423L39.641,0.423z"
        />
      </g>
      <g>
        <defs>
          <circle id="SVGID_5_" cx={39.641} cy={39.923} r={37.5} />
        </defs>
        <clipPath id="SVGID_2_17">
          <use xlinkHref="#SVGID_5_" />
        </clipPath>
        <g id="Male_-_Teen_2_" clipPath="url(#SVGID_2_17)">
          <path
            fill="#F7CDB9"
            strokeWidth={0}
            d="M56.263,43.762l0.516-6.703c0-8.766-8.379-15.869-17.138-15.869c-8.766,0-17.138,7.103-17.138,15.869 l0.515,6.697c-0.49,0.118-0.933,0.343-1.302,0.66c-0.686,0.584-1.117,1.447-1.117,2.418v3.301c0,1.745,1.405,3.161,3.154,3.172 c0,5.184,3.9,10.198,8.275,13.222v6.26l-2.384,1.028l0.635,21.399h1.749v3.281h15.234v-3.281H49l0.635-21.333l-2.374-1.094 v-6.265c4.372-3.025,8.268-8.036,8.268-13.216c1.742-0.012,3.152-1.426,3.152-3.171v-3.301 C58.681,45.344,57.649,44.103,56.263,43.762z"
          />
          <path
            fill="#E6E7E8"
            strokeWidth={0}
            d="M78.141,109.237c-1.349-9.701-2.743-16.565-6.056-23.909c-2.793-6.087-10.632-7.592-21.867-11.457 c-0.4-0.14-0.806-0.279-1.212-0.425c-0.006,0.019-0.006,0.044-0.013,0.063c-0.74,4.183-2.84,9.599-9.351,10.234 c-6.511-0.635-8.611-6.051-9.351-10.234c-0.006-0.019-0.006-0.044-0.013-0.063c-0.406,0.146-0.812,0.286-1.212,0.425 C17.829,77.736,9.99,79.241,7.197,85.328c-3.313,7.345-4.708,14.209-6.056,23.909H78.141z"
          />
          <polygon
            fill="#F05D44"
            strokeWidth={0}
            points="43.306,82.405 39.639,79.408 35.973,82.405 37.38,85.178 36.685,108.083 42.569,108.083  41.858,85.179  "
          />
          <path
            fill="#FFFFFF"
            strokeWidth={0}
            d="M39.639,79.408l-4.685,4.933l-9.112-9.36c0.013-0.007,6.101-2.687,6.101-2.687l1.881,1.74L39.639,79.408z "
          />
          <path
            fill="#FFFFFF"
            strokeWidth={0}
            d="M39.64,79.408l4.685,4.933l9.112-9.36c-0.013-0.007-6.101-2.687-6.101-2.687l-1.881,1.74L39.64,79.408z"
          />
          <path
            fill="#EFAD93"
            strokeWidth={0}
            d="M56.263,43.762l0.516-6.703c0-8.766-8.379-15.869-17.138-15.869c-0.003,0-0.003,22.534-0.003,22.534 c-2.315,4.924-3.809,12.06-3.809,12.06h3.809V69.43c-2.222-0.001-5.003-1.098-7.614-2.905v3l15.234,2.622v-5.621 c-0.02,0.014-0.04,0.026-0.06,0.039c4.398-3.02,8.331-8.053,8.331-13.258c1.742-0.012,3.152-1.426,3.152-3.171v-3.301 C58.681,45.344,57.649,44.103,56.263,43.762z M39.639,69.43c0.004,0,0.009-0.001,0.013-0.001c-0.004,0-0.008,0.001-0.011,0.001 C39.64,69.431,39.639,69.43,39.639,69.43z M46.154,67.237c0.081-0.049,0.161-0.099,0.242-0.149 C46.315,67.139,46.235,67.188,46.154,67.237z M40.395,69.386c0.072-0.007,0.144-0.016,0.217-0.025 C40.539,69.37,40.467,69.379,40.395,69.386z M41.351,69.239c0.031-0.006,0.062-0.013,0.093-0.019 C41.413,69.226,41.382,69.233,41.351,69.239z M42.217,69.019c0.084-0.024,0.167-0.05,0.251-0.076 C42.385,68.97,42.301,68.995,42.217,69.019z M43.229,68.681c0.039-0.015,0.077-0.028,0.116-0.043 C43.306,68.653,43.268,68.667,43.229,68.681z M44.161,68.292c0.085-0.039,0.171-0.078,0.257-0.119 C44.332,68.213,44.247,68.253,44.161,68.292z M45.209,67.777c0.028-0.015,0.056-0.028,0.084-0.043 C45.265,67.749,45.237,67.762,45.209,67.777z"
          />
          <g>
            <circle fill="#F7CDB9" strokeWidth={0} cx={32.339} cy={45.64} r={2.507} />
            <circle fill="#3C2417" strokeWidth={0} cx={32.339} cy={45.005} r={2.507} />
            <path
              fill="#FFFFFF"
              strokeWidth={0}
              d="M33.816,44.201c0.012,0.346-0.259,0.636-0.604,0.648c-0.346,0.012-0.636-0.259-0.648-0.604 c-0.012-0.346,0.259-0.636,0.604-0.648C33.514,43.585,33.804,43.855,33.816,44.201z"
            />
          </g>
          <g>
            <circle fill="#F7CDB9" strokeWidth={0} cx={46.938} cy={45.64} r={2.507} />
            <circle fill="#3C2417" strokeWidth={0} cx={46.938} cy={45.005} r={2.507} />
            <path
              fill="#FFFFFF"
              strokeWidth={0}
              d="M48.415,44.201c0.012,0.346-0.259,0.636-0.604,0.648c-0.346,0.012-0.636-0.259-0.648-0.604 c-0.012-0.346,0.259-0.636,0.604-0.648C48.113,43.585,48.403,43.855,48.415,44.201z"
            />
          </g>
          <path
            fill="#F05D44"
            strokeWidth={0}
            d="M45.352,58.649c0,2.804-2.558,5.078-5.713,5.078c-3.155,0-5.713-2.273-5.713-5.078H45.352z"
          />
          <path
            fill="#FFFFFF"
            strokeWidth={0}
            d="M43.703,60.553h-8.252c-0.351,0-0.635-0.284-0.635-0.635v-1.269h9.521v1.269 C44.338,60.269,44.053,60.553,43.703,60.553z"
          />
          <path
            fill="#C5672F"
            strokeWidth={0}
            d="M56.536,26.72c0.178-2.12-0.502-5.137-2.171-9.446c-0.394-1.017-1.723-1.193-2.403-0.341 c-1.933,2.424-4.49,1.939-8.695,0.226c-7.031-2.863-12.191-1.115-15.165,1.998c-12.75,6.507-4.96,29.319-4.96,29.319 s0.267-11.879,4.648-17.332c2.399,0.55,9.15,1.475,11.851,1.475c2.7,0,10.406-0.887,12.861-1.475 c4.758,2.839,3.642,17.332,3.642,17.332S61.163,35.83,56.536,26.72z"
          />
          <path
            fill="#FFFFFF"
            strokeWidth={0}
            d="M57.414,40.499h-3.435c-1.086-0.569-2.605-0.932-4.732-0.932c-3.537,0-5.938,0.678-7.169,2.001 c-0.583-0.32-1.452-0.685-2.436-0.685c-0.975,0-1.837,0.357-2.42,0.676c-1.234-1.318-3.632-1.993-7.161-1.993 c-2.127,0-3.646,0.363-4.732,0.932h-3.46v2.475l1.136,0.398c-0.116,0.464-0.164,0.934-0.164,1.388 c0,3.075,0.748,7.163,7.22,7.163c5.78,0,7.296-3.556,8.017-7.034c0.109-0.528,0.121-1.03,0.048-1.502 c0.282-0.18,0.747-0.338,1.516-0.338c0.789,0,1.259,0.166,1.539,0.352c-0.071,0.468-0.058,0.966,0.05,1.488 c0.721,3.478,2.237,7.034,8.017,7.034c6.471,0,7.22-4.088,7.22-7.163c0-0.456-0.048-0.93-0.166-1.396l1.113-0.39V40.499z  M36.834,44.631c-0.749,3.615-2.188,6.023-6.774,6.023c-5.23,0-5.95-2.833-5.95-5.894c0-0.971,0-3.924,5.95-3.924 c4.105,0,5.724,0.929,6.358,1.709C36.867,43.097,37.007,43.798,36.834,44.631z M49.247,50.654c-4.586,0-6.025-2.408-6.774-6.023 c-0.173-0.833-0.033-1.535,0.416-2.086c0.634-0.78,2.253-1.709,6.358-1.709c5.95,0,5.95,2.953,5.95,3.924 C55.197,47.821,54.476,50.654,49.247,50.654z"
          />
        </g>
      </g>
    </g>
  ),
  viewBox: '0 0 80 80'
};
