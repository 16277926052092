import React from 'react';

import { Color } from 'modules/styles/colors';

export const styles = {
  fill: 'none',
  stroke: Color.black,
  strokeWidth: 1.5,
  strokeLinecap: 'round',
  strokeLinejoin: 'round'
} as const;

export default {
  svg: (
    <g fill="none">
      <path
        d="M19.5 6.75H4.5C2.84315 6.75 1.5 8.09315 1.5 9.75V18.75C1.5 20.4069 2.84315 21.75 4.5 21.75H19.5C21.1569 21.75 22.5 20.4069 22.5 18.75V9.75C22.5 8.09315 21.1569 6.75 19.5 6.75Z"
        {...styles}
      />
      <path
        d="M7.5 6.75V3.75C7.5 3.35218 7.65804 2.97064 7.93934 2.68934C8.22064 2.40804 8.60218 2.25 9 2.25H15C15.3978 2.25 15.7794 2.40804 16.0607 2.68934C16.342 2.97064 16.5 3.35218 16.5 3.75V6.75"
        {...styles}
      />
      <path d="M12 11.231V17.231" {...styles} />
      <path d="M9 14.231H15" {...styles} />
    </g>
  ),
  viewBox: '0 0 24 24'
};
