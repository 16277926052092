import { Color } from 'modules/styles/colors';
import { FontSize, FontWeight, Spacing } from 'modules/styles/variables';
import styled from 'styled-components';
import Card from '../Card/Card';
import DefaultText from '../Typography/DefaultText';

export const Row = styled.div`
  flex-direction: row;
  margin-top: 10px;
`;

export const Dot = styled.div<{ color?: string }>`
  height: 10px;
  width: 10px;
  background-color: ${props => props.color || Color.gray};
  border-radius: 50%;
  margin-right: ${Spacing.small}px;
`;

export const StyledTouchableOpacity = styled.div`
  flex: 1;
  padding: ${Spacing.small}px;
  margin: ${Spacing.large}px ${Spacing.none}px;
  align-items: flex-start;
  align-content: flex-start;
  display: flex;
  cursor: pointer;
`;

export const BottomAlignRow = styled(Row)`
  align-items: baseline;
`;

export const CenterAlignRow = styled(Row)`
  align-items: flex-start;
`;

export const ValueText = styled(DefaultText)`
  font-weight: ${FontWeight.bold};
  font-size: ${FontSize.heading};
  line-height: ${FontSize.heading};
`;
export const UnitsText = styled(DefaultText)`
  line-height: ${FontSize.heading};
  margin-right: ${Spacing.small}px;
`;

export const DegreeText = styled(DefaultText)`
  font-size: ${FontSize.heading};
  line-height: ${FontSize.heading};
`;

export const ValueUnitsContainer = styled.div`
  flex-direction: row;
  align-items: baseline;
`;
export const StyledCard = styled(Card)`
  width: 100%;
  padding: ${Spacing.medium}px;
`;

export const VDivider = styled.div`
  height: 100%;
  width: 1px;
  background-color: ${Color.grayLight};
  align-items: center;
  justify-content: center;
  margin-right: ${Spacing.medium}px;
`;
