import React from 'react';

export default {
  svg: (
    <g>
      <g>
        <circle fill="#213245" strokeWidth={0} cx={40} cy={40} r={38.5} />
        <path
          fill="#FFFFFF"
          strokeWidth={0}
          d="M40,2.5c20.711,0,37.5,16.789,37.5,37.5S60.711,77.5,40,77.5S2.5,60.711,2.5,40S19.289,2.5,40,2.5 M40,0.5 C18.22,0.5,0.5,18.22,0.5,40S18.22,79.5,40,79.5S79.5,61.78,79.5,40S61.78,0.5,40,0.5L40,0.5z"
        />
      </g>
      <g>
        <defs>
          <circle id="SVGID_17_" cx={40} cy={40} r={37.5} />
        </defs>
        <clipPath id="SVGID_2_3">
          <use xlinkHref="#SVGID_17_" />
        </clipPath>
        <g id="Male_-_Elder_4_" clipPath="url(#SVGID_2_3)">
          <path
            fill="#F7CDB9"
            strokeWidth={0}
            d="M57.681,34.067l0.541-7.035c0-9.2-8.793-16.654-17.986-16.654c-9.2,0-17.986,7.454-17.986,16.654 l0.541,7.028c-0.515,0.124-0.979,0.36-1.367,0.692c-0.72,0.613-1.172,1.519-1.172,2.538v3.464c0,1.832,1.475,3.317,3.31,3.329 c0,5.947,4.892,11.681,9.978,14.707h-2.093v7.919l-2.752,1.079l0.733,22.458h2.019v3.443h17.587v-3.443h2.007l0.733-22.389 l-2.74-1.148v-7.919h-2.101c5.086-3.026,9.978-8.759,9.978-14.706c1.829-0.013,3.307-1.497,3.307-3.328v-3.464 C60.217,35.728,59.135,34.425,57.681,34.067z"
          />
          <path
            fill="#E5E5E5"
            strokeWidth={0}
            d="M70.844,118.167H85.5c-1.456-14.664-6.564-36.716-6.564-36.716l-0.028,0.032 c-0.111-0.536-0.291-1.074-0.557-1.61c-3.224-6.388-12.94-7.967-25.911-12.024c-0.462-0.147-0.931-0.293-1.4-0.446 c-0.007,0.02-0.007,0.047-0.015,0.067c-0.85,4.39-3.276,5.523-10.794,6.189c-7.518-0.666-9.944-1.799-10.794-6.189 c-0.007-0.02-0.007-0.046-0.015-0.067c-0.469,0.153-0.938,0.3-1.4,0.446c-0.059,0.02-0.124,0.04-0.183,0.053 C14.966,71.919,5.324,73.518,2.114,79.873c-0.266,0.537-0.445,1.075-0.556,1.612L1.529,81.45c0,0-4.838,22.385-6.294,37.05 h15.322c0,0,1.477-8.704,2.585-15.169c0.036,3.458,0.045,8.084-0.04,13.567l54.27,0.44c-0.097-6.393-0.072-11.685-0.025-15.341 C68.744,108.424,70.844,118.167,70.844,118.167z"
          />
          <path
            fill="#AC9074"
            strokeWidth={0}
            d="M67.847,117.834c-0.201-11.146-0.387-21.36-0.387-21.36s-4.81-1.459-3.711-25.214 c-3.107-0.979-6.624-1.965-10.508-3.171c0,0-1.468,17.56-13.008,17.56c-11.54,0-13.008-17.56-13.008-17.56 c-3.884,1.206-7.401,2.192-10.508,3.171c1.099,23.755-3.711,25.214-3.711,25.214s-0.251,10.185-0.453,21.36H67.847z"
          />
          <g>
            <path
              fill="#FFFFFF"
              strokeWidth={0}
              d="M31.348,66.191c0,0-7.028,2.813-7.043,2.82l10.52,9.823l5.408-5.177l-6.714-5.64L31.348,66.191z"
            />
            <path
              fill="#FFFFFF"
              strokeWidth={0}
              d="M49.119,66.191l-2.171,1.826l-6.714,5.64l5.408,5.177l10.52-9.823 C56.147,69.004,49.119,66.191,49.119,66.191z"
            />
          </g>
          <g>
            <path
              fill="#F05D44"
              strokeWidth={0}
              d="M40.723,75.963h-0.974h-0.974c0,0-7.326-5.708-8.225-1.86c0,0-0.575,1.629,0.717,3.432 c0.025,0.035,0.025,0.09,0,0.124c-1.292,1.803-0.717,3.432-0.717,3.432c0.899,3.848,8.225-1.86,8.225-1.86h0.974h0.974 c0,0,7.326,5.708,8.225,1.86c0,0,0.575-1.629-0.717-3.432c-0.025-0.035-0.025-0.09,0-0.124c1.292-1.803,0.717-3.432,0.717-3.432 C48.049,70.255,40.723,75.963,40.723,75.963z"
            />
            <path
              fill="#F05D44"
              strokeWidth={0}
              d="M44.619,76.772c0-1.023-3.663,0.583-3.663,0.583h-1.207h-1.207c0,0-3.663-1.607-3.663-0.583 c0,0,0.006,0.427,0.404,0.825c-0.392,0.392-0.404,0.825-0.404,0.825c0,1.023,3.663-0.583,3.663-0.583h1.207h1.207 c0,0,3.663,1.607,3.663,0.583c0,0-0.012-0.433-0.404-0.825C44.614,77.199,44.619,76.772,44.619,76.772z"
            />
            <path
              fill="#F05D44"
              strokeWidth={0}
              d="M42.823,77.597c0,1.697-1.376,3.073-3.073,3.073c-1.697,0-3.073-1.376-3.073-3.073 c0-1.697,1.376-3.074,3.073-3.074C41.447,74.523,42.823,75.899,42.823,77.597z"
            />
          </g>
          <g>
            <path
              opacity={0.8}
              fill="#EEAB91"
              strokeWidth={0}
              d="M57.681,34.067l0.541-7.035c0-9.2-8.793-16.654-17.986-16.654c-0.001,0-0.002,0-0.003,0 v23.649c-2.43,5.168-3.997,12.657-3.997,12.657h3.997v14.322c0.001,0,0.001,0,0.002,0c0.001,0,0.002,0,0.003,0 c6.237-0.002,16.673-8.235,16.673-16.922c1.829-0.013,3.307-1.497,3.307-3.328v-3.464 C60.217,35.728,59.135,34.425,57.681,34.067z"
            />
            <path
              opacity={0.8}
              fill="#EEAB91"
              strokeWidth={0}
              d="M31.442,61.852v2.854c0,0,3.089,1.486,8.793,1.486c5.704,0,8.793-1.486,8.793-1.486V61.85 c-2.279,1.199-5.151,1.944-8.794,1.944C36.591,63.794,33.72,63.05,31.442,61.852z"
            />
          </g>
          <g>
            <g>
              <path
                fill="#F7CDB9"
                strokeWidth={0}
                d="M35.201,35.947c0.051,1.452-1.085,2.67-2.537,2.721c-1.452,0.051-2.67-1.085-2.721-2.537 c-0.051-1.452,1.085-2.67,2.537-2.721C33.932,33.359,35.15,34.495,35.201,35.947z"
              />
              <path
                fill="#3C2417"
                strokeWidth={0}
                d="M35.201,35.281c0.051,1.452-1.085,2.67-2.537,2.721c-1.452,0.051-2.67-1.085-2.721-2.537 c-0.051-1.452,1.085-2.67,2.537-2.721C33.932,32.693,35.15,33.829,35.201,35.281z"
              />
              <path
                fill="#FFFFFF"
                strokeWidth={0}
                d="M34.122,34.529c0.013,0.363-0.271,0.668-0.634,0.68c-0.363,0.013-0.668-0.271-0.68-0.634 c-0.013-0.363,0.271-0.668,0.634-0.68C33.805,33.882,34.109,34.166,34.122,34.529z"
              />
            </g>
            <g>
              <path
                fill="#F7CDB9"
                strokeWidth={0}
                d="M50.523,35.947c0.051,1.452-1.085,2.67-2.537,2.721c-1.452,0.051-2.67-1.085-2.721-2.537 c-0.051-1.452,1.085-2.67,2.537-2.721C49.254,33.359,50.472,34.495,50.523,35.947z"
              />
              <path
                fill="#3C2417"
                strokeWidth={0}
                d="M50.523,35.281c0.051,1.452-1.085,2.67-2.537,2.721c-1.452,0.051-2.67-1.085-2.721-2.537 c-0.051-1.452,1.085-2.67,2.537-2.721C49.254,32.693,50.472,33.829,50.523,35.281z"
              />
              <path
                fill="#FFFFFF"
                strokeWidth={0}
                d="M49.444,34.529c0.013,0.363-0.271,0.668-0.634,0.68c-0.363,0.013-0.668-0.271-0.68-0.634 c-0.013-0.363,0.271-0.668,0.634-0.68C49.127,33.882,49.431,34.166,49.444,34.529z"
              />
            </g>
          </g>
          <g>
            <path
              fill="#E5E5E5"
              strokeWidth={0}
              d="M40.234,46.56c-7.467,0-8.993,2.982-8.993,6.662c0,0,7.236-0.36,8.993-3.095 c1.757,2.735,8.993,3.095,8.993,3.095C49.227,49.543,47.701,46.56,40.234,46.56z"
            />
            <path
              fill="#E5E5E5"
              strokeWidth={0}
              d="M65.513,29.532c0-1.278-0.647-2.404-1.632-3.07c0.077-0.304,0.122-0.621,0.122-0.949 c0-1.148-0.503-2.176-1.298-2.883c0.265-0.541,0.418-1.147,0.418-1.791c0-2.254-1.827-4.082-4.082-4.082 c-0.084,0-0.165,0.008-0.247,0.013c-0.503-1.232-1.711-2.102-3.124-2.102c-1.864,0-3.374,1.511-3.374,3.374 c0,2.773,4.884,5.415,4.884,15.281c0,9.866-0.16,23.497-16.946,23.497c-16.785,0-16.946-13.631-16.946-23.497 c0-9.866,4.884-12.508,4.884-15.281c0-1.864-1.511-3.374-3.374-3.374c-1.413,0-2.622,0.869-3.124,2.102 c-0.082-0.005-0.164-0.013-0.247-0.013c-2.254,0-4.082,1.827-4.082,4.082c0,0.643,0.153,1.25,0.418,1.791 c-0.795,0.708-1.298,1.735-1.298,2.883c0,0.328,0.045,0.645,0.122,0.949c-0.985,0.665-1.632,1.792-1.632,3.07 c0,1.469,0.857,2.734,2.097,3.332c-0.047,0.25-0.074,0.506-0.074,0.77c0,1.892,1.275,3.482,3.011,3.969 c0.228,1.225,0.478,2.227,0.694,2.919c0.326,1.049,1.728,2.665,1.735,2.665c0.546,1.023-0.936,20.607,17.816,20.607 c18.752,0,17.27-19.584,17.816-20.607c0.007,0,1.409-1.616,1.735-2.665c0.216-0.692,0.466-1.694,0.694-2.919 c1.736-0.487,3.011-2.078,3.011-3.969c0-0.263-0.027-0.52-0.074-0.77C64.656,32.266,65.513,31,65.513,29.532z"
            />
            <path
              fill="#8F6642"
              strokeWidth={0}
              d="M48.58,28.546c-3.621,0-6.607,2.766-6.959,6.295h-2.775c-0.352-3.53-3.339-6.295-6.959-6.295 c-3.857,0-6.995,3.138-6.995,6.995c0,3.857,3.138,6.995,6.995,6.995c3.621,0,6.607-2.766,6.959-6.295h2.775 c0.352,3.53,3.339,6.295,6.959,6.295c3.857,0,6.995-3.138,6.995-6.995C55.575,31.684,52.437,28.546,48.58,28.546z  M31.887,41.137c-3.085,0-5.596-2.51-5.596-5.596c0-3.085,2.51-5.596,5.596-5.596c3.085,0,5.596,2.51,5.596,5.596 C37.483,38.627,34.973,41.137,31.887,41.137z M48.58,41.137c-3.085,0-5.596-2.51-5.596-5.596c0-3.085,2.51-5.596,5.596-5.596 c3.085,0,5.596,2.51,5.596,5.596C54.176,38.627,51.666,41.137,48.58,41.137z"
            />
          </g>
        </g>
      </g>
    </g>
  ),
  viewBox: '0 0 80 80'
};
