import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { ProviderLocation, ProviderSummary } from 'store/providerSearch/search/types';
import {
  MuiAvatar,
  MuiBox,
  MuiButton,
  MuiCard,
  MuiCardActionArea,
  MuiDivider,
  MuiGrid,
  MuiTypography
} from 'theme/material-ui';
import { hasLength } from 'modules/utils/ArrayUtils';
import { FontWeight, IconSize } from 'modules/styles/variables';
import analyticsService, { AnalyticsEvent } from 'services/AnalyticsService';
import { parseDistance } from 'store/providerSearch/utils';
import Svg from 'components/UI/Svg/Svg';

interface Props {
  provider: ProviderSummary;
  showInsurance?: boolean;
}

interface SecondaryLocationProps {
  locations: ProviderLocation[];
  visible: boolean;
  showInsurance: boolean;
}

const Specialties = ({
  specialties = [],
  lgbtqFlg
}: {
  specialties: ProviderSummary['specialties'];
  lgbtqFlg: Boolean;
}) => (
  <MuiTypography
    style={{
      overflow: 'hidden',
      display: '-webkit-box',
      WebkitLineClamp: '1',
      WebkitBoxOrient: 'vertical'
    }}
  >
    {specialties.map(s => s.specialtyName).join(', ')} {lgbtqFlg ? ', ' : null}
    {lgbtqFlg ? <Svg name="LgbtqIcon" size={15} /> : null}
    {lgbtqFlg ? ` LGBTQ+ Clinically Competent` : null}
  </MuiTypography>
);
const Location = ({
  location,
  showInsurance
}: {
  location: ProviderLocation;
  showInsurance: boolean;
}) => {
  if (!location) return null;

  const { name, distance, inNetwork } = location;

  return (
    <MuiBox p={2} paddingTop={1} paddingLeft={4}>
      <MuiGrid container>
        <MuiGrid item xs={1} /> {/* placeholder item so locations can match up with displayName */}
        <MuiGrid container item sm={10} justify="space-between" wrap="nowrap">
          <MuiGrid item>
            <MuiGrid container direction="row" wrap="nowrap">
              <Svg name="DirectionIcon" size={IconSize.base} />
              <MuiTypography>
                {name}
                {parseDistance(distance)}
              </MuiTypography>
            </MuiGrid>
          </MuiGrid>
          <MuiGrid item>
            {showInsurance && (
              <MuiBox pl={1}>
                <MuiTypography>{inNetwork ? 'In-network' : 'Out-of-network'}</MuiTypography>
              </MuiBox>
            )}
          </MuiGrid>
        </MuiGrid>
      </MuiGrid>
    </MuiBox>
  );
};

const SecondaryLocationToggle = ({
  visible,
  setVisible
}: {
  visible: boolean;
  setVisible: (visible: boolean) => void;
}) => {
  const toggleLocationsVisible = e => {
    e.stopPropagation();
    e.preventDefault();

    setVisible(!visible);
  };

  return (
    <>
      <MuiDivider light={visible} />
      <MuiButton
        variant="text"
        size="large"
        fullWidth
        onClick={toggleLocationsVisible}
        color="primary"
        disableRipple
      >
        <MuiBox p={1}>{visible ? 'Less locations' : 'More locations'}</MuiBox>
      </MuiButton>
    </>
  );
};

const SecondaryLocations = ({ locations = [], visible, showInsurance }: SecondaryLocationProps) => {
  if (!locations.length || !visible) return null;

  return (
    <>
      {locations.map(location => (
        <>
          <MuiDivider />
          <MuiBox paddingTop={1}>
            <Location location={location} showInsurance={showInsurance} />
          </MuiBox>
        </>
      ))}
    </>
  );
};

export const Locations = ({
  primary,
  secondary,
  showInsurance
}: {
  primary: ProviderLocation;
  secondary: ProviderLocation[];
  showInsurance: boolean;
}) => {
  const [secondaryLocationsVisible, setSecondaryLocationsVisible] = useState(false);

  return (
    <>
      <Location location={primary} showInsurance={showInsurance} />
      <SecondaryLocations
        locations={secondary}
        visible={secondaryLocationsVisible}
        showInsurance={showInsurance}
      />
      {hasLength(secondary) && (
        <SecondaryLocationToggle
          visible={secondaryLocationsVisible}
          setVisible={setSecondaryLocationsVisible}
        />
      )}
    </>
  );
};

export const ProviderSearchListItem = ({ provider, showInsurance }: Props) => {
  const history = useHistory();

  const selectProvider = () => {
    analyticsService.logEvent(AnalyticsEvent.ProviderSearchResultClicked);
    history.push(`/u/provider/${provider.providerId}`);
  };

  const primaryLocation = provider.locations?.[0];
  const secondaryLocations = provider.locations?.slice(1) || [];

  return (
    <MuiBox my={2} key={provider.providerId} data-testid="provider-search-results-list-item">
      <MuiCard elevation={2}>
        <MuiCardActionArea component="div" onClick={selectProvider} disableRipple>
          <MuiBox paddingBottom={hasLength(secondaryLocations) ? 0 : 1}>
            <MuiBox paddingX={3} paddingTop={3}>
              <MuiGrid container direction="row" wrap="nowrap" alignItems="center">
                <MuiGrid item sm={1}>
                  <MuiAvatar alt={provider.displayName} src={provider.profileImage} size={60} />
                </MuiGrid>
                <MuiGrid item sm={9}>
                  <MuiBox pl={2}>
                    <MuiTypography fontWeight={FontWeight.semibold} data-testid="provider-name">
                      {provider.displayName}
                    </MuiTypography>
                    <Specialties specialties={provider.specialties} lgbtqFlg={provider.lgbtqFlg} />
                  </MuiBox>
                </MuiGrid>
                <MuiGrid xs={2} item container justify="flex-end">
                  <Svg name="ChevronRight" size={IconSize.base} />
                </MuiGrid>
              </MuiGrid>
            </MuiBox>
            <Locations
              primary={primaryLocation}
              secondary={secondaryLocations}
              showInsurance={showInsurance}
            />
          </MuiBox>
        </MuiCardActionArea>
      </MuiCard>
    </MuiBox>
  );
};

export default ProviderSearchListItem;
