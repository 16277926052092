import React from 'react';

export default {
  svg: (
    <g>
      <g>
        <g>
          <circle fill="#BF00E6" strokeWidth={0} cx={40} cy={39.75} r={38.5} />
          <path
            fill="#FFFFFF"
            strokeWidth={0}
            d="M40,2.25c20.711,0,37.5,16.789,37.5,37.5S60.711,77.25,40,77.25S2.5,60.461,2.5,39.75S19.289,2.25,40,2.25  M40,0.25c-21.78,0-39.5,17.72-39.5,39.5s17.72,39.5,39.5,39.5s39.5-17.72,39.5-39.5S61.78,0.25,40,0.25L40,0.25z"
          />
        </g>
        <g>
          <defs>
            <circle id="SVGID_35_" cx={40} cy={39.75} r={37.5} />
          </defs>
          <clipPath id="SVGID_4_">
            <use xlinkHref="#SVGID_35_" />
          </clipPath>
          <g clipPath="url(#SVGID_4_)">
            <rect
              x={29.446}
              y={67.804}
              fill="#E6E6E6"
              strokeWidth={0}
              width={21.648}
              height={8.448}
            />
            <path
              fill="#F2AE75"
              strokeWidth={0}
              d="M58.21,38.35l0.556-7.234c0-9.46-9.042-17.125-18.495-17.125c-9.46,0-18.495,7.665-18.495,17.125 l0.556,7.227c-0.529,0.128-1.007,0.37-1.405,0.712c-0.74,0.63-1.206,1.562-1.206,2.61v3.562c0,1.883,1.516,3.411,3.404,3.423 c0,5.265,3.729,10.366,8.103,13.669v33.442h18.084v-33.44c4.376-3.303,8.105-8.405,8.105-13.671 c1.88-0.013,3.401-1.539,3.401-3.423v-3.562C60.819,40.058,59.706,38.718,58.21,38.35z"
            />
            <path
              fill="#BF00E6"
              strokeWidth={0}
              d="M82.128,109.436c-1.361-15.079-1.762-13.425-3.882-25.832c-0.785-4.592-4.869-6.649-10.866-8.427 c-0.045-0.014-0.084-0.034-0.13-0.046c-0.1-0.028-0.206-0.055-0.307-0.083c-2.077-0.602-4.375-1.179-6.834-1.818 c-2.887-0.775-5.918-1.635-8.998-2.687c0,0,1.293,15.615-10.84,15.615c-12.133,0-10.806-15.615-10.806-15.615 c-2.67,0.912-5.304,1.679-7.838,2.373C11.169,75.672,3.403,77.082,2.288,83.604c-2.12,12.407-3.055,10.753-4.416,25.832H82.128z"
            />
            <polygon
              fill="#F5F5F5"
              strokeWidth={0}
              points="51.088,67.804 40.267,86.16 29.446,67.804 21.527,72.563 28.673,86.282 34.637,83.362  40.267,86.16 45.897,83.362 51.861,86.282 59.007,72.563  "
            />
            <path
              fill="#E99260"
              strokeWidth={0}
              d="M58.21,38.35l0.556-7.234c0-9.46-9.042-17.125-18.495-17.125c-0.001,0-0.002,0-0.003,0v24.318 c-2.499,5.314-4.11,13.015-4.11,13.015h4.11v14.728c-2.633-0.001-5.993-1.431-9.04-3.732v4.213l17.613,2.624l0.472,0.144v-6.978 c4.376-3.303,8.105-8.405,8.105-13.671c1.88-0.013,3.401-1.539,3.401-3.423v-3.562C60.819,40.058,59.706,38.718,58.21,38.35z  M40.271,66.052C40.271,66.052,40.272,66.052,40.271,66.052C40.272,66.052,40.272,66.052,40.271,66.052 C40.271,66.052,40.271,66.052,40.271,66.052z M48.099,63.179c0.038-0.025,0.076-0.051,0.114-0.076 C48.176,63.128,48.138,63.153,48.099,63.179z M41.188,65.992c0.06-0.007,0.121-0.013,0.182-0.021 C41.308,65.98,41.249,65.985,41.188,65.992z M42.308,65.803c0.055-0.012,0.11-0.027,0.165-0.04 C42.418,65.776,42.362,65.79,42.308,65.803z M43.453,65.487c0.015-0.005,0.029-0.008,0.044-0.013 C43.482,65.479,43.467,65.483,43.453,65.487z M44.548,65.082c0.055-0.022,0.11-0.045,0.165-0.068 C44.658,65.038,44.603,65.06,44.548,65.082z M45.779,64.526c0.028-0.014,0.056-0.029,0.084-0.043 C45.835,64.496,45.807,64.512,45.779,64.526z M46.965,63.886c0.009-0.005,0.018-0.01,0.027-0.015 C46.983,63.876,46.974,63.88,46.965,63.886z"
            />
            <g>
              <g>
                <path
                  fill="#EC9B68"
                  strokeWidth={0}
                  d="M35.095,40.283c0.052,1.493-1.116,2.746-2.609,2.798c-1.493,0.052-2.746-1.116-2.798-2.609 c-0.052-1.493,1.116-2.746,2.609-2.798C33.79,37.622,35.042,38.79,35.095,40.283z"
                />
                <path
                  fill="#3C2417"
                  strokeWidth={0}
                  d="M35.095,39.598c0.052,1.493-1.116,2.746-2.609,2.798c-1.493,0.052-2.746-1.116-2.798-2.609 c-0.052-1.493,1.116-2.746,2.609-2.798C33.79,36.937,35.042,38.105,35.095,39.598z"
                />
                <path
                  fill="#FFFFFF"
                  strokeWidth={0}
                  d="M33.985,38.825c0.013,0.373-0.279,0.687-0.652,0.7c-0.373,0.013-0.686-0.279-0.699-0.652 c-0.013-0.373,0.279-0.686,0.652-0.7C33.659,38.159,33.972,38.451,33.985,38.825z"
                />
              </g>
              <g>
                <circle fill="#DB8258" strokeWidth={0} cx={48.146} cy={40.377} r={2.705} />
                <circle fill="#3C2417" strokeWidth={0} cx={48.146} cy={39.692} r={2.705} />
                <path
                  fill="#FFFFFF"
                  strokeWidth={0}
                  d="M49.741,38.825c0.013,0.373-0.279,0.687-0.652,0.7c-0.373,0.013-0.687-0.279-0.7-0.652 c-0.013-0.373,0.279-0.686,0.652-0.7C49.414,38.159,49.728,38.451,49.741,38.825z"
                />
              </g>
            </g>
            <path
              fill="#C6C6C6"
              strokeWidth={0}
              d="M40.27,51.196c-7.679,0-9.248,3.067-9.248,6.85c0,0,7.441-0.37,9.248-3.182 c1.807,2.812,9.248,3.182,9.248,3.182C49.518,54.263,47.949,51.196,40.27,51.196z"
            />
            <path
              fill="#C6C6C6"
              strokeWidth={0}
              d="M40.27,7.368c-25.856,0-20.789,24.645-18.594,32.723c0.106,0.389,0.679,0.308,0.672-0.095 c-0.089-5.212,0.447-16.13,6.579-15.238c4.935,0.718,8.593,0.962,11.344,0.941c2.751,0.021,6.409-0.223,11.344-0.941 c6.131-0.892,6.668,10.026,6.579,15.238c-0.007,0.403,0.566,0.485,0.672,0.095C61.059,32.014,66.126,7.368,40.27,7.368z"
            />
          </g>
        </g>
      </g>
    </g>
  ),
  viewBox: '0 0 80 80'
};
