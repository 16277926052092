import capitalize from 'lodash/capitalize';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { formatPhone } from 'services/formatPhone';
import * as bookingTypes from 'store/booking/types';
import { MuiBox, MuiDialog, MuiDialogTitle, MuiGrid, MuiTypography } from 'theme/material-ui';
import { AppointmentCardAction, AppointmentCardActions } from '../../components/AppointmentCard';
import { getLocationMapsLink } from '../../utils';

export interface AppointmentDetailDialogProps {
  open: boolean;
  onClose: () => void;
  bookedAppointment: bookingTypes.BookedAppointment;
  onReschedule?: () => void;
  onCancel?: () => void;
  isMoreThan2Hours: boolean;
}

export function AppointmentDetailsDialog({
  open,
  onClose,
  bookedAppointment,
  onReschedule,
  onCancel,
  isMoreThan2Hours
}: AppointmentDetailDialogProps) {
  const phoneLink = formatPhone(bookedAppointment.appointmentLocation?.phoneDisplay);
  const mapsLink = getLocationMapsLink(bookedAppointment.appointmentLocation);
  const isVideoAppointment = /\bVV\b/.test(bookedAppointment.appointmentReasonDesc);

  return (
    <MuiDialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      PaperProps={{ style: { minWidth: 435 } }}
    >
      <MuiDialogTitle id="wrt-title">Appointment Details</MuiDialogTitle>
      <MuiBox px={3}>
        <MuiGrid container direction="column" alignItems="center">
          <MuiGrid container direction="column" item alignItems="center">
            <MuiTypography fontWeight={600}>{capitalize(bookedAppointment.status)}</MuiTypography>
            <MuiTypography>{bookedAppointment.appointmentReasonDesc}</MuiTypography>
            <MuiTypography>with</MuiTypography>
            <MuiTypography fontWeight={600}>{bookedAppointment.providerDisplayName}</MuiTypography>
            {!isEmpty(bookedAppointment.appointmentLocation) ? (
              <>
                <MuiTypography>at</MuiTypography>
                <MuiTypography>
                  {bookedAppointment.appointmentLocation.locationName},&nbsp;
                  {bookedAppointment.appointmentLocation.city}
                </MuiTypography>
              </>
            ) : null}
          </MuiGrid>
          <MuiGrid item alignContent="center">
            <AppointmentCardActions>
              {phoneLink ? (
                <AppointmentCardAction
                  component="a"
                  href={`tel:${phoneLink}`}
                  label={phoneLink}
                  svgName="PhoneNumber"
                />
              ) : null}

              {mapsLink ? (
                <AppointmentCardAction
                  component="a"
                  href={mapsLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  svgName="Directions"
                  label="Directions"
                />
              ) : null}

              {bookedAppointment.isReschedulable && onReschedule && isMoreThan2Hours ? (
                <AppointmentCardAction
                  onClick={onReschedule}
                  svgName="Reschedule"
                  label="Reschedule"
                />
              ) : null}

              {!isVideoAppointment && bookedAppointment.cpmAppointment && isMoreThan2Hours ? (
                <AppointmentCardAction
                  onClick={onCancel}
                  svgName="CloseOutline"
                  label="Cancel Appointment"
                />
              ) : null}
            </AppointmentCardActions>
          </MuiGrid>
        </MuiGrid>
      </MuiBox>
    </MuiDialog>
  );
}

export default AppointmentDetailsDialog;
