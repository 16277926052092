import React from 'react';
import { KeyboardDatePickerProps, MuiDatePicker } from 'theme/material-ui';
import Spacer from 'components/UI/Layout/Spacer';
import { StyledAsterisk } from 'components/RequiredFieldsLegend';
import { InputLabel } from '@material-ui/core';
import styled from 'styled-components';

export const StyledDatePicker = styled(MuiDatePicker)<{ hideAsterisk?: boolean }>`
  width: 300px;
  ${({ hideAsterisk }) =>
    hideAsterisk
      ? `
  .MuiFormLabel-asterisk {
    display: none;
    }`
      : ''}
`;

export const PersonalInfoInputLabel = styled(InputLabel)`
  margin: 1rem 0 0.5rem 0;
`;

interface AuthLiteDatePickerProps extends KeyboardDatePickerProps {
  name: string;
  label: string;
  disableToolbar?: boolean;
}

export function AuthLiteDatePicker(props: AuthLiteDatePickerProps) {
  const { label, disableToolbar = true, ...rest } = props;
  return (
    <>
      <PersonalInfoInputLabel>
        {label}
        {props.required ? <StyledAsterisk /> : null}
      </PersonalInfoInputLabel>
      <StyledDatePicker
        data-testid={`pre-reg-date-picker-${rest.name}`}
        disableFuture
        autoOk
        hideAsterisk
        disableToolbar={disableToolbar}
        variant="inline"
        format="MM/DD/YYYY"
        margin="none"
        label="mm/dd/yyyy"
        KeyboardButtonProps={{
          'aria-label': 'change date'
        }}
        {...rest}
      />
      <Spacer spacing="large" />
    </>
  );
}
