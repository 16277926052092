import React from 'react';

export default {
  svg: (
    <g>
      <path
        fill="none"
        strokeLinecap="round"
        strokeWidth="2.3px"
        d="M23.11 26l3 3 3-3 1.63-1.63a2.49 2.49 0 0 0 0-3.52 2.49 2.49 0 0 0-3.52 0L26.15 22 25 20.81a2.49 2.49 0 0 0-3.52
        0 2.49 2.49 0 0 0 0 3.52z"
      />
      <rect
        fill="none"
        strokeLinecap="round"
        strokeWidth="2.3px"
        x="13.39"
        y="1.15"
        width="25.51"
        height="46.77"
        rx="3.68"
        ry="3.68"
      />
      <rect
        fill="none"
        strokeLinecap="round"
        strokeWidth="2.3px"
        x="17.4"
        y="6.82"
        width="17.5"
        height="32.42"
        rx="2.54"
        ry="2.54"
      />
      <circle cx="26.15" cy="43.44" r="1.21" />
      <path
        fill="none"
        strokeLinecap="round"
        strokeWidth="2.3px"
        d="M44.33 24.05h6.82M44.33 14.8l6.82-3.11M44.33 33.3l6.82 3.12M7.97 24.05H1.15M7.97 14.8l-6.82-3.11M7.97
        33.3l-6.82 3.12"
      />
    </g>
  ),
  viewBox: '0 0 52.3 49.07'
};
