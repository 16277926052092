import React from 'react';
import { Grid } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';

const ErrorMessage = ({ alert }) => {
  return (
    <div>
      <Grid item xs={12}>
        <Alert severity={alert.severity}>
          <AlertTitle>{alert.title}</AlertTitle>
          {alert.message}
        </Alert>
      </Grid>
    </div>
  );
};

export default ErrorMessage;
