import { useMemo } from 'react';
import uniqBy from 'lodash/uniqBy';
import startCase from 'lodash/startCase';
import { useLocation, matchPath } from 'react-router-dom';
import { BreadcrumbsProps } from 'components/Breadcrumbs';
import { useDispatch } from 'react-redux';
import { navigationBreadCrumbs } from 'store/navigation/actions.web';

export interface Route {
  component: any;
  path: string;
  [x: string]: any;
}

export function useRoutesToCrumbs<T extends Route>(routes: T[]): BreadcrumbsProps['crumbs'] {
  const dispatch = useDispatch();
  const location = useLocation();
  const currentPathParts = location.pathname.split('/');
  return useMemo(() => {
    const crumbs = routes
      .filter(route => {
        // we need to check if each path part matches correctly.
        // for instance /find-a-doctor and /u/find-a-doctor should never match
        // in the old system they did.
        // the other thing we check is for routes with a parameter.
        // /u/health-record/vitals/:name/:title should match /u/health-record/vitals/Temperature/temperature
        // in the old system it did not.
        const routePathComponents = route.path.split('/');
        const optionalPartAdjust = routePathComponents[routePathComponents.length - 1]?.endsWith(
          '?'
        )
          ? 1
          : 0;
        if (routePathComponents.length - optionalPartAdjust > currentPathParts.length) {
          return false;
        }

        let allMatch = true;
        routePathComponents.forEach((pathComponent, index) => {
          if (pathComponent[0] !== ':' && pathComponent !== currentPathParts[index]) {
            allMatch = false;
          }
        });
        return allMatch;
      })
      .map(route => ({
        label: route.crumbLabel || startCase(route.title || route.componentName),
        crumbComponent: route.crumbComponent,
        to:
          matchPath(location.pathname, {
            path: route.path,
            exact: route.exact
          })?.url || route.path
      }))
      .sort((a, b) => a.to.length - b.to.length);
    const breadCrumbs = uniqBy(crumbs, 'to');
    dispatch(navigationBreadCrumbs(breadCrumbs));
    return breadCrumbs;
  }, [location, routes]);
}
