import {
  HouseholdSpouseTextInputFields,
  HouseholdIncomeSection,
  AssetsSection,
  SeekingFACheckbox
} from './types';

export const PATIENT_FINANCIAL_SERVICE_NUMBER = '1-866-415-6556';

export const FA_NEW_APP_BODY = `Please completely fill this online form and return all required verification forms to the Intermountain facility where you had or plan to receive care for processing. Patients may not receive financial assistance if they do not complete the application process.

Patients may not receive financial assistance if they potentially could qualify for programs, such as Medicaid, but choose not to apply.`;

export const FA_REAPPLY_APP_BODY = `After submitting the application, Intermountain will contact you via mail if it requires any updated verification information.

Patients may not receive financial assistance if they potentially could qualify for programs, such as Medicaid, but choose not to apply.
`;

export const FA_FORM_STEPS = [
  { key: 1, label: 'Basic Info', routeName: 'FABasicInfo' },
  { key: 2, label: 'Household', routeName: 'FAHousehold' },
  { key: 3, label: 'Assets', routeName: 'FAAssets' },
  { key: 4, label: 'Bills', routeName: 'FABills' },
  { key: 5, label: 'Submit', routeName: 'FAComplete' }
];

export const RELATIONSHIP_OPTIONS = [
  {
    label: 'Self',
    value: 'self'
  },
  {
    label: 'Spouse',
    value: 'spouse'
  },
  {
    label: 'Parent',
    value: 'parent'
  },
  {
    label: 'Other',
    value: 'other'
  }
];

export const ADDRESS_LENGTH_OPTIONS = [
  {
    label: '1-3 months',
    value: '1',
    altLabel: 'One to three months'
  },
  {
    label: '4 months or more',
    value: '4',
    altLabel: 'Four months or more'
  }
];

export const HOUSEHOLD_SPOUSE_FIELDS: HouseholdSpouseTextInputFields[] = [
  {
    key: 1,
    fieldLabel: 'First Name',
    fieldName: 'spouseFirstName',
    placeholder: '',
    maxLength: 20,
    keyboardType: 'default',
    accessibilityLabel: 'spouse first name text input (no numbers or special characters)',
    editableDependency: 'patientFirstName',
    required: true,
    span: 5
  },
  {
    key: 2,
    fieldLabel: 'Middle Initial',
    fieldName: 'spouseMiddle',
    placeholder: '',
    maxLength: 1,
    keyboardType: 'default',
    accessibilityLabel: 'spouse middle initial text input (no numbers or special characters)',
    editableDependency: 'patientMiddle',
    span: 2
  },
  {
    key: 3,
    fieldLabel: 'Last Name',
    fieldName: 'spouseLastName',
    placeholder: '',
    maxLength: 20,
    keyboardType: 'default',
    accessibilityLabel: 'spouse last name text input (no numbers or special characters)',
    editableDependency: 'patientLastName',
    required: true,
    span: 5
  },
  {
    key: 4,
    fieldLabel: 'Social Security Number',
    fieldName: 'spouseSSN',
    placeholder: '',
    maxLength: 11,
    keyboardType: 'number-pad',
    accessibilityLabel: 'spouse SSN text input (only numbers)',
    editableDependency: 'patientSSN',
    textMask: 'maskSSN',
    span: 6
  },
  {
    key: 5,
    fieldLabel: 'Date of Birth',
    placeholder: '',
    fieldName: 'spouseBirthDate',
    accessibilityLabel: 'spouse birth date input picker',
    editableDependency: 'patientBirthDate',
    required: true,
    span: 6
  },
  {
    key: 6,
    fieldLabel: 'Home Phone Number',
    fieldName: 'spouseHomePhone',
    placeholder: '',
    maxLength: 14,
    keyboardType: 'number-pad',
    accessibilityLabel: 'spouse home phone text input (only numbers)',
    required: true,
    textMask: 'maskPhone',
    span: 4
  },
  {
    key: 7,
    fieldLabel: 'Work Phone Number',
    fieldName: 'spouseWorkPhone',
    placeholder: '',
    maxLength: 14,
    keyboardType: 'number-pad',
    accessibilityLabel: 'spouse work phone text input (only numbers)',
    textMask: 'maskPhone',
    span: 4
  },
  {
    key: 8,
    fieldLabel: 'Cell Phone Number',
    fieldName: 'spouseCellPhone',
    placeholder: '',
    maxLength: 14,
    keyboardType: 'number-pad',
    accessibilityLabel: 'spouse cell phone text input (only numbers)',
    textMask: 'maskPhone',
    span: 4
  },
  {
    key: 9,
    fieldLabel: 'Employer',
    fieldName: 'spouseEmployer',
    placeholder: '',
    maxLength: 50,
    keyboardType: 'default',
    accessibilityLabel: 'spouse employer text input (no numbers or special characters)'
  }
];

export const HOUSEHOLD_MARITAL_STATUS = [
  { value: 'S', label: 'Single', accessibilityLabel: 'marital status single button' },
  { value: 'M', label: 'Married', accessibilityLabel: 'marital status married button' }
];

export const HOUSEHOLD_INCOME_FIELDS: HouseholdIncomeSection[] = [
  {
    key: 1,
    label: 'Monthly Employment Income (Gross)',
    checkboxFieldName: 'hasIncome',
    fields: {
      textInputs: [
        {
          key: 1,
          fieldLabel: 'Responsible Party Amount ($)',
          placeholder: '',
          fieldName: 'grossIncome',
          required: true,
          maxLength: 14,
          skipNextIf: 'maritalStatus',
          isEqualTo: 'S'
        },
        {
          key: 2,
          fieldLabel: 'Spouse Amount ($)',
          placeholder: '',
          fieldName: 'spouseIncome',
          required: true,
          conditionalOn: 'maritalStatus',
          maxLength: 14
        }
      ]
    }
  },
  {
    key: 2,
    label: 'Pension/Retirement, Unemployment, Disability Income, etc.',
    checkboxFieldName: 'hasPension',
    fields: {
      textInputs: [
        {
          key: 3,
          fieldLabel: 'Monthly Household Amount ($)',
          placeholder: '',
          fieldName: 'pension',
          required: true,
          maxLength: 14
        }
      ]
    }
  },
  {
    key: 3,
    label: 'Child Support',
    checkboxFieldName: 'hasChildSupport',
    fields: {
      textInputs: [
        {
          key: 4,
          fieldLabel: 'Monthly Amount ($)',
          placeholder: '',
          fieldName: 'childSupport',
          required: true,
          maxLength: 14
        }
      ]
    }
  },
  {
    key: 4,
    label: 'Alimony',
    checkboxFieldName: 'hasAlimony',
    fields: {
      textInputs: [
        {
          key: 5,
          fieldLabel: 'Monthly Responsible Party Amount ($)',
          placeholder: '',
          fieldName: 'alimony',
          required: true,
          maxLength: 14
        }
      ]
    }
  },
  {
    key: 5,
    label: 'Grants/Scholarships',
    radioConditional: 'grants',
    checkboxFieldName: 'hasGrants',
    fields: {
      textInputs: [
        {
          key: 6,
          fieldLabel: 'Responsible Party Amount ($)',
          fieldName: 'grants',
          placeholder: '',
          required: true,
          maxLength: 14
        }
      ],
      radioButtons: [
        {
          fieldLabel: 'Yearly',
          fieldName: 'Yearly',
          groupName: 'grantPeriod'
        },
        {
          fieldLabel: 'Monthly',
          fieldName: 'Monthly',
          groupName: 'grantPeriod'
        }
      ]
    }
  },
  {
    key: 6,
    label: 'Other',
    radioConditional: 'otherIncome',
    checkboxFieldName: 'hasOther',
    fields: {
      textInputs: [
        {
          key: 7,
          fieldLabel: 'Amount ($)',
          fieldName: 'otherIncome',
          placeholder: '',
          required: true,
          maxLength: 14,
          skipNextIf: 'otherIncome',
          isEqualTo: '0'
        },
        {
          key: 8,
          fieldLabel: 'Please List Sources',
          fieldName: 'otherIncomeSource',
          placeholder: '',
          required: true,
          conditionalOn: 'otherIncome',
          maxLength: 50,
          keyboardType: 'default'
        }
      ],
      radioButtons: [
        {
          fieldLabel: 'Yearly',
          fieldName: 'Yearly',
          groupName: 'otherPeriod'
        },
        {
          fieldLabel: 'Monthly',
          fieldName: 'Monthly',
          groupName: 'otherPeriod'
        }
      ]
    }
  }
];

export const ASSETS_FIELDS: AssetsSection[] = [
  {
    key: 1,
    label: 'Cash',
    fields: {
      textInputs: [
        {
          key: 1,
          fieldLabel: 'Total balance amount ($)',
          fieldName: 'cash',
          placeholder: '',
          maxLength: 14,
          keyboardType: 'decimal-pad'
        },
        {
          key: 2,
          fieldLabel: 'Name of Financial institution(s)',
          fieldName: 'cashInstitution',
          placeholder: '',
          conditionalOn: 'cash',
          maxLength: 50
        }
      ]
    }
  },
  {
    key: 2,
    label: 'Savings Account(s)',
    fields: {
      textInputs: [
        {
          key: 3,
          fieldLabel: 'Total balance amount ($)',
          fieldName: 'saving',
          placeholder: '',
          maxLength: 14,
          keyboardType: 'decimal-pad'
        },
        {
          key: 4,
          fieldLabel: 'Name of Financial institution(s)',
          fieldName: 'savingInstitution',
          placeholder: '',
          conditionalOn: 'saving',
          maxLength: 50
        }
      ]
    }
  },
  {
    key: 3,
    label: 'Checking Account(s)',
    fields: {
      textInputs: [
        {
          key: 5,
          fieldLabel: 'Total balance amount ($)',
          fieldName: 'checking',
          placeholder: '',
          maxLength: 14,
          keyboardType: 'decimal-pad'
        },
        {
          key: 6,
          fieldLabel: 'Name of Financial institution(s)',
          fieldName: 'checkingInstitution',
          placeholder: '',
          conditionalOn: 'checking',
          maxLength: 50
        }
      ]
    }
  },
  {
    key: 4,
    label: 'Stocks and/or bonds',
    fields: {
      textInputs: [
        {
          key: 7,
          fieldLabel: 'Total balance amount ($)',
          fieldName: 'stocks',
          placeholder: '',
          maxLength: 14,
          keyboardType: 'decimal-pad'
        },
        {
          key: 8,
          fieldLabel: 'Name of Financial institution(s)',
          placeholder: '',
          fieldName: 'stocksInstitution',
          conditionalOn: 'stocks',
          maxLength: 50
        }
      ]
    }
  },
  {
    key: 5,
    label: 'Do you have Medicare?',
    fields: {
      radioButtons: [
        {
          fieldLabel: 'Yes',
          fieldName: 'Y',
          groupName: 'hasMedicare'
        },
        {
          fieldLabel: 'No',
          fieldName: 'N',
          groupName: 'hasMedicare'
        }
      ],
      textInputs: [
        {
          key: 9,
          fieldLabel: 'Total balance amount ($)',
          fieldName: 'k401',
          placeholder: '',
          conditionalOn: 'hasMedicare',
          maxLength: 14,
          keyboardType: 'decimal-pad'
        },
        {
          key: 10,
          fieldLabel: 'Name of Financial institution(s)',
          placeholder: '',
          fieldName: 'k401Institution',
          conditionalOn: 'hasMedicare',
          maxLength: 50
        },
        {
          key: 11,
          fieldLabel: 'Total balance amount ($)',
          fieldName: 'ira',
          placeholder: '',
          conditionalOn: 'hasMedicare',
          maxLength: 14,
          keyboardType: 'decimal-pad'
        },
        {
          key: 12,
          fieldLabel: 'Name of Financial institution(s)',
          fieldName: 'iraInstitution',
          placeholder: '',
          conditionalOn: 'hasMedicare',
          maxLength: 50
        }
      ]
    }
  }
];

export const SEEKING_FA_CHECKBOXES: SeekingFACheckbox[] = [
  {
    key: 1,
    label: 'My employer or my spouse’s employer offers group health insurance',
    fieldName: 'hasInsured',
    fields: {
      textInputs: [
        {
          fieldLabel: 'Insurance company',
          placeholder: '',
          fieldName: 'insuranceCompany',
          maxLength: 50
        }
      ]
    }
  },
  {
    key: 2,
    label: 'I have other types of insurance such as Allstate, AFLAC, etc.',
    fieldName: 'hasOtherInsured',
    fields: {
      textInputs: [
        {
          fieldLabel: 'Insurance company',
          placeholder: '',
          fieldName: 'otherCompany',
          maxLength: 50
        }
      ]
    }
  },
  {
    key: 3,
    label: 'I have a Health Savings / Flex Spending Account',
    fieldName: 'hasFlex',
    fields: {
      textInputs: [
        {
          fieldLabel: 'Total Balance Amount ($)',
          fieldName: 'flexBalance',
          placeholder: '',
          maxLength: 14,
          keyboardType: 'decimal-pad'
        }
      ]
    }
  },
  {
    key: 4,
    label: 'My employer reimburses me for any deductible or healthcare costs',
    fieldName: 'hasReimburse'
  },
  {
    key: 5,
    label: 'I was denied Medicaid',
    fieldName: 'hasDeny',
    helpText: 'Note: please submit copy of the Medicaid denial with your application'
  },
  {
    key: 6,
    label: 'I have applied for state assistance programs (CHIP, PCN, Crime Victims, etc.)',
    fieldName: 'hasStateAst'
  },
  {
    key: 7,
    label: 'I am eligible for COBRA through a previous employer',
    fieldName: 'hasCobra'
  },
  {
    key: 8,
    label: 'I have family or church assistance',
    fieldName: 'hasAssistance'
  }
];
