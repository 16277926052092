import React from 'react';

import dayjs from 'dayjs';
import * as bookingTypes from 'store/booking/types';
import * as bookingActions from 'store/booking/actions';

import { MuiCardContent, MuiGrid } from 'theme/material-ui';
import { StyledMuiButton } from '../components/styled';
import { formatTimeToMountainTimeZone } from 'modules/utils/DateUtils';
import useNavigationAnalytics from 'hooks/useNavigationAnalytics';
import { AnalyticsEvent } from 'services/AnalyticsService';

export const TimeSlots = ({
  timeSlots,
  appointmentDetails,
  setAppointmentTime
}: {
  timeSlots: bookingTypes.TimeSlot[];
  appointmentDetails: bookingTypes.AppointmentDetails;
  setAppointmentTime: typeof bookingActions.setAppointmentTime;
}) => {
  const analytics = useNavigationAnalytics(AnalyticsEvent.AppointmentTimeClicked);

  const getTimeSlotIsSelected = (timeSlot: bookingTypes.TimeSlot) => {
    if (!timeSlot || !appointmentDetails.appointmentTime) {
      return false;
    }
    return timeSlot.datetime === appointmentDetails?.appointmentTime?.datetime;
  };

  return (
    <MuiCardContent>
      <MuiGrid container spacing={1}>
        {timeSlots
          .sort((t1, t2) => (dayjs(t1.datetime).isAfter(t2.datetime) ? 1 : -1))
          .map(timeSlot => {
            const isSelected = getTimeSlotIsSelected(timeSlot);

            return (
              <MuiGrid data-testid="timeslot" item xs={4} key={timeSlot.datetime}>
                <StyledMuiButton
                  fullWidth
                  aria-selected={isSelected}
                  variant={isSelected ? 'contained' : 'outlined'}
                  onClick={() => {
                    setAppointmentTime(timeSlot);
                    analytics();
                  }}
                >
                  {formatTimeToMountainTimeZone(timeSlot.datetime)}
                </StyledMuiButton>
              </MuiGrid>
            );
          })}
      </MuiGrid>
    </MuiCardContent>
  );
};
