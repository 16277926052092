import React from 'react';

export default {
  svg: (
    <g>
      <g>
        <g>
          <circle fill="#50E3C2" strokeWidth={0} cx={40} cy={40} r={38.5} />
          <path
            fill="#FFFFFF"
            strokeWidth={0}
            d="M40,2.5c20.711,0,37.5,16.789,37.5,37.5S60.711,77.5,40,77.5S2.5,60.711,2.5,40S19.289,2.5,40,2.5 M40,0.5 C18.22,0.5,0.5,18.22,0.5,40c0,21.78,17.72,39.5,39.5,39.5S79.5,61.78,79.5,40C79.5,18.22,61.78,0.5,40,0.5L40,0.5z"
          />
        </g>
        <g>
          <defs>
            <circle id="SVGID_9_" cx={40} cy={40} r={37.5} />
          </defs>
          <clipPath id="SVGID_4_">
            <use xlinkHref="#SVGID_9_" />
          </clipPath>
          <g clipPath="url(#SVGID_4_)">
            <path
              fill="#564137"
              strokeWidth={0}
              d="M68.132,68.98c-2.402-0.719-4.752-2.85-7.022-3.915c0.253,0.529,0.569,2.261,0.939,2.716 c-2.461-0.956-4.417-2.909-5.933-5.07c-3.854-5.495-5.194-12.479-4.715-19.174c0.108-1.509,0.31-3.036,0.922-4.42 c0.46-1.039,1.147-1.977,1.999-2.729c0.544-0.48,1.173-0.892,1.883-1.042c0.92-0.194,1.886,0.076,2.706,0.534 c1.957,1.094,3.17,3.162,3.972,5.255c2.448,6.393,1.838,13.493,2.703,20.284C65.926,64.087,66.538,66.815,68.132,68.98z"
            />
            <path
              fill="#C48A5F"
              strokeWidth={0}
              d="M55.73,37.144v-7.125c0-7.568-8.761-17.128-15.696-17.128c-6.935,0-15.696,9.561-15.696,17.128v7.125 c-1.926,0-3.488,1.534-3.488,3.426v3.563c0,1.892,1.562,3.426,3.488,3.426c0,5.128,4.024,11.293,8.775,14.738l0,7.325 l-3.426,0.729l3.426,21.627l13.703,0l3.426-21.627l-3.426-0.729v-7.224c4.812-3.424,8.914-9.662,8.914-14.84 c1.926,0,3.488-1.534,3.488-3.426V40.57C59.218,38.678,57.656,37.144,55.73,37.144z"
            />
            <path
              fill="#B87249"
              strokeWidth={0}
              d="M55.73,37.144v-7.125c0-7.568-8.761-17.128-15.696-17.128v27.748C37.49,45.953,36.546,51.6,36.546,51.6 l3.488,0.685v12.672c-2.197-0.016-4.574-1.014-6.796-2.611v2.193l13.703,4.82v-7.013c-1.806,1.297-3.713,2.19-5.544,2.492 c6.666-1.14,14.333-10.202,14.333-17.281c1.926,0,3.488-1.534,3.488-3.426V40.57C59.218,38.678,57.656,37.144,55.73,37.144z  M40.065,64.959c0.075,0,0.151-0.007,0.226-0.01c-0.067,0.002-0.135,0.011-0.202,0.011C40.081,64.96,40.073,64.959,40.065,64.959 z M40.756,64.924c0.179-0.017,0.358-0.039,0.538-0.068C41.114,64.883,40.934,64.908,40.756,64.924z"
            />
            <g>
              <g>
                <path
                  fill="#A0603B"
                  strokeWidth={0}
                  d="M34.174,41.683c0.052,1.493-1.116,2.746-2.61,2.799c-1.493,0.052-2.746-1.116-2.799-2.61 c-0.052-1.493,1.116-2.746,2.61-2.799C32.869,39.021,34.122,40.19,34.174,41.683z"
                />
                <path
                  fill="#3C2417"
                  strokeWidth={0}
                  d="M34.174,40.998c0.052,1.493-1.116,2.746-2.61,2.799c-1.493,0.052-2.746-1.116-2.799-2.61 c-0.052-1.493,1.116-2.746,2.61-2.799C32.869,38.336,34.122,39.505,34.174,40.998z"
                />
                <path
                  fill="#FFFFFF"
                  strokeWidth={0}
                  d="M33.065,40.225c0.013,0.373-0.279,0.687-0.652,0.7c-0.373,0.013-0.687-0.279-0.7-0.652 c-0.013-0.373,0.279-0.687,0.652-0.7C32.738,39.559,33.052,39.851,33.065,40.225z"
                />
                <path
                  fill="#3C2417"
                  strokeWidth={0}
                  d="M34.761,38.931c-0.499-1.159-1.778-2.038-3.183-2.188c-0.861-0.094-2.477,0.034-3.865,1.791 c-0.066,0.006-0.128,0.007-0.205-0.001c-0.154-0.014-0.369-0.064-0.579-0.153c-0.426-0.172-0.781-0.531-0.781-0.531 c-0.025-0.024-0.065-0.033-0.097-0.017c-0.042,0.02-0.059,0.071-0.039,0.113c0,0,0.053,0.118,0.156,0.287 c0.1,0.17,0.259,0.39,0.46,0.582c0.199,0.193,0.438,0.358,0.638,0.458c0.099,0.051,0.19,0.086,0.257,0.11 c0.064,0.023,0.132,0.035,0.12,0.034c0.187,0.019,0.357-0.081,0.438-0.238c0.909-1.27,2.125-1.895,3.423-1.755 c1.147,0.122,2.227,0.853,2.625,1.778c0.076,0.174,0.278,0.254,0.45,0.179C34.755,39.307,34.836,39.105,34.761,38.931z"
                />
              </g>
              <g>
                <path
                  fill="#A25432"
                  strokeWidth={0}
                  d="M51.302,41.683c0.052,1.493-1.116,2.746-2.61,2.799c-1.493,0.052-2.746-1.116-2.799-2.61 c-0.052-1.493,1.116-2.746,2.61-2.799C49.997,39.021,51.25,40.19,51.302,41.683z"
                />
                <path
                  fill="#3C2417"
                  strokeWidth={0}
                  d="M51.302,40.998c0.052,1.493-1.116,2.746-2.61,2.799c-1.493,0.052-2.746-1.116-2.799-2.61 c-0.052-1.493,1.116-2.746,2.61-2.799C49.997,38.336,51.25,39.505,51.302,40.998z"
                />
                <path
                  fill="#FFFFFF"
                  strokeWidth={0}
                  d="M50.193,40.225c0.013,0.373-0.279,0.687-0.652,0.7c-0.373,0.013-0.687-0.279-0.7-0.652 c-0.013-0.373,0.279-0.687,0.652-0.7C49.867,39.559,50.18,39.851,50.193,40.225z"
                />
                <path
                  fill="#3C2417"
                  strokeWidth={0}
                  d="M45.307,38.931c0.499-1.159,1.778-2.038,3.183-2.188c0.861-0.094,2.477,0.034,3.865,1.791 c0.066,0.006,0.128,0.007,0.205-0.001c0.154-0.014,0.369-0.064,0.578-0.153c0.426-0.172,0.781-0.531,0.781-0.531 c0.025-0.024,0.065-0.033,0.097-0.017c0.042,0.02,0.059,0.071,0.039,0.113c0,0-0.053,0.118-0.156,0.287 c-0.1,0.17-0.259,0.39-0.46,0.582c-0.199,0.193-0.438,0.358-0.638,0.458c-0.099,0.051-0.19,0.086-0.257,0.11 c-0.064,0.023-0.132,0.035-0.121,0.034c-0.187,0.019-0.357-0.081-0.438-0.238c-0.909-1.27-2.125-1.895-3.423-1.755 c-1.147,0.122-2.227,0.853-2.625,1.778c-0.076,0.174-0.278,0.254-0.45,0.179C45.313,39.307,45.232,39.105,45.307,38.931z"
                />
              </g>
            </g>
            <g>
              <path
                fill="#FFFFFF"
                strokeWidth={0}
                d="M45.515,54.816c0,2.691-2.454,4.872-5.481,4.872c-3.027,0-5.481-2.181-5.481-4.872H45.515z"
              />
            </g>
            <g>
              <path
                fill="#F05D44"
                strokeWidth={0}
                d="M23.653,51.129c-0.378,0-0.685-0.307-0.685-0.685v-2.886c0-0.378,0.307-0.685,0.685-0.685 c0.378,0,0.685,0.307,0.685,0.685v2.886C24.339,50.822,24.032,51.129,23.653,51.129z"
              />
              <path
                fill="#F05D44"
                strokeWidth={0}
                d="M56.415,51.129c-0.379,0-0.685-0.307-0.685-0.685v-2.886c0-0.378,0.306-0.685,0.685-0.685 c0.379,0,0.685,0.307,0.685,0.685v2.886C57.1,50.822,56.794,51.129,56.415,51.129z"
              />
            </g>
            <path
              fill="#564137"
              strokeWidth={0}
              d="M49.443,32.98c0.466,1.672,1.377,3.878,2.96,5.193c-4.659-1.192-8.626-3.946-11.496-7.208 c0.281,1.685,1.35,3.522,3.001,5.145c-1.254-0.555-2.473-1.274-3.638-2.097c-2.597-1.822-4.906-4.186-6.618-6.584 c-2.713,5.502-7.758,12.362-11.163,12.465c-5.299-9.749-1.423-30.378,17.761-30.378s23.189,19.944,18.384,30.951 C54.769,39.644,50.519,34.577,49.443,32.98z"
            />
            <g>
              <path
                fill="#9E005D"
                strokeWidth={0}
                d="M71.238,108.5l-0.463-16.256c-0.751-5.874-2.109-12.484-7.009-17.186 c-4.901-4.703-10.6-4.536-15.765-4.93c0,0-0.01,0.08-0.028,0.219L40,92.162l-7.973-21.813c-0.018-0.139-0.028-0.22-0.028-0.22 c-5.165,0.393-10.865,0.227-15.765,4.93c-4.9,4.702-6.258,11.312-7.009,17.186L8.762,108.5H71.238z"
              />
              <g>
                <polygon
                  fill="#9B88BE"
                  strokeWidth={0}
                  points="24.168,75.704 31.678,78.543 28.73,82.654 40,92.162 31.999,70.129  "
                />
                <polygon
                  fill="#9B88BE"
                  strokeWidth={0}
                  points="55.833,75.704 47.989,70.222 40,92.162 51.27,82.654 48.322,78.543  "
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  ),
  viewBox: '0 0 80 80'
};
