import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import BloodPressureLineChart from 'components/BloodPressureLineChart/BloodPressureLineChart';
import BloodPressureTrendsModal from 'components/BloodPressureTrendsModal/BloodPressureTrendsModal';
import { useWindowSize } from 'hooks/useWindowSize';
import { Color } from 'modules/styles/colors';
import React from 'react';
import { NavigationScreenProps } from 'screens/navigation';
import { BloodPressureData } from 'store/vitals/types';
import BloodPressureCircle from '../BloodPressureCircle/BloodPressureCircle';
import { BloodPressureContainer, CirclesContainer } from './styled';
import FlexBox from 'components/UI/Layout/FlexBox';
import BloodTypeBadge from './BloodTypeBadge';
import { MuiBox } from 'theme/material-ui';

interface Props extends NavigationScreenProps {
  bloodPressureData: BloodPressureData;
  bloodType?: string;
}

const smallDeviceWidth = 350;
const lgCircleWidth = 152;
const smCircleWidth = 135;

export function BloodPressureVital({ bloodPressureData, bloodType }: Props) {
  const { data = [] } = bloodPressureData;
  const deviceWidth = useWindowSize().width || window.innerWidth;
  const lgCircleFontSz = deviceWidth < smallDeviceWidth ? `38px` : `26px`;
  const smCircleFontSz = deviceWidth < smallDeviceWidth ? `26px` : `22px`;
  const isGreaterThan1250 = useMediaQuery('(min-width: 1250px)');

  return (
    <FlexBox flexDirection="row" justifyContent="center">
      <MuiBox display="inline-block">
        <FlexBox flexDirection="row">
          <FlexBox flexDirection="row" width={0} flex="1">
            <BloodTypeBadge bloodType={bloodType} />
          </FlexBox>
        </FlexBox>
        <BloodPressureContainer>
          <CirclesContainer>
            <BloodPressureCircle
              title="Latest"
              sbpValue={bloodPressureData.latestSBP}
              dbpValue={bloodPressureData.latestDBP}
              dateText={bloodPressureData.latestDateText}
              maxWidth={lgCircleWidth}
              fontSize={lgCircleFontSz}
              fontColor={Color.lightBlue}
              marginTop={0}
            />
            <BloodPressureCircle
              title="Average"
              sbpValue={bloodPressureData.aveSBP}
              dbpValue={bloodPressureData.aveDBP}
              dateText={bloodPressureData.aveDateText}
              maxWidth={smCircleWidth}
              fontSize={smCircleFontSz}
              marginTop={20}
              marginLeft={10}
              paddingLeft={0}
            />
          </CirclesContainer>

          {isGreaterThan1250 ? (
            <BloodPressureLineChart data={data} />
          ) : (
            <BloodPressureTrendsModal data={data} />
          )}
        </BloodPressureContainer>
      </MuiBox>
    </FlexBox>
  );
}

export default BloodPressureVital;
