import React from 'react';
import { Row, Message, ShortMessage } from './styled';

interface ListActionsSelectionRowLabelProps {
  message: string;
  shortMessage: string;
  isSmallScreen: unknown;
  hideMessage?: boolean;
}

const ListActionsSelectionRowLabel = ({
  message,
  shortMessage,
  hideMessage
}: ListActionsSelectionRowLabelProps) => {
  return (
    <Row>
      {hideMessage ? null : <ShortMessage>{shortMessage}</ShortMessage>}
      <Message>{message}</Message>
    </Row>
  );
};

export default ListActionsSelectionRowLabel;
