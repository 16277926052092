import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import IHLogo from 'assets/branding/ih-logo-640x196-on-light.png';
import SupergraphicBackground from 'components/common/SupergraphicBackground/SupergraphicBackground';
import SupergraphicCopyright from 'components/common/SupergraphicBackground/SupergraphicCopyright';
import Spacer from 'components/UI/Layout/Spacer';
import ConfirmModal from 'components/UI/Modals/ConfirmModal';
import {
  LEARN_MORE_MESSAGE_1_WEB,
  LEARN_MORE_MESSAGE_2_WEB,
  LEARN_MORE_MESSAGE_3A,
  LEARN_MORE_MESSAGE_3B,
  NEW_APP_MESSAGE_1,
  NEW_APP_MESSAGE_2_WEB,
  NEW_APP_MESSAGE_3_WEB,
  NEW_APP_MESSAGE_3B_WEB,
  NEW_APP_MESSAGE_4
} from 'modules/constants/landing';
import { technicalHelpNumber } from 'modules/constants/phoneNumbers';
import { Color } from 'modules/styles/colors';
import { FontSize, FontWeight, Spacing } from 'modules/styles/variables.web';
import queryString from 'query-string';
import { GUEST_ROUTES } from 'screens/Guest/constants';
import { NavigationScreenProps } from 'screens/navigation';
import { CDP_ID_KEY } from 'services/AnalyticsService.web';
import { RedirectBrand } from 'store/register';
import { handleLoginPress } from 'store/pageActions/handleLoginPress';
import {
  dfdDefaultTheme,
  MuiBox,
  MuiButton,
  MuiGrid,
  MuiLink,
  MuiTypography
} from 'theme/material-ui';
import { Logo } from './styled';

export default function Landing({ history }: NavigationScreenProps) {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const urlParams = useMemo(() => queryString.parse(search) || {}, [search]);
  const [showTimedOut, setShowTimedOut] = useState(false);
  const [showLearnMore, setShowLearnMore] = useState(false);

  const isSmallScreen = useMediaQuery(dfdDefaultTheme.breakpoints.down('sm'));

  const LOGIN_BOX_MAX_WIDTH = 496;
  const LOGIN_BOX_MAX_HEIGHT = 428;

  const LOGIN_BOX_MAX_WIDTH_SMALL = 343;
  const LOGIN_BOX_MAX_HEIGHT_SMALL = 360;

  const LANDING_BUTTON_MAX_WIDTH = 327;
  const LANDING_BUTTON_MAX_WIDTH_SMALL = 284;

  const handleRegistrationPress = () => {
    history.push(`/register`);
  };

  const handleGuestPress = () => {
    history.push(GUEST_ROUTES.GUEST_HOME);
  };

  const handleShowLearnMorePress = () => {
    showLearnMore ? setShowLearnMore(false) : setShowLearnMore(true);
  };

  useEffect(() => {
    window.sessionStorage.setItem('registerBrand', RedirectBrand.MHP);
    window.sessionStorage.setItem('redirectIdx', '000');
    const timedOut = window.localStorage.getItem('timedOut');
    if (timedOut) {
      setShowTimedOut(!!timedOut);
      window.localStorage.removeItem('timedOut');
    }
  }, []);

  useEffect(() => {
    if (!!urlParams['cdp-id']) {
      /*
       * Cdp id is a platform-crosswalk value that will tie a user's analytics data for our app to that of another app.
       * Note: this may only ever apply to the IH.org site. They are the only external app passing this to us currently.
       */
      window.localStorage.setItem(CDP_ID_KEY, urlParams['cdp-id'] as string);
    }
  }, [urlParams]);

  const LEARN_MORE_MESSAGE_COMPLETE = (
    <MuiBox>
      <Spacer size="small" />
      <MuiTypography>{LEARN_MORE_MESSAGE_1_WEB}</MuiTypography>
      <Spacer size="medium" />
      <MuiTypography>{LEARN_MORE_MESSAGE_2_WEB}</MuiTypography>
      <Spacer size="medium" />
      <MuiTypography display="inline" fontWeight={FontWeight.semibold}>
        {LEARN_MORE_MESSAGE_3A}
      </MuiTypography>
      <MuiTypography display="inline">{LEARN_MORE_MESSAGE_3B}</MuiTypography>
      <MuiTypography
        display="inline"
        noWrap
        fontWeight={FontWeight.semibold}
        color={Color.secondary}
      >
        {' '}
        <MuiLink href={`tel: ${technicalHelpNumber}`}>{technicalHelpNumber}</MuiLink>
        {'. '}
      </MuiTypography>
      <Spacer size="small" />
    </MuiBox>
  );

  return (
    <SupergraphicBackground isSmallScreen={isSmallScreen}>
      <ConfirmModal
        test-dataid="timeout-dialog"
        isOpen={showTimedOut}
        showCloseIcon
        icon="ExclamationCircle"
        title="Session Timeout"
        description="You have been logged out due to inactivity. Please log in again."
        confirmLabel="Sign in"
        onConfirm={() => setShowTimedOut(false)}
        fullWidth
        maxWidth={`${Spacing.xxLarge * 3}px`}
        position="bottom"
      />
      <ConfirmModal
        isOpen={showLearnMore}
        showCloseIcon
        description={LEARN_MORE_MESSAGE_COMPLETE}
        confirmLabel="Close"
        onConfirm={() => setShowLearnMore(false)}
        fullWidth
        maxWidth={isSmallScreen ? '90%' : `${LOGIN_BOX_MAX_WIDTH * 0.9}px`}
        position="middle"
        style={{ height: 'fit-content', paddingBottom: 0 }}
      />

      <MuiBox
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        height="100%"
        width={isSmallScreen ? '90%' : LOGIN_BOX_MAX_WIDTH}
        maxWidth={isSmallScreen ? LOGIN_BOX_MAX_WIDTH_SMALL : LOGIN_BOX_MAX_WIDTH}
        margin="auto"
        textAlign="center"
      >
        <MuiBox />
        <MuiBox justifyContent="center" width="100%" marginTop={isSmallScreen ? 1 : 10}>
          <MuiBox color={Color.white} mb={4}>
            <MuiTypography
              display="inline"
              fontWeight={FontWeight.semibold}
              fontSize={isSmallScreen ? FontSize.base : FontSize.large}
            >
              {NEW_APP_MESSAGE_1}
            </MuiTypography>
            <MuiTypography
              display="inline"
              fontSize={isSmallScreen ? FontSize.base : FontSize.large}
            >
              {NEW_APP_MESSAGE_2_WEB}
            </MuiTypography>
            <MuiTypography
              display="inline"
              fontSize={isSmallScreen ? FontSize.base : FontSize.large}
              noWrap
            >
              {NEW_APP_MESSAGE_3_WEB}
            </MuiTypography>
            <MuiTypography
              display="inline"
              fontSize={isSmallScreen ? FontSize.base : FontSize.large}
              noWrap
            >
              {NEW_APP_MESSAGE_3B_WEB}
            </MuiTypography>
            <MuiTypography
              fontSize={isSmallScreen ? FontSize.base : FontSize.large}
              noWrap
              style={{ textDecorationLine: 'underline', cursor: 'pointer' }}
              onClick={handleShowLearnMorePress}
            >
              {NEW_APP_MESSAGE_4}
            </MuiTypography>
          </MuiBox>
          <MuiBox
            bgcolor={Color.white}
            borderRadius={20}
            height={isSmallScreen ? LOGIN_BOX_MAX_HEIGHT_SMALL : LOGIN_BOX_MAX_HEIGHT}
          >
            <MuiBox py={isSmallScreen ? 5 : 6}>
              <Logo isSmallScreen={isSmallScreen} src={IHLogo} alt="" />
            </MuiBox>
            <MuiBox
              maxWidth={isSmallScreen ? LANDING_BUTTON_MAX_WIDTH_SMALL : LANDING_BUTTON_MAX_WIDTH}
              mx="auto"
              pb={isSmallScreen ? 4 : 6}
            >
              <MuiGrid container spacing={2}>
                <MuiGrid item xs={12}>
                  <MuiButton
                    data-testid="sign-in-button"
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={() => dispatch(handleLoginPress('landing'))}
                  >
                    Sign in
                  </MuiButton>
                </MuiGrid>
                <MuiGrid item xs={12}>
                  <MuiButton
                    data-testid="create-account-button"
                    fullWidth
                    color="primary"
                    variant="outlined"
                    onClick={handleRegistrationPress}
                  >
                    Create account
                  </MuiButton>
                </MuiGrid>
                <MuiGrid item xs={12}>
                  <MuiButton
                    fullWidth
                    variant="text"
                    color="primary"
                    onClick={handleGuestPress}
                    data-testid="continue-as-guest-button"
                  >
                    Continue as guest
                  </MuiButton>
                </MuiGrid>
              </MuiGrid>
            </MuiBox>
          </MuiBox>
        </MuiBox>
        <SupergraphicCopyright isSmallScreen={isSmallScreen} />
      </MuiBox>
    </SupergraphicBackground>
  );
}
