import Typography from 'components/UI/Typography';
import Box from '@material-ui/core/Box';
import { MuiBox } from 'theme/material-ui';
import React from 'react';
import { FinancialAssistance, ListOfDocs, ListOfDocs2, ApplicationSection } from './shared';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { FinancialAssistanceApplication as Application } from 'store/billing/types';

interface PendingProps {
  application: Application;
}

const PendingStatus = (props: PendingProps) => {
  const { application = {} as Application } = props;

  return (
    <>
      <Container>
        <Grid container>
          <ApplicationSection application={application} />
        </Grid>
        <Grid container>
          <Grid item md={7} xs={12}>
            <Box p={1}>
              <MuiBox flexDirection="column">
                <Typography accessibilityLabel="Under review status message">
                  Your application is being reviewed. During this process, please verify that you
                  have submitted the following verification forms. If you have any questions, please
                  call Intermountain Patient Financial Services at <FinancialAssistance />. Please
                  allow about 2 weeks for the application to be processed
                </Typography>
                <ListOfDocs />
              </MuiBox>
            </Box>
          </Grid>
          <Grid item md={5} xs={12}>
            <Box p={1}>
              <ListOfDocs2 />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default PendingStatus;
