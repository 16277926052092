import React, { useState } from 'react';
import { Prompt, useHistory } from 'react-router-dom';
import get from 'lodash/get';
import Spacer from 'components/UI/Layout/Spacer';
import { Icon, IconProps } from 'components/Icon';
import Svg from 'components/UI/Svg/Svg';
import WebMuiModal from 'components/UI/Modals/WebMuiModal';
import { Typography } from 'components/UI/Typography/styled';
import { Spacing, IconSize, FontWeight, FontSize } from 'modules/styles/variables.web';
import { toSentenceCase } from 'modules/utils/StringUtils';
import { MuiButton, MuiBox } from 'theme/material-ui';
import useStateWithCallback from 'hooks/useStateWithCallback';
import { Color } from 'modules/styles/colors';
import { useDispatch } from 'react-redux';
import { resetAmwellActivity } from 'store/amwell/actions';

interface NavLocation {
  pathname: string;
  state: object;
}

interface Props {
  navigate: (targetLocation: string, targetOptions: object) => void;
  exitTitle?: string;
  exitSubtitle?: string;
  exitButtonText?: string;
  keepGoingButtonText?: string;
  exitAndShareButtonText?: string;
  when?: boolean;
  closeIcon?: boolean;
  hideExitButton?: boolean;
  iconType?: 'info' | 'warning' | 'error';
  shouldBlockNavigation: (nextLocation: NavLocation) => boolean;
  exitAndShareHandler?: (targetLocation?: string, targetOptions?: object) => void;
}

const RouteLeavingGuardModal = ({
  when,
  shouldBlockNavigation,
  navigate,
  exitTitle,
  exitSubtitle,
  exitButtonText,
  keepGoingButtonText,
  exitAndShareButtonText,
  closeIcon,
  iconType,
  exitAndShareHandler,
  hideExitButton
}: Props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [modalVisible, setModalVisible] = useState(false);
  const [targetLocation, setTargetLocation] = useState<NavLocation | null>(null);

  const [confirmedNavigation, setConfirmedNavigation] = useStateWithCallback(false, confirmed => {
    if (confirmed) {
      if (targetLocation) {
        const { pathname, state } = targetLocation;
        navigate(pathname, state);
        dispatch(resetAmwellActivity());
      }
    }
  });

  const showModal = (location: NavLocation) => {
    setModalVisible(true);
    setTargetLocation(location);
  };

  // nextLocation comes from ReactRouter <Prompt/>
  const handleBlockedNavigation = (nextLocation: NavLocation) => {
    const currentRoute = get(history, 'location.pathname', '');

    // don't allow navigation if
    if (
      !confirmedNavigation &&
      shouldBlockNavigation(nextLocation) &&
      nextLocation.pathname !== currentRoute
    ) {
      showModal(nextLocation);
      return false;
    }
    // else allow navigation
    return true;
  };

  const handleConfirmNavigationClick = () => {
    setModalVisible(false);
    if (targetLocation) {
      setConfirmedNavigation(true);
    }
  };

  const handleStay = () => {
    setModalVisible(false);
  };

  const iconProps: IconProps =
    iconType === 'error' || iconType === 'warning'
      ? { color: Color[iconType], name: 'ErrorOutline' }
      : { color: Color.primary, name: 'InfoIcon', set: 'assets' };

  const sentenceCaseWhiteList = ['E-Visit', 'Connect Care'];

  const shouldSentenceCase = (text: string | undefined) =>
    !!text && typeof text === 'string' && !sentenceCaseWhiteList.some(v => text?.includes(v));

  const exitAndShareFeedback = () => {
    setConfirmedNavigation(true);
    setModalVisible(false);

    if (targetLocation) {
      const { pathname, state } = targetLocation;
      exitAndShareHandler?.(pathname, state);
    } else {
      exitAndShareHandler?.();
    }
  };

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      <WebMuiModal open={modalVisible} handleClose={handleStay}>
        {closeIcon ? (
          <MuiBox position="absolute" padding={1} top={0} right={0}>
            <Icon name="close" onClick={handleStay} />
          </MuiBox>
        ) : null}
        <MuiBox p={Spacing.small}>
          {iconType === 'error' || iconType === 'warning' ? (
            <MuiBox textAlign="center" top={`-${Spacing.large}px`}>
              <Icon size={IconSize.xLarge} {...iconProps} />
            </MuiBox>
          ) : iconType ? (
            <MuiBox textAlign="center" top={`-${Spacing.large}px`}>
              <Svg size={IconSize.xLarge} {...iconProps} />
            </MuiBox>
          ) : null}
          <Typography textAlign="center" fontSize={FontSize.large} fontWeight={FontWeight.semibold}>
            {exitTitle}
          </Typography>
          <Spacer size="small" />
          {exitSubtitle ? <Typography textAlign="center">{exitSubtitle}</Typography> : null}
          {hideExitButton ? null : (
            <>
              <Spacer size="medium" />
              <MuiButton
                variant="contained"
                color="primary"
                data-testid="handle-exit"
                onClick={handleConfirmNavigationClick}
                fullWidth
              >
                {shouldSentenceCase(exitButtonText)
                  ? toSentenceCase(exitButtonText)
                  : exitButtonText || 'Confirm'}
              </MuiButton>
            </>
          )}
          <Spacer size="small" />
          {exitAndShareButtonText ? (
            <MuiButton
              variant="outlined"
              color="primary"
              data-testid="handle-exit-and-share-feedback"
              onClick={exitAndShareFeedback}
              fullWidth
            >
              {shouldSentenceCase(exitAndShareButtonText)
                ? toSentenceCase(exitAndShareButtonText)
                : exitAndShareButtonText || 'Exit and share feedback'}
            </MuiButton>
          ) : null}
          <Spacer size="small" />
          <MuiButton
            variant="text"
            color="primary"
            data-testid="handle-stay"
            onClick={handleStay}
            fullWidth
          >
            {shouldSentenceCase(keepGoingButtonText)
              ? toSentenceCase(keepGoingButtonText)
              : keepGoingButtonText || 'Stay on page'}
          </MuiButton>
        </MuiBox>
      </WebMuiModal>
    </>
  );
};

RouteLeavingGuardModal.defaultProps = {
  exitTitle: 'Are you sure you want to leave this page?',
  when: true,
  shouldBlockNavigation: () => true
};

export default RouteLeavingGuardModal;
