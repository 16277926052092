import React from 'react';
import { InfoCard } from 'components/InfoCard';
import Typography from 'components/UI/Typography';
import DashboardBillingHeader from './DashboardBillingHeader';
import { StyledBox } from './styled';

const DashboardBillingError = () => {
  return (
    <InfoCard
      testID="dashboard-billing-error-state"
      customHeader={() => <DashboardBillingHeader />}
    >
      <StyledBox>
        <Typography>
          We're having trouble loading your statements. Please check back in a bit!
        </Typography>
      </StyledBox>
    </InfoCard>
  );
};

export default DashboardBillingError;
