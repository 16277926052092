import React from 'react';
import { MuiFormLabel, MuiFormControl, MuiFormHelperText, MuiFormGroup } from 'theme/material-ui';
import useStyles from '../useStyles';
import { ConnectCareCheckboxItem, CustomFormControlLabelProps } from '../ConnectCareCheckboxItem';

export interface ConnectCareCheckboxGroupProps<T> extends CustomFormControlLabelProps {
  items: T[];
  itemToString: (item: T) => string;
  checkedKey: keyof T;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>, item?: T) => void;
  label?: React.ReactNode;
  labelPlacement?: 'start' | 'top' | 'end' | 'bottom';
  disabled?: boolean;
  error?: boolean;
  helperText?: React.ReactNode;
  name?: string;
  row?: boolean;
  fullWidth?: boolean;
}

function ConnectCareRadioGroup<T>(props: ConnectCareCheckboxGroupProps<T>) {
  const {
    items,
    itemToString,
    checkedKey,
    label,
    onChange,
    error,
    helperText,
    row,
    disabled,
    name,
    labelPlacement,
    justifyContent,
    borderBottom,
    paddingX,
    fullWidth,
    ...rest
  } = props;
  const classes = useStyles();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      const selection = items.find(item => itemToString(item) === e.target.value);
      if (selection) {
        (selection[checkedKey] as any) = e.target.checked;
      }

      onChange(e, selection);
    }
  };

  return (
    <MuiFormControl component="fieldset" disabled={disabled} fullWidth={fullWidth}>
      {!!label && (
        <MuiFormLabel component="legend" error={error} className={classes.formLabel}>
          {label}
        </MuiFormLabel>
      )}
      <MuiFormGroup row={row}>
        {items.map((item, idx) => {
          const itemValue = itemToString(item);
          const checked = item[checkedKey];

          return (
            <ConnectCareCheckboxItem
              {...rest}
              name={name}
              id={`${idx}`}
              key={itemValue}
              label={itemValue}
              labelPlacement={labelPlacement}
              value={itemValue}
              checked={!!checked}
              disabled={disabled}
              onChange={handleChange}
              justifyContent={justifyContent}
              borderBottom={borderBottom}
              paddingX={paddingX}
            />
          );
        })}
      </MuiFormGroup>
      <MuiFormHelperText className={classes.formHelperText}>{helperText}</MuiFormHelperText>
    </MuiFormControl>
  );
}

ConnectCareRadioGroup.defaultProps = {
  itemToString: (item: any) => (item ? item.label : ''),
  checkedKey: 'checked',
  direction: 'row'
};

export default ConnectCareRadioGroup;
