import React from 'react';

import { Color } from 'modules/styles/colors';
import { BorderRadius, FontSize, FontWeight, IconSize } from 'modules/styles/variables';
import { paymentMethod, providerDetails as amwellProviderDetails } from 'modules/constants/amwell';
import { providerColorMap as colorMap } from 'modules/constants/ConnectCare';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import {
  MuiAvatar,
  MuiButton,
  MuiBox,
  MuiDivider,
  MuiGrid,
  MuiTypography
} from 'theme/material-ui';
import { ConnectCareError, ConnectCareDataLoader } from 'components/ConnectCare';
import Divider from 'components/UI/Divider';
import ProviderAvatar from 'components/UI/Avatar/ProviderAvatar';
import { Svg } from 'components/UI/Svg';

import { EstimatedVisitCost, ProviderDetails } from 'store/amwell/types';

import { ProviderRating } from './styled';

export interface Props {
  getEstimatedVisitCost: any;
  cost: number;
  estimatedVisitCost: EstimatedVisitCost | null;
  isLoading: boolean;
  estimatedVisitCostError: Error | null;
  providerDetailsError: Error | null;
  providerDetails: ProviderDetails | null;
  visibilityAttr: any;
  isWaitingRoom: boolean;
}

export function ConnectCareProviderDetails(props: Props) {
  const {
    getEstimatedVisitCost,
    cost,
    isLoading,
    estimatedVisitCostError,
    estimatedVisitCost,
    providerDetailsError,
    providerDetails,
    visibilityAttr,
    isWaitingRoom
  } = props;

  const errorComponentProps = {
    message: amwellProviderDetails.PROVIDER_DETAILS_ERROR.title
  };
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <MuiBox pb={2}>
      {isWaitingRoom ? (
        <MuiBox py={3}>
          <MuiBox pb={1}>
            <MuiTypography variant="h5">Provider Details</MuiTypography>
          </MuiBox>
          <MuiBox>
            <MuiDivider />
          </MuiBox>
        </MuiBox>
      ) : null}
      <ConnectCareDataLoader
        data={providerDetails}
        loading={isLoading}
        error={providerDetailsError}
        errorComponentProps={errorComponentProps}
        renderNoData={() => (
          <ConnectCareError message={amwellProviderDetails.PROVIDER_DETAILS_NO_DATA.title} />
        )}
        renderData={data => (
          <>
            <MuiGrid container spacing={2} alignItems="center" justify="center">
              <MuiGrid item>
                {data?.logoUrl ? (
                  <MuiAvatar size={IconSize.xLarge * 4} alt={data?.fullName} src={data?.logoUrl} />
                ) : (
                  <ProviderAvatar
                    avatarUrl="Avatar"
                    color={Color.grayDark}
                    size={IconSize.xLarge * 4}
                    backgroundColor={Color.foreColor}
                  />
                )}
                {!isWaitingRoom ? (
                  <MuiBox
                    width={60}
                    height={60}
                    bgcolor={colorMap[visibilityAttr.color]}
                    borderRadius={BorderRadius.full}
                    style={{ position: 'absolute', top: 0, right: 0 }}
                  />
                ) : null}
              </MuiGrid>
              <MuiGrid
                item
                container
                sm
                xs={12}
                style={{ textAlign: !isLargeScreen ? 'center' : 'left' }}
              >
                <MuiGrid container item direction="column">
                  {!isWaitingRoom ? (
                    <MuiGrid
                      container
                      alignItems="center"
                      justify={isLargeScreen ? 'flex-start' : 'center'}
                    >
                      <MuiGrid item container xs={3} alignItems="center">
                        {!isLargeScreen ? (
                          <MuiBox
                            width={12}
                            height={12}
                            bgcolor={colorMap[visibilityAttr.color]}
                            borderRadius={BorderRadius.full}
                            mr={1}
                          />
                        ) : null}
                        <MuiBox>
                          <MuiTypography fontSize={FontSize.base}>
                            {visibilityAttr.label}
                          </MuiTypography>
                        </MuiBox>
                      </MuiGrid>
                      <MuiGrid item>
                        <MuiTypography fontSize={FontSize.base}>
                          {data?.waitingRoomCount} Waiting
                        </MuiTypography>
                      </MuiGrid>
                    </MuiGrid>
                  ) : null}
                  <MuiGrid item>
                    <MuiTypography
                      variant="h6"
                      fontSize={FontSize.mediumHeading}
                      fontWeight={FontWeight.bold}
                    >
                      {data?.fullName}
                    </MuiTypography>
                    <MuiTypography fontSize={FontSize.base} gutterBottom variant="body1">
                      {data?.specialty?.value}
                    </MuiTypography>
                    <ProviderRating
                      readOnly
                      name="read-only"
                      value={data?.rating}
                      isWaitingRoom={isWaitingRoom}
                    />
                  </MuiGrid>
                  <MuiBox py={1} display="flex" justifyContent="center">
                    <Divider style={{ width: isLargeScreen ? '100%' : '70%' }} />
                  </MuiBox>
                  {data?.spokenLanguages?.length ? (
                    <MuiGrid item>
                      <MuiBox
                        py={1}
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        justifyContent={isLargeScreen ? 'flex-start' : 'center'}
                      >
                        <Svg
                          color={!isWaitingRoom ? Color.foreColor : Color.black}
                          size={IconSize.medium}
                          set="assets"
                          name="Language"
                        />
                        <MuiBox ml={2}>
                          <MuiTypography
                            fontSize={FontSize.large}
                            variant="span"
                            fontWeight={FontWeight.bold}
                          >
                            Languages:{' '}
                          </MuiTypography>
                          <MuiTypography fontSize={FontSize.large} variant="span">
                            {data.spokenLanguages?.map(language => language.value).join(', ')}
                          </MuiTypography>
                        </MuiBox>
                      </MuiBox>
                    </MuiGrid>
                  ) : null}
                </MuiGrid>
              </MuiGrid>
              <MuiGrid item xs>
                <MuiBox p={4} bgcolor={Color.baseColor} borderRadius={4}>
                  <MuiGrid container direction="column" alignItems="center">
                    <MuiGrid item>
                      {!isWaitingRoom ? (
                        estimatedVisitCostError ? (
                          <ConnectCareError
                            message={amwellProviderDetails.ESTIMATED_COST_ERROR.title}
                            action={{
                              onClick: () => getEstimatedVisitCost({ providerDetails })
                            }}
                          />
                        ) : (
                          <MuiTypography color={Color.black} variant="h3">
                            {estimatedVisitCost ? estimatedVisitCost.formattedCost : 'N/A'}
                          </MuiTypography>
                        )
                      ) : (
                        <MuiTypography variant="h3">${cost.toFixed(2)}</MuiTypography>
                      )}
                    </MuiGrid>
                    <MuiGrid item>
                      <MuiButton
                        data-testid="info-button-payment-info"
                        aria-label="More info on payment cost"
                        color="inherit"
                        href={paymentMethod.PAYMENT_COST_MORE_INFO_URL}
                        target="_blank"
                      >
                        <MuiTypography
                          color={Color.primary}
                          fontWeight={FontWeight.normal}
                          fontSize={FontSize.base}
                          variant="h6"
                          style={{ marginRight: 20 }}
                        >
                          Visit Cost
                        </MuiTypography>
                        <Svg
                          set="assets"
                          name="InfoIconFilled"
                          size={IconSize.medium}
                          color={Color.primary}
                        />
                      </MuiButton>
                    </MuiGrid>
                    <MuiGrid item style={{ marginBottom: 15 }}>
                      <MuiTypography color={Color.primary} fontSize={FontSize.base} variant="body1">
                        (This may be covered by insurance)
                      </MuiTypography>
                    </MuiGrid>
                  </MuiGrid>
                </MuiBox>
              </MuiGrid>
            </MuiGrid>
          </>
        )}
      />
    </MuiBox>
  );
}

ConnectCareProviderDetails.defaultProps = {
  isWaitingRoom: true
};

export default ConnectCareProviderDetails;
