import React from 'react';
import { MuiTypography, MuiBox } from 'theme/material-ui';
import StyledButton from 'components/UI/Button/StyledButton';
import { Pharmacy } from 'store/amwell/types';
import ConnectCarePharmacyItem from './ConnectCarePharmacyItem';

export interface ConnectCarePharmacyPreferredProps {
  preferred: Pharmacy;
  change: () => void;
}
const ConnectCarePharmacyPreferred = (props: ConnectCarePharmacyPreferredProps) => {
  const { preferred, change, ...rest } = props;
  return (
    <MuiBox py={2} {...rest}>
      <MuiTypography variant="h5">Your Preferred Pharmacy</MuiTypography>
      <ConnectCarePharmacyItem data={preferred} isSelected />
      <StyledButton onPress={change}>Change My Pharmacy</StyledButton>
    </MuiBox>
  );
};
export default ConnectCarePharmacyPreferred;
