import React from 'react';
import {
  MuiExpansionPanel,
  MuiExpansionPanelSummary,
  MuiExpansionPanelDetails,
  MuiExpansionPanelActions,
  MuiBox,
  MuiTypography,
  MuiIcon,
  MuiButton
} from 'theme/material-ui';
import { Color } from 'modules/styles/colors';
import { toSentenceCase } from 'modules/utils/StringUtils';

export interface AccordionAction {
  title: string;
  onClick: () => void;
  disabled?: boolean;
}

export interface ConnectCareAccordionProps {
  title: React.ReactNode;
  value?: React.ReactNode;
  children: React.ReactNode;
  defaultExpanded?: boolean;
  expanded?: boolean;
  disabled?: boolean;
  onChange?: (event: React.ChangeEvent<{}>, expanded: boolean) => void;
  actions?: AccordionAction[];
}

export function ConnectCareAccordion({
  title,
  value,
  actions,
  children,
  ...rest
}: ConnectCareAccordionProps) {
  return (
    <MuiExpansionPanel {...rest}>
      <MuiExpansionPanelSummary expandIcon={<MuiIcon>expand_more</MuiIcon>}>
        <MuiBox flex={value ? '0 0 33.33%' : '0 1 auto'}>
          {typeof title === 'string' ? (
            <MuiTypography fontWeight={600} color={Color.textLight}>
              {title}
            </MuiTypography>
          ) : (
            title
          )}
        </MuiBox>
        {value ? (
          typeof value === 'string' ? (
            <MuiTypography color={Color.textLight}>{value}</MuiTypography>
          ) : (
            value
          )
        ) : null}
      </MuiExpansionPanelSummary>
      <MuiExpansionPanelDetails>{children}</MuiExpansionPanelDetails>
      {actions ? (
        <MuiExpansionPanelActions>
          {actions.map(action => (
            <MuiButton
              key={action.title}
              color="primary"
              onClick={action.onClick}
              disabled={action.disabled}
            >
              {toSentenceCase(action.title)}
            </MuiButton>
          ))}
        </MuiExpansionPanelActions>
      ) : null}
    </MuiExpansionPanel>
  );
}

export default ConnectCareAccordion;
