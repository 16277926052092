import * as Yup from 'yup';

export const submitSurveyValidationSchema = Yup.object().shape({
  otherIdea: Yup.string().trim(),
  healthTopicsChecked: Yup.array()
    .required('Please select up to 5 topics')
    .min(1, 'Please select up to 5 topics')
    .max(5, 'Please select up to 5 topics'),
  radioAnswer: Yup.string().required('Please select an answer'),
  contactName: Yup.string()
    .notRequired()
    .when('radioAnswer', {
      is: (val: string) => val === 'Yes',
      then: Yup.string().required('Please enter your first and last contactName')
    }),
  contactEmail: Yup.string()
    .notRequired()
    .when('radioAnswer', {
      is: (val: string) => val === 'Yes',
      then: Yup.string()
        .required('Please enter your email')
        .email()
    }),
  contactPhone: Yup.string()
    .notRequired()
    .when('radioAnswer', {
      is: (val: string) => val === 'Yes',
      then: Yup.string()
        .required('Please enter your phone number')
        .matches(/^\(\d{3}\) \d{3}-\d{4}$/, 'Phone must match format (###) ###-####')
    })
});
