import React from 'react';
import Spacer from 'components/UI/Layout/Spacer';
import { FontWeight } from 'modules/styles/variables';
import { MuiBox, MuiDivider, MuiTypography } from 'theme/material-ui';
import { useLanguageSwitcher } from 'lib/hooks/useLanguageSwitcher';
import { translations } from 'lib/constants/translations/screens/covidGuest/agreement';
import { SelectDropdown } from 'components/UI/SelectDropdown';
import * as consumerActions from 'store/consumerPreferences/actions';
import { connect } from 'react-redux';
import { languageSelector } from 'store/consumerPreferences/selectors';
import { RootState } from 'store/types';
import Config from 'react-native-config';
import { languages } from 'lib/constants/translations/components/LanguageSwitcher';
import useNavigationAnalytics from 'hooks/useNavigationAnalytics';
import { AnalyticsEvent } from 'services/AnalyticsService.common';
import { CovidTitles } from 'lib/constants/covidScreening';

const isLocalizationEnabled = Config.GUEST_COVID_LOCALIZATION === 'enabled';

interface IntroductionTextProps {
  language: string;
  setLanguage: (e: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>) => void;
}

function IntroductionText({ language, setLanguage }: IntroductionTextProps) {
  const screenText = useLanguageSwitcher(translations);
  const changeLanguageAnalytics = useNavigationAnalytics(AnalyticsEvent.CovidLanguageSelected);

  const onChangeLanguage = (
    language: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setLanguage(language);
    changeLanguageAnalytics({
      authentication_type: 'unauthenticated',
      language: language.target.value === 'en' ? 'English' : 'Spanish',
      title: CovidTitles.CONSENT_AND_AGREEMENTS
    });
  };

  return (
    <>
      <MuiBox mb={2}>
        <MuiTypography variant="h4">{screenText?.title}</MuiTypography>
      </MuiBox>

      <MuiBox mb={1}>
        <MuiDivider />
      </MuiBox>

      {isLocalizationEnabled && (
        <MuiBox mt={2}>
          <MuiTypography fontSize={14}>Language/Idioma :</MuiTypography>
          <MuiBox maxWidth="30%" mt={1}>
            <SelectDropdown
              fullWidth
              items={languages}
              value={language}
              itemToLabel={item => item?.label || ''}
              onChange={onChangeLanguage}
            />
          </MuiBox>
        </MuiBox>
      )}

      <Spacer />

      <MuiTypography display="inline">
        {screenText?.desclaimerPart1}
        <MuiTypography fontWeight={FontWeight.bold} display="inline">
          {screenText?.desclaimerPart2}
        </MuiTypography>
      </MuiTypography>

      <Spacer />

      <MuiTypography fontWeight={FontWeight.normal}>{screenText?.desc}</MuiTypography>

      <Spacer />

      <MuiTypography fontWeight={FontWeight.normal}>{screenText?.info}</MuiTypography>

      <Spacer />
    </>
  );
}

const mapStateToProps = (state: RootState) => ({
  language: languageSelector(state)
});

const mapDispatchToProps = (dispatch: Function) => ({
  setLanguage: (e: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>) =>
    dispatch(consumerActions.setLanguage(e.target.value))
});

export default connect(mapStateToProps, mapDispatchToProps)(IntroductionText);
