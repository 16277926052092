import React from 'react';
import { BaseTextFieldProps, MuiTextField, MuiGrid } from 'theme/material-ui';
import { Typography } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { Color } from 'modules/styles/colors';

const StyledTextField = styled(MuiTextField)({
  background: Color.white
});

interface Props extends BaseTextFieldProps {
  value: string;
  maxLength: number;
  onTextChange?: (text: string) => void;
}

const TextInputCounter = (props: Props) => {
  const { value, maxLength, onTextChange = () => {}, ...rest } = props;
  const shortValue = value.slice(0, maxLength);
  const counterText = `${shortValue.length || 0}/${maxLength}`;
  return (
    <MuiGrid container>
      <MuiGrid xs={12}>
        <StyledTextField
          onChange={(event: any) => onTextChange(event.target.value)}
          value={shortValue}
          {...rest}
        />
      </MuiGrid>
      <MuiGrid xs={12}>
        <Typography variant="body2" align="right" color="textSecondary">
          {counterText}
        </Typography>
      </MuiGrid>
    </MuiGrid>
  );
};

export default TextInputCounter;
