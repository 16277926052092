import React from 'react';
import { Spacing } from 'modules/styles/variables';
import Box from './Box';

const sizes = {
  xxsmall: Spacing.small,
  xsmall: Spacing.medium,
  small: Spacing.large,
  medium: Spacing.xLarge,
  large: Spacing.xxLarge,
  xlarge: Spacing.xxxLarge
};

interface SizeProps {
  size: keyof typeof sizes;
}

interface SpacingProps {
  spacing: keyof typeof Spacing;
}

// Would like to use the standard spacings we have defined in variables, so that's why its extended like this.
type Props = SizeProps | SpacingProps;

const Spacer = (props: Props) => {
  if ('spacing' in props) {
    return <Box height={Spacing[props.spacing]} />;
  }

  return <Box height={sizes[props.size]} />;
};

Spacer.defaultProps = { size: 'medium' };

export default Spacer;
