import styled from 'styled-components';

import { View } from 'react-native';
import { Color } from 'modules/styles/colors';
import DefaultLabel from 'components/UI/Labels/DefaultLabel';
import Headline from 'components/UI/Typography/Headline';

export const FormBody = styled.div`
  padding: 20px;
  width: 100%;
`;

export const FormSection = styled.div`
  padding: 0px;
  width: 100%;
`;

export const FormGroup = styled(View)<{ multipleLines?: boolean }>`
  flex-direction: ${props => (props.multipleLines ? 'column' : 'row')};
  align-items: ${props => (props.multipleLines ? 'flex-start' : 'center')};
  padding: 15px 0px;
  border-top-color: transparent;
  border-bottom-color: ${Color.grayLight};
  border-bottom-width: 1px;
  width: 100%;
`;

export const FormTitle = styled(Headline)``;

export const FormLabel = styled(DefaultLabel)`
  flex: 4;
`;

export const FormField = styled.div`
  flex: 1 1 auto;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

export const FormRow = styled.div`
  justify-content: space-around;
  flex-direction: row;
  width: 100%;
  margin-top: 5px;
`;
