import React from 'react';
import { MuiBox, MuiTypography } from 'theme/material-ui';
import { FontWeight } from 'modules/styles/variables.web';

function VisitSummaryMessage(props: { message: string; isSmallScreen: boolean }) {
  return (
    <>
      <MuiBox display="flex" justifyContent="center">
        <MuiTypography align="center" fontWeight={FontWeight.semibold}>
          {props.message}
        </MuiTypography>
      </MuiBox>
    </>
  );
}

export default VisitSummaryMessage;
