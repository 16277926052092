import React from 'react';
import { Color } from 'modules/styles/colors';
import { Spacing, FontSize } from 'modules/styles/variables';
import { MuiBox, MuiTypography, MuiButton } from 'theme/material-ui';
import { currentConsumerIntermountainPcpSelector } from 'store/myDoctors/selectors';
import { useSelector } from 'react-redux';
import { useWindowSize } from 'hooks/useWindowSize';
import useVisibleSlidesCount from 'hooks/useVisibleSlidesCount';
import { CardContainerBlank } from 'components/DashboardCarousel/DashboardBannerCard';

interface Props {
  healthAdvisoryAction: {
    label: string;
    onPress: () => void;
    onSubmit: () => void;
    submitText?: string;
  };
  onSnooze: () => void;
  onDone: () => void;
  totalCards: number;
}

export const DashboardCarouselHealthAdvisoryBack = (props: Props) => {
  const { healthAdvisoryAction, onDone, totalCards } = props;
  const { label, onPress, onSubmit, submitText } = healthAdvisoryAction;
  const options = [
    { id: '1', label, onPress },
    { id: '3', label: 'Snooze', onPress: props.onSnooze }
  ];
  const pcp = useSelector(currentConsumerIntermountainPcpSelector);
  if (pcp && pcp.messagingPools && pcp.messagingPools.length) {
    options.push({ id: '2', label: submitText || 'Submit results', onPress: onSubmit });
  }

  const visibleSlides = useVisibleSlidesCount(totalCards);
  const windowDim = useWindowSize();
  const calcCardProps = {
    totalCards,
    visibleSlides,
    windowWidth: windowDim.width
  };

  return (
    <CardContainerBlank
      {...calcCardProps}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      flex={1}
      bgcolor="white"
    >
      <MuiBox p={3}>
        <MuiBox mb={Spacing.small}>
          <MuiTypography color={Color.grayHue3} fontWeight={600}>
            Your options:
          </MuiTypography>
        </MuiBox>
        {options.map(o => (
          <MuiBox key={o.id} onClick={o.onPress} style={{ cursor: 'pointer' }}>
            <MuiTypography fontSize={FontSize.large} color={Color.link}>
              {o.label}
            </MuiTypography>
          </MuiBox>
        ))}
      </MuiBox>
      <MuiBox width="100%" p={2} display="flex" justifyContent="center" alignItems="center">
        <MuiButton variant="outlined" color="primary" onClick={onDone}>
          Done
        </MuiButton>
      </MuiBox>
    </CardContainerBlank>
  );
};
