import React, { useEffect } from 'react';

import FlexBox from 'components/UI/Layout/FlexBox';
import UserAvatar from 'components/UI/Avatar';
import { FontSize, Spacing } from 'modules/styles/variables';
import { getUserName } from 'lib/user';
import { Consumer, Grantor } from 'store/profile/types';
import { CursorMuiBox } from 'theme/material-ui';
import analyticsService, { AnalyticsEvent } from 'services/AnalyticsService';

import AvatarScreenTitle from './components/AvatarScreenTitle';
import { ScrollFlexbox } from './styled';

interface AvatarSelectionProps {
  avatarsAvailable: string[];
  userSelected: Consumer | Grantor;
  onAvatarSelect: (avatar: string) => void;
}

const AvatarSelection = ({
  avatarsAvailable,
  onAvatarSelect,
  userSelected
}: AvatarSelectionProps) => {
  useEffect(() => {
    analyticsService.logEvent(AnalyticsEvent.AvatarSelectionStart);
  }, []);

  return (
    <ScrollFlexbox>
      <AvatarScreenTitle title="Choose an avatar" />
      <FlexBox maxWidth={900} flexDirection="row" flexWrap="wrap" justifyContent="center">
        {avatarsAvailable.map(avatar => (
          <CursorMuiBox justifyContent="space-between" py={Spacing.small} px={0.5} width={120}>
            <UserAvatar
              size={90}
              avatarUrl={avatar === 'Initials' ? '' : avatar}
              fallback={getUserName(userSelected)}
              initialsFontSize={FontSize.title}
              onAvatarClick={() => onAvatarSelect(avatar)}
            />
          </CursorMuiBox>
        ))}
      </FlexBox>
    </ScrollFlexbox>
  );
};

export default AvatarSelection;
