import styled, { css } from 'styled-components';
import { List } from '@material-ui/core';
import { dfdDefaultTheme, MuiDrawer, MuiBox } from 'theme/material-ui';

import { Color } from 'modules/styles/colors';
import { resolveCSSUnit } from 'modules/utils/StylesUtils';

export const DRAWER_WIDTH = 320;
export const PANEL_HEIGHT = 64;

interface DrawerProps {
  width?: string | number;
}

const width = (props: DrawerProps) =>
  props.width &&
  css`
    width: ${resolveCSSUnit(props.width)};
  `;

export const Drawer = styled(MuiDrawer)<DrawerProps>`
  &.MuiDrawer-root {
    flex-shrink: 0;
  }

  & .MuiBackdrop-root {
    background-color: transparent;
  }

  & .MuiDrawer-paper {
    width: ${DRAWER_WIDTH}px;
    overflow: scroll;
    padding-bottom: ${dfdDefaultTheme.spacing(1)}px;
    ${width};
  }

  & .MuiExpansionPanel-root.Mui-expanded {
    margin: auto;
  }
`;

export const DrawerHeader = styled(MuiBox)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: ${Color.baseDark};
  min-height: ${PANEL_HEIGHT}px;
  padding: 0 ${dfdDefaultTheme.spacing(2)}px;
`;

export const StyledList = styled(List)`
  flex: 1;
`;
