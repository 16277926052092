import Spacer from 'components/UI/Layout/Spacer';
import { JsonObj } from '@mhp/general-interface';
import Typography from 'components/UI/Typography';
import { DUE_STATUS_TYPE } from 'modules/constants/Billing';
import { Color } from 'modules/styles/colors';
import { FontSize, FontWeight } from 'modules/styles/variables';
import React, { ComponentType, useEffect } from 'react';
import { connect } from 'react-redux';
import { NavigationScreenProps } from 'screens/navigation';
import analyticsService, { AmplitudeEventData, AnalyticsEvent } from 'services/AnalyticsService';
import formatMoney from 'services/formatMoney';
import * as snacksActions from 'store/snacks/actions';
import { Snack, SnackType } from 'store/snacks/types';
import {
  BILLING_SUMMARY,
  DEDUCTIBLE_ACTIVITY,
  OOP_DEDUCTIBLE_ACTIVITY,
  VISITPAY_PAYMENT_PLAN,
  VISIT_PAY_HOME
} from '../constants';
import {
  DueNowHeading,
  StyledHeading,
  PaymentPlanHeading,
  StyledText,
  BorderMuiBox
} from '../styled';
import { MuiBox, MuiButton } from 'theme/material-ui';
import { getPlanBenefits } from 'store/cost/actions';
import DeductibleBar from 'components/billing/DeductibleBar/DeductibleBar';
import FlexBox from 'components/UI/Layout/FlexBox';
import { Carousel } from 'components/Carousel';
import Spinner from 'components/UI/Spinner/Spinner';
import { formatDate } from 'modules/utils/DateUtils';
import VisitPayWebView from 'screens/Billing/VisitPayWebView';
import { useBillingHome } from 'lib/hooks/useBilling';
import useCoreEventData from 'hooks/useCoreEventData';

interface Props extends NavigationScreenProps {
  createSnack: typeof snacksActions.createSnack;
  fetchBenefits: typeof getPlanBenefits;
}

export const BillingSummary = ({ createSnack, history, fetchBenefits }: Props) => {
  const [goToVisitPay, setGoToVisitPay] = React.useState(false);
  const {
    familyBenefits,
    hsaError,
    isActiveSHInsurance,
    isFetching,
    isImpersonating,
    myBenefits,
    paymentPlansData,
    shInsurance,
    paymentsSummary,
    guarantorData,
    guarantorError,
    guarantorLoading
  } = useBillingHome();
  const { currentUrl, referringUrl } = useCoreEventData();

  const eventData: AmplitudeEventData = {
    currentUrl,
    referringUrl
  };

  const hasError = guarantorError || hsaError;

  const onError = () => {
    if (hasError && currentUrl === 'Billing') {
      createSnack(SnackType.BASE, {
        label: 'Unable to load data, please try again'
      });
    }
  };

  const [swiperOptions, setSwiperOptions] = React.useState([BILLING_SUMMARY]);

  useEffect(() => {
    if (isActiveSHInsurance) {
      fetchBenefits();
    }
  }, [isActiveSHInsurance]);

  useEffect(() => {
    if (myBenefits) {
      const customOptions = [];
      if (myBenefits.deductible && !swiperOptions.includes(DEDUCTIBLE_ACTIVITY)) {
        customOptions.push(DEDUCTIBLE_ACTIVITY);
      }
      if (myBenefits.max_oop && !swiperOptions.includes(OOP_DEDUCTIBLE_ACTIVITY)) {
        customOptions.push(OOP_DEDUCTIBLE_ACTIVITY);
      }
      setSwiperOptions([...swiperOptions, ...customOptions]);
    }
  }, [myBenefits]);

  useEffect(onError, [currentUrl, hasError]);

  const encounters = guarantorData?.encounters || [];
  const paidEncounters = paymentsSummary?.paidEncounters || [];
  const totalEncounters = guarantorData?.totalEncounters || 0;
  const amountDue = guarantorData?.amountDue || 0;
  const dueStatus = guarantorData?.dueStatus || 'UNKNOWN';
  const paymentPlans = paymentPlansData?.paymentPlans || [];
  const goToUnpaidBillSummary = () => {
    analyticsService.logEvent(AnalyticsEvent.ViewAndPayClicked, eventData);
    history.push('/u/billing/bills');
  };

  const goToInsurace = () => {
    history.push('/u/manage-account/insurance');
  };

  useEffect(() => {
    if (paymentPlans.length) {
      const customOptions: string[] = [];

      paymentPlans.forEach((eachPaymentPlan: JsonObj, index: number) => {
        if (!swiperOptions.some(s => s.indexOf('visitpay_payment_plan') !== -1)) {
          customOptions.push(`${VISITPAY_PAYMENT_PLAN}#${index}`);
        }
      });

      /* payment plan cards should display first if non-payment plan amount due (amountDue) is 0 */
      if (!guarantorLoading) {
        if (amountDue && amountDue > 0) {
          setSwiperOptions([
            ...swiperOptions.slice(0, 1),
            ...customOptions,
            ...swiperOptions.slice(1)
          ]);
        } else {
          setSwiperOptions(customOptions.concat(swiperOptions));
        }
      }
    }
  }, [paymentPlans, guarantorLoading]);

  const goToVisitPayHome = () => {
    analyticsService.logEvent(AnalyticsEvent.ProceedWithPaymentClicked, eventData);
    setGoToVisitPay(true);
  };

  function renderBillingSummary() {
    return (
      <BorderMuiBox
        display="flex"
        minHeight="200px"
        aria-label="Billing home summary"
        title="Billing home summary"
        data-testid="billing-home-summary"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        {dueStatus.length ? (
          <DueNowHeading
            data-testid="due-status"
            color={Color.white}
            center
            adjustsFontSizeToFit
            aria-label={`Due status is ${DUE_STATUS_TYPE[dueStatus]}`}
            title={`Due status is ${DUE_STATUS_TYPE[dueStatus]}`}
          >
            {DUE_STATUS_TYPE[dueStatus]}
          </DueNowHeading>
        ) : null}
        <StyledHeading
          center
          color={Color.white}
          numberOfLines={1}
          adjustsFontSizeToFit
          allowFontScaling={false}
          data-testid="unpaid-bills-total-amount-due"
          aria-label={`Total unpaid bills amount is $${formatMoney(amountDue)}`}
          title={`Total unpaid bills amount is $${formatMoney(amountDue)}`}
        >
          {`$${formatMoney(amountDue)}`}
        </StyledHeading>
        <StyledText
          data-testid="total-unpaid-bills"
          aria-label={`Total unpaid bills is ${totalEncounters}`}
          color={Color.white}
          title={`Total unpaid bills is ${totalEncounters}`}
        >
          ({totalEncounters} Unpaid Bills)
        </StyledText>
        <Spacer size="small" />
        {(encounters.length > 0 || paidEncounters.length > 0) && (
          <MuiButton
            variant="contained"
            color="primary"
            onClick={goToUnpaidBillSummary}
            aria-label="Tap to view bills"
            title="Tap to view bills"
            data-testid="view-and-pay-button"
          >
            View and pay
          </MuiButton>
        )}
      </BorderMuiBox>
    );
  }

  const renderDeductibleBar = (title: string, familyTitle: string, type: string) => {
    return (
      <>
        <DeductibleBar
          hideBlock
          barColor={Color.tealLight}
          textColor={Color.foreColor}
          backColor={Color.tealTint}
          info={myBenefits}
          name={title}
          appliedSpend={0}
          type={type}
        />
        {familyBenefits && (
          <DeductibleBar
            hideBlock
            barColor={Color.tealLight}
            textColor={Color.foreColor}
            backColor={Color.tealTint}
            info={familyBenefits}
            name={familyTitle}
            appliedSpend={0}
            type={type}
          />
        )}
      </>
    );
  };

  const renderDeductibleActivity = (title: string, type: string) => {
    return (
      <MuiBox aria-label="New Deductible activity" title="New Deductible activity">
        <FlexBox accessibilityLabel={title} flexDirection="column">
          <Typography color={Color.foreColor} fontSize={FontSize.heading}>
            {title}
          </Typography>
          {isFetching ? (
            <Spinner />
          ) : myBenefits ? (
            type === 'max_oop' ? (
              renderDeductibleBar('Individual Out of Pocket Max', 'Family Out of Pocket Max', type)
            ) : (
              renderDeductibleBar('Individual Deductible', 'Family Deductible', type)
            )
          ) : (
            <Typography color={Color.foreColor} fontSize={FontSize.heading}>
              Failed to fetch Benefits
            </Typography>
          )}
        </FlexBox>
        <FlexBox flexDirection="row" justifyContent="center">
          <MuiButton variant="contained" color="primary" onClick={goToInsurace}>
            View insurance details
          </MuiButton>
        </FlexBox>
      </MuiBox>
    );
  };

  const renderPaymentPlan = (item: string) => {
    const index = item.split('#')[1];
    const eachPaymentPlan = paymentPlans[index];

    return (
      <BorderMuiBox
        display="flex"
        minHeight="200px"
        aria-label="Payment Plan card"
        title="Payment Plan card"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Typography color={Color.foreColor} fontSize={FontSize.large} fontWeight={FontWeight.bold}>
          {`Due date ${formatDate(new Date(eachPaymentPlan.paymentDueDate))}`}
        </Typography>
        <Typography color={Color.foreColor} fontSize={FontSize.small}>
          MONTHLY BALANCE DUE
        </Typography>
        <PaymentPlanHeading LineHeight="50px" adjustsFontSizeToFit>
          {`$${formatMoney(eachPaymentPlan.monthlyBalanceDue)}`}
        </PaymentPlanHeading>
        <StyledText color={Color.white}>
          {`Payment Plan Balance $${formatMoney(eachPaymentPlan.totalBalance)}`}
        </StyledText>
        <Spacer size="small" />
        <MuiButton
          variant="contained"
          color="primary"
          onClick={goToVisitPayHome}
          data-testid="Tap to view unpaid bills"
        >
          Manage payment plan
        </MuiButton>
      </BorderMuiBox>
    );
  };

  const renderItem = (item: string) => {
    let keyValue = item;
    if (item.split('#').length) {
      // eslint-disable-next-line
      keyValue = item.split('#')[0];
    }
    switch (keyValue) {
      case BILLING_SUMMARY:
        return renderBillingSummary();
      case DEDUCTIBLE_ACTIVITY:
        return renderDeductibleActivity(shInsurance?.insuranceName, 'deductible');
      case OOP_DEDUCTIBLE_ACTIVITY:
        return renderDeductibleActivity(shInsurance?.insuranceName, 'max_oop');
      case VISITPAY_PAYMENT_PLAN:
        return renderPaymentPlan(item);
      default:
        return null;
    }
  };
  return isImpersonating ? (
    renderBillingSummary()
  ) : (
    <Carousel
      data-testid="billing-summary"
      className="action-carousel"
      naturalSlideWidth={350}
      naturalSlideHeight={swiperOptions.length === 1 ? 140 : 280}
      slides={swiperOptions}
      renderSlide={renderItem}
      slideToKey={slide => slide || ''}
    >
      {goToVisitPay && <VisitPayWebView closePopup={setGoToVisitPay} uri={VISIT_PAY_HOME} />}
    </Carousel>
  );
};

const mapDispatchToProps = (dispatch: Function) => ({
  createSnack: (type: SnackType, snack?: Snack) => dispatch(snacksActions.createSnack(type, snack)),
  fetchBenefits: () => dispatch(getPlanBenefits())
});

export default connect(null, mapDispatchToProps)(BillingSummary as ComponentType);
