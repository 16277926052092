import React from 'react';
import { MuiTypography, MuiContainer, MuiBox } from 'theme/material-ui';

interface Props {
  patientName?: string;
  patientAge?: number;
}

const Banner = ({ patientName, patientAge }: Props) => (
  <MuiContainer maxWidth="lg">
    <MuiBox display="flex" alignItems="center" py={2} flexDirection="row">
      <MuiBox>
        {patientName && (
          <MuiTypography color="textPrimary" variant="h6" data-testid="vitals-patient-name">
            {patientName}
          </MuiTypography>
        )}
      </MuiBox>
      <MuiBox pl={1}>
        {patientAge && (
          <MuiTypography
            variant="subtitle2"
            fontWeight="fontWeightBold"
            color="textSecondary"
            data-testid="vitals-patient-age"
          >
            {patientAge} years old
          </MuiTypography>
        )}
      </MuiBox>
    </MuiBox>
  </MuiContainer>
);

export default Banner;
