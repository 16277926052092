import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import Svg from 'components/UI/Svg/Svg';

import { FontSize, IconSize } from 'modules/styles/variables';

import { MuiBox, MuiTypography } from 'theme/material-ui';

import { cancelVisit } from 'store/amwell/actions';
import { Visit } from 'store/amwell/types';
import * as selectors from 'store/amwell/selectors';
import { visitStartErrorSelector } from 'store/amwell/visit/selectors';
import { RootState, RootDispatch } from 'store/types';

import { pluralize } from 'modules/utils/StringUtils';

import ConnectCareWaitingRoomVideo from 'screens/ConnectCare/ConnectCareWaitingRoom/ConnectCareWaitingRoomVideo';
import ConnectCareWaitingRoomTextMeWhenReadyDialog from 'screens/ConnectCare/ConnectCareWaitingRoom/ConnectCareWaitingRoomTextMeWhenReadyDialog';
import ConnectCareWaitingRoomCancelVisitDialog from 'screens/ConnectCare/ConnectCareWaitingRoom/ConnectCareWaitingRoomCancelVisitDialog';

import {
  BannerAction,
  BannerContainer,
  BlueLetter,
  ButtonGroup,
  Content,
  MediaContainer
} from './styled';

import analyticsService, { AnalyticsEvent } from 'services/AnalyticsService';

import {
  currentLocationPathNameSelector,
  previousLocationPathNameSelector
} from 'store/router/selectors';
import { useSnack } from 'components/Snack';

export interface Props extends RouteComponentProps {
  dispatch: RootDispatch;
  visit: Visit | null;
  error: Error | null;
  currentUrl?: string;
  referringUrl?: string;
}

export function WaitingRoomBanner(props: Props) {
  const { dispatch, visit, error, currentUrl, referringUrl } = props;
  const snack = useSnack();
  const count = visit?.patientsAheadOfYou || 0;
  const verb = pluralize(count, 'is', 'are', true);
  const noun = pluralize(count, 'person', 'people', true);

  const [time, setTime] = useState(0);
  const [isTimerActive, setIsTimerActive] = useState(true);
  const [isTextMeWhenDialogVisible, setIsTextMeWhenReadyDialogVisible] = useState(false);
  const [phoneNumberSaved, setPhoneNumberSaved] = useState(false);
  const [isCancelVisitDialogVisible, setIsCancelVisitDialogVisible] = useState(false);

  const minutes = `${Math.floor(time / 60)}`;
  const seconds = `${time % 60}`.slice(-2);

  useEffect(() => {
    let interval: number | undefined;
    if (isTimerActive) {
      interval = setInterval(() => {
        setTime(secs => secs + 1);
      }, 1000);
    } else if (!isTimerActive && time !== 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isTimerActive, time]);

  const handleError = () => {
    snack.create('Sorry, there was an error processing the visit', 'error');
  };

  useEffect(() => {
    if (error) {
      handleError();
    }
  }, [error]);

  useEffect(() => {
    if (visit?.providerConnected === true) {
      setIsTimerActive(false);
      analyticsService.logEvent(AnalyticsEvent.ConnectCareStart, {
        wait_time_for_video_visit: { min: minutes, sec: seconds },
        referringUrl,
        currentUrl
      });
    }
  }, [visit?.providerConnected]);

  const onVisitCancel = () => {
    setIsTimerActive(false);
    analyticsService.logEvent(AnalyticsEvent.ConnectCareWaitingRoomExit, {
      text_me_when_ready: !phoneNumberSaved ? 'No' : 'Yes',
      wait_time_of_abandoned: { min: minutes, sec: seconds },
      currentUrl,
      referringUrl
    });

    dispatch(cancelVisit({ visit })).then(res => {
      if (res.error) {
        handleError();
      } else {
        if (isCancelVisitDialogVisible) {
          setIsCancelVisitDialogVisible(false);
        }
        snack.create('Your visit has been successfully cancelled', 'success');
      }
    });
  };

  return (
    <MuiBox width="100%">
      <MuiBox display="flex" flexDirection="row" justifyContent="center" alignItems="center">
        <BannerContainer>
          <MediaContainer>
            {visit?.providerConnected === true ? (
              <MuiBox height="100%" display="flex" alignItems="center" justifyContent="center">
                <MuiTypography variant="h5" align="center">
                  Your visit is in progress.
                </MuiTypography>
              </MuiBox>
            ) : (
              <ConnectCareWaitingRoomVideo />
            )}
          </MediaContainer>
          <Content>
            <MuiTypography fontSize={FontSize.large} gutterBottom>
              Your provider will be with you shortly. There {verb} <BlueLetter>{count}</BlueLetter>{' '}
              {noun} ahead of you.
            </MuiTypography>
            <ButtonGroup textAlign="center">
              <BannerAction
                fullWidth
                variant="outlined"
                onClick={() => setIsTextMeWhenReadyDialogVisible(true)}
                startIcon={<Svg set="assets" name="ConnectCareTextMe" size={IconSize.small} />}
                data-testid="text-message"
              >
                Message Me
              </BannerAction>
              <BannerAction
                fullWidth
                variant="outlined"
                onClick={() => setIsCancelVisitDialogVisible(true)}
                startIcon={<Svg set="assets" name="ConnectCareCancel" size={IconSize.small} />}
                data-testid="cancel-visit"
              >
                Cancel Visit
              </BannerAction>
            </ButtonGroup>
          </Content>
        </BannerContainer>
      </MuiBox>
      <ConnectCareWaitingRoomTextMeWhenReadyDialog
        isTextMeWhenDialogVisible={isTextMeWhenDialogVisible}
        setPhoneNumberSaved={setPhoneNumberSaved}
        setIsTextMeWhenReadyDialogVisible={setIsTextMeWhenReadyDialogVisible}
        handleClose={() => setIsTextMeWhenReadyDialogVisible(false)}
      />
      <ConnectCareWaitingRoomCancelVisitDialog
        onVisitCancel={onVisitCancel}
        isCancelVisitDialogVisible={isCancelVisitDialogVisible}
        handleClose={() => {
          setIsCancelVisitDialogVisible(false);
        }}
      />
    </MuiBox>
  );
}

const mapStateToProps = (state: RootState) => ({
  // visit selectors
  visit: selectors.visitDataSelector(state),
  error: visitStartErrorSelector(state),
  // where we are and where we were selectors
  currentUrl: currentLocationPathNameSelector(state),
  referringUrl: previousLocationPathNameSelector(state)
});

export default connect(mapStateToProps)(WaitingRoomBanner);
