import Snackbar from '@material-ui/core/Snackbar';
import FlexBox from 'components/UI/Layout/FlexBox';
import Typography from 'components/UI/Typography';
import { Spacing } from 'modules/styles/variables';
import React, { useEffect, useState } from 'react';
import { MuiButton } from 'theme/material-ui';

interface DownloadErrorSnackbarProps {
  hasError: boolean;
  onRetry: () => void;
}

const DownloadErrorSnackbar = ({ hasError, onRetry }: DownloadErrorSnackbarProps) => {
  const [showSnack, setShowSnack] = useState(false);

  useEffect(() => {
    if (hasError) {
      setShowSnack(true);
    } else {
      setShowSnack(false);
    }
  }, [hasError]);

  return (
    <>
      <Snackbar
        key={Date.now().toString()}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={showSnack}
        onClose={() => setShowSnack(false)}
        autoHideDuration={6000}
        style={{ top: 70, right: 10 }}
      >
        <>
          <FlexBox
            flexDirection="row"
            backgroundColor="white"
            alignItems="center"
            spacing={Spacing.medium}
            border={{
              width: { left: 10, top: 0, bottom: 0, right: 0 },
              style: 'solid',
              color: 'orange',
              radius: 0
            }}
          >
            <FlexBox>
              <Typography>This download has failed.</Typography>
            </FlexBox>
            <FlexBox>
              <MuiButton
                onClick={() => {
                  setShowSnack(false);
                  onRetry();
                }}
                color="primary"
              >
                Retry
              </MuiButton>
            </FlexBox>
          </FlexBox>
        </>
      </Snackbar>
    </>
  );
};

export default DownloadErrorSnackbar;
