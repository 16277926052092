import styled from 'styled-components';
import { Color } from 'modules/styles/colors';
import { ZIndex, Spacing } from 'modules/styles/variables';
import { MuiBox, dfdDefaultTheme } from 'theme/material-ui';

export const Container = styled.div`
  flex: auto;
  background-color: ${Color.foreColor};
  flex-direction: column;
  width: 100%;
`;

export const SpinnerContainer = styled.div`
  background-color: ${Color.foreColor};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  justify-content: center;
  z-index: ${ZIndex.spinner};
`;

export const ProcedureBox = styled(MuiBox)`
  display: flex;
  padding-bottom: ${Spacing.small}px;
  justify-content: space-between;
  flex-wrap: wrap;
`;
export const ProcedureInfoBox = styled(MuiBox)`
  padding-right: ${Spacing.small}px;
  flex-wrap: nowrap;
  ${dfdDefaultTheme.breakpoints.up('sm')} {
    min-width: 150px;
  }
  ${dfdDefaultTheme.breakpoints.down('sm')} {
    min-width: 100px;
  }
`;
