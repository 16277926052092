import React from 'react';
import { useSelector } from 'react-redux';
import { FiltersStructure } from 'lib/hooks/ProviderSearch/types';
import { FilterDrawer } from 'components/FilterDrawer';
import { languagesDataSelector } from 'store/providerSearch/selectors';
import { viewableCount } from '../constants';
import { FormCheckboxList } from '../shared';

export type LanguagesFilterProps = FiltersStructure['languages'] & {
  defaultExpanded: boolean;
};

function LanguagesFilter({ title, values, defaultExpanded }: LanguagesFilterProps) {
  const languages = useSelector(languagesDataSelector);

  return (
    <FilterDrawer.Panel
      expandable={languages.length}
      title={title}
      defaultExpanded={defaultExpanded}
    >
      <FormCheckboxList initialCount={viewableCount} list={Object.values(values(languages))} />
    </FilterDrawer.Panel>
  );
}

export default LanguagesFilter;
