import React, { useMemo } from 'react';
import { MuiBox } from 'theme/material-ui';

type Props = {
  value: string;
  valueLength: number;
  onChange: (value: string) => void;
};

const OTPInput = ({ value, valueLength, onChange }: Props) => {
  const valueItems = useMemo(() => {
    const valueArray = value.split('');
    const items: Array<string> = [];

    for (let i = 0; i < valueLength; i += 1) {
      const char = valueArray[i];

      if (/^\d+$/.test(char)) {
        items.push(char);
      } else {
        items.push('');
      }
    }
    return items;
  }, [value, valueLength]);

  const focusNext = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.target.nextElementSibling) {
      event.target.nextElementSibling.focus();
    }
  };

  const focusPrev = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.target.previousElementSibling) {
      event.target.previousElementSibling.focus();
    }
  };

  const inputOnChange = (event: React.ChangeEvent<HTMLInputElement>, idx: number) => {
    let inputValue = event.target.value.trim();
    const isInputValueDigit = /^\d+$/.test(inputValue);

    if (isInputValueDigit === false && inputValue !== '') {
      return false;
    }

    inputValue = isInputValueDigit ? inputValue : ' ';

    if (inputValue.length === 1) {
      const newValue = value.substring(0, idx) + inputValue + value.substring(idx + 1);
      onChange(newValue);

      if (isInputValueDigit === false) {
        return false;
      }
      focusNext(event);
    } else if (inputValue.length === valueLength) {
      onChange(inputValue);
      event.target.blur();
    }
    return true;
  };

  const inputOnKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'ArrowRight' || event.key === 'ArrowDown') {
      event.preventDefault();
      focusNext(event);
    }
    if (event.key === 'ArrowLeft' || event.key === 'ArrowDoUp') {
      event.preventDefault();
      focusPrev(event);
    }
    if (event.key !== 'Backspace' || event.target.value !== '') {
      return false;
    }
    event.target.setSelectionRange(0, event.target.value.length);
    focusPrev(event);
    return true;
  };

  const inputOnFocus = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.target.setSelectionRange(0, event.target.value.length);
  };

  return (
    <MuiBox justifyContent="space-between" flexDirection="row" display="flex" mb={3}>
      {valueItems.map((digit, idx) => {
        return (
          <input
            value={digit}
            maxLength={valueLength}
            pattern="\d{1}"
            type="text"
            data-testid={`OTPvalidationInput-${idx}`}
            onChange={event => inputOnChange(event, idx)}
            onKeyDown={inputOnKeyDown}
            autoComplete="one-time-code"
            inputMode="numeric"
            onFocus={inputOnFocus}
            style={{
              height: 40,
              maxWidth: 32,
              textAlign: 'center'
            }}
          />
        );
      })}
    </MuiBox>
  );
};

export default OTPInput;
