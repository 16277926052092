import React from 'react';
import styled, { css } from 'styled-components';
import MaskedInput from 'react-text-mask';
import { TextFieldProps, InputBaseComponentProps } from '@material-ui/core';
import { MuiButton, MuiTextField, MuiTypography } from 'theme/material-ui';
import { FontSize, Spacing } from 'modules/styles/variables';
import { Color } from 'modules/styles/colors';
import masks from 'utils/masks';
import FlexBox from 'components/UI/Layout/FlexBox';
import Svg from 'components/UI/Svg/Svg';

export const StyledTextField = styled(MuiTextField)`
  border-radius: 4px;
  border: 1px solid ${Color.grayHue8};
  background: ${Color.foreColor};

  font-size: ${FontSize.large}px;
  line-height: 26px;
  letter-spacing: 0.54px;

  &::placeholder {
    color: ${Color.grayHue8};
  }

  .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${Color.secondary};
  }

  ${({ showError }) =>
    showError &&
    css`
      .MuiInputBase-root,
      &:hover .MuiInputBase-root .MuiOutlinedInput-notchedOutline,
      .MuiInputBase-root .MuiOutlinedInput-notchedOutline,
      .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        color: ${Color.red};
        border-color: ${Color.red};
      }
      .MuiInputBase-input::placeholder {
        color: ${Color.grayHue8};
      }
    `}
`;

interface MaskedTextProps extends InputBaseComponentProps {
  mask: typeof masks;
}

function MaskedText({ inputRef, ...rest }: MaskedTextProps) {
  return (
    <MaskedInput
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      {...rest}
    />
  );
}

const MaxLengthError = ({ helperTextInfo }: { helperTextInfo: string }) => (
  <MuiTypography
    fontSize={FontSize.base * 0.75}
    component="p"
    style={{ margin: '0px 14px', color: Color.red }}
  >
    {helperTextInfo}
  </MuiTypography>
);

function RenderTextInfo({
  helperTextInfo,
  maxLength,
  value,
  showError
}: {
  helperTextInfo: string;
  maxLength?: number;
  value: string;
  showError?: boolean | null;
}) {
  if (maxLength) {
    if (showError === null || showError === true) {
      return value.length === maxLength - (showError !== null ? 1 : 0) ? (
        <MaxLengthError helperTextInfo={helperTextInfo} />
      ) : null;
    }
    return null;
  }
  if (helperTextInfo) {
    return (
      <MuiTypography fontSize={FontSize.small} color={showError ? Color.red : Color.textDark}>
        {helperTextInfo}
      </MuiTypography>
    );
  }
  return null;
}

export type TextInputProps = TextFieldProps & {
  // access by MaskKey [e.g. 'creditCard'] or by string dot.notation [e.g. 'creditCard.amex']
  mask?: keyof typeof masks | string;
  helperTextInfo?: string;
  showError?: boolean | null;
  onMoreInfoPress?: () => void;
  moreInfoText?: string;
  accessibilityLabel?: string;
};

const markAsRequired = (label: string, required: boolean) => (required ? `${label}*` : label);

/**
 * Component: TextInput
 * @material-ui/core/TextField wrapper to be able to use masks.
 */
function TextInput({
  mask,
  InputProps,
  label,
  required,
  onMoreInfoPress,
  moreInfoText = 'More info',
  ...rest
}: TextInputProps) {
  const textColor = rest?.showError ? Color.red : Color.textDark;
  return (
    <FlexBox vOffsetBottom={Spacing.medium} vOffsetTop={Spacing.large}>
      <FlexBox
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        fullWidth
        vOffsetBottom={Spacing.small}
      >
        {label ? (
          <MuiTypography color={textColor}>{markAsRequired(label, required)}</MuiTypography>
        ) : null}
        {onMoreInfoPress ? (
          <MuiButton
            color="primary"
            variant="text"
            size="small"
            startIcon={<Svg name="InfoIcon" color={Color.secondary} />}
            style={{ flexShrink: 0 }}
          >
            {moreInfoText}
          </MuiButton>
        ) : null}
      </FlexBox>
      <StyledTextField
        variant="outlined"
        InputProps={
          mask
            ? {
                ...InputProps,
                inputComponent: MaskedText as React.ElementType<InputBaseComponentProps>,
                inputProps: { ...InputProps?.inputProps, mask: masks(mask), ...rest.inputProps }
              }
            : InputProps
        }
        {...rest}
        label=""
      />
      {rest.helperTextInfo ? (
        <RenderTextInfo {...rest} maxLength={rest?.maxLength} showError={rest?.showError} />
      ) : null}
    </FlexBox>
  );
}

export default TextInput;
