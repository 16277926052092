import React from 'react';
import { useSelector } from 'react-redux';

import FlexBox from 'components/UI/Layout/FlexBox';
import UserAvatar from 'components/UI/Avatar';
import { CursorMuiBox, MuiBox, MuiTypography } from 'theme/material-ui';

import { getUserName } from 'lib/user';

import { Color } from 'modules/styles/colors';
import { FontSize, Spacing } from 'modules/styles/variables';
import { capitalizeEachWord } from 'modules/utils/StringUtils';
import { Consumer, Grantor } from 'store/profile/types';
import { MapAvatar } from 'store/consumerPreferences/types';
import { allActiveAccountsSelector } from 'store/account/selectors';

import AvatarScreenTitle from './components/AvatarScreenTitle';
import { AvatarContainer, ScrollFlexbox, EditComponent } from './styled';

interface UserSelectionProps {
  avatarsMap: MapAvatar;
  onUserSelect: (user: Consumer | Grantor) => void;
}

const UserSelection = ({ avatarsMap, onUserSelect }: UserSelectionProps) => {
  const users = useSelector(allActiveAccountsSelector);

  return (
    <ScrollFlexbox>
      <AvatarScreenTitle title="Manage Avatars" />
      <FlexBox maxWidth={900} flexDirection="row" flexWrap="wrap" justifyContent="center">
        {users.map(user => (
          <CursorMuiBox
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            py={Spacing.small}
            px={0.5}
            width={150}
            data-testid="user-selection-avatar"
          >
            <AvatarContainer>
              <UserAvatar
                size={90}
                avatarUrl={avatarsMap[user.consumerId]}
                fallback={getUserName(user)}
                initialsFontSize={FontSize.title}
                onAvatarClick={() => onUserSelect(user)}
                borderColor={Color.secondary}
                initialsFontColor={Color.secondary}
                backgroundColor={Color.white}
              />
              <MuiBox mt={1}>
                <MuiTypography noWrap align="center" color={Color.white}>
                  {capitalizeEachWord(`${user.firstName} ${user.lastName}`)}
                </MuiTypography>
              </MuiBox>
            </AvatarContainer>
            <EditComponent />
          </CursorMuiBox>
        ))}
      </FlexBox>
    </ScrollFlexbox>
  );
};

export default UserSelection;
