import React from 'react';
import dayjs from 'dayjs';

import Svg from 'components/UI/Svg/Svg';
import { MuiBox, MuiDivider, MuiGrid, MuiTypography } from 'theme/material-ui';

import { Color } from 'modules/styles/colors';
import { maskZip } from 'modules/utils/MaskUtils';
import { FontSize, IconSize } from 'modules/styles/variables';
import { formatTimeWithTimeZone } from 'modules/utils/DateUtils';

import { AppointmentDetails } from 'store/booking/types';
import { LazyMuiAvatar } from 'components/LazyAvatar';

export interface Props {
  appointmentDetails: AppointmentDetails;
}

export function AppointmentDetailsGrid({ appointmentDetails }: Props) {
  const appointmentTime =
    appointmentDetails?.appointmentTime?.datetime || appointmentDetails?.appointmentTime?.start;

  return (
    <>
      <MuiGrid container xs spacing={2}>
        <MuiGrid item xs>
          <MuiTypography fontSize={FontSize.small}>
            {appointmentDetails
              ? `${dayjs(appointmentDetails?.appointmentDate).format('MM/DD/YYYY')} -
                  ${appointmentTime && formatTimeWithTimeZone(appointmentTime)}`
              : ''}
          </MuiTypography>
          <MuiTypography fontSize={FontSize.heading} fontWeight={600}>
            {appointmentDetails?.groupedVisitType?.name}
          </MuiTypography>
        </MuiGrid>
        <MuiGrid item>
          <MuiBox>
            <LazyMuiAvatar
              src={appointmentDetails?.doctor?.profileImage}
              style={{ width: IconSize.xxLarge, height: IconSize.xxLarge }}
            />
          </MuiBox>
        </MuiGrid>
      </MuiGrid>
      <MuiBox my={1}>
        <MuiDivider />
      </MuiBox>
      <MuiGrid container direction="row" alignItems="center" spacing={1}>
        {appointmentDetails?.doctor?.isSelectHealth ? (
          <MuiGrid item>
            <Svg
              set="assets"
              name="SelectHealth"
              size={IconSize.base}
              data-testid="select-health-svg"
            />
          </MuiGrid>
        ) : null}
        <MuiGrid item>
          <MuiTypography color={Color.black} fontSize={FontSize.base}>
            {appointmentDetails?.doctor?.displayName}
          </MuiTypography>
        </MuiGrid>
      </MuiGrid>
      <MuiBox my={1}>
        <MuiDivider />
      </MuiBox>
      {appointmentDetails?.appointmentType === 'Video' ? (
        <MuiGrid container direction="row" alignItems="center" spacing={1}>
          <MuiGrid item>
            <Svg
              set="assets"
              name="VideoVisit"
              size={IconSize.base}
              color="black"
              data-testid="video-visit-svg"
            />
          </MuiGrid>
          <MuiGrid item>
            <MuiTypography color={Color.black} fontSize={FontSize.base}>
              Video visit
            </MuiTypography>
          </MuiGrid>
        </MuiGrid>
      ) : (
        <MuiGrid container spacing={1} wrap="nowrap">
          <MuiGrid item>
            <Svg set="assets" name="DirectionIcon" size={20} />
          </MuiGrid>
          <MuiGrid item xs={12} sm container alignItems="center">
            <MuiGrid item xs>
              <MuiTypography fontSize={FontSize.base}>
                {appointmentDetails?.appointmentLocation?.locationName ?? ''}{' '}
                {appointmentDetails?.appointmentLocation?.addressLine1 ?? ''}{' '}
                {appointmentDetails?.appointmentLocation?.city ?? ''}{' '}
                {appointmentDetails?.appointmentLocation?.state ?? ''},{' '}
                {maskZip(appointmentDetails?.appointmentLocation?.postalCode) ?? ''}
              </MuiTypography>
            </MuiGrid>
          </MuiGrid>
        </MuiGrid>
      )}
      <MuiBox my={1}>
        <MuiDivider />
      </MuiBox>
      <MuiTypography>Patient: {appointmentDetails?.patient?.displayName}</MuiTypography>
    </>
  );
}

export default AppointmentDetailsGrid;
