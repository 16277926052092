import React from 'react';
import { MuiMenuItem, SelectProps, MenuProps } from 'theme/material-ui';
import { TextInput } from 'components/TextInput';

const SELECT_ITEM_HEIGHT = 48;
const SELECT_ITEM_PADDING_TOP = 8;

const menuProps: Partial<MenuProps> = {
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left'
  },
  PaperProps: {
    style: {
      maxHeight: SELECT_ITEM_HEIGHT * 4.5 + SELECT_ITEM_PADDING_TOP
    }
  }
};

export interface SelectDropdownProps<T> {
  id?: string;
  value?: string | number | string[];
  items: T[];
  variant: 'outlined' | 'filled' | 'standard';
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  fullWidth?: boolean;
  label?: React.ReactNode;
  placeholder?: string;
  name?: string;
  SelectProps?: SelectProps;
  itemToValue: (item: T) => string;
  itemToLabel: (item: T) => string;
  itemToDataObject: (item: T) => string;
}

function SelectDropdown<T>({
  SelectProps,
  items,
  placeholder,
  itemToLabel,
  itemToValue,
  itemToDataObject,
  ...rest
}: SelectDropdownProps<T>) {
  return (
    <TextInput
      select
      SelectProps={{ displayEmpty: true, MenuProps: menuProps, ...SelectProps }}
      {...rest}
    >
      {placeholder ? (
        <MuiMenuItem value="" disabled>
          {placeholder}
        </MuiMenuItem>
      ) : null}
      {items.map(item => (
        <MuiMenuItem
          key={itemToValue(item)}
          value={itemToValue(item)}
          data-object={itemToDataObject(item)}
          data-testid={itemToLabel(item)}
        >
          {itemToLabel(item)}
        </MuiMenuItem>
      ))}
    </TextInput>
  );
}

SelectDropdown.defaultProps = {
  variant: 'outlined',
  itemToValue: (item: any) => (item ? item.value : ''),
  itemToLabel: (item: any) => (item ? item.label : ''),
  itemToDataObject: (item: any) => (item ? JSON.stringify(item) : '')
};

export default SelectDropdown;
