import styled, { css } from 'styled-components';
import { $Values } from 'utility-types';
import { Color } from 'modules/styles/colors';
import { FontSize, FontWeight, FontFamily } from 'modules/styles/variables';
import { resolveCSSUnit } from 'modules/utils/StylesUtils';
import { TextProps } from 'react-native';
import { oc } from 'ts-optchain';
import SmallCardText from './SmallCardText';

export type TextAlign = 'inherit' | 'left' | 'right' | 'center';
export type TypographyTag =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'small'
  | 'caption'
  | 'xsmall'
  | 'label'
  | 'link';

export interface TypographyProps extends TextProps {
  tag?: TypographyTag;
  textAlign?: TextAlign;
  color?: $Values<typeof Color>;
  display?: 'initial' | 'block' | 'inline';
  fontSize?: $Values<typeof FontSize>;
  fontWeight?: $Values<typeof FontWeight>;
  lineHeight?: number;
  italics?: boolean;
  FontFamily?: string;
}

interface Options {
  operation?: '*' | '+' | '-' | '/';
  value?: number;
}

const evaluate = {
  '*': (from: number, to: number) => from * to,
  '/': (from: number, to: number) => from / to,
  '+': (from: number, to: number) => from + to,
  '-': (from: number, to: number) => from - to
};

const numToPx = (num: number, opt: Options = {}) => {
  const operation = oc(opt).operation('*');
  const value = oc(opt).value(1);

  const result = evaluate[operation](num, value);

  return `${result}px`;
};

const tagStyles = (props: TypographyProps) => {
  switch (props.tag) {
    case 'h1':
      return css`
        font-size: ${resolveCSSUnit(FontSize.largeTitle)};
        line-height: 48px;
      `;
    case 'h2':
      return css`
        font-size: ${resolveCSSUnit(FontSize.title)};
        line-height: 40px;
      `;
    case 'h3':
      return css`
        font-size: ${resolveCSSUnit(FontSize.largeHeading)};
        line-height: 34px;
      `;
    case 'h4':
      return css`
        font-size: ${resolveCSSUnit(FontSize.heading)};
        line-height: 28px;
      `;
    case 'h5':
      return css`
        font-size: ${resolveCSSUnit(FontSize.large)};
        line-height: 26px;
      `;
    case 'label':
      return css`
        font-weight: ${FontWeight.semibold};
      `;
    case 'small':
      return css`
        font-size: ${resolveCSSUnit(FontSize.small)};
        line-height: 22px;
      `;
    case 'caption':
      return css`
        font-size: ${resolveCSSUnit(FontSize.xsmall)};
        text-transform: 'upper-case';
        line-height: 20px;
      `;
    case 'xsmall':
      return css`
        font-size: ${resolveCSSUnit(FontSize.xxsmall)};
        line-height: 18px;
      `;
    case 'link':
      return css`
        color: ${Color.link};
        cursor: pointer;
      `;
    default:
      return null;
  }
};

const alignStyles = ({ textAlign }: TypographyProps) =>
  textAlign &&
  css`
    text-align: ${textAlign};
  `;

const colorStyles = ({ color }: TypographyProps) =>
  color &&
  css`
    color: ${color};
  `;

const displayStyles = ({ display }: TypographyProps) =>
  display &&
  css`
    display: ${display};
  `;

// TODO: This is a Kludge. FontSize should be $Values<typeof FontSize>, but is sometimes a string with px. Fix it as you find it. It should always be a number.
const fontSizeStyles = ({ fontSize }: TypographyProps) =>
  fontSize &&
  css`
    font-size: ${resolveCSSUnit(fontSize)};
  `;

const lineHeightStyles = (props: TypographyProps) => {
  const { fontSize, lineHeight } = props;

  let multiplyBy = 1;
  let num = FontSize.base;

  if (lineHeight) {
    num = lineHeight;
  } else if (fontSize) {
    multiplyBy = fontSize > FontSize.large ? 1 : 1.4;
    num = fontSize;
  } else {
    multiplyBy = 1.4;
  }

  return css`
    line-height: ${numToPx(num, { value: multiplyBy })};
  `;
};

const fontWeightStyles = ({ fontWeight }: TypographyProps) =>
  fontWeight &&
  css`
    font-weight: ${fontWeight};
  `;

const fontFamilyStyles = (props: TypographyProps) => {
  return css`
    font-family: ${props.FontFamily || FontFamily.primary};
  `;
};

const italicsStyles = (props: TypographyProps) =>
  props.italics &&
  css`
    font-style: italic;
  `;

export const Typography = styled.div<TypographyProps>`
  font-size: ${FontSize.base};
  font-weight: ${FontWeight.normal};
  line-height: 24px;
  color: ${Color.textDark};
  text-align: left;

  ${alignStyles};
  ${colorStyles};
  ${displayStyles};
  ${fontSizeStyles};
  ${lineHeightStyles};
  ${fontWeightStyles};
  ${italicsStyles};
  ${fontFamilyStyles};
  ${tagStyles};
`;

export const BoldText = styled(Typography)`
  font-weight: ${FontWeight.bold};
`;

export const SmallCardHours = styled(SmallCardText)`
  display: flex;
`;
