import styled from 'styled-components';
import React, { ReactNode } from 'react';
import { ActivityIndicator } from 'react-native';
import { Icon } from 'components/Icon';

import { Color } from 'modules/styles/colors';
import { Spacing } from 'modules/styles/variables';
import AbsoluteBox from '../Layout/AbsoluteBox';
import SmallText from '../Typography/SmallText';
import { TextInputProps } from './types';

export interface InputContainerProps extends TextInputProps {
  children: ReactNode;
}

const Container = styled.div`
  width: 100%;
`;

const ErrorText = styled(SmallText)<{ formGroup: boolean }>`
  margin-top: ${Spacing.medium};
  color: ${Color.red};
  position: ${props => (props.formGroup ? 'absolute' : 'relative')};
  top: ${props => (props.formGroup ? '39px' : '0px')};
`;

const RequiredLabel = styled(SmallText)`
  right: 10px;
  position: absolute;
  top: 9px;
  color: ${Color.red};
`;

const InputContainer = (props: InputContainerProps) => {
  const hasLeftDecoration = !!props.iconName || props.loading;

  return (
    <Container pointerEvents={props.nonInteractable ? 'none' : 'auto'}>
      {props.children}
      {props.requiredLabel ? <RequiredLabel>*</RequiredLabel> : null}
      {hasLeftDecoration && (
        <AbsoluteBox left={10} top={10}>
          {props.loading ? (
            <ActivityIndicator size="small" />
          ) : props.iconName ? (
            <Icon name={props.iconName} />
          ) : null}
        </AbsoluteBox>
      )}
      {props.error ? <ErrorText formGroup={!!props.formGroup}>{props.error}</ErrorText> : null}
    </Container>
  );
};

InputContainer.defaultProps = {
  iconName: null
};

export default InputContainer;
