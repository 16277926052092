import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Pharmacy } from 'store/amwell/types';
import { MuiGrid, MuiBox, dfdDefaultTheme, MuiSwitch, MuiButton } from 'theme/material-ui';
import { RootState } from 'store/types';
import { FlexBoxRow } from 'components/UI/Layout/FlexBox';
import GoogleMap from 'components/GoogleMap';
import { LatLng, MapLocation } from 'services/LocationService/types';
import CityStateZipPicker, {
  FilterOption
} from 'screens/ConnectCare/ConnectCarePharmacySelect/CityStateZipPicker';
import styled from 'styled-components';
import { Spacing } from 'modules/styles/variables';
import { Color } from 'modules/styles/colors';
import { ConnectCareDataLoader } from 'components/ConnectCare';
import { PharmacyType } from 'lib/amwell/sdk/enums';
import * as selectors from 'store/amwell/selectors';
import ConnectCarePharmacyItem from './ConnectCarePharmacyItem';
import CloseButton from 'components/UI/Button/CloseButton';
import { updatePreferredPharmacy } from 'store/amwell/actions';
import { getData } from 'lib/amwell/normalizer';
import Spacer from 'components/UI/Layout/Spacer';
import { getAllPharmacies, searchPharmacies } from 'store/amwell/activeConsumer/pharmacies/actions';
import { State } from 'lib/amwell/sdk/types';
import rxIcon from 'assets/ConnectCare/rxIcon.png';
import { formatPhone } from 'services/formatPhone';

const PER_PAGE = 5;

const SearchBox = styled.div`
  flex: 1 1 auto;
  margin: ${Spacing.small}px;
  border: 1px solid ${Color.grayHue4};
  border-radius: ${Spacing.xLarge}px;
  background-color: ${Color.white};
`;

const FlexRowColumn = styled.div`
  display: flex;
  flex-direction: row;

  ${dfdDefaultTheme.breakpoints.down('lg')} {
    flex-direction: column;
  }
`;

const SearchColumn = styled.div`
  ${dfdDefaultTheme.breakpoints.up('xl')} {
    max-width: 40%;
    min-width: 400px;
  }
`;

export interface ConnectCarePharmacySearchFormProps {
  setUpdating: (isUpdating: boolean) => void;
  error: Error | null;
  data: Pharmacy[];
  preferred: Pharmacy;
  usStates: State[];
}

export function mapLocations(loc: Pharmacy) {
  /* eslint-disable-next-line */
  const { address, distance, fax, id, latitude, longitude, name, phone, type } = loc.__data || loc;
  const { address1, city, state, zipCode } = address;
  return {
    id: id.persistentId,
    icon: rxIcon,
    lat: latitude,
    lng: longitude,
    name,
    content: `<div style="padding: 0.5em;">
      <div style="font-weight: bold;">${name}</div>
      <div>${type}</div>
      <div>${address1}</div>
      <div>${city}, ${state.code} ${zipCode}</div>
      <br>

      <div style="font-weight: bold;">Contact</div>
      <div>Phone: ${formatPhone(phone)}</div>
      ${fax ? `<div>Fax: ${formatPhone(fax)}</div>` : ''}
      ${distance ? `<div>${Math.round(distance * 100) / 100} miles</div>` : ''}
      <div style="padding-top: .5rem;"><button style="cursor: pointer;" onClick="handleConnectCareSearchClick('${
        id.persistentId
      }')">Select pharmacy</button></div>

    </div>`
  };
}

export function ConnectCarePharmacySearchFormComponent(props: ConnectCarePharmacySearchFormProps) {
  const { data, preferred, error, setUpdating, usStates } = props;
  const [latLng, setLatLng] = useState<LatLng | undefined>();
  const [locations, setLocations] = useState<MapLocation[]>([]);
  const [typeSwitch, setTypeSwitch] = useState(true);
  const [labelOrigin, setLabelOrigin] = useState<MapLocation>();
  const [page, setPage] = useState(1);
  const [pageData, setPageData] = useState<Pharmacy[]>([]);
  const dispatch = useDispatch();

  const totalPages = data ? Math.ceil(data.length / PER_PAGE) : 0;
  window.handleConnectCareSearchClick = (id: string) => {
    const loc = data.find(loc => loc.id.persistentId === id);
    if (loc) {
      changePreferred(loc);
    }
  };

  const searchByLatLng = (latitude: number, longitude: number, pharmacyType: PharmacyType) => {
    dispatch(
      getAllPharmacies({
        latitude,
        longitude,
        pharmacyType
      })
    );
  };

  const searchByState = (state: State, pharmacyType: PharmacyType) => {
    dispatch(
      searchPharmacies({
        city: '',
        zipCode: '',
        state,
        pharmacyType
      })
    );
  };

  const onSubmit = async (option: FilterOption, typeSwitch: boolean) => {
    const { lat, lng, city, state, zip } = option;
    if (lat && lng) {
      setLatLng({ lat, lng });
    }
    const pharmacyType = typeSwitch ? PharmacyType.RETAIL : PharmacyType.MAIL_ORDERS;
    if (lat && lng) {
      setPage(0);
      /* eslint-disable-next-line */
      const stateObj = usStates.find(obj => obj.__data.code === state);
      if (stateObj && !city && !zip) {
        /* eslint-disable-next-line */
        const lo = { name: stateObj?.__data.name, lat, lng };
        setLabelOrigin(lo);
        searchByState(stateObj, pharmacyType);
      } else {
        const name = `${city ? `${city}, ` : ''}${state}${zip ? ` ${zip}` : ''}`;
        setLabelOrigin({ name, lat, lng });
        searchByLatLng(lat, lng, pharmacyType);
      }
    }
  };

  const changePreferred = (prefered: Pharmacy) => {
    resetData();
    dispatch(updatePreferredPharmacy({ pharmacy: prefered }));
    setUpdating(false);
  };

  const resetData = () => {
    setPage(0);
    setPageData([]);
    setLocations([]);
  };

  useEffect(() => {
    if (data && data.length) {
      const fullArray = [...data];
      fullArray.sort((loc1, loc2) => {
        /* eslint-disable-next-line */
        const dist1 = loc1.__data.distance;
        /* eslint-disable-next-line */
        const dist2 = loc2.__data.distance;
        return dist1 && dist2 ? (dist1 > dist2 ? 1 : -1) : -1;
      });
      const offset = page * PER_PAGE;
      const pageData = fullArray.slice(offset, offset + PER_PAGE);
      setPageData(pageData);
      const locations = pageData.map(mapLocations);
      setLocations(locations);
      if (!latLng && locations.length) {
        setLatLng({ lat: locations[0].lat, lng: locations[0].lng });
      }
    } else if (preferred) {
      setPage(0);
      setLatLng({ lat: preferred.latitude, lng: preferred.longitude });
      setPageData([preferred]);
      setLocations([mapLocations(preferred)]);
    }
  }, [data, page]);

  useEffect(resetData, [preferred]);

  return (
    <FlexRowColumn>
      <SearchColumn>
        <FlexBoxRow>
          <SearchBox>
            <CityStateZipPicker onChange={option => onSubmit(option, typeSwitch)} />
          </SearchBox>
          <MuiBox display="flex" alignItems="center">
            <CloseButton
              onPress={() => {
                resetData();
                setUpdating(false);
              }}
            />
          </MuiBox>
        </FlexBoxRow>
        <Spacer size="xsmall" />
        <div>
          <span>Pharmacy Type: </span>
          <span>
            <MuiSwitch
              checked={typeSwitch}
              onChange={(evt, val) => {
                setTypeSwitch(val);
                if (latLng) {
                  onSubmit(latLng, val);
                }
              }}
              aria-label="Pharmacy Type"
              data-testid="Pharmacy Type Input"
              name="Pharamcy Type"
              color="primary"
            />
          </span>
          <span>{typeSwitch ? 'Retail' : 'Mail Order'}</span>
        </div>
        <div>
          <ConnectCareDataLoader
            data={pageData}
            error={error}
            errorComponentProps={{
              message:
                'There was an issue fetching pharamcies, please enter at least one of the search criteria above'
            }}
            noDataComponentProps={{
              message: 'Please enter the city, state, or zip.'
            }}
            renderData={pharmacies => (
              <MuiGrid container spacing={2} direction="column">
                {pharmacies.map((d: Pharmacy) => {
                  const isSelected = preferred && d.id.persistentId === preferred.id.persistentId;
                  const border = isSelected ? `2px solid ${Color.blueHue3}` : '';
                  return (
                    <MuiGrid
                      container
                      item
                      key={d.id.persistentId}
                      wrap="nowrap"
                      direction="row"
                      xs
                      onClick={() =>
                        d.latitude &&
                        d.longitude &&
                        setLatLng({ lat: d.latitude, lng: d.longitude })
                      }
                    >
                      <MuiBox width="100%" border={border}>
                        <MuiBox width="100%">
                          <ConnectCarePharmacyItem
                            data={d}
                            isSelected={isSelected}
                            changePreferred={changePreferred}
                          />
                        </MuiBox>
                      </MuiBox>
                    </MuiGrid>
                  );
                })}
                {data ? (
                  <MuiBox
                    margin={`${Spacing.small}px ${Spacing.medium}px`}
                    data-testid="pharmacy-page-numbers"
                  >
                    {data.length ? `Page ${page + 1} of ${totalPages}` : null}
                    <MuiButton disabled={page === 0} onClick={() => setPage(page - 1)}>
                      Previous
                    </MuiButton>
                    <MuiButton disabled={page + 1 >= totalPages} onClick={() => setPage(page + 1)}>
                      Next
                    </MuiButton>
                  </MuiBox>
                ) : null}
              </MuiGrid>
            )}
          />
        </div>
      </SearchColumn>
      <MuiBox flex="1 1 auto" margin={`${Spacing.small}px`}>
        <GoogleMap
          mapId="CCPharMap"
          latLng={latLng}
          locations={locations}
          labelOrigin={labelOrigin}
        />
      </MuiBox>
    </FlexRowColumn>
  );
}

const mapStateToProps = (rootState: RootState) => ({
  data: selectors.pharmaciesListDataSelector(rootState),
  error: selectors.pharmaciesListErrorSelector(rootState),
  preferred: getData(selectors.preferredPharmacyDataSelector(rootState)),
  usStates: selectors.usStatesSelector(rootState)
});

export default connect(mapStateToProps)(ConnectCarePharmacySearchFormComponent);
