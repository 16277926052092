import CallToActionRow from 'components/common/CallToActionRow/CallToActionRow';
import CollapsibleListItem from 'components/common/CollapsibleList/CollapsibleListItem';
import { ListItem } from 'components/UI/ListItem/ListItem';
import { MEDICAL_HISTORY_DRAWERS, HEALTH_RECORDS_DRAWERS } from 'lib/constants/healthRecords';
import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'store/types';
import analyticsService, { AnalyticsEvent } from 'services/AnalyticsService';
import {
  currentLocationPathNameSelector,
  previousLocationPathNameSelector
} from 'store/router/selectors';
import { MuiBox, MuiList, MuiPaper } from 'theme/material-ui';
import { IconSize } from 'modules/styles/variables';

interface MedicalHistoryListProps {
  onTransmitPress: () => void;
  onDownloadPress: () => void;
  isDownloading: boolean;
  currentUrl?: string;
  referringUrl?: string;
}

export const HealthRecordsMedicalHistoryList = ({
  onDownloadPress,
  onTransmitPress,
  isDownloading,
  currentUrl,
  referringUrl
}: MedicalHistoryListProps) => {
  const handleDrawerOpen = (title: string) => {
    const eventEnumName = title.replace(' ', '').concat('Clicked');
    analyticsService.logEvent(AnalyticsEvent[eventEnumName], {
      currentUrl,
      referringUrl
    });
  };

  return (
    <CollapsibleListItem
      leftIcon="MedicalHistoryIcon"
      title={HEALTH_RECORDS_DRAWERS.MedicalHistory.title}
      subtitle={HEALTH_RECORDS_DRAWERS.MedicalHistory.subtitle()}
      analyticsEvent={AnalyticsEvent.MedicalHistoryClicked}
      currentUrlForAnalytics={currentUrl}
      referringUrlForAnalytics={referringUrl}
      detailsPadding="0"
    >
      <MuiBox width="100%">
        <MuiPaper elevation={2}>
          <MuiList>
            {MEDICAL_HISTORY_DRAWERS.map(item => (
              <ListItem
                to={item.to}
                accessibilityLabel={`Tap to view ${item.title}`}
                accessible
                title={item.title}
                body={item.body}
                icon={item.icon}
                iconSize={IconSize.xmedium}
                key={item.title}
                onPress={() => handleDrawerOpen(item.title)}
              />
            ))}
          </MuiList>
        </MuiPaper>
      </MuiBox>
      <CallToActionRow
        onTransmitPress={onTransmitPress}
        isDownloading={isDownloading}
        onDownloadPress={onDownloadPress}
      />
    </CollapsibleListItem>
  );
};

const mapState = (state: RootState) => ({
  currentUrl: currentLocationPathNameSelector(state),
  referringUrl: previousLocationPathNameSelector(state)
});

export default connect(mapState)(HealthRecordsMedicalHistoryList);
