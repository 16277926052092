import { connect } from 'react-redux';
import { applyOutboxSearch } from 'store/messaging/actions';
import { outboxSearchTextSelector } from 'store/messaging/selectors';
import { RootState } from 'store/types';
import MessageSearchBar from 'components/Messaging/MessageSearchBar';

export default connect(
  (state: RootState) => ({
    value: outboxSearchTextSelector(state)
  }),
  { onSearch: applyOutboxSearch }
)(MessageSearchBar);
