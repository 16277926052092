export interface MedicalHistoryError {
  title: string;
  subtitle?: string;
}

export type MedicalHistoryErrors = Readonly<Record<string, MedicalHistoryError>>;

export const allergiesError: MedicalHistoryErrors = {
  GET: {
    title: 'There was an issue fetching your allergies.'
  },
  UPDATE: {
    title: 'Uh oh!',
    subtitle: 'There was an issue updating your allergies.'
  }
};

export const conditionsError: MedicalHistoryErrors = {
  GET: {
    title: 'There was an issue fetching your conditions.'
  },
  UPDATE: {
    title: 'Uh oh!',
    subtitle: 'There was an issue updating your conditions.'
  }
};

export const vitalsError: MedicalHistoryErrors = {
  GET: {
    title: 'There was an issue fetching your vitals.'
  }
};

export const VITALS_ERROR_MESSAGE = {
  WEIGHT: {
    MAJOR: 'The value for Weight must be between 0.0 and 1500.0 lbs.',
    MINOR: 'The value for Weight must be between 0.0 and 15.9 oz.'
  },
  HEIGHT: {
    MAJOR: 'The value for Height must be between 0.0 and 10.0 ft.',
    MINOR: 'The value for Height must be between 0.0 and 11.9 oz.'
  },
  TEMPERATURE: 'The value for Temperature must be between 0.0 and 120.0 degrees Fahrenheit.',
  SYSTOLIC: 'The value for Systolic Blood Pressure must be between 0 and 250.',
  DIASTOLIC: 'The value for Systolic Blood Pressure must be between 0 and 250.'
};
