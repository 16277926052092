import React from 'react';
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons';
import { useWindowSize } from 'hooks/useWindowSize';
import { BackButton, ForwardButton, Slide, Slider, CarouselProvider } from './styled';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { isMobileBrowser } from 'lib/browser';

export interface CarouselProps<T> {
  className?: string;
  sliderHeight: number;
  naturalSlideWidth: number;
  naturalSlideHeight: number;
  slides: T[];
  slideToKey: (slide: T) => number | string;
  renderSlide: (slide: T) => React.ReactNode;
  infinite?: boolean;
  children?: React.ReactNode;
}

const useVisibleSlidesCount = (totalSlides: number) => {
  const { width } = useWindowSize();

  if (width > 1600) {
    return Math.min(3, totalSlides);
  }

  if (width < 1200) {
    return 1;
  }

  return Math.min(2, totalSlides);
};

export function shouldShowArrows(totalSlides: number) {
  return totalSlides > (isMobileBrowser() ? 1 : 2);
}

function Carousel<T>(props: CarouselProps<T>) {
  const { sliderHeight, slides, renderSlide, slideToKey, children, ...rest } = props;
  const totalSlides = slides.length;
  const visibleSlides = useVisibleSlidesCount(totalSlides);

  return (
    <CarouselProvider
      visibleSlides={visibleSlides}
      totalSlides={totalSlides}
      orientation="horizontal"
      {...rest}
    >
      <Slider sliderHeight={sliderHeight}>
        {slides.map((item, index) => (
          <Slide key={slideToKey(item) || index} index={index}>
            {renderSlide(item)}
          </Slide>
        ))}
      </Slider>
      {shouldShowArrows(totalSlides) ? (
        <>
          <BackButton>
            <ArrowBackIos />
          </BackButton>
          <ForwardButton>
            <ArrowForwardIos />
          </ForwardButton>
        </>
      ) : null}
      {children}
    </CarouselProvider>
  );
}

Carousel.defaultProps = {
  sliderHeight: 300,
  naturalSlideWidth: 350,
  naturalSlideHeight: 280,
  infinite: true,
  slideToKey: (item: any) => (item ? item.id : '')
};

export default Carousel;
