import React from 'react';
import { MuiBox, MuiContainer, dfdDefaultTheme, MuiButton } from 'theme/material-ui';
import { Color } from 'modules/styles/colors';
import ButtonGroup from 'components/UI/Button/ButtonGroup';
import { BorderRadius, IconSize, ZIndex } from 'modules/styles/variables';
import { Svg } from 'components/UI/Svg';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import ResponsiveIconButton from './ResponsiveIconButton';

export interface ListActionsRowProps {
  filterActive?: boolean;
  sortActive?: boolean;
  filterTitleEndText?: string;
  sortTitleEndText?: string;
  justifyContent?: 'center' | 'space-around' | 'space-between' | 'flex-end';
  onFilterPress?: (e: MouseEvent) => void;
  onSortPress?: (e: MouseEvent) => void;
  onDownloadActionPress?: (e: MouseEvent) => void;
  onShareActionPress?: (e: MouseEvent) => void;
  isDownloadingFiles?: boolean;
}

const ListActionsRow = ({
  filterActive,
  sortActive,
  filterTitleEndText,
  sortTitleEndText,
  justifyContent,
  onFilterPress,
  onSortPress,
  onDownloadActionPress,
  onShareActionPress,
  isDownloadingFiles
}: ListActionsRowProps) => {
  const isSmallScreen = useMediaQuery(dfdDefaultTheme.breakpoints.down('md'));
  return (
    <MuiBox width="100%" bgcolor={Color.cobaltTint} position="sticky" top={0} zIndex={ZIndex.high}>
      <MuiContainer maxWidth="lg">
        <MuiBox
          px={2}
          py={2}
          paddingRight={isSmallScreen ? 0 : 2}
          paddingLeft={isSmallScreen ? 0 : 2}
          bgcolor={Color.cobaltTint}
          display={!!justifyContent ? 'flex' : null}
          width="100%"
          justifyContent={justifyContent || null}
        >
          <ButtonGroup>
            {onDownloadActionPress ? (
              <ResponsiveIconButton
                testID="download-button"
                buttonText="Download"
                buttonAction={onDownloadActionPress}
                iconName="DownloadIcon"
                arialLabel="download"
                loading={isDownloadingFiles}
              />
            ) : null}
            {onShareActionPress ? (
              <ResponsiveIconButton
                testID="share-button"
                buttonText="Share"
                buttonAction={onShareActionPress}
                iconName="Share"
                arialLabel="share"
              />
            ) : null}
            {onSortPress ? (
              <MuiButton
                disableElevation
                variant="outlined"
                borderRadius={BorderRadius.base}
                color="primary"
                style={{ backgroundColor: sortActive ? Color.outlinedPressed : null }}
                onClick={onSortPress}
                aria-label="sort"
                data-testid="list-sort-button"
                width={156}
                endIcon={
                  <Svg
                    size={IconSize.xmedium}
                    color={Color.secondary}
                    set="assets"
                    name="ChevronDown"
                  />
                }
              >
                Sort{sortTitleEndText || null}
              </MuiButton>
            ) : null}
            {onFilterPress ? (
              <MuiButton
                disableElevation
                variant="outlined"
                borderRadius={BorderRadius.base}
                color="primary"
                style={{ backgroundColor: filterActive ? Color.outlinedPressed : null }}
                onClick={onFilterPress}
                aria-label="filter"
                data-testid="list-filter-button"
                endIcon={
                  <Svg
                    size={IconSize.xmedium}
                    color={Color.secondary}
                    set="assets"
                    name="ChevronDown"
                  />
                }
              >
                Filter{filterTitleEndText || null}
              </MuiButton>
            ) : null}
          </ButtonGroup>
        </MuiBox>
      </MuiContainer>
    </MuiBox>
  );
};

export default ListActionsRow;
