import React from 'react';
import { FontWeight, IconSize } from 'modules/styles/variables';
import TouchablePanelGroup from 'components/UI/Panel/TouchablePanelGroup';
import { Icon } from 'components/Icon';
import { TriageCondition } from 'store/triageGyant/types';
import { getLikelihoodText } from 'lib/triage/utils';
import { MuiBox, MuiTypography, CursorMuiBox } from 'theme/material-ui';
import { Color } from 'modules/styles/colors';

interface Props {
  causes: TriageCondition[];
  emptyCaption?: string;
  onItemPressed: Function;
  buttonText?: string;
}

interface ItemProps {
  cause: TriageCondition;
  index: number;
  buttonText?: string;
}

const renderItem = (props: ItemProps) => {
  const { cause, index, buttonText } = props;
  const likelihood = getLikelihoodText(cause.likelihoodScore);

  return (
    <CursorMuiBox
      data-testid={cause.conditionText}
      data-accessibilitylabel={`Tap for more info ${cause.conditionText} ${cause.likelihoodScore}`}
      flexDirection="row"
      box-shadow={1}
      display="flex"
    >
      <MuiBox flex={1}>
        <MuiTypography fontWeight={index === 0 ? FontWeight.bold : undefined}>
          {`${index + 1}. ${cause.conditionText}`}
        </MuiTypography>
      </MuiBox>
      <MuiBox flexDirection="row" display="flex" alignItems="center">
        <MuiTypography color={Color.link}>{buttonText || likelihood}</MuiTypography>
        <Icon name="keyboard-arrow-right" size={IconSize.base} color={Color.link} />
      </MuiBox>
    </CursorMuiBox>
  );
};

const LikelyCausesList = ({
  causes = [],
  emptyCaption,
  onItemPressed = () => null,
  buttonText
}: Props) => (
  <TouchablePanelGroup
    data={causes}
    emptyCaption={emptyCaption}
    keyExtractor={(cause: TriageCondition) => `${cause.condition}`}
    onItemPressed={(cause: TriageCondition) => onItemPressed(cause.condition)}
    renderItem={(cause: TriageCondition, index: number) => renderItem({ cause, index, buttonText })}
  />
);

LikelyCausesList.defaultProps = {
  emptyCaption:
    'Based on the information provided, Scout is unable to determine the potential cause of these symptoms. Please seek one of the care options below.'
};

export default LikelyCausesList;
