import React from 'react';
import { MuiGrid, MuiTypography, MuiDivider } from 'theme/material-ui';
import { Pharmacy } from 'store/amwell/types';

export interface Props {
  pharmacy: Pharmacy;
}

function ConnectCareVisitSummaryEndPharmacy({ pharmacy }: Props) {
  return (
    <MuiGrid container spacing={4}>
      <MuiGrid item xs={12}>
        <MuiTypography align="center" fontSize={16} variant="subtitle1" gutterBottom>
          Pharmacy
        </MuiTypography>
        <MuiDivider />
      </MuiGrid>
      <MuiGrid item xs={12}>
        <MuiTypography fontWeight={600} gutterBottom>
          {pharmacy.name}
        </MuiTypography>
        {pharmacy.address ? (
          <>
            <MuiTypography>{pharmacy.address.address1}</MuiTypography>
            <MuiTypography>{pharmacy.address.address2}</MuiTypography>
            <MuiTypography>
              {pharmacy.address.city}, {pharmacy.address.stateCode} {pharmacy.address.zipCode}
            </MuiTypography>
            <MuiTypography>Phone: {pharmacy.phone}</MuiTypography>
          </>
        ) : null}
      </MuiGrid>
    </MuiGrid>
  );
}

export default ConnectCareVisitSummaryEndPharmacy;
