import { useWindowSize } from 'hooks/useWindowSize';

const useVisibleSlidesCount = (totalSlides: number) => {
  const { width } = useWindowSize();

  if (width < 1200) {
    return 1;
  }

  return Math.min(2, totalSlides);
};

export default useVisibleSlidesCount;
