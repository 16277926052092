import React from 'react';
import { useHistory } from 'react-router-dom';

import Spacer from 'components/UI/Layout/Spacer';
import WebMuiModal from 'components/UI/Modals/WebMuiModal';

import { MuiButton, MuiBox, MuiTypography } from 'theme/material-ui';

import { Color } from 'modules/styles/colors';
import { Spacing, FontSize, FontWeight } from 'modules/styles/variables';
import { useWindowSize } from 'hooks/useWindowSize';

import { benefitList } from 'screens/Guest/constants';

export interface Props {
  open: boolean;
  handleToggle: () => void;
}

const smallDeviceWidth = 376;
const GUEST_MODAL_MAX_WIDTH = 496;

export const GuestBenefitsModal = ({ open, handleToggle }: Props) => {
  const history = useHistory();
  const deviceWidth = useWindowSize().width || window.innerWidth;
  const isSmallDevice = deviceWidth < smallDeviceWidth;

  const navigateToCreateAccount = () => {
    history.push(`/register`);
  };

  return (
    <WebMuiModal open={open} handleClose={handleToggle} isSmallDevice={isSmallDevice}>
      <MuiBox maxWidth={GUEST_MODAL_MAX_WIDTH}>
        <MuiTypography align="center" fontSize={FontSize.heading}>
          Creating an account gives you these additional benefits.
        </MuiTypography>
        <MuiBox
          mx={isSmallDevice ? Spacing.xSmall : Spacing.small}
          margin="auto"
          maxWidth={GUEST_MODAL_MAX_WIDTH * 0.85}
        >
          {benefitList.map(item => {
            return (
              <MuiBox key={item.title} pt={isSmallDevice ? Spacing.xSmall : Spacing.small}>
                <MuiTypography fontWeight={FontWeight.semibold} color={Color.primary}>
                  {item.title}
                </MuiTypography>
                <MuiTypography>{item.body}</MuiTypography>
              </MuiBox>
            );
          })}
        </MuiBox>
        <MuiBox
          mt={isSmallDevice ? Spacing.xSmall : Spacing.medium}
          justifyContent="center"
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <MuiButton
            color="primary"
            variant="contained"
            data-testid="create-account"
            onClick={navigateToCreateAccount}
            fullWidth
          >
            Yes, create an account
          </MuiButton>
          <Spacer size={isSmallDevice ? 'xsmall' : 'small'} />
          <MuiButton
            variant="text"
            color="primary"
            data-testid="continue-as-guest"
            onClick={handleToggle}
            fullWidth
          >
            Continue as guest
          </MuiButton>
        </MuiBox>
      </MuiBox>
    </WebMuiModal>
  );
};

export default GuestBenefitsModal;
