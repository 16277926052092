import SpinnerModal from 'components/UI/Spinner/SpinnerModal';
import React, { useEffect, useState } from 'react';
import { Alert } from 'components/Alert';
import { MuiBox, MuiButton, MuiDialog, MuiTextField } from 'theme/material-ui';

interface ValidationProps {
  children: React.ReactNode;
  resetAndClose: () => void;
  sendError: boolean;
  validateAction: (pin: string) => Promise<any>;
  validated?: boolean;
}

export default function ValidationDialog({
  children,
  resetAndClose,
  sendError,
  validated,
  validateAction
}: ValidationProps) {
  const [inputValue, setInputValue] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const submitAction = async () => {
    setIsSubmitting(true);
    await validateAction(inputValue.trim());
    setIsSubmitting(false);
  };

  useEffect(() => {
    if (validated) {
      resetAndClose();
    }
  }, [validated]);

  useEffect(() => {
    if (sendError) {
      Alert.alert('There was a problem sending your request', 'Please try again.', [
        {
          onPress: resetAndClose,
          text: 'OK'
        }
      ]);
    }
  }, [sendError]);

  return (
    <MuiDialog onClose={resetAndClose} open maxWidth="lg" disableBackdropClick>
      <MuiBox p={3}>
        {children}

        <MuiBox textAlign="center">
          <MuiBox mb={3}>
            <MuiTextField fullWidth onChange={e => setInputValue(e.target.value)} />
          </MuiBox>

          <MuiBox mb={1}>
            <MuiButton
              variant="contained"
              color="primary"
              fullWidth
              disabled={isSubmitting}
              onClick={submitAction}
            >
              Submit
            </MuiButton>
          </MuiBox>

          <MuiButton variant="outlined" onClick={resetAndClose} fullWidth>
            Go back
          </MuiButton>
        </MuiBox>
      </MuiBox>

      <SpinnerModal isLoading={isSubmitting} />
    </MuiDialog>
  );
}
