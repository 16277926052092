import React from 'react';
import styled from 'styled-components';

import {
  MuiBox,
  MuiFormControl,
  MuiInputLabel,
  MuiList,
  MuiMenuItem,
  MuiSelect,
  MuiTypography
} from 'theme/material-ui';

import { Facility } from 'store/getCare/liveCare/types';

import { FacilityListItem } from 'components/getCare/FacilityList/FacilityListItem';
import { filterOptions } from '../../constants';

const StyledMuiSelect = styled(MuiSelect)`
  width: 200px;
  > .MuiSelect-select.MuiSelect-select {
    padding-right: 0px;
  }
`;

interface AllOtherLocationsHeaderProps {
  showFilters: boolean;
  filterBy: string;
  filteredFacilities: Facility[];
  onPressFacility: (facility: Facility) => void;
  onPressFilter: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

export default function AllOtherLocations(props: AllOtherLocationsHeaderProps) {
  return (
    <MuiBox my={3}>
      <MuiBox
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        flexDirection="row"
        mt={4}
      >
        <MuiTypography variant="h5">All Other Locations</MuiTypography>

        {props.showFilters && (
          <>
            <MuiFormControl>
              <MuiInputLabel id="filterByLabel">Filter By</MuiInputLabel>

              <StyledMuiSelect
                labelId="filterByLabel"
                id="filterBy"
                value={props.filterBy}
                onChange={props.onPressFilter}
              >
                {filterOptions.map(o => (
                  <MuiMenuItem value={o.id} key={o.id}>
                    {o.label}
                  </MuiMenuItem>
                ))}
              </StyledMuiSelect>
            </MuiFormControl>
          </>
        )}
      </MuiBox>

      <MuiList>
        {props.filteredFacilities.map((facility: Facility) => {
          return (
            <FacilityListItem
              key={facility.locationId}
              facility={facility}
              onPress={props.onPressFacility}
            />
          );
        })}
      </MuiList>
    </MuiBox>
  );
}
