import React from 'react';
import { useSelector } from 'react-redux';
import { FilterDrawer } from 'components/FilterDrawer';
import { hospitalsDataSelector } from 'store/providerSearch/selectors';
import { FiltersStructure } from 'lib/hooks/ProviderSearch/types';
import { viewableCount } from '../constants';
import { FormCheckboxList } from '../shared';

export type AffiliatedHospitalsFilterProps = FiltersStructure['affiliatedHospitals'] & {
  defaultExpanded: boolean;
};

function AffiliatedHospitalsFilter({
  title,
  values,
  defaultExpanded
}: AffiliatedHospitalsFilterProps) {
  const hospitals = useSelector(hospitalsDataSelector);

  return (
    <FilterDrawer.Panel
      expandable={hospitals.length}
      defaultExpanded={defaultExpanded}
      title={title}
    >
      <FormCheckboxList initialCount={viewableCount} list={Object.values(values(hospitals))} />
    </FilterDrawer.Panel>
  );
}

export default AffiliatedHospitalsFilter;
