import { Divider } from '@material-ui/core';
import FlexBox from 'components/UI/Layout/FlexBox';
import { Svg } from 'components/UI/Svg';
import { ContactInfoItem, CONTACT_INFO, HELP_SCREEN_LINKS } from 'lib/constants/help';
import { Color } from 'modules/styles/colors';
import { FontSize, FontWeight, Spacing, IconSize } from 'modules/styles/variables';
import React from 'react';
import useNavigationAnalytics from 'hooks/useNavigationAnalytics';
import { RouteComponentProps } from 'react-router-dom';
import analyticsService, { AmplitudeEventData, AnalyticsEvent } from 'services/AnalyticsService';
import { MuiBox, MuiContainer, MuiList, MuiTypography, MuiLink } from 'theme/material-ui';

export const ContactUsScreen = ({ history }: RouteComponentProps) => {
  const contactUsTechnicalSupportMessageSent = useNavigationAnalytics(
    AnalyticsEvent.ContactUsTechnicalSupportMessageSent
  );
  const renderTechSupportAdditionalInfo = () => (
    <FlexBox alignItems="flex-end" vSpacingTop={Spacing.large}>
      <FlexBox flexDirection="row" alignItems="center">
        <Svg name="MailOutline" set="assets" size={IconSize.small} color={Color.secondary} />
        <MuiBox
          onClick={() => {
            contactUsTechnicalSupportMessageSent();
            history.push(HELP_SCREEN_LINKS.TechSupport.webPath);
          }}
          marginLeft={0.5}
        >
          <MuiTypography color={Color.secondary}>Send Message</MuiTypography>
        </MuiBox>
      </FlexBox>
      <MuiBox>
        <MuiTypography align="right">Response within 2 business days</MuiTypography>
      </MuiBox>
    </FlexBox>
  );

  const ContactItem = ({ icon, phone, title, subtitle }: ContactInfoItem) => {
    const phoneHref = `tel:${phone}`;
    const data: AmplitudeEventData = {
      currentUrl: '/u/help-support/contact'
    };

    const linkAction = (e: { currentTarget: { tagName: string; href: string } }) => {
      let phone = 'unknown';
      if (e && e.currentTarget && e.currentTarget.tagName === 'A') {
        phone = e.currentTarget.href.slice(4);
      }
      analyticsService.logEvent(AnalyticsEvent.SupportCalled, {
        phone_number: phone,
        ...data
      });
    };

    return (
      <>
        <Divider />
        <FlexBox
          flex={1}
          flexDirection="row"
          justifyContent="space-between"
          hSpacing={Spacing.large}
          vSpacing={Spacing.xLarge}
        >
          <MuiBox flex={2}>
            <MuiTypography fontWeight={FontWeight.regularbold}>{title}</MuiTypography>
          </MuiBox>
          <FlexBox alignItems="flex-end">
            <FlexBox flexDirection="row" alignItems="center">
              <MuiBox display="flex" justifyContent="center" width={Spacing.largeXLarge}>
                <Svg name={icon} size={IconSize.small} color={Color.secondary} />
              </MuiBox>
              <MuiBox>
                <MuiLink href={phoneHref} onClick={linkAction}>
                  <MuiTypography color={Color.secondary}>{phone}</MuiTypography>
                </MuiLink>
              </MuiBox>
            </FlexBox>
            <MuiBox>
              <MuiTypography align="right" style={{ whiteSpace: 'pre-line' }}>
                {subtitle}
              </MuiTypography>
            </MuiBox>
            {title === CONTACT_INFO.TechSupport.title ? renderTechSupportAdditionalInfo() : null}
          </FlexBox>
        </FlexBox>
      </>
    );
  };

  return (
    <FlexBox flex={1} backgroundColor={Color.baseColor} vSpacingTop={Spacing.xLarge}>
      <MuiContainer maxWidth="md">
        <MuiBox>
          <FlexBox vSpacingBottom={Spacing.xLarge}>
            <MuiTypography fontSize={FontSize.heading}>Here's how to reach us.</MuiTypography>
          </FlexBox>
          <MuiList>
            {Object.values(CONTACT_INFO).map(item => (
              <ContactItem key={item.title} {...item} />
            ))}
          </MuiList>
        </MuiBox>
      </MuiContainer>
    </FlexBox>
  );
};

export default ContactUsScreen;
