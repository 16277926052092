import styled from 'styled-components';

import IconSubhead from 'components/UI/Labels/IconSubhead';
import SmallLabel from 'components/UI/Labels/SmallLabel';
import Svg from 'components/UI/Svg/Svg';
import { Color } from 'modules/styles/colors';
import { BorderRadius, FontSize, IconSize, Spacing, ZIndex } from 'modules/styles/variables';
import { dfdDefaultTheme, MuiBox, MuiCard, MuiContainer, MuiDivider } from 'theme/material-ui';

interface Props {
  providerProfileImageUrl: boolean;
}

export const AvatarContainer = styled(MuiContainer)`
  &&& {
    display: flex;
    position: relative;
    justify-content: center;
    border-radius: ${Spacing.xxxLarge}px;
    box-shadow: ${(props: Props) =>
      props.providerProfileImageUrl ? '3px 3px 6px rgba(0, 0, 0, 0.4);' : 'none'};
    width: ${Spacing.xxxLarge}px;
    height: ${Spacing.xxxLarge}px;
  }
`;

export const Circle = styled.div`
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: ${IconSize.xLarge}px;
  height: ${IconSize.xLarge}px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: ${ZIndex.low};
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
  background-color: ${Color.white};
  border: 1px solid ${Color.gray};
  border-radius: ${BorderRadius.xLarge}px;
`;

export const InfoColumn = styled(MuiBox)`
  &&& {
    flex: 1;
    padding-left: ${Spacing.large}px;
  }
`;

export const InfoLabel = styled(SmallLabel)`
  color: ${Color.foreColor};
  font-size: ${FontSize.large}px;
  padding-bottom: ${Spacing.small}px;
`;

export const NumberOfComments = styled.p`
  color: ${Color.foreColor};
  margin: 0 0 ${Spacing.small}px 0;
`;

export const ProviderActions = styled(MuiCard)`
  &&& {
    max-width: 425px;
    margin: 0 auto;
  }
`;

export const ProviderDetails = styled(MuiBox)`
  ${dfdDefaultTheme.breakpoints.up('xl')} {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
`;

export const RatingsRow = styled(MuiBox)`
  margin-top: ${Spacing.medium}px;
`;

export const SelectHealth = styled(Svg)`
  left: 2px;
`;

export const StyledDivider = styled(MuiDivider)`
  && {
    background-color: ${Color.foreColor};
    margin: ${Spacing.large}px 0px;
  }
`;

export const StyledIconSubhead = styled(IconSubhead)`
  color: ${Color.foreColor};
  font-size: ${FontSize.base}px;
  padding-left: ${Spacing.small}px;
`;

export const StyledMuiContainer = styled(MuiContainer)`
  &&& {
    ${dfdDefaultTheme.breakpoints.down('md')} {
      margin-bottom: ${Spacing.large}px;
      padding-left: ${Spacing.medium}px;
      padding-right: ${Spacing.medium}px;
    }
    ${dfdDefaultTheme.breakpoints.up('md')} {
      margin: ${dfdDefaultTheme.spacing(2)}px auto;
    }
  }
`;

export const StyledMuiDivider = styled(MuiDivider)`
  && {
    background-color: ${Color.foreColor};
    margin: ${Spacing.large}px 0px;
    width: 90%;
  }
`;
