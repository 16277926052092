import { ListItem } from 'components/UI/ListItem/ListItem';
import React from 'react';
import { useDispatch } from 'react-redux';
import { ParsedEvent } from 'serverTypes/event';
import { getUpdate } from 'lib/updates/utils';
import { History } from 'lib/history';
import { routeLink } from 'store/deepLink/selectors';
import { patchEvent } from 'store/events/actions';
import { Alert } from 'components/Alert';

export interface UpdatesListItemProps {
  update: ParsedEvent;
  history: History;
}

enum UpdateItemsKey {
  VISIT_SUMMARY = 'visitSummary',
  IMAGING_REPORT = 'imagingReport',
  LAB_RESULT = 'labResult',
  PATHOLOGY_REPORT = 'pathologyReport',
  MEDICATIONS = 'medications'
}

export const UpdatesListItem = (props: UpdatesListItemProps) => {
  const { update, history } = props;
  const item = getUpdate(update);
  const dispatch = useDispatch();

  if (!item) return null;

  const navigateToUpdate = () => {
    const hasMatchingUpdate = Object.values(UpdateItemsKey).find(
      key => key === update.sourceResource
    );

    // Update event if there is no sourceId
    if (!update.sourceId || !hasMatchingUpdate) {
      // read is being verified in the action
      dispatch(patchEvent(update.id, { read: true }));
    }

    if (hasMatchingUpdate) {
      const { params, path } = routeLink({
        id: update.id,
        sourceId: update.sourceId,
        consumerId: update.consumerId,
        type: update.type,
        relatedData: update.relatedData
      });
      history.push(path, params);
    } else {
      Alert.alert(
        'Failed to find update details',
        'We failed to find details for the selected update'
      );
    }
  };

  return (
    <ListItem
      onPress={navigateToUpdate}
      leftIcon={item.icon.name}
      iconColor={item.icon.fill}
      iconSize={item.icon.size}
      showUnread={!item.read}
      title={item.label}
      body={item.eventDescription || item.medicationName || decodeURIComponent(item.labName || '')}
    />
  );
};

export default UpdatesListItem;
