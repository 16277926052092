import React, { ReactNode } from 'react';

import styled from 'styled-components';

import { borderRadius } from 'modules/styles/variables';

import { StyledPanel } from './TouchablePanel';

interface Props {
  firstChild?: boolean;
  lastChild?: boolean;
  children: ReactNode;
}

export const StyledPanelItem = styled(StyledPanel)`
  border-top-width: ${props => (props.firstChild ? 1 : 0)};
  border-top-left-radius: ${props => (props.firstChild ? borderRadius : 0)};
  border-top-right-radius: ${props => (props.firstChild ? borderRadius : 0)};
  border-bottom-left-radius: ${props => (props.lastChild ? borderRadius : 0)};
  border-bottom-right-radius: ${props => (props.lastChild ? borderRadius : 0)};
`;

const PanelItem = (props: Props) => (
  <StyledPanelItem firstChild={props.firstChild} lastChild={props.lastChild} {...props}>
    {props.children}
  </StyledPanelItem>
);

PanelItem.defaultProps = {
  firstChild: false,
  lastChild: false,
  style: {}
};

export default PanelItem;
