import React, { ComponentType } from 'react';
import Config from 'react-native-config';
import { connect, Matching } from 'react-redux';
import { Formik, FieldArray, getIn } from 'formik';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { oc } from 'ts-optchain';
import dayjs from 'dayjs';
import { KeyboardDatePicker } from '@material-ui/pickers';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { maskSSN, maskPhone } from 'modules/utils/MaskUtils';
import analyticsService, { AmplitudeEventData, AnalyticsEvent } from 'services/AnalyticsService';
import { RootState } from 'store/types';
import {
  faHouseholdSelector,
  faBasicInfoSelector
} from 'store/billing/financialAssistance/createApplication/selectors';
import {
  currentLocationPathNameSelector,
  previousLocationPathNameSelector
} from 'store/router/selectors';
import { setFAForm2Household } from 'store/billing/financialAssistance/createApplication/actions';
import {
  HouseholdForm,
  Member,
  BasicInfoForm
} from 'store/billing/financialAssistance/createApplication/types';
import { HouseholdIncomeSection, HouseholdSpouseTextInputFields } from './types';
import {
  HOUSEHOLD_INCOME_FIELDS,
  HOUSEHOLD_SPOUSE_FIELDS,
  HOUSEHOLD_MARITAL_STATUS
} from './constants';
import RequiredFieldsLegend, { StyledAsterisk } from 'components/RequiredFieldsLegend';
import { householdValidationSchema } from './validation';
import { convertToLowerKabobCase } from 'modules/utils/StringUtils';
import { MuiTypography, MuiButton } from 'theme/material-ui';
import { Color } from 'modules/styles/colors';
import { FontSize, FontWeight } from 'modules/styles/variables';
import { useHistory } from 'react-router-dom';
import DisableWhenWaiting from '../components/DisableWhenWaiting';
import { MuiRow } from './styled';
import { FAExitGuard } from './FAExitGuard';

interface Props {
  setFAForm2Household: typeof setFAForm2Household;
  householdData: HouseholdForm;
  basicInfoData: BasicInfoForm;
  currentUrl?: string;
  referringUrl?: string;
}

const initialValues: HouseholdForm = {
  maritalStatus: '',
  spouseFirstName: '',
  spouseMiddle: '',
  spouseLastName: '',
  spouseSSN: '',
  spouseBirthDate: '',
  spouseHomePhone: '',
  spouseWorkPhone: '',
  spouseCellPhone: '',
  spouseEmployer: '',
  members: [],
  hasIncome: true,
  grossIncome: '',
  spouseIncome: '',
  hasPension: true,
  pension: '',
  hasChildSupport: true,
  childSupport: '',
  hasAlimony: true,
  alimony: '',
  hasGrants: true,
  grants: '',
  hasOther: true,
  otherIncome: '',
  otherIncomeSource: '',
  grantPeriod: 'Monthly',
  otherPeriod: 'Monthly'
};

const FAHouseholdScreen = ({
  householdData,
  basicInfoData,
  setFAForm2Household,
  currentUrl,
  referringUrl
}: Props) => {
  const history = useHistory();
  const eventData: AmplitudeEventData = {
    currentUrl,
    referringUrl
  };

  const isInitialValid = () => {
    if (!Object.keys(householdData).length) return false;
    return householdValidationSchema.isValid(householdData);
  };

  const saveToStore = (values: HouseholdForm) => {
    const isEdit = oc(history.location.state).isEdit(false);
    setFAForm2Household(values);

    if (isEdit) {
      analyticsService.logEvent(AnalyticsEvent.HouseholdNextClicked, {
        type: 'edit',
        ...eventData
      });
      history.push('/u/fa-app/complete');
    } else {
      analyticsService.logEvent(AnalyticsEvent.HouseholdNextClicked, {
        type: 'apply',
        ...eventData
      });
      const navigateToURL =
        Config.FA_SHOW_ASSETS_AND_ASSISTANCE === 'enabled'
          ? '/u/fa-app/assets'
          : '/u/fa-app/other-funding';
      history.push(navigateToURL);
    }
  };

  const navigateToPrevious = (values: HouseholdForm, touched: {}, errors: {}) => {
    const err = [];
    Object.keys(touched).forEach(k => {
      if (k in errors) {
        err.push(k);
      }
    });
    if (!err.length) {
      setFAForm2Household(values);
      history.push('/u/fa-app/basic-info');
    } else {
      history.push('/u/fa-app');
    }
  };

  return (
    <>
      <FAExitGuard />
      <Formik
        initialValues={!isEmpty(householdData) ? householdData : initialValues}
        validationSchema={householdValidationSchema}
        onSubmit={values => saveToStore(values)}
        isInitialValid={isInitialValid() as boolean | ((props: {}) => boolean) | undefined}
      >
        {({ values, touched, errors, handleBlur, isValid, setFieldValue, handleSubmit }) => {
          const handleMaskPhone = (field: string, fieldName: string) => {
            const maskedValue = maskPhone(fieldName);
            setFieldValue(field, maskedValue);
          };

          const handleMaskSSN = (field: string, fieldName: string) => {
            const maskedValue = maskSSN(fieldName);
            setFieldValue(field, maskedValue);
          };

          const getFormError = (errorKey: string, touchKey?: string) => {
            const touchedValue = get(touched, touchKey || errorKey);
            const errorValue = get(errors, errorKey);

            return touchedValue && errorValue ? errorValue || null : null;
          };

          const length = values.otherIncomeSource ? values.otherIncomeSource.length : 0;

          return (
            <Container maxWidth="md">
              <Box py={4}>
                <Box mb={2}>
                  <Typography variant="h5">Responsible Party Household</Typography>
                </Box>
                <Divider />
                <Box mt={2}>
                  <RequiredFieldsLegend />
                  <MuiTypography
                    fontSize={FontSize.small}
                    fontWeight={FontWeight.normal}
                    color={Color.textLight}
                    align="left"
                  >
                    Please only enter numbers in the Amount fields. Enter “0” (zero) if you don’t
                    have that type of income.
                  </MuiTypography>
                </Box>
              </Box>
              <Box>
                <Box pb={0.75}>
                  <MuiRow>
                    <Typography variant="h6">Your Marital Status</Typography>
                    <Box pl={0.5}>
                      <StyledAsterisk />
                    </Box>
                  </MuiRow>
                </Box>
                <Box>
                  <FormControl component="fieldset">
                    <RadioGroup
                      value={values.maritalStatus}
                      onChange={async e => {
                        const { value } = e.target;
                        const relationship = oc(basicInfoData).relationship('');
                        await setFieldValue('maritalStatus', value);

                        if (value === 'M' && relationship === 'spouse') {
                          setFieldValue('spouseFirstName', basicInfoData.patientFirstName);
                          setFieldValue('spouseMiddle', basicInfoData.patientMiddle);
                          setFieldValue('spouseLastName', basicInfoData.patientLastName);
                          setFieldValue('spouseSSN', basicInfoData.patientSSN);
                          setFieldValue('spouseBirthDate', basicInfoData.patientBirthDate);
                        }

                        if (value === 'S') {
                          const resetFields = [
                            'spouseFirstName',
                            'spouseMiddle',
                            'spouseLastName',
                            'spouseSSN',
                            'spouseBirthDate',
                            'spouseHomePhone',
                            'spouseWorkPhone',
                            'spouseCellPhone',
                            'spouseEmployer',
                            'spouseIncome'
                          ];
                          resetFields.forEach(f => setFieldValue(f, ''));
                        }
                      }}
                    >
                      {HOUSEHOLD_MARITAL_STATUS.map(status => (
                        <FormControlLabel
                          key={`maritalStatus-${status.value}`}
                          value={status.value}
                          control={<Radio color="primary" />}
                          label={status.label}
                          data-testid={convertToLowerKabobCase(status.label, '-marital-status')}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                </Box>

                {values.maritalStatus === 'M' && (
                  <Box pt={3} mb={2}>
                    <Box pb={1.5}>
                      <Typography variant="h6">Spouse</Typography>
                    </Box>
                    <Grid container spacing={2}>
                      {HOUSEHOLD_SPOUSE_FIELDS.map(
                        ({
                          key,
                          fieldLabel,
                          fieldName,
                          maxLength,
                          editableDependency,
                          required,
                          textMask,
                          placeholder,
                          span
                        }: HouseholdSpouseTextInputFields) => {
                          const relationship = oc(basicInfoData).relationship('');
                          const editable = (dependencyKey?: string) => {
                            if (dependencyKey && relationship === 'spouse') {
                              return !basicInfoData[dependencyKey as keyof BasicInfoForm];
                            }
                            return true;
                          };

                          const onChangeText = (field: string) => (val: string) => {
                            if (textMask === 'maskSSN') {
                              return handleMaskSSN(field, val);
                            }
                            if (textMask === 'maskPhone') {
                              return handleMaskPhone(field, val);
                            }
                            return setFieldValue(field, val);
                          };

                          return (
                            <Grid key={key} item md={span || 12} sm={12}>
                              {fieldName !== 'spouseBirthDate' ? (
                                <TextField
                                  variant="outlined"
                                  label={fieldLabel}
                                  required={required}
                                  placeholder={placeholder}
                                  value={getIn(values, fieldName)}
                                  onChange={e => onChangeText(fieldName)(e.target.value)}
                                  onBlur={handleBlur(fieldName)}
                                  inputProps={{ maxlength: maxLength }}
                                  disabled={!editable(editableDependency)}
                                  error={!!getFormError(fieldName as keyof typeof values)}
                                  helperText={getFormError(fieldName as keyof typeof values)}
                                  fullWidth
                                />
                              ) : (
                                <KeyboardDatePicker
                                  disableToolbar
                                  fullWidth
                                  required
                                  variant="inline"
                                  inputVariant="outlined"
                                  placeholder={placeholder}
                                  label={fieldLabel}
                                  format="MM/DD/YYYY"
                                  value={values.spouseBirthDate ? values.spouseBirthDate : null}
                                  onChange={date => setFieldValue(fieldName, date, true)}
                                  onBlur={handleBlur(fieldName)}
                                  helperText={getFormError(fieldName as keyof typeof values)}
                                  error={!!getFormError(fieldName as keyof typeof values)}
                                  maxDate={dayjs(new Date())
                                    .subtract(18, 'years')
                                    .add(1, 'days')
                                    .toDate()}
                                />
                              )}
                            </Grid>
                          );
                        }
                      )}
                    </Grid>
                  </Box>
                )}

                <FieldArray
                  name="members"
                  validateOnChange
                  render={arrayHelpers => {
                    const members = oc(values).members([]);
                    return (
                      <>
                        {members.map((member: Member, index: number) => {
                          return (
                            <Box pt={3} mb={2}>
                              <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                pb={0.5}
                              >
                                <Typography variant="h6">Household Member {index + 1}</Typography>
                                <IconButton onClick={() => arrayHelpers.remove(index)}>
                                  <DeleteIcon />
                                </IconButton>
                              </Box>
                              <Grid container spacing={2}>
                                <Grid item xs={12}>
                                  <TextField
                                    variant="outlined"
                                    data-testid="full-name"
                                    label="Full Name"
                                    value={members[index].name}
                                    onChange={e =>
                                      arrayHelpers.replace(index, {
                                        ...member,
                                        name: e.target.value
                                      })
                                    }
                                    inputProps={{ maxlength: 25 }}
                                    error={!!getFormError(`members[${index}].name`, `name${index}`)}
                                    helperText={getFormError(
                                      `members[${index}].name`,
                                      `name${index}`
                                    )}
                                    fullWidth
                                  />
                                </Grid>
                                <Grid item md={6} sm={12}>
                                  <KeyboardDatePicker
                                    disableToolbar
                                    fullWidth
                                    variant="inline"
                                    inputVariant="outlined"
                                    date-testid="date-of-birth"
                                    label="Date of Birth"
                                    format="MM/DD/YYYY"
                                    value={
                                      members[index].birthDate ? members[index].birthDate : null
                                    }
                                    onChange={date =>
                                      setFieldValue(`members[${index}].birthDate`, date, true)
                                    }
                                    helperText={getFormError(
                                      `members[${index}].birthDate`,
                                      `birthDate${index}`
                                    )}
                                    error={
                                      !!getFormError(
                                        `members[${index}].birthDate`,
                                        `birthDate${index}`
                                      )
                                    }
                                  />
                                </Grid>
                                <Grid item md={6} sm={12}>
                                  <TextField
                                    variant="outlined"
                                    data-testid="relationship"
                                    label="Relationship"
                                    value={members[index].relationship}
                                    onChange={e =>
                                      arrayHelpers.replace(index, {
                                        ...member,
                                        relationship: e.target.value
                                      })
                                    }
                                    inputProps={{ maxlength: 25 }}
                                    error={
                                      !!getFormError(
                                        `members[${index}].relationship`,
                                        `relationship${index}`
                                      )
                                    }
                                    helperText={getFormError(
                                      `members[${index}].relationship`,
                                      `relationship${index}`
                                    )}
                                    fullWidth
                                  />
                                </Grid>
                              </Grid>
                            </Box>
                          );
                        })}
                        <Box pt={1}>
                          {members.length < 6 || !members.length ? (
                            <MuiButton
                              size="large"
                              variant="outlined"
                              color="primary"
                              data-testid="add-household-member"
                              onClick={() => {
                                const key = Math.floor(Math.random() * 10000);
                                arrayHelpers.push({ key });
                              }}
                            >
                              Add household member
                            </MuiButton>
                          ) : (
                            <Typography>
                              Note: List additional family members in &quot;Additional Comment&quot;
                              input on Assets page
                            </Typography>
                          )}
                        </Box>
                      </>
                    );
                  }}
                />

                <Box pt={5}>
                  <Box pb={0.75}>
                    <Typography variant="h6">Household Income</Typography>
                  </Box>
                  {HOUSEHOLD_INCOME_FIELDS.map((section: HouseholdIncomeSection) => (
                    <Box py={0.2} key={`income-${section.key}`}>
                      <Box>
                        {section.label} <StyledAsterisk />
                      </Box>
                      {getIn(values, section.checkboxFieldName) && (
                        <Box>
                          {oc(section)
                            .fields.textInputs([])
                            .map(
                              ({
                                fieldLabel,
                                fieldName,
                                conditionalOn,
                                maxLength,
                                placeholder
                              }) => {
                                const conditionalAmountBoolean =
                                  conditionalOn && getIn(values, conditionalOn) > 0;
                                const conditionalMarriedBoolean =
                                  conditionalOn && getIn(values, conditionalOn) === 'M';

                                return (
                                  (!conditionalOn ||
                                    conditionalAmountBoolean ||
                                    conditionalMarriedBoolean) && (
                                    <Box py={1} maxWidth="300px" key={fieldName}>
                                      <TextField
                                        data-testid={convertToLowerKabobCase(
                                          fieldName,
                                          '-text-field'
                                        )}
                                        variant="outlined"
                                        label={fieldLabel}
                                        placeholder={placeholder}
                                        value={getIn(values, fieldName)}
                                        onChange={e => {
                                          const { value } = e.target;
                                          setFieldValue(fieldName, value);
                                          if (fieldName === 'otherIncome' && value === '0') {
                                            setFieldValue('otherIncomeSource', '');
                                          }
                                        }}
                                        onBlur={handleBlur(fieldName)}
                                        inputProps={{ maxLength }}
                                        error={!!getFormError(fieldName)}
                                        helperText={getFormError(fieldName)}
                                        fullWidth
                                      />
                                      {fieldName === 'otherIncomeSource' ? (
                                        <Box pt={0.3} display="flex" justifyContent="flex-end">
                                          <Typography variant="caption">{length}/50</Typography>
                                        </Box>
                                      ) : null}
                                    </Box>
                                  )
                                );
                              }
                            )}
                          {oc(section).fields.radioButtons.length() && (
                            <Box pt={0.25}>
                              <FormControl component="fieldset">
                                <RadioGroup
                                  row
                                  value={get(
                                    values,
                                    oc(section).fields.radioButtons[0].groupName('')
                                  )}
                                  onChange={e => {
                                    setFieldValue(
                                      oc(section).fields.radioButtons[0].groupName(''),
                                      e.target.value
                                    );
                                  }}
                                >
                                  {oc(section)
                                    .fields.radioButtons([])
                                    .map(field => (
                                      <FormControlLabel
                                        key={field.fieldName}
                                        control={<Radio color="primary" />}
                                        label={field.fieldLabel}
                                        value={field.fieldName}
                                      />
                                    ))}
                                </RadioGroup>
                              </FormControl>
                            </Box>
                          )}
                        </Box>
                      )}
                    </Box>
                  ))}
                </Box>
              </Box>
              <Box py={4}>
                <Container maxWidth="xs">
                  <Box mb={1}>
                    <MuiButton
                      data-testid="household-next-button"
                      size="large"
                      variant="contained"
                      color="primary"
                      disabled={!isValid}
                      fullWidth
                      onClick={() => {
                        handleSubmit();
                      }}
                    >
                      Next
                    </MuiButton>
                  </Box>
                  <MuiButton
                    data-testid="household-previous-button"
                    size="large"
                    color="primary"
                    fullWidth
                    onClick={() => navigateToPrevious(values, touched, errors)}
                  >
                    Previous
                  </MuiButton>
                </Container>
              </Box>
            </Container>
          );
        }}
      </Formik>
      <DisableWhenWaiting />
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  householdData: faHouseholdSelector(state),
  basicInfoData: faBasicInfoSelector(state),
  currentUrl: currentLocationPathNameSelector(state),
  referringUrl: previousLocationPathNameSelector(state)
});

const mapDispatch = {
  setFAForm2Household
};

export default connect(
  mapStateToProps,
  mapDispatch
)(
  FAHouseholdScreen as ComponentType<
    Matching<
      {
        householdData: {} | HouseholdForm;
        basicInfoData: {} | BasicInfoForm;
      } & {
        setFAForm2Household: (validData: HouseholdForm) => { type: string; payload: HouseholdForm };
      },
      Props
    >
  >
);
