import React from 'react';
import styled from 'styled-components';
import { MuiCardHeader, CardHeaderProps, MuiTypography } from 'theme/material-ui';

export interface CardHeader extends CardHeaderProps {
  padding?: string;
  title?: string;
}

const Header = styled(MuiCardHeader)<{ padding: string }>`
  &.MuiCardHeader-root {
    padding: ${props => props.padding};
  }
`;

export const ConnectCareCardHeader = (props: CardHeader) => (
  <Header {...props}>
    <MuiTypography fontSize={24}>{props.title}</MuiTypography>
  </Header>
);

ConnectCareCardHeader.defaultProps = {
  padding: '8px 16px'
};
