import * as React from 'react';

export default {
  svg: (
    <g>
      <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <path
          d="M0 61.46c70.667-79.466 134-79.466 190 0 56 79.465 117.667 135.193 185 167.184v32.216H0V61.46z"
          fillOpacity={0.475087413}
          fill="#213245"
          opacity={0.474291256}
        />
        <path
          d="M0 60.334c77.678-49.62 133.345-34.388 167 45.698 33.655 80.085 102.989 121.175 208 123.269v32.559H0V60.334z"
          fillOpacity={0.474513767}
          fill="#004394"
          opacity={0.831289}
          transform="matrix(-1 0 0 1 375 0)"
        />
      </g>
    </g>
  ),
  viewBox: '0 0 375 262'
};
