import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { RootState } from 'store/types';
import { useHistory } from 'react-router-dom';
import analyticsService, { AnalyticsEvent } from 'services/AnalyticsService';
import {
  currentLocationPathNameSelector,
  previousLocationPathNameSelector
} from 'store/router/selectors';
import { LinkedConsumer } from 'store/profile/types';
import { otherActiveAccountsSelector } from 'store/account/selectors';
import get from 'lodash/get';

import Spacer from 'components/UI/Layout/Spacer';
import WebMuiModal from 'components/UI/Modals/WebMuiModal';

import { TRIAGE_ROUTES, TRIAGE_MODAL_TEXT } from 'screens/TriageGyant/constants';
import { Spacing } from 'modules/styles/variables';

import { resetTriageData } from 'store/triageGyant/actions';
import { forgetTriageSession } from 'storage/triageSessions/storage';

import { MuiButton, MuiBox, MuiTypography } from 'theme/material-ui';
import { SHARE_FEEDBACK_SCENARIOS } from 'lib/constants/help';
import { ROUTE } from 'components/ConnectCare/constants';
import { isEVisitFrontDoorSelector } from 'store/eVisit/selectors';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { startEVisitFrontDoorSession } from 'store/eVisit/actions';

export interface Props {
  isAuthenticated: boolean;
  open: boolean;
  handleToggle: () => void;
  currentUrl?: string;
  referringUrl?: string;
  proxyAccounts: LinkedConsumer[];
  resetTriageData: typeof resetTriageData;
  isEVisitFrontDoor: boolean;
}

export const TriageExitModal = ({
  open,
  handleToggle,
  isAuthenticated,
  currentUrl,
  referringUrl,
  proxyAccounts,
  resetTriageData,
  isEVisitFrontDoor
}: Props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const currentRoute = get(history, 'location.pathname', '');
  const [eVisitInfo] = useLocalStorage('EvisitCache', '');
  const handleExit = () => {
    analyticsService.logEvent(AnalyticsEvent.SymptomCheckerExited, {
      currentUrl,
      referringUrl
    });
    resetTriageData();
    localStorage.removeItem('EvisitCache');
    if (isAuthenticated) {
      history.push('/u/get-care-now');
    } else {
      history.push('/guest-home');
    }
  };

  const handleKeepGoing = () => {
    handleToggle();
  };

  const handleStartOver = () => {
    resetTriageData();
    forgetTriageSession();
    if (isEVisitFrontDoor) {
      const { localStateSelected, ...patientDTO } = JSON.parse(eVisitInfo);
      dispatch(startEVisitFrontDoorSession(patientDTO, localStateSelected));
      handleToggle();
    }
    if (!!proxyAccounts?.length) {
      history.push(TRIAGE_ROUTES.PROXY_SELECT);
    } else {
      handleToggle();
      history.push(TRIAGE_ROUTES.SYMPTOM_CHECKER);
      if (!isAuthenticated) history.go(0);
    }
  };

  const exitAndShareHandler = () => {
    history.push('/u/help-support/share-feedback', {
      scenario: SHARE_FEEDBACK_SCENARIOS.symptomChecker,
      screenLocation: TRIAGE_ROUTES.SYMPTOM_CHECKER,
      navigateTo: ROUTE.getCare
    });
    resetTriageData();
  };

  return (
    <WebMuiModal open={open} handleClose={handleToggle}>
      <MuiTypography align="center" variant="h6">
        {TRIAGE_MODAL_TEXT.title}
      </MuiTypography>

      <Spacer size="small" />
      <MuiTypography align="center">
        {currentRoute.includes(TRIAGE_ROUTES.RESULTS.BASE_URL) ? null : TRIAGE_MODAL_TEXT.subtitle}
      </MuiTypography>
      <Spacer size="medium" />

      <MuiBox mx={Spacing.medium}>
        <MuiButton
          color="primary"
          variant="contained"
          data-testid="handle-exit"
          onClick={handleExit}
          fullWidth
        >
          {TRIAGE_MODAL_TEXT.primaryButton}
        </MuiButton>{' '}
        <Spacer size="small" />
        <MuiButton
          color="primary"
          variant="outlined"
          data-testid="handle-exit-and-sharefeedback"
          onClick={exitAndShareHandler}
          fullWidth
        >
          {TRIAGE_MODAL_TEXT.textButton}
        </MuiButton>
        <Spacer size="small" />
        <MuiButton
          color="primary"
          variant="outlined"
          data-testid="handle-start-over"
          onClick={handleStartOver}
          fullWidth
        >
          Start over
        </MuiButton>
        <Spacer size="small" />
        <MuiButton
          color="primary"
          data-testid="handle-keep-going"
          onClick={handleKeepGoing}
          fullWidth
        >
          {TRIAGE_MODAL_TEXT.secondaryButton}
        </MuiButton>
      </MuiBox>
    </WebMuiModal>
  );
};

const mapDispatch = {
  resetTriageData
};

const mapState = (state: RootState) => ({
  currentUrl: currentLocationPathNameSelector(state),
  referringUrl: previousLocationPathNameSelector(state),
  proxyAccounts: otherActiveAccountsSelector(state),
  isEVisitFrontDoor: isEVisitFrontDoorSelector(state)
});

export default connect(mapState, mapDispatch)(TriageExitModal);
