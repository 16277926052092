import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as covidActions from 'store/CovidScreening/actions';
import { InsuranceCardData } from 'store/CovidScreening/types';
import { CovidScreeningInsuranceListSelector } from 'store/CovidScreening/selectors';

import { useCovidQuestionnaire } from 'screens/CovidTestGuest/hooks';
import { AuthType } from 'store/authentication/types';
import { CovidTitles } from 'lib/constants/covidScreening';
import { AnalyticsEvent } from 'services/AnalyticsService';
import useNavigationAnalytics from 'hooks/useNavigationAnalytics';

import { getInsuranceObjectFormatted } from '../../utils';

export default function useCovidInsuranceDropdown(hasToGetInsuranceList = true) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [insuranceListData, setInsuranceListData] = useState<InsuranceCardData[]>([]);
  const [insuranceSelected, setInsuranceSelected] = useState<InsuranceCardData | null>(null);

  const covidScreeningPreviousBtn = useNavigationAnalytics(AnalyticsEvent.CovidPrev, {
    title: CovidTitles.EXISTING_INSURANCE_SEARCH,
    authentication_type: AuthType.UNAUTHENTICATED
  });
  const covidScreeningNextBtn = useNavigationAnalytics(AnalyticsEvent.CovidNext, {
    title: CovidTitles.EXISTING_INSURANCE_SEARCH,
    authentication_type: AuthType.UNAUTHENTICATED
  });
  const covidInsuranceNotFoundNextBtn = useNavigationAnalytics(AnalyticsEvent.CovidNext, {
    title: CovidTitles.EXISTING_INSURANCE_SEARCH,
    can_not_find_insurance: 'Yes',
    authentication_type: AuthType.UNAUTHENTICATED
  });

  const { questionnaire, updateQuestionnaire } = useCovidQuestionnaire();
  const { isFetching, insuranceData } = useSelector(CovidScreeningInsuranceListSelector);

  useEffect(() => {
    if (hasToGetInsuranceList) {
      setIsLoading(true);
      fetchInsuranceListData();
    }
  }, []);

  useEffect(() => {
    if (!isFetching && insuranceData?.length) {
      setIsLoading(false);
      setInsuranceListData(insuranceData);
    }
  }, [isFetching]);

  const fetchInsuranceListData = () => {
    dispatch(covidActions.getInsuranceListUnauthenticated());
  };

  const updateInsurance = (insurance: InsuranceCardData | null) => {
    setInsuranceSelected(insurance);
  };

  const saveQuestionnaire = () => {
    const insuranceObjectData = getInsuranceObjectFormatted(insuranceSelected);

    const questionnaireObject = {
      ...questionnaire,
      ...insuranceObjectData
    };

    updateQuestionnaire(questionnaireObject);
  };

  const confirmInsurance = () => {
    saveQuestionnaire();

    if (insuranceSelected) return covidScreeningNextBtn();

    return covidInsuranceNotFoundNextBtn();
  };

  const goBack = () => {
    saveQuestionnaire();
    covidScreeningPreviousBtn();
  };

  return {
    confirmInsurance,
    goBack,
    healthPlanName: insuranceSelected?.plan_name ?? '',
    insuranceListData,
    insuranceSelected,
    isLoading,
    updateInsurance
  };
}
