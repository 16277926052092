import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import RequiredLabel from 'components/UI/Labels/RequiredLabel';
import FlexBox from 'components/UI/Layout/FlexBox';
import Spacer from 'components/UI/Layout/Spacer';
import SmallText from 'components/UI/Typography/SmallText';
import { Formik } from 'formik';
import {
  COVID_SCREENING_LEGAL_SEX_TEXT,
  COVID_SCREENING_PERSONAL_INFO_BODY_1,
  COVID_SCREENING_PERSONAL_INFO_BODY_2,
  COVID_AUTH_SCREENING_PERSONAL_INFO_BODY_2_OTP,
  COVID_SCREENING_PERSONAL_INFO_BODY_3B_OTP,
  SEX_OPTIONS,
  SexOptionsType,
  StateOptionsType,
  submitPersonalInfoValidation,
  FormValues
} from 'modules/constants/covidScreening/personalInfo';
import { LOCATION_STATES } from 'modules/constants/LocationStates';
import { Color } from 'modules/styles/colors';
import { maskFullDate, maskPhone, maskZip } from 'modules/utils/MaskUtils';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { NavigationScreenProps } from 'screens/navigation';
import CustomTextField from 'screens/Register/FormElements/CustomTextField';
import * as covidActions from 'store/CovidScreening/actions';
import {
  CovidScreeningPatientInfoSelector,
  CovidScreeningPrevPatientInfoSelector
} from 'store/CovidScreening/selectors';
import { Consumer, Insurance } from 'store/profile/types';
import {
  profileConsumerMilleniumInsuranceSelector,
  profileConsumerSelector
} from 'store/profile/selectors';
import { RootState } from 'store/types';
import styled from 'styled-components';
import {
  MuiBox,
  MuiDivider,
  MuiMenuItem,
  MuiSelect,
  MuiTypography,
  dfdDefaultTheme
} from 'theme/material-ui';
import { CovidTestButton } from '../Components/CovidTestButton';
import useStyles from '../Components/CovidTestButton/useStyles';
import { patientSelect } from 'modules/constants/covidScreening';
import Footer from 'components/common/Footer';
import { InfoTooltip } from 'components/InfoTooltip';
import { CovidTitles } from 'lib/constants/covidScreening';
import { AnalyticsEvent } from 'services/AnalyticsService';
import useNavigationAnalytics from 'hooks/useNavigationAnalytics';
import OTPValidationDialog from 'components/CovidTest/PersonalInformation/OTPValidationDialog';
import { Patient } from 'store/CovidScreening/patients/types';
import { PatientTypeData } from 'modules/constants/PersonalInformation';

export interface CovidPersonalInfoScreenProps extends NavigationScreenProps {
  selectUser: Consumer;
  setPatient: typeof covidActions.setPatient;
  activeInsurance: Insurance[];
  sendOTP: typeof covidActions.sendOTPAuthenticated;
  verifyOTP: typeof covidActions.verifyOTPAuthenticated;
  prevPatientInfo: Patient;
  currentUrl: string;
  referringUrl: string;
}

export function CovidPersonalInfoScreen({
  selectUser,
  setPatient,
  history,
  activeInsurance,
  sendOTP,
  verifyOTP,
  prevPatientInfo,
  currentUrl,
  referringUrl
}: CovidPersonalInfoScreenProps) {
  const [showOTPValidationDialog, setShowOTPValidationDialog] = useState(false);
  const [sendError, setSendError] = useState('');
  const [validated, setValidated] = useState('');

  const homeAddress = selectUser?.addresses?.find(el => el.type === 'HOMEADDR');

  const initialValues = {
    userFirstName: selectUser?.names?.[0]?.givenName || '',
    userMiddleName: selectUser?.names?.[0]?.middleName || '',
    userLastName: selectUser?.names?.[0]?.familyName || '',
    userDateOfBirth: maskFullDate(selectUser?.dateOfBirth) || '',
    userSSNDigits: selectUser?.ssn?.substring(selectUser?.ssn?.length - 4),
    userSex: selectUser?.genderType?.toUpperCase() || '',
    userAddressLine1: homeAddress?.line1 || '',
    userAddressLine2: homeAddress?.line2 || '',
    userCity: homeAddress?.city || '',
    userState: homeAddress?.state || '',
    userZip: maskZip(homeAddress?.zip) || '',
    userEmail: selectUser?.emails[0]?.address || '',
    userCellNumber: maskPhone(
      prevPatientInfo?.phones?.length
        ? prevPatientInfo?.phones[0].areaCode + prevPatientInfo?.phones[0].phoneNumber
        : selectUser?.phones?.length
        ? selectUser?.phones[0].areaCode + selectUser?.phones[0].phoneNumber
        : ''
    ),
    userOtherPhoneNumber: selectUser?.alternateNumber || ''
  };

  const ssnField = selectUser?.ssn?.includes('undefined')
    ? ''
    : selectUser?.ssn?.substring(selectUser?.ssn?.length - 4) || '';
  initialValues.userSSNDigits = ssnField;
  const classes = useStyles();

  const covidScreeningPreviousBtn = useNavigationAnalytics(AnalyticsEvent.CovidPrev, {
    title: CovidTitles.PERSONAL_INFORMATION
  });
  const sendOTPError = useNavigationAnalytics(AnalyticsEvent.CovidOTPError);
  const covidOTPVerifyBtnClicked = useNavigationAnalytics(AnalyticsEvent.CovidOTPVerify);
  const covidContinueBtnClicked = useNavigationAnalytics(AnalyticsEvent.CovidContinueClicked);
  const covidScreeningNextBtn = useNavigationAnalytics(AnalyticsEvent.CovidNext, {
    title: CovidTitles.PERSONAL_INFORMATION
  });

  const standardWidth = 'fullwidth';
  const standardHeight = `
height: 42px;
min-height: 42px;`;

  const StyledSelect = styled(MuiSelect)`
    ${standardWidth}
    ${standardHeight}
  select {
      padding: 0px 32px 0px 7px;
      ${standardHeight}
    }
  `;

  const saveToStore = (values: FormValues) => {
    const updatedPatientsData = {
      dateOfBirth: values?.userDateOfBirth,
      genderType: values?.userSex?.toUpperCase(),
      ssn: values?.userSSNDigits,
      alternateNumber: values?.userOtherPhoneNumber,
      cmrn: selectUser?.cmrn,
      icentraPersonId: selectUser?.icentraPersonId,
      names: [
        {
          givenName: values?.userFirstName,
          familyName: values?.userLastName,
          middleName: values?.userMiddleName
        }
      ],
      addresses: [
        {
          line1: values?.userAddressLine1,
          line2: values?.userAddressLine2,
          city: values?.userCity,
          state: values?.userState,
          zip: values?.userZip,
          type: selectUser?.addresses[0]?.type || PatientTypeData.ADDRESS
        }
      ],
      phones: [
        {
          areaCode: values?.userCellNumber.replace(/[^a-zA-Z0-9]/g, '').substring(0, 3),
          phoneNumber: values?.userCellNumber.replace(/[^a-zA-Z0-9]/g, '').substring(3, 10),
          countryCode: null,
          phoneType: selectUser?.phones[0]?.phoneType || PatientTypeData.PHONE
        }
      ],
      emails: [
        {
          address: values?.userEmail,
          type: selectUser.emails[0].type || PatientTypeData.EMAIL
        }
      ]
    };
    setPatient(updatedPatientsData);
  };

  const handleOTPVerification = async (values: FormValues, pin: number) => {
    const res = await verifyOTP(values, pin);
    if (res.type === 'COVID.VERIFY_OTP_FAIL') {
      covidOTPVerifyBtnClicked({
        covid19_OTP_validation: 'failure',
        errorCode: res?.error?.response?.data?.statusCode,
        errorMessage: res?.error?.response?.data?.statusDescription,
        currentUrl,
        referringUrl,
        title: CovidTitles.PERSONAL_INFORMATION,
        OTP_validation_type: 'SMS'
      });
      setValidated('FAIL');
    } else {
      covidOTPVerifyBtnClicked({
        covid19_OTP_validation: 'success',
        errorCode: res?.error?.response?.data?.statusCode,
        errorMessage: res?.error?.response?.data?.statusDescription,
        currentUrl,
        referringUrl,
        title: CovidTitles.PERSONAL_INFORMATION,
        OTP_validation_type: 'SMS'
      });
      setValidated('SUCCESS');
      saveToStore(values);
    }
  };

  const handleSendOTP = async (values: FormValues) => {
    if (
      selectUser?.phones[0]?.areaCode.concat(selectUser?.phones[0]?.phoneNumber) ===
        values?.userCellNumber.replace(/[^a-zA-Z0-9]/g, '') ||
      values?.userCellNumber.replace(/[^a-zA-Z0-9]/g, '') ===
        prevPatientInfo?.phones[0]?.areaCode.concat(prevPatientInfo?.phones[0]?.phoneNumber)
    ) {
      saveToStore(values);
      handleContinue('next');
      return;
    }
    setValidated('');
    const res = await sendOTP(values);
    if (res.type === 'COVID.SEND_PIN_OTP_FAIL') {
      sendOTPError({
        covid19_OTP_validation: 'Failed',
        errorCode: res?.error?.response?.data?.errorCode,
        errorMessage: res?.error?.response?.data?.errorDescription,
        currentUrl,
        referringUrl,
        title: CovidTitles.PERSONAL_INFORMATION,
        OTP_validation_type: 'SMS'
      });
      // maxPinAuthError = IDW021 and status 429
      // phoneError = IDW005 and status 500
      // emailError = IDW005 and status 422
      if (res.error?.response?.data?.errorCode === 'IDW005') {
        if (res.error?.response?.status === 500) {
          setSendError('phoneError');
        } else if (res.error?.response?.status === 422) {
          setSendError('emailError');
        }
      } else if (
        res.error?.response?.data?.statusCode === 'IDW021' &&
        res.error?.response?.status === 429
      ) {
        setSendError('maxPinAuthError');
      } else {
        setSendError('otherError');
      }
      setShowOTPValidationDialog(true);
    } else {
      setShowOTPValidationDialog(true);
    }
  };

  const handleSubmit = (values: FormValues) => {
    handleSendOTP(values);
  };

  const handleContinue = (type: string) => {
    if (type === 'next') {
      covidScreeningNextBtn();
    } else {
      covidContinueBtnClicked({
        currentUrl,
        referringUrl,
        title: CovidTitles.PERSONAL_INFORMATION,
        OTP_validation_type: 'SMS'
      });
    }
    if (activeInsurance[0]?.isActive) {
      history.push('/u/get-care-now/covid-screen/covid-existing-insurance');
    } else {
      history.push('/u/get-care-now/covid-screen/covid-insurance');
    }
  };

  const onCancelClick = () => {
    covidScreeningPreviousBtn();
    history.goBack();
  };

  const isValidData =
    initialValues.userFirstName &&
    initialValues.userLastName &&
    initialValues.userDateOfBirth &&
    initialValues.userSex &&
    initialValues.userAddressLine1 &&
    initialValues.userCity &&
    initialValues.userState &&
    initialValues.userZip &&
    initialValues.userEmail &&
    initialValues.userCellNumber;

  return (
    <>
      <Container maxWidth="md">
        <Spacer size="medium" />
        <MuiBox>
          <MuiTypography variant="h4">Personal Information</MuiTypography>
        </MuiBox>
        <Spacer size="medium" />
        <MuiBox>
          <MuiDivider />
        </MuiBox>
        <Formik
          enableReinitialize="true"
          validateOnMount
          initialValues={initialValues}
          validationSchema={submitPersonalInfoValidation}
          isInitialValid={isValidData}
        >
          {({ values, isValid, errors, touched, handleChange, setFieldTouched }) => {
            return (
              <>
                <Spacer size="small" />
                <FlexBox flexDirection="row">
                  <MuiTypography color={Color.offBlack}>
                    {COVID_SCREENING_PERSONAL_INFO_BODY_1}
                    <b>{COVID_SCREENING_PERSONAL_INFO_BODY_2}</b>
                    {COVID_AUTH_SCREENING_PERSONAL_INFO_BODY_2_OTP}
                    {COVID_SCREENING_PERSONAL_INFO_BODY_3B_OTP}
                  </MuiTypography>
                </FlexBox>
                <Spacer size="medium" />
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <RequiredLabel labelText="Legal first name" />
                    <CustomTextField
                      name="userFirstName"
                      variant="outlined"
                      value={values?.userFirstName}
                      onChange={handleChange('userFirstName')}
                      required
                      disabled={initialValues.userFirstName !== ''}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <RequiredLabel hideIndicator labelText="Legal middle name" />
                    <CustomTextField
                      name="userMiddleName"
                      variant="outlined"
                      value={values?.userMiddleName}
                      onChange={handleChange('userMiddleName')}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <RequiredLabel labelText="Legal last name" />
                    <CustomTextField
                      name="userLastName"
                      variant="outlined"
                      value={values?.userLastName}
                      onChange={handleChange('userLastName')}
                      required
                      disabled={initialValues.userLastName !== ''}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <RequiredLabel labelText="Date of birth" />
                    <CustomTextField
                      name="userDateOfBirth"
                      variant="outlined"
                      value={values?.userDateOfBirth}
                      onChange={handleChange('userDateOfBirth')}
                      required
                      disabled={initialValues.userDateOfBirth !== ''}
                      mask="date.monthDayYear"
                    />
                  </Grid>
                  {ssnField ? (
                    <Grid item xs={12} md={6}>
                      <RequiredLabel hideIndicator labelText="Last four digits of SSN" />
                      <CustomTextField
                        data-testid="personal-info-ssn"
                        name="userSSNDigits"
                        variant="outlined"
                        value={values?.userSSNDigits}
                        onChange={handleChange('userSSNDigits')}
                        disabled
                        mask="ssnFourDigits"
                      />
                    </Grid>
                  ) : (
                    <Grid item xs={12} md={6}></Grid>
                  )}
                  <Grid item xs={12} md={6}>
                    <FlexBox width="100%">
                      <FlexBox flexDirection="row" justifyContent="flex-start" alignItems="center">
                        <RequiredLabel labelText="Sex" />
                        <InfoTooltip>{COVID_SCREENING_LEGAL_SEX_TEXT}</InfoTooltip>
                      </FlexBox>
                      <StyledSelect
                        value={values.userSex}
                        onChange={handleChange('userSex')}
                        variant="outlined"
                        disabled={initialValues.userSex !== ''}
                      >
                        {SEX_OPTIONS.map((option: SexOptionsType) => (
                          <MuiMenuItem
                            style={{ whiteSpace: 'normal' }}
                            key={option.label}
                            value={option.value}
                          >
                            {option.label}
                          </MuiMenuItem>
                        ))}
                        {errors?.userSex ? (
                          <SmallText color="red">{errors?.userState}</SmallText>
                        ) : null}
                      </StyledSelect>
                    </FlexBox>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <RequiredLabel labelText="Address line 1" />
                    <CustomTextField
                      name="userAddressLine1"
                      variant="outlined"
                      value={values?.userAddressLine1}
                      onChange={handleChange('userAddressLine1')}
                      disabled={initialValues.userAddressLine1 !== ''}
                      onBlur={() => {
                        setFieldTouched('userAddressLine1');
                      }}
                      required
                      maxLength={50}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <RequiredLabel hideIndicator labelText="Address line 2" />
                    <CustomTextField
                      name="userAddressLine2"
                      variant="outlined"
                      value={values?.userAddressLine2}
                      onChange={handleChange('userAddressLine2')}
                      disabled={
                        initialValues.userAddressLine2 === '' ||
                        initialValues.userAddressLine2 !== ''
                      }
                      maxLength={50}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <RequiredLabel labelText="City" />
                    <CustomTextField
                      name="userCity"
                      variant="outlined"
                      value={values?.userCity}
                      onChange={handleChange('userCity')}
                      disabled={initialValues.userCity !== ''}
                      onBlur={() => {
                        setFieldTouched('userCity');
                      }}
                      required
                      maxLength={30}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FlexBox width="100%">
                      <RequiredLabel labelText="State" />
                      <StyledSelect
                        variant="outlined"
                        value={values?.userState}
                        onChange={handleChange('userState')}
                        disabled={initialValues.userState !== ''}
                      >
                        {LOCATION_STATES.map((option: StateOptionsType) => (
                          <MuiMenuItem
                            style={{ whiteSpace: 'normal' }}
                            key={option.label}
                            value={option.value}
                          >
                            {option.label}
                          </MuiMenuItem>
                        ))}
                      </StyledSelect>
                      {errors?.userState && touched.userState ? (
                        <SmallText color="red">{errors?.userState}</SmallText>
                      ) : null}
                    </FlexBox>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <RequiredLabel labelText="Zip" />
                    <CustomTextField
                      name="userZip"
                      variant="outlined"
                      value={values?.userZip}
                      onChange={handleChange('userZip')}
                      disabled={initialValues.userZip !== ''}
                      mask="zip"
                      required
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <RequiredLabel labelText="Cell phone number" />
                    <CustomTextField
                      name="userCellNumber"
                      variant="outlined"
                      data-testid="cell-phone-number"
                      value={values?.userCellNumber}
                      onChange={handleChange('userCellNumber')}
                      onBlur={() => {
                        setFieldTouched('userCellNumber');
                      }}
                      required
                      mask="phone"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <RequiredLabel hideIndicator labelText="Alternate phone number" />
                    <CustomTextField
                      name="userOtherPhoneNumber"
                      variant="outlined"
                      value={values?.userOtherPhoneNumber}
                      data-testid="user-other-phone-number"
                      onChange={handleChange('userOtherPhoneNumber')}
                      mask="phone"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <RequiredLabel labelText="Email" />
                    <CustomTextField
                      name="userEmail"
                      variant="outlined"
                      data-testid="user-email"
                      value={values?.userEmail}
                      onChange={handleChange('userEmail')}
                      onBlur={() => {
                        setFieldTouched('userEmail');
                      }}
                      required
                      maxLength={30}
                    />
                  </Grid>
                </Grid>
                <Spacer size="medium" />
                <MuiBox className={classes.steppersFooter}>
                  <CovidTestButton
                    fullWidth
                    data-testid="personal-info-next-btn"
                    color="primary"
                    variant="contained"
                    disabled={!isValid}
                    onClick={() => handleSubmit(values)}
                  >
                    Next
                  </CovidTestButton>
                  <Spacer size="xsmall" />
                  <CovidTestButton
                    variant="text"
                    data-testid="personal-info-previous-button"
                    fullWidth
                    onClick={() => onCancelClick(values)}
                  >
                    Previous
                  </CovidTestButton>
                  {showOTPValidationDialog && (
                    <OTPValidationDialog
                      sendError={sendError}
                      validated={validated}
                      userCellNumber={values.userCellNumber}
                      userEmail={values.userEmail}
                      closeAction={() => setShowOTPValidationDialog(false)}
                      validateAction={(pin: string) => handleOTPVerification(values, pin)}
                      continueAction={handleContinue}
                      sendAction={() => handleSendOTP(values)}
                      sendErrorAction={() => setSendError('')}
                      authenticated
                      {...{ currentUrl, referringUrl }}
                    >
                      <MuiBox mb={2}>
                        <MuiTypography
                          color={Color.primary}
                          fontSize={16}
                          fontWeight={dfdDefaultTheme.typography.fontWeightMedium}
                        >
                          Phone Number Verification
                        </MuiTypography>
                      </MuiBox>
                      <MuiBox mb={2}>
                        <MuiTypography
                          fontSize={16}
                          fontWeight={dfdDefaultTheme.typography.fontWeightRegular}
                        >
                          Enter the one-time passcode sent to
                        </MuiTypography>
                        <MuiBox display="flex" width="100%" flexDirection="row">
                          <MuiTypography
                            style={{
                              marginRight: 3
                            }}
                            fontSize={16}
                            fontWeight={dfdDefaultTheme.typography.fontWeightBold}
                          >
                            {values.userCellNumber}
                          </MuiTypography>
                          <MuiTypography
                            fontSize={16}
                            fontWeight={dfdDefaultTheme.typography.fontWeightRegular}
                          >
                            to proceed
                          </MuiTypography>
                        </MuiBox>
                      </MuiBox>
                      <MuiBox textAlign="center" mb={2}>
                        {validated === 'FAIL' && (
                          <MuiTypography fontSize={16} color="red">
                            Incorrect code. Please try again
                          </MuiTypography>
                        )}
                      </MuiBox>
                    </OTPValidationDialog>
                  )}
                </MuiBox>
              </>
            );
          }}
        </Formik>
        <Footer
          headerText={patientSelect.GET_PATIENTS.HELPLINE.CovidInfo}
          link={patientSelect.GET_PATIENTS.HELPLINE.CovidPatientSelectHelpline}
          linkName={patientSelect.GET_PATIENTS.HELPLINE.CovidPatientSelectHelpline}
          helplineHours={patientSelect.GET_PATIENTS.HELPLINE.CovidHelplineHours}
          title={CovidTitles.PERSONAL_INFORMATION}
        />
      </Container>
    </>
  );
}

const mapStateToProps = (state: RootState) => ({
  selectUser: CovidScreeningPatientInfoSelector(state),
  consumer: profileConsumerSelector(state),
  activeInsurance: profileConsumerMilleniumInsuranceSelector(state),
  prevPatientInfo: CovidScreeningPrevPatientInfoSelector(state)
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setPatient: (Patient: object) => dispatch(covidActions.setPatient(Patient)),
  sendOTP: (values: FormValues) => dispatch(covidActions.sendOTPAuthenticated(values)),
  verifyOTP: (values: FormValues, pin: number) =>
    dispatch(covidActions.verifyOTPAuthenticated(values, pin))
});

export default connect(mapStateToProps, mapDispatchToProps)(CovidPersonalInfoScreen);
