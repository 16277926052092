import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { toSentenceCase } from 'modules/utils/StringUtils';
import { MuiButton } from 'theme/material-ui';

interface Props {
  title: string;
  description: string;
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  confirmLabel: string;
  cancelLabel: string;
}

const ConfirmDialog = ({
  title,
  description,
  isOpen,
  onCancel,
  onConfirm,
  confirmLabel,
  cancelLabel
}: Props) => (
  <Dialog open={isOpen} onClose={onCancel} fullWidth>
    <DialogTitle data-testid="dialog-title">{title}</DialogTitle>
    <DialogContent>
      <DialogContentText data-testid="dialog-body" component="span">
        {description}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <MuiButton data-testid="cancel-button" onClick={onCancel} color="primary">
        {toSentenceCase(cancelLabel)}
      </MuiButton>
      <MuiButton
        data-testid="confirm-button"
        onClick={onConfirm}
        color="primary"
        variant="contained"
      >
        {toSentenceCase(confirmLabel)}
      </MuiButton>
    </DialogActions>
  </Dialog>
);

export default ConfirmDialog;
