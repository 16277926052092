import React from 'react';

import { Color } from 'modules/styles/colors';

export const styles = {
  fill: 'none',
  stroke: Color.gray,
  strokeWidth: 2,
  strokeLinecap: 'round',
  strokeLinejoin: 'round',
  strokeMiterlimit: 10
} as const;

export default {
  svg: (
    <g>
      <path
        {...styles}
        d="M47.27,17.39c0,0-11.05-0.04-12.26-0.06c-0.23,0.04-4.39,3.28-4.39,3.28s0-8.81,0-9.08V4.49
  	c0-0.6,0.49-1.09,1.09-1.09h15.5c0.6,0,1.09,0.49,1.09,1.09v12.15C48.3,17.23,47.87,17.39,47.27,17.39z"
      />
      <path
        {...styles}
        d="M20.91,7.02v11.61c0,1.09-0.89,1.98-1.98,1.98H5.09c-1.09,0-1.98-0.89-1.98-1.98V7.02
  	c0-1.09,0.89-1.98,1.98-1.98h13.84C20.02,5.04,20.91,5.93,20.91,7.02z"
      />
      <path {...styles} d="M7.64,3.08v4.04V3.08z" />
      <path {...styles} d="M16.45,3.02v4.04V3.02z" />
      <line {...styles} x1="9.34" y1="10.32" x2="14.71" y2="15.69" />
      <line {...styles} x1="14.71" y1="10.32" x2="9.34" y2="15.69" />
    </g>
  ),
  viewBox: '0 0 24 24'
};
