import React, { memo, ReactNode } from 'react';
import styled from 'styled-components';

import { Svg } from 'components/UI/Svg';
import { MuiAvatar } from 'theme/material-ui';

import { Color } from 'modules/styles/colors';
import { IconSize } from 'modules/styles/variables';

export interface Props {
  accessibilityLabel: string;
  avatarUrl: string | ReactNode;
  backgroundColor?: string;
  borderColor?: string;
  margin?: string;
  size: number;
  color: string;
}
export const StyledSvg = styled(Svg)`
  visibility: visible;
`;

export const StyledAvatar = styled(MuiAvatar)<Props>`
  &.MuiAvatar-root {
    width: ${({ size }) => `${size}px`};
    height: ${({ size }) => `${size}px`};
    border-radius: ${({ size }) => `${size / 2}px`};
    border: ${({ borderColor }) => `2px solid ${borderColor}`};
    margin: ${({ margin }) => `${margin}`};
  }
  &.MuiAvatar-colorDefault {
    background-color: ${({ backgroundColor }) => `${backgroundColor}`};
  }
`;

export function ProviderAvatar({
  accessibilityLabel,
  avatarUrl,
  backgroundColor,
  borderColor,
  size,
  margin,
  color
}: Props) {
  const content = (
    <StyledAvatar
      display="flex"
      flexDirection="row"
      alignContent="center"
      justifyContent="center"
      textAlign="center"
      accessibilityLabel={accessibilityLabel}
      backgroundColor={backgroundColor}
      borderColor={!avatarUrl ? borderColor : null}
      margin={margin}
      size={size}
      data-testid="provider-avatar"
    >
      <StyledSvg set="assets" size={size} color={color} name={avatarUrl} />
    </StyledAvatar>
  );

  return content;
}

ProviderAvatar.defaultProps = {
  accessibilityLabel: 'provider avatar',
  backgroundColor: Color.grayHue7,
  color: Color.grayHue7,
  borderColor: Color.primary,
  margin: 'auto',
  size: IconSize.large
};

export default memo(ProviderAvatar);
