import React, { useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { isMobileBrowser } from 'lib/browser';
import { ReduxAxiosAction } from 'redux-axios-middleware';

import { FontWeight, FontSize, Spacing, IconSize } from 'modules/styles/variables';
import { Color } from 'modules/styles/colors';
import { Coordinate } from 'modules/types/common';

import useNavigationAnalytics from 'hooks/useNavigationAnalytics';

import BadgePanelList from 'components/UI/Panel/BadgePanelList';
import Spacer from 'components/UI/Layout/Spacer';
import NavigateToProtectedRouteModal from 'components/UI/Modals/NavigateToProtectedRouteModal';
import Spinner from 'components/UI/Spinner/Spinner';

import analyticsService, { AnalyticsEvent } from 'services/AnalyticsService';
import LinkingServices from 'services/LinkingServices';

import { navigateToProtectedRoute } from 'store/authentication/actions';
import { TriageEndpoint } from 'store/triageGyant/types';

import { RootState } from 'store/types';
import { isDfdAuthenticatedSelector } from 'store/authentication/selectors';
import * as bookingTypes from 'store/booking/types';
import * as bookingActions from 'store/booking/actions';
import * as bookingSelectors from 'store/booking/selectors';
import * as getCareActions from 'store/getCare/liveCare/actions';
import { Facility, AllFacilities } from 'store/getCare/liveCare/types';
import {
  allFacilitiesSelector,
  liveCareErrorSelector,
  isFetchingSelector
} from 'store/getCare/liveCare/selectors';
import { geolocationCoordsSelector } from 'store/geolocation/selectors';
import { slcGeoCoords } from 'store/geolocation/constants';

import { getButtonText, getPhoneDetails, PhoneType } from 'lib/triage/utils';
import { MuiBox, MuiTypography, CursorMuiBox, MuiButton } from 'theme/material-ui';

import PhoneNumberModal from 'screens/TriageGyant/ResultsScreens/Results/DisplayPhoneNumberOnDesktopModal';
import { FacilityDetailsDialog } from 'screens/GetCare/LiveCare/FacilityDetails';
import { EVISITS_ROUTES } from 'screens/EVisits/constants';
import { profileConsumerHasActiveSHInsuranceSelector } from 'store/profile/selectors';
import Config from 'react-native-config';
import { COVID19_CHECKER_CARD } from 'lib/triage/constants';

interface CareOptionsListProps {
  endpoints: TriageEndpoint[];
  emptyCaption?: string;
  isAuthenticated: boolean;
  logCareOptionSelected?: Function;
  navigateToProtectedRoute: typeof navigateToProtectedRoute;
  getFacilities: typeof getCareActions.getFacilities;
  facilities: AllFacilities;
  facilitiesError: typeof Error;
  facilitiesLoading: boolean;
  patients: bookingTypes.Patient[];
  setPatient: typeof bookingActions.setPatient;
  getPatients: typeof bookingActions.getPatients;
  patientsLoading: boolean;
  patientsError: typeof Error;
  geoLocationCoords: Coordinates;
  validateSelectedPatientInsurance: typeof bookingActions.validateSelectedPatientInsurance;
  hasActiveSHInsurance: boolean;
}

const getLoadingState = (
  isLoading: boolean | null,
  error: boolean | null,
  refetch: (() => ReduxAxiosAction) | (() => Promise<void>) | null
) => {
  if (isLoading) {
    return (
      <MuiBox mt={1} data-testid="handle-loading" justifyContent="center" display="flex" flex={1}>
        <Spinner size={IconSize.small} />
      </MuiBox>
    );
  }
  if (error) {
    return (
      <CursorMuiBox
        data-testid="handle-error"
        flexDirection="row"
        textAlign="center"
        mt={1}
        onClick={refetch}
      >
        <MuiTypography>An error occurred</MuiTypography>
        <MuiTypography color={Color.link}>Reload</MuiTypography>
      </CursorMuiBox>
    );
  }
  return null;
};

const renderItem = (
  endpoint: TriageEndpoint,
  isLoading: boolean | null,
  error: boolean | null,
  refetch: (() => ReduxAxiosAction) | (() => Promise<void>) | null
) => {
  const { title, displayText, description } = endpoint;
  const { extraText, buttonText } = getButtonText(title);
  return (
    <MuiBox borderRadius={100} p={3} data-testid={title}>
      <MuiTypography color={Color.primary} fontSize={FontSize.large} fontWeight={FontWeight.bold}>
        {displayText}
      </MuiTypography>
      <Spacer size="xsmall" />
      {description ? (
        <>
          <MuiTypography fontSize={FontSize.base}>{description}</MuiTypography>
          <Spacer size="xsmall" />
        </>
      ) : null}
      {!error && (
        <>
          {extraText ? <MuiTypography>{extraText}</MuiTypography> : null}
          <Spacer size="small" />
          <MuiBox>
            <MuiButton variant="contained" type="button" color="primary">
              {buttonText}
            </MuiButton>
          </MuiBox>
        </>
      )}
      <MuiBox flexDirection="row" justifyContent="center" display="flex" whiteSpace="pre-wrap">
        {getLoadingState(isLoading, error, refetch)}
      </MuiBox>
    </MuiBox>
  );
};

export const CareOptionsList = ({
  endpoints,
  emptyCaption,
  isAuthenticated,
  hasActiveSHInsurance = false,
  logCareOptionSelected = () => {},
  navigateToProtectedRoute,
  patients,
  setPatient,
  getFacilities,
  getPatients,
  patientsError,
  facilities,
  patientsLoading,
  facilitiesLoading,
  facilitiesError,
  geoLocationCoords,
  validateSelectedPatientInsurance
}: CareOptionsListProps) => {
  const history = useHistory();

  const [phoneModalVisible, setPhoneModalVisible] = useState(false);
  const togglePhoneModal = () => setPhoneModalVisible(!phoneModalVisible);
  const [phoneType, setPhoneType] = useState('' as PhoneType);

  const covidTestGetStartedClicked = useNavigationAnalytics(
    AnalyticsEvent.CovidTestGetStartedClicked
  );

  useEffect(() => {
    if (isAuthenticated) {
      getPatients();
    }
  }, []);

  const [primaryChildrensFacilityInfo, setPrimaryChildrensFacilityInfo] = useState<
    undefined | Facility
  >();
  const [primaryChildrensModalOpen, setPrimaryChildrensModalOpen] = useState(false);

  const primaryChildrensData = useMemo(() => {
    return facilities?.emergencyRooms?.find(
      a => a.locationId === '3aee0d22-ccbb-45ce-b2d6-b26dd3896e4d'
    );
  }, [facilities]);
  const showsChildEmergencyRoom = useMemo(() => {
    return !!endpoints?.find(a => a.title === 'childEmergencyRoom');
  }, [endpoints]);

  const fetchFacilities = async () => {
    // Note: Service fails without coords. Provide arbitrary slc coords
    getFacilities(geoLocationCoords || slcGeoCoords, false, 'Salt Lake City');
  };

  useEffect(() => {
    if (showsChildEmergencyRoom && !primaryChildrensData) {
      fetchFacilities();
    }
    if (!!primaryChildrensData) {
      setPrimaryChildrensFacilityInfo(primaryChildrensData);
    }
  }, [primaryChildrensData]);

  const showPrimaryChildrens = () => {
    setPrimaryChildrensModalOpen(true);
  };

  const gotoBooking = () => {
    if (patients.length === 1) {
      setPatient(patients[0]);
      validateSelectedPatientInsurance();
      navigateToProtectedRoute('/u/get-care-now/booking/specialty-or-my-doctor-select');
    } else {
      navigateToProtectedRoute('/u/get-care-now/booking/patient-select');
    }
    analyticsService.logEvent(AnalyticsEvent.BookAppointmentStarted, { source: 'Symptom Checker' });
  };

  const navigateToScreen = (screen: string) => {
    history.push(screen);
  };

  const navigateToProtectedScreen = (screen: string) => {
    navigateToProtectedRoute(screen);
  };

  const initiateCall = (phoneNumber: string) => {
    LinkingServices.callPhoneNumber(phoneNumber);
  };

  const handleCall = (phoneType: PhoneType) => {
    if (isMobileBrowser()) {
      const { number } = getPhoneDetails(phoneType);
      if (number) initiateCall(number);
    } else {
      setPhoneType(phoneType);
      togglePhoneModal();
    }
  };

  const goToEvisit = () => {
    analyticsService.logEvent(AnalyticsEvent.SymptomCheckerCompleted, {
      care_options_presented: ['E-Visit']
    });
    return navigateToScreen(EVISITS_ROUTES.OVERVIEW);
  };

  const goToCovidTesting = () => {
    if (isAuthenticated) {
      covidTestGetStartedClicked();
      return history.push('/u/get-care-now/covid-screen/patient-select');
    }
    return window.open(COVID19_CHECKER_CARD.url, '_blank');
  };

  const getNavigation = (title: string) => {
    switch (title) {
      case 'selfCareAtHome':
        return navigateToProtectedScreen('/u/get-care-now/connect-care');
      case 'asyncTelemedicine':
        return goToEvisit();
      case 'realTimeVideo':
        return navigateToProtectedScreen('/u/get-care-now/connect-care/form/location');
      case 'primaryCarePhysician':
        return gotoBooking();
      case 'urgentCare':
        return isAuthenticated
          ? navigateToProtectedScreen('/u/get-care-now/live-care/instacares')
          : navigateToScreen('/get-care-now/live-care/instacares');
      case 'childEmergencyRoom':
        return showPrimaryChildrens();
      case 'emergencyRoom':
        return isAuthenticated
          ? navigateToProtectedScreen('/u/get-care-now/live-care/emergencyRooms')
          : navigateToScreen('/get-care-now/live-care/emergencyRooms');
      case 'call911':
        return handleCall('emergency');
      case 'covidCallCenter':
        return Config.COVID_SCREENING_FEATURE_FLAG === 'enabled'
          ? goToCovidTesting()
          : window.open(COVID19_CHECKER_CARD.url, '_blank');
      case 'emotionalSupport':
        return handleCall('emotionalSupport');
      case 'nurseLine':
        return handleCall('nurseLine');
      default:
        return null;
    }
  };

  const getFetch = (title: string) => {
    switch (title) {
      case 'primaryCarePhysician':
        return getPatients;
      case 'childEmergencyRoom':
        return fetchFacilities;
      default:
        return null;
    }
  };

  const getError = (title: string) => {
    switch (title) {
      case 'primaryCarePhysician':
        return isAuthenticated && !!patientsError;
      case 'childEmergencyRoom':
        return !!facilitiesError;
      default:
        return null;
    }
  };

  const getLoading = (title: string) => {
    switch (title) {
      case 'primaryCarePhysician':
        return isAuthenticated && patientsLoading;
      case 'childEmergencyRoom':
        return facilitiesLoading;
      default:
        return null;
    }
  };

  const onItemPressed = (endpoint: TriageEndpoint) => {
    const { title } = endpoint;
    const loading = getLoading(title);
    const error = getError(title);

    if (loading || error) return;

    logCareOptionSelected(endpoint);
    getNavigation(endpoint.title);
  };

  const EVisitEndPoint = 'asyncTelemedicine';

  const filterEndPoints = () => {
    if (hasActiveSHInsurance && Config.EVISITS === 'enabled') {
      return endpoints;
    }
    return endpoints.filter(endpoint => endpoint.title !== EVisitEndPoint);
  };

  const insurancedEndPoints = filterEndPoints();

  return (
    <>
      <PhoneNumberModal
        visible={phoneModalVisible}
        handleToggle={togglePhoneModal}
        phoneType={phoneType}
        initiateCall={initiateCall}
      />
      <NavigateToProtectedRouteModal />
      <FacilityDetailsDialog
        facility={primaryChildrensFacilityInfo}
        open={primaryChildrensModalOpen}
        onClose={() => setPrimaryChildrensModalOpen(false)}
      />
      <BadgePanelList
        data={insurancedEndPoints}
        emptyCaption={emptyCaption}
        keyField="title"
        onItemPressed={onItemPressed}
        renderItem={(endpoint: TriageEndpoint) => {
          const { title } = endpoint;
          return renderItem(endpoint, getLoading(title), getError(title), getFetch(title));
        }}
        badgeHeight="auto"
        numColumns={2}
        itemSpacing={Spacing.large}
      />
    </>
  );
};

CareOptionsList.defaultProps = {
  emptyCaption: 'Unable to load Care Options',
  endpoints: []
};

const mapStateToProps = (state: RootState) => ({
  hasActiveSHInsurance: profileConsumerHasActiveSHInsuranceSelector(state),
  isAuthenticated: isDfdAuthenticatedSelector(state),
  patients: bookingSelectors.patientsDataSelector(state),
  patientsLoading: bookingSelectors.patientsFetchingSelector(state),
  patientsError: bookingSelectors.patientsErrorSelector(state),
  facilities: allFacilitiesSelector(state),
  facilitiesLoading: isFetchingSelector(state),
  facilitiesError: liveCareErrorSelector(state),
  geoLocationCoords: geolocationCoordsSelector(state)
});

const mapDispatch = (dispatch: Function) => ({
  navigateToProtectedRoute: (route: string) => dispatch(navigateToProtectedRoute(route)),
  setPatient: (patient: bookingTypes.Patient) => dispatch(bookingActions.setPatient(patient)),
  getFacilities: (coords: Partial<Coordinate>, useCache: boolean, city: string) =>
    dispatch(getCareActions.getFacilities(coords, useCache, city)),
  getPatients: () => dispatch(bookingActions.getPatients()),
  validateSelectedPatientInsurance: () =>
    dispatch(bookingActions.validateSelectedPatientInsurance())
});

export default connect(mapStateToProps, mapDispatch)(CareOptionsList);
