import React from 'react';
import FlexBox from 'components/UI/Layout/FlexBox';
import { Spacing, IconSize } from 'modules/styles/variables';
import { MuiTypography, MuiButton } from 'theme/material-ui';
import Divider from 'components/UI/Divider';
import { Svg } from 'components/UI/Svg/';
import { history } from 'lib/history';
import { useLanguageSwitcher } from 'lib/hooks/useLanguageSwitcher';
import { MyCareTeamHeader as translations } from 'lib/constants/translations/components/dashboard';
import { Color } from 'modules/styles/colors';

const MyCareTeamHeader = () => {
  const screenText = useLanguageSwitcher(translations);
  return (
    <>
      <FlexBox flexDirection="row" height={50} alignItems="center">
        <FlexBox spacing={Spacing.large} flexDirection="row" flex={1}>
          <MuiTypography variant="subtitle2">{screenText?.title}</MuiTypography>
        </FlexBox>
        <MuiButton
          color="primary"
          variant="text"
          onClick={() => history.push('/u/find-a-doctor')}
          data-testid="find-care-team-button"
          startIcon={
            <Svg name="search" color={Color.secondary} set="material" size={IconSize.base} />
          }
        >
          {screenText?.search_placeholder}
        </MuiButton>
      </FlexBox>
      <Divider />
    </>
  );
};

export default MyCareTeamHeader;
