import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ProxyFormNav } from 'screens/ProxyForm/navigation/router';

import Spacer from 'components/UI/Layout/Spacer';
import Svg from 'components/UI/Svg/Svg';

import { Spacing, FontSize } from 'modules/styles/variables';
import { MuiBox, MuiContainer, MuiTypography, MuiButton } from 'theme/material-ui';

import { RootState } from 'store/types';
import * as routerSelectors from 'store/router/selectors';
import analyticsService, { AmplitudeEventData, AnalyticsEvent } from 'services/AnalyticsService';
import { AppName, getAppName } from 'modules/utils/ConfigUtils';

export interface Props extends RouteComponentProps {
  currentUrl?: string;
  referringUrl?: string;
}

export const ProxyInfoScreen = ({ currentUrl, referringUrl, history }: Props) => {
  const handleRequest = () => {
    const eventData: AmplitudeEventData = {
      currentUrl,
      referringUrl,

      request_access_type: 'Minor'
    };

    analyticsService.logEvent(AnalyticsEvent.ProxyAccessStart, eventData);
    history.push(ProxyFormNav.PROXY_FORM);
  };

  return (
    <MuiBox
      alignItems="center"
      display="flex"
      flexDirection="column"
      my={Spacing.medium}
      mx={Spacing.small}
    >
      <Svg name="AddPerson" height="180" width="180" />
      <MuiTypography align="center" fontSize={FontSize.heading}>
        Account access for minors in your care.
      </MuiTypography>
      <MuiContainer maxWidth="xs">
        <MuiBox display="flex" flexDirection="column">
          <Spacer size="medium" />
          <MuiTypography>
            Parents and legal guardians may request access to their minor's protected health
            information in {getAppName(AppName.secondary, 'the')}. Children ages 0-17 are eligible.
          </MuiTypography>
          <Spacer size="medium" />
          <MuiButton
            color="primary"
            variant="contained"
            data-testid="request-access"
            onClick={handleRequest}
          >
            Request access for a minor
          </MuiButton>
          <Spacer size="medium" />
          <MuiTypography>
            Access to another adult's account can be done at your Intermountain Clinic. Both you and
            the adult you want access to must be present with the appropriate legal identification.
          </MuiTypography>
        </MuiBox>
      </MuiContainer>
    </MuiBox>
  );
};

const mapStateToProps = (state: RootState) => ({
  currentUrl: routerSelectors.currentLocationPathNameSelector(state),
  referringUrl: routerSelectors.previousLocationPathNameSelector(state)
});

export default connect(mapStateToProps)(ProxyInfoScreen);
