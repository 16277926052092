import React from 'react';

export default {
  svg: (
    <g>
      <path
        d="M16.8596872,9.98924853 C16.8598091,10.4673103 16.6675612,10.9164523 16.3179531,11.2545187 L12.000061,15.4338411 L7.68180293,11.255345 C6.96087341,10.5572568 6.96087341,9.42135834 7.68168095,8.72362422 C8.0422677,8.3745801 8.51605623,8.2002351 8.98972279,8.2002351 C9.46338934,8.2002351 9.93717788,8.3745801 10.2977646,8.72362422 L12.000183,10.3708717 L13.7023574,8.7238603 C14.0517215,8.38543977 14.5163612,8.1990547 15.0105212,8.1990547 C15.5040712,8.1990547 15.968101,8.38520369 16.3175871,8.72374226 C16.6670733,9.06180867 16.8595651,9.5110687 16.8596872,9.98924853 L16.8596872,9.98924853 Z M12.4082218,7.47228275 L12.000183,7.86700833 L11.5915343,7.47157451 C10.1568723,6.08330669 7.82245128,6.08307061 6.38766735,7.47181059 C4.95385929,8.8600784 4.95385929,11.1186547 6.38778933,12.5071586 L12.000305,17.9380585 L17.6117227,12.5065684 C18.3069135,11.8343309 18.6897014,10.9401783 18.6894576,9.98877637 C18.6892135,9.03761048 18.3061816,8.14369396 17.6116007,7.47204667 C16.9170199,6.79910093 15.9933518,6.4284555 15.0105212,6.4284555 C14.0270806,6.4284555 13.1028026,6.79933701 12.4082218,7.47228275 L12.4082218,7.47228275 Z M20.1702296,14.041914 C20.1702296,15.6617762 19.3374402,17.1634804 17.9426672,18.0591675 L17.9426672,18.0591675 L11.999939,21.8750449 L6.05721082,18.0591675 C4.66243786,17.1634804 3.82977036,15.6617762 3.82977036,14.041914 L3.82977036,3.18802288 C6.50928608,4.27127547 9.60196395,3.94406874 11.999939,2.2537367 C14.3979141,3.94418678 17.4907139,4.27115743 20.1702296,3.18802288 L20.1702296,14.041914 Z M12.8476106,0.653233065 L11.999939,0 L11.1522674,0.653233065 C8.91299442,2.37862297 5.80592236,2.50893907 3.42063371,0.977488799 L2,0.0652760905 L2,14.041914 C2,16.2565795 3.13848312,18.3098844 5.04546979,19.5344308 L11.999939,24 L18.9544082,19.5344308 C20.8613949,18.3098844 22,16.2565795 22,14.041914 L22,0.0652760905 L20.5792443,0.977488799 C18.1940776,2.50905711 15.0870056,2.37874101 12.8476106,0.653233065 L12.8476106,0.653233065 Z"
        id="Fill-1"
        fill="#231F20"
      />
    </g>
  ),
  viewBox: '0 0 24 24'
};
