import React from 'react';

export default {
  svg: (
    <g>
      <g>
        <circle fill="#BF00E6" strokeWidth={0} cx={40} cy={40} r={38.5} />
        <path
          fill="#FFFFFF"
          strokeWidth={0}
          d="M40,2.5c20.711,0,37.5,16.789,37.5,37.5S60.711,77.5,40,77.5S2.5,60.711,2.5,40S19.289,2.5,40,2.5 M40,0.5 C18.22,0.5,0.5,18.22,0.5,40S18.22,79.5,40,79.5S79.5,61.78,79.5,40S61.78,0.5,40,0.5L40,0.5z"
        />
      </g>
      <g>
        <defs>
          <circle id="SVGID_27_" cx={40} cy={40} r={37.5} />
        </defs>
        <clipPath id="SVGID_2_2">
          <use xlinkHref="#SVGID_27_" />
        </clipPath>
        <g id="Female_-_Adult_1_" clipPath="url(#SVGID_2_2)">
          <path
            fill="#C5672F"
            strokeWidth={0}
            d="M53.33,17.21c-3.376-3.5-6.392-6.578-13.33-6.578l0,0h0c0,0,0,0,0,0l0,0c-6.938,0-9.954,3.078-13.33,6.578 c-8.229,8.533-9.309,33.406-5.679,49.387C21.004,66.664,40,62.091,40,62.091s18.996,4.573,19.009,4.506 C62.639,50.617,61.559,25.744,53.33,17.21z"
          />
          <path
            fill="#F7CDB9"
            strokeWidth={0}
            d="M54.495,37.755v-6.581c0-6.989-8.091-15.819-14.496-15.819s-14.496,8.829-14.496,15.819v6.581 c-1.779,0-3.221,1.416-3.221,3.164v3.29c0,1.747,1.442,3.164,3.221,3.164c0,4.759,3.752,10.486,8.168,13.658v6.496l-3.164,0.245 l3.164,20.401l12.655,0l3.164-20.401l-3.164-0.245v-6.496c4.416-3.172,8.168-8.899,8.168-13.658c1.779,0,3.221-1.416,3.221-3.164 v-3.29C57.717,39.171,56.274,37.755,54.495,37.755z"
          />
          <path
            fill="#42404D"
            strokeWidth={0}
            d="M69.372,88.99c-0.677-5.973-2.166-11.145-7.428-16.195c-4.526-4.343-10.012-5.187-14.628-5.236 l-0.068,0.571L40,87.957L32.753,68.13l-0.068-0.571c-4.616,0.049-10.103,0.893-14.628,5.236 c-5.262,5.05-6.752,10.221-7.428,16.195c-0.734,6.478-0.626,23.491-0.626,23.491l9.112,0.02c0,0,0.497-14.516,1.186-20.586 c0.008-0.073,0.017-0.149,0.025-0.222c0.521,4.522,2.081,8.449,4.139,10.551c0,0-0.249,3.399-1.426,9.905l33.946,0.12 c-1.196-6.576-1.448-10.025-1.448-10.025c2.058-2.103,3.618-6.03,4.139-10.552c0.008,0.074,0.017,0.149,0.025,0.222 c0.688,6.07,1.186,20.586,1.186,20.586l9.112-0.02C69.998,112.48,70.106,95.467,69.372,88.99z"
          />
          <g>
            <polygon
              fill="#ED1E79"
              strokeWidth={0}
              points="25.378,72.757 32.314,75.38 29.592,79.176 40,87.957 32.611,67.609  "
            />
            <polygon
              fill="#ED1E79"
              strokeWidth={0}
              points="54.622,72.757 47.378,67.695 40,87.957 50.409,79.176 47.686,75.38  "
            />
          </g>
          <path
            fill="#EEAB91"
            strokeWidth={0}
            d="M54.495,37.755v-6.581c0-6.989-8.091-15.819-14.496-15.819v25.626c-2.35,4.909-3.221,10.124-3.221,10.124 L40,51.739v11.706c-2.044,0-4.258-0.927-6.327-2.414v1.412l12.655,5.084v-6.496c0,0,0,0,0,0c4.416-3.172,8.168-8.899,8.168-13.658 c1.779,0,3.221-1.416,3.221-3.164v-3.29C57.717,39.171,56.274,37.755,54.495,37.755z M40,63.444C40,63.444,40,63.444,40,63.444 L40,63.444L40,63.444z M44.689,62.068c0.034-0.019,0.068-0.037,0.102-0.056C44.757,62.031,44.723,62.049,44.689,62.068z  M40.706,63.405c0.071-0.007,0.142-0.018,0.213-0.027C40.847,63.388,40.776,63.398,40.706,63.405z M41.44,63.295 c0.074-0.014,0.148-0.029,0.222-0.045C41.588,63.266,41.514,63.281,41.44,63.295z M42.251,63.099 c0.046-0.013,0.091-0.024,0.137-0.038C42.342,63.075,42.296,63.086,42.251,63.099z M43.147,62.797 c0.013-0.005,0.025-0.011,0.038-0.016C43.172,62.787,43.159,62.792,43.147,62.797z M43.899,62.472 c0.046-0.021,0.091-0.044,0.137-0.066C43.99,62.428,43.944,62.45,43.899,62.472z"
          />
          <g>
            <g>
              <path
                fill="#F6CCB8"
                strokeWidth={0}
                d="M34.638,41.947c0.048,1.379-1.031,2.536-2.41,2.585c-1.379,0.048-2.536-1.031-2.585-2.41 c-0.048-1.379,1.031-2.536,2.41-2.585C33.433,39.489,34.59,40.568,34.638,41.947z"
              />
              <path
                fill="#3C2415"
                strokeWidth={0}
                d="M34.638,41.314c0.048,1.379-1.031,2.536-2.41,2.585c-1.379,0.048-2.536-1.031-2.585-2.41 c-0.048-1.379,1.031-2.536,2.41-2.585C33.433,38.856,34.59,39.935,34.638,41.314z"
              />
              <path
                fill="#FFFFFF"
                strokeWidth={0}
                d="M33.614,41.866c0.012,0.345-0.258,0.634-0.603,0.646c-0.345,0.012-0.634-0.258-0.646-0.603 c-0.012-0.345,0.258-0.634,0.603-0.646C33.312,41.251,33.602,41.521,33.614,41.866z"
              />
              <path
                fill="#3C2415"
                strokeWidth={0}
                d="M35.18,39.405c-0.461-1.071-1.642-1.883-2.939-2.021c-0.795-0.086-2.287,0.031-3.569,1.654 c-0.061,0.005-0.118,0.007-0.189-0.001c-0.142-0.013-0.341-0.059-0.534-0.141c-0.394-0.158-0.721-0.49-0.721-0.49 c-0.023-0.022-0.06-0.03-0.09-0.015c-0.039,0.019-0.055,0.066-0.036,0.104c0,0,0.049,0.109,0.144,0.265 c0.093,0.157,0.239,0.36,0.425,0.538c0.184,0.178,0.404,0.331,0.589,0.423c0.092,0.047,0.175,0.08,0.237,0.101 c0.059,0.021,0.122,0.033,0.111,0.031c0.172,0.017,0.329-0.075,0.405-0.22c0.84-1.173,1.963-1.75,3.162-1.621 c1.059,0.113,2.057,0.788,2.424,1.642c0.07,0.161,0.256,0.235,0.416,0.165C35.175,39.752,35.25,39.566,35.18,39.405z"
              />
            </g>
            <g>
              <path
                fill="#F6CCB8"
                strokeWidth={0}
                d="M50.457,41.947c0.048,1.379-1.031,2.536-2.41,2.585c-1.379,0.048-2.536-1.031-2.585-2.41 s1.031-2.536,2.41-2.585C49.252,39.489,50.409,40.568,50.457,41.947z"
              />
              <path
                fill="#3C2415"
                strokeWidth={0}
                d="M50.457,41.314c0.048,1.379-1.031,2.536-2.41,2.585c-1.379,0.048-2.536-1.031-2.585-2.41 s1.031-2.536,2.41-2.585C49.252,38.856,50.409,39.935,50.457,41.314z"
              />
              <path
                fill="#FFFFFF"
                strokeWidth={0}
                d="M49.432,41.866c0.012,0.345-0.258,0.634-0.603,0.646c-0.345,0.012-0.634-0.258-0.646-0.603 c-0.012-0.345,0.258-0.634,0.603-0.646C49.131,41.251,49.42,41.521,49.432,41.866z"
              />
              <path
                fill="#3C2415"
                strokeWidth={0}
                d="M44.92,39.405c0.461-1.071,1.642-1.883,2.939-2.021c0.795-0.086,2.287,0.031,3.569,1.654 c0.061,0.005,0.118,0.007,0.189-0.001c0.142-0.013,0.341-0.059,0.534-0.141c0.394-0.158,0.721-0.49,0.721-0.49 c0.023-0.022,0.06-0.03,0.09-0.015c0.039,0.019,0.055,0.066,0.036,0.104c0,0-0.049,0.109-0.144,0.265 c-0.093,0.157-0.239,0.36-0.425,0.538c-0.184,0.178-0.404,0.331-0.589,0.423c-0.092,0.047-0.176,0.08-0.237,0.101 c-0.059,0.021-0.122,0.033-0.111,0.031c-0.172,0.017-0.329-0.075-0.405-0.22c-0.84-1.173-1.963-1.75-3.162-1.621 c-1.06,0.113-2.057,0.788-2.424,1.642c-0.07,0.161-0.256,0.235-0.416,0.165C44.925,39.752,44.851,39.566,44.92,39.405z"
              />
            </g>
          </g>
          <g>
            <path
              fill="#F05D44"
              strokeWidth={0}
              d="M45.062,54.076c0,2.485-2.266,4.5-5.062,4.5c-2.796,0-5.062-2.014-5.062-4.5H45.062z"
            />
            <path
              fill="#FFFFFF"
              strokeWidth={0}
              d="M43.53,55.763h-7.171c-0.349,0-0.633-0.283-0.633-0.633v-1.055h8.437v1.055 C44.163,55.48,43.879,55.763,43.53,55.763z"
            />
          </g>
          <g>
            <circle fill="#D4145A" strokeWidth={0} cx={56.135} cy={47.199} r={1.582} />
            <circle fill="#D4145A" strokeWidth={0} cx={23.865} cy={47.199} r={1.582} />
          </g>
          <path
            fill="#4F3C30"
            strokeWidth={0}
            d="M56.597,36.312h-2.225c-1.273-0.862-3.598-0.863-5.703-0.858c-3.543,0-5.916,0.695-7.062,2.061 c-0.395-0.225-0.976-0.291-1.631-0.291c-0.655,0-1.236,0.066-1.631,0.291c-1.146-1.366-3.518-2.061-7.062-2.061 c-2.105-0.004-4.43-0.004-5.703,0.858h-2.225v2.404l0.962,0.534c0.02,3.751,1.852,8.099,6.966,8.099 c4.206,0,6.811-2.282,7.744-6.782c0.068-0.326,0.094-0.642,0.089-0.947c0.064-0.251,0.39-0.499,0.86-0.499 c0.47,0,0.796,0.248,0.86,0.499c-0.005,0.305,0.021,0.621,0.089,0.947c0.933,4.5,3.538,6.782,7.744,6.782 c5.114,0,6.946-4.347,6.966-8.099l0.962-0.534V36.312z M37.913,40.337c-0.819,3.952-2.987,5.873-6.628,5.873 c-5.548,0-5.829-5.85-5.829-7.023c0-1.8,4.012-1.961,5.829-1.961C35.292,37.226,38.287,38.653,37.913,40.337z M48.669,46.211 c-3.641,0-5.809-1.921-6.628-5.873c-0.375-1.685,2.621-3.111,6.628-3.111c1.817,0,5.829,0.161,5.829,1.961 C54.499,40.36,54.217,46.211,48.669,46.211z"
          />
          <path
            fill="#C5672F"
            strokeWidth={0}
            d="M24.902,61.507c-4.573-15.259-5.468-24.727-2.97-35.023C26.783,6.487,48.9,5.302,51.374,17.851 c0.526,2.671,6.185,5.307,3.401,18.461c-0.214-0.076-6.757-7.494-8.39-11.329C24.902,24.917,32.091,56.765,24.902,61.507z"
          />
        </g>
      </g>
    </g>
  ),
  viewBox: '0 0 80 80'
};
