import styled, { css } from 'styled-components';
import { rgba } from 'polished';
import { Color } from 'modules/styles/colors';
import { easeInOutBack } from 'screens/ConnectCare/ConnectCareWaitingRoom/ConnectCareWebRTC/styled/WebRTCStyles';
import { HEIGHT, WIDTH } from './constants';

interface MapSvgProps {
  size: number;
}

export const MapSvg = styled.svg.attrs({
  viewBox: `0 0 ${WIDTH} ${HEIGHT}`
})<MapSvgProps>`
  fill: none;
  height: 100%;
  width: ${WIDTH}px;

  ${props =>
    props.size &&
    css`
      width: ${props.size}px;
    `}
`;

const Path = styled.path.attrs({
  strokeLineJoin: 'round'
})`
  stroke: ${Color.primary};
  stroke-width: 0.970631;
`;

interface USStatePathProps {
  selected: boolean;
  readOnly?: boolean;
}

export const USStatePath = styled(Path)<USStatePathProps>`
  fill: ${Color.white};
  transition: 200ms fill ${easeInOutBack};
  cursor: pointer;
  &:hover {
    fill: ${rgba(Color.primary, 0.33)};
  }

  ${props =>
    props.readOnly &&
    css`
      cursor: default;
      pointer-events: none;
    `}

  ${props =>
    props.selected &&
    css`
      cursor: default;
      pointer-events: none;
      fill: ${Color.primary};
    `}
`;

export const BlurPath = styled(Path)`
  filter: url('#dropshadow');
`;
