import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { InsuranceCardData } from 'store/CovidScreening/types';
import { CovidTitles } from 'lib/constants/covidScreening';
import { MuiBox, MuiContainer } from 'theme/material-ui';

import Spacer from 'components/UI/Layout/Spacer';
import { ButtonsNavigation, CovidTestGuestFooter } from 'screens/CovidTestGuest/components';

import { TextInformation } from './components';
import InsuranceSelect from './components/InsuranceSelect';
import { useCovidInsuranceDropdown } from './hooks';
import CovidGuestGuard from '../navigation/CovidGuestLeavingGuard';
import { useLanguageSwitcher } from 'lib/hooks/useLanguageSwitcher';
import { translations } from 'lib/constants/translations/screens/covidGuest/covidInsuranceDropDown';

export default function CovidInsuranceSelect() {
  const history = useHistory();
  const screenText = useLanguageSwitcher(translations);
  const [insuranceCannotFind, setInsuranceCannotFind] = useState(false);
  const {
    confirmInsurance,
    goBack,
    insuranceSelected,
    updateInsurance
  } = useCovidInsuranceDropdown();

  const disabledNextButton = !insuranceSelected && !insuranceCannotFind;

  const handleInsurancePress = (value: InsuranceCardData) => {
    updateInsurance(value);
    setInsuranceCannotFind(false);
  };

  const cleanStatesData = (insuranceCannotFind: boolean) => {
    setInsuranceCannotFind(insuranceCannotFind);
    updateInsurance(null);
  };

  const onChangeCantFindInsurance = () => cleanStatesData(!insuranceCannotFind);

  const handleSubmit = () => {
    confirmInsurance();

    if (insuranceCannotFind)
      return history.push('/guest-home/covid-screen/guest-no-insurance-found');

    return history.push('/guest-home/covid-screen/guest-new-insurance-found');
  };

  const onCancelClick = () => {
    goBack();
    history.goBack();
  };

  return (
    <>
      <CovidGuestGuard />
      <MuiContainer maxWidth="lg">
        <MuiBox p={5}>
          <TextInformation />

          <Spacer size="small" />

          <InsuranceSelect onClean={cleanStatesData} onSelectInsurance={handleInsurancePress} />

          <Spacer size="small" />

          <MuiBox flexDirection="row" alignItems="center">
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  data-testid="insurance-select-check-box"
                  checked={insuranceCannotFind}
                  onChange={onChangeCantFindInsurance}
                  value="insurance-not-found"
                />
              }
              label={screenText.checkbox}
            />
          </MuiBox>
        </MuiBox>

        <ButtonsNavigation
          cancelTestId="insurance-select-previous-button"
          nextTestId="insurance-select-next-button"
          disabledNextButton={disabledNextButton}
          onCancelClick={onCancelClick}
          onNextClick={handleSubmit}
        />

        <CovidTestGuestFooter title={CovidTitles.EXISTING_INSURANCE_SEARCH} />
      </MuiContainer>
    </>
  );
}
