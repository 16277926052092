import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { oc } from 'ts-optchain';
import analyticsService, { AnalyticsEvent } from 'services/AnalyticsService';

import { RootDispatch, RootState } from 'store/types';
import * as globalSelectors from 'store/global/selectors';
import {
  currentLocationPathNameSelector,
  previousLocationPathNameSelector
} from 'store/router/selectors';
import * as globalActions from 'store/global/actions';
import * as actions from 'store/findProvider/actions';
import * as constants from 'store/findProvider/constants';
import * as profileActions from 'store/profile/actions';
import * as profileSelectors from 'store/profile/selectors';
import * as profileTypes from 'store/profile/types';
import * as authSelectors from 'store/authentication/selectors';
import { TermsAndConditionsResponse } from 'store/global/reducers';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import { Alert, AlertTitle } from '@material-ui/lab';
import { makeStyles, createStyles, Theme, useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actions: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between'
    },
    header: {
      textAlign: 'center'
    },
    button: {
      fontSize: theme.typography.fontSize
    },
    moreButton: {
      fontSize: theme.typography.fontSize,
      textDecoration: 'underline',
      marginTop: theme.spacing(1.1)
    },
    content: {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(5),
      paddingLeft: theme.spacing(6),
      paddingRight: theme.spacing(6)
    },
    contentSmall: {
      padding: theme.spacing(3)
    }
  })
);

const termsStyles = `
  <style>
    .paragraph:last-child,
    .section:last-child {
      padding: 0;
    }
  </style>
`;

export interface Props {
  open: boolean;
  isDfdAuthenticated: boolean;
  handleClose: () => void;
  handleAccept: () => void;
  dispatch: RootDispatch;
  consumer: profileTypes.Consumer;
  searchTerms: TermsAndConditionsResponse;
  currentUrl?: string;
  referringUrl?: string;
}

export function Terms({
  open,
  handleClose,
  handleAccept,
  isDfdAuthenticated,
  searchTerms,
  dispatch,
  consumer,
  referringUrl,
  currentUrl
}: Props) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();

  useEffect(() => {
    if (!searchTerms) {
      dispatch(globalActions.getTermsAndConditions());
    }
  }, []);

  const onAccept = () => {
    analyticsService.logEvent(AnalyticsEvent.ProviderSearchTCContinueClicked, {
      referringUrl,
      currentUrl
    });

    dispatch(actions.setSortBy(constants.SORT_BY_PROXIMITY));
    dispatch(globalActions.setAcceptTemporaryTC('PROVIDER_SEARCH'));
    if (isDfdAuthenticated) {
      const termAcceptance = {
        version: oc(searchTerms).version('error retrieving version'),
        date: new Date(),
        type: oc(searchTerms).type('error retrieving version')
      };

      const updatedExternalTermsArray = oc(consumer)
        .externalTermsAcceptance([])
        .concat(termAcceptance);

      dispatch(
        profileActions.updateConsumerProfile(
          consumerData => ({
            ...consumerData,
            externalTermsAcceptance: updatedExternalTermsArray
          }),
          ['ExternalTermsAcceptance']
        )
      );
    }

    handleAccept();
  };
  const onDecline = () => {
    dispatch(actions.setSortBy(constants.SORT_BY_LAST_NAME));
    handleClose();
  };
  return (
    <Dialog
      open={open}
      onClose={onDecline}
      scroll="paper"
      maxWidth="md"
      fullScreen={isSmallScreen}
      fullWidth
    >
      <DialogTitle className={classes.header}>
        Terms & Conditions
        <Box
          position="absolute"
          top={0}
          right="-10px"
          bottom={0}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <IconButton onClick={onDecline}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent dividers className={isSmallScreen ? classes.contentSmall : classes.content}>
        {searchTerms ? (
          <div
            dangerouslySetInnerHTML={{
              __html: termsStyles + searchTerms.content
            }}
          />
        ) : (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            Unable to locate Terms & Conditions document.
          </Alert>
        )}
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button onClick={handleClose} color="primary" size="large" className={classes.button}>
          Decline
        </Button>
        <Button
          onClick={onAccept}
          color="primary"
          variant="contained"
          size="large"
          disabled={!searchTerms}
          className={classes.button}
          data-testid="terms-and-conditions-agree-button"
        >
          Agree
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const mapStateToProps = (state: RootState) => {
  const terms = globalSelectors.termsAndConditionsSelector(state);
  return {
    searchTerms: terms && terms.provider,
    isDfdAuthenticated: authSelectors.isDfdAuthenticatedSelector(state),
    consumer: profileSelectors.profileConsumerSelector(state),
    currentUrl: currentLocationPathNameSelector(state),
    referringUrl: previousLocationPathNameSelector(state)
  };
};

const mapDispatchToProps = (dispatch: RootDispatch) => ({ dispatch });

export default connect(mapStateToProps, mapDispatchToProps)(Terms);
