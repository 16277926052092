import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Icon } from 'components/Icon';
import { useHistory } from 'react-router-dom';
import get from 'lodash/get';

import { Color } from 'modules/styles/colors';
import { Spacing, IconSize } from 'modules/styles/variables';

import RouteLeavingGuard from 'components/UI/Modals/RouteLeavingGuardModal';

import TriageExitConfirmationModal from 'screens/TriageGyant/TriageExitConfirmationModal';
import { TRIAGE_ROUTES, TRIAGE_MODAL_TEXT } from 'screens/TriageGyant/constants';
import { EVISITS_ROUTES } from 'screens/EVisits/constants';

import { RootState } from 'store/types';
import { isDfdAuthenticatedSelector } from 'store/authentication/selectors';

import { MuiContainer, MuiBox, CursorMuiBox } from 'theme/material-ui';
import { triageGyantSessionTokenErrorSelector } from 'store/triageGyant/selectors';

export interface Props {
  isAuthenticated: boolean;
  sessionTokenError: null | Error;
}

const TriageHeader = ({ isAuthenticated, sessionTokenError }: Props) => {
  const history = useHistory();
  const currentRoute = get(history, 'location.pathname', '');

  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const handleToggle = () => {
    if (
      currentRoute === TRIAGE_ROUTES.SYMPTOM_CHECKER ||
      currentRoute.includes(TRIAGE_ROUTES.RESULTS.BASE_URL) ||
      currentRoute.includes(TRIAGE_ROUTES.POSSIBLE_CAUSES.BASE_URL) ||
      currentRoute.includes(TRIAGE_ROUTES.CAUSE_DETAIL.BASE_URL)
    ) {
      toggleModal();
    } else if (isAuthenticated) {
      history.push('/u/get-care-now');
    } else {
      history.push('/guest-home');
    }
  };

  const validRouteToNavigate = (nextLocation: string) => {
    return (
      nextLocation === EVISITS_ROUTES.REQUEST_SUCCESS ||
      nextLocation.includes(TRIAGE_ROUTES.RESULTS.BASE_URL)
    );
  };

  return (
    <>
      <RouteLeavingGuard
        when={!modalOpen}
        shouldBlockNavigation={nextLocation => {
          if (nextLocation.pathname.includes('/u/get-care-now/covid-screen')) {
            return false;
          }
          if (
            currentRoute === TRIAGE_ROUTES.SYMPTOM_CHECKER &&
            !validRouteToNavigate(nextLocation.pathname) &&
            nextLocation.pathname !== '/loading' &&
            !sessionTokenError
          ) {
            return true;
          }
          return false;
        }}
        exitTitle={TRIAGE_MODAL_TEXT.title}
        exitSubtitle={TRIAGE_MODAL_TEXT.subtitle}
        navigate={(path: string) => history.push(path)}
      />
      <TriageExitConfirmationModal
        open={modalOpen}
        handleToggle={toggleModal}
        isAuthenticated={isAuthenticated}
      />
      <MuiContainer maxWidth="md">
        <MuiBox
          bgcolor={Color.primary}
          width="100%"
          height="50px"
          px={Spacing.small}
          flexDirection="row"
          justifyContent="flex-end"
          alignItems="center"
          display="flex"
          data-testid="triage-gyant-header"
        >
          <CursorMuiBox onClick={handleToggle} data-testid="handle-toggle">
            <Icon name="close" size={IconSize.large} color={Color.white} />
          </CursorMuiBox>
        </MuiBox>
      </MuiContainer>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  isAuthenticated: isDfdAuthenticatedSelector(state),
  sessionTokenError: triageGyantSessionTokenErrorSelector(state)
});

export default connect(mapStateToProps)(TriageHeader);
