import React, { useState, useEffect, useRef } from 'react';
import { Alert } from 'components/Alert';
import { Icon } from 'components/Icon';
import { AnyAction } from 'redux';
import { FlexBoxRow } from 'components/UI/Layout/FlexBox';
import Spacer from 'components/UI/Layout/Spacer';
import FileMessageUploadButton from 'components/FileMessageUpload/FileMessageUploadButton';
import FileChips from 'components/FileMessageUpload/FileChips';
import { SpinnerOverlay } from 'components/UI/Spinner/SpinnerModal';
import Typography from 'components/UI/Typography';
import { Color } from 'modules/styles/colors';
import { FontSize, FontWeight, IconSize, Spacing } from 'modules/styles/variables';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { NavigationScreenProps } from 'screens/navigation';
import analyticsService, { AnalyticsEvent, AmplitudeEventData } from 'services/AnalyticsService';
import {
  currentLocationPathNameSelector,
  previousLocationPathNameSelector
} from 'store/router/selectors';
import { MessagePool } from 'store/findProvider/types';
import {
  messageComposeClearProviderSelected,
  cancelComposeMessage,
  sendComposeMessage,
  addComposeDocument,
  deleteDocument
} from 'store/messaging/actions';
import {
  MAX_NUMBER_OF_FILES_MESSAGE,
  MAX_NUMBER_OF_ADD_DELETE_FILES_MESSAGE
} from 'store/messaging/constants';
import { messageComposeSendingSelector, messageComposeToSelector } from 'store/messaging/selectors';
import {
  CursorMuiBox,
  MuiBox,
  MuiButton,
  MuiContainer,
  MuiTypography,
  MuiAlert
} from 'theme/material-ui';
import { oc } from 'ts-optchain';
import MessageComposeFindProvider from './MessagingComposeFindProvider';
import { formatMessageBody } from 'lib/messaging/utils';
import {
  DisclaimerFlexBox,
  MessageRecepientTag,
  MessageTextInput,
  MessageDialog,
  MessageDialogHeader,
  SnackBar
} from 'screens/Messaging/styled';
import RequiredFieldsLegend from 'components/RequiredFieldsLegend';
import { Confirm } from 'components/ConfirmDialog/ConfirmDialog';
import { RequiredTextField } from 'screens/Register/styled';
import { MessageComposeProps } from 'screens/Messaging/MessageHomeScreen';
import { dataURLtoFile } from 'utils';
import { RootState } from 'store/types';
import { getCharCount, getWordCount } from 'modules/utils/StringUtils';

interface MessageComposeWidgetProps extends Partial<NavigationScreenProps> {
  to: MessagePool | null;
  isSending?: boolean;
  currentRouteName?: string;
  previousRouteName?: string;
  handleMessageModal: (openModal: boolean) => void;
  showSuccessAlert: (providerName: string) => void;
  paramsMessage: MessageComposeProps;
  messageUUID: string;
}

interface HandleSendProps {
  onSuccess?: () => void;
}

interface FileUpload {
  file: File;
  isUploading: boolean;
  errorMessage?: string;
  canRetryUpload?: boolean;
  attachmentUUID?: string;
}

export const MessageComposeWidgetComponent = (props: MessageComposeWidgetProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    location,
    currentRouteName,
    previousRouteName,
    handleMessageModal,
    paramsMessage,
    showSuccessAlert,
    messageUUID
  } = props;
  const params = oc(location).state({}) as MessageComposeProps;

  const {
    attachments,
    readonly,
    lockSubject,
    lockRecipient,
    hideMessage,
    onSendSuccess,
    onSend,
    previousMessageId,
    transmitParams
  } = paramsMessage;

  const [subject, setSubject] = useState(paramsMessage.subject || '');
  const [message, setMessage] = useState(paramsMessage.message || '');
  const [subjectTouched, setSubjectTouched] = useState(false);
  const [messageTouched, setMessageTouched] = useState(false);
  const [isFindProviderOpen, setIsFindProviderOpen] = useState(false);
  const [filesLoaded, setFilesLoaded] = useState<FileUpload[]>([]);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [showAttachmentButton, setShowAttachmentButton] = useState(true);
  const [isProviderSelected, setIsProviderSelected] = useState(false);
  const [isReplyingMessage, setIsReplyingMessage] = useState(false);

  const uploadInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (props.to) {
      setIsReplyingMessage(true);
    }
  }, []);

  useEffect(() => {
    if (attachments?.length && messageUUID) {
      const attachmentArray = attachments?.map(dataURLtoFile) || [];
      onUpload(null, attachmentArray);
    }
  }, [messageUUID, attachments]);

  useEffect(() => {
    if (props.to) {
      setIsProviderSelected(true);
    }
  }, [props.to]);

  const loadingOrError = filesLoaded.some(file => file.isUploading || !!file.errorMessage);
  const sendIsDisabled = !!(
    (!message && !hideMessage) ||
    !subject ||
    !props.to ||
    loadingOrError ||
    !isProviderSelected
  );

  const handleClose = () => {
    dispatch(cancelComposeMessage());
    if (params.handleClose) {
      params.handleClose();
    }
    const sendTo = transmitParams?.returnBackTo || onSend || '/u/messages';
    history.push(sendTo, { ...transmitParams?.returnBackToParams });
    handleMessageModal(false);
  };

  const handleCloseDialog = () => {
    Confirm.show(
      'Are you sure you want to exit?',
      'Your message will be lost.',
      null,
      'info',
      { text: 'Yes, exit', onClick: () => handleClose() },
      { text: 'No, take me back' }
    );
  };

  const handleSend = async (sendProps: HandleSendProps) => {
    if (sendIsDisabled || !props.to) {
      return;
    }

    const { onSuccess } = sendProps;
    const text = formatMessageBody(message, readonly);
    const attachmentUUIDs = filesLoaded.map(fileLoaded => fileLoaded.attachmentUUID);
    const attachmentUUIDArray = attachmentUUIDs.filter(uuid => !!uuid);

    const { error } = await dispatch(
      sendComposeMessage(
        props.to,
        subject,
        text,
        messageUUID,
        attachmentUUIDArray as string[],
        previousMessageId
      )
    );

    const handleSuccess = () => {
      const recipient = props.to;
      const message_pool_type = recipient ? recipient.poolTypeName : undefined;
      const message_pool = recipient ? recipient.personnelGroupId : undefined;
      const message_provider_id = recipient ? recipient.corpProvId : undefined;
      const char_count = getCharCount(message);
      const word_count = getWordCount(message);

      const analyticsData: AmplitudeEventData = {
        currentUrl: currentRouteName,
        referringUrl: previousRouteName,
        message_pool_type,
        message_pool,
        message_provider_id,
        attachmentUUIDArray,
        messageUUID,
        number_of_files_attached: filesLoaded.length,
        char_count,
        word_count
      };

      if (isReplyingMessage) {
        analyticsData.reply = 'Reply sent';
      }

      analyticsService.logEvent(AnalyticsEvent.MessageSent, analyticsData);

      if (onSuccess) {
        onSuccess();
      }

      history.push(onSend || '/u/messages');
    };

    if (error) {
      Alert.alert('Message did not send.', 'Sorry for the inconvenience.', [
        { text: 'Try Again?', onPress: () => handleSend(sendProps) },
        { text: 'Cancel' }
      ]);
    } else {
      showSuccessAlert(props.to.groupDisplayName);
      handleSuccess();
      handleMessageModal(false);
    }
  };

  const addErrorMessage = (
    filteredLength: number,
    filesLength: number,
    errors: string[],
    message: string
  ): number => {
    if (filteredLength !== filesLength) {
      errors.push(message);
      return filteredLength;
    }
    return filesLength;
  };

  const validateFiles = (files: File[]) => {
    const filesRendered = [...filesLoaded];
    const errors: string[] = [];
    const maxSizeMB = 10 * 1024 * 1024;
    const re = new RegExp('^[^<>:;,?"*|/]+$');
    let filesLength = files.length;

    // filter by unique files (no repeated)
    let filtered = files.filter((file: File) => {
      return !filesRendered.some(
        fileStored =>
          fileStored.file.lastModified === file.lastModified && fileStored.file.size === file.size
      );
    });
    filesLength = addErrorMessage(filtered.length, filesLength, errors, 'File already added');

    // filter by max file size (10MB)
    filtered = filtered.filter((file: File) => file.size <= maxSizeMB);
    filesLength = addErrorMessage(filtered.length, filesLength, errors, 'File size limit is 10MB');

    // filter by file name, avoid special characters (^<>:;,?"*|/)
    filtered = filtered.filter((file: File) => re.test(file.name));
    filesLength = addErrorMessage(
      filtered.length,
      filesLength,
      errors,
      '(^<>:;,?"*|/) file name characters are not supported'
    );

    // filter by number of files (max 5 files)
    if (filtered.length && filesRendered.length < MAX_NUMBER_OF_FILES_MESSAGE) {
      filtered.splice(MAX_NUMBER_OF_FILES_MESSAGE - filesRendered.length);
      const filesFiltered = filtered.map(file => ({ file, isUploading: true }));
      filesRendered.push(...filesFiltered);
    }

    setFilesLoaded(filesRendered);
    setErrorMessages(errors);
    return filtered;
  };

  const onReuploadFile = (id: number) => {
    setFilesLoaded(currentFilesLoaded => {
      return currentFilesLoaded.map(currentFile => {
        if (currentFile.file.lastModified === id) {
          uploadSingleFile(currentFile.file);
          return { file: currentFile.file, isUploading: true };
        }
        return { ...currentFile };
      });
    });
  };

  const onRemoveFile = async (id: number) => {
    const fileToDelete = filesLoaded.find(({ file }) => file.lastModified === id);
    const filesUpdated = filesLoaded.filter(({ file }) => file.lastModified !== id);
    if (!fileToDelete?.errorMessage && fileToDelete?.attachmentUUID) {
      dispatch(deleteDocument(messageUUID, fileToDelete.attachmentUUID));
    }
    setFilesLoaded(filesUpdated);

    if (uploadInputRef.current !== null) {
      uploadInputRef.current.value = '';
    }
  };

  const onUpload = (files?: FileList | null, attachmentArray: File[] = []) => {
    const filesArray = files ? Array.from(files) : [];
    const fileList = validateFiles([...filesArray, ...attachmentArray]);
    fileList.forEach(file => uploadSingleFile(file));
  };

  const uploadSingleFile = (file: File) => {
    const reader = new window.FileReader();
    reader.onload = async ({ target }: ProgressEvent<FileReader>) => {
      if (target?.result) {
        const { attachmentId, error }: AnyAction = await dispatch(
          addComposeDocument({ file, messageUUID })
        );

        setFilesLoaded(currentFiles => {
          return currentFiles.map(currentFileLoaded => {
            if (currentFileLoaded.file.lastModified !== file.lastModified) {
              return { ...currentFileLoaded };
            }
            if (error) {
              const errors: string[] = [];
              switch (error) {
                case 'File too large':
                case 'Extension is not allowed':
                case 'No file attached':
                case 'File cannot be empty':
                case 'Special characters in fileName are not allowed':
                  return { ...currentFileLoaded, errorMessage: error, isUploading: false };
                case 'Mime type is not supported':
                  return {
                    ...currentFileLoaded,
                    errorMessage: 'File type is not supported',
                    isUploading: false
                  };
                case 'Total number of messages with attachments threshold reached for an hour':
                case 'Adding/Deleting attachment limit reached for a message':
                  addErrorMessage(0, 1, errors, error);
                  setErrorMessages(errors);
                  setShowAttachmentButton(false);
                  return { ...currentFileLoaded, errorMessage: error, isUploading: false };
                default:
                  return {
                    ...currentFileLoaded,
                    errorMessage: error,
                    canRetryUpload: true,
                    isUploading: false
                  };
              }
            }
            return { ...currentFileLoaded, attachmentUUID: attachmentId, isUploading: false };
          });
        });

        if (uploadInputRef.current !== null) {
          uploadInputRef.current.value = '';
        }
      }
    };
    reader.readAsArrayBuffer(file);
  };

  const onCloseProvider = () => {
    dispatch(messageComposeClearProviderSelected());
    setIsProviderSelected(false);
  };

  const onCloseProviderOptions = (event?: Event) => {
    event?.stopPropagation();
    setIsFindProviderOpen(false);
  };

  const handleCloseError = () => {
    setErrorMessages([]);
  };

  const handleMessage = (value: string) => {
    setMessageTouched(true);
    setMessage(value);
  };

  const handleSubject = (value: string) => {
    setSubjectTouched(true);
    if (value.length <= 200) {
      setSubject(value);
    }
  };

  return (
    <>
      <MessageDialog data-testid="message-compose-widget">
        <SpinnerOverlay isLoading={!!props.isSending} />
        <MessageDialogHeader>
          <Typography
            color={Color.white}
            fontWeight={FontWeight.semibold}
            fontSize={FontSize.large}
          >
            New Message
          </Typography>
          <CursorMuiBox>
            <Icon
              name="close"
              size={IconSize.base}
              color={Color.white}
              onClick={handleCloseDialog}
            />
          </CursorMuiBox>
        </MessageDialogHeader>
        <MuiBox>
          <MuiBox>
            <Spacer spacing="xLarge" />
            <MuiBox flex={1}>
              <MuiContainer maxWidth="md">
                <FlexBoxRow
                  accessibilityLabel="Compose Find Provider"
                  alignItems="center"
                  justifyContent="flex-start"
                  onClick={() => !lockRecipient && setIsFindProviderOpen(true)}
                >
                  {isProviderSelected && props.to ? (
                    <>
                      <MuiTypography>To:</MuiTypography>
                      <MessageRecepientTag
                        icon={<Icon name="person" />}
                        label={props.to.groupDisplayName}
                        {...(!lockRecipient
                          ? {
                              onDelete: () => onCloseProvider()
                            }
                          : {})}
                      />
                    </>
                  ) : (
                    <MessageComposeFindProvider
                      isOpen={isFindProviderOpen}
                      onClose={onCloseProviderOptions}
                      paramsMessage={paramsMessage}
                    />
                  )}
                </FlexBoxRow>

                <Spacer spacing="xLarge" />
                <MuiTypography fontWeight={FontWeight.bold}>
                  Do not send messages for urgent issues.
                </MuiTypography>
                <MuiTypography>
                  If this is an urgent matter please call your doctor's office or dial 911.
                </MuiTypography>
                <Spacer spacing="xLarge" />

                <MuiBox marginBottom={`${Spacing.medium}px`}>
                  Subject <RequiredTextField>*</RequiredTextField>
                </MuiBox>
                <MessageTextInput
                  data-testid="subject"
                  disabled={lockSubject}
                  defaultValue={subject}
                  value={subject}
                  onChange={e => handleSubject(e.target.value)}
                  variant="outlined"
                  error={subjectTouched && !subject.length}
                  helperText={subjectTouched && !subject.length ? 'Please enter a subject.' : ''}
                  fullWidth
                />
                <MuiBox marginTop={subjectTouched && !subject.length ? 0 : 1} marginRight={2}>
                  <Typography
                    textAlign="right"
                    fontSize={FontSize.small}
                    fontWeight={FontWeight.semibold}
                  >
                    {subject.length}/200 characters
                  </Typography>
                </MuiBox>

                {!hideMessage ? (
                  <>
                    <Spacer spacing="large" />
                    <MuiBox marginBottom={`${Spacing.medium}px`}>
                      Message <RequiredTextField>*</RequiredTextField>
                    </MuiBox>
                    <MessageTextInput
                      data-testid="message"
                      defaultValue={message}
                      onChange={e => handleMessage(e.target.value)}
                      multiline
                      rows={10}
                      error={messageTouched && !message.length}
                      variant="outlined"
                      helperText={
                        messageTouched && !message.length ? 'Please enter a message.' : ''
                      }
                      fullWidth
                    />
                  </>
                ) : null}

                <Spacer spacing="large" />

                {filesLoaded.length ? (
                  <>
                    <FileChips
                      files={filesLoaded}
                      onRemoveFile={onRemoveFile}
                      onReuploadFile={onReuploadFile}
                    />
                    <Spacer spacing="medium" />
                  </>
                ) : null}

                {errorMessages.map(message => (
                  <SnackBar key={message} open autoHideDuration={8000} onClose={handleCloseError}>
                    <MuiAlert severity="error">{message}</MuiAlert>
                  </SnackBar>
                ))}

                <Spacer spacing="large" />
                {filesLoaded.length < MAX_NUMBER_OF_FILES_MESSAGE && showAttachmentButton ? (
                  <FileMessageUploadButton ref={uploadInputRef} onClick={onUpload}>
                    Add Attachment
                  </FileMessageUploadButton>
                ) : (
                  <Typography fontWeight={FontWeight.semibold}>
                    Limit of{' '}
                    {showAttachmentButton
                      ? MAX_NUMBER_OF_FILES_MESSAGE
                      : MAX_NUMBER_OF_ADD_DELETE_FILES_MESSAGE}{' '}
                    attachments
                  </Typography>
                )}

                {readonly ? (
                  <>
                    <MuiBox border={{ width: { left: 1 } }} borderColor={Color.gray}>
                      <Typography color={Color.gray}>
                        <pre style={{ whiteSpace: 'pre-wrap' }}>{readonly}</pre>
                      </Typography>
                    </MuiBox>
                  </>
                ) : null}
              </MuiContainer>
            </MuiBox>
          </MuiBox>
          <FlexBoxRow alignItems="center" justifyContent="center">
            {transmitParams ? (
              <MuiBox margin={`${Spacing.medium}px`}>
                <MuiButton
                  onClick={() =>
                    history.push({
                      pathname: '/u/messages/transmit',
                      state: { ...transmitParams, message }
                    })
                  }
                  variant="text"
                  color="primary"
                  data-testid="transmit-outside-intermountain-button"
                >
                  Transmit outside Intermountain
                </MuiButton>
              </MuiBox>
            ) : null}
          </FlexBoxRow>
          {!readonly && <Spacer size="medium" />}
          <DisclaimerFlexBox>
            <MuiBox flex={1}>
              <MuiTypography color={Color.textLight} fontWeight={FontWeight.semibold}>
                Please allow 2-3 business days for a response.
              </MuiTypography>
              <Spacer size="xsmall" />
              <RequiredFieldsLegend />
            </MuiBox>
            <MuiBox px={Spacing.small} flex={1}>
              <MuiButton
                data-testid="send-button"
                disabled={sendIsDisabled}
                variant="contained"
                color="primary"
                onClick={() => handleSend({ onSuccess: onSendSuccess })}
                size="large"
                style={{ flex: 1, marginLeft: Spacing.large }}
                fullWidth
              >
                Send
              </MuiButton>
            </MuiBox>
          </DisclaimerFlexBox>
        </MuiBox>
      </MessageDialog>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  to: messageComposeToSelector(state),
  isSending: messageComposeSendingSelector(state),
  currentRouteName: currentLocationPathNameSelector(state),
  previousRouteName: previousLocationPathNameSelector(state)
});

export default connect(mapStateToProps)(
  MessageComposeWidgetComponent as React.FC<MessageComposeWidgetProps>
);
