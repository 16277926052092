import styled from 'styled-components';

import { MuiPaper } from 'theme/material-ui';

import { Color } from 'modules/styles/colors';
import { FontWeight } from 'modules/styles/variables';

export const ContentWrapper = styled(MuiPaper)`
  && {
    margin-top: -20px;
  }
`;

export const NumberLink = styled.a`
  color: ${Color.primary};
  font-weight: ${FontWeight.semibold};
`;
