import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Hidden from '@material-ui/core/Hidden';

import Svg from 'components/UI/Svg/Svg';
import UserAvatar from 'components/UI/Avatar';
import NavigateToProtectedRouteModal from 'components/UI/Modals/NavigateToProtectedRouteModal';
import {
  CursorMuiBox,
  MuiBox,
  MuiDivider,
  MuiListItemSecondaryAction,
  MuiTypography
} from 'theme/material-ui';

import { getUserName } from 'lib/user';
import { Color } from 'modules/styles/colors';
import { FontSize, FontWeight, IconSize, Spacing } from 'modules/styles/variables';

import { GUEST_ROUTES } from 'screens/Guest/constants';

import analyticsService, { AnalyticsEvent, AMPLITUDE_EXPERIMENTS } from 'services/AnalyticsService';

import { RootState } from 'store/types';
import { Consumer } from 'store/profile/types';
import useNavigationAnalytics from 'hooks/useNavigationAnalytics';
import { currentAccountConsumerSelector } from 'store/account/selectors';
import { profileConsumerSelector } from 'store/profile/selectors';
import { logout, navigateToProtectedRoute } from 'store/authentication/actions';
import { getConsumerSharedUiData } from 'store/consumerPreferences/actions';
import { currentUserAvatarSelector } from 'store/consumerPreferences/selectors';
import { getPMMCToken } from 'store/cost/actions';
import { pmmcTokenSelector, pmmcTokenFetchingSelector } from 'store/cost/selectors';
import { PMMCTokenObject } from 'store/cost/types';

import SwitchPerson from './SwitchPerson';
import {
  MenuButton,
  StyledAppBar,
  SkipToContentLink,
  StyledMenu,
  StyledMuiList,
  StyledMuiMenuItem,
  StyledMuiPaper
} from './styled';
import { GlobalSearch } from 'components/Search';
import { DASHBOARD_BANNER_INDEX } from 'lib/constants/dashboard';
import { ExpandedView as translations } from 'lib/constants/translations/components/index';
import { useLanguageSwitcher } from 'lib/hooks/useLanguageSwitcher';
import { isValidEmail } from 'lib/utils';

export interface Props {
  consumer: Consumer;
  rootConsumer: Consumer;
  collapsed: boolean;
  isGuest: boolean;
  onClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  logout: typeof logout;
  navigateToProtectedRoute: typeof navigateToProtectedRoute;
  setCollapse?: Function;
  currentUserAvatar: string;
  hidden?: boolean;
  pmmcToken: PMMCTokenObject;
  pmmcTokenFetching: boolean;
}

interface HeaderMenuItem {
  title: string;
  iconSet: 'assets' | 'material' | 'ionic' | 'downloading' | 'web';
  iconName: string;
  testId: string;
  onClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

export const Header = ({
  consumer,
  rootConsumer,
  collapsed,
  hidden,
  isGuest,
  logout,
  onClick,
  setCollapse,
  currentUserAvatar,
  pmmcToken,
  pmmcTokenFetching
}: Props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const consumerName = getUserName(consumer);
  const screenText = useLanguageSwitcher(translations);
  const helpCenterClicked = useNavigationAnalytics(AnalyticsEvent.HelpCenterClicked);
  const signedOutClicked = useNavigationAnalytics(AnalyticsEvent.SignedOut);
  const quickSearchFlag = analyticsService.fetchExperimentVariant(
    AMPLITUDE_EXPERIMENTS.QUICK_SEARCH.flagKey
  );
  const quickSearchEnabled = quickSearchFlag?.value === AMPLITUDE_EXPERIMENTS.QUICK_SEARCH.variantA;

  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    if (!isGuest) {
      dispatch(getConsumerSharedUiData(rootConsumer.consumerId));
      if (!pmmcToken && !pmmcTokenFetching) {
        if (consumer.emails?.length) {
          const email = consumer.emails[0].value;
          if (isValidEmail(email)) {
            dispatch(getPMMCToken());
          }
        }
      }
    }
  }, []);

  const handleUserClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleUserMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMyAccountClick = () => {
    history.push('/u/manage-account');
  };

  const handleHelpCenterClick = () => {
    helpCenterClicked();
    history.push('/u/help-support');
  };

  const handleSignOutClick = () => {
    signedOutClicked();
    logout();
    window.sessionStorage.removeItem(DASHBOARD_BANNER_INDEX);
  };

  const headerMenuItems: HeaderMenuItem[] = [
    {
      title: screenText.AccountManagement,
      iconSet: 'assets',
      iconName: 'ChevronRight',
      testId: 'my-account-profile-menu-link',
      onClick: handleMyAccountClick
    },
    {
      title: screenText.HelpCenter,
      iconSet: 'assets',
      iconName: 'ChevronRight',
      testId: 'help-center-profile-menu-link',
      onClick: handleHelpCenterClick
    },
    {
      title: screenText.SignOut,
      iconSet: 'assets',
      iconName: 'SignOut',
      testId: 'sign-out-profile-menu-link',
      onClick: handleSignOutClick
    }
  ];

  return (
    <StyledAppBar hidden={hidden} elevation={3} collapsed={collapsed}>
      {/* Accessibility Links */}
      <SkipToContentLink href="#content">Skip to main content</SkipToContentLink>
      <SkipToContentLink href="#navigation">Skip to main navigation</SkipToContentLink>

      <MuiBox width="100%" display="flex" justifyContent="space-between">
        <MuiBox display="flex" alignItems="center">
          <Hidden mdUp>
            <MenuButton onClick={onClick} px={2}>
              <Svg
                name="Menu"
                set="material"
                accessibilityLabel="Show side navigation"
                size={25}
                color={Color.graySidebarIcon}
              />
            </MenuButton>
          </Hidden>

          {collapsed ? (
            <Hidden only={['xs', 'lg', 'xl']}>
              <MenuButton px={2} onClick={setCollapse}>
                <Svg
                  name="Menu"
                  set="material"
                  accessibilityLabel="Expand side navigation"
                  size={25}
                  color={Color.graySidebarIcon}
                />
              </MenuButton>
            </Hidden>
          ) : null}
        </MuiBox>

        <MuiBox flexDirection="row" display="flex">
          <StyledMenu
            elevation={7}
            anchorEl={anchorEl}
            getContentAnchorEl={null}
            open={Boolean(anchorEl)}
            onClose={handleUserMenuClose}
            onClick={handleUserMenuClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}
          >
            <StyledMuiPaper>
              <SwitchPerson onUserMenuClose={handleUserMenuClose} />
              <StyledMuiList>
                {headerMenuItems.map(item => (
                  <MuiBox key={item.testId}>
                    <StyledMuiMenuItem data-testid={item.testId} onClick={item.onClick}>
                      <MuiTypography fontSize={FontSize.large} color={Color.black}>
                        {item.title}
                      </MuiTypography>
                      <MuiListItemSecondaryAction>
                        <Svg
                          set={item.iconSet}
                          name={item.iconName}
                          size={IconSize.base}
                          color={Color.black}
                        />
                      </MuiListItemSecondaryAction>
                    </StyledMuiMenuItem>
                    <MuiDivider variant="fullWidth" />
                  </MuiBox>
                ))}
              </StyledMuiList>
            </StyledMuiPaper>
          </StyledMenu>
          {isGuest ? (
            <CursorMuiBox
              display="flex"
              alignItems="center"
              onClick={() => history.push(GUEST_ROUTES.GUEST_HOME)}
            >
              <MuiBox mr={Spacing.xSmall}>
                <MuiTypography color={Color.primary} fontSize={FontSize.base}>
                  {screenText.GuestAccount}
                </MuiTypography>
              </MuiBox>
              <Svg
                set="material"
                name="account-circle"
                size="40px"
                accessibilityLabel="Guest Home"
              />
            </CursorMuiBox>
          ) : (
            <>
              {quickSearchEnabled ? <GlobalSearch /> : null}
              <CursorMuiBox
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                onClick={handleUserClick}
                data-testid="header-avatar"
              >
                <MuiBox p={1}>
                  <MuiTypography
                    color={Color.primary}
                    fontWeight={FontWeight.semibold}
                    data-testid="first-name-last-name"
                  >
                    {consumerName}
                  </MuiTypography>
                </MuiBox>
                <UserAvatar
                  size={40}
                  borderColor="none"
                  avatarUrl={currentUserAvatar}
                  fallback={consumerName}
                  backgroundColor={Color.primary}
                  initialsFontColor={Color.white}
                />
              </CursorMuiBox>
            </>
          )}
        </MuiBox>
      </MuiBox>
      <NavigateToProtectedRouteModal />
    </StyledAppBar>
  );
};

const mapStateToProps = (state: RootState) => ({
  consumer: currentAccountConsumerSelector(state),
  currentUserAvatar: currentUserAvatarSelector(state),
  rootConsumer: profileConsumerSelector(state),
  pmmcToken: pmmcTokenSelector(state),
  pmmcTokenFetching: pmmcTokenFetchingSelector(state)
});

const mapDispatchToProps = (dispatch: Function) => ({
  logout: () => dispatch(logout()),
  navigateToProtectedRoute: (route: string) => dispatch(navigateToProtectedRoute(route))
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
