/**
 * @deprecated
 * Please use MuiButton from theme/material-ui
 */

import React from 'react';
import Box from 'components/UI/Layout/Box';
import { Color } from 'modules/styles/colors';
import {
  BorderRadius,
  Elevation,
  FontFamily,
  FontSize,
  IconSize,
  Spacing
} from 'modules/styles/variables';
import { ActivityIndicator } from 'react-native';
import { MuiBox } from 'theme/material-ui';
import { Icon } from 'components/Icon';
import { toSentenceCase } from 'modules/utils/StringUtils';
import styled, { css } from 'styled-components';

type ButtonVariant =
  | 'primary'
  | 'secondary'
  | 'flat'
  | 'flat-secondary'
  | 'outline'
  | 'outline-selected'
  | 'web-primary'
  | 'web-secondary';

interface ButtonStyleProps {
  variant: ButtonVariant;
  disabled?: boolean;
  fullWidth?: boolean;
}

interface ButtonProps extends ButtonStyleProps {
  children: React.ReactNode;
  icon?: string;
  iconColor?: string;
  iconSize?: number;
  iconSet?: string;
  testID?: string;
  reverse?: boolean;
  isLoading?: boolean;
  styles?: React.CSSProperties;
  onPress?: () => void;
}

const defaultButtonStyles = css`
  border-radius: ${BorderRadius.base};
  border-width: 1px;
  align-items: center;
  height: 36px;
  padding: 0px ${Spacing.medium}px;
  justify-content: center;
  box-shadow: ${Elevation.el3};
`;

const fullWidthStyles = css`
  width: 100%;
`;

const getWidthStyles = (props: ButtonStyleProps) => {
  return props.fullWidth ? fullWidthStyles : null;
};

const primaryButtonStyles = css`
  background-color: ${Color.primary};
  border-color: ${Color.primary};
`;

const secondaryButtonStyles = css`
  background-color: ${Color.primaryHue2};
  border-color: ${Color.primaryHue2};
`;

const flatButtonStyles = css`
  box-shadow: ${Elevation.el0};
  border-color: transparent;
`;

const outlineButtonStyles = css`
  background-color: transparent;
  border: 1px solid ${Color.black};
  border-radius: ${BorderRadius.base}px;
  box-shadow: none;
  text-align: center;
  padding: ${Spacing.medium}px;
  min-width: 150px;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-transform: capitalize;
  cursor: pointer;
`;

const outlineSelectedButtonStyles = css`
  background-color: ${Color.purple};
  border-radius: ${BorderRadius.base}px;
  box-shadow: none;
  text-align: left;
  padding: ${Spacing.medium}px;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-transform: capitalize;
  min-width: 150px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  cursor: pointer;
`;

const webPrimaryStyles = css`
  background-color: ${Color.primary};
  text-transform: capitalize;
  text-align: center;
  letter-spacing: 1px;
  border-radius: ${BorderRadius.small}px;
  box-shadow: none;
  margin: 0 auto;
  height: 40px;
  padding: 0;
  display: flex;
  cursor: pointer;
`;

const webSecondaryStyles = css`
  background-color: transparent;
  width: 275px !important;
  height: 40px;
  box-shadow: none;
  margin: 0 auto;
  cursor: pointer;
`;

const getDisabledButtonStyles = (props: ButtonStyleProps) => {
  if (!props.disabled) {
    return null;
  }

  return css`
    box-shadow: ${Elevation.el0};
    opacity: 0.5;
  `;
};

const getButtonVariantStyles = (props: ButtonStyleProps) => {
  switch (props.variant) {
    case 'web-primary':
      return webPrimaryStyles;
    case 'web-secondary':
      return webSecondaryStyles;
    case 'flat':
    case 'flat-secondary':
      return flatButtonStyles;
    case 'outline':
      return outlineButtonStyles;
    case 'outline-selected':
      return outlineSelectedButtonStyles;
    case 'secondary':
      return secondaryButtonStyles;
    case 'primary':
    default:
      return primaryButtonStyles;
  }
};

const defaultTextStyles = css`
  color: ${Color.foreColor};
  font-family: ${FontFamily.primary};
  font-size: ${FontSize.base};
  background-color: transparent;
`;

const primaryTextStyles = css`
  color: ${Color.foreColor};
`;

const secondaryTextStyles = css`
  color: ${Color.foreColor};
`;

const flatTextStyles = css`
  color: ${Color.foreColor};
`;

const flatSecondaryTextStyles = css`
  color: ${Color.primary};
`;

const outlineTextStyles = css`
  color: ${Color.black};
  font-size: ${FontSize.small};
`;

const outlineSelectedTextStyles = css`
  font-size: ${FontSize.small};
`;

const webPrimaryTextStyles = css`
  color: ${Color.white};
  text-transform: capitalize;
  text-align: center;
  letter-spacing: 1px;
  padding: ${Spacing.medium}px;
`;

const webSecondaryTextStyles = css`
  color: ${Color.primary};
  text-transform: capitalize;
  text-align: center;
  letter-spacing: 1px;
  padding: ${Spacing.medium}px;
`;

const getTextVariantStyles = (props: ButtonStyleProps) => {
  switch (props.variant) {
    case 'web-primary':
      return webPrimaryTextStyles;
    case 'web-secondary':
      return webSecondaryTextStyles;
    case 'flat':
      return flatTextStyles;
    case 'flat-secondary':
      return flatSecondaryTextStyles;
    case 'outline':
      return outlineTextStyles;
    case 'outline-selected':
      return outlineSelectedTextStyles;
    case 'secondary':
      return secondaryTextStyles;
    case 'primary':
    default:
      return primaryTextStyles;
  }
};

export const ButtonTouchable = styled.div`
  ${defaultButtonStyles};
  ${getButtonVariantStyles};
  ${getDisabledButtonStyles};
  ${getWidthStyles};
  cursor: pointer;
`;

export const ButtonText = styled.div`
  ${defaultTextStyles};
  ${getTextVariantStyles};
`;

export default function Button({
  onPress,
  icon,
  iconSize,
  iconColor,
  iconSet,
  reverse,
  children,
  isLoading,
  styles,
  testID,
  ...rest
}: ButtonProps) {
  const direction = reverse ? 'row-reverse' : 'row';

  return (
    <ButtonTouchable
      alignItems="center"
      accessibilityLabel={testID}
      {...rest}
      onClick={onPress}
      data-testid={testID}
    >
      <MuiBox alignItems="center" justifyContent="space-between" flexDirection={direction}>
        {isLoading ? (
          <ActivityIndicator size="small" />
        ) : (
          <div style={styles}>
            <ButtonText {...rest}>{toSentenceCase(children)}</ButtonText>
            {icon && (
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Box hOffsetLeft={Spacing.small} />
                <Icon set={iconSet} name={icon} size={iconSize} color={iconColor} />
              </div>
            )}
          </div>
        )}
      </MuiBox>
    </ButtonTouchable>
  );
}

Button.defaultProps = {
  variant: 'primary',
  iconSize: IconSize.base,
  iconColor: Color.white,
  reverse: false
};
