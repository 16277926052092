import React, { ComponentType, useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';

import { MuiContainer, MuiTypography, MuiBox } from 'theme/material-ui';
import * as covidScreeningSelectors from 'store/CovidScreening/selectors';
import { RootState } from 'store/types';
import { AuthType } from 'store/authentication/types';
import { OrderStatus } from 'store/CovidScreening/types';
import { CovidTitles } from 'lib/constants/covidScreening';
import { AnalyticsEvent } from 'services/AnalyticsService';
import { useLanguageSwitcher } from 'lib/hooks/useLanguageSwitcher';
import { translations } from 'lib/constants/translations/screens/covidGuest/submissionError';
import {
  ButtonsNavigation_translations,
  CovidTestGuestFooter_translations
} from 'lib/constants/translations/components/index';

import Spacer from 'components/UI/Layout/Spacer';
import Footer from 'components/common/Footer';
import useNavigationAnalytics from 'hooks/useNavigationAnalytics';

import CovidGuestGuard from '../navigation/CovidGuestLeavingGuard';
import { CovidTestButton } from '../../CovidScreening/Components/CovidTestButton';

export interface Props extends RouteComponentProps {
  covidScreeningOrderStatus: OrderStatus;
}

export function CovidErrorComponent({ history, covidScreeningOrderStatus }: Props) {
  const covidScreeningClosed = useNavigationAnalytics(AnalyticsEvent.CovidScreeningClosed);
  const covidScreeningError = useNavigationAnalytics(AnalyticsEvent.CovidOrderError);
  const screenText = useLanguageSwitcher({
    en: {
      ...translations.en,
      ...ButtonsNavigation_translations.en,
      ...CovidTestGuestFooter_translations.en
    },
    es: {
      ...translations.es,
      ...ButtonsNavigation_translations.es,
      ...CovidTestGuestFooter_translations.es
    }
  });

  const getErrorMessage = () => {
    if (covidScreeningOrderStatus === 500) return 'Server Error';
    if (covidScreeningOrderStatus === 401) return 'Unauthorized';
    return 'Bad Request';
  };
  useEffect(() => {
    covidScreeningError({
      covid19_order: 'Failed',
      errorCode: covidScreeningOrderStatus,
      errorMessage: getErrorMessage(),
      title: CovidTitles.ERROR_PAGE,
      authentication_type: AuthType.UNAUTHENTICATED
    });
    window.history.pushState(null, document.title, window.location.href);
  }, []);

  const handleClose = () => {
    covidScreeningClosed({
      title: CovidTitles.ERROR_PAGE,
      authentication_type: AuthType.UNAUTHENTICATED
    });
    history.push('/guest-home');
  };
  return (
    <>
      <CovidGuestGuard />
      <MuiContainer maxWidth="lg">
        <Spacer size="medium" />
        <MuiBox pl={4} my={2}>
          <MuiTypography variant="h4" align="center" color="red">
            {screenText.paragraph}
          </MuiTypography>
        </MuiBox>
        <Spacer size="medium" />
        <MuiBox my={2}>
          <MuiTypography align="center">{screenText.try_again}</MuiTypography>
        </MuiBox>
        <Spacer size="medium" />
        <MuiBox width="30%" margin="0 auto" alignItems="center">
          <CovidTestButton
            fullWidth
            color="primary"
            variant="contained"
            onClick={handleClose}
            data-testid="error-screen-close-button"
          >
            {screenText.close}
          </CovidTestButton>
        </MuiBox>
        <Spacer size="medium" />
      </MuiContainer>
      <Spacer size="medium" />
      <Footer
        headerText={screenText.covidInfo}
        link={screenText.CovidPatientSelectHelpline}
        linkName={screenText.CovidPatientSelectHelpline}
        helplineHours={screenText.CovidHelplineHours}
        title={CovidTitles.ERROR_PAGE}
        authenticated={false}
      />
    </>
  );
}

const mapStateToProps = (state: RootState) => ({
  covidScreeningOrderStatus: covidScreeningSelectors.CovidScreeningOrderStatus(state)
});

export default connect(mapStateToProps)(CovidErrorComponent as ComponentType);
