import React from 'react';

export default () => ({
  svg: (
    <path
      fill="#000"
      fillRule="evenodd"
      d="M9.5 1.5a.75.75 0 0 0-.75.75V4.5h13.5V2.25a.75.75 0 0 0-.75-.75h-12ZM20.75 6h-10.5v5.25a.75.75 0 0 1-1.5 0V6a1.5 1.5 0 0 1-1.5-1.5V2.25A2.25 2.25 0 0 1 9.5 0h12a2.25 2.25 0 0 1 2.25 2.25V4.5a1.5 1.5 0 0 1-1.5 1.5v15.75A2.25 2.25 0 0 1 20 24h-9a2.25 2.25 0 0 1-2.25-2.25.75.75 0 0 1 1.5 0 .75.75 0 0 0 .75.75h9a.75.75 0 0 0 .75-.75V19.5H14a.75.75 0 0 1-.75-.75v-7.5a.75.75 0 0 1 .75-.75h6.75V6Zm0 6h-6v6h6v-6ZM8.717 14.25a1.539 1.539 0 0 0-1.087.45L5.57 16.76l2.174 2.174 2.06-2.058a1.539 1.539 0 0 0-1.087-2.625Zm-2.034 5.744-2.175-2.175-2.058 2.056a1.538 1.538 0 0 0 2.175 2.175l2.058-2.056ZM4.007 16.2a.716.716 0 0 0-.057.057l-2.56 2.557a3.038 3.038 0 0 0 4.295 4.296l5.18-5.174a3.042 3.042 0 0 0 .659-3.31 3.037 3.037 0 0 0-4.954-.987L4.007 16.2Z"
      clipRule="evenodd"
    />
  ),
  viewBox: '0 0 24 24'
});
