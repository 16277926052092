import * as React from 'react';
import { BoldText } from 'components/FindProvider/ProviderContacts/styled';
import Card from 'components/UI/Card/Card';
import FlexBox from 'components/UI/Layout/FlexBox';
import Spacer from 'components/UI/Layout/Spacer';
import Typography from 'components/UI/Typography';
import Divider from 'components/UI/Divider';
import Container from '@material-ui/core/Container';
import { FontWeight, Spacing, FontSize } from 'modules/styles/variables';
import { MuiBox, MuiLink } from 'theme/material-ui';
import { ApplicationStatusColor } from 'screens/Billing/constants';
import styled from 'styled-components';
import { PATIENT_FINANCIAL_SERVICE_NUMBER } from 'screens/Billing/FinancialAssistance/FAApplication/constants';
import { FinancialAssistanceApplication as Application } from 'store/billing/types';
import { ListItem, SubInnerItem, SubItem } from '../../FAApplication/styled';
import { ADDRESS_INFO, ApplicationStatus, FA_SUBMIT_INFO } from '../../FAStatus/constant';
import { GrayBar, GreyViewBox, BlueTextLabel } from '../../styled';
import { Color } from 'modules/styles/colors';
import { formatDate } from 'modules/utils/DateUtils';

export interface StatusInfo {
  applicationId: string;
  lastModified: string;
  navigateToFAInit?: () => {};
}

interface ListItem {
  type: string;
  title: string;
}

const renderList = (item: ListItem) => {
  switch (item.type) {
    case 'item': {
      return (
        <ListItem key={item.title} accessibilityLabel={item.title}>
          <Typography fontWeight={FontWeight.semibold}>{item.title}</Typography>
        </ListItem>
      );
    }
    case 'subItem': {
      return (
        <SubItem key={item.title} accessibilityLabel={item.title}>
          <Typography>&bull; &nbsp;{item.title}</Typography>
        </SubItem>
      );
    }
    case 'subInnerItem': {
      return (
        <SubInnerItem key={item.title} accessibilityLabel={item.title}>
          <Typography>&ndash; &nbsp;{item.title}</Typography>
        </SubInnerItem>
      );
    }
    default:
      return null;
  }
};

export const FinancialAssistance = () => {
  return (
    <MuiLink data-testid="service-number" href={`tel: ${PATIENT_FINANCIAL_SERVICE_NUMBER}`}>
      {PATIENT_FINANCIAL_SERVICE_NUMBER}
    </MuiLink>
  );
};

export const ListOfDocs = () => {
  return (
    <>
      <MuiBox display="flex" flexDirection="column" alignSelf="center" justifyContent="center">
        {FA_SUBMIT_INFO.map(item => renderList(item))}

        <Spacer spacing="medium" />
        <Divider />
        <ListItem key={ADDRESS_INFO.title} accessibilityLabel={ADDRESS_INFO.title}>
          <Typography>{ADDRESS_INFO.title}</Typography>
        </ListItem>
        <Spacer spacing="medium" />
        <Divider />
      </MuiBox>
      <MuiBox paddingBottom={Spacing.small}>
        <Typography>
          You are still responsible for paying the bills on time. If you have any new bills in
          addition to the above, please reapply for financial assistance or call Patient Financial
          Services at <FinancialAssistance />.
        </Typography>
      </MuiBox>
    </>
  );
};

export const ListOfDocs2 = () => {
  return (
    <>
      <Spacer spacing="large" />
      <Card>
        <MuiBox alignItems="center" justifyContent="center">
          <BlueTextLabel>
            <BoldText>{ADDRESS_INFO.emailLabel}</BoldText>
          </BlueTextLabel>
          <Typography textAlign="center">
            <BlueTextLabel>{ADDRESS_INFO.email}</BlueTextLabel>
          </Typography>

          <Spacer spacing="medium" />

          <Typography fontWeight={FontWeight.semibold} textAlign="center">
            OR
          </Typography>

          <Spacer spacing="medium" />

          <BlueTextLabel textAlign="center">
            <BoldText>{ADDRESS_INFO.addressLabel}</BoldText>
          </BlueTextLabel>

          <Spacer spacing="small" />

          <BlueTextLabel textAlign="center">
            <BoldText>{ADDRESS_INFO.address}</BoldText>
          </BlueTextLabel>
          <Typography textAlign="center">
            <BlueTextLabel>{ADDRESS_INFO.addressLine1}</BlueTextLabel>
            <Spacer spacing="small" />
            <BlueTextLabel>{ADDRESS_INFO.addressLine2}</BlueTextLabel>
          </Typography>

          <Spacer spacing="medium" />
          <BlueTextLabel textAlign="center">
            <Typography fontWeight={FontWeight.semibold} textAlign="center">
              OR
            </Typography>
          </BlueTextLabel>

          <Spacer spacing="medium" />
          <BlueTextLabel textAlign="center">
            <BoldText>{ADDRESS_INFO.localVisit}</BoldText>
          </BlueTextLabel>

          <Spacer spacing="medium" />
        </MuiBox>
      </Card>
    </>
  );
};

export const Row = styled(FlexBox)`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const Section = styled(FlexBox)`
  padding-top: ${Spacing.large}px;
  padding-bottom: ${Spacing.large}px;
  width: 100%;
`;

export interface ApplicationSectionProps {
  application: Partial<Application>;
}

export const ApplicationSection = ({ application }: { application: Application }) => {
  return (
    <Section>
      <MuiBox display="flex" alignItems="flex-start" flexDirection="row">
        <GreyViewBox>Last Updated: </GreyViewBox>
        <Typography fontWeight={FontWeight.semibold}>
          {' '}
          {formatDate(new Date(application?.updateDate))}
        </Typography>
      </MuiBox>
      <Spacer size="small" />
    </Section>
  );
};

export const GreyViewHeader = ({ application }: { application: Application }) => {
  const status = application.status || '';
  const statusColor = ApplicationStatusColor[status];
  const ApplicationText = application?.detailedStatus || '';
  const showName = ApplicationStatus.APPROVED === status;

  return (
    <GrayBar>
      <Container>
        <Spacer size="small" />
        <MuiBox display="flex" alignItems="flex-start" flexDirection="row">
          {showName && (
            <>
              <Typography fontSize={FontSize.large} fontWeight={FontWeight.semibold}>{`${application
                ?.guarantor?.firstName || ''}
                ${application?.guarantor?.lastName || ''}`}</Typography>
              <GreyViewBox>Application: </GreyViewBox>
              <Typography fontWeight={FontWeight.semibold} color={Color.primary}>
                {`${ApplicationText}`}{' '}
              </Typography>
            </>
          )}

          {!showName && (
            <>
              <GreyViewBox>Application Number: </GreyViewBox>
              <Typography>{`${application?.id || ''}`}</Typography>
              <GreyViewBox>Status: </GreyViewBox>
              <Typography color={statusColor}>{`${ApplicationText}`} </Typography>
            </>
          )}
        </MuiBox>
        <Spacer size="small" />
      </Container>
    </GrayBar>
  );
};
