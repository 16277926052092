import { RouteData } from 'screens/navigation';
import { GUEST_ROUTES } from 'screens/Guest/constants';
import GuestHomeScreen from 'screens/Guest/GuestHomeScreen';

const guestIntroductionNavigation: RouteData[] = [
  {
    path: GUEST_ROUTES.GUEST_HOME,
    component: GuestHomeScreen,
    componentName: 'GuestHome',
    title: 'Guest Home',
    includeBanner: true,
    includeDefaultLayout: true
  }
];

export default guestIntroductionNavigation;
