import Box from '@material-ui/core/Box';
import FormLabel from '@material-ui/core/FormLabel';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { useField } from 'formik';
import React from 'react';
import { RequiredTextField } from '../styled';
import useStyles from '../useStyles';
import FieldError from './FieldError';
import { getRegisterBrand } from 'store/register';

export interface Props {
  name: string;
  label: string;
  required: boolean;
  tooltip?: React.FC<any>;
}

const CustomDatepicker = ({ label, required, tooltip, ...props }: Props) => {
  const redirectBrand = getRegisterBrand();
  const classes = useStyles({ redirectBrand });
  const [field, meta, helpers] = useField(props);
  const hasError = meta.touched && !!meta.error;

  return (
    <>
      {label && (
        <FormLabel error={hasError} className={classes.label}>
          {label} {required ? <RequiredTextField>*</RequiredTextField> : null} {tooltip || ''}
        </FormLabel>
      )}
      <Box>
        <KeyboardDatePicker
          className={classes.customTextField}
          disableFuture
          disableToolbar
          autoOk
          openTo="year"
          views={['year', 'month', 'date']}
          variant="inline"
          inputVariant="outlined"
          format="MM/DD/YYYY"
          placeholder="MM/DD/YYYY"
          fullWidth
          {...field}
          {...props}
          onChange={date => helpers.setValue(date)}
          invalidDateMessage={null}
          maxDateMessage={null}
          minDateMessage={null}
          error={hasError}
          KeyboardButtonProps={{
            'aria-label': 'change date'
          }}
          onClose={() => helpers.setTouched(true)}
        />
      </Box>
      <FieldError error={hasError && meta.error} />
    </>
  );
};

export default CustomDatepicker;
