import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import FlexBox from 'components/UI/Layout/FlexBox';
import Spinner from 'components/UI/Spinner/Spinner';
import { Svg } from 'components/UI/Svg';
import { Typography } from 'components/UI/Typography/styled';
import { Color } from 'modules/styles/colors';
import { FontSize, FontWeight, IconSize, Spacing } from 'modules/styles/variables';
import LikelyCausesList from 'screens/TriageGyant/ResultsScreens/Results/LikelyCausesList';
import TriageHeader from 'screens/TriageGyant/TriageGyantHeader';
import { TRIAGE_ROUTES } from 'screens/TriageGyant/constants';
import { getTriageResults } from 'store/triageGyant/actions';
import {
  triageGyantConditionsSortedSelector,
  triageGyantCurrentCaseIdSelector,
  triageGyantResultsIsFetchingSelector
} from 'store/triageGyant/selectors';
import { TriageCondition } from 'store/triageGyant/types';
import { RootState } from 'store/types';
import { CursorMuiBox, MuiBox, MuiContainer, MuiDivider, MuiTypography } from 'theme/material-ui';

export interface Props {
  getTriageResults: typeof getTriageResults;
  sortedConditions: TriageCondition[];
  isFetching: boolean;
  currentCaseId: string;
}

export function TriageCausesListComponent(props: Props) {
  const { sortedConditions, currentCaseId } = props;
  const { caseId = '' } = useParams();
  const history = useHistory();

  useEffect(() => {
    if (currentCaseId !== caseId) {
      props.getTriageResults(caseId);
    }
  }, []);

  const onConditionDetailPress = (conditionId: string) => {
    history.push(`${TRIAGE_ROUTES.CAUSE_DETAIL.BASE_URL}/${conditionId}`);
  };

  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <MuiBox display="flex" flexDirection="column" height="100%" bgcolor={Color.grayLight3}>
      <TriageHeader />
      {props.isFetching ? (
        <MuiBox height="100%" pt={Spacing.xLarge} display="flex" justifyContent="center">
          <Spinner />
        </MuiBox>
      ) : (
        <MuiContainer maxWidth="lg">
          <MuiBox display="flex" flexDirection="column" alignItems="center">
            <MuiBox width="100%" maxWidth={800} mx={Spacing.small}>
              <MuiBox mx={Spacing.small} mt={Spacing.medium} mb={Spacing.small} textAlign="center">
                <MuiTypography
                  variant="h2"
                  color={Color.primary}
                  fontSize={FontSize.title}
                  fontWeight={FontWeight.semibold}
                >
                  Possible Causes
                </MuiTypography>
              </MuiBox>
              <MuiDivider />
            </MuiBox>
            <MuiBox width="100%" maxWidth={700} mx={Spacing.small} mt={Spacing.medium}>
              <CursorMuiBox>
                <FlexBox
                  flexDirection="row"
                  alignItems="center"
                  vSpacingBottom={Spacing.xLarge}
                  onClick={handleGoBack}
                >
                  <Svg
                    name="ChevronLeft"
                    size={IconSize.small}
                    color={Color.secondary}
                    style={{ marginRight: Spacing.medium }}
                  />
                  <Typography tag="link">Back</Typography>
                </FlexBox>
              </CursorMuiBox>
              <LikelyCausesList
                causes={sortedConditions}
                onItemPressed={onConditionDetailPress}
                buttonText="Learn More"
              />
            </MuiBox>
          </MuiBox>
        </MuiContainer>
      )}
    </MuiBox>
  );
}

const mapDispatch = {
  getTriageResults
};

const mapState = (state: RootState) => ({
  sortedConditions: triageGyantConditionsSortedSelector(state),
  isFetching: triageGyantResultsIsFetchingSelector(state),
  currentCaseId: triageGyantCurrentCaseIdSelector(state)
});

export default connect(mapState, mapDispatch)(TriageCausesListComponent);
