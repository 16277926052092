import * as React from 'react';

export default () => {
  return {
    svg: (
      <g id="prefix__Immunizations">
        <path
          fill="none"
          d="M53.2758 5.00006L37.8633 20.4126"
          stroke="url(#paint0_linear_2404_1766)"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fill="none"
          d="M33.5963 21.0225L34.2063 20.4125C35.2151 19.4038 36.8526 19.4025 37.8626 20.4125C38.8726 21.4225 38.8726 23.0613 37.8626 24.0713L37.2538 24.6801"
          stroke="url(#paint1_linear_2404_1766)"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fill="none"
          d="M8.76135 41.6937L30.2951 20.1599C30.9689 19.4862 32.0614 19.4862 32.7339 20.1599L38.1151 25.5412C38.7889 26.2137 38.7889 27.3074 38.1151 27.9799L16.5826 49.5137"
          stroke="url(#paint2_linear_2404_1766)"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fill="none"
          d="M7.54309 40.4755L17.8006 50.733"
          stroke="url(#paint3_linear_2404_1766)"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fill="none"
          d="M7.58613 46.3795L4.99988 48.9657"
          stroke="url(#paint4_linear_2404_1766)"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fill="none"
          d="M11.8964 50.6898L9.31018 53.276"
          stroke="url(#paint5_linear_2404_1766)"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fill="none"
          d="M3.27576 48.1037L10.172 55"
          stroke="url(#paint6_linear_2404_1766)"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fill="none"
          d="M13.2283 37.2285L15.6658 39.666"
          stroke="url(#paint7_linear_2404_1766)"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fill="none"
          d="M19.3234 31.1326L21.7609 33.5701"
          stroke="url(#paint8_linear_2404_1766)"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fill="none"
          d="M25.4192 25.0373L27.8567 27.4748"
          stroke="url(#paint9_linear_2404_1766)"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fill="none"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M53.2766 12.8361C53.2766 12.8361 49.8266 16.1948 49.8279 18.8823C49.8291 20.7373 51.3729 22.2411 53.2766 22.2411H53.2754C55.1779 22.2411 56.7229 20.7373 56.7241 18.8823C56.7254 16.1948 53.2754 12.8361 53.2754 12.8361H53.2766Z"
          stroke="url(#paint10_linear_2404_1766)"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <defs>
          <linearGradient
            id="paint0_linear_2404_1766"
            x1={37.8633}
            y1={12.7063}
            x2={53.2758}
            y2={12.7063}
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0.0843751} stopColor="#FF0048" />
            <stop offset={0.386458} stopColor="#DF36FF" />
            <stop offset={0.980208} stopColor="#4A00E2" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_2404_1766"
            x1={33.5963}
            y1={22.1678}
            x2={38.6201}
            y2={22.1678}
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0.0843751} stopColor="#FF0048" />
            <stop offset={0.386458} stopColor="#DF36FF" />
            <stop offset={0.980208} stopColor="#4A00E2" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_2404_1766"
            x1={8.76135}
            y1={34.5841}
            x2={38.6204}
            y2={34.5841}
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0.0843751} stopColor="#FF0048" />
            <stop offset={0.386458} stopColor="#DF36FF" />
            <stop offset={0.980208} stopColor="#4A00E2" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_2404_1766"
            x1={7.54309}
            y1={45.6043}
            x2={17.8006}
            y2={45.6043}
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0.0843751} stopColor="#FF0048" />
            <stop offset={0.386458} stopColor="#DF36FF" />
            <stop offset={0.980208} stopColor="#4A00E2" />
          </linearGradient>
          <linearGradient
            id="paint4_linear_2404_1766"
            x1={4.99988}
            y1={47.6726}
            x2={7.58613}
            y2={47.6726}
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0.0843751} stopColor="#FF0048" />
            <stop offset={0.386458} stopColor="#DF36FF" />
            <stop offset={0.980208} stopColor="#4A00E2" />
          </linearGradient>
          <linearGradient
            id="paint5_linear_2404_1766"
            x1={9.31018}
            y1={51.9829}
            x2={11.8964}
            y2={51.9829}
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0.0843751} stopColor="#FF0048" />
            <stop offset={0.386458} stopColor="#DF36FF" />
            <stop offset={0.980208} stopColor="#4A00E2" />
          </linearGradient>
          <linearGradient
            id="paint6_linear_2404_1766"
            x1={3.27576}
            y1={51.5518}
            x2={10.172}
            y2={51.5518}
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0.0843751} stopColor="#FF0048" />
            <stop offset={0.386458} stopColor="#DF36FF" />
            <stop offset={0.980208} stopColor="#4A00E2" />
          </linearGradient>
          <linearGradient
            id="paint7_linear_2404_1766"
            x1={13.2283}
            y1={38.4472}
            x2={15.6658}
            y2={38.4472}
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0.0843751} stopColor="#FF0048" />
            <stop offset={0.386458} stopColor="#DF36FF" />
            <stop offset={0.980208} stopColor="#4A00E2" />
          </linearGradient>
          <linearGradient
            id="paint8_linear_2404_1766"
            x1={19.3234}
            y1={32.3513}
            x2={21.7609}
            y2={32.3513}
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0.0843751} stopColor="#FF0048" />
            <stop offset={0.386458} stopColor="#DF36FF" />
            <stop offset={0.980208} stopColor="#4A00E2" />
          </linearGradient>
          <linearGradient
            id="paint9_linear_2404_1766"
            x1={25.4192}
            y1={26.256}
            x2={27.8567}
            y2={26.256}
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0.0843751} stopColor="#FF0048" />
            <stop offset={0.386458} stopColor="#DF36FF" />
            <stop offset={0.980208} stopColor="#4A00E2" />
          </linearGradient>
          <linearGradient
            id="paint10_linear_2404_1766"
            x1={49.8279}
            y1={17.5386}
            x2={56.7241}
            y2={17.5386}
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0.0843751} stopColor="#FF0048" />
            <stop offset={0.386458} stopColor="#DF36FF" />
            <stop offset={0.980208} stopColor="#4A00E2" />
          </linearGradient>
        </defs>
      </g>
    ),
    viewBox: '0 0 60 60'
  };
};
