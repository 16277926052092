import { exitModalText } from 'modules/constants/exitConfirmModal';

const GET_CARE_ROUTE = '/u/get-care-now';
const BASE_ROUTE = `${GET_CARE_ROUTE}/connect-care`;
const FORM_ROUTE = `${BASE_ROUTE}/form`;

export const ROUTE = {
  landing: '/landing',
  getCare: GET_CARE_ROUTE,
  base: BASE_ROUTE,
  login: `${BASE_ROUTE}/login`,
  form: FORM_ROUTE,
  visit: `${FORM_ROUTE}/waiting-room`
};

// Dialogs
export const EXPIRED_SESSION = {
  title: 'Session has expired.',
  subtitle: 'You have been logged out due to inactivity. Please re-login to Connect Care.',
  severity: 'error' as 'error'
};

export const ACTIVE_VISIT = {
  title: 'You have an active Connect Care visit.',
  subtitle:
    'It seems that you are still in a visit with a provider. Would you like to rejoin the visit?'
};

export const EXIT_MODAL_TEXT = exitModalText('Connect Care');
