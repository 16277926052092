import React from 'react';

export default (props: any) => ({
  svg: (
    <g
      id="GC-Get-Care-Now---Emotional-Support---Web"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="GC-Get-Care-Emotional-Support"
        transform="translate(-964.000000, -541.000000)"
        fill={props.color ? props.color : '#107686'}
      >
        <path
          d="M986.519655,560.640773 C983.699748,563.347561 979.11226,563.347561 976.292676,560.640773 L968.540762,553.198972 C965.72134,550.492184 965.72134,546.088062 968.540762,543.381275 C968.575715,543.347719 968.623938,543.330787 968.672161,543.330787 C968.720546,543.330787 968.768769,543.347719 968.80356,543.381275 L973.785732,548.164136 C973.855801,548.231246 973.855639,548.349311 973.785732,548.416577 L970.66791,551.409516 L978.156702,558.598721 L981.274685,555.605782 C981.344754,555.538361 981.467901,555.538516 981.537484,555.605782 L986.519493,560.388488 C986.589724,560.455754 986.589562,560.573663 986.519655,560.640773 L986.519655,560.640773 Z M983.253767,553.957848 C982.234774,552.980086 980.577071,552.979776 979.558079,553.957848 L978.156702,555.303474 L974.100637,551.409516 L975.502176,550.0642 L975.502176,550.064045 C976.521006,549.085817 976.520845,547.494274 975.502176,546.516202 L970.520005,541.733496 C969.501013,540.755579 967.84331,540.755424 966.824317,541.733496 C963.058561,545.34876 963.058561,551.231487 966.824317,554.846751 L974.576231,562.288552 C976.459191,564.096184 978.932476,565 981.405923,565 C983.879532,565 986.352979,564.096029 988.235938,562.288397 C989.254768,561.310324 989.254606,559.718626 988.235938,558.740709 L983.253767,553.957848 Z"
          id="Icons-/-Phone"
        ></path>
      </g>
    </g>
  ),
  viewBox: '0 0 24 24'
});
