import React from 'react';
import {
  BloodPressureBelowCircleNote,
  BloodPressureLabel,
  BloodPressureLatestInner,
  BloodPressureNote,
  BloodPressureSys,
  BloodPressureTitle,
  Circle,
  Divider
} from './styled';
import FlexBox from 'components/UI/Layout/FlexBox';

interface Props {
  dateText: string;
  dbpValue: number;
  fontSize: string;
  maxWidth: number;
  sbpValue: number;
  title: string;
  backgroundColor?: string;
  fontColor?: string;
  marginTop: number;
  marginLeft?: number;
  paddingLeft?: number;
}
function BloodPressureCircle(props: Props) {
  const {
    backgroundColor,
    dateText,
    dbpValue,
    fontColor,
    fontSize,
    marginLeft,
    marginTop,
    maxWidth,
    sbpValue,
    title,
    paddingLeft
  } = props;
  return (
    <FlexBox flexDirection="column" justifyContent="center">
      <Circle
        backgroundColor={backgroundColor}
        maxWidth={maxWidth}
        marginTop={marginTop}
        marginLeft={marginLeft}
        paddingLeft={paddingLeft || marginLeft}
      >
        <BloodPressureLatestInner paddingLeft={title === 'Average' ? 10 : 0}>
          <BloodPressureSys>
            <BloodPressureTitle fontSize={`${fontSize}`}>{sbpValue}</BloodPressureTitle>
            <BloodPressureLabel>SBP</BloodPressureLabel>
          </BloodPressureSys>
          <Divider />
          <BloodPressureSys>
            <BloodPressureTitle fontColor={fontColor} fontSize={`${fontSize}`}>
              {dbpValue}
            </BloodPressureTitle>
            <BloodPressureLabel>DBP</BloodPressureLabel>
          </BloodPressureSys>
        </BloodPressureLatestInner>
        <BloodPressureNote width={title === 'Average' ? '80px' : 'auto'}>
          {dateText}
        </BloodPressureNote>
      </Circle>
      <BloodPressureBelowCircleNote>
        {title}
        <br /> Blood Pressure
      </BloodPressureBelowCircleNote>
    </FlexBox>
  );
}
export default BloodPressureCircle;
