import { TermsAndConditionsType, TermsAndConditions } from 'store/global/reducers';

interface TermsDetails {
  acceptNavigation: string;
  html?: string;
}

export const TermsAndConditionsAcceptanceMap: Record<TermsAndConditionsType, TermsDetails> = {
  [TermsAndConditionsType.COST_ESTIMATOR]: {
    acceptNavigation: 'EstimateCost'
  },
  [TermsAndConditionsType.PROVIDER_SEARCH]: {
    acceptNavigation: 'FindProvider'
  },
  [TermsAndConditionsType.TRIAGE_DISCLAIMER]: {
    acceptNavigation: 'TriageGyant'
  },
  [TermsAndConditionsType.CHANGE_HEALTH]: {
    acceptNavigation: ''
  },
  [TermsAndConditionsType.GENERAL]: {
    acceptNavigation: ''
  },
  [TermsAndConditionsType.E_VISIT_PRIVACY_NOTICE]: {
    acceptNavigation: ''
  },
  [TermsAndConditionsType.E_VISIT_CONSENT_TO_TREAT]: {
    acceptNavigation: ''
  },
  [TermsAndConditionsType.E_VISIT_TERMS_OF_USE]: {
    acceptNavigation: ''
  }
};

export const mapTermsAndConditionsDetails = (
  type?: TermsAndConditionsType,
  terms?: TermsAndConditions
) => {
  if (!type || !terms || !TermsAndConditionsAcceptanceMap[type]) {
    return {
      content: 'Terms of use unavailable',
      acceptNavigation: 'Home',
      version: 0
    };
  }

  return {
    content: String.raw`
      ${terms[type].content}
    `,
    acceptNavigation: TermsAndConditionsAcceptanceMap[type].acceptNavigation
  };
};

export const mapTermsAndConditionsTypeToExternalAcceptanceType = (type: TermsAndConditionsType) => {
  return Object.keys(TermsAndConditionsType).find(
    key => TermsAndConditionsType[key as keyof typeof TermsAndConditionsType] === type
  );
};
