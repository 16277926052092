import React from 'react';

const st0 = {
  fill: 'none',
  strokeWidth: 2,
  strokeLinecap: 'round',
  strokeMiterlimit: 10
} as const;

const st1 = {
  ...st0,
  strokeMiterlimit: 10
} as const;

const st2 = {
  fill: 'none',
  strokeWidth: 2,
  strokeMiterlimit: 10
} as const;

export default {
  svg: (
    <g fill="none">
      <path
        {...st0}
        d="M27.3,24.4c1,0,1.7,0.8,1.7,1.7l0,0c0,1-0.8,1.7-1.7,1.7H3.7c-1,0-1.7-0.8-1.7-1.7l0,0c0-1,0.8-1.7,1.7-1.7"
      />
      <path
        {...st1}
        d="M7,3.4l2,2.1l2.1-2.3l2.1,2.3l2.1-2.3l2.1,2.3l2.1-2.3l2.1,2.3L24,3.2l0,0v24.7H7V3.4L7,3.4z"
      />
      <line {...st2} x1="15.7" y1="8.1" x2="15.7" y2="14.4" />
      <line {...st2} x1="18.9" y1="11.3" x2="12.6" y2="11.3" />
      <line {...st0} x1="11.1" y1="16.9" x2="19.9" y2="16.9" />
      <line {...st0} x1="11.1" y1="20.6" x2="19.9" y2="20.6" />
      <line {...st0} x1="11.1" y1="24.4" x2="17.1" y2="24.4" />
    </g>
  ),
  viewBox: '0 0 30 30'
};
