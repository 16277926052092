import Screen from 'components/UI/Layout/Screen';
import React from 'react';
import { NavigationScreenProps } from 'screens/navigation';
import VisitSummaryList from './VisitSummaryList/VisitSummaryList';

export const VisitSummarySearchScreen = (props: NavigationScreenProps) => {
  return (
    <Screen safe>
      <VisitSummaryList navigation={props.navigation} />
    </Screen>
  );
};

export default VisitSummarySearchScreen;
