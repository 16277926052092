import React, { ComponentType, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import { Image } from 'react-native';
import {
  MuiBox,
  MuiButton,
  MuiContainer,
  MuiDivider,
  MuiGrid,
  MuiTypography,
  dfdDefaultTheme
} from 'theme/material-ui';

import analyticsService, { AmplitudeEventData, AnalyticsEvent } from 'services/AnalyticsService';

import Bg9 from 'assets/DashboardBanner/desktop/bg_9.jpg';

import * as bookingActions from 'store/booking/actions';
import * as bookingSelectors from 'store/booking/selectors';
import * as bookingTypes from 'store/booking/types';
import * as findProviderActions from 'store/findProvider/actions';
import { ProviderSummary } from 'store/findProvider/types';
import * as geoLocationSelectors from 'store/geolocation/selectors';
import { getMyDoctors } from 'store/myDoctors/actions';
import * as myDoctorsSelectors from 'store/myDoctors/selectors';
import * as routerSelectors from 'store/router/selectors';
import { RootState } from 'store/types';

import { Color } from 'modules/styles/colors';
import { FontSize, FontWeight, IconSize, Spacing } from 'modules/styles/variables';

import { InfoCard } from 'components/InfoCard';
import DataLoader from 'components/UI/DataLoader/DataLoader';
import FlexBox from 'components/UI/Layout/FlexBox';
import Spinner from 'components/UI/Spinner/Spinner';
import { Typography } from 'components/UI/Typography/styled';
import DashboardEmptyStateCard from 'screens/Dashboard/DashboardEmptyStateCard';

import { capitalizeEachWord } from 'modules/utils/StringUtils';
import { currentAccountConsumerSelector } from 'store/account/selectors';
import { BookingLoading } from '../components/sharedComponents';
import { StyledScreen } from '../components/styled';
import { useCheckBookingInfoEffect } from '../useCheckBookingInfoEffect';
import ProvidersSection from './providersSection';

export interface Props extends RouteComponentProps {
  appointmentDetails: bookingTypes.AppointmentDetails;
  doctorsLoading: boolean;
  doctors: ProviderSummary[];
  geolocationStatus: boolean;
  setGeoLocationForProvider: typeof findProviderActions.setGeoLocation;
  getMyDoctors: typeof getMyDoctors;
  setActiveProviderSearchFilter: typeof findProviderActions.setActiveProviderSearchFilter;
  setDoctor: typeof bookingActions.setDoctor;
  setSort: typeof findProviderActions.setSortBy;
  providerSearch: typeof findProviderActions.schedulingProviderSearch;
  currentRouteName?: string;
  previousRouteName?: string;
  setCorrelationId: typeof bookingActions.setCorrelationIDAction;
  patients: bookingTypes.Patient[];
}

export function BookingSpecialtyOrMyDoctorSelect({
  appointmentDetails,
  doctors,
  doctorsLoading,
  geolocationStatus,
  getMyDoctors,
  setDoctor,
  history,
  currentRouteName,
  previousRouteName,
  setCorrelationId,
  patients
}: Props) {
  const eventData: AmplitudeEventData = {
    currentUrl: currentRouteName,
    referringUrl: previousRouteName
  };
  const currentProfile = useSelector(currentAccountConsumerSelector);
  const isSmallScreen = useMediaQuery(dfdDefaultTheme.breakpoints.down('md'));
  const isLoading = doctorsLoading;

  useEffect(() => {
    setCorrelationId();
    getMyDoctors();
  }, []);

  useCheckBookingInfoEffect(appointmentDetails?.patient);

  const callAnalytics = (appointment_option: string) => {
    const helpUsedData: AmplitudeEventData = {
      ...eventData,
      appointment_option,
      isProxy:
        patients.length && appointmentDetails.patient?.consumerId === patients[0].consumerId
          ? 'No'
          : 'Yes'
    };
    analyticsService.logEvent(AnalyticsEvent.BookAppointmentStarted, helpUsedData);
  };

  const handleProviderSelect = (provider: ProviderSummary) => {
    setDoctor(provider);
    callAnalytics('My Care Team');
    history.push('/u/get-care-now/booking/kyruus-provider-details');

    analyticsService.logEvent(AnalyticsEvent.BookingProviderSelected, eventData);
  };

  const getProviderIsSelected = (provider: ProviderSummary) => {
    if (!provider || !appointmentDetails.doctor) {
      return false;
    }

    return appointmentDetails.doctor.corpProvId === provider.corpProvId;
  };

  const NoCareTeam = (
    <>
      <MuiBox mb={2}>
        <MuiTypography gutterBottom variant="h5">
          {capitalizeEachWord(currentProfile?.givenName)}'s Care Team
        </MuiTypography>
        <MuiDivider />
      </MuiBox>
      <InfoCard testID="no-data-mycare-team-list">
        <DashboardEmptyStateCard
          iconName="MyDoctors"
          title="My Care Team"
          subtitle={
            <MuiTypography>
              You don't have anyone schedulable in your care team. If you would like to find a new
              provider, navigate to{' '}
              <i style={{ color: Color.secondary, fontWeight: +FontWeight.semibold }}>
                New Provider
              </i>{' '}
              section.
            </MuiTypography>
          }
        />
      </InfoCard>
    </>
  );

  return (
    <>
      <StyledScreen>
        <MuiBox mt={3} mx="auto" maxWidth={850}>
          <MuiContainer>
            <MuiGrid
              container
              spacing={5}
              alignItems="flex-start"
              direction={isSmallScreen ? 'column-reverse' : 'row'}
            >
              {/* Column 1 */}
              <MuiGrid item lg={6} md={12} xs={12}>
                <DataLoader
                  data={doctors}
                  loading={isLoading}
                  renderLoading={() => (
                    <MuiBox textAlign="center" paddingTop={3}>
                      <Spinner
                        size={IconSize.xLarge}
                        data-testid="booking-specialty-or-my-doctor-select-loading"
                      />
                      <BookingLoading />
                    </MuiBox>
                  )}
                  renderNoData={() => NoCareTeam}
                  renderData={() => (
                    <ProvidersSection
                      selectedSpecialty={appointmentDetails?.specialty}
                      doctors={doctors}
                      doctorsLoading={doctorsLoading}
                      geolocationStatus={geolocationStatus}
                      getProviderIsSelected={getProviderIsSelected}
                      handleProviderSelect={handleProviderSelect}
                      currentPatientName={capitalizeEachWord(currentProfile?.givenName)}
                    />
                  )}
                />
              </MuiGrid>
              {/* Column 2 */}
              <MuiGrid container item lg={6} md={12} xs={12} style={{ minHeight: '300px' }}>
                <MuiGrid item xs>
                  <MuiTypography gutterBottom variant="h5">
                    New Provider
                  </MuiTypography>
                </MuiGrid>
                <MuiGrid xs={12}>
                  <MuiBox paddingBottom={2}>
                    <MuiDivider />
                  </MuiBox>
                </MuiGrid>
                <FlexBox style={{ opacity: 1, height: 280, width: '100%' }}>
                  <Image
                    style={{ width: '100%', height: 280, position: 'absolute' }}
                    source={Bg9}
                  />
                  <FlexBox style={{ width: '70%' }} vSpacing={Spacing.xLargeLarge}>
                    <Typography
                      textAlign="center"
                      fontSize={FontSize.largeHeading}
                      fontWeight={FontWeight.normal}
                      color={Color.white}
                    >
                      Find a new doctor or specialist
                    </Typography>
                  </FlexBox>
                  <FlexBox
                    flexDirection="row"
                    justifyContent="center"
                    vSpacing={Spacing.xLargeLarge}
                  >
                    <MuiButton
                      fullWidth
                      color="primary"
                      variant="outlined"
                      onClick={() => {
                        history.push('/u/get-care-now/booking/find-a-doctor');
                      }}
                      data-testid="get-started-kyruus"
                    >
                      Get Started
                    </MuiButton>
                  </FlexBox>
                </FlexBox>
              </MuiGrid>
            </MuiGrid>
          </MuiContainer>
        </MuiBox>
      </StyledScreen>
    </>
  );
}

const mapStateToProps = (state: RootState) => ({
  appointmentDetails: bookingSelectors.appointmentDetailsSelector(state),
  doctorsLoading: myDoctorsSelectors.myDoctorFetchingSelector(state),
  doctors: bookingSelectors.currentPatientAllDoctorsSelector(state),
  geolocationStatus: geoLocationSelectors.geolocationAcceptedSelector(state),
  currentRouteName: routerSelectors.currentLocationPathNameSelector(state),
  previousRouteName: routerSelectors.previousLocationPathNameSelector(state),
  patients: bookingSelectors.patientsDataSelector(state)
});

const mapDispatchToProps = {
  getMyDoctors,
  setDoctor: bookingActions.setDoctor,
  setGeoLocationForProvider: findProviderActions.setGeoLocation,
  setCorrelationId: bookingActions.setCorrelationIDAction
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BookingSpecialtyOrMyDoctorSelect as ComponentType);
