import { makeStyles, createStyles } from '@material-ui/core/styles';

interface UseStylesProps {
  row?: boolean;
  spacing?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
}

export default makeStyles(theme => {
  return createStyles({
    steppersFooter: {
      maxWidth: 275,
      margin: 'auto',
      right: 0,
      bottom: 0,
      left: 0,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      '& > button': {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
      }
    },
    formControl: {
      display: 'flex'
    },
    radioGroup: (props?: UseStylesProps) => ({
      flexDirection: props?.row ? 'row' : 'column',
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2)
    })
  });
});
