import React, { ComponentType, useEffect } from 'react';
import { connect } from 'react-redux';
import { RootState, RootDispatch } from 'store/types';
import { NavigationScreenProps } from 'screens/navigation';
import ProxyLeavingGuard from 'screens/ProxyForm/navigation/ProxyLeavingGuard';
import { Consumer } from 'store/profile/types';
import { deleteRequestedProxy, resetProxyToUpdate, setProxyToUpdate } from 'store/profile/actions';
import { profileConsumerSelector, profileRequestedProxies } from 'store/profile/selectors';
import { Color } from 'modules/styles/colors';
import { Spacing } from 'modules/styles/variables';
import Spacer from 'components/UI/Layout/Spacer';
import Typography from 'components/UI/Typography';
import Box from 'components/UI/Layout/Box';
import FlexBox, { FlexBoxColumn } from 'components/UI/Layout/FlexBox';
import DataLoader from 'components/UI/DataLoader/DataLoader';
import { Confirm } from 'components/ConfirmDialog/ConfirmDialog';
import ProxyFormFooter from './components/ProxyFormFooter';
import ProxyReviewMinor from './components/ProxyReviewMinor';
import { spacings } from 'modules/utils/StylesUtils';
import { MINOR_REVIEW_INFO } from './constants';
import {
  CheckboxProps,
  MuiBox,
  MuiFormLabel,
  MuiTypography,
  MuiContainer,
  MuiButton
} from 'theme/material-ui';
import { ConnectCareCheckboxItem } from 'components/ConnectCare/ConnectCareCheckboxItem';
import { StyledAsterisk } from 'components/RequiredFieldsLegend';

type OmittedProps = 'color' | 'onChange';
type MuiCheckboxProps = Omit<CheckboxProps, OmittedProps>;

export interface ConnectCareCheckboxItemProps extends MuiCheckboxProps {
  label?: React.ReactNode;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>, checked?: boolean) => void;
}

export interface ProxyFormProps {
  id: string;
  firstName: string;
  middleName: string;
  lastName: string;
  gender: string;
  birthDate: string;
  relationship: string;
  address: string;
  otherRelationship: string;
  street: string;
  housingType: string;
  city: string;
  state: string;
  postalCode: string;
}
export interface Props extends NavigationScreenProps {
  minorsData: ProxyFormProps[];
  profileConsumer: Consumer;
  deleteProxy: (id: string) => void;
  setProxyToUpdate: (proxy: ProxyFormProps) => void;
  resetProxyToUpdate: () => void;
}

export const ProxyReviewInfoScreen = (props: Props) => {
  const { minorsData = [], history } = props;

  const [acceptCheckbox, setAcceptCheckbox] = React.useState(false);

  useEffect(() => {
    props.setProxyToUpdate(minorsData[minorsData.length - 1]);
  }, [minorsData]);

  const toggleAcceptCheckBox = () => setAcceptCheckbox(!acceptCheckbox);
  const handlePrevious = () => {
    history.push('/u/add-account/request-access');
  };
  const handleNext = () => {
    props.resetProxyToUpdate();
    props.history.push('/u/add-account/request-access/review/contact');
  };

  const removeMinor = (name: string, id: string) => {
    Confirm.show(
      `Are you sure you would like to delete ${name}'s information?`,
      undefined,
      undefined,
      'info',
      {
        text: 'Yes, delete information',
        onClick: () => {
          props.deleteProxy(id);
          if (minorsData.length === 1) {
            props.resetProxyToUpdate();
            handlePrevious();
          }
        }
      },
      { text: 'No, take me back' },
      {
        closeIcon: true
      }
    );
  };

  const addMinor = () => {
    props.resetProxyToUpdate();
    handlePrevious();
  };

  const updateMinor = (proxy: ProxyFormProps) => {
    props.setProxyToUpdate(proxy);
    handlePrevious();
  };

  return (
    <MuiBox p={1} bgcolor={Color.baseColor} flex={1} width="100%" height="100%">
      <ProxyLeavingGuard />
      <MuiBox pt={9.5} pb={0.5} px={0} mx="auto" maxWidth={430}>
        <MuiTypography variant="h5">Review Information</MuiTypography>
      </MuiBox>
      <MuiBox pb={Spacing.small} mx="auto" maxWidth={430}>
        <MuiTypography> Please verify the information below is correct.</MuiTypography>
      </MuiBox>

      <FlexBox>
        <DataLoader
          data={minorsData}
          renderNoData={() => (
            <MuiContainer maxWidth="sm">
              <FlexBoxColumn justifyContent="center" alignItems="center" spacing={spacings(2, 0)}>
                <Typography>There are no proxy's</Typography>
              </FlexBoxColumn>
            </MuiContainer>
          )}
          renderData={data => (
            <>
              {data.map((item, i) => {
                const { streetAddress, state, postalCode } =
                  item.address === 'yes'
                    ? props.profileConsumer?.addresses[0]
                    : { streetAddress: '', state: '', postalCode: '' };
                const profileConsumerAddress = { streetAddress, state, postalCode };

                return (
                  <ProxyReviewMinor
                    key={item.id}
                    index={i}
                    minorData={item}
                    removeMinor={removeMinor}
                    profileConsumerAddress={profileConsumerAddress}
                    updateMinor={updateMinor}
                  />
                );
              })}
            </>
          )}
        />
      </FlexBox>

      <MuiBox width="100%" borderColor={Color.grayLight} mx="auto" maxWidth={430}>
        <Box vSpacing={Spacing.medium}>
          <MuiBox pt={2}>
            <ConnectCareCheckboxItem
              required
              checked={acceptCheckbox}
              onChange={toggleAcceptCheckBox}
              inputProps={{ 'data-testid': 'agree-checkbox' }}
              label={
                <MuiFormLabel>
                  {minorsData.length > 1
                    ? MINOR_REVIEW_INFO.multipleMinorCheckboxText
                    : MINOR_REVIEW_INFO.singleMinorCheckboxText}{' '}
                  <StyledAsterisk />
                </MuiFormLabel>
              }
            />
          </MuiBox>
        </Box>
        <Box vSpacing={Spacing.medium}>
          <Typography textAlign="left">
            {minorsData.length > 1
              ? MINOR_REVIEW_INFO.multipleMinorCheckboxDesc
              : MINOR_REVIEW_INFO.singleMinorCheckboxDesc}
          </Typography>
        </Box>

        <MuiButton
          data-testid="submit-button"
          color="primary"
          variant="contained"
          fullWidth
          disabled={!acceptCheckbox}
          onClick={handleNext}
        >
          Next
        </MuiButton>
        <Spacer size="xsmall" />
        <MuiButton
          data-testid="previous-button"
          color="primary"
          variant="text"
          fullWidth
          onClick={addMinor}
        >
          Add another minor
        </MuiButton>
        <Spacer size="medium" />
        <MuiBox py={9.5} px={0} mx="auto" maxWidth={420}>
          <ProxyFormFooter />
        </MuiBox>
      </MuiBox>
    </MuiBox>
  );
};

const mapDispatch = (dispatch: RootDispatch) => ({
  deleteProxy: (id: string) => dispatch(deleteRequestedProxy(id)),
  setProxyToUpdate: (proxy: ProxyFormProps) => dispatch(setProxyToUpdate(proxy)),
  resetProxyToUpdate: () => dispatch(resetProxyToUpdate())
});

const mapStateToProps = (state: RootState) => ({
  profileConsumer: profileConsumerSelector(state),
  minorsData: profileRequestedProxies(state)
});

export default connect(mapStateToProps, mapDispatch)(ProxyReviewInfoScreen as ComponentType);
