import React, { useEffect } from 'react';
import analyticsService, { AmplitudeEventData, AnalyticsEvent } from 'services/AnalyticsService';
import { MuiBox, MuiTypography, MuiButton } from 'theme/material-ui';
import { connect } from 'react-redux';
import { FlexBoxRow } from 'components/UI/Layout/FlexBox';
import { EVENT_LINK_EXPIRED, MY_HEALTH_PLUS_DOWNLOAD_SPLASH } from '../constants';
import { FontSize } from 'modules/styles/variables';
import Spacer from 'components/UI/Layout/Spacer';
import { AuthLiteHeader } from 'components/AuthLite';
import {
  currentLocationPathNameSelector,
  previousLocationPathNameSelector
} from 'store/router/selectors';
import { RootState } from 'store/types';

interface Props {
  currentUrl: string;
  referringUrl: string;
}

export function EventLinkExpired({ currentUrl, referringUrl }: Props) {
  useEffect(() => {
    const evnentData: AmplitudeEventData = {
      currentUrl,
      referringUrl,
      action: 'COVID-19 light authentication error',
      errorCode: 'N/A',
      errorMessage: 'COVID-19 light authentication error: expired 72-hour authentication'
    };
    analyticsService.logEvent(AnalyticsEvent.ErrorTracking, evnentData);
  }, []);

  const onMhButtonPress = () => {
    const evnentData: AmplitudeEventData = {
      currentUrl,
      referringUrl,

      promotion_type: 'COVID-19 light authentication: expired link'
    };
    analyticsService.logEvent(AnalyticsEvent.PromotionLink, evnentData);

    window.open(MY_HEALTH_PLUS_DOWNLOAD_SPLASH);
  };

  return (
    <MuiBox data-testid="event-link-expired">
      <AuthLiteHeader />
      <Spacer size="medium" />
      <MuiBox m={3}>
        <MuiTypography variant="h4" align="center">
          {EVENT_LINK_EXPIRED.title}
        </MuiTypography>
        <Spacer size="small" />
        <MuiTypography fontSize={FontSize.large} align="center">
          {EVENT_LINK_EXPIRED.content}
        </MuiTypography>
      </MuiBox>
      <Spacer size="small" />
      <FlexBoxRow justifyContent="center">
        <MuiBox width="300px">
          <MuiButton
            color="primary"
            variant="contained"
            onClick={onMhButtonPress}
            data-testid="event-link-expired-mhp-button"
          >
            {EVENT_LINK_EXPIRED.buttonText}
          </MuiButton>
        </MuiBox>
      </FlexBoxRow>
    </MuiBox>
  );
}

const mapStateToProps = (state: RootState) => ({
  currentUrl: currentLocationPathNameSelector(state),
  referringUrl: previousLocationPathNameSelector(state)
});

export default connect(mapStateToProps)(EventLinkExpired);
