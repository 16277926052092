import * as React from 'react';
import { JsonObj } from '@mhp/general-interface';
import { Color } from 'modules/styles/colors';
import { FontWeight } from 'modules/styles/variables';
import Progress from 'components/billing/DeductibleBar/Progress';
import Legend from 'components/billing/DeductibleBar/Legend';
import formatMoney from 'services/formatMoney';
import { MuiBox, MuiGrid, MuiTypography } from 'theme/material-ui';

interface Props {
  info: JsonObj;
  name?: string;
  appliedSpend?: number;
  type: string;
}

function DeductibleBar({ name, info, appliedSpend, type }: Props) {
  const percentageConverter = (remainder: number, deductible: number, appliedPrevSpend: number) => {
    const remainderDecimal = remainder / deductible;
    const alreadyPaidDecimal = 1 - remainderDecimal;
    const appliedSpendDecimal = appliedPrevSpend / deductible;

    const output = {
      remainderValue: remainder,
      remainderPercentage: Math.round(remainderDecimal * 100),
      alreadyPaidValue: deductible - remainder,
      alreadyPaidPercentage: Math.round(alreadyPaidDecimal * 100),
      deductibleValue: deductible,
      appliedSpendValue: appliedSpend,
      appliedSpendPercentage: Math.round(appliedSpendDecimal * 100)
    };
    return output;
  };

  const appliedSpendWidthCalc = (percentages: JsonObj) => {
    const { appliedSpendPercentage, appliedSpendValue, alreadyPaidPercentage } = percentages;
    if (appliedSpendPercentage === 0 || alreadyPaidPercentage === 100) {
      return {
        width: 0,
        borderRadiusRight: 0,
        borderRadiusLeft: 0,
        appliedAmount: appliedSpendValue
      };
    }
    if (alreadyPaidPercentage + appliedSpendPercentage === 100) {
      const extra = alreadyPaidPercentage + appliedSpendPercentage - 100;
      return {
        width: appliedSpendPercentage - extra - alreadyPaidPercentage,
        borderRadiusRight: alreadyPaidPercentage + appliedSpendPercentage < 95 ? 0 : 8,
        borderRadiusLeft: alreadyPaidPercentage < 2 ? 8 : 0,
        appliedAmount: appliedSpendValue
      };
    }
    if (alreadyPaidPercentage + appliedSpendPercentage > 100) {
      return {
        width: 100 - alreadyPaidPercentage,
        borderRadiusRight: alreadyPaidPercentage + appliedSpendPercentage < 95 ? 0 : 8,
        borderRadiusLeft: alreadyPaidPercentage < 2 ? 8 : 0,
        appliedAmount: appliedSpendValue
      };
    }
    if (alreadyPaidPercentage + appliedSpendPercentage < 100) {
      return {
        width: appliedSpendPercentage,
        borderRadiusRight: alreadyPaidPercentage + appliedSpendPercentage < 95 ? 0 : 8,
        borderRadiusLeft: alreadyPaidPercentage < 2 ? 8 : 0,
        appliedAmount: appliedSpendValue
      };
    }
    return {
      width: 0,
      borderRadiusRight: 0,
      borderRadiusLeft: alreadyPaidPercentage < 2 ? 8 : 0,
      appliedAmount: appliedSpendValue
    };
  };

  const dataObject = percentageConverter(info.remainder[type], info[type], appliedSpend);
  const capMath = appliedSpendWidthCalc(dataObject);

  return (
    <MuiBox my={1}>
      <MuiGrid container spacing={1}>
        <MuiGrid item>
          {name ? (
            <MuiTypography color={Color.foreColor}>{name}: </MuiTypography>
          ) : (
            <MuiTypography>Family: </MuiTypography>
          )}
        </MuiGrid>
        <MuiGrid item>
          {info && (
            <MuiTypography
              color={Color.foreColor}
              fontWeight={FontWeight.semibold}
            >{`$${formatMoney(info[type], 0)}`}</MuiTypography>
          )}
        </MuiGrid>
      </MuiGrid>
      <>
        <Progress deductible={dataObject} capMath={capMath} />
        <Legend deductible={dataObject} />
      </>
    </MuiBox>
  );
}

export default DeductibleBar;
