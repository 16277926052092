import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { oc } from 'ts-optchain';
import analyticsService, { AnalyticsEvent } from 'services/AnalyticsService';

import Spacer from 'components/UI/Layout/Spacer';
import Spinner from 'components/UI/Spinner/Spinner';
import Svg from 'components/UI/Svg/Svg';

import { Color } from 'modules/styles/colors';
import { FontWeight, Spacing, FontSize, IconSize } from 'modules/styles/variables';
import { RequestState } from 'modules/types/common';
import { EVisitTypeKey } from 'modules/constants/eVisit/eVisitType';

import { RootState } from 'store/types';

import { getDisqualifiedId } from 'storage/eVisitsDisqualified/storage.web';
import { getTriageResults } from 'store/triageGyant/actions';
import { TriageResults, TriageCondition, TriageEndpoint } from 'store/triageGyant/types';
import {
  triageGyantConditionsSortedSelector,
  triageGyantResultsDataSelector,
  triageGyantEndpointsSortedSelector,
  triageGyantResultsIsFetchingSelector,
  triageGyantCurrentCaseIdSelector
} from 'store/triageGyant/selectors';
import {
  currentLocationPathNameSelector,
  previousLocationPathNameSelector
} from 'store/router/selectors';

import { checkEVisitEligibility } from 'store/eVisit/actions';
import {
  eVisitEligibilityAccessSelector,
  eVisitEligibilityRequestStateSelector,
  eVisitEligibilityFetchingSelector
} from 'store/eVisit/selectors';

import LikelyCausesList from 'screens/TriageGyant/ResultsScreens/Results/LikelyCausesList';
import CareOptionsList from 'screens/TriageGyant/ResultsScreens/Results/CareOptionsList';
import TriageHeader from 'screens/TriageGyant/TriageGyantHeader';
import {
  getCareBadgeText,
  getCareBadgeColor,
  getCareDisplayText,
  getEnteredSymptoms,
  getCareOptionsPresented,
  getTopThreeCauses,
  getCareDescription
} from 'lib/triage/utils';
import { TRIAGE_ROUTES } from 'screens/TriageGyant/constants';

import { MuiContainer, MuiBox, MuiTypography, MuiDivider, CursorMuiBox } from 'theme/material-ui';
import { useLocalStorage } from 'hooks/useLocalStorage';

export interface Props {
  getResults: typeof getTriageResults;
  sortedConditions: TriageCondition[];
  sortedEndpoints: string[];
  isFetching: boolean;
  results: TriageResults;
  currentCaseId: string;
  currentUrl?: string;
  referringUrl?: string;
  checkEligibility: typeof checkEVisitEligibility;
  eligibilityRequestState: RequestState;
  eligibilityAccess: boolean;
  eligibilityFetching: boolean;
}

export const TriageResultsScreen = ({
  sortedConditions,
  sortedEndpoints,
  results,
  isFetching,
  currentCaseId,
  getResults,
  currentUrl,
  referringUrl,
  eligibilityRequestState,
  eligibilityAccess,
  checkEligibility,
  eligibilityFetching
}: Props) => {
  const { disposition, evidence } = results;
  const { caseId = '' } = useParams();
  const history = useHistory();
  const hideEVisitOption = getDisqualifiedId(caseId);
  const mainSymptom = oc(evidence)[0].medicalName(undefined);
  const [endpoints, setEndpoints] = useState<TriageEndpoint[]>([]);
  const [eVisitType, setEVisitType] = useLocalStorage(EVisitTypeKey, '');

  // checkEVisitEligibility
  const eligibilityError = eligibilityRequestState === RequestState.ERROR;
  const eligibilityAccessDenied = eligibilityAccess !== true;
  useEffect(() => {
    if (!hideEVisitOption) checkEligibility(caseId);
  }, []);

  useEffect(() => {
    if (caseId && currentCaseId !== caseId) {
      getResults(caseId);
    }
  }, [caseId]);

  useEffect(() => {
    if (isFetching || eligibilityFetching) return;
    const endpointsObj: TriageEndpoint[] = [];
    sortedEndpoints.forEach((item, index) => {
      if (
        item === 'asyncTelemedicine' &&
        (hideEVisitOption || eligibilityAccessDenied || eligibilityError)
      )
        return;
      const badgeText = getCareBadgeText(index);
      const badgeColor = getCareBadgeColor(badgeText);
      const badgeTextColor = badgeText === 'LOW' ? Color.gray : undefined;
      const displayText = getCareDisplayText(item);
      const description = getCareDescription(item);
      endpointsObj.push({
        title: item,
        badgeText,
        badgeColor,
        badgeTextColor,
        displayText,
        description
      });
      if (endpointsObj.length) {
        setEndpoints(endpointsObj);
      }
    });
  }, [sortedEndpoints, isFetching, eligibilityFetching]);

  const enteredSymptoms = getEnteredSymptoms(oc(results).evidence([]));
  const careOptionsPresented = getCareOptionsPresented(endpoints);
  const topThreeCauses = getTopThreeCauses(sortedConditions);
  const topCause = oc(topThreeCauses)[0](undefined);

  useEffect(() => {
    if (oc(sortedConditions).length(0)) {
      analyticsService.logEvent(AnalyticsEvent.DiagnosisProvided, {
        currentUrl,
        referringUrl,
        top_3_causes: topThreeCauses,
        top_cause: topCause,
        recommended_followup_time: disposition
      });
    }
  }, [sortedConditions]);

  useEffect(() => {
    if (oc(results).evidence.length(0)) {
      setEVisitType('');
      analyticsService.logEvent(AnalyticsEvent.SymptomCheckerCompleted, {
        top_3_causes: topThreeCauses,
        top_cause: topCause,
        care_options_presented: careOptionsPresented,
        entered_symptoms: enteredSymptoms,
        currentUrl,
        referringUrl
      });
    }
  }, [results]);

  useEffect(() => {
    if (careOptionsPresented?.length) {
      analyticsService.logEvent(AnalyticsEvent.CareOptionProvided, {
        currentUrl,
        referringUrl,
        top_cause: topCause,
        care_options_presented: careOptionsPresented
      });
    }
  }, [careOptionsPresented]);

  const logCareOptionSelected = (endpoint: TriageEndpoint) => {
    const selected = oc(endpoint).title('');
    analyticsService.logEvent(AnalyticsEvent.CareOptionSelected, {
      currentUrl,
      referringUrl,
      top_cause: topCause,
      care_options_presented: careOptionsPresented,
      care_option_selected: selected
    });
    analyticsService.logEvent(AnalyticsEvent.SymptomCheckerActionTaken, {
      action: selected
    });
  };

  const onConditionDetailPress = (conditionId: string) => {
    history.push(`${TRIAGE_ROUTES.CAUSE_DETAIL.BASE_URL}/${conditionId}`);
  };

  const onAllConditionsPress = () => {
    history.push(`${TRIAGE_ROUTES.POSSIBLE_CAUSES.BASE_URL}/${caseId}`);
  };

  return (
    <MuiBox flex={1} bgcolor={Color.grayLight3}>
      <TriageHeader />
      {isFetching || eligibilityFetching ? (
        <MuiBox
          height="100%"
          pt={Spacing.xLarge}
          display="flex"
          justifyContent="center"
          data-testid="triage-results-screen-spinner"
        >
          <Spinner />
        </MuiBox>
      ) : (
        <MuiContainer maxWidth="lg">
          <MuiBox minWidth={340} mx={3} display="flex" flexDirection="column" alignItems="center">
            <MuiBox
              maxWidth={700}
              display="flex"
              flexWrap="wrap"
              flexDirection="row"
              justifyContent="center"
            >
              <MuiBox flexGrow={1} mt={Spacing.medium} mb={Spacing.small}>
                <MuiTypography
                  variant="h3"
                  align="center"
                  fontSize={FontSize.title}
                  fontWeight={FontWeight.bold}
                  color={Color.primary}
                >
                  Symptom Assessment Complete!
                </MuiTypography>
              </MuiBox>
              <MuiBox flexShrink={0}>
                {mainSymptom && (
                  <MuiBox
                    flexDirection="row"
                    display="flex"
                    mb={2}
                    justifyContent="center"
                    flexWrap="wrap"
                    textAlign="center"
                  >
                    <MuiTypography variant="subtitle2" fontWeight={FontWeight.normal}>
                      Here's what was troubling you:&nbsp;
                    </MuiTypography>
                    <MuiTypography variant="subtitle2" fontWeight={FontWeight.bold}>
                      {mainSymptom}
                    </MuiTypography>
                  </MuiBox>
                )}
                {disposition && (
                  <>
                    <MuiTypography variant="subtitle2" fontWeight={FontWeight.bold}>
                      Our recommendation:&nbsp;
                    </MuiTypography>
                    <MuiTypography variant="subtitle2" fontWeight={FontWeight.normal}>
                      {disposition}
                    </MuiTypography>
                  </>
                )}
              </MuiBox>
            </MuiBox>
            <MuiBox width="100%" maxWidth={700} my={Spacing.small}>
              <MuiDivider />
              <MuiBox
                flexDirection="row"
                justifyContent="space-between"
                mt={Spacing.small}
                display="flex"
              >
                <MuiTypography variant="subtitle2" fontWeight={FontWeight.bold}>
                  Most Likely Causes
                </MuiTypography>
                <Spacer size="medium" />
                {!!sortedConditions.length && (
                  <CursorMuiBox pr={2}>
                    <MuiBox display="flex" flexDirection="row" alignItems="center">
                      <MuiTypography
                        data-testid="see-all"
                        color={Color.link}
                        onClick={onAllConditionsPress}
                      >
                        See all ({sortedConditions.length}) causes
                      </MuiTypography>
                      <Svg name="ChevronRight" size={IconSize.small} color={Color.link} />
                    </MuiBox>
                  </CursorMuiBox>
                )}
              </MuiBox>
              <Spacer size="small" />
              <LikelyCausesList causes={sortedConditions} onItemPressed={onConditionDetailPress} />
            </MuiBox>
            {!!endpoints.length && (
              <MuiBox mx={Spacing.small} mb={Spacing.medium} width="100%" maxWidth={700}>
                <MuiTypography variant="subtitle2" fontWeight={FontWeight.bold}>
                  Care Options
                </MuiTypography>
                <Spacer size="small" />
                <CareOptionsList
                  endpoints={endpoints}
                  logCareOptionSelected={logCareOptionSelected}
                />
              </MuiBox>
            )}
          </MuiBox>
        </MuiContainer>
      )}
    </MuiBox>
  );
};

const mapDispatch = {
  getResults: getTriageResults,
  checkEligibility: checkEVisitEligibility
};

const mapState = (state: RootState) => ({
  results: triageGyantResultsDataSelector(state),
  sortedConditions: triageGyantConditionsSortedSelector(state),
  sortedEndpoints: triageGyantEndpointsSortedSelector(state),
  isFetching: triageGyantResultsIsFetchingSelector(state),
  currentCaseId: triageGyantCurrentCaseIdSelector(state),
  currentUrl: currentLocationPathNameSelector(state),
  referringUrl: previousLocationPathNameSelector(state),
  eligibilityAccess: eVisitEligibilityAccessSelector(state),
  eligibilityRequestState: eVisitEligibilityRequestStateSelector(state),
  eligibilityFetching: eVisitEligibilityFetchingSelector(state)
});

export default connect(mapState, mapDispatch)(TriageResultsScreen);
