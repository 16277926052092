import React from 'react';
import { useHistory } from 'react-router';
import { MuiButton } from 'theme/material-ui';
import FlexBox from 'components/UI/Layout/FlexBox';
import { Spacing } from 'modules/styles/variables';
import { GetCareSearchContainer } from './styled';

function GetCareSearch() {
  const history = useHistory();

  return (
    <GetCareSearchContainer>
      <FlexBox flexDirection="row" justifyContent="center" vSpacingTop={Spacing.xLargeLarge}>
        <MuiButton
          fullWidth
          color="primary"
          variant="outlined"
          onClick={() => {
            history.push('/u/find-a-doctor');
          }}
          data-testid="get-started-kyruus"
        >
          Get Started
        </MuiButton>
      </FlexBox>
    </GetCareSearchContainer>
  );
}

export default GetCareSearch;
