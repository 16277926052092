import React, { ComponentType, useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { MuiContainer, MuiTypography, MuiBox } from 'theme/material-ui';
import Spacer from 'components/UI/Layout/Spacer';
import Footer from 'components/common/Footer';
import * as covidScreeningSelectors from 'store/CovidScreening/selectors';
import { RootState } from 'store/types';
import { patientSelect } from 'modules/constants/covidScreening';
import { CovidTestButton } from '../Components/CovidTestButton';
import { OrderStatus } from 'store/CovidScreening/types';
import { CovidTitles } from 'lib/constants/covidScreening';
import { AnalyticsEvent } from 'services/AnalyticsService';
import useNavigationAnalytics from 'hooks/useNavigationAnalytics';

export interface Props extends RouteComponentProps {
  covidScreeningOrderStatus: OrderStatus;
}

export function CovidErrorComponent({ history, covidScreeningOrderStatus }: Props) {
  const covidScreeningError = useNavigationAnalytics(AnalyticsEvent.CovidOrderError);
  const getErrorMessage = () => {
    if (covidScreeningOrderStatus === 400) return 'Bad Request';
    if (covidScreeningOrderStatus === 401) return 'Unauthorized';
    return 'Internal Server Error';
  };
  useEffect(() => {
    covidScreeningError({
      covid19_order: 'Failed',
      errorCode: covidScreeningOrderStatus,
      errorMessage: getErrorMessage(),
      title: CovidTitles.ERROR_PAGE
    });
    window.history.pushState(null, document.title, window.location.href);
  }, []);
  return (
    <>
      <MuiContainer maxWidth="lg">
        <Spacer size="medium" />
        <MuiBox pl={4} my={2}>
          <MuiTypography variant="h4" align="center" color="red">
            There has been an error processing your request.
          </MuiTypography>
        </MuiBox>
        <Spacer size="medium" />
        <MuiBox my={2}>
          {covidScreeningOrderStatus ? (
            <MuiTypography align="center">Please try again</MuiTypography>
          ) : null}
        </MuiBox>
        <Spacer size="medium" />
        <MuiBox width="30%" margin="0 auto" alignItems="center">
          <CovidTestButton
            fullWidth
            color="primary"
            variant="contained"
            onClick={() => history.push('/u/get-care-now')}
          >
            Close
          </CovidTestButton>
        </MuiBox>
        <Spacer size="medium" />
      </MuiContainer>
      <Spacer size="medium" />
      <Footer
        headerText={patientSelect.GET_PATIENTS.HELPLINE.CovidInfo}
        link={patientSelect.GET_PATIENTS.HELPLINE.CovidPatientSelectHelpline}
        linkName={patientSelect.GET_PATIENTS.HELPLINE.CovidPatientSelectHelpline}
        helplineHours={patientSelect.GET_PATIENTS.HELPLINE.CovidHelplineHours}
        title={CovidTitles.ERROR_PAGE}
      />
    </>
  );
}

const mapStateToProps = (state: RootState) => ({
  covidScreeningOrderStatus: covidScreeningSelectors.CovidScreeningOrderStatus(state)
});

export default connect(mapStateToProps)(CovidErrorComponent as ComponentType);
