import { AnimatedEllipsis } from 'components/AnimatedEllipsis';
import React from 'react';
import { UPDATES } from 'lib/constants/dashboard';
import { Spacing } from 'modules/styles/variables';
import { MuiBox, MuiTypography } from 'theme/material-ui';

const UpdatesListLoading = () => (
  <MuiBox py={5} display="flex" flexDirection="column" alignItems="center">
    <MuiTypography>{UPDATES.loading.title}</MuiTypography>

    <MuiBox marginTop={Spacing.small} marginBottom={Spacing.small}>
      <AnimatedEllipsis />
    </MuiBox>

    <MuiTypography>{UPDATES.loading.subTitle}</MuiTypography>
  </MuiBox>
);

export default UpdatesListLoading;
