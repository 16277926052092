import React from 'react';
import styled from 'styled-components';
import { Color } from 'modules/styles/colors';
import { dfdDefaultTheme, MuiBox, MuiTypography } from 'theme/material-ui';

interface Props {
  text: string;
}

const { spacing } = dfdDefaultTheme;

const DisclaimerBanner = ({ text }: Props) => {
  return (
    <DisclaimerView>
      <DisclaimerText data-testid="disclaimer-text-banner" variant="body1">
        {text}
      </DisclaimerText>
    </DisclaimerView>
  );
};

const DisclaimerView = styled(MuiBox)`
  background: ${Color.primaryHue5};
  padding: ${spacing(1)}px ${spacing(4)}px;
`;

const DisclaimerText = styled(MuiTypography)`
  color: ${Color.foreColor};
`;

export default DisclaimerBanner;
