import React from 'react';
import { SvgProps } from 'components/UI/Svg/Svg';

export default ({ color = '#000000' }: SvgProps) => ({
  svg: (
    <g stroke="none" fill={color}>
      <path
        d="M26,20.7c0.8-1.2,1.2-2.5,1.2-4s-0.4-2.8-1.2-3.9l1.3-1.3c0.5-0.5,0.5-1.3,0-1.9s-1.3-0.5-1.9,0l-1.2,1.2
	c-1.2-0.8-2.7-1.3-4.1-1.3H20c-0.3-0.4-0.4-0.9-0.8-1.3l1.5-1.5c0.5-0.5,0.5-1.3,0-1.9s-1.3-0.5-1.9,0l-1.5,1.5
	c-1.2-0.8-2.8-1.3-4.4-1.3S9.7,5.5,8.5,6.3L6.9,4.8c-0.5-0.5-1.3-0.5-1.9,0s-0.5,1.3,0,1.9l1.5,1.5c-0.9,1.3-1.5,2.8-1.5,4.5
	c0,1.5,0.4,2.8,1.1,4c-0.3,0.7-0.5,1.3-0.7,2H3.6c-0.8,0-1.3,0.5-1.3,1.3s0.5,1.3,1.3,1.3h1.9c0.5,2.9,2.8,5.2,5.7,5.7v1.9
	c0,0.8,0.5,1.3,1.3,1.3s1.3-0.5,1.3-1.3v-1.9c2-0.4,3.7-1.6,4.8-3.2C19.1,24,19.6,24,20,24c1.6,0,2.9-0.5,4.1-1.3l1.3,1.3
	c0.3,0.3,0.7,0.4,0.9,0.4s0.7-0.1,0.9-0.4c0.5-0.5,0.5-1.3,0-1.9L26,20.7L26,20.7z M12.5,21.5c-0.8,0-1.5-0.7-1.5-1.5
	s0.7-1.5,1.5-1.5S14,19.2,14,20S13.3,21.5,12.5,21.5z M13.5,16.5c-0.3,0-0.5,0.1-0.7,0.1l0,0c-0.3,0-0.5,0-0.7-0.1
	c-2.1-0.4-3.7-2.1-3.7-4.4c0-2.4,2-4.4,4.4-4.4s4.4,2,4.4,4.4C17.2,14.4,15.6,16.3,13.5,16.5z M20.4,19.9c-1.6,0-2.9-1.3-2.9-2.9
	s1.3-2.9,2.9-2.9s2.9,1.3,2.9,2.9C23.5,18.5,22.1,19.9,20.4,19.9z"
      />
    </g>
  ),
  viewBox: '0 0 32 32'
});
