import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { ConnectCareIcon, ConnectCareButton } from 'components/ConnectCare';

import {
  MuiDialog,
  MuiDialogTitle,
  MuiDialogContent,
  MuiDialogActions,
  MuiTypography,
  MuiGrid,
  MuiBox,
  MuiAlert,
  MuiAlertTitle,
  MuiDivider
} from 'theme/material-ui';

import { Visit } from 'store/amwell/types';
import { getTelehealthVideoInstallUrl, launchTelehealthVideo } from 'store/amwell/actions';
import * as selectors from 'store/amwell/selectors';
import { RootState, RootDispatch } from 'store/types';
import ConnectCareWaitingRoomCancelVisitDialog from '../ConnectCareWaitingRoomCancelVisitDialog';

export interface Props {
  dispatch: RootDispatch;
  open: boolean;
  visit: Visit | null;
  telehealthVideoInstallUrl: string;
  onVisitCancel: () => void;
  onClose: () => void;
}

const getOS = () => {
  let os = null;
  if (/Mac/.test(window.navigator.platform)) {
    os = 'Mac';
  } else if (/Win/.test(window.navigator.platform)) {
    os = 'Windows';
  }
  return os;
};

export function ConnectCareTelehealthSetup(props: Props) {
  const { dispatch, visit, open, telehealthVideoInstallUrl, onVisitCancel, onClose } = props;
  const os = getOS();

  const [launching, setLaunching] = useState(false);
  const [canceling, setCanceling] = useState(false);

  useEffect(() => {
    dispatch(getTelehealthVideoInstallUrl());
  }, []);

  const onCancel = () => {
    onVisitCancel();
    setCanceling(false);
    onClose();
  };

  const onLaunch = () => {
    setLaunching(true);
    dispatch(launchTelehealthVideo({ visit })).then(() => {
      setLaunching(false);
    });
  };

  return (
    <MuiDialog
      open={open}
      maxWidth="md"
      onClose={() => setCanceling(true)}
      aria-labelledby="connect-care-video-setup-dialog-title"
    >
      <MuiDialogTitle id="connect-care-video-setup-dialog-title">
        Steps to get ready for your visit
      </MuiDialogTitle>
      <MuiDialogContent dividers>
        <MuiGrid container spacing={2}>
          <MuiGrid item xs={12}>
            <MuiBox display="flex" alignItems="center" flexWrap="nowrap" mb={1}>
              <ConnectCareIcon fontSize="default">1</ConnectCareIcon>
              <MuiBox ml={1}>
                <MuiTypography variant="subtitle2">Download Telehealth Video</MuiTypography>
              </MuiBox>
            </MuiBox>
            <MuiBox>
              <ConnectCareButton
                disabled={!telehealthVideoInstallUrl}
                href={telehealthVideoInstallUrl}
                download
              >
                Download
              </ConnectCareButton>
            </MuiBox>
          </MuiGrid>
          <MuiGrid item xs={12}>
            <MuiDivider />
          </MuiGrid>
          <MuiGrid item xs={12}>
            <MuiBox display="flex" alignItems="center" flexWrap="nowrap" mb={1}>
              <ConnectCareIcon fontSize="default">2</ConnectCareIcon>
              <MuiBox ml={1}>
                <MuiTypography variant="subtitle2">Install</MuiTypography>
              </MuiBox>
            </MuiBox>
            <MuiBox>
              <MuiTypography>
                {os === 'Mac'
                  ? 'Click the TelehealthVideoInstall.pkg file and run the installer'
                  : 'Click the TelehealthVideoInstall.exe file and run the installer'}
              </MuiTypography>
            </MuiBox>
          </MuiGrid>
          <MuiGrid item xs={12}>
            <MuiDivider />
          </MuiGrid>
          <MuiGrid item xs={12}>
            <MuiBox display="flex" alignItems="center" flexWrap="nowrap" mb={1}>
              <ConnectCareIcon fontSize="default">3</ConnectCareIcon>
              <MuiBox ml={1}>
                <MuiTypography variant="subtitle2">Launch</MuiTypography>
              </MuiBox>
            </MuiBox>
            <MuiBox>
              <ConnectCareButton
                onClick={onLaunch}
                disabled={!telehealthVideoInstallUrl}
                loading={launching}
              >
                Launch Telehealth Video
              </ConnectCareButton>
            </MuiBox>
          </MuiGrid>
        </MuiGrid>
        <MuiBox mt={3}>
          <MuiAlert severity="warning">
            <MuiAlertTitle>Note!</MuiAlertTitle>
            If you receive a warning from your security or anti-virus software, please allow the
            application to install and launch.
          </MuiAlert>
        </MuiBox>
      </MuiDialogContent>
      <MuiDialogActions>
        <ConnectCareButton
          variant="outlined"
          onClick={() => setCanceling(true)}
          disabled={launching}
        >
          Cancel My Visit
        </ConnectCareButton>
      </MuiDialogActions>
      <ConnectCareWaitingRoomCancelVisitDialog
        isCancelVisitDialogVisible={canceling}
        onVisitCancel={onCancel}
        handleClose={() => setCanceling(false)}
      />
    </MuiDialog>
  );
}

const mapStateToProps = (state: RootState) => ({
  visit: selectors.visitDataSelector(state),
  telehealthVideoInstallUrl: selectors.telehealthVideoInstallUrlSelector(state)
});

export default connect(mapStateToProps)(ConnectCareTelehealthSetup);
