import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { getCurrentPosition } from 'store/geolocation/actions.web';

export default function useLiveCare() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCurrentPosition());
  }, []);

  return {};
}
