import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import MaskedInput from 'react-text-mask';
import { Formik, FormikHelpers as FormikActions } from 'formik';
import {
  FormControl,
  FormControlLabel,
  InputBaseComponentProps,
  Radio,
  RadioGroup
} from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import { KeyboardDatePicker } from '@material-ui/pickers';
import ConfirmModal from 'components/UI/Modals/ConfirmModal';
import Spacer from 'components/UI/Layout/Spacer';
import Svg from 'components/UI/Svg/Svg';
import dayjs from 'dayjs';
import { LOCATION_STATES } from 'modules/constants/LocationStates';
import { masks } from 'utils/masks';
import { NavigationScreenProps } from 'screens/navigation';
import { AdvisoryFormattedInterface } from 'store/priorityItems/types';
import { phoneNumberMask } from 'modules/utils/PhoneUtils';
import { getHighPriorityItems } from 'store/priorityItems/actions';
import { ProviderSummary } from 'store/findProvider/types';
import { currentConsumerIntermountainPcpSelector } from 'store/myDoctors/selectors';
import { MuiBox, MuiDivider, MuiButton, MuiGrid } from 'theme/material-ui';
import { clearHealthAdvisory } from './actions';
import {
  formInstructions,
  formTitles,
  noteToRead,
  providerPoolSelect,
  readMessage,
  submitHelperText
} from './constants';
import {
  FullWidthTextField,
  StateSelect,
  StyledCheckBoxNote,
  StyledDateFormControl,
  StyledFormControl,
  StyledFormTitle,
  StyledHeader,
  StyledHelperText,
  StyledInstructions,
  StyledNativeSelect,
  StyledText,
  StyledTextField,
  StyledZip
} from './styled';
import RequiredFieldsLegend, { StyledAsterisk } from 'components/RequiredFieldsLegend';
import { ClinicTypes, ClearHealthAdvisoryFormValues } from 'lib/dashboard/types';
import {
  initialValues,
  defaultHealthAdvisorySchema,
  fluShotSchemas
} from 'lib/constants/dashboard';

interface CloseHealthAdvisoryScreenParams extends NavigationScreenProps {
  advisory: AdvisoryFormattedInterface;
  pcp: ProviderSummary[];
}
interface MaskedTextProps extends InputBaseComponentProps {
  mask: (string | RegExp)[] | ((rawValue: string) => (string | RegExp)[]);
}

function MaskedText({ inputRef, ...rest }: MaskedTextProps) {
  return <MaskedInput {...rest} ref={(ref: any) => inputRef(ref ? ref.inputElement : null)} />;
}

export const CloseHealthAdvisoryScreen = ({ history }: CloseHealthAdvisoryScreenParams) => {
  const { advisoryName, eventId } = useParams();
  const dispatch = useDispatch();
  const pcp = useSelector(currentConsumerIntermountainPcpSelector);
  const myDoctors = pcp && pcp.messagingPools;
  const isDropDownShow = myDoctors && myDoctors.length > 1;

  const handleClose = () => history.goBack();

  const handleSuccess = () => {
    dispatch(getHighPriorityItems(false));
    history.push('/u/dashboard', { showSnackbar: true });
  };

  const handleFail = () => {
    setShowUnableToClearModal(true);
  };
  const handleSubmitForm = async (
    values: ClearHealthAdvisoryFormValues,
    { setSubmitting }: FormikActions<ClearHealthAdvisoryFormValues>
  ) => {
    setSubmitting(true);
    try {
      const success = await dispatch(
        clearHealthAdvisory(
          {
            advisoryName: advisoryName || null,
            eventId: eventId || null
          },
          pcp,
          values
        )
      );
      setSubmitting(false);
      if (success) {
        handleSuccess();
      } else {
        handleFail();
      }
    } catch (e) {
      throw e;
    }
  };

  const [pcploaded, setPcpLoaded] = useState(false);
  const [showUnableToClearModal, setShowUnableToClearModal] = useState(false);
  const [showProviderNotMessageableModal, setShowProviderNotMessageableModal] = useState(false);

  useEffect(() => {
    if (!eventId) {
      setShowUnableToClearModal(true);
    }
  }, [eventId]);

  let schemaToLoad;
  if (advisoryName === ClinicTypes.FLU_SHOT) {
    schemaToLoad = fluShotSchemas;
  } else {
    schemaToLoad = defaultHealthAdvisorySchema;
  }

  // PCP provider not messageable (or no Intermountain PCP)
  useEffect(() => {
    if (!pcp || !pcp.messagingPools || !pcp.messagingPools.length) {
      setShowProviderNotMessageableModal(true);
    }
    if (pcp && pcp.messagingPools && pcp.messagingPools[0].groupDisplayName) {
      setPcpLoaded(true);
    }
  }, [pcp]);

  const commonModalProps = {
    isOpen: true,
    confirmLabel: 'DISMISS',
    onConfirm: handleClose
  };

  const renderModal = () => {
    if (showUnableToClearModal) {
      return (
        <ConfirmModal
          {...commonModalProps}
          accessibilityLabel="Unable to clear advisory modal"
          title="Unable to clear Advisory"
          description="Sorry this advisory is unable to be cleared at the moment. Contact your Provider for more information"
        />
      );
    }

    if (showProviderNotMessageableModal) {
      return (
        <ConfirmModal
          {...commonModalProps}
          accessibilityLabel="Provider not messageable modal"
          title="Your provider cannot be messaged"
          description="Please call your provider to close this health advisory"
        />
      );
    }

    return null;
  };

  return (
    <>
      {renderModal()}
      <MuiBox px={5}>
        <MuiBox my={4}>
          <StyledHeader>{`${advisoryName}  Advisory Documentation`}</StyledHeader>
        </MuiBox>
        <MuiDivider />
        <StyledInstructions>{formInstructions}</StyledInstructions>
        <RequiredFieldsLegend />
        <Formik
          initialValues={initialValues}
          validationSchema={schemaToLoad}
          onSubmit={handleSubmitForm}
        >
          {({
            values,
            isValid,
            handleChange,
            setFieldValue,
            handleSubmit,
            isSubmitting,
            errors,
            touched,
            handleBlur,
            dirty
          }) => {
            if (
              !isDropDownShow &&
              values.providerGroupName === '' &&
              pcploaded &&
              pcp &&
              pcp.messagingPools
            ) {
              setFieldValue('providerGroupName', pcp.messagingPools[0].groupDisplayName);
            }

            const getFormError = (key: keyof typeof values) => {
              return touched[key] ? errors[key] : '';
            };
            return (
              <>
                <MuiBox my={2}>
                  <StyledFormTitle>{formTitles.providerPool}</StyledFormTitle>
                  <StyledText>
                    {providerPoolSelect} <StyledAsterisk />
                  </StyledText>
                  <StyledNativeSelect
                    value={values.providerGroupName}
                    onChange={e => setFieldValue('providerGroupName', e.target.value)}
                    inputProps={{
                      name: 'messagingPool'
                    }}
                    IconComponent={params => (
                      <Svg {...params} set="material" name="keyboard-arrow-down" size="24" />
                    )}
                    helperText={getFormError('providerGroupName')}
                    error={!!getFormError('providerGroupName')}
                  >
                    <option value="" disabled>
                      Select...
                    </option>
                    {myDoctors &&
                      myDoctors.map(item => (
                        <option key={`${item.groupDisplayName}`} value={item.groupDisplayName}>
                          {item.groupDisplayName}
                        </option>
                      ))}
                  </StyledNativeSelect>
                </MuiBox>
                <Spacer size="small" />
                <MuiBox my={2}>
                  <StyledFormTitle>{formTitles.when}</StyledFormTitle>
                  <StyledDateFormControl>
                    <StyledText>
                      Date of Service <StyledAsterisk />
                    </StyledText>
                    <KeyboardDatePicker
                      autoOk
                      disableFuture
                      disableToolbar
                      variant="inline"
                      format="MM/DD/YYYY"
                      margin="normal"
                      value={values.date}
                      onChange={day => setFieldValue('date', dayjs(day).toDate())}
                      KeyboardButtonProps={{
                        'aria-label': 'change date'
                      }}
                      onBlur={handleBlur('date')}
                      error={!!getFormError('date')}
                      helperText={getFormError('date')}
                      keyboardIcon={<Svg set="assets" name="CalendarFlat" />}
                    />
                  </StyledDateFormControl>
                </MuiBox>
                <Spacer size="small" />
                <MuiBox my={2} display="flex" flexDirection="column">
                  <StyledFormTitle>{formTitles.where}</StyledFormTitle>
                  <FormControl>
                    <StyledText>
                      Location Name <StyledAsterisk />
                    </StyledText>
                    <FullWidthTextField
                      required
                      placeholder="Location"
                      onChange={handleChange('location')}
                      onBlur={handleBlur('location')}
                      margin="none"
                      InputLabelProps={{
                        shrink: true
                      }}
                      error={!!getFormError('location')}
                      helperText={getFormError('location')}
                    />
                  </FormControl>
                  <Spacer size="small" />
                  <FormControl>
                    <StyledText>Address</StyledText>
                    <FullWidthTextField
                      onChange={handleChange('address')}
                      onBlur={handleBlur('address')}
                      margin="none"
                      InputLabelProps={{
                        shrink: true
                      }}
                      error={!!getFormError('address')}
                      helperText={getFormError('address')}
                    />
                  </FormControl>
                  <Spacer size="small" />
                  <FormControl>
                    <StyledText>Address Line 2</StyledText>
                    <FullWidthTextField
                      onChange={handleChange('address2')}
                      onBlur={handleBlur('address2')}
                      margin="none"
                      InputLabelProps={{
                        shrink: true
                      }}
                      error={!!getFormError('address2')}
                      helperText={getFormError('address2')}
                    />
                  </FormControl>
                  <Spacer size="small" />
                </MuiBox>
                <MuiBox>
                  <FormControl>
                    <StyledText>City</StyledText>
                    <StyledTextField
                      onChange={handleChange('city')}
                      name="city"
                      onBlur={handleBlur('city')}
                      error={!!getFormError('city')}
                      helperText={getFormError('city')}
                    />
                  </FormControl>
                  <StyledFormControl>
                    <StyledText>State</StyledText>
                    <StateSelect
                      value={values.state}
                      onChange={handleChange('state')}
                      IconComponent={params => (
                        <Svg {...params} set="material" name="keyboard-arrow-down" size="24" />
                      )}
                      error={!!getFormError('state')}
                      helperText={getFormError('state')}
                    >
                      {LOCATION_STATES.map(e => (
                        <MenuItem value={e.value}>{e.value}</MenuItem>
                      ))}
                    </StateSelect>
                  </StyledFormControl>
                  <FormControl>
                    <StyledText>Zip Code</StyledText>
                    <StyledZip
                      InputProps={{
                        inputComponent: MaskedText,
                        inputProps: {
                          mask: masks.zip.default
                        }
                      }}
                      onChange={handleChange('zip')}
                      onBlur={handleBlur('zip')}
                      error={!!getFormError('zip')}
                      helperText={getFormError('zip')}
                    />
                  </FormControl>
                </MuiBox>
                <Spacer size="small" />
                <MuiBox my={2}>
                  <StyledFormTitle>{formTitles.who}</StyledFormTitle>
                  <FormControl>
                    <StyledText>
                      Provider name
                      {schemaToLoad.fields && schemaToLoad.fields.providerName ? (
                        <StyledAsterisk />
                      ) : (
                        ''
                      )}
                    </StyledText>
                    <StyledTextField
                      onChange={handleChange('providerName')}
                      onBlur={handleBlur('providerName')}
                      error={!!getFormError('providerName')}
                      helperText={getFormError('providerName')}
                    />
                  </FormControl>
                  <StyledFormControl>
                    <StyledText>Provider Phone</StyledText>
                    <StyledTextField
                      InputProps={{
                        inputComponent: MaskedText,
                        inputProps: {
                          mask: phoneNumberMask
                        }
                      }}
                      onChange={handleChange('phone')}
                      onBlur={handleBlur('phone')}
                      helperText={getFormError('phone')}
                      error={!!getFormError('phone')}
                    />
                  </StyledFormControl>
                </MuiBox>
                <Spacer size="small" />
                <MuiBox my={2}>
                  <StyledFormTitle>{formTitles.what}</StyledFormTitle>
                  <FormControl>
                    <FormControl component="fieldset">
                      <RadioGroup
                        aria-label="result"
                        name="result"
                        onChange={e => setFieldValue('outCome', e.target.value)}
                      >
                        <FormControlLabel value="normal" control={<Radio />} label="Normal" />
                        <FormControlLabel value="abnormal" control={<Radio />} label="Abnormal" />
                      </RadioGroup>
                    </FormControl>
                  </FormControl>
                </MuiBox>
                <MuiBox my={3}>
                  <MuiBox mb={2}>
                    <MuiDivider />
                  </MuiBox>
                  <StyledCheckBoxNote>{noteToRead}</StyledCheckBoxNote>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.isTermsAccepted}
                        onChange={handleChange('isTermsAccepted')}
                        name="hasRead"
                        color="primary"
                      />
                    }
                    label={
                      <>
                        {readMessage}
                        <StyledAsterisk />
                      </>
                    }
                  />

                  <MuiBox mt={2}>
                    <MuiDivider />
                  </MuiBox>
                </MuiBox>
                <MuiBox
                  mt={3}
                  mb={6}
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <StyledHelperText>{submitHelperText}</StyledHelperText>

                  <MuiGrid container direction="column" alignItems="center">
                    <MuiButton
                      disabled={!(isValid && dirty) || isSubmitting}
                      fullWidth
                      variant="contained"
                      color="primary"
                      onClick={handleSubmit}
                    >
                      Submit
                    </MuiButton>
                  </MuiGrid>
                </MuiBox>
              </>
            );
          }}
        </Formik>
      </MuiBox>
    </>
  );
};

export default CloseHealthAdvisoryScreen;
