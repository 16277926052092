import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import noop from 'lodash/noop';

import { IconSize, Spacing } from 'modules/styles/variables';
import { Color } from 'modules/styles/colors';

import Typography from 'components/UI/Typography';
import TouchablePanelGroup from 'components/UI/Panel/TouchablePanelGroup';
import DefaultText from 'components/UI/Typography/DefaultText';
import { MuiBox, TypographyProps } from 'theme/material-ui';
import { Icon } from 'components/Icon';

interface AmountSummaryTableProps {
  headers: string[];
  hideHeaders?: boolean;
  data?: ItemBody[];
  emptyCaption?: string;
  arrows?: boolean;
  largeView?: boolean;
  onItemPressed?: Function;
}

interface LargeViewProps extends TypographyProps {
  largeView: boolean;
}

export const GreyTypography = styled(Typography)`
  color: ${Color.gray};
`;

export const HeaderFlexBox = styled(MuiBox)`
  padding-horizontal: 20;
  padding-vertical: ${Spacing.medium};
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${Color.foreColor};
`;

const EmptyBox = styled(MuiBox)`
  padding-horizontal: 20;
  padding-vertical: ${Spacing.large};
  align-items: center;
  justify-content: center;
`;

const EndIcon = styled(Icon)`
  justify-content: flex-end;
  top: -${(props: LargeViewProps) => (props.largeView ? '2' : IconSize.small)}px;
`;

const WideText = styled(Typography)`
  flex: 3;
`;
const SkinnyText = styled(Typography)`
  flex: 2;
`;
const SkinnyBoldText = styled(SkinnyText)`
  ${(props: LargeViewProps) => (props.largeView ? 'text-align: right;' : '')}
  font-weight: bold;
`;

const GreyHeaderText = styled(MuiBox)`
  color: ${Color.gray};
`;

function AmountSummaryTable({
  data,
  headers,
  hideHeaders,
  emptyCaption,
  arrows,
  largeView,
  onItemPressed
}: AmountSummaryTableProps) {
  const [currentData, setCurrentData] = useState();

  useEffect(() => {
    if (data) {
      setCurrentData(data);
    }
  }, [data]);

  if (!data || data.length === 0) {
    return (
      <EmptyBox>
        <DefaultText>{emptyCaption}</DefaultText>
      </EmptyBox>
    );
  }

  return (
    <>
      {!hideHeaders && (
        <HeaderFlexBox padding={arrows ? '0px 20px' : '0px 15px 0px 20px'}>
          <GreyHeaderText width="33%" display="inline-block">
            {headers[0]}
          </GreyHeaderText>

          <GreyHeaderText width="33%" display="inline-block" paddingLeft="3px">
            {headers[1]}
          </GreyHeaderText>

          <GreyHeaderText
            width="33%"
            display="inline-block"
            textAlign="right"
            paddingRight={arrows ? '18px' : ''}
          >
            {headers[2]}
          </GreyHeaderText>
        </HeaderFlexBox>
      )}

      <TouchablePanelGroup
        data={currentData}
        emptyCaption="Empty Data"
        keyExtractor={(itemBody: ItemBody) => `${itemBody.id}`}
        onItemPressed={onItemPressed}
        renderItem={(itemBody: ItemBody) => (
          <MuiBox
            data-testid="amount-summary-record"
            aria-label={`Tap for more info ${itemBody.left} ${itemBody.middle} ${itemBody.right}`}
            justifyContent="space-between"
            alignItems="center"
            title={headers[0]}
            display={largeView ? 'flex' : 'block'}
          >
            {!largeView && <SkinnyText>{itemBody.left}</SkinnyText>}

            {largeView && (
              <MuiBox width="30%">
                <SkinnyText>{itemBody.left}</SkinnyText>
              </MuiBox>
            )}

            {!largeView && (
              <WideText numberOfLines={1} title={headers[1]}>
                {itemBody.middle}
              </WideText>
            )}

            {largeView && (
              <MuiBox width="30%">
                <WideText numberOfLines={1} title={headers[1]}>
                  {itemBody.middle}
                </WideText>
              </MuiBox>
            )}

            <MuiBox width={largeView ? '33%' : 'unset'} display="flex">
              <SkinnyBoldText largeView={largeView} numberOfLines={1} title={headers[2]}>
                {itemBody.right}
              </SkinnyBoldText>
              {arrows && (
                <EndIcon
                  largeView={largeView}
                  name="keyboard-arrow-right"
                  size={largeView ? IconSize.base : IconSize.large}
                />
              )}
            </MuiBox>
          </MuiBox>
        )}
      />
    </>
  );
}

AmountSummaryTable.defaultProps = {
  data: [],
  emptyCaption: undefined,
  headers: [],
  arrows: false,
  largeView: false,
  onItemPressed: noop
};

export default AmountSummaryTable;
