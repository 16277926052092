import { MuiButton } from 'theme/material-ui';
import styled from 'styled-components';
import List from '@material-ui/core/List';
import Screen from 'components/UI/Layout/Screen';
import { Color } from 'modules/styles/colors';
import { Spacing } from 'modules/styles/variables';

export const StyledScreen = styled(Screen)`
  height: 100%;
  overflow: auto;
`;

export const StyledMuiButton = styled(MuiButton)`
  &.MuiButton-root {
    min-width: 250px;
    color: ${Color.secondary};
    border: 1px solid ${Color.secondary};
  }
  &&.MuiButton-contained {
    background-color: ${Color.secondary};
    color: ${Color.white};
  }
`;

/* FAQS */
export const StyledList = styled(List)`
  &.MuiList-padding {
    padding-top: ${Spacing.none};
    padding-bottom: ${Spacing.none};
  }
  & .MuiListItem-container {
    height: 65px;
    background-color: ${Color.baseColor};
    & .MuiListItem-root {
      padding: ${Spacing.large}px;
    }
    border-bottom: 1px solid ${Color.grayLight};
    &:last-child {
      border-bottom: ${Spacing.none};
    }
  }
`;
