import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { MuiBox, MuiGrid, dfdDefaultTheme } from 'theme/material-ui';
import { RootState, RootDispatch } from 'store/types';
import * as selectors from 'store/amwell/selectors';
import { getPreferredPharmacy, getCountries } from 'store/amwell/actions';
import { Pharmacy } from 'store/amwell/types';
import { ConnectCareStepActions, ConnectCareStepAction } from 'components/ConnectCare';
import AbsoluteSpinner from 'components/common/AbsoluteSpinner/AbsoluteSpinner';
import ConnectCarePharmacyPreferred from './ConnectCarePharmacyPreferred';
import ConnectCarePharmacySearchForm from './ConnectCarePharmacySearchForm';
import { RouteData } from '../types';
import { FormScreen } from '../styled';
import { Divider } from '@material-ui/core';
import Title from 'components/UI/Typography/Title';
import styled from 'styled-components';
import { Spacing } from 'modules/styles/variables';

const PharmacySelect = styled.div`
  margin: 0px ${Spacing.xxLarge}px;
  ${dfdDefaultTheme.breakpoints.down('lg')} {
    margin: 0px ${Spacing.xLarge}px;
  }
  ${dfdDefaultTheme.breakpoints.down('md')} {
    margin: 0px ${Spacing.large}px;
  }
`;
export interface Props extends RouteComponentProps {
  dispatch: RootDispatch;
  preferred: Pharmacy | null;
  loading: boolean;
}

export function ConnectCarePharmacySelect(props: Props) {
  const { dispatch, preferred, loading, history } = props;
  const [updating, setUpdating] = useState(false);

  useEffect(() => {
    dispatch(getPreferredPharmacy());
    dispatch(getCountries());
  }, []);

  const onPrevClick = (prevStep?: RouteData) => {
    if (prevStep) {
      history.push(prevStep.path);
    }
  };

  const onNextClick = (nextStep?: RouteData) => {
    if (nextStep) {
      history.push(nextStep.path);
    }
  };

  return (
    <>
      <FormScreen>
        {loading && <AbsoluteSpinner />}

        <PharmacySelect>
          <MuiBox margin="20px 0px">
            <Title>Select A Pharmacy</Title>
          </MuiBox>

          <Divider />
          {preferred && !updating ? (
            <ConnectCarePharmacyPreferred
              data-testid="connect-care-pharmacy-preferred"
              preferred={preferred}
              change={() => setUpdating(true)}
            />
          ) : (
            <MuiGrid container direction="column" alignItems="stretch">
              <MuiBox py={2}>
                <ConnectCarePharmacySearchForm
                  data-testid="connect-care-pharmacy-search"
                  setUpdating={setUpdating}
                />
              </MuiBox>
            </MuiGrid>
          )}
        </PharmacySelect>
      </FormScreen>
      <ConnectCareStepActions>
        <ConnectCareStepAction onClick={onPrevClick} />
        <ConnectCareStepAction disabled={loading} onClick={onNextClick} />
      </ConnectCareStepActions>
    </>
  );
}

const mapStateToProps = (state: RootState) => ({
  preferred: selectors.preferredPharmacyDataSelector(state),
  loading:
    selectors.pharmaciesListLoadingSelector(state) ||
    selectors.countriesLoadingSelector(state) ||
    selectors.preferredPharmacyLoadingSelector(state)
});

export default connect(mapStateToProps)(ConnectCarePharmacySelect);
