import React, { ComponentType, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import { maskPhone } from 'modules/utils/MaskUtils';
import { MuiContainer, MuiTypography } from 'theme/material-ui';
import Spacer from 'components/UI/Layout/Spacer';
import Footer from 'components/common/Footer';
import dayjs from 'dayjs';

import { RootState } from 'store/types';
import * as covidScreeningSelectors from 'store/CovidScreening/selectors';
import { Insurance, Grantor } from 'store/profile/types';
import {
  profileConsumerMilleniumInsuranceSelector,
  profileGrantorsSelector
} from 'store/profile/selectors';
import { CovidTitles } from 'lib/constants/covidScreening';
import { Color } from 'modules/styles/colors';
import { SpinnerOverlay } from 'components/UI/Spinner/SpinnerModal';
import { AnalyticsEvent } from 'services/AnalyticsService';
import useNavigationAnalytics from 'hooks/useNavigationAnalytics';
import * as covidActions from 'store/CovidScreening/actions';
import { Patient, Questionnaire, CovidScreeningState } from 'store/CovidScreening/types';
import { patientSelect } from 'modules/constants/covidScreening';
import { FontWeight } from 'modules/styles/variables';
import useStyles from '../Components/CovidTestButton/useStyles';
import { CovidTestButton } from '../Components/CovidTestButton';

export interface Props extends RouteComponentProps {
  patientsData: Patient;
  questionnaire: Questionnaire;
  covidScreeningData: CovidScreeningState;
  sendCovidScreeningFinalData: typeof covidActions.sendCovidScreeningData;
  setCovidScreeningOrderStatus: typeof covidActions.covidOrderStatus;
  activeInsurance: Insurance[];
  grantors: Grantor[];
}

export function CovidScreenSummary({
  history,
  questionnaire,
  patientsData,
  covidScreeningData,
  sendCovidScreeningFinalData,
  setCovidScreeningOrderStatus,
  activeInsurance,
  grantors
}: Props) {
  const covidTestSummaryPrev = useNavigationAnalytics(AnalyticsEvent.CovidPrev);
  const covidTestSummarySubmit = useNavigationAnalytics(AnalyticsEvent.CovidSubmit);
  const classes = useStyles();
  const getSubscriberDOB = () => {
    if (
      covidScreeningData?.questionnaire.subscriberDateOfBirth ||
      activeInsurance[0]?.subscriber.dateOfBirth
    ) {
      return dayjs(
        covidScreeningData?.questionnaire.subscriberDateOfBirth ||
          activeInsurance[0]?.subscriber.dateOfBirth
      ).format('MM/DD/YYYY');
    }

    return '';
  };

  const [isLoading, setIsLoading] = useState(false);

  const getSubscriberRelation = () => {
    let subscriberRelationship = '';
    if (grantors.length >= 1) {
      const selectedGrantor = grantors.find(
        element => element.consumerId === covidScreeningData.patient.cmrn
      );
      subscriberRelationship = selectedGrantor?.relationship;
    }
    if (!subscriberRelationship) {
      const { relationship } = activeInsurance[0].subscriber;
      subscriberRelationship = relationship;
    }
    return subscriberRelationship;
  };

  const createQuestionnaireObject = () => {
    let questionnaireObject = {
      Q_COVID_Healthcare_Worker_HD_residence:
        covidScreeningData.questionnaire.Q_COVID_Healthcare_Worker_HD_residence,
      Q_Covid19_Prescreening: covidScreeningData.questionnaire.Q_Covid19_Prescreening,
      Q_Covid_Symptoms_Primary: covidScreeningData.questionnaire.Q_Covid_Symptoms_Primary,
      has_insurance: covidScreeningData.questionnaire.has_insurance,
      Q_attest_to_accurate_and_complete:
        covidScreeningData.questionnaire.Q_attest_to_accurate_and_complete,
      Q_COVID_Screen_High_Density_Residence:
        covidScreeningData.questionnaire.Q_COVID_Screen_High_Density_Residence,
      Q_COVID_Screen_Close_Contact: covidScreeningData.questionnaire.Q_COVID_Screen_Close_Contact,
      Q_UDOH_Contact_Tracing: covidScreeningData.questionnaire.Q_UDOH_Contact_Tracing,
      Q_None_of_the_above: covidScreeningData.questionnaire.Q_None_of_the_above,
      patient_first_name: covidScreeningData.patient.names[0].givenName,
      patient_last_name: covidScreeningData.patient.names[0].familyName,
      patient_middle_name: covidScreeningData.patient.names[0].middleName,
      patient_cell_number: maskPhone(
        covidScreeningData.patient.phones[0].areaCode.concat(
          covidScreeningData.patient.phones[0].phoneNumber
        )
      ),
      patient_alt_number: covidScreeningData.patient.alternateNumber
        ?.replace(/[^a-zA-Z0-9 ]/g, '')
        .replace(/ /g, ''),
      patient_ssn: covidScreeningData.patient.ssn || null,
      patient_dob: covidScreeningData.patient.dateOfBirth,
      patient_sex: covidScreeningData.patient.genderType,
      patient_address_1: covidScreeningData.patient.addresses[0].line1,
      patient_address_2: covidScreeningData.patient.addresses[0].line2,
      patient_zip_code: covidScreeningData.patient.addresses[0].zip,
      patient_city: covidScreeningData.patient.addresses[0].city,
      patient_state: covidScreeningData.patient.addresses[0].state,
      patient_email: covidScreeningData.patient.emails[0].address,
      SUBSCRIBER_FIRST_NAME_ON_CARD: covidScreeningData.questionnaire.SUBSCRIBER_FIRST_NAME_ON_CARD,
      SUBSCRIBER_LAST_NAME_ON_CARD: covidScreeningData.questionnaire.SUBSCRIBER_LAST_NAME_ON_CARD,
      SUBSCRIBER_MIDDLE_NAME_ON_CARD:
        covidScreeningData.questionnaire.SUBSCRIBER_MIDDLE_NAME_ON_CARD
    };
    if (covidScreeningData.questionnaire.has_insurance === 'Yes') {
      questionnaireObject = {
        ...questionnaireObject,
        Health_Plan_Name:
          covidScreeningData.questionnaire.Health_Plan_Name || activeInsurance[0]?.planName,
        HealthPlan_state:
          covidScreeningData.questionnaire.HealthPlan_state || activeInsurance[0]?.address.state,
        HealthPlan_zip_code:
          covidScreeningData.questionnaire.HealthPlan_zip_code ||
          activeInsurance[0]?.address.postalCode,
        HealthPlan_City:
          covidScreeningData.questionnaire.HealthPlan_City || activeInsurance[0]?.address.city,
        Health_Plan_ID:
          covidScreeningData.questionnaire.Health_Plan_ID.toString() || activeInsurance[0]?.planId,
        Health_Plan_Address_line_1:
          covidScreeningData.questionnaire.Health_Plan_Address_line_1 ||
          activeInsurance[0]?.address.streetAddress,
        HEALTH_PLAN_COUNTRY:
          covidScreeningData.questionnaire.HEALTH_PLAN_COUNTRY ||
          activeInsurance[0]?.address.country,
        MEMBER_NUMBER:
          covidScreeningData.questionnaire.MEMBER_NUMBER ||
          activeInsurance[0]?.members[0]?.subscriberId,
        GROUP_NUMBER: covidScreeningData.questionnaire.GROUP_NUMBER,
        subscriber_first_name:
          covidScreeningData?.subscriber.subscriberFirstName ||
          activeInsurance[0]?.subscriber.firstName,
        subscriber_last_name:
          covidScreeningData?.subscriber.subscriberLastName ||
          activeInsurance[0]?.subscriber.lastName,
        subscriber_dob: getSubscriberDOB(),
        subscriber_middle_name:
          covidScreeningData?.subscriber.subscriberMiddleName ||
          activeInsurance[0]?.subscriber.middleName
      };
    }
    if (
      covidScreeningData.questionnaire.has_insurance === 'Yes' &&
      covidScreeningData.questionnaire?.correctInsurance === 'No'
    ) {
      questionnaireObject = {
        ...questionnaireObject,
        Q_Patient_relationship_to_subscriber:
          covidScreeningData.questionnaire.Q_Patient_relationship_to_subscriber ||
          getSubscriberRelation()
      };
    }
    if (covidScreeningData.questionnaire.Q_Outbreak_Date_of_Symptoms_Onset) {
      questionnaireObject = {
        ...questionnaireObject,
        Q_Fever: covidScreeningData.questionnaire.Q_Fever,
        Q_New_or_Worsening_Cough: covidScreeningData.questionnaire.Q_New_or_Worsening_Cough,
        Q_Shortness_of_Breath_New_or_Worsening:
          covidScreeningData.questionnaire.Q_Shortness_of_Breath_New_or_Worsening,
        Q_Body_Aches: covidScreeningData.questionnaire.Q_Body_Aches,
        Q_COVID_Screen_Sore_Throat: covidScreeningData.questionnaire.Q_COVID_Screen_Sore_Throat,
        Q_COVID_Screen_Runny_Nose: covidScreeningData.questionnaire.Q_COVID_Screen_Runny_Nose,
        Q_COVID_Screen_Decreased_Smell_or_Taste:
          covidScreeningData.questionnaire.Q_COVID_Screen_Decreased_Smell_or_Taste,
        Q_Diarrhea: covidScreeningData.questionnaire.Q_Diarrhea,
        Q_Outbreak_Date_of_Symptoms_Onset:
          covidScreeningData.questionnaire.Q_Outbreak_Date_of_Symptoms_Onset
      };
    }
    return questionnaireObject;
  };

  const createSubscriberObject = () => {
    let subscriberObject = null;
    if (covidScreeningData.questionnaire.Q_Patient_relationship_to_subscriber === 'Self') {
      subscriberObject = {
        dateOfBirth: dayjs(covidScreeningData.patient.dateOfBirth).format('MM/DD/YYYY'),
        genderType: covidScreeningData.patient.genderType,
        names: [
          {
            givenName: covidScreeningData.patient.names[0].givenName,
            familyName: covidScreeningData.patient.names[0].familyName,
            middleName: covidScreeningData.patient.names[0].middleName
          }
        ],
        addresses: [
          {
            line1: covidScreeningData.patient.addresses[0].line1,
            line2: covidScreeningData.patient.addresses[0].line2,
            city: covidScreeningData.patient.addresses[0].city,
            state: covidScreeningData.patient.addresses[0].state,
            zip: covidScreeningData.patient.addresses[0].zip,
            type: 'HOMEADDR'
          }
        ],
        phones: [
          {
            areaCode: covidScreeningData.patient.phones[0].areaCode,
            phoneNumber: covidScreeningData.patient.phones[0].phoneNumber,
            countryCode: null,
            phoneType: 'CELLPHONE'
          }
        ],
        emails: [
          {
            address: covidScreeningData.patient.emails[0].address,
            type: 'HOMEEMAIL'
          }
        ]
      };
    }
    if (covidScreeningData?.subscriber.subscriberDateOfBirth) {
      subscriberObject = {
        dateOfBirth: dayjs(covidScreeningData?.subscriber.subscriberDateOfBirth).format(
          'MM/DD/YYYY'
        ),
        genderType: covidScreeningData?.subscriber.subscriberSex,
        names: [
          {
            givenName: covidScreeningData?.subscriber.subscriberFirstName,
            familyName: covidScreeningData?.subscriber.subscriberLastName,
            middleName: covidScreeningData?.subscriber.subscriberMiddleName
          }
        ],
        addresses: [
          {
            line1: covidScreeningData?.subscriber.subscriberAddressLine1,
            line2: covidScreeningData?.subscriber.subscriberAddressLine2,
            city: covidScreeningData?.subscriber.subscriberCity,
            state: covidScreeningData?.subscriber.subscriberState,
            zip: covidScreeningData?.subscriber.subscriberZip,
            type: 'HOMEADDR'
          }
        ],
        phones: [
          {
            areaCode: covidScreeningData?.subscriber.subscriberCellNumber
              ?.replace(/[^a-zA-Z0-9]/g, '')
              .substring(0, 3),
            phoneNumber: covidScreeningData?.subscriber.subscriberCellNumber
              ?.replace(/[^a-zA-Z0-9]/g, '')
              .substring(3, 10),
            countryCode: null,
            phoneType: 'CELLPHONE'
          }
        ],
        emails: [
          {
            address: covidScreeningData?.subscriber.subscriberEmail,
            type: 'HOMEEMAIL'
          }
        ]
      };
    }
    return subscriberObject;
  };

  const submitCovidScreening = async () => {
    const patientObject = {
      dateOfBirth: covidScreeningData.patient.dateOfBirth,
      genderType: covidScreeningData.patient.genderType,
      cmrn: covidScreeningData.patient.cmrn,
      names: covidScreeningData.patient.names,
      addresses: covidScreeningData.patient.addresses,
      phones: covidScreeningData.patient.phones,
      emails: covidScreeningData.patient.emails
    };

    let insuranceCardData = [];

    if (covidScreeningData?.insuranceCard?.length) {
      let insuranceFront = {};
      let insuranceBack = {};
      if (covidScreeningData.insuranceCard?.[0]) {
        insuranceFront = {
          fileName: covidScreeningData.insuranceCard?.[0].fileName,
          img: covidScreeningData.insuranceCard?.[0].img,
          mimeType: covidScreeningData.insuranceCard?.[0].mimeType
        };
        insuranceCardData = [insuranceFront];
      }
      if (covidScreeningData.insuranceCard?.[1]) {
        insuranceBack = {
          fileName: covidScreeningData.insuranceCard?.[1].fileName,
          img: covidScreeningData.insuranceCard?.[1].img,
          mimeType: covidScreeningData.insuranceCard?.[1].mimeType
        };
        insuranceCardData = [...insuranceCardData, insuranceBack];
      }
    }

    const sendData = {
      patient: patientObject,
      questionnaire: createQuestionnaireObject(),
      subscriber: createSubscriberObject(),
      insuranceCard: insuranceCardData.length ? insuranceCardData : null
    };
    setIsLoading(true);
    const result = await sendCovidScreeningFinalData(sendData);
    setIsLoading(false);
    covidTestSummarySubmit({
      title: CovidTitles.PATIENT_SUMMARY
    });
    if (result?.error) {
      setCovidScreeningOrderStatus(result?.error?.response?.status);
      history.push('/u/get-care-now/covid-screen/order-status');
    } else {
      history.push('/u/get-care-now/covid-screen/order-confirmation');
    }
  };
  const handleCancel = () => {
    covidTestSummaryPrev({
      title: CovidTitles.PATIENT_SUMMARY
    });
    history.goBack();
  };

  return (
    <>
      <MuiContainer maxWidth="lg">
        <SpinnerOverlay
          isLoading={isLoading}
          position="absolute"
          backgroundColor={Color.translucent}
          testID="Covid-Order-Submit"
        />
        <Spacer size="medium" />
        <MuiTypography fontWeight={FontWeight.bold}>Patient Information Summary</MuiTypography>
        <Spacer size="xsmall" />
        <MuiTypography fontWeight={FontWeight.semibold} display="inline">
          Patient Name:{' '}
        </MuiTypography>{' '}
        <MuiTypography display="inline" data-testid="patient-name">
          {patientsData?.names[0]?.givenName} {patientsData?.names[0]?.middleName}
          {patientsData?.names[0]?.familyName}
        </MuiTypography>
        <MuiTypography>
          <MuiTypography fontWeight={FontWeight.semibold} display="inline">
            Patient Phone:{' '}
          </MuiTypography>{' '}
          <MuiTypography display="inline" data-testid="patient-phone">
            {maskPhone(
              patientsData?.phones[0]?.areaCode.concat(patientsData?.phones[0]?.phoneNumber)
            )}
          </MuiTypography>
        </MuiTypography>
        <MuiTypography>
          <MuiTypography fontWeight={FontWeight.semibold} display="inline">
            Patient Alt Phone if provided:
          </MuiTypography>{' '}
          <MuiTypography display="inline">{patientsData?.alternateNumber}</MuiTypography>
        </MuiTypography>
        <MuiTypography>
          {' '}
          <MuiTypography fontWeight={FontWeight.semibold} display="inline">
            {' '}
            Patient Email:{' '}
          </MuiTypography>
          <MuiTypography display="inline"> {patientsData?.emails[0]?.address} </MuiTypography>
        </MuiTypography>
        <MuiTypography>
          <MuiTypography fontWeight={FontWeight.semibold} display="inline">
            {' '}
            Patient Birthdate:{' '}
          </MuiTypography>
          <MuiTypography display="inline"> {patientsData?.dateOfBirth} </MuiTypography>
        </MuiTypography>
        <MuiTypography>
          <MuiTypography fontWeight={FontWeight.semibold} display="inline">
            Patient Address:{' '}
          </MuiTypography>
          <MuiTypography>
            {`${patientsData?.addresses[0]?.line1} ${patientsData.addresses[0]?.line2}`}
          </MuiTypography>
          <MuiTypography>
            {`${patientsData?.addresses[0]?.city} ${patientsData?.addresses[0]?.state} ${patientsData?.addresses[0]?.zip}`}
          </MuiTypography>
        </MuiTypography>
        <Spacer size="small" />
        <MuiTypography>
          <MuiTypography fontWeight={FontWeight.bold} display="inline">
            {' '}
            Do you have insurance?
          </MuiTypography>{' '}
          <MuiTypography display="inline"> {questionnaire?.has_insurance} </MuiTypography>
        </MuiTypography>
        {questionnaire?.MEMBER_NUMBER ? (
          <>
            <Spacer size="small" />
            {questionnaire?.has_insurance === 'Yes' ? (
              <MuiTypography fontWeight={FontWeight.bold}>Insurance Information</MuiTypography>
            ) : null}
            {questionnaire?.Health_Plan_Name && questionnaire?.has_insurance === 'Yes' ? (
              <MuiTypography>
                <MuiTypography fontWeight={FontWeight.semibold} display="inline">
                  {' '}
                  Plan Network:{' '}
                </MuiTypography>
                <MuiTypography display="inline"> {questionnaire?.Health_Plan_Name} </MuiTypography>
              </MuiTypography>
            ) : null}
            {questionnaire?.MEMBER_NUMBER && questionnaire?.has_insurance === 'Yes' ? (
              <MuiTypography>
                <MuiTypography fontWeight={FontWeight.semibold} display="inline">
                  {' '}
                  Member Number/ID:{' '}
                </MuiTypography>
                <MuiTypography display="inline"> {questionnaire?.MEMBER_NUMBER} </MuiTypography>
              </MuiTypography>
            ) : null}
            {questionnaire?.GROUP_NUMBER && questionnaire?.has_insurance === 'Yes' ? (
              <MuiTypography>
                <MuiTypography fontWeight={FontWeight.semibold} display="inline">
                  {' '}
                  Group Number/ID:{' '}
                </MuiTypography>
                <MuiTypography display="inline"> {questionnaire?.GROUP_NUMBER} </MuiTypography>
              </MuiTypography>
            ) : null}
            {questionnaire?.has_insurance === 'No' ? <Spacer size="small" /> : null}
            {questionnaire?.Q_Patient_relationship_to_subscriber &&
              questionnaire?.Q_Patient_relationship_to_subscriber !== 'Self' && (
                <>
                  <Spacer size="small" />
                  <MuiTypography fontWeight={FontWeight.bold}>Subscriber Information</MuiTypography>
                  <Spacer size="xsmall" />
                  <MuiTypography>
                    <MuiTypography fontWeight={FontWeight.semibold} display="inline">
                      Relationship to subscriber:{' '}
                    </MuiTypography>{' '}
                    <MuiTypography display="inline">
                      {' '}
                      {questionnaire?.Q_Patient_relationship_to_subscriber}
                    </MuiTypography>{' '}
                  </MuiTypography>
                  <MuiTypography>
                    <MuiTypography fontWeight={FontWeight.semibold} display="inline">
                      {' '}
                      Subscriber Name:{' '}
                    </MuiTypography>
                    <MuiTypography display="inline">
                      {covidScreeningData?.subscriber.subscriberFirstName}{' '}
                      {covidScreeningData?.subscriber.subscriberMiddleName}{' '}
                      {covidScreeningData?.subscriber.subscriberLastName}{' '}
                    </MuiTypography>
                  </MuiTypography>
                  <MuiTypography>
                    <MuiTypography fontWeight={FontWeight.semibold} display="inline">
                      {' '}
                      Subscriber Phone:{' '}
                    </MuiTypography>
                    <MuiTypography display="inline">
                      {covidScreeningData?.subscriber.subscriberCellNumber}{' '}
                    </MuiTypography>
                  </MuiTypography>
                  <MuiTypography>
                    <MuiTypography fontWeight={FontWeight.semibold} display="inline">
                      Subscriber Birthday:
                    </MuiTypography>{' '}
                    <MuiTypography display="inline">
                      {' '}
                      {dayjs(covidScreeningData?.subscriber.subscriberDateOfBirth).format(
                        'MM/DD/YYYY'
                      )}{' '}
                    </MuiTypography>{' '}
                  </MuiTypography>
                  <MuiTypography>
                    <MuiTypography fontWeight={FontWeight.semibold} display="inline">
                      {' '}
                      Subscriber Email:
                    </MuiTypography>
                    <MuiTypography display="inline">
                      {covidScreeningData?.subscriber.subscriberEmail}{' '}
                    </MuiTypography>{' '}
                  </MuiTypography>
                  <MuiTypography>
                    <MuiTypography fontWeight={FontWeight.semibold} display="inline">
                      Subscriber Sex:
                    </MuiTypography>
                    <MuiTypography display="inline">
                      {covidScreeningData?.subscriber.subscriberSex}
                    </MuiTypography>{' '}
                  </MuiTypography>
                  <MuiTypography fontWeight={FontWeight.semibold} display="inline">
                    Subscriber Address:{' '}
                  </MuiTypography>
                  <MuiTypography>
                    {`${covidScreeningData?.subscriber.subscriberAddressLine1} ${covidScreeningData?.subscriber.subscriberAddressLine2}`}
                  </MuiTypography>
                  <MuiTypography>
                    {`${covidScreeningData?.subscriber.subscriberCity} ${covidScreeningData?.subscriber.subscriberState} ${covidScreeningData?.subscriber.subscriberZip}`}
                  </MuiTypography>
                </>
              )}
          </>
        ) : null}
        <Spacer size="small" />
        <MuiTypography>
          If you would like to correct or change the information provided, please select the
          Previous button. If all information looks correct, proceed to see a list of available
          testing sites.
        </MuiTypography>
        <Spacer size="small" />
        <MuiContainer className={classes.steppersFooter}>
          <CovidTestButton
            fullWidth
            variant="contained"
            data-testid="summary-next-button"
            onClick={() => submitCovidScreening()}
          >
            Submit
          </CovidTestButton>
          <CovidTestButton
            fullWidth
            color="default"
            variant="contained"
            data-testid="summary-previous-button"
            onClick={handleCancel}
          >
            Previous
          </CovidTestButton>
        </MuiContainer>
        <Spacer size="small" />
        <Footer
          headerText={patientSelect.GET_PATIENTS.HELPLINE.CovidInfo}
          link={patientSelect.GET_PATIENTS.HELPLINE.CovidPatientSelectHelpline}
          linkName={patientSelect.GET_PATIENTS.HELPLINE.CovidPatientSelectHelpline}
          helplineHours={patientSelect.GET_PATIENTS.HELPLINE.CovidHelplineHours}
          title={CovidTitles.PATIENT_SUMMARY}
          authenticated
        />
        <Spacer size="small" />
      </MuiContainer>
    </>
  );
}

const mapStateToProps = (state: RootState) => ({
  questionnaire: covidScreeningSelectors.CovidScreeningQuestionnaireSelector(state),
  patientsData: covidScreeningSelectors.CovidScreeningPatientInfoSelector(state),
  covidScreeningData: covidScreeningSelectors.CovidScreeningSelector(state),
  subscriberInfo: covidScreeningSelectors.CovidScreeningSubscriberSelector(state),
  activeInsurance: profileConsumerMilleniumInsuranceSelector(state),
  grantors: profileGrantorsSelector(state)
});

const mapDispatchToProps = {
  sendCovidScreeningFinalData: covidActions.sendCovidScreeningData,
  setCovidScreeningOrderStatus: covidActions.covidOrderStatus
};

export default connect(mapStateToProps, mapDispatchToProps)(CovidScreenSummary as ComponentType);
