import React from 'react';
import { SectionListData } from 'react-native';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

interface FABasicInfoHeaderProps {
  section: SectionListData<any>;
}

function FABasicInfoHeader(props: FABasicInfoHeaderProps) {
  const { section } = props;

  if (!section.title && !section.action) return null;

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
      {section.title && <Typography variant="h6">{section.title}</Typography>}
      {/* {section.action && (
        <Button onClick={fn ? basicInfoFns[fn] : () => {}} color="primary">
          {section.action.label}
        </Button>
      )} */}
    </Box>
  );
}

export default FABasicInfoHeader;
