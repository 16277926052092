import { AlertDialog } from 'components/Alert';
import ErrorBoundary from 'components/common/ErrorBoundary/ErrorBoundary';
import ErrorHandler from 'components/UI/ErrorHandler';
import {
  ConnectedRouter,
  connectRouter,
  routerMiddleware,
  RouterState,
  LocationChangeAction
} from 'connected-react-router';
import { history, History } from 'lib/history';
import { DfdMuiThemeProvider } from 'theme/material-ui';
import 'modules/utils/YupExtensions';
import React, { Reducer, useEffect } from 'react';
import Config from 'react-native-config';
import { Provider as ReduxProvider } from 'react-redux';
import { Action, applyMiddleware, combineReducers, compose, createStore, AnyAction } from 'redux';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import { analyticsMiddleware } from 'store/analytics/middleware';
import { AUTHENTICATE_LOGOUT } from 'store/authentication/actions';
import { crashReportingMiddleware, errorReportingMiddleware } from 'store/errorMiddleware';
import {
  attachSessionIdMiddleware,
  validateSessionIdMiddleware
} from 'store/ignoreAfterLogoutMiddleware';
import createNetworkMiddleware from 'store/network/middleware';
import axiosMiddleware from 'store/networkMiddleware';
import routeInterceptorMiddleware from 'store/routeInterceptorMiddleware';
import redundantRequestMiddleware from 'store/networkMiddleware/redundantRequestManager';
import amwellMiddleware from 'store/amwell/middleware';
import ReduxStoreContainer from 'store/ReduxStoreContainer';
import reducers from 'store/rootReducer';
import { RootState } from 'store/types';
import { isActionOfType } from 'store/utils';
import { refreshProfiles } from 'store/PeriodicTasks';
import { createCancellationMiddleware } from 'store/networkMiddleware/cancellationMiddleware';
import AppNavigator from './screens/navigation';
import { GlobalStyles } from './globalStyles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DayJSUtils from '@date-io/dayjs';
import analyticsService, { AmplitudeEventData, AnalyticsEvent } from 'services/AnalyticsService';
import { oc } from 'ts-optchain';

// can be used in reducers without bloating them - one line to report exiting a flow
const exitedFlowEvent = (
  action: AnyAction,
  commonPath: string,
  exceptPath: string,
  flowPath: string,
  eventEnum: string,
  data: AmplitudeEventData
) => {
  const lastUrl = oc(action).payload.prevLocation.pathname(null);
  const nextUrl = oc(action).payload.location.pathname(null);

  if (lastUrl && lastUrl.includes(commonPath) && lastUrl.indexOf(exceptPath) === -1) {
    if (nextUrl && nextUrl.indexOf(flowPath) === -1) {
      analyticsService.logEvent(AnalyticsEvent[eventEnum], data);
    }
  }
};

const interceptRouter = (connectRouter: Reducer<RouterState<any>, LocationChangeAction<any>>) => {
  return (state: RootState, action: AnyAction) => {
    const routerState = connectRouter(state, action);

    if (isActionOfType(['@@router/LOCATION_CHANGE'], action)) {
      const { isFirstRendering, prevLocation } = action.payload;
      if (!isFirstRendering) {
        return { ...routerState, prevLocation };
      }
    }
    return routerState;
  };
};

const createAppReducer = (history: History) =>
  combineReducers<RootState | undefined>({
    ...reducers,
    router: interceptRouter(connectRouter(history))
  });

const rootReducer = (history: History) => (state: RootState | undefined, action: Action) => {
  if (isActionOfType([AUTHENTICATE_LOGOUT], action)) {
    state = undefined;
  }

  if (isActionOfType(['@@router/LOCATION_CHANGE'], action)) {
    const dta: AmplitudeEventData = {
      currentUrl: history.location.pathname,
      referringUrl: state.router.location.pathname
    };

    analyticsService.logEvent(AnalyticsEvent.PageViewed, dta);
    exitedFlowEvent(action, '/u/appointments/book', '/verify', '/book', 'BookingFlowExited', dta);
  }
  return createAppReducer(history)(state, action);
};

const middlewares = [
  redundantRequestMiddleware,
  routerMiddleware(history),
  routeInterceptorMiddleware,
  createNetworkMiddleware(),
  attachSessionIdMiddleware,
  createCancellationMiddleware,
  axiosMiddleware,
  amwellMiddleware,
  validateSessionIdMiddleware,
  thunk,
  analyticsMiddleware,
  errorReportingMiddleware,
  crashReportingMiddleware
];

/* eslint-disable no-underscore-dangle, no-undef, max-len */
if (process.env.NODE_ENV === 'development') {
  middlewares.push(createLogger({ collapsed: true }));
}

const middleware = applyMiddleware(...middlewares);

/* eslint-disable no-underscore-dangle, no-undef, max-len */
const composeEnhancers =
  process.env.NODE_ENV === 'development'
    ? (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ as typeof compose) || compose
    : compose;

// Use the second version to import a previous state
// To update, stringify the state from the console, and copy that into 'state.json' JSON.stringify(store.getState())
const preloadedState = undefined;
// const preloadedState = __DEV__ ? require('./state.json') : undefined;

export const store = createStore(
  rootReducer(history),
  preloadedState,
  composeEnhancers(middleware)
);

if (process.env.NODE_ENV === 'development') {
  // @ts-ignore
  window.store = store;
}

ReduxStoreContainer.setStore(store);

refreshProfiles(store);

// eslint-disable-next-line no-undef, no-console
if (process.env.NODE_ENV === 'development') console.disableYellowBox = true;

const App = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = `${Config.MY_HEALTH_STATUS_BASE_URL}/embed/script.js`;
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <GlobalStyles />
      <DfdMuiThemeProvider>
        <ErrorBoundary>
          <MuiPickersUtilsProvider utils={DayJSUtils}>
            <ReduxProvider store={store}>
              <ConnectedRouter history={history}>
                <AlertDialog />
                <AppNavigator />
              </ConnectedRouter>
              <ErrorHandler />
            </ReduxProvider>
          </MuiPickersUtilsProvider>
        </ErrorBoundary>
      </DfdMuiThemeProvider>
    </>
  );
};

export default App;
