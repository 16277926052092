import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import StyledButton from 'components/UI/Button/StyledButton';
import { Column } from 'components/UI/Layout/Container';
import FlexBox, { FlexBoxColumn, FlexBoxRow } from 'components/UI/Layout/FlexBox';
import Section from 'components/UI/Layout/Section';
import PanelSwiper from 'components/UI/Panel/PanelSwiper';
import Typography from 'components/UI/Typography';
import DefaultText from 'components/UI/Typography/DefaultText';
import Heading from 'components/UI/Typography/Heading';
import Subhead from 'components/UI/Typography/Subhead';
import { Color } from 'modules/styles/colors';
import {
  borderRadius,
  Elevation,
  FontSize,
  FontWeight,
  Spacing,
  ZIndex
} from 'modules/styles/variables';
import { Dimensions } from 'react-native';
import styled from 'styled-components';
import { MuiBox, MuiGrid, MuiTypography, dfdDefaultTheme, MuiButton } from 'theme/material-ui';
import { blue, indigo } from '@material-ui/core/colors';
import { Link } from 'react-router-dom';
import Card from 'components/UI/Card/Card';

export const CarouselCard = styled(Card)`
  padding: ${Spacing.large}px;
  height: 300px;
  width: auto;
`;

export const TitleFlexBox = styled(FlexBox)`
  padding-horizontal: ${Spacing.large}px;
  padding-vertical: 64;
`;

export const TransformedSection = styled(Section)`
  transform: translateY(-60px);
`;

export const CloseButton = styled.div`
  position: absolute;
  right: ${Spacing.large}px;
  top: ${Spacing.large}px;
`;

export const PageTitle = styled(Heading)`
  color: ${Color.foreColor};
  text-align: center;
  font-size: ${FontSize.title};
  line-height: ${Spacing.xLarge}px;
  margin-top: ${Spacing.xLarge}px;
  margin-bottom: ${Spacing.medium}px;
`;

export const PatientName = styled(Heading)`
  margin-top: ${Spacing.large}px;
  text-align: center;
`;

export const SpacedRowFlexBox = styled(FlexBoxRow)`
  padding: ${Spacing.large}px;
  justify-content: space-between;
  align-items: center;
  margin-left: ${Spacing.large}px;
  margin-right: ${Spacing.large}px;
  background-color: ${Color.foreColor};
`;

export const SpacedRowFlexBoxVisits = styled(FlexBoxRow)`
  padding: ${Spacing.large}px;
  justify-content: space-between;
  align-items: center;
  margin-left: ${Spacing.large}px;
  margin-right: ${Spacing.large}px;
`;

export const DisclaimerFlexBox = styled(FlexBox)`
  justify-content: center;
  align-items: center;
  flex-direction: column;
  align-items: center;
  margin: ${Spacing.medium}px;
  background-color: ${Color.foreColor};
`;

export const GreyFlexBox = styled(FlexBox)`
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  padding: ${Spacing.medium}px;
  padding-left: ${Spacing.large};
  background-color: ${Color.grayLight};
  border-top-color: ${Color.grayLight};
  border-top-width: 1px;
  border-bottom-color: ${Color.grayLight};
  border-bottom-width: 1px;
`;

export const ButtonRow = styled.div`
  width: 60%;
  margin-top: ${Spacing.large}px;
  margin-left: 35%;
  font-size: 14px;
`;

export const Panel = styled.div<{ accessibilityLabel?: string }>`
  background-color: ${Color.foreColor};
  border: 1px solid ${Color.grayHue4};
  border-radius: ${borderRadius};
  margin: ${Spacing.xLarge}px;
  box-shadow: ${Elevation.el2};
`;

export const StyledHeading = styled(Heading)`
  margin-top: ${Spacing.large}px;
  margin-bottom: ${Spacing.medium}px;
`;

export const PaymentPlanHeading = styled(Heading)`
  color: ${Color.foreColor};
  width: 85%;
  text-align: center;
  margin-top: ${Spacing.large};
  margin-bottom: ${Spacing.large};
  font-size: ${FontSize.xlarge};
`;

export const MarginView = styled.div`
  margin: ${Spacing.large}px;
`;

export const BillingDetailMarginView = styled.div`
  margin: ${Spacing.large}px;
`;

export const BillingDetailMarginViewSummary = styled(FlexBox)`
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: ${Spacing.large}px ${Spacing.xLarge}px;
`;

export const DueNowHeading = styled(DefaultText)`
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  font-weight: ${FontWeight.semibold};
  text-transform: uppercase;
  color: ${Color.red};
`;

export const BorderFlex = styled(FlexBox)`
  border-color: ${Color.gray};
  padding: ${Spacing.small}px;
  padding-top: ${Spacing.large}px;
  border-radius: 5;
  border-width: 1px;
`;

export const BillDetailBorderFlex = styled(BorderFlex)`
  margin: ${Spacing.small}px ${Spacing.xLarge}px;
  display: flex;
  width: max-content;
  text-align: center;
`;

// Deprecating use EmptyMuiBox
export const EmptyFlexBox = styled(FlexBoxRow)`
  margin: ${Spacing.large}px;
  align-items: center;
  justify-content: center;
`;

export const EmptyMuiBox = styled(MuiBox)`
  padding-horizontal: ${Spacing.large}px;
  padding-vertical: ${Spacing.large}px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const BorderMuiBox = styled(MuiBox)`
  border: 1px solid ${Color.grayLight5};
  border-radius: 5px;
  padding: 10px;
  && {
    min-height: 250px;
  }
`;

export const SpinnerContainer = styled.div`
  background-color: ${Color.foreColor};
  opacity: 0.5;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  justify-content: center;
  z-index: ${ZIndex.spinner};
`;

export const GreyTypography = styled(Typography)`
  color: ${Color.grayHue3};
  // font-size: ${FontSize.base}px;
  font-size: 18px;
`;

export const ViewDrillDownFlexBox = styled(FlexBoxColumn)`
  padding: ${Spacing.large}px;
  padding-left: ${Spacing.xLarge}px;
`;

export const PaymentViewDrillDownFlexBox = styled(FlexBox)`
  padding: ${Spacing.large}px;
  padding-left: ${Spacing.large}px;
  flex-direction: column;
`;

export const BillDetailPanel = styled.div`
  border: 1px solid ${Color.grayHue4};
  width: 100%;
  background-color: ${Color.white};
`;

export const SummaryFlexBox = styled(FlexBox)`
  width: ${Dimensions.get('window').width * 0.88};
`;

export const CenteredTypography = styled(Typography)`
  text-align: center;
  display: 'inline-block';
  text-transform: capitalize;
`;

export const GreyBoxTypography = styled(Typography)`
  text-align: center;
  padding: ${Spacing.large}px;
  background-color: ${Color.grayLight};
`;

export const PaddedColumn = styled(Column)`
  padding-left: 15px;
`;

export const StyledWebView = styled.iframe`
  flex: 1;
  margin: ${Spacing.medium}px 0px;
  width: 100%;
`;

export const WebViewTitleFlexBox = styled(FlexBox)`
  margin-top: 30px;
  padding: ${Spacing.medium}px;
`;
export const FooterFlexBox = styled(FlexBoxRow)`
  justify-content: space-between;
  padding: ${Spacing.large}px ${Spacing.xLarge}px;
  padding-top: ${Spacing.medium}px;
`;

export const CenteredFlexBox = styled(FlexBox)`
  flex-direction: column;
  align-items: center;
`;

export const PaymentConfirmPadding = styled.div`
  margin: ${Spacing.large}px;
`;

export const PaymentConfirmMarginView = styled.div`
  margin: ${Spacing.large}px ${Spacing.large}px;
`;

export const ConfrmationGreyTypography = styled(Typography)`
  color: ${Color.gray};
  font-size: ${FontSize.small};
`;

export const PaymentSuccessButtonView = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${Spacing.medium}px;
`;

export const StyledPanelSwiper = styled(PanelSwiper)`
  padding: 0px;
  margin: 0px;
  width: 100%;
`;

// Deprecating use SpacedMuiBox
export const SpacedFlexBox = styled(FlexBoxRow)`
  padding-horizontal: 20;
  padding-vertical: ${Spacing.large}px;
  justify-content: space-between;
  align-items: center;
  background-color: ${Color.foreColor};
`;

export const SpacedMuiBox = styled(MuiBox)`
  padding-horizontal: 20;
  padding-vertical: ${Spacing.large}px;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${Color.foreColor};
`;

export const MinFlexBox = styled(FlexBox)`
  min-height: 200px;
`;

export const CustomStyledButton = styled(StyledButton)`
  width: 75%;
  margin-bottom: 55px;
`;

export const StyledText = styled(DefaultText)`
  color: ${props => (props.color ? props.color : Color.lightBlue)};
`;

export const GreyView = styled.div`
  opacity: '1';
  flex: 1;
  width: 100%;
  background-color: ${Color.baseColor};
`;

export const ShareButtonContainer = styled(FlexBox)`
  background-color: ${Color.foreColor};
  padding: ${Spacing.large}px ${Spacing.medium}px;
  align-items: flex-end;
  width: 100%;
`;

export const GrayBar = styled(MuiBox)`
  text-align: right;
  background: ${Color.grayLight};
`;

export const InlineBlock = styled.div`
  display: inline-block;
  vertical-align: middle;
  min-width: 350px;
`;

export const BannerInline = styled.div`
  width: 100%;
  display: block;
  text-align: center;
`;

export const BillContainer = styled.div`
  border: 1px solid ${Color.grayHue6};
  background: ${Color.white};
  margin: 2.5em;
`;

export const BillDetailLeftPanel = styled(MuiGrid)`
  padding-top: 0.5em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const BillDetailRightPanel = styled(FlexBoxColumn)`
  align-items: flex-end;
  text-align: right;
  flex: 1 1 auto;
`;

export const BillDetailRightPanelTop = styled.div`
  width: 280px;
  text-align: center;
`;

export const BottomDividerContainer = styled.div`
  height: ${FontSize.heading}px;
  border-bottom: dotted 1px ${Color.black};
`;

export const BottomDivider = styled(Typography)``;
export const LinkText = styled(Typography)`
  color: ${Color.primary};
`;
export const LinkTextInline = styled(Typography)`
  color: ${Color.primary};
  display: contents;
`;
export const RedText = styled(DefaultText)`
  color: ${Color.red};
  font-size: ${FontSize.small}px;
  fontweight: ${FontWeight.semibold};
`;
export const BigMoney = styled(Typography)`
  font-size: ${FontSize.title}px;
`;
export const CustomBuild = styled(TableCell)`
  text-align: center !important;
  width: 300px;
`;
export const CustomBuildEnd = styled(TableCell)`
  text-align: End !important;
  width: 300px;
`;
export const BigMoneyInline = styled(Typography)`
  font-size: 30px;
  display: inline;
`;
export const TextHolder = styled(MuiGrid)`
  padding: 1em 1.5em;
  font-size: 16px;
  color: ${Color.black};
`;
export const RenderPayData = styled(TextHolder)`
  flex-direction: row;
  text-align: end;
`;
export const DueAmount = styled(MuiTypography)`
  color: ${Color.red};
  font-size: ${FontSize.large}px;
  text-transform: uppercase;
`;

export const CenteredTypographyBill = styled(Typography)`
  text-align: center;
  max-width: ${FontSize.heading}rem;
  color: ${Color.black};
`;

export const TextBlue = styled(DefaultText)`
  color: ${Color.primary};
  display: inline;
  text-decoration: underline;
`;
export const TextBlueBlock = styled(DefaultText)`
  color: ${Color.primary};
  margin-bottom: 1em;
  display: block;
  text-decoration: underline;
`;
export const BillText = styled.div`
  width: 280px;
`;

export const MarginSection = styled.div<{ accessibilityLabel?: string }>`
  margin: ${Spacing.xLarge}px;
`;

interface ColoredLinkProps {
  to?: string;
  color?: string;
  backgroundColor?: string;
  hoverBackgroundColor?: string;
}

export const ColorButton = styled(props => <Link {...props} />)`
  display: inline-block;
  width: 100%;
  max-width: ${FontSize.heading}rem;
  font-size: ${FontSize.large}px;
  color: ${(props: ColoredLinkProps) =>
    props.color || dfdDefaultTheme.palette.getContrastText(blue[900])};
  background-color: ${(props: ColoredLinkProps) => props.backgroundColor || indigo[900]};
  border: 1px solid ${props => props.color || dfdDefaultTheme.palette.getContrastText(blue[900])};
  padding: ${Spacing.medium}px;
  text-align: center;
  border-radius: ${Spacing.medium}px;

  &:hover {
    background-color: ${(props: ColoredLinkProps) => props.hoverBackgroundColor || blue[200]};
  }
`;

export const CustomLink = styled(props => <Link {...props} />)`
  display: inline-block;
  text-decoration: none;
  color: ${Color.link};
  &:focus,
  &:active,
  &:visited,
  &:hover {
    text-decoration: none;
    color: ${Color.link};
  }
`;

export const BillingButton = styled(MuiButton)`
  width: 100%;
  max-width: ${FontSize.heading}rem;

  .MuiButton-label {
    font-size: ${FontSize.large}px;
  }
`;

export const ColorAnchor = styled.a<ColoredLinkProps>`
  display: inline-block;
  width: 100%;
  max-width: ${FontSize.heading}rem;
  font-size: ${FontSize.large}px;
  color: ${(props: ColoredLinkProps) =>
    props.color || dfdDefaultTheme.palette.getContrastText(blue[900])};
  background-color: ${(props: ColoredLinkProps) => props.backgroundColor || indigo[900]};
  border: 1px solid ${props => props.color || dfdDefaultTheme.palette.getContrastText(blue[900])};
  padding: ${Spacing.medium}px;
  text-align: center;
  border-radius: ${Spacing.medium}px;

  &:hover {
    background-color: ${(props: ColoredLinkProps) => props.hoverBackgroundColor || blue[200]};
  }
`;

export const StyledFlexBox = styled(FlexBox)`
  width: 100%;
  flex: 1 1 auto;
  background-color: ${props => props.backgroundColor || Color.foreColor};
`;

export const Title = styled(Heading)`
  margin-top: ${Spacing.xLarge};
  padding: 0 ${Spacing.xLarge}px;
  text-align: left;
  font-weight: ${FontWeight.bold};
`;

export const SubTitle = styled(Subhead)`
  color: ${Color.gray};
  margin-top: ${Spacing.large}px;
  padding: 0 ${Spacing.xLarge}px;
`;

export const BannerBackButton = styled.div<{ accessibilityLabel?: string }>`
  position: absolute;
  cursor: pointer;
  left: ${Spacing.xLarge}px;
  top: ${Spacing.large}px;
`;

export const BannerCloseButton = styled.div<{ accessibilityLabel?: string }>`
  position: absolute;
  cursor: pointer;
  right: ${Spacing.xLarge}px;
  top: ${Spacing.large}px;
`;
