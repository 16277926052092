import React, { ComponentType } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import { RootState } from 'store/types';
import * as bookingTypes from 'store/booking/types';
import * as findProviderTypes from 'store/findProvider/types';
import * as bookingActions from 'store/booking/actions';
import * as findProviderActions from 'store/findProvider/actions';
import * as bookingSelectors from 'store/booking/selectors';
import * as geoLocationSelectors from 'store/geolocation/selectors';
import * as findProviderSelectors from 'store/findProvider/selectors';
import * as searchSelectors from 'store/providerSearch/selectors';

import BookingSpecialtyProviderSelectDefault from './default';
import BookingSpecialtyProviderSelectNPV from './npv';
import Config from 'react-native-config';

export interface Props extends RouteComponentProps {
  appointmentDetails: bookingTypes.AppointmentDetails;
  providers: findProviderTypes.ProviderSummary[];
  consumerPastProviders: findProviderTypes.ProviderSummary[];
  providersLoading: boolean;
  setDoctor: typeof bookingActions.setDoctor;
  searchFilter: findProviderTypes.ProviderSearchFilterState;
  activeFilters: findProviderTypes.ProviderSearchFilters;
  providerSearchMore: typeof findProviderActions.schedulingProviderSearchMore;
  isHydrating: boolean;
  rawProviderTotal: number;
  rawProviderCount: number;
  termsAccepted: boolean;
  termsShown: boolean;
  setSort: typeof findProviderActions.setSortBy;
  providerSearch: typeof findProviderActions.schedulingProviderSearch;
  setFetchSlots: typeof bookingActions.setFetchSlots;
  getSortBy: findProviderTypes.OptionType;
  setActiveProviderSearchFilter: typeof findProviderActions.setActiveProviderSearchFilter;
  geolocationStatus: boolean;
  currentProviderId: string;
}

type BookingSpecialtyProviderProps = {
  skipNpv2FlowSelect: boolean;
};

export function BookingSpecialtyProviderSelect(props: Props) {
  const bookingSpecialtyProviderState = props.history.location
    ?.state as BookingSpecialtyProviderProps;
  const shouldSlopNpv2FlowSelect = bookingSpecialtyProviderState?.skipNpv2FlowSelect;
  const showNpvDesign = Config.SCHEDULING_NPV_PHASE_2 === 'enabled' && !shouldSlopNpv2FlowSelect;

  return (
    <>
      {showNpvDesign ? (
        <BookingSpecialtyProviderSelectNPV {...props} />
      ) : (
        <BookingSpecialtyProviderSelectDefault {...props} />
      )}
    </>
  );
}

const mapStateToProps = (state: RootState) => ({
  appointmentDetails: bookingSelectors.appointmentDetailsSelector(state),
  providers: findProviderSelectors.providerSearchResultSelector(state),
  consumerPastProviders: findProviderSelectors.selectedConsumerPastDoctorsSelector(state),
  providersLoading: findProviderSelectors.providerSearchFetchingSelector(state),
  searchFilter: findProviderSelectors.searchFilterSelector(state),
  activeFilters: findProviderSelectors.activeFiltersSelector(state),
  isHydrating: findProviderSelectors.providerSearchHydratingSelector(state),
  rawProviderTotal: findProviderSelectors.providerSearchRawTotal(state),
  rawProviderCount: findProviderSelectors.providerSearchCurrentTotal(state),
  termsAccepted: searchSelectors.searchTermsAndConditionsAccepted(state),
  getSortBy: findProviderSelectors.getSortBy(state),
  geolocationStatus: geoLocationSelectors.geolocationAcceptedSelector(state),
  currentProviderId: bookingSelectors.currentProviderIdSelector(state)
});

const mapDispatchToProps = {
  setDoctor: bookingActions.setDoctor,
  providerSearchMore: findProviderActions.schedulingProviderSearchMore,
  setSort: findProviderActions.setSortBy,
  providerSearch: findProviderActions.schedulingProviderSearch,
  setActiveProviderSearchFilter: findProviderActions.setActiveProviderSearchFilter,
  setFetchSlots: bookingActions.setFetchSlots
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BookingSpecialtyProviderSelect as ComponentType);
