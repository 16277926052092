import Grid from '@material-ui/core/Grid';
import CountryRegionData from 'country-region-data/data';
import { Form, Formik } from 'formik';
import difference from 'lodash/difference';
import has from 'lodash/has';
import uniq from 'lodash/uniq';
import moment from 'moment';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { USPhoneMaskRegex, getUSPhoneNumber } from 'modules/utils/PhoneUtils';
import analyticsService, { AmplitudeEventData, AnalyticsEvent } from 'services/AnalyticsService';
import {
  fields,
  genders,
  races,
  registrationStatuses,
  Step
} from 'store/pageActions/RegisterAccount/constants';
import {
  updateRegistrationData,
  updateRegistrationStep,
  getRegisterBrand,
  registerPersonalSelector,
  RegisterData,
  getRedirectIdx
} from 'store/register';
import { RootState } from 'store/types';
import * as Yup from 'yup';
import { CancelButton } from '../FormElements/CancelButton';
import CustomButton from '../FormElements/CustomButton';
import CustomDatepicker from '../FormElements/CustomDatepicker';
import CustomSelect from '../FormElements/CustomSelect';
import CustomTextField from '../FormElements/CustomTextField';
import Effect from '../FormElements/FormikEffect';
import RequiredFieldsLegend from 'components/RequiredFieldsLegend';
import { CustomDOBTooltip } from 'components/InfoPopper';
import { MuiBox, MuiTypography } from 'theme/material-ui';

interface Props {
  initialValues: {
    firstName: string;
    lastName: string;
    dateOfBirth: string;
    gender: string;
    ethnicity: string;
    streetAddress: string;
    unitNumber: string;
    city: string;
    state: string;
    zip: string;
    country: string;
    email: string;
    phone: string;
    registrationStatus: string;
  };
  updateRegister: (data: Partial<RegisterData>) => void;
  updateStep: (step: string) => void;
}

const PersonalInfoSchema = Yup.object().shape({
  firstName: Yup.string()
    .required('Required')
    .max(60, 'Must be less than 60 characters'),
  lastName: Yup.string()
    .required('Required')
    .max(60, 'Must be less than 60 characters'),
  dateOfBirth: Yup.date()
    .typeError('Invalid Date')
    .required('Required')
    .test('DOB', 'Must be at least 18 years old to register.', (date: string) => {
      return moment().diff(moment(date), 'years') >= 18;
    }),
  gender: Yup.string().required('Required'),
  ethnicity: Yup.string(),
  streetAddress: Yup.string().required('Required'),
  unitNumber: Yup.string(),
  city: Yup.string().required('Required'),
  state: Yup.string().required('Required'),
  zip: Yup.string().required('Required'),
  country: Yup.string().required('Required'),
  email: Yup.string()
    .trim()
    .email('Must be a valid email address')
    .required('Required'),
  phone: Yup.string()
    .matches(USPhoneMaskRegex, 'Phone must match format (XXX) XXX-XXXX')
    .required('Phone Number is required')
});

const RegisterPersonal = ({ initialValues, updateRegister, updateStep }: Props) => {
  const countries = CountryRegionData.map(c => ({
    value: c.countryShortCode,
    label: c.countryName,
    regions: c.regions
  }));
  const [regions, setRegions] = useState([]);

  const findCountryRegions = (countryShortCode: string) => {
    const country = countries.find(c => c.value === countryShortCode);
    return country
      ? country.regions.map(r => ({
          value: r.shortCode,
          label: r.name
        }))
      : [];
  };

  const updateRegions = (currentFormikState, nextFormikState) => {
    // !nextFormikState indicates that we are initializing our form
    if (!nextFormikState) {
      // default country to US if form is empty and has no value for country
      const countryShortCode =
        currentFormikState.values.country === '' ? 'US' : currentFormikState.values.country;
      const countryRegions = findCountryRegions(countryShortCode);

      currentFormikState.values.country = countryShortCode;
      setRegions(countryRegions);

      // reset regions and selected state every time country changes
    } else if (currentFormikState.values.country !== nextFormikState.values.country) {
      const countryRegions = findCountryRegions(currentFormikState.values.country);
      currentFormikState.values.state = '';
      setRegions(countryRegions);
    }
  };

  const [formErrors, setFormErrors] = useState<string[]>([]);

  const handleSubmit = (values: Partial<RegisterData>) => {
    values.registrationStatus = registrationStatuses.ACCOUNT_INFO;
    updateRegister(values);
    updateStep(Step.PERSONAL_REVIEW);
  };

  const sendEvent = () => {
    const redirect_index = getRedirectIdx();
    const redirect_brand = getRegisterBrand();
    const eventData: AmplitudeEventData = {
      redirect_index,
      redirect_brand,
      registration_field_errors: formErrors
    };
    analyticsService.logEvent(AnalyticsEvent.AccountCreationFormError, eventData);
  };

  const getFormErrors = (errors: object, touched: object) => {
    const fieldErrors = fields.filter(
      (field: string) => has(errors, field) && touched[field] === true
    );
    const fieldDiff = difference(fieldErrors, formErrors);
    if (fieldDiff.length > 0) {
      const newErrors = [...formErrors, ...fieldErrors];
      setFormErrors(uniq(newErrors));
    }
  };
  if (initialValues?.phone) {
    initialValues.phone = getUSPhoneNumber(initialValues.phone) || '';
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={PersonalInfoSchema}
      onSubmit={handleSubmit}
    >
      {({ errors, touched, isValid, dirty }) => {
        const disabled = !isValid || (isValid && !dirty);
        getFormErrors(errors, touched);
        return (
          <Form>
            <Effect onChange={updateRegions} />
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <MuiTypography variant="h5">Personal information</MuiTypography>
                <RequiredFieldsLegend />
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  data-testid="first-name-input"
                  name="firstName"
                  label="Legal First Name"
                  required
                  placeholder="Enter First Name"
                />
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  data-testid="last-name-input"
                  name="lastName"
                  label="Legal Last Name"
                  required
                  placeholder="Enter Last Name"
                />
              </Grid>
              <Grid item xs={12}>
                <CustomDatepicker
                  data-testid="date-of-birth-input"
                  name="dateOfBirth"
                  label="Date of Birth (Must be over 18 years)"
                  required
                  tooltip={<CustomDOBTooltip />}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomSelect
                  data-testid="gender-option"
                  name="gender"
                  label="Sex at Birth"
                  options={genders}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomSelect
                  data-testid="ethnicity-option"
                  name="ethnicity"
                  label="Ethnicity (Optional)"
                  options={races}
                />
              </Grid>
              <Grid item xs={12}>
                <MuiBox mb={2}>
                  <CustomTextField
                    data-testid="street-address-input"
                    name="streetAddress"
                    label="Home Address"
                    required
                    placeholder="Street Address"
                  />
                </MuiBox>
                <MuiBox mb={2}>
                  <CustomTextField
                    data-testid="unit-number-input"
                    name="unitNumber"
                    placeholder="Apt, suite, etc. (Optional)"
                  />
                </MuiBox>
              </Grid>
              <Grid item xs={12} sm={8}>
                <CustomTextField
                  data-testid="city-input"
                  name="city"
                  label="City"
                  required
                  placeholder="Enter city name"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <CustomSelect
                  data-testid="state-option"
                  name="state"
                  label="State"
                  required
                  options={regions}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomTextField
                  data-testid="zip-code-input"
                  name="zip"
                  mask="zip"
                  label="Zip / Postal Code"
                  required
                  placeholder="00000"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomSelect
                  data-testid="country-option"
                  name="country"
                  label="Country"
                  required
                  options={countries}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  data-testid="email-input"
                  name="email"
                  label="Email Address"
                  required
                  placeholder="example@email.com"
                />
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  data-testid="phone-input"
                  name="phone"
                  mask="phone"
                  label="Mobile Phone Number"
                  required
                  placeholder="(000) 000-0000"
                />
              </Grid>
            </Grid>
            <MuiBox my={3} textAlign="center">
              <CustomButton
                type="submit"
                label="Next"
                disabled={disabled}
                isSubmitting={false}
                aria-label="submit-button"
                onClick={() => sendEvent()}
              />
              <CancelButton />
            </MuiBox>
          </Form>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = (state: RootState) => ({
  initialValues: registerPersonalSelector(state)
});

const mapDispatchToProps = {
  updateRegister: updateRegistrationData,
  updateStep: updateRegistrationStep
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterPersonal);
