import React from 'react';
import styled, { css } from 'styled-components';

import { borderRadius } from 'modules/styles/variables';
import Panel from './Panel';

interface Props {
  onPress: () => void;
  children: React.ReactNode;
  stretchHeight?: boolean;
  testID: string;
}

export const StyledPanel = styled(Panel)`
  ${props => props.style};
`;

const withStretchStyles = (props: { stretchHeight?: boolean }) => {
  return (
    props.stretchHeight &&
    css`
      flex-direction: row;
      align-items: stretch;
    `
  );
};

export const StyledTouchable = styled.div`
  border-radius: ${borderRadius};
  margin-top: 5px;
  margin-bottom: 5px;
  width: 100%;
  ${withStretchStyles}
`;

const TouchablePanel = ({ testID, onPress, children, ...rest }: Props) => (
  <StyledTouchable {...rest} onClick={onPress} data-testid={testID}>
    <StyledPanel {...rest}>{children}</StyledPanel>
  </StyledTouchable>
);

TouchablePanel.defaultProps = {
  children: null,
  style: {}
};

export default TouchablePanel;
