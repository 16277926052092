import React, { useEffect, useState } from 'react';

import Header from 'components/UI/Header/Header';
import Footer from 'components/UI/Footer/Footer';
import Sidebar from 'components/Sidebar';
import { useWindowSize } from 'hooks/useWindowSize';
import { dfdDefaultTheme, MuiBox } from 'theme/material-ui';
import { ContainerMuiBox, MainContent, StyledMuiBox } from './styled';

interface Props {
  children: React.ReactNode;
  isGuest?: boolean;
  hidden?: boolean;
}

const AuthLayout = ({ children, isGuest, hidden }: Props) => {
  const [collapsed, setCollapse] = useState(false);
  const smBreakpoint = dfdDefaultTheme.breakpoints.values.md + 1;
  const lgBreakpoint = dfdDefaultTheme.breakpoints.values.lg - 1;
  const { width } = useWindowSize();

  useEffect(() => {
    if (window.sessionStorage.getItem('collapsed') === 'true') setCollapse(true);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (width < smBreakpoint || width > lgBreakpoint) {
        setCollapse(false);
        window.sessionStorage.setItem('collapsed', collapsed.toString());
      }
    }, 1);
  }, [width]);

  const handleDrawerToggle = () => {
    setCollapse(!collapsed);
  };

  const handleSidebarCollapse = () => {
    const isCollapsed = !collapsed;
    window.sessionStorage.setItem('collapsed', isCollapsed.toString());
    setCollapse(!collapsed);
  };

  return (
    <MuiBox
      data-testid="authLayout"
      id="mainContent"
      display="flex"
      flexDirection="row"
      width="100%"
      height="100%"
    >
      <Header
        collapsed={collapsed}
        hidden={hidden}
        isGuest={isGuest || false}
        onClick={handleDrawerToggle}
        setCollapse={handleSidebarCollapse}
      />
      <Sidebar
        collapsed={collapsed}
        isGuest={isGuest || false}
        open={collapsed}
        onClick={handleDrawerToggle}
        onClose={handleDrawerToggle}
        setCollapse={handleSidebarCollapse}
      />
      <ContainerMuiBox $hiddenHeader={hidden}>
        <StyledMuiBox>
          <MainContent>{children}</MainContent>
          <Footer />
        </StyledMuiBox>
      </ContainerMuiBox>
    </MuiBox>
  );
};

export default AuthLayout;
