import Dialog from '@material-ui/core/Dialog';
import { Alert } from 'components/Alert';
import CloseButton from 'components/UI/Button/CloseButton';
import Box from 'components/UI/Layout/Box';
import FlexBox from 'components/UI/Layout/FlexBox';
import Spinner from 'components/UI/Spinner/Spinner';
import { Color } from 'modules/styles/colors';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Nullable } from 'modules/types/common';
import * as selectors from 'store/notifications/selectors';
import * as types from 'store/notifications/types';
import { cernerIdSelector, consumerIdSelector } from 'store/profile/selectors';
import { RootState } from 'store/types';
import styled from 'styled-components';
import { MuiContainer } from 'theme/material-ui';
import { AUTHORIZING_HIPAA_DISCLAIMER, REVOKING_HIPAA_DISCLAIMER } from './constants';
import { formatDisplayName, getFormattedAgreement } from './helpers';
import { SpinnerContainer } from 'components/UI/Spinner/SpinnerContainer';
import { Disclaimer, LegalScrollWrapper, ProxyName, StyledHeader } from './styled';
import StyledButton from 'components/UI/Button/StyledButton';
// TODO: Update agreementObject interface;

interface HIPAAAgreementProps {
  agreement: string;
  agreementObject: Nullable<types.NotificationsHipaaAgreementObjectState>;
  cernerID: string;
  error: Nullable<Error>;
  open: boolean;
  patientID: string;
  proxies: { [key: string]: types.NotificationProxy };
  isFetching: boolean;
  isSigning: boolean;
  handleClose: () => void;
  handleSigning: () => Promise<void>;
}

const StyledName = styled.div`
  box-sizing: border-box;
  width: 363px;
  padding: 19.5px 0px 19.5px 20px;
  margin: 20px 0px;
  border: 1px solid ${Color.baseColor};
  border-radius: 3px;
  background-color: ${Color.white};
`;

function HIPAAAgreement({
  agreement,
  agreementObject,
  cernerID,
  error,
  open,
  patientID,
  proxies,
  isFetching,
  isSigning,
  handleClose,
  handleSigning
}: HIPAAAgreementProps) {
  const [agreementWithStyles, setAgreementWithStyles] = useState('');
  const [formattedDisplayName, setFormattedDisplayName] = useState('');

  const isRevoking = proxies[patientID] && proxies[patientID].authorized;

  useEffect(() => {
    if (agreementObject) {
      const { relationshipToPatient, proxyName, patientName } = agreementObject;
      const displayName = relationshipToPatient !== 'Self' ? proxyName : patientName;
      setFormattedDisplayName(formatDisplayName(displayName));
    }
  });

  // Inject styles into agreement
  useEffect(() => {
    setAgreementWithStyles(getFormattedAgreement(agreement));
  }, [agreement]);

  useEffect(() => {
    if (error) {
      const msg = `Unable to ${isRevoking ? 'Revoke' : 'Authorize'}`;
      Alert.alert(msg, 'Please try again or contact support.');
    }
  }, [error]);

  return (
    <Dialog fullScreen open={open} onClose={handleClose}>
      <MuiContainer maxWidth="md">
        <FlexBox spacing={18} flex={1} flexDirection="column" backgroundColor={Color.white}>
          <FlexBox alignItems="flex-end">
            <CloseButton onPress={handleClose} />
          </FlexBox>
          <>
            <StyledHeader>HIPAA {isRevoking ? 'Revocation' : 'Authorization'}</StyledHeader>
            <Box vOffsetTop={10}>
              <Disclaimer>
                {isRevoking ? REVOKING_HIPAA_DISCLAIMER : AUTHORIZING_HIPAA_DISCLAIMER}
              </Disclaimer>
            </Box>
          </>
          <>
            <StyledName>
              <ProxyName>
                {patientID === cernerID ? `${formattedDisplayName} (You)` : formattedDisplayName}
              </ProxyName>
            </StyledName>
            <LegalScrollWrapper>
              {(isFetching || isSigning) && (
                <SpinnerContainer>
                  <Box vSpacingBottom={50}>
                    <Spinner />
                  </Box>
                </SpinnerContainer>
              )}
              <div
                dangerouslySetInnerHTML={{ __html: agreementWithStyles }} /*eslint-disable-line*/
              />
            </LegalScrollWrapper>
          </>
          <Box vSpacingTop={15} vSpacingBottom={20} style={{ margin: '0 auto' }}>
            <Box vOffsetBottom={15}>
              <Disclaimer>You must read all terms before continuing</Disclaimer>
            </Box>
            <StyledButton
              disabled={isFetching || isSigning || !agreementWithStyles}
              onClick={() => {
                handleSigning().then(() => handleClose());
              }}
            >
              {isRevoking ? 'Revoke' : 'Confirm and Sign'}
            </StyledButton>
          </Box>
        </FlexBox>
      </MuiContainer>
    </Dialog>
  );
}

const mapStateToProps = (state: RootState) => ({
  agreement: selectors.notificationsHipaaAgreementSelector(state),
  agreementObject: selectors.notificationsHipaaAgreementObjectSelector(state),
  cernerID: cernerIdSelector(state),
  consumerID: consumerIdSelector(state),
  error: selectors.notificationsHipaaErrorSelector(state),
  proxies: selectors.notificationsProxiesDataSelector(state),
  patientID: selectors.notificationsHipaaPatientIdSelector(state),
  isFetching:
    selectors.notificationsHipaaFetchingSelector(state) ||
    selectors.notificationsProxiesFetchingSelector(state),
  isSigning:
    selectors.notificationsHipaaSigningSelector(state) ||
    selectors.notificationsProxiesFetchingSelector(state)
});

export default connect(mapStateToProps, {})(HIPAAAgreement);
