import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { MuiBox, MuiContainer, MuiTypography } from 'theme/material-ui';
import { FontSize, FontWeight, Spacing } from 'modules/styles/variables';
import { Confirm } from 'components/ConfirmDialog/ConfirmDialog';
import EVisitBanner from './components/EVisitBanner';
import EVisitAccordion from './components/EVisitAccordion';
import { EVISITS_ROUTES } from '../constants';
import useNavigationAnalytics from 'hooks/useNavigationAnalytics';
import { eVisitFrontDoorConditionsScreen, treatedConditionsFrontDoor } from 'lib/evisit/constants';
import { buildPatientDTO } from 'lib/triage/utils';
import { resetEVisitSessionToken, startEVisitFrontDoorSession } from 'store/eVisit/actions';
import { eVisitSessionErrorSelector, eVisitSessionFetchingSelector } from 'store/eVisit/selectors';
import { profileConsumerSelector } from 'store/profile/selectors';
import analyticsService, { AMPLITUDE_EXPERIMENTS, AnalyticsEvent } from 'services/AnalyticsService';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { getStateAndCondition } from './utils';

const EVisitConditionsWeTreat = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [showEvisitInsurance, setShowEvisitInsurance] = useState(true);
  const [eVisitInfo, setEVisitInfo] = useLocalStorage('EvisitCache', '');
  const { localStateSelected } = getStateAndCondition(
    eVisitInfo,
    history?.location?.state as { localStateSelected: string }
  );

  const patientInfo = useSelector(profileConsumerSelector);
  const isFetching = useSelector(eVisitSessionFetchingSelector);
  const error = useSelector(eVisitSessionErrorSelector);
  const { sectionTitle } = eVisitFrontDoorConditionsScreen;
  const eVisitFrontDoorSymptomSelected = useNavigationAnalytics(
    AnalyticsEvent.EVisitFrontDoorSymptomSelected
  );

  useEffect(() => {
    const eVisitInsuranceFlag = analyticsService.fetchExperimentVariant(
      AMPLITUDE_EXPERIMENTS.E_VISIT_FRONT_DOOR_INSURANCE.flagKey
    );
    const evisitInsuranceFlagIsActive =
      eVisitInsuranceFlag?.value === AMPLITUDE_EXPERIMENTS.E_VISIT_FRONT_DOOR_INSURANCE.variantA;

    if (!localStateSelected) {
      history.goBack();
    }

    setShowEvisitInsurance(evisitInsuranceFlagIsActive);
  }, [localStateSelected]);

  useEffect(() => {
    if (error) {
      Confirm.show(
        'Unable to start E-Visit at this time',
        'Please try again or contact support.',
        '',
        'error',
        {
          text: 'Ok',
          onClick: () => {
            dispatch(resetEVisitSessionToken());
          }
        }
      );
    }
  }, [error]);

  const analyticsOnSelect = (condition: string) => eVisitFrontDoorSymptomSelected({ condition });

  const onClickNext = (conditionCode: string, condition: string) => {
    if (localStateSelected) {
      const patientDTO = buildPatientDTO(patientInfo, conditionCode) ?? {
        patientSuspectedConditions: [conditionCode]
      };
      const obj = { ...patientDTO, localStateSelected };
      setEVisitInfo(JSON.stringify(obj));
      if (showEvisitInsurance) {
        history.push({
          pathname: EVISITS_ROUTES.FRONTDOOR_INSURANCE,
          state: { conditionCode, localStateSelected }
        });
      } else {
        dispatch(startEVisitFrontDoorSession(patientDTO, localStateSelected));
      }
      analyticsOnSelect(condition);
    }
  };

  return (
    <>
      <EVisitBanner title={eVisitFrontDoorConditionsScreen.screenTitle} />
      <MuiContainer data-testid="e-visit-conditions">
        <MuiBox marginTop={Spacing.medium} marginBottom={Spacing.small}>
          <MuiTypography fontSize={FontSize.mediumHeading} fontWeight={FontWeight.semibold}>
            {sectionTitle.headerText}
          </MuiTypography>
          <MuiTypography style={{ marginTop: Spacing.mediumLarge }}>
            {sectionTitle.descriptionText}
          </MuiTypography>
        </MuiBox>
        <MuiBox data-testid="condition-definitions-content" mb={Spacing.medium}>
          {treatedConditionsFrontDoor.map(({ condition, definition, termCode }) => (
            <EVisitAccordion
              key={condition}
              isLoading={isFetching}
              title={condition}
              paragraphs={definition}
              termCode={termCode}
              onClick={onClickNext}
            />
          ))}
        </MuiBox>
      </MuiContainer>
    </>
  );
};

export default EVisitConditionsWeTreat;
