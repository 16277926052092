export const EVISITS_ROUTES = {
  OVERVIEW: '/u/e-visits/overview',
  STATE_SELECTOR: '/u/e-visits/state-selector',
  PHARMACY_SELECTOR: '/u/e-visits/pharmacy-selector',
  REQUEST_SUCCESS: '/u/e-visits/request-success',
  FRONTDOOR_OVERVIEW: '/u/e-visits/front-door-overview',
  FRONTDOOR_TERMS_AND_AGREEMENTS: '/u/e-visits/front-door-terms-and-agreements',
  FRONTDOOR_CONDITIONS_WE_TREAT: '/u/e-visits/front-door-conditions-we-treat',
  FRONTDOOR_INSURANCE: '/u/e-visits/front-door-insurance',
  FRONTDOOR_INSURANCE_UPLOAD: '/u/e-visits/front-door-insurance-upload',
  FRONTDOOR_INSURANCE_PROCESSED: '/u/e-visits/front-door-insurance-processed',
  FRONTDOOR_INSURANCE_SUBMIT: '/u/e-visits/front-door-insurance-submit'
};
