import React from 'react';

import Box from '@material-ui/core/Box';
import ErrorIcon from '@material-ui/icons/Error';
import Typography from '@material-ui/core/Typography';

interface Props {
  error?: string | boolean;
}

const FieldError = ({ error }: Props) => {
  if (error) {
    return (
      <Box display="flex" flexDirection="row" alignItems="center" mt="6px">
        <Box alignSelf="flex-start" mr="4px">
          <ErrorIcon color="error" fontSize="small" />
        </Box>
        <Box pb="4px">
          <Typography color="error" variant="body2">
            {error}
          </Typography>
        </Box>
      </Box>
    );
  }

  return null;
};

export default FieldError;
