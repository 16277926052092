const year = new Date().getFullYear();

/**
 * Function: getSelectionId
 * @desc grabs persistentId off of amwell object Entity
 * @param selection amwell item selection that extends an Entity
 */
export function getSelectionId<T extends { [key: string]: any }>(
  selection: T | null,
  id: keyof T = 'id'
) {
  return selection ? selection[id]?.persistentId : '';
}

/**
 * Function: findSelectionById
 * @desc finds an awmell item from an array of matching items
 * @param items amwell array of items that have Entity in their type
 * @param selection amwell item selection that extends the shape of an item
 * @param fallback [null] whatever value to fallback to if item not found
 */
export function findSelectionById<T extends { [key: string]: any }>(
  items: T[],
  selection: T | null,
  fallback: any = null
) {
  const selectionId = getSelectionId(selection);

  if (!selection || !selectionId) return fallback;
  if (!Array.isArray(items)) return fallback;

  const result = items.find(item => {
    const itemId = getSelectionId(item);
    return itemId === selectionId;
  });

  return result || fallback;
}

/**
 * Function: clone
 * @desc clones amwell object
 * @param obj amwell object to clone
 */
export function clone(obj: any) {
  if (typeof obj !== 'object') return {};
  return Object.assign(Object.create(Object.getPrototypeOf(obj)), obj);
}

/**
 * Function: awData
 * @desc grabs object data in __data
 * @param obj amwell object
 */
export function awData(obj: any) {
  // eslint-disable-next-line no-underscore-dangle
  return (obj && obj.__data) || obj;
}

/**
 * Function: prepend
 * @desc prepends 0 for positive integers
 * @param num
 */
export function prepend(num: number) {
  return `0${num}`.slice(-2);
}

/**
 * Function: createYears
 * @desc creates array of years by count in the future || past
 * @param count
 * @param dir 'future' | 'past'
 */
export function createYears(count: number, dir: 'future' | 'past' = 'future') {
  const years = [];
  if (dir === 'future') {
    for (let i = year; i < year + count; i += 1) {
      years.push(i);
    }
  }
  if (dir === 'past') {
    for (let i = year - count; i < year; i += 1) {
      years.push(i);
    }
  }
  return years;
}

/**
 * Function: isString
 * @desc checks to see if obj is string
 * @param obj
 */
export const isString = (obj: any) => typeof obj === 'string';

// ▼ RegEx & Credit Card Checks ▼ //

// No numbers || special characters
export const regExCity = /^[a-zA-Z\-.' ]*$/;

// No special characters
export const regExAddress = /^[a-zA-Z0-9\- .,'@#()]*$/;

// Numbers && string characters
export const regExNumAndString = /^[a-zA-Z0-9-]*$/;

/**
 * Function: cleanNumber
 * @desc cleans string to only be numbers
 * @param str
 */
export const cleanNumber = (str: any) => isString(str) && str.replace(/\D/g, '');

/**
 * Function: isAmex
 * @desc starting with (34 || 37) && 15 digits
 * @param str
 */
export const regExAmex = /^(?:3[47][0-9]{13})$/;
export const isAmex = (str: string) => isString(str) && str.match(regExAmex);

/**
 * Function: isVisa
 * @desc starting with 4 && (13 || 16) digits
 * @param str
 */
export const regExVisa = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;
export const isVisa = (str: string) => isString(str) && str.match(regExVisa);

/**
 * Function: isMastercard
 * @desc starting with 51-55 && 16 digits
 * @param str
 */
export const regExMasterCard = /^(?:5[1-5][0-9]{14})$/;
export const isMasterCard = (str: string) => isString(str) && str.match(regExMasterCard);

/**
 * Function: isDiscover
 * @desc starting with 6011 && 16 digits || starting with 5 && 15 digits
 * @param str
 */
export const regExDiscover = /^(?:6(?:011|5[0-9][0-9])[0-9]{12})$/;
export const isDiscover = (str: string) => isString(str) && str.match(regExDiscover);

// ▼ Formik Tests ▼ //

export function minNumber(path: string | number, msg: string) {
  return {
    name: 'minNumber',
    exclusive: false,
    message: msg,
    test: (value: string) => {
      const prev = (cleanNumber(value) || 0).length;
      const ref = path || 0;
      return prev >= ref;
    }
  };
}

export function maxNumber(path: string | number, msg: string) {
  return {
    name: 'maxNumber',
    exclusive: false,
    message: msg,
    test: (value: string) => {
      const prev = (cleanNumber(value) || 0).length;
      const ref = path || 0;
      return prev <= ref;
    }
  };
}

export function numberLength(
  path: string | number,
  msg: string,
  opts?: { skip: (value: string) => boolean }
) {
  return {
    name: 'numberLength',
    exclusive: false,
    message: msg,
    test: (value: string) => {
      if (opts?.skip(value)) return true;
      const prev = (cleanNumber(value) || 0).length;
      const ref = path || 0;
      return prev === ref;
    }
  };
}
