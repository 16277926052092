import { SearchBar } from 'components/AutoComplete/SearchBarV2';
import DataLoader from 'components/UI/DataLoader/DataLoader';
import { useSearch } from 'hooks/useSearch';
import debounce from 'lodash/debounce';
import { ZIndex } from 'modules/styles/variables';
import React, { useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getVitalsData } from 'store/vitals/actions';
import { getClinicNotesList } from 'store/clinicNotes/actions';
import { globalSearchItems } from 'store/global/selectors';
import { getMedications } from 'store/medications/actions';
import { fetchProcedures } from 'store/medicalHistory/procedures/actions';
import { getAllergies } from 'store/medicalHistory/allergies/actions';
import { fetchSocialHistory } from 'store/medicalHistory/socialHistory/actions';
import { fetchImmunizations } from 'store/medicalHistory/immunizations/actions';
import { fetchProblems } from 'store/medicalHistory/problems/actions';
import styled from 'styled-components';
import { MuiBox, MuiList, MuiPaper } from 'theme/material-ui';
import FlexBox from 'components/UI/Layout/FlexBox';
import { getMyDoctors } from 'store/myDoctors/actions';
import * as bookingActions from 'store/booking/actions';
import SearchItem from './SearchItem';
import { getVisitSummaryFullList } from 'store/visitSummary/actions.web';
import { getTestResultsList } from 'store/testResults/actions';
import { GlobalSearchItem, SearchResultItem } from 'store/global/types';
import useNavigationAnalytics from 'hooks/useNavigationAnalytics';
import { useProxySwitcherEffect } from 'hooks/useProxySwitcherEffect';
import { AnalyticsEvent } from 'services/AnalyticsService';
import { ExpandedView as translations } from 'lib/constants/translations/components/index';
import { useLanguageSwitcher } from 'lib/hooks/useLanguageSwitcher';

export const SearchResultsBox = styled(MuiPaper)`
  position: absolute;
  margin-top: 60px;
  z-index: ${ZIndex.high};
  width: 200%;
  max-width: 300px;
  max-height: 500px;
  overflow-y: scroll;
`;

export const BgCurtain = styled(MuiBox)`
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: ${ZIndex.medium};
`;

// Quick Search
export const GlobalSearch = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const searchList = useSelector(globalSearchItems) as SearchResultItem[];
  const searchOptions = {
    minMatchCharLength: 3,
    findAllMatches: false,
    includeScore: true,
    threshold: 0.27,
    ignoreLocation: true,
    keys: ['title', 'provider', 'location', 'date', 'keywords']
  };
  const { result, search, term } = useSearch<SearchResultItem>(searchList, searchOptions);
  const inputRef = useRef<HTMLInputElement>(null);
  const DEBOUNCE_TIME = 400;
  let blurTimeout: any;

  const QuickSearchResultsClicked = useNavigationAnalytics(
    AnalyticsEvent.QuickSearchResultsClicked
  );
  const QuickSearchAbandoned = useNavigationAnalytics(AnalyticsEvent.QuickSearchAbandoned);

  const handleTextChange = (value: string) => {
    search(value);
  };

  const debouncedTextChange = useMemo(() => debounce(handleTextChange, DEBOUNCE_TIME), []);
  const screenText = useLanguageSwitcher(translations);

  const handleOnPress = (item: GlobalSearchItem) => {
    if (item?.navWebPath) {
      history.push(item?.navWebPath, {
        ...item?.navParams
      });
      QuickSearchResultsClicked({
        number_results_returned: result.length || 0,
        result_type: item.category,
        search_term: term?.toLowerCase()
      });
      if (blurTimeout) {
        clearTimeout(blurTimeout);
        handleOnClear();
      }
    }
  };

  const handleOnClear = () => {
    if (inputRef.current !== null) {
      inputRef.current.value = '';
      search('');
    }
  };

  const handleBlur = () => {
    blurTimeout = setTimeout(() => {
      handleOnClear();
      if (inputRef.current !== null) {
        QuickSearchAbandoned({
          number_results_returned: result.length || 0,
          search_term: term?.toLowerCase()
        });
      }
    }, DEBOUNCE_TIME);
  };

  useProxySwitcherEffect(() => {
    dispatch(getClinicNotesList());
    dispatch(getMedications());
    dispatch(getMyDoctors());
    dispatch(bookingActions.getUpcomingAppointments());
    dispatch(fetchProcedures());
    dispatch(getAllergies());
    dispatch(fetchSocialHistory());
    dispatch(fetchImmunizations());
    dispatch(fetchProblems());
    dispatch(getVitalsData());
    dispatch(getVisitSummaryFullList());
    dispatch(getTestResultsList());
  }, []);

  return (
    <FlexBox flexDirection="row" data-testid="quick-search">
      <FlexBox alignItems="end" justifyContent="center" width="100%">
        <SearchBar
          inputRef={inputRef}
          fullWidth
          placeholder={screenText.RecordSearch}
          onClear={handleOnClear}
          onChange={e => debouncedTextChange(e.target.value)}
          onBlur={handleBlur}
        />
      </FlexBox>
      <SearchResultsBox elevation={5} data-testid="search-result-box">
        {!!result.length && term ? (
          <DataLoader
            data={result}
            renderData={() => (
              <MuiList disablePadding data-testid="search-option-list">
                {result.map((item: SearchResultItem) => (
                  <SearchItem item={item?.item} searchValue={term} onPress={handleOnPress} />
                ))}
              </MuiList>
            )}
          />
        ) : null}
      </SearchResultsBox>
    </FlexBox>
  );
};
