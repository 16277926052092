import styled from 'styled-components';
import { MuiBox } from 'theme/material-ui';
import ListItemSection from './ListItemSection';
import { Color } from 'modules/styles/colors';

export const ItemHeader = styled(MuiBox)`
  display: flex;
  align-content: center;
  justify-content: space-between;
  align-items: center;

  min-height: 80px;
  margin-bottom: 5px;
`;

export const LocationItem = styled(ListItemSection)`
  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const PatientItem = styled(MuiBox)`
  border-top: 0.5px solid ${Color.grayLight2};
  padding-top: 8px;
  overflow: hidden;

  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
