import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import Spacer from 'components/UI/Layout/Spacer';
import { Color } from 'modules/styles/colors';
import TermsAndConditions from 'screens/TermsAndConditions/TermsAndConditions';
import TriageHeader from 'screens/TriageGyant/TriageGyantHeader';
import { TRIAGE_ROUTES } from 'screens/TriageGyant/constants';
import { otherActiveAccountsSelector } from 'store/account/selectors';
import { setAcceptTemporaryTC } from 'store/global/actions';
import { TermsAndConditionsType } from 'store/global/reducers';
import { MuiButton, MuiContainer, MuiBox, MuiTypography } from 'theme/material-ui';
import { Spacing } from 'modules/styles/variables.web';
import { isMobileBrowser } from 'lib/browser';
import usePreviouslyAcceptedLatestTerms from 'lib/hooks/usePreviouslyAcceptedLatestTerms';
import { isDfdAuthenticatedSelector } from 'store/authentication/selectors';
import { CompanyName } from 'modules/utils/ConfigUtils';

const GyantTriageTerms = () => {
  const [termsOpen, setTermsOpen] = React.useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const proxyAccounts = useSelector(otherActiveAccountsSelector);
  const isAuthenticated = useSelector(isDfdAuthenticatedSelector);
  const previouslyAcceptedTheLatestTerms = usePreviouslyAcceptedLatestTerms();

  const handleNext = () => {
    dispatch(setAcceptTemporaryTC('TRIAGE_DISCLAIMER'));
    if (!!proxyAccounts.length) {
      history.replace(TRIAGE_ROUTES.PROXY_SELECT);
    } else {
      history.replace(TRIAGE_ROUTES.SYMPTOM_CHECKER);
    }
  };

  useEffect(() => {
    if (previouslyAcceptedTheLatestTerms) {
      handleNext();
    }
  }, []);

  const handleDecline = () => {
    if (isAuthenticated) {
      history.push('/u/get-care-now');
    } else {
      history.push('/guest-home');
    }
  };

  const StyledMuiTypography = styled(MuiTypography)`
    cursor: pointer;
  `;

  return (
    <MuiBox display="flex" flexDirection="column" height="100%">
      <TermsAndConditions
        onClick={() => setTermsOpen(false)}
        open={termsOpen}
        type={TermsAndConditionsType.GENERAL}
      />
      <TriageHeader />
      <MuiContainer maxWidth="sm">
        <MuiBox mt={isMobileBrowser() ? Spacing.medium : Spacing.large} px={2}>
          <MuiTypography>
            By proceeding, you acknowledge that this is not a diagnosis or a substitute for
            professional medical advice. You expressly agree that your use of and reliance on this
            Service is at your sole risk and that {CompanyName}, SelectHealth, and their licensors
            are not responsible for any decision made or action taken in reliance on this Service.
          </MuiTypography>
          <Spacer size="small" />
          <MuiTypography>
            This Service is purely informational and will not be included in your electronic medical
            record. {CompanyName} and SelectHealth do not monitor the results.
          </MuiTypography>
          <Spacer size="small" />
          <MuiBox flexDirection="row" display="flex">
            <MuiTypography>Complete&nbsp;</MuiTypography>
            <StyledMuiTypography
              data-testid="handle-terms"
              color={Color.link}
              onClick={() => setTermsOpen(true)}
            >
              Terms of Use
            </StyledMuiTypography>
            <MuiTypography>&nbsp;can be found here.</MuiTypography>
          </MuiBox>
          <Spacer size="medium" />
          <MuiButton
            data-testid="handle-accept"
            variant="contained"
            color="primary"
            onClick={handleNext}
            fullWidth
          >
            Accept
          </MuiButton>
          <Spacer size="medium" />
          <MuiButton
            data-testid="handle-decline"
            variant="outlined"
            color="primary"
            onClick={handleDecline}
            fullWidth
          >
            Decline
          </MuiButton>
        </MuiBox>
      </MuiContainer>
    </MuiBox>
  );
};

export default GyantTriageTerms;
