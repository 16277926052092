import { Alert } from 'components/Alert';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import Logger, { LoggingModule } from 'services/Logger';
import analyticsService, { AmplitudeEventData, AnalyticsEvent } from 'services/AnalyticsService';
import { oc } from 'ts-optchain';

import { ProfileUpdateMessages } from 'store/profile/constants';
import {
  getProfile,
  resetValidationState,
  updateConsumerProfile,
  validatePinOTP,
  sendPinOTP
} from 'store/profile/actions';
import * as profileSelectors from 'store/profile/selectors';
import { Consumer, SendPinProps } from 'store/profile/types';
import { RootState } from 'store/types';

import { getUpdatedPhoneConsumer } from './utils';
import { UPDATE_DISCLAIMER } from './variables';
import EditPhoneComponent from 'components/EditPhone';

export interface EditPhoneScreenProps extends RouteComponentProps {
  consumer: Consumer;
  fetchProfile: () => void;
  resetValidation: () => void;
  validated: boolean;
  sendError: boolean;
  sendPinToPhone: (data: SendPinProps) => Promise<any>;
  validateSMSPin: (phone: string, pin: string) => Promise<any>;
  updatePhone: (update: Consumer) => Promise<any>;
}

export function EditPhoneScreen({
  consumer,
  fetchProfile,
  updatePhone,
  resetValidation,
  sendPinToPhone,
  validateSMSPin,
  validated,
  sendError,
  history
}: EditPhoneScreenProps) {
  const [isLoading, setIsLoading] = useState(false);

  const initialPhone = oc(consumer).phones[0].value('');

  const data: AmplitudeEventData = {
    currentUrl: 'Edit Phone',
    referringUrl: 'Profile',
    type: 'phone'
  };

  const handleCancel = () => {
    history.push('/u/manage-account');
  };

  const handleSubmit = async (pin: string, numberToSubmit: string) => {
    analyticsService.logEvent(AnalyticsEvent.MyAccountEditOptionsEdited, data);

    setIsLoading(true);

    const validateRes = await validateSMSPin(numberToSubmit, pin);
    if (validateRes.error) {
      Logger.error(`${LoggingModule.profile}SMS verification failed`, validateRes.error.message);
      setIsLoading(false);

      return Alert.alert(
        ProfileUpdateMessages.VALIDATE_PHONE.title,
        ProfileUpdateMessages.VALIDATE_PHONE.message
      );
    }

    const updatedConsumer = getUpdatedPhoneConsumer(consumer, numberToSubmit);
    const res = await updatePhone(updatedConsumer);

    if (res.error) {
      Logger.error(`${LoggingModule.profile}Failed to update phone number`, res.error.message);
      setIsLoading(false);

      return Alert.alert(
        ProfileUpdateMessages.UPDATE_PHONE.title,
        ProfileUpdateMessages.UPDATE_PHONE.message
      );
    }

    setIsLoading(false);

    analyticsService.logEvent(AnalyticsEvent.MyAccountEditOptionsCompleted, data);

    return Alert.alert('Phone Updated', UPDATE_DISCLAIMER, [
      {
        text: 'OK',
        onPress: () => {
          fetchProfile();
          history.goBack();
        }
      }
    ]);
  };

  const props = {
    initialMobile: initialPhone,
    handleCancel,
    handleSubmit,
    isLoading,
    resetValidation,
    validated,
    sendError,
    sendPin: async (data: SendPinProps) => sendPinToPhone(data)
  };
  return <EditPhoneComponent {...props} />;
}

const mapDispatch = (dispatch: Function) => ({
  sendPinToPhone: (data: SendPinProps) => dispatch(sendPinOTP(data)),
  resetValidation: () => dispatch(resetValidationState()),
  validateSMSPin: (phone: string, pin: string) =>
    dispatch(validatePinOTP({ type: 'sms', phone, pin })),
  updatePhone: (update: Consumer) => dispatch(updateConsumerProfile(update, ['Phone'])),
  fetchProfile: () => dispatch(getProfile('', true))
});

const mapState = (state: RootState) => ({
  consumer: profileSelectors.profileConsumerSelector(state),
  validated: profileSelectors.profileValidatedSelector(state),
  sendError: profileSelectors.profileValidationSendErrorSelector(state)
});

export default connect(mapState, mapDispatch)(EditPhoneScreen);
