import React from 'react';
import { Icon } from 'components/Icon';
import Typography from 'components/UI/Typography';
import { convertToLowerKabobCase } from 'modules/utils/StringUtils';
import { MEDLINE_PLUS_URL } from 'lib/constants/medications';
import { Color } from 'modules/styles/colors';
import { Confirm } from 'components/ConfirmDialog/ConfirmDialog';
import { Spacing, IconSize } from 'modules/styles/variables';
import { MuiBox } from 'theme/material-ui';
import Svg from 'components/UI/Svg/Svg';
import { StyledMuiBox } from './styled';

interface Props {
  body?: string;
  children?: JSX.Element[] | JSX.Element;
  itemBackgroundColor?: string;
  secondaryBody?: string;
  statusColor?: string;
  statusText?: string;
  tertiaryBody?: string;
  title: string;
  hideInfoIcon?: boolean;
  onInfoClick?: () => void;
  onExpand?: () => void;
}

export function MedicalHistoryListItem({
  body,
  children,
  itemBackgroundColor,
  secondaryBody,
  statusColor,
  statusText,
  tertiaryBody,
  title,
  hideInfoIcon,
  onInfoClick,
  onExpand
}: Props) {
  const [itemExpanded, setItemExpanded] = React.useState(false);
  const toggleItem = () => {
    if (!itemExpanded && onExpand) {
      onExpand();
    }
    setItemExpanded(!itemExpanded);
  };

  const openUrl = () => {
    const titleString = title.split(' ').join('+');
    window.open(`${MEDLINE_PLUS_URL}${titleString}`, '_blank');
  };

  const openDialog = () =>
    Confirm.show(
      'Visit MedlinePlus for more information about this condition.',
      null,
      null,
      'info',
      { text: 'Visit Site', onClick: openUrl },
      { text: 'Cancel' },
      { closeIcon: true }
    );

  const showInfoIcon = title !== 'Active' && title !== 'Resolved' && !hideInfoIcon;
  return (
    <>
      <StyledMuiBox
        display="flex"
        flexDirection="row"
        alignItems="center"
        p={3}
        itemBackgroundColor={itemBackgroundColor}
        button
        onClick={toggleItem}
      >
        <MuiBox flex={1}>
          <Typography
            onClick={e => {
              e.stopPropagation();
              if (showInfoIcon) {
                if (onInfoClick) {
                  onInfoClick();
                }
                openDialog();
              }
            }}
            data-testid={convertToLowerKabobCase(title)}
            tag="label"
          >
            {title}{' '}
            {showInfoIcon ? (
              <Svg
                data-testid="info-icon"
                set="assets"
                name="InfoIcon"
                size={IconSize.small}
                color={Color.primary}
              />
            ) : null}
          </Typography>

          {body ? (
            <Typography tag="small" numberOfLines={1}>
              {body}
            </Typography>
          ) : null}

          {statusText ? (
            <Typography color={statusColor} tag="small">
              {statusText}
            </Typography>
          ) : null}

          {secondaryBody ? (
            <MuiBox pt={1}>
              <Typography tag="small">{secondaryBody}</Typography>
            </MuiBox>
          ) : null}

          {tertiaryBody ? <Typography tag="small">{tertiaryBody}</Typography> : null}
        </MuiBox>
        <MuiBox
          pl={Spacing.large}
          data-testid={convertToLowerKabobCase(title.toString(), '-expandable-drawer')}
        >
          <Icon name={itemExpanded ? 'remove' : 'add'} size={IconSize.base} />
        </MuiBox>
      </StyledMuiBox>
      {itemExpanded && children}
    </>
  );
}
