import React from 'react';

export default {
  svg: (
    <g>
      <g>
        <g>
          <circle fill="#8CC63F" strokeWidth={0} cx={40.271} cy={40} r={38.5} />
          <path
            fill="#FFFFFF"
            strokeWidth={0}
            d="M40.271,2.5c20.711,0,37.5,16.789,37.5,37.5s-16.789,37.5-37.5,37.5S2.771,60.711,2.771,40 S19.56,2.5,40.271,2.5 M40.271,0.5c-21.78,0-39.5,17.72-39.5,39.5s17.72,39.5,39.5,39.5s39.5-17.72,39.5-39.5 S62.051,0.5,40.271,0.5L40.271,0.5z"
          />
        </g>
        <g>
          <defs>
            <circle id="SVGID_7_" cx={40.271} cy={40} r={37.5} />
          </defs>
          <clipPath id="SVGID_4_">
            <use xlinkHref="#SVGID_7_" />
          </clipPath>
          <g clipPath="url(#SVGID_4_)">
            <rect
              x={31.01}
              y={64.674}
              fill="#E6E6E6"
              strokeWidth={0}
              width={18.223}
              height={7.823}
            />
            <path
              fill="#F2AE75"
              strokeWidth={0}
              d="M56.733,36.425l0.515-6.699c0-8.76-8.373-15.857-17.126-15.857c-8.76,0-17.126,7.098-17.126,15.857 l0.515,6.692c-0.49,0.118-0.932,0.343-1.301,0.659c-0.685,0.584-1.116,1.446-1.116,2.417v3.298c0,1.744,1.404,3.159,3.152,3.17 c0,5.178,3.895,10.188,8.266,13.21v31.389h15.223V59.173c4.37-3.022,8.265-8.031,8.265-13.21 c1.741-0.012,3.149-1.425,3.149-3.169v-3.298C59.148,38.006,58.117,36.765,56.733,36.425z"
            />
            <path
              fill="#E6E6E6"
              strokeWidth={0}
              d="M78.608,101.5c-0.881-7.863-3.14-15.425-4.246-21.895c-1.215-7.109-12.733-7.742-25.115-12.394 c0,0,0.917,8.05-4.139,11.703c-1.224,0.884-2.883,1.73-4.986,2.756c-1.933-1.026-3.616-1.93-4.816-2.756 c-5.202-3.58-4.28-11.703-4.28-11.703c-12.382,4.652-23.9,5.285-25.115,12.394C4.748,86.406,2.666,93.748,1.933,101.5H78.608z"
            />
            <polygon
              fill="#FFFFFF"
              strokeWidth={0}
              points="49.228,64.674 40.119,81.67 31.01,64.674 24.344,69.714 30.359,81.783 35.38,79.079  40.119,81.67 44.858,79.079 49.879,81.783 55.894,69.714  "
            />
            <path
              fill="#E99260"
              strokeWidth={0}
              d="M56.733,36.425l0.515-6.699c0-8.76-8.373-15.857-17.126-15.857c-0.003,0-0.003,22.518-0.003,22.518 c-2.314,4.921-3.806,12.052-3.806,12.052h3.806v13.637c-2.22-0.001-4.999-1.098-7.609-2.903v2.997l14.826,3.756l0.397,0.133 v-6.886c-0.019,0.013-0.038,0.024-0.057,0.037c4.394-3.018,8.323-8.046,8.323-13.247c1.741-0.012,3.149-1.425,3.149-3.169v-3.298 C59.148,38.006,58.117,36.765,56.733,36.425z M40.12,62.075c0.004,0,0.009-0.001,0.013-0.001c-0.004,0-0.008,0.001-0.011,0.001 C40.121,62.075,40.12,62.075,40.12,62.075z M46.631,59.883c0.081-0.049,0.161-0.098,0.241-0.149 C46.792,59.785,46.711,59.834,46.631,59.883z M40.875,62.031c0.072-0.007,0.144-0.016,0.217-0.025 C41.02,62.015,40.947,62.023,40.875,62.031z M41.83,61.884c0.031-0.007,0.063-0.013,0.094-0.02 C41.893,61.871,41.862,61.877,41.83,61.884z M42.697,61.664c0.084-0.024,0.167-0.05,0.252-0.076 C42.864,61.614,42.78,61.64,42.697,61.664z M43.706,61.327c0.04-0.015,0.079-0.029,0.119-0.045 C43.786,61.298,43.746,61.312,43.706,61.327z M44.639,60.937c0.085-0.039,0.171-0.078,0.256-0.119 C44.81,60.859,44.725,60.898,44.639,60.937z M45.686,60.423c0.028-0.015,0.056-0.028,0.084-0.043 C45.742,60.394,45.714,60.407,45.686,60.423z"
            />
            <g>
              <g>
                <path
                  fill="#EC9B68"
                  strokeWidth={0}
                  d="M35.328,38.214c0.048,1.383-1.033,2.543-2.416,2.591c-1.383,0.048-2.543-1.033-2.591-2.416 c-0.048-1.383,1.033-2.543,2.416-2.591C34.12,35.75,35.28,36.832,35.328,38.214z"
                />
                <path
                  fill="#3C2417"
                  strokeWidth={0}
                  d="M35.328,37.58c0.048,1.383-1.033,2.543-2.416,2.591c-1.383,0.048-2.543-1.033-2.591-2.416 c-0.048-1.383,1.033-2.543,2.416-2.591C34.12,35.116,35.28,36.197,35.328,37.58z"
                />
                <path
                  fill="#FFFFFF"
                  strokeWidth={0}
                  d="M34.301,36.864c0.012,0.346-0.258,0.636-0.604,0.648c-0.346,0.012-0.636-0.258-0.648-0.604 c-0.012-0.346,0.258-0.636,0.604-0.648C33.999,36.248,34.289,36.518,34.301,36.864z"
                />
              </g>
              <g>
                <path
                  fill="#DB8258"
                  strokeWidth={0}
                  d="M49.917,38.214c0.048,1.383-1.033,2.543-2.416,2.591c-1.383,0.048-2.543-1.033-2.591-2.416 c-0.048-1.383,1.033-2.543,2.416-2.591C48.709,35.75,49.869,36.832,49.917,38.214z"
                />
                <path
                  fill="#3C2417"
                  strokeWidth={0}
                  d="M49.917,37.58c0.048,1.383-1.033,2.543-2.416,2.591c-1.383,0.048-2.543-1.033-2.591-2.416 c-0.048-1.383,1.033-2.543,2.416-2.591C48.709,35.116,49.869,36.197,49.917,37.58z"
                />
                <path
                  fill="#FFFFFF"
                  strokeWidth={0}
                  d="M48.89,36.864c0.012,0.346-0.258,0.636-0.604,0.648c-0.346,0.012-0.636-0.258-0.648-0.604 c-0.012-0.346,0.258-0.636,0.604-0.648C48.588,36.248,48.878,36.518,48.89,36.864z"
                />
              </g>
            </g>
            <path
              fill="#2D1C12"
              strokeWidth={0}
              d="M47.792,51.992c-2.273-2.374-6.992-2.627-7.523-2.648c-0.033-0.001-0.067-0.001-0.1,0 c-0.531,0.021-5.25,0.274-7.523,2.648c-0.229,0.238-0.356,0.557-0.352,0.888c0.029,3.275,0.579,6.387,0.629,6.518 c0.08,0.209,0.214,0.393,0.389,0.533c0.107,0.086,3.286,2.098,6.905,2.098c3.618,0,6.802-2.011,6.909-2.097 c0.175-0.141,0.31-0.325,0.39-0.534c0.051-0.131,0.6-3.243,0.629-6.517C48.147,52.55,48.021,52.231,47.792,51.992z M45.269,58.14 c-0.46,0.301-2.121,0.884-3.468,1.176v-1.701c0-0.876-0.71-1.586-1.586-1.586c-0.876,0-1.586,0.71-1.586,1.586v1.701 c-1.348-0.292-3.005-0.876-3.463-1.176c-0.258-0.763-0.207-2.683-0.319-4.719c1.471-1.114,4.27-1.485,5.37-1.539 c1.101,0.054,3.899,0.425,5.37,1.538C45.477,55.446,45.527,57.373,45.269,58.14z"
            />
            <path
              fill="#2D1C12"
              strokeWidth={0}
              d="M48.719,10.527C44.58,7.45,38.37,6.026,32.301,9.599c-6.091,3.586-6.819,4.591-9.802,4.404 c-0.956-0.06-1.925-0.203-2.045,0.747c-0.39,3.098,0.17,5.77,1.444,7.926c-2.079,8.492,1.729,18.087,1.729,18.087 s-0.723-9.486,1.49-14.648c5.736,3.998,15.81,3.633,25.67-3.09c5.888,4.135,5.818,17.738,5.818,17.738 S67.757,14.921,48.719,10.527z"
            />
          </g>
        </g>
      </g>
    </g>
  ),
  viewBox: '0 0 80 80'
};
