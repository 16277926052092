import React, { ReactNode, useMemo, useEffect, useState } from 'react';
import { EventEmitter } from 'services/EventEmitter';
import startCase from 'lodash/startCase';
import { RouteData } from 'screens/Booking/router/types';
import { BookingButton } from '../BookingButton';
import { BookingStepperProps } from './index';

export interface BookingStepActionProps<T = RouteData> {
  onClick?: (step?: T) => void;
  children?: ReactNode;
  disabled?: boolean;
  loading?: boolean;
  label?: string;
  variant: 'previous' | 'next';
}

export const stepActionBus = new EventEmitter();

class StepAction {
  static timer: number;

  static setStepper<T>(props: BookingStepperProps<T>) {
    if (StepAction.timer) {
      clearTimeout(StepAction.timer);
    }
    StepAction.timer = setTimeout(() => stepActionBus.emit('setStepper', props), 0);
  }
}

export function BookingStepAction<T>({
  onClick,
  label,
  disabled,
  loading,
  variant,
  ...rest
}: BookingStepActionProps<T>) {
  const [steps, setSteps] = useState([]);
  const [activeStep, setActiveStep] = useState(-1);
  const upcomingStepCount = useMemo(() => {
    return variant === 'previous' ? activeStep - 1 : activeStep + 1;
  }, [variant, activeStep]);

  useEffect(() => {
    const unsubscribe = stepActionBus.on('setStepper', props => {
      setActiveStep(props.activeStep);
      setSteps(props.steps);
    });
    return () => unsubscribe();
  }, [stepActionBus]);

  const handleClick = () => {
    if (onClick) {
      onClick(steps[upcomingStepCount]);
    }
  };

  return (
    <BookingButton
      fullWidth
      variant={variant === 'next' ? 'contained' : 'text'}
      loading={loading}
      disabled={disabled}
      onClick={handleClick}
      {...rest}
    >
      {label || startCase(variant)}
    </BookingButton>
  );
}

BookingStepAction.defaultProps = {
  variant: 'next'
};

export const useBookingStepAction = () => ({
  setStepper: StepAction.setStepper
});

export default BookingStepAction;
