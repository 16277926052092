import React, { useState } from 'react';

import { ConnectCareBullet, ConnectCareButton } from 'components/ConnectCare';
import Svg from 'components/UI/Svg/Svg';
import { awData, prepend } from 'lib/connectcare/utils';
import { Color } from 'modules/styles/colors';
import { Spacing } from 'modules/styles/variables';
import { PaymentMethod } from 'store/amwell/types';
import { MuiBox, MuiCard, MuiCardContent, MuiDivider, MuiTypography } from 'theme/material-ui';
import useStyles from '../useStyles';
import ConnectCarePaymentMethodSelectDialog from './ConnectCarePaymentMethodSelectDialog';

export interface Props {
  paymentMethod: PaymentMethod | null;
}

export function ConnectCarePaymentMethodSelectSaved(props: Props) {
  const { paymentMethod } = props;
  const classes = useStyles();
  const [showPaymentMethod, setShowPaymentMethod] = useState(false);

  return (
    <>
      <MuiBox pb={2} textAlign="center">
        <MuiTypography variant="h5">Saved Payment Method</MuiTypography>
      </MuiBox>
      {paymentMethod ? (
        <MuiCard className={classes.flexCard}>
          <MuiBox pl={2} display="flex" alignItems="center" flex={0}>
            <Svg name="CreditCard" size={60}>
              {paymentMethod.type.toLowerCase()}
            </Svg>
          </MuiBox>
          <MuiCardContent style={{ flex: 1 }}>
            <MuiBox className={classes.flexContent} pr={4}>
              <MuiTypography>{paymentMethod.type}</MuiTypography>
              <MuiTypography>
                <ConnectCareBullet />
                <ConnectCareBullet />
                <ConnectCareBullet />
                <ConnectCareBullet />
                {paymentMethod.lastDigits}
              </MuiTypography>
            </MuiBox>
            <MuiBox className={classes.flexContent} pr={4}>
              <MuiTypography>Expires</MuiTypography>
              <MuiTypography>
                {prepend(awData(paymentMethod).expirationMonth)}/
                {awData(paymentMethod).expirationYear}
              </MuiTypography>
            </MuiBox>
          </MuiCardContent>

          <MuiDivider className={classes.verticalDivider} orientation="vertical" />
          <MuiBox textAlign="center" className={classes.cardAction}>
            <ConnectCareButton variant="text" onClick={() => setShowPaymentMethod(true)}>
              Change
            </ConnectCareButton>
          </MuiBox>
        </MuiCard>
      ) : (
        <MuiBox textAlign="center" minWidth={350} paddingX={Spacing.small}>
          <ConnectCareButton
            fullWidth
            variant="outlined"
            backgroundColor={Color.foreColor}
            onClick={() => setShowPaymentMethod(true)}
          >
            Add Payment Method
          </ConnectCareButton>
        </MuiBox>
      )}
      <ConnectCarePaymentMethodSelectDialog
        open={showPaymentMethod}
        onClose={() => setShowPaymentMethod(false)}
      />
    </>
  );
}

export default ConnectCarePaymentMethodSelectSaved;
