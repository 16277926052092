import styled from 'styled-components';
import { dfdDefaultTheme, MuiBox, MuiTypography } from 'theme/material-ui';
import { FontSize } from 'modules/styles/variables';
import { FontFamily } from 'modules/styles/variables.web';

interface CardContainerProps {
  backgroundColor: string;
}

export const CardContainerBlank = styled(MuiBox)`
  border-radius: 5px;
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

export const CardContainer = styled(MuiBox)`
  display: flex;
  position: relative;
  justify-content: space-between;

  width: 100%;
  height: 100%;
  border-radius: 5px;
  background-color: ${(props: CardContainerProps) => props.backgroundColor || 'white'};
  overflow: hidden;
`;

export const ContentLeft = styled(MuiBox)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 55%;
  height: 100%;
  padding: 16px;
  z-index: 2;

  ${dfdDefaultTheme.breakpoints.up('sm')} {
    padding: 16px 32px;
  }
`;

export const ContentRight = styled(MuiBox)`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 45%;
  height: 100%;
  right: -10px;
  z-index: 1;
`;

export const StyledImage = styled.img`
  width: auto;
  height: 100%;
`;

export const TextContentContainer = styled(MuiBox)`
  width: 100%;
`;

export const SmallTitle = styled(MuiTypography)`
  font-size: ${FontSize.base}px;
  color: ${props => props.color};

  ${dfdDefaultTheme.breakpoints.up('sm')} {
    && {
      font-size: ${FontSize.large}px;
    }
  }
`;

export const LargeTitle = styled(MuiTypography)`
  && {
    font-size: ${FontSize.largeHeading}px;
    font-family: ${FontFamily.rector};
    line-height: initial;
    color: ${props => props.color};
  }

  ${dfdDefaultTheme.breakpoints.up('sm')} {
    && {
      font-size: ${FontSize.heading}px;
    }
  }
`;

export const SubTitle = styled(MuiTypography)`
  font-size: ${FontSize.large}px;
  color: ${props => props.color};
  width: 100%;
`;

export const LeftBtnContainer = styled(MuiBox)`
  width: 100%;
  max-width: 300px;
  padding-bottom: 20px;
`;

export const ActionButtonTwoSpace = styled(MuiBox)`
  height: 33px;
`;

export const FlippableCardContainer = styled(MuiBox)`
  background-color: transparent;
  perspective: 1000px;
  height: 100%;
`;

export const FlippableInner = styled(MuiBox)`
  width: 100%;
  height: 100%;
  position: relative;
  transition: transform 0.3s;
  transform-style: preserve-3d;
`;

// @Note: flip animation will be revisited in phase two
export const FlippableFront = styled(MuiBox)`
  position: absolute;
  width: 100%;
  height: 100%;
  --webkit-backface-visibility: hidden;
  --moz-backface-visibility: hidden;
  backface-visibility: hidden;
  background-color: white;
`;

export const FlippableBack = styled(MuiBox)`
  position: absolute;
  width: 100%;
  height: 100%;
  --webkit-backface-visibility: hidden;
  --moz-backface-visibility: hidden;
  backface-visibility: hidden;
  transform: rotateY(180deg);
  background-color: white;
`;
