import styled from 'styled-components';

export default styled.div`
  &&& {
    flex: 1 1 auto;
  }
`;

export const WebRTCVideoContainer = styled.div`
  &&& {
    height: 100%;
    position: relative;
  }
`;
