import React from 'react';
import styled from 'styled-components';
import Card from 'components/UI/Card/Card';
import { MuiTypography, dfdDefaultTheme } from 'theme/material-ui';
import { Color } from 'modules/styles/colors';
import { FontSize } from 'modules/styles/variables';

export interface Props {
  src?: string;
  alt?: string;
  img?: React.ReactNode;
  testId?: string;
  title: string;
  subtitle?: string;
  children?: React.ReactNode;
}

const CarouselCard = styled(Card)`
  padding: ${dfdDefaultTheme.spacing(2)}px;
  height: 185px;
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1 1 auto;
`;

const CarouselImage = styled.img`
  max-width: 100%;
  height: 85px;
`;

export const CarouselSlide = ({ src, alt, img, testId, title, subtitle, children }: Props) => (
  <CarouselCard data-testid={testId}>
    {img || (src ? <CarouselImage src={src} alt={alt} /> : null)}

    <MuiTypography color={Color.primary} fontSize={FontSize.heading} align="center" gutterBottom>
      {title}
    </MuiTypography>

    {subtitle ? (
      <MuiTypography align="center" gutterBottom>
        {subtitle}
      </MuiTypography>
    ) : null}

    {children}
  </CarouselCard>
);
