import React from 'react';
import { Color } from 'modules/styles/colors';

export default ({ color = Color.black }) => ({
  svg: (
    <g>
      <path
        d="M13.6348 39.8525L16.3648 44.0425C20.483 41.425 25.2678 40.0485 30.1475 40.0773C35.027 40.1063 39.7953 41.539 43.8823 44.205L46.6623 40.05C41.7795 36.7913 36.0483 35.0355 30.178 35.0005C24.3079 34.9655 18.5561 36.6523 13.6348 39.8525Z"
        fill={color}
      />
      <path
        d="M41.25 23.75C41.25 21.525 40.5903 19.3499 39.354 17.4999C38.1178 15.6498 36.3608 14.2079 34.3053 13.3564C32.2495 12.5049 29.9875 12.2821 27.8053 12.7162C25.623 13.1503 23.6184 14.2217 22.0451 15.7951C20.4717 17.3684 19.4003 19.373 18.9662 21.5553C18.5321 23.7375 18.7549 25.9995 19.6064 28.0553C20.4579 30.1108 21.8998 31.8678 23.7498 33.104C25.6 34.3403 27.775 35 30 35C32.9828 34.9968 35.8423 33.8103 37.9513 31.7013C40.0603 29.5923 41.2468 26.7328 41.25 23.75ZM30 30C28.7638 30 27.5555 29.6335 26.5278 28.9468C25.5 28.26 24.6988 27.2838 24.2258 26.1418C23.7527 24.9998 23.6289 23.7431 23.8701 22.5307C24.1113 21.3183 24.7065 20.2047 25.5805 19.3306C26.4548 18.4565 27.5683 17.8613 28.7808 17.6201C29.993 17.379 31.2498 17.5027 32.3918 17.9758C33.5338 18.4488 34.51 19.2499 35.1968 20.2777C35.8835 21.3055 36.25 22.5139 36.25 23.75C36.25 24.5708 36.0883 25.3835 35.7743 26.1418C35.4603 26.9 34.9998 27.589 34.4195 28.1695C33.839 28.7498 33.15 29.2103 32.3918 29.5243C31.6335 29.8383 30.8208 30 30 30Z"
        fill={color}
      />
    </g>
  ),
  viewBox: '0 0 60 60'
});
