import React from 'react';

import {
  MuiDialog,
  MuiDialogActions,
  MuiDialogContent,
  MuiDialogContentText,
  MuiDialogTitle,
  MuiButton
} from 'theme/material-ui';
import { termsNotFound } from 'store/providerSearch/constants';

export interface Props {
  content?: string;
  open: boolean;
  handleDecline: () => void;
  handleAccept: () => void;
}

const ProviderSearchTerms = ({ open, content, handleDecline, handleAccept }: Props) => {
  return (
    <MuiDialog open={open} scroll="paper">
      <MuiDialogTitle>Terms & Conditions</MuiDialogTitle>
      <MuiDialogContent dividers>
        <MuiDialogContentText>
          {content ? (
            <span
              dangerouslySetInnerHTML={{
                __html: content
              }}
            />
          ) : (
            termsNotFound
          )}
        </MuiDialogContentText>
      </MuiDialogContent>
      <MuiDialogActions>
        <MuiButton onClick={handleDecline} color="primary">
          Decline
        </MuiButton>
        <MuiButton
          data-testid="terms-and-conditions-agree-button"
          onClick={handleAccept}
          variant="contained"
          color="primary"
        >
          Agree
        </MuiButton>
      </MuiDialogActions>
    </MuiDialog>
  );
};

export default ProviderSearchTerms;
