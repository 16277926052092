import React from 'react';
import Svg from 'components/UI/Svg/Svg';
import { IconSize, FontWeight } from 'modules/styles/variables';
import { Color } from 'modules/styles/colors';
import { ClampedText } from '../styled';
import Typography from 'components/UI/Typography';
import Range from 'components/Range/Range';
import { orNA } from './utils';
import { LabComponent } from 'store/testResults/types';
import {
  Row,
  ColumnName,
  ColumnRange,
  ColumnComments,
  CommentContainer,
  ColumnArrow
} from './styled';

const getRangeValue = (item: LabComponent) => {
  if (typeof item?.valueQuantity === 'number') {
    return item?.valueQuantity || 0;
  }
  if (typeof item?.valueQuantity === 'object') {
    if (item?.valueQuantity === null) return item?.valueString || 0;
    return item?.valueQuantity?.value || 0;
  }
  return item?.valueString || 0;
};
export interface LabRowProps {
  item: LabComponent;
  onLabRowClick?: () => void;
}

const LabRow = ({ item, onLabRowClick }: LabRowProps) => {
  const hasComments = orNA(item.notes) !== 'N/A';
  const rangeValue = getRangeValue(item);
  const { low, high } = item?.referenceRange || {};

  return (
    <Row onClick={onLabRowClick} style={{ backgroundColor: Color.baseColor }}>
      <ColumnName id={item.eventId}>
        <Typography data-testid="test-result-name" fontWeight={FontWeight.semibold}>
          {item.name}
        </Typography>
      </ColumnName>
      <ColumnRange>
        <Range
          testID="lab-row-range"
          value={rangeValue}
          unit={item.valueQuantity?.unit}
          low={low?.value}
          high={high?.value}
          fixedTooltip
          big
        />
      </ColumnRange>
      <ColumnComments>
        {item.valueQuantity ? (
          <Svg data-testid="trendline-icon" set="assets" name="Trendline" width={IconSize.base} />
        ) : null}
        {hasComments ? (
          <Svg
            data-testid="comments-icon"
            set="assets"
            name="SNavMessages"
            fill={Color.black}
            width={IconSize.base}
          />
        ) : null}
        {hasComments ? (
          <CommentContainer>
            <ClampedText data-testid="comments" numberoflines={2}>
              {orNA(item.notes)}
            </ClampedText>
          </CommentContainer>
        ) : null}
      </ColumnComments>
      <ColumnArrow>
        <Svg
          data-testid="navigate-button"
          name="ChevronRight"
          color="black"
          size={IconSize.small}
        />
      </ColumnArrow>
    </Row>
  );
};

export default LabRow;
