import { FontSize } from 'modules/styles/variables';
import { range } from 'modules/utils/ArrayUtils';
import React from 'react';
import { Color } from 'modules/styles/colors';
import styled, { keyframes } from 'styled-components';

interface AnimatedEllipsisProps {
  color: typeof Color[keyof typeof Color];
}

const fadedScaleAnimation = keyframes`
  0%, 66%, 100% {
    transform: scale(0.5);
    opacity: 0.5;
  }

  33% {
    transform: scale(1);
    opacity: 1;
  }
`;

const ANIMATION_DURATION = 900;
const DOT_DELAY = 150;
const NUMBER_OF_DOTS = 3;

const StyledAnimatedEllipsisDot = styled.div<{
  index: number;
  color: typeof Color[keyof typeof Color];
}>`
  height: ${FontSize.base}px;
  width: ${FontSize.base}px;
  background-color: ${props => props.color};
  border-radius: 50%;
  margin: 0 1px;

  animation: ${fadedScaleAnimation} ${ANIMATION_DURATION}ms ease-in-out infinite;
  animation-iteration-count: infinite;
  animation-delay: ${props => props.index * DOT_DELAY}ms;
`;

const StyledAnimatedEllipsisContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1 0 auto;
  justify-content: center;
`;

export const AnimatedEllipsis = ({ color }: AnimatedEllipsisProps) => {
  return (
    <StyledAnimatedEllipsisContainer>
      {range(NUMBER_OF_DOTS).map(v => (
        <StyledAnimatedEllipsisDot key={v} index={v} color={color} />
      ))}
    </StyledAnimatedEllipsisContainer>
  );
};

AnimatedEllipsis.defaultProps = {
  color: Color.gray
};
