import React from 'react';

export default {
  svg: (
    <g>
      <path
        d="M14.8,19.5l3.3-4.4l6.1,8.3c0.2,0.2,0.5,0.4,0.8,0.4c0.2,0,0.6-0.1,0.7-0.4l2.8-3.3h1c-1,3.7-4.4,6.4-8.3,6.4
      c-1.8,0-3.4-0.5-4.8-1.4c-0.4-0.4-0.9-0.2-1.3,0.2c-0.4,0.4-0.2,0.9,0.2,1.3c1.8,1.2,3.8,1.8,5.9,1.8c4.9,0,9.2-3.5,10.2-8.3h2.7
      c0,0,0,0,0.1,0h5.8c0.5,0,0.9-0.4,0.9-0.9c0-0.5-0.4-0.9-0.9-0.9h-4.8c0-0.1,0-0.2,0-0.4c0-7.7-6.3-13.9-13.9-13.9
      S7.4,10.1,7.4,17.8c0,0,0,0.1,0,0.1H2c-0.5,0-0.9,0.4-0.9,0.9c0,0.5,0.4,0.9,0.9,0.9h12.1C14.3,19.8,14.6,19.7,14.8,19.5z
       M13.1,17.9c0.1-4.7,3.9-8.5,8.6-8.5c1.8,0,3.4,0.5,4.8,1.4c0.4,0.4,0.9,0.2,1.3-0.2c0.4-0.4,0.2-0.9-0.2-1.3
      c-1.8-1.2-3.8-1.8-5.9-1.8c-5.8,0-10.5,4.7-10.6,10.4H9.3c0-6.6,5.4-12,12-12s12,5.4,12,12c0,0.1,0,0.2,0,0.2h-5.2
      c-0.2,0-0.5,0.1-0.7,0.4l-2.2,2.7L18.9,13c-0.1-0.2-0.4-0.4-0.7-0.4c-0.4,0-0.6,0.1-0.8,0.4l-3.9,5H13.1z"
      />
      <path d="M43.2,20.1c0.6,0,0.9-0.6,0.8-1c0-0.5-0.1-1-0.9-1c-0.5,0-0.9,0.5-0.9,1.1C42.3,19.7,42.7,20.1,43.2,20.1z" />
      <path
        d="M46,37l-8.6-7.4c0-0.1-0.1-0.2-0.2-0.2c-0.1-0.1-0.2-0.1-0.3-0.2l-2.4-2.1c-0.6-0.6-1.7-0.6-2.7,0c0.8-0.9,1.5-1.9,2-3
      c0.2-0.5,0-1.1-0.5-1.3c-0.5-0.2-1.1,0-1.3,0.5c-2,4-6.1,6.6-10.7,6.6c-4.6,0-8.7-2.5-10.7-6.6c-0.2-0.5-0.8-0.7-1.3-0.5
      c-0.5,0.2-0.7,0.8-0.5,1.3c2.4,4.8,7.2,7.8,12.5,7.8c3.4,0,6.6-1.2,9.1-3.3c-0.5,0.9-0.6,2,0,2.7l2,2.3c0,0.1,0.1,0.3,0.2,0.4
      c0.1,0,0.1,0.1,0.2,0.1l7.5,8.6C41,43.5,41.9,44,43,44h0.1c1.1,0,2.1-0.5,2.9-1.2c0.8-0.8,1.2-1.9,1.2-3.1
      C47.3,38.7,46.8,37.7,46,37z M32.6,28.9c0.4-0.4,0.8-0.5,0.9-0.5l1.7,1.5l-1.9,1.9l-1.5-1.7C31.9,29.8,32,29.5,32.6,28.9z
       M44.8,41.3c-0.5,0.5-1.1,0.7-1.7,0.7c-0.5,0-0.9-0.2-1.3-0.6l-7.1-8.1l2.1-2.1l8.1,7.1c0.4,0.4,0.6,0.8,0.6,1.3
      C45.5,40.2,45.2,40.8,44.8,41.3z"
      />
      <path
        d="M29.6,14.6c0.1,0.4,0.5,0.6,0.8,0.6c0.1,0,0.2,0,0.4,0c0.5-0.2,0.7-0.8,0.5-1.3c-0.2-0.6-0.6-1.2-0.9-1.8
      c-0.2-0.5-0.8-0.6-1.3-0.2c-0.5,0.2-0.6,0.8-0.2,1.3S29.3,14.2,29.6,14.6z"
      />
      <path
        d="M12.1,20.6c-0.5,0.2-0.7,0.8-0.5,1.3c0.2,0.6,0.6,1.2,0.9,1.8c0.2,0.4,0.5,0.5,0.8,0.5c0.2,0,0.5-0.1,0.6-0.4
      c0.5-0.2,0.6-0.8,0.2-1.3c-0.4-0.5-0.6-0.9-0.8-1.4S12.6,20.4,12.1,20.6z"
      />
    </g>
  ),
  viewBox: '0 0 48 48'
};
