import { Color } from 'modules/styles/colors';
import { largeFontSize } from 'modules/styles/variables';
import styled from 'styled-components';
import DefaultText from './DefaultText';

interface Props {
  color?: string;
}

export default styled(DefaultText)`
  font-weight: 600;
  font-size: ${largeFontSize};
  line-height: ${largeFontSize * 1.4};
  color: ${(props: Props) => (props.color ? props.color : Color.textLight)};
`;
