import React from 'react';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import capitalize from 'lodash/capitalize';
import {
  MuiDialog,
  MuiDialogTitle,
  MuiDialogContent,
  MuiDialogActions,
  MuiGrid
} from 'theme/material-ui';
import * as Yup from 'yup';
import { RootDispatch } from 'store/types';
import { Gender } from 'store/amwell/types';
import { addDependent } from 'store/amwell/actions';
import { ConnectCareForm, ConnectCareButton } from 'components/ConnectCare';
import { FormikHelpers } from 'formik';

export interface Props {
  dispatch: RootDispatch;
  open: boolean;
  onClose: () => void;
}

const current = dayjs(new Date()).startOf('day');

const initialValues = {
  firstName: '',
  middleName: '',
  lastName: '',
  gender: '',
  dob: current
};

const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .required('Please enter first name')
    .matches(/^[-A-Za-z]+$/, 'Name may only contain letters.'),
  middleName: Yup.string().matches(/^[-A-Za-z]+$/, 'Name may only contain letters.'),
  lastName: Yup.string()
    .required('Please enter last name')
    .matches(/^[-A-Za-z]+$/, 'Name may only contain letters.'),
  dob: Yup.date()
    .typeError('Please enter a valid date')
    .required('Please enter date of birth')
    .min(
      current
        .add(1, 'day')
        .subtract(18, 'year')
        .toDate(),
      'Dependent can not be 18 years or older'
    )
    .max(current.toDate(), 'Please enter a valid date'),
  gender: Yup.string().required('Please select gender')
});

export function ConnectCarePatientSelectAddDependentDialog(props: Props) {
  const { dispatch, open, onClose } = props;

  const handleSubmit = async (
    values: typeof initialValues,
    actions: FormikHelpers<typeof initialValues>
  ) => {
    /**
     * gender should be first uppercased leter either: 'M','F'...
     */
    const registerDependentForm = {
      ...values,
      gender: values.gender.slice(0, 1),
      dob: values.dob.toDate()
    };

    actions.setSubmitting(true);
    await dispatch(addDependent({ registerDependentForm }));
    actions.setSubmitting(false);
    onClose();
  };

  return (
    <ConnectCareForm
      onSubmit={handleSubmit}
      isInitialValid={false}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {({ isValid, isSubmitting, submitForm }) => (
        <MuiDialog
          open={open}
          disableBackdropClick={isSubmitting}
          onClose={onClose}
          maxWidth="md"
          aria-labelledby="add-dependent-form-title"
        >
          <MuiDialogTitle id="add-dependent-form-title">Add a Dependent</MuiDialogTitle>
          <MuiDialogContent data-testid="add-dependent-form">
            <MuiGrid container direction="column" spacing={2} wrap="nowrap">
              <MuiGrid item container direction="row" spacing={2}>
                <MuiGrid item md={4} xs={6}>
                  <ConnectCareForm.TextField
                    label="First Name"
                    name="firstName"
                    placeholder="John"
                    required
                    helperTextInfo="This input field has a 16 character limit"
                    InputProps={{
                      inputProps: { maxLength: 16 }
                    }}
                  />
                </MuiGrid>
                <MuiGrid item md={4} xs={6}>
                  <ConnectCareForm.TextField
                    label="Middle Name"
                    name="middleName"
                    placeholder="Joe"
                    helperTextInfo="This input field has a 16 character limit"
                    InputProps={{
                      inputProps: { maxLength: 16 }
                    }}
                  />
                </MuiGrid>
                <MuiGrid item md={4} xs>
                  <ConnectCareForm.TextField
                    label="Last Name"
                    name="lastName"
                    placeholder="Doe"
                    required
                    helperTextInfo="This input field has a 25 character limit"
                    InputProps={{
                      inputProps: { maxLength: 25 }
                    }}
                  />
                </MuiGrid>
              </MuiGrid>
              <MuiGrid item>
                <ConnectCareForm.DatePicker
                  name="dob"
                  label="Date of Birth"
                  ariaLabel="date picker"
                  required
                />
              </MuiGrid>
              <MuiGrid item>
                <ConnectCareForm.RadioGroup
                  label="Gender"
                  name="gender"
                  required
                  row
                  items={Object.keys(Gender)}
                  itemToValue={(item: string) => (item ? capitalize(item) : '')}
                  itemToLabel={(item: string) => (item ? capitalize(item) : '')}
                />
              </MuiGrid>
            </MuiGrid>
          </MuiDialogContent>
          <MuiDialogActions>
            <ConnectCareButton disabled={isSubmitting} variant="outlined" onClick={onClose}>
              Cancel
            </ConnectCareButton>
            <ConnectCareButton
              disabled={!isValid}
              loading={isSubmitting}
              onClick={submitForm}
              type="submit"
            >
              Add Dependent
            </ConnectCareButton>
          </MuiDialogActions>
        </MuiDialog>
      )}
    </ConnectCareForm>
  );
}

export default connect()(ConnectCarePatientSelectAddDependentDialog);
