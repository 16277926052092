import { exitModalText } from 'modules/constants/exitConfirmModal';
import { Color } from 'modules/styles/colors';
import Config from 'react-native-config';
import { ENTRI_PAY_HOME_URL } from 'modules/constants/Billing';
import { $Values } from 'utility-types';

export const BILLING_SUMMARY = 'billing_summary';
export const DEDUCTIBLE_ACTIVITY = 'deductible_activity';
export const OOP_DEDUCTIBLE_ACTIVITY = 'oop_deductible_activity';
export const VISITPAY_PAYMENT_PLAN = 'visitpay_payment_plan';

// Application Status
export enum ApplicationStatus {
  PENDING = 'P',
  APPROVED = 'A',
  DECLINED = 'D',
  CANCELLED = 'C',
  INCOMPLETE = 'I'
}

export enum ApplicationDetailedStatus {
  PENDING = 'Under Review',
  APPROVED = 'Approved',
  DECLINED = 'Declined',
  CANCELLED = 'Cancelled',
  INCOMPLETE = 'Incomplete'
}

export const ApplicationStatusColor: Record<ApplicationStatus, $Values<typeof Color>> = {
  [ApplicationStatus.PENDING]: Color.textLight,
  [ApplicationStatus.APPROVED]: Color.darkGreenShade,
  [ApplicationStatus.DECLINED]: Color.textLight,
  [ApplicationStatus.CANCELLED]: Color.textLight,
  [ApplicationStatus.INCOMPLETE]: Color.red
};

// Application Approved Detail Screen Static Text
export const APPLICATION_DETAIL_APPROVED =
  'Your application for Financial Assistance has been approved. The following statements have been considered for assistance. Please note that some medical care you receive may not be covered by Intermountain’s Financial Assistance program.';
export const APPLICATION_DETAIL_APPROVED_DISCLAIMER_1 =
  'You are still responsible to pay bills on time. If approved for financial assistance, you may receive a refund. If you have any new bills in addition to the above, please reapply for financial assistance or call';
export const APPLICATION_DETAIL_APPROVED_DISCLAIMER_2 =
  'You do not have to submit additional verification forms for new financial assistance applications in the next 6 months, unless your circumstances have changed. If you have questions, please call Patient Financial Services at';
export const APPLICATION_DETAIL_APPROVED_TEXT =
  'Financial Assistance contribution will take about 1 week to be reflected on your statement.';

export const DISPUTE_QUESTION_OPTIONS = [
  {
    label: 'General Question',
    value: 'General Question'
  },
  {
    label: 'Bill Dispute',
    value: 'Bill Dispute'
  },
  {
    label: 'Financial Assistance',
    value: 'Financial Assistance'
  },
  {
    label: 'Trouble Paying Bill',
    value: 'Trouble Paying Bill'
  },
  {
    label: 'Other',
    value: 'Other'
  }
];

export const VISIT_PAY_SUPPORT = `${Config.VISIT_PAY_URL}&redirectUrl=/Support/MySupportRequests`;
export const VISIT_PAY_HOME = ENTRI_PAY_HOME_URL;
export const VISIT_PAY_PAYMENT_PLAN = `${Config.VISIT_PAY_URL}&redirectUrl=%2FPayment%2FArrangePayment%3FpaymentOption%3D4`;
export const VISIT_PAY_FAMILY_FINANCE = `${Config.VISIT_PAY_URL}&redirectUrl=/Consolidate/ManageHousehold`;
export const ACCOUNT_NUMBER_PREFIX = '107';

export const EXIT_MODAL_TEXT = exitModalText('Financial Assistance');
