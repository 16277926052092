import Card from 'components/UI/Card/Card';
import Divider from 'components/UI/Divider';
import ExpandableList from 'components/UI/ExpandableList/ExpandableList';
import Box from 'components/UI/Layout/Box';
import FlexBox from 'components/UI/Layout/FlexBox';
import ScrollableLayout from 'components/UI/Layout/ScrollableLayout';
import Spacer from 'components/UI/Layout/Spacer';
import Typography from 'components/UI/Typography';
import Paragraph from 'components/UI/Typography/Paragraph';
import { BoldText } from 'components/UI/Typography/styled';
import { Color } from 'modules/styles/colors';
import { FontSize, FontWeight, Spacing } from 'modules/styles/variables';
import { AppName, getAppName } from 'modules/utils/ConfigUtils';
import React, { useState } from 'react';
import { PATIENT_FINANCIAL_SERVICE_NUMBER } from 'screens/Billing/FinancialAssistance/FAApplication/constants';
import VisitPayWebView from 'screens/Billing/VisitPayWebView';
import WhiteBanner from 'screens/Billing/WhiteBanner';
import { VISIT_PAY_HOME } from 'screens/Billing/constants';
import { NavigationScreenProps, getPathByComponentName } from 'screens/navigation';
import styled from 'styled-components';
import { MuiButton } from 'theme/material-ui';
import { SubInnerItem, SubItem, ListItem as li } from '../FAApplication/styled';
import { ADDRESS_INFO } from '../FAStatus/constant';
import { FAQ_BODY, FINANCIAL_ASSISTANCE_DOCUMENT_NOTE, RESOURCE_INFO_BOX } from './constants';

const ListItem = styled(li)`
  justify-content: left;
`;

interface Item {
  title: string;
  subTitle?: string;
  body: object[] | undefined;
  key: number;
}

const FAFAQ = ({ history }: NavigationScreenProps) => {
  const [goToVisitPay, setGoToVisitPay] = useState(false);
  const renderMailAddressInfo = () => {
    return (
      <Box aria-label="{MAIL_ADDRESS_INFO}" key="MAIL_ADDRESS_INFO">
        <Spacer spacing="large" />
        <Card>
          <FlexBox alignItems="center" justifyContent="center">
            <Spacer spacing="medium" />
            <BoldText>{ADDRESS_INFO.addressLabel}</BoldText>
            <Spacer spacing="small" />
            <Typography>{ADDRESS_INFO.address}</Typography>
            <Typography>{ADDRESS_INFO.addressLine1}</Typography>
            <Typography>{ADDRESS_INFO.addressLine2}</Typography>
            <Spacer spacing="medium" />
            <Spacer spacing="medium" />
            <Typography textAlign="center" style={{ fontStyle: 'italic' }}>
              {FINANCIAL_ASSISTANCE_DOCUMENT_NOTE}
            </Typography>
            <Spacer spacing="medium" />
          </FlexBox>
        </Card>
      </Box>
    );
  };

  const renderList = (listItem: ListItem[]) => {
    if (Array.isArray(listItem.body)) {
      return listItem.body.map((item: ListItem) => {
        switch (item.type) {
          case 'sectionHeading': {
            return (
              <ListItem key={item.title} aria-label={item.title}>
                <Spacer size="small" />
                <Divider />
                <Spacer size="medium" />
                <Typography
                  textAlign="center"
                  fontWeight={FontWeight.semibold}
                  fontSize={FontSize.heading}
                  style={{ fontStyle: 'italic' }}
                >
                  {item.title}
                </Typography>
              </ListItem>
            );
          }
          case 'item': {
            return (
              <ListItem key={item.title} aria-label={item.title}>
                <Typography
                  textAlign={item.textTransform === 'center' ? 'center' : 'left'}
                  style={{ fontStyle: item.textTransform === 'italic' ? 'italic' : 'normal' }}
                >
                  {item.title}
                </Typography>
              </ListItem>
            );
          }
          case 'subItem': {
            return (
              <SubItem key={item.title} aria-label={item.title}>
                {item.textTransform !== 'numericSubItem' ? (
                  <Typography>&bull; &nbsp;</Typography>
                ) : null}
                <Typography>{item.title}</Typography>
              </SubItem>
            );
          }
          case 'subInnerItem': {
            return (
              <SubInnerItem key={item.title} aria-label={item.title}>
                <Typography>&ndash; &nbsp;</Typography>
                <Typography>{item.title}</Typography>
              </SubInnerItem>
            );
          }
          case 'entripay': {
            return (
              <ListItem key={item.title} aria-label={item.title}>
                <FlexBox alignItems="center">
                  <MuiButton
                    size="large"
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={() => setGoToVisitPay(true)}
                  >
                    {item.title}
                  </MuiButton>
                </FlexBox>
              </ListItem>
            );
          }
          case 'linkItem': {
            if (item.linkType === 'web') {
              return (
                <ListItem key={item.title} aria-label={item.title}>
                  <FlexBox alignItems="center">
                    <a target="_blank" rel="noopener noreferrer" href={item.routeName}>
                      <MuiButton size="large" variant="contained" color="primary" fullWidth>
                        {item.title}
                      </MuiButton>
                    </a>
                  </FlexBox>
                </ListItem>
              );
            }
            return (
              <ListItem key={item.title} aria-label={item.title}>
                <FlexBox alignItems="center">
                  <MuiButton
                    variant="contained"
                    size="large"
                    color="primary"
                    onClick={() => history.push(getPathByComponentName(item.routeName))}
                  >
                    {item.title}
                  </MuiButton>
                </FlexBox>
              </ListItem>
            );
          }
          case 'MAIL_ADDRESS_INFO': {
            return renderMailAddressInfo();
          }
          case 'PATIENT_FINANCIAL_SERVICE_NUMBER': {
            return (
              <ListItem key={item.title} aria-label={item.title}>
                <FlexBox alignItems="center">
                  <a href={`tel:${PATIENT_FINANCIAL_SERVICE_NUMBER.split('-').join('')}`}>
                    <MuiButton variant="contained" color="primary" fullWidth>
                      {PATIENT_FINANCIAL_SERVICE_NUMBER}
                    </MuiButton>
                  </a>
                </FlexBox>
              </ListItem>
            );
          }
          case 'RESOURCE_INFO_BOX': {
            return (
              <ListItem key={item.title} aria-label={item.title}>
                <Card>
                  <Typography>{RESOURCE_INFO_BOX}</Typography>
                </Card>
              </ListItem>
            );
          }
          default:
            return null;
        }
      });
    }
    return (
      <>
        <Spacer size="small" />
        <Typography aria-label={listItem.body}>{listItem.body}</Typography>
      </>
    );
  };

  return (
    <ScrollableLayout>
      <Spacer size="small" />
      <WhiteBanner
        title="Financial Assistance FAQ"
        subTitle="Frequently asked questions about qualifying for financial assistance on patient bills"
      />

      <Divider />

      <ExpandableList
        data={FAQ_BODY}
        emptyCaption="Empty Data"
        keyExtractor={(item: Item) => `${item.key}`}
        renderItem={(item: Item) => <Typography>{item.title}</Typography>}
        renderBody={(item: Item) => (
          <FlexBox
            justifyContent="center"
            backgroundColor={Color.foreColor}
            vSpacingTop={Spacing.small}
            vSpacingBottom={Spacing.xLarge}
            hSpacing={Spacing.xLarge}
            aria-label={item.title}
          >
            {renderList(item)}
          </FlexBox>
        )}
      />

      <Divider />

      <FlexBox
        backgroundColor={Color.foreColor}
        vSpacing={Spacing.xLarge}
        width="100%"
        alignItems="center"
      >
        <Paragraph>
          If you're unable to find an answer to your question call to speak with a representative:
        </Paragraph>
        <Spacer size="small" />
        <a href={`tel:${PATIENT_FINANCIAL_SERVICE_NUMBER.split('-').join('')}`}>
          <MuiButton
            aria-label="Call Patient Financial Services button"
            color="primary"
            variant="contained"
          >
            {`CALL ${PATIENT_FINANCIAL_SERVICE_NUMBER}`}
          </MuiButton>
        </a>
        <Spacer size="small" />
        <FlexBox spacing={Spacing.large}>
          <Typography textAlign="center" fontSize={FontSize.small}>
            {getAppName(AppName.base)} complies with applicable Federal civil rights laws and does
            not discriminate on the basis of race, color, national origin, age, disability, or sex.
          </Typography>
        </FlexBox>
      </FlexBox>
      {goToVisitPay ? <VisitPayWebView closePopup={setGoToVisitPay} uri={VISIT_PAY_HOME} /> : null}
    </ScrollableLayout>
  );
};

export default FAFAQ;
