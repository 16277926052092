import React, { useMemo } from 'react';
import { MuiBox, MuiPaper, MuiIconButton } from 'theme/material-ui';
import Svg from 'components/UI/Svg/Svg';
import { IconSize } from 'modules/styles/variables';
import { SidePanel, PanelTab } from './styled';

export interface Props {
  id: string;
  open: boolean;
  onTabClick: () => void;
  children: React.ReactNode;
  anchor: 'left' | 'right' | 'top' | 'bottom';
  variant: 'permanent' | 'temporary';
  width?: number | string;
  height?: number | string;
}

function ConnectCareSlidingPanel({
  onTabClick,
  children,
  anchor,
  variant,
  open: openProp,
  id,
  ...rest
}: Props) {
  const open = useMemo(() => {
    return variant === 'temporary' ? openProp : true;
  }, [openProp, variant]);

  const name = useMemo(() => {
    switch (anchor) {
      case 'left':
        return open ? 'arrow-left' : 'arrow-right';
      case 'right':
        return open ? 'arrow-right' : 'arrow-left';
      case 'top':
        return open ? 'arrow-drop-up' : 'arrow-drop-down';
      case 'bottom':
        return open ? 'arrow-drop-down' : 'arrow-drop-up';
      default:
        return '';
    }
  }, [open, anchor]);

  return (
    <SidePanel
      id={id}
      aria-label="drawer"
      aria-labelledby="panel-tab"
      open={open}
      anchor={anchor}
      variant={variant}
      {...rest}
    >
      {variant === 'temporary' ? (
        <PanelTab elevation={3} square>
          <MuiIconButton
            aria-label="panel-tab"
            id="panel-tab"
            onClick={onTabClick}
            role="button"
            aria-controls={id}
            aria-expanded={open ? 'true' : 'false'}
          >
            <Svg name={name} set="material" size={IconSize.base} />
          </MuiIconButton>
        </PanelTab>
      ) : null}
      <MuiPaper elevation={open ? 3 : 0} square>
        <MuiBox py={3} px={3}>
          {children}
        </MuiBox>
      </MuiPaper>
    </SidePanel>
  );
}

ConnectCareSlidingPanel.defaultProps = {
  anchor: 'left',
  variant: 'temporary',
  id: 'connect-care-sliding-panel'
};

export default ConnectCareSlidingPanel;
