import React from 'react';
import styled from 'styled-components';
import Headline from 'components/UI//Typography/Headline';
import Panel from 'components/UI/Panel/Panel';
import Paragraph from 'components/UI/Typography/Paragraph';
import StyledButton from 'components/UI//Button/StyledButton';
import { SearchTopic } from 'store/cost/reducers';
import { convertToLowerKabobCase } from 'modules/utils/StringUtils';

interface Props {
  onPress: (result: SearchTopic) => void;
  result: SearchTopic;
}

const SearchResultsItem = ({ onPress, result }: Props) => {
  return (
    <Panel data-testid={convertToLowerKabobCase(result.name)}>
      <StyledHeadline>{result.name}</StyledHeadline>
      <StyledParagraph>{result.description}</StyledParagraph>
      <StyledButton onPress={() => onPress(result)}>Find Providers</StyledButton>
    </Panel>
  );
};

const StyledHeadline = styled(Headline)`
  text-align: center;
`;

const StyledParagraph = styled(Paragraph)`
  text-align: left;
  padding: 20px;
  max-width: 100%;
`;

export default SearchResultsItem;
