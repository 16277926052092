import React, { useEffect, useState } from 'react';
import { RouteComponentProps, StaticContext } from 'react-router';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';

import {
  MuiBox,
  MuiContainer,
  MuiGrid,
  MuiTypography,
  MuiPaper,
  dfdDefaultTheme
} from 'theme/material-ui';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Svg from 'components/UI/Svg/Svg';
import { Alert } from 'components/Alert';
import { useSnack } from 'components/Snack';
import { Map, US_STATES, USState } from 'components/Map';
import { sideBarWidthOpen } from 'components/AuthLayout/styled';
import { SpinnerOverlay } from 'components/UI/Spinner/SpinnerModal';
import {
  ConnectCareStepActions,
  ConnectCareStepAction,
  ConnectCareAutocomplete,
  ConnectCareSlidingPanel
} from 'components/ConnectCare';

import { Color } from 'modules/styles/colors';
import { FontSize, FontWeight, IconSize } from 'modules/styles/variables';

import Logger from 'services/Logger';
import { geoCodeLocation } from 'services/LocationService/GoogleApis';
import { GeoCodeLocation } from 'services/LocationService/types';
import { Coordinate } from 'modules/types/common';
import { RootState, RootDispatch } from 'store/types';
import {
  Consumer,
  HealthDocumentRecord,
  UpdateConsumerLegalResidencePayload
} from 'store/amwell/types';

import * as selectors from 'store/amwell/selectors';
import * as geolocationSelectors from 'store/geolocation/selectors';
import { getCurrentPosition } from 'store/geolocation/actions';
import {
  getHealthDocuments,
  updateConsumerLegalResidence,
  setNewStartedFlowTimeStamp
} from 'store/amwell/actions';

import { RouteData } from '../types';
import { NO_LOCATION, ERROR, GEOLOCATION } from './constants';
import { FormScreen } from '../styled';

const getGeolocationStateCode = (geolocation?: GeoCodeLocation) => {
  if (!geolocation) return '';

  const geolocationState = geolocation.address_components.find(
    ({ types }) => types[0] === 'administrative_area_level_1'
  );

  return geolocationState ? geolocationState.short_name : '';
};

const getConsumerStateCode = (consumer: Consumer | null) => {
  if (!consumer) return '';

  const consumerLocation = consumer.legalResidence;

  return consumerLocation ? consumerLocation.code : '';
};

const initSelection = (stateCode: string) => {
  if (!stateCode) return null;

  return US_STATES.find(usState => usState.value === stateCode) ?? null;
};

export interface ConnectCareLocationSelectProps
  extends RouteComponentProps<{}, StaticContext, { fromAppointment?: boolean }> {
  geolocationAccepted: boolean;
  geolocationCoords: Coordinate;
  geolocationRequested: boolean;
  consumer: Consumer | null;
  updateLegalResidence: (payload: UpdateConsumerLegalResidencePayload) => Promise<AnyAction>;
  getCurrentPosition: () => void;
  healthDocuments: HealthDocumentRecord[];
  setStartedFlowTimeStamp: typeof setNewStartedFlowTimeStamp;
  startedFlowTimeStamp: typeof selectors.startedFlowTimeStampSelector;
  getHealthDocuments: typeof getHealthDocuments;
}

export function ConnectCareLocationSelect({
  location,
  history,
  geolocationAccepted,
  geolocationCoords,
  geolocationRequested,
  consumer,
  updateLegalResidence,
  getCurrentPosition,
  setStartedFlowTimeStamp,
  startedFlowTimeStamp
}: ConnectCareLocationSelectProps) {
  const { create } = useSnack();
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [pageLoading, setPageLoading] = useState(true);
  const [nextLoading, setNextLoading] = useState(false);
  const [selection, setSelection] = useState<USState | null>(null);
  const isSmallScreen = useMediaQuery(dfdDefaultTheme.breakpoints.down('sm'));

  useEffect(() => {
    // we don't want to change timeStamp every single time we land here on a single flow.
    // user can go back to this screen, but timeStamp will be null when entering a new CC flow.
    if (startedFlowTimeStamp !== null) return;

    setStartedFlowTimeStamp();
  }, []);

  useEffect(() => {
    if (!geolocationAccepted || !geolocationCoords) {
      getCurrentPosition();
    }
  }, []);

  useEffect(() => {
    function initLocation() {
      const consumerStateCode = getConsumerStateCode(consumer);

      if (!geolocationAccepted || !geolocationCoords) {
        setSelection(initSelection(consumerStateCode));
        setPageLoading(false);

        return;
      }

      const { latitude } = geolocationCoords;
      const { longitude } = geolocationCoords;

      const address = `${latitude},${longitude}`;

      geoCodeLocation(address)
        .then(res => {
          const geolocationStateCode = getGeolocationStateCode(res[0]);

          if (consumerStateCode && geolocationStateCode) {
            setSelection(initSelection(consumerStateCode));

            if (consumerStateCode !== geolocationStateCode) {
              Alert.alert(
                GEOLOCATION.title,
                GEOLOCATION.subtitle,
                [
                  { text: 'No' },
                  {
                    text: 'Yes',
                    onPress: () => setSelection(initSelection(geolocationStateCode))
                  }
                ],
                { closeIcon: true, cancelable: true }
              );
            }
          } else if (consumerStateCode) {
            setSelection(initSelection(consumerStateCode));
          } else if (geolocationStateCode) {
            setSelection(initSelection(geolocationStateCode));
          }

          Alert.alert(NO_LOCATION.title, NO_LOCATION.subtitle, [{ text: 'Ok' }], {
            cancelable: true
          });

          setSelection(null);
          setPageLoading(false);
        })
        .catch(e => {
          Logger.error('geoCodeLocation error', e);
          setSelection(initSelection(consumerStateCode));
          setPageLoading(false);
        });
    }

    if (geolocationRequested) {
      initLocation();
    }
  }, [geolocationAccepted, geolocationCoords, geolocationRequested]);

  const onPrevClick = () => history.push('/u/get-care-now/connect-care');

  const onNextClick = (nextStep?: RouteData) => {
    if (!selection) return;

    setNextLoading(true);

    updateLegalResidence({ stateCode: selection.value }).then((res: AnyAction) => {
      setNextLoading(false);

      if (res.error) return create(ERROR.title, 'error');

      if (location?.state?.fromAppointment)
        return history.push('/u/get-care-now/connect-care/form/payment-information');

      if (nextStep) return history.push(nextStep.path);

      return null;
    });
  };

  return (
    <>
      <SpinnerOverlay
        backgroundColor={Color.translucent}
        isLoading={pageLoading}
        position="absolute"
        testID="cc-locations-loading"
      />

      <FormScreen>
        <MuiBox
          left={0}
          position="relative"
          pt={isSmallScreen ? 0 : 1}
          top={0}
          width={isSmallScreen ? '100%' : sideBarWidthOpen}
        >
          <MuiPaper elevation={3} square>
            <MuiBox
              display="flex"
              flexDirection="column"
              justifyContent="center"
              mb={1}
              minHeight={93}
              px={2}
              py={2}
            >
              {selection ? (
                <>
                  <MuiTypography variant="subtitle2" color={Color.textLight}>
                    Select State Currently Located:
                  </MuiTypography>
                  <MuiTypography variant="h4" color={Color.primary}>
                    {selection.label}
                  </MuiTypography>
                </>
              ) : (
                <MuiTypography variant="h5" color={Color.textLight}>
                  Please select a State
                </MuiTypography>
              )}
            </MuiBox>
          </MuiPaper>

          <ConnectCareSlidingPanel
            onTabClick={() => setDrawerOpen(!drawerOpen)}
            open={drawerOpen}
            variant={isSmallScreen ? 'permanent' : 'temporary'}
            width={isSmallScreen ? '100%' : sideBarWidthOpen}
          >
            <MuiGrid container spacing={2}>
              <MuiGrid item xs={12}>
                <ConnectCareAutocomplete
                  autoHighlight
                  clearOnEscape
                  id="location-select-autocomplete"
                  label="Search for a State"
                  onChange={(_, inputValue: USState | null) => setSelection(inputValue)}
                  options={US_STATES.slice().sort((a, b) => a.label.localeCompare(b.label))}
                  placeholder="State of Residence"
                  value={selection}
                />
              </MuiGrid>

              <MuiGrid item xs={12}>
                <MuiBox p={1} bgcolor={Color.grayLight3} borderRadius={3}>
                  <MuiBox display="flex" flexDirection="row">
                    <MuiBox paddingRight={1}>
                      <Svg set="assets" name="InfoIcon" size={IconSize.base} color={Color.black} />
                    </MuiBox>

                    <MuiTypography fontSize={FontSize.small} fontWeight={FontWeight.normal}>
                      Select the state where you are currently physically located. We need to ensure
                      you are evaluated by a provider licensed in that state.
                    </MuiTypography>
                  </MuiBox>
                </MuiBox>
              </MuiGrid>
            </MuiGrid>
          </ConnectCareSlidingPanel>
        </MuiBox>

        <MuiContainer maxWidth="lg">
          <MuiBox display="flex" justifyContent="center" py={3}>
            <Map
              onClick={(_, usState) => setSelection(usState)}
              readOnly={isSmallScreen}
              size={isSmallScreen ? 500 : 650}
              value={selection}
            />
          </MuiBox>
        </MuiContainer>
      </FormScreen>

      <ConnectCareStepActions>
        <ConnectCareStepAction onClick={onPrevClick} label="Home" />

        <ConnectCareStepAction
          disabled={!selection || pageLoading}
          loading={nextLoading}
          onClick={onNextClick}
        />
      </ConnectCareStepActions>
    </>
  );
}

const mapStateToProps = (state: RootState) => ({
  consumer: selectors.consumerDataSelector(state),
  geolocationAccepted: geolocationSelectors.geolocationAcceptedSelector(state),
  geolocationCoords: geolocationSelectors.geolocationCoordsSelector(state),
  geolocationRequested: geolocationSelectors.geolocationRequestedSelector(state),
  healthDocuments: selectors.healthDocumentsSelector(state),
  startedFlowTimeStamp: selectors.startedFlowTimeStampSelector(state)
});

const mapDispatchToProps = (dispatch: RootDispatch) => ({
  getCurrentPosition: () => dispatch(getCurrentPosition()),
  setStartedFlowTimeStamp: () => dispatch(setNewStartedFlowTimeStamp()),
  updateLegalResidence: (payload: UpdateConsumerLegalResidencePayload) =>
    dispatch(updateConsumerLegalResidence(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(ConnectCareLocationSelect);
