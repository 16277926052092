import React from 'react';

export default {
  svg: (
    <g
      id="GC-Get-Care-Now---Emotional-Support---Web"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="GC-Get-Care-Emotional-Support"
        transform="translate(-964.000000, -486.000000)"
        fill="#107686"
      >
        <path
          d="M969.333333,496.8 L972,496.8 L972,499.2 L969.333333,499.2 L969.333333,496.8 Z M988,490.8 L988,507.6 C988,508.92 986.8,510 985.333333,510 L966.666667,510 C965.186667,510 964,508.92 964,507.6 L964.013333,490.8 C964.013333,489.48 965.186667,488.4 966.666667,488.4 L968,488.4 L968,486 L970.666667,486 L970.666667,488.4 L981.333333,488.4 L981.333333,486 L984,486 L984,488.4 L985.333333,488.4 C986.8,488.4 988,489.48 988,490.8 Z M966.666667,493.2 L985.333333,493.2 L985.333333,490.8 L966.666667,490.8 L966.666667,493.2 Z M985.333333,507.6 L985.333333,495.6 L966.666667,495.6 L966.666667,507.6 L985.333333,507.6 Z M980,499.2 L982.666667,499.2 L982.666667,496.8 L980,496.8 L980,499.2 Z M974.666667,499.2 L977.333333,499.2 L977.333333,496.8 L974.666667,496.8 L974.666667,499.2 Z"
          id="Shape"
        ></path>
      </g>
    </g>
  ),
  viewBox: '0 0 24 24'
};
