import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { RootState } from 'store/types';

import { MuiDialog, MuiDialogContent, MuiBox, MuiToolbar, MuiIconButton } from 'theme/material-ui';

import { Color } from 'modules/styles/colors';

import Svg from 'components/UI/Svg/Svg';
import { ConnectCareProviderDetails, ProviderProfessionalSummary } from 'components/ConnectCare';

import * as selectors from 'store/amwell/selectors';
import * as actions from 'store/amwell/actions';
import { getVisibilityAttr } from 'store/amwell/utils';
import { EstimatedVisitCost, Provider, ProviderDetails } from 'store/amwell/types';

import { AppBar, DialogTitle } from './styled';

export interface Props {
  getProviderDetail: typeof actions.getProviderDetail;
  getEstimatedVisitCost: typeof actions.getEstimatedVisitCost;
  estimatedVisitCost: EstimatedVisitCost | null;
  estimatedVisitCostLoading: boolean;
  estimatedVisitCostError: Error | null;
  isOpen: boolean;
  providerDetailsLoading: boolean;
  providerDetailsError: Error | null;
  onClose: () => void;
  providerDetails: ProviderDetails | null;
  provider: Provider;
}

export function ConnectCareProviderProfile(props: Props) {
  const {
    getProviderDetail,
    getEstimatedVisitCost,
    onClose,
    providerDetailsLoading,
    providerDetailsError,
    isOpen,
    providerDetails,
    provider,
    estimatedVisitCost,
    estimatedVisitCostLoading,
    estimatedVisitCostError
  } = props;

  const visibilityAttr = getVisibilityAttr(provider);
  const isLoading = providerDetailsLoading || estimatedVisitCostLoading;

  useEffect(() => {
    async function init() {
      const res: AnyAction = await getProviderDetail({ provider });
      if (res.payload) {
        getEstimatedVisitCost({ providerDetails: res.payload });
      }
    }

    if (isOpen) {
      init();
    }
  }, [isOpen]);

  return (
    <MuiDialog
      fullScreen
      open={isOpen}
      onClose={onClose}
      aria-labelledby="connect-care-provider-profile-dialog"
    >
      <AppBar position="static">
        <MuiToolbar>
          <DialogTitle color={Color.white} id="connect-care-provider-profile-dialog-title">
            Provider Profile
          </DialogTitle>
          <MuiIconButton edge="end" color="inherit" onClick={onClose} aria-label="close">
            <Svg color={Color.white} set="material" name="close" />
          </MuiIconButton>
        </MuiToolbar>
        <MuiBox
          width="100%"
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
        >
          <MuiBox width="100%" maxWidth={900} p={2}>
            <ConnectCareProviderDetails
              getEstimatedVisitCost={getEstimatedVisitCost}
              isLoading={isLoading}
              estimatedVisitCostError={estimatedVisitCostError}
              estimatedVisitCost={estimatedVisitCost}
              providerDetailsError={providerDetailsError}
              providerDetails={providerDetails}
              visibilityAttr={visibilityAttr}
              isWaitingRoom={false}
            />
          </MuiBox>
        </MuiBox>
      </AppBar>
      <MuiDialogContent>
        <MuiBox display="flex" justifyContent="center" width="100%">
          <MuiBox maxWidth={900} width="100%">
            <ProviderProfessionalSummary
              providerDetails={providerDetails}
              providerDetailsLoading={isLoading}
              providerDetailsError={providerDetailsError}
              isWaitingRoom={false}
            />
          </MuiBox>
        </MuiBox>
      </MuiDialogContent>
    </MuiDialog>
  );
}

const mapStateToProps = (state: RootState) => ({
  // provider details
  providerDetails: selectors.providerDetailsDataSelector(state),
  providerDetailsLoading: selectors.providerDetailsDataLoadingSelector(state),
  providerDetailsError: selectors.providerDetailsDataErrorSelector(state),
  // estimated visit cost
  estimatedVisitCost: selectors.estimatedVisitCostDataSelector(state),
  estimatedVisitCostLoading: selectors.estimatedVisitCostDataLoadingSelector(state),
  estimatedVisitCostError: selectors.estimatedVisitCostDataErrorSelector(state)
});

const mapDispatch = {
  getProviderDetail: actions.getProviderDetail,
  getEstimatedVisitCost: actions.getEstimatedVisitCost
};

export default connect(mapStateToProps, mapDispatch)(ConnectCareProviderProfile);
