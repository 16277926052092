import brandingBackgroundMobile from 'assets/backgrounds/ih-login-bg-1080x1920.svg';
import brandingBackground from 'assets/backgrounds/ih-login-bg-2052x1152.svg';
import FlexBox from 'components/UI/Layout/FlexBox';
import React from 'react';
import { isMobileBrowser } from 'lib/browser';
import styled from 'styled-components';

const LandingImageBackground = styled(FlexBox)<{ isSmallScreen?: boolean }>`
  display: 'flex';
  flex: 1;
  background: url(${props =>
    props.isSmallScreen || isMobileBrowser()
      ? `${brandingBackgroundMobile}`
      : `${brandingBackground}`});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

interface SupergraphicBackgroundProps {
  isSmallScreen?: boolean;
  children?: React.ReactNode;
}
const SupergraphicBackground = ({ children, isSmallScreen }: SupergraphicBackgroundProps) => {
  return <LandingImageBackground isSmallScreen={isSmallScreen}>{children}</LandingImageBackground>;
};

export default SupergraphicBackground;
