import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { Color } from 'modules/styles/colors';
import { Spacing } from 'modules/styles/variables';
import {
  dfdDefaultTheme,
  MuiBadge,
  MuiDivider,
  MuiListItem,
  MuiListItemIcon,
  withStyles
} from 'theme/material-ui';

export const SidebarLink = styled(props => <Link {...props} />)`
  &&& {
    pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
    text-decoration: none;
    color: ${props => (props.disabled ? Color.grayHue5 : Color.black)};
    flex-direction: row;
    &:focus,
    &:active,
    &:visited,
    &:hover {
      text-decoration: none;
      color: ${Color.black};
    }
  }
`;

export const SidebarMuiBadge = withStyles({
  badge: {
    right: `calc(${Spacing.medium}px + 2px)`,
    top: `${dfdDefaultTheme.spacing(2.5)}px`,
    backgroundColor: Color.secondary,
    color: Color.white
  }
})(MuiBadge);

export const SidebarMuiListIcon = styled(MuiListItemIcon)`
  &&& {
    min-width: 36px;
  }
`;

export const SidebarMuiListItem = styled(MuiListItem)`
  &&& {
    padding: ${Spacing.smallMedium}px 0 ${Spacing.smallMedium}px ${Spacing.xLarge}px;
    transition: 0.5s ease all;
  }
`;

export const SidebarResourceListItem = styled(MuiListItem)`
  &&& {
    padding: ${Spacing.medium}px 0 ${Spacing.medium}px ${Spacing.xLarge}px;
  }
`;

export const HelpDivider = styled(MuiDivider)`
  && {
    &.MuiDivider-root {
      margin-right: ${Spacing.xLarge}px;
    }
  }
`;
