import React from 'react';

export default {
  svg: (
    <g>
      <g>
        <g>
          <circle fill="#E9E6FC" strokeWidth={0} cx={40} cy={40.5} r={38.5} />
          <path
            fill="#FFFFFF"
            strokeWidth={0}
            d="M40,3c20.711,0,37.5,16.789,37.5,37.5S60.711,78,40,78S2.5,61.211,2.5,40.5S19.289,3,40,3 M40,1 C18.22,1,0.5,18.72,0.5,40.5S18.22,80,40,80s39.5-17.72,39.5-39.5S61.78,1,40,1L40,1z"
          />
        </g>
      </g>
      <g>
        <defs>
          <circle id="SVGID_9_" cx={40} cy={40.5} r={37.5} />
        </defs>
        <clipPath id="SVGID_2_20">
          <use xlinkHref="#SVGID_9_" />
        </clipPath>
        <g clipPath="url(#SVGID_2_20)">
          <path
            fill="#8F6642"
            strokeWidth={0}
            d="M56.37,41.894l0.524-6.811c0-8.906-8.513-16.123-17.412-16.123c-8.906,0-17.412,7.216-17.412,16.123 l0.523,6.804c-0.498,0.12-0.948,0.349-1.323,0.67c-0.697,0.593-1.135,1.47-1.135,2.457v3.353c0,1.773,1.428,3.211,3.205,3.223 c0,5.264,3.959,10.356,8.401,13.429v7.084l-0.516,0.116l-1.258,0.929V94.25h1.773v3.328h15.478V94.25h1.767V72.575l-1.251-0.335 l-0.516-0.135v-7.08c4.444-3.073,8.406-8.166,8.406-13.432c1.77-0.012,3.202-1.449,3.202-3.222v-3.354 C58.825,43.501,57.777,42.24,56.37,41.894z"
          />
          <path
            fill="#EE9D25"
            strokeWidth={0}
            d="M14.526,107.477c-0.052,0.064-5.159,0-14.833,0c1.109-6.346,4.031-18.219,6.179-22.894 c2.838-6.191,11.441-7.713,22.855-11.647c0.406-0.142,0.819,0.361,1.238,0.213c0.8,4.534,1.773,9.912,9.512,9.912 c7.7,0,8.7-5.959,9.506-10.486c0.413,0.148,0.838,0.219,1.245,0.361c11.415,3.934,20.018,5.456,22.862,11.647 c2.148,4.676,6.113,16.549,7.216,22.894c-5.804,0-7.094,0-16.123,0"
          />
          <path
            fill="#F5C47C"
            strokeWidth={0}
            d="M50.229,72.936c-0.277,1.548-0.6,3.36-1.245,5.075c-1.264,3.347-3.753,6.339-9.506,6.339 c-5.765,0-8.255-3.012-9.512-6.372c-0.638-1.709-0.961-3.502-1.238-5.043c0.406-0.142,2.496-0.716,2.496-0.716l0.039,0.219 c0.967,5.469,2.122,9.332,8.216,9.332s7.249-3.863,8.216-9.332l0.039-0.2C47.733,72.24,49.823,72.794,50.229,72.936z"
          />
          <path
            fill="#8A5636"
            strokeWidth={0}
            d="M56.37,41.894l0.524-6.811c0-8.906-8.513-16.123-17.412-16.123c-0.003,0-0.003,22.894-0.003,22.894 c-2.352,5.003-3.869,12.253-3.869,12.253h3.869v13.865c-2.202-0.001-4.944-1.065-7.54-2.82v2.102l15.475,4.621l0.002-6.994 c-0.005,0.004-0.011,0.007-0.016,0.011c4.37-3.085,8.224-8.108,8.224-13.301c1.77-0.012,3.202-1.449,3.202-3.222v-3.354 C58.825,43.501,57.777,42.24,56.37,41.894z M39.479,67.973c0.004,0,0.009-0.001,0.013-0.001c-0.004,0-0.008,0.001-0.011,0.001 C39.48,67.973,39.479,67.973,39.479,67.973z M46.35,65.59c0.021-0.013,0.043-0.026,0.064-0.04 C46.392,65.564,46.371,65.577,46.35,65.59z M40.268,67.925c0.07-0.007,0.141-0.015,0.212-0.024 C40.409,67.91,40.339,67.918,40.268,67.925z M41.295,67.763c0.033-0.007,0.067-0.017,0.101-0.025 C41.362,67.746,41.328,67.756,41.295,67.763z M42.177,67.531c0.069-0.021,0.138-0.041,0.208-0.063 C42.316,67.491,42.247,67.511,42.177,67.531z M43.274,67.156c0.032-0.013,0.064-0.027,0.097-0.04 C43.339,67.129,43.306,67.143,43.274,67.156z M44.236,66.741c0.048-0.022,0.096-0.044,0.144-0.067 C44.332,66.697,44.284,66.718,44.236,66.741z M45.341,66.183c0.019-0.01,0.037-0.021,0.056-0.032 C45.378,66.161,45.359,66.172,45.341,66.183z"
          />
          <g>
            <g>
              <path
                fill="#78482E"
                strokeWidth={0}
                d="M34.607,43.713c0.049,1.406-1.051,2.585-2.456,2.634c-1.406,0.049-2.585-1.051-2.634-2.456 c-0.049-1.406,1.051-2.585,2.456-2.634C33.379,41.208,34.558,42.307,34.607,43.713z"
              />
              <path
                fill="#3C2417"
                strokeWidth={0}
                d="M34.607,43.068c0.049,1.406-1.051,2.585-2.456,2.634c-1.406,0.049-2.585-1.051-2.634-2.456 c-0.049-1.406,1.051-2.585,2.456-2.634C33.379,40.563,34.558,41.663,34.607,43.068z"
              />
              <path
                fill="#FFFFFF"
                strokeWidth={0}
                d="M33.563,42.34c0.012,0.351-0.263,0.646-0.614,0.659c-0.351,0.012-0.646-0.263-0.659-0.614 c-0.012-0.351,0.263-0.646,0.614-0.659C33.256,41.714,33.551,41.989,33.563,42.34z"
              />
            </g>
            <g>
              <path
                fill="#78482E"
                strokeWidth={0}
                d="M49.44,43.713c0.049,1.406-1.051,2.585-2.456,2.634c-1.406,0.049-2.585-1.051-2.634-2.456 c-0.049-1.406,1.051-2.585,2.456-2.634C48.212,41.208,49.391,42.307,49.44,43.713z"
              />
              <path
                fill="#3C2417"
                strokeWidth={0}
                d="M49.44,43.068c0.049,1.406-1.051,2.585-2.456,2.634c-1.406,0.049-2.585-1.051-2.634-2.456 c-0.049-1.406,1.051-2.585,2.456-2.634C48.212,40.563,49.391,41.663,49.44,43.068z"
              />
              <path
                fill="#FFFFFF"
                strokeWidth={0}
                d="M48.396,42.34c0.012,0.351-0.263,0.646-0.614,0.659c-0.351,0.012-0.646-0.263-0.659-0.614 c-0.012-0.351,0.263-0.646,0.614-0.659C48.089,41.714,48.383,41.989,48.396,42.34z"
              />
            </g>
          </g>
          <path
            fill="#FFFFFF"
            strokeWidth={0}
            d="M45.216,57.99c0,2.849-2.599,5.159-5.804,5.159s-5.804-2.31-5.804-5.159H45.216z"
          />
          <path
            fill="#221F1F"
            strokeWidth={0}
            d="M39.481,15c-23.696,0.364-20.118,20.571-18.123,29.216c0.17,0.734,1.243,0.626,1.272-0.127 c0.183-4.835,1.306-14.07,6.171-13.362c4.646,0.676,8.09,0.906,10.68,0.886c2.589,0.019,6.034-0.21,10.68-0.886 c4.865-0.708,5.988,8.528,6.171,13.362c0.028,0.753,1.102,0.862,1.272,0.127C59.599,35.571,63.177,15.364,39.481,15"
          />
          <path
            fill="#43414D"
            strokeWidth={0}
            d="M57.538,39.426h-3.516c-1.103-0.578-2.647-0.947-4.808-0.947c-3.585,0-6.021,0.686-7.275,2.024 c-0.592-0.323-1.469-0.687-2.459-0.687c-0.99,0-1.867,0.363-2.459,0.687c-1.254-1.339-3.69-2.024-7.275-2.024 c-2.161,0-3.704,0.369-4.808,0.947h-3.516v2.514l1.154,0.405c-0.118,0.472-0.166,0.949-0.166,1.411c0,3.793,1.2,5.988,7.335,5.988 c5.183,0,7.238-1.478,8.145-5.857c0.111-0.536,0.123-1.047,0.049-1.526c0.287-0.183,0.759-0.343,1.54-0.343 c0.781,0,1.254,0.16,1.54,0.343c-0.074,0.479-0.062,0.99,0.049,1.526c0.908,4.379,2.963,5.857,8.145,5.857 c6.135,0,7.335-2.195,7.335-5.988c0-0.461-0.048-0.939-0.166-1.411l1.154-0.405V39.426z M36.63,43.625 c-0.786,3.791-2.265,4.829-6.883,4.829c-5.625,0-6.046-1.86-6.046-4.698c0-0.986,0-3.342,6.046-3.342 c4.171,0,5.865,0.787,6.51,1.579C36.713,42.553,36.805,42.778,36.63,43.625z M49.215,48.454c-4.617,0-6.097-1.038-6.883-4.829 c-0.175-0.846-0.083-1.072,0.373-1.632c0.645-0.792,2.338-1.579,6.51-1.579c6.046,0,6.046,2.355,6.046,3.342 C55.261,46.594,54.84,48.454,49.215,48.454z"
          />
        </g>
      </g>
    </g>
  ),
  viewBox: '0 0 80 80'
};
