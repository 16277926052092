import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import SmallLabel from 'components/UI/Labels/SmallLabel';
import { Row } from 'components/UI/Layout/Container';
import ImagePanel from 'components/UI/Layout/ImagePanel';
import Panel from 'components/UI/Panel/Panel';
import BodyText from 'components/UI/Typography/BodyText';
import DefaultText from 'components/UI/Typography/DefaultText';
import Heading from 'components/UI/Typography/Heading';
import Subhead from 'components/UI/Typography/Subhead';
import Title from 'components/UI/Typography/Title';
import { Color } from 'modules/styles/colors';
import { BorderRadius, FontSize, Spacing } from 'modules/styles/variables';
import styled from 'styled-components';
import {
  dfdDefaultTheme,
  MuiBox,
  MuiInputBase,
  MuiLinearProgress,
  MuiTextField,
  MuiTypography
} from 'theme/material-ui';
import { GooglePlacesInput } from 'components/profile/AddressValidation/AddressValidation';
import StyledButton from 'components/UI/Button/StyledButton';

export const StyledMuiBox = styled(MuiBox)<{ paddingLeft?: string }>`
  background: ${Color.white};
  padding-left: ${props => (props.paddingLeft ? props.paddingLeft : '0px')};
  flex-direction: column;
  height: 53px;
  border-radius: 28px;
  width: 100%;
  display: flex;
  overflow: hidden;
`;
export const StyledInputBase = styled(MuiInputBase)`
  background: ${Color.white};
  flex: 1 1 auto;
  display: flex;
  &.MuiInputBase-root {
    border-right: 1px solid ${Color.grayLight};
    font-size: 0.95rem;
  }
  &.MuiInputBase-input {
    flex: 1 1 auto;
    display: flex;
    font-size: 1.1rem;
    padding-left: 0;
    flex-direction: column;
  }
`;
export const StyledGeoLocation = styled(GooglePlacesInput)`
  background: ${Color.white};
  &.MuiInputBase-root {
    max-width: 50%;
    flex: 1 1 auto;
    display: flex;
  }
  &.MuiInputBase-input {
    flex: 1 1 auto;
    display: flex;
    font-size: 1.1rem;
    padding-left: 0;
    flex-direction: column;
  }
`;
export const StyledImageBackground = styled.div`
  height: 200;
  width: 100%;
`;

export const StyledImagePanel = styled(ImagePanel)`
  border: 0px;
`;

export const CenteredHeading = styled(Heading)`
  text-align: center;
`;

export const StyledIcon = styled.div`
  padding: ${Spacing.none}px;
  margin: ${Spacing.none}px;
`;

export const StyledText = styled.div`
  font-size: 30;
  text-align: center;
  color: ${Color.foreColor};
`;

export const BlueBox = styled.div`
  background-color: rgba(27, 59, 137, 0.5);
  height: 100%;
  align-items: center;
  color: ${Color.foreColor};
  justify-content: center;
`;

export const CenteredSmallLabel = styled(SmallLabel)`
  text-align: center;
`;

export const StyledPanel = styled(Panel)`
  border-top-left-radius: ${BorderRadius.none};
  border-top-right-radius: ${BorderRadius.none};
`;

export const CenteredDefaultText = styled(DefaultText)`
  text-align: center;
`;

export const BoldDefaultText = styled(DefaultText)`
  font-weight: bold;
  margin: ${Spacing.medium}px;
`;

export const StyledTitle = styled(Title)`
  color: ${Color.foreColor};
  text-align: center;
  padding: ${Spacing.none}px ${Spacing.medium}px;
`;

export const Container = styled.div`
  margin-bottom: ${Spacing.medium}px;
  flex: 1;
  height: 100%;
  width: 100%;
`;

export const StyledView = styled.div`
  margin-bottom: ${Spacing.medium}px;
`;

export const DescriptionText = styled(BodyText)`
  margin-bottom: ${Spacing.medium}px;
`;

export const StyledSubhead = styled(Subhead)`
  margin-top: ${Spacing.xLarge}px;
`;

export const StyledRow = styled(Row)`
  width: 100%;
  justify-content: space-between;
`;

export const SearchHeading = styled(Panel)`
  background: #e8ecf0;
  width: 100%;
`;

export const SearchButton = styled.button<{ borderRadius?: string }>`
  background: #1e4899;
  border-color: #1e4899;
  color: ${Color.white};
  border-radius: ${props => (props.borderRadius ? props.borderRadius : '0px 25px 25px 0px')};
  font-size: ${FontSize.large}px;
  margin: 0;
  padding: ${Spacing.large}px;
  width: 20%;
`;

export const SearchBoxGrid = styled(Grid)`
  left: 30px;
`;

export const RightSearchInput = styled(MuiTextField)`
  &.MuiTextField-root {
    border-bottom: none;
    padding: 15px 0px;
    color: ${Color.black};
    background: ${Color.white};
    width: 45%;
    padding-right: 45px;
    margin: 0;
    font-size: 15px;
  }
`;

export const LeftSearchInput = styled(MuiTextField)`
  &.MuiTextField-root {
    border-bottom: none;
    padding: 15px 0px;
    color: ${Color.black};
    background: ${Color.white};
    width: 45%;
    padding-left: 55px;
    border-radius: 25px 0 0 25px;
    font-size: 15px;
  }
`;

export const StyledCard = styled(Card)`
  width: 100%;
  text-align: left;
  border: 2px solid ${Color.grayLight3};
  box-shadow: none;
  padding: 8px 16px;
  cursor: pointer;

  /* ${dfdDefaultTheme.breakpoints.up('md')} {
    min-width: 500px;
  } */
`;
export const GuidedSearchCard = styled(Card)`
  width: 100%;
  text-align: left;
  border: 2px solid ${Color.grayLight3};
  box-shadow: none;
  cursor: pointer;
`;
export const PosTypography = styled(Typography)`
  color: grey;
`;

export const SearchStyledButton = styled(StyledButton)``;

export const SearchMuiBox = styled(MuiBox)<{ paddingLeft?: string }>`
  background: ${Color.grayLight};
  padding-left: ${props => (props.paddingLeft ? props.paddingLeft : '0px')};
  flex-direction: column;
  height: 53px;
  width: 100%;
  display: flex;
  overflow: hidden;
`;

export const StyledCardTitle = styled(MuiTypography)`
  &.MuiTypography-root {
    align-self: center;
    display: flex;
    font-size: 16px;
  }
`;

export const StyledCardBox = styled(MuiBox)`
  width: 100%;

  ${dfdDefaultTheme.breakpoints.up('md')} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
  }
`;

export const removeUnderline = makeStyles({
  underline: {
    '&&&:before': {
      borderBottom: 'none'
    },
    '&&:after': {
      borderBottom: 'none'
    }
  }
});
export const StyledLinearProgress = styled(MuiLinearProgress)`
  &.MuiLinearProgress-root {
    border-radius: 5px;
    height: 20px;
  }
`;
export const StyledLinearProgressLegend = styled.span`
  width: 14px;
  height: 14px;
  background: ${Color.primary};
  border: ${Color.primary} 1px solid;
  display: inline-block;
  content: [];

  border-radius: 3px;
`;
export const StyledMoneyText = styled.span`
  padding: 0px 5px;
  color: ${Color.primary};
`;
