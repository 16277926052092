import React from 'react';
import { Color } from 'modules/styles/colors';
import { SvgProps } from 'components/UI/Svg';

export default (props: SvgProps | undefined) => ({
  svg: (
    <g>
      <path
        fill={props?.color || Color.black}
        fillRule="evenodd"
        d="M12 1.5a6.75 6.75 0 0 0-6.75 6.75c0 .706.273 1.77.772 3.08.49 1.287 1.167 2.732 1.907 4.169 1.48 2.874 3.184 5.665 4.071 7.001l-.625.415L12 22.5l.625.415L12 22.5c.887-1.336 2.591-4.127 4.071-7.001.74-1.438 1.417-2.883 1.907-4.17.499-1.308.772-2.373.772-3.079A6.75 6.75 0 0 0 12 1.5Zm-5.834.916A8.25 8.25 0 0 1 20.25 8.25c0 .997-.361 2.278-.87 3.614-.518 1.358-1.222 2.857-1.975 4.321-1.508 2.928-3.24 5.766-4.155 7.145a1.5 1.5 0 0 1-2.5 0c-.914-1.379-2.647-4.217-4.155-7.145-.753-1.463-1.457-2.963-1.975-4.321-.509-1.336-.87-2.617-.87-3.614a8.25 8.25 0 0 1 2.416-5.834Zm2.652 2.652a4.5 4.5 0 1 1 6.364 6.364 4.5 4.5 0 0 1-6.364-6.364ZM12 5.25a3 3 0 1 0 0 6 3 3 0 0 0 0-6Z"
        clipRule="evenodd"
      />
    </g>
  ),
  viewBox: '0 0 24 24'
});
