import styled from 'styled-components';
import { KeyboardDatePicker } from '@material-ui/pickers';

import { Color } from 'modules/styles/colors';
import { Spacing, ZIndex } from 'modules/styles/variables';
import {
  dfdDefaultTheme,
  MuiCheckbox,
  MuiDialogTitle,
  MuiDrawer,
  MuiFormControlLabel,
  MuiGrid,
  MuiList,
  MuiRadio
} from 'theme/material-ui';

const filterWidth = 365;
const filterWidthXS = 320;

export const FilterCheckbox = styled(MuiCheckbox)`
  &.MuiCheckbox-colorSecondary.Mui-checked {
    color: ${Color.purple};
  }
`;

export const FilterDatePicker = styled(KeyboardDatePicker)`
  & > div.MuiOutlinedInput-adornedStart {
    padding-left: 0;
    height: 42px;
    ${dfdDefaultTheme.breakpoints.down('sm')} {
      height: auto;
    }
    & > div > button.MuiIconButton-root {
      padding: 12px;
      padding-right: 0;
    }
    & > input.MuiOutlinedInput-inputAdornedStart {
      padding-right: ${Spacing.small}px;
    }
  }

  & .MuiOutlinedInput.input {
    padding: ${Spacing.large}px;
  }
`;

export const FilterDrawer = styled(MuiDrawer)`
  z-index: ${ZIndex.high};
  margin: 0;
  & > .MuiPaper-root {
    box-shadow: -2px 0 10px 0 rgba(0, 0, 0, 0.5);
    width: ${filterWidth}px;
    ${dfdDefaultTheme.breakpoints.only('xs')} {
      width: ${filterWidthXS}px;
    }
  }
  & > .MuiBackdrop-root {
    display: none;
  }
  div.MuiExpansionPanel-root.Mui-expanded {
    margin: 0;
    padding: 0;
  }
  div.MuiExpansionPanelDetails-root {
    padding: ${Spacing.medium}px ${Spacing.large}px ${Spacing.medium}px 24px;
  }
`;

export const FilterFormControlLabel = styled(MuiFormControlLabel)`
  padding: ${Spacing.small}px 0;
  &:first-of-type {
    padding-top: ${Spacing.medium}px;
  }
  &:last-of-type {
    padding-bottom: ${Spacing.large}px;
  }
`;
// need to figure out drawer "bounce" issue

export const FilterList = styled(MuiList)`
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 0 0%;
  &.MuiList-padding {
    padding: 0;
    margin: 0;
    & + div.MuiPaper-root {
      padding: 0;
    }
  }
`;

export const FilterRadio = styled(MuiRadio)`
  &.MuiRadio-colorPrimary.Mui-checked {
    color: ${Color.purple};
  }
`;

export const FilterTitle = styled(MuiDialogTitle)`
  background-color: ${Color.baseDark};
  &.MuiDialogTitle-root {
    padding: 24px;
  }
`;

export const StyledGridItemOne = styled(MuiGrid)`
  &&& {
    margin-left: -${Spacing.medium}px;
    margin-right: ${Spacing.medium}px;
  }
`;

export const StyledGridItemTwo = styled(MuiGrid)`
  &&& {
    margin-left: 0px;
    margin-right: 0px;
  }
`;
