import React from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { useSelector } from 'react-redux';
import { ProviderFacetKeys, ProviderFacetGroups } from 'store/providerSearch/types';
import { facetsDataSelector } from 'store/providerSearch/selectors';
import { Confirm } from 'components/ConfirmDialog/ConfirmDialog';
import { FilterDrawer } from 'components/FilterDrawer';
import { useProviderSearchFilter } from 'lib/hooks/ProviderSearch/useProviderSearchFilters';
import { FiltersStructure } from 'lib/hooks/ProviderSearch/types';
import { hasLength } from 'modules/utils/ArrayUtils';
import { FormCheckboxList } from '../shared';
import { specialtiesViewableCount } from '../constants';
import { sortAlphabeticDescending } from 'lib/hooks/ProviderSearch/QuickLinks/utils';

export type SpecialtiesFilterProps = FiltersStructure['specialties'] & {
  defaultExpanded: boolean;
};

function SpecialtiesFilter({ title, values, defaultExpanded }: SpecialtiesFilterProps) {
  const specialtyKey = ProviderFacetKeys.specialty;
  const aliasValues = ProviderFacetGroups.insuranceAliasValues;

  const specialties = useSelector(facetsDataSelector)?.[specialtyKey] || [];
  const sortedSpecialties = sortAlphabeticDescending(specialties);
  const [field, helpers] = useProviderSearchFilter('facets');
  const facets = cloneDeep(field.value);

  return (
    <FilterDrawer.Panel
      expandable={specialties.length}
      title={title}
      defaultExpanded={defaultExpanded}
    >
      <FormCheckboxList
        initialCount={specialtiesViewableCount}
        list={Object.values(values(sortedSpecialties))}
        onChange={({ title }) => {
          if (title === 'Dentist' && hasLength(facets[aliasValues])) {
            Confirm.show(
              'Filter Updated',
              'Sorry, we cleared your selection "Accepts My Insurance" due to the specialty you have selected.',
              undefined,
              'info',
              {
                text: 'Okay',
                onClick: () => {
                  facets[aliasValues] = [];
                  helpers.setValue(facets);
                }
              },
              undefined,
              { closeIcon: false, cancelable: false }
            );
          }
        }}
      />
    </FilterDrawer.Panel>
  );
}

export default SpecialtiesFilter;
