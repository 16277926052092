import ExpandableList from 'components/UI/ExpandableList/ExpandableList';
import FlexBox from 'components/UI/Layout/FlexBox';
import ScrollableLayout from 'components/UI/Layout/ScrollableLayout';
import Spacer from 'components/UI/Layout/Spacer';
import Typography from 'components/UI/Typography';
import last from 'lodash/last';
import React, { ComponentType, useEffect, useMemo, useRef, useState } from 'react';
import { NavigationScreenProps } from 'screens/navigation';
import { Color } from 'modules/styles/colors';
import { Spacing } from 'modules/styles/variables';
import { MuiBox } from 'theme/material-ui';
import { vyncaQuestionsList, vyncaFaqCategories } from 'lib/constants/VyncaFaqConstants';
import useNavigationAnalytics from 'hooks/useNavigationAnalytics';
import { AnalyticsEvent } from 'services/AnalyticsService';
import styled from 'styled-components';

const LinkTypography = styled(Typography)`
  a:link,
  a:hover,
  a:active,
  a:visited {
    color: ${Color.link};
  }
`;

interface FaqSubTitle {
  bullets: string[];
  text: string[];
  subTitle?: string;
  title: string;
  clickLink: object;
}

interface FaqListItem {
  title: string;
  categoryId: string;
  subtitle: FaqSubTitle[];
}

const renderSubTitleText = (
  subTitle: FaqSubTitle,
  onLinkClick: (e: React.MouseEventHandler<HTMLDivElement>) => void
) =>
  subTitle.text.map((subTitleText: string) => (
    <Typography
      testID={subTitleText}
      dangerouslySetInnerHTML={{ __html: subTitleText }}
      onClick={onLinkClick}
    ></Typography>
  ));

const renderSubTitleBullets = (subTitle: FaqSubTitle) =>
  subTitle.bullets
    ? subTitle.bullets.map((bullet: string) => (
        <Typography
          style={{
            padding: Spacing.medium,
            paddingBottom: 0,
            display: 'flex'
          }}
        >
          <Typography>&bull; &nbsp;</Typography>
          <Typography testID={bullet}>{bullet}</Typography>
        </Typography>
      ))
    : null;

const renderClickLink = (subTitle: FaqSubTitle) => (
  <Typography
    style={{ textDecorationLine: 'underline', color: Color.link, float: 'left' }}
    onClick={() => window.open(`mailto:${subTitle.clickLink['link']}`)}
  >
    {subTitle.clickLink['link']}
  </Typography>
);

const FAQSList = ({ history }: NavigationScreenProps) => {
  const categoryId = last(history?.location?.pathname?.split('/'));

  const faqsList = useMemo(
    () => vyncaQuestionsList?.filter(question => question.categoryId === categoryId),
    [vyncaQuestionsList, categoryId]
  );

  const selectedFaqCategory = vyncaFaqCategories.find(category => category.id === categoryId);
  const faqQuestionClicked = useNavigationAnalytics(AnalyticsEvent.VyncaFAQQuestionClicked);
  const faqCategoryClicked = useNavigationAnalytics(AnalyticsEvent.VyncaFAQCategoryClicked);

  const initialActiveQuestion = useMemo(() => {
    const questionIndex = faqsList?.findIndex(
      q => q.title === history?.location?.state?.activeFaqQuestion
    );
    return questionIndex ? `${questionIndex}--initial` : undefined;
  }, [faqsList, history?.location?.state?.activeFaqQuestion]);

  const [activeQuestionId, setActiveQuestionId] = useState(undefined);

  const flatListRef = useRef(null);
  const handleOnClick = (selectedQuestion: string) => {
    faqQuestionClicked({
      title: `Vynca FAQ ${selectedFaqCategory?.title} Page`,
      vendor: 'Vynca',

      vynca_faq_question: selectedQuestion
    });
  };

  const onLinkClick = (event: React.MouseEventHandler<HTMLDivElement>) => {
    event?.preventDefault();

    if (event.target?.nodeName === 'A' && event.target?.href.includes('navigate-to-question')) {
      const targetQuestionCategory = last(event.target?.href?.split('/'));
      const isSameQuestionList = targetQuestionCategory === categoryId;

      if (isSameQuestionList) {
        const questionIndex = faqsList.findIndex(q => q.title === event.target?.title);
        setActiveQuestionId(`${questionIndex}--${Math.random()}`);

        flatListRef?.current?.scrollToIndex({ animated: true, index: questionIndex });

        // // To reset activeItemIndex. To enable user to autoscroll to the same element again
        setTimeout(() => setActiveQuestionId(undefined));
      } else {
        faqCategoryClicked({
          title: 'Vynca FAQ Home',
          vendor: 'Vynca',

          vynca_faq_category: targetQuestionCategory,
          vynca_faq_question: event?.target?.title
        });
        history.replace(`/u/health-tools/vynca/faqslist/${last(event.target?.href?.split('/'))}`, {
          categoryId: targetQuestionCategory,
          title: event.target?.title,
          activeFaqQuestion: event.target?.title
        });
      }
    }
  };

  useEffect(() => {
    setActiveQuestionId(initialActiveQuestion);
  }, [initialActiveQuestion]);

  return (
    <MuiBox
      display="flex"
      justifyContent="space-around"
      paddingTop={Spacing.small}
      style={{ backgroundColor: Color.foreColor }}
    >
      <MuiBox width="75%">
        <ScrollableLayout>
          <ExpandableList
            data={faqsList}
            emptyCaption="Empty Data"
            keyExtractor={(item: FaqListItem) => `${item.title}`}
            renderItem={(item: FaqListItem) => (
              <Typography onClick={() => handleOnClick(item.title)}>{item.title}</Typography>
            )}
            renderBody={(item: FaqListItem) => (
              <FlexBox
                justifyContent="center"
                backgroundColor={Color.white}
                accessibilityLabel={item.title}
                vSpacing={Spacing.largeXLarge}
                hSpacing={Spacing.xLargeLarge}
              >
                {item.subtitle.map((subTitle: FaqSubTitle | string) => {
                  if ((subTitle as FaqSubTitle).text) {
                    return (
                      <MuiBox>
                        {renderSubTitleText(subTitle as FaqSubTitle, onLinkClick)}

                        {renderSubTitleBullets(subTitle as FaqSubTitle)}

                        <Spacer size="xsmall" />
                      </MuiBox>
                    );
                  }

                  const subTitleText = subTitle as string;
                  return (
                    <MuiBox>
                      {(subTitle as FaqSubTitle).clickLink ? (
                        <MuiBox mb={1} style={{ float: 'left' }}>
                          <Typography
                            testID={subTitleText}
                            dangerouslySetInnerHTML={{
                              __html: (subTitle as FaqSubTitle).clickLink['text']
                            }}
                            onClick={onLinkClick}
                            style={{ float: 'left' }}
                          ></Typography>
                          {renderClickLink(subTitle as FaqSubTitle)}
                        </MuiBox>
                      ) : (
                        <MuiBox mb={1}>
                          <LinkTypography
                            testID={subTitleText}
                            dangerouslySetInnerHTML={{ __html: subTitleText }}
                            onClick={onLinkClick}
                          ></LinkTypography>
                        </MuiBox>
                      )}
                    </MuiBox>
                  );
                })}
              </FlexBox>
            )}
            style={{
              borderLeft: '0px',
              borderRight: '0px',
              paddingTop: Spacing.largeXLarge,
              paddingBottom: Spacing.large
            }}
            activeItemId={activeQuestionId}
            setRef={flatListRef}
            contentContainerStyle={{ maxHeight: '600px' }}
          />
        </ScrollableLayout>
      </MuiBox>
    </MuiBox>
  );
};

export default FAQSList as ComponentType;
