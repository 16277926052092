import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import styled from 'styled-components';

import get from 'lodash/get';
import take from 'lodash/take';
import differenceBy from 'lodash/differenceBy';

import { MuiBox, MuiContainer, MuiGrid, MuiTypography } from 'theme/material-ui';

import Banner from 'components/UI/Banner/Banner';
import DataLoader from 'components/UI/DataLoader/DataLoader';

import { FacilityCardItem } from 'components/getCare/FacilityList/FacilityCardItem';
import { getFacilities } from 'store/getCare/liveCare/actions';
import { geolocationCoordsSelector, geolocationDeniedSelector } from 'store/geolocation/selectors';
import { allFacilitiesSelector, isFetchingSelector } from 'store/getCare/liveCare/selectors';

import { AllFacilities, Facility } from 'store/getCare/liveCare/types';
import { RootDispatch, RootState } from 'store/types';
import { Coordinate } from 'modules/types/common';

import { FacilityDetailsDialog } from './FacilityDetails';
import { FacilitiesError, FacilitiesLoading, FacilitiesNoData } from './shared';
import analyticsService, { AmplitudeEventData, AnalyticsEvent } from 'services/AnalyticsService';
import {
  currentLocationPathNameSelector,
  previousLocationPathNameSelector
} from 'store/router/selectors';

import { useLiveCare } from './hooks';
import { AllOtherLocations } from './components';
import { filterOptions, FILTER_NONE } from './constants';
import { facilityTypeTitles } from 'modules/constants/symptomChecker';

interface SearchResultProps
  extends RouteComponentProps<{ facilityType: 'instacares' | 'emergencyRooms' | 'kidscares' }> {
  allFacilities: AllFacilities;
  isFetching: boolean;
  error: boolean;
  geolocationAccepted: boolean;
  geolocationDenied: boolean;
  geolocationCoords: Coordinate;
  dispatch: RootDispatch;
  currentUrl?: string;
  referringUrl?: string;
}

// TODO: Extract useEffect logic and some facilities data manipulation into a custom hook
function SearchResultsScreen({
  dispatch,
  error,
  isFetching,
  allFacilities,
  geolocationDenied,
  geolocationCoords,
  ...props
}: SearchResultProps) {
  const { facilityType } = props.match.params;

  const [filterBy, setFilterBy] = useState(FILTER_NONE);
  const [showFilters, setShowFilters] = useState(true);
  const [selectedFacility, setSelectedFacility] = useState<Facility>();
  const [closestFacilities, setClosestFacilities] = useState<Facility[]>([]);
  const [filteredFacilities, setFilteredFacilities] = useState<Facility[]>([]);

  const data: AmplitudeEventData = {
    currentUrl: props.currentUrl,
    referringUrl: props.referringUrl
  };

  useLiveCare();

  // only runs on ititial render, and when the filter setting is changed
  useEffect(() => {
    analyticsService.logEvent(AnalyticsEvent.UrgentCareFacilitySearched, data);
  }, [filterBy]);

  useEffect(() => {
    if (geolocationCoords) {
      dispatch(getFacilities(geolocationCoords, true));
    }
  }, [geolocationCoords]);

  useEffect(() => {
    const facilities = facilityType ? get(allFacilities, facilityType, []) : allFacilities;

    if (!facilities || !facilities.length) {
      setClosestFacilities([]);
      setFilteredFacilities([]);
      return;
    }

    filterFacilities(facilities);
  }, [allFacilities, filterBy]);

  const onPressFilter = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value: filter } = event.target;

    setFilterBy(filter);

    analyticsService.logEvent(AnalyticsEvent.UrgentCareFacilityFiltered, {
      type: filter,
      ...data
    });
  };

  const onPressOtherLocation = (facility: Facility) => {
    analyticsService.logEvent(AnalyticsEvent.UrgentCareFacilityAddressClicked, data);
    setSelectedFacility(facility);
  };

  const filterFacilities = (facilities: Facility[]) => {
    const tempFacilites = [...facilities];

    // Find 3 closest facility location by isCurrentlyOpen
    const tempThreeClosestFacilites = take(
      tempFacilites.filter(_ => _.isCurrentlyOpen),
      3
    );

    setClosestFacilities(tempThreeClosestFacilites);

    // Get all other locations (minus 3 closest to user)
    const remainingFacilities = differenceBy(
      tempFacilites,
      tempThreeClosestFacilites,
      'locationId'
    );

    // Check that there is at least one open facility and one close facility to show filters
    if (
      remainingFacilities.find(_ => _.isCurrentlyOpen) &&
      remainingFacilities.find(_ => !_.isCurrentlyOpen)
    ) {
      setShowFilters(true);
    } else {
      setShowFilters(false);
    }

    // Filter remaining locations
    const tempFilteredFacilities = remainingFacilities.filter((facility: Facility) =>
      filterOptions.find(_ => _.id === filterBy)?.filter(facility)
    );

    setFilteredFacilities(tempFilteredFacilities);
  };

  return (
    <MuiBox component="main">
      <Banner message={facilityTypeTitles[facilityType]}></Banner>
      <DataLoader
        data={filteredFacilities}
        error={error}
        loading={isFetching}
        renderError={() => <FacilitiesError />}
        renderLoading={() => <FacilitiesLoading />}
        renderNoData={() => <FacilitiesNoData />}
        renderData={() => (
          <>
            <MuiContainer maxWidth="lg">
              <MuiBox my={3}>
                <MuiBox my={3}>
                  <InlineBlockTypography variant="h4">Closest to You</InlineBlockTypography>{' '}
                  <InlineBlockTypography variant="h5">
                    (from {geolocationDenied ? 'Salt Lake City' : 'your current location'})
                  </InlineBlockTypography>
                </MuiBox>

                <MuiGrid container alignItems="stretch">
                  {closestFacilities.map((facility: Facility) => (
                    <FacilityCardItem
                      facility={facility}
                      key={facility.locationId}
                      onPress={() => {
                        analyticsService.logEvent(
                          AnalyticsEvent.UrgentCareFacilityAddressClicked,
                          data
                        );
                        setSelectedFacility(facility);
                      }}
                    />
                  ))}
                </MuiGrid>
              </MuiBox>

              <AllOtherLocations
                filterBy={filterBy}
                filteredFacilities={filteredFacilities}
                onPressFacility={onPressOtherLocation}
                onPressFilter={onPressFilter}
                showFilters={showFilters}
              />
            </MuiContainer>

            <FacilityDetailsDialog
              facility={selectedFacility}
              open={!!selectedFacility}
              onClose={() => setSelectedFacility(undefined)}
            />
          </>
        )}
      />
    </MuiBox>
  );
}

const InlineBlockTypography = styled(MuiTypography)`
  && {
    display: inline-block;
  }
`;

const mapStateToProps = (state: RootState) => ({
  allFacilities: allFacilitiesSelector(state),
  isFetching: isFetchingSelector(state),
  geolocationDenied: geolocationDeniedSelector(state),
  geolocationCoords: geolocationCoordsSelector(state),
  currentUrl: currentLocationPathNameSelector(state),
  referringUrl: previousLocationPathNameSelector(state)
});

export default connect(mapStateToProps)(SearchResultsScreen);
