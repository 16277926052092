import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { EducationItem } from 'store/education/types';
import { FontSize } from 'modules/styles/variables';
import Spacer from 'components/UI/Layout/Spacer';
import Spinner from 'components/UI/Spinner/Spinner';
import DataLoader from 'components/UI/DataLoader/DataLoader';
import useCoreEventData from 'hooks/useCoreEventData';
import { HEALTH_RECORDS_DRAWERS } from 'lib/constants/healthRecords';
import { MuiBox, MuiPaper, MuiContainer, MuiTypography, MuiSnackbar } from 'theme/material-ui';
import usePatientEducation from 'lib/hooks/usePatientEducation';
import EducationListItem from 'screens/HealthRecords/Education/ListItem';

export const PatientEducationViewAll = () => {
  const history = useHistory();
  const {
    data: educationData,
    incomplete,
    complete,
    loading,
    error,
    counts,
    refetch
  } = usePatientEducation();

  const { referringUrl } = useCoreEventData();

  useEffect(() => {
    // Refetch PE list everytime user comes back from viewing an assignment
    if (referringUrl.includes('/patient-education/detail')) {
      refetch(); // Hard refresh / don't use cache
    } else {
      refetch(true); // Uses cache if available
    }
  }, []);

  const handleItemOnPress = (accessCode: EducationItem['accessCode']) => {
    history.push(`/u/health-record/patient-education/detail/${accessCode}`);
  };

  return (
    <>
      {educationData.length > 0 && loading ? (
        <MuiSnackbar
          open
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          style={{ bottom: 10, right: 10 }}
        >
          <MuiPaper>
            <MuiBox
              p={2}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <MuiTypography>Refreshing your education assignments...</MuiTypography>
              <Spinner />
            </MuiBox>
          </MuiPaper>
        </MuiSnackbar>
      ) : null}
      <MuiContainer>
        <MuiBox alignContent="center">
          <MuiBox my={6}>
            <DataLoader
              data={educationData}
              loading={educationData.length === 0 && loading}
              error={error}
              renderLoading={() => (
                <MuiBox
                  p={5}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <MuiTypography>Loading your education assignments...</MuiTypography>
                  <Spinner />
                </MuiBox>
              )}
              renderError={() => (
                <MuiBox display="flex" alignItems="center" justifyContent="center">
                  <MuiTypography>There was an issue loading your assignments.</MuiTypography>
                </MuiBox>
              )}
              renderNoData={() => (
                <MuiBox display="flex" alignItems="center" justifyContent="center">
                  <MuiTypography>You have no assignments at this time.</MuiTypography>
                </MuiBox>
              )}
              renderData={() => {
                return (
                  <>
                    <MuiTypography data-testid="action-items" fontSize={FontSize.largeHeading}>
                      Action Items from Providers
                    </MuiTypography>
                    <Spacer size="small" />
                    {counts.incompleteCount > 0 ? (
                      <MuiBox mb={5}>
                        <MuiPaper elevation={3}>
                          {incomplete?.map((item: EducationItem) => (
                            <EducationListItem
                              item={item}
                              onPress={() => handleItemOnPress(item.accessCode)}
                            />
                          ))}
                        </MuiPaper>
                      </MuiBox>
                    ) : (
                      <>
                        <MuiPaper elevation={3}>
                          <MuiBox p={2} display="flex" alignItems="center" justifyContent="center">
                            <MuiTypography>
                              {HEALTH_RECORDS_DRAWERS.Education.empty("You're all caught up! ")}
                            </MuiTypography>
                          </MuiBox>
                        </MuiPaper>
                        <Spacer size="small" />
                      </>
                    )}

                    {counts.completeCount > 0 ? (
                      <MuiBox>
                        <MuiTypography data-testid="history-items" fontSize={FontSize.largeHeading}>
                          History of Education
                        </MuiTypography>
                        <Spacer size="small" />
                        <MuiPaper elevation={3}>
                          {complete?.map((item: EducationItem) => (
                            <EducationListItem
                              item={item}
                              onPress={() => handleItemOnPress(item.accessCode)}
                            />
                          ))}
                        </MuiPaper>
                      </MuiBox>
                    ) : null}
                  </>
                );
              }}
            />
          </MuiBox>
        </MuiBox>
      </MuiContainer>
    </>
  );
};

export default PatientEducationViewAll;
