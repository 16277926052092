import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { Alert } from 'components/Alert';
import UserAvatar from 'components/UI/Avatar';
import { SpinnerOverlay } from 'components/UI/Spinner/SpinnerModal';
import {
  dfdDefaultTheme,
  CursorMuiBox,
  MuiBox,
  MuiContainer,
  MuiGrid,
  MuiTypography
} from 'theme/material-ui';

import { getUserName } from 'lib/user';

import { Color } from 'modules/styles/colors';
import { FontSize, FontWeight } from 'modules/styles/variables';
import { capitalizeEachWord, convertToLowerKabobCase } from 'modules/utils/StringUtils';

import { NavigationScreenProps } from 'screens/navigation';

import { RootState } from 'store/types';
import { Consumer, Grantor } from 'store/profile/types';
import * as accountActions from 'store/account/actions';
import * as preferencesActions from 'store/consumerPreferences/actions';
import * as getUserPCPActions from 'store/myDoctors/actions';
import { MapAvatar } from 'store/consumerPreferences/types';
import { avatarDataMapSelector } from 'store/consumerPreferences/selectors';

import {
  allActiveAccountsSelector,
  accountLoadingSelector,
  currentAccountConsumerSelector
} from 'store/account/selectors';

import { AccountSelectScreen } from './styled';

export interface Props extends NavigationScreenProps {
  activeAccounts: (Consumer | Grantor)[];
  avatarsMap: MapAvatar;
  consumer: Consumer;
  currentUrl: string;
  getConsumerSharedUiData: typeof preferencesActions.getConsumerSharedUiData;
  referringUrl: string;
  switchAccount: typeof accountActions.switchAccount;
  switchingAccount: boolean;
  getUserPCP: typeof getUserPCPActions.getCurrentSignedInUserPrimaryDoctor;
}

export function AccountSelect({
  activeAccounts,
  avatarsMap,
  consumer,
  getConsumerSharedUiData,
  currentUrl,
  referringUrl,
  switchAccount,
  switchingAccount,
  history,
  getUserPCP
}: Props) {
  const [consumerId, setConsumerId] = useState(
    activeAccounts.length ? activeAccounts[0].consumerId : ''
  );
  const isSmallScreen = useMediaQuery(dfdDefaultTheme.breakpoints.down('sm'));

  useEffect(() => {
    getConsumerSharedUiData(consumer.consumerId);

    if (consumer?.consumerId !== consumerId) {
      setConsumerId(consumer?.consumerId);
    }
  }, [consumer?.consumerId]);

  const handleAccountSelectError = () => {
    Alert.alert(
      'Failed to switch account. Please try signing out and signing in again to switch to this account.'
    );
  };

  const handleUserClick = async (consumerId: string) => {
    if (consumer?.consumerId !== consumerId) {
      setConsumerId(consumerId);
      const response = await switchAccount(consumerId, currentUrl, referringUrl);

      if (response.error) {
        handleAccountSelectError();
      }
    } else {
      getUserPCP();
    }

    history.push('/u/dashboard');
  };

  return (
    <AccountSelectScreen isSmallScreen={isSmallScreen}>
      <SpinnerOverlay
        isLoading={switchingAccount}
        position="absolute"
        backgroundColor={Color.translucent}
        testID="account-select-loading"
      />
      <MuiBox pt={6}>
        <MuiBox pb={6}>
          <MuiTypography align="center" color={Color.white} variant="h5">
            Welcome
          </MuiTypography>
          <MuiTypography align="center" color={Color.white} variant="body1">
            Which account would you like to view?
          </MuiTypography>
        </MuiBox>
        <MuiContainer maxWidth="md">
          <MuiGrid container spacing={3} justify="center">
            {activeAccounts.map(activeAccount => (
              <MuiGrid key={activeAccount.consumerId} item lg={2} sm={6} xs={6}>
                <CursorMuiBox
                  onClick={() => handleUserClick(activeAccount.consumerId)}
                  data-testid={`proxy-user-${convertToLowerKabobCase(
                    `${activeAccount?.firstName || ''} ${activeAccount?.lastName || ''}`
                  )}`}
                >
                  <UserAvatar
                    avatarUrl={avatarsMap[activeAccount.consumerId]}
                    fallback={getUserName(activeAccount)}
                    initialsFontSize={FontSize.title}
                    size={80}
                  />
                  <MuiBox mt={1}>
                    <MuiTypography noWrap align="center" color={Color.white}>
                      {capitalizeEachWord(`${activeAccount.firstName} ${activeAccount.lastName}`)}
                    </MuiTypography>
                  </MuiBox>
                </CursorMuiBox>
              </MuiGrid>
            ))}
          </MuiGrid>
        </MuiContainer>
      </MuiBox>
      <CursorMuiBox
        mx="auto"
        my={10}
        onClick={() => history.push('/u/manage-account')}
        textAlign="center"
        data-testid="manage-accounts-button"
      >
        <MuiTypography color={Color.white} fontWeight={FontWeight.semibold} variant="body1">
          Manage accounts
        </MuiTypography>
      </CursorMuiBox>
    </AccountSelectScreen>
  );
}

const mapStateToProps = (state: RootState) => ({
  activeAccounts: allActiveAccountsSelector(state),
  avatarsMap: avatarDataMapSelector(state),
  consumer: currentAccountConsumerSelector(state),
  switchingAccount: accountLoadingSelector(state)
});

const mapDispatchToProps = {
  switchAccount: accountActions.switchAccount,
  getConsumerSharedUiData: preferencesActions.getConsumerSharedUiData,
  getUserPCP: getUserPCPActions.getCurrentSignedInUserPrimaryDoctor
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountSelect);
