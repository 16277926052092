import React from 'react';
import { MuiPaper } from 'theme/material-ui';
import InfoCardTitle from './InfoCardTitle';

export interface InfoCardProps {
  children: React.ReactNode;
  title?: string;
  buttonOnClick?: () => void;
  buttonText?: string;
  testID?: string;
  buttonTestID?: string;
  customHeader?: () => React.ReactNode;
  onClick?: () => void;
}

const InfoCard = ({
  children,
  title,
  buttonOnClick,
  buttonText = 'Link',
  testID,
  buttonTestID,
  customHeader,
  onClick
}: InfoCardProps) => {
  return (
    <MuiPaper variant="outlined" data-testid={testID} onClick={onClick}>
      {customHeader ? customHeader() : null}
      {title ? (
        <InfoCardTitle
          title={title}
          buttonOnClick={buttonOnClick}
          buttonText={buttonText}
          buttonTestID={buttonTestID}
        />
      ) : null}
      {children}
    </MuiPaper>
  );
};

export default InfoCard;
