import Svg, { SvgProps } from 'components/UI/Svg/Svg';
import DefaultText from 'components/UI/Typography/DefaultText';
import { Color } from 'modules/styles/colors';
import React, { ReactNode, useState, useEffect, useRef } from 'react';
import { FontWeight, IconSize, Spacing } from 'modules/styles/variables';
import analyticsService, { AmplitudeEventData, AnalyticsEvent } from 'services/AnalyticsService';
import { convertToLowerKabobCase } from 'modules/utils/StringUtils';
import {
  MuiBox,
  MuiExpansionPanelDetails,
  MuiExpansionPanelSummary,
  MuiTypography,
  withStyles
} from 'theme/material-ui';
import { SubText, StyledMuiExpansionPanel } from './styled';
import FlexBox from 'components/UI/Layout/FlexBox';

const ExpansionPanelSummary = withStyles({
  root: {},
  content: {
    margin: (props: { style: React.CSSProperties }) => {
      return props.style?.margin || props.style?.margin === 0
        ? `${props.style.margin} !important`
        : '12px 0';
    }
  },
  expanded: {}
})(MuiExpansionPanelSummary);

export interface CollapsibleListItemProps {
  alignLast?: string;
  testID?: string;
  detailsPadding?: string | number;
  headerPadding?: string | number;
  headerMargin?: string | number;
  detailsBackground?: string;
  firstListItem?: boolean;
  handleChange: (val: boolean) => void;
  headerColor?: string;
  headerColorOnExpand?: string;
  textColor?: string;
  textColorOnExpand?: string;
  fontSize?: string | number;
  leftIcon?: string;
  leftIconProps?: SvgProps;
  leftIconPaddingRight?: string | number;
  leftIndent?: string;
  title: string | ReactNode;
  customTitle?: ReactNode;
  subtitle?: string;
  subtitle1?: string;
  subtitle2?: string;
  padding?: string;
  margin?: string;
  children: ReactNode;
  largeView?: boolean;
  overrideDisabledOpacity: boolean;
  currentUrlForAnalytics?: string;
  referringUrlForAnalytics?: string;
  analyticsEvent?: AnalyticsEvent;
  expandOnFirstRender?: boolean;
  isExpandable?: boolean;
  error?: string;
  shouldScroll?: boolean;
  height?: string;
}

const CollapsibleListItem = ({
  alignLast,
  detailsPadding,
  headerPadding,
  headerMargin,
  detailsBackground,
  error,
  firstListItem,
  handleChange,
  headerColor,
  headerColorOnExpand,
  textColor,
  textColorOnExpand,
  fontSize,
  leftIcon,
  leftIconProps,
  leftIconPaddingRight,
  leftIndent,
  overrideDisabledOpacity,
  title = '',
  customTitle,
  subtitle,
  subtitle1,
  subtitle2,
  padding,
  margin,
  currentUrlForAnalytics,
  referringUrlForAnalytics,
  analyticsEvent,
  expandOnFirstRender,
  children,
  largeView,
  testID,
  isExpandable = true,
  shouldScroll,
  height
}: CollapsibleListItemProps) => {
  const ref = useRef<HTMLDivElement>();
  const [expanded, setItemExpanded] = useState(expandOnFirstRender || false);
  const iconName = expanded ? 'Subtract' : 'Add';
  const hideExpandIcon = !isExpandable && overrideDisabledOpacity;

  subtitle1 = subtitle1 || subtitle;

  useEffect(() => {
    if (ref.current && shouldScroll) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [ref, shouldScroll]);

  const toggleItem = () => {
    if (analyticsEvent && !expanded) {
      const data: AmplitudeEventData = {
        currentUrl: currentUrlForAnalytics,
        referringUrl: referringUrlForAnalytics
      };

      analyticsService.logEvent(analyticsEvent, data);
    }

    setItemExpanded(!expanded);
    handleChange(!expanded);
  };

  return (
    <StyledMuiExpansionPanel
      ref={ref}
      firstListItem={firstListItem}
      onChange={toggleItem}
      disabled={!isExpandable}
      expanded={expanded}
      margin={margin}
      padding={padding}
      style={{
        backgroundColor: 'transparent',
        boxShadow: 'none',
        width: '100%'
      }}
      data-testid={testID}
      height={height}
    >
      <ExpansionPanelSummary
        id={
          React.isValidElement(title)
            ? convertToLowerKabobCase(title.props?.children[0], '-header')
            : convertToLowerKabobCase(title, '-header')
        }
        aria-controls={
          React.isValidElement(title)
            ? convertToLowerKabobCase(title.props?.children[0], '-content')
            : convertToLowerKabobCase(title, '-content')
        }
        data-testid={
          React.isValidElement(title)
            ? convertToLowerKabobCase(title.props?.children[0], '-collapsible-list-item-parent')
            : convertToLowerKabobCase(title, '-collapsible-list-item-parent')
        }
        expandIcon={
          !hideExpandIcon ? (
            <Svg
              name={iconName}
              size={IconSize.base}
              color={
                textColor && !expanded ? textColor : expanded ? textColorOnExpand : Color.black
              }
              style={{
                marginLeft: Spacing.small
              }}
            />
          ) : (
            <MuiBox width="23px"></MuiBox>
          )
        }
        style={{
          backgroundColor:
            headerColor && !expanded ? headerColor : expanded ? headerColorOnExpand : undefined,
          opacity: overrideDisabledOpacity ? '1' : '',
          minHeight: height || undefined,
          padding: headerPadding,
          margin: headerMargin
        }}
      >
        <MuiBox
          display="flex"
          flex={largeView ? '1 1 auto' : ''}
          justifyContent="space-between"
          alignItems="center"
          paddingLeft={leftIndent}
          width="100%"
        >
          {leftIcon && (
            <FlexBox
              alignItems="center"
              width={30}
              hSpacingRight={!leftIconPaddingRight ? Spacing.large : leftIconPaddingRight}
            >
              <Svg
                name={leftIcon}
                size={32}
                color={
                  textColor && !expanded ? textColor : expanded ? textColorOnExpand : undefined
                }
                {...leftIconProps}
              />
            </FlexBox>
          )}

          {customTitle || (
            <MuiBox
              data-testid={
                React.isValidElement(title)
                  ? convertToLowerKabobCase(title.props?.children[0], '-expandable-drawer')
                  : convertToLowerKabobCase(title, '-expandable-drawer')
              }
              display="block"
              width="100%"
              alignItems="center"
            >
              <MuiBox
                fontWeight={600}
                display={largeView ? 'inline-block' : 'inline'}
                width={largeView ? '33%' : 'unset'}
              >
                <DefaultText
                  data-testid="collapsible-list-item-title"
                  color={
                    textColor && !expanded ? textColor : expanded ? textColorOnExpand : undefined
                  }
                  fontSize={fontSize}
                >
                  {title}
                </DefaultText>
              </MuiBox>

              {subtitle && !largeView && (
                <MuiBox mt={0.5}>
                  <MuiTypography>
                    {error || subtitle ? (
                      <SubText data-testid="collapsible-list-item-subtitle">
                        {error || subtitle}
                      </SubText>
                    ) : null}
                  </MuiTypography>
                </MuiBox>
              )}
              {largeView && subtitle1 && (
                <MuiBox width={!subtitle2 ? '66%' : '33%'} display="inline-block">
                  <MuiTypography>
                    <SubText>{subtitle1}</SubText>
                  </MuiTypography>
                </MuiBox>
              )}
              {largeView && subtitle2 && (
                <MuiBox width={!subtitle ? '66%' : '33%'} display="inline-block" textAlign="right">
                  <MuiTypography align="right">
                    <SubText textAlign={alignLast} fontWeight={FontWeight.bold}>
                      {subtitle2}
                    </SubText>
                  </MuiTypography>
                </MuiBox>
              )}
            </MuiBox>
          )}
        </MuiBox>
      </ExpansionPanelSummary>

      <MuiExpansionPanelDetails
        style={{
          padding: detailsPadding,
          backgroundColor: detailsBackground
        }}
      >
        <MuiBox width="100%">{children}</MuiBox>
      </MuiExpansionPanelDetails>
    </StyledMuiExpansionPanel>
  );
};

CollapsibleListItem.defaultProps = {
  handleChange: () => {},
  title: '',
  subtitle: '',
  children: null,
  rightExpandText: null,
  rightExpandFlex: 1,
  thisRef: undefined,
  isExpandable: true
} as Partial<CollapsibleListItemProps>;

export default CollapsibleListItem;
