import React from 'react';

export default {
  svg: (
    <g>
      <defs>
        <polygon id="path-1" points="0.8995 0.437 5.4535 0.437 5.4535 16.2149 0.8995 16.2149" />
        <polygon
          id="path-3"
          points="0.0039965671 0.437 12.2670881 0.437 12.2670881 28.1344964 0.0039965671 28.1344964"
        />
        <polygon
          id="path-5"
          points="0.109981949 0.437 12.3738596 0.437 12.3738596 28.1344964 0.109981949 28.1344964"
        />
        <polygon id="path-7" points="0 33.5635 199.039 33.5635 199.039 1.0005 0 1.0005" />
      </defs>
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Group-57" transform="translate(0.000000, -1.000000)">
          <g id="Group-3" transform="translate(49.000000, 0.563500)">
            <mask id="mask-2" fill="#ffffff">
              <use href="#path-1" />
            </mask>
            <g id="Clip-2" />
            <path
              d="M1.9525,3.8159 C1.9525,2.7109 2.1205,0.9619 0.8995,0.5049 L0.8995,0.4369 L5.4535,0.4369 L5.4535,0.5049 C4.2545,0.9619 4.3995,2.7379 4.3995,3.8159 L4.3995,12.8529 C4.3995,13.9339 4.2545,15.6849 5.4535,16.1409 L5.4535,16.2149 L0.8995,16.2149 L0.8995,16.1409 C2.0975,15.7109 1.9525,13.9339 1.9525,12.8529 L1.9525,3.8159 Z"
              id="Fill-1"
              fill="#0057A4"
              mask="url(#mask-2)"
            />
          </g>
          <path
            d="M57.3314,5.7471 C57.8584,5.7471 58.4324,5.7211 58.9394,5.4341 L59.0114,5.4341 L59.0114,7.2791 C59.8494,6.1281 60.8314,5.4341 62.3924,5.4341 C63.7334,5.4341 65.2684,5.9641 66.0364,7.0891 C66.7064,8.0481 66.6354,9.5341 66.6354,10.6581 L66.6354,14.1401 C66.6354,15.2401 66.4914,16.1031 67.5464,16.7041 L67.5464,16.7781 L63.5404,16.7781 L63.5404,16.7041 C64.5984,16.1031 64.4544,15.2161 64.4544,14.1401 L64.4544,10.9031 C64.4544,8.3351 63.7334,7.0161 61.5504,7.0161 C59.3704,7.0161 59.0114,8.4791 59.0114,9.8941 L59.0114,14.1401 C59.0114,15.2401 58.8654,16.1031 59.9194,16.7041 L59.9194,16.7781 L55.9144,16.7781 L55.9144,16.7041 C56.9734,16.1031 56.8274,15.2161 56.8274,14.1161 L56.8274,8.4091 C56.8274,7.3071 56.9734,6.4211 55.9144,5.8191 L55.9144,5.7471 L57.3314,5.7471 Z"
            id="Fill-4"
            fill="#0057A4"
          />
          <path
            d="M72.4153,12.1245 C72.4153,13.0345 72.1253,15.5075 74.6883,15.5075 C75.0253,15.5075 75.3383,15.3845 75.6483,15.2665 L74.3793,16.9665 C74.1413,17.0395 73.8773,17.0855 73.6113,17.0855 C69.8973,17.0855 70.2293,13.2535 70.2293,12.3165 L70.2293,7.3275 L68.6023,7.3275 L68.6023,6.4685 C70.8563,6.0825 71.4553,4.4075 71.4553,2.1705 L72.4153,2.1705 L72.4153,5.7475 L74.6673,5.7475 C74.9763,5.7475 75.3383,5.7475 75.5793,5.6035 L75.6483,5.6035 L75.6483,7.7855 L75.5793,7.7855 C75.1943,7.2795 74.4733,7.3275 73.8533,7.3275 L72.4153,7.3275 L72.4153,12.1245 Z"
            id="Fill-6"
            fill="#0057A4"
          />
          <path
            d="M85.3362,9.4859 C85.0722,7.8089 83.9722,7.0159 82.2432,7.0159 C80.6372,7.0159 79.4392,7.9749 79.1502,9.4859 L85.3362,9.4859 Z M79.0562,10.8759 C79.1042,13.8029 81.1412,15.5069 83.8492,15.5069 C85.2902,15.5069 86.6782,15.0969 87.7822,14.2359 L87.8562,14.2359 L86.6572,16.4879 C85.6252,16.9209 84.4752,17.0859 83.3472,17.0859 C79.2702,17.0859 76.4642,14.5249 76.4642,11.1889 C76.4642,7.8599 78.8862,5.4339 82.4612,5.4339 C85.7232,5.4339 87.9502,7.7849 87.8562,10.8759 L79.0562,10.8759 Z"
            id="Fill-8"
            fill="#0057A4"
          />
          <path
            d="M90.7791,5.7471 C91.3311,5.7471 91.9081,5.7211 92.2431,5.4341 L92.3151,5.4341 L92.3151,7.2601 C92.8421,6.1761 93.9431,5.4341 95.1451,5.4341 L96.0041,5.5061 L96.0041,7.7141 C95.7411,7.4241 95.3101,7.3271 94.9041,7.3271 C93.0831,7.3271 92.3151,8.2881 92.3151,10.7561 L92.3151,14.1161 C92.3151,15.2161 92.1711,16.1031 93.2251,16.7041 L93.2251,16.7781 L89.2191,16.7781 L89.2191,16.7041 C90.2791,16.1031 90.1331,15.2161 90.1331,14.1161 L90.1331,8.3831 C90.1331,7.2791 90.2791,6.4211 89.2451,5.8191 L89.2451,5.7471 L90.7791,5.7471 Z"
            id="Fill-10"
            fill="#0057A4"
          />
          <path
            d="M99.3118,5.7471 C99.8368,5.7471 100.4138,5.7211 100.7968,5.4341 L100.8688,5.4341 L100.8688,7.3271 C101.7088,6.0331 102.6438,5.4341 104.2478,5.4341 C105.9518,5.4341 107.1018,6.1031 107.9898,7.5191 C108.9258,6.2251 110.0528,5.4341 111.8278,5.4341 C115.2318,5.4341 116.1178,7.3821 116.1178,10.4241 L116.1178,14.1161 C116.1178,15.1461 115.9768,16.3231 117.1738,16.7051 L117.1738,16.7781 L113.0258,16.7781 L113.0258,16.7051 C114.0818,16.1031 113.9378,15.2161 113.9378,14.1161 L113.9378,11.0231 C113.9378,8.5751 113.1678,7.0161 111.2518,7.0161 C109.0898,7.0161 108.4938,8.3831 108.4938,10.6371 L108.4938,14.1161 C108.4938,15.2161 108.3498,16.1031 109.4078,16.7051 L109.4078,16.7781 L105.3988,16.7781 L105.3988,16.7051 C106.4558,16.1031 106.3118,15.2161 106.3118,14.1161 L106.3118,10.8051 C106.3118,8.2881 105.0658,7.0161 103.5808,7.0161 C101.7548,7.0161 100.8688,8.1441 100.8688,10.4471 L100.8688,14.1161 C100.8688,15.2161 100.7248,16.0801 101.7548,16.7041 L101.7548,16.7781 L97.7768,16.7781 L97.7768,16.7041 C98.8318,16.1031 98.6848,15.2161 98.6848,14.1161 L98.6848,8.3831 C98.6848,7.2791 98.8318,6.4211 97.7768,5.8191 L97.7768,5.7471 L99.3118,5.7471 Z"
            id="Fill-12"
            fill="#0057A4"
          />
          <path
            d="M128.2742,11.645 C128.2742,9.317 127.0282,7.016 124.4612,7.016 C122.2312,7.016 120.7952,8.772 120.7952,10.924 C120.7952,13.129 122.1142,15.507 124.5582,15.507 C126.7622,15.507 128.2742,13.803 128.2742,11.645 M118.3732,11.358 C118.3732,7.764 121.2732,5.434 124.7282,5.434 C126.2572,5.434 127.5802,5.843 128.7292,6.852 C129.9552,7.926 130.6972,9.51 130.6972,11.143 C130.6972,14.593 127.8172,17.085 124.4392,17.085 C121.1542,17.085 118.3732,14.763 118.3732,11.358"
            id="Fill-14"
            fill="#0057A4"
          />
          <path
            d="M140.8182,5.7471 C141.4392,5.7471 142.1812,5.8431 142.6152,5.4341 L142.6852,5.4341 L142.6852,14.1631 C142.6852,15.2671 142.5422,16.1541 143.5972,16.7051 L143.5972,16.7781 L140.5062,16.7781 L140.5062,15.2161 C139.6382,16.5361 138.7302,17.0851 137.0522,17.0851 C134.2222,17.0851 132.8812,15.4801 132.8812,12.1011 L132.8812,8.4091 C132.8812,7.3071 133.0182,6.4211 131.9682,5.8191 L131.9682,5.7471 L133.5252,5.7471 C134.0542,5.7471 134.6522,5.7211 134.9902,5.4341 L135.0612,5.4341 L135.0612,11.6921 C135.0612,13.9001 135.6842,15.2671 137.7692,15.2671 C139.3532,15.2671 140.5062,13.9731 140.5062,12.1241 L140.5062,8.4311 C140.5062,7.3071 140.6482,6.4401 139.5902,5.8191 L139.5902,5.7471 L140.8182,5.7471 Z"
            id="Fill-16"
            fill="#0057A4"
          />
          <path
            d="M146.283,5.7471 C146.81,5.7471 147.387,5.7211 147.891,5.4341 L147.961,5.4341 L147.961,7.2791 C148.801,6.1281 149.785,5.4341 151.343,5.4341 C152.683,5.4341 154.222,5.9641 154.986,7.0881 C155.656,8.0481 155.586,9.5341 155.586,10.6581 L155.586,14.1401 C155.586,15.2401 155.442,16.1031 156.498,16.7051 L156.498,16.7781 L152.497,16.7781 L152.497,16.7051 C153.551,16.1031 153.404,15.2161 153.404,14.1401 L153.404,10.9031 C153.404,8.3351 152.683,7.0141 150.502,7.0141 C148.32,7.0141 147.961,8.4791 147.961,9.8941 L147.961,14.1401 C147.961,15.2401 147.818,16.1031 148.873,16.7051 L148.873,16.7781 L144.867,16.7781 L144.867,16.7051 C145.922,16.1031 145.783,15.2161 145.783,14.1161 L145.783,8.4091 C145.783,7.3071 145.922,6.4211 144.867,5.8161 L144.867,5.7471 L146.283,5.7471 Z"
            id="Fill-18"
            fill="#0057A4"
          />
          <path
            d="M160.8856,12.1245 C160.8856,13.0345 160.5996,15.5075 163.1626,15.5075 C163.4976,15.5075 163.8096,15.3845 164.1206,15.2665 L162.8486,16.9665 C162.6116,17.0395 162.3476,17.0855 162.0856,17.0855 C158.3676,17.0855 158.7016,13.2535 158.7016,12.3165 L158.7016,7.3275 L157.0736,7.3275 L157.0736,6.4685 C159.3276,6.0825 159.9276,4.4075 159.9276,2.1705 L160.8856,2.1705 L160.8856,5.7475 L163.1396,5.7475 C163.4496,5.7475 163.8096,5.7475 164.0516,5.6035 L164.1206,5.6035 L164.1206,7.7855 L164.0516,7.7855 C163.6696,7.2795 162.9486,7.3275 162.3236,7.3275 L160.8856,7.3275 L160.8856,12.1245 Z"
            id="Fill-20"
            fill="#0057A4"
          />
          <path
            d="M175.033,17.0855 C173.449,16.9925 171.863,16.5125 171.863,14.5935 L171.863,10.8055 C171.17,11.1635 170.355,11.3585 169.567,11.5495 C168.412,11.8145 167.119,12.0775 167.119,13.5385 C167.119,15.0285 168.461,15.5075 169.732,15.5075 C170.308,15.5075 170.885,15.3355 171.459,15.1685 L170.047,16.9665 C169.637,17.0395 169.204,17.0855 168.801,17.0855 C166.375,17.0855 164.7,15.5545 164.7,13.5635 C164.7,9.1065 171.863,11.1635 171.863,8.5525 C171.863,7.4025 170.454,7.0135 169.567,7.0135 C168.344,7.0135 167.385,7.4695 166.305,7.9755 L167.907,5.5765 L169.468,5.4345 C172.057,5.4345 174.024,6.1755 174.048,9.0785 L174.048,12.6745 C174.048,14.5705 174.097,15.2875 176.085,15.5075 L175.033,17.0855 Z"
            id="Fill-22"
            fill="#0057A4"
          />
          <path
            d="M178.5565,4.3062 C177.6675,4.3062 176.9995,3.6092 176.9995,2.7232 C176.9995,1.8842 177.7655,1.3082 178.5565,1.3082 C179.3685,1.3082 179.9955,2.0072 179.9955,2.7992 C179.9955,3.5902 179.3685,4.3062 178.5565,4.3062 L178.5565,4.3062 Z M178.0995,5.7472 C178.6545,5.7472 179.2505,5.7212 179.6605,5.3612 L179.7325,5.3612 L179.7325,14.1162 C179.7325,15.2162 179.5855,16.1032 180.6435,16.7052 L180.6435,16.7782 L176.6395,16.7782 L176.6395,16.7052 C177.6915,16.1032 177.5485,15.2162 177.5485,14.1162 L177.5485,8.3832 C177.5485,7.2792 177.6915,6.4212 176.6395,5.8162 L176.6395,5.7472 L178.0995,5.7472 Z"
            id="Fill-24"
            fill="#0057A4"
          />
          <path
            d="M183.6863,5.7471 C184.2123,5.7471 184.7893,5.7211 185.2933,5.4341 L185.3653,5.4341 L185.3653,7.2791 C186.2053,6.1281 187.1873,5.4341 188.7443,5.4341 C190.0873,5.4341 191.6233,5.9641 192.3923,7.0881 C193.0633,8.0481 192.9893,9.5341 192.9893,10.6581 L192.9893,14.1401 C192.9893,15.2401 192.8463,16.1031 193.9003,16.7051 L193.9003,16.7781 L189.8993,16.7781 L189.8993,16.7051 C190.9523,16.1031 190.8063,15.2161 190.8063,14.1401 L190.8063,10.9011 C190.8063,8.3351 190.0873,7.0141 187.9083,7.0141 C185.7233,7.0141 185.3653,8.4791 185.3653,9.8941 L185.3653,14.1401 C185.3653,15.2401 185.2213,16.1031 186.2773,16.7051 L186.2773,16.7781 L182.2753,16.7781 L182.2753,16.7051 C183.3273,16.1031 183.1833,15.2161 183.1833,14.1161 L183.1833,8.4091 C183.1833,7.3071 183.3273,6.4211 182.2753,5.8161 L182.2753,5.7471 L183.6863,5.7471 Z"
            id="Fill-26"
            fill="#0057A4"
          />
          <path
            d="M20.2239,5.2974 C20.2239,4.1384 21.1629,3.1954 22.3269,3.1954 C23.4849,3.1954 24.4219,4.1384 24.4219,5.2974 C24.4219,6.4624 23.4849,7.4014 22.3269,7.4014 C21.1629,7.4014 20.2239,6.4624 20.2239,5.2974"
            id="Fill-28"
            fill="#0057A4"
          />
          <path
            d="M22.3138,31.7725 C22.5028,31.7725 22.6638,31.6395 22.7218,31.4545 L28.2238,5.6335 L28.2248,5.6135 L28.2308,5.5605 C28.2308,5.2865 28.0278,5.0665 27.7748,5.0665 C27.6118,5.0665 27.4678,5.1625 27.3878,5.3025 L22.7618,12.1005 L22.7458,12.1155 C22.6398,12.2445 22.4828,12.3295 22.3078,12.3295 L22.3368,12.3295 C22.1638,12.3295 22.0068,12.2445 21.8998,12.1155 L21.8818,12.1005 L17.2588,5.3025 C17.1788,5.1625 17.0368,5.0665 16.8688,5.0665 C16.6178,5.0665 16.4128,5.2865 16.4128,5.5605 L16.4218,5.6135 L16.4218,5.6335 L21.9248,31.4545 C21.9828,31.6395 22.1428,31.7725 22.3328,31.7725 L22.3138,31.7725 Z"
            id="Fill-30"
            fill="#0057A4"
          />
          <g id="Group-34" transform="translate(25.000000, 0.563500)">
            <mask id="mask-4" fill="white">
              <use href="#path-3" />
            </mask>
            <g id="Clip-33" />
            <path
              d="M0.1443,28.0942 C0.2573,28.1662 0.3963,28.1382 0.4883,28.0382 L12.1993,11.8582 L12.2073,11.8472 L12.2293,11.8172 C12.3113,11.6362 12.2553,11.4102 12.1053,11.3172 C12.0053,11.2552 11.8883,11.2642 11.7973,11.3282 L6.9463,14.0702 L6.9293,14.0752 C6.8263,14.1202 6.7093,14.1152 6.6063,14.0522 L6.6223,14.0602 C6.5163,13.9952 6.4453,13.8822 6.4213,13.7572 L6.4193,13.7402 L7.2693,0.7802 C7.2643,0.6562 7.2063,0.5392 7.1093,0.4792 C6.9563,0.3842 6.7683,0.4532 6.6843,0.6342 L6.6713,0.6712 L6.6673,0.6852 L0.0083,27.7402 C-0.0127,27.8832 0.0443,28.0302 0.1543,28.1002 L0.1443,28.0942 Z"
              id="Fill-32"
              fill="#5090CC"
              mask="url(#mask-4)"
            ></path>
          </g>
          <g id="Group-37" transform="translate(7.000000, 0.563500)">
            <mask id="mask-6" fill="white">
              <use href="#path-5" />
            </mask>
            <g id="Clip-36" />
            <path
              d="M12.2303,28.0942 C12.1193,28.1662 11.9803,28.1382 11.8893,28.0382 L0.1773,11.8582 L0.1693,11.8472 L0.1493,11.8172 C0.0653,11.6362 0.1203,11.4102 0.2723,11.3172 C0.3713,11.2552 0.4873,11.2642 0.5793,11.3282 L5.4313,14.0702 L5.4453,14.0752 C5.5493,14.1202 5.6673,14.1152 5.7713,14.0522 L5.7533,14.0602 C5.8603,13.9952 5.9303,13.8822 5.9543,13.7572 L5.9573,13.7402 L5.1073,0.7802 C5.1113,0.6562 5.1683,0.5392 5.2683,0.4792 C5.4203,0.3842 5.6103,0.4532 5.6923,0.6342 L5.7023,0.6712 L5.7093,0.6852 L12.3693,27.7402 C12.3913,27.8832 12.3323,28.0302 12.2223,28.1002 L12.2303,28.0942 Z"
              id="Fill-35"
              fill="#5090CC"
              mask="url(#mask-6)"
            />
          </g>
          <path
            d="M32.3343,22.48 C32.3903,22.568 32.4873,22.594 32.5773,22.556 L44.2423,16.271 L44.2513,16.264 L44.2743,16.249 C44.3813,16.155 44.4093,15.984 44.3343,15.869 C44.2883,15.791 44.2093,15.758 44.1303,15.767 L40.1383,15.949 L40.1283,15.948 C40.0453,15.942 39.9723,15.9 39.9193,15.82 L39.9253,15.829 C39.8753,15.748 39.8643,15.65 39.8813,15.555 L39.8833,15.54 L41.8293,9.844 C41.8613,9.757 41.8573,9.659 41.8093,9.584 C41.7383,9.467 41.5903,9.444 41.4823,9.542 L41.4663,9.562 L41.4573,9.57 L32.3473,22.189 C32.2923,22.279 32.2853,22.401 32.3393,22.488 L32.3343,22.48 Z"
            id="Fill-38"
            fill="#82C341"
          />
          <path
            d="M12.0413,22.48 C11.9883,22.568 11.8883,22.594 11.7993,22.556 L0.1343,16.271 L0.1253,16.264 L0.1023,16.249 C-0.0047,16.155 -0.0327,15.984 0.0423,15.869 C0.0883,15.791 0.1673,15.758 0.2463,15.767 L4.2383,15.949 L4.2483,15.948 C4.3313,15.942 4.4063,15.9 4.4573,15.82 L4.4503,15.829 C4.5013,15.748 4.5123,15.65 4.4953,15.555 L4.4933,15.54 L2.5473,9.844 C2.5153,9.757 2.5193,9.659 2.5683,9.584 C2.6393,9.467 2.7863,9.444 2.8943,9.542 L2.9113,9.562 L2.9193,9.57 L12.0303,22.189 C12.0833,22.279 12.0923,22.401 12.0373,22.488 L12.0413,22.48 Z"
            id="Fill-40"
            fill="#82C341"
          />
          <polygon
            id="Fill-42"
            fill="#5090CC"
            points="50.9525 20.7237 53.4835 20.7237 53.4835 25.8247 58.5485 25.8247 58.5485 20.7237 61.0815 20.7237 61.0815 33.3477 58.5485 33.3477 58.5485 27.8147 53.4835 27.8147 53.4835 33.3477 50.9525 33.3477"
          />
          <path
            d="M69.9232,27.7793 C69.8512,26.6013 69.3062,25.5873 67.9522,25.5873 C66.5972,25.5873 65.9072,26.5283 65.8002,27.7793 L69.9232,27.7793 Z M71.5482,32.8233 C70.6632,33.3093 69.6522,33.5633 68.3492,33.5633 C65.2772,33.5633 63.4842,31.7903 63.4842,28.7333 C63.4842,26.0413 64.9132,23.8183 67.8102,23.8183 C71.2612,23.8183 72.2372,26.1853 72.2372,29.4413 L65.8002,29.4413 C65.9072,30.9403 66.9582,31.7903 68.4582,31.7903 C69.6342,31.7903 70.6482,31.3563 71.5482,30.8513 L71.5482,32.8233 Z"
            id="Fill-44"
            fill="#5090CC"
          />
          <path
            d="M77.7728,31.7901 C78.5128,31.7901 79.0928,31.4671 79.4738,30.9591 C79.8708,30.4361 79.9778,29.7671 79.9778,29.0441 L78.9288,29.0441 C77.8428,29.0441 76.2338,29.2251 76.2338,30.6511 C76.2338,31.4491 76.9038,31.7901 77.7728,31.7901 L77.7728,31.7901 Z M74.9328,24.5211 C75.8728,24.0871 77.1378,23.8181 78.1688,23.8181 C81.0088,23.8181 82.1868,24.9921 82.1868,27.7411 L82.1868,28.9361 C82.1868,29.8741 82.2028,30.5801 82.2198,31.2671 C82.2398,31.9721 82.2748,32.6241 82.3318,33.3481 L80.1968,33.3481 C80.1048,32.8591 80.1048,32.2431 80.0868,31.9541 L80.0498,31.9541 C79.4918,32.9861 78.2788,33.5631 77.1748,33.5631 C75.5308,33.5631 73.9208,32.5691 73.9208,30.7961 C73.9208,29.4061 74.5888,28.5911 75.5128,28.1421 C76.4348,27.6861 77.6268,27.5971 78.6398,27.5971 L79.9778,27.5971 C79.9778,26.0951 79.3078,25.5871 77.8798,25.5871 C76.8508,25.5871 75.8198,25.9861 75.0028,26.6021 L74.9328,24.5211 Z"
            id="Fill-46"
            fill="#5090CC"
          />
          <mask id="mask-8" fill="white">
            <use href="#path-7" />
          </mask>
          <g id="Clip-49" />
          <polygon
            id="Fill-48"
            fill="#5090CC"
            mask="url(#mask-8)"
            points="84.9 33.3475 87.324 33.3475 87.324 19.7835 84.9 19.7835"
          />
          <path
            d="M91.6614,25.8057 L89.8704,25.8057 L89.8704,24.0337 L91.6614,24.0337 L91.6614,22.1697 L94.0854,21.3937 L94.0854,24.0337 L96.2374,24.0337 L96.2374,25.8057 L94.0854,25.8057 L94.0854,30.1447 C94.0854,30.9407 94.3024,31.6837 95.2244,31.6837 C95.6574,31.6837 96.0754,31.5917 96.3274,31.4317 L96.3974,33.3477 C95.8954,33.4927 95.3334,33.5637 94.6084,33.5637 C92.7104,33.5637 91.6614,32.3897 91.6614,30.5437 L91.6614,25.8057 Z"
            id="Fill-50"
            fill="#5090CC"
            mask="url(#mask-8)"
          />
          <path
            d="M99.2552,19.7832 L101.6812,19.7832 L101.6812,25.2992 L101.7182,25.2992 C102.3312,24.3962 103.3802,23.8182 104.6482,23.8182 C106.8532,23.8182 107.8102,25.3722 107.8102,27.4702 L107.8102,33.3472 L105.3902,33.3472 L105.3902,28.3712 C105.3902,27.2362 105.3702,25.6972 103.8142,25.6972 C102.0602,25.6972 101.6812,27.5962 101.6812,28.7892 L101.6812,33.3472 L99.2552,33.3472 L99.2552,19.7832 Z"
            id="Fill-51"
            fill="#5090CC"
            mask="url(#mask-8)"
          />
          <path
            d="M116.7752,26.041 C116.3412,25.843 115.8732,25.697 115.1322,25.697 C113.6302,25.697 112.6702,26.889 112.6702,28.646 C112.6702,30.362 113.4862,31.684 115.1832,31.684 C115.8732,31.684 116.6322,31.432 117.0482,31.249 L117.1562,33.257 C116.4512,33.473 115.7282,33.563 114.7322,33.563 C111.6772,33.563 110.1402,31.484 110.1402,28.646 C110.1402,25.95 111.7322,23.818 114.6422,23.818 C115.6212,23.818 116.3242,23.922 116.9942,24.126 L116.7752,26.041 Z"
            id="Fill-52"
            fill="#5090CC"
            mask="url(#mask-8)"
          />
          <path
            d="M122.9686,31.7901 C123.7086,31.7901 124.2906,31.4671 124.6696,30.9591 C125.0666,30.4361 125.1756,29.7671 125.1756,29.0441 L124.1226,29.0441 C123.0406,29.0441 121.4316,29.2251 121.4316,30.6511 C121.4316,31.4491 122.1016,31.7901 122.9686,31.7901 L122.9686,31.7901 Z M120.1286,24.5211 C121.0686,24.0871 122.3356,23.8181 123.3666,23.8181 C126.2026,23.8181 127.3806,24.9921 127.3806,27.7411 L127.3806,28.9361 C127.3806,29.8741 127.3986,30.5801 127.4176,31.2671 C127.4356,31.9721 127.4706,32.6241 127.5236,33.3481 L125.3906,33.3481 C125.3006,32.8591 125.3006,32.2431 125.2826,31.9541 L125.2456,31.9541 C124.6856,32.9861 123.4766,33.5631 122.3726,33.5631 C120.7266,33.5631 119.1166,32.5691 119.1166,30.7961 C119.1166,29.4061 119.7846,28.5911 120.7066,28.1421 C121.6286,27.6861 122.8236,27.5971 123.8356,27.5971 L125.1756,27.5971 C125.1756,26.0951 124.5056,25.5871 123.0756,25.5871 C122.0466,25.5871 121.0136,25.9861 120.2006,26.6021 L120.1286,24.5211 Z"
            id="Fill-53"
            fill="#5090CC"
            mask="url(#mask-8)"
          />
          <path
            d="M130.4568,24.0332 L132.6048,24.0332 L132.6048,26.1482 L132.6428,26.1482 C132.7538,25.2792 133.7478,23.8182 135.1908,23.8182 C135.4298,23.8182 135.6818,23.8182 135.9338,23.8872 L135.9338,26.3292 C135.7188,26.2052 135.2828,26.1322 134.8498,26.1322 C132.8808,26.1322 132.8808,28.5912 132.8808,29.9292 L132.8808,33.3472 L130.4568,33.3472 L130.4568,24.0332 Z"
            id="Fill-54"
            fill="#5090CC"
            mask="url(#mask-8)"
          />
          <path
            d="M143.5838,27.7793 C143.5118,26.6013 142.9688,25.5873 141.6128,25.5873 C140.2558,25.5873 139.5698,26.5283 139.4608,27.7793 L143.5838,27.7793 Z M145.2088,32.8233 C144.3258,33.3093 143.3128,33.5633 142.0098,33.5633 C138.9378,33.5633 137.1468,31.7903 137.1468,28.7333 C137.1468,26.0413 138.5738,23.8183 141.4688,23.8183 C144.9238,23.8183 145.8978,26.1853 145.8978,29.4413 L139.4608,29.4413 C139.5698,30.9403 140.6168,31.7903 142.1208,31.7903 C143.2948,31.7903 144.3068,31.3563 145.2088,30.8513 L145.2088,32.8233 Z"
            id="Fill-55"
            fill="#5090CC"
            mask="url(#mask-8)"
          />
          <path
            d="M196.8201,7.1768 L197.1721,7.1768 C197.4061,7.1768 197.6121,7.1448 197.6121,6.8578 C197.6121,6.6068 197.3801,6.5648 197.1761,6.5648 L196.8201,6.5648 L196.8201,7.1768 Z M196.4231,6.2608 L197.2181,6.2608 C197.7311,6.2608 198.0081,6.4388 198.0081,6.8888 C198.0081,7.2548 197.7991,7.4338 197.4481,7.4648 L198.0241,8.4008 L197.6171,8.4008 L197.0611,7.4788 L196.8201,7.4788 L196.8201,8.4008 L196.4231,8.4008 L196.4231,6.2608 Z M198.5781,7.3388 C198.5781,6.4548 197.9401,5.8168 197.1511,5.8168 C196.3631,5.8168 195.7241,6.4548 195.7241,7.3388 C195.7241,8.2228 196.3631,8.8618 197.1511,8.8618 C197.9401,8.8618 198.5781,8.2228 198.5781,7.3388 L198.5781,7.3388 Z M195.2631,7.3388 C195.2631,6.2988 196.1111,5.4508 197.1511,5.4508 C198.1911,5.4508 199.0391,6.2988 199.0391,7.3388 C199.0391,8.3778 198.1911,9.2268 197.1511,9.2268 C196.1111,9.2268 195.2631,8.3778 195.2631,7.3388 L195.2631,7.3388 Z"
            id="Fill-56"
            fill="#0057A4"
            mask="url(#mask-8)"
          />
        </g>
      </g>
    </g>
  ),
  viewBox: '0 0 200 33'
};
