import React, { useEffect, useState } from 'react';
import { useWindowSize } from 'hooks/useWindowSize';
import useVisibleSlidesCount from 'hooks/useVisibleSlidesCount';
import { Slide, Slider, CarouselProvider, DotContainer, Dot } from './styled';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { isMobileBrowser } from 'lib/browser';

export interface CarouselProps<T> {
  autoSlideIndex?: number;
  className?: string;
  sliderHeight: number;
  naturalSlideWidth: number;
  naturalSlideHeight: number;
  slides: T[];
  slideToKey: (slide: T) => number | string;
  renderSlide: (slide: T) => React.ReactNode;
  infinite?: boolean;
  children?: React.ReactNode;
}

export function shouldShowArrows(totalSlides: number) {
  return totalSlides > (isMobileBrowser() ? 1 : 2);
}

function Carousel<T>(props: CarouselProps<T>) {
  const {
    autoSlideIndex,
    sliderHeight,
    slides,
    renderSlide,
    slideToKey,
    children,
    ...rest
  } = props;

  const totalSlides = slides.length;
  const visibleSlides = useVisibleSlidesCount(totalSlides);
  const windowDim = useWindowSize();
  const [slideIndex, setSlideIndex] = useState(0);

  useEffect(() => {
    if (autoSlideIndex !== undefined) {
      setSlideIndex(autoSlideIndex);
    }
  }, [autoSlideIndex]);

  const handleDotClick = (index: number) => {
    const lastIndex = slides.length - 1;
    // Prevents the slider from scrolling passed the last available slide if the last few dots are clicked.
    if (totalSlides > 2) {
      // Offset dot click by two if any of the the last three dots are clicked with 3 visible slides.
      if (visibleSlides === 3 && index > lastIndex - 3) {
        setSlideIndex(index - 2);
        // Offset dot click by one if any of the the last two dots are clicked with 2 visible slides.
      } else if (visibleSlides === 2 && index > lastIndex - 2) {
        setSlideIndex(index - 1);
      } else {
        setSlideIndex(index);
      }
    } else {
      setSlideIndex(index);
    }
  };

  return (
    <CarouselProvider
      visibleSlides={visibleSlides}
      totalCards={totalSlides} // prop for height calculation
      totalSlides={totalSlides} // prop for carousel package
      windowWidth={windowDim.width}
      orientation="horizontal"
      currentSlide={slideIndex}
      step={1}
      {...rest}
    >
      <Slider
        visibleSlides={visibleSlides}
        totalCards={totalSlides} // prop for height calculation
        windowWidth={windowDim.width}
        sliderHeight={sliderHeight}
      >
        {slides.map((item, index) => (
          <Slide data-testid="carousel-card" key={slideToKey(item) || index} index={index}>
            {renderSlide(item)}
          </Slide>
        ))}
      </Slider>
      {children}
      {slides.length > 1 ? (
        <DotContainer>
          {slides.map((slide, index) => (
            <Dot
              key={`${slide.type}-${String(index)}`}
              slide={index}
              onClick={() => handleDotClick(index)}
            />
          ))}
        </DotContainer>
      ) : null}
    </CarouselProvider>
  );
}

Carousel.defaultProps = {
  sliderHeight: 225,
  naturalSlideWidth: 350,
  infinite: true,
  slideToKey: (item: any) => (item ? item.id : '')
};

export default Carousel;
