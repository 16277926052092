import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import Typography from 'components/UI/Typography';
import { Color } from 'modules/styles/colors';
import { Spacing } from 'modules/styles/variables';
import React from 'react';
import { Link } from 'react-router-dom';
import analyticsService, { AnalyticsEvent } from 'services/AnalyticsService';
import { useSelector } from 'react-redux';
import {
  currentLocationPathNameSelector,
  previousLocationPathNameSelector
} from 'store/router/selectors';
import styled from 'styled-components';
import { MuiBox, MuiListItemSecondaryAction, MuiPaper } from 'theme/material-ui';
import { convertToLowerKabobCase } from 'modules/utils/StringUtils';

interface ItemBody {
  routeName: string;
  title: string;
  key: number;
  analyticsEvent: string;
}

const StyledList = styled(List)`
  &.MuiList-padding {
    padding-top: ${Spacing.none};
    padding-bottom: ${Spacing.none};
  }
  & .MuiListItem-container {
    & .MuiListItem-root {
      padding: ${Spacing.large}px;
    }
    border-bottom: 1px solid ${Color.grayLight};
    &:last-child {
      border-bottom: ${Spacing.none};
    }
  }
`;

const faMenuOptions = [
  {
    key: 1,
    title: 'Apply for assistance',
    routeName: '/u/fa-app',
    analyticsEvent: AnalyticsEvent.ApplyForAssistanceClicked
  },
  {
    key: 4,
    title: 'View Application Status',
    routeName: '/u/fa-app/status',
    analyticsEvent: AnalyticsEvent.ViewAppStatusClicked
  },
  {
    key: 2,
    title: 'Eligibility Information',
    routeName: '/u/fa-app/eligibility',
    analyticsEvent: AnalyticsEvent.EligibilityInformationClicked
  },
  {
    key: 3,
    title: 'FAQs',
    routeName: '/u/fa-app/faq',
    analyticsEvent: AnalyticsEvent.FAQClicked
  }
];

const FinancialAssistanceBody = () => {
  const currentUrl = useSelector(currentLocationPathNameSelector);
  const referringUrl = useSelector(previousLocationPathNameSelector);

  const handleItemClick = (route: string) => {
    const routeParts = route.split('/');
    const clue = routeParts[routeParts.length - 1];
    let enumName;

    switch (clue) {
      case 'status':
        enumName = 'ViewAppStatusClicked';
        break;
      case 'eligibility':
        enumName = 'EligibilityInformationClicked';
        break;
      case 'faq':
        enumName = 'FAQClicked';
        break;
      default:
        enumName = 'ApplyForAssistanceClicked';
        break;
    }

    analyticsService.logEvent(AnalyticsEvent[enumName], {
      currentUrl,
      referringUrl
    });
  };

  return (
    <MuiBox width="100%">
      <MuiPaper>
        <StyledList>
          {faMenuOptions.map((item: ItemBody) => (
            <ListItem
              button
              component={Link}
              to={item.routeName}
              key={item.key}
              onClick={() => handleItemClick(item.routeName)}
            >
              <Typography data-testid={convertToLowerKabobCase(item.title, '-list-item')}>
                {item.title}
              </Typography>
              <MuiListItemSecondaryAction>
                <KeyboardArrowRightIcon aria-label={`Go to ${item.title}`} />
              </MuiListItemSecondaryAction>
            </ListItem>
          ))}
        </StyledList>
      </MuiPaper>
    </MuiBox>
  );
};

export default FinancialAssistanceBody;
