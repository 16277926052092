import React from 'react';
import FlexBox from 'components/UI/Layout/FlexBox';
import Typography from 'components/UI/Typography';

// ERROR
const Error = ({ message }: { message: string }) => (
  <FlexBox flex={1} alignItems="center" justifyContent="center">
    <Typography>{message}</Typography>
  </FlexBox>
);

export default Error;
