import ConfirmModal from 'components/UI/Modals/ConfirmModal';
import { HealthAdvisory } from 'lib/dashboard/types';
import { fluFinderWidgetUrl } from 'modules/constants/urls';
import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import Config from 'react-native-config';
import { useHistory } from 'react-router-dom';
import { MY_DOCS } from 'store/booking/constants';
import { patchEvent } from 'store/events/actions';
import { snoozeHealthAdvisoryItem } from 'store/healthAdvisory/actions';
import { RootDispatch } from 'store/types';
import { getHealthAdvisoryActionProps } from 'lib/dashboard/utils';
import {
  MuiFormControl,
  MuiFormControlLabel,
  MuiFormLabel,
  MuiGrid,
  MuiRadio,
  MuiRadioGroup
} from 'theme/material-ui';
import { FlippableCard } from 'components/DashboardCarousel/DashboardBannerCard';
import { DashboardCarouselHealthAdvisoryBack } from './DashboardCarouselHealthAdvisoryBack';
import DashboardCarouselHealthAdvisoryFront from './DashboardCarouselHealthAdvisoryFront';
import { MammogramCallModal } from './MammogramCallModal';

export interface DashboardCarouselHealthAdvisoryProps {
  data: HealthAdvisory;
  totalCards: number;
  dispatch: RootDispatch;
}

export const DashboardCarouselHealthAdvisory = (props: DashboardCarouselHealthAdvisoryProps) => {
  const { data, totalCards, dispatch } = props;
  const history = useHistory();
  const useSurrogateId = Config.HIGH_PRIORITY_ITEMS_V2 === 'enabled';
  const eventId = useSurrogateId ? data?.surrogateUuid : data?.eventId;
  const { advisoryName, advisoryType, snoozeOptions } = data;

  const [flipped, setFlipped] = useState(false);

  // ***************************************************
  // ** CARD REMOVE BUTTON + MAMMOGRAM BUTTON TOGGLES **
  // ***************************************************

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showClinicsModal, setShowClinicsModal] = useState(false);

  // *************************
  // ** BOOKING TYPE ACTION **
  // *************************

  const onBookingPress = () => {
    history.push('/u/get-care-now', {
      isExpanded: MY_DOCS
    });
  };

  const healthAdvisoryActionProps = useMemo(() => {
    const onMammogramPress = () => setShowClinicsModal(true);
    const onFluPress = () => window.open(fluFinderWidgetUrl, '_blank');
    const onSubmitAdvisory = () => {
      const title = advisoryName;
      history.push(`/u/health-record/preventative-care/${title}/${eventId}`);
    };

    const actions = {
      onMammogramPress,
      onFluPress,
      onBookingPress,
      onSubmitAdvisory
    };

    return getHealthAdvisoryActionProps(advisoryType, actions);
  }, [advisoryName]);

  const [snoozeOption, setSnoozeOption] = useState<number | boolean>(snoozeOptions.days[0]);
  const snoozeParams =
    snoozeOption !== -1
      ? {
          hideFor: snoozeOption
        }
      : {
          hide: true
        };

  return (
    <>
      <FlippableCard
        flipped={flipped}
        totalCards={totalCards}
        back={
          <DashboardCarouselHealthAdvisoryBack
            totalCards={totalCards}
            onDone={() => setFlipped(false)}
            onSnooze={() => setShowConfirmModal(true)}
            healthAdvisoryAction={healthAdvisoryActionProps}
          />
        }
        front={
          <DashboardCarouselHealthAdvisoryFront
            advisoryType={advisoryType}
            advisoryName={advisoryName}
            totalCards={totalCards}
            onViewAllOptions={() => setFlipped(true)}
            healthAdvisoryAction={healthAdvisoryActionProps}
          />
        }
      />

      {/* CARD REMOVE CONFIRM MODAL */}
      <ConfirmModal
        accessibilityLabel="remove card modal"
        isOpen={showConfirmModal}
        title="Are you sure you want to dismiss this reminder?"
        onCancel={() => setShowConfirmModal(false)}
        subTitleElement={() => {
          return (
            <MuiGrid container item mt="10px" wrap="nowrap" xs>
              <MuiFormControl component="fieldset">
                <MuiFormLabel component="legend">
                  How long do you want to snooze this advisory?
                </MuiFormLabel>
                <MuiRadioGroup
                  row
                  value={snoozeOption}
                  onChange={event => {
                    setSnoozeOption(Number.parseInt(event.target.value, 10));
                  }}
                >
                  {snoozeOptions.days.map(day => (
                    <MuiFormControlLabel
                      key={day}
                      value={day}
                      control={<MuiRadio />}
                      label={`${day} days`}
                    />
                  ))}
                  {snoozeOptions.nextSeason && (
                    <MuiFormControlLabel value={-1} control={<MuiRadio />} label="Next Season" />
                  )}
                </MuiRadioGroup>
              </MuiFormControl>
            </MuiGrid>
          );
        }}
        onConfirm={() => {
          const { onAnalyticsDismissedClick } = healthAdvisoryActionProps;
          onAnalyticsDismissedClick(snoozeParams.hideFor);
          if (useSurrogateId) dispatch(snoozeHealthAdvisoryItem(eventId, snoozeParams));
          else dispatch(patchEvent(eventId, snoozeParams));
          setShowConfirmModal(false);
        }}
      />

      {/* Mammogram Modal */}
      <MammogramCallModal isOpen={showClinicsModal} onClose={() => setShowClinicsModal(false)} />
    </>
  );
};

export default connect()(DashboardCarouselHealthAdvisory);
