import diabetesThumb from 'assets/HealthTools/diabetes-thumb.png';
import { HealthToolCardData } from 'components/HealthToolsCards/HealthToolCard';
import PreventDiabetesScreen from './screen';
import { RouteData } from 'screens/navigation';

export const diabetesCard: HealthToolCardData = {
  healthToolCardKey: 'preventDiabetes',
  description: 'Learn what you can do to prevent diabetes',
  path: '/u/health-tools/prevent-diabetes',
  thumb: diabetesThumb
};

const preventDiabetesScreenRouteData: RouteData = {
  component: PreventDiabetesScreen,
  componentName: 'PreventDiabetesScreen',
  crumbLabel: 'Prediabetes 101',
  exact: true,
  includeBanner: true,
  includeCrumbs: true,
  includeDefaultLayout: true,
  path: diabetesCard.path,
  subMessage: 'Prediabetes and Diabetes Prevention videos',
  title: 'Prediabetes 101'
};

export const preventDiabetesRouteData: RouteData[] = [preventDiabetesScreenRouteData];
