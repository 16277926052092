import { Insurance } from 'store/profile/types';
import dayjs from 'dayjs';

export const getInsuranceStatus = (insurance: Insurance) => {
  const today = dayjs();

  if (dayjs(insurance.endDate).isBefore(today)) {
    return 'EXPIRED';
  }

  if (dayjs(insurance.startDate).isAfter(today)) {
    return 'FUTURE';
  }

  return 'ACTIVE';
};
