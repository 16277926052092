import React from 'react';

export default {
  svg: (
    <g>
      <g>
        <circle fill="#00857C" strokeWidth={0} cx={40} cy={40} r={38.5} />
        <path
          fill="#FFFFFF"
          strokeWidth={0}
          d="M40,2.5c20.711,0,37.5,16.789,37.5,37.5S60.711,77.5,40,77.5S2.5,60.711,2.5,40S19.289,2.5,40,2.5 M40,0.5 C18.22,0.5,0.5,18.22,0.5,40S18.22,79.5,40,79.5S79.5,61.78,79.5,40S61.78,0.5,40,0.5L40,0.5z"
        />
      </g>
      <g>
        <defs>
          <circle id="SVGID_1_" cx={40} cy={40} r={37.5} />
        </defs>
        <clipPath id="SVGID_2_5">
          <use xlinkHref="#SVGID_1_" />
        </clipPath>
        <g clipPath="url(#SVGID_2_5)">
          <rect
            x={30.125}
            y={69.945}
            fill="#231F20"
            strokeWidth={0}
            width={19.305}
            height={8.188}
          />
          <path
            fill="#BF8858"
            strokeWidth={0}
            d="M57.018,42.062l0.535-6.952c0-9.092-8.69-16.458-17.775-16.458c-9.092,0-17.775,7.367-17.775,16.458 l0.534,6.946c-0.509,0.123-0.967,0.356-1.351,0.684c-0.711,0.606-1.159,1.501-1.159,2.508v3.423c0,1.81,1.457,3.278,3.271,3.29 c0,5.376,4.045,10.576,8.582,13.713v5.594l-2.472,1.067l0.658,22.194h1.814v3.403h15.8v-3.403h1.803l0.658-22.126l-2.462-1.135 v-5.599c4.534-3.137,8.575-8.334,8.575-13.708c1.807-0.013,3.269-1.479,3.269-3.289v-3.423 C59.526,43.703,58.456,42.415,57.018,42.062z"
          />
          <path
            fill="#FFFFFF"
            strokeWidth={0}
            d="M60.19,109.807c0.425-11.937-0.102-33.867-9.377-37.352l-0.317,1.19 c-0.826,2.625-3.488,8.685-10.465,8.685c-6.184,0-8.789-4.76-9.805-7.67l-0.587-2.205c-9.275,3.485-10.144,25.415-9.911,37.352 H60.19z"
          />
          <polygon
            fill="#F15D44"
            strokeWidth={0}
            points="43.579,81.242 39.777,78.134 35.974,81.242 37.414,84.001 36.613,109.807 42.963,109.807  42.18,84.001  "
          />
          <g>
            <path
              fill="#E6E7E8"
              strokeWidth={0}
              d="M47.758,70.756l-1.951,1.805l-6.032,5.574l4.859,5.116l8.309-9.912 C52.93,73.331,47.758,70.756,47.758,70.756z"
            />
            <polygon
              fill="#E6E7E8"
              strokeWidth={0}
              points="26.61,73.335 34.917,83.253 39.776,78.133 31.79,70.758  "
            />
          </g>
          <path
            fill="#231F20"
            strokeWidth={0}
            d="M80.71,109.807c-1.21-8.939-2.653-17.382-3.98-25.143c-0.268-1.571-0.915-2.796-1.883-3.804 c-3.756-4.277-13.419-4.977-24.032-8.964c0,0-11.039,32.981-11.039,32.983c0-0.002-11.039-32.983-11.039-32.983 C15.887,76.723,4.42,76.726,3.159,84.104c-0.142,0.831-0.213,1.703-0.232,2.601c-1.229,7.197-2.531,14.939-3.636,23.102H80.71z"
          />
          <g>
            <g>
              <polygon
                fill="#4F4C4D"
                strokeWidth={0}
                points="56.078,73.365 55.821,80.914 52.05,84.016 54.984,88.026 39.777,104.878 49.43,69.945  "
              />
            </g>
            <g>
              <polygon
                fill="#4F4C4D"
                strokeWidth={0}
                points="23.476,73.365 23.733,80.914 27.503,84.016 24.569,88.026 39.777,104.878 30.124,69.945  "
              />
            </g>
          </g>
          <path
            opacity={0.8}
            fill="#B97349"
            strokeWidth={0}
            d="M57.018,42.062l0.535-6.952c0-9.092-8.69-16.458-17.775-16.458 c-0.003,0-0.003,23.371-0.003,23.371c-2.401,5.107-3.95,12.508-3.95,12.508h3.95v14.154c-2.305-0.001-5.19-1.14-7.899-3.014 v3.417l15.8,1.662v-5.077c-0.021,0.014-0.042,0.026-0.062,0.041c4.562-3.132,8.642-8.353,8.642-13.752 c1.807-0.013,3.269-1.479,3.269-3.289v-3.423C59.526,43.703,58.456,42.415,57.018,42.062z M39.776,68.684 c0.005,0,0.009-0.001,0.014-0.001c-0.004,0-0.008,0.001-0.012,0.001C39.778,68.684,39.777,68.684,39.776,68.684z M46.533,66.41 c0.083-0.051,0.167-0.102,0.25-0.154C46.7,66.308,46.617,66.359,46.533,66.41z M40.56,68.638c0.075-0.008,0.15-0.016,0.226-0.026 C40.71,68.622,40.635,68.631,40.56,68.638z M41.55,68.486c0.033-0.007,0.066-0.013,0.099-0.021 C41.616,68.473,41.583,68.479,41.55,68.486z M42.45,68.258c0.087-0.025,0.174-0.052,0.261-0.079 C42.624,68.206,42.537,68.233,42.45,68.258z M43.497,67.908c0.041-0.016,0.082-0.03,0.124-0.046 C43.579,67.878,43.538,67.893,43.497,67.908z M44.466,67.504c0.089-0.04,0.178-0.082,0.267-0.124 C44.644,67.422,44.555,67.463,44.466,67.504z M45.551,66.97c0.03-0.016,0.06-0.03,0.089-0.046 C45.61,66.94,45.581,66.954,45.551,66.97z"
          />
          <g>
            <g>
              <path
                fill="#964F30"
                strokeWidth={0}
                d="M34.803,43.919c0.05,1.435-1.073,2.639-2.508,2.689c-1.435,0.05-2.639-1.073-2.689-2.508 c-0.05-1.435,1.073-2.639,2.508-2.689C33.549,41.362,34.753,42.484,34.803,43.919z"
              />
              <path
                fill="#3C2417"
                strokeWidth={0}
                d="M34.803,43.261c0.05,1.435-1.073,2.639-2.508,2.689c-1.435,0.05-2.639-1.073-2.689-2.508 c-0.05-1.435,1.073-2.639,2.508-2.689C33.549,40.703,34.753,41.826,34.803,43.261z"
              />
              <path
                fill="#FFFFFF"
                strokeWidth={0}
                d="M33.737,42.518c0.013,0.359-0.268,0.66-0.627,0.672c-0.359,0.013-0.66-0.268-0.672-0.627 c-0.013-0.359,0.268-0.66,0.627-0.672C33.424,41.878,33.725,42.159,33.737,42.518z"
              />
            </g>
            <g>
              <path
                fill="#964F30"
                strokeWidth={0}
                d="M49.945,43.919c0.05,1.435-1.073,2.639-2.508,2.689c-1.435,0.05-2.639-1.073-2.689-2.508 c-0.05-1.435,1.073-2.639,2.508-2.689C48.691,41.362,49.895,42.484,49.945,43.919z"
              />
              <path
                fill="#3C2417"
                strokeWidth={0}
                d="M49.945,43.261c0.05,1.435-1.073,2.639-2.508,2.689c-1.435,0.05-2.639-1.073-2.689-2.508 c-0.05-1.435,1.073-2.639,2.508-2.689C48.691,40.703,49.895,41.826,49.945,43.261z"
              />
              <path
                fill="#FFFFFF"
                strokeWidth={0}
                d="M48.879,42.518c0.013,0.359-0.268,0.66-0.627,0.672c-0.359,0.013-0.66-0.268-0.672-0.627 c-0.013-0.359,0.268-0.66,0.627-0.672C48.565,41.878,48.866,42.159,48.879,42.518z"
              />
            </g>
          </g>
          <g>
            <path
              fill="#F05D44"
              strokeWidth={0}
              d="M45.702,57.502c0,2.909-2.653,5.267-5.925,5.267s-5.925-2.358-5.925-5.267H45.702z"
            />
            <path
              fill="#FFFFFF"
              strokeWidth={0}
              d="M43.991,59.477h-8.558c-0.364,0-0.658-0.295-0.658-0.658v-1.317h9.875v1.317 C44.65,59.182,44.355,59.477,43.991,59.477z"
            />
          </g>
          <path
            fill="#3C2417"
            strokeWidth={0}
            d="M59.862,24.815c0-1.855-1.379-3.384-3.167-3.629c-0.209-1.204-0.954-2.223-1.982-2.802 c0.012-0.132,0.02-0.265,0.02-0.4c0-2.408-1.952-4.36-4.36-4.36c-0.415,0-0.815,0.062-1.195,0.17 c-0.841-1.186-2.221-1.962-3.785-1.962c-0.848,0-1.641,0.231-2.325,0.629c-0.74-0.569-1.663-0.911-2.668-0.911 c-1.077,0-2.061,0.389-2.824,1.033c-0.645-0.424-1.416-0.672-2.246-0.672c-1.532,0-2.853,0.849-3.556,2.094 c-0.454-0.237-0.963-0.383-1.51-0.383c-1.709,0-3.098,1.308-3.26,2.974c-0.052,0.073-0.108,0.142-0.156,0.218 c-1.608,0.262-2.896,1.466-3.28,3.03c-0.098-0.008-0.195-0.015-0.295-0.015c-2.016,0-3.65,1.634-3.65,3.65 c0,0.947,0.364,1.807,0.955,2.456c-0.795,5.19-0.254,14.361,0.701,18.498c0.173,0.75,1.269,0.639,1.298-0.13 c0.187-4.935,1.333-14.363,6.3-13.641c4.742,0.69,8.259,0.924,10.902,0.905c2.643,0.02,6.16-0.215,10.902-0.905 c4.966-0.722,6.113,8.705,6.3,13.641c0.029,0.769,1.125,0.88,1.298,0.13c0.891-3.863,1.421-12.11,0.841-17.412 C59.583,26.407,59.862,25.644,59.862,24.815z"
          />
        </g>
      </g>
    </g>
  ),
  viewBox: '0 0 80 80'
};
