import Panel from 'components/UI/Panel/Panel';
import SmallCardText from 'components/UI/Typography/SmallCardText';
import Subhead from 'components/UI/Typography/Subhead';
import { Color } from 'modules/styles/colors';
import { Spacing } from 'modules/styles/variables';
import styled from 'styled-components';
import { MuiBox } from 'theme/material-ui';
import { BorderRadius } from 'modules/styles/variables.web';

export const Container = styled.div`
  margin-bottom: ${Spacing.medium}px;
  flex: 1;
  height: 100%;
  width: 100%;
`;

export const StyledView = styled.div`
  margin-bottom: ${Spacing.medium}px;
`;

export const StyledSubhead = styled(Subhead)`
  margin-top: ${Spacing.large}px;
`;

export const ProviderItemStyledPanel = styled(MuiBox)`
  border-radius: ${BorderRadius.none}px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${Spacing.large}px;
`;

export const AddressContainer = styled.div`
  flex-direction: row;
  align-items: center;
`;

export const StyledPanel = styled(Panel)`
  border-bottom-width: 0.5px;
  border-bottom-color: ${Color.grayLight};
  border-bottom-left-radius: ${BorderRadius.base}px;
  border-top-left-radius: ${BorderRadius.none}px;
  border-bottom-right-radius: ${BorderRadius.base}px;
  border-top-right-radius: ${BorderRadius.none}px;
`;

export const DeductibleRow = styled.div`
  flex-direction: row;
  padding: ${Spacing.small}px ${Spacing.small}px ${Spacing.none}px ${Spacing.none}px;
  justify-content: space-between;
`;

export const CostText = styled(SmallCardText)`
  font-weight: bold;
  padding: ${Spacing.small}px;
`;

export const BoldSmallCardText = styled(SmallCardText)`
  font-weight: bold;
`;
