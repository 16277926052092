import React, { useState, useEffect } from 'react';
import { Checkbox } from '@material-ui/core';
import styled from 'styled-components';

interface ColorCheckboxProps {
  $customColor?: string;
  $disablePadding?: string;
}

const StyledCheckbox = styled(Checkbox)`
  && {
    color: ${({ $customColor }: ColorCheckboxProps) => $customColor};
    ${({ $disablePadding }: ColorCheckboxProps) => ($disablePadding ? 'padding: 0;' : '')}
  }
`;

const ColorCheckbox = ({ customColor, disablePadding, checked, ...props }: any) => {
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  return (
    <StyledCheckbox
      color="default"
      $customColor={customColor}
      $disablePadding={disablePadding}
      checked={isChecked || false}
      {...props}
    />
  );
};

export default ColorCheckbox;
