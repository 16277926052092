import React from 'react';
import styled from 'styled-components';
import { Color } from 'modules/styles/colors';
import { Spacing } from 'modules/styles/variables';
import SmallText from 'components/UI/Typography/SmallText';

interface Props {
  requiredLabel?: boolean;
  error?: string | null;
  accessibilityLabel?: string;
}

const Container = styled.div`
  width: 100%;
`;

const RequiredLabel = styled(SmallText)`
  right: 10px;
  position: absolute;
  top: 9px;
  color: ${Color.red};
`;

const ErrorText = styled(SmallText)`
  margin-top: ${Spacing.medium};
  color: ${Color.red};
`;

const InputPicker = React.forwardRef(({ requiredLabel, error, accessibilityLabel }: Props) => {
  return (
    <Container accessibilityLabel={accessibilityLabel}>
      <div>PickerSelect</div>
      {requiredLabel ? <RequiredLabel>*</RequiredLabel> : null}
      {error ? <ErrorText>{error}</ErrorText> : null}
    </Container>
  );
});

export default InputPicker;
