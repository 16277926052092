import styled, { css } from 'styled-components';
import Section from 'components/UI/Layout/Section';
import { Color } from 'modules/styles/colors';

const widthShadowStyles = (props: { shadow?: boolean }) => {
  return (
    props.shadow &&
    css`
      box-shadow: 0px 2px 1px ${Color.grayHue5};
    `
  );
};

export const FloatableSection = styled(Section)`
  margin-top: -30px;
  flex: 0 1 auto;
  ${widthShadowStyles}
`;
