import styled from 'styled-components';

import {
  headerHeight,
  sideBarWidthCollapsed,
  sideBarWidthOpen
} from 'components/AuthLayout/styled';
import { ZIndex } from 'modules/styles/variables';
import { dfdDefaultTheme, MuiDrawer } from 'theme/material-ui';
import { SidebarProps } from './index';

export const StyledSidebar = styled(MuiDrawer)`
  &&& {
    height: 100%;
    background-color: transparent;
    transition: width 100ms ease;
    z-index: ${(props: SidebarProps) => (props.collapsed ? ZIndex.medium - 2 : ZIndex.medium + 1)};
    width: ${(props: SidebarProps) =>
      props.collapsed ? `${sideBarWidthCollapsed}px` : `${sideBarWidthOpen}px`};
    height: ${(props: SidebarProps) =>
      props.collapsed ? `calc(100% - ${headerHeight}px)` : `100%`};
    ${dfdDefaultTheme.breakpoints.up('sm')} {
      box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.5);
    }
    margin-top: ${(props: SidebarProps) => (props.collapsed ? `${headerHeight}px` : '0px')};
    ${dfdDefaultTheme.breakpoints.down('xs')} {
      box-shadow: none;
    }
    & > div.MuiPaper-root {
      border: none;
    }
  }
`;
