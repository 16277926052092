import React, { useEffect } from 'react';
import { View, TouchableOpacity } from 'react-native';
import { connect } from 'react-redux';
import { KeyboardArrowRight } from '@material-ui/icons';
import { useHistory } from 'react-router';
import isNil from 'lodash/isNil';
import * as snacksActions from 'store/snacks/actions';
import { getCollectionsDetails } from 'store/billing/actions';
import { getCollectionsDetailsSelector } from 'store/billing/selectors';
import { SnackType, Snack } from 'store/snacks/types';
import { NavigationScreenProps } from 'screens/navigation';
import { RootState } from 'store/types';
import { consumerIdSelector } from 'store/profile/selectors';
import formatMoney from 'services/formatMoney';
import { Color } from 'modules/styles/colors';
import { formatDate } from 'modules/utils/DateUtils';
import { FontSize, FontWeight } from 'modules/styles/variables';
import ScrollableLayout from 'components/UI/Layout/ScrollableLayout';
import Typography from 'components/UI/Typography';
import FlexBox from 'components/UI/Layout/FlexBox';
import DefaultText from 'components/UI/Typography/DefaultText';
import Spacer from 'components/UI/Layout/Spacer';
import Divider from 'components/UI/Divider';
import Spinner from 'components/UI/Spinner/Spinner';
import { renderBadDebt } from 'components/billing/BadDebt';
import Section from 'components/UI/Layout/Section';
import { SpacedRowFlexBox, Panel, EmptyFlexBox } from './styled';
import analyticsService, { AmplitudeEventData, AnalyticsEvent } from 'services/AnalyticsService';
import {
  currentLocationPathNameSelector,
  previousLocationPathNameSelector
} from 'store/router/selectors';
import { CollectionsDetailsState } from 'store/billing/reducers';
import { EncounterListItem } from 'store/billing/types';

type Props = NavigationScreenProps & {
  consumerId: string;
  createSnack: typeof snacksActions.createSnack;
  currentUrl?: string;
  referringUrl?: string;
  fetchCollectionsDetails: typeof getCollectionsDetails;
  collectionsDetails?: CollectionsDetailsState;
};

export const CollectionsSummary = (props: Props) => {
  const history = useHistory();
  const { currentUrl, referringUrl, fetchCollectionsDetails, collectionsDetails } = props;
  const { data, isFetching: loading, error: guarantorError } = collectionsDetails || {};
  const eventData: AmplitudeEventData = {
    currentUrl,
    referringUrl
  };

  useEffect(() => {
    fetchCollectionsDetails();
  }, []);

  useEffect(() => {
    if (guarantorError && currentUrl === 'CollectionsSummary') {
      props.createSnack(SnackType.BASE, { label: 'Unable to load data, please try again' });
    }
  }, [currentUrl, guarantorError]);

  const collections = data?.encounters || [];
  const collectionsBalance = data?.collectionsBalance || '0';
  const totalInCollections = data?.totalInCollections;

  const goToBillingDetail = (item: EncounterListItem) => {
    analyticsService.logEvent(AnalyticsEvent.IndividualBillingClicked, eventData);

    return history.push({
      pathname: `/u/billing/bill-detail/${item.id}`
    });
  };

  return (
    <>
      <ScrollableLayout>
        <Section accessibilityLabel="Collections List Section">
          <Panel accessibilityLabel="Collections List">
            <View accessibilityLabel="collections summary list">
              {renderBadDebt(
                parseFloat(collectionsBalance),
                totalInCollections ? 'In Collections' : null
              )}
              {collections.length === 0 ? (
                loading ? (
                  <EmptyFlexBox>
                    <Spacer size="large" />
                    <Spinner />
                  </EmptyFlexBox>
                ) : (
                  <EmptyFlexBox>
                    <Spacer size="large" />
                    <DefaultText>No Bills in Collections</DefaultText>
                  </EmptyFlexBox>
                )
              ) : (
                collections.map((eachCollectin: EncounterListItem) => (
                  <TouchableOpacity
                    accessible
                    accessibilityLabel={`Tap to view bill detail ${formatDate(
                      eachCollectin.dischargeDate
                    )} ${eachCollectin.patient} $${formatMoney(eachCollectin.balance)}`}
                    onPress={() => goToBillingDetail(eachCollectin)}
                    key={eachCollectin.id}
                  >
                    <SpacedRowFlexBox justifyContent="space-between">
                      <FlexBox flex={1} flexDirection="column">
                        <Typography
                          accessibilityLabel={`Date of collection is ${formatDate(
                            eachCollectin.dischargeDate
                          )}`}
                          numberOfLines={1}
                          fontSize={FontSize.small}
                          color={Color.gray}
                        >{`${formatDate(eachCollectin.dischargeDate)}`}</Typography>
                        <Typography
                          accessibilityLabel={`Collections description is ${eachCollectin.billingDescription}`}
                          numberOfLines={1}
                          fontSize={FontSize.large}
                          fontWeight={FontWeight.bold}
                        >{`${eachCollectin.billingDescription}`}</Typography>
                        <Typography
                          numberOfLines={1}
                          fontSize={FontSize.base}
                          accessibilityLabel={`Patient name is ${eachCollectin.patient.firstName} ${eachCollectin.patient.lastName}`}
                        >
                          {`${eachCollectin.patient.firstName} ${eachCollectin.patient.lastName}`}
                        </Typography>
                        <Typography
                          fontSize={FontSize.base}
                          accessibilityLabel={`Balance of collection is $${formatMoney(
                            eachCollectin.balance
                          )}`}
                        >
                          {eachCollectin.paymentPlan
                            ? 'Payment Plan'
                            : `$${formatMoney(eachCollectin.balance)}`}
                        </Typography>
                      </FlexBox>
                      <FlexBox
                        justifyContent="space-between"
                        alignItems="center"
                        flexDirection="row"
                      >
                        {!isNil(eachCollectin.inCollections) ? (
                          <Typography
                            color={Color.red}
                            fontSize={FontSize.base}
                            accessibilityLabel="Due status of collection"
                          >
                            In Collections
                          </Typography>
                        ) : null}
                        <KeyboardArrowRight />
                      </FlexBox>
                    </SpacedRowFlexBox>
                    <Divider />
                  </TouchableOpacity>
                ))
              )}
            </View>
          </Panel>
        </Section>
        {!loading && !collections.length ? (
          <>
            <FlexBox flexDirection="row" justifyContent="center" alignItems="center">
              <TouchableOpacity>
                <Typography color={Color.gray}>No More Bills in Collections</Typography>
              </TouchableOpacity>
            </FlexBox>
            <Spacer size="medium" />
          </>
        ) : null}
      </ScrollableLayout>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  consumerId: consumerIdSelector(state),
  currentUrl: currentLocationPathNameSelector(state),
  referringUrl: previousLocationPathNameSelector(state),
  collectionsDetails: getCollectionsDetailsSelector(state)
});

const mapDispatchToProps = (dispatch: Function) => ({
  createSnack: (type: SnackType, snack?: Snack) => dispatch(snacksActions.createSnack(type, snack)),
  fetchCollectionsDetails: () => dispatch(getCollectionsDetails())
});

export default connect(mapStateToProps, mapDispatchToProps)(CollectionsSummary);
