import React, { useState } from 'react';
import styled from 'styled-components';
import { getLocationsByAddress } from 'services/LocationService/placePredictions';
import { PlacePrediction, GeoCodeLocation } from 'services/LocationService/types';
import { AddressAutoComplete } from './AddressAutoComplete';

interface Props {
  flex?: string;
  handleOnPress: (val: GeoCodeLocation) => void;
  hideIcon?: boolean;
  placeholder?: string;
  width?: string;
  noOptionsText?: string;
}

const Container = styled.div<{ width?: string; flex?: string }>`
  width: ${props => props.width || '100%'};
  ${props => (props.flex ? `flex: ${props.flex};` : '')}
`;

export const GooglePlacesInput = ({
  flex,
  handleOnPress,
  hideIcon,
  placeholder,
  width,
  noOptionsText
}: Props) => {
  const [options, setOptions] = useState([] as PlacePrediction[]);

  function handleInputChange(evt: any) {
    const { value } = evt.target;
    if (value) {
      getLocationsByAddress(evt.target.value).then((results: PlacePrediction[]) =>
        setOptions(results)
      );
    }
  }

  return (
    <Container width={width} flex={flex}>
      <AddressAutoComplete
        noOptionsText={noOptionsText}
        options={options}
        handleInputChange={handleInputChange}
        hideIcon={hideIcon}
        handleOnPress={handleOnPress}
        placeholder={placeholder}
      />
    </Container>
  );
};
