import React from 'react';
import { ScrollView, ViewStyle } from 'react-native';
import styled from 'styled-components';

import { Color } from 'modules/styles/colors';
import FixedLayout from './FixedLayout';

const SafeContainer = styled.div`
  flex: 1 1 auto;
`;

const StyledScrollView = styled(ScrollView)<{ backgroundColor?: string }>`
  background-color: ${props => (props.backgroundColor ? props.backgroundColor : Color.baseColor)};
`;

interface Props {
  children: any;
  contentContainerStyle?: ViewStyle;
  backgroundColor?: string;
  refreshControl?: any;
}

export default (props: Props) => (
  <SafeContainer>
    <StyledScrollView {...props}>
      <FixedLayout backgroundColor={props.backgroundColor}>{props.children}</FixedLayout>
    </StyledScrollView>
  </SafeContainer>
);
