import React, { Fragment, useCallback, useState } from 'react';
import { US_STATES, USState } from './constants';
import { MapSvg, BlurPath, USStatePath } from './styled';

export interface MapProps {
  size: number;
  value?: USState | null;
  readOnly?: boolean;
  onClick?: (e: React.MouseEvent<SVGPathElement, MouseEvent>, usState: USState) => void;
}

function Map(props: MapProps) {
  const { size, value, readOnly, onClick } = props;
  const [focusedEl, setFocusedEl] = useState<EventTarget & SVGPathElement>();
  const isSelected = (usState: USState) => usState === value;

  const handleMouseEnter = useCallback(
    (usState: USState) => (e: React.MouseEvent<SVGPathElement, MouseEvent>) => {
      if (readOnly || isSelected(usState)) return;
      setFocusedEl(e.currentTarget);
    },
    [focusedEl, readOnly]
  );

  const handleMouseLeave = useCallback(
    (usState: USState) => () => {
      if (readOnly || isSelected(usState)) return;
      setFocusedEl(undefined);
    },
    [focusedEl, readOnly]
  );

  const handleClick = (usState: USState) => (e: React.MouseEvent<SVGPathElement, MouseEvent>) => {
    if (readOnly || isSelected(usState)) return;
    if (onClick) {
      onClick(e, usState);
    }
  };

  return (
    <MapSvg size={size}>
      <filter id="dropshadow" x="-2" y="-2" width="200" height="200">
        <feGaussianBlur stdDeviation="1" />
      </filter>
      {US_STATES.map(usState => (
        <Fragment key={usState.value}>
          {focusedEl?.id === usState.value ? (
            <BlurPath
              data-testid={`${usState.value} blur`}
              d={focusedEl?.getAttribute('d') || ''}
            />
          ) : null}
          <USStatePath
            data-testid={usState.value}
            id={usState.value}
            d={usState.d}
            readOnly={readOnly}
            selected={usState === value}
            onClick={handleClick(usState)}
            onMouseEnter={handleMouseEnter(usState)}
            onMouseLeave={handleMouseLeave(usState)}
          />
        </Fragment>
      ))}
    </MapSvg>
  );
}

Map.defaultProps = {
  size: 450
};

export default Map;
