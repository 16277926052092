import React, { useEffect, ComponentType } from 'react';
import dayjs from 'dayjs';
import Spacer from 'components/UI/Layout/Spacer';
import Divider from 'components/UI/Divider';
import { Svg } from 'components/UI/Svg';
import { AuthLiteHeader } from 'components/AuthLite';
import jsonwebtoken from 'jsonwebtoken';
import { connect, useDispatch } from 'react-redux';
import analyticsService, { AmplitudeEventData, AnalyticsEvent } from 'services/AnalyticsService';
import Encryption from 'services/Encryption';
import {
  authLiteDownloadLabDetails,
  getAuthLiteTestResultDetails,
  authLiteDownloadPatientLetter
} from 'store/testResults/actions';
import * as testResults from 'store/testResults/selectors';
import * as appSettingsSelectors from 'store/appSettings/selectors';
import * as routeSelectors from 'store/router/selectors';
import { RootState, RootDispatch } from 'store/types';
import { TestResult, LabResultsListItems, LabDetails } from 'store/testResults/types';
import Typography from 'components/UI/Typography';
import { Color } from 'modules/styles/colors';
import { FontSize, FontWeight, IconSize } from 'modules/styles/variables';
import { FlexBoxRow } from 'components/UI/Layout/FlexBox';
import Spinner from 'components/UI/Spinner/Spinner';
import {
  MuiBox,
  MuiPaper,
  MuiTypography,
  MuiButton,
  MuiLink,
  dfdDefaultTheme
} from 'theme/material-ui';
import { FooterBanner, DownloadToast, StyledText, IconText } from '../styled';
import { authLiteDataSelector } from 'store/authentication/selectors';
import { AuthLiteResponse } from 'store/authentication/types';
import { DetailsCommentsContainer, PageContainer } from './styled';
import { History } from 'history';
import { patchAuthLiteEventBySourceId } from 'store/events/actions';
import {
  labDetailsDownloadingSelector,
  patientLetterDownloadingSelector,
  labDetailsDownloadDataSelector,
  patientLetterDownloadDataSelector
} from 'store/testResults/selectors';
import { isIOS } from 'lib/browser';
import { MY_HEALTH_PLUS_DOWNLOAD_SPLASH } from '../constants';
import { technicalHelpNumber } from 'modules/constants/phoneNumbers';
import Loading from './Loading';
import Error from './Error';
import LabResults from './LabResults';
import RelatedDocuments from './RelatedDocuments';
import LabDetailSection from './LabDetails';
import LabComments from './LabComments';
import { AppName, getAppName } from 'modules/utils/ConfigUtils';

interface UserData {
  firstName: string;
  lastName: string;
  gender: string;
  dateOfBirth: string;
}

export interface EventTestResultProps {
  loading: boolean;
  error: Error | null;
  data: { content: LabDetails | null };
  isDownloading: boolean;
  labDetailLoading: boolean;
  patientLetterLoading: boolean;
  labDetailData: string;
  patientLetterData: string;
  testResult: TestResult;
  authLiteData: AuthLiteResponse;
  downloadLabs: (testResult: TestResult) => void;
  downloadPatientLetter: (documentId: string) => void;
  history: History;
  currentUrl?: string;
  referringUrl?: string;
  encryptionSettings: {
    password: string;
    algorithm: string;
  };
}

export const EventTestResultComponent = (props: EventTestResultProps) => {
  const {
    loading,
    error,
    data,
    isDownloading,
    authLiteData,
    downloadLabs,
    downloadPatientLetter,
    labDetailLoading,
    patientLetterLoading,
    encryptionSettings,
    referringUrl,
    labDetailData,
    patientLetterData
  } = props;
  const dispatch = useDispatch();
  const [isSpanish, setIsSpanish] = React.useState(false);
  const [userData, setUserData] = React.useState<UserData | null>(null);
  const [showToast, setShowToast] = React.useState(false);

  const results = data?.content?.results || [];
  const result = results[0];
  const labResults = results.reduce((acc: LabResultsListItems[], { name, source, components }) => {
    const labResultsListItems = components.map(comp => ({
      ...comp,
      source,
      panelName: name
    }));
    return acc.concat(...labResultsListItems);
  }, []);

  const eventUrlData = { currentUrl: '/al/test-results', referringUrl };

  useEffect(() => {
    if (result?.name) {
      const eventData: AmplitudeEventData = {
        ...eventUrlData,
        authentication_type: 'Light',
        // we can not pass name to Amplitude as it is PHI
        test_name: Encryption.encrypt(
          encryptionSettings.algorithm,
          encryptionSettings.password,
          result.name
        ),
        test_type: 'Lab',
        test_result_displayed: 'Success'
      };
      analyticsService.logEvent(AnalyticsEvent.TestResultsDetailsViewed, eventData);
    }
  }, [data]);

  useEffect(() => {
    if (error && result?.name) {
      const eventData: AmplitudeEventData = {
        ...eventUrlData,
        authentication_type: 'Light',
        // we can not pass name to Amplitude as it is PHI
        test_name: Encryption.encrypt(
          encryptionSettings.algorithm,
          encryptionSettings.password,
          result.name
        ),
        test_type: 'Lab',
        test_result_displayed: 'Error',
        test_result_error: error
      };
      analyticsService.logEvent(AnalyticsEvent.TestResultsDetailsViewed, eventData);
    }
  }, [error]);

  useEffect(() => {
    if (authLiteData) {
      const resultJwt = jsonwebtoken.decode(authLiteData.accessToken, { complete: true });
      setUserData(resultJwt?.payload?.user);

      dispatch(
        getAuthLiteTestResultDetails({
          url: authLiteData.mhpDetails?.url,
          responseType: authLiteData.mhpDetails?.contentType || '',
          headers: { ...authLiteData.mhpDetails?.headers } || null
        })
      );
      dispatch(patchAuthLiteEventBySourceId(authLiteData.clinicalEventId, { read: true }));
    }
  }, [authLiteData]);

  useEffect(() => {
    if (Object.values(labDetailData).some(a => a === 'success')) {
      setShowToast(true);
    }
    const timer = setTimeout(() => {
      setShowToast(false);
    }, 3000);
    return () => {
      clearTimeout(timer);
    };
  }, [labDetailData]);

  useEffect(() => {
    if (Object.values(patientLetterData).some(a => a === 'success')) {
      setShowToast(true);
    }
    const timer = setTimeout(() => {
      setShowToast(false);
    }, 3000);
    return () => {
      clearTimeout(timer);
    };
  }, [patientLetterData]);

  const onDownloadPress = () => {
    if (authLiteData) {
      const evnentData: AmplitudeEventData = {
        ...eventUrlData,
        authentication_type: 'Light'
      };
      analyticsService.logEvent(AnalyticsEvent.DownloadTestResultsClicked, evnentData);
      downloadLabs(result);
    }
  };

  const openAppDownload = () => {
    const evnentData: AmplitudeEventData = {
      ...eventUrlData,
      promotion_type: 'COVID-19 light authentication: footer'
    };
    analyticsService.logEvent(AnalyticsEvent.PromotionLink, evnentData);

    window.open(MY_HEALTH_PLUS_DOWNLOAD_SPLASH);
  };

  const onSpanishPress = () => {
    const evnentData: AmplitudeEventData = {
      ...eventUrlData,
      language: 'Spanish'
    };
    analyticsService.logEvent(AnalyticsEvent.SwitchLanguage, evnentData);
    setIsSpanish(!isSpanish);
  };

  if (loading) return <Loading />;
  if (error) return <Error message="An error occurred retrieving Lab details" />;
  if (!data) return <Error message="No data available" />;

  return (
    <>
      <MuiBox bgcolor={Color.grayHue4}>
        <AuthLiteHeader />
        <PageContainer>
          <MuiBox maxWidth={dfdDefaultTheme.breakpoints.values.xl} marginX="auto" marginY={4}>
            <MuiBox>
              <MuiButton
                variant="outlined"
                color="primary"
                onClick={isSpanish ? () => setIsSpanish(!isSpanish) : onSpanishPress}
                data-testid="language-change-button"
              >
                <MuiTypography>{isSpanish ? 'English' : 'Español'}</MuiTypography>
              </MuiButton>
            </MuiBox>
            <MuiBox component={MuiPaper} p={3}>
              <FlexBoxRow>
                <MuiBox>
                  <MuiTypography>{`Collection Date/Time: ${dayjs(result?.collectionDate).format(
                    'MMM DD, YYYY [at] h:mm A'
                  )}`}</MuiTypography>
                  {result?.title ? (
                    <MuiTypography variant="h5" fontWeight={FontWeight.semibold}>
                      {result.title}
                    </MuiTypography>
                  ) : null}
                  <Spacer size="small" />
                  <MuiTypography>{`Caregiver: ${result?.referringProvider?.providerName ||
                    'N/A'}`}</MuiTypography>
                  <MuiTypography>{`Test Location: ${
                    result.testLocation ? result.testLocation : 'N/A'
                  }`}</MuiTypography>
                  {userData ? (
                    <>
                      <MuiTypography>{`Patient: ${
                        userData ? `${userData.lastName}, ${userData.firstName}` : 'N/A'
                      }`}</MuiTypography>
                      <MuiTypography>{`DOB: ${
                        userData ? userData.dateOfBirth : 'N/A'
                      }`}</MuiTypography>
                      <MuiTypography>{`Gender: ${
                        userData ? userData.gender : 'N/A'
                      }`}</MuiTypography>
                    </>
                  ) : null}
                </MuiBox>
                <MuiBox marginLeft="auto">
                  <MuiButton
                    variant="contained"
                    color="primary"
                    onClick={onDownloadPress}
                    data-testid="download-button"
                  >
                    {labDetailLoading ? (
                      <Spinner />
                    ) : (
                      <>
                        <Svg
                          set={isDownloading ? 'downloading' : 'material'}
                          name="get-app"
                          color={Color.white}
                          size={IconSize.base}
                        />
                        <IconText>Download</IconText>
                      </>
                    )}
                  </MuiButton>
                </MuiBox>
              </FlexBoxRow>
            </MuiBox>
            <MuiBox component={MuiPaper} marginY={4}>
              <MuiBox>
                {referringUrl ? (
                  <RelatedDocuments
                    referringUrl={referringUrl}
                    content={results}
                    downloadPatientLetter={downloadPatientLetter}
                    patientLetterLoading={patientLetterLoading}
                  />
                ) : null}
              </MuiBox>
            </MuiBox>
            <MuiBox component={MuiPaper} marginY={4}>
              <MuiBox>
                <LabResults content={labResults} isSpanish={isSpanish} />
              </MuiBox>
            </MuiBox>
            <DetailsCommentsContainer>
              <MuiBox flex={1} component={MuiPaper}>
                <MuiBox>
                  <LabDetailSection content={results} />
                </MuiBox>
              </MuiBox>
              <MuiBox flex={1} component={MuiPaper}>
                <MuiBox>
                  <LabComments content={results} />
                </MuiBox>
              </MuiBox>
            </DetailsCommentsContainer>
          </MuiBox>
        </PageContainer>
      </MuiBox>
      <DownloadToast open={showToast && !isIOS()}>
        <MuiBox m={2} display="flex" flexDirection="row">
          <Svg name="check" set="material" size={IconSize.large} color={Color.white} />
          <StyledText>
            File has downloaded successfully. Please check your downloads folder.
          </StyledText>
        </MuiBox>
      </DownloadToast>
      <FooterBanner>
        <Divider />
        <MuiBox p={2}>
          <FlexBoxRow justifyContent="center">
            <Typography fontSize={FontSize.small} fontWeight={FontWeight.semibold}>
              These results are available for 72 hours. Get{' '}
              {getAppName(AppName.base, 'the', AppName.suffix1)} for long term access to these and
              all of your Intermountain medical data
            </Typography>
          </FlexBoxRow>
          <Spacer size="small" />
          <FlexBoxRow justifyContent="center">
            <MuiBox width="300px">
              <MuiButton
                variant="contained"
                color="primary"
                onClick={openAppDownload}
                data-testid="event-link-expired-mhp-button"
              >
                Get {getAppName(AppName.base, 'the', AppName.suffix1)}
              </MuiButton>
            </MuiBox>
          </FlexBoxRow>
          <MuiBox>
            <Spacer size="small" />
            <MuiTypography fontSize={FontSize.small} display="inline">
              If you need technical support please call{' '}
            </MuiTypography>
            <MuiLink href={`tel: ${technicalHelpNumber}`}>
              <MuiTypography display="inline" fontSize={FontSize.small}>
                {technicalHelpNumber}
              </MuiTypography>
            </MuiLink>
          </MuiBox>
        </MuiBox>
      </FooterBanner>
    </>
  );
};

const mapDispatchToProps = (dispatch: RootDispatch) => ({
  downloadLabs: (testResult: TestResult) => dispatch(authLiteDownloadLabDetails(testResult)),
  downloadPatientLetter: (documentId: string) => dispatch(authLiteDownloadPatientLetter(documentId))
});

const mapStateToProps = (state: RootState) => ({
  authLiteData: authLiteDataSelector(state),
  labDetailLoading: labDetailsDownloadingSelector(state),
  labDetailData: labDetailsDownloadDataSelector(state),
  patientLetterLoading: patientLetterDownloadingSelector(state),
  patientLetterData: patientLetterDownloadDataSelector(state),
  loading: testResults.testResultDetailsLoadingSelector(state),
  error: testResults.testResultDetailsErrorSelector(state),
  data: testResults.testResultDetailsDataSelector(state),
  referringUrl: routeSelectors.previousLocationPathNameSelector(state),
  encryptionSettings: appSettingsSelectors.encryptionSettingsSelector(state)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EventTestResultComponent as ComponentType);
