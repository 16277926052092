import React from 'react';
import styled from 'styled-components';
import { MuiCard, MuiCardContent, MuiDialog, MuiGrid, MuiBox } from 'theme/material-ui';

import {
  AddressContainer,
  BadgeColContainer,
  CardColMedia,
  DetailTitleCardText,
  PaddedContainer,
  TextContainer
} from 'components/getCare/FacilityList/styled';
import WaitTimeBadge from 'components/getCare/WaitTime/WaitTimeBadge';
import { Icon } from 'components/Icon';

import SmallCardText from 'components/UI/Typography/SmallCardText';
import Subhead from 'components/UI/Typography/Subhead';
import { SmallCardHours } from 'components/UI/Typography/styled';

import { Color } from 'modules/styles/colors';
import { WEEK_DAYS } from 'modules/constants/WeekDays';
import { Spacing } from 'modules/styles/variables';
import { Facility } from 'store/getCare/liveCare/types';

const HoursCard = (day: string, facilityHours: string) => (
  <SmallCardHours key={day}>{` ${day}: ${facilityHours}`}</SmallCardHours>
);

const renderOperatingHours = (
  operatingHours: Record<string, { Open: string; Close: string; IsOpen: boolean }>
) =>
  WEEK_DAYS.map(day => {
    if (operatingHours[day].Open !== operatingHours[day].Close) {
      return HoursCard(day, ` ${operatingHours[day].Open} - ${operatingHours[day].Close}`);
    }

    if (operatingHours.is24Hours || operatingHours[day].IsOpen) return HoursCard(day, ' 24 Hour');

    return HoursCard(day, ' Closed');
  });

export interface FacilityDetailsProps {
  open: boolean;
  facility?: Facility;
  onClose: () => void;
}

export const FacilityDetailsDialog = ({ onClose, facility, open }: FacilityDetailsProps) => {
  return facility ? (
    <MuiDialog onClose={onClose} open={open}>
      <StyledMuiCard>
        <BadgeColContainer>
          {facility.isCurrentlyOpen && (
            <WaitTimeBadge
              isOpen={facility.isCurrentlyOpen}
              time={facility.currentWaitTime}
              text="minute"
            />
          )}
        </BadgeColContainer>
        <CardColMedia imageUrl={facility.locationImageUrl} />
        <MuiCardContent>
          <MuiGrid container>
            <MuiGrid item xs={6}>
              <PaddedContainer>
                <TextContainer>
                  <DetailTitleCardText data-testid="facility-location-name">
                    {facility.locationName}
                  </DetailTitleCardText>
                  <AddressContainer>
                    <MuiBox mt={0.5}>
                      <Icon name="location-on" color={Color.primary} size={15} />
                    </MuiBox>
                    <MuiGrid item>
                      <SmallCardText>{facility.address1}</SmallCardText>
                    </MuiGrid>
                  </AddressContainer>
                  <AddressContainer>
                    <SmallCardText className="distanceTextSpacer">
                      {`Distance: ${parseFloat(facility.distanceToUser).toFixed(2)} miles`}
                    </SmallCardText>
                  </AddressContainer>
                  <StyledMuiGridContainer container>
                    <MuiBox mt={0.5}>
                      <Icon name="schedule" color={Color.primary} size={15} />
                    </MuiBox>
                    <MuiGrid item>
                      <SmallCardText>
                        Currently: {facility.isCurrentlyOpen ? 'Open' : 'Closed'}
                      </SmallCardText>
                    </MuiGrid>
                  </StyledMuiGridContainer>
                  <StyledMuiGridContainer container>
                    <MuiBox mt={0.5}>
                      <Icon name="phone" color={Color.primary} size={15} />
                    </MuiBox>
                    <MuiGrid item>
                      <SmallCardText>{facility.phone}</SmallCardText>
                    </MuiGrid>
                  </StyledMuiGridContainer>
                </TextContainer>
              </PaddedContainer>
            </MuiGrid>
            <MuiGrid item xs={6}>
              <PaddedContainer>
                <Subhead>Operating Hours:</Subhead>
                <div>{renderOperatingHours(facility.operatingHours)}</div>
              </PaddedContainer>
            </MuiGrid>
          </MuiGrid>
        </MuiCardContent>
      </StyledMuiCard>
    </MuiDialog>
  ) : null;
};

const StyledMuiCard = styled(MuiCard)`
  width: 480px;
`;

const StyledMuiGridContainer = styled(MuiGrid)`
  margin-top: ${Spacing.medium}px;
`;
