import React from 'react';
import { MuiBox, MuiContainer, MuiButton } from 'theme/material-ui';
import styled from 'styled-components';

import { Color } from 'modules/styles/colors';
import { Spacing, FontSize, IconSize, FontWeight } from 'modules/styles/variables';

import { ProxyFormProps } from 'screens/ProxyForm/ProxyReviewInfoScreen';

import { FlexBoxRow, FlexBoxColumn } from 'components/UI/Layout/FlexBox';
import Spacer from 'components/UI/Layout/Spacer';
import { Icon } from 'components/Icon';
import Typography from 'components/UI/Typography';

export interface ProxyReviewMinorProps {
  index: number;
  minorData: ProxyFormProps;
  removeMinor: (firstName: string, id: string) => void;
  updateMinor: (proxy: ProxyFormProps) => void;
  profileConsumerAddress: {
    streetAddress: string;
    state: string;
    postalCode: string;
  };
}

const ActionMinorButton = styled(MuiButton)`
  &.MuiButton-outlined {
    min-width: 0;
    padding: ${Spacing.small}px;
    &:not(:last-child) {
      margin-right: ${Spacing.medium}px;
    }
  }
`;

const CardRow = styled(FlexBoxRow)`
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding-bottom: ${Spacing.medium}px;
`;

const CardCell = ({ keyName, label, data }) => (
  <FlexBoxColumn flex={8} maxWidth="100%">
    <Typography fontSize={FontSize.large}>{label}</Typography>
    <Typography
      style={{ wordWrap: 'break-word' }}
      fontSize={FontSize.large}
      fontWeight={FontWeight.bold}
      data-testid={label}
    >
      {data[keyName]}
    </Typography>
  </FlexBoxColumn>
);

const ProxyReviewMinor = ({
  index,
  minorData,
  removeMinor,
  updateMinor,
  profileConsumerAddress
}: ProxyReviewMinorProps) => {
  const { streetAddress, state, postalCode } = profileConsumerAddress;
  const address =
    minorData.address === 'yes'
      ? `${streetAddress}, ${state}, ${postalCode}`
      : `${minorData.street}, ${minorData.state}, ${minorData.postalCode}`;

  const relationshipKey = minorData.relationship !== 'Other' ? 'relationship' : 'otherRelationship';

  return (
    <MuiContainer maxWidth="sm" key={minorData.firstName}>
      <FlexBoxRow
        backgroundColor={Color.white}
        vSpacing={Spacing.large}
        hSpacing={Spacing.xLarge + Spacing.small}
        borderRadius={Spacing.small}
        justifyContent="space-between"
        flexWrap="wrap"
      >
        <CardRow>
          <Typography tag="h3" lineHeight={FontSize.largeHeading}>
            {index + 1}. Minor's Details
          </Typography>
          <MuiBox marginRight={`-${Spacing.large}px`}>
            <ActionMinorButton
              data-testid="edit-minor-button"
              color="default"
              variant="outlined"
              onClick={() => updateMinor(minorData)}
            >
              <Icon name="edit" size={IconSize.base} color={Color.black} />
            </ActionMinorButton>
            <ActionMinorButton
              data-testid="delete-minor-button"
              color="default"
              variant="outlined"
              onClick={() => removeMinor(minorData.firstName, minorData.id)}
            >
              <Icon name="delete-outline" size={IconSize.base} color={Color.black} />
            </ActionMinorButton>
          </MuiBox>
        </CardRow>
        <CardRow>
          <CardCell keyName="firstName" label="First" data={minorData} />
        </CardRow>
        {minorData.middleName ? (
          <CardRow>
            <CardCell keyName="middleName" label="Middle" data={minorData} />
          </CardRow>
        ) : null}
        <CardRow>
          <CardCell keyName="lastName" label="Last" data={minorData} />
        </CardRow>
        <CardRow>
          <CardCell keyName="gender" label="Sex" data={minorData} />
          <CardCell keyName="birthDate" label="DOB" data={minorData} />
        </CardRow>
        <CardRow>
          <CardCell keyName={relationshipKey} label="This minor is your" data={minorData} />
        </CardRow>
        <CardRow>
          <CardCell keyName="address" label="Minor's Primary Address" data={{ address }} />
        </CardRow>
      </FlexBoxRow>
      <Spacer size="small" />
    </MuiContainer>
  );
};

export default ProxyReviewMinor;
