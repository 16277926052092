import React from 'react';
import Spinner from 'components/UI/Spinner/Spinner';
import { MuiBox } from 'theme/material-ui';
import { SpinnerContainer, SpinnerProps } from 'components/UI/Spinner/SpinnerContainer';

const AbsoluteSpinner = (props: SpinnerProps) => (
  <SpinnerContainer
    {...props}
    data-testid="Loading"
    aria-label="Loading"
    opacity={props.opacity || 0.6}
  >
    <MuiBox p={3} display="flex" flexDirection="row" justifyContent="center">
      <Spinner />
    </MuiBox>
  </SpinnerContainer>
);

export default AbsoluteSpinner;
