import { CovidTitles } from 'lib/constants/covidScreening';

const baseUrl = '/guest-home/covid-screen';

export const TEST_GUEST_PAGE_NAME = {
  [`${baseUrl}/consent-and-agreement`]: CovidTitles.CONSENT_AND_AGREEMENTS,
  [`${baseUrl}/guest-covid-reasons`]: CovidTitles.REASON_FOR_REQUEST,
  [`${baseUrl}/guest-covid-symptoms`]: CovidTitles.SYMPTOM_INFORMATION,
  [`${baseUrl}/personal-information`]: CovidTitles.PERSONAL_INFORMATION,
  [`${baseUrl}/guest-covid-insurance`]: CovidTitles.HAVE_HEALTH_INSURANCE,
  [`${baseUrl}/guest-insurance-search`]: CovidTitles.EXISTING_INSURANCE_SEARCH,
  [`${baseUrl}/guest-summary-info`]: CovidTitles.SUMMARY_INFORMATION,
  [`${baseUrl}/guest-subscriber-information`]: CovidTitles.SUBSCRIBER_INFO,
  [`${baseUrl}/guest-new-insurance-found`]: CovidTitles.EXISTING_INSURANCE_FOUND,
  [`${baseUrl}/guest-no-insurance-found`]: CovidTitles.EXISTING_INSURANCE_NOT_FOUND,
  [`${baseUrl}/guest-covid-insurance-upload`]: CovidTitles.INSURANCE_CARD_UPLOAD,
  // Todo: possibly update for future covid unauthenticated pages when completed
  [`${baseUrl}/guest-insurance-information`]: CovidTitles.INSURANCE_INFORMATION,
  [`${baseUrl}/guest-order-confirmation`]: CovidTitles.ORDER_CONFIRMATION,
  [`${baseUrl}/guest-order-status`]: CovidTitles.ORDER_STATUS
};
