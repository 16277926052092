import React from 'react';

export default {
  svg: (
    <path
      fill="#000"
      fillRule="evenodd"
      d="M2.573.336c-.237.014-.48.063-.688.139-.136.05-.385.175-.503.252A2.45 2.45 0 0 0 .295 2.429l-.022.139v2.784c0 2.87.001 2.928.04 3.494a16.946 16.946 0 0 0 4.16 10.012 16.88 16.88 0 0 0 6.544 4.604c.394.155.625.203.983.203.26 0 .491-.034.715-.104.171-.054.831-.33 1.248-.524a16.994 16.994 0 0 0 5.91-4.591 16.934 16.934 0 0 0 3.814-9.6c.039-.563.04-.617.04-3.48 0-3.07.003-2.846-.06-3.13A2.441 2.441 0 0 0 22.363.586a2.286 2.286 0 0 0-.96-.25C21.216.326 2.76.326 2.573.337Zm-.04 1.516a.95.95 0 0 0-.73.687l-.022.077-.003 2.645c-.004 2.977 0 3.163.06 3.825a15.46 15.46 0 0 0 7.338 11.791c.722.442 1.532.845 2.352 1.171.26.104.343.122.51.115a.757.757 0 0 0 .312-.07l.288-.117c.962-.392 1.94-.914 2.784-1.488a15.482 15.482 0 0 0 4.356-4.461 15.44 15.44 0 0 0 2.383-6.936c.062-.685.064-.85.06-3.83l-.002-2.645-.02-.067a.998.998 0 0 0-.09-.224.933.933 0 0 0-.669-.477c-.073-.013-1.14-.014-9.453-.014-9.042 0-9.374.001-9.454.018Zm3.774 2.65a1.735 1.735 0 0 0-.845.293c-.4.268-.666.67-.751 1.141l-.022.117v1.675c0 1.755.002 1.813.044 2.26a9.865 9.865 0 0 0 1.462 4.292 10.344 10.344 0 0 0 5.013 4.135c.348.137.512.173.787.174.297 0 .438-.032.84-.193.999-.4 1.877-.911 2.7-1.57 2.125-1.698 3.478-4.175 3.731-6.828.043-.45.044-.514.044-2.27V6.053l-.021-.117a1.754 1.754 0 0 0-1.467-1.421c-.09-.016-.398-.017-5.76-.018-3.115-.001-5.705.002-5.755.006Zm.042 1.513a.28.28 0 0 0-.14.124c-.016.037-.017.104-.017 1.618 0 1.647.002 1.766.043 2.155.26 2.477 1.62 4.723 3.73 6.16.544.37 1.117.674 1.761.932.266.106.266.106.485.021a9.028 9.028 0 0 0 2.11-1.158c.357-.264.64-.51.986-.857a8.41 8.41 0 0 0 2.457-5.093c.041-.381.044-.506.044-2.156 0-1.518-.001-1.585-.018-1.622a.273.273 0 0 0-.143-.124C17.607 6 17.043 6 11.996 6c-4.889 0-5.612.002-5.647.014Z"
      clipRule="evenodd"
    />
  ),
  viewBox: '0 0 24 24'
};
