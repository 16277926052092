import { Button } from '@material-ui/core';
import Card from 'components/UI/Card/Card';
import FlexBox from 'components/UI/Layout/FlexBox';
import Typography from 'components/UI/Typography';
import { Color } from 'modules/styles/colors';
import { FontSize, FontWeight } from 'modules/styles/variables';
import React from 'react';
import styled from 'styled-components';
import { dfdDefaultTheme, MuiTypography } from 'theme/material-ui';

export const CarouselCard = styled(Card)`
  padding: 20px 24px;
  height: 300px;
  width: auto;
  max-width: 500px;
  margin: 0 auto;
`;

const SecondaryTouchable = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  border-radius: 5px;
  :hover {
    cursor: pointer;
  }
`;

const SecondaryText = styled(Typography)`
  color: ${Color.primary};
  letter-spacing: 1.25px;
  line-height: ${FontSize.base * 1.15}px;
  text-align: center;
`;

export const StyledButton = styled(Button)`
  height: 45px;
  font-size: 1.2rem;
  &:hover {
    color: ${Color.white};
    background-color: ${Color.primary};
    text-decoration: none;
  }
  &:focus {
    color: ${Color.white};
  }
`;

export const StyledFlexBox = styled(FlexBox)`
  height: 80%;
`;

export const SecondaryButton = ({
  onPress,
  children,
  ...props
}: {
  onPress: () => void;
  children: string;
}) => (
  <SecondaryTouchable {...props} onClick={onPress}>
    <SecondaryText>{children}</SecondaryText>
  </SecondaryTouchable>
);

export const DashboardCarouselImage = styled.img`
  max-width: 100%;
  height: 150px;

  ${dfdDefaultTheme.breakpoints.up('md')} {
    height: 175px;
  }
`;

export const DahboardHeaderContainer = styled.div<{ bgImage: string; opacity?: number }>`
  position: absolute;
  width: 100%;
  height: 0;
  padding-bottom: 62%;
  background: transparent;
  background-image: url(${({ bgImage }) => bgImage});
  background-size: cover;
  background-position: top;
  opacity: ${({ opacity }) => opacity};
  transition: all 0.8s ease;
`;

export const DahboardHeaderVeil = styled.div`
  position: absolute;
  width: 100%;
  height: 0;
  padding-bottom: 62.3%;
  background: linear-gradient(to right, ${Color.grayLight3}, transparent 30%),
    linear-gradient(to left, ${Color.grayLight3}, transparent 30%),
    linear-gradient(to top, ${Color.grayLight3}, transparent 30%);
`;

export const DashboardTitle = styled(MuiTypography)<{ isLoading?: boolean }>`
  display: none;
  && {
    color: ${Color.white};
    font-size: ${FontSize.largeHeading}px;
    font-weight: ${FontWeight.semibold};
    line-height: 30px;
    text-shadow: 0px 0px 20px black, 0px 0px 80px black, 0px 0px 140px black;
  }

  ${dfdDefaultTheme.breakpoints.up('md')} {
    display: block;
  }

  ${({ isLoading }) => isLoading && 'display: block;'}
`;
