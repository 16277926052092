import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { MuiBackdrop } from 'theme/material-ui';

import { Alert } from 'components/Alert';
import Spinner from 'components/UI/Spinner/Spinner';

import { isGreaterThanXTimeFromNow } from 'lib/booking/utils';
import { appointmentDetails } from 'modules/constants/booking';

import { formatPhone } from 'services/formatPhone';
import analyticsService, { AnalyticsUserStat, AnalyticsEvent } from 'services/AnalyticsService';

import { RootState } from 'store/types';
import * as bookingSelectors from 'store/booking/selectors';
import * as bookingTypes from 'store/booking/types';
import * as bookingActions from 'store/booking/actions';
import { ProviderSummary } from 'store/findProvider/types';
import {
  currentLocationPathNameSelector,
  previousLocationPathNameSelector
} from 'store/router/selectors';

import { getLocationMapsLink } from '../../utils';
import { CancelAppointmentDialog } from '../../dialogs/CancelAppointmentDialog';
import { AppointmentDetailsDialog } from '../../dialogs/AppointmentDetailsDialog';
import AppointmentCard, {
  AppointmentCardActions,
  AppointmentCardAction
} from '../../components/AppointmentCard';

interface Props {
  upcomingAppointment: bookingTypes.BookedAppointment;
  cancelAppointment: typeof bookingActions.cancelAppointment;
  getUpcomingAppointments: typeof bookingActions.getUpcomingAppointments;
  modifyAppointment: typeof bookingActions.modifyAppointment;
  allProviders: ProviderSummary[];
  currentUrl?: string;
  referringUrl?: string;
}

export function UpcomingAppointmentCard({
  upcomingAppointment,
  allProviders,
  cancelAppointment,
  getUpcomingAppointments,
  modifyAppointment,
  currentUrl,
  referringUrl
}: Props) {
  const history = useHistory();
  const location = useLocation();
  const openById = location?.state?.openById;
  const phone = formatPhone(upcomingAppointment.appointmentLocation?.phoneDisplay);
  const mapsLink = getLocationMapsLink(upcomingAppointment?.appointmentLocation);
  const isVideoAppointment = /\bVV\b/.test(upcomingAppointment?.appointmentReasonDesc);
  const isMoreThan2Hours = isGreaterThanXTimeFromNow(
    120,
    'minute',
    upcomingAppointment?.appointmentDate
  );

  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const cancellable = !isVideoAppointment && upcomingAppointment.cpmAppointment;
  const [cancelLoading, setCancelLoading] = useState(false);

  const rescheduleable = upcomingAppointment.isReschedulable;
  const [rescheduleLoading, setRescheduleLoading] = useState(false);

  const [detailsOpen, setDetailsOpen] = useState(false);

  useEffect(() => {
    if (openById === upcomingAppointment?.appointmentId) {
      setDetailsOpen(true);
    }
  }, [openById]);

  const handleDialogClose = () => {
    if (!rescheduleLoading) {
      setDetailsOpen(!detailsOpen);
      if (openById) {
        history.goBack();
      }
    }
  };

  const handleViewDetails = () => {
    // HACK we can only tell if it is a amwell visit by checking the reason.
    if (isVideoAppointment) {
      history.push('/u/get-care-now/connect-care', { sourceId: upcomingAppointment.appointmentId });
    } else {
      setDetailsOpen(!detailsOpen);
    }
  };

  const handleCancel = () => {
    analyticsService.logEvent(AnalyticsEvent.ModifyAppointmentStarted, {
      currentUrl,
      referringUrl,
      type: 'cancel'
    });
    setShowCancelDialog(true);
  };

  const handleSubmitCancel = async (comment: string) => {
    if (!isGreaterThanXTimeFromNow(120, 'minute', upcomingAppointment.appointmentDate)) {
      return Alert.alert(
        appointmentDetails.CANCEL_APPOINTMENT.WITHIN_2HR.title,
        appointmentDetails.CANCEL_APPOINTMENT.WITHIN_2HR.subtitle
      );
    }

    setCancelLoading(true);

    const response = await cancelAppointment({
      appointmentId: upcomingAppointment.appointmentId,
      consumerId: upcomingAppointment.consumerId,
      comment
    });

    setCancelLoading(false);
    setShowCancelDialog(false);

    if (response.error) {
      return Alert.alert(
        appointmentDetails.CANCEL_APPOINTMENT.ERROR.title,
        appointmentDetails.CANCEL_APPOINTMENT.ERROR.subtitle
      );
    }

    const doctor = allProviders.find(d => {
      return d.cernerPersonnelId === Number(upcomingAppointment?.providerPersonnelId);
    });

    analyticsService.logEvent(AnalyticsEvent.ModifyAppointmentCompleted, {
      currentUrl,
      referringUrl,

      date_selected: dayjs(upcomingAppointment?.appointmentDate).format('YYYY-MM-DD') || '',
      time_to_appointment: dayjs(new Date()).diff(
        dayjs(upcomingAppointment?.appointmentDate),
        'day'
      ),
      provider_specialty: doctor?.primarySpecialty || '',
      provider_name: upcomingAppointment?.providerDisplayName || '',
      facility_name: upcomingAppointment?.locationName || '',
      type_of_location: upcomingAppointment?.isVideoVisit ? 'Video' : 'In Clinic',
      type: 'cancel'
    });

    analyticsService.incrementUserStat(AnalyticsUserStat.AppointmentsModified);

    // Fire off refetch of appointments
    getUpcomingAppointments(false);

    return Alert.alert(
      appointmentDetails.CANCEL_APPOINTMENT.SUCCESS.title,
      appointmentDetails.CANCEL_APPOINTMENT.SUCCESS.subtitle
    );
  };

  const handleReschedule = async () => {
    if (!isGreaterThanXTimeFromNow(120, 'minute', upcomingAppointment.appointmentDate)) {
      return Alert.alert(
        appointmentDetails.RESCHEDULE_APPOINTMENT.WITHIN_2HR.title,
        appointmentDetails.RESCHEDULE_APPOINTMENT.WITHIN_2HR.subtitle
      );
    }

    setRescheduleLoading(true);

    analyticsService.logEvent(AnalyticsEvent.ModifyAppointmentStarted, {
      currentUrl,
      referringUrl,
      type: 'reschedule'
    });

    const modifySetupResponse = await modifyAppointment(upcomingAppointment);

    setRescheduleLoading(false);

    if (modifySetupResponse.error) {
      return Alert.alert(
        appointmentDetails.RESCHEDULE_APPOINTMENT.ERROR.title,
        appointmentDetails.RESCHEDULE_APPOINTMENT.ERROR.subtitle
      );
    }

    analyticsService.incrementUserStat(AnalyticsUserStat.AppointmentsModified);

    return history.push('/u/get-care-now/booking/date-and-time-select');
  };

  return (
    <>
      <AppointmentCard
        providerName={upcomingAppointment.providerDisplayName}
        location={upcomingAppointment.appointmentLocation}
        date={upcomingAppointment.appointmentDate}
        month={upcomingAppointment.month}
        day={upcomingAppointment.day}
        time={upcomingAppointment.time}
        isSelectHealth={upcomingAppointment.isSelectHealth}
        reason={upcomingAppointment.appointmentReasonDesc}
        isVideoVisit={upcomingAppointment.isVideoVisit}
        displayTime={upcomingAppointment.displayTime}
      >
        <AppointmentCardActions>
          <AppointmentCardAction onClick={handleViewDetails} svgName="Detail" label="Detail" />
          {phone ? (
            <AppointmentCardAction
              component="a"
              href={`tel:${phone}`}
              label={phone}
              svgName="PhoneNumber"
            />
          ) : null}

          {mapsLink ? (
            <AppointmentCardAction
              component="a"
              href={mapsLink}
              target="_blank"
              rel="noopener noreferrer"
              svgName="Directions"
              label="Directions"
            />
          ) : null}

          {rescheduleable && isMoreThan2Hours ? (
            <AppointmentCardAction
              onClick={handleReschedule}
              svgName="Reschedule"
              label="Reschedule"
              data-testid="reschedule-button"
            />
          ) : null}

          {cancellable && isMoreThan2Hours ? (
            <AppointmentCardAction
              onClick={handleCancel}
              svgName="CloseOutline"
              label="Cancel"
              data-testid="cancel-appt-button"
            />
          ) : null}
        </AppointmentCardActions>
      </AppointmentCard>

      <AppointmentDetailsDialog
        open={detailsOpen}
        bookedAppointment={upcomingAppointment}
        onClose={handleDialogClose}
        onReschedule={() => handleReschedule()}
        onCancel={() => handleCancel()}
        isMoreThan2Hours={isMoreThan2Hours}
      />

      <CancelAppointmentDialog
        open={showCancelDialog}
        loading={cancelLoading}
        onClose={() => setShowCancelDialog(false)}
        onSubmit={comment => handleSubmitCancel(comment)}
      />

      <MuiBackdrop open={rescheduleLoading}>
        <Spinner />
      </MuiBackdrop>
    </>
  );
}

const mapStateToProps = (state: RootState) => ({
  allProviders: bookingSelectors.allProvidersSelector(state),
  currentUrl: currentLocationPathNameSelector(state),
  referringUrl: previousLocationPathNameSelector(state)
});

const mapDispatchToProps = {
  cancelAppointment: bookingActions.cancelAppointment,
  getUpcomingAppointments: bookingActions.getUpcomingAppointments,
  modifyAppointment: bookingActions.modifyAppointment
};

export default connect(mapStateToProps, mapDispatchToProps)(UpcomingAppointmentCard);
