import React from 'react';

export default {
  svg: (
    <g>
      <g>
        <g>
          <circle fill="#413379" strokeWidth={0} cx={40} cy={40} r={38.5} />
          <path
            fill="#FFFFFF"
            strokeWidth={0}
            d="M40,2.5c20.711,0,37.5,16.789,37.5,37.5S60.711,77.5,40,77.5S2.5,60.711,2.5,40S19.289,2.5,40,2.5 M40,0.5 C18.22,0.5,0.5,18.22,0.5,40S18.22,79.5,40,79.5S79.5,61.78,79.5,40S61.78,0.5,40,0.5L40,0.5z"
          />
        </g>
        <g>
          <defs>
            <circle id="SVGID_51_" cx={40} cy={40} r={37.5} />
          </defs>
          <clipPath id="SVGID_4_">
            <use xlinkHref="#SVGID_51_" />
          </clipPath>
          <g id="Male_-_Elder_5_" clipPath="url(#SVGID_4_)">
            <path
              fill="#BF8858"
              strokeWidth={0}
              d="M57.181,38.554l0.536-6.966c0-9.11-8.707-16.491-17.81-16.491c-9.11,0-17.81,7.381-17.81,16.491 l0.535,6.959c-0.51,0.123-0.969,0.357-1.353,0.686c-0.712,0.607-1.161,1.504-1.161,2.513v3.43c0,1.814,1.46,3.285,3.278,3.296 c0,5.387,4.053,10.597,8.599,13.741v5.605l-2.477,1.069l0.66,22.238h1.817v3.41h15.831v-3.41h1.807l0.66-22.17l-2.467-1.137 v-5.61c4.543-3.143,8.592-8.351,8.592-13.735c1.811-0.013,3.275-1.482,3.275-3.296v-3.43 C59.693,40.198,58.621,38.908,57.181,38.554z"
            />
            <path
              fill="#B87249"
              strokeWidth={0}
              d="M57.181,38.554l0.536-6.966c0-9.11-8.707-16.491-17.81-16.491c-0.003,0-0.003,23.417-0.003,23.417 c-2.406,5.117-3.958,12.533-3.958,12.533h3.958v14.182c-2.308-0.001-5.196-1.141-7.909-3.016v3.421l15.831,1.665v-5.09 c-0.02,0.014-0.04,0.026-0.06,0.039c4.568-3.139,8.652-8.367,8.652-13.774c1.811-0.013,3.275-1.482,3.275-3.296v-3.43 C59.693,40.198,58.621,38.908,57.181,38.554z M39.904,65.229c0.005,0,0.011-0.001,0.016-0.001c-0.004,0-0.009,0.001-0.014,0.001 C39.906,65.229,39.905,65.229,39.904,65.229z M46.676,62.949c0.085-0.051,0.17-0.104,0.255-0.157 C46.846,62.846,46.761,62.898,46.676,62.949z M40.69,65.183c0.075-0.008,0.15-0.016,0.226-0.026 C40.841,65.167,40.765,65.175,40.69,65.183z M41.684,65.03c0.034-0.007,0.067-0.014,0.101-0.021 C41.751,65.016,41.718,65.023,41.684,65.03z M42.584,64.802c0.087-0.025,0.175-0.052,0.263-0.08 C42.759,64.75,42.672,64.776,42.584,64.802z M43.636,64.45c0.043-0.016,0.086-0.032,0.13-0.049 C43.722,64.418,43.68,64.434,43.636,64.45z M44.605,64.045c0.089-0.04,0.179-0.082,0.268-0.124 C44.784,63.963,44.695,64.005,44.605,64.045z M45.693,63.51c0.033-0.017,0.066-0.033,0.099-0.051 C45.759,63.477,45.726,63.493,45.693,63.51z"
            />
            <g>
              <g>
                <path
                  fill="#A0603B"
                  strokeWidth={0}
                  d="M34.921,40.415c0.05,1.438-1.075,2.644-2.513,2.694c-1.438,0.05-2.644-1.075-2.694-2.513 c-0.05-1.438,1.075-2.644,2.513-2.694C33.665,37.852,34.871,38.977,34.921,40.415z"
                />
                <path
                  fill="#3C2417"
                  strokeWidth={0}
                  d="M34.921,39.755c0.05,1.438-1.075,2.644-2.513,2.694c-1.438,0.05-2.644-1.075-2.694-2.513 c-0.05-1.438,1.075-2.644,2.513-2.694C33.665,37.193,34.871,38.318,34.921,39.755z"
                />
                <path
                  fill="#FFFFFF"
                  strokeWidth={0}
                  d="M33.853,39.011c0.013,0.36-0.269,0.661-0.628,0.674c-0.359,0.013-0.661-0.269-0.674-0.628 c-0.013-0.359,0.269-0.661,0.628-0.674C33.539,38.37,33.841,38.651,33.853,39.011z"
                />
              </g>
              <g>
                <path
                  fill="#A25432"
                  strokeWidth={0}
                  d="M50.093,40.415c0.05,1.438-1.075,2.644-2.513,2.694c-1.438,0.05-2.644-1.075-2.694-2.513 c-0.05-1.438,1.075-2.644,2.513-2.694C48.836,37.852,50.043,38.977,50.093,40.415z"
                />
                <path
                  fill="#3C2417"
                  strokeWidth={0}
                  d="M50.093,39.755c0.05,1.438-1.075,2.644-2.513,2.694c-1.438,0.05-2.644-1.075-2.694-2.513 c-0.05-1.438,1.075-2.644,2.513-2.694C48.836,37.193,50.043,38.318,50.093,39.755z"
                />
                <path
                  fill="#FFFFFF"
                  strokeWidth={0}
                  d="M49.025,39.011c0.013,0.36-0.269,0.661-0.628,0.674c-0.359,0.013-0.661-0.269-0.674-0.628 c-0.013-0.359,0.269-0.661,0.628-0.674C48.711,38.37,49.012,38.651,49.025,39.011z"
                />
              </g>
            </g>
            <path
              fill="#D8D8D8"
              strokeWidth={0}
              d="M39.666,10.834C15.429,11.206,19.089,31.874,21.13,40.717c0.173,0.751,1.272,0.64,1.301-0.13 c0.187-4.945,1.336-14.392,6.312-13.668c4.752,0.691,8.275,0.926,10.924,0.907c2.649,0.02,6.172-0.215,10.924-0.907 c4.976-0.724,6.125,8.723,6.312,13.668c0.029,0.77,1.128,0.881,1.301,0.13C60.244,31.874,63.904,11.206,39.666,10.834z"
            />
            <path
              fill="#D8D8D8"
              strokeWidth={0}
              d="M47.99,54.876c-2.364-2.469-7.271-2.732-7.824-2.754c-0.035-0.001-0.07-0.001-0.104,0 c-0.552,0.022-5.459,0.285-7.824,2.754c-0.238,0.248-0.37,0.58-0.367,0.924c0.03,3.406,0.602,6.642,0.654,6.778 c0.084,0.217,0.223,0.408,0.405,0.554c0.111,0.09,3.417,2.182,7.181,2.182c3.763,0,7.074-2.092,7.185-2.181 c0.182-0.146,0.322-0.338,0.406-0.555c0.053-0.137,0.624-3.372,0.654-6.777C48.36,55.456,48.229,55.124,47.99,54.876z  M45.367,61.269c-0.479,0.313-2.205,0.92-3.606,1.223v-1.769c0-0.911-0.738-1.649-1.649-1.649s-1.649,0.738-1.649,1.649v1.769 c-1.401-0.303-3.125-0.911-3.602-1.223c-0.269-0.794-0.216-2.791-0.332-4.908c1.53-1.159,4.441-1.545,5.585-1.6 c1.145,0.056,4.055,0.442,5.584,1.599C45.583,58.468,45.635,60.471,45.367,61.269z"
            />
            <g>
              <path
                fill="#3FA9F5"
                strokeWidth={0}
                d="M57.919,105.165V72.321H48.8c0,4.286-3.999,7.76-8.801,7.76c-4.802,0-8.915-3.474-8.915-7.76h-9.005 v32.844H57.919z"
              />
              <g>
                <path
                  fill="#2B7DAF"
                  strokeWidth={0}
                  d="M31.2,105.165V67.64C18.324,72.478,5.686,73.137,4.422,80.529c-1.301,7.615-3.895,15.885-4.509,24.635 H31.2z"
                />
              </g>
              <g>
                <path
                  fill="#2B7DAF"
                  strokeWidth={0}
                  d="M80.086,105.165c-0.614-8.75-3.207-17.02-4.509-24.635C74.315,73.137,61.676,72.478,48.8,67.64v37.525 H80.086z"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  ),
  viewBox: '0 0 80 80'
};
