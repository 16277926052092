import styled from 'styled-components';

import Headline from 'components/UI/Typography/Headline';
import SmallCardText from 'components/UI/Typography/SmallCardText';
import { Color } from 'modules/styles/colors';
import { MuiBox, MuiButton, MuiCard, MuiContainer, MuiGrid } from 'theme/material-ui';

export const MuiRowCard = styled(MuiCard)`
  height: 100%;
  margin-right: 20px;
`;

export const MuiRowCardLong = styled(MuiCard)`
  display: flex;
  flex-direction: row;
`;

export const MuiBoxLong = styled(MuiBox)`
  display: flex;
  flex-direction: table-row;
  width: 100%;
`;

export const TextContainer = styled.div`
  flex: auto;
  align-items: flex-start;
  align-self: flex-start;
`;

export const PaddedContainer = styled.div`
  padding: 10px;
  justify-content: center;
  align-items: center;
  align-content: center;
`;

export const LargeCardText = styled(Headline)`
  margin-bottom: 10px;
  font-weight: bold;
`;

export const SmallText = styled(SmallCardText)`
  padding-left: 0;
`;

export const AddressContainer = styled.div`
  && {
    display: flex;
    flex-direction: row;
    align-content: center;
    margin-top: 10px;
  }
`;

export const BottomSpacer = styled(MuiGrid)`
  margin-bottom: 20px;
`;

export interface CardMediaProps {
  imageUrl: string;
}

export const CardColMedia = styled.div`
  height: 150px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left;
  background-image: url("${(props: CardMediaProps) => props.imageUrl}");
`;

export const CardColMediaLong = styled.div`
  min-width: 150px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left;
  background-image: url("${(props: CardMediaProps) => props.imageUrl}");
`;

export const HrBreaker = styled.hr`
  color: ${Color.grayHue4};
  height: 0.5px;
`;

export const GridSpacer = styled.div`
  margin-top: 30px;
`;

export const StyledMuiContainer = styled(MuiContainer)`
  &&& {
    padding: 0px;
  }
`;

export const StyledMuiGrid = styled(MuiGrid)`
  &&& {
    width: 100%;
  }
`;

export const StyledMuiButton = styled(MuiButton)`
  &.MuiButton-root {
    min-width: 200px;
    border: 1px solid white;
  }
  &&.MuiButton-contained {
    background-color: white;
    color: ${Color.MHlogoDarkBlue};
  }
`;
