import Table from 'components/common/Table/Table';
import DataLoader from 'components/UI/DataLoader/DataLoader';
import { AnimatedEllipsis } from 'components/AnimatedEllipsis';
import { MuiBox, MuiContainer } from 'theme/material-ui';
import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { currentAccountConsumerSelector } from 'store/account/selectors';
import { RootState, RootDispatch } from 'store/types';
import { getVitalsData, setHistoricalVitalType } from 'store/vitals/actions';
import * as vitalsSelectors from 'store/vitals/selectors';
import { StyledScrollableLayout, BackgroundWrapper } from './styled';
import { useParams } from 'react-router-dom';
import { oc } from 'ts-optchain';
import VitalsChart from 'components/vitals/VitalsChart/VitalsChart';
import Banner from 'screens/Vitals/Banner';
import Header from 'screens/Vitals/Header';
import { HistoricalVitalsDataObject, VitalsTableItem } from 'store/vitals/types';
import { HEALTH_RECORDS_PAGES } from 'lib/constants/healthRecords';
import { BLOOD_PRESSURE_VITAL_SERVICE_NAME } from 'store/vitals/constants';
import { HealthRecordsListItemPlaceholder } from '../HealthRecords/shared';
import { VitalsTrendLineChart } from 'components/vitals/VitalsTrendLineChart/VitalsTrendLineChart';
import { Spacing } from 'modules/styles/variables';
import FlexBox from 'components/UI/Layout/FlexBox';
import LabRowChange from 'screens/TestResults/Lab/LabRowChange';
import { formatShortDate } from 'modules/utils/DateUtils';
import { Color } from 'modules/styles/colors';
import Config from 'react-native-config';
import BMIHeader from './BMIHeader';
import VitalRowStatus from './VitalRowStatus';

interface Props {
  isFetching: boolean;
  dispatch: RootDispatch;
  data: HistoricalVitalsDataObject[];
  error: Error | null;
}

export const VitalsTable = ({ dispatch, data, isFetching = false, error }: Props) => {
  let showTrendLineChartFor: string[] = [];
  if (Config.VITALS_TRENDLINE_CHART === 'enabled') {
    showTrendLineChartFor = ['Weight', 'BMI'];
  }

  const { name } = useParams();
  const [vitalName, setVitalName] = useState(name);
  const [isWaiting, setIsWaiting] = useState(true);

  // There is a race condition that arises from the historical data not being reset IF you navigate from the health record home page

  useEffect(() => {
    if (isWaiting) {
      setTimeout(() => setIsWaiting(false));
    }
  }, [isWaiting]);

  const consumer = useSelector(currentAccountConsumerSelector);
  const patientName = oc(consumer).displayName('');
  const age = oc(consumer).age();
  const shouldShowSecondaryValue = () => vitalName === BLOOD_PRESSURE_VITAL_SERVICE_NAME;

  useEffect(() => {
    dispatch(setHistoricalVitalType(name));
    dispatch(getVitalsData());
    setVitalName(name);
  }, [name]);

  const renderLoadingState = () => (
    <>
      <HealthRecordsListItemPlaceholder message={HEALTH_RECORDS_PAGES.Vitals.loading} />
      <MuiBox display="flex" flexDirection="row" justifyContent="center">
        <AnimatedEllipsis />
      </MuiBox>
    </>
  );

  const renderErrorState = () => (
    <HealthRecordsListItemPlaceholder
      message={HEALTH_RECORDS_PAGES.Vitals.getErrorState(vitalName)}
    />
  );

  const renderVitalsChartData = () => (
    <StyledScrollableLayout>
      <Banner patientName={patientName} patientAge={age} />
      <BackgroundWrapper>
        <VitalsChart data={data} type={vitalName} showSecondaryValue={shouldShowSecondaryValue()} />
      </BackgroundWrapper>
      <Table data={data} type={vitalName} isFetching={isFetching} />
    </StyledScrollableLayout>
  );

  const VitalHeader = vitalName === 'BMI' ? BMIHeader : Header;

  const renderTrendLineChartData = () => {
    if (age < 20) return null;
    return (
      <StyledScrollableLayout>
        <VitalHeader title={vitalName} subtitle="The measurement of a body's relative mass." />
        {data.length >= 3 ? <VitalsTrendLineChart data={data} /> : null}
        <MuiContainer data-testid="lab-historical-list">
          <MuiContainer
            disableGutters
            style={{
              maxHeight: 500,
              overflowY: 'auto',
              marginTop: Spacing.largeXLarge,
              marginBottom: Spacing.largeXLarge
            }}
          >
            <MuiBox bgcolor={Color.white}>
              {data?.map((item: VitalsTableItem) => {
                const valueText = ` Value: ${item?.valueUnits[0]?.value} ${item?.valueUnits[0].units}`;
                const changeValue = item?.valueChange?.value;
                const changeDirection = item?.valueChange?.direction;
                const status = item?.statusText;
                return (
                  <FlexBox
                    // height="50px"
                    p={2}
                    flexWrap="wrap"
                    flexDirection="row"
                    borderStyle="solid"
                    borderColor="white" // due to some border issue we have added border color twice
                    width="100%"
                    style={{
                      padding: 16,
                      borderBottomColor: 'rgba(0,0,0,0.15)'
                    }}
                  >
                    <FlexBox>{formatShortDate(item.date)}</FlexBox>
                    <FlexBox flexDirection="row">
                      <MuiBox mr={1} ml={1}>
                        |
                      </MuiBox>
                      {valueText}
                    </FlexBox>
                    {status ? <VitalRowStatus item={item} /> : null}
                    {changeValue ? (
                      <LabRowChange value={changeValue} direction={changeDirection} />
                    ) : null}
                  </FlexBox>
                );
              })}
            </MuiBox>
          </MuiContainer>
        </MuiContainer>
      </StyledScrollableLayout>
    );
  };

  const renderData = showTrendLineChartFor.includes(vitalName)
    ? renderTrendLineChartData
    : renderVitalsChartData;

  return (
    <DataLoader
      error={error}
      loading={isFetching || isWaiting}
      renderData={renderData}
      renderError={renderErrorState}
      renderLoading={renderLoadingState}
    />
  );
};

const mapStateToProps = (state: RootState) => ({
  data: vitalsSelectors.historicalVitalsChartDataSelector(state),
  isFetching: vitalsSelectors.recentVitalsLoadingSelector(state),
  error: vitalsSelectors.recentVitalsErrorSelector(state)
});

export default connect(mapStateToProps)(VitalsTable);
