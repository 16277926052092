import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { history } from 'lib/history';
import { TimerContext, BOOKING_TIMEOUT } from 'lib/booking/utils';

import { BorderRadius, FontSize, IconSize, Spacing } from 'modules/styles/variables';
import { Color } from 'modules/styles/colors';

import FlexBox from 'components/UI/Layout/FlexBox';
import { Icon } from 'components/Icon';
import { CountDownTimer } from 'components/Timer/CountDownTimer';

import { MuiTypography, MuiBox, MuiButton } from 'theme/material-ui';
import { WebMuiModal } from 'components/UI/Modals/WebMuiModal';
import Spacer from 'components/UI/Layout/Spacer';
import appointmentTimeout from 'modules/constants/booking/appointmentTimeout';
import { Svg } from 'components/UI/Svg';
import Box from 'components/UI/Layout/Box';

const TimerBox = styled(FlexBox)`
  background-color: ${props => props.backgroundColor};
  padding: ${Spacing.medium}px;
  border-radius: ${Spacing.medium}px;
  border: ${props => props.borderWidth}px solid ${props => props.borderColor};
  min-width: 275px;
`;

const BookingTimer = () => {
  const [timerContext, setTimerContext] = useContext(TimerContext);
  const [warningTimer, setWarningTimer] = useState(false);
  const [timeoutModal, setTimeoutModal] = useState(false);

  const onTimeout = () => {
    setTimerContext(0);
    setTimeoutModal(true);
  };

  const onReselectPress = () => {
    if (history.location.pathname === '/u/get-care-now/booking/date-and-time-select') {
      history.replace('/u/get-care-now/booking/date-and-time-select');
    } else {
      const backTo =
        history.location.pathname === '/u/get-care-now/booking/reason-for-visit' ? -1 : -2;
      history.go(backTo);
    }
  };

  const onUpdateTimer = (currentTimer: number) => {
    setTimerContext(currentTimer);
    if (currentTimer < 2 * 60 * 1000) {
      setWarningTimer(true);
    }
  };

  const timer = CountDownTimer({
    duration: timerContext === 0 ? BOOKING_TIMEOUT : timerContext,
    onTimeout,
    onUpdateTimer,
    onTimeoutNewValue: 0
  });

  return (
    <>
      <WebMuiModal open={timeoutModal}>
        <MuiBox p={Spacing.xSmall} display="flex" flexDirection="column">
          <MuiBox textAlign="center">
            <Icon name="access-time" size={IconSize.xLarge} color={Color.black} />
          </MuiBox>
          <Spacer size="small" />
          <MuiTypography align="center" fontSize={FontSize.heading}>
            {appointmentTimeout.BOOKING_TIMEOUT_DIALOG_TEXT.title}
          </MuiTypography>
          <MuiTypography align="center" fontSize={FontSize.large}>
            {appointmentTimeout.BOOKING_TIMEOUT_DIALOG_TEXT.subtitle}
          </MuiTypography>
          <Spacer size="medium" />
          <MuiButton
            data-testid="handle-reselect"
            variant="contained"
            color="primary"
            onClick={onReselectPress}
            fullWidth
            style={{
              borderRadius: BorderRadius.full,
              alignSelf: 'center'
            }}
          >
            {appointmentTimeout.BOOKING_TIMEOUT_DIALOG_TEXT.primaryButton}
          </MuiButton>
        </MuiBox>
      </WebMuiModal>
      <FlexBox flexDirection="row" justifyContent="center">
        <TimerBox
          backgroundColor={warningTimer ? Color.lightYellow : Color.foreColor}
          borderColor={warningTimer ? Color.warning : Color.gray}
          borderWidth={warningTimer ? 2 : 1}
          flexDirection="row"
          alignItems="center"
          justifyContent="space-around"
        >
          <Svg name="ClockCircle" />
          <Box hOffset={Spacing.xSmall} />
          <MuiTypography>{`${timer} to reserve the appointment`}</MuiTypography>
        </TimerBox>
      </FlexBox>
    </>
  );
};

export default BookingTimer;
