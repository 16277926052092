import React from 'react';
import { DahboardHeaderContainer, DahboardHeaderVeil } from './styled';
import Bg1 from 'assets/DashboardBanner/desktop/bg_1.jpg';
import Bg2 from 'assets/DashboardBanner/desktop/bg_2.jpg';
import Bg3 from 'assets/DashboardBanner/desktop/bg_3.jpg';
import Bg4 from 'assets/DashboardBanner/desktop/bg_4.jpg';
import Bg5 from 'assets/DashboardBanner/desktop/bg_5.jpg';
import Bg6 from 'assets/DashboardBanner/desktop/bg_6.jpg';
import Bg7 from 'assets/DashboardBanner/desktop/bg_7.jpg';
import Bg8 from 'assets/DashboardBanner/desktop/bg_8.jpg';

interface DashboardBgBannerProps {
  opacify?: boolean;
}

const imagesList = [Bg1, Bg2, Bg3, Bg4, Bg5, Bg6, Bg7, Bg8];

const DashboardBgBanner = ({ opacify }: DashboardBgBannerProps) => {
  let bannerIndex = window.sessionStorage.getItem('dashboardBannerIndex');
  if (bannerIndex === null) {
    const randomNumber = Math.floor(Math.random() * imagesList.length).toString();
    window.sessionStorage.setItem('dashboardBannerIndex', randomNumber);
    bannerIndex = randomNumber;
  }
  const selectedImage = imagesList[Number(bannerIndex)];
  const opacity = opacify ? 0.15 : 1;
  return (
    <DahboardHeaderContainer opacity={opacity} bgImage={selectedImage}>
      <DahboardHeaderVeil />
    </DahboardHeaderContainer>
  );
};

export default DashboardBgBanner;
