import React from 'react';

const a = {
  fill: '#231f20'
} as const;

export default {
  svg: (
    <g>
      <polygon
        {...a}
        points="280.39 0 280.39 38.1 359.69 38.1 359.69 195.56 280.39 195.56 280.39 233.66 395.66 233.66 395.66 0 280.39 0"
      />
      <polygon
        {...a}
        points="118.39 195.56 35.97 195.56 35.97 38.1 118.39 38.1 118.39 0 0 0 0 233.66 118.39 233.66 118.39 195.56"
      />
      <path
        {...a}
        d="M276.26,346v22.32H250.33V346c-32.09-3.38-53.44-19.43-57.18-48.74l30.64-1.08c4.34,17.6,19.18,26.66,39.93,26.66,18.57,0,33.9-10,33.9-24.61,0-14-8.45-20.86-26.66-25.2l-29.32-7c-26.18-5.91-42.94-20.63-43.18-46.09-.25-27.86,21.71-47.28,52.83-51.39V144.45h26.06v24.24c30,4.71,48.13,25.46,48.36,49.94l-29.79,1c-2.77-17.86-11.58-28-31.48-28-19.43,0-34.5,9.41-34.5,25.09,0,11.33,6.39,16.89,27.27,22.69l28.46,7.46c30.76,8,43.19,26.66,43.19,51.15C328.86,323.82,308.35,342.29,276.26,346Z"
        transform="translate(-63.17 -144.45)"
      ></path>
    </g>
  ),
  viewBox: '-70 -70 595.66 433.66'
};
