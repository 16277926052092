import React from 'react';
import styled from 'styled-components';
import { Alert } from '../Alert';
import { useHistory } from 'react-router-dom';
import { GlossaryItem } from 'store/glossary/reducers';
import Svg from 'components/UI/Svg/Svg';
import { MuiIconButton, MuiBox } from 'theme/material-ui';
import { IconSize } from 'modules/styles/variables';
import { Color } from 'modules/styles/colors';

export const StyledButton = styled(MuiBox)`
  border: 1px solid ${Color.gray};
  border-radius: 6px;
  width: 50px;
  margin: 8px;
  text-align: center;
  background: ${Color.grayHue5};
`;

export const Bold = styled.span`
  font-weight: bold;
`;

export const Term = styled.div`
  padding: 12px;
`;

interface Props {
  definitions: GlossaryItem[];
  type?: string;
  hideBorder?: boolean;
  color?: string;
}
export interface RenderIconProps {
  handleRequestOpen: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  color: string;
  title: string;
}

export function renderIcon(params: RenderIconProps) {
  const { handleRequestOpen, color, title } = params;
  return (
    <MuiIconButton
      title={title}
      aria-label="information"
      aria-haspopup="true"
      onClick={handleRequestOpen}
      color="inherit"
    >
      <Svg set="assets" name="InfoIcon" size={IconSize.base} color={color} />
    </MuiIconButton>
  );
}

export function renderWithBorder(params: RenderIconProps) {
  return <StyledButton display="inline-block">{renderIcon(params)}</StyledButton>;
}

export function InfoButton({
  definitions,
  type = 'billing',
  hideBorder,
  color = Color.offBlack
}: Props) {
  const history = useHistory();
  const handleRequestOpen = () => {
    Alert.alert(
      'Terms You Should Know',
      null,
      [
        {
          text: 'View All',
          onPress: () => {
            history.push(`/u/${type}/glossary`);
          }
        }
      ],
      { cancelable: true, closeIcon: true },
      () => {
        return (
          <div>
            {definitions.map(def => {
              if (!def.Term || !def.Definition) {
                return null;
              }

              return (
                <Term key={def.Term}>
                  <Bold>{def.Term}:</Bold> {def.Definition}
                </Term>
              );
            })}
          </div>
        );
      }
    );
  };
  const renderIconProps = { handleRequestOpen, color, title: 'Term Definitions' };
  return hideBorder ? renderIcon(renderIconProps) : renderWithBorder(renderIconProps);
}
