import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { EducationType, EducationItem } from 'store/education/types';
import ActionEducationView from './components/ActionEducationView';
import HistoricalEducationView from './components/HistoricalEducationView';
import ErrorMessage from './components/ErrorMessage';
import usePatientEducation from 'lib/hooks/usePatientEducation';

export const PatientEducationDetail = () => {
  const history = useHistory();
  const { accessCode } = useParams<{ accessCode: string }>();
  const { data, refetch } = usePatientEducation();

  useEffect(() => {
    refetch(true);
  }, []);

  // Derive EducationType of accessCode if given
  let itemTypeOfAccessCode: EducationType | null = null;
  if (accessCode) {
    const educationItem: EducationItem | undefined = data.find(
      (e: EducationItem) => e.accessCode === accessCode
    );
    itemTypeOfAccessCode =
      educationItem?.status === 'Completed' ? EducationType.historical : EducationType.action;
  }

  switch (itemTypeOfAccessCode) {
    case EducationType.action:
      return <ActionEducationView accessCode={accessCode} />;
    case EducationType.historical:
      return <HistoricalEducationView accessCode={accessCode} />;
    default:
      return (
        <ErrorMessage
          message="There was an issue loading your assignment."
          action={{
            text: 'Go to education page',
            onClick: () => history.push('/u/health-record/patient-education')
          }}
        />
      );
  }
};

export default PatientEducationDetail;
