import * as d3 from 'd3';
import { isIE } from 'lib/browser';
import { Color } from 'modules/styles/colors';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { currentAccountConsumerAgeSelector } from 'store/account/selectors';
import { RootState } from 'store/types';
import {
  BLOOD_PRESSURE_VITAL_SERVICE_NAME,
  BMI_VITAL_SERVICE_NAME,
  HEART_RATE_VITAL_SERVICE_NAME,
  HEIGHT_VITAL_SERVICE_NAME,
  TEMPERATURE_VITAL_SERVICE_NAME,
  WEIGHT_VITAL_SERVICE_NAME,
  DIASTOLIC,
  SYSTOLIC
} from 'store/vitals/constants';
import * as vitalsSelectors from 'store/vitals/selectors';
import { BloodPressureKey, BMIKey, ChartLegendKey, ChartNames, TemperatureKey } from './constants';
import {
  ColoredDot,
  KeyPart,
  KeyText,
  LineChartContainer,
  LineChartContainerStyles,
  LineGraphKey
} from './styled';

const lineGraphTypeToKeys: { [key: string]: ChartLegendKey[] } = {
  [BLOOD_PRESSURE_VITAL_SERVICE_NAME]: BloodPressureKey,
  [BMI_VITAL_SERVICE_NAME]: BMIKey,
  [TEMPERATURE_VITAL_SERVICE_NAME]: TemperatureKey
};

const appendElement = element => {
  // it will not fire onMouseleave for the element on ie
  if (!isIE()) {
    element.parentNode.appendChild(element);
  }
};

const d3ReorderingmouseEvents = {
  onMouseover() {
    appendElement(this);

    setTimeout(() => {
      d3.select(this)
        .classed('hover', true)
        .select('.graph-point__inner')
        .attr('transform', 'scale(1.75)');
    }, 10);
  },

  onMouseleave() {
    d3.select(this)
      .classed('hover', false)
      .select('.graph-point__inner')
      .attr('transform', 'scale(0.4)');
  }
};

const FullLineGraphKey = (type: string) => {
  if (lineGraphTypeToKeys[type]) {
    const keyType = lineGraphTypeToKeys[type];

    return (
      <LineGraphKey>
        {keyType.map(({ title, color }) => (
          <KeyPart key={title}>
            <KeyText>{title}</KeyText>
            <ColoredDot color={color} />
          </KeyPart>
        ))}
      </LineGraphKey>
    );
  }
  return null;
};

export interface ChartData {
  [x: string]: any;
  date: Date;
  value: number;
  value2?: number;
  interpretation?: string;
  interpretation2?: string;
}
export interface ChartMarginProps {
  bottom: number;
  left: number;
  right: number;
  top: number;
}
export interface VitalsChartProps {
  age: number;
  data: any;
  labelX: string;
  showSecondaryValue: boolean;
  showBackground?: boolean;
  type: string;
}

const VitalsChart = (props: VitalsChartProps) => {
  const { showSecondaryValue, showBackground = true } = props;
  const ref = React.createRef();
  const state = {
    yAxisAttribute: 'skill',
    xAxisAttribute: 'value',
    width: 600,
    height: 0,
    chartMargin: {
      top: 40,
      right: 60,
      bottom: 80,
      left: 80
    },
    showSecondaryValue,
    showTabs: false,
    rotateLabelY: true,
    graphPointLabelModifier: '',
    componentClass: '',
    timeFormat: '%m/%d/%Y',
    labelX: '',
    labelY: '',
    currentYear: 'View All',
    chartAspect: 6 / 4,
    chartYears: [],
    startDate: '',
    endDate: '',
    range: null,
    originalDataSet: []
  };
  const setPointClass = (
    point: d3.Selection<SVGGElement, unknown, SVGGElement, unknown>,
    interpretation: any
  ) => {
    switch (interpretation) {
      case 'high':
      case 'low':
      case 'overweight':
      case 'underweight':
        point.classed('warn', true);
        break;
      case 'obese':
        point.classed('obese', true);
        break;
      case 'normal':
        point.classed('normal', true);
        break;
      default:
        point.classed('normal', true);
    }
  };
  // CleanData used for Blood Pressure
  const cleanBPData = (data: any) => {
    const chartData: ChartData[] = [];
    data.forEach((vital: ChartData) => {
      if (!vital) {
        return;
      }
      const systolic = vital[SYSTOLIC];
      const diastolic = vital[DIASTOLIC];
      chartData.push({
        date: new Date(vital.date),
        value: parseFloat(systolic.value),
        value2: parseFloat(diastolic.value),
        interpretation: systolic.statusText,
        interpretation2: diastolic.statusText
      });
    });
    draw(chartData);
  };

  // CleanData used for anything other than blood pressure
  const cleanData = (data: any) => {
    const chartData: ChartData[] = [];
    data.forEach((vital: ChartData) => {
      if (!vital || !vital.valueUnits) {
        return;
      }
      const getValue = () => {
        if (props.type === HEIGHT_VITAL_SERVICE_NAME) {
          let inches = 0;
          vital.valueUnits.forEach(u => {
            const val = u.units === 'ft' ? parseFloat(u.value) * 12 : parseFloat(u.value);
            inches += val;
          });
          return inches;
        }
        return parseFloat(vital.valueUnits[0].value);
      };
      chartData.push({
        date: new Date(vital.date),
        value: getValue(),
        interpretation: vital.statusText,
        valueUnits: vital.valueUnits
      });
    });
    if (chartData.length > 0) draw(chartData);
  };
  const setMaxY = d => {
    if (props.type === BLOOD_PRESSURE_VITAL_SERVICE_NAME) {
      return 190;
    }
    return parseFloat(d.value);
  };
  const setMinY = d => {
    if (props.type === BLOOD_PRESSURE_VITAL_SERVICE_NAME) {
      return 40;
    }
    return parseFloat(d.value);
  };
  const getChartLabel = (type: string) => {
    return ChartNames[type];
  };
  const draw = data => {
    d3.select('svg.line-chart > *').remove();
    const svg = d3.select('svg.line-chart');
    const svgWidth = window.innerWidth >= 400 ? window.innerWidth * 0.6 : 320;
    const svgHeight = 260;
    const margin = state.chartMargin;
    const maxY = d3.max(data, setMaxY);
    const minY = d3.min(data, setMinY);
    const { range } = state;
    if (data.length > 6) {
      state.timeFormat = "%b% % %d % %'%y";
    }
    if (showSecondaryValue && !range) {
      if (margin && margin.right === 20) {
        // if default value
        margin.right = 80;
      }
    }
    const width = svgWidth - margin.left - margin.right;
    const height = svgHeight - margin.top - margin.bottom;

    // Select SVG and set width and height
    svg
      .attr('preserveAspectRatio', 'xMidYMid meet')
      .attr('viewbox', `0 0 ${svgWidth} ${svgHeight}`)
      .attr('width', svgWidth)
      .attr('height', svgHeight)
      .attr('shape-rendering', 'auto');

    // Append group tag inside SVG element (move to specific position)
    const svgGroup = svg.append('g').attr('transform', `translate(${margin.left}, ${margin.top})`);
    const xScale = d3.scaleTime().rangeRound([0, width]);

    // Keep scale of y-axis linear
    const yScale = d3.scaleLinear().rangeRound([height, 0]);

    const x = xScale;
    const y = yScale;

    const xDomain = d3.extent(data, d => {
      return d.date;
    });
    const yDomain = [minY, maxY];
    const linePath = d3
      .line()
      .x(d => {
        return x(d.date);
      }) // returns minimum and maximum value of the dates
      .y(d => {
        return y(d.value);
      }) // returns minimum and maximum
      .curve(d3.curveMonotoneX); // apply smoothing to the line
    let linePath2;
    if (showSecondaryValue) {
      linePath2 = d3
        .line()
        .x(d => {
          return x(d.date);
        }) // returns minimum and maximum value of the dates
        .y(d => {
          return y(d.value2);
        }) // returns minimum and maximum
        .curve(d3.curveMonotoneX); // apply smoothing to the line
    }
    x.domain(xDomain);
    y.domain(yDomain);
    const scaleD = height / (maxY - minY);

    if (range && data.length > 0) {
      const sysH = (range && range.systolic.high.value2 - range.systolic.high.value1) * scaleD;
      const diaH = (range && range.diastolic.high.value2 - range.diastolic.high.value1) * scaleD;

      const sysHighRect = svgGroup.append('g').lower();
      sysHighRect
        .append('rect')
        .classed('range__high', true)
        .attr('x', 0)
        .attr('y', y(range.systolic.high.value2))
        .attr('width', width)
        .attr('height', sysH);

      sysHighRect
        .append('text')
        .classed('graph-label-range', true)
        .attr('x', 10)
        .attr('y', y(range.systolic.high.value2) + 20)
        .text('Systolic High Range');

      const diaHighRect = svgGroup.append('g').lower();
      diaHighRect
        .append('rect')
        .classed('range__low', true)
        .attr('x', 0)
        .attr('y', y(range.diastolic.high.value2))
        .attr('width', width)
        .attr('height', diaH);

      diaHighRect
        .append('text')
        .classed('graph-label-range', true)
        .attr('x', 10)
        .attr('y', y(range.diastolic.high.value2) + 15)
        .text('Diastolic High Range');
    }
    let ticksX = Math.round(width / 100) + 8;
    const ticksY = Math.round(height / 100) + 8;
    if (ticksX * 2 > data.length) {
      ticksX /= 2; // attempting to minimize too many ticks
    }

    if (data.length === 0) {
      svgGroup
        .append('text')
        .classed('graph-label-note', true)
        .attr('y', height / 2)
        .attr('x', width / 2)
        .attr('text-anchor', 'middle');
    }
    // HORIZONTAL AXIS
    svgGroup
      .append('g')
      .attr('transform', `translate(0,${height})`)
      .classed('x-axis', true)
      .call(
        d3.axisBottom(x).tickFormat(date => {
          if (d3.timeYear(date) < date) {
            return d3.timeFormat(state.timeFormat)(date);
          }
          return d3.timeFormat('%Y')(date);
        })
      )
      .selectAll('text')
      .classed('x-axis-label', true)
      .attr('dx', '-.8em')
      .attr('dy', '.15em')
      .attr('transform', 'rotate(-65)')
      .select('.domain')
      .remove();
    const axisY = d3.axisLeft(y).ticks(ticksY);

    const yAxisLabelRotation = 0;
    const yAxisLabelPositionX = -10;
    const yAxisLabelPositionY = 0;
    const yAxisLabelTextAlign = 'right';
    svgGroup
      .append('g')
      .call(axisY)
      .classed('y-axis', true)
      .selectAll('text')
      .classed('y-axis-label', true)
      .attr('transform', `rotate(${yAxisLabelRotation})`)
      .attr('text-anchor', yAxisLabelTextAlign)
      .attr('x', yAxisLabelPositionX)
      .attr('y', yAxisLabelPositionY);
    svgGroup
      .append('g')
      .append('text')
      .classed('graph-label-x', true)
      .attr('y', 0)
      .attr('x', width / 2)
      .attr('dy', '0.71em')
      .attr('text-anchor', 'middle')
      .text(getChartLabel(props.type));
    // CHART LINE PATH
    const trend1 = svgGroup.append('g').classed('chart-line', true);

    trend1.on('mouseover', function mouseover() {
      appendElement(this);
    });

    let trend2;
    trend1
      .append('path')
      .datum(data)
      .attr('fill', 'none')
      .attr('d', linePath) // create the line for the d attribute
      .attr('stroke-dashoffset', d => {
        return d.totalLength;
      })
      .attr('stroke-dasharray', d => {
        return `${d.totalLength} ${d.totalLength}`;
      })
      .transition()
      .attr('stroke-dashoffset', 0)
      .duration(2000);

    trend1.selectAll('path');
    const dataPoint = trend1
      .selectAll('graph-point')
      .data(data)
      .enter()
      .append('g')
      .classed('graph-point', true)
      .on('mouseover', d3ReorderingmouseEvents.onMouseover)
      .on('mouseleave', d3ReorderingmouseEvents.onMouseleave)
      .attr('transform', d => {
        return `translate(${x(d.date)},${y(d.value)})`;
      })
      .append('g')
      .classed('graph-point__inner', true)
      .attr('transform', 'scale(0.4)');

    dataPoint
      .append('circle')
      .classed('graph-point__data-point', true)
      .attr('r', 0)
      .transition()
      .delay((d, i) => {
        let s = i + 1;
        s = s * 150 + 1500;
        if (data.length === 1) {
          s = 0;
        }
        return s;
      })
      .duration(300)
      .attr('r', 26)
      .attr('opacity', '1')
      .transition()
      .delay(200)
      .duration(200)
      .attr('r', 20);
    const dataPointLabel = dataPoint.append('g').classed('graph-point__data-group', true);
    dataPointLabel
      .append('text')
      .classed(`graph-point__label ${state.graphPointLabelModifier}`, true)
      .attr('transform', 'translate(0, 4)')
      .text((d, i) => {
        // Text displayed inside data point
        const setLabel = () => {
          if (props.type === WEIGHT_VITAL_SERVICE_NAME) {
            return `${d.value} lbs`;
          }
          if (props.type === HEART_RATE_VITAL_SERVICE_NAME) {
            return `${d.value}bpm`;
          }
          if (props.type === TEMPERATURE_VITAL_SERVICE_NAME) {
            return `${d.value}°F`;
          }
          // This is used to create the value displayed on the dot on the line chart
          if (props.type === HEIGHT_VITAL_SERVICE_NAME) {
            let label = '';
            d.valueUnits.forEach(dd => {
              label += `${dd.value}${dd.units} `;
            });
            return label;
          }
          if (props.type === BMI_VITAL_SERVICE_NAME) {
            return d.value;
          }
          return d.value;
        };
        const label = setLabel();
        const dp = dataPoint.filter((d, idx) => {
          return idx === i;
        }); // filter the matching dataPoint with current index
        if (label.length > 3) {
          dp.select('text').classed('small', true);
        }
        // Add interpretation class to dataPoint
        if (d.interpretation) {
          const interp = d.interpretation.toLowerCase();
          setPointClass(dp, interp);
        }

        return label;
      });

    if (showSecondaryValue) {
      dataPointLabel
        .selectAll('.graph-point__label')
        .classed('graph-point__label--highlight', true)
        .attr('transform', 'translate(0, -2)');

      dataPoint.selectAll('circle').classed('has-secondary-value', true);
      // CHART LINE PATH2
      trend2 = svgGroup.append('g').classed('chart-line chart-line2', true);

      trend2.on('mouseover', function mouseover() {
        appendElement(this);
      });

      trend2
        .append('path')
        .datum(data)
        .attr('fill', 'none')
        .attr('d', linePath2) // create the line for the d attribute
        .attr('stroke-dashoffset', (d: { totalLength: string | number | boolean | null }) => {
          return d.totalLength;
        })
        .attr('stroke-dasharray', (d: { totalLength: string }) => {
          return `${d.totalLength} ${d.totalLength}`;
        })
        .transition()
        .attr('stroke-dashoffset', 0)
        .duration(2000);
      const dataPoint2 = trend2
        .selectAll('graph-point2')
        .data(data)
        .enter()
        .append('g')
        .classed('graph-point graph-point2', true)
        .on('mouseover', d3ReorderingmouseEvents.onMouseover)
        .on('mouseleave', d3ReorderingmouseEvents.onMouseleave)
        .attr('transform', d => {
          return `translate(${x(d.date)},${y(d.value2)})`;
        })
        .append('g')
        .classed('graph-point__inner', true)
        .attr('transform', 'scale(0.4)');

      dataPoint2
        .append('circle')
        .classed('graph-point__data-point graph-point2__data-point', true)
        .attr('r', 0)
        .transition()
        .delay((d, i) => {
          let s = i + 1;
          s = s * 150 + 1500;
          if (data.length === 1) {
            s = 0;
          }
          return s;
        })
        .duration(300)
        .attr('r', 26)
        .attr('opacity', '1')
        .transition()
        .delay(200)
        .duration(200)
        .attr('r', 20);

      dataPointLabel
        .append('text')
        .classed('graph-point__label', true)
        .text(d => {
          return d.value2;
        })
        .attr('transform', 'translate(0, 14)');

      dataPointLabel
        .append('rect')
        .classed('graph-point__label-line', true)
        .attr('width', 22)
        .attr('height', 1.25) // matching visual stroke width of font
        .attr('transform', 'translate(-11, 0)');

      const dataPointLabel2 = dataPoint2.append('g').classed('graph-point__data-group', true);

      dataPointLabel2
        .append('text')
        .classed('graph-point__label graph-point2__label', true)
        .text((d, i) => {
          const label = d.value;

          const dp2 = dataPoint2.filter((d, idx) => {
            return idx === i;
          }); // filter the matching dataPoint with current index
          if (label.length > 3) {
            dp2.select('text').classed('small', true);
          }
          // Add interpretation class to dataPoint
          if (d.interpretation2) {
            const interp = d.interpretation2.toLowerCase();
            setPointClass(dp2, interp);
          }

          return label;
        })
        .attr('transform', 'translate(0, -2)');

      dataPointLabel2
        .append('text')
        .classed('graph-point__label graph-point2__label', true)
        .text(d => {
          return d.value2;
        })

        .attr('transform', 'translate(0, 14)');

      dataPointLabel2
        .append('rect')
        .classed('graph-point__label-line graph-point2__label-line', true)
        .attr('width', 22)
        .attr('height', 1.25)
        .attr('transform', 'translate(-11, 0)');
    }
  };
  useEffect(() => {
    if (props.data.length > 0 && props.type === BLOOD_PRESSURE_VITAL_SERVICE_NAME) {
      cleanBPData(props.data);
    } else {
      cleanData(props.data);
    }
  });

  return (
    <LineChartContainer
      background={
        showBackground
          ? `linear-gradient(323deg, ${Color.blueHue3} 0%, ${Color.primary} 100%)`
          : 'transparent'
      }
    >
      <LineChartContainerStyles>
        <svg className="line-chart" ref={ref} width={`${window.innerWidth}px`} />
      </LineChartContainerStyles>
      {FullLineGraphKey(props.type)}
    </LineChartContainer>
  );
};

const mapStateToProps = (state: RootState) => ({
  data: vitalsSelectors.historicalVitalsChartDataSelector(state),
  isFetching: vitalsSelectors.recentVitalsLoadingSelector(state),
  error: vitalsSelectors.recentVitalsErrorSelector(state),
  age: currentAccountConsumerAgeSelector(state)
});

export default connect(mapStateToProps)(VitalsChart);
