import dayjs from 'dayjs';
import React, { useRef } from 'react';
import isNil from 'lodash/isNil';
import { useSelector } from 'react-redux';
import { currentAccountConsumerSelector } from 'store/account/selectors';
import { useHistory } from 'react-router-dom';
import { EducationItem } from 'store/education/types';
import { FontSize, FontWeight } from 'modules/styles/variables';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { MuiBox, MuiTypography, MuiPaper, MuiContainer, MuiButton } from 'theme/material-ui';
import { VerticalDivider } from 'components/UI/Divider';
import ExpandableText from 'components/ExpandableText/ExpandableText';

interface EducationViewProps {
  educationItem?: EducationItem;
  onNext?: () => Promise<void>;
  nextCount?: number;
  loading?: boolean;
}

export const EducationView = ({
  educationItem,
  onNext,
  nextCount,
  loading
}: EducationViewProps) => {
  const history = useHistory();
  const typographyRef = useRef(null);
  const consumer = useSelector(currentAccountConsumerSelector);
  const patientName = consumer?.displayName || '';

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const getNextBtnText = () => {
    return `Go to next assignment (${nextCount})`;
  };

  return (
    <MuiContainer>
      <MuiBox alignContent="center">
        <MuiBox m={5}>
          <MuiPaper elevation={0}>
            <MuiBox p={3} display="flex" flexDirection={isSmallScreen ? 'column' : 'row'}>
              <MuiBox flex={1}>
                <MuiTypography data-testid="program-name" fontSize={FontSize.largeHeading}>
                  {educationItem?.programName}
                </MuiTypography>
                <MuiTypography data-testid="due-date" fontWeight={FontWeight.bold}>
                  Due Date: {dayjs(educationItem?.viewBy).format('MM/DD/YYYY')}
                </MuiTypography>
              </MuiBox>

              <MuiBox flex={1} display="flex" flexDirection="row" alignItems="center">
                {!isSmallScreen ? (
                  <VerticalDivider style={{ height: '45px', marginRight: '15px' }} />
                ) : null}
                <MuiBox>
                  <MuiTypography data-testid={`${patientName}`}>
                    Patient: {patientName}
                  </MuiTypography>
                  <MuiTypography data-testid="education-status">
                    Status: {educationItem?.status}
                  </MuiTypography>
                </MuiBox>
              </MuiBox>
            </MuiBox>
          </MuiPaper>
        </MuiBox>

        {educationItem?.customerNotes ? (
          <MuiBox m={5}>
            <MuiPaper elevation={0}>
              <MuiBox p={3} overflow="hidden" display="flex" flexDirection="column" flex={1}>
                <MuiTypography
                  data-testid="additional-provider-comments"
                  fontSize={FontSize.heading}
                  fontWeight={FontWeight.semibold}
                >
                  Additional Provider Comments
                </MuiTypography>
                <ExpandableText typographyRef={typographyRef} text={educationItem?.customerNotes} />
              </MuiBox>
            </MuiPaper>
          </MuiBox>
        ) : null}

        <MuiBox m={5}>
          <MuiPaper elevation={0}>
            <MuiBox p={3}>
              <MuiBox
                display="flex"
                flexDirection={isSmallScreen ? 'column' : 'row'}
                justifyContent="center"
              >
                <MuiButton
                  data-testid="go-to-education-page"
                  onClick={() => history.push('/u/health-record/patient-education')}
                  variant="contained"
                  color="primary"
                >
                  Go to education page
                </MuiButton>
                {!isNil(onNext) ? (
                  <>
                    <MuiBox p={1}></MuiBox>
                    <MuiButton
                      data-testid="go-to-next-assignment"
                      aria-label="next-assingment-button"
                      onClick={onNext}
                      variant="outlined"
                      color="primary"
                      disabled={loading}
                    >
                      {getNextBtnText()}
                    </MuiButton>
                  </>
                ) : null}
              </MuiBox>

              <MuiBox m={2}>
                <iframe
                  key={educationItem?.accessCode}
                  width="100%"
                  height="500px"
                  frameBorder="0"
                  src={educationItem?.url}
                  title={educationItem?.programName}
                  allowFullScreen
                ></iframe>
              </MuiBox>
            </MuiBox>
          </MuiPaper>
        </MuiBox>
      </MuiBox>
    </MuiContainer>
  );
};

export default EducationView;
