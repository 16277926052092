import React, { Component } from 'react';
import { Dimensions, FlatList } from 'react-native';
import styled from 'styled-components';
import head from 'lodash/head';

import { Column } from 'components/UI/Layout/Container';
import Panel from 'components/UI/Panel/Panel';
import Section from 'components/UI/Layout/Section';
import DefaultLabel from 'components/UI/Labels/DefaultLabel';
import Subhead from 'components/UI/Typography/Subhead';

import { Color } from 'modules/styles/colors';
import { borderRadius, Spacing } from 'modules/styles/variables';
import Box from '../Layout/Box';

interface Props<T> {
  customWidth?: string;
  customPadding?: string;
  title?: string;
  textColor?: string;
  data?: T[];
  keyExtractor: (item: T) => string;
  renderItem: (item: T, index: number) => React.ReactNode;
  hideIndicator?: boolean;
  emptyCaption?: string | React.ReactNode;
  pagingEnabled?: boolean;
}

const viewabilityConfig = { viewAreaCoveragePercentThreshold: 50 };

const Title = styled(Subhead)`
  margin-left: 20px;
  margin-bottom: 0px;
  color: ${props => props.textColor};
`;

const StyledFlatList = styled(FlatList)`
  align-self: flex-start;
  margin-bottom: ${props => (props.hideIndicator ? 10 : 0)};
`;

const StyledSection = styled(Section)`
  padding-top: 5px;
  padding-bottom: 0px;
  width: ${props => props.customWidth};
  padding: ${props => props.customPadding};
`;

const IndicatorStrip = styled.div`
  align-items: center;
  flex-direction: row;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const Indicator = styled.div`
  background-color: ${props => (props.selected ? props.textColor : 'transparent')};
  border-color: ${props => props.textColor};
  border-radius: ${borderRadius};
  border-width: 1px;
  height: 8px;
  margin-left: 2.5px;
  margin-right: 2.5px;
  width: 8px;
`;

export default class TouchablePanelSwiper<T> extends Component<Props<T>> {
  state = {
    currentIndex: 0
  };

  handleViewableItemsChange = item => {
    const { changed, viewableItems } = item;
    if (changed.length > 0) {
      const panel = changed.find(p => p.isViewable) || head(viewableItems) || {};
      this.setState({ currentIndex: panel.index });
    }
  };

  render() {
    const { data = [], title, emptyCaption, keyExtractor, renderItem, textColor } = this.props;
    const { currentIndex } = this.state;
    return (
      <Column>
        {title !== '' && <Title textColor={textColor}>{`${title} (${data.length})`}</Title>}
        {data.length === 0 && (
          <Box spacing={Spacing.large}>
            <Panel>
              {typeof emptyCaption === 'string' ? (
                <DefaultLabel>{emptyCaption}</DefaultLabel>
              ) : (
                emptyCaption
              )}
            </Panel>
          </Box>
        )}
        {data.length > 0 && (
          <Column>
            <StyledFlatList
              // onViewableItemsChanged={this.handleViewableItemsChange}
              data={data}
              keyExtractor={keyExtractor}
              horizontal
              pagingEnabled={this.props.pagingEnabled || false}
              showsHorizontalScrollIndicator={false}
              hideIndicator={this.props.hideIndicator}
              viewabilityConfig={viewabilityConfig}
              renderItem={({ item, index }) => {
                return (
                  <StyledSection
                    customWidth={this.props.customWidth}
                    customPadding={this.props.customPadding}
                  >
                    {renderItem(item, index)}
                  </StyledSection>
                );
              }}
            />
          </Column>
        )}
        {data.length > 1 && !this.props.hideIndicator && (
          <IndicatorStrip>
            {data.map((item, index) => (
              <Indicator
                key={keyExtractor(item)}
                selected={index === currentIndex}
                textColor={textColor}
              />
            ))}
          </IndicatorStrip>
        )}
      </Column>
    );
  }
}

TouchablePanelSwiper.defaultProps = {
  title: '',
  data: [],
  emptyCaption: undefined,
  textColor: Color.textDark,
  customWidth: Dimensions.get('window').width,
  customPadding: '20px',
  hideIndicator: false
};
