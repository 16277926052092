import React from 'react';
import { MuiBox, MuiTypography } from 'theme/material-ui';
import { UPDATES } from 'lib/constants/dashboard';

const UpdatesListNoData = () => {
  return (
    <MuiBox
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      py={3}
    >
      <MuiBox maxWidth={300}>
        <MuiTypography align="center">{UPDATES.empty.title}</MuiTypography>
      </MuiBox>
    </MuiBox>
  );
};

export default UpdatesListNoData;
