import React, { Fragment, useMemo, useEffect } from 'react';
import { connect } from 'react-redux';
import { MuiButton, MuiLink } from 'theme/material-ui';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';

import Spinner from 'components/UI/Spinner/Spinner';
import { Icon } from 'components/Icon';
import { Color } from 'modules/styles/colors';
import { formatDate } from 'modules/utils/DateUtils';
import { RootState } from 'store/types';
import { isImpersonatingSelector } from 'store/account/selectors';
import { consumerIdSelector } from 'store/profile/selectors';
import { getFinancialAssistanceApplications } from 'store/billing/actions';

import { FinancialAssistanceApplication as Application } from 'store/billing/types';
import analyticsService, { AmplitudeEventData, AnalyticsEvent } from 'services/AnalyticsService';
import {
  currentLocationPathNameSelector,
  previousLocationPathNameSelector
} from 'store/router/selectors';
import { history } from 'lib/history';

import { FAStatusProps } from './common';
import {
  ApplicationDetailedStatus,
  ApplicationStatus,
  ApplicationStatusColor
} from '../../constants';
import { PATIENT_FINANCIAL_SERVICE_NUMBER } from '../FAApplication/constants';
import { convertToLowerKabobCase } from 'modules/utils/StringUtils';
import { StyledTableRow } from './styled';
import { getFinancialAssistanceApplicationsSelector } from 'store/billing/selectors';
import { AppName, getAppName } from 'modules/utils/ConfigUtils';

export const FAStatus = ({
  currentUrl,
  referringUrl,
  getFinancialAssistanceApplications,
  isImpersonating,
  financialAssistanceApplications
}: FAStatusProps) => {
  const { data, error, isFetching: loading } = financialAssistanceApplications;

  useEffect(() => {
    getFinancialAssistanceApplications();
  }, [isImpersonating, currentUrl]);

  const eventData: AmplitudeEventData = {
    currentUrl,
    referringUrl
  };

  const applications: Application[] =
    data && Array.isArray(data)
      ? data.map(
          (
            app: Application // set Status to be "I" for incomplete application
          ) => {
            switch (app.detailedStatus) {
              case ApplicationDetailedStatus.INCOMPLETE:
                return { ...app, status: ApplicationStatus.INCOMPLETE };
              case ApplicationDetailedStatus.CANCELLED:
              case ApplicationDetailedStatus.DECLINED:
                return { ...app, detailedStatus: 'Denied' };
              default:
                return app;
            }
          }
        )
      : [];

  const pendingApplication: Application | undefined = useMemo(() => {
    return applications.find(
      app => app.status === ApplicationStatus.PENDING || app.status === ApplicationStatus.INCOMPLETE
    );
  }, [data]);

  const previousApplications: Application[] = useMemo(() => {
    return applications.filter(
      app => app.status !== ApplicationStatus.PENDING && app.status !== ApplicationStatus.INCOMPLETE
    );
  }, [data]);

  const navigateToApplicationDetail = (app: Application) => {
    let enumName;
    switch (app.status) {
      case ApplicationStatus.APPROVED:
        enumName = 'ApprovedStatusClicked';
        break;
      case ApplicationStatus.DECLINED:
      case ApplicationStatus.CANCELLED:
        enumName = 'DeniedStatusClicked';
        break;
      case ApplicationStatus.PENDING:
        enumName = 'UnderReviewStatusClicked';
        break;
      case ApplicationStatus.INCOMPLETE:
        enumName = 'IncompleteStatusClicked';
        break;
      default:
    }

    analyticsService.logEvent(AnalyticsEvent[enumName as keyof typeof AnalyticsEvent], eventData);

    if (app.id) {
      history.push(`/u/fa-app/statusDetails/${app.id}`, {
        application: app
      });
    }
  };

  const renderItem = (item: Application) => {
    return (
      <Fragment key={`${item.id}${item.createDate}`}>
        <StyledTableRow hover onClick={() => navigateToApplicationDetail(item)}>
          <TableCell>
            <Typography>
              Applied:{' '}
              <Box component="span" color={Color.secondary}>
                {formatDate(item.createDate)}
              </Box>
            </Typography>
          </TableCell>
          <TableCell data-testid={convertToLowerKabobCase(item.detailedStatus, '-status')}>
            <div style={{ color: ApplicationStatusColor[item.status as ApplicationStatus] }}>
              {item.detailedStatus}
            </div>
          </TableCell>
          <TableCell>
            <Typography>Application ID: {item.id}</Typography>
          </TableCell>
          <TableCell>
            <Icon name="ArrowForwardIos" color={Color.gray} size={15} />
          </TableCell>
        </StyledTableRow>
      </Fragment>
    );
  };

  return (
    <Container>
      {error && (
        <Box my={5}>
          <Alert severity="error">There was an issue fetching application details.</Alert>
        </Box>
      )}
      {pendingApplication ? (
        <>
          <Box mb={5}>
            <Paper style={{ marginTop: '-20px' }}>
              <Box p={6}>
                <Grid container spacing={6}>
                  <Grid item md={7} sm={12}>
                    <Box mb={2}>
                      <Typography>
                        Please allow 2-4 weeks for processing your Financial Assistance request.
                      </Typography>
                    </Box>
                    <Typography>
                      Note that below list represents status of Financial Assistance applications
                      submitted using this app. For applications not submitted using{' '}
                      {getAppName(AppName.base, 'the', AppName.suffix1)} or{' '}
                      {getAppName(AppName.tertiary)}, Intermountain will communicate the status and
                      any verification documents that you may be missing via mail.
                    </Typography>
                  </Grid>
                  <Grid item md={5} sm={12}>
                    <Box bgcolor="grey.100" p={2} borderRadius={2} mb={2}>
                      <Typography>
                        If you need help please call Financial Assistance Dept
                      </Typography>
                      <Typography variant="h6">
                        <MuiLink
                          data-testid="service-number"
                          href={`tel: ${PATIENT_FINANCIAL_SERVICE_NUMBER}`}
                        >
                          {PATIENT_FINANCIAL_SERVICE_NUMBER}
                        </MuiLink>
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Box>
          <Box mb={5}>
            <Box mb={1}>
              <Typography variant="h6">Pending Applications</Typography>
            </Box>
            <TableContainer component={Paper}>
              <Table>
                <TableBody>{renderItem(pendingApplication)}</TableBody>
              </Table>
            </TableContainer>
          </Box>
        </>
      ) : loading ? (
        <Box py={6}>
          <Spinner />
        </Box>
      ) : (
        <Box mb={5}>
          <Paper style={{ marginTop: '-20px' }}>
            <Box p={6}>
              <Grid container spacing={6}>
                <Grid item md={7} sm={12}>
                  <Typography>
                    You have no active applications at this time. If you would like to be considered
                    for Financial Assistance please apply.
                  </Typography>
                </Grid>
                <Grid item md={5} sm={12}>
                  <MuiButton
                    variant="contained"
                    color="primary"
                    size="large"
                    fullWidth
                    onClick={() => {
                      analyticsService.logEvent(AnalyticsEvent.ReApplyClicked, eventData);
                      history.push('/u/fa-app');
                    }}
                  >
                    {previousApplications.length ? 'Re-apply' : 'Apply'}
                  </MuiButton>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Box>
      )}
      {!!previousApplications.length && (
        <Box mb={5}>
          <Box mb={1}>
            <Typography variant="h6">Finalized Applications</Typography>
          </Box>
          <TableContainer component={Paper}>
            <Table>
              <TableBody>{previousApplications.map(renderItem)}</TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </Container>
  );
};

const mapStateToProps = (state: RootState) => ({
  guarantorMmi: consumerIdSelector(state), // consumerId is guarantor mmi
  currentUrl: currentLocationPathNameSelector(state),
  referringUrl: previousLocationPathNameSelector(state),
  isImpersonating: isImpersonatingSelector(state),
  financialAssistanceApplications: getFinancialAssistanceApplicationsSelector(state)
});

const mapDispatchToProps = {
  getFinancialAssistanceApplications
};

export default connect(mapStateToProps, mapDispatchToProps)(FAStatus);
