import Spinner from 'components/UI/Spinner/Spinner';
import React, { useState, useMemo } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import remove from 'lodash/remove';
import {
  MuiFormGroup,
  MuiFormControlLabel,
  MuiCheckbox,
  MuiBox,
  MuiButton,
  MuiTypography,
  MuiGrid
} from 'theme/material-ui';
import { useAuditLogFilters } from 'lib/hooks/AuditLogFilters/useAuditLogFilters';
import { FiltersFormValues } from 'lib/hooks/AuditLogFilters/types/filter';
import { AuditLogFilterGroups, AuditLogFacetGroups } from 'store/audit/types';

import { FontWeight } from 'modules/styles/variables';

export const AuditLogLoading = () => (
  <MuiBox p={5} display="flex" alignItems="center" justifyContent="center">
    <Spinner />
  </MuiBox>
);

export const AuditLogNoData = () => (
  <MuiBox p={5} display="flex" alignItems="center" justifyContent="center">
    <MuiTypography>No audit log entries found</MuiTypography>
  </MuiBox>
);

export const AuditLogError = () => (
  <MuiBox p={5} display="flex" alignItems="center" justifyContent="center">
    <MuiTypography>There was an issue loading your audit logs</MuiTypography>
  </MuiBox>
);

export const getAuditDateAndTime = (date: string) => {
  const [itemDate, itemTime] = date.toString().split('T');

  return [itemDate, itemTime];
};

//
//  Component: FormCheckboxList
//
//  Description:
//  <FormCheckboxList /> is a component that is shared throughout the filters for consistent checkbox layout.
//  FormCheckboxList logic should remain agnostic to specific logic outside of handling AuditLogFilterGroups | AuditLogFacetGroups
//
//  Props:
//  list: T[];
//  initialCount?: number;
//  onChange?: (item: T, checked: boolean) => void;
//  itemToLabel?: (item: T) => string;
//  itemToValue?: (item: T) => string;
//  itemToGroup?: (item: T) => AuditLogFilterGroups | AuditLogFacetGroups | string;
//  renderItem?: (item: T) => JSX.Element | null;
//
export function FormCheckboxList<T>({
  list,
  initialCount,
  onChange,
  itemToLabel = (item: any) => (item ? item.title : ''),
  itemToValue = (item: any) => (item ? item.value : ''),
  itemToGroup = (item: any) => (item ? item.group : ''),
  renderItem = () => null
}: {
  list: T[];
  initialCount?: number;
  onChange?: (item: T, checked: boolean) => void;
  itemToLabel?: (item: T) => string;
  itemToValue?: (item: T) => string;
  itemToGroup?: (item: T) => AuditLogFilterGroups | AuditLogFacetGroups | string;
  renderItem?: (item: T) => JSX.Element | null;
}) {
  const hasMore = initialCount && list.length > initialCount;
  const { values, setFieldValue } = useAuditLogFilters();
  const [showMore, setShowMore] = useState(hasMore ? null : false);

  // Determines whether to limit the list to show up to the initialCount or all
  const items = useMemo(() => {
    if (!hasMore || showMore) return list;
    return list.slice(0, initialCount);
  }, [list, initialCount, hasMore, showMore]);

  // Determines the checked state of each field
  const checked = (item: T) => {
    const group = itemToGroup(item);
    const value = itemToValue(item);

    if (group in AuditLogFacetGroups) {
      const key = group as AuditLogFacetGroups;
      return values.facets[key].some(f => f.value === value);
    }

    if (group in values) {
      const key = group as keyof FiltersFormValues;
      return Boolean(values[key]);
    }

    return false;
  };

  // Handles the change value for the list
  const handleChange = (item: T, checked: boolean) => {
    const title = itemToLabel(item);
    const group = itemToGroup(item);
    const value = itemToValue(item);

    const facets = cloneDeep(values.facets);
    if (group in AuditLogFacetGroups) {
      const key = group as AuditLogFacetGroups;

      if (facets[key].some(f => f.value === value)) {
        remove(facets[key], f => f.value === value);
      } else {
        if (item.group === 'dateAndTimeOfEvents') {
          facets[key] = [];
        }
        facets[key].push({ title, group: key, value });
      }

      setFieldValue('facets', facets);
    }

    if (group in values) {
      setFieldValue(group, checked);
    }

    onChange?.(item, checked);
  };

  return (
    <MuiFormGroup>
      {items.map(item => (
        <MuiGrid key={itemToLabel(item)} container>
          <MuiGrid item xs={12}>
            <MuiFormControlLabel
              control={
                <MuiCheckbox
                  data-testid="checkbox-items"
                  color="primary"
                  checked={checked(item)}
                  onChange={(_, checked) => handleChange(item, checked)}
                />
              }
              label={itemToLabel(item)}
            />
          </MuiGrid>

          {renderItem(item) ? (
            <MuiGrid item xs={12}>
              <MuiBox ml={3.5}>{renderItem(item)}</MuiBox>
            </MuiGrid>
          ) : null}
        </MuiGrid>
      ))}

      {hasMore ? (
        <MuiBox>
          <MuiButton color="primary" onClick={() => setShowMore(prevState => !prevState)}>
            {showMore ? 'Show less' : 'Show more'}
          </MuiButton>
        </MuiBox>
      ) : null}
    </MuiFormGroup>
  );
}

//
//  Component: FormChangeItem
//
//  Description:
//  <FormChangeItem /> is a component that is shared throughout the filters for consistent value + Change button
//
//  Props:
//  value: string;
//  onClick: () => void;
//
export function FormChangeItem({ value, onClick }: { value: string; onClick: () => void }) {
  return (
    <MuiBox display="flex" flexDirection="row" alignItems="center">
      <MuiTypography variant="body2" fontWeight={FontWeight.semibold}>
        {value}
      </MuiTypography>
      <MuiButton color="primary" onClick={onClick}>
        Change
      </MuiButton>
    </MuiBox>
  );
}
