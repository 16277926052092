import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import analyticsService, { AmplitudeEventData, AnalyticsEvent } from 'services/AnalyticsService';
import { handleUrlOnComplete } from 'store/pageActions/RegisterAccount';
import CustomButton from '../FormElements/CustomButton';
import { getRedirectIdx, getRegisterBrand } from 'store/register';

const RegisterSuccess = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const redirect_index = getRedirectIdx();
  const redirect_brand = getRegisterBrand();
  const eventData: AmplitudeEventData = {
    redirect_index,
    redirect_brand
  };

  useEffect(() => {
    analyticsService.logEvent(AnalyticsEvent.AccountCreationCompleted, eventData);
  }, []);

  return (
    <>
      <Box mb={3}>
        <Box mb={1}>
          <Typography variant="h6">Account Created</Typography>
        </Box>
        <Typography variant="body1">Your account has been successfully created.</Typography>
      </Box>
      <Box mb={3} textAlign="center">
        <CustomButton
          type="submit"
          label="Continue to login"
          aria-label="submit-button"
          onClick={() => dispatch(handleUrlOnComplete(history))}
        />
      </Box>
    </>
  );
};

export default RegisterSuccess;
