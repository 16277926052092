import React, { useState } from 'react';
import { Color } from 'modules/styles/colors';
import {
  Grid,
  Box,
  Typography,
  IconButton,
  List,
  ExpansionPanelDetails,
  Divider,
  Button
} from '@material-ui/core';
import { Icon } from 'components/Icon';
import { StyledDrawer, StyledDrawerHeader, ExpansionPanel, ExpansionPanelSummary } from './styled';
import { convertToLowerKabobCase } from 'modules/utils/StringUtils';

interface DrawerHeaderProps {
  title: string;
  onClose: () => void;
}

function DrawerHeader({ title, onClose }: DrawerHeaderProps) {
  return (
    <StyledDrawerHeader>
      <Box display="flex" alignItems="center">
        <Typography style={{ color: Color.textLight }}>{title}</Typography>{' '}
      </Box>
      <IconButton data-testid="drawer-close" aria-label="open drawer" edge="end" onClick={onClose}>
        <Icon name="close" color={Color.textLight} />
      </IconButton>
    </StyledDrawerHeader>
  );
}

interface DrawerFooterProps {
  onApply: () => void;
  onClear: () => void;
  disabled?: boolean;
}

function DrawerFooter({ onApply, onClear, disabled }: DrawerFooterProps) {
  return (
    <Box p={2}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Button
            size="large"
            variant="contained"
            color="primary"
            onClick={onApply}
            fullWidth
            data-testid="apply-button"
            disabled={disabled}
          >
            Apply
          </Button>
        </Grid>
        {onClear ? (
          <Grid item xs={12}>
            <Button disabled={disabled} size="large" color="primary" onClick={onClear} fullWidth>
              Clear
            </Button>
          </Grid>
        ) : null}
      </Grid>
    </Box>
  );
}

export interface DrawerPanelProps {
  title: string;
  startAdornment?: React.ReactNode;
  children: React.ReactNode;
  defaultExpanded: boolean;
  expandable: boolean;
  onToggle?: (e: React.ChangeEvent<{}>) => void;
}

function DrawerPanel<T>({
  defaultExpanded,
  expandable,
  onToggle,
  children,
  title,
  startAdornment
}: DrawerPanelProps) {
  const [expanded, setExpanded] = useState(defaultExpanded);

  const onChange = (e: React.ChangeEvent<{}>) => {
    setExpanded(prevState => !prevState);
    onToggle?.(e);
  };

  return (
    <>
      <ExpansionPanel expanded={expanded} disabled={!expandable} onChange={onChange}>
        <ExpansionPanelSummary
          bgcolor={expanded ? Color.grayHue4 : 'inherit'}
          expandIcon={<Icon name={expanded ? 'remove' : 'add'} color={Color.black} />}
        >
          {startAdornment || null}
          <Typography
            data-testid={convertToLowerKabobCase(title, '-collapsible-group')}
            style={{ fontWeight: 600 }}
          >
            {title}
          </Typography>
        </ExpansionPanelSummary>

        <ExpansionPanelDetails>
          <Box width="100%">{children}</Box>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <Divider />
    </>
  );
}

DrawerPanel.defaultProps = {
  defaultExpanded: false,
  expandable: true
};

export interface DrawerProps {
  open: boolean;
  title: string;
  onClose: () => void;
  onApply: () => void;
  onClear?: () => void;
  width?: string | number;
  disabled?: boolean;
  children: React.ReactNode;
}

function Drawer({
  open,
  title,
  onClose,
  onApply,
  onClear,
  width,
  disabled,
  children
}: DrawerProps) {
  return (
    <StyledDrawer anchor="right" open={open} width={width} onClose={onClose}>
      <DrawerHeader title={title} onClose={onClose} />
      <List disablePadding style={{ flex: 1 }}>
        {children}
      </List>
      <DrawerFooter disabled={disabled} onApply={onApply} onClear={onClear} />
    </StyledDrawer>
  );
}

Drawer.Panel = DrawerPanel;

export default Drawer;
