import { getVisitSummaryLinkPath } from '../../VisitSummary/navigation';
import React from 'react';
import { connect } from 'react-redux';
import Config from 'react-native-config';
import { RootState } from 'store/types';
import startCase from 'lodash/startCase';
import {
  MuiBox,
  MuiGrid,
  MuiCard,
  MuiCardContent,
  MuiTypography,
  MuiCardActions,
  MuiButton
} from 'theme/material-ui';
import { Color } from 'modules/styles/colors';
import { oc } from 'ts-optchain';
import ListItemSection from './ListItemSection';
import Avatar from 'components/UI/Avatar/Avatar';
import { Link } from 'react-router-dom';
import { VisitSummary } from 'store/visitSummary/types';
import { formatDate } from 'modules/utils/DateUtils';
import analyticsService, { AnalyticsEvent } from 'services/AnalyticsService';
import {
  currentLocationPathNameSelector,
  previousLocationPathNameSelector
} from 'store/router/selectors';
import { ItemHeader, LocationItem, PatientItem } from './styled';
import { FontSize } from 'modules/styles/variables';

export interface HealthRecordsVisitSummaryListItemProps {
  visit: VisitSummary;
  currentUrl?: string;
  referringUrl?: string;
}

const visitSummaryDisplayText = (visit: VisitSummary) => ({
  provider: oc(visit).primaryProvider.providerName(''),
  providerUrl: oc(visit).primaryProvider.providerImageUrl(''),
  isSelectHealth: oc(visit).primaryProvider.isSelectHealth(false),
  location: oc(visit).locationObject.locationName(''),
  visitDescription: startCase(visit.visitDescription),
  date: visit.date && formatDate(visit.date),
  patient: visit.patient,
  status: visit?.status
});

const hideButton = (visit: VisitSummary) =>
  Config.EVISITS === 'enabled' && visit?.status === 'in-progress';

export const HealthRecordsVisitSummaryListItem = ({
  visit,
  currentUrl,
  referringUrl
}: HealthRecordsVisitSummaryListItemProps): JSX.Element | null => {
  const text = visitSummaryDisplayText(visit);
  const eVisitText = Config.EVISITS === 'enabled' && text.location.includes('CCCL_EVisit');
  const locationText = eVisitText ? 'E-Visit appointment' : text.location;
  const finishedEVisitDescriptionText =
    text.status === 'finished' && eVisitText
      ? `${text.visitDescription} E-Visit`
      : text.visitDescription;
  const visitDescriptionText =
    Config.EVISITS === 'enabled' && text.status === 'in-progress'
      ? 'E-Visit is under review'
      : finishedEVisitDescriptionText;

  const handleViewDetailsButtonClicked = () => {
    analyticsService.logEvent(AnalyticsEvent.VisitSummaryDetailClicked, {
      referringUrl,
      currentUrl
    });
  };

  return (
    <MuiGrid item xs={12} md={4} className="visit-summary-list-item">
      <MuiCard style={{ height: '100%', backgroundColor: Color.foreColor }}>
        <MuiCardContent data-testid="visit-summary-card">
          <ItemHeader>
            <MuiBox>
              <MuiTypography>{text.date}</MuiTypography>
              <MuiTypography fontSize={FontSize.heading} fontWeight="bold">
                {visitDescriptionText}
              </MuiTypography>
            </MuiBox>
            <MuiBox borderRadius={50} border={`3px solid ${Color.secondary}`}>
              <Avatar imageUrl={text.providerUrl} style={{ backgroundColor: Color.white }} />
            </MuiBox>
          </ItemHeader>
          <ListItemSection
            useIcon={text.isSelectHealth}
            iconName="SelectHealth"
            iconAltText="Caregiver"
            displayText={text.provider}
          />
          <LocationItem
            useMuiIcon
            useIcon
            iconName="location-on"
            iconAltText="Location"
            displayText={locationText}
          />
          <PatientItem>
            <MuiTypography color={Color.gray}>Patient: {text.patient}</MuiTypography>
          </PatientItem>
        </MuiCardContent>
        {hideButton(visit) ? null : (
          <MuiCardActions>
            <MuiButton
              color="primary"
              variant="outlined"
              component={Link}
              to={getVisitSummaryLinkPath(visit)}
              onClick={handleViewDetailsButtonClicked}
              data-testid="view-details-button"
            >
              View details
            </MuiButton>
          </MuiCardActions>
        )}
      </MuiCard>
    </MuiGrid>
  );
};

const mapState = (state: RootState) => ({
  currentUrl: currentLocationPathNameSelector(state),
  referringUrl: previousLocationPathNameSelector(state)
});

export default connect(mapState)(HealthRecordsVisitSummaryListItem);
