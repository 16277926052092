import CallToActionRow from 'components/common/CallToActionRow/CallToActionRow';
import CollapsibleListItem from 'components/common/CollapsibleList/CollapsibleListItem';
import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'store/types';
import { MuiBox } from 'theme/material-ui';
import Spinner from 'components/UI/Spinner/Spinner';
import analyticsService, { AnalyticsEvent } from 'services/AnalyticsService';
import {
  currentLocationPathNameSelector,
  previousLocationPathNameSelector
} from 'store/router/selectors';
import { HealthRecordsListItemPlaceholder } from '../shared';
import { VitalsBlock } from 'store/vitals/types';
import styled from 'styled-components';
import { HEALTH_RECORDS_DRAWERS } from 'lib/constants/healthRecords';
import { useHistory } from 'react-router-dom';
import kebabCase from 'lodash/kebabCase';
import BlockSet from 'components/UI/Blocks/BlockSet';
import { Spacing } from 'modules/styles/variables';

interface HealthRecordsVitalsListProps {
  error: boolean;
  loading?: boolean;
  lastUpdated: string;
  vitals: VitalsBlock[];
  isDownloadingVitals: boolean;
  handleDownloadVitals: () => void;
  handleShareVitals: () => void;
  currentUrl?: string;
  referringUrl?: string;
}

const StyledCallToActionRow = styled(CallToActionRow)`
  && {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export function HealthRecordsVitalsList({
  error,
  loading,
  vitals,
  lastUpdated,
  isDownloadingVitals,
  handleDownloadVitals,
  handleShareVitals,
  currentUrl,
  referringUrl
}: HealthRecordsVitalsListProps) {
  const history = useHistory();

  const handleBlockPress = (block: VitalsBlock) => {
    const enumName = block.serviceName
      ? block.serviceName.concat('Clicked')
      : 'BlankVitalsBlockClicked';
    analyticsService.logEvent(AnalyticsEvent[enumName], {
      currentUrl,
      referringUrl
    });

    history.push(
      `/u/health-record/vitals/${block.serviceName}/${kebabCase(block.title.toLowerCase())}`
    );
  };

  const getPlaceHolder = () => {
    let message: string | undefined;
    if (loading) {
      return (
        <>
          <HealthRecordsListItemPlaceholder message={HEALTH_RECORDS_DRAWERS.Vitals.loading} />
          <MuiBox display="flex" flexDirection="row" justifyContent="center">
            <Spinner />
          </MuiBox>
        </>
      );
    }

    if (error) {
      message = HEALTH_RECORDS_DRAWERS.Vitals.error;
    } else if (vitals.length === 0) {
      message = HEALTH_RECORDS_DRAWERS.getEmptyState('vitals');
    }

    return <HealthRecordsListItemPlaceholder message={message} />;
  };

  return (
    <CollapsibleListItem
      leftIcon="VitalsIcon"
      title={HEALTH_RECORDS_DRAWERS.Vitals.title}
      subtitle={HEALTH_RECORDS_DRAWERS.Vitals.subtitle(lastUpdated)}
      error={error ? 'An issue occurred' : undefined}
      analyticsEvent={AnalyticsEvent.VitalsClicked}
      currentUrlForAnalytics={currentUrl}
      referringUrlForAnalytics={referringUrl}
      detailsPadding={`${Spacing.large}px 0`}
    >
      {loading || error || vitals.length === 0 ? (
        getPlaceHolder()
      ) : (
        <>
          <BlockSet blocks={vitals} onBlockPressed={handleBlockPress} />
          <StyledCallToActionRow
            onTransmitPress={handleShareVitals}
            isDownloading={isDownloadingVitals}
            onDownloadPress={handleDownloadVitals}
          />
        </>
      )}
    </CollapsibleListItem>
  );
}

const mapState = (state: RootState) => ({
  currentUrl: currentLocationPathNameSelector(state),
  referringUrl: previousLocationPathNameSelector(state)
});

export default connect(mapState)(HealthRecordsVitalsList);
