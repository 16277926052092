import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';

import Accordion from 'components/UI/Accordion';
import Banner from 'components/UI/Banner/Banner';
import Spinner from 'components/UI/Spinner/Spinner';
import { MuiBox } from 'theme/material-ui';
import { AnalyticsEvent } from 'services/AnalyticsService';

import { NavigationScreenProps } from 'screens/navigation';
import TermsAndConditions from 'screens/TermsAndConditions/TermsAndConditions';

import { getTooltips } from 'store/tooltips/actions';
import { RootDispatch, RootState } from 'store/types';
import { getMyDoctors } from 'store/myDoctors/actions';
import { getProfileForInsurance, getVisitpayEnrolledInfo } from 'store/profile/actions';
import { TermsAndConditionsType } from 'store/global/reducers';
import {
  profileGrantorsSelector,
  profileVisitpayEnrolled,
  profileVisitpayLoading,
  userGeneralTermsAndConditionsSelector
} from 'store/profile/selectors';
import {
  currentLocationPathNameSelector,
  previousLocationPathNameSelector
} from 'store/router/selectors';
import { languageSelector } from 'store/consumerPreferences/selectors';
import { allGrantorsNeedsActivationSelector } from 'store/account/selectors';

import { ProfileContainer } from './styled';
import { ProfileSectionHeader } from './components/ProfileSectionHeader';
import ManageFamilyFinance from './ManageFamilyFinance/ManageFamilyFinance';
import {
  accountManagementMenuItems,
  getSettingsItems,
  accountSharingMenuItems,
  securityAndLegalMenuItems
} from './constants';
import { Consumer } from 'store/profile/types';
import { useParams } from 'react-router-dom';
import { LANGUAGE } from 'store/consumerPreferences/types';
import { getPaymentPlanSummary } from 'store/billing/actions';

interface ProfileScreenProps extends NavigationScreenProps {
  allProxiesNeedsActivation: boolean;
  currentUrl?: string;
  dispatch: RootDispatch;
  generalTermAcceptance: TermsAndConditionsType;
  grantors: Consumer[];
  referringUrl?: string;
  visitpayEnrolled: boolean;
  visitpayLoading: boolean;
  language: LANGUAGE;
}

interface QueryParams {
  expandKey: string;
}

export const ProfileScreen = ({
  allProxiesNeedsActivation,
  currentUrl,
  dispatch,
  generalTermAcceptance,
  grantors = [],
  referringUrl,
  history,
  visitpayLoading,
  visitpayEnrolled,
  language
}: ProfileScreenProps) => {
  const { expandKey = '' } = useParams() as QueryParams;
  const [showTermsOnboardingModal, setShowTermsOnboardingModal] = useState(!generalTermAcceptance);
  const [paymentPlansLoading, setPaymentPlansLoading] = useState(true);

  const scrollToDefaultExpandRef = useRef(null);
  const executeScroll = async () =>
    scrollToDefaultExpandRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: `${referringUrl === 'unknown' ? 'start' : 'center'}`
    });
  useEffect(() => {
    if (expandKey) executeScroll();
  }, [scrollToDefaultExpandRef]);

  useEffect(() => {
    const loadData = async () => {
      dispatch(getMyDoctors());
      dispatch(getTooltips());
      dispatch(getProfileForInsurance());
      dispatch(getVisitpayEnrolledInfo());
      dispatch(getPaymentPlanSummary());
      setPaymentPlansLoading(false);
    };
    loadData();
  }, []);

  const renderFamilyFinanace = () => {
    if (visitpayLoading || paymentPlansLoading)
      return (
        <MuiBox display="flex" flexDirection="row" justifyContent="center" mt={1}>
          <Spinner />
        </MuiBox>
      );
    if (!visitpayEnrolled) return null;
    return (
      <Accordion
        key="Manage Family Finances"
        title="Manage Family Finances"
        analyticsEvent={AnalyticsEvent.PageViewed}
        currentUrlForAnalytics={currentUrl}
        referringUrlForAnalytics={referringUrl}
      >
        <ManageFamilyFinance />
      </Accordion>
    );
  };

  const openManageAvatars = () => {
    history.push('/u/manage-account/manage-avatars');
  };

  const closeTermsModal = () => {
    setShowTermsOnboardingModal(false);
  };

  const settingsItems = getSettingsItems(allProxiesNeedsActivation, !!grantors.length, language);

  return (
    <>
      <Banner message="Account Management" icon="Settings" />
      <ProfileContainer maxWidth="lg">
        <ProfileSectionHeader svgSet="assets" svgName="PersonalInfo" title="Personal Information" />
        <MuiBox mb={3}>
          {accountManagementMenuItems.map(item => (
            <Accordion
              key={item.title}
              title={item.title}
              isExpandedByDefault={item.key === 'MyAccount' && referringUrl === '/u/account-select'}
              analyticsEvent={AnalyticsEvent.PageViewed}
              currentUrlForAnalytics={currentUrl}
              referringUrlForAnalytics={referringUrl}
            >
              {item.content ? item.content() : null}
            </Accordion>
          ))}
          {renderFamilyFinanace()}
        </MuiBox>

        <ProfileSectionHeader svgSet="assets" svgName="Settings" title="Settings" />
        <MuiBox mb={3}>
          {settingsItems.map(item => (
            <Accordion
              key={item.title}
              title={item.title}
              analyticsEvent={AnalyticsEvent.PageViewed}
              currentUrlForAnalytics={currentUrl}
              referringUrlForAnalytics={referringUrl}
              onOpen={item.title === 'Manage Avatars' ? openManageAvatars : undefined}
              isExpandedByDefault={item.key.toLowerCase() === expandKey}
              refProp={item.key.toLowerCase() === expandKey ? scrollToDefaultExpandRef : null}
            >
              {item.content ? item.content() : null}
            </Accordion>
          ))}
        </MuiBox>

        <ProfileSectionHeader svgSet="assets" svgName="AccountShare" title="Account Sharing" />
        <MuiBox mb={3}>
          {accountSharingMenuItems.map(item => (
            <Accordion
              key={item.title}
              title={item.title}
              analyticsEvent={AnalyticsEvent.PageViewed}
              currentUrlForAnalytics={currentUrl}
              referringUrlForAnalytics={referringUrl}
            >
              {item.content ? item.content() : null}
            </Accordion>
          ))}
        </MuiBox>

        <ProfileSectionHeader svgSet="assets" svgName="Security" title="Security and Legal" />
        <MuiBox mb={3}>
          {securityAndLegalMenuItems.map(item => (
            <Accordion
              key={item.title}
              title={item.title}
              analyticsEvent={AnalyticsEvent.PageViewed}
              currentUrlForAnalytics={currentUrl}
              referringUrlForAnalytics={referringUrl}
            >
              {item.content ? item.content() : null}
            </Accordion>
          ))}
        </MuiBox>

        <TermsAndConditions
          open={showTermsOnboardingModal}
          type={TermsAndConditionsType.GENERAL}
          forceAcceptGeneralLegacyOnboarding
          acceptAction={closeTermsModal}
        />
      </ProfileContainer>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  allProxiesNeedsActivation: allGrantorsNeedsActivationSelector(state),
  currentUrl: currentLocationPathNameSelector(state),
  generalTermAcceptance: userGeneralTermsAndConditionsSelector(state),
  grantors: profileGrantorsSelector(state),
  visitpayEnrolled: profileVisitpayEnrolled(state),
  visitpayLoading: profileVisitpayLoading(state),
  referringUrl: previousLocationPathNameSelector(state),
  language: languageSelector(state)
});

export default connect(mapStateToProps)(ProfileScreen);
