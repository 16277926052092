import React, { useEffect } from 'react';
import dayjs from 'dayjs';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import analyticsService, { AmplitudeEventData, AnalyticsEvent } from 'services/AnalyticsService';

import { MuiBox, MuiContainer, MuiGrid, MuiTypography } from 'theme/material-ui';

import { Svg } from 'components/UI/Svg';

import { Color } from 'modules/styles/colors';
import { FontSize, Spacing } from 'modules/styles/variables';

import { RootState } from 'store/types';
import * as bookingTypes from 'store/booking/types';
import * as bookingActions from 'store/booking/actions';
import * as bookingSelectors from 'store/booking/selectors';
import * as routerSelectors from 'store/router/selectors';

import { BookingStepAction } from '../components/BookingStepper';
import { useCheckBookingInfoEffect } from '../useCheckBookingInfoEffect';
import { AppointmentDetailsGrid } from '../components/AppointmentDetailsGrid';
import { BookingSuccessCard, MuiBorderBox, StyledScreen } from '../components/styled';
import { redirectExternalSchedulingUrl, InsuranceDisclaimerText } from '../constants';

export interface Props extends RouteComponentProps {
  appointmentDetails: bookingTypes.AppointmentDetails;
  clearAppointment: typeof bookingActions.clearAppointment;
  clearNpv2State: typeof bookingActions.clearNpv2State;
  setIsExternalRequest: typeof bookingActions.setIsExternalRequest;
  clearCorrelationId: typeof bookingActions.clearCorrelationIDAction;
  currentRouteName?: string;
  previousRouteName?: string;
  isExternalRequest: boolean;
}

export function BookingSuccess({
  appointmentDetails,
  clearAppointment,
  clearNpv2State,
  setIsExternalRequest,
  clearCorrelationId,
  history,
  currentRouteName,
  previousRouteName,
  isExternalRequest
}: Props) {
  useCheckBookingInfoEffect(appointmentDetails?.patient);

  const handleGotItClick = () => {
    if (isExternalRequest) {
      setIsExternalRequest(false);
      window.location.replace(redirectExternalSchedulingUrl);
    } else {
      clearAppointment();
      clearNpv2State();
      history.push('/u/get-care-now');
    }
  };

  useEffect(() => {
    clearCorrelationId();
    const doctor = appointmentDetails?.doctor || null;
    let eventName = AnalyticsEvent.BookAppointmentCompleted;
    const eventData: AmplitudeEventData = {
      currentUrl: currentRouteName,
      referringUrl: previousRouteName,

      date_selected: dayjs(appointmentDetails?.appointmentDate).format('YYYY-MM-DD') || '',
      time_to_appointment: dayjs(new Date()).diff(
        dayjs(appointmentDetails?.appointmentDate),
        'day'
      ),
      type_of_appointment: appointmentDetails?.groupedVisitType?.name || '',
      provider_specialty: doctor?.specialties?.[0] || doctor?.primarySpecialty || '',
      provider_name: appointmentDetails?.doctor?.displayName || '',
      facility_name: appointmentDetails?.appointmentLocation?.locationName || '',
      type_of_location: appointmentDetails?.appointmentType || '',
      clinical_visit_name:
        appointmentDetails?.groupedVisitType?.visitTypes?.[0]?.appointmentNameInCerner || '',
      clinical_specialty_name:
        appointmentDetails?.groupedVisitType?.visitTypes?.[0]?.specialty?.nameLong || ''
    };
    if (appointmentDetails?.isEditingAppointment) {
      eventName = AnalyticsEvent.ModifyAppointmentCompleted;
      eventData.type = 'reschedule';
    }
    analyticsService.logEvent(eventName, eventData);
  }, []);

  return (
    <>
      <StyledScreen>
        <MuiContainer maxWidth="lg">
          <MuiBox my={3}>
            <MuiContainer maxWidth="md">
              <BookingSuccessCard>
                <MuiBox pb={3}>
                  <MuiGrid container xs direction="column" alignItems="center">
                    <MuiGrid item xs>
                      <Svg
                        set="assets"
                        name="CheckCircle"
                        size={100}
                        color={Color.darkGreenShade}
                      />
                    </MuiGrid>
                    <MuiGrid item xs>
                      <MuiTypography fontSize={FontSize.heading} fontWeight="bold">
                        Success
                      </MuiTypography>
                    </MuiGrid>
                    <MuiGrid item xs>
                      <MuiTypography
                        fontSize={FontSize.heading}
                        data-testid="appointment-confirmation-message"
                      >
                        Your appointment has been booked
                      </MuiTypography>
                    </MuiGrid>
                    <MuiGrid
                      style={{
                        border: `2px solid ${Color.golden}`,
                        backgroundColor: `${Color.darkOrangeShade}`,
                        marginTop: `${Spacing.xLarge}px`,
                        padding: 24,
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        borderRadius: 5
                      }}
                    >
                      <MuiGrid
                        style={{
                          marginRight: 20
                        }}
                      >
                        <Svg name="InfoIcon" color={Color.black} />
                      </MuiGrid>
                      <MuiTypography>{InsuranceDisclaimerText}</MuiTypography>
                    </MuiGrid>
                  </MuiGrid>
                </MuiBox>
                <MuiBorderBox>
                  <AppointmentDetailsGrid appointmentDetails={appointmentDetails} />
                </MuiBorderBox>
                <MuiBox display="flex" alignItems="center" justifyContent="center" padding={4}>
                  <BookingStepAction
                    data-testid="booking-success-got-it-button"
                    label="Got it"
                    onClick={() => handleGotItClick()}
                  />
                </MuiBox>
              </BookingSuccessCard>
            </MuiContainer>
          </MuiBox>
        </MuiContainer>
      </StyledScreen>
    </>
  );
}

const mapStateToProps = (state: RootState) => ({
  appointmentDetails: bookingSelectors.appointmentDetailsSelector(state),
  currentRouteName: routerSelectors.currentLocationPathNameSelector(state),
  previousRouteName: routerSelectors.previousLocationPathNameSelector(state),
  isExternalRequest: bookingSelectors.isExternalRequestSelector(state)
});

const mapDispatchToProps = {
  clearAppointment: bookingActions.clearAppointment,
  clearNpv2State: bookingActions.clearNpv2State,
  setIsExternalRequest: bookingActions.setIsExternalRequest,
  clearCorrelationId: bookingActions.clearCorrelationIDAction
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingSuccess);
