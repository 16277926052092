import React from 'react';

export default {
  svg: (
    <g fill="none" fillRule="evenodd">
      <circle fill="none" strokeWidth={2} cx="16.5" cy="16.5" r="15.5" />
      <path
        id="Path"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#95D8EC"
        d="M29.5,19c0.1-0.1,0.1-0.1,0.1-0.2c0-0.1,0.1-0.1,0.1-0.2c0-0.1,0.1-0.1,0.1-0.2c0-0.1,0-0.1,0-0.2  c0-0.1,0-0.2,0-0.2c0-0.1,0-0.1-0.1-0.2c0-0.1-0.1-0.1-0.1-0.2c0-0.1-0.1-0.1-0.1-0.2c-0.3-0.3-0.6-0.4-1-0.3c-0.1,0-0.1,0-0.2,0.1  c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.1-0.1,0.2c0,0.1-0.1,0.1-0.1,0.2c0,0.1,0,0.1-0.1,0.2  c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0.1,0.2c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0.1,0.1,0.1,0.2c0.1,0.1,0.1,0.1,0.2,0.1  c0.1,0,0.1,0.1,0.2,0.1c0.1,0,0.1,0.1,0.2,0.1c0.1,0,0.1,0,0.2,0C29,19.3,29.3,19.2,29.5,19L29.5,19z"
      />
      <path
        d="M15.8,21.6L12,16.1l-1.8,2.5H4.2c-0.6,0-1-0.5-1-1.1c0-0.6,0.5-1.1,1-1.1h4.9l2.8-3.9l3.9,5.6l1.2-1.6h7.2  c0.6,0,1,0.5,1,1.1c0,0.6-0.5,1.1-1,1.1h-6.2L15.8,21.6z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#95D8EC"
      />
    </g>
  ),
  viewBox: '0 0 33 33'
};
