import { AnalyticsEvent } from 'services/AnalyticsService';
import useNavigationAnalytics from 'hooks/useNavigationAnalytics';
import { getPaymentType, PaymentEventData } from 'lib/billing/utils';

const useOnMessageEvent = (prevScreen: string) => {
  let currentUrl = '';
  let referringUrl = '';

  if (prevScreen === '/guest-home') {
    currentUrl = 'VisitPayModal';
    referringUrl = '/guest-home';
  }

  const data = {
    currentUrl,
    referringUrl
  };
  const billPayCompleteBtn = useNavigationAnalytics(AnalyticsEvent.BillPayComplete);
  const billPayRegistrationBtn = useNavigationAnalytics(AnalyticsEvent.BillPayRegistration);

  const onMessageEvent = (event: { Event: string; EventData: PaymentEventData }) => {
    const paymentType = getPaymentType(event?.EventData);

    if (event?.Event === 'VisitPay:RegistrationComplete') {
      billPayRegistrationBtn();
    } else {
      billPayCompleteBtn({
        amountPaid: event?.EventData?.AmountPaid,
        FinancePlanMonthlyPaymentAmount: event?.EventData?.FinancePlanMonthlyPaymentAmount,
        paymentType,
        ...data
      });
    }
  };
  return [onMessageEvent];
};

export default useOnMessageEvent;
