import styled, { css } from 'styled-components';

import { Color } from 'modules/styles/colors';

import Svg from 'components/UI/Svg/Svg';
import { MuiRating } from 'theme/material-ui';

export const ProviderRating = styled(MuiRating)<{ isWaitingRoom?: boolean }>`
  && {
    & .MuiRating-iconFilled {
      color: ${Color.white};
    }
  }
  ${props =>
    props.isWaitingRoom &&
    css`
      && {
        & .MuiRating-iconFilled {
          color: ${Color.purple};
        }
      }
    `}
`;

export const LanguageIcon = styled(Svg)`
  margin-right: 5px;
`;
