import React from 'react';

export default {
  svg: (
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <defs>
        <linearGradient id="grad" x1="100%" y1="50%" x2="0%" y2="50%">
          <stop offset="0" stopColor="#3283D8" />
          <stop offset="1" stopColor="#004394" />
        </linearGradient>
      </defs>
      <g transform="translate(-136.000000, -116.000000)">
        <path
          d="M202.536392,141.5106 C201.8356,140.844047 201.819431,139.747475 202.500014,139.061623 L210.963086,130.525583
          C211.643163,129.838741 212.762309,129.823401 213.463608,130.489459 C214.1644,131.155518 214.180569,132.25209
          213.499986,132.938437 L205.036914,141.474477 C204.690813,141.824331 204.229512,142 203.768211,142 C203.324595,
          142 202.880473,141.837197 202.536392,141.5106 L202.536392,141.5106 Z M167.502998,131.932925 C166.831597,131.261181
          166.83258,130.173526 167.504472,129.502274 C168.177347,128.832003 169.266038,128.832494 169.93793,129.504238
          L180.497002,140.067014 C181.168403,140.738758 181.16742,141.826413 180.495528,142.497665 C180.159336,142.832555
          179.719436,143 179.279536,143 C178.839145,143 178.398262,142.832064 178.062562,142.495701 L167.502998,131.932925
          Z M189,139.25077 L189,117.74923 C189,116.783155 189.895429,116 191,116 C192.104,116 193,116.783155 193,117.74923
          L193,139.25077 C193,140.216845 192.104,141 191,141 C189.895429,141 189,140.216845 189,139.25077 L189,139.25077 Z
          M237.864837,179.094668 C238.238665,179.998936 237.810352,181.03535 236.909329,181.410018 L182.562409,204
          L137.186872,188.184937 C136.26567,187.863937 135.77833,186.854357 136.098178,185.929331 C136.418026,185.005317
          137.421461,184.51521 138.345691,184.837223 L182.452429,200.209772 L235.558299,178.135719 C236.457304,177.760544
          237.492017,178.189894 237.864837,179.094668 L237.864837,179.094668 Z M237.864837,173.094668 C238.238665,173.998936
          237.810352,175.03535 236.909329,175.410018 L182.562409,198 L137.186872,182.184937 C136.26567,181.863937 135.77833,
          180.854357 136.098178,179.929331 C136.418026,179.004811 137.421461,178.514704 138.345691,178.837223 L182.452429,
          194.209772 L235.558299,172.135719 C236.457304,171.760544 237.492017,172.189894 237.864837,173.094668 L237.864837,
          173.094668 Z M136.098178,172.929331 C136.418026,172.005317 137.421461,171.514704 138.345691,171.837223 L182.452429,
          187.209772 L235.558299,165.135719 C236.457304,164.760544 237.492017,165.189894 237.864837,166.094668 C238.238665,
          166.998936 237.810352,168.03535 236.909329,168.410018 L182.562409,191 L137.186872,175.184937 C136.26567,174.863937
          135.77833,173.854357 136.098178,172.929331 L136.098178,172.929331 Z M194.799259,155.384378 C193.340987,156.053448
          191.129785,156.199917 189.860802,155.711297 C188.591291,155.222677 188.743569,154.284691 190.201313,153.615622
          C191.659585,152.946552 193.870259,152.800083 195.13977,153.288703 C196.408752,153.777323 196.255946,154.715309
          194.799259,155.384378 L194.799259,155.384378 Z M217.798725,163.384801 C216.341071,164.053825 214.130004,164.199698
          212.860571,163.711111 C211.591137,163.223111 211.743935,162.284603 213.20106,161.615579 C214.659243,160.946556
          216.87031,160.800097 218.139215,161.288683 C219.409177,161.77727 219.255851,162.715192 217.798725,163.384801
          L217.798725,163.384801 Z M184.517083,178 L178,175.889747 L185.483428,173 L192,175.110253 L184.517083,178 Z M190,
          170.889747 L197.483428,168 L204,170.110253 L196.516572,173 L190,170.889747 Z M181,171.110253 L173.517321,174 L167,
          171.889747 L174.483701,169 L181,171.110253 Z M179,166.889747 L186.482679,164 L193,166.110253 L185.51681,169 L179,
          166.889747 Z M170,167.110253 L162.517048,170 L156,167.889747 L163.482952,165 L170,167.110253 Z M174.516572,165
          L168,162.890022 L175.482917,160 L182,162.110455 L174.516572,165 Z M175.670872,157 L211,169.409244 L183.328627,181
          L148,168.591264 L175.670872,157 Z M191.125594,151 L227,162.970874 L216.874406,167 L181,155.029126 L191.125594,151
          Z M236,162.624201 L191.471114,147 L138,169.375799 L182.528886,185 L236,162.624201 Z"
          fill="url(#grad)"
        />
      </g>
    </g>
  ),
  viewBox: '0 0 102 88'
};
