import React from 'react';
import styled, { css } from 'styled-components';
import { MuiButton, ButtonProps } from 'theme/material-ui';
import { Color } from 'modules/styles/colors';

export interface ConnectCareButtonProps extends ButtonProps {
  loading?: boolean;
  download?: boolean;
  height?: number;
  paddingX?: number;
  paddingY?: number;
  width?: number;
  minWidth?: number;
  backgroundColor?: string;
}

const height = (props: ConnectCareButtonProps) =>
  props.height &&
  css`
    &.MuiButton-root {
      height: ${props.height}px !important;
    }
  `;

const width = (props: ConnectCareButtonProps) =>
  props.width &&
  css`
    &.MuiButton-root {
      width: ${props.width}px !important;
    }
  `;

const minWidth = (props: ConnectCareButtonProps) =>
  props.minWidth &&
  css`
    &.MuiButton-root {
      min-width: ${props.minWidth}px !important;
    }
  `;

const paddingX = (props: ConnectCareButtonProps) =>
  props.paddingX &&
  css`
    &.MuiButton-root {
      padding-left: ${props.paddingX}px !important;
      padding-right: ${props.paddingX}px !important;
    }
  `;

const paddingY = (props: ConnectCareButtonProps) =>
  props.paddingY &&
  css`
    &.MuiButton-root {
      padding-top: ${props.paddingY}px !important;
      padding-bottom: ${props.paddingY}px !important;
    }
  `;

const href = (props: ConnectCareButtonProps) =>
  props.href &&
  css`
    &.MuiButton-containedPrimary:hover,
    &.MuiButton-containedPrimary:focus,
    &.MuiButton-containedPrimary:active {
      color: ${Color.white};
    }
  `;

const backgroundColor = (props: ConnectCareButtonProps) =>
  props.backgroundColor &&
  css`
    &.MuiButton-root {
      background-color: ${props.backgroundColor} !important;
    }
  `;

const Button = styled(MuiButton)<ConnectCareButtonProps>`
  ${height}
  ${href}
  ${paddingX}
  ${paddingY}
  ${width}
  ${minWidth}
  ${backgroundColor}
`;

function ConnectCareButton(props: ConnectCareButtonProps) {
  return <Button {...props} />;
}

ConnectCareButton.defaultProps = {
  variant: 'contained',
  color: 'primary'
};

export default ConnectCareButton;
