import React from 'react';

import { formatDate } from 'modules/utils/DateUtils';

import LabelGroup from 'components/UI/Labels/LabelGroup';
import {
  DetailsWrapper,
  DetailsContainerColumn,
  PageDivider,
  StyledProviderEducation,
  DetailsSectionWrapper
} from './styled';
import SmallLabel from 'components/UI/Labels/SmallLabel';
import { ProviderDetail, Certification, EducationDisplay } from 'store/findProvider/types';
import { getProviderDegreeText } from 'store/findProvider/utils';
import { MuiGrid, MuiDivider, MuiBox, MuiTypography } from 'theme/material-ui';
import { StyledDefaultText, SectionHeader } from '../common';
import { FontSize, FontWeight } from 'modules/styles/variables';

const ProviderEducation = ({ educations, title }: EducationDisplay) => (
  <MuiBox item sm={12} md={6}>
    <MuiTypography variant="subtitle2" fontWeight={FontWeight.bold}>
      {title}
    </MuiTypography>
    {educations.length <= 0 ? (
      <StyledProviderEducation>
        <StyledDefaultText>No Information Available</StyledDefaultText>
      </StyledProviderEducation>
    ) : (
      educations.map(item => {
        const degreeText = getProviderDegreeText(item);
        return (
          <StyledProviderEducation key={item.programId}>
            <StyledDefaultText fontWeight={FontWeight.regularbold}>{degreeText}</StyledDefaultText>
          </StyledProviderEducation>
        );
      })
    )}
  </MuiBox>
);

interface CertificateDisplay {
  title: string;
  certifications: Certification[];
}

const displayCertTime = (cert: Certification) => {
  if (cert.isLifetimeCertification) {
    return ' Life Time Certification';
  }
  if (cert.reverifyDate) {
    return ` Re-verification: ${formatDate(cert.reverifyDate)}`;
  }
  if (cert.expireDate) {
    return ` Expiration: ${formatDate(cert.expireDate)}`;
  }
  return ' Expiration: No Information';
};

const ProviderCertification = ({ certifications, title }: CertificateDisplay) => (
  <MuiGrid item sm={12} md={6}>
    <MuiTypography variant="subtitle2">{title}</MuiTypography>
    {certifications.length <= 0 ? (
      <LabelGroup>
        <StyledDefaultText>No Information Available</StyledDefaultText>
      </LabelGroup>
    ) : (
      certifications.map(item => (
        <LabelGroup key={item.boardId}>
          <StyledDefaultText>{item.boardDescription}</StyledDefaultText>
          <SmallLabel>{displayCertTime(item)}</SmallLabel>
        </LabelGroup>
      ))
    )}
  </MuiGrid>
);

export const ProviderProfessions = ({ provider }: { provider: ProviderDetail }) => {
  const {
    certifications = [],
    chiefResidencies = [],
    degrees = [],
    fellowships = [],
    residencies = []
  } = provider;

  const hasDetailsToShow =
    provider.degrees.length > 0 ||
    provider.fellowships.length > 0 ||
    provider.certifications.length ||
    provider.residencies.length > 0 ||
    provider.chiefResidencies.length > 0;

  const hasDetailsFirstColumn =
    degrees.length > 0 || fellowships.length > 0 || certifications.length > 0;

  return (
    <DetailsSectionWrapper>
      {hasDetailsToShow ? (
        <PageDivider>
          <SectionHeader variant="h5" fontSize={FontSize.heading}>
            Professional Summary
          </SectionHeader>
          <MuiDivider />
          <DetailsWrapper>
            {hasDetailsFirstColumn ? (
              <MuiGrid item xs={9}>
                <MuiBox mt={3}>
                  <MuiGrid container>
                    <DetailsContainerColumn>
                      {degrees.length > 0 ? (
                        <ProviderEducation title="Degree(s)" educations={degrees} />
                      ) : null}
                      {fellowships.length > 0 ? (
                        <ProviderEducation title="Fellowship(s)" educations={fellowships} />
                      ) : null}

                      {certifications.length > 0 ? (
                        <ProviderCertification
                          title="Board Certifications"
                          certifications={certifications}
                        />
                      ) : null}
                    </DetailsContainerColumn>
                  </MuiGrid>
                </MuiBox>
              </MuiGrid>
            ) : null}
            <MuiGrid item xs={9}>
              <MuiBox mt={3}>
                <MuiGrid container>
                  <DetailsContainerColumn>
                    {residencies.length > 0 ? (
                      <ProviderEducation title="Residency" educations={residencies} />
                    ) : null}
                    {chiefResidencies.length > 0 ? (
                      <ProviderEducation title="Chief Residency" educations={chiefResidencies} />
                    ) : null}
                  </DetailsContainerColumn>
                </MuiGrid>
              </MuiBox>
            </MuiGrid>
          </DetailsWrapper>
        </PageDivider>
      ) : null}
    </DetailsSectionWrapper>
  );
};
