import React from 'react';
import { ConnectCareCheckboxItem } from 'components/ConnectCare';
import { MuiTypography } from 'theme/material-ui';
import { Color } from 'modules/styles/colors';

export interface EVisitCheckboxItemProps {
  label: string;
  linkText?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  onClick?: () => void;
  testID?: string;
}

const EVisitCheckboxItem = ({
  label,
  linkText,
  onChange,
  onClick,
  testID,
  ...rest
}: EVisitCheckboxItemProps) => {
  return (
    <>
      <ConnectCareCheckboxItem
        data-testid={`${testID}-checkbox`}
        label={<MuiTypography>{label}</MuiTypography>}
        onChange={onChange}
        {...rest}
      />
      <MuiTypography
        data-testid={`${testID}-link`}
        color={Color.link}
        onClick={onClick}
        style={{ marginTop: '-6px', marginLeft: '28px', cursor: 'pointer' }}
      >
        {linkText}
      </MuiTypography>
    </>
  );
};

export default EVisitCheckboxItem;
