import dayjs from 'dayjs';
import { oc } from 'ts-optchain';
import { JsonObj } from '@mhp/general-interface';
import formatMoney from 'services/formatMoney';
import { formatDate } from 'modules/utils/DateUtils';
import {
  EncounterListItem,
  PaymentListItem,
  PaymentPlanListItem,
  CheckPaymentPlansEligiblity
} from 'store/billing/types';

import { TableData } from './types';

const NA = 'N/A';

export function mapPaymentPlanData(paymentPlans: PaymentPlanListItem[]): TableData[] {
  return paymentPlans.map(
    (each: PaymentPlanListItem): TableData => ({
      id: each.paymentPlanId,
      left: each.paymentDueDate ? formatDate(new Date(each.paymentDueDate)) : NA,
      middle: 'Payment Plan',
      right: formatMoneyOrDash(each.monthlyBalanceDue)
    })
  );
}

export function mapPaymentsToData(payments: PaymentListItem[]): TableData[] {
  return payments.map(
    (each: PaymentListItem): TableData => ({
      left: formatDate(new Date(each.date)),
      middle: each.description,
      right: formatMoneyOrDash(each.amount),
      id: each.receiptNumber
    })
  );
}

export function mapCollectionsToTableData(collections: EncounterListItem[]): TableData[] {
  return collections.map(
    (each: EncounterListItem): TableData => ({
      left: each.dischargeDate ? formatDate(new Date(each.dischargeDate)) : NA,
      middle: `${each.patient.firstName} ${each.patient.lastName}`,
      right: each.paymentPlan ? DASH : formatMoneyOrDash(each.balance),
      id: each.id
    })
  );
}

const sortBillingDataByNameAndBalance = (a: EncounterListItem, b: EncounterListItem) => {
  const aName = `${a.patient.firstName} ${a.patient.lastName}`;
  const bName = `${b.patient.firstName} ${b.patient.lastName}`;

  const nameCompare = aName.localeCompare(bName);

  if (nameCompare !== 0) {
    return nameCompare;
  }

  if (a.paymentPlan && b.paymentPlan) {
    return 0;
  }

  if (a.paymentPlan) return 1;

  if (b.paymentPlan) return -1;

  return b.balance - a.balance;
};

const sortBillingData = (a: EncounterListItem, b: EncounterListItem) => {
  let dateCompare: number | undefined;
  if (a.dischargeDate && b.dischargeDate) {
    dateCompare =
      new Date(dayjs(b.dischargeDate).format('YYYY-MM-DD')).getTime() -
      new Date(dayjs(a.dischargeDate).format('YYYY-MM-DD')).getTime();
  }

  if (dateCompare === 0 || (!a.dischargeDate && !b.dischargeDate)) {
    return sortBillingDataByNameAndBalance(a, b);
  }

  if (!a.dischargeDate) return 1;
  if (!b.dischargeDate) return -1;

  return dateCompare || 0;
};

export function sortEncountersByDate(a: Encounter, b: Encounter) {
  return new Date(b.dischargeDate).getTime() - new Date(a.dischargeDate).getTime();
}

export function mapEncountersToData(
  encounters: EncounterListItem[],
  paidEncounters: EncounterListItem[]
): TableData[] {
  const billingData = [...encounters];

  if (billingData && billingData.length < 3) {
    billingData.push(...[...paidEncounters].sort(sortBillingData).slice(0, 3 - billingData.length));
  }

  if (billingData && billingData.length > 3) {
    billingData.splice(3, billingData.length - 3);
  }

  return mapCollectionsToTableData(billingData.sort(sortBillingData));
}

export function generateTotalPaymentPlans(paymentPlans: PaymentPlanListItem[]): number {
  if (!paymentPlans.length) return 0;

  return paymentPlans
    .map((v: PaymentPlanListItem) => parseFloat(String(v.totalBalance)))
    .reduce((a: number, b: number) => a + b);
}

export function generateSpentYearDate(plan: JsonObj, currentPlan: string): string | null {
  if (currentPlan === 'SelectHealth Med')
    return formatMoney(plan?.deductible - plan?.remainder?.deductible);

  return formatMoney(plan?.max_oop - plan?.remainder?.max_oop);
}

export function generateDeductible(
  plan: JsonObj,
  currentPlan: string,
  hsaData?: JsonObj
): string | null {
  if (currentPlan === 'Health Saving Account') {
    return formatMoney(oc(hsaData).availableBalance(0.0));
  }

  return formatMoney(currentPlan === 'SelectHealth Med' ? plan?.deductible : plan?.max_oop);
}

export function generatePaymentPlanText(
  isVPeligible: boolean,
  checkPaymentPlansEligiblity?: CheckPaymentPlansEligiblity
) {
  if (!isVPeligible) return 'You may be eligible for a Payment Plan';

  if (checkPaymentPlansEligiblity && !checkPaymentPlansEligiblity.eligibleForPaymentPlans)
    return 'You are not eligible for a Payment Plan';

  return '';
}

const DASH = 'Payment Plan';

export function formatMoneyOrDash(money: number): string {
  const res = formatMoney(money);

  return res ? `$${res}` : DASH;
}

export const iff = (condition: boolean, then: string, otherwise: string) =>
  condition ? then : otherwise;
