import InputAdornment from '@material-ui/core/InputAdornment';
import CloseIcon from '@material-ui/icons/Close';
import PhoneOutlinedIcon from '@material-ui/icons/PhoneOutlined';
import warning from 'assets/warning.png';
import Box from 'components/UI/Layout/Box';
import ScrollableLayout from 'components/UI/Layout/ScrollableLayout';
import { ListItem } from 'components/UI/ListItem/ListItem';
import Spinner from 'components/UI/Spinner/Spinner';
import { Formik, FormikValues } from 'formik';
import useNavigationAnalytics from 'hooks/useNavigationAnalytics';
import values from 'lodash/values';
import { Color } from 'modules/styles/colors';
import { FontSize, FontWeight, Spacing } from 'modules/styles/variables';
import { isUSPhoneNumber } from 'modules/utils/PhoneUtils';
import React, { Dispatch, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import analyticsService, {
  AmplitudeEventData,
  AnalyticsEvent,
  NotificationsEventNames
} from 'services/AnalyticsService';
import { AnyAction } from 'shared/node_modules/redux';
// eslint-disable-next-line import/extensions
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import HiddenLabelControl from 'components/UI/Labels/HiddenLabelControl';
import { Typography } from 'components/UI/Typography/styled';
import useCoreEventData from 'hooks/useCoreEventData';
import * as notificationActions from 'store/notifications/actions.web.ts';
import * as notificationSelectors from 'store/notifications/selectors';
import * as types from 'store/notifications/types';
import { savePrimaryPhone } from 'store/profile/actions';
import * as profileSelectors from 'store/profile/selectors';
import { RootState } from 'store/types';
import {
  MuiBox,
  MuiButton,
  MuiDialog,
  MuiGrid,
  MuiTextField,
  MuiTypography
} from 'theme/material-ui';
import HIPAAAgreement from './HipaaAgreement';
import IOSSwitch from './IOSSwitch';
import ShortCodeAuthorization from './ShortcodeAuthorization';
import TCPAAuthorization from './TcpaAuthorization';
import { hipaaType, toggleType } from './constants';
import { formatDisplayName } from './helpers';
import { BoxWrapper, Error, NotifyBoxWrapper } from './styled';

interface NotificationsPreferencesProps {
  channels: { [key: string]: types.NotificationItem };
  categories: { [key: string]: types.NotificationItem };
  proxies: { [key: string]: types.NotificationProxy };
  isFetching: boolean;
  hasError: boolean;
  consumerID: string;
  cernerID: string;
  patientID: string;
  agreementObject: types.NotificationsHipaaAgreementObjectState;
  savePrimaryPhone: typeof savePrimaryPhone;
  consumer: any;

  getAgreement: (
    type: string
  ) => (dispatch: Dispatch<AnyAction>, getState: () => RootState) => void;

  getProxies: (
    consumerId: string
  ) => {
    type: string;
    payload: {
      status: number;
      client: string;
      request: {
        method: string;
        url: string;
      };
    };
  };

  getPreferences: () => {
    type: string;
    payload: {
      client: string;
      request: {
        method: string;
        url: string;
      };
    };
  };

  togglePreference: (
    item: types.NotificationItem,
    type: string
  ) => (dispatch: Dispatch<AnyAction>, getState: () => RootState) => Promise<void>;

  toggleProxy: (patientID: string) => {};

  setPatient: (
    patient: types.NotificationProxy
  ) => {
    type: string;
    payload: types.NotificationProxy;
  };

  signHIPAAAgreement: (
    type: string,
    patientID: string,
    data: types.NotificationsHipaaAgreementObjectState
  ) => {
    error: string;
    type: string;
    payload: {
      client: string;
      request: {
        method: string;
        url: string;
        data: {
          errorMessage: string;
        };
      };
    };
  };

  toggleChannelsOff: () => (dispatch: Dispatch<AnyAction>, getState: () => RootState) => void;
}

export function NotificationPreferencesContent(props: NotificationsPreferencesProps) {
  const {
    getAgreement,
    consumerID,
    consumer,
    channels,
    categories,
    proxies,
    isFetching,
    hasError,
    togglePreference,
    setPatient,
    cernerID,
    patientID,
    getProxies,
    signHIPAAAgreement,
    agreementObject,
    toggleChannelsOff
  } = props;
  const [proxiesArr, setProxiesArr] = useState<types.NotificationProxy[]>([]);
  const [openTCPA, setTCPAOpen] = useState(false);
  const [openHIPAA, setHIPAAOpen] = useState(false);
  const [openShortCode, setShortCodeOpen] = useState(false);
  const [isSigningHIPAA, setIsSigningHIPAA] = useState(false);
  const [notificationType, setNotificationType] = useState('');
  const [showPhoneDialog, setShowPhoneDialog] = useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const coreEventData = useCoreEventData();
  const [selfProxy, setSelfProxy] = useState<types.NotificationProxy>({
    patientId: '',
    displayName: '',
    eligible: false,
    authorized: false
  });
  const [agreementType, setAgreementType] = useState(hipaaType.authorization);
  const [notificationItem, setNotificationItem] = useState({
    key: 'EMAIL',
    enabled: false,
    display: 'Email Notification'
  });

  const analyticsOnEmailToggle = useNavigationAnalytics(AnalyticsEvent.EMAILNotificationsToggled);
  const analyticsOnSMSToggle = useNavigationAnalytics(AnalyticsEvent.SMSNotificationsToggled);

  const channelsArr = values(channels).filter(chan => chan.key !== 'FCM');
  const categoriesArr = values(categories);
  const hasChannelsEnabled = channelsArr.find(c => c.enabled);

  useEffect(() => {
    const arr = values(proxies).filter(p => p.patientId !== cernerID);
    setProxiesArr(arr);
    setSelfProxy(proxies[cernerID]);
    if (proxies[patientID] && proxies[patientID].authorized) {
      setAgreementType(hipaaType.revocation);
    }
  }, [proxies]);

  const toggleProxy = (patient: types.NotificationProxy) => {
    setPatient(patient);
    setAgreementType(patient.authorized ? hipaaType.revocation : hipaaType.authorization);
    getAgreement(patient.authorized ? hipaaType.revocation : hipaaType.authorization);
  };

  if (isFetching && (!proxiesArr || (!channelsArr.length && !categoriesArr.length))) {
    return (
      <Box vSpacingTop={50}>
        <Spinner />
      </Box>
    );
  }

  if (hasError) {
    return (
      <Box vSpacingTop={50} hSpacing={40}>
        <Error>We are having trouble fetching your notifications preferences.</Error>
      </Box>
    );
  }

  const handleTCPAOpen = () => setTCPAOpen(true);
  const handleTCPAClose = () => setTCPAOpen(false);

  const handleHIPAAOpen = () => setHIPAAOpen(true);
  const handleHIPAAClose = () => setHIPAAOpen(false);

  const handleShortCodeOpen = () => setShortCodeOpen(true);
  const handleShortCodeClose = () => setShortCodeOpen(false);

  const handleValidate = (values: FormikValues) => {
    return !isUSPhoneNumber(values.phone) ? { phone: 'Please enter a valid phone' } : {};
  };

  const handlePhoneSubmit = async (values: FormikValues) => {
    await props.savePrimaryPhone(values.phone, 'MOBILE');
    togglePreference(notificationItem, toggleType.channels);
    setShowPhoneDialog(false);
  };

  const handleNotificationAnalytics = (item: types.NotificationItem) => {
    const eventProps = {
      action: item.enabled ? 'deactivate' : 'activate'
    };
    if (item.key === 'EMAIL') {
      analyticsOnEmailToggle(eventProps);
    }
    if (item.key === 'SMS') {
      analyticsOnSMSToggle(eventProps);
    }
  };

  const signHIPAA = async () => {
    setIsSigningHIPAA(true);
    const isRevokingGrantor = patientID === cernerID && proxies[patientID].authorized;

    const signed = await signHIPAAAgreement(agreementType, patientID, agreementObject);
    if (signed.error) {
      return;
    }
    const eventType =
      agreementType === hipaaType.revocation ? 'HIPAARevocation' : 'HIPAAAuthorization';

    analyticsService.logEvent(AnalyticsEvent[eventType], {
      referringUrl: 'Notifications',
      currentUrl: 'NOTIFICATIONS_HIPAA'
    });

    const proxyResponse = await getProxies(consumerID);

    if (proxyResponse.payload.status !== 200) {
      return;
    }
    if (isRevokingGrantor) {
      toggleChannelsOff();
    }

    if (notificationType === 'EMAIL') {
      togglePreference(notificationItem, toggleType.channels);
      return;
    }

    if (notificationType === 'SMS' && !isRevokingGrantor) {
      handleTCPAOpen();
    }
  };

  const onSwitchToggled = (item: any) => {
    const eventName = NotificationsEventNames[item.key];
    analyticsService.logEvent(
      eventName as AnalyticsEvent,
      {
        ...coreEventData,
        action: item.enabled ? 'deactivate' : 'activate'
      } as AmplitudeEventData
    );
    togglePreference(item, toggleType.categories);
  };

  return (
    <>
      <HIPAAAgreement
        open={openHIPAA}
        handleClose={handleHIPAAClose}
        showTCPAModal={handleTCPAOpen}
        isSigning={isSigningHIPAA}
        handleSigning={signHIPAA}
      />
      <TCPAAuthorization
        open={openTCPA}
        handleClose={handleTCPAClose}
        handleAccept={() => {
          handleTCPAClose();
          handleShortCodeOpen();
        }}
      />
      <ShortCodeAuthorization
        consumerID={consumerID}
        open={openShortCode}
        handleClose={handleShortCodeClose}
        handleUpdate={() => {
          togglePreference(notificationItem, toggleType.channels);
          handleShortCodeClose();
        }}
      />
      <MuiDialog open={showPhoneDialog} maxWidth="sm">
        <MuiGrid container direction="column" alignItems="center">
          <MuiBox mt={2} ml={45}>
            <CloseIcon fontSize="large" onClick={() => setShowPhoneDialog(false)} />
          </MuiBox>
          <img alt="warning" src={warning} />
          <MuiTypography gutterBottom fontSize={FontSize.heading} fontWeight="semibold">
            Almost there
          </MuiTypography>
          <MuiTypography fontSize={FontSize.heading} align="center">
            Please provide your mobile number so you can receive text notifications.
          </MuiTypography>
          <MuiBox width="100%">
            <Formik
              initialValues={{ phone: '' }}
              validate={handleValidate}
              onSubmit={handlePhoneSubmit}
            >
              {({ touched, errors, values, handleChange, handleBlur, handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <MuiBox mt={4} mb={3} px={8}>
                    <MuiTypography color={Color.textLight} gutterBottom>
                      Mobile Phone
                    </MuiTypography>
                    <MuiTextField
                      type="phone"
                      name="phone"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start" variant="outlined">
                            <PhoneOutlinedIcon />
                          </InputAdornment>
                        )
                      }}
                      fullWidth
                      placeholder="(___) ___ - ____"
                      variant="outlined"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.phone}
                      error={Boolean(touched.phone && errors.phone)}
                      helperText={touched.phone && errors.phone ? errors.phone : null}
                    />
                  </MuiBox>
                  <MuiBox px={11}>
                    <MuiButton fullWidth variant="contained" color="primary" type="submit">
                      Submit
                    </MuiButton>
                  </MuiBox>
                  <MuiBox mb={2} px={11}>
                    <MuiButton
                      fullWidth
                      onClick={() => setShowPhoneDialog(false)}
                      variant="outlined"
                    >
                      Cancel
                    </MuiButton>
                  </MuiBox>
                </form>
              )}
            </Formik>
          </MuiBox>
        </MuiGrid>
      </MuiDialog>

      <BoxWrapper
        width="100%"
        backgroundColor={Color.white}
        hSpacing={isSmallScreen ? Spacing.medium : Spacing.xLarge}
      >
        <Box
          width={isSmallScreen ? '100%' : '50%'}
          vSpacingTop={Spacing.large}
          vSpacingBottom={Spacing.small}
        >
          {channelsArr.map((item: types.NotificationItem) => (
            <ListItem
              key={item.key}
              title={item.display}
              titleProps={{ fontSize: FontSize.base }}
              body={item.description}
              hasBottomBorder
              action={
                <HiddenLabelControl forAtt={item.key} label={item.key}>
                  <IOSSwitch
                    data-testid="toggle-notification-check"
                    checked={item.enabled}
                    id={item.key}
                    onChange={event => {
                      setNotificationType(item.key);
                      setNotificationItem(item);
                      if (!selfProxy) {
                        return;
                      }
                      if (selfProxy.authorized) {
                        handleNotificationAnalytics(item);
                        if (item.key === 'EMAIL' || !event.target.checked) {
                          togglePreference(item, toggleType.channels);
                          return;
                        }
                        if (item.key === 'SMS' && event.target.checked) {
                          if (
                            consumer &&
                            consumer.phones &&
                            consumer.phones.length > 0 &&
                            consumer.phones[0].value
                          ) {
                            handleTCPAOpen();
                          } else {
                            setShowPhoneDialog(true);
                          }
                        }
                      } else {
                        toggleProxy(selfProxy);
                        handleHIPAAOpen();
                      }
                    }}
                  />
                </HiddenLabelControl>
              }
            />
          ))}
        </Box>

        {hasChannelsEnabled && (
          <>
            <Box vSpacingTop={Spacing.xLarge} vSpacingBottom={Spacing.small}>
              <Typography fontSize={FontSize.heading}>Receive notifications about:</Typography>
              <Typography>
                E-signatures are required to turn on notifications for you and your dependent
                minors. You cannot modify permissions for legal adults.
              </Typography>
            </Box>
            <Box width={isSmallScreen ? '100%' : '50%'} vSpacing={Spacing.large}>
              {selfProxy && (
                <ListItem
                  title={`${formatDisplayName(selfProxy.displayName)} (You)`}
                  titleProps={{ fontSize: FontSize.base }}
                  disabled={!selfProxy.eligible}
                  hasBottomBorder
                  action={
                    <HiddenLabelControl
                      forAtt={selfProxy.displayName}
                      label={selfProxy.displayName}
                    >
                      <IOSSwitch
                        checked={selfProxy.authorized}
                        disabled={!selfProxy.eligible}
                        id={selfProxy.displayName}
                        onChange={event => {
                          event.preventDefault();
                          toggleProxy(selfProxy);
                          handleHIPAAOpen();
                        }}
                      />
                    </HiddenLabelControl>
                  }
                />
              )}

              {proxiesArr &&
                proxiesArr.map((item: types.NotificationProxy) => (
                  <ListItem
                    key={item.patientId}
                    title={formatDisplayName(item.displayName)}
                    titleProps={{
                      fontSize: FontSize.base,
                      fontWeight: FontWeight.normal
                    }}
                    disabled={!item.eligible}
                    hasBottomBorder
                    action={
                      <HiddenLabelControl forAtt={item.displayName} label={item.displayName}>
                        <IOSSwitch
                          checked={item.authorized}
                          disabled={!item.eligible}
                          id={item.displayName}
                          onChange={event => {
                            event.preventDefault();
                            toggleProxy(item);
                            handleHIPAAOpen();
                          }}
                        />
                      </HiddenLabelControl>
                    }
                  />
                ))}
            </Box>
            <Box vSpacingBottom={Spacing.small}>
              <Typography fontSize={FontSize.heading}>Notify me when:</Typography>
            </Box>
            <NotifyBoxWrapper
              pb="1"
              display="flex"
              flexDirection={isSmallScreen ? 'column' : 'row'}
            >
              <MuiBox
                width={isSmallScreen ? '100%' : '50%'}
                display="flex"
                flexDirection="column"
                pr={isSmallScreen ? 0 : 2}
                fontSize={FontSize.base}
              >
                {categoriesArr.map((item: types, index) => {
                  if (index > 3) {
                    return null;
                  }
                  return (
                    <ListItem
                      key={item.key}
                      title={item.display}
                      titleProps={{
                        fontSize: FontSize.base,
                        fontWeight: FontWeight.normal
                      }}
                      body={item.description}
                      hasBottomBorder
                      action={
                        <HiddenLabelControl forAtt={item.display} label={item.display}>
                          <IOSSwitch
                            checked={item.enabled}
                            id={item.display}
                            onChange={() => onSwitchToggled(item)}
                          />
                        </HiddenLabelControl>
                      }
                    />
                  );
                })}
              </MuiBox>
              <MuiBox
                width={isSmallScreen ? '100%' : '50%'}
                display="flex"
                flexDirection="column"
                pl={isSmallScreen ? 0 : 2}
              >
                {categoriesArr.map((item: types.NotificationItem, index) => {
                  if (index < 4) {
                    return null;
                  }
                  return (
                    <ListItem
                      key={item.key}
                      title={item.display}
                      titleProps={{
                        fontSize: FontSize.base,
                        fontWeight: FontWeight.normal
                      }}
                      body={item.description}
                      hasBottomBorder
                      action={
                        <HiddenLabelControl forAtt={item.display} label={item.display}>
                          <IOSSwitch
                            checked={item.enabled}
                            id={item.display}
                            onChange={() => onSwitchToggled(item)}
                          />
                        </HiddenLabelControl>
                      }
                    />
                  );
                })}
              </MuiBox>
            </NotifyBoxWrapper>
          </>
        )}
      </BoxWrapper>
    </>
  );
}

export const NotificationsPreferencesComponent = (props: NotificationsPreferencesProps) => {
  const { consumerID, getProxies, getPreferences } = props;

  const fetch = () => {
    getProxies(consumerID);
    getPreferences();
  };

  useEffect(() => fetch(), []);

  return (
    <ScrollableLayout>
      <NotificationPreferencesContent {...props} />
    </ScrollableLayout>
  );
};

const mapStateToProps = (state: RootState) => ({
  consumer: profileSelectors.profileConsumerSelector(state),
  consumerID: profileSelectors.consumerIdSelector(state),
  channels: notificationSelectors.notificationsChannelsSelector(state),
  categories: notificationSelectors.notificationsCategoriesSelector(state),
  proxies: notificationSelectors.notificationsProxiesDataSelector(state),
  cernerID: profileSelectors.cernerIdSelector(state),
  patientID: notificationSelectors.notificationsHipaaPatientIdSelector(state),
  agreementObject: notificationSelectors.notificationsHipaaAgreementObjectSelector(state),
  isFetching:
    notificationSelectors.notificationsPreferencesFetchingSelector(state) ||
    notificationSelectors.notificationsProxiesFetchingSelector(state),
  hasError:
    !!notificationSelectors.notificationsProxiesErrorSelector(state) ||
    !!notificationSelectors.notificationsPreferencesErrorSelector(state)
});

const mapDispatchToProps = {
  getAgreement: notificationActions.getHipaaAgreement,
  getPreferences: notificationActions.getNotificationsPreferences,
  getProxies: notificationActions.getNotificationsProxies,
  togglePreference: notificationActions.toggleNotificationsPreference,
  toggleChannelsOff: notificationActions.toggleNotificationsChannelsOff,
  signHIPAAAgreement: notificationActions.signHipaaAgreement,
  setPatient: notificationActions.setHipaaPatient,
  savePrimaryPhone
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsPreferencesComponent);
