import styled from 'styled-components';
import { MuiListItem, MuiTypography } from 'theme/material-ui';
import { Color } from 'modules/styles/colors';
import { FontSize, Spacing } from 'modules/styles/variables';

export const StyledMuiTypography = styled(MuiTypography)`
  &.MuiTypography-root {
    color: ${Color.alertError};
    font-weight: bold;
    text-align: center;
  }
`;

export const StyledSymbolMuiTypography = styled(MuiTypography)`
  &.MuiTypography-root {
    margin-left: ${Spacing.medium}px;
    font-size: ${FontSize.small}px;
    font-weight: bold;
  }
`;

export const StyledMuiBoxContainer = styled(MuiListItem)`
  height: 4em;

  &:not(:last-child) {
    border-bottom: 1px solid ${Color.grayLight};
  }
`;
