import React from 'react';
import { connect } from 'react-redux';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';

import { RootState } from 'store/types';
import { faBillsSelector } from 'store/billing/financialAssistance/createApplication/selectors';

import { BILLS_INFO } from '../constants';
import { Section } from './types';
import { convertToLowerKabobCase } from 'modules/utils/StringUtils';
import { MuiButton } from 'theme/material-ui';

const BillsInfo = ({ navigateToEdit, formData }: Section) => {
  return (
    <Box>
      <Box position="absolute" top="-10px" right="-7px" zIndex={1}>
        <MuiButton color="primary" onClick={navigateToEdit} startIcon={<EditIcon />}>
          Edit
        </MuiButton>
      </Box>
      <Box>
        <Typography variant="h6">Bills</Typography>
      </Box>
      {Array.isArray(formData.expenses) &&
        formData.expenses.map(bill => {
          return BILLS_INFO.map(item => {
            const fieldValue = bill[item.field];
            if (fieldValue) {
              return (
                <Box py={1} key={`${item.field}${fieldValue}`}>
                  <Box>
                    <Typography>{item.label}</Typography>
                    <Typography data-testid={convertToLowerKabobCase(item.label, '-text')}>
                      <b>{fieldValue}</b>
                    </Typography>
                  </Box>
                  {/* {item.label === 'Account Balance' && index !== formData.expenses.length - 1 && (
                    <ArrayItemSeparator />
                  )} */}
                </Box>
              );
            }
            return null;
          });
        })}
    </Box>
  );
};

const mapStateToProps = (state: RootState) => ({
  formData: faBillsSelector(state)
});
export default connect(mapStateToProps)(BillsInfo);
