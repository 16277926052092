import React from 'react';
import { Color } from 'modules/styles/colors';

export default {
  svg: (
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.58929 0H14.4107C15.7125 0 16.7679 1.05533 16.7679 2.35714V3.21429H20.8393H23.25C23.6642 3.21429 24 3.55007 24 3.96429C24 4.34398 23.7178 4.65778 23.3518 4.70744L23.25 4.71429H21.5294L20.1028 21.8377C20.0014 23.0598 18.9799 24 17.7536 24H6.2475C5.02117 24 3.99966 23.0598 3.89831 21.838L2.47064 4.71429H0.75C0.335786 4.71429 0 4.3785 0 3.96429C0 3.58459 0.282154 3.27079 0.648229 3.22113L0.75 3.21429H3.16071H7.23214V2.35714C7.23214 1.05533 8.28747 0 9.58929 0ZM3.97602 4.71429L5.39315 21.7137C5.42694 22.1211 5.74189 22.4423 6.1377 22.493L6.2475 22.5H17.7536C18.1996 22.5 18.5711 22.1581 18.6079 21.7134L20.023 4.71429H16.0179H7.98214H3.97602ZM14.5182 1.50668L14.4107 1.5H9.58929C9.1159 1.5 8.73214 1.88376 8.73214 2.35714L8.732 3.214H15.267L15.2679 2.35714C15.2679 1.92017 14.9409 1.55957 14.5182 1.50668ZM10.3324 9.48752C10.2828 9.12144 9.96898 8.83929 9.58929 8.83929C9.17507 8.83929 8.83929 9.17507 8.83929 9.58929V17.625L8.84613 17.7268C8.89579 18.0928 9.20959 18.375 9.58929 18.375C10.0035 18.375 10.3393 18.0392 10.3393 17.625V9.58929L10.3324 9.48752ZM14.4107 8.83929C14.7904 8.83929 15.1042 9.12144 15.1539 9.48752L15.1607 9.58929V17.625C15.1607 18.0392 14.8249 18.375 14.4107 18.375C14.031 18.375 13.7172 18.0928 13.6676 17.7268L13.6607 17.625V9.58929C13.6607 9.17507 13.9965 8.83929 14.4107 8.83929Z"
        fill={Color.black}
      />
    </g>
  ),
  viewBox: '0 0 24 24'
};
