import React from 'react';

export default (props: any) => ({
  svg: (
    <g clipPath="url(#clip0_1956_1638)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 12.0371C0 11.6229 0.335786 11.2871 0.75 11.2871H23.25C23.6642 11.2871 24 11.6229 24 12.0371C24 12.4513 23.6642 12.7871 23.25 12.7871H0.75C0.335786 12.7871 0 12.4513 0 12.0371Z"
        fill={props.color ?? 'black'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M-0.25 12.0371C-0.25 11.4848 0.197715 11.0371 0.75 11.0371H23.25C23.8023 11.0371 24.25 11.4848 24.25 12.0371C24.25 12.5894 23.8023 13.0371 23.25 13.0371H0.75C0.197715 13.0371 -0.25 12.5894 -0.25 12.0371ZM0.75 11.5371C0.473858 11.5371 0.25 11.761 0.25 12.0371C0.25 12.3133 0.473858 12.5371 0.75 12.5371H23.25C23.5261 12.5371 23.75 12.3133 23.75 12.0371C23.75 11.761 23.5261 11.5371 23.25 11.5371H0.75Z"
        fill={props.color ?? 'black'}
      />
    </g>
  ),
  viewBox: '0 0 24 24'
});
