import React from 'react';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import RequiredLabel from 'components/UI/Labels/RequiredLabel';
import FlexBox from 'components/UI/Layout/FlexBox';
import Spacer from 'components/UI/Layout/Spacer';
import SmallText from 'components/UI/Typography/SmallText';
import { Formik } from 'formik';
import { MuiBox, MuiMenuItem, MuiTypography } from 'theme/material-ui';
import {
  StateOptionsType,
  RelationShipToSubscriber
} from 'modules/constants/covidScreening/personalInfo';
import { LOCATION_STATES } from 'modules/constants/LocationStates';
import * as covidActions from 'store/CovidScreening/actions';
import { patientSelect } from 'modules/constants/covidScreening';
import { CovidScreeningQuestionnaireSelector } from 'store/CovidScreening/selectors';
import { Questionnaire } from 'store/CovidScreening/types';
import { RootState } from 'store/types';
import { NavigationScreenProps } from 'screens/navigation';
import CustomTextField from 'screens/Register/FormElements/CustomTextField';
import Footer from 'components/common/Footer';
import { CovidTestButton } from '../Components/CovidTestButton';
import useStyles from '../Components/CovidTestButton/useStyles';
import { StyledSelect } from './styled';
import { InsuranceInformationData } from './types';
import { InsuranceValidateSchema } from './validation';
import { CovidTitles } from 'lib/constants/covidScreening';
import { AnalyticsEvent } from 'services/AnalyticsService';
import useNavigationAnalytics from 'hooks/useNavigationAnalytics';
import { convertToLowerKabobCase } from 'modules/utils/StringUtils';

export interface CovidSubscriberInfoScreenProps extends NavigationScreenProps {
  questionnaire: Questionnaire;
  setQuestionnaire: typeof covidActions.setQuestionnaire;
}

export function InsuranceInformation({
  questionnaire,
  history,
  setQuestionnaire
}: CovidSubscriberInfoScreenProps) {
  const classes = useStyles();
  const initialValues = {
    Health_Plan_Name: questionnaire.Health_Plan_Name ?? '',
    Health_Plan_ID: questionnaire.Health_Plan_ID ?? '',
    MEMBER_NUMBER: questionnaire.MEMBER_NUMBER ?? '',
    GROUP_NUMBER: questionnaire.GROUP_NUMBER ?? '',
    Health_Plan_Address_line_1: questionnaire.Health_Plan_Address_line_1 ?? '',
    HealthPlan_City: questionnaire.HealthPlan_City ?? '',
    HealthPlan_state: questionnaire.HealthPlan_state ?? '',
    HealthPlan_zip_code: questionnaire.HealthPlan_zip_code ?? '',
    Q_Patient_relationship_to_subscriber: questionnaire.Q_Patient_relationship_to_subscriber ?? '',
    SUBSCRIBER_FIRST_NAME_ON_CARD: questionnaire.SUBSCRIBER_FIRST_NAME_ON_CARD ?? '',
    SUBSCRIBER_MIDDLE_NAME_ON_CARD: questionnaire.SUBSCRIBER_MIDDLE_NAME_ON_CARD ?? '',
    SUBSCRIBER_LAST_NAME_ON_CARD: questionnaire.SUBSCRIBER_LAST_NAME_ON_CARD ?? ''
  };

  const covidScreeningNextBtn = useNavigationAnalytics(AnalyticsEvent.CovidNext, {
    title: CovidTitles.EXISTING_INSURANCE_NOT_FOUND
  });

  const covidScreeningPreviousBtn = useNavigationAnalytics(AnalyticsEvent.CovidPrev, {
    title: CovidTitles.EXISTING_INSURANCE_NOT_FOUND
  });

  const handleSubmit = (values: InsuranceInformationData) => {
    const newQuestionnaire = { ...questionnaire, ...values };
    setQuestionnaire(newQuestionnaire);
    covidScreeningNextBtn();
    const navigateTo =
      values?.Q_Patient_relationship_to_subscriber === 'Self'
        ? '/u/get-care-now/covid-screen/covid-insurance-upload'
        : '/u/get-care-now/covid-screen/subscriber-information';
    history.push(navigateTo);
  };

  const onCancelClick = values => {
    const newQuestionnaire = { ...questionnaire, ...values };
    setQuestionnaire(newQuestionnaire);
    covidScreeningPreviousBtn();
    history.goBack();
  };

  return (
    <>
      <Container maxWidth="md">
        <Spacer size="medium" />
        <MuiBox my={2}>
          <MuiTypography align="left" variant="h6" fontWeight="bold">
            Please provide your insurance information:
          </MuiTypography>
        </MuiBox>
        <Formik
          enableReinitialize="true"
          validateOnMount
          initialValues={initialValues}
          validationSchema={InsuranceValidateSchema}
        >
          {({ values, errors, isValid, touched, handleChange, setFieldTouched }) => {
            return (
              <>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <RequiredLabel labelText="Health Plan Name" />
                    <CustomTextField
                      name="Health_Plan_Name"
                      variant="outlined"
                      value={values?.Health_Plan_Name}
                      onChange={handleChange('Health_Plan_Name')}
                      data-testid="health-plan-name"
                      required
                      maxLength={100}
                      placeholder="Name of plan"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <RequiredLabel labelText="Health Plan ID" />
                    <CustomTextField
                      name="Health_Plan_ID"
                      variant="outlined"
                      value={values?.Health_Plan_ID}
                      onChange={handleChange('Health_Plan_ID')}
                      data-testid="health-plan-id"
                      placeholder="Enter Health Plan ID"
                      maxLength={20}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <RequiredLabel labelText="Member Number/ID" />
                    <CustomTextField
                      name="MEMBER_NUMBER"
                      variant="outlined"
                      value={values?.MEMBER_NUMBER}
                      onChange={handleChange('MEMBER_NUMBER')}
                      data-testid="member-number"
                      required
                      placeholder="Enter Member Number/ID"
                      maxLength={20}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <RequiredLabel hideIndicator labelText="Group Number/ID" />
                    <CustomTextField
                      name="GROUP_NUMBER"
                      variant="outlined"
                      placeholder="Enter Group Number/ID"
                      data-testid="group-number"
                      value={values?.GROUP_NUMBER}
                      onChange={handleChange('GROUP_NUMBER')}
                      maxLength={20}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <RequiredLabel labelText="Insurance Street" />
                    <CustomTextField
                      name="Health_Plan_Address_line_1"
                      variant="outlined"
                      value={values?.Health_Plan_Address_line_1}
                      onChange={handleChange('Health_Plan_Address_line_1')}
                      data-testid="insurance-street"
                      onBlur={() => {
                        setFieldTouched('Health_Plan_Address_line_1');
                      }}
                      placeholder="Enter Address"
                      required
                      maxLength={30}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <RequiredLabel labelText="Insurance City" />
                    <CustomTextField
                      name="HealthPlan_City"
                      variant="outlined"
                      value={values?.HealthPlan_City}
                      onChange={handleChange('HealthPlan_City')}
                      data-testid="insurance-city"
                      placeholder="Enter Insurance City"
                      maxLength={34}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <FlexBox width="100%">
                      <RequiredLabel labelText="Insurance State" />
                      <StyledSelect
                        variant="outlined"
                        value={values?.HealthPlan_state}
                        data-testid="insurance-state"
                        onChange={handleChange('HealthPlan_state')}
                      >
                        {LOCATION_STATES.map((option: StateOptionsType) => (
                          <MuiMenuItem
                            style={{ whiteSpace: 'normal' }}
                            data-testid={convertToLowerKabobCase(option.value, '-insurance-state')}
                            key={option.label}
                            value={option.value}
                          >
                            {option.label}
                          </MuiMenuItem>
                        ))}
                      </StyledSelect>
                      {errors?.HealthPlan_state && touched.HealthPlan_state ? (
                        <SmallText color="red">{errors?.HealthPlan_state}</SmallText>
                      ) : null}
                    </FlexBox>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <RequiredLabel labelText="Insurance Zip" />
                    <CustomTextField
                      name="HealthPlan_zip_code"
                      data-testid="insurance-zip_code"
                      maxLength={5}
                      variant="outlined"
                      value={values?.HealthPlan_zip_code}
                      onChange={handleChange('HealthPlan_zip_code')}
                      mask="zip"
                      placeholder="Enter Insurance Zip"
                      required
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FlexBox width="100%">
                      <RequiredLabel labelText="Patient relationship to subscriber:" />
                      <StyledSelect
                        variant="outlined"
                        value={values?.Q_Patient_relationship_to_subscriber}
                        onChange={handleChange('Q_Patient_relationship_to_subscriber')}
                        data-testid="insurance-subscriber-relationship"
                      >
                        {RelationShipToSubscriber.map((option: StateOptionsType) => (
                          <MuiMenuItem
                            style={{ whiteSpace: 'normal' }}
                            data-testid={convertToLowerKabobCase(
                              option.value,
                              '-insurance-relationship-to-subscriber'
                            )}
                            key={option.label}
                            value={option.value}
                          >
                            {option.label}
                          </MuiMenuItem>
                        ))}
                      </StyledSelect>
                      {errors?.Q_Patient_relationship_to_subscriber &&
                      touched.Q_Patient_relationship_to_subscriber ? (
                        <SmallText color="red">
                          {errors?.Q_Patient_relationship_to_subscriber}
                        </SmallText>
                      ) : null}
                    </FlexBox>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <RequiredLabel labelText="First name on card" />
                    <CustomTextField
                      name="SUBSCRIBER_FIRST_NAME_ON_CARD"
                      variant="outlined"
                      placeholder="Enter First Name"
                      value={values?.SUBSCRIBER_FIRST_NAME_ON_CARD}
                      data-testid="insurance-first-name"
                      onChange={handleChange('SUBSCRIBER_FIRST_NAME_ON_CARD')}
                      maxLength={16}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <RequiredLabel hideIndicator labelText="Middle name on card" />
                    <CustomTextField
                      name="SUBSCRIBER_MIDDLE_NAME_ON_CARD"
                      variant="outlined"
                      placeholder="Enter Middle Name"
                      value={values?.SUBSCRIBER_MIDDLE_NAME_ON_CARD}
                      data-testid="insurance-middle-name"
                      onChange={handleChange('SUBSCRIBER_MIDDLE_NAME_ON_CARD')}
                      maxLength={16}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <RequiredLabel labelText="Last name on card" />
                    <CustomTextField
                      name="SUBSCRIBER_LAST_NAME_ON_CARD"
                      variant="outlined"
                      placeholder="Enter Last Name"
                      value={values?.SUBSCRIBER_LAST_NAME_ON_CARD}
                      data-testid="insurance-last-name"
                      onChange={handleChange('SUBSCRIBER_LAST_NAME_ON_CARD')}
                      maxLength={26}
                      required
                    />
                  </Grid>
                </Grid>
                <Spacer size="medium" />
                <MuiBox className={classes.steppersFooter}>
                  <CovidTestButton
                    fullWidth
                    disabled={!isValid}
                    data-testid="insurance-info-next-btn"
                    color="primary"
                    variant="contained"
                    onClick={() => handleSubmit(values)}
                  >
                    Next
                  </CovidTestButton>
                  <Spacer size="xsmall" />
                  <CovidTestButton
                    variant="text"
                    data-testid="insurance-info-previous-btn"
                    fullWidth
                    onClick={() => onCancelClick(values)}
                  >
                    Previous
                  </CovidTestButton>
                </MuiBox>
              </>
            );
          }}
        </Formik>
        <Footer
          headerText={patientSelect.GET_PATIENTS.HELPLINE.CovidInfo}
          link={patientSelect.GET_PATIENTS.HELPLINE.CovidPatientSelectHelpline}
          linkName={patientSelect.GET_PATIENTS.HELPLINE.CovidPatientSelectHelpline}
          helplineHours={patientSelect.GET_PATIENTS.HELPLINE.CovidHelplineHours}
          title={CovidTitles.EXISTING_INSURANCE_NOT_FOUND}
        />
      </Container>
    </>
  );
}

const mapStateToProps = (state: RootState) => ({
  questionnaire: CovidScreeningQuestionnaireSelector(state)
});

const mapDispatchToProps = {
  setQuestionnaire: covidActions.setQuestionnaire
};

export default connect(mapStateToProps, mapDispatchToProps)(InsuranceInformation);
