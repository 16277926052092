import CollapsibleListItem from 'components/common/CollapsibleList/CollapsibleListItem';
import DataLoader from 'components/UI/DataLoader/DataLoader';
import { History } from 'lib/history';
import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'store/types';
import analyticsService, { AmplitudeEventData, AnalyticsEvent } from 'services/AnalyticsService';
import {
  currentLocationPathNameSelector,
  previousLocationPathNameSelector
} from 'store/router/selectors';
import { HEALTH_RECORDS_DRAWERS } from 'lib/constants/healthRecords';
import { TestResult } from 'store/testResults/types';
import { getDetailsPath } from 'store/testResults/utils';
import { MuiBox, MuiButton, MuiList, MuiPaper } from 'theme/material-ui';
import Spinner from 'components/UI/Spinner/Spinner';
import { HealthRecordsListItemPlaceholder } from '../shared';
import TestResultsRowItem from './TestResultsRowItem';
import { getProviderName } from 'lib/healthRecords/preventativeCare/utils';
import FlexBox from 'components/UI/Layout/FlexBox';
import { Spacing } from 'modules/styles/variables';

interface HealthRecordsTestResultsListProps {
  testResults: TestResult[];
  loading?: boolean;
  error: boolean;
  onMarkTestResultAsRead: (id: string | null) => Promise<void>;
  history: History;
  currentUrl?: string;
  referringUrl?: string;
}

const getLoadingState = () => (
  <>
    <HealthRecordsListItemPlaceholder message={HEALTH_RECORDS_DRAWERS.TestResults.loading} />
    <MuiBox display="flex" flexDirection="row" justifyContent="center">
      <Spinner />
    </MuiBox>
  </>
);

const getErrorState = () => (
  <HealthRecordsListItemPlaceholder
    message={HEALTH_RECORDS_DRAWERS.getErrorState('test results')}
  />
);

const getEmptyState = () => (
  <HealthRecordsListItemPlaceholder
    message={HEALTH_RECORDS_DRAWERS.getEmptyState('test results')}
  />
);

export const HealthRecordsTestResultsList = ({
  testResults,
  loading,
  error,
  history,
  currentUrl,
  referringUrl
}: HealthRecordsTestResultsListProps) => {
  const slicedTestResults = testResults.slice(0, 3);

  const data: AmplitudeEventData = {
    currentUrl,
    referringUrl
  };

  const handleViewAllClick = () => {
    analyticsService.logEvent(AnalyticsEvent.ViewAllTestResultsClicked, data);
    history.push('/u/health-record/test-results');
  };

  const handleListItemClick = () => {
    analyticsService.logEvent(AnalyticsEvent.TestResultsItemClicked, data);
  };

  return (
    <CollapsibleListItem
      leftIcon="TestResultsIcon2"
      title={HEALTH_RECORDS_DRAWERS.TestResults.title}
      subtitle={HEALTH_RECORDS_DRAWERS.TestResults.subtitle(testResults.length)}
      error={error ? 'An issue occurred' : undefined}
      analyticsEvent={AnalyticsEvent.TestResultsClicked}
      currentUrlForAnalytics={currentUrl}
      referringUrlForAnalytics={referringUrl}
      detailsPadding="0"
    >
      <DataLoader
        data={testResults}
        error={error}
        loading={loading}
        renderData={() => (
          <>
            <MuiBox width="100%">
              <MuiPaper elevation={2}>
                <MuiList disablePadding>
                  {slicedTestResults.map(item => (
                    <TestResultsRowItem
                      testID="test-results-content"
                      key={item.eventId}
                      date={item.testDate}
                      resultName={item.name}
                      providerName={getProviderName(item.providerNames)}
                      read={item.isRead}
                      to={{
                        pathname: getDetailsPath(item),
                        state: { result: item }
                      }}
                      onClick={handleListItemClick}
                    />
                  ))}
                  {testResults.length > slicedTestResults.length ? (
                    <FlexBox justifyContent="flex-start" padding={Spacing.large}>
                      <MuiButton
                        color="primary"
                        variant="outlined"
                        data-testid="view-all-test-results"
                        onClick={handleViewAllClick}
                      >
                        View all test results
                      </MuiButton>
                    </FlexBox>
                  ) : null}
                </MuiList>
              </MuiPaper>
            </MuiBox>
          </>
        )}
        renderNoData={getEmptyState}
        renderError={getErrorState}
        renderLoading={getLoadingState}
      />
    </CollapsibleListItem>
  );
};

const mapState = (state: RootState) => ({
  currentUrl: currentLocationPathNameSelector(state),
  referringUrl: previousLocationPathNameSelector(state)
});

export default connect(mapState)(HealthRecordsTestResultsList);
