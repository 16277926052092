import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { useField } from 'formik';
import React from 'react';
import useStyles from '../useStyles';
import FieldError from './FieldError';
import { getRegisterBrand } from 'store/register';

const CustomRadioGroup = ({ label, options, tooltip, ...props }) => {
  const redirectBrand = getRegisterBrand();
  const classes = useStyles({ redirectBrand });
  const [field, meta] = useField({ ...props, type: 'radio' });
  const hasError = meta.touched && !!meta.error;

  return (
    <FormControl component="fieldset" error={hasError} className={classes.formControl}>
      <FormLabel component="legend" className={classes.label}>
        {label} {tooltip}
      </FormLabel>
      <RadioGroup {...field} {...props}>
        {options.map(opt => (
          <FormControlLabel
            key={opt.value}
            value={opt.value}
            control={<Radio className={classes.radio} />}
            label={opt.label}
          />
        ))}
      </RadioGroup>
      <FieldError error={hasError && meta.error} />
    </FormControl>
  );
};

export default CustomRadioGroup;
