import React from 'react';

export default (props: any) => ({
  svg: (
    <g>
      <path
        d="M187.9,99.1V76.6c0-10.3-8.4-18.7-18.7-18.7H76c-10.3,0-18.7,8.4-18.7,18.7v22.5c0,9.4,7,17.2,16,18.5v107.7
        c0,10.8,8.7,19.5,19.5,19.5h59.6c10.8,0,19.5-8.7,19.5-19.5V117.6C181,116.3,187.9,108.5,187.9,99.1z M72.3,76.6
        c0-2,1.7-3.7,3.7-3.7h93.3c2,0,3.7,1.7,3.7,3.7v22.5c0,2-1.7,3.7-3.7,3.7H76c-2,0-3.7-1.7-3.7-3.7V76.6z M152.4,229.7H92.8
        c-2.4,0-4.5-2.1-4.5-4.5V117.8h68.6v12.7h-41.8v85.2h41.8v9.5C156.9,227.7,154.9,229.7,152.4,229.7z M156.9,168.7v7.9h-10.5v15
        h10.5v9.1h-26.8v-55.2h26.8v8.2h-10.5v15H156.9z M261,206.5h-17.7c-2.7-15.2-16-26.8-32-26.8c-17.9,0-32.5,14.6-32.5,32.5
        s14.6,32.5,32.5,32.5c0.7,0,1.5,0,2.2-0.1c0.3,0,0.7,0.1,1.1,0.1H261c4.1,0,7.5-3.4,7.5-7.5V214C268.5,209.8,265.1,206.5,261,206.5
        z M253.5,229.7h-31.4v-8.2h31.4V229.7z M193.8,212.2c0-9.7,7.9-17.5,17.5-17.5c7.6,0,14.2,4.9,16.5,11.8h-13.3
        c-4.1,0-7.5,3.4-7.5,7.5v15.2C199.5,227.3,193.8,220.4,193.8,212.2z"
      />
    </g>
  ),
  viewBox: '0 0 300 300'
});
