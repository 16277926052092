import React from 'react';

export default (props: any) => ({
  svg: (
    <g fill="none">
      <path
        d="M8 0C5.87827 0 3.84344 0.842855 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0 11.51 5 20 7.15 23.52C7.23968 23.6647 7.36483 23.7841 7.51358 23.8669C7.66232 23.9498 7.82975 23.9932 8 23.9932C8.17025 23.9932 8.33768 23.9498 8.48642 23.8669C8.63517 23.7841 8.76032 23.6647 8.85 23.52C11 20 16 11.51 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0ZM8 11.5C7.30777 11.5 6.63108 11.2947 6.0555 10.9101C5.47993 10.5256 5.03133 9.97893 4.76642 9.33939C4.50151 8.69985 4.4322 7.99612 4.56725 7.31718C4.7023 6.63825 5.03564 6.01461 5.52513 5.52513C6.01461 5.03564 6.63825 4.7023 7.31718 4.56725C7.99612 4.4322 8.69985 4.50151 9.33939 4.76642C9.97893 5.03133 10.5256 5.47993 10.9101 6.0555C11.2947 6.63108 11.5 7.30777 11.5 8C11.5 8.92826 11.1313 9.8185 10.4749 10.4749C9.8185 11.1313 8.92826 11.5 8 11.5Z"
        fill={props.color ? props.color : 'white'}
      />
    </g>
  ),
  viewBox: '0 0 16 24'
});
