import styled from 'styled-components';

export const BPTrendsModal = styled.div<{ maxWidth?: string }>`
  display: block;
  margin-left: auto;
  margin-right: auto;
  top: 10%;
  background-color: #38b;
  border: 2px solid #000;
  max-width: 1126px;
  padding: 50px;
`;
