import React from 'react';
import { MuiTableBody } from 'theme/material-ui';
import {
  TableWrapper,
  StyledTable,
  StyledTableHead,
  StyledTableCell,
  StyledTableRow
} from '../styled';
import { ELIGIBILITY_DATA } from './constants';

const EligibilityTable = () => {
  return (
    <TableWrapper>
      <StyledTable>
        <StyledTableHead>
          <StyledTableRow className="header">
            <StyledTableCell className="title" colSpan={6}>
              2023 Annual Guidelines
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow className="header">
            <StyledTableCell rowSpan={2}>Family Size</StyledTableCell>
            <StyledTableCell colSpan={5}>Household income before taxes</StyledTableCell>
          </StyledTableRow>
          <StyledTableRow className="header">
            <StyledTableCell>250%</StyledTableCell>
            <StyledTableCell>300%</StyledTableCell>
            <StyledTableCell>350%</StyledTableCell>
            <StyledTableCell>400%</StyledTableCell>
            <StyledTableCell>500%</StyledTableCell>
          </StyledTableRow>
        </StyledTableHead>
        <MuiTableBody>
          {ELIGIBILITY_DATA.map(row => (
            <StyledTableRow key={row.id}>
              <StyledTableCell>{row.id}</StyledTableCell>
              <StyledTableCell>{row.value1}</StyledTableCell>
              <StyledTableCell>{row.value2}</StyledTableCell>
              <StyledTableCell>{row.value3}</StyledTableCell>
              <StyledTableCell>{row.value4}</StyledTableCell>
              <StyledTableCell>{row.value5}</StyledTableCell>
            </StyledTableRow>
          ))}
        </MuiTableBody>
        <StyledTableRow>
          <StyledTableCell className="title" colSpan={6}>
            For households with more than eight persons, add $4,720 for each additional person.
          </StyledTableCell>
        </StyledTableRow>
      </StyledTable>
    </TableWrapper>
  );
};

export default EligibilityTable;
