import React from 'react';

export default {
  svg: (
    <g>
      <path
        d="M135.17 0a48.64 48.64 0 0 1 6.4 1.43c11 4 17.36 11.81 19.16 23.35a9.47 9.47 0 0 1 0 1.19v17.38a1.82 1.82
        0 0 1-.91 1.73c-4 2.68-8.07 5.4-12.1 8.11v-.7V28.4a15 15 0 0 0-15.22-15.2H28.05A15 15 0 0 0 13.2 28v106.46A15
        15 0 0 0 28 149.37h104.86a14.65 14.65 0 0 0 3.84-.54 15 15 0 0 0 11-14.7V83.45c.21-.22.4-.45.62-.64 2.93-2.57
        5.85-5.13 8.79-7.69 1.08-.95 2.2-1.87 3.5-3 0 .7.1 1.15.1 1.59v62.89a25.65 25.65 0 0 1-4.52 13.33A27.67 27.67
        0 0 1 136 162.4a7.25 7.25 0 0 0-.78.17H25.59a6.63 6.63 0 0 0-.77-.18 28.17 28.17 0 0
        1-24-21.24c-.3-1.25-.53-2.52-.79-3.78V25.59c.06-.32.15-.63.19-1A28.22 28.22 0 0 1 20.52 1c1.67-.42 3.38-.66
        5.07-1zM955.6 112a17.21 17.21 0 0 1-1.78 3.83 7 7 0 0 1-8.44 1.82 7.19 7.19 0 0 1-3.93-7.64 7.09 7.09 0 0 1
        13.92-.77c.06.25.15.5.23.76zm-12.6-.1a5.47 5.47 0 0 0 10 2.67c1.9-3 1-7.18-1.91-8.74-3.82-2.1-8.09.7-8.09 6.05z"
        fill="#fff"
      />
      <path
        d="M181.43 30.8a15.69 15.69 0 0 1 6.75-2.8 7.63 7.63 0 0 1 8.59 9.66 12.41 12.41 0 0 1-3.94 6.34q-12.56
        11.16-25.15 22.25c-1.39 1.23-2.78 2.48-4.22 3.66l-1.1 1c-1.29 1.1-2.39 2.1-3.48 3q-4.4 3.84-8.78 7.69a7.52 7.52
        0 0 0-.62.64c-.55.42-1.13.81-1.65 1.27q-24.51 21.3-49 42.63a46.53 46.53 0 0 1-9.69 7c-4.81 2.38-9.42
        2.07-13.71-1.17a31 31 0 0 1-4.66-4.2c-7.17-8.06-14.25-16.22-21.38-24.32-6-6.77-12-13.5-17.94-20.28a15.31 15.31 0
        0 1-3.82-7.9C26.85 70 30 65.7 35.68 66a16.59 16.59 0 0 1 8.68 3.27c6 4.19 11.86 8.49 17.81 12.71 3.69 2.62 7.33
        5.34 11.18 7.72a16.83 16.83 0 0 0 17.24.77 50 50 0 0 0 5.39-3q18.48-12.09 36.91-24.26c5.56-3.67 11.07-7.41
        16.6-11.12 4-2.71 8-5.43 12.1-8.11z"
        fill="#58993c"
      />
      <path
        d="M665.9 87.58c.65 7.22 2.73 13.45 9.19 17.34a15.14 15.14 0 0 0 6.56 1.94 25.91 25.91 0 0 0 8.73-.52c4.5-1.15
        7.53-4.09 9.84-7.94.51-.86 1-1.7 1.57-2.56a5.49 5.49 0 0 1 5.72-2.84 13 13 0 0 1 4 1.09c3.53 1.76 4.67 5.35 3
        9.1a25.73 25.73 0 0 1-7.07 9c-5.66 4.9-12.36 7.19-19.76 7.65a43.48 43.48 0 0
        1-14.85-1.36c-8.85-2.54-14.86-8.32-18.61-16.59a44.81 44.81 0 0 1-3.66-21.26 42.9 42.9 0 0 1 3.85-16c5-10.69
        13.35-17 25.15-18.4a37.39 37.39 0 0 1 15.53 1.18 26.77 26.77 0 0 1 17.15 15.09 43.79 43.79 0 0 1 3.93 17.22
        11.84 11.84 0 0 1-.25 2.78c-.64 3.07-2.83 4.82-6.3 5.11H665.9zm17.7-12.66h15.69a2.38 2.38 0 0 0 .89
        0c.25-.12.6-.46.57-.66A19.51 19.51 0 0 0 696.39 64c-5.43-6.36-16.81-5.77-22.14-2.11a19.51 19.51 0 0 0-7.73
        11.52c-.4 1.44-.34 1.52 1.18 1.52zM298.91 87.27a26.23 26.23 0 0 0 1.45 7.73c2.1 5.77 5.78 9.94 12 11.3a24 24
        0 0 0 10.9-.16 14.47 14.47 0 0 0 8.64-6.06c.81-1.18 1.6-2.38 2.29-3.63 2.16-3.85 5.21-4.62 9.59-2.91 4 1.56
        5.45 5.29 3.78 9.27a25.38 25.38 0 0 1-7.44 9.4 32 32 0 0 1-19.12 7.33 42.14 42.14 0 0 1-16.58-1.79A27.49 27.49
        0 0 1 287.48 102c-6-14-5.71-28 1.95-41.39 5.3-9.24 13.75-13.93 24.31-14.88a37 37 0 0 1 15 1.51 26.69 26.69 0 0
        1 16.5 14.86 42 42 0 0 1 3.91 17.9c0 4.75-2.57 7.31-7.3 7.31h-42.94zm34.82-12.72a18.92 18.92 0 0
        0-1.65-6.55c-2-4.39-5.16-7.43-9.92-8.5a25.36 25.36 0 0 0-5.84-.53c-10.46.14-15.74 8.1-17.08 15.57zM404
        87.27c.28 9.66 5.43 17.4 13.42 19a25 25 0 0 0 10.81-.15c4.62-1.11 7.72-4.13 10.05-8.1.42-.72.85-1.44 1.3-2.14
        1.87-2.84 3.91-3.69 7.27-3a11.41 11.41 0 0 1 1.81.54 6.73 6.73 0 0 1 4 9.21 22.43 22.43 0 0 1-5.06 7.25 31.44
        31.44 0 0 1-20.15 9.53 45 45 0 0 1-15.48-.9A27.64 27.64 0 0 1 392.45 102c-5.92-13.63-5.66-27.3 1.42-40.44
        5.24-9.73 13.82-14.78 24.79-15.79a37.28 37.28 0 0 1 14.44 1.31c8.37 2.54 14.13 8 17.55 16a42.34 42.34 0 0 1
        3.48 17c0 4.59-2.61 7.17-7.22 7.18H404zm34.84-12.7c-.12-.75-.18-1.37-.31-2-.86-4.07-2.39-7.85-5.84-10.39-5.81-4.28-12.21-4.26-18.57-1.55-5.08
        2.16-7.8 6.55-9.37 11.69-.22.71-.37 1.44-.57 2.24zM889.61 52.81a26.31 26.31 0 0 1 9.33-5.5 31.64 31.64 0 0 1
        18.6-.67c8.73 2.34 13.78 8.14 15.21 17a55.76 55.76 0 0 1 .68 8.85c.07 13.07 0 26.13 0 39.19 0 2.89-1 5.2-3.62
        6.53a8.07 8.07 0 0 1-8.39-.33c-2.18-1.37-2.84-3.61-2.84-6.06V77.33c0-2.72-.16-5.46-.39-8.18-.53-6.16-4.44-9.68-10.62-9.8a18.42
        18.42 0 0 0-9.53 2.18c-5.57 3.06-8.06 8-8.42 14.18-.15 2.46-.18 4.92-.19 7.39 0 9.56-.09 19.13 0 28.69a7.3 7.3 0 0
        1-8.24 7.31c-4.37-.39-6.63-2.92-6.63-7.32V28.6c0-3.67 1.74-6.17 4.87-7.06 3.6-1 7.31.13 9 2.82a8.21 8.21 0 0 1
        1.15 4.49v24zM597.05 52.78c.56-.47.9-.76 1.25-1a28.24 28.24 0 0 1 15.22-6 31.25 31.25 0 0 1 12 1c8.08 2.38 13
        7.76 14.52 16a46.58 46.58 0 0 1 .83 8.33c.08 13.36.06 26.72 0 40.08a8.12 8.12 0 0 1-1.6 5.45 7.28 7.28 0 0 1-6.58
        2.44c-4.35-.39-6.62-2.93-6.63-7.34V75.86a51.49 51.49 0 0 0-.61-8c-.81-5-4.13-8-9.15-8.41A17.74 17.74 0 0 0 602.13
        64a15.35 15.35 0 0 0-4.83 9.62 43.63 43.63 0 0 0-.39 5.48v31.68a15.68 15.68 0 0 1-.83 4.59 5.32 5.32 0 0 1-3.47
        3.24 8.82 8.82 0 0 1-6.73-.12c-2.38-1-3.44-3-3.74-5.5A15.09 15.09 0 0 1 582 111V29.33a12.82 12.82 0 0 1
        .15-2.48c.75-4 4.14-6.26 8.52-5.63a6.87 6.87 0 0 1 6.33 7.35v22.79zM768.18 112a60.22 60.22 0 0 1-5.72 3.94c-8.23
        4.43-16.8 5-25.44 1.47-7.12-2.93-11-8.62-12.14-16.15a25 25 0 0 1 .6-10.9c1.86-6.3 6.29-10.14 12.3-12.1 4.07-1.33
        8.32-2.13 12.5-3.1 4.83-1.11 9.69-2.13 14.53-3.23 2.41-.55 3-1.32 3-3.74 0-5.15-2.54-8.43-7.69-9.6a23.25 23.25 0
        0 0-10.3.09c-4.27 1-6.81 3.68-7.92 7.85-1 3.66-2.81 5-6.62 5a12.47 12.47 0 0 1-2.87-.26 6.12 6.12 0 0 1-4.85-7.27
        20.68 20.68 0 0 1 9.48-13.88 28.86 28.86 0 0 1 13.27-4.32 44.5 44.5 0 0 1 15.68 1c5.58 1.42 10.16 4.28 13
        9.46a22.72 22.72 0 0 1 2.57 9.67c.16 2.39.2 4.8.2 7.19v38.39a13.13 13.13 0 0 1-.32 2.86c-.65 2.77-2.26 4.33-5.09
        4.59a11.44 11.44 0 0 1-4.11-.42 5.32 5.32 0 0 1-3.81-4.47c-.09-.67-.16-1.36-.25-2.07zm.91-30.3c-2.49.64-4.86
        1.28-7.24 1.86s-4.88 1.09-7.3 1.69c-3 .76-6.1 1.42-9.06 2.41-4.61 1.55-6.93 5-6.91 9.76a10.1 10.1 0 0 0 7 10
        15.49 15.49 0 0 0 9 .5c6.32-1.51 11.15-4.85 13.15-11.36 1.45-4.81 1.27-9.82 1.36-14.82zM249 45.57c6.49.11 12.22
        1 17.41 4 5.36 3.15 9 7.66 10.21 13.87a6 6 0 0 1-2 6.24 7 7 0 0 1-7 1.28 6.35 6.35 0 0 1-2.45-1.93 14.92 14.92 0
        0 1-1.91-3.03c-2.66-5.42-7.14-8-13-8.35a15.68 15.68 0 0 0-6.89.87 7.15 7.15 0 0 0-4.37 4.53 5.93 5.93 0 0 0 2.79
        7.46 33 33 0 0 0 5.53 2.28c5.36 1.79 10.81 3.36 16.14 5.25a22.71 22.71 0 0 1 10.27 7c3.29 4.05 4.24 8.77 4
        13.86-.36 8.37-5.56 15.32-14 18.45a41.56 41.56 0 0 1-18.21 2.08 31.06 31.06 0 0 1-12.87-3.69 21.81 21.81 0 0
        1-11.35-16.93c-.52-4 1.74-6.83 5.7-7.63 2.36-.49 4.69-.47 6.48 1.49A9 9 0 0 1 235 95q1.06 2.37 2 4.81a10.28 10.28
        0 0 0 6.2 6.19c5 2 10.08 2.14 15-.25a8 8 0 0 0 4.8-8.49 6 6 0 0 0-3.3-4.84 44.17 44.17 0 0
        0-6.24-2.6c-4.87-1.61-9.82-3-14.66-4.64a25.31 25.31 0 0 1-9.86-5.92 16.66 16.66 0 0 1-4.82-14.54c1.32-10.2
        7.92-16.8 18.24-18.52.92-.15 1.85-.31 2.77-.39 1.46-.12 2.95-.18 3.87-.24zM460.26 83c0-7.61 1.11-14.33 4.27-20.6
        4.63-9.18 12.13-14.48 22.19-16.18a43.59 43.59 0 0 1 18.43.7 24 24 0 0 1 14.88 11 40.82 40.82 0 0 1 2.67 4.9c1.25
        2.85 1.11 5.66-1.07 8a8 8 0 0 1-8.16 2.25 5.36 5.36 0 0 1-3.24-2.2 23.82 23.82 0 0 1-2-3.44c-3.25-7.16-9.23-9.61-16.35-9-6.9.62-11.5
        4.44-13.86 10.76a36.93 36.93 0 0 0 .27 27.14c2.71 6.92 8.42 10.38 15.85 10.22 4.49-.09 8.56-1.23 11.78-4.56a
        22.24 22.24 0 0 0 4.23-6.85c.18-.4.34-.8.5-1.2 2.3-5.56 7.26-5.3 10.57-3.56 3.13 1.64 4.38 4.71 3.15 8.58-2.67
        8.39-7.78 14.81-16.16 18a38.58 38.58 0 0 1-27.76.34c-8.91-3.24-14.37-9.93-17.52-18.66A46 46 0 0 1 460.26
        83zM834.17 82.18V65.29c0-1.72-.11-3.44-.18-5.16l-5.07-.13a14.62 14.62 0 0 1-2.09-.18 5.39 5.39 0 0 1-4.61-4.82
        11.2 11.2 0 0 1 .09-3.56 5.43 5.43 0 0 1 5.45-4.44c1.79-.06 3.6-.05 5.39 0 .83 0 1.1-.26 1.09-1.09V33.32a12 12
        0 0 1 .15-2 6 6 0 0 1 5.52-5.45 13.15 13.15 0 0 1 3.49 0c3.56.49 5.59 2.91 5.62 6.62v13.2c0 1 .27 1.34 1.29
        1.3 2-.07 3.93 0 5.9 0a5.67 5.67 0 0 1 6 6 11 11 0 0 1-.08 2 5.83 5.83 0 0 1-6 5h-6.09c-.72 0-1 .21-1 1 0 12.62
        0 25.25.07 37.88a19.75 19.75 0 0 0 .56 4 3.69 3.69 0 0 0 3.22 3c1.45.24 2.94.21 4.36.52a10.36 10.36 0 0 1 3.8
        1.33c3.17 2.25 2.65 8.18-.75 10.13a10.93 10.93 0 0 1-5.3 1.26 34.09 34.09 0 0 1-10-1.05c-6-1.63-9.42-5.54-10.32-11.63a
        45.38 45.38 0 0 1-.46-6.27c-.09-5.98-.05-11.98-.05-17.98zM553.84 60c0 .47-.07.86-.07 1.25v31.69c0 2.69 0 5.4.28
        8.08.37 3.75 1.77 4.93 5.56 5.18a28 28 0 0 1 3.67.39c2.87.59 4.3 2.3 4.55 5.28s-1 5.39-3.58 6.36a15 15 0 0
        1-4.28.87 32.16 32.16 0 0 1-9.91-.93c-6.38-1.67-10-5.8-10.76-12.37a55.73 55.73 0 0 1-.4-6.58V60h-2.65a32.92
        32.92 0 0 1-4.67-.21 5.16 5.16 0 0 1-4.49-4.37 11.12 11.12 0 0 1 0-3.86 5.38 5.38 0 0 1 5.39-4.56c1.77-.06
        3.54-.07 5.3 0 1 0 1.32-.2 1.3-1.26-.06-4.26 0-8.53 0-12.79 0-4.71 2.86-7.42 7.59-7.21a14 14 0 0 1 1.59.13c
        3.52.59 5.48 2.88 5.52 6.5v13.7c0 .68.22.93.92.92h6.1c4 0 6.26 2.33 6.3 6.4s-2.35 6.57-6.26 6.62c
        -2.32-.01-4.61-.01-7-.01zM376.09 70.15v41.38c0 6.28-6.09 9.76-11.56 6.6-2.38-1.37-3.24-3.59-3.37-6.2v-.8-82a12
        12 0 0 1 .27-2.68c.91-3.74 4.3-5.76 8.51-5.13 3.93.59 6.13 3.12 6.13 7.11q.03 20.83.02 41.72zM812.25 70.19v41.48a
        7.78 7.78 0 0 1-1.28 4.65c-1.67 2.35-4.12 3-6.79 2.79-4.21-.25-6.78-3-6.78-7.25V28.38c0-3.57
        1.88-6.09 5.07-6.9 3.79-1 7.46.27 8.88 3.18a9.23 9.23 0 0 1 .85 3.84c.07 13.9.05 27.79.05 41.69z"
        fill="#fff"
      />
      <path d="" fill="#58993c" />
      <path d="" fill="#fff" />
    </g>
  ),
  viewBox: '0 0 955.6 162.57'
};
