import React from 'react';
import { MuiBox, MuiTypography, MuiButton } from 'theme/material-ui';
import { toSentenceCase } from 'modules/utils/StringUtils';

interface ErrorAction {
  label?: string;
  onClick: () => void;
}

export interface ConnectCareErrorProps {
  message: string;
  action?: ErrorAction;
}

function ConnectCareError({ message, action }: ConnectCareErrorProps) {
  return (
    <MuiBox
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      py={3}
    >
      <MuiTypography gutterBottom>{message}</MuiTypography>

      {action && (
        <MuiButton color="primary" onClick={() => action.onClick()}>
          {toSentenceCase(action.label) || 'Try again?'}
        </MuiButton>
      )}
    </MuiBox>
  );
}

export default ConnectCareError;
