import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { JsonObj } from '@mhp/general-interface';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Color } from 'modules/styles/colors';
import { FontSize, FontWeight, IconSize, Spacing } from 'modules/styles/variables';
import { mimeTypes } from 'modules/constants/ConnectCare';
import { RootState } from 'store/types';
import { SystemConfiguration, HealthDocumentRecord } from 'store/amwell/types';
import * as selectors from 'store/amwell/selectors';
import * as actions from 'store/amwell/actions';
import {
  MuiBox,
  MuiContainer,
  MuiGrid,
  MuiTypography,
  MuiList,
  MuiListItem,
  MuiListItemAvatar,
  MuiAvatar,
  MuiListItemText,
  MuiListItemSecondaryAction,
  MuiDivider
} from 'theme/material-ui';
import { FileUploadBox } from 'components/FileUploadBox';
import { Svg, SvgButton } from 'components/UI/Svg';
import DataLoader from 'components/UI/DataLoader/DataLoader';
import { Row } from 'components/UI/Layout/Container';
import { Alert } from 'components/Alert';
import {
  ConnectCareBullet,
  ConnectCareLoading,
  ConnectCareError,
  ConnectCareStepAction,
  ConnectCareStepActions
} from 'components/ConnectCare';
import {
  shared as amwellShared,
  healthDocuments as healthDocumentsConfirmations
} from 'modules/constants/amwell';
import { getSelectionId } from '../utils';
import { RouteComponentProps } from 'react-router';
import { FormScreen } from '../styled';
import { RouteData } from '../types';

export interface Props extends RouteComponentProps {
  systemConfiguration: SystemConfiguration | null;
  healthDocuments: HealthDocumentRecord[];
  errors: ReturnType<typeof selectors.healthDocumentsErrorSelector>;
  loading: ReturnType<typeof selectors.healthDocumentsLoadingSelector>;
  searchHealthDocuments: typeof actions.searchHealthDocuments;
  addHealthDocument: typeof actions.addHealthDocument;
  removeHealthDocument: typeof actions.removeHealthDocument;
}

const List = styled(MuiList)`
  &.MuiList-root {
    width: 100%;
    background-color: ${Color.white};
  }
`;

const uploadDate = (healthDocument: HealthDocumentRecord) => {
  return dayjs(healthDocument.uploadDate).format(amwellShared.DATE_FORMAT.mmddyyyy);
};

export function ConnectCareHealthDocuments(props: Props) {
  const {
    history,
    systemConfiguration,
    healthDocuments,
    loading,
    errors,
    searchHealthDocuments,
    addHealthDocument,
    removeHealthDocument
  } = props;

  const [error, setError] = useState<JsonObj | null>(null);
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    if (error) {
      Alert.alert(error.title, error.subtitle, [{ text: 'Ok', onPress: () => setError(null) }], {
        cancelable: true
      });
    }
  }, [error]);

  const onUpload = (file: File) => {
    setUploading(true);
    const reader = new window.FileReader();

    reader.onload = async ({ target }: ProgressEvent<FileReader>) => {
      if (target?.result) {
        const blob = new window.Blob([target.result], { type: file.type });
        const res: AnyAction = await addHealthDocument({ file: blob, fileName: file.name });
        if (res.error) {
          if (res.error.errorCode?.includes('size')) {
            setError({
              title: healthDocumentsConfirmations.UPLOAD_SIZE_ERROR.title,
              subtitle: res.error.errorCode
            });
          } else if (res.error.errorCode?.includes('type')) {
            setError({
              title: healthDocumentsConfirmations.UPLOAD_TYPE_ERROR.title,
              subtitle: res.error.errorCode
            });
          } else {
            setError(healthDocumentsConfirmations.UPLOAD_ERROR);
          }
        }
        setUploading(false);
      } else {
        setUploading(false);
      }
    };

    reader.onerror = () => {
      setError(healthDocumentsConfirmations.UPLOAD_ERROR);
      setUploading(false);
    };

    reader.readAsArrayBuffer(file);
  };

  const onRemove = async (healthDocument: HealthDocumentRecord) => {
    const res: AnyAction = await removeHealthDocument({ healthDocument });
    if (res.error) {
      setError(healthDocumentsConfirmations.REMOVE_ERROR);
    }
  };

  const onNextClick = (nextStep?: RouteData) => {
    if (nextStep) {
      history.push(nextStep.path);
    }
  };

  const onPrevClick = (prevStep?: RouteData) => {
    if (prevStep) {
      history.push(prevStep.path);
    }
  };

  return (
    <>
      <FormScreen>
        <MuiContainer maxWidth="lg">
          <MuiBox my={2}>
            <MuiGrid container spacing={2}>
              <MuiGrid item xs={12}>
                <MuiTypography align="center" variant="h5">
                  Would you like to share a photo, lab result, or other information with your
                  provider?
                </MuiTypography>
              </MuiGrid>
              <MuiGrid container spacing={2} style={{ maxWidth: '600px', margin: 'auto' }}>
                <MuiGrid item xs={12}>
                  <FileUploadBox
                    onClick={onUpload}
                    loading={uploading || loading.add}
                    infoText="Press here to add a media file to share with your provider."
                  >
                    Add a file
                  </FileUploadBox>
                </MuiGrid>
                {systemConfiguration ? (
                  <MuiGrid item xs={12}>
                    <MuiBox bgcolor={Color.foreColor} padding={Spacing.small}>
                      <Row>
                        <MuiTypography fontWeight={FontWeight.bold}>Upload Info</MuiTypography>
                        <MuiBox mx={1}>
                          <Svg
                            set="assets"
                            name="InfoIcon"
                            size={IconSize.xmedium}
                            color={Color.black}
                          />
                        </MuiBox>
                      </Row>
                      <MuiTypography fontSize={FontSize.small}>
                        <ConnectCareBullet /> File size can not exceed 20MB.
                        <br />
                        <ConnectCareBullet /> The selected file must be an allowed file type (
                        {mimeTypes}).
                      </MuiTypography>
                    </MuiBox>
                  </MuiGrid>
                ) : null}
                <DataLoader
                  data={healthDocuments}
                  loading={loading.search}
                  error={errors.search}
                  renderLoading={() => (
                    <MuiGrid item xs={12}>
                      <ConnectCareLoading />
                    </MuiGrid>
                  )}
                  renderError={() => (
                    <MuiGrid item xs={12}>
                      <ConnectCareError
                        message={healthDocumentsConfirmations.GET_ERROR.title}
                        action={{ label: 'Try Again?', onClick: searchHealthDocuments }}
                      />
                    </MuiGrid>
                  )}
                  renderNoData={() => <></>}
                  renderData={documents => (
                    <MuiGrid item xs={12}>
                      <List>
                        {documents.map((doc, idx, arr) => (
                          <React.Fragment key={getSelectionId(doc)}>
                            <MuiListItem>
                              <MuiListItemAvatar>
                                <MuiAvatar alt="Folder">
                                  <Svg set="material" name="folder" size={24} color={Color.white} />
                                </MuiAvatar>
                              </MuiListItemAvatar>
                              <MuiListItemText
                                primary={doc.name}
                                secondary={
                                  doc.uploadDate ? `Uploaded Date: ${uploadDate(doc)}` : null
                                }
                              />
                              <MuiListItemSecondaryAction>
                                <SvgButton
                                  edge="end"
                                  aria-label="delete"
                                  appearance="transparent"
                                  set="material"
                                  name="delete"
                                  size={24}
                                  color={Color.primary}
                                  onClick={() => onRemove(doc)}
                                  disabled={loading.remove}
                                />
                              </MuiListItemSecondaryAction>
                            </MuiListItem>
                            {arr.length !== idx + 1 ? (
                              <MuiDivider variant="inset" component="li" />
                            ) : null}
                          </React.Fragment>
                        ))}
                      </List>
                    </MuiGrid>
                  )}
                />
              </MuiGrid>
            </MuiGrid>
          </MuiBox>
        </MuiContainer>
      </FormScreen>
      <ConnectCareStepActions>
        <ConnectCareStepAction onClick={onPrevClick} />
        <ConnectCareStepAction onClick={onNextClick} />
      </ConnectCareStepActions>
    </>
  );
}

const mapStateToProps = (state: RootState) => ({
  systemConfiguration: selectors.configurationSelector(state),
  healthDocuments: selectors.healthDocumentsSelector(state),
  errors: selectors.healthDocumentsErrorSelector(state),
  loading: selectors.healthDocumentsLoadingSelector(state)
});

const mapDispatch = {
  searchHealthDocuments: actions.searchHealthDocuments,
  addHealthDocument: actions.addHealthDocument,
  removeHealthDocument: actions.removeHealthDocument
};

export default connect(mapStateToProps, mapDispatch)(ConnectCareHealthDocuments);
