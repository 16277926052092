import React from 'react';

import Spacer from 'components/UI/Layout/Spacer';
import { MuiTypography } from 'theme/material-ui';
import { FontWeight } from 'modules/styles/variables';
import { useLanguageSwitcher } from 'lib/hooks/useLanguageSwitcher';
import { translations } from 'lib/constants/translations/screens/covidGuest/covidInsuranceDropDown';

export default function TextInformation() {
  const screenText = useLanguageSwitcher(translations);
  return (
    <>
      <MuiTypography fontWeight={FontWeight.bold}>{screenText.instruction1}</MuiTypography>

      <Spacer size="small" />

      <MuiTypography>{screenText.paragraph1}</MuiTypography>

      <Spacer size="small" />

      <MuiTypography>{screenText.paragraph2}</MuiTypography>
    </>
  );
}
