import { GoalStatusDescriptions } from 'components/healthrecords/GoalStatusDescriptions/GoalStatusDescriptions';
import dayjs from 'dayjs';
import {
  MuiPaper,
  MuiTable,
  MuiTableBody,
  MuiTableCell,
  MuiTableContainer,
  MuiTableHead,
  MuiTableRow,
  MuiTypography
} from 'theme/material-ui';
import isNil from 'lodash/isNil';
import React from 'react';
import { Goal } from 'store/goals/types';
import { FontSize, FontWeight } from 'modules/styles/variables';

interface GoalsTableProps {
  goals: Goal[];
}

const orNA = (item: any) => (isNil(item) ? 'N/A' : item);

const formatDate = (d: Date | string | null) => {
  if (isNil(d)) {
    return 'N/A';
  }

  return dayjs(d).format('MM/DD/YYYY');
};

const formatStatus = (status: string) => {
  if (!status) {
    return 'N/A';
  }

  return status.toUpperCase().replace('-', ' ');
};

export const GoalsTable = ({ goals }: GoalsTableProps) => (
  <MuiTableContainer component={MuiPaper}>
    <MuiTable aria-label="Health Records Goals">
      <MuiTableHead>
        <MuiTableRow>
          <MuiTableCell>
            <MuiTypography fontSize={FontSize.heading} fontWeight={FontWeight.bold}>
              Goal
            </MuiTypography>
          </MuiTableCell>
          <MuiTableCell>
            <MuiTypography fontSize={FontSize.heading} fontWeight={FontWeight.bold}>
              Start Date
            </MuiTypography>
          </MuiTableCell>
          <MuiTableCell>
            <MuiTypography fontSize={FontSize.heading} fontWeight={FontWeight.bold}>
              Target Date
            </MuiTypography>
          </MuiTableCell>
          <MuiTableCell>
            <MuiTypography fontSize={FontSize.heading} fontWeight={FontWeight.bold}>
              Status
            </MuiTypography>
          </MuiTableCell>
        </MuiTableRow>
      </MuiTableHead>

      <MuiTableBody>
        {goals.map(goal => (
          <MuiTableRow key={goal.id}>
            <MuiTableCell>
              <MuiTypography>{orNA(goal.description)}</MuiTypography>
            </MuiTableCell>
            <MuiTableCell>
              <MuiTypography>{formatDate(goal.startDate)}</MuiTypography>
            </MuiTableCell>
            <MuiTableCell>
              <MuiTypography>{formatDate(goal.targetDate)}</MuiTypography>
            </MuiTableCell>
            <MuiTableCell>
              <MuiTypography color="primary">
                <MuiTypography>{formatStatus(goal.status)}</MuiTypography>
              </MuiTypography>
            </MuiTableCell>
          </MuiTableRow>
        ))}
      </MuiTableBody>
    </MuiTable>
    <GoalStatusDescriptions />
  </MuiTableContainer>
);
