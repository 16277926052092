import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'store/types';
import { NavigationScreenProps } from 'screens/navigation';
import { Color } from 'modules/styles/colors';
import { Spacing, FontSize, FontWeight, IconSize } from 'modules/styles/variables';
import Typography from 'components/UI/Typography';
import Box from 'components/UI/Layout/Box';
import { FlexBoxColumn, FlexBoxRow } from 'components/UI/Layout/FlexBox';
import useStyles from '../useStyles';
import CustomButton from '../FormElements/CustomButton';
import {
  updateRegistrationData,
  updateRegistrationStep,
  verifyPerson,
  getRegisterBrand,
  registerDataSelector,
  registerSubmittingSelector
} from 'store/register';

import { CheckboxProps, MuiBox, MuiTypography, MuiButton } from 'theme/material-ui';
import { ConnectCareCheckboxItem } from 'components/ConnectCare/ConnectCareCheckboxItem';
import { StyledAsterisk } from 'components/RequiredFieldsLegend';
import { Icon } from 'components/Icon';
import { Step } from 'store/pageActions/RegisterAccount/constants';
import styled from 'styled-components';
import { getUSPhoneNumber } from 'modules/utils/PhoneUtils';
import upperFirst from 'lodash/upperFirst';
import { AnyAction } from 'redux';
import { Form, Formik } from 'formik';

type OmittedProps = 'color' | 'onChange';
type MuiCheckboxProps = Omit<CheckboxProps, OmittedProps>;

export interface ConnectCareCheckboxItemProps extends MuiCheckboxProps {
  label?: React.ReactNode;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>, checked?: boolean) => void;
}

export interface Props extends NavigationScreenProps {
  registerData: {
    firstName: string;
    lastName: string;
    dateOfBirth: Date;
    gender: string;
    ethnicity: string;
    streetAddress: string;
    unitNumber: string;
    city: string;
    state: string;
    zip: string;
    country: string;
    email: string;
    phone: '';
    registrationStatus: string;
  };
  updateStep: (step: string) => void;
  verifyId: () => Promise<AnyAction>;
  isSubmitting: boolean;
}

const CardRow = styled(FlexBoxRow)`
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding-bottom: ${Spacing.medium}px;
`;

const CardCell = ({ label, value }) => (
  <FlexBoxColumn flex={8} maxWidth="100%" minHeight="46px" alignSelf="baseline">
    <Typography fontSize={FontSize.large}>{label}</Typography>
    <Typography
      style={{ wordWrap: 'break-word' }}
      fontSize={FontSize.large}
      fontWeight={FontWeight.bold}
      data-testid={label}
    >
      {value}
    </Typography>
  </FlexBoxColumn>
);

const ActionMinorButton = styled(MuiButton)`
  &.MuiButton-outlined {
    min-width: 0;
    padding: ${Spacing.small}px;
    &:not(:last-child) {
      margin-right: ${Spacing.medium}px;
    }
  }
`;

export const RegisterPersonalReview = ({
  registerData,
  updateStep,
  verifyId,
  isSubmitting
}: Props) => {
  const redirectBrand = getRegisterBrand();
  const classes = useStyles({ redirectBrand });

  const [acceptCheckbox, setAcceptCheckbox] = React.useState(false);

  const toggleAcceptCheckBox = () => setAcceptCheckbox(!acceptCheckbox);
  const handlePrevious = () => {
    updateStep(Step.PERSONAL);
  };

  const handleSubmit = () => {
    verifyId().then(res => {
      if (res.error) {
        updateStep(Step.ERROR);
      } else {
        updateStep(Step.VERIFY_EMAIL);
      }
    });
  };

  return (
    <>
      <Formik
        initialValues={{
          ...registerData
        }}
        onSubmit={handleSubmit}
      >
        {props => (
          <Form>
            <MuiTypography variant="h5">Review your information</MuiTypography>
            <MuiBox pb={Spacing.small} mx="auto" maxWidth={430}>
              <MuiTypography>
                {' '}
                For security purposes please verify your information below is correct.{' '}
              </MuiTypography>
            </MuiBox>
            <MuiBox bgcolor={Color.baseColor} flex={1}>
              <FlexBoxRow
                vSpacing={Spacing.large}
                hSpacing={Spacing.xLarge + Spacing.small}
                borderRadius={Spacing.small}
                justifyContent="space-between"
                flexWrap="wrap"
              >
                <CardRow>
                  <Typography tag="h3" lineHeight={FontSize.largeHeading}>
                    Personal Information
                  </Typography>
                  <MuiBox marginRight={`-${Spacing.large}px`}>
                    <ActionMinorButton
                      data-testid="edit-minor-button"
                      color="default"
                      variant="outlined"
                      onClick={handlePrevious}
                    >
                      <Icon name="edit" size={IconSize.base} color={Color.black} />
                    </ActionMinorButton>
                  </MuiBox>
                </CardRow>
                <CardRow>
                  <CardCell
                    label="Full Legal Name"
                    value={`${registerData.firstName} ${registerData.lastName}`}
                  />
                </CardRow>
                <CardRow>
                  <CardCell
                    label="DOB"
                    value={registerData.dateOfBirth?.toDate()?.toLocaleDateString()}
                  />
                </CardRow>
                <CardRow>
                  <CardCell label="Ethnicity" value={registerData.ethnicity} />
                  <CardCell label="Sex" value={upperFirst(registerData.gender)} />
                </CardRow>
                <CardRow>
                  <CardCell
                    label="Home Address"
                    value={
                      <>
                        <div>{registerData.streetAddress}</div>
                        <div>{`${registerData.city}, ${registerData.state}, ${registerData.zip}`}</div>
                      </>
                    }
                  />
                </CardRow>
                <CardRow>
                  <CardCell label="Email Address" value={registerData.email} />
                </CardRow>
                <CardRow>
                  <CardCell
                    label="Mobile Phone Number"
                    value={getUSPhoneNumber(registerData.phone)}
                  />
                </CardRow>
              </FlexBoxRow>
            </MuiBox>
            <MuiBox>
              <Box vSpacing={Spacing.medium}>
                <MuiBox pt={2}>
                  <ConnectCareCheckboxItem
                    className={classes.customTextField}
                    required
                    checked={acceptCheckbox}
                    onChange={toggleAcceptCheckBox}
                    inputProps={{ 'data-testid': 'agree-checkbox' }}
                  />
                  <MuiTypography display="inline">
                    I certify that this is my legal name and information.
                  </MuiTypography>
                  <StyledAsterisk />
                </MuiBox>
              </Box>
            </MuiBox>
            <MuiBox mb={3} textAlign="center">
              <CustomButton
                type="submit"
                label="Next"
                disabled={!acceptCheckbox && !(props.isValid && props.dirty)}
                isSubmitting={isSubmitting}
                aria-label="submit-button"
              />
              <MuiButton
                className={classes.customTextButton}
                variant="text"
                size="large"
                fullWidth
                aria-label="previous-button"
                onClick={handlePrevious}
                style={{ marginTop: 8 }}
              >
                Previous
              </MuiButton>
            </MuiBox>
          </Form>
        )}
      </Formik>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  registerData: registerDataSelector(state),
  isSubmitting: registerSubmittingSelector(state)
});
const mapDispatchToProps = {
  updateRegister: updateRegistrationData,
  verifyId: verifyPerson,
  updateStep: updateRegistrationStep
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterPersonalReview);
