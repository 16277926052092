import styled from 'styled-components';
import Screen from 'components/UI/Layout/Screen';

export const StyledScreen = styled(Screen)`
  height: 100%;
  overflow: auto;
  margin: auto;
  @media (min-width: 750px) {
    max-width: 85%;
    padding-top: 2%;
  }
`;
