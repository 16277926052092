import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import FlexBox from 'components/UI/Layout/FlexBox';
import Spinner from 'components/UI/Spinner/Spinner';
import { Svg } from 'components/UI/Svg';
import { Typography } from 'components/UI/Typography/styled';
import { Color } from 'modules/styles/colors';
import { FontWeight, IconSize, Spacing } from 'modules/styles/variables';
import TriageHeader from 'screens/TriageGyant/TriageGyantHeader';
import { getConditionDetail } from 'store/triageGyant/actions';
import {
  triageGyantConditionDetailIsFetchingSelector,
  triageGyantConditionDetailSelector
} from 'store/triageGyant/selectors';
import { TriageCondition } from 'store/triageGyant/types';
import { RootState } from 'store/types';
import { CursorMuiBox, MuiBox, MuiContainer, MuiTypography } from 'theme/material-ui';

export interface Props {
  getConditionDetail: typeof getConditionDetail;
  conditionDetail: TriageCondition;
  isFetching: boolean;
}

export function LikelyCauseDetail(props: Props) {
  const { history } = props;
  const { conditionId } = useParams();

  useEffect(() => {
    props.getConditionDetail(conditionId);
  }, [conditionId]);
  const { medicalName, infotext } = props.conditionDetail.data;

  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <MuiBox display="flex" flexDirection="column" bgcolor={Color.grayLight3} height="100%">
      <TriageHeader />
      {props.isFetching ? (
        <MuiBox height="100%" pt={Spacing.xLarge} display="flex" justifyContent="center">
          <Spinner />
        </MuiBox>
      ) : (
        <MuiContainer maxWidth="md">
          <MuiBox
            bgcolor={Color.foreColor}
            m={Spacing.small}
            px={Spacing.medium}
            paddingTop={Spacing.smallMedium}
            paddingBottom={Spacing.medium}
          >
            <CursorMuiBox>
              <FlexBox
                flexDirection="row"
                alignItems="center"
                vSpacingBottom={Spacing.xLarge}
                onClick={handleGoBack}
              >
                <Svg
                  name="ChevronLeft"
                  size={IconSize.small}
                  color={Color.secondary}
                  style={{
                    marginLeft: -Spacing.small,
                    marginRight: Spacing.medium
                  }}
                />
                <Typography tag="link">Back</Typography>
              </FlexBox>
            </CursorMuiBox>
            <MuiTypography variant="h5" fontWeight={FontWeight.semibold}>
              {medicalName}
            </MuiTypography>
            {infotext?.map((info: string) => (
              <MuiBox key={info} mt={Spacing.small}>
                <MuiTypography>{info}</MuiTypography>
              </MuiBox>
            ))}
          </MuiBox>
        </MuiContainer>
      )}
    </MuiBox>
  );
}

const mapDispatch = {
  getConditionDetail
};

const mapState = (state: RootState) => ({
  conditionDetail: triageGyantConditionDetailSelector(state),
  isFetching: triageGyantConditionDetailIsFetchingSelector(state)
});

export default connect(mapState, mapDispatch)(LikelyCauseDetail);
