import { AppName, getAppName } from 'modules/utils/ConfigUtils';

export const EVENT_LINK_EXPIRED = {
  title: 'Your link has expired',
  content: `To protect your medical information, this link was only available to use for 72 hours. For long term access to your medical records, try out ${getAppName(
    AppName.secondary,
    'the'
  )}`,
  buttonText: `Get ${getAppName(AppName.base, 'the', AppName.suffix1)}`
};

export const EVENT_LINK_LOGIN = {
  subTitle: 'Your health matters to us',
  content: ' To access test results please enter the information below.'
};

export const GFE_LINK_LOGIN = {
  subTitle: 'Here is your cost estimate',
  content: ' To access your estimate, please verify the following information:'
};

export const MY_HEALTH_PLUS_DOWNLOAD_SPLASH =
  'https://intermountainhealthcare.org/patient-tools/my-health-plus/';
