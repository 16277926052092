import React from 'react';
import { Box, Grid, Typography, IconButton } from '@material-ui/core';

import { MuiButton } from 'theme/material-ui';

import { Color } from 'modules/styles/colors';

import { Drawer, DrawerHeader, StyledList } from './styled';

import { convertToLowerKabobCase } from 'modules/utils/StringUtils';
import { Svg } from 'components/UI/Svg';
import { IconSize } from 'modules/styles/variables';

interface BookingDrawerHeaderProps {
  onClose: () => void;
  headerText: string;
}

function BookingDrawerHeader({ onClose, headerText }: BookingDrawerHeaderProps) {
  return (
    <DrawerHeader>
      <Box display="flex" alignItems="center">
        <Typography style={{ color: Color.textLight }}>{headerText}</Typography>
      </Box>
      <IconButton aria-label="close drawer" edge="end" onClick={onClose}>
        <Svg name="CloseIcon" color={Color.textDark} size={IconSize.base} />
      </IconButton>
    </DrawerHeader>
  );
}

interface BookingDrawerFooterProps {
  disabled?: boolean;
  onApply: () => void;
  onClear?: () => void;
  buttonOneText: string;
  buttonTwoText?: string;
  isFiltersDrawer: boolean;
}

function BookingDrawerFooter({
  buttonOneText,
  buttonTwoText,
  isFiltersDrawer,
  disabled,
  onApply,
  onClear
}: BookingDrawerFooterProps) {
  return (
    <Box p={2}>
      {isFiltersDrawer ? (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <MuiButton
              disabled={disabled}
              size="large"
              variant="contained"
              color="primary"
              onClick={onApply}
              fullWidth
            >
              {buttonOneText}
            </MuiButton>
          </Grid>
          <Grid item xs={12}>
            <MuiButton size="large" color="primary" onClick={onClear} fullWidth>
              {buttonTwoText}
            </MuiButton>
          </Grid>
        </Grid>
      ) : (
        <MuiButton
          disabled={disabled}
          size="large"
          variant="contained"
          color="primary"
          onClick={onApply}
          fullWidth
          data-testid={convertToLowerKabobCase(buttonOneText)}
        >
          {buttonOneText}
        </MuiButton>
      )}
    </Box>
  );
}

export interface BookingDrawerProps {
  open: boolean;
  disabled?: boolean;
  onClose: () => void;
  onApply: () => void;
  onClear?: () => void;
  width?: string | number;
  headerText: string;
  buttonOneText: string;
  buttonTwoText?: string;
  isFiltersDrawer: boolean;
  children: React.ReactNode;
}

function BookingDrawer({
  open,
  disabled,
  onClose,
  onApply,
  onClear,
  width,
  headerText,
  buttonOneText,
  buttonTwoText,
  isFiltersDrawer,
  children
}: BookingDrawerProps) {
  return (
    <Drawer anchor="right" open={open} width={width} onClose={onClose}>
      <BookingDrawerHeader onClose={onClose} headerText={headerText} />
      <StyledList disablePadding>{children}</StyledList>
      <BookingDrawerFooter
        buttonOneText={buttonOneText}
        buttonTwoText={buttonTwoText}
        isFiltersDrawer={isFiltersDrawer}
        onApply={onApply}
        onClear={onClear}
        disabled={disabled}
      />
    </Drawer>
  );
}

BookingDrawer.defaultProps = {
  isFiltersDrawer: true
};

export default BookingDrawer;
