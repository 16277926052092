import React from 'react';
import { History } from 'lib/history';
import { MuiBox } from 'theme/material-ui';
import CloseButton from 'components/UI/Button/CloseButton';
import Spacer from 'components/UI/Layout/Spacer';
import { Color } from 'modules/styles/colors';
import { Spacing } from 'modules/styles/variables';

export interface CostEstimatorPMMCProps {
  history: History;
}

const CostEstimatorPMMC = ({ history }: CostEstimatorPMMCProps) => {
  const onClose = () => {
    history.push('/u/get-care-now');
  };

  return (
    <MuiBox height="calc(100vh - 206px)">
      <CloseButton
        absolute
        onClick={onClose}
        color={Color.white}
        customBackgroundColor={Color.primary}
        padding={Spacing.xSmall}
        margin={`${Spacing.small}px`}
      />
      <Spacer size="small" />
      <iframe
        width="100%"
        height="100%"
        frameBorder="0"
        src={history.location.state?.eproUrl}
        title="Cost Estimator PMMC"
      ></iframe>
    </MuiBox>
  );
};

export default CostEstimatorPMMC;
