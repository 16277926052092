import React, { useRef } from 'react';
import { useField, FieldHookConfig } from 'formik';
import { ConnectCareSelect, ConnectCareSelectProps } from '../ConnectCareSelect';

export type ConnectCareFormSelectProps<T> = FieldHookConfig<any> & {
  label?: ConnectCareSelectProps<T>['label'];
  items: ConnectCareSelectProps<T>['items'];
  itemToLabel: ConnectCareSelectProps<T>['itemToLabel'];
  itemToValue: ConnectCareSelectProps<T>['itemToValue'];
  placeholder?: ConnectCareSelectProps<T>['placeholder'];
  disabled?: ConnectCareSelectProps<T>['disabled'];
  onChange?: ConnectCareSelectProps<T>['onChange'];
  onClose?: ConnectCareSelectProps<T>['onClose'];
  required?: ConnectCareSelectProps<T>['required'];
};

export function ConnectCareFormSelect<T>(props: ConnectCareFormSelectProps<T>) {
  const {
    label,
    placeholder,
    disabled,
    items,
    itemToValue,
    itemToLabel,
    onChange,
    onClose,
    required,
    ...rest
  } = props;
  const init = useRef(true);
  const [field, meta] = useField(rest);

  const error = meta.touched && !init.current && !!meta.error;
  const helperText = error && meta.error;
  const value = items.find(item => itemToValue(item) === field.value) || field.value;

  return (
    <ConnectCareSelect
      {...field}
      required={required}
      value={value}
      items={items}
      itemToValue={itemToValue}
      itemToLabel={itemToLabel}
      error={error}
      helperText={helperText || ' '}
      label={label}
      onChange={(e: React.ChangeEvent<any>) => {
        field.onChange(e);
        if (onChange) onChange(e);
      }}
      onClose={() => {
        init.current = false;
        if (onClose) onClose();
      }}
      disabled={disabled}
      placeholder={placeholder}
    />
  );
}

ConnectCareFormSelect.defaultProps = {
  itemToLabel: (item: any) => (item ? item.label : ''),
  itemToValue: (item: any) => (item ? item.value : '')
};
