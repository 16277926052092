import React from 'react';
import { connect } from 'react-redux';

import Stars from 'components/UI/Stars/Stars';
import { Color } from 'modules/styles/colors';
import * as selectors from 'store/findProvider/selectors';
import * as types from 'store/findProvider/types';
import { RootState } from 'store/types';
import {
  InfoColumn,
  InfoLabel,
  NumberOfComments,
  RatingsRow,
  StyledMuiDivider,
  StyledIconSubhead
} from './styled';
import Svg from 'components/UI/Svg/Svg';
import { MuiTypography } from 'theme/material-ui';
import FlexBox from 'components/UI/Layout/FlexBox';
import { FontSize } from 'modules/styles/variables';

export interface Props {
  provider: types.ProviderDetail;
  isMyPcpProvider: boolean;
  iconSize: number;
  onRatingsPressed?: () => void;
  exceptions: types.RolledUpException[];
  scrollToProviderComments: () => void;
  totalCount: number;
}

const ProviderBannerInfo = (props: Props) => {
  const {
    ratings,
    primarySpecialty,
    specialties,
    isMedicalGroupProvider,
    isSelectHealth,
    acceptingNewPatients,
    isStLukesProvider,
    primaryLocation,
    languages = [],
    lgbtqFlg
  } = props.provider;

  const { isMyPcpProvider, iconSize, totalCount = ratings?.totalCommentCount } = props;

  const showContactOfficeDisclaimer = isMyPcpProvider && isMedicalGroupProvider;
  const hasLanguages = !!(languages && languages.length);

  const renderProviderSubhead = ({ iconName, label }) => {
    return (
      <FlexBox flexDirection="row">
        <Svg
          name={iconName}
          height="15"
          width="15"
          color={Color.foreColor}
          style={{ marginTop: 2 }}
        />
        <MuiTypography color="white" fontSize={FontSize.base} style={{ marginLeft: 6 }}>
          {label}
        </MuiTypography>
      </FlexBox>
    );
  };

  const renderLanguages = (languages: types.ProviderDetail['languages']) => {
    return (
      <StyledIconSubhead iconName="language" size={iconSize} iconColor={Color.foreColor}>
        Speaks:&nbsp;
        {languages.map(
          (lang, idx) => `${lang.languageDescription + (idx + 1 !== languages.length ? ', ' : '')}`
        )}
      </StyledIconSubhead>
    );
  };

  return (
    <InfoColumn>
      <InfoLabel>
        {specialties && primarySpecialty
          ? [primarySpecialty, ...specialties].map(e => e.specialtyName).join(', ')
          : null}
      </InfoLabel>
      {renderProviderSubhead({
        iconName: 'DirectionFilled',
        label: primaryLocation && primaryLocation.name
      })}
      <RatingsRow
        display="flex"
        alignItems="center"
        alignContent="center"
        onClick={props.scrollToProviderComments}
      >
        <Stars ratings={ratings && ratings.overallRating} />
        {totalCount ? (
          <NumberOfComments>
            {totalCount} Comment{totalCount !== 1 ? 's' : ''}
          </NumberOfComments>
        ) : null}
      </RatingsRow>

      <StyledMuiDivider />
      {isMedicalGroupProvider &&
        renderProviderSubhead({
          iconName: 'CheckCircleFilled',
          label: 'Intermountain Medical Group'
        })}
      {isSelectHealth &&
        renderProviderSubhead({
          iconName: 'CheckCircleFilled',
          label: 'SelectHealth Provider'
        })}
      {isMyPcpProvider &&
        renderProviderSubhead({
          iconName: 'CheckCircleFilled',
          label: 'Primary Care Physician'
        })}
      {isStLukesProvider &&
        renderProviderSubhead({
          iconName: 'CheckCircleFilled',
          label: "St. Luke's Provider"
        })}
      {showContactOfficeDisclaimer &&
        renderProviderSubhead({
          iconName: 'InfoIconFilled',
          label: ' Please contact provider’s office directly to change your Primary Care Physician'
        })}
      {acceptingNewPatients &&
        renderProviderSubhead({
          iconName: 'CheckCircleFilled',
          label: 'Accepting New Patients'
        })}
      {hasLanguages && renderLanguages(languages)}
      {lgbtqFlg
        ? renderProviderSubhead({
            iconName: 'LgbtqIcon',
            label: 'LGBTQ+ Clinically Competent'
          })
        : null}
    </InfoColumn>
  );
};

const mapStateToProps = (state: RootState) => ({
  isMyPcpProvider: selectors.isProviderPcpProviderSelector(state),
  exceptions: selectors.providerRolledUpExceptionsSelector(state),
  totalCount: selectors.totalCommentsSelector(state)
});

export default connect(mapStateToProps)(ProviderBannerInfo);
