import React from 'react';
import {
  MuiBox,
  MuiPaper,
  MuiGrid,
  MuiTypography,
  MuiCard,
  MuiCardContent
} from 'theme/material-ui';
import Banner from 'components/UI/Banner/Banner';
import { PaymentMethod, VisitSummary } from 'store/amwell/types';
import { Color } from 'modules/styles/colors';

import useStyles from '../../useStyles';
import ConnectCareVisitSummaryEndFeedback from './ConnectCareVisitSummaryEndFeedback';
import ConnectCareVisitSummaryEndPrescriptions from './ConnectCareVisitSummaryEndPrescriptions';
import ConnectCareVisitSummaryEndPharmacy from './ConnectCareVisitSummaryEndPharmacy';

export interface Props {
  visitSummary: VisitSummary;
  paymentMethod: PaymentMethod;
}

export function ConnectCareVisitSummaryEnd(props: Props) {
  const { visitSummary, paymentMethod } = props;
  const classes = useStyles();
  const zeroCost = visitSummary.visitCost.zeroCostVisit;

  return (
    <MuiGrid container spacing={3}>
      <MuiGrid item xs>
        <MuiCard>
          <Banner
            align="center"
            message="Thank You"
            subMessage={
              <MuiBox width="auto" pb={3}>
                <MuiTypography align="center" color={Color.white}>
                  Your visit with {visitSummary.assignedProvider.fullName} has ended
                </MuiTypography>
              </MuiBox>
            }
          />
          <MuiCardContent>
            <MuiGrid container spacing={4} alignItems="center" justify="center">
              <MuiGrid item xs={8}>
                <MuiPaper variant="outlined" className={classes.paper}>
                  <MuiGrid container spacing={2}>
                    <MuiGrid item xs={12}>
                      <MuiTypography align="center" variant="h5">
                        ${visitSummary.visitCost.expectedConsumerCopayCost.toFixed(2)}
                      </MuiTypography>
                    </MuiGrid>
                    <MuiGrid item xs={12}>
                      <MuiTypography align="center">
                        {zeroCost
                          ? 'Has been charged'
                          : `Has been charged to the card ending in ${paymentMethod?.lastDigits}`}
                      </MuiTypography>
                    </MuiGrid>
                    <MuiGrid item xs={12}>
                      <MuiTypography align="center" variant="subtitle1">
                        Charge will appear on your statement as 'myTelehealth'
                      </MuiTypography>
                    </MuiGrid>
                  </MuiGrid>
                </MuiPaper>
              </MuiGrid>
              {visitSummary.providerEntries.prescriptions?.length ? (
                <>
                  <MuiGrid item xs={8}>
                    <ConnectCareVisitSummaryEndPrescriptions
                      prescriptions={visitSummary.providerEntries.prescriptions}
                    />
                  </MuiGrid>
                  <MuiGrid item xs={8}>
                    <ConnectCareVisitSummaryEndPharmacy pharmacy={visitSummary.pharmacy} />
                  </MuiGrid>
                </>
              ) : null}
            </MuiGrid>
          </MuiCardContent>
        </MuiCard>
      </MuiGrid>
      <MuiGrid item>
        <ConnectCareVisitSummaryEndFeedback
          feedbackQuestion={visitSummary.consumerFeedbackQuestion}
        />
      </MuiGrid>
    </MuiGrid>
  );
}

export default ConnectCareVisitSummaryEnd;
