import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import dayjs from 'dayjs';
import styled from 'styled-components';

import { Grid } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import { KeyboardDatePicker } from '@material-ui/pickers';

import * as covidActions from 'store/CovidScreening/actions';
import {
  CovidScreeningPatientInfoSelector,
  CovidScreeningOTPValidatedSelector
} from 'store/CovidScreening/selectors';
import { Patient } from 'store/CovidScreening/types';
import { RootState } from 'store/types';
import { AuthType } from 'store/authentication/types';
import { languageSelector } from 'store/consumerPreferences/selectors';

import { CovidTitles } from 'lib/constants/covidScreening';
import { getAge } from 'lib/booking/utils';
import {
  dfdDefaultTheme,
  MuiBox,
  MuiDivider,
  MuiMenuItem,
  MuiSelect,
  MuiTypography,
  MuiCheckbox
} from 'theme/material-ui';
import {
  SEX_OPTIONS,
  SexOptionsType,
  StateOptionsType,
  FormValues,
  submitGuestPersonalInfoValidationLocalized
} from 'modules/constants/covidScreening/personalInfo';
import { LOCATION_STATES } from 'modules/constants/LocationStates';
import { Color } from 'modules/styles/colors';
import { maskFullDate, maskPhone, maskZip } from 'modules/utils/MaskUtils';
import { ButtonsNavigation } from 'screens/CovidTestGuest/components';

import FieldError from 'screens/Register/FormElements/FieldError';
import CustomTextField from 'screens/Register/FormElements/CustomTextField';
import RequiredLabel from 'components/UI/Labels/RequiredLabel';
import FlexBox from 'components/UI/Layout/FlexBox';
import Spacer from 'components/UI/Layout/Spacer';
import { InfoTooltip } from 'components/InfoTooltip';
import SmallText from 'components/UI/Typography/SmallText';
import OTPValidationDialog from 'components/CovidTest/PersonalInformation/OTPValidationDialog';

import useStyles from '../../CovidScreening/Components/CovidTestButton/useStyles';
import CovidGuestGuard from '../navigation/CovidGuestLeavingGuard';
import { useLanguageSwitcher } from 'lib/hooks/useLanguageSwitcher';
import { translations } from 'lib/constants/translations/screens/covidGuest/personalInfo';
import { CovidTestGuestFooter } from '../components';
import { usePersonalInformation } from './hooks';
import { ValidationType } from './types';
import { NavigationScreenProps } from 'screens/navigation';

const standardWidth = 'fullwidth';
const standardHeight = `
height: 42px;
min-height: 42px;`;

const StyledSelect = styled(MuiSelect)`
  ${standardWidth}
  ${standardHeight}
  select {
    padding: 0px 32px 0px 7px;
    ${standardHeight}
  }
`;

export interface CovidPersonalInfoScreenProps extends NavigationScreenProps {
  selectUser: Patient;
  setPatient: typeof covidActions.setPatient;
  setOTPValidation: typeof covidActions.setOTPValidation;
  sendOTP: Function;
  verifyOTP: Function;
  isOTPValidated: boolean;
  currentLanguage: string;
}

export function CovidPersonalInfoScreen({
  setPatient,
  setOTPValidation,
  history,
  selectUser,
  sendOTP,
  verifyOTP,
  isOTPValidated,
  currentLanguage
}: CovidPersonalInfoScreenProps) {
  const classes = useStyles();
  const screenText = useLanguageSwitcher(translations);
  const [sendError, setSendError] = useState('');
  const [validated, setValidated] = useState('');
  const [showOTPValidationDialog, setShowOTPValidationDialog] = useState(false);

  const {
    covidContinueBtnClicked,
    covidOTPVerifyBtnClicked,
    covidScreeningNextBtn,
    covidScreeningPreviousBtn,
    sendOTPError,
    updateValidationType,
    validationType
  } = usePersonalInformation({});

  const homeAddress = selectUser?.addresses?.find(el => el.type === 'HOMEADDR');

  const initialValues = {
    userFirstName: selectUser?.names?.[0]?.givenName || '',
    userMiddleName: selectUser?.names?.[0]?.middleName || '',
    userLastName: selectUser?.names?.[0]?.familyName || '',
    userDateOfBirth: maskFullDate(selectUser?.dateOfBirth) || '',
    userSSNDigits: selectUser?.ssn?.substring(-4),
    userSex: selectUser?.genderType?.toUpperCase() || '',
    userAddressLine1: homeAddress?.line1 || '',
    userAddressLine2: homeAddress?.line2 || '',
    userCity: homeAddress?.city || '',
    userState: homeAddress?.state || '',
    userZip: maskZip(homeAddress?.zip) || '',
    userEmail: selectUser?.emails[0]?.address || '',
    userCellNumber: maskPhone(
      selectUser?.phones?.length
        ? selectUser?.phones[0].areaCode + selectUser?.phones[0].phoneNumber
        : ''
    ),
    userHasNoCellNumber: selectUser?.userHasNoCellNumber || false,
    userOtherPhoneNumber: maskPhone(selectUser?.alternateNumber || '')
  };

  const saveToStore = async (values: FormValues, isNumberVerified: boolean) => {
    const updatedPatientsData = {
      dateOfBirth: dayjs(values?.userDateOfBirth).format('MM/DD/YYYY'),
      genderType: values?.userSex?.toUpperCase(),
      ssn: values?.userSSNDigits,
      alternateNumber: values?.userOtherPhoneNumber,
      userHasNoCellNumber: values?.userHasNoCellNumber,
      names: [
        {
          givenName: values?.userFirstName,
          familyName: values?.userLastName,
          middleName: values?.userMiddleName
        }
      ],
      addresses: [
        {
          line1: values?.userAddressLine1,
          line2: values?.userAddressLine2,
          city: values?.userCity,
          state: values?.userState,
          zip: values?.userZip,
          type: 'HOMEADDR'
        }
      ],
      phones: [
        {
          areaCode: values?.userCellNumber.replace(/[^a-zA-Z0-9]/g, '').substring(0, 3),
          phoneNumber: values?.userCellNumber.replace(/[^a-zA-Z0-9]/g, '').substring(3, 10),
          countryCode: null,
          phoneType: 'CELLPHONE',
          verified: isNumberVerified ?? false
        },
        {
          areaCode: values?.userOtherPhoneNumber.replace(/[^a-zA-Z0-9]/g, '').substring(0, 3),
          phoneNumber: values?.userOtherPhoneNumber.replace(/[^a-zA-Z0-9]/g, '').substring(3, 10),
          countryCode: null,
          phoneType: 'ALTPHONE'
        }
      ],
      emails: [
        {
          address: values?.userEmail,
          type: 'HOMEEMAIL'
        }
      ]
    };
    setPatient(updatedPatientsData);
  };

  const handleSubmit = (values: FormValues) => {
    if (isOTPValidated && values.userHasNoCellNumber === selectUser.userHasNoCellNumber) {
      const isSamePhoneNumber =
        selectUser.phones?.[0].areaCode + selectUser.phones?.[0].phoneNumber ===
        values?.userCellNumber.replace(/[^a-zA-Z0-9]/g, '');
      const isSameEmail = selectUser.emails?.[0].address === values.userEmail;

      if ((values.userCellNumber && isSamePhoneNumber) || (!values.userCellNumber && isSameEmail)) {
        handleContinue('', values);
        saveToStore(values, true);
        return;
      }
    }

    handleSendOTP(values);
  };

  const handleContinue = (type: string, values: FormValues) => {
    if (type === 'next') {
      covidScreeningNextBtn({
        has_cell_phone: values?.userHasNoCellNumber ? 'No' : 'Yes'
      });
    } else {
      covidContinueBtnClicked({
        title: CovidTitles.PERSONAL_INFORMATION,
        authentication_type: AuthType.UNAUTHENTICATED,
        OTP_validation_type: validationType,
        has_cell_phone: values?.userHasNoCellNumber ? 'No' : 'Yes'
      });
    }

    setOTPValidation(true);
    history.push('/guest-home/covid-screen/guest-covid-insurance');
  };

  const onCancelClick = () => {
    history.goBack();

    covidScreeningPreviousBtn({
      title: CovidTitles.PERSONAL_INFORMATION,
      authentication_type: AuthType.UNAUTHENTICATED
    });
  };

  const handleOTPVerification = async (values: FormValues, pin: number) => {
    const res = await verifyOTP(values, pin, currentLanguage);

    if (res.type === 'COVID.VERIFY_OTP_FAIL') {
      setValidated('FAIL');

      covidOTPVerifyBtnClicked({
        title: CovidTitles.PERSONAL_INFORMATION,
        authentication_type: AuthType.UNAUTHENTICATED,
        covid19_OTP_validation: 'Failed',
        errorCode: res?.error?.response?.data?.statusCode,
        errorMessage: res?.error?.response?.data?.statusDescription,
        OTP_validation_type: validationType
      });

      return;
    }

    setValidated('SUCCESS');
    setOTPValidation(true);
    saveToStore(values, true);
    covidOTPVerifyBtnClicked({
      title: CovidTitles.PERSONAL_INFORMATION,
      authentication_type: AuthType.UNAUTHENTICATED,
      covid19_OTP_validation: 'Success',
      OTP_validation_type: validationType
    });
  };

  const handleSendOTP = async (values: FormValues) => {
    if (
      (selectUser?.phones[0]?.verified &&
        selectUser?.phones[0]?.areaCode.concat(selectUser?.phones[0]?.phoneNumber) ===
          values?.userCellNumber.replace(/[^a-zA-Z0-9]/g, '')) ||
      values?.userHasNoCellNumber
    ) {
      saveToStore(values, true);
    }

    setSendError('');
    setValidated('');

    const res = await sendOTP(values, currentLanguage);

    if (res.type !== 'COVID.SEND_PIN_OTP_FAIL') {
      return setShowOTPValidationDialog(true);
    }

    sendOTPError({
      title: CovidTitles.PERSONAL_INFORMATION,
      authentication_type: AuthType.UNAUTHENTICATED,
      OTP_validation_type: validationType,
      covid19_OTP_validation: 'Failed',
      errorCode: res?.error?.response?.data?.errorCode,
      errorDescription: res?.error?.response?.data?.errorDescription,
      errorMessage: res?.error?.response?.data?.errors[0]?.message
    });

    // maxPinUnauthError = IDW005 & status 500 & error 'MAXIMUM_PIN_RESENDS_EXCEEDED:Maximum number of PIN re-sends has been exceeded'
    // phoneError = IDW005 & status 500
    // emailError = IDW005 & status 422
    if (res.error?.response?.data?.errorCode === 'IDW005') {
      if (res.error?.response?.status === 500) {
        if (
          res.error?.response?.data?.errors[0] ===
          'MAXIMUM_PIN_RESENDS_EXCEEDED:Maximum number of PIN re-sends has been exceeded'
        ) {
          setSendError('maxPinUnauthError');
        } else {
          setSendError('phoneError');
        }
      } else if (res.error?.response?.status === 422) {
        setSendError('emailError');
      } else {
        setSendError('otherError');
      }
    }

    return setShowOTPValidationDialog(true);
  };

  const isValidData =
    initialValues.userFirstName &&
    initialValues.userLastName &&
    initialValues.userDateOfBirth &&
    initialValues.userSex &&
    initialValues.userAddressLine1 &&
    initialValues.userCity &&
    initialValues.userState &&
    initialValues.userZip &&
    initialValues.userEmail &&
    initialValues.userHasNoCellNumber
      ? initialValues.userOtherPhoneNumber
      : initialValues.userCellNumber;

  return (
    <>
      <CovidGuestGuard />
      <Container maxWidth="md">
        <Spacer size="medium" />

        <MuiBox>
          <MuiTypography variant="h4">{screenText.title}</MuiTypography>
        </MuiBox>

        <Spacer size="small" />

        <MuiBox>
          <MuiDivider />
        </MuiBox>

        <Formik
          enableReinitialize="true"
          validateOnMount
          initialValues={initialValues}
          validationSchema={submitGuestPersonalInfoValidationLocalized(screenText)}
          isInitialValid={isValidData}
        >
          {({
            values,
            isValid,
            errors,
            touched,
            handleChange,
            handleBlur,
            setFieldTouched,
            setFieldValue
          }) => {
            return (
              <>
                <Spacer size="small" />
                <FlexBox flexDirection="row">
                  <MuiTypography color={Color.offBlack}>
                    {screenText.COVID_SCREENING_PERSONAL_INFO_BODY_1}
                    <b>{screenText.COVID_SCREENING_PERSONAL_INFO_BODY_2}</b>
                    {screenText.COVID_SCREENING_PERSONAL_INFO_BODY_2_OTP}
                    {screenText.COVID_SCREENING_PERSONAL_INFO_BODY_3B_OTP}
                  </MuiTypography>
                </FlexBox>

                <Spacer size="medium" />

                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <RequiredLabel labelText={screenText.field_1} />
                    <CustomTextField
                      name="userFirstName"
                      data-testid="user-first-name"
                      variant="outlined"
                      value={values?.userFirstName}
                      onChange={handleChange('userFirstName')}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <RequiredLabel hideIndicator labelText={screenText.field_2} />
                    <CustomTextField
                      name="userMiddleName"
                      data-testid="user-middle-name"
                      variant="outlined"
                      value={values?.userMiddleName}
                      onChange={handleChange('userMiddleName')}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <RequiredLabel labelText={screenText.field_3} />
                    <CustomTextField
                      name="userLastName"
                      data-testid="user-last-name"
                      variant="outlined"
                      value={values?.userLastName}
                      onChange={handleChange('userLastName')}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <RequiredLabel labelText={screenText.field_4} />
                    <KeyboardDatePicker
                      disableToolbar
                      openTo="year"
                      views={['year', 'month', 'date']}
                      variant="inline"
                      inputVariant="outlined"
                      format="MM/DD/YYYY"
                      value={values?.userDateOfBirth || null}
                      onChange={value => {
                        setFieldTouched('userDateOfBirth');
                        setFieldValue('userDateOfBirth', value, true);
                      }}
                      onBlur={handleBlur('userDateOfBirth')}
                      maxDate={new Date()}
                      name="userDateOfBirth"
                      data-testid="user-date-of-birth"
                      placeholder={screenText.field_4_placeholder}
                      required
                      autoOk
                      fullWidth
                      helperText={null}
                      error={touched.userDateOfBirth && errors.userDateOfBirth}
                    />
                    {touched.userDateOfBirth && errors.userDateOfBirth && (
                      <FieldError error={errors.userDateOfBirth} />
                    )}
                    {getAge(values.userDateOfBirth) < 2}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <RequiredLabel hideIndicator labelText={screenText.field_6} />
                    <CustomTextField
                      data-testid="personal-info-ssn"
                      name="userSSNDigits"
                      variant="outlined"
                      value={values?.userSSNDigits}
                      onChange={handleChange('userSSNDigits')}
                      mask="ssnFourDigits"
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <FlexBox width="100%">
                      <FlexBox flexDirection="row" justifyContent="flex-start" alignItems="center">
                        <RequiredLabel labelText={screenText.field_5} />
                        <InfoTooltip>{screenText.field_5_tooltip}</InfoTooltip>
                      </FlexBox>
                      <StyledSelect
                        value={values.userSex}
                        data-testid="user-sex-field"
                        onChange={handleChange('userSex')}
                        variant="outlined"
                      >
                        {SEX_OPTIONS.map((option: SexOptionsType) => (
                          <MuiMenuItem
                            style={{ whiteSpace: 'normal' }}
                            key={option.label}
                            value={option.value}
                            data-testid={option.label}
                          >
                            {screenText[option.value]}
                          </MuiMenuItem>
                        ))}
                      </StyledSelect>
                    </FlexBox>
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <RequiredLabel labelText={screenText.field_7} />
                    <CustomTextField
                      name="userAddressLine1"
                      data-testid="user-addressline1"
                      variant="outlined"
                      value={values?.userAddressLine1}
                      onChange={handleChange('userAddressLine1')}
                      onBlur={() => {
                        setFieldTouched('userAddressLine1');
                      }}
                      required
                      maxLength={50}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <RequiredLabel hideIndicator labelText={screenText.field_8} />
                    <CustomTextField
                      name="userAddressLine2"
                      data-testid="user-addressline2"
                      variant="outlined"
                      value={values?.userAddressLine2}
                      onChange={handleChange('userAddressLine2')}
                      maxLength={50}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <RequiredLabel labelText={screenText.field_9} />
                    <CustomTextField
                      name="userCity"
                      data-testid="user-city"
                      variant="outlined"
                      value={values?.userCity}
                      onChange={handleChange('userCity')}
                      onBlur={() => {
                        setFieldTouched('userCity');
                      }}
                      required
                      maxLength={30}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FlexBox width="100%">
                      <RequiredLabel labelText={screenText.field_10} />
                      <StyledSelect
                        variant="outlined"
                        data-testid="user-state"
                        value={values?.userState}
                        onChange={handleChange('userState')}
                      >
                        {LOCATION_STATES.map((option: StateOptionsType) => (
                          <MuiMenuItem
                            style={{ whiteSpace: 'normal' }}
                            key={option.label}
                            value={option.value}
                            data-testid={option.label}
                          >
                            {option.label}
                          </MuiMenuItem>
                        ))}
                      </StyledSelect>
                      {errors?.userState && touched.userState ? (
                        <SmallText color="red">{errors?.userState}</SmallText>
                      ) : null}
                    </FlexBox>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <RequiredLabel labelText={screenText.field_11} />
                    <CustomTextField
                      name="userZip"
                      data-testid="user-zip-field"
                      variant="outlined"
                      value={values?.userZip}
                      onChange={handleChange('userZip')}
                      mask="zip"
                      required
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <RequiredLabel
                      hideIndicator={values.userHasNoCellNumber}
                      labelText={screenText.field_13}
                    />
                    <CustomTextField
                      name="userCellNumber"
                      disabled={values?.userHasNoCellNumber}
                      variant="outlined"
                      data-testid="cell-phone-number"
                      value={values?.userCellNumber}
                      onChange={handleChange('userCellNumber')}
                      onBlur={() => {
                        setFieldTouched('userCellNumber');
                      }}
                      mask="phone"
                    />
                    <MuiBox display="flex" flexDirection="row" alignItems="center">
                      <MuiCheckbox
                        color="primary"
                        data-testid="no-cell-phone-number"
                        onChange={() => {
                          setFieldValue('userHasNoCellNumber', !values.userHasNoCellNumber);
                          setFieldValue('userCellNumber', '', false);

                          if (values.userHasNoCellNumber)
                            return updateValidationType(ValidationType.SMS);

                          return updateValidationType(ValidationType.EMAIL);
                        }}
                        checked={values?.userHasNoCellNumber}
                        style={{ paddingLeft: 0 }}
                      />
                      {screenText.checkbox}
                    </MuiBox>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <RequiredLabel
                      hideIndicator={!values.userHasNoCellNumber}
                      labelText={screenText.field_14}
                    />
                    <CustomTextField
                      name="userOtherPhoneNumber"
                      variant="outlined"
                      value={values?.userOtherPhoneNumber}
                      data-testid="user-other-phone-number"
                      onChange={handleChange('userOtherPhoneNumber')}
                      onBlur={() => {
                        setFieldTouched('userOtherPhoneNumber');
                      }}
                      mask="phone"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <RequiredLabel labelText={screenText.field_12} />
                    <CustomTextField
                      name="userEmail"
                      variant="outlined"
                      data-testid="user-email"
                      value={values?.userEmail}
                      onChange={handleChange('userEmail')}
                      onBlur={() => {
                        setFieldTouched('userEmail');
                      }}
                      required
                      maxLength={30}
                    />
                  </Grid>
                </Grid>
                <Spacer size="medium" />
                <MuiBox className={classes.steppersFooter}>
                  {showOTPValidationDialog && (
                    <OTPValidationDialog
                      closeAction={() => setShowOTPValidationDialog(false)}
                      continueAction={() => handleContinue('', values)}
                      sendAction={() => handleSendOTP(values)}
                      sendErrorAction={() => setSendError('')}
                      sendError={sendError}
                      userCellNumber={values.userCellNumber}
                      userEmail={values.userEmail}
                      userHasNoCellNumber={values.userHasNoCellNumber}
                      validateAction={(pin: string) => handleOTPVerification(values, pin)}
                      validated={validated}
                      validationType={validationType}
                    >
                      <MuiBox mb={2}>
                        <MuiTypography
                          color={Color.primary}
                          fontSize={16}
                          fontWeight={dfdDefaultTheme.typography.fontWeightMedium}
                        >
                          {values.userHasNoCellNumber
                            ? screenText.OTP_EMAIL_TITLE
                            : screenText.OTP_PHONE_TITLE}
                        </MuiTypography>
                      </MuiBox>
                      <MuiBox mb={2}>
                        <MuiBox display="flex" width="100%" flexDirection="row">
                          <MuiTypography
                            fontSize={16}
                            fontWeight={dfdDefaultTheme.typography.fontWeightRegular}
                            display="inline"
                          >
                            {screenText.OTP_RECEIVE}
                            <MuiTypography
                              style={{
                                marginRight: 3,
                                marginLeft: 3,
                                wordBreak: 'break-word'
                              }}
                              fontSize={16}
                              fontWeight={dfdDefaultTheme.typography.fontWeightBold}
                              display="inline"
                            >
                              {values.userHasNoCellNumber
                                ? values.userEmail
                                : values.userCellNumber}
                            </MuiTypography>
                            {screenText.OTP_PROCEED}
                          </MuiTypography>
                        </MuiBox>
                      </MuiBox>
                      <MuiBox textAlign="center" mb={2}>
                        {validated === 'FAIL' && (
                          <MuiTypography fontSize={16} color="red">
                            {screenText.OTP_INCORRECT}
                          </MuiTypography>
                        )}
                      </MuiBox>
                    </OTPValidationDialog>
                  )}
                </MuiBox>

                <ButtonsNavigation
                  cancelTestId="personal-info-previous-btn"
                  nextTestId="personal-info-next-btn"
                  disabledNextButton={!isValid}
                  onCancelClick={() => onCancelClick(values)}
                  onNextClick={() => handleSubmit(values)}
                />
              </>
            );
          }}
        </Formik>
      </Container>
      <CovidTestGuestFooter title={CovidTitles.PERSONAL_INFORMATION} />
    </>
  );
}

const mapStateToProps = (state: RootState) => ({
  selectUser: CovidScreeningPatientInfoSelector(state),
  isOTPValidated: CovidScreeningOTPValidatedSelector(state),
  currentLanguage: languageSelector(state)
});

const mapDispatchToProps = (dispatch: Function) => ({
  sendOTP: (values: FormValues, currentLanguage: string) =>
    dispatch(covidActions.sendOTP(values, currentLanguage)),
  setOTPValidation: (value: boolean) => dispatch(covidActions.setOTPValidation(value)),
  setPatient: (Patient: object) => dispatch(covidActions.setPatient(Patient)),
  verifyOTP: (values: FormValues, pin: number, currentLanguage: string) =>
    dispatch(covidActions.verifyOTP(values, pin, currentLanguage))
});

export default connect(mapStateToProps, mapDispatchToProps)(CovidPersonalInfoScreen);
