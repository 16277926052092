import React from 'react';
import { SvgProps } from 'components/UI/Svg/Svg';

export default (props?: SvgProps) => {
  return {
    svg: (
      <g>
        <g style={{ fill: 'none' }}>
          <path
            d="M12 14.531L7.52602 9.863C7.13225 9.47068 6.87203 8.96433 6.78228 8.41578C6.69253 7.86723 6.77781 7.30435 7.02602 6.807C7.21365 6.43179 7.48777 6.10655 7.82578 5.85809C8.1638 5.60964 8.55603 5.44508 8.97013 5.37798C9.38424 5.31088 9.80836 5.34317 10.2075 5.47218C10.6067 5.60119 10.9695 5.82323 11.266 6.12L11.996 6.85L12.727 6.12C13.0235 5.82323 13.3863 5.60119 13.7855 5.47218C14.1847 5.34317 14.6088 5.31088 15.0229 5.37798C15.437 5.44508 15.8292 5.60964 16.1672 5.85809C16.5053 6.10655 16.7794 6.43179 16.967 6.807C17.2148 7.30445 17.2998 7.86722 17.2101 8.41567C17.1203 8.96411 16.8604 9.47044 16.467 9.863L12 14.531Z"
            stroke="black"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M19.059 23.105L12.882 18.613C12.6257 18.4267 12.3169 18.3263 12 18.3263C11.6831 18.3263 11.3743 18.4267 11.118 18.613L4.941 23.105C4.82922 23.1863 4.69716 23.2351 4.5594 23.246C4.42164 23.2569 4.28354 23.2296 4.16033 23.167C4.03712 23.1044 3.9336 23.009 3.86119 22.8913C3.78878 22.7736 3.7503 22.6382 3.75 22.5V2.25C3.75 1.85218 3.90804 1.47064 4.18934 1.18934C4.47064 0.908035 4.85218 0.75 5.25 0.75H18.75C19.1478 0.75 19.5294 0.908035 19.8107 1.18934C20.092 1.47064 20.25 1.85218 20.25 2.25V22.5C20.2497 22.6382 20.2112 22.7736 20.1388 22.8913C20.0664 23.009 19.9629 23.1044 19.8397 23.167C19.7165 23.2296 19.5784 23.2569 19.4406 23.246C19.3028 23.2351 19.1708 23.1863 19.059 23.105Z"
            stroke={props?.stroke ?? 'black'}
            strokeWidth={props?.strokeWidth ?? '1.5'}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12 14.531L7.52602 9.863C7.13225 9.47068 6.87203 8.96433 6.78228 8.41578C6.69253 7.86723 6.77781 7.30435 7.02602 6.807C7.21365 6.43179 7.48777 6.10655 7.82578 5.85809C8.1638 5.60964 8.55603 5.44508 8.97013 5.37798C9.38424 5.31088 9.80836 5.34317 10.2075 5.47218C10.6067 5.60119 10.9695 5.82323 11.266 6.12L11.996 6.85L12.727 6.12C13.0235 5.82323 13.3863 5.60119 13.7855 5.47218C14.1847 5.34317 14.6088 5.31088 15.0229 5.37798C15.437 5.44508 15.8292 5.60964 16.1672 5.85809C16.5053 6.10655 16.7794 6.43179 16.967 6.807C17.2148 7.30445 17.2998 7.86722 17.2101 8.41567C17.1203 8.96411 16.8604 9.47044 16.467 9.863L12 14.531Z"
            fill={props?.fill ?? 'black'}
            stroke={props?.stroke ?? 'black'}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
    ),
    viewBox: '0 0 24 24'
  };
};
