import React from 'react';
import { useHistory } from 'react-router';
import { FontSize, IconSize } from 'modules/styles/variables';
import { Color } from 'modules/styles/colors';
import { convertToLowerKabobCase } from 'modules/utils/StringUtils';

import { Svg } from 'components/UI/Svg/';
import { MuiBox, MuiTypography } from 'theme/material-ui';
import { AnalyticsEvent } from 'services/AnalyticsService';
import useNavigationAnalytics from 'hooks/useNavigationAnalytics';
import { QuickLinkBtn } from '../styled';

export interface QuicklinkData {
  label?: string;
  link?: string;
  params?: { [params: string]: unknown };
  webPath?: string;
}
interface QuicklinkProps {
  iconName: string;
  label: string;
  link: string;
  labelWidth?: number;
  onQuicklinkClicked?: (quicklinkData: QuicklinkData) => void;
  analyticsTitle: string;
}

export const Quicklink = ({
  iconName,
  label,
  link,
  labelWidth,
  onQuicklinkClicked,
  analyticsTitle
}: QuicklinkProps) => {
  const history = useHistory();
  const handleAnalytics = useNavigationAnalytics(AnalyticsEvent.DashboardQuicklinksClicked, {
    Quicklinks_title: analyticsTitle
  });

  const handleClick = () => {
    handleAnalytics();
    if (onQuicklinkClicked) {
      return onQuicklinkClicked({ label, link });
    }
    return history.push(link);
  };

  return (
    <QuickLinkBtn
      onClick={handleClick}
      data-testid={convertToLowerKabobCase(label, `-quick-link`)}
      style={{ borderRadius: 5 }}
    >
      <MuiBox height={74} display="flex" flexDirection="column" alignItems="center">
        <Svg name={iconName} set="assets" size={IconSize.navMenu} color={Color.secondary} />
        <MuiTypography
          align="center"
          fontSize={FontSize.small}
          color={Color.textDark}
          style={{
            width: `${labelWidth || '90'}px`,
            maxWidth: '90px',
            marginTop: '10px',
            letterSpacing: '0.29px',
            lineHeight: '14px'
          }}
        >
          {label}
        </MuiTypography>
      </MuiBox>
    </QuickLinkBtn>
  );
};

export default Quicklink;
