import React from 'react';
import { MuiButton, MuiBox, MuiTypography, MuiModal, MuiContainer } from 'theme/material-ui';
import { Spacing, FontSize, BorderRadius } from 'modules/styles/variables';
import { Color } from 'modules/styles/colors';
import FlexBox from 'components/UI/Layout/FlexBox';

export interface MammogramCallModalProps {
  onClose: () => void;
  isOpen: boolean;
}

export const MammogramCallModal = (props: MammogramCallModalProps) => {
  const { onClose, isOpen } = props;
  return (
    <MuiModal onClose={onClose} open={isOpen} disableEnforceFocus disableAutoFocus>
      <MuiContainer>
        <FlexBox
          height={300}
          borderRadius={BorderRadius.base}
          spacing="30px 60px"
          alignItems="center"
          justifyContent="center"
          backgroundColor={Color.white}
        >
          <MuiTypography align="center" fontSize={FontSize.large} color={Color.primary}>
            In order to schedule a Mammogram appointment please contact your preferred location
            directly
          </MuiTypography>
          <MuiTypography align="center">
            North/South – Logan/Ogden/Layton/Utah County (including Park City/Heber) 801-525-5700
          </MuiTypography>
          <MuiTypography align="center">
            Central/Southwest – Salt Lake/St. George/Cedar City 801-507-7840
          </MuiTypography>
          <MuiTypography align="center">
            Rural South – Richfield/Sanpete Valley/Fillmore/Delta 435-893-0514
          </MuiTypography>
          <MuiTypography align="center">Bear River – 435-207-4545</MuiTypography>
          <MuiTypography align="center">Cassia – 208-677-6551</MuiTypography>
          <MuiBox pt={Spacing.small} display="flex" justifyContent="center">
            <MuiButton color="primary" variant="contained" onClick={onClose}>
              Close
            </MuiButton>
          </MuiBox>
        </FlexBox>
      </MuiContainer>
    </MuiModal>
  );
};
