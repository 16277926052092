import React from 'react';

export default {
  svg: (
    <g>
      <path
        d="M46.377 15.113a1.481 1.481 0 0 1-.118.593 1.69 1.69 0 0 1-.143.269 1.693 1.693 0 0 1-.193.237 1.625 1.625
        0 0 1-.506.337 1.55 1.55 0 0 1-1.458-.144 1.625 1.625 0 0 1-.237-.193 1.625 1.625 0 0 1-.336-.506 1.62 1.62 0
        0 1-.09-.29 1.574 1.574 0 0 1 .425-1.405 1.555 1.555 0 0 1 .507-.335 1.55 1.55 0 0 1 1.458.143
        c.085.056.164.12.237.192.288.29.454.69.454 1.102m-13.983 3.223a1.555 1.555 0 0 1 0 2.2L23.18 29.75l-1.052
        1.183-.066-.065-.066.066-1.053-1.184-9.214-9.214a1.555 1.555 0 1 1 2.2-2.2l8.065 8.065.067-.075.067.076
        8.066-8.066a1.555 1.555 0 0 1 2.2 0m2.747-4.948c-.043.042-.06.065-.143.133h-8.255l-2.667 2.54-7.585-7.976-5.147
        5.275H8.956a5.979 5.979 0 0 1-1.737-4.228c0-1.61.627-3.121 1.764-4.257a5.977 5.977 0 0 1 4.256-1.764c1.608 0
        3.12.626 4.257 1.764l4.566 4.565 4.565-4.565a5.978 5.978 0 0 1 4.257-1.764c1.608 0 3.119.626 4.256 1.764a5.974
        5.974 0 0 1 1.764 4.257 5.98 5.98 0 0 1-1.763 4.256m5.17.133h-1.418a9.075 9.075 0 0 0 1.122-4.39
        c0-2.44-.95-4.732-2.674-6.456-3.561-3.561-9.354-3.561-12.914 0L22.062 5.04l-2.366-2.365A9.068 9.068 0 0 0 13.239
        0c-2.44 0-4.732.95-6.456 2.675a9.068 9.068 0 0 0-2.675 6.457c0 1.497.36 2.938 1.035 4.228H1.556a1.556 1.556 0
        1 0 0 3.11h11.099l3.807-3.901 7.506 7.894 4.02-3.83H40.31a1.557 1.557 0 0 0 0-3.112"
        fill="#231f20"
        fillRule="evenodd"
      />
    </g>
  ),
  viewBox: '0 0 47 31'
};
