import CollapsibleListItem from 'components/common/CollapsibleList/CollapsibleListItem';
import { ListItem } from 'components/UI/ListItem/ListItem';
import capitalize from 'lodash/capitalize';
import React from 'react';
import { WeDiscussedDiagnosis } from 'store/visitSummary/types';
import { MuiBox, MuiList, MuiPaper } from 'theme/material-ui';

interface Props {
  diagnoses: WeDiscussedDiagnosis[];
}

export const DiagnosesCollapsibleListItem = ({ diagnoses }: Props) => {
  const subtitleText = `There ${diagnoses.length > 1 ? 'were' : 'was'} ${diagnoses.length} ${
    diagnoses.length > 1 ? 'diagnoses' : 'diagnosis'
  }`;

  return (
    <CollapsibleListItem
      title="You came for"
      subtitle={diagnoses && diagnoses.length > 0 ? subtitleText : ''}
    >
      <MuiBox width="100%">
        <MuiPaper elevation={2}>
          <MuiList>
            {diagnoses.map(item => (
              <ListItem title={capitalize(item.name)} />
            ))}
          </MuiList>
        </MuiPaper>
      </MuiBox>
    </CollapsibleListItem>
  );
};

DiagnosesCollapsibleListItem.defaultProps = {
  diagnoses: []
};

export default DiagnosesCollapsibleListItem;
