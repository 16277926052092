import React from 'react';

import { Color } from 'modules/styles/colors';

export default {
  svg: (
    <g>
      <path
        d="M173.61,159.28v-25.6a33.28,33.28,0,1,0-66.55,0v43.67a18.28,18.28,0,0,1-36.56,0V143.47l39-43.86A65.56,
        65.56,0,0,0,126,56.05V20.24A16.07,16.07,0,0,0,109.94,4.18H94.68V0h-.56A11.72,11.72,0,0,0,82.44,
        11.68h0A11.72,11.72,0,0,0,94.12,23.36h.56V19.18h15.26A1.05,1.05,0,0,1,111,20.24V56.05a50.51,50.51,0,0,1-12.77,
        33.6l-6.92,7.8H34.69l-6.92-7.8A50.51,50.51,0,0,1,15,56.05V20.24a1.05,1.05,0,0,1,1.06-1.06H31.32v4.17h.56A11.72,
        11.72,0,0,0,43.56,11.68h0A11.72,11.72,0,0,0,31.88,0h-.56V4.18H16.06A16.07,16.07,0,0,0,0,20.24V56.05A65.56,
        65.56,0,0,0,16.55,99.61l39,43.86v33.88a33.28,33.28,0,0,0,66.56,0V133.68a18.28,18.28,0,1,1,36.55,0v25.6a26.22,
        26.22,0,1,0,15,0ZM78,112.45,63,129.33,48,112.45Zm88.12,83.17a11.22,11.22,0,1,1,11.22-11.22A11.23,11.23,0,0,1,
        166.11,195.62Z"
        fill={Color.baseColor}
      />
    </g>
  ),
  viewBox: '0 0 192.33 210.63'
};
