export const GEOLOCATION = {
  title: 'Location Update',
  subtitle:
    'It appears your location has changed since the last time you used Connect Care. Would you like us to use your current location?'
} as const;

export const ERROR = {
  title: 'There was an issue updating your location'
};

export const NO_LOCATION = {
  title: 'Location Not Found',
  subtitle:
    'It seems that you do not have a location set in your Connect Care account. Please select a state that you are currently living in.'
};
