import React from 'react';

/**
 * Wrap a keyboard event and listen for the enter key
 */
export const onEnter = <T extends Function>(callback: T) => (
  event: React.KeyboardEvent<HTMLElement>
) => {
  if (event.keyCode === 13) {
    callback();
  }
};
