import { KeyboardArrowRight } from '@material-ui/icons';
import { Divider } from 'components/UI/Divider/styled';
import Box from 'components/UI/Layout/Box';
import FlexBox from 'components/UI/Layout/FlexBox';
import { ListItem } from 'components/UI/ListItem/ListItem';
import Typography from 'components/UI/Typography';
import CollapsibleListItem from 'components/common/CollapsibleList/CollapsibleListItem';
import { Color } from 'modules/styles/colors';
import { FontSize, FontWeight } from 'modules/styles/variables';
import React, { ComponentType, useEffect } from 'react';
import { FlatList, TouchableOpacity } from 'react-native';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { SpacedRowFlexBoxVisits } from 'screens/Billing/styled';
import analyticsService, { AnalyticsEvent } from 'services/AnalyticsService';
import formatMoney from 'services/formatMoney';
import { patientFinancialServicesNumber } from 'modules/constants/phoneNumbers';
import LinkingServices from 'services/LinkingServices';
import { BillingVisitSummaryState } from 'store/billing/reducers';
import * as AccountActions from 'store/account/actions';
import { getBillingVisitSummaryDetails } from 'store/billing/actions';
import { getBillingVisitSummarySelector } from 'store/billing/selectors';
import { RootState } from 'store/types';
import { MuiBox, MuiButton, MuiPaper } from 'theme/material-ui';
import { EncounterListItem } from 'store/billing/types';

interface Props {
  accountNumber: string;
  isImpersonating: boolean;
  returnToMainAccount: typeof AccountActions.returnToMain;
  billingVisitSummary: typeof getBillingVisitSummaryDetails;
  billingVisitSummaryResult: BillingVisitSummaryState;
}

const TITLE = 'Billing';

export const BillingComponent = (props: Props) => {
  const history = useHistory();

  const {
    accountNumber,
    isImpersonating,
    returnToMainAccount,
    billingVisitSummary,
    billingVisitSummaryResult
  } = props;

  useEffect(() => {
    billingVisitSummary(accountNumber);
  }, [accountNumber]);

  const billingLoading = billingVisitSummaryResult?.isFetching;
  const billingData = billingVisitSummaryResult?.data?.encounters;
  const collectionLoading = billingVisitSummaryResult?.isFetching;
  const collectionData = billingVisitSummaryResult?.data?.collectionEncounters;

  const goToBillingDetail = (item: EncounterListItem) => {
    analyticsService.logEvent(AnalyticsEvent.IndividualBillingClicked);
    history.push(`/u/billing/bill-detail/${item.id}`, {
      eaid: item.id
    });
  };

  const handleReturnToMainAccount = () => {
    history.push('/u/billing'); // Note: Navigate first so the server calls do not happen across accounts. Billing calls are protected.
    returnToMainAccount();
  };

  const goToBillingHelpCenter = () => {
    history.push('/u/billing/billing-help');
  };

  // Viewing on a proxy account and is not a guarantor
  if (isImpersonating && billingVisitSummaryResult.error) {
    return (
      <CollapsibleListItem title={TITLE} subtitle="You do not have access to this information.">
        <FlexBox
          data-testid="billing-section-is-inpersonating"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <MuiPaper elevation={1}>
            <MuiBox p={3}>
              <Typography>
                For more information on this bill, visit the{' '}
                <Typography tag="link" onClick={goToBillingHelpCenter} display="inline">
                  Billing FAQs
                </Typography>
                , or please call{' '}
                <Typography
                  display="inline"
                  tag="link"
                  data-testid="call-financial-services"
                  onClick={() => LinkingServices.callPhoneNumber(patientFinancialServicesNumber)}
                >
                  Patient Financial Services at 1-{patientFinancialServicesNumber}
                </Typography>
                .
              </Typography>
            </MuiBox>
            <MuiBox display="flex" justifyContent="center" px={1} pb={2}>
              <MuiButton
                variant="contained"
                color="primary"
                onClick={handleReturnToMainAccount}
                style={{ maxHeight: 64 }}
              >
                Switch accounts to view your outstanding bills
              </MuiButton>
            </MuiBox>
          </MuiPaper>
        </FlexBox>
      </CollapsibleListItem>
    );
  }

  let encounters = billingData || [];
  if (!encounters.length) {
    encounters = collectionData || [];
  }
  const balance = encounters.reduce((carry, encounter) => carry + encounter.balance, 0);

  // Is able to view billing info, but the billing info is not available
  if (!encounters.length && !billingLoading && !collectionLoading) {
    return (
      <CollapsibleListItem title={TITLE} subtitle="Billing information is not available.">
        <Box backgroundColor={Color.foreColor} data-testid="billing-section-no-encounters">
          <MuiPaper elevation={2}>
            <ListItem
              to="/u/billing/payment-history"
              accessibilityLabel="Tap to view payment history"
              accessible
              title="View Payment History"
              icon="ChevronRight"
            />
          </MuiPaper>
        </Box>
      </CollapsibleListItem>
    );
  }

  // Show billing info if available
  return (
    <CollapsibleListItem
      title={TITLE}
      subtitle={encounters.length ? `Total due: $${formatMoney(balance)}` : 'Loading...'}
    >
      <Box backgroundColor={Color.foreColor}>
        <MuiPaper elevation={2}>
          <MuiBox p={3}>
            <FlatList
              data={encounters}
              keyExtractor={item => `${item.id}`}
              renderItem={({ item: eachEncounter }) => {
                return (
                  <>
                    <TouchableOpacity
                      accessible
                      onPress={() => goToBillingDetail(eachEncounter)}
                      key={eachEncounter.id}
                    >
                      <SpacedRowFlexBoxVisits justifyContent="space-between">
                        <FlexBox flex={1} flexDirection="column">
                          <Typography
                            numberOfLines={1}
                            fontWeight={FontWeight.bold}
                          >{`#${eachEncounter.fullAccountNumber}`}</Typography>
                        </FlexBox>
                        <FlexBox
                          justifyContent="space-between"
                          alignItems="center"
                          flexDirection="row"
                        >
                          <Typography fontSize={FontSize.base} fontWeight={FontWeight.bold}>
                            {eachEncounter.paymentPlan
                              ? 'Payment Plan'
                              : `$${formatMoney(eachEncounter.balance)}`}
                          </Typography>
                          <KeyboardArrowRight />
                        </FlexBox>
                      </SpacedRowFlexBoxVisits>
                      <Divider />
                    </TouchableOpacity>
                  </>
                );
              }}
            />
          </MuiBox>
        </MuiPaper>
      </Box>
    </CollapsibleListItem>
  );
};

const mapStateToProps = (state: RootState) => ({
  billingVisitSummaryResult: getBillingVisitSummarySelector(state)
});

const mapDispatchToProps = (dispatch: Function) => ({
  returnToMainAccount: () => dispatch(AccountActions.returnToMain()),
  billingVisitSummary: (accountNumber: string) =>
    dispatch(getBillingVisitSummaryDetails(accountNumber))
});

export default connect(mapStateToProps, mapDispatchToProps)(BillingComponent as ComponentType);
