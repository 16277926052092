import React from 'react';
import { Color } from 'modules/styles/colors';

export default (props: any = { color: Color.black }) => ({
  svg: (
    <path
      d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
      fill={props.color}
    />
  ),
  viewBox: '0 0 24 24'
});
