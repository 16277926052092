import styled from 'styled-components';
import { Color } from 'modules/styles/colors';

export const RangeContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
  margin-bottom: 20px;
`;

interface LeftBarProps {
  big?: boolean;
  bgColor?: string;
}

export const LeftBar = styled.div<LeftBarProps>`
  flex: 1;
  height: ${({ big }) => (big ? '11px' : '8px')};
  background-color: ${Color.grayLight7};
  background-color: ${({ bgColor }) => bgColor};
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  margin-right: 3px;

  &:after {
    position: absolute;
    content: attr(data-low-label);
    top: 10px;
    right: -8px;
    height: 31px;
    padding: 0;
    color: ${Color.textLight};
    font-size: 12px;
    font-weight: 600;
    white-space: nowrap;
  }
`;

interface MiddleBarProps {
  big?: boolean;
  bgColor?: string;
  widthPercentage?: number;
}

export const MiddleBar = styled.div<MiddleBarProps>`
  width: ${({ widthPercentage }) => `${widthPercentage}%`};
  height: ${({ big }) => (big ? '11px' : '8px')};
  background-color: ${Color.grayLight7};
  background-color: ${({ bgColor }) => bgColor};
`;

interface RightBarProps {
  big?: boolean;
  bgColor?: string;
}

export const RightBar = styled.div<RightBarProps>`
  flex: 1;
  height: ${({ big }) => (big ? '11px' : '8px')};
  background-color: ${Color.grayLight7};
  background-color: ${({ bgColor }) => bgColor};
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  margin-left: 3px;

  &:after {
    position: absolute;
    content: attr(data-high-label);
    top: 10px;
    left: -8px;
    height: 31px;
    padding: 0;
    color: ${Color.textLight};
    font-size: 12px;
    font-weight: 600;
    white-space: nowrap;
  }
`;

interface IndicatorProps {
  bgColor?: string;
  position?: number;
  fixedTooltip?: boolean;
}

export const Indicator = styled.div<IndicatorProps>`
  position: absolute;
  top: -7px;
  left: ${({ position }) => `${position}%`};
  width: 6px;
  height: 6px;

  &:before {
    position: absolute;
    content: '';
    top: 0;
    left: -5px;

    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: ${Color.grayLight7};
    border-top: ${({ bgColor }) => `7px solid ${bgColor}`};
    white-space: nowrap;
  }

  &:after {
    position: absolute;
    display: ${({ fixedTooltip }) => (fixedTooltip ? 'none' : 'flex')};
    justify-content: center;
    align-items: center;
    content: attr(data-tooltip-text);
    top: -30px;
    transform: translateX(-49%);

    height: 31px;
    padding: 4px 8px;
    background-color: ${Color.foreColor};
    border: ${({ bgColor }) => `2px solid ${bgColor}`};
    border-radius: 5px;

    color: ${Color.textLight};
    font-size: 14px;
    line-height: 17px;
    font-weight: 600;
    white-space: nowrap;
  }
`;

interface FixedTooltipProps {
  bgColor?: string;
  showRange?: boolean;
}

export const FixedTooltip = styled.div<FixedTooltipProps>`
  position: absolute;
  display: block;
  text-align: center;
  top: ${({ showRange }) => (showRange ? '-50px' : '-40px')};

  width: 160px;
  height: 41px;
  padding: 8px 16px;
  background-color: ${Color.baseColor};
  border: ${({ bgColor }) => `2px solid ${bgColor}`};
  border-radius: 5px;

  color: ${Color.textLight};
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;
