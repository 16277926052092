/* eslint-disable react/jsx-no-duplicate-props */
import React from 'react';
import { removeUnderline, StyledIconButton, StyledInput, Adornment } from './styled';
import { Color } from 'modules/styles/colors';
import { Svg } from 'components/UI/Svg';

export interface SearchBarProps {
  value?: string;
  autoFocus?: boolean;
  fullWidth?: boolean;
  border?: boolean;
  placeholder?: string;
  inputProps?: string[];
  label?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClear?: () => void;
  onBlur?: () => void;
  onFocus?: () => void;
  inputRef?: any;
}

export const SearchBar = ({
  value,
  autoFocus,
  placeholder,
  fullWidth,
  inputProps,
  label,
  border,
  onBlur,
  onChange,
  onClear,
  onFocus,
  inputRef
}: SearchBarProps) => (
  <StyledInput
    id={label || ''}
    label={label || ''}
    value={value}
    inputRef={inputRef}
    fullWidth={fullWidth}
    autoFocus={autoFocus}
    placeholder={placeholder}
    onChange={onChange}
    onBlur={onBlur}
    border={!!border}
    onFocus={onFocus}
    inputProps={{
      'data-testid': 'auto-complete-input'
    }}
    InputProps={{
      classes: removeUnderline(),
      startAdornment: (
        <Adornment position="start">
          <Svg set="material" name="search" color={Color.black} />
        </Adornment>
      ),
      endAdornment: (
        <Adornment position="end">
          <StyledIconButton
            onClick={() => onClear?.()}
            hidden={value === ''}
            data-testid="record-search-close-button"
          >
            <Svg set="material" name="clear" color="inherit" size={20} />
          </StyledIconButton>
        </Adornment>
      ),
      ...inputProps
    }}
  />
);
