import * as React from 'react';

export default {
  svg: (
    <g>
      <style>{'.st0{fill:none}.st1{fill:#e8e8e8}.st2{fill:#9e9e9e}.st3{fill:#c8c8c8}'}</style>
      <path
        className="st0"
        d="M110.3 239.7c12.5 0 25 .1 37.5.1h48.5-48.5c-12.4-.1-24.9-.2-37.5-.1zM145.2 152.7c0-4 .1-8 0-12.1v12.1c0 3-.2 6 0 8.9-.3-2.9 0-5.9 0-8.9z"
      />
      <path
        className="st1"
        d="M101.3 255.7c.2 0 .3 0 .4-.1h-8.4c2.7.1 5.3.1 8 .1zM193.6 255.6h-91.1c.1.2.1.7-.1 1.6L99 277.3c15.8 6.2 32.9 9.6 50.9 9.6 16.8 0 32.9-3 47.8-8.4-.5-4-1.1-8-2-12-.7-3.6-2-7.1-2.1-10.9z"
      />
      <path
        className="st1"
        d="M235.3 255.6h-75.1 69.9c-7.5 0-14.9 0-22.4.1 1.8 5.4 3.5 10.8 5.3 16.3 7-3.6 13.7-7.7 20-12.3.1-1.1.1-2.3.2-3.4.6-.6 1.3-.7 2.1-.7zM85.6 255.6c-7.6 0-15.1 0-22.7.1H62h.9c0 .3 0 .5.1.8 6.5 5.2 13.4 9.8 20.8 13.8 1.5-4.9 3-9.9 4.5-14.6l-2.7-.1z"
      />
      <path
        className="st2"
        d="M38.7 109.8h64.4c1.6 0 3.1.1 4.6-.5 2-1.2 3.9-2.6 6.1-3.6 10.2-4.5 15.6-12.7 18.1-23.2.2-2.3.3-4.6.5-6.9-1.2-2.9-2.5-5.8-3.7-8.7-.3-.7-.9-1-1.4-1.5-4.7-3.5-5.7-8.3-2.6-13.2.6-.9.5-1.8.5-2.8-.1-3.4-.1-6.8.5-10.2 1.6-9.7 8-15.9 17.8-17.2 3.9-.5 7.7-.5 11.4.3 9.9 2.2 15.7 9.5 16.2 20.7.1 1.7.1 3.5 0 5.2-.1 1.9.1 3.5 1.3 5.1 1.9 2.7 1.2 8.3-1.8 10.5-2.7 2.1-3.8 4.8-5.1 7.6-.4.8-.8 1.6-1.2 2.3-.1.4-.3.7-.4 1.1 0 .3-.1.7-.1 1 .1 6 1.8 11.5 4.3 16.8 2.8 3.1 4.6 6.9 8.2 9.3 2.9 1.9 6 3.1 8.9 4.9l3.3 2.1c1.4.6 2.9.5 4.4.5H256c6.6 0 7.1.5 7.1 6.9v112.3c16-22.3 25.4-49.6 25.4-79.1 0-75.6-62-136.9-138.5-136.9S11.5 74.4 11.5 150c0 27.1 8 52.3 21.7 73.6V115.2c0-4.3 1.1-5.4 5.5-5.4zM62 255.7c.3.3.7.5 1 .8 0-.3-.1-.5-.1-.8H62zM235.3 255.6c-.7 0-1.4.1-2.1.6-.1 1.1-.1 2.3-.2 3.4 1.8-1.3 3.5-2.6 5.2-4h-2.9z"
      />
      <path
        className="st1"
        d="M161.4 78.9c.6-1.1 1.1-2.2 2.3-2.7 0-.3.1-.7.1-1-.2-.5-.1-.9.4-1.1.4-.8.8-1.6 1.2-2.3 1.3-2.8 2.4-5.6 5.1-7.6 3-2.3 3.8-7.8 1.8-10.5-1.2-1.7-1.4-3.3-1.3-5.1.1-1.7.1-3.5 0-5.2-.6-11.2-6.3-18.5-16.2-20.7-3.8-.8-7.6-.9-11.4-.3-9.7 1.4-16.2 7.5-17.8 17.2-.6 3.4-.6 6.8-.5 10.2 0 .9.1 1.8-.5 2.8-3.1 4.9-2.1 9.7 2.6 13.2.6.4 1.2.8 1.4 1.5 1.2 2.9 2.5 5.8 3.7 8.7 2.2 2.1 3.4 5 5.6 7.1 6.8 6.1 19 4.2 23.5-4.2z"
      />
      <path
        className="st1"
        d="M176.3 102.3c-3.6-2.4-5.5-6.2-8.2-9.3-8.8.1-17.1-1.8-25.2-4.9-2.4-.9-4.5-2.3-6.6-3.6-1.3-.8-2.5-1.9-4.2-1.9-2.5 10.4-7.9 18.7-18.1 23.2-2.1.9-4.1 2.4-6.1 3.6 1.6.8 3.2.5 4.9.5 24.6 0 49.1.1 73.7.1.8 0 1.6.1 2.1-.6l-3.3-2.1c-3-1.9-6.2-3.1-9-5z"
      />
      <path
        className="st3"
        d="M142.8 88.1c8.1 3.1 16.5 5.1 25.2 4.9-2.5-5.3-4.2-10.9-4.3-16.8-1.2.5-1.8 1.6-2.3 2.7-4.6 8.4-16.8 10.3-23.4 3.9-2.2-2.2-3.4-5-5.6-7.1-.2 2.3-.3 4.6-.5 6.9 1.7 0 2.9 1.1 4.2 1.9 2.2 1.3 4.3 2.7 6.7 3.6zM163.9 75.2c.1-.4.3-.7.4-1.1-.5.2-.7.6-.4 1.1z"
      />
      <path
        className="st2"
        d="M193.6 255.6c.1 3.8 1.3 7.3 2.2 10.9.9 3.9 1.5 8 2 12 5.2-1.9 10.3-4.1 15.2-6.6-1.8-5.4-3.5-10.8-5.3-16.3-4.7.1-9.4 0-14.1 0zM102.5 255.6h-.8c-.1 0-.3.1-.4.1-2.7 0-5.3 0-8-.1h-5c-1.5 4.7-3 9.7-4.5 14.6 4.9 2.7 10 5 15.3 7.1l3.4-20.1c.1-.9.1-1.4 0-1.6z"
      />
      <path
        d="M110.3 239.7c12.5 0 25 .1 37.5.1h95.9c3.8 0 3.8 0 3.8-3.8.1-20.5-.1-41.1.2-61.6.2-15 .1-30.1 0-45.1 0-3.6-.1-3.7-3.6-3.7-14.8 0-29.7.4-44.5 0-10.1-.3-20.3-.1-30.4-.1l-116.8.3c-3.7 0-3.7.1-3.7 3.9-.2 18.7.1 37.4-.3 56.1-.4 16.9-.1 33.7-.1 50.6 0 3.6 0 3.7 3.7 3.6 15.3-.1 30.6 0 45.8-.2 4.2-.1 8.4-.1 12.5-.1zm-4.1-34.4c.4-5.3 1.3-10.6 2.5-15.8 2.4-10.9 9.1-18.9 17.1-26 1-.9 2.3-1.5 3.6-2.1 2.6-1 4.7-.3 6.4 2.1 1.6 2.4 2.6 5.1 3.5 7.9.5 1.5 1.1 1.5 2.1.5 2.4-2.5 4.3-5 3.9-8.9 0-.4-.1-.8-.1-1.3-.2-3 0-6 0-8.9 0-4 .1-8 0-12.1 0-2.1.4-3.3 2.9-3.3 2.4 0 3 1.1 3 3.2-.1 7.9.1 15.7-.1 23.6-.1 3.7 2.2 5.7 4.4 7.9 1.1 1.1 1.5 0 1.8-.9.7-2.2 1.4-4.3 2.6-6.3 2.2-4 4.6-4.9 8.6-2.8 5.5 2.8 9.3 7.4 12.6 12.3 8.8 13 9.7 27.9 9.3 43-.1 2.5-.7 4.9-1.6 7.1-1.2 2.8-2.9 3.8-5.8 3.2-12.2-2.6-21.9-8.5-27.2-20.3-1.1-2.5-1.5-5.3-1.7-8.1-.3-5.1-.3-10.1.5-15.2.3-2.1-.1-3.7-1.4-5.3-1.2-1.5-2.6-2.8-3.6-4.4-.7-1.3-1.8-1-2.5.1-1.2 1.9-2.8 3.6-4.4 5.2-1.2 1.3-1 2.7-.8 4.2.9 6.7 1.6 13.4.2 20.1-1.8 8.2-6.8 14-13.7 18.4-4.2 2.7-8.8 4.6-13.8 5.5-3.8.7-5.5-.2-6.8-3.9-2.3-6.1-2-12.4-1.5-18.7z"
        style={{
          fill: '#414141'
        }}
      />
      <path
        className="st3"
        d="M52 239.9c-3.6 0-3.7 0-3.7-3.6 0-16.9-.3-33.7.1-50.6.4-18.7.1-37.4.3-56.1 0-3.8.1-3.9 3.7-3.9l116.8-.3c10.1 0 20.3-.2 30.4.1 14.8.4 29.7 0 44.5 0 3.6 0 3.6.1 3.6 3.7 0 15 .2 30.1 0 45.1-.3 20.5-.1 41.1-.2 61.6 0 3.8 0 3.8-3.8 3.8h-95.9c-12.5 0-25-.1-37.5-.1-4.2 0-8.3 0-12.5.1-15.2.3-30.5.2-45.8.2h202.4c3.1-3.5 6-7.1 8.7-10.9V116.7c0-6.4-.5-6.9-7.1-6.9h-63.1c-1.5 0-3 .1-4.4-.5-.6.7-1.3.6-2.1.6-24.6 0-49.1-.1-73.7-.1-1.6 0-3.3.3-4.9-.5-1.5.6-3.1.5-4.6.5H38.8c-4.4 0-5.5 1-5.5 5.3v108.4c5.4 8.4 11.7 16.1 18.8 23.1v-6.7z"
      />
      <path
        className="st3"
        d="M254.4 239.9H52v6.8c3.2 3.1 6.5 6.1 10 9h.9c7.6 0 15.1 0 22.7-.1h2.7c0-.1 0-.1.1-.2 1.6.1 3.3.1 4.9.2h8.4c.4-.1.7-.3.8 0h38-33.4H238.2c5.8-4.8 11.2-10 16.2-15.7z"
      />
      <path
        className="st3"
        d="M207.7 255.7c7.5 0 14.9 0 22.4-.1H140.5h53.1c4.7 0 9.4.1 14.1.1zM107.1 255.6H160.2h-53.1zM235.3 255.6h1.2-1.2zM101.7 255.6h.8c-.1-.3-.4-.1-.8 0zM88.4 255.5c0 .1 0 .1-.1.2h5c-1.6-.1-3.3-.2-4.9-.2zM114.4 227.9c5-.9 9.5-2.8 13.8-5.5 6.9-4.4 11.9-10.2 13.7-18.4 1.4-6.7.7-13.4-.2-20.1-.2-1.5-.4-2.9.8-4.2 1.6-1.6 3.1-3.3 4.4-5.2.7-1.1 1.7-1.4 2.5-.1 1 1.7 2.5 2.9 3.6 4.4 1.2 1.6 1.7 3.2 1.4 5.3-.8 5-.9 10.1-.5 15.2.2 2.8.6 5.6 1.7 8.1 5.3 11.8 15 17.7 27.2 20.3 2.9.6 4.6-.5 5.8-3.2 1-2.3 1.6-4.7 1.6-7.1.4-15.1-.4-30-9.3-43-3.3-4.9-7.1-9.6-12.6-12.3-4-2-6.4-1.2-8.6 2.8-1.1 2-1.8 4.2-2.6 6.3-.3.9-.7 1.9-1.8.9-2.2-2.2-4.5-4.2-4.4-7.9.2-7.9 0-15.7.1-23.6 0-2.1-.6-3.2-3-3.2-2.5 0-2.9 1.2-2.9 3.3.1 4 0 8 0 12.1 0 3-.2 6 0 8.9 0 .4.1.8.1 1.3.4 3.9-1.5 6.4-3.9 8.9-1 1.1-1.6 1.1-2.1-.5-.8-2.8-1.8-5.5-3.5-7.9-1.6-2.4-3.7-3.1-6.4-2.1-1.3.5-2.6 1.2-3.6 2.1-8.1 7.1-14.7 15.1-17.1 26-1.2 5.2-2 10.5-2.5 15.8-.5 6.3-.8 12.6 1.4 18.7 1.4 3.6 3.1 4.6 6.9 3.9z"
      />
    </g>
  ),
  viewBox: '0 0 300 300'
};
