import { Color } from 'modules/styles/colors';
import { EventEmitter } from 'services/EventEmitter';
import WarningIcon from '@material-ui/icons/Warning';
import ErrorIcon from '@material-ui/icons/Error';
import Svg from 'components/UI/Svg/Svg';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import React, { useEffect, useState } from 'react';
import { useMediaQuery } from '@material-ui/core';
import { MuiBox, MuiDialog, MuiDialogContent, useTheme } from '../../theme/material-ui';
import { ConnectCareButton } from '../ConnectCare/ConnectCareButton';
import FlexBox from '../UI/Layout/FlexBox';
import { Typography } from '../UI/Typography/styled';
import { FontSize, IconSize } from 'modules/styles/variables';
import { Spacing } from 'modules/styles/variables.web';
import SvgButton from '../UI/Svg/SvgButton';

interface ConfirmDialogAction {
  text: string;
  onClick?: () => void;
  skipOnClose?: boolean;
}

interface ConfirmDialogOptions {
  cancelable?: boolean;
  closeIcon?: boolean;
  fullWidth?: boolean;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  fullScreen?: boolean;
  animationType?: 'fade' | 'slide' | 'none';

  onClose?: () => void;
}

export interface ConfirmDialogProps {
  severity?: 'error' | 'warning' | 'info' | 'success';
  isOpen: boolean;
  title: React.ReactNode;
  description?: React.ReactNode;
  secondaryDescription?: React.ReactNode;
  cancel?: ConfirmDialogAction;
  confirm?: ConfirmDialogAction;
  options?: ConfirmDialogOptions;
  footNote?: React.ReactNode;
}

type ColorKey = keyof typeof Color;

const confirmBus = new EventEmitter();

export class Confirm {
  static show(
    title: ConfirmDialogProps['title'],
    description?: ConfirmDialogProps['description'],
    secondaryDescription?: ConfirmDialogProps['secondaryDescription'],
    severity?: ConfirmDialogProps['severity'] | string,
    confirm?: ConfirmDialogProps['confirm'],
    cancel?: ConfirmDialogProps['cancel'],
    options?: ConfirmDialogProps['options'],
    footNote?: ConfirmDialogProps['footNote']
  ) {
    confirmBus.emit('show', {
      severity,
      title,
      description,
      secondaryDescription,
      confirm,
      cancel,
      options,
      footNote
    });
  }

  /**
   * If calling multiple dialogs in sequence, use 'await Confirm.close()' before calling next 'Confirm.show(...)'
   */
  static async close() {
    confirmBus.emit('close', {});
  }
}

function SeverityIcon({ severity }: { severity: ConfirmDialogProps['severity'] }) {
  const baseProps = (opts = {}, styleOpts = {}) => ({
    accessibilityLabel: severity,
    ...opts,
    style: {
      fontSize: FontSize.xlarge,
      color: Color[severity as ColorKey],
      ...styleOpts
    }
  });

  let props = baseProps();

  switch (severity) {
    case 'warning':
      props = baseProps({}, { color: Color.error });
      return <WarningIcon {...props} />;
    case 'error':
      return <ErrorIcon {...props} />;
    case 'info':
      props = baseProps({}, { color: Color.primary });
      return <Svg set="assets" name="InfoIcon" size={IconSize.xLarge} color={Color.primary} />;
    case 'success':
      return <CheckCircleIcon {...props} />;
    default:
      return null;
  }
}

function ConfirmDialog() {
  const [props, setProps] = useState<ConfirmDialogProps | null>(null);

  useEffect(() => {
    const unsubscribe = confirmBus.on(
      'show',
      ({
        severity,
        title,
        description,
        secondaryDescription,
        confirm,
        cancel,
        options,
        footNote
      }: ConfirmDialogProps) => {
        if (props !== null) {
          return;
        }

        setProps(
          prevState =>
            prevState ||
            ({
              severity,
              title,
              description,
              secondaryDescription,
              confirm,
              cancel,
              options,
              footNote
            } as ConfirmDialogProps)
        );
      }
    );

    const unsubscribeClose = confirmBus.on('close', () => {
      setProps(null);
    });

    return () => {
      unsubscribe();
      unsubscribeClose();
    };
  }, [setProps, confirmBus]);

  const handleClose = () => {
    props?.options?.onClose?.();
    setProps(null);
  };

  const wrapOnClick = (
    onClickHandler: ConfirmDialogAction['onClick'],
    skipOnClose: ConfirmDialogAction['skipOnClose']
  ) => () => {
    onClickHandler?.();
    if (!skipOnClose) {
      props?.options?.onClose?.();
    }
    setProps(null);
  };

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  if (!props) {
    return null;
  }

  const {
    severity,
    title,
    description,
    secondaryDescription,
    cancel,
    confirm = {
      text: 'Ok'
    },
    options: optionsProp,
    footNote
  } = props;

  const options: ConfirmDialogOptions = {
    fullWidth: true,
    maxWidth: 'sm',
    fullScreen: isSmallScreen,
    closeIcon: true,
    ...optionsProp
  };

  return (
    <MuiDialog
      open
      maxWidth={options?.maxWidth}
      fullWidth={options?.fullWidth}
      fullScreen={options?.fullScreen}
      onClose={handleClose}
      onBackdropClick={wrapOnClick(cancel?.onClick, cancel?.skipOnClose)}
      data-testid="dialog"
    >
      {options?.closeIcon ? (
        <FlexBox alignItems="flex-end">
          <SvgButton
            color={Color.black}
            appearance="transparent"
            set="assets"
            name="CloseIcon"
            accessibilityLabel="Close Dialog"
            data-testid="close-button"
            onClick={handleClose}
          />
        </FlexBox>
      ) : null}
      <MuiDialogContent>
        <FlexBox alignItems="center" hSpacing={Spacing.xLarge} vSpacingBottom={20}>
          {severity ? <SeverityIcon severity={severity} /> : null}
          {typeof title === 'string' ? (
            <MuiBox marginTop={Spacing.xSmall}>
              <Typography tag="h3" fontSize={FontSize.large} textAlign="center">
                {title}
              </Typography>
            </MuiBox>
          ) : null}
          {typeof description === 'string' ? (
            <MuiBox
              marginTop={Spacing.xSmall}
              marginBottom={Spacing.xSmall}
              data-testid="description"
            >
              <Typography textAlign="center">{description}</Typography>
            </MuiBox>
          ) : (
            description
          )}
          {typeof secondaryDescription === 'string' ? (
            <MuiBox marginBottom={Spacing.xSmall}>
              <Typography textAlign="center">{secondaryDescription}</Typography>
            </MuiBox>
          ) : null}
          <MuiBox marginTop={Spacing.xSmall} />
          {confirm ? (
            <ConnectCareButton
              size="large"
              onClick={wrapOnClick(confirm.onClick, confirm.skipOnClose)}
              fullWidth
              data-testid="ok-button"
            >
              {confirm.text}
            </ConnectCareButton>
          ) : null}
          {cancel ? (
            <ConnectCareButton
              size="large"
              variant="text"
              onClick={wrapOnClick(cancel.onClick, cancel.skipOnClose)}
              fullWidth
            >
              {cancel.text}
            </ConnectCareButton>
          ) : null}
          {typeof footNote === 'string' ? (
            <MuiBox
              flexDirection="row"
              alignItems="start"
              justifyContent="space-around"
              marginTop={Spacing.medium}
            >
              <Typography textAlign="left" fontSize={FontSize.small}>
                {footNote}
              </Typography>
            </MuiBox>
          ) : (
            footNote
          )}
        </FlexBox>
      </MuiDialogContent>
    </MuiDialog>
  );
}

export default ConfirmDialog;
