import React from 'react';

import { MuiBox, MuiDivider, MuiTypography } from 'theme/material-ui';

import { ProviderSummary } from 'store/findProvider/types';
import { Nullable } from 'modules/types/common';
import { Specialty } from 'store/booking/types';

import ProviderListItem from '../../components/providerListItem';

interface Props {
  selectedSpecialty: Nullable<Specialty>;
  doctors: ProviderSummary[];
  doctorsLoading: boolean;
  geolocationStatus: boolean;
  getProviderIsSelected: Function;
  handleProviderSelect: Function;
  currentPatientName?: string;
}

const ProvidersSection = ({
  doctors,
  selectedSpecialty,
  doctorsLoading,
  geolocationStatus,
  getProviderIsSelected,
  handleProviderSelect,
  currentPatientName
}: Props) =>
  doctors.length || doctorsLoading ? (
    <>
      <MuiBox mb={3}>
        <MuiTypography gutterBottom variant="h5">
          {currentPatientName}'s Care Team
        </MuiTypography>
        <MuiDivider />
      </MuiBox>
      <MuiBox py={2}>
        <MuiBox role="radiogroup">
          {doctors.map(doctor => (
            <ProviderListItem
              key={doctor.corpProvId}
              selectedSpecialty={selectedSpecialty}
              doctor={doctor}
              geolocationStatus={geolocationStatus}
              getProviderIsSelected={getProviderIsSelected}
              handleProviderSelect={handleProviderSelect}
            />
          ))}
        </MuiBox>
      </MuiBox>
    </>
  ) : null;

export default ProvidersSection;
