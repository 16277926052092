import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { apiKeySelector } from 'store/appSettings/selectors';
import { dfdAccessTokenSelector } from 'store/authentication/selectors';
import { getHipaaDocument } from 'store/hipaaDocuments/actions';
import { cernerIdSelector } from 'store/profile/selectors';
import { RootState, RootDispatch } from 'store/types';
import { BinaryDocument } from 'store/visitSummary/types';
import { Nullable } from 'modules/types/common';
import { HipaaDocumentsItem } from 'store/hipaaDocuments/types';
import { hipaaDocumentsDataSelector } from 'store/hipaaDocuments/selectors';
import { MuiBox, MuiTypography } from 'theme/material-ui';
import { FlexBoxRow } from 'components/UI/Layout/FlexBox';
import Spinner from 'components/UI/Spinner/Spinner';
import DataLoader from 'components/UI/DataLoader/DataLoader';
import { oc } from 'shared/node_modules/ts-optchain';

export interface Props {
  dispatch: RootDispatch;
  match: { params: { id: string } };
  documents: Nullable<HipaaDocumentsItem[]>;
}

export function HipaaViewDocument({ dispatch, match, documents }: Props) {
  const [data, setData] = useState<null | BinaryDocument>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const patientId = match.params.id;
  const documentDetailsObj = documents?.find(item => item.patientId === patientId);
  const documentDetails = documentDetailsObj?.document;

  // Render Document Content
  const RenderDocument = (data: Nullable<{ content: string; contentType: string }>) => {
    const documentObj = data.data;
    const content = oc(documentObj).content();
    const contentType = oc(documentObj).contentType();

    if (content && contentType && contentType.includes('application/pdf')) {
      return (
        // eslint-disable-next-line jsx-a11y/iframe-has-title
        <iframe
          style={{ width: '100vw', height: '100vh' }}
          src={`data:application/pdf;base64, ${encodeURI(content.content)}`}
        ></iframe>
      );
    }
    return <></>;
  };
  const onError = () => {
    setError(true);
  };

  const handleDocumentReceived = (document: BinaryDocument) => {
    setData(document);
    setLoading(false);
  };

  useEffect(() => {
    if (documentDetails) {
      const { documentId } = documentDetails;

      dispatch(getHipaaDocument('application/json+fhir', documentId))
        .then(res => {
          const { data, headers } = res.payload;
          const contentType = data.contentType || headers['content-type'];
          const result = { contentType, content: data };

          handleDocumentReceived(result);
        })
        .catch(onError);
    }
  }, [documentDetails]);

  return (
    <DataLoader
      loading={loading}
      data={data}
      error={error}
      renderLoading={() => (
        <MuiBox p={5} display="flex" alignItems="center" justifyContent="center">
          <Spinner />
        </MuiBox>
      )}
      renderError={() => (
        <FlexBoxRow alignItems="center" justifyContent="center">
          <MuiTypography>There was an issue loading HIPAA documents.</MuiTypography>
        </FlexBoxRow>
      )}
      renderNoData={() => (
        <FlexBoxRow alignItems="center" justifyContent="center">
          <MuiTypography>No HIPAA documents on file.</MuiTypography>
        </FlexBoxRow>
      )}
      renderData={() => <RenderDocument data={data} />}
    />
  );
}

const mapStateToProps = (state: RootState) => ({
  accessToken: dfdAccessTokenSelector(state),
  apiKey: apiKeySelector(state),
  patientId: cernerIdSelector(state),
  documents: hipaaDocumentsDataSelector(state)
});

export default connect(mapStateToProps)(HipaaViewDocument);
