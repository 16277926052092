import React, { ElementType } from 'react';

import Svg from 'components/UI/Svg/Svg';
import FlexBox from 'components/UI/Layout/FlexBox';
import {
  ButtonProps,
  MuiBox,
  MuiButton,
  MuiDivider,
  MuiGrid,
  MuiTypography
} from 'theme/material-ui';

import { Color } from 'modules/styles/colors';
import { toSentenceCase } from 'modules/utils/StringUtils';
import { AppointmentLocation } from 'store/booking/types';
import { FontSize } from 'modules/styles/variables';

import CalendarDateIcon from './CalendarDateIcon';
import { AppointmentCardActionsWrapper } from '../styled';

import { formatDateOrdinal, formatTimeWithTimeZone } from 'modules/utils/DateUtils';
import { Tooltip } from '@material-ui/core';

export interface AppointmentCardProps {
  providerName: string;
  date?: Date | string | null;
  location?: AppointmentLocation;
  children?: React.ReactNode;
  month: string;
  day: string;
  time: string;
  isSelectHealth: boolean;
  reason: string;
  isVideoVisit?: boolean;
  displayTime: boolean;
}

export interface CalendarDateIconProps {
  month: string;
  day: string;
}

export interface AppointmentCardActionsProps {
  children: React.ReactNode;
}

export interface AppointmentCardActionProps extends ButtonProps {
  svgName: string;
  label: string;
  component?: ElementType;
}

export function AppointmentCardAction({ svgName, label, ...rest }: AppointmentCardActionProps) {
  return (
    <MuiButton style={{ minWidth: 0, padding: '6px 6px' }} {...rest}>
      <Tooltip
        title={<MuiTypography color={Color.white}>{toSentenceCase(label)}</MuiTypography>}
        arrow
      >
        <MuiBox display="flex" flexDirection="column" alignItems="center" justifyContent="center">
          <Svg name={svgName} />
        </MuiBox>
      </Tooltip>
    </MuiButton>
  );
}

export function AppointmentCardActions({ children }: AppointmentCardActionsProps) {
  return <AppointmentCardActionsWrapper>{children}</AppointmentCardActionsWrapper>;
}

function AppointmentCard({
  providerName,
  location,
  month,
  day,
  time,
  date,
  reason,
  children,
  isVideoVisit,
  displayTime
}: AppointmentCardProps) {
  return (
    <>
      <MuiDivider />
      <MuiBox py={2} px={2}>
        <MuiGrid container spacing={2} alignItems="center">
          <MuiGrid item>
            {displayTime ? (
              <CalendarDateIcon month={month} day={day} time={time} />
            ) : (
              <CalendarDateIcon month={month} day={day} />
            )}
          </MuiGrid>
          <MuiGrid item xs container>
            <MuiGrid item xs container direction="column" spacing={2}>
              <MuiGrid item xs>
                {providerName ? (
                  <MuiTypography data-testid="appointment-provider">{providerName}</MuiTypography>
                ) : null}
                {reason ? (
                  <MuiTypography data-testid="appointment-reason" fontSize={FontSize.large} noWrap>
                    {reason}
                  </MuiTypography>
                ) : null}
                {!isVideoVisit && location ? (
                  <>
                    <MuiTypography data-testid="appointment-location" noWrap>
                      {location.locationName}
                    </MuiTypography>
                    <MuiTypography>{location.city}</MuiTypography>
                  </>
                ) : null}
                {isVideoVisit ? (
                  <FlexBox
                    data-testid="appointment-video-visit"
                    vSpacingTop={2}
                    flexDirection="row"
                    alignItems="center"
                  >
                    <Svg set="assets" name="VideoVisit" />
                    Video Visit
                  </FlexBox>
                ) : null}
                {displayTime ? (
                  <MuiTypography data-testid="appointment-time" noWrap>
                    {date
                      ? `${formatDateOrdinal(date.toString())} - ${formatTimeWithTimeZone(
                          date.toString()
                        )}`
                      : 'No Time'}
                  </MuiTypography>
                ) : null}
              </MuiGrid>
            </MuiGrid>
          </MuiGrid>
          <MuiGrid item style={{ padding: 0 }}>
            {children ? <>{children}</> : null}
          </MuiGrid>
        </MuiGrid>
      </MuiBox>
    </>
  );
}

export default AppointmentCard;
