import React, { useState } from 'react';
import { connect } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, createStyles, Theme, useTheme } from '@material-ui/core/styles';

import { Color } from 'modules/styles/colors';

import { ConnectCareCheckboxItem, ConnectCareButton } from 'components/ConnectCare';

import {
  MuiDialog,
  MuiDialogTitle,
  MuiDialogContent,
  MuiDialogActions,
  MuiBox,
  MuiFormLabel
} from 'theme/material-ui';

import analyticsService, { AnalyticsEvent } from 'services/AnalyticsService';

import { RootState } from 'store/types';
import * as prefActions from 'store/consumerPreferences/actions';
import * as globalActions from 'store/global/actions';
import { ConsumerSharedUiData } from 'store/consumerPreferences/types';
import {
  currentLocationPathNameSelector,
  previousLocationPathNameSelector
} from 'store/router/selectors';

import { connectCareTermsAndConditionsHtml } from './connectCareTermsAndConditionsHtml';

export interface Props {
  currentUrl?: string;
  referringUrl?: string;
  updateAndRefreshSharedUiData: typeof prefActions.updateAndRefreshSharedUiData;
  setAcceptTemporaryTC: typeof globalActions.setAcceptTemporaryTC;

  isVisible: boolean;
  onClose: () => void;
}

const TermsAndConditionsLabel = () => {
  return <MuiFormLabel>I accept the terms and conditions.</MuiFormLabel>;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actions: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between'
    },
    button: {
      fontSize: theme.typography.fontSize
    }
  })
);

export function TermsAndConditionsDialog({
  isVisible,
  onClose,
  updateAndRefreshSharedUiData,
  setAcceptTemporaryTC,
  currentUrl,
  referringUrl
}: Props) {
  const theme = useTheme();
  const classes = useStyles();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [acceptCheckbox, setAcceptCheckbox] = useState(false);

  const toggleAcceptCheckBox = () => setAcceptCheckbox(!acceptCheckbox);

  const handleAccept = () => {
    const params: Partial<ConsumerSharedUiData> = {
      hasAcceptedConnectCareTermsAndConditions: true
    };

    analyticsService.logEvent(AnalyticsEvent.ConnectCareTCContinueClicked, {
      referringUrl,
      currentUrl
    });

    updateAndRefreshSharedUiData(params);
    setAcceptTemporaryTC('CONNECT_CARE');
  };

  return (
    <MuiDialog
      maxWidth="md"
      fullWidth
      fullScreen={isSmallScreen}
      disableBackdropClick
      disableEscapeKeyDown
      onClose={onClose}
      open={isVisible}
      aria-labelledby="connect-care-disclaimer-dialog-title"
    >
      <MuiDialogTitle id="connect-care-disclaimer-dialog-title">
        Connect Care: Terms & Conditions
      </MuiDialogTitle>
      <MuiDialogContent dividers>
        <MuiBox p={1} bgcolor={Color.foreColor}>
          <iframe
            width="100%"
            height={575}
            title="Terms of use"
            frameBorder="0"
            srcDoc={connectCareTermsAndConditionsHtml}
          />
        </MuiBox>
        <MuiBox pt={2}>
          <ConnectCareCheckboxItem
            data-testid="cc-accept-terms-checkbox"
            required
            checked={acceptCheckbox}
            onChange={toggleAcceptCheckBox}
            label={<TermsAndConditionsLabel />}
          />
        </MuiBox>
      </MuiDialogContent>
      <MuiDialogActions className={classes.actions}>
        <ConnectCareButton className={classes.button} variant="text" onClick={onClose}>
          Decline
        </ConnectCareButton>
        <ConnectCareButton
          data-testid="cc-accept-terms-continue-button"
          className={classes.button}
          disabled={!acceptCheckbox}
          onClick={handleAccept}
        >
          Continue
        </ConnectCareButton>
      </MuiDialogActions>
    </MuiDialog>
  );
}

const mapStateToProps = (state: RootState) => ({
  currentUrl: currentLocationPathNameSelector(state),
  referringUrl: previousLocationPathNameSelector(state)
});

const mapDispatchToProps = {
  updateAndRefreshSharedUiData: prefActions.updateAndRefreshSharedUiData,
  setAcceptTemporaryTC: globalActions.setAcceptTemporaryTC
};

export default connect(mapStateToProps, mapDispatchToProps)(TermsAndConditionsDialog);
