import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { getProfile, updateConsumerProfile } from 'store/profile/actions';
import { consumerDoOnboardingSelector, profileConsumerSelector } from 'store/profile/selectors';
import { Consumer } from 'store/profile/types';
import { RootState } from 'store/types';
import {
  DashboardBannerCard,
  DashboardCards
} from 'components/DashboardCarousel/DashboardBannerCard';

export interface OnboardingCardProps {
  consumer: Consumer;
  close: () => void;
  fetchProfile: () => void;
  updateConsumerDoOnboardingFlag: (update: Consumer) => Promise<any>;
  history: RouteComponentProps['history'];
  totalCards: number;
}

export const DashboardCarouselOnboarding = ({
  history,
  consumer,
  close,
  fetchProfile,
  totalCards,
  updateConsumerDoOnboardingFlag
}: OnboardingCardProps) => {
  const updatedConsumer = { ...consumer, doOnboarding: false };

  const updateAndFetchProfile = async () => {
    await updateConsumerDoOnboardingFlag(updatedConsumer);
    fetchProfile();
  };

  const removeCard = () => {
    updateAndFetchProfile();
    close();
  };

  const navigateToNotifications = () => {
    history.push('/u/manage-account');
  };

  return (
    <DashboardBannerCard
      title="Set up Notifications"
      subTitle="Receive alerts for test results and more!"
      actionOne={{
        text: 'Go to settings',
        onClick: navigateToNotifications
      }}
      imageName={DashboardCards.notification.imageName}
      textColor={DashboardCards.notification.color}
      background={DashboardCards.notification.background}
      onClosePress={removeCard}
      totalCards={totalCards}
    />
  );
};

const mapDispatchToProps = (dispatch: Function) => ({
  updateConsumerDoOnboardingFlag: (updatedConsumer: Consumer) =>
    dispatch(updateConsumerProfile(updatedConsumer, ['DoOnboarding'])),
  fetchProfile: () => dispatch(getProfile('', true))
});

const mapStateToProps = (state: RootState) => ({
  consumer: profileConsumerSelector(state),
  doOnboarding: consumerDoOnboardingSelector(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardCarouselOnboarding);
