import React, { ComponentType, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  downloadProcedures,
  fetchProcedures,
  replyToProcedures
} from 'store/medicalHistory/procedures/actions';
import {
  proceduresDataSelector,
  proceduresDownloadErrorSelector,
  proceduresDownloadingSelector,
  proceduresErrorSelector,
  proceduresLoadingSelector
} from 'store/medicalHistory/procedures/selectors';
import { Procedure } from 'store/medicalHistory/types';
import { RootDispatch, RootState } from 'store/types';
import { MuiBox, MuiPaper, MuiContainer } from 'theme/material-ui';
import Banner from 'screens/MedicalHistory/Banner';
import { oc } from 'ts-optchain';
import { formatDate, formatTime } from 'modules/utils/DateUtils';
import { Color } from 'modules/styles/colors';
import analyticsService, { AmplitudeEventData, AnalyticsEvent } from 'services/AnalyticsService';
import {
  currentLocationPathNameSelector,
  previousLocationPathNameSelector
} from 'store/router/selectors';
import { ProcedureBox, ProcedureInfoBox } from '../styled';
import { MedicalHistoryMessage } from '../common';
import DownloadErrorSnackbar from 'components/DownloadErrorSnackbar/DownloadErrorSnackbar';

export interface Props {
  error: null | Error;
  loading: boolean;
  procedures: Procedure[];
  dispatch: RootDispatch;
  isDownloadingProcedures: boolean;
  downloadError: string | null;
  currentUrl?: string;
  referringUrl?: string;
}

const orEmpty = (str: string | undefined, empty = 'N/A') => str || empty;

const renderData = (procedures: Procedure[]) => {
  return (
    <MuiBox width="100%" pt={3}>
      {procedures.map((procedure: Procedure) => (
        <MuiBox data-testid="past-procedure-list-item" mb={1} key={procedure.id}>
          <MuiPaper elevation={2}>
            <MuiBox p={3}>
              <MuiBox data-testid="past-procedure-name" mb={1} fontWeight="fontWeightBold">
                {procedure.name}
              </MuiBox>
              <ProcedureBox fontWeight="fontWeightBold">
                <ProcedureInfoBox>{procedure?.location || ''}</ProcedureInfoBox>
                <ProcedureInfoBox data-testid="past-procedure-date-performed">
                  {formatDate(procedure.datePerformed)}
                  &nbsp;{formatTime(procedure.datePerformed)}
                </ProcedureInfoBox>
              </ProcedureBox>
              <MuiPaper>
                <ProcedureBox p={2} bgcolor={Color.foreColor}>
                  <ProcedureBox>
                    <ProcedureInfoBox>Performed by:</ProcedureInfoBox>
                    <ProcedureInfoBox data-testid="past-procedure-performed-by">
                      {orEmpty(procedure.performer)}
                    </ProcedureInfoBox>
                  </ProcedureBox>
                  <ProcedureBox>
                    <ProcedureInfoBox>Last reviewed:</ProcedureInfoBox>
                    <ProcedureInfoBox data-testid="past-procedure-last-reviewed-on">
                      {formatDate(oc(procedure).lastUpdated(''))}
                    </ProcedureInfoBox>
                  </ProcedureBox>
                  <ProcedureBox>
                    <ProcedureInfoBox>Status:</ProcedureInfoBox>
                    <ProcedureInfoBox data-testid="past-procedure-status">
                      {orEmpty(procedure.status)}
                    </ProcedureInfoBox>
                  </ProcedureBox>
                  <ProcedureBox>
                    <ProcedureInfoBox>Notes:</ProcedureInfoBox>
                    <ProcedureInfoBox data-testid="past-procedure-notes">
                      {orEmpty(procedure.notes)}
                    </ProcedureInfoBox>
                  </ProcedureBox>
                </ProcedureBox>
              </MuiPaper>
            </MuiBox>
          </MuiPaper>
        </MuiBox>
      ))}
    </MuiBox>
  );
};

export const Procedures = ({
  error,
  loading,
  procedures,
  dispatch,
  isDownloadingProcedures,
  downloadError,
  currentUrl,
  referringUrl
}: Props) => {
  useEffect(() => {
    dispatch(fetchProcedures());
  }, [fetchProcedures]);

  const data: AmplitudeEventData = {
    currentUrl,
    referringUrl
  };

  const handleShareProcedures = () => {
    analyticsService.logEvent(AnalyticsEvent.SharePastProceduresClicked, data);
    dispatch(replyToProcedures(procedures, currentUrl));
  };

  const handleDownloadProcedures = () => {
    analyticsService.logEvent(AnalyticsEvent.DownloadPastProceduresClicked, data);
    dispatch(downloadProcedures());
  };

  return (
    <>
      <DownloadErrorSnackbar hasError={!!downloadError} onRetry={handleDownloadProcedures} />
      <Banner
        onDownloadPress={handleDownloadProcedures}
        onTransmitPress={handleShareProcedures}
        isDownloading={isDownloadingProcedures}
      />
      <MuiContainer maxWidth="lg">
        <MedicalHistoryMessage
          type="procedures"
          loading={loading}
          data={procedures}
          error={error}
        />
        {renderData(procedures)}
      </MuiContainer>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  error: proceduresErrorSelector(state),
  loading: proceduresLoadingSelector(state),
  procedures: proceduresDataSelector(state),
  isDownloadingProcedures: proceduresDownloadingSelector(state),
  downloadError: proceduresDownloadErrorSelector(state),
  currentUrl: currentLocationPathNameSelector(state),
  referringUrl: previousLocationPathNameSelector(state)
});

export default connect(mapStateToProps)(Procedures as ComponentType);
