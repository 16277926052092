import { useEffect, useState } from 'react';
import { CovidScreeningQuestionnaireSelector } from 'store/CovidScreening/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { setQuestionnaire } from 'store/CovidScreening/actions';
import { Questionnaire } from 'store/CovidScreening/types';

export type KeyFormField = 'consent1' | 'consent2' | 'consent3' | 'consent4';

type InputFieldsChecked = { [key in KeyFormField]: boolean };

const getConsentObject = (questionnaire: Questionnaire) => ({
  consent1: questionnaire.consent1 === undefined ? false : questionnaire.consent1,
  consent2: questionnaire.consent2 === undefined ? false : questionnaire.consent2,
  consent3: questionnaire.consent3 === undefined ? false : questionnaire.consent3,
  consent4: questionnaire.consent4 === undefined ? false : questionnaire.consent4
});

export default function useCovidAgreementGuest() {
  const dispatch = useDispatch();
  const questionnaire = useSelector(CovidScreeningQuestionnaireSelector);
  const [requiredField, setRequiredField] = useState<InputFieldsChecked>(
    getConsentObject(questionnaire)
  );

  useEffect(() => {
    setRequiredField(getConsentObject(questionnaire));
  }, [questionnaire]);

  const updateRequiredFields = (key: KeyFormField) => {
    const newQuestionnaireData = {
      ...questionnaire,
      ...requiredField,
      [key]: !requiredField[key]
    };

    dispatch(setQuestionnaire(newQuestionnaireData));
  };

  const confirmAgreementGuest = () => {
    const newQuestionnaireData = {
      ...questionnaire,
      ...requiredField,
      Q_attest_to_accurate_and_complete: 'Yes'
    };

    dispatch(setQuestionnaire(newQuestionnaireData));
  };

  return {
    confirmAgreementGuest,
    requiredField,
    updateRequiredFields
  };
}
