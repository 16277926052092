import { ProxyFormProps } from './ProxyFormScreen';
import { LOCATION_STATES } from 'modules/constants/LocationStates';
import { exitModalText } from 'modules/constants/exitConfirmModal';
import { AppName, getAppName } from 'modules/utils/ConfigUtils';

export interface ProxyFormFieldProps {
  idx: number;
  key: keyof ProxyFormProps & string;
  fieldType?: string;
  props: {
    label: string;
    placeholder?: string;
    accessibilityLabel: string;
    icon?: string;
    // Generic Options
    required?: boolean;
    multiline?: boolean;
    rowsMax?: number;
    maxLength?: number;
    fieldHeight?: string;
    widthPercentage?: number;
    // MaskedInput Options
    options?: SelectItem[];
  };
}

export interface ProxyReviewContactFieldProps {
  idx: number;
  key: string;
  props: {
    label: string;
    placeholder?: string;
    accessibilityLabel: string;
    icon?: string;
    // Generic Options
    required?: boolean;
    multiline?: boolean;
    // rowsMax?: number;
    maxLength?: number;
    fieldHeight?: string;
    // MaskedInput Options
    options?: {
      mask: (string | RegExp)[];
    };
  };
}

export enum ProxyFormTypeField {
  SELECT = 'select',
  DEFAULT = 'default',
  DATE = 'date'
}

export interface SelectItem {
  value: string;
  label: string;
}

export const genders: SelectItem[] = [
  { value: 'Male', label: 'Male' },
  { value: 'Female', label: 'Female' },
  { value: 'Other', label: 'Other' },
  { value: 'Prefer not to respond', label: 'Prefer not to respond' }
];

export const minorRelationship: SelectItem[] = [
  { value: 'Child', label: 'Child' },
  { value: 'Legal dependent', label: 'Legal dependent' },
  { value: 'Stepchild', label: 'Stepchild' },
  { value: 'Foster child', label: 'Foster child' },
  { value: 'Other', label: 'Other' }
];

export const minorAddress: SelectItem[] = [
  { value: 'yes', label: "Yes, this is the minor's address" },
  { value: 'no', label: "No, this is not the minor's address" }
];

export const PROXY_CONFIRM_DIALOG_TEXT = exitModalText('Proxy Request');

export const PROXY_FORM_FIELDS: ProxyFormFieldProps[] = [
  {
    idx: 0,
    key: 'firstName',
    fieldType: ProxyFormTypeField.DEFAULT,
    props: {
      label: "Minor's Legal First Name",
      placeholder: 'Enter First Name',
      required: true,
      accessibilityLabel: 'First name text input',
      maxLength: 50
    }
  },
  {
    idx: 1,
    key: 'middleName',
    fieldType: ProxyFormTypeField.DEFAULT,
    props: {
      label: "Minor's Legal Middle Name",
      placeholder: 'Enter Middle Name',
      required: false,
      accessibilityLabel: 'Middle name text input',
      maxLength: 50
    }
  },
  {
    idx: 2,
    key: 'lastName',
    fieldType: ProxyFormTypeField.DEFAULT,
    props: {
      label: "Minor's Legal Last Name",
      placeholder: 'Enter Last Name',
      required: true,
      accessibilityLabel: 'Last name text input',
      maxLength: 50
    }
  },
  {
    idx: 3,
    key: 'gender',
    fieldType: ProxyFormTypeField.SELECT,
    props: {
      label: 'What is the sex of the minor?',
      placeholder: 'Select gender',
      required: true,
      accessibilityLabel: 'Gender selector',
      options: genders
    }
  },
  {
    idx: 4,
    key: 'birthDate',
    fieldType: ProxyFormTypeField.DATE,
    props: {
      label: "Minor's date of birth",
      required: true,
      accessibilityLabel: 'Date of birth input'
    }
  },
  {
    idx: 5,
    key: 'relationship',
    fieldType: ProxyFormTypeField.SELECT,
    props: {
      label: 'The minor is your',
      placeholder: 'Select relationship',
      required: true,
      accessibilityLabel: 'Relationship selector',
      options: minorRelationship
    }
  },
  {
    idx: 6,
    key: 'address',
    fieldType: ProxyFormTypeField.SELECT,
    props: {
      label: "Is this the minor's home address?",
      placeholder: 'Select address',
      required: true,
      accessibilityLabel: 'Address selector',
      options: minorAddress
    }
  }
];

export const PROXY_REVIEW_CONTACT_FIELDS: ProxyReviewContactFieldProps[] = [
  {
    idx: 0,
    key: 'phone',
    props: {
      label: 'Phone',
      required: true,
      accessibilityLabel: 'Phone number text input',
      maxLength: 13
    }
  },
  {
    idx: 1,
    key: 'emailAddress',
    props: {
      label: 'Email Address',
      required: true,
      accessibilityLabel: 'Email address text input',
      maxLength: 120
    }
  }
];
export const MINOR_REVIEW_INFO = {
  singleMinorCheckboxText:
    "I certify that I have the legal right to this minor's medical information.",
  multipleMinorCheckboxText:
    "I certify that I have the legal right to these minors' medical information.",
  singleMinorCheckboxDesc: `Access to your minor's record in ${getAppName(
    AppName.secondary,
    'the'
  )} does not affect any legal right you may have to access the minor's medical record by other means. By submitting this form, you are attesting to your status as the minor's parent or legal guardian with rights to view their medical information. `,
  multipleMinorCheckboxDesc: `Access to your minors' records in ${getAppName(
    AppName.secondary,
    'the'
  )} does not affect any legal right you may have to access the minors' medical records by other means. By submitting this form, you are attesting to your status as the minors' parent or legal guardian with rights to view their medical information. `
};

export const ADDRESS_FIELDS: ProxyFormFieldProps[] = [
  {
    idx: 8,
    key: 'street',
    fieldType: ProxyFormTypeField.DEFAULT,
    props: {
      label: "Please provide the minor's primary address",
      placeholder: 'Street address',
      required: true,
      accessibilityLabel: 'Street address text input',
      maxLength: 500
    }
  },
  {
    idx: 9,
    key: 'housingType',
    fieldType: ProxyFormTypeField.DEFAULT,
    props: {
      label: '',
      placeholder: 'Apt, suite, etc.',
      required: false,
      accessibilityLabel: 'housing type text input'
    }
  },
  {
    idx: 10,
    key: 'city',
    fieldType: ProxyFormTypeField.DEFAULT,
    props: {
      label: 'City',
      placeholder: 'City Name',
      required: true,
      accessibilityLabel: 'City name text input',
      maxLength: 50,
      widthPercentage: 55
    }
  },
  {
    idx: 11,
    key: 'state',
    fieldType: ProxyFormTypeField.SELECT,
    props: {
      label: 'State',
      required: true,
      placeholder: 'Select state',
      accessibilityLabel: 'State text input',
      options: LOCATION_STATES,
      widthPercentage: 40
    }
  },
  {
    idx: 12,
    key: 'postalCode',
    fieldType: ProxyFormTypeField.DEFAULT,
    props: {
      label: 'Zip / Postal Code',
      placeholder: '00000',
      required: true,
      accessibilityLabel: 'Zip code text input',
      maxLength: 10
    }
  }
];

export const OTHER_RELATIONSHIP_FIELD: ProxyFormFieldProps = {
  idx: 7,
  key: 'otherRelationship',
  props: {
    label: 'If other, please explain',
    placeholder: 'Enter comment',
    required: true,
    accessibilityLabel: 'Other relationship input',
    maxLength: 500
  }
};
