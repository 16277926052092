import dayjs from 'dayjs';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import { TransformedAssets } from 'store/billing/financialAssistance/createApplication/types';

const splitPhone = (phoneNumber: string) => {
  const phoneArea = phoneNumber.slice(1, 4); // (123) 789-1234
  const phone = phoneNumber.slice(6, 9) + phoneNumber.slice(10);
  return { phoneArea, phone };
};

const mergeSSN = (SSN: string) => {
  return SSN.replace(/-/g, '');
};

export const transformBasicInfo = (basicInfoData: any) => {
  const valueObj = cloneDeep(basicInfoData);

  Object.keys(valueObj).forEach(key => valueObj[key] === '' && delete valueObj[key]);

  if (valueObj.patientSSN) {
    valueObj.patientSSN = mergeSSN(valueObj.patientSSN);
  }
  if (valueObj.guarantorSSN) {
    valueObj.guarantorSSN = mergeSSN(valueObj.guarantorSSN);
  }

  if (valueObj.relationship === 'self') {
    valueObj.guarantorFirstName = valueObj.patientFirstName;
    valueObj.guarantorLastName = valueObj.patientLastName;
    valueObj.guarantorBirthDate = valueObj.patientBirthDate;
  }

  if (valueObj.homePhone) {
    const home = splitPhone(valueObj.homePhone);
    valueObj.homePhoneArea = home.phoneArea;
    valueObj.homePhone = home.phone;
  }

  if (valueObj.cellPhone) {
    const cell = splitPhone(valueObj.cellPhone);
    valueObj.cellPhoneArea = cell.phoneArea;
    valueObj.cellPhone = cell.phone;
  }

  if (valueObj.workPhone) {
    const work = splitPhone(valueObj.workPhone);
    valueObj.workPhoneArea = work.phoneArea;
    valueObj.workPhone = work.phone;
  }

  if (valueObj.patientBirthDate) {
    valueObj.patientBirthDate = dayjs(valueObj.patientBirthDate).format('YYYY-MM-DD');
  }
  if (valueObj.guarantorBirthDate) {
    valueObj.guarantorBirthDate = dayjs(valueObj.guarantorBirthDate).format('YYYY-MM-DD');
  }

  let address;
  if (valueObj.addressLineOne && valueObj.addressLineTwo) {
    address = `${valueObj.addressLineOne} ${valueObj.addressLineTwo}`;
  } else {
    address = valueObj.addressLineOne;
  }
  valueObj.address = address;
  delete valueObj.addressLineOne;
  delete valueObj.addressLineTwo;
  if (valueObj.extra1AddressFrom) {
    valueObj.extra1AddressFrom = dayjs(valueObj.extra1AddressFrom).format('MM/YYYY');
  }
  if (valueObj.extra1AddressTo) {
    valueObj.extra1AddressTo = dayjs(valueObj.extra1AddressTo).format('MM/YYYY');
  }
  if (valueObj.extra2AddressFrom) {
    valueObj.extra2AddressFrom = dayjs(valueObj.extra2AddressFrom).format('MM/YYYY');
  }
  if (valueObj.extra2AddressTo) {
    valueObj.extra2AddressTo = dayjs(valueObj.extra2AddressTo).format('MM/YYYY');
  }
  const extraAddresses = [];
  if (valueObj.extra1AddressLineOne && valueObj.extra1AddressLineTwo) {
    const extraAddress1 = {
      address: `${valueObj.extra1AddressLineOne} ${valueObj.extra1AddressLineTwo}`,
      city: valueObj.extra1AddressCity,
      state: valueObj.extra1AddressState,
      zip: valueObj.extra1AddressZip,
      from: valueObj.extra1AddressFrom,
      to: valueObj.extra1AddressTo
    };
    extraAddresses.push(extraAddress1);
  } else if (valueObj.extra1AddressLineOne) {
    const extraAddress1 = {
      address: valueObj.extra1AddressLineOne,
      city: valueObj.extra1AddressCity,
      state: valueObj.extra1AddressState,
      zip: valueObj.extra1AddressZip,
      from: valueObj.extra1AddressFrom,
      to: valueObj.extra1AddressTo
    };
    extraAddresses.push(extraAddress1);
  }

  if (valueObj.extra2AddressLineOne && valueObj.extra2AddressLineTwo) {
    const extraAddress2 = {
      address: `${valueObj.extra2AddressLineOne} ${valueObj.extra2AddressLineTwo}`,
      city: valueObj.extra2AddressCity,
      state: valueObj.extra2AddressState,
      zip: valueObj.extra2AddressZip,
      from: valueObj.extra2AddressFrom,
      to: valueObj.extra2AddressTo
    };
    extraAddresses.push(extraAddress2);
  } else if (valueObj.extra2AddressLineOne) {
    const extraAddress2 = {
      address: valueObj.extra2AddressLineOne,
      city: valueObj.extra2AddressCity,
      state: valueObj.extra2AddressState,
      zip: valueObj.extra2AddressZip,
      from: valueObj.extra2AddressFrom,
      to: valueObj.extra2AddressTo
    };
    extraAddresses.push(extraAddress2);
  }

  valueObj.addresses = extraAddresses;

  delete valueObj.extra1AddressLineOne;
  delete valueObj.extra1AddressLineTwo;
  delete valueObj.extra1AddressCity;
  delete valueObj.extra1AddressState;
  delete valueObj.extra1AddressZip;
  delete valueObj.extra1AddressFrom;
  delete valueObj.extra1AddressTo;

  delete valueObj.extra2AddressLineOne;
  delete valueObj.extra2AddressLineTwo;
  delete valueObj.extra2AddressCity;
  delete valueObj.extra2AddressState;
  delete valueObj.extra2AddressZip;
  delete valueObj.extra2AddressFrom;
  delete valueObj.extra2AddressTo;

  return valueObj;
};

export const transformHousehold = (householdData: any) => {
  const valueObj = cloneDeep(householdData);

  if (valueObj.spouseIncome === '') {
    valueObj.spouseIncome = 0;
  }
  if (valueObj.pension === '') {
    valueObj.pension = 0;
  }
  if (valueObj.childSupport === '') {
    valueObj.childSupport = 0;
  }
  if (valueObj.alimony === '') {
    valueObj.alimony = 0;
  }
  if (valueObj.grants === '') {
    valueObj.grants = 0;
  }
  if (valueObj.otherIncome === '') {
    valueObj.otherIncome = 0;
  }
  if (valueObj.otherIncomeSource === '') {
    valueObj.otherIncomeSource = 0;
  }

  if (valueObj.grantPeriod === '') {
    valueObj.grantPeriod = 'Monthly';
  }

  if (valueObj.otherPeriod === '') {
    valueObj.otherPeriod = 'Monthly';
  }

  Object.keys(valueObj).forEach(key => valueObj[key] === '' && delete valueObj[key]);

  if (valueObj.spouseBirthDate) {
    valueObj.spouseBirthDate = dayjs(valueObj.spouseBirthDate).format('YYYY-MM-DD');
  }

  if (valueObj.spouseSSN) {
    valueObj.spouseSSN = mergeSSN(valueObj.spouseSSN);
  }

  if (valueObj.spouseHomePhone) {
    const home = splitPhone(valueObj.spouseHomePhone);
    valueObj.spouseHomePhoneArea = home.phoneArea;
    valueObj.spouseHomePhone = home.phone;
  }

  if (valueObj.spouseCellPhone) {
    const cell = splitPhone(valueObj.spouseCellPhone);
    valueObj.spouseCellPhoneArea = cell.phoneArea;
    valueObj.spouseCellPhone = cell.phone;
  }

  if (valueObj.spouseWorkPhone) {
    const work = splitPhone(valueObj.spouseWorkPhone);
    valueObj.spouseWorkPhoneArea = work.phoneArea;
    valueObj.spouseWorkPhone = work.phone;
  }

  if (valueObj.members && valueObj.members.length) {
    valueObj.members.forEach((member: object, index: number) => {
      delete valueObj.members[index].key;
      valueObj.members[index].birthDate = dayjs(valueObj.members[index].birthDate).format(
        'YYYY-MM-DD'
      );
    });
  }
  delete valueObj.hasIncome;
  delete valueObj.hasPension;
  delete valueObj.hasChildSupport;
  delete valueObj.hasAlimony;
  delete valueObj.hasGrants;
  delete valueObj.hasOther;

  return valueObj;
};

export const transformAssets = (assetsData: any): TransformedAssets => {
  const valueObj = cloneDeep(assetsData);

  Object.keys(valueObj).forEach(key => valueObj[key] === '' && delete valueObj[key]);
  const checkBoxFields = [
    'hasInsured',
    'hasOtherInsured',
    'hasFlex',
    'hasReimburse',
    'hasDeny',
    'hasStateAst',
    'hasCobra',
    'hasAssistance'
  ];
  checkBoxFields.forEach((item: string) => {
    valueObj[item] = 'N';
  });

  const { checked } = valueObj;
  if (!isEmpty(checked)) {
    checked.forEach((item: string) => {
      valueObj[item] = 'Y';
    });
  }
  delete valueObj.checked;
  valueObj.cashInstitution = valueObj.cashInstitution || 'NA';
  valueObj.cash = valueObj.cash || '0';
  valueObj.savingInstitution = valueObj.savingInstitution || 'NA';
  valueObj.saving = valueObj.saving || '0';
  valueObj.checkingInstitution = valueObj.checkingInstitution || 'NA';
  valueObj.checking = valueObj.checking || '0';
  valueObj.stocksInstitution = valueObj.stocksInstitution || 'NA';
  valueObj.stocks = valueObj.stocks || '0';

  return valueObj;
};
