import { useState, useEffect } from 'react';

import { useTimeout } from 'lib/hooks/useTimeout';
import { useInterval } from 'lib/hooks/useInterval';

export interface CountDownTimerProps {
  duration: number;
  onTimeout: () => void;
  onUpdateTimer?: (currentTimer: number) => void;
  onTimeoutNewValue?: number;
}

const pad = (timer: number) => {
  return `0${timer}`.slice(-2);
};

export const CountDownTimer = ({
  duration,
  onTimeout,
  onUpdateTimer,
  onTimeoutNewValue = 0
}: CountDownTimerProps) => {
  const [count, setCount] = useState(duration);

  useEffect(() => {
    if (onUpdateTimer) {
      onUpdateTimer(count);
    }
  }, [count]);

  useTimeout(() => onTimeout(), count, [count]);
  useInterval(
    () =>
      setCount(prevCount => {
        if (prevCount > 0) {
          return prevCount - 1000;
        }
        return onTimeoutNewValue;
      }),
    1000
  );

  const minutes = Math.floor((count % 36e5) / 6e4);
  const seconds = Math.floor((count % 6e4) / 1000);

  return `${pad(minutes)}:${pad(seconds)}`;
};
