import React from 'react';
import { MuiGrid } from 'theme/material-ui';
import { Spacing } from 'modules/styles/variables';
import { UploadInsuranceCard } from './InsuranceCard';
import { eVisitFrontDoorNewInsuranceScreen } from 'lib/triage/constants';
import InfoLabel from 'components/InfoLabel';
import { FileUploadButton } from 'components/FileUploadButton';
import { UploadState } from '../types';

type SelectedImages = {
  front: string | undefined;
  back: string | undefined;
};
const { errors } = eVisitFrontDoorNewInsuranceScreen;

const TryAgainButton = ({ upload }: { upload: (file: File) => void }) => {
  return (
    <FileUploadButton
      color="primary"
      data-testid="add-insurance-button"
      fullWidth
      hasIcon={false}
      onClick={upload}
      variant="contained"
      image
    >
      Try again
    </FileUploadButton>
  );
};

const CardMuiGridItem = ({ children }: { children: any }) => (
  <MuiGrid
    item
    alignItems="center"
    direction="column"
    justify="flex-start"
    style={{ display: 'flex' }}
  >
    {children}
  </MuiGrid>
);

export default ({
  uploadImage,
  status,
  selectedImages,
  isPrimary
}: {
  uploadImage: Function;
  status: UploadState;
  selectedImages?: SelectedImages;
  isPrimary?: Boolean;
}) => {
  const errorMessageDefault = errors.default;
  let displayErrorMessage = errorMessageDefault;
  const errorMessage = status.frontSide.errorMessage || status.backSide.errorMessage;
  if (errorMessage === errors.attachmentLimitServer) {
    displayErrorMessage = errors.attachmentLimit;
  } else if (errorMessage === errors.fileSize) {
    displayErrorMessage = errorMessage;
  } else if (errorMessage) {
    displayErrorMessage = errorMessageDefault;
  }

  const upload = (file: File) => {
    const isBackSide = !!status.backSide.isError;
    uploadImage(isBackSide, isPrimary, file);
  };
  return (
    <MuiGrid container spacing={Spacing.small} justify="center">
      <CardMuiGridItem>
        <UploadInsuranceCard
          status={status.frontSide}
          uploadImage={uploadImage}
          selectedImage={selectedImages?.front}
          isPrimary={isPrimary}
          isBackSide={false}
        />
        {status.frontSide.isError ? <TryAgainButton upload={upload} /> : null}
      </CardMuiGridItem>
      <CardMuiGridItem>
        <UploadInsuranceCard
          status={status.backSide}
          isBackSide
          uploadImage={uploadImage}
          selectedImage={selectedImages?.back}
          isPrimary={isPrimary}
        />
        {status.backSide.isError ? <TryAgainButton upload={upload} /> : null}
      </CardMuiGridItem>
      {status.frontSide.isError || status.backSide.isError ? (
        <MuiGrid item>
          <InfoLabel isError text={displayErrorMessage} />
        </MuiGrid>
      ) : null}
    </MuiGrid>
  );
};
