import React, { useEffect } from 'react';

import { MuiBox, MuiLinearProgress } from 'theme/material-ui';
import { StepperProps } from 'components/UI/Stepper';
import styled from 'styled-components';

import { useBookingStepAction } from './BookingStepAction';
import { Color } from 'modules/styles/colors';

const StyledLinearProgress = styled(MuiLinearProgress)`
  && {
    & .MuiLinearProgress-barColorPrimary {
      background-color: ${Color.secondary};
    }
    background-color: ${Color.cobaltTint};
    height: 14px;
  }
`;

function BookingStepper<T>({ activeStep, steps }: StepperProps<T>) {
  const { setStepper } = useBookingStepAction();

  useEffect(() => {
    setStepper({ steps, activeStep });
  }, [steps, activeStep]);

  const progress = Math.ceil(((activeStep + 1) / steps.length) * 100);
  return (
    <MuiBox>
      <StyledLinearProgress variant="determinate" value={progress} />
    </MuiBox>
  );
}

BookingStepper.defaultProps = {
  stepToValue: (step: any) => (step ? step.path : '')
};

export default BookingStepper;
