import React from 'react';

export default (props: any) => ({
  svg: (
    <g fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 1.5C10.2098 1.5 8.4929 2.21116 7.22703 3.47703C5.96116 4.7429 5.25 6.45979 5.25 8.25C5.25 8.95633 5.52296 10.0206 6.02181 11.3299C6.51199 12.6165 7.18898 14.0615 7.92893 15.4987C9.40891 18.3732 11.1134 21.1639 12 22.5004L11.375 22.915L12 22.5004L12.625 22.915L12 22.5004C12.8866 21.1639 14.5911 18.3732 16.0711 15.4987C16.811 14.0615 17.488 12.6165 17.9782 11.3299C18.477 10.0206 18.75 8.95633 18.75 8.25C18.75 6.45979 18.0388 4.7429 16.773 3.47703C15.5071 2.21116 13.7902 1.5 12 1.5ZM6.16637 2.41637C7.71354 0.869194 9.81196 0 12 0C14.188 0 16.2865 0.869194 17.8336 2.41637C19.3808 3.96354 20.25 6.06196 20.25 8.25C20.25 9.24717 19.8887 10.5284 19.3799 11.864C18.8624 13.2222 18.1584 14.7215 17.4047 16.1853C15.8974 19.1128 14.1644 21.9511 13.25 23.3296C13.1132 23.5359 12.9274 23.7051 12.7093 23.8221C12.4912 23.9392 12.2475 24.0004 12 24.0004C11.7525 24.0004 11.5088 23.9392 11.2907 23.8221C11.0726 23.7051 10.8868 23.5359 10.75 23.3296C9.83557 21.9511 8.10259 19.1128 6.59532 16.1853C5.84165 14.7215 5.13757 13.2222 4.62009 11.864C4.11129 10.5284 3.75 9.24717 3.75 8.25C3.75 6.06196 4.61919 3.96354 6.16637 2.41637ZM8.81802 5.06802C9.66193 4.22411 10.8065 3.75 12 3.75C13.1935 3.75 14.3381 4.22411 15.182 5.06802C16.0259 5.91193 16.5 7.05653 16.5 8.25C16.5 9.44347 16.0259 10.5881 15.182 11.432C14.3381 12.2759 13.1935 12.75 12 12.75C10.8065 12.75 9.66193 12.2759 8.81802 11.432C7.97411 10.5881 7.5 9.44347 7.5 8.25C7.5 7.05653 7.97411 5.91193 8.81802 5.06802ZM12 5.25C11.2044 5.25 10.4413 5.56607 9.87868 6.12868C9.31607 6.69129 9 7.45435 9 8.25C9 9.04565 9.31607 9.80871 9.87868 10.3713C10.4413 10.9339 11.2044 11.25 12 11.25C12.7956 11.25 13.5587 10.9339 14.1213 10.3713C14.6839 9.80871 15 9.04565 15 8.25C15 7.45435 14.6839 6.69129 14.1213 6.12868C13.5587 5.56607 12.7956 5.25 12 5.25Z"
        fill={props.color ? props.color : 'black'}
      />
    </g>
  ),
  viewBox: '0 0 26 26'
});
