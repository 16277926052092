import styled from 'styled-components';

import { dfdDefaultTheme, MuiBox, MuiButton, MuiPaper, MuiTypography } from 'theme/material-ui';

import { Color } from 'modules/styles/colors';
import { FontWeight } from 'modules/styles/variables';

export const Typography = styled(MuiTypography)`
  && {
    font-size: 14px;
    color: ${Color.white};
    text-align: center;
  }
`;

// WaitingRoom Banner
export const BannerContainer = styled(MuiPaper)`
  && {
    display: flex;
    height: 100%;
    overflow: hidden;
    ${dfdDefaultTheme.breakpoints.down('xs')} {
      display: block;
    }
  }
`;

export const MediaContainer = styled(MuiBox)`
  && {
    flex: 1 1 auto;
    ${dfdDefaultTheme.breakpoints.down('xs')} {
      min-width: 0;
    }
  }
`;

export const Content = styled(MuiBox)`
  && {
    padding: ${dfdDefaultTheme.spacing(2)}px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
`;

export const ButtonGroup = styled(MuiBox)`
  && {
    display: block;
    margin-bottom: -${dfdDefaultTheme.spacing(0.5)}px;
    margin-top: -${dfdDefaultTheme.spacing(0.5)}px;
    margin-right: 0;
    margin-left: 0;
    ${dfdDefaultTheme.breakpoints.down('xs')} {
      display: flex;
      flex-direction: row;
      margin-right: -${dfdDefaultTheme.spacing(0.5)}px;
      margin-left: -${dfdDefaultTheme.spacing(0.5)}px;
      margin-top: 0;
      margin-bottom: 0;
    }
  }
`;

export const BannerAction = styled(MuiButton)`
  && {
    margin-bottom: ${dfdDefaultTheme.spacing(0.5)}px;
    margin-top: ${dfdDefaultTheme.spacing(0.5)}px;
    margin-right: 0;
    margin-left: 0;
  }
`;

export const BlueLetter = styled.span`
  color: ${Color.primary};
  font-weight: ${FontWeight.bold};
`;
