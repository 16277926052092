import TextField from '@material-ui/core/TextField';
import { AutocompleteInputChangeReason, FilterOptionsState } from '@material-ui/lab';
import Autocomplete, {
  RenderGroupParams,
  RenderInputParams,
  RenderOptionState
} from '@material-ui/lab/Autocomplete';
import React, { ChangeEvent, ReactNode } from 'react';
import { StyledAutoCompletePaper } from './styled';

export interface SearchOption {
  displayName?: string;
  providerId?: string;
  value?: string;
  onClick?: () => void;
  disabled?: boolean;
  [key: string]: any;
}

interface Props {
  disableSelectOnClose?: boolean;
  filterOptions?: (options: any[], state: FilterOptionsState) => any[];
  freeSolo?: boolean;
  isControlled?: boolean;
  getOptionDisabled?: (option: any) => boolean;
  groupBy?: (option: any) => string;
  id?: string;
  ListboxComponent: object;
  noOptionsText?: string;
  onBlur?: ((event: ChangeEvent<{}>, value: any) => void) | undefined;
  onChange?:
    | ((event: ChangeEvent<{}>, value: any, reason: AutocompleteInputChangeReason) => void)
    | undefined;
  onFocus?: ((event: ChangeEvent<{}>, value: any) => void) | undefined;
  onInputChange?: ((event: ChangeEvent<{}>, value: any) => void) | undefined;
  options: any[];
  open: boolean;
  placeholder?: string;
  renderGroup?: (params: RenderGroupParams) => ReactNode;
  renderInput?: (params: RenderInputParams) => ReactNode;
  renderOption: (option: SearchOption, state: RenderOptionState) => React.ReactNode;
  value: string;
}

export function BasicAutoComplete(props: Props) {
  const {
    filterOptions,
    groupBy,
    isControlled = false,
    onChange,
    onBlur,
    onFocus,
    options,
    placeholder,
    renderInput,
    freeSolo,
    open,
    renderOption,
    noOptionsText,
    renderGroup,
    value,
    ListboxComponent,
    getOptionDisabled
  } = props;
  const renderDefault = (params: RenderInputParams) => (
    <TextField placeholder={placeholder} {...params} fullWidth value={value} onChange={onChange} />
  );

  return (
    <Autocomplete
      defaultValue={!isControlled ? { value } : undefined}
      value={{ value }}
      clearOnEscape={false}
      clearOnBlur={false}
      filterOptions={filterOptions}
      freeSolo={freeSolo}
      popupIcon={false}
      options={options}
      open={open}
      groupBy={groupBy}
      renderGroup={renderGroup}
      renderInput={renderInput || renderDefault}
      onBlur={onBlur}
      onFocus={onFocus}
      PaperComponent={params => <StyledAutoCompletePaper {...params} />}
      ListboxComponent={ListboxComponent}
      noOptionsText={noOptionsText}
      onChange={onChange}
      renderOption={renderOption}
      placeholder={placeholder}
      getOptionLabel={option => option.value || ''}
      getOptionDisabled={getOptionDisabled}
    />
  );
}
