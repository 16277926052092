import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import { Dialog, DialogContent, Box as MaterialBox } from '@material-ui/core';
import Box from 'components/UI/Layout/Box';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import insImage from 'assets/insuranceNumbers2.png';
import scripiusIdImg from 'assets/scripiusId.png';
import smScripiusIdImg from 'assets/smScripiusId.png';
import smHealthIdImg from 'assets/insuranceNumbersMobile.png';
import { Color } from 'modules/styles/colors';
import { IconSize, Spacing } from 'modules/styles/variables';
import { FontSize } from 'modules/styles/variables.web';
import { MuiBox, MuiTypography, MuiDivider } from 'theme/material-ui';
import Svg from './UI/Svg/Svg';
import CloseButton from './UI/Button/CloseButton';
import LinkingServices from 'services/LinkingServices';
import styled from 'styled-components';
import TypographyInlineWrapper from './UI/Typography/TypographyInLineWrapper';
import { RedirectBrand, getRegisterBrand } from 'store/register';
import { AppName, getAppName } from 'modules/utils/ConfigUtils';
import { getBrandColor, getBrandLinkColor } from 'store/register/utils';

export const TypographyWrapper = styled(MuiTypography)`
  display: inline-block;
  cursor: pointer;
  letter-spacing: 0.33px;
`;

export const TypographyHeadingWrapper = styled(MuiTypography)`
  letter-spacing: 0.48px;
  line-height: 24px;
`;

const useStyles = makeStyles(theme => ({
  arrow: {
    color: theme.palette.common.white,
    fontSize: FontSize.title,
    '&::before': {
      boxShadow: '0 -2px 2px 1px rgba(157,155,155,0.18)'
    }
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    maxWidth: window.innerWidth >= 786 ? '786px' : `${window.innerWidth}px`,
    boxShadow: '0 5px 10px 0 rgba(0,0,0,0.3)',
    padding: '0px'
  },
  infoIcon: {
    minWidth: '20px',
    padding: '6px 4Px'
  },
  popper: {
    pointerEvents: 'auto'
  }
}));

function getBrandTooltipColor(usePrimary = true) {
  const redirectBrand = getRegisterBrand();
  return getBrandColor({ redirectBrand }, usePrimary);
}

function getBrandTooltipLinkColor() {
  const redirectBrand = getRegisterBrand();
  return getBrandLinkColor({ redirectBrand });
}

export const CustomTooltip = () => {
  const [open, setOpen] = useState(false);
  const color = getBrandTooltipColor(true);
  const classes = useStyles();
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));
  const toggleToolTip = () => {
    setOpen(!open);
  };
  return (
    <>
      {isLargeScreen ? (
        <Tooltip
          open={open}
          placement="bottom"
          title={<HealthIdHelpText handleClose={toggleToolTip} />}
          classes={classes}
          arrow
        >
          <Button onClick={toggleToolTip} className={classes.infoIcon}>
            <Svg name="InfoIcon" size={IconSize.small} color={color} />
          </Button>
        </Tooltip>
      ) : (
        <>
          <TooltipIcon toggleToolTip={toggleToolTip} />
          {open && <HealthIdHelpTextSmallScreen toggleToolTip={toggleToolTip} />}
        </>
      )}
    </>
  );
};

export const CustomDOBTooltip = () => {
  const [open, setOpen] = useState(false);
  const color = getBrandTooltipColor(true);
  const classes = useStyles();
  const toggleToolTip = () => {
    setOpen(!open);
  };
  return (
    <Tooltip
      open={open}
      placement="bottom"
      title={<HealthDOBHelpText handleClose={toggleToolTip} />}
      classes={classes}
      arrow
      pointerEvents
    >
      <Button onClick={toggleToolTip} className={classes.infoIcon}>
        <Svg name="InfoIcon" size={IconSize.small} color={color} />
      </Button>
    </Tooltip>
  );
};
export const RegisterErrorInfoTooltip = ({ handleReviewPersonalInfo, handleForgetUsername }) => {
  const [open, setOpen] = React.useState(false);
  const color = getBrandTooltipColor(true);
  const classes = useStyles();
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));
  const toggleToolTip = () => {
    setOpen(!open);
  };
  return (
    <Tooltip
      open={open}
      placement="bottom"
      title={
        <RegisterErrorInfoTooltipText
          handleClose={toggleToolTip}
          handleReviewPersonalInfo={handleReviewPersonalInfo}
          handleForgetUsername={handleForgetUsername}
        />
      }
      classes={classes}
      arrow={isLargeScreen}
      pointerEvents
    >
      <Button onClick={toggleToolTip} className={classes.infoIcon}>
        <Svg name="InfoIcon" set="assets" size={IconSize.small} color={color} />
      </Button>
    </Tooltip>
  );
};

const TooltipIcon = ({ toggleToolTip }) => {
  const color = getBrandTooltipColor(true);
  const classes = useStyles();
  return (
    <Button onClick={toggleToolTip} className={classes.infoIcon}>
      <Svg name="InfoIcon" set="assets" size={IconSize.base} color={color} />
    </Button>
  );
};

const HealthIdHelpText = ({ handleClose }) => {
  return (
    <MuiBox width={window.innerWidth >= 786 ? 760 : window.innerWidth - 26}>
      <MuiBox
        alignItems="flex-end"
        display="inline-flex"
        width="100%"
        justifyContent="flex-end"
        pt={1}
        pr={2}
      >
        <CloseButton onClick={handleClose} color={Color.black}>
          <Svg color={Color.black} name="close" size={IconSize.small} set="material" />
        </CloseButton>
      </MuiBox>
      <img
        alt="Health Identification Number locations for SelectHealth, SelectHealth pharmacy, Scripius, and Medicaid users."
        height="100%"
        width="100%"
        src={getRegisterBrand() === RedirectBrand.SC ? scripiusIdImg : insImage}
      />
    </MuiBox>
  );
};

const HealthDOBHelpText = ({ handleClose }) => {
  const brandTooltipColor = getBrandTooltipColor(true);
  const brandTooltipTextColor = getBrandTooltipLinkColor();
  const handleOrgLink = () => {
    window.open(
      'https://intermountainhealthcare.org/patient-tools/my-health-plus/record-access?utm_source=web&utm_medium=web&utm_campaign=myhealth'
    );
  };
  return (
    <MuiBox p={3} pt={1} maxWidth="520px">
      <MuiBox display="inline-flex" alignItems="center" justifyContent="center" pb={1} width="88%">
        <Box>
          <Svg set="assets" name="InfoIcon" size={IconSize.base} color={brandTooltipColor} />
        </Box>
        <Box hSpacingLeft={Spacing.small}>
          <MuiTypography fontSize={FontSize.heading} color={Color.black}>
            More information
          </MuiTypography>
        </Box>
      </MuiBox>
      <MuiBox alignItems="flex-end" display="inline-flex">
        <CloseButton onClick={handleClose} color={Color.black}>
          <Svg color={Color.black} name="close" size={IconSize.small} set="material" />
        </CloseButton>
      </MuiBox>
      <MuiDivider />
      <Box vSpacingTop={Spacing.large}>
        <MuiTypography fontSize={FontSize.large} color={Color.black}>
          Accounts can only be created for adults 18 years of age or older.
        </MuiTypography>
      </Box>
      <Box vSpacingTop={Spacing.large}>
        <MuiTypography fontSize={FontSize.large} color={Color.black}>
          If you would like to access a minor's medical records you can request access after your
          account has been created.
        </MuiTypography>
      </Box>
      <Box vSpacingTop={Spacing.large}>
        <MuiTypography fontSize={FontSize.large} color={Color.black}>
          You can access this information from{' '}
          <TypographyInlineWrapper
            fontSize={FontSize.large}
            color={brandTooltipTextColor}
            onClick={handleOrgLink}
          >
            {getAppName(AppName.base)}
          </TypographyInlineWrapper>
        </MuiTypography>
      </Box>
    </MuiBox>
  );
};

const RegisterErrorInfoTooltipText = ({
  handleClose,
  handleReviewPersonalInfo,
  handleForgetUsername
}) => {
  const brandTooltipColor = getBrandTooltipColor(true);
  const brandTooltipTextColor = getBrandTooltipLinkColor();
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));
  return (
    <MuiBox pt={1} width={isLargeScreen ? '438px' : 'auto'}>
      <MuiBox
        p={3}
        display="inline-flex"
        pb={1}
        width="88%"
        alignItems="center"
        justifyContent="center"
      >
        <Box vSpacingTop={Spacing.small}>
          <Svg set="assets" name="InfoIcon" size={IconSize.base} color={brandTooltipColor} />
        </Box>
        <Box hSpacingLeft={Spacing.small}>
          <TypographyHeadingWrapper
            fontSize={FontSize.heading}
            color={Color.black}
            fontWeight={600}
          >
            More information
          </TypographyHeadingWrapper>
        </Box>
      </MuiBox>
      <MuiBox alignItems="flex-end" display="inline-flex">
        <CloseButton onClick={handleClose} color={Color.black}>
          <Svg color={Color.black} name="close" size={IconSize.small} set="material" />
        </CloseButton>
      </MuiBox>

      <Box vSpacingTop={Spacing.large} hSpacing={68} letterSpacing="0.33px">
        <TypographyWrapper fontSize={FontSize.base} color={Color.black}>
          To protect your medical information we use algorithms to securely match you to your data.
          These algorithms safely match most people. However, we sometimes need to verify identity
          over the phone.
        </TypographyWrapper>
      </Box>
      <Box vSpacing={Spacing.large} hSpacing={68}>
        <TypographyWrapper fontSize={FontSize.base} color={Color.black}>
          If this happens to you, don't worry! The Online Services team is here to help you complete
          your setup.
        </TypographyWrapper>
      </Box>
      <Box vSpacingTop={Spacing.large} hSpacing={68} backgroundColor={Color.foreColor}>
        <TypographyWrapper fontSize={FontSize.base} color={Color.black} fontWeight="600">
          Before calling Online Services,
        </TypographyWrapper>
        <TypographyWrapper fontSize={FontSize.base} color={Color.black} fontWeight="600">
          try these steps:
        </TypographyWrapper>
      </Box>
      <Box vSpacing={Spacing.large} hSpacing={68} backgroundColor={Color.foreColor}>
        <TypographyWrapper fontSize={FontSize.base} color={Color.black}>
          &bull; Review your personal details to ensure you've spelled everything correctly.
          <TypographyInlineWrapper
            fontSize={FontSize.base}
            color={brandTooltipTextColor}
            onClick={handleReviewPersonalInfo}
          >
            Review my info.{' '}
          </TypographyInlineWrapper>
        </TypographyWrapper>
      </Box>
      <Box vSpacing={Spacing.large} hSpacing={68} backgroundColor={Color.foreColor}>
        <TypographyWrapper fontSize={FontSize.base} color={Color.black}>
          &bull; If you've created an account in the past you cannot create a new one, but you can
          recover your account{' '}
          <TypographyInlineWrapper
            fontSize={FontSize.base}
            color={brandTooltipTextColor}
            onClick={handleForgetUsername}
          >
            {' '}
            forgot username.{' '}
          </TypographyInlineWrapper>{' '}
          If you do not recall the email you used in the past,{' '}
          <TypographyInlineWrapper
            fontSize={FontSize.base}
            color={brandTooltipTextColor}
            onClick={() => LinkingServices.callPhoneNumber('18004425502')}
          >
            Online services{' '}
          </TypographyInlineWrapper>{' '}
          can help.
        </TypographyWrapper>
      </Box>
    </MuiBox>
  );
};

const HealthIdHelpTextSmallScreen = ({ toggleToolTip }) => {
  return (
    <Dialog open fullScreen>
      <MaterialBox
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        fontSize="20px"
        pt={2}
      >
        <MaterialBox pl={2}>Health Identification Numbers</MaterialBox>
        <MaterialBox pr={2} onClick={toggleToolTip}>
          X
        </MaterialBox>
      </MaterialBox>
      <DialogContent>
        <MuiBox>
          <img
            alt="Health Identification Number locations for SelectHealth, SelectHealth pharmacy, Scripius, and Medicaid users."
            height="100%"
            width="100%"
            src={getRegisterBrand() === RedirectBrand.SC ? smScripiusIdImg : smHealthIdImg}
          />
        </MuiBox>
      </DialogContent>
    </Dialog>
  );
};
