import styled from 'styled-components';
import { MuiPaper } from 'theme/material-ui';
import FlexBox from 'components/UI/Layout/FlexBox';

export const PreviewImg = styled.img`
  width: 300px;
  height: 200px;
`;

export const PreviewIFrame = styled.iframe`
  height: 200px;
`;

export const PreviewFlexBox = styled(FlexBox)`
  height: 220px;
  align-items: center;
  justify-content: center;
`;

export const RoundedMuiPaper = styled(MuiPaper)`
  border-radius: 10px;
`;
