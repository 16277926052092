import React from 'react';
import { Color } from 'modules/styles/colors';

export default (props: any = { color: Color.black }) => ({
  svg: (
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.75 4.75C0.75 4.33579 1.08579 4 1.5 4H22.5C22.9142 4 23.25 4.33579 23.25 4.75V19.75C23.25 20.1642 22.9142 20.5 22.5 20.5H1.5C1.08579 20.5 0.75 20.1642 0.75 19.75V4.75ZM3.32919 5.5L10.4403 10.9695L10.4403 10.9695C10.8875 11.3135 11.4358 11.5001 12 11.5001C12.5642 11.5001 13.1125 11.3135 13.5597 10.9695L13.5597 10.9695L20.6708 5.5H3.32919ZM21.75 6.56231V19H2.25V6.56231L9.5257 12.1585C10.2351 12.7042 11.105 13.0001 12 13.0001C12.895 13.0001 13.7649 12.7042 14.4743 12.1585L21.75 6.56231Z"
        fill={props.color}
      />
    </g>
  ),
  viewBox: '0 0 24 24'
});
