import styled from 'styled-components';

import { ConnectCareTextField } from 'components/ConnectCare';
import { MuiDivider } from 'theme/material-ui';

import { Color } from 'modules/styles/colors';

export const VitalsTextField = styled(ConnectCareTextField)`
  background-color: ${Color.white};
`;

export const Divider = styled(MuiDivider)`
  &.MuiDivider-root {
    background-color: ${Color.black};
    width: 2px;
  }
  &.MuiDivider-vertical {
    height: 45px;
  }
  &.MuiDivider-middle {
    margin-right: 0;
    margin-left: 0;
    margin: auto;
  }
`;
