import React, { useEffect, ComponentType } from 'react';
import { connect, useDispatch } from 'react-redux';
import UpdatesList from 'screens/Updates/UpdatesList';
import { RootState } from 'store/types';
import { History } from 'lib/history';
import {
  eventListDataSelector,
  eventListErrorSelector,
  eventListFetchingSelector
} from 'store/events/selectors';
import { AnalyticsEvent } from 'services/AnalyticsService';
import useNavigationAnalytics from 'hooks/useNavigationAnalytics';
import InfoCard from 'components/InfoCard/InfoCard';
import { MuiGrid } from 'theme/material-ui';
import { getUpdateEvents } from 'store/events/actions';
import { FormattedEvent } from 'serverTypes/event';
import { useProxySwitcherEffect } from 'hooks/useProxySwitcherEffect';
import { useLanguageSwitcher } from 'lib/hooks/useLanguageSwitcher';
import { DashboardUpdates as translations } from 'lib/constants/translations/components/dashboard';

export interface Props {
  loading: boolean;
  error?: Error | null;
  data?: FormattedEvent[];
  history: History;
}

export const DashboardUpdates = (props: Props) => {
  const { history, loading, error, data } = props;
  const screenText = useLanguageSwitcher(translations);
  const analyticsOnError = useNavigationAnalytics(AnalyticsEvent.DashboardUpdatesFetchError);

  const updates = data?.slice(0, 5) || [];

  const dispatch = useDispatch();

  useProxySwitcherEffect(() => {
    dispatch(getUpdateEvents());
  }, []);

  useEffect(() => {
    if (error) {
      analyticsOnError();
    }
  }, [error]);

  if (loading) return null;
  if (!data || data.length === 0) return null;

  return (
    <MuiGrid item xs={12} sm={12} md={12} lg={6}>
      <InfoCard
        title={screenText?.title}
        buttonOnClick={() => history.push('/u/health-record/all-notifications')}
        buttonText={screenText?.btnText}
        buttonTestID="view-all-updates"
      >
        <UpdatesList loading={loading} error={error} history={history} updates={updates} />
      </InfoCard>
    </MuiGrid>
  );
};

const mapStateToProps = (state: RootState) => ({
  loading: eventListFetchingSelector(state),
  error: eventListErrorSelector(state),
  data: eventListDataSelector(state)
});

export default connect(mapStateToProps)(DashboardUpdates as ComponentType);
