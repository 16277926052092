import React, { ComponentType, useEffect } from 'react';
import Config from 'react-native-config';
import { connect, Matching } from 'react-redux';
import { Formik, getIn } from 'formik';
import get from 'lodash/get';
import { oc } from 'ts-optchain';

import Container from '@material-ui/core/Container';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Alert from '@material-ui/lab/Alert';
import { MuiButton } from 'theme/material-ui';

import analyticsService, { AmplitudeEventData, AnalyticsEvent } from 'services/AnalyticsService';
import { RootState } from 'store/types';
import { faAssetsSelector } from 'store/billing/financialAssistance/createApplication/selectors';
import {
  currentLocationPathNameSelector,
  previousLocationPathNameSelector
} from 'store/router/selectors';
import {
  setFAForm3Assets,
  setFAFormClear
} from 'store/billing/financialAssistance/createApplication/actions';
import { AssetsForm } from 'store/billing/financialAssistance/createApplication/types';
import { history } from 'lib/history';

import { AssetsSection, SeekingFACheckboxInputField } from './types';
import { ASSETS_FIELDS, SEEKING_FA_CHECKBOXES } from './constants';
import { assetsValidationSchema, otherFundingValidationSchema } from './validation';
import { convertToLowerKabobCase } from 'modules/utils/StringUtils';
import RequiredFieldsLegend, { StyledAsterisk } from 'components/RequiredFieldsLegend';
import useBreadCrumbs from 'hooks/useBreadCrumbs';
import DisableWhenWaiting from '../components/DisableWhenWaiting';
import { FAExitGuard } from './FAExitGuard';

interface Props {
  setFAForm3Assets: typeof setFAForm3Assets;
  assetsData: AssetsForm;
  setFAFormClear: typeof setFAFormClear;
  currentUrl?: string;
  referringUrl?: string;
}

const initialValues: AssetsForm = {
  cashInstitution: '',
  cash: '',
  savingInstitution: '',
  saving: '',
  checkingInstitution: '',
  checking: '',
  stocksInstitution: '',
  stocks: '',
  hasMedicare: '',
  k401Institution: '',
  k401: '',
  iraInstitution: '',
  ira: '',
  insuranceCompany: '',
  otherCompany: '',
  flexBalance: '',
  otherSituation: '',
  checked: []
};

const FAAssetsScreen = ({
  assetsData,
  setFAForm3Assets,
  setFAFormClear,
  currentUrl,
  referringUrl
}: Props) => {
  const eventData: AmplitudeEventData = {
    currentUrl,
    referringUrl
  };
  const { updateBreadCrumbAtIndex } = useBreadCrumbs();
  const assetsAndAssistanceEnabled = Config.FA_SHOW_ASSETS_AND_ASSISTANCE === 'enabled';

  useEffect(() => {
    if (!assetsAndAssistanceEnabled) {
      updateBreadCrumbAtIndex('FA Other Funding', 0);
    }
  }, []);

  const isInitialValid = () => {
    if (assetsAndAssistanceEnabled) {
      if (!Object.keys(assetsData).length) return false;
      return assetsValidationSchema.isValid(assetsData);
    }
    return otherFundingValidationSchema.isValid(assetsData);
  };

  const saveToStore = (values: AssetsForm) => {
    const setIfZero = ['cash', 'saving', 'checking', 'stocks'];
    setIfZero.forEach((field: string) => {
      if (values[field] === '0') {
        values[`${field}Institution`] = '';
      }
    });
    const isEdit = oc(history.location.state).isEdit(false);
    setFAForm3Assets(values);
    if (isEdit) {
      analyticsService.logEvent(AnalyticsEvent.AssetsNextClicked, {
        type: 'edit',
        ...eventData
      });
      history.push('/u/fa-app/complete');
    } else {
      analyticsService.logEvent(AnalyticsEvent.AssetsNextClicked, {
        type: 'apply',
        ...eventData
      });
      history.push('/u/fa-app/bills');
    }
  };

  const navigateToPrevious = (values: AssetsForm, touched: {}, errors: {}) => {
    const err = [];
    Object.keys(touched).forEach(k => {
      if (k in errors) {
        err.push(k);
      }
    });
    if (!err.length) {
      setFAForm3Assets(values);
      history.push('/u/fa-app/household');
    } else {
      history.push('/u/fa-app');
    }
  };

  return (
    <>
      <FAExitGuard />
      <Container maxWidth="md">
        {assetsAndAssistanceEnabled ? (
          <Box py={4}>
            <Box mb={2}>
              <Typography variant="h5">Assets & Assistance</Typography>
            </Box>
            <Divider />
            <Box mt={2}>
              <RequiredFieldsLegend />
            </Box>
          </Box>
        ) : null}
        <Formik
          initialValues={
            (assetsAndAssistanceEnabled && assetsData.cash) ||
            assetsData.otherSituation ||
            assetsData.checked
              ? assetsData
              : initialValues
          }
          validationSchema={
            assetsAndAssistanceEnabled ? assetsValidationSchema : otherFundingValidationSchema
          }
          onSubmit={values => saveToStore(values)}
          isInitialValid={isInitialValid() as boolean | ((props: {}) => boolean) | undefined}
        >
          {({
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            isValid,
            setFieldValue,
            handleSubmit
          }) => {
            const length = values.otherSituation ? values.otherSituation.length : 0;

            const getFormError = (errorKey: string, touchKey?: string) => {
              const touchedValue = get(touched, touchKey || errorKey);
              const errorValue = get(errors, errorKey);

              return touchedValue && errorValue ? errorValue || null : null;
            };

            return (
              <Box>
                {assetsAndAssistanceEnabled ? (
                  <Box pb={1}>
                    <Typography variant="h6">
                      Please report the responsible party’s assets
                    </Typography>
                  </Box>
                ) : null}
                {assetsAndAssistanceEnabled &&
                  ASSETS_FIELDS.map((section: AssetsSection) => (
                    <>
                      {oc(section).fields.radioButtons.length() && (
                        <Box mb={values.hasMedicare === 'Y' ? 1.5 : 0}>
                          <Box pt={4} pb={2}>
                            <Typography variant="h6">
                              {section.label}
                              <StyledAsterisk />
                            </Typography>
                          </Box>
                          <FormControl component="fieldset">
                            <RadioGroup
                              value={get(values, oc(section).fields.radioButtons[0].groupName(''))}
                              onChange={e => {
                                setFieldValue(
                                  oc(section).fields.radioButtons[0].groupName(''),
                                  e.target.value
                                );
                                const resetIfNoMedicare = [
                                  'k401Institution',
                                  'k401',
                                  'iraInstitution',
                                  'ira'
                                ];
                                if (values.hasMedicare === 'N') {
                                  resetIfNoMedicare.forEach(f => setFieldValue(f, ''));
                                }
                              }}
                            >
                              {oc(section)
                                .fields.radioButtons([])
                                .map(field => (
                                  <FormControlLabel
                                    data-testid={convertToLowerKabobCase(
                                      field.fieldLabel,
                                      '-medicare-radio-button'
                                    )}
                                    key={field.fieldName}
                                    control={<Radio color="primary" />}
                                    label={field.fieldLabel}
                                    value={field.fieldName}
                                  />
                                ))}
                            </RadioGroup>
                          </FormControl>
                        </Box>
                      )}
                      {(section.key !== 5 || values.hasMedicare === 'Y') && (
                        <Box py={0.2}>
                          {section.key !== 5 && (
                            <Box>
                              <Typography variant="body1">{section.label}</Typography>
                            </Box>
                          )}
                          {oc(section)
                            .fields.textInputs([])
                            .map(field => {
                              const showMedicare =
                                field.conditionalOn && getIn(values, field.conditionalOn) === 'Y';
                              const showInstitution =
                                field.conditionalOn && getIn(values, field.conditionalOn) > 0;

                              if (showMedicare || showInstitution || !field.conditionalOn) {
                                return (
                                  <Box py={section.key === 5 ? 0.2 : 0}>
                                    {field.key === 9 ? (
                                      <Typography variant="body1">
                                        401k associated to Medicare
                                      </Typography>
                                    ) : field.key === 11 ? (
                                      <Typography variant="body1">
                                        IRA associated to Medicare
                                      </Typography>
                                    ) : null}
                                    <Box py={1}>
                                      <TextField
                                        data-testid={convertToLowerKabobCase(
                                          field.fieldName,
                                          '-text-field'
                                        )}
                                        variant="outlined"
                                        label={field.fieldLabel}
                                        required
                                        value={getIn(values, field.fieldName)}
                                        onChange={handleChange(field.fieldName)}
                                        onBlur={handleBlur(field.fieldName)}
                                        error={!!getFormError(field.fieldName)}
                                        helperText={getFormError(field.fieldName)}
                                        fullWidth
                                      />
                                    </Box>
                                  </Box>
                                );
                              }
                              return null;
                            })}
                        </Box>
                      )}
                    </>
                  ))}
                <Box pt={4} pb={2}>
                  <Typography variant="h6">
                    We ask patients who apply for financial assistance to also look for other
                    funding. Please check all that apply.
                  </Typography>
                </Box>
                {SEEKING_FA_CHECKBOXES.map(item => {
                  const checkedValues = oc(values).checked([]);
                  return (
                    <Box py={0.2}>
                      <Box>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              checked={checkedValues.includes(item.fieldName)}
                              onChange={() => {
                                setFieldValue(
                                  'checked',
                                  checkedValues.includes(item.fieldName)
                                    ? checkedValues.filter(i => i !== item.fieldName)
                                    : checkedValues.concat(item.fieldName)
                                );

                                if (checkedValues.includes(item.fieldName)) {
                                  switch (item.fieldName) {
                                    case 'hasInsured': {
                                      setFieldValue('insuranceCompany', '');
                                      break;
                                    }
                                    case 'hasOtherInsured': {
                                      setFieldValue('otherCompany', '');
                                      break;
                                    }
                                    case 'hasFlex': {
                                      setFieldValue('flexBalance', '');
                                      break;
                                    }
                                    default:
                                  }
                                }
                              }}
                              value={item.fieldName}
                            />
                          }
                          label={item.label}
                        />
                      </Box>
                      {checkedValues.includes(item.fieldName) && item.helpText && (
                        <Box py={1}>
                          <Alert severity="info">{item.helpText}</Alert>
                        </Box>
                      )}
                      {checkedValues.includes(item.fieldName) &&
                        oc(item)
                          .fields.textInputs([])
                          .map((textInputProps: SeekingFACheckboxInputField) => (
                            <Box py={1}>
                              <TextField
                                variant="outlined"
                                label={textInputProps.fieldLabel}
                                required
                                value={values[textInputProps.fieldName]}
                                onChange={handleChange(textInputProps.fieldName)}
                                onBlur={handleBlur(textInputProps.fieldName)}
                                error={!!getFormError(textInputProps.fieldName)}
                                helperText={getFormError(textInputProps.fieldName)}
                                fullWidth
                              />
                            </Box>
                          ))}
                    </Box>
                  );
                })}
                <Box pt={4} pb={2}>
                  <Typography variant="h6">
                    Please explain any situation we should be informed of in order to understand
                    your inability to pay the medical balance. Additional verification may be
                    required.
                  </Typography>
                </Box>
                <TextField
                  variant="outlined"
                  multiline
                  inputProps={{ maxlength: 8192 }}
                  label="Additional Comments"
                  rows="6"
                  value={values.otherSituation}
                  onChange={handleChange('otherSituation')}
                  onBlur={handleBlur('otherSituation')}
                  fullWidth
                />
                <Box pt={0.3} display="flex" justifyContent="flex-end">
                  <Typography variant="caption">{length}/8192</Typography>
                </Box>
                <Box py={4}>
                  <Container maxWidth="xs">
                    <Box mb={1}>
                      <MuiButton
                        data-testid="other-funding-next-button"
                        size="large"
                        variant="contained"
                        color="primary"
                        disabled={!isValid}
                        fullWidth
                        onClick={() => {
                          handleSubmit();
                        }}
                      >
                        Next
                      </MuiButton>
                    </Box>
                    <MuiButton
                      data-testid="other-funding-previous-button"
                      size="large"
                      color="primary"
                      fullWidth
                      onClick={() => navigateToPrevious(values, touched, errors)}
                    >
                      Previous
                    </MuiButton>
                  </Container>
                </Box>
              </Box>
            );
          }}
        </Formik>
      </Container>
      <DisableWhenWaiting />
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  assetsData: faAssetsSelector(state),
  currentUrl: currentLocationPathNameSelector(state),
  referringUrl: previousLocationPathNameSelector(state)
});

const mapDispatch = {
  setFAForm3Assets,
  setFAFormClear
};

export default connect(
  mapStateToProps,
  mapDispatch
)(
  FAAssetsScreen as ComponentType<
    Matching<
      { assetsData: {} | AssetsForm } & {
        setFAForm3Assets: (validData: AssetsForm) => { type: string; payload: AssetsForm };
        setFAFormClear: () => { type: string };
      },
      Props
    >
  >
);
