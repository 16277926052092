import React, { useState } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { useSelector } from 'react-redux';
import { ProviderFacetGroups } from 'store/providerSearch/types';
import { geolocationAcceptedSelector } from 'store/geolocation/selectors';
import {
  MuiBox,
  MuiButton,
  MuiTypography,
  MuiIcon,
  MuiIconButton,
  MuiInputAdornment,
  MuiSlider
} from 'theme/material-ui';
import { FontSize } from 'modules/styles/variables';
import { toSentenceCase } from 'modules/utils/StringUtils';
import { TextInput } from 'components/TextInput';
import { Confirm } from 'components/ConfirmDialog/ConfirmDialog';
import { FilterDrawer } from 'components/FilterDrawer';
import { FiltersStructure } from 'lib/hooks/ProviderSearch/types';
import { useProviderSearchFilter } from 'lib/hooks/ProviderSearch/useProviderSearchFilters';

import { FormChangeItem } from '../shared';

type LocationTextInputProps = LocationNearMeFilterProps['values']['locationText'];

function LocationTextInput({ values }: LocationTextInputProps) {
  const geolocationAccepted = useSelector(geolocationAcceptedSelector);

  const [field, helpers] = useProviderSearchFilter('locationText');
  const [showInput, setShowInput] = useState(!field.value);

  const onKeyUpClick = ({ type, key }: React.SyntheticEvent) => {
    const onKeyUp = type === 'keyup' && key === 'Enter';
    const onClick = type === 'click';

    if (onClick || onKeyUp) {
      setShowInput(false);
    }
  };

  const onCurrentLocationClick = (e: React.SyntheticEvent) => {
    if (!geolocationAccepted) {
      Confirm.show(
        'Geolocation Error',
        'Sorry, something went wrong attempting to use your location. Please check your location settings and try again.',
        undefined,
        'error',
        { text: 'Okay' },
        undefined,
        { closeIcon: false, cancelable: false }
      );
    } else {
      helpers.setValue(values.auto.value);
      onKeyUpClick(e);
    }
  };

  if (showInput) {
    return (
      <MuiBox mt={1}>
        <TextInput
          {...field}
          fullWidth
          variant="outlined"
          onKeyUp={onKeyUpClick}
          placeholder={values.manual.title}
          InputProps={{
            endAdornment: (
              <MuiInputAdornment position="end">
                <MuiIconButton edge="end" onClick={onKeyUpClick} aria-label="search">
                  <MuiIcon>search</MuiIcon>
                </MuiIconButton>
              </MuiInputAdornment>
            )
          }}
        />
        <MuiBox textAlign="center" m={0.5}>
          <MuiTypography fontSize={FontSize.small}>OR</MuiTypography>
        </MuiBox>
        <MuiButton fullWidth variant="contained" color="primary" onClick={onCurrentLocationClick}>
          {toSentenceCase(values.auto.title)}
        </MuiButton>
      </MuiBox>
    );
  }

  return (
    <MuiBox mt={1}>
      <FormChangeItem value={field.value} onClick={() => setShowInput(true)} />
    </MuiBox>
  );
}

type LocationDistanceSliderProps = LocationNearMeFilterProps['values']['locationDistance'];

function LocationDistanceSlider({ title, values }: LocationDistanceSliderProps) {
  const key = ProviderFacetGroups.locationDistance;
  const [field, helpers] = useProviderSearchFilter('facets');
  const facets = cloneDeep(field.value);
  const [data] = field.value[key];
  const tickMarkValues = [
    {
      value: 5,
      label: '5'
    },
    {
      value: 10,
      label: '10'
    },
    {
      value: 25,
      label: '25'
    },
    {
      value: 100,
      label: 'Any'
    }
  ];

  const onChange = (_: React.ChangeEvent<{}>, newValue: number | number[]) => {
    if (newValue < 10) {
      facets[key] = [values.fiveMiles];
    }
    if (newValue >= 10 && newValue < 25) {
      facets[key] = [values.tenMiles];
    }
    if (newValue >= 25) {
      facets[key] = [values.twentyFiveMiles];
    }
    if (newValue === 100) {
      facets[key] = [values.any];
    }
    helpers.setValue(facets);
  };

  const getDefaultValue = () => {
    let defaultValue = 0;

    if (data?.value === 9999) {
      defaultValue = 100;
    } else if (data?.value) {
      defaultValue = data.value as number;
    }

    return defaultValue;
  };

  return (
    <MuiBox mt={2}>
      <MuiTypography id="location-distance-slider" gutterBottom>
        {title}
      </MuiTypography>
      <MuiBox px={0.5}>
        <MuiSlider
          defaultValue={getDefaultValue()}
          getAriaValueText={value => (value === 100 ? 'Any' : `${value} mi`)}
          valueLabelFormat={value => (value === 100 ? 'Any' : `${value} mi`)}
          aria-labelledby="location-distance-slider"
          valueLabelDisplay="auto"
          onChange={onChange}
          marks={tickMarkValues}
          step={null}
        />
      </MuiBox>
    </MuiBox>
  );
}

export type LocationNearMeFilterProps = FiltersStructure['locationNearMe'] & {
  defaultExpanded: boolean;
};

function LocationNearMeFilter({ title, values, defaultExpanded }: LocationNearMeFilterProps) {
  return (
    <FilterDrawer.Panel title={title} defaultExpanded={defaultExpanded}>
      <LocationTextInput {...values.locationText} />
      <LocationDistanceSlider {...values.locationDistance} />
    </FilterDrawer.Panel>
  );
}

export default LocationNearMeFilter;
