import React from 'react';
import { MuiTypography } from 'theme/material-ui';
import { RedText } from 'components/styled';

export const StyledAsterisk = () => <RedText>*</RedText>;

/**
 * RequiredFieldLegend provides a indication that a specific form control
 * in a Web application or form is required for successful data submission.
 * @returns {JSX.Element} RequiredFieldLegend
 */
export function RequiredFieldsLegend() {
  const message = 'Required fields are marked with an asterisk. ';

  return (
    <MuiTypography align="left">
      {message}
      <StyledAsterisk />
    </MuiTypography>
  );
}

export default RequiredFieldsLegend;
