import { Alert } from 'components/Alert';
import Spinner from 'components/UI/Spinner/Spinner';
import { CenteredSpinnerContainer } from 'components/UI/Spinner/SpinnerContainer';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';
import { NavigationScreenProps } from 'screens/navigation';
import { switchAccount } from 'store/account/actions';
import { accountLoadingSelector, otherActiveAccountsSelector } from 'store/account/selectors';
import { Consumer } from 'store/profile/types';
import { RootState } from 'store/types';
import { routeLink } from 'store/deepLink/selectors';
import { DeepLinkAction } from 'store/deepLink/types';
import { DeepLinkRouteDefinition } from 'store/deepLink/utils';
import { getEvent } from 'store/events/actions';

export interface EventsResolverProps extends NavigationScreenProps {
  getEvent: typeof getEvent;
  switchAccount: typeof switchAccount;
  switchingAccount: boolean;
  proxyUserList: Consumer[];
}

export interface EventActionPayload {
  payload: {
    data: DeepLinkAction;
  };
}

export const EventsResolver = ({
  getEvent,
  proxyUserList,
  switchAccount,
  switchingAccount
}: EventsResolverProps) => {
  const { eventId } = useParams();
  const [redirectLocation, setRedirectLocation] = useState<DeepLinkRouteDefinition | null>(null);

  const checkForProxyEvent = async (deepLinkRes: DeepLinkAction) => {
    const isProxy = proxyUserList?.some(consumer => consumer.consumerId === deepLinkRes.consumerId);
    if (isProxy) {
      await switchAccount(deepLinkRes.consumerId);
    }
  };

  useEffect(() => {
    const resolveEvent = async () => {
      try {
        const res = await getEvent(eventId || '');
        const eventData = res.payload.data;
        await checkForProxyEvent(eventData);
        setRedirectLocation(routeLink(eventData));
      } catch (e) {
        Alert.alert('There was a problem loading the event. Please try again.');
        setRedirectLocation(routeLink({ type: 'unknown', id: '0' }));
      }
    };

    resolveEvent();
  }, [eventId]);

  if (redirectLocation && !switchingAccount) {
    return (
      <Redirect
        to={{
          pathname: redirectLocation.path,
          state: redirectLocation.params
        }}
      />
    );
  }

  return (
    <CenteredSpinnerContainer>
      <Spinner data-testid="events-resolver-loading" />
    </CenteredSpinnerContainer>
  );
};

const mapStateToProps = (state: RootState) => ({
  proxyUserList: otherActiveAccountsSelector(state),
  switchingAccount: accountLoadingSelector(state)
});

const mapDispatchToProps = {
  getEvent,
  switchAccount
};

export default connect(mapStateToProps, mapDispatchToProps)(EventsResolver);
