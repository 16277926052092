import styled from 'styled-components';
import { dfdDefaultTheme } from 'theme/material-ui';

import Screen from 'components/UI/Layout/Screen';

export const VisitTypesContainer = styled.div`
  height: 87%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: scroll;
`;

export const VisitTypesListContainer = styled.div`
  margin-left: 35%;
  width: 45%;

  ${dfdDefaultTheme.breakpoints.down('md')} {
    margin-left: 25%;
    width: 65%;
  }

  ${dfdDefaultTheme.breakpoints.down('sm')} {
    margin-left: 10%;
    width: 80%;
  }
`;

export const VisitTypesFormContainer = styled.div`
  margin-left: 35%;
  width: 30%;

  ${dfdDefaultTheme.breakpoints.down('md')} {
    margin-left: 25%;
    width: 50%;
  }

  ${dfdDefaultTheme.breakpoints.down('sm')} {
    margin-left: 10%;
    width: 80%;
  }
`;

export const VisitTypesBottomContainer = styled.div`
  margin-left: 35%;
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  ${dfdDefaultTheme.breakpoints.down('md')} {
    margin-left: 25%;
    width: 50%;
  }

  ${dfdDefaultTheme.breakpoints.down('sm')} {
    margin-left: 15%;
    width: 70%;
  }
`;

export const StepActionsVisitType = styled.div`
  margin: 0;
  padding: 0;
`;

export const StyledVisitTypeScreen = styled(Screen)`
  height: calc(100vh - 250px);
`;
