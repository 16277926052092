import styled from 'styled-components';
import { headerHeight } from 'components/AuthLayout/styled';
import { FontSize } from 'modules/styles/variables';
import { Color } from 'modules/styles/colors';
import { dfdDefaultTheme } from 'theme/material-ui';
import { spacings, spacing } from 'modules/utils/StylesUtils';
import { FlexBoxColumn } from 'components/UI/Layout/FlexBox';
import { easeInOutBack, menuWidth, muiButtonStyles, boxShadow } from './WebRTCStyles';

export default styled.div`
  &&& {
    height: calc(100vh - ${headerHeight}px);
    max-width: 508px;
    position: fixed;
    transform: translateX(0);
    transition: all 250ms ${easeInOutBack};
    top: ${headerHeight}px;
    bottom: 0;
    background-color: ${Color.white};
    box-shadow: 3px 3px 3px 0 rgba(0, 0, 0, 0.15);
    font-size: ${FontSize.base}px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: ${Color.grayHue2};
    word-wrap: normal;
    overflow: auto;
    z-index: 5;
    width: calc(100% - ${menuWidth.large}px);
    left: ${menuWidth.large}px;
    box-shadow: ${boxShadow};

    &.awsdk-hidden {
      display: block !important;
      transform: translateX(-100%);
    }

    ${dfdDefaultTheme.breakpoints.down('md')} {
      width: calc(100% - ${menuWidth.medium}px);
      left: ${menuWidth.medium}px;
    }
    ${dfdDefaultTheme.breakpoints.down('sm')} {
      width: calc(100% - ${menuWidth.small}px);
      left: ${menuWidth.small}px;
    }
  }
`;

const WebRTCDetails = styled(FlexBoxColumn)`
  height: 100%;
  overflow: hidden;
`;

const WebRTCInput = styled.input`
  &&& {
    background-color: ${Color.white};
    border: 1px solid ${Color.grayLight};
    padding: 10px;
    font-size: 18px;
    margin-top: 10px;
    margin: 0;
    border-radius: 4px;
    padding: 15px 13px;
    width: 100%;
    outline: none;
    box-shadow: none; /* Firefox */
    font-family: inherit;
    font-size: ${FontSize.base}px;
    line-height: inherit;
    transition: border 0.2s, box-shadow 0.2s;

    &:focus,
    &:hover {
      border-color: ${Color.primary};
    }
  }
`;

export const WebRTCMenuClose = styled.div`
  &&& {
    display: inline-block;
    position: absolute;
    right: 24px;
    top: 24px;
    z-index: 2;
    &:hover {
      opacity: 0.5;
      cursor: pointer;
    }
  }
`;

export const WebRTCMenuTitle = styled.h4`
  &&& {
    width: fit-content;
    font-size: ${FontSize.base}px;
    font-weight: 800;
    font-style: normal;
    font-stretch: normal;
    color: ${Color.grayHue2};
    margin-bottom: ${spacing(2)};
    letter-spacing: 1px;
  }
`;

export const WebRTCNotes = styled(WebRTCDetails)``;

export const WebRTCMenuHeader = styled.div`
  &&& {
    padding: ${spacings(3, 6, 0, 3)};
  }
`;

export const WebRTCNotesContent = styled.div`
  &&& {
    padding: ${spacings(0, 3)};
    & .awsdk-notesBody {
      width: 100%;
      height: 100%;
      min-height: 700px;
      border: none;
      overflow-wrap: break-word;
      /* for browsers that don't support above */
      word-wrap: break-word;

      word-break: break-all;
      -ms-word-break: break-all;
      /* safer for WebKit */
      word-break: break-word;
      /* place a hyphen if browser supports */
      -webkit-hyphens: auto;
      -moz-hyphens: auto;
      hyphens: auto;
    }
  }
`;

export const WebRTCChat = styled(WebRTCDetails)`
  &&& {
    div.awsdk-chatMessage {
      display: flex;
      margin-bottom: ${spacing(1.5)};
    }

    div.awsdk-chatMessage.awsdk-mine {
      justify-content: flex-end;
    }

    div.awsdk-chatMessageContent {
      width: 60%;
      display: flex;
      flex-direction: column;
    }

    div.awsdk-chatMessage.awsdk-mine .awsdk-chatMessageContent {
      align-items: flex-end;
    }

    div.awsdk-chatMessage.awsdk-systemMessage .awsdk-chatMessageContent,
    div.awsdk-chatMessage.awsdk-systemMessage span {
      width: 100%;
      font-weight: unset;
    }

    & .awsdk-chatMessage .awsdk-chatItemTitle {
      font-size: 10px;
      font-weight: 600;
      color: ${Color.grayHue2};
      padding: ${spacings(0, 3)};
    }

    & .awsdk-chatMessage span.awsdk-bubble {
      min-width: 100%;
      margin-top: 5px;
      padding: ${spacings(2, 3)};
      border: 1px solid ${Color.grayLight};
      border-top-left-radius: 30px;
      border-top-right-radius: 25px;
      border-bottom-right-radius: 25px;
      word-break: break-word;
    }

    & .awsdk-chatMessage.awsdk-mine span.awsdk-bubble {
      border-color: ${Color.grayLight3};
      background-color: ${Color.grayLight3};
      border-top-left-radius: 25px;
      border-top-right-radius: 25px;
      border-bottom-left-radius: 25px;
      border-bottom-right-radius: 0;
    }
  }
`;

export const WebRTCChatIntroText = styled.p`
  &&& {
    font-size: ${FontSize.base}px;
    font-weight: bolder;
    margin-bottom: 8px;
  }
`;

export const WebRTCChatMsgArea = styled.div`
  &&& {
    flex-grow: 1;
    overflow-wrap: break-word;
    overflow-y: auto;
    padding: ${spacings(0, 3)};
  }
`;

export const WebRTCChatTextInputWrapper = styled.div`
  &&& {
    border-top: 1px solid ${Color.grayLight};
    padding: ${spacings(2)};
    background-color: ${Color.white};
    height: auto;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    position: relative;
  }
`;

export const WebRTCChatTextInput = styled(WebRTCInput)`
  &&& {
    display: block;
    width: 100%;
    padding-right: ${spacing(4.5)};
    margin-left: auto;
    margin-right: auto;
  }
`;

export const WebRTCChatNoIVR = styled.p``;

export const WebRTCChatSend = styled.div`
  &&& {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    position: absolute;
    right: 28px;
    top: 50%;
    transform: translateY(-50%);
    &:hover {
      cursor: pointer;
      opacity: 0.5;
    }
  }
`;

export const WebRTCInvite = styled(WebRTCDetails)`
  &&& {
    span.awsdk-invitees {
      height: 33px;
      display: block;
      border-bottom: 1px solid ${Color.grayLight};
      margin: ${spacings(1.5, 0)};
      font-size: ${FontSize.large};
      overflow-x: hidden;
      text-overflow: ellipsis;
    }

    span.awsdk-invitees + p {
      margin-top: ${spacing(3)};
    }
  }
`;

export const WebRTCInviteIntroText = styled.p`
  &&& {
    margin: ${spacings(1.5, 0)};
    font-size: ${FontSize.large}px;
  }
`;

export const WebRTCInviteDisplayInvited = styled.div`
  &&& {
    margin-bottom: ${spacing(4)};
  }
`;

export const WebRTCInviteButton = styled.button`
  &&& {
    margin-top: ${spacing(2)};
    ${muiButtonStyles};
  }
`;

export const WebRTCInviteTextInputWrapper = styled.div`
  &&& {
    padding: ${spacings(0, 3)};
  }
`;

export const WebRTCInviteLabelWrapper = styled.div`
  &&& {
    padding: ${spacings(0, 3)};
  }
`;

export const WebRTCMaxInvitesMessage = styled.p`
  &&& {
    padding: ${spacings(0, 3)};
  }
`;

export const WebRTCInviteTextInput = styled(WebRTCInput)``;

export const WebRTCInviteErrorText = styled.span`
  &&& {
    font-size: ${FontSize.base}px;
    margin-top: ${spacing(3)};
    color: ${Color.red};
  }
`;

export const WebRTCInviteLabel = styled.span`
  &&& {
    font-weight: 600;
    font-size: ${FontSize.base}px;
    display: block;
    margin-bottom: ${spacing(3)};
  }
`;
