import { ReactNode } from 'react';
import { createPortal } from 'react-dom';
import { usePortal } from 'hooks/usePortal';

export interface PortalProps {
  el?: HTMLElement;
  onElement?: (el: HTMLElement | null) => void;
  children: ReactNode;
}

function Portal(props: PortalProps) {
  const { el, onElement, children } = props;
  const root = usePortal(el, onElement);
  return root != null ? createPortal(children, root) : null;
}

export default Portal;
