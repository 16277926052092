import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { Color } from 'modules/styles/colors';
import { borderRadius, smallFontSize } from 'modules/styles/variables';
import TouchablePanel from 'components/UI/Panel/TouchablePanel';
import Subhead from 'components/UI/Typography/Subhead';

interface Props {
  children: ReactNode;
  onPress: Function;
  badgeText?: string;
  badgeColor?: string;
  badgeHeight?: string;
  badgeTextColor?: string;
}

const StyledTouchablePanel = styled(TouchablePanel)<{ badgeHeight: string }>`
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  height: ${props => props.badgeHeight};
  box-shadow: 0px 1px 0px ${Color.grayHue5};
  cursor: pointer;
`;

const InnerPanel = styled.div<{ badgeHeight: string }>`
  width: 100%;
  align-items: space-around;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 35px;
  padding-right: 20px;
  height: ${props => props.badgeHeight};
`;

const Badge = styled.div<{ badgeColor: string }>`
  position: absolute;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.badgeColor};
  border-top-left-radius: ${borderRadius};
  border-bottom-left-radius: ${borderRadius};
  width: 25px;
  height: 100%;
  display: flex;
`;

const BadgeText = styled(Subhead)<{ badgeHeight: string; badgeTextColor?: string }>`
  color: ${props => props.badgeTextColor || Color.foreColor};
  font-size: ${smallFontSize};
  transform: rotate(270deg);
  width: ${props => props.badgeHeight};
  text-align: center;
`;

const BadgePanel = (props: Props) => (
  <StyledTouchablePanel onPress={props.onPress}>
    {props.badgeText && (
      <Badge {...props}>
        <BadgeText {...props}>{props.badgeText}</BadgeText>
      </Badge>
    )}
    <InnerPanel {...props}>{props.children}</InnerPanel>
  </StyledTouchablePanel>
);

BadgePanel.defaultProps = {
  badgeColor: Color.secondaryHue3,
  badgeHeight: '80px'
};

export default BadgePanel;
