import React from 'react';
import { MuiBox, MuiGrid, MuiTypography } from 'theme/material-ui';
import CollapsibleListItemNpv from 'components/common/CollapsibleList/CollapsibleListItemNpv';
import { Color } from 'modules/styles/colors';
import { FontSize, FontWeight, Spacing } from 'modules/styles/variables';
import { Svg } from 'components/UI/Svg';
import Divider from 'components/UI/Divider';
import { ProviderLocation, ProviderSearchFilters } from 'store/findProvider/types';
import { AppointmentType, Slots, Specialty, VisitTypeNpv } from 'store/booking/types';
import { formatMonthDate, formatTimeToMountainTimeZone } from 'modules/utils/DateUtils';
import { locationDistance } from 'lib/booking/utils';
import { bookingVisitTypes } from 'modules/constants/booking';
import { showDistance } from 'modules/utils/LocationUtils';
import { Nullable } from 'modules/types/common';

export interface Props {
  location: ProviderLocation;
  selectedSpecialty: Nullable<Specialty>;
  hasLocation: boolean;
  onSelectSlot: (
    location: ProviderLocation,
    visitType: VisitTypeNpv,
    slot: Nullable<Slots>,
    index: number
  ) => void;
  index: number;
  activeFilters: ProviderSearchFilters;
}

interface SlotButtonProps {
  color?: string;
  onClick: () => void;
  children: React.ReactNode;
}

const SpecialtyProviderLocation = ({
  location,
  selectedSpecialty,
  hasLocation,
  onSelectSlot,
  index,
  activeFilters
}: Props) => {
  const showOnlyVideoVisit = activeFilters?.filters?.find(
    i => i.group === 'offersVideoVisits' && i.value
  )?.value;

  const VisitTypeHeader = ({
    appointmentType,
    synonymDisplay = ''
  }: {
    appointmentType: AppointmentType;
    synonymDisplay: string;
  }) => (
    <>
      {appointmentType === 'In Clinic' ? (
        <MuiBox display="flex" flexDirection="row" alignItems="center" marginTop={1}>
          <Svg
            set="assets"
            name="Hospital"
            size={FontSize.mediumHeading}
            style={{ marginBottom: '3px' }}
          />
          <MuiTypography
            fontWeight="600"
            fontSize={FontSize.small}
            style={{ marginTop: '3px', marginLeft: '6px' }}
          >
            Office Visits
          </MuiTypography>
          {synonymDisplay ? (
            <MuiBox display="flex" flexDirection="row" alignItems="center" marginTop={0}>
              <MuiTypography
                fontWeight="600"
                fontSize={FontSize.small}
                style={{ marginTop: '3px', marginLeft: '3px', marginRight: '3px' }}
              >
                |
              </MuiTypography>
              <MuiTypography fontSize={FontSize.small} style={{ marginTop: '5px' }}>
                {synonymDisplay}
              </MuiTypography>
            </MuiBox>
          ) : null}
        </MuiBox>
      ) : (
        <MuiBox display="flex" flexDirection="row" alignItems="center" marginTop={2}>
          <Svg
            set="assets"
            name="VideoVisit"
            size={FontSize.heading}
            color={Color.black}
            style={{ marginBottom: '3px' }}
          />
          <MuiTypography fontWeight="600" fontSize={FontSize.small} style={{ marginLeft: '6px' }}>
            Video Visits
          </MuiTypography>
          {synonymDisplay ? (
            <MuiBox display="flex" flexDirection="row" alignItems="center" marginTop={0}>
              <MuiTypography
                fontWeight="600"
                fontSize={FontSize.small}
                style={{ marginLeft: '3px', marginRight: '3px' }}
              >
                |
              </MuiTypography>
              <MuiTypography fontSize={FontSize.small} style={{ marginTop: '2px' }}>
                {synonymDisplay}
              </MuiTypography>
            </MuiBox>
          ) : null}
        </MuiBox>
      )}
    </>
  );

  const SlotButton = ({ color, onClick, children, ...rest }: SlotButtonProps) => (
    <MuiBox
      minWidth="105%"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      color={color || Color.blueHue7}
      borderRadius={4}
      borderColor={color || Color.blueHue7}
      onClick={onClick}
      style={{
        backgroundColor: Color.white,
        borderStyle: 'solid',
        borderWidth: 2,
        minHeight: '45px',
        cursor: 'pointer'
      }}
      {...rest}
    >
      {children}
    </MuiBox>
  );

  return (
    <MuiGrid item container>
      <CollapsibleListItemNpv
        expandOnFirstRender={index === 0}
        detailsBackground={Color.blueHue5}
        headerColor={Color.blueHue5}
        headerColorOnExpand={Color.primary}
        textColor={Color.primary}
        textColorOnExpand={Color.white}
        fontSize={FontSize.small}
        leftIcon="DirectionIcon"
        leftIconProps={{ set: 'assets', size: '22px' }}
        leftIconPaddingRight={Spacing.smallMedium}
        title={
          <MuiTypography fontSize={FontSize.small}>
            {location.name}
            <MuiTypography variant="inherit" fontWeight={FontWeight.bold}>
              {`${
                showDistance(location, selectedSpecialty, hasLocation)
                  ? ` - ${locationDistance(location?.distance)}`
                  : ''
              }`}
            </MuiTypography>
          </MuiTypography>
        }
        height="auto"
        detailsPadding="4px 8px 8px 8px"
        headerPadding="2px 5px"
        headerMargin={Spacing.none}
      >
        <MuiBox>
          <MuiTypography color={Color.textDark} fontSize={FontSize.small}>
            {`${location?.address?.addressLine1 ? `${location?.address?.addressLine1}, ` : ''}
            ${location?.address?.addressLine2 ?? ''}
            ${location?.address?.city ? `${location?.address?.city}, ` : ''}
            ${location?.address?.state ?? ''} ${location?.address?.postalCode ?? ''}`}
          </MuiTypography>
        </MuiBox>
        {location?.bookingData?.length > 0 ? (
          location?.bookingData?.map(visitType => (
            <>
              {(visitType.appointmentType === 'In Clinic' && !showOnlyVideoVisit) ||
              visitType.appointmentType === 'Video' ? (
                <MuiBox key={visitType.typeId}>
                  <VisitTypeHeader {...visitType} />

                  <Divider color={Color.black} />

                  <MuiGrid container item spacing={1} style={{ marginTop: Spacing.small }}>
                    {visitType.slots?.length > 0 ? (
                      <>
                        {visitType.slots.slice(0, 3).map((slot: Slots, index: number) => (
                          <MuiGrid key={slot.id} item xs={3} container>
                            <SlotButton
                              data-testid="timeslot-button"
                              color={
                                visitType.appointmentType === 'Video'
                                  ? Color.secondary
                                  : Color.primary
                              }
                              onClick={() => onSelectSlot(location, visitType, slot, index)}
                            >
                              <MuiTypography
                                fontSize={FontSize.small}
                                fontWeight={FontWeight.semibold}
                              >
                                {formatMonthDate(slot.datetime).toUpperCase()}
                              </MuiTypography>
                              <MuiTypography
                                fontSize={FontSize.xsmall}
                                fontWeight={FontWeight.semibold}
                              >
                                {formatTimeToMountainTimeZone(slot.datetime).toUpperCase()}
                              </MuiTypography>
                            </SlotButton>
                          </MuiGrid>
                        ))}
                        <MuiGrid item xs={3} container>
                          <SlotButton
                            data-testid="timeslot-more-button"
                            color={
                              visitType.appointmentType === 'Video'
                                ? Color.secondary
                                : Color.primary
                            }
                            onClick={() => onSelectSlot(location, visitType, null, 4)}
                          >
                            <MuiTypography fontSize={11} fontWeight={FontWeight.semibold}>
                              More
                            </MuiTypography>
                          </SlotButton>
                        </MuiGrid>
                      </>
                    ) : (
                      <MuiBox
                        width="100%"
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        padding="6px 6px 10px 6px"
                      >
                        <MuiTypography
                          color={Color.primary}
                          fontSize={FontSize.small}
                          fontWeight={FontWeight.semibold}
                        >
                          No timeslots available
                        </MuiTypography>
                      </MuiBox>
                    )}
                  </MuiGrid>
                </MuiBox>
              ) : null}
            </>
          ))
        ) : (
          <MuiBox
            width="100%"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            padding="15px 0 0"
          >
            <MuiTypography
              color={Color.primary}
              fontSize={FontSize.small}
              fontWeight={FontWeight.semibold}
            >
              {bookingVisitTypes.GET_VISIT_TYPES.NO_DATA.message}
            </MuiTypography>
          </MuiBox>
        )}
      </CollapsibleListItemNpv>
    </MuiGrid>
  );
};

export default SpecialtyProviderLocation;
