import React from 'react';

export default {
  svg: (
    <g id="magnifying-glass" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="collections" transform="translate(-699.000000, -305.000000)" fill="#222222">
        <g id="Icons-/-Search" transform="translate(699.000000, 305.000000)">
          <path d="M2.28875142,9.34481947 C2.28875142,5.45409464 5.45409464,2.28875142 9.34481947,2.28875142 C13.2356969,2.28875142 16.4010401,5.45409464 16.4010401,9.34481947 C16.4010401,11.1712431 15.7034287,12.8376067 14.5610366,14.0918425 C14.3375018,14.3313985 14.419134,14.2497664 14.3375018,14.3313985 C14.2558697,14.4130306 14.3375018,14.3313985 14.0916899,14.5611891 C12.8374541,15.7035813 11.1712431,16.4010401 9.34481947,16.4010401 C5.45409464,16.4010401 2.28875142,13.2356969 2.28875142,9.34481947 L2.28875142,9.34481947 Z M16.7077328,15.0891278 C17.9479309,13.5031756 18.6897915,11.5096732 18.6897915,9.34481947 C18.6897915,4.19192452 14.4977144,0 9.34481947,0 C4.19207711,0 0,4.19192452 0,9.34481947 C0,14.4977144 4.19207711,18.6897915 9.34481947,18.6897915 C11.5096732,18.6897915 13.5031756,17.9479309 15.0892804,16.7077328 L22.0463218,23.6649268 C22.2698565,23.8881563 22.5628167,24 22.8556243,24 C23.1484319,24 23.4413921,23.8881563 23.6649268,23.6649268 C24.1116911,23.2178573 24.1116911,22.4933912 23.6649268,22.0463218 L16.7077328,15.0891278 Z"></path>
        </g>
      </g>
    </g>
  ),
  viewBox: '0 0 24 24'
};
