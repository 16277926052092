import React from 'react';

export default ({ color = '#4A00E2' }: any) => ({
  svg: (
    <g fill="none">
      <path
        d="M15.75 12H2.25C1.42157 12 0.75 12.6716 0.75 13.5V21C0.75 21.8284 1.42157 22.5 2.25 22.5H15.75C16.5784 22.5 17.25 21.8284 17.25 21V13.5C17.25 12.6716 16.5784 12 15.75 12Z"
        stroke={color ?? '#4A00E2'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.25 19.832L22.237 21.702C22.3504 21.7444 22.4725 21.7588 22.5927 21.7438C22.7129 21.7289 22.8276 21.685 22.9272 21.616C23.0267 21.547 23.1081 21.455 23.1643 21.3477C23.2205 21.2404 23.2499 21.1211 23.25 21V14.164C23.2498 14.0429 23.2204 13.9237 23.1641 13.8166C23.1078 13.7094 23.0264 13.6175 22.9268 13.5487C22.8272 13.4799 22.7125 13.4362 22.5923 13.4215C22.4722 13.4067 22.3503 13.4213 22.237 13.464L17.25 15.334V19.832Z"
        stroke={color ?? '#4A00E2'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.5 15H12.75"
        stroke={color ?? '#4A00E2'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.5 9C6.57107 9 8.25 7.32107 8.25 5.25C8.25 3.17893 6.57107 1.5 4.5 1.5C2.42893 1.5 0.75 3.17893 0.75 5.25C0.75 7.32107 2.42893 9 4.5 9Z"
        stroke={color ?? '#4A00E2'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.25 9C15.9069 9 17.25 7.65685 17.25 6C17.25 4.34315 15.9069 3 14.25 3C12.5931 3 11.25 4.34315 11.25 6C11.25 7.65685 12.5931 9 14.25 9Z"
        stroke={color ?? '#4A00E2'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  ),
  viewBox: '0 0 24 24'
});
