import React from 'react';

import { COVID_COLORS_MASK } from 'store/booking/constants';

const styles = {
  stroke: COVID_COLORS_MASK.st45,
  strokeWidth: 3,
  strokeMiterLimit: 10
};

export default {
  svg: (
    <g>
      <path
        fill={COVID_COLORS_MASK.st0}
        d="M226.6,282.4c-0.5,1.1-0.7,2.4-0.4,3.6c2.1,8.2,4.3,16.3,8.7,23.7c1.2,2.1,3.1,3.7,5.4,4.6
	c9.5,3.8,19.3,6.9,29,10.4c4.1,1.5,8.1,3.2,12.1,4.8c1.8,0.9,2,2,0.6,3.4c-3.1,3.2-6.2,6.3-9.4,9.4c-0.9,0.9-2.4,0.8-3.2,1.8h-35.5
	c0.1-2.5,2.2-2.1,3.6-2.3c4.7-0.6,9.5-0.4,14.2-1.1c2.2-0.3,4.2-1.2,5.9-2.6c-0.9-0.8-1.8-0.5-2.6-0.5c-8.6-0.3-17.2,0.1-25.7,1.1
	c-4.3,0.5-8.4,1.6-11.1,5.4h-51.2c-3.6-5.2-9.3-5.4-14.7-5.9c-7.4-0.7-14.8-0.9-22.1-0.7c-0.9,0-1.8,0.1-2.6,0.5
	c1.3,0.9,2.9,1.4,4.5,1.5c8.9,0.3,17.7,1,26.5,2.2c1.6,0.2,3.8-0.3,4.2,2.3h-46.5c-5.5-2.4-9-7.1-13.1-11.2
	c-1.1-1.1-1.2-2.5,0.5-3.4c12.8-5.4,26.2-9.4,39.2-14.2c3.9-1.4,7-3.5,8.8-7.5c3.3-7,5.8-14.4,7.4-21.9c0.3-1.2,0.2-2.4-0.5-3.5
	c0.9-4,1.1-7.6-3-10.6c-3.2-2.3-5.8-5.6-8.9-8.2c-2.4-2.9-5-5.6-7.3-8.6c-0.3-0.4-0.8-0.7-1.3-0.7c-0.5-0.8-0.7-1.7-0.5-2.6
	c0.8-1.4,1.8-1,2.8-0.2c1.2,1.2,2.5,2.3,3.9,3.4c2.2,3,6,3.6,8.8,5.8s6.5,2.9,9.6,4.5c1,0.4,2.1,0.4,3.1,0.1
	c2.6-0.4,5.2-0.4,7.8-0.1c-1.9-1.2-3.9-2.3-6-3.2c-0.8-0.2-1.2-1.1-1-1.9c0.1-0.2,0.1-0.4,0.3-0.5c1.1-1,2.3-0.5,3.4,0
	c4,1.7,8,3.3,12,5c4.9,2,10,3.5,15,5.3c1.4,0.5,3.5,0.8,3.3,2.8c-0.2,2.3-11.3,2.2-15.1,1.7c-1.9-0.3-3.6,1.3-5.6,0.6
	c-1.8-0.6-3.8-0.4-5.6-1.3c-4.3-0.7-8.2-2.6-12.2-4.3c3.8,3,7.8,5.7,11.6,8.6c1.8,1.4,0.9,2.7-0.2,3.9c2.5,2,5.8,2.6,7.9,5.1
	c2.9,2.8,10.5,6.1,14.3,4.3c2.7-1.3,6-1.6,7.9-4.5c3.2-2.2,6.9-3.7,10.1-6c2.6-1.9,5.6-3.4,6.3-6.9c0.1-0.3,0.3-0.6,0.6-0.8
	c4.4-4.1,8-8.9,12.6-12.9c2.2-1.9,2.9-1.6,3.4,1.3c-0.5,1.7-1.8,2.8-2.8,4.1c-1.4,0.8-2.9,1.4-3.3,3.2c-0.5,1.1-1.6,1.6-2.4,2.4
	c-2.7,2.4-3.5,6.2-2.1,9.5C226.3,281.1,226.5,281.7,226.6,282.4z"
      />
      <path
        fill={COVID_COLORS_MASK.st1}
        d="M218.2,344c-0.1-2.5,1.3-4,3.6-4.9c3.3-1.2,6.7-1.9,10.2-2.1c9.9-0.8,19.9-1.3,30,0.2c-3.1,1.8-5.8,4.4-9.4,4.8
	c-4.6,0.5-9.2,0.8-13.7,1.1c-1.7,0-3.3,0.4-4.8,1.1L218.2,344z"
      />
      <path
        fill={COVID_COLORS_MASK.st2}
        d="M103.8,329.4c-0.6,1.5,0.3,2.4,1.1,3.4c3.5,4.1,8,7.1,11.4,11.2H97.6c-2.7-2.4-5-5.2-7.6-7.7
	c-1.4-1.4-1.5-2.6,0.3-3.8C94.7,331,98.8,328.4,103.8,329.4z"
      />
      <path
        fill={COVID_COLORS_MASK.st2}
        d="M269.5,344c0.9-2,3-3,4.4-4.6c2.3-2.5,4.7-4.8,7.1-7.1c0.9-0.9,1.5-1.7,0.5-2.9c4.7-0.9,8.8,1.2,13.1,2.6
	c1.9,1.7,2.2,2.6,0.6,4.3c-2.4,2.5-4.1,5.6-6.9,7.7L269.5,344z"
      />
      <path
        fill={COVID_COLORS_MASK.st3}
        d="M288.3,344c0.7-3.1,3-5.1,5-7.3c1.2-1.3,2.7-2.6,1.3-4.7c5.2,2,10.8,2.8,15.8,5.3c3.8,1.9,7.7,3.7,10.9,6.7
	H288.3z"
      />
      <path
        fill={COVID_COLORS_MASK.st3}
        d="M90.3,332.5c-0.7,1.3-0.1,2.3,0.8,3.2c1.7,1.7,3.4,3.4,5.1,5.2c0.8,0.8,1.3,1.9,1.4,3H64.7
	C72,337.5,81,334.6,90.3,332.5z"
      />
      <path
        fill={COVID_COLORS_MASK.st1}
        d="M162.9,344c-4.2-1.5-8.7-1.6-13.1-2c-6.1-0.6-12.3-1.1-18.5-1.2c-3-0.1-5.3-2.1-7.9-3.9
	c2.2-0.2,4.4-0.6,6.6-0.7c8.9-0.2,17.7,0.1,26.6,1c2.5,0.3,5,1,7.3,1.9c2.1,0.6,3.5,2.7,3.2,4.9L162.9,344z"
      />
      <path
        fill={COVID_COLORS_MASK.st4}
        d="M125.3,235.2c-6.9-4.6-13.4-9.6-18.6-16.1c-6.6-8.3-10.9-18.1-12.3-28.6c-0.2-0.7-0.3-1.5-0.2-2.2
	c0-0.4,0.1-1-0.5-1s-0.4,0.8-0.8,1.1c-1.3,11.8-0.7,23.4,3.7,35c-2-0.5-2.2-1.6-2.6-2.4c-4-8.1-6.1-17.1-6.2-26.2
	c-0.5-26.5,4.1-51.9,15.8-75.9c0.2-0.4,0.4-0.7,0.7-1.1c1.2-1.2,1.9-2.7,2.2-4.3c0.5-2.7,1.6-3.1,4.1-2c0.8,0.4,1.6,0.7,2.5,0.7
	c4.5-0.4,8.3,1.6,12.3,3.3c3.7,1.6,7.2,3.7,10.8,5.4c3.2,1.6,6.5,3.2,9.7,4.8c3.8,1.9,7.5,4,11.3,5.8c2.7,1.3,3.7,4.1,1.7,6.2
	s-4,4.4-7,5.2c-2,0.6-3.4,2.1-5.1,3.1c-4.9,2.4-9.6,5.2-14.1,8.3c-7.6,5.4-11.7,12.6-10.8,22.2c0.2,1.9-0.3,3.1-2.3,3.2
	c-0.1,0.2-0.5,0.3-0.3,0.5c2.6,3.1,2.8,6.9,3,10.7c0.1,1,0.4,1.9,0.4,2.8c-1.3,2.2-2.4,2.4-3.6,0.1c-2.1-3.6-3.9-7.4-5.4-11.3
	c-0.2-0.9-0.7-1.8-1.5-2.4c-0.2,2,0.7,3.6,1.4,5.3c2.7,6.8,6.7,13,9,19.9c1.3,3.9,2,7.8,3,11.8c0.1,4.9,1,9.6,1.7,14.4
	c0.2,0.8,0.3,1.7,0.1,2.6C127,235.1,126.6,236.1,125.3,235.2z"
      />
      <path
        fill={COVID_COLORS_MASK.st5}
        d="M125.3,235.2c1.5-0.1,1-1.1,0.9-1.9c-0.9-5.5-1.4-11.1-1.5-16.7c-0.4-3.6-1.3-7.1-1.1-10.7c0-1.3,0.4-2.6,1-3.7
	c2-1.3,2.3,0.3,2.4,1.6c0.3,2.9-0.1,5.9,0,8.9c0.1,4.5-0.2,9,0.4,13.4c0.1,0.8,0.3,1.5,1.3,1.7c0.8,0.2,1.4,0.8,1.5,1.6
	c0.4,2.2,1.9,2.4,3.8,2.7c4.9,0.8,9.9-0.5,14.8,0.4c0.4,0.1,0.9,0,1.3-0.2c4.4-1.9,9.1-1.7,13.6-1.4c5.5,0.4,10.9-1.4,16.3-1.1
	c1.7,0,3.5,0.2,5.2,0.6c2.3,0.8,4.5-0.4,6.7-0.7c3.2-0.5,6.2,0.1,9.3,0.4c2.3,0.3,4.7-0.2,6.8-1.3c1.4-0.7,2.9-1.1,4.5-1
	c4.9,0.1,9.9-0.5,14.8,1c1.4,0.3,2.8,0,4-0.8c3.2-1.7,7-2.3,10.6-1.6c2.6,0.4,5.2-0.3,7.8-0.2c1.1,0,2.1-0.5,2.6-1.5
	c1-2,0.8-4.4,2.3-6.2c0.2-0.2-0.1-0.7-0.2-1c-1.6-3.7-1.5-4.2,1.7-7.1c0.3,0,0.6,0,0.9,0.1c1.4,1.1,1.4,2.7,1.4,4.2
	c0.1,4.5,0.1,9.1-0.2,13.6c-3.6,3.4-5,8.2-7.8,12.1c-1.4,1.9-2.8,3.8-2.6,6.3c-1.4,0.9-1.4,2.9-3,3.7c-1.9,0-2.9,1.8-4.5,2.4
	c-2.8,0.1-5.2,1.9-8.1,1.4c-1-0.2-2.2,0.4-2.7-0.9s0.8-1.5,1.4-2.1c1.8-2,3.6-4,6.1-5.2c0.9-0.2,1.7-0.6,2.5-1.1
	c-4.2,2.1-8.3,4.3-12.7,6c-2,0.7-4.1,0.5-6,1.6c-5.2,1.3-10.4,2.8-15.7,3.5c-7.8,1.2-15.6,1.3-23.4,0.5c-3.6-0.4-7.2-0.7-10.8-1.2
	c-7.8-0.6-15.4-2.1-22.8-4.6c2,0.7,4.3,1,5.7,3c0.6,2.5,0.2,3-2.2,3.5c-2.9,0.7-5-1.3-7.5-1.8l-3.4-3.1c-0.7-0.6-1.2-1.5-2.4-1.1
	c-2.5-4.2-5.8-7.7-8.7-11.5C127.9,238.4,127,236.5,125.3,235.2z"
      />
      <path
        fill={COVID_COLORS_MASK.st6}
        d="M145.6,145c2.4-2.2,5.4-3.1,8.2-4.4c2.3-0.8,4.1-2.8,4.5-5.3c0.2-1.3-0.2-1.8-1.4-2.4c-4.2-2.1-8.6-4-12.6-6.6
	c-0.9-0.6-2.1-0.5-2.9-1c-3.7-2.2-8-3.6-11.4-6.3c-0.1-0.1-0.3-0.3-0.4-0.3c-4.3-0.7-7.5-3.9-11.7-4.9c-1.3-0.4-2.7-0.6-4.1-0.4
	c-1.5,0.4-3.2,0-4.4-1c-1.4-1.2-2.7-0.4-2.4,1.1c0.5,2.6-1,3.4-2.8,4.2c3.2-8.6,7.6-16.7,13-24.1c0.4-0.4,0.8-0.7,1.2-1
	c0.8-0.6,1.9-0.8,2.9-0.4c5.7,1.6,11.1,3.9,16.2,6.9c3.2,1.8,6.7,3.1,9.8,5.2c2.8,1.9,6.3,2.8,9.2,4.7c3.9,2.4,8.2,4,12.3,5.9
	c3.5,1.6,6.6,4.1,10.4,5.1c2,0.7,3.7,2,4.9,3.7c1.2,1.5,3.1,2.3,5,1.9c3-0.4,4,0.5,4.7,3.5c0,0.5-0.1,1-0.1,1.6
	c-1.2,2.4-3.7,3.4-5.7,4.8c-6.9,4.6-14,8.7-22.3,10.4c-1.4,0.3-2.8,0.3-4.1,0.6c-0.9,0.3-1.8-0.2-2.2-1c-0.3-0.7,0-1.6,0.7-2
	c4.8-3.3,9.2-6.9,12.7-9.7c-7.2,3.9-16.2,7.5-24.8,11.6C147.3,145.8,146.4,146,145.6,145z"
      />
      <path
        fill={COVID_COLORS_MASK.st7}
        d="M124.6,84.9c4.8-5.8,10.4-10.7,16.7-14.7c1.1-0.7,2.2-1.3,3.3-2c4-0.1,4.1,0.2,9,2s9.3,5,14.5,6.5
	c1.4,0.4,2.3,1.8,3.7,2.4c4.4,1.9,8.8,4.1,13.1,6.4c3,1.6,5.8,3.4,8.9,4.8c3.8,1.8,7.8,3.4,11.4,5.6c1.2,0.6,2.5,0.7,3.8,0.4
	c4.2-1,5.1-0.2,5.4,4c0.1,1.5,1.3,2.7,0.8,4.3c-0.7,2.6-1.8,5.2-3.2,7.5c-0.5,0.9-1.1,1.8-2.3,1.8c-2.5-1.4-5-0.7-7.6-0.2
	c-3.7,0.6-4.2,0.3-5.5-3.3c-0.7-1.9-2.2-3.4-4.1-4.1c-2.6-1.1-5.2-2.4-7.6-3.8c-2.4-1.2-4.9-2.2-7.2-3.4c-4.3-2.4-8.7-4.6-13.2-6.7
	c-3.6-1.7-7.1-3.5-10.7-5.2c-3.2-1.6-6.5-3.1-9.7-4.9c-2.3-1.3-4.6-2.4-7.1-3.4c-2.6-1.1-4.7-0.1-6.5,2.7c-1,0.8-2.1,1.5-3,2.4
	C126.7,84.7,125.8,85.7,124.6,84.9z"
      />
      <path
        fill={COVID_COLORS_MASK.st8}
        d="M265.8,107.4c4.3,5.5,6.9,11.8,8.9,18.3c-0.4,0.2-0.8,0.1-1.1-0.1c-0.9-3.2-4.2-4.9-5.2-8
	c-0.1-0.3-0.2-0.5-0.5-0.6c-3.3-1.3-6-3.8-9.8-3.8c-1.2,0-2.4,0.1-3.2-0.7c-1.9-1.7-4.4-2.1-6.3-3.3c-3.2-2.2-7.1-2.8-10.1-5.3
	c-0.6-0.5-1.4-0.8-2.1-1c-2.2-0.8-4.5-1.3-6.2-3.2c-0.4-0.5-1-0.7-1.6-0.8c-3.1-0.1-5.3-2.1-7.7-3.6c-1.4-1-2.9-1.9-4.5-2.7
	c-1.3-0.6-2.5-1.5-3.3-2.7c-2-2.9-5.2-4.4-7.8-6.8c-0.8-0.7-1.9-0.7-2.6,0c-2.4,1.6-3.7,1.7-5.9,0c-1.4-1-3-1.8-4.6-2.3
	c-4.1-1.7-7.9-3.9-11.9-5.8c-3.2-1.5-6.4-3.1-9.4-4.8c-3.9-2.3-8.3-3.6-11.9-6.3c-0.6-0.4-1.2-0.8-1.3-1.6c0.6-2,2.9-1.2,4-2.3
	c1.7-0.4,3.3-1,5-1.3c2.8-0.6,5.4,0.4,7.8,1.5c3.5,1.5,6.9,3.2,10.2,5.1c3.2,1.9,6.6,3.5,10,4.9c1.3,0.6,2.5,1.2,3.7,1.9
	c5.6,3.2,11.7,5.7,17.5,8.6c2.9,1.5,5.7,3.2,8.7,4.5c3.6,1.6,7.2,3.4,10.7,5.3c5.4,2.9,11.1,5.3,16.3,8.5c0.4,0.2,0.9,0.5,1.2,0.3
	c4-1.6,6,1.9,8.8,3.5c0.9,0.5,1.6,1.2,2.5,1.8S265.1,106.6,265.8,107.4z"
      />
      <path
        fill={COVID_COLORS_MASK.st9}
        d="M193.2,129.3c-0.7-2.1-2.1-2.8-4.2-2.3c-2.4,0.5-4.5,0.7-5.8-2.3c-0.8-1.4-2.1-2.5-3.7-3.1
	c-2.6-1-4.9-2.7-7.4-3.6c-3.3-1.2-6.1-3.4-9.6-4.5c-3-0.9-5.7-2.9-8.6-4.3c-4.5-2.2-9.1-4.2-13.3-6.7c-3.4-2-6.9-3.4-10.3-5.3
	c-3.6-2.1-7.9-3-11.9-4.5c0.4-1.7,1.8-2.8,2.8-4.2c1.9-1.7,3.9-3.3,6-4.8c0.9-0.8,2.1-0.9,3.1-0.2c7.6,4.3,15.6,8,23.4,11.9
	c9.1,4.6,18.3,9.1,27.5,13.8c2.8,1.4,5.9,1.9,8.4,4c1.3,1.1,2.1,1.8,1.8,3.6c-0.4,3.1,0.5,3.7,3.4,2.6c3.7-1.3,5.9-0.6,7.2,2.3
	c-0.4,1.8-1.3,3.5-2.6,4.9c-0.6,0.4-1.1,0.8-1.5,1.3C196.6,128.8,195.1,129.8,193.2,129.3z"
      />
      <path
        fill={COVID_COLORS_MASK.st10}
        d="M274.1,125.1l0.6,0.5l2.6,7.4c0.1,0.1,0.2,0.3,0.1,0.4c0,0.1-0.1,0.2-0.1,0.2c-1.1,0.9-1.9,0.3-2.7-0.5
	c-1.5-1.6-3.4-2.7-5.5-3.2c-1.6-0.2-3-1-4-2.2c-0.6-0.6-1.3-0.9-2.1-1c-4.1-1-7.3-3.8-11-5.6c-1.1-0.6-2.3-1.1-3.5-1.6
	c-1.8-0.4-3.4-1.3-4.8-2.5c-0.2-0.3-0.5-0.5-0.9-0.6c-4.3-0.7-7.4-4.1-11.7-4.8c-1.3-0.2-1.9-1.2-2.8-1.9c0.4,1.1,1.6,2,0.7,3.3
	c-1.4,1.1-2.2,0-3.1-0.8c-2.3-1.9-3.9-4.4-6-6.4c-1.8-1.7-3.6-3.7-6.4-1.6c-0.1-1.7-0.1-3.3-0.3-4.9s-1.1-2.9-2.8-2.2
	c-3.1,1.4-5.6,0.5-8-1.2c-2.8-2-6.1-2.7-9.1-4.3c-5.4-3-10.8-5.7-16-8.7c-1.9-1.1-4.2-1.4-6.2-2.6c-4.7-2.6-9.5-5-14.3-7.3
	c-2.5-1.3-5.4-1.9-7.5-4c-0.6-0.5-1.4-0.5-2-0.2c-1,0.4-2.1,0.1-2.9-0.7c2.1-1.8,4.8-2.6,7.2-3.8c1.1,0.5,2.3,0.2,3.4,0.4
	c2.7,0.5,5.3,1.5,7.7,2.9c3.2,2,6.7,3.7,10.2,5.1c1.9,0.7,3.3,2.4,5.2,3c6.1,2.1,11.5,5.7,17.5,8c1.6,0.5,3,1.3,4.2,2.3
	c2.4,2.2,4.3,2.3,6.9,0.5c1.4-1,2.6-0.3,3.6,0.6c1.8,1.7,3.5,3.6,5,5.7c1.2,1.4,2.7,2.5,4.3,3.3c5.2,2.7,10.6,5.2,15.9,7.8
	c0.7,0.4,1.4,0.7,2.1,0.9c2.4,0.6,4.7,1.8,6.6,3.4c2.8,2.2,6.1,3.5,9.4,5c3,1.3,6.2,2.1,9.3,3.3s4.8,4.5,8.4,5
	C273,121.9,272.8,124.3,274.1,125.1z"
      />
      <path
        fill={COVID_COLORS_MASK.st11}
        d="M257.9,228.5c-1.6-0.2-1-1.4-0.9-2.2c0.3-5.2,0.1-10.4-0.6-15.5c-1.3-1.3-0.8-3-0.7-4.5c0.5-7,2-13.8,4.6-20.3
	c1-2.8,3.3-4.7,4.2-7.7c0.5-1.6,2.8-1.5,4.2-0.9c4.7,2.4,9.6,4.4,14,7.4c1,0.6,1.5,1.8,1.4,2.9c0,6.6-0.8,13.3-2.7,19.7
	c-0.1,0.4-0.2,0.8-0.4,1.2c-0.3,0.5-0.7,1.2-1.3,1c-0.9-0.3-0.5-1.1-0.3-1.7c0.1-1.4,0.3-2.7,0.5-4.1
	C274.3,213.8,266.8,221.7,257.9,228.5z"
      />
      <path
        fill={COVID_COLORS_MASK.st12}
        d="M279.3,208.1c0.4,1.6,1,1.2,1.5,0.1c0.8-1.7,1.3-3.6,1.5-5.5c0.1-3.8,1.3-7.5,1.4-11.3s-1.6-5.9-4.6-7.2
	c-3.4-1.5-6.7-3.4-10-5c-2.7-1.3-3.6-1-4.7,1.8c-0.7,2-1.7,4-2.8,5.8c-1.3-0.8-0.9-1.9-0.6-3c1-3.4,2.5-6.7,3.1-10.2
	c0.1-0.8,0.6-1.6-0.2-2.8c-1.2,4.4-2.5,8.5-4,12.6c-0.5,1.4-1,2.9-3.1,2.4c-1.1-0.6-1.1-1.7-1.1-2.7c-0.1-4-0.4-7.9,2-11.5
	c-0.3,0.5-0.9,0.9-1.5,1c-0.9,0.2-1.7-0.4-1.9-1.2c0-0.1,0-0.3,0-0.4c-0.6-7.6-1.7-15.1-3.2-22.5c0-0.2,0-0.3,0-0.5
	c1.3-2.6,1.9-2.8,4.4-1.8c2.2,0.9,4.4,1.6,6.5,2.5c2.9,1.1,5.1,3.5,7.9,4.9c3.3,1.6,5.9,4.8,10,5.1c2.3,0.2,2.7,2.9,3.9,4.4
	c0.2,2.6,0.5,5.1,0.9,7.7c0.5,1.9,0.6,3.9,0.5,5.9c-0.5,8.8-0.5,17.7-2.2,26.4c-0.8,4.8-2.6,9.4-5.2,13.5c-0.3,0.5-0.8,0.7-1.4,0.6
	C277.4,214,278.3,211,279.3,208.1z"
      />
      <path
        fill={COVID_COLORS_MASK.st10}
        d="M274.1,125.1c-2-2-4.3-3.6-7.4-3.5c-0.6,0-1.2-0.4-1.4-1c-0.3-2.4-2.2-2.8-3.9-3.2c-5.9-1.2-11.5-3.6-16.4-7
	c-4.2-3-8.9-4.6-13.3-6.8c-4-2-7.9-4.3-12-6c-2.6-1-4.8-2.9-6.1-5.3c-0.7-1.2-1.5-2.3-2.5-3.3c-1.4-1.4-2.6-2.3-4.5-0.3
	c-2.2,2.4-4.9,0.7-6.6-0.8c-3.7-3.2-8.5-4.2-12.6-6.5c-5.4-3-11.4-5-16.7-8.1c-3.4-2-7.2-2.9-10.2-5.6c-1.6-1.4-4-1.2-5.9-1.9
	c-1-0.4-2.2-0.5-2.9-1.6l0.4-0.4c1.2-0.3,2.3-0.7,3.3-1.3c0.9-0.4,1.7-0.8,2.6-0.2c3.6,2.1,7.6,3.7,11.4,5.5
	c3.4,1.7,6.4,4.2,10.3,5.2c2.6,0.7,5,2.6,7.5,3.8c2,1.1,4,2,6.1,2.8c1.6,0.5,3,1.3,4.3,2.3c1.6,1.3,3.2,1.9,4.4-0.7
	c1-2.2,2.5-0.7,3.4,0c2.9,2.1,5.5,4.6,7.8,7.2c0.9,1.1,2,1.9,3.2,2.5c1.6,0.8,3.2,1.7,4.7,2.7c2.6,1.6,4.8,3.8,8.2,3.6
	c0.2,0,0.4,0,0.5,0.1c2.7,3.4,7.5,3,10.5,5.9c0.8,0.7,1.8,1.1,2.8,1.2c1.4,0.2,2.7,0.8,3.7,1.8c1.2,1.1,2.8,1.3,4.1,2
	c3.4,1.7,6.6,3.5,10.6,3.7c1.3,0.1,2.5,1.7,4.1,2c2.3,0.5,3.9,1.3,4.7,4.1C271.2,120.7,273.5,122.5,274.1,125.1z"
      />
      <path
        fill={COVID_COLORS_MASK.st13}
        d="M167.1,59.3l-5.2,1.1c-0.1,0.1-0.4,0.2-0.5,0c0,0-0.1-0.1-0.1-0.1c8.5-3.1,17.5-4.6,26.6-4.3
	c9.9,0.1,19.8,2,29,5.8c15.9,6.4,28.7,16.7,39.3,30.1c0.2,0.3,0.4,0.6,0.5,0.9c-2.1,0.8-3.7-0.7-4.5-2c-2.2-3.5-6.2-4.3-9-6.9
	c-0.4-0.3-0.9-0.5-1.5-0.5c-7.6,0.2-13.3-4.7-19.8-7.5c-3.6-1.6-7-3.5-10.5-5.2c-3.3-1.6-6.5-3.3-9.7-4.9c-7.1-3.5-14-7.6-22.2-7.8
	c-1.5,0-3.1-0.1-4.4,1c-1,0.4-2.1,0.5-3.2,0.3C170.3,58.9,168.6,58.9,167.1,59.3z"
      />
      <path
        fill={COVID_COLORS_MASK.st14}
        d="M174.9,57.8c4.3-0.8,8.5-1.2,12.9,0.3c5.7,1.9,10.7,5,16,7.5c4.8,2.2,9.4,4.7,14.1,7s9.3,4.9,14.1,6.9
	c2.6,1.1,5.2,3.3,8.7,2.3c1.8-0.3,3.6,0.5,4.6,2c1.1,1.6,3.2,1.6,4.6,2.8c2.3,1.9,3.7,4.9,6.6,6.2c1.3,1.2,2.3,2.6,3.1,4.2
	c-1,0.6-2.3,0.4-3.1-0.3c-3.5-2.3-7.4-3.8-11.4-4.5c-1.9-0.3-3.7-1.1-5.2-2.3c-2.7-2.4-6.1-3.4-9.1-5c-4.9-2.4-9.9-4.7-14.6-7.3
	c-4.1-2.3-8.5-3.9-12.7-5.9c-3.8-1.8-6.9-4.6-10.6-6.1c-3.4-1.4-6.6-3.5-10.3-4.5C179.8,60.4,177.2,59.3,174.9,57.8z"
      />
      <path
        fill={COVID_COLORS_MASK.st15}
        d="M201.5,122c-1.7-2.2-3.9-2.3-6.3-1.6c-3.6,1-4.4,0.5-4.6-3.2c-0.2-3.2-3-4.2-5-4.9c-6.7-2.2-12.7-6-19-9
	c-7.4-3.5-14.6-7.5-22.1-11c-5-2.3-9.9-4.8-14.7-7.6c-0.9-0.6-2.1-0.5-2.9,0.2c-1.8,1.3-3.5,2.8-5.6,3.7c0.9-1.4,2-2.6,3.2-3.7
	c2.2-0.7,3.1-3,4.9-4.1c0.4-0.3,0.9-0.5,1.3-0.8c2.2-1.7,2.3-1.7,3.7,0.6c0.5,0.9,1.3,1.6,2.3,2c5.3,2.4,10.4,5.3,15.8,7.5
	c2.4,1,4.5,2.5,6.8,3.7c6.1,3.2,12.5,6,18.6,9.3c3,1.6,6.4,2.8,9.4,4.3s7.2,2.8,7.2,7.6c0,0.8,0.5,1.4,1.3,1.2c2-0.3,4,0,5.9-0.8
	c3.9-1.5,5-0.6,4.6,3.7c-0.3,0.6-0.9,1.1-1.2,1.7C204.3,121.9,202.9,122.4,201.5,122z"
      />
      <path
        fill={COVID_COLORS_MASK.st16}
        d="M174.9,57.8c4,0.7,7.9,1.9,11.6,3.8c5,2.3,10.1,4.6,14.4,8.2c1.2,0.8,2.4,1.3,3.8,1.6
	c7.4,2.2,13.7,6.9,20.7,9.8c3.6,1.5,6.5,4.4,10.6,4.8c0.7,0.1,1.5,0.8,2.1,1.4c2.4,2.6,5.9,3.9,9.4,3.7c0.6,0,1.2,0.1,1.7,0.5
	c3.5,1.9,7,3.7,10.5,5.5c2.3,3.3,5.2,6.3,6.1,10.5l-1.5-2.1c-1.7-2.1-4.1-3.4-5.8-5.6c-1.2-1.5-3.2-2.3-5.1-2.1
	c-2.2,0.2-4.4,0.2-5.8-2c-0.1-0.2-0.2-0.3-0.4-0.3c-4.1-0.5-6.9-3.5-10.4-5.1c-3.3-1.5-6.4-3.3-9.7-4.9c-3.8-1.9-7.2-4.5-11.4-5.4
	c-0.7,0-1.4-0.3-1.9-0.8c-1.5-1.7-3.7-2.8-6-2.9c-0.4,0-1,0-1.2-0.3c-2.6-2.5-6.1-3.2-9.1-5c-3.3-1.9-6.4-4.4-10.3-5.3
	c-0.9-0.2-1.8-0.7-2.5-1.3c-1-1-2.3-1.5-3.7-1.7c-1-0.1-2-0.5-2.9-1.1c-1.5-1.1-3.2-1.8-5-2.1c-0.6,0-1.2-0.5-1.3-1.1
	C172.7,57.8,173.8,57.6,174.9,57.8z"
      />
      <path
        fill={COVID_COLORS_MASK.st17}
        d="M244,249.5c0.7-0.9,1.4-1.8,2-2.7s1-0.7,1.5,0.1c0.1,6.2-0.6,12.2-4,17.7c-1.8,3.9-4.7,5.9-9.1,5.4
	c-1.2-0.1-2.5,0.1-3.2-1.2c-0.8-1.5,0.5-2.2,1.2-3.1c0.2-0.4,0.4-0.8,0.7-1.1c0.8-1.2,2.2-2.1,2.6-3.6
	C237,256.1,240.8,253.1,244,249.5z"
      />
      <path
        fill={COVID_COLORS_MASK.st10}
        d="M232.9,264.6c0,0.2,0,0.3-0.1,0.5c-1.2,3.6-0.9,3.9,3.1,3.7c3,0,5.8-1.6,7.5-4.1c-2.7,5.8-7,10.3-12.1,14.1
	c-1.2,0.7-2.4,1.5-3.5,2.3c-2,1.3-2.4,1.2-2.8-1.1c0-0.4-0.2-0.9-0.3-1.2c-1-2.9-0.8-5.5,1.9-7.6c1.2-1,2.3-2.1,3.2-3.4L232.9,264.6
	z"
      />
      <path
        fill={COVID_COLORS_MASK.st18}
        d="M147.8,262.5c4.1,3.8,8.1,7.6,12.2,11.4c1.8,1.7,0.9,7.2-1.2,8.4c-6.3-4.4-12.1-9.3-16-16.1
	c-1.5-2.7-2.8-5.5-3.7-8.4c1.5-0.7,1.7-0.7,2.4,1c1.1,2.9,2.6,5.7,4.3,8.2c0.8,1.3,1.9,2.4,3.3,3.2c0.7,0.5,1.6,0.3,2.1-0.3
	c0.6-0.7,0-1.3-0.4-1.8c-0.9-1-1.9-2-2.9-2.9S146.5,263.4,147.8,262.5z"
      />
      <path
        fill={COVID_COLORS_MASK.st19}
        d="M147.8,262.5c0.5,2.5,2.9,3.3,4.2,5.1c0.8,1.1,2.8,1.8,1,3.5c-1.6,1.4-4,1.4-5.6,0c-3-3.3-5.4-7.2-7-11.4
	c-0.3-0.7-0.1-1.9-1.4-1.9c-1-1.1-0.5-2.4-0.7-3.6c0.1-0.5,0.1-1.2,0.4-1.4c0.5-0.3,0.9,0.4,1.2,0.7
	C142.5,256.5,145.1,259.5,147.8,262.5z"
      />
      <path
        fill={COVID_COLORS_MASK.st10}
        d="M284.6,170.6c-0.7-2.6-1.1-5.2-1.2-7.9c-1.3-1.5-2.1-3.4-2.3-5.4c-0.4-3.4-1-6.8-1.9-10.1c0-2.6-1-4.8-1.9-7.1
	c-0.8-2.1-2-4.3-0.2-6.6c0-0.2,0-0.3,0.1-0.5c2,4.3,2.9,8.9,3.9,13.4C283,154.4,284.5,162.4,284.6,170.6z"
      />
      <path
        fill={COVID_COLORS_MASK.st14}
        d="M161.2,60.3l0.6,0.1c-1,1-2.8,0.7-3.6,2c-2.2-0.2-3.8,1.7-6,1.5C155,62.3,158,61.1,161.2,60.3z"
      />
      <path
        fill={COVID_COLORS_MASK.st20}
        d="M225,278.7c1.1,2.9,1.1,2.9,3.8,1.2c0.7-0.6,1.6-1,2.5-1.1c-1.3,1.6-3.2,2.4-4.7,3.7c-0.7,0-1.3-0.4-1.5-1
	C224.6,280.5,224.6,279.6,225,278.7z"
      />
      <path
        fill={COVID_COLORS_MASK.st21}
        d="M98.8,228.9c-2.1-2.4-2.1-2.4-2-4.2C98.4,225.8,98.6,227.4,98.8,228.9z"
      />
      <path
        fill={COVID_COLORS_MASK.st22}
        d="M274.1,221.8c0-0.3,0-0.5,0.1-0.8c0.4-0.8,0.5-1.9,1.5-2.3c0.4-0.2,0.7,0.2,0.6,0.6
	C276.1,220.4,275.2,221.4,274.1,221.8z"
      />
      <path
        fill={COVID_COLORS_MASK.st23}
        d="M92.9,188.4c-0.3-1.1-0.1-2.2,0.6-3.2c1.1,1.7,1,3.5,0.9,5.3C93.4,190.1,94.7,188.1,92.9,188.4z"
      />
      <path
        fill={COVID_COLORS_MASK.st24}
        d="M244,249.5c-2.3,4.2-5.2,8-8.5,11.5c0-0.5,0.3-1.6-0.7-0.8c-1.4,1-2.7,2.2-3.8,3.6c-2.1,2.5-4.3,5-6.7,7.2
	c-1.3,1.3-2.8,2.3-3,4.8c-0.2,1.6-2.8,3.1-4.4,4.5c-3.8,3-8.2,4.9-12.2,7.5c-4.5,1.9-9.3,2.7-14.2,2.4c-3.5-0.2-6.6-1.5-9.9-2.4
	c-2.2-1.9-4.8-3-7.3-4.4c-1.2-0.6-3.1-1.3-2.9-2.7c0.3-1.7,2.3-0.1,3.8-1.1c-3.6-1.2-5.3-4.3-8.3-6c-2.4-1.4-4.5-3.5-6.7-5.2
	c-0.4-0.3-0.7-0.6-0.3-1.1s0.8-0.3,1.2-0.1c1.8,0.5,3.6,1.2,5.4,2c2.9,1.5,6.1,2.1,9,3.5c0.9,1.2,2.4,1.3,3.7,1.6
	c6.6,1.7,13.4,2.3,20.1,1.7c4.8-0.5,9.6-1.7,14-3.7c4.2-1.9,8.3-4.1,12.2-6.6c1-0.6,1.8-1.4,1.7-2.7c0.1-0.4,0.3-0.8,0.6-1.1
	c4.5-3.2,8.4-7.2,13.4-9.6C241.5,251.4,242.2,249.6,244,249.5z"
      />
      <path
        fill={COVID_COLORS_MASK.st5}
        d="M143.5,249.6c0.4-0.5,1-0.7,1.6-0.4c7.7,2.2,15.5,3.9,23.3,5c0.4,0.1,0.8,0.3,1.2,0.4c3.9,1.7,8.1,1.6,12.2,2.1
	c8.8,0.9,17.6,0.6,26.3-1.1c3.9-0.8,7.8-1.8,11.7-3c0.5-0.1,0.9-0.5,1-1.1c7.3-2.3,14.4-5.2,21.3-8.6c-0.9,2.1-2.9,2.8-4.6,3.9
	c-3.1,2.5-6.7,4.3-9.8,6.9c-3.2,2.8-7.5,4.4-11.5,6c-5.3,2-10.6,4.4-16.5,4.6c-0.8,0-1.8,0-2,0.9c-0.3,1.1,0.9,1.5,1.6,1.7
	c2.7,0.8,5.2,2.2,8.3,1.3c2-0.4,4-0.5,6-0.2c1.1,0.1,2.1,0.2,2.2,1.7c-1.2,2.2-3.8,2.5-5.7,3.1c-9,3.3-18.8,4-28.1,1.9
	C182.1,274.8,153.2,269.5,143.5,249.6z"
      />
      <path
        fill={COVID_COLORS_MASK.st25}
        d="M152.4,254.2c3.9,0.5,7.2,2.9,10.8,4.1c1.3,0.5,2.6,1.1,3.8,1.6c3.7,1.7,6.9,4.1,10.8,5.6
	c-0.5,1-1.3,0.6-1.9,0.7c-3.6,0.1-7.3-0.2-10.9,0.5c-2.1,0.4-3.6-1-5.3-1.6c-6.1-2-10.8-6.1-16.1-9.3c2.5-0.5,4.8,1.3,7.4,0.6
	C152.2,256,153.1,255.7,152.4,254.2z"
      />
      <path
        fill={COVID_COLORS_MASK.st5}
        d="M202.4,273.7c4.6-0.7,9.1-2.2,13.2-4.3c-0.2-1.3,0.8-1.8,1.7-2.2c2.4-1.1,4.8-2.4,7-3.9c0.9-0.6,1.8-1,2.8-0.2
	c0.5,1.2-0.4,1.8-1.2,2.3c-7.8,5.6-16.1,10.4-25.8,11.6c-8.6,1.1-17.3,0-25.3-3.2c-0.5-0.2-1.7-0.3-0.5-1.3
	C176.3,273,195,275.1,202.4,273.7z"
      />
      <path
        fill={COVID_COLORS_MASK.st26}
        d="M180.6,287.6c0.7-0.4,1.5-0.5,2.2-0.2c6.6,1.8,13.4,1.8,20,0c0.6-0.2,1.2-0.1,1.8,0.2
	c-3.3,2.3-6.6,4.9-10.9,5.2C188.5,293.3,184.6,290.4,180.6,287.6z"
      />
      <path
        fill={COVID_COLORS_MASK.st24}
        d="M225,278.7c0,0.9,0,1.8,0,2.6c-1.1-2.1-1-4.4-1.2-6.6c0-1.2,0.6-2.2,1.5-2.9c1.4-1.4,2.9-2.7,4.4-4.1
	c-0.5,1.3-1.4,2.4-2.6,3.2C224.4,273,223.9,275.6,225,278.7z"
      />
      <path
        fill={COVID_COLORS_MASK.st0}
        d="M204.2,119.9l1-1c0.5-0.7,0.9-1.5,1.1-2.3c0.4-1.6,1.1-3.1,3.2-3.3c2.1-2.6,3.5-5.7,4.1-9
	c0.4-1.4,0.9-2.8,1.4-4.4c5.2,4,9.1,9,13.8,13.2c2.2-0.2,3.1,1.6,4.2,2.9c3.9,4.5,7.8,8.9,10.9,14l0.5,0.5c1.5,2.2,2.8,4.5,4.3,6.7
	c0.4,1.2,0.9,2.4,1.6,3.5c1.3,2.1,1.9,4.5,1.9,7c-0.1,0.7-0.1,1.4,0.1,2.1c2.3,6.6,2.8,13.4,3.1,20.2c0,0.6-0.6,1.8,1,1.5
	c0.6,0.3,0.8,1,0.5,1.6c-2.1,4.2-0.8,8.8-1.5,13.2c-0.1,0.8,0.1,1.7-0.6,2.4c-0.8,0.7-2,0.7-2.7,0c0,0,0,0-0.1-0.1
	c-1.3-1-3-1.4-4.7-1.1c-8,1-16,2.4-24,3.5c-11.9,1.5-23.8,2.7-35.8,3.4c-14.8,0.9-29.5,0.3-44.3,0.4c-3.5,0-7.1-0.5-10.7-0.5
	c-2.4,0-4.7,0-6.2,2.4c-0.4,0.4-0.9,0.6-1.4,0.6c-1.4-0.1-1.7-1.2-2-2.3c-0.6-2.5-0.7-5.1-1.1-7.6c-0.2-2.4-1-4.8-2.3-6.8
	c-0.6-0.8-0.8-1.4-0.2-2.2c1.5,0.7,1.3-0.4,1.2-1.3c-0.4-2.6-0.3-5.2,0.1-7.8c1.2-6.6,5.2-11.4,10.3-15.4c4.5-3.6,9.7-6.1,14.6-9
	c9.6-4.2,19-8.6,28.4-13.2c1.3-0.8,2.7-1.3,4.2-1.5c-5.5,5.4-11.5,10.3-17.9,14.5c6.5,1.3,23.9-6.4,32.4-14.3
	c1.3-1.5,3.2-2.4,4.6-3.8l1.1-1C200.5,124,202.4,122,204.2,119.9z"
      />
      <path
        fill={COVID_COLORS_MASK.st27}
        d="M125.3,201.9c-0.7,4.9-0.4,9.8-0.6,14.7c-2.6-11.1-8-21.1-12.6-31.3c-1-2-1.6-4.3-1.5-6.6
	c0.1-1,0.3-2.1,1.4-2.2s1.2,1.1,1.5,1.8c1.8,5.2,4,10.2,6.7,15c0.4,0.8,0.5,0.8,1.2,0.3c1.6-0.6,1.7,0.8,1.8,1.6
	c0.1,1.4,0.5,2.7,1.2,3.9C124.8,200,125.8,200.7,125.3,201.9z"
      />
      <path
        fill={COVID_COLORS_MASK.st28}
        d="M123.6,199.9c-1-1-1.5-2.3-1.5-3.6c-0.1-0.9,0.1-1.9-0.7-2.6c-0.3-2.5-0.5-5-0.9-7.4c-0.3-2.5-1.4-4.9-3.2-6.7
	c-0.6-0.6-1.8-1.3-0.9-2.3s1.8,0,2.6,0.7c0.2,0.2,0.3,0.4,0.5,0.6c3.9,4.4,3.9,9.9,4.5,15.3c0.2,1,0.4,2,0.7,3
	c0.4,0.4,0.9,0.6,1.2,1c0.7,1,1.2,2.1,0.2,3C124.9,201.8,124.3,200.6,123.6,199.9z"
      />
      <path
        fill={COVID_COLORS_MASK.st5}
        d="M123.6,199.9c0.6,0.2,1,0.9,1.6,0.5s0.2-1.1,0-1.6s-0.4-1.3-0.6-2c0.2-0.1,0.4-0.2,0.4-0.3
	c0.5-3.8,3.3-3.5,6-3.4c16.3,0.7,32.6,0.7,49,0.4c9.6-0.2,19.2-1.1,28.8-2.1c13.5-1.4,26.8-3.6,40.2-5.5c1.7-0.2,3.4-0.4,3.9,1.9
	c0.2,0.5,0.7,0.8,1.2,0.6c0.7,0.3,1.1,1,0.8,1.6c-0.6,1.3,0.2,2.3,0.7,3.3c0.3,5.1,0.8,10.1,0.3,15.2c-0.1,0.8,0.2,1.6-0.2,2.3
	c-0.2,1.3,0.6,2.9-1.5,3.7c-0.9,0.3-0.3,1,0.5,1.4c1.4,0.6,2.1,2.3,1.5,3.7c-0.3,0.6-0.7,1.1-1.4,1.4c-1,0.5-0.9,1.1-0.8,1.9
	c0.4,3.8-2.8,4.8-6,5c-5,0.4-10-1.5-14.9,0.6c-1.2,0.5-1.7,2-3.4,1.9c-1.8-0.1-3.5-0.3-5.3-0.6c-5.6-0.7-11.3-1.9-16.8,0.8
	c-2.6,1.2-5.6,1.5-8.4,0.9c-3.8-0.8-7.8-0.6-11.5,0.6c-0.8,0.2-1.6,0.2-2.3,0c-4.5-1.3-8.8-0.9-13.4-0.1c-3.6,0.7-7.5,0.3-11.2,0.2
	s-7.3,0-10.7,1.6c-1.7,0.8-3.3,0.1-4.9-0.1c-3.8-0.5-7.6,0.1-11.3,0.1c-3.6,0-5.1-0.2-5.2-3.8c0-0.4-0.2-0.7-0.5-0.9
	c-3-0.9-2.3-3.4-2.4-5.6c-0.2-6.1-0.3-12.1,0-18.2c0.2-1.1,0-2.3-0.5-3.3C124.6,201.4,124,200.7,123.6,199.9z"
      />
      <path
        fill={COVID_COLORS_MASK.st25}
        d="M220.9,233.3c7.8-0.4,15.5-1.9,23.2-3.3c0.7-0.1,1.5-0.5,2.2,0.1c-0.4,1.2-1.4,1.6-2.3,2
	c-10.1,4.2-20.2,8.5-30.3,12.6c-5.1,2.1-10,4.4-14.9,7c-0.5,0.3-1.1,1-1.6,0.3s0.2-1.3,0.8-1.7c2-1.5,4.1-2.9,6.1-4.4
	c0.5-0.3,1.2-0.6,0.9-1.4s-1.1-0.4-1.6-0.2c-6.3,1.5-12.3,3.7-18.1,6.6c-1.6,1-3.6,1.2-5.4,0.5c-11.3-3.9-22.8-7.5-34.3-10.8
	c-3.3-0.9-6.6-2.2-9.9-3.3c-0.8-0.1-1.4-0.6-1.7-1.3c1.6-0.6,3.4-0.8,5.1-0.4c4,0.4,8,0.5,12,0.5c0.8,0,1.6,0.1,2.3,0.5
	c-1,1-2.3,0.3-3.6,0.6c7.9,0,15.6,1,23.3,1c13.4,0,26.8-0.9,40.1-2.7c2.5-0.4,5.2,0.1,7.5-1.4L220.9,233.3L220.9,233.3z"
      />
      <path
        fill={COVID_COLORS_MASK.st25}
        d="M227.1,263.1c-3.6,2.4-7.5,4.5-11.5,6.3c-3.3,0.2-6.6,0-10,0.9c-2,0.5-4.7-1.1-7-2c-1.2-0.6-2.3-1.3-3.2-2.2
	c-0.5-0.4-1.3-0.9-1-1.7s1.1-0.7,1.8-0.7c5.4-0.4,10.6-1.6,15.7-3.5c6.2-2.3,12.1-5.2,17.6-8.8c2.6-1.6,5.4-3,8.2-4.5
	c-2.2,2.4-5.5,3.6-7.6,6.2c3.5,0.4,6.9-0.4,10.2-1C236.3,256.3,231.6,259.5,227.1,263.1z"
      />
      <path
        fill={COVID_COLORS_MASK.st27}
        d="M255.9,210.8c0.1-4.7-0.3-9.3-1.3-13.9c-0.3-1.3-0.3-2.6,0.2-3.8c-0.1-2.5,1-4.8,1.1-7.2c0-0.4,0.6-0.6,1-0.7
	c1.3-0.4,1.5-1.6,1.8-2.7c1.6-4.3,2.9-8.7,3.9-13.3c0.1-0.8,0-2,1.2-2c1,0,1.4,1,1.7,1.8c0.4,1.4,0.4,2.9,0,4.4
	c-0.8,4.6-2.9,8.7-3.9,13.3c-3.2,7.6-5,15.7-5.2,24L255.9,210.8z"
      />
      <path
        fill={COVID_COLORS_MASK.st25}
        d="M220.9,251.6c0.4,0.1,1.1,0.1,1.2,0.3c0.3,0.9-0.6,0.9-1,1.1c-4.1,1.3-8.3,2.8-12.5,3.7
	c-6.2,1.4-12.5,2-18.9,1.7c-6.7-0.4-13.3-1.2-19.9-2.6c-0.4-0.1-1.2,0.1-0.8-0.8c0.1-0.2,0.5-0.2,0.7-0.2c5.8,0.5,11.6,1.3,17.3,1.5
	c7.4,0.2,14.7-0.4,22-1.8C213,253.7,217,252.8,220.9,251.6z"
      />
      <path
        fill={COVID_COLORS_MASK.st29}
        d="M197.3,126.7c-1.2,1.6-2.8,2.9-4.6,3.8c0.1-0.4,0.3-0.8,0.5-1.2L197.3,126.7z"
      />
      <path
        fill={COVID_COLORS_MASK.st29}
        d="M209.4,113.3c-1,2.2-2.5,4.1-4.3,5.6c0.1-0.9,0.6-1.9-0.3-2.7s-1.6,0.1-2.4,0.4c-2.8,1.1-6,0.4-8.8,2
	c-0.9,0.5-1.5-0.5-1.1-1.4c1.5-3.4,0-5-2.9-6.9c-3.3-2.1-7-3.1-10.4-5c-2.9-1.6-6.2-2.7-8.9-4.6c-2.4-1.7-5.2-2.4-7.7-3.7
	c-6.6-3.3-13-7.1-19.9-9.9c-2.5-1-4.8-2.6-7.4-3.7c-1.6-0.6-2.6-2.1-2.6-3.8c-1.3,0.1-2.1,1.2-3.3,1.1c3.3-4,5.4-4.3,10.1-2.2
	c3.4,1.5,6.8,3.1,10,4.8c3.5,1.9,7,3.6,10.5,5.4c3.3,1.6,6.5,3.5,9.9,4.9c3.4,1.4,6.7,3.1,9.9,5c1.4,0.8,3,1,4.2,1.8
	c4,2.7,8.9,3.8,12.6,6.9c0.7,0.6,1.1,1.6,1.1,2.5c0.1,2.2,1.3,2.9,3.3,2.4c1.4-0.2,2.7-0.5,4-0.9
	C207.3,110.7,208.6,111.3,209.4,113.3z"
      />
      <path
        fill={COVID_COLORS_MASK.st30}
        d="M171.8,58.3c2.4-0.2,4.7,0.1,6.6,1.9c0.8,0.7,1.8,1.1,2.9,1.2c1.7,0,3.2,0.8,4.4,2c0.5,0.5,1.1,0.8,1.8,1
	c4.6,1,8,4,12.1,6c3.4,1.7,6.5,4.2,10.4,4.8c1.9,0.2,3.6,1.1,4.7,2.6c0.5,0.7,1.3,0.8,2.1,1c2,0.6,4.1,0.8,5.4,2.7
	c0.3,0.3,0.7,0.6,1.1,0.7c4.5,1.1,7.9,4.4,12.1,5.9s7.3,5.4,12.1,5.6c0.3,0,0.8,0.2,0.9,0.4c1.6,3,4.2,2.3,6.8,2.1
	c1.8,0,3.5,0.9,4.4,2.4c1.5,2.3,4,4,4.8,6.7c-2.3-1.8-5.7-1.8-7.6-4.5c-0.7-1-1.8-0.4-2.4,0.4s-1.1,0.9-2,0.1
	c-2.4-2.1-5.1-3.7-8.1-4.7c-2.9-0.9-5.3-2.8-8-4.1c-5.5-2.6-11.1-5-16.3-8c-4.8-2.7-9.8-5.2-14.7-7.5c-6.3-3-12.3-6.7-18.8-9.3
	c-2.8-1.1-5.4-3-8.2-4.3c-3.6-1.6-7.1-3.7-11.1-4.1C168.6,58.7,170.1,58.3,171.8,58.3z"
      />
      <path
        fill={COVID_COLORS_MASK.st29}
        d="M204.2,119.9c-1.4,2.4-3.4,4.4-5.8,5.8l3.1-3.7L204.2,119.9z"
      />
      <path
        fill={COVID_COLORS_MASK.st18}
        d="M243.2,131c-4.5-6.2-9.5-12.1-14.5-17.9c-0.6-1.8-2.3-2.8-3-4.5c-0.3-0.6-0.7-1.3,0-1.9
	c0.5-0.4,1.3-0.2,1.6,0.3c2.6,4,7.8,3.6,10.9,6.6c0.3,0.2,0.6,0.4,0.9,0.4c2.4,0.6,4.7,1.6,6.8,3c3,1.7,6.5,2.5,9.3,4.6
	c3.6,2.7,8.4,3.1,11.8,6.2c0.1,0.1,0.3,0.2,0.4,0.3c3.9,0.7,7,2.8,9.7,5.5c0.5,4.7,2.9,8.9,3.2,13.6c-0.3,0.6-1.1,1-1.5,0.6
	c-2-1.5-4.7-1.2-6.5-3c-0.3-0.4-0.8-0.7-1.3-0.9c-3.9-0.8-7.2-3.1-10.5-4.8s-6.9-2.8-10-5C248.5,132.5,246.4,130.3,243.2,131z"
      />
      <path
        fill={COVID_COLORS_MASK.st31}
        d="M243.2,131c1.3-2.3,3.4-1.3,4.6-0.5c3,1.9,6.4,3.3,9.3,5.1c3.1,2,7,2.3,9.6,5.1c0.7,0.8,1.9,0.7,2.9,1.1
	s2.3,0.6,3,1.4c1.5,1.7,3,2.2,5.3,0c-0.6,2.8,0.8,3.5,2.5,3.9c1.9,5.1,1.7,10.5,3.1,15.7c-2.3-2.2-5.3-3.6-8.4-4
	c-2-0.2-3-2.8-5.1-3.6c-2.4-0.9-4.3-3.2-6.6-4.4c-3.2-1.5-6.5-2.8-9.8-3.9c-0.7-0.3-1.1,0.3-1.4,0.9c-2.1-3-3.2-6.5-4.7-9.8
	C246.3,135.4,244.9,133.1,243.2,131L243.2,131z"
      />
      <path
        fill={COVID_COLORS_MASK.st28}
        d="M256.9,185.2c0,2.8-0.1,5.6-2.1,7.8c-2-1.4-0.4-3.1-0.5-4.7c0-2.7,0.1-5.4,0-8.1c-0.2-3.2,0.9-6,2-8.8
	c0.4-1.1,1.1-2.2,2.1-2.9c0.4-0.5,1.1-0.6,1.6-0.2c0.5,0.3,0.6,0.9,0.3,1.4c-0.1,0.1-0.1,0.2-0.2,0.2
	C256,174.4,257,179.9,256.9,185.2z"
      />
      <path
        fill={COVID_COLORS_MASK.st5}
        d="M247.6,155.2c-0.6,0.5-1.3,0.2-2,0.1c-13.7-2.1-26.6,0.4-38.9,6.7c-0.6,0.3-1.2,0.6-1.9,0.9
	c-0.7,0.3-1.5,0.1-1.8-0.6c-0.4-0.6-0.3-1.4,0.2-1.9c0.3-0.3,0.6-0.6,0.9-0.9c10.6-9.7,30.3-12,42.9-5
	C247.3,154.6,247.7,154.7,247.6,155.2z"
      />
      <path
        fill={COVID_COLORS_MASK.st5}
        d="M127.1,162.6c2-2.5,4.6-3.6,7.4-4.6c12.2-4.4,24.1-3.6,35.4,3.1c0.5,0.3,1,0.6,1.5,1c0.6,0.4,0.9,1.1,0.7,1.8
	c-0.3,0.8-1.2,1.2-2,0.9c0,0,0,0,0,0c-1.5-0.4-3-1-4.4-1.5c-11.8-3.9-24.5-4.3-36.5-1.1C128.4,162.4,127.7,162.4,127.1,162.6z"
      />
      <path
        fill={COVID_COLORS_MASK.st32}
        d="M139.3,182c-3.8,2.6-7-0.6-10.6-1.1c5.8-2.5,10.9-6.3,16.8-8.4c7.6-2.7,14.2-1.2,19.9,4.4c1,1,2.1,2.1,2.2,3.7
	c-1,1.2-2,0.4-3-0.1c-1.2-0.6-2.2-1.7-3.6-2c-1.9-0.2-3.6-0.9-5.4-1.2c-3.2-0.5-6.1,0.6-9,1.9c-1.8,0.2-2.9,1.6-4.4,2.4
	C141.3,181.9,140.4,182.4,139.3,182z"
      />
      <path
        fill={COVID_COLORS_MASK.st32}
        d="M209.2,177c3.3-6,8.1-9.8,15.2-10.1c8.4-0.3,15.3,4.4,23,7c-3,1.1-5.4,3.2-8.7,3c-2.1,0.9-3.3-1-5-1.4
	c-0.9-0.4-1.7-0.8-2.5-1.4c-0.6-0.1-1.3-0.2-1.9-0.2c-1.6,0.2-3-0.7-4.6-0.9c-3.2-0.4-5.8,1.1-8.6,2.2l-0.8,0.2
	c-1.3,0.3-2.2,1.3-3.3,2S210,178,209.2,177z"
      />
      <path
        fill={COVID_COLORS_MASK.st1}
        d="M195.9,179.7c-0.1-2.4,0.9-4.7,2.8-6.3c1.5-1.3,3.1-2.5,4.7-3.6c0.6-0.4,1.4-0.9,2.2-0.4s0.4,1.3,0.2,1.9
	c-1,4-2.7,7.9-5.2,11.2c-0.5,1-1.6,1.5-2.7,1.2c-1-0.3-1.4-1.2-1.6-2.1C196.1,180.9,196,180.1,195.9,179.7z"
      />
      <path
        fill={COVID_COLORS_MASK.st1}
        d="M180.7,181.4c-0.3,1.2,0.5,3.3-1.4,3.8c-1.6,0.4-2.8-1.2-3.7-2.4c-2.1-3-4.4-5.9-5.3-9.5c-0.2-0.6-0.6-1.4,0-2
	s1.3-0.1,1.9,0.2c2.4,1,4.6,2.5,6.5,4.2C180.3,177.2,181,179.3,180.7,181.4z"
      />
      <path
        fill={COVID_COLORS_MASK.st33}
        d="M209.2,177c1.6,0.5,2.1-1.1,3.2-1.5c0.9-0.4,1.6-1.2,2.7-1.1c0.9,0.4,0.6,1.3,0.9,2c1,2.1,0.7,5,3.4,6.2
	c0.5,0.3,0.7,1,0.4,1.5c0,0,0,0.1,0,0.1c0.5,0.9,0,1-0.7,1.1c-3.9,0.4-7.6-0.9-11.1-2.3c-1.5-0.6-1.8-1.8-0.6-3.1
	C208.2,179,208.8,178.1,209.2,177z"
      />
      <path
        fill={COVID_COLORS_MASK.st33}
        d="M161.7,177.6c2.2,0.6,3.7,2.5,5.9,3c3.2,2.9,3.1,4.2-0.7,6c-2.9,1.4-6.1,2.2-9.3,2.4c-0.5,0-1.1,0.3-1.1-0.6
	c0-0.9,0.5-1.7,1.2-2.2c2.4-1.8,2.9-4.3,2.7-7.1c-0.1-0.7-0.1-1.4,0.7-1.8c0.2-0.1,0.4,0,0.5,0.1
	C161.7,177.5,161.7,177.5,161.7,177.6z"
      />
      <path
        fill={COVID_COLORS_MASK.st34}
        d="M161.5,177.5c-0.4,1.1,0.3,2.2,0.2,3.4c-0.1,3.3-2.1,6.3-5.1,7.5c-2.1,0.4-4.2,0.3-6.3-0.1
	c-3.9-0.3-5.4-2.1-5-6c1.3-0.8,2.2-2.4,4-2.6c0.8,0.5,1,1.7,2.1,1.9c1.6,2,3.6,1.1,5.6,0.7c2.4-0.1,2.6-0.4,2.5-3.4
	C159.6,177.7,159.9,176.6,161.5,177.5z"
      />
      <path
        fill={COVID_COLORS_MASK.st35}
        d="M146.1,182.1c0,2.7,1.7,5.2,4.2,6.3c-2.6,0.8-9.7-1.6-11.3-3.8c-0.8-1.1,0-1.8,0.3-2.6c1.8-1.8,4.2-2.8,6.3-4
	C147.6,179.1,147.8,180,146.1,182.1z"
      />
      <path
        fill={COVID_COLORS_MASK.st35}
        d="M231.3,173.2c2.6,0.8,5.1,2,7.3,3.7c-0.1,2.1-1.8,3-3.3,4.1c-2.2,1.4-4.5,2.5-7,3.2c0-1.5,1.4-2,1.4-3.1
	c-0.8-1.9-1-4-0.5-6C229.4,174,229.6,172.6,231.3,173.2z"
      />
      <path
        fill={COVID_COLORS_MASK.st36}
        d="M231.3,173.2c-1,0.2-0.9,0.9-0.7,1.6c0.4,2.1,0.1,4.3-0.9,6.3c-2.7,3-5.9,4.3-9.9,3.1c-4-2.1-5.3-5.5-4.7-9.8
	v-0.2c0.9,0,1.6,0.7,1.6,1.6c-0.3,3.1,0.6,3.9,3.7,3.3c0.5-0.1,1-0.2,1.5-0.1c1,0.1,1.9,0,2.8-0.4c3.8,0.2,4,0.1,3.9-3.6
	c0-0.9,0.4-1.7,1.1-2.2C230.3,172.5,230.9,172.7,231.3,173.2z"
      />
      <path
        fill={COVID_COLORS_MASK.st18}
        d="M243.2,130.9c2.1,1.8,3.6,4.3,4.2,7C245.9,135.7,244.5,133.3,243.2,130.9z"
      />
      <path
        fill={COVID_COLORS_MASK.st37}
        d="M128.2,225.8c0,0.5-0.2,0.9-0.7,0.9c-0.4,0.1-0.8-0.2-0.8-0.6c0-0.1,0-0.1,0-0.2c-0.1-0.5,0.3-0.9,0.7-0.9
	C128,224.9,128.1,225.3,128.2,225.8z"
      />
      <path
        fill={COVID_COLORS_MASK.st7}
        d="M146.1,182.1c-0.6-1.3,0.5-2.8-0.5-4.1c5.1-2.1,10.9-2.3,16.1-0.4h-0.1l-0.1-0.1c-1.4,0.2-0.8,1.2-0.7,2
	c-0.4,1.7-1.4,2.4-3.2,1.8c-0.3,0-0.7-0.2-0.9-0.4c-2.9-2.6-2.9-2.6-5.9-0.3c-0.5,0.1-1-0.1-1.4-0.5
	C148.5,181.1,148.7,183.7,146.1,182.1z"
      />
      <path
        fill={COVID_COLORS_MASK.st38}
        d="M215.7,176.3c0-0.8-0.2-1.6-0.5-2.2c4.7-2.4,9.6-2.3,14.6-1.5c0,0.2,0,0.3,0,0.5c0.7,5.6,0.7,5.6-4.7,6.8
	c-0.5-0.7-0.5-1.8,0.2-2.4c0.6-0.3,0.7-1.1,0.4-1.6c-0.1-0.2-0.2-0.3-0.4-0.4c-2.7-1.6-3.7-1.2-4.8,1.6c-0.1,0.7-0.6,1.3-1.1,1.6
	C217.3,179.3,216.4,177.9,215.7,176.3z"
      />
      <path
        fill={COVID_COLORS_MASK.st39}
        d="M150.9,180c-0.4-1.4,0.5-2.1,1.6-2.4c1.2-0.5,2.6-0.3,3.6,0.7c0.6,0.7,1.1,1.5,1.6,2.3
	c0.9,1.1,0.5,1.9-0.6,2.6c-3.2,2.3-5.2,2-6.1-0.9C150.5,181.5,150.5,180.7,150.9,180z"
      />
      <path
        fill={COVID_COLORS_MASK.st40}
        d="M157.1,183.1c0.2-0.9,0.4-1.7,0.6-2.6c1.1,0.1,2.3-0.3,3.1-1.1C160.8,183.6,160.3,184.1,157.1,183.1z"
      />
      <path
        fill={COVID_COLORS_MASK.st41}
        d="M150.9,180c0.6,0.5,0.7,1.4,0.2,2c0,0-0.1,0.1-0.1,0.1c-1.3-0.1-1.7-0.9-1.6-2.1L150.9,180z"
      />
      <path
        fill={COVID_COLORS_MASK.st42}
        d="M226,178l-1,2l-3.1,0.5c-0.9-0.6-2.4-0.5-2.6-2.1c-0.1-3.9,1.3-5.7,4.2-5.2c1.2,0.1,2.2,0.9,2.4,2
	C226.3,176.2,226.3,177.1,226,178z"
      />
      <path
        fill={COVID_COLORS_MASK.st10}
        d="M219.4,178.4l2.6,2.1c-0.8-0.1-1.5-0.2-2.3-0.2c-3.5,0-3.8-0.2-4-3.9L219.4,178.4z"
      />
      <path
        fill={COVID_COLORS_MASK.st43}
        d="M226,178c-1.2-0.9-1.1-1.8,0-2.7C228.1,176.2,227.9,177.1,226,178z"
      />
      <circle fill={COVID_COLORS_MASK.st44} cx="273.4" cy="64.2" r="41" />
      <path
        {...styles}
        d="M229.8,70.2c0.2-10,2.9-18.1,8.1-25.3c5.3-7.4,12.8-13,21.4-15.9c5.5-2,11.4-2.7,17.3-2.2
	c14,1.2,25.2,7.5,33.2,19.1c4.2,6.1,6.7,13.2,7.2,20.5c1,11.3-2.7,22.6-10.1,31.2c-6.6,8-15.9,13.2-26.1,14.7
	c-15.1,2.4-28.2-2-39-12.8c-6.3-6.4-10.3-14.6-11.5-23.5C230,73.8,229.9,71.5,229.8,70.2z M273.5,31.7c-20.9,0.1-37.8,17.1-37.7,38
	c0.1,20.9,17.1,37.8,38,37.7c20.9-0.1,37.7-17.1,37.7-37.9C311.5,48.8,294.4,31.8,273.5,31.7z"
      />
      <path
        fill={COVID_COLORS_MASK.st45}
        d="M255,56.9c0.1-2.3,0.8-4.6,1.9-6.6c2.4-4.3,6.5-7.4,11.4-8.5c5.1-1.3,10.4-1,15.3,0.9c4,1.4,7.2,4.4,9,8.2
	c2.4,4.6,1.7,10.1-1.8,13.9c-2.3,2.6-4.8,4.9-7.5,7.1c-0.7,0.6-1.4,1.3-2,1.9c-1.6,1.4-2.4,3.5-2.4,5.6c0,0.8,0,1.7,0,2.6h-9.4
	c-0.5-5.4,0.4-10.3,4.6-14.1c2.2-2,4.5-3.9,6.8-5.9c1.3-1,2.3-2.5,2.7-4.1c0.6-2.9-0.7-5.8-3.3-7.3c-4.8-3-11.2-1.5-14.2,3.3
	c-0.4,0.7-0.8,1.5-1,2.2c-0.2,0.6-0.3,1.2-0.5,1.8c-0.1,0.2-0.3,0.4-0.5,0.4c-3-0.4-6-0.7-9-1.1C255.1,57,255,57,255,56.9z"
      />
      <path fill={COVID_COLORS_MASK.st45} d="M279.7,95.9h-10.3V85.6h10.3V95.9z" />
    </g>
  ),
  viewBox: '0 0 375 375'
};
