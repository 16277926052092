import React from 'react';
import { useHistory } from 'react-router-dom';
import { mapPaymentsToData, iff } from './util';
import analyticsService, { AmplitudeEventData, AnalyticsEvent } from 'services/AnalyticsService';
import { TableData } from './types';
import CollapsibleListItem from 'components/common/CollapsibleList/CollapsibleListItem';
import AmountSummaryTable from 'components/billing/AmountSummaryTable';
import { EmptyMuiBox } from '../styled';
import DefaultText from 'components/UI/Typography/DefaultText';
import { TouchableOpacity } from 'react-native';
import { MuiBox } from 'theme/material-ui';
import Typography from 'components/UI/Typography';
import { KeyboardArrowRight } from '@material-ui/icons';
import { Color } from 'modules/styles/colors';
import { usePaymentsCollapsibleList } from 'lib/hooks/useBilling';
import useCoreEventData from 'hooks/useCoreEventData';

interface PaymentsComponentProps {
  largeView: boolean;
}

function PaymentsComponent(props: PaymentsComponentProps) {
  const history = useHistory();
  const { currentUrl, referringUrl } = useCoreEventData();
  const { paymentsSummary, isImpersonating } = usePaymentsCollapsibleList();

  const { largeView } = props;
  const payments = paymentsSummary?.payments || [];
  const paymentHistoryData = mapPaymentsToData(payments);
  const lastPaid = paymentHistoryData[0]?.right || 0;
  const lastPaidOn = paymentHistoryData[0]?.left || '';
  const data: AmplitudeEventData = {
    currentUrl,
    referringUrl
  };

  const goToPaymentDetail = (item: TableData) => {
    analyticsService.logEvent(AnalyticsEvent.IndividualPaymentClicked, data);
    history.push(`/u/billing/payment-detail/${item.id}`);
  };

  const goToPaymentHistory = () => {
    analyticsService.logEvent(AnalyticsEvent.ViewAllPaymentHistoryClicked, data);
    history.push('/u/billing/payment-history');
  };

  return (
    <CollapsibleListItem
      largeView={largeView}
      leftIcon="PaymentHistory"
      title="Payment History"
      key={`Payment ${isImpersonating}`}
      isExpandable={!isImpersonating}
      subtitle={
        isImpersonating
          ? 'Information unavailable'
          : iff(
              paymentHistoryData.length !== 0,
              `Last Paid ${lastPaid} on ${lastPaidOn}`,
              '0 Payments'
            )
      }
      analyticsEvent={AnalyticsEvent.PaymentHistoryClicked}
      currentUrlForAnalytics={currentUrl}
      referringUrlForAnalytics={referringUrl}
    >
      <AmountSummaryTable
        largeView={largeView}
        emptyCaption=""
        arrows
        data={paymentHistoryData}
        onItemPressed={goToPaymentDetail}
        hideHeaders={!largeView}
        headers={['Paid on', 'Patient', 'Amount']}
      />
      {paymentHistoryData.length === 0 ? (
        <EmptyMuiBox>
          <DefaultText>No Payment History</DefaultText>
        </EmptyMuiBox>
      ) : (
        <TouchableOpacity
          onPress={goToPaymentHistory}
          accessibilityLabel="Tap to view all payment history"
        >
          <MuiBox justifyContent="space-between" display="flex" padding="20px 20px 0px 20px">
            <MuiBox id="view-all-payment-history">
              <Typography color={Color.secondary}>View All</Typography>
            </MuiBox>
            <MuiBox
              justifyContent="space-between"
              flexDirection="row"
              data-testid="view-all-payment-history-link"
            >
              <KeyboardArrowRight />
            </MuiBox>
          </MuiBox>
        </TouchableOpacity>
      )}
    </CollapsibleListItem>
  );
}

export default PaymentsComponent;
