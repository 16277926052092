import * as React from 'react';
import { JsonObj } from '@mhp/general-interface';
import { CircularProgress, Box, withStyles } from '@material-ui/core';
import { toSentenceCase } from 'modules/utils/StringUtils';
import useStyles from '../useStyles';
import { MuiButton } from 'theme/material-ui';
import { getRegisterBrand } from 'store/register';

const StyledSpinner = withStyles({
  colorSecondary: {
    color: '#fff'
  }
})(CircularProgress);

const CustomButton = ({
  isSubmitting,
  label,
  ...props
}: {
  isSubmitting?: boolean;
  label: string | React.ReactNode;
} & JsonObj) => {
  const redirectBrand = getRegisterBrand();
  const classes = useStyles({ redirectBrand });
  const labelText = toSentenceCase(label);
  return (
    <Box>
      <MuiButton
        variant="contained"
        className={classes.customContainedButton}
        size="large"
        fullWidth
        {...props}
      >
        {isSubmitting ? <div className={classes.hidden}>{labelText}</div> : labelText}
      </MuiButton>
      {isSubmitting && (
        <Box
          display="flex"
          position="absolute"
          top={0}
          right={0}
          bottom={0}
          left={0}
          alignItems="center"
          justifyContent="center"
        >
          <StyledSpinner color="primary" size={20} thickness={6} role="progressbar" />
        </Box>
      )}
    </Box>
  );
};

export default CustomButton;
