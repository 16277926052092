import Typography from 'components/UI/Typography';
import { isLast } from 'modules/utils/ArrayUtils';
import { formatDate } from 'modules/utils/DateUtils';
import React from 'react';
import { connect } from 'react-redux';
import * as selectors from 'store/profile/selectors';
import { Grantee } from 'store/profile/types';
import { RootState } from 'store/types';
import styled from 'styled-components';
import {
  dfdDefaultTheme,
  MuiBox,
  MuiGrid,
  MuiList,
  MuiListItem,
  MuiPaper
} from 'theme/material-ui';

export interface GranteeListProps {
  grantees: Grantee[];
}

const StyledAlignedBox = styled.div<{ justifyContent: string }>`
  && {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    ${dfdDefaultTheme.breakpoints.up('sm')} {
      justify-content: ${props => props.justifyContent};
    }
  }
`;

export const GranteeList = ({ grantees }: GranteeListProps) => {
  if (!grantees || !grantees.length) {
    return (
      <MuiBox p={2}>
        <Typography textAlign="center">No users found</Typography>
      </MuiBox>
    );
  }

  return (
    <MuiList component={MuiPaper}>
      {grantees.map((patient, idx, arr) => {
        return (
          <MuiListItem
            divider={!isLast(arr, idx)}
            key={patient.consumerId}
            data-testid="grantee-linked-accounts-list-item"
          >
            <MuiGrid container justify="space-between">
              <MuiGrid item xs={12} sm={6}>
                <StyledAlignedBox justifyContent="flex-start">
                  <MuiBox>
                    <Typography>{patient.displayName}</Typography>
                    <Typography>{patient.relationship}</Typography>
                  </MuiBox>
                </StyledAlignedBox>
              </MuiGrid>

              <MuiGrid item xs={12} sm={6}>
                <StyledAlignedBox justifyContent="flex-end">
                  <MuiBox>
                    <Typography>Access Granted</Typography>
                    <Typography>{formatDate(patient.startDate)}</Typography>
                  </MuiBox>
                </StyledAlignedBox>
              </MuiGrid>
            </MuiGrid>
          </MuiListItem>
        );
      })}
    </MuiList>
  );
};

const mapState = (state: RootState) => ({
  grantees: selectors.profileGranteesSelector(state)
});

export default connect(mapState)(GranteeList);
