import { Color } from 'modules/styles/colors';
import React, { useEffect } from 'react';
import { TouchableOpacity } from 'react-native';
import { Icon } from 'components/Icon';
import { connect } from 'react-redux';
import { WebViewTitleFlexBox } from './styled';
import { MuiDialog, MuiBox } from 'theme/material-ui';
import Config from 'react-native-config';
import { Slide } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import { useTpsToken } from 'lib/hooks/useTpsToken';
import { TpsSource } from 'store/authentication/tpsHelpers';
import { SpinnerOverlay } from 'components/UI/Spinner/SpinnerModal';
import { useWindowSize } from 'hooks/useWindowSize';
import { IconSize } from 'modules/styles/variables';
import { VisitPayImhSource } from 'modules/constants/Billing';
import useOnMessageEvent from 'hooks/useOnMessageEvent';
import useCoreEventData from 'hooks/useCoreEventData';

const DEFAULT_URI = Config.VISIT_PAY_DEFAULT_URL;

type Props = {
  uri: string;
  closePopup: Function;
  onMessageEvent?: Function;
};

const Transition = React.forwardRef<unknown, TransitionProps>((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));
export const useVisitPayWebviewUri = (uriBase: string) => {
  const { token, loading } = useTpsToken(TpsSource.VISIT_PAY);
  let uri = token?.accessToken ? `${uriBase}&accessToken=${token.accessToken}` : DEFAULT_URI;
  uri = `${uri}&imhsource=${VisitPayImhSource.mhweb}`;

  return { loading, uri };
};
const VisitPayLoading = () => <SpinnerOverlay isLoading />;

export const VisitPayWebView = (props: Props) => {
  const { height } = useWindowSize();
  const { uri: uriBase, closePopup } = props;
  const { loading, uri } = useVisitPayWebviewUri(uriBase);
  const { referringUrl } = useCoreEventData();
  const [open, setOpen] = React.useState(false);

  const [onMessageEvent] = useOnMessageEvent(referringUrl);

  const messageHandler = (e: any) => {
    if (e.data) {
      if (
        ['VisitPay:ArrangePaymentComplete', 'VisitPay:RegistrationComplete'].includes(e.data.Event)
      ) {
        onMessageEvent?.(e.data);
      }
    }

    if (e.origin !== uriBase.split('/sso')[0]) {
      return;
    }
    if (e.data) {
      if (
        ['VisitPay:SessionTimeout', 'VisitPay:UserIndicatedExitPaymentConfirmation'].includes(
          e.data.Event
        )
      ) {
        handleClose(e.data);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('message', messageHandler, false);
    return () => window.removeEventListener('message', messageHandler, false);
  }, []);
  useEffect(() => setOpen(true), []);

  const handleClose = () => {
    setOpen(false);
    closePopup(false);
  };

  return (
    <MuiDialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
      <WebViewTitleFlexBox alignItems="flex-end">
        <TouchableOpacity testID="close" accessibilityLabel="close button" onPress={handleClose}>
          <Icon name="close" size={IconSize.large} />
        </TouchableOpacity>
      </WebViewTitleFlexBox>
      {loading ? (
        <VisitPayLoading />
      ) : (
        <MuiBox p={1} bgcolor={Color.foreColor}>
          <iframe width="100%" height={height * 0.8} title="Visit Pay" frameBorder="0" src={uri} />
        </MuiBox>
      )}
    </MuiDialog>
  );
};

VisitPayWebView.defaultProps = {
  uri: DEFAULT_URI,
  closePopup: () => {}
};

export default connect()(VisitPayWebView);
