import CollapsibleListItem from 'components/common/CollapsibleList/CollapsibleListItem';
import { MuiBox, MuiPaper } from 'theme/material-ui';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { TRIAGE_COVID_TEST_TITLE } from 'screens/TriageGyant/constants';
import { GetCareButton, GetCareButtonTextBox, GetCareText } from '../styled';
import { history } from 'lib/history';
import { AnalyticsEvent } from 'services/AnalyticsService';
import useNavigationAnalytics from 'hooks/useNavigationAnalytics';

export const GetCareCovidTest = () => {
  const location = useLocation();
  const isExpanded = location.state?.expandTab === 'COVID_CARE';

  const covidTestGetStartedClicked = useNavigationAnalytics(
    AnalyticsEvent.CovidTestGetStartedClicked
  );
  const handleRequestCovidTestClick = () => {
    covidTestGetStartedClicked({ title: 'Get Care' });
    history.push('/u/get-care-now/covid-screen/patient-select');
  };
  return (
    <CollapsibleListItem
      leftIcon="CovidIcon"
      title="Request Covid-19 Test"
      subtitle="Screening for Covid-19 testing"
      expandOnFirstRender={isExpanded}
    >
      <MuiBox width="100%">
        <MuiPaper elevation={1}>
          <GetCareButtonTextBox
            flexDirection="column"
            justifyContent="center"
            display="flex"
            alignItems="center"
          >
            <GetCareText color="textPrimary">{TRIAGE_COVID_TEST_TITLE}</GetCareText>
            <GetCareButton
              data-testid="request-covid-19-test-get-started-button"
              variant="contained"
              color="primary"
              onClick={handleRequestCovidTestClick}
            >
              Get started
            </GetCareButton>
          </GetCareButtonTextBox>
        </MuiPaper>
      </MuiBox>
    </CollapsibleListItem>
  );
};

export default GetCareCovidTest;
