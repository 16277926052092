import React from 'react';
import MaskedInput from 'react-text-mask';
import { TextField, TextFieldProps, InputBaseComponentProps } from '@material-ui/core';
import { MuiTypography } from 'theme/material-ui';
import { FontSize } from 'modules/styles/variables';
import { Color } from 'modules/styles/colors';
import masks from 'utils/masks';

interface MaskedTextProps extends InputBaseComponentProps {
  mask: typeof masks;
}

function MaskedText({ inputRef, ...rest }: MaskedTextProps) {
  return (
    <MaskedInput
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      {...rest}
    />
  );
}

const MaxLengthError = ({ helperTextInfo }: { helperTextInfo: string }) => (
  <MuiTypography
    fontSize={FontSize.base * 0.75}
    component="p"
    style={{ margin: '0px 14px', color: Color.red }}
  >
    {helperTextInfo}
  </MuiTypography>
);

function RenderTextInfo({
  helperTextInfo,
  maxLength,
  value,
  showError
}: {
  helperTextInfo: string;
  maxLength?: number;
  value: string;
  showError?: boolean | null;
}) {
  if (maxLength) {
    if (showError === null || showError === true) {
      return value.length === maxLength - (showError !== null ? 1 : 0) ? (
        <MaxLengthError helperTextInfo={helperTextInfo} />
      ) : null;
    }
    return null;
  }
  if (helperTextInfo) {
    return (
      <MuiTypography fontSize={FontSize.base * 0.75} component="p" style={{ margin: '-15px 0px' }}>
        {helperTextInfo}
      </MuiTypography>
    );
  }
  return null;
}

export type TextInputProps = TextFieldProps & {
  // access by MaskKey [e.g. 'creditCard'] or by string dot.notation [e.g. 'creditCard.amex']
  mask?: keyof typeof masks | string;
  helperTextInfo?: string;
  showError?: boolean | null;
};

/**
 * Component: TextInput
 * @material-ui/core/TextField wrapper to be able to use masks.
 */
function TextInput({ mask, InputProps, ...rest }: TextInputProps) {
  return (
    <>
      <TextField
        InputProps={
          mask
            ? {
                ...InputProps,
                inputComponent: MaskedText as React.ElementType<InputBaseComponentProps>,
                inputProps: { ...InputProps?.inputProps, mask: masks(mask), ...rest.inputProps }
              }
            : InputProps
        }
        {...rest}
      />
      {rest.helperTextInfo ? (
        <RenderTextInfo
          {...rest}
          maxLength={InputProps?.inputProps?.maxLength}
          showError={InputProps?.inputProps?.showError}
        />
      ) : null}
    </>
  );
}

export default TextInput;
