import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Svg from 'components/UI/Svg/Svg';
import Box from 'components/UI/Layout/Box';
import { dfdDefaultTheme, MuiBox, MuiButton, MuiDialog, MuiTypography } from 'theme/material-ui';

import { Color } from 'modules/styles/colors';
import { IconSize, FontSize, FontWeight, Spacing, borderRadius } from 'modules/styles/variables';

import { CloseButton } from '../../components/styled';
import Spacer from 'components/UI/Layout/Spacer';
import FlexBox from 'components/UI/Layout/FlexBox';
import { wellChildCheckRules } from 'modules/constants/booking';

export interface WellChildCheckProps {
  fullScreen?: boolean;
  handleClose: Function;
  open: boolean;
}

export function BookingWellChildCheckDialog({ handleClose, open }: Props) {
  const fullScreen = useMediaQuery(dfdDefaultTheme.breakpoints.down('sm'));

  return (
    <MuiDialog fullScreen={fullScreen} maxWidth="md" open={open} onClose={handleClose}>
      <MuiBox p={3} py={2} pl={8} pr={8}>
        <CloseButton onClick={handleClose}>
          <Svg color={Color.black} name="CloseIcon" />
        </CloseButton>
        <MuiBox display="flex" justifyContent="center" py={1}>
          <Svg set="assets" name="InfoIcon" size={IconSize.xLarge} color={Color.primary} />
        </MuiBox>
        <MuiBox display="flex" justifyContent="center" py={2}>
          <Box vSpacing={Spacing.large}>
            <MuiTypography align="center" fontSize={FontSize.mediumHeading}>
              When do I Schedule this Visit Type?
            </MuiTypography>
            <Spacer spacing="large" />
            <MuiTypography fontSize={FontSize.large} color={Color.black}>
              At this exam, the physician or advanced practice provider will evaluate your child's
              growth, development and overall health in order to identify or prevent potential
              problems and promote your child’s well–being. Please schedule carefully according to
              the dates noted below. This allows us to administer vaccines at the right time and
              ensures your insurance coverage.
            </MuiTypography>
          </Box>
        </MuiBox>
        <FlexBox style={{ paddingBottom: Spacing.mediumLarge }} flexDirection="row">
          <MuiBox flex={1}>
            <MuiTypography fontWeight={FontWeight.bold}>Patient's Age</MuiTypography>
          </MuiBox>
          <MuiBox flex={1}>
            <MuiTypography fontWeight={FontWeight.bold}>When to Schedule</MuiTypography>
          </MuiBox>
        </FlexBox>
        {wellChildCheckRules.RULES.map(item => (
          <FlexBox
            key={item.description}
            justifyContent="center"
            padding={Spacing.mediumLarge}
            borderRadius={borderRadius}
            backgroundColor={item.bgColor}
            alignItems="center"
            flexDirection="row"
          >
            <MuiBox flex={1}>
              <MuiTypography fontWeight={item.fontWeight}>{item.age}</MuiTypography>
            </MuiBox>
            <MuiBox flex={1}>
              <MuiTypography fontWeight={item.fontWeight} style={{ whiteSpace: 'pre-line' }}>
                {item.description}
              </MuiTypography>
            </MuiBox>
          </FlexBox>
        ))}
        <Box vSpacing={Spacing.small} />
        <MuiBox display="flex" justifyContent="center" py={1}>
          <MuiButton
            onClick={handleClose}
            fullWidth
            data-testid="continue"
            variant="contained"
            color="primary"
          >
            Yes, Continue
          </MuiButton>
        </MuiBox>
        <Box vSpacing={Spacing.small} />
      </MuiBox>
    </MuiDialog>
  );
}

export default BookingWellChildCheckDialog;
