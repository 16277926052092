import React, { useEffect, useMemo, useState } from 'react';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { useMediaQuery } from '@material-ui/core';
import {
  MuiBox,
  MuiContainer,
  MuiGrid,
  useTheme,
  MuiDivider,
  MuiAlert,
  MuiAlertTitle
} from 'theme/material-ui';
import { ConnectCareStepActions, ConnectCareStepAction } from 'components/ConnectCare';

import * as actions from 'store/amwell/actions';

import { RouteData } from '../types';
import { FormScreen } from '../styled';
import ConnectCareMedicalHistoryConditions from './ConnectCareMedicalHistoryConditions';
import ConnectCareMedicalHistoryAllergies from './ConnectCareMedicalHistoryAllergies';

export interface Props extends RouteComponentProps {
  getAllergies: typeof actions.getAllergies;
  getConditions: typeof actions.getConditions;
  getSystemConfiguration: typeof actions.getSystemConfiguration;
}

export function ConnectCareMedicalHistory(props: Props) {
  const { history, getAllergies, getConditions, getSystemConfiguration } = props;

  const theme = useTheme();
  const lg = useMediaQuery(theme.breakpoints.up('lg'));
  const columns = useMemo(() => (lg ? 3 : 2), [lg]);

  const [nextDisabled, setNextDisabled] = useState(true);
  const [patientHasPreviousMedicalHistory, setPatientHasPreviousMedicalHistory] = useState(false);

  useEffect(() => {
    async function getMedicalHistory() {
      const res: AnyAction[] = await Promise.all([getAllergies(), getConditions()]);

      const allergies = res[0].error ? [] : res[0].payload;
      const conditions = res[1].error ? [] : res[1].payload;

      const hasPreviousAllergies = allergies.some(a => a.current);
      const hasPreviousConditions = conditions.some(c => c.current);

      setPatientHasPreviousMedicalHistory(hasPreviousAllergies || hasPreviousConditions);
      setNextDisabled(false);
    }

    getSystemConfiguration();
    getMedicalHistory();
  }, []);

  const onNextClick = (nextStep?: RouteData) => {
    if (nextStep) {
      history.push(nextStep.path);
    }
  };

  const onPrevClick = (prevStep?: RouteData) => {
    if (prevStep) {
      history.push(prevStep.path);
    }
  };

  return (
    <>
      <FormScreen>
        <MuiContainer maxWidth="lg">
          <MuiBox my={3}>
            {patientHasPreviousMedicalHistory && (
              <MuiBox mb={3}>
                <MuiAlert severity="warning">
                  <MuiAlertTitle>Prepopulated Medical History</MuiAlertTitle>
                  We have prepopulated your medical history using your Connect Care profile.
                  <br />
                  If any of the information prefilled does not seem to reflect your latest medical
                  history input, please let us know.
                </MuiAlert>
              </MuiBox>
            )}
            <MuiGrid container spacing={4} direction="column">
              <MuiGrid item xs={12}>
                <ConnectCareMedicalHistoryConditions columns={columns} />
              </MuiGrid>
              <MuiDivider variant="middle" />
              <MuiGrid item xs={12}>
                <ConnectCareMedicalHistoryAllergies columns={columns} />
              </MuiGrid>
            </MuiGrid>
          </MuiBox>
        </MuiContainer>
      </FormScreen>
      <ConnectCareStepActions>
        <ConnectCareStepAction onClick={onPrevClick} />
        <ConnectCareStepAction disabled={nextDisabled} onClick={onNextClick} />
      </ConnectCareStepActions>
    </>
  );
}

const mapDispatch = {
  getAllergies: actions.getAllergies,
  getConditions: actions.getConditions,
  getSystemConfiguration: actions.getSystemConfiguration
};

export default connect(null, mapDispatch)(ConnectCareMedicalHistory);
