import * as React from 'react';

export default () => {
  return {
    svg: (
      <g>
        <path
          fill="none"
          d="M10.7255 27.9431V15.9268H33.3067H52.4992V36.8143L43.6292 45.6856H35.7255"
          stroke="url(#paint0_linear_2404_1754)"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fill="none"
          d="M43.6288 45.6852V36.814H52.5"
          stroke="url(#paint1_linear_2404_1754)"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fill="none"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.6288 11.0884C23.6288 11.9797 22.9075 12.7009 22.0163 12.7009C21.1238 12.7009 20.4025 11.9797 20.4025 11.0884V7.86219C20.4025 6.97094 21.1238 6.24969 22.0163 6.24969C22.9075 6.24969 23.6288 6.97094 23.6288 7.86219V11.0884Z"
          stroke="url(#paint2_linear_2404_1754)"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fill="none"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M42.8223 11.0884C42.8223 11.9797 42.1011 12.7009 41.2098 12.7009C40.3173 12.7009 39.5961 11.9797 39.5961 11.0884V7.86219C39.5961 6.97094 40.3173 6.24969 41.2098 6.24969C42.1011 6.24969 42.8223 6.97094 42.8223 7.86219V11.0884Z"
          stroke="url(#paint3_linear_2404_1754)"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fill="none"
          d="M46.0481 9.4754H49.2743C51.0556 9.4754 52.4993 10.1129 52.4993 11.8942V16.7329"
          stroke="url(#paint4_linear_2404_1754)"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fill="none"
          d="M26.8546 9.4754H36.3709"
          stroke="url(#paint5_linear_2404_1754)"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fill="none"
          d="M10.7255 16.7335V12.701C10.7255 10.9198 12.1692 9.47601 13.9517 9.47601H17.1767"
          stroke="url(#paint6_linear_2404_1754)"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fill="none"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M33.3063 40.8466C33.3063 47.9728 27.53 53.7503 20.4025 53.7503C13.2762 53.7503 7.5 47.9728 7.5 40.8466C7.5 33.7203 13.2762 27.9428 20.4025 27.9428C27.53 27.9428 33.3063 33.7203 33.3063 40.8466Z"
          stroke="url(#paint7_linear_2404_1754)"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fill="none"
          d="M25.2416 40.8464H20.4029V32.7814"
          stroke="url(#paint8_linear_2404_1754)"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fill="none"
          d="M31.986 24.8637L33.0735 25.9512L36.3348 21.6025"
          stroke="url(#paint9_linear_2404_1754)"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fill="none"
          d="M42.8582 24.8637L43.9457 25.9512L47.2069 21.6025"
          stroke="url(#paint10_linear_2404_1754)"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <defs>
          <linearGradient
            id="paint0_linear_2404_1754"
            x1={10.7255}
            y1={30.8062}
            x2={52.4992}
            y2={30.8062}
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0.0843751} stopColor="#FF0048" />
            <stop offset={0.386458} stopColor="#DF36FF" />
            <stop offset={0.980208} stopColor="#4A00E2" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_2404_1754"
            x1={43.6288}
            y1={41.2496}
            x2={52.5}
            y2={41.2496}
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0.0843751} stopColor="#FF0048" />
            <stop offset={0.386458} stopColor="#DF36FF" />
            <stop offset={0.980208} stopColor="#4A00E2" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_2404_1754"
            x1={20.4025}
            y1={9.47532}
            x2={23.6288}
            y2={9.47532}
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0.0843751} stopColor="#FF0048" />
            <stop offset={0.386458} stopColor="#DF36FF" />
            <stop offset={0.980208} stopColor="#4A00E2" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_2404_1754"
            x1={39.5961}
            y1={9.47532}
            x2={42.8223}
            y2={9.47532}
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0.0843751} stopColor="#FF0048" />
            <stop offset={0.386458} stopColor="#DF36FF" />
            <stop offset={0.980208} stopColor="#4A00E2" />
          </linearGradient>
          <linearGradient
            id="paint4_linear_2404_1754"
            x1={46.0481}
            y1={13.1042}
            x2={52.4993}
            y2={13.1042}
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0.0843751} stopColor="#FF0048" />
            <stop offset={0.386458} stopColor="#DF36FF" />
            <stop offset={0.980208} stopColor="#4A00E2" />
          </linearGradient>
          <linearGradient
            id="paint5_linear_2404_1754"
            x1={26.8546}
            y1={9.4754}
            x2={36.3709}
            y2={9.4754}
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0.0843751} stopColor="#FF0048" />
            <stop offset={0.386458} stopColor="#DF36FF" />
            <stop offset={0.980208} stopColor="#4A00E2" />
          </linearGradient>
          <linearGradient
            id="paint6_linear_2404_1754"
            x1={10.7255}
            y1={13.1048}
            x2={17.1767}
            y2={13.1048}
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0.0843751} stopColor="#FF0048" />
            <stop offset={0.386458} stopColor="#DF36FF" />
            <stop offset={0.980208} stopColor="#4A00E2" />
          </linearGradient>
          <linearGradient
            id="paint7_linear_2404_1754"
            x1={7.5}
            y1={40.8466}
            x2={33.3062}
            y2={40.8466}
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0.0843751} stopColor="#FF0048" />
            <stop offset={0.386458} stopColor="#DF36FF" />
            <stop offset={0.980208} stopColor="#4A00E2" />
          </linearGradient>
          <linearGradient
            id="paint8_linear_2404_1754"
            x1={20.4029}
            y1={36.8139}
            x2={25.2416}
            y2={36.8139}
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0.0843751} stopColor="#FF0048" />
            <stop offset={0.386458} stopColor="#DF36FF" />
            <stop offset={0.980208} stopColor="#4A00E2" />
          </linearGradient>
          <linearGradient
            id="paint9_linear_2404_1754"
            x1={31.986}
            y1={23.7769}
            x2={36.3348}
            y2={23.7769}
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0.0843751} stopColor="#FF0048" />
            <stop offset={0.386458} stopColor="#DF36FF" />
            <stop offset={0.980208} stopColor="#4A00E2" />
          </linearGradient>
          <linearGradient
            id="paint10_linear_2404_1754"
            x1={42.8582}
            y1={23.7769}
            x2={47.2069}
            y2={23.7769}
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0.0843751} stopColor="#FF0048" />
            <stop offset={0.386458} stopColor="#DF36FF" />
            <stop offset={0.980208} stopColor="#4A00E2" />
          </linearGradient>
        </defs>
      </g>
    ),
    viewBox: '0 0 60 60'
  };
};
