import { createGlobalStyle } from 'styled-components';
import './App.css';
import { FontSize } from 'modules/styles/variables';

export const GlobalStyles = createGlobalStyle`
  body,
  html {
    margin: 0;
    padding: 0;
    background-color: #ffffff;
    font-family: 'ABC Social Variable', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
      'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif, 'Rector Web Regular';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: ${FontSize.base}px;
    line-height: 1.4;
  }

  html,
  body,
  #root {
    height: 100vh;
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }

  /** Styles that match react-native defaults */
  *,
  *::before,
  *::after {
    box-sizing: border-box;
    position: relative;
  }

  a {
    color: #2765b8;
    cursor: pointer;
    text-decoration: none;
  }

  a:hover,
  a:active,
  a:focus {
    text-decoration: none;
    color: #036;
  }

  ul,
  li {
    list-style: none;
    margin: 0;
    padding: 0;
  }
`;
