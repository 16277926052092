import React from 'react';

export default {
  svg: (
    <g>
      <path
        d="M60.905 25.391c.077-.284.048-.478-.005-.564-.013-.02-.05-.082-.22-.116l-9.237-1.892.547-1.997
        c.459-1.67.252-3.187-.58-4.27-.772-1.004-2.048-1.557-3.594-1.557h-5.55l-2.396 8.73L37.875 31h.9c3.176-2.914 7.146-4.57
        11.01-4.57 3.848 0 7.079 1.685 8.884 4.57h.697l1.539-5.608zM33.822 31l2.981-10.867 2.323-8.465h-25.46L8.363
        31h1.82c3.177-2.914 7.147-4.57 11.01-4.57 3.849 0 7.08 1.685 8.884 4.57h3.745zM26.473 5.956l-.416
        1.518h3.064l.469-1.8c.104-.38-.018-.897-.296-1.259-.122-.158-.312-.327-.481-.327-.956 0-2.071.89-2.34
        1.868zm37.759 16.837c.642 1.04.8 2.326.445 3.62l-2.324 8.472h-6.121l-.518-1.15c-.98-2.174-3.14-3.421-5.929-3.421-3.031
        0-6.334 1.494-8.834 3.997l-.574.574H27.64l-.517-1.15c-.98-2.174-3.14-3.421-5.93-3.421-3.03 0-6.333 1.494-8.834
        3.997l-.573.574H3.244L10.68 7.782h11.24l.781-2.848C23.43 2.281 26.114.202 28.813.202c1.375 0 2.682.676 3.588
        1.855 1.012 1.317 1.381 3.088.964 4.625l-.286 1.1h11.165l-.912 3.327h4.484c2.793 0 5.173 1.095 6.701 3.085
        1.187 1.543 1.735 3.518 1.594 5.613l5.358 1.098c1.178.24 2.159.912 2.763 1.888z
        M20.96 38.47c.197-.744.128-1.394-.198-1.83-.371-.5-.987-.604-1.438-.604-1.787 0-3.789 1.634-4.284
        3.496-.197.744-.127 1.394.198 1.83.372.5.987.604 1.438.604 1.787 0 3.789-1.633 4.284-3.496zM19.324
        32c1.865 0 3.528.798 4.564 2.19 1.071 1.437 1.387 3.384.867 5.34-.964 3.628-4.513 6.47-8.08 6.47-1.864
        0-3.527-.798-4.563-2.189-1.07-1.438-1.387-3.385-.867-5.342.965-3.627 4.513-6.469 8.08-6.469zm30.636
        6.47c.197-.745.128-1.395-.198-1.831-.371-.5-.987-.604-1.438-.604-1.787 0-3.789 1.634-4.284 3.496-.197.744-.127
        1.394.198 1.83.371.5.987.604 1.438.604 1.787 0 3.789-1.633 4.284-3.496zM48.324 32c1.865 0 3.528.798 4.564 2.19
        1.071 1.437 1.387 3.384.867 5.34-.964 3.628-4.513 6.47-8.08 6.47-1.864
        0-3.527-.798-4.563-2.19-1.071-1.437-1.387-3.384-.867-5.34.965-3.628 4.513-6.47 8.08-6.47zM6.92 37.18a1.86
        1.86 0 0 1 1.867 1.855A1.861 1.861 0 0 1 6.92 40.89H1.867A1.861 1.861 0 0 1 0 39.035a1.86 1.86 0 0 1 1.867-1.854
        H6.92zM61.104 37C62.15 37 63 37.895 63 39c0 1.104-.849 2-1.896 2h-3.208C56.85 41 56 40.104 56 39c0-1.105.849-2
        1.896-2h3.208zm-25.022 0c1.06 0 1.918.895 1.918 2 0 1.104-.859 2-1.918 2h-6.164C28.858 41 28 40.104 28
        39c0-1.105.859-2 1.918-2h6.164zM28.96 17.117c1.127 0 2.04.843 2.04 1.883s-.913 1.883-2.04 1.883h-2.901l-.807
        2.729c-.247.837-1.07 1.388-1.967 1.388-.177 0-.358-.021-.537-.067-1.087-.273-1.728-1.308-1.432-2.312l.513-1.738
        H20.04C18.914 20.883 18 20.04 18 19s.914-1.883 2.04-1.883h2.902l.806-2.729c.296-1.003 1.417-1.595 2.505-1.321
        1.087.273 1.728 1.309 1.431 2.312l-.513 1.738h1.789zM47.964 17C49.09 17 50 17.895 50 19c0 1.104-.911 2-2.036 2
        h-1.928C44.912 21 44 20.104 44 19c0-1.105.912-2 2.036-2h1.928z"
        fillRule="evenodd"
      />
    </g>
  ),
  viewBox: '0 0 65 46'
};
