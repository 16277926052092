import React from 'react';
import { MIME_FILES_ALLOWED } from 'store/messaging/constants';
import { MuiButton } from 'theme/material-ui';

export interface FileMessageUploadButtonProps {
  onClick?: (files: FileList | null) => void;
  children: React.ReactNode;
}

const FileMessageUploadButton = React.forwardRef(
  ({ onClick, children, ...rest }: FileMessageUploadButtonProps, ref: any) => {
    if (!ref) {
      ref = React.createRef<HTMLInputElement>();
    }

    return (
      <MuiButton
        color="primary"
        variant="outlined"
        data-testid="file-upload-button"
        onClick={() => ref.current?.click()}
        fullWidth
        {...rest}
      >
        {children}
        <input
          data-testid="file-upload"
          type="file"
          multiple
          hidden
          ref={ref}
          accept={MIME_FILES_ALLOWED.join(', ')}
          onChange={({ target: { validity, files } }: React.ChangeEvent<HTMLInputElement>) =>
            validity.valid && onClick?.(files)
          }
        />
      </MuiButton>
    );
  }
);

export default FileMessageUploadButton;
