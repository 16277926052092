import { Color } from 'modules/styles/colors';
import { FontSize, IconSize, Spacing } from 'modules/styles/variables';
import styled, { css } from 'styled-components';
import {
  dfdDefaultTheme,
  makeStyles,
  MuiBox,
  MuiIconButton,
  MuiPaper,
  MuiTextField,
  MuiTypography,
  MuiInputAdornment,
  BoxProps
} from 'theme/material-ui';

export const AutoCompleteBox = styled(MuiBox)`
  padding: 0px;
  padding-left: 15px;
  border-radius: 25px;
  flex-direction: row;
  align-self: center;
  & svg {
    width: ${IconSize.base}px;
    height: ${IconSize.base}px;
    fill: #777777;
    margin-right: 10.5px;
    align-self: center;
  }
  & input {
    padding: 15px;
    ${props =>
      props.width !== 'auto'
        ? `
    min-width: 250px;
    ${dfdDefaultTheme.breakpoints.up('md')} {
      width: 793px;
    }`
        : ``}

    &::placeholder {
      font-size: ${FontSize.large}px;
    }
  }
`;

export const AutoCompleteSmallBox = styled(AutoCompleteBox as React.ComponentType<BoxProps>)`
  padding: 0px;
  border-radius: 4px;
  border: 1px solid ${Color.graySidebarDivider};
  background-color: ${Color.white};
`;

export const GroupBox = styled(MuiBox)`
  padding: 0px 30px;
`;

export const Adornment = styled(MuiInputAdornment)`
  ${props => {
    switch (props.position) {
      case 'start':
        return css`
          margin-left: ${dfdDefaultTheme.spacing(2)}px;
        `;
      case 'end':
        return css`
          margin-right: ${dfdDefaultTheme.spacing(2)}px;
        `;
      default:
        return '';
    }
  }}
`;

export const GroupHeader = styled(MuiTypography)`
  font-size: ${FontSize.large}px;
  letter-spacing: 0.5px;
  color: #222222;
  font-weight: normal;
  padding: ${Spacing.medium}px 0px;
`;

export const removeUnderline = makeStyles({
  underline: {
    '&&&:before': {
      borderBottom: 'none'
    },
    '&&:after': {
      borderBottom: 'none'
    }
  }
});

export const SearchButton = styled.button<{ borderRadius?: string }>`
  color: ${Color.white};
  background-color: ${Color.primary};
  cursor: pointer;
  border-radius: ${props => (props.borderRadius ? props.borderRadius : '0px 25px 25px 0px')};
  font-size: ${FontSize.large}px;
  line-height: 0;
  margin: 0;
  padding: ${Spacing.large}px;
  border-width: 0;
`;

export const StyledIconButton = styled(MuiIconButton)<{ hidden: boolean; customColor?: string }>`
  opacity: ${props => (props.hidden ? 0 : 1)};
  transform: ${props => (props.hidden ? 'scale(0, 0)' : 'scale(1, 1)')};
  transition: transform 200ms cubic-bezier(0.4, 0, 0.2, 1);

  && {
    color: ${props => props.customColor || 'rgba(0, 0, 0, 0.54)'};
    padding: 2px;
  }
`;

interface StyledInput {
  border: boolean;
  borderColor?: string;
  borderWidth?: number;
  maxHeight?: number;
}

export const StyledInput = styled(MuiTextField).attrs(({ maxHeight }: StyledInput) => ({
  maxHeight: maxHeight || 48
}))<{ border: boolean }>`
  border-radius: 25px;
  background-color: ${Color.white};

  .MuiFormLabel-root {
    display: none;
  }
  .MuiInput-root {
    max-height: ${props => props.maxHeight}px;
  }
  .MuiInput-formControl {
    margin-top: 0px;
    border: ${props => (props.border ? `1px solid ${Color.grayLight}` : 'none')};
    border-color: ${props => props.borderColor};
    border-width: ${props => `${props.borderWidth}px;`}
    border-radius: 25px;
    .MuiInputBase-input {
      min-height: 40px;
      font-size: 13px;
      &::placeholder {
        font-size: 13px;
      }
    }
    .MuiInputAdornment-root {
      margin-left: ${Spacing.medium}px;
    }
  }
`;

export const StyledInputV2 = styled(MuiTextField)`
  border-radius: 25px;
  background-color: ${Color.secondary};
  background-color: ${Color.white};

  && {
    width: 100%;
    overflow: hidden;
  }

  .MuiFormLabel-root {
    display: none;
  }
  .MuiInput-root {
    max-height: 30px;
  }
  .MuiInput-formControl {
    margin-top: 0px;
    border: 1px solid ${Color.secondary};
    border-width: 2px;
    border-color: ${Color.secondary};
    border-radius: 25px;
    .MuiInputBase-input {
      min-height: 40px;
      font-size: 13px;
      &::placeholder {
        font-size: 13px;
      }
    }
    .MuiInputAdornment-root {
      width: 32px;
      height: 32px;
      padding-left: 6px;
      padding-right: 6px;
      left: -3px;
      margin-left: 0px;
      margin-right: 0px;
      margin-left: ${Spacing.medium}px;
      &.MuiInputAdornment-positionEnd {
        display: flex;
      }
    }
  }
`;

export const StyledAutoCompletePaper = styled(MuiPaper)`
  &.MuiAutocomplete-paper > ul {
    max-height: 100%;
  }
`;
