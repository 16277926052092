import React from 'react';
import dayjs from 'dayjs';
import { connect } from 'react-redux';

import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';

import { RootState } from 'store/types';
import { faBasicInfoSelector } from 'store/billing/financialAssistance/createApplication/selectors';

import { PATIENT_INFO, RESPONSIBLE_PARTY } from '../constants';
import { Section } from './types';
import { convertToLowerKabobCase } from 'modules/utils/StringUtils';
import { MuiButton } from 'theme/material-ui';

const BasicInfo = ({ navigateToEdit, formData }: Section) => {
  const parseValues = (field: string, value: string) => {
    switch (field) {
      case 'addressLength': {
        if (value === '1') {
          return '1-3 months';
        }
        return '4 months or more';
      }
      case 'patientBirthDate':
      case 'guarantorBirthDate': {
        return dayjs(value).format('MM/DD/YYYY');
      }
      case 'extra1AddressFrom': {
        return dayjs(value).format('MM/DD/YYYY');
      }
      case 'extra1AddressTo': {
        return dayjs(value).format('MM/DD/YYYY');
      }
      case 'extra2AddressFrom': {
        return dayjs(value).format('MM/DD/YYYY');
      }
      case 'extra2AddressTo': {
        return dayjs(value).format('MM/DD/YYYY');
      }
      default:
        return value;
    }
  };

  return (
    <Box>
      <Box position="absolute" top="-10px" right="-7px" zIndex={1}>
        <MuiButton color="primary" onClick={navigateToEdit} startIcon={<EditIcon />}>
          Edit
        </MuiButton>
      </Box>
      <Box>
        <Typography variant="h6">Patient</Typography>
      </Box>
      {PATIENT_INFO.map(item => {
        const fieldValue = formData[item.field];
        if (fieldValue) {
          return (
            <Box py={1} key={item.field} data-testid={item.field}>
              <Typography>{item.label}</Typography>
              <Typography data-testid={convertToLowerKabobCase(item.label, '-text')}>
                <b>{parseValues(item.field, fieldValue)}</b>
              </Typography>
            </Box>
          );
        }
        return null;
      })}
      <Box py={3}>
        <Divider />
      </Box>
      <Box>
        <Typography variant="h6">Responsible Party</Typography>
      </Box>
      {RESPONSIBLE_PARTY.map(item => {
        const fieldValue = formData[item.field];
        if (fieldValue) {
          return (
            <>
              {/* {item.label === 'Previous Address' && <ArrayItemSeparator />} */}
              <Box py={1} key={item.field} data-testid={item.field}>
                <Typography>{item.label}</Typography>
                <Typography data-testid={convertToLowerKabobCase(item.label, '-text')}>
                  <b>{parseValues(item.field, fieldValue)}</b>
                </Typography>
              </Box>
            </>
          );
        }
        return null;
      })}
    </Box>
  );
};

const mapStateToProps = (state: RootState) => ({
  formData: faBasicInfoSelector(state)
});
export default connect(mapStateToProps)(BasicInfo);
