import React from 'react';

export default {
  svg: (
    <g>
      <g>
        <circle fill="#FF5D55" strokeWidth={0} cx={40} cy={40} r={38.5} />
        <path
          fill="#FFFFFF"
          strokeWidth={0}
          d="M40,2.5c20.711,0,37.5,16.789,37.5,37.5S60.711,77.5,40,77.5S2.5,60.711,2.5,40S19.289,2.5,40,2.5 M40,0.5 C18.22,0.5,0.5,18.22,0.5,40S18.22,79.5,40,79.5S79.5,61.78,79.5,40S61.78,0.5,40,0.5L40,0.5z"
        />
      </g>
      <g>
        <path
          fill="none"
          strokeWidth={0}
          d="M12.768,63.803c-0.188-0.645-0.352-1.274-0.52-1.905c-0.112-0.419-0.23-0.844-0.333-1.257 c-0.148-0.595-0.276-1.177-0.406-1.759c-0.096-0.431-0.197-0.865-0.284-1.289c-0.113-0.553-0.209-1.094-0.306-1.635 c-0.078-0.436-0.159-0.873-0.227-1.301c-0.082-0.517-0.15-1.023-0.217-1.53c-0.058-0.433-0.116-0.867-0.164-1.293 c-0.019-0.171-0.048-0.349-0.066-0.518c0.006,0.001,0.011,0.002,0.017,0.003c-2.153-21.128,8.6-32.416,20.876-36.814 c15.727-4.37,35.735,1.44,45.122,35.075C77.066,46.521,77.5,43.311,77.5,40C77.5,19.289,60.711,2.5,40,2.5S2.5,19.289,2.5,40 c0,10.439,4.269,19.878,11.152,26.677c-0.187-0.572-0.347-1.126-0.519-1.688C13.012,64.594,12.882,64.193,12.768,63.803z"
        />
        <path
          fill="#FBD5CC"
          strokeWidth={0}
          d="M11.225,57.593c0.087,0.424,0.188,0.858,0.284,1.289C11.413,58.451,11.312,58.017,11.225,57.593z"
        />
        <path
          fill="#FBD5CC"
          strokeWidth={0}
          d="M10.692,54.656c0.068,0.428,0.149,0.866,0.227,1.301C10.841,55.522,10.76,55.084,10.692,54.656z"
        />
        <path
          fill="#FBD5CC"
          strokeWidth={0}
          d="M12.768,63.803c0.114,0.39,0.244,0.791,0.365,1.185C13.012,64.594,12.881,64.193,12.768,63.803z"
        />
        <path
          fill="#FBD5CC"
          strokeWidth={0}
          d="M10.312,51.834c0.047,0.425,0.106,0.859,0.164,1.293C10.417,52.693,10.359,52.259,10.312,51.834z"
        />
        <path
          fill="#FBD5CC"
          strokeWidth={0}
          d="M11.916,60.641c0.103,0.413,0.221,0.838,0.333,1.257C12.137,61.479,12.018,61.055,11.916,60.641z"
        />
        <path
          fill="#FBD5CC"
          strokeWidth={0}
          d="M31.138,14.504C18.863,18.902,8.11,30.19,10.263,51.318c2.701,0.393,5.475,0.819,8.299,1.274 c-0.197-0.323-0.37-0.665-0.489-1.042c-0.634-1.995,0.113-4.08,1.69-5.276c-1.61-3.731-2.457-7.719-2.27-11.542 c0.414-8.48,7.768-15.169,14.848-17.419c0.14-0.044,0.281-0.08,0.421-0.119l-0.002-0.005c0.14-0.041,0.281-0.078,0.423-0.114 c0.271-0.104,0.546-0.201,0.824-0.289c7.081-2.25,16.946-1.028,22.178,5.66c2.359,3.015,3.968,6.76,4.805,10.736 c1.978,0.067,3.792,1.339,4.426,3.333c0.804,2.533-0.597,5.236-3.128,6.04c-0.26,0.083-0.521,0.127-0.782,0.165 c-0.671,6.657-3.543,12.88-8.775,16.463c5.757,1.309,11.35,2.699,16.613,4.154c3.186-4.002,5.575-8.665,6.917-13.756 C66.874,15.944,46.865,10.134,31.138,14.504z"
        />
        <path
          fill="#FBC0D1"
          strokeWidth={0}
          d="M69.344,63.335c-5.263-1.455-10.857-2.845-16.613-4.154c-1.124,0.77-2.358,1.416-3.702,1.92 c-0.414,0.542-0.99,0.969-1.69,1.191c-0.708,0.226-1.432,0.203-2.09-0.008c-7.709,1.837-15.161-1.889-20.405-7.922 c-0.234,0.12-0.474,0.235-0.733,0.317c-2.155,0.684-4.423-0.238-5.55-2.087c-2.823-0.455-5.597-0.881-8.299-1.274 c-0.006-0.001-0.011-0.002-0.017-0.003c0.017,0.169,0.047,0.348,0.066,0.518c0.047,0.425,0.106,0.859,0.164,1.293 c0.067,0.506,0.135,1.013,0.217,1.53c0.068,0.428,0.149,0.866,0.227,1.301c0.097,0.541,0.193,1.082,0.306,1.635 c0.087,0.424,0.188,0.859,0.284,1.289c0.13,0.583,0.258,1.164,0.406,1.759c0.103,0.413,0.221,0.838,0.333,1.257 c0.167,0.631,0.332,1.26,0.52,1.905c0.113,0.39,0.244,0.791,0.365,1.185c0.172,0.562,0.332,1.116,0.519,1.688 C20.424,73.367,29.728,77.5,40,77.5C51.887,77.5,62.474,71.963,69.344,63.335z"
        />
        <path
          fill="#FBC0D1"
          strokeWidth={0}
          d="M46.928,61.799l-0.002-0.006c-0.039,0.014-0.077,0.027-0.115,0.041c0.12-0.022,0.24-0.038,0.36-0.076 c0.15-0.047,0.287-0.114,0.424-0.183c-0.085,0.023-0.17,0.051-0.256,0.073C47.203,61.7,47.066,61.75,46.928,61.799z"
        />
        <path
          fill="#934127"
          strokeWidth={0}
          d="M61.506,42.718c0.261-0.037,0.522-0.082,0.782-0.165c2.531-0.804,3.932-3.507,3.128-6.04 c-0.633-1.994-2.448-3.266-4.426-3.333C61.646,36.298,61.825,39.554,61.506,42.718z"
        />
        <path
          fill="#B34D2E"
          strokeWidth={0}
          d="M19.763,46.274c-1.577,1.196-2.324,3.281-1.69,5.276c0.12,0.377,0.292,0.719,0.489,1.042 c1.126,1.848,3.395,2.771,5.55,2.087c0.26-0.082,0.499-0.197,0.733-0.317C22.759,51.962,21.025,49.199,19.763,46.274z"
        />
        <path
          fill="#B34D2E"
          strokeWidth={0}
          d="M46.119,59.252c-0.905,0.273-1.806,0.337-2.655,0.246c0.022,0.128,0.04,0.257,0.08,0.384 c0.241,0.759,0.769,1.351,1.424,1.69c0.553,0.286,1.198,0.381,1.843,0.262c0.039-0.013,0.077-0.027,0.115-0.041L46.119,59.252z"
        />
        <path
          fill="#B34D2E"
          strokeWidth={0}
          d="M43.012,60.051c-0.066-0.207-0.105-0.415-0.131-0.623c-1.715-0.352-3.108-1.421-3.603-2.978 c-0.784-2.47,1.299-4.272,4.286-5.244L37.582,32.37c-0.891,0.864-1.858,1.649-2.694,2.375c1.277-1.792-0.403-4.017-1.472-6.151 c-0.005,0.007-0.011,0.013-0.016,0.019c-0.855,1.063-1.608,1.28-2.133,0.526c-0.829-1.192-0.481-1.981-0.64-2.731 c-0.399-0.655-1.193-0.991-1.406-2.427c-0.134-0.908,0.562-1.267,1.913-1.08c0.977,0.136,1.958,0.239,2.939,0.345 c0.022-0.213,0.157-0.405,0.368-0.487l0.08-0.032l-1.758-5.535c-0.14,0.04-0.281,0.076-0.421,0.119 c-7.08,2.25-14.434,8.939-14.848,17.419c-0.187,3.823,0.659,7.811,2.27,11.542c1.262,2.924,2.996,5.688,5.082,8.088 c5.244,6.033,12.696,9.759,20.405,7.922c-0.185-0.059-0.367-0.128-0.539-0.216C43.928,61.663,43.299,60.958,43.012,60.051z  M32.144,49.452c0.853-0.27,1.765,0.202,2.036,1.056c0.271,0.854-0.2,1.765-1.055,2.037c-0.854,0.271-1.766-0.202-2.037-1.056 C30.817,50.635,31.289,49.722,32.144,49.452z M33.868,58.842c-1.405,0.444-2.904-0.332-3.35-1.735 c-0.445-1.405,0.332-2.905,1.735-3.35c1.404-0.446,2.904,0.33,3.349,1.735C36.048,56.897,35.271,58.395,33.868,58.842z"
        />
        <path
          fill="#934127"
          strokeWidth={0}
          d="M49.048,58.134c-0.052-0.162-0.122-0.313-0.198-0.459c-0.699,0.675-1.591,1.224-2.63,1.554 c-0.034,0.011-0.067,0.013-0.101,0.023l0.807,2.542l0.002,0.006c0.138-0.049,0.275-0.099,0.41-0.151 c0.085-0.022,0.17-0.049,0.256-0.073c0.553-0.279,0.993-0.714,1.266-1.241C49.2,59.68,49.29,58.893,49.048,58.134z"
        />
        <path
          fill="#934127"
          strokeWidth={0}
          d="M61.506,42.718c0.319-3.164,0.141-6.421-0.516-9.538c-0.837-3.976-2.447-7.721-4.805-10.736 c-5.231-6.688-15.097-7.909-22.178-5.66c-0.279,0.088-0.554,0.185-0.824,0.289c-0.142,0.036-0.283,0.073-0.423,0.114l0.002,0.005 l1.758,5.535l0.716-0.284c0.218-0.088,0.459-0.034,0.62,0.116c0.651-0.761,1.305-1.518,1.933-2.298 c0.855-1.063,1.608-1.282,2.132-0.528c0.83,1.192,0.482,1.981,0.641,2.731c0.399,0.657,1.193,0.991,1.406,2.429 c0.125,0.844-0.476,1.206-1.648,1.103c0.555,2.549-0.931,4.622-2.738,6.373l5.982,18.837c0.033-0.011,0.064-0.023,0.097-0.033 c3.041-0.966,5.832-0.716,6.626,1.781c0.467,1.471,0.023,3.046-1.036,4.292c0.132,0.223,0.248,0.459,0.33,0.716 c0.288,0.907,0.181,1.845-0.225,2.628c-0.093,0.179-0.205,0.348-0.328,0.508c1.344-0.503,2.578-1.149,3.702-1.92 C57.963,55.598,60.835,49.375,61.506,42.718z M51.127,45.126c-0.271-0.854,0.201-1.767,1.055-2.037 c0.854-0.272,1.766,0.2,2.036,1.054c0.272,0.854-0.201,1.767-1.054,2.037C52.31,46.452,51.398,45.979,51.127,45.126z  M53.783,49.719c-0.445-1.405,0.332-2.904,1.736-3.35c1.404-0.446,2.904,0.332,3.348,1.735c0.446,1.403-0.33,2.904-1.735,3.35 C55.73,51.897,54.23,51.122,53.783,49.719z"
        />
        <path
          fill="#934127"
          strokeWidth={0}
          d="M35.602,55.492c-0.445-1.405-1.946-2.181-3.349-1.735c-1.403,0.446-2.18,1.945-1.735,3.35 c0.447,1.403,1.946,2.179,3.35,1.735C35.271,58.395,36.048,56.897,35.602,55.492z"
        />
        <path
          stroke="#000000"
          d="M33.125,52.545c0.854-0.272,1.326-1.183,1.055-2.037c-0.271-0.854-1.183-1.326-2.036-1.056 c-0.855,0.271-1.327,1.183-1.056,2.037C31.359,52.343,32.271,52.815,33.125,52.545z"
        />
        <path
          stroke="#000000"
          d="M54.218,44.143c-0.271-0.854-1.183-1.326-2.036-1.054c-0.854,0.27-1.326,1.183-1.055,2.037 c0.271,0.852,1.183,1.326,2.037,1.054C54.017,45.91,54.49,44.997,54.218,44.143z"
        />
        <path
          fill="#703421"
          strokeWidth={0}
          d="M57.133,51.453c1.404-0.446,2.181-1.947,1.735-3.35c-0.445-1.403-1.945-2.181-3.348-1.735 c-1.404,0.446-2.18,1.945-1.736,3.35C54.23,51.122,55.73,51.897,57.133,51.453z"
        />
        <path
          fill="#FE0002"
          strokeWidth={0}
          d="M39.278,56.451c0.494,1.557,1.888,2.626,3.603,2.978c-0.088-0.705,0.041-1.402,0.354-2.008 c0.079-0.152,0.178-0.293,0.279-0.432c-0.343-0.273-0.611-0.647-0.754-1.096c-0.382-1.205,0.283-2.49,1.488-2.873 c1.204-0.382,2.49,0.283,2.873,1.489c0.125,0.394,0.129,0.794,0.047,1.171c0.248,0.065,0.488,0.149,0.714,0.266 c0.56,0.291,1.036,0.74,1.37,1.302c1.059-1.246,1.503-2.822,1.036-4.292c-0.794-2.498-3.585-2.748-6.626-1.781 c-0.033,0.01-0.064,0.023-0.097,0.033C40.577,52.179,38.494,53.98,39.278,56.451z"
        />
        <path
          fill="#FE0002"
          strokeWidth={0}
          d="M46.119,59.252c0.034-0.01,0.068-0.012,0.101-0.023c1.04-0.33,1.931-0.879,2.63-1.554 c-0.28-0.538-0.71-0.964-1.226-1.231c-0.201-0.104-0.416-0.175-0.636-0.23c-0.266,0.537-0.738,0.972-1.355,1.169 c-0.563,0.179-1.142,0.121-1.64-0.107c-0.096,0.129-0.187,0.261-0.261,0.404c-0.283,0.547-0.379,1.182-0.269,1.819 C44.313,59.589,45.214,59.525,46.119,59.252z"
        />
        <path
          fill="#FFFFFF"
          strokeWidth={0}
          d="M45.634,57.382c0.616-0.197,1.089-0.631,1.355-1.169c-0.5-0.125-1.039-0.126-1.566,0.042 c-0.595,0.188-1.081,0.556-1.429,1.02C44.492,57.503,45.07,57.561,45.634,57.382z"
        />
        <path
          fill="#FFFFFF"
          strokeWidth={0}
          d="M47.121,54.509c-0.382-1.205-1.669-1.871-2.873-1.489c-1.204,0.382-1.87,1.668-1.488,2.873 c0.143,0.449,0.411,0.823,0.754,1.096c0.418-0.577,1.008-1.036,1.74-1.267c0.645-0.204,1.304-0.201,1.914-0.042 C47.25,55.303,47.247,54.903,47.121,54.509z"
        />
        <path
          fill="#FFFFFF"
          strokeWidth={0}
          d="M49.582,57.964c-0.082-0.257-0.197-0.494-0.33-0.716c-0.334-0.562-0.81-1.011-1.37-1.302 c-0.226-0.117-0.467-0.201-0.714-0.266c-0.61-0.16-1.269-0.163-1.914,0.042c-0.731,0.231-1.322,0.69-1.74,1.267 c-0.101,0.139-0.2,0.28-0.279,0.432c-0.313,0.605-0.442,1.303-0.354,2.008c0.026,0.208,0.065,0.416,0.131,0.623 c0.287,0.907,0.917,1.612,1.699,2.016c0.172,0.089,0.354,0.157,0.539,0.216c0.657,0.211,1.382,0.233,2.09,0.008 c0.7-0.222,1.275-0.649,1.69-1.191c0.123-0.161,0.235-0.329,0.328-0.508C49.762,59.809,49.87,58.87,49.582,57.964z M48.861,60.335 c-0.273,0.527-0.713,0.962-1.266,1.241c-0.136,0.068-0.274,0.135-0.424,0.183c-0.119,0.038-0.24,0.054-0.36,0.076 c-0.645,0.118-1.29,0.024-1.843-0.262c-0.656-0.34-1.183-0.931-1.424-1.69c-0.04-0.128-0.058-0.256-0.08-0.384 c-0.11-0.637-0.015-1.272,0.269-1.819c0.074-0.142,0.165-0.275,0.261-0.404c0.348-0.464,0.834-0.831,1.429-1.02 c0.527-0.168,1.065-0.167,1.566-0.042c0.22,0.055,0.436,0.127,0.636,0.23c0.516,0.267,0.946,0.693,1.226,1.231 c0.076,0.146,0.146,0.296,0.198,0.459C49.29,58.893,49.2,59.68,48.861,60.335z"
        />
        <path
          stroke="#000000"
          d="M37.048,25.62c-0.03,0.203-0.163,0.386-0.366,0.466l-0.797,0.315c-0.194,0.078-0.407,0.043-0.566-0.073 c-0.642,0.749-1.286,1.497-1.903,2.266c1.069,2.134,2.75,4.359,1.472,6.151c0.835-0.726,1.803-1.511,2.694-2.375 c1.807-1.751,3.293-3.825,2.738-6.373c-0.088-0.008-0.171-0.012-0.265-0.025C39.056,25.832,38.052,25.73,37.048,25.62z"
        />
        <path
          fill="#FFFFFF"
          strokeWidth={0}
          d="M34.072,23.247c-0.981-0.106-1.962-0.209-2.939-0.345c-1.352-0.186-2.047,0.172-1.913,1.08 c0.214,1.436,1.007,1.772,1.406,2.427c0.158,0.75-0.189,1.539,0.64,2.731c0.525,0.754,1.278,0.537,2.133-0.526 c0.005-0.007,0.011-0.013,0.016-0.019c0.617-0.769,1.261-1.517,1.903-2.266c0.158,0.116,0.372,0.151,0.566,0.073l0.797-0.315 c0.203-0.08,0.337-0.263,0.366-0.466c1.004,0.11,2.008,0.213,3.007,0.353c0.094,0.013,0.177,0.017,0.265,0.025 c1.172,0.103,1.773-0.259,1.648-1.103c-0.213-1.438-1.007-1.772-1.406-2.429c-0.159-0.75,0.189-1.539-0.641-2.731 c-0.524-0.754-1.277-0.535-2.132,0.528c-0.628,0.78-1.282,1.537-1.933,2.298c-0.161-0.149-0.402-0.203-0.62-0.116l-0.716,0.284 l-0.08,0.032C34.229,22.842,34.094,23.034,34.072,23.247z"
        />
      </g>
    </g>
  ),
  viewBox: '0 0 80 80'
};
