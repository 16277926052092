import { Confirm } from 'components/ConfirmDialog/ConfirmDialog';
import RequiredFieldsLegend, { StyledAsterisk } from 'components/RequiredFieldsLegend';
import Spacer from 'components/UI/Layout/Spacer';
import ProxyFormFooter from './components/ProxyFormFooter';
import React, { ComponentType, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { Color } from 'modules/styles/colors';
import { FontSize, IconSize } from 'modules/styles/variables';
import { Spacing } from 'modules/styles/variables.web';
import {
  consumerIdSelector,
  profileConsumerSelector,
  profileRequestedProxies
} from 'store/profile/selectors';
import { RootState, RootDispatch } from 'store/types';
import { Consumer } from 'store/profile/types';
import { clearRequestedProxies } from 'store/profile/actions';
import { MuiBox, MuiTypography, MuiContainer, MuiButton, MuiTextField } from 'theme/material-ui';
import { PROXY_REVIEW_CONTACT_FIELDS, ProxyReviewContactFieldProps } from './constants';
import ProxyLeavingGuard from './navigation/ProxyLeavingGuard';
import { Typography } from '../../components/UI/Typography/styled';
import FlexBox from '../../components/UI/Layout/FlexBox';
import { Icon } from 'components/Icon';

import { Formik } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import * as routerSelectors from 'store/router/selectors';
import analyticsService, { AmplitudeEventData, AnalyticsEvent } from 'services/AnalyticsService';
import { shareServiceNow } from 'store/serviceNow/actions';
import { serviceNowSendingSelector } from 'store/serviceNow/selectors';

const TimerBox = styled(FlexBox)`
  background-color: ${Color.white};
  padding: ${Spacing.medium}px;
  border-radius: ${Spacing.medium}px;
  border: 1px solid ${Color.black};
  min-width: 340px;
`;

const ReferenceBox = styled(MuiBox)`
  background-color: ${Color.grayLight3};
  padding: ${Spacing.medium}px;
  border-radius: ${Spacing.medium}px;
  min-width: 340px !important;
`;

const technicalSupportValidationSchema = Yup.object().shape({
  phone: Yup.string()
    .trim()
    .required('Please enter phone number')
    .max(12, 'Must be less than 12 characters')
    .min(8, 'Must be a valid phone number'),
  emailAddress: Yup.string()
    .trim()
    .required('Please enter the email address')
    .email('Please enter a valid email')
    .max(100, 'Must be less than 1000 characters')
});

export interface Props extends RouteComponentProps {
  consumerId: string;
  displayName: string;
  profileConsumer: Consumer;
  clearProxies: () => void;
  shareServiceNow: (data: any) => void;
  minorsData: ProxyFormProps[];
  currentUrl?: string;
  referringUrl?: string;
  isSendingIncident?: boolean;
}

export interface DescriptionProps {
  incidentId: string;
}

export interface ReviewContactInformationProxyRequest {
  emailAddress: string;
  phone: string;
}

export interface AdultInfo {
  name: string;
  emailOnFile: string;
  phoneOnFile: string;
  emailForProxyAccess?: string;
  phoneForProxyAccess?: string;
  MRN: string;
  address: string;
}
export interface ProxyFormProps {
  firstName: string;
  middleName: string;
  lastName: string;
  gender: string;
  birthDate: string;
  relationship: string;
  address: string;
  otherRelationship: string;
  street: string;
  housingType: string;
  city: string;
  state: string;
  postalCode: string;
}

export const Description = (props: DescriptionProps) => (
  <>
    <MuiBox marginTop={Spacing.xSmall} marginBottom={Spacing.xSmall}>
      <Typography textAlign="center">
        We'll notify you via email once your request has been completed.
      </Typography>
    </MuiBox>
    <ReferenceBox
      flexDirection="row"
      alignItems="start"
      justifyContent="space-around"
      marginBottom={Spacing.medium}
      padding={Spacing.xSmall}
    >
      <Typography textAlign="left">Reference Number: {props.incidentId}</Typography>
    </ReferenceBox>
    <TimerBox flexDirection="row" alignItems="center" justifyContent="space-around">
      <MuiBox margin={Spacing.xSmall}>
        <Icon name="access-time" size={IconSize.base} color={Color.black} />
      </MuiBox>
      <Typography textAlign="left">Estimated response time: Usually within 24 hours.</Typography>
    </TimerBox>
  </>
);

const StyledTextField = styled(MuiTextField)`
  background-color: ${Color.foreColor};
`;

export const ProxyReviewContactScreen = (props: Props) => {
  const {
    currentUrl,
    referringUrl,
    minorsData = [],
    consumerId,
    profileConsumer,
    isSendingIncident
  } = props;
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);

  const initialEmail = profileConsumer?.emails?.[0]?.value || '';
  const initialPhone = profileConsumer?.phones?.[0]?.value || '';

  const initialValues = {
    emailAddress: initialEmail,
    phone: initialPhone
  };

  const submitForm = async (values?: ReviewContactInformationProxyRequest) => {
    const eventData: AmplitudeEventData = {
      currentUrl,
      referringUrl,
      request_access_type: 'Minor',
      number_proxies: minorsData.length
    };

    setIsSubmitClicked(true);

    const emailDiff = values?.emailAddress !== initialEmail;
    const phoneDiff = values?.phone !== initialPhone;

    const { streetAddress, streetAddress2, city, state, postalCode } =
      profileConsumer?.addresses?.[0] || {};
    const adultInfo: AdultInfo = {
      name: `${profileConsumer?.firstName} ${profileConsumer?.middleName} ${profileConsumer?.lastName}`,
      emailOnFile: initialEmail,
      phoneOnFile: initialPhone,
      emailForProxyAccess: emailDiff ? values?.emailAddress : undefined,
      phoneForProxyAccess: phoneDiff ? values?.phone : undefined,
      MRN: consumerId,
      address: `${streetAddress} ${streetAddress2} \n${city}, ${state} ${postalCode}`
    };

    const minorsInfo = minorsData.map(minor => {
      return {
        legalFirstName: minor.firstName,
        legalMiddleName: minor.middleName,
        legalLastName: minor.lastName,
        sex: minor.gender,
        DOB: minor.birthDate,
        address:
          minor.address === 'yes'
            ? adultInfo.address
            : `${minor.street} ${minor.housingType} \n ${minor.city} ${minor.state}, ${minor.postalCode}`,
        relationshipToMinor: minor.relationship,
        comments: minor.otherRelationship ? minor.otherRelationship : null
      };
    });

    const formattedProxyData = {
      incidentType: 'proxyAccess',
      records: {
        adult: adultInfo,
        minors: minorsInfo,
        legalAcceptance: true
      }
    };

    const res = await props.shareServiceNow(formattedProxyData);
    if (res.error) {
      Confirm.show(
        'An error has occurred.',
        'Please try submitting again.',
        undefined,
        'error',
        {
          text: 'Try again?',
          onClick: () => {
            submitForm(values);
          }
        },
        { text: 'Cancel' },
        {
          closeIcon: true
        }
      );
      analyticsService.logEvent(AnalyticsEvent.ProxyAccessFailed, eventData);
      setIsSubmitClicked(false);
    } else {
      analyticsService.logEvent(AnalyticsEvent.ProxyAccessComplete, eventData);
      if (emailDiff || phoneDiff) {
        const contactDetailsChanged: AmplitudeEventData = {
          email_changed: emailDiff,
          phone_changed: phoneDiff
        };
        analyticsService.logEvent(
          AnalyticsEvent.ProxyAccessEditedContactDetails,
          contactDetailsChanged
        );
      }
      props.clearProxies();

      Confirm.show(
        'The request has been sent.',
        <Description incidentId={res?.payload?.data?.incidentId} />,
        undefined,
        'success',
        {
          text: 'Close',
          onClick: () => {
            props.history.push('/u/manage-account', { submitted: true });
          }
        },
        undefined,
        {
          closeIcon: false,
          cancelable: false
        },
        '* Estimations based on normal business hours.'
      );
    }
  };

  return (
    <MuiBox bgcolor={Color.baseColor} flex={1} width="100%" height="100%">
      <ProxyLeavingGuard />
      <MuiContainer maxWidth="xs">
        <MuiBox py={Spacing.small} display="flex" flexDirection="column">
          <MuiTypography align="left" fontSize={FontSize.largeHeading}>
            Review contact information
          </MuiTypography>
          <Spacer size="medium" />
          <MuiTypography fontSize={FontSize.large}>
            Please confirm your contact information for this request.
          </MuiTypography>
          <Spacer size="xsmall" />
          <MuiBox display="flex" flexDirection="row">
            <RequiredFieldsLegend />
          </MuiBox>
          <Spacer size="xsmall" />
        </MuiBox>

        <Formik
          initialValues={initialValues}
          validationSchema={technicalSupportValidationSchema}
          onSubmit={values => submitForm(values)}
          isInitialValid={!!initialValues.phone && !!initialValues.emailAddress}
        >
          {({ errors, handleChange, handleBlur, isValid, handleSubmit }) => {
            return (
              <div>
                <MuiBox pt={Spacing.small}>
                  {PROXY_REVIEW_CONTACT_FIELDS.map((field: ProxyReviewContactFieldProps) => {
                    const { key, props: itemProps } = field;
                    const { fieldHeight, accessibilityLabel, label, ...rest } = itemProps;
                    const customProps: any = {
                      inputProps: {
                        style: { minHeight: fieldHeight },
                        'aria-label': accessibilityLabel
                      }
                    };

                    return (
                      <MuiBox pb={Spacing.small} key={key}>
                        <MuiBox display="flex" flexDirection="row" pb={1}>
                          <MuiTypography>{label}</MuiTypography>
                          <MuiBox px={1}>
                            <StyledAsterisk />
                          </MuiBox>
                        </MuiBox>
                        <StyledTextField
                          {...rest}
                          InputProps={{
                            ...customProps
                          }}
                          FormHelperTextProps={{ style: { position: 'absolute', top: '100%' } }}
                          variant="outlined"
                          defaultValue={initialValues[`${key}`]}
                          onChange={handleChange(key)}
                          data-testid={label}
                          error={!!errors[key]}
                          helperText={errors[key]}
                          onBlur={handleBlur(key)}
                          fullWidth
                        />
                      </MuiBox>
                    );
                  })}
                </MuiBox>
                <MuiBox width="100%" p={Spacing.small} borderColor={Color.grayLight} borderTop={1}>
                  <Spacer size="small" />
                  <MuiButton
                    data-testid="submit-button"
                    color="primary"
                    variant="contained"
                    fullWidth
                    disabled={!isValid || isSubmitClicked}
                    onClick={() => handleSubmit()}
                    loading={isSendingIncident}
                  >
                    Submit Request
                  </MuiButton>
                  <Spacer size="xsmall" />
                  <MuiButton
                    data-testid="previous-button"
                    color="primary"
                    variant="text"
                    fullWidth
                    onClick={() => props.history.push('/u/manage-account')}
                  >
                    Cancel
                  </MuiButton>
                </MuiBox>
              </div>
            );
          }}
        </Formik>
      </MuiContainer>
      <ProxyFormFooter />
    </MuiBox>
  );
};

const mapDispatch = (dispatch: RootDispatch) => ({
  clearProxies: () => dispatch(clearRequestedProxies()),
  shareServiceNow: (data: any) => dispatch(shareServiceNow(data))
});

const mapStateToProps = (state: RootState) => ({
  consumerId: consumerIdSelector(state),
  profileConsumer: profileConsumerSelector(state),
  minorsData: profileRequestedProxies(state),
  currentUrl: routerSelectors.currentLocationPathNameSelector(state),
  referringUrl: routerSelectors.previousLocationPathNameSelector(state),
  isSendingIncident: serviceNowSendingSelector(state)
});

export default connect(mapStateToProps, mapDispatch)(ProxyReviewContactScreen as ComponentType);
