import React from 'react';
import { Color } from 'modules/styles/colors';

export default {
  svg: (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none" viewBox="0 0 15 15">
      <path
        stroke={Color.primary}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M13 5.905l-5.854 6.012A3.553 3.553 0 014.6 13c-.955 0-1.87-.39-2.546-1.083A3.748 3.748 0 011 9.303c0-.98.38-1.92 1.054-2.614l4.809-4.937a2.4 2.4 0 01.779-.552 2.347 2.347 0 012.644.522c.226.231.404.506.524.81a2.521 2.521 0 01-.553 2.705l-4.808 4.94c-.227.224-.53.349-.844.346a1.184 1.184 0 01-.839-.36 1.249 1.249 0 01-.351-.861 1.25 1.25 0 01.336-.867l4.809-4.94"
      ></path>
    </svg>
  ),
  viewBox: '0 0 15 15'
};
