import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import { useHistory } from 'react-router';
import {
  MuiBox,
  MuiContainer,
  MuiList,
  MuiPaper,
  MuiListItem,
  MuiTypography,
  MuiRadioGroup,
  MuiRadio,
  MuiFormControlLabel
} from 'theme/material-ui';
import FilterCalendar from 'components/common/FilterModal/FilterCalendar';
import ListActionsRow from 'components/common/ListActionsRow/ListActionsRow';
import { FilterDrawer } from 'components/FilterDrawer';
import { Drawer } from 'components/common/Drawer';
import { ListItem } from 'components/UI/ListItem/ListItem';
import Spinner from 'components/UI/Spinner/Spinner';
import { useProxySwitcherEffect } from 'hooks/useProxySwitcherEffect';
import { RootState, RootDispatch } from 'store/types';
import { Nullable } from 'modules/types/common';
import {
  getClinicNotesList,
  applyClinicNotesListFilters,
  resetClinicNotesListFilters,
  updateFilterOptions,
  updateDateRange,
  updateSort
} from 'store/clinicNotes/actions';
import {
  clinicNotesListDataSelector,
  clinicNotesListFetchingSelector,
  clinicNotesListErrorSelector,
  clinicNotesListFilterOptions,
  clinicNotesListSort,
  clinicNotesListFilters
} from 'store/clinicNotes/selectors';
import {
  ClinicNotesListItem,
  ClinicNotesFilterOptions,
  ClinicNotesFilterOption,
  ClinicNotesSortBy,
  ClinicNotesFilter
} from 'store/clinicNotes/types';
import { IconSize } from 'modules/styles/variables';

interface ClinicNotesViewAllProps {
  data: ClinicNotesListItem[];
  loading: boolean;
  error: Error;
  dispatch: RootDispatch;
  filterOptions: Nullable<ClinicNotesFilterOptions>;
  filters: ClinicNotesFilter[];
  sortBy: ClinicNotesSortBy | string;
}

export const ClinicNotesViewAll = ({
  data,
  loading,
  error,
  dispatch,
  filterOptions,
  filters,
  sortBy
}: ClinicNotesViewAllProps) => {
  const history = useHistory();
  const handleOnPress = (clinicNote: ClinicNotesListItem) =>
    history.push(`/u/health-record/clinic-notes-and-documents/${clinicNote?.description}`, {
      clinicNote
    });
  const [filterOpen, setFilterOpen] = useState(false);
  const [sortOpen, setSortOpen] = useState(false);
  const [sortByValue, setSortByValue] = useState<ClinicNotesSortBy | string>(sortBy);
  const dateRangeOptions = filterOptions?.dateTime?.[3];

  useProxySwitcherEffect(() => {
    dispatch(getClinicNotesList());
  }, []);

  useEffect(() => {
    if (!data) {
      history.push('/u/health-record');
    }
  }, []);

  const handleChange = (item: ClinicNotesFilterOption, checked: boolean) => {
    dispatch(updateFilterOptions(item.id, checked, item.filterType));
  };

  const handleApply = () => {
    dispatch(applyClinicNotesListFilters());
    setFilterOpen(false);
  };

  const handleClear = () => {
    dispatch(resetClinicNotesListFilters());
    setFilterOpen(false);
  };

  const handleApplySort = () => {
    dispatch(updateSort(sortByValue));
    setSortOpen(false);
  };

  return (
    <>
      <MuiBox alignContent="center">
        <ListActionsRow
          filterActive={filters?.length > 0}
          sortActive
          justifyContent="flex-end"
          sortTitleEndText={`:${sortBy}`}
          onFilterPress={() => setFilterOpen(true)}
          onSortPress={() => setSortOpen(true)}
        />
        <MuiContainer maxWidth="lg">
          <FilterDrawer
            open={filterOpen}
            onClose={() => setFilterOpen(false)}
            onApply={handleApply}
            onClear={handleClear}
          >
            <FilterDrawer.Panel title="Document Type">
              <FilterDrawer.CheckBoxGroup
                list={filterOptions?.noteCategories}
                renderLabel={item => item.noteCategory}
                checked={item => item.checked}
                handleChange={handleChange}
              />
            </FilterDrawer.Panel>
            <FilterDrawer.Panel title="Date Range">
              <FilterDrawer.CheckBoxGroup
                list={filterOptions?.dateTime}
                renderLabel={item => item.title}
                checked={item => item.checked}
                handleChange={handleChange}
              />
              {dateRangeOptions?.checked ? (
                <MuiBox display="flex" flexDirection="row" paddingTop="15px">
                  <FilterCalendar
                    value={dateRangeOptions?.value?.from}
                    label="From"
                    handleChange={date =>
                      dispatch(updateDateRange(date, dateRangeOptions?.value?.to))
                    }
                  />
                  <FilterCalendar
                    value={dateRangeOptions?.value?.to}
                    label="To"
                    handleChange={date =>
                      dispatch(updateDateRange(dateRangeOptions?.value?.from, date))
                    }
                  />
                </MuiBox>
              ) : null}
            </FilterDrawer.Panel>
          </FilterDrawer>

          <Drawer
            title="Sort"
            open={sortOpen}
            onClose={() => setSortOpen(false)}
            onApply={handleApplySort}
          >
            <MuiBox p={3}>
              <MuiRadioGroup
                aria-label="sort"
                name="sort"
                value={sortByValue}
                onChange={(_, value: string) => {
                  setSortByValue(value);
                }}
              >
                <MuiFormControlLabel
                  value={ClinicNotesSortBy.date}
                  data-testid="sort-by-date"
                  control={<MuiRadio color="primary" />}
                  label="Date"
                />
                <MuiFormControlLabel
                  value={ClinicNotesSortBy.name}
                  data-testid="sort-by-name"
                  control={<MuiRadio color="primary" />}
                  label="Name"
                />
              </MuiRadioGroup>
            </MuiBox>
          </Drawer>
          <MuiBox my={6}>
            <MuiPaper elevation={2}>
              <MuiList>
                {loading ? (
                  <MuiBox
                    p={5}
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <MuiTypography>Updating your clinic notes and documents...</MuiTypography>
                    <Spinner />
                  </MuiBox>
                ) : null}
                {error ? (
                  <MuiBox display="flex" alignItems="center" justifyContent="center">
                    <MuiTypography>
                      There was an issue loading your clinic notes and documents.
                    </MuiTypography>
                  </MuiBox>
                ) : null}
                <MuiListItem divider>
                  <MuiBox
                    display="flex"
                    flex="1"
                    justifyContent="space-between"
                    alignItems="center"
                    width="100%"
                  >
                    <MuiBox width="80%">
                      <MuiTypography color="textPrimary" fontWeight="bold">
                        Name
                      </MuiTypography>
                    </MuiBox>
                  </MuiBox>
                </MuiListItem>
                {data?.map(item => (
                  <ListItem
                    accessibilityLabel={`Tap to view ${item?.noteType}`}
                    testID="clinic-notes-list"
                    accessible
                    title={item?.noteType || ''}
                    body={item?.noteCategory || ''}
                    secondaryBody={dayjs(item?.indexDate || '').format('MM/DD/YYYY')}
                    icon="ChevronRight"
                    iconSize={IconSize.xmedium}
                    key={item?.id}
                    disabled={loading}
                    onPress={() => handleOnPress(item)}
                    titleProps={{}}
                  />
                ))}
              </MuiList>
            </MuiPaper>
          </MuiBox>
        </MuiContainer>
      </MuiBox>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  data: clinicNotesListDataSelector(state),
  loading: clinicNotesListFetchingSelector(state),
  error: clinicNotesListErrorSelector(state),
  filterOptions: clinicNotesListFilterOptions(state),
  filters: clinicNotesListFilters(state),
  sortBy: clinicNotesListSort(state)
});

export default connect(mapStateToProps)(ClinicNotesViewAll);
