import React from 'react';
import { useField, FieldHookConfig } from 'formik';
import { ConnectCareDatePicker, ConnectCareDatePickerProps } from '../ConnectCareDatePicker';

export type ConnectCareFormDatePickerProps = FieldHookConfig<Date> & {
  label?: ConnectCareDatePickerProps['label'];
  placeholder?: ConnectCareDatePickerProps['placeholder'];
  ariaLabel?: ConnectCareDatePickerProps['ariaLabel'];
  disabled?: ConnectCareDatePickerProps['disabled'];
  required?: ConnectCareDatePickerProps['required'];
};

export function ConnectCareFormDatePicker(props: ConnectCareFormDatePickerProps) {
  const { label, placeholder, ariaLabel, disabled, required, ...rest } = props;
  const [field, meta, helpers] = useField(rest);
  const error = meta.touched && !!meta.error;
  const helperText = error && meta.error;

  return (
    <ConnectCareDatePicker
      {...field}
      required={required}
      error={error}
      helperText={helperText || ' '}
      label={label}
      placeholder={placeholder}
      ariaLabel={ariaLabel}
      disabled={disabled}
      onChange={(date: any) => helpers.setValue(date)}
    />
  );
}
