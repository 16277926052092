import * as React from 'react';

export default {
  svg: (
    <g>
      <style>{'.st0{opacity:.8348}.st1{fill:#727272}'}</style>
      <g className="st0">
        <defs>
          <path
            id="SVGID_1_3"
            className="st0"
            d="M150 288.7c-77.7 0-140.9-63.2-140.9-140.9C9.1 70.2 72.3 7 150 7s140.9 63.2 140.9 140.9c0 77.6-63.2 140.8-140.9 140.8zm0-267.8c-70 0-126.9 56.9-126.9 126.9S80 274.7 150 274.7s126.9-56.9 126.9-126.9S220 20.9 150 20.9z"
          />
        </defs>
        <use
          xlinkHref="#SVGID_1_3"
          style={{
            overflow: 'visible',
            fill: '#9e9e9e'
          }}
        />
        <clipPath id="SVGID_00000025402054191190663070000006594616998488608446_">
          <use
            xlinkHref="#SVGID_1_3"
            style={{
              overflow: 'visible'
            }}
          />
        </clipPath>
      </g>
      <circle className="st1" cx={84.1} cy={198.7} r={16.1} />
      <circle className="st1" cx={144.1} cy={158.1} r={8.3} />
      <path
        d="M250.8 141.7c-4.3-40.4-27.6-95-59.3-81.2-31.7 13.8 5.2 42.5 9 84.1 3.7 41.6-20.5 67.8 6 81.7 26.5 13.9 48.6-44.2 44.3-84.6z"
        style={{
          fillRule: 'evenodd',
          clipRule: 'evenodd',
          fill: '#999393'
        }}
      />
      <circle className="st1" cx={152.7} cy={126.4} r={16.8} />
      <circle
        cx={96.7}
        cy={118.5}
        r={26.6}
        style={{
          fill: '#999393'
        }}
      />
      <circle className="st1" cx={116.8} cy={66.4} r={19.9} />
    </g>
  ),
  viewBox: '0 0 300 300'
};
