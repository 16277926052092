import { makeStyles, createStyles } from '@material-ui/core/styles';
import { spacings } from 'modules/utils/StylesUtils';
import { Color } from 'modules/styles/colors';

interface UseStylesProps {
  row?: boolean;
  spacing?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
}

const BACKGROUND = Color.foreColor;

export default makeStyles(theme => {
  const stepperBase: React.CSSProperties = {
    width: '100%',
    height: 'auto',
    backgroundColor: BACKGROUND
  };

  return createStyles({
    steppersHeader: {
      ...stepperBase,
      padding: spacings(3, 2)
    },
    steppersFooter: {
      ...stepperBase,
      maxWidth: 275,
      margin: 'auto',
      backgroundColor: Color.baseColor,
      right: 0,
      bottom: 0,
      left: 0,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(3),
      '& > button': {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
      }
    },
    formControl: {
      display: 'flex'
    },
    formLabel: {
      transform: 'translate(14px, -6px) scale(0.75)',
      transformOrigin: 'top left',
      padding: 0,
      position: 'absolute',
      top: 0,
      left: 0
    },
    radioGroup: (props?: UseStylesProps) => ({
      flexDirection: props?.row ? 'row' : 'column',
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2)
    })
  });
});
