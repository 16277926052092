import React from 'react';
import { AnyAction } from 'redux';
import { FormikHelpers } from 'formik';
import {
  MuiBox,
  MuiGrid,
  MuiTypography,
  MuiCard,
  MuiCardContent,
  MuiDivider
} from 'theme/material-ui';
import { connect } from 'react-redux';
import { ConnectCareForm, ConnectCareButton } from 'components/ConnectCare';
import { useSnack } from 'components/Snack';
import visitSummary from 'modules/constants/amwell/visitSummary';

import { RootDispatch } from 'store/types';
import { ConsumerFeedbackQuestion, ActivityStatus } from 'store/amwell/types';
import { submitVisitFeedback, setActivityStatus } from 'store/amwell/actions';

import { FontSize, FontWeight, Spacing } from 'modules/styles/variables';
import { Color } from 'modules/styles/colors';

interface FeedbackValues {
  providerRating: number;
  engagementRating: number;
  feedbackQuestion: string;
  feedbackAnswer: string;
}

export interface Props {
  feedbackQuestion: ConsumerFeedbackQuestion;
  submitVisitFeedback: (values: FeedbackValues) => Promise<any>;
  setEndVisitActivityStatus: () => void;
}

export const isFormFilled = ({
  providerRating,
  engagementRating,
  feedbackAnswer
}: Partial<FeedbackValues>): boolean =>
  !(!!providerRating && !!engagementRating && !!feedbackAnswer);

export function ConnectCareVisitSummaryEndFeedback(props: Props) {
  const { create } = useSnack();

  const handleSubmit = async (values: FeedbackValues, actions: FormikHelpers<FeedbackValues>) => {
    actions.setSubmitting(true);

    const feedbackValues: FeedbackValues = {
      ...values,
      providerRating: +values.providerRating,
      engagementRating: +values.engagementRating,
      feedbackAnswer: values.feedbackAnswer || 'N/A'
    };

    props
      .submitVisitFeedback(feedbackValues)
      .then((res: AnyAction) => {
        actions.setSubmitting(false);

        if (res.error) {
          create(visitSummary.SUBMIT.ERROR.title, visitSummary.SUBMIT.ERROR.severity);
          actions.setStatus({ submitted: false });
        } else {
          create(visitSummary.SUBMIT.SUCCESS.title, visitSummary.SUBMIT.SUCCESS.severity);
          actions.setStatus({ submitted: true });
          props.setEndVisitActivityStatus();
        }
      })
      .catch(() => {
        create(visitSummary.SUBMIT.ERROR.title, visitSummary.SUBMIT.ERROR.severity);
        actions.setStatus({ submitted: false });
        actions.setSubmitting(false);
      });
  };

  const initialValues = {
    providerRating: 0,
    engagementRating: 0,
    feedbackAnswer: '',
    feedbackQuestion: props.feedbackQuestion?.questionText || ''
  };

  return (
    <>
      <MuiCard>
        <MuiCardContent>
          <MuiGrid container item xs={8} style={{ margin: 'auto' }}>
            <ConnectCareForm onSubmit={handleSubmit} initialValues={initialValues}>
              {({ isSubmitting, values, handleChange }) => (
                <MuiGrid container spacing={4}>
                  <MuiGrid item xs={12}>
                    <MuiBox mt={1} marginBottom={Spacing.xSmall}>
                      <MuiTypography
                        color={Color.black}
                        fontSize={FontSize.heading}
                        fontWeight={FontWeight.semibold}
                      >
                        Help us make this service better
                      </MuiTypography>
                    </MuiBox>
                    <MuiDivider />
                  </MuiGrid>
                  <MuiGrid item xs={12}>
                    <ConnectCareForm.RatingStars
                      label="Rate your provider:"
                      name="providerRating"
                      value={values.providerRating}
                      setValue={handleChange('providerRating')}
                    />
                  </MuiGrid>
                  <MuiGrid item xs={12}>
                    <ConnectCareForm.RatingStars
                      label="Rate your overall experience:"
                      name="engagementRating"
                      value={values.engagementRating}
                      setValue={handleChange('engagementRating')}
                    />
                  </MuiGrid>
                  {props.feedbackQuestion?.show ? (
                    <MuiGrid item xs={12}>
                      <MuiBox mb={1}>
                        <MuiTypography gutterBottom>
                          {props.feedbackQuestion.questionText}
                        </MuiTypography>
                      </MuiBox>
                      <ConnectCareForm.Select
                        name="feedbackAnswer"
                        items={props.feedbackQuestion.responseOptions}
                        itemToLabel={(item: string) => item}
                        itemToValue={(item: string) => item}
                      />
                      <MuiDivider />
                    </MuiGrid>
                  ) : null}
                  <MuiGrid item xs={12} style={{ textAlign: 'center' }}>
                    <ConnectCareButton
                      fullWidth
                      loading={isSubmitting}
                      disabled={isFormFilled(values)}
                      type="submit"
                    >
                      Submit
                    </ConnectCareButton>
                  </MuiGrid>
                </MuiGrid>
              )}
            </ConnectCareForm>
          </MuiGrid>
        </MuiCardContent>
      </MuiCard>
    </>
  );
}

const mapDispatchToProps = (dispatch: RootDispatch) => ({
  submitVisitFeedback: (values: FeedbackValues) => dispatch(submitVisitFeedback(values)),
  setEndVisitActivityStatus: () => dispatch(setActivityStatus(ActivityStatus.END_VISIT))
});

export { ConnectCareVisitSummaryEndFeedback as ConnectCareVisitSummaryEndFeedbackComponent };
export default connect(null, mapDispatchToProps)(ConnectCareVisitSummaryEndFeedback);
