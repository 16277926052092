import { History } from 'lib/history';
import * as accountSelectors from 'store/account/selectors';
import * as profileSelectors from 'store/profile/selectors';
import * as accountActions from 'store/account/actions';
import ProxyWarning from 'modules/constants/eVisit/proxyWarning';
import Logger from 'services/Logger';
import analyticsService, { AnalyticsEvent } from 'services/AnalyticsService';
import { Confirm } from 'components/ConfirmDialog/ConfirmDialog';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootDispatch } from 'store/types';
import useNavigationAnalytics from 'hooks/useNavigationAnalytics';
import { EVISITS_ROUTES } from '../constants';

export interface Props {
  setIsLoading: (bool: boolean) => void;
}

export interface OnSwitchProps {
  setIsLoading: (bool: boolean) => void;
  consumerId: string;
  dispatch: RootDispatch;
  history: History;
  eVisitFrontDoorStarted: () => void;
}

export const onSwitchError = async (errorMessage: string) => {
  Logger.error(ProxyWarning.ERROR.LOGGER.title, errorMessage);
  await Confirm.close();
  Confirm.show(
    ProxyWarning.ERROR.title,
    ProxyWarning.ERROR.description,
    null,
    ProxyWarning.ERROR.severity,
    {
      text: ProxyWarning.ERROR.text,
      onClick: () => null
    }
  );
};

export const onSwitch = async ({
  setIsLoading,
  consumerId,
  dispatch,
  history,
  eVisitFrontDoorStarted
}: OnSwitchProps) => {
  if (!consumerId) {
    onSwitchError(ProxyWarning.ERROR.LOGGER.consumerNotFound);
  } else {
    setIsLoading(true);
    const res: any = await dispatch(accountActions.switchAccount(consumerId));
    setIsLoading(false);

    if (res?.error) {
      onSwitchError(res.error);
      return;
    }

    analyticsService.logEvent(AnalyticsEvent.AccountSwitched, {
      source: ProxyWarning.SUCCESS.LOGGER.source
    });

    eVisitFrontDoorStarted();

    history.push(EVISITS_ROUTES.FRONTDOOR_OVERVIEW);
  }
};

export function useEVisitProxyWarning({ setIsLoading }: Props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const currentConsumer = useSelector(accountSelectors.currentAccountConsumerSelector);
  const profileConsumer = useSelector(profileSelectors.profileConsumerSelector);
  const eVisitFrontDoorStarted = useNavigationAnalytics(AnalyticsEvent.EVisitFrontDoorStart);

  const mainAccountFirstName = profileConsumer?.firstName;
  const consumerId = profileConsumer?.consumerId;
  const currentAccountFirstName = currentConsumer?.firstName;

  const showDialogCallback = () => {
    Confirm.show(
      ProxyWarning.MODAL_TEXT.title,
      ProxyWarning.MODAL_TEXT.slug({ currentAccountFirstName, mainAccountFirstName }),
      '',
      ProxyWarning.MODAL_TEXT.severity,
      {
        text: ProxyWarning.MODAL_TEXT.confirm,
        onClick: () =>
          onSwitch({ setIsLoading, dispatch, consumerId, history, eVisitFrontDoorStarted }),
        skipOnClose: true
      },
      {
        text: ProxyWarning.MODAL_TEXT.decline,
        onClick: () => null
      },
      {
        cancelable: false,
        closeIcon: false
      }
    );
  };

  return showDialogCallback;
}
