import React from 'react';

import { Color } from 'modules/styles/colors';

export default {
  svg: (
    <g
      transform="translate(0.000000,493.000000) scale(0.100000,-0.100000)"
      stroke="none"
      fill={Color.white}
    >
      <path
        d="M2150 4904 c-550 -75 -1070 -336 -1445 -726 -192 -199 -327 -390
-446 -633 -86 -176 -135 -309 -179 -484 -58 -236 -64 -293 -65 -596 0 -251 3
-294 23 -410 95 -523 322 -957 696 -1331 268 -267 545 -445 891 -572 269 -98
540 -145 840 -145 331 0 605 51 905 169 325 129 604 317 860 580 140 145 238
270 336 430 91 151 81 128 479 1009 196 435 361 798 367 808 8 16 -3 17 -208
15 l-218 -3 -148 -355 c-81 -195 -189 -464 -240 -598 -50 -134 -94 -239 -98
-235 -4 4 -62 168 -129 363 -67 195 -159 461 -204 590 l-83 235 -243 3 c-229
2 -243 1 -239 -15 3 -10 152 -380 332 -822 l327 -804 -37 -56 c-339 -512 -873
-852 -1472 -935 -223 -31 -543 -17 -758 34 -563 131 -1058 499 -1343 995 -277
481 -353 1035 -216 1571 185 726 765 1312 1486 1503 206 55 292 65 544 65 252
0 338 -10 544 -65 563 -149 1061 -553 1322 -1071 l49 -98 195 0 c107 0 195 2
195 5 0 2 -22 57 -50 120 -191 446 -540 849 -960 1110 -292 180 -642 304 -993
350 -148 19 -472 18 -617 -1z"
      />
      <path
        d="M15920 3730 l0 -210 -215 0 -215 0 0 -205 0 -205 215 0 215 0 0 -210
0 -210 200 0 200 0 0 210 0 210 215 0 215 0 0 205 0 205 -215 0 -215 0 0 210
0 210 -200 0 -200 0 0 -210z"
      />
      <path
        d="M1000 2525 l0 -1205 225 0 225 0 2 872 3 873 380 -520 381 -520 45
-3 46 -3 391 522 392 522 0 -871 0 -872 230 0 230 0 0 1205 0 1205 -262 -1
-263 0 -373 -515 c-205 -283 -376 -514 -380 -513 -4 0 -175 232 -380 515
l-373 514 -259 0 -260 0 0 -1205z"
      />
      <path
        d="M5660 2445 l0 -1205 225 0 225 0 0 490 0 490 595 0 595 0 0 -490 0
-490 220 0 220 0 0 1205 0 1205 -220 0 -220 0 0 -510 0 -510 -595 0 -595 0 0
510 0 510 -225 0 -225 0 0 -1205z"
      />
      <path
        d="M11910 2450 l0 -1200 205 0 205 0 0 1200 0 1200 -205 0 -205 0 0
-1200z"
      />
      <path
        d="M13880 2450 l0 -1200 205 0 205 0 0 443 c0 254 5 470 10 507 22 135
116 277 219 329 162 83 366 58 475 -59 104 -111 106 -126 106 -724 l0 -496
210 0 210 0 0 488 c0 294 -4 523 -11 577 -25 200 -92 357 -199 466 -118 119
-243 169 -441 177 -222 8 -358 -40 -501 -176 l-78 -75 0 472 0 471 -205 0
-205 0 0 -1200z"
      />
      <path
        d="M13015 3395 c-110 -13 -201 -24 -202 -24 -2 -1 -3 -98 -3 -216 l0
-215 -160 0 -160 0 0 -180 0 -180 159 0 160 0 4 -447 c4 -496 4 -499 73 -635
58 -115 179 -215 301 -247 140 -38 337 -31 486 15 45 15 84 28 85 29 2 2 -22
82 -54 178 l-57 174 -51 -20 c-66 -24 -181 -33 -230 -17 -49 16 -92 54 -119
104 -22 40 -22 50 -25 454 l-3 412 235 0 236 0 0 180 0 180 -230 0 -230 0 0
240 c0 132 -3 239 -7 239 -5 -1 -98 -12 -208 -24z"
      />
      <path
        d="M8710 2984 c-76 -10 -209 -48 -276 -79 -231 -106 -387 -293 -457
-550 -19 -69 -22 -104 -21 -260 0 -213 15 -281 93 -440 124 -252 359 -404 685
-445 295 -37 640 55 818 220 l49 45 -129 129 -130 129 -34 -26 c-45 -35 -149
-83 -223 -102 -33 -9 -109 -19 -170 -22 -193 -9 -323 34 -431 141 -44 44 -67
77 -82 119 -41 108 -116 97 637 97 l659 0 6 31 c9 48 7 195 -4 289 -46 370
-245 611 -578 696 -75 20 -128 26 -237 29 -77 2 -156 1 -175 -1z m292 -375
c152 -32 273 -147 294 -278 l7 -41 -462 0 -461 0 18 48 c50 132 160 228 305
267 63 17 227 19 299 4z"
      />
      <path
        d="M10467 2964 c-335 -60 -554 -255 -653 -579 -27 -86 -28 -100 -28
-295 0 -238 13 -302 88 -455 81 -164 189 -272 351 -350 103 -49 188 -72 318
-86 252 -26 514 80 630 254 l25 37 6 -37 c3 -21 8 -75 12 -120 l6 -83 194 0
194 0 0 845 0 845 -197 -2 -198 -3 -6 -108 c-3 -59 -8 -110 -11 -112 -2 -2
-21 18 -41 44 -128 172 -410 256 -690 205z m415 -387 c127 -46 226 -148 279
-285 27 -72 38 -247 19 -331 -27 -126 -108 -246 -210 -311 -152 -98 -399 -98
-552 -1 -177 113 -263 342 -214 573 43 199 178 333 381 378 72 16 223 4 297
-23z"
      />
    </g>
  ),
  viewBox: '0 0 1675 493'
};
