import {
  DashboardBannerCard,
  DashboardCards
} from 'components/DashboardCarousel/DashboardBannerCard';
import { RTACardInfo, RTASummary } from 'lib/dashboard/types';
import { getRTACardInfo } from 'lib/dashboard/utils';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { patchEvent } from 'store/events/actions';

export interface RTAProps {
  data: RTASummary;
  totalCards: number;
}

export const DashboardCarouselRTA = ({ data, totalCards }: RTAProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id, relatedData, onAnalyticsClick, onAnalyticsDismissedClick } = data;
  const cardInfo: RTACardInfo = getRTACardInfo(relatedData);

  const handlePress = () => {
    onAnalyticsClick();
    history.push(`/u/billing/pending-bill/${id}`);
  };

  const handleClose = () => {
    onAnalyticsDismissedClick();
    dispatch(patchEvent(id, { hide: true }));
  };

  return (
    <DashboardBannerCard
      title={cardInfo.title}
      subTitle={cardInfo.amount}
      secondSubtitle={cardInfo.date}
      actionOne={{
        text: cardInfo.buttonText,
        onClick: handlePress
      }}
      imageName={DashboardCards.rta.imageName}
      textColor={DashboardCards.rta.color}
      background={DashboardCards.rta.background}
      totalCards={totalCards}
      onClosePress={handleClose}
    />
  );
};

export default DashboardCarouselRTA;
