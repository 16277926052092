import React from 'react';
import { SvgProps } from 'components/UI/Svg';
import { Color } from 'modules/styles/colors';

export default (props: SvgProps) => ({
  svg: (
    <g fill="none">
      <path
        d="M12 0C9.62663 0 7.30655 0.703788 5.33316 2.02236C3.35977 3.34094 1.8217 5.21509 0.913451 7.4078C0.00519943 9.60051 -0.232441 12.0133 0.230582 14.3411C0.693605 16.6689 1.83649 18.8071 3.51472 20.4853C5.19295 22.1635 7.33115 23.3064 9.65892 23.7694C11.9867 24.2324 14.3995 23.9948 16.5922 23.0866C18.7849 22.1783 20.6591 20.6402 21.9776 18.6668C23.2962 16.6935 24 14.3734 24 12C24 8.8174 22.7357 5.76515 20.4853 3.51472C18.2348 1.26428 15.1826 0 12 0ZM18.93 8.2L12.08 17.49C12.0003 17.5986 11.8995 17.69 11.7836 17.7587C11.6676 17.8274 11.539 17.8719 11.4055 17.8897C11.2719 17.9074 11.1362 17.898 11.0063 17.862C10.8765 17.8259 10.7553 17.764 10.65 17.68L5.76001 13.77C5.65728 13.6875 5.57187 13.5855 5.5087 13.4698C5.44553 13.3542 5.40583 13.2272 5.39189 13.0962C5.37795 12.9652 5.39004 12.8327 5.42748 12.7063C5.46491 12.58 5.52694 12.4623 5.61001 12.36C5.77617 12.1584 6.01466 12.0298 6.27444 12.0017C6.53421 11.9737 6.79465 12.0485 7.00001 12.21L11.08 15.47L17.32 7C17.4775 6.78914 17.7117 6.64885 17.9719 6.60954C18.2321 6.57023 18.4973 6.63507 18.71 6.79C18.818 6.86736 18.9095 6.96546 18.9792 7.07858C19.0489 7.19171 19.0953 7.31758 19.1158 7.44885C19.1363 7.58012 19.1304 7.71415 19.0985 7.84312C19.0666 7.97209 19.0093 8.09342 18.93 8.2Z"
        fill={props?.color || Color.black}
      />
    </g>
  ),
  viewBox: '0 0 24 24'
});
