import React, { useEffect, useState } from 'react';
import { MuiBox, MuiTypography, CursorMuiBox, dfdDefaultTheme } from 'theme/material-ui';

import { useSelector, useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Svg from 'components/UI/Svg/Svg';
import FlexBox from 'components/UI/Layout/FlexBox';
import UserAvatar from 'components/UI/Avatar';

import { Consumer, Grantor } from 'store/profile/types';
import { avatarDataSelector, avatarDataMapSelector } from 'store/consumerPreferences/selectors';
import { updateConsumerAvatar } from 'store/consumerPreferences/actions';
import { getUserName } from 'lib/user';

import { Color } from 'modules/styles/colors';
import { FontSize, Spacing, IconSize } from 'modules/styles/variables';

import { avatarComponents } from './constants';
import { AccountSelectScreen } from './styled';

import UserSelection from './UserSelection';
import AvatarSelection from './AvatarSelection';
import ChangeAvatar from './ChangeAvatar';

const ManageAvatars = ({ history }: RouteComponentProps) => {
  const [userSelected, setUserSelected] = useState<Consumer | Grantor>();
  const [avatarSelected, setAvatarSelected] = useState('');
  const [currentAvatar, setCurrentAvatar] = useState('');
  const [avatarsAvailable, setAvatarsAvailable] = useState<string[]>();
  const avatarsMap = useSelector(avatarDataMapSelector);
  const avatars = useSelector(avatarDataSelector);
  const isSmallScreen = useMediaQuery(dfdDefaultTheme.breakpoints.down('sm'));
  const dispatch = useDispatch();

  useEffect(() => {
    const currentAvatarsAvailable = avatarComponents.reduce((availableAvatars, avatar) => {
      if (!avatars?.some(avatarUsed => avatarUsed.avatarId === avatar.avatarId)) {
        availableAvatars.push(avatar.fileName);
      }
      return availableAvatars;
    }, [] as string[]);

    const currentUserAvatar = avatarsMap[userSelected?.consumerId];

    if (currentUserAvatar) {
      currentAvatarsAvailable.unshift('Initials');
      setCurrentAvatar(currentUserAvatar);
    } else {
      setCurrentAvatar('Initials');
    }

    setAvatarsAvailable(currentAvatarsAvailable);
  }, [userSelected]);

  const goBack = () => {
    history.goBack();
  };

  const onCancel = () => {
    setUserSelected(undefined);
    setAvatarSelected('');
  };

  const onUserSelect = (user: Consumer | Grantor) => {
    setUserSelected(user);
  };

  const onAvatarSelect = (avatar: string) => {
    setAvatarSelected(avatar);
  };

  const onAvatarChange = async () => {
    let avatarsCopy = [...avatars];
    const avatarSelectedId = avatarComponents.find(avatar => avatar.fileName === avatarSelected)
      ?.avatarId;

    if (avatarSelected === 'Initials') {
      avatarsCopy = avatarsCopy.filter(avatar => avatar.consumerId !== userSelected?.consumerId);
    } else if (currentAvatar === 'Initials') {
      avatarsCopy.push({
        consumerId: userSelected?.consumerId,
        avatarId: avatarSelectedId,
        avatarFileName: avatarSelected
      });
    } else {
      avatarsCopy = avatarsCopy.map(avatar => {
        return avatar.consumerId === userSelected?.consumerId
          ? {
              ...avatar,
              avatarId: avatarSelectedId,
              avatarFileName: avatarSelected
            }
          : avatar;
      });
    }

    await dispatch(
      updateConsumerAvatar(avatarsCopy, avatarSelectedId, currentAvatar, userSelected?.consumerId)
    );
    setUserSelected(undefined);
    setAvatarSelected('');
  };

  if (!avatars) {
    return null;
  }

  return (
    <AccountSelectScreen isSmallScreen={isSmallScreen}>
      <MuiBox>
        <FlexBox flexDirection="row" justifyContent="space-between">
          <CursorMuiBox
            display="flex"
            flexDirection="row"
            alignItems="center"
            onClick={goBack}
            px={Spacing.xSmall}
            py={Spacing.xSmall}
          >
            <Svg
              set="assets"
              name="ArrowLeft"
              accessibilityLabel="hide side navigation"
              size={IconSize.small}
              color={Color.white}
            />
            <MuiBox marginLeft={2}>
              <MuiTypography color={Color.white}>Back</MuiTypography>
            </MuiBox>
          </CursorMuiBox>
          {userSelected ? (
            <FlexBox spacing={Spacing.large} flexDirection="row" alignItems="center">
              <MuiTypography color={Color.white}>{getUserName(userSelected)}</MuiTypography>
              <MuiBox marginLeft={1}>
                <UserAvatar
                  size={40}
                  borderColor="none"
                  avatarUrl={avatarsMap[userSelected.consumerId]}
                  fallback={getUserName(userSelected)}
                  initialsFontSize={FontSize.largeHeading}
                  onAvatarClick={() => {}}
                  data-testid="manage-account-avatar"
                />
              </MuiBox>
            </FlexBox>
          ) : null}
        </FlexBox>
      </MuiBox>
      {!userSelected ? <UserSelection avatarsMap={avatarsMap} onUserSelect={onUserSelect} /> : null}
      {userSelected && !avatarSelected ? (
        <AvatarSelection
          avatarsAvailable={avatarsAvailable}
          userSelected={userSelected}
          onAvatarSelect={onAvatarSelect}
        />
      ) : null}
      {userSelected && avatarSelected ? (
        <ChangeAvatar
          avatarSelected={avatarSelected}
          currentAvatar={currentAvatar}
          onAvatarChange={onAvatarChange}
          userSelected={userSelected}
          cancelChange={onCancel}
        />
      ) : null}
    </AccountSelectScreen>
  );
};

export default ManageAvatars;
