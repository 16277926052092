import React, { Component } from 'react';
import styled from 'styled-components';

import FullStar from 'assets/ConnectCare/star-filled.png';
import HalfStar from 'assets/ConnectCare/star-half.png';
import EmptyStar from 'assets/ConnectCare/star-empty.png';
import { StarMuiGrid, StarMuiBox } from './styled';

interface Props {
  ratings?: number;
  reviews?: number;
}
class Stars extends Component<Props> {
  static defaultProps = {
    reviews: 0
  };

  createStars = () => {
    const stars = [];
    let { ratings } = this.props;
    for (let i = 0; i < 5; i += 1) {
      let img = FullStar;
      if (ratings === 0.5) {
        img = HalfStar;
      }
      if (!ratings || ratings < 0.5) {
        img = EmptyStar;
      }
      stars.push(<StyledImage src={img} key={i} />);
      ratings -= 1;
    }
    return stars;
  };

  render() {
    return (
      <StarMuiGrid {...this.props} alignItems="center">
        <StarMuiBox>{this.createStars()}</StarMuiBox>
      </StarMuiGrid>
    );
  }
}

const StyledImage = styled.img`
  width: 15px;
  height: 15px;
  color: Orange;
`;

export default Stars;
