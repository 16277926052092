import { LazyMuiAvatar } from 'components/LazyAvatar';
import Avatar from 'components/UI/Avatar/Avatar';
import DataLoader from 'components/UI/DataLoader/DataLoader';
import Svg from 'components/UI/Svg/Svg';
import AbsoluteSpinner from 'components/common/AbsoluteSpinner/AbsoluteSpinner';
import { NO_CARE_TEAM_FOUND } from 'modules/constants/Provider';
import { IconSize } from 'modules/styles/variables';
import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { setDoctor } from 'store/booking/actions';
import { ProviderSummary } from 'store/findProvider/types';
import {
  currentConsumerPastDoctorsSelector,
  currentConsumerPcpDoctorsSelector
} from 'store/myDoctors/selectors';
import { RootState } from 'store/types';
import {
  MuiBox,
  MuiButton,
  MuiGrid,
  MuiIconButton,
  MuiPaper,
  MuiTypography,
  dfdDefaultTheme
} from 'theme/material-ui';
import { oc } from 'ts-optchain';
import { MuiGridAvatarWrapper, MuiProviderName, StyledMuiGrid, TagContainer } from './styled';

interface MyProviderListItemProps {
  onDelete?: (provider: ProviderSummary) => void;
  provider: ProviderSummary;
}

const MyProviderListItem = ({ onDelete, provider }: MyProviderListItemProps) => {
  const history = useHistory();
  const specialtyName = oc(provider).primarySpecialty.specialtyName();
  const dispatch = useDispatch();

  const onViewProviderDetail = (provider: ProviderSummary) => {
    dispatch(setDoctor(provider));
    history.push('/u/provider/kyruus/details');
  };

  return (
    <MuiBox
      display="flex"
      flexDirection="row"
      alignItems="flex-start"
      component={MuiPaper}
      style={{ padding: '24px 8px 24px 48px' }}
      px={1}
      py={3}
      my={2}
    >
      <MuiGrid data-testid="past-provider-list-item" container spacing={3} alignItems="flex-start">
        <MuiGridAvatarWrapper item xs={2} lg={1}>
          {provider.providerProfileImageUrl ? (
            <LazyMuiAvatar src={provider.providerProfileImageUrl} />
          ) : (
            <Avatar size="medium" />
          )}
        </MuiGridAvatarWrapper>

        <MuiGrid item xs={7} lg={8}>
          <MuiProviderName>{provider.displayName}</MuiProviderName>

          {specialtyName ? <MuiTypography>{specialtyName}</MuiTypography> : null}
        </MuiGrid>

        {!onDelete ? (
          <StyledMuiGrid item xs={3} lg={3}>
            <MuiIconButton
              aria-label="provider details"
              onClick={() => onViewProviderDetail(provider)}
              style={{ padding: '8px' }}
            >
              <Svg
                name="ChevronRight"
                set="assets"
                size={dfdDefaultTheme.breakpoints.up('sm') ? IconSize.base : IconSize.small}
              />
            </MuiIconButton>
          </StyledMuiGrid>
        ) : null}
      </MuiGrid>
    </MuiBox>
  );
};

interface MyProvidersProps {
  pcpProviders: ProviderSummary[];
  pastProviders: ProviderSummary[];
}

export const MyProviders = ({ pcpProviders = [], pastProviders = [] }: MyProvidersProps) => {
  const [loading] = useState(false);

  const allProviders = [...pcpProviders, ...pastProviders];

  return (
    <MuiBox>
      <MuiBox display="flex" justifyContent="flex-end">
        <MuiButton variant="contained" color="primary" component={Link} to="/u/find-a-doctor">
          Add provider
        </MuiButton>
      </MuiBox>
      <MuiBox>
        {loading ? <AbsoluteSpinner /> : null}
        <DataLoader
          data={allProviders}
          renderNoData={() => (
            <MuiBox p={2}>
              <MuiTypography align="center">{NO_CARE_TEAM_FOUND}</MuiTypography>
            </MuiBox>
          )}
          renderData={() => (
            <>
              {pcpProviders.map(provider => (
                <TagContainer>
                  <span>Primary</span>
                  <MyProviderListItem provider={provider} key={provider.npi} />
                </TagContainer>
              ))}

              {pastProviders.map(provider => (
                <TagContainer>
                  <span>Past</span>
                  <MyProviderListItem provider={provider} key={provider.npi} />
                </TagContainer>
              ))}
            </>
          )}
        />
      </MuiBox>
    </MuiBox>
  );
};

const mapState = (state: RootState) => ({
  pcpProviders: currentConsumerPcpDoctorsSelector(state),
  pastProviders: currentConsumerPastDoctorsSelector(state)
});

export default connect(mapState)(MyProviders);
