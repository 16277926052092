import styled from 'styled-components';
import { Color } from 'modules/styles/colors';
import Snackbar from '@material-ui/core/Snackbar/Snackbar';
import { MuiTypography, MuiButton } from 'theme/material-ui';

export const Container = styled.div`
  flex: 1;
  background-color: ${Color.grayLight3};
  flex-direction: column;
  width: 100%;
`;

export const StyledSnackbar = styled(Snackbar)`
  height: 60px;
  width: 1400px;
  border-radius: 4px;
  background-color: #444444;
`;

export const StyledText = styled(MuiTypography)`
  &.MuiTypography-body1 {
    color: ${Color.white};
    letter-spacing: 0.38px;
    line-height: 2.25;
    padding-left: 10px;
  }
`;

export const NewMessageButton = styled(MuiButton)`
  && {
    background-color: ${Color.white};
    border: solid 1px ${Color.gray};
  }
`;

export const ViewInboxButton = styled(MuiButton)`
  &&,
  &&:hover {
    background-color: ${Color.secondary};
  }
`;
