import React from 'react';
import { FileChip, FileSizeSpan, ChipBox, RetryIcon } from './Styled';
import { FontSize } from 'modules/styles/variables';
import { Icon } from 'components/Icon';
import { ActivityIndicator } from 'react-native';

interface FileUpload {
  file: File;
  isUploading: boolean;
  errorMessage?: string;
  canRetryUpload?: boolean;
}
interface FileChipsProps {
  files: FileUpload[];
  onRemoveFile: (id: number) => void;
  onReuploadFile: (id: number) => void;
}

const FileChips = ({ files, onRemoveFile, onReuploadFile }: FileChipsProps) => {
  const fileChipsToLoad = files.map(({ file, errorMessage, canRetryUpload, isUploading }) => {
    const fileSizeKb = Math.round(file.size / 1024);
    const label = errorMessage ? `${errorMessage} ${file.name}` : file.name;

    let chipIcon = <Icon name="AttachFile" />;

    if (isUploading) {
      chipIcon = <ActivityIndicator size="small" />;
    } else if (errorMessage) {
      chipIcon = <Icon size={FontSize.largeHeading} name="error-outline" />;
    }

    return (
      <ChipBox key={file.lastModified}>
        <FileChip
          icon={chipIcon}
          label={label}
          clickable={false}
          onDelete={() => onRemoveFile(file.lastModified)}
          variant="outlined"
          error={!!errorMessage}
          isUploading={isUploading}
        />
        <FileSizeSpan>{fileSizeKb}k</FileSizeSpan>
        {errorMessage && canRetryUpload ? (
          <RetryIcon name="refresh" onClick={() => onReuploadFile(file.lastModified)} />
        ) : null}
      </ChipBox>
    );
  });

  return <>{fileChipsToLoad}</>;
};

export default FileChips;
