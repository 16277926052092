import styled, { css } from 'styled-components';
import { rgba } from 'polished';
import { FlexBoxColumn } from 'components/UI/Layout/FlexBox';
import { FontSize, ZIndex } from 'modules/styles/variables';
import { Color } from 'modules/styles/colors';
import { dfdDefaultTheme } from 'theme/material-ui';
import { menuWidth, boxShadow } from './WebRTCStyles';

import chatFilled from '../assets/chat-filled.png';
import chatOutlined from '../assets/chat-outlined.png';
import chatNotified from '../assets/chat-notified.png';
import inviteFilled from '../assets/invite-filled.png';
import inviteOutlined from '../assets/invite-outlined.png';
import inviteNotified from '../assets/invite-notified.png';
import noteFilled from '../assets/note-filled.png';
import noteOutlined from '../assets/note-outlined.png';
import noteNotified from '../assets/note-notified.png';

export default styled(FlexBoxColumn)`
  &&& {
    width: ${menuWidth.large}px;
    min-width: ${menuWidth.large}px;
    background: ${Color.foreColor};
    box-shadow: ${boxShadow};
    border-right: 1px solid ${rgba(Color.black, 0.1)};
    z-index: ${ZIndex.low};
    flex: 0;

    ${dfdDefaultTheme.breakpoints.down('md')} {
      width: ${menuWidth.medium}px;
      min-width: ${menuWidth.medium}px;
    }
    ${dfdDefaultTheme.breakpoints.down('sm')} {
      width: ${menuWidth.small}px;
      min-width: ${menuWidth.small}px;
    }
  }
`;

const itemStyles = css`
  height: 88px;
  background-size: 35px 35px;
  width: 100%;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: 16px;
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 16px 0;
  &:hover {
    cursor: pointer;
  }

  ${dfdDefaultTheme.breakpoints.down('md')} {
    height: 80px;
    padding: 16px 0;
    background-size: 30px 30px;
  }
  ${dfdDefaultTheme.breakpoints.down('sm')} {
    height: 68px;
    padding: 8px 0;
    background-size: 25px 25px;
  }
`;

export const WebRTCMenuNotes = styled.div`
  &&& {
    ${itemStyles};
    background-image: url(${noteOutlined});

    &.awsdk-expanded {
      background-image: url(${noteFilled});
    }
    &.awsdk-newContent {
      background-image: url(${noteNotified});
    }
    &.awsdk-newContent.awsdk-expanded {
      background-image: url(${noteOutlined});
    }
  }
`;

export const WebRTCMenuChat = styled.div`
  &&& {
    ${itemStyles};
    background-image: url(${chatOutlined});

    &.awsdk-expanded {
      background-image: url(${chatFilled});
    }
    &.awsdk-newContent {
      background-image: url(${chatNotified});
    }
    &.awsdk-newContent.awsdk-expanded {
      background-image: url(${chatOutlined});
    }
  }
`;

export const WebRTCMenuInvite = styled.div`
  &&& {
    ${itemStyles};
    background-image: url(${inviteOutlined});

    &.awsdk-expanded {
      background-image: url(${inviteFilled});
    }
    &.awsdk-newContent {
      background-image: url(${inviteNotified});
    }
    &.awsdk-newContent.awsdk-expanded {
      background-image: url(${inviteOutlined});
    }
  }
`;

export const WebRTCMenuItemTitle = styled.span`
  &&& {
    font-size: ${FontSize.base}px;
    font-weight: 600;
    text-align: center;
    color: ${Color.grayHue2};

    ${dfdDefaultTheme.breakpoints.down('md')} {
      font-size: ${FontSize.small}px;
    }
    ${dfdDefaultTheme.breakpoints.down('sm')} {
      font-size: ${FontSize.xsmall}px;
    }
  }
`;
