import React, { MouseEvent } from 'react';
import { useHistory } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';

import { Alert } from 'components/Alert';
import ButtonGroup from 'components/UI/Button/ButtonGroup';
import Svg from 'components/UI/Svg/Svg';
import { BorderRadius, FontWeight, IconSize } from 'modules/styles/variables';
import { Color } from 'modules/styles/colors';
import { GlossaryItem } from 'store/glossary/reducers';
import { MuiBox, MuiButton, MuiTypography, MuiContainer, dfdDefaultTheme } from 'theme/material-ui';

export interface CallToActionRowProps {
  hrefDownload?: string;
  sendAuditInfo?: Function;
  downloadTitle?: string;
  definitions?: GlossaryItem[];
  isBillingHome?: boolean;
  isDownloading?: boolean;
  isPatientToolbar?: boolean;
  justifyContent?: 'center' | 'space-around' | 'space-between' | 'flex-end';
  onCallPress?: (e: MouseEvent) => void;
  onDownloadPress?: (e: MouseEvent) => void;
  onReplyPress?: (e: MouseEvent) => void;
  onTimelineFilter?: (e: MouseEvent) => void;
  onTransmitPress?: (e: MouseEvent) => void;
}

const CallToActionRow = (props: CallToActionRowProps) => {
  const {
    definitions,
    isBillingHome,
    isDownloading,
    isPatientToolbar,
    justifyContent,
    onCallPress,
    onDownloadPress,
    onReplyPress,
    onTimelineFilter,
    onTransmitPress,
    hrefDownload,
    sendAuditInfo,
    downloadTitle
  } = props;
  const history = useHistory();
  const isSmallScreen = useMediaQuery(dfdDefaultTheme.breakpoints.down('md'));

  const onDownloadButtonPress = (event: MouseEvent) => {
    if (sendAuditInfo) sendAuditInfo();
    if (onDownloadPress) onDownloadPress(event);
  };

  /** Depending on how many active buttons are, is how the hiding labels feature will choose the breakpoint */
  const activeButtons = [
    onReplyPress,
    onCallPress,
    onDownloadPress,
    onTransmitPress,
    onTimelineFilter,
    isBillingHome
  ].filter(i => i).length;

  return (
    <MuiBox bgcolor={isPatientToolbar ? Color.grayLight2 : null} data-testid="call-to-action-row">
      <MuiContainer maxWidth="lg">
        <MuiBox
          px={2}
          py={2}
          paddingRight={isSmallScreen ? 0 : 2}
          bgcolor={isPatientToolbar ? Color.grayLight2 : null}
          display={!!justifyContent ? 'flex' : null}
          justifyContent={justifyContent || null}
        >
          <ButtonGroup>
            {onReplyPress && (
              <MuiBox>
                <MuiButton
                  color="primary"
                  variant="outlined"
                  borderRadius={BorderRadius.base}
                  style={{ borderColor: Color.transparent }}
                  onClick={onReplyPress}
                  data-testid="reply-button"
                  aria-label="reply"
                  startIcon={
                    <Svg
                      set="assets"
                      name="SNavMessages"
                      fill={Color.secondary}
                      size={IconSize.xmedium}
                    />
                  }
                  hideLabelBelow={activeButtons > 3 ? 'md' : 'sm'}
                >
                  Message
                </MuiButton>
              </MuiBox>
            )}
            {onCallPress && (
              <MuiButton
                color="primary"
                variant="outlined"
                borderRadius={BorderRadius.base}
                style={{ borderColor: Color.transparent }}
                onClick={onCallPress}
                data-testid="call-button"
                aria-label="phone"
                startIcon={
                  <Svg
                    set="material"
                    name="phone"
                    color={Color.secondary}
                    size={IconSize.xmedium}
                  />
                }
                hideLabelBelow={activeButtons > 3 ? 'md' : 'sm'}
              >
                Call
              </MuiButton>
            )}
            {(onDownloadPress || hrefDownload) && (
              <MuiButton
                color="primary"
                variant="outlined"
                borderRadius={BorderRadius.base}
                style={{ borderColor: Color.transparent }}
                href={hrefDownload}
                download={downloadTitle || 'download.pdf'}
                onClick={onDownloadButtonPress}
                data-testid="download-button"
                loading={isDownloading}
                startIcon={<Svg set="assets" name="DownloadIcon" size={IconSize.xmedium} />}
                hideLabelBelow={activeButtons > 3 ? 'md' : 'sm'}
              >
                Download
              </MuiButton>
            )}
            {onTransmitPress && (
              <MuiButton
                color="primary"
                variant="outlined"
                borderRadius={BorderRadius.base}
                style={{ borderColor: Color.transparent }}
                onClick={onTransmitPress}
                data-testid="transmit-button"
                aria-label="share"
                startIcon={<Svg set="assets" name="Share" size={IconSize.xmedium} />}
                hideLabelBelow={activeButtons > 3 ? 'md' : 'sm'}
              >
                Share
              </MuiButton>
            )}
            {onTimelineFilter && (
              <MuiButton
                color="primary"
                variant="outlined"
                borderRadius={BorderRadius.base}
                style={{ borderColor: Color.transparent }}
                onClick={onTimelineFilter}
                aria-label="Timeline filter"
              >
                <Svg set="assets" name="Filter" color={Color.secondary} size={IconSize.large} />
              </MuiButton>
            )}
            {isBillingHome && (
              <MuiButton
                data-testid="billing-term-definitions"
                variant="outlined"
                aria-label="Billing term definitions"
                onClick={() => {
                  Alert.alert(
                    'Terms You Should Know',
                    null,
                    [
                      {
                        text: 'View All',
                        onPress: () => {
                          history.push(`/u/billing/glossary`);
                        }
                      }
                    ],
                    { cancelable: true, closeIcon: true },
                    () => {
                      let defs = definitions;
                      if (!Array.isArray(defs)) {
                        defs = [];
                      }
                      return (
                        <>
                          {defs.map(def => {
                            if (!def) {
                              return null;
                            }
                            return (
                              <MuiBox key={def.Term}>
                                <MuiTypography fontWeight={FontWeight.bold}>
                                  {def.Term}:
                                </MuiTypography>{' '}
                                {def.Definition}
                              </MuiBox>
                            );
                          })}
                        </>
                      );
                    }
                  );
                }}
              >
                <Svg set="assets" name="InfoIcon" size={IconSize.large} color={Color.secondary} />
              </MuiButton>
            )}
          </ButtonGroup>
        </MuiBox>
      </MuiContainer>
    </MuiBox>
  );
};

export default CallToActionRow;
