import React from 'react';
import { MuiBox, MuiContainer, MuiTypography, dfdDefaultTheme } from 'theme/material-ui';
import { Color } from 'modules/styles/colors';
import ButtonGroup from 'components/UI/Button/ButtonGroup';
import { FontSize, IconSize, Spacing, ZIndex } from 'modules/styles/variables';
import { Svg } from 'components/UI/Svg';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import ColorCheckbox from '../../UI/ColorCheckbox/ColorCheckbox';
import ListActionsSelectionRowLabel from './ListActionsSelectionRowLabel';
import { CheckboxBadge, CloseButton, StyledMuiButton } from './styled';

export interface ListActionsSelectionRowProps {
  actionButtonText: string;
  buttonIconName: string;
  message: string;
  shortMessage: string;
  onActionPress?: (e: MouseEvent) => void;
  onSelectAllPress?: (e: MouseEvent) => void;
  onRowClose?: (e: MouseEvent) => void;
  isSelectAll?: boolean;
  areSomeSelected?: boolean;
  actionBtnTestID?: string;
  actionBtnAriaLabel?: string;
}

const ListActionsSelectionRow = ({
  actionButtonText,
  buttonIconName,
  message,
  shortMessage,
  onActionPress,
  onSelectAllPress,
  onRowClose,
  isSelectAll,
  areSomeSelected,
  actionBtnTestID,
  actionBtnAriaLabel
}: ListActionsSelectionRowProps) => {
  const isSmallScreen = useMediaQuery(dfdDefaultTheme.breakpoints.down('md'));

  const handleOnClose = (e: MouseEvent) => {
    if (onRowClose) onRowClose(e);
  };

  const visibilityControl = (isSelectAll && areSomeSelected) || areSomeSelected;

  return (
    <MuiBox
      width="100%"
      bgcolor={Color.accentHue3}
      borderBottom={`1px solid ${Color.purple}`}
      paddingRight={Spacing.small}
      position="sticky"
      top={0}
      zIndex={ZIndex.high}
    >
      <MuiContainer maxWidth="lg">
        <MuiBox
          py={Spacing.xSmall}
          paddingRight={isSmallScreen ? Spacing.none : Spacing.xSmall}
          display="flex"
          justifyContent={isSmallScreen ? 'space-between' : 'flex-end'}
          alignContent="center"
          width="100%"
        >
          <ListActionsSelectionRowLabel
            message={message}
            shortMessage={shortMessage}
            hideMessage={visibilityControl}
            isSmallScreen={isSmallScreen}
          />
          <ButtonGroup>
            {onActionPress && visibilityControl ? (
              <StyledMuiButton
                disableElevation
                variant="contained"
                color="default"
                bgColor={Color.purple}
                onClick={onActionPress}
                data-testid={actionBtnTestID}
                aria-label={actionBtnAriaLabel}
                startIcon={
                  <Svg
                    size={IconSize.base}
                    color={Color.white}
                    set="material"
                    name={buttonIconName}
                  />
                }
              >
                {actionButtonText}
              </StyledMuiButton>
            ) : null}
          </ButtonGroup>
          <CheckboxBadge
            onClick={onSelectAllPress}
            data-testid={isSelectAll ? 'deselect-all-button' : 'select-all-button'}
          >
            <ColorCheckbox customColor={Color.purple} checked={isSelectAll} disablePadding />
            <MuiTypography fontSize={FontSize.small} color={Color.black} style={{ paddingLeft: 8 }}>
              Select All
            </MuiTypography>
          </CheckboxBadge>
        </MuiBox>
      </MuiContainer>
      <CloseButton onClick={handleOnClose}>
        <Svg size={IconSize.base} color={Color.white} set="material" name="Clear" />
      </CloseButton>
    </MuiBox>
  );
};

export default ListActionsSelectionRow;
