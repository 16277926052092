import React from 'react';
import { connect } from 'react-redux';
import { RootState, RootDispatch } from 'store/types';
import { visitSummaryDataSelector, visitSummaryFilterSelector } from 'store/visitSummary/selectors';
import { setVisitSummaryFilter } from 'store/visitSummary/actions.web';
import { Spacing } from 'modules/styles/variables';
import { MuiBox, MuiButton, MuiTypography } from 'theme/material-ui';
import { FilterChip } from './styled';
import { VisitSummary, VisitSummaryFilter, VisitType, FilterItem } from 'store/visitSummary/types';
import { capitalizeEachWord } from 'modules/utils/StringUtils';
import dayjs from 'shared/node_modules/dayjs';

enum FilterKey {
  PROVIDER = 'provider',
  LOCATION = 'location',
  START_DATE = 'startDate',
  END_DATE = 'endDate'
}

const getValuesToShow = (
  filters: VisitSummaryFilter,
  keys: (keyof VisitSummaryFilter)[],
  data: VisitSummary[]
): FilterItem[] => {
  return keys.map(key => {
    let value: VisitType | string | undefined | null = filters[key];
    let visit: VisitSummary | undefined;

    switch (key) {
      case FilterKey.LOCATION:
        visit = data.find(visit => visit.locationObject.locationId === filters[key]);
        value = visit?.locationObject.locationName;
        break;
      case FilterKey.PROVIDER:
        visit = data.find(visit => visit.primaryProvider?.providerId === filters[key]);
        value = visit?.primaryProvider?.providerName;
        break;
      case FilterKey.START_DATE:
      case FilterKey.END_DATE:
        value = dayjs(filters[key]).format('MM/DD/YYYY');
        break;
      default:
        break;
    }
    const filterItem: FilterItem = { label: key, value: value || '' };
    return filterItem;
  });
};

const checkFilters = (filters: VisitSummaryFilter) => {
  return Object.values(filters).some(value => !!value);
};

interface Props {
  data: VisitSummary[];
  filter: VisitSummaryFilter;
  dispatch: RootDispatch;
  clearAllFilters: () => void;
}

const FilterTag = ({ data, filter, dispatch, clearAllFilters }: Props) => {
  const filters: VisitSummaryFilter = { ...filter };
  if (!filters || !checkFilters(filters)) return null;

  const handleDelete = (key: keyof VisitSummaryFilter) => {
    dispatch(
      setVisitSummaryFilter({
        ...filters,
        [key]: ''
      })
    );
  };

  const filterKeysWithValue = (Object.keys(filters) as (keyof VisitSummaryFilter)[]).filter(
    key => !!filters[key]
  );
  const filtersWithValue: FilterItem[] = getValuesToShow(filters, filterKeysWithValue, data);

  const filterChipsToRender = filtersWithValue.map((filter: FilterItem) => {
    return (
      <FilterChip
        key={filter.value}
        label={capitalizeEachWord(filter.value)}
        onDelete={() => handleDelete(filter.label as keyof VisitSummaryFilter)}
        clickable={false}
      />
    );
  });

  return (
    <>
      <MuiBox mx={1} display="flex" alignItems="center" justifyContent="space-between">
        <MuiTypography style={{ whiteSpace: 'nowrap' }}>Filtering by:</MuiTypography>
        <MuiButton onClick={clearAllFilters}>Clear all</MuiButton>
      </MuiBox>
      <MuiBox display="flex" paddingBottom={Spacing.small}>
        <MuiBox mx={1}>{filterChipsToRender}</MuiBox>
      </MuiBox>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  data: visitSummaryDataSelector(state),
  filter: visitSummaryFilterSelector(state)
});

export default connect(mapStateToProps)(FilterTag);
