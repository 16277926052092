import Typography from '@material-ui/core/Typography';
import { SpinnerOverlay } from 'components/UI/Spinner/SpinnerModal';
import { Color } from 'modules/styles/colors';
import { ZIndex } from 'modules/styles/variables';
import React from 'react';
import { useSelector } from 'react-redux';
import { faSubmitSendingSelector } from 'store/billing/financialAssistance/createApplication/selectors';
import { getFinancialAssistanceHasPendingSelector } from 'store/billing/selectors';

const DisableWhenWaiting = () => {
  const isSending = useSelector(faSubmitSendingSelector);
  const hasPending = useSelector(getFinancialAssistanceHasPendingSelector);

  return (
    <SpinnerOverlay
      isLoading={isSending || hasPending}
      position="absolute"
      backgroundColor={Color.whiteTransparent}
      color={Color.secondary}
      zIndex={ZIndex.medium - 2}
    >
      <>
        <Typography>Submitting Application</Typography>
        <Typography>Please do not navigate away or close the browser.</Typography>
      </>
    </SpinnerOverlay>
  );
};

export default DisableWhenWaiting;
