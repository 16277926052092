import Screen from 'components/UI/Layout/Screen';
import Typography from 'components/UI/Typography';
import { headingFontSize, largeFontSize, Spacing } from 'modules/styles/variables';
import { AdapterSource } from 'modules/types/common';
import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { getMicrobiologyHistorical, resetTestResultDetails } from 'store/testResults/actions';
import * as testResults from 'store/testResults/selectors';
import { MicrobiologyResult } from 'store/testResults/types';
import { DEFAULT_MICROBIOLOGY_HISTORICAL_NAME } from 'store/testResults/constants';
import { RootState, RootDispatch } from 'store/types';
import { oc } from 'ts-optchain';
import Banner from 'components/UI/Banner/Banner';
import MedHistoryBanner from 'screens/MedicalHistory/Banner';
import Divider from 'components/UI/Divider';
import {
  MuiBox,
  MuiContainer,
  MuiPaper,
  MuiTable,
  MuiTableBody,
  MuiTableCell,
  MuiTableHead,
  MuiTablePagination,
  MuiTableRow
} from 'theme/material-ui';
import dayjs from 'dayjs';
import { orNA } from '../Lab/utils';
import { useLocation } from 'react-router-dom';
import AbsoluteSpinner from 'components/common/AbsoluteSpinner/AbsoluteSpinner';
import useBreadCrumbs from 'hooks/useBreadCrumbs';

export interface Props {
  error: null | Error;
  loading: boolean;
  data: MicrobiologyResult[] | null;
  dispatch: RootDispatch;
}

// Flattens out the data received, to fit component needs
const formatData = (data: MicrobiologyResult[] | null) => {
  if (data) {
    return [...data].reverse();
  }
  return [];
};

// Component
export const MicrobiologyHistoricalScreen = (props: Props) => {
  const { loading, error, data, dispatch } = props;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(12);

  const state = oc(useLocation()).state({});
  const name = oc(state).name(DEFAULT_MICROBIOLOGY_HISTORICAL_NAME);
  const nameNcid = state?.nameNcid || '';
  const source = oc(state).source();
  const adapterSource = oc(state).adapterSource(AdapterSource.UTAH);

  const formattedData = useMemo(() => formatData(data), [data]);
  const { mergeCurrentWithPreviousBreadCrumbs } = useBreadCrumbs();

  useEffect(() => {
    mergeCurrentWithPreviousBreadCrumbs();
  }, []);

  useEffect(() => {
    return () => {
      dispatch(resetTestResultDetails());
    };
  }, []);

  useEffect(() => {
    const params = {
      source,
      nameNcid
    };
    dispatch(getMicrobiologyHistorical(params, adapterSource));
  }, [getMicrobiologyHistorical]);

  const handleChangePage = (event: React.FormEvent<HTMLInputElement>, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.FormEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const renderContent = () => {
    if (loading) {
      return <AbsoluteSpinner />;
    }

    if (error) {
      return (
        <MuiBox flex={1} alignItems="center" justifyContent="center" mt={Spacing.xLarge}>
          <Typography fontSize={largeFontSize} lineHeight={headingFontSize} textAlign="center">
            There was an issue fetching microbiology historical information
          </Typography>
        </MuiBox>
      );
    }

    return (
      <>
        <Banner message={name} />
        <MedHistoryBanner isDownloading={false} />
        <Divider />
        <MuiBox my={5}>
          <MuiContainer maxWidth="lg">
            <MuiBox component={MuiPaper} elevation={2}>
              <MuiBox>
                <MuiTable aria-label="Lab Historical Data">
                  <MuiTableHead>
                    <MuiTableRow>
                      <MuiTableCell>Date</MuiTableCell>
                      <MuiTableCell align="right">Source</MuiTableCell>
                      <MuiTableCell>Results</MuiTableCell>
                      <MuiTableCell>Stains</MuiTableCell>
                    </MuiTableRow>
                  </MuiTableHead>
                  <MuiTableBody>
                    {formattedData
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((item: MicrobiologyResult) => (
                        <MuiTableRow key={item.eventId}>
                          <MuiTableCell component="th" scope="row">
                            {dayjs(item.microbiologyDate).format('MM/DD/YYYY')}
                          </MuiTableCell>
                          <MuiTableCell align="right">{orNA(item.source)}</MuiTableCell>
                          <MuiTableCell align="right">{item.resultList}</MuiTableCell>
                          <MuiTableCell>{orNA(item.stainResult)}</MuiTableCell>
                        </MuiTableRow>
                      ))}
                  </MuiTableBody>
                </MuiTable>
                <MuiTablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={formattedData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </MuiBox>
            </MuiBox>
          </MuiContainer>
        </MuiBox>
      </>
    );
  };

  return <Screen safe>{renderContent()}</Screen>;
};

const mapStateToProps = (state: RootState) => ({
  loading: testResults.microbiologyHistoricalLoadingSelector(state),
  error: testResults.microbiologyHistoricalErrorSelector(state),
  data: testResults.microbiologyHistoricalDataSelector(state)
});

export default connect(mapStateToProps)(MicrobiologyHistoricalScreen);
