import React from 'react';

export default {
  svg: (
    <g>
      <g>
        <g>
          <circle fill="#E9E6FC" strokeWidth={0} cx={40.298} cy={39.64} r={38.5} />
          <path
            fill="#FFFFFF"
            strokeWidth={0}
            d="M40.298,2.14c20.711,0,37.5,16.789,37.5,37.5c0,20.711-16.789,37.5-37.5,37.5s-37.5-16.789-37.5-37.5 C2.798,18.929,19.587,2.14,40.298,2.14 M40.298,0.14c-21.78,0-39.5,17.72-39.5,39.5s17.72,39.5,39.5,39.5s39.5-17.72,39.5-39.5 S62.078,0.14,40.298,0.14L40.298,0.14z"
          />
        </g>
        <g>
          <defs>
            <circle id="SVGID_37_" cx={40.298} cy={39.64} r={37.5} />
          </defs>
          <clipPath id="SVGID_2_26">
            <use xlinkHref="#SVGID_37_" />
          </clipPath>
          <g clipPath="url(#SVGID_2_26)">
            <path
              fill="#F2AE75"
              strokeWidth={0}
              d="M56.757,36.236l0.51-6.63c0-8.67-8.287-15.696-16.952-15.696c-8.67,0-16.952,7.026-16.952,15.696 l0.51,6.624c-0.485,0.117-0.923,0.339-1.288,0.653c-0.678,0.578-1.105,1.431-1.105,2.392v3.265c0,1.726,1.39,3.127,3.12,3.137 c0,5.12,3.847,10.073,8.167,13.066v6.461l-2.358,1.017l0.628,21.166h1.73v3.245h15.068v-3.245h1.72l0.628-21.101l-2.348-1.082 v-6.443c4.331-2.991,8.195-7.953,8.195-13.084c1.723-0.012,3.117-1.411,3.117-3.137v-3.265 C59.148,37.801,58.127,36.573,56.757,36.236z"
            />
            <path
              fill="#84D1C9"
              strokeWidth={0}
              d="M78.249,101.3c-0.766-8.279-2.857-16.113-4.089-23.322c-1.202-7.037-11.511-7.039-23.766-11.643 l-0.56,2.103c-0.968,2.775-3.453,7.315-9.35,7.315c-6.654,0-9.192-5.779-9.98-8.283l-0.302-1.135 C17.946,70.94,7.637,70.942,6.435,77.979C5.203,85.188,3.112,93.021,2.346,101.3H78.249z"
            />
            <g>
              <polygon
                fill="#43414D"
                strokeWidth={0}
                points="38.073,77.514 37.371,100.666 43.194,100.666 42.475,77.514  "
              />
              <polygon
                fill="#43414D"
                strokeWidth={0}
                points="42.475,77.486 43.921,74.716 40.295,71.752 36.668,74.716 38.073,77.486  "
              />
            </g>
            <g>
              <path
                fill="#639D97"
                strokeWidth={0}
                d="M32.685,64.715c0,0-4.932,2.456-4.945,2.463l7.924,9.453l4.634-4.879l-5.753-5.316L32.685,64.715z"
              />
              <polygon
                fill="#639D97"
                strokeWidth={0}
                points="47.913,64.717 40.297,71.751 44.931,76.633 52.853,67.175  "
              />
            </g>
            <path
              fill="#E99260"
              strokeWidth={0}
              d="M56.757,36.236l0.51-6.63c0-8.67-8.287-15.696-16.952-15.696c-0.003,0-0.003,22.288-0.003,22.288 c-2.29,4.87-3.767,11.929-3.767,11.929h3.767v13.498c-2.202-0.001-4.958-1.09-7.545-2.882v2.964l15.146,3.011l-0.067-5.964 c-0.019,0.013-0.038,0.024-0.057,0.038c4.351-2.987,8.241-7.965,8.241-13.114c1.723-0.012,3.117-1.411,3.117-3.137v-3.265 C59.148,37.801,58.127,36.573,56.757,36.236z M40.313,61.625c0.005,0,0.009-0.001,0.014-0.001c-0.004,0-0.008,0.001-0.012,0.001 C40.314,61.625,40.314,61.625,40.313,61.625z M46.757,59.456c0.079-0.048,0.158-0.097,0.237-0.146 C46.915,59.36,46.836,59.408,46.757,59.456z M41.061,61.581c0.071-0.007,0.143-0.015,0.215-0.025 C41.204,61.566,41.132,61.574,41.061,61.581z M42.005,61.436c0.031-0.007,0.062-0.013,0.093-0.019 C42.067,61.424,42.036,61.43,42.005,61.436z M42.863,61.219c0.083-0.024,0.166-0.049,0.249-0.075 C43.029,61.169,42.946,61.194,42.863,61.219z M43.861,60.885c0.039-0.015,0.077-0.028,0.116-0.043 C43.938,60.857,43.899,60.871,43.861,60.885z M44.786,60.499c0.084-0.038,0.169-0.077,0.253-0.117 C44.954,60.422,44.87,60.461,44.786,60.499z M45.822,59.99c0.027-0.015,0.055-0.027,0.082-0.042 C45.876,59.962,45.849,59.975,45.822,59.99z"
            />
            <path
              fill="#EC9B68"
              strokeWidth={0}
              d="M35.57,38.007c0.048,1.369-1.023,2.517-2.391,2.565c-1.369,0.048-2.517-1.023-2.565-2.391 c-0.048-1.369,1.023-2.517,2.391-2.565C34.374,35.568,35.523,36.639,35.57,38.007z"
            />
            <path
              fill="#3C2417"
              strokeWidth={0}
              d="M35.57,37.379c0.048,1.369-1.023,2.517-2.391,2.565c-1.369,0.048-2.517-1.023-2.565-2.391 c-0.048-1.369,1.023-2.517,2.391-2.565C34.374,34.94,35.523,36.011,35.57,37.379z"
            />
            <path
              fill="#FFFFFF"
              strokeWidth={0}
              d="M34.554,36.671c0.012,0.342-0.256,0.629-0.598,0.641c-0.342,0.012-0.629-0.256-0.641-0.598 c-0.012-0.342,0.256-0.629,0.598-0.641C34.255,36.061,34.542,36.328,34.554,36.671z"
            />
            <path
              fill="#DB8258"
              strokeWidth={0}
              d="M50.011,38.007c0.048,1.369-1.023,2.517-2.391,2.565c-1.369,0.048-2.517-1.023-2.565-2.391 c-0.048-1.369,1.023-2.517,2.391-2.565C48.815,35.568,49.963,36.639,50.011,38.007z"
            />
            <path
              fill="#3C2417"
              strokeWidth={0}
              d="M50.011,37.379c0.048,1.369-1.023,2.517-2.391,2.565c-1.369,0.048-2.517-1.023-2.565-2.391 c-0.048-1.369,1.023-2.517,2.391-2.565C48.815,34.94,49.963,36.011,50.011,37.379z"
            />
            <path
              fill="#FFFFFF"
              strokeWidth={0}
              d="M48.994,36.671c0.012,0.342-0.256,0.629-0.598,0.641c-0.342,0.012-0.629-0.256-0.641-0.598 c-0.012-0.342,0.256-0.629,0.598-0.641C48.695,36.061,48.982,36.328,48.994,36.671z"
            />
            <path
              fill="#F05D44"
              strokeWidth={0}
              d="M45.964,50.961c0,2.774-2.53,5.023-5.65,5.023c-3.121,0-5.651-2.249-5.651-5.023H45.964z"
            />
            <path
              fill="#FFFFFF"
              strokeWidth={0}
              d="M44.333,52.844h-8.162c-0.347,0-0.628-0.281-0.628-0.628v-1.256h9.418v1.256 C44.961,52.563,44.68,52.844,44.333,52.844z"
            />
            <path
              fill="#3C2417"
              strokeWidth={0}
              d="M59.778,14.782c-0.118-0.941-1.078-0.799-2.024-0.739c-2.952,0.185-3.674-0.81-9.702-4.359 c-6.007-3.537-12.154-2.127-16.251,0.918c-18.844,4.349-7.807,29.929-7.807,29.929s-0.069-13.464,5.759-17.557 c9.759,6.654,19.731,7.016,25.409,3.059c2.19,5.109,1.475,14.499,1.475,14.499s3.769-9.498,1.712-17.903 C59.609,20.493,60.164,17.849,59.778,14.782z"
            />
          </g>
        </g>
      </g>
    </g>
  ),
  viewBox: '0 0 80 80'
};
