import React from 'react';

export default {
  svg: (
    <g>
      <g>
        <g>
          <circle fill="#4A00E2" strokeWidth={0} cx={40} cy={40} r={38.5} />
          <path
            fill="#FFFFFF"
            strokeWidth={0}
            d="M40,2.5c20.711,0,37.5,16.789,37.5,37.5S60.711,77.5,40,77.5S2.5,60.711,2.5,40S19.289,2.5,40,2.5 M40,0.5 C18.22,0.5,0.5,18.22,0.5,40S18.22,79.5,40,79.5S79.5,61.78,79.5,40S61.78,0.5,40,0.5L40,0.5z"
          />
        </g>
        <g>
          <defs>
            <circle id="SVGID_17_" cx={40} cy={40} r={37.5} />
          </defs>
          <clipPath id="SVGID_2_15">
            <use xlinkHref="#SVGID_17_" />
          </clipPath>
          <g id="Female_-_Elder_2_" clipPath="url(#SVGID_2_15)">
            <polygon
              fill="#826AAE"
              strokeWidth={0}
              points="32.267,66.446 47.739,66.446 47.562,72.235 32.438,72.235  "
            />
            <path
              fill="#D8D8D8"
              strokeWidth={0}
              d="M31.779,61.696c2.639-0.021,9.076,0.517,14.706,0c3.903-0.358,7.877-1.805,10.151-4.75 c14.541-18.837-5.679-46.201-26.498-45.623C14.668,11.752,10.787,61.866,31.779,61.696z"
            />
            <path
              fill="#F7CDB9"
              strokeWidth={0}
              d="M54.856,37.522v-6.744c0-7.163-8.292-16.212-14.857-16.212s-14.857,9.049-14.857,16.212v6.744 c-1.823,0-3.301,1.452-3.301,3.243v3.372c0,1.791,1.478,3.243,3.301,3.243c0,4.877,3.846,10.747,8.372,13.998v6.136l-3.243,1.154 l3.243,25.195h12.97l3.243-25.053l-3.243-1.33v-6.103c4.526-3.251,8.372-9.121,8.372-13.998c1.823,0,3.301-1.452,3.301-3.243 v-3.372C58.158,38.974,56.68,37.522,54.856,37.522z"
            />
            <g>
              <path
                fill="#9B88BE"
                strokeWidth={0}
                d="M70.096,105.5c-0.179-6.574-0.389-12.027-0.62-14.066l-0.031-0.274 c-0.693-6.122-1.556-13.742-6.949-18.917c-4.64-4.452-9.995-4.52-14.727-4.571c0-0.001-1.35,5.011-1.35,5.011l-6.415,14.531 l-6.421-14.545L32.71,67.67c-4.732,0.051-10.565,0.12-15.206,4.572c-5.393,5.176-6.256,12.797-6.949,18.921l-0.031,0.271 c-0.231,2.038-0.441,7.492-0.62,14.066H70.096z"
              />
              <g>
                <polygon
                  fill="#D2B9F6"
                  strokeWidth={0}
                  points="24.671,71.635 31.944,74.323 29.089,78.214 40.003,87.213 32.267,66.446  "
                />
                <polygon
                  fill="#D2B9F6"
                  strokeWidth={0}
                  points="55.334,71.635 47.739,66.446 40.003,87.213 50.916,78.214 48.062,74.323  "
                />
              </g>
            </g>
            <path
              fill="#EEAB91"
              strokeWidth={0}
              d="M54.856,37.522v-6.744c0-7.163-8.292-16.212-14.857-16.212v26.264 c-2.408,5.031-3.301,10.376-3.301,10.376L40,51.854v11.997l0,0c-2.062,0-4.295-0.92-6.388-2.403v2.26l12.97,3.914v-6.316 c4.484-3.265,8.275-9.084,8.275-13.927c1.823,0,3.301-1.452,3.301-3.243v-3.372C58.158,38.974,56.68,37.522,54.856,37.522z  M44.063,62.822c0.036-0.017,0.071-0.034,0.107-0.052C44.135,62.788,44.099,62.805,44.063,62.822z M40.733,63.81 c0.072-0.007,0.144-0.018,0.216-0.027C40.876,63.792,40.804,63.803,40.733,63.81z M41.501,63.693 c0.072-0.014,0.143-0.028,0.215-0.044C41.644,63.665,41.573,63.679,41.501,63.693z M42.395,63.473 c0.023-0.007,0.046-0.012,0.069-0.019C42.441,63.461,42.418,63.466,42.395,63.473z M43.266,63.171 c0.021-0.008,0.043-0.018,0.064-0.027C43.309,63.153,43.287,63.163,43.266,63.171z"
            />
            <g>
              <g>
                <path
                  fill="#F6CCB8"
                  strokeWidth={0}
                  d="M34.453,41.819c0.049,1.414-1.057,2.6-2.47,2.649c-1.414,0.049-2.6-1.057-2.649-2.47 s1.057-2.6,2.47-2.649C33.218,39.299,34.404,40.405,34.453,41.819z"
                />
                <path
                  fill="#3C2417"
                  strokeWidth={0}
                  d="M34.453,41.17c0.049,1.414-1.057,2.6-2.47,2.649c-1.414,0.049-2.6-1.057-2.649-2.47 s1.057-2.6,2.47-2.649C33.218,38.651,34.404,39.757,34.453,41.17z"
                />
                <path
                  fill="#FFFFFF"
                  strokeWidth={0}
                  d="M33.403,40.438c0.012,0.353-0.264,0.65-0.618,0.662c-0.353,0.012-0.65-0.264-0.662-0.618 c-0.012-0.353,0.264-0.65,0.618-0.662C33.094,39.808,33.391,40.085,33.403,40.438z"
                />
                <path
                  fill="#3C2417"
                  strokeWidth={0}
                  d="M35.009,39.214c-0.472-1.097-1.683-1.929-3.013-2.071c-0.815-0.089-2.344,0.032-3.658,1.696 c-0.063,0.006-0.121,0.007-0.194-0.001c-0.146-0.013-0.349-0.06-0.548-0.145c-0.403-0.162-0.739-0.502-0.739-0.502 c-0.024-0.022-0.061-0.031-0.092-0.016c-0.04,0.019-0.056,0.067-0.037,0.107c0,0,0.05,0.111,0.147,0.271 c0.095,0.161,0.245,0.369,0.436,0.551c0.189,0.183,0.414,0.339,0.604,0.433c0.094,0.048,0.18,0.082,0.243,0.104 c0.061,0.021,0.125,0.034,0.114,0.032c0.177,0.018,0.338-0.077,0.415-0.225c0.861-1.202,2.012-1.793,3.24-1.661 c1.086,0.116,2.108,0.808,2.485,1.683c0.072,0.165,0.263,0.24,0.426,0.169C35.003,39.569,35.08,39.378,35.009,39.214z"
                />
              </g>
              <g>
                <path
                  fill="#D78163"
                  strokeWidth={0}
                  d="M50.666,41.819c0.049,1.414-1.057,2.6-2.47,2.649c-1.414,0.049-2.6-1.057-2.649-2.47 c-0.049-1.414,1.057-2.6,2.47-2.649C49.43,39.299,50.616,40.405,50.666,41.819z"
                />
                <path
                  fill="#3C2417"
                  strokeWidth={0}
                  d="M50.666,41.17c0.049,1.414-1.057,2.6-2.47,2.649c-1.414,0.049-2.6-1.057-2.649-2.47 c-0.049-1.414,1.057-2.6,2.47-2.649C49.43,38.651,50.616,39.757,50.666,41.17z"
                />
                <path
                  fill="#FFFFFF"
                  strokeWidth={0}
                  d="M49.616,40.438c0.012,0.353-0.264,0.65-0.618,0.662c-0.353,0.012-0.65-0.264-0.662-0.618 c-0.012-0.353,0.264-0.65,0.618-0.662C49.307,39.808,49.603,40.085,49.616,40.438z"
                />
                <path
                  fill="#3C2417"
                  strokeWidth={0}
                  d="M44.991,39.214c0.472-1.097,1.683-1.929,3.012-2.071c0.815-0.089,2.344,0.032,3.658,1.696 c0.063,0.006,0.121,0.007,0.194-0.001c0.146-0.013,0.349-0.06,0.548-0.145c0.403-0.162,0.739-0.502,0.739-0.502 c0.024-0.022,0.061-0.031,0.092-0.016c0.04,0.019,0.056,0.067,0.037,0.107c0,0-0.05,0.111-0.147,0.271 c-0.095,0.161-0.245,0.369-0.436,0.551c-0.189,0.183-0.414,0.339-0.604,0.433c-0.094,0.048-0.18,0.082-0.243,0.104 c-0.061,0.021-0.125,0.034-0.114,0.032c-0.177,0.018-0.338-0.077-0.415-0.225c-0.861-1.202-2.012-1.793-3.24-1.661 c-1.086,0.116-2.108,0.808-2.485,1.683c-0.072,0.165-0.263,0.24-0.426,0.169C44.997,39.569,44.92,39.378,44.991,39.214z"
                />
              </g>
            </g>
            <g>
              <path
                fill="#F05D44"
                strokeWidth={0}
                d="M45.188,54.25c0,2.547-2.323,4.612-5.188,4.612c-2.865,0-5.188-2.065-5.188-4.612H45.188z"
              />
              <path
                fill="#FFFFFF"
                strokeWidth={0}
                d="M43.618,55.979h-7.35c-0.358,0-0.648-0.29-0.648-0.648V54.25h8.647v1.081 C44.266,55.688,43.976,55.979,43.618,55.979z"
              />
            </g>
            <g>
              <path
                fill="#9B88BE"
                strokeWidth={0}
                d="M24.495,50.759c-0.358,0-0.649-0.29-0.649-0.649v-2.732c0-0.358,0.29-0.648,0.649-0.648 s0.649,0.29,0.649,0.648v2.732C25.144,50.469,24.853,50.759,24.495,50.759z"
              />
              <path
                fill="#9B88BE"
                strokeWidth={0}
                d="M55.505,50.759c-0.358,0-0.649-0.29-0.649-0.649v-2.732c0-0.358,0.29-0.648,0.649-0.648 s0.648,0.29,0.648,0.648v2.732C56.154,50.469,55.864,50.759,55.505,50.759z"
              />
            </g>
            <path
              fill="#E5E5E5"
              strokeWidth={0}
              d="M63.298,40.469c-3.91-5.08-1.673-32.251-23.958-31.04c-22.591,1.227-15.232,25.181-13.932,28.094 c0.877-0.311,5.816-7.849,8.048-13.089C38.948,33.898,56.977,39.362,63.298,40.469z"
            />
          </g>
        </g>
      </g>
    </g>
  ),
  viewBox: '0 0 80 80'
};
