import {
  AdditionalInformation,
  Directions,
  Header
} from 'components/Medications/MedicationDetailsSummary';
import Box from 'components/UI/Layout/Box';
import FlexBox, { FlexBoxColumn } from 'components/UI/Layout/FlexBox';
import Spacer from 'components/UI/Layout/Spacer';
import SpinnerModal from 'components/UI/Spinner/SpinnerModal';
import dayjs from 'dayjs';
import capitalize from 'lodash/capitalize';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import { Spacing, FontSize } from 'modules/styles/variables';
import { objectFromQsParam } from 'modules/utils/UrlUtils';
import { MEDLINE_PLUS_URL } from 'lib/constants/medications';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { NavigationScreenProps } from 'screens/navigation';
import { ProviderSummary } from 'store/findProvider/types';
import { askMedicationDetailsQuestion, getMedicationDetails } from 'store/medications/actions';

import {
  medicationDetailsDataSelector,
  medicationDetailsErrorSelector,
  medicationDetailsLoadingSelector,
  medicationDetailsProviderIdSelector
} from 'store/medications/selectors';
import analyticsService, { AmplitudeEventData, AnalyticsEvent } from 'services/AnalyticsService';
import useNavigationAnalytics from 'hooks/useNavigationAnalytics';
import {
  currentLocationPathNameSelector,
  previousLocationPathNameSelector
} from 'store/router/selectors';
import { Medication, Prescriber } from 'store/medications/types';
import { getProviderRecepientForReply } from 'store/messaging/actions';
import { RootState } from 'store/types';
import { MuiBox, MuiButton, MuiContainer, MuiGrid, useTheme } from 'theme/material-ui';
import { StyledText } from './styled';
import { getDuration } from './utils';
import { patchEventBySourceId } from 'store/events/actions.common';
import { StyledHeader } from 'components/Medications/MedicationDetailsSummary/styled';
import { useMediaQuery } from '@material-ui/core';
import BannerUser from 'screens/MedicalHistory/Banner';
import Banner from 'components/UI/Banner/Banner';
import { Confirm } from 'components/ConfirmDialog/ConfirmDialog';
import { pcpDoctorConsumerSelector } from 'store/myDoctors/selectors';

interface MedicationDetailsContentProps {
  data: Medication;
  onRequestRenewal: () => void;
  onAskQuestion: () => void;
  recipient: ProviderSummary;
  medicationType?: string;
}

interface Match {
  url: string;
  isExact: boolean;
  path: string;
  params: {
    id: string;
    mhpDetails?: string;
  };
}

export const getMedicationNameParts = (fullName: string) => {
  const indexOfFirstParen = fullName.indexOf('(');

  if (indexOfFirstParen === -1) {
    return [capitalize(fullName)];
  }

  const title = capitalize(fullName.slice(0, indexOfFirstParen).trim());
  const subtitle = fullName.slice(indexOfFirstParen);

  return [title, subtitle];
};

const MedicationDetailsContent = ({
  recipient,
  data,
  onRequestRenewal,
  onAskQuestion,
  medicationType
}: MedicationDetailsContentProps) => {
  const {
    name = '',
    dosage = [],
    duration = { start: '', end: '' },
    prescriber = {} as Prescriber
  } = data;
  const { start, end } = duration;
  const getDirections = get(dosage[0], 'patientFriendlyInstructions[0]') || 'N/A';
  const getDosage = get(dosage[0], 'dosage') || 'N/A';
  const getRoute = get(dosage[0], 'route') || 'N/A';
  const startDate = !isNil(start) ? dayjs(start).format('MM/DD/YYYY') : 'N/A';
  const endDate = !isNil(end) ? dayjs(end).format('MM/DD/YYYY') : 'N/A';
  const [title, subtitle] = getMedicationNameParts(name);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const openUrl = () => {
    const titleString = title.split(' ').join('+');
    window.open(`${MEDLINE_PLUS_URL}${titleString}`, '_blank');
  };

  const moreInfoClicked = useNavigationAnalytics(AnalyticsEvent.MedicationMoreInfoClicked);

  const openDialog = () => {
    moreInfoClicked();
    Confirm.show(
      'Visit MedlinePlus for more information about this medication.',
      null,
      null,
      'info',
      { text: 'Visit Site', onClick: openUrl },
      { text: 'Cancel' },
      { closeIcon: true }
    );
  };

  return (
    <>
      <Banner message={`${medicationType} Medications`} />
      <BannerUser />
      <MuiContainer maxWidth="sm">
        <Header title={title} subtitle={subtitle} handleInfoPress={openDialog} />
        <Spacer spacing="large" />
        <Directions
          sectionHeader="Details"
          directions={getDirections}
          dosage={getDosage}
          route={getRoute}
          duration={getDuration(start, end)}
        />
        <Spacer spacing="large" />

        <MuiBox p={1}>
          <StyledHeader fontSize={FontSize.heading} data-testid="applicable-date-range">
            Applicable Date Range
          </StyledHeader>
          <Spacer spacing="medium" />
          <MuiGrid container spacing={1} aria-label="allergies list">
            <MuiGrid item xs={4} data-testid="start-date">
              <MuiBox fontWeight="fontWeightBold" textAlign="left">
                Start Date
              </MuiBox>
            </MuiGrid>
            <MuiGrid item xs={6}>
              <MuiBox style={{ minHeight: '19px' }}>{startDate}</MuiBox>
            </MuiGrid>
            <MuiGrid item xs={4} data-testid="end-date">
              <MuiBox fontWeight="fontWeightBold" textAlign="left">
                End Date
              </MuiBox>
            </MuiGrid>
            <MuiGrid item xs={6}>
              <MuiBox style={{ minHeight: '19px' }}>{endDate}</MuiBox>
            </MuiGrid>
          </MuiGrid>
        </MuiBox>

        <Spacer spacing="large" />
        <AdditionalInformation sectionSubheader="Additional Information" prescriber={prescriber} />
        <Spacer spacing="medium" />
        <FlexBoxColumn
          vSpacingTop={isSmallScreen ? Spacing.none : 64}
          hSpacingRight={isSmallScreen ? Spacing.none : Spacing.largeXLarge}
          maxWidth={isSmallScreen ? '100%' : '349px'}
          alignItems={isSmallScreen ? 'left' : 'center'}
        >
          {recipient ? (
            <MuiButton
              fullWidth
              variant="contained"
              color="primary"
              size="large"
              onClick={onRequestRenewal}
              data-testid="request-a-renewal"
            >
              Request a renewal
            </MuiButton>
          ) : (
            <MuiBox textAlign="center" data-testid="contact-your-physician-for-refill">
              Contact your physician for a refill
            </MuiBox>
          )}
          <Spacer spacing="large" />
          <MuiButton color="primary" onClick={onAskQuestion} fullWidth data-testid="ask-a-question">
            Ask a question
          </MuiButton>
          <Spacer spacing="large" />
        </FlexBoxColumn>
      </MuiContainer>
    </>
  );
};

export interface Props extends NavigationScreenProps {
  data: Medication | {};
  dispatch: Function;
  error: Error | null;
  match: Match;
  isFetching: boolean;
  providerId?: string;
  currentUrl?: string;
  referringUrl?: string;
  medicationType?: string;
}
const ScreenContent = ({ children, ...rest }: { children: React.ReactNode }) => (
  <FlexBox height="100%" alignItems="center" justifyContent="center" {...rest}>
    {children}
  </FlexBox>
);

const MedicationDetailsNoData = () => (
  <ScreenContent>
    <StyledText>Your medication was not found</StyledText>
  </ScreenContent>
);

const MedicationDetailsError = () => (
  <ScreenContent>
    <StyledText>There was an issue fetching your medication details</StyledText>
  </ScreenContent>
);

export const MedicationDetailsSummary = ({
  data,
  dispatch,
  error,
  isFetching,
  match,
  providerId,
  currentUrl,
  referringUrl,
  medicationType,
  pcpList
}: Props) => {
  const [recipient, setRecipient] = useState();
  const [isPCP, setIsPCP] = useState(false);
  const { id, mhpDetails } = match.params;
  let viewState = '';
  if (isFetching) {
    viewState = 'isFetching';
  } else if (error) {
    viewState = 'error';
  } else if (!data || !Object.keys(data).length) {
    viewState = 'noData';
  } else {
    viewState = 'hasData';
  }

  const eventData: AmplitudeEventData = {
    currentUrl,
    referringUrl
  };

  useEffect(() => {
    dispatch(getMedicationDetails(id, objectFromQsParam(mhpDetails)));
    dispatch(patchEventBySourceId(id, { read: true }));
  }, []);

  useEffect(() => {
    if (providerId && id === data?.id) {
      dispatch(getProviderRecepientForReply(providerId)).then(setRecipient);
    }
  }, [providerId]);

  useEffect(() => {
    if (recipient === null && pcpList) {
      dispatch(getProviderRecepientForReply(pcpList[0]?.cernerPersonnelId)).then((recip: any) => {
        setRecipient(recip);
        setIsPCP(true);
      });
    }
  }, [recipient]);

  const handleRequestRenewal = () => {
    if (isPCP) {
      Confirm.show(
        'Your prescribing physician could not be messaged at this time',
        'Would you like to message your primary care provider?',
        null,
        'info',
        { text: 'Yes', onClick: requestRenewalAPI },
        { text: 'No' },
        { closeIcon: true }
      );
    } else {
      requestRenewalAPI();
    }
  };

  const requestRenewalAPI = () => {
    analyticsService.logEvent(AnalyticsEvent.RenewPrescriptionClicked, eventData);
    dispatch(
      askMedicationDetailsQuestion({
        subject: 'Medication Renewal',
        medication: data as Medication,
        config: {
          recipient,
          lockRecipient: true,
          lockSubject: true,
          hideMessage: true,
          onSend: '/u/health-record/renew-prescription'
        },
        returnBackTo: currentUrl
      })
    );
  };

  return (
    <Box>
      {viewState === 'isFetching' ? <SpinnerModal isLoading={isFetching} /> : null}
      {viewState === 'error' ? <MedicationDetailsError /> : null}
      {viewState === 'noData' ? <MedicationDetailsNoData /> : null}
      {viewState === 'hasData' ? (
        <MedicationDetailsContent
          medicationType={medicationType}
          data={data as Medication}
          recipient={recipient}
          onRequestRenewal={handleRequestRenewal}
          onAskQuestion={() => {
            analyticsService.logEvent(AnalyticsEvent.MedicationAskAQuestionClicked, eventData);
            dispatch(
              askMedicationDetailsQuestion({
                returnBackTo: referringUrl,
                medication: data as Medication
              })
            );
          }}
        />
      ) : null}
    </Box>
  );
};

const mapStateToProps = (state: RootState) => ({
  data: medicationDetailsDataSelector(state),
  error: medicationDetailsErrorSelector(state),
  isFetching: medicationDetailsLoadingSelector(state),
  providerId: medicationDetailsProviderIdSelector(state),
  currentUrl: currentLocationPathNameSelector(state),
  referringUrl: previousLocationPathNameSelector(state),
  pcpList: pcpDoctorConsumerSelector(state)
});

export default connect(mapStateToProps)(MedicationDetailsSummary);
