import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { FormikHelpers } from 'formik';
import { animated, useTransition } from 'react-spring';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { RootDispatch, RootState } from 'store/types';
import * as selectors from 'store/amwell/selectors';
import { State } from 'store/amwell/types';
import { updatePaymentMethod, getCountries } from 'store/amwell/actions';
import {
  useTheme,
  MuiDialog,
  MuiDialogTitle,
  MuiDialogContent,
  MuiDialogActions,
  MuiGrid,
  MuiBox,
  MuiTypography,
  MuiAlert,
  MuiButton
} from 'theme/material-ui';
import Svg from 'components/UI/Svg/Svg';
import { ConnectCareForm, ConnectCareIcon, ConnectCareButton } from 'components/ConnectCare';
import { paymentMethod as paymentMethodConfirmations } from 'modules/constants/amwell';
import {
  isAmex,
  isVisa,
  isMasterCard,
  isDiscover,
  cleanNumber,
  regExCity,
  regExAddress,
  minNumber,
  numberLength
} from '../utils';
import { months, years } from './constants';

export interface Props {
  dispatch: RootDispatch;
  open: boolean;
  onClose: () => void;
  usStates: State[];
}

// ▼ Formik ▼ //

const initialValues = {
  cardholderName: '',
  cardNumber: '',
  cvv: '',
  expiryMonth: '',
  expiryYear: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  zipCode: ''
};

const validationSchema = Yup.object().shape({
  cardholderName: Yup.string().required('Please enter a name'),
  cardNumber: Yup.string()
    .required('Please enter a valid card number')
    .test(minNumber(12, 'Please enter a minimum of 12 digits')),
  cvv: Yup.string().when('cardNumber', {
    is: str => isAmex(cleanNumber(str)),
    then: Yup.string()
      .required('Please enter 4 digits for Amex')
      .length(4, 'Please enter 4 digits for Amex'),
    otherwise: Yup.string()
      .required('Please enter 3 digits')
      .length(3, 'Please enter 3 digits')
  }),
  expiryMonth: Yup.string().required('Please select a month'),
  expiryYear: Yup.string().required('Please select a year'),
  address1: Yup.string()
    .required('Please enter an address')
    .matches(regExAddress, 'Please do not use special characters'),
  address2: Yup.string().matches(regExAddress, 'Please do not use special characters'),
  city: Yup.string()
    .required('Please enter a city')
    .matches(regExCity, 'Please do not use special characters or numbers'),
  state: Yup.string().required('Please select a state'),
  zipCode: Yup.string()
    .required('Please enter a valid zip')
    .test(numberLength(5, 'Please enter 5 digits'))
});

// ▼ Animation & Styling ▼ //

const AnimatedDiv = animated.div;
const StyledBox = styled(MuiBox)`
  && {
    height: 40px;
    width: 40px;

    & > div {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      left: 0;
      right: 0;
    }
  }
`;

// ▼ ConnectCarePaymentMethodSelectDialog ▼ //

const getCreditCardType = (cn: string) => {
  const cardNumber = cleanNumber(cn);
  if (isAmex(cardNumber)) return 'amex';
  if (isVisa(cardNumber)) return 'visa';
  if (isMasterCard(cardNumber)) return 'mastercard';
  if (isDiscover(cardNumber)) return 'discover';
  return '';
};

export function ConnectCarePaymentMethodSelectDialog(props: Props) {
  const { dispatch, open, onClose, usStates } = props;
  const theme = useTheme();

  const [showError, setShowError] = useState(false);
  const [cardType, setCardType] = useState('');

  useEffect(() => {
    if (!usStates.length) {
      dispatch(getCountries());
    }
  }, []);

  const slide = useTransition(cardType, t => t, {
    from: { opacity: 0, transform: 'translate3d(0,33%,0)' },
    enter: { opacity: 1, transform: 'translate3d(0%,0,0)' },
    leave: { opacity: 0, transform: 'translate3d(0,-33%,0)' }
  });

  const fade = useTransition(showError, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 }
  });

  const onCardNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCardType(getCreditCardType(e.target.value));
  };

  const handleSubmit = async (
    values: typeof initialValues,
    actions: FormikHelpers<typeof initialValues>
  ) => {
    const updatePaymentMethodForm = { ...values, cardNumber: cleanNumber(values.cardNumber) };
    actions.setSubmitting(true);
    dispatch(updatePaymentMethod({ updatePaymentMethodForm })).then(res => {
      if (res.error) {
        setShowError(true);
      } else {
        actions.resetForm();
        onClose();
      }
      actions.setSubmitting(false);
    });
  };

  return (
    <ConnectCareForm
      onSubmit={handleSubmit}
      isInitialValid={false}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {({ isValid, isSubmitting, submitForm }) => (
        <MuiDialog
          open={open}
          disableBackdropClick={isSubmitting}
          onClose={onClose}
          maxWidth="lg"
          aria-labelledby="payment-method-form-title"
        >
          <MuiDialogTitle id="payment-method-form-title">Payment Information</MuiDialogTitle>

          {/* Payment Method Form */}
          <MuiDialogContent data-testid="payment-method-form">
            <MuiGrid container direction="row" spacing={6}>
              {/* Credit Card Information Section */}
              <MuiGrid item md={6} xs={12}>
                <MuiBox display="flex" alignItems="center" flexWrap="nowrap" mb={4}>
                  <ConnectCareIcon fontSize="large">1</ConnectCareIcon>
                  <MuiBox ml={2}>
                    <MuiTypography variant="h6">Credit Card Information</MuiTypography>
                  </MuiBox>
                </MuiBox>

                <MuiGrid container spacing={2}>
                  <MuiGrid item xs={12}>
                    <ConnectCareForm.TextField
                      label="Name on Card"
                      name="cardholderName"
                      placeholder="John Doe"
                      InputProps={{
                        inputProps: { maxLength: 40 }
                      }}
                    />
                  </MuiGrid>

                  <MuiGrid item xs={12}>
                    <ConnectCareForm.TextField
                      label="Card Number"
                      name="cardNumber"
                      mask="creditCard"
                      placeholder="####-####-####-####"
                      onChange={onCardNumberChange}
                      InputProps={{
                        endAdornment: (
                          <StyledBox>
                            {slide.map(({ item, props, key }) => (
                              <AnimatedDiv key={key} style={props}>
                                <Svg name="CreditCard" size={40}>
                                  {item}
                                </Svg>
                              </AnimatedDiv>
                            ))}
                          </StyledBox>
                        )
                      }}
                    />
                  </MuiGrid>

                  <MuiGrid item xs={12}>
                    <MuiGrid container direction="row" spacing={2}>
                      <MuiGrid item xs={4}>
                        <ConnectCareForm.TextField
                          label="CVV Number"
                          name="cvv"
                          placeholder="CVV"
                          mask="cvv"
                        />
                      </MuiGrid>
                      <MuiGrid item xs={4}>
                        <ConnectCareForm.Select
                          label="Exp Month"
                          name="expiryMonth"
                          placeholder="Month"
                          items={months}
                        />
                      </MuiGrid>
                      <MuiGrid item xs={4}>
                        <ConnectCareForm.Select
                          label="Exp Year"
                          name="expiryYear"
                          placeholder="Year"
                          items={years}
                        />
                      </MuiGrid>
                    </MuiGrid>
                  </MuiGrid>
                </MuiGrid>
              </MuiGrid>

              {/* Billing Address Section */}
              <MuiGrid item md={6} xs={12}>
                <MuiBox display="flex" alignItems="center" flexWrap="nowrap" mb={4}>
                  <ConnectCareIcon fontSize="large">2</ConnectCareIcon>
                  <MuiBox ml={2}>
                    <MuiTypography variant="h6">Billing Address</MuiTypography>
                  </MuiBox>
                </MuiBox>

                <MuiGrid container spacing={2}>
                  <MuiGrid item xs={12}>
                    <ConnectCareForm.TextField
                      label="Address 1"
                      name="address1"
                      placeholder="10 Medical Dr"
                      InputProps={{
                        inputProps: { maxLength: 40 }
                      }}
                    />
                  </MuiGrid>

                  <MuiGrid item xs={12}>
                    <ConnectCareForm.TextField
                      label="Address 2"
                      name="address2"
                      placeholder="Apt, Suite, etc."
                      InputProps={{
                        inputProps: { maxLength: 20 }
                      }}
                    />
                  </MuiGrid>

                  <MuiGrid item xs={12}>
                    <MuiGrid container direction="row" spacing={2}>
                      <MuiGrid item xs={5}>
                        <ConnectCareForm.TextField
                          label="City"
                          name="city"
                          placeholder="Salt Lake City"
                          InputProps={{
                            inputProps: { maxLength: 40 }
                          }}
                        />
                      </MuiGrid>
                      <MuiGrid item xs={4}>
                        <ConnectCareForm.Select
                          label="State"
                          name="state"
                          placeholder="State"
                          items={usStates}
                          itemToValue={(s: State) => (s ? s.code : '')}
                          itemToLabel={(s: State) => (s ? s.name : '')}
                        />
                      </MuiGrid>
                      <MuiGrid item xs={3}>
                        <ConnectCareForm.TextField
                          label="Zip"
                          name="zipCode"
                          placeholder="#####"
                          mask="zip"
                        />
                      </MuiGrid>
                    </MuiGrid>
                  </MuiGrid>
                </MuiGrid>
              </MuiGrid>
            </MuiGrid>
          </MuiDialogContent>

          {/* Form Actions */}
          <MuiDialogActions>
            <ConnectCareButton disabled={isSubmitting} variant="outlined" onClick={onClose}>
              Cancel
            </ConnectCareButton>
            <ConnectCareButton
              disabled={!isValid || showError}
              loading={isSubmitting}
              onClick={submitForm}
              type="submit"
            >
              Add Payment Method
            </ConnectCareButton>
          </MuiDialogActions>

          {/* Error Alert */}
          <MuiBox position="absolute" right={theme.spacing(1)} top={theme.spacing(1)}>
            {fade.map(
              ({ item, props, key }) =>
                item && (
                  <AnimatedDiv style={props} key={key}>
                    <MuiAlert
                      severity={paymentMethodConfirmations.UPDATE_PAYMENT_ERROR.severity}
                      action={
                        <MuiButton size="small" onClick={() => setShowError(false)}>
                          DISMISS
                        </MuiButton>
                      }
                    >
                      {paymentMethodConfirmations.UPDATE_PAYMENT_ERROR.subtitle}
                    </MuiAlert>
                  </AnimatedDiv>
                )
            )}
          </MuiBox>
        </MuiDialog>
      )}
    </ConnectCareForm>
  );
}

const mapStateToProps = (state: RootState) => ({
  usStates: selectors.usStatesSelector(state)
});

export default connect(mapStateToProps)(ConnectCarePaymentMethodSelectDialog);
