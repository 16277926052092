import { Modal } from 'components/CustomModal';
import { MuiBox, MuiButton, MuiTypography } from 'theme/material-ui';
import { Color } from 'modules/styles/colors';
import { FontSize, FontWeight, Spacing } from 'modules/styles/variables';
import React from 'react';

import { Image } from 'react-native';
import useNavigationAnalytics from 'hooks/useNavigationAnalytics';
import MailboxPaperStatement from 'assets/MailboxPaperStatement.png';
import { AnalyticsEvent } from 'services/AnalyticsService.common';
import { BillingStatementTitles } from 'modules/constants/Billing';
import Spacer from 'components/UI/Layout/Spacer';
import { useHistory } from 'react-router';

export const useBillingStatementInfoModal = () => {
  const history = useHistory();
  const helpCenterClicked = useNavigationAnalytics(AnalyticsEvent.BillingBannerHelpCenter, {
    title: BillingStatementTitles.HelpCenter
  });
  const webPayLinkClicked = useNavigationAnalytics(AnalyticsEvent.BillingBannerWebPay, {
    title: BillingStatementTitles.WebPay
  });

  const handleHelpCenterPress = () => {
    helpCenterClicked();
    Modal.hide();
    history.push('/u/billing/billing-help');
  };

  const handleWebPayPress = () => {
    webPayLinkClicked();
    window.open('https://webpay.intermountainhealthcare.org/webpay/', '_blank');
  };

  return () =>
    Modal.show({
      title: (
        <MuiTypography variant="h5" fontWeight={FontWeight.bold}>
          More Information
        </MuiTypography>
      ),
      description: (
        <MuiBox
          alignItems="center"
          flex={1}
          textAlign="center"
          display="flex"
          flexDirection="column"
          maxWidth={400}
        >
          <MuiBox pb={Spacing.small} pt={Spacing.xSmall}>
            <Image style={{ width: 153, height: 153 }} source={MailboxPaperStatement} />
          </MuiBox>
          <MuiBox p={Spacing.small} borderRadius={10} border={`1px solid ${Color.grayLight}`}>
            <MuiTypography variant="h5" fontWeight={FontWeight.bold}>
              I am expecting a Bill
            </MuiTypography>
            <Spacer spacing="small" />
            <MuiTypography fontSize={FontSize.large}>
              Typically a paper statement will be sent by mail for Labs and Homecare bills. If you
              have your account number and pin you can pay online here.
            </MuiTypography>
            <Spacer spacing="large" />
            <MuiButton color="primary" variant="contained" fullWidth onClick={handleWebPayPress}>
              Intermountain Webpay
            </MuiButton>
          </MuiBox>
          <MuiBox
            p={Spacing.small}
            my={Spacing.small}
            borderRadius={10}
            border={`1px solid ${Color.grayLight}`}
          >
            <MuiTypography fontSize={FontSize.large}>
              If you have questions about your bills, please visit our help center.{' '}
            </MuiTypography>
            <Spacer spacing="large" />
            <MuiButton
              color="primary"
              variant="contained"
              fullWidth
              onClick={handleHelpCenterPress}
            >
              Billing Help Center
            </MuiButton>
          </MuiBox>
        </MuiBox>
      ),
      buttons: undefined,
      options: { maxWidth: 'sm' }
    });
};

export default useBillingStatementInfoModal;
