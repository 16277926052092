import React from 'react';

export default {
  svg: (
    <g>
      <g>
        <g>
          <circle fill="#7CAFD0" strokeWidth={0} cx={39.834} cy={39.315} r={38.5} />
          <path
            fill="#FFFFFF"
            strokeWidth={0}
            d="M39.834,1.816c20.711,0,37.5,16.789,37.5,37.5c0,20.711-16.789,37.5-37.5,37.5s-37.5-16.789-37.5-37.5 C2.334,18.605,19.123,1.816,39.834,1.816 M39.834-0.184c-21.78,0-39.5,17.72-39.5,39.5s17.72,39.5,39.5,39.5s39.5-17.72,39.5-39.5 S61.614-0.184,39.834-0.184L39.834-0.184z"
          />
        </g>
        <g>
          <defs>
            <circle id="SVGID_23_" cx={39.834} cy={39.316} r={37.5} />
          </defs>
          <clipPath id="SVGID_4_">
            <use xlinkHref="#SVGID_23_" />
          </clipPath>
          <g clipPath="url(#SVGID_4_)">
            <rect
              x={29.966}
              y={70.024}
              fill="#E6E6E6"
              strokeWidth={0}
              width={19.418}
              height={8.336}
            />
            <path
              fill="#F7CDB9"
              strokeWidth={0}
              d="M57.376,39.922l0.549-7.138c0-9.334-8.922-16.897-18.249-16.897c-9.334,0-18.249,7.563-18.249,16.897 l0.549,7.131c-0.522,0.126-0.993,0.365-1.387,0.703c-0.73,0.622-1.19,1.541-1.19,2.575v3.515c0,1.858,1.496,3.366,3.359,3.377 c0,5.518,4.151,10.856,8.808,14.077V97.61h16.222V64.162c4.657-3.221,8.807-8.558,8.807-14.076 c1.855-0.013,3.356-1.519,3.356-3.377v-3.515C59.949,41.607,58.851,40.285,57.376,39.922z"
            />
            <g>
              <path
                fill="#BF8858"
                strokeWidth={0}
                d="M29.983,72.727C16.789,77.684,4.515,78.359,3.22,85.934c-1.238,7.247-3.457,15.071-4.238,23.331h81.703 c-0.939-8.378-3.346-16.437-4.524-23.331c-1.295-7.575-13.569-8.25-26.762-13.207c0,0,1.659,8.17-4.244,11.805 c-1.227,0.755-3.687,2.458-5.48,3.603c-1.894-1.145-3.136-2.253-4.352-2.988"
              />
              <polygon
                fill="#FFFFFF"
                strokeWidth={0}
                points="49.379,70.024 39.673,88.135 29.966,70.024 22.863,75.395 29.273,88.255 34.623,85.374  39.673,88.135 44.723,85.374 50.072,88.255 56.482,75.395  "
              />
            </g>
            <path
              fill="#EFAD93"
              strokeWidth={0}
              d="M57.376,39.922l0.549-7.138c0-9.334-8.922-16.897-18.249-16.897c-0.003,0-0.003,23.994-0.003,23.994 c-2.466,5.243-4.055,12.842-4.055,12.842h4.055v14.532c-2.366-0.001-5.327-1.17-8.108-3.093v2.58l15.798,4.616l0.423,0.142 v-7.338c-0.021,0.015-0.042,0.027-0.064,0.041c4.683-3.216,8.871-8.575,8.871-14.117c1.855-0.013,3.356-1.519,3.356-3.377v-3.515 C59.949,41.607,58.851,40.285,57.376,39.922z M39.673,67.255c0.005,0,0.01-0.001,0.014-0.001c-0.004,0-0.008,0.001-0.012,0.001 C39.675,67.255,39.674,67.255,39.673,67.255z M46.61,64.92c0.086-0.052,0.173-0.106,0.259-0.16 C46.783,64.814,46.697,64.867,46.61,64.92z M40.478,67.207c0.077-0.008,0.154-0.017,0.232-0.027 C40.632,67.191,40.555,67.2,40.478,67.207z M41.494,67.051c0.035-0.007,0.069-0.014,0.104-0.022 C41.563,67.037,41.529,67.044,41.494,67.051z M42.418,66.817c0.089-0.026,0.179-0.053,0.269-0.082 C42.597,66.764,42.508,66.791,42.418,66.817z M43.493,66.458c0.044-0.017,0.087-0.032,0.131-0.049 C43.58,66.426,43.537,66.442,43.493,66.458z M44.488,66.042c0.091-0.041,0.183-0.084,0.274-0.127 C44.671,65.959,44.58,66.001,44.488,66.042z M45.602,65.495c0.031-0.017,0.062-0.031,0.093-0.048 C45.664,65.464,45.633,65.479,45.602,65.495z"
            />
            <g>
              <g>
                <path
                  fill="#CD8D73"
                  strokeWidth={0}
                  d="M34.567,41.829c0.051,1.473-1.101,2.709-2.575,2.761c-1.473,0.051-2.709-1.101-2.761-2.575 c-0.051-1.473,1.101-2.709,2.575-2.761S34.516,40.356,34.567,41.829z"
                />
                <path
                  fill="#3C2417"
                  strokeWidth={0}
                  d="M34.567,41.153c0.051,1.473-1.101,2.709-2.575,2.761c-1.473,0.051-2.709-1.101-2.761-2.575 c-0.051-1.473,1.101-2.709,2.575-2.761S34.516,39.68,34.567,41.153z"
                />
                <path
                  fill="#FFFFFF"
                  strokeWidth={0}
                  d="M33.473,40.39c0.013,0.368-0.275,0.677-0.644,0.69c-0.368,0.013-0.677-0.275-0.69-0.644 c-0.013-0.368,0.275-0.677,0.644-0.69C33.151,39.734,33.46,40.022,33.473,40.39z"
                />
              </g>
              <g>
                <path
                  fill="#E78B6C"
                  strokeWidth={0}
                  d="M50.113,41.829c0.051,1.473-1.101,2.709-2.575,2.761c-1.473,0.051-2.709-1.101-2.761-2.575 c-0.051-1.473,1.101-2.709,2.575-2.761C48.826,39.203,50.062,40.356,50.113,41.829z"
                />
                <path
                  fill="#3C2417"
                  strokeWidth={0}
                  d="M50.113,41.153c0.051,1.473-1.101,2.709-2.575,2.761c-1.473,0.051-2.709-1.101-2.761-2.575 c-0.051-1.473,1.101-2.709,2.575-2.761C48.826,38.527,50.062,39.68,50.113,41.153z"
                />
                <path
                  fill="#FFFFFF"
                  strokeWidth={0}
                  d="M49.018,40.39c0.013,0.368-0.275,0.677-0.644,0.69c-0.368,0.013-0.677-0.275-0.69-0.644 c-0.013-0.368,0.275-0.677,0.644-0.69C48.697,39.734,49.006,40.022,49.018,40.39z"
                />
              </g>
            </g>
            <g>
              <path
                fill="#F05D44"
                strokeWidth={0}
                d="M45.757,55.774c0,2.986-2.724,5.407-6.083,5.407c-3.36,0-6.083-2.421-6.083-5.407H45.757z"
              />
              <path
                fill="#FFFFFF"
                strokeWidth={0}
                d="M44.001,57.802h-8.787c-0.373,0-0.676-0.303-0.676-0.676v-1.352h10.138v1.352 C44.677,57.499,44.374,57.802,44.001,57.802z"
              />
            </g>
            <path
              fill="#F4BB6E"
              strokeWidth={0}
              d="M48.836,12.326c-4.41-3.278-11.028-4.796-17.495-0.988c-6.49,3.821-7.267,4.892-10.445,4.693 c-1.019-0.064-2.051-0.217-2.179,0.796c-0.416,3.302,0.181,6.148,1.539,8.446c-2.215,9.049,1.843,19.274,1.843,19.274 s-0.77-10.108,1.588-15.608c6.112,4.26,16.847,3.871,27.354-3.293c6.274,4.406,6.2,18.901,6.2,18.901 S69.122,17.008,48.836,12.326z"
            />
          </g>
        </g>
      </g>
    </g>
  ),
  viewBox: '0 0 80 80'
};
