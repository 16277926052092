import { CompanyName } from 'modules/utils/ConfigUtils';
import { FooterLinks } from './Footer';

export const FOOTER_LINKS: FooterLinks[] = [
  {
    id: 1,
    name: 'CHNA Reports',
    link: 'https://intermountainhealthcare.org/about/who-we-are/chna-reports/'
  },
  {
    id: 2,
    name: 'Privacy Policy',
    link: 'https://intermountainhealthcare.org/website-information/privacy-notices/'
  },
  {
    id: 3,
    name: 'Non-Discrimination Policy',
    link: 'https://intermountainhealthcare.org/about/non-discrimination-accessibility/'
  },
  {
    id: 4,
    name: 'Terms of Use',
    link: 'https://intermountainhealthcare.org/website-information/terms-of-use/'
  }
];

export const DISCLAIMER = `© ${new Date().getFullYear()} ${CompanyName}, All rights reserved`;
