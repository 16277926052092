import React from 'react';

export default {
  svg: (
    <g>
      <g>
        <circle fill="#7CAFD0" strokeWidth={0} cx={40.514} cy={40.473} r={38.5} />
        <path
          fill="#FFFFFF"
          strokeWidth={0}
          d="M40.514,2.973c20.711,0,37.5,16.789,37.5,37.5c0,20.711-16.789,37.5-37.5,37.5s-37.5-16.789-37.5-37.5 C3.014,19.762,19.803,2.973,40.514,2.973 M40.514,0.973c-21.78,0-39.5,17.72-39.5,39.5s17.72,39.5,39.5,39.5s39.5-17.72,39.5-39.5 S62.294,0.973,40.514,0.973L40.514,0.973z"
        />
      </g>
      <g>
        <path
          fill="#7CAFD0"
          strokeWidth={0}
          d="M21.239,72.641c3.232-0.913,6.721-1.91,10.23-3.226l0.907-0.534v-3.876l-0.004-0.001v-2.446 c0.001,0.001,0.002,0.001,0.003,0.002c-4.417-3.054-8.355-8.117-8.355-13.35c-1.766-0.011-3.185-1.44-3.185-3.203v-3.333 c0-0.981,0.436-1.852,1.128-2.442c0.027-0.024,0.062-0.038,0.09-0.06c-2.115-6.858-4.896-21.07,6.356-25.82 c3.003-3.143,8.213-4.909,15.313-2.017c4.247,1.729,6.828,2.219,8.78-0.229c0.686-0.861,2.029-0.682,2.427,0.344 c1.685,4.351,2.372,7.398,2.192,9.538c3.061,6.027,1.941,13.586,0.804,18.083c0.821,0.578,1.363,1.523,1.363,2.604v3.333 c0,1.762-1.423,3.19-3.182,3.202c0,0.328-0.016,0.655-0.046,0.981c-0.009,0.101-0.029,0.201-0.041,0.302 c-0.027,0.224-0.052,0.449-0.092,0.672c-0.021,0.117-0.053,0.232-0.078,0.349c-0.043,0.206-0.083,0.412-0.138,0.616 c-0.031,0.116-0.072,0.23-0.106,0.345c-0.06,0.203-0.117,0.407-0.187,0.608c-0.039,0.11-0.086,0.218-0.127,0.328 c-0.077,0.204-0.151,0.409-0.238,0.611c-0.043,0.099-0.093,0.196-0.138,0.295c-0.096,0.21-0.19,0.42-0.296,0.627 c-0.039,0.076-0.084,0.15-0.124,0.226c-0.12,0.227-0.241,0.453-0.373,0.676c-0.035,0.06-0.075,0.117-0.111,0.177 c-0.143,0.236-0.288,0.471-0.442,0.702c-0.034,0.051-0.072,0.1-0.106,0.15c-0.162,0.237-0.327,0.473-0.5,0.703 c-0.027,0.036-0.056,0.07-0.083,0.105c-0.185,0.243-0.374,0.485-0.571,0.72c-0.018,0.022-0.038,0.043-0.056,0.065 c-0.208,0.248-0.421,0.492-0.64,0.73c-0.007,0.007-0.014,0.014-0.02,0.021c-1.167,1.266-2.478,2.397-3.836,3.336v6.326 l0.855,0.538v-0.021c3.744,1.407,7.466,2.449,10.878,3.413c11.08-6.516,18.521-18.556,18.521-32.339 c0-20.711-16.789-37.5-37.5-37.5s-37.5,16.789-37.5,37.5C3.014,54.134,10.321,66.086,21.239,72.641z"
        />
        <path
          fill="#F5D291"
          strokeWidth={0}
          d="M40.063,65.491c0.004,0,0.008-0.001,0.011-0.001c-0.004,0-0.009,0.001-0.014,0.001 C40.062,65.491,40.063,65.491,40.063,65.491z"
        />
        <path
          fill="#F5D291"
          strokeWidth={0}
          d="M56.02,50.493c-0.027,0.224-0.052,0.449-0.092,0.672C55.968,50.942,55.993,50.718,56.02,50.493z"
        />
        <path
          fill="#F5D291"
          strokeWidth={0}
          d="M56.107,49.211c0,0.328-0.016,0.655-0.046,0.981C56.091,49.865,56.107,49.538,56.107,49.211z"
        />
        <path
          fill="#F5D291"
          strokeWidth={0}
          d="M48.614,69.42l-0.855-0.538l-15.383-3.876v3.876l-0.907,0.534c0.015-0.006,0.03-0.011,0.044-0.016v5.696 c0.123,0.68,0.348,1.33,0.668,1.937c2.681,0.609,5.467,0.941,8.333,0.941c2.465,0,4.872-0.245,7.204-0.699 c0.567-1.004,0.896-2.131,0.896-3.327V69.42z"
        />
        <path
          fill="#F5D291"
          strokeWidth={0}
          d="M54.012,56.023c-0.143,0.236-0.288,0.471-0.442,0.702C53.725,56.494,53.87,56.259,54.012,56.023z"
        />
        <path
          fill="#F5D291"
          strokeWidth={0}
          d="M53.464,56.875c-0.162,0.237-0.327,0.473-0.5,0.703C53.137,57.348,53.302,57.112,53.464,56.875z"
        />
        <path
          fill="#F5D291"
          strokeWidth={0}
          d="M52.255,58.468c-0.208,0.248-0.421,0.492-0.64,0.73C51.834,58.96,52.047,58.716,52.255,58.468z"
        />
        <path
          fill="#F5D291"
          strokeWidth={0}
          d="M54.496,55.17c-0.12,0.227-0.241,0.454-0.373,0.676C54.255,55.624,54.375,55.397,54.496,55.17z"
        />
        <path
          fill="#F5D291"
          strokeWidth={0}
          d="M52.882,57.684c-0.186,0.243-0.374,0.485-0.571,0.72C52.507,58.168,52.696,57.927,52.882,57.684z"
        />
        <path
          fill="#F5D291"
          strokeWidth={0}
          d="M55.85,51.514c-0.043,0.206-0.083,0.412-0.138,0.616C55.767,51.926,55.807,51.72,55.85,51.514z"
        />
        <path
          fill="#F5D291"
          strokeWidth={0}
          d="M47.695,62.598c0.02-0.014,0.04-0.025,0.06-0.039l0.003,6.324v-6.326c1.358-0.94,2.67-2.07,3.836-3.336 C50.41,60.505,49.075,61.649,47.695,62.598z"
        />
        <path
          fill="#F5D291"
          strokeWidth={0}
          d="M55.606,52.476c-0.06,0.203-0.117,0.407-0.187,0.608C55.489,52.882,55.546,52.679,55.606,52.476z"
        />
        <path
          fill="#F5D291"
          strokeWidth={0}
          d="M55.292,53.411c-0.077,0.204-0.151,0.409-0.238,0.611C55.14,53.82,55.215,53.615,55.292,53.411z"
        />
        <path
          fill="#F5D291"
          strokeWidth={0}
          d="M40.061,59.732c-3.185-0.001-5.767-2.296-5.767-5.127h0.898h4.869v-2.893h-3.846 c0,0,1.508-7.206,3.846-12.178c0,0,0-6.637,0-12.743c-0.344,0.002-0.717-0.06-1.112-0.159c-2.795-0.064-5.861-0.089-9.166,0.045 c-0.066,0.003-0.135-0.001-0.201-0.012c-0.3-0.05-0.581-0.121-0.847-0.209c-5.4,4.609-5.334,17.502-5.334,17.502 s-0.636-1.477-1.348-3.785c-0.028,0.023-0.063,0.037-0.09,0.06c-0.692,0.59-1.128,1.461-1.128,2.442v3.333 c0,1.762,1.419,3.192,3.185,3.203c0,5.234,3.938,10.296,8.355,13.35c2.636,1.823,5.443,2.931,7.686,2.931V59.732z M30.294,39.907 c-0.005-0.148,0.009-0.291,0.028-0.433c-0.008-0.07-0.026-0.137-0.028-0.208c-0.049-1.397,1.044-2.569,2.441-2.618 c1.397-0.049,2.569,1.044,2.618,2.441c0.005,0.148-0.009,0.291-0.028,0.433c0.008,0.07,0.026,0.137,0.028,0.208 c0.049,1.397-1.044,2.569-2.441,2.618S30.343,41.304,30.294,39.907z"
        />
        <path
          fill="#F5D291"
          strokeWidth={0}
          d="M54.915,54.317c-0.096,0.21-0.19,0.42-0.296,0.627C54.725,54.737,54.82,54.527,54.915,54.317z"
        />
        <path
          fill="#FFFFFF"
          strokeWidth={0}
          d="M31.513,76.878c0.222,0.055,0.445,0.104,0.668,0.154c-0.32-0.607-0.545-1.257-0.668-1.937V76.878z"
        />
        <path
          fill="#FFFFFF"
          strokeWidth={0}
          d="M47.717,77.274c0.301-0.058,0.599-0.123,0.896-0.188v-3.138C48.614,75.143,48.285,76.27,47.717,77.274z"
        />
        <path
          fill="#110057"
          strokeWidth={0}
          d="M31.513,69.399c-0.015,0.006-0.03,0.011-0.044,0.016c-3.509,1.316-6.998,2.313-10.23,3.226 c3.153,1.893,6.608,3.333,10.274,4.236v-1.783V69.399z"
        />
        <path
          fill="#110057"
          strokeWidth={0}
          d="M48.614,69.42v4.527v3.138c3.887-0.856,7.547-2.314,10.878-4.274c-3.413-0.964-7.134-2.006-10.878-3.413 V69.42z"
        />
        <polygon
          opacity={0.61}
          fill="#F4BD65"
          strokeWidth={0}
          points="32.375,62.56 32.372,62.558 32.372,65.004 32.376,65.005 32.376,62.561  "
        />
        <path
          opacity={0.61}
          fill="#F4BD65"
          strokeWidth={0}
          d="M51.615,59.199c-0.007,0.007-0.014,0.014-0.02,0.021 C51.601,59.212,51.608,59.206,51.615,59.199z"
        />
        <path
          opacity={0.61}
          fill="#F4BD65"
          strokeWidth={0}
          d="M53.57,56.725c-0.034,0.051-0.072,0.1-0.106,0.15 C53.498,56.825,53.536,56.776,53.57,56.725z"
        />
        <path
          opacity={0.61}
          fill="#F4BD65"
          strokeWidth={0}
          d="M52.964,57.579c-0.027,0.036-0.056,0.07-0.083,0.105 C52.908,57.648,52.937,57.614,52.964,57.579z"
        />
        <path
          opacity={0.61}
          fill="#F4BD65"
          strokeWidth={0}
          d="M52.311,58.404c-0.018,0.022-0.038,0.043-0.056,0.065 C52.273,58.447,52.293,58.426,52.311,58.404z"
        />
        <path
          opacity={0.61}
          fill="#F4BD65"
          strokeWidth={0}
          d="M54.123,55.847c-0.035,0.06-0.075,0.117-0.111,0.177 C54.049,55.964,54.088,55.906,54.123,55.847z"
        />
        <path
          opacity={0.61}
          fill="#F4BD65"
          strokeWidth={0}
          d="M56.061,50.191c-0.009,0.101-0.029,0.201-0.041,0.302 C56.032,50.393,56.052,50.292,56.061,50.191z"
        />
        <path
          opacity={0.61}
          fill="#F4BD65"
          strokeWidth={0}
          d="M55.928,51.166c-0.021,0.117-0.053,0.232-0.078,0.349 C55.875,51.398,55.907,51.282,55.928,51.166z"
        />
        <path
          opacity={0.61}
          fill="#F4BD65"
          strokeWidth={0}
          d="M55.053,54.022c-0.043,0.099-0.093,0.196-0.138,0.295 C54.96,54.218,55.011,54.121,55.053,54.022z"
        />
        <path
          opacity={0.61}
          fill="#F4BD65"
          strokeWidth={0}
          d="M54.62,54.944c-0.039,0.076-0.084,0.15-0.124,0.226 C54.536,55.095,54.581,55.021,54.62,54.944z"
        />
        <path
          opacity={0.61}
          fill="#F4BD65"
          strokeWidth={0}
          d="M55.419,53.084c-0.039,0.11-0.086,0.218-0.127,0.328 C55.333,53.302,55.381,53.194,55.419,53.084z"
        />
        <path
          opacity={0.61}
          fill="#F4BD65"
          strokeWidth={0}
          d="M55.712,52.13c-0.031,0.116-0.072,0.23-0.106,0.345 C55.641,52.36,55.682,52.246,55.712,52.13z"
        />
        <path
          fill="#F5D291"
          strokeWidth={0}
          d="M47.755,62.558c-0.02,0.014-0.04,0.026-0.06,0.039c1.38-0.948,2.715-2.093,3.9-3.378 c0.007-0.007,0.014-0.014,0.02-0.021c0.219-0.238,0.432-0.483,0.64-0.73c0.018-0.022,0.038-0.043,0.056-0.065 c0.197-0.236,0.385-0.477,0.571-0.72c0.027-0.035,0.056-0.069,0.083-0.105c0.173-0.231,0.338-0.466,0.5-0.703 c0.034-0.051,0.072-0.099,0.106-0.15c0.155-0.231,0.299-0.466,0.442-0.702c0.036-0.059,0.076-0.117,0.111-0.177 c0.131-0.223,0.252-0.449,0.373-0.676c0.04-0.076,0.085-0.15,0.124-0.226c0.106-0.207,0.2-0.417,0.296-0.627 c0.045-0.099,0.095-0.196,0.138-0.295c0.087-0.202,0.161-0.407,0.238-0.611c0.042-0.109,0.089-0.217,0.127-0.328 c0.07-0.201,0.127-0.405,0.187-0.608c0.034-0.115,0.075-0.229,0.106-0.345c0.054-0.204,0.094-0.411,0.138-0.616 c0.025-0.116,0.057-0.232,0.078-0.349c0.041-0.223,0.065-0.448,0.092-0.672c0.012-0.101,0.032-0.201,0.041-0.302 c0.03-0.326,0.046-0.653,0.046-0.981c1.759-0.012,3.182-1.44,3.182-3.202v-3.333c0-1.081-0.542-2.026-1.363-2.604 c-0.598,2.367-1.201,3.885-1.201,3.885s1.127-14.743-3.678-17.609c-2.479,0.594-6.017,0.514-10.418,0.385 c-0.935,0.044-1.794,0.064-2.566,0.058c-0.001,0-0.001,0-0.002,0c0,6.105,0,12.743,0,12.743 c-2.338,4.972-3.846,12.178-3.846,12.178h3.846v2.893h4.745h1.025c0,2.832-2.583,5.128-5.769,5.128c0,0-0.001,0-0.001,0v5.76 c-2.243-0.001-5.05-1.109-7.686-2.931c0,0,0,0,0.001,0.001v2.445l15.383,3.876L47.755,62.558z M40.063,65.491 c-0.001,0-0.001,0-0.002,0c0.004,0,0.009-0.001,0.014-0.001C40.071,65.491,40.067,65.491,40.063,65.491z M45.036,39.907 c-0.005-0.148,0.009-0.291,0.029-0.432c-0.008-0.07-0.026-0.137-0.029-0.209c-0.049-1.397,1.044-2.569,2.441-2.618 c1.397-0.049,2.569,1.044,2.618,2.441c0.005,0.148-0.009,0.291-0.028,0.433c0.008,0.07,0.026,0.137,0.028,0.208 c0.049,1.397-1.044,2.569-2.441,2.618S45.085,41.304,45.036,39.907z"
        />
        <path
          opacity={0.61}
          fill="#F4BD65"
          strokeWidth={0}
          d="M47.755,62.558c-0.02,0.014-0.04,0.026-0.06,0.039c1.38-0.948,2.715-2.093,3.9-3.378 c0.007-0.007,0.014-0.014,0.02-0.021c0.219-0.238,0.432-0.483,0.64-0.73c0.018-0.022,0.038-0.043,0.056-0.065 c0.197-0.236,0.385-0.477,0.571-0.72c0.027-0.035,0.056-0.069,0.083-0.105c0.173-0.231,0.338-0.466,0.5-0.703 c0.034-0.051,0.072-0.099,0.106-0.15c0.155-0.231,0.299-0.466,0.442-0.702c0.036-0.059,0.076-0.117,0.111-0.177 c0.131-0.223,0.252-0.449,0.373-0.676c0.04-0.076,0.085-0.15,0.124-0.226c0.106-0.207,0.2-0.417,0.296-0.627 c0.045-0.099,0.095-0.196,0.138-0.295c0.087-0.202,0.161-0.407,0.238-0.611c0.042-0.109,0.089-0.217,0.127-0.328 c0.07-0.201,0.127-0.405,0.187-0.608c0.034-0.115,0.075-0.229,0.106-0.345c0.054-0.204,0.094-0.411,0.138-0.616 c0.025-0.116,0.057-0.232,0.078-0.349c0.041-0.223,0.065-0.448,0.092-0.672c0.012-0.101,0.032-0.201,0.041-0.302 c0.03-0.326,0.046-0.653,0.046-0.981c1.759-0.012,3.182-1.44,3.182-3.202v-3.333c0-1.081-0.542-2.026-1.363-2.604 c-0.598,2.367-1.201,3.885-1.201,3.885s1.127-14.743-3.678-17.609c-2.479,0.594-6.017,0.514-10.418,0.385 c-0.935,0.044-1.794,0.064-2.566,0.058c-0.001,0-0.001,0-0.002,0c0,6.105,0,12.743,0,12.743 c-2.338,4.972-3.846,12.178-3.846,12.178h3.846v2.893h4.745h1.025c0,2.832-2.583,5.128-5.769,5.128c0,0-0.001,0-0.001,0v5.76 c-2.243-0.001-5.05-1.109-7.686-2.931c0,0,0,0,0.001,0.001v2.445l15.383,3.876L47.755,62.558z M40.063,65.491 c-0.001,0-0.001,0-0.002,0c0.004,0,0.009-0.001,0.014-0.001C40.071,65.491,40.067,65.491,40.063,65.491z M45.036,39.907 c-0.005-0.148,0.009-0.291,0.029-0.432c-0.008-0.07-0.026-0.137-0.029-0.209c-0.049-1.397,1.044-2.569,2.441-2.618 c1.397-0.049,2.569,1.044,2.618,2.441c0.005,0.148-0.009,0.291-0.028,0.433c0.008,0.07,0.026,0.137,0.028,0.208 c0.049,1.397-1.044,2.569-2.441,2.618S45.085,41.304,45.036,39.907z"
        />
        <path
          fill="#eea968"
          strokeWidth={0}
          d="M35.354,39.73c-0.002-0.072-0.02-0.138-0.028-0.208c-0.164,1.198-1.164,2.142-2.413,2.185 c-1.326,0.046-2.437-0.939-2.59-2.233c-0.019,0.142-0.033,0.285-0.028,0.433c0.049,1.397,1.221,2.49,2.618,2.441 S35.403,41.128,35.354,39.73z"
        />
        <path
          fill="#eea968"
          strokeWidth={0}
          d="M35.354,39.73c-0.002-0.072-0.02-0.138-0.028-0.208c-0.164,1.198-1.164,2.142-2.413,2.185 c-1.326,0.046-2.437-0.939-2.59-2.233c-0.019,0.142-0.033,0.285-0.028,0.433c0.049,1.397,1.221,2.49,2.618,2.441 S35.403,41.128,35.354,39.73z"
        />
        <path
          fill="#000000"
          strokeWidth={0}
          d="M32.912,41.708c1.25-0.044,2.249-0.987,2.413-2.185c0.019-0.142,0.033-0.285,0.028-0.433 c-0.049-1.397-1.221-2.49-2.618-2.441c-1.397,0.049-2.49,1.221-2.441,2.618c0.002,0.072,0.02,0.138,0.028,0.208 C30.476,40.768,31.587,41.754,32.912,41.708z M33.705,39.02c-0.349,0.012-0.642-0.261-0.654-0.61 c-0.012-0.349,0.261-0.642,0.61-0.655c0.349-0.012,0.642,0.261,0.654,0.61S34.055,39.008,33.705,39.02z"
        />
        <path
          fill="#FFFFFF"
          strokeWidth={0}
          d="M33.661,37.756c-0.349,0.012-0.623,0.305-0.61,0.655c0.012,0.349,0.305,0.623,0.654,0.61 c0.349-0.012,0.623-0.305,0.61-0.655S34.01,37.743,33.661,37.756z"
        />
        <path
          fill="#F5D291"
          strokeWidth={0}
          d="M50.095,39.73c-0.002-0.072-0.02-0.138-0.028-0.208c-0.164,1.198-1.164,2.142-2.413,2.185 c-1.325,0.046-2.436-0.939-2.59-2.233c-0.019,0.141-0.034,0.285-0.029,0.432c0.049,1.397,1.221,2.49,2.618,2.441 S50.144,41.128,50.095,39.73z"
        />
        <path
          opacity={0.61}
          fill="#F4BD65"
          strokeWidth={0}
          d="M50.095,39.73c-0.002-0.072-0.02-0.138-0.028-0.208c-0.164,1.198-1.164,2.142-2.413,2.185 c-1.325,0.046-2.436-0.939-2.59-2.233c-0.019,0.141-0.034,0.285-0.029,0.432c0.049,1.397,1.221,2.49,2.618,2.441 S50.144,41.128,50.095,39.73z"
        />
        <path
          fill="#eb9e56"
          strokeWidth={0}
          d="M50.095,39.73c-0.002-0.072-0.02-0.138-0.028-0.208c-0.164,1.198-1.164,2.142-2.413,2.185 c-1.325,0.046-2.436-0.939-2.59-2.233c-0.019,0.141-0.034,0.285-0.029,0.432c0.049,1.397,1.221,2.49,2.618,2.441 S50.144,41.128,50.095,39.73z"
        />
        <path
          fill="#000000"
          strokeWidth={0}
          d="M47.654,41.708c1.25-0.044,2.249-0.987,2.413-2.185c0.019-0.142,0.033-0.285,0.028-0.433 c-0.049-1.397-1.221-2.49-2.618-2.441c-1.397,0.049-2.49,1.221-2.441,2.618c0.002,0.072,0.02,0.139,0.029,0.209 C45.218,40.769,46.329,41.754,47.654,41.708z M48.447,39.02c-0.349,0.012-0.642-0.261-0.654-0.61 c-0.012-0.349,0.261-0.642,0.61-0.655c0.349-0.012,0.642,0.261,0.655,0.61S48.796,39.008,48.447,39.02z"
        />
        <path
          fill="#FFFFFF"
          strokeWidth={0}
          d="M48.403,37.756c-0.349,0.012-0.622,0.305-0.61,0.655c0.012,0.349,0.305,0.623,0.654,0.61 c0.349-0.012,0.623-0.305,0.61-0.655S48.752,37.743,48.403,37.756z"
        />
        <path
          fill="#F05D44"
          strokeWidth={0}
          d="M44.165,56.527h-8.333c-0.354,0-0.641-0.287-0.641-0.641v-1.282h-0.898c0,2.831,2.582,5.127,5.767,5.127 c0,0,0.001,0,0.001,0c3.186,0,5.769-2.296,5.769-5.128h-1.025v1.282C44.806,56.24,44.519,56.527,44.165,56.527z"
        />
        <path
          fill="#FFFFFF"
          strokeWidth={0}
          d="M35.192,55.886c0,0.354,0.287,0.641,0.641,0.641h8.333c0.354,0,0.641-0.287,0.641-0.641v-1.282h-4.745 h-4.869V55.886z"
        />
        <path
          fill="#000000"
          strokeWidth={0}
          d="M28.736,26.456c0.266,0.088,0.548,0.16,0.847,0.209c0.065,0.011,0.135,0.014,0.201,0.012 c3.304-0.134,6.371-0.109,9.166-0.045c0.395,0.099,0.768,0.161,1.112,0.159c0.001,0,0.001,0,0.002,0 c0.772,0.006,1.632-0.014,2.566-0.058c4.401,0.129,7.939,0.209,10.418-0.385c4.805,2.866,3.678,17.609,3.678,17.609 s0.603-1.519,1.201-3.885c1.136-4.497,2.257-12.056-0.804-18.083c0.179-2.141-0.507-5.188-2.192-9.538 c-0.398-1.026-1.74-1.205-2.427-0.344c-1.952,2.448-4.533,1.958-8.78,0.229c-7.1-2.891-12.31-1.126-15.313,2.017 c-11.252,4.75-8.471,18.962-6.356,25.82c0.712,2.308,1.348,3.785,1.348,3.785S23.335,31.064,28.736,26.456z"
        />
      </g>
    </g>
  ),
  viewBox: '0 0 80 80'
};
