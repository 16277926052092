import React, { ComponentType, useEffect, useState } from 'react';
import Config from 'react-native-config';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { History } from 'lib/history';
import { v4 as uuidv4 } from 'uuid';
import { currentAccountConsumerSelector } from 'store/account/selectors';
import { Consumer } from 'store/profile/types';
import { RootState } from 'store/types';
import {
  getSessionToken,
  resetTriageChat,
  getLastSessionToken,
  unsetResetChat,
  resetTriageData,
  setTriageOrigin
} from 'store/triageGyant/actions';
import {
  triageGyantSessionTokenDataSelector,
  triageGyantResetChatSelector,
  triageGyantHasFetchedLastSession,
  triageGyantLastSessionSelector,
  triageGyantFetchingLastSessionSelector,
  triageGyantSessionTokenErrorSelector,
  triageGyantSessionTokenIsFetchingSelector
} from 'store/triageGyant/selectors';
import { TriagePatient } from 'store/triageGyant/types';
import { EVisitTypes, EVisitTypeKey } from 'modules/constants/eVisit/eVisitType';
import { isDfdAuthenticatedSelector } from 'store/authentication/selectors';
import { eVisitSessionTokenSelector, isEVisitFrontDoorSelector } from 'store/eVisit/selectors';
import TriageHeader from 'screens/TriageGyant/TriageGyantHeader';
import { NavigationScreenProps } from 'screens/navigation';
import { TRIAGE_ROUTES } from 'screens/TriageGyant/constants';
import { TriageConsumerData, buildPatientDTO } from 'lib/triage/utils';
import { isMobileBrowser } from 'lib/browser';
import useNavigationAnalytics from 'hooks/useNavigationAnalytics';
import { ZIndex, Spacing } from 'modules/styles/variables';
import { Color } from 'modules/styles/colors';
import Spinner from 'components/UI/Spinner/Spinner';
import { Alert } from 'components/Alert';
import { Confirm } from 'components/ConfirmDialog/ConfirmDialog';
import { MuiBox, MuiContainer } from 'theme/material-ui';
import { forgetTriageSession } from 'storage/triageSessions/storage';
import { setDisqualifiedId } from 'storage/eVisitsDisqualified/storage.web';
import analyticsService, { AmplitudeEventData, AnalyticsEvent } from 'services/AnalyticsService';
import { EVISITS_ROUTES } from 'screens/EVisits/constants';
import { useLocalStorage } from 'hooks/useLocalStorage';

export interface Props extends NavigationScreenProps {
  consumer: Consumer;
  fetchingLastSession: boolean;
  getSessionToken: typeof getSessionToken;
  hasFetchedLastSession: boolean;
  isAuthenticated: boolean;
  isFetchingSessionToken: boolean;
  resetTriageChat: typeof resetTriageChat;
  getLastSessionToken: typeof getLastSessionToken;
  lastSession?: string | null;
  sessionToken: string;
  sessionTokenError?: Error | null;
  resetChatBool: boolean;
  unsetResetChat: typeof unsetResetChat;
  eVisitSessionToken: string;
  resetTriageData: typeof resetTriageData;
  setTriageOrigin: typeof setTriageOrigin;
  isEVisitFrontDoor: boolean;
}

export interface GyantWebViewLocationState {
  selectedProxyAccount: TriageConsumerData;
}

interface EventData {
  status: string;
  caseId: string;
}
interface Event {
  data: EventData;
}

interface Callbacks {
  covidTestGetStartedClicked: (ampData?: AmplitudeEventData) => void;
}

const StyledWebView = styled.iframe`
  width: 100%;
`;

const StyledContainer = styled(MuiContainer)`
  height: 93%;
`;

export const handleErrorMessage = (
  message: string,
  history: History,
  resetStoreData?: () => void
) => {
  Confirm.show(message, 'Please try again later or contact support.', '', 'error', {
    text: 'Ok',
    onClick: () => {
      history.goBack();
      resetStoreData?.();
    }
  });
};

export const messageHandler = (
  event: Event,
  history: History,
  forgetSession: () => void,
  setIsLoading: (arg: boolean) => void,
  resetStoreData: () => void,
  isAuthenticated: boolean,
  callbacks: Callbacks,
  setComesFrom: (routeName: string | null) => void
) => {
  const { status, caseId } = event.data;
  switch (status) {
    case 'diagnosis':
      history.push(`${TRIAGE_ROUTES.RESULTS.BASE_URL}/${caseId}`);
      forgetSession();
      break;
    case 'medicalAlert':
      forgetSession();
      break;
    case 'onCovidTest':
      if (isAuthenticated) {
        callbacks.covidTestGetStartedClicked();
        setComesFrom('TriageGyant');
        history.push('/u/get-care-now/covid-screen');
      } else {
        window.open(
          'https://intermountainhealthcare.org/health-wellness-promotion/pandemics/covid/get-testing/',
          '_blank'
        );
      }
      break;
    case 'onCovidSelected':
      analyticsService.logEvent(AnalyticsEvent.SymptomCheckerCovidSelected);
      window.open(
        'https://intermountainhealthcare.org/Health-Wellness-Promotion/Vaccines/COVID',
        '_blank'
      );
      break;
    case 'onFirstMessage':
      analyticsService.logEvent(AnalyticsEvent.EVisitFrontDoorGyantTriageStart);
      setIsLoading(false);
      break;
    case 'evisitQualified':
      history.push(EVISITS_ROUTES.REQUEST_SUCCESS);
      resetStoreData();
      break;
    case 'evisitDeclined':
      history.push(`${TRIAGE_ROUTES.RESULTS.BASE_URL}/${caseId}`);
      break;
    case 'evisitDisqualified':
      setDisqualifiedId(caseId);
      history.push(`${TRIAGE_ROUTES.RESULTS.BASE_URL}/${caseId}`);
      break;
    case 'evisitError':
      handleErrorMessage('Unable to complete an E-Visit at this time', history);
      break;
    default:
      break;
  }
};

export const GyantWebViewComponent = (props: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const {
    consumer,
    fetchingLastSession,
    hasFetchedLastSession,
    isAuthenticated,
    isFetchingSessionToken,
    lastSession,
    sessionToken,
    sessionTokenError,
    resetChatBool,
    history,
    eVisitSessionToken,
    isEVisitFrontDoor
  } = props;

  // Use proxy account if selected
  const locationState = history.location.state as GyantWebViewLocationState;
  const proxyAccount = locationState?.selectedProxyAccount;
  const user = proxyAccount || consumer;
  const [useNewSession, setUseNewSession] = useState<boolean>();
  const [patientInfo, setPatientInfo] = useState<TriagePatient>();
  const [eVisitType, setEVisitType] = useLocalStorage(EVisitTypeKey, '');

  const covidTestGetStartedClicked = useNavigationAnalytics(
    AnalyticsEvent.CovidTestGetStartedClicked
  );
  const symptomCheckerStarted = useNavigationAnalytics(AnalyticsEvent.SymptomCheckerStarted);

  useEffect(() => {
    if (!isEVisitFrontDoor) {
      symptomCheckerStarted();
    }
  }, []);

  // Init EVisit Session if token is present
  useEffect(() => {
    const initEVisit = async () => {
      const iframe = document.getElementById('frameRef') as HTMLIFrameElement;
      let redirect;

      if (isEVisitFrontDoor) {
        redirect = `${Config.TRIAGE_GYANT_WEBVIEW_URL}&sessionToken=${eVisitSessionToken}&platform=web`;
      } else {
        redirect = `${Config.TRIAGE_GYANT_WEBVIEW_URL}&sessionToken=${eVisitSessionToken}&platform=web&gyStFl=aW0td2ViLWZkX2FzeW5jX3N0YXJ0OnN0YXJ0Cg==&gyStartFlow=true`;
      }
      setEVisitType(isEVisitFrontDoor ? EVisitTypes.FrontDoor : EVisitTypes.SymptomChecker);
      iframe.contentWindow?.location.replace(redirect);
    };
    if (eVisitSessionToken && isLoading) {
      initEVisit();
    }
  }, [eVisitSessionToken, isLoading]);

  // get patientInfo
  useEffect(() => {
    if (user && user.consumerId && !eVisitSessionToken) {
      setPatientInfo(buildPatientDTO(user));
    } else if (!isAuthenticated) {
      // If no patient info give a random patientId
      setPatientInfo({ patientId: uuidv4() });
    }
  }, [user]);

  useEffect(() => {
    if (sessionTokenError) {
      handleErrorMessage(
        'Unable to start symptom checker at this time',
        history,
        props.resetTriageData
      );
    }
  }, [sessionTokenError]);

  // Get Old Session if the user is authenticated
  useEffect(() => {
    if (
      isAuthenticated &&
      !sessionTokenError &&
      !hasFetchedLastSession &&
      !fetchingLastSession &&
      patientInfo
    ) {
      const id = patientInfo.grantor?.patientId || patientInfo.patientId;
      if (id) {
        props.getLastSessionToken(id);
      }
    }
  }, [hasFetchedLastSession, fetchingLastSession, patientInfo]);

  // Alert if the user has an existing Session
  useEffect(() => {
    if (!isEVisitFrontDoor && eVisitType === EVisitTypes.FrontDoor) {
      setUseNewSession(true);
      props.resetTriageChat();
    } else if (useNewSession === undefined && lastSession && !resetChatBool) {
      Alert.alert(
        'You have a previous session',
        'Would you like to view, or continue your last session?',
        [
          {
            text: 'Yes',
            onPress: () => setUseNewSession(false)
          },
          {
            text: 'New session',
            onPress: () => {
              setUseNewSession(true);
              props.resetTriageChat();
            }
          }
        ]
      );
    }
  }, [lastSession]);

  // Initialize session if there is no last session
  useEffect(() => {
    if ((hasFetchedLastSession && !lastSession && !eVisitSessionToken) || !isAuthenticated) {
      props.resetTriageChat();
    }
  }, [hasFetchedLastSession]);

  // Reset Chat with a new session if resetChatBool has been triggered
  useEffect(() => {
    if (!sessionTokenError && resetChatBool && patientInfo && !isFetchingSessionToken) {
      props.getSessionToken(patientInfo);
      setUseNewSession(true);
      props.unsetResetChat();
    }
  }, [resetChatBool]);

  // Initialize the chat when there is a token, or the token changes
  useEffect(() => {
    if (
      useNewSession !== undefined &&
      ((useNewSession && sessionToken) || (!useNewSession && lastSession))
    ) {
      const iframe = document.getElementById('frameRef') as HTMLIFrameElement;
      const sessionId = useNewSession || isEVisitFrontDoor ? sessionToken : lastSession;
      setEVisitType(isEVisitFrontDoor ? EVisitTypes.FrontDoor : EVisitTypes.SymptomChecker);
      if (iframe && sessionId) {
        // replace location to prevent iframe duplicating navigation history
        iframe.contentWindow?.location.replace(
          `${Config.TRIAGE_GYANT_WEBVIEW_URL}&sessionToken=${sessionId}&platform=web`
        );
      }
    }
  }, [useNewSession, sessionToken]);

  useEffect(() => {
    const callbacks = {
      covidTestGetStartedClicked
    };
    const handler = (event: Event) => {
      messageHandler(
        event,
        history,
        forgetTriageSession,
        setIsLoading,
        props.resetTriageData,
        isAuthenticated,
        callbacks,
        props.setTriageOrigin,
        props.consumer
      );
    };

    window.addEventListener('message', event => handler(event), false);
    return () => {
      window.removeEventListener('message', event => handler(event), false);
    };
  }, []);

  return (
    <MuiBox
      display="block"
      flexDirection="column"
      height={isMobileBrowser() ? window.innerHeight - 15 : '100%'}
    >
      <TriageHeader />
      {sessionTokenError ? (
        <MuiBox color={Color.red} zIndex={ZIndex.spinner} padding={`${Spacing.large}px`}>
          {sessionTokenError.message}
        </MuiBox>
      ) : null}
      {isLoading && !sessionTokenError ? (
        <MuiBox position="absolute" left="48%" top="50%" zIndex={ZIndex.spinner}>
          <Spinner />
        </MuiBox>
      ) : null}
      <StyledContainer maxWidth="md">
        <MuiBox height="100%" display="flex" data-testid="webview-wrapper">
          <StyledWebView
            style={{ borderWidth: 0 }}
            id="frameRef"
            allow-popups
            sandbox="allow-forms
            allow-same-origin
            allow-scripts
            allow-popups
            allow-popups-to-escape-sandbox"
          />
        </MuiBox>
      </StyledContainer>
    </MuiBox>
  );
};

const mapDispatch = {
  getLastSessionToken,
  getSessionToken,
  resetTriageChat,
  unsetResetChat,
  resetTriageData,
  setTriageOrigin
};

const mapState = (state: RootState) => ({
  consumer: currentAccountConsumerSelector(state),
  fetchingLastSession: triageGyantFetchingLastSessionSelector(state),
  hasFetchedLastSession: triageGyantHasFetchedLastSession(state),
  isAuthenticated: isDfdAuthenticatedSelector(state),
  isFetchingSessionToken: triageGyantSessionTokenIsFetchingSelector(state),
  lastSession: triageGyantLastSessionSelector(state),
  sessionToken: triageGyantSessionTokenDataSelector(state),
  sessionTokenError: triageGyantSessionTokenErrorSelector(state),
  resetChatBool: triageGyantResetChatSelector(state),
  eVisitSessionToken: eVisitSessionTokenSelector(state),
  isEVisitFrontDoor: isEVisitFrontDoorSelector(state)
});

export default connect(mapState, mapDispatch)(GyantWebViewComponent as ComponentType);
