import React from 'react';
import { Color } from 'modules/styles/colors';
import { RangeContainer, FixedTooltip, Indicator, LeftBar, MiddleBar, RightBar } from './styled';

const calcRelativeValues = (value: number, low: number, high: number, highest: number) => {
  const HALF_A_BAR = 50;
  const QUARTER_OF_A_BAR = 25;
  // Calculation for left part of the range
  if (value < low) {
    return (value * QUARTER_OF_A_BAR) / low;
  }

  // This ensures the indicator does not move farther than the limit when a value exceed the highest number
  if (value > highest) return 100;

  // Calculation for right part of the range
  if (value > high) {
    return ((value - high) * QUARTER_OF_A_BAR) / low + (HALF_A_BAR + QUARTER_OF_A_BAR);
  }

  // Calculation for middle part of the range
  return ((value - low) / (high - low)) * HALF_A_BAR + QUARTER_OF_A_BAR;
};

export interface RangeProps {
  value: number | string;
  low?: number;
  high?: number;
  unit?: string;
  big?: boolean;
  fixedTooltip?: boolean;
  testID?: string;
}

const Range = ({ value, low = 0, high = 0, unit, big, fixedTooltip, testID }: RangeProps) => {
  const HIGHEST_VALUE = Number(high) + Number(low);
  const PERCENTAGE_AREA = 50;
  const percentageValue = calcRelativeValues(Number(value), low, high, HIGHEST_VALUE);
  const isInRange = value >= low && value <= high;
  const middleBarColor = isInRange ? Color.darkGreenShade : Color.grayLight7;
  const sidebarsColor = isInRange ? Color.grayLight7 : Color.darkOrangeShade;
  const indicatorColor = isInRange ? Color.darkGreenShade : Color.darkOrangeShade;
  const tooltipText = unit ? `${value} ${unit}` : value;
  const showRange = !!(low >= 0 && high);

  return (
    <RangeContainer data-testid={testID}>
      {fixedTooltip || !showRange ? (
        <FixedTooltip
          data-testid={tooltipText}
          showRange={showRange}
          bgColor={showRange ? indicatorColor : Color.darkGreenShade}
        >
          {tooltipText}
        </FixedTooltip>
      ) : null}
      {showRange ? (
        <>
          <Indicator
            data-testid="indicator"
            bgColor={indicatorColor}
            position={percentageValue}
            fixedTooltip={fixedTooltip}
            data-tooltip-text={tooltipText}
          />
          <LeftBar big={big} bgColor={sidebarsColor} data-low-label={low} />
          <MiddleBar big={big} bgColor={middleBarColor} widthPercentage={PERCENTAGE_AREA} />
          <RightBar big={big} bgColor={sidebarsColor} data-high-label={high} />
        </>
      ) : null}
    </RangeContainer>
  );
};

export default Range;
