import { Icon } from 'components/Icon';
import {
  MuiAvatar,
  MuiBox,
  MuiListItem,
  MuiListItemAvatar,
  MuiListItemIcon,
  MuiListItemText,
  MuiTypography
} from 'theme/material-ui';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Svg } from 'components/UI/Svg';
import BlueDot from 'components/common/BlueDot';
import { IconSize, Spacing } from 'modules/styles/variables';
import { Color } from 'modules/styles/colors';

const StyledMuiListItemText = styled(MuiListItemText)`
  width: 65%;
`;

interface MessageListItemProps {
  to: string;
  imageUrl?: string;
  title: string;
  subtitle: string;
  body: string;
  icon: string;
  footer: string;
  unread?: boolean;
  hasAttachment?: boolean;
  numberOfFiles?: number;
}
export const MessageListItem = (props: MessageListItemProps) => {
  return (
    <MuiListItem button component={Link} to={props.to}>
      {props.unread ? <BlueDot hOffsetRight={Spacing.large} flexShrink="0" /> : null}

      <MuiListItemAvatar>
        <MuiAvatar src={props.imageUrl} />
      </MuiListItemAvatar>

      <StyledMuiListItemText
        primary={props.title}
        secondary={
          <>
            <MuiTypography variant="body2" component="span" style={{ display: 'block' }}>
              {props.subtitle}
            </MuiTypography>
            <MuiBox display="flex" flexDirection="row">
              <MuiTypography variant="caption">{props.body}</MuiTypography>
              {props.hasAttachment ? (
                <MuiBox px={1} alignSelf="center">
                  <Svg set="assets" name="FileAttachment" size={IconSize.small} />
                </MuiBox>
              ) : null}
            </MuiBox>
            {props.numberOfFiles ? (
              <MuiBox display="flex" alignItems="center" marginTop={`${Spacing.small}px`}>
                <Icon name="AttachFile" size={Spacing.large} />
                <MuiTypography variant="caption" color={Color.blueHue2}>
                  {props.numberOfFiles} Attachment
                </MuiTypography>
              </MuiBox>
            ) : null}
          </>
        }
      />
      <MuiListItemText disableTypography>
        <MuiBox mr={1} textAlign="right">
          <MuiTypography variant="caption" align="right">
            {props.footer}
          </MuiTypography>
        </MuiBox>
      </MuiListItemText>

      <MuiListItemIcon>
        <Icon name={props.icon}></Icon>
      </MuiListItemIcon>
    </MuiListItem>
  );
};

export default MessageListItem;
