import styled from 'styled-components';

import Menu from '@material-ui/core/Menu';
import { headerHeight, sideBarWidthOpen } from 'components/AuthLayout/styled';

import { Color } from 'modules/styles/colors';
import { Spacing, ZIndex } from 'modules/styles/variables';
import {
  CursorMuiBox,
  dfdDefaultTheme,
  MuiAppBar,
  MuiContainer,
  MuiList,
  MuiMenuItem,
  MuiPaper,
  withStyles
} from 'theme/material-ui';
import { Props } from './Header';

export const ContainerHeader = styled.div`
  &&& {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: ${headerHeight}px;
    background-color: ${Color.white};
    z-index: ${ZIndex.navigation};
    padding: 0 ${Spacing.large}px;
  }
`;

export const ContainerRow = styled.div`
  &&& {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

export const MenuButton = styled(CursorMuiBox)`
  &&& {
    padding-left: ${Spacing.none}px;
  }
`;

export const StyledAppBar = styled(MuiAppBar)`
  &&& {
    z-index: ${ZIndex.medium - 1};
    display: ${({ hidden }: Props) => (hidden ? 'none' : 'flex')};
    flex-direction: row;
    justify-content: space-between;
    height: ${headerHeight}px;
    background-color: ${Color.white};
    padding: 0 ${Spacing.large}px;
    width: 100%;
    ${dfdDefaultTheme.breakpoints.up('lg')} {
      width: calc(100% - ${sideBarWidthOpen}px);
    }
    ${dfdDefaultTheme.breakpoints.down('sm')} {
      width: 100%;
    }
  }
`;

export const SkipToContentLink = styled.a`
  left: -999px;
  position: absolute;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
  z-index: ${ZIndex.min};

  &:active,
  &:focus {
    background-color: white;
    left: 0;
    top: 0;
    width: auto;
    height: auto;
    overflow: auto;
    margin: 10px;
    padding: 5px;
    z-index: ${ZIndex.max};
  }
`;

export const StyledMenu = withStyles({
  list: {
    paddingTop: 0,
    paddingBottom: 0
  },
  paper: {
    margin: '12px 25px'
  }
})(Menu);

export const StyledMuiPaper = styled(MuiPaper)`
  &.MuiPaper-root {
    max-width: 466px;
    min-width: 300px;
    background-color: ${Color.white};
    padding-top: ${Spacing.large}px;
  }
`;

export const StyledMuiList = styled(MuiList)`
  &.MuiList-root {
    background-color: ${Color.baseColor};
  }

  &.MuiList-padding {
    padding: 0;
  }
`;

export const StyledMuiMenuItem = styled(MuiMenuItem)`
  &.MuiMenuItem-root {
    padding-top: ${Spacing.large}px;
    padding-bottom: ${Spacing.large}px;
  }
`;

export const StyledMuiContainer = styled(MuiContainer)`
  &.MuiContainer-root {
    padding-top: ${Spacing.medium}px;
    padding-bottom: ${Spacing.medium}px;
    background-color: ${Color.foreColor};
  }
`;
