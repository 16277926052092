import { Modal } from 'components/CustomModal';
import Svg from 'components/UI/Svg/Svg';
import { MuiTypography } from 'theme/material-ui';
import useNotificationsOptOut from 'lib/hooks/useNotificationsOptOut';
import useNotificationsOptOutTrigger from 'lib/hooks/useNotificationsOptOutTrigger';
import { Color } from 'modules/styles/colors';
import { FontSize, FontWeight } from 'modules/styles/variables';
import wait from 'modules/utils/wait';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { toggleNotificationsPreferenceWithAnalytics } from 'store/notifications/actions.common';

export const useNotificationsOptOutDisplay = () => {
  const dispatch = useDispatch();
  const {
    doSmsEnroll,
    isEmailEnabled,
    isSmsEnabled,
    notificationsChannels
  } = useNotificationsOptOut();
  const hasTriggered = useNotificationsOptOutTrigger();

  const setPreferences = async (updates: { [key: string]: boolean }) => {
    for (const [key, enabled] of Object.entries(updates)) {
      const item = notificationsChannels?.[key.toUpperCase()];
      if (item?.key && item.enabled !== enabled) {
        dispatch(toggleNotificationsPreferenceWithAnalytics(item, 'channels'));
        // eslint-disable-next-line no-await-in-loop
        await wait(1000); // give redux state a little time to update
      }
    }
  };

  const onPressEnrollAll = async () => {
    await setPreferences({ email: true, sms: true });
  };

  const onPressEmailOnly = async () => {
    await setPreferences({ email: true, sms: false });
  };

  const onPressSmsOnly = async () => {
    await setPreferences({ email: false, sms: true });
  };

  const onPressEmailOptOut = async () => {
    await setPreferences({ email: false, sms: false });
  };

  useEffect(() => {
    if (hasTriggered) {
      const buttons = {
        styles: {
          direction: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          flexWrap: 'wrap'
        },
        items: [
          {
            label: 'Email and SMS',
            onClick: onPressEnrollAll,
            styles: { paddingX: 35, width: 190, flexGrow: 0 }
          },
          {
            label: 'Email only',
            onClick: onPressEmailOnly,
            styles: { paddingX: 35, width: 190, flexGrow: 0 }
          },
          {
            label: 'SMS only',
            onClick: onPressSmsOnly,
            styles: { paddingX: 35, width: 190, flexGrow: 0 }
          },
          {
            label: 'No Notifications',
            onClick: onPressEmailOptOut,
            styles: { paddingX: 35, width: 190, flexGrow: 0 }
          }
        ]
      };
      Modal.show({
        title: (
          <>
            <MuiTypography color={Color.primary} fontWeight={FontWeight.semibold}>
              Great News!
            </MuiTypography>
          </>
        ),
        description: (
          <>
            <MuiTypography fontSize={FontSize.base} style={{ fontStyle: 'italic' }}>
              Our notification system has been updated. Receive alerts for test results and more.
            </MuiTypography>
            <MuiTypography fontSize={FontSize.base} style={{ fontStyle: 'italic' }}>
              <>
                {!isEmailEnabled && doSmsEnroll ? "Let's get you enrolled." : null}
                {isEmailEnabled && doSmsEnroll
                  ? "You're enrolled for email notifications. Let's enroll you for SMS notifications too."
                  : null}
                {!isEmailEnabled && isSmsEnabled
                  ? "You're enrolled for SMS notifications. Let's enroll you for email notifications too."
                  : null}
                {isEmailEnabled && isSmsEnabled ? "You're all set." : null}
              </>
              <Svg name="Notifications" set="assets" size={230} />
            </MuiTypography>
          </>
        ),
        buttons,
        backdrop: { skipBackdropClose: true },
        options: { maxWidth: 'md' }
      });
    }
  }, [hasTriggered]);
};

export default useNotificationsOptOutDisplay;
