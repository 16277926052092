import React, { useState, useEffect, useContext, ChangeEvent } from 'react';
import { connect } from 'react-redux';

import { MuiBox, MuiButton, MuiTypography } from 'theme/material-ui';
import LinkingServices from 'services/LinkingServices';
import { RootDispatch, RootState } from 'store/types';
import { languageSelector } from 'store/consumerPreferences/selectors';
import * as consumerActions from 'store/consumerPreferences/actions';
import * as consumerTypes from 'store/consumerPreferences/types';
import { Color } from 'modules/styles/colors';
import { FontSize, Spacing, FontWeight } from 'modules/styles/variables';
import { cleanPhone } from 'modules/utils/PhoneUtils';
import { technicalHelpNumber } from 'modules/constants/phoneNumbers';
import Spacer from 'components/UI/Layout/Spacer';
import { ExpandedAccordionContext } from 'components/UI/Accordion';
import { SelectDropdown } from 'components/UI/SelectDropdown';
import {
  languages,
  warningSpanishText,
  switcherTranslations
} from 'lib/constants/translations/components/LanguageSwitcher';
import useNavigationAnalytics from 'hooks/useNavigationAnalytics';
import { AnalyticsEvent } from 'services/AnalyticsService';

export interface LanguageSwitcherProps {
  language: string;
  updateAndRefreshSharedUiData: typeof consumerActions.updateAndRefreshSharedUiData;
}

export const LanguageSwitcher = ({
  language,
  updateAndRefreshSharedUiData
}: LanguageSwitcherProps) => {
  const [languageSelected, setLanguageSelected] = useState(language);
  const applySelectedLanguage = useNavigationAnalytics(AnalyticsEvent.LanguageSelected);
  const languageSelectionError = useNavigationAnalytics(AnalyticsEvent.LanguageSelectionError);
  const [error, setError] = useState(false);
  const [, setExpanded] = useContext(ExpandedAccordionContext);

  useEffect(() => {
    setLanguageSelected(language);
  }, [language]);

  const onSelectLanguage = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setLanguageSelected(event.target.value);
    setError(false);
  };

  const onChangeLaguage = async () => {
    if (error) {
      setError(false);
      setExpanded(false);
    } else {
      const params: Partial<consumerTypes.ConsumerSharedUiData> = {
        preferredLanguage: languageSelected
      };
      const res = await updateAndRefreshSharedUiData(params);
      if (res.error || res === 'no consumer id') {
        languageSelectionError({
          title: 'Account Management',
          language: languageSelected,
          errorCode: typeof res === 'string' ? '409' : res.error.response.data.errorCode,
          errorMessage: typeof res === 'string' ? res : res.error.response.data.errorDescription
        });
        setError(true);
        return;
      }
      applySelectedLanguage({
        title: 'Account Management',
        language: languageSelected
      });
    }
  };

  const handlePhoneNumber = () => {
    LinkingServices.callPhoneNumber(cleanPhone(technicalHelpNumber));
  };

  return (
    <MuiBox>
      <MuiTypography
        align="center"
        id="language-title"
        color={Color.primary}
        fontSize={FontSize.large}
        fontWeight={FontWeight.semibold}
        data-testid="language-title"
      >
        {switcherTranslations[languageSelected].title}
      </MuiTypography>
      <MuiBox
        marginTop={Spacing.xSmall}
        width={327}
        maxWidth="100%"
        marginLeft="auto"
        marginRight="auto"
      >
        <SelectDropdown
          fullWidth
          items={languages}
          value={languageSelected}
          itemToLabel={item => item?.label || ''}
          onChange={onSelectLanguage}
          data-testid="language-dropdown"
        />
      </MuiBox>
      {languageSelected === 'es' ? (
        <>
          <Spacer size="small" />
          <MuiTypography fontSize={FontSize.small} display="inline">
            {warningSpanishText}
          </MuiTypography>
          <MuiTypography display="inline" color={Color.link} onClick={handlePhoneNumber}>
            {technicalHelpNumber}
          </MuiTypography>
          <MuiTypography display="inline">.</MuiTypography>
        </>
      ) : null}
      {error ? (
        <>
          <Spacer size="small" />
          <MuiTypography align="center" fontSize={FontSize.base} color={Color.red}>
            {switcherTranslations[languageSelected].errorText}
          </MuiTypography>
        </>
      ) : null}
      <MuiBox
        marginTop={Spacing.small}
        width={295}
        maxWidth="100%"
        marginLeft="auto"
        marginRight="auto"
      >
        <MuiButton
          variant="contained"
          color="primary"
          fullWidth
          size="large"
          onClick={onChangeLaguage}
          disabled={languageSelected === language}
          data-testid="apply-language-button"
        >
          {error
            ? switcherTranslations[languageSelected].buttonTextError
            : switcherTranslations[languageSelected].buttonText}
        </MuiButton>
      </MuiBox>
    </MuiBox>
  );
};

const mapState = (state: RootState) => ({
  language: languageSelector(state)
});

const mapDispatch = (dispatch: RootDispatch) => ({
  updateAndRefreshSharedUiData: (sharedUiData: consumerTypes.ConsumerSharedUiData) =>
    dispatch(consumerActions.updateAndRefreshSharedUiData(sharedUiData))
});

export default connect(mapState, mapDispatch)(LanguageSwitcher);
