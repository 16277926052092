import { LogoSVG } from 'components/styled';
import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import IHLogo from 'assets/branding/logo-ih-color.svg';
import IHLogoFull from 'assets/branding/ih-logo-640x196-on-light.png';
import ihBwLogo from 'assets/branding/logo-ih-bw.svg';
import shLogo from 'assets/branding/logo-selecthealth-color-txt.svg';
import shBwLogo from 'assets/branding/logo-selecthealth-bw-txt.svg';
import shCobrandLogo from 'assets/branding/logo-selecthealth-cobrand-color.svg';
import scripiusLogo from 'assets/branding/logo-scripius-color.png';
import scripiusBwLogo from 'assets/branding/logo-scripius-bw.png';

export function HeaderSVG({ name, useSizeBP }: { name: string; useSizeBP?: boolean }) {
  return <LogoSVG name={name} set="assets" width="253" height="40" useSizeBP={useSizeBP} />;
}

export function LogoFactory({
  bigLogo,
  smallLogo = bigLogo,
  alt,
  bigHeight,
  smallHeight = bigHeight
}: {
  bigLogo: string;
  smallLogo?: string;
  alt: string;
  bigHeight: string;
  smallHeight?: string;
}) {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));
  return (
    <img
      src={isLargeScreen ? bigLogo : smallLogo}
      alt={alt}
      height={isLargeScreen ? bigHeight : smallHeight}
    />
  );
}

export function IntermountainHealthLogoSVG() {
  return LogoFactory({
    bigLogo: IHLogo,
    smallLogo: ihBwLogo,
    alt: 'Intermountain Health Logo',
    bigHeight: '50px',
    smallHeight: '40px'
  });
}

export function IntermountainHealthAuthLiteLogo({
  bigHeight = '56px',
  smallHeight = '39px'
}: {
  bigHeight: string;
  smallHeight?: string;
}) {
  return LogoFactory({
    bigLogo: IHLogoFull,
    alt: 'Intermountain Health Logo',
    bigHeight,
    smallHeight
  });
}

export function SelectHealthSVG() {
  return LogoFactory({
    bigLogo: shLogo,
    smallLogo: shBwLogo,
    alt: 'Select Health Logo',
    bigHeight: '65px',
    smallHeight: '70px'
  });
}

export function SelectHealthCoBrandSVG() {
  return LogoFactory({
    bigLogo: shCobrandLogo,
    smallLogo: shBwLogo,
    alt: 'Select Health Logo',
    bigHeight: '50px',
    smallHeight: '40px'
  });
}

export function ScripiusSVG() {
  return LogoFactory({
    bigLogo: scripiusLogo,
    smallLogo: scripiusBwLogo,
    alt: 'Scripius Logo',
    bigHeight: '35px',
    smallHeight: '42px'
  });
}
