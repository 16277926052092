import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import React, { useEffect, useState } from 'react';

const TimerComponent = ({ interval, showTimer, isExpired }) => {
  const [countInInterval, setCountInInterval] = useState(0);
  const [displayTimer, setDisplayTimer] = useState(false);
  const [backgroundTimer, setBackgroundTimer] = useState(0);
  let timerInterval;

  useEffect(() => {
    if (showTimer) {
      timer();
    } else {
      clearInterval(timerInterval);
    }
    return () => {
      clearInterval(timerInterval);
    };
  }, [showTimer]);

  const timer = () => {
    if (backgroundTimer) {
      clearInterval(backgroundTimer);
    }
    const timerValue = interval;
    let deadline = timerValue * 60 * 1000;
    const everySecond = 1000;

    timerInterval = setInterval(() => {
      if (deadline !== 0) {
        const minutes = Math.floor((deadline / 1000 / 60) % 60);
        const sec = Math.floor((deadline / 1000) % 60);

        setCountInInterval(Math.ceil((minutes * 60 + sec) / 60));
        setDisplayTimer(true);

        if (deadline === 0) {
          clearInterval(backgroundTimer);
          setDisplayTimer(false);
          isExpired();
          setBackgroundTimer(0);
        }

        deadline -= everySecond;
      } else {
        clearInterval(backgroundTimer);
        setDisplayTimer(false);
        isExpired();
        setBackgroundTimer(0);
        deadline = 0;
      }
      setBackgroundTimer(timerInterval);
    }, everySecond);
  };
  return (
    <div>
      {displayTimer && (
        <Typography>
          {countInInterval !== 0 && (
            <Box>
              <span style={{ fontWeight: 'bold' }}>Code Sent!</span> Expires in {countInInterval}{' '}
              minute
              {countInInterval > 1 ? 's' : ''}
            </Box>
          )}
        </Typography>
      )}
    </div>
  );
};

export default TimerComponent;
