import React from 'react';

export default {
  svg: (
    <g>
      <path
        d="M36.05 47.32C34.85 48.52 32.9 48.52 31.7 47.32L24.04 39.66C21.64 37.26 17.74 37.26 15.33 39.66L29.52 53.85C32.07 56.4 35.98 56.1 38.23 53.85L57.24 34.84C59.64 32.44 59.64 28.54 57.24 26.13L36.05 47.32Z"
        fill="url(#paint0_linear_630_675)"
      />
      <path
        d="M29.52 53.85C15.32 39.66 15.33 39.65 15.32 39.66C12.92 42.06 12.92 45.96 15.32 48.37L25.16 58.21C27.56 60.61 31.46 60.61 33.87 58.21L38.22 53.85C35.98 56.1 32.07 56.4 29.52 53.85Z"
        fill="url(#paint1_linear_630_675)"
      />
      <path
        d="M48.53 26.13L29.52 45.14L31.7 47.32C32.9 48.52 34.85 48.52 36.05 47.32L57.24 26.13C54.83 23.73 50.93 23.73 48.53 26.13Z"
        fill="url(#paint2_linear_630_675)"
      />
      <path
        d="M22.99 12.69C24.19 11.49 26.14 11.49 27.34 12.69L35 20.35C37.4 22.75 41.3 22.75 43.71 20.35L29.52 6.16C26.97 3.61 23.06 3.91 20.81 6.16L1.8 25.18C-0.6 27.58 -0.6 31.48 1.8 33.89L22.99 12.69Z"
        fill="url(#paint3_linear_630_675)"
      />
      <path
        d="M29.52 6.16C43.71 20.35 43.7 20.36 43.71 20.35C46.11 17.95 46.11 14.05 43.71 11.64L33.87 1.8C31.47 -0.6 27.57 -0.6 25.16 1.8L20.81 6.15C23.06 3.92 26.97 3.61 29.52 6.16Z"
        fill="url(#paint4_linear_630_675)"
      />
      <path
        d="M10.5 33.88L29.52 14.87L27.34 12.69C26.14 11.49 24.19 11.49 22.99 12.69L1.8 33.88C4.2 36.29 8.1 36.29 10.5 33.88Z"
        fill="url(#paint5_linear_630_675)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_630_675"
          x1="12.5152"
          y1="46.3623"
          x2="64.4624"
          y2="11.8444"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0222" stopColor="#DF36FF" />
          <stop offset="0.1437" stopColor="#C928F2" />
          <stop offset="0.3299" stopColor="#AE16E2" />
          <stop offset="0.5197" stopColor="#9B0AD6" />
          <stop offset="0.7134" stopColor="#9002CF" />
          <stop offset="0.917" stopColor="#8C00CD" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_630_675"
          x1="51.5049"
          y1="58.2401"
          x2="-6.11277"
          y2="11.5359"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.17" stopColor="#8C00CD" />
          <stop offset="0.3277" stopColor="#9B0AD6" />
          <stop offset="0.6292" stopColor="#C223ED" />
          <stop offset="0.83" stopColor="#DF36FF" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_630_675"
          x1="55.9055"
          y1="-2.71012"
          x2="-20.7949"
          y2="62.3243"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1344" stopColor="#8C00CD" />
          <stop offset="0.1841" stopColor="#990BD8" />
          <stop offset="0.3057" stopColor="#B521ED" />
          <stop offset="0.4126" stopColor="#C62EFA" />
          <stop offset="0.4928" stopColor="#CC33FF" />
          <stop offset="0.5588" stopColor="#C52EFA" />
          <stop offset="0.6524" stopColor="#B31FEB" />
          <stop offset="0.7619" stopColor="#9507D4" />
          <stop offset="0.7887" stopColor="#8C00CD" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_630_675"
          x1="9.09305"
          y1="70.0034"
          x2="61.0292"
          y2="35.4809"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.35" stopColor="#FF6D66" />
          <stop offset="0.4518" stopColor="#FF6764" />
          <stop offset="0.5967" stopColor="#FF545E" />
          <stop offset="0.7672" stopColor="#FF3555" />
          <stop offset="0.956" stopColor="#FF0B47" />
          <stop offset="1" stopColor="#FF0044" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_630_675"
          x1="57.6393"
          y1="63.5769"
          x2="0.0213609"
          y2="16.8725"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.17" stopColor="#FF0044" />
          <stop offset="0.2421" stopColor="#FF0F4A" />
          <stop offset="0.4873" stopColor="#FF3F5D" />
          <stop offset="0.7042" stopColor="#FF616B" />
          <stop offset="0.8825" stopColor="#FF7674" />
          <stop offset="1" stopColor="#FF7D77" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_630_675"
          x1="28.0655"
          y1="11.9888"
          x2="-2.88561"
          y2="42.9398"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B70044" />
          <stop offset="0.3295" stopColor="#FF0044" />
          <stop offset="0.3526" stopColor="#FF124A" />
          <stop offset="0.3957" stopColor="#FF2E52" />
          <stop offset="0.4443" stopColor="#FF465A" />
          <stop offset="0.5" stopColor="#FF575F" />
          <stop offset="0.567" stopColor="#FF6463" />
          <stop offset="0.6568" stopColor="#FF6B65" />
          <stop offset="0.8651" stopColor="#FF6D66" />
        </linearGradient>
      </defs>
    </g>
  ),
  viewBox: '0 0 60 61'
};
