import React, { ComponentType, useEffect } from 'react';
import { connect } from 'react-redux';
import capitalize from 'lodash/capitalize';
import {
  downloadAllergies,
  getAllergies,
  replyToAllergies
} from 'store/medicalHistory/allergies/actions';
import {
  allergiesDataSelector,
  allergiesDownloadErrorSelector,
  allergiesDownloadingSelector,
  allergiesErrorSelector,
  allergiesLoadingSelector
} from 'store/medicalHistory/allergies/selectors';
import { Allergy } from 'store/medicalHistory/types';
import { RootDispatch, RootState } from 'store/types';
import { MuiContainer, MuiBox, MuiPaper, MuiGrid } from 'theme/material-ui';
import Banner from 'screens/MedicalHistory/Banner';
import { MedicalHistoryListItem } from 'components/medicalHistory/MedicalHistoryListItem';
import DownloadErrorSnackbar from 'components/DownloadErrorSnackbar/DownloadErrorSnackbar';
import { Color } from 'modules/styles/colors';
import { formatDate } from 'modules/utils/DateUtils';
import analyticsService, { AmplitudeEventData, AnalyticsEvent } from 'services/AnalyticsService';
import {
  currentLocationPathNameSelector,
  previousLocationPathNameSelector
} from 'store/router/selectors';
import { MedicalHistoryMessage } from '../common';

export interface Props {
  allergies: Allergy[];
  loading: boolean;
  error: Error | null;
  isDownloadingAllergies: boolean;
  downloadError: string | null;
  dispatch: RootDispatch;
  currentUrl?: string;
  referringUrl?: string;
}

const orEmpty = (str: string | undefined, empty = 'N/A') => str || empty;

const renderData = (allergies: Allergy[]) => {
  return (
    <MuiBox width="100%" pt={3}>
      {allergies.map((item: Allergy) => (
        <MuiBox mb={1} key={item.id}>
          <MuiPaper data-testid="allergies-list-item" elevation={2}>
            <MedicalHistoryListItem
              title={capitalize(item.name)}
              body={formatDate(item.recordedDate)}
              secondaryBody={orEmpty(item.reaction)}
              itemBackgroundColor={Color.white}
              hideInfoIcon
            >
              <MuiBox p={3}>
                <MuiGrid container spacing={1} aria-label="allergies list">
                  <MuiGrid item xs={4}>
                    <MuiBox fontWeight="fontWeightBold" textAlign="left">
                      Estimated onset:
                    </MuiBox>
                  </MuiGrid>
                  <MuiGrid item xs={6}>
                    <MuiBox data-testid="estimated-onset" style={{ minHeight: '19px' }}>
                      {orEmpty(item.onsetDate)}
                    </MuiBox>
                  </MuiGrid>
                  <MuiGrid item xs={4}>
                    <MuiBox fontWeight="fontWeightBold" textAlign="left">
                      Reaction:
                    </MuiBox>
                  </MuiGrid>
                  <MuiGrid item xs={6}>
                    <MuiBox data-testid="reaction" style={{ minHeight: '19px' }}>
                      {orEmpty(item.reaction)}
                    </MuiBox>
                  </MuiGrid>
                  <MuiGrid item xs={4}>
                    <MuiBox fontWeight="fontWeightBold" textAlign="left">
                      Last updated:
                    </MuiBox>
                  </MuiGrid>
                  <MuiGrid item xs={6}>
                    <MuiBox data-testid="last-updated" style={{ minHeight: '19px' }}>
                      {formatDate(item.lastUpdated)}
                    </MuiBox>
                  </MuiGrid>
                  {/* <MuiGrid item xs={4}>
                    <MuiBox fontWeight="fontWeightBold" textAlign="left">
                      Original Recorded Date:
                    </MuiBox>
                  </MuiGrid>
                  <MuiGrid item xs={6}>
                    <MuiBox data-testid="recorded-date" style={{ minHeight: '19px' }}>
                      {formatDate(item.recordedDate)}
                    </MuiBox>
                  </MuiGrid>
                 <MuiGrid item xs={4}>
                    <MuiBox fontWeight="fontWeightBold" textAlign="left">
                      Comments:
                    </MuiBox>
                  </MuiGrid>
                  <MuiGrid item xs={6}>
                    <MuiBox data-testid="comments">{orEmpty(item.comments)}</MuiBox>
                  </MuiGrid> */}
                </MuiGrid>
              </MuiBox>
            </MedicalHistoryListItem>
          </MuiPaper>
        </MuiBox>
      ))}
    </MuiBox>
  );
};

export const Allergies = ({
  allergies,
  loading,
  error,
  isDownloadingAllergies,
  downloadError,
  dispatch,
  currentUrl,
  referringUrl
}: Props) => {
  useEffect(() => {
    dispatch(getAllergies());
  }, [getAllergies]);

  const data: AmplitudeEventData = {
    currentUrl,
    referringUrl
  };

  const handleShareAllergies = () => {
    analyticsService.logEvent(AnalyticsEvent.ShareAllergiesClicked, data);
    dispatch(replyToAllergies(allergies, currentUrl));
  };

  const handleDownloadAllergies = () => {
    analyticsService.logEvent(AnalyticsEvent.DownloadAllergiesClicked, data);
    dispatch(downloadAllergies());
  };

  return (
    <>
      <DownloadErrorSnackbar hasError={!!downloadError} onRetry={handleDownloadAllergies} />
      <Banner
        onDownloadPress={handleDownloadAllergies}
        onTransmitPress={handleShareAllergies}
        isDownloading={isDownloadingAllergies}
      />
      <MuiContainer maxWidth="lg">
        <MedicalHistoryMessage type="allergies" loading={loading} data={allergies} error={error} />
        {renderData(allergies)}
      </MuiContainer>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  error: allergiesErrorSelector(state),
  loading: allergiesLoadingSelector(state),
  isDownloadingAllergies: allergiesDownloadingSelector(state),
  downloadError: allergiesDownloadErrorSelector(state),
  allergies: allergiesDataSelector(state),
  currentUrl: currentLocationPathNameSelector(state),
  referringUrl: previousLocationPathNameSelector(state)
});

export default connect(mapStateToProps)(Allergies as ComponentType);
