import React from 'react';

import FlexBox from 'components/UI/Layout/FlexBox';
import DefaultLabel from 'components/UI/Labels/DefaultLabel';

import { Spacing } from 'modules/styles/variables';

import { MuiGrid, MuiGridList } from 'theme/material-ui';

import BadgePanel from './BadgePanel';

interface Props {
  data?: any[];
  keyField: string;
  badgeText?: (item: any) => string;
  badgeTextColor?: string;
  renderItem: Function;
  onItemPressed: Function;
  emptyCaption?: string;
  numColumns: number;
  badgeHeight?: string;
  itemSpacing?: number;
}

const BadgePanelList = (props: Props) =>
  props.data?.length === 0 ? (
    <FlexBox flex={1} alignItems="center" justifyContent="center" spacing={Spacing.large}>
      <DefaultLabel>{props.emptyCaption}</DefaultLabel>
    </FlexBox>
  ) : (
    <MuiGridList cellHeight="auto" cols={props.numColumns} spacing={props.itemSpacing}>
      {props.data?.map((item, index) => {
        return (
          <MuiGrid item xs={12} md="auto" key={item[props.keyField].toString()}>
            <BadgePanel
              onClick={() => props.onItemPressed(item)}
              badgeText={props.badgeText ? props.badgeText(item) : item.badgeText}
              badgeColor={item.badgeColor || undefined}
              badgeTextColor={item.badgeTextColor || undefined}
              badgeHeight={props.badgeHeight}
            >
              {props.renderItem(item, index)}
            </BadgePanel>
          </MuiGrid>
        );
      })}
    </MuiGridList>
  );

BadgePanelList.defaultProps = {
  data: [],
  emptyCaption: undefined,
  numColumns: 1
};

export default BadgePanelList;
