import React, { useMemo, useState } from 'react';

import { ConnectCareTextField } from 'components/ConnectCare';
import FlexBox from 'components/UI/Layout/FlexBox';
import { Color } from 'modules/styles/colors';
import { Spacing } from 'modules/styles/variables';
import { spacings } from 'modules/utils/StylesUtils';
import { TriageQuestion, UpdateVisitContextForm, VisitContext } from 'store/amwell/types';
import { MuiBox, MuiGrid, MuiTypography } from 'theme/material-ui';
import { awData } from '../utils';

export interface Props {
  visitContext: VisitContext;
  loading: boolean;
  onVisitContextChange: (updateVisitContextForm: UpdateVisitContextForm) => void;
  isLargeScreen?: boolean;
}

const getBy = (q: TriageQuestion, opts = 'question') => awData(q)[opts];

const initValues = (triageQuestions: TriageQuestion[]) => {
  return triageQuestions.reduce((acc, cur) => {
    acc[getBy(cur)] = cur.answer || '';
    return acc;
  }, {} as { [question: string]: string });
};

const getQuestions = (triageQuestions: TriageQuestion[]) => {
  if (triageQuestions && triageQuestions.length) {
    return triageQuestions.map(q => ({
      question: getBy(q),
      answer: q.answer
    }));
  }
  return [];
};

function ConnectCareTriageQuestions(props: Props) {
  const { visitContext, onVisitContextChange, loading, isLargeScreen } = props;

  const questions: TriageQuestion[] = useMemo(() => getQuestions(visitContext.triageQuestions), [
    visitContext.triageQuestions
  ]);

  const [values, setValues] = useState(initValues(visitContext.triageQuestions));

  const onChange = (question: string) => (e: React.ChangeEvent<any>) => {
    setValues({ ...values, [question]: e.target.value });
  };

  const onBlur = (question: string) => () => {
    const questionsToUpdate: TriageQuestion[] = [...questions];
    const updateIdx = questionsToUpdate.findIndex(q => getBy(q) === question);

    // May want to capture the inability to find the index and handle the error?
    if (updateIdx === -1) return;

    questionsToUpdate[updateIdx].answer = values[question];
    onVisitContextChange({ triageQuestions: questionsToUpdate });
  };

  if (!questions.length) return null;

  return (
    <>
      <MuiGrid item xs>
        <MuiBox bgcolor={Color.foreColor} padding={spacings(4, isLargeScreen ? 8 : 3)}>
          <MuiGrid container direction="column" spacing={4} wrap="nowrap">
            {questions.map((q, i) => {
              const question = getBy(q);

              return (
                <MuiGrid key={question} item xs>
                  <FlexBox flexDirection="row" vSpacingBottom={Spacing.medium}>
                    <MuiTypography style={{ marginRight: 4 }}>{i + 1}.</MuiTypography>
                    <MuiTypography>{question}</MuiTypography>
                  </FlexBox>
                  <ConnectCareTextField
                    name={question}
                    multiline
                    rows={4}
                    disabled={loading}
                    value={values[question]}
                    onChange={onChange(question)}
                    onBlur={onBlur(question)}
                  />
                </MuiGrid>
              );
            })}
          </MuiGrid>
        </MuiBox>
      </MuiGrid>
    </>
  );
}

export default ConnectCareTriageQuestions;
