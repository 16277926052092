import React from 'react';
import { useLocation } from 'react-router-dom';

import Svg from 'components/UI/Svg/Svg';
import FlexBox from 'components/UI/Layout/FlexBox';
import { Color } from 'modules/styles/colors';
import { MuiTypography } from 'theme/material-ui';
import { FontSize, FontWeight } from 'modules/styles/variables';
import { convertToLowerKabobCase } from 'modules/utils/StringUtils';
import {
  HelpDivider,
  SidebarLink,
  SidebarMuiBadge,
  SidebarMuiListItem,
  SidebarMuiListIcon,
  SidebarResourceListItem
} from './styled';

interface ComponentProps {
  routeText: string;
  routeName?: string;
  set?: 'web' | 'assets' | 'material' | 'ionic' | 'downloading' | undefined;
  disabled?: boolean;
  iconName?: string;
  iconSize?: string | number;
  newWindow?: boolean;
  onClick?: Function;
  unreadCount?: number;
  hasBorder?: boolean;
  titleText?: string;
}

interface ListItemProps {
  active: boolean;
  disabled: boolean;
}

const ListItemButton = ({
  set,
  disabled,
  iconName,
  iconSize,
  newWindow,
  onClick,
  routeName,
  routeText,
  unreadCount,
  hasBorder,
  titleText
}: ComponentProps) => {
  const location = useLocation().pathname;
  const MessagingListItem = ({ active, disabled }: ListItemProps) => {
    return (
      <SidebarMuiListItem button>
        {unreadCount > 0 ? (
          <SidebarMuiBadge badgeContent={unreadCount}>
            <SidebarMuiListIcon>
              <Svg
                name="SNavMessages"
                set="assets"
                size={iconSize}
                disabled={false}
                active={active}
              />
            </SidebarMuiListIcon>
          </SidebarMuiBadge>
        ) : (
          <SidebarMuiListIcon>
            <Svg
              name="SNavMessages"
              set="assets"
              size={iconSize}
              disabled={disabled}
              active={active}
            />
          </SidebarMuiListIcon>
        )}
        <MuiTypography
          fontSize={FontSize.base}
          fontWeight={FontWeight.semibold}
          color={disabled ? Color.grayHue5 : active ? Color.secondary : Color.primary}
        >
          {routeText}
        </MuiTypography>
      </SidebarMuiListItem>
    );
  };

  const ResourceListItem = () => {
    return (
      <SidebarResourceListItem button>
        {iconName ? (
          <SidebarMuiListIcon>
            <Svg name={iconName} set={set || 'assets'} size={iconSize} />
          </SidebarMuiListIcon>
        ) : null}
        <MuiTypography fontSize={FontSize.base} color={Color.graySidebarHeading}>
          {routeText}
        </MuiTypography>
      </SidebarResourceListItem>
    );
  };

  const SidebarListItem = ({ active, disabled }: ListItemProps) => {
    return (
      <SidebarMuiListItem button onClick={onClick}>
        <SidebarMuiListIcon>
          <Svg
            name={iconName}
            set={set || 'assets'}
            size={iconSize}
            disabled={disabled}
            active={active}
          />
        </SidebarMuiListIcon>
        <FlexBox flexDirection="column">
          {titleText ? <MuiTypography fontSize={FontSize.xsmall}>{titleText}</MuiTypography> : null}
          <MuiTypography
            fontSize={FontSize.base}
            fontWeight={FontWeight.semibold}
            color={disabled ? Color.grayHue5 : active ? Color.secondary : Color.primary}
          >
            {routeText}
          </MuiTypography>
        </FlexBox>
      </SidebarMuiListItem>
    );
  };

  if (!routeName) {
    return <SidebarListItem active={!!(routeName === location)} disabled={disabled || false} />;
  }

  return (
    <SidebarLink
      as={newWindow ? 'a' : null}
      href={newWindow ? routeName : null}
      target={newWindow ? '_blank' : null}
      data-testid={convertToLowerKabobCase(routeText?.toString(), `-sidebar-link`)}
      to={routeName}
      onClick={onClick}
      disabled={disabled || false}
    >
      {iconName === 'SNavMessages' ? (
        <MessagingListItem active={!!(routeName === location)} disabled={disabled || false} />
      ) : newWindow ? (
        <>
          {hasBorder ? <HelpDivider /> : null}
          <ResourceListItem />
        </>
      ) : (
        <SidebarListItem active={!!(routeName === location)} disabled={disabled || false} />
      )}
    </SidebarLink>
  );
};

export default ListItemButton;
