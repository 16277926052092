import styled, { css } from 'styled-components';
import { rgba } from 'polished';
import { Box } from '@material-ui/core';
import { dfdDefaultTheme, MuiPaper } from 'theme/material-ui';
import { ZIndex, FontWeight } from 'modules/styles/variables';
import { spacing } from 'modules/utils/StylesUtils';
import { Color } from 'modules/styles/colors';
import { StyledAppBar } from 'components/UI/Header/styled';
import { headerHeight } from 'components/AuthLayout/styled';
import { muiButtonStyles } from 'screens/ConnectCare/ConnectCareWaitingRoom/ConnectCareWebRTC/styled/WebRTCStyles';

export const WizardDialog = styled(MuiPaper)`
  display: flex;
  width: 100%;
  height: 100%;
  z-index: ${ZIndex.max};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding-top: ${headerHeight}px;
`;

export const WizardHeader = styled(StyledAppBar)`
  &&& {
    z-index: ${ZIndex.base + 1};
    width: 100%;
  }
`;

export const WizardSideBar = styled(MuiPaper)`
  height: 100%;
  background: ${Color.white};
  padding: 12px;
  border-right: 1px solid ${rgba(Color.black, 0.1)};
`;

export const WizardContent = styled(Box)<{ open: boolean }>`
  position: relative;
  z-index: ${ZIndex.base};
  overflow: hidden;
  flex: 1 1 auto;

  & .awcore-tmc-drawer {
    ${dfdDefaultTheme.breakpoints.down('md')} {
      position: absolute;
      transition: transform 200ms cubic-bezier(0.4, 0, 0.2, 1);
      left: 0;
      max-width: 100%;
     ${props =>
       css`
         transform: translateX(${props.open ? '0%' : '-100%'});
       `}
  }

  & .awcore-tmc-step {
    color: ${Color.textLight};
  }

  & .awcore-tmc-step-test h3 {
    padding: 0;
    margin-top: ${spacing(1)};
  }

  & .awcore-tmc-microphone-meter p {
    width: 100%;
    margin: 0;
  }

  & .awcore-tmc-step-start-test-button,
  & .awcore-tmc-step-fail-button,
  & .awcore-tmc-step-pass-button,
  & .awcore-drawer-content button {
    ${muiButtonStyles};
    height: auto;
    font-size: 0.875rem;
    font-weight: ${FontWeight.semibold};
    text-transform: none;
  }

  & .awcore-tmc-audio-controls button {
    height: 40px;
    color: transparent;
  }
`;
