import React from 'react';
import Typography from 'components/UI/Typography';
import FlexBox from 'components/UI/Layout/FlexBox';
import { InfoCard } from 'components/InfoCard';
import { IconSize, Spacing } from 'modules/styles/variables';
import { Color } from 'modules/styles/colors';
import { useHistory } from 'react-router-dom';
import { Svg } from 'components/UI/Svg';
import { AmountRow, StyledBox } from './styled';
import { MuiButton, MuiBox } from 'theme/material-ui';
import DashboardBillingHeader from './DashboardBillingHeader';
import { BILLS_TAB } from 'store/billing/constants';
import { getTextPluralized } from 'lib/utils';
import formatMoney from 'services/formatMoney';
import { useMediaQuery, useTheme } from '@material-ui/core';

export interface DashboardBillingInfoProps {
  numberOfStatements?: number;
  amountDue?: number;
  dueStatus?: string;
}

const DashboardBillingInfo = ({
  amountDue = 0,
  numberOfStatements,
  dueStatus
}: DashboardBillingInfoProps) => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('xl'));
  const history = useHistory();

  const handleViewStatements = () => {
    history.push('/u/billing', {
      isExpanded: BILLS_TAB
    });
  };
  const handleMakePayment = () => {
    history.push('/u/billing/bills');
  };

  const getBillsTextPlurals = getTextPluralized('New statement', 'New statements');

  return (
    <InfoCard
      testID="dashboard-billing-info"
      customHeader={() => <DashboardBillingHeader dueStatus={dueStatus} />}
    >
      <StyledBox>
        <AmountRow>
          <Typography>
            {`${numberOfStatements} ${getBillsTextPlurals(numberOfStatements)}`}
          </Typography>
          <FlexBox flexDirection="row" style={{ marginLeft: 'auto' }}>
            <Typography style={{ alignSelf: 'flex-start' }}>$</Typography>
            <Typography fontSize={26}>{formatMoney(amountDue)}</Typography>
          </FlexBox>
        </AmountRow>
        <FlexBox flexDirection="row" justifyContent="space-evenly" alignItems="center">
          <MuiButton
            style={{ width: isLargeScreen ? '100%' : '35%' }}
            data-testid="billing-view-statements-button"
            onClick={handleViewStatements}
            variant="outlined"
            color="primary"
          >
            View
          </MuiButton>
          <MuiBox width={Spacing.medium}></MuiBox>
          <MuiButton
            style={{ width: isLargeScreen ? '100%' : '65%' }}
            data-testid="billing-make-payment-button"
            onClick={handleMakePayment}
            endIcon={
              <Svg color={Color.white} set="material" name="chevron-right" size={IconSize.base} />
            }
            variant="contained"
            color="primary"
          >
            Make payments
          </MuiButton>
        </FlexBox>
      </StyledBox>
    </InfoCard>
  );
};

export default DashboardBillingInfo;
