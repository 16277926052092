import React from 'react';
import { isSafari, isMobileBrowser, isTablet } from 'lib/browser';
import { getFileSizeInMegaBytes, b64toBlob, isBase64 } from 'utils';
import Typography from 'components/UI/Typography';
import { MuiBox } from 'theme/material-ui';
import DownloadLargeDocumentCard from 'components/common/DownloadLargeDocumentCard';

export interface DocumentViewProps {
  content: string;
  contentType: string;
  ariaLabel?: string;
  documentName?: string;
}

const maxDocumentSize = 2.5;

const DocumentView = ({ content, contentType, ariaLabel, documentName }: DocumentViewProps) => {
  if (content && contentType) {
    const sizeOfContent = getFileSizeInMegaBytes(content);
    // Handle as PDF
    if (contentType.includes('application/pdf')) {
      // If mobile browser, show button to open pdf in alternate browser window
      // Mobile pdf rendering is not working in mobile safari and chrome.
      if (isMobileBrowser() || isTablet) {
        // eslint-disable-next-line no-undef
        const blob = new Blob([b64toBlob(content)], { type: contentType });
        const fileURL = URL.createObjectURL(blob);
        return (
          <MuiBox style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            {/* eslint-disable-next-line react/jsx-no-target-blank */}
            <a href={fileURL} target="_blank">
              Click to view document
            </a>
          </MuiBox>
        );
      }
      if (sizeOfContent > maxDocumentSize) {
        return (
          <DownloadLargeDocumentCard
            buttonProps={{
              download: `${documentName || 'pdf-document'}.pdf`,
              href: `data:application/pdf;base64,${encodeURI(content)}`
            }}
          />
        );
      }
      // Safari seems to have trouble with the pdf object web api
      // however, it does just fine with iframes.
      if (isSafari()) {
        return (
          // eslint-disable-next-line jsx-a11y/iframe-has-title
          <iframe
            style={{ width: '100%', height: '100%' }}
            src={`data:application/pdf;base64, ${encodeURI(content)}`}
          ></iframe>
        );
      }

      if (isBase64(content)) {
        // eslint-disable-next-line no-undef
        const blob = new Blob([b64toBlob(content)], { type: contentType });
        const fileURL = URL.createObjectURL(blob);

        return (
          <object
            data={`${fileURL}#toolbar=0&navpanes=0`}
            type={contentType}
            width="100%"
            height="100%"
            aria-label={ariaLabel || 'pdf-document'}
          />
        );
      }
    }
    // Handle as HTML
    if (contentType?.includes('text/html')) {
      const style = {
        background: 'white',
        padding: 24,
        borderRadius: 4,
        border: '1px solid rgba(0,0,0,0.1)'
      };

      if (isBase64(content)) {
        return (
          <object
            data={`data:${contentType};base64,${content}`}
            style={{
              ...style,
              margin: 10,
              height: 'calc(100% - 20px)',
              width: 'calc(100% - 20px)'
            }}
            aria-label={ariaLabel || 'html-document'}
          />
        );
      }

      return (
        <div
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: content }}
          style={style}
        />
      );
    }
  }

  return <Typography>Unable to open document type.</Typography>;
};

export default DocumentView;
