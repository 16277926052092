import React from 'react';

import Box from '@material-ui/core/Box';
import { MuiBox, MuiTypography } from 'theme/material-ui';

import { FontSize, FontWeight } from 'modules/styles/variables';

import Spacer from 'components/UI/Layout/Spacer';
import { StyledAsterisk } from 'components/RequiredFieldsLegend';

import { COVID_SYMPTOMS } from 'lib/covidScreening/types';
import {
  StyledListItem,
  StyledMainList,
  StyledMainUL
} from 'screens/CovidScreening/Components/styled';
import { useLanguageSwitcher } from 'lib/hooks/useLanguageSwitcher';
import { translations } from 'lib/constants/translations/screens/covidGuest/reasons';

export default function IntroductionText() {
  const screenText = useLanguageSwitcher(translations);

  return (
    <>
      <MuiBox my={3}>
        <MuiBox mb={1}>
          <MuiTypography fontSize={FontSize.base} fontWeight={FontWeight.semibold}>
            {screenText?.paragraph_text}
          </MuiTypography>
        </MuiBox>

        <Box mb={5} mt={1}>
          <StyledMainUL>
            <StyledListItem>
              <MuiTypography>
                {COVID_SYMPTOMS.map(item => (
                  <StyledMainList key={item.key}>{screenText?.[item.key]}</StyledMainList>
                ))}
              </MuiTypography>
            </StyledListItem>
          </StyledMainUL>
        </Box>

        <MuiBox mb={1}>
          <MuiTypography>{screenText?.paragraph_text2}</MuiTypography>
        </MuiBox>
      </MuiBox>

      <Box mb={3}>
        <StyledMainUL>
          <StyledListItem>
            <MuiTypography>
              {' '}
              {screenText?.bullet_point7_1}{' '}
              <a
                data-testid="url-covid-testing-locations"
                href="https://coronavirus.utah.gov/covid-testing-locations-list"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://coronavirus.utah.gov/covid-testing-locations-list.
              </a>{' '}
              {screenText?.bullet_point7_2}
            </MuiTypography>
          </StyledListItem>

          <StyledListItem>
            <MuiTypography> {screenText?.bullet_point8} </MuiTypography>
          </StyledListItem>

          <StyledListItem>
            <MuiTypography> {screenText?.bullet_point9} </MuiTypography>
          </StyledListItem>
        </StyledMainUL>
      </Box>

      <Spacer size="small" />

      <MuiBox mb={1}>
        <MuiTypography display="inline">
          <StyledAsterisk />
          {screenText?.instructions}{' '}
        </MuiTypography>
      </MuiBox>
    </>
  );
}
