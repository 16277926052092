import styled from 'styled-components';
import { MuiChip, MuiBox, dfdDefaultTheme } from 'theme/material-ui';
import { BorderRadius, FontSize, Spacing } from 'modules/styles/variables';
import { Color } from 'modules/styles/colors';
import { Icon } from 'components/Icon';

interface Props {
  error: boolean;
  isUploading: boolean;
}

export const ChipBox = styled(MuiBox)`
  width: 50%;
  display: inline-block;
  ${dfdDefaultTheme.breakpoints.down('xs')} {
    width: 100%;
  }
`;

export const FileChip = styled(MuiChip)`
  &.MuiChip-root {
    ${(props: Props) => `
      background-color: ${!props.error ? Color.white : Color.redLight};
    `}
    border-radius: ${BorderRadius.none};
    color: ${Color.textDark};
    margin: ${Spacing.medium}px;
    height: 50px;
    width: 95%;
    justify-content: flex-start;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }
  & > .MuiChip-icon {
    ${({ isUploading, error }: Props) => {
      let borderColor = Color.grayLight;
      let backgroundColor = Color.grayLight;
      const uploadingOrError = isUploading || error;

      if (isUploading) {
        borderColor = Color.blueHue3;
        backgroundColor = Color.translucent;
      } else if (error) {
        borderColor = Color.red;
        backgroundColor = Color.translucent;
      }

      return `
        padding-left: ${uploadingOrError ? Spacing.small : 0}px;
        background-color: ${backgroundColor};
        border-left: 3px solid ${borderColor};
      `;
    }}
    height: 100%;
    left: -${Spacing.small}px;
  }
  & > .MuiChip-label {
    flex: 1;
    padding: 0 ${Spacing.large}px;
    top: -${Spacing.medium}px;
  }
  & > .MuiChip-deleteIcon {
    height: 25px;
    width: 25px;
  }
`;

export const FileSizeSpan = styled.span`
  font-size: ${FontSize.small}px;
  position: absolute;
  left: 48px;
  top: ${Spacing.xLarge}px;
`;

export const RetryIcon = styled(Icon)`
  background-color: inherit;
  position: absolute;
  right: 45px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
`;
