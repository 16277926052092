import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import dayjs, { Dayjs } from 'dayjs';
import Svg from 'components/UI/Svg/Svg';
import capitalize from 'lodash/capitalize';
import { IconSize, Spacing } from 'modules/styles/variables';
import {
  MuiList,
  MuiListItem,
  MuiBox,
  MuiTypography,
  MuiPaper,
  MuiRadioGroup,
  MuiRadio,
  MuiFormControlLabel
} from 'theme/material-ui';
import { FlexBoxRow } from 'components/UI/Layout/FlexBox';
import { BlueDotStyled } from 'components/testResults/styled';
import FilterCalendar from 'components/common/FilterModal/FilterCalendar';
import { FilterDrawer } from 'components/FilterDrawer';
import { Drawer } from 'components/common/Drawer';
import {
  TestResult,
  TestResultsFilter,
  DateRangeFilterParams,
  SortByValues,
  SortByMap,
  TestResultsFilterType
} from 'store/testResults/types';
import {
  updateSort,
  updateFilterOptions,
  updateDateRange,
  applyTestResultsFilters,
  resetTestResultsFilters
} from 'store/testResults/actions';
import { getDetailsPath, getLastThreeYears, filterTestResults } from 'store/testResults/utils';
import useNavigationAnalytics from 'hooks/useNavigationAnalytics';
import { LinkTo, Nullable } from 'modules/types/common';
import { DATE_DISPLAY, DAYJS_FORMAT } from 'lib/constants';
import { patchEvent } from 'store/events/actions';
import { Color } from 'modules/styles/colors';
import { AnalyticsEvent } from 'services/AnalyticsService';

interface ResultListProps {
  dateRangeFilters: DateRangeFilterParams;
  dateRangeValue: DateRangeFilterParams;
  filters: TestResultsFilter[];
  filterOptions: TestResultsFilter[];
  filterOpen: boolean;
  referringUrl: string;
  setSortByValue: (s: SortByValues) => void;
  setDateRangeValue: (d: DateRangeFilterParams) => void;
  setFilterOptions: (f: TestResultsFilter[]) => void;
  setFilterOpen: (b: boolean) => void;
  setFiltersApplied: (b: boolean) => void;
  setHasSorted: (b: boolean) => void;
  setSortOpen: (b: boolean) => void;
  sortBy: SortByValues;
  sortByValue: SortByValues;
  sortOpen: boolean;
  testResults: TestResult[];
}

interface TestResultListItem {
  id: string;
  dfdEventId?: string;
  title: string;
  body: string;
  isRead: boolean;
  to: LinkTo;
}

export const ResultList = ({
  sortBy,
  sortByValue,
  setSortByValue,
  filters,
  sortOpen,
  dateRangeFilters,
  dateRangeValue,
  setDateRangeValue,
  filterOpen,
  testResults,
  setSortOpen,
  referringUrl,
  setHasSorted,
  filterOptions,
  setFilterOptions,
  setFilterOpen,
  setFiltersApplied
}: ResultListProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const testResultItemClicked = useNavigationAnalytics(AnalyticsEvent.TestResultsItemClicked);

  const [dateRangeOpen, setDateRangeOpen] = useState<boolean>(false);
  const [disableApply, setDisableApply] = useState<boolean>(true);
  const filteredTestResults = filterTestResults(testResults, filters, dateRangeFilters, sortBy);
  const years = getLastThreeYears();
  const mappedYears = years.map((year: string) => ({ label: year, value: year, checked: false }));
  const dateRangeOptions = [...mappedYears, { label: 'Custom', value: 'custom', checked: false }];

  const testResultsList: TestResultListItem[] = filteredTestResults?.map((t: TestResult) => ({
    id: t.eventId,
    dfdEventId: t.dfdEventId,
    title: t.name,
    body: t.testDate,
    isRead: t.isRead,
    to: {
      pathname: getDetailsPath(t),
      state: { result: t }
    }
  }));

  useEffect(() => {
    // clear filters if not within test-result navigation stack
    if (!referringUrl?.includes('test-results')) {
      handleFilterClear();
      setSortByValue(SortByValues.desc);
      dispatch(updateSort(SortByValues.desc));
    }
  }, []);

  useEffect(() => {
    if (dateRangeValue.year === 'Custom') {
      setDateRangeOpen(true);
    } else {
      setDateRangeOpen(false);
    }
  }, [filterOptions, dateRangeValue]);

  const markTestResultAsRead = async (id: Nullable<string>) => {
    if (id) {
      await dispatch(patchEvent(id, { read: true }, true));
      // Todo: Look into if we even need this as we are navigating to a different page on click already
      // refetch();
    }
  };

  const handleTestResultSelect = (item: TestResultListItem) => {
    testResultItemClicked();
    if (!item.isRead && item?.dfdEventId) markTestResultAsRead(item.dfdEventId);
    history.push(item?.to?.pathname, item?.to?.state);
  };

  const handleFilterApply = () => {
    dispatch(updateFilterOptions(filterOptions));
    dispatch(updateDateRange(dateRangeValue));
    dispatch(applyTestResultsFilters());
    setDisableApply(true);
    setFiltersApplied(true);
    setFilterOpen(false);
  };

  const handleFilterClear = () => {
    dispatch(resetTestResultsFilters());
    setDisableApply(true);
    setDateRangeValue({
      year: '',
      startDate: dayjs().format(DAYJS_FORMAT),
      endDate: dayjs().format(DAYJS_FORMAT)
    });
    setDateRangeOpen(false);
    setFilterOptions(
      filterOptions?.map(filterOption => {
        return {
          ...filterOption,
          checked: false
        };
      })
    );
    setFilterOpen(false);
    setFiltersApplied(false);
  };

  return (
    <>
      <MuiBox>
        <MuiPaper elevation={2}>
          <MuiList>
            <MuiListItem divider>
              <MuiBox
                display="flex"
                flex="1"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
                color={Color.foreColor}
              >
                <MuiBox>
                  <MuiTypography color="textPrimary" fontWeight="bold">
                    Name
                  </MuiTypography>
                </MuiBox>

                <MuiBox width="11%">
                  <MuiTypography color="textPrimary" fontWeight="bold">
                    Date
                  </MuiTypography>
                </MuiBox>
              </MuiBox>
            </MuiListItem>
            {testResultsList?.map((item: TestResultListItem) => {
              const name = item?.title ? capitalize(item.title) : '';
              const date = item?.body ? dayjs(item.body).format(DATE_DISPLAY) : '';
              return (
                <MuiListItem
                  key={item?.id}
                  divider
                  button
                  onClick={() => handleTestResultSelect(item)}
                  data-testid="test-results-list"
                >
                  <MuiBox
                    display="flex"
                    flex="1"
                    justifyContent="space-between"
                    alignItems="center"
                    width="100%"
                    py="6px"
                  >
                    <MuiBox>
                      <MuiTypography color="textPrimary">
                        {!item?.isRead ? <BlueDotStyled hOffsetRight={Spacing.large} /> : null}
                        {name}
                      </MuiTypography>
                    </MuiBox>

                    <MuiBox>
                      <FlexBoxRow alignItems="center">
                        <MuiBox mr={Spacing.xSmall}>
                          <MuiTypography color="textPrimary">{date}</MuiTypography>
                        </MuiBox>
                        <Svg
                          set="assets"
                          name="ChevronRight"
                          size={IconSize.xmedium}
                          fill={Color.black}
                        />
                      </FlexBoxRow>
                    </MuiBox>
                  </MuiBox>
                </MuiListItem>
              );
            })}
          </MuiList>
        </MuiPaper>
      </MuiBox>

      <FilterDrawer
        open={filterOpen}
        onClose={() => setFilterOpen(false)}
        disabled={disableApply}
        onApply={handleFilterApply}
        onClear={handleFilterClear}
      >
        <FilterDrawer.Panel title="Test Type">
          <FilterDrawer.CheckBoxGroup
            list={filterOptions?.filter(
              (option: TestResultsFilter) => option.type === TestResultsFilterType.TEST_TYPE
            )}
            renderLabel={item => item.label}
            checked={item => item.checked}
            handleChange={(item, checked) => {
              const options = filterOptions?.map(value => {
                if (value.label === item?.label) return { ...value, checked };
                return value;
              });
              setDisableApply(false);
              setFilterOptions(options);
            }}
          />
        </FilterDrawer.Panel>

        <FilterDrawer.Panel title="Date Range">
          <FilterDrawer.RadioGroup
            list={dateRangeOptions}
            value={dateRangeValue.year}
            renderLabel={item => item?.label}
            handleChange={(e: any) => {
              const value = e?.target?.value;
              if (value === 'Custom') {
                setDateRangeOpen(true);
              } else {
                setDateRangeOpen(false);
              }
              // allow unselect
              if (dateRangeValue.year === value) {
                setDateRangeValue({ ...dateRangeValue, year: '' });
              } else {
                setDateRangeValue({ ...dateRangeValue, year: value });
              }
              setDisableApply(false);
            }}
          />
          {dateRangeOpen ? (
            <MuiBox display="flex" flexDirection="row" paddingTop="15px">
              <FilterCalendar
                value={dateRangeValue?.startDate as Dayjs}
                label="From"
                handleChange={date => {
                  setDateRangeValue({
                    ...dateRangeValue,
                    startDate: dayjs(date).format(DAYJS_FORMAT)
                  });
                  setDisableApply(false);
                }}
              />
              <FilterCalendar
                value={dateRangeValue?.endDate as Dayjs}
                label="To"
                handleChange={date => {
                  setDateRangeValue({
                    ...dateRangeValue,
                    endDate: dayjs(date).format(DAYJS_FORMAT)
                  });
                  setDisableApply(false);
                }}
              />
            </MuiBox>
          ) : null}
        </FilterDrawer.Panel>

        <FilterDrawer.Panel title="Providers">
          <FilterDrawer.CheckBoxGroup
            list={filterOptions?.filter(
              (option: TestResultsFilter) => option.type === TestResultsFilterType.PROVIDER
            )}
            renderLabel={item => item?.label}
            checked={item => item?.checked}
            handleChange={(item, checked) => {
              const options = filterOptions?.map(value => {
                if (value.label === item?.label) return { ...value, checked };
                return value;
              });
              setDisableApply(false);
              setFilterOptions(options);
            }}
          />
        </FilterDrawer.Panel>
      </FilterDrawer>

      <Drawer
        title="Sort"
        open={sortOpen}
        onClose={() => setSortOpen(false)}
        onApply={() => {
          dispatch(updateSort(sortByValue));
          setHasSorted(true);
          setSortOpen(false);
        }}
      >
        <MuiBox p={3}>
          <MuiRadioGroup
            aria-label="sort"
            name="sort"
            value={sortByValue}
            onChange={(_, value: string) => setSortByValue(value as SortByValues)}
          >
            <MuiFormControlLabel
              value={SortByValues.desc}
              control={<MuiRadio color="secondary" />}
              label="New to Old"
            />
            <MuiFormControlLabel
              value={SortByValues.asc}
              control={<MuiRadio color="secondary" />}
              label="Old to New"
            />
            <MuiFormControlLabel
              value={SortByValues.atoz}
              control={<MuiRadio color="secondary" />}
              label={SortByMap.atoz}
            />
            <MuiFormControlLabel
              value={SortByValues.ztoa}
              control={<MuiRadio color="secondary" />}
              label={SortByMap.ztoa}
            />
          </MuiRadioGroup>
        </MuiBox>
      </Drawer>
    </>
  );
};

export default ResultList;
