import React from 'react';
import { MuiTypography, MuiContainer, MuiBox } from 'theme/material-ui';

import { Color } from 'modules/styles/colors';
import { FontSize, Spacing } from 'modules/styles/variables';
import TypographyInlineWrapper from 'components/UI/Typography/TypographyInLineWrapper';

const openBMICalc = () => {
  if (window)
    window.open(
      'https://www.cdc.gov/healthyweight/assessing/bmi/adult_bmi/english_bmi_calculator/bmi_calculator.html',
      '_blank'
    );
};

const BMIHeader = () => (
  <MuiContainer maxWidth="lg">
    <MuiBox
      display="flex"
      mt={Spacing.mediumLarge}
      p={Spacing.xSmall}
      borderRadius={4}
      flexDirection="column"
      bgcolor={Color.white}
    >
      <MuiBox>
        <MuiBox>
          <MuiTypography fontSize={FontSize.largeHeading} data-testid="bmi-header-title">
            Body Mass Index (BMI) Trendline
          </MuiTypography>
        </MuiBox>
        <MuiBox>
          BMI is a person's weight in kilograms (or pounds) divided by the square of height in
          meters.
        </MuiBox>
        <MuiBox mt={2}>
          Link to CDC Body Mass Index{' '}
          <TypographyInlineWrapper
            fontSize={FontSize.large}
            data-testid="bmi-calculator"
            color={Color.link}
            onClick={openBMICalc}
          >
            (BMI) calculator
          </TypographyInlineWrapper>
        </MuiBox>
      </MuiBox>
    </MuiBox>
  </MuiContainer>
);

export default BMIHeader;
