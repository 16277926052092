import React, { useState } from 'react';
import { connect } from 'react-redux';

import ValidationError from 'lib/amwell/client/errors/ValidationError';

import { ConnectCareButton, ConnectCareTextField } from 'components/ConnectCare';
import { useSnack } from 'components/Snack';

import { MuiDialog, MuiDialogTitle, MuiDialogContent, MuiDialogActions } from 'theme/material-ui';

import { RootState, RootDispatch } from 'store/types';
import { VisitContext } from 'store/amwell/types';
import * as selectors from 'store/amwell/selectors';
import { setupWaitingRoomAlerts } from 'store/amwell/actions';

import { cleanNumber } from '../utils';

export interface Props {
  dispatch: RootDispatch;
  isTextMeWhenDialogVisible: boolean;
  setIsTextMeWhenReadyDialogVisible: (toggle: boolean) => void;
  setPhoneNumberSaved: (toggle: boolean) => void;
  handleClose: () => void;
  visitContext: VisitContext;
  waitingRoomAlertsError: ValidationError | Error | null;
  waitingRoomAlertsLoading: boolean;
}

export function ConnectCareWaitingRoomTextMeWhenReadyDialog(props: Props) {
  const {
    dispatch,
    isTextMeWhenDialogVisible,
    setPhoneNumberSaved,
    handleClose,
    visitContext,
    waitingRoomAlertsLoading
  } = props;

  const [smsNumber, setSmsNumber] = useState(visitContext?.callbackNumber || '');
  const [phoneNumberError, setPhoneNumberError] = useState('');

  const { create } = useSnack();

  const onChange = (e: React.ChangeEvent<any>) => {
    setSmsNumber(e.target.value);
  };

  const onSave = async () => {
    const cleanSmsNumber = cleanNumber(smsNumber);

    setPhoneNumberError('');

    dispatch(
      setupWaitingRoomAlerts({ phoneNumber: cleanSmsNumber, providerReadyAlert: true })
    ).then(res => {
      if (res.error) {
        let helperText = '';
        if (res.error instanceof ValidationError) {
          const err = res.error.errors.fieldName === 'phoneNumber' ? res.error.errors : null;
          helperText = err ? err.recoverySuggestion : '';
        }
        if (helperText) {
          setPhoneNumberError(helperText);
        } else {
          create('There was an issue setting up alerts', 'error');
        }
      } else {
        create('SMS number saved successfully!', 'success');
        setPhoneNumberSaved(true);
        handleClose();
      }
    });
  };

  return (
    <MuiDialog
      disableBackdropClick
      disableEscapeKeyDown
      open={isTextMeWhenDialogVisible}
      onClose={handleClose}
      maxWidth="md"
    >
      <MuiDialogTitle id="connect-care-waiting-room-text-me-dialog-title">
        Set SMS Number
      </MuiDialogTitle>
      <MuiDialogContent dividers>
        <ConnectCareTextField
          required
          mask="phone"
          label="Phone Number"
          placeholder="(###) ###-####"
          value={smsNumber}
          disabled={waitingRoomAlertsLoading}
          onChange={onChange}
          error={!!phoneNumberError}
          helperText={phoneNumberError}
        />
      </MuiDialogContent>
      <MuiDialogActions>
        <ConnectCareButton onClick={onSave}>Save</ConnectCareButton>
      </MuiDialogActions>
    </MuiDialog>
  );
}

const mapStateToProps = (state: RootState) => ({
  visitContext: selectors.visitContextDataSelector(state),
  waitingRoomAlertsLoading: selectors.visitWaitingRoomAlertsLoadingSelector(state)
});

export default connect(mapStateToProps)(ConnectCareWaitingRoomTextMeWhenReadyDialog);
