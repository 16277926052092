import CollapsibleListItem from 'components/common/CollapsibleList/CollapsibleListItem';
import DataLoader from 'components/UI/DataLoader/DataLoader';
import Spinner from 'components/UI/Spinner/Spinner';
import { History } from 'history';
import { HEALTH_RECORDS_DRAWERS } from 'lib/constants/healthRecords';
import { isPreventiveCareItemDue } from 'lib/healthRecords/preventativeCare/utils';
import { fluFinderWidgetUrl } from 'modules/constants/urls';
import React, { Dispatch, SetStateAction } from 'react';
import { connect } from 'react-redux';
import analyticsService, { AnalyticsEvent } from 'services/AnalyticsService';
import dayjs from 'shared/node_modules/dayjs';
import { MY_DOCS } from 'store/booking/constants';
import { PreventativeCareData, PreventativeCareItem } from 'store/preventativeCare/types';
import {
  currentLocationPathNameSelector,
  previousLocationPathNameSelector
} from 'store/router/selectors';
import { RootState } from 'store/types';
import { MuiBox, MuiPaper } from 'theme/material-ui';
import { HealthRecordsListItemPlaceholder } from '../shared';
import { PreventativeCareListItem } from './ListItem';

export interface Props {
  careItems: PreventativeCareData;
  setModalType: Dispatch<SetStateAction<string | null>>;
  setModalVisibility: (state: boolean) => void;
  setModalProps: Dispatch<SetStateAction<{}>>;
  history: History;
  error?: boolean;
  loading?: boolean;
  currentUrl?: string;
  referringUrl?: string;
}

interface SwitchProps extends Omit<Props, 'careItems'> {
  care: PreventativeCareItem;
}

type OnPressProps = Omit<Props, 'careItems'>;

export const modalTypeMap = {
  mammogram: 'mammogram',
  fluShot: 'fluShot'
};

// FLU SHOT MODAL SETTINGS
const getFluShotModalProps = (props: SwitchProps) => ({
  title: 'Flu Finder',
  description:
    'Please press "Find clinic" to visit a website with a list of clinics offering Influenza (flu) vaccines.',
  confirmLabel: 'Find clinic',
  onConfirm: () => window.open(fluFinderWidgetUrl, '_blank'),
  onCancel: () => props.setModalVisibility(false)
});

// MAMMOGRAM MODAL SETTINGS
const getMammogramModalProps = (props: SwitchProps) => ({
  onClose: () => props.setModalVisibility(false)
});

const onPreventiveCareSwitchPress = (props: OnPressProps) => (care: PreventativeCareItem) => {
  const {
    history,
    setModalType,
    setModalVisibility,
    setModalProps,
    currentUrl,
    referringUrl
  } = props;

  analyticsService.logEvent(AnalyticsEvent.PreventiveCareItemClicked, {
    currentUrl,
    referringUrl
  });

  if (!isPreventiveCareItemDue(care)) return;

  const switchProps: SwitchProps = { ...props, care };
  const fluShotModalProps = getFluShotModalProps(switchProps);
  const mammogramModalProps = getMammogramModalProps(switchProps);

  switch (care.type) {
    case 'mammogram':
      setModalType(modalTypeMap[care.type]);
      setModalProps(mammogramModalProps);
      setModalVisibility(true);
      break;
    case 'fluShot':
      setModalType(modalTypeMap[care.type]);
      setModalProps(fluShotModalProps);
      setModalVisibility(true);
      break;
    default:
      history.push('/u/get-care-now', {
        isExpanded: MY_DOCS
      });
  }
};

const getLoadingState = () => (
  <>
    <HealthRecordsListItemPlaceholder message={HEALTH_RECORDS_DRAWERS.VisitSummary.loading} />
    <MuiBox display="flex" flexDirection="row" justifyContent="center">
      <Spinner />
    </MuiBox>
  </>
);

const getErrorState = () => (
  <HealthRecordsListItemPlaceholder
    message={HEALTH_RECORDS_DRAWERS.getErrorState('preventive care')}
  />
);

const PreventativeCareList = (props: Props) => {
  const { careItems, loading, error, currentUrl, referringUrl, ...rest } = props;

  const dueCareItems = careItems ? careItems.filter(item => isPreventiveCareItemDue(item)) : [];

  const count = dueCareItems.length > 0 ? dueCareItems.length : 'no';
  const onPressFn = onPreventiveCareSwitchPress({ currentUrl, referringUrl, ...rest });

  return (
    <CollapsibleListItem
      leftIcon="PreventativeCareIcon"
      title={HEALTH_RECORDS_DRAWERS.PreventiveCare.title}
      subtitle={HEALTH_RECORDS_DRAWERS.PreventiveCare.subtitle(count)}
      data-testid="PreventativeCareCollapsible"
      analyticsEvent={AnalyticsEvent.PreventiveCareClicked}
      currentUrlForAnalytics={currentUrl}
      referringUrlForAnalytics={referringUrl}
      detailsPadding="0"
    >
      <MuiBox width="100%">
        <DataLoader
          data={dueCareItems}
          error={error}
          loading={loading}
          renderData={() => (
            <MuiPaper elevation={2}>
              {dueCareItems.map(item => (
                <PreventativeCareListItem
                  key={(dayjs() * Math.random()).toString()}
                  careItem={item}
                  onPress={() => onPressFn(item)}
                />
              ))}
            </MuiPaper>
          )}
          renderNoData={() => (
            <HealthRecordsListItemPlaceholder message="No Preventive Care Items To Schedule" />
          )}
          renderError={getErrorState}
          renderLoading={getLoadingState}
        />
      </MuiBox>
    </CollapsibleListItem>
  );
};

const mapState = (state: RootState) => ({
  currentUrl: currentLocationPathNameSelector(state),
  referringUrl: previousLocationPathNameSelector(state)
});

export default connect(mapState)(PreventativeCareList);
