import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { connect } from 'react-redux';

import { AuthType } from 'store/authentication/types';
import * as covidActions from 'store/CovidScreening/actions';
import { RootState } from 'store/types';
import * as covidScreeningSelectors from 'store/CovidScreening/selectors';
import { AnalyticsEvent } from 'services/AnalyticsService';

import RouteLeavingGuard from 'components/UI/Modals/RouteLeavingGuardModal';
import useNavigationAnalytics from 'hooks/useNavigationAnalytics';
import { useLanguageSwitcher } from 'lib/hooks/useLanguageSwitcher';
import { ExitPopUp_translations } from 'lib/constants/translations/components';

import { getCovidTestGuestPageName } from '../utils';

export interface CovidGuestLeavingGuardProps {
  resetCovidScreening: typeof covidActions.resetCovidScreening;
  flowStarted: string;
}

export const CovidGuestLeavingGuard = ({
  resetCovidScreening,
  flowStarted
}: CovidGuestLeavingGuardProps) => {
  const history = useHistory();
  const [isRouteExternal, setIsRouteExternal] = useState<string>('');
  const handleAnalyticsCovid = useNavigationAnalytics(AnalyticsEvent.CovidFlowExited);
  const screenText = useLanguageSwitcher(ExitPopUp_translations);

  if (flowStarted === 'No') {
    history.push('/guest-home/covid-screen/consent-and-agreement');
  }

  const handleNavigateFromCovidTestBooking = () => {
    const title = getCovidTestGuestPageName(history.location.pathname);
    const authentication_type = AuthType.UNAUTHENTICATED;

    handleAnalyticsCovid({ title, authentication_type });
    resetCovidScreening();

    if (isRouteExternal) {
      setIsRouteExternal('');
      if (isRouteExternal !== '/u/dashboard') return history.push(isRouteExternal);
    }

    return history.push('/guest-home');
  };

  return (
    <RouteLeavingGuard
      shouldBlockNavigation={nextLocation => {
        if (!nextLocation.pathname.includes('guest-home')) {
          setIsRouteExternal(nextLocation.pathname);
        }
        if (
          (nextLocation.pathname.includes('guest-home') &&
            history?.location?.pathname.includes('guest-covid-order-confirmation')) ||
          history?.location?.pathname.includes('guest-covid-order-status')
        ) {
          resetCovidScreening();
          return false;
        }
        if (nextLocation.pathname.includes('/guest-home/')) {
          return false;
        }

        return true;
      }}
      exitTitle={screenText.title}
      exitSubtitle={screenText.subtitle}
      exitButtonText={screenText.primaryButton}
      keepGoingButtonText={screenText.secondaryButton}
      navigate={handleNavigateFromCovidTestBooking}
    />
  );
};

const mapDispatchToProps = {
  resetCovidScreening: covidActions.resetCovidScreening
};

const mapStateToProps = (state: RootState) => ({
  flowStarted: covidScreeningSelectors.CovidScreeningFlowStarted(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(CovidGuestLeavingGuard);
