import React, { useEffect, useState } from 'react';
import FlexBox from 'components/UI/Layout/FlexBox';
import { MuiGrid, MuiTypography } from 'theme/material-ui';
import { CheckMark, CircleMark, NumberCircle } from '../components/styled';
import { PatientFormAccordion } from './styled';
import { AccordionDetails, AccordionSummary } from '@material-ui/core';
import { FontSize, FontWeight, Spacing } from 'modules/styles/variables';
import { StyledAsterisk } from 'components/RequiredFieldsLegend';

export function CircleCheck() {
  return (
    <MuiGrid item>
      <CircleMark>
        <CheckMark />
      </CircleMark>
    </MuiGrid>
  );
}

export function EVisitPatientAcordian(props: {
  children: React.ReactNode;
  defaultExpanded?: boolean;
  isValid?: boolean;
  num: number;
  required?: boolean;
  title: string;
}) {
  const { children, defaultExpanded, isValid, num, required, title } = props;
  const [isExpanded, setIsExpanded] = useState(defaultExpanded);

  useEffect(() => {
    setIsExpanded(defaultExpanded);
  }, [defaultExpanded]);
  return (
    <PatientFormAccordion
      className={isValid ? 'formComplete' : ''}
      expanded={isExpanded}
      onChange={() => setIsExpanded(!isExpanded)}
    >
      <AccordionSummary style={{ margin: Spacing.mediumLarge }}>
        <MuiGrid container xs={12} style={{}}>
          <MuiGrid item>
            <NumberCircle>{num}</NumberCircle>
          </MuiGrid>
          <FlexBox>
            <MuiTypography
              component="h2"
              fontSize={FontSize.heading}
              fontWeight={FontWeight.bold}
              color="#1B164F"
              style={{ lineHeight: '36px', paddingLeft: '10px' }}
            >
              {title}
              {required ? <StyledAsterisk /> : null}
            </MuiTypography>
          </FlexBox>
          <FlexBox flex="1 1 auto" flexDirection="row" justifyContent="flex-end">
            {!isExpanded && isValid ? <CircleCheck /> : null}
          </FlexBox>
        </MuiGrid>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </PatientFormAccordion>
  );
}
