import React from 'react';
import { TouchableOpacity } from 'react-native';
import styled from 'styled-components';

import { Color } from 'modules/styles/colors';
import { smallFontSize } from 'modules/styles/variables';

import DefaultText from 'components/UI/Typography/DefaultText';
import BodyText from 'components/UI/Typography/BodyText';

interface Props {
  label?: string;
  value: any;
  selected: boolean;
  onPress?: () => void;
  labelStyle?: string;
  tagText?: string;
  accessibilityLabel?: string;
}

const Toggle = styled.div`
  height: 12px;
  width: 12px;
  border-radius: 6px;
  background-color: ${({ selected }: { selected: boolean }) =>
    selected ? Color.primary : 'transparent'};
`;

const RadioBorder = styled.div`
  height: 22px;
  width: 22px;
  border-radius: 11px;
  border-width: 2px;
  border-color: ${({ selected }: { selected: boolean }) =>
    selected ? Color.primary : 'rgba(0,0,0,0.54)'};
  align-items: center;
  justify-content: center;
  margin-top: 3px;
`;

const Label = styled(BodyText)`
  padding-left: 10px;
  ${({ labelStyle }: { labelStyle: string }) => labelStyle}
`;

const Tag = styled(DefaultText)`
  color: ${Color.primaryHue3};
  font-size: ${smallFontSize};
  font-weight: bold;
  line-height: 28px;
  padding-left: 8px;
`;

const RadioElement = styled.div`
  flex-direction: row;
`;

function Radio(props: Props) {
  return (
    <RadioElement {...props}>
      <RadioBorder selected={props.selected}>
        <Toggle selected={props.selected} />
      </RadioBorder>
      <Label labelStyle={props.labelStyle}>{props.label}</Label>
      {props.tagText && <Tag>{props.tagText}</Tag>}
    </RadioElement>
  );
}

function RadioButton(props: Props) {
  const { onPress, ...otherProps } = props;

  if (onPress) {
    return (
      <TouchableOpacity onPress={onPress}>
        <Radio {...otherProps} />
      </TouchableOpacity>
    );
  }

  return <Radio {...otherProps} />;
}

RadioButton.defaultProps = {
  label: '',
  labelStyle: {},
  tagText: undefined
};

export default RadioButton;
