import React from 'react';

export default {
  svg: (
    <path
      fill="#60548E"
      d="M22.5 0a22.5 22.5 0 1 0 0 45 22.5 22.5 0 0 0 0-45Zm0 41.25a18.6 18.6 0 0 1-12.281-4.612.9.9 0 0 1-.319-.844.975.975 0 0 1 .544-.713c1.687-.75 3.656-1.462 5.625-2.156l2.1-.769a.468.468 0 0 0 .3-.45v-3.244a.469.469 0 0 0-.263-.337 5.776 5.776 0 0 1-2.437-4.894.506.506 0 0 0-.225-.375 2.324 2.324 0 0 1-.919-2.231 2.495 2.495 0 0 1 .769-1.875.488.488 0 0 0 .168-.506c-.468-1.706-1.124-4.256 0-5.494a2.175 2.175 0 0 1 1.876-.637.525.525 0 0 0 .43-.244c.92-1.631 3.563-2.381 6-2.381 2.757 0 5.757.956 6.282 3.056a10.668 10.668 0 0 1-.675 5.625.469.469 0 0 0 .15.506 2.494 2.494 0 0 1 .769 1.875 2.344 2.344 0 0 1-.919 2.081.509.509 0 0 0-.225.375 5.737 5.737 0 0 1-2.475 5.119.469.469 0 0 0-.225.413v3.243a.468.468 0 0 0 .3.45l2.269.825c2.025.732 3.881 1.407 5.437 2.082a.975.975 0 0 1 .563.712 1.013 1.013 0 0 1-.319.862 18.75 18.75 0 0 1-12.3 4.538Z"
    />
  ),
  viewBox: '0 0 45 45'
};
