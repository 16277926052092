import React from 'react';

import { MuiTypography } from 'theme/material-ui';

import myStrengthThumb from 'assets/HealthTools/myStrength-thumb.png';

import myStrengthScreen from './screen';
import { RouteData } from 'screens/navigation';
import { HealthToolCardData } from 'components/HealthToolsCards/HealthToolCard';

import { FontSize, FontWeight } from 'modules/styles/variables';

export const myStrengthCard: HealthToolCardData = {
  healthToolCardKey: 'myStrength',
  description: (
    <MuiTypography>
      <MuiTypography display="inline">We're partnering with </MuiTypography>
      <MuiTypography fontWeight={FontWeight.bold} display="inline">
        myStrength
      </MuiTypography>
      <MuiTypography display="inline"> to bring you behavioral health tools</MuiTypography>
    </MuiTypography>
  ),
  path: '/u/health-tools/mystrength',
  thumb: myStrengthThumb
};

const myStrengthScreenRouteData: RouteData = {
  component: myStrengthScreen,
  componentName: 'myStrengthScreen',
  crumbLabel: 'Behavioral & Mental Health Resources',
  exact: true,
  includeBanner: true,
  includeCrumbs: true,
  includeDefaultLayout: true,
  path: myStrengthCard.path,
  title: (
    <MuiTypography fontSize={FontSize.mediumHeading}>
      <MuiTypography fontSize={FontSize.mediumHeading}>
        Behavioral & Mental Health Resources{' '}
      </MuiTypography>
      <MuiTypography display="inline">provided by</MuiTypography>{' '}
      <MuiTypography
        fontSize={FontSize.mediumHeading}
        fontWeight={FontWeight.bold}
        display="inline"
      >
        myStrength
      </MuiTypography>
    </MuiTypography>
  )
};

export const myStrengthRouteData: RouteData[] = [myStrengthScreenRouteData];
