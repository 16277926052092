import { useState, useEffect, useLayoutEffect, SetStateAction, Dispatch } from 'react';

function useStateWithCallback<T>(
  initialState: T | (() => T),
  callback: (state: T) => void
): [T, Dispatch<SetStateAction<T>>] {
  const [state, setState] = useState(initialState);

  useEffect(() => callback(state), [state, callback]);

  return [state, setState];
}

function useStateWithCallbackInstant<T>(
  initialState: T | (() => T),
  callback: (state: T) => void
): [T, Dispatch<SetStateAction<T>>] {
  const [state, setState] = useState(initialState);

  useLayoutEffect(() => callback(state), [state, callback]);

  return [state, setState];
}

export { useStateWithCallbackInstant };

export default useStateWithCallback;
