import Heading from 'components/UI/Typography/Heading';
import { Color } from 'modules/styles/colors';
import styled, { css } from 'styled-components';
import Svg from 'components/UI/Svg/Svg';

export const SubMessage = styled(Heading)<{ padding: number }>`
  color: ${Color.inverted};
  font-size: 14px;
  padding: 0 ${props => props.padding}px;
  text-align: center;
  line-height: 14px;
`;
export const BillboardTitle = styled.h1`
  margin: 1rem 0 0 0;
  color: ${Color.foreColor};
  font-size: 28px;
  font-weight: 500;
`;
export const Gradient = styled.div`
  background: linear-gradient(323deg, ${Color.blueHue3} 0%, ${Color.primary} 100%);
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const SolidBackground = styled.div<{ bgUrl?: string }>`
  background: ${Color.primary};
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  ${props =>
    props.bgUrl &&
    css`
      background-image: url(${props.bgUrl});
      background-position: top;
      background-repeat: no-repeat;
      background-size: cover;
    `}
`;

export const BillboardContainer = styled(SolidBackground)<{ height?: number }>`
  height: ${props => `${props.height}px` ?? 'auto'};
  margin: 0 auto;
  padding: 0.7rem 0;
  flex-direction: row;
  display: flex;
  width: 100%;
`;

export const SvgBackground = styled(Svg)`
  position: absolute;
  bottom: 0;
  left: 0;
`;
