import React from 'react';
import { useHistory } from 'react-router-dom';
import { getAnticoagulationResponseDateText } from 'lib/dashboard/utils';
import {
  DashboardBannerCard,
  DashboardCards
} from 'components/DashboardCarousel/DashboardBannerCard';

export interface Props {
  data: {
    createdAt: string;
    eventId: string;
    onAnalyticsClick: () => void;
  };
  totalCards: number;
}

export const DashboardCarouselAntiCoagulation = ({ data, totalCards }: Props) => {
  const responseText = getAnticoagulationResponseDateText(data.createdAt);
  const history = useHistory();
  const { onAnalyticsClick } = data;

  return (
    <DashboardBannerCard
      title="You have a new anti-coagulation alert"
      subTitle={responseText}
      actionOne={{
        text: 'View details',
        onClick: () => {
          onAnalyticsClick();
          history.push(`/u/dashboard/anti-coagulation/${data.eventId}`);
        }
      }}
      imageName={DashboardCards.antiCoagulation.imageName}
      textColor={DashboardCards.antiCoagulation.color}
      background={DashboardCards.antiCoagulation.background}
      totalCards={totalCards}
    />
  );
};
