import styled from 'styled-components';
import { MuiButton, MuiGrid, MuiTypography, dfdDefaultTheme } from 'theme/material-ui';
import { Color } from 'modules/styles/colors';
import { FontWeight } from 'modules/styles/variables.web';

export const StyledMuiButton = styled(MuiButton)`
  &:hover {
    color: ${Color.white};
  }
`;

export const StyledMuiGrid = styled(MuiGrid)`
  display: flex;
  justify-content: flex-end;
`;

export const TagContainer = styled.div`
  position: relative;

  & > span {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    position: absolute;
    z-index: 1;
    transform-origin: top left;
    transform: rotate(-90deg);
    bottom: -20px;
    background-color: ${Color.secondary};
    color: ${Color.white};
    width: 90px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: ${FontWeight.semibold};
    letter-spacing: 1.5px;
  }
`;

export const MuiGridAvatarWrapper = styled(MuiGrid)`
  display: flex;
  justify-content: flex-end;
`;

export const MuiProviderName = styled(MuiTypography)`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  ${dfdDefaultTheme.breakpoints.up('sm')} {
    max-width: 325px;
  }
`;
