import { Alert } from 'components/Alert';
import FlexBox from 'components/UI/Layout/FlexBox';
import Screen from 'components/UI/Layout/Screen';
import { SpinnerOverlay } from 'components/UI/Spinner/SpinnerModal';
import useNavigationAnalytics from 'hooks/useNavigationAnalytics';
import { useWindowSize } from 'hooks/useWindowSize';
import { history } from 'lib/history';
import { Spacing } from 'modules/styles/variables';
import React, { useEffect } from 'react';
import Config from 'react-native-config';
import { connect } from 'react-redux';
import { AmplitudeEventData, AnalyticsEvent } from 'services/AnalyticsService';
import { logout } from 'store/authentication/actions.web';
import { isDfdAuthenticatedSelector } from 'store/authentication/selectors';
import { getUpcomingAppointments } from 'store/booking/appointments/upcomingAppointments/actions';
import { currentProviderSelector } from 'store/booking/selectors';
import * as bookingTypes from 'store/booking/types';
import { ProviderSummary } from 'store/findProvider/types';
import { getBcsToken } from 'store/kyruus/kyruusProviderSearch/actions';
import { bcsTokenDataSelector } from 'store/kyruus/kyruusProviderSearch/selectors';
import { RootState } from 'store/types';

interface Props {
  selectedProvider: ProviderSummary;
  appointmentDetails: bookingTypes.AppointmentDetails;
  getBcsToken: (issue: string, audience: string) => {};
  logout: typeof logout;
  getUpcomingAppointments: typeof getUpcomingAppointments;
  bcsToken: string;
  isAuthenticated: boolean;
}

const KyruusProviderDetails = ({
  selectedProvider,
  getBcsToken,
  logout,
  getUpcomingAppointments,
  bcsToken,
  isAuthenticated
}: Props) => {
  const { height } = useWindowSize();
  const KyruusProviderDetailsLoading = () => <SpinnerOverlay isLoading />;
  const bookingCompletedEvent = useNavigationAnalytics(AnalyticsEvent.BookAppointmentCompleted);
  useEffect(() => {
    if (!isAuthenticated) {
      Alert.alert('Session Timeout', 'Your session has ended, please log in again.', [
        { text: 'OK', onPress: () => logout() }
      ]);
    } else if (!selectedProvider?.kyruusDetailsUrl) {
      history.push('/u/find-a-doctor');
      Alert.alert('No Provider Selected', 'Unfortunately provider details are not available.', [
        { text: 'OK' }
      ]);
    }
    getBcsToken('*', Config.SCL_HEALTH_PMC_BCSTOKEN);
  }, []);

  const getProviderList = event => {
    const currentEvent = event?.data?.params?.[0];
    if (currentEvent === 'hl.sdk.routeTo' && event?.data?.params?.[1]?.path) {
      const eventData: AmplitudeEventData = {
        kyruus: true
      };
      bookingCompletedEvent(eventData);
      getUpcomingAppointments(false);
      Alert.alert(
        'Appointment Successfully Booked',
        "The provider's office may contact you prior to your appointment to confirm details.",
        [{ text: 'OK', onPress: () => history.push('/u/dashboard') }]
      );
    }
  };

  useEffect(() => {
    if (bcsToken) {
      const iframe = document.getElementById('frameRef') as HTMLIFrameElement;
      const iframeWin = iframe.contentWindow;
      iframeWin?.postMessage({ jsonrpc: '2.0', method: 'authorizeConsumer' }, '*');
      window.addEventListener('message', getProviderList, false);
    }
    return () => {
      window.removeEventListener('message', getProviderList, false);
    };
  }, [bcsToken]);

  return (
    <Screen safe foreground>
      <FlexBox flex={1} hSpacing={Spacing.large} vSpacing={Spacing.large}>
        {bcsToken ? (
          <iframe
            id="frameRef"
            width="100%"
            height={height * 0.8}
            title="Provider Details"
            frameBorder="0"
            src={`${selectedProvider?.kyruusDetailsUrl}?bcs_token=${bcsToken}`}
          />
        ) : (
          <KyruusProviderDetailsLoading />
        )}
      </FlexBox>
    </Screen>
  );
};

const mapStateToProps = (state: RootState) => ({
  bcsToken: bcsTokenDataSelector(state),
  isAuthenticated: isDfdAuthenticatedSelector(state),
  selectedProvider: currentProviderSelector(state)
});

const mapDispatchToProps = {
  getBcsToken,
  logout,
  getUpcomingAppointments
};

export default connect(mapStateToProps, mapDispatchToProps)(KyruusProviderDetails);
