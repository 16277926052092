import styled from 'styled-components';
import StyledButton from 'components/UI/Button/StyledButton';
import { Color } from 'modules/styles/colors';

export const OnBoardingButton = styled(StyledButton)`
  border: ${Color.foreColor};
  width: 100%;
`;

const largeLogoWidth = 365;
const smallLogoWidth = 222;
const logoAspectRatio = 3.3;

export const Logo = styled.img<{ isSmallScreen: boolean }>`
  width: ${props => (props.isSmallScreen ? `${smallLogoWidth}px` : `${largeLogoWidth}px`)};
  height: ${props =>
    props.isSmallScreen
      ? `${smallLogoWidth / logoAspectRatio}px`
      : `${largeLogoWidth / logoAspectRatio}px`};
`;
