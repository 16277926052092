import Banner from 'components/UI/Banner/Banner';
import { formatDate } from 'modules/utils/DateUtils';
import React from 'react';
import HealthSummaryCard from '../HealthSummaryCard/HealthSummaryCard';

interface Props {
  title: string;
  subtitle: string;
  patient: string;
  date: string;
  dischargeDate?: string | null;
  dischargeDisposition?: string | null;
  providers?: string;
  providerImageUrl?: string;
  isSelectHealth?: boolean;
  location?: string;
  accessibilityLabel?: string;
  source?: string | null;
  children?: React.ReactNode;
}

export function HealthRecordBanner({
  title,
  subtitle,
  patient,
  date,
  dischargeDate,
  dischargeDisposition,
  providers,
  providerImageUrl,
  isSelectHealth,
  location,
  accessibilityLabel,
  source,
  children
}: Props) {
  return (
    <Banner
      message={title}
      bannerComponent={() => (
        <>
          <HealthSummaryCard
            date={
              dischargeDate
                ? `${formatDate(date)} - ${formatDate(dischargeDate)}`
                : formatDate(date)
            }
            patient={patient}
            providers={providers}
            providerImageUrl={providerImageUrl}
            isSelectHealth={isSelectHealth}
            location={location}
            title={subtitle}
            dischargeDisposition={dischargeDisposition}
            accessibilityLabel={accessibilityLabel}
            source={source}
          />
          {children}
        </>
      )}
    />
  );
}

export default HealthRecordBanner;
