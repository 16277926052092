import React from 'react';
import { Color } from 'modules/styles/colors';
import CollapsibleList from 'components/common/CollapsibleList/CollapsibleList';
import CollapsibleListHeader from 'components/common/CollapsibleList/CollapsibleListHeader';
import { EmptyFlexBox } from '../styled';
import DefaultText from 'components/UI/Typography/DefaultText';
import ChargeItem from './ChargeItem';
import { ChargeGroupDisplay, ChargeGroupProps } from './types';
import formatMoney from 'services/formatMoney';
import analyticsService, { AnalyticsEvent } from 'services/AnalyticsService';
import { connect } from 'react-redux';
import { RootState } from 'store/types';
import {
  currentLocationPathNameSelector,
  previousLocationPathNameSelector
} from 'store/router/selectors';

interface Props extends ChargeGroupProps {
  currentUrl?: string;
  referringUrl?: string;
}

// export default function ChargeGroup({ totalCharges, triggerSurvey, charges }: ChargeGroupProps) {

export function ChargeGroup(props: Props) {
  const { totalCharges, triggerSurvey, charges, currentUrl, referringUrl } = props;

  // Create the charges display array
  const chargesDisplay: ChargeGroupDisplay[] = charges.map(item => ({
    description: item.description,
    amount: item.amount,
    charges: item.charges.map(charge => ({
      description: charge.description,
      amount: charge.amount,
      date: charge.date,
      cptcode: charge.cptcode
    }))
  }));

  // Build the Charges display object
  chargesDisplay.push({
    description: 'Your cost for this service: ',
    amount: totalCharges || 0,
    isTotal: true,
    charges: [{ description: '', amount: totalCharges || 0, isTotal: true }]
  });

  return (
    <CollapsibleList
      data-testid="view-itemized-charges-group"
      accessibilityLabel="View itemized charges"
      key="View itemized charges"
    >
      <CollapsibleListHeader
        title="View itemized charges"
        subtitle={totalCharges ? `Your cost for this service: $${formatMoney(totalCharges)}` : ''}
        onOpened={() => {
          analyticsService.logEvent(AnalyticsEvent.ViewItemizedClicked, {
            currentUrl,
            referringUrl
          });
          triggerSurvey();
        }}
        headerColor={Color.offBlack}
        headerWeight="700"
      >
        {charges.length === 0 ? (
          <EmptyFlexBox>
            <DefaultText data-testid="ChargeGroup_EmptyArray">No Charges Available</DefaultText>
          </EmptyFlexBox>
        ) : (
          chargesDisplay.map((eachCharge: ChargeGroupDisplay) => (
            <ChargeItem
              key={`Charge-${eachCharge.description}`}
              data-testid="view-itemized-charges-item"
              {...eachCharge}
            />
          ))
        )}
      </CollapsibleListHeader>
    </CollapsibleList>
  );
}

const mapState = (state: RootState) => ({
  currentUrl: currentLocationPathNameSelector(state),
  referringUrl: previousLocationPathNameSelector(state)
});

export default connect(mapState)(ChargeGroup);
