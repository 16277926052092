import React from 'react';
import { useDispatch } from 'react-redux';

import {
  DashboardBannerCard,
  DashboardCards
} from 'components/DashboardCarousel/DashboardBannerCard';
import { patchEvent } from 'store/events/actions';

export interface EVisitCardProps {
  visitDetailsClick: () => void;
  isInProgress?: boolean;
  data: any;
  totalCards: number;
}
export const DashboardCarouselEVisitsCard = ({
  visitDetailsClick,
  isInProgress = false,
  data,
  totalCards
}: EVisitCardProps) => {
  const dispatch = useDispatch();
  const { eventId, onAnalyticsClick, onAnalyticsDismissedClick } = data;

  const removeCard = () => {
    dispatch(patchEvent(eventId, { hide: true }));
    onAnalyticsDismissedClick();
  };
  const handleClick = () => {
    visitDetailsClick();
    onAnalyticsClick();
  };

  return (
    <DashboardBannerCard
      title={
        isInProgress
          ? `Your E-Visit is under review. Please check back later for results.`
          : 'Your E-Visit results are available'
      }
      actionOne={!isInProgress ? { text: 'View details', onClick: handleClick } : undefined}
      imageName={DashboardCards.evisitAvailable.imageName}
      textColor={DashboardCards.evisitAvailable.color}
      background={
        isInProgress
          ? DashboardCards.evisitInProgress.background
          : DashboardCards.evisitAvailable.background
      }
      totalCards={totalCards}
      onClosePress={eventId ? removeCard : undefined}
    />
  );
};
export default DashboardCarouselEVisitsCard;
