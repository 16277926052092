import styled from 'styled-components';

import { Spacing, FontSize, BorderRadius, FontFamily, FontWeight } from 'modules/styles/variables';
import { Color } from 'modules/styles/colors';

import FlexBox, { FlexBoxColumn } from 'components/UI/Layout/FlexBox';
import Box from 'components/UI/Layout/Box';
import Heading from 'components/UI/Typography/Heading';
import DefaultLabel from 'components/UI/Labels/DefaultLabel';
import DefaultText from 'components/UI/Typography/DefaultText';
import RadioButton from 'components/UI/Radio/RadioButton';
import Typography from 'components/UI/Typography';

import TextInput, { TextArea } from 'components/UI/Input/TextInput';
import { FormField, FormGroup } from 'components/UI/Layout/Form';
import { MuiBox } from 'theme/material-ui';

// Shared FA styles:
export const FormContainer = styled(FlexBoxColumn)`
  padding: ${Spacing.large}px;
`;

export const StyledFormField = styled(FormField)<{ width?: string }>`
  padding-top: ${Spacing.medium}px;
  padding-bottom: ${Spacing.medium}px;
  width: ${props => (props.width ? props.width : '100%')};
`;

export const StyledTextInput = styled(TextInput)<{ width?: string; editable?: boolean }>`
  width: ${props => (props.width ? props.width : '100%')};
  border-color: ${Color.gray};
  border-radius: ${BorderRadius.base};
  background-color: ${props => (props.editable === false ? Color.grayLight3 : Color.foreColor)};
`;

export const StyledTextArea = styled(TextArea)<{ width?: string; editable?: boolean }>`
  width: ${props => (props.width ? props.width : '100%')};
  border-color: ${Color.gray};
  border-radius: ${BorderRadius.base};
  background-color: ${props => (props.editable === false ? Color.grayLight3 : Color.foreColor)};
`;

export const SubmitButtonBox = styled(Box)`
  width: 80%;
  align-self: center;
  margin-top: ${Spacing.xLarge};
`;

export const StyledFormGroup = styled(FormGroup)<{ noBorder?: boolean }>`
  justify-content: space-evenly;
  border-bottom-width: ${props => (props.noBorder ? 0 : 1)};
  padding: ${props => (props.noBorder ? 0 : '15px 0px')};
`;

export const PreviousButton = styled.button`
  background: transparent;
  width: 70%;
  text-align: center;
  padding: ${Spacing.small}px;
  margin-top: ${Spacing.medium};
  display: flex;
  align-self: center;
  justify-content: center;
  align-items: center;
`;

export const PreviousButtonText = styled(DefaultText)`
  color: ${Color.link};
  text-transform: uppercase;
`;

export const dateInputStyle = {
  backgroundColor: Color.foreColor,
  borderWidth: 1,
  borderColor: Color.gray,
  borderRadius: BorderRadius.base,
  color: Color.textDark,
  fontFamily: FontFamily.primary,
  fontSize: FontSize.large,
  letterSpacing: 0.5,
  minHeight: 42,
  paddingLeft: Spacing.medium,
  marginVertical: Spacing.small,
  width: '100%'
};

// Basic info exclusive styles:
export const DatesFormGroupField = styled.div`
  padding-bottom: ${Spacing.large};
  margin-left: 1px;
  margin-right: 1px;
  flex: 1;
`;

export const pickerSelectStyles = () => dateInputStyle;

// Household-exclusive styles:
export const MaritalStatusLabel = styled(DefaultLabel)`
  font-size: ${FontSize.small};
`;

// Assets-exclusive styles:
export const PaddedRadioButton = styled(RadioButton)`
  padding-top: ${Spacing.xLarge}px;
  padding-bottom: ${Spacing.xLarge}px;
`;

// TODO: Create reusable header that fulfills the needs of all headers (Gradient, White, Form, Static):
export const CloseButton = styled.button`
  position: absolute;
  right: ${Spacing.large};
  top: ${Spacing.large};
`;

export const FormSubtitle = styled(Heading)`
  text-align: center;
  color: ${Color.foreColor};
  line-height: ${Spacing.xLarge};
  margin-bottom: ${Spacing.xLarge};
`;

export const FormTitle = styled(Heading)`
  color: ${Color.foreColor};
  text-align: center;
  line-height: ${Spacing.xLarge};
  margin-top: ${Spacing.xLarge};
`;

// Bills exclusive styles
export const ListCloseButton = styled.button`
  text-align: center;
  justify-content: center;
  align-items: center;
`;

export const SaveButton = styled.button<{ backgroundColor: string }>`
  justify-content: center;
  align-items: center;
  width: 80%;
  padding-top: ${Spacing.large}px;
  padding-bottom: ${Spacing.large}px;
  border-radius: ${BorderRadius.base};
  align-self: center;
  border: 1px solid ${Color.baseDark};
  background-color: ${props => (props.backgroundColor ? props.backgroundColor : Color.primary)};
`;

// Static Page styles:

export const TitleText = styled(Heading)`
  width: 80%;
  text-align: center;
  color: ${Color.foreColor};
  margin-top: ${Spacing.xLarge};
  margin-bottom: ${Spacing.xLarge};
  font-size: ${FontSize.title};
  line-height: 50px;
`;

export const ElevatedCard = styled.div`
  width: 90%;
  background-color: ${Color.white};
  border-radius: ${BorderRadius.base};
  align-items: center;
  padding: ${Spacing.xLarge}px;
  margin-top: -25px;
  margin-bottom: ${Spacing.xLarge};
  border-color: ${Color.grayHue4};
  border-width: 1;
  box-shadow: 0px 1.5px 1px ${Color.grayLight};
`;

export const StaticFooterContainer = styled.div`
  align-self: center;
  padding-left: ${Spacing.xLarge + 15}px;
  padding-right: ${Spacing.xLarge + 15}px;
  padding-top: ${Spacing.xLarge}px;
  padding-bottom: ${Spacing.xLarge}px;
`;

export const StyledGrayBox = styled(FlexBox)<{ onPress?: () => void }>`
  width: ${props => (props.width ? props.width : '100%')};
  background-color: ${Color.grayLight2};
  padding: ${Spacing.large}px;
  align-items: center;
  justify-content: center;
  border-radius: ${BorderRadius.base};
`;

export const BlueText = styled(DefaultLabel)<{
  fontSize?: number;
  textAlign?: string;
  hSpacing?: number;
}>`
  color: ${Color.link};
  font-size: ${props => (props.fontSize ? props.fontSize : FontSize.base)};
  text-align: ${props => (props.textAlign ? props.textAlign : 'left')};
  padding-horizontal: ${props => (props.hSpacing ? props.hSpacing : 0)};
`;

export const DangerText = styled(DefaultLabel)<{ fontSize?: number; textAlign?: string }>`
  color: ${Color.red};
  font-size: ${props => (props.fontSize ? props.fontSize : FontSize.base)};
`;

export const BillFlexBox = styled(FlexBox)`
  padding: ${Spacing.medium}px ${Spacing.small}px;
  margin-bottom: ${Spacing.medium};
`;

export const StyledHeading = styled(Heading)<{ color?: string }>`
  color: ${props => (props.color ? props.color : Color.foreColor)};
  text-align: center;
  line-height: ${Spacing.xLarge}px;
  margin-top: ${Spacing.xLarge}px;
  margin-bottom: ${Spacing.xLarge}px;
`;

export const StyledHr = styled.div<{ color?: string }>`
  flex: 1;
  background-color: ${props => (props.color ? props.color : Color.grayLight2)};
  height: 1;
`;

export const InfoText = styled(DefaultText)`
  line-height: 20px;
  margin-top: ${Spacing.small};
  margin-right: ${Spacing.none};
  color: ${props => (props.color ? props.color : Color.grayHue2)};
`;

export const BolderText = styled(Typography)`
  font-weight: ${FontWeight.semibold};
`;

export const Background = styled.div`
  width: 100%;
  background-color: ${Color.foreColor};
`;

export const ListItem = styled.div`
  justify-content: center;
  padding-top: ${Spacing.large}px;
`;

export const SubItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-top: ${Spacing.medium}px;
  padding-left: ${Spacing.medium}px;
  padding-right: ${Spacing.large}px;
`;

export const SubInnerItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-top: ${Spacing.medium}px;
  padding-left: ${Spacing.large}px;
  padding-right: ${Spacing.xLarge}px;
`;

export const ItemText = styled(Typography)<{
  fontSize?: number;
  marginTop?: number;
  marginRight?: number;
  fontWeight?: number;
}>`
  font-size: ${props => (props.fontSize ? props.fontSize : 14)};
  line-height: ${props => (props.lineHeight ? props.lineHeight : Spacing.large)};
  margin-top: ${props => (props.marginTop ? props.marginTop : 5)};
  margin-right: ${props => (props.marginRight ? props.marginRight : 0)};
  color: ${props => (props.color ? props.color : Color.textLight)};
  font-weight: ${props => (props.fontWeight ? props.fontWeight : FontWeight.normal)};
`;

export const MuiRow = styled(MuiBox)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
