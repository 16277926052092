import React from 'react';

export default {
  svg: (
    <g fill="none">
      <rect x="1" y="1" width="42" height="42" rx="3" fill="white" />
      <g clipPath="url(#clip0_1_240)">
        <path
          d="M23.045 24.136L19.333 24.667L19.863 20.954L29.409 11.408C29.831 10.9861 30.4033 10.749 31 10.749C31.2955 10.749 31.5881 10.8072 31.8611 10.9203C32.134 11.0334 32.3821 11.1991 32.591 11.408C32.7999 11.617 32.9657 11.865 33.0787 12.138C33.1918 12.411 33.25 12.7036 33.25 12.999C33.25 13.2945 33.1918 13.5871 33.0787 13.8601C32.9657 14.1331 32.7999 14.3811 32.591 14.59L23.045 24.136Z"
          stroke="#4A00E2"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M28.3479 12.469L31.5299 15.651"
          stroke="#4A00E2"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M28.75 24.25V31.75C28.75 32.1478 28.592 32.5294 28.3107 32.8107C28.0294 33.092 27.6478 33.25 27.25 33.25H12.25C11.8522 33.25 11.4706 33.092 11.1893 32.8107C10.908 32.5294 10.75 32.1478 10.75 31.75V16.75C10.75 16.3522 10.908 15.9706 11.1893 15.6893C11.4706 15.408 11.8522 15.25 12.25 15.25H19.75"
          stroke="#4A00E2"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <rect x="1" y="1" width="42" height="42" rx="3" stroke="#4A00E2" strokeWidth="2" />
      <defs>
        <clipPath id="clip0_1_240">
          <rect width="24" height="24" fill="white" transform="translate(10 10)" />
        </clipPath>
      </defs>
    </g>
  ),
  viewBox: '0 0 44 44'
};
