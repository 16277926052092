import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { oc } from 'ts-optchain';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { useTheme } from '@material-ui/core/styles';

import Svg from 'components/UI/Svg/Svg';
import { FA_SUBMIT_INFO } from './constants';
import FADisclaimer from '../FADisclaimer';
import { PATIENT_FINANCIAL_SERVICE_NUMBER } from '../constants';
import { useHistory } from 'react-router-dom';

interface ListItem {
  title: string;
  subItems: { title: string }[];
}

export const FASubmitComponenet = () => {
  const { palette } = useTheme();
  const history = useHistory();
  useEffect(() => {
    return () => {
      if (history.action === 'POP') {
        history.push('u/fa-app/start');
      }
    };
  }, [history]);

  const applicationID = oc(history).location.state.applicationId('#####');

  const renderList = (item: ListItem) => (
    <li key={item.title} style={{ listStyle: 'disc', marginBottom: '2px', marginTop: '2px' }}>
      {item.title}
      {item.subItems && (
        <ul style={{ listStyle: 'circle', paddingLeft: '20px' }}>
          {item.subItems.map(subItem => (
            <li
              key={subItem.title}
              style={{ listStyle: 'circle', marginBottom: '2px', marginTop: '2px' }}
            >
              {subItem.title}
            </li>
          ))}
        </ul>
      )}
    </li>
  );

  return (
    <Container>
      <Box mb={5}>
        <Paper style={{ marginTop: '-20px' }}>
          <Box p={6}>
            <Grid container spacing={6}>
              <Grid item md={7} sm={12}>
                <Box mb={1}>
                  <Box mb={1}>
                    <Typography variant="h5">Success!</Typography>
                  </Box>
                  <Typography>Thank you for completing the application form.</Typography>
                </Box>
                <Box mb={3}>
                  <Typography>
                    Your Application Number is:{' '}
                    <span color="primary" style={{ fontSize: '18px', display: 'inline' }}>
                      {applicationID}
                    </span>
                  </Typography>
                </Box>
                <Box mb={1}>
                  <Box mb={1}>
                    <Typography variant="h5">Next Steps</Typography>
                  </Box>
                  <Typography>
                    As next steps, please submit the following verification documents via email or
                    to the Intermountain facility where you had or plan to receive care for
                    processing. Patients may not receive financial assistance if they do not
                    complete the application process.
                  </Typography>
                </Box>
                <Box mb={3}>
                  <ol style={{ listStyle: 'disc', paddingLeft: '20px', margin: 0 }}>
                    {FA_SUBMIT_INFO.map(item => renderList(item))}
                  </ol>
                </Box>
                <Box>
                  <Box mb={1}>
                    <Typography variant="h5">Sending Documents</Typography>
                  </Box>
                  <Box mb={1}>
                    <Typography>
                      You have several options for sending in your verification documents:
                    </Typography>
                  </Box>
                  <Box mb={1}>
                    <Typography>Address mail to Intermountain Financial Assistance:</Typography>
                    <Typography>Intermountain Financial Assistance</Typography>
                    <Typography>PO Box 27327, Salt Lake city, Utah 84127</Typography>
                  </Box>
                  <Box mb={1}>
                    Visit your local Intermountain hospital or clinic where you receive services
                  </Box>
                  <Box mb={1}>
                    <Typography>
                      Scan and email to{' '}
                      <a href="mailto:financial.assistance@r1rcm.com">
                        financial.assistance@r1rcm.com
                      </a>
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item md={5} sm={12}>
                <Box
                  display="flex"
                  alignItems="center"
                  bgcolor="grey.100"
                  p={2}
                  borderRadius={2}
                  mb={2}
                >
                  <Svg
                    name="Timer"
                    strokeWidth="0.25px"
                    stroke={palette.primary.main}
                    height="40"
                    width="40"
                  />
                  <Box pl={1}>
                    <Typography color="primary">Estimated processing time:</Typography>
                    <Typography color="primary">
                      <b>2 weeks</b>
                    </Typography>
                  </Box>
                </Box>
                <Box bgcolor="grey.100" p={2} borderRadius={2} mb={2}>
                  <Typography color="primary">
                    If you need help please call Financial Assistance Dept
                  </Typography>
                  <Typography variant="h6">
                    <a
                      href={`tel:${PATIENT_FINANCIAL_SERVICE_NUMBER}`}
                      style={{ color: palette.primary.main, fontWeight: 600 }}
                    >
                      {PATIENT_FINANCIAL_SERVICE_NUMBER}
                    </a>
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Box>
      <Box mb={5}>
        <FADisclaimer />
      </Box>
    </Container>
  );
};

export default connect()(FASubmitComponenet);
