import React from 'react';

import { Color } from 'modules/styles/colors';

export const styles = {
  fill: 'none',
  stroke: Color.black,
  strokeWidth: 1.5,
  strokeLinecap: 'round',
  strokeLinejoin: 'round'
} as const;

export default {
  svg: (
    <g fill="none">
      <path
        d="M11.046 21.0919C16.5942 21.0919 21.0919 16.5942 21.0919 11.0459C21.0919 5.49773 16.5942 1 11.046 1C5.49773 1 1 5.49773 1 11.0459C1 16.5942 5.49773 21.0919 11.046 21.0919Z"
        {...styles}
      />
      <path d="M18.1455 18.145L22.7666 22.7661" {...styles} />
      <path
        d="M13.1414 5.39111H8.95039V8.95059H5.39111V13.1416H8.95039V16.7009H13.1414V13.1416H16.701V8.95059H13.1414V5.39111Z"
        {...styles}
      />
    </g>
  ),
  viewBox: '0 0 24 24'
};
