import profilePic from 'assets/avatardefault.png';
import { Color } from 'modules/styles/colors';
import React from 'react';
import { ImageSourcePropType, ImageURISource } from 'react-native';
import styled from 'styled-components';
import { MuiAvatar } from 'theme/material-ui';

export enum AvatarSize {
  xSmall = 24,
  small = 32,
  medium = 40,
  large = 52,
  xLarge = 65,
  mega = 90,
  giga = 200
}

type AvatarSizeKeys = keyof typeof AvatarSize;

interface RoundImageProps {
  size: AvatarSizeKeys;
  src: string;
}

const RoundImage = styled(MuiAvatar)<RoundImageProps>`
  background-color: ${Color.grayLight6};

  && {
    border-radius: ${({ size }) => `${AvatarSize[size] / 2}px`};
    height: ${({ size }) => `${AvatarSize[size]}px`};
    width: ${({ size }) => `${AvatarSize[size]}px`};
    background-color: ${Color.white};
  }
`;

interface AvatarProps {
  imageUrl: string;
  defaultSource: number | ImageURISource;
  size: AvatarSizeKeys;
  accessibilityLabel: string;
  alt?: string;
}

const Avatar = ({ imageUrl, size, defaultSource, accessibilityLabel, alt }: AvatarProps) => {
  const source: ImageSourcePropType = imageUrl || defaultSource;

  return (
    <RoundImage
      accessibilityLabel={accessibilityLabel}
      size={size}
      defaultSource={defaultSource}
      src={source}
      alt={alt || accessibilityLabel}
    />
  );
};

Avatar.defaultProps = {
  size: 'large',
  accessibilityLabel: 'Avatar',
  imageUrl: '',
  defaultSource: profilePic
};

export default Avatar;
