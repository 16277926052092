import { MuiBox, MuiListItem, MuiTypography } from 'theme/material-ui';
import { Color } from 'modules/styles/colors';
import { Spacing } from 'modules/styles/variables';
import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';
import BlueDot from 'components/common/BlueDot';

export const GridHolder = styled(Grid)`
  margin: 6px 30px -3px 16px;
`;
export const GridLeft = styled(Grid)`
  padding-left: 0px;
  color: ${Color.black};
`;
export const GridMid = styled(Grid)`
  text-align: right;
  color: ${Color.black};
  padding-left: 300px;
`;
export const GridRight = styled(Grid)`
  margin-top: -4px;

  text-align: right;
`;

export const ListItemA = styled(MuiListItem)`
  padding-bottom: 0px;
`;

export const StyledMuiBox = styled(MuiBox)`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  max-width: 960px;
  padding: 0px ${Spacing.medium}px;
`;

export const StyledMuiBoxContainer = styled(MuiListItem)`
  &:not(:last-child) {
    border-bottom: 1px solid ${Color.grayLight};
  }
`;

export const TextDark = styled(MuiTypography)`
  color: ${Color.black};
  display: inline;
`;

export const IconArrow = styled.div`
  display: inline-block;
  top: 6px;
`;

export const BlueDotStyled = styled(BlueDot)`
  display: inline-block;
`;
