import { RouteData } from 'screens/Booking/router/types';
import GuestHomeScreen from 'screens/Guest/GuestHomeScreen';
import { CovidAgreementGuest, CovidReasonsGuest } from '../ConsentForms';
import CovidInsurance from '../CovidInsurance';
import CovidConfirmation from '../OrderConfirmation';
import CovidErrorComponent from '../OrderStatus';
import CovidSymptoms from '../CurrentSymptoms/CurrentSymptomsGuest';
import InsuranceInformation from '../InsuranceInformation/InsuranceNotFound';
import SubscriberInformation from '../SubscriberInformation';
import PersonalInformation from '../PersonalInformation/PersonalInformation';
import TestInsurance from '../TestInsurance';
import CovidInsuranceSelect from '../CovidInsuranceDropDown';
import CovidScreeningInsuranceUpload from '../CovidScreeningInsuranceUpload';
import CovidScreenGuestSummary from '../CovidScreenGuestSummary';

const commonCovidGuestNavigationProps = {
  title: 'CovidGuestTitle',
  crumbLabel: 'CovidGuestTitle',
  includeBanner: true,
  includeCrumbs: true,
  includeDefaultLayout: true
};

export const covidTestGuestScreens: RouteData[] = [
  {
    ...commonCovidGuestNavigationProps,
    component: CovidAgreementGuest,
    componentName: 'GuestCovidAgreement',
    path: '/guest-home/covid-screen/consent-and-agreement'
  },
  {
    ...commonCovidGuestNavigationProps,
    component: CovidReasonsGuest,
    componentName: 'GuestCovidReasons',
    path: '/guest-home/covid-screen/guest-covid-reasons'
  },
  {
    ...commonCovidGuestNavigationProps,
    component: CovidSymptoms,
    componentName: 'GuestCovidSymptoms',
    path: '/guest-home/covid-screen/guest-covid-symptoms'
  },
  {
    ...commonCovidGuestNavigationProps,
    component: TestInsurance,
    componentName: 'GuestCovidTestInsuranceForm',
    path: '/guest-home/covid-screen/guest-new-insurance-found'
  },
  {
    ...commonCovidGuestNavigationProps,
    component: PersonalInformation,
    componentName: 'GuestCovidTest',
    path: '/guest-home/covid-screen/personal-information'
  },
  {
    ...commonCovidGuestNavigationProps,
    component: InsuranceInformation,
    componentName: 'GuestCovidNoInsurance',
    path: '/guest-home/covid-screen/guest-no-insurance-found'
  },
  {
    ...commonCovidGuestNavigationProps,
    component: CovidInsuranceSelect,
    componentName: 'GuestCovidTestInsuranceSearch',
    path: '/guest-home/covid-screen/guest-insurance-search'
  },
  {
    ...commonCovidGuestNavigationProps,
    component: CovidInsurance,
    componentName: 'GuestCovidInsurance',
    path: '/guest-home/covid-screen/guest-covid-insurance'
  },
  {
    ...commonCovidGuestNavigationProps,
    component: SubscriberInformation,
    componentName: 'GuestCovidTestSuscriberForm',
    path: '/guest-home/covid-screen/guest-subscriber-information'
  },
  {
    ...commonCovidGuestNavigationProps,
    component: CovidScreenGuestSummary,
    componentName: 'GuestCovidTest',
    path: '/guest-home/covid-screen/guest-summary-info'
  },
  {
    ...commonCovidGuestNavigationProps,
    component: CovidScreeningInsuranceUpload,
    componentName: 'GuestCovidScreeningInsuranceUpload',
    path: '/guest-home/covid-screen/guest-covid-insurance-upload'
  },
  {
    ...commonCovidGuestNavigationProps,
    component: CovidConfirmation,
    componentName: 'GuestCovidCovidConfirmation',
    path: '/guest-home/covid-screen/guest-covid-order-confirmation'
  },
  {
    ...commonCovidGuestNavigationProps,
    component: CovidErrorComponent,
    componentName: 'GuestCovidTestOrderStatus',
    path: '/guest-home/covid-screen/guest-covid-order-status'
  },
  {
    exact: true,
    path: '/guest-home',
    component: GuestHomeScreen,
    crumbLabel: 'GuestHome',
    includeDefaultLayout: true
  }
];
