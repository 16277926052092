import React from 'react';
import Divider from 'components/UI/Divider';
import FlexBox from 'components/UI/Layout/FlexBox';
import Avatar from 'components/UI/Avatar/Avatar';
import { Svg } from 'components/UI/Svg/';
import { FontSize, Spacing } from 'modules/styles/variables';
import { AvatarContainer, StyledSvg, StyledTypography } from './styled';
import { MuiListItem } from 'theme/material-ui';

export interface ProviderItemProps {
  name: string;
  specialty?: string;
  pictureUrl?: string;
  isSelectHealth?: 'Y' | 'N' | null;
  onClick?: () => void;
}

const ProviderItem = ({
  name = '',
  specialty = '',
  pictureUrl,
  isSelectHealth,
  onClick
}: ProviderItemProps) => {
  return (
    <>
      <MuiListItem button onClick={onClick} data-testid="provider-item">
        <FlexBox width="100%" flexDirection="row" justifyContent="flex-start">
          <FlexBox width="20%" justifyContent="center" alignItems="center">
            <AvatarContainer>
              <Avatar imageUrl={pictureUrl} size="xLarge" alt={name} />
              {isSelectHealth && (
                <StyledSvg>
                  <Svg name="SelectHealth" height="20" width="20" />
                </StyledSvg>
              )}
            </AvatarContainer>
          </FlexBox>
          <FlexBox width="68%" hSpacing={Spacing.large} vSpacing={Spacing.smallMedium}>
            <StyledTypography fontSize={FontSize.base}>{name}</StyledTypography>
            <StyledTypography>{specialty}</StyledTypography>
          </FlexBox>
        </FlexBox>
      </MuiListItem>
      <Divider />
    </>
  );
};

export default ProviderItem;
