import React, { useRef } from 'react';
import styled from 'styled-components';

import FlexBox, { FlexProps } from 'components/UI/Layout/FlexBox';
import Spinner from 'components/UI/Spinner/Spinner';
import { Svg } from 'components/UI/Svg';
import { Color } from 'modules/styles/colors';
import { FontSize, FontWeight, IconSize, Spacing } from 'modules/styles/variables';
import { toSentenceCase } from 'modules/utils/StringUtils';
import { MuiTypography } from 'theme/material-ui';

export interface FileUploadBoxProps {
  hasIcon?: boolean;
  disabled?: boolean;
  loading?: boolean;
  onClick?: (file: File) => void;
  infoText?: string;
  children: React.ReactNode;
  isMini?: boolean;
  image?: boolean;
  color?: string;
}

const FileUploadCard = styled(FlexBox)<FlexProps & { isMini?: boolean }>`
  ${(props: any) => `
  background-color: ${props.color ? props.color : Color.foreColor};
  padding: ${props.isMini ? Spacing.small : Spacing.xLarge}px;
  align-items: center;
  justify-content: center;
  width: ${props.isMini ? '100%' : null};
  min-height: ${props.isMini ? 0 : 200}px;
  cursor: pointer;
  `}
`;

const ButtonText = styled(MuiTypography)`
  &.MuiTypography-root {
    color: ${Color.secondary};
    font-size: ${FontSize.heading}px;
    font-weight: ${FontWeight.bold};
    margin-top: ${Spacing.smallMedium}px;
  }
`;

const FileUploadBoxInfoText = styled(MuiTypography)`
  &.MuiTypography-root {
    margin-top: ${Spacing.mediumLarge}px;
    font-size: ${FontSize.large}px;
  }
`;

function FileUploadBox({
  onClick,
  disabled,
  loading,
  hasIcon,
  infoText,
  children,
  isMini,
  image,
  color
}: FileUploadBoxProps) {
  const inputRef = useRef<HTMLInputElement>(null);

  if (loading) {
    return (
      <FileUploadCard>
        <Spinner />
      </FileUploadCard>
    );
  }

  return (
    <FileUploadCard isMini={isMini} color={color} onClick={() => inputRef.current?.click()}>
      {hasIcon ? (
        <Svg name="UploadFileIcon" set="assets" size={IconSize.xLarge} color={Color.secondary} />
      ) : null}
      {typeof children === 'string' ? (
        <ButtonText>{toSentenceCase(children)}</ButtonText>
      ) : (
        <>{children}</>
      )}
      {infoText ? <FileUploadBoxInfoText>{infoText}</FileUploadBoxInfoText> : null}
      <input
        data-testid="file-upload"
        type="file"
        hidden
        ref={inputRef}
        disabled={disabled || loading}
        accept={image ? 'image/png, image/jpg, image/jpeg' : undefined}
        onChange={({
          target: {
            validity,
            files: [file]
          }
        }: React.ChangeEvent<HTMLInputElement>) => validity.valid && onClick?.(file)}
      />
    </FileUploadCard>
  );
}

FileUploadBox.defaultProps = {
  hasIcon: true
};

export default FileUploadBox;
