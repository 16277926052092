import React from 'react';
import Switch from '@material-ui/core/Switch';
import { withStyles } from 'theme/material-ui';
import { Color } from 'modules/styles/colors';

const IOSSwitch = withStyles(theme => ({
  root: {
    width: 64,
    height: 32,
    padding: 0,
    margin: theme.spacing(1)
  },
  switchBase: {
    padding: 0,
    '&$checked': {
      transform: 'translateX(30px)',
      color: Color.secondary,
      '& + $track': {
        backgroundColor: `${Color.cobaltTint}`,
        opacity: 1,
        border: `2px solid ${Color.secondary}`
      }
    }
  },

  thumb: {
    width: 24,
    height: 24,
    transform: 'translateY(3px) translateX(4px)'
  },
  track: {
    borderRadius: '18.5px',
    border: `2px solid ${Color.grayHue8}`,
    backgroundColor: Color.grayLight10,
    opacity: 1
  },
  checked: {},
  focusVisible: {},
  disabled: {
    '&$checked': {
      background: 'none'
    }
  }
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
        disabled: classes.disabled
      }}
      {...props}
    />
  );
});

export default IOSSwitch;
