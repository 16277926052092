import React from 'react';
import styled from 'styled-components';
import { MuiTypography, MuiGrid } from 'theme/material-ui';
import { FontSize, FontWeight } from 'modules/styles/variables';
import { Color } from 'modules/styles/colors';
import Spacer from 'components/UI/Layout/Spacer';
import Screen from 'components/UI/Layout/Screen';
import Spinner from 'components/UI/Spinner/Spinner';

const LoadingContainer = styled(MuiGrid)`
  flex: 1;
  background-color: ${Color.primary};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
`;

const EVisitLoading = () => {
  return (
    <Screen>
      <LoadingContainer
        data-testid="e-visit-loading"
        direction="column"
        justify="center"
        alignItems="center"
        container
      >
        <MuiGrid item>
          <MuiTypography
            color={Color.foreColor}
            fontSize={FontSize.title}
            fontWeight={FontWeight.bold}
            align="center"
          >
            Your insurance is <br /> being processed
          </MuiTypography>
        </MuiGrid>
        <MuiGrid>
          <Spacer />
          <Spinner size={45} />
        </MuiGrid>
      </LoadingContainer>
    </Screen>
  );
};

export default EVisitLoading;
