import { Color } from 'modules/styles/colors';

export const getFillColor = (
  active: boolean | undefined = false,
  activeFillColor: string | undefined = Color.primary,
  disabled: boolean | undefined = false,
  disabledColor: string | undefined = 'none',
  color: string | undefined = 'none'
) => {
  return active ? activeFillColor : disabled ? disabledColor : color;
};

export const getStrokeColor = (
  active: boolean | undefined = false,
  activeStrokeColor: string | undefined = Color.primary,
  disabled: boolean | undefined = false,
  disabledColor: string | undefined = Color.grayHue5,
  color: string | undefined = Color.graySidebarIcon
) => {
  return active ? activeStrokeColor : disabled ? disabledColor : color;
};

export const getStrokeWidth = (strokeWidth?: number | string) => {
  return !!strokeWidth
    ? typeof strokeWidth === 'string'
      ? strokeWidth
      : `${strokeWidth}px`
    : '2px';
};
