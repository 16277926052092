import MedicationsCollapsibleListItem from 'components/Medications/MedicationsCollapsibleListItem';
import Screen from 'components/UI/Layout/Screen';
import ClinicsModal from 'components/UI/Modals/ClinicsModal';
import ConfirmModal from 'components/UI/Modals/ConfirmModal';
import { useProxySwitcherEffect } from 'hooks/useProxySwitcherEffect';
import { useRefresh } from 'hooks/useRefresh';
import { GoalsObject } from 'models/healthRecords';
import { Nullable } from 'modules/types/common';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { NavigationScreenProps } from 'screens/navigation';
import analyticsService, {
  AmplitudeEventData,
  AnalyticsEvent,
  AMPLITUDE_EXPERIMENTS
} from 'services/AnalyticsService';
import { patchEvent } from 'store/events/actions';
import { getGoals } from 'store/goals/actions';
import {
  filteredGoalsSelector,
  goalsErrorSelector,
  goalsLoadingState
} from 'store/goals/selectors';
import { askMedicalHistoryQuestion, downloadMedicalHistory } from 'store/medicalHistory/actions';
import { medicalHistoryDownloadingSelector } from 'store/medicalHistory/selectors';
import { MedicalHistoryData } from 'store/medicalHistory/types';
import { getClinicNotesList } from 'store/clinicNotes/actions';
import { getEducationList } from 'store/education/actions';
import { getPreventativeCare } from 'store/preventativeCare/actions';
import {
  preventativeCareDataSelector,
  preventativeCareErrorSelector,
  preventativeCareFetchingSelector
} from 'store/preventativeCare/selectors';
import { PreventativeCareData } from 'store/preventativeCare/types';
import {
  currentLocationPathNameSelector,
  previousLocationPathNameSelector
} from 'store/router/selectors';
import { TestResult } from 'store/testResults/types';
import { RootDispatch, RootState } from 'store/types';
import { getVisitSummaryFullList } from 'store/visitSummary/actions';
import {
  visitSummaryDataSelector,
  visitSummaryListErrorSelector,
  visitSummaryListTotalCountSelector
} from 'store/visitSummary/selectors';
import { VisitSummary } from 'store/visitSummary/types';
import { downloadVitals, getVitalsData, replyToVitals } from 'store/vitals/actions';
import * as vitalsSelectors from 'store/vitals/selectors';
import { VitalsBlock } from 'store/vitals/types';
import { MuiBox, MuiContainer } from 'theme/material-ui';
import HealthRecordsGoalsList from './Goals/List';
import HealthRecordsMedicalHistoryList from './MedicalHistory/List';
import HealthRecordsClinicNotesList from './ClinicNotes/List';
import PreventativeCareList from './PreventativeCare/List';
import EducationList from './Education/List';
import HealthRecordsTestResultsList from './TestResults/List';
import HealthRecordsVisitSummaryList from './VisitSummary/List';
import HealthRecordsVitalsList from './Vitals/List';
import HealthRecordsSummary from './HealthRecordsSummary';
import { getTestResultsList } from 'store/testResults/actions';
import {
  testResultsListDataSelector,
  testResultsListErrorSelector,
  testResultsListIsFetchingSelector
} from 'store/testResults/selectors';

const { vitalsLastUpdatedSelector, vitalsDownloadingSelector } = vitalsSelectors;

interface Props extends NavigationScreenProps {
  dispatch: RootDispatch;
  goals: GoalsObject[];
  goalsError: Error | null;
  goalsLoading?: boolean;
  isDownloadingMedicalHistory: boolean;
  isDownloadingVitals: boolean;
  medHistory: MedicalHistoryData[];
  testResultsError?: Error | null;
  preventativeCareData: PreventativeCareData;
  preventativeCareError: Error | null;
  preventativeCareLoading?: boolean;
  totalVisits: number;
  visitSummaries: VisitSummary[];
  visitSummariesError: Error | null;
  visitSummariesLoading?: boolean;
  vitals: VitalsBlock[];
  vitalsError: Error | null;
  vitalsLoading?: boolean;
  vitalsLastUpdatedDate: string;
  currentUrl?: string;
  referringUrl?: string;
  testResults: TestResult[];
  testResultsLoading: boolean;
}

export const HealthRecordsHome = ({
  dispatch,
  goals = [],
  goalsError,
  goalsLoading,
  history,
  isDownloadingVitals,
  medHistory = [],
  testResultsError,
  preventativeCareData,
  preventativeCareError,
  preventativeCareLoading,
  totalVisits,
  visitSummaries,
  visitSummariesError,
  visitSummariesLoading,
  vitals = [],
  vitalsError,
  vitalsLoading,
  vitalsLastUpdatedDate,
  isDownloadingMedicalHistory,
  currentUrl,
  referringUrl,
  testResults = [],
  testResultsLoading
}: Props) => {
  const markTestResultAsRead = (id: Nullable<string>) => {
    if (id) {
      dispatch(patchEvent(id, { read: true }, true));
    }
  };

  const emmiFlag = analyticsService.fetchExperimentVariant(AMPLITUDE_EXPERIMENTS.EMMI.flagKey);
  const educationEnabled = emmiFlag?.value === AMPLITUDE_EXPERIMENTS.EMMI.variantA;
  const emptyAction = { type: '', payload: {} };

  const { load, reset } = useRefresh(() =>
    Promise.all([
      dispatch(getClinicNotesList()),
      dispatch(educationEnabled ? getEducationList() : emptyAction),
      dispatch(getVitalsData()),
      dispatch(getVisitSummaryFullList(true)),
      dispatch(getGoals()),
      dispatch(getPreventativeCare()),
      dispatch(getTestResultsList())
    ]).catch(() => {})
  );

  useProxySwitcherEffect(() => {
    reset();
    load();
  }, []);

  /**
   *
   * Modal State
   */

  const [modalIsVisible, setModalVisibility] = useState(false);
  const [modalType, setModalType] = useState<string | null>(null);
  const [modalProps, setModalProps] = useState({});

  /**
   *
   * Modal Functions
   */

  const renderModal = () => {
    switch (modalType) {
      case 'mammogram':
        return <ClinicsModal isOpen={modalIsVisible} {...modalProps} />;
      default:
        return <ConfirmModal isOpen={modalIsVisible} {...modalProps} />;
    }
  };

  const data: AmplitudeEventData = {
    currentUrl,
    referringUrl
  };

  const handleDownloadVitals = () => {
    analyticsService.logEvent(AnalyticsEvent.DownloadVitalsClicked, data);
    dispatch(downloadVitals());
  };

  const handleShareVitals = () => {
    analyticsService.logEvent(AnalyticsEvent.ShareVitalsClicked, data);
    dispatch(replyToVitals(vitals, '/u/health-record'));
  };

  const handleDownloadMedicalHistory = () => {
    analyticsService.logEvent(AnalyticsEvent.DownloadMedicalHistoryClicked, data);
    dispatch(downloadMedicalHistory());
  };

  const handleShareMedicalHistory = () => {
    analyticsService.logEvent(AnalyticsEvent.ShareMedicalHistoryClicked, data);
    dispatch(
      askMedicalHistoryQuestion(
        { medHistory, subject: 'Medical History Question' },
        true,
        '/u/health-record'
      )
    );
  };

  return (
    <Screen>
      {modalIsVisible && modalType && modalType.length && renderModal()}

      <MuiBox my={5}>
        <MuiContainer maxWidth="lg">
          <HealthRecordsVisitSummaryList
            visitSummaries={visitSummaries}
            totalVisits={totalVisits}
            error={!!visitSummariesError}
            loading={visitSummariesLoading && !visitSummaries.length}
          />

          <HealthRecordsTestResultsList
            testResults={testResults}
            loading={testResultsLoading && !testResults.length}
            error={!!testResultsError}
            onMarkTestResultAsRead={markTestResultAsRead}
            history={history}
          />

          <MedicationsCollapsibleListItem />

          <PreventativeCareList
            history={history}
            careItems={preventativeCareData}
            setModalType={setModalType}
            setModalVisibility={setModalVisibility}
            setModalProps={setModalProps}
            error={!!preventativeCareError}
            loading={preventativeCareLoading}
          />

          {educationEnabled ? <EducationList /> : null}

          <HealthRecordsClinicNotesList />

          <HealthRecordsMedicalHistoryList
            onTransmitPress={handleShareMedicalHistory}
            onDownloadPress={handleDownloadMedicalHistory}
            isDownloading={isDownloadingMedicalHistory}
          />

          <HealthRecordsVitalsList
            lastUpdated={vitalsLastUpdatedDate}
            error={!!vitalsError}
            loading={vitalsLoading}
            vitals={vitals}
            isDownloadingVitals={isDownloadingVitals}
            handleDownloadVitals={handleDownloadVitals}
            handleShareVitals={handleShareVitals}
          />

          <HealthRecordsGoalsList
            goals={goals}
            error={!!goalsError}
            loading={!goals.length && goalsLoading}
          />
          <HealthRecordsSummary />
        </MuiContainer>
      </MuiBox>
    </Screen>
  );
};

const mapStateToProps = (state: RootState) => ({
  visitSummaries: visitSummaryDataSelector(state),
  visitSummariesError: visitSummaryListErrorSelector(state),
  totalVisits: visitSummaryListTotalCountSelector(state),
  vitals: vitalsSelectors.recentVitalsSelector(state),
  vitalsError: vitalsSelectors.recentVitalsErrorSelector(state),
  vitalsLoading: vitalsSelectors.recentVitalsLoadingSelector(state),
  vitalsLastUpdatedDate: vitalsLastUpdatedSelector(state),
  isDownloadingVitals: vitalsDownloadingSelector(state),
  preventativeCareData: preventativeCareDataSelector(state),
  preventativeCareError: preventativeCareErrorSelector(state),
  preventativeCareLoading: preventativeCareFetchingSelector(state),
  goals: filteredGoalsSelector(state),
  goalsError: goalsErrorSelector(state),
  goalsLoading: goalsLoadingState(state),
  isDownloadingMedicalHistory: medicalHistoryDownloadingSelector(state),
  currentUrl: currentLocationPathNameSelector(state),
  referringUrl: previousLocationPathNameSelector(state),
  testResultsError: testResultsListErrorSelector(state),
  testResultsLoading: testResultsListIsFetchingSelector(state),
  testResults: testResultsListDataSelector(state)
});

export default connect(mapStateToProps)(HealthRecordsHome);
