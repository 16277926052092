import { RootState } from 'store/types';
import { connect } from 'react-redux';
import React, { useMemo, useEffect } from 'react';
import { Nullable } from 'modules/types/common';
import Encryption from 'services/Encryption';
import analyticsService, { AmplitudeEventData, AnalyticsEvent } from 'services/AnalyticsService';
import * as selectors from 'store/testResults/selectors';
import * as routeSelectors from 'store/router/selectors';
import * as appSettingsSelectors from 'store/appSettings/selectors';
import { TestResultFocused, TestResultError } from 'store/testResults/types';
import { FALLBACK_TEST_RESULT_ERROR_MESSAGE } from 'store/testResults/constants';
import { MuiGrid, MuiTypography, MuiBox } from 'theme/material-ui';
import Spinner from 'components/UI/Spinner/Spinner';
import DocumentView from 'components/UI/DocumentView';
import { selectForm } from 'store/testResults/utils';

interface Data {
  content: string;
  contentType: string;
}
export interface Props {
  error: Nullable<Error>;
  isLoading: boolean;
  testResultFocused: TestResultFocused;
  data: Nullable<Data>;
  currentUrl?: string;
  referringUrl?: string;
  eventId?: string;
  encryptionSettings: {
    password: string;
    algorithm: string;
  };
}

export const ResultDocumentHandler = (props: Props) => {
  const {
    testResultFocused,
    data,
    isLoading,
    error,
    encryptionSettings,
    currentUrl,
    referringUrl,
    eventId
  } = props;
  const presentedForm = testResultFocused.presentedForm || [];
  const hasSelectedForm = useMemo(() => !!selectForm(presentedForm, true) || !!data, [
    presentedForm
  ]);

  const eventData: AmplitudeEventData = {
    // we can not pass name to Amplitude as it is PHI
    test_name: Encryption.encrypt(
      encryptionSettings.algorithm,
      encryptionSettings.password,
      testResultFocused.name
    ),
    test_type: testResultFocused.category,
    referringUrl,
    currentUrl,
    eventId
  };

  useEffect(() => {
    if (data) {
      const detailsViewedEventData: AmplitudeEventData = {
        ...eventData,
        test_result_displayed: 'Success'
      };
      analyticsService.logEvent(AnalyticsEvent.TestResultsDetailsViewed, detailsViewedEventData);
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      const detailsViewedEventData: AmplitudeEventData = {
        ...eventData,
        test_result_displayed: 'Error',
        test_result_error: error
      };
      analyticsService.logEvent(AnalyticsEvent.TestResultsDetailsViewed, detailsViewedEventData);
    }
  }, [error]);

  if (error) {
    let errorMessage: string = FALLBACK_TEST_RESULT_ERROR_MESSAGE;
    try {
      const decoder = new TextDecoder();
      const testResultError: TestResultError = JSON.parse(decoder.decode(error.response.data));
      if (testResultError.errorDescription) {
        errorMessage = testResultError.errorDescription;
      }
    } catch (e) {
      // pass...
    }

    return (
      <MuiGrid item xs={12} key={testResultFocused.eventId}>
        <MuiBox p={3} whiteSpace="pre-line">
          <MuiTypography>{errorMessage}</MuiTypography>
        </MuiBox>
      </MuiGrid>
    );
  }

  if (!hasSelectedForm) {
    return (
      <MuiGrid item xs={12} key={testResultFocused.eventId}>
        <MuiBox p={3} whiteSpace="pre-line">
          <MuiTypography>TEST RESULT NOT AVAILABLE</MuiTypography>
        </MuiBox>
      </MuiGrid>
    );
  }
  // Pathology and imaging are slightly different in how they come back from
  // the service
  const content = data?.content?.content || data?.content;
  return (
    <MuiBox>
      {isLoading ? (
        <MuiBox p={3} flex={1} alignItems="center" justifyContent="center">
          <Spinner />
        </MuiBox>
      ) : (
        <MuiBox height={!data?.contentType?.includes('html') ? 'calc(100vh - 300px)' : null}>
          <DocumentView
            content={content}
            contentType={data?.contentType}
            documentName="TestResults"
          />
        </MuiBox>
      )}
    </MuiBox>
  );
};

const mapStateToProps = (state: RootState) => ({
  isLoading: selectors.testResultDetailsLoadingSelector(state),
  error: selectors.testResultDetailsErrorSelector(state),
  data: selectors.testResultDetailsDataSelector(state),
  currentUrl: routeSelectors.currentLocationPathNameSelector(state),
  referringUrl: routeSelectors.previousLocationPathNameSelector(state),
  encryptionSettings: appSettingsSelectors.encryptionSettingsSelector(state)
});

export default connect(mapStateToProps)(ResultDocumentHandler);
