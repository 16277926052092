import React, { ReactNode } from 'react';

import { ViewStyleProp } from 'react-native/Libraries/StyleSheet/StyleSheet';
import styled from 'styled-components';

import { borderRadius } from 'modules/styles/variables';

import { StyledPanel, StyledTouchable } from './TouchablePanel';

interface Props {
  firstChild?: boolean;
  lastChild?: boolean;
  onPress: Function;
  children: ReactNode;
  style?: ViewStyleProp;
}

export const StyledPanelItem = styled(StyledPanel)`
  border-top-width: ${props => (props.firstChild ? 1 : 0)};
  border-top-left-radius: ${props => (props.firstChild ? borderRadius : 0)};
  border-top-right-radius: ${props => (props.firstChild ? borderRadius : 0)};
  border-bottom-left-radius: ${props => (props.lastChild ? borderRadius : 0)};
  border-bottom-right-radius: ${props => (props.lastChild ? borderRadius : 0)};
  ${props => props.style};
`;

export const StyledTouchableItem = styled(StyledTouchable)`
  margin: 0px;
  border-top-left-radius: ${props => (props.firstChild ? borderRadius : 0)};
  border-top-right-radius: ${props => (props.firstChild ? borderRadius : 0)};
  border-bottom-left-radius: ${props => (props.lastChild ? borderRadius : 0)};
  border-bottom-right-radius: ${props => (props.lastChild ? borderRadius : 0)};
`;

const TouchablePanelItem = (props: Props) => (
  <StyledTouchableItem
    firstChild={props.firstChild}
    lastChild={props.lastChild}
    onClick={props.onPress}
  >
    <StyledPanelItem firstChild={props.firstChild} lastChild={props.lastChild} {...props}>
      {props.children}
    </StyledPanelItem>
  </StyledTouchableItem>
);

TouchablePanelItem.defaultProps = {
  firstChild: false,
  lastChild: false,
  style: {}
};

export default TouchablePanelItem;
