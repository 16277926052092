import React, { useEffect, useState } from 'react';
import useNotificationsOptOutDisplay from 'hooks/useNotificationsOptOutDisplay';
import { IconSize } from 'modules/styles/variables';
import { Color } from 'modules/styles/colors';
import { NavigationScreenProps } from 'screens/navigation';
import { MuiBox, MuiContainer, MuiGrid } from 'theme/material-ui';
import DashboardCarousel from 'screens/Dashboard/DashboardCarousel/DashboardCarousel';
import DashboardMessagesContainer from './DashboardMessages';
import DashboardUpdates from './DashboardUpdates/DashboardUpdates';
import DashboardQuicklinks from './DashboardQuicklinks/DashboardQuicklinks';
import DashboardAppointments from './DashboardAppointments/DashboardAppointments';
import DashboardMyCareTeam from './DashboardMyCareTeam/DashboardMyCareTeam';
import { Container, StyledSnackbar, StyledText } from './styled';
import { useLocation } from 'react-router-dom';
import Svg from 'components/UI/Svg/Svg';
import DashboardBilling from './DashboardBilling/DashboardBilling';
import { EmailAlert } from 'components/Alert/email-alert';
import { useShowEmailAlert } from 'lib/hooks/useShowEmailAlert';

export const Dashboard = ({ history }: NavigationScreenProps) => {
  const [open, setOpen] = useState(false);
  const [openEmailAlert, setOpenEmailAlert] = useShowEmailAlert();
  const location = useLocation();
  const { category, goToId } = location?.state || {};

  useNotificationsOptOutDisplay();

  useEffect(() => {
    const { state }: { showSnackbar?: boolean } = location;
    if (state && state.showSnackbar) {
      setOpen(true);
    }
  }, []);

  const handleEmailAlertClose = (action: 'closeDialog' | 'closeX' | 'closeBtn' | 'update') => {
    setOpenEmailAlert(action);

    if (action === 'update') {
      history.push('/u/manage-account/edit-email');
    }
  };

  return (
    <Container>
      <EmailAlert open={openEmailAlert} handleClose={handleEmailAlertClose} />
      <MuiContainer maxWidth="lg" disableGutters>
        <DashboardCarousel goToId={category === 'carousel' ? goToId : undefined} />
      </MuiContainer>
      <MuiContainer maxWidth="lg">
        <MuiBox mb={3}>
          <MuiGrid container spacing={4}>
            <MuiGrid item xs={12} md={12} lg={12}>
              <DashboardQuicklinks />
            </MuiGrid>
            <MuiGrid item xs={12} sm={12} md={12} lg={6}>
              <DashboardMyCareTeam />
            </MuiGrid>
            <MuiGrid item xs={12} sm={12} md={12} lg={6}>
              <DashboardAppointments />
            </MuiGrid>
            <DashboardBilling />
            <DashboardMessagesContainer />
            <DashboardUpdates history={history} />
          </MuiGrid>
        </MuiBox>
      </MuiContainer>
      <StyledSnackbar open={open} onClose={() => setOpen(false)} autoHideDuration={6000}>
        <MuiBox display="flex" flexDirection="row">
          <Svg name="InfoIcon" set="assets" size={IconSize.large} color={Color.white} />
          <StyledText>Your information has been submitted to your doctor for review.</StyledText>
        </MuiBox>
      </StyledSnackbar>
    </Container>
  );
};

export default Dashboard;
