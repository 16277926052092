import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import orderBy from 'lodash/orderBy';
import CollapsibleListItem from 'components/common/CollapsibleList/CollapsibleListItem';
import Svg from 'components/UI/Svg/Svg';
import { Color } from 'modules/styles/colors';
import { FontSize, IconSize, Spacing, ZIndex } from 'modules/styles/variables';
import { connect } from 'react-redux';
import { capitalizeEachWord } from 'modules/utils/StringUtils';
import { visitSummaryDataSelector } from 'store/visitSummary/selectors';
import { RootState } from 'store/types';
import { getLastThreeYears, getProviders, getLocations } from 'lib/visitSummary/utils';
import VisitSummaryFilterGroup from './VisitSummaryFilterGroup';
import FilterCalendar from './FilterCalendar';
import { VisitSummaryFilter, VisitSummary, VisitType, FilterItem } from 'store/visitSummary/types';
import {
  CursorMuiBox,
  MuiBox,
  MuiButton,
  MuiDivider,
  MuiGrid,
  MuiRadioGroup,
  MuiTypography
} from 'theme/material-ui';
import {
  FilterDrawer,
  FilterFormControlLabel,
  FilterList,
  FilterRadio,
  FilterTitle,
  StyledGridItemOne,
  StyledGridItemTwo
} from './styled';

interface Props {
  close: () => void;
  applyFilters: (state: VisitSummaryFilter) => void;
  clearAllFilters: () => void;
  filterState: VisitSummaryFilter;
  data: VisitSummary[];
  open: boolean;
}

enum FilterKey {
  START_DATE = 'startDate',
  END_DATE = 'endDate',
  YEAR = 'year',
  PROVIDER = 'provider',
  LOCATION = 'location',
  VISIT_TYPE = 'visitType'
}

const changeValue = (data: FilterItem[], value: string) => {
  return data.map(item => {
    item.checked = item.value === value;
    return item;
  });
};

const FilterModal = ({ close, applyFilters, clearAllFilters, filterState, open, data }: Props) => {
  const [endDate, setEndDate] = useState(dayjs());
  const [startDate, setStartDate] = useState(dayjs().subtract(10, 'year'));
  const [providers, setProviders] = useState<FilterItem[]>([]);
  const [locations, setLocations] = useState<FilterItem[]>([]);
  const [visitTypes, setVisitTypes] = useState<FilterItem[]>([]);
  const [years, setYears] = useState<FilterItem[]>([]);
  const [showDates, changeShowDates] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [state, setState] = useState(filterState);

  useEffect(() => {
    setYears(getLastThreeYears());
    getVisitTypes();
    getInitialState();
  }, []);

  useEffect(() => {
    const checkYear: FilterItem[] = changeValue(years, filterState[FilterKey.YEAR]);
    const checkVisitType: FilterItem[] = changeValue(visitTypes, filterState[FilterKey.VISIT_TYPE]);
    const checkProvider: FilterItem[] = changeValue(providers, filterState[FilterKey.PROVIDER]);
    const checkLocation: FilterItem[] = changeValue(locations, filterState[FilterKey.LOCATION]);
    if (checkYear.length) {
      setYears(checkYear);
      setVisitTypes(checkVisitType);
      setProviders(checkProvider);
      setLocations(checkLocation);
    }
    setState(filterState);
  }, [filterState]);

  const getInitialState = () => {
    setLocations(getLocations(data));
    setProviders(getProviders(data));
  };

  const getVisitTypes = () => {
    const newVisitTypes: FilterItem[] = Object.values(VisitType).map(type => ({
      label: capitalizeEachWord(type),
      value: type,
      checked: false
    }));

    setVisitTypes(newVisitTypes);
  };

  const handleShowDates = () => {
    changeShowDates(true);
    setState(previousState => ({ ...previousState, year: '' }));
    const uncheckedYears: FilterItem[] = changeValue(years, '');
    setYears(uncheckedYears);
  };

  const handleFilterChange = (
    sectionKey: string,
    value: string,
    items: FilterItem[],
    setStateCb: React.Dispatch<React.SetStateAction<FilterItem[]>>
  ) => {
    setDisabled(false);
    const itemsToSet: FilterItem[] = changeValue(items, value);
    setStateCb(itemsToSet);
    if (sectionKey === FilterKey.YEAR) {
      changeShowDates(false);
      setState(previousState => ({
        ...previousState,
        [sectionKey]: value,
        startDate: '',
        endDate: ''
      }));
    } else setState(previousState => ({ ...previousState, [sectionKey]: value }));
  };

  const handleDates = (sectionKey: string, date: any) => {
    setDisabled(false);
    setState(previousState => ({ ...previousState, [sectionKey]: date }));
    if (FilterKey.START_DATE === sectionKey) setStartDate(date);
    else setEndDate(date);
  };

  const onApply = () => {
    applyFilters(state);
    close();
  };

  const unCheckCallback = (item: FilterItem) => ({ ...item, checked: false });

  const cleanFilters = () => {
    setEndDate(dayjs());
    setStartDate(dayjs().subtract(10, 'year'));
    setProviders(providers.map(unCheckCallback));
    setLocations(locations.map(unCheckCallback));
    setVisitTypes(visitTypes.map(unCheckCallback));
    setYears(years.map(unCheckCallback));
    changeShowDates(false);
    setDisabled(true);
    clearAllFilters();
    close();
  };

  const yearsToRender = years.length
    ? years.map(year => (
        <FilterFormControlLabel
          key={year.value}
          for={`${year.label}-radio`}
          control={
            <FilterRadio id={`${year.label}-radio`} color="primary" checked={year.checked} />
          }
          label={year.label}
          value={year.value}
          onClick={() => handleFilterChange(FilterKey.YEAR, year.value, years, setYears)}
        />
      ))
    : [];

  return (
    <FilterDrawer
      variant="temporary"
      anchor="right"
      open={open}
      onClose={close}
      ModalProps={{
        keepMounted: true
      }}
    >
      <MuiBox>
        <CursorMuiBox
          position="absolute"
          top="24px"
          right={Spacing.large}
          onClick={close}
          zIndex={ZIndex.high}
        >
          <Svg set="material" name="close" color={Color.black} size={IconSize.base} />
        </CursorMuiBox>
        <FilterTitle>
          <MuiBox display="flex" alignItems="center">
            <MuiBox marginRight={1}>
              <MuiTypography color={Color.black} fontSize={FontSize.large}>
                Filters
              </MuiTypography>
            </MuiBox>
          </MuiBox>
        </FilterTitle>
        <FilterList>
          <VisitSummaryFilterGroup
            items={orderBy(providers, 'label')}
            subtitle="Providers"
            sectionKey={FilterKey.PROVIDER}
            initialItemsNumber={6}
            handleRadioClick={(value: string) =>
              handleFilterChange(FilterKey.PROVIDER, value, providers, setProviders)
            }
          />
          <MuiDivider />
          <VisitSummaryFilterGroup
            items={orderBy(locations, 'label')}
            subtitle="Locations"
            sectionKey={FilterKey.LOCATION}
            initialItemsNumber={6}
            handleRadioClick={(value: string) =>
              handleFilterChange(FilterKey.LOCATION, value, locations, setLocations)
            }
          />
          <MuiDivider />
          <VisitSummaryFilterGroup
            items={visitTypes}
            subtitle="Visit Types"
            sectionKey={FilterKey.VISIT_TYPE}
            initialItemsNumber={4}
            handleRadioClick={(value: string) =>
              handleFilterChange(FilterKey.VISIT_TYPE, value, visitTypes, setVisitTypes)
            }
          />
          <MuiDivider />
          <CollapsibleListItem
            headerColorOnExpand={Color.grayLight}
            subtitle="Date Range"
            key="dateRange"
            firstListItem
            padding={`${Spacing.medium}px 0px`}
          >
            <MuiBox p={0} m={0}>
              <MuiRadioGroup>
                {yearsToRender}
                <FilterFormControlLabel
                  value="Custom"
                  control={<FilterRadio id="custom-radio" color="primary" checked={showDates} />}
                  for="custom-radio"
                  label="Custom"
                  onClick={handleShowDates}
                />
              </MuiRadioGroup>
              <MuiBox display={showDates ? 'block' : 'none'} paddingBottom={2}>
                <MuiGrid container xs={12}>
                  <StyledGridItemOne item xs={6}>
                    <FilterCalendar
                      value={startDate}
                      label="From"
                      handleChange={date => handleDates(FilterKey.START_DATE, date)}
                    />
                  </StyledGridItemOne>
                  <StyledGridItemTwo item xs={6}>
                    <FilterCalendar
                      value={endDate}
                      label="To"
                      handleChange={date => handleDates(FilterKey.END_DATE, date)}
                    />
                  </StyledGridItemTwo>
                </MuiGrid>
              </MuiBox>
            </MuiBox>
          </CollapsibleListItem>
          <MuiDivider />
        </FilterList>
      </MuiBox>
      <MuiBox width="100%" p={2} marginTop="auto">
        <MuiButton
          fullWidth
          size="large"
          variant="contained"
          color="primary"
          onClick={onApply}
          data-testid="apply-filters-button"
          disabled={disabled}
        >
          Apply
        </MuiButton>
        <MuiButton fullWidth size="large" variant="text" color="primary" onClick={cleanFilters}>
          Clear all
        </MuiButton>
      </MuiBox>
    </FilterDrawer>
  );
};

const mapStateToProps = (state: RootState) => ({
  data: visitSummaryDataSelector(state)
});

export default connect(mapStateToProps)(FilterModal);
