import React from 'react';

import { Color } from 'modules/styles/colors';

export const styles = {
  fill: 'none',
  stroke: Color.white,
  strokeWidth: 2,
  strokeLinecap: 'round',
  strokeLinejoin: 'round',
  strokeMiterlimit: 10
} as const;

export default {
  svg: (
    <g>
      <g>
        <path
          {...styles}
          d="M30.02,23.14L35.16,18c1.07-1.07,1.07-2.81,0-3.88l0,0c-1.07-1.07-2.81-1.07-3.88,0l-1.26,1.26l-1.26-1.26
			c-1.07-1.07-2.81-1.07-3.88,0l0,0c-1.07,1.07-1.07,2.81,0,3.88L30.02,23.14z"
        />
      </g>
      <path
        {...styles}
        d="M38.15,29.66l7.67,1.81c1.55,0.37,2.05,1.24,1.1,1.95L27.36,47.78c-0.95,0.7-3,0.98-4.55,0.61l-13.94-3.3
		c-1.55-0.37-2.05-1.24-1.1-1.95l15.54-11.41"
      />
      <path
        {...styles}
        d="M33.6,32.64l4.94,1.17c0.86,0.2,1.14,0.69,0.61,1.08l-10.33,7.66c-0.53,0.39-1.66,0.54-2.53,0.34l-7.68-1.82
		c-0.86-0.2-1.14-0.69-0.61-1.08l8.35-6.19"
      />
      <ellipse
        {...styles}
        transform="matrix(0.9993 -0.0361 0.0361 0.9993 -1.5894 0.7186)"
        cx="19.11"
        cy="44.39"
        rx="1.94"
        ry="0.84"
      />
      <g>
        <g>
          <line {...styles} x1="48.69" y1="18.23" x2="54.07" y2="18.23" />
          <line {...styles} x1="48.69" y1="10.93" x2="54.07" y2="8.47" />
          <line {...styles} x1="48.69" y1="25.53" x2="54.07" y2="27.98" />
        </g>
        <g>
          <line {...styles} x1="11.31" y1="18.23" x2="5.93" y2="18.23" />
          <line {...styles} x1="11.31" y1="10.93" x2="5.93" y2="8.47" />
          <line {...styles} x1="11.31" y1="25.53" x2="5.93" y2="27.98" />
        </g>
      </g>
      <path {...styles} d="M46.91,37.23L27.36,51.58c-0.95,0.7-3,0.98-4.55,0.61l-13.94-3.3" />
      <path
        {...styles}
        d="M43.82,17.49c0-5.43-6.18-9.84-13.8-9.84c-7.62,0-13.8,4.41-13.8,9.84c0,4.88,4.99,8.92,11.53,9.7l2.26,7.08
		l2.26-7.08C38.83,26.41,43.82,22.37,43.82,17.49z"
      />
    </g>
  ),
  viewBox: '0 0 60 60'
};
