import { Alert } from 'components/Alert';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import Logger, { LoggingModule } from 'services/Logger';
import analyticsService, { AmplitudeEventData, AnalyticsEvent } from 'services/AnalyticsService';
import { ProfileUpdateMessages } from 'store/profile/constants';
import {
  getProfile,
  resetValidationState,
  sendPin,
  updateConsumerProfile,
  validatePin
} from 'store/profile/actions';
import * as profileSelectors from 'store/profile/selectors';
import { Consumer, SendPinProps } from 'store/profile/types';
import { RootState } from 'store/types';
import { getUpdatedEmailConsumer } from './utils';
import { UPDATE_DISCLAIMER } from './variables';
import EditEmailComponent from 'components/EditEmail';

export interface EditEmailScreenProps extends RouteComponentProps {
  consumer: Consumer;
  fetchProfile: () => void;
  resetValidation: () => void;
  validated: boolean;
  sendError: boolean;
  sendPinToEmail: (data: SendPinProps) => Promise<any>;
  validateEmailPin: (email: string, pin: string) => Promise<any>;
  updateEmail: (update: Consumer) => Promise<any>;
}

export function EditEmailScreen({
  consumer,
  fetchProfile,
  resetValidation,
  sendError,
  sendPinToEmail,
  updateEmail,
  validateEmailPin,
  validated,
  history
}: EditEmailScreenProps) {
  const [isLoading, setIsLoading] = useState(false);

  const initialEmail = consumer?.emails?.[0]?.value || '';
  const data: AmplitudeEventData = {
    currentUrl: 'Edit Email',
    referringUrl: 'Profile',
    type: 'email'
  };

  const handleCancel = () => {
    history.push('/u/manage-account');
  };

  const onSubmit = async (pin: string, emailToSubmit: string) => {
    if (!emailToSubmit) return false;

    analyticsService.logEvent(AnalyticsEvent.MyAccountEditOptionsEdited, data);

    setIsLoading(true);

    const validateRes = await validateEmailPin(emailToSubmit, pin);

    if (validateRes.error) {
      Logger.error(`${LoggingModule.profile}Email verification failed`, validateRes.error.message);

      setIsLoading(false);

      return Alert.alert(
        ProfileUpdateMessages.VALIDATE_EMAIL.title,
        ProfileUpdateMessages.VALIDATE_EMAIL.message
      );
    }

    const updatedConsumer = getUpdatedEmailConsumer(consumer, emailToSubmit);
    const res = await updateEmail(updatedConsumer);

    if (res.error) {
      Logger.error(`${LoggingModule.profile}Failed to update email`, res.error.message);

      setIsLoading(false);

      return Alert.alert(
        ProfileUpdateMessages.UPDATE_EMAIL.title,
        ProfileUpdateMessages.UPDATE_EMAIL.message
      );
    }

    setIsLoading(false);

    analyticsService.logEvent(AnalyticsEvent.MyAccountEditOptionsCompleted, data);

    return Alert.alert('Email Updated', UPDATE_DISCLAIMER, [
      {
        text: 'OK',
        onPress: () => {
          fetchProfile();
          history.push('/u/manage-account');
        }
      }
    ]);
  };

  const props = {
    initialEmail,
    resetValidation,
    sendError,
    sendPin: async (data: SendPinProps) => sendPinToEmail(data),
    validated,
    onSubmit,
    handleCancel,
    isLoading
  };
  return <EditEmailComponent {...props} />;
}

const mapDispatch = (dispatch: Function) => ({
  resetValidation: () => dispatch(resetValidationState()),
  sendPinToEmail: (data: SendPinProps) => dispatch(sendPin(data)),
  validateEmailPin: (email: string, pin: string) =>
    dispatch(validatePin({ type: 'email', email, pin })),
  updateEmail: (update: Consumer) => dispatch(updateConsumerProfile(update, ['Email'])),
  fetchProfile: () => dispatch(getProfile('', true))
});

const mapState = (state: RootState) => ({
  consumer: profileSelectors.profileConsumerSelector(state),
  validated: profileSelectors.profileValidatedSelector(state),
  sendError: profileSelectors.profileValidationSendErrorSelector(state)
});

export default connect(mapState, mapDispatch)(EditEmailScreen);
