import React from 'react';
import { MuiCard, MuiCardContent, MuiCardMedia, MuiTypography } from 'theme/material-ui';
import { FontWeight } from 'modules/styles/variables';

export interface MediaCardProps {
  image?: string;
  title: string;
  isSmallScreen?: boolean;
}

const MediaCard = ({ title, image, isSmallScreen }: MediaCardProps) => {
  return (
    <MuiCard style={{ height: '100%' }}>
      {image ? (
        <MuiCardMedia image={image} style={{ height: isSmallScreen ? '155px' : '220px' }} />
      ) : null}
      <MuiCardContent style={{ padding: '25px 40px' }}>
        <MuiTypography component="h4" fontSize={18} fontWeight={FontWeight.bold} color="primary">
          {title}
        </MuiTypography>
      </MuiCardContent>
    </MuiCard>
  );
};

export default MediaCard;
