import { connect } from 'react-redux';
import { applyInboxSearch } from 'store/messaging/actions';
import { inboxSearchTextSelector } from 'store/messaging/selectors';
import { RootState } from 'store/types';
import MessageSearchBar from 'components/Messaging/MessageSearchBar';

export default connect(
  (state: RootState) => ({
    value: inboxSearchTextSelector(state)
  }),
  { onSearch: applyInboxSearch }
)(MessageSearchBar);
