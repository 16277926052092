import { EVISITS_ROUTES } from 'screens/EVisits/constants';

import {
  eVisitFrontDoorInsuranceScreen,
  eVisitFrontDoorNewInsuranceScreen,
  eVisitFrontDoorOverviewScreen,
  eVisitFrontDoorTermsAndAgreementsScreen
} from 'lib/triage/constants';
import { RouteData } from '../navigation';
import { EVisitOverview } from './EVisitOverview';
import EVisitsSuccessScreen from './EVisitSuccessScreen';
import EVisitsStateLocation from './EVisitsStateLocation';
import EVisitFrontDoorConditionsWeTreat from './FrontDoor/EVisitConditionsWeTreat';
import EVisitInsurance from './FrontDoor/EVisitInsurance';
import EVisitLoading from './FrontDoor/EVisitLoading';
import EVisitFrontDoorOverview from './FrontDoor/EVisitOverview';
import EVisitFrontDoorTermsAndAgreements from './FrontDoor/EVisitTermsAndAgreements';
import EVisitUploadInsurance from './FrontDoor/EVisitUploadInsurance';
import EvisitPatientForm from './FrontDoor/EvisitPatientForm';

const title = 'E-Visit Appointment';
const eVisitsNavigation: RouteData[] = [
  {
    path: EVISITS_ROUTES.OVERVIEW,
    component: EVisitOverview,
    componentName: 'EVisitOverview',
    title
  },
  {
    path: EVISITS_ROUTES.STATE_SELECTOR,
    component: EVisitsStateLocation,
    componentName: 'EVisitsStateLocation',
    title
  },
  {
    path: `${EVISITS_ROUTES.REQUEST_SUCCESS}/:encounterId?`,
    component: EVisitsSuccessScreen,
    componentName: 'EVisitSuccessScreen',
    title
  },
  {
    path: EVISITS_ROUTES.FRONTDOOR_OVERVIEW,
    component: EVisitFrontDoorOverview,
    componentName: 'EVisitFrontDoorOverview',
    title: eVisitFrontDoorOverviewScreen.screenTitle,
    includeCrumbs: false,
    includeBanner: false
  },
  {
    path: EVISITS_ROUTES.FRONTDOOR_TERMS_AND_AGREEMENTS,
    component: EVisitFrontDoorTermsAndAgreements,
    componentName: 'EVisitFrontDoorTermsAndAgreements',
    title: eVisitFrontDoorTermsAndAgreementsScreen.screenTitle,
    includeCrumbs: false,
    includeBanner: false
  },
  {
    path: EVISITS_ROUTES.FRONTDOOR_CONDITIONS_WE_TREAT,
    component: EVisitFrontDoorConditionsWeTreat,
    componentName: 'EVisitFrontDoorConditionsWeTreat',
    title: 'E-Visit Conditions We Treat',
    includeBanner: false
  },
  {
    path: EVISITS_ROUTES.FRONTDOOR_INSURANCE,
    component: EVisitInsurance,
    componentName: 'EVisitFrontDoorInsurance',
    title: eVisitFrontDoorInsuranceScreen.screenTitle,
    includeCrumbs: false,
    includeBanner: false
  },
  {
    path: EVISITS_ROUTES.FRONTDOOR_INSURANCE_UPLOAD,
    component: EVisitUploadInsurance,
    componentName: 'EVisitUploadInsurance',
    title: eVisitFrontDoorNewInsuranceScreen.screenTitle,
    includeCrumbs: false,
    includeBanner: false
  },
  {
    path: EVISITS_ROUTES.FRONTDOOR_INSURANCE_PROCESSED,
    component: EVisitLoading,
    componentName: 'EVisitLoading',
    title: '',
    includeCrumbs: false,
    includeBanner: false
  },
  {
    path: EVISITS_ROUTES.FRONTDOOR_INSURANCE_SUBMIT,
    component: EvisitPatientForm,
    componentName: 'EvisitPatientForm',
    title: eVisitFrontDoorOverviewScreen.screenTitle,
    includeCrumbs: false,
    includeBanner: false
  }
];

export default eVisitsNavigation;
