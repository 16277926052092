import React from 'react';

import { CovidTitles } from 'lib/constants/covidScreening';
import { CovidTestGuestFooter } from 'screens/CovidTestGuest/components';

import { CovidAgreementGuestForm } from './components';
import CovidGuestGuard from '../../navigation/CovidGuestLeavingGuard';

export default function CovidAgreementGuest() {
  return (
    <>
      <CovidGuestGuard />
      <CovidAgreementGuestForm />
      <CovidTestGuestFooter title={CovidTitles.CONSENT_AND_AGREEMENTS} />
    </>
  );
}
