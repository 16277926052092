import React from 'react';
import { connect } from 'react-redux';
import { MuiBox, MuiTypography, MuiGrid, MuiContainer } from 'theme/material-ui';
import { StyledMuiButton } from './styled';
import { StyledScreen } from 'screens/HealthTools/Cards';
import { FontSize, FontWeight } from 'modules/styles/variables';
import { Color } from 'modules/styles/colors';
import myStrengthBanner from 'assets/myStrength/myStrength-web.png';
import HealthToolsPictureBanner from 'components/HealthToolsCards/HealthToolsPictureBanner';
import Spacer from 'components/UI/Layout/Spacer';
import FlexBox from 'components/UI/Layout/FlexBox';
import useNavigationAnalytics from 'hooks/useNavigationAnalytics';
import { AnalyticsEvent } from 'services/AnalyticsService';
import { profileConsumerSelector } from 'store/profile/selectors';
import { Consumer } from 'store/profile/types';
import { RootState } from 'store/types';
import Config from 'react-native-config';
import { CompanyName } from 'modules/utils/ConfigUtils';

export interface HealthToolsMyStrengthProps {
  userProfile: Consumer;
}

const openMyStrength = () => {
  window.open(`${Config.AUTH_SERVER_BASE_URL}${Config.MY_STRENGTH_AUTH_PATH}`, '_blank');
};

export const HealthToolsMyStrengthHome = (props: HealthToolsMyStrengthProps) => {
  const { userProfile } = props;

  const launchMyStrengthClicked = useNavigationAnalytics(AnalyticsEvent.MyStrengthLaunchClicked, {
    title: 'myStrength Landing Page',
    vendor: 'myStrength',
    age: userProfile?.age,
    gender: userProfile?.gender
  });

  const launchMyStrength = () => {
    launchMyStrengthClicked();
    openMyStrength();
  };

  return (
    <StyledScreen>
      <MuiBox mb={3} display="center" data-testid="myStrength">
        <HealthToolsPictureBanner
          pictureBannerSrc={myStrengthBanner}
          titleBanner="Behavioral and Mental Health resources to help you take charge of your well-being"
          dataTestId="section-description"
        />
        <MuiContainer maxWidth="lg">
          <FlexBox alignItems="center">
            <MuiTypography>
              <Spacer size="medium" />
              <MuiTypography fontSize={FontSize.large} fontWeight={FontWeight.regularbold}>
                {CompanyName} has partnered with myStrength to bring you resources to help build a
                healthier mind for a stronger you{' '}
              </MuiTypography>
            </MuiTypography>
            <Spacer size="medium" />
            <MuiGrid>
              <MuiBox alignItems="center" display="inline">
                <StyledMuiButton
                  variant="contained"
                  color={Color.secondary}
                  onClick={launchMyStrength}
                  data-testid="launch-mystrength-button"
                >
                  Launch myStrength
                </StyledMuiButton>
              </MuiBox>
            </MuiGrid>
            <Spacer size="medium" />
          </FlexBox>
        </MuiContainer>
      </MuiBox>
    </StyledScreen>
  );
};

const mapState = (state: RootState) => ({
  userProfile: profileConsumerSelector(state)
});

export default connect(mapState)(HealthToolsMyStrengthHome);
