import React from 'react';
import { Color } from 'modules/styles/colors';
import { SvgProps } from 'components/UI/Svg/Svg';

export default (props?: SvgProps) => {
  const styles = {
    fill: 'none',
    stroke: props?.color || Color.black,
    strokeWidth: 2,
    strokeLinecap: 'round',
    strokeLinejoin: 'round'
  } as const;

  return {
    svg: (
      <g clipPath="url(#clip0_130_68)">
        <path
          d="M18.5 0.75L7.78 11.47C7.71034 11.5396 7.65507 11.6222 7.61736 11.7131C7.57965 11.8041 7.56025 11.9016 7.56025 12C7.56025 12.0984 7.57965 12.1959 7.61736 12.2869C7.65507 12.3778 7.71034 12.4604 7.78 12.53L18.5 23.25"
          {...styles}
        />
      </g>
    ),
    viewBox: '0 0 24 24'
  };
};
