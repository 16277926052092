import DefaultText from 'components/UI/Typography/DefaultText';
import { Color } from 'modules/styles/colors';
import { toSentenceCase } from 'modules/utils/StringUtils';
import React from 'react'; // import { ViewStyleProp } from 'react-native/Libraries/StyleSheet/StyleSheet';
import { GestureResponderEvent, StyleProp, TextStyle, ViewStyle } from 'react-native';
import { Icon } from 'components/Icon';
import styled from 'styled-components';

interface LinkButtonProps {
  onPress?: (event: GestureResponderEvent) => void;
  children: React.ReactNode;
  style?: StyleProp<ViewStyle>;
  disabled?: boolean;
  invert?: boolean;
  iconName?: string;
  justifyContent: string;
  accessibilityLabel?: string;
  testID?: string;
  textStyle?: StyleProp<TextStyle>;
}

const StyledTouchable = styled.div`
  align-items: center;
  background-color: transparent;
  border-radius: 5px;
  min-height: 36px;
  min-width: 80px;
  justify-content: center;
  margin-bottom: 3px;
  margin-top: 3px;
  max-height: 38px;
  :hover {
    cursor: pointer;
  }
`;

const StyledText = styled(DefaultText)<{ invert?: boolean; textStyle?: {} }>`
  color: ${props => (props.invert ? Color.foreColor : Color.primary)};
  letter-spacing: 0.4px;
  text-align: center;
  padding: 8px 12px 6px 12px;
`;

const Group = styled.div<{ justifyContent?: string }>`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: ${props => props.justifyContent};
  width: 100%;
`;

const LinkButton = (props: LinkButtonProps) => (
  <StyledTouchable
    style={props.style}
    testID={props.testID}
    underlayColor={Color.flatUnderlay}
    onClick={props.onPress}
    disabled={props.disabled}
    accessible
    accessibilityLabel={props.accessibilityLabel}
    accessibilityRole="link"
  >
    <Group justifyContent={props.justifyContent}>
      <StyledText style={props.textStyle} invert={props.invert}>
        {toSentenceCase(props.children)}
      </StyledText>
      {props.iconName ? (
        <Icon
          name={props.iconName}
          size={24}
          color={props.invert ? Color.foreColor : Color.primary}
        />
      ) : null}
    </Group>
  </StyledTouchable>
);

LinkButton.defaultProps = {
  style: {},
  disabled: false,
  invert: false,
  iconName: undefined,
  justifyContent: 'center'
};

export default LinkButton;
