import { Paper } from '@material-ui/core';
import Divider from 'components/UI/Divider';
import Spinner from 'components/UI/Spinner/Spinner';
import { MuiBox, MuiButton, MuiList, MuiTypography } from 'theme/material-ui';
import { formatPastDisplayDate } from 'modules/utils/DateUtils';
import { objectToQsParam } from 'modules/utils/UrlUtils';
import React, { useCallback } from 'react';
import { FlatList, ListRenderItem } from 'react-native';
import { MessageFolderType, MessageStatus, MessageSummary } from 'store/messaging/types';
import { oc } from 'ts-optchain';
import MessageListItem from './MessageListItem';
import { CompanyName } from 'modules/utils/ConfigUtils';

const MessageListError = (props: { onRefresh: () => void }) => (
  <MuiBox p={3} display="flex" justifyContent="center" flexDirection="column">
    <MuiTypography align="center" variant="body2">
      There was an issue loading messages
    </MuiTypography>

    <MuiButton color="primary" variant="text" onClick={props.onRefresh}>
      Refresh
    </MuiButton>
  </MuiBox>
);

const MessageListEmpty = (props: { onRefresh: () => void }) => (
  <MuiBox p={3} display="flex" justifyContent="center" alignItems="center" flexDirection="column">
    <MuiTypography variant="body2">No messages found</MuiTypography>
    <MuiButton color="primary" variant="text" onClick={props.onRefresh}>
      Refresh
    </MuiButton>
  </MuiBox>
);

const MessageListLoading = () => (
  <MuiBox p={5} display="flex" alignItems="center" justifyContent="center">
    <Spinner />
  </MuiBox>
);

interface MessageListProps {
  messages: MessageSummary[];
  onPress: (item: MessageSummary) => void;
  onRefresh: () => void;
  loading: boolean;
  error: Error | null;
  showStatus: boolean;
  folder: MessageFolderType;
}

const keyExtractor = (item: MessageSummary) => `${item.id}`;
const itemSeparator = () => <Divider />;

export const OutboxMessageListItem = ({ message }: { message: MessageSummary }) => {
  const receiver = oc(message).receivers[0]({});
  const title = receiver.receiverGroupName || receiver.receiverDisplayName || '(Unknown Recipient)';
  const location = receiver.receiverPoolName;
  const body = message.subject || '(No Subject)';
  const hasAttachment = message?.hasAttachment;
  const numberOfFiles = message.receivers?.length > 1 ? message.receivers.length : 0;
  const to = `/u/messages/outbox/${message.source}/${objectToQsParam(message.mhpDetails)}`;

  return (
    <MessageListItem
      to={to}
      title={title}
      subtitle={location}
      body={body}
      hasAttachment={hasAttachment}
      icon="keyboard-arrow-right"
      footer={formatPastDisplayDate(message.createDate)}
      numberOfFiles={numberOfFiles}
    />
  );
};

export const InboxMessageListItem = ({ message }: { message: MessageSummary }) => {
  const title = message.senderDisplayName || message.senderFullName || CompanyName;
  const location = message.senderPoolName;
  const body = message.subject || '(No Subject)';
  const imageUrl = oc(message).senderProfileImageUrl();
  const hasAttachment = message?.hasAttachment;
  const to = `/u/messages/inbox/${message.source}/${objectToQsParam(message.mhpDetails)}`;

  return (
    <MessageListItem
      to={to}
      imageUrl={imageUrl}
      title={title}
      subtitle={location}
      body={body}
      hasAttachment={hasAttachment}
      icon="keyboard-arrow-right"
      footer={formatPastDisplayDate(message.createDate)}
      unread={message.status === MessageStatus.UNREAD}
    />
  );
};

const MessageList = (props: MessageListProps) => {
  const renderListItem: ListRenderItem<MessageSummary> = useCallback(
    ({ item }) => {
      return (
        <MuiBox onClick={() => props.onPress(item)}>
          {props.folder === MessageFolderType.Inbox ? (
            <InboxMessageListItem message={item} />
          ) : (
            <OutboxMessageListItem message={item} />
          )}
        </MuiBox>
      );
    },
    [props.onPress]
  );

  if (props.loading) {
    return <MessageListLoading />;
  }

  if (props.error) {
    return <MessageListError onRefresh={props.onRefresh} />;
  }

  if (!props.messages || !props.messages.length) {
    return <MessageListEmpty onRefresh={props.onRefresh} />;
  }

  return (
    <Paper square>
      <MuiList>
        <FlatList
          data={props.messages}
          keyExtractor={keyExtractor}
          onRefresh={props.onRefresh}
          refreshing={props.loading}
          ItemSeparatorComponent={itemSeparator}
          renderItem={renderListItem}
        />
      </MuiList>
    </Paper>
  );
};

export default MessageList;
