import React, { useMemo, useState } from 'react';

import { ConnectCareTextField } from 'components/ConnectCare';
import RequiredLabel from 'components/UI/Labels/RequiredLabel';
import AwsdkError from 'lib/amwell/client/errors/AwsdkError';
import { Color } from 'modules/styles/colors';
import { spacings } from 'modules/utils/StylesUtils';
import { UpdateVisitContextForm, VisitContext } from 'store/amwell/types';
import { MuiBox, MuiGrid } from 'theme/material-ui';

export interface Props {
  visitContext: VisitContext;
  loading: boolean;
  error: AwsdkError | null;
  onVisitContextChange: (updateVisitContextForm: UpdateVisitContextForm) => void;
  isLargeScreen?: boolean;
}

const getHelperText = (err: AwsdkError | null) => {
  return err ? err.recoverySuggestion : null;
};

function ConnectCareTriageCallback(props: Props) {
  const { visitContext, onVisitContextChange, loading, error, isLargeScreen } = props;

  const [callbackNumber, setCallbackNumber] = useState(visitContext.callbackNumber || '');
  const helperText = useMemo(() => getHelperText(error), [error]);

  const onChange = (e: React.ChangeEvent<any>) => {
    setCallbackNumber(e.target.value);
  };

  const onBlur = () => {
    onVisitContextChange({ callbackNumber });
  };

  return (
    <MuiGrid item xs>
      <MuiBox
        bgcolor={Color.foreColor}
        padding={spacings(4, isLargeScreen ? 8 : 3)}
        marginBottom={3}
      >
        <MuiGrid container direction="column" wrap="nowrap">
          <MuiGrid item xs>
            <RequiredLabel labelText="What is the best phone number to reach you by?" />
          </MuiGrid>
          <MuiGrid item>
            <MuiBox width="100%">
              <ConnectCareTextField
                required
                mask="phone"
                placeholder="(###) ###-####"
                value={callbackNumber}
                disabled={loading}
                onChange={onChange}
                onBlur={onBlur}
                error={!!helperText}
                helperText={helperText || ' '}
              />
            </MuiBox>
          </MuiGrid>
        </MuiGrid>
      </MuiBox>
    </MuiGrid>
  );
}

export default ConnectCareTriageCallback;
