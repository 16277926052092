import { TestResultDetails } from 'store/testResults/types';
import { EobDocumentsItem } from 'store/visitSummary/eob/types';

/**
 * Used to convert raw buffer to base64 string
 * @param {ArrayBuffer} data - raw buffer of binary data
 */
export const bufferToBase64 = (data: ArrayBuffer) => {
  const buff = Buffer.from(data);
  return buff.toString('base64');
};

/**
 * Used to change TestResultDetails to have content converted to base64 string from raw buffer
 * @param {object | null} data - TestResultDetails object or null
 */
export const formatTestResultData = (data: TestResultDetails | EobDocumentsItem | null) => {
  return data ? { ...data, content: bufferToBase64(data.content as ArrayBuffer) } : data;
};
