import { useDispatch, useSelector } from 'react-redux';
import { navigationAllBreadCrumbsSelector } from 'store/navigation/selectors';
import { navigationBreadCrumbs } from 'store/navigation/actions.web';
import { BreadcrumbProps } from 'components/Breadcrumbs';

const useBreadCrumbs = () => {
  const dispatch = useDispatch();
  const breadCrumbsHistory = useSelector(navigationAllBreadCrumbsSelector);
  const currentCrumbs = breadCrumbsHistory[0] || [];
  const previousCrumbs = breadCrumbsHistory[1] || [];
  const hasPreviousCrumbs = previousCrumbs.length > 0;
  const lastCrumb = currentCrumbs[1];

  /**
   * Replaces all current breadcrumbs with new ones. They must match the BreadCrumb type object
   * structure. Any customization should be done before passing the new breadcrumbs array.
   *
   * @param breadCrumbs BreadcrumbProps[]
   */
  const updateAllBreadCrumbs = (breadCrumbs: BreadcrumbProps[]) => {
    const firstElement = breadCrumbs[0];
    if (firstElement) {
      dispatch(navigationBreadCrumbs(breadCrumbs));
    }
  };

  /**
   * Pass in the string that you would like the final breadcrumb to be
   * Replaces the last breadcrumb handled by navigation state.
   *
   * @param breadCrumb string
   */
  const updateLastBreadCrumb = (breadCrumb: string) => {
    if (lastCrumb?.label !== breadCrumb) {
      const updatedCrumbs = currentCrumbs.map((crumb, index, arr) => {
        if (index === arr.length - 1) {
          return {
            ...crumb,
            label: breadCrumb
          };
        }
        return crumb;
      });
      dispatch(navigationBreadCrumbs(updatedCrumbs));
    }
  };

  /**
   * Pass in the string that you would like the final breadcrumb to be
   * Replaces a breadcrumb at specific index.
   *
   * @param breadCrumb string
   */
  const updateBreadCrumbAtIndex = (breadCrumb: string, idx: number) => {
    if (lastCrumb?.label !== breadCrumb) {
      const updatedCrumbs = currentCrumbs.map((crumb, index) => {
        if (index === idx) {
          return {
            ...crumb,
            label: breadCrumb
          };
        }
        return crumb;
      });
      dispatch(navigationBreadCrumbs(updatedCrumbs));
    }
  };

  /**
   * This brings the previous breadcrumbs, if any, and add at the
   * end of them the last crumb from the current screen,
   */
  const mergeCurrentWithPreviousBreadCrumbs = () => {
    updateAllBreadCrumbs(hasPreviousCrumbs ? [...previousCrumbs, lastCrumb] : [...currentCrumbs]);
  };

  return {
    updateAllBreadCrumbs,
    updateLastBreadCrumb,
    updateBreadCrumbAtIndex,
    mergeCurrentWithPreviousBreadCrumbs,
    currentCrumbs,
    previousCrumbs,
    hasPreviousCrumbs,
    lastCrumb
  };
};

export default useBreadCrumbs;
