import { useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';

interface Route {
  component: any;
  path: string;
  [x: string]: any;
}

export function useRoutes<T extends Route>(routes: T[]): T[] {
  const match = useRouteMatch();

  return useMemo(() => {
    return routes.map(r => ({
      ...r,
      path: `${match.path}${r.path === '/' ? '' : r.path}`
    }));
  }, [match, routes]);
}
