import React from 'react';

import Avatar from 'components/UI/Avatar/Avatar';
import { BillboardContainer, BillboardTitle } from 'components/UI/Banner/styled';
import { IconSize } from 'modules/styles/variables';
import { ProviderDetail } from 'store/findProvider/types';
import { MuiBox, MuiGrid } from 'theme/material-ui';
import ProviderBannerActions from './ProviderBannerActions';
import ProviderBannerInfo from './ProviderBannerInfo';
import {
  AvatarContainer,
  Circle,
  ProviderDetails,
  SelectHealth,
  StyledMuiContainer
} from './styled';

export interface Props {
  provider: ProviderDetail;
  isAuthenticated: boolean;
  scrollToProviderComments: () => void;
}

export const ProviderDetailsBanner = (props: Props) => {
  const { isAuthenticated, provider } = props;

  const { providerProfileImageUrl, displayName } = props.provider;

  return (
    <BillboardContainer>
      <MuiBox width="100%" m={2}>
        <MuiGrid container spacing={3}>
          <MuiGrid item xs={12}>
            <BillboardTitle>{displayName}</BillboardTitle>
          </MuiGrid>
          <StyledMuiContainer maxWidth="lg">
            <MuiBox display="flex" alignItems="center" justifyContent="center">
              <MuiGrid
                container
                xs={12}
                direction="row"
                justify="center"
                spacing={2}
                alignItems="center"
              >
                <MuiGrid item xs={12} lg={7}>
                  <ProviderDetails>
                    <MuiGrid item justify="center" xs={12}>
                      <AvatarContainer providerProfileImageUrl={!!providerProfileImageUrl}>
                        <Avatar size="giga" imageUrl={providerProfileImageUrl} />
                        {provider.isSelectHealth && (
                          <Circle>
                            <SelectHealth name="SelectHealth" size={IconSize.large} />
                          </Circle>
                        )}
                      </AvatarContainer>
                    </MuiGrid>
                    <MuiGrid item xs={12}>
                      <ProviderBannerInfo
                        provider={provider}
                        iconSize={IconSize.small}
                        scrollToProviderComments={props.scrollToProviderComments}
                      />
                    </MuiGrid>
                  </ProviderDetails>
                </MuiGrid>
                <MuiGrid item xs={12} lg={5} justify="center">
                  <ProviderBannerActions isAuthenticated={isAuthenticated} provider={provider} />
                </MuiGrid>
              </MuiGrid>
            </MuiBox>
          </StyledMuiContainer>
        </MuiGrid>
      </MuiBox>
    </BillboardContainer>
  );
};
