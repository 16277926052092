import React from 'react';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import { Color } from 'modules/styles/colors';
import { SnackProps } from './Snack';
import { AlertStyled } from './styled';

const iconMap = {
  success: <CheckCircleIcon />,
  error: <ErrorIcon />
};

const colorMap = {
  success: Color.darkGreenShade,
  error: Color.alertError,
  warning: Color.darkOrangeShade,
  info: Color.tertiary
};

const SnackAlert = ({ id, message, severity, onClose, options }: SnackProps) => (
  <AlertStyled
    id={id}
    severity={severity}
    onClose={onClose ? () => onClose() : undefined}
    action={options?.action}
    icon={options?.icon}
    iconMapping={options?.iconMapping || iconMap}
    variant={options?.variant || 'filled'}
    backgroundColor={colorMap[severity || 'info']}
  >
    {message}
  </AlertStyled>
);

export default SnackAlert;
