import Spinner from 'components/UI/Spinner/Spinner';
import Svg from 'components/UI/Svg/Svg';
import ListActionsRow from 'components/common/ListActionsRow/ListActionsRow';
import ResultList from 'components/testResults/ResultList';
import dayjs from 'dayjs';
import useNavigationAnalytics from 'hooks/useNavigationAnalytics';
import { DAYJS_FORMAT, ISO_8601_DATE } from 'lib/constants';
import { HEALTH_RECORDS_PAGES } from 'lib/constants/healthRecords';
import { isNada } from 'modules/utils/ArrayUtils';
import React, { useEffect, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { AnalyticsEvent } from 'services/AnalyticsService';
import Logger from 'services/Logger';
import { Color } from 'modules/styles/colors';
import { IconSize } from 'modules/styles/variables';
import { mergeArrayObjectsBy } from 'store/myDoctors/utils';
import { previousLocationPathNameSelector } from 'store/router/selectors';
import {
  getMoreTestResults,
  getTestResultsList,
  updateFilterOptions
} from 'store/testResults/actions';
import { INIT_YEARS_TO_QUERY, MAX_YEARS_TO_QUERY } from 'store/testResults/constants';
import {
  testResultListFilterDateRangeSelector,
  testResultsListDataSelector,
  testResultsListErrorSelector,
  testResultsListFilterOptionsSelector,
  testResultsListFiltersSelector,
  testResultsListIsFetchingSelector,
  testResultsListSortSelector
} from 'store/testResults/selectors';
import {
  DateRangeFilterParams,
  SortByMap,
  SortByValues,
  TestResult,
  TestResultsFilter
} from 'store/testResults/types';
import { getFilterOptions } from 'store/testResults/utils';
import { RootState } from 'store/types';
import { MuiBox, MuiButton, MuiContainer, MuiTypography } from 'theme/material-ui';

const MAX_RETRY_ATTEMPTS = 3;

interface Props {
  referringUrl: string;
  testResults: TestResult[];
  error?: Error | null;
  loading: boolean;
  sortBy: SortByValues;
  filters: TestResultsFilter[];
  filterOptions: TestResultsFilter[];
  dateRangeFilters: DateRangeFilterParams;
}

export const TestResultsHealthRecordsList = ({
  testResults,
  loading,
  error,
  sortBy,
  filters,
  referringUrl,
  filterOptions,
  dateRangeFilters
}: Props) => {
  const [yearsToQuery, setYearsToQuery] = useState(INIT_YEARS_TO_QUERY);
  const [startDate, setStartDate] = useState(
    dayjs()
      .subtract(INIT_YEARS_TO_QUERY, 'y')
      .format(ISO_8601_DATE)
  );
  const [previousListCount, setPreviousListCount] = useState(-1);
  const [filterOpen, setFilterOpen] = useState(false);
  const [sortByValue, setSortByValue] = useState<SortByValues>(sortBy);
  const [sortOpen, setSortOpen] = useState(false);
  const [hasSorted, setHasSorted] = useState(false);
  const [filtersApplied, setFiltersApplied] = useState(false);
  const [filterOptionsValue, setFilterOptionsValue] = useState(filterOptions);
  const [dateRangeValue, setDateRangeValue] = useState<DateRangeFilterParams>(dateRangeFilters);
  const analyticsTrackError = useNavigationAnalytics(AnalyticsEvent.ErrorTracking, {
    action: 'GetMedicalTestResultList',
    errorCode: error?.networkError?.statusCode || '',
    errorMessage: error?.message || ''
  });
  const showMoreClickedAnalytics = useNavigationAnalytics(
    AnalyticsEvent.ShowMoreTestResultsClicked
  );
  const dispatch = useDispatch();

  const noNewText = `No new results found searching ${yearsToQuery} years`;
  const currentListCount = testResults.length;

  useEffect(() => {
    // deactivate filter button if no filters or date has been set
    if (filters?.length === 0 && dateRangeFilters.year === '') {
      setFiltersApplied(false);
    }
  }, [filters, dateRangeFilters]);

  useEffect(() => {
    // deactivate filter and sort if previous url wasn't within test-results stack
    if (!referringUrl?.includes('test-results')) {
      setFiltersApplied(false);
      setHasSorted(false);
    } else {
      // set filter button active if filters are applied
      if (filters?.length > 0 || dateRangeFilters.year !== '') {
        setFiltersApplied(true);
      }
      // set sort button active if sort has been changed from default
      if (sortBy !== SortByValues.desc) {
        setHasSorted(true);
      }
    }
  }, []);

  const refetch = async (useCache = true) => {
    const start = dayjs(startDate).format(ISO_8601_DATE);
    dispatch(getTestResultsList(start, dayjs().format(ISO_8601_DATE), useCache));
  };

  useEffect(() => {
    refetch(previousListCount === -1);
  }, []);

  const handlePress = () => {
    showMoreClickedAnalytics();
    setPreviousListCount(currentListCount);
    const newYearsToQuery = yearsToQuery + 5;
    setYearsToQuery(newYearsToQuery);
    const newStartDate = dayjs()
      .subtract(newYearsToQuery, 'year')
      .format(ISO_8601_DATE);
    setStartDate(newStartDate);
    setDateRangeValue({
      year: '',
      startDate: dayjs().format(DAYJS_FORMAT),
      endDate: dayjs().format(DAYJS_FORMAT)
    });
    dispatch(getMoreTestResults(newStartDate, dayjs().format(ISO_8601_DATE)));
  };

  const setFilterOptions = () => {
    const options = getFilterOptions(testResults);
    setFilterOptionsValue(options);
    dispatch(updateFilterOptions(options));
  };

  const updateByDateChange = async () => {
    const filterStart = dayjs(dateRangeFilters?.startDate).format(ISO_8601_DATE);
    if (!!filterStart && filterStart < startDate) {
      const res = await dispatch(
        getTestResultsList(filterStart, dayjs().format(ISO_8601_DATE), false)
      );
      const options = getFilterOptions(res?.payload?.data);
      if (options?.length < filterOptionsValue?.length) {
        const filteredOptions = filterOptionsValue.filter(filterOption => {
          return options.some(option => {
            return option.value === filterOption.value;
          });
        });
        setFilterOptionsValue(filteredOptions);
        dispatch(updateFilterOptions(filteredOptions));
      } else {
        const concatenatedOptions = [...options, ...filterOptionsValue];
        const mergedOptions = mergeArrayObjectsBy(concatenatedOptions, 'label');
        setFilterOptionsValue(mergedOptions);
        dispatch(updateFilterOptions(mergedOptions));
      }
    }
  };

  useEffect(() => {
    updateByDateChange();
  }, [dateRangeFilters?.startDate]);

  useEffect(() => {
    // Set filter options on first render only if they are empty
    // This prevents filter options from being reset when navigating
    // back from a test results details page
    if (isNada(filterOptions)) {
      setFilterOptions();
    }
  }, []);

  useEffect(() => {
    // This insures that filter options will be set upon page refresh
    // but only when test results have loaded
    if (testResults.length > 0) {
      if (isNada(filterOptions)) {
        setFilterOptions();
      }
    }
  }, [testResults]);

  useEffect(() => {
    // Set filterOptions according to available test result categories
    if (filterOptions?.length === 0 && testResults?.length > 0) {
      setFilterOptions();
    }
    // If redux filter options have more options, update local filter options
    if (filterOptions?.length > filterOptionsValue?.length) {
      const concatenatedOptions = [...filterOptions, ...filterOptionsValue];
      const mergedOptions = mergeArrayObjectsBy(concatenatedOptions, 'label');
      setFilterOptionsValue(mergedOptions);
      dispatch(updateFilterOptions(mergedOptions));
    }
  }, [filterOptions]);

  const getPlaceHolder = () => {
    let message: string | undefined;
    if (loading) {
      message = HEALTH_RECORDS_PAGES.TestResults.loading;
    } else if (error) {
      message = HEALTH_RECORDS_PAGES.TestResults.error;
    } else if (testResults?.length === 0) {
      message = HEALTH_RECORDS_PAGES.getEmptyState('test results');
    }
    return (
      <MuiBox pt={3} width="100%">
        <MuiTypography align="center">{message}</MuiTypography>
        {loading && !testResults?.length ? (
          <MuiBox p={3} display="flex" flexDirection="row" justifyContent="center">
            <Spinner />
          </MuiBox>
        ) : null}
      </MuiBox>
    );
  };

  // Auto retry in event of error when deep linking to this screen
  const retryRef = useRef(0);
  useEffect(() => {
    if (error && retryRef.current < MAX_RETRY_ATTEMPTS) {
      // One second time out in the event of Apollo race conditions when deep linking
      setTimeout(() => {
        Logger.error('Medical test results list request retry attempt', retryRef.current, error);
        refetch();
        retryRef.current += 1;
      }, 1000);
    }
    if (error && retryRef.current === MAX_RETRY_ATTEMPTS) {
      analyticsTrackError();
    }
  }, [error]);

  // if (loading || error || testResults.length === 0) {
  //   return getPlaceHolder();
  // }

  return (
    <>
      <MuiBox alignContent="center" bgcolor={Color.baseColor}>
        <ListActionsRow
          justifyContent="flex-end"
          sortTitleEndText={` by: ${SortByMap[sortBy] || 'New'}`}
          filterActive={filtersApplied}
          sortActive={hasSorted}
          onFilterPress={() => setFilterOpen(true)}
          onSortPress={() => setSortOpen(true)}
        />

        {getPlaceHolder()}
        {loading && !testResults?.length ? null : (
          <MuiContainer maxWidth="lg">
            <MuiBox my={3}>
              <ResultList
                dateRangeFilters={dateRangeFilters}
                dateRangeValue={dateRangeValue}
                setDateRangeValue={setDateRangeValue}
                filters={filters}
                filterOptions={filterOptionsValue}
                setFilterOptions={setFilterOptionsValue}
                filterOpen={filterOpen}
                referringUrl={referringUrl}
                setFilterOpen={setFilterOpen}
                setFiltersApplied={setFiltersApplied}
                setHasSorted={setHasSorted}
                setSortOpen={setSortOpen}
                sortBy={sortBy}
                sortByValue={sortByValue}
                setSortByValue={setSortByValue}
                sortOpen={sortOpen}
                testResults={testResults}
                yearsToQuery={yearsToQuery}
              />
              {!!dateRangeFilters.year ? null : (
                <>
                  {!loading && previousListCount === currentListCount ? (
                    <MuiContainer maxWidth="md">
                      <MuiBox p={2} textAlign="center">
                        {noNewText}
                      </MuiBox>
                    </MuiContainer>
                  ) : null}
                  {loading ? (
                    <MuiBox p={3} display="flex" flexDirection="row" justifyContent="center">
                      <Spinner />
                    </MuiBox>
                  ) : null}
                  <MuiBox display="flex" justifyContent="center" m={2}>
                    <MuiButton
                      disabled={loading || yearsToQuery === MAX_YEARS_TO_QUERY}
                      onClick={() => handlePress()}
                      color="primary"
                      variant="outlined"
                      aria-label="show-more"
                      data-testid="show-more-button"
                      endIcon={
                        <Svg
                          size={IconSize.xmedium}
                          color={
                            loading || yearsToQuery === MAX_YEARS_TO_QUERY
                              ? Color.disabledColor
                              : Color.secondary
                          }
                          set="assets"
                          name="ChevronDown"
                        />
                      }
                    >
                      Show more
                    </MuiButton>
                  </MuiBox>
                </>
              )}
            </MuiBox>
          </MuiContainer>
        )}
      </MuiBox>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  testResults: testResultsListDataSelector(state),
  referringUrl: previousLocationPathNameSelector(state),
  error: testResultsListErrorSelector(state),
  loading: testResultsListIsFetchingSelector(state),
  sortBy: testResultsListSortSelector(state),
  filters: testResultsListFiltersSelector(state),
  filterOptions: testResultsListFilterOptionsSelector(state),
  dateRangeFilters: testResultListFilterDateRangeSelector(state)
});

export default connect(mapStateToProps)(TestResultsHealthRecordsList);
