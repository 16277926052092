export const formInstructions = `Please fill out this form with details as accurately as you can to enable your
doctor to close out this advisory.`;

export const successSubmit = 'Your information has been submitted to your doctor for review.';
export const noteToRead =
  'Note that this advisory will continue to show in your Health Record until your physician updates it in the system.';

export const failSubmit = 'Unable to close health advisory\nPlease contact your PCP';
export const providerPoolSelect = 'Select Provider';
export const formTitles = {
  providerPool: 'Provider',
  what: 'What was the outcome of your results?',
  who: 'Who performed this?',
  where: 'Where was this performed?',
  when: 'When was this performed?'
};

export const HealthAdvisoryDisplay = {
  ANNUAL_WELLNESS_VISIT: 'Annual Wellness Visit',
  DIABETES_A1C: 'Diabetes A1C',
  CERVICAL_CANCER_SCREENING: 'Cervical Cancer Screening',
  MAMMOGRAM: 'Mammogram',
  FLU_SHOT: 'Influenza (flu) Vaccine'
};

export const submitHelperText =
  'To complete this process please take or submit your results to your doctor.';
export const readMessage = 'I read the message above';
