import styled from 'styled-components';
import { Color } from 'modules/styles/colors';
import { DfdError, ErrorLevel } from 'store/error/types';
import { isMS } from 'lib/browser';
import { ZIndex } from 'modules/styles/variables';

const { white, black, red, warning, yellow } = Color;

// ErrorMessages needs to be above the footer
export const ErrorMessages = styled.div`
  display: block;
  position: fixed;
  width: 100%;
  bottom: 1rem;
  left: 0px;
  z-index: ${ZIndex.high};
`;

export const ErrorTitle = styled.span`
  font-weight: bold;
  display: inline;
  text-transform: capitalize;
`;

export const ErrorMessage = styled.div.attrs((props: { error: DfdError }) => {
  const { error } = props;
  let color: string = white;
  let background: string = black;

  if (error.level === ErrorLevel.error) {
    background = red;
  } else if (error.level === ErrorLevel.warn) {
    background = warning;
    color = black;
  } else if (error.level === ErrorLevel.info) {
    color = black;
    background = yellow;
  }
  return {
    error,
    color,
    background
  };
})`
  display: block;
  position: relative;
  margin: 0.25rem;
  color: ${props => props.color};
  padding: 0.5rem;
  border: 1px solid ${white};
  background: ${props => props.background};
  padding-right: 3rem;
`;

export const ErrorText = styled.div`
  display: inline;
`;
export const Close = styled.div``;

export const CloseButWrapper = styled.div`
  position: absolute;
  display: block;
  top: 50%;
  right: 0.5rem;
  transform: translateY(${isMS() ? '-50%' : 'calc(-50% + 1px)'});
  cursor: pointer;
`;
