import React from 'react';
import { MuiTypography } from 'theme/material-ui';
import {
  splitTextIntoLinesArray,
  linkRegex,
  parseLineIntoTextPartsObject
} from 'lib/messaging/rtf/parseHyperlink';
import { tableRegex } from 'lib/messaging/rtf/constants';
import { MessageDetail } from 'store/messaging/types';
import Table from './rtfTable';
import { generateLinkComponent } from './linkComponent';

export const generateMessageTextComponents = (text: string, isRtf: boolean) => {
  const lines = splitTextIntoLinesArray(text, isRtf);
  return lines?.map((line: string) => {
    if (tableRegex.test(line)) {
      return <Table line={line} />;
    }
    if (linkRegex.test(line)) {
      const lineParts = parseLineIntoTextPartsObject(line);
      const components = lineParts?.map(({ link, before, after }) => {
        return generateLinkComponent(link, before, after);
      });
      return components;
    }
    return <MuiTypography key={Math.random()}>{line}</MuiTypography>;
  });
};

// Try using plaintext -> fallback to text -> fallback to No Content
export const getMessageText = (message: MessageDetail, shouldUseRTFConversion: boolean) => {
  if (shouldUseRTFConversion) {
    if (message?.texts?.length) {
      const messageText: string[] = message.texts.map(item => {
        const isRtf = item.formatType === 'RTF' && !!item.text;
        const text = isRtf ? item.text : item.plainText || item.text || '(No Content)';
        return generateMessageTextComponents(text, isRtf);
      });
      return messageText;
    }
    return <MuiTypography>(No Content)</MuiTypography>;
  }
  if (message?.texts?.length) {
    const messageText: string[] = message.texts.map(
      item => item.plainText || item.text || '(No Content)'
    );
    return messageText;
  }
  return '(No Content)';
};

export default getMessageText;
