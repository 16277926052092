import React from 'react';
import styled from 'styled-components';
import FlexBox from 'components/UI/Layout/FlexBox';

const StyledFlexBox = styled(FlexBox)`
  flex-direction: row;
  align-items: center;
  color: white;
  border: 1px solid white;
  padding: 5px 10px;
  margin-left: auto;
`;

const StyledLabel = styled.span`
  display: flex;
  align-items: center;
  font-size: 12px;
`;

const StyledStrong = styled.strong`
  font-size: 15px;
  line-height: 1em;
  margin-left: 5px;
  margin-bottom: 1px;
`;

interface BloodTypeBadgeProps {
  bloodType?: string;
}

const BloodTypeBadge = ({ bloodType = 'Unavailable' }: BloodTypeBadgeProps) => (
  <StyledFlexBox data-testid="blood-type-badge">
    <StyledLabel>Blood Type:</StyledLabel>
    <StyledStrong>{bloodType}</StyledStrong>
  </StyledFlexBox>
);

export default BloodTypeBadge;
