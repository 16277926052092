import React from 'react';
import { connect } from 'react-redux';
import Container from '@material-ui/core/Container';

import { AuthType } from 'store/authentication/types';
import * as covidActions from 'store/CovidScreening/actions';
import { CovidScreeningQuestionnaireSelector } from 'store/CovidScreening/selectors';
import { Questionnaire } from 'store/CovidScreening/types';
import { RootState } from 'store/types';
import { CovidTitles } from 'lib/constants/covidScreening';
import { AnalyticsEvent } from 'services/AnalyticsService';
import { InsuranceInformationData } from 'modules/constants/covidScreening/insuranceFound';

import { NavigationScreenProps } from 'screens/navigation';
import Spacer from 'components/UI/Layout/Spacer';
import CovidTestInsuranceForm from 'components/CovidTest/insurance/form';
import useNavigationAnalytics from 'hooks/useNavigationAnalytics';

import CovidGuestGuard from '../navigation/CovidGuestLeavingGuard';

export interface CovidTestInsuranceProps extends NavigationScreenProps {
  questionnaire: Questionnaire;
  setQuestionnaire: typeof covidActions.setQuestionnaire;
}

export function CovidTestInsurance(props: CovidTestInsuranceProps) {
  const initialValues = {
    GROUP_NUMBER: props.questionnaire?.GROUP_NUMBER ?? '',
    MEMBER_NUMBER: props.questionnaire?.MEMBER_NUMBER ?? '',
    Q_Patient_relationship_to_subscriber:
      props.questionnaire?.Q_Patient_relationship_to_subscriber ?? '',
    SUBSCRIBER_FIRST_NAME_ON_CARD: props.questionnaire?.SUBSCRIBER_FIRST_NAME_ON_CARD ?? '',
    SUBSCRIBER_LAST_NAME_ON_CARD: props.questionnaire?.SUBSCRIBER_LAST_NAME_ON_CARD ?? '',
    SUBSCRIBER_MIDDLE_NAME_ON_CARD: props.questionnaire?.SUBSCRIBER_MIDDLE_NAME_ON_CARD ?? ''
  };

  const saveValues = (values: InsuranceInformationData) => {
    const newQuestionnaire = {
      ...props.questionnaire,
      ...values
    };

    props.setQuestionnaire(newQuestionnaire);
  };

  const covidScreeningNextBtn = useNavigationAnalytics(AnalyticsEvent.CovidNext, {
    title: CovidTitles.EXISTING_INSURANCE_FOUND,
    authentication_type: AuthType.UNAUTHENTICATED
  });

  const covidScreeningPreviousBtn = useNavigationAnalytics(AnalyticsEvent.CovidPrev, {
    title: CovidTitles.EXISTING_INSURANCE_FOUND,
    authentication_type: AuthType.UNAUTHENTICATED
  });

  const saveToStore = (values: InsuranceInformationData) => {
    saveValues(values);
    covidScreeningNextBtn();
  };

  const onPreviousClicked = (values: InsuranceInformationData) => {
    saveValues(values);
    covidScreeningPreviousBtn();
    props.history.goBack();
  };

  return (
    <>
      <Container maxWidth="md" data-testID="covid-new-insurance">
        <Spacer size="small" />
        <CovidTestInsuranceForm
          initialValues={initialValues}
          onPreviousClicked={onPreviousClicked}
          saveDataForm={saveToStore}
        />
      </Container>
      <CovidGuestGuard />
    </>
  );
}

const mapStateToProps = (state: RootState) => ({
  questionnaire: CovidScreeningQuestionnaireSelector(state)
});

const mapDispatchToProps = {
  setQuestionnaire: covidActions.setQuestionnaire
};

export default connect(mapStateToProps, mapDispatchToProps)(CovidTestInsurance);
