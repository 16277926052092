import AbsoluteSpinner from 'components/common/AbsoluteSpinner/AbsoluteSpinner';
import CollapsibleListItem from 'components/common/CollapsibleList/CollapsibleListItem';
import FlexBox from 'components/UI/Layout/FlexBox';
import Svg from 'components/UI/Svg/Svg';
import Avatar from 'components/UI/Avatar/Avatar';
import { IconSize, Spacing, FontSize } from 'modules/styles/variables';
import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { AnalyticsEvent } from 'services/AnalyticsService';
import { MY_DOCS } from 'store/booking/constants';
import { searchUrl } from 'store/findProvider/constants';
import { ProviderSummary } from 'store/findProvider/types';
import * as myDoctorsSelectors from 'store/myDoctors/selectors';
import { RootState } from 'store/types';
import { MuiBox, MuiButton, MuiPaper, MuiTypography } from 'theme/material-ui';
import {
  AvatarContainer,
  IconsContainer,
  SelectHealthIconContainer,
  StyledLocationName,
  StyledMuiBoxContainer,
  StyledProviderName
} from './styled';
import { getMyDoctors } from 'store/myDoctors/actions';
import { setDoctor } from 'store/booking/actions.common';

interface GetCareMyDoctorsListProps {
  isFetching: boolean;
  providers: ProviderSummary[];
}

export const GetCareMyDoctorsList = ({ isFetching, providers }: GetCareMyDoctorsListProps) => {
  const history = useHistory();
  const location = useLocation();
  const isExpanded = location.state?.isExpanded === MY_DOCS;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMyDoctors());
  }, []);

  const onViewProviderDetail = (provider: ProviderSummary) => {
    dispatch(setDoctor(provider));
    history.push('/u/provider/kyruus/details');
  };

  const handleSearch = () => {
    history.push(searchUrl, { persistFilters: true });
  };

  return (
    <CollapsibleListItem
      leftIcon="MyDoctors"
      title="My Doctors"
      expandOnFirstRender={isExpanded || false}
      subtitle="Your PCP and your care team"
      analyticsEvent={AnalyticsEvent.MyDoctorsClicked}
      sourceUrlForAnalytics="Get Care"
    >
      <MuiBox width="100%">
        <MuiPaper elevation={1}>
          {isFetching ? (
            <FlexBox flex={1} alignItems="center" justifyContent="center">
              <AbsoluteSpinner />
            </FlexBox>
          ) : (
            <>
              {providers.map(provider => (
                <StyledMuiBoxContainer
                  button
                  key={provider.corpProvId}
                  onClick={() => onViewProviderDetail(provider)}
                >
                  <IconsContainer>
                    <AvatarContainer>
                      {/*
                        @NOTE: providerProfileImageUrls fail to load if you are connected to the /engr
                        VPN. If you're curious about an issue related to these images not rendering here,
                        try disconnecting from your VPN and reloading.
                      */}
                      <Avatar size="small" imageUrl={provider.providerProfileImageUrl} />
                    </AvatarContainer>
                    {provider.isSelectHealth && (
                      <SelectHealthIconContainer>
                        <Svg name="SelectHealth" size={IconSize.small} />
                      </SelectHealthIconContainer>
                    )}
                  </IconsContainer>
                  <StyledProviderName color="textPrimary" fontSize={FontSize.base}>
                    {provider.displayName}
                  </StyledProviderName>
                  {provider.primaryLocation ? (
                    <StyledLocationName color="textSecondary" fontSize={FontSize.base}>
                      {provider.primaryLocation.name}
                    </StyledLocationName>
                  ) : null}
                  <MuiBox flex={1} flexDirection="row" justifyContent="flex-end" display="flex">
                    <Svg name="ChevronRight" size={IconSize.small} />
                  </MuiBox>
                </StyledMuiBoxContainer>
              ))}
            </>
          )}
          <MuiBox flex={1} justifyContent="center" display="flex" py={2}>
            <FlexBox alignItems="center">
              <MuiTypography style={{ paddingBottom: Spacing.medium }} fontSize={FontSize.large}>
                Looking for another provider?
              </MuiTypography>
              <MuiButton
                data-testid="find-a-provider"
                variant="contained"
                color="primary"
                onClick={handleSearch}
                fullWidth
              >
                Find a provider
              </MuiButton>
            </FlexBox>
          </MuiBox>
        </MuiPaper>
      </MuiBox>
    </CollapsibleListItem>
  );
};

const mapStateToProps = (state: RootState) => ({
  isFetching: myDoctorsSelectors.myDoctorFetchingSelector(state),
  providers: myDoctorsSelectors.currentConsumerAllDoctorsSelector(state)
});

export default connect(mapStateToProps)(GetCareMyDoctorsList);
