import React from 'react';
import styled from 'styled-components';
import { ZIndex } from 'modules/styles/variables';
import { Color } from 'modules/styles/colors';
import Spinner from './Spinner';

interface Props {
  backgroundColor?: string;
  opacity?: number;
}

export interface SpinnerProps extends Props {
  accessibilityLabel?: string;
  display?: string;
  ZIndex?: number;
  testID?: string;
}

export const CenteredSpinnerContainer = styled.div<Props>`
  position: absolute;
  ${props => (props.backgroundColor ? `background-color: ${props.backgroundColor};` : '')};
  ${props => (props.opacity ? `opacity: ${props.opacity};` : '')}
  top: 50%;
  left: 50%;
  z-index: ${ZIndex.spinner};
`;

export const SpinnerContainer = styled.div<SpinnerProps>`
  background-color: ${props => props.backgroundColor || Color.foreColor};
  position: absolute;
  ${props => (props.display ? `display: ${props.display};` : '')}
  ${props => (props.opacity ? `opacity: ${props.opacity};` : '')}
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
  z-index: ${props => props.ZIndex || ZIndex.spinner};
`;

export function CenteredSpinner(props: Props) {
  return (
    <SpinnerContainer {...props}>
      <CenteredSpinnerContainer>
        <Spinner />
      </CenteredSpinnerContainer>
    </SpinnerContainer>
  );
}
