import { useState, useRef } from 'react';

export function useRefresh<T extends any[]>(callback: (...args: T) => void) {
  const [isLoading, setIsLoading] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const callCount = useRef(0);

  const refreshableCallback = async (...args: T) => {
    const setterFn = callCount.current > 0 ? setIsRefreshing : setIsLoading;
    callCount.current += 1;

    setterFn(true);

    await callback(...args);

    setterFn(false);
  };

  const reset = () => {
    callCount.current = 0;
  };

  return {
    load: refreshableCallback,
    loading: isLoading,
    refreshing: isRefreshing,
    reset
  } as const;
}
