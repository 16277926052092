import IconButton from '@material-ui/core/IconButton';
import Typography from 'components/UI/Typography';
import DefaultText from 'components/UI/Typography/DefaultText';
import Headline from 'components/UI/Typography/Headline';
import { Color } from 'modules/styles/colors';
import {
  baseFontSize,
  FontFamily,
  FontSize,
  FontWeight,
  headingFontSize,
  largeFontSize,
  Spacing,
  titleFontSize
} from 'modules/styles/variables';
import styled from 'styled-components';
import { MuiBox, MuiGrid, MuiListItem, MuiSelect } from 'theme/material-ui';

export const StyledTypography = styled(Typography)`
  padding-left: ${Spacing.large};
  height: 29px;
  color: ${Color.grayHue3};
  font-family: ${FontFamily.primary};
  font-size: ${headingFontSize};
`;

export const StyledDirectionsText = styled(Typography)`
  color: ${Color.black};
  font-family: ${FontFamily.primary};
  font-size: ${FontSize.base};
  letter-spacing: 0.25px;
  line-height: 20px;
  padding-bottom: 8%;
`;

export const Directions = styled(Typography)`
  color: ${Color.black};
  font-family: ${FontFamily.primary};
  font-size: ${FontSize.base};
  font-weight: 600;
  letter-spacing: 0.25px;
  line-height: 20px;
  padding-bottom: 2%;
`;

export const StyledSortDropdown = styled(MuiGrid)`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const StyledMuiListItem = styled(MuiListItem)`
  min-height: 4em;

  &:not(:last-child) {
    border-bottom: 1px solid ${Color.grayLight};
  }
`;

export const StyledMuiSelect = styled(MuiSelect)`
  width: 200px;
  > .MuiSelect-select.MuiSelect-select {
    padding-right: 0px;
  }
`;
export const StyledMuiBox = styled(MuiBox)`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 0 0 50%;
`;

export const DateRangeHeading = styled(Directions)``;

export const DefaultHeading = styled(Directions)`
  font-weight: ${FontWeight.bold};
  font-size: ${FontSize.heading}px;
`;

export const DateRangeText = styled(Typography)`
  color: ${Color.black};
  font-family: ${FontFamily.primary};
  font-size: ${FontSize.base};
  letter-spacing: 0.25px;
  line-height: 20px;
  padding-bottom: 8%;
`;

export const PrescriberText = styled(Typography)`
  color: ${Color.black};
  font-family: ${FontFamily.primary};
  font-size: ${FontSize.base};
  letter-spacing: 0.25px;
  line-height: 20px;
  padding-bottom: 8%;
`;

export const CloseButton = styled(IconButton)`
  background: ${Color.translucent};
  margin-left: 90%;
  &:hover {
    background: ${Color.translucent};
  }
  &:hover {
    background: ${Color.translucent};
  }
`;

export const StyledHeadline = styled(Headline)`
  flex: 1;
  margin-left: 5px;
  padding-right: 10px;
  font-size: ${titleFontSize};
  line-height: ${largeFontSize * 2};
  font-weight: normal;
`;

export const StyledDefaultText = styled(DefaultText)`
  font-size: ${baseFontSize};
`;

export const StyledText = styled(DefaultText)`
  font-size: ${headingFontSize};
  line-height: ${titleFontSize};
  text-align: center;
`;

export const StyledMessage = styled(DefaultText)`
  font-size: ${baseFontSize};
  line-height: ${headingFontSize};
  text-align: center;
`;

export const ErrorText = styled(DefaultText)`
  font-size: ${largeFontSize};
  line-height: ${headingFontSize};
  text-align: center;
`;
