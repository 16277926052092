import Spinner from 'components/UI/Spinner/Spinner';
import React from 'react';
import { MuiBox, MuiTypography } from 'theme/material-ui';

export const FacilitiesLoading = () => (
  <MuiBox p={5} display="flex" alignItems="center" justifyContent="center">
    <Spinner />
  </MuiBox>
);

export const FacilitiesNoData = () => (
  <MuiBox p={5} display="flex" alignItems="center" justifyContent="center">
    <MuiTypography>No facilities found</MuiTypography>
  </MuiBox>
);

export const FacilitiesError = () => (
  <MuiBox p={5} display="flex" alignItems="center" justifyContent="center">
    <MuiTypography>There was an issue loading these facilities</MuiTypography>
  </MuiBox>
);
