import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography
} from '@material-ui/core';
import { MuiButton } from 'theme/material-ui';
import { CountDownTimer } from 'components/Timer/CountDownTimer';

export interface ReactInactivityWarningProps {
  duration: number;
  onLogout: () => void;
  onTimeout: () => void;
  onResume: () => void;
}

export function ReactInactivityWarning({
  duration,
  onLogout,
  onTimeout,
  onResume
}: ReactInactivityWarningProps) {
  return (
    <Dialog
      open
      disableBackdropClick
      disableEscapeKeyDown
      onClose={onResume}
      aria-labelledby="inactivity-dialog-title"
      aria-describedby="inactivity-dialog-description"
    >
      <DialogTitle id="inactivity-dialog-title">Your session is about to end.</DialogTitle>

      <DialogContent>
        <Typography variant="h2" color="primary" gutterBottom align="center">
          {CountDownTimer({ duration, onTimeout })}
        </Typography>
      </DialogContent>

      <DialogContent>
        <DialogContentText color="textPrimary" id="inactivity-dialog-description" component="span">
          Please click "Continue" to stay logged in or click "Log Out" to end your session now.
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <MuiButton onClick={onLogout} color="primary" variant="outlined">
          Log out
        </MuiButton>
        <MuiButton onClick={onResume} color="primary" variant="contained">
          Continue
        </MuiButton>
      </DialogActions>
    </Dialog>
  );
}
