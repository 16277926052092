import styled from 'styled-components';
import { Spacing, BorderRadius } from 'modules/styles/variables';
import { Row } from 'components/UI/Layout/Container';
import { Color } from 'modules/styles/colors';
import DefaultText from 'components/UI/Typography/DefaultText';
import { MuiBox } from 'theme/material-ui';

export const StatusContainer = styled.div`
  flex-direction: row;
  align-items: flex-start;
  align-self: flex-start;
`;

export const StyledRow = styled(Row)`
  padding: ${Spacing.large}px ${Spacing.medium}px;
  border-top-width: 0.5px;
  border-top-color: ${Color.gray};
  width: 100%;
  justify-content: space-between;
`;
export const BPStyledRow = styled(Row)`
  padding: ${Spacing.large}px ${Spacing.medium}px;
  border-top-width: 0.5px;
  border-top-color: ${Color.gray};
  width: 100%;
  justify-content: space-between;
`;
export const VDivider = styled.div`
  height: 100%;
  width: 1px;
  background-color: ${Color.grayLight};
  align-items: center;
  justify-content: center;
  margin-right: ${Spacing.medium}px;
`;

export const Dot = styled.div<{ color?: string }>`
  height: 10px;
  width: 10px;
  background-color: ${props => props.color || Color.gray};
  border-radius: ${BorderRadius.full}px;
  margin-right: ${Spacing.small}px;
`;

export const BoldDefaultText = styled(DefaultText)`
  font-weight: bold;
`;

export const CenterAlign = styled.div`
  align-items: center;
`;

export const LeftAlignContainer = styled.div`
  justify-content: flex-start;
  flex-direction: row;
  flex: 1;
`;

export const CenterAlignContainer = styled.div`
  justify-content: center;
  flex-direction: row;
  flex: 2;
`;

export const RightAlignContainer = styled.div`
  justify-content: flex-end;
  flex-direction: row;
  flex: 1;
`;

export const StyledMuiBox = styled(MuiBox)`
  background-color: ${Color.white};
  padding-top: 40px;
  padding-bottom: 40px;
  margin-top: 0;
  margin-bottom: 0;
`;

export const TableWrapper = styled.div`
  background-color: ${Color.white};
  width: 100%;
`;
