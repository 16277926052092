import React from 'react';

import { Svg } from 'components/UI/Svg';
import SpacedSpinner from 'components/UI/Spinner/SpacedSpinner';
import { MuiBox, MuiButton, MuiTypography } from 'theme/material-ui';

import { Color } from 'modules/styles/colors';
import { BorderRadius, FontSize, FontWeight, IconSize } from 'modules/styles/variables';

import { StyledLIDisc, SpecialtyLICircle, StyledLIDecimal } from './styled';

export const stepActionsHeight = 100;

export const AppointmentsLoading = ({ testId }: { testId: string }) => (
  <MuiBox p={3} display="flex" alignItems="center" justifyContent="center" data-testid={testId}>
    <SpacedSpinner />
  </MuiBox>
);

export const BookingLoading = ({ message = 'Loading...' }) => (
  <MuiBox p={5} display="flex" alignItems="center" justifyContent="center">
    <MuiTypography>{message}</MuiTypography>
  </MuiBox>
);

export const BookingNoData = ({ message = 'No data found.' }: { message?: string }) => (
  <MuiBox p={5} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
    <MuiTypography>{message}</MuiTypography>
  </MuiBox>
);

export const BookingError = ({
  message = 'Uh oh! There seems to be an issue on our end. Please refresh and try again. If the issue persists, please contact support.'
}: {
  message?: string;
}) => (
  <MuiBox p={5} display="flex" alignItems="center" justifyContent="center">
    <MuiTypography>{message}</MuiTypography>
  </MuiBox>
);

export const BookingMoreInfoButton = ({
  testID,
  handleOpen,
  size
}: {
  testID: string;
  handleOpen: () => void;
  size?: 'small' | 'medium' | 'large';
}) => (
  <MuiButton
    data-testid={testID}
    size={size || 'large'}
    variant="text"
    color="primary"
    onClick={handleOpen}
    startIcon={<Svg color={Color.secondary} name="InfoIcon" size={IconSize.small} />}
    style={{ fontWeight: FontWeight.normal }}
  >
    More info
  </MuiButton>
);

export const listItemCircle = (item: string, fontSize?: number) => {
  return (
    <StyledLIDisc>
      <MuiTypography fontSize={fontSize}>{item}</MuiTypography>
    </StyledLIDisc>
  );
};

export const listItemDecimal = (item: string, fontSize?: number) => {
  return (
    <StyledLIDecimal>
      <MuiTypography fontSize={fontSize}>{item}</MuiTypography>
    </StyledLIDecimal>
  );
};

export const CountCircle = ({
  children,
  testID
}: {
  children: React.ReactNode;
  testID?: string;
}) => {
  return (
    <MuiBox
      data-testid={testID}
      justifyContent="center"
      alignItems="center"
      width={18}
      height={18}
      borderRadius={BorderRadius.full}
      bgcolor={Color.purple}
    >
      <MuiTypography fontSize={FontSize.small} color={Color.white}>
        {children}
      </MuiTypography>
    </MuiBox>
  );
};

export const specialtyListItem = (itemTitle: string, itemDescription: string) => (
  <SpecialtyLICircle key={itemTitle}>
    <MuiTypography component="span" fontWeight={FontWeight.bold}>
      {itemTitle}
    </MuiTypography>
    {itemDescription}
  </SpecialtyLICircle>
);
