export interface DataLoaderProps<T> {
  data?: T;
  renderData: (data: T) => React.ReactElement;
  renderNoData?: () => React.ReactElement;

  loading?: boolean;
  renderLoading?: () => React.ReactElement;

  error?: string | boolean | Error | null;
  renderError?: () => React.ReactElement;
}

/**
 * Normalizes rendering UI that depends on data, including rendering data/loading/error/nodata states
 */
export default function DataLoader<T>({
  data,
  loading,
  error,
  renderLoading,
  renderNoData,
  renderError,
  renderData
}: DataLoaderProps<T>) {
  if (error && renderError) {
    return renderError();
  }

  if (loading && renderLoading) {
    return renderLoading();
  }

  if (Array.isArray(data) && !data.length && renderNoData) {
    return renderNoData();
  }

  if ((data === null || data === undefined) && renderNoData) {
    return renderNoData();
  }

  if (data && data.constructor === Object && Object.entries(data).length === 0 && renderNoData) {
    return renderNoData();
  }

  return renderData(data);
}
