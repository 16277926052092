import React from 'react';

import { Svg } from 'components/UI/Svg';
import { MuiBox, MuiTypography } from 'theme/material-ui';

import { Color } from 'modules/styles/colors';
import { FontSize } from 'modules/styles/variables';

interface Props {
  size?: number;
  svgName: string;
  svgSet: 'assets' | 'material' | 'ionic' | 'downloading' | 'web';
  title: string;
}

const ICON_FONT_SIZE = 26;

export const ProfileSectionHeader = ({ size = ICON_FONT_SIZE, svgName, svgSet, title }: Props) => (
  <MuiBox display="flex" flexDirection="row" alignItems="center" mb={2} pl={1}>
    <Svg set={svgSet} name={svgName} fill={Color.black} size={size} />
    <MuiBox pl={1}>
      <MuiTypography fontSize={FontSize.heading}>{title}</MuiTypography>
    </MuiBox>
  </MuiBox>
);

ProfileSectionHeader.defaultProps = {
  size: ICON_FONT_SIZE,
  svgSet: 'assets',
  svgName: '',
  title: ''
} as Partial<Props>;
