import React from 'react';
import styled from 'styled-components';
import InputLabel from '@material-ui/core/InputLabel';

interface HiddenLabelProps {
  forAtt: string;
  label: string;
  children: React.ReactNode;
}

export const HiddenLabel = styled(InputLabel)`
  &.MuiInputLabel-root {
    display: none;
  }
`;

const HiddenLabelControl = (props: HiddenLabelProps) => {
  const { forAtt, label, children } = props;
  return (
    <div>
      <HiddenLabel htmlFor={forAtt}>{label}</HiddenLabel>
      {children}
    </div>
  );
};

export default HiddenLabelControl;
