import React from 'react';
import { Color } from 'modules/styles/colors';

import { COVID_COLORS_HOSPITAL } from 'store/booking/constants';

export default {
  svg: (
    <svg xmlns="http://www.w3.org/2000/svg">
      <g style={{ clipPath: 'circle(40% at 40% 40%)', WebkitClipPath: 'circle(40% at 40% 40%)' }}>
        <circle fill={COVID_COLORS_HOSPITAL.st0} cx="183.3" cy="192.3" r="148.3" />
        <defs>
          <clipPath id="circClipPath">
            <circle cx="183.3" cy="192.1" r="148.3" />
          </clipPath>
        </defs>
        <g>
          <g>
            <path
              fill={COVID_COLORS_HOSPITAL.st2}
              d="M389.6,233.8L302.8,147h-42.9l-26-26.5h-10.8C223,120.3,196,93.3,195.7,93c-8.7-9.2-23.1-9.7-32.4-1.1
					c-7.9,7.4-9.5,19.2-4,28.5h-35.2v7.6l3.8,2.9v16H55.2v7.6l3.8,2.9v90.4l2.4,155.6l328.1-10.8V233.8z"
            />
            <rect
              x="201.2"
              y="146.9"
              fill={COVID_COLORS_HOSPITAL.st3}
              width="97.7"
              height="100.9"
            />
            <rect x="269.2" y="216.9" fill={COVID_COLORS_HOSPITAL.st4} width="20.4" height="17.5" />
            <rect x="269.2" y="216.9" fill={COVID_COLORS_HOSPITAL.st5} width="10.2" height="17.5" />
            <rect x="237.7" y="216.9" fill={COVID_COLORS_HOSPITAL.st4} width="20.4" height="17.5" />
            <rect x="237.7" y="216.9" fill={COVID_COLORS_HOSPITAL.st5} width="10.2" height="17.5" />
            <rect x="269.2" y="190.1" fill={COVID_COLORS_HOSPITAL.st4} width="20.4" height="17.5" />
            <rect x="269.2" y="190.1" fill={COVID_COLORS_HOSPITAL.st5} width="10.2" height="17.5" />
            <rect x="237.7" y="190.1" fill={COVID_COLORS_HOSPITAL.st4} width="20.4" height="17.5" />
            <rect x="237.7" y="190.1" fill={COVID_COLORS_HOSPITAL.st5} width="10.2" height="17.5" />
            <rect x="269.2" y="163.3" fill={COVID_COLORS_HOSPITAL.st4} width="20.4" height="17.5" />
            <rect x="269.2" y="163.3" fill={COVID_COLORS_HOSPITAL.st5} width="10.2" height="17.5" />
            <rect x="237.7" y="163.3" fill={COVID_COLORS_HOSPITAL.st4} width="20.4" height="17.5" />
            <rect x="237.7" y="163.3" fill={COVID_COLORS_HOSPITAL.st5} width="10.2" height="17.5" />
            <rect x="197.4" y="146.9" fill={COVID_COLORS_HOSPITAL.st6} width="105.4" height="7.6" />
            <rect x="59" y="147" fill={COVID_COLORS_HOSPITAL.st3} width="97.7" height="100.9" />
            <rect x="68.4" y="216.9" fill={COVID_COLORS_HOSPITAL.st4} width="20.4" height="17.5" />
            <rect x="68.4" y="216.9" fill={COVID_COLORS_HOSPITAL.st5} width="10.2" height="17.5" />
            <rect x="99.9" y="216.9" fill={COVID_COLORS_HOSPITAL.st4} width="20.4" height="17.5" />
            <rect x="99.9" y="216.9" fill={COVID_COLORS_HOSPITAL.st5} width="10.2" height="17.5" />
            <rect x="68.4" y="190.1" fill={COVID_COLORS_HOSPITAL.st4} width="20.4" height="17.5" />
            <rect x="68.4" y="190.1" fill={COVID_COLORS_HOSPITAL.st5} width="10.2" height="17.5" />
            <rect x="99.9" y="190.1" fill={COVID_COLORS_HOSPITAL.st4} width="20.4" height="17.5" />
            <rect x="99.9" y="190.1" fill={COVID_COLORS_HOSPITAL.st5} width="10.2" height="17.5" />
            <rect x="68.4" y="163.3" fill={COVID_COLORS_HOSPITAL.st4} width="20.4" height="17.5" />
            <rect x="68.4" y="163.3" fill={COVID_COLORS_HOSPITAL.st5} width="10.2" height="17.5" />
            <rect x="99.9" y="163.3" fill={COVID_COLORS_HOSPITAL.st4} width="20.4" height="17.5" />
            <rect x="99.9" y="163.3" fill={COVID_COLORS_HOSPITAL.st5} width="10.2" height="17.5" />
            <rect x="55.2" y="147" fill={COVID_COLORS_HOSPITAL.st6} width="105.4" height="7.6" />
            <rect
              x="127.9"
              y="120.4"
              fill={COVID_COLORS_HOSPITAL.st7}
              width="102.2"
              height="127.5"
            />
            <rect x="164.7" y="217.2" fill={COVID_COLORS_HOSPITAL.st8} width="28.5" height="30.7" />
            <rect x="164.7" y="217.2" fill={COVID_COLORS_HOSPITAL.st9} width="14.2" height="30.7" />
            <rect x="200.3" y="217.2" fill={COVID_COLORS_HOSPITAL.st8} width="20.4" height="17.5" />
            <rect x="200.3" y="217.2" fill={COVID_COLORS_HOSPITAL.st9} width="10.2" height="17.5" />
            <rect x="137.3" y="217.2" fill={COVID_COLORS_HOSPITAL.st8} width="20.4" height="17.5" />
            <rect x="137.3" y="217.2" fill={COVID_COLORS_HOSPITAL.st9} width="10.2" height="17.5" />
            <rect x="200.3" y="190.4" fill={COVID_COLORS_HOSPITAL.st8} width="20.4" height="17.5" />
            <rect x="200.3" y="190.4" fill={COVID_COLORS_HOSPITAL.st9} width="10.2" height="17.5" />
            <rect x="137.3" y="190.4" fill={COVID_COLORS_HOSPITAL.st8} width="20.4" height="17.5" />
            <rect x="137.3" y="190.4" fill={COVID_COLORS_HOSPITAL.st9} width="10.2" height="17.5" />
            <rect x="168.8" y="190.4" fill={COVID_COLORS_HOSPITAL.st8} width="20.4" height="17.5" />
            <rect x="168.8" y="190.4" fill={COVID_COLORS_HOSPITAL.st9} width="10.2" height="17.5" />
            <rect x="200.3" y="163.6" fill={COVID_COLORS_HOSPITAL.st8} width="20.4" height="17.5" />
            <rect x="200.3" y="163.6" fill={COVID_COLORS_HOSPITAL.st9} width="10.2" height="17.5" />
            <rect x="137.3" y="163.6" fill={COVID_COLORS_HOSPITAL.st8} width="20.4" height="17.5" />
            <rect x="137.3" y="163.6" fill={COVID_COLORS_HOSPITAL.st9} width="10.2" height="17.5" />
            <rect x="168.8" y="163.6" fill={COVID_COLORS_HOSPITAL.st8} width="20.4" height="17.5" />
            <rect x="168.8" y="163.6" fill={COVID_COLORS_HOSPITAL.st9} width="10.2" height="17.5" />
            <rect x="200.3" y="136.8" fill={COVID_COLORS_HOSPITAL.st8} width="20.4" height="17.5" />
            <rect x="200.3" y="136.8" fill={COVID_COLORS_HOSPITAL.st9} width="10.2" height="17.5" />
            <rect x="137.3" y="136.8" fill={COVID_COLORS_HOSPITAL.st8} width="20.4" height="17.5" />
            <rect x="137.3" y="136.8" fill={COVID_COLORS_HOSPITAL.st9} width="10.2" height="17.5" />
            <rect x="168.8" y="136.8" fill={COVID_COLORS_HOSPITAL.st8} width="20.4" height="17.5" />
            <rect x="168.8" y="136.8" fill={COVID_COLORS_HOSPITAL.st9} width="10.2" height="17.5" />
            <rect
              x="124.1"
              y="120.4"
              fill={COVID_COLORS_HOSPITAL.st10}
              width="109.9"
              height="7.6"
            />
            <circle fill={COVID_COLORS_HOSPITAL.st11} cx="179" cy="108.7" r="22.9" />
            <polygon
              fill={COVID_COLORS_HOSPITAL.st11}
              points="190.5,104.8 182.8,104.8 182.8,97.1 175.1,97.1 175.1,104.8 167.4,104.8 167.4,112.5 175.1,112.5
					175.1,120.2 182.8,120.2 182.8,112.5 190.5,112.5 				"
            />
          </g>
        </g>
        <circle fill={COVID_COLORS_HOSPITAL.st12} cx="179.1" cy="109.3" r="19" />
        <rect x="169.1" y="106.4" fill={COVID_COLORS_HOSPITAL.st7} width="19.9" height="5.8" />
        <rect x="176.2" y="99.3" fill={COVID_COLORS_HOSPITAL.st7} width="5.8" height="19.9" />
      </g>
    </svg>
  ),
  viewBox: '0 0 375 375'
};
