import React from 'react';

const st0 = {
  fill: 'none',
  strokeWidth: 2,
  strokeLinecap: 'round',
  strokeMiterlimit: 10
} as const;

const st1 = {
  fill: '#AAAAAA'
} as const;

export default {
  svg: (
    <g fill="none">
      <polyline {...st0} points="23,9 25,9 25,28 5,28 5,9 7,9" />
      <g>
        <path
          {...st1}
          d="M15,2.2c0.2,0,0.3,0,0.5,0.1c0.7,0.2,1.3,0.8,1.5,1.6c0.2,0.7,0,1.3-0.3,1.8C15.9,7.1,16,8.1,16,8.8V9h3v2h-8   V9h3V8.8c0-0.8,0.1-1.7-0.7-3.2C13.1,5.3,13,4.9,13,4.4C13,3.2,13.9,2.2,15,2.2 M15,0.2L15,0.2c-1.1,0-2.1,0.5-2.9,1.3   C11.4,2.3,11,3.3,11,4.4c0,0.7,0.2,1.4,0.6,2.1c0.1,0.2,0.2,0.3,0.2,0.5H11H9v2v2v2h2h8h2v-2V9V7h-2h-0.8c0.1-0.1,0.1-0.3,0.2-0.4   c0.2-0.4,0.8-1.6,0.5-3.1S17.4,0.8,16,0.4C15.6,0.3,15.3,0.2,15,0.2L15,0.2L15,0.2z"
        />
      </g>
      <circle {...st1} cx="15" cy="4" r="1" />
      <line {...st0} x1="9.4" y1="15.9" x2="20.9" y2="15.9" />
      <line {...st0} x1="9.4" y1="19.4" x2="20.9" y2="19.4" />
      <line {...st0} x1="9.4" y1="23" x2="15.5" y2="23" />
    </g>
  ),
  viewBox: '0 0 30 30'
};
