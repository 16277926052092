import { AnimatedEllipsis } from 'components/AnimatedEllipsis';
import BlueDot from 'components/common/BlueDot';
import DataLoader from 'components/UI/DataLoader/DataLoader';
import Svg from 'components/UI/Svg/Svg';
import { useProxySwitcherEffect } from 'hooks/useProxySwitcherEffect';
import { Spacing, IconSize } from 'modules/styles/variables';
import { orFallback } from 'modules/utils/StringUtils';
import { objectToQsParam } from 'modules/utils/UrlUtils';
import dayjs from 'dayjs';
import upperCase from 'lodash/upperCase';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { MESSAGES } from 'lib/constants/dashboard';
import { getInboxMessages } from 'store/messaging/actions';
import {
  createNMostRecentMessagesSelector,
  inboxErrorSelector,
  inboxFetchingSelector
} from 'store/messaging/selectors';
import { AnalyticsEvent } from 'services/AnalyticsService';
import useNavigationAnalytics from 'hooks/useNavigationAnalytics';
import { MessageStatus, MessageSummary } from 'store/messaging/types';
import { RootState } from 'store/types';
import InfoCard from 'components/InfoCard/InfoCard';
import { MuiBox, MuiList, MuiListItem, MuiTypography, MuiGrid, MuiButton } from 'theme/material-ui';
import { useLanguageSwitcher } from 'lib/hooks/useLanguageSwitcher';
import { DashboardMessages as translations } from 'lib/constants/translations/components/dashboard';
import { CompanyName } from 'modules/utils/ConfigUtils';

interface MessageSummaryItemProps {
  to: string;
  createDate: Date;
  sender: string;
  subject: string;
  unread: boolean;
  hasAttachment: boolean;
}

const Unread = styled(BlueDot)`
  & {
    position: absolute;
    margin-top: 25px;
    margin-left: -6px;
  }
`;

const MessageSummaryItem = (props: MessageSummaryItemProps) => {
  const formattedDate = dayjs(props.createDate)
    .format('MMM,DD')
    ?.split(',');

  return (
    <>
      {props.unread ? <Unread size={12} /> : null}
      <MuiListItem
        button
        divider
        component={Link}
        to={props.to}
        style={{ padding: 0 }}
        disableGutters
        data-testid="dashboard-messages-list"
      >
        <MuiBox display="flex" width="100%">
          <MuiBox display="flex" flexDirection="row" alignItems="center">
            <MuiBox
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              paddingLeft={Spacing.xSmall}
              paddingRight={Spacing.xSmall}
            >
              <MuiBox>
                <MuiTypography>{upperCase(formattedDate?.[0])}</MuiTypography>
              </MuiBox>
              <MuiBox>
                <MuiTypography>{formattedDate?.[1]}</MuiTypography>
              </MuiBox>
            </MuiBox>
          </MuiBox>
          <MuiBox flexGrow={2} p={1} overflow="hidden">
            <MuiTypography noWrap>{props.sender}</MuiTypography>
            <MuiBox display="flex" flexDirection="row">
              <MuiTypography noWrap>{orFallback(props.subject, '(No Subject)')}</MuiTypography>
              {props.hasAttachment ? (
                <MuiBox px={1} alignSelf="center">
                  <Svg set="assets" name="FileAttachment" size={IconSize.small} />
                </MuiBox>
              ) : null}
            </MuiBox>
          </MuiBox>
          <MuiBox p={2}>
            <Svg set="material" name="keyboard_arrow_right" />
          </MuiBox>
        </MuiBox>
      </MuiListItem>
    </>
  );
};

const DashboardMessagesLoading = () => (
  <MuiBox py={5} display="flex" flexDirection="column" alignItems="center">
    <MuiTypography>{MESSAGES.loading.title}</MuiTypography>

    <MuiBox marginTop={Spacing.small}>
      <AnimatedEllipsis />
    </MuiBox>
  </MuiBox>
);

const DashboardMessagesPlaceholder = ({ message }: { message: string }) => (
  <MuiBox display="flex" alignItems="center" justifyContent="center" flexDirection="column" py={3}>
    <MuiTypography gutterBottom>{message}</MuiTypography>
  </MuiBox>
);

interface DashboardMessagesProps {
  messages: MessageSummary[];
  loading: boolean;
  error: Error | null;
  loadMessages: () => void;
}

export const DashboardMessages = (props: DashboardMessagesProps) => {
  const history = useHistory();
  const screenText = useLanguageSwitcher(translations);
  const analyticsOnError = useNavigationAnalytics(AnalyticsEvent.DashboardMessagesFetchError);

  useEffect(() => {
    if (props.error) {
      analyticsOnError();
    }
  }, [props.error]);

  useProxySwitcherEffect(() => {
    props.loadMessages();
  }, []);

  if (props.loading) return null;
  if (!props.messages || props.messages.length === 0) return null;

  return (
    <MuiGrid item xs={12} sm={12} md={12} lg={6}>
      <InfoCard title={screenText?.title}>
        <DataLoader
          data={props.messages}
          loading={props.loading}
          error={props.error}
          renderLoading={() => <DashboardMessagesLoading />}
          renderError={() => <DashboardMessagesPlaceholder message={MESSAGES.error.title} />}
          renderNoData={() => <DashboardMessagesPlaceholder message={MESSAGES.empty.title} />}
          renderData={data => (
            <MuiBox>
              <MuiList disablePadding>
                {data.map(item => {
                  const title = item.senderDisplayName || CompanyName;
                  const subject = item.subject || '(No Subject)';
                  const { createDate } = item;
                  const unread = item.status === MessageStatus.UNREAD;
                  const hasAttachment = item?.hasAttachment;
                  const to = `/u/messages/inbox/${item.source}/${objectToQsParam(item.mhpDetails)}`;

                  return (
                    <MessageSummaryItem
                      key={item.id}
                      createDate={createDate}
                      sender={title}
                      subject={subject}
                      unread={unread}
                      to={to}
                      hasAttachment={hasAttachment}
                    />
                  );
                })}
              </MuiList>
              <MuiBox
                display="flex"
                flexDirection="row"
                justifyContent="space-evenly"
                padding={Spacing.xSmall}
              >
                <MuiButton
                  fullWidth
                  color="primary"
                  variant="outlined"
                  onClick={() =>
                    history.push('/u/messages', {
                      subject: '(No Subject)',
                      transmitParams: { returnBackTo: '/u/dashboard' }
                    })
                  }
                  data-testid="dashboard-new-messages"
                >
                  {screenText?.btnText}
                </MuiButton>
                <MuiBox width={Spacing.xLarge}></MuiBox>
                <MuiButton
                  fullWidth
                  variant="contained"
                  color="primary"
                  endIcon={<Svg set="material" name="keyboard_arrow_right" color="white" />}
                  onClick={() => history.push('/u/messages')}
                  data-testid="dashboard-view-inbox"
                >
                  {screenText?.btnText2}
                </MuiButton>
              </MuiBox>
            </MuiBox>
          )}
        />
      </InfoCard>
    </MuiGrid>
  );
};

const threeMostRecentMessagesSelector = createNMostRecentMessagesSelector(3);

const mapState = (state: RootState) => ({
  error: inboxErrorSelector(state),
  loading: inboxFetchingSelector(state),
  messages: threeMostRecentMessagesSelector(state)
});

const mapDispatch = {
  loadMessages: getInboxMessages
};

export default connect(mapState, mapDispatch)(DashboardMessages);
