import { AppointmentLocation } from 'store/booking/types';

export const getLocationMapsLink = (location?: AppointmentLocation) => {
  if (!location) {
    return null;
  }

  const { latitude, longitude, locationName } = location;

  let link = 'https://maps.google.com?';

  if (latitude && longitude) {
    link += `ll=${latitude},${longitude}`;
  }

  if (locationName) {
    link += `&q=${locationName}`;
  }

  return link;
};
