import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Color } from 'modules/styles/colors';

export default makeStyles(theme => {
  return createStyles({
    flexCard: {
      display: 'inline-flex',
      alignItems: 'center',
      [theme.breakpoints.down('lg')]: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
      }
    },
    flexContent: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: `-${theme.spacing(1) / 2}px`,
      marginRight: `-${theme.spacing(1) / 2}px`,
      '& > *': {
        marginLeft: theme.spacing(1) / 2,
        marginRight: theme.spacing(1) / 2
      }
    },
    cardAction: {
      width: 'auto',
      flex: 'none',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    },
    paper: {
      background: Color.foreColor,
      padding: theme.spacing(2)
    },
    verticalDivider: {
      alignSelf: 'stretch',
      height: 'auto'
    }
  });
});
