import styled from 'styled-components';
import DefaultText from 'components/UI/Typography/DefaultText';
import {
  smallFontSize,
  titleFontSize,
  Spacing,
  BorderRadius,
  FontSize
} from 'modules/styles/variables';
import { Color } from 'modules/styles/colors';
import Typography from 'components/UI/Typography';

export const BadgeCell = styled.div`
  && {
    display: flex;
    background-color: ${Color.secondary};
    flex-direction: column;
    width: 100px;
    border-radius: ${BorderRadius.base}px;
    margin-top: 10px;
    max-height: 50px;
  }
`;

export const TopContainer = styled.div`
  background-color: ${Color.secondary};
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin: ${Spacing.small}px ${Spacing.small}px ${Spacing.none}px ${Spacing.small}px;
  padding-top: ${Spacing.small}px;
`;

export const BottomContainer = styled.div`
  background-color: ${Color.secondary};
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  border-bottom-left-radius: ${BorderRadius.base}px;
  border-bottom-right-radius: ${BorderRadius.base}px;
  padding-bottom: ${Spacing.small}px;
`;

export const MinuteText = styled(DefaultText)`
  font-size: ${titleFontSize};
  font-weight: 400;
  line-height: 32px;
  color: ${Color.inverted};
  text-align: center;
`;

export const PaddedIcon = styled.div`
  padding-right: ${Spacing.small}px;
`;

export const UnitText = styled(DefaultText)`
  font-size: ${smallFontSize};
  color: ${Color.inverted};
  line-height: 17px;
  margin-bottom: ${Spacing.small}px;
  text-align: center;
`;

export const BannerCell = styled.div`
  align-items: center;
  background-color: ${Color.secondary};
  flex-direction: column;
  justify-content: center;
  align-content: center;
  width: 100%;
  flex-direction: row;
  max-height: 45px;
  border-bottom-left-radius: ${BorderRadius.base}px;
  border-bottom-right-radius: ${BorderRadius.base}px;
`;

export const PaddedMinute = styled(MinuteText)`
  margin: ${Spacing.small}px;
`;

export const PaddedUnit = styled(UnitText)`
  margin: ${Spacing.small}px;
`;

export const ClosedTextContainer = styled.div`
  background-color: ${Color.secondary};
  flex-direction: column;
  flex: 1;
  min-height: 40px;
  align-items: center;
  justify-content: center;
`;

export const ClosedText = styled(Typography)`
  color: ${Color.white};
  font-size: ${FontSize.large};
`;
