import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { RootState, RootDispatch } from 'store/types';

import { MuiBox } from 'theme/material-ui';

import { ConnectCareProviderDetails, ProviderProfessionalSummary } from 'components/ConnectCare';

import * as selectors from 'store/amwell/selectors';
import { getProviderDetail } from 'store/amwell/actions';
import { Provider, ProviderDetails } from 'store/amwell/types';

export interface Props {
  baseCost: number;
  dispatch: RootDispatch;
  providerDetailsLoading: boolean;
  providerDetailsError: Error | null;
  providerDetails: ProviderDetails | null;
  provider: Provider;
}

export function ConnectCareWaitingRoomProviderDetails(props: Props) {
  const {
    baseCost,
    dispatch,
    providerDetailsLoading,
    providerDetailsError,
    providerDetails,
    provider
  } = props;

  const isLoading = providerDetailsLoading;
  const [cost, setCost] = useState(0);

  useEffect(() => {
    if (baseCost && !cost) {
      setCost(baseCost);
    }
  }, [baseCost]);

  useEffect(() => {
    dispatch(getProviderDetail({ provider }));
  }, [provider.sourceId]);

  return (
    <MuiBox>
      <ConnectCareProviderDetails
        cost={cost}
        isLoading={isLoading}
        providerDetailsError={providerDetailsError}
        providerDetails={providerDetails}
      />
      <ProviderProfessionalSummary
        providerDetails={providerDetails}
        providerDetailsLoading={isLoading}
        providerDetailsError={providerDetailsError}
      />
    </MuiBox>
  );
}

const mapStateToProps = (state: RootState) => ({
  // provider details
  providerDetails: selectors.providerDetailsDataSelector(state),
  providerDetailsLoading: selectors.providerDetailsDataLoadingSelector(state),
  providerDetailsError: selectors.providerDetailsDataErrorSelector(state)
});

export default connect(mapStateToProps)(ConnectCareWaitingRoomProviderDetails);
