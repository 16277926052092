import React from 'react';

export default {
  svg: (
    <g>
      <g>
        <circle fill="#00857C" strokeWidth={0} cx={40} cy={40} r={38.5} />
        <g>
          <path
            fill="#FFFFFF"
            strokeWidth={0}
            d="M40,2.5c20.711,0,37.5,16.789,37.5,37.5S60.711,77.5,40,77.5S2.5,60.711,2.5,40S19.289,2.5,40,2.5  M40,0.5C18.22,0.5,0.5,18.22,0.5,40S18.22,79.5,40,79.5S79.5,61.78,79.5,40S61.78,0.5,40,0.5L40,0.5z"
          />
        </g>
      </g>
      <g>
        <defs>
          <circle id="SVGID_7_" cx={40} cy={40} r={37.5} />
        </defs>
        <clipPath id="SVGID_2_27">
          <use xlinkHref="#SVGID_7_" />
        </clipPath>
        <g clipPath="url(#SVGID_2_27)">
          <path
            fill="#3C2415"
            strokeWidth={0}
            d="M57.783,44.204c0,0.601-0.025,1.196-0.081,1.785c-0.031,0.407-0.081,0.808-0.15,1.202 c-0.163,1.133-0.426,2.235-0.776,3.294l0.069-0.138c-0.651,1.421-0.52,3.219,0,4.584c-0.701-0.232-1.259-0.607-1.459-1.252 c-0.939,1.722-13.012,8.197-15.448,8.197s-14.508-6.487-15.441-8.197c-0.207,0.645-0.764,1.021-1.465,1.252 c0.52-1.365,0.651-3.162,0-4.584l0.075,0.138c-0.357-1.058-0.62-2.16-0.783-3.294c-0.069-0.394-0.119-0.795-0.144-1.196 c-0.063-0.595-0.088-1.19-0.088-1.791c0-1.396,0.144-2.761,0.42-4.07c0.094-0.482,0.213-0.958,0.344-1.421 c0.357-1.259,0.839-2.461,1.428-3.594c0.689-1.34,1.528-2.58,2.498-3.694c0.814-0.946,1.722-1.803,2.705-2.555 c0.075-0.063,0.15-0.125,0.232-0.175c0.783-0.589,1.609-1.102,2.473-1.547c2.342-1.196,4.972-1.866,7.746-1.866 c2.774,0,5.404,0.67,7.746,1.872c2.054,1.046,3.889,2.505,5.41,4.27c0.971,1.115,1.81,2.354,2.498,3.694 c0.589,1.133,1.071,2.336,1.428,3.594c0.138,0.476,0.25,0.945,0.344,1.421C57.638,41.443,57.783,42.808,57.783,44.204z"
          />
          <path
            fill="#F6CCB8"
            strokeWidth={0}
            d="M56.398,37.423l0.509-6.613c0-8.647-8.265-15.654-16.907-15.654c-8.647,0-16.907,7.007-16.907,15.654 l0.508,6.606c-0.484,0.117-0.92,0.338-1.285,0.651c-0.676,0.576-1.102,1.428-1.102,2.386v3.256c0,1.722,1.386,3.118,3.111,3.129 c0,5.115,3.849,10.062,8.166,13.045v6.172l-1.403,0.62l-1.158,0.507l0.163,4.095l0.67,16.907h1.728v3.231H47.52v-3.231h1.71 l0.664-16.6l0.175-4.402l-1.158-0.513l-1.39-0.614v-6.181c4.312-2.984,8.153-7.927,8.153-13.036 c1.719-0.012,3.109-1.407,3.109-3.129v-3.256C58.782,38.984,57.764,37.759,56.398,37.423z"
          />
          <path
            fill="#87959B"
            strokeWidth={0}
            d="M3.338,116.775c1.246-13.782,2.204-26.637,4.139-37.977c1.165-6.794,9.649-7.013,21.271-11.177 c0.388-0.144,0.783-0.288,1.184-0.438l0.3,1.133c0.783,2.498,3.319,8.259,9.956,8.259c5.88,0,8.359-4.527,9.324-7.295 l0.557-2.098c0.401,0.15,0.795,0.294,1.183,0.438c11.622,4.164,20.106,4.383,21.265,11.177 c1.941,11.34,2.899,24.195,4.145,37.977"
          />
          <path
            fill="none"
            strokeWidth={0}
            d="M3.338,116.775c1.246-13.782,2.204-26.637,4.139-37.977c1.165-6.794,9.649-7.013,21.271-11.177 c0.388-0.144,0.783-0.288,1.184-0.438l0.3,1.133c0.783,2.498,3.319,8.259,9.956,8.259c5.88,0,8.359-4.527,9.324-7.295 l0.557-2.098c0.401,0.15,0.795,0.294,1.183,0.438c11.622,4.164,20.106,4.383,21.265,11.177 c1.941,11.34,2.899,24.195,4.145,37.977"
          />
          <path
            fill="#4E4D55"
            strokeWidth={0}
            d="M51.252,67.621l-0.557,2.073c-0.2,0.576-0.463,1.221-0.801,1.891c-1.446,2.887-4.296,6.243-9.706,6.243 c-5.604,0-8.591-3.594-10.094-6.55c-0.501-0.989-0.839-1.904-1.058-2.586l-0.288-1.071c0.388-0.144,0.783-0.288,1.184-0.438 l1.158-0.507l0.351,1.321c0.852,2.705,3.143,7.326,8.748,7.326c4.84,0,7.088-3.475,8.128-6.412l0.595-2.242l1.158,0.513 C50.47,67.333,50.864,67.477,51.252,67.621z"
          />
          <path
            fill="#EEAB91"
            strokeWidth={0}
            d="M56.398,37.423l0.509-6.613c0-8.647-8.265-15.654-16.907-15.654c-0.003,0-0.003,22.229-0.003,22.229 c-2.284,4.858-3.757,11.897-3.757,11.897h3.757v13.424c-2.171-0.188-4.772-1.309-7.202-3.054h-0.033v2.899l15.035,3.787 l-0.006-6.225c-2.474,1.641-5.071,2.632-7.165,2.632c-0.081,0-0.168-0.012-0.25-0.015c5.9-0.318,15.298-7.898,15.298-15.89 c1.719-0.012,3.109-1.407,3.109-3.129v-3.256C58.782,38.984,57.764,37.759,56.398,37.423z"
          />
          <g>
            <path
              fill="#F6CCB8"
              strokeWidth={0}
              d="M35.267,39.19c0.048,1.365-1.02,2.51-2.385,2.558c-1.365,0.048-2.51-1.02-2.558-2.385 s1.02-2.51,2.385-2.558C34.075,36.757,35.22,37.825,35.267,39.19z"
            />
            <path
              fill="#3C2417"
              strokeWidth={0}
              d="M35.267,38.563c0.048,1.365-1.02,2.51-2.385,2.558c-1.365,0.048-2.51-1.02-2.558-2.385 s1.02-2.51,2.385-2.558C34.075,36.131,35.22,37.198,35.267,38.563z"
            />
            <path
              fill="#FFFFFF"
              strokeWidth={0}
              d="M34.253,37.857c0.012,0.341-0.255,0.627-0.596,0.639c-0.341,0.012-0.628-0.255-0.639-0.596 c-0.012-0.341,0.255-0.628,0.596-0.639C33.955,37.248,34.242,37.515,34.253,37.857z"
            />
          </g>
          <g>
            <path
              fill="#F6CCB8"
              strokeWidth={0}
              d="M49.669,39.19c0.048,1.365-1.02,2.51-2.385,2.558c-1.365,0.048-2.51-1.02-2.558-2.385 c-0.048-1.365,1.02-2.51,2.385-2.558C48.477,36.757,49.622,37.825,49.669,39.19z"
            />
            <path
              fill="#3C2417"
              strokeWidth={0}
              d="M49.669,38.563c0.048,1.365-1.02,2.51-2.385,2.558c-1.365,0.048-2.51-1.02-2.558-2.385 c-0.048-1.365,1.02-2.51,2.385-2.558C48.477,36.131,49.622,37.198,49.669,38.563z"
            />
            <path
              fill="#FFFFFF"
              strokeWidth={0}
              d="M48.655,37.857c0.012,0.341-0.255,0.627-0.596,0.639c-0.341,0.012-0.628-0.255-0.639-0.596 c-0.012-0.341,0.255-0.628,0.596-0.639C48.357,37.248,48.643,37.515,48.655,37.857z"
            />
          </g>
          <path
            fill="#F05D44"
            strokeWidth={0}
            d="M45.634,52.661c0,2.767-2.523,5.009-5.636,5.009c-3.112,0-5.636-2.243-5.636-5.009H45.634z"
          />
          <path
            fill="#FFFFFF"
            strokeWidth={0}
            d="M44.007,54.54h-8.14c-0.346,0-0.626-0.28-0.626-0.626v-1.252h9.393v1.252 C44.633,54.26,44.353,54.54,44.007,54.54z"
          />
          <path
            fill="#3C2415"
            strokeWidth={0}
            d="M39.937,9.07c-9.092,0-14.389,5.26-17.251,10.601c0,0.006-0.006,0.006-0.006,0.013 c-3.876,7.257-3.25,16.988-1.528,22.498c0.313,1.008,0.657,1.872,1.021,2.555c0,0,0,0.006,0.006,0.006 c0.689,1.29,1.44,1.941,2.104,1.672c0.006,0,0.013-0.006,0.019-0.006V43.66l-0.006-4.108c0.501-0.601,0.958-1.315,1.396-2.098 c1.678-3.062,2.887-7.213,3.795-9.837c0.044-0.138,0.094-0.269,0.138-0.401c0.031,0.075,0.063,0.15,0.094,0.225 c1.359,3.394,3.688,5.98,7.245,6.537c-0.501-0.814-0.801-2.279-0.726-3.231c0.645,1.221,2.041,2.267,3.701,3.1 c3.162,1.597,7.295,2.43,9.079,2.267c-0.15-0.388-0.564-0.939-0.564-2.167c1.553,1.853,2.505,2.718,4.79,3.369 c-0.351-0.983-0.495-2.248-0.257-3.757c0,0,0.52,1.941,1.277,3.851v0.006c0.388,0.996,0.845,1.985,1.315,2.686l-0.006,3.557 v2.749c0.006,0,0.013,0.006,0.019,0.006c0.664,0.269,1.415-0.382,2.104-1.672c0.006,0,0.006-0.006,0.006-0.006 c0.363-0.683,0.708-1.547,1.021-2.555c1.722-5.51,2.348-15.241-1.528-22.498c0-0.006-0.006-0.006-0.006-0.013 C54.326,14.33,49.029,9.07,39.937,9.07"
          />
          <path
            fill="none"
            strokeWidth={0}
            d="M64.253,71.669H48.426c-1.057,1.838-2.753,3.714-5.424,4.509h28.527 C70.173,74.055,67.647,72.807,64.253,71.669z"
          />
          <path
            fill="none"
            strokeWidth={0}
            d="M31.755,71.669H15.746c-3.395,1.138-5.921,2.386-7.278,4.509h28.919 C34.669,75.387,32.888,73.513,31.755,71.669z"
          />
          <path
            fill="#43414D"
            strokeWidth={0}
            d="M72.517,78.798c-0.172-1.008-0.509-1.868-0.989-2.621H43.001c-0.84,0.25-1.771,0.398-2.814,0.398 c-1.034,0-1.957-0.152-2.801-0.398H8.468C7.987,76.93,7.65,77.79,7.477,78.798c-0.106,0.619-0.198,1.26-0.298,1.888h65.637 C72.716,80.058,72.623,79.417,72.517,78.798z"
          />
          <path
            fill="#43414D"
            strokeWidth={0}
            d="M51.252,67.621c-0.388-0.144-0.783-0.288-1.183-0.438l-0.557,2.098c-0.249,0.715-0.602,1.547-1.086,2.389 h15.827C60.736,70.49,56.289,69.426,51.252,67.621z"
          />
          <path
            fill="#43414D"
            strokeWidth={0}
            d="M30.232,68.316l-0.3-1.133c-0.401,0.15-0.795,0.294-1.184,0.438c-5.037,1.805-9.483,2.869-13.001,4.048 h16.008C30.981,70.41,30.5,69.172,30.232,68.316z"
          />
        </g>
      </g>
    </g>
  ),
  viewBox: '0 0 80 80'
};
