import React from 'react';
import Spacer from 'components/UI/Layout/Spacer';
import { convertToLowerKabobCase } from 'modules/utils/StringUtils';
import { StyledHeader } from './styled';
import { FontSize } from 'modules/styles/variables';
import { MuiBox, MuiGrid } from 'theme/material-ui';

interface Props {
  sectionHeader: string;
  directions: string;
  dosage: string;
  route: string;
  duration: string;
}

const Directions = ({ sectionHeader, directions, dosage, route, duration }: Props) => (
  <MuiBox p={1}>
    <StyledHeader fontSize={FontSize.heading} data-testid={convertToLowerKabobCase(sectionHeader)}>
      {sectionHeader}
    </StyledHeader>
    <Spacer spacing="medium" />
    <MuiGrid container spacing={1}>
      <MuiGrid item xs={4} data-testid="dosage">
        <MuiBox fontWeight="fontWeightBold" textAlign="left">
          Dosage
        </MuiBox>
      </MuiGrid>
      <MuiGrid item xs={6}>
        <MuiBox minHeight="19px">{dosage}</MuiBox>
      </MuiGrid>
      <MuiGrid item xs={4} data-testid="route">
        <MuiBox fontWeight="fontWeightBold" textAlign="left">
          Route
        </MuiBox>
      </MuiGrid>
      <MuiGrid item xs={6}>
        <MuiBox data-testid="reaction" minHeight="19px">
          {route}
        </MuiBox>
      </MuiGrid>
      <MuiGrid item xs={4} data-testid="duration">
        <MuiBox fontWeight="fontWeightBold" textAlign="left">
          Duration
        </MuiBox>
      </MuiGrid>
      <MuiGrid item xs={6}>
        <MuiBox data-testid="last-updated" minHeight="19px">
          {duration}
        </MuiBox>
      </MuiGrid>
      <MuiGrid item xs={4} data-testid="directions">
        <MuiBox fontWeight="fontWeightBold" textAlign="left">
          Directions
        </MuiBox>
      </MuiGrid>
      <MuiGrid item xs={6}>
        <MuiBox data-testid="recorded-date" minHeight="19px">
          {directions}
        </MuiBox>
      </MuiGrid>
    </MuiGrid>
  </MuiBox>
);

export default Directions;
