import styled from 'styled-components';

export const StyledListItem = styled.li`
  &&& {
    list-style-type: square;
    margin-bottom: 2px;
    margin-top: 2px;
    padding-bottom: 10px;
  }
`;

export const StyledSubList = styled.li`
  &&& {
    list-style-type: square;
    margin-bottom: 2px;
    margin-top: 2px;
    padding-bottom: 5px;
  }
`;

export const StyledUL = styled.ul`
  &&& {
    padding-left: 50px;
  }
`;

export const StyledMainList = styled.li`
  &&& {
    list-style-type: square;
    margin-bottom: 2px;
    margin-top: 2px;
  }
`;

export const StyledMainUL = styled.ul`
  &&& {
    padding-left: 30px;
  }
`;
