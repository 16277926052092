import React from 'react';
import { orFallback } from 'modules/utils/StringUtils';
import { MuiGrid, MuiTypography, MuiDivider } from 'theme/material-ui';

import { VisitRx } from 'store/amwell/types';
import { getSelectionId } from '../../utils';

export interface Props {
  prescriptions: VisitRx[];
}

function ConnectCareVisitSummaryEndPrescriptions({ prescriptions }: Props) {
  return (
    <MuiGrid container spacing={4}>
      <MuiGrid item xs={12}>
        <MuiTypography align="center" fontSize={16} variant="subtitle1" gutterBottom>
          Prescriptions
        </MuiTypography>
        <MuiDivider />
      </MuiGrid>
      <MuiGrid item xs={12}>
        <MuiGrid container spacing={2}>
          {prescriptions.map(p => (
            <MuiGrid key={getSelectionId(p)} item xs={12}>
              <MuiTypography fontWeight={600} gutterBottom>
                {p.description}
              </MuiTypography>
              <MuiTypography>{orFallback(p.rxInstructions, 'N/A')}</MuiTypography>
            </MuiGrid>
          ))}
        </MuiGrid>
      </MuiGrid>
    </MuiGrid>
  );
}

export default ConnectCareVisitSummaryEndPrescriptions;
