import styled from 'styled-components';
import { Color } from 'modules/styles/colors';
import { MuiBox } from 'theme/material-ui';
import { FontSize, Spacing } from 'modules/styles/variables';

export const RedMuiBox = styled(MuiBox)`
  background-color: ${Color.red + 21};
`;

export const WarningImg = styled.img<{ large?: boolean }>`
  width: ${({ large }) => (large ? FontSize.xlarge : FontSize.heading)}px;
  margin: 0px ${Spacing.large}px;
`;
