import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import chunk from 'lodash/chunk';
import { RootState } from 'store/types';
import { Condition } from 'store/amwell/types';
import * as selectors from 'store/amwell/selectors';
import * as actions from 'store/amwell/actions';
import { MuiBox, MuiGrid, MuiTypography } from 'theme/material-ui';
import { Alert } from 'components/Alert';
import { ConnectCareCheckboxGroup, ConnectCareDataLoader } from 'components/ConnectCare';
import { conditionsError } from './constants';
import analyticsService, { AnalyticsEvent } from 'services/AnalyticsService';
import {
  currentLocationPathNameSelector,
  previousLocationPathNameSelector
} from 'store/router/selectors';

export interface Props {
  getConditions: typeof actions.getConditions;
  updateConditions: typeof actions.updateConditions;
  conditions: Condition[];
  loading: boolean;
  error: Error | null;
  actionLoading: boolean;
  actionError: Error | null;
  columns: number;
  currentUrl?: string;
  referringUrl?: string;
}

const getChunks = (conditions: Condition[], columns: number) => {
  return chunk(conditions, Math.ceil(conditions.length / columns));
};

export function ConnectCareMedicalHistoryConditions(props: Props) {
  const {
    getConditions,
    updateConditions,
    conditions,
    loading,
    error,
    actionLoading,
    actionError,
    columns,
    currentUrl,
    referringUrl
  } = props;

  const chunks = useMemo(() => getChunks(conditions, columns), [conditions, columns]);

  useEffect(() => {
    if (actionError) {
      Alert.alert(conditionsError.UPDATE.title, conditionsError.UPDATE.subtitle);
      getConditions();
    }
  }, [actionError]);

  const onConditionChange = (chunkIndex: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const index = +e.target.id;
    const conditionsToUpdate: Condition[] = [...conditions];

    const count = [...Array(chunkIndex + 1)].reduce((acc, _, idx, arr) => {
      return arr.length - 1 === idx
        ? acc + chunks[idx].slice(0, index).length
        : acc + chunks[idx].length;
    }, 0);

    conditionsToUpdate[count].isCurrent = e.target.checked;
    updateConditions({ conditions: conditionsToUpdate });

    analyticsService.logEvent(AnalyticsEvent.MedicalHistoryEntered, {
      currentUrl,
      referringUrl
    });
  };

  const errorComponentProps = {
    message: conditionsError.GET.title,
    action: {
      label: 'Try Again?',
      onClick: getConditions
    }
  };

  return (
    <MuiBox my={2}>
      <MuiBox pb={4}>
        <MuiTypography variant="h5">
          Have you ever been diagnosed with any of the following conditions?
        </MuiTypography>
      </MuiBox>
      <ConnectCareDataLoader
        data={chunks}
        loading={loading}
        error={error}
        errorComponentProps={errorComponentProps}
        noDataComponentProps={errorComponentProps}
        renderData={data => (
          <MuiGrid container justify="center" spacing={4} wrap="nowrap">
            {data.map((d, idx) => (
              // eslint-disable-next-line react/no-array-index-key
              <MuiGrid key={idx} item lg={4} md={6}>
                <ConnectCareCheckboxGroup
                  items={d}
                  disabled={actionLoading}
                  itemToString={(item: any) => (item ? item.displayName : '')}
                  onChange={onConditionChange(idx)}
                  checkedKey="isCurrent"
                />
              </MuiGrid>
            ))}
          </MuiGrid>
        )}
      />
    </MuiBox>
  );
}

const mapStateToProps = (state: RootState) => ({
  conditions: selectors.conditionsDataSelector(state),
  loading: selectors.conditionsDataLoadingSelector(state),
  error: selectors.conditionsDataErrorSelector(state),
  actionLoading: selectors.conditionsActionLoadingSelector(state),
  actionError: selectors.conditionsActionErrorSelector(state),
  currentUrl: currentLocationPathNameSelector(state),
  referringUrl: previousLocationPathNameSelector(state)
});

const mapDispatch = {
  getConditions: actions.getConditions,
  updateConditions: actions.updateConditions
};

export default connect(mapStateToProps, mapDispatch)(ConnectCareMedicalHistoryConditions);
