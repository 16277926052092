import React, { ChangeEvent } from 'react';
import { GeoCodeLocation, PlacePrediction } from 'services/LocationService/types';
import { MuiAutoComplete, MuiBox, RenderInputParams, MuiInputBase } from 'theme/material-ui';
import { GroupBox, GroupHeader, AutoCompleteBox } from 'components/AutoComplete/styled';
import { geoCodeLocation } from 'services/LocationService/GoogleApis';
import SearchIcon from '@material-ui/icons/Search';

interface Props {
  options: PlacePrediction[];
  handleOnPress: (val: GeoCodeLocation) => void;
  handleInputChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  hideIcon: boolean;
  placeholder?: string;
  noOptionsText?: string;
}

const placeholderDefault = 'Address Search';

export function AddressAutoComplete({
  options,
  handleOnPress,
  handleInputChange,
  hideIcon,
  noOptionsText,
  placeholder = placeholderDefault
}: Props) {
  return (
    <MuiAutoComplete
      filterOptions={options => options} // This is needed to allow google to choose what is filtered and not just on the exact string. IE: "10 west main st danville" and "10 West Main St. Danville" are the same address to google, but MuiAutoComplete will filter any devation out.
      options={options}
      noOptionsText={noOptionsText}
      getOptionLabel={(option: PlacePrediction) => option.description}
      onChange={(evt: ChangeEvent<{}>, val: PlacePrediction | null): void => {
        if (val && val.description) {
          // once the place has been choosen, get the google data about the place
          geoCodeLocation(val.description).then((geoCodeArr: GeoCodeLocation[]) => {
            if (geoCodeArr[0]) {
              handleOnPress(geoCodeArr[0]);
            }
          });
        }
      }}
      renderGroup={params => {
        return (
          <GroupBox key={params.key}>
            <MuiBox
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              borderBottom="1px solid rgba(151,151,151,0.15)"
            >
              <GroupHeader>{params.key}</GroupHeader>
            </MuiBox>
            {params.children}
          </GroupBox>
        );
      }}
      renderInput={(params: RenderInputParams) => (
        <AutoCompleteBox display="flex" width="auto">
          <MuiInputBase
            style={{ flex: '1 1 auto' }}
            ref={params.InputProps.ref}
            inputProps={{ ...params.inputProps }}
            placeholder={placeholder}
            onChange={handleInputChange}
            autoComplete="off"
          />
          {!hideIcon ? <SearchIcon /> : null}
        </AutoCompleteBox>
      )}
    />
  );
}
