import { Box, Fade, Hidden } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';

import SvgButton from 'components/UI/Svg/SvgButton';
import { IntermountainHealthAuthLiteLogo } from 'components/common/AppHeader/AppHeader';
import { Color } from 'modules/styles/colors';
import { getTestWizard } from 'store/amwell/actions';
import { wizardSelector } from 'store/amwell/selectors';
import { Wizard } from 'store/amwell/types';
import { RootState } from 'store/types';
import { WizardContent, WizardDialog, WizardHeader, WizardSideBar } from './styled';

export interface TestMyComputerDialogProps {
  open: boolean;
  onClose?: () => void;
  onDone?: () => void;
  wizard: Wizard | null;
  getTestWizard: typeof getTestWizard;
}

export function TestMyComputerDialog({
  open,
  wizard,
  getTestWizard,
  onClose,
  onDone
}: TestMyComputerDialogProps) {
  const wizardRef = useRef<HTMLElement>();
  const [openDrawer, setOpenDrawer] = useState(false);

  useEffect(() => {
    if (open && wizardRef.current) {
      getTestWizard({
        container: wizardRef.current,
        doneCallback: handleDone
      });
    }
  }, [open, wizardRef]);

  useEffect(() => {
    if (open && wizard) {
      wizard.start();
    }
  }, [open, wizard]);

  const handleClose = () => {
    if (wizard) {
      wizard.stop();
    }
    if (onClose) {
      onClose();
    }
  };

  const handleDone = () => {
    handleClose();
    if (onDone) {
      onDone();
    }
  };

  return (
    <Fade in={open} appear>
      <WizardDialog role="dialog" elevation={24} aria-modal="true" aria-label="Test My Computer">
        <WizardHeader position="fixed" elevation={3}>
          <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
            <IntermountainHealthAuthLiteLogo bigHeight="45px" smallHeight="45" />

            <SvgButton
              set="material"
              size={24}
              color={Color.primary}
              name="close"
              accessibilityLabel="Close"
              appearance="transparent"
              onClick={handleClose}
            />
          </Box>
        </WizardHeader>
        <Hidden lgUp>
          <WizardSideBar elevation={24}>
            <SvgButton
              set="material"
              size={24}
              color={Color.primary}
              name="computer"
              accessibilityLabel="Setup Steps"
              appearance="transparent"
              onClick={() => setOpenDrawer(!openDrawer)}
            />
          </WizardSideBar>
        </Hidden>
        <WizardContent ref={wizardRef} open={openDrawer} />
      </WizardDialog>
    </Fade>
  );
}

const mapStateToProps = (state: RootState) => ({
  wizard: wizardSelector(state)
});

const mapDispatch = {
  getTestWizard
};

export default connect(mapStateToProps, mapDispatch)(TestMyComputerDialog);
