import React from 'react';
import { DfdError, ErrorState } from 'store/error/types';
import { ErrorMessages, ErrorMessage, ErrorText, ErrorTitle, CloseButWrapper } from './styled';
import { errorsSelector } from 'store/error/selectors';
import { connect, useDispatch } from 'react-redux';
import { RootState } from 'store/types';
import CloseButton from '../Button/CloseButton';
import { dismissError } from 'store/error/actions';

function ErrorHandler(props: ErrorState) {
  const { errors } = props;
  const dispatch = useDispatch();

  function handleErrorDismiss(err: DfdError) {
    if (err.callback) {
      err.callback();
    }
    dispatch(dismissError(err.id));
  }

  return (
    <ErrorMessages className="errorHandler">
      {errors.map((err: DfdError) => {
        const { id, name, level, message } = err;
        return (
          <ErrorMessage key={id} error={err}>
            <ErrorTitle>{level || name}: </ErrorTitle>
            <ErrorText>{message}</ErrorText>
            <CloseButWrapper>
              <CloseButton
                data-testid="close-errorHandler-button"
                onPress={() => handleErrorDismiss(err)}
                iconName="close"
              />
            </CloseButWrapper>
          </ErrorMessage>
        );
      })}
    </ErrorMessages>
  );
}

const mapDispatch = {};

const mapStateToProps = (state: RootState) => {
  const errors = errorsSelector(state);
  return {
    errors,
    errorsLength: errors.length
  };
};

export default connect(mapStateToProps, mapDispatch)(ErrorHandler);
