import styled, { css } from 'styled-components';
import {
  dfdDefaultTheme,
  MuiDrawer,
  MuiExpansionPanel,
  MuiExpansionPanelSummary,
  MuiBox
} from 'theme/material-ui';
import { resolveCSSUnit } from 'modules/utils/StylesUtils';

export const DRAWER_WIDTH = 320;
export const PANEL_HEIGHT = 64;

interface DrawerProps {
  width?: string | number;
}

const width = (props: DrawerProps) =>
  props.width &&
  css`
    width: ${resolveCSSUnit(props.width)};
  `;

export const StyledDrawer = styled(MuiDrawer)<DrawerProps>`
  &.MuiDrawer-root {
    flex-shrink: 0;
  }

  & .MuiBackdrop-root {
    background-color: transparent;
  }

  & .MuiDrawer-paper {
    width: ${DRAWER_WIDTH}px;
    overflow: scroll;
    padding-bottom: ${dfdDefaultTheme.spacing(4)}px;
    ${width};
  }

  & .MuiExpansionPanel-root.Mui-expanded {
    margin: auto;
  }
`;

export const StyledDrawerHeader = styled(MuiBox)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #ccc;
  min-height: ${PANEL_HEIGHT}px;
  padding: 0 ${dfdDefaultTheme.spacing(2)}px;
`;

export const ExpansionPanel = styled(MuiExpansionPanel)`
  background-color: transparent;
  box-shadow: none;
`;

interface ExpansionPanelSummaryProps {
  bgcolor: string;
}

export const ExpansionPanelSummary = styled(MuiExpansionPanelSummary)<ExpansionPanelSummaryProps>`
  &.MuiExpansionPanelSummary-root {
    min-height: ${PANEL_HEIGHT}px;
    ${props =>
      props.bgcolor &&
      css`
        background-color: ${props.bgcolor};
      `};
  }
`;
