import React from 'react';
import { MuiBox } from 'theme/material-ui';
import FlexBox from 'components/UI/Layout/FlexBox';
import shareIdeasCard from 'screens/HealthTools/Cards/ShareIdeas';
import { HealthToolCard, HealthToolCardData } from './HealthToolCard';

interface HealthToolsCardsProps {
  toolsData: Array<HealthToolCardData>;
  navigateToSurveyShareIdeas: () => void;
  isHealthToolSurveyCompleted?: boolean;
}

const TOOLS_PER_ROW = 3;

const getRowTools = (
  currentRowIndex: number,
  toolsData: HealthToolCardData[]
): HealthToolCardData[] => {
  const currentToolIndex = TOOLS_PER_ROW * currentRowIndex;
  const lastToolIndex = TOOLS_PER_ROW * (currentRowIndex + 1);
  const currentRow = [];

  for (
    let toolIndex = currentToolIndex;
    toolIndex < lastToolIndex && toolIndex < toolsData.length;
    toolIndex += 1
  ) {
    currentRow.push(toolsData[toolIndex]);
  }

  return currentRow;
};

const getToolsPerRow = (toolsData: HealthToolCardData[]): HealthToolCardData[][] => {
  const totalRows = Math.ceil(toolsData.length / TOOLS_PER_ROW);
  const toolsPerRow = [];

  for (let currentRowIndex = 0; currentRowIndex < totalRows; currentRowIndex += 1) {
    const currentRow = getRowTools(currentRowIndex, toolsData);
    toolsPerRow.push(currentRow);
  }

  return toolsPerRow;
};

export default function HealthToolsCards({
  toolsData,
  navigateToSurveyShareIdeas,
  isHealthToolSurveyCompleted
}: HealthToolsCardsProps) {
  const toolsPerRow = getToolsPerRow(toolsData);

  return (
    <MuiBox maxWidth={1120} width="100%">
      {toolsPerRow.map(currentRow => (
        <FlexBox flexDirection="row" justifyContent="center" flexWrap="wrap">
          {currentRow.map(toolData => (
            <HealthToolCard toolData={toolData} />
          ))}
          {!isHealthToolSurveyCompleted ? (
            <HealthToolCard
              toolData={{ ...shareIdeasCard, onSelect: navigateToSurveyShareIdeas }}
            />
          ) : null}
        </FlexBox>
      ))}
    </MuiBox>
  );
}
