import React from 'react';
import CollapsibleListItem from 'components/common/CollapsibleList/CollapsibleListItem';
import Divider from 'components/UI/Divider';
import {
  ResourceDocument,
  Document,
  DocumentCategoryType,
  VisitSummaryDetails
} from 'store/visitSummary/types';
import { MuiBox, MuiGrid, MuiPaper } from 'theme/material-ui';
import BinaryDocumentHandler from 'components/VisitSummaries/BinaryDocumentHandler';

interface Props {
  documents: VisitSummaryDetails['documents'];
}

export const renderCategoryList = (data: ResourceDocument[]) => {
  return (
    <>
      {data.map((item: ResourceDocument) => (
        <>
          <MuiGrid item xs={3}>
            <MuiBox textAlign="left" p={3}>
              {item.categoryDisplay.toString()}
            </MuiBox>
          </MuiGrid>
          <MuiGrid item xs={9} direction="column">
            <MuiGrid container>
              {item.documents.map((document: Document) => (
                <BinaryDocumentHandler document={document} />
              ))}
            </MuiGrid>
          </MuiGrid>
        </>
      ))}
    </>
  );
};

export const Resources = ({ documents }: Props) => {
  if (!documents) return null;

  // Need to convert documents object to an array
  const filteredDocuments = Object.values(documents).filter(resourceDocument => {
    return (
      (resourceDocument.categoryDisplay === DocumentCategoryType.OTHER ||
        resourceDocument.categoryDisplay === DocumentCategoryType.EDUCATION) &&
      resourceDocument.documents.length
    );
  });

  if (filteredDocuments.length > 0) {
    return (
      <>
        <CollapsibleListItem title="Resources" subtitle="Visit resources and documentation">
          <MuiBox width="100%">
            <MuiPaper elevation={2}>
              <MuiGrid container aria-label="resources list">
                {renderCategoryList(filteredDocuments)}
              </MuiGrid>
            </MuiPaper>
          </MuiBox>
        </CollapsibleListItem>
        <Divider />
      </>
    );
  }
  return null;
};

export default Resources;
