import React, { useEffect, useState } from 'react';
import uniq from 'lodash/uniq';

import { MuiBox, MuiFormControlLabel } from 'theme/material-ui';
import { BookingDrawer } from 'components/booking/BookingDrawer';

import { Thunk } from 'store/types';
import { Appointment } from 'store/booking/types';
import * as findProviderTypes from 'store/findProvider/types';
import { mergeFiltersAndFacets } from 'store/findProvider/utils';
import * as findProviderConstants from 'store/findProvider/constants';
import { StyledMuiCheckbox } from 'screens/Booking/components/styled';

export interface Props {
  appointment: Appointment;
  open: boolean;
  search: () => void;
  onClose: () => void;
  activeFilters: findProviderTypes.ProviderSearchFilters;
  searchFilter: findProviderTypes.ProviderSearchFilterState;
  setActiveProviderSearchFilter: (
    filter: Partial<findProviderTypes.ProviderSearchFilters>,
    opts?: { search: boolean }
  ) => Thunk;
}

export function SpecialtyProviderFilters({
  appointment,
  open,
  search,
  activeFilters,
  searchFilter,
  setActiveProviderSearchFilter,
  onClose
}: Props) {
  const [filteredTexts, setFilteredTexts] = useState([]);
  const [acceptingNewPatientsChecked, setAcceptingNewPatientsChecked] = useState(true);

  useEffect(() => {
    if (searchFilter.hasActiveFilters) {
      const { facets, filters } = activeFilters;
      const filterArray = [];

      facets.forEach(e => {
        filterArray.push(e.name);
      });

      filters.forEach(e => {
        if (e.value) {
          filterArray.push(findProviderConstants.filteredTextKey[e.group]);
        }
      });

      setFilteredTexts(uniq([...filteredTexts, ...filterArray]));
    }
  }, [activeFilters, searchFilter]);

  const toggleAcceptingNewPatients = () =>
    setAcceptingNewPatientsChecked(!acceptingNewPatientsChecked);

  const handleApplyOrClearAllFilters = (clearFilters?: boolean) => {
    let filters = { isAcceptingOnlineBooking: true };

    if (clearFilters) {
      filters = {
        isAcceptingOnlineBooking: true
      };
      setAcceptingNewPatientsChecked(false);
    }

    const facets = {
      specialty: [
        {
          name: appointment?.specialty?.nameLong || '',
          group: 'specialty',
          value: appointment?.specialty?.nameLong || ''
        }
      ]
    };

    const dispatchSearchFilters: findProviderTypes.ProviderSearchFilters = mergeFiltersAndFacets(
      filters,
      facets
    );

    setActiveProviderSearchFilter(dispatchSearchFilters);
    search();
    onClose();
  };

  const handleClose = () => () => {
    onClose();
  };

  return (
    <BookingDrawer
      open={open}
      headerText="Filter By..."
      buttonOneText="Apply filters"
      buttonTwoText="Clear all filters"
      onClose={handleClose()}
      onApply={() => handleApplyOrClearAllFilters()}
      onClear={() => handleApplyOrClearAllFilters(true)}
    >
      <MuiBox width="100%" px={2} pt={3}>
        <MuiFormControlLabel
          control={
            <StyledMuiCheckbox
              color="primary"
              checked={acceptingNewPatientsChecked}
              onChange={toggleAcceptingNewPatients}
            />
          }
          label="Accepting new patients"
        />
      </MuiBox>
    </BookingDrawer>
  );
}

export default SpecialtyProviderFilters;
