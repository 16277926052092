import * as React from 'react';

export default () => {
  return {
    svg: (
      <g>
        <path
          fill="none"
          d="M29.2518 22.575V6.08246C29.2518 5.34121 29.8518 4.73871 30.5943 4.73871H55.5043C56.2468 4.73871 56.8468 5.34121 56.8468 6.08246V11.4537V36.2962V45.025C56.8468 45.7662 56.2468 46.3675 55.5043 46.3675H38.7556"
          stroke="url(#paint0_linear_2404_1757)"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M44.062 7.42503C44.062 7.79628 43.762 8.09628 43.3907 8.09628C43.0195 8.09628 42.7195 7.79628 42.7195 7.42503C42.7195 7.05378 43.0195 6.75378 43.3907 6.75378C43.762 6.75378 44.062 7.05378 44.062 7.42503Z"
          fill="url(#paint1_linear_2404_1757)"
        />
        <path
          fill="none"
          d="M39.021 42.3388H47.0785"
          stroke="url(#paint2_linear_2404_1757)"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fill="none"
          d="M37.0673 20.5957C37.0673 21.0394 37.4273 21.3994 37.871 21.3994H38.2723"
          stroke="url(#paint3_linear_2404_1757)"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fill="none"
          d="M47.9115 21.3988H48.3128C48.7565 21.3988 49.1165 21.0388 49.1165 20.5951"
          stroke="url(#paint4_linear_2404_1757)"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fill="none"
          d="M38.339 21.3988C38.7215 23.6776 40.704 25.4151 43.0915 25.4151C45.484 25.4151 47.469 23.6726 47.8477 21.3876"
          stroke="url(#paint5_linear_2404_1757)"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fill="none"
          d="M49.1166 20.5956V18.5869C49.1166 17.7844 49.1716 17.0606 48.7154 16.1781C49.1616 15.7331 49.5179 14.9819 49.5179 14.1694V12.9644H48.3129H45.9041C45.1004 12.9644 44.0991 12.5631 43.0916 12.5631C39.5429 12.5631 37.0679 14.9731 37.0679 18.1856V20.5956"
          stroke="url(#paint6_linear_2404_1757)"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fill="none"
          d="M39.8787 27.4236C39.8787 27.4236 39.7262 29.7374 43.0912 31.8411C43.0912 31.8411 46.305 29.8336 46.305 27.4236"
          stroke="url(#paint7_linear_2404_1757)"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fill="none"
          d="M39.8789 27.4236C37.4689 27.8249 37.4689 29.8336 37.4689 29.8336V31.0386"
          stroke="url(#paint8_linear_2404_1757)"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fill="none"
          d="M38.6739 35.0547H39.4776V33.9072C39.4776 32.6447 38.6164 31.0385 37.4689 31.0385"
          stroke="url(#paint9_linear_2404_1757)"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M50.0275 34.2897C50.0275 35.0147 49.44 35.6022 48.715 35.6022C47.99 35.6022 47.4025 35.0147 47.4025 34.2897C47.4025 33.5647 47.99 32.9772 48.715 32.9772C49.44 32.9772 50.0275 33.5647 50.0275 34.2897Z"
          fill="url(#paint10_linear_2404_1757)"
        />
        <path
          fill="none"
          d="M35.2285 28.5734C35.9398 28.1059 36.6248 27.9134 37.0673 27.8246C38.0048 27.6909 38.9423 27.5571 39.8785 27.4234C40.2723 27.3646 40.6823 27.0634 40.6823 26.6196V25.0134"
          stroke="url(#paint11_linear_2404_1757)"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fill="none"
          d="M45.5018 25.0137V26.6199C45.5018 27.0637 45.9118 27.3649 46.3056 27.4237L49.1168 27.8249C51.1243 28.2262 53.2431 29.8337 53.2431 32.2437"
          stroke="url(#paint12_linear_2404_1757)"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fill="none"
          d="M46.3051 27.4236C48.7151 27.8249 48.7151 29.8336 48.7151 29.8336V33.4486"
          stroke="url(#paint13_linear_2404_1757)"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fill="none"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.1511 35.4479L21.2086 37.4504V34.8754H11.5023V44.2992H21.2086V41.7254L26.1511 43.7279V35.4479Z"
          stroke="url(#paint14_linear_2404_1757)"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fill="none"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M34.5007 39.5876C34.5007 48.2438 27.4832 55.2613 18.8269 55.2613C10.1694 55.2613 3.1532 48.2438 3.1532 39.5876C3.1532 30.9313 10.1694 23.9138 18.8269 23.9138C27.4832 23.9138 34.5007 30.9313 34.5007 39.5876Z"
          stroke="url(#paint15_linear_2404_1757)"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <defs>
          <linearGradient
            id="paint0_linear_2404_1757"
            x1={29.2518}
            y1={25.5531}
            x2={56.8468}
            y2={25.5531}
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0.0843751} stopColor="#FF0048" />
            <stop offset={0.386458} stopColor="#DF36FF" />
            <stop offset={0.980208} stopColor="#4A00E2" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_2404_1757"
            x1={42.7195}
            y1={7.42503}
            x2={44.062}
            y2={7.42503}
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0.0843751} stopColor="#FF0048" />
            <stop offset={0.386458} stopColor="#DF36FF" />
            <stop offset={0.980208} stopColor="#4A00E2" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_2404_1757"
            x1={39.021}
            y1={42.3388}
            x2={47.0785}
            y2={42.3388}
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0.0843751} stopColor="#FF0048" />
            <stop offset={0.386458} stopColor="#DF36FF" />
            <stop offset={0.980208} stopColor="#4A00E2" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_2404_1757"
            x1={37.0448}
            y1={20.9976}
            x2={38.2948}
            y2={20.9976}
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0.0843751} stopColor="#FF0048" />
            <stop offset={0.386458} stopColor="#DF36FF" />
            <stop offset={0.980208} stopColor="#4A00E2" />
          </linearGradient>
          <linearGradient
            id="paint4_linear_2404_1757"
            x1={47.889}
            y1={20.9969}
            x2={49.139}
            y2={20.9969}
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0.0843751} stopColor="#FF0048" />
            <stop offset={0.386458} stopColor="#DF36FF" />
            <stop offset={0.980208} stopColor="#4A00E2" />
          </linearGradient>
          <linearGradient
            id="paint5_linear_2404_1757"
            x1={38.339}
            y1={23.4013}
            x2={47.8477}
            y2={23.4013}
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0.0843751} stopColor="#FF0048" />
            <stop offset={0.386458} stopColor="#DF36FF" />
            <stop offset={0.980208} stopColor="#4A00E2" />
          </linearGradient>
          <linearGradient
            id="paint6_linear_2404_1757"
            x1={37.0679}
            y1={16.5794}
            x2={49.5179}
            y2={16.5794}
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0.0843751} stopColor="#FF0048" />
            <stop offset={0.386458} stopColor="#DF36FF" />
            <stop offset={0.980208} stopColor="#4A00E2" />
          </linearGradient>
          <linearGradient
            id="paint7_linear_2404_1757"
            x1={39.8777}
            y1={29.6324}
            x2={46.305}
            y2={29.6324}
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0.0843751} stopColor="#FF0048" />
            <stop offset={0.386458} stopColor="#DF36FF" />
            <stop offset={0.980208} stopColor="#4A00E2" />
          </linearGradient>
          <linearGradient
            id="paint8_linear_2404_1757"
            x1={37.4689}
            y1={29.2311}
            x2={39.8789}
            y2={29.2311}
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0.0843751} stopColor="#FF0048" />
            <stop offset={0.386458} stopColor="#DF36FF" />
            <stop offset={0.980208} stopColor="#4A00E2" />
          </linearGradient>
          <linearGradient
            id="paint9_linear_2404_1757"
            x1={37.4689}
            y1={33.0466}
            x2={39.4776}
            y2={33.0466}
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0.0843751} stopColor="#FF0048" />
            <stop offset={0.386458} stopColor="#DF36FF" />
            <stop offset={0.980208} stopColor="#4A00E2" />
          </linearGradient>
          <linearGradient
            id="paint10_linear_2404_1757"
            x1={47.4025}
            y1={34.2897}
            x2={50.0275}
            y2={34.2897}
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0.0843751} stopColor="#FF0048" />
            <stop offset={0.386458} stopColor="#DF36FF" />
            <stop offset={0.980208} stopColor="#4A00E2" />
          </linearGradient>
          <linearGradient
            id="paint11_linear_2404_1757"
            x1={35.2285}
            y1={26.7934}
            x2={40.6823}
            y2={26.7934}
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0.0843751} stopColor="#FF0048" />
            <stop offset={0.386458} stopColor="#DF36FF" />
            <stop offset={0.980208} stopColor="#4A00E2" />
          </linearGradient>
          <linearGradient
            id="paint12_linear_2404_1757"
            x1={45.5018}
            y1={28.6287}
            x2={53.2431}
            y2={28.6287}
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0.0843751} stopColor="#FF0048" />
            <stop offset={0.386458} stopColor="#DF36FF" />
            <stop offset={0.980208} stopColor="#4A00E2" />
          </linearGradient>
          <linearGradient
            id="paint13_linear_2404_1757"
            x1={46.3051}
            y1={30.4361}
            x2={48.7151}
            y2={30.4361}
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0.0843751} stopColor="#FF0048" />
            <stop offset={0.386458} stopColor="#DF36FF" />
            <stop offset={0.980208} stopColor="#4A00E2" />
          </linearGradient>
          <linearGradient
            id="paint14_linear_2404_1757"
            x1={11.5023}
            y1={39.5873}
            x2={26.1511}
            y2={39.5873}
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0.0843751} stopColor="#FF0048" />
            <stop offset={0.386458} stopColor="#DF36FF" />
            <stop offset={0.980208} stopColor="#4A00E2" />
          </linearGradient>
          <linearGradient
            id="paint15_linear_2404_1757"
            x1={3.1532}
            y1={39.5876}
            x2={34.5007}
            y2={39.5876}
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0.0843751} stopColor="#FF0048" />
            <stop offset={0.386458} stopColor="#DF36FF" />
            <stop offset={0.980208} stopColor="#4A00E2" />
          </linearGradient>
        </defs>
      </g>
    ),
    viewBox: '0 0 60 60'
  };
};
