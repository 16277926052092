import React from 'react';
import Footer from 'components/common/Footer';
import { useLanguageSwitcher } from 'lib/hooks/useLanguageSwitcher';
import { CovidTestGuestFooter_translations } from 'lib/constants/translations/components';

interface CovidTestGuestFooterProps {
  title: string;
}

export default function CovidTestGuestFooter({ title }: CovidTestGuestFooterProps) {
  const screenText = useLanguageSwitcher(CovidTestGuestFooter_translations);
  return (
    <Footer
      headerText={screenText?.covidInfo}
      link={screenText?.CovidPatientSelectHelpline}
      linkName={screenText?.CovidPatientSelectHelpline}
      helplineHours={screenText?.CovidHelplineHours}
      title={title}
      authenticated={false}
    />
  );
}
