import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import analyticsService, { AmplitudeEventData, AnalyticsEvent } from 'services/AnalyticsService';

import {
  MuiBox,
  MuiContainer,
  MuiDivider,
  MuiTypography,
  MuiGrid,
  MuiRadio,
  MuiFormControlLabel,
  useTheme
} from 'theme/material-ui';

import { Svg } from 'components/UI/Svg';
import Button from 'components/UI/Button/Button';

import { Color } from 'modules/styles/colors';
import { FontWeight, IconSize } from 'modules/styles/variables';
import { BehavioralHealthSpecialtyNames } from 'modules/constants/booking/locationPreference';

import { RootState } from 'store/types';
import * as bookingTypes from 'store/booking/types';
import * as bookingActions from 'store/booking/actions';
import * as bookingSelectors from 'store/booking/selectors';
import * as routerSelectors from 'store/router/selectors';

import { StyledScreen } from '../components/styled';
import BookingLocationPreferenceMoreInfoDialog from '../dialogs/BookingLocationPreferenceMoreInfoDialog';
import { BookingMoreInfoButton, stepActionsHeight } from '../components/sharedComponents';
import { BookingStepActions, BookingStepAction } from '../components/BookingStepper';
import { useCheckBookingInfoEffect } from '../useCheckBookingInfoEffect';
import FlexBox from 'components/UI/Layout/FlexBox';

export interface Props extends RouteComponentProps {
  appointmentDetails: bookingTypes.AppointmentDetails;
  setAppointmentType: typeof bookingActions.setAppointmentType;
  locations: bookingTypes.AppointmentLocation[];
  setAppointmentLocation: typeof bookingActions.setAppointmentLocation;
  currentRouteName?: string;
  previousRouteName?: string;
  setInitialTimeSlots: typeof bookingActions.setInitialTimeSlots;
  getLocationTimeSlots: typeof bookingActions.getLocationTimeSlots;
}

export function BookingLocationPreferenceSelect({
  appointmentDetails,
  setAppointmentType,
  history,
  currentRouteName,
  previousRouteName,
  locations,
  setAppointmentLocation,
  setInitialTimeSlots,
  getLocationTimeSlots
}: Props) {
  useCheckBookingInfoEffect(appointmentDetails?.patient);

  useEffect(() => {
    if (appointmentDetails?.groupedVisitType?.appointmentTypes?.length === 1) {
      setAppointmentType(appointmentDetails?.groupedVisitType?.appointmentTypes[0]);
    }
  }, [appointmentDetails?.groupedVisitType?.appointmentTypes]);

  const theme = useTheme();
  const [isMoreInfoOpen, setIsMoreInfoOpen] = useState(false);

  const isVideoAppointment = appointmentDetails?.groupedVisitType?.appointmentTypes?.includes(
    'Video'
  );
  const isOfficeAppointment = appointmentDetails?.groupedVisitType?.appointmentTypes?.includes(
    'In Clinic'
  );

  const handleMoreInfoOpen = (moreInfoClicked: boolean) => {
    const helpUsedData: AmplitudeEventData = {
      currentUrl: currentRouteName,
      referringUrl: previousRouteName,

      source_page: moreInfoClicked
        ? 'SelectLocationPage(MoreInfo)'
        : 'SelectLocationPage(NeedHelpChoosing)'
    };
    analyticsService.logEvent(AnalyticsEvent.BookingHelpUsed, helpUsedData);
    setIsMoreInfoOpen(true);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAppointmentType(event.target.value);
  };

  const handleLocationPreferenceSelect = () => {
    if (
      (locations?.length === 1 &&
        appointmentDetails?.appointmentType?.includes('Video') &&
        appointmentDetails?.specialty?.nameLong === 'Behavioral Health Connect Care') ||
      appointmentDetails?.doctor?.primarySpecialty?.specialtyName ===
        BehavioralHealthSpecialtyNames.PSY_NURSE_PRACTITIONER_FAMILY
    ) {
      setAppointmentLocation(locations[0]);
      setInitialTimeSlots(locations[0]?.slots);
      getLocationTimeSlots();
      history.push('/u/get-care-now/booking/date-and-time-select');
    } else {
      history.push('/u/get-care-now/booking/office-location-select');
    }
  };

  const OnlineVisitText = () => (
    <>
      <MuiBox mr={2} width="50%" flexDirection="row" alignItems="center">
        <MuiTypography fontWeight={FontWeight.bold}>Video Visit -</MuiTypography>
      </MuiBox>
      <MuiTypography>
        Convenient and safe for everyone. Great option for situations that do not need hands-on exam
        or treatment.
      </MuiTypography>
    </>
  );

  const OfficeVisitText = () => (
    <>
      <MuiBox mr={1} width="50%" flexDirection="row" alignItems="center">
        <MuiTypography fontWeight={FontWeight.bold}>In-Office Visit -</MuiTypography>
      </MuiBox>
      <MuiTypography> Required for situations that need hands-on exam or treatment.</MuiTypography>
    </>
  );

  const handlePrevious = (): void => {
    setAppointmentType(null);
    history.goBack();
  };

  return (
    <StyledScreen>
      <FlexBox flex="1">
        <MuiContainer maxWidth="lg">
          <MuiBox my={3}>
            <MuiGrid container>
              <MuiGrid item xs>
                <MuiTypography gutterBottom variant="h4">
                  Select Location Preference
                </MuiTypography>
              </MuiGrid>
              <MuiGrid item>
                <BookingMoreInfoButton
                  testID="location-preference-select-more-info-button"
                  color={Color.secondary}
                  handleOpen={() => handleMoreInfoOpen(true)}
                />
              </MuiGrid>
            </MuiGrid>
            <MuiBox mb={3}>
              <MuiDivider />
            </MuiBox>
            {isVideoAppointment ? (
              <>
                <MuiGrid item xs align="center">
                  <Svg
                    set="assets"
                    fill={Color.grayHue6}
                    name="DocPhone"
                    size={IconSize.xLarge * 3}
                  />
                  <MuiGrid item xs={9} sm={7} md={5}>
                    <MuiBox>
                      <MuiFormControlLabel
                        data-testid="videoRadio"
                        control={
                          <MuiRadio
                            color="secondary"
                            value="Video"
                            onChange={handleChange}
                            checked={appointmentDetails.appointmentType === 'Video'}
                          />
                        }
                        label={<OnlineVisitText />}
                      />
                    </MuiBox>
                  </MuiGrid>
                </MuiGrid>
              </>
            ) : null}
            {isOfficeAppointment ? (
              <>
                <MuiGrid item xs={12} align="center">
                  <Svg
                    set="assets"
                    fill={Color.grayHue6}
                    name="COVID19ModalHospital"
                    size={IconSize.xLarge * 3}
                  />
                  <MuiGrid item xs={9} sm={7} md={5}>
                    <MuiBox>
                      <MuiFormControlLabel
                        data-testid="officeRadio"
                        control={
                          <MuiRadio
                            color="secondary"
                            value="In Clinic"
                            onChange={handleChange}
                            checked={appointmentDetails.appointmentType === 'In Clinic'}
                          />
                        }
                        label={<OfficeVisitText />}
                      />
                    </MuiBox>
                  </MuiGrid>
                </MuiGrid>
              </>
            ) : null}
          </MuiBox>
        </MuiContainer>
      </FlexBox>

      <MuiBox pt={10}>
        {appointmentDetails?.groupedVisitType?.appointmentTypes?.length > 1 ? (
          <MuiBox
            position="absolute"
            right={0}
            left={0}
            mx="auto"
            maxWidth={275}
            bottom={theme.spacing(6) + stepActionsHeight}
          >
            <Button onPress={() => handleMoreInfoOpen(false)} variant="web-secondary" fullWidth>
              Need help choosing?
            </Button>
          </MuiBox>
        ) : null}

        <BookingStepActions>
          <BookingStepAction
            disabled={!appointmentDetails.appointmentType}
            onClick={handleLocationPreferenceSelect}
            data-testid="next-button"
          />
          <BookingStepAction data-testid="previous-button" onClick={handlePrevious} />
        </BookingStepActions>
      </MuiBox>

      <BookingLocationPreferenceMoreInfoDialog
        handleClose={() => setIsMoreInfoOpen(false)}
        open={isMoreInfoOpen}
      />
    </StyledScreen>
  );
}

const mapStateToProps = (state: RootState) => ({
  appointmentDetails: bookingSelectors.appointmentDetailsSelector(state),
  locations: bookingSelectors.groupedVisitTypeLocationsSelector(state),
  currentRouteName: routerSelectors.currentLocationPathNameSelector(state),
  previousRouteName: routerSelectors.previousLocationPathNameSelector(state)
});

const mapDispatchToProps = {
  setAppointmentType: bookingActions.setAppointmentType,
  setAppointmentLocation: bookingActions.setAppointmentLocation,
  setInitialTimeSlots: bookingActions.setInitialTimeSlots,
  getLocationTimeSlots: bookingActions.getLocationTimeSlots
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingLocationPreferenceSelect);
