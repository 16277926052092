import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { Color } from 'modules/styles/colors';
import { MuiBox, MuiGrid, MuiPaper, MuiTypography } from 'theme/material-ui';
import { profileInsurancesSelector } from 'store/profile/selectors';
import { Insurance as InsuranceType } from 'store/profile/types';
import { setProfileActiveInsurance } from 'store/profile/actions';
import { RootState } from 'store/types';
import analyticsService, { AnalyticsEvent } from 'services/AnalyticsService';

import { getInsuranceStatus } from './utils';

const MyInsuranceCard = ({
  insurance,
  setActiveInsurance
}: {
  insurance: InsuranceType;
  setActiveInsurance: (insurance: InsuranceType) => void;
}) => {
  const history = useHistory();

  const navigateToInsuranceDetail = (item: InsuranceType) => {
    analyticsService.logEvent(AnalyticsEvent.InsuranceCardClicked);
    setActiveInsurance(item);
    history.push('/u/manage-account/insurance-details');
  };

  return (
    <MuiBox component={MuiPaper} display="flex" flexDirection="row" my={2}>
      <MuiBox p={2} flex={1} onClick={() => navigateToInsuranceDetail(insurance)}>
        <MuiGrid container spacing={3}>
          <MuiGrid item xl={9} xs={12}>
            <MuiTypography fontWeight="300">
              <MuiTypography component="span" uppercase display="inline">
                Insurance
              </MuiTypography>{' '}
              | {insurance.insuranceName}
            </MuiTypography>

            <MuiTypography variant="h5">{insurance.subscriberName}</MuiTypography>

            {insurance.subscriberId ? (
              <MuiTypography color={Color.textLight} uppercase>
                Subscriber Id | {insurance.subscriberId}
              </MuiTypography>
            ) : null}

            {insurance.planId ? (
              <MuiTypography color={Color.textLight} uppercase>
                Plan Number | {insurance.planId}
              </MuiTypography>
            ) : null}

            <MuiTypography color={Color.textLight} uppercase>
              Status | {getInsuranceStatus(insurance)}
            </MuiTypography>
          </MuiGrid>

          {insurance.members ? (
            <MuiGrid item xl={3} xs={12}>
              <MuiBox
                display="flex"
                alignItems="flex-start"
                flexDirection="column"
                height="100%"
                justifyContent="center"
              >
                <MuiTypography fontWeight={600} uppercase>
                  Covered Members
                </MuiTypography>
                {insurance.members.map(member => (
                  <MuiTypography key={member.consumerId}>{member.displayName}</MuiTypography>
                ))}
              </MuiBox>
            </MuiGrid>
          ) : null}
        </MuiGrid>
      </MuiBox>
    </MuiBox>
  );
};

const mapStateToProps = (state: RootState) => ({
  insurances: profileInsurancesSelector(state)
});

const mapDispatchToProps = (dispatch: Function) => ({
  setActiveInsurance: (insurance: InsuranceType) => dispatch(setProfileActiveInsurance(insurance))
});

export default connect(mapStateToProps, mapDispatchToProps)(MyInsuranceCard);
