import React from 'react';

export default {
  svg: (
    <g id="Page-1">
      <g id="Artboard" transform="translate(-3.000000, -2.000000)">
        <g id="Group" transform="translate(3.000000, 2.000000)">
          <path
            id="Path"
            d="M40.5730377,17.862723 C41.2441729,17.4318812 42.1262039,17.9137908 42.1262039,18.7113164 L42.1262039,43.2407282 C42.1262039,43.797654 41.6747263,44.2491315 41.1178005,44.2491315 L1.00855685,44.2491315 C0.451631052,44.2491315 0.000153488592,43.797654 0.000153488592,43.2407282 L0.000153488592,18.7113164 C0.000153488592,17.913531 0.882697401,17.4316687 1.55380469,17.8630345 L21.0509162,30.3951402 L40.5730377,17.862723 Z M21.5953365,32.4422627 C21.2632399,32.6554554 20.8373005,32.6553337 20.5053258,32.4419512 L2.01696021,20.5582344 L2.01696021,42.2323248 L40.1093972,42.2323248 L40.1093972,20.5569801 L21.5953365,32.4422627 Z"
          />
          <path
            id="Path"
            d="M28.7305867,9.41096772 C29.1494808,9.77797464 29.7865794,9.73591177 30.1535863,9.31701767 C30.5205933,8.89812356 30.4785304,8.26102491 30.0596363,7.89401799 L21.0495675,-6.21724894e-15 L12.0655841,7.89501565 C11.647241,8.26265051 11.6061339,8.89981154 11.9737687,9.31815465 C12.3414036,9.73649776 12.9785646,9.77760492 13.3969077,9.40997007 L21.0515798,2.68313698 L28.7305867,9.41096772 Z"
          />
          <path
            id="Path"
            d="M34.1466377,14.2440678 L40.4743688,19.4877653 C40.9031897,19.8431228 41.5388919,19.783569 41.8942495,19.3547482 C42.249607,18.9259273 42.1900532,18.290225 41.7612323,17.9348675 L35.4335012,12.69117 C35.0046803,12.3358125 34.3689781,12.3953663 34.0136205,12.8241871 C33.658263,13.253008 33.7178168,13.8887103 34.1466377,14.2440678 Z"
          />
          <path
            id="Path"
            d="M1.64745044,19.4915038 L8.05081178,14.2478063 C8.48169737,13.8949551 8.54495674,13.2596109 8.19210556,12.8287253 C7.83925437,12.3978397 7.20391019,12.3345804 6.77302461,12.6874315 L0.369663261,17.931129 C-0.0612223214,18.2839802 -0.124481699,18.9193244 0.228369487,19.35021 C0.581220673,19.7810956 1.21656486,19.8443549 1.64745044,19.4915038 Z"
          />
          <path
            id="Path"
            d="M30.8320863,9.66089622 L30.8320863,23.980224 C30.8320863,24.5371498 31.2835638,24.9886273 31.8404896,24.9886273 C32.3974154,24.9886273 32.848893,24.5371498 32.848893,23.980224 L32.848893,7.6440895 L9.25225433,7.6440895 L9.25225433,23.980224 C9.25225433,24.5371498 9.70373189,24.9886273 10.2606577,24.9886273 C10.8175835,24.9886273 11.2690611,24.5371498 11.2690611,23.980224 L11.2690611,9.66089622 L30.8320863,9.66089622 Z"
          />
          <path
            id="Path"
            d="M15.9329266,16.2407282 L26.1682207,16.2407282 C26.7251465,16.2407282 27.1766241,15.7892506 27.1766241,15.2323248 C27.1766241,14.675399 26.7251465,14.2239214 26.1682207,14.2239214 L15.9329266,14.2239214 C15.3760008,14.2239214 14.9245232,14.675399 14.9245232,15.2323248 C14.9245232,15.7892506 15.3760008,16.2407282 15.9329266,16.2407282 Z"
          />
          <path
            id="Path"
            d="M15.9329266,19.4171987 L26.1682207,19.4171987 C26.7251465,19.4171987 27.1766241,18.9657212 27.1766241,18.4087954 C27.1766241,17.8518696 26.7251465,17.400392 26.1682207,17.400392 L15.9329266,17.400392 C15.3760008,17.400392 14.9245232,17.8518696 14.9245232,18.4087954 C14.9245232,18.9657212 15.3760008,19.4171987 15.9329266,19.4171987 Z"
          />
          <path
            id="Path"
            d="M15.9329266,22.5936693 L26.1682207,22.5936693 C26.7251465,22.5936693 27.1766241,22.1421918 27.1766241,21.585266 C27.1766241,21.0283402 26.7251465,20.5768626 26.1682207,20.5768626 L15.9329266,20.5768626 C15.3760008,20.5768626 14.9245232,21.0283402 14.9245232,21.585266 C14.9245232,22.1421918 15.3760008,22.5936693 15.9329266,22.5936693 Z"
          />
          <path
            id="Path"
            d="M15.9329266,25.7701399 L26.1682207,25.7701399 C26.7251465,25.7701399 27.1766241,25.3186624 27.1766241,24.7617366 C27.1766241,24.2048108 26.7251465,23.7533332 26.1682207,23.7533332 L15.9329266,23.7533332 C15.3760008,23.7533332 14.9245232,24.2048108 14.9245232,24.7617366 C14.9245232,25.3186624 15.3760008,25.7701399 15.9329266,25.7701399 Z"
          />
          <path
            id="Path"
            d="M15.9329266,28.9466105 L26.1682207,28.9466105 C26.7251465,28.9466105 27.1766241,28.4951329 27.1766241,27.9382071 C27.1766241,27.3812813 26.7251465,26.9298038 26.1682207,26.9298038 L15.9329266,26.9298038 C15.3760008,26.9298038 14.9245232,27.3812813 14.9245232,27.9382071 C14.9245232,28.4951329 15.3760008,28.9466105 15.9329266,28.9466105 Z"
          />
          <path
            id="Path"
            d="M1.52638934,44.1060182 L17.7868935,34.3749258 C18.26478,34.0889348 18.4203421,33.4696897 18.1343511,32.9918032 C17.8483601,32.5139167 17.2291151,32.3583547 16.7512286,32.6443457 L0.490724361,42.3754381 C0.012837868,42.6614291 -0.142724176,43.2806741 0.14326681,43.7585606 C0.429257797,44.2364471 1.04850285,44.3920092 1.52638934,44.1060182 Z"
          />
          <path
            id="Path"
            d="M41.635633,42.3754381 L25.3751288,32.6443457 C24.8972423,32.3583547 24.2779973,32.5139167 23.9920063,32.9918032 C23.7060153,33.4696897 23.8615774,34.0889348 24.3394639,34.3749258 L40.5999681,44.1060182 C41.0778546,44.3920092 41.6970996,44.2364471 41.9830906,43.7585606 C42.2690816,43.2806741 42.1135195,42.6614291 41.635633,42.3754381 Z"
          />
          <path
            id="Path"
            d="M3.32788458,24.887787 L3.32788458,34.1650979 C3.32788458,34.7220237 3.77936214,35.1735013 4.33628794,35.1735013 C4.89321374,35.1735013 5.3446913,34.7220237 5.3446913,34.1650979 L5.3446913,24.887787 C5.3446913,24.3308612 4.89321374,23.8793836 4.33628794,23.8793836 C3.77936214,23.8793836 3.32788458,24.3308612 3.32788458,24.887787 Z"
          />
        </g>
      </g>
    </g>
  ),
  viewBox: '0 0 43 45'
};
