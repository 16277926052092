import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Svg from 'components/UI/Svg/Svg';
import Box from 'components/UI/Layout/Box';
import {
  dfdDefaultTheme,
  MuiBox,
  MuiDialog,
  MuiTypography,
  MuiDivider,
  MuiButton
} from 'theme/material-ui';

import { GroupedVisitType } from 'store/booking/types';

import { Color } from 'modules/styles/colors';
import { IconSize, FontSize, FontWeight, Spacing } from 'modules/styles/variables';

import { CloseButton } from '../../components/styled';

interface Props {
  fullScreen?: boolean;
  handleClose: Function;
  open: boolean;
  secondScreen?: boolean;
  visitTypes: GroupedVisitType[];
}

function BookingVisitTypeSelectMoreInfoDialog({ handleClose, open, visitTypes }: Props) {
  const fullScreen = useMediaQuery(dfdDefaultTheme.breakpoints.down('sm'));

  return (
    <MuiDialog fullScreen={fullScreen} maxWidth="md" open={open} onClose={handleClose}>
      <MuiBox p={3} py={2} pl={8} pr={8}>
        <CloseButton onClick={handleClose}>
          <Svg name="CloseIcon" size={IconSize.medium} />
        </CloseButton>
        <MuiBox display="flex" justifyContent="center" py={1}>
          <Svg set="assets" name="InfoIcon" size={IconSize.xLarge} color={Color.primary} />
        </MuiBox>
        <MuiBox display="flex" justifyContent="center" py={2}>
          <Box vSpacing={Spacing.large}>
            <MuiTypography align="center" fontSize={FontSize.largeHeading}>
              More information about visit types
            </MuiTypography>
          </Box>
        </MuiBox>
        <MuiDivider />
        {visitTypes.map((item: string) => {
          return (
            <>
              <Box vSpacing={Spacing.xLarge - Spacing.small}>
                <MuiTypography
                  fontSize={FontSize.large}
                  fontWeight={FontWeight.semibold}
                  color={Color.black}
                >
                  {item.name}
                </MuiTypography>
                <MuiTypography fontSize={FontSize.large} color={Color.black}>
                  {item.description}
                </MuiTypography>
              </Box>
              <MuiDivider />
            </>
          );
        })}

        <MuiBox display="flex" pt={3} justifyContent="center">
          <MuiButton variant="contained" color="primary" fullWidth onClick={() => handleClose()}>
            Close
          </MuiButton>
        </MuiBox>
      </MuiBox>
    </MuiDialog>
  );
}

export default BookingVisitTypeSelectMoreInfoDialog;
