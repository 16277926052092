import React, { useState, useMemo, useEffect } from 'react';
import CollapsibleListItem from 'components/common/CollapsibleList/CollapsibleListItem';
import ManageAppointments from 'screens/Booking/ManageAppointments';
import { RootState } from 'store/types';
import { MuiPaper } from 'theme/material-ui';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import * as bookingTypes from 'store/booking/types';
import * as bookingSelectors from 'store/booking/selectors';
import orderBy from 'lodash/orderBy';
import * as accountSelectors from 'store/account/selectors';

interface GetCareAppointmentsListProps {
  upcoming: bookingTypes.BookedAppointment[];
  consumerId: string;
}

const getAppointmentSubtitle = (count: number) => {
  return `Book, View, or Modify ${count > 0 ? `(${count} Upcoming)` : ''}`;
};

export const GetCareAppointmentsList = ({ upcoming, consumerId }: GetCareAppointmentsListProps) => {
  const location = useLocation();
  const expandAppointments = location.state?.expandAppointments;
  const [bannerFilterBy, setBannerFilterBy] = useState('');
  useEffect(() => {
    setBannerFilterBy(consumerId);
  }, [consumerId]);
  const upcomingAppointments = useMemo(() => {
    let appts = upcoming;

    appts = upcoming.filter(appt => appt.consumerId === bannerFilterBy);

    return orderBy(appts, ['appointmentDate'], ['asc']);
  }, [upcoming, bannerFilterBy]);

  return (
    <CollapsibleListItem
      detailsPadding="0px"
      leftIcon="Appointment"
      title="Appointments"
      subtitle={getAppointmentSubtitle(upcomingAppointments.length)}
      sourceUrlForAnalytics="Get Care"
      expandOnFirstRender={expandAppointments}
    >
      <MuiPaper square elevation={1}>
        <ManageAppointments asScreen={false} />
      </MuiPaper>
    </CollapsibleListItem>
  );
};

const mapState = (state: RootState) => ({
  upcoming: bookingSelectors.upcomingAppointmentsDataSelector(state),
  consumerId: accountSelectors.currentAccountConsumerIdSelector(state)
});

export default connect(mapState)(GetCareAppointmentsList);
