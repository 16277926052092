import React from 'react';
import { FlexBoxRow } from 'components/UI/Layout/FlexBox';
import Typography from 'components/UI/Typography';
import Divider from 'components/UI/Divider';
import { ViewDrillDownFlexBox } from '../styled';
import formatMoney from 'services/formatMoney';
import { Color } from 'modules/styles/colors';
import { FontWeight } from 'modules/styles/variables';
import { Claims } from 'store/billing/types';

export default function ClaimItem(eachClaim: Claims) {
  const sumOfClaimPlanPaid = parseFloat(eachClaim.sumOfClaimPlanPaid);
  return (
    <>
      <ViewDrillDownFlexBox>
        <FlexBoxRow
          accessibilityLabel="Tap to view claims"
          alignItems="flex-start"
          justifyContent="space-between"
        >
          <Typography numberOfLines={1} fontWeight={FontWeight.bold}>
            Claim {eachClaim.claimNumber}
          </Typography>
          <Typography color={Color.red}>{eachClaim.claimStatus}</Typography>
        </FlexBoxRow>
        <FlexBoxRow alignItems="flex-start" justifyContent="space-between">
          <Typography numberOfLines={1}>{eachClaim.planName}</Typography>
          <Typography numberOfLines={1}>{`$${formatMoney(
            Math.abs(sumOfClaimPlanPaid)
          )}`}</Typography>
        </FlexBoxRow>
      </ViewDrillDownFlexBox>
      <Divider />
    </>
  );
}
