import React from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from 'theme/material-ui';
import Svg from 'components/UI/Svg/Svg';
import { navigationAllBreadCrumbsSelector } from 'store/navigation/selectors';
import Breadcrumb, { BreadcrumbProps } from './Breadcrumb';
import { BreadcrumbsWrapper } from './styled';

export interface Crumb extends BreadcrumbProps {
  crumbComponent?: React.ComponentType<BreadcrumbProps>;
}

export interface BreadcrumbsProps {
  crumbs: Crumb[];
}

function Breadcrumbs({ crumbs }: BreadcrumbsProps) {
  const theme = useTheme();
  const allCrumbs = useSelector(navigationAllBreadCrumbsSelector);
  const newCrumbs = allCrumbs.length > 0 ? allCrumbs?.[0] : crumbs;
  return (
    <BreadcrumbsWrapper
      separator={<Svg set="material" name="navigate-next" size={20} />}
      aria-label="breadcrumbs"
      spacing={theme.spacing(3)}
    >
      {newCrumbs.map(({ crumbComponent: CrumbComponent, ...rest }) =>
        CrumbComponent ? (
          <CrumbComponent key={rest.to} {...rest} />
        ) : (
          <Breadcrumb key={rest.to} {...rest} />
        )
      )}
    </BreadcrumbsWrapper>
  );
}

export default Breadcrumbs;
