import { HealthToolCardData } from 'components/HealthToolsCards/HealthToolCard';
import VyncaBanner from '../../assets/HealthTools/vyncaB-thumb.png';
import HealthToolsSurveyScreen from './Survey/HealthToolsSurveyScreen';
import { vyncaCard, vyncaRouteData } from './Cards/vynca';
import { myStrengthCard, myStrengthRouteData } from './Cards/myStrength';
import { diabetesCard, preventDiabetesRouteData } from './Cards/preventDiabetes';
import { RouteData } from 'screens/navigation';
import Config from 'react-native-config';

export const getHealthToolsData = variant => {
  const vyncaVariantBData: HealthToolCardData = {
    healthToolCardKey: 'vyncaB',
    description: 'Take Control with Advance Care Planning',
    path: '/u/health-tools/vynca',
    thumb: VyncaBanner
  };
  const toolsData: HealthToolCardData[] = [];

  if (Config.MYSTRENGTH_HEALTH_TOOL_ENABLED === 'enabled') {
    toolsData.push(myStrengthCard);
  }
  if (!variant) {
    toolsData.push(vyncaVariantBData);
  } else {
    toolsData.push(vyncaCard);
  }

  if (Config.PREDIABETES_HEALTH_TOOL_ENABLED === 'enabled') {
    toolsData.push(diabetesCard);
  }

  return toolsData;
};

const healthToolsSurveyRouteData: RouteData = {
  path: '/u/health-tools/survey',
  component: HealthToolsSurveyScreen,
  componentName: 'HealthToolsSurveyScreen',
  title: 'Health Tools Survey',
  crumbLabel: 'Health Tools Survey',
  exact: true,
  includeDefaultLayout: true,
  includeBanner: true,
  includeCrumbs: true,
  icon: 'HealthTool'
};

export const getScreensOfHealthTools = (): RouteData[] => {
  const screens: RouteData[] = [];
  screens.push(healthToolsSurveyRouteData);

  if (Config.MYSTRENGTH_HEALTH_TOOL_ENABLED === 'enabled') {
    screens.push(...myStrengthRouteData);
  }

  screens.push(...vyncaRouteData);

  if (Config.PREDIABETES_HEALTH_TOOL_ENABLED === 'enabled') {
    screens.push(...preventDiabetesRouteData);
  }

  return screens;
};
