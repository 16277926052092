import React, { useState } from 'react';
import { Dimensions } from 'react-native';
import CollapsibleListItem from 'components/common/CollapsibleList/CollapsibleListItem';
import { dfdDefaultTheme, MuiBox } from 'theme/material-ui';
import { FontSize, FontWeight } from 'modules/styles/variables';
import formatMoney from 'services/formatMoney';
import { Color } from 'modules/styles/colors';
import { formatDate } from 'modules/utils/DateUtils';
import Typography from 'components/UI/Typography';
import { ChargeGroupDisplay } from './types';

export default function ChargeItem(eachCharge: ChargeGroupDisplay) {
  const [expanded, setExpanded] = useState(false);
  const { width } = Dimensions.get('window');
  const largeView = width > dfdDefaultTheme.breakpoints.values.md;
  const isTotal = eachCharge.isTotal === true;
  const isExpandable = eachCharge.charges.length > 1;
  const alignLast = expanded || !isExpandable ? 'right' : 'left';
  const chargesDisplay = eachCharge.charges;
  const subtitle2 =
    isExpandable && !expanded
      ? `+${eachCharge.charges.length - 1} more`
      : `$${formatMoney(eachCharge.charges[0].amount)}`;
  const handleChange = (val: boolean) => {
    setExpanded(val);
  };

  return (
    <MuiBox key={eachCharge.description} borderBottom={`1px solid ${Color.grayLight}`}>
      <CollapsibleListItem
        title={eachCharge.description}
        subtitle={eachCharge.charges[0].description}
        subtitle2={subtitle2}
        titleExtension={!eachCharge.isTotal ? formatDate(eachCharge.charges[0].date) : null}
        leftIndent="25px"
        largeView={largeView}
        handleChange={handleChange}
        alignLast={alignLast}
        isExpandable={!isTotal}
        headerColorOnExpand={Color.grayLight2}
        overrideDisabledOpacity
        detailsPadding="0px"
      >
        {chargesDisplay.map(eachSubCharges => (
          <MuiBox
            key={eachSubCharges.description}
            display="flex"
            padding="12px 60px 12px 50px"
            borderTop={`1px solid ${Color.grayLight}`}
          >
            <MuiBox width="33%">
              <Typography numberOfLines={1} fontSize={FontSize.base}>{`${formatDate(
                eachSubCharges.date
              )}`}</Typography>
            </MuiBox>
            <MuiBox width="33%">
              <Typography numberOfLines={1} fontSize={FontSize.base}>
                {!!eachSubCharges.cptcode ? `(${eachSubCharges?.cptcode}) ` : null}
                {`${eachSubCharges.description}`}
              </Typography>
            </MuiBox>

            <MuiBox width="33%">
              <Typography
                textAlign="right"
                fontSize={FontSize.base}
                fontWeight={FontWeight.bold}
              >{`$${formatMoney(eachSubCharges.amount)}`}</Typography>
            </MuiBox>
          </MuiBox>
        ))}
      </CollapsibleListItem>
    </MuiBox>
  );
}
