import React from 'react';
import { useSelector } from 'react-redux';
import capitalize from 'lodash/capitalize';
import { currentAccountConsumerSelector } from 'store/account/selectors';
import { MuiBox } from 'theme/material-ui';
import { SliderBoxContainer } from 'components/DashboardCarousel/styled';
import FlexBox from 'components/UI/Layout/FlexBox';
import { DashboardTitle } from '../styled';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

interface Props {
  isLoading?: boolean;
}

const DashboardWelcomeCard = ({ isLoading }: Props) => {
  const currentConsumer = useSelector(currentAccountConsumerSelector);
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));
  const marginTop = isLargeScreen ? 230 : 50;
  const capitalizedName = capitalize(currentConsumer?.firstName || 'to My Health Plus');
  const welcomeTitle = isLoading ? 'Loading...' : `Welcome, ${capitalizedName}!`;

  return (
    <MuiBox
      data-testid="dashboard-welcome-card"
      display="flex"
      justifyContent="center"
      width="100%"
      minHeight={100}
    >
      <SliderBoxContainer>
        <FlexBox center vOffsetTop={marginTop} vOffsetBottom={20}>
          <DashboardTitle isLoading={isLoading}>{welcomeTitle}</DashboardTitle>
        </FlexBox>
      </SliderBoxContainer>
    </MuiBox>
  );
};

export default DashboardWelcomeCard;
