import React from 'react';
import capitalize from 'lodash/capitalize';
import isEmpty from 'lodash/isEmpty';
import { useHistory } from 'react-router-dom';

import { AnalyticsEvent } from 'services/AnalyticsService';
import useNavigationAnalytics from 'hooks/useNavigationAnalytics';
import { objectToQsParam } from 'modules/utils/UrlUtils';
import { MuiBox, MuiList, MuiPaper, MuiDivider } from 'theme/material-ui';
import { Medication } from 'store/medications/types';
import { HEALTH_RECORDS_DRAWERS, MEDICATION_LIST_DRAWERS } from 'lib/constants/healthRecords';

import CollapsibleListItem from 'components/common/CollapsibleList/CollapsibleListItem';
import { ListItem } from 'components/UI/ListItem/ListItem';
import MedicationsDetail from './MedicationsDetail';
import { convertToLowerKabobCase } from 'modules/utils/StringUtils';
import { IconSize } from 'modules/styles/variables';

interface MedicationsCollapsibleListItemProps {
  medications?: Medication[];
  isVisitSummary: boolean;
}

export const MedicationsCollapsibleListItemComponent = ({
  medications,
  isVisitSummary = false
}: MedicationsCollapsibleListItemProps) => {
  const history = useHistory();
  const previousItemClicked = useNavigationAnalytics(AnalyticsEvent.PastMedicationsListViewed);
  const currentItemClicked = useNavigationAnalytics(AnalyticsEvent.CurrentMedicationsListViewed);
  const medicationsItemClicked = useNavigationAnalytics(AnalyticsEvent.MedicationItemClicked);
  const icon = isVisitSummary ? undefined : 'MedicationListIcon';

  if (isVisitSummary && medications) {
    medications = medications.slice().sort((a, b) => a.name.localeCompare(b.name));
  }

  const handleMedicationSelect = (item: Medication) => {
    medicationsItemClicked();
    let url = `/u/health-record/medications/${item.id}`;
    if (!isEmpty(item.mhpDetails)) {
      url += `/${objectToQsParam(item.mhpDetails)}`;
    }

    history.push(url);
  };

  return (
    <CollapsibleListItem
      testID="MedicationsCollapsibleListItem"
      leftIcon={icon}
      title={HEALTH_RECORDS_DRAWERS.Medications.title(isVisitSummary)}
      subtitle={HEALTH_RECORDS_DRAWERS.Medications.subtitle()}
      analyticsEvent={AnalyticsEvent.MedicationListClicked}
      detailsPadding={isVisitSummary ? undefined : '0'}
    >
      <MuiBox width="100%">
        <MuiPaper elevation={2}>
          <MuiList>
            {medications &&
              medications.map(item => (
                <>
                  <ListItem
                    key={item.id}
                    icon="ChevronRight"
                    title={capitalize(item.name)}
                    onPress={() => handleMedicationSelect(item)}
                  />
                  <MedicationsDetail medication={item} />
                  <MuiDivider />
                </>
              ))}
            {MEDICATION_LIST_DRAWERS.map(item => (
              <ListItem
                testID={convertToLowerKabobCase(item.title)}
                key={item.title}
                icon={item.icon}
                iconSize={IconSize.xmedium}
                body={item.title}
                secondaryBody={item.body}
                onPress={() => {
                  const medType = item?.params?.medicationType;
                  if (medType === 'current') currentItemClicked();
                  if (medType === 'past') previousItemClicked();
                  history.push(item.webRoutePath);
                }}
              />
            ))}
          </MuiList>
        </MuiPaper>
      </MuiBox>
    </CollapsibleListItem>
  );
};

MedicationsCollapsibleListItemComponent.defaultProps = {
  isVisitSummary: false
};

export default MedicationsCollapsibleListItemComponent;
