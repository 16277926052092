import styled from 'styled-components';
import { MuiButton, MuiTypography, dfdDefaultTheme } from 'theme/material-ui';
import { Color } from 'modules/styles/colors';

export const StyledMuiButton = styled(MuiButton)`
  && {
    background-color: ${props => (props && props.bgColor) || Color.white};
    color: ${props => (props && props.bgColor ? Color.white : Color.black)};
  }

  &&:hover {
    background-color: ${props => (props && props.bgColor) || Color.white};
    color: ${props => (props && props.bgColor ? Color.white : Color.black)};
  }
`;

export const CheckboxBadge = styled.div`
  display: flex;
  box-sizing: border-box;
  height: 36px;
  border: 1px solid #cccccc;
  border-radius: 4px;
  background-color: #ffffff;
  margin-left: 8px;
  padding-right: 16px;
  padding-left: 8px;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
`;

export const CloseButton = styled.div`
  position: absolute;
  right: 8px;
  top: 18px;

  display: flex;
  justify-content: center;
  flex-shrink: 0;

  height: 30px;
  width: 30px;
  background-color: #7e4ef2;
  border-radius: 100px;
  align-items: center;
  cursor: pointer;
`;

export const ShortMessage = styled(MuiTypography)`
  width: 180px;
  margin-right: auto;
  align-self: center;
`;

export const Message = styled(MuiTypography)`
  display: none;
  margin-right: auto;
  align-self: center;
`;

export const Row = styled.div`
  display: flex;
  margin-right: auto;

  ${dfdDefaultTheme.breakpoints.up('lg')} {
    ${Message} {
      display: initial;
    }
    ${ShortMessage} {
      display: none;
    }
  }
`;
