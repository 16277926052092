import { AuthLiteHeader, AuthLiteLogin } from 'components/AuthLite';
import { CheckInForm } from 'components/AuthLite/types';
import Spacer from 'components/UI/Layout/Spacer';
import React, { ComponentType, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import jsonwebtoken from 'jsonwebtoken';
import { objectToQsParam } from 'modules/utils/UrlUtils';
import dayjs from 'dayjs';
import { MuiBox, MuiTypography } from 'theme/material-ui';
import analyticsService, { AmplitudeEventData, AnalyticsEvent } from 'services/AnalyticsService';
import { GFE_LINK_LOGIN, MY_HEALTH_PLUS_DOWNLOAD_SPLASH } from '../constants';
import { useHistory, useParams } from 'react-router-dom';
import { authLiteLogin } from 'store/authentication/actions';
import { RootState } from 'store/types';
import * as routeSelectors from 'store/router/selectors';
import { AuthLiteResponse } from 'store/authentication/types';
import { getErrorCode } from 'services/api/utils';
import ConfirmModal from 'components/UI/Modals/ConfirmModal';
import { Svg } from 'components/UI/Svg';
import { Alert } from 'components/Alert';
import { Color } from 'modules/styles/colors';
import { FontWeight } from 'modules/styles/variables';
import { AppName, getAppName } from 'modules/utils/ConfigUtils';

const confirmLabel = (
  <>
    <MuiTypography fontWeight={FontWeight.semibold} color={Color.white}>
      Continue to
    </MuiTypography>
    <MuiBox paddingLeft={0.5} paddingTop={0.5}>
      <Svg name="HeaderMyHealthPlus" width={75} color={Color.white} />
    </MuiBox>
  </>
);

interface Props {
  loading: boolean;
  response?: AuthLiteResponse;
  error?: Error;
  currentUrl: string;
  referringUrl: string;
}
const sixtyMinutes = 1000 * 60 * 60;

interface CustomResponse extends Response {
  payload: {
    config: {};
    data: {
      mhpDetails: {};
      accessToken: string;
      clinicalEventId: string;
      consumerId: string;
      dfdEventId: string;
      personId: string;
    };
  };
}

export function GoodFaithEstimateAuthLiteLogin({ currentUrl, referringUrl }: Props) {
  const now = Date.now();
  const { eventId } = useParams<{ eventId: string }>();
  const storageKey = `authLiteAttempts:${eventId}`;
  const authLiteAttempts = window.localStorage.getItem(storageKey);
  const attemptsArray: number[] = (authLiteAttempts ? JSON.parse(authLiteAttempts) : []).filter(
    (attempt: number) => attempt + sixtyMinutes > now
  );

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [attempts, setAttempts] = useState(attemptsArray.length);
  const history = useHistory();

  const navToDownloadSplash = () => {
    const eventData: AmplitudeEventData = {
      currentUrl,
      referringUrl,

      promotion_type: 'Good Faith Estimate light authentication: failed authentication'
    };
    analyticsService.logEvent(AnalyticsEvent.PromotionLink, eventData);

    window.open(MY_HEALTH_PLUS_DOWNLOAD_SPLASH);
  };

  const submitCheckIn = async (values: CheckInForm) => {
    const processedValues = {
      ...values,
      dob: dayjs(values.dob).format('YYYY-MM-DD'),
      lastName: values.lastName?.trim()
    };
    try {
      if (!loading) {
        setLoading(true);
        const res: CustomResponse = await dispatch(
          authLiteLogin({ ...processedValues, eventId, scope: 'ClinicalEvents' })
        );
        setLoading(false);
        if (res.payload?.data?.clinicalEventId) {
          const resultJwt = jsonwebtoken.decode(res.payload?.data?.accessToken, { complete: true });
          analyticsService.identifyUser({
            consumer: {
              consumerId: res.payload?.data?.consumerId,
              lastName: resultJwt.payload.user.lastName
            }
          });
          history.push(`/al/good-faith-estimate/${objectToQsParam(res.payload?.data?.mhpDetails)}`);
        }

        if (res.error) {
          const code = getErrorCode(res.error);
          if (code === 'AL429' || code === '429') {
            // If the user has already failed 3 times in one browser, but tries again in another
            // browswer, this will catch that case and reset their attempts to three, blocking them
            // from continuting in that browser as well.
            setAttempts(3);
            Array(3)
              .fill(Date.now())
              .forEach((att: number) => {
                attemptsArray.push(att);
              });
            window.localStorage.setItem(storageKey, JSON.stringify(attemptsArray));
            return;
          }
          if (code === '401') {
            const attemptsString = attempts + 1 > 1 ? `${attempts + 1} attempts` : '1 attempt';
            const eventData: AmplitudeEventData = {
              currentUrl,
              referringUrl,
              action: 'Good Faith Estimate light authentication error',
              errorCode: 'N/A',
              errorMessage: `Good Faith Estimate light authentication error: Invalid credentials: ${attemptsString}`
            };
            analyticsService.logEvent(AnalyticsEvent.ErrorTracking, eventData);

            attemptsArray.push(Date.now());
            window.localStorage.setItem(storageKey, JSON.stringify(attemptsArray));
            setAttempts(attempts + 1);
          } else {
            window.localStorage.setItem(storageKey, JSON.stringify(attemptsArray));
            Alert.alert('Login Failed', 'Please try again later');
          }
        } else {
          window.localStorage.setItem(storageKey, '');
        }
      }
    } catch (err) {
      // Note: This should not happen. If it does there is a hard crash. Expected errors will not get here.
      Alert.alert('Login Failed', 'Please try again later');
    }
  };

  return (
    <MuiBox flexDirection="column" alignContent="center" data-testid="auth-lite-event-login">
      <ConfirmModal
        showCloseIcon
        maxWidth="400px"
        fullWidth
        position="bottom"
        style={{ padding: 0 }}
        isOpen={attempts >= 3}
        icon="ExclamationCircle"
        data-testid="login-attempts-exceeded"
        title="Too many login attempts"
        cancelLabel=""
        onCancel={navToDownloadSplash}
        description={`To protect your medical information this login has been locked for 60 minutes. Please try again later. For long term access to your medical records, try out 
        ${getAppName(AppName.base, 'the', AppName.suffix5)}`}
        onConfirm={navToDownloadSplash}
        confirmLabel={confirmLabel}
      />
      <AuthLiteHeader />
      <MuiBox m={2}>
        <Spacer size="medium" />
        <MuiTypography variant="h4">{GFE_LINK_LOGIN.subTitle}</MuiTypography>
      </MuiBox>
      <AuthLiteLogin
        submitCheckIn={submitCheckIn}
        attempts={attempts}
        loading={loading}
        message={GFE_LINK_LOGIN.content}
        submitText="Next"
      />
    </MuiBox>
  );
}

const mapStateToProps = (state: RootState) => ({
  currentUrl: routeSelectors.currentLocationPathNameSelector(state),
  referringUrl: routeSelectors.previousLocationPathNameSelector(state)
});
export default connect(mapStateToProps)(GoodFaithEstimateAuthLiteLogin as ComponentType);
