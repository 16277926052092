import styled, { css } from 'styled-components';
import { MuiBreadcrumbs } from 'theme/material-ui';
import { Color } from 'modules/styles/colors';
import { FontSize } from 'modules/styles/variables';

interface BreadcrumbsWrapperProps {
  spacing: number;
}

interface BreadcrumbWrapperProps {
  spacing: number;
}

const PRIMARY = Color.primary;
const BACKGROUND = Color.white;
const BORDER = '1px solid rgba(0,0,0,0.1)';
const FONT_SIZE = FontSize.small;

const getPadding = (props: BreadcrumbsWrapperProps) =>
  props.spacing &&
  css`
    padding: ${props.spacing / 2}px ${props.spacing}px;
  `;

export const BreadcrumbsWrapper = styled(MuiBreadcrumbs)<BreadcrumbsWrapperProps>`
  && {
    background-color: ${BACKGROUND};
    border-top: ${BORDER};
    ${getPadding};

    p {
      font-size: ${FONT_SIZE}px;
      color: ${PRIMARY};
    }
    path {
      fill: ${PRIMARY};
    }
  }
`;

const getGutter = (props: BreadcrumbWrapperProps) =>
  props.spacing &&
  css`
    margin-left: -${props.spacing}px;
    margin-right: -${props.spacing}px;

    li {
      margin-left: ${props.spacing}px;
      margin-right: ${props.spacing}px;
    }
  `;

export const BreadcrumbWrapper = styled.div<BreadcrumbWrapperProps>`
  display: flex;
  align-items: center;
  ${getGutter};
`;
