import React from 'react';
import { MuiBox } from 'theme/material-ui';
import useStyles from '../useStyles';

export interface ConnectCareStepActionsProps {
  children: React.ReactNode;
}

function ConnectCareStepActions({ children }: ConnectCareStepActionsProps) {
  const classes = useStyles();

  return (
    <MuiBox className={classes.steppersFooter}>
      {React.Children.map(children, (child, index) => {
        if (!React.isValidElement(child)) return null;

        return React.cloneElement(child, {
          ...child.props,
          variant: index === 0 ? 'previous' : 'next'
        });
      })}
    </MuiBox>
  );
}

export default ConnectCareStepActions;
