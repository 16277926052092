import React from 'react';
import { TextInputProps as CustomTextInputProps } from './types';
import InputContainer from './InputContainer';

const MaskedTextInput = React.forwardRef((props: CustomTextInputProps) => {
  return (
    <InputContainer {...props}>
      {/* <TextInputMask
        ref={ref}
        customTextInput={StyledInput}
        customTextInputProps={{
          textAlignVertical: props.multiline ? "top" : "auto"
        }}
        {...props}
      /> */}
    </InputContainer>
  );
});

export default MaskedTextInput;
