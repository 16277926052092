import React from 'react';

export default {
  svg: (
    <g>
      <path
        d="M242.31,87.33,209.07,71.08a23.51,23.51,0,0,0-25.3,39.22l-26.29,53.78h0l-23.73,48.55a24.75,24.75,0,1,0,44.47,21.74l41.69-85.29h0l8.51-17.41a23.5,23.5,0,0,0,13.89-44.34ZM164.74,227.78a9.75,9.75,0,1,1-17.52-8.56l27-55.14h21.7Zm74.88-115.61a8.38,8.38,0,0,1-4.88,4.29,8.46,8.46,0,0,1-6.48-.38l-7.19-3.52-17.85,36.52H181.51l22-45.08L195,99.83a8.5,8.5,0,0,1,7.46-15.28l33.24,16.25A8.51,8.51,0,0,1,239.62,112.17ZM85.76,69H115V84H100.76v146H115v15H85.76V163.8h-18v-15h18Z"
        fillRule="evenodd"
      />
    </g>
  ),
  viewBox: '0 0 300 300'
};
