import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import { RootState } from 'store/types';
import { Appointment, Consumer, Provider, State } from 'store/amwell/types';
import * as selectors from 'store/amwell/selectors';

import {
  MuiContainer,
  MuiRadioGroup,
  MuiRadio,
  MuiFormControlLabel,
  MuiButtonGroup,
  MuiButton,
  MuiBox
} from 'theme/material-ui';

import { Color } from 'modules/styles/colors';
import { Spacing } from 'modules/styles/variables';
import { toSentenceCase } from 'modules/utils/StringUtils';

import { ConnectCareAccordion, ConnectCareSelect } from 'components/ConnectCare';
import { Confirm } from 'components/ConfirmDialog/ConfirmDialog';
import { SpinnerOverlay } from 'components/UI/Spinner/SpinnerModal';

import { useConnectCareNavigator } from 'lib/hooks/useConnectCareNavigator';

import { FormScreen } from '../styled';
import { awData, getSelectionId } from '../utils';

dayjs.extend(relativeTime);

const FormGroup = styled.form`
  flex: 1;
  margin-top: -${Spacing.large}px;
  margin-bottom: -${Spacing.large}px;

  & .MuiTextField-root {
    margin-top: ${Spacing.large}px;
    margin-bottom: ${Spacing.large}px;
  }
`;

export interface Props {
  requirements: { [key: string]: boolean };
}

enum NavigatorKey {
  NEW = 'new',
  APPOINTMENT = 'appointment'
}

interface NavigatorField {
  key: string;
  label: string;
  value: any;
  items: any[];
  itemToLabel: (item: any) => string;
}

interface NavigatorValues {
  title: string;
  generate: () => void;
  disabled: boolean;
  fields: NavigatorField[];
}

const findById = (items: any[], id: string) => {
  return items.find(item => getSelectionId(item) === id);
};

export function ConnectCareNavigator({ requirements }: Props) {
  const [navigatorKey, setNavigatorKey] = useState(NavigatorKey.NEW);
  const [generating, setGenerating] = useState(false);

  const [state, set] = useConnectCareNavigator();

  state.appointment = awData(state.appointment);
  state.activeConsumer = awData(state.activeConsumer);

  const [{ appointment, location, activeConsumer, provider }, setFields] = useState<{
    appointment: Appointment | null;
    location: State | null;
    activeConsumer: Consumer | null;
    provider: Provider | null;
  }>({
    appointment: null,
    location: null,
    activeConsumer: null,
    provider: null
  });

  useEffect(() => {
    if (state.appointment) {
      setFields(prevState => ({ ...prevState, appointment: state.appointment }));
    }
    if (state.location) {
      setFields(prevState => ({ ...prevState, location: state.location }));
    }
    if (state.activeConsumer) {
      setFields(prevState => ({ ...prevState, activeConsumer: state.activeConsumer }));
    }
    if (state.provider) {
      setFields(prevState => ({ ...prevState, provider: state.provider }));
    }
  }, [state.appointment, state.location, state.activeConsumer, state.provider]);

  useEffect(() => {
    if (state.consumer && location) {
      try {
        if (location.code !== state.consumer.legalResidence?.code) {
          set.location(location);
        }
      } catch (error) {
        Confirm.show(
          'Location Update Error',
          'Something went wrong updating the location.',
          'error'
        );
      }
    }
  }, [location]);

  const newVisit = async () => {
    if (!state.consumer) return;

    try {
      setGenerating(true);

      if (state.activeConsumer !== activeConsumer) {
        await set.consumer(activeConsumer);
      }

      if (state.provider !== provider) {
        await set.provider(provider);
      }

      if (!state.visitContext || state.provider !== provider) {
        await set.visitContext.new({
          callbackNumber: state.consumer.phone,
          disclaimersAcknowledged: true
        });
      }

      setGenerating(false);
      Confirm.show('New Visit Success', 'Successfully generated a new visit.', 'success');
    } catch (error) {
      setGenerating(false);
      Confirm.show('New Visit Error', 'Something went wrong generating a new visit.', 'error');
    }
  };

  const appointmentVisit = async () => {
    if (!state.consumer) return;

    try {
      setGenerating(true);

      if (state.activeConsumer?.isDependent) {
        await set.consumer(state.consumer);
      }

      if (state.appointment !== appointment) {
        await set.visitContext.appointment(appointment, {
          callbackNumber: state.consumer.phone,
          disclaimersAcknowledged: true
        });
      }

      setGenerating(false);
      Confirm.show(
        'Appointment Visit Success',
        'Successfully generated an appointment visit.',
        'success'
      );
    } catch (error) {
      setGenerating(false);
      Confirm.show(
        'Appointment Visit Error',
        'Something went wrong generating an appointment visit.',
        'error'
      );
    }
  };

  const navigator: Record<NavigatorKey, NavigatorValues> = {
    [NavigatorKey.NEW]: {
      title: 'New Visit',
      generate: newVisit,
      disabled: !location || !activeConsumer || !provider,
      fields: [
        {
          key: 'location',
          label: 'Select Location',
          value: location,
          items: state.usStates,
          itemToLabel: (item: State) => (item ? item.name : '')
        },
        {
          key: 'activeConsumer',
          label: 'Select Patient',
          value: activeConsumer,
          items: state.patients,
          itemToLabel: (item: Consumer) => (item ? item.fullName : '')
        },
        {
          key: 'provider',
          label: 'Select Provider',
          value: provider,
          items: state.providers,
          itemToLabel: (item: Provider) =>
            item ? `${item.fullName}${item.practice.name ? `: ${item.practice.name}` : ''}` : ''
        }
      ]
    },
    [NavigatorKey.APPOINTMENT]: {
      title: 'Appointment Visit',
      generate: appointmentVisit,
      disabled: !location || !appointment,
      fields: [
        {
          key: 'appointment',
          label: 'Select Appointment',
          value: appointment,
          items: state.appointments,
          itemToLabel: (item: Appointment) => {
            if (!item) return '';
            const startTime = dayjs(item.schedule.scheduledStartTime).format('MMMM D, YYYY, h:mma');
            const assignedProvider = item.assignedProvider.fullName;
            return `${assignedProvider}: ${startTime}`;
          }
        },
        {
          key: 'location',
          label: 'Select Location',
          value: location,
          items: state.usStates,
          itemToLabel: (item: State) => (item ? item.name : '')
        }
      ]
    }
  };

  const routeDisabled = (conditions: Partial<Record<'new' | 'appointment', boolean>>) => {
    switch (navigatorKey) {
      case NavigatorKey.NEW:
        return typeof conditions.new === 'boolean' ? conditions.new : true;
      case NavigatorKey.APPOINTMENT:
        return typeof conditions.appointment === 'boolean' ? conditions.appointment : true;
      default:
        return true;
    }
  };

  const connectCareSessionRoutes = [
    {
      path: '/u/get-care-now/connect-care',
      componentName: 'ConnectCareHome',
      title: 'Connect Care Home',
      deps: [],
      disabled: false
    },
    {
      path: `/u/get-care-now/connect-care/appointments/${appointment?.sourceId}`,
      componentName: 'ConnectCareAppointmentDetails',
      title: 'Connect Care Appointment Details',
      deps: [],
      disabled: routeDisabled({ appointment: !appointment })
    }
  ];

  const connectCareFormRoutes = [
    {
      path: '/u/get-care-now/connect-care/form/location',
      title: 'Connect Care Location',
      deps: [],
      params: navigatorKey === NavigatorKey.APPOINTMENT ? { fromAppointment: true } : {}
    },
    {
      path: '/u/get-care-now/connect-care/form/patients',
      title: 'Connect Care Patients',
      deps: ['hasLocation'],
      disabled: routeDisabled({ appointment: true, new: false })
    },
    {
      path: '/u/get-care-now/connect-care/form/providers',
      title: 'Connect Care Providers',
      deps: ['hasLocation', 'hasActiveConsumer'],
      disabled: routeDisabled({ appointment: true, new: false })
    },
    {
      path: '/u/get-care-now/connect-care/form/medical-history',
      title: 'Connect Care Medical History',
      deps: ['hasLocation', 'hasActiveConsumer', 'hasProviderOrSpecialty'],
      disabled: routeDisabled({ appointment: true, new: false })
    },
    {
      path: '/u/get-care-now/connect-care/form/medications',
      title: 'Connect Care Medications',
      deps: ['hasLocation', 'hasActiveConsumer', 'hasProviderOrSpecialty'],
      disabled: routeDisabled({ appointment: true, new: false })
    },
    {
      path: '/u/get-care-now/connect-care/form/vitals',
      title: 'Connect Care Vitals',
      deps: ['hasLocation', 'hasActiveConsumer', 'hasProviderOrSpecialty'],
      disabled: routeDisabled({ appointment: true, new: false })
    },
    {
      path: '/u/get-care-now/connect-care/form/health-documents',
      title: 'Connect Care Share Images & Files',
      deps: ['hasLocation', 'hasActiveConsumer', 'hasProviderOrSpecialty'],
      disabled: routeDisabled({ appointment: true, new: false })
    },
    {
      path: '/u/get-care-now/connect-care/form/your-visit',
      title: 'Connect Care Your Visit',
      deps: ['hasLocation', 'hasActiveConsumer', 'hasProviderOrSpecialty'],
      disabled: routeDisabled({ appointment: true, new: false })
    },
    {
      path: '/u/get-care-now/connect-care/form/insurance',
      title: 'Connect Care Select Insurance',
      deps: ['hasLocation', 'hasActiveConsumer', 'hasProviderOrSpecialty', 'hasVisitContext'],
      disabled: routeDisabled({ appointment: true, new: false })
    },
    {
      path: '/u/get-care-now/connect-care/form/pharmacy',
      title: 'Connect Care Pharmacies',
      deps: ['hasLocation', 'hasActiveConsumer', 'hasProviderOrSpecialty', 'hasVisitContext'],
      disabled: routeDisabled({ appointment: true, new: false })
    },
    {
      path: '/u/get-care-now/connect-care/form/payment-information',
      title: 'Connect Care Payment Information',
      deps: ['hasLocation', 'hasActiveConsumer', 'hasProviderOrSpecialty', 'hasVisitContext']
    }
  ];

  const screens = [...connectCareSessionRoutes, ...connectCareFormRoutes];

  if (state.loading) {
    return (
      <SpinnerOverlay
        testID="connect-care-navigator-loading"
        isLoading
        position="absolute"
        backgroundColor={Color.translucent}
      />
    );
  }

  return (
    <FormScreen>
      <MuiContainer maxWidth="lg">
        <MuiBox my={3}>
          <ConnectCareAccordion title="Visit Type" value={navigator[navigatorKey].title}>
            <MuiRadioGroup
              value={navigatorKey}
              onChange={e => setNavigatorKey(e.target.value as NavigatorKey)}
            >
              {(Object.keys(navigator) as Array<keyof typeof navigator>).map(key => (
                <MuiFormControlLabel
                  key={key}
                  value={key}
                  control={<MuiRadio />}
                  label={navigator[key].title}
                />
              ))}
            </MuiRadioGroup>
          </ConnectCareAccordion>
          <ConnectCareAccordion
            title="Required Information"
            defaultExpanded={!!navigator[navigatorKey]}
            disabled={!navigator[navigatorKey]}
            actions={[
              {
                title: `Generate ${navigator[navigatorKey].title}`,
                onClick: navigator[navigatorKey].generate,
                disabled: navigator[navigatorKey].disabled
              }
            ]}
          >
            <FormGroup noValidate autoComplete="off">
              {navigator[navigatorKey].fields.map((field: NavigatorField) => (
                <ConnectCareSelect
                  key={field.label}
                  label={field.label}
                  value={field.value}
                  onChange={e => {
                    const value = findById(field.items, e.target.value);
                    setFields(prev => ({ ...prev, [field.key]: value }));
                  }}
                  items={field.items}
                  itemToLabel={field.itemToLabel}
                  itemToValue={getSelectionId}
                />
              ))}
            </FormGroup>
          </ConnectCareAccordion>
          <ConnectCareAccordion
            title="Routes"
            defaultExpanded={!!navigator[navigatorKey]}
            disabled={!navigator[navigatorKey]}
          >
            <MuiButtonGroup orientation="vertical" color="primary" fullWidth>
              {screens.map(screen => {
                const disabled = screen.disabled || screen.deps.some(dep => !requirements[dep]);
                return (
                  <MuiButton
                    key={screen.path}
                    disabled={disabled}
                    component={Link}
                    to={screen.path}
                  >
                    {toSentenceCase(screen.title)}
                  </MuiButton>
                );
              })}
            </MuiButtonGroup>
          </ConnectCareAccordion>
        </MuiBox>
      </MuiContainer>
      <SpinnerOverlay
        isLoading={generating}
        backgroundColor={Color.translucent}
        position="absolute"
        testID="cc-navigator-loading"
      />
    </FormScreen>
  );
}

const mapStateToProps = (state: RootState) => ({
  requirements: selectors.visitRequirementsSelector(state)
});

export default connect(mapStateToProps)(ConnectCareNavigator);
