import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useFormikContext, FormikValues } from 'formik';

import RequiredFieldsLegend, { StyledAsterisk } from 'components/RequiredFieldsLegend';
import { ConnectCareForm, ConnectCareDataLoader } from 'components/ConnectCare';
import SnackAlert from 'components/Snack/SnackAlert';
import {
  MuiBox,
  MuiDivider,
  MuiFormControl,
  MuiFormControlLabel,
  MuiGrid,
  MuiRadio,
  MuiRadioGroup,
  MuiTypography,
  MuiPaper
} from 'theme/material-ui';

import { insurance } from 'modules/constants/amwell';
import { healthPlanNotListed } from 'modules/constants/ConnectCare';
import { Spacing } from 'modules/styles/variables.web';
import { toSentenceCase } from 'modules/utils/StringUtils';

import { Consumer, HealthPlan, RelationshipToSubscriberCode } from 'store/amwell/types';

import { getSelectionId } from '../utils';

export interface Props {
  consumer: Consumer | null;
  activeConsumer: Consumer | null;
  healthPlans: HealthPlan[];
  relationshipsToSubscriber: RelationshipToSubscriberCode[];
  loading: boolean;
  primarySubscriberSelection: string;
  setPrimarySubscriberSelection: (selection: string) => void;
  usePrimarySubscriberInfoSelection: string;
  setUsePrimarySubscriberInfoSelection: (selection: string) => void;
  skipValue: string;
  isAutoFilled: boolean;
}

function ConnectCareInsuranceSelectInsuranceForm(props: Props) {
  const {
    healthPlans,
    relationshipsToSubscriber,
    loading,
    consumer,
    activeConsumer,
    primarySubscriberSelection,
    setPrimarySubscriberSelection,
    usePrimarySubscriberInfoSelection,
    setUsePrimarySubscriberInfoSelection,
    skipValue,
    isAutoFilled
  } = props;
  const {
    setFieldValue,
    values: { healthPlan }
  } = useFormikContext<FormikValues>();
  const formattedName = toSentenceCase(activeConsumer?.firstName);
  const isDependent = Number(activeConsumer?.age) < 18;
  const [isTouched, setIsTouched] = useState(false);

  useEffect(() => {
    if (healthPlan === 'notListedItem') {
      setFieldValue('option', skipValue);
      setFieldValue('healthPlan', null);
    }
  }, [healthPlan]);

  return (
    <>
      <ConnectCareDataLoader
        data={{ healthPlans, relationshipsToSubscriber }}
        loading={loading}
        renderData={({ healthPlans, relationshipsToSubscriber }) => (
          <>
            <MuiPaper elevation={2}>
              <MuiBox position="absolute" right={Spacing.large} top={Spacing.medium}>
                <RequiredFieldsLegend />
              </MuiBox>
              <MuiBox py={3} px={4} mb={3}>
                <MuiBox mb={4}>
                  <MuiBox pb={1}>
                    <MuiTypography variant="subtitle2">Health Plan Information</MuiTypography>
                  </MuiBox>
                  <MuiDivider />
                </MuiBox>
                <MuiGrid container spacing={3} alignItems="flex-start">
                  <MuiGrid item xs={12} sm={6}>
                    <MuiBox display="flex" flexDirection="row" pb={1}>
                      <MuiTypography>
                        Select {formattedName}'s Health Plan <StyledAsterisk />
                      </MuiTypography>
                    </MuiBox>
                    <ConnectCareForm.Select
                      required
                      name="healthPlan"
                      placeholder="Health Plan"
                      items={[...healthPlans, healthPlanNotListed]}
                      itemToLabel={item => (item ? item.name : '')}
                      itemToValue={item => getSelectionId(item)}
                      onClose={() => setIsTouched(true)}
                    />
                  </MuiGrid>
                  <MuiGrid item xs={12} sm={6}>
                    <MuiBox display="flex" flexDirection="row" pb={1}>
                      <MuiTypography>
                        Subscriber ID <StyledAsterisk />
                      </MuiTypography>
                    </MuiBox>
                    <ConnectCareForm.TextField
                      required
                      name="subscriberId"
                      placeholder="Subscriber ID"
                      InputProps={{
                        inputProps: { maxLength: 20 }
                      }}
                      onBlur={() => setIsTouched(true)}
                    />
                  </MuiGrid>
                  {isAutoFilled && !isTouched && healthPlan ? (
                    <MuiGrid item xs={12}>
                      <SnackAlert
                        id="insurance-auto-filled"
                        message="Insurance Successfully Auto-Filled"
                        severity="success"
                      />
                    </MuiGrid>
                  ) : null}
                </MuiGrid>
              </MuiBox>
            </MuiPaper>
            <MuiPaper elevation={2}>
              <MuiBox py={3} px={4} mb={3}>
                <MuiGrid container spacing={3}>
                  <MuiGrid item xs={12} sm={6}>
                    <MuiBox mt={1} mb={2}>
                      <MuiTypography>
                        Is {formattedName} the primary subscriber? <StyledAsterisk />
                      </MuiTypography>
                    </MuiBox>
                    <MuiBox ml={1}>
                      <MuiFormControl component="fieldset">
                        <MuiRadioGroup
                          row
                          value={primarySubscriberSelection}
                          onChange={(e: React.ChangeEvent<any>) => {
                            setPrimarySubscriberSelection(e.target.value);
                            if (e.target.value === 'Yes' && activeConsumer) {
                              // clear value of second radio button set
                              setUsePrimarySubscriberInfoSelection('');
                              setFieldValue('relationshipToSubscriber', 'Self');
                              setFieldValue('primarySubscriberFirstName', activeConsumer.firstName);
                              setFieldValue('primarySubscriberLastName', activeConsumer.lastName);
                              setFieldValue(
                                'primarySubscriberDateOfBirth',
                                dayjs(activeConsumer.dob)
                              );
                            } else {
                              setFieldValue('relationshipToSubscriber', '');
                              setFieldValue('primarySubscriberFirstName', '');
                              setFieldValue('primarySubscriberLastName', '');
                              setFieldValue('primarySubscriberDateOfBirth', '');
                            }
                          }}
                        >
                          {insurance.IS_PRIMARY_SUBSCRIBER_OPTIONS.map(option => {
                            return (
                              <MuiBox key={option.value} mr={4}>
                                <MuiFormControlLabel
                                  value={option.value}
                                  control={<MuiRadio color="secondary" />}
                                  label={option.label}
                                />
                              </MuiBox>
                            );
                          })}
                        </MuiRadioGroup>
                      </MuiFormControl>
                    </MuiBox>
                  </MuiGrid>
                  {isDependent && primarySubscriberSelection === 'No' ? (
                    <MuiGrid item xs={12} sm={6}>
                      <MuiBox mt={1} mb={2}>
                        <MuiTypography>
                          Do you want to use your info as primary subscriber for {formattedName}
                          <StyledAsterisk />
                        </MuiTypography>
                      </MuiBox>
                      <MuiBox ml={1}>
                        <MuiFormControl component="fieldset">
                          <MuiRadioGroup
                            row
                            value={usePrimarySubscriberInfoSelection}
                            onChange={(e: React.ChangeEvent<any>) => {
                              setUsePrimarySubscriberInfoSelection(e.target.value);
                              if (e.target.value === 'Yes' && consumer) {
                                setFieldValue('relationshipToSubscriber', '');
                                setFieldValue('primarySubscriberFirstName', consumer.firstName);
                                setFieldValue('primarySubscriberLastName', consumer.lastName);
                                setFieldValue('primarySubscriberDateOfBirth', dayjs(consumer.dob));
                              } else {
                                setFieldValue('relationshipToSubscriber', '');
                                setFieldValue('primarySubscriberFirstName', '');
                                setFieldValue('primarySubscriberLastName', '');
                                setFieldValue('primarySubscriberDateOfBirth', null);
                              }
                            }}
                          >
                            {insurance.SHOULD_USE_PRIMARY_SUBSCRIBER_INFO_OPTIONS.map(option => {
                              return (
                                <MuiBox key={option.value} mr={4}>
                                  <MuiFormControlLabel
                                    value={option.value}
                                    control={<MuiRadio color="secondary" />}
                                    label={option.label}
                                  />
                                </MuiBox>
                              );
                            })}
                          </MuiRadioGroup>
                        </MuiFormControl>
                      </MuiBox>
                    </MuiGrid>
                  ) : null}
                </MuiGrid>
                {primarySubscriberSelection === 'No' ? (
                  <MuiBox mt={3}>
                    <MuiBox mb={4}>
                      <MuiBox pb={1}>
                        <MuiTypography variant="subtitle2">
                          Primary Subscriber’s Information
                        </MuiTypography>
                      </MuiBox>
                      <MuiDivider />
                    </MuiBox>
                    <MuiGrid container spacing={3}>
                      <MuiGrid item xs={12} sm={6}>
                        <MuiBox display="flex" flexDirection="row" pb={1}>
                          <MuiTypography>
                            First Name (Legal) <StyledAsterisk />
                          </MuiTypography>
                        </MuiBox>
                        <ConnectCareForm.TextField
                          required
                          name="primarySubscriberFirstName"
                          placeholder="First Name (Legal)"
                          InputProps={{
                            inputProps: { maxLength: 20 }
                          }}
                        />
                      </MuiGrid>
                      <MuiGrid item xs={12} sm={6}>
                        <MuiBox display="flex" flexDirection="row" pb={1}>
                          <MuiTypography>
                            Last Name (Legal) <StyledAsterisk />
                          </MuiTypography>
                        </MuiBox>
                        <ConnectCareForm.TextField
                          required
                          name="primarySubscriberLastName"
                          placeholder="Last Name (Legal)"
                          InputProps={{
                            inputProps: { maxLength: 20 }
                          }}
                        />
                      </MuiGrid>
                      <MuiGrid item xs={12} sm={6}>
                        <MuiBox display="flex" flexDirection="row" pb={1}>
                          <MuiTypography>
                            Date of Birth <StyledAsterisk />
                          </MuiTypography>
                        </MuiBox>
                        <ConnectCareForm.DatePicker
                          required
                          name="primarySubscriberDateOfBirth"
                          ariaLabel="date picker"
                        />
                      </MuiGrid>
                      <MuiGrid item xs={12} sm={6}>
                        <MuiBox display="flex" flexDirection="row" pb={1}>
                          <MuiTypography>
                            Patient's Relationship to Primary <StyledAsterisk />
                          </MuiTypography>
                        </MuiBox>
                        <ConnectCareForm.Select
                          required
                          name="relationshipToSubscriber"
                          placeholder="Select Relationship"
                          items={relationshipsToSubscriber}
                          itemToLabel={item => (item ? item.localizedDisplayName : '')}
                          itemToValue={item => (item ? item.localizedDisplayName : '')}
                        />
                      </MuiGrid>
                    </MuiGrid>
                  </MuiBox>
                ) : null}
              </MuiBox>
            </MuiPaper>
          </>
        )}
      />
    </>
  );
}

export default ConnectCareInsuranceSelectInsuranceForm;
