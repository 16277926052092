import React from 'react';
import { Color } from 'modules/styles/colors';

export default {
  svg: (
    <g clipPath="url(#clip0_1135_1536)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.25 1.5C1.83579 1.5 1.5 1.83579 1.5 2.25V21.75C1.5 22.1642 1.83579 22.5 2.25 22.5H21.75C22.1642 22.5 22.5 22.1642 22.5 21.75V2.25C22.5 1.83579 22.1642 1.5 21.75 1.5H2.25ZM0 2.25C0 1.00736 1.00736 0 2.25 0H21.75C22.9926 0 24 1.00736 24 2.25V21.75C24 22.9926 22.9926 24 21.75 24H2.25C1.00736 24 0 22.9926 0 21.75V2.25ZM9.70443 7.38336L12.9948 16.7037C13.0904 16.9745 13.2618 17.2122 13.4886 17.3885C13.7153 17.5647 13.9879 17.6722 14.2739 17.6981C14.5599 17.724 14.8474 17.6673 15.1021 17.5346C15.3568 17.402 15.5681 17.199 15.7108 16.9498L17.6849 13.5H20.25C20.6642 13.5 21 13.1642 21 12.75C21 12.3358 20.6642 12 20.25 12H17.25C16.981 12 16.7326 12.144 16.599 12.3775L14.4092 16.2042L11.1172 6.87933L11.1165 6.87716C11.0144 6.59079 10.8276 6.34223 10.581 6.16446C10.3343 5.9867 10.0395 5.88813 9.73553 5.88183C9.43158 5.87553 9.13289 5.96181 8.87911 6.12921C8.62532 6.29661 8.42844 6.53722 8.31458 6.81912L8.31364 6.82146L6.24222 12H3.75C3.33579 12 3 12.3358 3 12.75C3 13.1642 3.33579 13.5 3.75 13.5H6.75C7.05668 13.5 7.33246 13.3133 7.44636 13.0285L9.70443 7.38336Z"
        fill={Color.black}
      />
    </g>
  ),
  viewBox: '0 0 24 24'
};
