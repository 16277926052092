import * as React from 'react';

export default {
  svg: (
    <g>
      <style>
        {
          '.st1{opacity:.8348;fill:#444445}.st2{fill:#fff}.st3{fill:#f2f2f2}.st4{fill:#e1e1e1}.st6{fill:#e7e7e7}'
        }
      </style>
      <circle
        cx={150.8}
        cy={150.5}
        r={139.1}
        style={{
          opacity: 0.8348,
          fill: '#9e9e9e'
        }}
      />
      <path
        className="st1"
        d="M218.4 196c-11.8 0-21.3 9.6-21.2 21.4v4.9l-25.8.1v-4.9c0-11.8-9.6-21.3-21.4-21.2l-50.9.1v-8.7l126.3-.4v8.7h-7z"
      />
      <path
        transform="matrix(1 -.00274 .00274 1 -.588 .376)"
        className="st1"
        d="M118.9 211.3h36.5v7.8h-36.5z"
      />
      <path
        className="st2"
        d="M184.3 215.1c0-1.1-.9-1.9-1.9-1.9l-43.3.1c-1.1 0-1.9.9-1.9 1.9s.9 1.9 1.9 1.9l43.3-.1c1 0 1.9-.9 1.9-1.9z"
      />
      <path
        transform="matrix(1 -.00274 .00274 1 -.63 .505)"
        className="st2"
        d="M171.4 222.3h25.8v17h-25.8z"
      />
      <path
        className="st2"
        d="m197.2 237.8 24.5 6.9c2.6.7 4.7 3.5 4.7 6.2v6l-117.3.3v-6c0-2.7 2.2-5.2 4.8-5.6l57.4-7.8h25.9z"
      />
      <path
        transform="matrix(1 -.00274 .00274 1 -.697 .46)"
        className="st1"
        d="M109.2 252.9h117.3v4.1H109.2z"
      />
      <path
        transform="matrix(1 -.00274 .00274 1 -.135 .374)"
        className="st3"
        d="M123.8 32.3h25.8v34.6h-25.8z"
      />
      <path
        className="st2"
        d="M122 30.6c-.9 0-1.7.8-1.7 1.7 0 1 .8 1.7 1.7 1.7l29.3-.1c.9 0 1.7-.8 1.7-1.7 0-1-.8-1.7-1.7-1.7l-29.3.1z"
      />
      <path
        transform="matrix(1 -.00274 .00274 1 -.093 .374)"
        className="st4"
        d="M123.8 32.3h25.8v3.5h-25.8z"
      />
      <path
        d="M122 30.6c-.9 0-1.7.8-1.7 1.7 0 1 .8 1.7 1.7 1.7l29.3-.1c.9 0 1.7-.8 1.7-1.7 0-1-.8-1.7-1.7-1.7l-29.3.1z"
        style={{
          opacity: 0.8348,
          fill: '#f3f3f3'
        }}
      />
      <path className="st3" d="m124 136.3.1 30.5 2.8 4h20.3l2.7-4-.1-30.6z" />
      <path className="st3" d="m149.6 58.1-25.8.1-5.3 2.7.3 90 5.3 2.7 25.8-.1 5.3-2.7-.2-90z" />
      <path
        transform="matrix(1 -.00274 .00274 1 -.279 .375)"
        className="st6"
        d="M118.6 100.4h36.5v3.5h-36.5z"
      />
      <path className="st2" d="m149.6 58.1-25.8.1-5.3 2.7 36.5-.1z" />
      <path className="st6" d="m124.1 153.6 25.8-.1 5.3-2.7-36.4.1z" />
      <path
        style={{
          opacity: 0.8348,
          fill: '#dcdbdb'
        }}
        d="m147.2 170.8 2.7-4h-25.8l2.8 4z"
      />
      <path
        className="st4"
        d="m149.6 58.1-.1-22.3V34h1.8c.9 0 1.7-.8 1.7-1.7 0-1-.8-1.7-1.7-1.7h-14.6l.4 140.2h10.2l2.7-4v-13.3l5.3-2.7-.2-90-5.5-2.7z"
      />
      <path
        className="st2"
        d="M157.2 102.1c1.8 0 3.3-1.5 3.3-3.3l-.1-24.7c0-1.8-1.5-3.3-3.3-3.3l-38 .2c-1.8 0-3.3 1.5-3.3 3.3l.1 24.7c0 1.8 1.5 3.3 3.3 3.3l38-.2z"
      />
      <path
        className="st2"
        d="M184.3 226.8c-4.7 0-9.2-2.9-11-7.6-2.3-6.1.7-12.8 6.8-15.1 21.2-8.1 35.3-28.7 35.3-51.3-.1-30.2-24.7-54.7-54.9-54.6-6.5 0-11.7-5.2-11.8-11.7s5.2-11.7 11.7-11.8c20.9-.1 40.5 8 55.3 22.7s23 34.3 23 55.2c0 16.2-4.8 31.8-14.1 45-9.1 12.9-21.6 22.7-36.3 28.3-1.2.7-2.6.9-4 .9z"
      />
      <path
        d="M184.3 218.2c-1.3 0-2.5-.8-2.9-2-.6-1.6.2-3.4 1.8-4 24.5-9.3 40.9-33.2 40.8-59.4 0-1.7 1.4-3.1 3.1-3.1s3.1 1.4 3.1 3.1c0 14.4-4.3 28.3-12.5 40-8.1 11.5-19.2 20.2-32.3 25.2-.4.2-.8.2-1.1.2z"
        style={{
          opacity: 0.8348,
          fill: '#606060'
        }}
      />
      <path
        d="M179.8 86.5c0 .5-.6.9-.6 1.4 0 .5.5 1 .4 1.5s-.7.8-.8 1.3c-.1.5.3 1.1.2 1.5s-.8.7-1 1.2c-.2.4.1 1.1-.1 1.5-.2.4-.9.6-1.1 1-.2.4 0 1.1-.3 1.5s-1 .4-1.3.8-.2 1.1-.5 1.4c-.3.3-1 .3-1.4.6-.4.3-.4 1.1-.7 1.3-.4.3-1.1.1-1.5.4-.4.3-.5 1-.9 1.2s-1.1 0-1.5.2-.6.9-1.1 1.1-1.1-.2-1.5 0-.8.8-1.2.9c-.5.1-1-.3-1.5-.3s-.9.7-1.4.7c-.5 0-1-.5-1.4-.5s-1 .5-1.4.5c-.5 0-.9-.6-1.4-.7-.5-.1-1 .4-1.5.3-.5-.1-.8-.8-1.2-.9-.5-.1-1.1.2-1.5.1s-.7-.9-1.1-1.1-1.1.1-1.5-.2c-.4-.2-.5-.9-.9-1.2-.4-.3-1.1-.1-1.5-.4s-.4-1-.7-1.3c-.4-.3-1.1-.3-1.4-.6-.3-.3-.2-1.1-.5-1.4s-1-.4-1.3-.8c-.3-.4-.1-1.1-.3-1.5-.2-.4-.9-.6-1.1-1-.2-.4.1-1.1-.1-1.5-.2-.4-.9-.7-1-1.2-.1-.4.3-1.1.1-1.5-.1-.5-.7-.8-.8-1.3-.1-.5.4-1 .4-1.5s-.6-.9-.6-1.4.6-.9.6-1.4c0-.5-.5-1-.4-1.5.1-.5.7-.8.8-1.3.1-.5-.3-1.1-.2-1.5s.8-.7 1-1.2c.2-.4-.1-1.1.1-1.5.2-.4.9-.6 1.1-1 .2-.4 0-1.1.3-1.5.3-.4 1-.4 1.3-.8.3-.4.2-1.1.5-1.4.3-.3 1-.3 1.4-.6.4-.3.4-1.1.7-1.3.4-.3 1.1-.1 1.5-.4.4-.3.5-1 .9-1.2s1.1 0 1.5-.2.6-.9 1.1-1.1 1.1.2 1.5 0c.4-.1.8-.8 1.2-.9.5-.1 1 .3 1.5.3s.9-.7 1.4-.7c.5 0 1 .5 1.4.5s1-.5 1.4-.5c.5 0 .9.6 1.4.7.5.1 1-.4 1.5-.3.5.1.8.8 1.2.9.5.1 1.1-.2 1.5-.1s.7.9 1.1 1.1 1.1-.1 1.5.2c.4.2.5.9.9 1.2.4.3 1.1.1 1.5.4s.4 1 .7 1.3c.4.3 1.1.3 1.4.6.3.3.2 1.1.5 1.4s1 .4 1.3.8c.3.4.1 1.1.3 1.5.2.4.9.6 1.1 1 .2.4-.1 1.1.1 1.5.2.4.9.7 1 1.2.1.4-.3 1.1-.1 1.5.1.5.7.8.8 1.3s-.4 1-.4 1.5.6.9.6 1.4z"
        style={{
          fill: '#444'
        }}
      />
      <path
        className="st3"
        d="M176.1 86.5c0 8.6-6.9 15.6-15.6 15.6s-15.6-6.9-15.6-15.6 6.9-15.6 15.6-15.6c8.6 0 15.6 7 15.6 15.6z"
      />
      <path
        d="M165.9 86.5c0 3-2.4 5.4-5.4 5.4s-5.4-2.4-5.4-5.4c0-3 2.4-5.4 5.4-5.4 3 0 5.4 2.4 5.4 5.4z"
        style={{
          fill: '#777'
        }}
      />
      <path
        className="st4"
        d="M199.9 215.1c0 8.6-6.9 15.6-15.6 15.6s-15.6-6.9-15.6-15.6c0-8.6 6.9-15.6 15.6-15.6 8.5 0 15.5 6.9 15.6 15.6z"
      />
      <path
        d="M189.7 215.1c0 3-2.4 5.4-5.4 5.4-3 0-5.4-2.4-5.4-5.4s2.4-5.4 5.4-5.4c2.9 0 5.4 2.4 5.4 5.4z"
        style={{
          fill: '#9e9e9e'
        }}
      />
    </g>
  ),
  viewBox: '0 0 300 300'
};
