import React, { useEffect, useRef } from 'react';

import { EventEmitter } from 'services/EventEmitter';
import { AlertProps } from 'theme/material-ui';
import SnackAlert from './SnackAlert';

export interface SnackOptions {
  action?: AlertProps['action'];
  icon?: AlertProps['icon'];
  iconMapping?: AlertProps['iconMapping'];
  variant?: AlertProps['variant'];
  timer?: number | false;
}

export interface SnackProps {
  id?: any;
  message: React.ReactNode;
  severity?: AlertProps['severity'];
  onClose?: () => void;
  options?: SnackOptions;
}

export const snackBus = new EventEmitter();

class Snackbar {
  static create(
    message: SnackProps['message'],
    severity?: SnackProps['severity'],
    onClose?: SnackProps['onClose'],
    options?: SnackProps['options']
  ) {
    snackBus.emit('create', {
      message,
      severity,
      onClose,
      options
    });
  }
}

function Snack({ id, message, severity, onClose, options }: SnackProps) {
  const timeoutId = useRef<number | null>();

  useEffect(() => {
    if (options?.timer) {
      timeoutId.current = setTimeout(() => {
        onClose?.();
      }, options?.timer);
    }

    return () => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }
    };
  }, []);

  return (
    <SnackAlert
      id={id}
      severity={severity}
      message={message}
      onClose={() => onClose?.()}
      options={options}
    />
  );
}

export const useSnack = () => ({ create: Snackbar.create });
export default Snack;
