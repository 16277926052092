import React from 'react';
import { MuiTypography } from 'theme/material-ui';
import { Color } from 'modules/styles/colors';
import { ensureProtocol } from 'lib/messaging/rtf/parseHyperlink';

export const generateLinkComponent = (link: string, before?: string, after?: string) => {
  const url = ensureProtocol(link);
  return (
    <MuiTypography key={link} display="inline">
      {before}
      <MuiTypography color={Color.link} sx={{ textDecoration: 'underline' }} display="inline">
        <a target="_blank" rel="noopener noreferrer" href={url}>
          {link}
        </a>
      </MuiTypography>
      {after}
    </MuiTypography>
  );
};
