import styled from 'styled-components';

import { Color } from 'modules/styles/colors';
import { smallFontSize } from 'modules/styles/variables';

const SmallText = styled.div`
  color: ${Color.textLight};
  font-size: ${smallFontSize};
  line-height: ${smallFontSize};
`;

export default SmallText;
