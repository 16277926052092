import React from 'react';
import { Color } from 'modules/styles/colors';
import { Svg } from 'components/UI/Svg';
import { BorderRadius, IconSize } from 'modules/styles/variables';
import { StyledMuiIconButton, StyledMuiButton } from './styled';
import styled from 'styled-components';
import { dfdDefaultTheme } from 'theme/material-ui';
import Spinner from 'components/UI/Spinner/Spinner';

const IconOnlyButton = styled(StyledMuiIconButton)``;

const NormalButton = styled(StyledMuiButton)`
  && {
    display: none;
  }
`;

const ResponsiveContainer = styled.div`
  ${dfdDefaultTheme.breakpoints.up('lg')} {
    ${NormalButton} {
      display: inline-flex;
    }
    ${IconOnlyButton} {
      display: none;
    }
  }
`;

interface ResponsiveIconButtonProps {
  buttonText?: string;
  buttonAction?: () => void;
  iconName?: string;
  arialLabel?: string;
  loading?: boolean;
  testID?: string;
}

const ResponsiveIconButton = ({
  buttonText,
  buttonAction,
  iconName,
  arialLabel,
  loading,
  testID
}: ResponsiveIconButtonProps) => {
  return (
    <ResponsiveContainer>
      <IconOnlyButton
        data-testid={testID}
        disableElevation
        variant="outlined"
        color="primary"
        dark
        onClick={buttonAction}
        disabled={loading}
        aria-label={arialLabel}
      >
        {loading ? (
          <Spinner size={IconSize.base} />
        ) : (
          <Svg size={IconSize.base} color={Color.secondary} set="assets" name={iconName} />
        )}
      </IconOnlyButton>
      <NormalButton
        data-testid={testID}
        disableElevation
        variant="outlined"
        color="primary"
        dark
        onClick={buttonAction}
        aria-label={arialLabel}
        borderRadius={BorderRadius.base}
        disabled={loading}
        startIcon={
          <Svg size={IconSize.xmedium} color={Color.secondary} set="assets" name={iconName} />
        }
      >
        {loading ? <Spinner size={IconSize.base} /> : buttonText}
      </NormalButton>
    </ResponsiveContainer>
  );
};

export default ResponsiveIconButton;
