import styled from 'styled-components';

import { titleFontSize } from 'modules/styles/variables';

import DefaultText from './DefaultText';

const Title = styled(DefaultText)`
  font-size: ${titleFontSize};
  line-height: 32px;
  margin-top: 15px;
  margin-bottom: 15px;
  width: 100%;
`;

export default Title;
