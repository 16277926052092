import React from 'react';

export default {
  svg: (
    <g>
      <g>
        <circle fill="#213245" strokeWidth={0} cx={39.596} cy={39.892} r={38.5} />
        <path
          fill="#FFFFFF"
          strokeWidth={0}
          d="M39.596,2.392c20.711,0,37.5,16.789,37.5,37.5c0,20.711-16.789,37.5-37.5,37.5s-37.5-16.789-37.5-37.5 C2.096,19.181,18.885,2.392,39.596,2.392 M39.596,0.392c-21.78,0-39.5,17.72-39.5,39.5s17.72,39.5,39.5,39.5s39.5-17.72,39.5-39.5 S61.376,0.392,39.596,0.392L39.596,0.392z"
        />
      </g>
      <g>
        <path
          fill="none"
          strokeWidth={0}
          d="M19.644,71.638c0.832-0.214,1.678-0.438,2.536-0.674l-0.011-0.288l7.168-3.352l0,0.002v-0.002h1.895v-2.6 V63.28c-8.003-4.11-8.151-13.974-8.335-16.946c-1.456-0.313-2.542-1.604-2.542-3.15v-3.355c0-0.197,0.03-0.386,0.063-0.574 c-1.226-9.361-0.795-29.452,12.875-26.248c26.867-8.34,27.229,16.331,25.745,26.511c0.01,0.104,0.027,0.206,0.027,0.312v3.355 c0,1.529-1.067,2.803-2.495,3.135c-0.185,2.951-0.322,12.816-8.304,16.942v4.062h1.884h0v0l7.167,3.351l-0.011,0.286 c0.785,0.216,1.562,0.424,2.326,0.622c10.492-6.648,17.463-18.352,17.463-31.692c0-20.711-16.789-37.5-37.5-37.5 s-37.5,16.789-37.5,37.5C2.096,53.267,9.104,65,19.644,71.638z"
        />
        <polygon
          fill="#231F20"
          strokeWidth={0}
          points="50.151,67.324 50.15,67.324 50.151,67.324  "
        />
        <path
          fill="#231F20"
          strokeWidth={0}
          d="M48.266,68.187l0.082-0.069c0,0,0.584,0.264,1.372,0.621l0.43-1.415h-1.884v0.202V68.187z"
        />
        <polygon
          fill="#231F20"
          strokeWidth={0}
          points="31.133,68.121 31.232,68.204 31.232,67.324 29.338,67.324 29.338,67.326 29.767,68.738  "
        />
        <path
          fill="#F5D291"
          strokeWidth={0}
          d="M30.999,55.258c0-3.143,1.156-5.758,6.39-6.332h-1.552c0,0,1.518-7.254,3.871-12.259v-0.883 c-0.677,0-1.207,0.119-1.574,0.353l-0.036,0.023l0.007,0.042c0.075,0.483,0.059,0.989-0.048,1.504 c-0.9,4.343-2.94,5.809-8.086,5.809c-4.076,0-5.95-0.98-6.747-2.752c0.108,2.484,0.339,4.198,0.339,4.198 c0,2.076,3.67,13.789,16.145,13.804v-6.501C38.005,54.91,30.999,55.258,30.999,55.258z"
        />
        <path
          fill="#F5D291"
          strokeWidth={0}
          d="M21.139,42.97c-0.25-0.801-0.509-2.104-0.72-3.715c-0.034,0.187-0.063,0.376-0.063,0.574v3.355 c0,1.547,1.086,2.837,2.542,3.15c-0.027-0.434-0.055-0.729-0.108-0.829C22.782,45.505,21.448,43.968,21.139,42.97z"
        />
        <path
          fill="#F5D291"
          strokeWidth={0}
          d="M29.971,42.716c4.84,0,6.387-1.069,7.196-4.971c0.181-0.875-0.164-1.385-0.633-1.962 c-0.767-0.943-2.853-1.382-6.563-1.382c-4.328,0-6.522,1.026-6.522,3.049C23.449,40.434,23.929,42.716,29.971,42.716z  M29.986,38.243c-0.049-1.406,1.051-2.586,2.458-2.636c1.406-0.049,2.586,1.051,2.635,2.458c0.005,0.149-0.009,0.293-0.028,0.435 c0.008,0.07,0.026,0.138,0.028,0.21c0.049,1.406-1.051,2.586-2.458,2.636c-1.406,0.049-2.586-1.051-2.636-2.458 c-0.005-0.149,0.009-0.293,0.029-0.435C30.007,38.382,29.989,38.315,29.986,38.243z"
        />
        <path
          fill="#F5D291"
          strokeWidth={0}
          d="M29.971,32.373c3.552,0,5.985,0.675,7.232,2.005l0.034,0.037l0.044-0.024 c0.824-0.451,1.641-0.679,2.428-0.679v-8.066c-2.223-0.006-4.729-0.208-7.145-0.813c-3.338-0.836-7.396,1.029-8.336,4.339 c-0.374,1.318-0.623,2.73-0.788,4.148h1.72C26.381,32.689,27.989,32.373,29.971,32.373z"
        />
        <polygon
          fill="#F5D291"
          strokeWidth={0}
          points="39.742,75.349 39.736,75.355 39.742,75.35 39.742,75.35 46.245,69.887 48.266,68.187  48.266,67.526 31.232,64.724 31.232,67.324 31.232,68.204  "
        />
        <path
          fill="#96281B"
          strokeWidth={0}
          d="M39.736,75.355l-2.075,1.988c0.641,0.033,1.286,0.049,1.935,0.049c0.741,0,1.476-0.027,2.207-0.07 l-2.06-1.972L39.736,75.355z"
        />
        <path
          fill="#E6E7E8"
          strokeWidth={0}
          d="M49.72,68.739c-0.788-0.357-1.372-0.621-1.372-0.621l-0.082,0.069l-2.021,1.7l-6.503,5.463v0l2.06,1.972 c1.885-0.109,3.732-0.356,5.532-0.734L49.72,68.739z"
        />
        <path
          fill="#E6E7E8"
          strokeWidth={0}
          d="M39.742,75.349l-8.51-7.145l-0.099-0.083l-1.366,0.618l2.406,7.915c1.787,0.359,3.619,0.594,5.488,0.689 l2.075-1.988L39.742,75.349z"
        />
        <path
          fill="#231F20"
          strokeWidth={0}
          d="M19.644,71.638c0.85,0.536,1.723,1.037,2.618,1.505l-0.082-2.179C21.322,71.2,20.476,71.424,19.644,71.638 z"
        />
        <path
          fill="#231F20"
          strokeWidth={0}
          d="M57.231,72.988c0.82-0.438,1.619-0.909,2.402-1.405c-0.764-0.197-1.54-0.405-2.326-0.622L57.231,72.988z"
        />
        <path
          fill="#4F4C4D"
          strokeWidth={0}
          d="M57.318,70.676l-7.167-3.351l0,0l-0.43,1.415l-2.386,7.849c3.504-0.735,6.827-1.961,9.897-3.6l0.076-2.026 L57.318,70.676z"
        />
        <path
          fill="#4F4C4D"
          strokeWidth={0}
          d="M29.767,68.738l-0.429-1.413l0-0.002l-7.168,3.352l0.011,0.288l0.082,2.179 c3.079,1.608,6.404,2.807,9.911,3.511L29.767,68.738z"
        />
        <path
          fill="#F5D291"
          strokeWidth={0}
          d="M49.449,42.716c6.041,0,6.521-2.283,6.521-5.267c0-2.023-2.194-3.049-6.521-3.049 c-3.71,0-5.796,0.439-6.563,1.382c-0.469,0.577-0.814,1.087-0.633,1.962C43.062,41.647,44.609,42.716,49.449,42.716z  M44.827,38.243c-0.049-1.406,1.051-2.586,2.458-2.636c1.406-0.049,2.586,1.051,2.635,2.458c0.005,0.149-0.009,0.293-0.029,0.435 c0.008,0.07,0.026,0.138,0.029,0.21c0.049,1.406-1.051,2.586-2.458,2.636c-1.406,0.049-2.586-1.051-2.635-2.458 c-0.005-0.149,0.009-0.293,0.028-0.435C44.847,38.382,44.829,38.315,44.827,38.243z"
        />
        <path
          opacity={0.61}
          fill="#F4BD65"
          strokeWidth={0}
          d="M49.449,42.716c6.041,0,6.521-2.283,6.521-5.267c0-2.023-2.194-3.049-6.521-3.049 c-3.71,0-5.796,0.439-6.563,1.382c-0.469,0.577-0.814,1.087-0.633,1.962C43.062,41.647,44.609,42.716,49.449,42.716z  M44.827,38.243c-0.049-1.406,1.051-2.586,2.458-2.636c1.406-0.049,2.586,1.051,2.635,2.458c0.005,0.149-0.009,0.293-0.029,0.435 c0.008,0.07,0.026,0.138,0.029,0.21c0.049,1.406-1.051,2.586-2.458,2.636c-1.406,0.049-2.586-1.051-2.635-2.458 c-0.005-0.149,0.009-0.293,0.028-0.435C44.847,38.382,44.829,38.315,44.827,38.243z"
        />
        <path
          fill="#F5D291"
          strokeWidth={0}
          d="M56.677,45.505c-0.053,0.099-0.081,0.388-0.107,0.815c1.428-0.332,2.495-1.605,2.495-3.135V39.83 c0-0.107-0.017-0.208-0.027-0.312c-0.223,1.532-0.488,2.739-0.711,3.452C58.017,43.968,56.683,45.505,56.677,45.505z"
        />
        <path
          opacity={0.61}
          fill="#F4BD65"
          strokeWidth={0}
          d="M56.677,45.505c-0.053,0.099-0.081,0.388-0.107,0.815c1.428-0.332,2.495-1.605,2.495-3.135 V39.83c0-0.107-0.017-0.208-0.027-0.312c-0.223,1.532-0.488,2.739-0.711,3.452C58.017,43.968,56.683,45.505,56.677,45.505z"
        />
        <path
          fill="#F5D291"
          strokeWidth={0}
          d="M49.449,43.514c-5.146,0-7.186-1.466-8.086-5.809c-0.107-0.515-0.123-1.021-0.048-1.504l0.007-0.042 l-0.036-0.023c-0.367-0.234-0.898-0.354-1.576-0.354c0,0-0.001,0-0.001,0v0.883c-2.354,5.005-3.871,12.259-3.871,12.259h1.552 c0.7-0.077,1.467-0.12,2.32-0.12c7.232,0,8.71,2.889,8.71,6.452c0,0-7.009-0.348-8.71-2.997c0,0-0.001,0.001-0.001,0.001v6.501 c0.008,0,0.016,0.001,0.024,0.001c12.494,0,16.169-11.727,16.169-13.805c0,0,0.238-1.761,0.344-4.302 C55.477,42.498,53.607,43.514,49.449,43.514z"
        />
        <path
          opacity={0.61}
          fill="#F4BD65"
          strokeWidth={0}
          d="M49.449,43.514c-5.146,0-7.186-1.466-8.086-5.809c-0.107-0.515-0.123-1.021-0.048-1.504 l0.007-0.042l-0.036-0.023c-0.367-0.234-0.898-0.354-1.576-0.354c0,0-0.001,0-0.001,0v0.883 c-2.354,5.005-3.871,12.259-3.871,12.259h1.552c0.7-0.077,1.467-0.12,2.32-0.12c7.232,0,8.71,2.889,8.71,6.452 c0,0-7.009-0.348-8.71-2.997c0,0-0.001,0.001-0.001,0.001v6.501c0.008,0,0.016,0.001,0.024,0.001 c12.494,0,16.169-11.727,16.169-13.805c0,0,0.238-1.761,0.344-4.302C55.477,42.498,53.607,43.514,49.449,43.514z"
        />
        <path
          fill="#F5D291"
          strokeWidth={0}
          d="M39.71,33.711c0.787,0,1.604,0.228,2.429,0.679l0.044,0.024l0.034-0.037 c1.246-1.33,3.679-2.005,7.232-2.005c1.982,0,3.59,0.316,4.78,0.94l1.795,0.004c-0.164-1.416-0.413-2.827-0.787-4.144 c-0.939-3.31-4.998-5.175-8.335-4.339c-2.424,0.607-4.94,0.808-7.169,0.813c-0.008,0-0.017-0.001-0.024-0.001v8.066 C39.709,33.711,39.71,33.711,39.71,33.711z"
        />
        <path
          opacity={0.61}
          fill="#F4BD65"
          strokeWidth={0}
          d="M39.71,33.711c0.787,0,1.604,0.228,2.429,0.679l0.044,0.024l0.034-0.037 c1.246-1.33,3.679-2.005,7.232-2.005c1.982,0,3.59,0.316,4.78,0.94l1.795,0.004c-0.164-1.416-0.413-2.827-0.787-4.144 c-0.939-3.31-4.998-5.175-8.335-4.339c-2.424,0.607-4.94,0.808-7.169,0.813c-0.008,0-0.017-0.001-0.024-0.001v8.066 C39.709,33.711,39.71,33.711,39.71,33.711z"
        />
        <path
          fill="#F5D291"
          strokeWidth={0}
          d="M48.266,67.526v-0.202v-4.062c-2.197,1.136-4.981,1.841-8.533,1.841c-3.534,0-6.309-0.698-8.501-1.823 v1.444L48.266,67.526z"
        />
        <path
          opacity={0.61}
          fill="#F4BD65"
          strokeWidth={0}
          d="M48.266,67.526v-0.202v-4.062c-2.197,1.136-4.981,1.841-8.533,1.841 c-3.534,0-6.309-0.698-8.501-1.823v1.444L48.266,67.526z"
        />
        <path
          fill="#D3D4D5"
          strokeWidth={0}
          d="M30.999,55.258c0,0,7.006-0.348,8.709-2.996c0,0,0.001-0.001,0.001-0.001 c1.702,2.649,8.71,2.997,8.71,2.997c0-3.564-1.478-6.452-8.71-6.452c-0.853,0-1.62,0.043-2.32,0.12 C32.155,49.5,30.999,52.115,30.999,55.258z"
        />
        <path
          fill="#D3D4D5"
          strokeWidth={0}
          d="M48.266,63.262c7.981-4.127,8.119-13.991,8.304-16.942c0.027-0.426,0.054-0.716,0.107-0.815 c0.006,0,1.34-1.537,1.65-2.535c0.223-0.713,0.488-1.92,0.711-3.452c1.484-10.18,1.122-34.851-25.745-26.511 C19.623,9.803,19.192,29.894,20.418,39.255c0.211,1.612,0.47,2.914,0.72,3.715c0.31,0.998,1.644,2.535,1.65,2.535 c0.053,0.1,0.081,0.395,0.108,0.829c0.185,2.972,0.333,12.836,8.335,16.946c2.192,1.126,4.967,1.823,8.501,1.823 C43.285,65.104,46.069,64.398,48.266,63.262z M22.696,37.587c0-0.491,0.055-0.96,0.165-1.395l0.014-0.057l-1.167-0.41V33.32h1.733 c0.164-1.418,0.413-2.83,0.788-4.148c0.939-3.31,4.998-5.175,8.336-4.339c2.415,0.605,4.921,0.806,7.145,0.813 c0.008,0,0.016,0.001,0.024,0.001c2.229-0.005,4.745-0.207,7.169-0.813c3.338-0.836,7.396,1.029,8.335,4.339 c0.374,1.317,0.623,2.728,0.787,4.144l1.687,0.004v2.405l-1.167,0.41l0.014,0.057c0.109,0.435,0.164,0.905,0.164,1.395 c0,1.189-0.121,2.217-0.477,3.07c-0.106,2.541-0.344,4.302-0.344,4.302c0,2.078-3.675,13.805-16.169,13.805 c-0.008,0-0.016-0.001-0.024-0.001C27.234,58.748,23.564,47.036,23.564,44.96c0,0-0.231-1.714-0.339-4.198 C22.832,39.888,22.696,38.826,22.696,37.587z"
        />
        <path
          fill="#43414D"
          strokeWidth={0}
          d="M29.971,43.514c5.146,0,7.186-1.466,8.086-5.809c0.107-0.514,0.123-1.02,0.048-1.504l-0.007-0.042 l0.036-0.023c0.367-0.234,0.897-0.353,1.574-0.353c0,0,0.001,0,0.001,0c0.678,0,1.208,0.119,1.576,0.354l0.036,0.023l-0.007,0.042 c-0.075,0.483-0.059,0.989,0.048,1.504c0.9,4.343,2.94,5.809,8.086,5.809c4.157,0,6.028-1.016,6.797-2.856 c0.356-0.853,0.477-1.882,0.477-3.07c0-0.491-0.055-0.96-0.164-1.395l-0.014-0.057l1.167-0.41V33.32l-1.687-0.004l-1.795-0.004 c-1.19-0.623-2.798-0.94-4.78-0.94c-3.552,0-5.986,0.675-7.232,2.005l-0.034,0.037l-0.044-0.024 c-0.825-0.451-1.642-0.679-2.429-0.679c0,0-0.001,0-0.001,0c-0.787,0-1.604,0.229-2.428,0.679l-0.044,0.024l-0.034-0.037 c-1.246-1.33-3.679-2.005-7.232-2.005c-1.982,0-3.59,0.316-4.81,0.947h-1.72h-1.733v2.405l1.167,0.41l-0.014,0.057 c-0.109,0.435-0.165,0.905-0.165,1.395c0,1.239,0.135,2.301,0.528,3.174C24.021,42.534,25.895,43.514,29.971,43.514z  M42.886,35.783c0.768-0.943,2.853-1.382,6.563-1.382c4.327,0,6.521,1.026,6.521,3.049c0,2.984-0.48,5.267-6.521,5.267 c-4.841,0-6.387-1.069-7.197-4.971C42.071,36.87,42.417,36.36,42.886,35.783z M29.971,34.401c3.71,0,5.795,0.439,6.563,1.382 c0.469,0.577,0.814,1.087,0.633,1.962c-0.809,3.903-2.356,4.971-7.196,4.971c-6.042,0-6.522-2.283-6.522-5.267 C23.449,35.426,25.643,34.401,29.971,34.401z"
        />
        <path
          fill="#F5D291"
          strokeWidth={0}
          d="M32.622,41.345c1.406-0.049,2.507-1.229,2.458-2.636c-0.002-0.072-0.02-0.139-0.028-0.21 c-0.165,1.206-1.171,2.156-2.429,2.2c-1.334,0.047-2.453-0.945-2.607-2.248c-0.02,0.143-0.034,0.287-0.029,0.435 C30.036,40.294,31.216,41.395,32.622,41.345z"
        />
        <path
          fill="#F7CDB9"
          strokeWidth={0}
          d="M32.622,41.345c1.406-0.049,2.507-1.229,2.458-2.636c-0.002-0.072-0.02-0.139-0.028-0.21 c-0.165,1.206-1.171,2.156-2.429,2.2c-1.334,0.047-2.453-0.945-2.607-2.248c-0.02,0.143-0.034,0.287-0.029,0.435 C30.036,40.294,31.216,41.395,32.622,41.345z"
        />
        <path
          strokeWidth={0}
          d="M32.622,40.7c1.258-0.044,2.264-0.994,2.429-2.2c0.019-0.143,0.034-0.287,0.028-0.435 c-0.049-1.406-1.229-2.507-2.635-2.458c-1.406,0.049-2.507,1.229-2.458,2.636c0.003,0.072,0.02,0.139,0.029,0.21 C30.17,39.755,31.288,40.747,32.622,40.7z M33.421,37.995c-0.352,0.012-0.647-0.263-0.659-0.614 c-0.012-0.352,0.263-0.647,0.615-0.659c0.352-0.012,0.646,0.263,0.659,0.614C34.047,37.688,33.772,37.983,33.421,37.995z"
        />
        <path
          fill="#FFFFFF"
          strokeWidth={0}
          d="M33.376,36.722c-0.352,0.012-0.627,0.307-0.615,0.659c0.012,0.352,0.307,0.627,0.659,0.614 c0.352-0.012,0.627-0.307,0.614-0.659C34.023,36.985,33.728,36.71,33.376,36.722z"
        />
        <path
          fill="#F5D291"
          strokeWidth={0}
          d="M47.462,41.345c1.406-0.049,2.507-1.229,2.458-2.636c-0.002-0.072-0.02-0.139-0.029-0.21 c-0.165,1.206-1.171,2.156-2.429,2.2c-1.334,0.047-2.453-0.945-2.607-2.248c-0.019,0.143-0.034,0.287-0.028,0.435 C44.876,40.294,46.056,41.395,47.462,41.345z"
        />
        <path
          opacity={0.61}
          fill="#F4BD65"
          strokeWidth={0}
          d="M47.462,41.345c1.406-0.049,2.507-1.229,2.458-2.636c-0.002-0.072-0.02-0.139-0.029-0.21 c-0.165,1.206-1.171,2.156-2.429,2.2c-1.334,0.047-2.453-0.945-2.607-2.248c-0.019,0.143-0.034,0.287-0.028,0.435 C44.876,40.294,46.056,41.395,47.462,41.345z"
        />
        <path
          fill="#F7CDB9"
          strokeWidth={0}
          d="M47.462,41.345c1.406-0.049,2.507-1.229,2.458-2.636c-0.002-0.072-0.02-0.139-0.029-0.21 c-0.165,1.206-1.171,2.156-2.429,2.2c-1.334,0.047-2.453-0.945-2.607-2.248c-0.019,0.143-0.034,0.287-0.028,0.435 C44.876,40.294,46.056,41.395,47.462,41.345z"
        />
        <path
          strokeWidth={0}
          d="M47.462,40.7c1.258-0.044,2.264-0.994,2.429-2.2c0.02-0.143,0.034-0.287,0.029-0.435 c-0.049-1.406-1.229-2.507-2.635-2.458c-1.406,0.049-2.507,1.229-2.458,2.636c0.002,0.072,0.02,0.139,0.028,0.21 C45.01,39.755,46.128,40.747,47.462,40.7z M48.261,37.995c-0.352,0.012-0.647-0.263-0.659-0.614 c-0.012-0.352,0.263-0.647,0.614-0.659c0.352-0.012,0.647,0.263,0.659,0.614C48.887,37.688,48.612,37.983,48.261,37.995z"
        />
        <path
          fill="#FFFFFF"
          strokeWidth={0}
          d="M48.216,36.722c-0.352,0.012-0.626,0.307-0.614,0.659c0.012,0.352,0.307,0.627,0.659,0.614 c0.352-0.012,0.627-0.307,0.615-0.659C48.863,36.985,48.568,36.71,48.216,36.722z"
        />
      </g>
    </g>
  ),
  viewBox: '0 0 80 80'
};
