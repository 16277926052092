import React from 'react';
import { useProviderSearchFilters } from 'lib/hooks/ProviderSearch/useProviderSearchFilters';
import { FiltersKey } from 'lib/hooks/ProviderSearch/types';

import MainFilter, { MainFilterProps } from './MainFilter';
import LocationNearMeFilter, { LocationNearMeFilterProps } from './LocationNearMeFilter';
import SpecialtiesFilter, { SpecialtiesFilterProps } from './SpecialtiesFilter';
import GenderFilter, { GenderFilterProps } from './GenderFilter';
import HasPhotoVideoFilter, { HasPhotoVideoFilterProps } from './HasPhotoVideoFilter';
import OrganizationAffiliationsFilter, {
  OrganizationAffiliationsFilterProps
} from './OrganizationAffiliationsFilter';
import AffiliatedHospitalsFilter, {
  AffiliatedHospitalsFilterProps
} from './AffiliatedHospitalsFilter';
import ClinicsFilter, { ClinicsFilterProps } from './ClinicsFilter';
import LanguagesFilter, { LanguagesFilterProps } from './LanguagesFilter';

export interface Props {
  defaultExpandedKeys: FiltersKey[];
}

const componentMap: Record<FiltersKey, React.FC<any>> = {
  main: MainFilter as React.FC<MainFilterProps>,
  locationNearMe: LocationNearMeFilter as React.FC<LocationNearMeFilterProps>,
  specialties: SpecialtiesFilter as React.FC<SpecialtiesFilterProps>,
  gender: GenderFilter as React.FC<GenderFilterProps>,
  hasPhotoVideo: HasPhotoVideoFilter as React.FC<HasPhotoVideoFilterProps>,
  organizationAffiliations: OrganizationAffiliationsFilter as React.FC<
    OrganizationAffiliationsFilterProps
  >,
  affiliatedHospitals: AffiliatedHospitalsFilter as React.FC<AffiliatedHospitalsFilterProps>,
  clinics: ClinicsFilter as React.FC<ClinicsFilterProps>,
  languages: LanguagesFilter as React.FC<LanguagesFilterProps>
} as const;

function ProviderSearchFiltersContent({ defaultExpandedKeys }: Props) {
  const { filters } = useProviderSearchFilters();

  return (
    <>
      {Object.keys(filters).map(filter => {
        const key = filter as FiltersKey;
        const FilterComponent = componentMap[key];

        return (
          <FilterComponent
            key={key}
            defaultExpanded={defaultExpandedKeys.includes(key)}
            {...filters[key]}
          />
        );
      })}
    </>
  );
}

export default ProviderSearchFiltersContent;
