import styled from 'styled-components';

import { Color } from 'modules/styles/colors';
import { MuiBox } from 'theme/material-ui';

export const footerHeight = 60;
export const headerHeight = 56;
export const minHeight = footerHeight + headerHeight;
export const sideBarWidthCollapsed = 70;
export const sideBarWidthOpen = 270;

export const ContainerRow = styled.div`
  &&& {
    display: flex;
    flex-direction: row;
    height: calc(100vh - ${headerHeight}px);
  }
`;

export const ContainerMuiBox = styled(MuiBox)`
  &&& {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    background-color: ${Color.foreColor};
    flex: 1 1 auto;
    overflow: auto;
    padding-top: ${({ $hiddenHeader }: { $hiddenHeader: boolean }) =>
      $hiddenHeader ? 0 : headerHeight}px;
  }
`;

export const MainContent = styled(MuiBox)`
  &&& {
    min-height: calc(100vh - ${minHeight}px);
    display: flex;
    flex-direction: column;
  }
`;

export const StyledMuiBox = styled(MuiBox)`
  transition: width 75ms cubic-bezier(0.32, 0, 0.67, 0);
`;
