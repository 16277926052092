import React, { Fragment } from 'react';
import styled, { css } from 'styled-components';
import DefaultLabel from 'components/UI/Labels/DefaultLabel';
import { Column } from 'components/UI/Layout/Container';
import Section from 'components/UI/Layout/Section';
import Panel from 'components/UI/Panel/Panel';
import TouchablePanel from 'components/UI/Panel/TouchablePanel';
import Subhead from 'components/UI/Typography/Subhead';
import { Color } from 'modules/styles/colors';
import FlexBox from 'components/UI/Layout/FlexBox';

interface Props {
  title?: string;
  data?: any[];
  keyExtractor: Function;
  renderItem: Function;
  onItemPressed: Function;
  emptyCaption?: string;
  stretchHeight: boolean;
}

const Title = styled(Subhead)`
  margin-left: 20px;
  margin-bottom: 0px;
  color: ${Color.textDark};
`;

const StyledFlatList = styled.div`
  align-self: flex-start;
  margin-bottom: 10px;
`;

const withStretchStyles = (props: { stretchHeight?: boolean }) => {
  return (
    props.stretchHeight &&
    css`
      flex-direction: row;
    `
  );
};

const StyledSection = styled(Section)`
  padding-top: 5px;
  padding-bottom: 0px;
  width: 170px;
  padding: 5px;
  ${withStretchStyles}
`;

const StyledTouchablePanel = styled(TouchablePanel)`
  min-height: 120px;
  justify-content: center;
`;

export function HealthRecordsSwiper({
  title,
  data,
  keyExtractor,
  renderItem,
  onItemPressed,
  emptyCaption,
  stretchHeight
}: Props) {
  return (
    <Fragment>
      <FlexBox backgroundColor={Color.foreColor}>
        <Column>
          {title !== '' && <Title>{`${title} (${data.length})`}</Title>}
          {data.length === 0 && (
            <Panel>
              <DefaultLabel>{emptyCaption}</DefaultLabel>
            </Panel>
          )}
          {data.length > 0 && (
            <Column accessibilityLabel="Swipe for more Visit Summaries">
              <StyledFlatList
                data={data}
                keyExtractor={keyExtractor}
                horizontal
                showsHorizontalScrollIndicator={false}
                viewabilityConfig={{ viewAreaCoveragePercentThreshold: 50 }}
                renderItem={({ item, index }) => (
                  <StyledSection stretchHeight={stretchHeight}>
                    <StyledTouchablePanel
                      stretchHeight={stretchHeight}
                      onPress={() => onItemPressed(item)}
                    >
                      {renderItem(item, index)}
                    </StyledTouchablePanel>
                  </StyledSection>
                )}
              />
            </Column>
          )}
        </Column>
      </FlexBox>
    </Fragment>
  );
}

HealthRecordsSwiper.defaultProps = {
  title: '',
  data: [],
  emptyCaption: undefined,
  textColor: Color.textDark
};
