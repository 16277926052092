import React from 'react';
import { MuiDivider, MuiTypography, MuiBox, MuiButton } from 'theme/material-ui';
import { Spacing } from 'modules/styles/variables';

export interface InfoCardTitleProps {
  title?: string;
  buttonOnClick?: () => void;
  buttonText?: string;
  buttonTestID?: string;
}

const InfoCardTitle = ({ title, buttonOnClick, buttonText, buttonTestID }: InfoCardTitleProps) => {
  return (
    <>
      <MuiBox
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        flexDirection="row"
        padding={Spacing.xSmall}
        height={50}
      >
        <MuiTypography variant="subtitle2" data-testid={title}>
          {title}
        </MuiTypography>
        {buttonOnClick ? (
          <MuiButton
            color="primary"
            variant="text"
            onClick={buttonOnClick}
            data-testid={buttonTestID}
          >
            {buttonText}
          </MuiButton>
        ) : null}
      </MuiBox>
      <MuiDivider />
    </>
  );
};

export default InfoCardTitle;
