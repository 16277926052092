import Spinner from 'components/UI/Spinner/Spinner';
import CollapsibleListItem from 'components/common/CollapsibleList/CollapsibleListItem';
import DataLoader from 'components/UI/DataLoader/DataLoader';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { RootState } from 'store/types';
import { AnalyticsEvent } from 'services/AnalyticsService';
import {
  currentLocationPathNameSelector,
  previousLocationPathNameSelector
} from 'store/router/selectors';
import { HEALTH_RECORDS_DRAWERS } from 'lib/constants/healthRecords';
import { Goal } from 'store/goals/types';
import { MuiBox } from 'theme/material-ui';
import { HealthRecordsListItemPlaceholder } from '../shared';
import { GoalsTable } from './Table';

interface HealthRecordsGoalsListProps {
  error: boolean;
  goals: Goal[];
  loading?: boolean;
  currentUrl?: string;
  referringUrl?: string;
}

const getLoadingState = () => (
  <>
    <HealthRecordsListItemPlaceholder message={HEALTH_RECORDS_DRAWERS.Goals.loading} />
    <MuiBox display="flex" flexDirection="row" justifyContent="center">
      <Spinner />
    </MuiBox>
  </>
);

const getErrorState = () => (
  <HealthRecordsListItemPlaceholder message={HEALTH_RECORDS_DRAWERS.Goals.error} />
);

const getEmptyState = () => (
  <HealthRecordsListItemPlaceholder message={HEALTH_RECORDS_DRAWERS.Goals.empty} />
);

export const HealthRecordsGoalsList = ({
  error,
  goals,
  loading,
  currentUrl,
  referringUrl
}: HealthRecordsGoalsListProps) => {
  const location = useLocation();
  const shouldExpandGoals = location.state?.expand === 'goals';

  return (
    <CollapsibleListItem
      shouldScroll={shouldExpandGoals}
      expandOnFirstRender={shouldExpandGoals}
      leftIcon="IconGoals"
      title={HEALTH_RECORDS_DRAWERS.Goals.title}
      subtitle={HEALTH_RECORDS_DRAWERS.Goals.subtitle(goals.length)}
      error={error ? 'An issue occurred' : undefined}
      analyticsEvent={AnalyticsEvent.GoalsClicked}
      currentUrlForAnalytics={currentUrl}
      referringUrlForAnalytics={referringUrl}
      detailsPadding="0"
    >
      <MuiBox width="100%">
        <DataLoader
          data={goals}
          error={error}
          loading={loading}
          renderData={() => <GoalsTable goals={goals} />}
          renderNoData={getEmptyState}
          renderError={getErrorState}
          renderLoading={getLoadingState}
        />
      </MuiBox>
    </CollapsibleListItem>
  );
};

const mapState = (state: RootState) => ({
  currentUrl: currentLocationPathNameSelector(state),
  referringUrl: previousLocationPathNameSelector(state)
});

export default connect(mapState)(HealthRecordsGoalsList);
