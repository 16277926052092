import { AdvisoryType } from 'lib/dashboard/types';

export enum HealthAdvisoryLinkType {
  BOOKING,
  EXTERNAL_FLU,
  CALL
}

export enum HealthAdvisoryTitleType {
  ANNUAL_APPOINTMENT,
  HEALTH_ADVISORY
}

export const mainButtonAction = (healthAdvisoryType: AdvisoryType): HealthAdvisoryLinkType => {
  switch (healthAdvisoryType) {
    case AdvisoryType.CERVICAL_CANCER:
    case AdvisoryType.DIABETES_A1C:
    case AdvisoryType.WELLNESS:
    case AdvisoryType.HPV_VACCINE:
      return HealthAdvisoryLinkType.BOOKING;
    case AdvisoryType.FLU:
      return HealthAdvisoryLinkType.EXTERNAL_FLU;
    case AdvisoryType.MAMMOGRAM:
      return HealthAdvisoryLinkType.CALL;
    default:
      return HealthAdvisoryLinkType.BOOKING;
  }
};
