import React from 'react';
import { TransitionProps } from '@material-ui/core/transitions';
import { IconButton, Slide } from '@material-ui/core';
import {
  MuiBox,
  MuiDialog,
  MuiList,
  MuiListItem,
  MuiListItemText,
  MuiTypography
} from 'theme/material-ui';
import { Svg } from 'components/UI/Svg';

import { FontSize, FontWeight, IconSize, Spacing, ZIndex } from 'modules/styles/variables';
import BlueDot from 'components/common/BlueDot';
import { Color } from 'modules/styles/colors';
import styled from 'styled-components';
import { SentenceWithBoldWords } from 'components/UI/SentenceWithBoldWords';
import { TreatedCondition } from '../types';

const Slider = React.forwardRef<unknown, TransitionProps>((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const StyledMuiListItem = styled(MuiListItem)`
  &.MuiListItem-root {
    padding: 0;
  }
`;

const secondaryDescriptionParagraph = ({ id, ...rest }: TreatedCondition) => (
  <MuiTypography key={id}>
    <SentenceWithBoldWords {...rest} boldStyle={FontWeight.bold} />
  </MuiTypography>
);

const buildSecondaryDescriptionParagraphs = (secondaryDescriptions: TreatedCondition[]) => (
  <MuiBox bottom={Spacing.large}>
    {secondaryDescriptions.map((element: TreatedCondition) =>
      secondaryDescriptionParagraph(element)
    )}
  </MuiBox>
);

export interface ConnectCareModalProps {
  description: string;
  note?: { sentence: string; boldWords: number[] };
  open: boolean;
  title: string;
  treatedConditions: TreatedCondition[];
  onClick: () => void;
  testId: string;
  secondaryConditions?: TreatedCondition[];
}

export const ConnectCareModal = ({
  description,
  note,
  open,
  title,
  treatedConditions,
  onClick,
  secondaryConditions = [],
  testId
}: ConnectCareModalProps) => {
  const conditionText = { fontSize: 16 };

  return (
    <MuiDialog
      open={open}
      onBackdropClick={onClick}
      onClose={onClick}
      TransitionComponent={Slider}
      scroll="paper"
      fullWidth
      maxWidth="md"
      data-testid={testId}
    >
      <MuiBox position="absolute" top={5} right={5} zIndex={ZIndex.high}>
        <IconButton
          onClick={onClick}
          aria-label="close connect care modal"
          data-testid="close-modal-icon"
        >
          <Svg set="material" name="close" size={IconSize.base} />
        </IconButton>
      </MuiBox>
      <MuiBox display="flex" flexDirection="column" alignItems="flex-start" p={6}>
        <MuiBox bottom={Spacing.xLarge}>
          <MuiTypography fontSize={24}>{title}</MuiTypography>
        </MuiBox>
        <MuiBox bottom={Spacing.large} data-testid="description">
          <MuiTypography fontSize={16}>{description}</MuiTypography>
        </MuiBox>
        {secondaryConditions.length
          ? buildSecondaryDescriptionParagraphs(secondaryConditions)
          : null}
        <MuiBox bottom={Spacing.large} data-testid="treated-conditions">
          <MuiList>
            {treatedConditions.map(condition => (
              <StyledMuiListItem key={condition.id}>
                <BlueDot
                  color={Color.black}
                  hOffsetRight={Spacing.large}
                  size={FontSize.xsmall / 1.5}
                />
                {condition.boldWords?.length ? (
                  <SentenceWithBoldWords {...condition} boldStyle={FontWeight.bold} />
                ) : (
                  <MuiListItemText
                    primary={condition.sentence}
                    primaryTypographyProps={{ style: conditionText }}
                  />
                )}
              </StyledMuiListItem>
            ))}
          </MuiList>
        </MuiBox>
        {note ? (
          <SentenceWithBoldWords componentType="p" boldStyle={FontWeight.bold} {...note} />
        ) : null}
      </MuiBox>
    </MuiDialog>
  );
};
