import React from 'react';
import Config from 'react-native-config';
import { Color } from 'modules/styles/colors';
import { FontSize } from 'modules/styles/variables.web';
import { MuiBox, MuiTypography } from 'theme/material-ui';
import DeviceInfoServices from 'services/DeviceInfoServices';
import { CompanyName } from 'modules/utils/ConfigUtils';

const buildNumber = DeviceInfoServices.getBuildNumber();

const SupergraphicCopyright = ({ isSmallScreen = false }) => {
  return (
    <MuiBox marginBottom={isSmallScreen ? 3 : 10} textAlign="center">
      <MuiTypography color={Color.white} fontSize={isSmallScreen ? FontSize.small : undefined}>
        {`© ${new Date().getFullYear()} ${CompanyName}, All rights reserved`}
      </MuiTypography>
      {Config.SHOW_APP_VERSION === 'enabled' ? (
        <MuiTypography color={Color.white} fontSize={isSmallScreen ? FontSize.small : undefined}>
          {` Version: ${Config.APP_VERSION} Build: ${buildNumber} ${Config.BUILD_ENV}`}
        </MuiTypography>
      ) : null}
    </MuiBox>
  );
};

export default SupergraphicCopyright;
