import React from 'react';
import uniqBy from 'lodash/uniqBy';
import { Step, StepIconProps } from '@material-ui/core';
import { Svg } from 'components/UI/Svg';
import { generate } from './utils';
import { Steps, StepLabel, Connector, StepperOptions } from './styled';

export interface StepperProps<T> {
  steps: T[];
  activeStep: number;
  uniqueBy?: string | ((step: T) => any);
  stepToValue?: (step: T) => string | number;
  stepToLabel?: (step: T) => string;
  options?: StepperOptions;
}

enum IconKey {
  COMPLETED = 'completed',
  ACTIVE = 'active',
  INACTIVE = 'inactive'
}

const mapKey = ({ active, completed }: StepIconProps) => {
  let key = IconKey.INACTIVE;

  if (completed) {
    key = IconKey.COMPLETED;
  } else if (active) {
    key = IconKey.ACTIVE;
  }

  return key;
};

function Stepper<T>({
  activeStep,
  steps: stepsProp,
  uniqueBy,
  stepToValue,
  stepToLabel,
  options
}: StepperProps<T>) {
  const steps = uniqueBy ? uniqBy(stepsProp, uniqueBy) : stepsProp;

  const svgMap = {
    [IconKey.COMPLETED]: {
      set: 'material',
      name: 'check-circle',
      color: generate.completedColor(options?.completedColor)
    },
    [IconKey.ACTIVE]: {
      set: 'assets',
      name: 'Dot',
      color: generate.activeColor(options?.activeColor)
    },
    [IconKey.INACTIVE]: {
      set: 'material',
      name: 'radio-button-unchecked',
      color: generate.inactiveColor(options?.inactiveColor)
    }
  } as const;

  return (
    <Steps alternativeLabel activeStep={activeStep} connector={<Connector {...options} />}>
      {steps.map(step => {
        const value = stepToValue ? stepToValue(step) : step;
        const label = stepToLabel ? stepToLabel(step) : '';

        return (
          <Step key={value as string | number}>
            <StepLabel
              label={label}
              StepIconComponent={(stepIconProps: StepIconProps) => (
                <Svg size={20} {...svgMap[mapKey(stepIconProps)]} />
              )}
              {...options}
            >
              {label}
            </StepLabel>
          </Step>
        );
      })}
    </Steps>
  );
}

export default Stepper;
