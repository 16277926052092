import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import uniq from 'lodash/uniq';
import { onEnter } from 'utils/keyboard';
import { RouteComponentProps } from 'react-router';

import {
  MuiBox,
  MuiButton,
  MuiContainer,
  MuiDivider,
  MuiGrid,
  MuiTypography
} from 'theme/material-ui';

import Spinner from 'components/UI/Spinner/Spinner';
import DataLoader from 'components/UI/DataLoader/DataLoader';
import { Confirm } from 'components/ConfirmDialog/ConfirmDialog';

import { strBeginsWithVowel } from 'lib/booking/utils';

import { IconSize } from 'modules/styles/variables';
import { providerSelectConstant } from 'modules/constants/booking';

import analyticsService, {
  AnalyticsEvent,
  AmplitudeEventData,
  getAnalyticsProviderType
} from 'services/AnalyticsService';

import { RootState } from 'store/types';
import * as bookingTypes from 'store/booking/types';
import * as bookingActions from 'store/booking/actions';
import * as bookingSelectors from 'store/booking/selectors';
import * as findProviderTypes from 'store/findProvider/types';
import * as findProviderActions from 'store/findProvider/actions';
import * as findProviderConstants from 'store/findProvider/constants';
import * as findProviderSelectors from 'store/findProvider/selectors';
import * as searchSelectors from 'store/providerSearch/selectors';

import * as geoLocationSelectors from 'store/geolocation/selectors';

import { SpecialtyProviderPanel } from './SpecialtyProviderPanel';
import SpecialtyProviderFilters from './SpecialtyProviderFilters';
import { BookingPanel, StyledScreen } from 'screens/Booking/components/styled';
import { BookingLoading, BookingNoData } from 'screens/Booking/components/sharedComponents';
import { useCheckBookingInfoEffect } from 'screens/Booking/useCheckBookingInfoEffect';
import SpecialtyProviderActionsHeader from './SpecialtyProviderActionsHeader';
import SpecialtyProviderSortOptions from './SpecialtyProviderSortOptions';
import { convertToLowerKabobCase } from 'modules/utils/StringUtils';

export interface Props extends RouteComponentProps {
  appointmentDetails: bookingTypes.AppointmentDetails;
  providers: findProviderTypes.ProviderSummary[];
  consumerPastProviders: findProviderTypes.ProviderSummary[];
  providersLoading: boolean;
  setDoctor: typeof bookingActions.setDoctor;
  searchFilter: findProviderTypes.ProviderSearchFilterState;
  activeFilters: findProviderTypes.ProviderSearchFilters;
  providerSearchMore: typeof findProviderActions.schedulingProviderSearchMore;
  isHydrating: boolean;
  rawProviderTotal: number;
  rawProviderCount: number;
  termsAccepted: boolean;
  termsShown: boolean;
  setSort: typeof findProviderActions.setSortBy;
  providerSearch: typeof findProviderActions.schedulingProviderSearch;
  setFetchSlots: typeof bookingActions.setFetchSlots;
  getSortBy: findProviderTypes.OptionType;
  setActiveProviderSearchFilter: typeof findProviderActions.setActiveProviderSearchFilter;
  geolocationStatus: boolean;
  currentProviderId: string;
}

function countFilters(options: findProviderTypes.ProviderSearchFilters) {
  let count = 0;
  if (
    options.filters.find(filter => filter.group === 'acceptingNewPatients' && filter.value === true)
  ) {
    count += 1;
  }
  return count;
}

export function BookingSpecialtyProviderSelect({
  appointmentDetails,
  providers,
  consumerPastProviders,
  providersLoading,
  setDoctor,
  searchFilter,
  activeFilters,
  providerSearchMore,
  isHydrating,
  rawProviderCount,
  history,
  setSort,
  providerSearch,
  getSortBy,
  setActiveProviderSearchFilter,
  geolocationStatus,
  currentProviderId,
  setFetchSlots
}: Props) {
  const eventData: AmplitudeEventData = {
    number_results_visible: providers.length,
    referringUrl: 'BookingSpecialtyOrProviderSelect',
    currentUrl: 'SpecialtyProviderSelect'
  };

  const hasMoreProviders = !!rawProviderCount;
  // checks if specialty begins with a vowel
  const specialtyBeginsWithVowel = appointmentDetails?.specialty?.nameLong?.match(
    strBeginsWithVowel
  );

  const currentSortBy = findProviderConstants.SORT_OPTIONS.find(
    item => item.value === getSortBy.value
  );

  const [filteredTexts, setFilteredTexts] = useState([]);
  const [showFiltersDrawer, setShowFiltersDrawer] = useState(false);
  const [showSortOptionsDrawer, setShowSortOptionsDrawer] = useState(false);
  const [displayFilters, setDisplayFilters] = useState(true);

  const filtersCount = useMemo(() => {
    return countFilters(activeFilters);
  }, [activeFilters]);

  useCheckBookingInfoEffect(appointmentDetails?.patient);

  useEffect(() => {
    if (searchFilter.hasActiveFilters) {
      const { facets, filters } = activeFilters;
      const filterArray = [];
      facets.forEach(e => {
        filterArray.push(e.name);
      });
      filters.forEach(e => {
        if (e.value) {
          filterArray.push(findProviderConstants.filteredTextKey[e.group]);
        }
      });

      setFilteredTexts(uniq([...filteredTexts, ...filterArray]));
    }
  }, [activeFilters, searchFilter]);

  useEffect(() => {
    setDisplayFilters(appointmentDetails?.specialty?.nameShort !== 'OB|OBG|GYN');
  }, []);

  const handleSpecialtyProviderSelect = (provider: findProviderTypes.ProviderSummary) => {
    const providerId = provider?.providerId || provider?.corpProvId;
    const isDifferentProvider = providerId !== currentProviderId;
    const isPastProvider = consumerPastProviders.some(
      pastProvider => pastProvider.corpProvId === provider.corpProvId
    );

    if (provider?.callClinicForNPV && !isPastProvider && isDifferentProvider) {
      Confirm.show(
        `${provider?.displayName}, ${providerSelectConstant.NO_ONLINE_NPV_SLOTS.title}`,
        null,
        null,
        providerSelectConstant.NO_ONLINE_NPV_SLOTS.severity,
        {
          text: providerSelectConstant.CALL_TO_SCHEDULE,
          onClick: () => history.push(`/u/provider/${providerId}`)
        },
        { text: 'Close' },
        { cancelable: false }
      );
    } else {
      setDoctor(provider);
      setFetchSlots(isDifferentProvider);
      history.push('/u/get-care-now/booking/visit-type-select');
    }

    analyticsService.logEvent(AnalyticsEvent.BookingProviderSelected, {
      provider_type: getAnalyticsProviderType(provider.providerType),
      referringUrl: 'BookingSpecialtyOrProviderSelect',
      currentUrl: 'SpecialtyProviderSelect'
    });
  };

  const getSpecialtyProviderIsSelected = (provider: findProviderTypes.ProviderSummary) => {
    if (!provider || !appointmentDetails.doctor) {
      return false;
    }

    return appointmentDetails.doctor.cernerResourceId === provider.cernerResourceId;
  };

  const handleViewMore = () => {
    providerSearchMore();

    analyticsService.logEvent(AnalyticsEvent.ViewMoreProviderSearchResultsClicked, eventData);
  };

  return (
    <>
      <StyledScreen>
        <SpecialtyProviderActionsHeader
          currentSortBy={currentSortBy}
          showSortOptions={() => setShowSortOptionsDrawer(true)}
          filtersCount={filtersCount}
          showFilters={() => setShowFiltersDrawer(true)}
          displayFilters={displayFilters}
        />
        <MuiContainer maxWidth="lg">
          <MuiBox my={3}>
            <MuiGrid container>
              <MuiGrid item xs>
                <MuiTypography gutterBottom variant="h4">
                  Select {specialtyBeginsWithVowel ? 'an' : 'a'}{' '}
                  {appointmentDetails?.specialty?.moreInfo?.title
                    ? appointmentDetails?.specialty?.moreInfo?.title
                    : appointmentDetails?.specialty?.nameLong}{' '}
                  Provider
                </MuiTypography>
              </MuiGrid>
            </MuiGrid>
            <MuiBox mb={2}>
              <MuiDivider />
            </MuiBox>
            <MuiBox py={2}>
              <DataLoader
                data={providers}
                loading={providersLoading}
                renderLoading={() => <BookingLoading />}
                renderNoData={() => (
                  <BookingNoData
                    message={providerSelectConstant.GET_SPECIALTY_PROVIDER.NO_DATA.message}
                  />
                )}
                renderData={() => (
                  <MuiBox role="radiogroup">
                    {providers.map(provider => (
                      <BookingPanel
                        tabindex="0"
                        role="radio"
                        elevation={2}
                        key={provider.cernerResourceId}
                        aria-checked={getSpecialtyProviderIsSelected(provider)}
                        selected={getSpecialtyProviderIsSelected(provider)}
                        onClick={() => handleSpecialtyProviderSelect(provider)}
                        onKeyUp={onEnter(() => handleSpecialtyProviderSelect(provider))}
                        data-testid={convertToLowerKabobCase(provider.displayName)}
                      >
                        <SpecialtyProviderPanel
                          providerId={provider.providerId}
                          displayName={provider.displayName}
                          imageUrl={provider.profileImage}
                          specialties={provider.specialties}
                          selectedSpecialty={appointmentDetails?.specialty}
                          primaryLocation={provider.locations[0]}
                          isSelectHealth={provider.isSelectHealth}
                          offersVideoVisits={provider.offersVideoVisits}
                          hasLocation={geolocationStatus}
                        />
                      </BookingPanel>
                    ))}
                    {hasMoreProviders ? (
                      <MuiBox display="flex" justifyContent="center">
                        <MuiButton
                          data-testid="booking-specialty-provider-select-view-more"
                          color="primary"
                          variant="outlined"
                          onClick={() => handleViewMore()}
                          size="large"
                          disabled={isHydrating}
                          endIcon={isHydrating ? <Spinner size={IconSize.small} /> : null}
                        >
                          View more
                        </MuiButton>
                      </MuiBox>
                    ) : null}
                  </MuiBox>
                )}
              />
              <SpecialtyProviderSortOptions
                open={showSortOptionsDrawer}
                onClose={() => setShowSortOptionsDrawer(false)}
                sortOptions={findProviderConstants.SORT_OPTIONS}
                defaultSortOption={getSortBy}
                setSort={setSort}
                search={providerSearch}
              />
              <SpecialtyProviderFilters
                appointment={appointmentDetails}
                open={showFiltersDrawer}
                onClose={() => setShowFiltersDrawer(false)}
                searchFilter={searchFilter}
                activeFilters={activeFilters}
                setActiveProviderSearchFilter={setActiveProviderSearchFilter}
                search={providerSearch}
              />
            </MuiBox>
          </MuiBox>
        </MuiContainer>
      </StyledScreen>
    </>
  );
}

const mapStateToProps = (state: RootState) => ({
  appointmentDetails: bookingSelectors.appointmentDetailsSelector(state),
  providers: findProviderSelectors.providerSearchResultSelector(state),
  consumerPastProviders: findProviderSelectors.selectedConsumerPastDoctorsSelector(state),
  providersLoading: findProviderSelectors.providerSearchFetchingSelector(state),
  searchFilter: findProviderSelectors.searchFilterSelector(state),
  activeFilters: findProviderSelectors.activeFiltersSelector(state),
  isHydrating: findProviderSelectors.providerSearchHydratingSelector(state),
  rawProviderTotal: findProviderSelectors.providerSearchRawTotal(state),
  rawProviderCount: findProviderSelectors.providerSearchCurrentTotal(state),
  termsAccepted: searchSelectors.searchTermsAndConditionsAccepted(state),
  getSortBy: findProviderSelectors.getSortBy(state),
  geolocationStatus: geoLocationSelectors.geolocationAcceptedSelector(state),
  currentProviderId: bookingSelectors.currentProviderIdSelector(state)
});

const mapDispatchToProps = {
  setDoctor: bookingActions.setDoctor,
  providerSearchMore: findProviderActions.schedulingProviderSearchMore,
  setSort: findProviderActions.setSortBy,
  providerSearch: findProviderActions.schedulingProviderSearch,
  setActiveProviderSearchFilter: findProviderActions.setActiveProviderSearchFilter,
  setFetchSlots: bookingActions.setFetchSlots
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingSpecialtyProviderSelect);
