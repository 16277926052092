import React from 'react';

const st0 = {
  fill: 'url(#SVGID_1_)'
};

const st1 = {
  fill: '#06173F'
};

const st2 = {
  fill: '#557EB9'
};

const st3 = {
  fill: '#FFB000'
};

const st4 = {
  fill: '#FFFFFF'
};

export default {
  svg: (
    <g>
      <linearGradient
        id="SVGID_1_"
        gradientUnits="userSpaceOnUse"
        x1="194.6728"
        y1="202.8445"
        x2="296"
        y2="202.8445"
      >
        <stop offset="0" style={{ stopColor: '#999999' }} />
        <stop offset="1" style={{ stopColor: '#FFFFFF' }} />
      </linearGradient>
      <path
        {...st0}
        d="M194.7,239.8c0,0,3.7,3.2,11.7-0.8s82.6-46.5,87.2-49c4.6-2.6,3.6-7.7-9.1-10.3c-12.7-2.6-60.8-15.1-60.8-15.1
        l-7.1,29.2c0,0-5.2,33.4-5.8,36.6c-0.6,3.2-2.2,4.4-5.4,6.1c-3.2,1.7-6.9,3.5-6.9,3.5L194.7,239.8z"
      />
      <g>
        <g>
          <path
            {...st1}
            d="M218.7,76.4L52.9,24.8c-3.7-1.2-7.2,1.1-7.8,5l-11,74.7c3.9,0.2,7.9,0.9,12,2.2c0.1,0,0.1,0.1,0.2,0.1
            l6.7-45.4l155.2,48.3l-17.1,116.6l-108-33.6c-2.1,3.8-4.7,7.1-7.7,10l119.3,37.1c3.7,1.2,7.2-1.1,7.8-5l21.9-149.3
            C224.9,81.7,222.4,77.6,218.7,76.4z"
          />
          <path
            {...st2}
            d="M62.3,114.8c7.7,5.5,14.2,12.9,18.9,21.3l2.3-15.7l-21.1-6.6L62.3,114.8z"
          />
          <path
            {...st2}
            d="M89.4,171.4c-0.1,1.3-0.2,2.6-0.4,3.9s-0.4,2.6-0.7,3.8l-0.3,2.1l21.1,6.6l3.2-22l-21.1-6.6L89.4,171.4z"
          />
          <polygon {...st2} points="93.1,146.3 114.1,152.9 117.4,130.9 96.3,124.3 		" />
          <polygon {...st2} points="122.4,96.3 101.4,89.7 98.1,111.7 119.2,118.3 		" />
          <polygon {...st2} points="121.8,191.7 142.8,198.2 146.1,176.2 125,169.6 		" />
          <polygon {...st2} points="155.6,202.2 176.6,208.8 179.9,186.7 158.8,180.2 		" />
          <polygon {...st2} points="190,117.3 169,110.7 165.7,132.8 186.8,139.3 		" />
          <polygon {...st2} points="126.9,156.9 147.9,163.4 151.2,141.4 130.1,134.8 		" />
          <polygon {...st2} points="156.2,106.8 135.2,100.2 131.9,122.2 153,128.8 		" />
        </g>
        <g>
          <polygon {...st2} points="160.7,167.4 181.7,174 185,151.9 163.9,145.4 		" />
        </g>
      </g>
      <g>
        <path
          {...st1}
          d="M255,196.4c-3-11.8-18-103.9-19.3-112.8c-1-6.9-2.2-12.8-12.8-15.8C212.3,64.9,64,19.6,64,19.6
          S59.8,18,57.9,20l-0.3,0.3l164.2,50.5c9,2.8,11.5,7.3,10.2,16.6l-15.5,106.4c0,0,24.8,7.2,31.6,9.4C252,204.4,256,200.4,255,196.4z
          "
        />
      </g>
      <path
        {...st2}
        d="M186.9,77.4c-0.8,0.4-1.7,0.5-2.3,0.3c-2.5-0.8-4.5-7.5-1.8-15.7c1.3-4,3.5-7.6,6-9.8c1.5-1.3,2.9-2,4-2
        c0.2,0,0.5,0,0.7,0.1c1.8,0.6,3.4,4.4,3,9.8l9.6,2.9c1.5-10.5-2.2-19.6-9.6-22c-4.6-1.5-9.7,0-14.2,3.9c-3.8,3.4-6.9,8.4-8.8,14.2
        c-4.2,12.9-0.6,25.3,8.2,28.1c1.2,0.4,2.4,0.6,3.6,0.6c2.2,0,5.4-0.9,6.6-1.7c1.2-0.8,2.9-3.3,0.6-6.5
        C190.4,76.5,187.5,77.1,186.9,77.4z"
      />
      <path
        {...st2}
        d="M75.3,43.1c-0.8,0.4-1.7,0.5-2.3,0.3c-2.5-0.8-4.5-7.5-1.8-15.7c1.3-4,3.5-7.6,6-9.8c1.5-1.3,2.9-2,4-2
        c0.2,0,0.5,0,0.7,0.1c1.9,0.6,3.5,4.6,3,10.1l9.5,2.9C96,18.2,92.3,8.9,84.9,6.6c-4.6-1.5-9.7,0-14.2,3.9c-3.8,3.4-6.9,8.4-8.8,14.2
        c-4.2,12.9-0.6,25.3,8.2,28.1c1.2,0.4,2.4,0.6,3.6,0.6c2.2,0,5.4-0.9,6.6-1.7s2.9-3.3,0.6-6.5C78.9,42.2,76,42.8,75.3,43.1z"
      />
      <g>
        <path
          {...st3}
          d="M73.5,146.7c-9.3-23.2-31.7-36-50-28.7C5.2,125.4-2,150.1,7.3,173.3c9.3,23.2,31.7,36,50,28.7
          S82.8,169.9,73.5,146.7z"
        />
        <path
          {...st4}
          d="M64.9,148.1c-1.7-3.7-3.8-7.1-6.2-10.1l-17.2,13.6l-16.4-24.9c-3.5,1.8-6.4,4.6-8.7,8.2l15.8,24l-16.3,12.9
          c1.7,3.7,3.7,7.1,6.1,10.2l16.7-13.2l16.5,24.9c3.6-1.7,6.6-4.4,8.9-7.9L48,161.4L64.9,148.1z"
        />
      </g>
    </g>
  ),
  viewBox: '0 0 300 248'
};
