import React from 'react';
import styled, { css } from 'styled-components';
import { Spacing } from 'modules/styles/variables';

export interface ButtonGroupProps {
  children: React.ReactNodeArray;
}

const ButtonGroupContainer = styled.div`
  && {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    display: flex;
  }
`;

const GroupItem = styled.div<{ lastItem?: boolean }>`
  align-self: center;

  ${props =>
    !props.lastItem &&
    css`
      margin-right: ${Spacing.medium}px;
    `}
`;

const ButtonGroup = (props: ButtonGroupProps) => {
  const { children } = props;

  const childrenArray = React.Children.toArray(children);

  const count = childrenArray.filter(child => React.isValidElement(child)).length;

  return (
    <ButtonGroupContainer>
      {childrenArray.map((child, idx) => {
        const isLastItem = idx + 1 === count;
        const key = idx + Date.now();

        return (
          <GroupItem key={key} lastItem={isLastItem}>
            {child}
          </GroupItem>
        );
      })}
    </ButtonGroupContainer>
  );
};

export default ButtonGroup;
