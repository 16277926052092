import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Patient } from 'store/booking/types';

export const useCheckBookingInfoEffect = (data: Patient | null) => {
  const history = useHistory();

  useEffect(() => {
    if (!data) {
      history.replace('/u/get-care-now/booking/patient-select');
    }
  }, []);
};
