import styled from 'styled-components';
import { Color } from 'modules/styles/colors';
import { MuiBox, MuiButton, MuiIconButton, MuiList, MuiListItem } from 'theme/material-ui';
import { ConnectCareTextField } from 'components/ConnectCare';

// ConnectCareWaitingRoomBanner
export const BannerIconButton = styled(MuiButton)`
  && {
    & .MuiButton-label {
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      color: ${Color.gray};
      transition: 200ms all ease-in-out;
      & path {
        stroke: ${Color.gray};
        transition: 200ms all ease-in-out;
      }
      & line {
        stroke: ${Color.gray};
        transition: 200ms all ease-in-out;
      }
    }
    &:hover {
      & .MuiButton-label {
        color: ${Color.primary};
        & path {
          stroke: ${Color.primary};
        }
        & line {
          stroke: ${Color.primary};
        }
      }
    }
  }
`;

// ConnectCareWaitingRoomChat
export const ChatWindow = styled(MuiBox)`
  && {
    position: absolute;
    right: 0px;
    bottom: 0px;
    box-shadow: 0px 7px 40px 2px rgba(148, 149, 150, 0.3);
    background: ${Color.white};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: 0.3s ease-in-out;
    border-radius: 5px;
    width: ${props => (props.fullscreen ? '100%' : `${450}px`)};
    height: ${props => (props.fullscreen ? '100%' : `${584}px`)};
  }
`;

export const ChatWindowHeader = styled(MuiBox)`
  && {
    background: ${Color.primary};
    min-height: 40px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    color: white;
    padding: 10px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export const MessageList = styled(MuiList)`
  && {
    height: 80%;
    overflow-y: auto;
  }
`;

export const MessageListItem = styled(MuiListItem)`
  && {
    margin: auto;
    padding-bottom: 10px;
    display: flex;
  }
`;

export const MessageReceivedBubble = styled(MuiBox)`
  && {
    padding: 8px 16px;
    margin-bottom: 10px;
    border-radius: 10px 10px 10px 2px;
    font-weight: 300;
    font-size: 14px;
    word-wrap: break-word;
  }
`;

export const MessageSentBubble = styled(MuiBox)`
  && {
    padding: 8px 16px;
    margin-bottom: 10px;
    border-radius: 10px 10px 2px 10px;
    font-weight: 300;
    font-size: 14px;
    word-wrap: break-word;
  }
`;

export const SendButton = styled(MuiIconButton)`
  && {
    & .MuiIconButton-label {
      & path {
        fill: ${Color.primary};
        stroke: ${Color.primary};
        transition: 200ms all ease-in-out;
      }
      & line {
        fill: ${Color.primary};
        stroke: ${Color.primary};
        transition: 200ms all ease-in-out;
      }
    }
    &:disabled {
      & .MuiIconButton-label {
        & path {
          fill: ${Color.gray};
          stroke: ${Color.gray};
        }
        & line {
          fill: ${Color.gray};
          stroke: ${Color.gray};
        }
      }
    }
  }
`;

export const MessageTextField = styled(ConnectCareTextField)`
  && {
    & .MuiInputBase-input {
      min-height: 0px;
    }
  }
`;
