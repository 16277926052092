import styled from 'styled-components';

import { Color } from 'modules/styles/colors';

const ImagePanel = styled.div`
  background-color: ${Color.foreColor};
  border: 1px solid ${Color.foreColor};
  border-radius: 5px;
  width: 100%;
  align-items: center;
`;

export default ImagePanel;
