import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Formik } from 'formik';
import RequiredFieldsLegend, { StyledAsterisk } from 'components/RequiredFieldsLegend';
import { maskPhone } from 'modules/utils/MaskUtils';
import { Color } from 'modules/styles/colors';
import { Spacing } from 'modules/styles/variables';
import React, { ComponentType, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Config from 'react-native-config';
import { History } from 'lib/history';
import get from 'lodash/get';
import Svg from 'components/UI/Svg/Svg';
import Spacer from 'components/UI/Layout/Spacer';
import ConfirmModal from 'components/UI/Modals/ConfirmModal';
import WebMuiModal from 'components/UI/Modals/WebMuiModal';
import {
  consumerPrimaryEmailSelector,
  consumerPrimaryPhoneSelector,
  profileConsumerDisplayNameSelector,
  profileConsumerSelector
} from 'store/profile/selectors';

import { MuiBox, MuiButton, MuiContainer, MuiTypography, dfdDefaultTheme } from 'theme/material-ui';
import FormHelperText from '@material-ui/core/FormHelperText';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { HEALTH_TOPICS_CHECKBOXES, SurveyForm } from 'lib/healthTools/types';
import { getQualtricsSession, updateHealthToolsQualtricsSession } from 'services/QualtricsService';
import { SESSION_URL } from 'lib/healthTools/constants';
import { QualtricsSession } from 'lib/constants/help';
import { Consumer } from 'store/profile/types';
import { ConsumerSharedUiData } from 'store/consumerPreferences/types';
import { updateAndRefreshSharedUiData } from 'store/consumerPreferences/actions';
import RequiredLabel from 'components/UI/Labels/RequiredLabel';
import FlexBox, { FlexBoxRow } from 'components/UI/Layout/FlexBox';
import { RootState, RootDispatch } from 'store/types';
import { convertToLowerKabobCase } from 'modules/utils/StringUtils';
import { AnalyticsEvent } from 'services/AnalyticsService';
import useNavigationAnalytics from 'hooks/useNavigationAnalytics';
import { submitSurveyValidationSchema } from './schema';
import { AppName, getAppName } from 'modules/utils/ConfigUtils';

interface HealthToolsSurveyScreenProps {
  consumerPrimaryPhone: string;
  consumerPrimaryEmail: string;
  consumerDisplayName: string;
  history: History;
  dispatch: RootDispatch;
  userProfile: Consumer;
}

export const HealthToolsSurveyScreen = ({
  consumerPrimaryPhone,
  consumerPrimaryEmail,
  consumerDisplayName,
  history,
  userProfile,
  dispatch
}: HealthToolsSurveyScreenProps) => {
  const [showFeedbackGivenModal, setShowFeedbackGivenModal] = useState(false);
  const [showSubmitting, setShowSubmitting] = useState<boolean>(false);
  const [session, setSession] = useState<QualtricsSession>();
  const [error, setError] = useState();

  const isSmallScreen = useMediaQuery(dfdDefaultTheme.breakpoints.down('md'));

  const initialValues: SurveyForm = {
    healthTopicsChecked: [],
    otherIdea: '',
    radioAnswer: '',
    contactName: consumerDisplayName || '',
    contactEmail: consumerPrimaryEmail || '',
    contactPhone: maskPhone(consumerPrimaryPhone) || ''
  };

  const submitHealthToolsFeedBack = useNavigationAnalytics(
    AnalyticsEvent.HealthToolsFeedBackSubmitted,
    {
      feedbackFormType: 'Health Tools Ideas',
      age: userProfile?.age,
      gender: userProfile?.gender,
      appVersion: Config.APP_VERSION,
      title: 'Health Tools Feedback Form'
    }
  );

  const cancelHealthToolsFeedBack = useNavigationAnalytics(
    AnalyticsEvent.cancelHealthToolsFeedBackClicked,
    {
      title: 'Health Tools Feedback Form',
      feedbackFormType: 'Health Tools Ideas'
    }
  );

  const navigateToDashBoard = () => history.push('/u/dashboard');

  const navigateToHealthTools = () => {
    cancelHealthToolsFeedBack();
    history.push('/u/health-tools');
  };

  async function updateSession(values: SurveyForm) {
    submitHealthToolsFeedBack();
    setShowSubmitting(true);

    const qualtricsResponse = await updateHealthToolsQualtricsSession(
      values,
      session as QualtricsSession
    );

    if (qualtricsResponse?.meta?.httpStatus === '200 - OK') {
      const params: Partial<ConsumerSharedUiData> = {
        isHealthToolSurveyCompleted: true
      };

      setShowFeedbackGivenModal(true);
      dispatch(updateAndRefreshSharedUiData(params));

      return setShowSubmitting(false);
    }

    setError(qualtricsResponse?.meta?.error?.errorMessage);
    return setShowSubmitting(false);
  }

  async function initSession() {
    const newSession = await getQualtricsSession(SESSION_URL);
    setSession(newSession);
  }

  useEffect(() => {
    initSession();
  }, []);

  return (
    <>
      <ConfirmModal
        accessibilityLabel="confirm modal"
        confirmLabel="Close"
        isOpen={showFeedbackGivenModal}
        maxWidth={isSmallScreen ? '100%' : '50%'}
        onConfirm={() => {
          setShowFeedbackGivenModal(false);
          navigateToDashBoard();
        }}
        subTitleElement={() => {
          return (
            <>
              <FlexBox vSpacing={Spacing.xLarge} hSpacing={Spacing.large}>
                <Spacer spacing="small" />
                <FlexBox justifyContent="center" alignItems="center">
                  <Svg name="IllustrationsHealthRecord" height="100" width="100" />
                </FlexBox>
                <Spacer spacing="large" />
                <MuiTypography variant="h6">
                  Thank you for your responses! Your feedback is appreciated and will help make{' '}
                  {getAppName(AppName.base, 'the', AppName.suffix1)} a better experience for all our
                  patients.
                </MuiTypography>
              </FlexBox>
            </>
          );
        }}
      />

      <WebMuiModal open={showSubmitting}>
        <FlexBox flexDirection="row">
          <FlexBox backgroundColor={Color.secondary} width="4px"></FlexBox>
          <FlexBox vSpacing={Spacing.xLarge} hSpacing={Spacing.xxLarge}>
            <MuiTypography variant="h6">Sending your feedback...</MuiTypography>
          </FlexBox>
        </FlexBox>
      </WebMuiModal>

      <MuiBox flex={1} width="100%">
        <Formik
          data-testid="Survey-Formik"
          initialValues={initialValues}
          onSubmit={updateSession}
          validationSchema={submitSurveyValidationSchema}
        >
          {({
            values,
            errors,
            touched,
            dirty,
            isValid,
            handleBlur,
            handleChange,
            setFieldValue,
            handleSubmit
          }) => {
            const healthTopicsErr = errors?.healthTopicsChecked;
            const length = values.otherIdea ? values.otherIdea.length : 0;
            const getFormError = (errorKey: string, touchKey?: string) => {
              const touchedValue = get(touched, touchKey || errorKey);
              const errorValue = get(errors, errorKey);

              return touchedValue && errorValue ? errorValue : null;
            };
            return (
              <MuiContainer maxWidth="sm">
                <MuiBox
                  display="flex"
                  flex={1}
                  flexDirection="column"
                  justifyContent="space-between"
                  pt={Spacing.small}
                >
                  <RequiredFieldsLegend />
                  <Box pt={4} pb={2}>
                    <MuiTypography variant="h6">
                      1. What topics are most important to you?
                    </MuiTypography>
                    <FlexBoxRow>
                      <MuiTypography></MuiTypography>
                      (Select up to five )
                      <Box pl={0.5}>
                        <StyledAsterisk />
                      </Box>
                    </FlexBoxRow>
                  </Box>

                  {!!healthTopicsErr ? (
                    <FormHelperText error> {healthTopicsErr}</FormHelperText>
                  ) : null}

                  {HEALTH_TOPICS_CHECKBOXES.map(item => {
                    const checkedValues = values?.healthTopicsChecked || [];

                    return (
                      <Box py={0.2}>
                        <Box>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={checkedValues.includes(item.fieldName)}
                                color="primary"
                                data-testid={convertToLowerKabobCase(item.label)}
                                onBlur={handleBlur('healthTopicsChecked')}
                                onChange={() => {
                                  setFieldValue(
                                    'healthTopicsChecked',
                                    checkedValues.includes(item.fieldName)
                                      ? checkedValues.filter(i => i !== item.fieldName)
                                      : checkedValues.concat(item.fieldName)
                                  );
                                }}
                                value={item.fieldName}
                              />
                            }
                            label={item.label}
                          />
                        </Box>
                      </Box>
                    );
                  })}
                  <Box pt={4}>
                    <MuiTypography variant="h6">
                      2. What other ideas for health tools or resources would you like to see in{' '}
                      {getAppName(AppName.base, 'the', AppName.suffix1)} <br /> Why would they be
                      valuable to you? &nbsp;
                    </MuiTypography>
                  </Box>
                  <MuiBox pt={Spacing.small}>
                    <TextField
                      aria-label="Input otherIdea"
                      data-testid="other-idea-input"
                      defaultValue=""
                      error={!!getFormError('otherIdea' as keyof typeof values)}
                      fullWidth
                      helperText={getFormError('otherIdea' as keyof typeof values)}
                      inputProps={{ maxlength: 500 }}
                      multiline
                      onBlur={handleBlur('otherIdea')}
                      onChange={handleChange('otherIdea')}
                      placeholder="Add comment..."
                      rows={15}
                      variant="outlined"
                    />

                    <Box pt={0.3} display="flex" justifyContent="flex-end">
                      <Typography variant="caption">{length}/500</Typography>
                    </Box>
                  </MuiBox>
                </MuiBox>

                <MuiBox
                  display="flex"
                  flex={1}
                  flexDirection="column"
                  justifyContent="space-between"
                  pt={Spacing.small}
                >
                  <Box>
                    <MuiTypography variant="h6">
                      3. Are you interested in future opportunities to share ideas/feedback about{' '}
                      {getAppName(AppName.base, 'the', AppName.suffix1)}?&nbsp;
                      <StyledAsterisk />
                    </MuiTypography>
                  </Box>
                  <MuiBox display="flex" justifyContent="left" marginBottom="15px">
                    <RadioGroup
                      onChange={e => {
                        if (e.target.value === 'No') {
                          setFieldValue('name', '');
                          setFieldValue('email', '');
                          setFieldValue('phone', '');
                        } else {
                          setFieldValue('name', consumerDisplayName || '');
                          setFieldValue('email', consumerDisplayName || '');
                          setFieldValue('phone', maskPhone(consumerPrimaryPhone) || '');
                        }

                        setFieldValue('radioAnswer', e.target.value);
                      }}
                    >
                      <FormControlLabel
                        control={<Radio color="primary" />}
                        data-testid="yes-radio-btn"
                        key="Yes"
                        label="Yes"
                        value="Yes"
                      />
                      <FormControlLabel
                        control={<Radio color="primary" />}
                        data-testid="no-radio-btn"
                        key="No"
                        label="No"
                        value="No"
                      />
                    </RadioGroup>
                  </MuiBox>
                  {values.radioAnswer === 'Yes' ? (
                    <>
                      <RequiredLabel labelText="First and Last Name" />
                      <TextField
                        aria-label="Input name"
                        data-testid="name-input"
                        error={!!errors?.contactName}
                        fullWidth
                        helperText={errors?.contactName}
                        onChange={handleChange('contactName')}
                        value={values?.contactName}
                        variant="outlined"
                      />
                      <RequiredLabel labelText="Email Address" />
                      <TextField
                        aria-label="Input email"
                        data-testid="email-input"
                        error={!!errors.contactEmail}
                        fullWidth
                        helperText={errors?.contactEmail}
                        onChange={handleChange('contactEmail')}
                        value={values?.contactEmail}
                        variant="outlined"
                      />
                      <RequiredLabel labelText="Phone" />
                      <TextField
                        aria-label="Input Phone"
                        data-testid="phone-input"
                        error={!!errors?.contactPhone}
                        fullWidth
                        helperText={errors?.contactPhone}
                        onChange={e => {
                          const { value } = e.target;
                          setFieldValue('contactPhone', maskPhone(value));
                        }}
                        value={values?.contactPhone}
                        variant="outlined"
                      />
                    </>
                  ) : null}
                </MuiBox>

                <MuiBox width="100%" p={Spacing.medium}>
                  {error ? (
                    <FlexBox justifyContent="center" hOffsetLeft={Spacing.large}>
                      <MuiTypography color={Color.red}>Error: Please try again later</MuiTypography>
                    </FlexBox>
                  ) : null}

                  <MuiButton
                    color="primary"
                    data-testid="submit-btn"
                    disabled={!isValid || !dirty}
                    fullWidth
                    onClick={handleSubmit}
                    variant="contained"
                  >
                    Submit
                  </MuiButton>
                  <Spacer spacing="medium" />

                  <MuiButton
                    color="primary"
                    data-testid="cancel-btn"
                    fullWidth
                    onClick={navigateToHealthTools}
                    variant="outlined"
                  >
                    Cancel
                  </MuiButton>
                </MuiBox>
              </MuiContainer>
            );
          }}
        </Formik>
      </MuiBox>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  consumerPrimaryPhone: consumerPrimaryPhoneSelector(state),
  consumerPrimaryEmail: consumerPrimaryEmailSelector(state),
  consumerDisplayName: profileConsumerDisplayNameSelector(state),
  userProfile: profileConsumerSelector(state)
});

export default connect(mapStateToProps)(HealthToolsSurveyScreen as ComponentType);
