import * as React from 'react';

const styles = {
  fillRule: 'evenodd',
  clipRule: 'evenodd'
} as const;

export default (props: any) => {
  const { color = 'url(#grad)' } = props;

  return {
    svg: (
      <g>
        <path
          d="M0.25 12.125C0.25 5.56662 5.56662 0.25 12.125 0.25C18.6834 0.25 24 5.56662 24 12.125C24 18.6834 18.6834 24 12.125 24C5.56662 24 0.25 18.6834 0.25 12.125ZM12.125 1.75C6.39505 1.75 1.75 6.39505 1.75 12.125C1.75 17.855 6.39505 22.5 12.125 22.5C17.855 22.5 22.5 17.855 22.5 12.125C22.5 6.39505 17.855 1.75 12.125 1.75Z"
          fill={color}
          {...styles}
        />
        <path
          d="M9.44671 10.267C9.44671 9.8119 9.81566 9.44295 10.2708 9.44295H12.125C12.5801 9.44295 12.949 9.8119 12.949 10.267V17.6837C12.949 18.1388 12.5801 18.5078 12.125 18.5078C11.6698 18.5078 11.3009 18.1388 11.3009 17.6837V11.0911H10.2708C9.81566 11.0911 9.44671 10.7221 9.44671 10.267Z"
          fill={color}
          {...styles}
        />
        <path
          d="M9.44671 17.6837C9.44671 17.2285 9.81566 16.8596 10.2708 16.8596H13.9791C14.4342 16.8596 14.8032 17.2285 14.8032 17.6837C14.8032 18.1388 14.4342 18.5077 13.9791 18.5077H10.2708C9.81566 18.5077 9.44671 18.1388 9.44671 17.6837Z"
          fill={color}
          {...styles}
        />
        <path
          d="M12.1251 6.3854C12.2833 6.3854 12.4115 6.25714 12.4115 6.09894C12.4115 5.94074 12.2833 5.81248 12.1251 5.81248V4.8854C11.4549 4.8854 10.9115 5.42871 10.9115 6.09894C10.9115 6.76916 11.4549 7.31248 12.1251 7.31248V6.3854Z"
          fill={color}
          {...styles}
        />
        <path
          d="M12.1251 4.8854C12.7953 4.8854 13.3385 5.42871 13.3385 6.09894C13.3385 6.76916 12.7953 7.31248 12.1251 7.31248V6.3854C11.9669 6.3854 11.8385 6.25714 11.8385 6.09894C11.8385 5.94074 11.9669 5.81248 12.1251 5.81248V4.8854Z"
          fill={color}
          {...styles}
        />
        <defs>
          <linearGradient
            id="grad"
            x1="0.5"
            y1="12"
            x2="21.5"
            y2="12"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.0843751" stopColor="#FF0048" />
            <stop offset="0.386458" stopColor="#DF36FF" />
            <stop offset="0.980208" stopColor="#4A00E2" />
          </linearGradient>
        </defs>
      </g>
    ),
    viewBox: '0 0 45 45'
  };
};
