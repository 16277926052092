import React, { ComponentType, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import Config from 'react-native-config';
import dayjs from 'dayjs';

import DataLoader from 'components/UI/DataLoader/DataLoader';
import { TextInput } from 'components/TextInput';
import { SpinnerOverlay } from 'components/UI/Spinner/SpinnerModal';
import {
  MuiBox,
  MuiContainer,
  MuiTypography,
  MuiFormControlLabel,
  MuiGrid,
  MuiRadio,
  MuiFormControl,
  MuiRadioGroup,
  MuiDivider
} from 'theme/material-ui';
import { StyledAsterisk } from 'components/RequiredFieldsLegend';
import { Confirm } from 'components/ConfirmDialog/ConfirmDialog';

import { Color } from 'modules/styles/colors';
import { FontSize, FontWeight, Spacing } from 'modules/styles/variables';
import { bookingVisitTypes } from 'modules/constants/booking';
import { formatDateToIso8601 } from 'modules/utils/DateUtils';

import { RootState } from 'store/types';
import * as bookingTypes from 'store/booking/types';
import * as bookingActions from 'store/booking/actions';
import * as bookingSelectors from 'store/booking/selectors';
import {
  WIV_VISIT_TYPE_IDS,
  WCC_VISIT_TYPE_IDS,
  WCE_VISIT_TYPE_IDS
} from 'store/booking/constants';

import analyticsService, { AmplitudeEventData, AnalyticsEvent } from 'services/AnalyticsService';

import BookingSymptomsDialog from '../dialogs/BookingSymptomsDialog';
import { useCheckBookingInfoEffect } from '../useCheckBookingInfoEffect';
import { BookingStepActions, BookingStepAction } from '../components/BookingStepper';
import BookingVisitTypeSelectMoreInfoDialog from '../dialogs/BookingVisitTypeSelectMoreInfoDialog';
import BookingWellChildCheckDialog from '../dialogs/BookingWellChildCheckDialog';
import {
  BookingLoading,
  BookingNoData,
  BookingMoreInfoButton
} from '../components/sharedComponents';
import {
  VisitTypesContainer,
  VisitTypesListContainer,
  VisitTypesFormContainer,
  VisitTypesBottomContainer,
  StepActionsVisitType,
  StyledVisitTypeScreen
} from './styled';
import TypographyInlineWrapper from 'components/UI/Typography/TypographyInLineWrapper';
import { checkIsVisitType } from 'lib/booking/utils';
import { Modal } from 'components/CustomModal';
import Spacer from 'components/UI/Layout/Spacer';
import { VisitType } from 'store/booking/types';

export interface Props extends RouteComponentProps {
  appointmentDetails: bookingTypes.AppointmentDetails;
  groupedVisitTypes: bookingTypes.GroupedVisitType[];
  groupedVisitTypesError: bookingTypes.GroupedVisitTypeError;
  patients: bookingTypes.Patient[];
  isFetchingGroupedVisitTypes: boolean;
  getGroupedVisitTypes: typeof bookingActions.getGroupedVisitTypes;
  setGroupedVisitType: typeof bookingActions.setGroupedVisitType;
  setAppointmentDueDate: typeof bookingActions.setAppointmentDueDate;
  currentRouteName?: string;
  previousRouteName?: string;
  clearGroupedVisitTypes: typeof bookingActions.clearGroupedVisitTypes;
  fetchSlots: boolean;
  setFetchSlots: typeof bookingActions.setFetchSlots;
  updatedVisitGroup: boolean;
  setUpdatedVisitGroup: typeof bookingActions.setUpdatedVisitGroup;
}

export function BookingVisitTypeSelect({
  appointmentDetails,
  groupedVisitTypes,
  groupedVisitTypesError,
  isFetchingGroupedVisitTypes,
  patients,
  getGroupedVisitTypes,
  setGroupedVisitType,
  clearGroupedVisitTypes,
  setAppointmentDueDate,
  currentRouteName,
  previousRouteName,
  fetchSlots,
  setFetchSlots,
  history,
  updatedVisitGroup,
  setUpdatedVisitGroup
}: Props) {
  const eventData: AmplitudeEventData = {
    currentUrl: currentRouteName,
    referringUrl: previousRouteName
  };

  const [isGetGroupedVisitTypesCalled, setIsGetGroupedVisitTypesCalled] = useState(false);
  const [isMoreInfoDialogOpen, setMoreInfoDialogOpen] = useState(false);
  const [isSymptomsDialogOpen, setSymptomsDialogOpen] = useState(false);
  const [isWellChildCheckDialogOpen, setWellChildCheckDialogOpen] = useState(false);
  const [isBehavioralHealthType, setIsBehavioralHealthType] = useState(false);
  const [isOPHVisitType, setIsOPHVisitType] = useState(false);
  const [expectedDueDate, setExpectedDueDate] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const { ObGynVisitType } = bookingTypes;

  useCheckBookingInfoEffect(appointmentDetails?.patient);

  const fetchVisitTypes = () => {
    if (appointmentDetails?.patient && appointmentDetails?.doctor) {
      const selectedPatient = patients.filter(
        patient => patient?.consumerId === appointmentDetails?.patient?.consumerId
      );
      getGroupedVisitTypes(
        appointmentDetails.patient.cernerPersonId,
        appointmentDetails.patient.consumerId,
        appointmentDetails.doctor.cernerResourceId,
        formatDateToIso8601(selectedPatient?.[0]?.dateOfBirth)
      );
      setIsGetGroupedVisitTypesCalled(true);
    }
  };

  const WellCheckSecondaryDescription = () => (
    <>
      <MuiBox marginTop={Spacing.xSmall} marginBottom={Spacing.xSmall}>
        <MuiTypography>
          At this exam, the physician or advanced practice provider will evaluate your teen's
          growth, development and overall health in order to identify or prevent potential problems
          and promote your teen’s well–being. Screening for behavioral health concerns will start at
          11 years old.
        </MuiTypography>
      </MuiBox>
      <MuiBox marginBottom={Spacing.xSmall}>
        <MuiTypography>
          Most of the insurance companies require that they are done no sooner than 366 days apart.
        </MuiTypography>
      </MuiBox>
      <MuiBox marginBottom={Spacing.xSmall}>
        <MuiTypography fontWeight={FontWeight.bold}>
          If you are scheduling the 11 year, it cannot be any sooner than the child's 11 year
          birthday.
        </MuiTypography>
      </MuiBox>
    </>
  );

  const handleVisitTypeSelect = (groupedVisitType: bookingTypes.GroupedVisitType) => {
    if (
      fetchSlots ||
      !appointmentDetails.groupedVisitType ||
      groupedVisitType.id !== appointmentDetails.groupedVisitType?.id
    ) {
      setUpdatedVisitGroup(true);
    }
    setGroupedVisitType(groupedVisitType);

    const isWIV = groupedVisitType.visitTypes?.some(
      ({ visitTypeId }: Partial<VisitType>) =>
        visitTypeId && WIV_VISIT_TYPE_IDS.includes(visitTypeId)
    );

    if (isWIV) {
      Confirm.show(
        'If the patient is less than 3 months old with a fever of 100.4ºF or greater, please call the clinic immediately.',
        '',
        null,
        'info',
        { text: 'Continue', onClick: () => {} }
      );
    }

    const isWCC = groupedVisitType.visitTypes?.some(
      ({ visitTypeId }: Partial<VisitType>) =>
        visitTypeId && WCC_VISIT_TYPE_IDS.includes(visitTypeId)
    );

    if (isWCC) {
      setWellChildCheckDialogOpen(true);
    }

    const isWCE = groupedVisitType.visitTypes?.some(
      ({ visitTypeId }: Partial<VisitType>) =>
        visitTypeId && WCE_VISIT_TYPE_IDS.includes(visitTypeId)
    );

    if (isWCE) {
      Confirm.show(
        'When do I schedule this visit type?',
        WellCheckSecondaryDescription(),
        null,
        'info',
        {
          text: 'Continue',
          onClick: () => {}
        }
      );
    }

    const isOPH = checkIsVisitType(groupedVisitTypes, 'OPH');
    if (isOPH) {
      Modal.show({
        title: (
          <>
            <MuiTypography color={Color.primary} fontWeight={FontWeight.semibold}>
              Appointment Reminder:
            </MuiTypography>
            <Spacer spacing="large" />
          </>
        ),
        description: (
          <MuiTypography fontSize={FontSize.base} style={{ fontStyle: 'italic' }}>
            <StyledAsterisk />
            {bookingVisitTypes.GET_VISIT_TYPES.OPHTHALMOLOGIC_INFO.popupMessage}
            <StyledAsterisk />
          </MuiTypography>
        ),
        buttons: {},
        backdrop: { skipBackdropClose: true }
      });
    }

    analyticsService.logEvent(AnalyticsEvent.AppointmentTypeSelected, eventData);
  };

  useEffect(() => {
    fetchVisitTypes();
  }, [appointmentDetails.patient, appointmentDetails.doctor]);

  useEffect(() => {
    return () => {
      if (history.action === 'POP') {
        clearGroupedVisitTypes();
      }
    };
  }, [history]);

  useEffect(() => {
    if (groupedVisitTypes?.length === 1) {
      handleVisitTypeSelect(groupedVisitTypes[0]);
    } else if (!groupedVisitTypes?.length) {
      if (groupedVisitTypesError?.errorCode) {
        let error = '';
        switch (groupedVisitTypesError.errorCode) {
          case 'SCH-300':
            error = bookingVisitTypes.GET_VISIT_TYPES.NO_RELATIONSHIP_WITH_PROVIDER.message;
            break;
          case 'SCH-301':
          case 'SCH-302':
            error = bookingVisitTypes.GET_VISIT_TYPES.NO_DATA.message;
            break;
          default:
            error = bookingVisitTypes.GET_VISIT_TYPES.SERVICE_DOWN.message;
            break;
        }
        setErrorMessage(error);
      } else {
        setErrorMessage(bookingVisitTypes.GET_VISIT_TYPES.NO_DATA.message);
      }
    }

    const isBH = checkIsVisitType(groupedVisitTypes, 'PS');
    setIsBehavioralHealthType(isBH);

    const isOPH = checkIsVisitType(groupedVisitTypes, 'OPH');
    setIsOPHVisitType(isOPH);
  }, [groupedVisitTypes, groupedVisitTypesError]);

  const getVisitTypeSelected = (groupedVisitType: bookingTypes.GroupedVisitType) => {
    if (!groupedVisitType || !appointmentDetails.groupedVisitType) {
      return false;
    }

    return appointmentDetails.groupedVisitType.id === groupedVisitType.id;
  };

  const handleMoreInfoOpen = () => {
    const helpUsedData: AmplitudeEventData = {
      ...eventData,
      source_page: 'SelectVisitTypePage(MoreInfo)'
    };
    analyticsService.logEvent(AnalyticsEvent.BookingHelpUsed, helpUsedData);
    setMoreInfoDialogOpen(true);
  };

  const handleSymptomsDialog = () => {
    const helpUsedData: AmplitudeEventData = {
      ...eventData,
      source_page: 'SelectVisitTypePage(EmergencySymptom)'
    };
    analyticsService.logEvent(AnalyticsEvent.BookingHelpUsed, helpUsedData);
    setSymptomsDialogOpen(true);
  };

  const handleExpectedDueDate = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    setExpectedDueDate(value);
  };

  const handleNext = () => {
    if (expectedDueDate && appointmentDetails.groupedVisitType?.name === ObGynVisitType.prenatal) {
      setAppointmentDueDate(expectedDueDate);
    }
    setFetchSlots(fetchSlots || updatedVisitGroup);
    history.push('/u/get-care-now/booking/location-preference-select');
  };

  const handlePrevious = () => {
    history.goBack();
  };

  let disableNextButton = !appointmentDetails.groupedVisitType?.id;
  // validate expectedDueDate for a valid format
  if (appointmentDetails.groupedVisitType?.name === ObGynVisitType.prenatal) {
    disableNextButton =
      dayjs(expectedDueDate, 'MM/DD/YYYY').format('MM/DD/YYYY') !== expectedDueDate;
  }

  return (
    <>
      <StyledVisitTypeScreen>
        <MuiContainer maxWidth="lg" style={{ height: 'inherit' }}>
          <MuiBox my={3} style={{ height: 'inherit' }}>
            <MuiGrid container>
              <MuiGrid item xs>
                <MuiTypography gutterBottom variant="h4">
                  Select Visit Type
                </MuiTypography>
              </MuiGrid>
              <MuiGrid item>
                <BookingMoreInfoButton
                  testID="booking-visit-type-select-more-info-button"
                  handleOpen={handleMoreInfoOpen}
                />
              </MuiGrid>
            </MuiGrid>
            <MuiBox mb={Spacing.smallMedium}>
              <MuiDivider />
            </MuiBox>
            <VisitTypesContainer>
              <DataLoader
                loading={isFetchingGroupedVisitTypes || !isGetGroupedVisitTypesCalled}
                data={groupedVisitTypes}
                renderLoading={() => <BookingLoading />}
                renderNoData={() => <BookingNoData message={errorMessage} />}
                renderError={() => (
                  <BookingNoData message={bookingVisitTypes.GET_VISIT_TYPES.SERVICE_DOWN.message} />
                )}
                renderData={data => (
                  <VisitTypesListContainer>
                    <MuiFormControl component="fieldset">
                      {data.map((groupedVisitType, index) => (
                        <MuiRadioGroup
                          data-testid="booking-visit-type-select-radio-group"
                          onChange={() => handleVisitTypeSelect(groupedVisitType)}
                          key={groupedVisitType?.name}
                        >
                          <MuiBox paddingY={Spacing.xSmall}>
                            <MuiFormControlLabel
                              style={{ display: 'flex', alignItems: 'flex-start' }}
                              value=""
                              key={groupedVisitType?.id}
                              control={
                                <MuiRadio
                                  style={{ paddingTop: 0, paddingBottom: 0 }}
                                  color="secondary"
                                  checked={getVisitTypeSelected(groupedVisitType)}
                                />
                              }
                              label={groupedVisitType?.name}
                              data-testid={`booking-visit-type-select-radio-group-${index}`}
                            />
                          </MuiBox>
                        </MuiRadioGroup>
                      ))}
                    </MuiFormControl>
                  </VisitTypesListContainer>
                )}
              />
              {isOPHVisitType ? (
                <MuiBox maxWidth="450px" margin="auto">
                  <MuiTypography
                    data-testid="booking-visit-type-ophthalmology"
                    fontSize={FontSize.base}
                    style={{ fontStyle: 'italic' }}
                  >
                    <StyledAsterisk />
                    {bookingVisitTypes.GET_VISIT_TYPES.OPHTHALMOLOGIC_INFO.message}
                    <StyledAsterisk />
                  </MuiTypography>
                </MuiBox>
              ) : null}
              {Config.OBGYN === 'enabled' ? (
                <VisitTypesFormContainer>
                  {appointmentDetails.groupedVisitType?.name === ObGynVisitType.prenatal ? (
                    <>
                      <TextInput
                        label={
                          <MuiTypography>
                            Expected Due Date&nbsp;
                            <StyledAsterisk />
                          </MuiTypography>
                        }
                        placeholder="MM/DD/YYYY"
                        onChange={handleExpectedDueDate}
                        value={expectedDueDate}
                        mask="date"
                        variant="outlined"
                        fullWidth
                      />
                      <MuiBox marginTop={Spacing.xSmall}>
                        <MuiTypography fontSize={FontSize.small}>
                          Call the clinic if this is your first visit for a new pregnancy.
                        </MuiTypography>
                      </MuiBox>
                    </>
                  ) : null}
                  {appointmentDetails.groupedVisitType?.name === ObGynVisitType.annual ? (
                    <MuiBox marginTop={Spacing.xSmall}>
                      <MuiTypography fontSize={FontSize.small}>
                        These appointments are only for annual exams. Please call the clinic for
                        other reasons.
                      </MuiTypography>
                    </MuiBox>
                  ) : null}
                </VisitTypesFormContainer>
              ) : null}
              <VisitTypesBottomContainer>
                {isBehavioralHealthType ? (
                  <MuiTypography
                    data-testid="booking-visit-type-info-text"
                    fontSize={FontSize.small}
                  >
                    {bookingVisitTypes.GET_VISIT_TYPES.BEHAVIORAL_HEALTH_INFO.message}
                  </MuiTypography>
                ) : (
                  <MuiTypography
                    data-testid="booking-visit-type-info-text"
                    fontSize={FontSize.small}
                  >
                    If you are experiencing any of these{' '}
                    <TypographyInlineWrapper
                      color={Color.link}
                      onClick={() => handleSymptomsDialog()}
                    >
                      symptoms
                    </TypographyInlineWrapper>{' '}
                    please go to Emergency Room or call 911.{' '}
                  </MuiTypography>
                )}
                <StepActionsVisitType>
                  <BookingStepActions>
                    <BookingStepAction
                      data-testid="next-button"
                      disabled={disableNextButton}
                      onClick={handleNext}
                    />
                    <BookingStepAction
                      onClick={handlePrevious}
                      data-testid="booking-visit-type-select-previous-button"
                    />
                  </BookingStepActions>
                </StepActionsVisitType>
              </VisitTypesBottomContainer>
            </VisitTypesContainer>
          </MuiBox>
        </MuiContainer>
      </StyledVisitTypeScreen>

      <BookingVisitTypeSelectMoreInfoDialog
        open={isMoreInfoDialogOpen}
        visitTypes={groupedVisitTypes}
        handleClose={() => setMoreInfoDialogOpen(false)}
      />
      <BookingSymptomsDialog
        open={isSymptomsDialogOpen}
        handleClose={() => setSymptomsDialogOpen(false)}
      />
      <BookingWellChildCheckDialog
        open={isWellChildCheckDialogOpen}
        handleClose={() => setWellChildCheckDialogOpen(false)}
      />

      <SpinnerOverlay
        isLoading={isFetchingGroupedVisitTypes}
        position="absolute"
        backgroundColor={Color.translucent}
        testID="booking-visit-types-loading"
      />
    </>
  );
}

const mapStateToProps = (state: RootState) => {
  return {
    appointmentDetails: bookingSelectors.appointmentDetailsSelector(state),
    fetchSlots: bookingSelectors.fetchSlotsSelector(state),
    groupedVisitTypes: bookingSelectors.groupedVisitTypesDataSelector(state),
    groupedVisitTypesError: bookingSelectors.groupedVisitTypesErrorSelector(state),
    isFetchingGroupedVisitTypes: bookingSelectors.groupedVisitTypesFetchingSelector(state),
    patients: bookingSelectors.patientsDataSelector(state),
    updatedVisitGroup: bookingSelectors.updatedVisitGroupHasBeenSelected(state)
  };
};

const mapDispatchToProps = {
  clearGroupedVisitTypes: bookingActions.clearGroupedVisitTypes,
  getGroupedVisitTypes: bookingActions.getGroupedVisitTypes,
  setAppointmentDueDate: bookingActions.setAppointmentDueDate,
  setFetchSlots: bookingActions.setFetchSlots,
  setGroupedVisitType: bookingActions.setGroupedVisitType,
  setUpdatedVisitGroup: bookingActions.setUpdatedVisitGroup
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BookingVisitTypeSelect as ComponentType);
