import React, { ComponentType, useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';

import People from 'assets/Peopels.png';
import { MuiContainer, MuiTypography, MuiBox } from 'theme/material-ui';
import * as covidScreeningSelectors from 'store/CovidScreening/selectors';
import { RootState } from 'store/types';
import { Patient } from 'store/CovidScreening/types';
import { AuthType } from 'store/authentication/types';
import { covidTestingFacilities, myHealthPlus } from 'modules/constants/urls';
import { FontSize, FontWeight } from 'modules/styles/variables';
import { Color } from 'modules/styles/colors';
import { AnalyticsEvent } from 'services/AnalyticsService';
import { CovidTitles } from 'lib/constants/covidScreening';
import { useLanguageSwitcher } from 'lib/hooks/useLanguageSwitcher';
import { translations } from 'lib/constants/translations/screens/covidGuest/confirmationPage';
import {
  CovidTestGuestFooter_translations,
  ButtonsNavigation_translations
} from 'lib/constants/translations/components/index';

import Spacer from 'components/UI/Layout/Spacer';
import Footer from 'components/common/Footer';
import useNavigationAnalytics from 'hooks/useNavigationAnalytics';
import FlexBox from 'components/UI/Layout/FlexBox';
import { Svg } from 'components/UI/Svg';

import { Container, StyledImage } from './styled';
import CovidGuestGuard from '../navigation/CovidGuestLeavingGuard';
import { CovidTestButton } from '../../CovidScreening/Components/CovidTestButton';

export interface Props extends RouteComponentProps {
  patientsData: Patient;
}

const healthPlusAccPerks = (screenText: object = {}) => [
  {
    title: screenText.personalized_care,
    desc: screenText.health_info
  },
  {
    title: screenText.notifications,
    desc: screenText.receive_alerts
  },
  {
    title: screenText.access_doctors,
    desc: screenText.connect_providers
  }
];

export function CovidConfirmation({ history, patientsData }: Props) {
  const facilitiesLink = useNavigationAnalytics(AnalyticsEvent.CovidFacilitiesLinkClicked);
  const covidOrderConfirmation = useNavigationAnalytics(AnalyticsEvent.CovidOrderCompleted);
  const covidScreeningClosed = useNavigationAnalytics(AnalyticsEvent.CovidScreeningClosed);
  const createMyHealthAccount = useNavigationAnalytics(AnalyticsEvent.MyHealthAccountClicked);
  const handleAnalyticsAdditionalTest = useNavigationAnalytics(AnalyticsEvent.CovidAdditionalTest);
  const screenText = useLanguageSwitcher({
    en: {
      ...translations.en,
      ...CovidTestGuestFooter_translations.en,
      ...ButtonsNavigation_translations.en
    },
    es: {
      ...translations.es,
      ...CovidTestGuestFooter_translations.es,
      ...ButtonsNavigation_translations.es
    }
  });

  const handleClose = () => {
    covidScreeningClosed({
      title: CovidTitles.CONFIRMATION_PAGE,
      authentication_type: AuthType.UNAUTHENTICATED
    });
    history.push('/guest-home');
  };
  const handleCreateAccount = () => {
    createMyHealthAccount({
      title: CovidTitles.CONFIRMATION_PAGE,
      authentication_type: AuthType.UNAUTHENTICATED
    });
    window.open(myHealthPlus);
  };
  const onFacilitiesLinkClick = () => {
    facilitiesLink({
      title: CovidTitles.CONFIRMATION_PAGE,
      authentication_type: AuthType.UNAUTHENTICATED
    });
    window.open(covidTestingFacilities, '_blank');
  };

  const onOrderNewCovidTest = () => {
    handleAnalyticsAdditionalTest({
      title: CovidTitles.CONFIRMATION_PAGE,
      authentication_type: AuthType.UNAUTHENTICATED
    });
    history.push('/guest-home/covid-screen/consent-and-agreement');
  };

  useEffect(() => {
    covidOrderConfirmation({
      covid19_order: 'Success',
      title: CovidTitles.CONFIRMATION_PAGE,
      authentication_type: AuthType.UNAUTHENTICATED
    });
    window.history.pushState(null, document.title, window.location.href);
  }, []);
  window.onpopstate = () => handleClose();
  return (
    <>
      <CovidGuestGuard />
      <MuiContainer maxWidth="lg">
        <Spacer size="medium" />
        <MuiBox my={2}>
          <MuiTypography variant="h4" align="center">
            {screenText.status_title}
          </MuiTypography>
        </MuiBox>
        <Spacer size="medium" />
        <MuiBox my={2}>
          <MuiTypography align="justify">{screenText.paragraph}</MuiTypography>
          <Spacer size="small" />
          <MuiTypography
            display="inline"
            onClick={onFacilitiesLinkClick}
            color={Color.primaryUnderlay}
          >
            {screenText.link}
          </MuiTypography>
          <Spacer size="small" />
          <MuiTypography align="justify">{screenText.site_take_test}</MuiTypography>
          <Spacer size="medium" />
          <MuiTypography variant="h6" align="center">
            {screenText.email_send}
          </MuiTypography>
          <MuiTypography variant="h6" align="center">
            {screenText.email_send_2} {patientsData?.emails[0]?.address} {screenText.email_send_3}
          </MuiTypography>
        </MuiBox>
        <Footer
          headerText={screenText.covidInfo}
          link={screenText.CovidPatientSelectHelpline}
          linkName={screenText.CovidPatientSelectHelpline}
          helplineHours={screenText.CovidHelplineHours}
          title={CovidTitles.CONFIRMATION_PAGE}
          authenticated={false}
        />
        <Spacer size="medium" />
        <FlexBox alignItems="center">
          <CovidTestButton
            data-testid="new-order-button"
            variant="contained"
            onClick={onOrderNewCovidTest}
            size="large"
            startIcon={<Svg set="assets" name="COVID19" color={Color.white} />}
          >
            {screenText.another_test}
          </CovidTestButton>
        </FlexBox>
        <Spacer size="large" />
        <Container>
          <MuiBox maxWidth="465px">
            <StyledImage height={80} width={160} src={People} />
          </MuiBox>
          <Spacer size="medium" />
          <Spacer size="small" />
          <MuiBox maxWidth="70%" alignSelf="center">
            <MuiTypography
              variant="h6"
              align="left"
              fontSize={FontSize.large}
              fontWeight={FontWeight.semibold}
              color={Color.textDark}
            >
              {screenText.create_account}
            </MuiTypography>
            <Spacer size="medium" />
            {healthPlusAccPerks(screenText).map(perk => (
              <>
                <MuiTypography
                  variant="h6"
                  align="left"
                  fontSize={FontSize.base}
                  color={Color.secondary}
                  fontWeight={FontWeight.bold}
                >
                  {perk?.title}
                </MuiTypography>
                <Spacer size="xxsmall" />
                <MuiTypography
                  variant="h6"
                  align="left"
                  fontSize={FontSize.base}
                  color={Color.textLight}
                >
                  {perk?.desc}
                </MuiTypography>
                <Spacer size="small" />
              </>
            ))}
          </MuiBox>
          <MuiBox width="284px" margin="28px auto" alignItems="center">
            <CovidTestButton
              fullWidth
              style={{
                height: 45,
                fontSize: FontSize.base,
                background: Color.secondary,
                fontWeight: FontWeight.normal
              }}
              data-testid="Create-Acc"
              variant="contained"
              onClick={handleCreateAccount}
            >
              {screenText.create_account_button}
            </CovidTestButton>
          </MuiBox>
        </Container>
        <Spacer size="small" />
        <MuiBox width="284px" margin="0 auto" alignItems="center">
          <CovidTestButton
            fullWidth
            style={{ height: 45, fontSize: FontSize.base, fontWeight: FontWeight.normal }}
            data-testid="Close"
            color="primary"
            variant="text"
            onClick={handleClose}
          >
            {screenText.close}
          </CovidTestButton>
        </MuiBox>
        <Spacer size="medium" />
      </MuiContainer>
    </>
  );
}

const mapStateToProps = (state: RootState) => ({
  patientsData: covidScreeningSelectors.CovidScreeningPatientInfoSelector(state)
});

export default connect(mapStateToProps)(CovidConfirmation as ComponentType);
