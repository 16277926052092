import React from 'react';
import { connect } from 'react-redux';
import * as H from 'history';
import { useHistory } from 'react-router';
import { handleUrlOnCancel } from 'store/pageActions/RegisterAccount';
import { FontSize, FontWeight } from 'modules/styles/variables';
import { MuiBox } from 'theme/material-ui';
import SvgButton from 'components/UI/Svg/SvgButton';
import { RegistrationTitle, CrossIconContainer } from '../styled';
import { getRegisterBrand } from 'store/register';

interface Props {
  handleUrlOnCancel: (history: H.History) => void;
}

const RegisterHeader = ({ handleUrlOnCancel }: Props) => {
  const history = useHistory();
  const registerBrand = getRegisterBrand();
  return (
    <>
      <MuiBox py="16px">
        <RegistrationTitle
          textAlign="center"
          tag="h2"
          fontWeight={FontWeight.normal}
          fontSize={FontSize.largeHeading + 10}
          data-testid="create-account-title"
          registerBrand={registerBrand}
        >
          Create Account
          <CrossIconContainer width={40}>
            <SvgButton
              appearance="transparent"
              set="material"
              name="close"
              accessibilityLabel="Close"
              onClick={() => handleUrlOnCancel(history)}
            />
          </CrossIconContainer>
        </RegistrationTitle>
      </MuiBox>
    </>
  );
};

const mapDispatchToProps = {
  handleUrlOnCancel
};

export default connect(null, mapDispatchToProps)(RegisterHeader);
