import CallToActionRow from 'components/common/CallToActionRow/CallToActionRow';
import CollapsibleListItem from 'components/common/CollapsibleList/CollapsibleListItem';
import HealthRecordBanner from 'components/common/HealthRecordBanner/HealthRecordBanner';
import DiagnosesCollapsibleListItem from 'components/Diagnoses/DiagnosesCollapsibleListItem';
import MedicationsCollapsibleListItem from 'components/Medications/MedicationsCollapsibleListItem';
import DataLoader from 'components/UI/DataLoader/DataLoader';
import Divider from 'components/UI/Divider';
import FlexBox from 'components/UI/Layout/FlexBox';
import Spinner from 'components/UI/Spinner/Spinner';
import DefaultText from 'components/UI/Typography/DefaultText';
import VisitSummaryVitals from 'components/vitals/VitalsSummary/VisitSummaryVitals/VisitSummaryVitals';
import analyticsService, { AmplitudeEventData, AnalyticsEvent } from 'services/AnalyticsService';
import {
  currentLocationPathNameSelector,
  previousLocationPathNameSelector
} from 'store/router/selectors';
import { useGetMedicalTestResultListByEncounter } from 'hooks/queries/useGetMedicalTestResultListByEncounter';
import upperFirst from 'lodash/upperFirst';
import { Spacing } from 'modules/styles/variables';
import { MhpDetails } from 'modules/types/common';
import { objectFromQsParam } from 'modules/utils/UrlUtils';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Config from 'react-native-config';
import { useParams } from 'react-router-dom';
import { HealthRecordsListItemPlaceholder } from 'screens/HealthRecords/shared';
import { NavigationScreenProps } from 'screens/navigation';
import LinkingServices from 'services/LinkingServices';
import { isImpersonatingSelector } from 'store/account/selectors';
import { profileConsumerSelector } from 'store/profile/selectors';
import { Consumer } from 'store/profile/types';
import { getDetailsPath } from 'store/testResults/utils';
import { RootDispatch, RootState } from 'store/types';
import {
  getVisitSummaryCcd,
  getVisitSummaryDetails,
  replyToVisitSummary,
  resetVisitSummaryDetails
} from 'store/visitSummary/actions.web';
import * as visitSummarySelectors from 'store/visitSummary/selectors';
import { Procedure, VisitSummaryDetails } from 'store/visitSummary/types';
import {
  downloadVitalsByEncounter,
  getVitalsByEncounterId,
  replyToVitals
} from 'store/vitals/actions';
import * as vitalsSelectors from 'store/vitals/selectors';
import { VitalsBlock } from 'store/vitals/types';
import { MuiBox, MuiContainer, MuiGrid, MuiList, MuiPaper } from 'theme/material-ui';
import { VisitSummaryTypes } from './constants';
import Billing from './Sections/Billing';
import Claims from './Sections/Claims';
import { PatientInstructions } from './Sections/PatientInstructions';
import { Resources } from './Sections/Resources';
import WeDiscussed from './Sections/WeDiscussed';
import { ListItem } from '../../components/UI/ListItem/ListItem';
import { activeEncounterMedicationsDataSelector } from 'store/medications/selectors';
import { Medication } from 'store/medications/types';
import { getMedicationsByEncounterId } from 'store/medications/actions';
import { patchEventBySourceId } from 'store/events/actions.common';
import TestResultsRowItem from 'screens/HealthRecords/TestResults/TestResultsRowItem';
import DownloadErrorSnackbar from 'components/DownloadErrorSnackbar/DownloadErrorSnackbar';
import Screen from 'components/UI/Layout/Screen';

export interface VisitSummaryLiveParams {
  id: string;
  source: string;
  encounterId: string;
  dateOfEncounter: string;
  mhpDetails: string;
}

interface Props extends NavigationScreenProps {
  detailsError: any;
  dispatch: RootDispatch;
  isImpersonating: boolean;
  loadingAll: boolean;
  profileConsumer: Consumer;
  visitSummaryDetails: VisitSummaryDetails;
  visitSummaryDownloads: ReturnType<typeof visitSummarySelectors.visitSummaryDownloadsSelector>;
  vitals: VitalsBlock[];
  vitalsDownloadsByEncounter: ReturnType<typeof vitalsSelectors.vitalsDownloadsByEncounterSelector>;
  currentUrl?: string;
  referringUrl?: string;
  medications: Medication[];
}

const VisitSummaryLoading = () => (
  <FlexBox flex={1} alignItems="center" justifyContent="center">
    <Spinner />
  </FlexBox>
);
const getVisitTitle = (visitType: string) => {
  if (visitType) {
    return `${upperFirst(visitType)} Visit Summary`;
  }

  return 'Visit Summary';
};

export function VisitSummaryLive({
  detailsError,
  dispatch,
  isImpersonating,
  loadingAll,
  profileConsumer,
  visitSummaryDetails,
  visitSummaryDownloads,
  vitals,
  vitalsDownloadsByEncounter,
  currentUrl,
  referringUrl,
  medications
}: Props) {
  const { source, encounterId, dateOfEncounter, mhpDetails } = useParams<VisitSummaryLiveParams>();
  const {
    patient,
    visitType,
    primaryProvider,
    fin: accountNumber,
    locationObject,
    visitDescription,
    dischargeDate,
    dischargeDisposition
  } = visitSummaryDetails;

  const providerName = primaryProvider?.providerName || 'Provider not available';
  const providerImageUrl = primaryProvider?.providerImageUrl || '';
  const isSelectHealth = primaryProvider?.isSelectHealth || false;
  const phoneNumber = primaryProvider?.providerPhoneNumber || '';
  const locationName =
    Config.EVISITS === 'enabled' && locationObject?.locationName?.includes('CCCL_EVisit')
      ? 'E-Visit appointment'
      : locationObject?.locationName;
  const visitTitle = getVisitTitle(visitType);
  const isDownloadingVisitSummaryCcd = visitSummaryDownloads[encounterId] === 'pending';
  const downloadError = visitSummaryDownloads[encounterId] === 'fail';
  const isDownloadingVitals = vitalsDownloadsByEncounter[encounterId] === 'pending';
  const isLegacyOrInpatientVisit =
    visitType === VisitSummaryTypes.Inpatient || visitType === VisitSummaryTypes.Legacy;
  const description =
    Config.EVISITS === 'enabled' && locationObject?.locationName?.includes('CCCL_EVisit')
      ? `${visitDescription} E-Visit`
      : visitDescription;

  const encounterTestResultList = useGetMedicalTestResultListByEncounter({
    encounterId
  });

  const { error: hasTestResultsError, data: testResults = [] } = encounterTestResultList;

  const data: AmplitudeEventData = {
    currentUrl,
    referringUrl
  };

  const handleReplyToVisitSummary = () => {
    dispatch(replyToVisitSummary(visitSummaryDetails, false, currentUrl));
  };

  const handleShareVisitSummary = () => {
    analyticsService.logEvent(AnalyticsEvent.ShareVisitSummaryClicked, data);
    dispatch(replyToVisitSummary(visitSummaryDetails, true, currentUrl));
  };

  const handleDownloadVisitSummary = () => {
    analyticsService.logEvent(AnalyticsEvent.DownloadVisitSummaryClicked, data);
    dispatch(getVisitSummaryCcd(encounterId));
  };

  const handleShareVitals = () => {
    dispatch(replyToVitals(vitals, currentUrl));
  };

  useEffect(() => {
    dispatch(patchEventBySourceId(encounterId, { read: true }));
    return () => {
      dispatch(resetVisitSummaryDetails());
    };
  }, []);

  const fetchVisitSummary = () => {
    dispatch(getVitalsByEncounterId(encounterId));
    dispatch(
      getVisitSummaryDetails(
        source,
        encounterId,
        dateOfEncounter,
        objectFromQsParam(mhpDetails) as MhpDetails
      )
    );
    dispatch(getMedicationsByEncounterId(encounterId));
  };

  useEffect(() => {
    fetchVisitSummary();
  }, [encounterId]);

  const YouCameFor = () => {
    const chiefComplaintValue = visitSummaryDetails?.chiefComplaint?.valueString || '';
    const diagnoses = visitSummaryDetails?.diagnoses;

    if (!isLegacyOrInpatientVisit && chiefComplaintValue) {
      return (
        <>
          <CollapsibleListItem title="You came for" subtitle={chiefComplaintValue}>
            <MuiBox width="100%">
              <MuiPaper elevation={2}>
                <MuiGrid container aria-label="you came for">
                  <MuiBox p={3}>{chiefComplaintValue}</MuiBox>
                </MuiGrid>
              </MuiPaper>
            </MuiBox>
          </CollapsibleListItem>
          <Divider />
        </>
      );
    }

    if (isLegacyOrInpatientVisit && diagnoses && diagnoses.length > 0) {
      return (
        <>
          <DiagnosesCollapsibleListItem diagnoses={diagnoses} />
          <Divider />
        </>
      );
    }

    return null;
  };

  const WhatHappened = () => {
    const procedures = visitSummaryDetails?.procedures || [];

    if (procedures.length) {
      return (
        <>
          <CollapsibleListItem
            title="What happened"
            subtitle={`${procedures.length} service${
              procedures.length > 1 ? 's were' : ' was'
            } ordered or conducted`}
          >
            <MuiBox width="100%">
              <DataLoader
                data={procedures}
                renderData={() => (
                  <MuiPaper elevation={2}>
                    <MuiList>
                      {procedures.map((item: Procedure, index: number) => (
                        <ListItem key={item.name} title={`${index + 1}. ${item.name}`} />
                      ))}
                    </MuiList>
                  </MuiPaper>
                )}
              />
            </MuiBox>
          </CollapsibleListItem>
          <Divider />
        </>
      );
    }
    return null;
  };

  const getProviderName = (providerNames: string[]) =>
    providerNames?.[0] || 'Provider not available';

  const TestResults = () => {
    if (testResults.length > 0 || hasTestResultsError) {
      return (
        <>
          <CollapsibleListItem title="Test results" subtitle={`${testResults.length} available`}>
            <MuiBox width="100%">
              <DataLoader
                data={testResults}
                error={hasTestResultsError}
                renderData={() => (
                  <>
                    <MuiPaper elevation={2}>
                      <MuiList>
                        {testResults.map(item => (
                          <TestResultsRowItem
                            testID="test-results-content"
                            key={item.eventId}
                            date={item.testDate}
                            resultName={item.name}
                            providerName={getProviderName(item.providerNames)}
                            read={item.isRead}
                            to={{
                              pathname: getDetailsPath(item),
                              state: { result: item }
                            }}
                          />
                        ))}
                      </MuiList>
                    </MuiPaper>
                  </>
                )}
                renderNoData={() => (
                  <HealthRecordsListItemPlaceholder message="No test results data available" />
                )}
                renderError={() => <HealthRecordsListItemPlaceholder />}
              />
            </MuiBox>
          </CollapsibleListItem>
          <Divider />
        </>
      );
    }

    return null;
  };

  const ResourcesListItem = () => {
    const documents = visitSummaryDetails?.documents;

    if (!documents) {
      return null;
    }

    return <Resources documents={documents} />;
  };

  const BillingItem = () => {
    return (
      <Billing
        accountNumber={accountNumber}
        isImpersonating={isImpersonating}
        profileConsumer={profileConsumer}
      />
    );
  };

  const ClaimItem = () => {
    return (
      <Claims
        date={visitSummaryDetails.date}
        accountNumber={accountNumber}
        isImpersonating={isImpersonating}
        profileConsumer={profileConsumer}
      />
    );
  };

  const renderVitals = () => {
    if (vitals.length > 0) {
      return (
        <>
          <CollapsibleListItem
            title="Vitals"
            subtitle="Heart rate, blood pressure, temperature"
            expandOnFirstRender={isDownloadingVitals}
          >
            <MuiBox>
              <VisitSummaryVitals />
              <CallToActionRow
                onDownloadPress={() => dispatch(downloadVitalsByEncounter(encounterId))}
                isDownloading={isDownloadingVitals}
                onTransmitPress={handleShareVitals}
              />
            </MuiBox>
          </CollapsibleListItem>
          <Divider />
        </>
      );
    }

    return null;
  };

  const medicationsUpdates = () => {
    if (medications && medications.length > 0) {
      return (
        <>
          <MedicationsCollapsibleListItem medications={medications} isVisitSummary />
          <Divider />
        </>
      );
    }

    return null;
  };

  const renderError = () => (
    <FlexBox
      flexDirection="row"
      justifyContent="space-around"
      alignItems="center"
      vSpacing={Spacing.xLarge}
      hSpacing={Spacing.xLarge}
    >
      <DefaultText>An error occurred while retrieving visit details.</DefaultText>
    </FlexBox>
  );

  const renderCTARow = () => (
    <MuiBox>
      <CallToActionRow
        isPatientToolbar
        justifyContent="flex-end"
        onCallPress={() => LinkingServices.callPhoneNumber(phoneNumber)}
        onDownloadPress={handleDownloadVisitSummary}
        onReplyPress={handleReplyToVisitSummary}
        onTransmitPress={handleShareVisitSummary}
        isDownloading={isDownloadingVisitSummaryCcd}
      />
    </MuiBox>
  );

  if (loadingAll) return <VisitSummaryLoading />;

  return (
    <Screen background aria-label="Visit Details">
      <DownloadErrorSnackbar hasError={downloadError} onRetry={handleDownloadVisitSummary} />
      <HealthRecordBanner
        title={visitTitle}
        patient={patient}
        date={visitSummaryDetails.date}
        dischargeDate={isLegacyOrInpatientVisit ? dischargeDate : null}
        dischargeDisposition={isLegacyOrInpatientVisit ? dischargeDisposition : null}
        location={locationName || 'Location not available'}
        providers={providerName}
        providerImageUrl={providerImageUrl}
        isSelectHealth={isSelectHealth}
        subtitle={description}
      />
      {renderCTARow()}
      <Divider />
      <MuiBox my={5}>
        <MuiContainer maxWidth="lg">
          <YouCameFor />
          <WeDiscussed visit={visitSummaryDetails} />
          <WhatHappened />
          <PatientInstructions visit={visitSummaryDetails} />
          <TestResults />
          <ResourcesListItem />
          {renderVitals()}
          {medicationsUpdates()}
          {Object.keys(visitSummaryDetails).length > 0 ? (
            <>
              <BillingItem />
              <ClaimItem />
            </>
          ) : null}
        </MuiContainer>
      </MuiBox>
      {detailsError && renderError()}
    </Screen>
  );
}

const mapStateToProps = (state: RootState) => ({
  profileConsumer: profileConsumerSelector(state),
  isImpersonating: isImpersonatingSelector(state),
  loadingAll: visitSummarySelectors.visitSummaryScreenLoadingSelector(state),
  vitals: vitalsSelectors.encounterVitalsSelector(state),
  visitSummaryDetails: visitSummarySelectors.visitSummaryDetailsDataSelector(state),
  detailsError: visitSummarySelectors.visitSummaryDetailsErrorSelector(state),
  visitSummaryDownloads: visitSummarySelectors.visitSummaryDownloadsSelector(state),
  vitalsDownloadsByEncounter: vitalsSelectors.vitalsDownloadsByEncounterSelector(state),
  currentUrl: currentLocationPathNameSelector(state),
  referringUrl: previousLocationPathNameSelector(state),
  medications: activeEncounterMedicationsDataSelector(state)
});

export default connect(mapStateToProps)(VisitSummaryLive);
