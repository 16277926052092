import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  DashboardBannerCard,
  DashboardCards
} from 'components/DashboardCarousel/DashboardBannerCard';

export interface Props {
  data: {
    onAnalyticsClick: () => void;
  };
  totalCards: number;
}

const DashboardCarouselGoals = (props: Props) => {
  const history = useHistory();
  const { onAnalyticsClick } = props.data;

  return (
    <DashboardBannerCard
      title="You have new or updated goals available"
      actionOne={{
        text: 'View details',
        onClick: () => {
          onAnalyticsClick();
          history.push('/u/health-record', { expand: 'goals' });
        }
      }}
      imageName={DashboardCards.goals.imageName}
      textColor={DashboardCards.goals.color}
      background={DashboardCards.goals.background}
      totalCards={props.totalCards}
    />
  );
};

export default DashboardCarouselGoals;
