import React from 'react';

export default {
  svg: (
    <g>
      <path d="M8.65645 18.77H15.3565L18.6865 15.44H5.32645L8.65645 18.77Z" fill="#3399CC" />
      <path d="M12.0065 22.12L15.3565 18.77H8.65646L12.0065 22.12Z" fill="#5555CC" />
      <path
        d="M2.89645 13.01L5.32645 15.44H18.6865L21.1165 13.01C21.3965 12.73 21.6465 12.43 21.8665 12.11H2.14645C2.36645 12.43 2.61645 12.73 2.89645 13.01Z"
        fill="#99CC66"
      />
      <path
        d="M1.01645 8.77002C1.07645 9.94002 1.45645 11.1 2.14645 12.1H21.8665C22.5565 11.1 22.9365 9.94002 22.9965 8.77002H1.01645Z"
        fill="#FFFF66"
      />
      <path
        d="M22.2665 5.44H1.74645C1.19645 6.48 0.956453 7.63 1.00645 8.77H22.9865C23.0465 7.63 22.7965 6.47 22.2465 5.44H22.2665Z"
        fill="#FF9933"
      />
      <path
        d="M21.1165 3.89C18.5965 1.37 14.5265 1.37 12.0065 3.89C9.48645 1.37 5.40645 1.37 2.89645 3.89C2.42645 4.36 2.04645 4.88 1.75645 5.44H22.2665C21.9765 4.88 21.5865 4.36 21.1265 3.89H21.1165Z"
        fill="#FF6633"
      />
      <path
        d="M21.1165 3.89C18.5965 1.37 14.5265 1.37 12.0065 3.89C9.48646 1.37 5.40646 1.37 2.89646 3.89C0.386456 6.41 0.376456 10.49 2.89646 13.01L12.0065 22.12L21.1165 13.01C23.6365 10.49 23.6365 6.41 21.1165 3.89Z"
        stroke="black"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </g>
  ),
  viewBox: '0 0 24 24'
};
