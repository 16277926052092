import React from 'react';
import { Color } from 'modules/styles/colors';
import { IconSize, Spacing, ZIndex } from 'modules/styles/variables';
import styled, { css } from 'styled-components';
import { MuiModal, MuiBackdrop, MuiBox } from 'theme/material-ui';
import Spinner from './Spinner';

interface Props {
  isLoading: boolean;
  position?: 'fixed' | 'absolute';
  children?: React.ReactElement;
  size?: number;
  color?: string;
  testID?: string;
  backgroundColor?: string;
  direction?: 'row' | 'column';
}

const Backdrop = styled(MuiBackdrop)<{
  backgroundColor?: string;
  color?: string;
  position?: string;
  direction?: string;
}>`
  flex-direction: ${props => props?.direction ?? 'row'};
  color: ${Color.primary};
  ${props =>
    props.color &&
    css`
      color: ${props.color};
    `}

  &.MuiBackdrop-root {
    z-index: ${ZIndex.spinner};
    background-color: ${Color.white};
    ${props =>
      props.backgroundColor &&
      css`
        background-color: ${props.backgroundColor};
      `}
    ${props =>
      props.position &&
      css`
        position: ${props.position};
      `}
  }
`;

export const SpinnerOverlay = (props: Props) => {
  return props.isLoading ? (
    <Backdrop
      open
      backgroundColor={props.backgroundColor}
      color={props.color}
      position={props.position}
      direction={props.direction}
    >
      <Spinner size={props.size} data-testid={props.testID} />
      {props.children ? (
        <MuiBox marginLeft={props.direction === 'column' ? Spacing.none : Spacing.small}>
          {props.children}
        </MuiBox>
      ) : null}
    </Backdrop>
  ) : null;
};

SpinnerOverlay.defaultProps = {
  position: 'absolute'
};

const InlineBox = styled(MuiBox)<{
  position?: string;
}>`
  width: 40px;
  ${({ position }) =>
    position &&
    position === 'absolute' &&
    css`
      position: ${position};
      height: 100%;
      right: 0;
    `}
  display: flex;
  justify-content: start;
  align-items: center;
`;

export const SpinnerInline = (props: Props) => {
  return props.isLoading ? (
    <InlineBox position={props.position}>
      <Spinner size={props.size || IconSize.base} data-testid={props.testID} />
    </InlineBox>
  ) : null;
};

SpinnerInline.defaultProps = {
  position: 'absolute'
};

const CenteredMuiModal = styled(MuiModal)`
  align-items: center;
  justify-content: center;
  display: flex;
`;

interface SpinnerModalProps {
  isLoading: boolean;
  children?: React.ReactNode;
  size?: number;
}
export const SpinnerModal = (props: SpinnerModalProps) => (
  <CenteredMuiModal open={props.isLoading}>
    <>
      <Spinner size={props.size} />
      {props.children}
    </>
  </CenteredMuiModal>
);

export default SpinnerModal;
