import styled from 'styled-components';
import { Color } from 'modules/styles/colors';
import Screen from 'components/UI/Layout/Screen';

export const stepActionsHeight = 82;

export const OrBox = styled.div`
  background-color: ${Color.white};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40px;
`;

export const FormScreen = styled(Screen)`
  height: 100%;
  padding-bottom: ${stepActionsHeight}px;
`;
