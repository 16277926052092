import React, { useRef } from 'react';
import { RouteComponentProps, Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useRoutes } from 'hooks/useRoutes';
import { Breadcrumbs } from 'components/Breadcrumbs';
import { useRoutesToCrumbs } from 'hooks/useRoutesToCrumbs';
import * as costSelectors from 'store/cost/selectors';
import RouteLeavingGuard from 'components/UI/Modals/RouteLeavingGuardModal';
import CostEstimator from './CostEstimator';
import CostSearch from './CostSearch';
import CostDetailsScreen from './CostDetailsScreen';
import CostProviderScreen from './Providers';
import CostEstimatorPMMC from './CostEstimatorPMMC';
import { COST_ESTIMATOR_PATHS, COST_ESTIMATOR_CONFIRM_DIALOG_TEXT } from './constants';

export const ROUTES = {
  ESTIMATE_COSTS: `${COST_ESTIMATOR_PATHS.ROOT}`,
  ESTIMATE_COSTS_SEARCH: `${COST_ESTIMATOR_PATHS.ROOT}/find-services`,
  ESTIMATE_COSTS_PROVIDERS: `${COST_ESTIMATOR_PATHS.ROOT}/providers`,
  ESTIMATE_COSTS_PROVIDERS_DETAIL: `${COST_ESTIMATOR_PATHS.ROOT}/providers/detail`
};

const costProviderRoutes = [
  {
    path: '/',
    exact: true,
    component: CostProviderScreen,
    title: 'Cost Provider'
  },
  {
    path: '/detail',
    exact: true,
    component: CostDetailsScreen,
    title: 'Cost Details'
  }
];

const CostProviderRouter = (props: RouteComponentProps & { costEstimatorRoutes: any }) => {
  const selectedResult = useSelector(costSelectors.selectedResultSelector);
  const routes = useRoutes(costProviderRoutes);
  const crumbs = useRoutesToCrumbs(routes.concat(props.costEstimatorRoutes));
  const firstMountRef = useRef(true);

  if (firstMountRef.current && !selectedResult) {
    firstMountRef.current = false;
    return <Redirect to={ROUTES.ESTIMATE_COSTS} />;
  }

  return (
    <>
      <Breadcrumbs crumbs={crumbs} />
      <Switch>
        {routes.map(({ component: Component, ...r }) => (
          <Route
            key={r.path}
            path={r.path}
            exact={r.exact || false}
            render={props => <Component {...r} {...props} />}
          />
        ))}
      </Switch>
    </>
  );
};

const costEstimatorRoutes = [
  {
    path: '/',
    exact: true,
    component: CostEstimator,
    title: 'Cost Estimator'
  },
  {
    path: '/cost-estimator-pmmc',
    exact: true,
    component: CostEstimatorPMMC,
    title: 'Cost Estimator PMMC',
    includeCrumbs: false
  },
  {
    path: '/find-services',
    exact: true,
    component: CostSearch,
    title: 'Find Services'
  },
  {
    path: '/providers',
    component: CostProviderRouter,
    includeCrumbs: false
  }
];

const mainCostEstimatorRoutes = [
  COST_ESTIMATOR_PATHS.ROOT,
  COST_ESTIMATOR_PATHS.PMMC,
  COST_ESTIMATOR_PATHS.FIND_SERVICES,
  COST_ESTIMATOR_PATHS.PROVIDERS,
  COST_ESTIMATOR_PATHS.PROVIDERS_DETAIL
];

export const CostEstimatorRouter = () => {
  const history = useHistory();
  const routes = useRoutes(costEstimatorRoutes);
  const crumbs = useRoutesToCrumbs(routes);

  return (
    <Switch>
      {routes.map(({ component: Component, ...r }) => (
        <Route
          key={r.path}
          path={r.path}
          exact={r.exact || false}
          render={props => (
            <>
              {r.includeCrumbs ? <Breadcrumbs crumbs={crumbs} /> : null}
              <Component costEstimatorRoutes={routes} {...r} {...props} />
              <RouteLeavingGuard
                shouldBlockNavigation={nextLocation =>
                  !mainCostEstimatorRoutes.includes(nextLocation.pathname)
                }
                exitTitle={COST_ESTIMATOR_CONFIRM_DIALOG_TEXT.title}
                exitSubtitle={COST_ESTIMATOR_CONFIRM_DIALOG_TEXT.subtitle}
                exitButtonText={COST_ESTIMATOR_CONFIRM_DIALOG_TEXT.primaryButton}
                keepGoingButtonText={COST_ESTIMATOR_CONFIRM_DIALOG_TEXT.secondaryButton}
                iconType="info"
                closeIcon
                navigate={(path: string, options: object) => history.push(path, options)}
              />
            </>
          )}
        />
      ))}
    </Switch>
  );
};
