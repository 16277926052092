import dayjs from 'dayjs';

export const getDuration = (start: string | Date, end: string | Date) => {
  const startDuration = dayjs(start);
  const endDuration = dayjs(end);
  const durationInDays = endDuration.diff(startDuration, 'day');

  if (durationInDays < 7) {
    if (durationInDays === 0) return 'N/A';
    if (durationInDays === 1) return `${durationInDays} day`;
    return `${durationInDays} days`;
  }

  if (durationInDays >= 7) {
    const durationInWeeks = endDuration.diff(startDuration, 'week');

    if (durationInWeeks === 1) return `${durationInWeeks} week`;
    return `${durationInWeeks} weeks`;
  }

  return 'N/A';
};
