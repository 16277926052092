import React from 'react';
import { connect } from 'react-redux';
import { partition } from 'modules/utils/ArrayUtils';
import {
  profileConsumerDisplayNameSelector,
  profileInsurancesSelector
} from 'store/profile/selectors';
import { Insurance } from 'store/profile/types';
import { tooltipDefinitionSelector } from 'store/tooltips/selectors';
import { RootState } from 'store/types';
import { MuiBox, MuiTypography } from 'theme/material-ui';
import { InfoTooltip } from 'components/InfoTooltip';
import MyInsuranceCard from './MyInsuranceCard';

interface MyInsuranceProps {
  insurances: Insurance[];
  insuranceTooltip: string;
}

export const MyInsurance = ({ insurances = [], insuranceTooltip }: MyInsuranceProps) => {
  const allowedInsurances = insurances.filter(insurance => insurance.insuranceName !== 'Self Pay');

  const [shInsurances, nonShInsurances] = partition(
    allowedInsurances,
    insurance => insurance.source === 'Facets'
  );

  const proxyInsurances = allowedInsurances.filter(insurance => insurance.isProxy === true);

  const hasInsurance = shInsurances.length || nonShInsurances.length || proxyInsurances.length;

  return (
    <MuiBox data-testid="billing-and-insurance">
      {insuranceTooltip ? (
        <MuiBox display="flex" justifyContent="flex-end">
          <InfoTooltip>{insuranceTooltip}</InfoTooltip>
        </MuiBox>
      ) : null}

      {!hasInsurance ? (
        <MuiBox p={2}>
          <MuiTypography align="center">No insurances found</MuiTypography>
        </MuiBox>
      ) : null}

      {shInsurances.length ? (
        <>
          <MuiTypography>SelectHealth</MuiTypography>
          <ul>
            {shInsurances.map(insurance => (
              <li key={insurance.planId}>
                <MyInsuranceCard insurance={insurance} />
              </li>
            ))}
          </ul>
        </>
      ) : null}

      {proxyInsurances.length ? (
        <>
          <MuiTypography>Insurances for people you have access to</MuiTypography>
          <ul>
            {proxyInsurances.map(insurance => (
              <li key={insurance.planId}>
                <MyInsuranceCard insurance={insurance} />
              </li>
            ))}
          </ul>
        </>
      ) : null}

      {nonShInsurances.length ? (
        <>
          <MuiTypography fontWeight={600}>Insurances on file with Intermountain</MuiTypography>
          <ul>
            {nonShInsurances.map(insurance => (
              <li key={insurance.planId}>
                <MyInsuranceCard insurance={insurance} />
              </li>
            ))}
          </ul>
        </>
      ) : null}
    </MuiBox>
  );
};

const mapStateToProps = (state: RootState) => ({
  insurances: profileInsurancesSelector(state),
  userName: profileConsumerDisplayNameSelector(state),
  insuranceTooltip: tooltipDefinitionSelector(state)('Health Insurance')
});

export default connect(mapStateToProps)(MyInsurance);
