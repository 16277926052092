import Hidden from '@material-ui/core/Hidden';
import { useProxySwitcherEffect } from 'hooks/useProxySwitcherEffect';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { profileConsumerSelector } from 'store/profile/selectors';
import { Consumer } from 'store/profile/types';
import Config from 'react-native-config';
import { getNavBarRoutes } from 'screens/navigation';
import analyticsService, { AmplitudeEventData, AnalyticsEvent } from 'services/AnalyticsService';
import {
  currentLocationPathNameSelector,
  previousLocationPathNameSelector
} from 'store/router/selectors';
import { getInboxMessages } from 'store/messaging/actions';
import { inboxFetchingSelector, inboxUnreadSelector } from 'store/messaging/selectors';
import { MessageSummary } from 'store/messaging/types';
import { RootDispatch, RootState } from 'store/types';
import CollapsedView from './CollapsedView';
import ExpandedView from './ExpandedView';
import { StyledSidebar } from './styled';

export interface SidebarProps {
  collapsed: boolean;
  currentUrl?: string | undefined;
  isAccountSwitcherModalOpen: boolean;
  isGuest: boolean;
  isLoading: boolean;
  loadMessages: () => void;
  logoutAction: () => void;
  name: string;
  onClose?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  open: boolean;
  referringUrl?: string | undefined;
  unread: MessageSummary[];
  userProfile: Consumer;
}

const Sidebar = (props: SidebarProps) => {
  const {
    currentUrl,
    collapsed,
    isLoading,
    loadMessages,
    onClose,
    open,
    referringUrl,
    unread,
    userProfile,
    isGuest
  } = props;

  const [unreadCount, setUnreadCount] = useState(unread.length);
  const navBarRoutes = getNavBarRoutes();
  const eventData: AmplitudeEventData = {
    currentUrl,
    referringUrl
  };

  useEffect(() => {
    if (unread && !isLoading) {
      setUnreadCount(unread.length);
    }
  }, [unread]);

  useProxySwitcherEffect(() => {
    if (!isGuest) loadMessages();
  }, []);

  const handleTabClick = (path: string) => {
    let enumName;
    switch (path) {
      case '/u/dashboard':
        enumName = 'DashboardTabClicked';
        break;
      case '/u/timeline':
        enumName = 'TimelineTabClicked';
        break;
      case '/u/health-record':
        enumName = 'HealthRecordTabClicked';
        break;
      case '/u/get-care-now':
        enumName = 'GetCareTabClicked';
        break;
      case '/u/billing':
        enumName = 'BillingTabClicked';
        break;
      case '/u/messages':
        enumName = 'MessagesTabClicked';
        break;
      case '/u/health-tools':
        enumName = 'HealthToolsClicked';
        eventData.age = userProfile?.age;
        eventData.gender = userProfile?.gender;
        eventData.appVersion = Config.APP_VERSION;
        break;
      default:
        enumName = 'UnknownTabClicked';
    }
    analyticsService.logEvent(AnalyticsEvent[enumName], eventData);
  };

  return (
    <>
      <Hidden mdUp>
        <StyledSidebar
          variant="temporary"
          anchor="left"
          collapsed={collapsed}
          open={open}
          onClose={onClose}
          ModalProps={{
            keepMounted: true
          }}
        >
          <ExpandedView
            handleTabClick={handleTabClick}
            navBarRoutes={navBarRoutes}
            unreadCount={unreadCount}
            {...props}
          />
        </StyledSidebar>
      </Hidden>
      <Hidden only={['xs', 'sm', 'lg', 'xl']}>
        <StyledSidebar variant="permanent" anchor="left" open collapsed={collapsed}>
          {collapsed ? (
            <CollapsedView
              handleTabClick={handleTabClick}
              navBarRoutes={navBarRoutes}
              unreadCount={unreadCount}
              {...props}
            />
          ) : (
            <ExpandedView
              handleTabClick={handleTabClick}
              navBarRoutes={navBarRoutes}
              unreadCount={unreadCount}
              {...props}
            />
          )}
        </StyledSidebar>
      </Hidden>
      <Hidden mdDown>
        <StyledSidebar variant="permanent" anchor="left" open collapsed={collapsed}>
          <ExpandedView
            handleTabClick={handleTabClick}
            navBarRoutes={navBarRoutes}
            unreadCount={unreadCount}
            {...props}
          />
        </StyledSidebar>
      </Hidden>
    </>
  );
};

const mapDispatch = (dispatch: RootDispatch) => ({
  loadMessages: () => dispatch(getInboxMessages())
});

const mapState = (state: RootState) => ({
  currentUrl: currentLocationPathNameSelector(state),
  isLoading: inboxFetchingSelector(state),
  referringUrl: previousLocationPathNameSelector(state),
  unread: inboxUnreadSelector(state),
  userProfile: profileConsumerSelector(state)
});

export default connect(mapState, mapDispatch)(Sidebar);
