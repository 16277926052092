import { Color } from 'modules/styles/colors';
import * as React from 'react';

export default (props: any) => {
  const { color = Color.secondary } = props;

  return {
    svg: (
      <g fill="none">
        <path
          d="M8.62006 17.27C8.62005 17.6555 8.7725 18.0253 9.04414 18.2989C9.31579 18.5724 9.68458 18.7274 10.0701 18.73H13.4601C13.8465 18.7274 14.2163 18.5727 14.4895 18.2995C14.7628 18.0262 14.9174 17.6564 14.9201 17.27V9C14.9201 8.9337 14.9464 8.87011 14.9933 8.82322C15.0402 8.77634 15.1038 8.75 15.1701 8.75H18.0601C18.302 8.77501 18.5455 8.72128 18.7545 8.59681C18.9635 8.47234 19.1267 8.28377 19.2199 8.05912C19.3132 7.83447 19.3314 7.58574 19.272 7.34988C19.2126 7.11403 19.0786 6.90365 18.8901 6.75L12.7901 0.7C12.5171 0.449831 12.1603 0.311054 11.7901 0.311054C11.4198 0.311054 11.063 0.449831 10.7901 0.7L4.64006 6.8C4.46184 6.95912 4.3366 7.16898 4.28118 7.40138C4.22576 7.63378 4.24282 7.87758 4.33006 8.1C4.42843 8.31648 4.58991 8.4982 4.79334 8.62132C4.99676 8.74445 5.23264 8.80324 5.47006 8.79H8.37006C8.42846 8.79446 8.48399 8.81712 8.52883 8.85479C8.57368 8.89246 8.60559 8.94325 8.62006 9V17.27Z"
          fill={color}
        />
        <path
          d="M0 19.68C0 20.7409 0.421427 21.7583 1.17157 22.5084C1.92172 23.2586 2.93913 23.68 4 23.68H20C21.0609 23.68 22.0783 23.2586 22.8284 22.5084C23.5786 21.7583 24 20.7409 24 19.68V17.8C23.9723 17.4942 23.8313 17.2098 23.6046 17.0027C23.378 16.7956 23.082 16.6808 22.775 16.6808C22.468 16.6808 22.172 16.7956 21.9454 17.0027C21.7187 17.2098 21.5777 17.4942 21.55 17.8V19.68C21.5475 20.0931 21.3844 20.4891 21.0951 20.784C20.8057 21.0789 20.413 21.2496 20 21.26H4C3.58003 21.26 3.17712 21.0939 2.87922 20.7978C2.58132 20.5018 2.41264 20.1 2.41 19.68V17.8C2.35316 17.5224 2.20219 17.2729 1.98263 17.0937C1.76307 16.9146 1.48838 16.8167 1.205 16.8167C0.921618 16.8167 0.646929 16.9146 0.427368 17.0937C0.207807 17.2729 0.0568442 17.5224 0 17.8L0 19.68Z"
          fill={color}
        />
      </g>
    ),
    viewBox: '0 0 24 24'
  };
};
