import React from 'react';
import { useField } from 'formik';

import FormLabel from '@material-ui/core/FormLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import { getRegisterBrand } from 'store/register';
import useStyles from '../useStyles';
import FieldError from './FieldError';
import { RequiredTextField } from '../styled';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

const CustomSelect = ({ label, options, required, ...props }) => {
  const redirectBrand = getRegisterBrand();
  const classes = useStyles({ redirectBrand });
  const [field, meta] = useField(props);
  const hasError = meta.touched && !!meta.error;

  return (
    <FormControl variant="outlined" error={hasError} className={classes.formControl}>
      <FormLabel className={classes.label}>
        {label} {required ? <RequiredTextField>*</RequiredTextField> : null}
      </FormLabel>
      <Select
        {...field}
        {...props}
        displayEmpty
        MenuProps={MenuProps}
        className={classes.customSelectField}
      >
        <MenuItem value="" disabled>
          Select One
        </MenuItem>
        {options.map(opt => (
          <MenuItem key={opt.value} value={opt.value}>
            {opt.label}
          </MenuItem>
        ))}
      </Select>
      <FieldError error={hasError && meta.error} />
    </FormControl>
  );
};

export default CustomSelect;
