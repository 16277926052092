import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Svg from 'components/UI/Svg/Svg';
import Button from 'components/UI/Button/Button';
import { Divider } from 'components/UI/Divider/styled';
import { dfdDefaultTheme, MuiBox, MuiDialog, MuiTypography } from 'theme/material-ui';

import { Color } from 'modules/styles/colors';
import { IconSize } from 'modules/styles/variables';
import { emergencySymptoms } from 'modules/constants/booking';

import { CloseButton, StyledUL, StyledLINormal } from '../../components/styled';

interface Props {
  fullScreen?: boolean;
  handleClose: Function;
  open: boolean;
  secondScreen?: boolean;
}

export function BookingMoreInfoDialog({ handleClose, open }: Props) {
  const fullScreen = useMediaQuery(dfdDefaultTheme.breakpoints.down('sm'));

  return (
    <MuiDialog fullScreen={fullScreen} maxWidth="md" open={open} onClose={handleClose}>
      <MuiBox p={3} py={2}>
        <CloseButton onClick={handleClose}>
          <Svg name="close" size={IconSize.large} set="material" />
        </CloseButton>
        <MuiBox display="flex" justifyContent="center" py={1}>
          <Svg set="assets" size={IconSize.xxLarge} color={Color.primary} name="InfoIcon" />
        </MuiBox>
        <MuiBox display="flex" justifyContent="center" py={2}>
          <MuiTypography align="center" fontSize={24}>
            Emergency Symptoms
          </MuiTypography>
        </MuiBox>
        <Divider spaced />
        <MuiBox display="flex" py={1}>
          <MuiTypography align="left" fontSize={16} fontWeight="bold">
            {emergencySymptoms.EMERGENCY_TITLE}
          </MuiTypography>
        </MuiBox>
        <StyledUL>
          {emergencySymptoms.SYMPTOMS.map((item: string) => (
            <MuiBox key={item} display="flex" py={1}>
              <StyledLINormal>{item}</StyledLINormal>
            </MuiBox>
          ))}
        </StyledUL>
        <Divider spaced />
        <MuiBox display="flex" py={1}>
          <Button fullWidth variant="web-primary" onPress={handleClose}>
            Close
          </Button>
        </MuiBox>
      </MuiBox>
    </MuiDialog>
  );
}

export default BookingMoreInfoDialog;
