/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { AnyAction } from 'redux';
import { FormikContextType } from 'formik';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import { useSnack } from 'components/Snack';
import { MuiBox, MuiContainer } from 'theme/material-ui';
import {
  ConnectCareForm,
  ConnectCareStepActions,
  ConnectCareStepAction,
  useConnectCareFormEffect
} from 'components/ConnectCare';

import vitalsConstants from 'modules/constants/amwell/vitals';

import analyticsService, { AnalyticsEvent } from 'services/AnalyticsService';

import { RootState } from 'store/types';
import { Vitals } from 'store/amwell/types';
import * as actions from 'store/amwell/actions';
import * as selectors from 'store/amwell/selectors';
import {
  currentLocationPathNameSelector,
  previousLocationPathNameSelector
} from 'store/router/selectors';

import { RouteData } from '../types';
import { FormScreen } from '../styled';
import { vitalsError } from './constants';
import ConnectCareVitalsForm from './ConnectCareVitalsForm';

export interface Props extends RouteComponentProps {
  error: Error | null;
  getVitals: typeof actions.getVitals;
  loading: boolean;
  updateVitals: typeof actions.updateVitals;
  vitals: Vitals | null;
  currentUrl?: string;
  referringUrl?: string;
}

const initialValues: Vitals = {
  weightMajor: '',
  weightMinor: 0,
  heightMajor: '',
  heightMinor: '',
  temperature: '',
  systolic: '',
  diastolic: ''
};

const vitalsValidationSchema = Yup.object().shape({
  weightMajor: Yup.number()
    .min(0, vitalsConstants.VITALS_ERROR_MESSAGE.WEIGHT.MAJOR)
    .max(1500, vitalsConstants.VITALS_ERROR_MESSAGE.WEIGHT.MAJOR)
    .integer(vitalsConstants.VITALS_ERROR_MESSAGE.NO_DECIMALS_OR_SPECIAL_CHARS)
    .required(vitalsConstants.VITALS_ERROR_MESSAGE.WEIGHT.MAJOR),
  weightMinor: Yup.number()
    .min(0, vitalsConstants.VITALS_ERROR_MESSAGE.WEIGHT.MINOR)
    .max(15.9, vitalsConstants.VITALS_ERROR_MESSAGE.WEIGHT.MINOR)
    .integer(vitalsConstants.VITALS_ERROR_MESSAGE.NO_DECIMALS_OR_SPECIAL_CHARS)
    .required(vitalsConstants.VITALS_ERROR_MESSAGE.WEIGHT.MINOR),
  heightMajor: Yup.number()
    .min(0, vitalsConstants.VITALS_ERROR_MESSAGE.HEIGHT.MAJOR)
    .max(10, vitalsConstants.VITALS_ERROR_MESSAGE.HEIGHT.MAJOR)
    .integer(vitalsConstants.VITALS_ERROR_MESSAGE.NO_DECIMALS_OR_SPECIAL_CHARS)
    .required(vitalsConstants.VITALS_ERROR_MESSAGE.HEIGHT.MAJOR),
  heightMinor: Yup.number()
    .min(0, vitalsConstants.VITALS_ERROR_MESSAGE.HEIGHT.MINOR)
    .max(11.9, vitalsConstants.VITALS_ERROR_MESSAGE.HEIGHT.MINOR)
    .integer(vitalsConstants.VITALS_ERROR_MESSAGE.NO_DECIMALS_OR_SPECIAL_CHARS)
    .required(vitalsConstants.VITALS_ERROR_MESSAGE.HEIGHT.MINOR),
  temperature: Yup.number()
    .min(0, vitalsConstants.VITALS_ERROR_MESSAGE.TEMPERATURE)
    .max(120, vitalsConstants.VITALS_ERROR_MESSAGE.TEMPERATURE),
  systolic: Yup.number()
    .min(0, vitalsConstants.VITALS_ERROR_MESSAGE.SYSTOLIC)
    .max(250, vitalsConstants.VITALS_ERROR_MESSAGE.SYSTOLIC)
    .integer(vitalsConstants.VITALS_ERROR_MESSAGE.NO_DECIMALS_OR_SPECIAL_CHARS),
  diastolic: Yup.number()
    .min(0, vitalsConstants.VITALS_ERROR_MESSAGE.DIASTOLIC)
    .max(250, vitalsConstants.VITALS_ERROR_MESSAGE.DIASTOLIC)
    .integer(vitalsConstants.VITALS_ERROR_MESSAGE.NO_DECIMALS_OR_SPECIAL_CHARS)
});

const isFormValid = async (formikProps: FormikContextType<typeof initialValues>) => {
  const errors = await formikProps.validateForm(formikProps.values);
  const hasErrors = !!Object.keys(errors).length;
  return hasErrors;
};

export function ConnectCareVitals({
  error,
  getVitals,
  loading,
  updateVitals,
  vitals,
  history,
  currentUrl,
  referringUrl
}: Props) {
  const { create } = useSnack();
  const [nextDisabled, setNextDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(loading);
  const [formProps, setFormProps] = useState<FormikContextType<typeof initialValues>>();

  const fetchVitals = async () => {
    await getVitals();
  };

  useConnectCareFormEffect(async formProps => {
    const disabled = await isFormValid(formProps);
    setNextDisabled(disabled);
    setFormProps(formProps);
  });

  useEffect(() => {
    fetchVitals();
  }, []);

  const errorComponentProps = {
    message: vitalsError.GET.title,
    action: {
      label: 'Try Again?',
      onClick: getVitals
    }
  };

  function updateVitalValues(values: typeof initialValues): AnyAction {
    try {
      const vitalsToUpdate = vitals;
      const nonBlankFields = ['heightMinor', 'weightMinor'];
      Object.keys(values).forEach(key => {
        vitalsToUpdate[key] = values[key] ? +values[key] : nonBlankFields.includes(key) ? 0 : '';
      });

      return updateVitals({ vitals: vitalsToUpdate });
    } catch (e) {
      return {
        type: actions.UPDATE_VITALS_FAIL,
        error: e.message
      };
    }
  }

  const handleSubmit = async (values: typeof initialValues) => {
    const res = await updateVitalValues(values);
    if (res.error) throw res.error;
  };

  const onNextClick = async (nextStep?: RouteData) => {
    if (!nextStep || !formProps) return;

    setIsLoading(true);

    await formProps
      .submitForm()
      .then(() => {
        setIsLoading(false);
        history.push(nextStep.path);
        analyticsService.logEvent(AnalyticsEvent.VitalsEntered, {
          currentUrl,
          referringUrl
        });
      })
      .catch(() => {
        setIsLoading(false);
        create('There was an issue updating your vitals', 'error');
      });
  };

  const onPrevClick = (prevStep?: RouteData) => {
    if (prevStep) {
      history.push(prevStep.path);
    }
  };

  return (
    <>
      <FormScreen>
        <MuiContainer maxWidth="lg">
          <MuiBox p={4} display="flex" justifyContent="center">
            <ConnectCareForm
              onSubmit={handleSubmit}
              isInitialValid={false}
              initialValues={initialValues}
              validationSchema={vitalsValidationSchema}
            >
              <ConnectCareVitalsForm
                vitals={vitals}
                loading={loading}
                error={error}
                errorComponentProps={errorComponentProps}
              />
            </ConnectCareForm>
          </MuiBox>
        </MuiContainer>
      </FormScreen>
      <ConnectCareStepActions>
        <ConnectCareStepAction onClick={onPrevClick} />
        <ConnectCareStepAction disabled={nextDisabled} loading={isLoading} onClick={onNextClick} />
      </ConnectCareStepActions>
    </>
  );
}

const mapStateToProps = (state: RootState) => ({
  vitals: selectors.vitalsDataSelector(state),
  loading: selectors.vitalsDataLoadingSelector(state),
  error: selectors.vitalsDataErrorSelector(state),
  currentUrl: currentLocationPathNameSelector(state),
  referringUrl: previousLocationPathNameSelector(state)
});

const mapDispatchToProps = {
  getVitals: actions.getVitals,
  updateVitals: actions.updateVitals
};

export default connect(mapStateToProps, mapDispatchToProps)(ConnectCareVitals);
