import Banner from 'components/UI/Banner/Banner';
import DataLoader from 'components/UI/DataLoader/DataLoader';
import { technicalHelpNumber } from 'modules/constants/phoneNumbers';
import { Color } from 'modules/styles/colors';
import React, { useState, useMemo, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { getAuditsByPage, downloadAuditLogFilters, getAudits } from 'store/audit/actions';
import {
  auditByPageSelector,
  auditErrorSelector,
  auditFetchingSelector,
  auditPageNumberSelector,
  auditRowCountSelector,
  auditLogFiltersDownloadStatus
} from 'store/audit/selectors';
import { Audit, AuditLogOptions } from 'store/audit/types';
import { RootState } from 'store/types';
import {
  MuiBox,
  MuiIcon,
  MuiContainer,
  MuiTable,
  MuiTableBody,
  MuiTableCell,
  MuiTableContainer,
  MuiTableHead,
  MuiTablePagination,
  MuiTableRow,
  MuiTypography,
  MuiPaper,
  useTheme
} from 'theme/material-ui';
import { useAuditLogFilter } from 'lib/hooks/AuditLogFilters/useAuditLogFilter';
import { AuditLogError, AuditLogLoading, AuditLogNoData } from './shared';
import { FiltersButton, ShowMoreButton } from './styled';

import AuditLogFilters from './AuditLogFilters';
import { Svg } from 'components/UI/Svg';
import AuditLogListFiltersGroup from './AuditLogListFiltersGroups';
import { useMediaQuery, ButtonGroup } from '@material-ui/core';
import AuditLogDownloadDailog from './AuditLogDownloadDailog';
import { IconSize } from 'modules/styles/variables';
import { formatDateWithTime } from 'modules/utils/DateUtils';

interface AuditLogProps {
  getAuditPage: (page: number) => void;
  audits: Audit[];
  isFetching: boolean;
  error: boolean;
  count: number;
  currentPage: number;
  downloadAuditLogFilter: () => void;
  downloadStatus: string;
}

function countFilters(options: AuditLogOptions) {
  let count = 0;

  if (options && options.filters) {
    options.filters.forEach(({ value }) => {
      if (value) {
        count += 1;
      }
    });
  }
  if (options && options.facets) {
    options.facets.forEach(() => {
      count += 1;
    });
  }
  return count;
}
const AuditLogDisclaimer = ({ visibleRecordsCount, totalCount }) => {
  return (
    <MuiBox display="flex" justifyContent="center">
      <MuiBox mx={1}>
        <MuiTypography>
          Showing {visibleRecordsCount} of {totalCount} records. Please contact Intermountain for
          historical logs exceeding 1 year.
        </MuiTypography>
      </MuiBox>
      <MuiTypography component="a" href={`tel:${technicalHelpNumber}`}>
        1-{technicalHelpNumber}
      </MuiTypography>
    </MuiBox>
  );
};

const AuditContentContainer = ({ children }: { children: React.ReactNode }) => (
  <MuiBox py={3}>
    <MuiContainer maxWidth="lg">{children}</MuiContainer>
  </MuiBox>
);

export const AuditLog = (props: AuditLogProps) => {
  const {
    audits = [],
    getAuditPage,
    currentPage,
    isFetching,
    count,
    downloadAuditLogFilter,
    downloadStatus,
    error
  } = props;
  const [showFilters, setShowFilters] = useState(false);
  const [defaultVisibleRecords, setDefaultVisibleRecords] = useState(25);
  const [showShowMoreBtn, setShowShowMoreBtn] = useState(count > 25);
  const [isDownloadDialogOpen, setIsDownloadDialogOpen] = useState(false);

  const theme = useTheme();
  const dispatch = useDispatch();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { filter, options } = useAuditLogFilter('', {});
  const isDownloading = downloadStatus === 'pending';
  const filtersCount = useMemo(() => {
    return countFilters(options);
  }, [options]);

  useEffect(() => {
    setShowShowMoreBtn(count > 25);
    setDefaultVisibleRecords(25);
  }, [count]);

  useEffect(() => {
    dispatch(getAudits({ pageNumber: 1, totalRowCount: 50, options }));
  }, []);

  // Material-UI table pagination is 0-indexed whereas our api is 1-indexed
  const goToPage = (page: number) => {
    getAuditPage(page + 1);
  };
  const handleFileDownload = () => {
    downloadAuditLogFilter();
    setIsDownloadDialogOpen(false);
  };

  const handleShowMoreRecords = () => {
    setDefaultVisibleRecords(defaultVisibleRecords + 75);
    setShowShowMoreBtn(false);
  };
  const slicedAudits = showShowMoreBtn ? audits.slice(0, defaultVisibleRecords) : audits;
  return (
    <>
      <Banner message="Audit Logs" />

      <MuiBox component="main">
        <AuditLogFilters
          open={showFilters}
          onClose={() => setShowFilters(false)}
          defaultExpandedFilters={[]}
          filter={filter}
          defaultOptions={options}
        />
        <MuiBox bgcolor={Color.grayLight}>
          <AuditContentContainer>
            <MuiBox paddingRight={isSmallScreen ? 0 : 2} display="flex" justifyContent="flex-end">
              <ButtonGroup>
                <FiltersButton
                  onClick={() => setIsDownloadDialogOpen(true)}
                  variant="contained"
                  aria-label="open drawer"
                  data-testid="download-button"
                  disabled={audits.length === 0}
                >
                  {isDownloading ? (
                    <Svg
                      set="downloading"
                      name="cloud-download-outlined"
                      color={Color.black}
                      size={IconSize.base}
                    />
                  ) : (
                    <MuiIcon data-testid="tune-icon">download</MuiIcon>
                  )}
                  <MuiTypography>Download</MuiTypography>
                </FiltersButton>

                {!showFilters ? (
                  <FiltersButton
                    variant="contained"
                    onClick={() => setShowFilters(true)}
                    active={filtersCount}
                    aria-label="open drawer"
                    data-testid="filters-button"
                  >
                    <MuiTypography>Filters</MuiTypography>
                    <Svg set="material" name="keyboard-arrow-down" size={24} />
                  </FiltersButton>
                ) : null}
              </ButtonGroup>
            </MuiBox>
          </AuditContentContainer>
        </MuiBox>
        <DataLoader
          loading={isFetching}
          data={audits}
          error={error}
          renderLoading={() => <AuditLogLoading />}
          renderError={() => <AuditLogError />}
          renderNoData={() => <AuditLogNoData />}
          renderData={() => (
            <>
              <AuditContentContainer>
                <MuiBox>
                  <AuditLogListFiltersGroup options={options} filter={filter} />
                </MuiBox>
                <MuiBox marginRight={showFilters ? 22.5 : 0} marginTop={1}>
                  <MuiTableContainer component={MuiPaper}>
                    <MuiTable aria-label="Audit Log">
                      <MuiTableHead>
                        <MuiTableRow>
                          <MuiTableCell style={{ minWidth: 85 }}>Performed on</MuiTableCell>
                          <MuiTableCell style={{ minWidth: 50 }}>Data Category</MuiTableCell>
                          <MuiTableCell style={{ minWidth: 60, maxWidth: 80 }}>
                            Description
                          </MuiTableCell>
                          <MuiTableCell style={{ minWidth: 60 }}>Date & Time</MuiTableCell>
                          <MuiTableCell style={{ minWidth: 60 }}>Action</MuiTableCell>
                          <MuiTableCell style={{ minWidth: 60 }}>Performed by</MuiTableCell>
                          <MuiTableCell style={{ minWidth: 150 }}>Transmitted to</MuiTableCell>
                        </MuiTableRow>
                      </MuiTableHead>

                      <MuiTableBody>
                        {slicedAudits.map(audit => {
                          return (
                            <MuiTableRow key={audit.activityLogId}>
                              <MuiTableCell>{audit.actionPerformedOnName}</MuiTableCell>
                              <MuiTableCell>{audit.categoryLabel}</MuiTableCell>
                              <MuiTableCell style={{ minWidth: 60, maxWidth: 80 }}>
                                {audit.description}
                              </MuiTableCell>
                              <MuiTableCell style={{ minWidth: 60, maxWidth: 80 }}>
                                {formatDateWithTime(audit.auditDate)}
                              </MuiTableCell>
                              <MuiTableCell>{audit.actionName}</MuiTableCell>
                              <MuiTableCell>{audit.actionPerformedBy}</MuiTableCell>
                              <MuiTableCell>{audit.transmittedTo || '...'}</MuiTableCell>
                            </MuiTableRow>
                          );
                        })}
                      </MuiTableBody>
                    </MuiTable>
                  </MuiTableContainer>
                  {showShowMoreBtn ? (
                    <>
                      <MuiBox mt={3} mx="auto" width={130} mb={0}>
                        <ShowMoreButton
                          variant="outlined"
                          onClick={() => handleShowMoreRecords()}
                          active={filtersCount}
                          aria-label="open drawer"
                          data-testid="filters-button"
                        >
                          <MuiTypography color={Color.primary}>Show more</MuiTypography>
                          <Svg
                            set="material"
                            name="keyboard-arrow-down"
                            size={24}
                            color={Color.primary}
                          />
                        </ShowMoreButton>
                      </MuiBox>
                    </>
                  ) : (
                    <MuiTablePagination
                      component="div"
                      rowsPerPage={100}
                      rowsPerPageOptions={[]}
                      count={count}
                      page={currentPage - 1}
                      onChangePage={(e, page) => goToPage(page)}
                    />
                  )}
                </MuiBox>
              </AuditContentContainer>
              <MuiBox p={2} pt={0}>
                <AuditLogDisclaimer visibleRecordsCount={slicedAudits.length} totalCount={count} />
              </MuiBox>
            </>
          )}
        />
      </MuiBox>
      <AuditLogDownloadDailog
        open={isDownloadDialogOpen}
        downloadFileInfo={[
          {
            name: 'File Type',
            description: 'CSV'
          }
        ]}
        handleClose={() => setIsDownloadDialogOpen(false)}
        handleDownload={() => handleFileDownload()}
      />
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  audits: auditByPageSelector(state),
  isFetching: auditFetchingSelector(state),
  error: auditErrorSelector(state),
  count: auditRowCountSelector(state),
  currentPage: auditPageNumberSelector(state),
  downloadStatus: auditLogFiltersDownloadStatus(state)
});

const mapDispatchToProps = {
  getAuditPage: getAuditsByPage,
  downloadAuditLogFilter: downloadAuditLogFilters
};

export default connect(mapStateToProps, mapDispatchToProps)(AuditLog);
