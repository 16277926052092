import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { ListItem } from 'components/UI/ListItem/ListItem';
import { getHipaaDocuments, resetHipaaDocuments } from 'store/hipaaDocuments/actions';
import {
  hipaaDocumentsDataSelector,
  hipaaDocumentsFetchingSelector,
  hipaaDocumentsErrorSelector
} from 'store/hipaaDocuments/selectors';
import { HipaaDocumentsItem } from 'store/hipaaDocuments/types';
import { RootState } from 'store/types';
import {
  currentLocationPathNameSelector,
  previousLocationPathNameSelector
} from 'store/router/selectors';
import { Nullable } from 'modules/types/common';
import analyticsService, { AmplitudeEventData, AnalyticsEvent } from 'services/AnalyticsService';
import { MuiBox, MuiTypography, MuiPaper, MuiList } from 'theme/material-ui';
import { FlexBoxRow } from 'components/UI/Layout/FlexBox';
import Spinner from 'components/UI/Spinner/Spinner';
import { formatDisplayName } from 'screens/Notifications/helpers';
import DataLoader from 'components/UI/DataLoader/DataLoader';
import { convertToLowerKabobCase } from 'modules/utils/StringUtils';

export interface Props {
  getDocuments: () => {};
  documents: Nullable<HipaaDocumentsItem[]>;
  isFetching: boolean;
  error: Error;
  currentUrl: string;
  referringUrl: string;
}

export function HipaaDocuments({
  getDocuments,
  documents,
  isFetching,
  error,
  currentUrl,
  referringUrl
}: Props) {
  const history = useHistory();

  const handleHipaaDocumentSelect = (item: HipaaDocumentsItem) => {
    const eventData: AmplitudeEventData = {
      currentUrl,
      referringUrl
    };

    // Hipaa Documents Item Clicked
    analyticsService.logEvent(AnalyticsEvent.HIPAADocuments, eventData);

    history.push(`/u/manage-account/hipaa-documents/${item.patientId}`);
  };

  useEffect(() => {
    getDocuments();
  }, []);

  return (
    <MuiBox width="100%">
      <MuiPaper elevation={2}>
        <MuiList>
          <DataLoader
            loading={isFetching}
            data={documents}
            error={error}
            renderLoading={() => (
              <MuiBox p={5} display="flex" alignItems="center" justifyContent="center">
                <Spinner />
              </MuiBox>
            )}
            renderError={() => (
              <FlexBoxRow
                alignItems="center"
                justifyContent="center"
                data-testid="error-loading-hipaa-documents"
              >
                <MuiTypography>There was an issue loading HIPAA documents.</MuiTypography>
              </FlexBoxRow>
            )}
            renderNoData={() => (
              <FlexBoxRow
                alignItems="center"
                justifyContent="center"
                data-testid="no-hipaa-documents"
              >
                <MuiTypography>No HIPAA documents on file.</MuiTypography>
              </FlexBoxRow>
            )}
            renderData={data => (
              <>
                {data.map(item => (
                  <ListItem
                    key={item.patientId}
                    icon="ChevronRight"
                    title={formatDisplayName(item.displayName)}
                    body={item.displayName}
                    onPress={() => handleHipaaDocumentSelect(item)}
                    data-testid={convertToLowerKabobCase(formatDisplayName(item.displayName))}
                  />
                ))}
              </>
            )}
          />
        </MuiList>
      </MuiPaper>
    </MuiBox>
  );
}

const mapStateToProps = (state: RootState) => ({
  documents: hipaaDocumentsDataSelector(state),
  isFetching: hipaaDocumentsFetchingSelector(state),
  error: hipaaDocumentsErrorSelector(state),
  currentUrl: currentLocationPathNameSelector(state),
  referringUrl: previousLocationPathNameSelector(state)
});

const mapDispatchToProps = {
  getDocuments: getHipaaDocuments
};

export default connect(mapStateToProps, mapDispatchToProps)(HipaaDocuments);
