import styled from 'styled-components';
import { MuiTextField } from 'theme/material-ui';
import WebMuiModal from 'components/UI/Modals/WebMuiModal';
import { Color } from 'modules/styles/colors';

export const StyledTextField = styled(MuiTextField)`
  background-color: ${Color.foreColor};
`;

export const StyledWebMuiModal = styled(WebMuiModal)`
  background-color: rgba(0, 0, 0, 0.1);
  .MuiBox-root {
    padding: 0;
  }
`;
