import React from 'react';
import { FiltersStructure } from 'lib/hooks/AuditLogFilters/types/filter';
import { FilterDrawer } from 'components/FilterDrawer';
import { FormCheckboxList } from '../shared';
import { KeyboardDatePicker } from '@material-ui/pickers';
import Box from 'components/UI/Layout/Box';
import FlexBox from 'components/UI/Layout/FlexBox';
import { Spacing } from 'modules/styles/variables';
import { useAuditLogFilters } from 'lib/hooks/AuditLogFilters/useAuditLogFilters';

export type DateAndTimeFilterProps = FiltersStructure['dateAndTimeOfEvent'] & {
  defaultExpanded: boolean;
};

function DateAndTimeFilter({ title, values, defaultExpanded }: DateAndTimeFilterProps) {
  const filterContext = useAuditLogFilters();
  const checkBoxValue = filterContext?.values?.facets?.dateAndTimeOfEvents?.[0]?.value;
  const isCustomDateRange = checkBoxValue === 'custom';
  const [showCustomDateRange, setShowCustomDateRange] = React.useState(isCustomDateRange);
  const [startDate, setStartDate] = React.useState(
    isCustomDateRange ? filterContext?.values?.startsAt : new Date()
  );
  const [endDate, setEndDate] = React.useState(
    isCustomDateRange ? filterContext?.values?.endsAt : new Date()
  );
  const { setFieldValue } = useAuditLogFilters();

  const handleStartDateChange = (date: Date) => {
    setStartDate(date);
    setFieldValue('startsAt', date);
  };

  const handleEndDateChange = (date: Date) => {
    setEndDate(date);
    setFieldValue('endsAt', date);
  };

  const showCustomDates = (item: T, checked: boolean) => {
    if (item.value === 'custom') {
      if (checked) {
        setFieldValue('startsAt', startDate);
        setFieldValue('endsAt', endDate);
        setShowCustomDateRange(true);
      } else {
        setFieldValue('startsAt', null);
        setFieldValue('endsAt', null);
        setShowCustomDateRange(false);
      }
    } else {
      const today = new Date();
      const last30Days = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() - parseInt(item.value, 0)
      );
      if (checked) {
        setFieldValue('startsAt', last30Days);
        setFieldValue('endsAt', today);
      } else {
        setFieldValue('startsAt', null);
        setFieldValue('endsAt', null);
      }
    }
  };
  const pastDate = new Date();
  const pastYear = pastDate.getFullYear() - 1;
  pastDate.setFullYear(pastYear);
  return (
    <FilterDrawer.Panel
      title={title}
      defaultExpanded={defaultExpanded}
      filtersCount={filterContext?.values?.facets?.dateAndTimeOfEvents?.length}
    >
      <FormCheckboxList onChange={showCustomDates} list={Object.values(values)} />

      {showCustomDateRange ? (
        <FlexBox flexDirection="row" data-testid="custom-date-range">
          <Box hSpacingRight={Spacing.small}>
            <KeyboardDatePicker
              inputVariant="outlined"
              format="MM/DD/YYYY"
              placeholder="MM/DD/YYYY"
              KeyboardButtonProps={{
                'aria-label': 'change date'
              }}
              label="From"
              value={startDate}
              onChange={handleStartDateChange}
              minDate={pastDate}
            />
          </Box>
          <Box>
            <KeyboardDatePicker
              inputVariant="outlined"
              format="MM/DD/YYYY"
              placeholder="MM/DD/YYYY"
              KeyboardButtonProps={{
                'aria-label': 'change date'
              }}
              label="To"
              value={endDate}
              minDate={startDate}
              onChange={handleEndDateChange}
            />
          </Box>
        </FlexBox>
      ) : null}
    </FilterDrawer.Panel>
  );
}

export default DateAndTimeFilter;
