import React, { useState } from 'react';

import { BookingDrawer } from 'components/booking/BookingDrawer';
import {
  MuiBox,
  MuiGrid,
  MuiFormControl,
  MuiFormControlLabel,
  MuiRadioGroup,
  MuiTypography
} from 'theme/material-ui';

import { OptionType } from 'store/findProvider/types';

import { StyledMuiRadio } from 'screens/Booking/components/styled';

export interface Props {
  open: boolean;
  sortOptions: OptionType[];
  search: () => void;
  onClose: () => void;
  defaultSortOption: OptionType;
  setSort: (value: OptionType) => { type: string; payload: OptionType };
}

export function SpecialtyProviderSortOptions({
  open,
  sortOptions,
  search,
  onClose,
  defaultSortOption,
  setSort
}: Props) {
  const [selection, setSelection] = useState(defaultSortOption.value || '');

  const sortOptionLabel = (option: OptionType) => {
    return <MuiTypography>{option.label}</MuiTypography>;
  };

  const handleSortBySelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelection((event.target as HTMLInputElement).value);
  };

  const getSelectedSortOption = (option: OptionType) => {
    if (!option) {
      return false;
    }

    return option.value === selection;
  };

  const handleApplySortPress = () => {
    const options = sortOptions.find(option => option.value === selection);
    if (options) {
      setSort(options);
      search();
      onClose();
    }
  };

  const handleClose = () => () => {
    onClose();
  };

  return (
    <BookingDrawer
      open={open}
      headerText="Sort By..."
      buttonOneText="Apply sort"
      isFiltersDrawer={false}
      onClose={handleClose()}
      onApply={handleApplySortPress}
    >
      <MuiFormControl component="fieldset">
        <MuiRadioGroup
          value={defaultSortOption || selection}
          onChange={handleSortBySelect}
          data-testid="booking-specialty-provider-sort-options"
        >
          {sortOptions.map((item, index) => (
            <MuiGrid container key={item.value}>
              <MuiBox pl={2} pt={2} alignItems="center">
                <MuiFormControlLabel
                  value={item.value}
                  key={item.value}
                  control={<StyledMuiRadio size="medium" checked={getSelectedSortOption(item)} />}
                  label={sortOptionLabel(item)}
                  data-testid={`booking-specialty-provider-sort-options-${index}`}
                />
              </MuiBox>
            </MuiGrid>
          ))}
        </MuiRadioGroup>
      </MuiFormControl>
    </BookingDrawer>
  );
}

export default SpecialtyProviderSortOptions;
