/* eslint no-underscore-dangle: 0 */
import dayjs from 'dayjs';
import { JsonObj } from '@mhp/general-interface';
import GradientView from 'components/UI/Banner/GradientView';
import Divider from 'components/UI/Divider';
import FlexBox from 'components/UI/Layout/FlexBox';
import Screen from 'components/UI/Layout/Screen';
import Typography from 'components/UI/Typography';
import { Color } from 'modules/styles/colors';
import { FontSize, Spacing, FontWeight } from 'modules/styles/variables';
import React, { useEffect, useState, useRef } from 'react';
import { Dispatch } from 'redux';
import { View, Platform } from 'react-native';
import { Icon } from 'components/Icon';
import styled from 'styled-components';
import { FormGroup } from 'components/UI/Layout/Form';
import Spacer from 'components/UI/Layout/Spacer';
import TextInput from 'components/UI/Input/TextInput';
import RadioButton from 'components/UI/Radio/RadioButton';
import { SubmitButtonBox } from 'screens/Billing/FinancialAssistance/FAApplication/styled';
import StyledButton from 'components/UI/Button/StyledButton';
import { Formik } from 'formik';
import { oc } from 'ts-optchain';
import formatMoney from 'services/formatMoney';
import * as Yup from 'yup';
import { paymentInfo, applyPayment, resetPaymentInfo } from 'store/billing/Payment/actions';
import { connect } from 'react-redux';
import { formatDate } from 'modules/utils/DateUtils';
import BottomModal from 'components/UI/Modals/BottomModal';
import {
  ApplyPaymentFetchingSelector,
  PaymentInfoFetchingSelector,
  ApplyPaymentDataSelector,
  PaymentInfoDataSelector,
  ApplyPaymentErrorSelector
} from 'store/billing/Payment/selectors';
import SpinnerModal from 'components/UI/Spinner/SpinnerModal';
import BottomConfirmationModal from 'components/UI/Modals/BottomConfirmationModal';
import { PaymentForm, PaymentPlanForm, EncounterForm } from 'store/billing/Payment/types';
import analyticsService, { AnalyticsEvent } from 'services/AnalyticsService';
import {
  currentLocationPathNameSelector,
  previousLocationPathNameSelector
} from 'store/router/selectors';
import { RootState } from 'store/types';
import InputPicker from 'components/UI/Picker/InputPicker';
import { LOCATION_STATES } from 'modules/constants/LocationStates';
import MaskedTextInput from 'components/UI/Input/MaskedTextInput';
import DefaultLabel from 'components/UI/Labels/DefaultLabel';
import { NavigationScreenProps } from 'screens/navigation';

interface PaymentScreenProps extends NavigationScreenProps {
  dispatch: Dispatch;
  isFetching: boolean;
  isFetchingPaymentInfo: boolean;
  data: null | any;
  receiptData: null | any;
  paymentInfo: () => Promise<void>;
  resetPaymentInfo: () => void;
  applyPayment: (form: PaymentForm) => Promise<any>;
  error: string;
  currentUrl?: string;
  referringUrl?: string;
}

const CloseButton = styled.div`
  position: absolute;
  right: ${Spacing.xLarge}px;
  top: ${Spacing.large}px;
`;

const StyledFormGroup = styled(FormGroup)`
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  border-bottom-width: 0;
  padding: 0;
`;

const toNumber = (v: string | number) => {
  const n = parseFloat(v);

  return Number.isNaN(n) ? 0 : n;
};

const add = (a: number, b: number) => a + b;

function getTotalPaymentAmount<T extends { paymentAmount: number }>(values: T[]) {
  return values.map(i => toNumber(i.paymentAmount)).reduce(add, 0);
}

function calcTotalPayment(values: PaymentForm): number | undefined {
  if (values.paymentOption === '1') {
    const totalEncounters = getTotalPaymentAmount(values.encounters);
    const totalPaymentPlans = getTotalPaymentAmount(values.paymentPlans);

    return totalEncounters + totalPaymentPlans;
  }

  return values.paymentAmount;
}

const PaymentScreen = (props: PaymentScreenProps) => {
  const { currentUrl, referringUrl } = props;
  useEffect(() => {
    props.paymentInfo();
    return props.resetPaymentInfo;
  }, []);

  const handleClosePress = () => {
    props.history.pop();
  };

  const [showModal, setShowHideModal] = useState(false);
  const [showPaymentOverview, setShowPaymentOverview] = useState(false);

  const toggleModal = () => {
    setShowHideModal(!showModal);
  };

  const togglePaymentOverviewModal = () => {
    setShowPaymentOverview(!showPaymentOverview);
  };

  const submitPayment = async (values: PaymentForm) => {
    setShowPaymentOverview(!showPaymentOverview);
    analyticsService.logEvent(AnalyticsEvent.PaymentSubmitClicked);

    // Assign total amount due for all enecounters if using that option
    values.paymentAmount = calcTotalPayment(values) || 0;

    // Dispatch apply payment action
    const response = await props.applyPayment(values);

    if (response.error) {
      toggleModal();
    } else {
      analyticsService.logEvent(AnalyticsEvent.PaymentSubmitClicked, {
        currentUrl,
        referringUrl
      });
      props.history.replace('PaymentConfirmation', {
        amount: values.paymentAmount,
        confNumber: [oc(response).payload.data.receiptNumber('N/A')],
        paidDate: formatDate(new Date(oc(response).payload.data.receiptDate('N/A'))),
        accountNumber: `107-${oc(props).data.guarantor.mmi('N/A')}`,
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.emailAddress
      });
    }
  };

  const accountList: any[] = oc(props).data.accountList([]);
  const paymentPlanList: any[] = oc(props).data.paymentPlanList([]);
  const onlyAREAccount: boolean = oc(props).data.onlyAREAccount(false);
  const showECheck: boolean = oc(props).data.showECheck(false);

  const fieldPaymentAmount = useRef(null);
  const fieldFirstName = useRef(null);
  const fieldLastName = useRef(null);
  const fieldAddress = useRef(null);
  const fieldAddress2 = useRef(null);
  const fieldCity = useRef(null);
  const fieldState = useRef(null);
  const fieldZip = useRef(null);

  const fieldCardNumber = useRef(null);
  const fieldCardExpiration = useRef(null);
  const fieldCardCvv = useRef(null);

  const fieldRoutingNumber = useRef(null);
  const fieldAccountNumber = useRef(null);

  const fieldEmailAddress = useRef(null);
  const fieldEmailAddress2 = useRef(null);

  const focusNextField = nextRef => {
    nextRef.current.focus();
  };

  const posAmount = /^[0-9]*(\.[0-9]{0,2})?$/;
  const posNumber = /^[0-9 ]+$/;

  if (props.isFetchingPaymentInfo) {
    return (
      <Screen safe>
        <SpinnerModal isLoading />
      </Screen>
    );
  }

  const initialValues: PaymentForm = {
    paymentOption: '1',
    amountDue: 0.0,
    paymentPlanDue: 0.0,
    paymentAmount: 0.0,
    paymentMethod: '1',
    firstName: '',
    lastName: '',
    address: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    accountType: '2',
    routingNumber: '',
    accountNumber: '',
    cardNumber: '',
    cardExpiration: '',
    cardCvv: '',
    emailAddress: '',
    emailAddress2: '',
    encounters: accountList.map(a => ({ ...a, paymentAmount: undefined })),
    paymentPlans: paymentPlanList.map(p => ({ ...p, paymentAmount: undefined }))
  };

  const submitPaymentValidationSchema = Yup.object().shape({
    paymentAmount: Yup.lazy(() => {
      const totalDueAmount = oc(props).data.totalDueAmount(0);
      return Yup.string()
        .required('Please enter a valid payment amount.')
        .matches(posAmount, 'Payment should be valid amount')
        .lessThanValue(
          totalDueAmount,
          `Cannot exceed total amount due $${formatMoney(totalDueAmount)}`,
          { inclusive: true }
        );
    }),
    firstName: Yup.string()
      .required('Please enter a valid first name.')
      .matches(/^[a-zA-Z\- ]*$/, 'No numbers or special characters')
      .max(20, 'Name should not be more that 10')
      .whiteSpace('Name should not be empty'),
    lastName: Yup.string()
      .required('Please enter a valid last name.')
      .matches(/^[a-zA-Z\- ]*$/, 'No numbers or special characters')
      .max(20, 'Name should not be more that 10')
      .whiteSpace('Last Name should not be empty'),
    address: Yup.string()
      .matches(/^[a-zA-Z0-9\- .@#()]*$/, 'No special characters')
      .max(25, 'Address should not be more that 25')
      .whiteSpace('Address should not be empty')
      .required('Please enter a valid address'),
    address2: Yup.string()
      .matches(/^[a-zA-Z0-9\- .@#()]*$/, 'No special characters')
      .max(25, 'Address should not be more that 25')
      .notRequired(),
    city: Yup.string()
      .matches(/^[a-zA-Z\-. ]*$/, 'No numbers or special characters')
      .max(20, 'City should not be more that 20')
      .whiteSpace('City should not be empty')
      .required('Please enter a valid city'),
    state: Yup.string().required('Please enter a valid state.'),
    zip: Yup.string()
      .matches(/^[0-9]{5}$/, 'Please enter a valid zip code (XXXXX)')
      .required('Please enter a valid zip code'),
    accountType: Yup.number()
      .notRequired()
      .when('paymentMethod', {
        is: val => val === '2',
        then: Yup.number().required('Please enter a valid account type.')
      }),
    routingNumber: Yup.number()
      .notRequired()
      .when('paymentMethod', {
        is: val => val === '2',
        then: Yup.number().required('Please enter a valid routing number.')
      }),
    accountNumber: Yup.number()
      .notRequired()
      .when('paymentMethod', {
        is: val => val === '2',
        then: Yup.number().required('Please enter a valid account number.')
      }),
    cardNumber: Yup.string()
      .notRequired()
      .when('paymentMethod', {
        is: val => val === '1',
        then: Yup.string()
          .matches(posNumber, 'Card should be valid number')
          .min(16, 'Card number should be more than 12 digits')
          .required('Please enter a valid card number.')
      }),
    cardExpiration: Yup.string()
      .notRequired()
      .when('paymentMethod', {
        is: val => val === '1',
        then: Yup.string()
          .matches(
            /^(0[1-9]|1[0-2])\/?(([0-9]{4}|[0-9]{2})$)/,
            'Please enter a valid expiration date MM/YY'
          )
          .required('Please enter a valid expiration date MM/YY.')
          .test('Expiration Date', 'Should be valid expiration date', typedNumber => {
            if (typedNumber && typedNumber.length === 5) {
              const enteredDate = dayjs(
                `20${typedNumber.split('/')[1]}-${typedNumber.split('/')[0]}-01`
              );
              const today = dayjs(new Date());
              if (enteredDate.isBefore(today)) {
                return false;
              }
            }
            return true;
          })
      }),
    cardCvv: Yup.string()
      .notRequired()
      .when('paymentMethod', {
        is: val => val === '1',
        then: Yup.string()
          .matches(/^[0-9]{3,4}$/, 'Please enter a valid CVV code')
          .required('Please enter a valid CVV code.')
      }),
    emailAddress: Yup.string()
      .email('Email must be valid')
      .required('Please enter a valid email address.'),
    emailAddress2: Yup.string()
      .email('Email must be valid')
      .oneOf([Yup.ref('emailAddress'), null], 'Email does not match')
      .required('Please re-enter an valid email address.'),
    encounters: Yup.array()
      .of(
        Yup.lazy((value, field) => {
          let totalEncounterAmount = 0;
          const pathValue = field.path.split('[')[1];
          if (pathValue) {
            /** Extracting index and getting encounter Balance for each encounter */
            const elementIndex = pathValue.replace(']', '');
            totalEncounterAmount = accountList[elementIndex].accountBalance;
          }
          return Yup.object()
            .shape({
              paymentAmount: Yup.string()
                .notRequired()
                .nullable()
                .lessThanValue(
                  totalEncounterAmount,
                  `Cannot exceed total balance $${formatMoney(totalEncounterAmount)}`,
                  { inclusive: true }
                )
                .matches(posAmount, 'Payment should be valid amount')
            })
            .nullable();
        })
      )
      .notRequired(),
    paymentPlans: Yup.array()
      .of(
        Yup.lazy((value, field) => {
          let totalPaymentPlan = 0;
          const pathValue = field.path.split('[')[1];
          if (pathValue) {
            /** Extracting index and getting Balance for each payment plan */
            const elementIndex = pathValue.replace(']', '');
            totalPaymentPlan = paymentPlanList[elementIndex].currentBalance;
          }
          return Yup.object()
            .shape({
              paymentAmount: Yup.string()
                .nullable()
                .notRequired()
                .matches(posAmount, 'Payment should be valid amount')
                .lessThanValue(
                  totalPaymentPlan,
                  `Cannot exceed total balance $${formatMoney(totalPaymentPlan)}`,
                  { inclusive: true }
                )
            })
            .nullable();
        })
      )
      .notRequired()
  });

  const refStore: JsonObj = {};
  const refStorePlan: JsonObj = {};

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={submitPaymentValidationSchema}
      onSubmit={values => submitPayment(values)}
    >
      {({
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        isValid,
        setFieldValue,
        handleSubmit
      }) => {
        const totalPaymentAmount = calcTotalPayment(values);
        return (
          <Screen safe>
            <SpinnerModal isLoading={props.isFetching || props.isFetchingPaymentInfo} />
            <GradientView>
              <FlexBox spacing={Spacing.xLarge} alignItems="flex-start" justifyContent="flex-start">
                <Typography color={Color.white} fontSize={FontSize.largeHeading}>
                  Make a Payment
                </Typography>
                {!!totalPaymentAmount && (
                  <Typography color={Color.white} fontSize={FontSize.large}>
                    Paying ${formatMoney(totalPaymentAmount)}
                  </Typography>
                )}
                <CloseButton accessibilityLabel="close button" onPress={handleClosePress}>
                  <Icon name="close" size={24} color={Color.white} />
                </CloseButton>
              </FlexBox>
            </GradientView>
            <Divider />
            <Spacer size="small" />
            <Typography
              color={Color.black}
              fontSize={FontSize.heading}
              fontWeight={FontWeight.semibold}
            >
              Payment Options
            </Typography>
            <FlexBox
              alignItems="flex-start"
              justifyContent="center"
              flexDirection="column"
              vSpacing={Spacing.medium}
              hSpacing={0}
            >
              <StyledFormGroup>
                <RadioButton
                  accessibilityLabel="Choose encounters/plans"
                  selected={values.paymentOption === '1'}
                  onPress={() => {
                    setFieldValue('paymentOption', '1');
                    values.paymentAmount = 0.0;
                  }}
                  label="Choose encounters/plans"
                  value="1"
                />

                <RadioButton
                  accessibilityLabel="Amount due $"
                  selected={values.paymentOption === '2'}
                  onPress={() => {
                    setFieldValue('paymentOption', '2');
                    values.paymentAmount = oc(props).data.totalDueAmount(0.0);
                  }}
                  label={`Amount due $${formatMoney(oc(props).data.totalDueAmount(0.0))}`}
                  value="2"
                />

                {/*
                    DFD-11927: Hide payment plan option

                    {paymentPlanList.length > 0 && (
                      <RadioButton
                        accessibilityLabel="Payment plan due $"
                        selected={values.paymentOption === '3'}
                        onPress={() => {
                          setFieldValue('paymentOption', '3');
                          values.paymentAmount = get(props, 'data.payplanDueAmount', 0.0);
                        }}
                        label={`Payment plan due $${formatMoney(
                          get(props, 'data.payplanDueAmount', 0.0)
                        )}`}
                        value="3"
                      />
                    )}
                    */}

                {onlyAREAccount && (
                  <RadioButton
                    accessibilityLabel="Specify amount"
                    selected={values.paymentOption === '4'}
                    onPress={() => {
                      setFieldValue('paymentOption', '4');
                      values.paymentAmount = 0.0;
                    }}
                    label="Specify amount"
                    value="4"
                  />
                )}
              </StyledFormGroup>
            </FlexBox>

            <Spacer size="small" />
            <Typography fontSize={FontSize.small}>
              <Typography fontSize={FontSize.small} fontWeight={FontWeight.bold}>
                Note:{' '}
              </Typography>
              only payment plans set up with Intermountain are displayed
            </Typography>

            {values.paymentOption === '4' && (
              <View>
                <Spacer size="medium" />
                <Divider />
                <Spacer size="small" />
                <FlexBox flexDirection="column">
                  <DefaultLabel>Amount you would like to pay</DefaultLabel>
                  <TextInput
                    error={
                      touched.paymentAmount && errors.paymentAmount ? errors.paymentAmount : null
                    }
                    clearButtonMode="always"
                    placeholder="$0.00"
                    onChangeText={handleChange('paymentAmount')}
                    onBlur={handleBlur('paymentAmount')}
                    ref={fieldPaymentAmount}
                    onSubmitEditing={() => focusNextField(fieldFirstName)}
                    keyboardType="numeric"
                    returnKeyType="done"
                  />
                </FlexBox>
              </View>
            )}

            {values.paymentOption === '1' &&
              values.encounters.length > 0 &&
              values.encounters.map((encounter: EncounterForm, index: number) => {
                refStore[index] = React.createRef();
                if (encounter.paymentPlanFlag) {
                  return null;
                }

                return (
                  <View>
                    <Spacer size="medium" />
                    <Divider />
                    <Spacer size="small" />
                    <FlexBox
                      alignItems="flex-start"
                      justifyContent="center"
                      flexDirection="column"
                      vSpacing={Spacing.medium}
                    >
                      <Typography
                        color={Color.black}
                        fontSize={FontSize.heading}
                        fontWeight={FontWeight.semibold}
                      >
                        Encounter: {`${encounter.facilityId}-${encounter.accountNumber}`}
                      </Typography>
                      <Typography
                        color={Color.black}
                        fontSize={FontSize.large}
                        fontWeight={FontWeight.semibold}
                      >
                        Patient Name: {encounter.patientLastName} {encounter.patientFirstName}
                      </Typography>
                      <Typography
                        color={Color.black}
                        fontSize={FontSize.large}
                        fontWeight={FontWeight.semibold}
                      >
                        Status: {encounter.insuranceFlag ? 'Pending Insurance' : 'Self Pay'}
                      </Typography>
                      <Typography
                        color={Color.black}
                        fontSize={FontSize.large}
                        fontWeight={FontWeight.semibold}
                      >
                        Balance: ${formatMoney(encounter.remainingAmount)}
                      </Typography>
                    </FlexBox>
                    <Spacer size="small" />
                    <FlexBox flexDirection="column">
                      <DefaultLabel>Amount you would like to pay</DefaultLabel>
                      <TextInput
                        error={
                          oc(touched).encounters[index].paymentAmount() &&
                          oc(errors).encounters[index].paymentAmount()
                            ? oc(errors).encounters[index].paymentAmount()
                            : null
                        }
                        keyboardType="numeric"
                        disabled={!!encounter.paymentPlanFlag}
                        clearButtonMode="always"
                        placeholder="$0.00"
                        value={oc(values).encounters[index].paymentAmount()}
                        onChangeText={handleChange(`encounters.${index}.paymentAmount`)}
                        ref={refStore[index]}
                        returnKeyType="done"
                        onBlur={handleBlur(`encounters.${index}.paymentAmount`)}
                      />
                    </FlexBox>
                  </View>
                );
              })}

            {values.paymentOption === '1' &&
              values.paymentPlans.length > 0 &&
              values.paymentPlans.map((plan: PaymentPlanForm, index: number) => {
                refStorePlan[index] = React.createRef();
                return (
                  <View>
                    <Spacer size="medium" />
                    <Divider />
                    <Spacer size="small" />
                    <FlexBox
                      alignItems="flex-start"
                      justifyContent="center"
                      flexDirection="column"
                      vSpacing={Spacing.medium}
                    >
                      <Typography
                        color={Color.black}
                        fontSize={FontSize.heading}
                        fontWeight={FontWeight.semibold}
                      >
                        Payment Plan: {plan.paymentPlanId}
                      </Typography>
                      <Typography
                        color={Color.black}
                        fontSize={FontSize.large}
                        fontWeight={FontWeight.semibold}
                      >
                        Payment due date: {formatDate(plan.nextPaymentDueDate)}
                      </Typography>
                      <Typography
                        color={Color.black}
                        fontSize={FontSize.large}
                        fontWeight={FontWeight.semibold}
                      >
                        Payoff amount: ${formatMoney(Number(plan.currentBalance))}
                      </Typography>
                      <Typography
                        color={Color.black}
                        fontSize={FontSize.large}
                        fontWeight={FontWeight.semibold}
                      >
                        Overdue: ${formatMoney(Number(plan.overDueAmount))}
                      </Typography>
                      <Typography
                        color={Color.black}
                        fontSize={FontSize.large}
                        fontWeight={FontWeight.semibold}
                      >
                        Monthly payment: ${formatMoney(Number(plan.monthlyPaymentAmount))}
                      </Typography>
                      <Typography
                        color={Color.black}
                        fontSize={FontSize.large}
                        fontWeight={FontWeight.semibold}
                      >
                        Amount due: $
                        {formatMoney(
                          Number(plan.remainingMonthlyOblication) + Number(plan.overDueAmount)
                        )}
                      </Typography>
                    </FlexBox>
                    <Spacer size="small" />
                    <FlexBox flexDirection="column">
                      <DefaultLabel>Amount you would like to pay</DefaultLabel>
                      <TextInput
                        error={
                          oc(touched).paymentPlans[index].paymentAmount() &&
                          oc(errors).paymentPlans[index].paymentAmount()
                            ? oc(errors).paymentPlans[index].paymentAmount()
                            : null
                        }
                        clearButtonMode="always"
                        placeholder="$0.00"
                        value={oc(values).paymentPlans[index].paymentAmount()}
                        onChangeText={handleChange(`paymentPlans.${index}.paymentAmount`)}
                        onBlur={handleBlur(`paymentPlans.${index}.paymentAmount`)}
                        ref={refStorePlan[index]}
                        keyboardType="numeric"
                        returnKeyType="done"
                      />
                    </FlexBox>
                  </View>
                );
              })}

            <Spacer size="medium" />
            <Divider />
            <Spacer size="small" />
            <Typography
              color={Color.black}
              fontSize={FontSize.heading}
              fontWeight={FontWeight.semibold}
            >
              Payment Method
            </Typography>
            <FlexBox
              alignItems="flex-start"
              justifyContent="center"
              flexDirection="column"
              vSpacing={Spacing.medium}
              hSpacing={0}
            >
              <StyledFormGroup>
                <RadioButton
                  accessibilityLabel="Credit Card"
                  selected={values.paymentMethod === '1'}
                  onPress={() => setFieldValue('paymentMethod', '1')}
                  label="Credit Card"
                  value="1"
                />
                {showECheck && (
                  <RadioButton
                    accessibilityLabel="ACH Withdrawal"
                    selected={values.paymentMethod === '2'}
                    onPress={() => setFieldValue('paymentMethod', '2')}
                    label="ACH Withdrawal"
                    value="2"
                  />
                )}
              </StyledFormGroup>
            </FlexBox>

            <FlexBox
              alignItems="flex-start"
              justifyContent="center"
              flexDirection="column"
              vSpacing={Spacing.medium}
            >
              <Typography
                color={Color.black}
                fontSize={FontSize.heading}
                fontWeight={FontWeight.semibold}
              >
                Name
              </Typography>
            </FlexBox>
            <FlexBox alignItems="center" justifyContent="center" flexDirection="column">
              <TextInput
                error={touched.firstName && errors.firstName ? errors.firstName : null}
                clearButtonMode="always"
                placeholder="First Name"
                value={values.firstName}
                onChangeText={handleChange('firstName')}
                onBlur={handleBlur('firstName')}
                ref={fieldFirstName}
                onSubmitEditing={() => focusNextField(fieldLastName)}
                returnKeyType="next"
              />
              <Spacer size="small" />
              <TextInput
                error={touched.lastName && errors.lastName ? errors.lastName : null}
                clearButtonMode="always"
                placeholder="Last Name"
                value={values.lastName}
                onChangeText={handleChange('lastName')}
                onBlur={handleBlur('lastName')}
                ref={fieldLastName}
                onSubmitEditing={() => focusNextField(fieldAddress)}
                returnKeyType="next"
              />
            </FlexBox>

            <Spacer size="small" />
            <FlexBox
              alignItems="flex-start"
              justifyContent="center"
              flexDirection="column"
              vSpacing={Spacing.medium}
            >
              <Typography
                color={Color.black}
                fontSize={FontSize.heading}
                fontWeight={FontWeight.semibold}
              >
                Billing Address
              </Typography>
            </FlexBox>
            <FlexBox alignItems="center" justifyContent="center" flexDirection="column">
              <TextInput
                error={touched.address && errors.address ? errors.address : null}
                clearButtonMode="always"
                placeholder="Street Address"
                value={values.address}
                onChangeText={handleChange('address')}
                onBlur={handleBlur('address')}
                ref={fieldAddress}
                onSubmitEditing={() => focusNextField(fieldAddress2)}
                returnKeyType="next"
              />
              <Spacer size="small" />
              <TextInput
                error={touched.address2 && errors.address2 ? errors.address2 : null}
                clearButtonMode="always"
                placeholder="Apt, Suite, etc."
                value={values.address2}
                onChangeText={handleChange('address2')}
                onBlur={handleBlur('address2')}
                ref={fieldAddress2}
                onSubmitEditing={() => focusNextField(fieldCity)}
                returnKeyType="next"
              />
              <Spacer size="small" />
              <TextInput
                error={touched.city && errors.city ? errors.city : null}
                clearButtonMode="always"
                placeholder="City"
                value={values.city}
                onChangeText={handleChange('city')}
                onBlur={handleBlur('city')}
                ref={fieldCity}
                onSubmitEditing={() => fieldState.current.togglePicker()}
                returnKeyType="next"
              />
              <Spacer size="small" />
              <InputPicker
                error={(touched.state && errors.state) || null}
                items={LOCATION_STATES}
                selected={values.state}
                onSelection={value => {
                  setFieldValue('state', value);
                  if (Platform.OS === 'android') fieldZip.current._inputElement.focus();
                }}
                placeholder="State"
                ref={fieldState}
                onDonePress={() => fieldZip.current._inputElement.focus()}
                returnKeyType="done"
              />
              <Spacer size="small" />
              <MaskedTextInput
                keyboardType="number-pad"
                type="custom"
                options={{
                  mask: '99999'
                }}
                error={touched.zip && errors.zip ? errors.zip : null}
                clearButtonMode="always"
                placeholder="Zip/Postal Code"
                value={values.zip}
                onChangeText={handleChange('zip')}
                onBlur={handleBlur('zip')}
                ref={fieldZip}
                onSubmitEditing={() =>
                  values.paymentMethod === '1'
                    ? fieldCardNumber.current._inputElement.focus()
                    : focusNextField(fieldRoutingNumber)
                }
                returnKeyType="done"
              />
            </FlexBox>

            {values.paymentMethod === '1' && (
              <View>
                <Spacer size="small" />
                <FlexBox
                  alignItems="flex-start"
                  justifyContent="center"
                  flexDirection="column"
                  vSpacing={Spacing.medium}
                >
                  <Typography
                    color={Color.black}
                    fontSize={FontSize.heading}
                    fontWeight={FontWeight.semibold}
                  >
                    Credit Card
                  </Typography>
                </FlexBox>
                <FlexBox alignItems="center" justifyContent="center" flexDirection="column">
                  <MaskedTextInput
                    keyboardType="number-pad"
                    type="custom"
                    options={{
                      mask: '9999 9999 9999 9999 999'
                    }}
                    error={touched.cardNumber && errors.cardNumber ? errors.cardNumber : null}
                    clearButtonMode="always"
                    placeholder="Card Number"
                    value={values.cardNumber}
                    onChangeText={handleChange('cardNumber')}
                    onBlur={handleBlur('cardNumber')}
                    ref={fieldCardNumber}
                    onSubmitEditing={() => fieldCardExpiration.current._inputElement.focus()}
                    returnKeyType="done"
                  />
                  <Spacer size="small" />
                  <MaskedTextInput
                    keyboardType="number-pad"
                    type="custom"
                    options={{
                      mask: '99/99'
                    }}
                    error={
                      touched.cardExpiration && errors.cardExpiration ? errors.cardExpiration : null
                    }
                    clearButtonMode="always"
                    value={values.cardExpiration}
                    placeholder="Expiration MM/YY"
                    onChangeText={handleChange('cardExpiration')}
                    onBlur={handleBlur('cardExpiration')}
                    ref={fieldCardExpiration}
                    onSubmitEditing={() => fieldCardCvv.current._inputElement.focus()}
                    returnKeyType="done"
                  />
                  <Spacer size="small" />
                  <MaskedTextInput
                    keyboardType="number-pad"
                    type="custom"
                    options={{
                      mask: '9999'
                    }}
                    error={touched.cardCvv && errors.cardCvv ? errors.cardCvv : null}
                    clearButtonMode="always"
                    placeholder="CVV"
                    value={values.cardCvv}
                    onChangeText={handleChange('cardCvv')}
                    onBlur={handleBlur('cardCvv')}
                    ref={fieldCardCvv}
                    onSubmitEditing={() => focusNextField(fieldEmailAddress)}
                    returnKeyType="done"
                  />
                </FlexBox>
              </View>
            )}

            {values.paymentMethod === '2' && (
              <View>
                <Spacer size="small" />
                <FlexBox
                  alignItems="flex-start"
                  justifyContent="center"
                  flexDirection="column"
                  vSpacing={Spacing.medium}
                >
                  <Typography
                    color={Color.black}
                    fontSize={FontSize.heading}
                    fontWeight={FontWeight.semibold}
                  >
                    Banking Information
                  </Typography>
                </FlexBox>
                <StyledFormGroup>
                  <RadioButton
                    accessibilityLabel="Checking"
                    selected={values.accountType === '2'}
                    onPress={() => setFieldValue('accountType', '2')}
                    label="Checking"
                    value="2"
                  />
                  <RadioButton
                    accessibilityLabel="Savings"
                    selected={values.accountType === '1'}
                    onPress={() => setFieldValue('accountType', '1')}
                    label="Savings"
                    value="1"
                  />
                </StyledFormGroup>
                <Spacer size="small" />
                <FlexBox alignItems="center" justifyContent="center" flexDirection="column">
                  <TextInput
                    error={
                      touched.routingNumber && errors.routingNumber ? errors.routingNumber : null
                    }
                    clearButtonMode="always"
                    placeholder="Routing Number"
                    value={values.routingNumber}
                    onChangeText={handleChange('routingNumber')}
                    onBlur={handleBlur('routingNumber')}
                    ref={fieldRoutingNumber}
                    onSubmitEditing={() => focusNextField(fieldAccountNumber)}
                    keyboardType="number-pad"
                    returnKeyType="next"
                  />
                  <Spacer size="small" />
                  <TextInput
                    error={
                      touched.accountNumber && errors.accountNumber ? errors.accountNumber : null
                    }
                    clearButtonMode="always"
                    placeholder="Account Number"
                    value={values.accountNumber}
                    onChangeText={handleChange('accountNumber')}
                    onBlur={handleBlur('accountNumber')}
                    ref={fieldAccountNumber}
                    onSubmitEditing={() => focusNextField(fieldEmailAddress)}
                    keyboardType="number-pad"
                    returnKeyType="next"
                  />
                </FlexBox>
              </View>
            )}

            <Spacer size="small" />
            <FlexBox
              alignItems="flex-start"
              justifyContent="center"
              flexDirection="column"
              vSpacing={Spacing.medium}
            >
              <Typography
                color={Color.black}
                fontSize={FontSize.heading}
                fontWeight={FontWeight.semibold}
              >
                Send Receipt to:
              </Typography>
            </FlexBox>
            <FlexBox alignItems="center" justifyContent="center" flexDirection="column">
              <TextInput
                error={touched.emailAddress && errors.emailAddress ? errors.emailAddress : null}
                clearButtonMode="always"
                placeholder="Enter email address"
                value={values.emailAddress}
                autoCapitalize="none"
                onChangeText={handleChange('emailAddress')}
                onBlur={handleBlur('emailAddress')}
                ref={fieldEmailAddress}
                onSubmitEditing={() => focusNextField(fieldEmailAddress2)}
                keyboardType="email-address"
                returnKeyType="next"
              />
              <TextInput
                error={touched.emailAddress2 && errors.emailAddress2 ? errors.emailAddress2 : null}
                clearButtonMode="always"
                placeholder="Re-enter email address"
                value={values.emailAddress2}
                autoCapitalize="none"
                onChangeText={handleChange('emailAddress2')}
                onBlur={handleBlur('emailAddress2')}
                ref={fieldEmailAddress2}
                keyboardType="email-address"
                returnKeyType="done"
              />
            </FlexBox>

            <SubmitButtonBox>
              <StyledButton
                disabled={!isValid}
                accessibilityLabel="Submit"
                onPress={togglePaymentOverviewModal}
              >
                Submit
              </StyledButton>
              <StyledButton customColor={Color.gray} onPress={handleClosePress}>
                Cancel
              </StyledButton>
            </SubmitButtonBox>

            <Spacer size="medium" />

            {showModal && (
              <BottomModal
                onCloseModal={toggleModal}
                modalText={oc(props).error.response.data.shortDescription('Error')}
                iconName="error"
                iconColor={Color.red}
              />
            )}

            {showPaymentOverview && (
              <BottomConfirmationModal
                onCloseModal={togglePaymentOverviewModal}
                onButtonPress={handleSubmit}
                modalText="Payment Overview"
                buttonText="Confirm Payment"
              >
                <FlexBox flexDirection="column" width="100%">
                  <FlexBox flexDirection="row" justifyContent="space-between">
                    <Typography fontSize={FontSize.heading}>Payment amount: </Typography>
                    <Typography fontSize={FontSize.heading}>
                      ${formatMoney(totalPaymentAmount || 0)}
                    </Typography>
                  </FlexBox>
                  <Spacer size="small" />
                  <FlexBox flexDirection="row" justifyContent="space-between">
                    <Typography fontSize={FontSize.heading}>Payment date: </Typography>
                    <Typography fontSize={FontSize.heading}>{`${formatDate(
                      new Date()
                    )}`}</Typography>
                  </FlexBox>
                </FlexBox>
              </BottomConfirmationModal>
            )}
          </Screen>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = (state: RootState) => ({
  data: PaymentInfoDataSelector(state),
  receiptData: ApplyPaymentDataSelector(state),
  error: ApplyPaymentErrorSelector(state),
  isFetching: ApplyPaymentFetchingSelector(state),
  isFetchingPaymentInfo: PaymentInfoFetchingSelector(state),
  currentUrl: currentLocationPathNameSelector(state),
  referringUrl: previousLocationPathNameSelector(state)
});

const mapDispatchToProps = {
  paymentInfo,
  applyPayment,
  resetPaymentInfo
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentScreen);
