import React, { useEffect, useState } from 'react';
import { Color } from 'modules/styles/colors';
import { FontSize, FontWeight, Spacing } from 'modules/styles/variables';
import { convertToLowerKabobCase } from 'modules/utils/StringUtils';
import {
  makeStyles,
  MuiAccordion,
  MuiAccordionDetails,
  MuiAccordionSummary,
  MuiBox,
  MuiButton,
  MuiTypography
} from 'theme/material-ui';

export interface EVisitAccordionProps {
  isLoading: boolean;
  title: string;
  paragraphs: string[];
  termCode: string;
  onClick?: (termCode: string, condition: string) => void;
}

const useStyles = makeStyles({
  accordion: {
    boxShadow: 'none',
    border: `1px solid ${Color.grayLight}`
  }
});

const EVisitAccordion = ({
  isLoading,
  title,
  paragraphs,
  termCode,
  onClick
}: EVisitAccordionProps) => {
  const classes = useStyles();
  const [clicked, setClicked] = useState(false);

  useEffect(() => {
    if (!isLoading) {
      setClicked(false);
    }
  }, [isLoading]);

  return (
    <MuiBox my={Spacing.xSmall}>
      <MuiAccordion classes={{ root: classes.accordion }}>
        <MuiAccordionSummary>
          <MuiBox
            width="100%"
            textAlign="center"
            py={1}
            color={Color.primary}
            fontWeight={FontWeight.semibold}
            data-testid={convertToLowerKabobCase(title, '-expandable-drawer')}
          >
            {title}
          </MuiBox>
        </MuiAccordionSummary>
        <MuiAccordionDetails>
          <MuiBox width="100%" px={3}>
            <MuiTypography fontSize={FontSize.base} style={{ marginBottom: Spacing.largeXLarge }}>
              {paragraphs.map(paragraph => (
                <MuiTypography paragraph key={paragraph}>
                  {paragraph}
                </MuiTypography>
              ))}
            </MuiTypography>

            {onClick ? (
              <MuiBox>
                <MuiBox mx="auto" padding={Spacing.xSmall} maxWidth="250px">
                  <MuiButton
                    fullWidth
                    data-testid={convertToLowerKabobCase(title, '-button')}
                    variant="contained"
                    color="primary"
                    size="large"
                    loading={isLoading && clicked}
                    onClick={() => {
                      setClicked(true);
                      onClick(termCode, title);
                    }}
                  >
                    Select this reason
                  </MuiButton>
                </MuiBox>
              </MuiBox>
            ) : null}
          </MuiBox>
        </MuiAccordionDetails>
      </MuiAccordion>
    </MuiBox>
  );
};

export default EVisitAccordion;
