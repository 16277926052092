import React from 'react';

export default {
  svg: (
    <g>
      <polygon
        id="Path-2"
        fillRule="nonzero"
        points="8 0 16 0 16 8 24 8 24 16 16 16 16 24 8 24 8 16 0 16 0 8 8 8"
      />
    </g>
  ),
  viewBox: '0 0 24 24'
};
