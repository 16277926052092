import React from 'react';

const st0 = {
  fill: 'none',
  strokeWidth: 2,
  strokeLinecap: 'round',
  strokeLinejoin: 'round',
  strokeMiterlimit: 10
} as const;

const st1 = {
  fill: 'none',
  strokeWidth: 2,
  strokeMiterlimit: 10
} as const;

const st2 = {
  fill: 'none',
  strokeWidth: 2,
  strokeLinecap: 'round',
  strokeLinejoin: 'round',
  strokeMiterlimit: 10
} as const;

export default {
  svg: (
    <g fill="none">
      <polygon {...st0} points="2,1.6 2,20.7 8.9,20.7 8.9,28.5 16.7,20.7 28,20.7 28,1.6  " />
      <circle {...st1} cx="9.8" cy="9.4" r="1.7" />
      <line {...st2} x1="15" y1="7.7" x2="21.9" y2="7.7" />
      <line {...st2} x1="15" y1="11.7" x2="21.9" y2="11.7" />
      <line {...st2} x1="8.1" y1="15.6" x2="21.9" y2="15.6" />
    </g>
  ),
  viewBox: '0 0 33 33'
};
