import confusedPeople from 'assets/graphics/confusedPeople.svg';
import FlexBox from 'components/UI/Layout/FlexBox';
import { ListItem } from 'components/UI/ListItem/ListItem';
import { FontSize, Spacing } from 'modules/styles/variables';
import { Color } from 'modules/styles/colors';
import React from 'react';
import useNavigationAnalytics from 'hooks/useNavigationAnalytics';
import { History } from 'history';
import { AnalyticsEvent } from 'services/AnalyticsService';
import { HELP_SCREEN_LINKS, STATUS_PAGE_URL } from 'lib/constants/help';
import { MuiBox, MuiContainer, MuiList, MuiPaper, MuiTypography } from 'theme/material-ui';
import { convertToLowerKabobCase } from 'modules/utils/StringUtils';

export const HelpScreen = ({ history }: { history: History }) => {
  const shareFeedbackClicked = useNavigationAnalytics(AnalyticsEvent.ShareFeedbackClicked);
  const statusPageClicked = useNavigationAnalytics(AnalyticsEvent.MyHealthPlusStatusPageClicked);
  const technicalSupportClicked = useNavigationAnalytics(AnalyticsEvent.TechnicalSupportClicked);
  const contactUsClicked = useNavigationAnalytics(AnalyticsEvent.ContactUsClicked);
  const aboutThisAppClicked = useNavigationAnalytics(AnalyticsEvent.AboutThisAppClicked);

  const handleMenuItemPress = (path: string) => {
    switch (path) {
      case HELP_SCREEN_LINKS.Feedback.webPath:
        shareFeedbackClicked();
        history.push(path, { feedbackGiven: false });
        break;
      case HELP_SCREEN_LINKS.TechSupport.webPath:
        technicalSupportClicked();
        history.push(path);
        break;
      case HELP_SCREEN_LINKS.Contact.webPath:
        contactUsClicked();
        history.push(path);
        break;
      case HELP_SCREEN_LINKS.About.webPath:
        aboutThisAppClicked();
        history.push(path);
        break;
      case HELP_SCREEN_LINKS.MyHealthPlusStatusPage.webPath:
        statusPageClicked();
        window.open(STATUS_PAGE_URL, '_blank');
        break;
      default:
        history.push(path);
    }
  };

  return (
    <>
      <FlexBox
        backgroundColor={Color.baseColor}
        justifyContent="center"
        alignItems="center"
        vSpacing={Spacing.xLarge}
        flex={1}
      >
        <MuiContainer maxWidth="md">
          <MuiPaper style={{ backgroundColor: Color.foreColor }} elevation={1}>
            <FlexBox justifyContent="center" alignItems="center">
              <img style={{ width: 385, height: 200 }} src={confusedPeople} alt="" />
              <MuiBox m={1}>
                <MuiTypography fontSize={FontSize.heading}>
                  What do you need help with?
                </MuiTypography>
              </MuiBox>
            </FlexBox>
            <MuiList>
              {Object.values(HELP_SCREEN_LINKS).map(item => (
                <ListItem
                  testID={convertToLowerKabobCase(item.title)}
                  key={item.title}
                  icon="ChevronRight"
                  body={item.title}
                  onPress={() => handleMenuItemPress(item.webPath)}
                />
              ))}
            </MuiList>
          </MuiPaper>
        </MuiContainer>
      </FlexBox>
    </>
  );
};

export default HelpScreen;
