export interface UserAvatar {
  avatarId: string;
  fileName: string;
}

export const avatarComponents: UserAvatar[] = [
  {
    avatarId: 'avatar-01',
    fileName: 'Avatar01'
  },
  {
    avatarId: 'avatar-02',
    fileName: 'Avatar02'
  },
  {
    avatarId: 'avatar-03',
    fileName: 'Avatar03'
  },
  {
    avatarId: 'avatar-04',
    fileName: 'Avatar04'
  },
  {
    avatarId: 'avatar-05',
    fileName: 'Avatar05'
  },
  {
    avatarId: 'avatar-06',
    fileName: 'Avatar06'
  },
  {
    avatarId: 'avatar-07',
    fileName: 'Avatar07'
  },
  {
    avatarId: 'avatar-08',
    fileName: 'Avatar08'
  },
  {
    avatarId: 'avatar-09',
    fileName: 'Avatar09'
  },
  {
    avatarId: 'avatar-10',
    fileName: 'Avatar10'
  },
  {
    avatarId: 'avatar-11',
    fileName: 'Avatar11'
  },
  {
    avatarId: 'avatar-12',
    fileName: 'Avatar12'
  },
  {
    avatarId: 'avatar-13',
    fileName: 'Avatar13'
  },
  {
    avatarId: 'avatar-14',
    fileName: 'Avatar14'
  },
  {
    avatarId: 'avatar-15',
    fileName: 'Avatar15'
  },
  {
    avatarId: 'avatar-16',
    fileName: 'Avatar16'
  },
  {
    avatarId: 'avatar-17',
    fileName: 'Avatar17'
  },
  {
    avatarId: 'avatar-18',
    fileName: 'Avatar18'
  },
  {
    avatarId: 'avatar-19',
    fileName: 'Avatar19'
  },
  {
    avatarId: 'avatar-20',
    fileName: 'Avatar20'
  },
  {
    avatarId: 'avatar-21',
    fileName: 'Avatar21'
  },
  {
    avatarId: 'avatar-22',
    fileName: 'Avatar22'
  },
  {
    avatarId: 'avatar-23',
    fileName: 'Avatar23'
  },
  {
    avatarId: 'avatar-24',
    fileName: 'Avatar24'
  },
  {
    avatarId: 'avatar-25',
    fileName: 'Avatar25'
  },
  {
    avatarId: 'avatar-26',
    fileName: 'Avatar26'
  },
  {
    avatarId: 'avatar-27',
    fileName: 'Avatar27'
  },
  {
    avatarId: 'avatar-28',
    fileName: 'Avatar28'
  },
  {
    avatarId: 'avatar-29',
    fileName: 'Avatar29'
  },
  {
    avatarId: 'avatar-30',
    fileName: 'Avatar30'
  }
];
