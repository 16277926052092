import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  lowPriorityItemsSelector,
  highPriorityItemsSelector,
  lowPriorityErrorsSelector,
  highPriorityErrorsSelector,
  highPriorityItemsIsFetchingSelector,
  lowPriorityItemsIsFetchingSelector
} from 'store/priorityItems/selectors';
import { getLowPriorityItems, getHighPriorityItems } from 'store/priorityItems/actions';
import {
  HighPriorityActionItemsTypesInterface,
  LowPriorityActionItemsTypesInterface
} from 'store/priorityItems/types';
import { useProxySwitcherEffect } from './useProxySwitcherEffect';

/**
 * A useEffect that will get priority items
 */

export const useLowPriorityActionItems = () => {
  const [data, setData] = useState<LowPriorityActionItemsTypesInterface | undefined>(undefined);
  const [errors, setErrors] = useState<string[] | undefined>([]);
  const [refetch, setRefetch] = useState<() => void>(() => undefined);
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();
  const lowPriorityErrors = useSelector(lowPriorityErrorsSelector);
  const lowPriorityItems = useSelector(lowPriorityItemsSelector);
  const lowPriorityIsFetching = useSelector(lowPriorityItemsIsFetchingSelector);

  useProxySwitcherEffect(() => {
    dispatch(getLowPriorityItems());
  }, []);

  useEffect(() => {
    if (lowPriorityItems) {
      setData(lowPriorityItems.getActionItems);
      setRefetch(() => () => dispatch(getLowPriorityItems(false)));
    }
  }, [lowPriorityItems]);

  useEffect(() => {
    setLoading(lowPriorityIsFetching);
  }, [lowPriorityIsFetching]);

  useEffect(() => {
    setErrors(lowPriorityErrors);
  }, [lowPriorityErrors]);

  return {
    lowPriorityData: data,
    lowPriorityErrors: errors,
    lowPriorityLoading: loading,
    lowPriorityRefetch: refetch
  };
};

export const useHighPriorityActionItems = () => {
  const [data, setData] = useState<HighPriorityActionItemsTypesInterface | undefined>(undefined);
  const [errors, setErrors] = useState<string[] | undefined>([]);
  const [refetch, setRefetch] = useState<() => void>(() => undefined);
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();
  const highPriorityErrors = useSelector(highPriorityErrorsSelector);
  const highPriorityItems = useSelector(highPriorityItemsSelector);
  const highPriorityIsFetching = useSelector(highPriorityItemsIsFetchingSelector);

  useProxySwitcherEffect(() => {
    dispatch(getHighPriorityItems());
  }, []);

  useEffect(() => {
    if (highPriorityItems) {
      setData(highPriorityItems.getActionItems);
      setRefetch(() => () => dispatch(getHighPriorityItems(false)));
    }
  }, [highPriorityItems]);

  useEffect(() => {
    setLoading(highPriorityIsFetching);
  }, [highPriorityIsFetching]);

  useEffect(() => {
    setErrors(highPriorityErrors);
  }, [highPriorityErrors]);

  return {
    highPriorityData: data,
    highPriorityErrors: errors,
    highPriorityLoading: loading,
    highPriorityRefetch: refetch
  };
};
