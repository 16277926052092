import React from 'react';
import { FilterDatePicker } from './styled';
import dayjs from 'dayjs';

interface Props {
  value: dayjs.Dayjs;
  label: string;
  handleChange: (date: any) => void;
}

const FilterCalendar = ({ value, label, handleChange }: Props) => {
  return (
    <FilterDatePicker
      variant="inline"
      value={value}
      inputVariant="outlined"
      format="MM/DD/YYYY"
      label={label}
      id={`${label}-input`}
      openTo="date"
      InputAdornmentProps={{ position: 'start' }}
      onChange={handleChange}
    />
  );
};

export default FilterCalendar;
