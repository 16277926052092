import { ProviderSearchInterface } from 'lib/hooks/ProviderSearch/useProviderSearch';
import { FiltersKey } from 'lib/hooks/ProviderSearch/types';
import { history } from 'lib/history';
import { hasLength } from 'modules/utils/ArrayUtils';
import { searchUrl } from 'store/findProvider/constants';

export interface ProviderSearchParams {
  recentSearch: boolean;
  options: ProviderSearchInterface['options'];
  expandedFilters: FiltersKey[];
}

/**
 * Provider search has different suggestions that may or may not actually search.
 * We also needed this util function to accommodate search functionality from Get Care
 * to the Find Provider page.
 *
 * 1. Specialty Quick Link just wants to open filters [params.expandedFilters]
 * 2. Upon clicking a Quick Link, Suggestion, or Searching manually, needs to
 * land on the Find Provider page with the results.
 *
 * @param from Component name
 * @param query Provider search query
 * @param search Provider search
 * @param params Params from shared constants
 */
export function providerSearch({
  from,
  query,
  search,
  params
}: {
  from: 'Get Care' | 'Find Provider';
  query?: string;
  search: ProviderSearchInterface['search'];
  params?: ProviderSearchParams;
}) {
  const { pathname: currentPath } = history.location; // should be u/find-a-doctor or /find-a-doctor for unauthenticated

  if (!hasLength(params?.expandedFilters)) {
    search(params?.options, params?.recentSearch);
  }

  switch (from) {
    case 'Get Care':
      history.push(searchUrl, {
        initialQuery: query || params?.options.input,
        initialOptions: params?.options,
        expandedFilters: params?.expandedFilters
      });
      break;
    case 'Find Provider':
      history.replace(currentPath, {
        expandedFilters: params?.expandedFilters
      });
      break;
    default:
  }
}
