import React from 'react';

export default {
  svg: (
    <g>
      <g>
        <g>
          <circle fill="#BF00E6" strokeWidth={0} cx={40.118} cy={39.779} r={38.5} />
          <g>
            <path
              fill="#FFFFFF"
              strokeWidth={0}
              d="M40.119,2.279c20.711,0,37.5,16.789,37.5,37.5c0,20.711-16.789,37.5-37.5,37.5s-37.5-16.789-37.5-37.5 C2.619,19.068,19.408,2.279,40.119,2.279 M40.119,0.279c-21.78,0-39.5,17.72-39.5,39.5s17.72,39.5,39.5,39.5 s39.5-17.72,39.5-39.5S61.899,0.279,40.119,0.279L40.119,0.279z"
            />
          </g>
        </g>
        <g>
          <path
            fill="#BF00E6"
            strokeWidth={0}
            d="M39.881,2.721c-20.711,0-37.5,16.789-37.5,37.5c0,14.928,8.723,27.816,21.349,33.851 c3.598-2.822,8.225-2.99,11.802-3.033c0.217-0.052,0.428-0.113,0.653-0.154v-5.516c-3.748-2.692-6.933-7.553-6.933-11.592 c-1.51,0-2.734-1.202-2.734-2.685v-2.793c0-0.945,0.499-1.772,1.25-2.25l-1.286,0.224c-0.345-4.922-0.48-10.484,1.123-14.976 c-1.474-1.373-2.402-3.324-2.402-5.498c0-4.152,3.366-7.518,7.519-7.518c3.055,0,5.679,1.826,6.856,4.443 c0.607-0.047,1.232-0.079,1.894-0.079c15.526,0,16.747,13.785,15.526,24.166l-1.061-0.238c0.404,0.468,0.656,1.065,0.656,1.727 v2.793c0,1.483-1.224,2.685-2.734,2.685c0,1.262-0.311,2.605-0.853,3.936c-0.161,0.396-0.349,0.791-0.55,1.183 c-0.147,0.288-0.303,0.573-0.469,0.856c-0.216,0.368-0.441,0.733-0.685,1.089c-0.516,0.753-1.093,1.476-1.712,2.149 c-0.826,0.897-1.728,1.706-2.665,2.379v5.516l0,0c0.228,0.042,0.441,0.103,0.66,0.156c3.108,0.04,6.978,0.196,10.276,2.093 c11.631-6.368,19.519-18.718,19.519-32.913C77.381,19.51,60.592,2.721,39.881,2.721z"
          />
          <path
            fill="#F5D291"
            strokeWidth={0}
            d="M53.006,57.713c-0.161,0.396-0.349,0.791-0.55,1.183C52.657,58.503,52.845,58.109,53.006,57.713z"
          />
          <path
            fill="#F5D291"
            strokeWidth={0}
            d="M51.988,59.751c-0.216,0.368-0.441,0.733-0.685,1.089C51.547,60.484,51.772,60.119,51.988,59.751z"
          />
          <path
            fill="#F5D291"
            strokeWidth={0}
            d="M46.926,71.033v-0.148l0,0l0,0l-10.741-3.241v-2.275v5.516l0,0v5.299l0.014,1.292h7.921 c0.898-0.101,1.785-0.236,2.663-0.4l0.065-6.044L46.926,71.033z"
          />
          <path
            fill="#F5D291"
            strokeWidth={0}
            d="M41.556,63.393c-2.534-0.134-4.548-1.985-4.548-4.271h4.548v-1.64l-2.734-0.537 c0,0,0.74-4.427,2.734-8.593v-3.691h-3.95L36.8,38.754l-1.074,5.907l-7.957,1.387c-0.751,0.479-1.25,1.306-1.25,2.25v2.793 c0,1.483,1.224,2.685,2.734,2.685c0,4.039,3.185,8.9,6.933,11.592c1.756,1.262,3.636,2.049,5.371,2.049V63.393z M34.043,48.346 c-0.004-0.124,0.008-0.244,0.024-0.362c-0.007-0.059-0.022-0.115-0.024-0.175c-0.041-1.171,0.875-2.153,2.046-2.194 c1.17-0.041,2.153,0.875,2.193,2.046c0.004,0.124-0.007,0.244-0.024,0.362c0.007,0.059,0.022,0.115,0.024,0.175 c0.041,1.171-0.875,2.153-2.045,2.194C35.066,50.432,34.084,49.517,34.043,48.346z"
          />
          <path
            fill="#FFFFFF"
            strokeWidth={0}
            d="M36.2,77.539c1.211,0.118,2.439,0.182,3.681,0.182c1.434,0,2.847-0.088,4.239-0.245H36.2L36.2,77.539z"
          />
          <path
            fill="#8CC56E"
            strokeWidth={0}
            d="M36.186,76.184v-5.299l0,0c-0.225,0.042-0.436,0.102-0.653,0.154c-3.576,0.043-8.203,0.211-11.802,3.033 c3.841,1.836,8.042,3.036,12.469,3.468L36.2,77.476L36.186,76.184z"
          />
          <path
            fill="#8CC56E"
            strokeWidth={0}
            d="M46.926,70.885L46.926,70.885v0.148l-0.078-0.001l-0.065,6.044c3.944-0.734,7.673-2.078,11.079-3.943 c-3.298-1.897-7.168-2.053-10.276-2.093C47.367,70.988,47.154,70.927,46.926,70.885z"
          />
          <path
            opacity={0.61}
            fill="#F4BD65"
            strokeWidth={0}
            d="M46.926,65.369L46.926,65.369c0.937-0.673,1.839-1.482,2.665-2.379 C48.765,63.887,47.863,64.696,46.926,65.369z"
          />
          <path
            opacity={0.61}
            fill="#F4BD65"
            strokeWidth={0}
            d="M52.456,58.896c-0.147,0.287-0.303,0.573-0.469,0.856 C52.154,59.468,52.31,59.183,52.456,58.896z"
          />
          <path
            fill="#F5D291"
            strokeWidth={0}
            d="M46.926,70.885v-5.516v0c0.937-0.673,1.839-1.482,2.665-2.379c0.619-0.673,1.196-1.396,1.712-2.149 c0.244-0.357,0.47-0.721,0.685-1.089c0.166-0.283,0.322-0.568,0.469-0.856c0.2-0.392,0.388-0.786,0.55-1.183 c0.542-1.331,0.853-2.674,0.853-3.936c1.51,0,2.734-1.202,2.734-2.685v-2.793c0-0.661-0.253-1.259-0.656-1.727l-8.53-1.911 l-0.403-2.685l-1.074,2.685h-4.374v3.691c-1.995,4.166-2.734,8.593-2.734,8.593l2.734,0.537v1.64h5.118 c0,2.373-2.164,4.296-4.833,4.296c-0.098,0-0.189-0.02-0.285-0.026v4.025c-1.735,0-3.614-0.787-5.371-2.049v2.275L46.926,70.885z  M46.395,48.346c-0.004-0.124,0.007-0.244,0.024-0.362c-0.007-0.059-0.022-0.115-0.024-0.175 c-0.041-1.171,0.875-2.153,2.045-2.194c1.171-0.041,2.153,0.875,2.194,2.046c0.004,0.124-0.008,0.244-0.024,0.362 c0.007,0.059,0.022,0.115,0.024,0.175c0.041,1.171-0.875,2.153-2.046,2.194C47.418,50.432,46.435,49.517,46.395,48.346z"
          />
          <path
            opacity={0.61}
            fill="#F4BD65"
            strokeWidth={0}
            d="M46.926,70.885v-5.516v0c0.937-0.673,1.839-1.482,2.665-2.379 c0.619-0.673,1.196-1.396,1.712-2.149c0.244-0.357,0.47-0.721,0.685-1.089c0.166-0.283,0.322-0.568,0.469-0.856 c0.2-0.392,0.388-0.786,0.55-1.183c0.542-1.331,0.853-2.674,0.853-3.936c1.51,0,2.734-1.202,2.734-2.685v-2.793 c0-0.661-0.253-1.259-0.656-1.727l-8.53-1.911l-0.403-2.685l-1.074,2.685h-4.374v3.691c-1.995,4.166-2.734,8.593-2.734,8.593 l2.734,0.537v1.64h5.118c0,2.373-2.164,4.296-4.833,4.296c-0.098,0-0.189-0.02-0.285-0.026v4.025 c-1.735,0-3.614-0.787-5.371-2.049v2.275L46.926,70.885z M46.395,48.346c-0.004-0.124,0.007-0.244,0.024-0.362 c-0.007-0.059-0.022-0.115-0.024-0.175c-0.041-1.171,0.875-2.153,2.045-2.194c1.171-0.041,2.153,0.875,2.194,2.046 c0.004,0.124-0.008,0.244-0.024,0.362c0.007,0.059,0.022,0.115,0.024,0.175c0.041,1.171-0.875,2.153-2.046,2.194 C47.418,50.432,46.435,49.517,46.395,48.346z"
          />
          <path
            fill="#000000"
            strokeWidth={0}
            d="M36.8,38.754l0.806,5.907h3.95h4.374l1.074-2.685l0.403,2.685l8.53,1.911l1.061,0.238 c1.221-10.382,0-24.166-15.526-24.166c-0.662,0-1.286,0.033-1.894,0.079c-1.176-2.617-3.801-4.443-6.856-4.443 c-4.152,0-7.519,3.366-7.519,7.518c0,2.173,0.927,4.125,2.402,5.498c-1.603,4.492-1.467,10.055-1.123,14.976l1.286-0.224 l7.957-1.387L36.8,38.754z"
          />
          <path
            fill="#F5D291"
            strokeWidth={0}
            d="M38.282,48.198c-0.002-0.06-0.017-0.116-0.024-0.175c-0.137,1.004-0.975,1.795-2.022,1.831 c-1.111,0.039-2.042-0.787-2.17-1.871c-0.016,0.119-0.028,0.239-0.024,0.362c0.041,1.171,1.023,2.086,2.194,2.046 C37.407,50.351,38.323,49.368,38.282,48.198z"
          />
          <path
            fill="#F7CDB9"
            strokeWidth={0}
            d="M38.282,48.198c-0.002-0.06-0.017-0.116-0.024-0.175c-0.137,1.004-0.975,1.795-2.022,1.831 c-1.111,0.039-2.042-0.787-2.17-1.871c-0.016,0.119-0.028,0.239-0.024,0.362c0.041,1.171,1.023,2.086,2.194,2.046 C37.407,50.351,38.323,49.368,38.282,48.198z"
          />
          <path
            fill="#000000"
            strokeWidth={0}
            d="M36.236,49.854c1.047-0.036,1.885-0.827,2.022-1.831c0.016-0.119,0.028-0.239,0.024-0.362 c-0.041-1.171-1.023-2.086-2.193-2.046c-1.171,0.041-2.087,1.023-2.046,2.194c0.002,0.06,0.017,0.116,0.024,0.175 C34.195,49.068,35.126,49.893,36.236,49.854z M36.901,47.603c-0.293,0.01-0.538-0.219-0.549-0.511 c-0.01-0.293,0.219-0.538,0.511-0.548c0.292-0.01,0.538,0.219,0.548,0.511S37.193,47.593,36.901,47.603z"
          />
          <path
            fill="#FFFFFF"
            strokeWidth={0}
            d="M36.864,46.543c-0.293,0.01-0.522,0.256-0.511,0.548c0.01,0.293,0.256,0.522,0.549,0.511 c0.292-0.01,0.521-0.256,0.511-0.548S37.156,46.533,36.864,46.543z"
          />
          <path
            fill="#F5D291"
            strokeWidth={0}
            d="M48.588,50.392c1.171-0.041,2.087-1.023,2.046-2.194c-0.002-0.06-0.017-0.116-0.024-0.175 c-0.137,1.004-0.975,1.795-2.022,1.831c-1.11,0.039-2.041-0.787-2.17-1.871c-0.016,0.119-0.028,0.239-0.024,0.362 C46.435,49.517,47.418,50.432,48.588,50.392z"
          />
          <path
            opacity={0.61}
            fill="#F4BD65"
            strokeWidth={0}
            d="M48.588,50.392c1.171-0.041,2.087-1.023,2.046-2.194c-0.002-0.06-0.017-0.116-0.024-0.175 c-0.137,1.004-0.975,1.795-2.022,1.831c-1.11,0.039-2.041-0.787-2.17-1.871c-0.016,0.119-0.028,0.239-0.024,0.362 C46.435,49.517,47.418,50.432,48.588,50.392z"
          />
          <path
            fill="#F7CDB9"
            strokeWidth={0}
            d="M48.588,50.392c1.171-0.041,2.087-1.023,2.046-2.194c-0.002-0.06-0.017-0.116-0.024-0.175 c-0.137,1.004-0.975,1.795-2.022,1.831c-1.11,0.039-2.041-0.787-2.17-1.871c-0.016,0.119-0.028,0.239-0.024,0.362 C46.435,49.517,47.418,50.432,48.588,50.392z"
          />
          <path
            fill="#000000"
            strokeWidth={0}
            d="M48.588,49.854c1.047-0.036,1.885-0.827,2.022-1.831c0.016-0.119,0.028-0.239,0.024-0.362 c-0.041-1.171-1.023-2.086-2.194-2.046c-1.171,0.041-2.086,1.023-2.045,2.194c0.002,0.06,0.017,0.116,0.024,0.175 C46.547,49.068,47.478,49.893,48.588,49.854z M49.253,47.603c-0.292,0.01-0.538-0.219-0.548-0.511 c-0.01-0.293,0.219-0.538,0.511-0.548c0.293-0.01,0.538,0.219,0.548,0.511S49.545,47.593,49.253,47.603z"
          />
          <path
            fill="#FFFFFF"
            strokeWidth={0}
            d="M49.216,46.543c-0.293,0.01-0.522,0.256-0.511,0.548c0.01,0.293,0.256,0.522,0.548,0.511 c0.293-0.01,0.522-0.256,0.511-0.548S49.508,46.533,49.216,46.543z"
          />
          <path
            fill="#FCFCFC"
            strokeWidth={0}
            d="M41.556,63.393c0.096,0.005,0.187,0.026,0.285,0.026c2.669,0,4.833-1.924,4.833-4.296h-5.118h-4.548 C37.008,61.408,39.022,63.259,41.556,63.393z"
          />
        </g>
      </g>
    </g>
  ),
  viewBox: '0 0 80 80'
};
