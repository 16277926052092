import get from 'lodash/get';

import { phone } from './phone';
import { zip } from './zip';
import { cvv } from './cvv';
import { date } from './date';
import { creditCard } from './creditCard';
import { securityCode } from './securityCode';
import { ssn } from './ssn';
import { ssnFourDigits } from './ssnFourDigits';

export const masks = {
  phone,
  creditCard,
  zip,
  cvv,
  date,
  securityCode,
  ssn,
  ssnFourDigits
} as const;

function traverseMasks<T extends keyof typeof masks | string>(str: T) {
  const keys = str.split('.');
  if (keys.length === 1) {
    keys.push('default');
  }
  return get(masks, keys);
}

export default <T extends keyof typeof masks | string>(mask?: T) => {
  return mask ? traverseMasks(mask) : mask;
};
