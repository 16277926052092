import React from 'react';

import DataLoader from 'components/UI/DataLoader/DataLoader';
import { MuiBox } from 'theme/material-ui';

import * as bookingTypes from 'store/booking/types';

import UpcomingAppointmentCard from '../UpcomingAppointmentsCard';
import {
  AppointmentsLoading,
  BookingNoData,
  BookingError
} from '../../components/sharedComponents';

export interface Props {
  appointments: bookingTypes.BookedAppointment[];
  appointmentsLoading: boolean;
  appointmentsError: Error | null;
}

export const AppointmentsList = ({
  appointments,
  appointmentsLoading,
  appointmentsError
}: Props) => {
  return (
    <DataLoader
      data={appointments}
      loading={appointmentsLoading && !appointments.length}
      error={appointmentsError}
      renderLoading={() => <AppointmentsLoading testId="upcoming-appointments-loading" />}
      renderError={() => (
        <BookingError message="Uh oh! There was an issue fetching your upcoming appointments. Please refresh and try again. If the issue persists, please contact support." />
      )}
      renderNoData={() => (
        <BookingNoData message="You don't appear to have any upcoming appointments." />
      )}
      renderData={data => (
        <MuiBox>
          {data.map(appointment => (
            <UpcomingAppointmentCard
              upcomingAppointment={appointment}
              key={appointment.appointmentId}
            />
          ))}
        </MuiBox>
      )}
    />
  );
};

export default AppointmentsList;
