import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import EVisitsLeavingGuard from 'screens/EVisits/EVisitsLeavingGuard';
import TermsAndConditions from 'screens/TermsAndConditions/TermsAndConditions';
import { Confirm } from 'components/ConfirmDialog/ConfirmDialog';
import { StyledAsterisk } from 'components/RequiredFieldsLegend';
import {
  MuiBox,
  MuiContainer,
  MuiTypography,
  MuiButton,
  MuiPaper,
  MuiSelect,
  MuiMenuItem,
  MuiFormControl
} from 'theme/material-ui';
import { acceptEVisitTermsOfUse } from 'store/profile/actions';

import {
  eVisitStateLocationConstants,
  EVISIT_STATES,
  eVisitNotInUtahDialogConstants
} from 'lib/triage/constants';
import { Color } from 'modules/styles/colors';
import { Spacing, FontSize } from 'modules/styles/variables';
import { TermsAndConditionsType } from 'store/global/reducers';
import { startEVisitSession } from 'store/eVisit/actions';
import { eVisitSessionFetchingSelector, eVisitSessionErrorSelector } from 'store/eVisit/selectors';
import EVisitCheckboxItem from './FrontDoor/components/EVisitCheckboxItem';

export const NotInUtahButton = styled(MuiButton)`
  &.MuiButton-root {
    color: ${Color.link};
  }
`;

export const EVisitsStateLocation = () => {
  const [consentTreat, setConsentTreat] = useState(false);
  const [privacyPractices, setPrivacyPractices] = useState(false);
  const [termsOfUse, setTermsOfUse] = useState(false);
  const [stateSelected, setStateSelected] = useState('');
  const [termsType, setTermsType] = useState<TermsAndConditionsType | null>();
  const isFetching = useSelector(eVisitSessionFetchingSelector);
  const error = useSelector(eVisitSessionErrorSelector);

  const dispatch = useDispatch();

  const onClickNext = () => {
    dispatch(acceptEVisitTermsOfUse());
    dispatch(startEVisitSession(stateSelected));
  };

  useEffect(() => {
    if (error) {
      Confirm.show(
        'Unable to start E-Visit at this time',
        'Please try again or contact support.',
        '',
        'error'
      );
    }
  }, [error]);

  const showNotInAreaDialog = () => {
    Confirm.show(
      eVisitNotInUtahDialogConstants.dialogTitleText,
      eVisitNotInUtahDialogConstants.dialogContentText,
      '',
      'info',
      {
        text: 'Ok'
      }
    );
  };

  return (
    <MuiContainer>
      <TermsAndConditions
        declineAction={() => setTermsType(null)}
        open={!!termsType}
        type={termsType}
      />
      <EVisitsLeavingGuard />
      <MuiBox marginTop={Spacing.small} borderBottom={`1px solid ${Color.grayHue4}`}>
        <MuiTypography fontSize={FontSize.largeHeading}>
          {eVisitStateLocationConstants.title}
        </MuiTypography>
      </MuiBox>
      <MuiPaper>
        <MuiBox
          display="flex"
          flexDirection="column"
          alignItems="center"
          paddingY={2}
          marginY={Spacing.small}
        >
          <MuiBox display="flex" alignItems="center" paddingY={Spacing.small}>
            <MuiTypography>{eVisitStateLocationConstants.headerText}&nbsp;</MuiTypography>
            <MuiTypography
              color={Color.link}
              display="inline"
              onClick={() => showNotInAreaDialog()}
              style={{ cursor: 'pointer' }}
            >
              {eVisitStateLocationConstants.notInUtah}
            </MuiTypography>
          </MuiBox>
          <MuiBox maxWidth={320} paddingBottom={Spacing.small}>
            <MuiTypography>
              Select a State <StyledAsterisk />
            </MuiTypography>
            <MuiFormControl fullWidth variant="outlined">
              <MuiSelect
                onChange={e => setStateSelected(e.target.value as string)}
                data-testid="select-a-state"
                defaultValue=""
                displayEmpty
              >
                <MuiMenuItem value="" disabled>
                  Location at time of visit
                </MuiMenuItem>
                {EVISIT_STATES.map(({ label, value }) => {
                  return (
                    <MuiMenuItem key={value} value={value}>
                      {label}
                    </MuiMenuItem>
                  );
                })}
              </MuiSelect>
            </MuiFormControl>
            <MuiBox marginY={2} paddingX={2}>
              <EVisitCheckboxItem
                testID="consent"
                label={eVisitStateLocationConstants.consentCheckbox}
                linkText={eVisitStateLocationConstants.consentCheckboxLink}
                onChange={(e, checked: boolean) => setConsentTreat(checked)}
                onClick={() => setTermsType(TermsAndConditionsType.E_VISIT_CONSENT_TO_TREAT)}
              />
            </MuiBox>
            <MuiBox marginY={2} paddingX={2}>
              <EVisitCheckboxItem
                testID="privacy"
                label={eVisitStateLocationConstants.noticeCheckbox}
                linkText={eVisitStateLocationConstants.noticeCheckboxLink}
                onChange={(e, checked: boolean) => setPrivacyPractices(checked)}
                onClick={() => setTermsType(TermsAndConditionsType.E_VISIT_PRIVACY_NOTICE)}
              />
            </MuiBox>
            <MuiBox marginBottom={Spacing.small} paddingX={2}>
              <EVisitCheckboxItem
                testID="terms"
                label={eVisitStateLocationConstants.termsOfUseCheckbox}
                linkText={eVisitStateLocationConstants.termsOfUseCheckboxLink}
                onChange={(e, checked: boolean) => setTermsOfUse(checked)}
                onClick={() => setTermsType(TermsAndConditionsType.E_VISIT_TERMS_OF_USE)}
              />
            </MuiBox>
            <MuiButton
              fullWidth
              data-testid="next-button"
              variant="contained"
              color="primary"
              disabled={!consentTreat || !privacyPractices || !termsOfUse || !stateSelected}
              onClick={onClickNext}
              loading={isFetching}
            >
              Next
            </MuiButton>
          </MuiBox>
        </MuiBox>
      </MuiPaper>
    </MuiContainer>
  );
};

export default EVisitsStateLocation;
