import React from 'react';
import { Color } from 'modules/styles/colors';

export default ({ color = Color.black }) => ({
  svg: (
    <g fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.8637 0.113702C5.21517 -0.23777 5.78502 -0.23777 6.13649 0.113702L16.856 10.8332C16.8562 10.8334 16.8563 10.8336 16.8565 10.8337C17.0096 10.9868 17.1312 11.1685 17.2141 11.3685C17.2971 11.5687 17.3399 11.7834 17.3399 12.0001C17.3399 12.2168 17.2971 12.4315 17.2141 12.6317C17.1312 12.8317 17.0096 13.0134 16.8565 13.1665C16.8563 13.1666 16.8562 13.1668 16.856 13.1669L6.13649 23.8865C5.78502 24.238 5.21517 24.238 4.8637 23.8865C4.51223 23.535 4.51223 22.9652 4.8637 22.6137L15.4773 12.0001L4.8637 1.38649C4.51223 1.03502 4.51223 0.465173 4.8637 0.113702ZM15.5842 12.1069C15.584 12.1068 15.5839 12.1066 15.5837 12.1065L15.5842 12.1069ZM15.5837 11.8937C15.5839 11.8935 15.584 11.8934 15.5842 11.8932L15.5837 11.8937Z"
        fill={color}
      />
    </g>
  ),
  viewBox: '0 0 24 24'
});
