import styled from 'styled-components';

import { Color } from 'modules/styles/colors';
import { FontSize, Spacing, ZIndex } from 'modules/styles/variables';
import { MuiListItem, MuiTypography } from 'theme/material-ui';

export const StyledMuiBoxContainer = styled(MuiListItem)`
  height: 4em;
  display: flex;
  justify-content: space-between;

  &:not(:last-child) {
    border-bottom: 1px solid ${Color.grayLight};
  }
`;

export const IconsContainer = styled.div`
  margin-right: ${Spacing.xLarge}px;
  padding: ${Spacing.large}px;
  position: relative;
`;

export const AvatarContainer = styled.div`
  position: absolute;
  z-index: ${ZIndex.base};
  top: -5px;
  left: 0;
`;

export const SelectHealthIconContainer = styled.div`
  position: absolute;
  z-index: ${ZIndex.low};
  right: -5px;
  bottom: 1px;
  width: ${Spacing.large}px;
  height: ${Spacing.large}px;
  border-radius: 50%;
  background-color: ${Color.translucent};
  padding: 2px;
`;

export const StyledProviderName = styled(MuiTypography)`
  flex: 0 0 35%;
  &.MuiTypography-root {
    font-weight: bold;
  }
`;

export const StyledLocationName = styled(MuiTypography)`
  flex: 0 0 40%;
  &.MuiTypography-root {
    font-size: ${FontSize.small}px;
  }
`;
