import React, { useRef, useEffect } from 'react';
import { useTransition } from 'react-spring';
import { useQueue } from 'lib/hooks/useQueue';
import Snack, { SnackProps, snackBus } from './Snack';
import { SnackContainer, SnacksContainer } from './styled';

function Snacks() {
  const snackId = useRef(1);
  const queue = useQueue<SnackProps>([]);

  const transitions = useTransition(queue.list, s => s.id, {
    from: { opacity: 0, transform: 'translate3d(100%,0,0)' },
    enter: { opacity: 1, transform: 'translate3d(0,0,0)' },
    leave: { opacity: 0, transform: 'translate3d(100%,0,0)' },
    config: { tension: 200, friction: 25, precision: 0.1 }
  });

  useEffect(() => {
    const unsubscribe = snackBus.on('create', ({ options, ...rest }) => {
      const snack: SnackProps = {
        id: snackId.current,
        options: {
          timer: 10000,
          ...options
        },
        ...rest
      };

      queue.add(snack);
      snackId.current += 1;
    });

    return () => {
      unsubscribe();
    };
  }, [snackBus]);

  return (
    <SnacksContainer>
      {transitions.map(({ key, item, props }) => (
        <SnackContainer key={key} style={props} mb={2}>
          <Snack {...item} onClose={() => queue.remove(item)} />
        </SnackContainer>
      ))}
    </SnacksContainer>
  );
}

export default Snacks;
