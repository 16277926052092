import React from 'react';
import { MuiGrid, MuiTypography, MuiCard, MuiCardContent, MuiBox } from 'theme/material-ui';
import styled from 'styled-components';
import { Pharmacy } from 'store/amwell/types';
import { Color } from 'modules/styles/colors';

export interface ConnectCarePharmacyItemProps {
  data: Pharmacy;
  changePreferred?: (selected: Pharmacy) => void;
  isSelected?: boolean;
}

const Clickable = styled(MuiTypography)`
  cursor: pointer;
  display: inline;
`;

const ConnectCarePharmacyItem = (props: ConnectCarePharmacyItemProps) => {
  const { isSelected, changePreferred, data } = props;
  const { name, formattedPhone, address } = data;
  /* eslint-disable-next-line */
  const distance = data.__data?.distance;
  return (
    <MuiCard data-testid="CC-Phamacy-Item">
      <MuiCardContent>
        <MuiGrid container direction="column">
          <MuiTypography variant="h5">{name}</MuiTypography>
          <MuiGrid container direction="row" spacing={1}>
            <MuiGrid item>
              <MuiTypography>{address.address1},</MuiTypography>
            </MuiGrid>
            <MuiGrid item>
              <MuiTypography>{address.city},</MuiTypography>
            </MuiGrid>
            <MuiGrid item>
              <MuiTypography>{address.stateCode}</MuiTypography>
            </MuiGrid>
            <MuiGrid item>
              <MuiTypography>{address.zipCode}</MuiTypography>
            </MuiGrid>
            <MuiGrid item>
              <MuiTypography>{formattedPhone}</MuiTypography>
            </MuiGrid>
          </MuiGrid>
          {distance ? (
            <MuiTypography>{Math.round(distance * 100) / 100} Miles</MuiTypography>
          ) : null}
        </MuiGrid>
        {!isSelected && changePreferred ? (
          <MuiBox>
            <Clickable color={Color.blueHue2} onClick={() => changePreferred(props.data)}>
              Select Pharmacy
            </Clickable>
          </MuiBox>
        ) : null}
      </MuiCardContent>
    </MuiCard>
  );
};
export default ConnectCarePharmacyItem;
