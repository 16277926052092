import styled, { css } from 'styled-components';
import { Card, FormControl, Input, InputLabel } from '@material-ui/core';
import Screen from 'components/UI/Layout/Screen';
import {
  CursorMuiBox,
  dfdDefaultTheme,
  MuiBackdrop,
  MuiBox,
  MuiButton,
  MuiCard,
  MuiCheckbox,
  MuiDialog,
  MuiPaper,
  MuiRadio,
  MuiTextField
} from 'theme/material-ui';

import { Color } from 'modules/styles/colors';
import { Spacing, ZIndex } from 'modules/styles/variables';

export const BookingBackdrop = styled(MuiBackdrop)`
  && {
    z-index: ${ZIndex.max};
  }
`;

export const StyledScreen = styled(Screen)`
  height: 100%;
`;

export const BookingPanel = styled(MuiPaper)<{
  backgroundColor?: string;
  tabindex?: string;
  selected?: boolean;
  hoverDisabled?: boolean;
}>`
  cursor: ${props => (!props.hoverDisabled ? 'pointer' : 'default')};
  padding: 16px;
  margin-bottom: 15px;

  &.MuiPaper-root {
    background-color: ${props => props.backgroundColor ?? 'none'};
  }

  &:hover,
  &:focus {
    background-color: ${props => (!props.hoverDisabled ? Color.cobaltTint : 'none')};

    .MuiTypography-root {
      color: ${props => (!props.hoverDisabled ? Color.textDark : 'none')};
    }
  }
`;

export const BookingPanelListItem = styled(BookingPanel)`
  margin-bottom: 0;
  border-bottom: 1px solid ${Color.grayHue7};
  padding: 16px 28px;
  &:last-child {
    border-bottom: 0 none;
  }
`;

export const StyledMuiButton = styled(MuiButton)`
  && {
    &.MuiButton-root {
      border: none;
    }
    &.MuiButton-outlined {
      background-color: #dbdce0;
      color: ${Color.black};
    }
    &.MuiButton-contained {
      background-color: ${Color.primary};
      color: ${Color.white};
    }
  }
`;

export const SelectHealthIconWrapper = styled.div<{ align?: string }>`
  position: absolute;
  bottom: 0;
  ${({ align }: any) => align || 'left'}: 0;
  height: 16px;
  width: 16px;
  transform: translate(-25%, 25%);
`;

export const StyledUL = styled.ul`
  &&& {
    margin-left: ${Spacing.large}px;
  }
`;

export const StyledLIDisc = styled.li`
  &&& {
    list-style-type: disc;
  }
`;

export const StyledLIDecimal = styled.li`
  &&& {
    list-style-type: decimal;
  }
`;
// BookingSpecialtyOrMyDoctorSelect styles
export const OrBox = styled.div`
  background-color: ${Color.foreColor};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40px;
`;

// BookingDateAndTimeSelect styles
export const BookingLocationCard = styled(MuiCard)`
  &:not(:last-child) {
    margin-bottom: ${dfdDefaultTheme.spacing(1)}px;
  }

  & MuiCard-root {
    background-color: ${Color.foreColor};
  }
`;

// BookingReviewAppointmentDetails styles
export const StyledCard = styled(MuiCard)`
  padding: 20px 30px;
  max-width: 552px;
  margin: 0 auto;

  &.MuiCard-root {
    background-color: ${Color.foreColor};
  }
`;

export const FieldDropdown = styled(Input)`
  background-color: ${Color.foreColor};
  border-radius: 3px;
  width: 100%;
  height: 3rem;
  border-style: solid;
  border-color: darkgrey;
  border-width: thin;
  margin-bottom: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
`;

export const PersonalInfoFormControl = styled(FormControl)`
  background-color: transparent;
  border-radius: 3px;
  width: 100%;
`;

// BookingSuccess styles
export const MuiBorderBox = styled(MuiBox)`
  border: 1px solid ${Color.black};
  padding: 40px;
`;

export const BookingSuccessCardWrapper = styled(Card)`
  padding: 20px 30px;
  max-width: 402px;
  height: 250px;
  margin: 0 auto;
`;

export const BookingSuccessCard = styled(Card)`
  padding: 20px 100px;
  max-width: 800px;
  margin: 0 auto;
`;

// BookingSpecialtyProviderSelect styles
export const ActionHeaderButton = styled(MuiButton)<{ hasCount?: boolean }>`
  &.MuiButton-root {
    background-color: ${Color.white};
  }
  ${props =>
    props.hasCount &&
    css`
      && {
        &.MuiButton-outlined {
          border: 2px solid ${Color.secondary};
        }
      }
    `}
`;

export const StyledMuiRadio = styled(MuiRadio)<{ checked?: boolean }>`
  ${props =>
    props.checked &&
    css`
      && {
        &.MuiRadio-root {
          color: ${Color.secondary};
        }
      }
    `}
`;

export const StyledMuiCheckbox = styled(MuiCheckbox)<{ checked?: boolean }>`
  ${props =>
    props.checked &&
    css`
      && {
        &.MuiCheckbox-root {
          color: ${Color.secondary};
        }
      }
    `}
`;

// ManageAppointment styles
const selectStyles = css`
  border-color: ${Color.white};
  color: ${Color.white};
  fill: ${Color.white};

  &:hover,
  &:focus {
    border-color: ${Color.white};
    color: ${Color.white};
    fill: ${Color.white};

    .MuiOutlinedInput-notchedOutline {
      border-color: ${Color.white};
    }
  }
`;

export const Select = styled(MuiTextField)`
  &.MuiTextField-root {
    min-width: 150px;
  }
`;

export const BannerSelect = styled(Select)`
  .MuiSelect-root,
  .MuiInputLabel-root,
  .MuiOutlinedInput-root,
  .MuiOutlinedInput-notchedOutline,
  .MuiSelect-icon {
    ${selectStyles}
  }
`;

// AppointmentCard styles
export const AppointmentCardWrapper = styled(MuiBox)`
  &:not(:last-child) {
    margin-bottom: ${dfdDefaultTheme.spacing(2)}px;
  }
`;

export const AppointmentCardActionsWrapper = styled.div`
  display: flex;
  padding-bottom: ${Spacing.large}px;
  align-items: stretch;
  justify-content: center;
  ${dfdDefaultTheme.breakpoints.up('lg')} {
    justify-content: flex-end;
  }
  ${dfdDefaultTheme.breakpoints.down('xs')} {
    flex-wrap: wrap;
  }
`;

// Dialog styles
export const CloseButton = styled(CursorMuiBox)`
  position: absolute;
  top: ${Spacing.large}px;
  right: ${Spacing.large}px;
  z-index: ${ZIndex.high};
`;

export const SpecialtyUl = styled.ul`
  &&& {
    margin-left: ${Spacing.xLarge}px;
  }
`;

export const StyledLICircle = styled.li`
  &&& {
    list-style-type: circle;
  }
`;

export const SpecialtyLICircle = styled.li`
  &&& {
    list-style-type: circle;
    margin-bottom: ${Spacing.small}px;
  }
`;

export const StyledLINormal = styled.li`
  &&& {
    list-style: disc;
  }
`;

export const BookNewAppointmentButton = styled(MuiButton)`
  &.MuiButton-contained {
    min-height: 45px;
  }
`;

export const CalendarIconContainer = styled(MuiBox)`
  width: 55px;
  height: 55px;
  background-color: ${Color.accentTint};
  border-radius: 4px;
  overflow: hidden;
`;

export const CalendarMonth = styled(MuiBox)`
  background-color: ${Color.accent};
`;

export const CustomList = styled.ul`
  li {
    list-style: disc;
    margin: 5px 15px;
  }
`;

export const StyledMuiDialog = styled(MuiDialog)`
  & .MuiPaper-root {
    background-color: ${Color.foreColor};
  }
`;

export const PersonalInfoSection = styled(MuiBox)`
  display: flex;
  flex-direction: row;
`;

export const PersonalInfoInputLabel = styled(InputLabel)`
  margin: 1rem 0 0.5rem 0;
`;
