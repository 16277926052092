import Typography from '@material-ui/core/Typography';
import React, { useEffect } from 'react';
import analyticsService, { AnalyticsEvent, AmplitudeEventData } from 'services/AnalyticsService';
import LinkingServices from 'services/LinkingServices';
import Box from 'components/UI/Layout/Box';
import { MuiBox, MuiButton } from 'theme/material-ui';
import Spacer from 'components/UI/Layout/Spacer';
import { connect } from 'react-redux';
import { RegisterErrorInfoTooltip } from 'components/InfoPopper';
import { Spacing } from 'modules/styles/variables';
import { updateRegistrationStep, getRegisterBrand, getRedirectIdx } from 'store/register';
import { Step } from 'store/pageActions/RegisterAccount/constants';
import styled from 'styled-components';
import { handleUrlOnForgetUserName } from 'store/pageActions/RegisterAccount';
import { useHistory } from 'react-router';
import CustomButton from '../FormElements/CustomButton';
import useStyles from '../useStyles';

export const MagnifyingBoxWrapper = styled(Box)`
  opacity: 0.72;
`;

export interface Props {
  updateStep: (step: string) => void;
  handleUrlOnForgetUserName: (history: H.History) => void;
}

const RegisterError = ({ updateStep, handleUrlOnForgetUserName }: Props) => {
  const redirectBrand = getRegisterBrand();
  const redirect_index = getRedirectIdx();

  const classes = useStyles({ redirectBrand });
  const history = useHistory();

  const eventData: AmplitudeEventData = {
    redirect_index,
    redirect_brand: redirectBrand
  };

  useEffect(() => {
    analyticsService.logEvent(AnalyticsEvent.AccountCreationValidationFailed, eventData);
  }, []);

  const handleReviewPersonalInfo = () => {
    updateStep(Step.PERSONAL_REVIEW);
  };

  const handleForgetUsername = () => handleUrlOnForgetUserName(history);

  return (
    <>
      <Box mb={1}>
        <Typography variant="h6" align="left">
          We're sorry, we weren’t able to validate your information.
          <RegisterErrorInfoTooltip
            handleReviewPersonalInfo={handleReviewPersonalInfo}
            handleForgetUsername={handleForgetUsername}
          />
        </Typography>
        <Box vSpacingTop={Spacing.large} vSpacingBottom={Spacing.xLarge}>
          <Typography variant="h6" align="left">
            Please review your information one more time and call online services if everything is
            correct.
          </Typography>
        </Box>
      </Box>
      <MuiBox mb={3} textAlign="center">
        <CustomButton
          label="Review my information again"
          aria-label="review-button"
          onClick={() => {
            updateStep(Step.PERSONAL_REVIEW);
          }}
        />

        <Spacer size="small" />
        <MuiButton
          className={classes.customTextButton}
          variant="text"
          fullWidth
          onClick={() => LinkingServices.callPhoneNumber('18004425502')}
        >
          Call Online Services (800) 442-5502
        </MuiButton>
      </MuiBox>
    </>
  );
};
const mapDispatchToProps = {
  updateStep: updateRegistrationStep,
  handleUrlOnForgetUserName
};

export default connect(null, mapDispatchToProps)(RegisterError);
