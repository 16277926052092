import React from 'react';
import dayjs from 'dayjs';
import { connect } from 'react-redux';

import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';

import { RootState } from 'store/types';
import { faHouseholdSelector } from 'store/billing/financialAssistance/createApplication/selectors';

import { HOUSEHOLD_INFO, HOUSEHOLD_MEMBERS_INFO } from '../constants';
import { Section } from './types';
import { convertToLowerKabobCase } from 'modules/utils/StringUtils';
import { MuiButton } from 'theme/material-ui';

const Household = ({ navigateToEdit, formData }: Section) => {
  const parseValues = (field: string, value: string) => {
    switch (field) {
      case 'maritalStatus': {
        if (value === 'S') {
          return 'Single';
        }
        return 'Married';
      }
      case 'spouseBirthDate':
      case 'birthDate': {
        return dayjs(value).format('MM/DD/YYYY');
      }
      default:
        return value;
    }
  };

  return (
    <Box>
      <Box position="absolute" top="-10px" right="-7px" zIndex={1}>
        <MuiButton color="primary" onClick={navigateToEdit} startIcon={<EditIcon />}>
          Edit
        </MuiButton>
      </Box>
      <Box>
        <Typography variant="h6">Spouse</Typography>
      </Box>
      {HOUSEHOLD_INFO.map(item => {
        const fieldValue = formData[item.field];
        if (fieldValue) {
          return (
            <Box py={1} key={item.field}>
              <Typography>{item.label}</Typography>
              <Typography data-testid={convertToLowerKabobCase(item.label, '-text')}>
                <b>
                  {Array.isArray(fieldValue)
                    ? fieldValue.length
                    : parseValues(item.field, fieldValue)}
                </b>
              </Typography>
            </Box>
          );
        }
        return null;
      })}
      <Box py={3}>
        <Divider />
      </Box>
      <Box>
        <Typography variant="h6">Members</Typography>
      </Box>
      {Array.isArray(formData.members) &&
        formData.members.map((member: { [x: string]: string }) => {
          return HOUSEHOLD_MEMBERS_INFO.map(subItem => {
            const fieldValue = member[subItem.field];
            if (fieldValue) {
              return (
                <React.Fragment key={`${subItem.field}${fieldValue}`}>
                  <Box py={1} key={`${subItem.field}${fieldValue}`}>
                    <Typography>{subItem.label}</Typography>
                    <Typography data-testid={convertToLowerKabobCase(subItem.label, '-text')}>
                      <b>{parseValues(subItem.field, fieldValue)}</b>
                    </Typography>
                  </Box>
                  {/* {subItem.field === 'relationship' && index !== formData.members.length - 1 && (
                    <ArrayItemSeparator />
                  )} */}
                </React.Fragment>
              );
            }
            return null;
          });
        })}
    </Box>
  );
};

const mapStateToProps = (state: RootState) => ({
  formData: faHouseholdSelector(state)
});
export default connect(mapStateToProps)(Household);
