import React from 'react';
import { Gradient } from './styled';

interface Props {
  children: React.ReactNode;
}

const GradientView = ({ children, ...rest }: Props) => <Gradient {...rest}>{children}</Gradient>;

export default GradientView;
