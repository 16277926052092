import React from 'react';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import {
  MuiDialog,
  MuiDialogTitle,
  MuiDialogActions,
  MuiDialogContentText,
  MuiBox,
  MuiGrid,
  MuiCard,
  MuiCardContent,
  MuiTypography,
  MuiAvatar,
  MuiBadge,
  MuiIcon,
  MuiDialogContent
} from 'theme/material-ui';
import styled from 'styled-components';
import Logger from 'services/Logger';

import { Color } from 'modules/styles/colors';
import { waitingRoom as waitingRoomConfirmations } from 'modules/constants/amwell';
import { ConnectCareButton } from 'components/ConnectCare';
import { useSnack } from 'components/Snack';
import {
  startVisit,
  setActivityStatus,
  handleTransfer,
  acceptSuggestedTransfer,
  declineSuggestedTransfer
} from 'store/amwell/actions';
import { Visit, ActivityStatus } from 'store/amwell/types';
import { RootDispatch } from 'store/types';
import Svg from 'components/UI/Svg/Svg';
import { awData } from '../utils';

const StyledSvg = styled(Svg)`
  margin-bottom: 1px;
`;

const StyledBadge = styled(MuiBadge)`
  && {
    & .MuiBadge-badge {
      background-color: ${Color.purple};
      color: ${Color.purple};
      position: absolute;
      top: -15px;
      left: 95px;
      width: 55px;
      height: 25px;
      border-radius: 12.5px;
    }
  }
`;

export interface Props {
  dispatch: RootDispatch;
  transfering: boolean;
  setTransfering: (transfering: boolean) => void;
  onVisitCancel: (visit?: Visit | null) => void;
  visit: Visit | null;
  open: boolean;
  onClose: () => void;
  onVisitTransfer: (visit: Visit, isQuickTransfer: boolean) => void;
}

export function ConnectCareWaitingRoomTransferDialog(props: Props) {
  const {
    onVisitCancel,
    onVisitTransfer,
    visit,
    dispatch,
    transfering,
    setTransfering,
    onClose,
    open
  } = props;

  const { create } = useSnack();
  const provider = visit?.suggestedProviderForTransfer || visit?.providerForTransfer;
  const isSuggestedTransfer = visit?.suggestedProviderForTransfer;
  const isManualTransfer = !!awData(visit)?.providerForManualTransfer;

  const isEligibleForQuickTransfer =
    visit?.isProviderForTransferEligibleForQuickTransfer ||
    visit?.isSuggestedProviderEligibleForQuickTransfer;

  const doTransfer = () => {
    setTransfering(true);
    if (isSuggestedTransfer) {
      dispatch(acceptSuggestedTransfer({ visit })).then((res: AnyAction) => {
        if (res.error) {
          create(
            waitingRoomConfirmations.TRANSFER_WARNING.subtitle,
            waitingRoomConfirmations.TRANSFER_WARNING.severity
          );
          setTransfering(false);
        }
      });
    } else {
      dispatch(handleTransfer({ visit })).then((res: AnyAction) => {
        if (res.error) {
          Logger.warn('onVisitTransfer handleTransfer error', JSON.stringify(res.error));
          create(
            waitingRoomConfirmations.TRANSFER_ERROR.subtitle,
            waitingRoomConfirmations.TRANSFER_ERROR.severity
          );
          setTransfering(false);
        } else {
          onVisitTransfer(res.payload.visit, res.payload.isQuickTransfer);
          onClose();
        }
      });
    }
  };

  const declineTransfer = (dontAskAgain: boolean) => {
    setTransfering(true);
    dispatch(declineSuggestedTransfer({ dontAskAgain })).then((res: AnyAction) => {
      if (res.error) {
        create(
          waitingRoomConfirmations.TRANSFER_ERROR.subtitle,
          waitingRoomConfirmations.TRANSFER_ERROR.severity
        );
        setTransfering(false);
      } else {
        onClose();
      }
    });
  };

  const cancelTransfer = () => {
    setTransfering(true);
    dispatch(handleTransfer({ visit })).then((res: AnyAction) => {
      if (res.error) {
        create(
          waitingRoomConfirmations.TRANSFER_ERROR.subtitle,
          waitingRoomConfirmations.TRANSFER_ERROR.severity
        );
        setTransfering(false);
      } else if (res.payload.isQuickTransfer) {
        dispatch(startVisit({ visit: res.payload.visit })).then((response: AnyAction) => {
          onVisitCancel(response.payload);
          onClose();
        });
      } else {
        dispatch(setActivityStatus(ActivityStatus.END_VISIT));
      }
    });
  };

  return (
    <MuiDialog
      open={open}
      disableBackdropClick
      maxWidth="md"
      aria-labelledby="add-service-key-form"
    >
      <MuiDialogTitle id="wrt-title">
        {isSuggestedTransfer ? 'Suggested Transfer' : 'Transfering Providers'}
      </MuiDialogTitle>
      <MuiDialogContent>
        <MuiGrid container direction="row" justify="center" spacing={4}>
          <MuiGrid item>
            <MuiDialogContentText id="wrt-text">
              {isSuggestedTransfer
                ? 'A provider is available with possibly a shorter wait time.'
                : 'You have been transferred to another Provider.'}
            </MuiDialogContentText>
          </MuiGrid>
          <MuiGrid item>
            <MuiBox width={500}>
              <MuiCard>
                <MuiCardContent>
                  <MuiGrid container spacing={1}>
                    <MuiGrid item container direction="row" alignItems="flex-end" spacing={5}>
                      <MuiGrid item>
                        <StyledBadge
                          overlap="circle"
                          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                          badgeContent={
                            <MuiGrid
                              container
                              item
                              justify="space-evenly"
                              direction="row"
                              alignItems="center"
                            >
                              <MuiTypography color={Color.white} fontSize={18}>
                                {provider?.rating}
                              </MuiTypography>
                              <MuiIcon>
                                <StyledSvg color={Color.white} name="star" set="material" />
                              </MuiIcon>
                            </MuiGrid>
                          }
                        >
                          <MuiAvatar size={90} alt={provider?.fullName} src={provider?.logoUrl} />
                        </StyledBadge>
                      </MuiGrid>
                      <MuiGrid item>
                        <MuiBox pb={2}>
                          <MuiTypography variant="h4">{provider?.fullName}</MuiTypography>
                        </MuiBox>
                        <MuiBox>
                          <MuiTypography color="textSecondary" variant="h5">
                            {provider?.specialty?.value}
                          </MuiTypography>
                        </MuiBox>
                      </MuiGrid>
                    </MuiGrid>
                  </MuiGrid>
                </MuiCardContent>
              </MuiCard>
            </MuiBox>
          </MuiGrid>
          <MuiGrid item>
            {isEligibleForQuickTransfer ? (
              <MuiDialogContentText id="wrt-text">
                If you accept a new video call will start, please accept when prompted.
              </MuiDialogContentText>
            ) : (
              <MuiDialogContentText id="wrt-text">
                {isManualTransfer ? 'You' : 'If you accept you'} will be returned to the triage
                screen,
                <br /> please complete registration from there to complete transfer.
              </MuiDialogContentText>
            )}
          </MuiGrid>
        </MuiGrid>
      </MuiDialogContent>
      {isSuggestedTransfer ? (
        <MuiDialogActions>
          <ConnectCareButton
            disabled={transfering}
            variant="outlined"
            onClick={() => declineTransfer(false)}
          >
            Decline Transfer
          </ConnectCareButton>
          <ConnectCareButton disabled={transfering} onClick={doTransfer} data-testid="submit">
            Accept Transfer
          </ConnectCareButton>
          <ConnectCareButton
            disabled={transfering}
            variant="outlined"
            onClick={() => declineTransfer(true)}
            data-testid="dontAsk"
          >
            Don't Ask Again
          </ConnectCareButton>
        </MuiDialogActions>
      ) : (
        <MuiDialogActions>
          {isManualTransfer ? null : (
            <ConnectCareButton disabled={transfering} variant="outlined" onClick={cancelTransfer}>
              Cancel Visit
            </ConnectCareButton>
          )}
          <ConnectCareButton disabled={transfering} onClick={doTransfer} data-testid="submit">
            {isManualTransfer ? 'Continue' : 'Accept Transfer'}
          </ConnectCareButton>
        </MuiDialogActions>
      )}
    </MuiDialog>
  );
}

export default connect()(ConnectCareWaitingRoomTransferDialog);
