import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Image } from 'react-native';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { ReduxAxiosAction } from 'redux-axios-middleware';

import { MuiBox, MuiContainer, MuiDivider, MuiTypography } from 'theme/material-ui';
import InputAdornment from '@material-ui/core/InputAdornment';

import { SpinnerOverlay } from 'components/UI/Spinner/SpinnerModal';
import { Modal } from 'components/CustomModal';

import { BOOKING_TIMEOUT, getInitPhone, TimerContext } from 'lib/booking/utils';

import { Color } from 'modules/styles/colors';
import { review } from 'modules/constants/booking';
import { maskPhone } from 'modules/utils/MaskUtils';
import { FontSize } from 'modules/styles/variables';
import { convertToLowerKabobCase } from 'modules/utils/StringUtils';
import { bookingAppointmentCallEventProps } from 'modules/utils/AnalyticsUtils';
import { listItemDecimal } from '../components/sharedComponents';

import useNavigationAnalytics from 'hooks/useNavigationAnalytics';
import analyticsService, { AmplitudeEventData, AnalyticsEvent } from 'services/AnalyticsService';

import errorPlug from 'assets/errorPlug.png';

import { RootState } from 'store/types';
import { Profile, Phone } from 'store/profile/types';
import * as bookingTypes from 'store/booking/types';
import * as bookingActions from 'store/booking/actions';
import * as bookingSelectors from 'store/booking/selectors';
import * as profileSelectors from 'store/profile/selectors';
import * as routerSelectors from 'store/router/selectors';

import { getUSPhoneNumber } from 'modules/utils/PhoneUtils';
import LinkingServices from 'services/LinkingServices';
import { useCheckBookingInfoEffect } from '../useCheckBookingInfoEffect';
import { AppointmentDetailsGrid } from '../components/AppointmentDetailsGrid';
import { BookingStepActions, BookingStepAction } from '../components/BookingStepper';
import BookingTimer from '../components/BookingTimer/BookingTimer';
import {
  StyledCard,
  FieldDropdown,
  PersonalInfoFormControl,
  StyledScreen,
  CustomList,
  StyledUL,
  PersonalInfoInputLabel,
  PersonalInfoSection
} from '../components/styled';
import FlexBox from 'components/UI/Layout/FlexBox';
import { Svg } from 'components/UI/Svg';

export interface Props extends RouteComponentProps {
  appointmentDetails: bookingTypes.AppointmentDetails;
  confirmAndBookAppointment: typeof bookingActions.confirmAndBookAppointment;
  rescheduleAppointment: typeof bookingActions.rescheduleAppointment;
  getUpcomingAppointments: typeof bookingActions.getUpcomingAppointments;
  setReasonForVisit: typeof bookingActions.setReasonForVisit;
  clearLocationTimeSlots: typeof bookingActions.clearLocationTimeSlots;
  unlockTimeSlot: typeof bookingActions.unlockTimeSlot;
  sendVideoConsent: typeof bookingActions.sendVideoConsent;
  isReschedule: boolean;
  profile: Profile;
  profilePhones: Phone[];
  visitType: bookingTypes.VisitType;
  currentRouteName?: string;
  previousRouteName?: string;
}

export function BookingReviewAppointmentDetails({
  appointmentDetails,
  confirmAndBookAppointment,
  rescheduleAppointment,
  getUpcomingAppointments,
  setReasonForVisit,
  unlockTimeSlot,
  sendVideoConsent,
  isReschedule,
  profile,
  profilePhones,
  history,
  currentRouteName,
  previousRouteName,
  clearLocationTimeSlots
}: Props) {
  const { lockedSlot } = history?.location?.state ?? {};
  const [, setTimerContext] = useContext(TimerContext);
  useCheckBookingInfoEffect(appointmentDetails?.patient);

  const bookAppointmentErrorCallForCalendarOptions = useNavigationAnalytics(
    AnalyticsEvent.BookAppointmentErrorCallForCalendarOptions
  );
  const videoErrorAnalytics = useNavigationAnalytics(AnalyticsEvent.VideoVisitConsentCallError);

  useEffect(() => {
    const eventData: AmplitudeEventData = {
      currentUrl: currentRouteName,
      referringUrl: previousRouteName
    };

    analyticsService.logEvent(AnalyticsEvent.BookAppointmentConfirmationViewed, eventData);
  }, []);

  const initPhone = useMemo(() => {
    return profilePhones ? getInitPhone(profilePhones) : null;
  }, [profilePhones]);

  const [isBookingAppointment, setIsBookingAppointment] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(initPhone);

  const isDisabled = appointmentDetails?.appointmentType === 'Video' && phoneNumber?.length !== 14;
  const patientAge = appointmentDetails?.patient?.age;

  const handleChange = val => {
    const maskedValue = maskPhone(val);
    setPhoneNumber(maskedValue);
  };

  const submitRef = useRef(0);

  const handleResponse = (error: string, retryFn: () => Promise<void>) => {
    submitRef.current += 1;
    if (error && submitRef.current < 2) {
      retryFn();
    } else if (error) {
      const phone = appointmentDetails?.appointmentLocation?.phoneDisplay;
      const buttons = {
        styles: { direction: 'row', justifyContent: 'center', alignItems: 'center' },
        items: [
          phone
            ? {
                label: `Call the clinic ${getUSPhoneNumber(phone)}`,
                onClick: () => {
                  bookAppointmentErrorCallForCalendarOptions(
                    bookingAppointmentCallEventProps(appointmentDetails)
                  );
                  LinkingServices.callPhoneNumber(phone);
                },
                styles: { paddingX: 35, width: 175 }
              }
            : null,
          {
            label: 'Select another appointment time',
            onClick: () => {
              setTimerContext(BOOKING_TIMEOUT);
              if (history.location.pathname === '/u/get-care-now/booking/date-and-time-select') {
                history.replace('/u/get-care-now/booking/date-and-time-select');
              } else {
                const backTo =
                  history.location.pathname === '/u/get-care-now/booking/reason-for-visit'
                    ? -1
                    : -2;
                history.go(backTo);
              }
            },
            styles: { paddingX: 30, width: 175 }
          }
        ]
      };

      Modal.show({
        customIcon: (
          <Image
            accessibilityLabel="error-plug"
            style={{ width: 83, height: 51 }}
            source={{ uri: errorPlug }}
          />
        ),
        backdrop: { skipBackdropClose: true },
        title: 'Uh-oh... something went wrong',
        description:
          'Please call your clinic or select another appointment time to schedule your visit',
        buttons,
        options: { closeIcon: false }
      });
    } else {
      clearLocationTimeSlots();
      bookingActions.closeTimeSlotListener();
      history.push('/u/get-care-now/booking/book-appointment-success');
    }
  };

  const confirmAppointment = async () => {
    const appointmentPayload: bookingTypes.AppointmentPayload = {
      slotId: appointmentDetails?.appointmentTime?.id,
      slotStartTime:
        appointmentDetails?.appointmentTime?.datetime || appointmentDetails?.appointmentTime?.start,
      providerId: appointmentDetails?.doctor?.cernerResourceId.toString(),
      patientId: appointmentDetails?.patient?.cernerPersonId,
      apptReason: `Reason for visit: ${appointmentDetails?.reasonForVisit}`,
      displayName: appointmentDetails?.patient?.displayName
    };
    const videoConsentPayload: bookingTypes.VideoConsentPayload = {
      consentVal: patientAge < 18 ? review.CONSENT_VALUE_MINOR : review.CONSENT_VALUE_ADULT,
      cmrn: appointmentDetails?.patient?.consumerId
    };

    let res: ReduxAxiosAction;
    let vidRes: ReduxAxiosAction;

    if (appointmentDetails?.appointmentType === 'Video') {
      appointmentPayload.apptReason = `SVV contact: ${phoneNumber}. ${appointmentPayload.apptReason}`;
    }

    setIsBookingAppointment(true);

    if (isReschedule) {
      appointmentPayload.appointmentId = appointmentDetails?.id;
      appointmentPayload.comment = 'rescheduling';
      appointmentPayload.consumerId = profile.consumer.consumerId;
      res = await rescheduleAppointment(appointmentPayload);
    } else {
      res = await confirmAndBookAppointment(appointmentPayload);
    }

    if (appointmentDetails?.appointmentType === 'Video' && !res.error) {
      vidRes = sendVideoConsent(videoConsentPayload);
      if (vidRes.error) {
        videoErrorAnalytics(bookingAppointmentCallEventProps(appointmentDetails));
      }
    }
    if (!res.error) {
      getUpcomingAppointments(false);
    }
    setIsBookingAppointment(false);

    handleResponse(res.error, confirmAppointment);
  };

  const handlePreviousClick = (): void => {
    if (lockedSlot && isReschedule) {
      unlockTimeSlot(lockedSlot);
    }
    setReasonForVisit('');
    return isReschedule
      ? history.push('/u/get-care-now/booking/date-and-time-select')
      : history.goBack();
  };

  return (
    <StyledScreen>
      <FlexBox flex="1">
        <MuiContainer maxWidth="lg">
          <MuiBox my={3}>
            <MuiBox py={1}>
              <MuiTypography variant="h4">Review Appointment Details</MuiTypography>
            </MuiBox>
            <MuiBox mb={3}>
              <MuiDivider />
            </MuiBox>
            <StyledCard>
              <AppointmentDetailsGrid appointmentDetails={appointmentDetails} />
            </StyledCard>
            <MuiBox maxWidth={552} margin="auto">
              <MuiBox py={5}>
                {appointmentDetails?.appointmentType === 'Video' ? (
                  <MuiBox pb={2}>
                    <MuiTypography fontSize={FontSize.base}>
                      At the time of video visit, the provider will text you a link. Please confirm{' '}
                      {profile?.consumer?.consumerId !== appointmentDetails?.patient?.consumerId
                        ? `your`
                        : `${appointmentDetails?.patient?.displayName}'s`}{' '}
                      mobile number for the visit.
                    </MuiTypography>
                  </MuiBox>
                ) : null}
                <MuiBox>
                  <MuiTypography fontSize={FontSize.base}>{review.CONSENT_TEXT}</MuiTypography>
                </MuiBox>
              </MuiBox>
              <MuiDivider />
              <MuiBox py={5}>
                <PersonalInfoInputLabel
                  required
                >{`Mobile Phone for ${appointmentDetails?.patient?.displayName}`}</PersonalInfoInputLabel>
                <PersonalInfoSection>
                  <PersonalInfoFormControl required>
                    <FieldDropdown
                      variant="outlined"
                      margin="normal"
                      name="mobileNumber"
                      onChange={e => {
                        if (handleChange) {
                          handleChange(e.target.value);
                        }
                      }}
                      value={phoneNumber}
                      placeholder="(_ _ _) - _ _ _ - _ _ _ _"
                      startAdornment={
                        <MuiBox ml={1}>
                          <InputAdornment position="start">
                            <Svg name="PhoneCallThinEdges" color={Color.black} />
                          </InputAdornment>
                        </MuiBox>
                      }
                      fullWidth
                      type="search"
                    />
                  </PersonalInfoFormControl>
                </PersonalInfoSection>
              </MuiBox>
              <MuiDivider />
              {appointmentDetails?.appointmentType === 'Video' &&
              appointmentDetails?.groupedVisitType.stateLicenseList?.length ? (
                <>
                  <MuiBox py={5}>
                    <MuiTypography fontSize={FontSize.base}>
                      {review.CONSENT_STATE_TEXT}
                    </MuiTypography>
                    <CustomList>
                      {appointmentDetails?.groupedVisitType.stateLicenseList.map(
                        ({ stateCode }) => (
                          <li>{stateCode}</li>
                        )
                      )}
                    </CustomList>
                    <MuiTypography fontSize={FontSize.base}>
                      {review.CONSENT_VIDEO_VISIT_HEADER_TEXT}
                      <br />
                    </MuiTypography>
                    <StyledUL>
                      {review.CONSENT_VIDEO_VISIT_TEXT.map((item: string) =>
                        listItemDecimal(item, FontSize.base)
                      )}
                    </StyledUL>
                    <MuiTypography fontSize={FontSize.base}>
                      <br />
                      {review.CONSENT_VIDEO_VISIT_FOOTER_TEXT}
                    </MuiTypography>
                  </MuiBox>
                  <MuiDivider />
                </>
              ) : null}
            </MuiBox>
          </MuiBox>
        </MuiContainer>
      </FlexBox>
      <SpinnerOverlay
        isLoading={isBookingAppointment}
        position="absolute"
        backgroundColor={Color.translucent}
        testID="svv-booking-review-appointment-loading"
      />
      <MuiBox pt={10}>
        <BookingTimer />
        <BookingStepActions>
          <BookingStepAction
            label={isReschedule ? 'Confirm and Reschedule' : 'Confirm and Book'}
            disabled={isDisabled}
            onClick={confirmAppointment}
            data-testid={convertToLowerKabobCase(
              isReschedule ? 'Confirm and Reschedule' : 'Confirm and Book'
            )}
          />
          <BookingStepAction
            label={isReschedule ? 'Change Appointment Time' : 'Previous'}
            onClick={handlePreviousClick}
            data-testid={convertToLowerKabobCase(
              isReschedule ? 'Change Appointment Time' : 'Previous',
              '-button'
            )}
          />
        </BookingStepActions>
      </MuiBox>
    </StyledScreen>
  );
}

const mapStateToProps = (state: RootState) => ({
  appointmentDetails: bookingSelectors.appointmentDetailsSelector(state),
  profile: profileSelectors.profileSelector(state),
  profilePhones: profileSelectors.profilePhonesSelector(state),
  isReschedule: bookingSelectors.isRescheduleSelector(state),
  currentRouteName: routerSelectors.currentLocationPathNameSelector(state),
  previousRouteName: routerSelectors.previousLocationPathNameSelector(state)
});

const mapDispatchToProps = {
  confirmAndBookAppointment: bookingActions.confirmAndBookAppointment,
  rescheduleAppointment: bookingActions.rescheduleAppointment,
  getUpcomingAppointments: bookingActions.getUpcomingAppointments,
  setReasonForVisit: bookingActions.setReasonForVisit,
  unlockTimeSlot: bookingActions.unlockTimeSlot,
  clearLocationTimeSlots: bookingActions.clearLocationTimeSlots,
  sendVideoConsent: bookingActions.sendVideoConsent
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingReviewAppointmentDetails);
