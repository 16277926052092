import dayjs, { Dayjs } from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { useProxySwitcherEffect } from 'hooks/useProxySwitcherEffect';
import { getTestResultsList } from 'store/testResults/actions';
import { INIT_YEARS_TO_QUERY } from 'store/testResults/constants';
import {
  testResultsListDataSelector,
  testResultsListIsFetchingSelector,
  testResultsListErrorSelector
} from 'store/testResults/selectors';

interface GetMedicalTestResultListProps {
  startDate?: Date | Dayjs;
  endDate?: Date | Dayjs;
  skipAll?: boolean;
}

export const formatDateForQuery = (date: Date | Dayjs | string) => {
  return dayjs(date).format('YYYY-MM-DD');
};

export const getDefaultStartDate = () => dayjs().subtract(INIT_YEARS_TO_QUERY, 'year');
export const getDefaultEndDate = () => dayjs();

export const useGetMedicalTestResultList = (props?: GetMedicalTestResultListProps) => {
  const { startDate = getDefaultStartDate(), endDate = getDefaultEndDate(), skipAll } = props || {};
  const fStartDate = formatDateForQuery(startDate);
  const fEndDate = formatDateForQuery(endDate);
  const restData = useSelector(testResultsListDataSelector);
  const restIsFetching = useSelector(testResultsListIsFetchingSelector);
  const restError = useSelector(testResultsListErrorSelector);
  const dispatch = useDispatch();

  useProxySwitcherEffect(() => {
    if (!skipAll) {
      dispatch(getTestResultsList(fStartDate, fEndDate));
    }
  }, [fStartDate, fEndDate]);

  return {
    data: restData,
    loading: restIsFetching,
    error: restError,
    refetch: () => dispatch(getTestResultsList(fStartDate, fEndDate, false))
  };
};
