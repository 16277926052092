import React from 'react';

export default {
  svg: (
    <g fill="none">
      <rect x="1" y="1" width="42" height="42" rx="3" fill="white" />
      <g clipPath="url(#clip0_347_321)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M29.5931 9.99986C30.1729 9.99593 30.7476 10.1069 31.2842 10.3264C31.8207 10.5459 32.3085 10.8695 32.7193 11.2786L32.7214 11.2808C33.1305 11.6916 33.4542 12.1793 33.6736 12.7159C33.8931 13.2524 34.0041 13.8272 34.0002 14.4069C33.9963 14.9866 33.8775 15.5598 33.6508 16.0933C33.4246 16.6255 33.0956 17.1077 32.6825 17.5122L18.1909 32.0038C18.1906 32.0041 18.1911 32.0036 18.1909 32.0038L18.1853 32.0094C18.0903 32.1044 17.9715 32.1721 17.8413 32.2055L10.9363 33.9765C10.6796 34.0424 10.4071 33.9678 10.2197 33.7804C10.0322 33.5929 9.95768 33.3205 10.0235 33.0637L11.7945 26.1587C11.8279 26.0285 11.8957 25.9097 11.9907 25.8147L26.4878 11.3176C26.8923 10.9045 27.3745 10.5754 27.9067 10.3493C28.4402 10.1225 29.0134 10.0038 29.5931 9.99986ZM26.606 13.3207L25.5727 14.354L29.646 18.4274L30.6794 17.394L26.606 13.3207ZM31.7384 16.3317L27.6683 12.2617C27.9107 12.0388 28.1897 11.8588 28.4933 11.7298C28.8445 11.5806 29.2218 11.5024 29.6033 11.4998C29.9848 11.4972 30.3631 11.5703 30.7163 11.7147C31.069 11.859 31.3896 12.0717 31.6598 12.3404C31.9285 12.6105 32.141 12.9311 32.2853 13.2838C32.4298 13.6369 32.5028 14.0152 32.5002 14.3967C32.4976 14.7783 32.4195 15.1555 32.2702 15.5067C32.1412 15.8104 31.9612 16.0894 31.7384 16.3317ZM28.5854 19.488L24.512 15.4147L13.5822 26.3445L17.6595 30.4139L28.5854 19.488ZM16.2012 31.0776L12.9211 27.804L11.7914 32.2087L16.2012 31.0776Z"
          fill="#4A00E2"
        />
      </g>
      <rect x="1" y="1" width="42" height="42" rx="3" stroke="#4A00E2" strokeWidth="2" />
      <defs>
        <clipPath id="clip0_347_321">
          <rect width="24" height="24" fill="white" transform="translate(10 10)" />
        </clipPath>
      </defs>
    </g>
  ),
  viewBox: '0 0 44 44'
};
