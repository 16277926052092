import BlockSet from 'components/UI/Blocks/BlockSet';
import FlexBox from 'components/UI/Layout/FlexBox';
import Spinner from 'components/UI/Spinner/Spinner';
import Typography from 'components/UI/Typography';
import React from 'react';
import { NavigationScreenProps } from 'screens/navigation';
import { VitalsBlock } from 'store/vitals/types';
import kebabCase from 'lodash/kebabCase';
import { useHistory } from 'react-router-dom';

interface Props extends NavigationScreenProps {
  vitals: VitalsBlock[];
  error?: string;
  isFetching: boolean;
}

export function VitalsSummary({ vitals, error, isFetching }: Props) {
  const history = useHistory();

  const handleBlockPress = (block: VitalsBlock) => {
    history.push(
      `/u/health-record/vitals/${block.serviceName}/${kebabCase(block.title.toLowerCase())}`
    );
  };

  return (
    <>
      {isFetching && !vitals.length ? (
        <FlexBox flex={1} alignItems="center" justifyContent="center">
          <Spinner />
        </FlexBox>
      ) : null}
      {!isFetching && error ? (
        <Typography>Vitals data not available at this time</Typography>
      ) : null}
      {!isFetching && !error && vitals.length === 0 ? (
        <Typography>No data available</Typography>
      ) : null}
      {vitals.length > 0 && !error ? (
        <BlockSet blocks={vitals} onBlockPressed={handleBlockPress} />
      ) : null}
    </>
  );
}

export default VitalsSummary;
