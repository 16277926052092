import CollapsibleList from 'components/common/CollapsibleList/CollapsibleList';
import CollapsibleListHeader from 'components/common/CollapsibleList/CollapsibleListHeader';
import Divider from 'components/UI/Divider';
import FlexBox, { FlexBoxColumn } from 'components/UI/Layout/FlexBox';
import Spacer from 'components/UI/Layout/Spacer';
import Typography from 'components/UI/Typography';
import DefaultText from 'components/UI/Typography/DefaultText';
import Screen from 'components/UI/Layout/Screen';
import isNil from 'lodash/isNil';
import { Color } from 'modules/styles/colors';
import { FontSize, FontWeight } from 'modules/styles/variables';
import { formatDate } from 'modules/utils/DateUtils';
import React, { useEffect, ComponentType, useState } from 'react';
import { FlatList } from 'react-native';
import { connect, useDispatch } from 'react-redux';
import Spinner from 'components/UI/Spinner/Spinner';
import { InfoButton } from 'components/InfoButton';
import { GlossaryItem } from 'store/glossary/reducers';
import { getGlossaries, getItemByTerm } from 'store/glossary/selectors';
import { getGlossary } from 'store/glossary/actions';
import formatMoney from 'services/formatMoney';
import { currentLocationPathNameSelector } from 'store/router/selectors';
import { consumerIdSelector } from 'store/profile/selectors';
import * as snacksActions from 'store/snacks/actions';
import { Snack, SnackType } from 'store/snacks/types';
import { RootState } from 'store/types';
import { History } from 'history';
import { v4 as uuidv4 } from 'uuid';
import { MuiLink, MuiTypography } from 'theme/material-ui';
import {
  BillDetailBorderFlex,
  BillDetailPanel,
  BillingDetailMarginView,
  EmptyFlexBox,
  StyledHeading,
  SummaryFlexBox,
  PaymentViewDrillDownFlexBox,
  MarginSection
} from './styled';
import {
  getPaymentDetailDataSelector,
  getPaymentDetailErrorSelector,
  getPaymentDetailFetchingSelector,
  paymentDownloadDataSelector,
  paymentDownloadFetchingSelector
} from 'store/billing/selectors';
import { PaymentDetailSummary, PaymentEncounter } from 'store/billing/types';
import CallToActionRow from 'components/common/CallToActionRow/CallToActionRow';
import {
  downloadPaymentDetail,
  PAYMENT_DOWNLOAD_SUCCESS,
  getPaymentDetail,
  downloadPaymentDetailToStore
} from 'store/billing/actions';
import MessageComposeWidget from 'components/Messaging/MessageComposeWidget';
import { Alert } from 'components/Alert';
import { Action } from 'modules/types/common';
import { Base64Attachment } from 'store/messaging/types';

interface Match {
  url: string;
  isExact: boolean;
  path: string;
  params: { receiptId: string };
}

type Props = {
  createSnack: typeof snacksActions.createSnack;
  currentRouteName: string;
  consumerId: string;
  glossary: GlossaryItem[];
  match: Match;
  history: History;
  paymentDetailErr: Error;
  paymentDetail: PaymentDetailSummary;
  loading: boolean;
  handleDownloadPayment: (id: string) => Promise<Action>;
  handleSharePayment: (id: string) => Promise<Action>;
  isDownloading: boolean;
  pdfData: string | { receiptNumber: string; base64: string };
};

interface ItemBody {
  left: string;
  right: number | string | undefined | null;
}

const renderSpinner = () => <Spinner />;

export const PaymentDetail = (props: Props) => {
  const {
    consumerId,
    createSnack,
    currentRouteName,
    glossary,
    match,
    paymentDetailErr,
    paymentDetail,
    loading,
    handleDownloadPayment,
    handleSharePayment,
    isDownloading,
    pdfData
  } = props;
  const { receiptId } = match.params;
  const dispatch = useDispatch();
  const [definitions, setDefinitions] = useState([] as GlossaryItem[]);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [messageUUID, setMessageUUID] = useState('');

  useEffect(() => {
    setMessageUUID(uuidv4());
    dispatch(getPaymentDetail(receiptId));
  }, []);
  useEffect(() => {
    if (paymentDetailErr && currentRouteName === 'PaymentDetail') {
      createSnack(SnackType.BASE, { label: 'Unable to load data, please try again' });
    }
  }, [currentRouteName, paymentDetailErr]);

  useEffect(() => {
    if (!glossary || !glossary.length) {
      dispatch(getGlossary());
    } else {
      const def: GlossaryItem[] = [
        getItemByTerm(glossary, 'Adjustments'),
        getItemByTerm(glossary, 'Billing Statement'),
        getItemByTerm(glossary, 'Financial Assistance'),
        getItemByTerm(glossary, 'Total Charges'),
        getItemByTerm(glossary, 'What you already paid'),
        getItemByTerm(glossary, 'What you owe'),
        getItemByTerm(glossary, 'What your insurance covered')
      ];
      setDefinitions(def);
    }
  }, [glossary]);

  const handleDownload = async () => {
    const action = await handleDownloadPayment(receiptId);
    if (action.type === PAYMENT_DOWNLOAD_SUCCESS) {
      Alert.alert('Success', 'Download complete');
    } else {
      Alert.alert('Error', 'Download Failed. Please try again later.');
    }
  };

  const handleShare = () => {
    setShowMessageModal(true);
    if (!pdfData || typeof pdfData === 'string' || pdfData?.receiptNumber !== receiptId) {
      handleSharePayment(receiptId);
    }
  };

  const handleShowSuccessAlert = (providerName: string) => {
    Alert.alert(`Success`, `Message has been sent to ${providerName}`);
  };
  const encounters = paymentDetail?.encounters || [];
  const paymentSummaryData = [
    { left: 'Processed Date:', right: `${formatDate(paymentDetail?.date)}` },
    { left: 'Payment Status:', right: paymentDetail?.status },
    { left: 'Payment Type:', right: paymentDetail?.paymentType },
    {
      left: 'Payment Transaction Amount:',
      right: `$${formatMoney(Math.abs(paymentDetail?.amount || 0))}`
    }
  ];

  const navigateToVisitSummary = (encounters: PaymentEncounter) => {
    // Note that we use FHIR in the url being built as this is expected to always be the source
    props.history.push(
      `/u/health-record/visit-summaries/live/fhir/${encounters.cernerEncounterId}/${encounters.dischargeDate}`
    );
  };

  const renderAssociatedVisits = (encounter: PaymentEncounter) => {
    const paymentAmount = encounter?.paymentAmount || 0;
    const cernerEncounterId = encounter?.cernerEncounterId;
    return (
      <>
        <PaymentViewDrillDownFlexBox>
          <FlexBox
            accessibilityLabel="Tap to view claims"
            flexDirection="row"
            alignItems="flex-start"
            justifyContent="space-between"
          >
            <Typography numberOfLines={1}>
              {`${encounter?.patient.firstName || ''} ${encounter?.patient.lastName || ''}`}
            </Typography>
            <Typography numberOfLines={1}>{`${formatDate(
              encounter?.dischargeDate || ''
            )}`}</Typography>
          </FlexBox>
          <FlexBox flexDirection="row" alignItems="flex-start" justifyContent="space-between">
            <Typography numberOfLines={1} fontWeight={FontWeight.bold}>
              {encounter.billingDescription}
            </Typography>
            <Typography numberOfLines={1} fontWeight={FontWeight.bold}>{`$${formatMoney(
              Math.abs(paymentAmount)
            )}`}</Typography>
          </FlexBox>
          {cernerEncounterId && consumerId === encounter?.patient.id && (
            <>
              <Spacer size="xsmall" />
              <MuiLink
                data-testid="visit summary"
                onClick={() => navigateToVisitSummary(encounter)}
              >
                <MuiTypography fontSize={FontSize.base}>
                  View Visit Summary {`(Encounter: ${encounter.accountNumber})`}
                </MuiTypography>
              </MuiLink>
            </>
          )}
        </PaymentViewDrillDownFlexBox>
        <Divider />
      </>
    );
  };

  const paramsMessage = {
    attachments: [] as Base64Attachment[],
    transmitParams: {
      source: 'PaymentDetail',
      payload: { id: receiptId },
      returnBackTo: `/u/billing/payment-detail/${receiptId}`
    },
    isSending: false,
    open: showMessageModal
  };

  if (pdfData && typeof pdfData !== 'string' && pdfData?.receiptNumber === receiptId) {
    paramsMessage.attachments = [
      {
        base64: `data:application/pdf;base64, ${pdfData.base64}`,
        filename: `payment-detail-${receiptId.replace(/\W/g, '')}.pdf`
      }
    ];
  }

  return (
    <Screen>
      {showMessageModal && !isDownloading && paramsMessage.attachments.length ? (
        <MessageComposeWidget
          messageUUID={messageUUID}
          paramsMessage={paramsMessage}
          handleMessageModal={setShowMessageModal}
          showSuccessAlert={handleShowSuccessAlert}
        />
      ) : null}
      <MarginSection data-testid="payment-detail">
        <FlexBox>
          <CallToActionRow
            isDownloading={isDownloading && !showMessageModal}
            isPatientToolbar
            justifyContent="flex-end"
            onDownloadPress={handleDownload}
            onTransmitPress={handleShare}
          />
        </FlexBox>

        <BillDetailPanel>
          <BillingDetailMarginView>
            {loading || (isDownloading && showMessageModal) ? renderSpinner() : null}
            <FlexBox flexDirection="row" justifyContent="flex-start" alignItems="center">
              <Typography numberOfLines={1} fontSize={FontSize.large} fontWeight={FontWeight.bold}>
                Summary <InfoButton definitions={definitions} hideBorder color={Color.secondary} />
              </Typography>
            </FlexBox>
            <Spacer size="xsmall" />
            <FlatList
              accessibilityLabel="Bill Detail"
              data={paymentSummaryData}
              keyExtractor={(itemBody: ItemBody) => `${itemBody.left}`}
              renderItem={({ item: itemBody }) => (
                <>
                  <SummaryFlexBox
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <FlexBox flexDirection="column">
                      <Typography>{itemBody.left}</Typography>
                    </FlexBox>
                    <FlexBox flexDirection="column" alignItems="flex-end">
                      <Typography fontWeight={FontWeight.bold} numberOfLines={1}>
                        {loading ? '' : isNil(itemBody.right) ? 'N/A' : `${itemBody.right}`}
                      </Typography>
                    </FlexBox>
                  </SummaryFlexBox>
                  <Spacer size="xsmall" />
                </>
              )}
            />
          </BillingDetailMarginView>
          <Divider />
          <CollapsibleList accessibilityLabel="Associated Visits" key="Associated Visits">
            <CollapsibleListHeader
              onOpened={() => {}}
              title="Associated Visits"
              testID="associated-visits"
            >
              <Divider />
              {encounters.length === 0 ? (
                <EmptyFlexBox>
                  <DefaultText>No Associated Visits</DefaultText>
                </EmptyFlexBox>
              ) : (
                encounters.map((eachClaim: PaymentEncounter) => renderAssociatedVisits(eachClaim))
              )}
            </CollapsibleListHeader>
          </CollapsibleList>
          <Divider />
          <Spacer size="small" />
          <FlexBoxColumn alignItems="center">
            <BillDetailBorderFlex
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <FlexBox
                flexDirection="row"
                flexWrap="wrap"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography accessibilityLabel="Paid Amount" fontWeight={FontWeight.bold}>
                  {' '}
                  PAID AMOUNT
                </Typography>
              </FlexBox>
              <StyledHeading
                numberOfLines={1}
                adjustsFontSizeToFit
                accessibilityLabel="Total Owed Amt"
              >
                {`$${formatMoney(paymentDetail?.amount || 0)}`}
              </StyledHeading>
              <Spacer size="medium" />
            </BillDetailBorderFlex>
          </FlexBoxColumn>
          <Spacer size="large" />
        </BillDetailPanel>
      </MarginSection>
    </Screen>
  );
};
const mapStateToProps = (state: RootState) => ({
  consumerId: consumerIdSelector(state),
  glossary: getGlossaries(state),
  currentRouteName: currentLocationPathNameSelector(state),
  isDownloading: paymentDownloadFetchingSelector(state),
  pdfData: paymentDownloadDataSelector(state),
  paymentDetail: getPaymentDetailDataSelector(state),
  paymentDetailErr: getPaymentDetailErrorSelector(state),
  loading: getPaymentDetailFetchingSelector(state)
});

const mapDispatch = (dispatch: Function) => ({
  createSnack: (type: SnackType, snack?: Snack) => dispatch(snacksActions.createSnack(type, snack)),
  handleDownloadPayment: (id: string) => dispatch(downloadPaymentDetail(id)),
  handleSharePayment: (id: string) => dispatch(downloadPaymentDetailToStore(id))
});

export default connect(mapStateToProps, mapDispatch)(PaymentDetail as ComponentType);
