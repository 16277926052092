import React from 'react';
import styled from 'styled-components';
import { MuiBox } from 'theme/material-ui';
import { Color } from 'modules/styles/colors';
import {
  Slider as PRCSlider,
  Slide as PRSlide,
  CarouselProvider as PRCarouselProvider,
  Dot as PRDot
} from 'pure-react-carousel';

export const SliderBox = styled(MuiBox)`
  width: 100%;
`;

interface SliderBoxContainerProps {
  children: React.ReactNode;
}

export const SliderBoxContainer = ({ children }: SliderBoxContainerProps) => {
  return <SliderBox>{children}</SliderBox>;
};

export const CarouselProvider = styled(PRCarouselProvider)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 25px 25px;

  & ul {
    display: flex;
    gap: 16px;
  }
`;

export const Slider = styled(({ ...rest }) => <PRCSlider {...rest} />)`
  width: 100%;
`;

export const Slide = styled(PRSlide)`
  height: 250px;
`;

export const DotContainer = styled(MuiBox)`
  position: relative;
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
`;

export const Dot = styled(PRDot)`
  border: none;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  margin: 3px;

  &.carousel__dot {
    background-color: ${Color.white};
  }

  &.carousel__dot--selected {
    background-color: ${Color.secondary};
  }
`;
