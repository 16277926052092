import Divider from 'components/UI/Divider';
import { MuiBox } from 'theme/material-ui';
import Spacer from 'components/UI/Layout/Spacer';
import Typography from 'components/UI/Typography';
import React from 'react';
import { FinancialAssistance, ListOfDocs, ListOfDocs2, ApplicationSection } from './shared';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { FinancialAssistanceApplication as Application } from 'store/billing/types';
import { Box } from '@material-ui/core';

interface IncompleteProps {
  application: Application;
}

const IncompleteStatus = (props: IncompleteProps) => {
  return (
    <>
      <Container>
        <Grid container>
          <ApplicationSection application={props.application} />
        </Grid>
        <Grid container>
          <Grid item md={7} xs={12}>
            <Box p={1}>
              <MuiBox flexDirection="column">
                <Typography accessibilityLabel="Incomplete status message">
                  Your application is incomplete. Please verify that you have submitted the
                  following verification forms. If you have any questions, please call Intermountain
                  Patient Financial Services at <FinancialAssistance />. Please allow about 2 weeks
                  for the application to be processed.
                </Typography>
                <Divider />
                <Spacer spacing="large" />
                <Typography>
                  After completing the application form, please submit the following documentation:
                </Typography>
                <ListOfDocs />
              </MuiBox>
            </Box>
          </Grid>
          <Grid item md={5} xs={12}>
            <Box p={1}>
              <ListOfDocs2 />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default IncompleteStatus;
