import * as React from 'react';
import { Color } from 'modules/styles/colors';
import { SvgProps } from 'components/UI/Svg/Svg';

export default (props?: SvgProps) => {
  const styles = {
    fill: 'none',
    stroke: props?.color || Color.white,
    strokeWidth: 2,
    strokeLinecap: 'round',
    strokeLinejoin: 'round'
  } as const;
  return {
    svg: (
      <g transform="translate(-168 -943)" fill="none" fillRule="evenodd">
        <g transform="translate(168 943)">
          <path
            d="M28.487 33.257l9.758-4.55a1.936 1.936 0 111.638 3.509L21.044 41 8.742 37.129"
            {...styles}
          />
          <path
            d="M8.742 25.516s8.387-.645 12.258 3.871h7.097a1.936 1.936 0 010 3.871H17.129"
            {...styles}
          />
          <path
            {...styles}
            d="M1 39.065h7.742V24.226H1zM12.612 17.774h5.16l1.292-2.58 2.58 5.161 4.516-10.323 2.58 7.742h9.68M37.408 20.355c-1.439 2.817-3.402 5.183-5.44 7.096"
          />
          <path
            d="M11.323 22.936c-2.273-3.258-3.871-7.352-3.871-12.052 0-5.458 3.962-9.883 8.845-9.883 3.024 0 5.69 1.929 7.284 4.515C25.174 2.93 27.844 1 30.867 1c4.886 0 8.843 4.426 8.843 9.884 0 2.43-.417 4.666-1.123 6.709"
            {...styles}
          />
        </g>
      </g>
    ),
    viewBox: '0 0 42 42'
  };
};
