import { Color } from 'modules/styles/colors';

export interface ChartLegendKey {
  title: string;
  class1: string;
  color: string;
}
export const BloodPressureKey: ChartLegendKey[] = [
  { title: 'normal', class1: 'KeyValueNormal', color: Color.green },
  { title: 'high/low', class1: 'KeyValueWarning', color: '#fb2' }
];
export const TemperatureKey: ChartLegendKey[] = [
  { title: 'normal', class1: 'KeyValueNormal', color: Color.green },
  { title: 'high/low', class1: 'KeyValueWarning', color: '#fb2' }
];
export const BMIKey: ChartLegendKey[] = [
  { title: 'normal', class1: 'KeyValueNormal', color: Color.green },
  { title: 'overweight/underweight', class1: 'KeyValueWarning', color: '#fb2' },
  { title: 'obese', class1: 'obese', color: '#ff6666' }
];
export const ChartNames = {
  BloodPressure: 'Blood Pressure Trend (mmHg)',
  BMI: 'Body Mass Index (kg/m2)',
  HeartRate: 'Heart Rate (bpm)',
  Height: 'Height (in)',
  HeightLength: 'Height (in)',
  Temperature: 'Temperature (°F)',
  Weight: 'Weight (lb)'
};
