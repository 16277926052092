import React from 'react';
import { useHistory } from 'react-router';
import RouteLeavingGuard from 'components/UI/Modals/RouteLeavingGuardModal';
import { eVisitConfirmDialog } from 'modules/constants/ConnectCare';

import { ROUTE as CONNECT_CARE_ROUTES } from 'components/ConnectCare/constants';
import { HELP_SCREEN_LINKS, SHARE_FEEDBACK_SCENARIOS } from 'lib/constants/help';
import { EVISITS_ROUTES } from 'screens/EVisits/constants';
import { TRIAGE_ROUTES } from 'screens/TriageGyant/constants';
import { resetInsuranceFiles } from 'store/eVisit/actions';
import { useDispatch } from 'react-redux';

const EVisitsLeavingGuard = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const mainEVisitsRoutes = [
    EVISITS_ROUTES.FRONTDOOR_OVERVIEW,
    EVISITS_ROUTES.FRONTDOOR_TERMS_AND_AGREEMENTS,
    EVISITS_ROUTES.FRONTDOOR_CONDITIONS_WE_TREAT,
    HELP_SCREEN_LINKS.Feedback.webPath,
    TRIAGE_ROUTES.SYMPTOM_CHECKER,
    EVISITS_ROUTES.FRONTDOOR_INSURANCE,
    EVISITS_ROUTES.FRONTDOOR_INSURANCE_UPLOAD,
    EVISITS_ROUTES.FRONTDOOR_INSURANCE_SUBMIT
  ];

  const handleNavigate = (path: string, options: object) => {
    dispatch(resetInsuranceFiles());
    history.push(path, options);
  };

  const exitAndShareFeedback = (path?: string) => {
    dispatch(resetInsuranceFiles());

    history.push(HELP_SCREEN_LINKS.Feedback.webPath, {
      scenario: SHARE_FEEDBACK_SCENARIOS.eVisit,
      screenLocation: '/u/e-visits',
      navigateTo: path || CONNECT_CARE_ROUTES.base
    });
  };

  return (
    <RouteLeavingGuard
      shouldBlockNavigation={nextLocation => !mainEVisitsRoutes.includes(nextLocation.pathname)}
      exitTitle={eVisitConfirmDialog.title}
      exitSubtitle={eVisitConfirmDialog.subtitle}
      exitButtonText={eVisitConfirmDialog.primaryButton}
      keepGoingButtonText={eVisitConfirmDialog.secondaryButton}
      exitAndShareButtonText={eVisitConfirmDialog.textButton}
      navigate={handleNavigate}
      exitAndShareHandler={exitAndShareFeedback}
    />
  );
};

export default EVisitsLeavingGuard;
