//
//  Component: FilterDrawer
//
//  Description:
//  <FilterDrawer /> is a reusable component for building out synonymous filters throughout the app.
//  Should be used with <FilterDrawer.Panel /> as its direct children.
//
//  Example:
//  <FilterDrawer open onClose={() => {}} onApply={() => {}} onClear={() => {}}>
//    <FilterDrawer.Panel title="Panel 1">
//      >> Panel Contents <<
//    <FilterDrawer.Panel>
//  </FilterDrawer>
//
import React, { useState } from 'react';
import { Color } from 'modules/styles/colors';
import {
  Grid,
  Box,
  Typography,
  IconButton,
  List,
  ExpansionPanelDetails,
  Divider
} from '@material-ui/core';
import {
  MuiFormGroup,
  MuiFormControlLabel,
  MuiCheckbox,
  MuiBox,
  MuiGrid,
  MuiRadio,
  MuiRadioGroup,
  MuiButton
} from 'theme/material-ui';
import { Drawer, DrawerHeader, ExpansionPanel, ExpansionPanelSummary } from './styled';
import { convertToLowerKabobCase } from 'modules/utils/StringUtils';
import { CountCircle } from 'screens/Profile/AuditLog/styled';
import { IconSize, Spacing } from 'modules/styles/variables';
import Spacer from 'components/UI/Layout/Spacer';
import { Svg } from 'components/UI/Svg';

interface FilterDrawerHeaderProps {
  onClose: () => void;
}

function FilterDrawerHeader({ onClose }: FilterDrawerHeaderProps) {
  return (
    <DrawerHeader>
      <Box display="flex" alignItems="center">
        <Typography style={{ color: Color.textLight }}>Filter</Typography>
      </Box>
      <IconButton data-testid="close-button" aria-label="open drawer" edge="end" onClick={onClose}>
        <Svg name="CloseIcon" size={IconSize.base} />
      </IconButton>
    </DrawerHeader>
  );
}

interface FilterDrawerFooterProps {
  onApply: () => void;
  onClear: () => void;
  disabled?: boolean;
}

function FilterDrawerFooter({ onApply, onClear, disabled }: FilterDrawerFooterProps) {
  return (
    // eslint-disable-next-line react/style-prop-object
    <Box p={2} bottom={Spacing.none} position="fixed" width="inherit" bgcolor={Color.white}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <MuiButton
            size="large"
            variant="contained"
            fullWidth
            onClick={onApply}
            color="primary"
            data-testid="apply-filters-button"
            disabled={disabled}
          >
            Apply filters
          </MuiButton>
        </Grid>
        <Grid item xs={12}>
          <MuiButton
            data-testid="clear-filters-button"
            size="large"
            color="primary"
            onClick={onClear}
            fullWidth
          >
            Clear all filters
          </MuiButton>
          <Spacer size="small" />
        </Grid>
      </Grid>
    </Box>
  );
}

export interface CheckboxItem {
  label: string;
  checked: boolean;
}
export interface FilterPanelCheckboxGroupProps {
  list: CheckboxItem[];
  renderLabel: (item: CheckboxItem) => item.label;
  checked: (item: CheckboxItem) => item.checked;
  handleChange: (item: CheckboxItem, checked: boolean) => void;
}

function FilterPanelCheckBoxGroup({
  list,
  renderLabel,
  checked,
  handleChange
}: FilterPanelCheckboxGroupProps) {
  return (
    <MuiBox bgcolor={Color.foreColor}>
      <MuiFormGroup>
        {list?.map(item => (
          <MuiGrid key={renderLabel(item)} container>
            <MuiGrid item xs={12}>
              <MuiFormControlLabel
                data-testid="checkbox-label"
                control={
                  <MuiCheckbox
                    data-testid={convertToLowerKabobCase(renderLabel(item), '-checkbox-item')}
                    color="secondary"
                    checked={checked(item)}
                    onChange={(_, checked) => handleChange(item, checked)}
                  />
                }
                label={renderLabel(item)}
              />
            </MuiGrid>
          </MuiGrid>
        ))}
      </MuiFormGroup>
    </MuiBox>
  );
}

export interface RadioItem {
  label: string;
  checked: boolean;
}
export interface FilterPanelRadioGroupProps {
  list: CheckboxItem[];
  value: any;
  renderLabel: (item: RadioItem) => item.label;
  handleChange: (event: any, value: string) => void;
}

function FilterPanelRadioGroup({
  list,
  value,
  renderLabel,
  handleChange
}: FilterPanelRadioGroupProps) {
  return (
    <MuiBox bgcolor={Color.foreColor}>
      <MuiRadioGroup value={value} onClick={handleChange}>
        {list?.map(item => (
          <MuiFormControlLabel
            data-testid="radio-button"
            value={renderLabel(item)}
            control={<MuiRadio color="secondary" />}
            label={renderLabel(item)}
          />
        ))}
      </MuiRadioGroup>
    </MuiBox>
  );
}

export interface FilterDrawerPanelProps {
  title: string;
  startAdornment?: React.ReactNode;
  children: React.ReactNode;
  defaultExpanded: boolean;
  expandable: boolean;
  onToggle?: (e: React.ChangeEvent<{}>) => void;
  filtersCount?: number;
}

function FilterDrawerPanel<T>({
  defaultExpanded,
  expandable,
  onToggle,
  children,
  title,
  startAdornment,
  filtersCount
}: FilterDrawerPanelProps) {
  const [expanded, setExpanded] = useState(defaultExpanded);

  const onChange = (e: React.ChangeEvent<{}>) => {
    setExpanded(prevState => !prevState);
    onToggle?.(e);
  };

  return (
    <>
      <ExpansionPanel
        data-testid="expansion-panel"
        expanded={expanded}
        disabled={!expandable}
        onChange={onChange}
      >
        <ExpansionPanelSummary
          bgcolor={expanded ? Color.baseColor : 'inherit'}
          expandIcon={
            <Svg name={expanded ? 'Subtract' : 'Add'} color={Color.black} size={IconSize.base} />
          }
        >
          {startAdornment || null}
          <Typography
            data-testid={convertToLowerKabobCase(title, '-collapsible-filter-group')}
            style={{ fontWeight: 600 }}
          >
            {title}{' '}
          </Typography>
          {filtersCount ? <CountCircle data-testid="count-icon">{filtersCount}</CountCircle> : null}
        </ExpansionPanelSummary>

        <ExpansionPanelDetails>
          <Box width="100%">{children}</Box>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <Divider />
    </>
  );
}

FilterDrawerPanel.defaultProps = {
  defaultExpanded: false,
  expandable: true
};

export interface FilterDrawerProps {
  open: boolean;
  onClose: () => void;
  onApply: () => void;
  onClear: () => void;
  disabled?: boolean;
  width?: string | number;
  children: React.ReactNode;
  paperTop?: string | number;
}

function FilterDrawer({
  open,
  onClose,
  onApply,
  onClear,
  disabled,
  width,
  children,
  paperTop
}: FilterDrawerProps) {
  return (
    <Drawer anchor="right" open={open} width={width} onClose={onClose} paperTop={paperTop}>
      <FilterDrawerHeader onClose={onClose} />
      <List disablePadding style={{ flex: 1 }}>
        {children}
        <Spacer size="xlarge" />
      </List>
      <FilterDrawerFooter disabled={disabled} onApply={onApply} onClear={onClear} />
    </Drawer>
  );
}

FilterDrawer.Panel = FilterDrawerPanel;
FilterDrawer.CheckBoxGroup = FilterPanelCheckBoxGroup;
FilterDrawer.RadioGroup = FilterPanelRadioGroup;

export default FilterDrawer;
