import { SelectDropdown } from 'components/UI/SelectDropdown';
import { Color } from 'modules/styles/colors';
import styled from 'styled-components';

export const LanguageDropDown = styled(SelectDropdown)`
  width: 35%;

  @media screen and (max-width: 1000px) {
    width: 100%;
  }
`;

export const PrediabetesCardsVideos = styled.div`
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(3, 1fr);

  @media screen and (max-width: 1440px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 1100px) {
    grid-template-columns: 1fr;
  }
`;

export const CardVideo = styled.div`
  border-radius: 5px;

  img {
    border-radius: 5px;
    width: 100%;
  }
`;

export const VideoBanner = styled.div`
  background-color: ${Color.secondary};
  display: grid;
  grid-template-columns: 80% auto;
  height: 6rem;
  position: absolute;
  bottom: 0;
  border-radius: 0 0 5px 5px;
  width: 100%;
  padding: 1rem;
  gap: 1rem;
  justify-content: center;
  align-items: center;

  p {
    color: white;
    font-size: 1.1rem;
    font-weight: 600;
    margin: 0;
  }
`;

export const PlayIcon = styled.div`
  height: 3rem;
  width: 3rem;
  background: white;
  border-radius: 50%;
  cursor: pointer;

  &:after {
    content: '';
    position: absolute;
    left: 1.25rem;
    top: 0.7rem;
    width: 0;
    height: 0;
    border-bottom: 0.8rem solid transparent;
    border-top: 0.8rem solid transparent;
    border-left: 1rem solid ${Color.secondary};
  }
`;
