import React from 'react';

export default {
  svg: (
    <path
      fill="#000"
      fillRule="evenodd"
      d="M10.614 2.738C9.2 1.346 7.722.656 6.3.553c-1.61-.117-3.036.53-4.067 1.554C.205 4.121-.428 7.77 2.095 10.293a.788.788 0 0 0 .027.026l7.989 7.236a.75.75 0 0 0 1.006 0l1.484-1.344a.75.75 0 1 0-1.007-1.112l-.98.888-6.868-6.221h1.706a.75.75 0 0 0 .6-.3l1.134-1.513 1.86 3.254a.75.75 0 0 0 1.25.078l1.595-2.126h2.074a.75.75 0 0 0 0-1.5h-2.45a.75.75 0 0 0-.6.3L9.783 9.472l-1.86-3.254a.75.75 0 0 0-1.25-.078L5.076 8.266H2.429c-.93-1.712-.444-3.798.86-5.094.766-.76 1.78-1.204 2.903-1.123 1.123.082 2.467.697 3.86 2.277a.75.75 0 0 0 1.125 0c1.393-1.58 2.734-2.193 3.854-2.273 1.12-.08 2.131.367 2.895 1.128 1.564 1.56 1.948 4.25.146 6.052a.75.75 0 1 0 1.06 1.06c2.511-2.51 1.875-6.159-.147-8.174-1.028-1.025-2.45-1.676-4.06-1.562-1.42.1-2.897.79-4.311 2.181Zm3.316 10.224c0-.863.7-1.562 1.563-1.562h6.499c.863 0 1.562.7 1.562 1.562v8.936c0 .863-.7 1.562-1.562 1.562h-6.5c-.862 0-1.562-.699-1.562-1.562v-8.936Zm1.563-.062a.062.062 0 0 0-.063.062v8.936c0 .034.028.062.063.062h6.499a.062.062 0 0 0 .062-.062v-8.936a.062.062 0 0 0-.062-.062h-6.5Zm1.89 7.128a.75.75 0 0 1 .75-.75h1.218a.75.75 0 0 1 0 1.5h-1.218a.75.75 0 0 1-.75-.75Z"
      clipRule="evenodd"
    />
  ),
  viewBox: '0 0 24 24'
};
