import React from 'react';
import { useHistory } from 'react-router';
import { FontSize, FontWeight, Spacing } from 'modules/styles/variables';
import { MuiContainer, MuiBox, MuiButton, MuiTypography, MuiGrid } from 'theme/material-ui';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { eVisitFrontDoorOverviewScreen } from 'lib/triage/constants';
import FlexBox from 'components/UI/Layout/FlexBox';
import { Typography } from 'components/UI/Typography/styled';
import { EVISITS_ROUTES } from '../constants';
import EVisitBanner from './components/EVisitBanner';
import { StyledAsterisk } from 'components/RequiredFieldsLegend';
import MediaCard from 'components/Card/MediaCard';
import questionsImg from 'assets/evisits/questions.jpg';
import reviewImg from 'assets/evisits/review.jpg';
import treatmentImg from 'assets/evisits/treatment.jpg';

interface DisclosureTextProps {
  text: string;
}

export const DisclosureText = ({ text }: DisclosureTextProps) => (
  <FlexBox hSpacing={Spacing.medium}>
    <Typography tag="small" italics>
      <StyledAsterisk />
      {text}
      <StyledAsterisk />
    </Typography>
  </FlexBox>
);

const cardsImages = [
  {
    key: 'hiwc01',
    image: questionsImg
  },
  {
    key: 'hiwc02',
    image: reviewImg
  },
  {
    key: 'hiwc03',
    image: treatmentImg
  }
];

const EVisitOverview = () => {
  const history = useHistory();
  const {
    screenTitle,
    howItWorksCards,
    disclosures,
    sectionTitle,
    buttons
  } = eVisitFrontDoorOverviewScreen;

  const onStartVisit = () => {
    history.push(EVISITS_ROUTES.FRONTDOOR_TERMS_AND_AGREEMENTS);
  };
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <>
      <EVisitBanner title={screenTitle} />
      <MuiContainer data-testid="e-visit-overview">
        <MuiBox paddingLeft={0} marginTop={Spacing.medium} marginBottom={6}>
          <MuiTypography
            component="h1"
            color="primary"
            fontSize={FontSize.title}
            fontWeight={FontWeight.bold}
            data-testid="how-it-works"
          >
            {sectionTitle.title}
          </MuiTypography>
          <MuiTypography component="h2" fontSize={FontSize.heading}>
            {sectionTitle.subtitle}
          </MuiTypography>
          <MuiBox my={Spacing.xSmall} data-testid="disclosure_for_18">
            <DisclosureText text={disclosures.age} />
          </MuiBox>
        </MuiBox>
        <MuiGrid container direction="row" spacing={8}>
          {howItWorksCards.map(({ key, title }: { key: string; title: string }) => {
            const img = cardsImages.find(card => card.key === key);
            return (
              <MuiGrid key={key} item md={12} lg={4}>
                <MuiBox style={{ height: '100%' }}>
                  <MediaCard isSmallScreen={isSmallScreen} title={title} image={img?.image} />
                </MuiBox>
              </MuiGrid>
            );
          })}
        </MuiGrid>
        <MuiBox my={Spacing.xSmall} data-testid="disclosure" marginTop={Spacing.medium}>
          <FlexBox
            flexDirection="row"
            hSpacing={Spacing.largeXLarge}
            maxWidth="620px"
            style={{ margin: 'auto', textAlign: 'center' }}
          >
            <DisclosureText text={disclosures.info} />
          </FlexBox>
        </MuiBox>
        <MuiBox my={Spacing.xSmall}>
          <MuiBox mx="auto" padding={Spacing.small} maxWidth="390px">
            <MuiButton
              variant="contained"
              color="primary"
              fullWidth
              size="large"
              onClick={onStartVisit}
              data-testid="start-e-visit-next-button"
            >
              {buttons.startEVisit}
            </MuiButton>
          </MuiBox>
        </MuiBox>
      </MuiContainer>
    </>
  );
};

export default EVisitOverview;
