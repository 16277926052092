import React, { ComponentType, useMemo } from 'react';
import { Alert } from 'components/Alert';
import { connect, useDispatch } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { NavigationScreenProps } from 'screens/navigation';
import { AnalyticsEvent } from 'services/AnalyticsService';
import useNavigationAnalytics from 'hooks/useNavigationAnalytics';
import { RootState } from 'store/types';
import {
  profileEmailsSelector,
  consumerIdSelector,
  profileConsumerSelector,
  profilePhonesSelector
} from 'store/profile/selectors';
import { Email, Consumer, Phone } from 'store/profile/types';
import { serviceNowSendingSelector } from 'store/serviceNow/selectors';
import { Color } from 'modules/styles/colors';
import { Spacing, FontSize, IconSize } from 'modules/styles/variables';
import { maskPhone } from 'modules/utils/MaskUtils';

import Divider from 'components/UI/Divider';
import SpinnerModal from 'components/UI/Spinner/SpinnerModal';
import { MuiBox, MuiContainer, MuiTypography, MuiButton, MuiLink } from 'theme/material-ui';
import { CONTACT_INFO } from 'lib/constants/help';
import RequiredFieldsLegend from 'components/RequiredFieldsLegend';
import { shareServiceNow } from 'store/serviceNow/actions';
import { Svg } from 'components/UI/Svg/';
import GlobalTextInput from 'components/TextInput/GlobalTextInput';

const technicalSupportValidationSchema = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  preferredPhone: Yup.string()
    .matches(/^\(\d{3}\) \d{3}-\d{4}$/, 'Phone must match format (###) ###-####')
    .required('Required'),
  email: Yup.string()
    .email('Please enter a valid email')
    .notRequired(),
  issueDescription: Yup.string().required('Required')
});

export interface Props extends NavigationScreenProps {
  consumerId: string;
  profileEmails: Email[];
  displayName: string;
  profileConsumer: Consumer;
  guarantorPhones: Phone[];
  isSendingIncident: boolean;
}
export interface TechnicalSupportForm {
  firstName: string;
  lastName: string;
  preferredPhone: string;
  email: string;
  issueDescription: string;
}

const initialFormValues = {
  firstName: '',
  lastName: '',
  preferredPhone: '',
  email: '',
  issueDescription: ''
};

const getInitPhone = (phones: Phone[] = []) => {
  const primary = phones.find(phone => phone.primary === true);
  const value = primary?.value || phones[0]?.value;
  return { preferredPhone: value ? maskPhone(value) : undefined } as Partial<Phone>;
};

const getInitEmail = (emails: Email[] = []) => {
  const primary = emails.find(email => email.primary === true);
  return { email: primary?.value || emails[0]?.value || undefined } as Partial<Email>;
};

const getInitUser = (consumer: Consumer) => {
  return {
    firstName: consumer.firstName,
    lastName: consumer.lastName,
    dateOfBirth: consumer.dateOfBirth
  };
};

const getInitialValues = (
  user: Partial<Consumer> | null,
  phone: Partial<Phone> | null,
  email: Partial<Email> | null
) => {
  return {
    ...initialFormValues,
    ...user,
    ...phone,
    ...email
  };
};

export const TechnicalSupportScreen = (props: Props) => {
  const {
    history,
    profileEmails,
    consumerId,
    profileConsumer,
    guarantorPhones,
    isSendingIncident
  } = props;
  const dispatch = useDispatch();
  const technicalSupportFormSubmitted = useNavigationAnalytics(
    AnalyticsEvent.TechnicalSupportFormSubmitted
  );
  const technicalSupportFormCanceled = useNavigationAnalytics(
    AnalyticsEvent.TechnicalSupportFormCanceled
  );
  const serviceNumberCalled = useNavigationAnalytics(AnalyticsEvent.SupportCalled, {
    phone_number: CONTACT_INFO.TechSupport.phone
  });

  const initUser = useMemo(() => {
    return profileConsumer ? getInitUser(profileConsumer) : null;
  }, [profileConsumer]);

  const initPhone = useMemo(() => {
    return guarantorPhones ? getInitPhone(guarantorPhones) : null;
  }, [guarantorPhones]);

  const initEmail = useMemo(() => {
    return profileEmails ? getInitEmail(profileEmails) : null;
  }, [profileEmails]);

  const initialValues = useMemo(() => {
    return getInitialValues(initUser, initPhone, initEmail);
  }, [initPhone]);

  const submitQuestion = async ({
    firstName,
    lastName,
    preferredPhone,
    email,
    issueDescription
  }: TechnicalSupportForm) => {
    const questionDTO = {
      firstName,
      lastName,
      preferredPhone,
      email,
      issueDescription,
      MRN: consumerId
    };

    const res = await dispatch(shareServiceNow(questionDTO, !consumerId));
    if (res.error) {
      Alert.alert('Question did not submit.', 'Sorry for the inconvenience.', [
        {
          text: 'Try Again?',
          onPress: () =>
            submitQuestion({ firstName, lastName, preferredPhone, email, issueDescription })
        },
        { text: 'Cancel' }
      ]);
    } else {
      Alert.alert('Message Sent', 'We will contact you within 2 business days', [
        {
          text: 'Okay',
          onPress: () => {
            history.goBack();
          }
        }
      ]);
      technicalSupportFormSubmitted();
    }
  };

  const phoneHref = `tel:${CONTACT_INFO.TechSupport.phone}`;

  const supportHours = CONTACT_INFO.TechSupport.subtitle.split('\n').map(line => {
    return <MuiTypography key={line}>{line}</MuiTypography>;
  });

  return (
    <MuiBox bgcolor={Color.baseColor} flex={1} width="100%" height="100%">
      <SpinnerModal isLoading={isSendingIncident} />
      <MuiContainer maxWidth="md">
        <MuiBox
          py={Spacing.small}
          display="flex"
          flex={1}
          flexDirection="row"
          justifyContent="space-between"
        >
          <MuiTypography fontSize={FontSize.heading}>Call us</MuiTypography>
          <MuiBox display="flex" flexDirection="column" alignItems="flex-end">
            <MuiBox display="flex" flex={1} flexDirection="row" alignItems="center">
              <MuiBox display="flex" width={24}>
                <Svg set="assets" name="Phone" size={IconSize.small} color={Color.secondary} />
              </MuiBox>
              <MuiLink data-testid="service-number" href={phoneHref} onClick={serviceNumberCalled}>
                <MuiTypography>{CONTACT_INFO.TechSupport.phone}</MuiTypography>
              </MuiLink>
            </MuiBox>
            <MuiTypography align="right">{supportHours}</MuiTypography>
          </MuiBox>
        </MuiBox>

        <Divider />
        <MuiBox
          py={Spacing.small}
          display="flex"
          flex={1}
          flexDirection="row"
          justifyContent="space-between"
        >
          <MuiTypography fontSize={FontSize.heading}>Send a message</MuiTypography>
          <MuiBox maxWidth="40%">
            <MuiTypography align="right">Response within 2 business days</MuiTypography>
          </MuiBox>
        </MuiBox>
        <Formik
          initialValues={initialValues}
          validationSchema={technicalSupportValidationSchema}
          onSubmit={values => submitQuestion(values)}
        >
          {({
            values,
            errors,
            handleChange,
            handleBlur,
            isValid,
            handleSubmit,
            setFieldValue,
            dirty
          }) => {
            const length = values.issueDescription ? values.issueDescription.length : 0;

            return (
              <MuiContainer maxWidth="sm">
                <MuiBox display="flex" flexDirection="row">
                  <RequiredFieldsLegend />
                </MuiBox>
                <MuiBox pt={Spacing.small}>
                  <GlobalTextInput
                    label="First Name"
                    placeholder="Enter First Name"
                    value={values.firstName}
                    onChange={handleChange('firstName')}
                    showError={!!errors.firstName}
                    helperTextInfo={errors.firstName}
                    accessibilityLabel="First name text input"
                    required
                  />
                  <GlobalTextInput
                    label="Last Name"
                    placeholder="Enter Last Name"
                    value={values.lastName}
                    onChange={handleChange('lastName')}
                    showError={!!errors.lastName}
                    helperTextInfo={errors.lastName}
                    accessibilityLabel="Last name text input"
                    required
                  />
                  <GlobalTextInput
                    label="Preferred Phone"
                    placeholder="(888) 888 - 8888"
                    value={values.preferredPhone}
                    onChange={handleChange('preferredPhone')}
                    showError={!!errors.preferredPhone}
                    helperTextInfo={errors.preferredPhone}
                    accessibilityLabel="Preferred phone text input"
                    mask="phone"
                    required
                  />
                  <GlobalTextInput
                    label="Email"
                    placeholder="example@email.com"
                    value={values.email}
                    onChange={handleChange('email')}
                    showError={!!errors.email}
                    helperTextInfo={errors.email}
                    accessibilityLabel="Email text input"
                  />
                  <MuiBox pb={Spacing.small}>
                    <GlobalTextInput
                      aria-label="Issue text input"
                      label="Issue description"
                      variant="outlined"
                      placeholder={`Describe your issue, please include as much detail as possible such as:

        What you were trying to do?

        What happened?

        What device and/or browser were you using?`}
                      defaultValue=""
                      value={values.issueDescription}
                      onChange={e => {
                        const { value } = e.target;
                        setFieldValue('issueDescription', value);
                      }}
                      onBlur={handleBlur('issueDescription')}
                      showError={!!errors?.issueDescription}
                      helperTextInfo={errors?.issueDescription}
                      InputProps={{
                        inputProps: {
                          maxLength: 8192,
                          fieldHeight: '150px'
                        }
                      }}
                      rows={15}
                      multiline
                      required
                      fullWidth
                    />
                  </MuiBox>
                  <MuiBox pt={Spacing.small}>
                    <MuiTypography variant="body1" color={Color.gray}>
                      {length}/8192
                    </MuiTypography>
                  </MuiBox>
                </MuiBox>
                <MuiBox width="100%" p={Spacing.small} borderColor={Color.grayLight} borderTop={1}>
                  <MuiButton
                    data-testid="submit-button"
                    color="primary"
                    variant="contained"
                    fullWidth
                    disabled={!isValid || !dirty}
                    onClick={() => handleSubmit()}
                  >
                    Submit
                  </MuiButton>
                  <MuiButton
                    data-testid="cancel-btn"
                    fullWidth
                    variant="text"
                    onClick={() => {
                      technicalSupportFormCanceled();
                      history.goBack();
                    }}
                  >
                    Cancel
                  </MuiButton>
                </MuiBox>
              </MuiContainer>
            );
          }}
        </Formik>
      </MuiContainer>
    </MuiBox>
  );
};

const mapStateToProps = (state: RootState) => ({
  profileEmails: profileEmailsSelector(state),
  consumerId: consumerIdSelector(state),
  profileConsumer: profileConsumerSelector(state),
  guarantorPhones: profilePhonesSelector(state),
  isSendingIncident: serviceNowSendingSelector(state)
});

export default connect(mapStateToProps)(TechnicalSupportScreen as ComponentType);
