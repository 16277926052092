import { MuiBox, MuiTypography } from 'theme/material-ui';
import React from 'react';
import { FontWeight } from 'modules/styles/variables';

const StatusDescriptions = [
  { name: 'Achieved', description: 'A proposed goal was achieved.' },
  {
    name: 'In Progress',
    description:
      'The goal is being sought but has not yet been reached. (Also applies if goal was reached in the past but there has been regression and goal is being sought again.)'
  }
];

export const GoalStatusDescriptions = () => {
  return (
    <MuiBox component="ul" p={2}>
      {StatusDescriptions.map(item => (
        <li key={item.name}>
          <MuiTypography color="primary" display="inline" fontWeight={FontWeight.semibold}>
            {item.name}
          </MuiTypography>

          <MuiTypography color="textPrimary" display="inline">
            {' '}
            - {item.description}
          </MuiTypography>
        </li>
      ))}
    </MuiBox>
  );
};
