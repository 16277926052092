import behavioralHealthImg from 'assets/ConnectCare/behavioral-health.jpg';
import primaryCareImg from 'assets/ConnectCare/primary-care.jpeg';
import startEVisitImg from 'assets/ConnectCare/start-e-visit.png';
import urgentCareImg from 'assets/ConnectCare/urgent-care.jpg';
import { ConnectCareTestMyComputerDialog } from 'components/ConnectCare';
import Screen from 'components/UI/Layout/Screen';
import SpinnerModal from 'components/UI/Spinner/SpinnerModal';
import { getUserAgent } from 'lib/browser';

import {
  behavioralHealthModalName,
  behavioralHealthSectionTitle,
  eVisitModalName,
  eVisitSectionTitle,
  primaryCareModalName,
  reArrangeSections,
  urgentCareModalName,
  urgentCareSectionTitle,
  virtualPrimaryCareSectionTitle
} from 'modules/constants/ConnectCare';
import React, { useEffect, useState } from 'react';
import Config from 'react-native-config';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import {
  currentLocationPathNameSelector,
  previousLocationPathNameSelector
} from 'store/router/selectors';
import { RootState } from 'store/types';
import { MuiBox, MuiContainer, MuiTypography } from 'theme/material-ui';
import { ConnectCareModal } from './ConnectCareModal';
import { ConnectCareSection } from './ConnectCareSection';

import { useEVisitProxyWarning } from 'screens/EVisits/dialogs/useEVisitProxyWarning';
import { isImpersonatingSelector } from 'store/account/selectors';
import { setActivityStatus } from 'store/amwell/actions';
import { ActivityStatus } from 'store/amwell/types';
import * as bookingActions from 'store/booking/actions';
import * as bookingSelectors from 'store/booking/selectors';
import * as bookingTypes from 'store/booking/types';
import * as findProviderActions from 'store/findProvider/actions';
import * as geoLocationActions from 'store/geolocation/actions';
import { geolocationCoordsSelector, geolocationDeniedSelector } from 'store/geolocation/selectors';
import { profileConsumerHasActiveSHInsuranceSelector } from 'store/profile/selectors';

import { EVISITS_ROUTES } from 'screens/EVisits/constants';
import analyticsService, { AMPLITUDE_EXPERIMENTS, AnalyticsEvent } from 'services/AnalyticsService';
import { ConnectCareSection as CCSection } from '../types';
import { Coordinate } from 'modules/types/common';

export interface Props extends RouteComponentProps {
  currentUrl: string;
  hasActiveSHInsurance: boolean;
  isProxyUser: boolean;
  patients: bookingTypes.Patient[];
  referringUrl: string;
  setActiveProviderSearchFilter: typeof findProviderActions.setActiveProviderSearchFilter;
  setActivityStatus: (activityStatus: ActivityStatus) => void;
  setPatient: typeof bookingActions.setPatient;
  userAgent: IUAParser.IResult;
  validateSelectedPatientInsurance: typeof bookingActions.validateSelectedPatientInsurance;
  geolocationCoords: Coordinate;
  geolocationDenied: boolean;
  getCurrentPosition: Function;
}

const hasName = (str: string | undefined, name: string) => {
  return str ? str.includes(name) : false;
};

export function ConnectCareLanding(props: Props) {
  const {
    currentUrl,
    hasActiveSHInsurance,
    history,
    isProxyUser,
    referringUrl,
    setActivityStatus,
    userAgent,
    geolocationCoords,
    geolocationDenied,
    getCurrentPosition
  } = props;

  const [isLoading, setIsLoading] = useState(false);
  const showEVisitProxyAlert = useEVisitProxyWarning({ setIsLoading });
  const brand = window.localStorage.getItem('ccBrand');

  const [openTMC, setOpenTMC] = useState(false);
  const [urgentModalOpen, setUrgentModalOpen] = useState(false);
  const [behavioralModalOpen, setBehavioralModalOpen] = useState(false);
  const [primaryCareModalOpen, setPrimaryCareModalOpen] = useState(false);
  const [eVisitModalOpen, setEVisitModalOpen] = useState(false);
  const [sections, setSections] = useState<CCSection[]>([]);

  const DEV = process.env.NODE_ENV === 'development';
  const isTMCMacEnabled = Config.TEST_MY_DEVICE_MAC === 'enabled';
  const isWindows = userAgent ? hasName(userAgent.os?.name, 'Windows') : false;
  const TMCRequired = isWindows ? true : isTMCMacEnabled;
  const isIE = userAgent ? hasName(userAgent.browser?.name, 'IE') : false;
  const [showFrontDoor, setShowFrontDoor] = useState(true);
  const [showEVisitInsurance, setShowEVisitInsurance] = useState(true);

  useEffect(() => {
    if (!geolocationDenied && !geolocationCoords) getCurrentPosition();
  }, []);

  useEffect(() => {
    const eVisitInsuranceFlag = analyticsService.fetchExperimentVariant(
      AMPLITUDE_EXPERIMENTS.E_VISIT_FRONT_DOOR_INSURANCE.flagKey
    );

    const evisitInsuranceFlagIsActive =
      eVisitInsuranceFlag?.value === AMPLITUDE_EXPERIMENTS.E_VISIT_FRONT_DOOR_INSURANCE.variantA;

    const frontDoorFlagActive = analyticsService.getExperimentStatus(
      AMPLITUDE_EXPERIMENTS.E_VISIT_FRONT_DOOR.flagKey,
      AMPLITUDE_EXPERIMENTS.E_VISIT_FRONT_DOOR.variantB
    );
    const npvfrontDoorIsActive = frontDoorFlagActive && Config.EVISIT_FRONTDOOR === 'enabled';
    setShowFrontDoor(npvfrontDoorIsActive);

    setShowEVisitInsurance(evisitInsuranceFlagIsActive && hasActiveSHInsurance);
  }, []);

  useEffect(() => {
    let aux = reArrangeSections(
      brand,
      [urgentCareImg, behavioralHealthImg, primaryCareImg, startEVisitImg],
      hasActiveSHInsurance,
      showEVisitInsurance
    );
    if (!showFrontDoor) {
      aux = aux?.filter(section => section?.key !== 'e-visit');
    }
    setSections(aux);
    window.localStorage.removeItem('ccBrand');
  }, [showFrontDoor]);

  useEffect(() => {
    const fetchPatients = () => {
      bookingActions.getPatients();
    };

    fetchPatients();
  }, []);

  const onEVisitFrontDoorClick = () => {
    if (isProxyUser) {
      return showEVisitProxyAlert();
    }
    analyticsService.logEvent(AnalyticsEvent.EVisitFrontDoorStart, {
      referringUrl,
      currentUrl
    });
    return history.push(EVISITS_ROUTES.FRONTDOOR_OVERVIEW);
  };

  const handlingStartClick = (url: string, title: string) => {
    if (title === urgentCareSectionTitle && isProxyUser) {
      setActivityStatus(ActivityStatus.PROXY_SWITCH);
    } else if (title === urgentCareSectionTitle && TMCRequired && !isIE) {
      setOpenTMC(true);
    } else if (title === urgentCareSectionTitle) {
      history.push(url);
    } else if (title === behavioralHealthSectionTitle) {
      history.push({
        pathname: '/u/get-care-now/booking/find-a-doctor',
        state: {
          searchTerm: 'practice_location=Intermountain+Connect+Care+-+Behavioral+Health'
        }
      });
    } else if (title === virtualPrimaryCareSectionTitle) {
      history.push({
        pathname: '/u/get-care-now/booking/find-a-doctor',
        state: {
          searchTerm: 'practice_location=Intermountain+Virtual+Primary+Care'
        }
      });
    } else if (title === eVisitSectionTitle) {
      onEVisitFrontDoorClick();
    }

    analyticsService.logEvent(AnalyticsEvent.ConnectCarePreVisitFormsStart, {
      referringUrl,
      currentUrl
    });
  };

  const handleOpenModal = (name: string) => {
    switch (name) {
      case urgentCareModalName:
        return setUrgentModalOpen(!urgentModalOpen);
      case behavioralHealthModalName:
        return setBehavioralModalOpen(!behavioralModalOpen);
      case primaryCareModalName:
        return setPrimaryCareModalOpen(!primaryCareModalOpen);
      case eVisitModalName:
        return setEVisitModalOpen(!eVisitModalOpen);
      default:
    }
    return null;
  };

  const selectModalToOpen = (modalName: string): boolean => {
    switch (modalName) {
      case 'urgent-care-modal':
        return urgentModalOpen;
      case 'behavioral-health-modal':
        return behavioralModalOpen;
      case 'virtual-primary-care-modal':
        return primaryCareModalOpen;
      default:
        return eVisitModalOpen;
    }
  };

  return (
    <Screen>
      <SpinnerModal isLoading={isLoading} />
      <MuiContainer maxWidth="lg">
        {DEV ? (
          <MuiBox display="flex" justifyContent="center" p={2}>
            <MuiTypography>
              <Link to="/u/get-care-now/connect-care/navigator">Click here</Link> to access
              developer utility page
            </MuiTypography>
          </MuiBox>
        ) : null}

        {sections.map(({ modal, ...rest }) =>
          rest.showCard ? (
            <MuiContainer key={rest.key}>
              <ConnectCareSection
                testId={`${rest.key}-test`}
                {...rest}
                connectCareTypeClick={() => handlingStartClick(rest.redirectTo.web, rest.title)}
                linkHandlerClick={() => handleOpenModal(modal.name)}
              />
              <ConnectCareModal
                key={modal.name}
                open={selectModalToOpen(modal.name)}
                testId={`${modal.name}-test`}
                onClick={() => handleOpenModal(modal.name)}
                {...modal}
              />
              <ConnectCareTestMyComputerDialog
                open={openTMC}
                onClose={() => setOpenTMC(false)}
                onDone={() =>
                  TMCRequired && history.push('/u/get-care-now/connect-care/form/location')
                }
              />
            </MuiContainer>
          ) : null
        )}
      </MuiContainer>
    </Screen>
  );
}

const mapStateToProps = (state: RootState) => ({
  currentUrl: currentLocationPathNameSelector(state),
  hasActiveSHInsurance: profileConsumerHasActiveSHInsuranceSelector(state),
  isProxyUser: isImpersonatingSelector(state),
  patients: bookingSelectors.patientsDataSelector(state),
  referringUrl: previousLocationPathNameSelector(state),
  userAgent: getUserAgent(),
  geolocationCoords: geolocationCoordsSelector(state),
  geolocationDenied: geolocationDeniedSelector(state)
});

const mapDispatchToProps = {
  setActivityStatus: (status: ActivityStatus) => setActivityStatus(status),
  setPatient: bookingActions.setPatient,
  validateSelectedPatientInsurance: bookingActions.validateSelectedPatientInsurance,
  getCurrentPosition: geoLocationActions.getCurrentPosition
};

export default connect(mapStateToProps, mapDispatchToProps)(ConnectCareLanding);
