import React from 'react';
import { Color } from 'modules/styles/colors';
import { SvgProps } from 'components/UI/Svg/Svg';

export default (props: SvgProps | undefined) => {
  return {
    svg: (
      <g>
        <path
          fill={
            props?.active
              ? 'url(#a)'
              : props?.fill
              ? props.fill
              : props?.disabled
              ? Color.grayHue5
              : Color.primary
          }
          fillRule="evenodd"
          d="M2.25 1.5a.75.75 0 0 0-.75.75v15a.75.75 0 0 0 .75.75h3a.75.75 0 0 1 .75.75v3l4.8-3.6a.75.75 0 0 1 .45-.15h10.5a.75.75 0 0 0 .75-.75v-15a.75.75 0 0 0-.75-.75H2.25ZM.659.659A2.25 2.25 0 0 1 2.25 0h19.5A2.25 2.25 0 0 1 24 2.25v15a2.25 2.25 0 0 1-2.25 2.25H11.5l-5.8 4.35a.75.75 0 0 1-1.2-.6V19.5H2.25A2.25 2.25 0 0 1 0 17.25v-15C0 1.653.237 1.081.659.659ZM4.5 7.5a.75.75 0 0 1 .75-.75h13.5a.75.75 0 0 1 0 1.5H5.25a.75.75 0 0 1-.75-.75Zm0 4.5a.75.75 0 0 1 .75-.75h10.5a.75.75 0 0 1 0 1.5H5.25A.75.75 0 0 1 4.5 12Z"
          clipRule="evenodd"
        />
        <defs>
          <linearGradient id="a" x1={0} x2={24} y1={12} y2={12} gradientUnits="userSpaceOnUse">
            <stop offset={0.084} stopColor="#FF0048" />
            <stop offset={0.386} stopColor="#DF36FF" />
            <stop offset={0.98} stopColor="#4A00E2" />
          </linearGradient>
        </defs>
      </g>
    ),
    viewBox: '0 0 24 24'
  };
};
