import Section from 'components/UI/Layout/Section';
import Panel from 'components/UI/Panel/Panel';
import Heading from 'components/UI/Typography/Heading';
import { Color } from 'modules/styles/colors';
import { BorderRadius, Spacing } from 'modules/styles/variables';
import styled, { css } from 'styled-components';
import { MuiTableCell, MuiContainer, MuiBox, dfdDefaultTheme } from 'theme/material-ui';
import Typography from 'components/UI/Typography';

const raised = css`
  box-shadow: 0px 1px 0.2 ${Color.black};
`;

export const VisitStyledPanel = styled(Panel)`
  width: 90%;
  padding: 0;
  background-color: transparent;
  border: 0;
`;

export const VisitSection = styled(Section)`
  align-items: center;
  margin-top: -30px;
  padding: 0;
`;

export const StyledHeading = styled(Heading)`
  color: ${Color.foreColor};
  max-width: 180px;
  text-align: center;
  line-height: 30px;
  margin-top: 30px;
  margin-bottom: 15px;
`;

export const TitleHeading = styled(StyledHeading)`
  padding-bottom: 50px;
`;

export const OutlineAction = styled.div`
  padding: ${Spacing.large}px;
  border-color: ${Color.grayLight3};
  background-color: ${Color.grayLight3};
  border-width: 1;
  border-radius: ${BorderRadius.base};
  flex-direction: row;
  align-items: center;
  justify-content: center;

  ${raised};
`;

interface TableCellProps {
  active?: boolean;
}

export const TableCell = styled(MuiTableCell)`
  && {
    border-bottom: none;
    padding-bottom: 0px;
    @media (max-width: 375px) {
      padding-left: 3px;
      padding-right: 3px;
    }
    color: ${({ active }: TableCellProps) => (active ? Color.primary : Color.black)};
  }
`;

export const TableCellAlt = styled(MuiTableCell)`
  && {
    color: ${Color.textLight};
    padding: 0;
    font-weight: 400;
  }
`;

export const Container = styled(MuiContainer)`
  && {
    margin-top: 8px;
    padding: 0;
  }
`;

export const StackContainer = styled(MuiBox)`
  display: flex;
  flex-direction: row;
  ${dfdDefaultTheme.breakpoints.down('md')} {
    flex-direction: column-reverse;
  }
`;

interface ClampedTextProps {
  numberoflines: number;
}

export const ClampedText = styled(MuiBox)`
  width: 160px;
  font-size: 14px;
  color: ${Color.textDark};
  display: -webkit-box;
  -webkit-line-clamp: ${({ numberoflines = 3 }: ClampedTextProps) => numberoflines};
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const EllipsedText = styled(MuiBox)`
  display: inline-block;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
`;

export const SeeAllLink = styled(Typography)`
  color: ${Color.link};
  flex-shrink: 0;
  align-self: flex-end;
  cursor: pointer;
`;

export const TestResultContent = styled(MuiBox)`
  padding: 50px 16px;

  ${dfdDefaultTheme.breakpoints.up('md')} {
    padding: 50px 10%;
  }
`;

export const AnalyteTitle = styled(MuiBox)`
  font-size: 16px;

  ${dfdDefaultTheme.breakpoints.up('md')} {
    font-size: 20px;
  }
`;
