import { Alert } from 'components/Alert';
import SpacedSpinner from 'components/UI/Spinner/SpacedSpinner';
import SvgButton from 'components/UI/Svg/SvgButton';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import analyticsService, { AmplitudeEventData, AnalyticsEvent } from 'services/AnalyticsService';
import { Action } from 'modules/types/common';
import { getTermsAndConditions, setAcceptTemporaryTC } from 'store/global/actions';
import {
  TermsAndConditions as TermsAndConditionsObject,
  TermsAndConditionsType
} from 'store/global/reducers';
import * as globalSelectors from 'store/global/selectors';
import { getProfile, updateConsumerProfile } from 'store/profile/actions';
import * as profileSelectors from 'store/profile/selectors';
import { Consumer } from 'store/profile/types';
import {
  currentLocationPathNameSelector,
  previousLocationPathNameSelector
} from 'store/router/selectors';
import { RootState } from 'store/types';
import {
  MuiAlert,
  MuiAlertTitle,
  MuiBox,
  MuiButton,
  MuiDialogContent,
  MuiDialogTitle
} from 'theme/material-ui';
import { oc } from 'ts-optchain';
import { StyledMuiDialog, StyledMuiDialogActions } from './styled';
import {
  mapTermsAndConditionsDetails,
  mapTermsAndConditionsTypeToExternalAcceptanceType
} from './variables';

export interface Props {
  consumer: Consumer;
  getTCs: Function;
  isFetching: boolean;
  onClick?: Function;
  open?: boolean;
  close?: boolean;
  setAcceptTempTCs: (externalType: string) => {};
  termsAndConditions?: TermsAndConditionsObject;
  type: TermsAndConditionsType | undefined;
  updateProfile: Function;
  currentRouteName?: string;
  previousRouteName?: string;
  forceAcceptGeneralLegacyOnboarding?: boolean;
  acceptAction?: () => void;
  declineAction?: () => void;
  fetchProfile: typeof getProfile;
  isProfileLoading: boolean;
}

const TermsAndConditions = ({
  consumer,
  getTCs,
  isFetching,
  onClick,
  open,
  close,
  setAcceptTempTCs,
  termsAndConditions,
  type,
  updateProfile,
  currentRouteName,
  previousRouteName,
  forceAcceptGeneralLegacyOnboarding,
  acceptAction,
  declineAction,
  fetchProfile,
  isProfileLoading
}: Props) => {
  const history = useHistory();
  const termsDetails = mapTermsAndConditionsDetails(type, termsAndConditions);

  const data: AmplitudeEventData = {
    currentUrl: currentRouteName,
    referringUrl: previousRouteName
  };

  useEffect(() => {
    if (!termsDetails) {
      getTCs();
    }
  }, []);

  const profileErrorAlert = () => {
    return Alert.alert('Unable to update your profile.', 'Please try again or contact support.');
  };

  const handleAcceptGeneralOnboarding = () => {
    const termAcceptance = {
      type,
      version: type && oc(termsAndConditions)[type].version('error retrieving version'),
      date: new Date()
    };

    updateProfile(
      (consumerData: Consumer) => ({
        ...consumerData,
        termAcceptance
      }),
      ['TermAcceptance']
    ).then((res: Action) => {
      if (res.error) {
        history.goBack();
        profileErrorAlert();
      } else {
        fetchProfile('', true);
      }
    });
    if (acceptAction) acceptAction();
    analyticsService.logEvent(AnalyticsEvent.DisclaimerAccepted);
  };

  const handleAccept = () => {
    analyticsService.logEvent(AnalyticsEvent.DisclaimerAccepted, data);

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const externalType = type ? mapTermsAndConditionsTypeToExternalAcceptanceType(type)! : '';
    setAcceptTempTCs(externalType);

    const termAcceptance = {
      version: type && oc(termsAndConditions)[type].version('error retrieving version'),
      date: new Date(),
      type: externalType
    };

    const updatedExternalTermsArray = oc(consumer)
      .externalTermsAcceptance([])
      .concat(termAcceptance);

    updateProfile(
      (consumerData: Consumer) => ({
        ...consumerData,
        externalTermsAcceptance: updatedExternalTermsArray
      }),
      ['ExternalTermsAcceptance']
    ).then((res: Action) => {
      if (res.error) {
        handleDecline();
        profileErrorAlert();
      }
    });

    if (acceptAction) {
      acceptAction();
    }

    analyticsService.logEvent(AnalyticsEvent.DisclaimerAccepted);
  };

  const handleDecline = () => {
    if (
      type === TermsAndConditionsType.E_VISIT_PRIVACY_NOTICE ||
      type === TermsAndConditionsType.E_VISIT_CONSENT_TO_TREAT ||
      type === TermsAndConditionsType.E_VISIT_TERMS_OF_USE
    ) {
      declineAction?.();
      return;
    }

    if (
      type !== TermsAndConditionsType.PROVIDER_SEARCH &&
      type !== TermsAndConditionsType.GENERAL
    ) {
      history.push('/u/get-care-now');
    }

    if (close) {
      declineAction?.();
      return;
    }

    if (declineAction) {
      history.goBack();
    }

    // Disclaimer only when we didn't come from profile
    if (type !== TermsAndConditionsType.GENERAL) {
      analyticsService.logEvent(AnalyticsEvent.DisclaimerDeclined, data);
    }

    if (type === TermsAndConditionsType.GENERAL && onClick) {
      onClick();
    }
  };

  return isFetching || isProfileLoading ? (
    <SpacedSpinner />
  ) : (
    <StyledMuiDialog
      className="web"
      open={open || false}
      onClose={handleDecline}
      scroll="paper"
      maxWidth="md"
    >
      <MuiDialogTitle style={{ textAlign: 'center' }}>
        Terms of Use
        <MuiBox
          position="absolute"
          top={0}
          right="-10px"
          bottom={0}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          {forceAcceptGeneralLegacyOnboarding ? null : (
            <SvgButton
              appearance="transparent"
              set="material"
              name="close"
              accessibilityLabel="Close Dialog"
              onClick={handleDecline}
            />
          )}
        </MuiBox>
      </MuiDialogTitle>
      <MuiDialogContent dividers>
        {termsDetails ? (
          <div
            dangerouslySetInnerHTML={{
              __html: termsDetails.content
            }}
          />
        ) : (
          <MuiAlert severity="error">
            <MuiAlertTitle>Error</MuiAlertTitle>
            Unable to locate Terms of Use data. Please refresh the page and try again.
          </MuiAlert>
        )}
      </MuiDialogContent>
      <StyledMuiDialogActions>
        {type !== TermsAndConditionsType.PROVIDER_SEARCH &&
        type !== TermsAndConditionsType.GENERAL &&
        type !== TermsAndConditionsType.E_VISIT_PRIVACY_NOTICE &&
        type !== TermsAndConditionsType.E_VISIT_TERMS_OF_USE &&
        type !== TermsAndConditionsType.E_VISIT_CONSENT_TO_TREAT ? (
          <MuiButton onClick={handleDecline} color="primary" size="large">
            Decline
          </MuiButton>
        ) : null}
        {(type !== TermsAndConditionsType.GENERAL &&
          type !== TermsAndConditionsType.E_VISIT_PRIVACY_NOTICE &&
          type !== TermsAndConditionsType.E_VISIT_CONSENT_TO_TREAT &&
          type !== TermsAndConditionsType.E_VISIT_TERMS_OF_USE) ||
        forceAcceptGeneralLegacyOnboarding ? (
          <MuiButton
            onClick={
              forceAcceptGeneralLegacyOnboarding ? handleAcceptGeneralOnboarding : handleAccept
            }
            color="primary"
            variant="contained"
            size="large"
            disabled={!termsDetails}
          >
            Agree
          </MuiButton>
        ) : null}
      </StyledMuiDialogActions>
    </StyledMuiDialog>
  );
};

const mapDispatchToProps = {
  getTCs: getTermsAndConditions,
  setAcceptTempTCs: setAcceptTemporaryTC,
  updateProfile: updateConsumerProfile,
  fetchProfile: getProfile
};

const mapStateToProps = (state: RootState) => ({
  consumer: profileSelectors.profileConsumerSelector(state),
  isFetching: globalSelectors.globalIsFetchingSelector(state),
  termsAndConditions: globalSelectors.termsAndConditionsSelector(state),
  currentRouteName: currentLocationPathNameSelector(state),
  previousRouteName: previousLocationPathNameSelector(state),
  isProfileLoading: profileSelectors.profileIsFetchingSelector(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(TermsAndConditions);
