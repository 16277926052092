import React from 'react';

export default {
  svg: (
    <g>
      <path d="M164.2 60.4v15.8h32.9v65.3h-32.9v15.8H212V60.4zM136.3 144v9.3h-10.8V144c-13.3-1.4-22.2-8.1-23.7-20.2l12.7-.4c1.8 7.3 8 11.1 16.6 11.1 7.7 0 14.1-4.2 14.1-10.2 0-5.8-3.5-8.6-11.1-10.4l-12.2-2.9c-10.9-2.5-17.8-8.6-17.9-19.1-.1-11.5 9-19.6 21.9-21.3v-10h10.8v10.1c12.5 2 20 10.6 20.1 20.7l-12.4.4c-1.1-7.4-4.8-11.6-13.1-11.6-8.1 0-14.3 3.9-14.3 10.4 0 4.7 2.7 7 11.3 9.4l11.8 3.1c12.8 3.3 17.9 11.1 17.9 21.2.1 10.5-8.4 18.1-21.7 19.7zM80.3 60.4c2 4.9 3.1 10.2 3.1 15.8H97V60.4H80.3zM62.9 112.5c-4.5 2.7-9.5 4.7-14.9 5.6v39.2h49v-15.8H62.9v-29zM40.8 111.1c-19.2 0-34.9-15.7-34.9-34.9s15.7-34.9 34.9-34.9S75.7 57 75.7 76.2 60 111.1 40.8 111.1zm0-63c-15.4 0-28.1 12.7-28.1 28.1s12.7 28.1 28.1 28.1S69 91.6 69 76.2 56.2 48.1 40.8 48.1z" />
      <path d="M40.8 88.8c-2.1 0-3.7 1.7-3.7 3.7s1.7 3.7 3.7 3.7c2.1 0 3.7-1.7 3.7-3.7s-1.6-3.7-3.7-3.7zM40.8 57.5c-1.9 0-3.4 1.5-3.4 3.4v20.8c0 1.9 1.5 3.4 3.4 3.4s3.4-1.5 3.4-3.4V60.9c0-1.9-1.5-3.4-3.4-3.4z" />
    </g>
  ),
  viewBox: '0 0 300 240'
};
