import styled from 'styled-components';
import { MuiGrid, MuiBox } from 'theme/material-ui';

export const StarMuiGrid = styled(MuiGrid)`
  width: 80px;
  align-items: center;
  display: flex;
`;

export const StarMuiBox = styled(MuiBox)`
  width: 100px;
`;
