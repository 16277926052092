import React from 'react';
import { useSelector } from 'react-redux';
import { FiltersStructure } from 'lib/hooks/AuditLogFilters/types/filter';
import { FilterDrawer } from 'components/FilterDrawer';
import { auditActionsDataSelector } from 'store/audit/selectors';
import { viewableCount } from '../constants';
import { FormCheckboxList } from '../shared';
import { useAuditLogFilters } from 'lib/hooks/AuditLogFilters/useAuditLogFilters';

export type ActionsFilterProps = FiltersStructure['actions'] & {
  defaultExpanded: boolean;
};

function ActionsFilter({ title, values, defaultExpanded }: ActionsFilterProps) {
  const actions = useSelector(auditActionsDataSelector);
  const filterContext = useAuditLogFilters();
  return (
    <FilterDrawer.Panel
      expandable={actions.length}
      title={title}
      defaultExpanded={defaultExpanded}
      filtersCount={filterContext?.values?.facets?.actions?.length}
    >
      <FormCheckboxList initialCount={viewableCount} list={Object.values(values(actions))} />
    </FilterDrawer.Panel>
  );
}

export default ActionsFilter;
