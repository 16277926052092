import React, { useState } from 'react';
import { TouchableOpacity } from 'react-native';

import { MuiBox, MuiCard, MuiCardContent, MuiGrid, MuiTypography } from 'theme/material-ui';

import VisitPayWebView from 'screens/Billing/VisitPayWebView';
import { VISIT_PAY_FAMILY_FINANCE } from 'screens/Billing/constants';

import { MuiGridPanel } from '../styled';

export default function ManageFamilyFinance() {
  const [goToVisitPay, setGoToVisitPay] = useState(false);

  const showVisitpay = () => {
    setGoToVisitPay(true);
  };

  return (
    <MuiBox>
      <MuiCard>
        <MuiCardContent data-testid="profile-card">
          <MuiGrid container spacing={3}>
            <MuiGridPanel item>
              <TouchableOpacity onPress={showVisitpay}>
                <MuiTypography color="primary">Manage Family</MuiTypography>
              </TouchableOpacity>
            </MuiGridPanel>
          </MuiGrid>
        </MuiCardContent>
      </MuiCard>
      {goToVisitPay && (
        <VisitPayWebView closePopup={setGoToVisitPay} uri={VISIT_PAY_FAMILY_FINANCE} />
      )}
    </MuiBox>
  );
}
