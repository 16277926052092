import Config from 'react-native-config';

export const FA_FORM_SECTIONS = [
  { key: 1, label: 'Basic Info', routeName: 'FABasicInfo', answerCount: 25 },
  { key: 2, label: 'Household', routeName: 'FAHousehold', answerCount: 25 },
  {
    key: 3,
    label: Config.FA_SHOW_ASSETS_AND_ASSISTANCE === 'enabled' ? 'Assets' : 'Other Funding',
    routeName: 'BILLING_ROOT',
    answerCount: 25
  },
  { key: 4, label: 'Bills', routeName: 'FABills', answerCount: 25 }
];

export const PATIENT_INFO = [
  { key: 1, label: 'First Name', field: 'patientFirstName', value: '000-00000' },
  { key: 2, label: 'Middle Initial', field: 'patientMiddle', value: '000-00000' },
  { key: 3, label: 'Last Name', field: 'patientLastName', value: '000-00000' },
  { key: 4, label: 'Social Security Number', field: 'patientSSN', value: '000-00000' },
  { key: 5, label: 'Birth Date', field: 'patientBirthDate', value: '000-00000' }
];

export const HOUSEHOLD_INFO = [
  { key: 1, label: 'Marital Status', field: 'maritalStatus', value: '000-00000' },
  { key: 2, label: 'Spouse Last Name', field: 'spouseLastName', value: '000-00000' },
  { key: 3, label: 'Spouse First Name', field: 'spouseFirstName', value: '000-00000' },
  { key: 4, label: 'Middle Initial', field: 'spouseMiddle', value: '000-00000' },
  { key: 5, label: 'Birth Date', field: 'spouseBirthDate', value: '000-00000' },
  { key: 6, label: 'Social Security Number', field: 'spouseSSN', value: '000-00000' },
  { key: 7, label: 'Spouse Home Phone', field: 'spouseHomePhone', value: '000-00000' },
  { key: 8, label: 'Spouse Work Phone', field: 'spouseWorkPhone', value: '000-00000' },
  { key: 9, label: 'Spouse Cell Phone', field: 'spouseCellPhone', value: '000-00000' },
  { key: 10, label: 'Spouse Employer Name', field: 'spouseEmployer', value: '000-00000' },
  { key: 11, label: 'Members', field: 'members', value: '000-00000' },
  { key: 13, label: 'Employment Income (Gross)', field: 'grossIncome', value: '000-00000' },
  { key: 14, label: 'Pension', field: 'pension', value: '000-00000' },
  { key: 15, label: 'Spouse Amount', field: 'spouseIncome', value: '000-00000' },
  { key: 16, label: 'Child support', field: 'childSupport', value: '000-00000' },
  { key: 17, label: 'Alimony', field: 'alimony', value: '000-00000' },
  { key: 18, label: 'Grants/Scholarships', field: 'grants', value: '000-00000' },
  { key: 19, label: 'Grant Period', field: 'grantPeriod', value: '000-00000' },
  { key: 20, label: 'Other Income', field: 'otherIncome', value: '000-00000' },
  { key: 21, label: 'Other Income Source', field: 'otherIncomeSource', value: '000-00000' },
  { key: 22, label: 'Other Period', field: 'otherPeriod', value: '000-00000' }
];
export const HOUSEHOLD_MEMBERS_INFO = [
  { key: 1, label: 'Name', field: 'name', value: '000-00000' },
  { key: 4, label: 'Birth Date', field: 'birthDate', value: '2019-08-10' },
  { key: 5, label: 'Relationship', field: 'relationship', value: '000-00000' }
];

export const ASSETS_INFO = [
  { key: 1, label: 'Cash Institution', field: 'cashInstitution', value: '000-00000' },
  { key: 2, label: 'Cash', field: 'cash', value: '000-00000' },
  { key: 3, label: 'Saving Institution', field: 'savingInstitution', value: '000-00000' },
  { key: 4, label: 'Saving', field: 'saving', value: '000-00000' },
  { key: 5, label: 'Checking Institution', field: 'checkingInstitution', value: '000-00000' },
  { key: 6, label: 'Checking', field: 'checking', value: '000-00000' },
  { key: 7, label: 'Stocks Institution', field: 'stocksInstitution', value: '000-00000' },
  { key: 8, label: 'Stocks and/or bonds', field: 'stocks', value: '000-00000' },
  { key: 9, label: 'Has Medicare', field: 'hasMedicare', value: '000-00000' },
  { key: 10, label: '401k Institution', field: 'k401Institution', value: '000-00000' },
  { key: 11, label: '401k', field: 'k401', value: '000-00000' },
  { key: 12, label: 'IRA Institution', field: 'iraInstitution', value: '000-00000' },
  { key: 13, label: 'IRA', field: 'ira', value: '000-00000' },
  { key: 14, label: 'Has Insured', field: 'hasInsured', value: '000-00000' },
  { key: 15, label: 'Insurance Company', field: 'insuranceCompany', value: '000-00000' },
  { key: 16, label: 'Has Other Insured', field: 'hasOtherInsured', value: '000-00000' },
  { key: 17, label: 'Other Company', field: 'otherCompany', value: '000-00000' },
  { key: 18, label: 'Has Flex', field: 'hasFlex', value: '000-00000' },
  { key: 19, label: 'Flex Balance', field: 'flexBalance', value: '000-00000' },
  { key: 20, label: 'Has Reimburse', field: 'hasReimburse', value: '000-00000' },
  { key: 21, label: 'Was Denied Medicaid', field: 'hasDeny', value: '000-00000' },
  { key: 22, label: 'Has State Assistance', field: 'hasStateAst', value: '000-00000' },
  { key: 23, label: 'Has Cobra', field: 'hasCobra', value: '000-00000' },
  { key: 24, label: 'Has Family/Church Assistance', field: 'hasAssistance', value: '000-00000' },
  { key: 25, label: 'Other Situation', field: 'otherSituation', value: '000-0000' }
];
export const BILLS_INFO = [
  { key: 1, label: 'Account Number', field: 'accountNbr', value: '000-00000' },
  { key: 2, label: 'Name of Care Facility', field: 'provider', value: '000-00000' },
  { key: 3, label: 'Account Balance', field: 'balance', value: '000-00000' }
];
export const RESPONSIBLE_PARTY = [
  { key: 1, label: 'Relationship to Patient', field: 'relationship', value: '000-00000' },
  { key: 2, label: 'Explain Relationship', field: 'relationshipExplain', value: '000-00000' },
  { key: 3, label: 'First Name', field: 'guarantorFirstName', value: '000-00000' },
  { key: 4, label: 'Middle Initial', field: 'guarantorMiddle', value: '000-00000' },
  { key: 5, label: 'Last Name', field: 'guarantorLastName', value: '000-00000' },
  { key: 6, label: 'Social Security Number', field: 'guarantorSSN', value: '000-00000' },
  { key: 7, label: 'Birth Date', field: 'guarantorBirthDate', value: '06/28/2019' },
  { key: 8, label: 'Home Phone Number', field: 'homePhone', value: '000-00000' },
  { key: 9, label: 'Work Phone Number', field: 'workPhone', value: '000-00000' },
  { key: 9, label: 'Cell Phone Number', field: 'cellPhone', value: '000-00000' },
  { key: 11, label: 'Address', field: 'addressLineOne', value: '000-00000' },
  { key: 12, label: 'Apt,Suite,Etc.', field: 'addressLineTwo', value: '000-00000' },
  { key: 13, label: 'City', field: 'city', value: '000-00000' },
  { key: 14, label: 'State', field: 'state', value: '000-00000' },
  { key: 15, label: 'Zip/Postal Code', field: 'zip', value: '000-00000' },
  { key: 16, label: 'Name of Employee', field: 'employer', value: '000-00000' },
  { key: 17, label: 'Time at current address', field: 'addressLength', value: '000-00000' },
  { key: 18, label: 'Previous Address', field: 'extra1AddressLineOne', value: '000-00000' },
  {
    key: 19,
    label: 'Previous Apt, Suite, Etc.',
    field: 'extra1AddressLineTwo',
    value: '000-00000'
  },
  { key: 20, label: 'Previous Address City', field: 'extra1AddressCity', value: '000-00000' },
  { key: 21, label: 'Previous Address Zip', field: 'extra1AddressZip', value: '000-00000' },
  { key: 22, label: 'Previous Address State', field: 'extra1AddressState', value: '000-00000' },
  { key: 23, label: 'Previous Address From', field: 'extra1AddressFrom', value: '000-00000' },
  { key: 24, label: 'Previous Address To', field: 'extra1AddressTo', value: '000-00000' },
  { key: 25, label: 'Other Previous Address', field: 'extra2AddressLineOne', value: '000-00000' },
  {
    key: 26,
    label: 'Other Previous Apt, Suite, Etc.',
    field: 'extra2AddressLineTwo',
    value: '000-00000'
  },
  {
    key: 27,
    label: 'Other Previous Address City',
    field: 'extra2AddressCity',
    value: '000-00000'
  },
  { key: 28, label: 'Other Previous Address Zip', field: 'extra2AddressZip', value: '000-00000' },
  {
    key: 29,
    label: 'Other Previous Address State',
    field: 'extra2AddressState',
    value: '000-00000'
  },
  {
    key: 30,
    label: 'Other Previous Address From',
    field: 'extra2AddressFrom',
    value: '000-00000'
  },
  { key: 31, label: 'Other Previous Address To', field: 'extra2AddressTo', value: '000-00000' }
];

export const THANKYOU_MESSAGE = `Thank you for completing the application form. Your Application Number is: [APPLICATION_ID]

Please save this number and include your Application Number on all documents and correspondence with Intermountain related to financial assistance

As next steps, please submit the following verification documents via email or to the Intermountain facility where you had or plan to receive care for processing. Patients may not receive financial assistance if they do not complete the application process.
`;

export const FA_SUBMIT_INFO = [
  {
    title: 'Copies of your current federal tax return with all schedules, including W-2s',
    subItems: [
      {
        type: 'subItem',
        title:
          "If you don't have tax return documents, please submit the non-filing letter from the IRS"
      }
    ]
  },
  {
    title:
      'Household income verification (e.g., paycheck stubs for the past two pay periods OR your 3 most recent monthly bank statements).',
    subItems: [
      {
        title:
          "If you can't provide above information, please provide the following, as applicable:",
        subItems: [
          {
            title: 'Title 1 school assistance letter'
          },
          {
            title:
              'Support letter written by whomever is supporting you financially (Please note: This person will not be liable for your balance)'
          }
        ]
      }
    ]
  },
  {
    title: 'Please also provide following documents, as applicable:',
    subItems: [
      {
        title: 'Pension/retirement statement'
      },
      {
        title: 'Social security award letter'
      },
      {
        title: 'Unemployment award letter'
      },
      {
        title: 'Disability award letter'
      },
      {
        title: 'Denial letter from Medicaid'
      },
      {
        title: 'Child support award letter'
      },
      {
        title: 'Alimony award letter'
      }
    ]
  },
  {
    title: "If you don't have insurance, we will also require the following:",
    subItems: [
      {
        title: 'Medicaid denial letter'
      },
      {
        title:
          'Screening completed by an Eligibility Counselor at the Intermountain facility (while in-patient)'
      }
    ]
  },
  {
    title:
      'Please note: above list is not exhaustive and Intermountain will contact you via mail regarding specific verification information you are missing'
  }
];

export const FA_PREVIOUSLY_COMPLETED_BODY = `Thank you for completing the application form. Your Application Number is: [APPLICATION_ID]

Please save this number and include your Application Number on all documents and correspondence with Intermountain related to financial assistance.

Our records show that we have all the information required at this time and Intermountain will contact you via mail if it requires any updated verification information.

If your circumstances have changed (e.g., change in job or source of income) from when you last submitted your Financial Assistance application, please call Patient Financial Services at [PATIENT_FINANCIAL_SERVICE_NUMBER] to update your profile`;

export const NON_PRE_APPROVAL_INFO_BOX = `You have several options for sending in your verification documents. Please make sure to include your Financial Assistance application number on all communication:

Mailing Address
Intermountain Financial Assistance
PO Box 27327, Salt Lake city, Utah 84127

Visit your local Intermountain hospital or clinic where you receive services

Scan and email to financial.assistance@r1rcm.com`;
