import { Divider } from '@material-ui/core';
import { Alert } from 'components/Alert';
import { ConnectCareForm, ConnectCareLoading } from 'components/ConnectCare';
import { Svg } from 'components/UI/Svg';
import { login as amwellLogin } from 'modules/constants/amwell';
import { FontSize, FontWeight } from 'modules/styles/variables';
import { ConnectCareAccountLinkType as accountLinkType } from 'modules/constants/ConnectCare';
import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { AnyAction } from 'redux';
import { RootState } from 'store/types';
import { isImpersonatingSelector } from 'store/account/selectors';
import * as actions from 'store/amwell/actions';
import {
  MuiBox,
  MuiButton,
  MuiCheckbox,
  MuiContainer,
  MuiFormControlLabel,
  MuiIcon,
  MuiIconButton,
  MuiPaper,
  MuiTypography
} from 'theme/material-ui';
import * as Yup from 'yup';
import { OrBox } from '../styled';
import {
  currentLocationPathNameSelector,
  previousLocationPathNameSelector
} from 'store/router/selectors';
import analyticsService, { AmplitudeEventData, AnalyticsEvent } from 'services/AnalyticsService';
import { AppName, getAppName } from 'modules/utils/ConfigUtils';

export interface Props extends RouteComponentProps {
  currentUrl: string;
  getVisitToken: typeof actions.getVisitToken;
  login: typeof actions.login;
  loginWithToken: typeof actions.loginWithToken;
  referringUrl: string;
}

enum LoadingTypes {
  INIT = 0,
  LOGIN,
  TOKEN_LOGIN
}

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email(amwellLogin.EMAIL_VALIDATION.invalid)
    .required(amwellLogin.EMAIL_VALIDATION.required),
  password: Yup.string().required(amwellLogin.PASSWORD_VALIDATION.required)
});

const initialValues = {
  email: '',
  password: ''
};

const generateStatus = (error: any) => {
  if (error.response?.data) {
    return error.response?.data;
  }

  if (error.code) {
    return {
      errorCode: error.code,
      errorDescription: error.message
    };
  }

  if (error.errorCode && error.reason) {
    return {
      errorCode: error.errorCode,
      errorDescription: error.reason
    };
  }

  return null;
};

const generateErrorMessageLogin = (error: any) => {
  const status = generateStatus(error);

  let message;

  if (!status || status.errorCode === 'AWR012') {
    return message;
  }

  switch (status.errorCode) {
    case 'AWR013':
      message = amwellLogin.INVALID_COMBO_ERROR;
      break;
    case 'Incorrect credentials':
      message = amwellLogin.INVALID_USER_ERROR;
      break;
    default:
      message = amwellLogin.LINK_ERROR;
      break;
  }

  return message;
};

const generateErrorMessageContinue = (error: any) => {
  const status = generateStatus(error);

  let message;

  if (!status || status.errorCode === 'AWR012') {
    return message;
  }

  switch (status.errorCode) {
    case 'AWR022':
      message = amwellLogin.EXISTING_EMAIL_ERROR;
      break;
    case 'AWR025':
      message = amwellLogin.EXISTING_USER_ERROR;
      break;
    default:
      message = {
        title: amwellLogin.GENERAL_ERROR.title,
        subtitle: status.errorDescription.split('_').join(' '),
        severity: 'error' as 'error'
      };
      break;
  }

  return message;
};

export function ConnectCareLogin({
  currentUrl,
  getVisitToken,
  login,
  loginWithToken,
  history,
  referringUrl
}: Props) {
  // Sets the name of the action loadingType
  const [loadingType, setLoadingType] = useState<LoadingTypes | null>(LoadingTypes.INIT);
  const [showPassword, setShowPassword] = useState(false);
  const [buttonActive, setButtonActive] = useState(false);
  const loginLoading = loadingType === LoadingTypes.LOGIN;
  const isProxyUser = useSelector(isImpersonatingSelector);
  const path = '/u/get-care-now/connect-care/form/location';

  const eventData = (type = accountLinkType.AUTOMATIC): AmplitudeEventData => ({
    currentUrl,
    referringUrl,
    accountLinkType: type
  });

  const manualLogin = async (values: typeof initialValues) => {
    const resLogin: AnyAction = await login(values);

    if (resLogin?.error) {
      Alert.alert(amwellLogin.MANUAL_LOGIN_ERROR.title, amwellLogin.MANUAL_LOGIN_ERROR.subtitle);
    } else {
      history.push(path);
      analyticsService.logEvent(
        AnalyticsEvent.ConnectCareAccountCreation,
        eventData(accountLinkType.MANUAL)
      );
    }
  };

  // TODO: This should be out of the component scope in the actions
  const tokenLogin = async (
    params: Parameters<typeof actions.getVisitToken>[0],
    loginType: accountLinkType
  ) => {
    const resToken: AnyAction = await getVisitToken(params);

    let error = resToken?.error;
    const payload = resToken?.payload;
    const token = payload?.data?.visitToken;
    if (error || !token) {
      // Joining accounts did not succeed.
      setLoadingType(null);
    } else {
      // Try login with a new token from joined account
      const resLogin: AnyAction = await loginWithToken({ visitToken: token });
      error = resLogin?.error;
      setLoadingType(null);

      if (!error) {
        history.replace(path);
        analyticsService.logEvent(AnalyticsEvent.ConnectCareAccountCreation, eventData(loginType));
      }
    }

    if (error) {
      let message;

      if (params.possibleCCEmailAddr || params.ccpassword) {
        message = generateErrorMessageLogin(error);
      } else {
        message = generateErrorMessageContinue(error);
      }

      if (message) {
        if (message === amwellLogin.LINK_ERROR) {
          Alert.alert(message.title, message.subtitle, [
            { text: 'Cancel' },
            {
              text: 'Proceed',
              onPress: () => {
                setLoadingType(LoadingTypes.LOGIN);
                manualLogin({
                  email: params.possibleCCEmailAddr as string,
                  password: params.ccpassword as string
                });
              }
            }
          ]);
        } else {
          Alert.alert(message.title, message.subtitle);
        }
      }
    }
  };

  useEffect(() => {
    if (!isProxyUser) {
      tokenLogin({ dfdOnly: false }, accountLinkType.AUTOMATIC);
    }
  }, []);

  const onLoginClick = (values: typeof initialValues) => {
    setLoadingType(LoadingTypes.LOGIN);
    tokenLogin(
      {
        dfdOnly: false,
        possibleCCEmailAddr: values.email,
        ccpassword: values.password
      },
      accountLinkType.MANUAL
    );
  };

  const onContinueClick = () => {
    setLoadingType(LoadingTypes.TOKEN_LOGIN);
    tokenLogin({ dfdOnly: true }, accountLinkType.NEW);
  };

  if (loadingType === LoadingTypes.INIT) return <ConnectCareLoading />;

  return (
    <MuiBox p={6} data-testid="connect-care-login">
      <MuiContainer maxWidth="md">
        <ConnectCareForm
          isInitialValid={false}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onLoginClick}
        >
          {({ isValid }) => (
            <MuiBox data-testid="login" component={MuiPaper} p={3}>
              {/* Title */}
              <MuiBox py={10} textAlign="center">
                <Svg set="assets" name="ConnectCareLogo" width={250} height={32} />
              </MuiBox>

              <MuiBox textAlign="center" mb={2}>
                <MuiTypography fontSize={FontSize.large} fontWeight={FontWeight.bold} gutterBottom>
                  No Connect Care account
                </MuiTypography>
                <MuiBox pb={1}>
                  <MuiFormControlLabel
                    control={<MuiCheckbox color="primary" />}
                    label="I do not have a Connect Care account to link to"
                    labelPlacement="start"
                    onChange={(_, checked) => setButtonActive(checked)}
                  />
                </MuiBox>
                <MuiButton
                  disabled={!buttonActive}
                  variant="outlined"
                  color="primary"
                  onClick={onContinueClick}
                >
                  Continue with {getAppName(AppName.base)}
                </MuiButton>
              </MuiBox>

              <MuiBox textAlign="center" p={2}>
                <Divider />
                <OrBox>
                  <MuiTypography>OR</MuiTypography>
                </OrBox>
              </MuiBox>

              {/* Form */}
              <MuiBox mb={2}>
                <ConnectCareForm.TextField
                  label="Email"
                  placeholder="email@address.com"
                  required
                  type="email"
                  name="email"
                />
              </MuiBox>

              <MuiBox mb={7}>
                <ConnectCareForm.TextField
                  label="Password"
                  placeholder="password"
                  required
                  name="password"
                  type={showPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <MuiIconButton
                        size="small"
                        aria-label="show-password"
                        onMouseDown={e => e.preventDefault()}
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? (
                          <MuiIcon>visibility</MuiIcon>
                        ) : (
                          <MuiIcon>visibility_off</MuiIcon>
                        )}
                      </MuiIconButton>
                    )
                  }}
                />
              </MuiBox>

              {/* Action */}
              <MuiBox mb={1} textAlign="center">
                <MuiButton
                  disabled={!isValid}
                  loading={loginLoading}
                  fullWidth
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  Login
                </MuiButton>
              </MuiBox>
              <MuiBox mb={2} textAlign="center">
                <MuiButton
                  variant="text"
                  color="primary"
                  onClick={() =>
                    history.push('/u/get-care-now/connect-care/login/account-recovery')
                  }
                >
                  Forgot password?
                </MuiButton>
              </MuiBox>
            </MuiBox>
          )}
        </ConnectCareForm>
      </MuiContainer>
    </MuiBox>
  );
}

const mapStateToProps = (state: RootState) => ({
  currentUrl: currentLocationPathNameSelector(state),
  referringUrl: previousLocationPathNameSelector(state)
});

const mapDispatchToProps = {
  login: actions.login,
  getVisitToken: actions.getVisitToken,
  loginWithToken: actions.loginWithToken
};

export default connect(mapStateToProps, mapDispatchToProps)(ConnectCareLogin);
