import React from 'react';
import { useSelector } from 'react-redux';
import { currentAccountConsumerSelector } from 'store/account/selectors';
import { MuiTypography, MuiContainer, MuiBox } from 'theme/material-ui';
import { oc } from 'ts-optchain';
import CallToActionRow from 'components/common/CallToActionRow/CallToActionRow';
import { Color } from 'modules/styles/colors';
import { FontSize } from 'modules/styles/variables';

interface BannerProps {
  isBillingHome?: boolean;
  isDownloading?: boolean;
  onDownloadPress?: () => void;
  onTransmitPress?: () => void;
  onTimelineFilter?: () => void;
  disableGutters?: boolean;
}

const Banner = ({
  isBillingHome,
  isDownloading,
  onDownloadPress,
  onTimelineFilter,
  onTransmitPress,
  disableGutters
}: BannerProps) => {
  const consumer = useSelector(currentAccountConsumerSelector);
  const patientName = oc(consumer).displayName();
  const patientAge = oc(consumer).age();

  return (
    <MuiContainer
      {...{ disableGutters }}
      maxWidth={false}
      style={{ backgroundColor: Color.cobaltTint }}
    >
      <MuiContainer {...{ disableGutters }} maxWidth="lg">
        <MuiBox display="flex" alignItems="center" flexDirection="row">
          <MuiBox display="flex" alignItems="flex-end" py={2} flexDirection="row">
            <MuiBox>
              {patientName && (
                <MuiTypography data-testid="patient-name" color="textPrimary">
                  {patientName}
                </MuiTypography>
              )}
            </MuiBox>
            <MuiBox pl={1}>
              {patientAge && (
                <MuiTypography
                  data-testid="patient-age"
                  variant="subtitle2"
                  fontWeight="fontWeightBold"
                  color="textSecondary"
                  fontSize={FontSize.base - 1}
                >
                  {patientAge} year{patientAge > 1 ? 's' : null} old
                </MuiTypography>
              )}
            </MuiBox>
          </MuiBox>
          <MuiBox flex={1} display="flex" justifyContent="flex-end" alignItems="flex-end">
            <CallToActionRow
              isBillingHome={isBillingHome || false}
              onTransmitPress={onTransmitPress}
              onTimelineFilter={onTimelineFilter}
              onDownloadPress={onDownloadPress}
              isDownloading={isDownloading}
            />
          </MuiBox>
        </MuiBox>
      </MuiContainer>
    </MuiContainer>
  );
};

export default Banner;
