import styled from 'styled-components';
import { ZIndex } from 'modules/styles/variables';
import { Color } from 'modules/styles/colors';
import { StyledAppBar } from 'components/UI/Header/styled';
import DefaultText from 'components/UI/Typography/DefaultText';

export default styled(StyledAppBar)`
  &&& {
    z-index: ${ZIndex.navigation};
    width: 100%;
  }
`;

export const WebRTCTimer = styled(DefaultText)`
  font-weight: bold;
  color: ${Color.white};
`;
