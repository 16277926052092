import React from 'react';
import { MuiTypography, MuiBox } from 'theme/material-ui';
import { Spacing } from 'modules/styles/variables';
import { Color } from 'modules/styles/colors';
import { linkRegex, parseLineIntoTextPartsObject } from 'lib/messaging/rtf/parseHyperlink';
import { trRegex, tdRegex, tdTags } from 'lib/messaging/rtf/constants';
import { generateLinkComponent } from './linkComponent';

export function parseHTMLTableToReact(htmlString: string) {
  // Split the HTML string into individual rows using the <tr> tag
  const rows = htmlString?.match(trRegex);
  if (!rows) return null;

  const reactComponents = rows.map(row => {
    // Split each row into individual cells using the <td> tag
    const cells = row.match(tdRegex);

    const rowComponents = cells?.map(cell => {
      // Extract the content between <td> tags
      const content = cell.replace(tdTags, '');
      let component = content;
      if (linkRegex.test(content)) {
        const lineParts = parseLineIntoTextPartsObject(content);
        component = lineParts?.map(({ link, before, after }) =>
          generateLinkComponent(link, before, after)
        );
      }
      return (
        <MuiBox
          key={`cell-${Math.random()}`}
          p={Spacing.xSmall}
          border={`1px solid ${Color.gray}`}
          width={`${100 / cells.length}%`}
          style={{
            wordWrap: 'break-word'
          }}
        >
          <MuiTypography>{component}</MuiTypography>
        </MuiBox>
      );
    });

    return (
      <MuiBox
        key={`row-${Math.random()}`}
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
      >
        {rowComponents}
      </MuiBox>
    );
  });

  return reactComponents;
}

const Table = ({ line }: { line: string }) => {
  const components = parseHTMLTableToReact(line);
  return (
    <MuiBox display="flex" flexWrap="nowrap" overflow="auto">
      <MuiBox my={Spacing.small}>{components}</MuiBox>
    </MuiBox>
  );
};

export default Table;
