import React from 'react';
import { MuiBox, MuiTypography } from 'theme/material-ui';
import { UPDATES } from 'lib/constants/dashboard';

const UpdatesListError = () => {
  return (
    <MuiBox
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      py={3}
    >
      <MuiTypography>{UPDATES.error.title}</MuiTypography>
    </MuiBox>
  );
};

export default UpdatesListError;
