import React from 'react';
import FormHelperText from '@material-ui/core/FormHelperText';

export function SelectErrorHelper({ error }: { error: string | any }) {
  return (
    <>
      {!!error ? (
        <FormHelperText className="Mui-error MuiFormHelperText-contained">{error}</FormHelperText>
      ) : null}
    </>
  );
}
