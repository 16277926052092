import React from 'react';
import ccPoster from 'assets/poster.jpg';
import { waitingRoom } from 'modules/constants/amwell';
import { VideoPlayer } from 'components/VideoPlayer';

export function ConnectCareWaitingRoomVideo() {
  return (
    <VideoPlayer
      loop
      autoPlay
      seekable={false}
      controls={{
        includeProgress: true,
        includePlayPause: true,
        includeVolume: false,
        collapsible: true,
        subtitles: true
      }}
      src={waitingRoom.WAITING_ROOM_VIDEO.URL}
      poster={ccPoster}
    />
  );
}

export default ConnectCareWaitingRoomVideo;
