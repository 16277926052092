import styled from 'styled-components';

import { Color } from 'modules/styles/colors';
import { BorderRadius, FontSize, Spacing } from 'modules/styles/variables';
import { dfdDefaultTheme, MuiChip, MuiContainer, MuiTypography, MuiBox } from 'theme/material-ui';

export const Circle = styled.div`
  border-radius: 50px;
  height: 10px;
  width: 10px;
  background-color: ${Color.graySidebarIcon};
  margin-right: ${Spacing.medium}px;
`;

export const CircleBox = styled(MuiBox)`
  display: flex;
  align-items: center;
  margin: ${Spacing.small}px ${Spacing.large}px ${Spacing.xLarge}px;
  ${dfdDefaultTheme.breakpoints.down('sm')} {
    &&& {
      margin: 0;
      margin-left: ${Spacing.large}px;
      margin-bottom: ${Spacing.large}px;
    }
  }
`;

export const FilterChip = styled(MuiChip)`
  &.MuiChip-root {
    border-radius: ${BorderRadius.small}px;
    padding: 18px;
    background-color: ${Color.purple};
    color: ${Color.white};
    font-size: ${FontSize.large}px;
    margin: ${Spacing.small}px;
    justify-content: center;
    max-width: 175px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    ${dfdDefaultTheme.breakpoints.down('sm')} {
      padding: ${Spacing.medium}px;
      font-size: ${FontSize.small}px;
      max-width: 150px;
    }
  }
  & > .MuiChip-label {
    padding-left: 0;
    padding-right: 0;
    top: 1px;
    left: ${-Spacing.medium}px;
    ${dfdDefaultTheme.breakpoints.down('sm')} {
      padding-left: ${Spacing.small}px;
      left: 0px;
    }
  }
  & > .MuiSvgIcon-root.MuiChip-deleteIcon {
    color: ${Color.white};
    opacity: 0.85;
    right: ${-Spacing.large}px;
    ${dfdDefaultTheme.breakpoints.down('sm')} {
      right: -8px;
    }
  }
`;

export const Line = styled.div`
  width: 1px;
  height: 100%;
  position: absolute;
  top: -40px;
  left: 20px;
  background-color: ${Color.graySidebarIcon};
  ${dfdDefaultTheme.breakpoints.down('sm')} {
    &&& {
      top: -30px;
    }
  }
`;

export const StyledMuiContainer = styled(MuiContainer)`
  ${dfdDefaultTheme.breakpoints.down('sm')} {
    &&& {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
`;

export const ListFilter = styled(MuiTypography)`
  &.MuiTypography-body1 {
    font-size: ${FontSize.large}px;
    ${dfdDefaultTheme.breakpoints.down('sm')} {
      &&& {
        font-size: ${FontSize.small}px;
      }
    }
  }
`;

export const ListHeader = styled(MuiTypography)`
  &.MuiTypography-body1 {
    font-size: ${FontSize.heading}px;
    ${dfdDefaultTheme.breakpoints.down('sm')} {
      &&& {
        font-size: ${FontSize.base}px;
      }
    }
  }
`;
