import React, { useState, useEffect, useMemo, useRef } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { useTheme, MuiBox, MuiContainer, MuiTypography, MuiGrid } from 'theme/material-ui';
import { oc } from 'ts-optchain';
import ValidationError from 'lib/amwell/client/errors/ValidationError';
import { useMediaQuery } from '@material-ui/core';
import { RootState, RootDispatch } from 'store/types';
import { VisitContext, UpdateVisitContextForm } from 'store/amwell/types';
import analyticsService, { AnalyticsEvent } from 'services/AnalyticsService';
import * as selectors from 'store/amwell/selectors';
import {
  currentLocationPathNameSelector,
  previousLocationPathNameSelector
} from 'store/router/selectors';
import { updateVisitContext, getVisitContext } from 'store/amwell/actions';
import {
  ConnectCareConsentToTreatDialog,
  ConnectCareDataLoader,
  ConnectCareStepActions,
  ConnectCareStepAction
} from 'components/ConnectCare';
import { useSnack } from 'components/Snack';
import RouteLeavingGuard from 'components/UI/Modals/RouteLeavingGuardModal';
import { triage as triageConfirmations } from 'modules/constants/amwell';
import ConnectCareTriageTopics from './ConnectCareTriageTopics';
import ConnectCareTriageQuestions from './ConnectCareTriageQuestions';
import ConnectCareTriageCallback from './ConnectCareTriageCallback';
import { RouteData } from '../types';
import { FormScreen } from '../styled';

export interface Props extends RouteComponentProps {
  visitContext: VisitContext | null;
  loading: boolean;
  error: Error | null;
  dispatch: RootDispatch;
  actionLoading: boolean;
  actionError: ValidationError | Error | null;
  disclaimersAcknowledged: boolean;
  currentUrl?: string;
  referringUrl?: string;
}

const getCallbackNumberError = (err: ValidationError | Error | null) => {
  const errors = oc(err).errors([]);
  return errors.find(e => e && e.fieldName === 'callbackNumber');
};

function ConnectCareTriage(props: Props) {
  const {
    dispatch,
    history,
    visitContext,
    loading,
    error,
    actionLoading,
    actionError,
    referringUrl,
    currentUrl
  } = props;
  const theme = useTheme();
  const { create } = useSnack();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  const callbackNumberError = useMemo(() => {
    return getCallbackNumberError(actionError);
  }, [actionError]);

  const nextPath = useRef('');
  const nextDisabled = Boolean(
    !visitContext?.callbackNumber ||
      visitContext?.callbackNumber === '1111111111' ||
      callbackNumberError ||
      loading
  );

  const [showConsentDialog, setShowConsentDialog] = useState(false);

  // Blocks navigation when browser back button clicked, allows when previous or next are clicked
  const clickingPreviousOrNext = useRef(false);

  const hideConsentDialog = () => {
    clickingPreviousOrNext.current = false;
    setShowConsentDialog(false);
  };

  const fetchVisitContext = () => {
    dispatch(getVisitContext());
  };

  const onVisitContextChange = (updateVisitContextForm: Partial<UpdateVisitContextForm>) => {
    dispatch(updateVisitContext({ updateVisitContextForm }));
  };

  const onPrevClick = (prevStep?: RouteData) => {
    if (prevStep) {
      clickingPreviousOrNext.current = true;
      history.push(prevStep.path);
    }
  };

  const onNextClick = async (nextStep?: RouteData) => {
    if (nextStep) {
      nextPath.current = nextStep.path;
      setShowConsentDialog(true);
    }
  };

  const onTermsAgree = () => {
    analyticsService.logEvent(AnalyticsEvent.ConnectCareAppointmentTCAgreeClicked, {
      referringUrl,
      currentUrl
    });
    setShowConsentDialog(false);
    clickingPreviousOrNext.current = true;
    history.push(nextPath.current);
  };

  useEffect(() => {
    if (actionError && !callbackNumberError) {
      create(triageConfirmations.UPDATE_ERROR.subtitle, triageConfirmations.UPDATE_ERROR.severity);
      fetchVisitContext();
    }
  }, [actionError]);

  useEffect(fetchVisitContext, []);

  const errorComponentProps = {
    message: triageConfirmations.GET_ERROR.title,
    action: { onClick: fetchVisitContext, label: 'Try Again?' }
  };

  return (
    <>
      <FormScreen>
        <MuiContainer maxWidth="lg">
          <MuiBox my={2}>
            <MuiBox pb={4}>
              <MuiTypography variant="h5">{triageConfirmations.SCREEN.title}</MuiTypography>
            </MuiBox>
            <ConnectCareDataLoader
              data={visitContext}
              loading={loading}
              error={error}
              errorComponentProps={errorComponentProps}
              noDataComponentProps={errorComponentProps}
              renderData={data => (
                <MuiGrid container spacing={4} direction="column">
                  <ConnectCareTriageTopics
                    columns={1}
                    loading={actionLoading}
                    visitContext={data as VisitContext}
                    onVisitContextChange={onVisitContextChange}
                    isLargeScreen={isLargeScreen}
                  />
                  <ConnectCareTriageQuestions
                    loading={actionLoading}
                    visitContext={data as VisitContext}
                    onVisitContextChange={onVisitContextChange}
                    isLargeScreen={isLargeScreen}
                  />
                  <ConnectCareTriageCallback
                    loading={actionLoading}
                    visitContext={data as VisitContext}
                    error={callbackNumberError}
                    onVisitContextChange={onVisitContextChange}
                    isLargeScreen={isLargeScreen}
                  />
                  <ConnectCareConsentToTreatDialog
                    isOpen={showConsentDialog}
                    onDecline={hideConsentDialog}
                    onError={hideConsentDialog}
                    onAgree={onTermsAgree}
                  />
                </MuiGrid>
              )}
            />
          </MuiBox>
        </MuiContainer>
      </FormScreen>
      <RouteLeavingGuard
        shouldBlockNavigation={() =>
          !clickingPreviousOrNext.current && !!visitContext?.transferFromVisitId
        }
        exitTitle="Are you sure you want to exit?"
        exitSubtitle="Your session will be lost"
        exitButtonText="Exit Connect Care"
        keepGoingButtonText="Stay on page"
        exitAndShareButtonText="Exit & share feedback"
        navigate={() => history.push('/u/dashboard')}
      />
      <ConnectCareStepActions>
        <ConnectCareStepAction onClick={onPrevClick} />
        <ConnectCareStepAction disabled={nextDisabled} onClick={onNextClick} />
      </ConnectCareStepActions>
    </>
  );
}

const mapStateToProps = (state: RootState) => ({
  visitContext: selectors.visitContextDataSelector(state),
  loading: selectors.visitContextLoadingSelector(state),
  error: selectors.visitContextErrorSelector(state),
  actionLoading: selectors.visitContextActionLoadingSelector(state),
  actionError: selectors.visitContextActionErrorSelector(state),
  currentUrl: currentLocationPathNameSelector(state),
  referringUrl: previousLocationPathNameSelector(state)
});

export default connect(mapStateToProps)(ConnectCareTriage);
