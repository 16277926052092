import React from 'react';

export default {
  svg: (
    <g>
      <g>
        <g>
          <circle fill="#FFAA45" strokeWidth={0} cx={40.039} cy={39.659} r={38.5} />
          <path
            fill="#FFFFFF"
            strokeWidth={0}
            d="M40.039,2.159c20.711,0,37.5,16.789,37.5,37.5c0,20.711-16.789,37.5-37.5,37.5s-37.5-16.789-37.5-37.5 C2.539,18.949,19.328,2.159,40.039,2.159 M40.039,0.159c-21.78,0-39.5,17.72-39.5,39.5s17.72,39.5,39.5,39.5s39.5-17.72,39.5-39.5 S61.819,0.159,40.039,0.159L40.039,0.159z"
          />
        </g>
      </g>
      <g>
        <defs>
          <circle id="SVGID_11_" cx={40.039} cy={39.659} r={37.5} />
        </defs>
        <clipPath id="SVGID_2_22">
          <use xlinkHref="#SVGID_11_" />
        </clipPath>
        <g clipPath="url(#SVGID_2_22)">
          <rect x={31.2} y={68.744} fill="#231F20" strokeWidth={0} width={17.618} height={7.473} />
          <path
            fill="#F2AE75"
            strokeWidth={0}
            d="M55.744,40.225l0.488-6.345c0-8.297-7.93-15.019-16.221-15.019c-8.297,0-16.221,6.723-16.221,15.019 l0.488,6.338c-0.464,0.112-0.883,0.325-1.232,0.625c-0.649,0.553-1.057,1.37-1.057,2.289v3.124c0,1.652,1.33,2.992,2.985,3.002 c0,5.559,4.74,10.91,9.502,13.551h-1.671v7.142l-2.256,0.973l0.601,20.254h17.72l0.601-20.192l-2.247-1.036v-7.142h-1.677 c4.763-2.641,9.503-7.991,9.503-13.55c1.649-0.012,2.983-1.35,2.983-3.002v-3.124C58.032,41.722,57.055,40.547,55.744,40.225z"
          />
          <path
            fill="#FFFFFF"
            strokeWidth={0}
            d="M58.401,112.166c0,0,2.997-36.726-8.73-41.132l-0.536,2.012c-0.927,2.656-3.304,6.999-8.947,6.999 c-6.367,0-8.796-5.53-9.55-7.926l-0.289-1.086c-11.727,4.406-8.129,41.132-8.129,41.132"
          />
          <g>
            <polygon
              fill="#96281B"
              strokeWidth={0}
              points="42.095,81.704 43.479,79.053 40.009,76.216 36.539,79.053 37.883,81.704  "
            />
          </g>
          <path
            fill="#E6E7E8"
            strokeWidth={0}
            d="M40.009,76.217l-4.434,4.669l-7.582-9.046c0.013-0.006,4.732-2.356,4.732-2.356l1.78,1.647L40.009,76.217z "
          />
          <polygon
            fill="#E6E7E8"
            strokeWidth={0}
            points="47.297,69.485 40.01,76.216 44.444,80.888 52.024,71.837  "
          />
          <path
            fill="#42404D"
            strokeWidth={0}
            d="M40.009,109.634c0-0.002-9.663-39.111-9.663-39.111c-9.963,3.743-19.012,4.31-22.215,8.524 c-0.72,0.858-1.219,1.87-1.433,3.128c-1.432,8.378-3.019,17.616-4.218,27.459l13.62,0l0.022-0.069L40.009,109.634z"
          />
          <path
            fill="#42404D"
            strokeWidth={0}
            d="M77.539,109.634c-1.199-9.843-2.786-19.081-4.218-27.459c-0.215-1.258-0.713-2.27-1.434-3.128 c-3.203-4.214-12.252-4.781-22.215-8.524c0,0-9.663,39.109-9.663,39.111l23.889-0.069l0.022,0.069L77.539,109.634z"
          />
          <g opacity={0.8}>
            <path
              fill="#D17B4E"
              strokeWidth={0}
              d="M55.744,40.225l0.488-6.345c0-8.297-7.93-15.019-16.221-15.019c-0.002,0-0.002,21.327-0.002,21.327 c-2.191,4.66-3.605,11.415-3.605,11.415h3.605v12.916c5.629-0.002,15.04-7.427,15.04-15.26c1.649-0.012,2.983-1.35,2.983-3.002 v-3.124C58.032,41.722,57.055,40.547,55.744,40.225z"
            />
            <path
              fill="#D17B4E"
              strokeWidth={0}
              d="M32.804,64.964v2.212c0,0,3.734,1.202,7.204,1.202s7.208-1.202,7.208-1.202v-2.212 c-1.935,0.83-4.297,1.333-7.207,1.333C37.1,66.297,34.739,65.793,32.804,64.964z"
            />
          </g>
          <path
            fill="#3C2417"
            strokeWidth={0}
            d="M40.01,14.63c-23.666,0-19.248,25.506-17.632,30.678c0.294,0.946,1.559,2.404,1.565,2.404 c0.493,0.923-1.304,18.584,16.067,18.584s15.575-17.662,16.067-18.584c0.006,0,1.271-1.458,1.565-2.404 C59.258,40.137,63.676,14.63,40.01,14.63z M54.118,47.195c0,1.97-1.212,4.888-2.931,6.181c-0.739,0.556-1.727,0.647-2.577,0.284 c-1.433-0.613-4.636-1.162-8.601-1.162c-3.965,0-7.168,0.549-8.601,1.162c-0.85,0.363-1.838,0.272-2.577-0.284 c-1.719-1.293-2.931-4.211-2.931-6.181c0,0-2.393-8.63-0.593-14.97c0.891-3.139,4.739-4.907,7.904-4.115 c2.299,0.575,4.684,0.766,6.798,0.771c2.114-0.005,4.499-0.196,6.798-0.771c3.165-0.792,7.013,0.976,7.904,4.115 C56.512,38.566,54.118,47.195,54.118,47.195z"
          />
          <g>
            <g>
              <path
                fill="#EDA370"
                strokeWidth={0}
                d="M35.472,41.92c0.046,1.31-0.979,2.408-2.288,2.454c-1.31,0.046-2.408-0.979-2.454-2.288 c-0.046-1.31,0.979-2.408,2.288-2.454C34.327,39.586,35.426,40.61,35.472,41.92z"
              />
              <path
                fill="#3C2417"
                strokeWidth={0}
                d="M35.472,41.319c0.046,1.31-0.979,2.408-2.288,2.454c-1.31,0.046-2.408-0.979-2.454-2.288 c-0.046-1.31,0.979-2.408,2.288-2.454C34.327,38.985,35.426,40.009,35.472,41.319z"
              />
              <path
                fill="#FFFFFF"
                strokeWidth={0}
                d="M34.499,40.641c0.011,0.327-0.245,0.602-0.572,0.614c-0.327,0.011-0.602-0.245-0.613-0.572 c-0.011-0.327,0.245-0.602,0.572-0.614C34.213,40.057,34.487,40.313,34.499,40.641z"
              />
            </g>
            <g>
              <path
                fill="#C9623B"
                strokeWidth={0}
                d="M49.29,41.92c0.046,1.31-0.979,2.408-2.288,2.454c-1.31,0.046-2.408-0.979-2.454-2.288 c-0.046-1.31,0.979-2.408,2.288-2.454C48.145,39.586,49.244,40.61,49.29,41.92z"
              />
              <path
                fill="#3C2417"
                strokeWidth={0}
                d="M49.29,41.319c0.046,1.31-0.979,2.408-2.288,2.454c-1.31,0.046-2.408-0.979-2.454-2.288 c-0.046-1.31,0.979-2.408,2.288-2.454C48.145,38.985,49.244,40.009,49.29,41.319z"
              />
              <path
                fill="#FFFFFF"
                strokeWidth={0}
                d="M48.317,40.641c0.011,0.327-0.245,0.602-0.572,0.614c-0.327,0.011-0.602-0.245-0.613-0.572 c-0.011-0.327,0.245-0.602,0.572-0.614C48.031,40.057,48.305,40.313,48.317,40.641z"
              />
            </g>
          </g>
          <g>
            <path
              fill="#3C2417"
              strokeWidth={0}
              d="M34.319,38.8c0,0-0.081-0.026-0.223-0.072c-0.146-0.046-0.361-0.108-0.621-0.179 c-0.52-0.14-1.223-0.3-1.921-0.4c-0.349-0.052-0.695-0.083-1.015-0.101c-0.319-0.014-0.608-0.007-0.848,0.01 c-0.232,0.028-0.43,0.047-0.546,0.084c-0.124,0.028-0.194,0.044-0.194,0.044l-0.132,0.03c-0.483,0.11-0.963-0.192-1.073-0.674 c-0.102-0.448,0.151-0.894,0.573-1.044c0,0,0.111-0.04,0.306-0.109c0.198-0.078,0.471-0.138,0.801-0.209 c0.326-0.058,0.702-0.103,1.097-0.125c0.394-0.018,0.81-0.021,1.219-0.001c0.819,0.036,1.619,0.137,2.216,0.236 c0.299,0.05,0.547,0.096,0.724,0.133c0.18,0.039,0.283,0.061,0.283,0.061c0.65,0.141,1.062,0.781,0.922,1.431 c-0.14,0.65-0.781,1.062-1.431,0.922c-0.039-0.008-0.077-0.019-0.113-0.03L34.319,38.8z"
            />
            <path
              fill="#3C2417"
              strokeWidth={0}
              d="M45.053,36.485c0,0,0.103-0.022,0.284-0.061c0.177-0.037,0.425-0.083,0.724-0.133 c0.597-0.099,1.397-0.2,2.216-0.236c0.409-0.02,0.825-0.016,1.219,0.001c0.395,0.022,0.771,0.067,1.097,0.125 c0.33,0.07,0.603,0.131,0.801,0.209c0.195,0.069,0.306,0.109,0.306,0.109c0.466,0.166,0.71,0.679,0.544,1.145 c-0.154,0.433-0.607,0.673-1.043,0.573l-0.132-0.03c0,0-0.071-0.016-0.194-0.044c-0.116-0.036-0.314-0.056-0.546-0.084 c-0.241-0.017-0.529-0.024-0.848-0.01c-0.32,0.018-0.666,0.049-1.015,0.101c-0.698,0.1-1.402,0.26-1.921,0.4 c-0.259,0.071-0.475,0.133-0.621,0.179c-0.142,0.046-0.223,0.072-0.223,0.072l-0.021,0.007c-0.633,0.204-1.311-0.143-1.515-0.776 c-0.204-0.633,0.143-1.311,0.776-1.515C44.976,36.504,45.015,36.493,45.053,36.485z"
            />
          </g>
          <g>
            <path
              fill="#F05D44"
              strokeWidth={0}
              d="M45.416,55.548c0,2.654-2.421,4.806-5.407,4.806c-2.986,0-5.407-2.152-5.407-4.806H45.416z"
            />
            <path
              fill="#FFFFFF"
              strokeWidth={0}
              d="M43.855,57.35h-7.81c-0.332,0-0.601-0.269-0.601-0.601v-1.202h9.012v1.202 C44.456,57.081,44.187,57.35,43.855,57.35z"
            />
          </g>
          <g>
            <path
              fill="#ADADAD"
              strokeWidth={0}
              d="M19.358,26.081c0,3.524,0.908,9.514,2.734,9.514c18.304,0,32.426-20.374,27.393-23.452 c-3.171-1.939-7.034-2.476-11.202-2.476c-3.652,0-6.436,0.349-9.359,1.778C23.214,14.236,19.358,20.984,19.358,26.081z"
            />
            <path
              fill="#DADCDD"
              strokeWidth={0}
              d="M45.23,12.365c-2.289-0.006-5.347,0.24-8.597,1.208c-8.056,2.403-13.74,8.02-16.942,16.701 c-0.006-0.006-0.006-0.018-0.006-0.024c0,0,0,0,0-0.006c-0.12-0.835-0.21-1.676-0.264-2.481 C24.503,16.282,33.563,12.605,40.4,11.56C42.088,11.674,43.71,11.921,45.23,12.365z"
            />
            <path
              fill="#DADCDD"
              strokeWidth={0}
              d="M50.355,15.681c-0.006,0-0.012,0-0.018,0.006c-16.545,3.154-24.247,13.782-27.383,19.892 c-0.288,0.012-0.571,0.018-0.859,0.018c-0.084,0-0.168-0.012-0.246-0.042c-0.06-0.018-0.12-0.042-0.18-0.078 C24.75,29.271,32.53,18.06,49.574,14.612c0.372,0.246,0.625,0.601,0.769,1.039v0.006C50.349,15.663,50.355,15.675,50.355,15.681z "
            />
          </g>
          <path
            fill="#BCBCBC"
            strokeWidth={0}
            d="M39.331,9.066c12.475,0,21.327,7.618,21.327,17.015c0,3.524-0.141,9.514-2.734,9.514 c-18.304,0-35.299-20.978-30.02-23.611C30.356,10.761,33.313,9.066,39.331,9.066"
          />
          <path
            fill="#DADCDD"
            strokeWidth={0}
            d="M59.198,34.948c-0.282,0.342-0.619,0.565-1.027,0.631c-9.967-18.798-28.002-21.67-31.228-22.036 c0.03-0.463,0.174-0.853,0.439-1.154C31.496,12.918,49.249,16.264,59.198,34.948z"
          />
          <path
            fill="#DADCDD"
            strokeWidth={0}
            d="M35.053,9.397c7.071,0.204,19.663,2.854,25.593,18.113c-0.018,1.015-0.066,2.127-0.174,3.184 c-7.089-23.286-28.915-20.066-30.219-19.85C31.544,10.268,33.07,9.728,35.053,9.397"
          />
          <polyline
            fill="#6E6C76"
            strokeWidth={0}
            points="39.918,109.634 31.2,68.744 25.133,71.864 25.368,78.754 28.808,81.584 26.131,85.244  39.918,109.634  "
          />
          <polyline
            fill="#6E6C76"
            strokeWidth={0}
            points="40.1,109.634 48.818,68.744 54.885,71.864 54.65,78.754 51.209,81.584 53.887,85.244  40.1,109.634  "
          />
        </g>
      </g>
    </g>
  ),
  viewBox: '0 0 80 80'
};
