import { Alert } from 'components/Alert';
import { SpinnerOverlay } from 'components/UI/Spinner/SpinnerModal';
import useNavigationAnalytics from 'hooks/useNavigationAnalytics';
import { useWindowSize } from 'hooks/useWindowSize';
import { Color } from 'modules/styles/colors';
import { Coordinate } from 'modules/types/common';
import React, { useEffect, useState } from 'react';
import Config from 'react-native-config';
import { connect, useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { AmplitudeEventData, AnalyticsEvent } from 'services/AnalyticsService';
import { geoCodeLocation } from 'services/LocationService/GoogleApis';
import { getGeolocationPostalCode } from 'services/LocationService/utils';
import { logout } from 'store/authentication/actions';
import { isDfdAuthenticatedSelector } from 'store/authentication/selectors';
import { getUpcomingAppointments } from 'store/booking/appointments/upcomingAppointments/actions';
import * as geoLocationActions from 'store/geolocation/actions';
import { geolocationCoordsSelector, geolocationDeniedSelector } from 'store/geolocation/selectors';
import { getBcsToken } from 'store/kyruus/kyruusProviderSearch/actions';
import { bcsTokenDataSelector } from 'store/kyruus/kyruusProviderSearch/selectors';
import { RootState } from 'store/types';
import { MuiBox } from 'theme/material-ui';
import { Container } from './styled';

interface Props extends RouteComponentProps {
  getBcsToken: (issue: string, audience: string) => {};
  bcsToken: string;
  isAuthenticated: boolean;
  geolocationCoords: Coordinate;
  geolocationDenied: boolean;
  getCurrentPosition: Function;
}

interface SearchParamStateProps {
  searchTerm: string;
  locationParam: string;
}

const KyruusProviderSearch = ({
  getBcsToken,
  bcsToken,
  isAuthenticated,
  history,
  geolocationCoords,
  geolocationDenied,
  getCurrentPosition
}: Props) => {
  const { height } = useWindowSize();
  const KyruusProviderSearchLoading = () => <SpinnerOverlay isLoading />;
  const dispatch = useDispatch();
  const bookingCompletedEvent = useNavigationAnalytics(AnalyticsEvent.BookAppointmentCompleted);
  const searchParamsState = history.location?.state as SearchParamStateProps;
  const [locationPostalCode, setLocationPostalCode] = useState<string | null>(null);
  const DEFAULT_POSTAL_CODE = '84103'; // defaulting to salt lake postal
  useEffect(() => {
    if (!isAuthenticated) {
      Alert.alert('Session Timeout', 'Your session has ended, please log in again.', [
        { text: 'OK', onPress: () => dispatch(logout()) }
      ]);
    }
    getBcsToken('*', Config.SCL_HEALTH_PMC_BCSTOKEN);
  }, []);

  useEffect(() => {
    if (!geolocationDenied && !geolocationCoords) getCurrentPosition();
  }, []);

  useEffect(() => {
    geoCodeLocation(`${geolocationCoords?.latitude},${geolocationCoords?.longitude}`)
      .then(res => {
        const geolocationPostalCode = getGeolocationPostalCode(res[0]);
        if (geolocationPostalCode) {
          setLocationPostalCode(geolocationPostalCode);
        }
      })
      .catch(() => {
        setLocationPostalCode(DEFAULT_POSTAL_CODE);
      });
  }, [geolocationCoords]);

  const getProviderList = (event: any) => {
    const iframe = document.getElementById('frameRef') as HTMLIFrameElement;
    const newUrl = event?.data?.params?.[1]?.bookmarkLink;
    if (newUrl) {
      iframe.src = `${newUrl}&bcs_token=${bcsToken}`;
    }

    const currentEvent = event?.data?.params?.[0];
    if (currentEvent === 'hl.sdk.routeTo' && event?.data?.params?.[1]?.path) {
      const eventData: AmplitudeEventData = {
        kyruus: true
      };
      bookingCompletedEvent(eventData);
      dispatch(getUpcomingAppointments(false));
      Alert.alert(
        'Appointment Successfully Booked',
        "The provider's office may contact you prior to your appointment to confirm details.",
        [{ text: 'OK', onPress: () => history.push('/u/dashboard') }]
      );
    }
  };

  useEffect(() => {
    if (bcsToken && locationPostalCode) {
      const iframe = document.getElementById('frameRef') as HTMLIFrameElement;
      const iframeWin = iframe.contentWindow;
      iframeWin?.postMessage({ jsonrpc: '2.0', method: 'authorizeConsumer' }, '*');
      window.addEventListener('message', getProviderList, false);
    }
    return () => {
      window.removeEventListener('message', getProviderList, false);
    };
  }, [bcsToken, locationPostalCode]);

  const getPMCSourceURL = () => {
    let sourceUrl = `${Config.SCL_HEALTH_PMC_EMB_LINK}&bcs_token=${bcsToken}&location=${locationPostalCode}&distance=25`;
    if (searchParamsState?.searchTerm) {
      sourceUrl += `&${searchParamsState.searchTerm}`;
    }
    return sourceUrl;
  };

  return (
    <Container>
      {bcsToken && locationPostalCode ? (
        <MuiBox display="flex" flexDirection="row" bgcolor={Color.grayLight2} alignItems="center">
          <iframe
            id="frameRef"
            width="100%"
            height={height * 0.8}
            title="Kyruus Search"
            frameBorder="0"
            allow="geolocation *"
            src={getPMCSourceURL()}
          />
        </MuiBox>
      ) : (
        <KyruusProviderSearchLoading />
      )}
    </Container>
  );
};

const mapStateToProps = (state: RootState) => ({
  bcsToken: bcsTokenDataSelector(state),
  isAuthenticated: isDfdAuthenticatedSelector(state),
  geolocationCoords: geolocationCoordsSelector(state),
  geolocationDenied: geolocationDeniedSelector(state)
});

const mapDispatchToProps = {
  getBcsToken,
  getCurrentPosition: geoLocationActions.getCurrentPosition
};
export default connect(mapStateToProps, mapDispatchToProps)(KyruusProviderSearch);
