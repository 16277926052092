import React from 'react';

export default {
  svg: (
    <g fill="none">
      <rect x="1" y="1" width="42" height="42" rx="3" fill="white" />
      <g clipPath="url(#clip0_1_227)">
        <path
          d="M18.25 18.25C18.25 19.2446 18.6451 20.1984 19.3483 20.9017C20.0516 21.6049 21.0054 22 22 22C22.9946 22 23.9484 21.6049 24.6517 20.9017C25.3549 20.1984 25.75 19.2446 25.75 18.25C25.75 17.2554 25.3549 16.3016 24.6517 15.5983C23.9484 14.8951 22.9946 14.5 22 14.5C21.0054 14.5 20.0516 14.8951 19.3483 15.5983C18.6451 16.3016 18.25 17.2554 18.25 18.25Z"
          stroke="#4A00E2"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22 10.75C23.9891 10.75 25.8968 11.5402 27.3033 12.9467C28.7098 14.3532 29.5 16.2609 29.5 18.25C29.5 21.657 24.426 30.2 22.625 32.915C22.5566 33.0181 22.4637 33.1027 22.3547 33.1613C22.2456 33.2198 22.1238 33.2504 22 33.2504C21.8762 33.2504 21.7544 33.2198 21.6453 33.1613C21.5363 33.1027 21.4434 33.0181 21.375 32.915C19.574 30.2 14.5 21.657 14.5 18.25C14.5 16.2609 15.2902 14.3532 16.6967 12.9467C18.1032 11.5402 20.0109 10.75 22 10.75Z"
          stroke="#4A00E2"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <rect x="1" y="1" width="42" height="42" rx="3" stroke="#4A00E2" strokeWidth="2" />
      <defs>
        <clipPath id="clip0_1_227">
          <rect width="24" height="24" fill="white" transform="translate(10 10)" />
        </clipPath>
      </defs>
    </g>
  ),
  viewBox: '0 0 44 44'
};
