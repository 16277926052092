import React from 'react';

export default {
  svg: (
    <path
      fill="#000"
      fillRule="evenodd"
      d="M9.482 1.55a10.5 10.5 0 1 1 4.018 20.2.75.75 0 0 1 0-1.5 9 9 0 1 0-8.928-10.136L6.22 8.467a.75.75 0 0 1 1.06 1.06L4.32 12.49a.748.748 0 0 1-1.138 0L.22 9.527a.75.75 0 1 1 1.06-1.06l1.77 1.768A10.5 10.5 0 0 1 9.481 1.55Zm3.268 2.947a.75.75 0 0 1 .75.75v6H18a.75.75 0 0 1 0 1.5h-5.25a.75.75 0 0 1-.75-.75v-6.75a.75.75 0 0 1 .75-.75Z"
      clipRule="evenodd"
    />
  ),
  viewBox: '0 0 24 22'
};
