import styled from 'styled-components';

import DefaultText, { DefaultTextProps } from 'components/UI/Typography/DefaultText';

interface DefaultLabelProps extends DefaultTextProps {
  fontWeight?: string;
  paddingRight?: string;
  paddingLeft?: string;
}
export default styled(DefaultText)`
  letter-spacing: 0.25px;
  line-height: 20px;
  ${(props: DefaultLabelProps): string =>
    props.fontWeight ? `font-weight: ${props.fontWeight};` : ''}
  
  ${(props: DefaultLabelProps): string =>
    props.paddingRight ? `padding-right: ${props.paddingRight};` : ''}

  ${(props: DefaultLabelProps): string =>
    props.paddingLeft ? `padding-left: ${props.paddingLeft};` : ''}
`;
