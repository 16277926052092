import styled from 'styled-components';
import { MuiBox } from 'theme/material-ui';

export const Container = styled(MuiBox)`
  display: flex;
  background-color: #ffffff;
  flex-direction: column;
  margin: 0 auto;
  max-width: 465px;
`;

export const StyledImage = styled.img`
  position: absolute;
  top: -40px;
  left: 32.5%;
`;
