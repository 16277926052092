import { formatDate } from 'modules/utils/DateUtils';
import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as selectors from 'store/profile/selectors';
import { Grantee, Grantor } from 'store/profile/types';
import { isPrimaryUser } from 'modules/utils/UserUtils';
import { RootState } from 'store/types';
import styled from 'styled-components';
import {
  dfdDefaultTheme,
  MuiBox,
  MuiGrid,
  MuiList,
  MuiListItem,
  MuiPaper,
  MuiButton
} from 'theme/material-ui';
import { ProxyFormNav } from 'screens/ProxyForm/navigation/router';
import Spacer from 'components/UI/Layout/Spacer';
import { RefreshUserList } from './RefreshUserList';
import { otherAccountConsumersNeedActivationSelector } from 'store/account/selectors';
import Typography from 'components/UI/Typography';

export interface Props {
  shouldRefreshUserList: boolean;
  grantors: Grantor[];
  grantees: Grantee[];
}

const StyledAlignedBox = styled.div<{ justifyContent: string }>`
  && {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    ${dfdDefaultTheme.breakpoints.up('sm')} {
      justify-content: ${props => props.justifyContent};
    }
  }
`;

const isLast = (arr: any[], idx: number) => idx + 1 === arr.length;

export const GrantorList = ({ grantors, shouldRefreshUserList }: Props) => {
  const history = useHistory();

  return (
    <>
      {!grantors || !grantors.length ? (
        <MuiBox p={2}>
          <Typography textAlign="center">No users found</Typography>
        </MuiBox>
      ) : (
        <>
          {shouldRefreshUserList && <RefreshUserList />}
          <MuiList component={MuiPaper}>
            {grantors.map((patient, idx, arr) => {
              return (
                <MuiListItem
                  divider={!isLast(arr, idx)}
                  key={patient.consumerId}
                  data-testid="grantor-linked-accounts-list-item"
                >
                  <MuiGrid container justify="space-between">
                    <MuiGrid item xs={12} sm={3}>
                      <StyledAlignedBox justifyContent="flex-start">
                        <MuiBox>
                          <Typography>{patient.displayName}</Typography>
                          <Typography>{patient.relationship}</Typography>
                        </MuiBox>
                      </StyledAlignedBox>
                    </MuiGrid>

                    <MuiGrid item xs={12} sm={3}>
                      <StyledAlignedBox justifyContent="center">
                        <MuiBox>
                          <Typography>Birthday</Typography>
                          <Typography>{formatDate(patient.dateOfBirth)}</Typography>
                        </MuiBox>
                      </StyledAlignedBox>
                    </MuiGrid>

                    <MuiGrid item xs={12} sm={3}>
                      <StyledAlignedBox justifyContent="flex-end">
                        <MuiBox>
                          <Typography>Access Granted</Typography>
                          <Typography>{formatDate(patient.startDate)}</Typography>
                        </MuiBox>
                      </StyledAlignedBox>
                    </MuiGrid>

                    <MuiGrid item xs={12} sm={3}>
                      <StyledAlignedBox justifyContent="flex-end">
                        <MuiBox>
                          <Typography>
                            {!isPrimaryUser(patient) && !patient.isInMillenium
                              ? 'Needs Refresh'
                              : 'Active'}
                          </Typography>
                        </MuiBox>
                      </StyledAlignedBox>
                    </MuiGrid>
                  </MuiGrid>
                </MuiListItem>
              );
            })}
          </MuiList>
        </>
      )}
      <Spacer size="small" />
      <MuiButton
        color="primary"
        variant="outlined"
        data-testid="create-account"
        onClick={() => history.push(ProxyFormNav.PROXY_INFO)}
      >
        Request access to another account
      </MuiButton>
    </>
  );
};

const mapState = (state: RootState) => ({
  grantors: selectors.profileGrantorsSelector(state),
  shouldRefreshUserList: otherAccountConsumersNeedActivationSelector(state)
});

export default connect(mapState)(GrantorList);
