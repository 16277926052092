import useNavigationAnalytics from 'hooks/useNavigationAnalytics';
import { useState } from 'react';
import { AnalyticsEvent } from 'services/AnalyticsService.common';

import { ValidationType } from '../../types';

interface UsePersonalInformationProps {
  initialValidationType?: ValidationType;
}

export default function usePersonalInformation({
  initialValidationType = ValidationType.SMS
}: UsePersonalInformationProps) {
  const [validationType, setValidationType] = useState<ValidationType>(initialValidationType);

  const sendOTPError = useNavigationAnalytics(AnalyticsEvent.CovidOTPError);
  const covidContinueBtnClicked = useNavigationAnalytics(AnalyticsEvent.CovidContinueClicked);
  const covidOTPVerifyBtnClicked = useNavigationAnalytics(AnalyticsEvent.CovidOTPVerify);
  const covidScreeningNextBtn = useNavigationAnalytics(AnalyticsEvent.CovidNext);
  const covidScreeningPreviousBtn = useNavigationAnalytics(AnalyticsEvent.CovidPrev);

  const updateValidationType = (newValidationType: ValidationType) => {
    setValidationType(newValidationType);
  };

  return {
    covidContinueBtnClicked,
    covidOTPVerifyBtnClicked,
    covidScreeningNextBtn,
    covidScreeningPreviousBtn,
    sendOTPError,
    updateValidationType,
    validationType
  };
}
