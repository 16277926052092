import React, { useEffect, useState } from 'react';
import { getPatientLetter } from 'store/testResults/lab/actions';
import { RootDispatch } from 'store/types';
import { BinaryDocument } from 'store/visitSummary/types';
import { Nullable } from 'modules/types/common';
import { MuiBox, MuiTypography } from 'theme/material-ui';
import DocumentView from 'components/UI/DocumentView';
import FlexBox, { DisplayFlex, FlexBoxRow } from 'components/UI/Layout/FlexBox';
import Spinner from 'components/UI/Spinner/Spinner';
import DataLoader from 'components/UI/DataLoader/DataLoader';
import { oc } from 'ts-optchain';
import useBreadCrumbs from 'hooks/useBreadCrumbs';

export interface Props {
  dispatch: RootDispatch;
  match: { params: { docId: string } };
}

export function PatientLetter({ dispatch, match }: Props) {
  const [data, setData] = useState<null | BinaryDocument>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error>();
  const documentId = match.params.docId;
  const { mergeCurrentWithPreviousBreadCrumbs } = useBreadCrumbs();

  useEffect(() => {
    mergeCurrentWithPreviousBreadCrumbs();
  }, []);

  // Render Document Content
  const RenderDocument = (data: Nullable<{ content: string; contentType: string }>) => {
    const documentObj = data.data;
    const content = oc(documentObj).content();
    const contentType = oc(documentObj).contentType();
    const description = 'Patient Letter';

    if (content && contentType && contentType.includes('application/pdf')) {
      return (
        <DocumentView content={content} contentType={contentType} documentName={description} />
      );
    }
    return <></>;
  };

  const handleDocumentReceived = (document: BinaryDocument) => {
    setData(document);
    setLoading(false);
  };

  useEffect(() => {
    if (documentId) {
      dispatch(getPatientLetter('application/json+fhir', documentId))
        .then(res => {
          const { data } = res.payload;
          handleDocumentReceived(data);
        })
        .catch(setError);
    }
  }, [documentId]);

  return (
    <DataLoader
      loading={loading}
      data={data}
      error={error}
      renderLoading={() => (
        <MuiBox p={5} display="flex" alignItems="center" justifyContent="center">
          <Spinner />
        </MuiBox>
      )}
      renderError={() => (
        <FlexBoxRow alignItems="center" justifyContent="center">
          <MuiTypography>There was an issue loading documents.</MuiTypography>
        </FlexBoxRow>
      )}
      renderNoData={() => (
        <FlexBoxRow alignItems="center" justifyContent="center">
          <MuiTypography>No documents on file.</MuiTypography>
        </FlexBoxRow>
      )}
      renderData={() => (
        <DisplayFlex>
          <FlexBox width="100%">
            <RenderDocument data={data} />
          </FlexBox>
        </DisplayFlex>
      )}
    />
  );
}

export default PatientLetter;
