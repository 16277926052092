import React from 'react';
import { MuiContainer, MuiBox } from 'theme/material-ui';
import useNavigationAnalytics from 'hooks/useNavigationAnalytics';
import { AnalyticsEvent } from 'services/AnalyticsService';
import { Spacing } from 'modules/styles/variables';
import { ButtonsNavigation } from 'screens/CovidTestGuest/components';
import IntroductionText from '../IntroductionText';
import { CovidTitles } from 'lib/constants/covidScreening';
import { useCovidAgreementGuest } from '../../hooks';
import CheckboxStatements from '../CheckboxStatements';
import { AuthType } from 'store/authentication/types';
import { useHistory } from 'react-router-dom';

export default function CovidAgreementGuestForm() {
  const { confirmAgreementGuest, requiredField } = useCovidAgreementGuest();
  const covidAgreementCancelBtn = useNavigationAnalytics(
    AnalyticsEvent.CovidAgreementCancelClicked
  );
  const covidAgreementNextBtn = useNavigationAnalytics(AnalyticsEvent.CovidNext);
  const history = useHistory();

  const onCancelClick = () => {
    confirmAgreementGuest();

    covidAgreementCancelBtn({
      title: CovidTitles.CONSENT_AND_AGREEMENTS,
      authentication_type: AuthType.UNAUTHENTICATED
    });

    history.push('/guest-home');
  };

  const onNextClick = () => {
    confirmAgreementGuest();

    covidAgreementNextBtn({
      agrees_to_terms: 'yes',
      title: CovidTitles.CONSENT_AND_AGREEMENTS,
      authentication_type: AuthType.UNAUTHENTICATED
    });

    history.push('/guest-home/covid-screen/guest-covid-reasons');
  };

  const isValid = () => Object.values(requiredField).every(consent => consent);

  return (
    <MuiContainer maxWidth="lg">
      <MuiBox
        my={1}
        pt={Spacing.small}
        display="flex"
        flex={1}
        flexDirection="column"
        justifyContent="space-between"
      >
        <IntroductionText />
        <CheckboxStatements />
      </MuiBox>

      <ButtonsNavigation
        cancelTestId="consent-cancel-button"
        nextTestId="consent-next-button"
        disabledNextButton={!isValid()}
        onCancelClick={onCancelClick}
        onNextClick={onNextClick}
        isCancel
      />
    </MuiContainer>
  );
}
