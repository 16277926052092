import React, { ComponentType, SyntheticEvent, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import Logger from 'services/Logger/Logger.web';
import { SnackBar } from 'screens/Messaging/styled';
import { MuiBox, MuiContainer, MuiGrid, MuiTypography, MuiAlert } from 'theme/material-ui';
import { Color } from 'modules/styles/colors';
import { FontSize, FontWeight, IconSize } from 'modules/styles/variables';
import { MAX_FILE_SIZE } from 'modules/constants/covidScreening/insuranceFound';
import { InsuranceCard } from 'store/CovidScreening/types';
import * as covidActions from 'store/CovidScreening/actions';
import { AuthType } from 'store/authentication/types';
import { CovidTitles } from 'lib/constants/covidScreening';
import { AnalyticsEvent } from 'services/AnalyticsService';

import useNavigationAnalytics from 'hooks/useNavigationAnalytics';
import { Icon } from 'components/Icon';
import { SpinnerOverlay } from 'components/UI/Spinner/SpinnerModal';
import Spacer from 'components/UI/Layout/Spacer';
import FlexBox from 'components/UI/Layout/FlexBox';

import { PreviewFlexBox, PreviewIFrame, PreviewImg, RoundedMuiPaper } from './styled';
import { useCovidInsuranceUpload } from './hooks';
import { ButtonsNavigation, CovidTestGuestFooter } from 'screens/CovidTestGuest/components';
import CovidGuestGuard from '../navigation/CovidGuestLeavingGuard';
import { useLanguageSwitcher } from 'lib/hooks/useLanguageSwitcher';
import { translations } from 'lib/constants/translations/screens/covidGuest/insuranceCardUpload';

const KEY_BACK = 'Back';
const KEY_FRONT = 'Front';

interface PropsCovidScreeningInsuranceUpload extends RouteComponentProps {
  setInsuranceCard: typeof covidActions.setInsuranceCard;
  addInsuranceCardFile: typeof covidActions.addInsuranceCardUnauthenticated;
}

export function CovidScreeningInsuranceUpload({
  history,
  setInsuranceCard,
  addInsuranceCardFile
}: PropsCovidScreeningInsuranceUpload) {
  const covidScreeningNextBtn = useNavigationAnalytics(AnalyticsEvent.CovidNext);
  const covidScreeningPreviousBtn = useNavigationAnalytics(AnalyticsEvent.CovidPrev);
  const covidInsuranceCardUploadSkip = useNavigationAnalytics(
    AnalyticsEvent.CovidInsuranceCardUploadSkipped
  );
  const screenText = useLanguageSwitcher(translations);
  const [isLoading, setIsLoading] = useState(false);
  const { card, updateCard } = useCovidInsuranceUpload();
  const [errorMessage, setErrorMessage] = useState('');

  const updateBackCard = (back: InsuranceCard | undefined) => updateCard({ ...card, back });

  const updateFrontCard = (front: InsuranceCard | undefined) => updateCard({ ...card, front });

  const onUpload = (evt: SyntheticEvent, key: string) => {
    const file = evt.target.files[0];

    if (file?.size > MAX_FILE_SIZE) {
      setErrorMessage(screenText.errorMessage);
      return;
    }

    setIsLoading(true);
    const reader = new window.FileReader();

    reader.onloadend = async () => {
      try {
        if (file) {
          const fileString = (reader.result ?? '') as string;
          const { error, azureFileName, actionStatus, errorCode } = await addInsuranceCardFile(
            file
          );
          setIsLoading(false);
          if (error || actionStatus !== 'scannedUploaded') {
            setErrorMessage(screenText[errorCode]);
            return;
          }
          const uploadedData = {
            fileName: file.name,
            img: azureFileName,
            mimeType: file.type,
            fileString
          };
          if (key === KEY_FRONT) {
            updateFrontCard(uploadedData);
            return;
          }
          updateBackCard(uploadedData);
        }
      } catch (error) {
        Logger.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  // This ties the input to the button, so we can use the style of the button. <input /> does not accept children.
  const handleFileClick = (id: string) => {
    const ele = document.getElementById(id) as HTMLElement;
    ele.click();
  };

  const removeUploaded = (key: string) => {
    if (key === KEY_FRONT) return updateFrontCard(undefined);

    return updateBackCard(undefined);
  };

  const handlePrevious = () => {
    covidScreeningPreviousBtn({
      title: CovidTitles.INSURANCE_CARD_UPLOAD,
      authentication_type: AuthType.UNAUTHENTICATED
    });

    history.goBack();
  };

  const handleSkip = () => {
    setInsuranceCard([]);

    covidInsuranceCardUploadSkip({
      title: CovidTitles.INSURANCE_CARD_UPLOAD,
      authentication_type: AuthType.UNAUTHENTICATED
    });

    return history.push('/guest-home/covid-screen/guest-summary-info');
  };

  const handleSubmit = () => {
    const insuranceData = [card.front as InsuranceCard, card.back as InsuranceCard];

    setInsuranceCard(insuranceData);

    covidScreeningNextBtn({
      title: CovidTitles.INSURANCE_CARD_UPLOAD,
      authentication_type: AuthType.UNAUTHENTICATED
    });

    return history.push('/guest-home/covid-screen/guest-summary-info');
  };

  const renderUploadButton = (key: string) => {
    const ButtonId = `UploadInsurance-${key}-${Date.now()}`;
    const currentState = key === KEY_FRONT ? card.front : card.back;

    return (
      <MuiGrid xs style={{ minWidth: '330px' }}>
        <MuiTypography fontSize={FontSize.large}>
          {screenText[key]} {screenText.text1}
        </MuiTypography>

        <MuiBox mt={1} p={1}>
          <RoundedMuiPaper elevation={2} square>
            {currentState ? (
              <FlexBox flex={1}>
                <FlexBox alignItems="flex-end">
                  <Icon name="close" onClick={() => removeUploaded(key)} color={Color.black} />
                </FlexBox>

                <FlexBox alignItems="center">
                  {currentState?.mimeType?.includes('pdf') ? (
                    <PreviewIFrame title="pdf" src={currentState?.fileString}></PreviewIFrame>
                  ) : (
                    <PreviewImg src={currentState?.fileString} />
                  )}
                </FlexBox>
              </FlexBox>
            ) : (
              <PreviewFlexBox
                data-testid={`btn-${key}`}
                flex={1}
                onClick={() => handleFileClick(ButtonId)}
              >
                <Spacer spacing="xLarge" />

                <FlexBox alignItems="center">
                  <Icon name="publish" size={IconSize.xLarge} />
                </FlexBox>

                <MuiTypography textAlign="center">{screenText.text2}</MuiTypography>

                <input
                  card-face={key}
                  id={ButtonId}
                  data-testid="camera-button-input"
                  type="file"
                  onChange={e => onUpload(e, key)}
                  accept="image/*, application/pdf"
                  style={{ display: 'none' }}
                />

                <Spacer spacing="xLarge" />
              </PreviewFlexBox>
            )}
          </RoundedMuiPaper>
        </MuiBox>
      </MuiGrid>
    );
  };

  return (
    <>
      <CovidGuestGuard />
      <MuiContainer maxWidth="md">
        <SpinnerOverlay
          isLoading={isLoading}
          position="absolute"
          backgroundColor={Color.translucent}
          testID="Covid-Insurance-Card-Upload"
        />

        <Spacer spacing="xLarge" />

        <MuiTypography fontSize={FontSize.large} fontWeight={FontWeight.bold}>
          {screenText.title}
        </MuiTypography>

        <Spacer spacing="xLarge" />

        <MuiTypography fontWeight={FontWeight.bold}>{screenText.subTitle}</MuiTypography>

        <Spacer spacing="xLarge" />

        <MuiGrid container justify="center" direction="row">
          <MuiGrid item xs>
            {renderUploadButton(KEY_FRONT)}
          </MuiGrid>
          <MuiGrid item xs>
            {renderUploadButton(KEY_BACK)}
          </MuiGrid>
        </MuiGrid>

        <Spacer spacing="large" />

        {errorMessage ? (
          <SnackBar
            key={errorMessage}
            open
            autoHideDuration={8000}
            onClose={() => setErrorMessage('')}
          >
            <MuiAlert severity="error">{errorMessage}</MuiAlert>
          </SnackBar>
        ) : null}

        <Spacer spacing="large" />
        <ButtonsNavigation
          cancelTestId="insurance-upload-previous-btn"
          nextTestId="insurance-upload-next-btn"
          skipTestId="insurance-skip-button"
          isSkip
          disabledNextButton={!card.front || !card.back}
          onCancelClick={handlePrevious}
          onNextClick={handleSubmit}
          onSkipClick={handleSkip}
        />
        <CovidTestGuestFooter title={CovidTitles.INSURANCE_CARD_UPLOAD} />
      </MuiContainer>
    </>
  );
}

const mapDispatchToProps = {
  setInsuranceCard: covidActions.setInsuranceCard,
  addInsuranceCardFile: covidActions.addInsuranceCardUnauthenticated
};

export default connect(
  undefined,
  mapDispatchToProps
)(CovidScreeningInsuranceUpload as ComponentType);
