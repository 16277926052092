import React from 'react';

export default {
  svg: (
    <g>
      <g>
        <path
          d="M21.5,9.3h-7.3c-0.2,0-0.4-0.2-0.4-0.4V1.5c0-0.2,0.2-0.4,0.4-0.4h7.3c0.2,0,0.4,0.2,0.4,0.4v7.3
          C21.9,9.1,21.7,9.3,21.5,9.3z M14.6,8.4H21V1.9h-6.4V8.4z"
        />
      </g>
      <path
        d="M12.7,4.7H9.8V1.5C9.8,1.2,9.6,1,9.4,1H2C1.8,1,1.6,1.2,1.6,1.5v7.3c0,0.2,0.2,0.4,0.4,0.4h7.3c0.2,0,0.4-0.2,0.4-0.4V5.6
        h2.9c0.2,0,0.4-0.2,0.4-0.4S12.9,4.7,12.7,4.7z M8.9,8.4H2.5V1.9h6.4V8.4z"
      />
      <path
        d="M21.5,13.6h-7.3c-0.2,0-0.4,0.2-0.4,0.4v3.2H9.3c-0.2,0-0.4,0.2-0.4,0.4c0,0.2,0.2,0.4,0.4,0.4h4.4v3.2
        c0,0.2,0.2,0.4,0.4,0.4h7.3c0.2,0,0.4-0.2,0.4-0.4v-7.3C21.9,13.8,21.7,13.6,21.5,13.6z M21,21h-6.4v-6.4H21V21z"
      />
      <path
        d="M8.2,19.5c0-1.4-1.2-2.1-2.3-2.6c-0.8-0.3-1.4-0.7-1.4-1.4c0-0.6,0.6-1.1,1.3-1.1C6.4,14.5,7,15,7,15.6
        C7,15.8,7.2,16,7.4,16c0.2,0,0.4-0.2,0.4-0.4c0-0.9-0.7-1.7-1.7-1.9v-1.3c0-0.2-0.2-0.4-0.4-0.4S5.2,12,5.2,12.3v1.3
        c-1,0.2-1.7,1-1.7,1.9c0,1.2,1.1,1.8,2,2.2c1.2,0.5,1.7,1,1.7,1.7c0,0.7-0.7,1.3-1.6,1.3s-1.6-0.6-1.6-1.3c0-0.2-0.2-0.4-0.4-0.4
        s-0.4,0.2-0.4,0.4c0,1.1,0.9,2,2,2.2v1.2c0,0.2,0.2,0.4,0.4,0.4s0.4-0.2,0.4-0.4v-1.2C7.3,21.5,8.2,20.6,8.2,19.5z"
      />
      <path
        d="M7.1,4.6h-1v-1c0-0.2-0.2-0.4-0.4-0.4c-0.2,0-0.4,0.2-0.4,0.4v1h-1c-0.2,0-0.4,0.2-0.4,0.4s0.2,0.4,0.4,0.4h1v1
        c0,0.2,0.2,0.4,0.4,0.4c0.2,0,0.4-0.2,0.4-0.4v-1h1c0.2,0,0.4-0.2,0.4-0.4S7.3,4.6,7.1,4.6z"
      />
      <g>
        <path d="M19.2,5.5h-2.9c-0.2,0-0.4-0.2-0.4-0.4s0.2-0.4,0.4-0.4h2.9c0.2,0,0.4,0.2,0.4,0.4S19.5,5.5,19.2,5.5z" />
      </g>
      <g>
        <g>
          <path
            d="M19.2,17.1h-2.9c-0.2,0-0.4-0.2-0.4-0.4c0-0.2,0.2-0.4,0.4-0.4h2.9c0.2,0,0.4,0.2,0.4,0.4
            C19.7,16.9,19.5,17.1,19.2,17.1z"
          />
        </g>
        <g>
          <path
            d="M19.2,19.3h-2.9c-0.2,0-0.4-0.2-0.4-0.4c0-0.2,0.2-0.4,0.4-0.4h2.9c0.2,0,0.4,0.2,0.4,0.4
            C19.7,19.1,19.5,19.3,19.2,19.3z"
          />
        </g>
      </g>
      <g>
        <path d="M9.4,10.8H2.1c-0.2,0-0.4-0.2-0.4-0.4c0-0.2,0.2-0.4,0.4-0.4h7.3c0.2,0,0.4,0.2,0.4,0.4C9.8,10.6,9.6,10.8,9.4,10.8z" />
      </g>
      <path
        d="M21.4,9.9h-7.3c-0.2,0-0.4,0.2-0.4,0.4c0,0.2,0.2,0.4,0.4,0.4h3.2v1.9c0,0.2,0.2,0.4,0.4,0.4c0.2,0,0.4-0.2,0.4-0.4v-1.9
        h3.2c0.2,0,0.4-0.2,0.4-0.4C21.9,10.1,21.7,9.9,21.4,9.9z"
      />
      <g>
        <path
          d="M21.4,23.4h-7.3c-0.2,0-0.4-0.2-0.4-0.4c0-0.2,0.2-0.4,0.4-0.4h7.3c0.2,0,0.4,0.2,0.4,0.4C21.9,23.2,21.7,23.4,21.4,23.4
          z"
        />
      </g>
    </g>
  ),
  viewBox: '0 0 24 24'
};
