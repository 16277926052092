import { css, createGlobalStyle } from 'styled-components';
import { ZIndex, FontSize } from 'modules/styles/variables';
import { dfdDefaultTheme } from 'theme/material-ui';
import { Color } from 'modules/styles/colors';
import { spacings } from 'modules/utils/StylesUtils';
import { headerHeight } from 'components/AuthLayout/styled';
import close from '../assets/close.png';

export const muiButtonStyles = css`
  cursor: pointer;
  color: ${Color.white};
  background-color: ${Color.primary};
  border: 1px solid ${Color.primary};
  padding: 6px 16px;
  min-width: 64px;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.75;
  border-radius: 4px;
  text-transform: capitalize;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12);
`;

export const easeInOutBack = 'cubic-bezier(0.4, 0.0, 0.6, 1)';
export const boxShadow =
  '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);';

export const menuWidth = {
  large: 80,
  medium: 68,
  small: 56
};

export default createGlobalStyle`
    .awsdk-hidden {
      display: none !important;
    }

    /** Container **/
    .awsdk-console {
      z-index: ${ZIndex.max};
      position: fixed;
      width: 100%;
      height: 100vh;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: ${Color.white};
      opacity: 1;
      transform: scale(1);
      transition: all 250ms ${easeInOutBack};
      padding-top: ${headerHeight}px;

      &.awsdk-hidden {
        z-index: ${ZIndex.min};
        display: block !important;
        opacity: 0;
        transform: scale(0);
      }

      *,
      *::before,
      *::after {
        position: static;
      }
    }

    .awcore-container {
      max-height: 100%;
      min-height: 60vh;
      min-width: auto;
    }

    /* MODAL */
    .awcore-modal-container, .awcore-modal-container-flat, .awcore-top-pane-wrapper, .awcore-bottom-pane-wrapper, .awcore-spinner-container, .awcore-self-container, .awcore-settings-content-wrapper, .awcore-local-view-name, .awcore-hide-self-video-container, .awcore-participant-title-container {
      position: absolute;
    }

    .awcore-toggle-buttons-container, .awcore-settings-container {
      z-index: 1;
    }

    .awcore-settings-content-div {
      position: relative;
    }

    .awcore-modal {
      margin: ${spacings(2)};
      border-radius: 4px;
      box-shadow: ${boxShadow};

      & .awcore-modal-header {
        background: ${Color.primary};
        padding:  ${spacings(0, 5)};
        height: 90px;
        & h5 {
          text-shadow: none;
          text-transform: none;
          font-size: ${FontSize.large}px;
          margin: 0;

          ${dfdDefaultTheme.breakpoints.down('md')} {
            font-size: ${FontSize.base}px;
          }
          ${dfdDefaultTheme.breakpoints.down('sm')} {
            font-size: ${FontSize.base}px;
          }
        }
      }

      & .awcore-modal-body {
        font-size: ${FontSize.large}px;
        font-weight: 600;
        color: ${Color.grayHue2};
        padding: ${spacings(2, 8)};
      }

      & .awcore-modal-footer {
        padding: 8px 16px;
      }

      & .awcore-modalClose {
        width: 40px;
        height: 40px;
        background: url(${close}) no-repeat center;
        background-size: cover;
      }

      & button {
        ${muiButtonStyles};
      }
    }

    .awcore-browser-permissions .awcore-modal,
    .awcore-modal {
      width: auto;
      max-width: 600px;

      & h1 {
        font-size: ${FontSize.title}px;
        margin: 0;

        ${dfdDefaultTheme.breakpoints.down('md')} {
          font-size: ${FontSize.largeHeading}px;
        }
        ${dfdDefaultTheme.breakpoints.down('sm')} {
          font-size: ${FontSize.heading}px;
        }
      }

      & p {
        font-size: ${FontSize.base}px;

        ${dfdDefaultTheme.breakpoints.down('md')} {
          font-size: ${FontSize.small}px;
        }
        ${dfdDefaultTheme.breakpoints.down('sm')} {
          font-size: ${FontSize.xsmall}px;
        }
      }
    }

    .awcore-button.awcore-button-inverted {
      ${muiButtonStyles};
      color: ${Color.primary};
      background-color: transparent;
    }

    .awcore-disconnect-button {
      ${muiButtonStyles};
      border: 1px solid ${Color.red};
      background-color: ${Color.red};
      color: ${Color.white};
    }

    .awcore-call-me-div {
      background-color: ${Color.primary};
      color: ${Color.white};
    }

    .awcore-control-button {
      background-color: ${Color.primary};
      border: 1px solid ${Color.primary};
    }

    .awcore-control-button:active, .awcore-toggle-buttons-container .awcore-full-screen:active, .awcore-toggle-buttons-container .awcore-refresh:active, .awcore-control-button.awcore-settings-div:active {
      border: solid 1.9px ${Color.primary} !important;
    }
`;
