import { MuiCard, dfdDefaultTheme } from 'theme/material-ui';
import styled from 'styled-components';
import { FlexBoxRow } from 'components/UI/Layout/FlexBox';
import { spacings } from 'modules/utils/StylesUtils';

import { HIDE_IMAGE_BREAKPOINT } from 'modules/constants/ConnectCare';

interface ImgBoxProps {
  evisit: boolean;
}

export const ImgBox = styled(FlexBoxRow)<ImgBoxProps>`
  width: 290px;
  height: ${props => (props.evisit ? '300px' : '265px')};
  ${dfdDefaultTheme.breakpoints.down('sm')} {
    padding: ${spacings(2, 3)};
  }
`;

export const MuiHomeCard = styled(MuiCard)`
  &&& {
    display: flex;
    flex-direction: row;
    ${dfdDefaultTheme.breakpoints.up(HIDE_IMAGE_BREAKPOINT)} {
      align-items: center;
      max-height: 300px;
    }
    ${dfdDefaultTheme.breakpoints.down('sm')} {
      flex-direction: column;
    }
  }
`;
