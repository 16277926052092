import React from 'react';

export default {
  svg: (
    <g>
      <path
        d="M31.68 20.787l6.513-6.512a3.48 3.48 0 1 0-4.921-4.921l-1.592 1.591-1.591-1.591a3.48 3.48 0 1 0-4.921
        4.92l6.513 6.513z"
        fill="none"
        strokeWidth="2.298"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M41.973 29.05l9.719 2.3c1.968.465 2.594 1.575 1.392 2.467L28.302 52.005c-1.202.891-3.796 1.24-5.765.774L4.869
        48.6c-1.968-.466-2.594-1.576-1.392-2.467l19.695-14.456"
        fill="none"
        strokeWidth="2.298"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36.793 32.488l6.841 1.584c1.194.276 1.572.935.844 1.463l-14.29 10.371c-.73.53-2.301.736-3.494.46l-10.631-2.461
        c-1.193-.276-1.573-.934-.844-1.464l11.558-8.388"
        fill="none"
        strokeWidth="2.298"
        strokeLinecap="round"
        strokeLinejoin="round"
      />

      <path
        d="M19.634 48.463c-.72.49-2.258.68-3.437.423-1.177-.255-1.55-.86-.83-1.348.72-.49 2.26-.68 3.436-.425 1.177.257
        1.55.86.83 1.35"
        fill="none"
      />
      <path
        d="M55.331 14.56h6.818M55.331 5.311l6.818-3.114M55.331 23.81l6.818 3.114M7.967 14.56H1.15M7.967 5.311L1.15
        2.197M7.967 23.81L1.15 26.924M53.084 39L28.302 57.188c-1.202.892-3.796 1.24-5.765.774L4.87 53.784"
        fill="none"
        strokeWidth="2.298"
        strokeLinecap="round"
      />
      <path
        d="M49.163 13.618c0-6.886-7.827-12.469-17.482-12.469-9.657 0-17.484 5.583-17.484 12.47 0 6.188
        6.328 11.31 14.617 12.287l2.867 8.978 2.865-8.978c8.29-.977 14.617-6.099 14.617-12.288z"
        fill="none"
        strokeWidth="2.298"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  ),
  viewBox: '0 0 64 60'
};
