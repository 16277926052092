import React from 'react';
import { Color } from 'modules/styles/colors';
import { SvgProps } from 'components/UI/Svg';

export default (props?: SvgProps) => {
  return {
    svg: (
      <svg
        width={props?.size}
        height={props?.size}
        viewBox="0 0 50 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        fillRule="evenodd"
        clipRule="evenodd"
      >
        <g clipPath="url(#clip0_465_360)">
          <path
            d="M0.521484 25.2601C0.521484 11.5968 11.5978 0.520508 25.2611 0.520508C38.9245 0.520508 50.0007 11.5968 50.0007 25.2601C50.0007 38.9235 38.9245 49.9997 25.2611 49.9997C11.5978 49.9997 0.521484 38.9235 0.521484 25.2601ZM25.2611 3.64551C13.3237 3.64551 3.64648 13.3227 3.64648 25.2601C3.64648 37.1976 13.3237 46.8747 25.2611 46.8747C37.1986 46.8747 46.8757 37.1976 46.8757 25.2601C46.8757 13.3227 37.1986 3.64551 25.2611 3.64551Z"
            fill={props?.color || Color.secondary}
          />
          <path
            d="M19.6813 21.3893C19.6813 20.4411 20.45 19.6725 21.3981 19.6725H25.261C26.2091 19.6725 26.9778 20.4411 26.9778 21.3893V36.8407C26.9778 37.7889 26.2091 38.5575 25.261 38.5575C24.3128 38.5575 23.5442 37.7889 23.5442 36.8407V23.1061H21.3981C20.45 23.1061 19.6813 22.3375 19.6813 21.3893Z"
            fill={props?.color || Color.secondary}
          />
          <path
            d="M19.6813 36.8407C19.6813 35.8925 20.45 35.1238 21.3981 35.1238H29.1238C30.072 35.1238 30.8406 35.8925 30.8406 36.8407C30.8406 37.7888 30.072 38.5575 29.1238 38.5575H21.3981C20.45 38.5575 19.6813 37.7888 19.6813 36.8407Z"
            fill={props?.color || Color.secondary}
          />
          <path
            d="M25.2612 13.3026C25.5908 13.3026 25.858 13.0354 25.858 12.7058C25.858 12.3762 25.5908 12.109 25.2612 12.109V10.1776C23.8649 10.1776 22.733 11.3095 22.733 12.7058C22.733 14.1021 23.8649 15.234 25.2612 15.234V13.3026Z"
            fill={props?.color || Color.secondary}
          />
          <path
            d="M25.2612 10.1776C26.6575 10.1776 27.7893 11.3095 27.7893 12.7058C27.7893 14.1021 26.6575 15.234 25.2612 15.234V13.3026C24.9317 13.3026 24.6643 13.0354 24.6643 12.7058C24.6643 12.3762 24.9317 12.109 25.2612 12.109V10.1776Z"
            fill={props?.color || Color.secondary}
          />
        </g>
        <defs>
          <clipPath id="clip0_465_360">
            <rect width="50" height="50" fill={Color.white} />
          </clipPath>
        </defs>
      </svg>
    )
  };
};
