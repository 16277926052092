import React from 'react';

export default {
  svg: (
    <g>
      <g transform="translate(-40 -296)">
        <path
          d="M72.4169,311.3052 C71.6939,320.5642 62.9169,324.7652 62.5439,324.9392 C62.3269,325.0392 62.0989,325.0872
          61.8739,325.0872 C61.2779,325.0872 60.7059,324.7492 60.4369,324.1732 C60.0669,323.3812 60.4089,322.4402
          61.1989,322.0692 C61.2739,322.0332 68.6799,318.4492 69.2569,311.0602 C69.3249,310.1872 70.0839,309.5382
          70.9599,309.6032 C71.8329,309.6702 72.4849,310.4332 72.4169,311.3052 M72.0669,319.1042 C69.3349,323.7252
          65.4389,326.6862 61.6449,327.0242 C59.1769,327.2432 56.9579,326.3032 55.2299,324.3002 C53.3909,322.1682
          50.9919,319.8472 48.7509,320.7882 C47.0869,321.4912 46.4179,323.5952 46.7069,327.2202 C45.7519,327.4322
          44.4219,327.5802 43.1699,327.2062 L43.1699,319.3062 C43.1699,316.2792 43.8479,314.1542 45.0319,313.4762
          C45.6969,313.0952 46.3639,313.2832 46.6239,313.4342 C47.2679,313.8122 47.7449,314.5212 48.2499,315.2712
          C49.2269,316.7232 50.8629,319.1522 54.3399,317.3692 C58.0499,315.4682 57.0669,312.7022 56.1169,310.0292
          C55.3939,307.9922 54.4199,305.2502 54.4699,301.3472 L57.7439,299.8592 C57.7909,301.8962 58.0229,304.8372
          59.3629,307.2842 L61.2449,310.7242 L62.2819,306.9432 C62.2969,306.8872 63.8569,301.3512 67.5299,302.1792
          C69.5479,302.6382 71.7059,304.8052 72.8969,307.5732 C73.8399,309.7612 74.9849,314.1692 72.0669,319.1042
          M68.2319,299.0882 C64.9689,298.3432 62.6079,300.0772 61.0889,302.2022 C60.9129,300.8862 60.9049,299.6322
          60.8999,298.7652 C60.8979,298.3822 60.8959,298.0592 60.8829,297.8112 C60.8529,297.1792 60.5109,296.6062
          59.9709,296.2782 C59.4299,295.9492 58.7629,295.9102 58.1939,296.1722 L52.4379,298.7882 C51.7859,299.0872
          51.3599,299.7192 51.3269,300.4352 C51.0979,305.3702 52.3209,308.8122 53.1309,311.0892 C54.1339,313.9132
          54.0399,313.9612 52.8949,314.5492 C51.9759,315.0212 51.9209,315.0472 50.8789,313.5012 C50.2619,312.5842
          49.4939,311.4432 48.2269,310.7002 C47.1489,310.0682 45.2519,309.6972 43.4559,310.7252 C41.8789,311.6292
          39.9999,313.8512 39.9999,319.3062 L39.9999,327.3442 C39.9999,328.6312 40.8169,329.7682 42.0339,330.1712
          C42.9899,330.4892 43.9509,330.6052 44.8479,330.6052 C45.8919,330.6052 46.8479,330.4482 47.5989,330.2672
          C49.0489,329.9172 50.0079,328.5922 49.8789,327.1152 C49.7179,325.2642 49.8889,324.2502 50.0319,323.8262
          C50.4089,324.0042 51.2759,324.5712 52.8309,326.3702 C55.2189,329.1382 58.4489,330.4942 61.9259,330.1812
          C66.7339,329.7522 71.5449,326.2142 74.7959,320.7172 C77.4479,316.2312 77.8169,310.9842 75.8089,306.3192
          C74.1859,302.5522 71.2829,299.7822 68.2319,299.0882"
        />
      </g>
    </g>
  ),
  viewBox: '0 0 38 35'
};
