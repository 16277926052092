import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import chunk from 'lodash/chunk';
import { RootState } from 'store/types';
import { Allergy } from 'store/amwell/types';
import * as selectors from 'store/amwell/selectors';
import * as actions from 'store/amwell/actions';
import { MuiBox, MuiGrid, MuiTypography } from 'theme/material-ui';
import { Alert } from 'components/Alert';
import { ConnectCareDataLoader, ConnectCareCheckboxGroup } from 'components/ConnectCare';
import { allergiesError } from './constants';
import analyticsService, { AnalyticsEvent } from 'services/AnalyticsService';
import {
  currentLocationPathNameSelector,
  previousLocationPathNameSelector
} from 'store/router/selectors';

export interface Props {
  getAllergies: typeof actions.getAllergies;
  updateAllergies: typeof actions.updateAllergies;
  allergies: Allergy[];
  loading: boolean;
  error: Error | null;
  actionLoading: boolean;
  actionError: Error | null;
  columns: number;
  currentUrl?: string;
  referringUrl?: string;
}

const getChunks = (allergies: Allergy[], columns: number) => {
  return chunk(allergies, Math.ceil(allergies.length / columns));
};

export function ConnectCareMedicalHistoryAllergies(props: Props) {
  const {
    getAllergies,
    updateAllergies,
    allergies,
    loading,
    error,
    actionLoading,
    actionError,
    columns,
    currentUrl,
    referringUrl
  } = props;

  const chunks = useMemo(() => getChunks(allergies, columns), [allergies, columns]);

  useEffect(() => {
    if (actionError) {
      Alert.alert(allergiesError.UPDATE.title, allergiesError.UPDATE.subtitle);
      getAllergies();
    }
  }, [actionError]);

  const onAllergyChange = (chunkIndex: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const index = +e.target.id;
    const allergiesToUpdate: Allergy[] = [...allergies];
    const count = [...Array(chunkIndex + 1)].reduce((acc, _, idx, arr) => {
      return arr.length - 1 === idx
        ? acc + chunks[idx].slice(0, index).length
        : acc + chunks[idx].length;
    }, 0);

    allergiesToUpdate[count].isCurrent = e.target.checked;
    updateAllergies({ allergies: allergiesToUpdate });

    analyticsService.logEvent(AnalyticsEvent.MedicalHistoryEntered, {
      currentUrl,
      referringUrl
    });
  };

  const errorComponentProps = {
    message: allergiesError.GET.title,
    action: {
      label: 'Try Again?',
      onClick: getAllergies
    }
  };

  return (
    <MuiBox my={2}>
      <MuiBox pb={4}>
        <MuiTypography variant="h5">
          Are you allergic to any of the following medications?
        </MuiTypography>
      </MuiBox>
      <ConnectCareDataLoader
        data={chunks}
        loading={loading}
        error={error}
        errorComponentProps={errorComponentProps}
        noDataComponentProps={errorComponentProps}
        renderData={data => (
          <MuiGrid container justify="center" spacing={4} wrap="nowrap">
            {data.map((d, idx) => (
              // eslint-disable-next-line react/no-array-index-key
              <MuiGrid key={idx} item lg={4} md={6}>
                <ConnectCareCheckboxGroup
                  items={d}
                  disabled={actionLoading}
                  checkedKey="isCurrent"
                  itemToString={(item: any) => (item ? item.name : '')}
                  onChange={onAllergyChange(idx)}
                />
              </MuiGrid>
            ))}
          </MuiGrid>
        )}
      />
    </MuiBox>
  );
}

const mapStateToProps = (state: RootState) => ({
  allergies: selectors.allergiesDataSelector(state),
  loading: selectors.allergiesDataLoadingSelector(state),
  error: selectors.allergiesDataErrorSelector(state),
  actionLoading: selectors.allergiesActionLoadingSelector(state),
  actionError: selectors.allergiesActionErrorSelector(state),
  currentUrl: currentLocationPathNameSelector(state),
  referringUrl: previousLocationPathNameSelector(state)
});

const mapDispatch = {
  getAllergies: actions.getAllergies,
  updateAllergies: actions.updateAllergies
};

export default connect(mapStateToProps, mapDispatch)(ConnectCareMedicalHistoryAllergies);
