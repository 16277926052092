import React from 'react';

export default {
  svg: (
    <g>
      <g>
        <circle fill="#8CC63F" strokeWidth={0} cx={40} cy={40} r={38.5} />
        <path
          fill="#FFFFFF"
          strokeWidth={0}
          d="M40,2.5c20.711,0,37.5,16.789,37.5,37.5S60.711,77.5,40,77.5S2.5,60.711,2.5,40S19.289,2.5,40,2.5 M40,0.5 C18.22,0.5,0.5,18.22,0.5,40S18.22,79.5,40,79.5S79.5,61.78,79.5,40S61.78,0.5,40,0.5L40,0.5z"
        />
      </g>
      <g>
        <defs>
          <circle id="SVGID_15_" cx={40} cy={40} r={37.5} />
        </defs>
        <clipPath id="SVGID_2_4">
          <use xlinkHref="#SVGID_15_" />
        </clipPath>
        <g id="Female_-_Child_1_" clipPath="url(#SVGID_2_4)">
          <polygon
            fill="#32303A"
            strokeWidth={0}
            points="32.863,71.864 47.35,71.864 47.165,77.285 33.004,77.285"
          />
          <path
            fill="#BF8858"
            strokeWidth={0}
            d="M54.045,44.148v-6.315c0-6.707-7.764-15.18-13.91-15.18c-6.146,0-13.91,8.473-13.91,15.18v6.315 c-1.707,0-3.091,1.359-3.091,3.036v3.157c0,1.677,1.384,3.036,3.091,3.036c0,4.556,3.585,10.037,7.81,13.085l0,6.381 l-3.036,0.646l3.036,19.167l12.144,0l3.036-19.167l-3.036-0.646v-6.339c4.25-3.04,7.867-8.55,7.867-13.127 c1.707,0,3.091-1.359,3.091-3.036v-3.157C57.137,45.507,55.753,44.148,54.045,44.148z"
          />
          <path
            fill="#B87249"
            // stroke="#B87249"
            strokeWidth={0}
            d="M54.045,44.148v-6.315c0-6.707-7.764-15.18-13.91-15.18v24.591c-2.255,4.71-3.091,9.715-3.091,9.715 l3.091,0.607V68.8c-1.97,0-4.107-0.899-6.101-2.337v2.109l12.144,4.272v-6.339l0,0c4.25-3.04,7.867-8.55,7.867-13.127 c1.707,0,3.091-1.359,3.091-3.036v-3.157C57.137,45.507,55.753,44.148,54.045,44.148z M44.598,67.499 c0.043-0.023,0.085-0.046,0.127-0.07C44.683,67.453,44.641,67.476,44.598,67.499z M40.81,68.762 c0.068-0.007,0.137-0.017,0.206-0.026C40.947,68.745,40.878,68.756,40.81,68.762z M41.51,68.658 c0.073-0.014,0.145-0.028,0.218-0.045C41.655,68.63,41.582,68.644,41.51,68.658z M42.272,68.475 c0.05-0.014,0.1-0.027,0.151-0.042C42.372,68.448,42.322,68.46,42.272,68.475z M43.142,68.184 c0.01-0.004,0.019-0.008,0.029-0.012C43.161,68.176,43.152,68.18,43.142,68.184z M43.856,67.876 c0.048-0.022,0.096-0.046,0.144-0.07C43.953,67.83,43.905,67.854,43.856,67.876z"
          />
          <g>
            <g>
              <path
                fill="#A0603B"
                strokeWidth={0}
                id="left-under-eye-circle"
                d="M34.99,48.17c0.046,1.324-0.989,2.434-2.313,2.48c-1.324,0.046-2.434-0.989-2.48-2.313 c-0.046-1.324,0.989-2.434,2.313-2.48C33.833,45.811,34.944,46.847,34.99,48.17z"
              />
              <path
                fill="#3C2415"
                strokeWidth={0}
                id="left-outer-eye"
                d="M34.99,47.563c0.046,1.324-0.989,2.434-2.313,2.48c-1.324,0.046-2.434-0.989-2.48-2.313 c-0.046-1.324,0.989-2.434,2.313-2.48C33.833,45.204,34.944,46.24,34.99,47.563z"
              />
              <path
                fill="#FFFFFF"
                strokeWidth={0}
                d="M34.007,48.092c0.012,0.331-0.247,0.608-0.578,0.62c-0.331,0.012-0.608-0.247-0.62-0.578 c-0.012-0.331,0.247-0.609,0.578-0.62C33.718,47.502,33.995,47.761,34.007,48.092z"
              />
              <path
                fill="#3C2415"
                strokeWidth={0}
                id="left-eyebrow"
                d="M35.51,45.731c-0.442-1.027-1.576-1.807-2.821-1.939c-0.763-0.083-2.195,0.03-3.425,1.588 c-0.059,0.005-0.113,0.006-0.182-0.001c-0.137-0.012-0.327-0.056-0.513-0.136c-0.378-0.152-0.692-0.47-0.692-0.47 c-0.022-0.021-0.057-0.029-0.086-0.015c-0.037,0.018-0.053,0.063-0.034,0.1c0,0,0.047,0.104,0.138,0.254 c0.089,0.151,0.229,0.346,0.408,0.516c0.177,0.171,0.388,0.317,0.566,0.406c0.088,0.045,0.168,0.076,0.228,0.097 c0.057,0.02,0.117,0.031,0.107,0.03c0.165,0.017,0.316-0.072,0.388-0.211c0.806-1.125,1.884-1.679,3.034-1.555 c1.017,0.108,1.974,0.756,2.327,1.576c0.068,0.155,0.246,0.225,0.399,0.159C35.505,46.064,35.577,45.886,35.51,45.731z"
              />
            </g>
            <g>
              <path
                fill="#A25432"
                strokeWidth={0}
                id="right-under-eye-circle"
                d="M50.17,48.17c0.046,1.324-0.989,2.434-2.313,2.48c-1.324,0.046-2.434-0.989-2.48-2.313 c-0.046-1.324,0.989-2.434,2.313-2.48C49.013,45.811,50.124,46.847,50.17,48.17z"
              />
              <path
                fill="#3C2415"
                strokeWidth={0}
                id="right-outer-eye"
                d="M50.17,47.563c0.046,1.324-0.989,2.434-2.313,2.48c-1.324,0.046-2.434-0.989-2.48-2.313 c-0.046-1.324,0.989-2.434,2.313-2.48C49.013,45.204,50.124,46.24,50.17,47.563z"
              />
              <path
                fill="#FFFFFF"
                strokeWidth={0}
                d="M49.187,48.092c0.012,0.331-0.247,0.608-0.578,0.62c-0.331,0.012-0.609-0.247-0.62-0.578 c-0.012-0.331,0.247-0.609,0.578-0.62C48.898,47.502,49.175,47.761,49.187,48.092z"
              />
              <path
                fill="#3C2415"
                strokeWidth={0}
                id="right-eyebrow"
                d="M44.857,45.731c0.442-1.027,1.576-1.807,2.821-1.939c0.763-0.083,2.195,0.03,3.425,1.588 c0.059,0.005,0.113,0.006,0.182-0.001c0.137-0.012,0.327-0.056,0.513-0.136c0.378-0.152,0.692-0.47,0.692-0.47 c0.022-0.021,0.057-0.029,0.086-0.015c0.037,0.018,0.053,0.063,0.034,0.1c0,0-0.047,0.104-0.138,0.254 c-0.089,0.151-0.229,0.346-0.408,0.516c-0.177,0.171-0.388,0.317-0.566,0.406c-0.088,0.045-0.168,0.076-0.228,0.097 c-0.057,0.02-0.117,0.031-0.107,0.03c-0.165,0.017-0.316-0.072-0.389-0.211c-0.806-1.125-1.884-1.679-3.034-1.555 c-1.017,0.108-1.974,0.756-2.327,1.576c-0.068,0.155-0.246,0.225-0.399,0.159C44.862,46.064,44.79,45.886,44.857,45.731z"
              />
            </g>
          </g>
          <g>
            <path
              fill="#F05D44"
              strokeWidth={0}
              d="M44.993,59.809c0,2.385-2.175,4.318-4.857,4.318s-4.858-1.933-4.858-4.318H44.993z"
            />
            <path
              fill="#FFFFFF"
              strokeWidth={0}
              d="M43.523,61.429h-6.882c-0.335,0-0.607-0.272-0.607-0.607v-1.012h8.096v1.012 C44.13,61.157,43.858,61.429,43.523,61.429z"
            />
          </g>
          <path
            fill="#3C2417"
            strokeWidth={0}
            d="M50.838,20.328c0.466-0.703,0.744-1.542,0.744-2.449c0-0.322-0.036-0.636-0.101-0.939 c0.063-0.286,0.099-0.582,0.099-0.887c0-1.794-1.15-3.315-2.752-3.878c-0.085-2.274-1.925-1.887-4.207-1.94 c-0.686-1.696-2.346-2.894-4.288-2.894c-1.796,0-3.35,1.026-4.116,2.521c-0.314-0.08-0.641-0.128-0.98-0.128 c-2.178,0-3.944-0.442-3.944,1.736c0,0.073,0.007,0.145,0.011,0.218c-1.832,0.585-3.159,2.3-3.159,4.326 c0,1.071,0.373,2.053,0.992,2.829c-0.476,0.644-0.761,1.438-0.761,2.3c0,0.069,0.007,0.137,0.01,0.206 c-4.879,5.224-5.982,14.064-3.256,22.816c0.198,0.637,1.137,0.525,1.184-0.14c0.553-7.751,3.754-14.271,13.821-10.965 c10.067-3.306,13.269,3.214,13.821,10.965c0.048,0.665,0.986,0.777,1.184,0.14C58.053,34.816,56.601,25.362,50.838,20.328z"
          />
          <path
            fill="#F080B1"
            strokeWidth={0}
            d="M27.055,31.098c0,0,1.466-11.232,13.131-11.232c11.665,0,13.131,11.232,13.131,11.232 s-4.777-7.695-13.408-7.695C31.277,23.403,27.055,31.098,27.055,31.098z"
          />
          <path
            fill="#EF80B0"
            strokeWidth={0}
            d="M65.398,96.5c0,0,0.028-0.148-0.052-1.215c-0.066-0.877-0.205-2.376-0.494-4.926 c-0.726-10.664-6.453-15.726-12.517-17.399c-1.375-0.596-3.377-1.003-6.258-1.154L44.05,85.695h-7.728l-2.025-13.889 c-2.88,0.151-4.882,0.558-6.256,1.154c-6.065,1.673-11.793,6.734-12.52,17.4c-0.353,3.119-0.483,4.666-0.528,5.426 c-0.037,0.617-0.018,0.715-0.018,0.715H65.398z"
          />
        </g>
      </g>
    </g>
  ),
  viewBox: '0 0 80 80'
};
