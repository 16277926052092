import React from 'react';
import { TouchableOpacity } from 'react-native';
import { Icon } from 'components/Icon';
import { NavigationScreenProps } from 'screens/navigation';
import { oc } from 'ts-optchain';
import { connect } from 'react-redux';
import LinkingServices from 'services/LinkingServices';
import { Color } from 'modules/styles/colors';
import { formatDate } from 'modules/utils/DateUtils';
import { FontSize, FontWeight } from 'modules/styles/variables';
import formatMoney from 'services/formatMoney';
import { patientFinancialServicesNumber } from 'modules/constants/phoneNumbers';

import Typography from 'components/UI/Typography';
import ScrollableLayout from 'components/UI/Layout/ScrollableLayout';
import FlexBox from 'components/UI/Layout/FlexBox';
import Spacer from 'components/UI/Layout/Spacer';
import Divider from 'components/UI/Divider';
import StyledButton from 'components/UI/Button/StyledButton';
import Svg from 'components/UI/Svg/Svg';

import {
  PaymentSuccessButtonView,
  ConfrmationGreyTypography,
  BillDetailPanel,
  CenteredFlexBox,
  PaymentConfirmPadding,
  PaymentConfirmMarginView
} from '../styled';

type Props = NavigationScreenProps & {
  profileConsumer: string;
};

interface PaymentConfirmationProps {
  amount?: number;
  confNumber?: string[];
  paidDate?: string;
  accountNumber?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
}

export const PaymentConfirmation = (props: Props) => {
  const { navigation } = props;
  const data: PaymentConfirmationProps = {
    amount: navigation.getParam('amount'),
    confNumber: navigation.getParam('confNumber'),
    paidDate: navigation.getParam('paidDate'),
    accountNumber: navigation.getParam('accountNumber'),
    firstName: navigation.getParam('firstName'),
    lastName: navigation.getParam('lastName'),
    email: navigation.getParam('email')
  };

  const amount = oc(data).amount();
  const confNumber = oc(data)
    .confNumber([])
    .join(', ');
  const paidDate = oc(data).paidDate('N/A');
  const accountNumber = oc(data).accountNumber('');
  const name = `${oc(data).lastName('')}, ${oc(data).firstName('')}`;
  const email = oc(data).email('');

  const closeConfirmation = () => {
    navigation.pop();
  };

  return (
    <ScrollableLayout>
      <BillDetailPanel>
        <PaymentConfirmPadding>
          <FlexBox flex={2} flexDirection="column">
            <FlexBox flexDirection="row" justifyContent="flex-end">
              <TouchableOpacity
                testID="close"
                accessibilityLabel="close button"
                onPress={closeConfirmation}
              >
                <Icon name="close" size={24} accessibilityLabel="Unpaid Bills Close Button" />
              </TouchableOpacity>
            </FlexBox>
          </FlexBox>
          <Spacer size="small" />
        </PaymentConfirmPadding>
        <CenteredFlexBox>
          <Svg name="CheckCircle" height="120" width="120" />
          <Spacer size="medium" />
          <Typography fontSize={FontSize.large}>
            Thank you for your payment of
            <Typography fontWeight={FontWeight.bold}>{` $${formatMoney(amount)}`}</Typography>
          </Typography>
          <Typography textAlign="center" fontSize={FontSize.large}>
            {`Your confirmation number(s)\n${confNumber}`}
          </Typography>
          <Typography fontSize={FontSize.large}>
            {`Amount paid on: ${formatDate(paidDate)}`}
          </Typography>
          <Spacer size="xsmall" />
        </CenteredFlexBox>
        <PaymentConfirmMarginView>
          <Divider middle light />
        </PaymentConfirmMarginView>
        <Spacer size="xsmall" />
        <CenteredFlexBox>
          <Typography fontSize={FontSize.large}>{`Account No: ${accountNumber}`}</Typography>
          <Spacer size="xsmall" />
          <Typography fontSize={FontSize.large}>{`Payer Name: ${name}`}</Typography>
          <Spacer size="xsmall" />
        </CenteredFlexBox>
        <PaymentConfirmMarginView>
          <Divider middle light />
        </PaymentConfirmMarginView>
        <Spacer size="xsmall" />
        <CenteredFlexBox>
          <Spacer size="small" />
          <Typography textAlign="center">
            A confirmation will be sent to{'\n'}
            {`${email}`}
          </Typography>
          <Spacer size="small" />
          <Typography textAlign="center">
            If you have any questions about your bill{'\n'} please call
            <Typography
              onPress={() => {
                LinkingServices.callPhoneNumber(patientFinancialServicesNumber);
              }}
              color={Color.primaryUnderlay}
            >
              {` 1-${patientFinancialServicesNumber}`}
            </Typography>
          </Typography>
          <Spacer size="medium" />
          <ConfrmationGreyTypography textAlign="center">
            Your payment will be posted to your account(s){'\n'} within 1-2 business days
          </ConfrmationGreyTypography>
          <Spacer size="medium" />
        </CenteredFlexBox>
        <PaymentConfirmMarginView>
          <PaymentSuccessButtonView>
            <StyledButton height={50} fullWidth onPress={closeConfirmation}>
              CLOSE
            </StyledButton>
          </PaymentSuccessButtonView>
          <Spacer size="large" />
        </PaymentConfirmMarginView>
      </BillDetailPanel>
    </ScrollableLayout>
  );
};

export default connect()(PaymentConfirmation);
