export const ADDRESS_INFO = {
  title:
    'You have several options for sending in your verification documents. Please make sure to include your Financial Assistance application number on all communication:',
  addressLabel: 'Mailing address',
  address: 'Intermountain Financial Assistance:',
  addressLine1: 'PO Box 27327',
  addressLine2: 'Salt Lake City, Utah 84127',
  localVisit: 'Visit your local Intermountain hospital or clinic where you receive services',
  emailLabel: 'Scan and email to ',
  email: 'financial.assistance@r1rcm.com'
};

export enum ApplicationStatus {
  PENDING = 'P',
  APPROVED = 'A',
  DECLINED = 'D',
  CANCELLED = 'C',
  INCOMPLETE = 'I'
}
export enum ApplicationDetailedStatus {
  PENDING = 'Under Review',
  APPROVED = 'Approved',
  DECLINED = 'Declined',
  CANCELLED = 'Cancelled',
  INCOMPLETE = 'Incomplete'
}

export const FA_SUBMIT_INFO = [
  {
    type: 'item',
    title: 'Copies of your current federal tax return with all schedules, including W-2s'
  },
  {
    type: 'subItem',
    title:
      "If you don't have tax return documents, please submit the non-filing letter from the IRS"
  },
  {
    type: 'item',
    title:
      'Household income verification (e.g., paycheck stubs for the past two pay periods OR your 3 most recent monthly bank statements).'
  },
  {
    type: 'subItem',
    title: "If you can't provide above information, please provide the following, as applicable:"
  },
  {
    type: 'subInnerItem',
    title: 'Title 1 school assistance letter'
  },
  {
    type: 'subInnerItem',
    title:
      'Support letter written by whomever is supporting you financially (Please note: This person will not be liable for your balance)'
  },
  {
    type: 'item',
    title: 'Please also provide following documents, as applicable:'
  },
  {
    type: 'subItem',
    title: 'Pension/retirement statement'
  },
  {
    type: 'subItem',
    title: 'Social security award letter'
  },
  {
    type: 'subItem',
    title: 'Unemployment award letter'
  },
  {
    type: 'subItem',
    title: 'Disability award letter'
  },
  {
    type: 'subItem',
    title: 'Denial letter from Medicaid'
  },
  {
    type: 'subItem',
    title: 'Child support award letter'
  },
  {
    type: 'subItem',
    title: 'Alimony award letter'
  },
  {
    type: 'item',
    title: "If you don't have insurance, we will also require the following:"
  },
  {
    type: 'subItem',
    title: 'Medicaid denial letter'
  },
  {
    type: 'subItem',
    title:
      'Screening completed by an Eligibility Counselor at the Intermountain facility (while in-patient)'
  },
  {
    type: 'item',
    title:
      'Please note: above list is not exhaustive and Intermountain will contact you via mail regarding specific verification information you are missing'
  }
];
