import { MuiTypography, MuiListItem } from 'theme/material-ui';
import styled from 'styled-components';
import { Color } from 'modules/styles/colors';

export const StyledTitle = styled(MuiTypography)`
  &.MuiTypography-root {
    font-weight: bold;
  }
`;

export const StyledMuiBoxContainer = styled(MuiListItem)`
  height: 4em;
  display: flex;
  justify-content: space-between;
  cursor: pointer;

  &:not(:last-child) {
    border-bottom: 1px solid ${Color.grayLight};
  }
`;
