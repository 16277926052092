import styled, { css } from 'styled-components';
import { BasicAutoComplete } from 'components/AutoComplete/BasicAutoComplete';
import { Color } from 'modules/styles/colors';
import { FontSize, BorderRadius } from 'modules/styles/variables';
import { dfdDefaultTheme, MuiBox, MuiButton, MuiTextField, MuiChip } from 'theme/material-ui';
import { FontWeight, Spacing, ZIndex } from 'modules/styles/variables.web';

export const Container = styled(MuiBox)`
  height: 100%;
  overflow: auto;
  background: ${Color.grayLight3};
  padding-bottom: ${Spacing.xLarge}px;
`;

export const ListFetching = styled(MuiBox)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.6;
  background: ${Color.grayLight3};
  z-index: ${ZIndex.high};
`;

export const ListItemLink = styled.a`
  display: flex;
  width: 100%;
  padding: ${Spacing.medium}px 0;
  align-items: center;
  justify-content: space-between;
  color: ${Color.primary};
  font-size: ${FontSize.large}px;
  font-weight: ${FontWeight.bold};
`;

export const StyledMuiBox = styled(MuiBox)`
  && {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    padding: 8px 3px;
    cursor: pointer;
  }
`;

export const StyledListBox = styled.ul`
  && {
    &.MuiAutocomplete-listbox {
      padding: 0;
    }
  }
`;

// Refactor Components
export const SEARCH_BUTTON_WIDTH = 54;
export const INPUT_HEIGHT = 42;
export const INPUT_WIDTH = 555;

export const Autocomplete = styled(BasicAutoComplete)``;

export const AutocompleteInput = styled(MuiTextField)`
  &&& .MuiInput-root {
    padding: 0 ${dfdDefaultTheme.spacing(4)}px 0 ${dfdDefaultTheme.spacing(2.5)}px;
    border: 1px solid ${Color.grayLight};
    border-radius: ${BorderRadius.full}px;
    background-color: ${Color.white};
    max-width: ${INPUT_WIDTH}px;
    overflow: hidden;

    & > input {
      height: ${INPUT_HEIGHT}px;
      min-height: ${INPUT_HEIGHT}px;

      &::-ms-clear {
        display: none;
      }
    }
  }
  &&& .MuiInput-underline:before,
  &&& .MuiInput-underline:after {
    display: none;
  }
  &&& .MuiAutocomplete-endAdornment {
    right: ${SEARCH_BUTTON_WIDTH + dfdDefaultTheme.spacing(2)}px;
  }
`;

export const SearchButton = styled(MuiButton)`
  &.MuiButton-root {
    top: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    background-color: ${Color.grayLight};
    border-radius: 0;
    min-width: ${SEARCH_BUTTON_WIDTH}px;
  }
  &.MuiButton-root:hover {
    background-color: ${Color.grayLight};
  }
  & .MuiIcon-root {
    font-size: 1.75rem;
  }
`;

interface FiltersButtonProps {
  active: number;
}

export const FiltersButton = styled(MuiButton)<FiltersButtonProps>`
  &.MuiButton-root {
    padding: ${dfdDefaultTheme.spacing(3.5)}px;
    border-radius: ${BorderRadius.none}px;
    background-color: ${Color.grayLight};
    color: ${Color.black};

    @media (max-width: 375px) {
      padding: ${dfdDefaultTheme.spacing(3.5, 2)};
    }

    &.MuiButton-root:hover {
      background-color: ${Color.grayLight};
      color: ${Color.black};
    }

    ${props =>
      props.active &&
      css`
        background-color: ${Color.purple};
        color: ${Color.white};
        &.MuiButton-root:hover {
          background-color: ${Color.purple};
          color: ${Color.white};
        }
      `}
  }

  & .MuiTypography-root {
    line-height: 1;
    margin-right: ${dfdDefaultTheme.spacing(0.75)}px;
  }
`;

export const Chip = styled(MuiChip)`
  &.MuiChip-colorPrimary {
    color: ${Color.white};
    height: 36px;
    min-width: 110px;
    border-radius: ${BorderRadius.base / 2}px;
    background-color: ${Color.purple};
    justify-content: space-between;
    padding-right: ${dfdDefaultTheme.spacing(0.5)}px;
  }

  &.MuiChip-label {
    height: 17.14px;
    width: 131px;
    font-size: ${FontSize.small}px;
    letter-spacing: 0.41px;
    line-height: 1.5;
  }
`;

export const CountCircle = styled.span`
  height: ${FontSize.large}px;
  width: ${FontSize.large}px;
  font-size: ${FontSize.xsmall}px;
  border-radius: 50%;
  padding: ${dfdDefaultTheme.spacing(0.25)}px;
  background-color: ${Color.secondary};
  color: ${Color.white};
  text-align: center;
  line-height: 1;
  box-sizing: content-box;
  white-space: nowrap;

  &::before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    padding-top: 100%;
    height: 0;
  }
`;
