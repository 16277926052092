import React, { useEffect, useState } from 'react';
import { getGoogleMap } from './utils';
import { Spacing } from 'modules/styles/variables';
import { MuiBox } from 'theme/material-ui';
import { connect, useDispatch } from 'react-redux';
import { RootState } from 'store/types';
import { geolocationCoordsSelector, geolocationDeniedSelector } from 'store/geolocation/selectors';
import { Coordinate } from 'modules/types/common';
import { getCurrentPosition } from 'store/geolocation/actions';
import {
  LatLng,
  MapLocation,
  GoogleMapMarker,
  GoogleMapObject,
  GoogleMapInfoWindow
} from 'services/LocationService/types';
import { Color } from 'modules/styles/colors';

export const DEFAULT_LAT_LNG = { lat: 40.77908, lng: -111.87948 };

interface GoogleMapParams {
  isDeniged: boolean;
  mapId: string;
  labelOrigin?: MapLocation;
  origin?: Coordinate;
  locations?: MapLocation[];
  latLng?: LatLng;
  zoom?: number;
}

export function setCenter(map: GoogleMapObject, origin?: Coordinate, latLng?: LatLng) {
  const lat = parseFloat(String(latLng?.lat || origin?.latitude || DEFAULT_LAT_LNG.lat)); // TODO UTAH DEFAULT
  const lng = parseFloat(String(latLng?.lng || origin?.longitude || DEFAULT_LAT_LNG.lng));
  const center = { lat, lng };
  map.setCenter(center);
}

export function initMap(
  mapId: string,
  setMap: (val: GoogleMapObject) => void,
  map?: GoogleMapObject,
  origin?: Coordinate,
  latLng?: LatLng
) {
  if (window.google && !map && mapId) {
    const ele = document.getElementById(mapId);
    if (ele) {
      // If no element this was triggered when it was not visible.
      const map = new window.google.maps.Map(ele, {
        zoom: 8
      });

      setMap(map);
      setCenter(map, origin, latLng);
    }
  }
}

// reset all markers
export function deleteMarkers(
  markers: GoogleMapMarker[],
  setMarkers: (val: GoogleMapMarker[]) => void
) {
  markers.forEach(marker => marker.setMap(null));
  markers.length = 0;
  setMarkers([]);
}

export function handleLocationsChange(
  lastMarkers: GoogleMapMarker[],
  setMarkers: (val: GoogleMapMarker[]) => void,
  map?: GoogleMapObject,
  locations?: MapLocation[],
  labelOrigin?: MapLocation
) {
  if (map && locations) {
    deleteMarkers(lastMarkers, setMarkers); // Remove all old markers
    const markers = [];
    const bounds = new window.google.maps.LatLngBounds();
    locations.forEach((loc: MapLocation) => {
      const { icon, name, lat, lng, content, onClick } = loc;
      const label = {
        text: name,
        color: Color.grayHue2,
        fontSize: '1rem'
      };
      bounds.extend({ lat, lng });
      const marker = new window.google.maps.Marker({
        label,
        position: { lat, lng },
        icon,
        map,
        clickable: !!(content || onClick)
      });
      if (content || onClick) {
        let infowindow: GoogleMapInfoWindow;

        if (content) {
          infowindow = new window.google.maps.InfoWindow({
            content,
            maxWidth: 200
          });
        }

        marker.addListener('click', () => {
          if (infowindow) {
            infowindow.open(map, marker);
          }
          if (onClick) {
            onClick();
          }
        });
      }
      markers.push(marker);
    });

    if (labelOrigin) {
      markers.push(
        new window.google.maps.Marker({
          label: labelOrigin.name,
          position: { lat: labelOrigin.lat, lng: labelOrigin.lng },
          map
        })
      );
    }
    setMarkers(markers);
    map.fitBounds(bounds);
    map.setCenter(bounds.getCenter());
  }
}

export function GoogleMapComponent(params: GoogleMapParams) {
  const dispatch = useDispatch();
  const { mapId, origin, isDeniged, latLng, locations, labelOrigin, zoom = 12 } = params;

  const [map, setMap] = useState<GoogleMapObject>();
  const [markers, setMarkers] = useState<GoogleMapMarker[]>([]);

  useEffect(() => initMap(mapId, setMap, map, origin, latLng), [mapId]);

  // Handle Map Center Change
  useEffect(() => {
    if (map && (origin || latLng)) {
      setCenter(map, origin, latLng);
    }
    if (map && latLng) {
      map.setZoom(zoom);
    }
  }, [origin, map, isDeniged, latLng]);

  // Handle Locations Change
  useEffect(() => handleLocationsChange(markers, setMarkers, map, locations, labelOrigin), [
    locations,
    map
  ]);

  useEffect(() => {
    if (!origin && !isDeniged) {
      dispatch(getCurrentPosition());
    }
    getGoogleMap().then(() => initMap(mapId, setMap, map, origin, latLng));
  }, []);

  return (
    <MuiBox
      data-testid="google-map-element"
      minHeight={Spacing.xxLarge * 3}
      flex="1 1 auto"
      width="100%"
      height="100%"
      id={mapId}
    ></MuiBox>
  );
}
const mapStateToProps = (state: RootState) => ({
  origin: geolocationCoordsSelector(state),
  isDeniged: geolocationDeniedSelector(state)
});

export default connect(mapStateToProps)(GoogleMapComponent);
