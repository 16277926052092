import React from 'react';
import FlexBox from 'components/UI/Layout/FlexBox';
import { FontSize, Spacing, FontWeight } from 'modules/styles/variables';
import Typography from 'components/UI/Typography';
import Divider from 'components/UI/Divider';
import { Label } from './styled';
import { BILLLING_DUE_STATUS_TYPES } from 'lib/dashboard/utils';

interface DashboardBillingHeaderProps {
  dueStatus?: string;
}

const DashboardBillingHeader = ({ dueStatus = '' }: DashboardBillingHeaderProps) => {
  const { title, color } = BILLLING_DUE_STATUS_TYPES[dueStatus] || {};
  return (
    <div data-testid="dashboard-billing-header">
      <FlexBox flexDirection="row" alignItems="center">
        <FlexBox spacing={Spacing.large} flexDirection="row" alignItems="center" flex={1}>
          <Typography fontSize={FontSize.large} fontWeight={FontWeight.bold}>
            Billing
          </Typography>
        </FlexBox>
        <Label style={{ borderColor: color }}>
          <Typography
            data-testid="dashboard-billing-badge-title"
            color={color}
            fontSize={FontSize.small}
          >
            {title}
          </Typography>
        </Label>
      </FlexBox>
      <Divider />
    </div>
  );
};

export default DashboardBillingHeader;
