import React, { useMemo, useState } from 'react';

import { ConnectCareCheckboxGroup, ConnectCareTextField } from 'components/ConnectCare';
import chunk from 'lodash/chunk';
import { Color } from 'modules/styles/colors';
import { Spacing } from 'modules/styles/variables';
import { spacings } from 'modules/utils/StylesUtils';
import { Topic, UpdateVisitContextForm, VisitContext } from 'store/amwell/types';
import { MuiBox, MuiGrid } from 'theme/material-ui';

export interface Props {
  columns: number;
  visitContext: VisitContext;
  loading: boolean;
  onVisitContextChange: (updateVisitContextForm: UpdateVisitContextForm) => void;
  isLargeScreen?: boolean;
}

const getTopics = (visitContext: VisitContext) => {
  const { topics } = visitContext;

  return topics?.length ? topics : [];
};

// a chunck is an array of topics put into columns
const getChunks = (topics: Topic[], columns: number) => {
  return chunk(topics, Math.ceil(topics.length / columns));
};

function ConnectCareTriageTopics(props: Props) {
  const { columns, visitContext, onVisitContextChange, loading, isLargeScreen } = props;

  const [otherTopic, setOtherTopic] = useState(visitContext.otherTopic);
  const topics = useMemo(() => getTopics(visitContext), [visitContext]);
  const chunks = useMemo(() => getChunks(topics, columns), [topics, columns]);

  const onTopicChange = (chunkIndex: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const index = +e.target.id;
    const topicsToUpdate: Topic[] = [...visitContext.topics];

    const count = [...Array(chunkIndex + 1)].reduce((acc, _, idx, arr) => {
      return arr.length - 1 === idx
        ? acc + chunks[idx].slice(0, index).length
        : acc + chunks[idx].length;
    }, 0);

    topicsToUpdate[count].selected = e.target.checked;
    onVisitContextChange({ topics: topicsToUpdate });
  };

  if (!chunks.length) return null;

  const onOtherTopicBlur = () => {
    onVisitContextChange({ otherTopic });
  };

  return (
    <>
      <MuiGrid item xs>
        <MuiBox bgcolor={Color.foreColor} padding={spacings(4, isLargeScreen ? 8 : 3)}>
          <MuiGrid container item xs={12} wrap="nowrap">
            {chunks.map((c, idx) => (
              <MuiBox width="100%" paddingRight={2}>
                <ConnectCareCheckboxGroup
                  fullWidth
                  items={c}
                  disabled={loading}
                  itemToString={(item: Topic) => (item ? item.title : '')}
                  onChange={onTopicChange(idx)}
                  checkedKey="selected"
                  labelPlacement="start"
                  justifyContent="space-between"
                  borderBottom={`1px solid ${Color.grayLight}`}
                  paddingX={Spacing.smallMedium}
                />
              </MuiBox>
            ))}
          </MuiGrid>
          <MuiGrid container item xs={12}>
            <MuiBox mb={1} width="100%" paddingTop={Spacing.xSmall}>
              <ConnectCareTextField
                label="Other Condition"
                value={otherTopic}
                disabled={loading}
                onChange={e => setOtherTopic(e.target.value)}
                onBlur={onOtherTopicBlur}
              />
            </MuiBox>
          </MuiGrid>
        </MuiBox>
      </MuiGrid>
    </>
  );
}

export default ConnectCareTriageTopics;
