import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import startCase from 'lodash/startCase';
import Config from 'react-native-config';

import Banner from 'components/UI/Banner/Banner';
import { Alert } from 'components/Alert';
import { Breadcrumbs } from 'components/Breadcrumbs';
import {
  ConnectCareAppointmentDetailsBanner,
  ConnectCareAppointmentDetailsCrumb,
  ConnectCareLoading,
  ConnectCareStepper,
  ConnectCareWaitingRoomBanner,
  ConnectCareNoPhoneDialog,
  ConnectCareTermsAndConditionsDialog
} from 'components/ConnectCare';

import {
  consumerUiDataIsFetchingSelector,
  connectCareTermsAndConditionsSelector
} from 'store/consumerPreferences/selectors';
import { getConsumerSharedUiData } from 'store/consumerPreferences/actions';
import { consumerPrimaryPhoneSelector, profileConsumerSelector } from 'store/profile/selectors';
import {
  activityStatusSelector,
  consumerAuthenticatedSelector,
  storageSelector,
  visitRequirementsSelector
} from 'store/amwell/selectors';
import { isImpersonatingSelector } from 'store/account/selectors';
import { ActivityStatus } from 'store/amwell/types';
import { getStorage } from 'store/amwell/actions';
import { useRoutesToCrumbs } from 'hooks/useRoutesToCrumbs';
import { useRoutes } from 'hooks/useRoutes';
import { useProxySwitcherEffect } from 'hooks/useProxySwitcherEffect';

import { ComponentProps, RouteData, WithRoutes } from './types';

import GetCareHome from 'screens/GetCareHome/GetCareHome';
import ConnectCareLogin from './ConnectCareLogin';
import ConnectCareLanding from './ConnectCareLanding';
import ConnectCareLocationSelect from './ConnectCareLocationSelect';
import ConnectCarePatientSelect from './ConnectCarePatientSelect';
import ConnectCareProviderSelect from './ConnectCareProviderSelect';
import ConnectCareMedicalHistory from './ConnectCareMedicalHistory';
import ConnectCareVitals from './ConnectCareVitals';
import ConnectCareTriage from './ConnectCareTriage';
import ConnectCareInsuranceSelect from './ConnectCareInsuranceSelect';
import ConnectCarePharmacySelect from './ConnectCarePharmacySelect';
import ConnectCarePaymentMethodSelect from './ConnectCarePaymentMethodSelect';
import ConnectCareWaitingRoom from './ConnectCareWaitingRoom';
import ConnectCareVisitSummary from './ConnectCareVisitSummary';
import ConnectCareAppointmentDetails from './ConnectCareAppointmentDetails';
import ConnectCareAccountRecovery from './ConnectCareAccountRecovery';
import ConnectCareNavigator from './ConnectCareNavigator';
import ConnectCareMedications from './ConnectCareMedications';
import ConnectCareHealthDocuments from './ConnectCareHealthDocuments';

export const connectCareFormRoutes: RouteData[] = [
  {
    path: '/location',
    component: ConnectCareLocationSelect,
    componentName: 'ConnectCareLocationSelect',
    icon: 'WebTitleGetCareNow',
    title: 'Current Location',
    crumbLabel: 'Location',
    deps: []
  },
  {
    path: '/patients',
    component: ConnectCarePatientSelect,
    componentName: 'ConnectCarePatientSelect',
    icon: 'WebTitleGetCareNow',
    title: 'Select Patient',
    crumbLabel: 'Patients',
    deps: ['hasLocation']
  },
  {
    path: '/providers',
    component: ConnectCareProviderSelect,
    componentName: 'ConnectCareProviderSelect',
    title: 'Select Provider',
    crumbLabel: 'Providers',
    icon: 'WebTitleGetCareNow',
    deps: ['hasLocation', 'hasActiveConsumer']
  },
  {
    path: '/medical-history',
    component: ConnectCareMedicalHistory,
    componentName: 'ConnectCareMedicalHistory',
    icon: 'WebTitleGetCareNow',
    title: 'Medical History',
    crumbLabel: 'Medical History',
    deps: ['hasLocation', 'hasActiveConsumer', 'hasProviderOrSpecialty']
  },
  {
    path: '/medications',
    component: ConnectCareMedications,
    componentName: 'ConnectCareMedications',
    icon: 'WebTitleGetCareNow',
    title: 'Medications',
    crumbLabel: 'Medications',
    deps: ['hasLocation', 'hasActiveConsumer', 'hasProviderOrSpecialty']
  },
  {
    path: '/vitals',
    component: ConnectCareVitals,
    componentName: 'ConnectCareVitals',
    icon: 'WebTitleGetCareNow',
    title: 'Vitals',
    crumbLabel: 'Vitals',
    deps: ['hasLocation', 'hasActiveConsumer', 'hasProviderOrSpecialty']
  },
  {
    path: '/health-documents',
    component: ConnectCareHealthDocuments,
    componentName: 'ConnectCareHealthDocuments',
    icon: 'WebTitleGetCareNow',
    title: 'Share Images & Files',
    crumbLabel: 'Share Images & Files',
    deps: ['hasLocation', 'hasActiveConsumer', 'hasProviderOrSpecialty']
  },
  {
    path: '/your-visit',
    component: ConnectCareTriage,
    componentName: 'ConnectCareTriage',
    icon: 'WebTitleGetCareNow',
    title: 'Your Visit',
    crumbLabel: 'Your Visit',
    deps: ['hasLocation', 'hasActiveConsumer', 'hasProviderOrSpecialty']
  },
  {
    path: '/insurance',
    component: ConnectCareInsuranceSelect,
    componentName: 'ConnectCareInsuranceSelect',
    icon: 'WebTitleGetCareNow',
    title: 'Select Insurance',
    crumbLabel: 'Insurance',
    deps: ['hasLocation', 'hasActiveConsumer', 'hasProviderOrSpecialty', 'hasVisitContext']
  },
  {
    path: '/pharmacy',
    component: ConnectCarePharmacySelect,
    componentName: 'ConnectCarePharmacySelect',
    icon: 'WebTitleGetCareNow',
    title: 'Pharmacy',
    crumbLabel: 'Pharmacy',
    deps: ['hasLocation', 'hasActiveConsumer', 'hasProviderOrSpecialty', 'hasVisitContext']
  },
  {
    path: '/payment-information',
    component: ConnectCarePaymentMethodSelect,
    componentName: 'ConnectCarePaymentMethodSelect',
    icon: 'WebTitleGetCareNow',
    title: 'Payment',
    crumbLabel: 'Payment Information',
    deps: ['hasLocation', 'hasActiveConsumer', 'hasProviderOrSpecialty', 'hasVisitContext']
  },
  {
    path: '/waiting-room',
    component: ConnectCareWaitingRoom,
    componentName: 'ConnectCareWaitingRoom',
    title: 'Waiting Room',
    crumbLabel: 'Waiting Room',
    includeStepper: false,
    align: 'center',
    bannerComponent: ConnectCareWaitingRoomBanner,
    deps: ['hasActiveVisit']
  },
  {
    path: '/visit-summary',
    component: ConnectCareVisitSummary,
    componentName: 'ConnectCareVisitSummary',
    icon: 'WebTitleGetCareNow',
    title: 'Visit Summary',
    crumbLabel: 'Visit Summary',
    includeStepper: false,
    deps: ['hasCanceledOrEndedVisit']
  }
];

const isRouter = (r: Partial<RouteData>) => r.componentName?.includes('Router');
const filterRouter = (routes: RouteData[]) => routes.filter(r => !isRouter(r));

const ConnectCareComponent = ({
  component: Component,
  routes = [],
  stepRoutes = [],
  ...rest
}: ComponentProps) => {
  const crumbs = useRoutesToCrumbs(filterRouter(routes));
  const activeStep = stepRoutes.findIndex((s: RouteData) => s.path === rest.location.pathname);

  return (
    <>
      {rest.includeCrumbs ? <Breadcrumbs crumbs={crumbs} /> : null}
      {rest.includeBanner ? (
        <Banner
          message={rest.match.params.title ? startCase(rest.match.params.title) : rest.title}
          subMessage={rest.subTitle}
          icon={rest.icon || null}
          {...rest}
        />
      ) : null}
      {rest.includeStepper ? (
        <ConnectCareStepper activeStep={activeStep} steps={stepRoutes} />
      ) : null}
      <Component routes={routes} steps={stepRoutes} activeStep={activeStep} {...rest} />
    </>
  );
};

// ▼ Multi Form ▼ //

const ConnectCareFormRoute = ({ exact, path, ...rest }: WithRoutes<RouteData>) => {
  const requirements: { [key: string]: boolean } = useSelector(visitRequirementsSelector);
  const activityStatus = useSelector(activityStatusSelector);

  const getRedirectTo = (path: string, init = true): string => {
    const routeIdx = rest.routes.findIndex(r => r.path === path);
    const route = rest.routes[routeIdx];

    let redirectTo = '';

    if (route) {
      const hasNoRequirements = !route.deps?.length;
      const hasAllRequirements = route.deps?.every(dep => requirements[dep]);
      const prevRoute = rest.routes[routeIdx - 1];

      if ((hasNoRequirements || hasAllRequirements) && init) {
        // Route (has no requirements || has all requirements) on first iteration
        redirectTo = '';
      } else if (hasNoRequirements || hasAllRequirements) {
        // Route has all requirements after second+ iterations
        redirectTo = path;
      } else if (prevRoute) {
        // PrevRoute exists so loop function until finding the redirect path
        redirectTo = getRedirectTo(prevRoute.path, false);
      }
    }

    return redirectTo;
  };

  const redirectTo = useMemo(() => {
    return getRedirectTo(window.location.pathname);
  }, [window.location.pathname]);

  const alert = useCallback(() => {
    Alert.alert(
      'Unable to access the page',
      'You have been redirected to a step that needs to be completed'
    );
  }, [redirectTo]);

  if (redirectTo && activityStatus !== ActivityStatus.END_VISIT) {
    alert();
    return <Redirect to={redirectTo} />;
  }

  return (
    <Route
      exact={exact}
      path={path}
      render={props => <ConnectCareComponent {...rest} {...props} />}
    />
  );
};

const ConnectCareFormRouter = (props: WithRoutes) => {
  const routes = useRoutes(connectCareFormRoutes);
  const stepRoutes = routes.filter(r => r.includeStepper !== false);

  return (
    <Switch>
      {routes.map(r => (
        <ConnectCareFormRoute
          key={r.path}
          stepRoutes={stepRoutes}
          routes={routes.concat(props.routes)}
          includeStepper={r.includeStepper || true}
          includeBanner={r.includeBanner || true}
          includeCrumbs={r.includeCrumbs || true}
          {...r}
        />
      ))}
      <Redirect to={`${props.match.path}/patients`} />
    </Switch>
  );
};

// ▼ Logged-in Session ▼ //

const connectCareSessionRoutes: RouteData[] = [
  {
    path: '/appointments/:id',
    component: ConnectCareAppointmentDetails,
    componentName: 'ConnectCareAppointmentDetails',
    bannerComponent: ConnectCareAppointmentDetailsBanner,
    crumbComponent: ConnectCareAppointmentDetailsCrumb
  },
  {
    path: '/form',
    component: ConnectCareFormRouter,
    componentName: 'ConnectCareFormRouter',
    includeCrumbs: false,
    includeBanner: false
  }
];

const navigatorRoute: RouteData = {
  exact: true,
  path: '/navigator',
  component: ConnectCareNavigator,
  componentName: 'ConnectCareNavigator',
  title: 'Connect Care Navigator',
  crumbLabel: 'Navigator'
};

const ConnectCareSessionRoute = ({ component: Component, ...rest }: WithRoutes<RouteData>) => {
  const authenticated = useSelector(consumerAuthenticatedSelector);
  const activityStatus = useSelector(activityStatusSelector);
  if (!authenticated && activityStatus !== ActivityStatus.EXPIRED) {
    return <Redirect to="/u/get-care-now/connect-care/login" />;
  }

  return (
    <Route
      path={rest.path}
      exact={rest.exact}
      render={props =>
        isRouter(rest) ? (
          <Component {...rest} {...props} />
        ) : (
          <ConnectCareComponent component={Component} {...rest} {...props} />
        )
      }
    />
  );
};

const ConnectCareSessionRouter = ({ match, ...rest }: WithRoutes) => {
  const DEV = process.env.NODE_ENV === 'development';

  const routes = useRoutes(
    DEV ? [...connectCareSessionRoutes, navigatorRoute] : connectCareSessionRoutes
  );

  const dispatch = useDispatch();
  const storage = useSelector(storageSelector);
  const sessionLoading = !storage.success || storage.loading;

  useEffect(() => {
    dispatch(getStorage());
  }, []);

  if (sessionLoading) {
    return <ConnectCareLoading />;
  }

  return (
    <Switch>
      {routes.map(r => (
        <ConnectCareSessionRoute
          {...r}
          key={r.path}
          exact={r.exact || false}
          includeBanner={r.includeBanner || true}
          includeCrumbs={r.includeCrumbs || true}
          routes={routes.concat(rest.routes)}
        />
      ))}
      <Redirect to={`${match.path}`} />
    </Switch>
  );
};

// ▼ Root ▼ //

const connectCareRoutes: RouteData[] = [
  {
    path: '/login/account-recovery',
    component: ConnectCareAccountRecovery,
    componentName: 'ConnectCareAccountRecovery',
    crumbLabel: 'Account Recovery'
  },
  {
    path: '/login',
    component: ConnectCareLogin,
    componentName: 'ConnectCareLogin',
    crumbLabel: 'Login'
  },
  {
    exact: true,
    path: '/',
    component: ConnectCareLanding,
    componentName: 'ConnectCareLanding',
    icon: 'WebTitleGetCareNow',
    title: 'Connect Care',
    crumbLabel: 'Virtual Care'
  },
  {
    path: '/',
    component: ConnectCareSessionRouter,
    componentName: 'ConnectCareSessionRouter',
    includeCrumbs: false
  }
];

const getCareRoute: RouteData = {
  exact: true,
  path: '/u/get-care-now',
  component: GetCareHome,
  crumbLabel: 'Get Care'
};

const ConnectCareRouter = ({ match, history }: RouteComponentProps) => {
  let routes = useRoutes(connectCareRoutes);
  routes = routes.concat([getCareRoute]);
  const crumbs = useRoutesToCrumbs(filterRouter(routes));

  const dispatch = useDispatch();
  const primaryPhone = useSelector(consumerPrimaryPhoneSelector);
  const acceptedTC = useSelector(connectCareTermsAndConditionsSelector);
  const isProxyUser = useSelector(isImpersonatingSelector);
  const uiDataLoading = useSelector(consumerUiDataIsFetchingSelector);
  const consumer = useSelector(profileConsumerSelector);
  const [hasPrimaryPhone, setHasPrimaryPhone] = useState(!!primaryPhone);

  useProxySwitcherEffect(() => {
    dispatch(getConsumerSharedUiData(consumer.consumerId));
  }, []);

  const handleConnectCareNoPhoneDialogSubmit = () => {
    setHasPrimaryPhone(true);
    history.push('/u/get-care-now/connect-care');
  };

  if (!Config.CONNECT_CARE_APP) {
    window.location.href = Config.AMWELL_WHITE_LABEL_URL;
  }

  if (uiDataLoading) {
    return <ConnectCareLoading />;
  }

  if (!isProxyUser && !acceptedTC) {
    return <ConnectCareTermsAndConditionsDialog isVisible onClose={history.goBack} />;
  }

  if (!hasPrimaryPhone) {
    return (
      <ConnectCareNoPhoneDialog
        isVisible
        onClose={history.goBack}
        onSubmit={handleConnectCareNoPhoneDialogSubmit}
      />
    );
  }

  return (
    <Switch>
      {routes.map(({ component: Component, ...r }) => (
        <Route
          key={r.path}
          path={r.path}
          exact={r.exact || false}
          render={props => (
            <>
              {r.includeCrumbs === false ? null : <Breadcrumbs crumbs={crumbs} />}
              <Component routes={routes} {...r} {...props} />
            </>
          )}
        />
      ))}
      <Redirect to={`${match.path}`} />
    </Switch>
  );
};

export default ConnectCareRouter;
