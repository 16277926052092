import React from 'react';
import { MuiBox, MuiGrid, MuiTypography } from 'theme/material-ui';
import Svg from 'components/UI/Svg/Svg';
import { AlertWrapper, AlertIconWrapper } from './styled';
import { IconSize, FontSize } from 'modules/styles/variables';
import { Color } from 'modules/styles/colors';

interface Props {
  text: string;
  isError?: boolean;
}

const InfoLabel = ({ text, isError }: Props) => (
  <MuiBox>
    <AlertWrapper
      isError={isError}
      container
      spacing={isError ? 3 : 2}
      alignItems="center"
      wrap="nowrap"
    >
      <AlertIconWrapper isError={isError} item>
        {isError ? (
          <Svg name="WarningIcon" set="assets" size={IconSize.base} color={Color.red} />
        ) : (
          <Svg name="CheckCircleOutline" set="material" size={IconSize.small} color={Color.white} />
        )}
      </AlertIconWrapper>
      <MuiGrid item>
        <MuiTypography fontSize={FontSize.base}>{text}</MuiTypography>
      </MuiGrid>
    </AlertWrapper>
  </MuiBox>
);

export default InfoLabel;
