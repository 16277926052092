import React, { useEffect, useState } from 'react';
import isObject from 'lodash/isObject';
import { Color } from 'modules/styles/colors';
import { FontSize, FontWeight } from 'modules/styles/variables';
import { wordsObjectForBoldStyling, BuildedElement } from 'modules/utils/StringUtils';

import { MuiTypography } from 'theme/material-ui';

export interface SentenceWithBoldWordsProps {
  boldStyle: string;
  boldWords: number[];
  componentType?: string;
  fontSize?: FontSize;
  sentence: string;
}

export const SentenceWithBoldWords = ({
  fontSize = FontSize.large,
  sentence,
  boldWords,
  boldStyle,
  componentType = 'span'
}: SentenceWithBoldWordsProps) => {
  const [buildedSentence, setBuildedSentence] = useState<BuildedElement[]>([]);

  useEffect(() => {
    if (sentence.length > 0) {
      const aux = wordsObjectForBoldStyling(sentence, boldWords);
      setBuildedSentence(aux);
    }
  }, [sentence.length]);

  if (!sentence.length) {
    return null;
  }

  return (
    <MuiTypography component={componentType} data-testid="sentence-with-bold-words">
      {buildedSentence.map((item: BuildedElement, idx) => {
        const key = `${idx}-${item.word || item}`;
        return (
          <MuiTypography
            data-testid="word"
            component="span"
            fontSize={fontSize}
            color={Color.black}
            fontWeight={isObject(item) ? boldStyle : FontWeight.normal}
            key={key}
          >
            {isObject(item) ? `${item.word} ` : `${item} `}
          </MuiTypography>
        );
      })}
    </MuiTypography>
  );
};
