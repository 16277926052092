import { regExNumAndString } from 'screens/ConnectCare/utils';
import * as Yup from 'yup';

export const registerValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .required('Please enter their first name')
    .max(50, 'Must be less than 50 characters'),
  middleName: Yup.string().max(50, 'Must be less than 50 characters'),
  lastName: Yup.string()
    .required('Please enter their last name')
    .max(50, 'Must be less than 50 characters'),
  birthDate: Yup.string().required('Please enter their birthdate'),
  subscriberDateOfBirth: Yup.string().required('Please enter their birthdate'),
  subscriberRelationship: Yup.string().required('Please enter your relationship'),
  address: Yup.string().required('Please enter your address'),
  otherRelationship: Yup.string().when('relationship', {
    is: (val: string) => val === 'Other',
    then: Yup.string()
      .required('Please enter relationship')
      .max(500, 'Must be less than 500 characters')
  }),
  phoneNumber: Yup.string()
    .required()
    .when('phones', {
      is: (val: { type?: string }) => val?.type === 'CELLPHONE',
      then: Yup.string()
        .matches(/^[0-9]{7}$/, 'Phone must match format XXX XXXX')
        .required('Please select provide a valid phone number.')
    }),
  email: Yup.string()
    .email('Plase enter valid email address')
    .required('Please enter their email address')
    .max(50, 'Must be less than 50 characters'),
  isPrimarySubscriber: Yup.string().required(),
  subscriberId: Yup.string()
    .matches(regExNumAndString, 'Please do not use special characters.')
    .required('Please enter a subscriber id.'),
  planNumber: Yup.string()
    .matches(regExNumAndString, 'Please do not use special characters.')
    .required('Please enter a plan number.'),
  planNetwork: Yup.string()
    .max(50, 'Must be less than 50 characters')
    .required('Please enter a Plan Network.'),
  subscriberFullName: Yup.string()
    .required('Please enter their full name')
    .max(50, 'Must be less than 50 characters'),
  subscriberGender: Yup.string()
    .required('Please enter their Gender')
    .max(50, 'Must be less than 50 characters')
});
