import dayjs from 'dayjs';
import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGetMedicalTestResultList } from 'hooks/queries/useGetMedicalTestResultList';
import { cancelActionRequest } from 'store/networkMiddleware/cancellationMiddleware';
import {
  getTestResultDetails,
  GetTestResultDetails,
  GET_TEST_RESULT_DETAILS,
  resetTestResultDetails
} from 'store/testResults/actions';
import { MAX_YEARS_TO_QUERY } from 'store/testResults/constants';
import {
  testResultDetailsDataSelector,
  testResultDetailsLoadingSelector,
  testResultDetailsErrorSelector
} from 'store/testResults/selectors';
import { formatMhpDetails } from 'store/testResults/utils';

import { MhpDetails, Nullable } from 'modules/types/common';
import {
  TestResult,
  TestResultCategory,
  TestResultDetails,
  TestResultFocused,
  MicrobiologyDetailsFull,
  LabDetails,
  LabResultFull,
  PathologyDetailsFull
} from 'store/testResults/types';

interface UseTestResultProps {
  eventId: string;
  mhpDetails?: MhpDetails;
  testResultForceFocus?: TestResult;
  mhpDetailsEncoded?: string;
}

export const useTestResultFocused = (props: UseTestResultProps) => {
  const { eventId, mhpDetails, mhpDetailsEncoded, testResultForceFocus } = props;
  const [testResultFocused, setTestResultFocused] = useState<TestResultFocused | null>(null);

  const dispatch = useDispatch();

  const testResultDetails = useSelector(testResultDetailsDataSelector);
  const testResultDetailsLoading = useSelector(testResultDetailsLoadingSelector);
  const testResultDetailsError = useSelector(testResultDetailsErrorSelector);

  const isListDataInDetailsData = !isEmpty(mhpDetails);
  const doesNeedList =
    isEmpty(testResultForceFocus) && isEmpty(testResultFocused) && !isListDataInDetailsData;

  // TODO: The start date should be stored in the state & updated when the user clicks 'Show More'
  // on the Test Results screen. Once the start date value is being stored, it should be used to
  // replace the 'tempFixStartDate' variable.
  // See line 32 of web/src/screens/TestResults/TestResultsList/TestResultsHealthRecordsList.tsx
  // Mick Piereder 2020.06.02
  const tempFixStartDate = dayjs().subtract(MAX_YEARS_TO_QUERY, 'year'); // <- Delete me when above TODO is complete.
  const listHookParams = {
    startDate: tempFixStartDate,
    endDate: dayjs(),
    skipAll: !doesNeedList
  };

  const {
    data: medicalTestResultsListData,
    loading: medicalTestResultsListLoading,
    error: medicalTestResultsListError
  } = useGetMedicalTestResultList(listHookParams);

  const getFocusInfo = (
    toFocus: TestResult | MicrobiologyDetailsFull | LabResultFull | PathologyDetailsFull
  ): TestResultFocused => {
    return pick(toFocus, [
      'adapterSource',
      'category',
      'components',
      'componentEventIds',
      'encounterId',
      'eventId',
      'isRead',
      'mhpDetails',
      'name',
      'nameNcid',
      'presentedForm',
      'providerNames',
      'source',
      'testDate'
    ]) as TestResultFocused;
  };

  const setFocus = useCallback(
    (toFocus: TestResult | MicrobiologyDetailsFull | LabResultFull | PathologyDetailsFull) => {
      setTestResultFocused({
        ...getFocusInfo(toFocus)
      });
    },
    [testResultFocused]
  );

  const sendGetDetailsAction = useCallback(
    (
      requestDetails: GetTestResultDetails,
      testResult: TestResult | TestResultFocused | undefined = undefined
    ) => {
      if (!testResultDetailsLoading) {
        dispatch(getTestResultDetails({ ...requestDetails }, testResult));
      }
    },
    [testResultDetails?.contentType, testResultDetailsLoading]
  );

  const getFocusObjFromDetails = useCallback(
    (
      details: TestResultDetails | null
    ): MicrobiologyDetailsFull | LabResultFull | PathologyDetailsFull | undefined => {
      if (
        !isEmpty(testResultFocused) ||
        !isListDataInDetailsData ||
        !details?.content ||
        isEmpty(details?.content)
      ) {
        return;
      }

      let toFocus: MicrobiologyDetailsFull | LabResultFull | PathologyDetailsFull;
      const category: TestResultCategory =
        (details.content as MicrobiologyDetailsFull | PathologyDetailsFull)?.category ||
        TestResultCategory.LAB;
      switch (category) {
        case TestResultCategory.LAB:
          toFocus = {
            ...((details.content as LabDetails)?.results || [])[0]
          } as LabResultFull;
          break;
        default:
          toFocus = {
            ...details.content
          } as MicrobiologyDetailsFull | PathologyDetailsFull;
      }

      // eslint-disable-next-line consistent-return
      return toFocus;
    },
    [isListDataInDetailsData, testResultFocused?.eventId]
  );

  useEffect(() => {
    dispatch(resetTestResultDetails());
    return () => {
      dispatch(cancelActionRequest(GET_TEST_RESULT_DETAILS));
      dispatch(resetTestResultDetails());
    };
  }, []);

  useEffect(() => {
    if (!isEmpty(testResultForceFocus)) {
      setFocus(testResultForceFocus as TestResult);
    }
  }, [testResultForceFocus?.eventId]);

  useEffect(() => {
    if (!!medicalTestResultsListData.length) {
      const match: TestResult = medicalTestResultsListData.find(
        (item: TestResult) => item.eventId === eventId
      );
      if (!isEmpty(match)) {
        setFocus(match);
      }
    }
  }, [medicalTestResultsListData]);

  useEffect(() => {
    if (mhpDetails) {
      sendGetDetailsAction({
        ...formatMhpDetails(mhpDetails)
      });
    }
  }, [mhpDetailsEncoded]);

  useEffect(() => {
    if (testResultFocused?.presentedForm && testResultFocused.presentedForm.length) {
      sendGetDetailsAction({ ...testResultFocused.presentedForm[0] }, testResultFocused);
    }
  }, [testResultFocused?.presentedForm]);

  useEffect(() => {
    if (testResultDetails?.contentType) {
      const focusObj = getFocusObjFromDetails(testResultDetails);
      if (focusObj && !isEmpty(focusObj)) {
        setFocus(focusObj);
      }
    } else if (!testResultDetailsLoading) {
      // handles hot reloads in dev
      if (testResultFocused?.mhpDetails && !isEmpty(testResultFocused.mhpDetails)) {
        sendGetDetailsAction({
          ...formatMhpDetails(testResultFocused.mhpDetails)
        });
      } else if (testResultFocused?.presentedForm && testResultFocused.presentedForm.length) {
        sendGetDetailsAction({ ...testResultFocused.presentedForm[0] }, testResultFocused);
      }
    }
  }, [testResultDetails?.contentType]);

  let isLoading = true;
  let error: Nullable<Error> | undefined;
  if (isListDataInDetailsData) {
    isLoading = isEmpty(testResultFocused) && testResultDetailsLoading;
    error = testResultDetailsError;
  } else {
    isLoading = isEmpty(testResultFocused) && medicalTestResultsListLoading;
    error = medicalTestResultsListError;
  }

  return {
    data: testResultFocused,
    loading: isLoading,
    error
  };
};
