import React from 'react';
import { Spacing } from 'modules/styles/variables';
import { Color } from 'modules/styles/colors';
import { FlexBoxRow } from 'components/UI/Layout/FlexBox';
import { SubText, StyledIcon } from 'screens/HealthRecords/styled';
import { MuiBox } from 'theme/material-ui';
import { TouchableOpacityWrapper } from './styled';

interface CollapsibleListHeaderProps {
  children?: JSX.Element[] | JSX.Element | React.ReactNode;
  headerColor?: string;
  headerWeight?: string;
  onOpened?: Function;
  subtitle: string;
  title: string;
  testID?: string;
}

const CollapsibleListHeader = ({
  children,
  headerColor,
  headerWeight,
  onOpened,
  subtitle,
  title,
  testID
}: CollapsibleListHeaderProps) => {
  const [itemExpanded, setItemExpanded] = React.useState(false);
  const toggleItem = () => setItemExpanded(!itemExpanded);
  if (itemExpanded && onOpened) {
    onOpened();
  }
  return (
    <>
      <TouchableOpacityWrapper accessibilityLabel={testID} testID={testID} onPress={toggleItem}>
        <FlexBoxRow
          justifyContent="space-between"
          vSpacing={Spacing.large}
          hSpacing={Spacing.large}
          backgroundColor={itemExpanded ? Color.grayHue4 : Color.foreColor}
        >
          <MuiBox flex="1">
            <SubText color={headerColor} fontWeight={headerWeight}>
              {title}
            </SubText>
          </MuiBox>

          {subtitle ? (
            <MuiBox flex="2">
              <SubText textAlign="right" paddingRight={`${Spacing.large}px`}>
                {subtitle}
              </SubText>
            </MuiBox>
          ) : null}
          <StyledIcon name={itemExpanded ? 'remove' : 'add'} size={24} />
        </FlexBoxRow>
      </TouchableOpacityWrapper>
      {itemExpanded && children}
    </>
  );
};

CollapsibleListHeader.defaultProps = {
  title: '',
  subtitle: '',
  children: null,
  isImpersonating: false,
  onOpened: () => {}
};

export default CollapsibleListHeader;
