import { Base64Attachment } from 'store/messaging/types';
import Logger from 'services/Logger';

/**
 * Method: getFileSizeInBytes
 * Description: Gets the file size from a base 64 encoded string. The final file size of a base64 encoded string is about 1.37 times its original string length.
 * Referances:
 *  https://softwareengineering.stackexchange.com/questions/288670/know-file-size-with-a-base64-string/368406
 *  https://en.wikipedia.org/wiki/Base64
 *  const fileSizeInBytes = (lengthOfBase64String * (3/4)) - (base64Ending === '==' ? 2 : 1)
 */
export function getFileSizeInBytes(str: string): number {
  const strLen = str?.length;
  return strLen * (3 / 4) - (String(str).substring(strLen - 2) === '==' ? 2 : 1);
}

export function getFileSizeInMegaBytes(str: string): number {
  return getFileSizeInBytes(str) / 1000000;
}

/**
 * Method: dataURLtoFile
 * Description: Converts base64 string to a file object
 */
export function dataURLtoFile(attachment: Base64Attachment): File {
  const { base64, filename } = attachment;
  const arr = base64.split(',');
  const prefixArr = arr[0].match(/:(.*?);/);
  const mime = prefixArr ? prefixArr[1] : '';
  const bstr = window.atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  /* eslint-disable no-plusplus */
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new window.File([u8arr], filename, { type: mime });
}

export const isBase64 = (content: any) => {
  if (content === '' || content.trim() === '') {
    return false;
  }
  try {
    return window.btoa(window.atob(content)) === content;
  } catch (err) {
    return false;
  }
};

export const b64toBlob = (b64Data = '', contentType = '', sliceSize = 512) => {
  try {
    const byteCharacters = window.atob(isBase64(b64Data) ? b64Data : '');
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    // eslint-disable-next-line no-undef
    return new Blob(byteArrays, { type: contentType });
  } catch (e) {
    Logger.error(e);
    // eslint-disable-next-line no-undef
    return new Blob([], { type: contentType });
  }
};

/**
 * the letter 'e' is allowed in number fields due to it
 * being a float value (exponent). This prevents the letter 'e'
 * from being accepted. Pass this to the onKeyDown
 * key in the InputProps object of a TextField element
 */
export const onEKeyPress = (event: React.KeyboardEvent<any>) => {
  if (event.key === 'e' || event.key === 'E') {
    event.preventDefault();
  }
};
