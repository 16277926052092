import { makeStyles } from '@material-ui/core';
import { Color } from 'modules/styles/colors';
import React from 'react';
import { MuiBox, MuiModal } from 'theme/material-ui';
import { rgba } from 'polished';

const positioning = {
  middle: {
    top: '10%',
    width: '100%',
    overflow: 'auto',
    margin: 0
  },
  bottom: {
    top: 'auto',
    marginTop: 0,
    marginBottom: 0,
    position: 'absolute',
    bottom: 0,
    width: '100%',
    minWidth: 'fit-content',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0
  }
};
type PositioningType = keyof typeof positioning;

interface Props {
  open: boolean;
  handleClose?: () => void | Function;
  ariaLabelledBy?: string;
  ariaDescribedBy?: string;
  disableEscapeKeyDown?: boolean;
  disableBackdropClick?: boolean;
  position?: PositioningType;
  maxWidth?: string;
  children: React.ReactNode;
  isSmallDevice?: boolean;
}

interface UseStylesProps {
  maxWidth?: string;
  position: string;
}
const useStyles = makeStyles(theme => ({
  container: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  },

  overflow: {
    width: '100%',
    height: '100%',
    overflow: 'auto',
    pointerEvents: 'none'
  },

  box: (props: UseStylesProps) => ({
    top: '25%',
    height: 'fit-content',
    maxWidth: props.maxWidth || 'unset',
    pointerEvents: 'auto',
    borderRadius: 20,
    overflow: 'auto',
    maxHeight: '75%',
    [theme.breakpoints.down('xs')]: positioning[props.position]
  })
}));

export const WebMuiModal = (props: Props) => {
  const {
    open,
    handleClose,
    ariaLabelledBy,
    ariaDescribedBy,
    position = 'middle',
    maxWidth,
    isSmallDevice,
    ...rest
  } = props;
  const classes = useStyles({ position, maxWidth });

  return (
    <MuiModal
      {...rest}
      aria-labelledby={ariaLabelledBy}
      aria-describedby={ariaDescribedBy}
      open={open}
      onClose={handleClose}
      disableEnforceFocus
      disableAutoFocus
      BackdropProps={{ style: { backgroundColor: rgba(Color.primary, 0.5) } }}
    >
      <MuiBox className={classes.overflow}>
        <MuiBox className={classes.container}>
          <MuiBox bgcolor={Color.white} p={isSmallDevice ? 1.5 : 3} className={classes.box}>
            {props.children}
          </MuiBox>
        </MuiBox>
      </MuiBox>
    </MuiModal>
  );
};
WebMuiModal.defaultProps = {
  position: 'middle'
};
export default WebMuiModal;
