import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import ConfirmModal from 'components/UI/Modals/ConfirmModal';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { handleSubmit, handleUrlOnExisting } from 'store/pageActions/RegisterAccount';
import { TermsAndConditions } from 'store/global/reducers';
import { termsAndConditionsSelector } from 'store/global/selectors';
import {
  registerAccountSelector,
  registerErrorSelector,
  registerSubmittingSelector
} from 'store/register/selectors';
import { RootState } from 'store/types';
import { MuiBox, MuiButton, MuiDialog } from 'theme/material-ui';
import * as Yup from 'yup';
import { CancelButton } from '../FormElements/CancelButton';
import CustomButton from '../FormElements/CustomButton';
import CustomCheckbox from '../FormElements/CustomCheckbox';
import CustomTextField from '../FormElements/CustomTextField';
import { CloseMuiBox, StyledFieldTip, StyledPwdGrid, StyledUserGrid, StyleTerms } from '../styled';
import useStyles from '../useStyles';
import { getRegisterBrand } from 'store/register';
import { getBrandColor } from 'store/register/utils';
import { AppName, getAppName } from 'modules/utils/ConfigUtils';

export interface RegisterAccountProps {
  initialValues: {
    username: string;
    password: string;
  };
  termsAndConditions: TermsAndConditions;
  isSubmitting: boolean;
  registerError: any;
}

const PasswordSchema = Yup.string()
  .required('Required')
  .min(8, '8 or more characters')
  .matches(/[0-9]/, 'At least 1 number')
  .matches(/[a-z]/, 'At least 1 lowercase letter')
  .matches(/[A-Z]/, 'At least 1 uppercase letter')
  .test(
    'special-chars',
    'At least 1 special character (!@#$%^*)',
    pw => pw && pw.match(/(?=.*[!@#$%^*])/)
  )
  .test('no-spaces', 'No spaces', pw => pw && !pw.match(/\s/g));

const UsernameSchema = Yup.string()
  .required('Required')
  .matches(/^.{8,20}$/, '8 to 20 characters')
  .matches(/^[A-Za-z0-9]+$/, 'Only letters and numbers')
  .test('starts-with', 'Start with a letter', u => u && u.charAt(0).match(/^[A-Za-z]+$/));

const AccountSchema = Yup.object().shape({
  username: UsernameSchema,
  password: PasswordSchema,
  passwordConfirm: Yup.string()
    .required('Passwords must match')
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
  agreeToTerms: Yup.boolean().oneOf([true], 'The terms and conditions must be accepted')
});

export const RegisterAccount = ({
  initialValues,
  isSubmitting,
  termsAndConditions,
  registerError
}: RegisterAccountProps) => {
  const dispatch = useDispatch();
  const [passwordRequirements, setPasswordRequirements] = useState([]);
  const [usernameRequirements, setUsernameRequirements] = useState([]);
  const [passwordIsFocused, setPasswordIsFocused] = useState(false);
  const [usernameIsFocused, setUsernameIsFocused] = useState(false);
  const [termsHtml, setTermsHtml] = useState('');

  const [showDialog, setShowDialog] = useState(false);
  const [userEmail, setUserEmail] = useState();
  const [showTerms, setShowTerms] = useState(false);

  const redirectBrand = getRegisterBrand();
  const classes = useStyles({ redirectBrand });
  const brandColor = getBrandColor({ redirectBrand });
  const history = useHistory();

  useEffect(() => {
    validate(initialValues);
  }, []);

  useEffect(() => {
    const { general } = termsAndConditions;
    setTermsHtml(general.content);
  }, [termsAndConditions]);

  useEffect(() => {
    if (!showDialog && registerError && registerError?.response?.data?.statusCode === 'MYH006') {
      setUserEmail(registerError?.response?.data?.consumer?.emails?.[0]?.value);
      setShowDialog(true);
    }
  }, [registerError]);

  const validate = async values => {
    const passwordRequirements = [];
    const usernameRequirements = [];

    const gatherPasswordRequirements = (errors: string[]) =>
      PasswordSchema.tests.forEach(t => {
        const { message } = t.OPTIONS;
        const isValid = !errors.includes(message);
        if (message !== 'Required') {
          passwordRequirements.push({
            message,
            isValid
          });
        }
      });

    const gatherUsernameRequirements = (errors: string[]) =>
      UsernameSchema.tests.forEach(t => {
        const { message } = t.OPTIONS;
        const isValid = !errors.includes(message);
        if (message !== 'Required') {
          usernameRequirements.push({
            message,
            isValid
          });
        }
      });

    await PasswordSchema.validate(values.password, { abortEarly: false })
      .then(() => gatherPasswordRequirements([]))
      .catch(err => gatherPasswordRequirements(err.errors));

    await UsernameSchema.validate(values.username, { abortEarly: false })
      .then(() => gatherUsernameRequirements([]))
      .catch(err => gatherUsernameRequirements(err.errors));

    setPasswordRequirements(passwordRequirements);
    setUsernameRequirements(usernameRequirements);
  };

  return (
    <Formik
      initialValues={{
        ...initialValues,
        passwordConfirm: '',
        agreeToTerms: false
      }}
      validationSchema={AccountSchema}
      validate={validate}
      onSubmit={values => dispatch(handleSubmit(values))}
    >
      {({ isValid, dirty }) => {
        return (
          <Form>
            <Grid container spacing={4}>
              <StyledUserGrid xs={12} isFocused={usernameIsFocused}>
                <CustomTextField
                  name="username"
                  label="Create Username"
                  placeholder="Enter Username"
                  showError={false}
                  onFocus={() => setUsernameIsFocused(true)}
                  onBlur={() => setUsernameIsFocused(false)}
                />
                {usernameIsFocused && (
                  <StyledFieldTip>
                    <List>
                      {usernameRequirements.map(r => (
                        <ListItem key={r.message} className={classes.fieldTipItem}>
                          <ListItemIcon className={classes.fieldTipIcon}>
                            {r.isValid ? (
                              <CheckIcon className={classes.fieldTipCheck} fontSize="small" />
                            ) : (
                              <CloseIcon color="error" fontSize="small" />
                            )}
                          </ListItemIcon>
                          <ListItemText
                            primaryTypographyProps={{ className: classes.fieldTipText }}
                            primary={r.message}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </StyledFieldTip>
                )}
              </StyledUserGrid>
              <StyledPwdGrid xs={12} isFocused={passwordIsFocused}>
                <CustomTextField
                  type="password"
                  name="password"
                  label="Create Password"
                  placeholder="Enter Password"
                  pwdAdornment
                  unMaskType="text"
                  showError={false}
                  onFocus={() => setPasswordIsFocused(true)}
                  onBlur={() => setPasswordIsFocused(false)}
                />
                {passwordIsFocused && (
                  <StyledFieldTip>
                    <List>
                      {passwordRequirements.map(r => (
                        <ListItem key={r.message} className={classes.fieldTipItem}>
                          <ListItemIcon className={classes.fieldTipIcon}>
                            {r.isValid ? (
                              <CheckIcon className={classes.fieldTipCheck} fontSize="small" />
                            ) : (
                              <CloseIcon color="error" fontSize="small" />
                            )}
                          </ListItemIcon>
                          <ListItemText
                            primaryTypographyProps={{ className: classes.fieldTipText }}
                            primary={r.message}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </StyledFieldTip>
                )}
              </StyledPwdGrid>
              <Grid item xs={12}>
                <CustomTextField
                  type="password"
                  name="passwordConfirm"
                  label="Confirm Password"
                  placeholder="Re-enter Password"
                  pwdAdornment
                  unMaskType="text"
                />
              </Grid>

              <MuiDialog open={showTerms} maxWidth="lg" onClose={() => setShowTerms(false)}>
                <CloseMuiBox>
                  <CloseIcon fontSize="large" onClick={() => setShowTerms(false)} />
                </CloseMuiBox>
                <StyleTerms variant="outlined">
                  {termsHtml ? <div dangerouslySetInnerHTML={{ __html: termsHtml }} /> : null}
                </StyleTerms>
              </MuiDialog>
              <Box mb={2} />
              <MuiBox display="flex" alignItems="center" flexWrap="wrap" pl={2}>
                <CustomCheckbox
                  label={`I have read and agree to ${getAppName(AppName.secondary, 'the')}.`}
                  name="agreeToTerms"
                  disabled={!termsHtml}
                />
                <MuiButton
                  variant="text"
                  className={classes.customTextButton}
                  onClick={() => setShowTerms(true)}
                >
                  Terms of use
                </MuiButton>
              </MuiBox>
            </Grid>
            <Box my={3} maxWidth="xs" textAlign="center">
              <CustomButton
                type="submit"
                label="Continue"
                disabled={!(isValid && dirty)}
                isSubmitting={isSubmitting}
                aria-label="submit-button"
              />
              <CancelButton />
            </Box>

            <ConfirmModal
              isOpen={showDialog}
              icon="ExclamationCircle"
              iconColor={brandColor}
              description={`It appears you might already have an account associated to ${userEmail}`}
              confirmLabel="Sign in"
              onConfirm={() => {
                dispatch(handleUrlOnExisting(history));
              }}
              showCloseIcon
              cancelLabel="Cancel"
              onCancel={() => setShowDialog(false)}
              fullWidth
              maxWidth="400px"
              position="bottom"
              buttonConfirmClassName={classes.customContainedButton}
              buttonOutlinedClassName={classes.customOutlinedButton}
              buttonTextClassName={classes.customTextButton}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = (state: RootState) => ({
  initialValues: registerAccountSelector(state),
  termsAndConditions: termsAndConditionsSelector(state),
  isSubmitting: registerSubmittingSelector(state),
  registerError: registerErrorSelector(state)
});

export default connect(mapStateToProps)(RegisterAccount);
