import styled, { css } from 'styled-components';
import { Paper } from '@material-ui/core';
import { ZIndex, BorderRadius } from 'modules/styles/variables';
import { Color } from 'modules/styles/colors';
import { resolveCSSUnit } from 'modules/utils/StylesUtils';

interface SidePanelProps {
  open: boolean;
  variant: 'permanent' | 'temporary';
  anchor: 'left' | 'right' | 'top' | 'bottom';
  width?: number | string;
  height?: number | string;
}

export const PanelTab = styled(Paper)`
  position: absolute;
`;

const anchor = (props: SidePanelProps) => {
  switch (props.anchor) {
    case 'left':
      return css`
        left: 0;
        transform: translateX(${props.open ? '0%' : '-100%'});

        & ${PanelTab} {
          top: 8px;
          left: 100%;
          border-radius: 0 ${BorderRadius.base}px ${BorderRadius.base}px 0;

          & > button {
            min-width: 24px;
            padding: 12px 0;
          }
        }
      `;
    case 'right':
      return css`
        right: 0;
        transform: translateX(${props.open ? '0%' : '100%'});

        & ${PanelTab} {
          top: 8px;
          right: 100%;
          border-radius: ${BorderRadius.base}px 0 0 ${BorderRadius.base}px;

          & > button {
            min-width: 24px;
            padding: 12px 0;
          }
        }
      `;
    case 'top':
      return css`
        top: 0;
        transform: translateY(${props.open ? '0%' : '-100%'});

        & ${PanelTab} {
          right: 8px;
          top: 100%;
          border-radius: 0 0 ${BorderRadius.base}px ${BorderRadius.base}px;

          & > button {
            min-height: 24px;
            padding: 0 12px;
          }
        }
      `;
    case 'bottom':
      return css`
        bottom: 0;
        transform: translateY(${props.open ? '0%' : '100%'});

        & ${PanelTab} {
          right: 8px;
          bottom: 100%;
          border-radius: ${BorderRadius.base}px ${BorderRadius.base}px 0 0;

          & > button {
            min-height: 24px;
            padding: 0 12px;
          }
        }
      `;
    default:
      return css``;
  }
};

const height = (props: SidePanelProps) =>
  props.height &&
  css`
    height: ${resolveCSSUnit(props.height)};
  `;

const width = (props: SidePanelProps) =>
  props.width &&
  css`
    width: ${resolveCSSUnit(props.width)};
  `;

const variant = (props: SidePanelProps) =>
  props.variant === 'permanent' &&
  css`
    position: relative;
  `;

export const SidePanel = styled.div<SidePanelProps>`
  height: auto;
  width: auto;
  background-color: ${Color.white};
  z-index: ${ZIndex.base + 1};
  transition: transform 200ms cubic-bezier(0.4, 0, 0.2, 1);
  position: absolute;
  ${height};
  ${width};
  ${variant};
  ${anchor};
`;
