import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';
import { useTheme } from '@material-ui/core/styles';
import { consumerIdSelector } from 'store/profile/selectors';
import { RootState } from 'store/types';
import { history } from 'lib/history';
import Svg from 'components/UI/Svg/Svg';
import Spinner from 'components/UI/Spinner/Spinner';

import {
  getFinancialAssistanceSummary,
  getFinancialAssistanceApplications
} from 'store/billing/actions';
import { faSubmitSendingSelector } from 'store/billing/financialAssistance/createApplication/selectors';
import { getFinancialAssistanceSummarySelector } from 'store/billing/selectors';
import analyticsService, { AmplitudeEventData, AnalyticsEvent } from 'services/AnalyticsService';
import {
  currentLocationPathNameSelector,
  previousLocationPathNameSelector
} from 'store/router/selectors';
import { FAInitiationProps } from './common';
import {
  FA_NEW_APP_BODY,
  FA_REAPPLY_APP_BODY,
  PATIENT_FINANCIAL_SERVICE_NUMBER
} from '../constants';
import ConfirmDialog from '../../components/ConfirmDialog';
import FADisclaimer from '../FADisclaimer';
import { isImpersonatingSelector } from 'store/account/selectors';
import { MuiButton, MuiLink } from 'theme/material-ui';

export const FAInitiation = (props: FAInitiationProps) => {
  const {
    currentUrl,
    referringUrl,
    isImpersonating,
    financialAssistanceSummary,
    getFinancialAssistanceApplications,
    getFinancialAssistanceSummary
  } = props;
  const { palette } = useTheme();

  const eventData: AmplitudeEventData = {
    currentUrl,
    referringUrl
  };
  const { data, error, isFetching: loading } = financialAssistanceSummary;

  useEffect(() => {
    getFinancialAssistanceApplications(false);
    getFinancialAssistanceSummary(false);
  }, [isImpersonating, currentUrl]);

  const hasPendingApplication = !!data?.hasPendingApplications;

  const hasValidApplicationForms = !!data?.hasValidApplicationForms;

  const [showModal, setShowHideModal] = useState(false);
  useEffect(() => {
    setShowHideModal(!showModal);
  }, []);

  const navigateToApplication = () => {
    analyticsService.logEvent(AnalyticsEvent.StartApplicationClicked, eventData);
    if (hasPendingApplication) {
      setShowHideModal(true);
    } else {
      history.push('/u/fa-app/basic-info');
    }
  };

  return (
    <Container>
      <Paper style={{ marginTop: '-20px' }}>
        <Box p={6}>
          <Grid container spacing={6}>
            <Grid item md={7} sm={12}>
              {error && (
                <Box mb={3}>
                  <Alert severity="error">There was an issue fetching application details.</Alert>
                </Box>
              )}
              <Box mb={4}>
                <Typography>
                  {hasValidApplicationForms && !hasPendingApplication
                    ? FA_REAPPLY_APP_BODY
                    : FA_NEW_APP_BODY}
                </Typography>
              </Box>
              {loading ? (
                <Spinner data-testid="fa-initiation-loading-spinner" />
              ) : (
                <MuiButton
                  data-testid="start-button"
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={navigateToApplication}
                >
                  {hasValidApplicationForms && !hasPendingApplication
                    ? 'Re-apply for assistance'
                    : 'Start application'}
                </MuiButton>
              )}
            </Grid>
            <Grid item md={5} sm={12}>
              <Box
                display="flex"
                alignItems="center"
                bgcolor="grey.100"
                p={2}
                borderRadius={2}
                mb={2}
              >
                <Svg
                  name="Timer"
                  strokeWidth="0.25px"
                  stroke={palette.primary.main}
                  height="40"
                  width="40"
                />
                <Box pl={1}>
                  <Typography>
                    {`Time Estimate to complete this form is `}
                    <b>
                      {hasValidApplicationForms && !hasPendingApplication
                        ? 'less than 5 minutes'
                        : '20 minutes'}
                    </b>
                  </Typography>
                </Box>
              </Box>
              <Box bgcolor="grey.100" p={2} borderRadius={2} mb={2}>
                <Typography>If you need help please call Financial Assistance Dept</Typography>
                <Typography variant="h6">
                  <MuiLink
                    data-testid="service-number"
                    href={`tel: ${PATIENT_FINANCIAL_SERVICE_NUMBER}`}
                  >
                    {PATIENT_FINANCIAL_SERVICE_NUMBER}
                  </MuiLink>
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Paper>

      <Box mt={4}>
        <FADisclaimer />
      </Box>

      <ConfirmDialog
        isOpen={hasPendingApplication && showModal}
        title="Application Pending"
        description="You currently have an application pending"
        onCancel={() => setShowHideModal(false)}
        onConfirm={() => {
          setShowHideModal(false);
          analyticsService.logEvent(AnalyticsEvent.ViewAppStatusClicked, eventData);
          history.push('/u/fa-app/status');
        }}
        confirmLabel="View application status"
        cancelLabel="Close"
      />
    </Container>
  );
};

const mapStateToProps = (state: RootState) => ({
  guarantorMmi: consumerIdSelector(state), // consumerId is guarantor mmi
  currentUrl: currentLocationPathNameSelector(state),
  referringUrl: previousLocationPathNameSelector(state),
  isSending: faSubmitSendingSelector(state),
  isImpersonating: isImpersonatingSelector(state),
  financialAssistanceSummary: getFinancialAssistanceSummarySelector(state)
});

const mapDispatchToProps = {
  getFinancialAssistanceSummary,
  getFinancialAssistanceApplications
};

export { FAInitiation as FAInitiationComponent };
export default connect(mapStateToProps, mapDispatchToProps)(FAInitiation);
