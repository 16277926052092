import React from 'react';

import FlexBox from 'components/UI/Layout/FlexBox';
import UserAvatar from 'components/UI/Avatar';
import Spacer from 'components/UI/Layout/Spacer';

import { MuiTypography, MuiButton } from 'theme/material-ui';
import { Color } from 'modules/styles/colors';
import { FontSize, Spacing } from 'modules/styles/variables';
import { getUserName } from 'lib/user';
import { Consumer, Grantor } from 'store/profile/types';

import AvatarScreenTitle from './components/AvatarScreenTitle';
import { ScrollFlexbox, ArrowNew } from './styled';

interface ChangeAvatarProps {
  currentAvatar: string;
  avatarSelected: string;
  userSelected: Consumer | Grantor;
  onAvatarChange: () => void;
  cancelChange: () => void;
}

const ChangeAvatar = ({
  currentAvatar,
  avatarSelected,
  onAvatarChange,
  userSelected,
  cancelChange
}: ChangeAvatarProps) => {
  return (
    <ScrollFlexbox>
      <AvatarScreenTitle title="Change Avatar?" />
      <FlexBox alignItems="center">
        <FlexBox
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          vSpacing={Spacing.xxLarge}
        >
          <FlexBox alignItems="center" hSpacing={Spacing.xLarge}>
            <UserAvatar
              avatarUrl={currentAvatar === 'Initials' ? '' : currentAvatar}
              fallback={getUserName(userSelected)}
              initialsFontSize={FontSize.xlarge}
              size={120}
            />
            <MuiTypography fontSize={FontSize.heading} color={Color.white}>
              Current
            </MuiTypography>
          </FlexBox>
          <ArrowNew fontSize="large" />
          <FlexBox alignItems="center" hSpacing={Spacing.xLarge}>
            <UserAvatar
              avatarUrl={avatarSelected === 'Initials' ? '' : avatarSelected}
              fallback={getUserName(userSelected)}
              initialsFontSize={FontSize.xlarge}
              size={120}
            />
            <MuiTypography fontSize={FontSize.heading} color={Color.white}>
              New
            </MuiTypography>
          </FlexBox>
        </FlexBox>
        <FlexBox alignItems="center" hSpacing={Spacing.xLarge} width="100%">
          <MuiButton onClick={onAvatarChange} color="primary" variant="outlined" fullWidth>
            Use avatar
          </MuiButton>
          <Spacer spacing="large" />
          <MuiButton onClick={cancelChange} variant="text" color="primary" fullWidth>
            Cancel
          </MuiButton>
        </FlexBox>
      </FlexBox>
    </ScrollFlexbox>
  );
};

export default ChangeAvatar;
