import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import { RootState } from 'store/types';
import * as covidTestTypes from 'store/CovidScreening/types';
import * as covidTestSelectors from 'store/CovidScreening/patients/selectors';
import { triageOriginSelector } from 'store/triageGyant/selectors';
import { setTriageOrigin } from 'store/triageGyant/actions';
import { CovidTitles } from 'lib/constants/covidScreening';
import { AnalyticsEvent } from 'services/AnalyticsService';
import useNavigationAnalytics from 'hooks/useNavigationAnalytics';
import { avatarDataMapSelector } from 'store/consumerPreferences/selectors';
import Footer from 'components/common/Footer';
import PatientLoader from 'components/common/PatientLoader';
import { patientSelect } from 'modules/constants/covidScreening';
import { CovidTestButton } from '../Components/CovidTestButton';
import * as profileSelectors from 'store/profile/selectors';
import * as covidScreeningSelectors from 'store/CovidScreening/selectors';
import { MuiBox, MuiContainer } from 'theme/material-ui';
import { Email, Address, Phone, Grantor } from 'store/profile/types';
import { Patient } from 'store/CovidScreening/types';
import useStyles from '../Components/CovidTestButton/useStyles';
import { MapAvatar } from 'store/consumerPreferences/types';
import * as covidActions from 'store/CovidScreening/actions';

export interface Props extends RouteComponentProps {
  patients: covidTestTypes.Patient[];
  patientsLoading: boolean;
  patientsData: Patient;
  grantors: Grantor[];
  guarantorAddress: Address[];
  guarantorEmail: Email[];
  guarantorPhones: Phone[];
  avatarsMap: MapAvatar;
  setPatient: typeof covidActions.setPatient;
  setTriageOrigin: typeof setTriageOrigin;
}

export function BookCovidTest({
  avatarsMap,
  patients,
  patientsLoading,
  patientsData,
  guarantorAddress,
  guarantorEmail,
  guarantorPhones,
  grantors,
  history,
  setPatient,
  setTriageOrigin
}: Props) {
  const classes = useStyles();
  const selectedPatientAnalytics = useNavigationAnalytics(AnalyticsEvent.CovidTestPatientSelected);
  const prevBtnClickedAnalytics = useNavigationAnalytics(AnalyticsEvent.CovidPrev);
  const nextBtnClickedAnalytics = useNavigationAnalytics(AnalyticsEvent.CovidNext);
  const triageOrigin = useSelector(triageOriginSelector);

  useEffect(() => {
    // handle go_back browser button
    const handleGoBackBrowserBtn = () => {
      if (triageOrigin === 'TriageGyant') {
        setTriageOrigin(null);
        history.push('/u/assess-my-symptoms/scout-symptom-checker');
      } else {
        history.push('/u/get-care-now');
      }
    };

    window.addEventListener('popstate', handleGoBackBrowserBtn);

    return () => {
      window.removeEventListener('popstate', handleGoBackBrowserBtn);
    };
  }, []);

  const getRelationForAnalytics = data => {
    const relation = grantors.find(element => element.consumerId === data.consumerId);
    if (relation) return 'Proxy';
    return 'Self';
  };

  const getPatientObject = data => {
    const patientInfo = {
      dateOfBirth: data?.dateOfBirth,
      genderType: generateGenderType(data?.gender),
      cmrn: data?.consumerId,
      icentraPersonId: data?.cernerPersonId,
      names: [
        {
          givenName: data.firstName,
          familyName: data.lastName,
          middleName: data?.middleName ?? ''
        }
      ],
      addresses: [
        {
          line1: guarantorAddress[0]?.streetAddress,
          line2: guarantorAddress[0]?.streetAddress2,
          city: guarantorAddress[0]?.city,
          state: guarantorAddress[0]?.state,
          zip: guarantorAddress[0]?.postalCode,
          type: guarantorAddress[0]?.type ? 'HOMEADDR' : ''
        }
      ],
      phones: [
        {
          areaCode: guarantorPhones[0]?.value ? guarantorPhones[0]?.value.substring(0, 3) : '',
          phoneNumber: guarantorPhones[0]?.value.substring(3, 10),
          countryCode: null,
          phoneType: guarantorPhones[0]?.type ? 'CELLPHONE' : ''
        }
      ],
      emails: [
        {
          address: guarantorEmail[0]?.value,
          type: guarantorEmail[0]?.type ? 'HOMEEMAIL' : ''
        }
      ],
      ssn: data?.ssn
    };
    selectedPatientAnalytics({
      number_proxies: grantors.length,

      patient_selected: getRelationForAnalytics(data),
      title: CovidTitles.PATIENT_SELECT
    });
    setPatient(patientInfo);
  };
  useEffect(() => {
    if (patients.length === 1) {
      getPatientObject(patients[0]);
    }
  }, []);

  const generateGenderType = (
    gender?: 'Female' | 'Male' | 'Both' | 'Other' | 'Prefer not to respond'
  ) => {
    if (gender === 'Male') {
      return 'MALE';
    }
    if (gender === 'Female') {
      return 'FEMALE';
    }
    if (['Both', 'Other'].includes(gender)) {
      return 'BOTH';
    }
    return 'UNKNOWN';
  };

  const handlePatientSelect = (patient: covidTestTypes.Patient) => {
    getPatientObject(patient);
  };

  const getPatientIsSelected = (patient: covidTestTypes.Patient) => {
    if (patients.length === 1) {
      return true;
    }
    return patientsData?.cmrn === patient?.consumerId;
  };

  const onCancelClick = () => {
    prevBtnClickedAnalytics({
      title: CovidTitles.PATIENT_SELECT
    });
    if (triageOrigin === 'TriageGyant') {
      setTriageOrigin(null);
      history.push('/u/assess-my-symptoms/scout-symptom-checker');
    } else {
      history.push('/u/get-care-now');
    }
  };

  const handleNext = () => {
    if (patientsData?.cmrn?.length) {
      return false;
    }
    if (patients.length === 1) {
      handlePatientSelect(patients[0]);
      return false;
    }
    return true;
  };

  const onNextClick = () => {
    nextBtnClickedAnalytics({
      title: CovidTitles.PATIENT_SELECT
    });
    history.push('/u/get-care-now/covid-screen/consent-and-agreement');
  };

  return (
    <>
      <PatientLoader
        title="Who needs a Covid-19 test?"
        patients={patients}
        patientsLoading={patientsLoading}
        handlePatientSelect={handlePatientSelect}
        getPatientIsSelected={getPatientIsSelected}
        id="Covid"
        avatarsMap={avatarsMap}
        patientsData={patientsData}
        isCovidPatientSelect
      />
      <MuiContainer maxWidth="lg">
        <MuiBox className={classes.steppersFooter}>
          <CovidTestButton
            fullWidth
            variant="contained"
            data-testid="patient-select-next-button"
            disabled={handleNext()}
            onClick={onNextClick}
          >
            Next
          </CovidTestButton>
          <CovidTestButton
            fullWidth
            variant="text"
            data-testid="patient-select-cancel-button"
            onClick={onCancelClick}
          >
            Cancel
          </CovidTestButton>
        </MuiBox>
      </MuiContainer>
      <Footer
        headerText={patientSelect.GET_PATIENTS.HELPLINE.CovidInfo}
        link={patientSelect.GET_PATIENTS.HELPLINE.CovidPatientSelectHelpline}
        linkName={patientSelect.GET_PATIENTS.HELPLINE.CovidPatientSelectHelpline}
        helplineHours={patientSelect.GET_PATIENTS.HELPLINE.CovidHelplineHours}
        title={CovidTitles.PATIENT_SELECT}
      />
    </>
  );
}

const mapStateToProps = (state: RootState) => ({
  avatarsMap: avatarDataMapSelector(state),
  patients: covidTestSelectors.patientsDataSelector(state),
  patientsLoading: covidTestSelectors.patientsFetchingSelector(state),
  patientsData: covidScreeningSelectors.CovidScreeningPatientInfoSelector(state),
  guarantorEmail: profileSelectors.profileEmailsSelector(state),
  guarantorPhones: profileSelectors.profilePhonesSelector(state),
  guarantorAddress: profileSelectors.profileAddressesSelector(state),
  grantors: profileSelectors.profileGrantorsSelector(state)
});

const mapDispatchToProps = {
  setPatient: covidActions.setPatient,
  setTriageOrigin
};

export default connect(mapStateToProps, mapDispatchToProps)(BookCovidTest);
