import React, { ComponentType } from 'react';
import { useHistory } from 'react-router-dom';
import { TableData } from './types';
import { mapCollectionsToTableData } from './util';
import analyticsService, { AmplitudeEventData, AnalyticsEvent } from 'services/AnalyticsService';
import Divider from 'components/UI/Divider';
import CollapsibleListItem from 'components/common/CollapsibleList/CollapsibleListItem';
import AmountSummaryTable from 'components/billing/AmountSummaryTable';
import { TouchableOpacity } from 'react-native';
import { MuiBox } from 'theme/material-ui';
import Typography from 'components/UI/Typography';
import { KeyboardArrowRight } from '@material-ui/icons';
import { Color } from 'modules/styles/colors';
import useCoreEventData from 'hooks/useCoreEventData';
import { useBillsCollapsibleList } from 'lib/hooks/useBilling';

interface CollectionsComponentProps {
  largeView: boolean;
}

function CollectionsComponent(props: CollectionsComponentProps) {
  const history = useHistory();
  const { currentUrl, referringUrl } = useCoreEventData();
  const { isImpersonating, paymentsSummary } = useBillsCollapsibleList();
  const collections = paymentsSummary?.collectionEncounters || [];
  const { largeView } = props;
  const collectionsTableData = mapCollectionsToTableData(collections);
  const data: AmplitudeEventData = {
    currentUrl,
    referringUrl
  };

  const goToBillingDetail = (item: TableData) => {
    analyticsService.logEvent(AnalyticsEvent.IndividualBillingClicked, data);
    history.push(`/u/billing/bill-detail/${item.id}`, {
      eaid: item.id
    });
  };

  const goToCollectionsSummary = () => {
    analyticsService.logEvent(AnalyticsEvent.ViewAllCollectionsClicked, data);
    history.push('/u/billing/collections');
  };

  return (
    <>
      {collectionsTableData.length === 0 ? null : (
        <>
          <Divider />
          <CollapsibleListItem
            largeView={largeView}
            leftIcon="Collections"
            title="Collections"
            subtitle="Check your bills in collections"
            isExpandable={!isImpersonating}
            analyticsEvent={AnalyticsEvent.CollectionsClicked}
            currentUrlForAnalytics={currentUrl}
            referringUrlForAnalytics={referringUrl}
          >
            <AmountSummaryTable
              largeView={largeView}
              arrows
              emptyCaption=""
              data={collectionsTableData}
              onItemPressed={goToBillingDetail}
              headers={['Visit Date', 'Patient', 'Amount']}
            />
            {collectionsTableData.length < 3 ? null : (
              <TouchableOpacity
                onPress={goToCollectionsSummary}
                accessibilityLabel="Tap to view all collections"
              >
                <MuiBox justifyContent="space-between" display="flex" padding="20px 20px 0px 20px">
                  <MuiBox data-testid="view-all-unpaid-bills">
                    <Typography color={Color.secondary}>View All</Typography>
                  </MuiBox>
                  <MuiBox justifyContent="space-between" flexDirection="row">
                    <KeyboardArrowRight />
                  </MuiBox>
                </MuiBox>
              </TouchableOpacity>
            )}
          </CollapsibleListItem>
        </>
      )}
    </>
  );
}

export default CollectionsComponent as ComponentType;
