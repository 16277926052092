import React from 'react';
import {
  MuiBox,
  MuiContainer,
  MuiGrid,
  MuiDivider,
  MuiFormControl,
  MuiFormControlLabel,
  MuiRadio,
  MuiAlert,
  MuiRadioGroup,
  MuiTypography
} from 'theme/material-ui';
import { Color } from 'modules/styles/colors';
import { StyledScreen } from './styled';
import UserAvatar from 'components/UI/Avatar/UserAvatar';
import DataLoader from 'components/UI/DataLoader/DataLoader';
import Spacer from 'components/UI/Layout/Spacer';
import { getUserName } from 'lib/user';
import { fullName, getAge } from 'lib/booking/utils';
import { FontSize } from 'modules/styles/variables';
import { MapAvatar } from 'store/consumerPreferences/types';
import { patientSelect } from 'modules/constants/booking';
import { BookingLoading, BookingNoData } from 'screens/Booking/components/sharedComponents';
import * as bookingTypes from 'store/booking/types';
import { Typography } from 'components/UI/Typography/styled';

interface Props {
  title: string;
  patients: bookingTypes.Patient[];
  patientsLoading: boolean;
  handlePatientSelect: Function;
  getPatientIsSelected: Function;
  id: string;
  avatarsMap: MapAvatar;
  isCovidPatientSelect: boolean;
}
const PatientLoader = ({
  title,
  patients,
  patientsLoading,
  handlePatientSelect,
  getPatientIsSelected,
  id,
  avatarsMap,
  isCovidPatientSelect
}: Props) => {
  const child = patient => {
    if (getAge(patient.dateOfBirth, true) < 3) return true;
    return false;
  };
  const patientsList = patients?.filter(element => getAge(element.dateOfBirth) < 2);
  return (
    <StyledScreen>
      <MuiContainer maxWidth="lg">
        <MuiBox my={3}>
          <MuiBox mb={1}>
            <Typography tag="h2">{title}</Typography>
          </MuiBox>
          <MuiBox mb={3} mt={2}>
            <MuiDivider />
          </MuiBox>
          {isCovidPatientSelect && patientsList?.length > 0 ? (
            <MuiBox justifyItems="center" alignItems="center" pl={9} pr={9}>
              <MuiGrid color={Color.cyanBlueLight} container spacing={2}>
                <MuiBox paddingRight={1} bgcolor={Color.cyanBlueLight} display="flex">
                  <MuiAlert color={Color.cyanBlueLight} severity="info">
                    <MuiTypography fontSize={FontSize.base}>
                      For children less than three months old and experiencing symptoms, please call
                      your pediatrician or Connect Care for evaluation. Children this age cannot
                      receive the self-serve saliva PCR test.
                    </MuiTypography>
                  </MuiAlert>
                </MuiBox>
              </MuiGrid>
            </MuiBox>
          ) : null}
          <Spacer size="medium" />
          <DataLoader
            data={patients}
            loading={patientsLoading}
            renderLoading={() => <BookingLoading />}
            renderNoData={() => (
              <BookingNoData message={patientSelect.GET_PATIENTS.WEB.ERROR.description} />
            )}
            renderData={data => (
              <MuiGrid container justify="center">
                <MuiFormControl component="fieldset">
                  {data.map((patient, index) => (
                    <MuiRadioGroup
                      data-testid={`${id}-patient-select-radio-group`}
                      onChange={() => handlePatientSelect(patient)}
                      key={patient?.consumerId}
                    >
                      <MuiGrid
                        direction="row"
                        container
                        alignItems="center"
                        justify="space-between"
                      >
                        <MuiFormControlLabel
                          value=""
                          control={
                            <MuiRadio color="secondary" checked={getPatientIsSelected(patient)} />
                          }
                          label={
                            <MuiTypography style={{ width: 'auto' }}>
                              {fullName(patient)}
                            </MuiTypography>
                          }
                          data-testid={`${id}-patient-select-radio-${index}`}
                          disabled={isCovidPatientSelect && child(patient)}
                        />
                        <MuiGrid item>
                          <UserAvatar
                            fallback={getUserName(patient)}
                            avatarUrl={avatarsMap[patient.consumerId]}
                            size={35}
                            backgroundColor={Color.cobaltTint}
                            initialsFontSize={FontSize.base}
                            initialsFontColor={Color.secondary}
                            borderColor={Color.secondary}
                          />
                        </MuiGrid>
                      </MuiGrid>
                    </MuiRadioGroup>
                  ))}
                </MuiFormControl>
              </MuiGrid>
            )}
          />
        </MuiBox>
      </MuiContainer>
    </StyledScreen>
  );
};
export default PatientLoader;
