import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { Color } from 'modules/styles/colors';
import { Spacing } from 'modules/styles/variables';
import { MuiListItem, MuiListItemIcon } from 'theme/material-ui';

export const CollapsedLink = styled(props => <Link {...props} />)`
  &&& {
    pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
    text-decoration: none;
    color: ${props => (props.disabled ? Color.grayHue5 : Color.black)};
    flex-direction: row;
    &:focus,
    &:active,
    &:visited,
    &:hover {
      text-decoration: none;
      color: ${Color.black};
    }
  }
`;

export const CollapsedMuiListItem = styled(MuiListItem)`
  &&& {
    display: flex;
    justify-content: center;
    padding: ${Spacing.medium}px 0;
  }
`;

export const CollapsedMuiListIcon = styled(MuiListItemIcon)`
  &&& {
    display: flex;
    justify-content: center;
    min-width: 36px;
  }
`;
