import React from 'react';

import { FontWeight, Spacing } from 'modules/styles/variables';
import { Color } from 'modules/styles/colors';

import WebMuiModal from 'components/UI/Modals/WebMuiModal';
import Svg from 'components/UI/Svg/Svg';
import Spacer from 'components/UI/Layout/Spacer';
import {
  MuiBox,
  MuiTypography,
  CursorMuiBox,
  MuiDivider,
  MuiButton,
  MuiContainer
} from 'theme/material-ui';
import { getPhoneDetails, PhoneType } from 'lib/triage/utils';

export interface PhoneModalProps {
  visible: boolean;
  handleToggle: () => void;
  phoneType: PhoneType;
  initiateCall: (x: string) => void;
}

const PhoneNumberModal = ({ visible, handleToggle, phoneType, initiateCall }: PhoneModalProps) => {
  const { title, number } = getPhoneDetails(phoneType);
  const handleCall = () => {
    if (number) initiateCall(number);
  };

  return (
    <WebMuiModal open={visible} handleClose={handleToggle}>
      <MuiBox alignItems="center" display="flex" flexDirection="column">
        <MuiBox mt={`${Spacing.large}px`} mb={`${Spacing.xLarge}px`}>
          <MuiTypography variant="h5" fontWeight={FontWeight.semibold}>
            For further assistance, please dial:
          </MuiTypography>
          <Spacer size="xsmall" />
          <MuiDivider />
        </MuiBox>
        <MuiTypography variant="h6">{title}</MuiTypography>
        <CursorMuiBox
          onClick={handleCall}
          data-testid="handle-call"
          alignItems="center"
          display="flex"
          flexDirection="row"
          mr={2}
        >
          <Svg name="Phone" fill={Color.primary} height="50" width="50" />
          <MuiTypography variant="h5" color={Color.primary}>
            {number}
          </MuiTypography>
        </CursorMuiBox>
        <MuiContainer maxWidth="sm">
          <MuiBox my={`${Spacing.large}px`} width="100%">
            <MuiButton
              variant="outlined"
              data-testid="handle-close"
              onClick={handleToggle}
              fullWidth
            >
              Dismiss
            </MuiButton>
          </MuiBox>
        </MuiContainer>
      </MuiBox>
    </WebMuiModal>
  );
};

export default PhoneNumberModal;
