import React from 'react';

import { MuiTypography } from 'theme/material-ui';

import { Color } from 'modules/styles/colors';
import { FontSize, FontWeight } from 'modules/styles/variables';

import { CalendarIconContainer, CalendarMonth } from '../../styled';

export interface CalendarDateIconProps {
  month: string;
  day: string;
  time?: string;
}

export const CalendarDateIcon = ({ month, day, time }: CalendarDateIconProps) => {
  return (
    <CalendarIconContainer>
      <CalendarMonth>
        <MuiTypography
          fontSize={10}
          fontWeight={FontWeight.semibold}
          color={Color.white}
          align="center"
        >
          {month?.toUpperCase()}
        </MuiTypography>
      </CalendarMonth>
      <MuiTypography
        fontSize={FontSize.large}
        fontWeight={FontWeight.bold}
        color={Color.white}
        align="center"
      >
        {day}
      </MuiTypography>
      <CalendarMonth>
        <MuiTypography
          fontSize={10}
          fontWeight={FontWeight.semibold}
          color={Color.white}
          align="center"
        >
          {time}
        </MuiTypography>
      </CalendarMonth>
    </CalendarIconContainer>
  );
};

export default CalendarDateIcon;
