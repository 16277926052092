import { Consumer, Address } from 'store/profile/types';
import { useSelector } from 'react-redux';
import { isSsnExistSelector } from 'store/account/selectors';

export function getUpdatedEmailConsumer(consumer: Consumer, email: string) {
  consumer.emails = [
    {
      primary: true,
      value: email,
      verified: false,
      type: 'HOME'
    }
  ];

  return consumer;
}

export function getUpdatedPhoneConsumer(consumer: Consumer, phone: string) {
  consumer.phones = [
    {
      primary: true,
      value: phone,
      verified: false,
      type: 'MOBILE'
    }
  ];

  return consumer;
}

export function getUpdatedAddressConsumer(consumer: Consumer, address: Address) {
  if (address && !address.country) {
    address.country = 'US';
  }

  consumer.addresses = [address];
  return consumer;
}

export const useSsnStatus = () => useSelector(isSsnExistSelector);
