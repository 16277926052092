import React, { useState, useMemo } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { useSelector } from 'react-redux';
import { MuiBox } from 'theme/material-ui';
import { FilterDrawer } from 'components/FilterDrawer';
import { SelectDropdown } from 'components/UI/SelectDropdown';
import { insurancesDataSelector } from 'store/providerSearch/selectors';
import { isSelectHealthMemberSelector, profileInsurancesSelector } from 'store/profile/selectors';
import { AzureFacet, ProviderFacetGroups } from 'store/providerSearch/types';
import { useProviderSearchFilter } from 'lib/hooks/ProviderSearch/useProviderSearchFilters';
import { FiltersStructure } from 'lib/hooks/ProviderSearch/types';
import { hasLength } from 'modules/utils/ArrayUtils';
import { FormCheckboxList, FormChangeItem } from '../shared';

type InsuranceSelectProps = MainFilterProps['values']['acceptsMyInsurance']['values'];

function InsuranceSelect({ insuranceAliasValues, insurancePlanNcid }: InsuranceSelectProps) {
  const aliasValues = ProviderFacetGroups.insuranceAliasValues;
  const planNcid = ProviderFacetGroups.insurancePlanNcid;

  const [profileInsurance] = useSelector(profileInsurancesSelector);
  const insurances = useSelector(insurancesDataSelector);
  const [field, helpers] = useProviderSearchFilter('facets');
  const facets = cloneDeep(field.value);

  const myInsurance = profileInsurance ? insuranceAliasValues(profileInsurance) : null;
  const otherInsurances = Object.values(insurancePlanNcid.values(insurances));

  const { title, value } = useMemo(() => {
    let result = { title: '', value: '' } as AzureFacet;

    if (hasLength(field.value[aliasValues])) {
      [result] = field.value[aliasValues];
    } else if (hasLength(field.value[planNcid])) {
      [result] = field.value[planNcid];
    }

    return result;
  }, [field]);

  const items = useMemo(() => {
    return myInsurance ? [myInsurance, ...otherInsurances] : otherInsurances;
  }, [myInsurance, otherInsurances]);

  const [showSelect, setShowSelect] = useState(!title);

  const onChange = (e: React.ChangeEvent<any>) => {
    const data = JSON.parse(e.currentTarget.getAttribute('data-object'));

    if (data.group === planNcid) {
      facets[planNcid] = [data];
      facets[aliasValues] = [];
    }

    if (data.group === aliasValues) {
      facets[aliasValues] = [data];
      facets[planNcid] = [];
    }

    helpers.setValue(facets);
    setShowSelect(false);
  };

  if (showSelect) {
    return (
      <MuiBox mb={1}>
        <SelectDropdown
          fullWidth
          items={items}
          value={value}
          itemToLabel={item => item?.title || ''}
          onChange={onChange}
          label={insurancePlanNcid.title}
          placeholder={insurancePlanNcid.title}
        />
      </MuiBox>
    );
  }

  return (
    <MuiBox mb={1}>
      <FormChangeItem value={title || ''} onClick={() => setShowSelect(true)} />
    </MuiBox>
  );
}

export type MainFilterProps = FiltersStructure['main'] & {
  defaultExpanded: boolean;
};

function MainFilter({ title, values: valuesProp, defaultExpanded }: MainFilterProps) {
  const isSelectHealth = useSelector(isSelectHealthMemberSelector);

  const values: Mutable<typeof valuesProp> = cloneDeep(valuesProp);

  if (!isSelectHealth) {
    delete values.acceptsMyInsurance;
  }

  return (
    <FilterDrawer.Panel defaultExpanded={defaultExpanded} title={title}>
      <FormCheckboxList
        list={Object.values(values)}
        renderItem={item => {
          if (item.group === 'acceptsMyInsurance') {
            return <InsuranceSelect {...item.values} />;
          }

          return null;
        }}
      />
    </FilterDrawer.Panel>
  );
}

export default MainFilter;
