export interface SidebarLink {
  id: number;
  name: string;
  routeName: string;
  newWindow?: boolean;
  analyticsName?: string;
  key: string;
}

export const FOOTER_LINK: SidebarLink = {
  id: 15,
  name: 'Help',
  routeName: '/u/help-support'
};
