import React from 'react';
import {
  MuiFormControlLabel,
  MuiRadio,
  MuiFormLabel,
  MuiFormControl,
  MuiRadioGroup,
  MuiFormHelperText,
  MuiGrid
} from 'theme/material-ui';
import useStyles from '../useStyles';

export interface ConnectCareRadioGroupProps<T> {
  items: T[];
  itemToValue: (item: T) => string;
  itemToLabel: (item: T) => string;
  itemToRender: (item: T) => (() => JSX.Element) | null;
  value: T;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>, item?: T) => void;
  label?: React.ReactNode;
  disabled?: boolean;
  error?: boolean;
  helperText?: React.ReactNode;
  name?: string;
  row?: boolean;
}

function ConnectCareRadioGroup<T>(props: ConnectCareRadioGroupProps<T>) {
  const {
    items,
    value,
    itemToValue,
    itemToLabel,
    itemToRender,
    label,
    onChange,
    disabled,
    error,
    helperText,
    name,
    row,
    ...rest
  } = props;
  const classes = useStyles({ row });
  const selectionValue = itemToValue(value);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      const selection = items.find(item => itemToValue(item) === e.target.value);
      onChange(e, selection);
    }
  };

  return (
    <MuiFormControl component="fieldset" disabled={disabled} className={classes.formControl}>
      {!!label && (
        <MuiFormLabel component="legend" error={error} className={classes.formLabel}>
          {label}
        </MuiFormLabel>
      )}
      <MuiRadioGroup
        {...rest}
        name={name}
        value={selectionValue}
        onChange={handleChange}
        className={classes.radioGroup}
      >
        {items.map(item => {
          const itemValue = itemToValue(item);
          const itemLabel = itemToLabel(item);
          const itemRender = itemToRender(item);

          return typeof itemRender === 'function' && selectionValue === itemValue ? (
            <MuiGrid key={itemValue} container spacing={2}>
              <MuiGrid item xs={12}>
                <MuiFormControlLabel
                  value={itemValue}
                  label={itemLabel}
                  control={<MuiRadio color="secondary" />}
                />
              </MuiGrid>
              <MuiGrid item xs={12}>
                {itemRender()}
              </MuiGrid>
            </MuiGrid>
          ) : (
            <MuiFormControlLabel
              key={itemValue}
              value={itemValue}
              label={itemLabel}
              control={<MuiRadio color="secondary" />}
            />
          );
        })}
      </MuiRadioGroup>
      <MuiFormHelperText className={classes.formHelperText}>{helperText}</MuiFormHelperText>
    </MuiFormControl>
  );
}

ConnectCareRadioGroup.defaultProps = {
  itemToValue: (item: any) => (item ? item.value : ''),
  itemToLabel: (item: any) => (item ? item.label : ''),
  itemToRender: (item: any) => (item ? item.render : null)
};

export default ConnectCareRadioGroup;
