import React from 'react';
import { MuiBox, MuiTypography } from 'theme/material-ui';
import Spinner from 'components/UI/Spinner/Spinner';
import { HEALTH_RECORDS_PAGES } from 'lib/constants/healthRecords';

export interface MedicalHistoryMessageProps {
  type: 'allergies' | 'immunizations' | 'problems' | 'procedures' | 'social history' | 'USIR';
  data?: string | any[];
  error?: Error | null;
  loading?: boolean;
}

export function MedicalHistoryMessage({ loading, error, type, data }: MedicalHistoryMessageProps) {
  let message: string | undefined;
  const noData = !data || !data.length;
  if (loading && noData) {
    message = HEALTH_RECORDS_PAGES.MedicalHistory.loading;
  } else if (error) {
    message = HEALTH_RECORDS_PAGES.getErrorState(type);
  } else if (noData) {
    message = HEALTH_RECORDS_PAGES.getEmptyState(type);
  } else {
    return null;
  }

  return (
    <MuiBox data-testid="medical-history-message" pt={3} width="100%">
      <MuiTypography align="center">{message}</MuiTypography>
      {loading ? (
        <MuiBox p={3} display="flex" flexDirection="row" justifyContent="center">
          <Spinner />
        </MuiBox>
      ) : null}
    </MuiBox>
  );
}
