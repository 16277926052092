import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import { Alert } from 'components/Alert';
import { RouteComponentProps } from 'react-router';
import { Appointment, CheckInStatus } from 'store/amwell/types';
import analyticsService, { AnalyticsEvent } from 'services/AnalyticsService';
import * as selectors from 'store/amwell/selectors';
import {
  currentLocationPathNameSelector,
  previousLocationPathNameSelector
} from 'store/router/selectors';
import * as actions from 'store/amwell/actions';
import { RootState, RootDispatch } from 'store/types';
import {
  MuiBox,
  MuiGrid,
  MuiTypography,
  MuiDivider,
  MuiIcon,
  MuiButton,
  MuiContainer
} from 'theme/material-ui';
import { appointmentDetails as appointmentDetailsConfirmations } from 'modules/constants/amwell';
import Screen from 'components/UI/Layout/Screen';
import {
  ConnectCareError,
  ConnectCareLoading,
  ConnectCareConsentToTreatDialog
} from 'components/ConnectCare';
import { FontWeight, FontSize } from 'modules/styles/variables';
import { toSentenceCase } from 'modules/utils/StringUtils';

export interface Props extends RouteComponentProps<{ id: string }> {
  appointments: Appointment[];
  appointmentsLoading: boolean;
  appointmentsError: Error | null;
  appointment: Appointment | null;
  appointmentLoading: boolean;
  appointmentError: Error | null;
  currentUrl?: string;
  referringUrl?: string;
  getAppointments: () => void;
  getAppointment: (appointment: Appointment) => void;
  createVisitContextWithAppointment: (appointment: Appointment) => void;
}

const Title = (props: { children: React.ReactNode }) => (
  <MuiTypography
    fontWeight={FontWeight.semibold}
    fontSize={FontSize.large}
    variant="body1"
    gutterBottom
  >
    {props.children}
  </MuiTypography>
);

const Description = (props: { children: React.ReactNode }) => (
  <MuiTypography fontSize={FontSize.large} variant="body1">
    {props.children}
  </MuiTypography>
);

const getAppointmentDate = (appointment: Appointment) => {
  return dayjs(appointment.schedule.scheduledStartTime);
};

const getcheckInProperties = (appointment: Appointment | null) => {
  if (appointment) {
    const currentDate = dayjs();
    const appointmentDate = getAppointmentDate(appointment);
    const relativeAppointmentDate = appointmentDate.fromNow();
    const buttonLabel =
      appointmentDate < currentDate
        ? `Ended ${relativeAppointmentDate}`
        : `Starting ${relativeAppointmentDate}`;

    switch (appointment.checkInStatus) {
      case CheckInStatus.LATE:
        return {
          buttonLabel,
          buttonDisabled: true,
          description: 'Your appointment has passed'
        };
      case CheckInStatus.NO_PROVIDER:
        return {
          buttonLabel: 'Provider Unavailable',
          buttonDisabled: true,
          description: 'Your provider is not available'
        };
      case CheckInStatus.EARLY:
        return {
          buttonLabel,
          buttonDisabled: true,
          description: 'You are early for the appointment'
        };
      case CheckInStatus.ON_TIME:
        return {
          buttonLabel,
          buttonDisabled: false,
          description: 'Your appointment is ready to start'
        };
      default:
        return {
          buttonLabel: 'N/A',
          buttonDisabled: true,
          description: 'N/A'
        };
    }
  }
  return {
    buttonLabel: 'N/A',
    buttonDisabled: true,
    description: 'N/A'
  };
};

export const ConnectCareAppointmentDetails = (props: Props) => {
  const {
    appointments,
    appointmentsLoading,
    appointmentsError,
    getAppointments,
    appointment,
    appointmentLoading,
    appointmentError,
    getAppointment,
    createVisitContextWithAppointment,
    history,
    match,
    referringUrl,
    currentUrl
  } = props;
  const [isStarting, setIsStarting] = useState(false);
  const [showConsentDialog, setShowConsentDialog] = useState(false);

  const checkInProperties = useMemo(() => {
    return getcheckInProperties(appointment);
  }, [appointment]);

  useEffect(() => {
    if (appointments?.length) {
      const tempAppointment = appointments.find(a => a.sourceId === match.params.id);

      if (!tempAppointment) {
        Alert.alert(
          appointmentDetailsConfirmations.NO_APPOINTMENT_ERROR.title,
          appointmentDetailsConfirmations.NO_APPOINTMENT_ERROR.subtitle,
          [{ text: 'OK', onPress: () => {} }],
          { closeIcon: true, onClose: () => history.push('/u/get-care-now/connect-care') }
        );
      } else {
        getAppointment(tempAppointment);
      }
    } else {
      getAppointments();
    }
  }, [appointments, match]);

  const onTermsAgree = () => {
    analyticsService.logEvent(AnalyticsEvent.ConnectCareAppointmentTCAgreeClicked, {
      referringUrl,
      currentUrl
    });
    history.push('/u/get-care-now/connect-care/form/location', { fromAppointment: true });
  };

  const onStartAppointmentVisit = () => {
    setIsStarting(true);
    createVisitContextWithAppointment(appointment).then(res => {
      setIsStarting(false);
      if (res.error) {
        Alert.alert(
          appointmentDetailsConfirmations.START_APPOINTMENT_ERROR.title,
          appointmentDetailsConfirmations.START_APPOINTMENT_ERROR.subtitle
        );
      } else {
        setShowConsentDialog(true);
      }
    });
  };

  const renderAppointmentDetails = () => {
    if (appointmentsError || appointmentError) {
      return (
        <ConnectCareError
          message={appointmentDetailsConfirmations.GET_ERROR.title}
          action={{
            onClick: getAppointments,
            label: 'Try Again?'
          }}
        />
      );
    }

    if (appointmentsLoading || appointmentLoading || !appointment) {
      return <ConnectCareLoading />;
    }

    return (
      <MuiGrid container spacing={3}>
        <MuiGrid item xs>
          <Title>Check In Status</Title>
          <Description>{checkInProperties.description}</Description>
        </MuiGrid>
        <MuiGrid item xs>
          <Title>Appointment Date</Title>
          <Description>{getAppointmentDate(appointment).format('MMMM D, YYYY, h:mma')}</Description>
        </MuiGrid>
        <MuiGrid item xs>
          <Title>Start Visit</Title>
          <MuiButton
            color="primary"
            startIcon={<MuiIcon>schedule</MuiIcon>}
            variant="contained"
            disabled={checkInProperties.buttonDisabled}
            loading={isStarting}
            onClick={onStartAppointmentVisit}
          >
            {toSentenceCase(checkInProperties.buttonLabel)}
          </MuiButton>
        </MuiGrid>
      </MuiGrid>
    );
  };

  return (
    <Screen>
      <MuiContainer maxWidth="md">
        <MuiBox py={3}>
          <MuiTypography variant="h4">Appointment Details</MuiTypography>
          <MuiBox py={1}>
            <MuiDivider />
          </MuiBox>
          {renderAppointmentDetails()}
        </MuiBox>
      </MuiContainer>
      <ConnectCareConsentToTreatDialog
        isOpen={showConsentDialog}
        onDecline={() => setShowConsentDialog(false)}
        onError={() => {
          Alert.alert(
            'Something went wrong!',
            'There was an issue updating your appointment details. Please try again.'
          );
        }}
        onAgree={onTermsAgree}
      />
    </Screen>
  );
};

const mapStateToProps = (state: RootState) => ({
  appointments: selectors.appointmentsDataSelector(state),
  appointmentsLoading: selectors.appointmentsLoadingSelector(state),
  appointmentsError: selectors.appointmentsErrorSelector(state),
  appointment: selectors.appointmentDataSelector(state),
  appointmentLoading: selectors.appointmentLoadingSelector(state),
  appointmentError: selectors.appointmentErrorSelector(state),
  currentUrl: currentLocationPathNameSelector(state),
  referringUrl: previousLocationPathNameSelector(state)
});

const mapDispatchToProps = (dispatch: RootDispatch) => ({
  getAppointments: () => dispatch(actions.getAppointments()),
  getAppointment: (appointment: Appointment) => dispatch(actions.getAppointment({ appointment })),
  createVisitContextWithAppointment: (appointment: Appointment) =>
    dispatch(actions.createVisitContextWithAppointment({ appointment }))
});

export default connect(mapStateToProps, mapDispatchToProps)(ConnectCareAppointmentDetails);
