import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'store/types';
import * as routerSelectors from 'store/router/selectors';

import { FilterDrawer } from 'components/FilterDrawer';
import { AuditLogFiltersForm } from 'lib/hooks/AuditLogFilters/useAuditLogFilters';
import { AuditLogOptions } from 'store/audit/types';
import { FiltersFormContext, FiltersKey } from 'lib/hooks/AuditLogFilters/types/filter';
import { AuditLogFilterInterface } from 'lib/hooks/AuditLogFilters/useAuditLogFilter';
import AuditLogFiltersContent from './AuditLogFiltersContent';

export interface Props {
  // Local Props
  open: boolean;
  defaultExpandedFilters: FiltersKey[];
  defaultOptions: AuditLogOptions;
  onClose: () => void;
  filter: AuditLogFilterInterface['filter'];
}

export function AuditLogFilters({
  // Local Props
  open,
  filter,
  defaultOptions,
  onClose: handleClose,
  defaultExpandedFilters
}: Props) {
  const onClose = ({ resetForm }: FiltersFormContext) => () => {
    resetForm();
    handleClose();
  };

  const onClearSubmit = options => {
    filter(options);
    handleClose();
  };

  return (
    <AuditLogFiltersForm
      initialOptions={defaultOptions}
      onSubmit={onClearSubmit}
      onClear={onClearSubmit}
    >
      {formProps => (
        <FilterDrawer
          open={open}
          onClose={onClose(formProps)}
          onApply={formProps.onSubmit}
          onClear={formProps.onClear}
          paperTop={185}
        >
          <AuditLogFiltersContent defaultExpandedKeys={defaultExpandedFilters} />
        </FilterDrawer>
      )}
    </AuditLogFiltersForm>
  );
}

const mapStateToProps = (state: RootState) => ({
  currentUrl: routerSelectors.currentLocationPathNameSelector(state),
  referringUrl: routerSelectors.previousLocationPathNameSelector(state)
});

export default connect(mapStateToProps)(AuditLogFilters);
