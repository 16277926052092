import React, { useState } from 'react';
import BloodPressureLineChart from 'components/BloodPressureLineChart/BloodPressureLineChart';
import { BPTrendsModal } from './styled';
import Modal from '@material-ui/core/Modal';
import { MuiBox, MuiButton } from 'theme/material-ui';

export function BloodPressureTrendsModal(props: { data: string | any[] }) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <MuiBox>
      <MuiButton
        fullWidth
        color="primary"
        variant="contained"
        aria-label="show graph"
        onClick={() => handleOpen()}
      >
        Show trends
      </MuiButton>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={handleClose}
      >
        <BPTrendsModal maxWidth={`${window.innerWidth}px`}>
          {props.data.length > 0 && <BloodPressureLineChart data={props.data} />}
        </BPTrendsModal>
      </Modal>
    </MuiBox>
  );
}

export default BloodPressureTrendsModal;
