import React from 'react';
import { useSelector } from 'react-redux';
import { FilterDrawer } from 'components/FilterDrawer';
import { FiltersStructure } from 'lib/hooks/ProviderSearch/types';
import { clinicsDataSelector } from 'store/providerSearch/selectors';
import { viewableCount } from '../constants';
import { FormCheckboxList } from '../shared';

export type ClinicsFilterProps = FiltersStructure['clinics'] & {
  defaultExpanded: boolean;
};

function ClinicsFilter({ title, values, defaultExpanded }: ClinicsFilterProps) {
  const clinics = useSelector(clinicsDataSelector);

  return (
    <FilterDrawer.Panel defaultExpanded={defaultExpanded} expandable={clinics.length} title={title}>
      <FormCheckboxList initialCount={viewableCount} list={Object.values(values(clinics))} />
    </FilterDrawer.Panel>
  );
}

export default ClinicsFilter;
