import { Accordion } from '@material-ui/core';
import { Color } from 'modules/styles/colors';
import styled from 'styled-components';

export const PatientFormAccordion = styled(Accordion)`
  &.MuiAccordion-rounded {
    border-radius: 10px;
  }
  &.MuiAccordion-root.formComplete {
    background-color: ${Color.greenShade};
    border: 1px solid ${Color.darkGreenShade};
  }

  &.MuiAccordion-root.Mui-expanded {
    background-color: unset;
    border: 1px solid ${Color.grayLight};
  }
`;
