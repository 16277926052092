import React from 'react';
import { useLocation } from 'react-router-dom';

import Svg from 'components/UI/Svg/Svg';
import { convertToLowerKabobCase } from 'modules/utils/StringUtils';
import { CollapsedLink, CollapsedMuiListItem, CollapsedMuiListIcon } from './styled';
import { SidebarMuiBadge } from '../ListItemButton/styled';

interface ComponentProps {
  set?: 'assets' | 'material' | 'ionic' | 'downloading' | 'web' | undefined;
  disabled?: boolean;
  iconName?: string;
  iconSize?: string | number;
  onClick?: Function;
  routeName: string;
  routeText: string;
  unreadCount: number | null;
}

interface CollapsedItemProps {
  active: boolean;
  disabled: boolean;
  isLoading?: boolean;
}

interface MessagingItemProps {
  active: boolean;
  disabled: boolean;
  unreadCount: number;
}

const CollapsedItemButton = ({
  set,
  disabled,
  iconName,
  iconSize,
  routeName,
  routeText,
  onClick,
  unreadCount
}: ComponentProps) => {
  const location = useLocation().pathname;

  const CollapsedListItem = ({ active, disabled }: CollapsedItemProps) => {
    return (
      <CollapsedMuiListItem button>
        <CollapsedMuiListIcon>
          <Svg
            name={iconName}
            set={set || 'assets'}
            size={iconSize}
            active={active}
            disabled={disabled}
          />
        </CollapsedMuiListIcon>
      </CollapsedMuiListItem>
    );
  };

  const MessagingListItem = ({ active, disabled, unreadCount }: MessagingItemProps) => {
    return (
      <CollapsedMuiListItem button>
        {unreadCount > 0 && !active ? (
          <SidebarMuiBadge badgeContent={unreadCount}>
            <CollapsedMuiListIcon>
              <Svg name="SNavMessages" set="assets" size={iconSize} disabled={false} />
            </CollapsedMuiListIcon>
          </SidebarMuiBadge>
        ) : (
          <CollapsedMuiListIcon>
            <Svg
              name="SNavMessages"
              set="assets"
              size={iconSize}
              active={active}
              disabled={disabled}
            />
          </CollapsedMuiListIcon>
        )}
      </CollapsedMuiListItem>
    );
  };

  return (
    <CollapsedLink
      data-testid={convertToLowerKabobCase(routeText.toString(), `-sidebar-link`)}
      to={routeName}
      onClick={onClick}
      disabled={disabled || false}
    >
      {iconName === 'SNavMessages' ? (
        <MessagingListItem
          active={routeName === location}
          disabled={disabled || false}
          unreadCount={unreadCount || 0}
        />
      ) : (
        <CollapsedListItem active={routeName === location} disabled={disabled || false} />
      )}
    </CollapsedLink>
  );
};

export default CollapsedItemButton;
