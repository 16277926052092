import React, { Component } from 'react';
import Logger, { LoggingModule } from 'services/Logger';
import { Color } from 'modules/styles/colors';
import { MuiBox, MuiButton, MuiContainer, MuiTypography } from 'theme/material-ui';
import SupergraphicBackground from '../SupergraphicBackground/SupergraphicBackground';

interface State {
  hasError: boolean;
}

export default class ErrorBoundary extends Component<{}, State> {
  state = {
    hasError: false
  };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  // Reload the app from the landing page
  handleRestart = () => {
    window.location.href = `${window.location.origin}/landing`;
  };

  componentDidCatch(error: Error, info: React.ErrorInfo) {
    Logger.fatal(
      `${LoggingModule.errorBoundary}ErrorBoundary componentDidCatch: ${error.message}`,
      info
    );
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      return (
        <SupergraphicBackground>
          <MuiBox display="flex" alignItems="flex-end" flex={1} width="100%" height="100%" p={2}>
            <MuiContainer maxWidth="sm">
              <MuiBox pb={2}>
                <MuiTypography align="center" color={Color.white}>
                  Sorry, the application unexpectedly crashed
                </MuiTypography>
              </MuiBox>

              <MuiButton onClick={this.handleRestart} fullWidth variant="contained" color="primary">
                Reload the application
              </MuiButton>
            </MuiContainer>
          </MuiBox>
        </SupergraphicBackground>
      );
    }

    return this.props.children;
  }
}
