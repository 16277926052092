/* eslint-disable no-useless-escape */
import * as Yup from 'yup';

export const InsuranceValidateSchema = Yup.object().shape({
  Health_Plan_Name: Yup.string()
    .max(100, '100 characters maximum')
    .required('Please enter Health Plan Name'),
  Health_Plan_ID: Yup.string()
    .max(20, '20 characters maximum')
    .required('Please enter Health Plan ID'),
  MEMBER_NUMBER: Yup.string()
    .max(20, '20 characters maximum')
    .required('Please enter Member Number/ID'),
  GROUP_NUMBER: Yup.string()
    .max(20, '20 characters maximum')
    .optional(),
  Health_Plan_Address_line_1: Yup.string()
    .max(30, '30 characters maximum')
    .required('Please enter Insurance Street'),
  HealthPlan_City: Yup.string()
    .max(34, '34 characters maximum')
    .required('Please enter Insurance City'),
  HealthPlan_state: Yup.string()
    .max(2, '2 characters maximum')
    .required('Please enter Insurance State'),
  HealthPlan_zip_code: Yup.string()
    .required('Please enter Insurance Zip')
    .matches(/^[0-9]+$/, 'Must be a valid zip')
    .min(5, 'Must be exactly 5 digits')
    .max(5, 'Must be exactly 5 digits'),
  Q_Patient_relationship_to_subscriber: Yup.string().required(
    'Please enter Patient relationship to subscriber'
  ),
  SUBSCRIBER_FIRST_NAME_ON_CARD: Yup.string()
    .max(16, 'Maximum 16 characters')
    .matches(/^[a-zA-Z-]*$/, 'No numbers or special characters or space allowed')
    .required('Please enter First name on card'),
  SUBSCRIBER_MIDDLE_NAME_ON_CARD: Yup.string()
    .max(16, 'Maximum 16 characters')
    .matches(/^[a-zA-Z-]*$/, 'No numbers or special characters or space allowed')
    .optional(),
  SUBSCRIBER_LAST_NAME_ON_CARD: Yup.string()
    .max(26, 'Maximum 26 characters')
    .matches(/^[a-zA-Z-]*$/, 'No numbers or special characters or space allowed')
    .required('Please enter Last name on card')
});
