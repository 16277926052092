import { Box, IconButton, Slide } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import { Alert } from 'components/Alert';
import { Color } from 'modules/styles/colors';
import { FontSize, FontWeight, IconSize, Spacing, ZIndex } from 'modules/styles/variables';
import React from 'react';
import { connect, useDispatch } from 'react-redux';
import analyticsService, { AnalyticsEvent } from 'services/AnalyticsService';
import { logout } from 'store/authentication/actions';
import { profileIsFetchingSelector } from 'store/profile/selectors';
import { resetAccountAccessStartedSelector } from 'store/account/selectors';
import { RootState } from 'store/types';
import styled from 'styled-components';
import {
  MuiBox,
  MuiButton,
  MuiDialog,
  MuiDivider,
  MuiGrid,
  MuiTypography
} from 'theme/material-ui';
import FlexBox from './UI/Layout/FlexBox';
import SpinnerModal from './UI/Spinner/SpinnerModal';
import { Svg } from './UI/Svg';
import { useHistory } from 'react-router-dom';
import { ProxyFormNav } from 'screens/ProxyForm/navigation/router';
import { createMessagingAliases } from 'store/account/actions.web';
import { AppName, getAppName } from 'modules/utils/ConfigUtils';

const Slider = React.forwardRef<unknown, TransitionProps>((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const Divider = styled(MuiDivider)`
  && {
    background-color: ${Color.grayLight};
    width: 100%;
  }
`;

export interface ResetAccountAccessModalProps {
  open: boolean;
  onClose: () => void;
  isLoading: boolean;
}

export function ResetAccountAccessModal({
  open,
  onClose,
  isLoading
}: ResetAccountAccessModalProps) {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleConfirm = async () => {
    window.sessionStorage.setItem('resetAccountAccess', 'TRUE');
    const messagingAliasesResponse = await dispatch(createMessagingAliases());
    const { error } = messagingAliasesResponse;

    if (error) {
      Alert.alert('There was an issue refreshing users list, please try again later.');
    } else {
      dispatch(logout());
    }

    analyticsService.logEvent(AnalyticsEvent.AccountAccessReset, {
      source: 'Resetting Account Access'
    });
  };

  const handleNewProxyRequestAccount = () => {
    history.push(ProxyFormNav.PROXY_INFO);
  };

  return (
    <MuiDialog open={open} onClose={onClose} TransitionComponent={Slider} scroll="paper">
      <MuiBox position="absolute" top={5} right={5} zIndex={ZIndex.high}>
        <IconButton onClick={onClose} aria-label="close reset account access">
          <Svg set="material" name="close" size={IconSize.large} />
        </IconButton>
      </MuiBox>
      <MuiBox
        display="flex"
        flexDirection="column"
        alignItems="center"
        width="100%"
        height="100%"
        p={5}
        mt={5}
      >
        <MuiBox mb={5}>
          <MuiTypography fontSize={FontSize.heading} fontWeight={FontWeight.semibold}>
            Refresh user access?
          </MuiTypography>
        </MuiBox>

        <MuiGrid>
          <MuiGrid>
            <MuiTypography>
              If there has been a recent change to the patients you have access to, you will need to
              refresh your access to your {getAppName(AppName.base)} account to view their medical
              data.
            </MuiTypography>
            <Box height={Spacing.large} />
            <MuiTypography>
              Refreshing access will log you out of your {getAppName(AppName.base)} account. When
              you log back in, you will be prompted to reauthorize access to medical records.
            </MuiTypography>
          </MuiGrid>
        </MuiGrid>
        <FlexBox vOffsetTop={Spacing.xLarge} width={Spacing.xxxLarge} alignSelf="center">
          <Box height={Spacing.medium} />
          <MuiButton color="primary" variant="contained" onClick={() => handleConfirm()}>
            <MuiTypography>Continue with refresh</MuiTypography>
          </MuiButton>
        </FlexBox>
        <Box height={Spacing.xLarge} />
        <Divider />
        <Box height={Spacing.xLarge} />
        <MuiButton color="primary" variant="text" onClick={handleNewProxyRequestAccount} fullWidth>
          Request access to another account
        </MuiButton>
        <Box height={Spacing.xLarge} />
      </MuiBox>
      <SpinnerModal isLoading={isLoading} />
    </MuiDialog>
  );
}

const mapStateToProps = (state: RootState) => ({
  isLoading: profileIsFetchingSelector(state) || resetAccountAccessStartedSelector(state)
});

export default connect(mapStateToProps)(ResetAccountAccessModal);
