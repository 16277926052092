import React from 'react';
import { MuiBox, MuiPaper, MuiTypography, MuiGrid } from 'theme/material-ui';
import capitalize from 'lodash/capitalize';
import dayjs from 'dayjs';

import { Icon } from 'components/Icon';
import FlexBox from 'components/UI/Layout/FlexBox';
import Spacer from 'components/UI/Layout/Spacer';

import { Spacing } from 'modules/styles/variables';
import { Medication } from 'store/medications/types';

export interface MedicationsDetailProps {
  medication: Medication;
}

const MedicationsDetail = ({ medication }: MedicationsDetailProps) => {
  return (
    <MuiBox paddingY={Spacing.xSmall} paddingX={Spacing.small}>
      <MuiTypography>
        You have been prescribed {capitalize(medication.name.split(' (')[0])}.
      </MuiTypography>
      <MuiGrid container>
        <MuiGrid item xs={5} md={3}>
          <MuiTypography>Dosage:</MuiTypography>
        </MuiGrid>
        <MuiGrid item xs={7} md={9}>
          <MuiTypography>{medication?.dosage?.[0]?.dosage}</MuiTypography>
        </MuiGrid>
      </MuiGrid>
      <MuiGrid container>
        <MuiGrid item xs={5} md={3}>
          <MuiTypography>Instructions:</MuiTypography>
        </MuiGrid>
        <MuiGrid item xs={7} md={9}>
          <MuiTypography>{medication?.dosage?.[0]?.instructions}</MuiTypography>
        </MuiGrid>
      </MuiGrid>
      <Spacer size="xsmall" />
      {medication.routingPharmacy ? (
        <MuiGrid container>
          <MuiGrid item xs={5} md={3}>
            <MuiTypography>Medication sent to:</MuiTypography>
            <Spacer size="xsmall" />
            <MuiTypography>
              {dayjs(medication.duration.start).format('MM/DD/YYYY [at] HH:mm A')}
            </MuiTypography>
          </MuiGrid>
          <MuiGrid item xs={7} md={5}>
            <MuiPaper>
              <FlexBox flexDirection="row" padding={Spacing.large}>
                <MuiBox marginBottom="auto" marginTop="auto" marginRight={Spacing.xSmall}>
                  <Icon name="location-on" />
                </MuiBox>
                <FlexBox justifyContent="center">
                  <MuiTypography>
                    {medication.routingPharmacy?.routingPharmacyName.toUpperCase()}
                  </MuiTypography>
                  <MuiTypography>
                    {medication.routingPharmacy?.primaryBusinessAddress?.streetAddressLines?.[0]?.streetAddressLine.toUpperCase()}
                  </MuiTypography>
                </FlexBox>
              </FlexBox>
            </MuiPaper>
          </MuiGrid>
        </MuiGrid>
      ) : null}
    </MuiBox>
  );
};

export default MedicationsDetail;
