import styled from 'styled-components';
import { MuiButton, MuiIconButton } from 'theme/material-ui';
import { Color } from 'modules/styles/colors';

export const StyledMuiButton = styled(MuiButton)`
  && {
    border: 1px solid ${Color.secondary};
    background-color: ${props => (props && props.bgColor) || Color.white};
    color: ${props => (props && props.bgColor ? Color.white : Color.secondary)};
    width: ${props => (props && props.width ? `${props.width}px` : '')};
  }
`;

export const StyledMuiIconButton = styled(MuiIconButton)`
  && {
    border-radius: 4px;
    border: 1px solid ${Color.baseDark};
    background-color: ${props => (props && props.bgColor) || Color.white};
    color: ${props => (props && props.bgColor ? Color.white : Color.secondary)};
    padding: 6px;
  }
`;
