import React, { useEffect, useState, ChangeEvent } from 'react';
import { connect } from 'react-redux';

import { FormControlLabel, IconButton, Radio, RadioGroup } from '@material-ui/core';
import { MuiBox, MuiButton, MuiDialog, MuiTypography } from 'theme/material-ui';
import LinkingServices from 'services/LinkingServices';
import { RootDispatch, RootState } from 'store/types';
import { languageSelector } from 'store/consumerPreferences/selectors';
import * as consumerActions from 'store/consumerPreferences/actions';
import * as consumerTypes from 'store/consumerPreferences/types';
import { isDfdAuthenticatedSelector } from 'store/authentication/selectors';
import { Color } from 'modules/styles/colors';
import { FontSize, IconSize, Spacing, ZIndex, FontWeight } from 'modules/styles/variables';
import { cleanPhone } from 'modules/utils/PhoneUtils';
import { technicalHelpNumber } from 'modules/constants/phoneNumbers';
import {
  warningSpanishText,
  switcherTranslations
} from 'lib/constants/translations/components/LanguageSwitcher';
import { Svg } from 'components/UI/Svg';
import Spacer from 'components/UI/Layout/Spacer';
import useNavigationAnalytics from 'hooks/useNavigationAnalytics';
import { AnalyticsEvent } from 'services/AnalyticsService';

export interface LanguageSwitcherModalProps {
  open: boolean;
  handleClose: () => void;
  language: consumerTypes.LANGUAGE;
  isAuthenticated: boolean;
  updateAndRefreshSharedUiData: typeof consumerActions.updateAndRefreshSharedUiData;
  setLanguage: typeof consumerActions.setLanguage;
}

const LanguageSwitcherModal = ({
  open,
  handleClose,
  language,
  isAuthenticated,
  updateAndRefreshSharedUiData,
  setLanguage
}: LanguageSwitcherModalProps) => {
  const [languageSelected, setLanguageSelected] = useState(language);
  const applySelectedLanguage = useNavigationAnalytics(AnalyticsEvent.LanguageSelected);
  const languageSelectionError = useNavigationAnalytics(AnalyticsEvent.LanguageSelectionError);
  const [error, setError] = useState(false);

  useEffect(() => {
    setLanguageSelected(language);
  }, [language]);

  const onCloseModal = () => {
    setError(false);
    handleClose();
  };

  const onSelectLanguage = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setLanguageSelected(event.target.value);
    setError(false);
  };

  const onChangeLaguage = async () => {
    if (!error) {
      if (isAuthenticated) {
        const params: Partial<consumerTypes.ConsumerSharedUiData> = {
          preferredLanguage: languageSelected
        };
        const res = await updateAndRefreshSharedUiData(params);
        if (res.error || res === 'no consumer id') {
          languageSelectionError({
            title: 'Left Navigation Pane',
            language: languageSelected,
            errorCode: typeof res === 'string' ? '409' : res.error.response.data.errorCode,
            errorMessage: typeof res === 'string' ? res : res.error.response.data.errorDescription
          });
          setError(true);
          return;
        }
      } else {
        setLanguage(languageSelected);
      }
      applySelectedLanguage({
        title: 'Left Navigation Pane',
        language: languageSelected,
        isAuthenticated
      });
    }
    onCloseModal();
  };

  const handlePhoneNumber = () => {
    LinkingServices.callPhoneNumber(cleanPhone(technicalHelpNumber));
  };

  return (
    <MuiDialog open={open} onClose={onCloseModal} maxWidth="xs">
      <MuiBox position="absolute" top={Spacing.xSmall} right={Spacing.xSmall} zIndex={ZIndex.high}>
        <IconButton
          onClick={onCloseModal}
          aria-label="close reset account access"
          data-testid="close-button"
        >
          <Svg set="material" name="close" size={IconSize.medium} color={Color.textDark} />
        </IconButton>
      </MuiBox>
      <MuiBox marginX={Spacing.xSmall} marginTop={Spacing.medium} marginBottom={Spacing.small}>
        <MuiTypography
          data-testid="language-title"
          color={Color.primary}
          fontSize={FontSize.large}
          fontWeight={FontWeight.semibold}
        >
          {switcherTranslations[languageSelected].title}
        </MuiTypography>
        <MuiBox marginY={Spacing.xSmall}>
          <RadioGroup
            value={languageSelected}
            aria-label="laguage"
            name="language"
            onChange={onSelectLanguage}
          >
            <FormControlLabel
              data-testid="english-option"
              value="en"
              control={<Radio color="primary" />}
              label="English"
            />
            <FormControlLabel
              data-testid="spanish-option"
              value="es"
              control={<Radio color="primary" />}
              label="Español"
            />
          </RadioGroup>
        </MuiBox>
        {languageSelected === 'es' ? (
          <>
            <MuiTypography fontSize={FontSize.small} display="inline">
              {warningSpanishText}
            </MuiTypography>
            <MuiTypography display="inline" color={Color.link} onClick={handlePhoneNumber}>
              {technicalHelpNumber}
            </MuiTypography>
            <MuiTypography display="inline">.</MuiTypography>
            {error ? <Spacer size="small" /> : null}
          </>
        ) : null}
        {error ? (
          <MuiTypography fontSize={FontSize.base} color={Color.red}>
            {switcherTranslations[languageSelected].errorText}
          </MuiTypography>
        ) : null}
        <MuiBox
          marginTop={Spacing.small}
          width={295}
          maxWidth="100%"
          marginLeft="auto"
          marginRight="auto"
        >
          <MuiButton
            variant="contained"
            color="primary"
            fullWidth
            size="large"
            data-testid="apply-language-button"
            onClick={onChangeLaguage}
            disabled={languageSelected === language}
          >
            {error
              ? switcherTranslations[languageSelected].buttonTextError
              : switcherTranslations[languageSelected].buttonText}
          </MuiButton>
        </MuiBox>
      </MuiBox>
    </MuiDialog>
  );
};

const mapState = (state: RootState) => ({
  language: languageSelector(state),
  isAuthenticated: isDfdAuthenticatedSelector(state)
});

const mapDispatch = (dispatch: RootDispatch) => ({
  setLanguage: (value: string) => dispatch(consumerActions.setLanguage(value)),
  updateAndRefreshSharedUiData: (sharedUiData: consumerTypes.ConsumerSharedUiData) =>
    dispatch(consumerActions.updateAndRefreshSharedUiData(sharedUiData))
});

export default connect(mapState, mapDispatch)(LanguageSwitcherModal);
