import React, { MutableRefObject } from 'react';
import { FlatList } from 'react-native';
import { ViewStyleProp } from 'react-native/Libraries/StyleSheet/StyleSheet';
import DefaultLabel from '../Labels/DefaultLabel';
import Container from '../Layout/Container';
import ExpandableListItem from './ExpandableListItem';

interface ExpandableListProps {
  data?: object[];
  keyExtractor: Function;
  renderItem: Function;
  renderBody: Function;
  emptyCaption?: string;
  style?: ViewStyleProp;
  backgroundColor?: string;
  closeListBoolean?: boolean;
  web?: boolean;
  activeItemId?: string | number;
  setRef?: MutableRefObject<FlatList>;
  contentContainerStyle?: any;
}

const ExpandableList = ({
  data,
  emptyCaption,
  keyExtractor,
  closeListBoolean,
  activeItemId,
  setRef,
  web,
  contentContainerStyle,
  ...rest
}: ExpandableListProps) =>
  !data || data.length === 0 ? (
    <Container>
      <DefaultLabel>{emptyCaption}</DefaultLabel>
    </Container>
  ) : (
    <Container>
      <FlatList
        extraData={closeListBoolean}
        data={data}
        keyExtractor={keyExtractor}
        renderItem={({ item, index }) => (
          <ExpandableListItem
            closeListBoolean={closeListBoolean}
            item={item}
            firstChild={index === 0}
            lastChild={index === data.length - 1}
            isExpanded={parseInt(activeItemId?.split('--')[0] ?? '', 10) === index}
            web={web}
            {...rest}
          />
        )}
        ref={setRef}
        nativeID={activeItemId as string}
        contentContainerStyle={contentContainerStyle}
      />
    </Container>
  );

ExpandableList.defaultProps = {
  data: [],
  iconName: null,
  iconSet: null,
  emptyCaption: undefined,
  web: false
};

export default ExpandableList;
