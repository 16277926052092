import React, { ReactElement, useEffect, useState } from 'react';
import { Image } from 'react-native';
import { CursorMuiBox, MuiBox, MuiTypography } from 'theme/material-ui';
import { FontSize, FontWeight, Spacing } from 'modules/styles/variables.web';
import { Color } from 'modules/styles/colors';
import { Nullable } from 'modules/types/common';
import { BillingStatementTitles } from 'modules/constants/Billing';
import { Icon } from 'components/Icon';
import useBillingStatementInfoModal from 'screens/Billing/BillingHome/PaperStatementBillpayComponents/useBillingMailStatementInfoModal';
import analyticsService from 'services/AnalyticsService.web';
import { AMPLITUDE_EXPERIMENTS, AnalyticsEvent } from 'services/AnalyticsService.common';
import useNavigationAnalytics from 'hooks/useNavigationAnalytics';
import MailboxPaperStatement from 'assets/MailboxPaperStatement.png';

const useBillingBannerComponents = (componentName?: string) => {
  const entripayLinkClicked = useNavigationAnalytics(AnalyticsEvent.BillingBannerEntripay, {
    title: BillingStatementTitles.Entripay
  });
  const paperStatementMoreInfoClicked = useNavigationAnalytics(
    AnalyticsEvent.BillingBannerMoreInfo,
    {
      title: BillingStatementTitles.MoreInfo
    }
  );

  const [showWebpayExperiment, setShowWebpay] = useState(false);
  const [showEntripayExperiment, setShowEntripay] = useState(false);

  useEffect(() => {
    const webpayActive = analyticsService.getExperimentStatus(
      AMPLITUDE_EXPERIMENTS.WEBPAY_BANNER.flagKey,
      AMPLITUDE_EXPERIMENTS.WEBPAY_BANNER.variantA
    );
    setShowWebpay(webpayActive);
    const entripayActive = analyticsService.getExperimentStatus(
      AMPLITUDE_EXPERIMENTS.ENTRIPAY_BANNER.flagKey,
      AMPLITUDE_EXPERIMENTS.ENTRIPAY_BANNER.variantA
    );
    setShowEntripay(entripayActive);
  }, []);

  const handleEntripayPress = () => {
    entripayLinkClicked();
    window.open('https://www.intermountainbillpay.com', '_blank');
  };

  const showInfoModal = useBillingStatementInfoModal();

  const handleInfoBannerPress = () => {
    paperStatementMoreInfoClicked();
    showInfoModal();
  };

  let VariantA = () => null as Nullable<ReactElement>;
  let VariantB = () => null as Nullable<ReactElement>;

  if (componentName === 'BillingHomeScreen') {
    if (showWebpayExperiment) {
      VariantA = () => (
        <CursorMuiBox onClick={handleInfoBannerPress} mt={1}>
          <MuiBox
            p={Spacing.small}
            display="flex"
            bgcolor={Color.accent}
            flexDirection="row"
            maxHeight={50}
            justifyContent="center"
            alignItems="center"
          >
            <MuiBox px={1} justifyContent="center">
              <Image style={{ width: 40, height: 40 }} source={MailboxPaperStatement} />
            </MuiBox>
            <MuiBox display="inline">
              <MuiTypography color={Color.primary} fontSize={FontSize.xsmall}>
                If you have received a paper statement in the mail for{' '}
                <MuiTypography
                  fontWeight={FontWeight.bold}
                  color={Color.primary}
                  fontSize={FontSize.xsmall}
                  display="inline"
                >
                  Home Care
                </MuiTypography>{' '}
                or{' '}
                <MuiTypography
                  fontWeight={FontWeight.bold}
                  color={Color.primary}
                  fontSize={FontSize.xsmall}
                  display="inline"
                >
                  Lab services
                </MuiTypography>{' '}
                you can pay it here.{' '}
                <MuiTypography
                  style={{ textDecorationLine: 'underline', cursor: 'pointer' }}
                  fontWeight={FontWeight.bold}
                  color={Color.link}
                  fontSize={FontSize.xsmall}
                  display="inline"
                >
                  More information.
                </MuiTypography>
              </MuiTypography>
            </MuiBox>
            <Icon name="chevron-right" size={38} color={Color.black} />
          </MuiBox>
        </CursorMuiBox>
      );
    }
    if (showEntripayExperiment) {
      VariantB = () => (
        <CursorMuiBox
          bgcolor={Color.infoTint}
          p={Spacing.xSmall}
          textAlign="center"
          onClick={handleEntripayPress}
        >
          <MuiTypography
            style={{ textDecorationLine: 'underline' }}
            fontWeight={FontWeight.bold}
            color={Color.primary}
          >
            Visit Intermountainbillpay.com
          </MuiTypography>
        </CursorMuiBox>
      );
    }
  }
  return { VariantA, VariantB };
};

export default useBillingBannerComponents;
