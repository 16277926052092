import DisclaimerBanner from 'components/common/DisclaimerBanner/DisclaimerBanner';
import DeductibleBar from 'components/cost/DeductibleBar/DeductibleBar';
import Avatar from 'components/UI/Avatar/Avatar';
import Spinner from 'components/UI/Spinner/Spinner';
import get from 'lodash/get';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import analyticsService, { AnalyticsEvent, AnalyticsUserStat } from 'services/AnalyticsService';
import formatMoney from 'services/formatMoney';
import { Color } from 'modules/styles/colors';
import { FontWeight } from 'modules/styles/variables.web';
import { formatPhone } from 'services/formatPhone';
import {
  currentLocationPathNameSelector,
  previousLocationPathNameSelector
} from 'store/router/selectors';
import { currentAccountConsumerFullNameSelector } from 'store/account/selectors';
import * as bookingTypes from 'store/booking/types';
import * as bookingActions from 'store/booking/actions';
import * as bookingSelectors from 'store/booking/selectors';
import { getPatientCost, getProviderDetailsForCost, getVisitTypeID } from 'store/cost/actions';
import * as costSelectors from 'store/cost/selectors';
import { PatientCostObject, PlanBenefits, SelectedTopicResult } from 'store/cost/types';
import { ProviderDetail } from 'store/findProvider/types';
import { RootState } from 'store/types';
import {
  MuiBox,
  MuiButton,
  MuiContainer,
  MuiGrid,
  MuiPaper,
  MuiTypography
} from 'theme/material-ui';
import { oc } from 'ts-optchain';
import Screen from 'components/UI/Layout/Screen';

interface CostDetailsScreenProps extends RouteComponentProps {
  visitTypeIds: string[];
  myBenefits: PlanBenefits;
  familyBenefits: PlanBenefits;
  currentUserFullName: string;
  dispatch: Function;
  visitTypeBookable: boolean;
  providerBookable: boolean;
  providerDetailsForBooking: ProviderDetail;
  patientCost: PatientCostObject;
  selectedResult: SelectedTopicResult;
  patients: bookingTypes.Patient[];
  currentUrl?: string;
  referringUrl?: string;
}

const SpinnerBox = () => (
  <MuiGrid container>
    <MuiBox mt={1}>
      <Spinner />
    </MuiBox>
  </MuiGrid>
);

function CostDetailsScreen({
  history,
  visitTypeIds,
  myBenefits,
  familyBenefits,
  currentUserFullName,
  dispatch,
  visitTypeBookable,
  providerBookable,
  providerDetailsForBooking,
  patientCost,
  selectedResult,
  patients,
  currentUrl,
  referringUrl
}: CostDetailsScreenProps) {
  const params = oc(history).location.state({});
  const { provider } = params;
  const providerDetails = oc(provider).provider({});
  const costDisclaimerBannerText = 'Below is only an estimate';
  const showBookAppointmentButton = visitTypeBookable && providerBookable;

  React.useEffect(() => {
    const id = selectedResult?.id || selectedResult?.provider_service_grp_id;
    const providerCriteria = { idType: 'npi', id: providerDetails?.npi };
    dispatch(getVisitTypeID(id));
    dispatch(getProviderDetailsForCost(providerCriteria));
    dispatch(getPatientCost(provider?.id));

    analyticsService.incrementUserStat(AnalyticsUserStat.CostEstimatesCompleted);
    analyticsService.logEvent(AnalyticsEvent.CostEstimatorCompleted, { currentUrl, referringUrl });
  }, []);

  const handleBookButtonPress = async () => {
    const object = {
      visitTypeIds,
      provider,
      providerDetailsForBooking
    };

    await dispatch(bookingActions.startBookingFromCostEstimator(object));

    analyticsService.logEvent(AnalyticsEvent.BookAppointmentStarted, {
      currentUrl,
      referringUrl
    });

    if (patients.length === 1) {
      bookingActions.setPatient(patients[0]);
      history.push('/u/get-care-now/booking/visit-type-select');
    } else {
      history.push('/u/get-care-now/booking/patient-select');
    }
  };

  const handleClickOnProvider = () => {
    // TODO: link to provider details once page has been built
    // history.push('ProviderDetails', {
    //   criteria: { id: prov.npi, idType: 'npi' },
    //   source: 'Cost Estimation'
    // });
  };

  const getFormattedCost = (cost: PatientCostObject, costType: string) => {
    if (!cost) return '...';
    if (cost && cost.error) return 'Cost Unavailable';

    return `$${formatMoney(get(cost, `${costType}`, 0), 0)}`;
  };

  const DeductibleChart = ({ type }: { type: 'deductible' | 'oop' }) => {
    if (myBenefits && currentUserFullName && patientCost) {
      switch (type) {
        case 'deductible':
          return (
            <>
              <DeductibleBar
                info={myBenefits}
                name={currentUserFullName}
                appliedSpend={patientCost.member_cost}
                type="deductible"
              />
              {familyBenefits && (
                <DeductibleBar
                  info={familyBenefits}
                  appliedSpend={patientCost.member_cost}
                  type="deductible"
                />
              )}
            </>
          );
        case 'oop':
          return (
            <>
              <DeductibleBar
                info={myBenefits}
                name={currentUserFullName}
                appliedSpend={patientCost.member_cost}
                type="max_oop"
              />
              {familyBenefits && (
                <DeductibleBar
                  info={familyBenefits}
                  appliedSpend={patientCost.member_cost}
                  type="max_oop"
                />
              )}
            </>
          );
        default:
          return null;
      }
    }

    return <SpinnerBox />;
  };

  const formatAddress = location => {
    if (!location) return '';

    const { address1 = '', address2, city = '', state = '', zipcode = '' } = location;
    const address = `${address1}${address2 ? `, ${address2}` : ''}`;

    return `${address}, ${city}, ${state} ${zipcode}`;
  };

  return (
    <Screen>
      <DisclaimerBanner
        data-testid="cost-details-disclaimer-text"
        text={costDisclaimerBannerText}
      />
      <MuiContainer>
        <MuiBox my={3}>
          <MuiPaper>
            <MuiBox p={2}>
              <MuiGrid
                container
                spacing={2}
                alignItems="center"
                onClick={() => handleClickOnProvider(providerDetails)}
              >
                <MuiGrid item>
                  <Avatar size="large" imageUrl={providerDetails?.imageUrl} />
                </MuiGrid>
                <MuiGrid item>
                  <MuiTypography
                    variant="h4"
                    component="h2"
                    fontWeight={FontWeight.semibold}
                    data-testid="provider-name"
                  >
                    {providerDetails.name}
                  </MuiTypography>
                  <MuiTypography variant="subtitle1" data-testid="provider-location">
                    {formatAddress(providerDetails?.serviceLocation)}
                  </MuiTypography>
                </MuiGrid>
              </MuiGrid>
              <MuiBox my={2}>
                <MuiGrid container spacing={1} direction="column">
                  <MuiGrid item>
                    <MuiTypography variant="subtitle2">Encounter Type:</MuiTypography>
                    <MuiTypography variant="h6" data-testid="service-name">
                      {selectedResult?.name}
                    </MuiTypography>
                  </MuiGrid>
                  <MuiGrid container item alignItems="center">
                    <MuiTypography data-testid="estimated-out-of-pocket-cost" variant="subtitle2">
                      Estimated out-of-pocket cost:
                    </MuiTypography>
                    <MuiBox ml={1}>
                      <MuiTypography variant="h6" color={Color.primary}>
                        {getFormattedCost(patientCost, 'member_cost')}
                      </MuiTypography>
                    </MuiBox>
                    <MuiGrid item xs={12}>
                      <MuiTypography variant="subtitle1">
                        Cost may vary based on visit type and provider location
                      </MuiTypography>
                    </MuiGrid>
                  </MuiGrid>
                </MuiGrid>
              </MuiBox>
              <MuiGrid container spacing={1}>
                {showBookAppointmentButton && (
                  <MuiGrid item>
                    <MuiButton
                      variant="outlined"
                      size="large"
                      color="primary"
                      onClick={() => handleBookButtonPress(provider)}
                    >
                      Book this appointment
                    </MuiButton>
                  </MuiGrid>
                )}
                {providerDetails.phoneNumber ? (
                  <MuiGrid item>
                    Call to make an appointment: {formatPhone(providerDetails.phoneNumber)}
                  </MuiGrid>
                ) : null}
              </MuiGrid>
            </MuiBox>
          </MuiPaper>
          <MuiBox my={2}>
            <MuiPaper>
              <MuiBox data-testid="in-network-cost-details" p={2}>
                <MuiBox mb={1}>
                  <MuiTypography variant="h5">In-Network Cost Details</MuiTypography>
                </MuiBox>
                <MuiGrid container direction="column">
                  <MuiGrid item>
                    <MuiTypography variant="subtitle2">
                      Total Cost: {getFormattedCost(patientCost, 'expected_cost')}
                    </MuiTypography>
                  </MuiGrid>
                  <MuiGrid item>
                    <MuiTypography variant="subtitle2">
                      Insurance Pays: {getFormattedCost(patientCost, 'plan_cost')}
                    </MuiTypography>
                  </MuiGrid>
                  <MuiGrid item>
                    <MuiTypography variant="subtitle2">
                      Estimated out-pocket-cost: {getFormattedCost(patientCost, 'member_cost')}
                    </MuiTypography>
                  </MuiGrid>
                </MuiGrid>
                <MuiBox mt={3}>
                  <MuiGrid container justify="space-between" spacing={2}>
                    <MuiGrid item xs={12} lg={6}>
                      <MuiTypography variant="h5">Deductible Overview</MuiTypography>
                      <DeductibleChart type="deductible" />
                    </MuiGrid>
                    <MuiGrid item xs={12} lg={6}>
                      <MuiTypography variant="h5">Out-of-pocket Overview</MuiTypography>
                      <DeductibleChart type="oop" />
                    </MuiGrid>
                  </MuiGrid>
                </MuiBox>
              </MuiBox>
            </MuiPaper>
          </MuiBox>
        </MuiBox>
      </MuiContainer>
    </Screen>
  );
}

const mapStateToProps = (state: RootState) => ({
  selectedResult: costSelectors.selectedResultSelector(state),
  visitTypeIds: costSelectors.costVisitTypeID(state),
  visitTypeBookable: costSelectors.visitTypeBookableSelector(state),
  planBenefits: costSelectors.planBenefitsSelector(state),
  myBenefits: costSelectors.myPlanBenefitsSelector(state),
  familyBenefits: costSelectors.familyPlanBenefitsSelector(state),
  currentUserFullName: currentAccountConsumerFullNameSelector(state),
  providerBookable: costSelectors.providerBookableSelector(state),
  providerDetailsForBooking: costSelectors.providerDetailSelector(state),
  patientCost: costSelectors.patientCostSelector(state),
  patients: bookingSelectors.patientsDataSelector(state),
  currentUrl: currentLocationPathNameSelector(state),
  referringUrl: previousLocationPathNameSelector(state)
});

export default connect(mapStateToProps)(CostDetailsScreen);
