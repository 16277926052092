import React from 'react';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import { MuiTypography } from 'theme/material-ui';
import Spacer from 'components/UI/Layout/Spacer';
import { StyledAsterisk } from 'components/RequiredFieldsLegend';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { FontWeight } from 'modules/styles/variables';
import { convertToLowerKabobCase } from 'modules/utils/StringUtils';
import { FlatList } from 'react-native';
import { COVID_CONSENT_CHECKBOX } from 'lib/covidScreening/types';
import { useCovidAgreementGuest } from '../../hooks';
import { KeyFormField } from '../../hooks/useCovidAgreementGuest';
import { useLanguageSwitcher } from 'lib/hooks/useLanguageSwitcher';
import { translations } from 'lib/constants/translations/screens/covidGuest/agreement';

const completeLabel = (preLabel: string | undefined, label: string) => (
  <>
    <MuiTypography
      display="inline"
      fontWeight={FontWeight.bold}
      style={{ textDecorationLine: 'underline' }}
    >
      {preLabel}
    </MuiTypography>
    <MuiTypography display="inline">
      {label}
      <StyledAsterisk />
    </MuiTypography>
  </>
);

interface FlatListItemProps {
  checked: boolean;
  fieldName: string;
  preLabel: string | undefined;
  label: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const FlatListItem = (props: FlatListItemProps) => (
  <Box py={0.2} ml={0.5}>
    <FormControlLabel
      control={
        <Checkbox
          checked={props.checked}
          color="primary"
          data-testid={convertToLowerKabobCase(props.fieldName, '-form-checkbox')}
          onChange={props.onChange}
          style={{ alignSelf: 'start', paddingTop: '0px', paddingBottom: '0px' }}
          value={props.fieldName}
        />
      }
      label={completeLabel(props.preLabel, props.label)}
    />
  </Box>
);

export default function CheckboxStatements() {
  const { requiredField, updateRequiredFields } = useCovidAgreementGuest();
  const screenText = useLanguageSwitcher(translations);

  const onChangeCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    updateRequiredFields(value as KeyFormField);
  };

  return (
    <FlatList
      data={COVID_CONSENT_CHECKBOX}
      ItemSeparatorComponent={() => <Spacer size="medium" />}
      keyExtractor={item => `${item.key}`}
      renderItem={({ item }) => (
        <FlatListItem
          checked={requiredField[item.fieldName]}
          fieldName={item.fieldName}
          preLabel={screenText[`${item.fieldName}_preLabel`]}
          label={screenText[item.fieldName]}
          onChange={onChangeCheckbox}
        />
      )}
    />
  );
}
