import CollapsibleListItem from 'components/common/CollapsibleList/CollapsibleListItem';
import Divider from 'components/UI/Divider';
import Box from 'components/UI/Layout/Box';
import BinaryDocumentHandler from 'components/VisitSummaries/BinaryDocumentHandler';
import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'store/types';
import {
  visitSummaryDocumentDataSelector,
  visitSummaryDocumentErrorSelector,
  visitSummaryDocumentLoadingSelector
} from 'store/visitSummary/selectors';
import {
  Document,
  DocumentCategoryType,
  InpatientDocumentInfoItem,
  ResourceDocument,
  VisitSummaryDetails,
  WeDiscussedDiagnosis,
  WeDiscussedItem
} from 'store/visitSummary/types';
import { MuiBox, MuiDivider, MuiGrid, MuiPaper } from 'theme/material-ui';
import { oc } from 'ts-optchain';

type Visit = VisitSummaryDetails;

interface WeDiscussedProps {
  visit: Visit;
}

/**
 *
 * Identifies only the successfully parsed hospitalCourse documents (discharge summary)
 */
const getInpatientParsedDocumentTextArray = (infoItems: InpatientDocumentInfoItem[]) => {
  const successArray = infoItems.reduce((acc, item) => {
    if (oc(item).sections.hospitalCourse()) {
      return acc.concat(item);
    }

    return acc;
  }, [] as InpatientDocumentInfoItem[]);

  return successArray;
};

interface InpatientProps {
  infoItems: InpatientDocumentInfoItem[];
}

/**
 *
 * Parsed documents will potentially contain HTML tables
 * react-native-render-html is used to safely render HTML strings or plain text
 */
const WeDiscussedInpatientList = ({ infoItems }: InpatientProps) => {
  const hospitalCourses = getInpatientParsedDocumentTextArray(infoItems);

  if (hospitalCourses.length > 0) {
    return (
      <MuiBox p={3}>
        {hospitalCourses.map((item: InpatientDocumentInfoItem) => (
          <Box key={item.id}>{item.sections.hospitalCourse}</Box>
        ))}
      </MuiBox>
    );
  }

  return <Box />;
};

export const bullet = '\u2022';
const WeDiscussedDiagnosisList = ({ data }: { data: WeDiscussedDiagnosis[] }) => {
  const title = data.length > 1 ? 'Diagnoses' : 'Diagnosis';
  return (
    <>
      <MuiGrid item xs={4}>
        <MuiBox textAlign="left" p={3}>
          {title}
        </MuiBox>
      </MuiGrid>
      <MuiGrid item xs={8}>
        {data.map(diagnosis => (
          <MuiBox p={3} key={diagnosis.id}>
            {bullet} {diagnosis.name}
          </MuiBox>
        ))}
      </MuiGrid>
      <MuiDivider variant="fullWidth" style={{ width: '100%' }} />
    </>
  );
};

export const WeDiscussedSectionMap = {
  AssessmentAndPlan: {
    key: 'assessmentAndPlan',
    title: 'Assessment and Plan'
  },

  HistoryOfPresentIllness: {
    key: 'historyOfPresentIllness',
    display: 'History of Present Illness'
  },

  Notes: {
    key: 'notes',
    display: 'Notes'
  }
} as const;

export const RenderWeDiscussedVisitItem = ({ item }: { item: WeDiscussedItem }) => {
  if (item.status !== 'SUCCESS') return null;
  const sectionsDisplay = [];
  const { sections = {} } = item;

  if (sections[WeDiscussedSectionMap.AssessmentAndPlan.key]) {
    sectionsDisplay.push({
      title: WeDiscussedSectionMap.AssessmentAndPlan.title,
      value: sections[WeDiscussedSectionMap.AssessmentAndPlan.key]
    });
  }

  if (sections[WeDiscussedSectionMap.HistoryOfPresentIllness.key]) {
    sectionsDisplay.push({
      title: WeDiscussedSectionMap.HistoryOfPresentIllness.display,
      value: sections[WeDiscussedSectionMap.HistoryOfPresentIllness.key]
    });
  }

  if (sections[WeDiscussedSectionMap.Notes.key]) {
    sectionsDisplay.push({
      title: WeDiscussedSectionMap.Notes.display,
      value: sections[WeDiscussedSectionMap.Notes.key]
    });
  }

  return (
    <>
      {sectionsDisplay.map(section => (
        <React.Fragment key={section.value}>
          <MuiGrid item xs={4}>
            <MuiBox textAlign="left" p={3}>
              {section.title}
            </MuiBox>
          </MuiGrid>
          <MuiGrid item xs={8}>
            <MuiBox p={3} whiteSpace="pre-line">
              {section.value}
            </MuiBox>
          </MuiGrid>
          <MuiDivider variant="fullWidth" style={{ width: '100%' }} />
        </React.Fragment>
      ))}
    </>
  );
};

const WeDiscussedVisitList = ({ data }: { data: WeDiscussedItem[] }) => {
  return data.map(item => <RenderWeDiscussedVisitItem item={item} />);
};

const renderDocumentsList = (documents: VisitSummaryDetails['documents']) => {
  if (!documents) {
    return null;
  }

  const officeVisit: ResourceDocument[] = Object.values(documents).filter(
    item => item.categoryDisplay === DocumentCategoryType.OFFICE && item.documents.length > 0
  );

  if (!officeVisit.length) {
    return null;
  }

  const officeVisitDocuments: Document[] = officeVisit[0].documents;

  if (!officeVisitDocuments.length) {
    return null;
  }

  return (
    <>
      <MuiGrid item xs={4}>
        <MuiBox textAlign="left" p={3}>
          Documents
        </MuiBox>
      </MuiGrid>
      <MuiGrid item xs={8} direction="column">
        <MuiGrid container>
          {officeVisitDocuments.map((document: Document) => (
            <BinaryDocumentHandler document={document} />
          ))}
        </MuiGrid>
      </MuiGrid>
    </>
  );
};

const WeDiscussedOutpatientList = ({ visit }: WeDiscussedProps) => {
  const { diagnoses = [], weDiscussed = [], documents } = visit;

  return (
    <>
      {!!diagnoses.length && <WeDiscussedDiagnosisList data={diagnoses} />}
      {!!weDiscussed.length && <WeDiscussedVisitList data={weDiscussed} />}
      {renderDocumentsList(documents)}
    </>
  );
};

const WeDiscussedItems = ({ visit }: WeDiscussedProps) => {
  const { visitType, inpatientDocumentInfo } = visit;
  const outpatientVisit = visitType !== 'inpatient';
  return (
    <>
      {outpatientVisit ? (
        <WeDiscussedOutpatientList visit={visit} />
      ) : (
        <WeDiscussedInpatientList infoItems={inpatientDocumentInfo} />
      )}
    </>
  );
};

/**
 *
 * Discharge Summary is sourced from an iCentra document labeled Hospital Course
 */
const checkForDischargeSummary = (inpatientDocumentInfo: InpatientDocumentInfoItem[]) => {
  if (!Array.isArray(inpatientDocumentInfo)) {
    return false;
  }
  return inpatientDocumentInfo.some(info => {
    return info.sections.hospitalCourse !== null;
  });
};

const checkforWeDiscussedData = (visit: Visit) => {
  const { weDiscussed = [], diagnoses = [], visitType, inpatientDocumentInfo = [] } = visit;
  const hasWeDiscussed = weDiscussed.find(i => i.status === 'SUCCESS');
  return visitType !== 'inpatient'
    ? !!(hasWeDiscussed || diagnoses.length)
    : checkForDischargeSummary(inpatientDocumentInfo);
};

export const fallbackText = 'Details from your visit';

const WeDiscussed = (props: WeDiscussedProps) => {
  const { visit } = props;
  if (!visit) return null;

  const hasData = checkforWeDiscussedData(visit);

  return hasData ? (
    <>
      <CollapsibleListItem title="We discussed" subtitle={fallbackText}>
        <MuiBox width="100%">
          <MuiPaper elevation={2}>
            <MuiGrid container aria-label="we discussed list">
              <WeDiscussedItems visit={visit} />
            </MuiGrid>
          </MuiPaper>
        </MuiBox>
      </CollapsibleListItem>
      <Divider />
    </>
  ) : null;
};

const mapStateToProps = (state: RootState) => ({
  isFetching: visitSummaryDocumentLoadingSelector(state),
  error: visitSummaryDocumentErrorSelector(state),
  data: visitSummaryDocumentDataSelector(state)
});

export default connect(mapStateToProps)(WeDiscussed);
