import A1C from 'assets/DashboardBanner/A1C.png';
import Bill from 'assets/DashboardBanner/Bill.svg';
import BreastCancer from 'assets/DashboardBanner/BreastCancer.png';
import Cancer from 'assets/DashboardBanner/Cancer.png';
import Coagulation from 'assets/DashboardBanner/Coagulation.png';
import Covid from 'assets/DashboardBanner/Covid.png';
import FluShot from 'assets/DashboardBanner/FluShot.png';
import HPV from 'assets/DashboardBanner/HPV.png';
import Detective from 'assets/detective.png';
import DogRunning from 'assets/DashboardBanner/DogRunning.gif';
import AvatarMale from 'assets/DashboardBanner/AvatarMale.svg';
import AvatarsFemale from 'assets/DashboardBanner/AvatarsFemale.svg';
import AvatarsMale from 'assets/DashboardBanner/AvatarsMale.svg';
import Evisit from 'assets/DashboardBanner/Evisit.svg';
import Goals from 'assets/DashboardBanner/Goals.svg';
import Notifications from 'assets/DashboardBanner/Notifications.svg';
import { Color } from 'modules/styles/colors';
import { DBColors } from 'lib/dashboard/utils';

export const DashboardCards = {
  mammogram: {
    imageName: BreastCancer,
    color: Color.primary,
    background: DBColors.lightBlue
  },
  fluShot: {
    imageName: FluShot,
    color: Color.primary,
    background: DBColors.red
  },
  cervicalCancer: {
    imageName: Cancer,
    color: Color.blueCardText,
    background: DBColors.lightBlue
  },
  diabetes: {
    imageName: A1C,
    color: Color.blueCardText,
    background: DBColors.lightBlue
  },
  hpv: {
    imageName: HPV,
    color: Color.primary,
    background: DBColors.blue
  },
  antiCoagulation: {
    imageName: Coagulation,
    color: Color.blueCardText,
    background: DBColors.red
  },
  covid: {
    imageName: Covid,
    color: Color.primary,
    background: DBColors.blue
  },
  notification: {
    imageName: Notifications,
    color: Color.primary,
    background: DBColors.lightBlue
  },
  avatarsCardFemale: {
    imageName: AvatarsFemale,
    color: Color.primary,
    background: DBColors.red
  },
  avatarsCardMale: {
    imageName: AvatarsMale,
    color: Color.blueCardText,
    background: DBColors.red
  },
  evisitInProgress: {
    imageName: Evisit,
    color: Color.blueCardText,
    background: DBColors.lightRed
  },
  evisitAvailable: {
    imageName: Evisit,
    color: Color.primary,
    background: DBColors.blue
  },
  goals: {
    imageName: Goals,
    color: Color.blueCardText,
    background: DBColors.lightRed
  },
  rta: {
    imageName: Bill,
    color: Color.primary,
    background: DBColors.blue
  },
  changesToPeople: {
    imageName: AvatarMale,
    color: Color.primary,
    background: DBColors.tintBlue
  },
  error: {
    imageName: Detective,
    color: Color.blueCardText,
    background: DBColors.grey
  },
  loading: {
    imageName: DogRunning,
    color: Color.blueCardText,
    background: DBColors.grey
  }
};
