import React, { useRef } from 'react';
import { connect } from 'react-redux';

import analyticsService, { AnalyticsEvent } from 'services/AnalyticsService';

import { RootState } from 'store/types';
import * as routerSelectors from 'store/router/selectors';

import { MuiDivider, MuiBox, MuiButton } from 'theme/material-ui';
import { FilterDrawer } from 'components/FilterDrawer';

import { defaultValues } from 'lib/hooks/ProviderSearch/Filters/FiltersContext';
import { ProviderSearchFiltersForm } from 'lib/hooks/ProviderSearch/useProviderSearchFilters';
import { ProviderSearchInterface } from 'lib/hooks/ProviderSearch/useProviderSearch';
import { FiltersFormContext, FiltersKey } from 'lib/hooks/ProviderSearch/types';

import ProviderSearchFiltersContent from './ProviderSearchFiltersContent';
import { Svg } from 'components/UI/Svg';
import { Color } from 'modules/styles/colors';
import { IconSize } from 'modules/styles/variables';

export interface Props {
  // Local Props
  open: boolean;
  filtersCount: number;
  defaultExpandedFilters: FiltersKey[];
  defaultOptions: ProviderSearchInterface['options'];
  search: ProviderSearchInterface['search'];
  onClose: () => void;
  // Redux Props
  currentUrl?: string;
  referringUrl?: string;
}

export function ProviderSearchFilters({
  // Local Props
  open,
  filtersCount,
  defaultOptions,
  onClose: handleClose,
  search,
  defaultExpandedFilters,
  // Redux Props
  currentUrl,
  referringUrl
}: Props) {
  const chip = useRef(true);

  const onClose = ({ resetForm }: FiltersFormContext) => () => {
    resetForm();
    handleClose();
  };

  const onChipDelete = ({ resetForm }: FiltersFormContext) => {
    resetForm(defaultValues);
    chip.current = false;
  };

  const onClearSubmit = (options: ProviderSearchInterface['options']) => {
    analyticsService.logEvent(AnalyticsEvent.ProviderSearchResultsFiltered, {
      currentUrl,
      referringUrl
    });

    search(options);
    handleClose();
  };

  return (
    <ProviderSearchFiltersForm
      initialOptions={defaultOptions}
      onSubmit={onClearSubmit}
      onClear={onClearSubmit}
    >
      {formProps => (
        <FilterDrawer
          open={open}
          onClose={onClose(formProps)}
          onApply={formProps.onSubmit}
          onClear={formProps.onClear}
        >
          {chip.current && filtersCount ? (
            <>
              <MuiBox m={1.5}>
                <MuiButton
                  size="medium"
                  variant="outlined"
                  fullWidth
                  onClick={() => onChipDelete(formProps)}
                  color="primary"
                  endIcon={
                    <Svg
                      color={Color.black}
                      set="assets"
                      name="CloseCircleOutline"
                      size={IconSize.base}
                    />
                  }
                  style={{ width: 150 }}
                >
                  {`${filtersCount} selected`}
                </MuiButton>
              </MuiBox>
              <MuiDivider />
            </>
          ) : null}
          <ProviderSearchFiltersContent defaultExpandedKeys={defaultExpandedFilters} />
        </FilterDrawer>
      )}
    </ProviderSearchFiltersForm>
  );
}

const mapStateToProps = (state: RootState) => ({
  currentUrl: routerSelectors.currentLocationPathNameSelector(state),
  referringUrl: routerSelectors.previousLocationPathNameSelector(state)
});

export default connect(mapStateToProps)(ProviderSearchFilters);
