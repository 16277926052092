import React from 'react';
import { Color } from 'modules/styles/colors';
import CollapsibleList from 'components/common/CollapsibleList/CollapsibleList';
import CollapsibleListHeader from 'components/common/CollapsibleList/CollapsibleListHeader';
import { EmptyFlexBox } from '../styled';
import DefaultText from 'components/UI/Typography/DefaultText';
import formatMoney from 'services/formatMoney';
import ClaimItem from './ClaimItem';
import { Claims } from 'store/billing/types';
import { ClaimsGroupProps } from './types';
import analyticsService, { AnalyticsEvent } from 'services/AnalyticsService';
import { connect } from 'react-redux';
import { RootState } from 'store/types';
import {
  currentLocationPathNameSelector,
  previousLocationPathNameSelector
} from 'store/router/selectors';

interface Props extends ClaimsGroupProps {
  currentUrl?: string;
  referringUrl?: string;
}

export function ClaimsGroup(props: Props) {
  const { claims, currentUrl, referringUrl } = props;

  // Create the charges display array
  const planCovered = claims.reduce(
    (val, claim) => val + Math.abs(parseFloat(claim.sumOfClaimPlanPaid)),
    0
  );
  return (
    <CollapsibleList
      data-testid="view-claims-group"
      accessibilityLabel="View claims"
      key="View claims"
    >
      <CollapsibleListHeader
        title="View claims"
        subtitle={planCovered ? `Plan Covered: $${formatMoney(planCovered)}` : ''}
        onOpened={() => {
          analyticsService.logEvent(AnalyticsEvent.ViewClaimsClicked, {
            currentUrl,
            referringUrl
          });
        }}
        headerColor={Color.offBlack}
        headerWeight="700"
      >
        {claims.length === 0 ? (
          <EmptyFlexBox>
            <DefaultText data-testid="ChargeGroup_EmptyArray">No Claims Available</DefaultText>
          </EmptyFlexBox>
        ) : (
          claims.map((eachClaim: Claims) => (
            <ClaimItem
              data-testid="view-claims-item"
              key={`claim-${eachClaim.id}`}
              {...eachClaim}
            />
          ))
        )}
      </CollapsibleListHeader>
    </CollapsibleList>
  );
}

const mapState = (state: RootState) => ({
  currentUrl: currentLocationPathNameSelector(state),
  referringUrl: previousLocationPathNameSelector(state)
});

export default connect(mapState)(ClaimsGroup);
