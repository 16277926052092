import { useEffect, useState } from 'react';
import { CovidScreeningQuestionnaireSelector } from 'store/CovidScreening/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { setQuestionnaire } from 'store/CovidScreening/actions';
import { Questionnaire } from 'store/CovidScreening/types';
import { CovidReasonsBooleanEnums } from 'lib/covidScreening/types';
import { AnalyticsEvent } from 'services/AnalyticsService';
import useNavigationAnalytics from 'hooks/useNavigationAnalytics';
import { CovidTitles } from 'lib/constants/covidScreening';
import { AuthType } from 'store/authentication/types';

export type ReasonFieldsChecked = { covidReasonsChecked: CovidReasonsBooleanEnums[] };

function getReasonsObject(questionnaire: Questionnaire): ReasonFieldsChecked {
  const reasonsData: { [key in CovidReasonsBooleanEnums]: string | undefined } = {
    closeContact: questionnaire.Q_COVID_Screen_Close_Contact,
    covidSymptoms: questionnaire.Q_Covid_Symptoms_Primary,
    highDensityResidence: questionnaire.Q_COVID_Screen_High_Density_Residence,
    schoolRelated: questionnaire.Q_COVID_Healthcare_Worker_HD_residence,
    travelRelated: questionnaire.Q_Covid19_Prescreening_Travel,
    workRelated: questionnaire.Q_Covid19_Prescreening_Work
  };

  const filteredReasons = Object.entries(reasonsData)
    .filter(([, value]) => value === 'Yes')
    .map(([key]) => key);

  return { covidReasonsChecked: (filteredReasons as CovidReasonsBooleanEnums[]) || [] };
}

function getSymptomsQuestionnaireFormat(reasonsData: ReasonFieldsChecked) {
  const {
    closeContact,
    covidSymptoms,
    highDensityResidence,
    schoolRelated,
    travelRelated,
    workRelated
  } = CovidReasonsBooleanEnums;

  const { covidReasonsChecked } = reasonsData;

  const symptomsQuestionnaire = {
    Q_Covid_Symptoms_Primary: covidReasonsChecked.includes(covidSymptoms) ? 'Yes' : 'No',
    Q_COVID_Screen_Close_Contact: covidReasonsChecked.includes(closeContact) ? 'Yes' : 'No',
    Q_COVID_Healthcare_Worker_HD_residence: covidReasonsChecked.includes(schoolRelated)
      ? 'Yes'
      : 'No',
    Q_Covid19_Prescreening: 'No',
    Q_Covid19_Prescreening_Travel: covidReasonsChecked.includes(travelRelated) ? 'Yes' : 'No',
    Q_Covid19_Prescreening_Work: covidReasonsChecked.includes(workRelated) ? 'Yes' : 'No',
    Q_COVID_Screen_High_Density_Residence: covidReasonsChecked.includes(highDensityResidence)
      ? 'Yes'
      : 'No'
  };

  if (
    symptomsQuestionnaire.Q_Covid19_Prescreening_Travel === 'Yes' ||
    symptomsQuestionnaire.Q_Covid19_Prescreening_Work === 'Yes'
  ) {
    symptomsQuestionnaire.Q_Covid19_Prescreening = 'Yes';
  } else {
    symptomsQuestionnaire.Q_Covid19_Prescreening = 'No';
  }

  return symptomsQuestionnaire;
}

export default function useCovidReasonsGuest() {
  const dispatch = useDispatch();
  const questionnaire = useSelector(CovidScreeningQuestionnaireSelector);
  const covidReasonsNextBtn = useNavigationAnalytics(AnalyticsEvent.CovidNext);
  const [requiredFields, setRequiredFields] = useState<ReasonFieldsChecked>(
    getReasonsObject(questionnaire)
  );

  useEffect(() => {
    setRequiredFields(getReasonsObject(questionnaire));
  }, [questionnaire]);

  const saveQuestionnaireFormat = (reasonsData: ReasonFieldsChecked) => {
    const questionnaireData = {
      ...questionnaire,
      ...getSymptomsQuestionnaireFormat(reasonsData)
    };

    dispatch(setQuestionnaire(questionnaireData));
  };

  const updateRequiredFields = (reason: CovidReasonsBooleanEnums) => {
    setRequiredFields(prevValue => {
      const covidReasonsChecked = prevValue.covidReasonsChecked.filter(
        currentReason => currentReason !== reason
      );

      if (prevValue.covidReasonsChecked.length === covidReasonsChecked.length)
        covidReasonsChecked.push(reason);

      const newRequiredFields = { covidReasonsChecked };

      saveQuestionnaireFormat(newRequiredFields);

      return newRequiredFields;
    });
  };

  const confirmReasonsGuest = () => {
    saveQuestionnaireFormat(requiredFields);

    covidReasonsNextBtn({
      ...getSymptomsQuestionnaireFormat(requiredFields),
      title: CovidTitles.REASON_FOR_REQUEST,
      authentication_type: AuthType.UNAUTHENTICATED
    });
  };

  return {
    confirmReasonsGuest,
    requiredFields,
    updateRequiredFields
  };
}
