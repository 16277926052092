import React, { useEffect } from 'react';
import { oc } from 'ts-optchain';
import { RootState } from 'store/types';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import Encryption from 'services/Encryption';
import analyticsService, { AmplitudeEventData, AnalyticsEvent } from 'services/AnalyticsService';
import * as testResults from 'store/testResults/selectors';
import * as appSettingsSelectors from 'store/appSettings/selectors';
import * as routeSelectors from 'store/router/selectors';
import { TestResultDetails, TestResultFocused, MicrobiologyDetails } from 'store/testResults/types';
import Typography from 'components/UI/Typography';
import { Color } from 'modules/styles/colors';
import { FontSize, FontWeight } from 'modules/styles/variables';
import FlexBox from 'components/UI/Layout/FlexBox';
import Spinner from 'components/UI/Spinner/Spinner';
import { MuiBox, MuiButton, MuiPaper, MuiTypography } from 'theme/material-ui';
import { orNA } from '../Lab/utils';

export interface Props {
  loading: boolean;
  error: Error | null;
  data: TestResultDetails | null;
  testResultFocused: TestResultFocused;
  currentUrl?: string;
  referringUrl?: string;
  eventId?: string;
  encryptionSettings: {
    password: string;
    algorithm: string;
  };
}

// SECTION
export const Section = ({ title, content }: { title: string; content: string[] }) => {
  const renderContent = () => {
    if (content.length === 0) {
      return (
        <MuiBox p={3}>
          <Typography>No result found</Typography>
        </MuiBox>
      );
    }

    return content.map((item: string) => (
      <MuiBox p={3} key={item}>
        <Typography>{orNA(item)}</Typography>
      </MuiBox>
    ));
  };

  return (
    <>
      <MuiBox p={3} bgcolor={Color.cobaltTint}>
        <Typography fontSize={FontSize.base} fontWeight={FontWeight.bold}>
          {title}
        </Typography>
      </MuiBox>

      {renderContent()}
    </>
  );
};

// LOADING
export const Loading = () => (
  <FlexBox
    flex={1}
    alignItems="center"
    justifyContent="center"
    accessibilityLabel="microbiology loading"
  >
    <Spinner />
  </FlexBox>
);

// ERROR
export const Error = ({ message }: { message: string }) => (
  <FlexBox flex={1} alignItems="center" justifyContent="center">
    <Typography>{message}</Typography>
  </FlexBox>
);

// MAIN COMPONENT
export const Microbiology = (props: Props) => {
  const {
    loading,
    error,
    data,
    testResultFocused,
    encryptionSettings,
    referringUrl,
    currentUrl,
    eventId
  } = props;
  const history = useHistory();

  const content = oc(data).content(undefined) as MicrobiologyDetails;
  const resultList = oc(content).resultList([]);
  const stainResult = oc(content).stainResult('');

  const eventData: AmplitudeEventData = {
    // we can not pass name to Amplitude as it is PHI
    test_name: Encryption.encrypt(
      encryptionSettings.algorithm,
      encryptionSettings.password,
      testResultFocused.name
    ),
    test_type: testResultFocused.category,
    referringUrl,
    currentUrl,
    eventId
  };

  useEffect(() => {
    if (data) {
      const detailsViewedEventData: AmplitudeEventData = {
        ...eventData,
        test_result_displayed: 'Success'
      };
      analyticsService.logEvent(AnalyticsEvent.TestResultsDetailsViewed, detailsViewedEventData);
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      const detailsViewedEventData: AmplitudeEventData = {
        ...eventData,
        test_result_displayed: 'Error',
        test_result_error: error
      };
      analyticsService.logEvent(AnalyticsEvent.TestResultsDetailsViewed, detailsViewedEventData);
    }
  }, [error]);

  const handleShowHistoricalDate = () => {
    history.push('/u/health-record/microbiology-history', {
      source: testResultFocused.source,
      name: testResultFocused.name,
      nameNcid: testResultFocused.nameNcid,
      adapterSource: testResultFocused.adapterSource
    });
  };

  // *******************************
  // ** LOADING | ERROR | NO DATA **
  // *******************************

  if (loading) return <Loading />;
  if (error) return <Error message="An error occurred retrieving Microbiology details" />;
  if (!data) return <Error message="No data available" />;

  return (
    <>
      <MuiBox bgcolor={Color.baseColor} component={MuiPaper} elevation={2} mb={10}>
        <MuiBox p={3}>
          <Section title="Result List" content={resultList} />
          <Section title="Stain Result" content={stainResult ? [stainResult] : []} />
        </MuiBox>
      </MuiBox>
      <MuiBox display="flex" justifyContent="center" mb={10}>
        <MuiButton fullWidth onClick={handleShowHistoricalDate} variant="contained" color="primary">
          <MuiBox p={3}>
            <MuiTypography>View historical</MuiTypography>
          </MuiBox>
        </MuiButton>
      </MuiBox>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  loading: testResults.testResultDetailsLoadingSelector(state),
  error: testResults.testResultDetailsErrorSelector(state),
  data: testResults.testResultDetailsDataSelector(state),
  currentUrl: routeSelectors.currentLocationPathNameSelector(state),
  referringUrl: routeSelectors.previousLocationPathNameSelector(state),
  encryptionSettings: appSettingsSelectors.encryptionSettingsSelector(state)
});

export default connect(mapStateToProps)(Microbiology);
