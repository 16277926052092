import styled from 'styled-components';

import FlexBox from 'components/UI/Layout/FlexBox';
import Subhead from 'components/UI/Typography/Subhead';
import Headline from 'components/UI/Typography/Headline';
import DefaultText from 'components/UI/Typography/DefaultText';

import { Color } from 'modules/styles/colors';
import {
  baseFontSize,
  largeFontSize,
  Spacing,
  BorderRadius,
  FontWeight,
  FontSize
} from 'modules/styles/variables';

export const ActionsHeaderBox = styled(FlexBox)`
  width: 100%;
  background-color: ${Color.white};
`;

export const ActionItem = styled.div`
  background-color: ${Color.grayHue4};
  border-radius: ${BorderRadius.base};
  margin: ${Spacing.small}px ${Spacing.small}px 0;
  min-width: 54px;
  min-height: 48px;
  justify-content: center;
  align-items: center;
`;

export const StyledHeadline = styled(Headline)`
  margin-left: 5px;
  padding-right: 10px;
  font-size: ${FontSize.mediumHeading}px;
  line-height: ${largeFontSize * 2};
  font-weight: ${FontWeight.bold};
`;

export const StyledSubhead = styled(Subhead)`
  font-size: ${largeFontSize};
  line-height: 24;
`;

export const StyledDefaultText = styled(DefaultText)`
  font-size: ${baseFontSize};
`;
export const StyledHeader = styled(DefaultText)`
  font-size: ${props => (props.fontSize ? props.fontSize : FontSize.large)}px;
  font-weight: ${FontWeight.bold};
`;
