import { MuiButton } from 'theme/material-ui';
import styled from 'styled-components';
import Screen from 'components/UI/Layout/Screen';
import { Color } from 'modules/styles/colors';

export const StyledScreen = styled(Screen)`
  height: 100%;
  overflow: auto;
`;

export const StyledMuiButton = styled(MuiButton)`
  &.MuiButton-root {
    min-width: 250px;
    color: ${Color.secondary};
    border: 1px solid ${Color.secondary};
  }
  &&.MuiButton-contained {
    background-color: ${Color.secondary};
    color: ${Color.white};
  }
`;
