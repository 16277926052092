import Screen from 'components/UI/Layout/Screen';
import { Color } from 'modules/styles/colors';
import { Coordinate } from 'modules/types/common';
import React, { useEffect } from 'react';
import Config from 'react-native-config';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import * as geoLocationActions from 'store/geolocation/actions';
import { geolocationCoordsSelector, geolocationDeniedSelector } from 'store/geolocation/selectors';
import { RootDispatch, RootState } from 'store/types';
import { MuiBox, MuiContainer } from 'theme/material-ui';
import GetCareAppointmentsList from './Appointments/List';
import GetCareCheckSymptomsList from './CheckSymptoms/List';
import GetCareCostEstimatorList from './CostEstimator/List';
import GetCareCovidTest from './CovidTest/List';
import GetCareNowList from './GetCareNow/List';
import GetCareMyDoctorsList from './MyDoctors/List';

interface GetCareHomeProps extends RouteComponentProps {
  dispatch: RootDispatch;
  geolocationCoords: Coordinate;
  geolocationDenied: boolean;
}

export const GetCareHome = ({
  dispatch,
  geolocationCoords,
  geolocationDenied
}: GetCareHomeProps) => {
  useEffect(() => {
    if (!geolocationDenied && !geolocationCoords) {
      dispatch(geoLocationActions.getCurrentPosition());
    }
  }, []);

  return (
    <Screen style={{ backgroundColor: Color.baseColor }}>
      <MuiBox my={5}>
        <MuiContainer maxWidth="lg">
          <GetCareAppointmentsList />
          <GetCareNowList />
          {Config.COVID_SCREENING_FEATURE_FLAG === 'enabled' ? <GetCareCovidTest /> : null}
          <GetCareCheckSymptomsList />
          <GetCareCostEstimatorList />
          <GetCareMyDoctorsList />
        </MuiContainer>
      </MuiBox>
    </Screen>
  );
};

const mapStateToProps = (state: RootState) => ({
  geolocationCoords: geolocationCoordsSelector(state),
  geolocationDenied: geolocationDeniedSelector(state)
});

export default connect(mapStateToProps)(GetCareHome);
