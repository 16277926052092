/* eslint-disable react/no-array-index-key */
import React from 'react';
import { MuiTypography, MuiListItem } from 'theme/material-ui';
import { FlexBoxColumn, FlexBoxRow } from '../UI/Layout/FlexBox';
import { Spacing, FontSize, FontWeight, IconSize } from 'modules/styles/variables';
import { Color } from 'modules/styles/colors';
import FlexBox from 'components/UI/Layout/FlexBox';
import Svg from '../UI/Svg/Svg';
import { DateStr, formatShortDate } from 'modules/utils/DateUtils';
import {
  unCamelCase,
  convertToLowerKabobCase,
  cleanSpecialCharacters
} from 'modules/utils/StringUtils';
import { GlobalSearchItem } from 'store/global/types';

interface SearchItemProps {
  item: GlobalSearchItem;
  searchValue: string;
  onPress: any;
}

const getSubtitleByCategory = (category?: string, item?: GlobalSearchItem) => {
  switch (category) {
    case 'TestResults':
      return item?.provider?.length ? `Provider: ${item?.provider[0]}` : '';
    case 'Vitals':
      return item?.data?.valueUnits
        ? `${item?.data?.valueUnits?.[0]?.value} ${item?.data?.valueUnits?.[0]?.units}`
        : '';
    default:
      return item?.provider ? `Provider: ${item?.provider}` : '';
  }
};

const textWithHighlight = (text: string, searchValue: string, props: any) => {
  const lowercaseValue = searchValue.toLowerCase();
  if (text?.toLowerCase().includes(lowercaseValue)) {
    const finalText: JSX.Element[] = [];
    const srchVal = cleanSpecialCharacters(lowercaseValue);
    const re = new RegExp(`(${srchVal})`, 'ig');
    const noHighlightedText = text.split(re);

    noHighlightedText.forEach((element, i) => {
      finalText.push(
        <MuiTypography
          key={`${i}-${element}`}
          component="span"
          style={{ background: i === 1 ? 'yellow' : 'none' }}
          {...props}
        >
          {element}
        </MuiTypography>
      );
    });

    return finalText;
  }

  return (
    <MuiTypography component="span" {...props}>
      {text}
    </MuiTypography>
  );
};

const SearchItem = ({ item, onPress, searchValue }: SearchItemProps) => {
  if (!item) return null;
  const { title, category, date, icon, iconProps = {} } = item;
  return (
    <MuiListItem
      data-testid={`option-${convertToLowerKabobCase(item?.title)}`}
      key={convertToLowerKabobCase(item?.title)}
      button
      divider
      disableGutters
      onClick={() => onPress(item)}
    >
      <FlexBoxRow width="100%" alignItems="center" hSpacing={Spacing.small}>
        <FlexBoxColumn alignItems="center" width="34px" height="34px">
          <Svg name={icon} size={IconSize.base} fill={Color.primary} {...iconProps} />
        </FlexBoxColumn>

        <FlexBoxColumn overflowY="hidden" hSpacingLeft={Spacing.small} flex={10}>
          <MuiTypography noWrap data-testid="item-title">
            {textWithHighlight(title, searchValue, {
              color: Color.primary,
              fontWeight: FontWeight.bold
            })}
          </MuiTypography>
          <MuiTypography noWrap data-testid="item-subtitle">
            {textWithHighlight(getSubtitleByCategory(category, item), searchValue, {
              fontSize: 13
            })}
          </MuiTypography>
          <MuiTypography noWrap color={Color.gray} fontSize={FontSize.small}>
            <span>
              {textWithHighlight(formatShortDate(date as DateStr), searchValue, {
                color: Color.gray,
                fontSize: FontSize.small
              })}
            </span>
            <span>{category ? ` \u2022 ${unCamelCase(category)}` : ''}</span>
          </MuiTypography>
        </FlexBoxColumn>

        <FlexBox alignItems="center" justifyContent="center" flex={1}>
          <Svg set="material" name="keyboard_arrow_right" />
        </FlexBox>
      </FlexBoxRow>
    </MuiListItem>
  );
};

export default SearchItem;
