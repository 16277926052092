import React, { useEffect } from 'react';
import DashboardBillingError from './DashboardBillingError';
import DashboardBillingInfo from './DashboardBillingInfo';
import useNavigationAnalytics from 'hooks/useNavigationAnalytics';
import { useProxySwitcherEffect } from 'hooks/useProxySwitcherEffect';
import { useRefresh } from 'hooks/useRefresh';
import { AnalyticsEvent } from 'services/AnalyticsService';
import { MuiGrid } from 'theme/material-ui';
import { useDispatch, useSelector } from 'react-redux';
import { getBillingSummarySelector } from 'store/billing/selectors';
import { BillingSummary } from 'store/billing/types';
import { getBillingSummary } from 'store/billing/actions';

const useDashboardBillingSummary = (): {
  guarantorData?: BillingSummary;
  guarantorLoading?: boolean;
  guarantorError?: Error | string | null;
} => {
  const {
    data: mhpGuarantorData,
    error: mhpGuarantorError,
    isFetching: mhpGuarantorLoading = false
  } = useSelector(getBillingSummarySelector);

  return {
    guarantorData: mhpGuarantorData,
    guarantorLoading: mhpGuarantorLoading,
    guarantorError: mhpGuarantorError
  };
};

export const DashboardBilling = () => {
  const dispatch = useDispatch();

  const analyticsOnError = useNavigationAnalytics(AnalyticsEvent.DashboardBillingFetchError);
  const { guarantorData, guarantorLoading, guarantorError } = useDashboardBillingSummary();
  const { amountDue = 0, dueStatus = '', totalEncounters = 0 } = guarantorData || {};

  useEffect(() => {
    if (guarantorError) analyticsOnError();
  }, [guarantorError]);

  const { load, reset } = useRefresh(() => {
    dispatch(getBillingSummary());
  });

  useProxySwitcherEffect(() => {
    reset();
    load();
  }, []);

  if (guarantorLoading) return null;
  if (guarantorError)
    return (
      <MuiGrid item xs={12} sm={12} md={12} lg={6}>
        <DashboardBillingError />
      </MuiGrid>
    );
  if (totalEncounters === 0) return null;

  return (
    <MuiGrid item xs={12} sm={12} md={12} lg={6}>
      <DashboardBillingInfo
        amountDue={amountDue}
        numberOfStatements={totalEncounters}
        dueStatus={dueStatus}
      />
    </MuiGrid>
  );
};

export default DashboardBilling;
