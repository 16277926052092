import React from 'react';
import Divider from 'components/UI/Divider';
import { LabResultsListItems } from 'store/testResults/types';
import Typography from 'components/UI/Typography';
import { Color } from 'modules/styles/colors';
import { FontSize, FontWeight } from 'modules/styles/variables';
import { getLabValue, orNA } from '../../TestResults/Lab/utils';
import {
  MuiBox,
  MuiPaper,
  MuiTable,
  MuiTableRow,
  MuiTableHead,
  MuiTableBody
} from 'theme/material-ui';
import { TableCell } from '../styled';

// LAB RESULTS
interface LabResultsProps {
  content: LabResultsListItems[];
  isSpanish: boolean;
}
const LabResults = (props: LabResultsProps) => {
  const { content, isSpanish } = props;

  return (
    <>
      <Divider />
      <MuiBox p={2} bgcolor={Color.grayLight3}>
        <Typography fontSize={FontSize.large} fontWeight={FontWeight.bold}>
          Your results
        </Typography>
      </MuiBox>
      <Divider />

      <MuiBox component={MuiPaper} p={3}>
        <MuiTable aria-label="Labs Details">
          <MuiTableHead>
            <MuiTableRow>
              <TableCell id="table-name">Name</TableCell>
              <TableCell id="table-value" align="right">
                Value
              </TableCell>
              <TableCell id="table-interpretation">Interpretation</TableCell>
              <TableCell id="table-more-link">More</TableCell>
            </MuiTableRow>
          </MuiTableHead>
          <MuiTableBody>
            {content.map((item: any) => (
              <>
                <MuiTableRow key={item.name} hover>
                  <TableCell id={item.eventId} component="th" scope="row">
                    {item.name}
                  </TableCell>
                  <TableCell headers={item.eventId} align="right">
                    {getLabValue(item.valueQuantity, item.valueString)}
                  </TableCell>
                  <TableCell headers={item.eventId}>{orNA(item.interpretation)}</TableCell>
                </MuiTableRow>
                <MuiTableRow>
                  <TableCell headers={item.eventId} colSpan={4}>
                    Comments: {isSpanish ? orNA(item.notes_es) : orNA(item.notes)}
                  </TableCell>
                </MuiTableRow>
              </>
            ))}
          </MuiTableBody>
        </MuiTable>
      </MuiBox>
    </>
  );
};

export default LabResults;
