import React from 'react';
import { GestureResponderEvent } from 'react-native';
import { Icon } from 'components/Icon';
import styled, { css } from 'styled-components';
import { Color } from 'modules/styles/colors';
import { ZIndex, Spacing } from 'modules/styles/variables';

interface Props {
  onClick?: (event: GestureResponderEvent) => void;
  onPress?: (event: GestureResponderEvent) => void;
  iconName?: string;
  justifyContent?: string;
  color?: string;
  customBackgroundColor?: string;
  size?: number;
  absolute?: boolean;
  margin?: string;
  padding?: number;
  'data-testid'?: string;
}

const StyledTouchable = styled.div`
  cursor: pointer;
  align-items: center;
  background-color: ${(props: Props) =>
    props.customBackgroundColor ? props.customBackgroundColor : 'transparent'};
  margin: ${(props: Props) => (props.margin ? props.margin : `${Spacing.small}px;`)};
  ${(props: Props) => (props.padding ? `padding: ${props.padding}px;` : '')};
  border-radius: ${props => (props.size ? props.size / 2 : 12)}px;
  height: ${props => (props.padding ? `${props?.size + props.padding * 2}px` : `${props.size}px`)};
  z-index: ${ZIndex.high};
  ${(props: Props) =>
    props.absolute &&
    css`
      position: absolute;
      margin: ${(props: Props) => (props.margin ? props.margin : `${Spacing.large}px;`)};
      right: 0px;
      top: 0px;
    `}
`;

const CloseButton = (props: Props) => (
  <StyledTouchable
    data-testid={props['data-testid']}
    absolute={props.absolute}
    padding={props.padding}
    margin={props.margin}
    customBackgroundColor={props.customBackgroundColor}
    size={props.size}
    onClick={props.onPress || props.onClick}
  >
    <Icon name={props.iconName ? props.iconName : 'close'} size={props.size} color={props.color} />
  </StyledTouchable>
);

CloseButton.defaultProps = {
  justifyContent: 'center',
  color: Color.primary,
  size: 24
};

export default CloseButton;
