import React, { useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { animated, useSpring } from 'react-spring';

import { ConnectCareButton, ConnectCareTextField } from 'components/ConnectCare';
import { useSnack } from 'components/Snack';
import FlexBox from 'components/UI/Layout/FlexBox';
import { Svg } from 'components/UI/Svg';
import { errors as amwellErrors } from 'lib/amwell/client/constants';
import AwsdkError from 'lib/amwell/client/errors/AwsdkError';
import { paymentMethod as paymentMethodConfirmations } from 'modules/constants/amwell';
import { Color } from 'modules/styles/colors';
import { FontSize, IconSize, Spacing } from 'modules/styles/variables';
import { updateCouponCode } from 'store/amwell/actions';
import * as selectors from 'store/amwell/selectors';
import { VisitCost } from 'store/amwell/types';
import { RootDispatch, RootState } from 'store/types';
import { MuiBox, MuiGrid, MuiIconButton, MuiPaper, MuiTypography } from 'theme/material-ui';

export interface Props {
  dispatch: RootDispatch;
  loading: boolean;
  error: AwsdkError | Error | null;
  cost: VisitCost;
}

const AnimatedMuiTypography = animated(MuiTypography);

const getHelperText = (err: AwsdkError | Error | null) => {
  let helperText = '';
  if (err instanceof AwsdkError && err.errorCode === amwellErrors.INVALID_COUPON) {
    helperText = amwellErrors.INVALID_COUPON;
  }
  return helperText;
};

export function ConnectCarePaymentMethodSelectCost(props: Props) {
  const { dispatch, cost, loading, error } = props;

  const copayCost = Number(cost.expectedConsumerCopayCost.toFixed(2));
  const { current } = useRef(copayCost);
  const { create } = useSnack();
  const spring = useSpring({ num: current === copayCost ? current : copayCost });
  const helperText = useMemo(() => getHelperText(error), [error]);
  const [coupon, setCoupon] = useState('');

  useEffect(() => {
    if (cost.couponCode) {
      create(
        paymentMethodConfirmations.COUPON_CODE_SUCCESS.subtitle,
        paymentMethodConfirmations.COUPON_CODE_SUCCESS.severity
      );
    }
  }, [cost.couponCode]);

  const onApplyCoupon = () => {
    dispatch(updateCouponCode({ couponCode: coupon }));
  };

  const isApplying = loading || !!cost.couponCode;
  const isDisabled = !cost.canApplyCouponCode || cost.zeroCostVisit || isApplying;

  return (
    <MuiPaper>
      <MuiBox p={Spacing.small} minWidth={290}>
        <MuiGrid container direction="column">
          <MuiGrid item style={{ textAlign: 'center' }}>
            <AnimatedMuiTypography variant="h3">
              {spring.num.interpolate(n => `$${n.toFixed(2)}`)}
            </AnimatedMuiTypography>
          </MuiGrid>
          <MuiGrid item>
            <FlexBox flexDirection="row" alignItems="center" justifyContent="center">
              <MuiTypography variant="subtitle1" style={{ marginTop: 5, marginLeft: 15 }}>
                Your Visit Cost
              </MuiTypography>
              <MuiIconButton
                data-testid="info-button-payment-info"
                aria-label="More info on payment cost"
                color="inherit"
                href={paymentMethodConfirmations.PAYMENT_COST_MORE_INFO_URL}
                target="_blank"
              >
                <Svg name="InfoIcon" set="assets" size={IconSize.base} color={Color.black} />
              </MuiIconButton>
            </FlexBox>
          </MuiGrid>
        </MuiGrid>

        <MuiBox pt={2}>
          <MuiTypography>Coupon Code</MuiTypography>
          <ConnectCareTextField
            placeholder="Enter Coupon Code"
            value={coupon}
            disabled={isDisabled}
            error={!!helperText}
            onChange={e => setCoupon(e.target.value)}
            onKeyPress={e => e.key === 'Enter' && onApplyCoupon()}
          />
        </MuiBox>
        <MuiBox mt={2}>
          <ConnectCareButton
            fullWidth
            color="secondary"
            variant="contained"
            onClick={onApplyCoupon}
            data-testid="apply-coupon"
            disabled={!coupon || isDisabled}
          >
            Apply
          </ConnectCareButton>
        </MuiBox>
        <MuiBox textAlign="center" color="error.main" height={15} p={1}>
          {!!helperText && <MuiTypography fontSize={FontSize.small}>{helperText}</MuiTypography>}
        </MuiBox>
      </MuiBox>
    </MuiPaper>
  );
}

const mapStateToProps = (state: RootState) => ({
  loading: selectors.visitCouponLoadingSelector(state),
  error: selectors.visitCouponErrorSelector(state)
});

export default connect(mapStateToProps)(ConnectCarePaymentMethodSelectCost);
