import Svg, { SvgProps } from 'components/UI/Svg/Svg';
import { Color } from 'modules/styles/colors';
import React, { ReactNode, useState, useEffect, useRef } from 'react';
import { FontSize, FontWeight, IconSize, Spacing } from 'modules/styles/variables';
import analyticsService, { AmplitudeEventData, AnalyticsEvent } from 'services/AnalyticsService';
import { convertToLowerKabobCase } from 'modules/utils/StringUtils';
import {
  MuiBox,
  MuiExpansionPanelDetails,
  MuiExpansionPanelSummary,
  MuiTypography
} from 'theme/material-ui';
import { SubText, StyledMuiExpansionPanel } from './styled';
import FlexBox from 'components/UI/Layout/FlexBox';

export interface CollapsibleListItemProps {
  alignLast?: string;
  testID?: string;
  detailsPadding?: string;
  firstListItem?: boolean;
  handleChange: (val: boolean) => void;
  headerColorOnExpand?: string;
  leftIcon?: string;
  leftIconProps?: SvgProps;
  leftIndent?: string;
  title: string;
  titleExtension?: ReactNode;
  customTitle?: ReactNode;
  subtitle?: string;
  subtitle1?: string;
  subtitle2?: string;
  padding?: string;
  margin?: string;
  children: ReactNode;
  largeView?: boolean;
  overrideDisabledOpacity: boolean;
  currentUrlForAnalytics?: string;
  referringUrlForAnalytics?: string;
  analyticsEvent?: AnalyticsEvent;
  expandOnFirstRender?: boolean;
  isExpandable?: boolean;
  error?: string;
  shouldScroll?: boolean;
  height?: string;
}

const CollapsibleListItem = ({
  alignLast,
  detailsPadding,
  error,
  firstListItem,
  handleChange,
  headerColorOnExpand,
  leftIcon,
  leftIconProps,
  leftIndent,
  overrideDisabledOpacity,
  title = '',
  titleExtension,
  customTitle,
  subtitle,
  subtitle1,
  subtitle2,
  padding,
  margin,
  currentUrlForAnalytics,
  referringUrlForAnalytics,
  analyticsEvent,
  expandOnFirstRender,
  children,
  largeView,
  testID,
  isExpandable = true,
  shouldScroll,
  height
}: CollapsibleListItemProps) => {
  const ref = useRef<HTMLDivElement>();
  const [expanded, setItemExpanded] = useState(expandOnFirstRender || false);
  const iconName = expanded ? 'Subtract' : 'Add';
  const hideExpandIcon = !isExpandable && overrideDisabledOpacity;

  subtitle1 = subtitle1 || subtitle;

  useEffect(() => {
    if (ref.current && shouldScroll) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [ref, shouldScroll]);

  const toggleItem = () => {
    if (analyticsEvent && !expanded) {
      const data: AmplitudeEventData = {
        currentUrl: currentUrlForAnalytics,
        referringUrl: referringUrlForAnalytics
      };

      analyticsService.logEvent(analyticsEvent, data);
    }

    setItemExpanded(!expanded);
    handleChange(!expanded);
  };

  return (
    <StyledMuiExpansionPanel
      ref={ref}
      firstListItem={firstListItem}
      onChange={toggleItem}
      disabled={!isExpandable}
      expanded={expanded}
      margin={margin}
      padding={padding}
      style={{
        backgroundColor: 'transparent',
        boxShadow: 'none'
      }}
      data-testid={testID}
      height={height}
    >
      <MuiExpansionPanelSummary
        id={convertToLowerKabobCase(title, '-header')}
        aria-controls={convertToLowerKabobCase(title, '-content')}
        data-testid={convertToLowerKabobCase(title, '-collapsible-list-item-parent')}
        expandIcon={
          !hideExpandIcon ? (
            <Svg name={iconName} color={Color.black} />
          ) : (
            <MuiBox width="23px"></MuiBox>
          )
        }
        style={{
          backgroundColor: expanded ? headerColorOnExpand ?? Color.cobaltTint : undefined,
          opacity: overrideDisabledOpacity ? '1' : ''
        }}
      >
        <MuiBox
          display="flex"
          flex={largeView ? '1 1 auto' : ''}
          justifyContent="space-between"
          alignItems="center"
          paddingLeft={leftIndent}
          width="100%"
        >
          {leftIcon && (
            <FlexBox alignItems="center" width={40} hSpacingRight={Spacing.large}>
              <Svg name={leftIcon} size={IconSize.base} {...leftIconProps} />
            </FlexBox>
          )}

          {customTitle || (
            <MuiBox
              data-testid={convertToLowerKabobCase(title, `-expandable-drawer`)}
              display="block"
              width="100%"
              alignItems="center"
            >
              <MuiBox
                display={largeView ? 'inline-block' : 'inline'}
                width={largeView ? '33%' : 'unset'}
              >
                <MuiTypography
                  fontWeight={FontWeight.semibold}
                  fontSize={FontSize.large}
                  data-testid="collapsible-list-item-title"
                >
                  {title}
                </MuiTypography>
                {titleExtension ? <>{titleExtension}</> : null}
              </MuiBox>

              {subtitle && !largeView && (
                <MuiBox mt={0.5}>
                  <MuiTypography>
                    {error || subtitle ? (
                      <MuiTypography
                        fontWeight={FontWeight.regularbold}
                        data-testid="collapsible-list-item-subtitle"
                      >
                        {error || subtitle}
                      </MuiTypography>
                    ) : null}
                  </MuiTypography>
                </MuiBox>
              )}
              {largeView && subtitle1 && (
                <MuiBox width={!subtitle2 ? '66%' : '33%'} display="inline-block">
                  <MuiTypography>
                    <SubText>{subtitle1}</SubText>
                  </MuiTypography>
                </MuiBox>
              )}
              {largeView && subtitle2 && (
                <MuiBox width={!subtitle ? '66%' : '33%'} display="inline-block" textAlign="right">
                  <MuiTypography align="right">
                    <SubText textAlign={alignLast} fontWeight={FontWeight.bold}>
                      {subtitle2}
                    </SubText>
                  </MuiTypography>
                </MuiBox>
              )}
            </MuiBox>
          )}
        </MuiBox>
      </MuiExpansionPanelSummary>

      <MuiExpansionPanelDetails
        style={{
          padding: detailsPadding
        }}
      >
        <MuiBox width="100%">{children}</MuiBox>
      </MuiExpansionPanelDetails>
    </StyledMuiExpansionPanel>
  );
};

CollapsibleListItem.defaultProps = {
  handleChange: () => {},
  title: '',
  subtitle: '',
  children: null,
  rightExpandText: null,
  rightExpandFlex: 1,
  thisRef: undefined,
  isExpandable: true
} as Partial<CollapsibleListItemProps>;

export default CollapsibleListItem;
