import Dialog from '@material-ui/core/Dialog';
import CloseButton from 'components/UI/Button/CloseButton';
import Box from 'components/UI/Layout/Box';
import FlexBox from 'components/UI/Layout/FlexBox';
import Spacer from 'components/UI/Layout/Spacer';
import Spinner from 'components/UI/Spinner/Spinner';
import Typography from 'components/UI/Typography';
import BodyText from 'components/UI/Typography/BodyText';
import { Color } from 'modules/styles/colors';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { toggleNotificationsPreference } from 'store/notifications/actions';
import { updateConsumerProfile } from 'store/profile/actions';
import { profileConsumerSelector } from 'store/profile/selectors';
import { Consumer } from 'store/profile/types';
import { RootState } from 'store/types';
import { MuiContainer } from 'theme/material-ui';
import { oc } from 'ts-optchain';
import { Alert } from 'components/Alert';
import { SpinnerContainer } from 'components/UI/Spinner/SpinnerContainer';
import {
  CONFIRM_AND_SIGN,
  DISCLAIMER,
  E_SIGNATURE_TERMS,
  SHORTCODE_LEGAL_TEXT,
  SHORTCODE_PRIVACY_POLICY_URI,
  SHORTCODE_TERMS_URI,
  SHORTCODE_TITLE
} from './constants';
import { StyledDisclaimer, StyledDisclaimerText, StyledHeader, StyledLegal } from './styled';
import StyledButton from 'components/UI/Button/StyledButton';

interface ShortcodeAuthorizationProps {
  consumer: Consumer;
  open: boolean;
  handleClose: () => void;
  handleUpdate: () => void;
  updateConsumer: (updateConsumer: () => {}, updatedFields: string[]) => Promise<void>;
}

const ShortcodeAuthorization = ({
  consumer,
  open,
  handleClose,
  handleUpdate,
  updateConsumer
}: ShortcodeAuthorizationProps) => {
  const [isSigning, setIsSigning] = useState(false);
  const termTypes = ['TWILIO', 'TCPA'];
  const sign = () => {
    setIsSigning(true);
    updateConsumer(() => {
      return {
        ...consumer,
        externalTermsAcceptance: [
          ...oc(consumer)
            .externalTermsAcceptance([])
            .filter((term: { type: string }) => !termTypes.includes(term.type)),
          { type: 'TCPA', version: '269707', date: new Date() },
          { type: 'TWILIO', version: '271357', date: new Date() }
        ]
      };
    }, ['ExternalTermsAcceptance'])
      .then(() => {
        setIsSigning(false);
        handleUpdate();
      })
      .catch(() => Alert.alert('Unable to enabled SMS', 'Please try again or contact support'));
  };

  return (
    <Dialog fullScreen open={open} onClose={handleClose}>
      <MuiContainer maxWidth="md">
        <FlexBox spacing={18} flex={1} flexDirection="column" backgroundColor={Color.white}>
          <FlexBox alignItems="flex-end">
            <CloseButton onPress={() => handleClose()} />
          </FlexBox>
          <>
            <StyledHeader>{SHORTCODE_TITLE}</StyledHeader>
            <Box vOffsetTop={10} vOffsetBottom={15}>
              <StyledDisclaimer>{E_SIGNATURE_TERMS}</StyledDisclaimer>
            </Box>
          </>
          <StyledLegal>
            {isSigning ? (
              <SpinnerContainer backgroundColor="transparent" display="flex">
                <Box vSpacingBottom={50}>
                  <Spinner />
                </Box>
              </SpinnerContainer>
            ) : null}
            <BodyText>{SHORTCODE_LEGAL_TEXT}</BodyText>
            <Spacer size="medium" />
            <BodyText>Text STOP to opt out or HELP for help.</BodyText>
            <FlexBox flexDirection="row" justifyContent="space-around" alignItems="center">
              <a href={SHORTCODE_TERMS_URI} target="_blank" rel="noopener noreferrer">
                <Typography color={Color.primaryHue2}>Short Code T&amp;C</Typography>
              </a>
              <a href={SHORTCODE_PRIVACY_POLICY_URI} target="_blank" rel="noopener noreferrer">
                <Typography color={Color.primaryHue2}>Privacy Policy</Typography>
              </a>
            </FlexBox>
          </StyledLegal>
          <Box vSpacingTop={15} vSpacingBottom={20} style={{ margin: '0 auto' }}>
            <Box vOffsetBottom={15}>
              <StyledDisclaimerText>{DISCLAIMER}</StyledDisclaimerText>
            </Box>
            <StyledButton
              title="ShortCodeConfirmAndSign"
              disabled={isSigning}
              onClick={() => sign()}
            >
              {CONFIRM_AND_SIGN}
            </StyledButton>
          </Box>
        </FlexBox>
      </MuiContainer>
    </Dialog>
  );
};

const mapStateToProps = (state: RootState) => ({
  consumer: profileConsumerSelector(state)
});

const mapDispatchToProps = {
  updateConsumer: updateConsumerProfile,
  togglePreference: toggleNotificationsPreference
};

export default connect(mapStateToProps, mapDispatchToProps)(ShortcodeAuthorization);
