import React from 'react';
import { useAuditLogFilters } from 'lib/hooks/AuditLogFilters/useAuditLogFilters';
import { FiltersKey } from 'lib/hooks/AuditLogFilters/types/filter';

import CategoriesFilter, { CategoriesFilterProps } from './CategoriesFilter';
import PersonsFilter, { PersonsFilterProps } from './PersonsFilter';
import ActionsFilter, { ActionsFilterProps } from './ActionsFilter';
import DateAndTimeFilter, { DateAndTimeFilterProps } from './DateAndTimeFilter';
import PerformedBysFilter, { PerformedBysFilterProps } from './PerformedByFilter';

export interface Props {
  defaultExpandedKeys: FiltersKey[];
}

const componentMap: Record<FiltersKey, React.FC<any>> = {
  categories: CategoriesFilter as React.FC<CategoriesFilterProps>,
  persons: PersonsFilter as React.FC<PersonsFilterProps>,
  actions: ActionsFilter as React.FC<ActionsFilterProps>,
  dateAndTimeOfEvent: DateAndTimeFilter as React.FC<DateAndTimeFilterProps>,
  performedBy: PerformedBysFilter as React.FC<PerformedBysFilterProps>
} as const;

function AuditLogFiltersContent({ defaultExpandedKeys }: Props) {
  const { filters } = useAuditLogFilters();

  return (
    <>
      {Object.keys(filters).map(filter => {
        const key = filter as FiltersKey;
        const FilterComponent = componentMap[key];

        return (
          <FilterComponent
            key={key}
            defaultExpanded={defaultExpandedKeys.includes(key)}
            {...filters[key]}
          />
        );
      })}
    </>
  );
}

export default AuditLogFiltersContent;
