import React from 'react';
import { useHistory } from 'react-router-dom';
import { HEALTH_RECORDS_DRAWERS } from 'lib/constants/healthRecords';
import { Spacing } from 'modules/styles/variables';
import { AnalyticsEvent } from 'services/AnalyticsService';
import { EducationItem } from 'store/education/types';
import DataLoader from 'components/UI/DataLoader/DataLoader';
import CollapsibleListItem from 'components/common/CollapsibleList/CollapsibleListItem';
import { MuiBox, MuiDivider, MuiPaper, MuiButton } from 'theme/material-ui';
import Spinner from 'components/UI/Spinner/Spinner';
import useNavigationAnalytics from 'hooks/useNavigationAnalytics';
import usePatientEducation from 'lib/hooks/usePatientEducation';
import { HealthRecordsListItemPlaceholder } from '../shared';
import EducationListItem from './ListItem';
import FlexBox from 'components/UI/Layout/FlexBox';

const getLoadingState = () => (
  <>
    <HealthRecordsListItemPlaceholder message={HEALTH_RECORDS_DRAWERS.Education.loading} />
    <MuiBox p={Spacing.xSmall} display="flex" flexDirection="row" justifyContent="center">
      <Spinner />
    </MuiBox>
  </>
);

const getErrorState = () => (
  <HealthRecordsListItemPlaceholder
    message={HEALTH_RECORDS_DRAWERS.getErrorState('education programs')}
  />
);

export const EducationList = () => {
  const history = useHistory();
  const analytics = useNavigationAnalytics(AnalyticsEvent.EducationClicked);
  const { incomplete, loading, error, counts } = usePatientEducation();

  const handleItemOnPress = (accessCode: EducationItem['accessCode']) => {
    history.push(`/u/health-record/patient-education/detail/${accessCode}`);
  };

  const handleViewAll = () => {
    history.push('/u/health-record/patient-education');
  };

  const hasMoreThanThree = incomplete?.length > 3;
  const firstThreeItems = hasMoreThanThree ? incomplete?.slice(0, 3) : incomplete;
  const showViewAll = hasMoreThanThree || counts.completeCount > 0;
  const showDivider = counts.incompleteCount === 0 && counts.totalCount > 0;

  return (
    <CollapsibleListItem
      leftIcon="Education"
      title={HEALTH_RECORDS_DRAWERS.Education.title}
      subtitle={
        counts.incompleteCount > 0
          ? `(${counts.incompleteCount} action items)`
          : HEALTH_RECORDS_DRAWERS.Education.subtitle
      }
      data-testid="PatientEducationCollapsible"
      analyticsEvent={AnalyticsEvent.EducationClicked}
      currentUrlForAnalytics={analytics.currentUrl}
      referringUrlForAnalytics={analytics.referringUrl}
      detailsPadding="0"
    >
      <MuiBox width="100%">
        <MuiPaper elevation={2}>
          <DataLoader
            data={firstThreeItems}
            error={error}
            loading={loading}
            renderData={data => (
              <>
                {data?.map((item: EducationItem) => (
                  <EducationListItem
                    item={item}
                    onPress={() => handleItemOnPress(item.accessCode)}
                  />
                ))}
              </>
            )}
            renderNoData={() => (
              <HealthRecordsListItemPlaceholder
                message={HEALTH_RECORDS_DRAWERS.Education.empty(
                  counts.totalCount > 0 ? "You're all caught up! " : ''
                )}
              />
            )}
            renderError={getErrorState}
            renderLoading={getLoadingState}
          />
          {showViewAll ? (
            <>
              {showDivider ? <MuiDivider /> : null}
              <FlexBox justifyContent="flex-start" padding={Spacing.large}>
                <MuiButton
                  color="primary"
                  variant="outlined"
                  data-testid="view-all-education-link"
                  onClick={handleViewAll}
                >
                  View all education ({counts.totalCount})
                </MuiButton>
              </FlexBox>
            </>
          ) : null}
        </MuiPaper>
      </MuiBox>
    </CollapsibleListItem>
  );
};

export default EducationList;
