/* eslint-disable jsx-a11y/heading-has-content */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { startWebRTCVisit } from 'store/amwell/actions';
import { VideoConsole, Visit, ContextElements } from 'store/amwell/types';
import * as selectors from 'store/amwell/selectors';
import { RootState, RootDispatch } from 'store/types';

import { Color } from 'modules/styles/colors';
import Svg from 'components/UI/Svg/Svg';
import logo from 'assets/branding/ih-logo-640x196-on-light.png';
import { Box } from '@material-ui/core';
import { Portal } from 'components/Portal';

import { messages, createConsoleWrapperAttachPoints } from './constants';
import WebRTCBanner, { WebRTCTimer } from './styled/WebRTCBanner';
import WebRTCContent from './styled/WebRTCContent';
import WebRTCMenu, {
  WebRTCMenuNotes,
  WebRTCMenuChat,
  WebRTCMenuInvite,
  WebRTCMenuItemTitle
} from './styled/WebRTCMenu';
import WebRTCMenuDetails, {
  WebRTCMenuClose,
  WebRTCNotes,
  WebRTCNotesContent,
  WebRTCMenuHeader,
  WebRTCMenuTitle,
  WebRTCChat,
  WebRTCChatIntroText,
  WebRTCChatMsgArea,
  WebRTCChatTextInputWrapper,
  WebRTCChatTextInput,
  WebRTCChatSend,
  WebRTCChatNoIVR,
  WebRTCInvite,
  WebRTCInviteLabel,
  WebRTCInviteDisplayInvited,
  WebRTCInviteButton,
  WebRTCInviteErrorText,
  WebRTCInviteIntroText,
  WebRTCInviteTextInput,
  WebRTCInviteTextInputWrapper,
  WebRTCMaxInvitesMessage,
  WebRTCInviteLabelWrapper
} from './styled/WebRTCMenuDetails';
import WebRTCVideo, { WebRTCVideoContainer } from './styled/WebRTCVideo';
import WebRTCStyles from './styled/WebRTCStyles';

export interface Props {
  dispatch: RootDispatch;
  open: boolean;
  visit: Visit | null;
  videoConsole: VideoConsole | null;
}

export function ConnectCareWebRTC(props: Props) {
  const { visit, dispatch, open, videoConsole } = props;

  const onElement = (el: HTMLElement | null) => {
    if (el == null) {
      return;
    }

    el.className = 'awsdk-console awsdk-hidden';
    el.id = 'awsdk-console';
    el.dir = 'ltr';
    el.setAttribute('role', 'presentation');
  };

  const mountWebRTCVisit = () => {
    const contextElements: ContextElements = {
      messages,
      videoConsoleContainer: document.getElementById('awsdk-videoContainer'),
      consoleWrapperAttachPoints: createConsoleWrapperAttachPoints()
    };

    dispatch(startWebRTCVisit({ visit, contextElements }));
  };

  const destroyWebRTCVisit = () => {
    if (videoConsole) {
      videoConsole.stop();
    }
  };

  useEffect(() => {
    const awsdkConsole = document.getElementById('awsdk-console');

    if (open) {
      awsdkConsole?.classList.remove('awsdk-hidden');
      mountWebRTCVisit();
    } else {
      awsdkConsole?.classList.add('awsdk-hidden');
      destroyWebRTCVisit();
    }

    return () => {
      destroyWebRTCVisit();
    };
  }, [open]);

  return (
    <Portal onElement={onElement}>
      <WebRTCStyles />
      <WebRTCBanner position="fixed" elevation={3} id="awsdk-topBanner">
        <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
          <img src={logo} width="100px" height="34px" alt="" />
          <WebRTCTimer id="awsdk-timer" className="awsdk-hidden" />
        </Box>
      </WebRTCBanner>
      <WebRTCContent id="awsdk-console-middle">
        <WebRTCMenu id="awsdk-leftMenuContainer">
          <WebRTCMenuNotes id="awsdk-menuNotes">
            <WebRTCMenuItemTitle id="awsdk-menu-tab-title-notes" />
          </WebRTCMenuNotes>
          <WebRTCMenuChat id="awsdk-menuChat">
            <WebRTCMenuItemTitle id="awsdk-menu-tab-title-chat" />
          </WebRTCMenuChat>
          <WebRTCMenuInvite id="awsdk-menuInviteGuest">
            <WebRTCMenuItemTitle id="awsdk-menu-tab-title-invite" />
          </WebRTCMenuInvite>
        </WebRTCMenu>
        <WebRTCMenuDetails id="awsdk-menuDetailsWrapper" className="awsdk-hidden">
          <WebRTCMenuClose id="awsdk-menuClose">
            <Svg set="material" name="close" size={24} fill={Color.black} />
          </WebRTCMenuClose>
          <WebRTCNotes id="awsdk-notesDetails" className="awsdk-notes awsdk-hidden">
            <WebRTCMenuHeader id="awsdk-menuDetailsNotesHeader">
              <WebRTCMenuTitle id="awsdk-notesDetailsTitle" />
            </WebRTCMenuHeader>
            <WebRTCNotesContent id="awsdk-notesContent" />
          </WebRTCNotes>
          <WebRTCChat id="awsdk-chatDetails" className="awsdk-chat awsdk-hidden">
            <WebRTCMenuHeader id="awsdk-menuDetailsChatHeader">
              <WebRTCMenuTitle id="awsdk-chatDetailsTitle" />
              <WebRTCChatIntroText id="awsdk-chatIntroText" />
            </WebRTCMenuHeader>
            <WebRTCChatMsgArea id="awsdk-chatMsgArea">
              <WebRTCChatNoIVR id="awsdk-chatNoIVR" className="awsdk-hidden" />
            </WebRTCChatMsgArea>
            <WebRTCChatTextInputWrapper id="awsdk-chatTextInputWrapper">
              <WebRTCChatTextInput id="awsdk-chatInput" type="text" />
              <WebRTCChatSend id="awsdk-chatInputIcon">
                <Svg set="material" name="send" size={24} fill={Color.primary} />
              </WebRTCChatSend>
            </WebRTCChatTextInputWrapper>
          </WebRTCChat>
          <WebRTCInvite id="awsdk-inviteGuestDetails" className="awsdk-inviteGuest awsdk-hidden">
            <WebRTCMenuHeader id="awsdk-menuDetailsGuestHeader">
              <WebRTCMenuTitle id="awsdk-inviteGuestDetailsTitle" />
            </WebRTCMenuHeader>
            <WebRTCInviteLabelWrapper>
              <WebRTCInviteLabel id="awsdk-guestLabel" />
              <WebRTCInviteDisplayInvited
                id="awsdk-inviteGuestDisplayInvited"
                className="awsdk-hidden"
              />
            </WebRTCInviteLabelWrapper>
            <WebRTCInviteTextInputWrapper id="awsdk-inviteGuestInputWrapper">
              <WebRTCInviteIntroText id="awsdk-inviteGuestIntroText" />
              <WebRTCInviteTextInput id="awsdk-inviteGuestInput" type="text" />
              <WebRTCInviteErrorText id="awsdk-inviteGuestErrorText" className="awsdk-hidden" />
              <WebRTCInviteButton id="awsdk-inviteGuestButton" type="button"></WebRTCInviteButton>
            </WebRTCInviteTextInputWrapper>
            <WebRTCMaxInvitesMessage
              id="awsdk-maxInvitesMessage"
              className="awsdk-maxInvitesMessage awsdk-hidden"
            />
          </WebRTCInvite>
        </WebRTCMenuDetails>
        <WebRTCVideo id="awsdk-console-wrapper">
          <WebRTCVideoContainer id="awsdk-videoContainer" />
        </WebRTCVideo>
      </WebRTCContent>
    </Portal>
  );
}

const mapStateToProps = (state: RootState) => ({
  visit: selectors.visitDataSelector(state),
  videoConsole: selectors.webRTCVideoConsoleDataSelector(state)
});

export default connect(mapStateToProps)(ConnectCareWebRTC);
