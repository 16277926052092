import React, { useMemo, useState } from 'react';
import { FlatList, TouchableOpacity, View } from 'react-native';
import Box from 'components/UI/Layout/Box';
import Typography from 'components/UI/Typography';
import { animated, useSpring } from 'react-spring';

import { Color } from 'modules/styles/colors';
import { Spacing } from 'modules/styles/variables';

import * as types from 'store/visitSummary/types';
import Divider from 'components/UI/Divider';
import { ListItem } from 'components/UI/ListItem/ListItem';
import RadioButton from 'components/UI/Radio/RadioButton';

interface FilterItem {
  value: string | types.VisitType;
  label?: string;
}

interface Props {
  items: FilterItem[];
  filter: string | types.VisitType;
  onPress: (value: string | types.VisitType) => void;
  initialCount?: number;
}

const separateItems = (items: FilterItem[], initialCount?: number) => {
  const canExpand = initialCount && items.length > initialCount;

  return {
    base: canExpand ? items.slice(0, initialCount) : items,
    more: canExpand ? items.slice(initialCount) : []
  };
};

function VisitSummaryFilterGroup(props: Props) {
  const { filter, items, onPress, initialCount } = props;

  const [expanded, setExpanded] = useState(false);

  const { base, more } = useMemo(() => separateItems(items, initialCount), [items, initialCount]);

  const spring = useSpring({
    height: expanded ? 'auto' : 0,
    opacity: expanded ? 1 : 0
  });

  const renderItem = (item: FilterItem) => {
    return (
      <ListItem
        title={item.label}
        onPress={() => onPress(item.value)}
        rightItem={
          <RadioButton
            accessibilityLabel={item.label}
            value={item.value}
            selected={filter === item.value}
          />
        }
      />
    );
  };

  return (
    <>
      <FlatList
        data={base}
        ItemSeparatorComponent={() => <Divider />}
        keyExtractor={item => item.value}
        renderItem={({ item }) => renderItem(item)}
      />
      <Divider />
      <AnimatedView style={spring}>
        <FlatList
          data={more}
          ItemSeparatorComponent={() => <Divider />}
          keyExtractor={item => item.value}
          renderItem={({ item }) => renderItem(item)}
        />
        <Divider />
      </AnimatedView>
      {!!more.length && (
        <Box hSpacing={Spacing.large} vSpacing={10} flex={0}>
          <TouchableOpacity onPress={() => setExpanded(!expanded)}>
            <Typography color={Color.primary}>
              {expanded ? 'Close options' : 'Show all options'}
            </Typography>
          </TouchableOpacity>
        </Box>
      )}
    </>
  );
}

const AnimatedView = animated(View);

export default VisitSummaryFilterGroup;
