import React from 'react';
import { JsonObj } from '@mhp/general-interface';
import styled from 'styled-components';
import { Color } from 'modules/styles/colors';
import formatMoney from 'services/formatMoney';
import { MuiBox, MuiGrid, MuiTypography } from 'theme/material-ui';
import { FontWeight } from 'modules/styles/variables.web';

interface Props {
  deductible: JsonObj;
}

const Spacer = styled(MuiBox)`
  width: 2px;
  height: auto;
`;

const PrevColoredText = styled(MuiTypography)`
  color: ${Color.foreColor};
  margin-right: 2px;
`;

const AppliedColoredText = styled(MuiTypography)`
  color: ${Color.foreColor};
  margin-right: 2px;
`;

function Legend({ deductible }: Props) {
  const computeRemainingSpend = d => {
    const { alreadyPaidValue, appliedSpendValue, deductibleValue } = d;

    if (alreadyPaidValue > deductibleValue) {
      return 0;
    }
    if (alreadyPaidValue + appliedSpendValue < deductibleValue) {
      return formatMoney(appliedSpendValue, 0);
    }
    if (alreadyPaidValue + appliedSpendValue > deductibleValue) {
      const newAppliedSpendValue = deductibleValue - alreadyPaidValue;
      return formatMoney(newAppliedSpendValue, 0);
    }
    if (alreadyPaidValue + appliedSpendValue === deductibleValue) {
      return formatMoney(appliedSpendValue, 0);
    }
    return null;
  };

  return (
    <MuiBox>
      <MuiGrid container>
        <PrevColoredText color={Color.foreColor} fontWeight={FontWeight.bold}>
          ${formatMoney(deductible.alreadyPaidValue, 0)}{' '}
        </PrevColoredText>
        <Spacer />
        <MuiTypography color={Color.foreColor}>spent year to date</MuiTypography>
      </MuiGrid>
      {deductible.appliedSpendValue === 0 ? null : (
        <MuiGrid container>
          <AppliedColoredText fontWeight={FontWeight.bold}>
            ${computeRemainingSpend(deductible)}
          </AppliedColoredText>
          <Spacer />
          <MuiTypography color={Color.foreColor}>would be applied</MuiTypography>
        </MuiGrid>
      )}
    </MuiBox>
  );
}

export default Legend;
