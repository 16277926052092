import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';

import AwsdkError from 'lib/amwell/client/errors/AwsdkError';
import { errors as amwellErrors } from 'lib/amwell/client/constants';
import { RootState } from 'store/types';
import { Medication } from 'store/amwell/types';
import * as selectors from 'store/amwell/selectors';
import * as actions from 'store/amwell/actions';
import {
  MuiBox,
  MuiPaper,
  MuiList,
  MuiListItem,
  MuiListItemText,
  MuiIcon,
  MuiIconButton,
  MuiGrid,
  MuiTypography
} from 'theme/material-ui';
import { IconSize, Spacing } from 'modules/styles/variables';
import Svg from 'components/UI/Svg/Svg';
import DataLoader from 'components/UI/DataLoader/DataLoader';
import { ConnectCareError, ConnectCareTextField } from 'components/ConnectCare';
import analyticsService, { AnalyticsEvent } from 'services/AnalyticsService';
import {
  currentLocationPathNameSelector,
  previousLocationPathNameSelector
} from 'store/router/selectors';
import { medications as medicationConstants } from 'modules/constants/amwell';

export interface Props {
  searchMedications: typeof actions.searchMedications;
  updateMedications: typeof actions.updateMedications;
  clearMedicationsSearch: typeof actions.clearMedicationsSearch;
  medications: Medication[];
  updateLoading: boolean;
  searchResults: Medication[];
  searchLoading: boolean;
  searchError: Error | null;
  currentUrl?: string;
  referringUrl?: string;
}

const getHelperText = (err: AwsdkError | Error | null) => {
  let helperText = '';

  if (
    err instanceof AwsdkError &&
    err.errorCode === amwellErrors.MEDICATION_SEARCH_TEXT_TOO_SHORT
  ) {
    helperText = amwellErrors.MEDICATION_SEARCH_TEXT_TOO_SHORT;
  }

  return helperText;
};

const debouncer = debounce(cb => cb(), 250);

export function ConnectCareMedicationsSearch(props: Props) {
  const {
    searchMedications,
    updateMedications,
    clearMedicationsSearch,
    medications,
    updateLoading,
    searchResults,
    searchLoading,
    searchError,
    currentUrl,
    referringUrl
  } = props;

  const helperText = useMemo(() => getHelperText(searchError), [searchError]);

  const [searchQuery, setSearchQuery] = useState('');

  const filteredSearchResults = searchResults.filter(
    m => !medications.map(e => e.code).includes(m.code)
  );

  const data = searchQuery ? filteredSearchResults : [];

  const handleMedicationSearchTextChange = (value: string) => {
    const searchText = value.replace(/[^\w\s]/gi, '');
    setSearchQuery(searchText);
    debouncer(() => searchMedications({ searchText }));
  };

  const handleMedicationSearchBlur = () => {
    if (searchQuery === '') {
      clearMedicationsSearch();
    }
  };

  const handleClearMedicationsSearch = () => {
    setSearchQuery('');
    clearMedicationsSearch();
  };

  const handleSelectMedication = (m: Medication) => {
    const meds = medications.concat(m);
    updateMedications({ medications: meds });

    analyticsService.logEvent(AnalyticsEvent.MedicationsEntered, {
      currentUrl,
      referringUrl
    });
  };

  const noMatchText =
    searchQuery.length >= 3 && !searchResults.length ? medicationConstants.SEARCH.NO_MATCH : '';

  return (
    <MuiBox width="70%">
      <ConnectCareTextField
        type="search"
        label="Search Medications"
        placeholder="Enter Medication Name..."
        data-testid="Amwell Medications Search Input"
        error={!!helperText}
        helperText={helperText}
        value={searchQuery}
        onBlur={() => handleMedicationSearchBlur()}
        onChange={e => handleMedicationSearchTextChange(e.target.value)}
        InputProps={{
          startAdornment: (
            <MuiBox mr={1}>
              <MuiIcon>
                <Svg set="material" name="search" size={IconSize.base} />
              </MuiIcon>
            </MuiBox>
          ),
          endAdornment: (
            <MuiBox>
              {searchQuery ? (
                <MuiIconButton
                  data-testid="clear-search"
                  onClick={() => handleClearMedicationsSearch()}
                >
                  <Svg set="material" name="clear" size={IconSize.base} />
                </MuiIconButton>
              ) : null}
            </MuiBox>
          )
        }}
      />
      {searchQuery && searchQuery.length >= 3 ? (
        <MuiPaper elevation={2} style={{ maxHeight: '300px' }}>
          <DataLoader
            data={data}
            error={searchError}
            loading={searchLoading}
            renderLoading={() => (
              <MuiBox p={Spacing.small}>
                <MuiTypography>Loading...</MuiTypography>
              </MuiBox>
            )}
            renderNoData={() => (
              <MuiGrid item xs={12}>
                <ConnectCareError message={noMatchText} />
              </MuiGrid>
            )}
            renderData={data => (
              <MuiList style={{ overflow: 'scroll', maxHeight: 'inherit' }}>
                {data.map(m => (
                  <MuiListItem
                    disabled={updateLoading}
                    key={m.code}
                    button
                    onClick={() => handleSelectMedication(m)}
                  >
                    <MuiListItemText id={m.code} primary={m.displayName} />
                  </MuiListItem>
                ))}
              </MuiList>
            )}
          />
        </MuiPaper>
      ) : null}
    </MuiBox>
  );
}

const mapStateToProps = (state: RootState) => ({
  medications: selectors.medicationsDataSelector(state),
  updateLoading: selectors.medicationsActionLoadingSelector(state),
  searchResults: selectors.searchMedicationsDataSelector(state),
  searchLoading: selectors.searchMedicationsDataLoadingSelector(state),
  searchError: selectors.searchMedicationsDataErrorSelector(state),
  currentUrl: currentLocationPathNameSelector(state),
  referringUrl: previousLocationPathNameSelector(state)
});

const mapDispatch = {
  searchMedications: actions.searchMedications,
  updateMedications: actions.updateMedications,
  clearMedicationsSearch: actions.clearMedicationsSearch
};

export default connect(mapStateToProps, mapDispatch)(ConnectCareMedicationsSearch);
