import * as React from 'react';
import { SvgProps } from 'components/UI/Svg';

export default (props: SvgProps) => ({
  svg: (
    <g>
      <path
        fill={props?.color || 'inherit'}
        fillRule="evenodd"
        d="M15.568 2.818a4.5 4.5 0 1 1-.681 5.49l-5.23 2.033a4.5 4.5 0 0 1-.103 2.223l5.563 2.781A4.507 4.507 0 0 1 18.75 13.5a4.5 4.5 0 1 1-4.304 3.187l-5.563-2.782A4.502 4.502 0 0 1 5.25 15.75a4.5 4.5 0 1 1 3.864-6.807l5.229-2.033a4.5 4.5 0 0 1 1.225-4.092ZM18.75 3a3 3 0 1 0 0 6 3 3 0 0 0 0-6ZM5.25 8.25a3 3 0 1 0 0 6 3 3 0 0 0 0-6ZM18.75 15a3 3 0 1 0 0 6 3 3 0 0 0 0-6Z"
        clipRule="evenodd"
      />
    </g>
  ),
  viewBox: '0 0 24 24'
});
