import React from 'react';
import { connect } from 'react-redux';
import dayjs from 'dayjs';

import Container from '@material-ui/core/Container';
import { MuiBox, MuiDivider, MuiTypography } from 'theme/material-ui';

import { AuthType } from 'store/authentication/types';
import * as covidActions from 'store/CovidScreening/actions';
import { profileConsumerSelector } from 'store/profile/selectors';
import { CovidScreeningSubscriberSelector } from 'store/CovidScreening/selectors';
import { Subscriber } from 'store/CovidScreening/types';
import { RootState } from 'store/types';
import { CovidTitles } from 'lib/constants/covidScreening';
import { AnalyticsEvent } from 'services/AnalyticsService';

import { NavigationScreenProps } from 'screens/navigation';
import SusbscriberInformationForm from 'components/CovidTest/SubscriberInformation/Form';
import useNavigationAnalytics from 'hooks/useNavigationAnalytics';
import { useLanguageSwitcher } from 'lib/hooks/useLanguageSwitcher';
import { translations } from 'lib/constants/translations/screens/covidGuest/subscriberInfo';

import CovidGuestGuard from '../navigation/CovidGuestLeavingGuard';

export interface SubscriberInformationProps extends NavigationScreenProps {
  subscriberInfo: Subscriber;
  setSubscriberInfo: typeof covidActions.setSubscriber;
}

export function SubscriberInformation({
  setSubscriberInfo,
  history,
  subscriberInfo
}: SubscriberInformationProps) {
  const screenText = useLanguageSwitcher(translations);

  const initialValues = {
    subscriberFirstName: subscriberInfo?.subscriberFirstName ?? '',
    subscriberMiddleName: subscriberInfo?.subscriberMiddleName ?? '',
    subscriberLastName: subscriberInfo?.subscriberLastName ?? '',
    subscriberDateOfBirth: subscriberInfo?.subscriberDateOfBirth ?? '',
    subscriberSex: subscriberInfo?.subscriberSex?.toUpperCase() ?? '',
    subscriberAddressLine1: subscriberInfo?.subscriberAddressLine1 ?? '',
    subscriberAddressLine2: subscriberInfo?.subscriberAddressLine2 ?? '',
    subscriberCity: subscriberInfo?.subscriberCity ?? '',
    subscriberState: subscriberInfo?.subscriberState ?? '',
    subscriberZip: subscriberInfo?.subscriberZip ?? '',
    subscriberEmail: subscriberInfo?.subscriberEmail ?? '',
    subscriberCellNumber: subscriberInfo?.subscriberCellNumber ?? ''
  };

  interface FormValues {
    subscriberFirstName: string;
    subscriberMiddleName: string;
    subscriberLastName: string;
    subscriberDateOfBirth: string;
    subscriberSex: string;
    subscriberAddressLine1: string;
    subscriberAddressLine2: string;
    subscriberCity: string;
    subscriberState: string;
    subscriberZip: string;
    subscriberEmail: string;
    subscriberPhone: string;
    subscriberCellNumber: string;
  }

  const saveToStore = (values: FormValues) => {
    const updatedSubscriberdata = {
      subscriberFirstName: values?.subscriberFirstName,
      subscriberMiddleName: values?.subscriberMiddleName,
      subscriberLastName: values?.subscriberLastName,
      subscriberDateOfBirth: dayjs(values?.subscriberDateOfBirth).format('MM/DD/YYYY'),
      subscriberSex: values?.subscriberSex?.toUpperCase(),
      subscriberAddressLine1: values?.subscriberAddressLine1,
      subscriberAddressLine2: values?.subscriberAddressLine2,
      subscriberCity: values?.subscriberCity,
      subscriberState: values?.subscriberState,
      subscriberZip: values?.subscriberZip,
      subscriberEmail: values?.subscriberEmail,
      subscriberCellNumber: values?.subscriberCellNumber
    };

    setSubscriberInfo(updatedSubscriberdata);
  };

  const covidScreeningNextBtn = useNavigationAnalytics(AnalyticsEvent.CovidNext, {
    title: CovidTitles.SUBSCRIBER_INFO,
    authentication_type: AuthType.UNAUTHENTICATED
  });

  const covidScreeningPreviousBtn = useNavigationAnalytics(AnalyticsEvent.CovidPrev, {
    title: CovidTitles.SUBSCRIBER_INFO,
    authentication_type: AuthType.UNAUTHENTICATED
  });

  const handleSubmit = (values: FormValues) => {
    saveToStore(values);
    covidScreeningNextBtn();
    history.push('/guest-home/covid-screen/guest-covid-insurance-upload');
  };

  const onCancelClick = (values: FormValues) => {
    saveToStore(values);
    covidScreeningPreviousBtn();
    history.goBack();
  };

  return (
    <>
      <CovidGuestGuard />
      <MuiBox pl={6} my={2}>
        <MuiTypography data-testid="guest-subscriber-title" variant="h6">
          {screenText.title}
        </MuiTypography>
      </MuiBox>

      <MuiBox mx={3} mb={3}>
        <MuiDivider />
      </MuiBox>

      <Container maxWidth="md" data-testid="guest-subscriber-form-container">
        <SusbscriberInformationForm
          initialValues={initialValues}
          onClickNext={handleSubmit}
          onClickPrevious={onCancelClick}
        />
      </Container>
    </>
  );
}

const mapStateToProps = (state: RootState) => ({
  consumer: profileConsumerSelector(state),
  subscriberInfo: CovidScreeningSubscriberSelector(state)
});

const mapDispatchToProps = {
  setSubscriberInfo: covidActions.setSubscriber,
  setQuestionnaire: covidActions.setQuestionnaire
};

export default connect(mapStateToProps, mapDispatchToProps)(SubscriberInformation);
