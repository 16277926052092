import CollapsibleListItem from 'components/common/CollapsibleList/CollapsibleListItem';
import { ListItem } from 'components/UI/ListItem/ListItem';
import DataLoader from 'components/UI/DataLoader/DataLoader';
import dayjs from 'dayjs';
import Spinner from 'components/UI/Spinner/Spinner';
import FlexBox, { FlexBoxRow } from 'components/UI/Layout/FlexBox';
import { HEALTH_RECORDS_DRAWERS } from 'lib/constants/healthRecords';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { RootState, RootDispatch } from 'store/types';
import { updateSort, resetClinicNotesListFilters } from 'store/clinicNotes/actions';
import {
  clinicNotesListSelector,
  clinicNotesListFetchingSelector,
  clinicNotesListErrorSelector
} from 'store/clinicNotes/selectors';
import {
  ClinicNotesListItem,
  ClinicNotesListState,
  ClinicNotesSortBy
} from 'store/clinicNotes/types';
import { MuiBox, MuiTypography, MuiList, MuiPaper, MuiButton } from 'theme/material-ui';
import { AnalyticsEvent } from 'services/AnalyticsService';
import useNavigationAnalytics from 'hooks/useNavigationAnalytics';
import { IconSize, Spacing } from 'modules/styles/variables';

interface ClinicNotesListProps {
  clinicNotesList: ClinicNotesListState;
  loading: boolean;
  error: Error | null;
  currentUrl?: string;
  referringUrl?: string;
  dispatch: RootDispatch;
}

export const HealthRecordsClinicNotesList = ({
  clinicNotesList,
  loading,
  error,
  dispatch
}: ClinicNotesListProps) => {
  const slicedClinicNotes = clinicNotesList?.data?.slice(0, 3);
  const history = useHistory();
  const handleClinicNotesItemClicked = useNavigationAnalytics(
    AnalyticsEvent.ClinicNotesItemClicked
  );
  const handleViewAllClinicNotesClicked = useNavigationAnalytics(
    AnalyticsEvent.ViewAllClinicNotesClicked
  );

  const handleOnPress = (clinicNote: ClinicNotesListItem) => {
    handleClinicNotesItemClicked();
    history.push(`/u/health-record/clinic-notes-and-documents/${clinicNote?.description}`, {
      clinicNote
    });
  };

  const handleOnViewAll = () => {
    handleViewAllClinicNotesClicked();
    history.push('/u/health-record/clinic-notes-and-documents');
  };

  useEffect(() => {
    dispatch(updateSort(ClinicNotesSortBy.date));
    dispatch(resetClinicNotesListFilters());
  }, []);

  return (
    <CollapsibleListItem
      leftIcon="ClinicNotes"
      title={HEALTH_RECORDS_DRAWERS.ClinicNotes.title}
      subtitle={HEALTH_RECORDS_DRAWERS.ClinicNotes.subtitle(clinicNotesList?.data?.length || 0)}
      currentUrlForAnalytics={handleClinicNotesItemClicked?.currentUrl}
      referringUrlForAnalytics={handleClinicNotesItemClicked?.referringUrl}
      detailsPadding="0"
    >
      <MuiBox width="100%">
        <DataLoader
          loading={loading}
          data={clinicNotesList?.data}
          error={error}
          renderLoading={() => (
            <MuiBox p={5} display="flex" alignItems="center" justifyContent="center">
              <Spinner />
            </MuiBox>
          )}
          renderError={() => (
            <FlexBoxRow alignItems="center" justifyContent="center">
              <MuiTypography>
                There was an issue loading the clinic notes and documents.
              </MuiTypography>
            </FlexBoxRow>
          )}
          renderNoData={() => (
            <FlexBoxRow alignItems="center" justifyContent="center">
              <MuiTypography>No clinic notes or documents to show.</MuiTypography>
            </FlexBoxRow>
          )}
          renderData={() => (
            <>
              <MuiPaper elevation={2}>
                <MuiList>
                  {slicedClinicNotes?.map(item => (
                    <ListItem
                      accessibilityLabel={`Tap to view ${item.description}`}
                      accessible
                      title={item.noteType}
                      body={item.noteCategory}
                      secondaryBody={dayjs(item.indexDate).format('MM/DD/YYYY')}
                      icon="ChevronRight"
                      iconSize={IconSize.xmedium}
                      key={item.id}
                      onPress={() => handleOnPress(item)}
                    />
                  ))}
                  {clinicNotesList?.data?.length > slicedClinicNotes?.length ? (
                    <FlexBox justifyContent="flex-start" padding={Spacing.large}>
                      <MuiButton
                        color="primary"
                        variant="outlined"
                        onClick={() => handleOnViewAll()}
                        data-testid="view-all-clinic-notes"
                      >
                        View all
                      </MuiButton>
                    </FlexBox>
                  ) : null}
                </MuiList>
              </MuiPaper>
            </>
          )}
        />
      </MuiBox>
    </CollapsibleListItem>
  );
};

const mapStateToProps = (state: RootState) => ({
  clinicNotesList: clinicNotesListSelector(state),
  loading: clinicNotesListFetchingSelector(state),
  error: clinicNotesListErrorSelector(state)
});

export default connect(mapStateToProps)(HealthRecordsClinicNotesList);
