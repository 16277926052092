import React from 'react';
import { ProviderLocation } from 'store/findProvider/types';
import { MuiBox, MuiCardContent, MuiDivider, MuiGrid } from 'theme/material-ui';
import { SectionHeader } from 'components/FindProvider/common';
import { FontSize, Spacing, FontWeight } from 'modules/styles/variables';
import {
  AddressContainer,
  BottomSpacer,
  CardColMedia,
  CardColMediaLong,
  GridSpacer,
  HrBreaker,
  LargeCardText,
  MuiBoxLong,
  MuiRowCard,
  MuiRowCardLong,
  PaddedContainer,
  SmallText,
  TextContainer
} from 'screens/ProviderDetails/styled';
import { Icon } from 'components/Icon';
import { Color } from 'modules/styles/colors';

export interface Props {
  locations: ProviderLocation[];
}

const LocationDetails = ({ location }: { location: ProviderLocation }) => {
  return (
    <TextContainer>
      <LargeCardText>{location.name ? location.name : null}</LargeCardText>
      {location.address ? (
        <AddressContainer>
          <MuiGrid item>
            <SmallText>
              {location.address.addressLine1 ? `${location.address.addressLine1}, ` : null}
              {location.address.addressLine2 ? `${location.address.addressLine2}` : null}
            </SmallText>
            <br />
            <SmallText>
              {location.address.city ? `${location.address.city} ` : null}
              {location.address.postalCode ? `${location.address.postalCode}` : null}
            </SmallText>
          </MuiGrid>
        </AddressContainer>
      ) : null}
      <AddressContainer>
        <SmallText>
          {location.distanceToUser
            ? `${(
                <Icon name="location-on" color={Color.primary} size={15} />
              )}Distance: ${parseFloat(location.distanceToUser.toFixed(2)).toString()} miles`
            : null}
        </SmallText>
      </AddressContainer>
      <HrBreaker />
      <SmallText>{location.phoneNumber}</SmallText>
    </TextContainer>
  );
};

const ProviderLocations = ({ locations }: Props) => {
  return (
    <>
      {locations.length > 1 ? (
        // Multiple Location Cards
        <MuiGrid
          id="provider-details-locations"
          item
          xs={12}
          style={{ scrollMargin: Spacing.xLargeLarge }}
        >
          <SectionHeader variant="h5" fontWeight={FontWeight.bold} fontSize={FontSize.heading}>
            Locations
          </SectionHeader>
          <MuiDivider />
          <>
            <GridSpacer>
              <MuiGrid container alignItems="stretch">
                {locations.map(location => (
                  <BottomSpacer xs={12} sm={6} md={6} lg={4}>
                    <MuiRowCard>
                      <MuiBox>
                        {location.locationImageUrl ? (
                          <CardColMedia imageUrl={location.locationImageUrl} />
                        ) : null}
                        <MuiCardContent>
                          <PaddedContainer>
                            <LocationDetails location={location} />
                          </PaddedContainer>
                        </MuiCardContent>
                      </MuiBox>
                    </MuiRowCard>
                  </BottomSpacer>
                ))}
              </MuiGrid>
            </GridSpacer>
          </>
        </MuiGrid>
      ) : (
        // Single Location Card
        <MuiGrid
          id="provider-details-locations"
          item
          xs={9}
          style={{ scrollMargin: Spacing.xLargeLarge }}
        >
          <SectionHeader variant="h5" fontWeight={FontWeight.bold} fontSize={FontSize.heading}>
            Locations
          </SectionHeader>
          <MuiDivider />
          <>
            <MuiGrid container alignItems="stretch">
              {locations.map(location => (
                <BottomSpacer xs={12}>
                  <MuiRowCardLong>
                    <MuiBoxLong>
                      {location.locationImageUrl ? (
                        <CardColMediaLong imageUrl={location.locationImageUrl} />
                      ) : null}
                      <MuiCardContent>
                        <LocationDetails location={location} />
                      </MuiCardContent>
                    </MuiBoxLong>
                  </MuiRowCardLong>
                </BottomSpacer>
              ))}
            </MuiGrid>
          </>
        </MuiGrid>
      )}
    </>
  );
};

export default ProviderLocations;
