import React from 'react';
import { FlatList } from 'react-native';
import { VisitSummaryCard } from 'components/VisitSummaryCard/VisitSummaryCard';
import { CenteredSpinnerContainer } from 'components/UI/Spinner/SpinnerContainer';
import { VisitSummaryListProps } from './VisitSummaryList';
import Spinner from 'components/UI/Spinner/Spinner';
import VisitSummaryMessage from './VisitSummaryMessage';
import * as types from 'store/visitSummary/types';

interface VisitsListCardProps
  extends Pick<VisitSummaryListProps, 'loading' | 'hasVisits' | 'error'> {
  items: types.VisitSummary[];
  onItemPress: (item: types.VisitSummary) => void;
  onItemChecked: (item: types.VisitSummary) => void;
  eVisitHandler: (item: types.VisitSummary, callback: Function) => void;
  enableCheckbox?: boolean;
}

function VisitsListCard(props: VisitsListCardProps) {
  const {
    loading,
    items,
    hasVisits,
    error,
    onItemPress,
    onItemChecked,
    eVisitHandler,
    enableCheckbox
  } = props;
  let message = "We didn't find any past visits";

  if (hasVisits) message = 'No visits found with the selected filters';
  if (error) message = 'We are having trouble retrieving your records at this time';
  if (loading)
    return (
      <CenteredSpinnerContainer>
        <Spinner />
      </CenteredSpinnerContainer>
    );

  return (
    <FlatList
      data={items}
      ListEmptyComponent={() => <VisitSummaryMessage isSmallScreen={false} message={message} />}
      keyExtractor={(item: types.VisitSummary, idx) => `${item.id}${idx}`}
      renderItem={({ item }) => (
        <VisitSummaryCard
          onClick={() => (enableCheckbox ? onItemChecked(item) : eVisitHandler(item, onItemPress))}
          onChecked={() => onItemChecked(item)}
          visit={item}
          enableCheckbox={enableCheckbox}
        />
      )}
    />
  );
}

export default VisitsListCard;
