import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  downloadSocialHistory,
  fetchSocialHistory,
  replyToSocialHistory
} from 'store/medicalHistory/socialHistory/actions';
import {
  socialHistoryDataSelector,
  socialHistoryDataByCategorySelector,
  socialHistoryDownloadingSelector,
  socialHistoryErrorSelector,
  socialHistoryLoadingSelector,
  socialHistoryDownloadErrorSelector
} from 'store/medicalHistory/socialHistory/selectors';
import { MedicalHistoryData, Category, SocialHistoryCategories } from 'store/medicalHistory/types';
import { RootDispatch, RootState } from 'store/types';
import Banner from 'screens/MedicalHistory/Banner';
import { MuiContainer, MuiBox, MuiPaper, MuiGrid } from 'theme/material-ui';
import capitalize from 'lodash/capitalize';
import { formatDate } from 'modules/utils/DateUtils';
import { unCamelCase, convertToLowerKabobCase } from 'modules/utils/StringUtils';
import { MedicalHistoryListItem } from 'components/medicalHistory/MedicalHistoryListItem';
import { Color } from 'modules/styles/colors';
import { AnalyticsEvent } from 'services/AnalyticsService';
import useNavigationAnalytics, { NavigationAnalyticsCallback } from 'hooks/useNavigationAnalytics';
import { MedicalHistoryMessage } from '../common';
import DownloadErrorSnackbar from 'components/DownloadErrorSnackbar/DownloadErrorSnackbar';

export interface Props {
  error: null | Error;
  loading: boolean;
  socialHistory: MedicalHistoryData[];
  socialHistoryArrays: SocialHistoryCategories[];
  dispatch: RootDispatch;
  isDownloadingSocialHistory: boolean;
  downloadError: string | null;
  currentUrl?: string;
  referringUrl?: string;
}

const orEmpty = (str: string | undefined, empty = 'N/A') => str || empty;

const renderData = (category: string, socialHistory: MedicalHistoryData[]) => {
  return (
    <>
      {socialHistory.map(item => (
        <MuiBox mb={1} key={item.id}>
          <MuiPaper elevation={2}>
            <MedicalHistoryListItem
              title={capitalize(item.name)}
              itemBackgroundColor={Color.white}
              hideInfoIcon
            >
              <MuiBox p={3}>
                <MuiGrid container spacing={3} aria-label={`${category} history list`}>
                  <MuiGrid item xs={3}>
                    <MuiBox>
                      <MuiBox
                        fontWeight="fontWeightBold"
                        textAlign="left"
                        data-testid={convertToLowerKabobCase(item.name, '-name')}
                      >
                        Name:
                      </MuiBox>
                      <MuiBox
                        fontWeight="fontWeightBold"
                        textAlign="left"
                        data-testid={convertToLowerKabobCase(item.name, '-description')}
                      >
                        Description:
                      </MuiBox>
                      <MuiBox
                        fontWeight="fontWeightBold"
                        textAlign="left"
                        data-testid={convertToLowerKabobCase(item.name, '-status')}
                      >
                        Status:
                      </MuiBox>
                      <MuiBox
                        fontWeight="fontWeightBold"
                        textAlign="left"
                        data-testid={convertToLowerKabobCase(item.name, '-date-issued')}
                      >
                        Date issued:
                      </MuiBox>
                      <MuiBox
                        fontWeight="fontWeightBold"
                        textAlign="left"
                        data-testid={convertToLowerKabobCase(item.name, '-notes')}
                      >
                        Notes:
                      </MuiBox>
                    </MuiBox>
                  </MuiGrid>
                  <MuiGrid item xs={6}>
                    <MuiBox>
                      <MuiBox style={{ minHeight: '19px' }}>{orEmpty(item.name)}</MuiBox>
                      <MuiBox style={{ minHeight: '19px' }}>{orEmpty(item.description)}</MuiBox>
                      <MuiBox style={{ minHeight: '19px' }}>{orEmpty(item.status)}</MuiBox>
                      <MuiBox style={{ minHeight: '19px' }}>{formatDate(item.dateIssued)}</MuiBox>
                      <MuiBox>{orEmpty(item.text)}</MuiBox>
                    </MuiBox>
                  </MuiGrid>
                </MuiGrid>
              </MuiBox>
            </MedicalHistoryListItem>
          </MuiPaper>
        </MuiBox>
      ))}
    </>
  );
};

export const SocialHistory = (props: Props) => {
  const {
    error,
    loading,
    socialHistory,
    socialHistoryArrays,
    dispatch,
    isDownloadingSocialHistory,
    downloadError
  } = props;

  useEffect(() => {
    dispatch(fetchSocialHistory());
  }, [fetchSocialHistory]);

  const shareClicked = useNavigationAnalytics(AnalyticsEvent.ShareSocialHistoryClicked);
  const downloadClicked = useNavigationAnalytics(AnalyticsEvent.DownloadSocialHistoryClicked);
  const alcoholClicked = useNavigationAnalytics(AnalyticsEvent.SocialHistoryAlcoholClicked);
  const substanceClicked = useNavigationAnalytics(AnalyticsEvent.SocialHistorySubstanceUseClicked);
  const tobaccoClicked = useNavigationAnalytics(AnalyticsEvent.SocialHistoryTobaccoClicked);
  const sexualGenClicked = useNavigationAnalytics(AnalyticsEvent.SocialHistorySexualGenderClicked);
  const homeEnvClicked = useNavigationAnalytics(AnalyticsEvent.SocialHistoryHomeEnvironmentClicked);
  const employSchoolClicked = useNavigationAnalytics(
    AnalyticsEvent.SocialHistoryEmploymentSchoolClicked
  );
  const otherClicked = useNavigationAnalytics(AnalyticsEvent.SocialHistoryOtherClicked);

  const mapAnalyticsToCategory = (category: Category): NavigationAnalyticsCallback => {
    switch (category) {
      case Category.ALCOHOL:
        return alcoholClicked;
      case Category.SUBSTANCES_USE:
        return substanceClicked;
      case Category.TOBACCO:
        return tobaccoClicked;
      case Category.SEXUAL_GENDER:
        return sexualGenClicked;
      case Category.HOME_ENV:
        return homeEnvClicked;
      case Category.EMPLOYMENT_SCHOOL:
        return employSchoolClicked;
      case Category.OTHER:
        return otherClicked;
      default:
        return () => {};
    }
  };

  const handleShareSocialHistory = () => {
    shareClicked();
    dispatch(replyToSocialHistory(socialHistory, shareClicked.currentUrl));
  };

  const handleDownloadSocialHistory = () => {
    downloadClicked();
    dispatch(downloadSocialHistory());
  };

  return (
    <>
      <DownloadErrorSnackbar hasError={!!downloadError} onRetry={handleDownloadSocialHistory} />
      <Banner
        onDownloadPress={handleDownloadSocialHistory}
        onTransmitPress={handleShareSocialHistory}
        isDownloading={isDownloadingSocialHistory}
      />
      <MuiContainer maxWidth="lg">
        <MedicalHistoryMessage
          type="social history"
          loading={loading}
          data={socialHistoryArrays}
          error={error}
        />
        <MuiBox width="100%" pt={3}>
          {socialHistoryArrays.map(item => (
            <MuiBox mb={1} key={item.category}>
              <MuiPaper elevation={2}>
                <MedicalHistoryListItem
                  title={unCamelCase(item.category)}
                  itemBackgroundColor={Color.grayHue4}
                  onExpand={() => mapAnalyticsToCategory(item?.category)()}
                  hideInfoIcon
                >
                  {renderData(item.category, item.socialHistory)}
                </MedicalHistoryListItem>
              </MuiPaper>
            </MuiBox>
          ))}
        </MuiBox>
      </MuiContainer>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  error: socialHistoryErrorSelector(state),
  loading: socialHistoryLoadingSelector(state),
  socialHistory: socialHistoryDataSelector(state),
  socialHistoryArrays: socialHistoryDataByCategorySelector(state),
  isDownloadingSocialHistory: socialHistoryDownloadingSelector(state),
  downloadError: socialHistoryDownloadErrorSelector(state)
});

export default connect(mapStateToProps)(SocialHistory);
