import React from 'react';

const st0 = {
  fill: 'none',
  strokeWidth: 2,
  strokeLinecap: 'round',
  strokeMiterlimit: 10
} as const;

const st1 = {
  fill: '#AAAAAA'
} as const;

export default {
  svg: (
    <g fill="none">
      <polyline {...st0} points="1.2,10.6 15,2.5 28.8,10.6 " />
      <polyline {...st0} points="25.6,12.9 25.6,27.1 4.4,27.1 4.4,12.9 " />
      <circle {...st1} cx="16.6" cy="19.8" r="0.8" />
      <line {...st0} x1="7.9" y1="14.5" x2="10.9" y2="14.5" />
      <line {...st0} x1="7.9" y1="18.5" x2="10.9" y2="18.5" />
      <line {...st0} x1="7.9" y1="22.5" x2="10.9" y2="22.5" />
      <line {...st0} x1="19.1" y1="14.5" x2="22.1" y2="14.5" />
      <line {...st0} x1="19.1" y1="18.5" x2="22.1" y2="18.5" />
      <line {...st0} x1="19.1" y1="22.5" x2="22.1" y2="22.5" />
      <polyline {...st0} points="19.1,27.1 19.1,9.8 10.9,9.8 10.9,27.1 " />
    </g>
  ),
  viewBox: '0 0 33 33'
};
