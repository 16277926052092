import orderBy from 'lodash/orderBy';
import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';

import NoAppointmentCalendar from 'assets/FindProvider/No-Appointment-Calendar.jpg';

import Banner from 'components/UI/Banner/Banner';
import Spinner from 'components/UI/Spinner/Spinner';
import { MuiBox, MuiDivider, MuiMenuItem, MuiTypography } from 'theme/material-ui';

import { useProxySwitcherEffect } from 'hooks/useProxySwitcherEffect';

import { history } from 'lib/history';

import { FontSize, IconSize } from 'modules/styles/variables';

import analyticsService, { AnalyticsEvent } from 'services/AnalyticsService';

import * as accountSelectors from 'store/account/selectors';
import * as bookingActions from 'store/booking/actions';
import * as bookingSelectors from 'store/booking/selectors';
import * as bookingTypes from 'store/booking/types';
import { getProfileForInsurance } from 'store/profile/actions';
import { Consumer, Grantor, Insurance } from 'store/profile/types';
import * as routerSelectors from 'store/router/selectors';
import { RootDispatch, RootState } from 'store/types';

import { BannerSelect, BookNewAppointmentButton } from '../components/styled';
import AppointmentsList from './AppointmentsList';

export interface Props {
  dispatch: RootDispatch;
  upcoming: bookingTypes.BookedAppointment[];
  upcomingLoading: boolean;
  upcomingError: Error | null;
  consumers: (Consumer | Grantor)[];
  isImpersonating: boolean;
  asScreen?: boolean; // this screen is also used as a component w/o the banner.
  insurances: Insurance[];
  currentUrl?: string;
  referringUrl?: string;
  patientsLoading: boolean;
  patients: bookingTypes.Patient[];
  currentSelectedProfile: bookingTypes.Patient;
}

export const ManageAppointments = ({
  dispatch,
  upcoming,
  upcomingLoading,
  upcomingError,
  consumers,
  isImpersonating,
  asScreen = true,
  currentUrl,
  referringUrl,
  patients,
  currentSelectedProfile,
  patientsLoading
}: Props) => {
  const [bannerFilterBy, setBannerFilterBy] = useState('');

  useProxySwitcherEffect(() => {
    dispatch(getProfileForInsurance());
    dispatch(bookingActions.getUpcomingAppointments());
  }, []);

  useEffect(() => {
    const [consumer] = consumers;
    setBannerFilterBy(consumer.consumerId);
  }, [consumers, isImpersonating]);

  useEffect(() => {
    const fetchPatients = async () => {
      await dispatch(bookingActions.getPatients());
    };

    fetchPatients();
  }, []);

  const upcomingAppointments = useMemo(() => {
    let appts = upcoming;

    appts = upcoming.filter(appt => appt.consumerId === bannerFilterBy);

    return orderBy(appts, ['appointmentDate'], ['asc']);
  }, [upcoming, bannerFilterBy]);

  const appointmentsCount = (count: number) => {
    if (count > 0) {
      return count;
    }
    return null;
  };

  const handleBookNewAppointmentClick = () => {
    dispatch(bookingActions.setPatient(currentSelectedProfile));
    dispatch(bookingActions.validateSelectedPatientInsurance());
    history.push('/u/get-care-now/booking/specialty-or-my-doctor-select');

    analyticsService.logEvent(AnalyticsEvent.BookAppointmentStarted, {
      currentUrl,
      referringUrl
    });
  };

  return (
    <>
      {asScreen && (
        <Banner
          message="Manage Appointments"
          align="space-between"
          rightItem={
            !isImpersonating ? (
              <BannerSelect
                select
                variant="outlined"
                value={bannerFilterBy}
                onChange={e => setBannerFilterBy(e.target.value)}
                label="For"
                id="banner-select"
                InputLabelProps={{ shrink: true }}
              >
                {consumers.map(consumer => (
                  <MuiMenuItem
                    key={consumer.consumerId}
                    selected={bannerFilterBy === consumer.consumerId}
                    value={consumer.consumerId}
                  >
                    {consumer.displayName}
                  </MuiMenuItem>
                ))}
              </BannerSelect>
            ) : null
          }
        />
      )}
      <MuiBox width="100%" pt={2}>
        <MuiBox
          width="100%"
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
        >
          <BookNewAppointmentButton
            size="large"
            color="primary"
            variant="contained"
            disabled={patientsLoading}
            data-testid="book-new-appointment-button"
            onClick={() => handleBookNewAppointmentClick()}
            endIcon={patientsLoading ? <Spinner size={IconSize.small} /> : null}
          >
            Book an appointment
          </BookNewAppointmentButton>
        </MuiBox>
      </MuiBox>
      <MuiBox width="100%" my={2}>
        <MuiDivider />
      </MuiBox>
      <MuiBox width="100%" style={{ marginTop: asScreen ? '10px' : '0px' }}>
        {upcomingAppointments.length ? (
          <MuiBox pb={1} px={2}>
            <MuiTypography
              fontSize={FontSize.heading}
              gutterBottom
              component="h3"
              data-testid="upcoming-appts-label"
            >
              Upcoming Appointments ({appointmentsCount(upcomingAppointments.length)})
            </MuiTypography>
          </MuiBox>
        ) : (
          <MuiBox pt={3} textAlign="center">
            <img
              style={{ width: 180, height: 150 }}
              alt="No Appointment Calendar"
              src={NoAppointmentCalendar}
            />
          </MuiBox>
        )}

        <MuiBox pb={3} data-testid="no-upcoming-appts-label">
          <AppointmentsList
            appointments={upcomingAppointments}
            appointmentsLoading={upcomingLoading}
            appointmentsError={upcomingError}
          />
        </MuiBox>
      </MuiBox>
    </>
  );
};

const mapState = (state: RootState) => ({
  isImpersonating: accountSelectors.isImpersonatingSelector(state),
  consumers: accountSelectors.currentConsumerAndGrantorsSelector(state),
  patients: bookingSelectors.patientsDataSelector(state),
  patientsLoading: bookingSelectors.patientsFetchingSelector(state),
  upcoming: bookingSelectors.upcomingAppointmentsDataSelector(state),
  upcomingLoading: bookingSelectors.upcomingAppointmentsFetchingSelector(state),
  upcomingError: bookingSelectors.upcomingAppointmentsErrorSelector(state),
  currentUrl: routerSelectors.currentLocationPathNameSelector(state),
  referringUrl: routerSelectors.previousLocationPathNameSelector(state),
  currentSelectedProfile: accountSelectors.currentAccountConsumerSelector(state)
});

export default connect(mapState)(ManageAppointments);
