import React, { useState } from 'react';
import { connect } from 'react-redux';
import { RootState } from 'store/types';
import { getVisitSummaryBinaryDocument } from 'store/visitSummary/actions.web';
import {
  visitSummaryDocumentDataSelector,
  visitSummaryDocumentErrorSelector,
  visitSummaryDocumentLoadingSelector
} from 'store/visitSummary/selectors';
import { BinaryDocument, Document } from 'store/visitSummary/types';
import { oc } from 'ts-optchain';
import { MuiGrid, MuiTypography, MuiBox, MuiButton } from 'theme/material-ui';
import Svg from 'components/UI/Svg/Svg';
import { IconSize } from 'modules/styles/variables';
import { Color } from 'modules/styles/colors';
import Spinner from 'components/UI/Spinner/Spinner';
import { Alert } from 'components/Alert';
import noop from 'lodash/noop';
import { Action } from 'modules/types/common';
import { v4 } from 'uuid';

export interface Props {
  document: Document;
  isFetching: boolean;
  error: Error | null;
  data: BinaryDocument;
  dispatch: Promise<Action>;
}

export const RenderDocument = (data: BinaryDocument) => {
  const content = oc(data).content();
  const contentType = oc(data).contentType();
  if (content && contentType) {
    if (contentType.includes('application/pdf')) {
      const pdfWindow = window.open('about:blank', v4());
      if (pdfWindow) {
        pdfWindow.document.write(
          `<iframe width='100%' height='100%' src='data:application/pdf;base64, ${encodeURI(
            content
          )}'></iframe>`
        );
      }
    }
  }
};

export const BinaryDocumentHandler = (props: Props) => {
  const { document, dispatch } = props;
  const [isLoading, setIsLoading] = useState(false);
  return (
    <MuiGrid item xs={12} key={document.id}>
      <MuiButton
        disabled={isLoading}
        fullWidth
        onClick={event => {
          event.preventDefault();
          setIsLoading(true);
          ((dispatch(
            getVisitSummaryBinaryDocument('application/json+fhir', document.links[0].documentId)
          ) as unknown) as Promise<Action>)
            .then((data: Action<BinaryDocument>) => {
              setIsLoading(false);
              RenderDocument(data.payload?.data as BinaryDocument);
            })
            .catch(() => {
              return Alert.alert(
                "We're sorry.",
                'There was an issue loading your information. If the problem persists please contact support',
                [{ text: 'OK', onPress: () => noop }]
              );
            });
        }}
      >
        {isLoading ? (
          <MuiBox p={3} flex={1} alignItems="center" justifyContent="center">
            <Spinner />
          </MuiBox>
        ) : (
          <>
            <MuiBox p={3} whiteSpace="pre-line">
              <MuiTypography>
                {document.description ? document.description : 'Document'}
              </MuiTypography>
            </MuiBox>
            <MuiBox display="flex" flex={1} flexDirection="row-reverse">
              <Svg set="assets" name="ChevronRight" size={IconSize.xmedium} fill={Color.black} />
            </MuiBox>
          </>
        )}
      </MuiButton>
    </MuiGrid>
  );
};

const mapStateToProps = (state: RootState) => ({
  isFetching: visitSummaryDocumentLoadingSelector(state),
  error: visitSummaryDocumentErrorSelector(state),
  data: visitSummaryDocumentDataSelector(state)
});

export default connect(mapStateToProps)(BinaryDocumentHandler);
