import React from 'react';
import styled, { css } from 'styled-components';
import { dfdDefaultTheme } from 'theme/material-ui';
import { Color } from 'modules/styles/colors';
import {
  ButtonBack,
  ButtonNext,
  Slider as PRCSlider,
  Slide as PRSlide,
  CarouselProvider as PRCarouselProvider
} from 'pure-react-carousel';

const buttonStyles = css`
  background: none;
  border: 0;
  color: ${Color.white};
  height: 56px;
  width: 56px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`;

export const Slider = styled(({ sliderHeight, ...rest }) => <PRCSlider {...rest} />)`
  height: ${props => `${props.sliderHeight}px`};
  width: 100%;
`;

export const Slide = styled(PRSlide)`
  padding: 0 ${dfdDefaultTheme.spacing(1)}px;

  & .carousel__inner-slide {
    padding: 0 ${dfdDefaultTheme.spacing(2)}px;
  }
`;

export const BackButton = styled(ButtonBack)`
  ${buttonStyles};
  left: -${dfdDefaultTheme.spacing(3)}px;
`;

export const ForwardButton = styled(ButtonNext)`
  ${buttonStyles}
  right: -${dfdDefaultTheme.spacing(3)}px;
`;

export const CarouselProvider = styled(PRCarouselProvider)`
  padding: 0 ${dfdDefaultTheme.spacing(2)}px;
`;
