import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { FontSize, Spacing } from 'modules/styles/variables';
import { capitalizeEachWord } from 'modules/utils/StringUtils';

import * as accountActions from 'store/account/actions';
import { getPMMCToken } from 'store/cost/actions';
import { avatarDataMapSelector } from 'store/consumerPreferences/selectors';
import { profileIsFetchingSelector } from 'store/profile/selectors';
import { MapAvatar } from 'store/consumerPreferences/types';
import { Consumer } from 'store/profile/types';
import {
  accountLoadingSelector,
  currentAccountConsumerSelector,
  otherAccountConsumersNeedActivationSelector,
  otherActiveAccountsSelector
} from 'store/account/selectors';
import {
  currentLocationPathNameSelector,
  previousLocationPathNameSelector
} from 'store/router/selectors';
import { RootState } from 'store/types';

import { CursorMuiBox, MuiBox, MuiButton, MuiGrid, MuiTypography } from 'theme/material-ui';
import { ExpandedView as translations } from 'lib/constants/translations/components/index';
import { useLanguageSwitcher } from 'lib/hooks/useLanguageSwitcher';

import { Alert } from 'components/Alert';
import { ResetAccountAccessModal } from 'components/ResetAccountAccessModal';
import UserAvatar from 'components/UI/Avatar';
import { SpinnerModal } from 'components/UI/Spinner/SpinnerModal';

import { StyledMuiContainer } from './styled';
import { Color } from 'modules/styles/colors';
import { isValidEmail } from 'lib/utils';

export interface Props {
  avatarsMap: MapAvatar;
  consumer: Consumer;
  shouldRefreshUserList: boolean;
  users: Consumer[];
  switchAccount: typeof accountActions.switchAccount;
  fetchPMMCToken: typeof getPMMCToken;
  isLoading: boolean;
  onUserMenuClose: () => void;
  currentUrl: string;
  referringUrl: string;
}

export function SwitchPerson({
  avatarsMap,
  consumer,
  users,
  shouldRefreshUserList,
  switchAccount,
  isLoading,
  onUserMenuClose,
  currentUrl,
  referringUrl,
  fetchPMMCToken
}: Props) {
  const [consumerId, setConsumerId] = useState(users.length ? users[0].consumerId : '');
  const [resetAccountModalOpen, setResetAccountModalOpen] = useState(false);
  const screenText = useLanguageSwitcher(translations);
  const history = useHistory();

  useEffect(() => {
    // This updates the selection if account was switched from somewhere else besides this UI
    if (consumer?.consumerId !== consumerId) {
      setConsumerId(consumer?.consumerId);
    }
  }, [consumer?.consumerId]);

  const handleSwitchAccountError = () => {
    Alert.alert(
      'Failed to switch account. Please try signing out and signing in again to switch to this account.'
    );
  };

  const handleUserClick = async (consumerId: string) => {
    setConsumerId(consumerId);
    const response = await switchAccount(consumerId, currentUrl, referringUrl);

    if (response.error) {
      handleSwitchAccountError();
    } else {
      if (consumer.emails?.length) {
        const email = consumer.emails[0].value;
        if (isValidEmail(email)) {
          fetchPMMCToken();
        }
      }
      onUserMenuClose();
      history.replace('/u/dashboard');
    }
  };

  const handleResetUserAccount = () => {
    setResetAccountModalOpen(true);
  };

  const handleResetUserAccountClosed = () => {
    setResetAccountModalOpen(false);
  };

  return (
    <>
      <SpinnerModal isLoading={isLoading} />
      {users.length ? (
        <StyledMuiContainer maxWidth="sm">
          <MuiBox mb={2}>
            <MuiTypography>{screenText.SwitchPerson}</MuiTypography>
          </MuiBox>
          <MuiGrid container item xs spacing={2} justify="center" alignItems="center">
            {users.map(user => (
              <MuiGrid key={user.consumerId} item lg={users.length < 4 ? 4 : 3} sm={6} xs={6}>
                <CursorMuiBox
                  onClick={e => {
                    e.stopPropagation();
                    handleUserClick(user.consumerId);
                  }}
                  data-testid="proxy-user"
                >
                  <UserAvatar
                    avatarUrl={avatarsMap[user.consumerId]}
                    fallback={`${user.firstName} ${user.lastName}`}
                    size={60}
                    initialsFontSize={FontSize.largeHeading}
                    borderColor={Color.secondary}
                    initialsFontColor={Color.secondary}
                    backgroundColor={Color.white}
                  />
                  <MuiBox pt={1}>
                    <MuiTypography align="center" noWrap>
                      {capitalizeEachWord(user.firstName)}
                    </MuiTypography>
                  </MuiBox>
                </CursorMuiBox>
              </MuiGrid>
            ))}
          </MuiGrid>
        </StyledMuiContainer>
      ) : null}
      <MuiBox
        display="flex"
        flexDirection="row"
        justifyContent={shouldRefreshUserList ? 'center' : 'flex-end'}
        paddingX={shouldRefreshUserList ? Spacing.medium : Spacing.xSmall}
        paddingY={shouldRefreshUserList ? Spacing.xSmall : 1}
      >
        <MuiButton
          color="primary"
          fullWidth={shouldRefreshUserList}
          size={shouldRefreshUserList ? 'base' : 'small'}
          variant={shouldRefreshUserList ? 'contained' : 'text'}
          onClick={e => {
            e.stopPropagation();
            handleResetUserAccount();
          }}
        >
          {screenText.RefreshUserList}
        </MuiButton>
      </MuiBox>
      <ResetAccountAccessModal
        open={resetAccountModalOpen}
        onClose={handleResetUserAccountClosed}
      />
    </>
  );
}

const mapStateToProps = (state: RootState) => ({
  avatarsMap: avatarDataMapSelector(state),
  users: otherActiveAccountsSelector(state),
  shouldRefreshUserList: otherAccountConsumersNeedActivationSelector(state),
  consumer: currentAccountConsumerSelector(state),
  isLoading: accountLoadingSelector(state) || profileIsFetchingSelector(state),
  currentUrl: currentLocationPathNameSelector(state),
  referringUrl: previousLocationPathNameSelector(state)
});

const mapDispatchToProps = {
  switchAccount: accountActions.switchAccount,
  fetchPMMCToken: getPMMCToken
};

export default connect(mapStateToProps, mapDispatchToProps)(SwitchPerson);
