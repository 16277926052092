import { Alert } from 'components/Alert';
import { Divider } from 'components/UI/Divider/styled';
import Box from 'components/UI/Layout/Box';
import FlexBox from 'components/UI/Layout/FlexBox';
import Svg from 'components/UI/Svg/Svg';
import Typography from 'components/UI/Typography';
import CollapsibleListItem from 'components/common/CollapsibleList/CollapsibleListItem';
import dayjs from 'dayjs';
import noop from 'lodash/noop';
import { Color } from 'modules/styles/colors';
import { IconSize, Spacing } from 'modules/styles/variables';
import { Nullable } from 'modules/types/common';
import React, { ComponentType } from 'react';
import { FlatList, TouchableOpacity } from 'react-native';
import { connect } from 'react-redux';
import { SpacedRowFlexBoxVisits } from 'screens/Billing/styled';
import { formatTestResultData } from 'screens/TestResults/utils';
import { Claims as EncounterClaims } from 'store/billing/types';
import { selectHealthMemberServices } from 'modules/constants/phoneNumbers';
import * as AccountActions from 'store/account/actions';
import { getBillingVisitSummarySelector } from 'store/billing/selectors';
import {
  currentConsumerHasSHInsurancesSelector,
  selectHealthFacetsInsuranceSelector
} from 'store/profile/selectors';
import { RootState } from 'store/types';
import { downloadEob, getEobDocuments } from 'store/visitSummary/eob/actions';
import {
  eobDocumentsDataSelector,
  eobDocumentsErrorSelector,
  eobDocumentsFetchingSelector,
  eobDownloadingSelector
} from 'store/visitSummary/eob/selectors';
import { EobDocumentsItem } from 'store/visitSummary/eob/types';
import { MuiBox, MuiButton, MuiPaper } from 'theme/material-ui';
import { v4 } from 'uuid';
import { BillingVisitSummaryState } from 'store/billing/reducers';

interface Props {
  date: string;
  isImpersonating: boolean;
  getEobDocument: typeof getEobDocuments;
  downloadEobPdf: typeof downloadEob;
  documentData: Nullable<EobDocumentsItem>;
  isFetching: boolean;
  documentError?: Error;
  eobDownloading: boolean;
  returnToMainAccount: typeof AccountActions.returnToMain;
  subscriberId: string;
  billingVisitSummaryResult: BillingVisitSummaryState;
  hasSelectHealthInsurance: boolean;
}

export const TITLE = 'Explanation of Benefits';

export const ClaimsComponent = (props: Props) => {
  const {
    date,
    documentData,
    isFetching,
    documentError,
    eobDownloading,
    getEobDocument,
    downloadEobPdf,
    billingVisitSummaryResult,
    hasSelectHealthInsurance
  } = props;
  const isDateGreater = dayjs(date).isAfter(dayjs('2021-06-01'));

  const [showPDF, setShowPDF] = React.useState(false);
  const [testPDF, setTestPDF] = React.useState(true);

  const claimLoading = billingVisitSummaryResult?.isFetching;
  const claimData = billingVisitSummaryResult?.data;
  const claims = claimData?.claims || [];
  const validClaims = claims.some((claim: EncounterClaims) => claim.cernerClaimNumber);

  if (showPDF && documentData) {
    setShowPDF(false);

    const data = formatTestResultData(documentData);
    const content = data?.content;
    const contentType = data?.contentType;

    if (content && contentType) {
      if (contentType.includes('application/pdf')) {
        const pdfWindow = window.open('about:blank', v4());
        if (pdfWindow) {
          pdfWindow.document.write(
            `<iframe width='100%' height='100%' src='data:application/pdf;base64, ${encodeURI(
              content
            )}'></iframe>`
          );
        }
      }
    }
  }
  if (showPDF && documentError) {
    Alert.alert(
      "We're sorry.",
      'There was an issue loading your information. If the problem persists please contact support',
      [{ text: 'OK', onPress: () => noop }]
    );
    setShowPDF(false);
  }

  const viewEOB = (item: EncounterClaims) => {
    if (item.cernerClaimNumber) {
      setShowPDF(true);
      getEobDocument(item.cernerClaimNumber, item.planSubscriberId);
    }
  };

  const handleDownloadEob = (item: EncounterClaims) => {
    if (item.cernerClaimNumber) {
      downloadEobPdf(item.cernerClaimNumber, item.planSubscriberId);
    }
  };

  const renderClaim = (eachClaim: EncounterClaims) => {
    return (
      <>
        <SpacedRowFlexBoxVisits
          flexDirection="row"
          justifyContent="space-between"
          style={{ margin: 0, padding: Spacing.medium }}
        >
          <TouchableOpacity accessible onPress={() => viewEOB(eachClaim)}>
            <FlexBox justifyContent="space-between">
              <FlexBox flex={2}>
                <Typography color={Color.link}>View and download EOB</Typography>
              </FlexBox>
              <FlexBox
                flex={1}
                justifyContent="space-between"
                alignItems="center"
                flexDirection="row"
              >
                <Typography color={Color.link}>
                  {`Posted: ${eachClaim.claimGeneratedDate}`}
                </Typography>
              </FlexBox>
            </FlexBox>
          </TouchableOpacity>
          <MuiButton variant="outlined" onClick={() => handleDownloadEob(eachClaim)}>
            <Svg
              set={eobDownloading ? 'downloading' : 'assets'}
              name="DownloadIcon"
              size={IconSize.base}
              color={Color.link}
            />
          </MuiButton>
        </SpacedRowFlexBoxVisits>
      </>
    );
  };

  // If the user does not have SH, skip.
  if (!hasSelectHealthInsurance) return null;

  // If the doc is too old, call the support team message.
  if (!isDateGreater) {
    return (
      <CollapsibleListItem title={TITLE}>
        <FlexBox
          data-testid="billing-section-is-inpersonating"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          spacing={Spacing.large}
        >
          <MuiPaper elevation={1}>
            <MuiBox p={3} display="flex" justifyContent="center">
              For more information on this bill, please call{' '}
              <MuiBox fontWeight="fontWeightBold" pl={1}>
                <a href={`tel:${selectHealthMemberServices}`}>
                  Select Health Member Services at 1-{selectHealthMemberServices}
                </a>
              </MuiBox>
            </MuiBox>
          </MuiPaper>
        </FlexBox>
      </CollapsibleListItem>
    );
  }
  if (validClaims && testPDF) {
    getEobDocument(claims[0].cernerClaimNumber, claims[0].planSubscriberId);
    setTestPDF(false);
  }
  if (!claims.length && !isFetching && !claimLoading) {
    return null;
  }
  if (!validClaims || !documentData) {
    return null;
  }

  return (
    <CollapsibleListItem title={TITLE}>
      <Box backgroundColor={Color.foreColor}>
        <MuiPaper elevation={2}>
          {claims.length ? (
            <MuiBox p={3}>
              <FlatList
                data={claims}
                keyExtractor={item => `${item.cernerClaimNumber}`}
                renderItem={({ item: eachClaim }) => renderClaim(eachClaim)}
                ItemSeparatorComponent={() => <Divider />}
              />
            </MuiBox>
          ) : null}
        </MuiPaper>
      </Box>
    </CollapsibleListItem>
  );
};

const mapStateToProps = (state: RootState) => ({
  documentData: eobDocumentsDataSelector(state),
  isFetching: eobDocumentsFetchingSelector(state),
  documentError: eobDocumentsErrorSelector(state),
  eobDownloading: eobDownloadingSelector(state),
  billingVisitSummaryResult: getBillingVisitSummarySelector(state),
  subscriberId: selectHealthFacetsInsuranceSelector(state)?.subscriberId || '',
  hasSelectHealthInsurance: currentConsumerHasSHInsurancesSelector(state)
});

const mapDispatchToProps = (dispatch: Function) => ({
  getEobDocument: (claimId: string, subscriberId: string | null) =>
    dispatch(getEobDocuments(claimId, subscriberId)),
  downloadEobPdf: (claimId: string | null, subscriberId: string | null) =>
    dispatch(downloadEob(claimId, subscriberId)),
  returnToMainAccount: () => dispatch(AccountActions.returnToMain())
});

export default connect(mapStateToProps, mapDispatchToProps)(ClaimsComponent as ComponentType);
