import React from 'react';

export default {
  svg: (
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <defs>
        <linearGradient id="grad" x1="100%" y1="50%" x2="0%" y2="50%">
          <stop offset="0" stopColor="#3283D8" />
          <stop offset="1" stopColor="#004394" />
        </linearGradient>
      </defs>
      <g>
        <path
          d="M36.5,21.5002415 C36.5,20.4331732 37.3196171,19.5680218 38.330524,19.5680218 L43.6697048,19.5680218
          L43.6697048,13.9322198 C43.6697048,12.8651514 44.4893219,12 45.5002288,12 C46.5106781,12 47.3307528,
          12.8651514 47.3307528,13.9322198 L47.3307528,19.5680218 L52.669476,19.5680218 C53.6803829,19.5680218
          54.5,20.4331732 54.5,21.5002415 C54.5,22.5673099 53.6803829,23.4324613 52.669476,23.4324613 L47.3307528,
          23.4324613 L47.3307528,29.0677802 C47.3307528,30.1348486 46.5106781,31 45.5002288,31 C44.4893219,31
          43.6697048,30.1348486 43.6697048,29.0677802 L43.6697048,23.4324613 L38.330524,23.4324613 C37.3196171,
          23.4324613 36.5,22.5673099 36.5,21.5002415 L36.5,21.5002415 Z M89.2496084,68.9417925 C89.764399,69.8426177
          89.459993,70.9955043 88.5695821,71.5165884 L46.7384214,96 L2.56510291,74.8089349 C1.63559397,74.3637049
          1.23949376,73.239558 1.67981197,72.2991567 C2.12013018,71.3587555 3.2297693,70.9563995 4.15927824,71.403043
          L46.5666693,91.7469931 L86.7059096,68.2534527 C87.5958551,67.7323687 88.7348177,68.0404961 89.2496084,
          68.9417925 L89.2496084,68.9417925 Z M89.2496084,61.9415743 C89.764399,62.8423911 89.459993,63.995267
          88.5695821,64.5163461 L46.7384214,89 L2.56510291,67.8086619 C1.63559397,67.363436 1.23949376,66.2392996
          1.67981197,65.2989072 C2.12013018,64.3585147 3.2297693,63.9566336 4.15927824,64.4032729 L46.5666693,
          84.7465617 L86.7059096,61.253241 C87.5958551,60.7326329 88.7348177,61.0402864 89.2496084,61.9415743
          L89.2496084,61.9415743 Z M89.2496084,54.9418636 C89.764399,55.8426551 89.459993,56.9954986 88.5695821,
          57.5165631 L46.7384214,82 L2.56510291,60.8092575 C1.63559397,60.3640441 1.23949376,59.2399393 1.67981197,
          58.2995733 C2.12013018,57.3592073 3.2297693,56.9563952 4.15927824,57.4039642 L46.5666693,77.7466813
          L86.7059096,54.2535496 C87.5958551,53.732014 88.7348177,54.0410722 89.2496084,54.9418636 L89.2496084,
          54.9418636 Z M69.4865809,49.6117233 C68.2584217,49.0562799 68.1640516,48.1076479 69.275821,47.4933979
          C70.3880398,46.879148 72.2853278,46.831931 73.513487,47.3882329 C74.7416462,47.9441055 74.8360162,
          48.8927375 73.7237974,49.5069874 C72.612028,50.1208082 70.7151894,50.1680251 69.4865809,49.6117233
          L69.4865809,49.6117233 Z M46.3231317,66 L39.5,62.6738034 L45.6768683,59 L52.5,62.32668 L46.3231317,66
          Z M56.3229084,60 L49.5,56.6740331 L55.6770916,53 L62.5,56.3259669 L56.3229084,60 Z M35.3229084,61 L28.5,
          57.6738034 L34.6770916,54 L41.5,57.32668 L35.3229084,61 Z M22.6768683,48 L29.5,51.3257131 L23.3231317,55
          L16.5,51.6738034 L22.6768683,48 Z M45.0002305,4 C53.5467893,4 60.5,11.061779 60.5,19.7417848 C60.5,
          22.5742669 59.749186,25.3519811 58.3282163,27.777683 L44.9997695,49 L32.0441948,28.3796615 C30.3798674,
          25.8111887 29.5,22.823765 29.5,19.7417848 C29.5,11.061779 36.4532107,4 45.0002305,4 L45.0002305,4 Z
          M46.7071023,75 L90.5,49.3601506 L62.9834844,36.1559852 C62.0512437,35.7081379 60.9373187,36.1115315
          60.4950181,37.0554631 C60.0536516,37.9993947 60.451115,39.1277618 61.3833557,39.5746633 L82.4675968,
          49.6926074 L73.9046197,54.7064165 L56.5245878,46.3661471 C55.5914129,45.917354 54.477955,46.3207476
          54.0361215,47.265625 C53.5942879,48.2095566 53.9917513,49.3374508 54.9244591,49.7848252 L69.9748232,
          57.0071315 L46.5347592,70.7310268 L8.5324032,52.4941359 L33.7939797,37.7038249 L38.8923465,45.793924
          L32.9065761,42.9219317 L26.768546,46.5155872 L33.548309,49.7692191 L39.28187,46.4120195 L45.5002335,
          56.2802663 L62.1717437,29.815659 L62.2007011,29.7683679 C63.9951245,26.7261274 64.9432451,23.2402959
          64.9432451,19.6868379 C64.9432451,8.83162455 56.2210024,0 45.5002335,0 C34.7789976,0 26.0567549,8.83162455
          26.0567549,19.6868379 C26.0567549,23.5415406 27.1608717,27.2789611 29.2359949,30.4711146 L31.7819089,
          34.5116715 L0.5,52.8265928 L46.7071023,75 Z"
          fill="url(#grad)"
        />
      </g>
    </g>
  ),
  viewBox: '0 0 90 96'
};
