/* tslint:disable */

import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { AutocompleteRenderInputParams } from '@material-ui/lab/Autocomplete';
import { InsuranceCardData } from 'store/CovidScreening/types';
import { deleteQuotes } from 'screens/CovidScreening/utils';
import { InputProps } from 'screens/CovidScreening/types';
import { MuiTypography } from 'theme/material-ui';
import { FontSize } from 'modules/styles/variables';
import { convertToLowerKabobCase } from 'modules/utils/StringUtils';
import { useLanguageSwitcher } from 'lib/hooks/useLanguageSwitcher';
import { translations } from 'lib/constants/translations/screens/covidGuest/covidInsuranceDropDown';

interface VirtualizedAutoCompleteProps {
  InsuranceList: Array<InsuranceCardData>;
  handleInsurancePress: Function;
  valueSelected: string | null;
  cleanStatesData: Function;
}

const filterOptions = (options: InsuranceCardData[], { inputValue }: { inputValue: string }) => {
  return options.filter((item: InsuranceCardData) => {
    return (
      item.plan_name.toLowerCase().startsWith(inputValue.toLowerCase()) ||
      item.street_address.toLowerCase().startsWith(inputValue.toLowerCase())
    );
  });
};

export default function VirtualizedAutocomplete({
  InsuranceList,
  handleInsurancePress,
  cleanStatesData,
  valueSelected,
  ...props
}: VirtualizedAutoCompleteProps) {
  const [sortedInsurances, setSortedInsurances] = useState(InsuranceList);

  const screenText = useLanguageSwitcher(translations);

  useEffect(() => {
    const sortedList = [...InsuranceList];

    sortedList.sort((a, b) => a.plan_name.localeCompare(b.plan_name));

    setSortedInsurances(sortedList);
  }, [InsuranceList]);

  const handleSelect = (e, value) => {
    if (!value) cleanStatesData(false);
    handleInsurancePress(value);
  };

  // Todo: Create own component to display the list and increase the performance.
  return (
    <Autocomplete
      disableListWrap
      filterOptions={filterOptions}
      getOptionLabel={(option: InsuranceCardData) => JSON.stringify(option)}
      id="virtualize-demo"
      limitTags={1}
      ListboxProps={{ handleinsurancepress: handleInsurancePress }}
      onChange={handleSelect}
      options={sortedInsurances}
      value={valueSelected}
      renderOption={option => (
        <MuiTypography data-testid={convertToLowerKabobCase(option?.plan_name)}>
          <MuiTypography display="inline">{option?.plan_name}</MuiTypography>
          <MuiTypography fontSize={FontSize.small}>
            {`${option?.street_address}, ${option?.city}, ${option.state}, ${option?.zipcode?.slice(
              0,
              5
            )}`}
          </MuiTypography>{' '}
        </MuiTypography>
      )}
      renderInput={(params: AutocompleteRenderInputParams) => {
        params.inputProps.value = deleteQuotes(params.inputProps as InputProps);
        return (
          <TextField
            {...params}
            variant="outlined"
            label={screenText.selectionBox}
            data-testid="insurance-search"
          />
        );
      }}
      {...props}
    />
  );
}
