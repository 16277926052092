import React, { useState } from 'react';
import { useHistory } from 'react-router';
import isArray from 'lodash/isArray';
import { Color } from 'modules/styles/colors';
import { FontSize, FontWeight, Spacing } from 'modules/styles/variables';
import {
  MuiContainer,
  MuiBox,
  MuiButton,
  MuiTypography,
  MuiDivider,
  MuiGrid
} from 'theme/material-ui';

import analyticsService, { AmplitudeEventData, AnalyticsEvent } from 'services/AnalyticsService';
import { eVisitOverviewScreen } from 'lib/triage/constants';

import FlexBox from 'components/UI/Layout/FlexBox';
import { Svg } from 'components/UI/Svg';

import { ConditionDefinitions } from './ConditionDefinitions';
import { EVISITS_ROUTES } from './constants';

interface Item {
  importantText: string;
  regularText: string;
}

interface LineProps {
  item: Item;
  index: number;
}

const Line = ({ item, index }: LineProps) => (
  <MuiBox flexDirection="row" component="p">
    {item.importantText && (
      <MuiTypography component="span" fontWeight={FontWeight.semibold}>
        {item.importantText && `${index + 1}. `}
      </MuiTypography>
    )}

    <MuiTypography component="span" fontWeight={FontWeight.semibold}>
      {item.importantText}
      {item.importantText && ' '}
      <MuiTypography component="span">
        {isArray(item.regularText)
          ? item.regularText.map(ele => {
              if (ele.type === 'text') return ele.value;
              if (ele.type === 'link')
                return (
                  // eslint-disable-next-line react/jsx-no-target-blank
                  <a href={ele.link} target="_blank">
                    {ele.value}
                  </a>
                );
              return null;
            })
          : item.regularText}
      </MuiTypography>
    </MuiTypography>
  </MuiBox>
);

const { howItWorksItems, sectionTitle, button, card, disclosureItems } = eVisitOverviewScreen;

const InfoItems = !!howItWorksItems.length
  ? howItWorksItems.map((item, index) => <Line key={item.key} item={item} index={index} />)
  : null;

export const EVisitOverview = () => {
  const [expandedAccordions, setExpandedAccordions] = useState<string[]>([]);
  const history = useHistory();

  const onStartVisit = () => {
    const data: AmplitudeEventData = {
      conditions_treated_with_e_visits_viewed: expandedAccordions
    };
    analyticsService.logEvent(AnalyticsEvent.EVisitStart, data);

    history.push(EVISITS_ROUTES.STATE_SELECTOR);
  };

  const onExpandAccordion = (accordionSelected: string) => {
    setExpandedAccordions(prevState => [...prevState, accordionSelected]);
  };

  return (
    <MuiContainer data-testid="e-visit-overview">
      <MuiBox paddingLeft={0} marginTop={Spacing.medium} marginBottom={2}>
        <MuiTypography fontSize={FontSize.largeHeading} data-testid="how-it-works">
          {sectionTitle.howItWorks}
        </MuiTypography>
      </MuiBox>
      <MuiBox marginBottom={Spacing.small}>
        <MuiDivider />
      </MuiBox>
      <MuiGrid container style={{ height: '350', backgroundColor: `${Color.white}` }}>
        <MuiGrid item md={6}>
          <MuiBox padding={Spacing.small}>{InfoItems}</MuiBox>
        </MuiGrid>
        <MuiGrid item md={6} data-testid="pricing-section">
          <MuiBox padding={Spacing.small} bgcolor={Color.white}>
            <MuiBox bgcolor={Color.flatUnderlay} padding={Spacing.small}>
              <FlexBox flexDirection="row" justifyContent="center">
                <MuiBox>
                  <Svg name="SelectHealth" />
                </MuiBox>
                <MuiBox>
                  <MuiTypography gutterBottom>{card.title}</MuiTypography>
                </MuiBox>
              </FlexBox>

              <MuiTypography component="p" align="center" fontSize={FontSize.title}>
                $
                <MuiTypography component="span" fontSize={FontSize.title}>
                  0.00
                </MuiTypography>
              </MuiTypography>

              <MuiTypography align="center" fontSize={FontSize.small}>
                {card.disclosure}
              </MuiTypography>
            </MuiBox>
          </MuiBox>
          <MuiBox
            textAlign="center"
            bgcolor={Color.white}
            padding={Spacing.small}
            data-testid="start-e-visit"
          >
            <MuiButton
              variant="contained"
              color="primary"
              fullWidth
              size="large"
              onClick={onStartVisit}
            >
              {button.startEVisit}
            </MuiButton>
          </MuiBox>
        </MuiGrid>
      </MuiGrid>
      <MuiBox paddingLeft={2} marginTop={4} marginBottom={2}>
        <MuiTypography fontSize={24} data-testid="condition-definitions-title">
          {sectionTitle.conditions}
        </MuiTypography>
      </MuiBox>
      <MuiBox marginBottom={1}>
        <MuiDivider />
      </MuiBox>
      <ConditionDefinitions onExpandAccordion={onExpandAccordion} />
      <MuiBox marginBottom={1}>
        <MuiDivider />
      </MuiBox>
      <MuiBox my={Spacing.small} data-testid="disclosure">
        {disclosureItems.map(({ key, content, withAsterisk }) => (
          <FlexBox
            key={key}
            flexDirection="row"
            vOffsetBottom={Spacing.small}
            hOffsetLeft={Spacing.medium}
          >
            {withAsterisk ? (
              <FlexBox hOffsetRight={Spacing.small}>
                <MuiTypography>* </MuiTypography>
              </FlexBox>
            ) : null}

            <MuiTypography>{content}</MuiTypography>
          </FlexBox>
        ))}
      </MuiBox>
    </MuiContainer>
  );
};
