import { TriagePatient } from 'store/triageGyant/types';

export function parseEVisitInfoStorage(
  str?: string
): { localStateSelected?: string } & TriagePatient {
  if (!str) {
    return {} as { localStateSelected?: string } & TriagePatient;
  }

  try {
    return JSON.parse(str);
  } catch (err) {
    return {} as { localStateSelected?: string } & TriagePatient;
  }
}

export function getStateAndCondition(
  eVisitInfo?: string,
  locationState?: { conditionCode?: string; localStateSelected: string }
): { conditionCode: string; localStateSelected: string } {
  const eVisitInfoStore = parseEVisitInfoStorage(eVisitInfo);
  const localStateSelected =
    eVisitInfoStore?.localStateSelected || locationState?.localStateSelected || '';
  const conditionCode = eVisitInfoStore?.patientSuspectedConditions?.length
    ? eVisitInfoStore?.patientSuspectedConditions[0]
    : locationState?.conditionCode || '';
  return { conditionCode, localStateSelected };
}
