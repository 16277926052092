import React from 'react';
import { MuiBox } from 'theme/material-ui';
import Spinner from './Spinner';

interface SpacingProps {
  size: number;
}

const SpacedSpinner = ({ size }: SpacingProps) => {
  return (
    <MuiBox p={size}>
      <Spinner />
    </MuiBox>
  );
};

SpacedSpinner.defaultProps = {
  size: 3
};

export default SpacedSpinner;
