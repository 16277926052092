import React, { memo, ReactNode } from 'react';
import styled from 'styled-components';

import { Svg } from 'components/UI/Svg';
import { CursorMuiBox, MuiAvatar, MuiBox, MuiTypography } from 'theme/material-ui';

import { getUserInitials } from 'lib/user';

import { Color } from 'modules/styles/colors';
import { FontSize, FontWeight } from 'modules/styles/variables';

export interface Props {
  accessibilityLabel: string;
  avatarUrl: string | ReactNode;
  backgroundColor?: string;
  borderColor?: string;
  fallback: string;
  initialsFontSize?: number;
  initialsFontColor?: string;
  onAvatarClick?: () => void;
  margin?: string;
  size: number;
}
export const StyledSvg = styled(Svg)`
  visibility: visible;
`;

export const StyledAvatar = styled(MuiAvatar)<Props>`
  &.MuiAvatar-root {
    width: ${({ size }) => `${size}px`};
    height: ${({ size }) => `${size}px`};
    border-radius: ${({ size }) => `${size / 2}px`};
    border: ${({ borderColor }) => `2px solid ${borderColor}`};
    margin: ${({ margin }) => `${margin}`};
  }
  &.MuiAvatar-colorDefault {
    background-color: ${({ backgroundColor }) => `${backgroundColor}`};
  }
`;

export function UserAvatar({
  accessibilityLabel,
  avatarUrl,
  backgroundColor,
  borderColor,
  fallback,
  initialsFontColor,
  initialsFontSize,
  onAvatarClick,
  size,
  margin
}: Props) {
  const content = (
    <>
      {avatarUrl ? (
        <MuiBox textAlign="center">
          <StyledSvg set="assets" size={size} name={avatarUrl} />
        </MuiBox>
      ) : (
        <StyledAvatar
          display="flex"
          flexDirection="row"
          alignContent="center"
          justifyContent="center"
          textAlign="center"
          accessibilityLabel={accessibilityLabel}
          backgroundColor={backgroundColor}
          borderColor={!avatarUrl ? borderColor : null}
          margin={margin}
          size={size}
          data-testid="user-avatar"
        >
          <MuiTypography
            color={initialsFontColor}
            fontSize={initialsFontSize}
            fontWeight={FontWeight.semibold}
          >
            {getUserInitials(fallback)}
          </MuiTypography>
        </StyledAvatar>
      )}
    </>
  );

  if (onAvatarClick) {
    return (
      <CursorMuiBox data-testid="avatar-clickable" onClick={onAvatarClick}>
        {content}
      </CursorMuiBox>
    );
  }

  return content;
}

UserAvatar.defaultProps = {
  accessibilityLabel: 'user avatar',
  backgroundColor: Color.grayHue7,
  borderColor: Color.secondary,
  initialsFontColor: Color.secondary,
  initialsFontSize: FontSize.heading,
  margin: 'auto',
  size: 32
};

export default memo(UserAvatar);
