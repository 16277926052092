const masks = {
  sevenDigit: [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
  areaCode: ['(', /[2-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
  countryCode: [
    '+1',
    '(',
    /[2-9]/,
    /\d/,
    /\d/,
    ')',
    ' ',
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/
  ]
};

export const phone = {
  default: masks.areaCode,
  ...masks
};
