import React, { useCallback, useMemo } from 'react';
import dayjs from 'dayjs';
import { createMuiTheme } from '@material-ui/core';
import { DatePicker, KeyboardDatePickerProps } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';

import { MuiBadge } from 'theme/material-ui';

import Box from 'components/UI/Layout/Box';

import { Color } from 'modules/styles/colors';
import { formatDate } from 'modules/utils/DateUtils';

import { TimeSlot } from 'store/booking/types';
import { TIME_SLOTS_FETCH_DAYS_RANGE } from 'store/booking/constants';

interface BookingDatePicker extends KeyboardDatePickerProps {
  timeSlots: TimeSlot[];
  onChange: (date: MaterialUiPickersDate) => void;
}

export const BookingDatePicker = (props: BookingDatePicker) => {
  const maxDate = dayjs().add(TIME_SLOTS_FETCH_DAYS_RANGE, 'day');
  const daysMap = useMemo(() => {
    const map = new Map<string, number>();

    for (const slot of props.timeSlots) {
      const formatted = formatDate(slot.datetime);

      if (formatted !== '') {
        const prevValue = map.get(formatted) || 0;

        map.set(formatted, prevValue + 1);
      }
    }

    return map;
  }, [props.timeSlots]);

  const getShouldDisableDate = useCallback(
    (date: MaterialUiPickersDate) => {
      if (!daysMap.size || !date) {
        return false;
      }

      return !daysMap.has(formatDate(date));
    },
    [daysMap]
  );

  const renderDay = (
    day: MaterialUiPickersDate,
    selectedDate: MaterialUiPickersDate,
    isInCurrentMonth: boolean,
    dayComponent: JSX.Element
  ): JSX.Element => {
    if (!day || !isInCurrentMonth) {
      return dayComponent;
    }

    const count = daysMap.get(formatDate(day)) || 0;

    const renderBadgeContent = () => {
      if (!count) {
        return null;
      }

      return <Box data-testid="calendar-date-button" aria-label={`${count} timeslots available`} />;
    };

    return (
      <MuiBadge
        data-testid={`${count} timeslots available`}
        badgeContent={renderBadgeContent()}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        {dayComponent}
      </MuiBadge>
    );
  };

  const calendarTheme = createMuiTheme({
    overrides: {
      MuiPickersToolbar: {
        toolbar: {
          backgroundColor: Color.primary
        }
      },
      MuiPickersBasePicker: {
        pickerView: {
          display: 'flex',
          maxWidth: 450,
          minWidth: 310,
          minHeight: 305,
          overflowX: 'hidden',
          flexDirection: 'column',
          justifyContent: 'center'
        }
      },
      MuiPickersDay: {
        daySelected: {
          backgroundColor: Color.primary
        }
      },
      MuiBadge: {
        badge: {
          backgroundColor: Color.tealLight,
          height: 5,
          minWidth: 5,
          padding: 0
        },
        anchorOriginTopRightRectangle: {
          top: 5,
          right: 20
        }
      }
    }
  });

  return (
    <MuiThemeProvider theme={calendarTheme}>
      <DatePicker
        variant="static"
        disablePast
        autoOk
        openTo="date"
        views={['month', 'date']}
        format="MM/DD/YYYY"
        fullWidth
        shouldDisableDate={getShouldDisableDate}
        renderDay={renderDay}
        maxDate={maxDate}
        {...props}
      />
    </MuiThemeProvider>
  );
};
