import React, { useState, useEffect, useMemo } from 'react';
import dayjs from 'dayjs';
import * as Yup from 'yup';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import { FormikContextType } from 'formik';
import { RouteComponentProps } from 'react-router';
import { MuiBox, MuiContainer, MuiTypography, MuiGrid, MuiSvgIcon } from 'theme/material-ui';

import {
  ConnectCareForm,
  useConnectCareFormEffect,
  ConnectCareStepActions,
  ConnectCareStepAction
} from 'components/ConnectCare';
import { useSnack } from 'components/Snack';

import { insurance } from 'modules/constants/amwell';

import { RootDispatch, RootState } from 'store/types';
import * as selectors from 'store/amwell/selectors';
import { Consumer, HealthPlan, RelationshipToSubscriberCode } from 'store/amwell/types';
import {
  setInsuranceStatus,
  updateInsuranceSubscription,
  removeInsuranceSubscription,
  getRelationshipsToSubscriber,
  getHealthPlans,
  UPDATE_INSURANCE_SUBSCRIPTION_FAIL
} from 'store/amwell/actions';
import analyticsService, { AnalyticsEvent } from 'services/AnalyticsService';
import {
  currentLocationPathNameSelector,
  previousLocationPathNameSelector
} from 'store/router/selectors';

import { RouteData } from '../types';
import { regExNumAndString, getSelectionId } from '../utils';
import ConnectCareInsuranceSelectInsuranceForm from './ConnectCareInsuranceSelectInsuranceForm';
import { FormScreen } from '../styled';
import { Color } from 'modules/styles/colors';
import { FontWeight, Spacing } from 'modules/styles/variables';
import { Row } from 'components/UI/Layout/Container';
import { Svg } from 'components/UI/Svg';

const ExclamationCircleIcon = ({ fontSize }: { fontSize: 'default' | 'small' | 'large' }) => (
  <MuiSvgIcon fontSize={fontSize}>
    <circle cx="12" cy="12" r="12" fill={Color.baseColor} />
    <Svg set="assets" name="ExclamationCircleAlt" x={5} y={5} />
  </MuiSvgIcon>
);

export interface Props extends RouteComponentProps {
  dispatch: RootDispatch;
  healthPlans: HealthPlan[];
  healthPlansLoading: boolean;
  relationshipsToSubscriber: RelationshipToSubscriberCode[];
  relationshipsToSubscriberLoading: boolean;
  activeConsumer: Consumer | null;
  consumer: Consumer | null;
  currentUrl?: string;
  referringUrl?: string;
}

// ▼ Formik ▼ //

const current = dayjs(new Date()).startOf('day');

const initialValues = {
  option: insurance.INSURANCE_OPTIONS.INSURED,
  healthPlan: '',
  subscriberId: '',
  relationshipToSubscriber: '',
  primarySubscriberFirstName: '',
  primarySubscriberLastName: '',
  primarySubscriberDateOfBirth: current
};

const validationSchema = Yup.object().shape({
  healthPlan: Yup.string().when('option', {
    is: insurance.INSURANCE_OPTIONS.INSURED,
    then: Yup.string().required('Please select a health plan.'),
    otherwise: Yup.string().notRequired()
  }),
  subscriberId: Yup.string().when('option', {
    is: insurance.INSURANCE_OPTIONS.INSURED,
    then: Yup.string()
      .required('Please enter a subscriber id.')
      .matches(regExNumAndString, 'Please do not use special characters.'),
    otherwise: Yup.string().notRequired()
  }),
  relationshipToSubscriber: Yup.string().when('option', {
    is: insurance.INSURANCE_OPTIONS.INSURED,
    then: Yup.string().required('Please select a relationship.'),
    otherwise: Yup.string().notRequired()
  }),
  primarySubscriberFirstName: Yup.string().when('option', {
    is: insurance.INSURANCE_OPTIONS.INSURED,
    then: Yup.string().required('Please enter a first name.'),
    otherwise: Yup.string().notRequired()
  }),
  primarySubscriberLastName: Yup.string().when('option', {
    is: insurance.INSURANCE_OPTIONS.INSURED,
    then: Yup.string().required('Please enter a last name.'),
    otherwise: Yup.string().notRequired()
  }),
  primarySubscriberDateOfBirth: Yup.date().when('option', {
    is: insurance.INSURANCE_OPTIONS.INSURED,
    then: Yup.date()
      .typeError('Please enter a valid date.')
      .required('Please enter a date of birth.')
      .min(current.subtract(200, 'year').toDate(), 'Please enter a valid age.'),
    otherwise: Yup.date()
      .notRequired()
      .nullable()
  })
});

const isFormValid = async (formikProps: FormikContextType<typeof initialValues>) => {
  const errors = await formikProps.validateForm(formikProps.values);
  const hasErrors = !!Object.keys(errors).length;
  return formikProps.values.option === insurance.INSURANCE_OPTIONS.INSURED && hasErrors;
};

const getIsInitialValid = (activeConsumer: Consumer | null) => {
  return !!activeConsumer?.subscription;
};

const getInitialValues = (activeConsumer: Consumer | null, consumer: Consumer | null) => {
  const subscription = activeConsumer?.subscription ?? consumer?.subscription ?? null;
  const subscriberId = subscription?.subscriberId;
  const healthPlan = subscription?.healthPlan?.id?.persistentId;

  return subscription
    ? {
        option: insurance.INSURANCE_OPTIONS.INSURED,
        healthPlan,
        subscriberId,
        relationshipToSubscriber: '',
        primarySubscriberFirstName: '',
        primarySubscriberLastName: '',
        primarySubscriberDateOfBirth: null
      }
    : initialValues;
};

// ▼ ConnectCareInsuranceSelect ▼ //

export function ConnectCareInsuranceSelect(props: Props) {
  const {
    dispatch,
    history,
    activeConsumer,
    consumer,
    healthPlans,
    healthPlansLoading,
    relationshipsToSubscriber,
    relationshipsToSubscriberLoading,
    currentUrl,
    referringUrl
  } = props;

  const { create } = useSnack();
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [formProps, setFormProps] = useState<FormikContextType<typeof initialValues>>();
  const [primarySubscriberSelection, setPrimarySubscriberSelection] = useState('');
  const [usePrimarySubscriberInfoSelection, setUsePrimarySubscriberInfoSelection] = useState('');

  const isInitialValid = useMemo(() => {
    return getIsInitialValid(activeConsumer);
  }, [activeConsumer]);

  const initialValues = useMemo(() => {
    return getInitialValues(activeConsumer, consumer);
  }, [activeConsumer]);

  useConnectCareFormEffect(async formProps => {
    const disabled = await isFormValid(formProps);
    setDisabled(disabled);
    setFormProps(formProps);
  });

  useEffect(() => {
    dispatch(getHealthPlans());
    dispatch(getRelationshipsToSubscriber());
  }, []);

  const INSURANCE_STATUS_TEXT = activeConsumer?.isDependent
    ? insurance.INSURANCE_TEXT.INSURANCE_STATUS
    : insurance.INSURANCE_TEXT.INSURANCE_STATUS_IS_PRIMARY;
  const HAS_INSURANCE_TEXT = activeConsumer?.isDependent
    ? insurance.INSURANCE_TEXT.HAS_INSURANCE
    : insurance.INSURANCE_TEXT.HAS_INSURANCE_IS_PRIMARY;
  const HAS_NO_INSURANCE_TEXT = activeConsumer?.isDependent
    ? insurance.INSURANCE_TEXT.NO_INSURANCE
    : insurance.INSURANCE_TEXT.NO_INSURANCE_IS_PRIMARY;
  const NOT_LISTED_OR_SKIP_TEXT = activeConsumer?.isDependent
    ? insurance.INSURANCE_TEXT.NOT_LISTED_OR_SKIP
    : insurance.INSURANCE_TEXT.NOT_LISTED_OR_SKIP_IS_PRIMARY;

  function updateInsurance(values: typeof initialValues): AnyAction {
    try {
      const healthPlan = healthPlans.find(hp => getSelectionId(hp) === values.healthPlan);
      const relationshipToSubscriberCode = relationshipsToSubscriber.find(
        rel => rel.localizedDisplayName === values.relationshipToSubscriber
      );

      if (!healthPlan || !relationshipToSubscriberCode) {
        return {
          type: UPDATE_INSURANCE_SUBSCRIPTION_FAIL,
          error: 'Could not locate health plan'
        };
      }

      const updateInsuranceSubscriptionForm = {
        ignoreEligibilityChecks: false,
        healthPlan,
        subscriberId: values.subscriberId,
        relationshipToSubscriberCode,
        subscriberSuffix: '',
        primarySubscriberDateOfBirth:
          values.primarySubscriberDateOfBirth.toDate?.() || values.primarySubscriberDateOfBirth,
        primarySubscriberFirstName: values.primarySubscriberFirstName,
        primarySubscriberLastName: values.primarySubscriberLastName
      };

      return dispatch(updateInsuranceSubscription({ updateInsuranceSubscriptionForm }));
    } catch (e) {
      return {
        type: UPDATE_INSURANCE_SUBSCRIPTION_FAIL,
        error: e.message
      };
    }
  }

  function removeInsurance(): AnyAction {
    return dispatch(removeInsuranceSubscription());
  }

  const handleSubmit = async (values: typeof initialValues) => {
    const insured = values.option === insurance.INSURANCE_OPTIONS.INSURED;
    const uninsured = values.option === insurance.INSURANCE_OPTIONS.UNINSURED;
    const skip = values.option === insurance.INSURANCE_OPTIONS.SKIP;

    if (insured) {
      const res = await updateInsurance(values);
      if (res.error) throw res.error;
    }

    if (uninsured || skip) {
      const res = await removeInsurance();
      if (res.error) throw res.error;
    }

    dispatch(setInsuranceStatus(values.option));
  };

  const onPrevClick = (prevStep?: RouteData) => {
    if (prevStep) {
      history.push(prevStep.path);
    }
  };

  const onNextClick = async (nextStep?: RouteData) => {
    if (!nextStep || !formProps) return;

    setLoading(true);

    await formProps
      .submitForm()
      .then(() => {
        setLoading(false);
        history.push(nextStep.path);
        analyticsService.logEvent(AnalyticsEvent.InsuranceEntered, {
          currentUrl,
          referringUrl
        });
      })
      .catch(() => {
        setLoading(false);
        create('There was an issue updating your insurance', 'error');
      });
  };

  return (
    <>
      <FormScreen>
        <MuiContainer maxWidth="lg">
          <MuiBox my={3}>
            <MuiGrid container spacing={2}>
              <MuiGrid item xs={12}>
                <MuiTypography variant="h5">{INSURANCE_STATUS_TEXT}</MuiTypography>
              </MuiGrid>
              <MuiGrid item xs={12}>
                <MuiTypography>
                  Insurance may cover all or part of your visits. If your plan isn't listed, you can
                  still have a visit.
                </MuiTypography>
              </MuiGrid>
              <MuiGrid item xs={12}>
                <ConnectCareForm
                  onSubmit={handleSubmit}
                  isInitialValid={isInitialValid}
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  enableReinitialize
                >
                  <ConnectCareForm.RadioGroup
                    name="option"
                    items={[
                      {
                        label: HAS_INSURANCE_TEXT,
                        value: insurance.INSURANCE_OPTIONS.INSURED,
                        render: () => (
                          <ConnectCareInsuranceSelectInsuranceForm
                            consumer={consumer}
                            activeConsumer={activeConsumer}
                            healthPlans={healthPlans}
                            relationshipsToSubscriber={relationshipsToSubscriber}
                            loading={healthPlansLoading || relationshipsToSubscriberLoading}
                            primarySubscriberSelection={primarySubscriberSelection}
                            setPrimarySubscriberSelection={setPrimarySubscriberSelection}
                            usePrimarySubscriberInfoSelection={usePrimarySubscriberInfoSelection}
                            setUsePrimarySubscriberInfoSelection={
                              setUsePrimarySubscriberInfoSelection
                            }
                            skipValue={insurance.INSURANCE_OPTIONS.SKIP}
                            isAutoFilled={initialValues.healthPlan && initialValues.subscriberId}
                          />
                        )
                      },
                      {
                        label: HAS_NO_INSURANCE_TEXT,
                        value: insurance.INSURANCE_OPTIONS.UNINSURED
                      },
                      {
                        label: NOT_LISTED_OR_SKIP_TEXT,
                        value: insurance.INSURANCE_OPTIONS.SKIP
                      }
                    ]}
                  />
                </ConnectCareForm>
              </MuiGrid>
              <MuiGrid item xs={12}>
                <MuiBox bgcolor={Color.foreColor} padding={Spacing.small}>
                  <Row>
                    <ExclamationCircleIcon fontSize="large" />
                    <MuiTypography fontWeight={FontWeight.bold} style={{ margin: 5 }}>
                      Connect Care
                    </MuiTypography>
                  </Row>
                  <MuiBox marginLeft={4} marginTop={Spacing.xSmall}>
                    1. Connect Care does not treat patients for emergency illnesses. If you feel you
                    are experiencing an emergency medical problem, please call 911 or go to the
                    nearest emergency room.
                    <br />
                    <br />
                    2. Your visit is linked to your medical records, so please ensure you are using
                    your personal account and not a family member's account.
                    <br />
                    <br />
                    3. If you have dual insurance coverage, please contact our billing department
                    (801-442-1433) to provide secondary insurance for coordination of benefits.
                    <br />
                    <br />
                    4. If your insurance isn't listed, your insurer likely doesn’t cover this visit.
                    If you feel your insurance should be listed, please contact your insurer or you
                    can contact our operations team at connectcare@imail.org. You can also contact
                    us by calling one of the telephone numbers provided below:
                    <br />
                    <br />
                    (801) 906-2800 or (866) 415-6556
                    <br />
                    <br />
                    Business hours are weekdays 8:00 AM to 6:00 PM MT with the exception of
                    holidays.
                  </MuiBox>
                </MuiBox>
              </MuiGrid>
            </MuiGrid>
          </MuiBox>
        </MuiContainer>
      </FormScreen>
      <ConnectCareStepActions>
        <ConnectCareStepAction onClick={onPrevClick} />
        <ConnectCareStepAction disabled={disabled} loading={loading} onClick={onNextClick} />
      </ConnectCareStepActions>
    </>
  );
}

const mapStateToProps = (state: RootState) => ({
  activeConsumer: selectors.activeConsumerDataSelector(state),
  consumer: selectors.consumerDataSelector(state),
  healthPlans: selectors.healthPlansDataSelector(state),
  healthPlansLoading: selectors.healthPlansDataLoadingSelector(state),
  relationshipsToSubscriber: selectors.relationshipsToSubscriberDataSelector(state),
  relationshipsToSubscriberLoading: selectors.relationshipsToSubscriberDataLoadingSelector(state),
  currentUrl: currentLocationPathNameSelector(state),
  referringUrl: previousLocationPathNameSelector(state)
});

export default connect(mapStateToProps)(ConnectCareInsuranceSelect);
