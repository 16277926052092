import { useEffect, EffectCallback } from 'react';
import { useSelector } from 'react-redux';
import { currentAccountConsumerSelector } from 'store/account/selectors';

/**
 * A useEffect that will automatically rerun if the proxySwitcher changes account
 */
export const useProxySwitcherEffect = (effectFn: EffectCallback, extraDeps: readonly any[]) => {
  const currentConsumer = useSelector(currentAccountConsumerSelector);

  const consumerId = currentConsumer ? currentConsumer.consumerId : null;

  useEffect(effectFn, [consumerId, ...extraDeps]);
};
