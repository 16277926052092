import React from 'react';
import { AnyAction } from 'redux';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import * as Yup from 'yup';
import { FormikHelpers } from 'formik';
import { ConnectCareForm } from 'components/ConnectCare';
import { MuiPaper, MuiBox, MuiButton, MuiContainer, MuiTypography } from 'theme/material-ui';
import * as actions from 'store/amwell/actions';
import { FontSize, FontWeight } from 'modules/styles/variables';
import { Color } from 'modules/styles/colors';
import { Alert } from 'components/Alert';
import { accountRecovery } from 'modules/constants/amwell';

export interface Props extends RouteComponentProps {
  requestPasswordReset: typeof actions.requestPasswordReset;
}

const currDate = dayjs(new Date()).startOf('day');

const validationSchema = Yup.object().shape({
  lastName: Yup.string()
    .required('Please enter a last name')
    .max(40, 'Please enter no more than 40 characters'),
  dob: Yup.date()
    .typeError('Please enter a valid date')
    .required('Please enter a date of birth')
    .min(currDate.subtract(200, 'year').toDate(), 'Please enter a valid age')
    .max(currDate.subtract(18, 'year').toDate(), 'Account owner must be atleast 18 years of age'),
  email: Yup.string()
    .email('Please enter a valid email')
    .required('Please enter an email')
});

const initialValues = {
  lastName: '',
  dob: currDate,
  email: ''
};

export function ConnectCareAccountRecovery(props: Props) {
  const { requestPasswordReset, history } = props;

  const onSubmit = (values: typeof initialValues, actions: FormikHelpers<typeof initialValues>) => {
    actions.setSubmitting(true);

    requestPasswordReset({
      ...values,
      dob: values.dob.toDate()
    }).then((res: AnyAction) => {
      actions.setSubmitting(false);
      if (res.error) {
        Alert.alert(accountRecovery.ERROR.title, accountRecovery.ERROR.subtitle);
      } else {
        Alert.alert(accountRecovery.SUCCESS.title, accountRecovery.SUCCESS.subtitle, [
          { text: 'Ok', onPress: history.goBack }
        ]);
      }
    });
  };

  return (
    <MuiBox mt={6}>
      <MuiContainer maxWidth="sm">
        <ConnectCareForm
          validateOnMount
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ isValid, isSubmitting }) => (
            <MuiBox component={MuiPaper} p={3}>
              <MuiBox mb={2}>
                <MuiTypography
                  fontWeight={FontWeight.semibold}
                  fontSize={FontSize.large}
                  color={Color.textLight}
                  gutterBottom
                >
                  {accountRecovery.TITLE.title}
                </MuiTypography>
                <MuiTypography fontSize={FontSize.small} color={Color.textLight} gutterBottom>
                  {accountRecovery.DISCLAIMER.title}
                </MuiTypography>
              </MuiBox>

              <MuiBox mb={2}>
                <ConnectCareForm.TextField label="Last Name" name="lastName" />
              </MuiBox>

              <MuiBox mb={2}>
                <ConnectCareForm.DatePicker
                  label="Date of Birth"
                  name="dob"
                  ariaLabel="date picker"
                />
              </MuiBox>

              <MuiBox mb={2}>
                <ConnectCareForm.TextField label="Email" type="email" name="email" />
              </MuiBox>

              <MuiBox mb={1}>
                <MuiButton
                  fullWidth
                  variant="outlined"
                  disabled={isSubmitting}
                  onClick={history.goBack}
                >
                  Cancel
                </MuiButton>
              </MuiBox>

              <MuiButton
                fullWidth
                variant="contained"
                color="primary"
                data-testid="submit"
                loading={isSubmitting}
                disabled={!isValid}
                type="submit"
              >
                Submit
              </MuiButton>
            </MuiBox>
          )}
        </ConnectCareForm>
      </MuiContainer>
    </MuiBox>
  );
}

const mapDispatch = {
  requestPasswordReset: actions.requestPasswordReset
};

export default connect(null, mapDispatch)(ConnectCareAccountRecovery);
