import Fuse from 'fuse.js';
import { useMemo, useState } from 'react';

export function useSearch<T>(
  items: T[],
  config: Fuse.IFuseOptions<T>
): { result: T[]; search: (term: string) => void; term: string } {
  const [term, setSearch] = useState('');

  const indexer = useMemo(() => new Fuse(items, config), [items, config]);
  const result = useMemo(() => indexer.search(term), [indexer, term]);
  const search = (newTerm: string) => setSearch(newTerm);

  return {
    result: term ? result : items,
    search,
    term
  };
}
