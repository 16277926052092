import React from 'react';

export default {
  svg: (
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.04505 4.04505C6.15483 1.93526 9.01631 0.75 12 0.75C14.9837 0.75 17.8452 1.93526 19.955 4.04505C22.0647 6.15483 23.25 9.01631 23.25 12C23.25 14.9837 22.0647 17.8452 19.955 19.955C17.8452 22.0647 14.9837 23.25 12 23.25C9.01631 23.25 6.15483 22.0647 4.04505 19.955C1.93526 17.8452 0.75 14.9837 0.75 12C0.75 9.01631 1.93526 6.15483 4.04505 4.04505ZM12 2.25C9.41414 2.25 6.93419 3.27723 5.10571 5.10571C3.27723 6.93419 2.25 9.41414 2.25 12C2.25 14.5859 3.27723 17.0658 5.10571 18.8943C6.93419 20.7228 9.41414 21.75 12 21.75C14.5859 21.75 17.0658 20.7228 18.8943 18.8943C20.7228 17.0658 21.75 14.5859 21.75 12C21.75 9.41414 20.7228 6.93419 18.8943 5.10571C17.0658 3.27723 14.5859 2.25 12 2.25ZM12 7.5C12.4142 7.5 12.75 7.83579 12.75 8.25V11.6894L17.2174 16.1577C17.5103 16.4507 17.5102 16.9255 17.2173 17.2184C16.9244 17.5112 16.4495 17.5112 16.1566 17.2183L11.4696 12.5303C11.329 12.3896 11.25 12.1989 11.25 12V8.25C11.25 7.83579 11.5858 7.5 12 7.5Z"
        fill="#222222"
      />
    </g>
  ),
  viewBox: '0 0 24 24'
};
