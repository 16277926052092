import React from 'react';

export default {
  svg: (
    <g fill="none">
      <rect x="1" y="1" width="43" height="42" rx="3" fill="white" />
      <path
        d="M25.2022 32.3786L25.2129 32.3856C26.157 33.0619 27.2779 33.3556 28.3905 33.2183C29.503 33.081 30.5407 32.521 31.3321 31.6306L32.0201 30.8566C32.3274 30.5105 32.5 30.0413 32.5 29.5521C32.5 29.063 32.3274 28.5938 32.0201 28.2476L29.1214 24.9886C28.8137 24.643 28.3967 24.4488 27.9618 24.4488C27.527 24.4488 27.1099 24.643 26.8022 24.9886C26.6501 25.16 26.4694 25.296 26.2705 25.3887C26.0716 25.4815 25.8584 25.5292 25.6431 25.5292C25.4278 25.5292 25.2146 25.4815 25.0157 25.3887C24.8168 25.296 24.6361 25.16 24.484 24.9886L19.8457 19.7696C19.5384 19.4235 19.3658 18.9543 19.3658 18.4651C19.3658 17.976 19.5384 17.5068 19.8457 17.1606C19.998 16.9895 20.1189 16.7862 20.2013 16.5625C20.2838 16.3387 20.3262 16.0989 20.3262 15.8566C20.3262 15.6144 20.2838 15.3746 20.2013 15.1508C20.1189 14.9271 19.998 14.7238 19.8457 14.5526L16.9478 11.2886C16.6402 10.943 16.2231 10.7488 15.7883 10.7488C15.3534 10.7488 14.9363 10.943 14.6287 11.2886L13.9407 12.0626C13.1494 12.953 12.6516 14.1203 12.5294 15.3718C12.4072 16.6233 12.668 17.8843 13.2687 18.9466L13.2758 18.9586C16.4522 24.2485 20.5008 28.804 25.2022 32.3786Z"
        stroke="#4A00E2"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect x="1" y="1" width="43" height="42" rx="3" stroke="#4A00E2" strokeWidth="2" />
    </g>
  ),
  viewBox: '0 0 45 44'
};
