import React from 'react';
import { connect, useDispatch } from 'react-redux';
import Spacer from 'components/UI/Layout/Spacer';
import WebMuiModal from 'components/UI/Modals/WebMuiModal';

import { Spacing } from 'modules/styles/variables';

import { MuiButton, MuiBox, MuiTypography } from 'theme/material-ui';

import { RootState } from 'store/types';
import { protectedRouteModalIsOpenSelector } from 'store/authentication/selectors';
import { toggleProtectedRouteModal } from 'store/authentication/actions';
import { handleLoginPress } from 'store/pageActions/handleLoginPress';

interface Props {
  isOpen: boolean;
  handleToggle?: () => void;
}

export const NavigateToProtectedRouteModal = ({ isOpen, handleToggle }: Props) => {
  const dispatch = useDispatch();

  const handleLogin = () => {
    dispatch(toggleProtectedRouteModal());
    dispatch(handleLoginPress());
  };

  const handleCancel = () => {
    dispatch(toggleProtectedRouteModal());
    window.sessionStorage.removeItem('nextPath');
    window.sessionStorage.removeItem('provider');
  };

  return (
    <WebMuiModal open={isOpen} handleClose={handleToggle}>
      <MuiTypography align="center" variant="h6">
        Login Required
      </MuiTypography>

      <Spacer size="small" />
      <MuiTypography align="center">In order to continue, please login</MuiTypography>
      <Spacer size="medium" />

      <MuiBox mx={Spacing.medium}>
        <MuiButton
          variant="contained"
          color="primary"
          data-testid="handle-login"
          onClick={handleLogin}
          fullWidth
        >
          Login
        </MuiButton>
        <Spacer size="small" />
        <MuiButton variant="outlined" data-testid="handle-cancel" onClick={handleCancel} fullWidth>
          Cancel
        </MuiButton>
      </MuiBox>
    </WebMuiModal>
  );
};

const mapState = (state: RootState) => ({
  isOpen: protectedRouteModalIsOpenSelector(state)
});

export default connect(mapState)(NavigateToProtectedRouteModal);
