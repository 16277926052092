import React from 'react';
import Container from 'components/UI/Layout/Container';

interface CollapsibleListProps {
  emptyCaption?: string;
  children: React.ReactNode;
  accessibilityLabel?: string;
}

const CollapsibleList = ({ children, accessibilityLabel, ...rest }: CollapsibleListProps) => (
  <Container accessibilityLabel={accessibilityLabel} {...rest}>
    {children}
  </Container>
);

CollapsibleList.defaultProps = {
  emptyCaption: undefined
};

export default CollapsibleList;
