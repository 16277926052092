import React from 'react';
import { Color } from 'modules/styles/colors';

export default () => ({
  svg: (
    <path
      fill={Color.black}
      fillRule="evenodd"
      d="M.66.659A2.25 2.25 0 0 1 2.25 0h4.5A2.25 2.25 0 0 1 9 2.25V3h9.75A2.25 2.25 0 0 1 21 5.25V7.5h.75a2.25 2.25 0 0 1 2.175 2.829.75.75 0 1 1-1.45-.386A.75.75 0 0 0 21.75 9H7.88a.751.751 0 0 0-.722.542l-2.35 8.15v.002c-.03.105-.067.207-.11.306H8.25a.75.75 0 0 1 0 1.5H2.447a.75.75 0 0 1-.224-.034A2.45 2.45 0 0 1 0 17.06V2.25C0 1.653.237 1.081.66.659ZM19.5 7.5V5.25a.75.75 0 0 0-.75-.75H8.25a.75.75 0 0 1-.75-.75v-1.5a.75.75 0 0 0-.75-.75h-4.5a.75.75 0 0 0-.75.75v14.794a.95.95 0 0 0 1.864.24l.001-.004 2.351-8.154A2.25 2.25 0 0 1 7.88 7.5H19.5Zm-7.023 4.977a6.75 6.75 0 1 1 9.546 9.547 6.75 6.75 0 0 1-9.546-9.547ZM17.25 12a5.25 5.25 0 1 0 0 10.5 5.25 5.25 0 0 0 0-10.5Zm0 1.5a.75.75 0 0 1 .75.75v4.19l.97-.97a.75.75 0 0 1 1.06 1.06l-2.25 2.25a.75.75 0 0 1-1.06 0l-2.25-2.25a.75.75 0 1 1 1.06-1.06l.97.97v-4.19a.75.75 0 0 1 .75-.75Z"
      clipRule="evenodd"
      stroke="none"
    />
  ),
  viewBox: '0 0 24 24'
});
