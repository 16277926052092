import React from 'react';

export default {
  svg: (
    <g>
      <g transform="translate(-38, -539)">
        <path
          d="M71.5263344,567.850063 L69.8522741,567.850063 C69.8812253,567.604788 69.8999585,567.356993
          69.8999585,567.106678 C69.8999585,563.568669 66.9826999,560.691726 63.3961578,560.691726
          C62.1912773,560.691726 61.0655867,561.02184 60.0965732,561.587988 L60.0965732,558.859723
          L63.3961578,558.859723 C64.2783178,558.859723 64.992731,558.154137 64.992731,557.284754
          C64.992731,556.415372 64.2783178,555.709786 63.3961578,555.709786 L60.0965732,555.709786
          L60.0965732,553.272995 L66.9358671,553.272995 C67.818027,553.272995 68.5324403,552.568249
          68.5324403,551.698026 C68.5324403,550.828643 67.818027,550.123058 66.9358671,550.123058
          L60.0965732,550.123058 L60.0965732,547.687106 L66.2223053,547.687106 C67.1044652,547.687106
          67.8188785,546.98152 67.8188785,546.112138 C67.8188785,545.242755 67.1044652,544.537169
          66.2223053,544.537169 L60.0965732,544.537169 L60.0965732,542.149937 L71.5263344,542.149937
          L71.5263344,567.850063 Z M50.293188,567.106678 C50.293188,565.306594 51.7782139,563.841663
          53.6038422,563.841663 C55.3792316,563.841663 56.8216822,565.230995 56.8991693,566.963041 C56.8983178,
          567.01176 56.8923572,567.058799 56.8923572,567.106678 C56.8923572,567.166317 56.9017238,567.224276
          56.9034268,567.282234 C56.8932087,567.47543 56.8693666,567.665267 56.8250883,567.850063 L50.3817445,
          567.850063 C50.3255452,567.608988 50.293188,567.361193 50.293188,567.106678 L50.293188,567.106678
          Z M66.6122949,567.850063 L60.1740602,567.850063 C60.1306334,567.665267 60.1059398,567.47543 60.0965732,
          567.282234 C60.0982762,567.224276 60.1076428,567.166317 60.1076428,567.106678 C60.1076428,567.058799
          60.1016822,567.01176 60.0999792,566.963041 C60.1783178,565.230995 61.6199169,563.841663 63.3961578,
          563.841663 C65.2217861,563.841663 66.706812,565.306594 66.706812,567.106678 C66.706812,567.361193
          66.6693458,567.608148 66.6122949,567.850063 L66.6122949,567.850063 Z M45.4736656,542.149937
          L56.9034268,542.149937 L56.9034268,544.537169 L50.7768432,544.537169 C49.8955348,544.537169 49.18027,
          545.242755 49.18027,546.112138 C49.18027,546.98152 49.8955348,547.687106 50.7768432,547.687106
          L56.9034268,547.687106 L56.9034268,550.123058 L50.0641329,550.123058 C49.181973,550.123058 48.4675597,
          550.828643 48.4675597,551.698026 C48.4675597,552.568249 49.181973,553.272995 50.0641329,553.272995
          L56.9034268,553.272995 L56.9034268,555.709786 L53.6038422,555.709786 C52.7225337,555.709786 52.007269,
          556.415372 52.007269,557.284754 C52.007269,558.154137 52.7225337,558.859723 53.6038422,558.859723
          L56.9034268,558.859723 L56.9034268,561.587988 C55.9344133,561.02184 54.8087227,560.691726 53.6038422,
          560.691726 C50.0173001,560.691726 47.1000415,563.568669 47.1000415,567.106678 C47.1000415,567.356153
          47.1179232,567.603948 47.1477259,567.850063 L45.4736656,567.850063 L45.4736656,542.149937 Z M74.7194808,
          539 L42.2805192,539 L42.2805192,553.425031 L38,553.425031 L38,556.574969 L42.2805192,556.574969
          L42.2805192,571 L74.7194808,571 L74.7194808,556.574969 L79,556.574969 L79,553.425031 L74.7194808,
          553.425031 L74.7194808,539 Z"
        />
      </g>
    </g>
  ),
  viewBox: '0 0 41 32'
};
