import { exitModalText } from 'modules/constants/exitConfirmModal';
import { AppName, getAppName } from 'modules/utils/ConfigUtils';

export const TRIAGE_ROUTES = {
  TERMS: '/u/assess-my-symptoms/terms',
  PROXY_SELECT: '/u/assess-my-symptoms/select-user',
  SYMPTOM_CHECKER: '/u/assess-my-symptoms/scout-symptom-checker',
  RESULTS: {
    BASE_URL: '/u/assess-my-symptoms/results',
    PARAM_URL: '/u/assess-my-symptoms/results/:caseId'
  },
  POSSIBLE_CAUSES: {
    BASE_URL: '/u/assess-my-symptoms/possible-causes',
    PARAM_URL: '/u/assess-my-symptoms/possible-causes/:caseId'
  },
  CAUSE_DETAIL: {
    BASE_URL: '/u/assess-my-symptoms/cause-detail',
    PARAM_URL: '/u/assess-my-symptoms/cause-detail/:conditionId'
  }
};

export const TRIAGE_MODAL_TEXT = exitModalText('');

export const TRIAGE_TITLE = `${getAppName(
  AppName.base
)}, our medical symptom checker, \n can help direct you to the right care for your symptoms`;

export const TRIAGE_COVID_TEST_TITLE =
  'Order a self-serve COVID-19 test at a location convenient for you';
