import { Dialog } from '@material-ui/core';
import { Icon } from 'components/Icon';
import DataLoader from 'components/UI/DataLoader/DataLoader';
import Divider from 'components/UI/Divider';
import Box from 'components/UI/Layout/Box';
import FlexBox, { FlexBoxColumn } from 'components/UI/Layout/FlexBox';
import Spacer from 'components/UI/Layout/Spacer';
import Spinner from 'components/UI/Spinner/Spinner';
import Typography from 'components/UI/Typography';
import { Color } from 'modules/styles/colors';
import { FontSize, FontWeight, Spacing, IconSize } from 'modules/styles/variables';
import React, { ComponentType, useEffect } from 'react';
import { connect } from 'react-redux';
import { getAnticoagulationResponseDateText } from 'lib/dashboard/utils';
import { AntiCoagulationEvent } from 'store/events/types';
import { acknowledgeEvent, dismissEvent, getEvent, patchEventMessage } from 'store/events/actions';
import {
  acknowledgeEventSelector,
  dismissEventSelector,
  selectedEventSelector
} from 'store/events/selectors';
import { DefaultState } from 'store/setLocalStore';
import { MuiButton, MuiContainer, MuiBox, MuiTypography } from 'theme/material-ui';
import { SecondaryButton } from './styled';
import * as H from 'history';
import { Action, MhpDetails } from 'modules/types/common';
import { RootState } from 'store/types';
import {
  labTestResultDataSelector,
  testResultDetailsLoadingSelector
} from 'store/testResults/selectors';
import { getTestResultDetails } from 'store/testResults/actions';
import { LabResult, LabComponent, LabDataSource } from 'store/testResults/types';
import { useGetMedicalTestResultList } from 'hooks/queries/useGetMedicalTestResultList';
import { oc } from 'ts-optchain';
import { AnalyticsEvent } from 'services/AnalyticsService';
import useNavigationAnalytics from 'hooks/useNavigationAnalytics';

export interface AntiCoagulationAlertScreenProps {
  acknowledge: typeof acknowledgeEvent;
  acknowledgeState: DefaultState;
  dismiss: typeof dismissEvent;
  dismissState: DefaultState;
  eventState: DefaultState;
  getEvent: (eventId: string) => Promise<Action>;
  setPatchEventMessage: typeof patchEventMessage;
  match: { params: { id: string } };
  history: H.History;
  testDetailsData: LabResult[];
  testDetailsLoading: boolean;
  getTestResultDetails: typeof getTestResultDetails;
}
export type ListError = Error | null;

export enum AntiCoagulationAlertState {
  none = 0,
  acknowledge = 1,
  request = 2,
  loading = 3
}

export interface AntiCoagulationAcknowledgementBody {
  ihcEmpi: string;
  eventId: string;
  sequence: string;
}

export const modalMessages = {
  [AntiCoagulationAlertState.none]: '',
  [AntiCoagulationAlertState.loading]: '',
  [AntiCoagulationAlertState.acknowledge]: 'Your acknowledgement has been recorded.',
  [AntiCoagulationAlertState.request]:
    'Your request for a call has been submitted. You should receive a call back within 2 business days.'
};

export const convertToBodyText = (content: string) =>
  content.replace(/<br>/g, '\n').replace(/<br\/>/g, '\n');

interface RenderViewAllResultsButtonProps {
  testDetailsData: LabResult[];
  isLoading: boolean;
  listError?: ListError;
  history: H.History;
}

export const RenderViewAllResultsButton = ({
  testDetailsData,
  isLoading,
  listError,
  history
}: RenderViewAllResultsButtonProps) => {
  // Select first lab details
  const panelName = oc(testDetailsData)[0].name('');
  const labComponents = oc(testDetailsData)[0].components([]);
  const source = oc(testDetailsData)[0].source(LabDataSource.MILLENNIUM);

  // Find the most recent lab component that contains one of the possible AntiCoag codes
  const possibleLabCodes = ['34714-6', '6301-6'];
  const recentLabData = labComponents.find((item: LabComponent) =>
    possibleLabCodes.some(code => item.loincCode?.includes(code))
  );

  // Pass the details of the AntiCoag test to populate LabTrendLine chart
  const openLabResults = () => {
    history.push('/u/health-record/lab-history', {
      // LabTrendLine params:
      resultDetail: {
        name: recentLabData?.name,
        loincCode: recentLabData?.loincCode,
        panelName,
        source
      }
    });
  };

  // Render button states
  if (isLoading && !listError) {
    return (
      <MuiBox
        display="flex"
        flexDirection="row"
        alignItems="center"
        data-testid="results-loading"
        my={`${Spacing.medium}px`}
      >
        <Spinner size={IconSize.small} />
        <MuiTypography color={Color.primary} fontSize={FontSize.small}>
          &nbsp; Loading lab results
        </MuiTypography>
      </MuiBox>
    );
  }
  if (recentLabData && !listError) {
    return (
      <MuiButton color="primary" onClick={openLabResults} data-testid="view-all-results">
        View all lab results
      </MuiButton>
    );
  }
  return (
    <MuiBox alignItems="center" data-testid="results-error" my={`${Spacing.medium}px`}>
      <MuiTypography color={Color.primary} fontSize={FontSize.small}>
        Unable to load lab results at this time
      </MuiTypography>
    </MuiBox>
  );
};

interface RenderAlertProps {
  acknowledgeAction: (shouldDismiss: boolean) => void;
  history: H.History;
  requestAction: () => void;
  event?: AntiCoagulationEvent;
  responseText?: string;
  testDetailsData: LabResult[];
  isLoading: boolean;
  listError?: ListError;
}

export function RenderAlert({
  event,
  responseText,
  acknowledgeAction,
  requestAction,
  testDetailsData,
  isLoading,
  listError,
  history
}: RenderAlertProps) {
  if (!event) {
    return <div data-testid="no-event"></div>;
  }

  const hour = 60000 * 60;
  const past72Hours = Date.now() - new Date(event.createdAt).getTime() > hour * 72;
  return (
    <MuiContainer maxWidth="sm">
      <Box>
        <Typography fontSize={FontSize.largeHeading} fontWeight={FontWeight.semibold}>
          Test Result
        </Typography>
        <Spacer size="xsmall" />
        <Typography fontSize={FontSize.base}>{event.relatedData.toName}</Typography>
        <Spacer size="medium" />
      </Box>
      <Divider />
      <Spacer size="small" />
      <Box>
        <Typography lineHeight={26} fontSize={FontSize.large}>
          Please review your updated anticoagulation test result
        </Typography>{' '}
      </Box>
      <Spacer spacing="large" />
      <Typography fontSize={FontSize.base}>
        <Typography fontSize={FontSize.base} fontWeight={FontWeight.semibold}>
          From:{' '}
        </Typography>
        {event.relatedData.fromName}
      </Typography>
      <Spacer size="xsmall" />
      <Typography fontSize={FontSize.base}>
        <Typography fontSize={FontSize.base} fontWeight={FontWeight.semibold}>
          Subject:{' '}
        </Typography>
        {event.relatedData.subject}
      </Typography>
      <Spacer size="small" />
      <Typography fontSize={FontSize.base}>
        <Typography fontSize={FontSize.base} fontWeight={FontWeight.semibold}>
          Results:{' '}
        </Typography>
        {convertToBodyText(event.relatedData.body)}
      </Typography>
      <Spacer size="xsmall" />
      {RenderViewAllResultsButton({ testDetailsData, isLoading, listError, history })}
      <FlexBoxColumn>
        <Spacer size="medium" />

        <Typography textAlign="center" fontSize={FontSize.base}>
          {responseText}
        </Typography>

        <Spacer size="small" />
        <Box>
          <MuiButton
            color="primary"
            variant="contained"
            size="large"
            onClick={() => acknowledgeAction(past72Hours)}
            data-testid="acknowledgeButton"
            fullWidth
          >
            {past72Hours ? 'Dismiss' : 'Acknowledge'}
          </MuiButton>
          {!past72Hours ? (
            <SecondaryButton data-testid="requestButton" onPress={requestAction}>
              Request a call
            </SecondaryButton>
          ) : null}
        </Box>
      </FlexBoxColumn>
    </MuiContainer>
  );
}

export function AntiCoagulationAlertScreen(props: AntiCoagulationAlertScreenProps) {
  const {
    acknowledge,
    acknowledgeState,
    dismiss,
    dismissState,
    eventState,
    getEvent,
    history,
    setPatchEventMessage,
    testDetailsData,
    testDetailsLoading,
    getTestResultDetails
  } = props;
  const eventId = props.match.params.id;
  const { error, data, isFetching } = eventState;

  const event: AntiCoagulationEvent = data as AntiCoagulationEvent;
  const responseText =
    !!event && event.createdAt ? getAnticoagulationResponseDateText(event.createdAt) : '';
  const loading = isFetching || acknowledgeState.isFetching || dismissState.isFetching;

  const closeAntiCoagulationAlertScreen = () => {
    history.goBack();
  };

  const processResponse = (res: Action, alertState: AntiCoagulationAlertState) => {
    if (!!res && res.error) {
      setPatchEventMessage('Unable to record your response. Please try again or contact support.');
      closeAntiCoagulationAlertScreen();
    } else {
      setPatchEventMessage(modalMessages[alertState as AntiCoagulationAlertState]);
      closeAntiCoagulationAlertScreen();
    }
  };

  const AnticoagulationResponse = useNavigationAnalytics(AnalyticsEvent.AnticoagulationResponse);

  const acknowledgeAction = async (shouldDismiss?: boolean) => {
    if (shouldDismiss) {
      AnticoagulationResponse({
        response_type: 'Late Response'
      });
      const res = await dismiss(eventId);
      processResponse(res, AntiCoagulationAlertState.acknowledge);
    } else {
      AnticoagulationResponse({
        response_type: 'Acknowledge'
      });
      const res = await acknowledge({
        ihcEmpi: event?.sourceId,
        eventId: event?.id,
        sequence: '2'
      } as AntiCoagulationAcknowledgementBody);
      processResponse(res, AntiCoagulationAlertState.acknowledge);
    }
  };

  const requestAction = async () => {
    AnticoagulationResponse({
      response_type: 'Request a Call'
    });
    const res = await acknowledge({
      ihcEmpi: event?.sourceId,
      eventId: event?.id,
      sequence: '1'
    } as AntiCoagulationAcknowledgementBody);
    processResponse(res, AntiCoagulationAlertState.request);
  };

  // **********************************
  // ** GET 'VIEW ALL LAB RESULTS' DATA
  // **********************************

  // Get full list of test results
  const {
    data: resultsListData = [],
    loading: resultsListLoading,
    error: listError
  } = useGetMedicalTestResultList();

  // Find most recent INR lab, use its presentedForm url (dfd) or its mhpDetails (mhp) to fetch lab details
  const mostRecentAntiCoagLab = resultsListData.find(item => item.name.includes('INR'));
  const presentedForm = mostRecentAntiCoagLab?.presentedForm || [];
  const mhpDetails: MhpDetails = mostRecentAntiCoagLab?.mhpDetails || {};

  useEffect(() => {
    getEvent(eventId);
  }, [eventId]);

  useEffect(() => {
    if (presentedForm.length) getTestResultDetails({ ...presentedForm[0] }, mostRecentAntiCoagLab);
  }, [presentedForm, getTestResultDetails]);

  useEffect(() => {
    if (mhpDetails?.url) getTestResultDetails({ ...mhpDetails });
  }, [mhpDetails?.url]);

  const renderError = () => (
    <FlexBox flex={1} alignItems="center" justifyContent="center" testID="event-loading">
      <Typography fontSize={FontSize.base}>
        An error occurred while loading this message. Please try again or contact support.
      </Typography>
    </FlexBox>
  );

  return (
    <Dialog
      data-testid="anti-coagulation-alert"
      fullScreen
      open
      onClose={closeAntiCoagulationAlertScreen}
    >
      <Box spacing={Spacing.large}>
        <FlexBox alignItems="flex-end">
          <Icon
            aria-label="go back"
            data-testid="closeAlertScreen"
            name="close"
            size={24}
            onClick={closeAntiCoagulationAlertScreen}
          />
        </FlexBox>
        <DataLoader
          data={event}
          error={error}
          loading={loading}
          renderNoData={renderError}
          renderError={renderError}
          renderLoading={() => (
            <MuiBox
              height={window.innerHeight}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Spinner data-testid="anti-coagulation-loading" />
            </MuiBox>
          )}
          renderData={() =>
            RenderAlert({
              event,
              responseText,
              acknowledgeAction,
              requestAction,
              testDetailsData,
              isLoading: testDetailsLoading || resultsListLoading,
              listError,
              history
            })
          }
        />
      </Box>
    </Dialog>
  );
}

const mapStateToProps = (rootState: RootState) => ({
  acknowledgeState: acknowledgeEventSelector(rootState),
  dismissState: dismissEventSelector(rootState),
  eventState: selectedEventSelector(rootState),
  testDetailsData: labTestResultDataSelector(rootState),
  testDetailsLoading: testResultDetailsLoadingSelector(rootState)
});

const mapDispatchToProps = {
  acknowledge: acknowledgeEvent,
  dismiss: dismissEvent,
  getEvent,
  setPatchEventMessage: patchEventMessage,
  getTestResultDetails
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AntiCoagulationAlertScreen as ComponentType);
