import * as React from 'react';

export default () => {
  return {
    svg: (
      <g id="prefix__Test_Results" data-name="Test Results">
        <path
          fill="none"
          d="M29.465 13.9065L36.4232 16.6044C38.8523 17.4982 40.104 20.2139 39.2178 22.6709L31.4201 47.2668L37.6604 47.2659L40 52H1L3.33957 47.2659H23.7289L24.2154 44.9403L5.50769 38.054L6.57412 35.0884L25.2819 41.9759L32.0811 24.0483C32.4895 22.9149 31.9135 21.6603 30.7928 21.2472L26.9583 19.8358L25.491 23.9132M26.9566 24.4543C26.6011 25.4425 26.2457 26.4306 25.8902 27.4187L11.2313 22.0229L12.2977 19.056C12.7578 19.2256 13.2179 19.3952 13.678 19.5647M22.4802 3.49456C22.479 3.49456 21.3672 3.08528 21.3659 3.08401C21.0104 4.0734 20.655 5.06153 20.2995 6.05092L18.8309 5.51032L13.7636 19.5965M29.0925 13.9064H29.0913L29.1064 13.8631L30.5585 9.8277L29.0925 9.28837L30.1615 6.32146C30.2422 6.34823 29.5274 6.11108 28.8228 5.87649M28.7731 5.86028L29.0882 2.78754L24.2339 1L22.5359 3.43014M14.0784 23.281L12.5595 27.5497L12.228 30.7856L18.8244 33.2145L20.6219 30.5191L22.1674 26.0477L14.0784 23.281Z"
          stroke="url(#paint0_linear_2404_1772)"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <defs>
          <linearGradient
            id="paint0_linear_2404_1772"
            x1={1}
            y1={26.5}
            x2={40}
            y2={26.5}
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0.0843751} stopColor="#FF0048" />
            <stop offset={0.386458} stopColor="#DF36FF" />
            <stop offset={0.980208} stopColor="#4A00E2" />
          </linearGradient>
        </defs>
      </g>
    ),
    viewBox: '0 0 41 53'
  };
};
