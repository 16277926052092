import React from 'react';
import { SvgProps } from 'components/UI/Svg';
import { Color } from 'modules/styles/colors';

export default (props: SvgProps) => ({
  svg: (
    <g>
      <path
        d="M0 12.5C0 5.59644 5.59644 0 12.5 0C19.4036 0 25 5.59644 25 12.5C25 19.4036 19.4036 25 12.5 25C5.59644 25 0 19.4036 0 12.5ZM12.5 1.5625C6.45939 1.5625 1.5625 6.45939 1.5625 12.5C1.5625 18.5406 6.45939 23.4375 12.5 23.4375C18.5406 23.4375 23.4375 18.5406 23.4375 12.5C23.4375 6.45939 18.5406 1.5625 12.5 1.5625ZM12.5 4.6875C12.9315 4.6875 13.2812 5.03728 13.2812 5.46875V14.0625C13.2812 14.494 12.9315 14.8437 12.5 14.8437C12.0685 14.8437 11.7188 14.494 11.7188 14.0625V5.46875C11.7188 5.03728 12.0685 4.6875 12.5 4.6875ZM11.3281 17.5781C11.3281 16.9309 11.8528 16.4062 12.5 16.4062C13.1472 16.4062 13.6719 16.9309 13.6719 17.5781C13.6719 18.2253 13.1472 18.75 12.5 18.75C11.8528 18.75 11.3281 18.2253 11.3281 17.5781Z"
        fill={props?.color || Color.black}
      />
    </g>
  ),
  viewBox: '0 0 25 25'
});
