import React, { ReactNode, useMemo, useEffect, useState } from 'react';
import { EventEmitter } from 'services/EventEmitter';
import startCase from 'lodash/startCase';
import { RouteData } from 'screens/ConnectCare/types';
import { ConnectCareButton } from '../ConnectCareButton';
import { ConnectCareStepperProps } from './index';

export interface ConnectCareStepActionProps<T = RouteData> {
  onClick?: (step?: T) => void;
  children?: ReactNode;
  disabled?: boolean;
  loading?: boolean;
  label?: string;
  variant: 'previous' | 'next';
}

export const stepActionBus = new EventEmitter();

class StepAction {
  static timer: number;

  static setStepper<T>(props: ConnectCareStepperProps<T>) {
    if (StepAction.timer) {
      clearTimeout(StepAction.timer);
    }
    StepAction.timer = setTimeout(() => stepActionBus.emit('setStepper', props), 0);
  }
}

function ConnectCareStepAction<T>({
  onClick,
  label,
  disabled,
  loading,
  variant
}: ConnectCareStepActionProps<T>) {
  const [steps, setSteps] = useState([]);
  const [activeStep, setActiveStep] = useState(-1);
  const upcomingStepCount = useMemo(() => {
    return variant === 'previous' ? activeStep - 1 : activeStep + 1;
  }, [variant, activeStep]);

  useEffect(() => {
    const unsubscribe = stepActionBus.on('setStepper', props => {
      setActiveStep(props.activeStep);
      setSteps(props.steps);
    });
    return () => unsubscribe();
  }, [stepActionBus]);

  const handleClick = () => {
    if (onClick) {
      onClick(steps[upcomingStepCount]);
    }
  };

  return (
    <ConnectCareButton
      color="primary"
      variant={variant === 'next' ? 'contained' : 'outlined'}
      loading={loading}
      disabled={disabled}
      onClick={handleClick}
      data-testid="connect-care-step-button"
      minWidth={160}
    >
      {label || startCase(variant)}
    </ConnectCareButton>
  );
}

ConnectCareStepAction.defaultProps = {
  variant: 'next'
};

export const useConnectCareStepAction = () => ({
  setStepper: StepAction.setStepper
});

export default ConnectCareStepAction;
