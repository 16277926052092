import React from 'react';
import Svg, { SvgProps } from './Svg';
import { IconButton } from './styled';

export interface SvgButtonProps extends SvgProps {
  // multiples of 8 [ex: if spacing = 1 then padding will be 8px]
  spacing?: number;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  appearance?: 'transparent' | 'box';
  disableTouchRipple?: boolean;
  disableFocusRipple?: boolean;
  style?: React.CSSProperties;
  disabled?: boolean;
  edge?: 'start' | 'end';
  width?: number;
  height?: number;
  size?: number;
}

function SvgButton({
  onClick,
  appearance,
  style,
  accessibilityLabel,
  disabled,
  disableTouchRipple,
  disableFocusRipple,
  width,
  height,
  size,
  spacing,
  edge,
  ...rest
}: SvgButtonProps) {
  return (
    <IconButton
      role="button"
      onClick={onClick}
      disabled={disabled}
      appearance={appearance}
      aria-label={accessibilityLabel}
      spacing={spacing}
      edge={edge}
      disableTouchRipple={disableTouchRipple}
      disableFocusRipple={disableFocusRipple}
      style={{
        width: size || width || 'auto',
        height: size || height || 'auto',
        ...style
      }}
    >
      <Svg size={size} {...rest} />
    </IconButton>
  );
}

SvgButton.defaultProps = {
  appearance: 'box',
  spacing: 1.5
};

export default SvgButton;
