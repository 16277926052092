import React from 'react';
import { connect } from 'react-redux';

import { Grid } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import RequiredLabel from 'components/UI/Labels/RequiredLabel';
import FlexBox from 'components/UI/Layout/FlexBox';
import Spacer from 'components/UI/Layout/Spacer';
import SmallText from 'components/UI/Typography/SmallText';
import { Formik } from 'formik';
import { MuiBox, MuiDivider, MuiMenuItem, MuiTypography } from 'theme/material-ui';
import { KeyboardDatePicker } from '@material-ui/pickers';
import dayjs from 'dayjs';

import {
  COVID_SCREENING_LEGAL_SEX_TEXT,
  SEX_OPTIONS,
  SexOptionsType,
  StateOptionsType,
  submitSubscriberInfoValidation
} from 'modules/constants/covidScreening/personalInfo';
import { LOCATION_STATES } from 'modules/constants/LocationStates';
import { patientSelect } from 'modules/constants/covidScreening';

import * as covidActions from 'store/CovidScreening/actions';
import { CovidScreeningSubscriberSelector } from 'store/CovidScreening/selectors';
import { Subscriber } from 'store/CovidScreening/types';
import { profileConsumerSelector } from 'store/profile/selectors';
import { RootState } from 'store/types';

import { InfoTooltip } from 'components/InfoTooltip';
import { NavigationScreenProps } from 'screens/navigation';
import CustomTextField from 'screens/Register/FormElements/CustomTextField';
import { CovidTestButton } from '../Components/CovidTestButton';
import Footer from 'components/common/Footer';
import useStyles from '../Components/CovidTestButton/useStyles';
import { StyledSelect } from './styled';
import { CovidTitles } from 'lib/constants/covidScreening';
import { AnalyticsEvent } from 'services/AnalyticsService';
import useNavigationAnalytics from 'hooks/useNavigationAnalytics';
import { convertToLowerKabobCase } from 'modules/utils/StringUtils';

export interface CovidSubscriberInfoScreenProps extends NavigationScreenProps {
  subscriberInfo: Subscriber;
  setSubscriberInfo: typeof covidActions.setSubscriber;
}

// Todo: Refacto with components/CovidTest/SubscriberInformation/Form/index.tsx
export function CovidSubscriberInfoScreen({
  subscriberInfo,
  setSubscriberInfo,
  history
}: CovidSubscriberInfoScreenProps) {
  const initialValues = {
    subscriberFirstName: subscriberInfo?.subscriberFirstName ?? '',
    subscriberMiddleName: subscriberInfo?.subscriberMiddleName ?? '',
    subscriberLastName: subscriberInfo?.subscriberLastName ?? '',
    subscriberDateOfBirth: subscriberInfo?.subscriberDateOfBirth ?? '',
    subscriberSex: subscriberInfo?.subscriberSex?.toUpperCase() ?? '',
    subscriberAddressLine1: subscriberInfo?.subscriberAddressLine1 ?? '',
    subscriberAddressLine2: subscriberInfo?.subscriberAddressLine2 ?? '',
    subscriberCity: subscriberInfo?.subscriberCity ?? '',
    subscriberState: subscriberInfo?.subscriberState ?? '',
    subscriberZip: subscriberInfo?.subscriberZip ?? '',
    subscriberEmail: subscriberInfo?.subscriberEmail ?? '',
    subscriberCellNumber: subscriberInfo?.subscriberCellNumber ?? ''
  };

  const classes = useStyles();
  interface FormValues {
    subscriberFirstName: string;
    subscriberMiddleName: string;
    subscriberLastName: string;
    subscriberDateOfBirth: string;
    subscriberSex: string;
    subscriberAddressLine1: string;
    subscriberAddressLine2: string;
    subscriberCity: string;
    subscriberState: string;
    subscriberZip: string;
    subscriberEmail: string;
    subscriberPhone: string;
  }

  const saveToStore = (values: FormValues) => {
    const updatedSubscriberdata = {
      subscriberFirstName: values?.subscriberFirstName,
      subscriberMiddleName: values?.subscriberMiddleName,
      subscriberLastName: values?.subscriberLastName,
      subscriberDateOfBirth: values?.subscriberDateOfBirth,
      subscriberSex: values?.subscriberSex?.toUpperCase(),
      subscriberAddressLine1: values?.subscriberAddressLine1,
      subscriberAddressLine2: values?.subscriberAddressLine2,
      subscriberCity: values?.subscriberCity,
      subscriberState: values?.subscriberState,
      subscriberZip: values?.subscriberZip,
      subscriberEmail: values?.subscriberEmail,
      subscriberCellNumber: values?.subscriberCellNumber
    };
    setSubscriberInfo(updatedSubscriberdata);
  };

  const covidScreeningNextBtn = useNavigationAnalytics(AnalyticsEvent.CovidNext, {
    title: CovidTitles.SUBSCRIBER_INFO
  });

  const covidScreeningPreviousBtn = useNavigationAnalytics(AnalyticsEvent.CovidPrev, {
    title: CovidTitles.SUBSCRIBER_INFO
  });

  const handleSubmit = (values: FormValues) => {
    saveToStore(values);
    covidScreeningNextBtn();
    history.push('/u/get-care-now/covid-screen/covid-insurance-upload');
  };

  const onCancelClick = values => {
    saveToStore(values);
    covidScreeningPreviousBtn();
    history.goBack();
  };

  const checkDate = values =>
    !dayjs().isValid(values.symptomsBeganDate) || values.symptomsBeganDate > new Date();

  return (
    <>
      <MuiBox pl={6} my={2}>
        <MuiTypography variant="h6">Subscriber Information</MuiTypography>
      </MuiBox>
      <MuiBox mx={3}>
        <MuiDivider />
      </MuiBox>
      <Container maxWidth="md">
        <Spacer size="xsmall" />

        <Formik
          enableReinitialize="true"
          validateOnMount
          initialValues={initialValues}
          validationSchema={submitSubscriberInfoValidation}
        >
          {({
            values,
            isValid,
            touched,
            errors,
            handleBlur,
            handleChange,
            setFieldTouched,
            setFieldValue
          }) => {
            return (
              <>
                <Spacer size="medium" />
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <RequiredLabel labelText="Subscriber first name" />
                    <CustomTextField
                      name="subscriberFirstName"
                      variant="outlined"
                      value={values?.subscriberFirstName}
                      data-testid="subscriber-info-first-name"
                      onChange={handleChange('subscriberFirstName')}
                      required
                      placeholder="Enter first name"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <RequiredLabel hideIndicator labelText="Subscriber middle name" />
                    <CustomTextField
                      name="subscriberMiddleName"
                      variant="outlined"
                      value={values?.subscriberMiddleName}
                      data-testid="subscriber-info-middle-name"
                      onChange={handleChange('subscriberMiddleName')}
                      placeholder="Enter middle name"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <RequiredLabel labelText="Subscriber last name" />
                    <CustomTextField
                      name="subscriberLastName"
                      variant="outlined"
                      value={values?.subscriberLastName}
                      data-testid="subscriber-info-last-name"
                      onChange={handleChange('subscriberLastName')}
                      required
                      placeholder="Enter last name"
                      maxLength={26}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <RequiredLabel labelText="Subscriber date of birth" />
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      inputVariant="outlined"
                      format="MM/DD/YYYY"
                      value={values?.subscriberDateOfBirth || null}
                      onChange={value => {
                        setFieldValue('subscriberDateOfBirth', value, true);
                      }}
                      onBlur={handleBlur('subscriberDateOfBirth')}
                      maxDateMessage="Please enter a valid date of birth"
                      minDateMessage="Please enter a valid date of birth"
                      maxDate={new Date()}
                      name="subscriberDateOfBirth"
                      data-testid="subscriber-date-of-birth"
                      placeholder="MM/DD/YYYY"
                      required
                      autoOk
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FlexBox width="100%">
                      <FlexBox flexDirection="row" justifyContent="flex-start" alignItems="center">
                        <RequiredLabel labelText="Subscriber Sex" />
                        <MuiBox display="flex" justifyContent="flex-end">
                          <InfoTooltip>{COVID_SCREENING_LEGAL_SEX_TEXT}</InfoTooltip>
                        </MuiBox>
                      </FlexBox>
                      <StyledSelect
                        value={values.subscriberSex}
                        onChange={handleChange('subscriberSex')}
                        data-testid="subscriber-sex"
                        variant="outlined"
                      >
                        {SEX_OPTIONS.map((option: SexOptionsType) => {
                          return (
                            <MuiMenuItem
                              style={{ whiteSpace: 'normal' }}
                              data-testid={convertToLowerKabobCase(option.value, '-subscriber-sex')}
                              key={option.label}
                              value={option.value}
                            >
                              {option.label}
                            </MuiMenuItem>
                          );
                        })}
                      </StyledSelect>
                    </FlexBox>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <RequiredLabel labelText="Subscriber Address line 1" />
                    <CustomTextField
                      name="subscriberAddressLine1"
                      variant="outlined"
                      value={values?.subscriberAddressLine1}
                      onChange={handleChange('subscriberAddressLine1')}
                      data-testid="subscriber-address-line1"
                      onBlur={() => {
                        setFieldTouched('subscriberAddressLine1');
                      }}
                      placeholder="Enter address"
                      required
                      maxLength={50}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <RequiredLabel hideIndicator labelText="Subscriber Address line 2" />
                    <CustomTextField
                      name="subscriberAddressLine2"
                      variant="outlined"
                      value={values?.subscriberAddressLine2}
                      onChange={handleChange('subscriberAddressLine2')}
                      data-testid="subscriber-address-line2"
                      maxLength={50}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <RequiredLabel labelText=" Subscriber City" />
                    <CustomTextField
                      name="subscriberCity"
                      variant="outlined"
                      value={values?.subscriberCity}
                      onChange={handleChange('subscriberCity')}
                      data-testid="subscriber-city"
                      onBlur={() => {
                        setFieldTouched('subscriberCity');
                      }}
                      placeholder="Enter City"
                      required
                      maxLength={30}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <FlexBox width="100%">
                      <RequiredLabel labelText="Subscriber State" />
                      <StyledSelect
                        variant="outlined"
                        value={values?.subscriberState}
                        onChange={handleChange('subscriberState')}
                        data-testid="subscriber-state"
                      >
                        {LOCATION_STATES.map((option: StateOptionsType) => (
                          <MuiMenuItem
                            style={{ whiteSpace: 'normal' }}
                            data-testid={convertToLowerKabobCase(option.value, '-subscriber-state')}
                            key={option.label}
                            value={option.value}
                          >
                            {option.label}
                          </MuiMenuItem>
                        ))}
                      </StyledSelect>
                      {errors?.subscriberState && touched.subscriberState ? (
                        <SmallText color="red">{errors?.subscriberState}</SmallText>
                      ) : null}
                    </FlexBox>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <RequiredLabel labelText="Subscriber Zip" />
                    <CustomTextField
                      name="subscriberZip"
                      data-testid="subscriber-zip"
                      variant="outlined"
                      value={values?.subscriberZip}
                      onChange={handleChange('subscriberZip')}
                      mask="zip"
                      placeholder="Enter Zip"
                      required
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <RequiredLabel labelText="Subscriber Email" />
                    <CustomTextField
                      name="subscriberEmail"
                      variant="outlined"
                      value={values?.subscriberEmail}
                      onChange={handleChange('subscriberEmail')}
                      data-testid="subscriber-email"
                      onBlur={() => {
                        setFieldTouched('subscriberEmail');
                      }}
                      placeholder="Enter email"
                      required
                      maxLength={30}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <RequiredLabel labelText="Subscriber Phone" />
                    <CustomTextField
                      name="subscriberCellNumber"
                      variant="outlined"
                      value={values?.subscriberCellNumber}
                      onChange={handleChange('subscriberCellNumber')}
                      data-testid="subscriber-cell-number"
                      onBlur={() => {
                        setFieldTouched('subscriberCellNumber');
                      }}
                      placeholder="Enter phone number"
                      required
                      mask="phone"
                    />
                  </Grid>
                </Grid>
                <Spacer size="medium" />
                <MuiBox className={classes.steppersFooter}>
                  <CovidTestButton
                    fullWidth
                    disabled={checkDate(values) || !isValid}
                    data-testid="subscriber-info-next-btn"
                    color="primary"
                    variant="contained"
                    onClick={() => handleSubmit(values)}
                  >
                    Next
                  </CovidTestButton>
                  <Spacer size="xsmall" />
                  <CovidTestButton
                    variant="text"
                    data-testid="subscriber-info-previous-btn"
                    fullWidth
                    onClick={() => onCancelClick(values)}
                  >
                    Previous
                  </CovidTestButton>
                </MuiBox>
                <Footer
                  headerText={patientSelect.GET_PATIENTS.HELPLINE.CovidInfo}
                  link={patientSelect.GET_PATIENTS.HELPLINE.CovidPatientSelectHelpline}
                  linkName={patientSelect.GET_PATIENTS.HELPLINE.CovidPatientSelectHelpline}
                  helplineHours={patientSelect.GET_PATIENTS.HELPLINE.CovidHelplineHours}
                  title={CovidTitles.SUBSCRIBER_INFO}
                />
              </>
            );
          }}
        </Formik>
      </Container>
    </>
  );
}

const mapStateToProps = (state: RootState) => ({
  consumer: profileConsumerSelector(state),
  subscriberInfo: CovidScreeningSubscriberSelector(state)
});

const mapDispatchToProps = {
  setSubscriberInfo: covidActions.setSubscriber
};

export default connect(mapStateToProps, mapDispatchToProps)(CovidSubscriberInfoScreen);
