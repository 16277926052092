import React from 'react';
import styled from 'styled-components';
import { JsonObj } from '@mhp/general-interface';
import { Color } from 'modules/styles/colors';

interface Props {
  deductible: JsonObj;
  capMath: JsonObj;
  barColor?: string;
  backColor?: string;
}

const Background = styled.div`
  background-color: ${props => props.backColor};
  border-radius: 8px;
  height: 15px;
  width: 100%;
  margin: 5px 0px 10px 0px;
`;

const Bar = styled.div`
  background-color: ${props => props.barColor};
  height: 15px;
  position: absolute;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border-top-right-radius: ${props => (props.alreadyPaid > 95 ? '8px' : '0px')};
  border-bottom-right-radius: ${props => (props.alreadyPaid > 95 ? '8px' : '0px')};
  width: ${props => `${props.alreadyPaid}%` || '0%'};
`;

const EndCap = styled.div`
  background-color: ${Color.tealLight};
  height: 15px;
  position: absolute;
  border-top-right-radius: ${props => props.capMath.borderRadiusRight};
  border-bottom-right-radius: ${props => props.capMath.borderRadiusRight};
  border-top-left-radius: ${props => props.capMath.borderRadiusLeft}};
  border-bottom-left-radius: ${props => props.capMath.borderRadiusLeft};
  width: ${props => `${props.capMath.width}%`};
  left: ${props => `${props.alreadyPaid}%` || '0%'};
`;

const Progress = ({
  deductible,
  capMath,
  barColor = Color.tealLight,
  backColor = Color.tealTint
}: Props) => (
  <Background backColor={backColor}>
    <Bar barColor={barColor} alreadyPaid={deductible.alreadyPaidPercentage} />
    {deductible.appliedSpendValue > 0 && (
      <EndCap alreadyPaid={deductible.alreadyPaidPercentage} capMath={capMath} />
    )}
  </Background>
);

export default Progress;
