import * as React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import { RootState } from 'store/types';
import { registerCurrentStepSelector } from 'store/register/selectors';

const StepRoute = ({ children, currentStep, ...rest }) => (
  <Route
    {...rest}
    render={({ location }) =>
      currentStep === location.pathname.split('/').pop() ? (
        children
      ) : (
        <Redirect
          to={{
            pathname: currentStep,
            state: { from: location }
          }}
        />
      )
    }
  />
);

const mapStateToProps = (state: RootState) => ({
  currentStep: registerCurrentStepSelector(state)
});

export default connect(mapStateToProps)(StepRoute);
