import { AppName, getAppName } from 'modules/utils/ConfigUtils';

export const TCPA_URI = 'https://intermountainhealthcare.org/displayaca/aca/269707/';
export const TCPA_TITLE = 'TCPA Authorization';
export const SHORTCODE_TERMS_URI = 'https://intermountainhealthcare.org/displayaca/aca/271357/';
export const SHORTCODE_PRIVACY_POLICY_URI = 'https://www.twilio.com/legal/privacy';
export const TCPA_TEXT = `Important: If you opt in to receive calls or texts, you
consent to receiving auto-dialed or pre-recorded voice messages from us to the
phone number associated with your account. Message and data rates may
apply. Regardless of your notification preferences, we may send you important
messages relating to your healthcare where authorized by law.`;

export const SHORTCODE_LEGAL_TEXT = `You are subscribing to the ${getAppName(
  AppName.secondary
)} short code notifications program and you will
receive text messages from 32037. Message and data rates apply. Message frequency
will vary.`;
export const SHORTCODE_TITLE = 'SMS/Short Code Authorization';
export const E_SIGNATURE_TERMS = 'E-Signature Terms';
export const CONFIRM_AND_SIGN = 'Confirm and Sign';
export const DISCLAIMER = 'You must read all terms before continuing';

export const REVOKING_HIPAA_DISCLAIMER =
  'Federal regulations require a signature on behalf of each user for whom you wish to receive health-related notifications. Your account is eligible to receive health-related notifications associated with the following user. Note that revoking authorization for yourself will disable all notifications, while revoking authorization for other users will only disable notifications associated with them. Click "REVOKE" to complete your signature.';
export const AUTHORIZING_HIPAA_DISCLAIMER =
  ' Federal regulations require a signature on behalf of each user for whom you wish to receive health-related notifications. Your account is eligible to receive health-related notifications associated with the following user. Tap "Confirm and Sign" to complete your signature.';

export const toggleType = {
  categories: 'categories',
  channels: 'channels'
};

export const hipaaType = {
  authorization: 'authorization',
  revocation: 'revocation'
};
