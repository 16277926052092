import React from 'react';

export default {
  svg: (
    <g>
      <g>
        <circle fill="#7CAFD0" strokeWidth={0} cx={40} cy={40} r={38.5} />
        <path
          fill="#FFFFFF"
          strokeWidth={0}
          d="M40,2.5c20.711,0,37.5,16.789,37.5,37.5S60.711,77.5,40,77.5S2.5,60.711,2.5,40S19.289,2.5,40,2.5 M40,0.5 C18.22,0.5,0.5,18.22,0.5,40S18.22,79.5,40,79.5S79.5,61.78,79.5,40S61.78,0.5,40,0.5L40,0.5z"
        />
      </g>
      <g>
        <defs>
          <circle id="SVGID_1_" cx={40} cy={40} r={37.5} />
        </defs>
        <clipPath id="SVGID_2_12">
          <use xlinkHref="#SVGID_1_" />
        </clipPath>
        <g id="Male_-_Child_1_" clipPath="url(#SVGID_2_12)">
          <path
            fill="#F2AE75"
            strokeWidth={0}
            d="M56.211,36.292l0.517-6.718c0-8.785-8.397-15.903-17.175-15.903c-8.785,0-17.175,7.118-17.175,15.903 l0.516,6.711c-0.491,0.119-0.935,0.344-1.305,0.661c-0.687,0.585-1.12,1.45-1.12,2.424v3.308c0,1.749,1.408,3.168,3.161,3.179 c0,5.339,4.13,10.496,8.66,13.496v7.094l-2.389,1.031l0.636,21.445h1.753v3.288h15.267v-3.288H49.3l1.021-21.394l-2.764-1.082 v-7.562h-0.064c4.256-3.05,7.981-7.956,7.981-13.027c1.746-0.012,3.158-1.429,3.158-3.178v-3.308 C58.634,37.878,57.6,36.634,56.211,36.292z"
          />
          <path
            fill="#E99260"
            strokeWidth={0}
            d="M47.562,59.321l-0.039,6.809l-15.233-3.226l-0.155-3.768l0.699,0.699 c2.441,1.749,4.527,1.931,6.715,2.138V48.34h-3.817c0,0,1.496-7.151,3.817-12.086c0,0,0-22.582,0.003-22.582 c8.778,0,17.175,7.118,17.175,15.903l-0.517,6.718c1.389,0.341,2.422,1.586,2.422,3.08v3.308c0,1.749-1.412,3.166-3.158,3.178 c0,5.064-3.713,9.965-7.962,13.015L47.562,59.321z"
          />
          <g>
            <g>
              <path
                fill="#EC9B68"
                strokeWidth={0}
                d="M34.746,38.087c0.048,1.387-1.036,2.55-2.423,2.598c-1.387,0.048-2.55-1.036-2.598-2.423 c-0.048-1.387,1.036-2.55,2.423-2.598C33.534,35.616,34.697,36.7,34.746,38.087z"
              />
              <path
                fill="#3C2417"
                strokeWidth={0}
                d="M34.746,37.451c0.048,1.387-1.036,2.55-2.423,2.598c-1.387,0.048-2.55-1.036-2.598-2.423 c-0.048-1.387,1.036-2.55,2.423-2.598C33.534,34.979,34.697,36.064,34.746,37.451z"
              />
              <path
                fill="#FFFFFF"
                strokeWidth={0}
                d="M33.716,36.733c0.012,0.347-0.259,0.637-0.606,0.65c-0.347,0.012-0.637-0.259-0.65-0.606 c-0.012-0.347,0.259-0.637,0.606-0.65C33.413,36.115,33.704,36.386,33.716,36.733z"
              />
            </g>
            <g>
              <path
                fill="#DB8258"
                strokeWidth={0}
                d="M49.376,38.087c0.048,1.387-1.036,2.55-2.423,2.598s-2.55-1.036-2.598-2.423 c-0.048-1.387,1.036-2.55,2.423-2.598C48.164,35.616,49.328,36.7,49.376,38.087z"
              />
              <path
                fill="#3C2417"
                strokeWidth={0}
                d="M49.376,37.451c0.048,1.387-1.036,2.55-2.423,2.598c-1.387,0.048-2.55-1.036-2.598-2.423 c-0.048-1.387,1.036-2.55,2.423-2.598C48.164,34.979,49.328,36.064,49.376,37.451z"
              />
              <path
                fill="#FFFFFF"
                strokeWidth={0}
                d="M48.346,36.733c0.012,0.347-0.259,0.637-0.606,0.65c-0.347,0.012-0.637-0.259-0.65-0.606 c-0.012-0.347,0.259-0.637,0.606-0.65C48.043,36.115,48.334,36.386,48.346,36.733z"
              />
            </g>
          </g>
          <g>
            <path
              fill="#FFFFFF"
              strokeWidth={0}
              d="M45.277,51.211c0,2.81-2.563,5.089-5.725,5.089c-3.162,0-5.725-2.278-5.725-5.089H45.277z"
            />
          </g>
          <g>
            <path
              fill="#FDB415"
              strokeWidth={0}
              d="M78.621,102.5c-0.776-8.388-2.894-16.325-4.143-23.629c-1.218-7.129-11.662-7.131-24.079-11.796 l-0.568,2.131c-0.981,2.812-3.498,7.411-9.474,7.411c-6.742,0-9.313-5.855-10.111-8.392l-0.306-1.15 C17.524,71.74,7.08,71.742,5.861,78.871C4.613,86.175,2.494,94.112,1.719,102.5H78.621z"
            />
          </g>
          <g>
            <path
              fill="#FFE609"
              strokeWidth={0}
              d="M40.018,76.616c-6.742,0-9.313-5.855-10.111-8.392l-0.306-1.15C17.184,71.74,6.74,71.742,5.521,78.871 C4.273,86.175,2.155,94.112,1.379,102.5H39.83L40.018,76.616z"
            />
          </g>
          <path
            strokeWidth={0}
            d="M56.939,34.884c0,0-6.872-4.581-7.444-10.308c-3.436,2.291-8.025,4.81-14.889,5.154c1.365-0.955,0,0,2.291-2.291 c-4.59,0.114-6.299,2.863-8.017,2.291c1.594-1.566,0.573-2.863,0.573-2.863c-2.863,1.145-6.309,5.726-6.309,8.017 c-1.833-4.887-1.708-20.615,13.754-22.333c13.743-1.718,16.791,4.006,19.47,9.735C58.084,25.149,56.939,34.884,56.939,34.884z"
          />
        </g>
      </g>
    </g>
  ),
  viewBox: '0 0 80 80'
};
