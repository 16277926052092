import React, { ComponentType } from 'react';
import { connect, useDispatch } from 'react-redux';
import { RootState } from 'store/types';
import { History } from 'lib/history';
import {
  upcomingAppointmentForConsumerSelector,
  upcomingAppointmentsErrorSelector,
  upcomingAppointmentsFetchingSelector
} from 'store/booking/appointments/upcomingAppointments/selectors';
import { BookedAppointment } from 'store/booking/appointments/types';
import InfoCard from 'components/InfoCard/InfoCard';
import dayjs from 'dayjs';
import upperCase from 'lodash/upperCase';
import { useHistory } from 'react-router';
import { MAX_APPOINTMENTS_THRESHOLD } from 'lib/dashboard/types';
import useBookingNavigationSetup from 'hooks/useBookingNavigationSetup';
import useNavigationAnalytics from 'hooks/useNavigationAnalytics';
import { AnalyticsEvent } from 'services/AnalyticsService';
import DataLoader from 'components/UI/DataLoader/DataLoader';
import Svg from 'components/UI/Svg/Svg';
import Box from 'components/UI/Layout/Box';
import FlexBox, { FlexBoxRow, FlexBoxColumn } from 'components/UI/Layout/FlexBox';
import { AnimatedEllipsis } from 'components/AnimatedEllipsis';
import DashboardEmptyStateCard from '../DashboardEmptyStateCard';
import { MuiTypography, MuiBox, MuiListItem } from 'theme/material-ui';
import { Color } from 'modules/styles/colors';
import Typography from 'components/UI/Typography';
import { Spacing, FontSize, FontWeight } from 'modules/styles/variables';
import { getUpcomingAppointments } from 'store/booking/appointments/upcomingAppointments/actions';
import { useProxySwitcherEffect } from 'hooks/useProxySwitcherEffect';
import { formatDateOrdinal, formatTime, formatTimeWithTimeZone } from 'modules/utils/DateUtils';
import { useLanguageSwitcher } from 'lib/hooks/useLanguageSwitcher';
import { DashboardAppointments as translations } from 'lib/constants/translations/components/dashboard';

export interface Props {
  loading: boolean;
  error: Error | null;
  data: BookedAppointment[];
}

export const DashboardAppointments = (props: Props) => {
  const screenText = useLanguageSwitcher(translations);
  const { loading, error, data = [] } = props;
  const history = useHistory<History>();

  const threeMostRecentAppt: BookedAppointment[] = data?.slice(0, 3);

  const dispatch = useDispatch();

  useProxySwitcherEffect(() => {
    dispatch(getUpcomingAppointments());
  }, []);

  const navigateToBooking = useBookingNavigationSetup();
  const dashboardScheduleAppointmentClicked = useNavigationAnalytics(
    AnalyticsEvent.DashboardScheduleAppointmentClicked
  );

  const handleScheduleApptClicked = () => {
    dashboardScheduleAppointmentClicked();
    navigateToBooking();
  };

  const DashboardAppointmentsError = () => (
    <FlexBox width="100%" padding={Spacing.xLarge} justifyContent="center" alignItems="center">
      <Typography textAlign="center">
        Sorry, your appointments didn't load.
        <Typography textAlign="center">Refresh or come back in a bit!</Typography>
      </Typography>
    </FlexBox>
  );

  const DashboardAppointmentsEmptyState = () => (
    <DashboardEmptyStateCard
      iconName="Appointment"
      title="No Upcoming Appointments"
      subtitle="You haven't scheduled an appointment yet. When you do they will show up here."
      buttonText="Schedule appointment"
      buttonOnPress={handleScheduleApptClicked}
    />
  );

  const DashboardAppointmentsLoading = () => (
    <MuiBox py={5} display="flex" flexDirection="column" alignItems="center">
      <MuiTypography>Loading upcoming appointments</MuiTypography>

      <MuiBox marginTop={Spacing.small}>
        <AnimatedEllipsis />
      </MuiBox>
    </MuiBox>
  );

  const DashboardAppointmentsData = () => (
    <>
      {threeMostRecentAppt?.map((appt: BookedAppointment, i: number) => {
        const date = dayjs(appt.appointmentDate).format('MMM,DD');
        const splitDate = date.split(',');
        const time = formatTime(appt?.appointmentDate);
        const timeWithTz = formatTimeWithTimeZone(appt?.appointmentDate);
        const fullDate = formatDateOrdinal(appt?.appointmentDate);
        return (
          <MuiListItem
            data-testid="upcoming-appointment-item"
            key={appt.appointmentId}
            button
            divider={i < 2}
            disableGutters
            onClick={() =>
              history.push('/u/get-care-now', {
                expandAppointments: true,
                openById: appt.appointmentId
              })
            }
          >
            <FlexBoxRow width="100%" hSpacing={Spacing.medium}>
              <FlexBoxColumn
                alignItems="center"
                borderRadius="8px"
                backgroundColor={Color.accent}
                justifyContent="center"
                width="63px"
                height="63px"
              >
                <FlexBox vSpacingTop={Spacing.xSmall} vSpacingBottom={Spacing.none}>
                  <Typography
                    fontSize={FontSize.small}
                    color={Color.white}
                    lineHeight={FontSize.small}
                  >
                    {upperCase(splitDate[0])}
                  </Typography>
                </FlexBox>
                <FlexBox
                  vSpacingTop={Spacing.smallMedium}
                  backgroundColor={Color.accentTint}
                  height="100%"
                  alignItems="center"
                  width="100%"
                >
                  <Typography
                    style={{ textShadow: `0px 4px 4px ${Color.accent}` }}
                    fontSize={FontSize.mediumHeading}
                    fontWeight={FontWeight.semibold}
                    color={Color.white}
                  >
                    {splitDate[1]}
                  </Typography>
                </FlexBox>
                {appt.displayTime ? (
                  <FlexBox vSpacing={Spacing.xSmall}>
                    <Typography
                      fontSize={FontSize.xsmall}
                      color={Color.white}
                      lineHeight={FontSize.xsmall}
                    >
                      {time}
                    </Typography>
                  </FlexBox>
                ) : null}
              </FlexBoxColumn>

              <FlexBoxColumn overflowY="hidden" hSpacingLeft={Spacing.large} flex={4}>
                <MuiTypography noWrap>{appt.providerDisplayName}</MuiTypography>
                <MuiTypography noWrap>{appt.locationDisplay}</MuiTypography>
                {appt.displayTime && appt.appointmentDate ? (
                  <MuiTypography noWrap>
                    {fullDate} - {timeWithTz}
                  </MuiTypography>
                ) : null}
              </FlexBoxColumn>

              <FlexBox alignItems="center" justifyContent="center" flex={1}>
                <Svg set="material" name="keyboard_arrow_right" />
              </FlexBox>
            </FlexBoxRow>
          </MuiListItem>
        );
      })}
    </>
  );

  const handleOnButtonClick = () => {
    if (loading) return null;
    if (data?.length >= MAX_APPOINTMENTS_THRESHOLD)
      return () => history.push('/u/get-care-now', { expandAppointments: true });
    if (data?.length <= MAX_APPOINTMENTS_THRESHOLD && data?.length > 0)
      return () => handleScheduleApptClicked();
    return null;
  };

  const getButtonText = () => {
    if (data?.length >= MAX_APPOINTMENTS_THRESHOLD) return screenText?.btnText;
    if (data?.length <= MAX_APPOINTMENTS_THRESHOLD && data?.length > 0) return screenText?.btnText2;
    return null;
  };

  return (
    <InfoCard
      title={screenText?.title}
      buttonOnClick={handleOnButtonClick()}
      buttonText={getButtonText()}
      buttonTestID="view-all-appointments"
    >
      <Box>
        <DataLoader
          data={data}
          error={error}
          loading={loading}
          renderError={() => <DashboardAppointmentsError />}
          renderNoData={() => <DashboardAppointmentsEmptyState />}
          renderLoading={() => <DashboardAppointmentsLoading />}
          renderData={() => <DashboardAppointmentsData />}
        />
      </Box>
    </InfoCard>
  );
};

const mapStateToProps = (state: RootState) => ({
  loading: upcomingAppointmentsFetchingSelector(state),
  error: upcomingAppointmentsErrorSelector(state),
  data: upcomingAppointmentForConsumerSelector(state)
});

export default connect(mapStateToProps)(DashboardAppointments as ComponentType);
