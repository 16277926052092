/* CostEstimator.tsx */
import Spacer from 'components/UI/Layout/Spacer';
import Spinner from 'components/UI/Spinner/Spinner';
import Svg from 'components/UI/Svg/Svg';
import DefaultText from 'components/UI/Typography/DefaultText';
import React, { ComponentType, useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ROUTES as CostEstimatorRoutes } from 'screens/Cost/router';
import analyticsService, { AmplitudeEventData, AnalyticsEvent } from 'services/AnalyticsService';
import { JsonObj } from '@mhp/general-interface';
import {
  currentLocationPathNameSelector,
  previousLocationPathNameSelector
} from 'store/router/selectors';
import TermsAndConditions from 'screens/TermsAndConditions/TermsAndConditions';
import formatMoney from 'services/formatMoney';
import { Color } from 'modules/styles/colors';
import { convertToLowerKabobCase } from 'modules/utils/StringUtils';
import { currentAccountConsumerFullNameSelector } from 'store/account/selectors';
import {
  getPlanBenefits,
  selectSearchResult,
  setupGuidedSearch,
  getSearchResults,
  setCostEstimatorSearchText
} from 'store/cost/actions';
import * as geolocationSelectors from 'store/geolocation/selectors';
import { slcGeoCoords } from 'store/geolocation/constants';
import { Coordinate } from 'modules/types/common';
import * as costSelectors from 'store/cost/selectors';
import { GuidedSearchTopic } from 'store/cost/types';
import { TermsAndConditionsType } from 'store/global/reducers';
import * as profileSelectors from 'store/profile/selectors';
import { RootDispatch, RootState } from 'store/types';
import {
  MuiBox,
  MuiContainer,
  MuiDialog,
  MuiDialogContent,
  MuiDialogTitle,
  MuiMenuItem,
  MuiMenuList,
  MuiTypography,
  MuiGrid,
  LinearProgressProps,
  MuiInputBase
} from 'theme/material-ui';
import { oc } from 'ts-optchain';
import { estimateCostsText, insuranceNotFoundText } from './constants';
import {
  GuidedSearchCard,
  SearchHeading,
  StyledCard,
  StyledCardBox,
  StyledCardTitle,
  StyledLinearProgress,
  StyledLinearProgressLegend,
  StyledMoneyText,
  SearchStyledButton,
  SearchMuiBox
} from './styled';
import DataLoader from 'components/UI/DataLoader/DataLoader';
import { FontSize, FontWeight } from 'modules/styles/variables';
import SearchIcon from '@material-ui/icons/Search';
import { StyledIconButton } from 'components/AutoComplete/styled';

export interface Props extends RouteComponentProps {
  userPath: JsonObj;
  guidedSearchTopics: GuidedSearchTopic[];
  geolocationAccepted: boolean;
  geolocationCoords: Coordinate;
  currentUserFullName: string;
  hasAcceptedCostTC: boolean;
  myBenefits: JsonObj;
  familyBenefits: JsonObj;
  isFetching: boolean;
  isSelectHealthMember: boolean;
  dispatch: RootDispatch;
  currentUrl?: string;
  referringUrl?: string;
}

interface GuidedSearchListProps {
  onItemSelect: (item: GuidedSearchTopic) => void;
  items: GuidedSearchTopic[];
}

interface GuidedSearchListItemProps {
  item: GuidedSearchTopic;
  onItemSelect: (item: GuidedSearchTopic) => void;
}

interface GuidedSearchModalProps {
  showModal: boolean;
  onClose: () => void;
  onSelect: (item: GuidedSearchTopic) => void;
  currentTopic: GuidedSearchTopic;
}

function GuidedSearchListEmpty() {
  return (
    <MuiBox>
      <MuiTypography>No results found</MuiTypography>
    </MuiBox>
  );
}

function GuidedSearchListItem(props: GuidedSearchListItemProps) {
  const { item, onItemSelect } = props;
  const { svg, name } = item;

  return (
    <MuiGrid item sm={12} md={6} xl={4} onClick={() => onItemSelect(item)}>
      <GuidedSearchCard data-testid={convertToLowerKabobCase(name, '-guided-search-item')}>
        <MuiBox display="flex" pl={1} py={1}>
          <Svg name={svg} color="#000000" height="48" width="48" />

          <MuiBox display="flex" ml={2}>
            <StyledCardTitle>{name}</StyledCardTitle>
          </MuiBox>
        </MuiBox>
      </GuidedSearchCard>
    </MuiGrid>
  );
}

function GuidedSearchList(props: GuidedSearchListProps) {
  const { items, onItemSelect } = props;
  if (!items || !items.length) return <GuidedSearchListEmpty />;

  return (
    <MuiGrid container spacing={3}>
      {items.map(item => (
        <GuidedSearchListItem
          key={Math.floor(Math.random() * 10000)}
          item={item}
          onItemSelect={onItemSelect}
        />
      ))}
    </MuiGrid>
  );
}

function GuidedSearchModal(props: GuidedSearchModalProps) {
  const { showModal, onClose, onSelect, currentTopic } = props;
  const isOpen = showModal && currentTopic && currentTopic.children;
  const topics = oc(currentTopic).children([]);

  return (
    <MuiDialog
      fullWidth
      maxWidth="md"
      open={!!isOpen}
      onClose={onClose}
      aria-labelledby="guided-search-modal-title"
    >
      <MuiDialogTitle id="guided-search-modal-title">Select a service:</MuiDialogTitle>
      <MuiDialogContent>
        <MuiMenuList>
          {topics.map(topic => (
            <MuiMenuItem
              data-testid={convertToLowerKabobCase(topic.name)}
              key={Math.floor(Math.random() * 10000)}
              onClick={() => onSelect(topic)}
            >
              <MuiTypography variant="h6" color={Color.primary} noWrap>
                {topic.name}
              </MuiTypography>
            </MuiMenuItem>
          ))}
        </MuiMenuList>
      </MuiDialogContent>
    </MuiDialog>
  );
}

interface DeductibleInfoCardProps {
  title: string;
  subtitle: string;
  graphColor: LinearProgressProps['color'];
  graphValue: number;
  spentValue: number;
}

const DeductibleInfoCard = ({
  title,
  subtitle,
  graphColor,
  graphValue,
  spentValue
}: DeductibleInfoCardProps) => {
  return (
    <MuiGrid item xs={12} xl={6}>
      <StyledCard>
        <MuiTypography fontSize={FontSize.base} fontWeight={FontWeight.semibold} gutterBottom>
          {title}
        </MuiTypography>
        <MuiTypography fontSize={FontSize.base} gutterBottom>
          ${subtitle}
        </MuiTypography>

        <MuiBox mb={3}>
          <StyledLinearProgress variant="determinate" color={graphColor} value={graphValue} />
        </MuiBox>

        <MuiTypography variant="body2" component="p">
          <StyledLinearProgressLegend />
          <StyledMoneyText>${formatMoney(spentValue, 2)}</StyledMoneyText>
          spent year to date
        </MuiTypography>
      </StyledCard>
    </MuiGrid>
  );
};

export const CostLandingScreen = (props: Props) => {
  const {
    history,
    guidedSearchTopics,
    hasAcceptedCostTC,
    myBenefits,
    familyBenefits,
    currentUserFullName,
    geolocationAccepted,
    geolocationCoords,
    isSelectHealthMember,
    isFetching,
    dispatch,
    currentUrl,
    referringUrl
  } = props;
  const [guidedSearchModalOpened, setGuidedSearchModalOpened] = React.useState(false);
  const [guidedSearchTopic, setGuidedSearchTopic] = React.useState();
  const [searchText, setSearchText] = React.useState('');

  const data: AmplitudeEventData = {
    currentUrl,
    referringUrl
  };

  useEffect(() => {
    dispatch(setupGuidedSearch());
    dispatch(getPlanBenefits());
  }, [dispatch, isSelectHealthMember]);

  const handleSearchSubmit = () => {
    const coords = geolocationAccepted && geolocationCoords ? geolocationCoords : slcGeoCoords;
    dispatch(setCostEstimatorSearchText(searchText));
    dispatch(getSearchResults(searchText, coords));
    history.push('/u/estimate-costs/find-services');
    const data: AmplitudeEventData = {
      referringUrl,
      currentUrl
    };
    analyticsService.logEvent(AnalyticsEvent.CostEstimatorStarted, data);
  };

  const onEnterKeyPress = (e: React.KeyboardEvent<any>) => {
    if (e.charCode === 13) {
      e.preventDefault();
      handleSearchSubmit();
    }
  };

  const handleTopicSelect = (topic: GuidedSearchTopic) => {
    if (!topic.children) {
      analyticsService.logEvent(AnalyticsEvent.CostEstimateOptionSelected, data);
      setGuidedSearchModalOpened(false);
      dispatch(selectSearchResult(topic));
      history.push(CostEstimatorRoutes.ESTIMATE_COSTS_PROVIDERS);
    } else {
      setGuidedSearchTopic(topic);
      if (!guidedSearchModalOpened) {
        analyticsService.logEvent(AnalyticsEvent.CostEstimatorStarted, data);
        setGuidedSearchModalOpened(true);
      }
    }
  };

  const renderFamilyDeductible = () => {
    if (!familyBenefits) {
      return <DefaultText>{insuranceNotFoundText}</DefaultText>;
    }

    const spentValue = myBenefits.deductible - myBenefits.remainder.deductible;

    const familyDeductible = {
      title: `Family Deductible`,
      subtitle: `${formatMoney(familyBenefits.deductible, 2)} Deductible`,
      spentValue: familyBenefits.deductible - familyBenefits.remainder.deductible,
      graphValue: (spentValue / familyBenefits.deductible) * 100,
      graphColor: 'secondary' as LinearProgressProps['color']
    };

    const familyOopMax = {
      title: 'Family Out-of-pocket Max',
      subtitle: `${formatMoney(familyBenefits.max_oop, 2)} Out-of-pocket Max`,
      spentValue: familyBenefits.max_oop - familyBenefits.remainder.max_oop,
      graphValue: (spentValue / familyBenefits.max_oop) * 100,
      graphColor: 'secondary' as LinearProgressProps['color']
    };

    return (
      <MuiGrid container spacing={3} data-testid="family-deductible-and-oop-max">
        <DeductibleInfoCard {...familyDeductible} />
        <DeductibleInfoCard {...familyOopMax} />
      </MuiGrid>
    );
  };

  const renderUserDeductible = () => {
    if (!myBenefits || !currentUserFullName) {
      return <DefaultText>{insuranceNotFoundText}</DefaultText>;
    }

    const spentValue = myBenefits.deductible - myBenefits.remainder.deductible;

    const userDeductibleProps = {
      title: `${currentUserFullName}'s deductible`,
      subtitle: `${formatMoney(myBenefits.deductible, 2)} Deductible`,
      spentValue: myBenefits.deductible - myBenefits.remainder.deductible,
      graphValue: (spentValue / myBenefits.deductible) * 100,
      graphColor: 'primary' as LinearProgressProps['color']
    };

    const userOopMaxProps = {
      title: `${currentUserFullName}'s Out-of-pocket Max`,
      subtitle: `${formatMoney(myBenefits.max_oop, 2)} Out-of-pocket Max`,
      spentValue: myBenefits.max_oop - myBenefits.remainder.max_oop,
      graphValue: (spentValue / myBenefits.max_oop) * 100,
      graphColor: 'primary' as LinearProgressProps['color']
    };

    return (
      <MuiGrid container spacing={3} data-testid="deductible-and-oop-max">
        <DeductibleInfoCard {...userDeductibleProps} />
        <DeductibleInfoCard {...userOopMaxProps} />
      </MuiGrid>
    );
  };

  return (
    <>
      <TermsAndConditions open={!hasAcceptedCostTC} type={TermsAndConditionsType.COST_ESTIMATOR} />

      <GuidedSearchModal
        showModal={guidedSearchModalOpened}
        onClose={() => setGuidedSearchModalOpened(false)}
        onSelect={handleTopicSelect}
        currentTopic={guidedSearchTopic}
      />

      <MuiContainer maxWidth="xl">
        <MuiBox p={3}>
          <SearchHeading>
            <MuiBox textAlign="left" mb={1} data-testid="search-for-services">
              <MuiTypography variant="h4">Search for Services</MuiTypography>
            </MuiBox>

            <MuiTypography>Enter your search term below to find costs for services.</MuiTypography>
            <Spacer size="xsmall" />
            <SearchMuiBox mt={2}>
              <MuiBox display="flex" flexDirection="row">
                <StyledIconButton data-testid="estimate-costs-search-button" hidden={false}>
                  <SearchIcon />
                </StyledIconButton>

                <MuiInputBase
                  id="estimate-costs-search"
                  data-testid="estimate-costs-search-field"
                  placeholder="Search for services"
                  value={searchText}
                  fullWidth
                  onChange={e => setSearchText(e.target.value)}
                  onKeyPress={onEnterKeyPress}
                />
              </MuiBox>
            </SearchMuiBox>
            <Spacer size="xsmall" />
            <MuiBox alignSelf="center" display="flex" flexDirection="row">
              <SearchStyledButton
                style={{ display: 'flex' }}
                onPress={handleSearchSubmit}
                disabled={!searchText.length}
                title="search"
                fullWidth
              >
                Search
              </SearchStyledButton>
            </MuiBox>
          </SearchHeading>
          <Spacer size="xsmall" />
          <SearchHeading>
            <MuiBox textAlign="left" mb={2} data-testid="use-our-guided-search">
              <MuiTypography variant="h4">Use Our Guided Search</MuiTypography>
              <MuiTypography>{estimateCostsText}</MuiTypography>
            </MuiBox>

            <StyledCardBox data-testid="services-and-procedure-cards">
              <GuidedSearchList items={guidedSearchTopics} onItemSelect={handleTopicSelect} />
            </StyledCardBox>
          </SearchHeading>

          <Spacer size="xsmall" />

          <SearchHeading>
            <MuiBox mb={2} data-testid="medical-costs-to-date">
              <MuiTypography variant="h4">Medical Costs to Date</MuiTypography>
            </MuiBox>

            <DataLoader
              loading={isFetching}
              error={!myBenefits && !familyBenefits}
              data={{ myBenefits, familyBenefits }}
              renderLoading={() => (
                <MuiBox p={3} textAlign="center">
                  <Spinner />
                </MuiBox>
              )}
              renderError={() => (
                <StyledCardBox data-testid="benefits-error">
                  <DefaultText>{insuranceNotFoundText}</DefaultText>
                </StyledCardBox>
              )}
              renderData={() => (
                <>
                  {renderUserDeductible()}
                  {renderFamilyDeductible()}
                </>
              )}
            />
          </SearchHeading>
        </MuiBox>
      </MuiContainer>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  currentUserFullName: currentAccountConsumerFullNameSelector(state),
  geolocationAccepted: geolocationSelectors.geolocationAcceptedSelector(state),
  geolocationCoords: geolocationSelectors.geolocationCoordsSelector(state),
  guidedSearchTopics: costSelectors.guidedSearchTopicsSelector(state),
  hasAcceptedCostTC: costSelectors.hasAcceptedCostTCSelector(state),
  familyBenefits: costSelectors.familyPlanBenefitsSelector(state),
  isFetching: costSelectors.isFetchingSelector(state),
  isSelectHealthMember: profileSelectors.isSelectHealthMemberSelector(state),
  myBenefits: costSelectors.myPlanBenefitsSelector(state),
  currentUrl: currentLocationPathNameSelector(state),
  referringUrl: previousLocationPathNameSelector(state)
});

export default connect(mapStateToProps)(CostLandingScreen as ComponentType);
