import styled from 'styled-components';

import { MuiBox, MuiSelect } from 'theme/material-ui';
import { Spacing } from 'modules/styles/variables';

export const InsuranceFormButtons = styled(MuiBox)`
  margin: auto;
  max-width: 275px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: ${Spacing.largeXLarge};
  padding-bottom: ${Spacing.largeXLarge};

  & > button {
    margin-top: ${Spacing.medium}px;
    margin-bottom: ${Spacing.medium}px;
  }
`;

export const SelectRelationSuscriber = styled(MuiSelect)`
  select {
    padding: 0px 32px 0px 7px;
    height: 42px;
    min-height: 42px;
  }
`;
