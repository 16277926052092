import DisclaimerBanner from 'components/common/DisclaimerBanner/DisclaimerBanner';
import ProviderList from 'components/cost/Provider/ProviderList';
import Screen from 'components/UI/Layout/Screen';
import Spinner from 'components/UI/Spinner/Spinner';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ROUTES as CostEstimatorRoutes } from 'screens/Cost/router';
import { Color } from 'modules/styles/colors';
import formatMoney from 'services/formatMoney';
import { getProviders } from 'store/cost/actions';
import * as costSelectors from 'store/cost/selectors';
import { getCurrentPosition } from 'store/geolocation/actions';
import { slcGeoCoords } from 'store/geolocation/constants';
import * as geolocationSelectors from 'store/geolocation/selectors';
import { UserCoords } from 'store/global/reducers';
import { RootDispatch, RootState } from 'store/types';
import {
  CostProvider,
  ProcedureCostObject,
  ProcedureCostRangesObject,
  SelectedTopicResult
} from 'store/cost/types';
import {
  MuiBox,
  MuiButton,
  MuiContainer,
  MuiGrid,
  MuiPaper,
  MuiTypography
} from 'theme/material-ui';

export interface CostProviderScreenProps extends RouteComponentProps {
  costProviderDataIsFetching: boolean;
  dispatch: RootDispatch;
  geolocationAccepted: boolean;
  geolocationDenied: boolean;
  geolocationCoords: UserCoords;
  isFetching: boolean;
  isPaginatedFetching: boolean;
  procedureCostRanges: ProcedureCostRangesObject;
  providers: CostProvider[];
  selectedResult: SelectedTopicResult;
  totalCount: number;
}

const getCosts = (costs?: ProcedureCostObject) => {
  return costs
    ? {
        min: `$${formatMoney(costs.minValue, 0)}`,
        max: `$${formatMoney(costs.maxValue, 0)}`
      }
    : null;
};

interface CostEstimateProps {
  costs?: ProcedureCostRangesObject;
  isFetching: boolean;
}

function CostEstimates({ isFetching, costs }: CostEstimateProps) {
  const localCosts = getCosts(costs?.local);
  const nationalCosts = getCosts(costs?.national);

  const renderCostEstimateValues = (costs: ReturnType<typeof getCosts>) => {
    return costs && !isFetching ? (
      <MuiTypography
        data-testid={costs === localCosts ? 'local-average-cost' : 'national-average-cost'}
        variant="h6"
        color={Color.primary}
      >
        {costs.min} - {costs.max}
      </MuiTypography>
    ) : (
      <MuiTypography
        data-testid={
          costs === localCosts ? 'local-average-cost-not-found' : 'national-average-cost-not-found'
        }
        variant="h6"
        color={Color.primary}
      >
        {isFetching ? '...' : 'Not found'}
      </MuiTypography>
    );
  };

  return (
    <MuiBox my={2}>
      <MuiGrid container spacing={1}>
        <MuiGrid item>
          <MuiPaper>
            <MuiBox p={2}>
              <MuiTypography data-testid="local-average" variant="h5">
                Local Average
              </MuiTypography>
              {renderCostEstimateValues(localCosts)}
            </MuiBox>
          </MuiPaper>
        </MuiGrid>
        <MuiGrid item>
          <MuiPaper>
            <MuiBox p={2}>
              <MuiTypography data-testid="national-average" variant="h5">
                National Average
              </MuiTypography>
              {renderCostEstimateValues(nationalCosts)}
            </MuiBox>
          </MuiPaper>
        </MuiGrid>
      </MuiGrid>
    </MuiBox>
  );
}

function CostProviderScreen({
  costProviderDataIsFetching,
  dispatch,
  geolocationAccepted,
  geolocationCoords,
  history,
  isFetching,
  isPaginatedFetching,
  procedureCostRanges,
  providers,
  selectedResult,
  totalCount
}: CostProviderScreenProps) {
  const id = selectedResult?.id || selectedResult?.provider_service_grp_id;
  const pageFetching = isFetching || costProviderDataIsFetching;

  useEffect(() => {
    if (geolocationCoords) {
      dispatch(getProviders(id, geolocationCoords));
    } else {
      dispatch(getProviders(id, slcGeoCoords));
      dispatch(getCurrentPosition());
    }
  }, [geolocationAccepted]);

  const handleProviderSelected = async (provider: CostProvider) => {
    history.push(CostEstimatorRoutes.ESTIMATE_COSTS_PROVIDERS_DETAIL, { provider });
  };

  const handleFetchMoreProviders = () => {
    const coords = geolocationCoords || slcGeoCoords;
    dispatch(getProviders(id, coords, providers.length, true));
  };

  const renderViewMoreResults = (props: { isFetching: boolean }) => {
    const { isFetching } = props;

    if (totalCount <= providers.length) {
      return null;
    }

    return (
      <MuiBox my={2}>
        <MuiButton
          disabled={isFetching}
          variant="outlined"
          size="large"
          color="primary"
          onClick={handleFetchMoreProviders}
        >
          View more results
          {isFetching && (
            <>
              <MuiBox style={{ width: '8px' }} />
              <Spinner size={15} />
            </>
          )}
        </MuiButton>
      </MuiBox>
    );
  };

  const getLocationDisclaimerText = () => {
    return geolocationAccepted
      ? 'Sorting providers by distance from your current location'
      : 'Your geolocation is not available so we are sorting providers by distance from Salt Lake City';
  };

  return (
    <Screen>
      <DisclaimerBanner
        data-testid="providers-disclaimer-text"
        text={getLocationDisclaimerText()}
      />
      <MuiContainer maxWidth="xl">
        <MuiBox my={3}>
          <MuiBox>
            <MuiTypography data-testid="result-name" variant="h4" component="h2">
              {selectedResult?.name}
            </MuiTypography>
            {selectedResult.description ? (
              <MuiTypography data-testid="result-description" variant="body1">
                {selectedResult?.description}
              </MuiTypography>
            ) : null}
            <CostEstimates isFetching={costProviderDataIsFetching} costs={procedureCostRanges} />
          </MuiBox>
          <MuiBox my={3}>
            <MuiTypography variant="h4">Providers</MuiTypography>
            <MuiBox data-testid="provider-result-list" my={2}>
              {pageFetching && !providers.length ? (
                <Spinner />
              ) : (
                <>
                  <ProviderList
                    providers={providers}
                    onPress={costProvider => handleProviderSelected(costProvider)}
                    geoLocation={geolocationCoords}
                  />
                  {renderViewMoreResults({ isFetching: isPaginatedFetching })}
                </>
              )}
            </MuiBox>
          </MuiBox>
        </MuiBox>
      </MuiContainer>
    </Screen>
  );
}

const mapStateToProps = (state: RootState) => ({
  selectedResult: costSelectors.selectedResultSelector(state),
  myBenefits: costSelectors.myPlanBenefitsSelector(state),
  geolocationAccepted: geolocationSelectors.geolocationAcceptedSelector(state),
  geolocationDenied: geolocationSelectors.geolocationDeniedSelector(state),
  geolocationCoords: geolocationSelectors.geolocationCoordsSelector(state),
  providers: costSelectors.costProviders(state),
  costProviderDataIsFetching: costSelectors.costProviderDataIsFetchingSelector(state),
  isFetching: costSelectors.isFetchingSelector(state),
  procedureCostRanges: costSelectors.procedureCostRangesSelector(state),
  isPaginatedFetching: costSelectors.paginationFetchingSelector(state),
  totalCount: costSelectors.totalCountOfProvidersSelector(state)
});

export default connect(mapStateToProps)(CostProviderScreen);
