import styled from 'styled-components';
import { Spacing } from 'modules/styles/variables';
import { Color } from 'modules/styles/colors';

export const StyledUL = styled.ul`
  &&& {
    margin-left: ${Spacing.large + Spacing.xLarge}px;
    color: ${Color.black};
  }
`;

export const StyledLIDisc = styled.li`
  &&& {
    list-style-type: disc;
    font-size: 16px;
    line-height: 40px;
  }
`;
