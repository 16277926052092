import React from 'react';
import { Color } from 'modules/styles/colors';
import { SvgProps } from 'components/UI/Svg/Svg';

export default (props: SvgProps | undefined) => {
  return {
    svg: (
      <g>
        <path
          fill="none"
          stroke={
            props?.active
              ? 'url(#a)'
              : props?.fill
              ? props.fill
              : props?.disabled
              ? Color.grayHue5
              : Color.primary
          }
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="m3.75 23.25-2-2.38a4.5 4.5 0 0 1-1-2.81v-5.17a1.5 1.5 0 0 1 3 0v3.86m4.5 6.5v-3a4.46 4.46 0 0 0-.76-2.5L6.35 16a1.39 1.39 0 0 0-2-.44 1.4 1.4 0 0 0-.47 1.79l1.35 2.25m15.02 3.65 2-2.38a4.5 4.5 0 0 0 1-2.81v-5.17a1.5 1.5 0 1 0-3 0v3.88m-4.5 6.48v-3a4.46 4.46 0 0 1 .76-2.5L17.65 16a1.39 1.39 0 0 1 2-.44 1.4 1.4 0 0 1 .47 1.79l-1.35 2.25M18.75 6a.76.76 0 0 0-.75-.75h-3.75V1.5a.76.76 0 0 0-.75-.75h-3a.76.76 0 0 0-.75.75v3.75H6a.76.76 0 0 0-.75.75v3a.76.76 0 0 0 .75.75h3.75v3.75a.76.76 0 0 0 .75.75h3a.76.76 0 0 0 .75-.75V9.75H18a.76.76 0 0 0 .75-.75V6Z"
        />
        <defs>
          <linearGradient
            id="a"
            x1={0.75}
            x2={23.25}
            y1={12}
            y2={12}
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0.084} stopColor="#FF0048" />
            <stop offset={0.386} stopColor="#DF36FF" />
            <stop offset={0.98} stopColor="#4A00E2" />
          </linearGradient>
        </defs>
      </g>
    ),
    viewBox: '0 0 24 24'
  };
};
