import React from 'react';
import { FiltersStructure } from 'lib/hooks/ProviderSearch/types';
import { FilterDrawer } from 'components/FilterDrawer';
import { FormCheckboxList } from '../shared';

export type GenderFilterProps = FiltersStructure['gender'] & {
  defaultExpanded: boolean;
};

function GenderFilter({ title, values, defaultExpanded }: GenderFilterProps) {
  return (
    <FilterDrawer.Panel title={title} defaultExpanded={defaultExpanded}>
      <FormCheckboxList list={Object.values(values)} />
    </FilterDrawer.Panel>
  );
}

export default GenderFilter;
