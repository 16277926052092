function isAmexCard(rawValue: string) {
  const startValue = rawValue?.substring(0, 2);
  return startValue === '37' || startValue === '34';
}

const masks = {
  amex: [
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/
  ],
  common: [
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/
  ]
};

export const creditCard = {
  default: (rawValue: string) => (isAmexCard(rawValue) ? masks.amex : masks.common),
  ...masks
};
