import React from 'react';
import { StyledInput, StyledInputProps, StyledTextArea, StyledTextAreaProps } from './styled';
import InputContainer from './InputContainer';
import at from 'lodash/at';
import { useField } from 'formik';

const TextInput = React.forwardRef((props: StyledInputProps, ref) => {
  return (
    <InputContainer {...props}>
      <StyledInput ref={ref} {...props} />
    </InputContainer>
  );
});
export default TextInput;

export const TextArea = React.forwardRef((props: StyledTextAreaProps, ref) => {
  return (
    <InputContainer {...props}>
      <StyledTextArea ref={ref} {...props} />
    </InputContainer>
  );
});

export function InputField(props: StyledInputProps) {
  const [field, meta] = useField(props);

  function renderHelperText() {
    const [touched, error] = at(meta, 'touched', 'error');
    if (touched && error) {
      return error;
    }
    return '';
  }

  return (
    <TextInput
      type="text"
      error={meta.touched && meta.error ? meta.error : null}
      helperText={renderHelperText()}
      {...field}
      {...props}
    />
  );
}
