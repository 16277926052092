import React, { ComponentType, useState, useRef } from 'react';
import { NavigationScreenProps } from 'screens/navigation';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import dayjs, { Dayjs } from 'dayjs';
import { v4 as uuidv4 } from 'uuid';
import styled from 'styled-components';

import { ordinalSuffix } from 'modules/utils/StringUtils';

import { RootState, RootDispatch } from 'store/types';
import {
  consumerIdSelector,
  profileConsumerSelector,
  profileProxyToUpdateSelector,
  profileRequestedProxiesLengthSelector
} from 'store/profile/selectors';
import { Consumer } from 'store/profile/types';
import { addRequestedProxy, updateProxy, resetProxyToUpdate } from 'store/profile/actions';

import { Color } from 'modules/styles/colors';
import { Spacing, FontSize } from 'modules/styles/variables';
import RequiredFieldsLegend, { StyledAsterisk } from 'components/RequiredFieldsLegend';
import Spacer from 'components/UI/Layout/Spacer';
import Typography from 'components/UI/Typography';
import { Icon } from 'components/Icon';
import ProxyFormFooter from './components/ProxyFormFooter';

import {
  MuiBox,
  MuiTextField,
  MuiContainer,
  MuiTypography,
  MuiButton,
  MuiSelect,
  MuiMenuItem
} from 'theme/material-ui';
import { KeyboardDatePicker } from '@material-ui/pickers';
import {
  PROXY_FORM_FIELDS,
  OTHER_RELATIONSHIP_FIELD,
  ADDRESS_FIELDS,
  ProxyFormFieldProps,
  ProxyFormTypeField
} from 'screens/ProxyForm/constants';
import ProxyLeavingGuard from 'screens/ProxyForm/navigation/ProxyLeavingGuard';
import { ProxyFormNav } from 'screens/ProxyForm/navigation/router';
import capitalize from 'lodash/capitalize';

const technicalSupportValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .required('Please enter their first name')
    .max(50, 'Must be less than 50 characters'),
  middleName: Yup.string().max(50, 'Must be less than 50 characters'),
  lastName: Yup.string()
    .required('Please enter their last name')
    .max(50, 'Must be less than 50 characters'),
  gender: Yup.string().required('Please select their gender'),
  birthDate: Yup.date()
    .min(dayjs().subtract(18, 'year'), 'Please enter a valid birthdate')
    .max(dayjs(), 'Please enter a valid birthdate')
    .required('Please enter their birthdate'),
  relationship: Yup.string().required('Please enter your relationship'),
  address: Yup.string().required('Please enter your address'),
  otherRelationship: Yup.string().when('relationship', {
    is: val => val === 'Other',
    then: Yup.string()
      .required('Please enter relationship')
      .max(500, 'Must be less than 500 characters')
  }),
  street: Yup.string().when('address', {
    is: val => val === 'no',
    then: Yup.string().required('Please enter street')
  }),
  housingType: Yup.string().when('address', {
    is: val => val === 'no',
    then: Yup.string()
  }),
  city: Yup.string().when('address', {
    is: val => val === 'no',
    then: Yup.string().required('Please enter city')
  }),
  state: Yup.string().when('address', {
    is: val => val === 'no',
    then: Yup.string().required('Please enter state')
  }),
  postalCode: Yup.string().when('address', {
    is: val => val === 'no',
    then: Yup.string().required('Please enter postal code')
  })
});

export interface Props extends NavigationScreenProps {
  consumerId: string;
  displayName: string;
  minorsLength: number;
  profileConsumer: Consumer;
  proxyToUpdate: ProxyFormProps;
  addProxy: (proxies: ProxyFormProps[]) => void;
  updateProxy: (proxy: ProxyFormProps) => void;
  encryptionSettings: {
    encryption_algorithm: string;
    encryption_iv: string;
    encryption_password: string;
  };
  resetProxy: () => void;
}
export interface ProxyFormProps {
  firstName: string;
  middleName: string;
  lastName: string;
  gender: string;
  birthDate: Dayjs;
  relationship: string;
  address: string;
  otherRelationship: string;
  street: string;
  housingType: string;
  city: string;
  state: string;
  postalCode: string;
  isUpdateMode: boolean;
  consumerId: string;
  proxyToUpdate: object;
}

const initialValues = {
  firstName: '',
  middleName: '',
  lastName: '',
  gender: '',
  birthDate: null,
  relationship: '',
  address: '',
  otherRelationship: '',
  street: '',
  housingType: '',
  city: '',
  state: '',
  postalCode: '',
  isUpdateMode: false,
  consumerId: null,
  proxyToUpdate: {}
};

const StyledTextField = styled(MuiTextField)`
  background-color: ${Color.foreColor};
`;

const StyledSelectField = styled(MuiSelect)`
  background-color: ${Color.foreColor};
`;

const StyledDatePickerField = styled(KeyboardDatePicker)`
  background-color: ${Color.foreColor};
`;

export const getProxyDTOObject = ({
  firstName,
  middleName,
  lastName,
  gender,
  birthDate,
  relationship,
  address,
  otherRelationship,
  street,
  housingType,
  city,
  state,
  postalCode,
  isUpdateMode,
  consumerId,
  proxyToUpdate
}: ProxyFormProps) => {
  const proxyDTO = {
    id: isUpdateMode ? proxyToUpdate.id : uuidv4(),
    firstName: capitalize(firstName),
    middleName: capitalize(middleName),
    lastName: capitalize(lastName),
    gender,
    birthDate: dayjs(birthDate).format('MM/DD/YYYY'),
    relationship,
    address,
    otherRelationship: '',
    street: '',
    housingType: '',
    city: '',
    state: '',
    postalCode: '',
    MRN: consumerId
  };

  if (address === 'no') {
    proxyDTO.street = street;
    proxyDTO.housingType = housingType;
    proxyDTO.city = city;
    proxyDTO.state = state;
    proxyDTO.postalCode = postalCode;
  }

  if (relationship === 'Other') {
    proxyDTO.otherRelationship = otherRelationship;
  }
  return proxyDTO;
};

export const ProxyFormScreen = (props: Props) => {
  const [minorsData, setMinorsData] = useState<ProxyFormProps[]>([]);
  const [counterMinor, setCounterMinor] = useState(props.minorsLength + 1);
  const [isUpdateMode, setIsUpdateMode] = useState(!!props.proxyToUpdate.firstName);
  const scrollViewRef = useRef<HTMLInputElement>(null);

  const [previousInitialValues, setPreviousInitialValues] = useState<ProxyFormProps>();
  const { consumerId, proxyToUpdate } = props;

  const scrollToTop = () => {
    scrollViewRef.current?.scrollIntoView?.({ behavior: 'instant', block: 'start' });
  };

  const isEditingOrAdding = (isUpdateMode || !!props.minorsLength) && !minorsData.length;

  const handlePrevious = (resetForm: Function) => {
    if (isEditingOrAdding) {
      // Cancels add/edit
      props.resetProxy();
      props.history.push(ProxyFormNav.PROXY_REVIEW);
    } else if (!minorsData.length) {
      // Exits flow before saving
      props.history.push('/u/manage-account');
    } else {
      // Sets previous data
      setCounterMinor(counterMinor - 1);
      const minorsDataCopy = [...minorsData];
      const lastFormEntry = minorsDataCopy.pop();
      setPreviousInitialValues(lastFormEntry);
      setMinorsData(minorsDataCopy);
      resetForm(initValues);
    }
  };

  const handleMinorSubmit = (values: ProxyFormProps, resetForm: Function) => {
    const proxyDTO = getProxyDTOObject(values);
    setMinorsData([...minorsData, proxyDTO]);
    setCounterMinor(counterMinor + 1);
    resetForm(initValues);
    scrollToTop();
    setPreviousInitialValues(undefined);
  };

  const getAcceptButtonText = (canSaveAndContinue: boolean) => {
    if (canSaveAndContinue) return 'Done';
    if (isUpdateMode) return 'Update Minor';
    return 'Next';
  };

  const getCancelButtonText = () => {
    if (isEditingOrAdding) return 'Cancel Edit';
    return 'Previous';
  };

  const submitForm = async (values: ProxyFormProps) => {
    const proxyDTO = getProxyDTOObject({ ...values, consumerId, proxyToUpdate, isUpdateMode });
    const proxiesDTO: ProxyFormProps[] = [...minorsData, proxyDTO];
    if (isUpdateMode) {
      props.updateProxy(proxyDTO);
    } else {
      props.addProxy(proxiesDTO);
    }
    props.resetProxy();
    setPreviousInitialValues(undefined);
    props.history.push(ProxyFormNav.PROXY_REVIEW);
  };

  const saveAndContinue = () => {
    props.addProxy(minorsData);
    setPreviousInitialValues(undefined);
    props.history.push(ProxyFormNav.PROXY_REVIEW);
  };

  let initValues;
  if (!!previousInitialValues) {
    initValues = previousInitialValues;
    initValues.birthDate = dayjs(initValues.birthDate);
  } else if (isUpdateMode) {
    initValues = { ...props.proxyToUpdate };
    initValues.birthDate = dayjs(initValues.birthDate);
  } else {
    initValues = initialValues;
  }

  const minDay = dayjs()
    .subtract(18, 'year')
    .add(1, 'day');
  const disableMinor = minorsData.length >= 19;

  return (
    <MuiBox
      bgcolor={Color.baseColor}
      flex={1}
      width="100%"
      height="100%"
      style={{ scrollMargin: Spacing.xxxLarge }}
      ref={scrollViewRef}
    >
      <ProxyLeavingGuard />
      <MuiBox
        py={Spacing.small}
        display="flex"
        flex={1}
        flexDirection="row"
        justifyContent="space-around"
      >
        <MuiTypography variant="h5">
          {isUpdateMode ? 'Update' : ordinalSuffix(counterMinor)} Minor's Information
        </MuiTypography>
      </MuiBox>
      <Formik
        enableReinitialize
        initialValues={initValues}
        validationSchema={technicalSupportValidationSchema}
        onSubmit={values => submitForm(values)}
        isInitialValid={isUpdateMode || !!previousInitialValues}
      >
        {({
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          isValid,
          handleSubmit,
          dirty,
          setFieldValue,
          resetForm
        }) => {
          const getFormError = (formKey: keyof typeof values) => {
            if (
              formKey === 'birthDate' &&
              touched.birthDate &&
              (!values.birthDate || !values.birthDate?.isValid())
            ) {
              return 'You must provide a valid date.';
            }
            return touched[formKey] ? errors[formKey] || null : null;
          };

          const addresses = props.profileConsumer?.addresses;

          const { streetAddress, state, postalCode } = addresses?.[0] || {
            streetAddress: '',
            state: '',
            postalCode: ''
          };

          const otherRelationship = OTHER_RELATIONSHIP_FIELD;
          const fields = [...PROXY_FORM_FIELDS];

          if (values.address === 'no') {
            fields.push(...ADDRESS_FIELDS);
          } else if (!addresses) {
            setFieldValue('address', 'no');
          }

          // True if we have previous submission(s) to save, and current form has not been changed, and isn't populated with previous values
          const canSaveAndContinue = !!minorsData.length && !dirty && !values.id;
          return (
            <MuiContainer maxWidth="sm">
              <ProxyLeavingGuard />
              <MuiBox display="flex" flexDirection="row">
                <RequiredFieldsLegend />
              </MuiBox>
              <MuiBox
                display="flex"
                flexDirection="row"
                flexWrap="wrap"
                justifyContent="space-between"
                pt={Spacing.small}
              >
                {fields.map((field: ProxyFormFieldProps) => {
                  const { key, fieldType, props: itemProps } = field;
                  const {
                    maxLength,
                    fieldHeight,
                    accessibilityLabel,
                    placeholder,
                    required,
                    label,
                    ...rest
                  } = itemProps;
                  const customProps: any = {
                    inputProps: {
                      style: { minHeight: fieldHeight },
                      'aria-label': accessibilityLabel
                    }
                  };

                  if (field.key === 'address' && !addresses) {
                    customProps.disabled = true;
                  }
                  if (maxLength) {
                    customProps.inputProps.maxLength = maxLength;
                  }

                  const address = !!addresses ? (
                    <MuiBox pb={1}>
                      <MuiTypography>{`${streetAddress}, ${state}, ${postalCode}`}</MuiTypography>
                    </MuiBox>
                  ) : (
                    ''
                  );

                  return (
                    <MuiBox
                      pb={Spacing.small}
                      key={key}
                      width={itemProps.widthPercentage ? `${itemProps.widthPercentage}%` : '100%'}
                    >
                      <MuiBox display="flex" flexDirection="row">
                        <MuiTypography>{label}</MuiTypography>
                        {required ? (
                          <MuiBox px={0.5}>
                            <StyledAsterisk />
                          </MuiBox>
                        ) : null}
                      </MuiBox>
                      {field.key === 'address' ? address : null}
                      {fieldType === ProxyFormTypeField.DEFAULT ? (
                        <StyledTextField
                          {...rest}
                          placeholder={placeholder}
                          InputProps={{
                            ...customProps
                          }}
                          FormHelperTextProps={{ style: { position: 'absolute', top: '100%' } }}
                          variant="outlined"
                          defaultValue={values[key]}
                          onChange={handleChange(key)}
                          error={!!getFormError(key)}
                          helperText={getFormError(key)}
                          onBlur={handleBlur(key)}
                          data-testid={label}
                          fullWidth
                          value={values[key]}
                        />
                      ) : null}
                      {fieldType === ProxyFormTypeField.SELECT ? (
                        <>
                          <StyledSelectField
                            {...rest}
                            {...customProps}
                            variant="outlined"
                            defaultValue={values[key]}
                            onChange={handleChange(key)}
                            onBlur={handleBlur(key)}
                            label="Make selection"
                            data-testid={label}
                            fullWidth
                            displayEmpty
                            value={values[key]}
                          >
                            <MuiMenuItem value="" disabled>
                              {placeholder}
                            </MuiMenuItem>
                            {itemProps?.options.map(({ label, value }) => {
                              return (
                                <MuiMenuItem key={value} value={value}>
                                  {label}
                                </MuiMenuItem>
                              );
                            })}
                          </StyledSelectField>
                          <MuiBox marginLeft={2}>
                            <MuiTypography color="error" variant="body2">
                              {touched[key] && errors[key] ? errors[key] : null}
                            </MuiTypography>
                          </MuiBox>
                        </>
                      ) : null}
                      {fieldType === ProxyFormTypeField.DATE ? (
                        <StyledDatePickerField
                          {...rest}
                          {...customProps}
                          autoOk
                          fullWidth
                          value={values[key]}
                          openTo="date"
                          variant="inline"
                          inputVariant="outlined"
                          format="MM/DD/YYYY"
                          placeholder="MM/DD/YYYY"
                          maxDate={new Date()}
                          minDate={minDay}
                          helperText={getFormError(key)}
                          FormHelperTextProps={{ style: { position: 'absolute', top: '100%' } }}
                          onChange={date => {
                            const value = date || '';
                            setFieldValue(key, value);
                          }}
                          error={touched[key] && errors[key]}
                          onBlur={handleBlur(key)}
                          data-testid={label}
                        />
                      ) : null}
                      {field.key === 'relationship' && values.relationship === 'Other' ? (
                        <>
                          <MuiBox display="flex" flexDirection="row" pt={2}>
                            <MuiTypography>{otherRelationship.props.label}</MuiTypography>
                            {required ? (
                              <MuiBox px={0.5}>
                                <StyledAsterisk />
                              </MuiBox>
                            ) : null}
                          </MuiBox>
                          <StyledTextField
                            {...otherRelationship}
                            InputProps={{
                              ...otherRelationship.props
                            }}
                            FormHelperTextProps={{
                              style: { position: 'absolute', top: '100%' }
                            }}
                            variant="outlined"
                            multiline
                            defaultValue={values[otherRelationship.key]}
                            onChange={handleChange(otherRelationship.key)}
                            error={!!getFormError(otherRelationship.key)}
                            helperText={getFormError(otherRelationship.key)}
                            onBlur={handleBlur(otherRelationship.key)}
                            data-testid={otherRelationship.props.label}
                            fullWidth
                          />
                          <Typography textAlign="right" fontSize={FontSize.small}>
                            {values[otherRelationship.key].length}/
                            {otherRelationship.props.maxLength} characters
                          </Typography>
                        </>
                      ) : null}
                    </MuiBox>
                  );
                })}
              </MuiBox>
              <MuiBox width="100%" p={Spacing.small} borderColor={Color.grayLight} borderTop={1}>
                {!isUpdateMode ? (
                  <MuiButton
                    startIcon={
                      <Icon
                        name="person-add"
                        size={20}
                        color={!isValid || !dirty ? '' : Color.primary}
                      />
                    }
                    data-testid="add-minor-button"
                    color="primary"
                    variant="outlined"
                    fullWidth
                    disabled={!isValid || disableMinor}
                    onClick={() => handleMinorSubmit(values, resetForm)}
                  >
                    Add another minor
                  </MuiButton>
                ) : null}
                <Spacer size="small" />
                <MuiButton
                  data-testid="submit-button"
                  color="primary"
                  variant="contained"
                  fullWidth
                  disabled={!isValid && !canSaveAndContinue}
                  onClick={() => (canSaveAndContinue ? saveAndContinue() : handleSubmit())}
                >
                  {getAcceptButtonText(canSaveAndContinue)}
                </MuiButton>
                <Spacer size="xsmall" />
                <MuiButton
                  data-testid="previous-button"
                  color="primary"
                  variant="text"
                  fullWidth
                  onClick={() => handlePrevious(resetForm)}
                >
                  {getCancelButtonText()}
                </MuiButton>
              </MuiBox>
              <ProxyFormFooter />
            </MuiContainer>
          );
        }}
      </Formik>
    </MuiBox>
  );
};

const mapDispatch = (dispatch: RootDispatch) => ({
  addProxy: (proxies: ProxyFormProps[]) => dispatch(addRequestedProxy(proxies)),
  updateProxy: (proxy: ProxyFormProps) => dispatch(updateProxy(proxy)),
  resetProxy: () => dispatch(resetProxyToUpdate())
});

const mapStateToProps = (state: RootState) => ({
  consumerId: consumerIdSelector(state),
  profileConsumer: profileConsumerSelector(state),
  proxyToUpdate: profileProxyToUpdateSelector(state),
  minorsLength: profileRequestedProxiesLengthSelector(state)
});

export default connect(mapStateToProps, mapDispatch)(ProxyFormScreen as ComponentType);
