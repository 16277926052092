import React from 'react';
import { Color } from 'modules/styles/colors';
import { Spacing } from 'modules/styles/variables';

import FlexBox from 'components/UI/Layout/FlexBox';

import WhiteBanner from 'screens/Billing/WhiteBanner';

import { ELIGIBILITY_BODY } from './constants';
import { MuiBox } from 'theme/material-ui';
import Typography from 'components/UI/Typography';
import EligibilityTable from './EligibilityTable';

const FAEligibility = () => {
  return (
    <MuiBox>
      <WhiteBanner
        title="Financial Assistance Eligibility"
        subTitle="Review the information below to see if you meet the requirements for Financial Assistance"
      />

      <FlexBox backgroundColor={Color.foreColor} vSpacing={Spacing.large} hSpacing={Spacing.xLarge}>
        <Typography>{ELIGIBILITY_BODY}</Typography>
      </FlexBox>

      <FlexBox vSpacing={Spacing.medium} hSpacing={Spacing.xLarge}>
        <EligibilityTable />
      </FlexBox>
    </MuiBox>
  );
};

export default FAEligibility;
