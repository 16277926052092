import React from 'react';
import styled from 'styled-components';
import Accordion from 'components/UI/Accordion';
import { MuiBox, MuiTypography } from 'theme/material-ui';
import { Color } from 'modules/styles/colors';
import { treatedConditions } from 'lib/evisit/constants';
import { FontWeight } from 'modules/styles/variables';

const ListItem = styled.li`
  list-style: initial;
`;

interface ConditionDefinitionsProps {
  onExpandAccordion: (accordionSelected: string) => void;
}

interface AccordionMultipleParagraphsProps extends ConditionDefinitionsProps {
  title: string;
  paragraphs: string[];
}

const AccordionMultipleParagraphs = ({
  title,
  paragraphs,
  onExpandAccordion
}: AccordionMultipleParagraphsProps) => {
  return (
    <Accordion
      key={title}
      title={title}
      titleBackgroundColor={Color.grayLight3}
      contentBackgroundColor={Color.grayLight3}
      square
      onOpen={() => onExpandAccordion(title)}
      shadow="none"
    >
      {paragraphs.map(paragraph => (
        <MuiTypography paragraph>{paragraph}</MuiTypography>
      ))}
    </Accordion>
  );
};

export const ConditionDefinitions = ({ onExpandAccordion }: ConditionDefinitionsProps) => (
  <MuiBox data-testid="condition-definitions-content">
    {treatedConditions.slice(0, 5).map(({ condition, definition }) => (
      <AccordionMultipleParagraphs
        title={condition}
        paragraphs={definition}
        onExpandAccordion={onExpandAccordion}
      />
    ))}
    <Accordion
      key="COVID-19 Coronavirus"
      title="COVID-19 Coronavirus"
      titleBackgroundColor={Color.grayLight3}
      contentBackgroundColor={Color.grayLight3}
      square
      onOpen={() => onExpandAccordion('COVID-19 Coronavirus')}
      shadow="none"
    >
      <MuiTypography paragraph>
        COVID-19 typically causes symptoms similar to a cold or flu, including a cough, fever, and
        sometimes difficulty breathing. Other symptoms are possible, but usually mild.&nbsp;
        <MuiTypography component="span" fontWeight={FontWeight.semibold}>
          If your symptoms are severe, get medical care right away.
        </MuiTypography>
      </MuiTypography>
      <MuiTypography paragraph>
        According to the Centers for Disease Control and Prevention (CDC), you can be with others
        after:
      </MuiTypography>
      <ol>
        <ListItem>
          At least 10 days since your symptoms first appeared,&nbsp;
          <MuiTypography component="span" fontWeight={FontWeight.semibold}>
            and
          </MuiTypography>
        </ListItem>
        <ListItem>
          At least 24 hours with no fever without fever-reducing medication,&nbsp;
          <MuiTypography component="span" fontWeight={FontWeight.semibold}>
            and
          </MuiTypography>
        </ListItem>
        <ListItem>Your other symptoms of COVID-19 are improving</ListItem>
      </ol>
      <MuiTypography paragraph>
        The CDC recommends that anyone with symptoms of COVID-19 be tested for the virus.
      </MuiTypography>
    </Accordion>
    {treatedConditions.slice(5).map(({ condition, definition }) => (
      <AccordionMultipleParagraphs
        title={condition}
        paragraphs={definition}
        onExpandAccordion={onExpandAccordion}
      />
    ))}
  </MuiBox>
);
