import React from 'react';

import { MuiTypography } from 'theme/material-ui';

import TypographyInlineWrapper from 'components/UI/Typography/TypographyInLineWrapper';

import { history } from 'lib/history';

import { Color } from 'modules/styles/colors';
import { FontSize } from 'modules/styles/variables';

export default () => (
  <MuiTypography fontSize={FontSize.small} color={Color.black}>
    Please check our website for additional information including{' '}
    <TypographyInlineWrapper
      color={Color.link}
      fontSize={FontSize.small}
      onClick={() => history.push('/u/fa-app/faq')}
    >
      Frequently Asked Questions
    </TypographyInlineWrapper>
    . Patients may not receive financial assistance if they do not complete the application process.
    Patients may not receive financial assistance if they potentially could qualify for programs,
    such as Medicaid, but choose not to apply. Patients above 250-500% of the federal poverty line
    will be considered only in extenuating circumstances.{' '}
    <TypographyInlineWrapper
      color={Color.link}
      fontSize={FontSize.small}
      onClick={() => history.push('/u/fa-app/eligibility')}
    >
      See Eligibility Information for more details.
    </TypographyInlineWrapper>
  </MuiTypography>
);
