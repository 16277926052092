import React from 'react';

const a = {
  opacity: '0.1',
  fill: '#FFFFFF'
} as const;

export default {
  svg: (
    <g>
      <g>
        <rect {...a} x="-3723.6" y="-2229.3" />
        <rect {...a} x="-3541.8" y="-538.2" />
      </g>
      <g>
        <g>
          <g>
            <path d="M228.9,241.1l-77.7-35.8l-77.7,35.8V62.9h155.4V241.1z M88.5,77.9v139.8l62.7-28.9l62.7,28.9V77.9H88.5z" />
          </g>
          <path
            d="M121,137.4c-6.6-6.6-6.6-17.2,0-23.8c6.6-6.6,17.2-6.6,23.8,0l6.4,6.4l6.4-6.4c6.6-6.6,17.2-6.6,23.8,0
            c6.6,6.6,6.6,17.2,0,23.8l-30.2,30.2L121,137.4z"
          />
        </g>
      </g>
    </g>
  ),
  viewBox: '0 0 300 300'
};
