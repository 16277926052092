import Box from 'components/UI/Layout/Box';
import { Row } from 'components/UI/Layout/Container';
import PanelGroup from 'components/UI/Panel/PanelGroup';
import PanelGroupItem from 'components/UI/Panel/PanelGroupItem';
import React from 'react';
import { FontSize, FontWeight } from 'modules/styles/variables';
import { BaseAffiliatedHospital } from 'store/findProvider/types';
import styled from 'styled-components';
import { MuiGrid, MuiTypography } from 'theme/material-ui';
import { SectionHeader, StyledDefaultText } from '../common';
import { Color } from 'modules/styles/colors';
import Svg from 'components/UI/Svg/Svg';
import FlexBox from 'components/UI/Layout/FlexBox';

interface ProviderAffiliationsProps {
  affiliations: BaseAffiliatedHospital[];
}

const headerText =
  'The provider has medical privileges to admit patients and practice at these intermountain hospitals:';

const ProviderAffiliations = (props: ProviderAffiliationsProps) => (
  <MuiGrid item xs={12}>
    <SectionHeader variant="h5" fontWeight={FontWeight.bold} fontSize={FontSize.heading}>
      Hospital Affiliations
    </SectionHeader>
    <PanelGroupItem firstChild style={{ backgroundColor: Color.baseColor }}>
      <StyledDefaultText fontWeight={FontWeight.regularbold}>{headerText}</StyledDefaultText>
    </PanelGroupItem>
    {props.affiliations.length <= 0 && (
      <PanelGroupItem firstChild={false} lastChild>
        <StyledDefaultText>No affiliated hospitals</StyledDefaultText>
      </PanelGroupItem>
    )}
    <Box>
      <PanelGroup
        data={props.affiliations}
        style={{ backgroundColor: Color.baseColor }}
        keyExtractor={item => `${item.locationId}`}
        renderItem={item => (
          <LocationRow center>
            <Row center>
              <FlexBox flexDirection="row">
                <Svg name="DirectionFilled" height="15" width="15" color={Color.black} />
                <MuiTypography
                  fontSize={FontSize.base}
                  style={{ marginLeft: 6 }}
                  color={Color.textLight}
                >
                  {item.hospitalName}
                </MuiTypography>
              </FlexBox>
            </Row>
          </LocationRow>
        )}
      />
    </Box>
  </MuiGrid>
);

const LocationRow = styled(Row)`
  padding-right: 20px;
`;

export default ProviderAffiliations;
