import React, { useState, useEffect, useReducer } from 'react';
import { useTimeout } from 'lib/hooks/useTimeout';
import { ReactInactivityWarning } from './ReactInactivityWarning';

export interface ReactNativeUserActivityProps {
  duration: number;
  warningDuration: number;
  onLogout: () => void;
  onTimeout: () => void;
  children?: React.ReactNode;
}

/**
 * Set of events to listen for and count as activity
 */
const ACTIVITY_EVENTS = [
  'mousemove',
  'keydown',
  'wheel',
  'DOMMouseScroll',
  'mouseWheel',
  'mousedown',
  'touchstart',
  'touchmove',
  'visibilitychange'
];

/**
 * Create a throttled function so we don't trigger activity events super often
 */
const throttle = (callback: (...args: any[]) => void, delay: number) => {
  let lastTick = 0;

  return (...args: any[]) => {
    const now = Date.now();

    if (now - lastTick < delay) {
      return;
    }

    lastTick = now;
    callback(...args);
  };
};

enum UserActivityState {
  ACTIVE = 1,
  WARNING,
  END,
  INACTIVE
}

const stateReducer = (_: UserActivityState, newState: UserActivityState) => newState;

export function ReactInAppUserActivity({
  warningDuration,
  duration,
  onLogout,
  onTimeout,
  children
}: ReactNativeUserActivityProps) {
  /**
   * Initialize active as true and set a timer that will set it to false
   */
  const [activityState, dispatch] = useReducer(stateReducer, UserActivityState.ACTIVE);
  useEffect(() => {
    if (activityState === UserActivityState.INACTIVE) {
      onTimeout();
    }
    if (activityState === UserActivityState.END) {
      onLogout();
    }
  }, [activityState]);

  /**
   * Setup a timeout that will set active to false when a timeout occurs
   */
  const [lastActivityTime, setLastActivityTime] = useState(() => Date.now());
  useTimeout(() => dispatch(UserActivityState.WARNING), duration, [lastActivityTime, duration]);

  const onActivityTaken = () => {
    // Resets the timer due to dependency change
    dispatch(UserActivityState.ACTIVE);
    setLastActivityTime(Date.now());
  };

  useEffect(() => {
    if (activityState === UserActivityState.ACTIVE) {
      const throttled = throttle(onActivityTaken, 200);
      for (const event of ACTIVITY_EVENTS) {
        document.addEventListener(event, throttled);
      }

      return () => {
        for (const event of ACTIVITY_EVENTS) {
          document.removeEventListener(event, throttled);
        }
      };
    }

    return () => {};
  }, [activityState]);

  return (
    <>
      {activityState === UserActivityState.WARNING ? (
        <ReactInactivityWarning
          duration={warningDuration}
          onResume={() => onActivityTaken()}
          onLogout={() => dispatch(UserActivityState.END)}
          onTimeout={() => dispatch(UserActivityState.INACTIVE)}
        />
      ) : null}

      {children}
    </>
  );
}
