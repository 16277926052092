import React from 'react';
import { MuiSvgIcon, MuiIcon, IconProps } from 'theme/material-ui';
import useStyles from '../useStyles';

export type ConnectCareIconProps = IconProps;

const isNumber = (obj: any) => {
  return typeof obj === 'string' || typeof obj === 'number' ? !isNaN(+obj) : false;
};
function ConnectCareIcon(props: ConnectCareIconProps) {
  const { children, ...rest } = props;
  const classes = useStyles();

  if (isNumber(children)) {
    return (
      <MuiSvgIcon {...rest} component="svg">
        <circle cx="12" cy="12" r="12" />
        <text className={classes.svgText} x="12" y="16" textAnchor="middle">
          {children}
        </text>
      </MuiSvgIcon>
    );
  }

  return <MuiIcon {...rest}>{children}</MuiIcon>;
}

ConnectCareIcon.defaultProps = {
  color: 'primary'
};

export default ConnectCareIcon;
