import React, { useState } from 'react';
import { useField, FieldHookConfig } from 'formik';
import { ConnectCareTextField, ConnectCareTextFieldProps } from '../ConnectCareTextField';

export type ConnectCareFormTextFieldProps = FieldHookConfig<string> & {
  label?: ConnectCareTextFieldProps['label'];
  placeholder?: ConnectCareTextFieldProps['placeholder'];
  InputProps?: ConnectCareTextFieldProps['InputProps'];
  onBlur?: ConnectCareTextFieldProps['onBlur'];
  onChange?: ConnectCareTextFieldProps['onChange'];
  mask?: ConnectCareTextFieldProps['mask'];
  type?: ConnectCareTextFieldProps['type'];
  disabled?: ConnectCareTextFieldProps['disabled'];
  required?: ConnectCareTextFieldProps['required'];
  helperTextInfo?: string;
};

export function ConnectCareFormTextField(props: ConnectCareFormTextFieldProps) {
  const {
    onBlur,
    onChange,
    mask,
    label,
    placeholder,
    InputProps,
    type,
    disabled,
    required,
    helperTextInfo,
    ...rest
  } = props;
  const [field, meta] = useField(rest);
  const error = meta.touched && !!meta.error;
  const helperText = error && meta.error;
  const [lastLength, setLastLength] = useState(0);
  const [showError, setShowError] = useState(false);

  return (
    <ConnectCareTextField
      {...field}
      required={required}
      error={error}
      helperText={helperText || ' '}
      helperTextInfo={helperTextInfo}
      mask={mask}
      label={label}
      type={type}
      disabled={disabled}
      placeholder={placeholder}
      onChange={(e: any) => {
        const { value } = e.target;
        if (
          InputProps?.inputProps &&
          lastLength < value.length &&
          lastLength === InputProps?.inputProps?.maxLength
        ) {
          setShowError(true);
          return;
        }
        field.onChange(e);
        if (onChange) onChange(e);
        setLastLength(value.length || 0);
        if (showError) setShowError(false);
      }}
      onBlur={(e: any) => {
        field.onBlur(e);
        if (onBlur) onBlur(e);
      }}
      InputProps={{
        ...InputProps,
        inputProps: {
          maxLength: InputProps?.inputProps?.maxLength
            ? InputProps?.inputProps.maxLength + 1
            : null,
          showError: InputProps?.inputProps?.maxLength ? showError : null
        }
      }}
    />
  );
}
