import React, { useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';

import AwsdkError from 'lib/amwell/client/errors/AwsdkError';
import { errors as amwellErrors } from 'lib/amwell/client/constants';

import Spinner from 'components/UI/Spinner/Spinner';
import Svg from 'components/UI/Svg/Svg';
import { Alert } from 'components/Alert';
import { ConnectCareDataLoader } from 'components/ConnectCare';

import { MuiBox, MuiIconButton, MuiTypography } from 'theme/material-ui';

import { Color } from 'modules/styles/colors';
import { Nullable } from 'modules/types/common';
import { IconSize } from 'modules/styles/variables';

import { ChatReport } from 'store/amwell/types';
import { addChatMessage } from 'store/amwell/actions';
import * as selectors from 'store/amwell/selectors';
import { RootState, RootDispatch } from 'store/types';

import ConnectCareWaitingRoomChatListItem from './ConnectCareWaitingRoomChatListItem';
import { ChatWindow, ChatWindowHeader, MessageList, SendButton, MessageTextField } from './styled';

export interface Props {
  dispatch: RootDispatch;
  chatReport: Nullable<ChatReport>;
  loading: boolean;
  error: Nullable<Error> | Nullable<AwsdkError>;
  handleClose: () => void;
}

const getHelperText = (err: AwsdkError | Error | null) => {
  let helperText = '';

  if (err instanceof AwsdkError && err.errorCode === amwellErrors.INCORRECT_PARAMETERS) {
    helperText = 'Failed to send message. Please try again.';
  }

  return helperText;
};

function ConnectCareWaitingRoomChatWindow(props: Props) {
  const { dispatch, chatReport, loading, error, handleClose } = props;
  const messagesEndRef = useRef(null);
  const [message, setMessage] = useState('');
  const [isFullscreen, setIsFullscreen] = useState(false);
  const helperText = useMemo(() => getHelperText(error), [error]);

  const scrollToBottom = () => {
    messagesEndRef?.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(scrollToBottom, [chatReport?.chatItems]);

  const errorComponentProps = {
    message: 'There was an issue fetching chat data.'
  };

  const onMessageChange = (e: React.ChangeEvent<any>) => {
    setMessage(e.target.value);
  };

  const handleSendMessage = async () => {
    await dispatch(addChatMessage({ message, lastPolledChatOrdinal: 0 }));

    if (error instanceof AwsdkError && error.errorCode === amwellErrors.INTERNAL_ERROR) {
      Alert.alert(
        'Oops! Failed to send message.',
        'Something went wrong on our end. We are working on getting the issue resolved as soon as we can.'
      );
    }

    setMessage('');
  };

  const onEnterKeyPress = (e: React.KeyboardEvent<any>) => {
    if (e.charCode === 13) {
      handleSendMessage();
      e.preventDefault();
    }
  };

  return (
    <ChatWindow fullscreen={isFullscreen}>
      <ChatWindowHeader>
        <MuiBox>
          <MuiTypography variant="h6">Connect Care Chat</MuiTypography>
        </MuiBox>
        <MuiBox display="flex" flexDirection="row" justifyContent="space-between">
          <MuiIconButton onClick={() => setIsFullscreen(!isFullscreen)}>
            <Svg
              color={Color.white}
              set="material"
              name={isFullscreen ? 'fullscreen-exit' : 'fullscreen'}
              size={IconSize.base}
            />
          </MuiIconButton>
          <MuiIconButton onClick={handleClose}>
            <Svg color={Color.white} set="material" name="close" size={IconSize.base} />
          </MuiIconButton>
        </MuiBox>
      </ChatWindowHeader>
      <ConnectCareDataLoader
        data={chatReport}
        errorComponentProps={errorComponentProps}
        noDataComponentProps={errorComponentProps}
        renderData={data => (
          <MessageList>
            {data.chatItems?.map(d => (
              <ConnectCareWaitingRoomChatListItem key={d.ordinal} chatItem={d} />
            ))}
            <MuiBox ref={messagesEndRef} />
          </MessageList>
        )}
      />
      <MuiBox p={1}>
        <MessageTextField
          multiline
          value={message}
          disabled={loading}
          placeholder="Enter your response here..."
          onChange={onMessageChange}
          onKeyPress={onEnterKeyPress}
          error={!!helperText}
          helperText={helperText}
          InputProps={{
            endAdornment: loading ? (
              <Spinner size={30} />
            ) : (
              <SendButton disabled={!message} onClick={handleSendMessage}>
                <Svg set="material" name="send" size={IconSize.base} />
              </SendButton>
            )
          }}
        />
      </MuiBox>
    </ChatWindow>
  );
}

const mapStateToProps = (state: RootState) => ({
  error: selectors.addChatMessageErrorSelector(state),
  loading: selectors.addChatMessageLoadingSelector(state)
});

export default connect(mapStateToProps)(ConnectCareWaitingRoomChatWindow);
