import React from 'react';
import Avatar from 'components/UI/Avatar/Avatar';
import Box from 'components/UI/Layout/Box';
import { MuiTypography, MuiBox } from 'theme/material-ui';
import { Divider } from '@material-ui/core';
import { headingFontSize, IconSize, smallFontSize, Spacing } from 'modules/styles/variables';
import { Color } from 'modules/styles/colors';
import ColorCheckbox from '../ColorCheckbox/ColorCheckbox';

import {
  CardContainer,
  CardInfoContainer,
  CardBodyContainer,
  CardInfo,
  CardAvatar,
  CardHeading,
  CardHeadingContainer,
  CardSubTitle,
  CardText,
  CardIcon,
  HeadingText
} from './styled';
import { Svg } from '../Svg';

export interface SummaryCardType {
  heading: string;
  title: string;
  avatarUrl?: string;
  subTitle: string;
  subHeading: string;
  subTitleIcon?: any;
  bodyText?: string;
  headingTag?: string;
  isSelected?: boolean;
}

const renderCardAvatar = (url = '') => (
  <CardAvatar>
    <Box border={{ width: 3, color: Color.secondary, style: 'solid', radius: 50 }}>
      <Avatar style={{ backgroundColor: Color.white }} imageUrl={url} />
    </Box>
  </CardAvatar>
);

const CardInfoComponent = ({
  card,
  onChecked,
  enableCheckbox
}: {
  card: SummaryCardType;
  onChecked: () => void;
  enableCheckbox?: boolean;
}) => {
  const handleOnCheckboxClick = (event: React.ChangeEvent<HTMLElement>) => {
    event.stopPropagation();
  };

  return (
    <CardInfoContainer
      data-testid={card.isSelected ? 'visit-summary-card-checked' : 'visit-summary-card'}
    >
      {enableCheckbox ? (
        <MuiBox marginLeft={-1} padding={1}>
          <ColorCheckbox
            customColor={Color.purple}
            checked={card.isSelected}
            onChange={onChecked}
            onClick={handleOnCheckboxClick}
            disablePadding
          />
        </MuiBox>
      ) : null}
      <CardInfo>
        <CardHeadingContainer>
          <Box flex={2}>
            <CardHeading>
              <HeadingText>{card.heading}</HeadingText>
            </CardHeading>
            <CardText fontSize={headingFontSize}>
              <MuiTypography variant="h6" fontWeight="bold">
                {card.title}
              </MuiTypography>
            </CardText>
          </Box>
          {renderCardAvatar(card.avatarUrl)}
        </CardHeadingContainer>

        <Divider background-color={Color.gray} style={{ margin: '5px' }} />

        <CardSubTitle>
          {!!card.subTitleIcon && (
            <CardIcon name={card.subTitleIcon} height={IconSize.medium} width={IconSize.medium} />
          )}
          <CardText color={Color.gray} fontSize={smallFontSize}>
            {card.subTitle}
          </CardText>
        </CardSubTitle>
        <CardSubTitle>
          <Box hOffsetRight={`${Spacing.medium}px`}>
            <Svg name="Location" size={IconSize.medium} color={Color.secondary} />
          </Box>
          <CardText color={Color.textLight} fontSize={smallFontSize}>
            {card.subHeading}
          </CardText>
        </CardSubTitle>
      </CardInfo>
    </CardInfoContainer>
  );
};

const SummaryCard = ({
  card,
  onChecked,
  enableCheckbox
}: {
  card: SummaryCardType;
  onChecked: () => void;
  enableCheckbox?: boolean;
}) => (
  <CardContainer accessibilityLabel="Summary Card" testID="SummaryCard">
    <CardInfoComponent card={card} enableCheckbox={enableCheckbox} onChecked={onChecked} />
    {!!card.bodyText && (
      <CardBodyContainer>
        <CardText>{card.bodyText}</CardText>
      </CardBodyContainer>
    )}
  </CardContainer>
);

export default SummaryCard;
