import useNavigationAnalytics from 'hooks/useNavigationAnalytics';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { AnalyticsEvent } from 'services/AnalyticsService';
import { currentAccountConsumerSelector } from 'store/account/selectors';
import * as bookingActions from 'store/booking/actions';
import * as bookingSelectors from 'store/booking/selectors';

const useBookingNavigationSetup = () => {
  const patients = useSelector(bookingSelectors.patientsDataSelector);
  const currentProfile = useSelector(currentAccountConsumerSelector);
  const dispatch = useDispatch();
  const history = useHistory();
  const bookingAppointmentStarted = useNavigationAnalytics(AnalyticsEvent.BookAppointmentStarted);
  const bookingPatientSelected = useNavigationAnalytics(AnalyticsEvent.BookingPatientSelected);

  return useCallback(() => {
    bookingAppointmentStarted();
    dispatch(bookingActions.setPatient(currentProfile));
    dispatch(bookingActions.validateSelectedPatientInsurance());
    bookingPatientSelected();
    history.push('/u/get-care-now/booking/specialty-or-my-doctor-select');
  }, [patients]);
};

export default useBookingNavigationSetup;
