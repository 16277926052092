import React from 'react';
import { Image } from 'react-native';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import DoctorImg from 'assets/Doctor.png';

import Svg from 'components/UI/Svg/Svg';
import {
  dfdDefaultTheme,
  MuiBox,
  MuiDialog,
  MuiTypography,
  MuiDivider,
  MuiGrid,
  MuiButton
} from 'theme/material-ui';

import { Color } from 'modules/styles/colors';
import { FontSize, FontWeight, IconSize } from 'modules/styles/variables';
import moreInfo from 'modules/constants/booking/locationPreferenceMoreInfo';

import { StyledUL, CloseButton, StyledLICircle } from '../../components/styled';

interface Props {
  fullScreen?: boolean;
  handleClose: Function;
  open: boolean;
  secondScreen?: boolean;
  Transition?: React.ForwardRefExoticComponent<React.RefAttributes<unknown>>;
}

const listItemCircle = (item: string) => <StyledLICircle>{item}</StyledLICircle>;
const listItemSquare = (item: string) => <MuiTypography>{item}</MuiTypography>;
const titleTypography = (item: string) => (
  <MuiTypography fontSize={FontSize.large} fontWeight={FontWeight.semibold}>
    {item}
  </MuiTypography>
);
const typography = (item: string) => (
  <MuiBox display="flex" py={1}>
    <MuiTypography>{item}</MuiTypography>
  </MuiBox>
);

export const BookingLocationPreferenceMoreInfoDialog = ({ handleClose, open }: Props) => {
  const fullScreen = useMediaQuery(dfdDefaultTheme.breakpoints.down('sm'));

  return (
    <MuiDialog fullScreen={fullScreen} maxWidth="md" open={open} onClose={handleClose}>
      <MuiBox p={3} py={2} pl={8} pr={8}>
        <CloseButton onClick={handleClose}>
          <Svg name="CloseIcon" size={IconSize.medium} />
        </CloseButton>
        <MuiBox display="flex" justifyContent="center" py={1}>
          <Svg set="assets" size={IconSize.xxLarge} color={Color.primary} name="InfoIcon" />
        </MuiBox>
        <MuiBox display="flex" justifyContent="center" py={2}>
          <MuiTypography align="center" fontSize={24}>
            More information about location preference
          </MuiTypography>
        </MuiBox>
        <MuiDivider />
        {moreInfo.LOCATION_PREFERENCE_DIALOG_TYPOGRAPHY.map((item: string) => typography(item))}
        <MuiBox mb={3}>
          <MuiDivider />
        </MuiBox>
        <MuiTypography>
          <MuiGrid item xs={14} align="center">
            <Svg set="assets" fill={Color.grayHue6} name="DocPhone" size={IconSize.xLarge * 3} />
          </MuiGrid>
          <MuiTypography fontSize={FontSize.large} fontWeight={FontWeight.semibold}>
            {moreInfo.LOCATION_PREFERENCE_DIALOG_TITLE_ONE}
          </MuiTypography>
          {moreInfo.LOCATION_PREFERENCE_DIALOG_SUBTITLE_LIST_ONE.map((item: string) =>
            listItemSquare(item)
          )}
          <StyledUL>
            <StyledUL>
              {moreInfo.LOCATION_PREFERENCE_LIST_ITEMS_ONE.map((item: string) =>
                listItemCircle(item)
              )}
            </StyledUL>
          </StyledUL>
          {moreInfo.LOCATION_PREFERENCE_DIALOG_SUBTITLE_LIST_TWO.map((item: string) =>
            listItemSquare(item)
          )}
          <StyledUL>
            <StyledUL>
              {moreInfo.LOCATION_PREFERENCE_LIST_ITEMS_TWO.map((item: string) =>
                listItemCircle(item)
              )}
            </StyledUL>
          </StyledUL>
          {moreInfo.LOCATION_PREFERENCE_DIALOG_SUBTITLE_LIST_THREE.map((item: string) =>
            listItemSquare(item)
          )}
          <StyledUL>
            <StyledUL>
              {moreInfo.LOCATION_PREFERENCE_LIST_ITEMS_THREE.map((item: string) =>
                listItemCircle(item)
              )}
            </StyledUL>
          </StyledUL>
          <br />
        </MuiTypography>
        <MuiBox mb={3}>
          <MuiDivider />
        </MuiBox>
        <MuiGrid item xs={14} align="center">
          <Image style={{ width: 120, height: 120 }} source={{ uri: DoctorImg }} />
        </MuiGrid>
        <MuiBox mb={3}></MuiBox>
        {moreInfo.LOCATION_PREFERENCE_DIALOG_TITLE_TWO.map((item: string) => titleTypography(item))}
        <MuiTypography>
          <StyledUL>
            <StyledUL>
              {moreInfo.LOCATION_PREFERENCE_LIST_ITEMS_FOUR.map(item => listItemCircle(item))}
            </StyledUL>
          </StyledUL>
          <br />
        </MuiTypography>
        <MuiBox mb={3}>
          <MuiDivider />
        </MuiBox>
        <MuiGrid item xs={14} align="center">
          <Svg
            set="assets"
            fill={Color.grayHue6}
            name="COVID19ModalHospital"
            size={IconSize.xLarge * 3}
          />
        </MuiGrid>
        {moreInfo.LOCATION_PREFERENCE_DIALOG_TITLE_FOUR.map((item: string) =>
          titleTypography(item)
        )}
        <MuiTypography>
          <StyledUL>
            <StyledUL>
              {moreInfo.LOCATION_PREFERENCE_LIST_ITEMS_FIVE.map(item => listItemCircle(item))}
            </StyledUL>
          </StyledUL>
        </MuiTypography>
        <br />
        {moreInfo.LOCATION_PREFERENCE_DIALOG_TITLE_THREE.map((item: string) =>
          titleTypography(item)
        )}
        <br />
        <MuiBox display="flex" pt={3} justifyContent="center">
          <MuiButton variant="contained" color="primary" fullWidth onClick={() => handleClose()}>
            Close
          </MuiButton>
        </MuiBox>
      </MuiBox>
    </MuiDialog>
  );
};

export default BookingLocationPreferenceMoreInfoDialog;
