import ProviderItem from 'components/cost/Provider/ProviderItem';
import { CostProvider } from 'store/cost/types';
import React from 'react';
import { UserCoords } from 'store/global/reducers';
import { MuiBox, MuiCard, MuiCardActionArea, MuiGrid, MuiTypography } from 'theme/material-ui';

interface Props {
  providers?: CostProvider[];
  onPress: (provider: CostProvider) => void;
  geoLocation: UserCoords;
}

function NoProviders() {
  return (
    <MuiBox>
      <MuiTypography>No Providers Found</MuiTypography>
    </MuiBox>
  );
}

const ProviderList = ({ providers, geoLocation, onPress }: Props) => {
  if (!providers?.length) {
    return <NoProviders />;
  }

  return (
    <MuiGrid container spacing={1}>
      {providers.map(provider => (
        <MuiGrid key={provider.id} item xs={12} lg={6} sm={12}>
          <MuiCard onClick={() => onPress(provider)}>
            <MuiCardActionArea>
              <ProviderItem provider={provider} geoLocation={geoLocation} />
            </MuiCardActionArea>
          </MuiCard>
        </MuiGrid>
      ))}
    </MuiGrid>
  );
};

export default ProviderList;
