import styled from 'styled-components';
import { headerHeight } from 'components/AuthLayout/styled';
import { FlexBoxRow } from 'components/UI/Layout/FlexBox';

export default styled(FlexBoxRow)`
  &&& {
    flex-wrap: nowrap;
    height: calc(100vh - ${headerHeight}px);
  }
`;
