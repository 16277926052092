import styled from 'styled-components';
import { Spacing, FontSize, FontWeight } from 'modules/styles/variables';
import { Color } from 'modules/styles/colors';
import DefaultLabel from 'components/UI/Labels/DefaultLabel';
import {
  dfdDefaultTheme,
  MuiTable,
  MuiTableCell,
  MuiTableRow,
  MuiTableHead,
  MuiBox
} from 'theme/material-ui';

export const StyledMainBox = styled(MuiBox)`
  margin: ${Spacing.large}px ${Spacing.xxLarge}px;

  ${dfdDefaultTheme.breakpoints.down('lg')} {
    margin: ${Spacing.medium}px ${Spacing.xLarge}px;
  }

  ${dfdDefaultTheme.breakpoints.down('md')} {
    margin: ${Spacing.small}px ${Spacing.large}px;
  }

  ${dfdDefaultTheme.breakpoints.down('sm')} {
    margin: ${Spacing.small}px ${Spacing.medium}px;
  }
`;

export const BorderBox = styled.div`
  background-color: ${Color.white};
  box-shadow: 0px 0px 4px;
`;

export const RaisedBox = styled(BorderBox)`
  padding: ${Spacing.xLarge}px ${Spacing.xxLarge}px;
  border: 1px solid ${Color.grayHue5};
  border-radius: 4px;
  line-height: ${FontSize.largeHeading}px;
`;

export const RaisedApplicationBox = styled(RaisedBox)`
  padding: ${Spacing.large}px ${Spacing.xLarge}px;
`;
export const GreyView = styled.div`
  flex: 1;
  width: 100%;
`;

export const GrayBar = styled(MuiBox)`
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  padding: ${Spacing.medium}px;
  padding-left: ${Spacing.large}px;
  background-color: ${Color.baseColor};
  border-top-color: ${Color.grayLight};
  border-top-width: 1px;
  border-bottom-color: ${Color.grayLight};
  border-bottom-width: 1px;
`;

export const GreyViewBox = styled(MuiBox)`
  margin: 0 ${Spacing.medium}px;
  color: #3d4447;
`;
export const GreyViewBoldBox = styled(MuiBox)`
  font-weight: ${FontWeight.semibold};
  margin: 0 ${Spacing.medium}px;
`;

export const GreyViewBlueBox = styled(MuiBox)`
  color: ${Color.primary};
`;

export const BlueTextLabel = styled(DefaultLabel)<{
  fontSize?: number;
  textAlign?: string;
  hSpacing?: number;
  vSpacing?: number;
}>`
  color: ${Color.grayHue2};
  font-size: ${props => (props.fontSize ? props.fontSize : FontSize.base)};
  text-align: ${props => (props.textAlign ? props.textAlign : 'center')};
  padding: ${props => (props.hSpacing ? props.hSpacing : 0)}px
    ${props => (props.vSpacing ? props.vSpacing : 0)}px;
  word-break: break-word;
`;

export const StyledTable = styled(MuiTable)`
  border: 2px solid ${Color.tertiary};
`;

export const StyledTableCell = styled(MuiTableCell)`
  border: 1px solid ${Color.tertiary};
  && {
    text-align: center;
  }
  &.title {
    background-color: ${Color.tertiary};
    color: ${Color.white};
  }
`;

export const StyledTableHead = styled(MuiTableHead)`
  border: 1px solid ${Color.tertiary};
  background-color: ${Color.tableRowLight};
  text-align: center;
`;

export const StyledTableRow = styled(MuiTableRow)`
  & td {
    border: 1px solid ${Color.tertiary};
  }
  &:nth-of-type(even) {
    background-color: ${Color.tableRowLight};
  }
  &.header {
    border-bottom: 2px solid ${Color.tertiary};
  }
`;

export const TableWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
`;
