import React from 'react';
import useStyles from '../useStyles';

export interface ConnectCareBulletProps {
  value: React.ReactNode;
  spacing?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
}

function ConnectCareBullet({ value, spacing, ...rest }: ConnectCareBulletProps) {
  const classes = useStyles({ spacing });

  return (
    <span className={classes.bullet} {...rest}>
      {value}
    </span>
  );
}

ConnectCareBullet.defaultProps = {
  value: '•'
};

export default ConnectCareBullet;
