import React from 'react';
import { View, FlatList } from 'react-native';
import Headline from 'components/UI/Typography/Headline';
import styled from 'styled-components';
import SearchResultsItem from 'components/cost/SearchResults/SearchResultsItem';
import { SearchTopic } from 'store/cost/reducers';
import Spacer from 'components/UI/Layout/Spacer';

interface Props {
  results: SearchTopic[];
  onPress: Function;
}

const SearchResultsList = ({ results, onPress }: Props) => {
  const { length } = results || [];

  if (length === 0) {
    return (
      <Container data-testid="cost-estimator-search-results-empty">
        <Headline>No results found, please try searching for a different term</Headline>
      </Container>
    );
  }

  return (
    <Container data-testid="cost-estimator-search-results">
      <FlatList
        data={results}
        keyboardDismissMode="on-drag"
        keyExtractor={item => `${item.id}`}
        ItemSeparatorComponent={() => <Spacer size="small" />}
        renderItem={({ item }) => (
          <View>
            <SearchResultsItem onPress={result => onPress(result)} result={item} />
          </View>
        )}
      />
    </Container>
  );
};

const Container = styled.div`
  margin-bottom: 10px;
  flex: 1;
  height: 100%;
  width: 100%;
`;

export default SearchResultsList;
