import styled from 'styled-components';

import { Color } from 'modules/styles/colors';
import { borderRadius } from 'modules/styles/variables';

const Panel = styled.div`
  background-color: ${Color.foreColor};
  border: 1px solid ${Color.grayHue4};
  border-radius: ${borderRadius};
  padding: 15px 20px;
  width: 100%;
`;

export default Panel;
