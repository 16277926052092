import { InfoCard } from 'components/InfoCard';
import { history } from 'lib/history';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMyDoctors } from 'store/myDoctors/actions';
import { currentConsumerAllDoctorsWithTypeSelector } from 'store/myDoctors/selectors';
import DashboardEmptyStateCard from '../DashboardEmptyStateCard';
import MyCareTeamHeader from './MyCareTeamHeader';
import ProviderList from './ProviderList';

export const DashboardMyCareTeam = () => {
  const dispatch = useDispatch();
  const currentAllProviders = useSelector(currentConsumerAllDoctorsWithTypeSelector);

  useEffect(() => {
    dispatch(getMyDoctors());
  }, []);

  if (currentAllProviders.length > 0) {
    return (
      <InfoCard testID="dashboard-mycare-team-list" customHeader={() => <MyCareTeamHeader />}>
        <ProviderList providerList={currentAllProviders} />
      </InfoCard>
    );
  }

  return (
    <InfoCard testID="dashboard-mycare-team-empty-state">
      <DashboardEmptyStateCard
        iconName="MyDoctors"
        title="My Care Team"
        subtitle="Your primary care team hasn’t been set up yet. Would you like to get started?"
        buttonText="Get Started"
        buttonOnPress={() => history.push('/u/find-a-doctor')}
      />
    </InfoCard>
  );
};

export default DashboardMyCareTeam;
