import { Grid } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import RequiredLabel from 'components/UI/Labels/RequiredLabel';
import FlexBox from 'components/UI/Layout/FlexBox';
import Spacer from 'components/UI/Layout/Spacer';

import FormHelperText from '@material-ui/core/FormHelperText';
import { Formik } from 'formik';
import get from 'lodash/get';
import {
  InsuranceValidateSchema,
  RELATIONSHIP_TYPE,
  InsuranceInformationData,
  RelationshipOptionsType
} from 'modules/constants/covidScreening/insuranceFound';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { NavigationScreenProps } from 'screens/navigation';
import { patientSelect } from 'modules/constants/covidScreening';
import Footer from 'components/common/Footer';
import CustomTextField from 'screens/Register/FormElements/CustomTextField';
import * as covidActions from 'store/CovidScreening/actions';
import { CovidScreeningQuestionnaireSelector } from 'store/CovidScreening/selectors';
import { Questionnaire } from 'store/CovidScreening/types';
import { RootState } from 'store/types';
import styled from 'styled-components';
import { MuiBox, MuiMenuItem, MuiSelect, MuiTypography } from 'theme/material-ui';
import { CovidTestButton } from '../Components/CovidTestButton';
import useStyles from '../Components/CovidTestButton/useStyles';
import { CovidTitles } from 'lib/constants/covidScreening';
import { AnalyticsEvent } from 'services/AnalyticsService';
import useNavigationAnalytics from 'hooks/useNavigationAnalytics';
import { convertToLowerKabobCase } from 'modules/utils/StringUtils';

export interface CovidNewInsuranceFoundProps extends NavigationScreenProps {
  questionnaire: Questionnaire;
  setQuestionnaire: typeof covidActions.setQuestionnaire;
}

export const CovidNewInsuranceFound = ({
  questionnaire,
  setQuestionnaire,
  history
}: CovidNewInsuranceFoundProps) => {
  const initialValues = {
    GROUP_NUMBER: questionnaire.GROUP_NUMBER ?? '',
    MEMBER_NUMBER: questionnaire.MEMBER_NUMBER ?? '',
    Q_Patient_relationship_to_subscriber: questionnaire.Q_Patient_relationship_to_subscriber ?? '',
    SUBSCRIBER_FIRST_NAME_ON_CARD: questionnaire.SUBSCRIBER_FIRST_NAME_ON_CARD ?? '',
    SUBSCRIBER_LAST_NAME_ON_CARD: questionnaire.SUBSCRIBER_LAST_NAME_ON_CARD ?? '',
    SUBSCRIBER_MIDDLE_NAME_ON_CARD: questionnaire.SUBSCRIBER_MIDDLE_NAME_ON_CARD ?? ''
  };

  const classes = useStyles();

  const covidScreeningNextBtn = useNavigationAnalytics(AnalyticsEvent.CovidNext, {
    title: CovidTitles.EXISTING_INSURANCE_FOUND
  });

  const covidScreeningPreviousBtn = useNavigationAnalytics(AnalyticsEvent.CovidPrev, {
    title: CovidTitles.EXISTING_INSURANCE_FOUND
  });

  const standardWidth = 'fullwidth';
  const standardHeight = `
    height: 42px;
    min-height: 42px;`;

  const StyledSelect = styled(MuiSelect)`
    ${standardWidth}
    ${standardHeight}
  select {
      padding: 0px 32px 0px 7px;
      ${standardHeight}
    }
  `;
  const [dropdownSelected, setDropdownSelected] = useState(false);
  const saveValues = (values: InsuranceInformationData) => {
    const newQuestionnaire = {
      ...questionnaire,
      ...values
    };
    setQuestionnaire(newQuestionnaire);
  };
  const saveToStore = (values: InsuranceInformationData) => {
    saveValues(values);
    covidScreeningNextBtn();
    const navigateTo =
      values?.Q_Patient_relationship_to_subscriber === 'Self'
        ? '/u/get-care-now/covid-screen/covid-insurance-upload'
        : '/u/get-care-now/covid-screen/subscriber-information';
    history.push(navigateTo);
  };

  const onPreviousClicked = values => {
    saveValues(values);
    covidScreeningPreviousBtn();
    history.goBack();
  };

  // Todo: Refactor replace form code with components/CovidTest/Insurance/form
  return (
    <>
      <Container maxWidth="md" data-testID="covid-new-insurance">
        <Spacer size="small" />

        <Formik
          enableReinitialize="true"
          validateOnMount
          initialValues={initialValues}
          validationSchema={InsuranceValidateSchema}
          onSubmit={values => saveToStore(values)}
        >
          {({ values, isValid, errors, touched, handleChange, handleBlur, handleSubmit }) => {
            const getFormError = (errorKey: string, touchKey?: string) => {
              const touchedValue = get(touched, touchKey || errorKey);
              const errorValue = get(errors, errorKey);

              return touchedValue && errorValue ? errorValue : null;
            };

            return (
              <>
                <Spacer size="small" />
                <MuiTypography fontWeight="bold">
                  Please provide your insurance information:
                </MuiTypography>
                <Spacer size="small" />
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <RequiredLabel labelText="Member Number/ID" />
                    <CustomTextField
                      name="memberId"
                      variant="outlined"
                      placeholder="Enter Member Number/ID"
                      data-testid="new-insurance-memberid"
                      value={values?.MEMBER_NUMBER}
                      error={!!getFormError('MEMBER_NUMBER' as keyof typeof values)}
                      onChange={handleChange('MEMBER_NUMBER')}
                      onBlur={handleBlur('MEMBER_NUMBER')}
                      helperText={getFormError('MEMBER_NUMBER' as keyof typeof values)}
                      required
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <RequiredLabel hideIndicator labelText="Group Number/ID" />
                    <CustomTextField
                      name="groupid"
                      variant="outlined"
                      placeholder="Enter Group Number/ID"
                      data-testid="new-insurance-groupid"
                      value={values?.GROUP_NUMBER}
                      error={!!getFormError('GROUP_NUMBER' as keyof typeof values)}
                      onChange={handleChange('GROUP_NUMBER')}
                      onBlur={handleBlur('GROUP_NUMBER')}
                      helperText={getFormError('GROUP_NUMBER' as keyof typeof values)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FlexBox width="100%">
                      <FlexBox flexDirection="row" justifyContent="flex-start" alignItems="center">
                        <RequiredLabel labelText="Patient relationship to subscriber" />
                      </FlexBox>
                      <StyledSelect
                        name="relationship"
                        variant="outlined"
                        data-testid="new-insurance-relationship"
                        value={values?.Q_Patient_relationship_to_subscriber}
                        onChange={handleChange('Q_Patient_relationship_to_subscriber')}
                        onClose={() => setDropdownSelected(true)}
                        error={dropdownSelected && !!errors?.Q_Patient_relationship_to_subscriber}
                      >
                        {RELATIONSHIP_TYPE.map((option: RelationshipOptionsType) => (
                          <MuiMenuItem
                            style={{ whiteSpace: 'normal' }}
                            data-testid={convertToLowerKabobCase(
                              option.value,
                              '-relationship-to-subscriber'
                            )}
                            key={option.label}
                            value={option.value}
                          >
                            {option.label}
                          </MuiMenuItem>
                        ))}
                      </StyledSelect>
                      {dropdownSelected && !!errors?.Q_Patient_relationship_to_subscriber ? (
                        <FormHelperText error>
                          {errors?.Q_Patient_relationship_to_subscriber}
                        </FormHelperText>
                      ) : null}
                    </FlexBox>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <RequiredLabel labelText="First name on card" />
                    <CustomTextField
                      name="firstname"
                      variant="outlined"
                      placeholder="Enter First Name"
                      data-testid="new-insurance-first-name"
                      value={values?.SUBSCRIBER_FIRST_NAME_ON_CARD}
                      error={!!getFormError('SUBSCRIBER_FIRST_NAME_ON_CARD')}
                      onChange={handleChange('SUBSCRIBER_FIRST_NAME_ON_CARD')}
                      onBlur={handleBlur('SUBSCRIBER_FIRST_NAME_ON_CARD')}
                      helperText={getFormError(
                        'SUBSCRIBER_FIRST_NAME_ON_CARD' as keyof typeof values
                      )}
                      required
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <RequiredLabel hideIndicator labelText="Middle name on card" />
                    <CustomTextField
                      name="middlename"
                      variant="outlined"
                      placeholder="Enter Middle Name"
                      data-testid="new-insurance-middle-name"
                      value={values?.SUBSCRIBER_MIDDLE_NAME_ON_CARD}
                      error={
                        !!getFormError('SUBSCRIBER_MIDDLE_NAME_ON_CARD' as keyof typeof values)
                      }
                      onChange={handleChange('SUBSCRIBER_MIDDLE_NAME_ON_CARD')}
                      onBlur={handleBlur('SUBSCRIBER_MIDDLE_NAME_ON_CARD')}
                      helperText={getFormError(
                        'SUBSCRIBER_MIDDLE_NAME_ON_CARD' as keyof typeof values
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <RequiredLabel labelText="Last name on card" />
                    <CustomTextField
                      name="lastname"
                      variant="outlined"
                      placeholder="Enter Last Name"
                      data-testid="new-insurance-last-name"
                      value={values?.SUBSCRIBER_LAST_NAME_ON_CARD}
                      error={!!getFormError('SUBSCRIBER_LAST_NAME_ON_CARD')}
                      onChange={handleChange('SUBSCRIBER_LAST_NAME_ON_CARD')}
                      onBlur={handleBlur('SUBSCRIBER_LAST_NAME_ON_CARD')}
                      helperText={getFormError(
                        'SUBSCRIBER_LAST_NAME_ON_CARD' as keyof typeof values
                      )}
                      required
                    />
                  </Grid>
                </Grid>
                <Spacer size="medium" />
                <MuiBox className={classes.steppersFooter}>
                  <CovidTestButton
                    fullWidth
                    data-testid="insurance-found-next-btn"
                    color="primary"
                    variant="contained"
                    disabled={!isValid}
                    onClick={() => handleSubmit()}
                  >
                    Next
                  </CovidTestButton>
                  <Spacer size="xsmall" />
                  <CovidTestButton
                    variant="text"
                    data-testid="insurance-found-previous-button"
                    fullWidth
                    onClick={() => onPreviousClicked(values)}
                  >
                    Previous
                  </CovidTestButton>
                </MuiBox>
                <Footer
                  headerText={patientSelect.GET_PATIENTS.HELPLINE.CovidInfo}
                  link={patientSelect.GET_PATIENTS.HELPLINE.CovidPatientSelectHelpline}
                  linkName={patientSelect.GET_PATIENTS.HELPLINE.CovidPatientSelectHelpline}
                  helplineHours={patientSelect.GET_PATIENTS.HELPLINE.CovidHelplineHours}
                  title={CovidTitles.EXISTING_INSURANCE_FOUND}
                />
              </>
            );
          }}
        </Formik>
      </Container>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  questionnaire: CovidScreeningQuestionnaireSelector(state)
});

const mapDispatchToProps = {
  setQuestionnaire: covidActions.setQuestionnaire
};

export default connect(mapStateToProps, mapDispatchToProps)(CovidNewInsuranceFound);
