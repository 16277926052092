import React from 'react';
import {
  MuiBox,
  MuiPaper,
  MuiGrid,
  MuiTypography,
  MuiCard,
  MuiCardContent,
  MuiButton
} from 'theme/material-ui';
import { connect } from 'react-redux';
import Banner from 'components/UI/Banner/Banner';
import { Color } from 'modules/styles/colors';
import { ActivityStatus } from 'store/amwell/types';
import { setActivityStatus } from 'store/amwell/actions';
import { RootDispatch } from 'store/types';
import useStyles from '../useStyles';

export interface Props {
  setEndVisitActivityStatus: () => void;
}

export function ConnectCareVisitSummaryCancel(props: Props) {
  const classes = useStyles();

  const handleDone = () => {
    props.setEndVisitActivityStatus();
  };

  return (
    <MuiCard>
      <Banner
        align="center"
        message="Thank You"
        subMessage={
          <MuiBox width="auto" pb={3}>
            <MuiTypography align="center" data-testid="end-visit" color={Color.white}>
              Your visit has ended
            </MuiTypography>
          </MuiBox>
        }
      />
      <MuiCardContent>
        <MuiGrid container spacing={2} alignItems="center" justify="center">
          <MuiGrid item xs={8}>
            <MuiPaper variant="outlined" className={classes.paper}>
              <MuiTypography align="center">Your credit card has not been charged</MuiTypography>
            </MuiPaper>
          </MuiGrid>
          <MuiGrid item xs={8} style={{ textAlign: 'center' }}>
            <MuiButton
              fullWidth
              variant="contained"
              color="primary"
              data-testid="visit-done"
              onClick={handleDone}
            >
              Done
            </MuiButton>
          </MuiGrid>
        </MuiGrid>
      </MuiCardContent>
    </MuiCard>
  );
}

const mapDispatchToProps = (dispatch: RootDispatch) => ({
  setEndVisitActivityStatus: () => dispatch(setActivityStatus(ActivityStatus.END_VISIT))
});

export default connect(null, mapDispatchToProps)(ConnectCareVisitSummaryCancel);
