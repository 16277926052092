import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { MuiBox, MuiContainer, MuiDivider, MuiTypography } from 'theme/material-ui';
import { FontWeight, Spacing } from 'modules/styles/variables';
import Spacer from 'components/UI/Layout/Spacer';
import { StyledAsterisk } from 'components/RequiredFieldsLegend';
import Checkbox from '@material-ui/core/Checkbox';
import Footer from 'components/common/Footer';
import { patientSelect } from 'modules/constants/covidScreening';
import { CovidTestButton } from '../Components/CovidTestButton';
import useStyles from '../Components/CovidTestButton/useStyles';
import { FlatList } from 'react-native';
import { COVID_CONSENT_CHECKBOX } from 'lib/covidScreening/types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Box from '@material-ui/core/Box';
import { history } from 'lib/history';
import { CovidTitles } from 'lib/constants/covidScreening';
import { AnalyticsEvent } from 'services/AnalyticsService';
import useNavigationAnalytics from 'hooks/useNavigationAnalytics';
import { RootState, RootDispatch } from 'store/types';
import { Questionnaire } from 'store/CovidScreening/types';
import * as covidActions from 'store/CovidScreening/actions';
import { CovidScreeningQuestionnaireSelector } from 'store/CovidScreening/selectors';
import { convertToLowerKabobCase } from 'modules/utils/StringUtils';
import { CompanyName } from 'modules/utils/ConfigUtils';

interface CovidConsentAndAgreementProps {
  questionnaire: Questionnaire;
  setQuestionnaire: typeof covidActions.setQuestionnaire;
  dispatch: RootDispatch;
}

const CovidAgreement = ({ questionnaire, setQuestionnaire }: CovidConsentAndAgreementProps) => {
  const classes = useStyles();
  const covidAgreementPrevBtn = useNavigationAnalytics(AnalyticsEvent.CovidPrev);
  const covidAgreementNextBtn = useNavigationAnalytics(AnalyticsEvent.CovidNext);
  const [requiredField, setRequiredField] = useState({
    consent1: false,
    consent2: false,
    consent3: false,
    consent4: false
  });
  const getFieldValue = () => {
    const reduxFieldValue = {
      consent1: questionnaire.consent1,
      consent2: questionnaire.consent2,
      consent3: questionnaire.consent3,
      consent4: questionnaire.consent4
    };
    setRequiredField(reduxFieldValue);
  };
  useEffect(() => {
    getFieldValue();
  }, []);
  const handleOnChange = e => {
    const { value } = e.target;
    setRequiredField(prevState => ({ ...prevState, [value]: !requiredField[value] }));
  };
  const saveToStore = () => {
    const questionnaireObject = {
      ...questionnaire,

      Q_attest_to_accurate_and_complete: 'Yes',
      consent1: requiredField.consent1,
      consent2: requiredField.consent2,
      consent3: requiredField.consent3,
      consent4: requiredField.consent4
    };
    setQuestionnaire(questionnaireObject);
  };

  const onCancelClick = () => {
    saveToStore();
    history.goBack();
    covidAgreementPrevBtn({ title: CovidTitles.CONSENT_AND_AGREEMENTS });
  };
  const onNextClick = () => {
    saveToStore();
    history.push('/u/get-care-now/covid-screen/covid-reasons');
    covidAgreementNextBtn({
      agrees_to_terms: 'yes',
      title: CovidTitles.CONSENT_AND_AGREEMENTS
    });
  };

  const isValid = () => {
    const { consent1, consent2, consent3, consent4 } = requiredField;
    if (consent1 && consent2 && consent3 && consent4) {
      return false;
    }
    return true;
  };

  const completeLabel = (preLabel: string | undefined, label: string) => {
    return (
      <>
        <MuiTypography
          display="inline"
          fontWeight={FontWeight.bold}
          style={{ textDecorationLine: 'underline' }}
        >
          {preLabel}
        </MuiTypography>
        <MuiTypography display="inline">
          {label}
          <StyledAsterisk />
        </MuiTypography>
      </>
    );
  };

  return (
    <>
      <MuiContainer maxWidth="lg">
        <MuiBox
          my={3}
          pt={Spacing.small}
          display="flex"
          flex={1}
          flexDirection="column"
          justifyContent="space-between"
        >
          <MuiBox mb={1}>
            <MuiTypography variant="h4">{CompanyName} COVID-19 Screening for Testing</MuiTypography>
          </MuiBox>
          <MuiBox mb={3}>
            <MuiDivider />
          </MuiBox>
          <Spacer />
          <MuiTypography display="inline">
            This screening is for{' '}
            <MuiTypography fontWeight={FontWeight.bold} display="inline">
              Saliva-PCR Self Service testing only.
            </MuiTypography>
          </MuiTypography>
          <Spacer />
          <MuiTypography fontWeight={FontWeight.normal}>
            Before you proceed, make sure you will be able to give a saliva (drool) sample. You can
            evaluate your ability to do this by moving your tongue back and forth on the back of
            your bottom teeth. If saliva (drool) collects or pools in your mouth then you will be
            able to produce the saliva needed for this test.
          </MuiTypography>
          <Spacer />
          <MuiTypography fontWeight={FontWeight.normal}>
            User must agree to the following statements before proceeding with the survey.
          </MuiTypography>
          <Spacer />
          <FlatList
            ItemSeparatorComponent={() => <Spacer size="small" />}
            data={COVID_CONSENT_CHECKBOX}
            keyExtractor={item => `${item.key}`}
            renderItem={({ item }) => {
              return (
                <>
                  <Box py={0.2}>
                    <Box>
                      <FormControlLabel
                        control={
                          <Checkbox
                            data-testid={convertToLowerKabobCase(item.fieldName, '-form-checkbox')}
                            color="primary"
                            checked={requiredField[item.fieldName]}
                            onChange={e => handleOnChange(e)}
                            value={item.fieldName}
                          />
                        }
                        label={completeLabel(item.preLabel, item.label)}
                      />
                    </Box>
                  </Box>
                </>
              );
            }}
          />
        </MuiBox>
        <MuiBox className={classes.steppersFooter}>
          <CovidTestButton
            fullWidth
            variant="contained"
            data-testid="consent-next-button"
            disabled={isValid()}
            onClick={onNextClick}
          >
            Next
          </CovidTestButton>
          <CovidTestButton
            fullWidth
            variant="text"
            data-testid="consent-previous-button"
            onClick={onCancelClick}
          >
            Previous
          </CovidTestButton>
        </MuiBox>
      </MuiContainer>
      <Footer
        headerText={patientSelect.GET_PATIENTS.HELPLINE.CovidInfo}
        link={patientSelect.GET_PATIENTS.HELPLINE.CovidPatientSelectHelpline}
        linkName={patientSelect.GET_PATIENTS.HELPLINE.CovidPatientSelectHelpline}
        helplineHours={patientSelect.GET_PATIENTS.HELPLINE.CovidHelplineHours}
        title={CovidTitles.CONSENT_AND_AGREEMENTS}
        authenticated
      />
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  questionnaire: CovidScreeningQuestionnaireSelector(state)
});

const mapDispatchToProps = {
  setQuestionnaire: covidActions.setQuestionnaire
};

export default connect(mapStateToProps, mapDispatchToProps)(CovidAgreement);
