import React, { useState } from 'react';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import {
  MuiDialog,
  MuiDialogTitle,
  MuiDialogContent,
  MuiDialogActions,
  MuiGrid,
  MuiButton
} from 'theme/material-ui';
import { ConnectCareTextField } from 'components/ConnectCare';
import * as actions from 'store/amwell/actions';

export interface Props {
  isOpen: boolean;
  addServiceKey: typeof actions.addServiceKey;
  onClose: () => void;
  onSubmit: () => void;
}

export function ConnectCareAddServiceKeyDialog({
  isOpen,
  addServiceKey,
  onSubmit,
  onClose
}: Props) {
  const [key, setKey] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    const res: AnyAction = await addServiceKey({ key });
    setLoading(false);

    if (res.error) {
      setError(res.error.errorCode);
    } else {
      setKey('');
      onSubmit();
      onClose();
    }
  };

  return (
    <MuiDialog
      open={isOpen}
      disableBackdropClick={loading}
      onClose={onClose}
      maxWidth="md"
      aria-labelledby="add-service-key-form"
    >
      <MuiDialogTitle id="add-sevice-key-form-title">Enter Your Service Key</MuiDialogTitle>
      <MuiDialogContent data-testid="add-sevice-key-form">
        <MuiGrid container spacing={2} wrap="nowrap">
          <MuiGrid item>
            <ConnectCareTextField
              inputProps={{ 'data-testid': 'key' }}
              label="Service Key"
              name="serviceKey"
              value={key}
              onChange={e => {
                setError('');
                setKey(e.target.value);
              }}
              placeholder=""
              required
              helperText={error}
              error={!!error}
            />
          </MuiGrid>
        </MuiGrid>
      </MuiDialogContent>
      <MuiDialogActions>
        <MuiButton data-testid="cancel" disabled={loading} variant="outlined" onClick={onClose}>
          Cancel
        </MuiButton>
        <MuiButton
          variant="contained"
          color="primary"
          disabled={!key || !!error}
          loading={loading}
          onClick={handleSubmit}
          data-testid="submit"
        >
          Add key
        </MuiButton>
      </MuiDialogActions>
    </MuiDialog>
  );
}

const mapDispatch = {
  addServiceKey: actions.addServiceKey
};

export default connect(null, mapDispatch)(ConnectCareAddServiceKeyDialog);
