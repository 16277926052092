import React from 'react';
import { Icon } from 'components/Icon';
import { Color } from 'modules/styles/colors';
import { JsonObj } from '@mhp/general-interface';
import SmallCardText from 'components/UI/Typography/SmallCardText';
import { MuiBox, MuiCard, MuiGrid } from 'theme/material-ui';
import WaitTimeBadge from '../WaitTime/WaitTimeBadge';
import {
  Clickable,
  CardRowMedia,
  TextContainer,
  PaddedContainer,
  LargeCardText,
  AddressContainer,
  BadgeRowContainer
} from './styled';

interface Props {
  onPress: Function;
  facility: JsonObj;
}

export const FacilityListItem = ({ onPress, facility }: Props) => (
  <Clickable>
    <MuiBox mb={2}>
      <MuiCard onClick={() => onPress(facility)}>
        <MuiGrid container direction="row">
          <MuiGrid item xs={3}>
            <CardRowMedia imageUrl={facility.locationImageUrl} />
          </MuiGrid>
          <MuiGrid item xs>
            <PaddedContainer>
              <TextContainer>
                <LargeCardText>{facility.locationName}</LargeCardText>
                <AddressContainer>
                  <Icon name="location-on" color={Color.primary} size={15} />
                  <MuiGrid item>
                    <SmallCardText>{facility.address1}</SmallCardText>
                  </MuiGrid>
                </AddressContainer>
                <AddressContainer>
                  <SmallCardText>
                    {`Distance: ${parseFloat(facility.distanceToUser).toFixed(2)} miles`}
                  </SmallCardText>
                </AddressContainer>
              </TextContainer>
            </PaddedContainer>
          </MuiGrid>
          {facility.isCurrentlyOpen && (
            <MuiGrid item xs={3}>
              <BadgeRowContainer>
                <WaitTimeBadge
                  isOpen={facility.isCurrentlyOpen}
                  time={facility.currentWaitTime}
                  text="minute"
                />
              </BadgeRowContainer>
            </MuiGrid>
          )}
        </MuiGrid>
      </MuiCard>
    </MuiBox>
  </Clickable>
);
