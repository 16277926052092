import { useSelector } from 'react-redux';
import {
  currentLocationPathNameSelector,
  previousLocationPathNameSelector
} from 'store/router/selectors';

/**
 * This hooks gives back the necessary core data to send in an event.
 */

const useCoreEventData = () => {
  const currentUrl = useSelector(currentLocationPathNameSelector);
  const referringUrl = useSelector(previousLocationPathNameSelector);

  return {
    currentUrl,
    referringUrl
  };
};

export default useCoreEventData;
