import { Color } from 'modules/styles/colors';
import styled from 'styled-components';
import { MuiSelect, MuiTextField, MuiTypography } from 'theme/material-ui';
import { KeyboardDatePicker } from '@material-ui/pickers';
import MaskedInput, { MaskedInputProps } from 'react-text-mask';

export const standardHeight = `
height: 42px;
min-height: 42px;`;

export const NumberCircle = styled.div`
  border-radius: 50%;
  width: 36px;
  height: 36px;
  padding: 8px;
  margin-left: 26px;
  background: ${Color.blueHue11};
  border: 2px solid ${Color.blueHue11};
  color: #fff;
  text-align: center;

  font: 16px 'ABC Social Variable', sans-serif;
`;

export const CheckMark = styled.div`
  display: inline-block;
  transform: rotate(45deg);
  height: 20px;
  width: 9px;
  margin-left: 12%;
  border-bottom: 2px solid ${Color.darkGreenShade};
  border-right: 2px solid ${Color.darkGreenShade};
`;

export const CircleMark = styled.div`
  border-radius: 50%;
  width: 36px;
  height: 36px;
  padding: 5px;
  margin-left: 26px;
  background: transparent;
  border: 2px solid ${Color.darkGreenShade};
  color: #fff;
  text-align: center;
  font: 16px 'ABC Social Variable', sans-serif;
`;

export const StyledTextField = styled(MuiTextField)`
  background-color: ${Color.foreColor};
`;

export const StyledSelectField = styled(MuiSelect)`
  background-color: ${Color.foreColor};
`;

export const StyledDatePickerField = styled(KeyboardDatePicker)`
  background-color: ${Color.foreColor};
`;

export const MaskedPreRegInput = styled(MaskedInput)`
  padding-left: 1.75rem;
  background-color: ${Color.foreColor};
  border-width: thin;
  border-style: solid;
  ${standardHeight}
  width: 100%;
  ${(props: MaskedInputProps & { error: boolean }) =>
    props.error
      ? `
  border-color: ${Color.red};
  `
      : ''}
`;

export const InsuraceText = styled(MuiTypography)`
  color: ${Color.primary};
  font-family: 'ABC Social Variable';
`;
