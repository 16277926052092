import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { AuthType } from 'store/authentication/types';
import { CovidTitles } from 'lib/constants/covidScreening';
import { AnalyticsEvent } from 'services/AnalyticsService';
import { MuiContainer, MuiRadio, MuiTypography } from 'theme/material-ui';
import { Spacing, FontWeight } from 'modules/styles/variables';

import Spacer from 'components/UI/Layout/Spacer';
import FlexBox from 'components/UI/Layout/FlexBox';
import useNavigationAnalytics from 'hooks/useNavigationAnalytics';

import { useCovidQuestionnaire } from '../hooks';
import { ButtonsNavigation, CovidTestGuestFooter } from '../components';
import CovidGuestGuard from '../navigation/CovidGuestLeavingGuard';
import { StyledScreen } from '../../../components/common/PatientLoader/styled';
import { useLanguageSwitcher } from 'lib/hooks/useLanguageSwitcher';
import { translations } from 'lib/constants/translations/screens/covidGuest/covidInsurance';

interface PatientInsurance {
  hasInsurance?: string;
  haveBeenPatient?: string;
  haveChangedInsurance?: string;
}

const getPatientInsuranceData = (selectedDataObj: PatientInsurance) => {
  if (selectedDataObj?.hasInsurance === 'No')
    return { haveBeenPatient: '', haveChangedInsurance: '' };

  if (selectedDataObj?.haveBeenPatient === 'No' || selectedDataObj?.haveBeenPatient === 'Unknown')
    return { haveChangedInsurance: '' };

  return {};
};

export default function CovidInsurance() {
  const history = useHistory();
  const screenText = useLanguageSwitcher(translations);
  const { questionnaire, updateQuestionnaire } = useCovidQuestionnaire();
  const [insuranceData, setInsuranceData] = useState({
    hasInsurance: '',
    haveBeenPatient: '',
    haveChangedInsurance: ''
  });

  const covidScreeningNextBtn = useNavigationAnalytics(AnalyticsEvent.CovidNext, {
    title: CovidTitles.HAVE_HEALTH_INSURANCE,

    has_insurance: insuranceData.hasInsurance === 'Yes' ? 'Yes' : 'No',
    intermountain_patient: insuranceData.haveBeenPatient ? insuranceData.haveBeenPatient : null,
    change_insurance: insuranceData.haveChangedInsurance
      ? insuranceData.haveChangedInsurance
      : null,
    authentication_type: AuthType.UNAUTHENTICATED
  });

  const covidScreeningPreviousBtn = useNavigationAnalytics(AnalyticsEvent.CovidPrev, {
    title: CovidTitles.HAVE_HEALTH_INSURANCE,
    authentication_type: AuthType.UNAUTHENTICATED
  });

  const onCancelClick = () => {
    covidScreeningPreviousBtn();
    history.goBack();
  };

  useEffect(() => {
    setInsuranceData(prevState => ({
      ...prevState,
      hasInsurance: questionnaire?.has_insurance,
      haveBeenPatient: questionnaire?.have_been_patient,
      haveChangedInsurance: questionnaire?.have_changed_insurance
    }));
  }, []);

  const handleSubmit = () => {
    covidScreeningNextBtn();

    const questionnaireObject = {
      ...questionnaire,
      // patientInsuranceExist: insuranceExits,
      has_insurance: insuranceData.hasInsurance,
      have_been_patient: insuranceData.haveBeenPatient,
      have_changed_insurance: insuranceData.haveChangedInsurance
    };

    updateQuestionnaire(questionnaireObject);

    if (insuranceData.hasInsurance === 'No') {
      // they should proceed to the patient information summary screen.
      history.push('/guest-home/covid-screen/guest-summary-info');
      return;
    }

    if (['No', 'Unknown'].includes(insuranceData.haveBeenPatient)) {
      // They are directed to the Health insurance dropdown screen to search for their existing insurance
      history.push('/guest-home/covid-screen/guest-insurance-search');
      return;
    }

    if (['Yes', 'Unknown'].includes(insuranceData.haveChangedInsurance)) {
      // they are taken to Health insurance dropdown screen to search for their existing insurance
      history.push('/guest-home/covid-screen/guest-insurance-search');
      return;
    }

    if (insuranceData.haveChangedInsurance === 'No') {
      // they are then taken to the information summary screen
      history.push('/guest-home/covid-screen/guest-summary-info');
    }
  };

  const handleSelection = (selectedDataObj: PatientInsurance) => {
    const patientInsuranceData = getPatientInsuranceData(selectedDataObj);

    setInsuranceData(prevState => ({
      ...prevState,
      ...selectedDataObj,
      ...patientInsuranceData
    }));
  };

  const hasInsurance = insuranceData.hasInsurance === 'Yes';

  const haveBeenPatient =
    insuranceData.haveBeenPatient === 'Yes' && insuranceData.hasInsurance === 'Yes';

  const isButtonDisabled = () => {
    if (
      insuranceData?.hasInsurance === 'No' ||
      (insuranceData?.hasInsurance === 'Yes' &&
        (insuranceData.haveBeenPatient === 'No' || insuranceData.haveBeenPatient === 'Unknown'))
    )
      return false;

    if (insuranceData?.haveBeenPatient === 'Yes') return !insuranceData.haveChangedInsurance;

    return true;
  };

  const renderInsuranceExit = () => (
    <>
      <CovidGuestGuard />
      <FlexBox
        testID="covid-insurance-exit"
        width="100%"
        hSpacing={Spacing.medium}
        vSpacing={Spacing.medium}
      >
        <MuiTypography fontWeight={FontWeight.bold}>{screenText.ques1}</MuiTypography>
        <Spacer size="small" />
        <MuiTypography>{screenText.desc1}</MuiTypography>
        <Spacer size="small" />
        <MuiTypography>{screenText.desc2}</MuiTypography>

        <Spacer size="small" />

        <FlexBox flexDirection="row" alignItems="center">
          <MuiRadio
            onChange={() => handleSelection({ hasInsurance: 'Yes' })}
            checked={insuranceData.hasInsurance === 'Yes'}
            data-testid="insurance-yes"
            value="insurance-yes"
            color="primary"
          />
          <MuiTypography>{screenText.yes}</MuiTypography>
        </FlexBox>
        <FlexBox flexDirection="row" alignItems="center">
          <MuiRadio
            onChange={() => handleSelection({ hasInsurance: 'No' })}
            checked={insuranceData.hasInsurance === 'No'}
            data-testid="insurance-no"
            value="insurance-no"
            color="primary"
          />
          <MuiTypography>{screenText.no}</MuiTypography>
        </FlexBox>
      </FlexBox>

      {hasInsurance ? (
        <FlexBox width="100%" hSpacing={Spacing.medium} vSpacing={Spacing.medium}>
          <Spacer size="small" />
          <MuiTypography>{screenText.ques2}</MuiTypography>
          <Spacer size="small" />
          <FlexBox flexDirection="row" alignItems="center">
            <MuiRadio
              checked={insuranceData.haveBeenPatient === 'Yes'}
              color="primary"
              data-testid="haveBeenPatient-yes"
              onChange={() => handleSelection({ haveBeenPatient: 'Yes' })}
              value="haveBeenPatient-yes"
            />
            <MuiTypography>{screenText.yes}</MuiTypography>
          </FlexBox>
          <FlexBox flexDirection="row" alignItems="center">
            <MuiRadio
              checked={insuranceData.haveBeenPatient === 'No'}
              color="primary"
              data-testid="haveBeenPatient-no"
              onChange={() => handleSelection({ haveBeenPatient: 'No' })}
              value="haveBeenPatient-no"
            />
            <MuiTypography>{screenText.no}</MuiTypography>
          </FlexBox>
          <FlexBox flexDirection="row" alignItems="center">
            <MuiRadio
              onChange={() => handleSelection({ haveBeenPatient: 'Unknown' })}
              checked={insuranceData.haveBeenPatient === 'Unknown'}
              data-testid="haveBeenPatien-unkown"
              value={insuranceData.haveBeenPatient}
              color="primary"
            />
            <MuiTypography>{screenText.unknown}</MuiTypography>
          </FlexBox>
        </FlexBox>
      ) : null}

      {haveBeenPatient ? (
        <FlexBox width="100%" hSpacing={Spacing.medium} vSpacing={Spacing.medium}>
          <Spacer size="small" />
          <MuiTypography>{screenText.ques3}</MuiTypography>
          <Spacer size="small" />
          <FlexBox flexDirection="row" alignItems="center">
            <MuiRadio
              checked={insuranceData.haveChangedInsurance === 'Yes'}
              color="primary"
              data-testid="changedInsurance-yes"
              onChange={() => handleSelection({ haveChangedInsurance: 'Yes' })}
              value="changedInsurance-yes"
            />
            <MuiTypography>{screenText.yes}</MuiTypography>
          </FlexBox>
          <FlexBox flexDirection="row" alignItems="center">
            <MuiRadio
              checked={insuranceData.haveChangedInsurance === 'No'}
              color="primary"
              data-testid="changedInsurance-no"
              onChange={() => handleSelection({ haveChangedInsurance: 'No' })}
              value="changedInsurance-no"
            />
            <MuiTypography>{screenText.no}</MuiTypography>
          </FlexBox>
          <FlexBox flexDirection="row" alignItems="center">
            <MuiRadio
              checked={insuranceData.haveChangedInsurance === 'Unknown'}
              color="primary"
              data-testid="changedInsurance-unkown"
              onChange={() => handleSelection({ haveChangedInsurance: 'Unknown' })}
              value="changedInsurance-unkown"
            />
            <MuiTypography>{screenText.unknown}</MuiTypography>
          </FlexBox>
        </FlexBox>
      ) : null}
    </>
  );

  return (
    <StyledScreen>
      <MuiContainer maxWidth="lg">
        <Spacer />
        {renderInsuranceExit()}

        <Spacer size="small" />

        <ButtonsNavigation
          cancelTestId="insurance-previous-button"
          nextTestId="insurance-next-button"
          disabledNextButton={isButtonDisabled()}
          onCancelClick={onCancelClick}
          onNextClick={handleSubmit}
        />

        <CovidTestGuestFooter title={CovidTitles.HAVE_HEALTH_INSURANCE} />
      </MuiContainer>
    </StyledScreen>
  );
}
