import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { MuiContainer, MuiBox, MuiTypography, MuiButton, MuiGrid } from 'theme/material-ui';

import { EVISITS_ROUTES } from '../constants';
import { FontSize, FontWeight, Spacing } from 'modules/styles/variables';
import { eVisitFrontDoorInsuranceScreen } from 'lib/triage/constants';
import { Color } from 'modules/styles/colors';
import { startEVisitFrontDoorSession, getUserInsuranceInfo } from 'store/eVisit/actions';
import { eVisitInsuranceSelector, eVisitSessionFetchingSelector } from 'store/eVisit/selectors';
import { profileConsumerSelector } from 'store/profile/selectors';
import { buildPatientDTO } from 'lib/triage/utils';
import { eVisitInsuranceErrorMessage } from 'lib/evisit/constants';
import { useLocalStorage } from 'hooks/useLocalStorage';
import analyticsService, { AnalyticsEvent } from 'services/AnalyticsService';

import Spacer from 'components/UI/Layout/Spacer';
import EVisitBanner from './components/EVisitBanner';
import { InfoCard } from 'components/UI/Card/Card';
import Screen from 'components/UI/Layout/Screen';
import InsuranceCard from './components/InsuranceCard';
import Svg from 'components/UI/Svg/Svg';
import InfoLabel from 'components/InfoLabel';
import { Divider } from 'components/UI/Divider/styled';

const EVisitInsurance = () => {
  const history = useHistory();
  const location: any = useLocation();
  const dispatch = useDispatch();
  const [eVisitInfo, setEVisitInfo] = useLocalStorage('EvisitCache', '');

  const { isFetching, userInfo, error } = useSelector(eVisitInsuranceSelector);
  const isFetchingSession = useSelector(eVisitSessionFetchingSelector);
  const patientInfo = useSelector(profileConsumerSelector);
  const { screenTitle, sectionTitle, disclosures } = eVisitFrontDoorInsuranceScreen;
  const { localStateSelected, conditionCode } = location?.state ?? {};

  const getInsuranceInfo = () => dispatch(getUserInsuranceInfo());

  useEffect(() => {
    getInsuranceInfo();
  }, []);

  const onSelfPay = () => {
    const options = { insuranceUpdated: false, selfPay: true };
    const patientDTO = buildPatientDTO(patientInfo, conditionCode, options) ?? {};
    setEVisitInfo(JSON.stringify({ ...patientDTO, localStateSelected }));
    dispatch(startEVisitFrontDoorSession(patientDTO, localStateSelected));
    analyticsService.logEvent(AnalyticsEvent.EVisitsFrontDoorVerifyInsurance, {
      action: 'Self Pay'
    });
  };

  const onConfirmData = () => {
    const options = { insuranceUpdated: false, selfPay: false };
    const patientDTO = buildPatientDTO(patientInfo, conditionCode, options) ?? {};
    setEVisitInfo(JSON.stringify({ ...patientDTO, localStateSelected }));
    dispatch(startEVisitFrontDoorSession(patientDTO, localStateSelected));
    analyticsService.logEvent(AnalyticsEvent.EVisitsFrontDoorVerifyInsurance, {
      action: 'Insurance confirmed'
    });
  };

  const onAddInsurance = () => {
    analyticsService.logEvent(AnalyticsEvent.EVisitsFrontDoorVerifyInsurance, {
      action: 'Insurance update'
    });
    history.push({
      pathname: EVISITS_ROUTES.FRONTDOOR_INSURANCE_UPLOAD,
      state: { conditionCode, localStateSelected }
    });
  };

  return (
    <Screen>
      <EVisitBanner title={screenTitle} />
      <MuiContainer data-testid="e-visit-insurance">
        <MuiBox paddingTop={6} padding={4}>
          <MuiTypography
            component="h2"
            fontSize={FontSize.mediumHeading}
            fontWeight={FontWeight.bold}
          >
            {sectionTitle.title}
          </MuiTypography>
          <MuiTypography component="p" fontSize={FontSize.base}>
            {sectionTitle.description}
          </MuiTypography>
        </MuiBox>
        <MuiGrid container justify="center" style={{ padding: '40px 0' }}>
          <MuiGrid item xs="auto" style={{ maxWidth: '100%' }}>
            <MuiTypography>{sectionTitle.cardsTitle}</MuiTypography>
            <InsuranceCard isLoading={isFetching} insuranceInfo={userInfo?.policy} />
          </MuiGrid>
          {error ? (
            <>
              <MuiGrid item xs={10}>
                <InfoLabel isError text={eVisitInsuranceErrorMessage} />
                <Spacer spacing="xLarge" />
              </MuiGrid>
              <MuiButton
                variant="contained"
                color="primary"
                size="large"
                fullWidth
                onClick={getInsuranceInfo}
                data-testid="try-again-button"
              >
                Try again
              </MuiButton>
            </>
          ) : null}
        </MuiGrid>
        <MuiGrid container spacing={Spacing.small} justify="center">
          <MuiGrid item container xs={12} md={5} justify="center">
            <MuiButton
              variant="outlined"
              color="primary"
              size="large"
              fullWidth
              onClick={onSelfPay}
              data-testid="self-pay-button"
              loading={isFetchingSession}
            >
              Self-Pay
            </MuiButton>
          </MuiGrid>
          <MuiGrid item container xs={12} md={5} justify="center">
            <MuiButton
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              onClick={onAddInsurance}
              data-testid="add-insurance-button"
              startIcon={
                <Svg set="assets" name="Add" size={FontSize.small} color={Color.foreColor} />
              }
            >
              Add new insurance
            </MuiButton>
          </MuiGrid>
          <MuiGrid xs={10}>
            <InfoCard hasIcon>
              <MuiTypography>
                <strong>{disclosures.check}</strong>
                {disclosures.cost}
              </MuiTypography>
              <Divider spaced color={Color.primary} />
              <MuiTypography>
                {disclosures.explain}
                <MuiTypography fontWeight={FontWeight.bold}>{disclosures.selfPay}</MuiTypography>
              </MuiTypography>
            </InfoCard>
          </MuiGrid>
        </MuiGrid>
        <MuiGrid>
          <Spacer spacing="xxLarge" />
        </MuiGrid>
        {userInfo?.policy && (
          <>
            <MuiGrid container spacing={Spacing.small} justify="center">
              <MuiGrid item container xs={12} md={5} justify="center">
                <MuiButton
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth
                  onClick={onConfirmData}
                  loading={isFetchingSession}
                  data-testid="confirm-insurance-button"
                >
                  Confirm
                </MuiButton>
              </MuiGrid>
            </MuiGrid>
            <MuiGrid>
              <Spacer spacing="xLarge" />
            </MuiGrid>
          </>
        )}
      </MuiContainer>
    </Screen>
  );
};

export default EVisitInsurance;
