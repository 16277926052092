export const connectCareTermsAndConditionsHtml = `
<div style="font-family: ABC Social Variable, Open Sans, arial, helvetica, sans-serif; font-size: 24px; padding: 15px 20px;">
	<div id="disclaimerMain" class="popUpMain">
		<div id="disclaimerTitle" style="width: 100%;text-align:center; font-size: 27px;"><h2>Terms of Use</h2></div>
		<div id="disclaimerText"><p class="MsoNormal" style="background: white;line-height: normal;margin-bottom: 12.0pt;">
	<b><u><span>Please scroll to the section applicable to your visit to Connect Care for either 1. Connect Care WorkMed patients, 2) Intermountain Employee Assistance Program (EAP) clients or 3) Connect Care patients (All other patients)) and read the information contained therein.<span>&nbsp; </span>By checking "Accept" you acknowledge that you have read the information and agree with the terms applicable to your visit.<span>&nbsp; </span>Intermountain Health's Patient Privacy Notice, the Legal Notices, and the DMCA Notice apply to all uses of Connect Care.<span style="background: yellow;">   </span></span></u></b>
</p>
<p class="MsoNormal" style="background: white;line-height: normal;margin-bottom: 12.0pt;">
	<b><u><span style="background: yellow;">For WorkMed Patients:   </span></u></b>
</p>
<p class="MsoNormal" style="background: white;line-height: normal;margin-bottom: 12.0pt;">
	<b><span>The documents below, including the Consent and Conditions of Service for Connect Care patients and the Statement of Understanding for Connect Care EAP Clients, <i>do not</i> apply to WorkMed services provided to you through Connect Care.<span>&nbsp; </span><span>&nbsp;&nbsp;&nbsp;&nbsp;</span>The documents provided to you and signed by you during your in-person visit at a WorkMed clinic govern your relationship with WorkMed.<span>&nbsp; </span>By checking the "Accept" box in this document you acknowledge that you have read this information and accept the Legal Notices and DMCA Notice.   </span></b>
</p>
<p class="MsoNormal" style="background: white;line-height: normal;margin-bottom: 12.0pt;">
	<b><u><span style="background: yellow;">For Connect Care Patients:</span></u></b><b><u><span>   </span></u></b>
</p>
<p class="MsoNormal" style="background: white;line-height: normal;margin-bottom: 12.0pt;text-align: center;" align="center">
	<u><span>CONSENT AND CONDITIONS OF SERVICE</span></u><span>   </span>
</p>
<p class="MsoNormal" style="background: white;line-height: normal;margin-bottom: 3.0pt;">
	<b><span>&nbsp;</span></b><span>   </span>
</p>
<p class="MsoNormal" style="background: white;line-height: normal;margin-bottom: 3.0pt;">
	<b><span>By checking the "Accept" box, or by creating a Connect Care account or using the Connect Care Service to request or receive health care services for myself or for my minor child of whom I am the parent or legal guardian (in either case, the "Patient"), I consent and agree to the terms and conditions of this agreement. I intend that the following terms apply to all health care services received by the Patient from physicians employed or contracted by IHC Health Services, Inc. ("Intermountain Health").&nbsp; I make the following consents, understandings, and agreements on my own behalf and on behalf of the Patient in partial consideration of health care services to be provided to the Patient through the Connect Care Service:</span></b><span>   </span>
</p>
<p class="MsoNormal" style="background: white;line-height: normal;margin-bottom: 3.0pt;">
	<b><span>Consent for Services.</span></b><span>&nbsp;I hereby consent to health care services provided through the Connect Care Service by Intermountain Health, its physicians, employees, and independent contractors for the benefit of the Patient for this visit and any subsequent visits. I understand this consent may be revoked in writing at any time.&nbsp; I understand that there is a risk of substantial and serious harm involved in such health care services, and I accept such risk in the hope of obtaining beneficial results from such services. No promises of any particular outcome or successful result have been made. I accept that there is some uncertainty involved in the health care services for which this consent is given. I understand that physicians are separately responsible to explain what they do and, in some cases, to obtain separate consent for some of the procedures they perform.   </span>
</p>
<p class="MsoNormal" style="background: white;line-height: normal;margin-bottom: 3.0pt;">
	<b><span>Setting.</span></b><span>&nbsp;I understand that I may access the Connect Care Service through a mobile application (which is part of the Connect Care Service) or through an approved web browser. Whether I access the Connect Care Service from a phone, device, computer, kiosk, or other equipment, owned or leased by me, Intermountain Health, or another third party, I am responsible to make sure I am comfortable with the privacy and security of my setting. In choosing my setting, I determine my expectation of privacy and security. If I am not comfortable sharing certain information in my setting, I will not share that information. If I choose to share information in a setting that is not private or secure, I do so at my own risk. By proceeding to use the Connect Care Service, I agree that neither Intermountain Health nor any other third party is liable for information that I choose to disclose or not to disclose based on my setting.&nbsp;&nbsp;&nbsp;   </span>
</p>
<p class="H1" style="line-height: normal;margin: 0.0in;">
	<span>TeleHealth. </span><span>To the extent possible, I will be allowed to select my providers treating me through Connect Care.<span>&nbsp; </span>Intermountain safeguards medical information about me used in TeleHealth Services, and only uses and shares this information as described in Intermountain's Notice of Privacy Practices. The technology used to deliver Telehealth Services meets industry security and privacy standards.<span>&nbsp; </span>This technology could fail resulting in lost information.</span><span>   </span>
</p>
<p class="MsoNormal" style="background: white;line-height: normal;margin-bottom: 3.0pt;">
	<b><span>Medical Education.</span></b><span>&nbsp;I agree that individuals involved in medical-related education and training may help provide health care services, may observe my care, and may participate in my care, all under the supervision of physicians or other trained individuals.   </span>
</p>
<p class="MsoNormal" style="background: white;line-height: normal;margin-bottom: 3.0pt;">
	<b><span>Independent Contractors.</span></b><span>&nbsp;I understand that some of the physicians and other health care providers furnishing health care services to the Patient, including residents, interns and other persons in training, may be independent contractors and not employees of Intermountain Health. Some may be employees of the State of Utah, University of Utah, University of Utah School of Medicine or other training programs. The Utah Governmental Immunity Act (Utah Code 63G-7-101, et seq.) controls claims of liability or malpractice against University or State employees.   </span>
</p>
<p class="MsoNormal" style="background: white;line-height: normal;margin-bottom: 3.0pt;">
	<b><span>Release of Information.</span></b><span>&nbsp;Intermountain Health is required by law to make and keep records of the Patient's medical treatment. Intermountain Health safeguards those records and it uses and discloses such records and the information they contain only in accordance with State and Federal privacy laws. Such uses and disclosures are described in detail in Intermountain Health's Notice of Privacy Practices, which may be amended from time to time. I understand that either the Patient or I may ask for a copy of the current notice at any time.   </span>
</p>
<p class="MsoNormal" style="background: white;line-height: normal;margin-bottom: 3.0pt;">
	<b><span>Assignment of Benefits.&nbsp;</span></b><span>Any and all benefits from insurance companies and other third party payers that are payable to the Patient or on behalf of the Patient for health care services and related payments for services rendered or provided to the Patient are hereby transferred and assigned to Intermountain Health for the exclusive purpose of paying for charges associated with the health care services provided to the Patient through the Connect Care Service. I understand and intend that all insurance companies and other third party payers will pay benefits directly to Intermountain Health in payment of Intermountain Health's charges and the charges of any other health care providers for whom Intermountain Health is authorized to bill in connection with health care services provided to the Patient. By clicking "Accept" below, or by using the Connect Care Service to request or receive health care services for the Patient, I hereby&nbsp;assign and transfer to Intermountain Health, and to any other health care provider for whom Intermountain Health bills, the benefits of any insurance policy or other arrangement that may provide payment for some or all of my care.&nbsp; I appoint Intermountain Health or its designee as my attorney-in-fact for the limited purposes of communicating, appealing, negotiating, or otherwise pursuing in its discretion any legal remedies with any insurance company, entity, or other payer to obtain payment for Intermountain Health for the services that were provided to me.&nbsp; I authorize Intermountain Health to receive and deposit money received against the charges of Intermountain Health and any other health care provider for whom Intermountain Health.   </span>
</p>
<p class="MsoNormal" style="background: white;line-height: normal;margin-bottom: 3.0pt;">
	<b><span>Financial Responsibility.</span></b><span>&nbsp;If I am a person accepting this Agreement for the Patient and am otherwise legally responsible to pay for the care of the Patient (for example, as the spouse or parent of the Patient) (the "Responsible Party"), then each of us agrees to pay all of the following charges: any and all of the amounts Intermountain Health or independent contractor determines to be owed for health care services rendered to me or the Patient through the Connect Care Service, subject to agreements with payers; all applicable co-payments, deductibles, and co-insurance; all charges for non-covered services; interest on unpaid balances that are more than 30 days past due or are placed by Intermountain Health or an independent contractor for collection at the rate of 0.67% per month (8% per year); all costs and attorney fees (if an attorney is used) that Intermountain Health or an independent contractor incurs directly or indirectly if either refers my overdue bill for collection; and a service charge of $20.00 for any check or other instrument that is presented for payment but returns unpaid to Intermountain Health or independent contractor.   </span>
</p>
<p class="MsoNormal" style="background: white;line-height: normal;margin-bottom: 3.0pt;">
	<span>I understand that if I am a temporary caregiver for the Patient (such as a nanny, youth leader, foster parent or some law enforcement agencies), I may not be financially responsible for the Patient's care.&nbsp; I recognize that this statement about temporary caregivers is not an opinion by Intermountain Health whether I am or am not a temporary caregiver or whether I am responsible to pay for the Patient's care.   </span>
</p>
<p class="MsoNormal" style="background: white;line-height: normal;margin-bottom: 3.0pt;">
	<span>If I am the Responsible Party, I hereby consent to credit bureau inquiries for Intermountain Health's or the independent contractor's business needs, and to receiving auto-dialed and/or artificial or pre-recorded&nbsp;collection or health care-related message&nbsp;calls to my cellular phone number and any other telephone numbers provided during any interaction, agreement or communication with Intermountain Health, the independent contractor, the Intermountain Health system and/or their affiliates, agents and contractors, including any account management companies and/or debt collectors.   </span>
</p>
<p class="MsoNormal" style="background: white;line-height: normal;margin-bottom: 3.0pt;">
	<b><span>Medicare/Medicaid/Tricare Patient's Certification</span></b><span>. I certify as correct the information I submit to apply for Medicare, Medicaid, Champus, Tricare, or any other government program in order to obtain payment. I authorize any holder of medical or other information about me to release that to the Tricare administrator, Social Security Administration or its intermediaries, to other carriers or program administrators, the State or any other payer, any information needed to substantiate and process a claim for payment of health care or related services. By clicking "Accept" below, or by using the Connect Care Service to request or receive health care services for the Patient, I request these payers to make authorized payments for all of these services directly to Intermountain Health on my behalf.   </span>
</p>
<p class="MsoNormal" style="background: white;line-height: normal;margin-bottom: 3.0pt;">
	<b><span>Use of Health Information.</span></b><span>&nbsp;The Patient's health information, including the Patient's health history, medication, and prescription information, may be available electronically or physically from current or past healthcare providers. I agree that the Patient's health information may be accessed by the Patient's healthcare providers and Intermountain Health for treatment, payment or healthcare operations.   </span>
</p>
<p class="MsoNormal" style="background: white;line-height: normal;margin-bottom: 6.0pt;">
	<b><span>Financial Assistance.</span></b><span>&nbsp;I have been informed that I may be eligible to apply for assistance pursuant to the Intermountain Health's Financial Assistance Policy.   </span>
</p>
<p class="MsoNormal" style="background: white;line-height: normal;margin-bottom: 12.0pt;">
	<b><span>I hereby accept and agree to this document either as the Patient or as the parent or guardian of the Patient authorized to accept and agree to this document and its terms on behalf of the Patient. I have read the foregoing and have had the opportunity to ask any questions I may have about the foregoing. Such questions have been answered to my satisfaction, and I indicate my understanding by clicking the "Accept" box. I understand that I am entitled to print a copy of this document. This document will remain in effect unless revoked in writing.   </span></b>
</p>
<p class="MsoNormal" style="line-height: normal;margin-bottom: 0.0in;">
	<b><i><span>  &nbsp;  </span></i></b>
</p>
<p class="MsoNormal" style="background: white;line-height: normal;margin-bottom: 12.0pt;">
	<b><u><span style="background: yellow;">For Connect Care EAP Clients</span></u></b><b><u><span>:</span></u></b><span>   </span>
</p>
<p class="MsoNormal" style="background: white;line-height: normal;margin-bottom: 12.0pt;text-align: center;" align="center">
	<span>STATEMENT OF UNDERSTANDING   </span>
</p>
<p class="MsoNormal" style="background: white;line-height: normal;margin-bottom: 12.0pt;text-align: center;" align="center">
	<span>  &nbsp;  </span>
</p>
<p class="MsoNormal" style="line-height: normal;margin-bottom: 0.0in;">
	<b><i><span>WHAT DOES EAP DO? </span></i></b><span>EAP provides brief, solution focused counseling that is free and confidential. You and the EAP counselor will discuss your situation and assess the problem. If your problem is EAP appropriate, counseling will continue until the problem has improved.* If your problem is not EAP appropriate because it requires a specialist or long-term care, you will be referred to a provider through your medical insurance or community resources .Once you are referred, you are no longer using free EAP services, but will now be using your medical insurance.<span>&nbsp; </span>While the EAP counselor will do his/her best to refer you to providers who are covered under your insurance, it is your responsibility to understand your medical insurance coverage and fees.   </span>
</p>
<p class="MsoNormal" style="line-height: normal;margin-bottom: 0.0in;">
	<span>  &nbsp;  </span>
</p>
<p class="MsoNormal" style="line-height: normal;margin-bottom: 0.0in;">
	<b><i><span>IS EAP CONFIDENTIAL? </span></i></b><span>Information regarding you or your problem generally cannot be released to anyone without your written consent.<span>&nbsp; </span>If you request we contact someone on your behalf, you must complete a written informed consent release.<span>&nbsp; </span>State and federal laws, however, mandate that in cases of child abuse, elder abuse, or when a person may be a threat to his or someone else's safety, the counselor must notify the proper authorities.<span>&nbsp; </span>EAP must also release records if ordered by a court of law, required by lawful subpoena, or if requested by a licensing agency (i.e. DOPL).   </span>
</p>
<p class="MsoNormal" style="line-height: normal;margin-bottom: 0.0in;">
	<span>  &nbsp;  </span>
</p>
<p class="MsoNormal" style="line-height: normal;margin-bottom: 0.0in;">
	<span>If you are participating in couples counseling with your partner, one progress note will be written for each joint session and this note will be placed in both of your files.&nbsp; If you participate in an individual session, a progress note will be written and placed only in your individual file.</span><span>   </span>
</p>
<p class="MsoNormal" style="line-height: normal;margin-bottom: 0.0in;">
	<span>  &nbsp;  </span>
</p>
<p class="MsoNormal" style="line-height: normal;margin-bottom: 0.0in;">
	<b><i><span>WHAT ARE YOUR RESPONSIBILITIES? </span></i></b><span>The counseling process is most likely to produce results if you are willing to look at your own behavior, are honest and are willing to act on what is learned in counseling.<span>&nbsp; </span>You are responsible for setting and keeping appointments.<span>&nbsp; </span>Please provide as much notice as possible if an appointment is going to be missed (at least 24 hours).<span>&nbsp; </span>Generally, failure to notify is considered lack of involvement in the counseling process.   </span>
</p>
<p class="MsoNormal" style="line-height: normal;margin-bottom: 0.0in;">
	<span>If you are not satisfied with any part of the EAP process, please let us know.<span>&nbsp; </span>We want your experience to be positive and helpful.<span>&nbsp;&nbsp; </span>   </span>
</p>
<p class="MsoNormal" style="line-height: normal;margin-bottom: 0.0in;">
	<span>*California law limits EAP to 3 sessions.   </span>
</p>
<p class="MsoNormal" style="line-height: normal;margin-bottom: 0.0in;">
	<span>  &nbsp;  </span>
</p>
<p class="MsoNormal" style="background: white;line-height: normal;margin-bottom: 12.0pt;">
	<span>I hereby accept and agree as the client, <span>&nbsp;</span>to this document and its terms. </span><span>I give permission for my child (under 18) to receive services from Intermountain Employee Assistance Program and I have the legal authority to give this permission</span><span> I have read the foregoing and have had the opportunity to ask any questions I may have about the foregoing. Such questions have been answered to my satisfaction, and I indicate my understanding by clicking the "Accept" box. I understand that I am entitled to print a copy of this document. This document will remain in effect unless revoked in writing.   </span>
</p>
<p class="MsoNormal" style="line-height: normal;margin-bottom: 0.0in;">
	<span>  &nbsp;  </span>
</p>
<p class="MsoNormal" style="background: white;line-height: normal;text-align: center;" align="center">
	<u><span>INTERMOUNTAIN HEALTH'S PATIENT PRIVACY NOTICE</span></u><span>   </span>
</p>
<p class="MsoNormal" style="background: white;line-height: normal;text-align: center;" align="center">
	<u><span>&nbsp;</span></u><span>   </span>
</p>
<p class="MsoNormal" style="background: white;line-height: normal;">
	<span>This notice describes how medical information about you may be used and disclosed and how you can get access to this information. Please review it carefully.   </span>
</p>
<p class="MsoNormal" style="background: white;line-height: normal;">
	<span>Protecting your Privacy   </span>
</p>
<p class="MsoNormal" style="background: white;line-height: normal;">
	<span>Intermountain Health understands the importance and sensitivity of your health information. We protect the privacy of your health information because that is the right thing to do. We also follow federal and state laws that govern your health information. We use your health information (and allow others to have it) only as permitted by federal and state laws. These laws give you certain rights regarding your health information.   </span>
</p>
<p class="MsoNormal" style="background: white;line-height: normal;">
	<span>Intermountain participates in organized healthcare arrangements (OHCAs) with other entities including but not limited to, SelectHealth, Inc., the Intermountain Life and Health Benefit Plan, and the University of Utah Medical Group (with respect to certain defined pediatric specialty services). These OHCA members share information for treatment, payment and healthcare operations to improve, manage and coordinate your care.   </span>
</p>
<p class="MsoNormal" style="background: white;line-height: normal;">
	<span>Learn more about OHCA activities and see a current list of all OHCA members on Intermountain's public website (</span><span><a href="https://intermountainhealthcare.org/website-information/privacy-notices/patients/ohca/"><span style="text-decoration: none;">https:// intermountainhealthcare.org/website-information/privacy-notices/ patients/ohca/)</span></a></span><span>   </span>
</p>
<p class="MsoNormal" style="background: white;line-height: normal;">
	<span>Your Health Information Rights&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;   </span>
</p>
<p class="MsoNormal" style="background: white;line-height: normal;">
	<span>You may:   </span>
</p>
<ul>
	<li class="MsoNormal" style="background: white;line-height: normal;margin-bottom: 0.0in;">
		<span>Inspect and get a copy of your medical or billing records (including an electronic copy if we maintain the records electronically), as allowed by law, usually within 30 days of your written request.   </span>
	</li>
	<li class="MsoNormal" style="background: white;line-height: normal;margin-bottom: 0.0in;">
		<span>Request and receive a paper copy of our current notice of privacy practices.   </span>
	</li>
	<li class="MsoNormal" style="background: white;line-height: normal;margin-bottom: 0.0in;">
		<span>Require us to communicate with you using an alternate address or phone number.   </span>
	</li>
	<li class="MsoNormal" style="background: white;line-height: normal;margin-bottom: 0.0in;">
		<span>Require that we not send information about a healthcare service or related item to your health plan if you or someone else pays in full for that service or item and if you notify us in advance that you — and not your health plan — are going to pay for this service or item (so we don't automatically bill your health plan).   </span>
	</li>
	<li class="MsoNormal" style="background: white;line-height: normal;margin-bottom: 0.0in;">
		<span>Request in writing that restrictions be placed on how your health information is normally used or shared for treatment or other purposes.   </span>
	</li>
	<li class="MsoNormal" style="background: white;line-height: normal;margin-bottom: 0.0in;">
		<span>Request an accounting of when your identifiable health information is shared outside of Intermountain for a purpose other than treatment or payment.   </span>
	</li>
	<li class="MsoNormal" style="background: white;line-height: normal;margin-bottom: 0.0in;">
		<span>Receive notice if we or our business associate has breached the confidentiality of your health information.   </span>
	</li>
	<li class="MsoNormal" style="background: white;line-height: normal;margin-bottom: 0.0in;">
		<span>Report a privacy concern and be assured that we will investigate your concern thoroughly, support you appropriately, and not retaliate against you in any way (you may report any privacy concerns to the Compliance or Privacy Coordinator at one of our facilities, to our main &nbsp;Privacy Office at (800) 442-4845 or&nbsp;<u><a href="mailto:Privacy@imail.org"><span>Privacy@imail.org</span></a></u>, or to the Office for Civil Rights, U.S. Department of Health and Human Services, Denver Office.)   </span>
	</li>
	<li class="MsoNormal" style="background: white;line-height: normal;margin-bottom: 0.0in;">
		<span>Request in writing that your health information be amended if you think there is in error.   </span>
	</li>
</ul>
<p class="MsoNormal" style="background: white;line-height: normal;">
	<span>&nbsp;   </span>
</p>
<p class="MsoNormal" style="background: white;line-height: normal;">
	<span>How Your Health Information is Used   </span>
</p>
<p class="MsoNormal" style="background: white;line-height: normal;margin-bottom: 0.0in;">
	<span>1.&nbsp;Common Uses of Health Information. When we care for you, we will gather your health information. The law allows us to use or share this health information to:   </span>
</p>
<p class="MsoNormal" style="background: white;line-height: normal;margin-bottom: 0.0in;margin-left: 0.75in;margin-right: 0.0in;margin-top: 0.0in;">
	<span>&bull; Understand your health condition and to treat you when you are sick. For example, we may look at your x-rays or share x-rays we take of you with your treating doctor, who may be outside of Intermountain, or we may receive your prescription information from other health services companies to help you avoid harmful drug interactions.   </span>
</p>
<p class="MsoNormal" style="background: white;line-height: normal;margin-bottom: 0.0in;margin-left: 0.75in;margin-right: 0.0in;margin-top: 0.0in;">
	<span>&bull; Bill for your healthcare services and to receive payment for our services; for example, we share your health insurance information with other healthcare providers who treat you — like your anesthesia doctor or a specialty laboratory — so they can bill for those services.   </span>
</p>
<p class="MsoNormal" style="background: white;line-height: normal;margin-bottom: 0.0in;margin-left: 0.75in;margin-right: 0.0in;margin-top: 0.0in;">
	<span>&bull; Determine if a patient is eligible for Medicaid or the Children's Health Insurance Program by submitting personally identifiable information to these state databases.   </span>
</p>
<p class="MsoNormal" style="background: white;line-height: normal;margin-bottom: 0.0in;margin-left: 0.75in;margin-right: 0.0in;margin-top: 0.0in;">
	<span>&bull; Improve our care. For example, we may contact you to understand what you thought of our care and to learn how to enhance our services to you.   </span>
</p>
<p class="MsoNormal" style="background: white;line-height: normal;margin-bottom: 0.0in;margin-left: 0.75in;margin-right: 0.0in;margin-top: 0.0in;">
	<span>&bull; Contact you to invite your support of the Intermountain Foundation. The Intermountain Foundation raises money to expand critically important patient programs, research, capital projects, and services for individuals living in the Intermountain area. If the Intermountain Foundation contacts you, you will have the opportunity to help meet this goal, or to opt out of receiving further fundraising communications. Opting out will have no impact on your care or payment for your care. Also, Intermountain Foundation does not sell or otherwise release donor information without the donor's authorization.   </span>
</p>
<p class="MsoNormal" style="background: white;line-height: normal;margin-bottom: 0.0in;margin-left: 0.75in;margin-right: 0.0in;margin-top: 0.0in;">
	<span>&bull; Improve our services to you by allowing companies with whom we contract, called "business associates," to perform certain specialized work for us. The law requires these business associates to protect your health information and obey the same privacy laws that we do. );   </span>
</p>
<p class="MsoNormal" style="background: white;line-height: normal;margin-bottom: 0.0in;margin-left: 0.75in;margin-right: 0.0in;margin-top: 0.0in;">
	<span>&bull; Perform limited health data research, where the researcher keeps patient-identifiable information confidential. Intermountain reviews every research request to make sure privacy is appropriately protected before sharing health information.   </span>
</p>
<p class="MsoNormal" style="background: white;line-height: normal;margin-bottom: 0.0in;margin-left: 0.75in;margin-right: 0.0in;margin-top: 0.0in;">
	<span>&bull; Support law enforcement, but only as authorized by law.   </span>
</p>
<p class="MsoNormal" style="background: white;line-height: normal;margin-bottom: 0.0in;margin-left: 0.75in;margin-right: 0.0in;margin-top: 0.0in;">
	<span>&bull; Investigate a crime against Intermountain or any of its patients.   </span>
</p>
<p class="MsoNormal" style="background: white;line-height: normal;margin-left: 0.75in;">
	<b><span>&nbsp;</span></b><span>   </span>
</p>
<p class="MsoNormal" style="background: white;line-height: normal;margin-bottom: 0.0in;">
	<span>2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Required Uses of Health Information. The law sometimes requires us to share information for specific purposes with:   </span>
</p>
<p class="MsoNormal" style="background: white;line-height: normal;margin-bottom: 0.0in;margin-left: 0.75in;margin-right: 0.0in;margin-top: 0.0in;">
	<span>&bull; The Department of Health, to report communicable diseases, traumatic injuries, or birth defects, or for vital statistics, such as a baby's birth.   </span>
</p>
<p class="MsoNormal" style="background: white;line-height: normal;margin-bottom: 0.0in;margin-left: 0.75in;margin-right: 0.0in;margin-top: 0.0in;">
	<span>&bull; A funeral director or an organ-donation agency, when a patient dies, or with a medical examiner when appropriate to investigate a death;   </span>
</p>
<p class="MsoNormal" style="background: white;line-height: normal;margin-bottom: 0.0in;margin-left: 0.75in;margin-right: 0.0in;margin-top: 0.0in;">
	<span>&bull; The appropriate governmental agency if an injury or unexpected death occurs at an Intermountain facility;   </span>
</p>
<p class="MsoNormal" style="background: white;line-height: normal;margin-bottom: 0.0in;margin-left: 0.75in;margin-right: 0.0in;margin-top: 0.0in;">
	<span>&bull; State authorities, to report child or elderly abuse.   </span>
</p>
<p class="MsoNormal" style="background: white;line-height: normal;margin-bottom: 0.0in;margin-left: 0.75in;margin-right: 0.0in;margin-top: 0.0in;">
	<span>&bull; Law enforcement, for certain types of crime-related injuries, such as gunshot wounds.   </span>
</p>
<p class="MsoNormal" style="background: white;line-height: normal;margin-bottom: 0.0in;margin-left: 0.75in;margin-right: 0.0in;margin-top: 0.0in;">
	<span>&bull; </span><span>Governmental inspectors, who, for example, make sure our facilities are safe.   </span>
</p>
<p class="MsoNormal" style="background: white;line-height: normal;margin-bottom: 0.0in;margin-left: 0.75in;margin-right: 0.0in;margin-top: 0.0in;">
	<span>&bull; </span><span>Military command authorities or the Department of Veterans Affairs, when we treat patients who are in the military or are veterans.   </span>
</p>
<p class="MsoNormal" style="background: white;line-height: normal;margin-bottom: 0.0in;margin-left: 0.75in;margin-right: 0.0in;margin-top: 0.0in;">
	<span>&bull; A correctional institution, if a patient is an inmate.   </span>
</p>
<p class="MsoNormal" style="background: white;line-height: normal;margin-bottom: 0.0in;margin-left: 0.75in;margin-right: 0.0in;margin-top: 0.0in;">
	<span>&bull; The Secret Service or NSA, to protect the country or the President.   </span>
</p>
<p class="MsoNormal" style="background: white;line-height: normal;margin-bottom: 0.0in;margin-left: 0.75in;margin-right: 0.0in;margin-top: 0.0in;">
	<span>&bull; A medical device's manufacturer, as required by the FDA.   </span>
</p>
<p class="MsoNormal" style="background: white;line-height: normal;margin-bottom: 0.0in;margin-left: 0.75in;margin-right: 0.0in;margin-top: 0.0in;">
	<span>&bull; Court officers, as required by law, in response to a court order or a valid subpoena.   </span>
</p>
<p class="MsoNormal" style="background: white;line-height: normal;margin-bottom: 0.0in;margin-left: 0.75in;margin-right: 0.0in;margin-top: 0.0in;">
	<span>&bull; Governmental authorities, to prevent serious threats to the public's health or safety.   </span>
</p>
<p class="MsoNormal" style="background: white;line-height: normal;margin-bottom: 0.0in;margin-left: 0.75in;margin-right: 0.0in;margin-top: 0.0in;">
	<span>&bull; Governmental agencies and other affected parties, to report a breach of health information privacy.   </span>
</p>
<p class="MsoNormal" style="background: white;line-height: normal;margin-bottom: 0.0in;margin-left: 0.75in;margin-right: 0.0in;margin-top: 0.0in;">
	<span>&bull; An employer, but only if the employer contracts with us to help meet OSHA requirements about workplace and employee safety   </span>
</p>
<p class="MsoNormal" style="background: white;line-height: normal;margin-bottom: 0.0in;margin-left: 0.75in;margin-right: 0.0in;margin-top: 0.0in;">
	<span>&bull; A workers' compensation program, if a person is injured at work and claims benefits under that program.   </span>
</p>
<p class="MsoNormal" style="background: white;line-height: normal;">
	<span>&nbsp;   </span>
</p>
<p class="MsoNormal" style="background: white;line-height: normal;margin-bottom: 0.0in;">
	<span>3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Uses According to Your Request. Your preferences matter. If you let us know how you want us to disclose your information in the following situations, we will follow your directions. You decide:   </span>
</p>
<p class="MsoNormal" style="background: white;line-height: normal;margin-bottom: 0.0in;margin-left: 0.75in;margin-right: 0.0in;margin-top: 0.0in;">
	<span>&bull; If you want callers or visitors to know how to reach you when you are in the hospital. When you're admitted, you will be asked if you want to be in the "hospital directory." If you say "yes," the hospital can tell visitors who ask for you by name how to find you or talk to you. The hospital may also tell the visitor — only in general terms — how you are doing. If you say "no," the hospital won't let any visitor know you are in the hospital and won't direct phone calls or visitors to you.   </span>
</p>
<p class="MsoNormal" style="background: white;line-height: normal;margin-bottom: 0.0in;margin-left: 0.75in;margin-right: 0.0in;margin-top: 0.0in;">
	<span><span>&nbsp;</span>&bull; If you want us to share any health or payment information related to your care with your family members or friends (Please let our employees know what you want us to share. If you can't tell us what health or payment information you want us to share but your family or friends will need to help you, we may use our professional judgment to decide what to share with them.);   </span>
</p>
<p class="MsoNormal" style="background: white;line-height: normal;margin-bottom: 0.0in;margin-left: 0.75in;margin-right: 0.0in;margin-top: 0.0in;">
	<span>&bull; Who we should contact in an emergency.<span>&nbsp; </span>If you aren't able to tell us who to contact, we may ask the public authorities to help. For example, we may ask the police to help find your family, or, in a disaster, we may help the Red Cross reconnect you with your family.   </span>
</p>
<p class="MsoNormal" style="background: white;line-height: normal;margin-bottom: 0.0in;margin-left: 0.75in;margin-right: 0.0in;margin-top: 0.0in;">
	<span>&bull; If you want to indicate your religious preference when you are admitted to one of our facilities. If you indicate your religious preference, we may provide your name to a visiting representative of your religion. If you don't want us to do so, tell our staff at any time that you do not want us to share your name with a religious representative.   </span>
</p>
<p class="MsoNormal" style="background: white;line-height: normal;margin-bottom: 0.0in;margin-left: 0.75in;margin-right: 0.0in;margin-top: 0.0in;">
	<span>&bull; If you want us to provide immunization information about you or your child to a school.   </span>
</p>
<p class="MsoNormal" style="background: white;line-height: normal;">
	<span>&nbsp;   </span>
</p>
<p class="MsoNormal" style="background: white;line-height: normal;margin-bottom: 0.0in;">
	<span>4.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Uses of Health Information with Your Authorization   </span>
</p>
<p class="MsoNormal" style="background: white;line-height: normal;margin-left: 0.25in;">
	<span>&nbsp;   </span>
</p>
<p class="MsoNormal" style="background: white;line-height: normal;margin-left: 0.5in;">
	<span>Any sharing of your health information, other than as explained above, requires your written authorization. For example, we will not use your health information unless you authorize us in writing to:   </span>
</p>
<p class="MsoNormal" style="background: white;line-height: normal;margin-bottom: 0.0in;margin-left: 1.0in;margin-right: 0.0in;margin-top: 0.0in;">
	<span>&bull; Send copies of your health information to a life insurance company.   </span>
</p>
<p class="MsoNormal" style="background: white;line-height: normal;margin-bottom: 0.0in;margin-left: 1.0in;margin-right: 0.0in;margin-top: 0.0in;">
	<span>&bull; Share any of your psychotherapy notes, if they exist, with a third party who is not part of your care.   </span>
</p>
<p class="MsoNormal" style="background: white;line-height: normal;margin-bottom: 0.0in;margin-left: 1.0in;margin-right: 0.0in;margin-top: 0.0in;">
	<span>&bull; Send information to encourage you to buy&nbsp;a non-Intermountain product if we are paid to send that information or make the communication.   </span>
</p>
<p class="MsoNormal" style="background: white;line-height: normal;margin-bottom: 0.0in;margin-left: 1.0in;margin-right: 0.0in;margin-top: 0.0in;">
	<span>&bull; Sell your identifiable health information.   </span>
</p>
<p class="MsoNormal" style="background: white;line-height: normal;">
	<span>&nbsp;   </span>
</p>
<p class="MsoNormal" style="background: white;line-height: normal;margin-left: 0.5in;">
	<span>If you authorize us to share your health information but then change your mind, please notify the appropriate Intermountain facility in writing that you revoke the authorization. We will honor your revocation, but we will not able to get back the health information that you authorized us to send before your revocation.   </span>
</p>
<p class="MsoNormal" style="background: white;line-height: normal;margin-left: 0.5in;">
	<span>&nbsp;   </span>
</p>
<p class="MsoNormal" style="background: white;line-height: normal;margin-bottom: 0.0in;">
	<span>5.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Special Legal Protections for Certain Health Information.<span>&nbsp; </span>   </span>
</p>
<p class="MsoNormal" style="background: white;line-height: normal;margin-bottom: 0.0in;">
	<span>Intermountain complies with federal and state laws that require extra protection of special records.&nbsp; These records may include records of treatment in an addiction-treatment program, genetic information, or psychotherapy notes from a treating psychotherapist.   </span>
</p>
<p class="MsoNormal" style="background: white;line-height: normal;">
	<span>If You Still Have Questions   </span>
</p>
<p class="MsoNormal" style="background: white;line-height: normal;margin-left: 0.5in;">
	<span>Our staff is trained to help you with any questions you may have about the privacy of your health information. They can also address any privacy concerns you may have about your health information and help you fill out any forms that are needed to exercise your privacy rights. If you are at one of our Intermountain facilities, please ask one of our staff members to help you contact a local Compliance or Privacy Coordinator, or call (800) 442-4845 to reach our main Privacy Office.   </span>
</p>
<p class="MsoNormal" style="background: white;line-height: normal;margin-left: 0.5in;">
	<span>This privacy notice became effective January 2017.<span>&nbsp; </span>We may change this privacy notice at any time. You can see our current privacy notice that affects all PHI maintained by Intermountain on our website, at IntermountainHealthcare.org, or posted in our hospitals, clinics, and offices. You can also obtain a copy of this notice from any of our staff by asking for a copy, by visiting our website, or by calling our Privacy Office at (800) 442-4845.   </span>
</p>
<p class="MsoNormal" style="background: white;line-height: normal;margin-left: 0.5in;">
	<span>This notice describes the privacy practices of Intermountain employees and volunteers working in our hospitals, clinics, doctors' offices, and service departments and all are required to abide by the terms of this notice. This notice also describes the privacy practices of associated healthcare providers — who are not Intermountain employees — while treating you in our facilities, unless they provide you with a notice of their own privacy practices.<span>&nbsp; </span>For more information about the specific privacy practices of associated providers, please contact them directly   </span>
</p>
<p class="MsoNormal" style="line-height: normal;margin-bottom: 0.0in;">
	<span>  &nbsp;  </span>
</p>
</div>
	<p class="bodyDark"><strong class="allCaps">Geographic Restrictions</strong></p><p>I hereby certify that I am located in my current state (indicate on next page), and agree to only interact with a Provider through Connect Care while I am present in that state. I acknowledge that my ability to access and use these services is conditional upon the truthfulness of the certifications I make at the time of accessing a Provider, and that the Providers I access are relying upon this certification in order to interact with me.</p><p class="bodyDark"><strong class="allCaps">Age Requirements</strong></p><p>I hereby certify that I am at least 18 years of age and am qualified under the laws of my state to make medical decisions on my own behalf. I acknowledge that my ability to access and use the Intermountain Connect Care system and information is conditional upon the truthfulness of my certification of age.</p></div>
</div>
`;
