import styled from 'styled-components';
import { MuiGrid } from 'theme/material-ui';
import SmallLabel from 'components/UI/Labels/SmallLabel';

export const DetailsSectionWrapper = styled.div`
  width: 100%;
`;

export const PageDivider = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 650px) {
    flex-direction: column;
  }
`;

export const DetailsContainerColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-right: 1em;

  @media screen and (max-width: 650px) {
    margin-top: 0;
  }
`;

export const StyledProviderEducation = styled(MuiGrid)`
  width: fix-content;
  padding: 0.5em 0 1em 1em;
`;

export const StyledSmallLabel = styled(SmallLabel)`
  white-space: nowrap;
`;
