import Avatar from 'components/UI/Avatar/Avatar';
import { CostProvider } from 'store/cost/types';
import { Color } from 'modules/styles/colors';
import React from 'react';
import { Icon } from 'components/Icon';
import { UserCoords } from 'store/global/reducers';
import { ProviderItemStyledPanel } from './styled';
import { MuiBox, MuiGrid, MuiTypography } from 'theme/material-ui';
import { FontWeight } from 'modules/styles/variables';

interface Props {
  provider: CostProvider;
  geoLocation: UserCoords;
}

const ProviderItem = ({ provider, geoLocation }: Props) => {
  const { provider: providerDetails } = provider;

  return (
    <>
      <ProviderItemStyledPanel>
        <MuiBox data-testid="provider-item">
          <MuiBox>
            <MuiTypography data-testid="provider-name" fontWeight={FontWeight.semibold}>
              {providerDetails.name}
            </MuiTypography>
          </MuiBox>
          <MuiBox mt={1}>
            <MuiGrid container alignItems="center" spacing={1}>
              <MuiGrid item>
                <Icon name="location-on" size={20} color={Color.primary} />
              </MuiGrid>
              <MuiGrid data-testid="provider-address" item>
                <MuiTypography>{providerDetails.serviceLocation.address1}</MuiTypography>
                <MuiTypography>{providerDetails.serviceLocation.address2}</MuiTypography>
                <MuiTypography>
                  {geoLocation && `Distance: ${providerDetails.serviceLocation.distance} miles`}
                </MuiTypography>
              </MuiGrid>
            </MuiGrid>
          </MuiBox>
          <MuiBox mt={1}>
            <MuiTypography data-testid="total-cost-before-benefits">
              Total Cost before benefits:{' '}
            </MuiTypography>
            <MuiTypography variant="h6" color={Color.primary} fontWeight={FontWeight.semibold}>
              ${provider.expectedCost}
            </MuiTypography>
          </MuiBox>
        </MuiBox>
        <MuiBox>
          <Avatar imageUrl={providerDetails.imageUrl} />
        </MuiBox>
      </ProviderItemStyledPanel>
    </>
  );
};

export default ProviderItem;
