import React from 'react';
import { dfdDefaultTheme, MuiBox } from 'theme/material-ui';
import { EllipsedText } from '../styled';
import Svg from '../../../components/UI/Svg/Svg';
import { IconSize } from 'modules/styles/variables';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Color } from 'modules/styles/colors';

interface LabRowCommentsProps {
  comments: string;
}

const LabRowComments = ({ comments }: LabRowCommentsProps) => {
  const isSmallScreen = useMediaQuery(dfdDefaultTheme.breakpoints.down('md'));
  return (
    <>
      <MuiBox display="flex" mx={1}>
        {' '}
        |{' '}
      </MuiBox>
      {isSmallScreen ? (
        <MuiBox display="flex">
          <Svg
            data-testid="comments-icon"
            set="assets"
            name="SNavMessages"
            fill={Color.black}
            width={IconSize.xmedium}
          />
        </MuiBox>
      ) : (
        <EllipsedText width={200} data-testid="trendline-comment">
          {comments}
        </EllipsedText>
      )}
    </>
  );
};

export default LabRowComments;
