import React from 'react';
import { useHistory } from 'react-router';
import { connect } from 'react-redux';

import RouteLeavingGuard from 'components/UI/Modals/RouteLeavingGuardModal';
import {
  currentLocationPathNameSelector,
  previousLocationPathNameSelector
} from 'store/router/selectors';
import { RootState, RootDispatch } from 'store/types';
import { clearRequestedProxies } from 'store/profile/actions';
import analyticsService, { AmplitudeEventData, AnalyticsEvent } from 'services/AnalyticsService';

import { PROXY_CONFIRM_DIALOG_TEXT } from 'screens/ProxyForm/constants';
import { ProxyFormNav } from 'screens/ProxyForm/navigation/router';
import { SHARE_FEEDBACK_SCENARIOS } from 'lib/constants/help';

export interface LeavingGuardProps {
  currentUrl?: string;
  referringUrl?: string;
  clearProxies: () => void;
}

export const ProxyLeavingGuard = ({
  currentUrl,
  referringUrl,
  clearProxies
}: LeavingGuardProps) => {
  const history = useHistory();
  const currentRoute = history?.location?.pathname;

  const handleNavigate = async (path: string, options: object) => {
    clearProxies();
    const eventData: AmplitudeEventData = {
      referringUrl,
      currentUrl
    };
    analyticsService.logEvent(AnalyticsEvent.ProxyAccessExitedEarly, eventData);
    history.push(path, options);
  };

  const exitAndShareFeedback = () => {
    history.push('/u/help-support/share-feedback', {
      scenario: SHARE_FEEDBACK_SCENARIOS.proxyRequestForm,
      screenLocation: ProxyFormNav.PROXY_FORM,
      navigateTo: '/u/manage-account'
    });
  };

  const mainProxyRoutes = [
    ProxyFormNav.PROXY_FORM,
    ProxyFormNav.PROXY_REVIEW,
    ProxyFormNav.PROXY_REVIEW_CONTACT
  ];
  return (
    <RouteLeavingGuard
      shouldBlockNavigation={nextLocation => {
        if (
          mainProxyRoutes.includes(nextLocation.pathname) ||
          (currentRoute === ProxyFormNav.PROXY_REVIEW_CONTACT && nextLocation.state?.submitted)
        ) {
          return false;
        }
        return true;
      }}
      exitTitle={PROXY_CONFIRM_DIALOG_TEXT.title}
      exitSubtitle={PROXY_CONFIRM_DIALOG_TEXT.subtitle}
      exitButtonText={PROXY_CONFIRM_DIALOG_TEXT.primaryButton}
      keepGoingButtonText={PROXY_CONFIRM_DIALOG_TEXT.secondaryButton}
      exitAndShareButtonText={PROXY_CONFIRM_DIALOG_TEXT.textButton}
      exitAndShareHandler={exitAndShareFeedback}
      navigate={(path: string, options: object) => handleNavigate(path, options)}
    />
  );
};

const mapDispatch = (dispatch: RootDispatch) => ({
  clearProxies: () => dispatch(clearRequestedProxies())
});

const mapStateToProps = (state: RootState) => ({
  currentUrl: currentLocationPathNameSelector(state),
  referringUrl: previousLocationPathNameSelector(state)
});

export default connect(mapStateToProps, mapDispatch)(ProxyLeavingGuard);
