import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { dfdDefaultTheme, MuiBox, MuiCardMedia, MuiTypography } from 'theme/material-ui';
import {
  ConnectCareButton,
  ConnectCareCardActions,
  ConnectCareCardContent,
  ConnectCareCardHeader
} from 'components/ConnectCare';
import { Svg } from 'components/UI/Svg';
import { MuiHomeCard, ImgBox } from './styled';
import { HIDE_IMAGE_BREAKPOINT } from 'modules/constants/ConnectCare';
import { Color } from 'modules/styles/colors';
import { convertToLowerKabobCase } from 'modules/utils/StringUtils';
import styled from 'styled-components';
import { FontWeight } from 'modules/styles/variables';
import Spacer from 'components/UI/Layout/Spacer';

const StyledMuiCardMedia = styled(MuiCardMedia)`
  &.MuiCardMedia-root {
    width: 290px;
    height: 300px;
  }
`;

const StyledMuiBox = styled(MuiBox)`
  &.MuiBox-root {
    padding: 24px 24px 8px;
    ${dfdDefaultTheme.breakpoints.up(HIDE_IMAGE_BREAKPOINT)} {
      padding: 0 24px;
    }
  }
`;

export interface CardSectionProps {
  buttonLabel: string;
  description: string;
  imgSrc: string;
  linkLabel: string;
  testId: string;
  title: string;
  subTitle?: string;
  connectCareTypeClick: () => void;
  linkHandlerClick: () => void;
}

const getImage = (evisit: boolean, imgSrc: string, isSmallScreen: boolean) => {
  if (isSmallScreen) {
    return null;
  }
  return (
    <ImgBox evisit>
      {imgSrc ? (
        <StyledMuiCardMedia component="img" src={imgSrc} data-testid="section-image" />
      ) : (
        <Svg set="assets" name="ConnectCareLogo" width={250} height="100%" />
      )}
    </ImgBox>
  );
};

export const ConnectCareSection = ({
  buttonLabel,
  description,
  imgSrc,
  linkLabel,
  testId,
  title,
  subTitle,
  connectCareTypeClick,
  linkHandlerClick
}: CardSectionProps) => {
  const isSmallScreen = useMediaQuery(dfdDefaultTheme.breakpoints.down(HIDE_IMAGE_BREAKPOINT));
  return (
    <MuiBox my={3} data-testid={testId}>
      <MuiHomeCard>
        {getImage(title === 'E-Visit', imgSrc, isSmallScreen)}
        <StyledMuiBox py={3} px={4}>
          <ConnectCareCardHeader
            padding={title === 'E-Visit' ? '6px 16px' : '8px 16px'}
            title={title}
          />
          <MuiBox paddingLeft={2}>
            <MuiTypography color="textPrimary" fontWeight={FontWeight.bold} fontSize={16}>
              {subTitle}
            </MuiTypography>
          </MuiBox>

          <ConnectCareCardContent>
            <MuiTypography color="textPrimary" data-testid="section-description" fontSize={14}>
              {description}
            </MuiTypography>
          </ConnectCareCardContent>
          <ConnectCareCardActions>
            <ConnectCareButton
              data-testid={convertToLowerKabobCase(buttonLabel)}
              fullWidth
              size="large"
              onClick={connectCareTypeClick}
            >
              {buttonLabel}
            </ConnectCareButton>
            <Spacer size="small" />
            <ConnectCareButton
              backgroundColor={Color.outlinedPressed}
              color={Color.secondary}
              data-testid={convertToLowerKabobCase(linkLabel)}
              fullWidth
              size="large"
              onClick={linkHandlerClick}
            >
              {linkLabel}
            </ConnectCareButton>
            <Spacer size="small" />
          </ConnectCareCardActions>
        </StyledMuiBox>
      </MuiHomeCard>
    </MuiBox>
  );
};
