import DefaultLabel from 'components/UI/Labels/DefaultLabel';
import SmallLabel from 'components/UI/Labels/SmallLabel';
import Section from 'components/UI/Layout/Section';
import Panel from 'components/UI/Panel/Panel';
import { Spacing } from 'modules/styles/variables';
import styled from 'styled-components';
import { MuiDivider } from 'theme/material-ui';

export const StyledSection = styled(Section)`
  padding-top: 10px;
  padding-bottom: 10px;
`;

export const SectionHeader = styled(DefaultLabel)`
  padding-bottom: 10px;
`;

export const PanelContainer = styled(Panel)`
  flex: auto;
`;

export const TimeCol = styled(SmallLabel)`
  font-weight: 600;
  flex: 4;
  align-self: center;
`;

export const StarCol = styled.div`
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  display: flex;
`;

export const CommentText = styled(SmallLabel)`
  padding-bottom: ${Spacing.medium}px;
  padding-top: ${Spacing.medium}px;
`;

export const StyledMuiDivider = styled(MuiDivider)`
  && {
    margin: ${Spacing.large}px -20px ${Spacing.large}px -20px;
  }
`;
