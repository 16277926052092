import React, { useState } from 'react';

import Spinner from 'components/UI/Spinner/Spinner';

import { InsuranceCardData } from 'store/CovidScreening/types';
import VirtualizedAutocomplete from 'screens/CovidScreening/Components/VirtualizedAutoComplete';

import { useCovidInsuranceDropdown } from '../../hooks';

export interface InsuraceSelectProps {
  onClean: (cannotFind: boolean) => void;
  onSelectInsurance: (cardData: InsuranceCardData) => void;
}

export default function InsuranceSelect(props: InsuraceSelectProps) {
  const { healthPlanName, insuranceListData, isLoading } = useCovidInsuranceDropdown();
  const [insuranceSearchText, setInsuranceSearchText] = useState(healthPlanName ?? '');

  const onChangeSelected = (insurance: InsuranceCardData) => {
    setInsuranceSearchText(insurance?.plan_name);
    props.onSelectInsurance(insurance);
  };

  if (isLoading) return <Spinner />;

  return (
    <VirtualizedAutocomplete
      cleanStatesData={props.onClean}
      data-testid="insurance-list-dropdown"
      handleInsurancePress={onChangeSelected}
      InsuranceList={insuranceListData}
      valueSelected={insuranceSearchText}
    />
  );
}
