import React, { useState, useEffect } from 'react';
import { View, TouchableOpacity } from 'react-native';
import { connect, useSelector } from 'react-redux';
import { NavigationScreenProps } from 'screens/navigation';
import { KeyboardArrowRight } from '@material-ui/icons';
import * as snacksActions from 'store/snacks/actions';
import { SnackType, Snack } from 'store/snacks/types';
import analyticsService, { AmplitudeEventData, AnalyticsEvent } from 'services/AnalyticsService';
import {
  currentLocationPathNameSelector,
  previousLocationPathNameSelector
} from 'store/router/selectors';
import { RootState } from 'store/types';
import formatMoney from 'services/formatMoney';
import { Color } from 'modules/styles/colors';
import { formatDate } from 'modules/utils/DateUtils';
import { FontSize, FontWeight } from 'modules/styles/variables';

import Typography from 'components/UI/Typography';
import ScrollableLayout from 'components/UI/Layout/ScrollableLayout';
import FlexBox from 'components/UI/Layout/FlexBox';
import DefaultText from 'components/UI/Typography/DefaultText';
import Spacer from 'components/UI/Layout/Spacer';
import Divider from 'components/UI/Divider';
import SpinnerModal from 'components/UI/Spinner/SpinnerModal';
import Screen from 'components/UI/Layout/Screen';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import { SpacedRowFlexBox, Panel, EmptyFlexBox, CustomBuild, CustomBuildEnd } from './styled';
import { History } from 'history';
import { getPaymentsListSelector } from 'store/billing/selectors';
import { getPayments } from 'store/billing/actions';
import { PaymentListItem } from 'store/billing/types';
import { PaymentsListState } from 'store/billing/reducers';
import useCoreEventData from 'hooks/useCoreEventData';
import { LIMIT } from 'store/billing/constants';

interface Props extends NavigationScreenProps {
  createSnack: typeof snacksActions.createSnack;
  currentUrl?: string;
  getPayments: (params: {
    limit: number;
    offset: number;
    useRequestState?: boolean;
  }) => Promise<void>;
  referringUrl?: string;
}

export const PaymentHistoryMHP = ({ createSnack, history, getPayments }: Props) => {
  const [limit, setLimit] = useState(LIMIT);
  const { currentUrl, referringUrl } = useCoreEventData();
  const { data = [], isFetching: loading, error } = useSelector(
    getPaymentsListSelector
  ) as PaymentsListState;

  const eventData: AmplitudeEventData = {
    currentUrl,
    referringUrl
  };

  useEffect(() => {
    if (error && currentUrl === '/u/billing/payment-history') {
      createSnack(SnackType.BASE, { label: 'Unable to load data, please try again' });
    }
  }, [currentUrl, error]);

  const loadMore = data.length === limit;
  useEffect(() => {
    getPayments({ limit, offset: 0, useRequestState: data.length === limit });
  }, [limit]);

  const onLoadMore = () => {
    setLimit(limit + LIMIT);
  };

  return render({ eventData, history, loading, loadMore, onLoadMore, transactions: data });
};

function render({
  eventData,
  history,
  loading,
  loadMore,
  onLoadMore,
  transactions
}: {
  eventData: AmplitudeEventData;
  loading: boolean;
  loadMore: boolean;
  onLoadMore: () => void;
  transactions: PaymentListItem[];
  history: History;
}) {
  const goToPaymentDetail = (item: PaymentListItem) => {
    analyticsService.logEvent(AnalyticsEvent.IndividualPaymentClicked, eventData);
    history.push(`/u/billing/payment-detail/${item.receiptNumber}`);
  };

  return (
    <Screen>
      <ScrollableLayout>
        <SpinnerModal isLoading={loading} />
        <Panel accessibilityLabel="Payment History">
          {transactions.length === 0 ? (
            <EmptyFlexBox>
              <Spacer size="large" />
              <DefaultText>No Payments</DefaultText>
            </EmptyFlexBox>
          ) : (
            transactions.map((eachPayment: PaymentListItem) => (
              <View
                accessibilityLabel="View Payment"
                key={`${eachPayment.receiptNumber} ${eachPayment.date} ${eachPayment.amount}`}
              >
                <Table aria-label="simple table">
                  <TableBody>
                    <TouchableOpacity onPress={() => goToPaymentDetail(eachPayment)}>
                      <TableRow>
                        <CustomBuild component="th" scope="row">
                          <Typography
                            accessibilityLabel={` ${formatDate(eachPayment.date)}`}
                            numberOfLines={1}
                            fontSize={FontSize.small}
                          >{` ${formatDate(eachPayment.date)}`}</Typography>
                        </CustomBuild>
                        <CustomBuild>
                          <Typography
                            accessibilityLabel={`Description ${eachPayment.description}`}
                            numberOfLines={1}
                            fontSize={FontSize.large}
                            fontWeight={FontWeight.bold}
                          >
                            {eachPayment.description}
                          </Typography>
                        </CustomBuild>
                        <CustomBuild>
                          <Typography
                            accessibilityLabel={`Payment Amount $${formatMoney(
                              eachPayment.amount
                            )}`}
                            fontSize={FontSize.base}
                          >{`   $${formatMoney(eachPayment.amount)}`}</Typography>
                        </CustomBuild>
                        <CustomBuild>Paid</CustomBuild>
                        <CustomBuildEnd>
                          <KeyboardArrowRight />
                        </CustomBuildEnd>
                      </TableRow>
                    </TouchableOpacity>
                  </TableBody>
                </Table>
                <SpacedRowFlexBox accessibilityLabel="Payment" justifyContent="space-between">
                  <FlexBox
                    accessibilityLabel="Payment Information"
                    flex={2}
                    flexDirection="column"
                  ></FlexBox>
                  <FlexBox
                    accessibilityLabel="Payment Amount"
                    flex={1}
                    justifyContent="space-between"
                    alignItems="center"
                    flexDirection="row"
                  ></FlexBox>
                </SpacedRowFlexBox>
                <Divider />
              </View>
            ))
          )}
        </Panel>
        <FlexBox flexDirection="row" justifyContent="center" alignItems="center">
          {!loading ? (
            <TouchableOpacity
              onPress={onLoadMore}
              disabled={!loadMore}
              style={{ marginBottom: 15 }}
            >
              <Typography color={loadMore ? Color.primaryUnderlay : Color.gray}>
                {loadMore ? 'Load More' : 'No More Payments'}
              </Typography>
            </TouchableOpacity>
          ) : (
            ''
          )}
        </FlexBox>
      </ScrollableLayout>
    </Screen>
  );
}

const mapStateToProps = (state: RootState) => ({
  currentUrl: currentLocationPathNameSelector(state),
  referringUrl: previousLocationPathNameSelector(state)
});

const mapDispatchToProps = (dispatch: Function) => ({
  createSnack: (type: SnackType, snack?: Snack) => dispatch(snacksActions.createSnack(type, snack)),
  getPayments: (params: { limit: number; offset: number; useRequestState?: boolean }) =>
    dispatch(getPayments(params.limit, params.useRequestState, params.offset))
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentHistoryMHP);
