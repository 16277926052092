import React from 'react';
import { EducationItem } from 'store/education/types';
import usePatientEducation from 'lib/hooks/usePatientEducation';
import EducationView from './EducationView';

interface HistoricalEducationViewProps {
  accessCode?: string;
}

export const HistoricalEducationView = ({ accessCode }: HistoricalEducationViewProps) => {
  const { complete } = usePatientEducation();
  const educationItem: EducationItem | undefined = complete.find(
    (e: EducationItem) => e.accessCode === accessCode
  );

  return <EducationView educationItem={educationItem} />;
};

export default HistoricalEducationView;
