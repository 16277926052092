import React from 'react';
import { Color } from 'modules/styles/colors';
import { FontSize } from 'modules/styles/variables';
import Box, { BoxProps } from 'components/UI/Layout/Box';

interface BlueDotProps extends BoxProps {
  size: number;
  color: string;
}

const BlueDot = ({ size, color, ...rest }: BlueDotProps) => (
  <Box height={size} width={size} borderRadius={size / 2} backgroundColor={color} {...rest} />
);

BlueDot.defaultProps = {
  size: FontSize.small,
  color: Color.secondary
};

export default BlueDot;
