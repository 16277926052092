import React, { useEffect, useState } from 'react';
import { Image } from 'react-native';
import dayjs from 'dayjs';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import first from 'lodash/first';
import { RouteComponentProps } from 'react-router';

import errorPlug from 'assets/errorPlug.png';
import awkwardDog from 'assets/awkwardDog.png';

import {
  MuiAlert,
  MuiBox,
  MuiCard,
  MuiCardContent,
  MuiContainer,
  MuiDivider,
  MuiGrid,
  MuiTypography
} from 'theme/material-ui';

import useNavigationAnalytics from 'hooks/useNavigationAnalytics';
import { AnalyticsEvent } from 'services/AnalyticsService';

import DataLoader from 'components/UI/DataLoader/DataLoader';
import { Modal } from 'components/CustomModal';
import Spinner from 'components/UI/Spinner/Spinner';

import { FontSize, FontWeight, IconSize } from 'modules/styles/variables';
import { dateAndTimeSelect } from 'modules/constants/booking';
import { bookingAppointmentCallEventProps } from 'modules/utils/AnalyticsUtils';

import { RootState } from 'store/types';
import * as bookingTypes from 'store/booking/types';
import * as bookingActions from 'store/booking/actions';
import * as bookingSelectors from 'store/booking/selectors';
import * as routerSelectors from 'store/router/selectors';
import { closeTimeSlotListener } from 'store/booking/actions';
import { TIMEOUT_TIME_SLOTS_LIMIT } from 'store/booking/constants';

import { BookingDatePicker } from './DatePicker';
import { BookingLoading, BookingNoData } from '../components/sharedComponents';
import { BookingLocationCard, StyledScreen } from '../components/styled';
import { BookingStepActions, BookingStepAction } from '../components/BookingStepper';
import { useCheckBookingInfoEffect } from '../useCheckBookingInfoEffect';
import {
  calculateDaysTillFirstAvailability,
  formatTimeWithTimeZone
} from 'modules/utils/DateUtils';
import { TimeSlots } from './TimeSlotSelect';
import { Color } from 'modules/styles/colors';
import { getUSPhoneNumber } from 'modules/utils/PhoneUtils';
import LinkingServices from 'services/LinkingServices';
import FlexBox from 'components/UI/Layout/FlexBox';
import { Svg } from 'components/UI/Svg';

export interface Props extends RouteComponentProps {
  appointmentDetails: bookingTypes.AppointmentDetails;
  locationTimeSlots: bookingTypes.Slots[];
  timeSlotsByAppointmentDate: bookingTypes.Slots[];
  isFetchingDataDeps: boolean;
  setAppointmentDate: typeof bookingActions.setAppointmentDate;
  setAppointmentTime: typeof bookingActions.setAppointmentTime;
  resetAppointmentTime: typeof bookingActions.resetAppointmentTime;
  lockTimeSlot: typeof bookingActions.lockTimeSlot;
  getLocationTimeSlots: typeof bookingActions.getLocationTimeSlots;
}

export function BookingDateAndTimeSelect({
  appointmentDetails,
  getLocationTimeSlots,
  locationTimeSlots,
  timeSlotsByAppointmentDate,
  setAppointmentDate,
  setAppointmentTime,
  resetAppointmentTime,
  lockTimeSlot,
  history
}: Props) {
  useCheckBookingInfoEffect(appointmentDetails?.patient);

  const [isTimeout, setIsTimeout] = useState(false);
  const [tryAgain, setTryAgain] = useState(false);
  const isLoading = locationTimeSlots?.length === 0;

  const bookAppointmentCalendarViewed = useNavigationAnalytics(
    AnalyticsEvent.BookAppointmentCalendarViewed
  );

  const bookAppointmentErrorCallForCalendarOptions = useNavigationAnalytics(
    AnalyticsEvent.BookAppointmentErrorCallForCalendarOptions
  );

  useEffect(() => {
    let clearTimer: number;
    if (isLoading || tryAgain) {
      clearTimer = setTimeout(() => setIsTimeout(true), TIMEOUT_TIME_SLOTS_LIMIT);
    }
    return (): void => {
      setTryAgain(false);
      clearTimeout(clearTimer);
    };
  }, [locationTimeSlots, tryAgain]);

  const handleBookAppointmentCalendarViewed = (date: string) => {
    const daysTillFirstAvailability = calculateDaysTillFirstAvailability(dayjs(), date);

    bookAppointmentCalendarViewed(
      bookingAppointmentCallEventProps(appointmentDetails, {
        days_till_first_availability: daysTillFirstAvailability
      })
    );
  };

  // Selects the earliest date that has slots.
  useEffect(() => {
    if (locationTimeSlots?.length && !appointmentDetails.appointmentDate) {
      const earliestDate = first(
        [...locationTimeSlots].sort((t1, t2) => (dayjs(t1.datetime).isBefore(t2.datetime) ? -1 : 1))
      );

      if (earliestDate) {
        const date = dayjs(earliestDate.datetime).format('YYYY-MM-DD');
        handleBookAppointmentCalendarViewed(date);
        setAppointmentDate(date);
      } else {
        handleBookAppointmentCalendarViewed(dayjs().format('YYYY-MM-DD'));
      }
    }
  }, [locationTimeSlots]);

  // Resets selected time when selected date is changed.
  useEffect(() => {
    resetAppointmentTime();
  }, [appointmentDetails.appointmentDate]);

  const onSlotError = () => {
    Modal.show({
      customIcon: (
        <Image
          accessibilityLabel="awkward-dog"
          testID="awkward-dog"
          style={{ width: 50, height: 50 }}
          source={{ uri: awkwardDog }}
        />
      ),
      backdrop: { skipBackdropClose: true },
      title: 'Uh-oh...',
      description: 'Someone else just picked this time slot.',
      buttons: {
        styles: { direction: 'row', justifyContent: 'center', alignItems: 'center' },
        items: [
          {
            label: 'Ok',
            onClick: () => {},
            styles: { paddingX: 20, height: 55, width: 120 }
          }
        ]
      },
      options: {
        onClose: () => {}
      }
    });
  };

  const onNextClick = async () => {
    const lockAppointmentSlotArgs = {
      slotStartTime: appointmentDetails?.appointmentTime?.datetime,
      slotEndTime: appointmentDetails?.appointmentTime?.end,
      providerId: appointmentDetails?.doctor?.cernerResourceId?.toString()
    } as bookingTypes.LockSlotRequestPayload;

    const lockAppointmentResponse: AnyAction = await lockTimeSlot(lockAppointmentSlotArgs);

    if (lockAppointmentResponse.error || !lockAppointmentResponse.payload.data.success) {
      onSlotError();
    } else {
      history.push('/u/get-care-now/booking/reason-for-visit', {
        lockedSlot: lockAppointmentSlotArgs
      });
    }
    closeTimeSlotListener();
  };

  const onPreviousClick = () => {
    closeTimeSlotListener();
    history.goBack();
  };

  const headerText = `Select an appointment time for ${appointmentDetails?.patient?.firstName}'s visit with Dr. ${appointmentDetails?.doctor?.displayName}:`;
  const boxInfoText = `Can't find the date or time you want? Please call the clinic ${
    appointmentDetails?.appointmentLocation?.phoneDisplay
      ? getUSPhoneNumber(appointmentDetails?.appointmentLocation?.phoneDisplay)
      : ''
  }`;

  const handleNavigation = (): void => {
    if (history.location.pathname === '/u/get-care-now/booking/date-and-time-select') {
      history.replace('/u/get-care-now/booking/specialty-or-my-doctor-select');
    } else {
      const backTo =
        history.location.pathname === '/u/get-care-now/booking/office-location-select' ? -1 : -2;
      history.go(backTo);
    }
  };

  const handleError = () => {
    const phone = appointmentDetails?.appointmentLocation?.phoneDisplay;
    const buttons = {
      styles: { direction: 'row', justifyContent: 'center', alignItems: 'center' },
      items: [
        phone
          ? {
              label: `Call the clinic ${getUSPhoneNumber(phone)}`,
              onClick: () => {
                bookAppointmentErrorCallForCalendarOptions(
                  bookingAppointmentCallEventProps(appointmentDetails)
                );
                LinkingServices.callPhoneNumber(phone ?? '');
                setIsTimeout(false);
                closeTimeSlotListener();
                handleNavigation();
              },
              styles: { paddingX: 35, width: 175 }
            }
          : null,
        {
          label: 'Try again',
          onClick: () => {
            setIsTimeout(false);
            setTryAgain(true);
            closeTimeSlotListener();
            getLocationTimeSlots();
          },
          styles: { paddingX: 30, height: 55, width: 175 }
        }
      ]
    };

    Modal.show({
      customIcon: (
        <Image
          accessibilityLabel="error-plug"
          style={{ width: 83, height: 51 }}
          source={{ uri: errorPlug }}
        />
      ),
      backdrop: { skipBackdropClose: true },
      title: 'Uh-oh... something went wrong',
      description: 'Please call your clinic or try your attempt again',
      buttons,
      options: {
        onClose: (): void => {
          setIsTimeout(false);
          closeTimeSlotListener();
          handleNavigation();
        }
      }
    });
  };

  if (isLoading && isTimeout) {
    handleError();
  }

  return (
    <StyledScreen>
      <FlexBox flex="1">
        <MuiContainer maxWidth="lg">
          <MuiBox my={3}>
            <MuiBox mb={1}>
              <MuiTypography variant="h4">Select An Appointment</MuiTypography>
            </MuiBox>
            <MuiBox mb={3}>
              <MuiDivider />
            </MuiBox>
            <MuiBox mb={3}>
              <MuiTypography fontSize={FontSize.base}>{headerText}</MuiTypography>
            </MuiBox>
            <DataLoader
              data={locationTimeSlots}
              loading={isLoading}
              renderLoading={() => (
                <MuiBox textAlign="center" paddingTop={3}>
                  <Spinner
                    size={IconSize.xLarge}
                    data-testid="booking-date-and-time-select-loading"
                  />
                  <BookingLoading />
                </MuiBox>
              )}
              renderNoData={() => (
                <BookingNoData message={dateAndTimeSelect.LOCATION_TIMES.NO_DATA.message} />
              )}
              renderData={data => (
                <MuiGrid container justify="center">
                  <MuiGrid item xs>
                    <MuiBox mb={3} mr={3}>
                      <BookingDatePicker
                        timeSlots={data}
                        onChange={date => {
                          if (date) {
                            setAppointmentDate(date.toDate());
                          }
                        }}
                        value={appointmentDetails.appointmentDate}
                      />
                    </MuiBox>
                  </MuiGrid>
                  <MuiGrid item xs>
                    <MuiBox mb={3}>
                      <DataLoader
                        data={timeSlotsByAppointmentDate}
                        loading={isLoading}
                        renderLoading={() => <BookingLoading />}
                        renderNoData={() => (
                          <BookingNoData
                            message={dateAndTimeSelect.LOCATION_TIMES.NO_DATA.message}
                          />
                        )}
                        renderData={() => {
                          const [slotsData] = timeSlotsByAppointmentDate;
                          const { timeSlots } = slotsData;
                          return (
                            <>
                              <BookingLocationCard>
                                <MuiCardContent>
                                  <MuiTypography>Available time slots:</MuiTypography>
                                </MuiCardContent>
                                <TimeSlots
                                  timeSlots={timeSlots}
                                  appointmentDetails={appointmentDetails}
                                  setAppointmentTime={setAppointmentTime}
                                />
                              </BookingLocationCard>
                            </>
                          );
                        }}
                      />
                    </MuiBox>
                  </MuiGrid>
                </MuiGrid>
              )}
            />
            <MuiGrid container xs>
              <MuiGrid item xs>
                {appointmentDetails?.appointmentTime ? (
                  <MuiBox paddingBottom={3}>
                    <MuiCard>
                      <MuiCardContent>
                        <MuiTypography>You have chosen</MuiTypography>
                        <MuiTypography fontSize={FontSize.large} fontWeight={FontWeight.bold}>
                          {appointmentDetails?.appointmentTime?.datetime &&
                            formatTimeWithTimeZone(
                              appointmentDetails?.appointmentTime?.datetime
                            )}{' '}
                          on
                        </MuiTypography>
                        <MuiTypography fontSize={FontSize.large} fontWeight={FontWeight.bold}>
                          {dayjs(appointmentDetails?.appointmentDate).format('dddd MMMM Do')}
                        </MuiTypography>
                      </MuiCardContent>
                    </MuiCard>
                  </MuiBox>
                ) : null}

                {!isLoading ? (
                  <MuiBox>
                    <MuiAlert
                      style={{
                        width: '100%',
                        border: `2px solid ${Color.tertiary}`,
                        alignItems: 'center'
                      }}
                      severity="info"
                      icon={<Svg color={Color.black} name="InfoIcon" size={IconSize.small} />}
                    >
                      <MuiTypography>{boxInfoText}</MuiTypography>
                    </MuiAlert>
                  </MuiBox>
                ) : null}
              </MuiGrid>
            </MuiGrid>
          </MuiBox>
        </MuiContainer>
      </FlexBox>

      <BookingStepActions>
        <BookingStepAction
          disabled={!appointmentDetails?.appointmentDate || !appointmentDetails?.appointmentTime}
          onClick={onNextClick}
          data-testid="next-button"
        />
        <BookingStepAction onClick={onPreviousClick} data-testid="previous-button" />
      </BookingStepActions>
    </StyledScreen>
  );
}

const mapStateToProps = (state: RootState) => ({
  appointmentDetails: bookingSelectors.appointmentDetailsSelector(state),
  locationTimeSlots: bookingSelectors.timeSlotsDataSelector(state),
  timeSlotsByAppointmentDate: bookingSelectors.timeSlotsByAppointmentDateSelector(state),
  currentUrl: routerSelectors.currentLocationPathNameSelector(state),
  referringUrl: routerSelectors.previousLocationPathNameSelector(state)
});

const mapDispatchToProps = {
  setAppointmentDate: bookingActions.setAppointmentDate,
  setAppointmentTime: bookingActions.setAppointmentTime,
  resetAppointmentTime: bookingActions.resetAppointmentTime,
  lockTimeSlot: bookingActions.lockTimeSlot,
  getLocationTimeSlots: bookingActions.getLocationTimeSlots
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingDateAndTimeSelect);
