import React, { useEffect, useMemo } from 'react';
import { RootDispatch, RootState } from 'store/types';
import * as testResults from 'store/testResults/selectors';
import { connect } from 'react-redux';
import {
  LabHistoricalResult,
  LabResultsListItems,
  TestResultCategory,
  LabDataSource
} from 'store/testResults/types';
import { oc } from 'ts-optchain';
import { useLocation } from 'react-router-dom';
import Divider from 'components/UI/Divider';
import { MuiBox, MuiContainer, MuiPaper, MuiTypography, dfdDefaultTheme } from 'theme/material-ui';
import { Container, AnalyteTitle } from '../styled';
import Banner from 'components/UI/Banner/Banner';
import AbsoluteSpinner from 'components/common/AbsoluteSpinner/AbsoluteSpinner';
import { resetTestResultDetails } from 'store/testResults/actions';
import { getLabHistoricalCdr, getLabHistoricalMillennium } from 'store/testResults/lab/actions';
import { getLabValue, orNA } from './utils';
import { formatData } from './LabTrendLine/utils';
import MedHistoryBanner from 'screens/MedicalHistory/Banner';
import { Display } from './LabTrendLine/enums';
import { LabTrendLineChart } from 'screens/TestResults/Lab/LabTrendLine/LabTrendLineChart';
import { headingFontSize, largeFontSize, Spacing } from 'modules/styles/variables';
import { AdapterSource } from 'modules/types/common';
import Typography from 'components/UI/Typography';
import { getImageByCategory } from 'store/testResults/utils';
import Svg from 'components/UI/Svg/Svg';
import CollapsibleListItem from 'components/common/CollapsibleList/CollapsibleListItem';
import { formatShortDate } from 'modules/utils/DateUtils';
import FlexBox from 'components/UI/Layout/FlexBox';
import LabRowComments from './LabRowComments';
import { Color } from 'modules/styles/colors';
import LabRowProvider from './LabRowProvider';
import { LabComments } from './Lab';
import useBreadCrumbs from 'hooks/useBreadCrumbs';
import LabRowChange from './LabRowChange';
import useMediaQuery from '@material-ui/core/useMediaQuery';

export interface Props {
  milleniumError: null | Error;
  milleniumLoading: boolean;
  cdrError: null | Error;
  cdrLoading: boolean;
  milleniumData: LabHistoricalResult[] | null;
  cdrData: LabHistoricalResult[] | null;
  dispatch: RootDispatch;
}

const LabHistoricalScreen = (props: Props) => {
  const {
    milleniumLoading,
    cdrLoading,
    milleniumError,
    cdrError,
    milleniumData,
    cdrData,
    dispatch
  } = props;
  // Lab info
  const state = oc(useLocation()).state({});
  const resultDetail: LabResultsListItems = oc(state).resultDetail({});
  const labName = oc(resultDetail).name('');
  const loincCodes = oc(resultDetail).loincCode('');
  const panelName = oc(resultDetail).panelName('');
  const unit = oc(resultDetail).valueQuantity.unit('');
  const dataSource = oc(resultDetail).source(LabDataSource.CDR);
  const displayType = dataSource === LabDataSource.CDR ? Display.TABLE : Display.CHART;
  const adapterSource = oc(resultDetail).adapterSource(AdapterSource.UTAH);
  const data = displayType === Display.TABLE ? cdrData : milleniumData;
  const formattedData = useMemo(() => formatData(data), [data]);
  const { mergeCurrentWithPreviousBreadCrumbs } = useBreadCrumbs();
  const isSmallScreen = useMediaQuery(dfdDefaultTheme.breakpoints.down('sm'));

  useEffect(() => {
    mergeCurrentWithPreviousBreadCrumbs();
    return () => {
      dispatch(resetTestResultDetails());
    };
  }, []);

  useEffect(() => {
    if (displayType === Display.TABLE) {
      const cdrParams = {
        labName,
        panelName,
        unit
      };

      dispatch(getLabHistoricalCdr(cdrParams, adapterSource));
    } else {
      const millenniumParams = {
        labName,
        loincCodes,
        unit
      };

      dispatch(getLabHistoricalMillennium(millenniumParams, adapterSource));
    }
  }, [getLabHistoricalMillennium, getLabHistoricalCdr, displayType]);

  if (milleniumLoading || cdrLoading) {
    return <AbsoluteSpinner />;
  }

  if (
    (milleniumError && dataSource === LabDataSource.MILLENNIUM) ||
    (cdrError && dataSource === LabDataSource.CDR)
  ) {
    return (
      <MuiBox flex={1} alignItems="center" justifyContent="center" mt={Spacing.xLarge}>
        <Typography fontSize={largeFontSize} lineHeight={headingFontSize} textAlign="center">
          There was an issue fetching labs historical information
        </Typography>
      </MuiBox>
    );
  }
  const hasValues = formattedData[0]?.valueQuantity;
  const renderChart = displayType === Display.CHART && formattedData.length >= 3 && hasValues;
  const additionalComments = resultDetail?.notes;

  return (
    <MuiBox bgcolor={Color.baseColor} display="flex" flexDirection="column" flex={1}>
      <Banner message={panelName} />
      <MedHistoryBanner />
      <Divider />
      {renderChart ? (
        <MuiContainer style={{ padding: Spacing.none }} maxWidth="lg">
          <MuiBox
            component={MuiPaper}
            display="flex"
            justifyContent="center"
            alignItems="center"
            p={2}
            mt={3}
            elevation={0}
            bgcolor={Color.baseColor}
          >
            <MuiBox data-testid="lab-trendline-header" display="flex" flexDirection="column">
              <AnalyteTitle>{labName} - Historical Trendline and List</AnalyteTitle>
            </MuiBox>
            <MuiBox paddingLeft="5%" marginLeft="auto">
              <Svg set="assets" name={getImageByCategory(TestResultCategory.LAB)} size={70} />
            </MuiBox>
          </MuiBox>
        </MuiContainer>
      ) : null}
      <MuiBox my={3}>
        {renderChart ? (
          <MuiContainer
            data-testid="lab-trendline-graph"
            style={{ padding: Spacing.none }}
            maxWidth="lg"
          >
            <MuiBox bgcolor={Color.baseColor} component={MuiPaper} elevation={0} mb={2} pb={2}>
              <LabTrendLineChart data={formattedData} labName={labName} />
            </MuiBox>
          </MuiContainer>
        ) : null}
        <Container
          style={{
            paddingBottom: Spacing.none,
            paddingTop: Spacing.none,
            paddingInline: Spacing.largeXLarge,
            backgroundColor: Color.cobaltTint,
            height: 30,
            display: 'flex',
            alignItems: 'center'
          }}
          maxWidth="lg"
          data-testid="lab-historical-results"
        >
          <MuiTypography style={{ fontWeight: 'bold', marginRight: Spacing.small }}>
            {formattedData.length}
          </MuiTypography>
          <MuiTypography style={{ fontStyle: 'italic' }}>results</MuiTypography>
        </Container>
        <Container
          maxWidth="lg"
          style={{ maxHeight: 500, overflow: 'auto', marginTop: Spacing.none }}
          data-testid="lab-historical-list"
        >
          <MuiBox bgcolor={Color.baseColor} component={MuiPaper} elevation={0} mb={2}>
            {formattedData.map(item => {
              const location = item.providerLocation || '';
              const hasComments = orNA(item.notes) !== 'N/A';
              const comments = `Comments: ${orNA(item.notes)}`;
              const valueText = `| Value: ${getLabValue(item.valueQuantity, item.valueString)}`;
              const changeValue = item?.valueChange?.value;
              const changeDirection = item?.valueChange?.direction;

              return (
                <CollapsibleListItem
                  expansionPanelBackgroundColor={Color.baseColor}
                  customTitle={
                    <FlexBox flexDirection="row" width="100%">
                      {isSmallScreen ? (
                        <>
                          <FlexBox flex={1} maxWidth="20%">
                            {formatShortDate(item.issued)}
                          </FlexBox>
                          <FlexBox flex={1} maxWidth="30%" style={{ marginLeft: '10px' }}>
                            {valueText}
                          </FlexBox>
                          {changeValue ? (
                            <FlexBox flex={2} maxWidth="35%">
                              <LabRowChange value={changeValue} direction={changeDirection} />
                            </FlexBox>
                          ) : null}
                          {hasComments ? (
                            <FlexBox flexDirection="row" flex={1} maxWidth="15%">
                              <LabRowComments comments={comments} />
                            </FlexBox>
                          ) : null}
                        </>
                      ) : (
                        <>
                          <FlexBox>{formatShortDate(item.issued)}</FlexBox>
                          <FlexBox style={{ marginLeft: '10px' }}>{valueText}</FlexBox>
                          {changeValue ? (
                            <LabRowChange value={changeValue} direction={changeDirection} />
                          ) : null}
                          {hasComments ? <LabRowComments comments={comments} /> : null}
                        </>
                      )}
                    </FlexBox>
                  }
                  subtitle="Some subtitle"
                  height="50px"
                >
                  <LabRowProvider referringProvider={item.referringProvider} />
                  {location || ''}
                  {hasComments ? comments : ''}
                </CollapsibleListItem>
              );
            })}
          </MuiBox>
        </Container>
        {additionalComments ? (
          <Container maxWidth="lg" style={{ marginTop: Spacing.large }}>
            <LabComments notes={additionalComments} />
          </Container>
        ) : null}
      </MuiBox>
    </MuiBox>
  );
};

const mapStateToProps = (state: RootState) => ({
  milleniumLoading: testResults.labHistoricalMillenniumLoadingSelector(state),
  milleniumError: testResults.labHistoricalMillenniumErrorSelector(state),
  milleniumData: testResults.labHistoricalMillenniumDataSelector(state),
  cdrData: testResults.labHistoricalCdrDataSelector(state),
  cdrLoading: testResults.labHistoricalCdrLoadingSelector(state),
  cdrError: testResults.labHistoricalCdrErrorSelector(state)
});

export default connect(mapStateToProps)(LabHistoricalScreen);
