import { BasicInfoForm } from 'store/billing/financialAssistance/createApplication/types';
import dayjs from 'dayjs';
import { LOCATION_STATES } from 'modules/constants/LocationStates';
import { BasicInfoInputType, BasicInfoSection } from './types';
import { RELATIONSHIP_OPTIONS, ADDRESS_LENGTH_OPTIONS } from '../constants';

export const INIT_FORM: BasicInfoForm = {
  patientFirstName: '',
  patientMiddle: '',
  patientLastName: '',
  patientSSN: '',
  patientBirthDate: '',

  relationship: '',
  relationshipExplain: '',
  guarantorFirstName: '',
  guarantorMiddle: '',
  guarantorLastName: '',
  guarantorSSN: '',
  guarantorBirthDate: '',

  homePhone: '',
  workPhone: '',
  cellPhone: '',
  addressLineOne: '',
  addressLineTwo: '',
  city: '',
  zip: '',
  state: '',
  employer: '',

  addressLength: '',
  extra1AddressLineOne: '',
  extra1AddressLineTwo: '',
  extra1AddressCity: '',
  extra1AddressZip: '',
  extra1AddressState: '',
  extra1AddressFrom: '',
  extra1AddressTo: '',

  extra2AddressLineOne: '',
  extra2AddressLineTwo: '',
  extra2AddressCity: '',
  extra2AddressZip: '',
  extra2AddressState: '',
  extra2AddressFrom: '',
  extra2AddressTo: ''
};

export const OPTIONAL_ADDRESS = 'Previous Address';

export const BASIC_INFO_SECTIONS: BasicInfoSection[] = [
  {
    title: 'Patient Details',
    data: [
      {
        idx: 0,
        key: 'patientFirstName',
        type: BasicInfoInputType.DEFAULT,
        span: 5,
        opts: {
          edit: values => values.relationship !== 'self'
        },
        props: {
          label: 'First Name',
          placeholder: '',
          autoFocus: true,
          required: true,
          accessibilityLabel:
            'first name text input for patient (no numbers or special characters)',
          keyboardType: 'default',
          maxLength: 20,
          textContentType: 'name'
        }
      },
      {
        idx: 1,
        key: 'patientMiddle',
        type: BasicInfoInputType.DEFAULT,
        span: 2,
        opts: {
          edit: values => values.relationship !== 'self'
        },
        override: {
          value: ({ relationship, guarantorMiddle }) =>
            relationship === 'self' ? guarantorMiddle : undefined
        },
        props: {
          label: 'Middle Initial',
          placeholder: '',
          accessibilityLabel:
            'middle initial text input for patient (no numbers or special characters)',
          keyboardType: 'default',
          maxLength: 1
        }
      },
      {
        idx: 2,
        key: 'patientLastName',
        type: BasicInfoInputType.DEFAULT,
        span: 5,
        opts: {
          edit: values => values.relationship !== 'self'
        },
        props: {
          label: 'Last Name',
          placeholder: '',
          required: true,
          accessibilityLabel: 'last name text input for patient (no numbers or special characters)',
          keyboardType: 'default',
          maxLength: 20,
          textContentType: 'familyName'
        }
      },
      {
        idx: 3,
        key: 'patientSSN',
        type: BasicInfoInputType.DEFAULT,
        span: 6,
        opts: {
          edit: values => values.relationship !== 'self'
        },
        override: {
          value: ({ relationship, guarantorSSN }) =>
            relationship === 'self' ? guarantorSSN : undefined
        },
        props: {
          label: 'Social Security Number',
          placeholder: '',
          type: 'custom',
          options: {
            mask: [/[0-9]/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
          },
          accessibilityLabel: 'SSN text input for patient (only numbers)',
          keyboardType: 'number-pad',
          maxLength: 11
        }
      },
      {
        idx: 4,
        key: 'patientBirthDate',
        type: BasicInfoInputType.CALENDAR,
        opts: {
          edit: values => values.relationship !== 'self'
        },
        span: 6,
        props: {
          label: 'Date of Birth',
          placeholder: '',
          accessibilityLabel: 'birth date picker for patient',
          mode: 'date',
          required: true,
          maxDate: dayjs(new Date()).toDate(),
          openTo: 'year',
          views: ['year', 'month', 'date']
        }
      }
    ]
  },
  {
    title: 'Responsible Party',
    action: {
      label: 'Edit Profile',
      fn: 'onEditProfilePress'
    },
    data: [
      {
        idx: 5,
        key: 'relationship',
        type: BasicInfoInputType.SELECT,
        span: 12,
        opts: {
          fn: 'onRelationshipChange'
        },
        props: {
          label: 'Relationship to Patient',
          placeholder: 'Select a Relationship',
          accessibilityLabel: 'relationship to patient input picker',
          required: true,
          items: RELATIONSHIP_OPTIONS
        }
      },
      {
        idx: 6,
        key: 'relationshipExplain',
        type: BasicInfoInputType.DEFAULT,
        span: 12,
        opts: {
          hide: values => values.relationship !== 'other'
        },
        props: {
          label: 'Explain Relationship',
          placeholder: 'If "Other", please explain',
          accessibilityLabel: 'explain relationship text input if other',
          keyboardType: 'default',
          required: true
        }
      },
      {
        idx: 7,
        key: 'guarantorFirstName',
        type: BasicInfoInputType.DEFAULT,
        opts: {
          hide: values => !values.relationship,
          edit: (_, prefill) => !prefill.firstName
        },
        span: 5,
        props: {
          label: 'First Name',
          placeholder: '',
          required: true,
          accessibilityLabel:
            'first name text input for guarantor (no numbers or special characters)',
          keyboardType: 'default',
          textContentType: 'name'
        }
      },
      {
        idx: 8,
        key: 'guarantorMiddle',
        type: BasicInfoInputType.DEFAULT,
        opts: {
          hide: values => !values.relationship
        },
        span: 2,
        props: {
          label: 'Middle Initial',
          placeholder: '',
          accessibilityLabel:
            'middle Initial text input for guarantor (no numbers or special characters)',
          keyboardType: 'default',
          maxLength: 1
        }
      },
      {
        idx: 9,
        key: 'guarantorLastName',
        type: BasicInfoInputType.DEFAULT,
        opts: {
          hide: values => !values.relationship,
          edit: (_, prefill) => !prefill.lastName
        },
        span: 5,
        props: {
          label: 'Last Name',
          placeholder: '',
          required: true,
          accessibilityLabel:
            'last name text input for guarantor (no numbers or special characters)',
          keyboardType: 'default',
          textContentType: 'familyName'
        }
      },
      {
        idx: 10,
        key: 'guarantorBirthDate',
        type: BasicInfoInputType.CALENDAR,
        opts: {
          edit: (_, prefill) => !prefill.dateOfBirth
        },
        span: 6,
        props: {
          label: 'Date of Birth',
          placeholder: '',
          accessibilityLabel: 'birth date picker for guarantor',
          mode: 'date',
          required: true,
          maxDate: dayjs(new Date())
            .subtract(18, 'years')
            .add(1, 'days')
            .toDate()
        }
      },
      {
        idx: 11,
        key: 'guarantorSSN',
        type: BasicInfoInputType.DEFAULT,
        span: 6,
        props: {
          label: 'Social Security Number',
          placeholder: '',
          type: 'custom',
          options: {
            mask: [/[0-9]/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
          },
          accessibilityLabel: 'SSN text input for guarantor (only numbers)',
          keyboardType: 'number-pad',
          maxLength: 11
        }
      },
      {
        idx: 12,
        key: 'homePhone',
        type: BasicInfoInputType.DEFAULT,
        opts: {
          edit: (_, prefill) => !prefill.homePhone
        },
        span: 4,
        props: {
          label: 'Home Phone Number',
          placeholder: '',
          required: true,
          type: 'custom',
          options: {
            mask: [
              '(',
              /[1-9]/,
              /\d/,
              /\d/,
              ')',
              ' ',
              /\d/,
              /\d/,
              /\d/,
              '-',
              /\d/,
              /\d/,
              /\d/,
              /\d/
            ]
          },
          accessibilityLabel: 'home phone text input (only numbers)',
          keyboardType: 'number-pad',
          maxLength: 14
        }
      },
      {
        idx: 13,
        key: 'workPhone',
        type: BasicInfoInputType.DEFAULT,
        opts: {
          edit: (_, prefill) => !prefill.workPhone
        },
        span: 4,
        props: {
          label: 'Work Phone Number',
          placeholder: '',
          type: 'custom',
          options: {
            mask: [
              '(',
              /[1-9]/,
              /\d/,
              /\d/,
              ')',
              ' ',
              /\d/,
              /\d/,
              /\d/,
              '-',
              /\d/,
              /\d/,
              /\d/,
              /\d/
            ]
          },
          accessibilityLabel: 'work phone text input',
          keyboardType: 'number-pad',
          maxLength: 14
        }
      },
      {
        idx: 14,
        key: 'cellPhone',
        type: BasicInfoInputType.DEFAULT,
        span: 4,
        props: {
          label: 'Cell Phone Number',
          placeholder: '',
          type: 'custom',
          options: {
            mask: [
              '(',
              /[1-9]/,
              /\d/,
              /\d/,
              ')',
              ' ',
              /\d/,
              /\d/,
              /\d/,
              '-',
              /\d/,
              /\d/,
              /\d/,
              /\d/
            ]
          },
          accessibilityLabel: 'cell phone text input (only numbers)',
          keyboardType: 'number-pad',
          maxLength: 14
        }
      },
      {
        idx: 15,
        key: 'addressLineOne',
        type: BasicInfoInputType.DEFAULT,
        opts: {
          edit: (_, prefill) => !prefill.addressLineOne
        },
        props: {
          label: 'Street Address Line 1',
          placeholder: '',
          accessibilityLabel: 'address line one text input',
          required: true,
          textContentType: 'streetAddressLine1',
          maxLength: 30,
          keyboardType: 'default'
        }
      },
      {
        idx: 16,
        key: 'addressLineTwo',
        type: BasicInfoInputType.DEFAULT,
        opts: {
          edit: (_, prefill) => !prefill.addressLineTwo
        },
        props: {
          label: 'Street Address Line 2',
          placeholder: '',
          accessibilityLabel: 'address line two text input',
          textContentType: 'streetAddressLine2',
          maxLength: 20,
          keyboardType: 'default'
        }
      },
      {
        idx: 17,
        key: 'city',
        type: BasicInfoInputType.DEFAULT,
        opts: {
          edit: (_, prefill) => !prefill.city
        },
        span: 6,
        props: {
          label: 'City',
          placeholder: '',
          accessibilityLabel: 'city text input (no numbers or special characters)',
          required: true,
          textContentType: 'addressCity',
          keyboardType: 'default'
        }
      },
      {
        idx: 18,
        key: 'state',
        type: BasicInfoInputType.SELECT,
        opts: {
          edit: (_, prefill) => !prefill.state
        },
        span: 3,
        props: {
          label: 'State',
          placeholder: 'Select a State',
          accessibilityLabel: 'state input picker',
          required: true,
          items: LOCATION_STATES
        }
      },
      {
        idx: 19,
        key: 'zip',
        type: BasicInfoInputType.DEFAULT,
        opts: {
          edit: (_, prefill) => !prefill.zip
        },
        override: {
          mask: ({ zip }) =>
            zip.length > 5
              ? [/[1-9]/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
              : undefined
        },
        span: 3,
        props: {
          label: 'Zip Code',
          type: 'custom',
          options: {
            mask: [/[1-9]/, /\d/, /\d/, /\d/, /\d/, /\d/]
          },
          placeholder: '',
          accessibilityLabel: 'zip code text input (only numbers)',
          required: true,
          textContentType: 'postalCode',
          keyboardType: 'number-pad',
          maxLength: 10
        }
      },
      {
        idx: 20,
        key: 'employer',
        type: BasicInfoInputType.DEFAULT,
        props: {
          label: 'Employer Name',
          placeholder: 'Employer Name',
          accessibilityLabel: 'employer name text input',
          keyboardType: 'default',
          maxLength: 20,
          textContentType: 'organizationName'
        }
      },
      {
        idx: 21,
        key: 'addressLength',
        type: BasicInfoInputType.SELECT,
        opts: {
          last: values => values.addressLength === '4',
          fn: 'onAddressLengthChange'
        },
        props: {
          label: 'Duration Lived at Address',
          placeholder: 'Select a Duration',
          items: ADDRESS_LENGTH_OPTIONS,
          accessibilityLabel: 'time at current address input picker',
          required: true
        }
      }
    ]
  },
  {
    title: 'Previous Address One',
    hide: values => values.addressLength !== '1',
    data: [
      {
        idx: 22,
        key: 'extra1AddressLineOne',
        type: BasicInfoInputType.DEFAULT,
        props: {
          label: 'Street Address Line 1',
          placeholder: '',
          accessibilityLabel: 'address line one text input, for extra address one',
          required: true,
          textContentType: 'streetAddressLine1',
          maxLength: 30,
          keyboardType: 'default'
        }
      },
      {
        idx: 23,
        key: 'extra1AddressLineTwo',
        type: BasicInfoInputType.DEFAULT,
        props: {
          label: 'Street Address Line 2',
          placeholder: '',
          accessibilityLabel: 'address line two text input, for extra address one',
          textContentType: 'streetAddressLine2',
          maxLength: 20,
          keyboardType: 'default'
        }
      },
      {
        idx: 24,
        key: 'extra1AddressCity',
        type: BasicInfoInputType.DEFAULT,
        span: 6,
        props: {
          label: 'City',
          placeholder: '',
          accessibilityLabel:
            'city text input, for extra address one (no numbers or special characters)',
          required: true,
          textContentType: 'addressCity',
          keyboardType: 'default'
        }
      },
      {
        idx: 25,
        key: 'extra1AddressState',
        type: BasicInfoInputType.SELECT,
        span: 3,
        props: {
          label: 'State',
          placeholder: 'Select a State',
          accessibilityLabel: 'state input picker, for extra address one',
          required: true,
          items: LOCATION_STATES
        }
      },
      {
        idx: 26,
        key: 'extra1AddressZip',
        type: BasicInfoInputType.DEFAULT,
        override: {
          mask: ({ extra1AddressZip }) =>
            extra1AddressZip.length > 5
              ? [/[1-9]/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
              : undefined
        },
        span: 3,
        props: {
          label: 'Zip Code',
          type: 'custom',
          options: {
            mask: [/[1-9]/, /\d/, /\d/, /\d/, /\d/, /\d/]
          },
          placeholder: '',
          accessibilityLabel: 'zip code text input, for extra address one (only numbers)',
          required: true,
          textContentType: 'postalCode',
          keyboardType: 'number-pad',
          maxLength: 10
        }
      },

      {
        idx: 27,
        key: 'extra1AddressFrom',
        type: BasicInfoInputType.CALENDAR,
        override: {
          maxDate: values => values.extra1AddressTo
        },
        props: {
          label: 'From Date',
          placeholder: '',
          accessibilityLabel: 'living start date text input, for extra address one, format MM/YYYY',
          required: true,
          maxDate: new Date()
        }
      },
      {
        idx: 28,
        key: 'extra1AddressTo',
        type: BasicInfoInputType.CALENDAR,
        opts: {
          last: (_, expanded) => !expanded
        },
        override: {
          minDate: values => values.extra2AddressFrom
        },
        props: {
          label: 'To Date',
          placeholder: '',
          accessibilityLabel: 'living end date text input, for extra address one, format MM/YYYY',
          required: true,
          maxDate: new Date()
        }
      }
    ]
  },
  {
    title: 'Previous Address Two',
    hide: (values, expanded) => values.addressLength !== '1' || !expanded,
    data: [
      {
        idx: 29,
        key: 'extra2AddressLineOne',
        type: BasicInfoInputType.DEFAULT,
        props: {
          label: 'Street Address Line 1',
          placeholder: '',
          accessibilityLabel: 'address line one text input, for extra address one',
          required: true,
          textContentType: 'streetAddressLine1',
          maxLength: 30,
          keyboardType: 'default'
        }
      },
      {
        idx: 30,
        key: 'extra2AddressLineTwo',
        type: BasicInfoInputType.DEFAULT,
        props: {
          label: 'Street Address Line 2',
          placeholder: '',
          accessibilityLabel: 'address line two text input, for extra address two',
          textContentType: 'streetAddressLine2',
          maxLength: 20,
          keyboardType: 'default'
        }
      },
      {
        idx: 31,
        key: 'extra2AddressCity',
        type: BasicInfoInputType.DEFAULT,
        span: 6,
        props: {
          label: 'City',
          placeholder: '',
          accessibilityLabel:
            'city text input, for extra address two (no numbers or special characters)',
          required: true,
          textContentType: 'addressCity',
          keyboardType: 'default'
        }
      },

      {
        idx: 32,
        key: 'extra2AddressState',
        type: BasicInfoInputType.SELECT,
        span: 3,
        props: {
          label: 'State',
          placeholder: 'Select a State',
          accessibilityLabel: 'state input picker, for extra address two',
          required: true,
          items: LOCATION_STATES
        }
      },
      {
        idx: 33,
        key: 'extra2AddressZip',
        type: BasicInfoInputType.DEFAULT,
        span: 3,
        override: {
          mask: ({ extra1AddressZip }) =>
            extra1AddressZip.length > 5
              ? [/[1-9]/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
              : undefined
        },
        props: {
          label: 'Zip Code',
          type: 'custom',
          options: {
            mask: [/[1-9]/, /\d/, /\d/, /\d/, /\d/, /\d/]
          },
          placeholder: '',
          accessibilityLabel: 'zip code text input, for extra address two (only numbers)',
          required: true,
          textContentType: 'postalCode',
          keyboardType: 'number-pad',
          maxLength: 10
        }
      },
      {
        idx: 34,
        key: 'extra2AddressFrom',
        type: BasicInfoInputType.CALENDAR,
        override: {
          maxDate: values => values.extra1AddressTo
        },
        props: {
          label: 'From Date',
          placeholder: '',
          accessibilityLabel: 'living start date text input, for extra address two, format MM/YYYY',
          required: true,
          maxDate: new Date()
        }
      },
      {
        idx: 35,
        key: 'extra2AddressTo',
        type: BasicInfoInputType.CALENDAR,
        opts: {
          last: () => true
        },
        override: {
          minDate: values => values.extra2AddressFrom
        },
        props: {
          label: 'To Date',
          placeholder: '',
          accessibilityLabel: 'living end date text input, for extra address two, format MM/YYYY',
          required: true,
          maxDate: new Date()
        }
      }
    ]
  }
];
