import styled from 'styled-components';

interface SectionProps {
  flexEnd?: boolean;
  flexDirection?: string;
}

const Section = styled.div`
  justify-content: ${(props: SectionProps) => (props.flexEnd ? 'flex-end' : 'flex-start')};
  padding: 20px;
  width: 100%;
  flex: 1 1 auto;
  flex-direction: ${(props: SectionProps) =>
    props.flexDirection ? props.flexDirection : 'column'};
`;

export default Section;
