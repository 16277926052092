import Config from 'react-native-config';
import { TRIAGE_ROUTES } from 'screens/TriageGyant/constants';

const GUEST_COVID_TEST_SCREEN = Config.GUEST_COVID_TEST_SCREEN === 'enabled';

export const benefitList = [
  {
    title: 'Personalized care',
    body: 'See all your health info at a glance'
  },
  {
    title: 'Notifications',
    body:
      'Receive alerts about new medical info available, new messages from your provider, and more'
  },
  {
    title: 'Access to your doctors',
    body: 'Connect with your past providers'
  }
];

const getGuestHomeMenu = () => {
  const guestHome = [
    {
      title: 'Find a Doctor',
      subTitle: 'Search location, specialty, and more',
      route: '/find-a-doctor',
      icon: 'WebGetCareNow'
    },
    {
      title: 'Check Your Symptoms',
      subTitle: 'Chat with our friendly symptom checker',
      route: TRIAGE_ROUTES.TERMS,
      icon: 'Scout'
    },
    {
      title: 'Pay Bill as a Guest',
      subTitle: 'Pay without registering',
      route: 'VisitPayModal',
      icon: 'SNavBilling'
    }
  ];

  if (GUEST_COVID_TEST_SCREEN) {
    guestHome.push({
      title: 'Request COVID-19 Test',
      subTitle: 'Screening for COVID-19 Testing',
      route: '/guest-home/covid-screen/consent-and-agreement',
      icon: 'CovidIcon'
    });
  }

  return guestHome;
};

export const guestHomeMenu = getGuestHomeMenu();

export const GUEST_ROUTES = {
  GUEST_HOME: '/guest-home'
};
