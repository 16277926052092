import React, { ComponentType } from 'react';
import { StyledScreen } from 'components/common/PatientLoader/styled';
import { MuiBox, MuiContainer, MuiTypography } from 'theme/material-ui';
import { FontSize, FontWeight } from 'modules/styles/variables';
import { connect } from 'react-redux';
import { RootState, RootDispatch } from 'store/types';
import { CovidTitles } from 'lib/constants/covidScreening';
import { AnalyticsEvent } from 'services/AnalyticsService';
import useNavigationAnalytics from 'hooks/useNavigationAnalytics';
import Spacer from 'components/UI/Layout/Spacer';
import Footer from 'components/common/Footer';
import { patientSelect } from 'modules/constants/covidScreening';
import { CovidTestButton } from '../Components/CovidTestButton';
import { CovidScreeningQuestionnaireSelector } from 'store/CovidScreening/selectors';

import useStyles from '../Components/CovidTestButton/useStyles';
import { history } from 'lib/history';
import Box from '@material-ui/core/Box';
import {
  COVID_SYMPTOMS,
  COVID_REASONS_CHECKBOX,
  CovidReasonsBooleanEnums
} from 'lib/covidScreening/types';
import Checkbox from '@material-ui/core/Checkbox';
import { FlatList } from 'react-native';
import { Formik } from 'formik';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Color } from 'modules/styles/colors';
import * as Yup from 'yup';
import * as covidActions from 'store/CovidScreening/actions';
import { Questionnaire } from 'store/CovidScreening/types';

import { StyledListItem, StyledUL, StyledMainList, StyledMainUL } from '../Components/styled';
import { convertToLowerKabobCase } from 'modules/utils/StringUtils';

interface ListItem {
  title: string;
  subItems: { title: string }[];
}

interface CovidSymptomsProps {
  questionnaire: Questionnaire;
  setQuestionnaire: typeof covidActions.setQuestionnaire;
  dispatch: RootDispatch;
}

export const CovidReasons = ({ questionnaire, setQuestionnaire }: CovidSymptomsProps) => {
  const consentValidationSchema = Yup.object().shape({});
  const classes = useStyles();
  const covidReasonsPrevBtn = useNavigationAnalytics(AnalyticsEvent.CovidPrev);
  const covidReasonsNextBtn = useNavigationAnalytics(AnalyticsEvent.CovidNext);

  const navigateToPersonalInfo = '/u/get-care-now/covid-screen/personal-information';
  const navigateToCurrentSymptoms = '/u/get-care-now/covid-screen/current-symptoms';

  const getFieldValue = () => {
    const reduxFieldValues = {
      covidSymptoms: questionnaire.Q_Covid_Symptoms_Primary,
      closeContact: questionnaire.Q_COVID_Screen_Close_Contact,
      schoolRelated: questionnaire.Q_COVID_Healthcare_Worker_HD_residence,
      workRelated: questionnaire.Q_Covid19_Prescreening,
      travelRelated: questionnaire.Q_Covid19_Prescreening,
      highDensityResidence: questionnaire.Q_COVID_Screen_High_Density_Residence
    };
    const filtered = Object.entries(reduxFieldValues)
      .filter(([key, value]) => value === 'Yes')
      .map(([key]) => key);
    const initialValues = {
      covidReasonsChecked: filtered || []
    };
    return initialValues;
  };

  const saveToStore = values => {
    const symptomsQuestionnaire = {
      Q_Covid_Symptoms_Primary: 'No',
      Q_COVID_Screen_Close_Contact: 'No',
      Q_COVID_Healthcare_Worker_HD_residence: 'No',
      Q_Covid19_Prescreening: 'No',
      Q_COVID_Screen_High_Density_Residence: 'No'
    };

    if (values.covidReasonsChecked.includes(CovidReasonsBooleanEnums.covidSymptoms)) {
      symptomsQuestionnaire.Q_Covid_Symptoms_Primary = 'Yes';
    }
    if (values.covidReasonsChecked.includes(CovidReasonsBooleanEnums.closeContact)) {
      symptomsQuestionnaire.Q_COVID_Screen_Close_Contact = 'Yes';
    }
    if (values.covidReasonsChecked.includes(CovidReasonsBooleanEnums.schoolRelated)) {
      symptomsQuestionnaire.Q_COVID_Healthcare_Worker_HD_residence = 'Yes';
    }
    if (
      values.covidReasonsChecked.includes(CovidReasonsBooleanEnums.workRelated) ||
      values.covidReasonsChecked.includes(CovidReasonsBooleanEnums.travelRelated)
    ) {
      symptomsQuestionnaire.Q_Covid19_Prescreening = 'Yes';
    }
    if (values.covidReasonsChecked.includes(CovidReasonsBooleanEnums.highDensityResidence)) {
      symptomsQuestionnaire.Q_COVID_Screen_High_Density_Residence = 'Yes';
    }
    const questionnaireObject = {
      ...questionnaire,
      ...symptomsQuestionnaire
    };
    setQuestionnaire(questionnaireObject);

    covidReasonsNextBtn({
      symptomsQuestionnaire,
      title: CovidTitles.REASON_FOR_REQUEST
    });
  };

  const handleSubmit = values => {
    saveToStore(values);
    if (values.covidReasonsChecked.includes(CovidReasonsBooleanEnums.covidSymptoms)) {
      history.push(navigateToCurrentSymptoms);
    } else {
      history.push(navigateToPersonalInfo);
    }
  };

  const onCancelClick = values => {
    saveToStore(values);
    covidReasonsPrevBtn({ title: CovidTitles.REASON_FOR_REQUEST });
    history.goBack();
  };

  const renderList = (item: ListItem) => (
    <StyledMainList key={item.title}>
      {item.title}
      {item.subItems && (
        <StyledUL>
          {item.subItems.map(subItem => (
            <MuiTypography key={subItem.title}>{subItem.title}</MuiTypography>
          ))}
        </StyledUL>
      )}
    </StyledMainList>
  );

  return (
    <>
      <StyledScreen>
        <Formik
          enableReinitialize="true"
          initialValues={getFieldValue()}
          validationSchema={consentValidationSchema}
          isInitialValid={false}
          onSubmit={values => handleSubmit(values)}
        >
          {({ values, setFieldTouched, setFieldValue, handleSubmit }) => {
            return (
              <MuiContainer maxWidth="lg">
                <MuiBox my={3}>
                  <MuiBox mb={1}>
                    <MuiTypography fontSize={FontSize.base} fontWeight={FontWeight.semibold}>
                      You should go to an InstaCare IF you feel short of breath, including when
                      walking. You should call 911 right away IF you are experiencing potential
                      life-threatening symptoms which include:
                    </MuiTypography>
                  </MuiBox>
                  <Box mb={3}>
                    <StyledMainUL>
                      <StyledListItem>
                        <MuiTypography>
                          {COVID_SYMPTOMS.map(item => renderList(item))}
                        </MuiTypography>
                      </StyledListItem>
                    </StyledMainUL>
                  </Box>
                  <Spacer size="small" />
                  <MuiBox mb={1}>
                    <MuiTypography>STOP and read the items below carefully:</MuiTypography>
                  </MuiBox>
                </MuiBox>
                <Box mb={3}>
                  <StyledMainUL>
                    <StyledListItem>
                      <MuiTypography>
                        {' '}
                        This screening is ONLY for individuals that can produce saliva (drool). If
                        you need a COVID-19 test other than saliva please contact your primary care
                        provider or if you are in Utah visit{' '}
                        <a
                          href="https://coronavirus.utah.gov/covid-testing-locations-list"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          https://coronavirus.utah.gov/covid-testing-locations-list.
                        </a>{' '}
                        In Idaho you may proceed with the survey.
                      </MuiTypography>
                    </StyledListItem>
                    <StyledListItem>
                      <MuiTypography>
                        {' '}
                        If your child is less than three months and having symptoms, call your
                        pediatrician or visit an Intermountain InstaCare/KidsCare for evaluation.
                      </MuiTypography>
                    </StyledListItem>
                    <StyledListItem>
                      <MuiTypography>
                        {' '}
                        If you are having a baby or a procedure at an Intermountain facility you do
                        NOT need to create an order here. Your doctor will order you a COVID-19
                        test.
                      </MuiTypography>
                    </StyledListItem>
                  </StyledMainUL>
                </Box>
                <Spacer size="small" />
                <MuiBox mb={1}>
                  <MuiTypography display="inline">
                    <MuiTypography display="inline" color={Color.red}>
                      *
                    </MuiTypography>
                    Please select your reason(s) for requesting a COVID-19 test:
                  </MuiTypography>
                </MuiBox>
                <FlatList
                  ItemSeparatorComponent={() => <Spacer size="small" />}
                  data={COVID_REASONS_CHECKBOX}
                  keyExtractor={item => `${item.key}`}
                  renderItem={({ item }) => {
                    const checkedValues = values?.covidReasonsChecked || [];
                    return (
                      <>
                        <Box px={4}>
                          <Box>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  data-testid={convertToLowerKabobCase(
                                    item.fieldName,
                                    '-form-checkbox'
                                  )}
                                  color="primary"
                                  value={item.fieldName}
                                  checked={checkedValues.includes(item.fieldName)}
                                  onChange={() => {
                                    setFieldTouched('covidReasonsChecked');
                                    setFieldValue(
                                      'covidReasonsChecked',
                                      checkedValues.includes(item.fieldName)
                                        ? checkedValues.filter(i => i !== item.fieldName)
                                        : checkedValues.concat(item.fieldName)
                                    );
                                  }}
                                />
                              }
                              label={item.label}
                            />
                          </Box>
                        </Box>
                      </>
                    );
                  }}
                />
                <MuiBox className={classes.steppersFooter}>
                  <CovidTestButton
                    disabled={values.covidReasonsChecked.length === 0}
                    fullWidth
                    data-testid="covid-reasons-next-button"
                    variant="contained"
                    onClick={() => handleSubmit(values)}
                  >
                    Next
                  </CovidTestButton>
                  <CovidTestButton
                    fullWidth
                    variant="text"
                    data-testid="covid-reasons-previous-button"
                    onClick={() => onCancelClick(values)}
                  >
                    Previous
                  </CovidTestButton>
                </MuiBox>
              </MuiContainer>
            );
          }}
        </Formik>
      </StyledScreen>
      <Footer
        headerText={patientSelect.GET_PATIENTS.HELPLINE.CovidInfo}
        link={patientSelect.GET_PATIENTS.HELPLINE.CovidPatientSelectHelpline}
        linkName={patientSelect.GET_PATIENTS.HELPLINE.CovidPatientSelectHelpline}
        helplineHours={patientSelect.GET_PATIENTS.HELPLINE.CovidHelplineHours}
        title={CovidTitles.REASON_FOR_REQUEST}
        authenticated
      />
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  questionnaire: CovidScreeningQuestionnaireSelector(state)
});

const mapDispatchToProps = {
  setQuestionnaire: covidActions.setQuestionnaire
};

export default connect(mapStateToProps, mapDispatchToProps)(CovidReasons as ComponentType);
