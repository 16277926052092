import useMediaQuery from '@material-ui/core/useMediaQuery';
import SupergraphicBackground from 'components/common/SupergraphicBackground/SupergraphicBackground';
import SupergraphicCopyright from 'components/common/SupergraphicBackground/SupergraphicCopyright';

import Spacer from 'components/UI/Layout/Spacer';
import Spinner from 'components/UI/Spinner/Spinner';
import { Color } from 'modules/styles/colors';
import { FontSize } from 'modules/styles/variables.web';
import React from 'react';
import { useSelector } from 'react-redux';
import { isFetchingDependenciesSelector } from 'store/global/selectors';
import { dfdDefaultTheme, MuiBox, MuiTypography } from 'theme/material-ui';

function Loading() {
  const isFetchingDependencies = useSelector(isFetchingDependenciesSelector);
  const isSmallScreen = useMediaQuery(dfdDefaultTheme.breakpoints.down('sm'));

  return (
    <SupergraphicBackground isSmallScreen={isSmallScreen}>
      <MuiBox
        display="flex"
        flexDirection="column"
        justifyContent="flex-end"
        alignItems="center"
        height="100%"
      >
        <MuiBox justifyContent="center" alignItems="center" textAlign="center" pb={16}>
          <MuiTypography fontSize={FontSize.large} color={Color.white}>
            {isFetchingDependencies ? 'Loading your profile' : 'Loading'}
          </MuiTypography>
          <Spacer size="medium" />
          <Spinner />
        </MuiBox>
        <SupergraphicCopyright isSmallScreen={isSmallScreen} />
      </MuiBox>
    </SupergraphicBackground>
  );
}

export default Loading;
