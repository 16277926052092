import { RouteData } from 'screens/navigation';

import ProxyFormScreen from '../ProxyFormScreen';
import ProxyInfoScreen from '../ProxyInfoScreen';
import ProxyReviewContactScreen from '../ProxyReviewContactScreen';
import ProxyReviewInfoScreen from '../ProxyReviewInfoScreen';

export const ProxyFormNav = {
  PROXY_INFO: '/u/add-account',
  PROXY_FORM: '/u/add-account/request-access',
  PROXY_REVIEW: '/u/add-account/request-access/review',
  PROXY_REVIEW_CONTACT: '/u/add-account/request-access/review/contact'
};

const title = 'Request Access to Minor';
const proxyFormRoutes: RouteData[] = [
  {
    path: ProxyFormNav.PROXY_INFO,
    exact: true,
    component: ProxyInfoScreen,
    componentName: 'ProxyInfo',
    crumbLabel: 'Start',
    title
  },
  {
    path: ProxyFormNav.PROXY_FORM,
    component: ProxyFormScreen,
    componentName: 'ProxyForm',
    crumbLabel: 'Request',
    title,
    exact: true
  },
  {
    path: ProxyFormNav.PROXY_REVIEW,
    component: ProxyReviewInfoScreen,
    exact: true,
    componentName: 'ProxyReviewInfoScreen',
    crumbLabel: 'Review',
    title
  },
  {
    path: ProxyFormNav.PROXY_REVIEW_CONTACT,
    component: ProxyReviewContactScreen,
    componentName: 'ProxyReviewContact',
    crumbLabel: 'Submit',
    title
  }
];

export default proxyFormRoutes;
