import React, { useMemo, useState, useRef, useEffect, HTMLProps } from 'react';
import dayjs from 'dayjs';
import { Color } from 'modules/styles/colors';
import { IconSize } from 'modules/styles/variables';
import { waitingRoom } from 'modules/constants/amwell';

import { Svg, SvgButton } from 'components/UI/Svg';
import {
  VideoContainer,
  Video,
  VideoControls,
  VideoBackdrop,
  Slider,
  ProgressText,
  VolumeContainer,
  VideoControlRow,
  SubtitlesContainer
} from './styled';

import { MuiMenuItem, MuiPaper } from 'theme/material-ui';

import engSubs from 'assets/ConnectCare/cc-waiting-english.vtt';
import spaSubs from 'assets/ConnectCare/cc-waiting-spanish.vtt';

export interface VideoPlayerControls {
  includeProgress?: boolean;
  includePlayPause?: boolean;
  includeVolume?: boolean;
  collapsible?: boolean;
  subtitles?: boolean;
}

export interface VideoPlayerProps extends HTMLProps<HTMLVideoElement> {
  src: string;
  loop?: boolean;
  autoPlay?: boolean;
  readonly?: boolean;
  poster?: string;
  seekable?: boolean;
  defaultMuted?: boolean;
  onPlay?: (e: React.SyntheticEvent<HTMLVideoElement, Event>) => void;
  onPause?: (e: React.SyntheticEvent<HTMLVideoElement, Event>) => void;
  onEnd?: (e: React.SyntheticEvent<HTMLVideoElement, Event>) => void;
  onError?: (e: React.SyntheticEvent<HTMLVideoElement, Event>) => void;
  onTimeChange?: (e: React.SyntheticEvent<HTMLVideoElement, Event>) => void;
  controls?: VideoPlayerControls;
  subtitleSource?: string;
  showSubtitle?: boolean;
}

const subtitleOptions = waitingRoom.WAITING_ROOM_VIDEO.SUBTITLE_OPTIONS;

const addSeconds = (date, seconds) => {
  date.setSeconds(date.getSeconds() + seconds);
  return date;
};

const setDuration = (seconds: number) => {
  const date = dayjs()
    .set('hour', 0)
    .set('minute', 0o0)
    .set('second', 0o0);
  const updatedDate = addSeconds(new Date(date), seconds);
  return seconds < 3600 ? dayjs(updatedDate).format('m:ss') : dayjs(updatedDate).format('h:mm:ss');
};

const setControlsDisabled = (controls?: VideoPlayerControls) => {
  return controls ? Object.values(controls).every(control => !control) : true;
};

// TODO: refactor this component to use custom hooks and subcomponents
function VideoPlayer({
  src,
  autoPlay,
  loop,
  readonly,
  poster,
  defaultMuted,
  seekable,
  onPlay,
  onPause,
  onEnd,
  onError,
  onTimeChange,
  controls,
  showSubtitle,
  ...props
}: VideoPlayerProps) {
  const videoRef = useRef<HTMLVideoElement>(null);

  const [paused, setPaused] = useState(false);
  const [muted, setMuted] = useState(false);

  const [max, setMax] = useState(0);
  const [remaining, setRemaining] = useState(0);
  const [volume, setVolume] = useState(0);

  const [fade, setFade] = useState(false);
  const timeoutId = useRef<number>();

  const [manageSubtitleOptions, setManageSubtitleOptions] = useState(false);
  const [subtitleSource, setSubtitleSource] = useState(engSubs);

  const openCloseSubtitleOptions = () =>
    setManageSubtitleOptions(prevSubtitleOptions => !prevSubtitleOptions);

  const handleCloseSubtitleOptions = (option: string) => {
    if (typeof option === 'string') {
      // prevents re-starting video with the same selected subtitle option
      if (option === 'Español' && !subtitleSource.includes('spanish')) {
        setSubtitleSource(spaSubs);
      } else if (option === 'English' && !subtitleSource.includes('english')) {
        setSubtitleSource(engSubs);
      } else {
        openCloseSubtitleOptions();
        return;
      }

      const video = videoRef.current;
      if (video) {
        video.pause();
        video.currentTime = 0;
        video.load();
      }
    }
    setManageSubtitleOptions(false);
  };

  const remainingDuration = useMemo(() => {
    return setDuration(remaining);
  }, [remaining]);

  const maxDuration = useMemo(() => {
    return setDuration(max);
  }, [max]);

  const controlsDisabled = useMemo(() => {
    return setControlsDisabled(controls);
  }, [controls]);

  useEffect(() => {
    setPaused(!autoPlay);
  }, [autoPlay]);

  const onLoadedMetadata = (e: React.SyntheticEvent<HTMLVideoElement, Event>) => {
    const { currentTarget: video } = e;

    setMax(video.duration);
    setVolume(video.volume);
    setMuted(video.muted);

    if (autoPlay) {
      video.play().catch(() => {
        onError?.(e);
        setPaused(true);
      });
    }

    const track = video?.textTracks;
    if (track?.[0]?.mode) {
      // We switch this off and on to clear previous textTracks when switching languages
      track[0].mode = 'disabled';
      track[0].mode = 'showing';
    }
  };

  const onTimeUpdate = (e: React.SyntheticEvent<HTMLVideoElement, Event>) => {
    setRemaining(e.currentTarget.currentTime);
    onTimeChange?.(e);
  };

  const onEnded = (e: React.SyntheticEvent<HTMLVideoElement, Event>) => {
    if (onEnd) {
      onEnd(e);
    }

    e.currentTarget.pause();
    e.currentTarget.currentTime = 0;
    setRemaining(0);

    if (loop) {
      e.currentTarget.play();
    }
  };

  const onPlayPause = () => {
    const video = videoRef.current;

    if (video) {
      if (video.paused || video.ended) {
        setPaused(false);
        video.play();
      } else {
        setPaused(true);
        video.pause();
      }
    }
  };

  const onFade = () => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }
    setFade(true);
    timeoutId.current = setTimeout(() => {
      setFade(false);
    }, 250);
  };

  const onVideoClick = (playPauseHandler: typeof onPlayPause) => () => {
    if (readonly) return;

    playPauseHandler();
    onFade();
  };

  const onProgressChange = (_: React.ChangeEvent<{}>, newValue: number | number[]) => {
    const value = newValue as number;
    const video = videoRef.current;

    if (video) {
      if (!video.paused && !seekable) {
        setPaused(true);
        video.pause();
      }
      video.currentTime = value;
      setRemaining(value);
    }
  };

  const onVolumeChange = (_: React.ChangeEvent<{}>, newValue: number | number[]) => {
    const video = videoRef.current;

    if (video) {
      const value = Math.floor((newValue as number) * 10) / 10;
      if (value === 0) {
        setMuted(true);
      } else {
        setMuted(false);
      }
      setVolume(value);
      video.volume = value;
    }
  };

  const onVolumeClick = () => {
    const video = videoRef.current;

    if (video) {
      setVolume(video.muted ? video.volume : 0);
      setMuted(!video.muted);
      video.muted = !video.muted;
    }
  };

  // setting start volume value to 20%
  useEffect(() => {
    onVolumeChange(({} as unknown) as React.ChangeEvent, 0.2);
  }, []);

  return (
    <VideoContainer collapsible={controls?.collapsible}>
      <Video
        data-testid="video"
        playsinline
        src={src}
        ref={videoRef}
        poster={poster}
        onPlay={onPlay}
        onPause={onPause}
        onError={onError}
        onEnded={onEnded}
        muted={defaultMuted}
        onTimeUpdate={onTimeUpdate}
        onLoadedMetadata={onLoadedMetadata}
        {...props}
      >
        {showSubtitle ? (
          <track
            id="english-track"
            kind="subtitles"
            src={subtitleSource}
            srcLang={subtitleSource.includes('english') ? 'en' : 'es'}
            default
          />
        ) : null}
      </Video>

      <VideoBackdrop open fade={fade} onClick={onVideoClick(onPlayPause)}>
        <Svg
          set="material"
          name={paused ? 'pause' : 'play-arrow'}
          size={64}
          color={Color.white}
          data-testid="video button"
        />
      </VideoBackdrop>

      {!controlsDisabled ? (
        <VideoControls>
          {controls?.includeProgress ? (
            <Slider
              max={max}
              min={0}
              color="primary"
              disabled={!seekable}
              onChange={onProgressChange}
              value={remaining}
              data-testid="progress slider"
            />
          ) : null}
          <VideoControlRow>
            {controls?.includePlayPause ? (
              <SvgButton
                set="material"
                appearance="transparent"
                spacing={0}
                color={Color.white}
                name={paused ? 'play-arrow' : 'pause'}
                size={32}
                onClick={onPlayPause}
                data-testid="play-pause button"
              />
            ) : null}
            {controls?.includeVolume ? (
              <VolumeContainer>
                <SvgButton
                  set="material"
                  appearance="transparent"
                  spacing={0}
                  color={Color.white}
                  name={muted ? 'volume-off' : 'volume-up'}
                  size={26}
                  onClick={onVolumeClick}
                  data-testid="volume button"
                />
                <Slider
                  max={1}
                  min={0}
                  step={0.1}
                  value={volume}
                  onChange={onVolumeChange}
                  data-testid="volume slider"
                />
              </VolumeContainer>
            ) : null}

            {controls?.includeProgress ? (
              <ProgressText>
                {remainingDuration} / {maxDuration}
              </ProgressText>
            ) : null}

            {showSubtitle && controls?.subtitles ? (
              <SubtitlesContainer>
                <SvgButton
                  appearance="transparent"
                  name="ClosedCaptionOutlined"
                  set="material"
                  size={IconSize.large}
                  color={Color.white}
                  data-testid="cc-button"
                  onClick={openCloseSubtitleOptions}
                />
                {manageSubtitleOptions ? (
                  <MuiPaper elevation={1}>
                    {subtitleOptions.map(option => (
                      <MuiMenuItem
                        key={option.id}
                        onClick={() => handleCloseSubtitleOptions(option.display)}
                        divider
                      >
                        {option.display}
                      </MuiMenuItem>
                    ))}
                  </MuiPaper>
                ) : null}
              </SubtitlesContainer>
            ) : null}
          </VideoControlRow>
        </VideoControls>
      ) : null}
    </VideoContainer>
  );
}

VideoPlayer.defaultProps = {
  seekable: true,
  controls: {
    includeProgress: true,
    includePlayPause: true,
    includeVolume: true,
    collapsible: true,
    subtitles: true
  }
};

export default VideoPlayer;
