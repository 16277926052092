import React from 'react';
import { MuiTypography, MuiBox } from 'theme/material-ui';
import { Spacing } from 'modules/styles/variables';
import styled from 'styled-components';
import Svg from 'components/UI/Svg/Svg';
import { Icon as MuiIcon } from 'components/Icon';
import { Color } from 'modules/styles/colors';
import FlexBox from 'components/UI/Layout/FlexBox';

interface Props {
  useMuiIcon?: boolean;
  useIcon: boolean;
  iconName: string;
  iconAltText: string;
  displayText: string;
  className?: string;
}

const CardIcon = styled(Svg)`
  margin-right: ${Spacing.medium}px;
`;

const ListItemSection = ({
  useMuiIcon,
  useIcon,
  iconName,
  iconAltText,
  displayText,
  className // This prop is necessary so this component can be extended with styled() ref: https://styled-components.com/docs/basics#styling-any-component
}: Props) => {
  const Icon = useMuiIcon ? MuiIcon : Svg;
  const iconProps = useMuiIcon ? { size: 20 } : { height: 20, width: 20 };
  return (
    <MuiBox borderTop={`.5px solid ${Color.grayLight2}`} py="4px" className={className}>
      <FlexBox flexDirection="row">
        <MuiBox>
          {useIcon ? (
            <CardIcon {...iconProps} as={Icon} name={iconName} />
          ) : (
            <MuiTypography>{iconAltText}:&nbsp;</MuiTypography>
          )}
        </MuiBox>
        <MuiBox overflow="hidden">
          <MuiTypography gutterBottom>{displayText}</MuiTypography>
        </MuiBox>
      </FlexBox>
    </MuiBox>
  );
};

export default ListItemSection;
