import React from 'react';
import styled from 'styled-components';
import { BorderRadius } from 'modules/styles/variables';
import { Color } from 'modules/styles/colors';
import Panel from '../Panel/Panel';
import FlexBox from '../Layout/FlexBox';
import { Svg } from 'components/UI/Svg/';
import { MuiGrid } from 'theme/material-ui';

export default styled(Panel)<{ flat?: boolean; maxWidth?: string }>`
  padding: 22px 28px;
  shadow-color: ${props => (props.flat ? 'transparent' : '#000')};
  shadow-offset: 0 1px;
  shadow-opacity: 0.5;
  shadow-radius: ${props => (props.flat ? '0' : '1px')};
  max-width: ${props => (props.maxWidth ? props.maxWidth : 'auto')};
  margin: 0 auto;
`;

const TickerView = styled.div<{ color: string }>`
  width: 32;
  background-color: ${props => props.color};
  border-top-left-radius: ${BorderRadius.base};
  border-bottom-left-radius: ${BorderRadius.base};
`;

const TickerText = styled.div`
  width: 65;
  color: white;
  font-weight: bold;
`;

const CardContent = styled.div`
  flex: 1;
`;

const CardWrapper = styled.div`
  background-color: ${Color.foreColor};
  border-radius: 5px;
  border: 3px solid ${Color.linkLight};
  padding: 1.2rem 1.5rem;
  margin: 1rem;
`;

interface TickerProps {
  color?: string;
  text?: string;
}

const Ticker = ({ color = Color.primary, text }: TickerProps) => (
  <TickerView color={color}>
    <TickerText
      style={{
        transform: [{ rotate: '-90deg' }, { translateX: -38 }, { translateY: -16 }]
      }}
    >
      {text}
    </TickerText>
  </TickerView>
);

interface CardProps {
  hasTicker?: boolean;
  tickerText?: string;
  tickerColor?: string;
  children: React.ReactNode;
}

export const Card = ({ hasTicker, tickerColor, tickerText, children }: CardProps) => {
  return (
    <FlexBox flexDirection="row">
      {hasTicker && <Ticker color={tickerColor} text={tickerText} />}
      <CardContent>{children}</CardContent>
    </FlexBox>
  );
};

interface InfoCardProps {
  hasIcon?: boolean;
  iconName?: string;
  iconColor?: string;
  bgColor?: string;
  children: React.ReactNode;
}

export const InfoCard = ({ hasIcon, iconName, iconColor, children }: InfoCardProps) => {
  return (
    <CardWrapper>
      <MuiGrid container spacing={4} direction="row" wrap="nowrap">
        {hasIcon && (
          <MuiGrid item xs={1}>
            <Svg set="assets" name={iconName} color={iconColor} size={24} />
          </MuiGrid>
        )}
        <MuiGrid item xs={hasIcon ? 11 : 12}>
          <CardContent>{children}</CardContent>
        </MuiGrid>
      </MuiGrid>
    </CardWrapper>
  );
};

InfoCard.defaultProps = {
  iconName: 'InfoIcon',
  iconColor: Color.primary,
  bgColor: Color.foreColor
};
